import { Card } from "antd";
import { getstarted, upgrade } from "Assets/images";
import { toggleloginModal } from "Libs/redux/UserAuth/reduxSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const UpgradeCard = () => {

    const user = useSelector((state) => state?.updateUserData?.value);
    const userAccessLevel = user?.Client?.[0]?.SharpelyAccessLevel || 0;
    const dispatch = useDispatch();
    if(userAccessLevel>1) return null;
    return (
        <React.Fragment>
            <Card>
                <div className="d-flex justify-content-center flex-column align-items-center">
                    <img loading="lazy"  src={userAccessLevel === 0 ? getstarted : upgrade} style={{ width: "60%" }} />
                    {userAccessLevel === 0 ? <>
                        <div className='textSM w-500' style={{ marginTop: "1.25rem" }}>
                            Embark on your investment journey today by creating a free account with sharpely.
                        </div>
                        <>
                            <Link to='/signup' className='w100'>
                                <button className='btnBlack w100' style={{ marginTop: "2rem" }} >
                                    Create account
                                </button>
                            </Link>
                            <div className="d-flex align-items-center w100" style={{ gap: "1rem", margin: "1rem auto" }}>
                                <div className="flex-grow-1" style={{ borderTop: "1px solid var(--dark4)" }}></div>
                                <span className='textXS w-500'>Or</span>
                                <div className="flex-grow-1" style={{ borderTop: "1px solid var(--dark4)" }}></div>
                            </div>
                            <button className='btnWhite w100' onClick={() => { dispatch(toggleloginModal(true)); }}>
                                Login
                            </button>
                        </>
                    </> : <>
                        <div className='text-center textSM w-500 dark3 mt-3 mb-3'>
                            Supercharge your investments with sharpely's subscription plans. Access exclusive research, real-time updates, and expert strategies for smarter investment decisions. Subscribe now!
                        </div>
                        <Link to='/pricing'>
                            <button className="btnBlack">Explore plans</button>
                        </Link>
                    </>}
                </div>
            </Card>
        </React.Fragment>
    )
}