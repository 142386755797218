import { isChildVisible } from "Libs/scroll";
import { sortDrop } from "Libs/search";
import { generateUniqueKey } from 'Libs/utils';
import React from "react";
import { MdCancel } from "react-icons/md";
import { CustomComparatorEditor } from './CustomComparatorEditor';
import { FunctionsEditor } from "./FunctionsEditor";
import { MetricsEditor } from "./MetricsEditor";
import { QueryOptionsDropdown } from "./QueryBuilderDropdown";
import { brackets, closingBracket, comparators, conditionOperators, customComparators, directionTypes, groupColumnNames, maTypes, mathOperators, multipleValueComp, openingBracket, patternOutputs, stringParser, withinQuotesFields } from "./ScreenerUtils";

export const QueryViewer = React.memo(({ functions = [], variables = {}, timeframe = "1d", screenerMetrics = [], indices = [], onQueriesUpdate = () => { }, isCondition = false,
    initialQueries, isFocused = null, funcToCallOnWrapClick = () => { }, onCreateVariable = () => { }, isAdvance = false, allFundsData = [], readOnly = false }) => {

    const sample_placeholder = "Start typing your query... E.g. Market Cap > 10000"
    const [queries, setQueries] = React.useState(initialQueries);
    const [query, setQuery] = React.useState('');
    const [showDropDown, setShowDropDown] = React.useState(false);
    const queryBuilderRef = React.useRef();
    const [selectedFunc, setSelectedFunc] = React.useState({});
    const [funcModal, setFuncModal] = React.useState();
    const [selectedQuery, setSelectedQuery] = React.useState({});
    const [metricModal, setMetricModal] = React.useState(false);
    const [selectedMetric, setSelectedMetric] = React.useState({});
    const [customCompModal, setCustomCompModal] = React.useState(false);
    const [selectedCustomComp, setSelectedCustomComp] = React.useState({});
    const queryDDItemRef = React.useRef();
    const multipleTypeMetrics = ["sector_code", "industry_code", "nse_basic_ind_code", "style_box_code"];

    const getOptions = React.useCallback(() => {
        let queryKeys = Object.keys(queries || {});
        var queryKeys2 = [...queryKeys].reverse()?.map((el) => el?.split('_')?.[1]);
        let keys = ['compOperator', 'conditionOperator']
        let bracketsKey = ['bracket']
        let key1 = queryKeys2?.[0]
        let key2 = queryKeys2?.[1]
        let result = [];
        let isLastCustomComp = Boolean(Object.values(queries || {})?.reverse()?.[0]?.isCustomComparator)
        let isLastPattern = Object.values(queries || {})?.reverse()?.[0]?.patternOutput >= 0
        let lastOpenBrIndex = Object.values(queries || {})?.reverse()?.findIndex((el) => el?.expression_arr === '(');
        let lastOpenBr = Object.values(queries || {})?.reverse()?.[0]?.expression_arr === '(';
        let lastCloseBrIndex = Object.values(queries || {})?.reverse()?.findIndex((el) => el?.expression_arr === ')');
        let lastCloseBr = Object.values(queries || {})?.reverse()?.[0]?.expression_arr === ')';
        //console.log(isLastPattern, queries)
        console.log(lastOpenBrIndex, lastCloseBrIndex)
        if (selectedQuery?.isAdd === true) {
            if (selectedQuery?.key?.includes('_compOperator')) {
                let temp = sortDrop(query, [...mathOperators, ...comparators], ['label', 'value'])
                result = temp;
            } else if (selectedQuery?.key?.includes('_conditionOperator')) {
                let temp = sortDrop(query, [...conditionOperators], ['label', 'value'])
                result = temp;
            } else if (selectedQuery?.value?.isMetric === true) {
                let its = [...screenerMetrics];
                if (!isCondition) {
                    its = its?.filter((el) => !multipleTypeMetrics?.includes(el?.metric_col_code));
                }
                let temp = !isNaN(Number(query?.trim())) && query?.length > 0 ? [] : sortDrop(query, its, ['label', 'value'])
                result = temp;
            } else {
                let its = [...functions, ...screenerMetrics, ...Object.values(variables)?.map((i) => {
                    return {
                        // ...i,
                        type: "Variable",
                        label: i?.name,
                        value: i?.name,
                    }
                })];
                if (!isCondition) {
                    its = its?.filter((el) => !multipleTypeMetrics?.includes(el?.metric_col_code));
                }
                let temp = !isNaN(Number(query?.trim())) && query?.length > 0 ? [] : sortDrop(query, its, ['label', 'value'])
                result = temp;
            }
        } else {
            if (selectedQuery?.key?.includes('_compOperator') || selectedQuery?.key?.includes('_conditionOperator')) {
                let temp = sortDrop(query, [...mathOperators, ...comparators, ...conditionOperators], ['label', 'value'])
                result = temp;
            }
            else if (selectedQuery?.value?.type === 'metric') {
                let mets = screenerMetrics?.filter((el) => !multipleTypeMetrics?.includes(el?.metric_col_code))
                result = sortDrop(query, mets, ['label', 'value'])
            }
            else if (isLastCustomComp || isLastPattern) {
                result = [...conditionOperators];
            }
            else if (queryKeys2?.length === 0 || keys.includes(key1) || selectedQuery?.key?.includes('_number') || selectedQuery?.value?.type === 'variable' || lastOpenBr) {
                let its = [...functions, ...screenerMetrics, ...Object.values(variables)?.map((i) => {
                    return {
                        // ...i,
                        type: "Variable",
                        label: i?.name,
                        value: i?.name,
                    }
                })];
                if (!isCondition) {
                    its = its?.filter((el) => !multipleTypeMetrics?.includes(el?.metric_col_code));
                }
                if ((queryKeys?.length === 0) || (lastOpenBrIndex === -1 && lastCloseBrIndex === -1) || (lastOpenBrIndex !== -1 && (lastCloseBrIndex === -1 ? false : lastOpenBrIndex > lastCloseBrIndex))) {
                    its = [openingBracket, ...its]
                }
                let temp = !isNaN(Number(query?.trim())) && query?.length > 0 ? [] : sortDrop(query, its, ['label', 'value'])
                result = temp;
            }
            else if (!keys.includes(key1)) {
                if (queries?.[[...queryKeys]?.reverse()?.[0]]?.isMultiple === true) {
                    result = [...conditionOperators];
                } else {
                    let temp = [...mathOperators, ...comparators, ...conditionOperators];
                    if (lastOpenBrIndex !== -1 && (lastCloseBrIndex === -1 ? true : lastOpenBrIndex < lastCloseBrIndex)) {
                        temp = [closingBracket, ...temp];
                    }
                    temp = sortDrop(query, temp, ['label', 'value'])
                    result = temp;
                }
            }
        }
        return result;
    }, [query, queries, selectedQuery])

    const getOptionsTabsTypeAndShow = React.useCallback(() => {
        let queryKeys = Object.keys(queries || {});
        queryKeys = queryKeys.reverse()?.map((el) => el?.split('_')?.[1]);
        let keys = ['compOperator', 'conditionOperator']
        let key1 = queryKeys?.[0]
        let key2 = queryKeys?.[1]
        let result = { type: "", show: false }
        let isLastCustomComp = Boolean(Object.values(queries || {})?.reverse()?.[0]?.isCustomComparator)
        let lastOpenBrIndex = Object.values(queries || {})?.reverse()?.findIndex((el) => el?.expression_arr === '(');
        let lastOpenBr = Object.values(queries || {})?.reverse()?.[0]?.expression_arr === '(';
        let lastCloseBrIndex = Object.values(queries || {})?.reverse()?.findIndex((el) => el?.expression_arr === ')');
        let lastCloseBr = Object.values(queries || {})?.reverse()?.[0]?.expression_arr === ')';
        if (selectedQuery?.isAdd === true) {
            if (selectedQuery?.key?.includes('_compOperator')) {
                result = { type: "operator", show: true };
            } else if (selectedQuery?.key?.includes('_conditionOperator')) {
                result = { type: "operator", show: true };
            } else if (selectedQuery?.value?.isMetric === true) {
                result = { type: "metrics", show: false, showVarTab: false, showCreateVarOpt: isCondition }
            } else {
                result = { type: "metrics", show: true, showVarTab: true, showCreateVarOpt: isCondition }
            }
        }
        else {
            if (selectedQuery?.key?.includes('_compOperator') || selectedQuery?.key?.includes('_conditionOperator')) {
                result = { type: "operator", show: true };
            }
            else if (selectedQuery?.value?.type === 'metric') {
                result = { type: "metrics", show: false }
            }
            else if (isLastCustomComp) {
                result = { type: "operator", show: true }
            }
            else if (queryKeys?.length === 0 || keys.includes(key1) || selectedQuery?.key?.includes('_number') || lastOpenBr) {
                result = { type: "metrics", show: true, showVarTab: true, showCreateVarOpt: isCondition }
            }
            else if (!keys.includes(key1)) {
                result = { type: "operator", show: true }
            }
        }
        return result;
    }, [queries, selectedQuery]);

    const options = getOptions();

    const optionsTabTypeAndShow = getOptionsTabsTypeAndShow();

    const getDropdownPosition = React.useCallback(() => {
        if (showDropDown) {
            let dropdown = document.querySelector('.queryDropdownCont');
            // if (dropdown) {
            //     const {top,bottom,left,right,height,width} = dropdown.getBoundingClientRect();
            //     const viewportHeight = window.innerHeight;
            //     const viewportWidth = window.innerWidth;
            //     let result = {};
            //     if(bottom>viewportHeight){
            //         result.bottom = bottom-viewportHeight-height;
            //         if(left>0&&right)
            //     }
            //     if(top<0){
            //         result.top = '100%'
            //     }
            //     if(left<0){
            //         result.left = '100%'
            //     }
            //     if(right>viewportWidth){
            //         result.right = right - viewportWidth - right;
            //     }
            //     ////console.log(top,bottom,left,right,height,width, viewportHeight, viewportWidth)
            //     return result;
            // }
        }
        return { top: "100%", left: "100%" }
    }, [query, options, showDropDown]);

    const dropdownPositions = getDropdownPosition();

    const expUIParser = (exp, queryData, metric) => {
        //console.log(exp, queryData, metric)
        if (exp === "Add") {
            let colorCode = "#fa7d0f"
            return <span className="textXS py-1 px-2" style={{ lineHeight: undefined, color: colorCode, padding: "0 0.2rem 0.2rem 0", border: `1px solid ${colorCode}`, borderRadius: 6 }}>+ Add</span>
        }
        const isMetric = (str) => {
            let ser = screenerMetrics?.filter((i) => i?.value === str)?.[0]
            if (ser) {
                return ser?.label
            }
            return str;
        }
        let item = queryData?.expression_details
        let expN = String(exp)?.match(/.*?\(/)?.[0]
        var colorCode = metric?.includes("_number") ? "var(--green)" : metric?.includes("_compOperator") ? "var(--gold)" : "var(--grey4)"

        // //console.log("hello",exp, queryData, metric)

        if (String(exp)?.match(/by|matype|direction\s{0,3}=\s{0,2}/)) {
            if (String(exp)?.match(/by\s{0,3}=\s{0,2}/)) {
                let showPeriod = queryData?.expression_arr?.map(element => {
                    return [`"MKT"`, `"SEC"`, `"INDGRP"`, `"IND"`].concat(indices?.map((item) => `"${item?.IndexNameCaps}"`)).filter(str => element.includes(str))?.length > 0
                });
                showPeriod = showPeriod?.filter((i) => i === false)?.length === queryData?.expression_arr?.length
                queryData = {
                    ...queryData,
                    expression_arr: !showPeriod ? queryData?.expression_arr?.filter(element => {
                        return !element.match(/period\s{0,3}=/)
                    }) : queryData?.expression_arr
                }
            }
            let newArr = queryData?.expression_arr?.map((str) => {
                // alert(str)
                str = isMetric(str)
                if (String(str)?.match(/by|matype|direction\s{0,3}=\s{0,2}/)) {
                    if (String(str)?.match(/\s{0,3}=\s{0,3}/g)) {
                        var arr = String(str)?.split(/\s{0,3}=\s{0,3}/g)
                        var keyName = arr?.[0]
                        var keyValue = item?.type === "<class 'int'>" ? parseInt(Number(arr?.[1])) : item?.type === "<class 'float'>" ? parseFloat(Number(arr?.[1])) : arr?.[1];

                        if (String(str)?.match(/by\s{0,3}=\s{0,2}/)) {
                            var keyValue2 = groupColumnNames?.concat(indices?.map((item, i) => {
                                return { key: item?.IndexName, label: item?.IndexName, value: `"${item?.IndexNameCaps}"`, tabType: "Indices" }
                            }))?.filter((i) => {
                                return i?.value === keyValue
                            })?.[0]?.label
                            if (keyValue2) {
                                return keyName + "=" + keyValue2
                            } else {
                                return keyName + "=" + ((keyValue?.endsWith("F\"") || keyValue?.endsWith("Y\"")) ? "Years" : keyValue?.endsWith("Q\"") ? "Quarters" : (keyValue?.endsWith("M\"") ? "Months" : "Days"))
                            }
                        } else if (String(str)?.match(/matype\s{0,3}=\s{0,2}/)) {
                            var keyValue2 = maTypes?.filter((i) => {
                                return i?.value === Number(keyValue)
                            })?.[0]?.label
                            return keyName + "=" + keyValue2
                        } else if (String(str)?.match(/direction\s{0,3}=\s{0,2}/)) {
                            var keyValue2 = directionTypes?.filter((i) => {
                                return i?.value === Number(keyValue)
                            })?.[0]?.label
                            return keyName + "=" + keyValue2
                        }
                    } else {
                        return str
                    }
                } else {
                    return String(str)
                }
            })
            // //console.log("hello", newArr)
            return <span className="w-bold" style={{ color: colorCode }}>{isAdvance ? String(queryData?.expression_details?.key)?.toUpperCase() : String(queryData?.expression_details?.label)}<span style={{ color: "var(--grey4)" }} className="w-400">{" ("}{newArr?.join(", ")}{")"}</span></span>
        }
        if (String(exp)?.toLowerCase() === "and" || String(exp)?.toLowerCase() === "or")
            return <span className="w-bold" style={{ color: "rgb(197, 134, 192)" }}>{String(exp)?.toUpperCase()}</span>
        if (queryData?.type !== "metric" && expN) {
            let patternOutput = patternOutputs?.find((el) => el?.value === queryData?.patternOutput)?.label;

            return <span className="w-bold" style={{ color: colorCode }}>
                {String(expN)?.toUpperCase()?.replace("(", "")}
                <span className="w-400" style={{ color: "var(--grey4)" }}>
                    {" ("}{exp?.replace(expN, "")}</span>
                {patternOutput ? <span className="w-bold ms-2" style={{ color: "var(--gold)" }}> is <span className="w-bold ps-2" style={{ color: "var(--green)" }}>{patternOutput}</span></span> : ""}
            </span>

            // return <span className="w-bold" style={{ color: colorCode }}>{isAdvance ? String(expN)?.toUpperCase()?.replace("(", " (") : String(expN)?.replace("(", " (")}<span className="w-400" style={{ color: "var(--grey4)" }}>{exp?.replace(expN, "")?.replace(")", "")}</span>{")"}</span>
        }
        if (queryData?.type === 'metric' && queryData?.isMultiple === true && multipleTypeMetrics?.includes(item?.metric_col_code)) {
            let itemStr = queryData?.expression_arr?.map((el) => item?.dataToRenderFrom?.find((ele) => ele?.value === el)?.label)?.join(', ')
            return (
                <>
                    <span className="w-bold" style={{ color: colorCode, display: "inline", margin: "0 0.5rem 0.5rem 0", }}>{String(exp)}</span>
                    <span className="w-bold" style={{ color: "var(--gold)", margin: "0 0.5rem 0.5rem 0", display: "inline" }}>
                        {String(multipleValueComp?.find((el) => el?.value === queryData?.comparator)?.label)}
                    </span>
                    <span className="w-bold" style={{ color: "var(--white)" }}>[ <span className="w-normal" style={{ color: "var(--green)", display: "inline" }}>{itemStr}</span> ]</span>
                </>
            )
        }
        if (queryData?.isCustomComparator === true) {
            let itemStr = queryData?.expression_arr?.map((el) => isMetric(el))?.join(', ')
            return (
                <>
                    <span className="w-bold" style={{ color: colorCode, display: "inline", margin: "0 0.5rem 0.5rem 0", }}>{String(exp)}</span>
                    <span className="w-bold" style={{ color: "var(--white)" }}>[ <span className="w-normal" style={{ color: "var(--green)", display: "inline" }}>{itemStr}</span> ]</span>
                </>
            )
        }
        return <span className="w-bold" style={{ color: colorCode }}>{String(exp)}</span>

    }

    const handleKeyDown = (event) => {
        let includedKeys = ['Backspace', 'Delete', 'Enter', 'Space', 'ArrowUp', 'ArrowDown']
        if (!includedKeys.includes(event.key) && !includedKeys.includes(event?.code)) {
            return event;
        }
        let menuItems = Array.from(document.querySelectorAll('.queriesDropdown .queryDropdownItem'));
        const parent = document.querySelector('.queriesDropdown')
        if ((event.key == 'Backspace' || event.key == 'Delete') && event?.target?.value?.trim()?.length <= 0 && !selectedQuery?.key) {
            let q = { ...queries }
            let keys = Object.keys(queries)
            if (keys?.length > 0) {
                delete q[keys[keys.length - 1]]
                setQueries(q)
            }
        }
        else if ((event.key === 'Enter' || event.code === 'Space') && query?.trim() !== '' && (!isNaN(Number(query?.trim())) || [...comparators, ...mathOperators]?.map((i) => i?.value)?.includes(query?.trim() === "=" ? "==" : query?.trim()))) {
            let isNAN = isNaN(Number(query?.trim()))
            let val = !isNAN ? Number(query?.trim()) : query?.trim();
            console.log("sadjhbdsasahj", val)
            setQueries({
                ...queries,
                [selectedQuery?.key || (!isNAN ? Object.keys(queries)?.length + "_number" : Object.keys(queries)?.length + "_compOperator")]: {
                    expression: val,
                    expression_arr: val === "=" ? "==" : val
                }
            })
            setQuery("")
            if (selectedQuery?.key) {
                setSelectedQuery({});
                setTimeout(() => {
                    if (queryBuilderRef.current) {
                        queryBuilderRef.current.focus()
                    }
                }, 100)
            }
        }
        if (menuItems?.length <= 0) {
            return event
        }
        let maxIndex = menuItems.length - 1;
        let menuIndex = menuItems?.findIndex((ele) => ele.getAttribute('data-focused') !== null);
        if (!menuIndex && menuIndex !== 0) {
            menuItems[0].setAttribute('data-focused', 'active');
        }
        else if (event.key === 'ArrowUp' && menuIndex > 0) {
            event.preventDefault();
            menuItems[menuIndex]?.removeAttribute('data-focused');
            menuItems[menuIndex - 1].setAttribute('data-focused', 'active');
            if (!isChildVisible(parent, menuItems[menuIndex - 1])) {
                parent.scrollTo({ top: menuItems[menuIndex - 1].offsetTop - parent.offsetTop - 110, behavior: "smooth" });
            }
        }
        else if (event.key === 'ArrowDown' && menuIndex < maxIndex) {
            event.preventDefault();
            menuItems[menuIndex]?.removeAttribute('data-focused');
            menuItems[menuIndex + 1].setAttribute('data-focused', 'active');
            if (!isChildVisible(parent, menuItems[menuIndex + 1])) {
                parent.scrollTo({ top: menuItems[menuIndex + 1].offsetTop - parent.offsetTop - 110, behavior: "smooth" });
            }
        }
        else if ((event.key === 'Enter') && menuItems.length > 0 && menuIndex >= 0 && menuIndex <= maxIndex) {
            menuItems[menuIndex]?.click()
        }
    }

    const handleDocumentClick = (e) => {
        let target = document.querySelector('.queryDropdownCont');
        let currTarget = e.target
        let classNames = ['queriesDropdown', 'queryDropdownItem', 'queryDropdownTabCont', 'queryDropdownTabItem', 'queryDropdownInput', 'textSM w-500 dark4 queryDropdownInput']
        do {
            if (target === currTarget || classNames?.includes(currTarget.className)) {
                return;
            }
            else if (currTarget === target?.querySelector('.queryDropdownItem') || currTarget === target?.querySelector('.queryDropdownTabItem')) {
                return;
            }
            currTarget = currTarget.parentNode
        }
        while (currTarget) {
            setShowDropDown(false);
            if (selectedQuery?.key) {
                setSelectedQuery({});
                setQuery('');
            }
        }
    }

    const handleQueryInput = (e, query_Key) => {
        let value = e.target.value;
        const enteredText = e.target.value?.trim();
        // Regular expressions to match mathematical and comparison operators
        const splitters = [...comparators?.filter((i) => i?.value?.match(/<>/))?.reverse(), ...mathOperators, ...brackets]?.map((i) => i?.value)
        const escapedOperators = splitters.map(operator => operator.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
        // const regexPattern = new RegExp(`\\s{1,3}\(${escapedOperators.join('|')}\)\\s{1,3}`, 'g');
        const regexPattern = new RegExp(`${escapedOperators.join('|')}`, 'g');
        // Regular expressions to match 'AND' or 'OR'
        const keywordRegex = /\b(AND|OR)\b/i;
        if (regexPattern.test(enteredText) && (enteredText?.includes("(") || enteredText?.includes(")") || optionsTabTypeAndShow?.type === "operator") && !selectedQuery?.key) {
            // console.log(String(enteredText).match(/\d+/gm))
            if (String(enteredText).match(/\d+/gm)) {
                setQueries({
                    ...queries,
                    [query_Key || Object.keys(queries)?.length + "_number"]: {
                        expression: Number(String(enteredText).match(/\d+/gm)?.[0]),
                        expression_arr: Number(String(enteredText).match(/\d+/gm)?.[0]),
                    },
                    [query_Key || Object.keys(queries)?.length + ((enteredText?.includes("(") || enteredText?.includes(")")) ? "_bracket" : "_compOperator")]: {
                        expression: enteredText?.replace(String(enteredText).match(/\d+/gm)?.[0], ""),
                        expression_arr: enteredText?.replace(String(enteredText).match(/\d+/gm)?.[0], ""),
                    }
                })
                setQuery("")
            } else if (String(enteredText)?.trim()?.length === 1) {
                setQueries({
                    ...queries,
                    [query_Key || Object.keys(queries)?.length + ((enteredText?.includes("(") || enteredText?.includes(")")) ? "_bracket" : "_compOperator")]: {
                        expression: enteredText,
                        expression_arr: enteredText
                    }
                })
                setQuery("")
            }


        } else if (keywordRegex.test(enteredText) && optionsTabTypeAndShow?.type === "operator" && !selectedQuery?.key) {
            setQueries({
                ...queries,
                [query_Key || Object.keys(queries)?.length + "_conditionOperator"]: {
                    expression: enteredText,
                    expression_arr: enteredText
                }
            })
            setQuery("")
        } else {
            setQuery(value);
            if (!showDropDown) {
                setShowDropDown(true);
            }
        }
    }

    const onQueryOptionSelect = (item, query_key, queryObj) => {

        let selectedOption = item;
        //console.log("aASDASDA", selectedOption)
        if (item?.type === 'Variable') {
            setQueries({
                ...queries,
                [selectedQuery?.key || query_key || Object.keys(queries)?.length + "_variable"]: {
                    expression: item?.label,
                    expression_arr: item?.label,
                    type: "variable",
                    // expression_details: item,
                    // isMetric: true,
                }
            })
            setQuery("")
        } else if (item?.type === 'Metric F' || item?.type === 'Metric T') {
            if (multipleTypeMetrics?.includes(item?.metric_col_code)) {
                setSelectedMetric({
                    queryKey: selectedQuery?.key || query_key || Object.keys(queries)?.length + "_" + selectedOption?.key,
                    metric: selectedOption,
                    comparator: "is_in",
                    selectedItems: [selectedOption?.dataToRenderFrom?.[0]?.value]
                })
                setMetricModal(true);
            }
            else {
                setQueries({
                    ...queries,
                    [query_key || Object.keys(queries)?.length + "_" + selectedOption?.key]: {
                        expression: item?.label,
                        expression_arr: item?.key,
                        type: "metric",
                        expression_details: item,
                        isMetric: true,
                    }
                })
                setQuery("")
            }
        }
        else if ((['mathOperator', 'comparator', 'conditionOperator'])?.includes(item?.type)) {
            //console.log('retdgdt')
            if (customComparators?.map((i) => i?.value).includes(item?.value)) {
                setSelectedCustomComp({
                    queryKey: query_key || Object.keys(queries)?.length + "_compOperator",
                    comparator: item,
                    selectedItems: []
                });
                setCustomCompModal(true);
            }
            else {
                let key = item?.type === 'conditionOperator' ? 'conditionOperator' : 'compOperator'
                setQueries({
                    ...queries,
                    [query_key || Object.keys(queries)?.length + "_" + key]: {
                        expression: item?.label,
                        expression_arr: item?.value,
                    }
                })
                setQuery("")
            }
        }
        else if (item?.type === 'Brackets') {
            setQueries({
                ...queries,
                [query_key || Object.keys(queries)?.length + "_bracket"]: {
                    expression: item?.label,
                    expression_arr: item?.value,
                }
            })
            setQuery("")
        }
        else {
            let exp = `${selectedOption?.sh_name}(`;
            var selectedOptionReqInputs = stringParser(selectedOption?.sh_req_inputs || "[]")
            let arr = [...selectedOptionReqInputs];
            let exp_arr = []
            stringParser(selectedOption?.sh_opt_inputs || "[]")?.map((item) => {
                let v = queries?.[item?.name] ? queries?.[item?.name] : item?.default
                v = item?.name + "=" + (withinQuotesFields.includes(item?.name) ? `"${v}"` : v)
                arr.push(v);
            })
            for (let i = 0; i < arr?.length; i++) {
                exp += arr[i] + (i === arr?.length - 1 ? "" : ", ")
                exp_arr?.push(arr[i])
            }
            exp += ')'


            let exp2 = `${selectedOption?.label}(`;
            // var selectedOptionReqInputs = stringParser(selectedOption?.sh_req_inputs || "[]")
            let arr2 = [];
            let exp_arr2 = []
            stringParser(selectedOption?.sh_opt_inputs || "[]")?.map((item) => {
                let v = queries?.[item?.name] ? queries?.[item?.name] : item?.default
                v = item?.name + "=" + (withinQuotesFields.includes(item?.name) ? `"${v}"` : v)
                arr2.push(v);
            })
            for (let i = 0; i < arr2?.length; i++) {
                exp2 += arr2[i] + (i === arr2?.length - 1 ? "" : ", ")
                exp_arr2?.push(arr[i])
            }
            exp2 += ')'

            setSelectedFunc({
                metric: Object.keys(queries)?.length + "_" + selectedOption?.key,
                query_key: query_key,
                query: {
                    expression: exp,
                    basic_exp: exp?.replace(selectedOption?.sh_name, selectedOption?.label || selectedOption?.sh_name),
                    expression_arr: exp_arr,
                    expression_details: selectedOption,
                    type: "func"
                },
            })
            setFuncModal(true);
            setShowDropDown(false)
        }
        if (query_key && (!(queryObj))) {
            setSelectedQuery({});
            setTimeout(() => {
                if (queryBuilderRef.current) {
                    queryBuilderRef.current.focus()
                }
            }, 100)
        }
    }

    React.useEffect(() => {
        if (selectedQuery?.key || showDropDown) {
            document.addEventListener('click', handleDocumentClick);
        }
        else {
            document.removeEventListener('click', handleDocumentClick);
        }
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        }
    }, [selectedQuery, showDropDown])

    React.useEffect(() => {
        if ((JSON.stringify(queries || {}) !== JSON.stringify(initialQueries || {}))) {
            //console.log(3);
            setShowDropDown(false);
            onQueriesUpdate(queries);
        }
    }, [queries]);

    React.useEffect(() => {
        if (isFocused) {
            if (queryBuilderRef?.current) {
                queryBuilderRef.current.focus();
            }
        }
    }, [isFocused, queries])

    var indi = 0
    var prevAnd = 0
    return (
        <React.Fragment>
            <div style={{ width: "100%", minHeight: "150px", padding: "0.75rem", border: "0px", backgroundColor: "#1f1f1f", borderRadius: isCondition ? "0 0 0.3rem 0.3rem" : "0.3rem", position: "relative" }} onClick={() => {
                funcToCallOnWrapClick();
                if (queryBuilderRef?.current && !funcModal && !metricModal) {
                    queryBuilderRef.current.focus();
                }
            }}>
                <div className="sampleQueryView" style={{ gap: '5px 2px', width: "100%", position: "relative", flexWrap: "wrap" }}>
                    {Object.entries(queries || {})?.map(([metric, metricObj], index) => {
                        if ((String(metricObj?.expression)?.toLowerCase() === "and" || String(metricObj?.expression)?.toLowerCase() === "or"))
                            indi++
                        if (Object.keys(selectedQuery)?.length > 0) {
                            if (metric === selectedQuery?.key) {
                                return <span style={{ position: "relative", width: "fit-content", gap: 10, margin: "0 0.5rem 0.5rem 0", }}
                                    key={generateUniqueKey(metric)} >
                                    <input value={query} autoFocus={true} autoComplete="new-password"
                                        onBlur={() => {
                                            if (query?.trim() !== '' && !isNaN(Number(query?.trim()))) {
                                                let isNAN = isNaN(Number(query?.trim()))
                                                let val = !isNAN ? Number(query?.trim()) : query?.trim();
                                                setQueries({
                                                    ...queries,
                                                    [selectedQuery?.key || Object.keys(queries)?.length + "_number"]: {
                                                        expression: val,
                                                        expression_arr: val
                                                    }
                                                })
                                                setQuery("")
                                                if (selectedQuery?.key) {
                                                    setSelectedQuery({});
                                                    setTimeout(() => {
                                                        if (queryBuilderRef.current) {
                                                            queryBuilderRef.current.focus()
                                                        }
                                                    }, 100)
                                                }
                                            }
                                        }}
                                        style={{
                                            width: Object.keys(queries)?.length <= 0 ? query?.length === 0 ? sample_placeholder?.length + 1 + "ch" : query?.length + 1 + 'ch' : query?.length + 1 + 'ch', border: "none", outline: "none",
                                            borderRadius: "var(--borderRadius)", padding: "0.25rem",
                                        }} onChange={handleQueryInput} className='textSM w-500 dark4 queryDropdownInput' ref={queryBuilderRef} onKeyDown={handleKeyDown} />
                                    {showDropDown && options?.length > 0 ?
                                        <QueryOptionsDropdown
                                            isAdvance={isAdvance}
                                            dropdownPositions={dropdownPositions}
                                            options={options}
                                            onSelect={(item) => {
                                                //console.log("aASDASDA", item)
                                                onQueryOptionSelect(item, metric, metricObj);
                                            }}
                                            hideVarOpt={!optionsTabTypeAndShow?.showVarTab}
                                            hideCreateMetric={!optionsTabTypeAndShow?.showCreateVarOpt}
                                            hideTabs={!optionsTabTypeAndShow?.show}
                                            tabsType={optionsTabTypeAndShow?.type} /> : null}
                                </span>
                            }
                        }
                        return (
                            <span style={{ lineHeight: "1.75rem" }} key={generateUniqueKey(metric)}>
                                <span className={(metricObj?.expression === "Add" || metric?.includes('_bracket')) ? 'textSM' : readOnly ? "textSM" : "condition-query-item textSM"} onClick={(e) => {
                                    if (readOnly) {
                                        return
                                    }
                                    else if (metric?.includes('_bracket')) {
                                        e.stopPropagation()
                                        return;
                                    }
                                    else if (metricObj?.expression === "Add") {
                                        setQuery("");
                                        setSelectedQuery({
                                            key: metric,
                                            isAdd: true,
                                            value: metricObj?.prevdata,
                                        })
                                        setTimeout(() => {
                                            if (queryBuilderRef.current) {
                                                queryBuilderRef.current.focus()
                                            }
                                        }, 100)
                                        e.stopPropagation()
                                        // } else if (!(metric?.includes("_compOperator") || metric?.includes("_conditionOperator") || metric?.includes("_number") || metricObj?.type === "metric")) {
                                    } else if (metricObj?.type === "func" && isAdvance) {
                                        setSelectedFunc({
                                            metric: metric,
                                            query: metricObj,
                                            allowFuncChange: true
                                        })
                                        setFuncModal(true)
                                    }
                                    else if (metricObj?.type === "metric" && metricObj?.isMultiple === true && multipleTypeMetrics?.includes(metricObj?.expression_details?.metric_col_code)) {
                                        setSelectedMetric({
                                            queryKey: metric,
                                            metric: metricObj?.expression_details,
                                            comparator: metricObj?.comparator,
                                            selectedItems: metricObj?.expression_arr
                                        })
                                        setMetricModal(true);
                                    }
                                    else if (metricObj?.isCustomComparator === true && customComparators?.map((i) => i?.value)?.includes(metricObj?.comparator)) {
                                        setSelectedCustomComp({
                                            queryKey: metric,
                                            comparator: metricObj?.expression_details,
                                            selectedItems: metricObj?.expression_arr
                                        });
                                        setCustomCompModal(true);
                                    }
                                    else if (isAdvance) {
                                        setQuery(String(metricObj?.expression));
                                        setSelectedQuery({
                                            key: metric,
                                            value: metricObj
                                        })
                                        setTimeout(() => {
                                            if (queryBuilderRef.current) {
                                                queryBuilderRef.current.focus()
                                            }
                                        }, 100)
                                        e.stopPropagation()
                                    }
                                }}
                                    style={{
                                        cursor: "pointer",
                                        border: "none", outline: "none",
                                        borderRadius: "var(--borderRadius)", padding: "0.25rem",
                                        margin: "0 0.4rem 0.4rem 0", position: "relative"
                                    }}>
                                    {expUIParser(metricObj?.expression, metricObj, metric)}
                                    <MdCancel className="query-item-delete" color="var(--red)" onClick={(e) => {
                                        e.stopPropagation()
                                        setQueries({
                                            ...queries,
                                            [metric]: {
                                                prevdata: metricObj,
                                                expression: "Add"
                                            }
                                        })
                                    }} />
                                </span>
                                {(String(metricObj?.expression)?.toLowerCase() === "and" || String(metricObj?.expression)?.toLowerCase() === "or") && <div style={{ width: "100%" }}></div>}
                                {/* {(String(metricObj?.expression)?.toLowerCase() === "and" || String(metricObj?.expression)?.toLowerCase() === "or") && <span key={indi} className="showContentOnHoverRow queryViewerRow" style={{ width: "100%", position: "absolute", zIndex: 9000, bottom: `calc(${getDivHeight(".sampleQueryView")}px - ${(indi) * 1.75}rem)`, height: "1.75rem", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <div style={{border:"1px solid red"}} className={"content" + ` indi-${indi}`} onClick={(e) => {
                                        e.stopPropagation()
                                        console.log(e.currentTarget)
                                        var andIndex = indi - Number(e.currentTarget.classList[1]?.split("-")?.[1])
                                        console.log(andIndex,queries,index)
                                        // setQueries({
                                        //     ...queries,
                                        //     [metric]: {
                                        //         prevdata: metricObj,
                                        //         expression: "Add"
                                        //     }
                                        // })
                                    }}>
                                        <MdCancel style={{ cursor: "pointer" }} color="var(--orange)" onClick={(e) => {
                                            // e.stopPropagation()
                                        }} />
                                    </div>
                                </span>} */}
                            </span>
                        )
                    })}
                    {Object.keys(queries)?.length <= 0 ? query?.length === 0 ? <div className="textSM w-500 grey2" style={{ position: "absolute", top: 5, left: 10, userSelect: "none" }}>
                        {sample_placeholder}
                    </div> : null : null}
                    {Object.keys(selectedQuery)?.length === 0 && <span style={{ position: "relative", width: "fit-content", gap: 10, margin: "0 0.5rem 0.5rem 0", }}>
                        <input readOnly={readOnly} placeholder={""} value={query} autoComplete="new-password"
                            onBlur={() => {
                                if (query?.trim() !== '' && !isNaN(Number(query?.trim()))) {
                                    let isNAN = isNaN(Number(query?.trim()))
                                    let val = !isNAN ? Number(query?.trim()) : query?.trim();
                                    setQueries({
                                        ...queries,
                                        [selectedQuery?.key || Object.keys(queries)?.length + "_number"]: {
                                            expression: val,
                                            expression_arr: val
                                        }
                                    })
                                    setQuery("")
                                    if (selectedQuery?.key) {
                                        setSelectedQuery({});
                                        setTimeout(() => {
                                            if (queryBuilderRef.current) {
                                                queryBuilderRef.current.focus()
                                            }
                                        }, 100)
                                    }
                                }
                            }}
                            style={{
                                width: query?.length + 1 + 'ch', border: "none",
                                outline: "none",
                                borderRadius: "var(--borderRadius)", padding: "0.25rem",
                                color: "var(--white)",
                                border: "none", background: "transparent"
                            }} onInput={handleQueryInput} className='textSM w-500 dark4 queryDropdownInput' ref={queryBuilderRef} onKeyDown={handleKeyDown} />
                        {showDropDown && options?.length > 0 && query?.length > 0 ?
                            <QueryOptionsDropdown
                                isAdvance={isAdvance}
                                hideCreateMetric={!optionsTabTypeAndShow?.showCreateVarOpt}
                                dropdownPositions={dropdownPositions}
                                onCreateVariable={onCreateVariable}
                                options={options}
                                onSelect={(item) => {
                                    //console.log("aASDASDA", item)
                                    onQueryOptionSelect(item);
                                }} queryDDItemRef={queryDDItemRef}
                                hideVarOpt={!optionsTabTypeAndShow?.showVarTab} tabsType={optionsTabTypeAndShow?.type} hideTabs={!optionsTabTypeAndShow?.show}
                            /> : null}
                    </span>}
                </div>
                {funcModal && <FunctionsEditor allFundsData={allFundsData} isAdvance={isAdvance} variables={variables} indices={indices} timeframe={timeframe} open={funcModal} toggle={() => {
                    setFuncModal(false);
                    setSelectedFunc({});
                    setTimeout(() => {
                        if (queryBuilderRef.current) {
                            queryBuilderRef.current.focus()
                        }
                    }, 100)
                }} selectedOption={selectedFunc} functions={functions} screenerMetrics={screenerMetrics} allowFuncChange={selectedFunc?.allowFuncChange}
                    onDone={(modifiedOption) => {
                        if (modifiedOption?.isItemChanged || selectedQuery?.key) {
                            let qrKeys = Object.keys(queries || {});
                            let qrObj = {};
                            let ke = selectedQuery?.key ? selectedQuery?.key : modifiedOption?.keyToReplaceWith
                            qrKeys.forEach((k) => {
                                if (modifiedOption?.keyToReplace === k) {
                                    qrObj[modifiedOption?.query_key_initial || modifiedOption?.keyToReplaceWith] = modifiedOption?.query;
                                }
                                else {
                                    qrObj[k] = queries[k];
                                }
                            })
                            setQueries(qrObj);
                        }
                        else {
                            setQueries({
                                ...queries,
                                [modifiedOption?.query_key_initial || modifiedOption?.keyToReplaceWith]: modifiedOption?.query
                            })
                        }
                        setQuery("");
                        setSelectedFunc({});
                        setFuncModal(false);
                        setTimeout(() => {
                            if (queryBuilderRef.current) {
                                queryBuilderRef.current.focus()
                            }
                        }, 100)
                    }} />}
                {metricModal && <MetricsEditor open={metricModal} selectedMetric={selectedMetric} toggle={() => {
                    setMetricModal(false);
                    setSelectedMetric({});
                    setTimeout(() => {
                        if (queryBuilderRef.current) {
                            queryBuilderRef.current.focus()
                        }
                    }, 100)
                }} onDone={(modifiedOption) => {
                    //console.log(modifiedOption)
                    setQueries({
                        ...queries,
                        [modifiedOption?.queryKey]: modifiedOption?.query
                    })
                    setMetricModal(false);
                    setQuery('');
                    setTimeout(() => {
                        if (queryBuilderRef.current) {
                            queryBuilderRef.current.focus()
                        }
                    }, 100)
                }} />}
                {customCompModal && <CustomComparatorEditor functions={functions} open={customCompModal} selectedCustomComp={selectedCustomComp} toggle={() => {
                    setCustomCompModal(false);
                    setSelectedCustomComp({});
                    setTimeout(() => {
                        if (queryBuilderRef.current) {
                            queryBuilderRef.current.focus()
                        }
                    }, 100)
                }} variables={variables} screenerMetrics={screenerMetrics} onDone={(modifiedOption) => {
                    setQueries({
                        ...queries,
                        [modifiedOption?.queryKey]: modifiedOption?.query
                    })
                    setCustomCompModal(false);
                    setSelectedCustomComp({});
                    setQuery('');
                    setTimeout(() => {
                        if (queryBuilderRef.current) {
                            queryBuilderRef.current.focus()
                        }
                    }, 100)
                }} />}
            </div>
        </React.Fragment>
    )
})