import { LoadingOutlined } from '@ant-design/icons';
import moengage from "@moengage/web-sdk";
import { togglePreferenceModal, updateUserData } from "Libs/redux/UserAuth/reduxSlice";
import { preferenceQuestions as questions } from 'Libs/utils';
import AdminServices from "Services/api/AdminServices";
import { Dashboard_Portfolio } from "Services/api/FabricatedAPIS";
import { Modal } from "antd";
import React from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

export const UserOnBoardingModal = () => {

    let user = useSelector((state) => state?.updateUserData?.value);
    const openPreferenceModal = useSelector((state) => state?.preferenceModal?.value);
    const [step, setStep] = React.useState(1);
    const [answers, setAnswers] = React.useState({ isNewsletterSubscribed: 1 });
    const dispatch = useDispatch();
    const isMobile = window.innerWidth <= 575;
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    // const location = useLocation();

    let question = questions?.find((el) => el?.value === step);

    // const lottieOptions = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: animationData,
    //     rendererSettings: {
    //         preserveAspectRatio: 'xMidYMid slice',
    //     },
    // };

    const handleSubmit = async () => {
        if (loading) {
            return;
        }
        try {
            setLoading(true);
            let data = {
                clientId: user?.id,
                ...answers
            }
            let res = await AdminServices.editAccount(data)?.then((res1) => {
                return res1;
            })

            if (res?.type === true) {
                moengage.add_user_attribute('Preference1', answers?.['q1Answer']);
                moengage.add_user_attribute('Preference2', answers?.['q2Answer']);
                moengage.track_event("Preference_submitted", {
                    "user_email": user?.email
                })
                let userData = { name: user?.name, email: user?.email, mobile: user?.mobile, isLogged: true, id: user?.id, login_Date: user?.login_Date }
                await Dashboard_Portfolio.getClientData(userData, true).then((res) => {
                    if (res) {
                        setSuccess(true);
                        setLoading(false);
                        setAnswers({ isNewsletterSubscribed: 1 });
                        setStep(1);
                        dispatch(updateUserData({ ...userData, ...res, mobile: res?.Client[0]?.MobileNumber }))
                        setTimeout(() => {
                            dispatch(togglePreferenceModal(false));
                            setSuccess(false);
                        }, 2000)
                    }
                })
            } else {
                setLoading(false);
            }
        }
        catch (err) {
            setLoading(false);
            toast.error('Something wennt wrong! Please try again.')
        }
    }

    return (
        <>
            <Modal open={openPreferenceModal} footer={false} closable={false} width={isMobile ? "100%" : "30rem"}
                styles={{
                    mask: {
                        background: "rgb(0,0,0,0.1)", backdropFilter: "blur(2px)"
                    },
                    // body: {
                    //     padding: 0
                    // }
                }}
            // maskStyle={{ background: "rgb(0,0,0,0.1)", backdropFilter: "blur(2px)" }}
            >
                {success ? <>
                    <div className='d-flex flex-column align-items-center'>
                        {/* <Lottie options={lottieOptions} width='10rem' height='10rem' isClickToPauseDisabled /> */}
                        <img width='10rem' height='10rem' style={{
                        height: "10rem",
                        width: "10rem",
                    }} src={require('Assets/lottie/success.gif')} alt="loading..." />
                        <div className='textXL w-500 text-center black mb-3' style={{ userSelect: "none" }}>
                            Thank you
                        </div>
                        <div className='textSM dark4 mt-3 mb-2'>
                            Your preferences has been updated.
                        </div>
                    </div>
                </> : <>
                    <div className="textLG w-700" style={{}}>
                        {question?.heading}
                    </div>
                    <div className="textSM dark3 mt-2">
                        {question?.subText}
                    </div>
                    <div className="d-flex flex-column" style={{ gap: "1rem", marginTop: "1.25rem" }}>
                        {question?.options?.map((opt, i) => {
                            let questionKey = question?.key
                            return (
                                <div style={{
                                    border: answers?.[questionKey] === opt?.value ? "1px solid var(--primary)" : "1px solid var(--grey2)",
                                    background: answers?.[questionKey] === opt?.value ? "var(--blueShade)" : "var(--grey4)",
                                    padding: "0.5rem 1rem", borderRadius: "var(--borderRadius)", cursor: "pointer",
                                    display: "flex", gap: "0.75rem", alignItems: "center"
                                }} onClick={() => {
                                    let temp = { ...answers, [questionKey]: opt?.value }
                                    setAnswers(temp);
                                    console.log(temp)
                                }} key={i}>
                                    <div style={{
                                        border: answers?.[questionKey] === opt?.value ? "1px solid var(--primary)" : "1px solid var(--dark4)",
                                        background: answers?.[questionKey] === opt?.value ? "var(--primary)" : "var(--white)",
                                        height: "10px", width: "10px", borderRadius: "50%"
                                    }}></div>
                                    <div className="textSM w-500">{opt?.label}</div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="d-flex w100" style={{ gap: "1.25rem", marginTop: "1.25rem" }}>
                        {step > 1 && <button className="btnWhite w100 textMD" style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                            onClick={() => setStep(prev => prev - 1)} disabled={loading}>&lt; Previous</button>}
                        {step < questions?.length && <button className="btnBlack w100 textMD" style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                            onClick={() => setStep(prev => prev + 1)}>Next &gt;</button>}
                        {step === questions?.length && <button className="btnBlack w100 textMD" style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                            onClick={() => handleSubmit()} disabled={Object.keys(answers || {})?.length !== questions?.length}>
                            {loading ? <LoadingOutlined style={{ fontSize: '1rem', color: "var(--white)" }} spin /> : <span> Submit &gt;</span>}
                        </button>}
                    </div>
                </>}
            </Modal>
        </>
    )
}