import DashboardChartComponent from 'Components/DashboardChartComponent';
import DashboardTools from 'Components/DashboardTools';
import DashboardTopPerformers from 'Components/DashboardTopPerformers';
import { UpgradeCard } from 'Components/UpgradeCard';
import Head from 'Layout/Head';
import { useGetAssetType } from 'Libs/utils';
import AlphaAnalysis from "Pages/sharpelyFeatures/AlphaAnalysis";
import Magicboard from "Pages/sharpelyFeatures/Magicboard";
import React from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { CardHeader } from 'Components/atoms/SmallComponents';

const MFDashboard = (props) => {

  const navigate = useNavigate()
  const type = useGetAssetType()
  const accessLevelData = useSelector((state) => state?.accessLevel?.value);

  const dash_items = [
    {
      "UID": 9,
      "ClientId": 1,
      "DashboardId": 3,
      "InstrumentType": 4,
      "WidgetId": 9,
      "FullWidgetOrder": 0,
      "MiniWidgetOrder": 5,
      "IsVisible": 1,
      "IsDeleted": 0,
      "CreatedOn": "2024-04-12T14:03:57",
      "Type": "Mini Widget",
      "Key": "TOP_INDEX_FUNDS",
      "Name": "Top Index Funds",
      "Category": "TOP_MF",
      "CategoryName": "Mutual Fund analysis",
      "Description": "Get the best perfroming Index Funds across categories and timeframes",
      "IsDefaultPref1": 0,
      "IsDefaultPref2": 0,
      "IsDefaultPref3": 0,
      "IsDefaultPref4": 0,
      "IsDefaultPref5": 0
    },
    {
      "UID": 7,
      "ClientId": 1,
      "DashboardId": 3,
      "InstrumentType": 4,
      "WidgetId": 7,
      "FullWidgetOrder": 0,
      "MiniWidgetOrder": 6,
      "IsVisible": 1,
      "IsDeleted": 0,
      "CreatedOn": "2024-04-12T14:03:57",
      "Type": "Mini Widget",
      "Key": "TOP_MF",
      "Name": "Top Mutual Funds",
      "Category": "TOP_MF",
      "CategoryName": "Mutual Fund analysis",
      "Description": "Get the best perfroming Mutual Funds across categories and timeframes",
      "IsDefaultPref1": 0,
      "IsDefaultPref2": 0,
      "IsDefaultPref3": 0,
      "IsDefaultPref4": 0,
      "IsDefaultPref5": 0
    },
    {
      "UID": 23,
      "ClientId": 1,
      "DashboardId": 3,
      "InstrumentType": 4,
      "WidgetId": 23,
      "FullWidgetOrder": 10,
      "MiniWidgetOrder": 0,
      "IsVisible": 1,
      "IsDeleted": 0,
      "CreatedOn": "2024-04-12T14:03:57",
      "Type": "Full Widget",
      "Key": "MF_WIZARD",
      "Name": "MF Wizard",
      "Category": "MF",
      "CategoryName": "Mutual Fund analysis",
      "Description": "A birds eye view of everything important to analyse a Mutual Fund",
      "IsDefaultPref1": 0,
      "IsDefaultPref2": 0,
      "IsDefaultPref3": 0,
      "IsDefaultPref4": 0,
      "IsDefaultPref5": 0
    },
    {
      "UID": 26,
      "ClientId": 1,
      "DashboardId": 3,
      "InstrumentType": 4,
      "WidgetId": 26,
      "FullWidgetOrder": 11,
      "MiniWidgetOrder": 0,
      "IsVisible": 1,
      "IsDeleted": 0,
      "CreatedOn": "2024-04-12T14:03:57",
      "Type": "Full Widget",
      "Key": "MF_ALPHA_ANALYSIS",
      "Name": "MF Alpha analysis",
      "Category": "MF",
      "CategoryName": "Mutual Fund analysis",
      "Description": "Your one-stop shop for analyzing and comparing funds with different benchmarks.",
      "IsDefaultPref1": 0,
      "IsDefaultPref2": 0,
      "IsDefaultPref3": 0,
      "IsDefaultPref4": 0,
      "IsDefaultPref5": 0
    },
    {
      "UID": 22,
      "ClientId": 1,
      "DashboardId": 3,
      "InstrumentType": 4,
      "WidgetId": 22,
      "FullWidgetOrder": 12,
      "MiniWidgetOrder": 0,
      "IsVisible": 1,
      "IsDeleted": 0,
      "CreatedOn": "2024-04-12T14:03:57",
      "Type": "Full Widget",
      "Key": "MF_MAGICBOARD",
      "Name": "MF Magicboard",
      "Category": "MAGICBOARD",
      "CategoryName": "Mutual Fund analysis",
      "Description": "Quickly filter Mutual Funds based on their category and benchmark and compare across multiple metrics in a single table",
      "IsDefaultPref1": 0,
      "IsDefaultPref2": 0,
      "IsDefaultPref3": 0,
      "IsDefaultPref4": 0,
      "IsDefaultPref5": 0
    }
  ]
  return (
    <React.Fragment>
      <Head title="Mutual Fund Screener | Tracker, Growth, & Analysis | sharpely Fund Dashboard" description="Explore high-return mutual funds and multi-asset allocation options with our advanced mutual fund research tools. Analyze mutual fund performance metrics to make informed investment decisions and maximize your portfolio's potential." keywords="High return mutual funds, Multi asset allocation funds, Mutual fund tracker, Mutual Fund performance analysis, Investment reporting, Mutual fund Analysis tools, Mutual fund research tools, Mutual Fund screener tool, Mutual Fund performance metrics, Mutual fund Market Research Tools, Mutual fund Market Research Websites, Top high return mutual funds, top multi asset allocation funds, Best mutual fund tracker platform, Best mutual fund performance analysis platform, Top investment reporting management, Best mutual fund analysis tools, Top mutual fund research tools, Best mutual fund screener tool, Top mutual fund performance metrics, Top mutual fund market research tools, Best mutual fund market research websites, top high return mutual funds in india, Top asset allocation funds in india, Best mutual fund tracker platform in india, Best mutual fund performance analysis platform in india, Top investment reporting management in india, Best mutual fund analysis tools india, Top mutual fund research tools india, Best mutual fund screener tool india, Top mutual fund performance metrics india, Top mutual fund market research tools india, Best mutual fund market research websites in india" />

      <div className='mainContainer'>
        <div className='leftContainer'>
          <DashboardChartComponent isWidget type={type} header={dash_items[2]?.Name} subHeading={dash_items[2]?.Description} />
          <Magicboard defaultType={type} isWidget={true} widgetItem={dash_items[4]} />
          <AlphaAnalysis defaultType={type} isWidget={true} widgetItem={dash_items[3]} />
        </div>
        <div className='rightContainer'>
          <UpgradeCard />
          <DashboardTools defInstType={type.value} hideInsTypeTabs isBordered={false} showToolHeading={true} />
          <DashboardTopPerformers header={<CardHeader heading={'Top Mutual Funds'} headingType={2} />} type={type} />
          <DashboardTopPerformers header={<CardHeader heading={'Top Index Funds'} headingType={2} />} type={type} filter={[['is_index_fund', 1]]} />
        </div>
      </div>
    </React.Fragment >
  );
};

export default MFDashboard;
