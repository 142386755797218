import { useSelector } from "react-redux";
import { CurrencyFormat } from "./CurrencyFormat";

const Slider52Week = ({ high, low, fundData, symbol, type, mfValue = null, pageType = '', format, style = {} }) => {

    // console.log(fundData, 'slider')
    var stockprices = useSelector((state) => state?.stockprices?.value)
    var prevstockprices = useSelector((state) => state?.prevStockPrices?.value)
    var currentLTP = "-"
    var currentSymbolAllData = stockprices === "close" ? prevstockprices?.[fundData?.gdf_symbol] : (stockprices?.[fundData?.gdf_symbol] || prevstockprices?.[fundData?.gdf_symbol])
    currentLTP = currentSymbolAllData?.LastTradePrice
    var dayHigh = currentSymbolAllData?.High || null;
    var dayLow = currentSymbolAllData?.Low || null;
    // console.log(dayHigh, dayLow)
    if (currentSymbolAllData == undefined) {
        let ele = fundData
        currentLTP = ele?.price || 0
    }
    var value = Number(Number(type === 2 ? mfValue : currentLTP)?.toFixed(2));
    const currHigh = dayHigh !== null ? Math.max(high, type === 2 ? value : dayHigh) : high
    const currLow = dayLow !== null ? Math.min(low, type === 2 ? value : dayLow) : low
    // console.log('high', high, 'currhigh', currHigh, 'low', low, 'currlow', currLow, 'val', value)
    let percentage = ((value - currLow) / (currHigh - currLow)) * 100;
    if (percentage > 100) {
        percentage = 100;
    }
    if (value) {
        return (
            <div style={{ width: '100%', marginBottom: format === 'railTrack' ? "0" : '1rem', ...style }}>
                {pageType !== 'stockDashboard' && <div className="textXS w-500 black text-center" style={{ marginBottom: '0.5rem' }}>
                    52 week high/low</div>}
                <div className='d-flex align-items-center justify-content-between textXS w-500 black' style={{ gap: "0.25rem" }}>
                    {pageType !== 'stockDashboard' && <div className='d-flex flex-column' style={{ flex: 1, textAlign: 'right' }}>
                        <div style={{ whiteSpace: "nowrap" }}>{CurrencyFormat(currLow, 'long', 2)}</div>
                    </div>}
                    {format === 'railTrack' ? <div className='week52sliderRailTrack' style={{ '--width': percentage + '%', width: '100%', marginLeft: '0.5rem', marginRight: '0.5rem' }}>
                        {/* <Tooltip title={<span className='textXS w-500'>{CurrencyFormat(value,'short',1)}</span>} arrow={false}  */}
                        {/* // style={{userSelect:"none"}} > */}
                        <div className='week52sliderRail'></div>
                        {/* </Tooltip> */}
                    </div> :
                        <div className='week52slider' style={{ '--left': percentage + '%', width: '80px', '--color': "var(--primary)" }}>
                            <div className='week52slidertrack'>
                            </div>
                            <div className='week52sliderpoint'>
                            </div>
                        </div>}
                    {pageType !== 'stockDashboard' && <div className='d-flex flex-column' style={{ flex: 1 }}>
                        <div style={{ whiteSpace: "nowrap" }}>{CurrencyFormat(currHigh, 'long', 2)}</div>
                    </div>}
                </div>
            </div>
        )
    }
    else return <></>
}

export default Slider52Week