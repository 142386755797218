import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import Calculators from "Components/Calculators";
import BrokerRedirectPage from "Components/SharpelyGateway/BrokerRedirectPage";
import TradingViewChart from "Components/TradingView/TradingViewChart";
import Blog from 'Pages/Blogs/Blog';
import BlogCategory from 'Pages/Blogs/BlogCategory';
import BuildCustomPortfolio from 'Pages/CustomPortfolio/AdvCustomStrategy';
import FundDetail from 'Pages/DetailPage/FundDetail';
import StockDetail from 'Pages/DetailPage/StockDetail';
import AllModels from "Pages/FactorModel/AllModels";
import FactorModel from 'Pages/FactorModel/FactorModel';
import GenerateSitemap from "Pages/GenerateSitemap";
import IndexDetail from 'Pages/Indices/IndexDetails';
import IndexList from 'Pages/Indices/IndexList';
import Blogs from 'Pages/KnowledgeBase/Blogs';
import CategoryPage from 'Pages/KnowledgeBase/CategoryPage';
import KnowledgeBase from 'Pages/KnowledgeBase/KnowledgeBase';
import Resource from 'Pages/KnowledgeBase/Resource';
import SubCategoryPage from 'Pages/KnowledgeBase/SubCategoryPage';
import LandingPage from 'Pages/LandingPage/LandingPage';
import ScreenLanding from 'Pages/LandingPage/ScreenLanding';
import StockReportLanding from 'Pages/LandingPage/StockReportLanding';
import StrategyLanding from 'Pages/LandingPage/StrategyLanding';
import AboutUs from 'Pages/MarketingPages/AboutUs';
import ContactUs from "Pages/MarketingPages/ContactUs";
import Pricing from 'Pages/MarketingPages/Pricing';
import PrivacyPolicy from 'Pages/MarketingPages/PrivacyPolicy';
import RefundAndCancellation from 'Pages/MarketingPages/RefundAndCancellation';
import TermsAndConditions from 'Pages/MarketingPages/TermsAndConditions';
import PageNotFound from 'Pages/PageNotFound';
import Portfolio from 'Pages/Portfolio/Portfolio';
import SipRebalanceScreen from "Pages/Portfolio/SipRebalanceScreen";
import AllScreeners from "Pages/Screener/AllScreeners";
import ScreenerNew from "Pages/Screener/NewScreener";
import Screener from 'Pages/Screener/Screener';
import StockSectors from 'Pages/Sectors/StockSectors';
import StockSectorsDetail from 'Pages/Sectors/StockSectorsDetails';
import AllStrategies from "Pages/Strategies/AllStrategies";
import StrategyDetail from "Pages/Strategies/StrategyDetail";
import AppQRPage from 'Pages/UserPages/AppQRPage';
import Login from 'Pages/UserPages/Login';
import PaymentStatus from 'Pages/UserPages/PaymentStatus';
import Profile from 'Pages/UserPages/Profile';
import PublisherProfile from 'Pages/UserPages/PublisherProfile';
import Register from 'Pages/UserPages/Register';
import UserOnBoarding from 'Pages/UserPages/UserOnBoarding';
import AllChecklists from 'Pages/sharpelyFeatures/AllChecklist';
import AlphaAnalysis from 'Pages/sharpelyFeatures/AlphaAnalysis';
import Checklist from 'Pages/sharpelyFeatures/Checklist';
import Compare from 'Pages/sharpelyFeatures/Compare';
import ETFDashboard from 'Pages/sharpelyFeatures/ETFDashboard';
import EarningsDashboard from 'Pages/sharpelyFeatures/EarningsDashboard';
import EventCalendar from 'Pages/sharpelyFeatures/EventCalendar';
import InsiderTrades from 'Pages/sharpelyFeatures/InsiderTrades';
import MFDashboard from 'Pages/sharpelyFeatures/MFDashboard';
import Magicboard from 'Pages/sharpelyFeatures/Magicboard';
import MainDashboard from "Pages/sharpelyFeatures/MainDashboard";
import MarketWatch from 'Pages/sharpelyFeatures/MarketWatch';
import SectorRotationChart from "Pages/sharpelyFeatures/SectorRotationChart";
import StockStyleBoxes from 'Pages/sharpelyFeatures/StyleBox';
import WatchlistFeed from 'Pages/sharpelyFeatures/WatchlistFeed';
import List from 'Pages/sharpelyFeatures/WatchlistPage';
import NFOTracker from 'Pages/sharpelyFeatures/NFOTracker';
import { Suspense } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "./ProtectedRoutes";

const Pages = (props) => {
  const paths = ['/etfs', '/mutual-funds', '/stocks']
  const renderMultiRoutes = ({ element, path, ...rest }) =>
    paths.map((p) => <Route key={p + path} path={p + path} {...rest} element={<Suspense fallback={<div className='loaderContainer' >
      <ActivityLoaderMintbox showQuote={true} />
    </div>}>{element}</Suspense>} />);

  return (
    <Routes>
      <Route path="/" element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><LandingPage /></Suspense>} />
      <Route path='/campaign/stock-report' element={<Suspense fallback={<div className='loaderContainer' ><ActivityLoaderMintbox showQuote={true} /></div>}>
        <StockReportLanding /></Suspense>} />
      <Route path='/campaign/strategy' element={<Suspense fallback={<div className='loaderContainer' ><ActivityLoaderMintbox showQuote={true} /></div>}>
        <StrategyLanding /></Suspense>} />
      <Route path='/campaign/screener' element={<Suspense fallback={<div className='loaderContainer' ><ActivityLoaderMintbox showQuote={true} /></div>}>
        <ScreenLanding /></Suspense>} />
      <Route path='/about-us' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><AboutUs /></Suspense>} />
      <Route path='/pricing' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><Pricing /></Suspense>} />
      <Route path='/contact-us' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ContactUs /></Suspense>} />
      <Route path='/privacy-policy' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><PrivacyPolicy /></Suspense>} />
      <Route path='/terms-and-conditions' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><TermsAndConditions /></Suspense>} />
      <Route path='/refund-and-cancellation-policy' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><RefundAndCancellation /></Suspense>} />
      <Route path="/login" element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><Login /></Suspense>} />
      <Route path="/signup" element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><Register /></Suspense>} />
      <Route path="/user-onboarding" element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><UserOnBoarding /></Suspense>} />
      <Route path="/broker-page" element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><BrokerRedirectPage /></Suspense>} />
      <Route path={'/etfs/dashboard'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} ><ETFDashboard /></ProtectedRoutes></Suspense>} />
      <Route path={'/mutual-funds/dashboard'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} ><MFDashboard /></ProtectedRoutes></Suspense>} />
      <Route path={'/stocks/dashboard'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} ><MainDashboard /></ProtectedRoutes></Suspense>} />
      <Route path={"/technical-chart"} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} >
          <TradingViewChart />
        </ProtectedRoutes></Suspense>} />
      <Route path='/payment-success/:planType/:paymentOption/:frequency/:isTrial' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={1} ><PaymentStatus /></ProtectedRoutes></Suspense>} />
      <Route path='/payment-failed/:planType/:paymentOption/:frequency/:isTrial' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={1} ><PaymentStatus isSuccess={false} /></ProtectedRoutes></Suspense>} />
      <Route path={'/stocks/all-checklists'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><AllChecklists /></Suspense>} />
      <Route path={'/stocks/checklist'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><Checklist /></Suspense>} />
      <Route path={'/etfs/screener'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><Screener /></Suspense>} />
      <Route path={'/mutual-funds/screener'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><Screener /></Suspense>} />
      <Route path={'/etfs/magicboard'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} ><Magicboard /></ProtectedRoutes></Suspense>} />
      <Route path={'/etfs/calculators/:calculator'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} ><Calculators /></ProtectedRoutes></Suspense>} />
      <Route path={'/mutual-funds/magicboard'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} ><Magicboard /></ProtectedRoutes></Suspense>} />
      <Route path={'/mutual-funds/calculators/:calculator'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} ><Calculators /></ProtectedRoutes></Suspense>} />
      <Route path={'/strategy-detail/:name/:type/:id'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} >
          <StrategyDetail />
        </ProtectedRoutes></Suspense>} />

      <Route path={'/strategies'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} >
          <AllStrategies />
        </ProtectedRoutes></Suspense>} />

      <Route path={'/screens'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} >
          <AllScreeners />
        </ProtectedRoutes></Suspense>} />

      <Route path={'/factor-models'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} >
          <AllModels />
        </ProtectedRoutes></Suspense>} />

      {renderMultiRoutes({
        path: '/alpha-analysis',
        element: <ProtectedRoutes
          reqAccessLevel={0}>
          <AlphaAnalysis />
        </ProtectedRoutes>,
      })}

      <Route path={'/etfs/fund-detail/:name/:code'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} >
          <FundDetail />
        </ProtectedRoutes></Suspense>} />
      <Route path={'/mutual-funds/fund-detail/:name/:code'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} >
          <FundDetail />
        </ProtectedRoutes></Suspense>} />
      <Route path={'/stocks/stock-detail/:name/:code'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} >
          <StockDetail />
        </ProtectedRoutes></Suspense>} />
      <Route path={'/stocks/styleBox'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes
        reqAccessLevel={0}>
          <StockStyleBoxes /></ProtectedRoutes></Suspense>} />
      <Route path={'/stocks/sectors'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes
        reqAccessLevel={0} > <StockSectors /></ProtectedRoutes></Suspense>} />
      <Route path={'/stocks/earnings-dashboard'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes
        reqAccessLevel={0} >
          <EarningsDashboard /></ProtectedRoutes></Suspense>} />
      <Route path={'/stocks/sector-details'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes
        reqAccessLevel={0} > <StockSectorsDetail /></ProtectedRoutes></Suspense>} />
      <Route path={'/stocks/factor-model'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes
        reqAccessLevel={0}>
          <FactorModel />
        </ProtectedRoutes></Suspense>} />
      <Route path='/indices' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} ><IndexList /></ProtectedRoutes></Suspense>} />
      <Route path='/indices/index-detail/:name/:code' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} ><IndexDetail /></ProtectedRoutes></Suspense>} />
      {renderMultiRoutes({
        path: '/build-custom-strategy',
        element: <ProtectedRoutes reqAccessLevel={0}
        > <BuildCustomPortfolio /> </ProtectedRoutes>,
      })}
      <Route path={'/sector-rotation-chart'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} ><SectorRotationChart /></ProtectedRoutes></Suspense>} />
      <Route path={'/nfo-tracker'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} ><NFOTracker /></ProtectedRoutes></Suspense>} />
      <Route path={'/watchlist'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={1} redirectRoute='/login' ><List /></ProtectedRoutes></Suspense>} />
      <Route path={'/market-watch'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} ><MarketWatch /></ProtectedRoutes></Suspense>} />
      {/* all protected routes where userdata is must */}
      <Route path={'/investments'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} redirectRoute="/login" >
          <Portfolio />
        </ProtectedRoutes></Suspense>} />
      <Route path={'/scheduled-order/:orderType/:encryptedStr'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={1} redirectRoute="/login">
          <SipRebalanceScreen />
        </ProtectedRoutes></Suspense>} />
      <Route path={'/event-calendar'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} ><EventCalendar /></ProtectedRoutes></Suspense>} />
      <Route path={'/stocks/watchlist-feed'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} ><WatchlistFeed /></ProtectedRoutes></Suspense>} />
      <Route path={'/user-profile'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={1} redirectRoute="/login" ><Profile /></ProtectedRoutes></Suspense>} />

      <Route path="/insider-trades" element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} ><InsiderTrades /></ProtectedRoutes></Suspense>} />

      <Route path={'/app/qr-link'} element={<AppQRPage />} />
      {renderMultiRoutes({
        path: '/compare',
        element: <ProtectedRoutes reqAccessLevel={0} redirectRoute="/login" ><Compare /></ProtectedRoutes>,
      })}

      {/* <Route path={'/algo'} element={<Suspense fallback={<div className='loaderContainer' >
            <ActivityLoaderMintbox showQuote={true} />
        </div>}><ProtectedRoutes reqAccessLevel={0} ><Strategy1 /></ProtectedRoutes></Suspense>} />
      <Route path={'/algo2'} element={<Suspense fallback={<div className='loaderContainer' >
            <ActivityLoaderMintbox showQuote={true} />
        </div>}><ProtectedRoutes reqAccessLevel={0} ><Strategy2 /></ProtectedRoutes></Suspense>} /> */}

      <Route path={'/publisher-profile'} element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><PublisherProfile /></Suspense>} />
      {/* All routes related to knowledge base */}
      <Route path='/knowledge-base' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><KnowledgeBase /></Suspense>} />
      <Route path='/blog' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><Blogs /></Suspense>} />
      <Route path='/knowledge-base/:category' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><CategoryPage /></Suspense>} />
      <Route path='/knowledge-base/:category/:subCategory' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><SubCategoryPage /></Suspense>} />
      <Route path='/knowledge-base/:category/:subCategory/:blog' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><Resource /></Suspense>} />
      <Route path='/knowledge-base/:category/-/:blog' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><Resource /></Suspense>} />
      <Route path='/blog/:blog' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><Blog /></Suspense>} />
      <Route path='/blog/category/:category' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><BlogCategory /></Suspense>} />
      <Route path='/generate-sitemaps/download' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><GenerateSitemap /></Suspense>} />
      <Route path='/stocks/super-screener' element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><ProtectedRoutes reqAccessLevel={0} ><ScreenerNew /></ProtectedRoutes></Suspense>} />
      {/* <Route path='/stocks/super-screener/:screenName/:screenId' element={<Suspense fallback={<div className='loaderContainer' >
            <ActivityLoaderMintbox showQuote={true} />
        </div>}><ProtectedRoutes reqAccessLevel={4} ><ScreenerNew /></ProtectedRoutes></Suspense>} /> */}

      {/* Redirect urls for indexed Pages */}
      <Route path={'/stocks/screener'} element={<Navigate to={'/super-screener'} />} />
      <Route path={'/curated-baskets'} element={<Navigate to="/strategies" />} />
      <Route path="*" element={<Suspense fallback={<div className='loaderContainer' >
        <ActivityLoaderMintbox showQuote={true} />
      </div>}><PageNotFound /></Suspense>} />
    </Routes>
  );
};
export default Pages;