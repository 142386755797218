import { DataService } from "./DataService";
import { DataServiceOLDAPI } from "./DataServiceOLDAPI";

const MINTBOX_CLIENT_ID = "Li2L9VO1eawEbsgLrHdpZjhmUdW6N8Cm";

const FamilyMemberAPIs = {
  addMember: async (id, email) =>
    DataService.post(`/dashboard/addMember?clientId=${id}&email=${email}`),
  removeMember: async (id, email) =>
    DataService.post(`/dashboard/removeMember?clientId=${id}&email=${email}`),
};

export default {
  FamilyMemberAPIs,
  addBankAccount: async (data) =>
    DataService.post("/SignUp/addBankAccount", data),
  updateBankAccount: async (data) =>
    DataService.post("/SignUp/updateBankAccount", data),

  getAccessToken: async () => {
    return DataService.get("/Auth/getToken?clientId=" + MINTBOX_CLIENT_ID);
  },

  getClient: async ({ id }) => {
    return DataService.get("/Dashboard/getClient?clientid=" + id);
  },

  emailExist: async (id, data, optionalHeader) => {
    return DataService.get(
      "/SignUp/EmailExists?email=" + id,
      data,
      optionalHeader
    );
  },

  ClientExists: async ({ type, value }) => {
    return DataService.get(
      "/SignUp/ClientExists?type=" + type + "&value=" + value
    );
  },

  loginwithPass: async (data) => {
    return DataService.post("/SignUp/login", data);
  },
  validatePin: async (data) => {
    return DataService.post("/SignUp/validatePIN", data);
  },

  sendOTP: async (type, value, appId = 1, displayName = 'Customer') => {
    return DataService.post(
      "/SignUp/generateOTP?type=" + type + "&value=" + value + '&displayName=' + displayName + "&appId=" + appId
    );
  },

  login: async ({ id, displayName, appId = 1 }) => {
    return DataService.post(
      `/SignUp/login?clientId=${id}&appId=${appId}&displayName=${displayName}`
    );
  },

  forgotPass: async (data) => {
    return DataService.post("/SignUp/generatePasswordLink", data);
  },

  getRiskScore: async (data) => {
    return DataService.post("/Dashboard/getRiskScore", data);
  },

  updateGoal: async (data) => {
    return DataService.post("/Dashboard/addGoal", data);
  },

  getDashboardData: async () => {
    return DataService.get(
      "/Dashboard/getDashboardContent"
    );
  },

  getRecentlyViewed: async (id) => {
    return DataService.get(
      "/Dashboard/getRecentlyViewed?clientId=" + id
    );
  },


  getUserScreeners: async ({ id, type }) => {
    return DataService.get(
      `/Dashboard/getScreeners?clientId=${id}&instrumentType=${type}`
    );
  },

  getUserStrategies: async ({ id }) => {
    return DataService.get(
      "/Dashboard/getWatchListInfo?clientId=" + id
    );
  },

  getUserSignal: async ({ id }) => {
    return DataService.get(
      "/Dashboard/getSignals?clientId=" + id
    );
  },

  getWatchList: async ({ id }) => {
    return DataService.get(
      "/Dashboard/getWatchListInfo?clientId=" + id
    );
  },

  updatePortfolioAll: async () => {
    return DataService.get(
      "/Broker/updatePortfolioAll"
    );
  },

  updateWatchList: async (data) => {
    if (data.isActive === 1)
      return DataService.post("/Dashboard/addToWatchlist", data);
    else
      return DataService.post("/Dashboard/removeFromWatchlist", data);
  },


  updateRecentlyViewed: async (data) => {
    return DataService.post("/Dashboard/updateRecentlyViewed", data);
  },

  getSampleData: async () => {
    return DataService.post("/bse/getNiftyIndices?ticker=NIFTY 50")
  },

  getPortfolioHoldingsData: async (id, ifaid, isFresh) => {
    return DataService.get(
      `/portfolio/getPortfolioHoldings?clientId=${id}&ifaId=${ifaid}&isFresh=${isFresh}`
    );
  },

  getPortfolioScheduledOrders: async (id, ifaid, isFresh) => {
    return DataService.get(
      `/dashboard/getScheduledOrders?clientId=${id}`
    );
  },

  getPortfolioSnapshot: async (id, ifaid, isFresh) => {
    return DataService.get(
      `/portfolio/getPortfolioSnapshot?clientId=${id}&ifaId=${ifaid}&isFresh=${isFresh}`
    );
  },

  getPortfolioXIRR: async (id, isFresh) => {
    return DataService.get(
      `/portfolio/getportfolioXIRRv1?clientId=${id}&isFresh=${isFresh}`
    );
  },

  getPortfolioPerformance: async (id, isFresh) => {
    return DataService.get(
      `/portfolio/getPortfolioPerformancev1?clientId=${id}&isFresh=${isFresh}`

    );
  },

  getPortfolioAsset: async (id, isFresh) => {
    return DataService.get(
      `/portfolio/getPortfolioAssets?clientId=${id}&isFresh=${isFresh}`

    );
  },

  getPortfolioInstruments: async (id, isFresh) => {
    return DataService.get(
      `/portfolio/getPortfolioInstruments?clientId=${id}&isFresh=${isFresh}`

    );
  },

  getPortfolioTransactions: async (id, isFresh) => {
    return DataService.get(
      `/portfolio/getTransactions?clientId=${id}&isFresh=${isFresh}`

    );
  },

  getMintboxFunds: async (userid, mintboxid) => {
    return DataService.get(`/portfolio/getMintboxPortfolio?clientId=${userid}&mintboxId=${mintboxid}`)
  },

  getFundsData: async () => {
    return DataService.get(
      "/dashboard/getFundsMaster?appId=" + MINTBOX_CLIENT_ID
    );
  },

  getFundsDetails: async (id) => {
    return DataService.get(
      "/dashboard/getFundByFundCode?fundCode=" + id
    );
  },


  getEtfDetails: async (id) => {
    return DataService.get(
      "/dashboard/getETFInfoBySymbol?symbol=" + id
    );
  },

  getPeerReturns: async (id) => {
    return DataService.get(
      "/dashboard/getPeerReturnsByFundCode?fundCode=" + id
    );
  },

  getTopHoldings: async (id) => {
    return DataService.get(
      "/dashboard/getCompaniesByFundCode?fundCode=" + id
    );
  },

  getSectorHoldings: async (id) => {
    return DataService.get(
      "/dashboard/getFundSectorsByFundCode?fundCode=" + id
    );
  },

  getEtfPeer: async (id) => {
    return DataService.get(
      "/dashboard/getETFPeers?symbol=" + id
    );
  },

  getRollingReturns: async (id) => {
    return DataService.get(
      "/dashboard/getRollingReturnsByFundCode?fundCode=" + id
    );
  },

  getETFs: async () => {
    return DataService.get(
      "/Dashboard/getETFs"
    );
  },

  getStocks: async () => {
    return DataService.get(
      "/Dashboard/getStocks"
    );
  },

  getETFsById: async (id) => {
    return DataService.get(
      "/Dashboard/getETFs?symbols=" + id
    );
  },


  getXirr: async (data) => {
    return DataService.post(
      "/Dashboard/getXIRR", data
    );
  },

  getMintboxes: async ({ clientId }) => {
    return DataService.get(
      "/Mintbox/getAllMintboxesv1?clientId=" + clientId
    );
  },

  getMintboxData: async ({ id }) => {
    return DataService.get(
      `/Mintbox/getMintboxById?mintboxId=${id}`
    );
  },

  getMintboxConstituentsById: async (id) => {
    return DataService.get(
      "/Mintbox/getMintboxConstituentsById?mintboxId=" + id
    );
  },

  getMintboxExecParams: async (id) => {
    return DataService.get(
      "/Mintbox/getMintboxExecParams?mintboxId=" + id
    );
  },

  getPortfolioExecParams: async (id) => {
    return DataService.get(
      "/Order/getPortfolioExecParams" + id
    );
  },


  getETFQuantities: async (data) => {
    return DataService.post(
      "/StrategyAutomation/getFundQuantity", data
    );
  },

  createMintbox: async (data) => {
    return DataService.post("/Mintbox/insertMintbox", data)
  },

  savePass: async (data) => {
    return DataService.post("/SignUp/savePassword", data);
  },

  createUser: async (data) => {
    return DataService.post("/SignUp/openAccount", data);
  },

  uploadDoc: async (id, data) => {
    return DataService.postImg("/SignUp/saveDocument?id=" + id, data);
  },

  enableMf: async (data) => {
    return DataService.post("/Dashboard/activateMutualFunds?email=" + data);
  },

  completeprofile: async (data) => {
    return DataService.post("/SignUp/completeAccount?callBSE=True", data);
  },


  saveMFPIN: async (id, pin) => {
    return DataService.post(`/SignUp/savePin?clientId=${id}&pin=${pin}`);
  },



  checkkyc: async (data) => {
    return DataServiceOLDAPI.post("/Signzy/checkKYC", data);
  },

  verifyZip: async (id) => {
    return DataService.get("/SignUp/getInfoByZip?zip=" + id);
  },

  verifyifsc: async (id) => {
    return DataService.get("/SignUp/getBankByIFSC?ifsc=" + id);
  },

  getTaxStatus: async () => {
    return DataService.get("/bse/getTaxStaus");
  },

  getAnnualIncome: async () => {
    return DataService.get("/bse/getAnnualIncome");
  },

  getCountries: async () => {
    return DataService.get("/bse/getCountries");
  },

  getIdentificationType: async () => {
    return DataService.get("/bse/getIdentificationType");
  },

  getOccupation: async () => {
    return DataService.get("/bse/getOccupation");
  },

  getSourceofWealth: async () => {
    return DataService.get("/bse/getSourceofWealth");
  },

  getStates: async () => {
    return DataService.get("/bse/getStates");
  },

  getBanks: async () => {
    return DataService.get("/bse/getbanks");
  },

  createCustomGoal: async (data) => {
    return DataService.post("/Dashboard/addGoal", data);
  },
  addRisk: async (data) => {
    return DataService.post("/Dashboard/addRisk", data);
  },
  addEducationGoal: async (data) => {
    return DataService.post("/Dashboard/addEducationGoal", data);
  },
  addRetirmentGoal: async (data) => {
    return DataService.post("/Dashboard/addRetirementGoal", data);
  },


  // smallcase 

  getSmallcasetoken: async (authid) => {
    return DataService.get("/SmallCase/getToken?authId=" + authid);
  },


  saveSmallCaseAuthId: async (data) => {
    return DataService.post("/SmallCase/saveAuthId", data);
  },

  saveSmallCaseOrder: async (id, data) => {
    return DataService.post("/SmallCase/saveOrderResponse?clientId=" + id, data);
  },


  saveTransactionLogs: async (data) => {
    return DataService.post("/Feeds/logtransaction", data);
  },


  getSmallcaseTransactionId: async (data, authId = "", mintboxId = 0, goalId = 0) => {
    return DataService.post(`/SmallCase/getTransactionId?authId=${authId}&mintboxId=${mintboxId}&goalId=${goalId}`, data);
  },


  getHoldings: async (id, authId = "") => {
    return DataService.get(`/SmallCase/fetchHoldings?clientId=${id}&authId=${authId}`);
  },

  getGoalByID: async (goalID) => {
    return DataService.get(`/Dashboard/getGoal?goalId=${goalID}`);
  },

  getClientGoals: async (ClientId) => {
    return DataService.get(`/Dashboard/getClientGoals?ClientId=${ClientId}`);
  },

  updateHoldings: async (id, authId = "") => {
    return DataService.post(`/portfolio/updateHoldings?clientId=${id}`);
  },


  portfolioDecrypt: async (encryptedString) => {
    return DataService.get(`/Portfolio/decrypt?encryptedString=${encryptedString}`);
  },

  rebalanceMintbox: async (clientId, mintboxId, goalID) => {
    return DataService.get(`/mintbox/getMintboxRebalanceData?clientId=${clientId}&MintboxId=${mintboxId}&GoalId=${goalID}`);
  },

  getScheduledSIPData: async (clientId, mintboxId, goalID) => {
    return DataService.get(`/mintbox/getScheduledSIPData?clientId=${clientId}&MintboxId=${mintboxId}&GoalId=${goalID}`);
  },

  getScheduledETFSIPData: async (clientId, mintboxId, fundcode) => {
    return DataService.get(`/mintbox/getScheduledSIPData?clientId=${clientId}&MintboxId=${mintboxId}&fundCode=${fundcode}`);
  },


  updateScheduledOrders: async (data) => {
    return DataService.post(`/Order/updateScheduledOrder`, data);
  },

  rebalancePortfolio: async (data) => {
    return DataService.post(`/Order/rebalancePortfolio`, data);
  },
  updateGoalName: async (goalID, goalName) => {
    return DataService.post(`/Dashboard/updateGoalName?goalId=${goalID}&goalName=${goalName}`);
  },
  sendPushNotification: async (data) => {
    return DataService.post(`/Notification/sendPushNotification`, data);
  },
  getPushNotifications: async (id, st) => {
    return DataService.get(`/Notification/getNotificationsByClient?clientId=${id}&startDate=${st}`);
  },
  expireNotification: async ({ clientId, id }) => {
    return DataService.get(`/Notification/expireNotification?clientId=${clientId}&notificationId=${id}`);
  },
  getPortfolioReport: async (id) => {
    return DataService.get(`/Reports/getPortfolioReport?clientId=` + id);
  },

  getTransactionReport: async (id) => {
    return DataService.get(`/Reports/getTransactionReport?clientId=` + id);
  },

  deactivateAccount: async (id) => {
    return DataService.post(`/Signup/deleteAccount?clientId=` + id);
  },

  getRewardsStatus: async (id) => {
    return DataService.get(`/Dashboard/getRewardsStatus?clientId=` + id);
  },


  getMFCategories: async () => {
    return DataService.get(
      "/Dashboard/getFundCategories"
    );
  },

  getStockStyles: async () => {
    return DataService.get(
      "/Dashboard/getStockStyles"
    );
  },

  getStockSectorCLF: async () => {
    return DataService.get(
      "/Dashboard/getStockSectorCLF"
    );
  },

  getScreenerCategory: async (type) => {
    return DataService.get(
      "/Dashboard/getScreenerCategory?type=" + type
    );
  },
  getScreenerSubCategory: async (type) => {
    return DataService.get(
      "/Dashboard/getScreenerSubCategory?type=" + type
    );
  },
  getScreenerMetric: async (type) => {
    return DataService.get(
      "/Dashboard/getScreenerMetric?type=" + type
    );
  },

  getAMCs: async () => {
    return DataService.get(
      "/Dashboard/getAMCs"
    );
  },

  getMFScreenerFunds: async () => {
    return DataService.get(
      "/Dashboard/getScreenerFunds"
    );
  },


  callVrApi: async (data) => {
    return DataService.post(
      "/ValueResearch/updateTables", data
    );
  },


  createMFOrder: async (data) => {
    return DataService.post(
      "/BSE/order", data
    );
  },


  subscribeToBlack: async (data) => {
    return DataService.post(
      "/Razorpay/createSubscription", data
    );
  },

  getBenchmarks: async () => {
    return DataService.get(
      "/dashboard/getBenchmarks"
    );
  },


  saveScreener: async (data) => {
    return DataService.post(
      "/Dashboard/addScreener", data
    );
  },


  saveSignal: async (data) => {
    return DataService.post(
      "/Dashboard/addSignal", data
    );
  },

  saveScreenerRule: async (data) => {
    return DataService.post(
      "/Dashboard/addScreenerRule", data
    );
  },

  getStockPrevPrices: async (isFresh = false) => {
    return DataService.get(
      `/BSE/getRecentPrices?isFresh=${false}`
    );
  },

  getFundOneDayPrices: async ({ id, isIndex = 0 }) => {
    return DataService.get(
      `/BSE/getLivePricesBySymbol?ticker=${id}&isIndex=${isIndex}&isFresh=${true}`
    );
  },
  getGDFLivePrices: async ({ id, isIndex = 0 }) => {
    return DataService.get(
      `/BSE/getGDFLivePricesBySymbol?ticker=${id}&isIndex=${isIndex}&isFresh=${true}`
    );
  },
  //   updateComment: async (data) => {
  //     return DataService.post('/comment/update', data);
  //   },

  //   getComment: async (data) => {
  //     return DataService.post('/comment/listing', data);
  //   },

  //   getHomeBlockDiscover: async (data) => {
  //     return DataService.post('/block/home', data);
  //   }

  //knowledgebase
  getBlogCategories: async () => {
    return DataService.get(`/Content/getBlogCategories`);
  },
  getBlogSubCategories: async () => {
    return DataService.get(`/Content/getBlogSubCategories`);
  },
  getBlogs: async () => {
    return DataService.get(`/Content/getBlogs`)
  },
  readBlogFile: async (fileName) => {
    return DataService.get(`/AWS/readFile?bucketName=mintbox-assets&folderName=blogs&fileName=` + fileName + ".html")
  },
  readAWSFile: async (foldername, fileName) => {
    return DataService.get(`/AWS/readFile?bucketName=mintbox-assets&folderName=${foldername}&fileName=` + fileName + ".html")
  },
  getGuruScreens: async (instrumentType) => {
    return DataService.get('/dashboard/getGuruScreens?instrumentType=' + instrumentType)
  },
  getSharpelyScreens: async () => {
    return DataService.get(`/Dashboard/getSharpelyScreens`)
  },
  getScreenerRulesById: async (screenid, instrumentType) => {
    return DataService.get(`/dashboard/getScreenRulesById?screenId=${screenid}&instrumentType=` + instrumentType)
  },
  getSignalById: async (signalId, instrumentType) => {
    return DataService.get(`/dashboard/getSignalById?signalId=${signalId}`)
  },
  getAccessLevels: async () => {
    return DataService.get(`/Dashboard/getAccessLevels?isFresh=true`)
  },
  getAccessLevelsv1: async () => {
    return DataService.get(`/Dashboard/getAccessLevelsv1?isFresh=true`)
  },
  getEarlyAccess: async (user) => {
    return DataService.post(`/SignUp/earlyAccess`, user)
  },
  submitIssue: async (data) => {
    return DataService.post('/Dashboard/submitIssue', data)
  },
  subscribeBlog: async (email) => {
    return DataService.post(`/Content/subscribeBlog?email=${email}`)
  },
  editAccount: async (data) => {
    return DataService.post(`/SignUp/editAccount`, data)
  },
  // Razorpay
  createSubscription: async (data) => {
    return DataService.post(`/Payment/createSubscription`, data)
  },
  cancelSubscription: async (id) => {
    return DataService.post(`/Payment/cancelSubscription`, id)
  },
  getSubscriptionsByClientId: async (id) => {
    return DataService.get(`/Payment/getSubscriptionsByClientId?id=${id}`)
  },
  getAllPaymentRecords: async (email) => {
    return DataService.get(`/Payment/getAllPaymentRecords?email=${email}`)
  },
  getSubscriptionById: async (id) => {
    return DataService.get(`/Payment/getSubscriptionById?id=${id}`)
  },
  getPlans: async () => {
    return DataService.get(`/Payment/getPlans`)
  },
  getInvoice: async (id) => {
    return DataService.get(`/Payment/getInvoice?id=${id}`)
  },
  // Broker
  getProfile: async (data) => {
    return DataService.post(`/Broker/getProfile`, data)
  },
  validateSession: async (data) => {
    return DataService.post(`/Broker/validateSession`, data)
  },
  insertAuth: async (data) => {
    return DataService.post(`/Broker/insertAuth`, data)
  },
  getFunds: async (data) => {
    return DataService.post(`/Broker/getFunds`, data)
  },
  createOrder: async (data) => {
    return DataService.post(`/Broker/createOrder`, data)
  },
  createBasketOrder: async (data) => {
    return DataService.post(`/Broker/createBasketOrder`, data)
  },
  modifyOrder: async (data) => {
    return DataService.post(`/Broker/modifyOrder`, data)
  },
  cancelOrder: async (data) => {
    return DataService.post(`/Broker/cancelOrder`, data)
  },
  getOrderBook: async (data) => {
    return DataService.post(`/Broker/getOrderBook`, data)
  },
  getTradeBook: async (data) => {
    return DataService.post(`/Broker/getTradeBook`, data)
  },
  getHoldingBook: async (data) => {
    return DataService.post(`/Broker/getHolding`, data)
  },
  getPosition: async (data) => {
    return DataService.post(`/Broker/getPosition`, data)
  },
  generateConsent: async (data) => {
    return DataService.post(`/Broker/generateConsent`, data)
  },
  generateDhanToken: async (data) => {
    return DataService.post(`/Broker/generateDhanToken`, data)
  },
  updateAccess: async (data) => {
    return DataService.post(`/Dashboard/updateAccess`, data)
  },
  saveCustomColumns: async (data) => {
    return DataService.post(`/Dashboard/saveCustomColumns`, data)
  },
  getCustomColumns: async (id) => {
    return DataService.get(`/Dashboard/getCustomColumns?clientId=${id}`)
  },
  //payment
  getPPLink: async (data) => {
    return DataService.post(`/Payment/getPPLink`, data)
  },
  getPPPaymentStatus: async (id) => {
    return DataService.get(`/Payment/getPPPaymentStatus?transactionId=${id}`)
  },
  createPPSub: async (data) => {
    return DataService.post(`/Payment/createPPSub`, data)
  },
  cancelPPSubscription: async (data) => {
    return DataService.post(`/Payment/cancelPPSubscription`, data)
  },
  createPPAuthRequest: async (data) => {
    return DataService.post(`/Payment/createPPAuthRequest`, data)
  },
  getPPAuthReqStatus: async (id) => {
    return DataService.get(`/Payment/getPPAuthReqStatus?authRequestId=${id}`)
  },
  getPPAuthRequests: async (id) => {
    return DataService.get(`/Payment/getPPAuthRequests?subscriptionId=${id}`)
  },
  validateVPA: async (id) => {
    return DataService.get(`/Payment/validateVPA?upiId=${id}`)
  },
  getPPSubscriptions: async (id) => {
    return DataService.get(`/Payment/getPPSubscriptions?clientId=${id}`)
  },
  enrollFreeTrial: async ({ email, accessLevel }) => {
    return DataService.get(`/SignUp/enrollFreeTrial?email=${email}&accessLevel=${accessLevel}`)
  },
  disableFreeTrial: async ({ email, accessLevel }) => {
    return DataService.get(`/SignUp/disableFreeTrial?email=${email}&accessLevel=${accessLevel}`)
  },
  getAllWidgets: async () => {
    return DataService.get(`/Dashboard/getAllWidgets`)
  },
  getAllWidgetsv1: async () => {
    return DataService.get(`/Dashboard/getAllWidgetsv1`)
  },
  getDashboardByClientId: async (id) => {
    return DataService.get(`/Dashboard/getDashboardByClientId?clientId=${id}`)
  },
  insertWidget: async (data) => {
    return DataService.post(`/Dashboard/insertWidget`, data)
  },
  editWidget: async (data) => {
    return DataService.post(`/Dashboard/editWidget`, data)
  },
  deleteScreener: async (clientId, uid, type) => {
    return DataService.post(`/Dashboard/deleteScreener?clientId=${clientId}&uid=${uid}&instrumentType=${type}`)
  },
  deleteSignal: async (clientId, uid) => {
    return DataService.post(`/Dashboard/deleteSignal?clientId=${clientId}&signalId=${uid}`)
  },
  getChartDetails: async ({ clientId }) => {
    return DataService.get(`/Dashboard/getChartDetails?clientId=${clientId}`)
  },
  insUpChartDetails: async (data) => {
    return DataService.post(`/Dashboard/insUpChartDetails`, data)
  },
  getMarketStatus: async () => {
    return DataService.get(`/Dashboard/getMarketStatus`)
  },
  createBDOrder: async (data) => {
    return DataService.post(`/Payment/createBDOrder`, data)
  },
  createBDMandate: async (data) => {
    return DataService.post(`/Payment/createBDMandate`, data)
  },
  deleteBDMandate: async ({ clientId, mandateId }) => {
    return DataService.post(`/Payment/deleteBDMandate?clientId=${clientId}&mandateId=${mandateId}`)
  },
  createBDInvoice: async (data) => {
    return DataService.post(`/Payment/createBDInvoice`, data)
  },
  deleteMintbox: async (mintboxId) => {
    return DataService.post(`/Mintbox/deleteMintbox?mintboxId=${mintboxId}`)
  },
  getPPTrans: async (id, subId) => {
    return DataService.get(`/Payment/getPPTransactions?clientId=${id}&subscriptionId=${subId}`)
  },
  getAllSuperScreens: async (clientId) => {
    return DataService.get(`/Dashboard/getAllSuperScreens?clientId=${clientId}`)
  },
  addScreenv1: async (data) => {
    return DataService.post(`/Dashboard/addScreenv1`, data)
  },
  deleteScreenv1: async ({ screenId, clientId }) => {
    return DataService.post(`/Dashboard/deleteScreenv1?screenId=${screenId}&clientId=${clientId}`)
  },
  createRazorClient: async (data) => {
    return DataService.post(`/Payment/razor/createClient`, data);
  },
  getRazorPlans: async () => {
    return DataService.get(`/Payment/razor/getPlans`);
  },
  getRazorPlanById: async ({ id }) => {
    return DataService.get(`/Payment/razor/getPlanById?id=${id}`)
  },
  getRazorSubsriptions: async ({ skip = 0 }) => {
    return DataService.get(`/Payment/razor/getSubscriptions?skip=${skip}`)
  },
  getRazorSubscriptionById: async ({ id }) => {
    return DataService.get(`/Payment/razor/getSubscriptionById?id=${id}`)
  },
  getRazorSubscriptionsByCustId: async ({ id }) => {
    return DataService.get(`/Payment/razor/getSubscriptionsByCustId?id=${id}`)
  },
  createRazorSubscription: async (data) => {
    return DataService.post(`/Payment/razor/createSubscription`, data)
  },
  updateRazorSubscription: async ({ data, subId }) => {
    return DataService.post(`/Payment/razor/updateSubscription?subId=${subId}`, data)
  },
  cancelRazorSubscription: async ({ id }) => {
    return DataService.post(`/Payment/razor/cancelSubscription?id=${id}`)
  },
  pauseRazorSubscription: async ({ id }) => {
    return DataService.post(`/Payment/razor/pauseSubscription?id=${id}`)
  },
  resumeRazorSubscription: async ({ id }) => {
    return DataService.post(`/Payment/razor/resumeSubscription?id=${id}`)
  },
  createRazorPaymentLink: async (data) => {
    return DataService.post(`/Payment/razor/createPaymentLink`, data)
  },
  getAllRazorPaymentLinks: async () => {
    return DataService.get(`/Payment/razor/getAllPaymentLinks`)
  },
  getRazorPaymentLinkById: async ({ id }) => {
    return DataService.get(`/Payment/razor/getPaymentLinkById?id=${id}`)
  },
  sendRazorPaymentLinkReminder: async ({ id, medium }) => {
    return DataService.post(`/Payment/razor/sendPaymentLinkReminder?id=${id}&medium=${medium}`)
  },
  getWatchListByClientId: async ({ clientId }) => {
    return DataService.get(`/Dashboard/getWatchListByClientId?clientId=${clientId}`)
  },
  createWatchlist: async (data) => {
    return DataService.post(`/Dashboard/createWatchlist`, data)
  },
  addItemToWatchlist: async (data) => {
    return DataService.post(`/Dashboard/addItemToWatchlist`, data)
  },
  removeItemFromWatchlist: async (data) => {
    return DataService.post(`/Dashboard/removeItemFromWatchlist`, data);
  },
  removeWatchlist: async (data) => {
    return DataService.post(`/Dashboard/removeWatchlist`, data)
  },
  getActivePGSubs: async ({ clientId }) => {
    return DataService.get(`/SignUp/getActivePGSubs?clientId=${clientId}`)
  },


  getPublisherProfile: async ({ clientId }) => {
    return DataService.get(`/Dashboard/getPublisherProfile?clientId=${clientId}`)
  },

  createPublisherProfile: async (data) => {
    return DataService.post(`/Dashboard/createPublisherProfile`, data)
  },
  editWatchlistName: async ({ watchListId, name }) => {
    return DataService.post(`/Dashboard/editWatchlistName?watchListId=${watchListId}&name=${name}`)
  },
  uploadImg: async ({ fileName, data, folderName }) => {
    return DataService.postImg(`/AWS/updateToS3?filename=${fileName}&bucketName=mintbox-assets&folderName=${folderName}`, data)
  },

  getStockAlertById: async ({ clientId }) => {
    return DataService.get(`/Dashboard/getStockAlertById?clientId=${clientId}`)
  },

  insUpStockAlert: async ({ data }) => {
    return DataService.post(`/Dashboard/insUpStockAlert`, data)
  },

  deleteStockAlert: async ({ alertId }) => {
    return DataService.post(`/Dashboard/delStockAlert?alertId=${alertId}`)
  },

  addClonedStrategy: async ({ data }) => {
    return DataService.post(`/Dashboard/addClonedStrategy`, data)
  },

  addPublishedStrategy: async ({ data }) => {
    return DataService.post(`/Dashboard/addPublishedStrategy`, data)
  },

  getPublisherPayoutsById: async ({ publisherId }) => {
    return DataService.get(`/Dashboard/getPublisherPayoutsById?publisherId=${publisherId}`)
  },

  getCloneSetupById: async ({ clientId }) => {
    return DataService.get(`/Dashboard/getCloneSetupById?clientId=${clientId}`)
  },

  connectSSE: async () => {
    return DataService.get('/Data/connectForSSE')
  },
  disconnectSSE: async (data) => {
    return DataService.get('/Data/disconnectSSE?id=' + data)
  },
  getLivePricesV1: async (data) => {
    return DataService.get('/Data/getLivePricesV1', data)
  },
  verifyOTP: async ({ value, otp }) => {
    return DataService.post(`/SignUp/verifyOTP?value=${value}&otp=${otp}`)
  },
  verifySession: async ({ type, value, uniqueId }) => {
    return DataService.post(`/SignUp/verifySession?type=${type}&value=${value}&uniqueId=${uniqueId}`)
  },
  validateCoupon: async ({ couponCode }) => {
    return DataService.get(`/Payment/validateCoupon?couponCode=${couponCode}`);
  }
};

