import { changeModuleKey, toggleLoginModalParams, toggleSubModalParams, toggleSubscribeModal, toggleloginModal } from "Libs/redux/UserAuth/reduxSlice";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

export default function AccessControlledPage({ moduleKey = '', customAccessObj = {}, reqAccessLevel, isBeta = false, subModalParams = {}, isDefaultOpen = false }) {

    const user = useSelector((state) => state?.updateUserData?.value);
    const dispatch = useDispatch();
    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    let accessObj = moduleKey ? accessLevelData?.find((ele) => ele.ModuleKey === moduleKey) : customAccessObj;
    let modReqAccLevel = reqAccessLevel || 0;
    if (Object.keys(accessObj || {})?.length > 0) {
        modReqAccLevel = accessObj?.AccessLevel || 0;
    }
    const userAccessLevel = user?.Client?.[0]?.SharpelyAccessLevel || 0;
    const navigate = useNavigate();

    React.useEffect(() => {
        if ((userAccessLevel < modReqAccLevel) || isDefaultOpen) {
            if (userAccessLevel === 0) {
                dispatch(toggleLoginModalParams({
                    isExtClickDisabled: true
                }))
                dispatch(toggleloginModal(true));
            }
            else {
                dispatch(toggleSubModalParams({ isExtClickDisabled: true, customAccessObj: customAccessObj, isBeta: isBeta, ...subModalParams }))
                dispatch(changeModuleKey(moduleKey));
                dispatch(toggleSubscribeModal(true));
            }
        }
        return () => {
            dispatch(toggleloginModal(false));
            dispatch(toggleLoginModalParams({}));
            dispatch(changeModuleKey(''));
            dispatch(toggleSubscribeModal(false));
            dispatch(toggleSubModalParams({}))
        }
    }, [user]);

    return null;
}