import { baseURL } from 'Assets/Constant';
import { sharpelyLogoDark, sharpelyLogoLight } from 'Assets/images';
import { GlobalInstrumentSearch } from "Components/CustomComponents/GlobalInstrumentSearch";
import { useSubscribeMiddleware } from 'Components/SubscribeModal';
import { useIsMobile } from 'CustomHooks/useIsMobile';
import { toggleBasketCreateModal } from 'Libs/redux/Modals/reduxSlice';
import { makeid } from 'Libs/utils';
import React from 'react';
import { FaArrowCircleRight } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ReactTyped } from "react-typed";

export const TopSection = ({ sectionType = 1 }) => {
    /*
        Section type -->
        1 - Main Marketing page
        2 - Stock Report Campaign
        3 - Strategy Campaign
    */
    const isMobile = useIsMobile();
    const subscribeMiddle = useSubscribeMiddleware();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleScroll = () => {
        const s = document.getElementById('sharpelyHeader')
        let layoutHeader = s.querySelector('.ant-layout-header');
        let headerContainer = s.querySelector('.ant-layout-header>.headerContainer');
        if (window.scrollY > (sectionType === 4 ? 40 : 100)) {
            layoutHeader.classList.add('glassBGEffect')
        }
        else {
            layoutHeader.classList.remove('glassBGEffect')
        }
        if (window.scrollY > (sectionType === 4 ? 40 : window.innerHeight - 200)) {
            headerContainer.classList.remove('hideSecondChild')
        }
        else {
            headerContainer.classList.add('hideSecondChild')
        }
    }

    const stockReportChips = [
        { label: "HDFCBANK", to: "/stocks/stock-detail/Hdfc-Bank-Ltd/HDFCBANK" },
        { label: "RELIANCE", to: "/stocks/stock-detail/Reliance-Industries-Ltd./RELIANCE" },
        { label: "INFY", to: "/stocks/stock-detail/Infosys-Ltd./INFY" },
        { label: "BHARTIARTL", to: "/stocks/stock-detail/Bharti-Airtel-Ltd./BHARTIARTL" },
        { label: "TCS", to: "/stocks/stock-detail/Tata-Consultancy-Services-Ltd./TCS" },
        { label: "SBIN", to: "/stocks/stock-detail/State-Bank-Of-India/SBIN" }
    ]

    React.useLayoutEffect(() => {
        const s = document.getElementById('sharpelyHeader')
        if (s) {
            s.classList.add('sharpelyHeaderLPMain');
            let headerContainer = s.querySelector('.ant-layout-header>.headerContainer');
            headerContainer.classList.add('hideSecondChild')
            const logo = document.querySelector('.sharpelyLogo');
            if (logo) {
                logo.setAttribute('src', sharpelyLogoLight)
                if (isMobile) {
                    logo.style.height = '40px';
                }
            }
        }
        return () => {
            const s = document.getElementById('sharpelyHeader')
            if (s) {
                s.classList.remove('sharpelyHeaderLPMain');
                let headerContainer = s.querySelector('.ant-layout-header>.headerContainer');
                headerContainer.classList.remove('hideSecondChild')
                const logo = document.querySelector('.sharpelyLogo');
                if (logo) {
                    logo.setAttribute('src', sharpelyLogoDark)
                    if (isMobile) {
                        logo.style.height = '2.3rem';
                    }
                }
            }
        }
    }, [])

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    if (sectionType === 1) {
        return (
            <div style={{
                width: '100%',
                flex: 1,
                display: 'flex', alignItems: 'center', position: 'relative'
            }} className='padAllCampaign h100'>
                <div style={{
                    position: "absolute", width: "100%", height: "100%", top: 0, left: 0,
                    backgroundImage: `${isMobile ? "linear-gradient(to right, transparent, #00000020)" : 'linear-gradient(to right, transparent, rgba(0,0,0,0.7), rgba(0,0,0,0.7), rgba(0,0,0,0.87),rgba(0,0,0))'}, url('${baseURL}${isMobile ? "dash_mob.webp" : "dash_bg_main.webp"}')`,
                    backgroundRepeat: "no-repeat", backgroundSize: "cover",
                    backgroundPosition: "center",
                    transform: isMobile ? "" : "scaleX(-1)", zIndex: 1
                }}>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', zIndex: 10 }}>
                    <div className="d-flex flex-column">
                        <h1 className={"headXLtoSM margin0"} style={{ fontWeight: 700, padding: "0px", width: "14ch", color: 'var(--white2)', fontSize: isMobile ? "var(--displayMD)" : "" }}>
                            Supercharge your <span className='' style={{ color: "gold" }}>investing</span> journey
                        </h1>
                        <div className='textXLtoSM white' style={{
                            padding: "1rem 0rem 0rem",
                            display: isMobile ? "flex" : 'inline', flexWrap: "wrap",
                            fontSize: isMobile ? "var(--textLG)" : "", flexDirection: isMobile ? "column" : ""
                        }}>
                            <span style={{ color: 'var(--white2)' }} >The best investors are already using sharpely for </span>
                            <span className='w-500 grey5' style={{}}>
                                <ReactTyped
                                    className='headXLtoESM w-700'
                                    style={{ color: 'gold', fontSize: isMobile ? "var(--textLG)" : "", marginTop: isMobile ? "0.5rem" : "" }}
                                    loop
                                    strings={[
                                        "Stock research...", "Screening stocks...", "Building strategies...", "Market analysis..."
                                    ]}
                                    typeSpeed={110}
                                />
                            </span>
                        </div>
                        <div style={{ marginTop: "2rem", maxWidth: "100%" }}>
                            <GlobalInstrumentSearch viewType={2} width={isMobile ? "280px" : '30rem'} />
                        </div>
                        <div style={{ marginTop: "2rem" }}>
                            <Link to={'/login'} style={{ width: "fit-content" }}>
                                <button className="btnPrimary textLG hoverEffect" style={{ padding: '0.6rem 1.25rem', display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                    Get started <FaArrowCircleRight />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else if (sectionType === 2) {
        return (
            <div style={{
                width: '100%',
                flex: 1,
                display: 'flex', alignItems: 'center', position: 'relative'
            }} className='padAllCampaign h100'>
                <div style={{
                    position: "absolute", width: "100%", height: "100%", top: 0, left: 0,
                    backgroundImage: `${isMobile ? "linear-gradient(to right, transparent, #00000020)" : 'linear-gradient(to right, transparent, rgba(0,0,0,0.7), rgba(0,0,0,0.7), rgba(0,0,0,0.87),rgba(0,0,0))'}, url('${baseURL}${isMobile ? "dash_mob.webp" : "dash_bg_main.webp"}')`,
                    backgroundRepeat: "no-repeat", backgroundSize: "cover",
                    backgroundPosition: "center",
                    transform: isMobile ? "" : "scaleX(-1)", zIndex: 1
                }}>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', zIndex: 10 }}>
                    <div className="d-flex flex-column">
                        <h1 className={"headXLtoSM margin0"} style={{ fontWeight: 700, padding: "0px", width: "14ch", color: 'var(--white2)', fontSize: isMobile ? "var(--displayMD)" : "" }}>
                            Supercharge your <span className='' style={{ color: "gold" }}>stock</span> research
                        </h1>
                        <div className='textXLtoSM white' style={{
                            padding: "1rem 0rem 0rem",
                            display: isMobile ? "flex" : 'inline', flexWrap: "wrap",
                            fontSize: isMobile ? "var(--textLG)" : "", flexDirection: isMobile ? "column" : ""
                        }}>
                            <span style={{ color: 'var(--white2)' }} >The best investors are using sharpely for </span>
                            <span className='w-500 grey5' style={{}}>
                                <ReactTyped
                                    className='headXLtoESM w-700'
                                    style={{ color: 'gold', fontSize: isMobile ? "var(--textLG)" : "", marginTop: isMobile ? "0.5rem" : "" }}
                                    loop
                                    strings={[
                                        "Fundamental analysis...", "Technical analysis...", "Analyst estimates..."
                                    ]}
                                    typeSpeed={110}
                                />
                            </span>
                        </div>
                        <div style={{ marginTop: "2rem", maxWidth: "100%" }}>
                            <GlobalInstrumentSearch viewType={2} width={isMobile ? "280px" : '30rem'} placeholder='Search for stocks you want to trade or invest in...'
                                itemTypes={[4]} />
                        </div>
                        <div style={{ marginTop: "1.25rem", gap: "0.5rem" }} className='d-flex flex-wrap'>
                            {stockReportChips?.map((ele, i) => {
                                return (
                                    <div key={makeid(i + 1)} style={{
                                        padding: "0.125rem 0.5rem", border: "1px solid var(--dark3)", borderRadius: "var(--borderRadius)",
                                        cursor: "pointer"
                                    }} className='stRepChip' onClick={() => {
                                        navigate(ele.to)
                                    }}>
                                        <span className='textXS w-500 dark2' style={{ color: "rgba(255,255,255,0.7)" }}>{ele?.label}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else if (sectionType === 3) {
        return (
            <div style={{
                width: '100%',
                flex: 1,
                display: 'flex', alignItems: 'center', position: 'relative'
            }} className='padAllCampaign h100'>
                <div style={{
                    position: "absolute", width: "100%", height: "100%", top: 0, left: 0,
                    backgroundImage: `${isMobile ? "linear-gradient(to right, transparent, #00000020)" : 'linear-gradient(to right, transparent, rgba(0,0,0,0.7), rgba(0,0,0,0.7), rgba(0,0,0,0.87),rgba(0,0,0))'}, url('${baseURL}${isMobile ? "dash_mob.webp" : "dash_bg_main.webp"}')`,
                    backgroundRepeat: "no-repeat", backgroundSize: "cover",
                    backgroundPosition: "center",
                    transform: isMobile ? "" : "scaleX(-1)", zIndex: 1
                }}>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', zIndex: 10 }}>
                    <div className="d-flex flex-column">
                        <h1 className={"headXLtoSM margin0"} style={{ fontWeight: 700, padding: "0px", width: isMobile ? "100%" : "23ch", color: 'var(--white2)', fontSize: isMobile ? "var(--displayMD)" : "" }}>
                            Build, backtest and trade automated <span className='' style={{ color: "gold" }}>stock strategies</span>
                        </h1>
                        <div className='textXLtoSM white' style={{
                            padding: "1rem 0rem 0rem",
                            display: isMobile ? "flex" : 'inline', flexWrap: "wrap",
                            fontSize: isMobile ? "var(--textLG)" : "", flexDirection: isMobile ? "column" : ""
                        }}>
                            <span style={{ color: 'var(--white2)' }} >Create your strategy using </span>
                            <span className='w-500 grey5' style={{}}>
                                <ReactTyped
                                    className='headXLtoESM w-700'
                                    style={{ color: 'gold', fontSize: isMobile ? "var(--textLG)" : "", marginTop: isMobile ? "0.5rem" : "" }}
                                    loop
                                    strings={[
                                        "Stock screener...", "Factor models...", "custom baskets..."
                                    ]}
                                    typeSpeed={100}
                                />
                            </span>
                        </div>
                        <div style={{ marginTop: "2rem" }}>
                            <button className="btnWhite textMD" style={{
                                padding: '0.6rem 1.25rem', display: 'flex', alignItems: 'center', gap: '1rem',
                            }}
                                onClick={() => {
                                    subscribeMiddle({
                                        reqAccessLevel: 1,
                                        onNext: () => {
                                            dispatch(toggleBasketCreateModal({ open: true }))
                                        },
                                        loginModalParams: {
                                            type: "register",
                                            executeOnLoginOrSignup: () => {
                                                dispatch(toggleBasketCreateModal({ open: true }))
                                            }
                                        }
                                    })
                                }}>
                                Create your first strategy <FaArrowCircleRight />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else return (
        <div style={{
            width: '100%',
            flex: 1,
            display: 'flex', alignItems: 'center', position: 'relative'
        }} className='padAllCampaign h100'>
            <div style={{
                position: "absolute", width: "100%", height: "100%", top: 0, left: 0,
                backgroundImage: `${isMobile ? "linear-gradient(to right, transparent, #00000020)" : 'linear-gradient(to right, transparent, rgba(0,0,0,0.7), rgba(0,0,0,0.7), rgba(0,0,0,0.87),rgba(0,0,0))'}, url('${baseURL}${isMobile ? "dash_mob.webp" : "dash_bg_main.webp"}')`,
                backgroundRepeat: "no-repeat", backgroundSize: "cover",
                backgroundPosition: "center",
                transform: isMobile ? "" : "scaleX(-1)", zIndex: 1
            }}>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', zIndex: 10 }}>
                <div className="d-flex flex-column">
                    <h1 className={"headXLtoSM margin0"} style={{ fontWeight: 700, padding: "0px", width: isMobile ? "100%" : "23ch", color: 'var(--white2)', fontSize: isMobile ? "var(--displayMD)" : "" }}>
                        Screen stocks like a pro with sharpely <span className='' style={{ color: "gold" }}>Screener</span>
                    </h1>
                    <div className='textXLtoSM white' style={{
                        padding: "1rem 0rem 0rem",
                        display: isMobile ? "flex" : 'inline', flexWrap: "wrap",
                        fontSize: isMobile ? "var(--textLG)" : "", flexDirection: isMobile ? "column" : ""
                    }}>
                        <span style={{ color: 'var(--white2)' }} >Build almost any screen - </span>
                        <span className='w-500 grey5' style={{}}>
                            <ReactTyped
                                className='headXLtoESM w-700'
                                style={{ color: 'gold', fontSize: isMobile ? "var(--textLG)" : "", marginTop: isMobile ? "0.5rem" : "" }}
                                loop
                                strings={[
                                    "Fundamental metrics and functions...", "Technical indicators and patterns...", "Backtest your screen...", "Work with multiple timeframes..."
                                ]}
                                typeSpeed={110}
                            />
                        </span>
                    </div>
                    <div style={{ marginTop: "2rem" }}>
                        <button className="btnWhite textMD" style={{
                            padding: '0.6rem 1.25rem', display: 'flex', alignItems: 'center', gap: '1rem',
                        }}
                            onClick={() => {
                                subscribeMiddle({
                                    reqAccessLevel: 1,
                                    onNext: () => {
                                        navigate('/stocks/super-screener')
                                    },
                                    loginModalParams: {
                                        type: "register",
                                        executeOnLoginOrSignup: () => {
                                            navigate('/stocks/super-screener')
                                        }
                                    }
                                })
                            }}>
                            Create your first screen for free <FaArrowCircleRight />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}