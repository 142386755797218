import axios from 'axios';
import { DataService } from './DataService';

const MINTBOX_CLIENT_ID = 'Li2L9VO1eawEbsgLrHdpZjhmUdW6N8Cm';

let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
// const API_ENDPOINT = 'http://192.168.1.11:8001/';
const API_ENDPOINT = 'https://pyapi.mintbox.ai/api';
// const API_ENDPOINT = 'https://uat.mintbox.ai/api';

const client = axios.create({
	baseURL: API_ENDPOINT,
	withCredentials: false
});

class DataServicePY {
	static get(path = '', data = {}) {
		const optional = { 'Content-Type': 'application/json' };

		return client({
			method: 'GET',
			url: path,
			headers: { ...axios.defaults.headers, ...optional }
		});
	}

	static post(path = '', data = {}) {
		const optional = { 'Content-Type': 'application/json' };

		return client({
			method: 'POST',
			url: path,
			data: JSON.stringify(data),
			headers: { ...axios.defaults.headers, ...optional }
		});
	}

	static postImg(path = '', data = {}) {
		const optional = { 'Content-Type': 'multipart/form-data; charset=utf-8;' };

		return fetch(API_ENDPOINT + path, {
			method: 'POST',
			body: data
		}).then(r => r.json())
			.then(data => {
				if (data.statusCode === 200) {
					return {
						msg: 'Success',
						data: data.response,
						type: true
					};
				} else {
					return data;
				}

			})
	}

	static patch(path = '', data = {}) {
		const optional = { 'Content-Type': 'application/json' };

		return client({
			method: 'PATCH',
			url: path,
			data: JSON.stringify(data),
			headers: { ...axios.defaults.headers, ...optional }
		});
	}

	static put(path = '', data = {}) {
		const optional = { 'Content-Type': 'application/json' };

		return client({
			method: 'PUT',
			url: path,
			data: JSON.stringify(data),
			headers: { ...axios.defaults.headers, ...optional }
		});
	}

	static delete(path = '', data = {}) {
		const optional = { 'Content-Type': 'application/json' };

		return client({
			method: 'DELETE',
			url: path,
			headers: { ...axios.defaults.headers, ...optional }
		});
	}
}

const getAccessToken = async () => {
	const token = await localStorage.getItem('access_token');
	return JSON.stringify({ access_token: token || '' });
};

const refreshAccessToken = async () => {
	const res = await DataService.get(`/Auth/getToken?clientId=${MINTBOX_CLIENT_ID}`).then((d) => d);
	if (res.type) {
		await localStorage.setItem('access_token', res.data.accessToken);
		return res.data.accessToken;
	}
	return '';
};

client.interceptors.request.use(
	async (config) => {
		const value = await getAccessToken();
		const keys = JSON.parse(value);

		config.headers.Authorization = `Bearer ${keys.access_token}`

		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

client.interceptors.response.use(
	(response) => {
		if (response.status === 200) {
			return {
				msg: 'Success',
				data: typeof (response.data) === "string" ? base64regex?.test(response.data) ? String(response.data) : response.data?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null) : response.data,
				type: true
			};
		}
		// Toast.show({
		//   text1: 'Error Occured!!',
		//   text2: 'Try again ...',
		//   type: 'error'
		// });
		return {
			msg: response.statusText,
			data: typeof (response.data) === "string" ? base64regex?.test(response.data) ? String(response.data) : response.data?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null) : response.data,
			type: false
		};
	},

	async (error) => {
		const { response } = error;
		const originalRequest = error.config;


		if (response) {
			// if (response.status === 401) {
			// 	originalRequest._retry = true;
			// 	const access_token = await refreshAccessToken();
			// 	//console.log('aaac = ', access_token);
			// 	axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
			// 	return client(originalRequest);
			// }
			if (response.status === 502) {
				originalRequest._retry = true;
				// const access_token = await refreshAccessToken();
				//console.log('aaac = ', access_token);
				// axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
				return client(originalRequest);
			}
			// Toast.show({
			//   text1: 'Error Occured!!',
			//   text2: 'Try again ...',
			//   type: 'error'
			// });
			return response;
		}

		return Promise.reject(error);
	}
);

export { DataServicePY, API_ENDPOINT };
