const allowedError = 2;
// const minInvestment = (arr, type = 0) => {
//   if (arr.length === 0) {
//     return 0
//   } else if (arr.length === 1) {
//     return (arr[0]?.latest_nav )
//   } else {
//     // initialize equal weights
//     if (type) {
//       if (100 % arr.length === 0) {
//         arr = arr.map((obj) => ({ ...obj, allocation: (100 / arr.length).toFixed(0) }))
//       } else {
//         arr = arr.map((obj) => ({ ...obj, allocation: ((100 - (100 % arr.length)) / arr.length).toFixed(0) }))
//         arr[0].allocation = Number(arr[0].allocation) + (100 % arr.length)
//       }
//     }

//     const requiredAllocation = arr.map(e => e.allocation)
//     let quantities = arr.map(e => 1)
//     let t = 0
//     while (t < 10000) {
//       t++
//       const totalAmount = arr.reduce((sum, e, i) => sum + ((e?.latest_nav ) * quantities[i]), 0)
//       const actualAllocation = arr.map((e, i) => (e?.latest_nav) * quantities[i] * 100 / totalAmount)
//       const diffArr = actualAllocation.map((a, i) => requiredAllocation[i] - a)
//       const maxDiff = Math.max.apply(Math, diffArr)
//       const minDiff = Math.min.apply(Math, diffArr)
//       // //console.log(quantities, diffArr, actualAllocation)
//       if (minDiff > -1 * allowedError && maxDiff < allowedError) {
//         return totalAmount
//       } else {
//         const indexOfMaxDiff = diffArr.indexOf(maxDiff);
//         quantities[indexOfMaxDiff]++
//       }
//     }
//   }
// }

function minInvestment(arr, type = 0) {
  var a = arr?.filter((i) => i?.allocation > 0)
  if (type) {
    if (100 % a.length === 0) {
      a = a.map((obj, index) => ({ ...obj, allocation: (100 / a.length).toFixed(0), locked: false, index }))
    } else {
      a = a.map((obj, index) => ({ ...obj, allocation: ((100 - (100 % a.length)) / a.length).toFixed(0), locked: false, index }))
      a[0].allocation = Number(a[0].allocation) + (100 % a.length)
    }
  }

  var totalNAV = a.map(item => item?.latest_nav).reduce((prev, curr) => prev + curr, 0)

  var maxNAV = Math.max.apply(Math, a.map(function (o) { return o?.latest_nav; }))
  if (isNaN(maxNAV)) {
    return "-"
  } else {
    let obj = a.find(obj => obj?.latest_nav == maxNAV);

    var minInvestmentAMT = (obj?.latest_nav * 100) / obj?.allocation

    if (minInvestmentAMT < totalNAV) {
      var minNAV = Math.min.apply(Math, a.map(function (o) { return o?.latest_nav; }))
      let obj = a.find(obj => obj?.latest_nav == minNAV);

      minInvestmentAMT = (obj?.latest_nav * 100) / obj?.allocation
    }
    return (Math.ceil(minInvestmentAMT / 100) * 100).toFixed(2)
  }
};
export { minInvestment }