import moment from "moment";

function formatDate(d, skip = "", seperator = " ") {

    if (String(d) === "Invalid Date") {
        return "-"
    }
    var date = "";
    try {
        date = new Date(d.replace(" ", "T"));
    } catch (error) {
        date = new Date(d);
    }

    if (isNaN(date.getTime())) {
        return d;
    }
    else {

        var month = new Array();
        month[0] = "Jan";
        month[1] = "Feb";
        month[2] = "Mar";
        month[3] = "Apr";
        month[4] = "May";
        month[5] = "Jun";
        month[6] = "Jul";
        month[7] = "Aug";
        month[8] = "Sep";
        month[9] = "Oct";
        month[10] = "Nov";
        month[11] = "Dec";

        let day = date.getDate();

        if (day < 10) {
            day = "0" + day;
        }

        var finalStr = skip === "date" ? month[date.getMonth()] + seperator + date.getFullYear() : day + seperator + month[date.getMonth()] + seperator + date.getFullYear()
        return finalStr;
    }

}

function formatDateWithTime(d) {
    return new Date(d)?.toLocaleString('en-IN', {
        day: "2-digit", month: "short", year: "2-digit", hour: "numeric",
        minute: "2-digit", timeZone: 'Asia/Kolkata',
    })
}


function convertAnyDatetoDDMMYYYY(d, type = "") {
    var month = new Array();
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "Apr";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sep";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";



    if (type === "monStr") {
        var mon = ""
        for (let i = 0; i < d.length; i++) {
            if (d[i].match(/[a-zA-Z]/)) {
                mon += d[i];
            }
        }
        var monN = month.indexOf(mon) + 1
        d = d.replace(mon, monN.toString().length === 1 ? 0 + monN.toString() : monN.toString()).replaceAll(" ", "-")
        return d
    } else if (type === "yyyymmdd") {
        var today = new Date(d)
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return yyyy + '-' + mm + '-' + dd;
    } else {
        var today = new Date(d)
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '-' + mm + '-' + yyyy;

    }


}


function formatDateddmmyyyy(today) {
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return dd + '/' + mm + '/' + yyyy;
}

function formatDateddmmyy(today) {
    const yyyy = today.getFullYear().toString().slice(-2)
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;



    return dd + '/' + mm + '/' + yyyy;
}


function formatDateyyyymmdd(today) {
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return yyyy + '-' + mm + '-' + dd;
}

function formatDateMonthYear(date) {
    const month = date.toLocaleString('en-IN', { month: 'short', year: "2-digit" });
    return month;
}

function addMonths(date, months) {
    var a = date
    a.setMonth(a.getMonth() + months);
    return a;
}

function addWeek(date, week) {
    var a = date
    a.setDate(a.getDate() + (week * 7));
    return a;
}

function addDays(date, days) {
    var a = date;
    a.setDate(a.getDate() + days);
    return a;
}

function getNextSIPDate2(sipDate, freq = 1) {

    const date1 = new Date();
    const date2 = new Date(addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), sipDate), freq));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays < 27) {
        return formatDate(new Date(addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), sipDate), freq + 1)).toISOString())
    } else {
        return formatDate(new Date(addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), sipDate), freq)).toISOString())
    }
}

function getNextSIPDatel1(sipDate, freq = 1) {

    const date1 = new Date();
    const date2 = new Date(addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), sipDate), freq));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays < 27) {
        return new Date(addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), sipDate), freq + 1))
    } else {
        return new Date(addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), sipDate), freq))
    }
}

function getNextSIPDate(sipDate, freq = 1) {
    if (freq !== 1) {
        return formatDate(new Date(addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), sipDate), freq)).toISOString())
    }
    const date1 = new Date();
    const date2 = new Date(addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), sipDate), 1));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays < 27) {
        return formatDate(new Date(addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), sipDate), 2)).toISOString())
    } else {
        return formatDate(new Date(addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), sipDate), 1)).toISOString())
    }
}

function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

function dayDiff(d1, d2) {
    d1 = new Date(new Date(d1).setHours(0, 0, 0, 0)).getTime()
    d2 = new Date(new Date(d2).setHours(0, 0, 0, 0)).getTime()
    var diff = d2 - d1;
    var daydiff = diff / (1000 * 60 * 60 * 24);
    return daydiff;
}

function getLastFourQuarters(dateString) {
    let d = new Date(dateString);
    let m = d.getMonth()
    let quarters = [];
    for (let i = 1; i <= 4; i++) {
        let g = new Date(dateString);
        g.setMonth(g.getMonth() - (i * 3));
        quarters.push(formatDateyyyymmdd(g));
    }
    return quarters;
}

function getDateDiffInShort(inputDate, currentDate) {
    const timeDifference = currentDate - inputDate;

    if (timeDifference < 60000) {
        // Less than 1 minute
        return `${Math.floor(timeDifference / 1000)}s`;
    } else if (timeDifference < 3600000) {
        // 1 minute to 1 hour
        return `${Math.floor(timeDifference / 60000)}m`;
    } else if (timeDifference < 86400000) {
        // 1 hour to 23 hours
        return `${Math.floor(timeDifference / 3600000)}h`;
    } else if (timeDifference < 604800000) {
        // 1 day to 6 days
        return `${Math.floor(timeDifference / 86400000)}d`;
    } else if (timeDifference < 2419200000) {
        // 7 days to 28 days (1 week to 4 weeks)
        return `${Math.floor(timeDifference / 604800000)}w`;
    } else if (timeDifference < 29030400000) {
        // 1 month to 11 months
        return `${Math.floor(timeDifference / 2419200000)}mo`;
    } else {
        // More than 11 months
        return `${Math.floor(timeDifference / 29030400000)}y`;
    }
}

const DateDiffinDays = (d1, d2) => {
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return Math.floor((t2 - t1) / (24 * 3600 * 1000));
}

const DateDiffinWeeks = (d1, d2) => {
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
}

const DateDiffinMonths = (d1, d2) => {
    var d1Y = d1.getFullYear();
    var d2Y = d2.getFullYear();
    var d1M = d1.getMonth();
    var d2M = d2.getMonth();

    return (d2M + 12 * d2Y) - (d1M + 12 * d1Y);
}

const DateDiffinYears = (d1, d2) => {
    return d2.getFullYear() - d1.getFullYear();
}

const DateDiffCal = (d1, d2) => {
    var a = DateDiffinDays(d1, d2)
    if (a > 30) {
        a = DateDiffinWeeks(d1, d2)
        if (a > 30) {
            a = DateDiffinMonths(d1, d2)
            if (a > 30) {
                a = DateDiffinYears(d1, d2)
                return a + "y"
            }
            return a + "m"
        }
        return a + "w"
    }
    if (a === 0) {
        return "today"
    }
    return a + "d"
}

export function getDateFromDDMMYY(date = '', yearPrefix = 20) {
    let items = date?.split('/')?.map(Number);
    let temp = new Date();
    temp.setDate(items?.[0]);
    temp.setMonth(items?.[1] - 1);
    temp.setFullYear(Number(`${yearPrefix}` + items?.[2]));
    return temp;
}

export function checkIfSameMonthDate(date1, date2) {
    let td1 = new Date(date1);
    let td2 = new Date(date2);
    let yearCheck = td1.getFullYear() === td2.getFullYear();
    let monthCheck = td1.getMonth() === td2.getMonth();
    return yearCheck && monthCheck;
}

export function isLastDayOfMonth(date) {
    let curr = new Date(date)
    const nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1);
    return curr.getMonth() !== nextDay.getMonth();
}

export function getYYYYMMDD(todayTime) {
    function pad(s) {
        return s < 10 ? `0${s}` : s;
    }

    const d = new Date(todayTime);
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
}

export function formatDateMMMDDYYYY(dateStr = '') {
    let date = new Date(dateStr);
    if (String(date) === 'Invalid Date') return '--'
    return moment(date).format('MMM DD, YYYY')
}

export { formatDate, formatDateddmmyyyy, formatDateyyyymmdd, getNextSIPDate, convertAnyDatetoDDMMYYYY, monthDiff, addMonths, addWeek, dayDiff, formatDateddmmyy, formatDateMonthYear, getLastFourQuarters, addDays, DateDiffCal, getNextSIPDate2, getNextSIPDatel1, getDateDiffInShort, formatDateWithTime }