import { notification } from "antd";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export const ViewPlanButton = () => {

    const preventNotifRoutes = [
        '/login', '/signup', '/get-early-access', '/technical-chart', '/broker-page', '/user-onboarding', '/onboarding', '/about-us', '/pricing',
        '/privacy-policy', '/terms-and-conditions', '/refund-and-cancellation-policy', '/knowledge-base', '/', '/campaign/stock-report', '/campaign/strategy',
        '/campaign/screener'
    ];
    const user = useSelector((state) => state?.updateUserData?.value);
    const isMobile = window.innerWidth <= 575;
    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        if (isMobile && (user?.Client?.[0]?.SharpelyAccessLevel <= 1 || !user?.isLogged)) {
            if (!preventNotifRoutes.includes(location.pathname)) {
                notification.open({
                    message: "",
                    key: "upgradePlanNotif",
                    description: <div>
                        <AiOutlineClose style={{ color: "var(--white)", position: "absolute", top: 10, right: 10 }} onClick={() => {
                            notification.destroy('upgradePlanNotif');
                        }} />
                        <div className='textSM w-500 white mt-2'>
                            Upgrade now to unlock premium features and for an elevated and seamless user journey. 🚀✨
                        </div>
                        <button className="btnPrimary w100 mt-4" onClick={() => {
                            navigate('/pricing');
                            notification.destroy('upgradePlanNotif');
                        }}>Explore Plans</button>
                    </div>,
                    style: { width: "100vw", background: 'var(--dark4)', borderRadius: 8, boxShadow: '0.1px 0.1px 10px 0px var(--grey1)', color: "var(--white)", position: "relative" },
                    duration: 0,
                    placement: "bottom",
                    icon: null,
                    closeIcon: null
                })
            }
        }
    }, [user]);

    return null;
}