import React from 'react';

export const CustomChangingTextLoader = (props) => {

    const [count, setCount] = React.useState(0);

    React.useEffect(() => {
        if ((count + 1) !== props?.otherText?.length) {
            const interval = setInterval(() => {
                setCount(count + 1);
            }, 1500);
            return () => clearInterval(interval);
        }
    }, [count]);

    return (
        <div className='textMD w-500' style={{ textAlign: "center" }}>
            {props.otherText[count]}
        </div>
    );
}