import { userguide } from "Assets/images";
import { useLocation } from "react-router";
import { Link } from 'react-router-dom';
import { BsQuestionCircle } from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux';
import { toggleUserGuideModal } from 'Libs/redux/KnowledgeBase/reduxSlice';
import React from "react";

export const ClosableHeader = ({ heading = '', }) => {
    const location = useLocation();
    const links = [
        {
            path: ["/stocks/super-screener"], to: "", heading: "Know more about Stock screener",
            type: "kbSubCategory", subCategory: "stock-screener", title: "Stock Screener"
        },
        {
            path: ["/screens", "/screens?myScreenType=stock-screens"], to: "", heading: "Know more about Stock screener",
            type: "kbSubCategory", subCategory: "stock-screener", title: "Stock Screener"
        },
        {
            path: ["/screens?myScreenType=etf-screens"], to: "", heading: "Know more about ETF screener",
            type: "kbSubCategory", subCategory: "etf-screener", title: "ETF Screener"
        }, {
            path: ["/screens?myScreenType=mf-screens"], to: "", heading: "Know more about MF screener",
            type: "kbSubCategory", subCategory: "mutual-fund-screener", title: "Mutual Fund Screener"
        },
        {
            path: ["/strategies"], to: "", heading: "Know more about Strategy",
            type: "kbCategory", category: "strategy", title: "Strategy"
        },
        {
            path: ["/etfs/screener"], to: "/knowledge-base/mfs-and-etfs/etf-screener/introduction-to-etf-screener", heading: "Know more about ETFs screener",
            type: "kbSubCategory", subCategory: "etf-screener", title: "ETF Screener"
        },
        {
            path: ["/mutual-funds/screener"], to: "/knowledge-base/mfs-and-etfs/mutual-fund-screener/introduction-to-mutual-fund-screening", heading: "Know more about Mutual Funds screener",
            type: "kbSubCategory", subCategory: "mutual-fund-screener", title: "Mutual Fund Screener"
        },
        {
            path: ["/stocks/factor-model"], to: "/knowledge-base/stocks/factor-models/introducing-factor-model", heading: "Learn more about Factor Models",
            type: "kbSubCategory", subCategory: "factor-models", title: 'Factor Models'
        },
        {
            path: ["/factor-models"], to: "/knowledge-base/stocks/factor-models/introducing-factor-model", heading: "Learn more about Factor Models", type: "kbSubCategory",
            subCategory: "factor-models",
            title: 'Factor Models'
        },
        {
            path: ["/stocks/styleBox"], to: "/knowledge-base/stock-scores/stock-stylebox/introduction-to-stock-stylebox", heading: "Know more about Stylebox",
            type: "kbSubCategory", subCategory: "stock-stylebox", title: 'Stock StyleBox'
        },
        {
            path: ["/etfs/alpha-analysis"], to: "/knowledge-base/mfs-and-etfs/other-useful-tools/mf-and-etf-alpha-analysis", heading: 'Know more about Alpha Analysis',
            type: "kbArticle", article: "mf-and-etf-alpha-analysis", title: "MF and ETF Alpha Analysis"
        },
        {
            path: ["/mutual-funds/alpha-analysis"], to: "/knowledge-base/mfs-and-etfs/other-useful-tools/mf-and-etf-alpha-analysis", heading: 'Know more about Alpha Analysis',
            type: "kbArticle", article: "mf-and-etf-alpha-analysis", title: "MF and ETF Alpha Analysis"
        },
        { path: ["/curated-baskets"], heading: "Know more about Curated baskets", type: "kbSubCategory", subCategory: "curated-baskets" },
        { path: ["/stocks/stock-detail"], heading: "Know more about Stock report", type: "kbSubCategory", subCategory: "stock-report", title: "Stock Report" },
        { path: ["/mutual-funds/build-custom-strategy"], heading: "How to create a custom MF basket", type: "kbSubCategory", subCategory: "custom-mf-and-etf-baskets", },
        { path: ["/etfs/build-custom-strategy"], heading: "How to create a custom ETF basket", type: "kbSubCategory", subCategory: "custom-mf-and-etf-baskets", },
        { path: ["/stocks/build-custom-strategy"], heading: "How to create a custom Stock basket", type: "kbSubCategory", subCategory: "building-strategies", },
    ]
    const [selectedLink, setSelectedLink] = React.useState({});
    let dispatch = useDispatch();
    let link = links.find(ele => ele?.path?.some((el) => location.pathname.startsWith()));

    React.useEffect(() => {
        let url = location.pathname + location.search;
        let urlExist = links.find((ele) => ele?.path?.some((el) => url?.startsWith(el)));
        let absoluteUrl = links?.find((ele) => ele?.path?.some((el) => url === el));
        if (absoluteUrl?.path) {
            setSelectedLink(absoluteUrl);
        }
        else {
            if (urlExist?.path) {
                setSelectedLink(urlExist);
            }
            else {
                setSelectedLink({});
            }
        }
    }, [location])
    if (!selectedLink?.path) return null;
    return (
        <div style={{ display: "flex", alignItems: "center", gap: "0.4rem", }} onClick={() => {
            dispatch(toggleUserGuideModal({
                value: true, params: {
                    category: selectedLink?.category,
                    subCategory: selectedLink?.subCategory,
                    title: selectedLink?.title,
                    article: selectedLink?.article
                },
                type: selectedLink?.type
            }))
        }}>
            <span style={{ cursor: "pointer", flex: 1, whiteSpace: "nowrap", color: "var(--primary)" }} className="textXS w-500">{selectedLink?.heading} &gt;
            </span>
        </div>
    )
}
