import { Card, Tooltip } from "antd"
import { StockLivePrice } from "Services/api/LivePricesWebSocket"
import AMCIcon from "./AMCIcon"
import React from 'react';
import apiHelperPY from "Services/api/ApiHelperPY";
import ListLoader from "./ListLoader";
import { Link } from "react-router-dom";
import { getIndexList } from "Libs/utils";

const DashboardIndexPerf = ({ header, allIndices, loading, bordered = false, maxHeight = '20rem' }) => {



    return <Card bordered={false} style={{ padding: 0, border: bordered ? "1px solid var(--grey2)" : "none" }} bodyStyle={{ padding: 0, paddingBottom: "0.5rem" }}
        className="mobCardBorderRadius0">
        <div className='pad20Container' style={{ display: "flex", alignItems: "center" }}>
            {header}
        </div>
        {loading ? <ListLoader /> : <div style={{ maxHeight: maxHeight, overflow: "auto" }} className='scrollbox'>
            {allIndices.map((f, i) => {
                return (
                    <div key={i} className="borderAll" style={{ backgroundColor: i % 2 !== 0 ? 'var(--white)' : 'var(--grey4)' }}>
                        <Link to={`/indices/index-detail/${f?.proper_name?.replaceAll(' ', '-')}/${f?.Id}`}>
                            <div className="pad20Side6VerContainer" style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                                <AMCIcon amcName={f.symbol} fundType={4} height='1.5rem' width="1.5rem" showTricolor={true} />
                                <Tooltip title={<span className='textXS w-500'>
                                    {f?.proper_name}
                                </span>}
                                    arrow={false}>
                                    <div className="textXS black hoverBold" style={{ flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                                        {f?.proper_name}
                                    </div>
                                </Tooltip>
                                <span className="textXS w-500" style={{
                                    minWidth: "4.5rem", display: "flex", alignItems: "center",
                                    justifyContent: "flex-end"
                                }}>
                                    <span>
                                        <StockLivePrice fundData={f} format={11} symbol={f?.symbol} />
                                    </span>
                                </span>
                            </div>
                        </Link>
                    </div>
                )
            })}
        </div>}
    </Card>
}

export default DashboardIndexPerf