import ListLoader from "Components/ListLoader";
import { NoDataFound } from "Components/NoDataFound";
import { WatchList } from "Components/Watchlist";
import { LoadMore } from "Components/atoms/LoadMore";
import { useClickOutside } from 'CustomHooks/useClickOutside';
import { fetchFundData, fetchFundDataWithoutFilter } from "Libs/fetchFundData";
import { setFilterAppFrNav, setLetter, setOpenSearchModal } from "Libs/redux/Modals/reduxSlice";
import { getIndexList, screenerMetricData } from "Libs/utils";
import { Divider, Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";
import { IoSearch } from "react-icons/io5";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { v4 } from "uuid";

export const GlobalInstrumentSearch = ({ viewType = 1, width, placeholder = 'Search stocks, ETFs & mutual funds', itemTypes = [1, 2, 4, 5, 6] }) => {
    /*
    viewType =  1 --> Header Main Search,
                2 --> Landing page search
    */
    const dispatch = useDispatch();
    let user = useSelector((state) => state?.updateUserData?.value);
    const isMobile = window.innerWidth <= 575;
    const globalInsSearch = useSelector((s) => s?.globalInsSearch);
    const { openSearchModal, filterAppFrNav, letter } = globalInsSearch;
    const [uniqueId] = useState('gis' + v4());
    const [openSearchOther, setOpenSearchOther] = React.useState(false)
    const [mainData, setMainData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [filters, setFilters] = useState([]);
    const [data, setData] = useState([]);
    const [text, setText] = useState('');
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [isInitialLoaded, setIsInitialLoaded] = useState(false);
    const subsequenceIndicesMap = new Map();

    const clickref = React.useRef();

    useClickOutside(clickref, () => {
        setOpenSearchOther(false)
    })

    function searchAndSort(string, data) {
        if (string === '') return data;
        return searchObjects(string, data, ['nse_symbol', 'symbol', 'isin_code', 'Ticker', 'basic_name', 'proper_name', 'sectorName', 'IndexName'])
    }

    function getSubsequenceIndex(subseq, str) {
        const key = subseq + '_' + str;
        if (subsequenceIndicesMap.has(key)) {
            return subsequenceIndicesMap.get(key);
        }

        let i = 0;
        let index = 0;
        for (const char of str) {
            if (char?.toLowerCase() === subseq[i]?.toLowerCase()) {
                i++;
                if (i === subseq.length) {
                    subsequenceIndicesMap.set(key, index);
                    return index;
                }
            }
            index++;
        }
        return Infinity;
    }

    function searchObjects(searchInput, arr2, keys) {
        const subseq = searchInput
        let arr = [...arr2]
        let filteredObjs = arr?.map((obj) => {
            obj['isSubsequence'] = false
            for (const key of keys) {
                if (obj?.hasOwnProperty(key) && obj?.[key]) {
                    let str = obj?.[key];
                    let i = 0;
                    let index = 0;
                    const key_str = subseq + '_' + str;
                    for (const char of str) {
                        if (char?.toLowerCase() === subseq[i]?.toLowerCase()) {
                            i++;
                            if (i === subseq.length) {
                                subsequenceIndicesMap.set(key_str, index);
                                obj.isSubsequence = true
                                break;
                            }
                        }
                        index++;
                    }
                    if (obj?.isSubsequence) {
                        break;
                    }
                }
            }
            return obj;
        })?.filter((el) => el?.isSubsequence);
        let sortedArr = filteredObjs?.map((a) => {
            let aIndex = Infinity;
            for (const key of keys) {
                if (a.hasOwnProperty(key) && a?.[key]) {
                    aIndex = Math.min(aIndex, getSubsequenceIndex(searchInput, a[key]));
                }
            }
            a.sortIndex = aIndex;
            return a;
        })
        sortedArr.sort((a, b) => a.sortIndex - b.sortIndex);
        return sortedArr;
    }

    const applyFilter = (value) => {
        let tempFilters = [...filters]
        if (tempFilters.includes(value)) {
            tempFilters = [];
        }
        else {
            tempFilters = [value];
        }
        setFilters(tempFilters);
        let tempData = JSON.parse(JSON.stringify(mainData));
        tempData = tempData.filter((ele) => tempFilters.includes(ele.type) || tempFilters?.length <= 0);
        let res = searchAndSort(text, tempData);
        setSearchData(res);
    }

    const onSearch = (value) => {
        setText(value);
        if (value !== '') {
            let temp = JSON.parse(JSON.stringify(mainData));
            temp = temp.filter((ele) => filters.includes(ele.type) || filters?.length <= 0);
            let res = searchAndSort(value, temp);
            setSearchData(res);
            // fetchData(res);
        }
        else {
            let temp = JSON.parse(JSON.stringify(mainData));
            temp = temp.filter((ele) => filters.includes(ele.type) || filters?.length <= 0);
            setSearchData(temp);
            // fetchData(temp)
        }
    }

    function isChildVisible(container, child) {
        const containerRect = container.getBoundingClientRect();
        const childRect = child.getBoundingClientRect();
        return (
            childRect.top >= containerRect.top &&
            childRect.bottom <= containerRect.bottom
        );
    }

    const handleArrowUpDown = (e) => {
        const items = Array.from(document.querySelectorAll(`#${uniqueId} .globalInsList .globalInsListItem`));
        const parent = document.querySelector('.globalInsList')
        // console.log(parent)
        if (e.key === 'ArrowDown') {
            // console.log(items)
            e.preventDefault();
            let check = items.find((ele) => ele.getAttribute('data-focused') !== null);
            if (check) {
                let ind = items.findIndex((ele) => ele.getAttribute('data-focused') !== null);
                items[ind].removeAttribute('data-focused');
                if (ind === items.length - 1) {
                    items[0].setAttribute('data-focused', 'active');
                    ind = 0;
                }
                else {
                    items[ind + 1].setAttribute('data-focused', 'active');
                    ind = ind + 1;
                }
                if (!isChildVisible(parent, items[ind])) {
                    parent.scrollTo({ top: items[ind].offsetTop - parent.offsetTop - 110, behavior: "smooth" });
                }
            }
            else {
                items[0].setAttribute('data-focused', 'active');
            }
        }
        else if (e.key === 'ArrowUp') {
            e.preventDefault();
            let check = items.find((ele) => ele.getAttribute('data-focused') !== null);
            if (check) {
                let ind = items.findIndex((ele) => ele.getAttribute('data-focused') !== null);
                items[ind].removeAttribute('data-focused');
                if (ind === 0) {
                    items[0].setAttribute('data-focused', 'active');
                    ind = 0;
                }
                else {
                    items[ind - 1].setAttribute('data-focused', 'active')
                    ind = ind - 1;
                }
                if (!isChildVisible(parent, items[ind])) {
                    parent.scrollTo({ top: items[ind].offsetTop - parent.offsetTop, behavior: "smooth" });
                }
            }
            else {
                items[0].setAttribute('data-focused', 'active');
            }
        }
        else if (e.code === 'Enter') {
            let check = items.find((ele) => ele.getAttribute('data-focused') !== null);
            if (check) {
                check.querySelector('.itemFirstChild').click();
            }
        }
    }

    const loadData = async () => {
        let stType = { "key": "4", "name": "Stocks", "dname": "Stocks", basePath: "/stocks", value: 4, strVal: 'stock', omkar: 'Stock' }
        let res1 = await fetchFundDataWithoutFilter(4, false);
        let res2 = await fetchFundData(1, false);
        let res3 = await fetchFundData(2, false);
        let screenerData = await screenerMetricData(stType);
        let indices = await getIndexList();
        let sectors = screenerData?.fabricatedData?.sectors;
        let indGrps = screenerData?.fabricatedData?.industries;
        let bsicInds = screenerData?.fabricatedData?.basicIndustries;
        // let res1 = screenerData?.allFunds;
        res1 = res1.map(item => { return { ...item, type: 4 } }).sort((a, b) => b?.mcap - a?.mcap);
        res2 = res2.map(item => { return { ...item, type: 1 } }).sort((a, b) => b?.aum - a?.aum);
        res3 = res3.map(item => { return { ...item, type: 2 } }).sort((a, b) => b?.aum - a?.aum);
        sectors = sectors.map(item => { return { ...item, type: 5, sectorName: item.label, text: "Sector" } });
        indGrps = indGrps?.map(item => { return { ...item, type: 5, sectorName: item.label, text: "Industry Group", isIndGrp: true } });
        bsicInds = bsicInds?.map(item => { return { ...item, type: 5, sectorName: item.label, text: "Industry", isIndGrp: true, isInd: true } });
        let sectorsData = [...sectors, ...indGrps, ...bsicInds]
        indices = indices?.filter((el) => el?.is_sharpely_coverage === 1)
        indices = indices.map(item => { return { ...item, type: 6 } }) || []
        let temp = [...res1, ...res2, ...res3, ...sectorsData, ...indices];
        temp = temp?.filter((el) => itemTypes?.includes(el?.type))
        setMainData(temp);
        setSearchData(temp);
        // fetchData([...res1, ...res2, ...res3]);
        setLoading(false);
    }

    const fetchData = (arr) => {
        let temp = ([...arr])?.slice(0, 20);
        setData(temp);
    }

    const loadMoreData = () => {
        let temp = [...searchData];
        let temp2 = temp.slice(data.length, data.length + 20);
        setData([...data, ...temp2]);
    }

    const handleNavigate = (type, item) => {
        dispatch(setOpenSearchModal(false));
        var data = {
            "sector": String(item?.value).slice(0, 6),
            "indgrp": item?.isIndGrp === true ? String(item?.value).slice(0, 8) : "",
            "ind": (item?.isIndGrp === true && item?.isInd === true) ? String(item?.value) : "",
        }
        navigate(`${type === 4 ? '/stocks/stock-detail/' + item?.proper_name?.replaceAll(' ', '-') + '/' + item.symbol : type === 5 ? '/stocks/sector-details' :
            type === 6 ? "/indices/index-detail/" + item?.IndexName?.replaceAll(' ', '-') + '/' + item?.Id : (type === 1 ? "/etfs" : "/mutual-funds") +
                '/fund-detail/' + item?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + item.plan_id}`,
            { state: type === 5 ? { selectionData: data } : {} })
    }

    useEffect(() => {
        if (filterAppFrNav > 0 && mainData.length > 0) {
            let tempData = JSON.parse(JSON.stringify(mainData));
            let tempFilters = [filterAppFrNav]
            setFilters(tempFilters);
            tempData = tempData.filter((ele) => tempFilters.includes(ele.type) || tempFilters?.length <= 0);
            setSearchData(tempData);
        }
    }, [filterAppFrNav]);

    useEffect(() => {
        if (letter && mainData.length > 0) {
            onSearch(letter);
        }
    }, [letter])

    useEffect(() => {
        setText('');
        if (!openSearchModal) {
            setFilters([]);
            dispatch(setFilterAppFrNav(0))
            dispatch(setLetter(''))
        }
        if (!openSearchModal && mainData.length) {
            setSearchData(mainData);
            // fetchData(mainData);
        }
        if (openSearchModal && viewType === 1) {
            inputRef?.current?.focus();
        }
    }, [openSearchModal]);

    useEffect(() => {
        if (isInitialLoaded) {
            setLoading(true);
            loadData();
        }
    }, [user])

    useEffect(() => {
        if (openSearchModal) {
            document.getElementById(uniqueId)?.addEventListener('keydown', handleArrowUpDown);
        }
        else {
            document.getElementById(uniqueId)?.removeEventListener('keydown', handleArrowUpDown);
        }
        return () => {
            document.getElementById(uniqueId)?.removeEventListener('keydown', handleArrowUpDown);
        }
    }, [openSearchModal]);

    useEffect(() => {
        if (openSearchOther) {
            document.getElementById(uniqueId)?.addEventListener('keydown', handleArrowUpDown);
        }
        else {
            document.getElementById(uniqueId)?.removeEventListener('keydown', handleArrowUpDown);
        }
        return () => {
            document.getElementById(uniqueId)?.removeEventListener('keydown', handleArrowUpDown);
        }
    }, [openSearchOther]);

    useEffect(() => {
        fetchData(searchData);
    }, [searchData])

    return (
        <React.Fragment>
            <div className='globalInsSearch' id={uniqueId} onClick={() => { inputRef?.current?.focus(); }} style={{ width, position: viewType === 2 ? "relative" : undefined }} ref={clickref}>
                {viewType === 1 ? <>
                    <Input placeholder={placeholder} onClick={() => {
                        if (!isInitialLoaded) {
                            setIsInitialLoaded(true);
                            setLoading(true);
                            loadData();
                        }
                        dispatch(setOpenSearchModal(true));
                    }}
                        style={{
                            background: "var(--grey3)", border: "1px solid var(--grey2)",
                            width: "16.75rem", borderRadius: "0.25rem", fontSize: '0.85rem'
                        }} prefix={<IoSearch color='var(--dark2)' className="site-form-item-icon me-2" />}
                        className='globalInsSearchInput d-none d-xl-flex' readOnly />
                    <div className='d-flex d-xl-none align-items-center' style={{ gap: "1rem" }} >
                        <BsSearch onClick={() => {
                            if (!isInitialLoaded) {
                                setIsInitialLoaded(true);
                                setLoading(true);
                                loadData();
                            }
                            dispatch(setOpenSearchModal(true));
                        }} className='textMD black' />
                        <Divider style={{ height: "2rem", margin: 0 }} type="vertical" className="navLargeScreen" />
                    </div>
                </> : viewType === 2 ? <>
                    <div style={{
                        display: "flex", alignItems: "center", border: "1px solid var(--dark3)", padding: "0.5rem 0.75rem", borderRadius: "0.5rem",
                        cursor: "text", gap: "0.75rem", width: "100%", background: 'var(--dark4)'
                    }} onClick={() => {
                        if (!isInitialLoaded) {
                            setIsInitialLoaded(true);
                            setLoading(true);
                            loadData();
                        }
                        if (!openSearchOther) {
                            setOpenSearchOther(true);
                        }
                        inputRef.current.focus();
                    }}>
                        <BsSearch color="var(--dark2)" size={16} />
                        <input style={{ border: "none", outline: "none", background: "transparent", caretColor: "var(--dark2)", flex: 1 }}
                            placeholder={placeholder} className="white"
                            value={text} onChange={(e) => { onSearch(e.target.value) }} ref={inputRef} />
                    </div>
                </> : <></>}
                {viewType === 2 && (text?.length > 0) && openSearchOther && <div className='globalInsList' style={{
                    maxHeight: "20rem", position: "absolute", top: '100%', left: "0", width: "100%",
                    background: "var(--white)", borderRadius: "0.5rem", padding: "0.5rem 0", overflow: "scroll"
                }}>
                    {searchData?.length > 0 && !loading && <InfiniteScroll hasMore={searchData?.length > data?.length} loadMore={loadMoreData}
                        useWindow={false} threshold={80}>
                        {searchData?.length > 0 && data?.map((item, i) => {
                            let type = item.type;
                            return (
                                <div className='globalInsListItem landingPageSearch' key={i} style={{ padding: "0.5rem 1rem", borderBottom: "1px solid var(--grey2)" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}
                                        onClick={() => handleNavigate(type, item)} className="itemFirstChild">
                                        <div className='textSM black w-500 w100' style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                            {type === 1 || type === 2 ? item?.basic_name?.replace(" - Direct Plan", "") : type === 4 ? item?.proper_name : type === 5 ?
                                                item?.sectorName : item?.IndexName}
                                        </div>
                                        <div className='textXS w-500' style={{ fontSize: "10px", whiteSpace: "nowrap" }}>
                                            {type === 1 ? "ETFs" : type === 2 ? "MFs" : type === 4 ? "Stocks" : type === 5 ? item?.text : "Index"}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </InfiniteScroll>}
                    {loading ? <ListLoader /> : searchData?.length <= 0 ?
                        <div className='d-flex align-items-center justify-content-center mt-3'>
                            <NoDataFound className="textSM w-500 black" imageStyle={{ height: "5rem" }} /></div> : null
                    }
                </div>}
                {openSearchModal && viewType === 1 && <div className="globalInsSearchOverlay" onClick={() => {
                    dispatch(setOpenSearchModal(false));
                }}></div>}
                {openSearchModal && viewType === 1 && <div className='globalInsSearchModal' >
                    <div className='modalSearchBarGlobal'>
                        <BsSearch className='textSM black' style={{ marginRight: "0.3rem" }} cursor='pointer' />
                        <input placeholder={placeholder} value={text}
                            onChange={(e) => { onSearch(e.target.value) }} ref={inputRef} />
                        <GrFormClose className='black textLG' cursor={'pointer'} onClick={(e) => {
                            dispatch(setOpenSearchModal(false));
                            e.stopPropagation();
                        }} />
                    </div>
                    <div className='searchFiltersModalGlobal'>
                        <div onClick={() => applyFilter(4)} className={filters.includes(4) ? "appliedFilter" : ""}>Stocks</div>
                        <div onClick={() => applyFilter(1)} className={filters.includes(1) ? "appliedFilter" : ""}>ETFs</div>
                        <div onClick={() => applyFilter(2)} className={filters.includes(2) ? "appliedFilter" : ""}>{isMobile ? "MFs" : 'Mutual Funds'}</div>
                        <div onClick={() => applyFilter(5)} className={filters.includes(5) ? "appliedFilter" : ""}>Sectors</div>
                        <div onClick={() => applyFilter(6)} className={filters.includes(6) ? "appliedFilter" : ""}>Index</div>
                    </div>
                    {/* {text.length === 0 && <div className='dark3 textXS w-500'>
                        <BiTrendingUp className='black textSM' /> Trending
                    </div>} */}
                    <div className='globalInsList'>
                        {searchData?.length > 0 && !loading && <InfiniteScroll hasMore={searchData?.length > data?.length} loadMore={loadMoreData}
                            loader={< LoadMore key={0} />} useWindow={false} threshold={80}>
                            {searchData?.length > 0 && data?.map((item, i) => {
                                let type = item.type;
                                // let key = type === 1 || type === 2 ? item.plan_id : type === 4 ? item.symbol : i + 1;
                                return (
                                    <div className='globalInsListItem' key={i} style={{}}>
                                        <div className='itemFirstChild' style={{ cursor: "pointer", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                            onClick={() => handleNavigate(type, item)}>
                                            <div className='textXS black w-500 w100'
                                                style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                {type === 1 || type === 2 ? item?.basic_name?.replace(" - Direct Plan", "") : type === 4 ? item?.proper_name : type === 5 ?
                                                    item?.sectorName : item?.IndexName}
                                            </div>
                                            <div className='itemNameBtmContainer'>
                                                {type !== 5 && <div className='textXS dark3 w-500'>
                                                    {type === 1 ? item?.nse_symbol : type === 2 ? item?.isin_code : type === 4 ? item?.symbol : type === 5 ? '' : item?.Ticker}
                                                </div>}
                                                <div className='textXS w-500'>
                                                    {type === 1 ? "ETFs" : type === 2 ? "Mutual Funds" : type === 4 ? "Stocks" : type === 5 ? item?.text : "Index"}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='itemLastChild'>
                                            <div style={{ width: "fit-content" }}>
                                            </div>
                                            {/* {!([5, 6])?.includes(type) && (type === 4 ? item?.nse_active === 1 : true) && <WatchList type={type} instrument={item} />} */}
                                            {!([5, 6])?.includes(type) && <WatchList type={type} instrument={item} />}
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </InfiniteScroll>}
                    </div>
                    {loading ? <ListLoader /> : searchData?.length <= 0 ?
                        <div className='d-flex align-items-center justify-content-center mt-3'>
                            <NoDataFound className="textSM w-500 black" imageStyle={{ height: "5rem" }} /></div> : null
                    }
                </div>}
            </div>
        </React.Fragment >
    )
}