import AccessComponent from 'Components/AccessComponent';
import AccessControlledPage from 'Components/AccessControlledPage';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { addWeek, formatDate, formatDateyyyymmdd } from 'Components/DateFormatter';
import InstrumentListTable from 'Components/InstrumentListTable';
import UpgradeToPro from 'Components/UpgradeToPro';
import { CardHeader } from 'Components/atoms/SmallComponents';
import { useIsMobile } from 'CustomHooks/useIsMobile';
import Head from 'Layout/Head';
import { fetchFundData, fetchFundDataWithoutFilter } from 'Libs/fetchFundData';
import { displayValue, screenerMetricData, useGetAssetType } from 'Libs/utils';
import AdminServicesPY from 'Services/api/AdminServicesPY';
import { Card, Cascader, DatePicker, Select, Space, Switch } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-hot-toast';
import { IoIosArrowDown } from 'react-icons/io';

const AlphaAnalysis = ({ defaultType, isWidget = false, widgetItem = {}, allMutualFunds = [] }) => {
    const isMobile = useIsMobile();
    const [allFunds, setAllFunds] = useState([])
    const [allFilteredFundsData, setallFilteredFundsData] = useState([])
    const [fundsInDateRange, setFundsInDateRange] = useState([])
    const [showFundsInDateRange, setShowFundsInDateRange] = useState(true);
    const [allbenchmarks, setallbenchmarks] = useState([])
    const [mfCategories, setmfCategories] = useState([])
    const [loading, setloading] = useState(true)
    const [loading2, setloading2] = useState(true)


    var type = useGetAssetType()
    type = Object.keys(defaultType || {})?.length ? defaultType : type

    const [category_id, setcategory_id] = useState(['Equity', '100'])
    const [benchmark_id, setbenchmark_id] = useState([7])
    const today = new Date()
    today.setFullYear(today.getFullYear() - 1)
    const [start_date, setstart_date] = useState(isWidget ? formatDateyyyymmdd(today) : null)
    const [end_date, setend_date] = useState(isWidget ? formatDateyyyymmdd(new Date()) : null)
    const [dates, setDates] = useState(null);
    const [value1, setValue1] = useState(isWidget ? dayjs(today) : null);
    const [value2, setValue2] = useState(isWidget ? dayjs(new Date()) : null);
    const [tableLoading, setTableLoading] = useState(true)
    const minDate = moment('2013-01-01');
    const maxDate = moment(new Date());

    const getRange = (start, end) => [
        ...Array((end - start) + 1).keys(),
    ].map((
        (i) => i + start
    ));

    const disabledDate1 = useCallback((d) => {
        if (d == null) {
            return null;
        }
        return (
            (minDate != null && d.isBefore(minDate) && !d.isSame(minDate, 'day')) ||
            (maxDate != null && d.isAfter(maxDate) && !d.isSame(maxDate, 'day'))
        );
    }, [minDate, maxDate]);

    const disabledDate2 = useCallback((d) => {
        if (d == null) {
            return null;
        }
        return (
            (minDate != null && d.isBefore(minDate) && !d.isSame(minDate, 'day')) ||
            (maxDate != null && d.isAfter(maxDate) && !d.isSame(maxDate, 'day')) ||
            (value1 != null && d.isBefore(value1) && !d.isSame(value1, 'day')) || (d.diff(value1, 'days') < 91)
        );
    }, [minDate, maxDate, value1]);

    const onOpenChange = (open) => {
        if (open) {
            setDates([null, null]);
        } else {
            setDates(null);
        }
    };

    const getWeekData = (data) => {
        const initialDate = new Date(start_date);
        const endDate = addWeek(new Date(initialDate), 1);
        let temp = data.filter((ele) => {
            let t = ele?.start?.['_i'];
            if (t !== 'null') {
                t = t.replaceAll('/', '-');
            }
            let targetDate = new Date(t);
            const startTime = initialDate.getTime();
            const endTime = endDate.getTime();
            const targetTime = targetDate.getTime();
            return targetTime >= startTime && targetTime <= endTime
        })
        return temp;
    }

    const loadData = async () => {
        var benchmarks = await fetchFundData(11, true)
        setallbenchmarks(benchmarks)
        if (allMutualFunds?.length) {
            let allData = allMutualFunds
            var catLevel1 = [...new Set(allData.map((item, index) => item.primary_category_name))]
            var catLevel2 = [...new Set(allData.map((i, index) => {
                return {
                    "categoryName": i.primary_category_name,
                    "subCategoryName": i.category_name,
                    "categoryId": i.category_id
                }
            }))]
            catLevel2 = Array.from(new Set(catLevel2.map(JSON.stringify))).map(JSON.parse)

            let categories = catLevel1.map((i) => {
                return {
                    categoryName: i,
                    subCategories: catLevel2.filter((ii) => ii.categoryName === i)
                }
            })
            setAllFunds(allData)
            setmfCategories(categories)
        } else {
            var screener_datas = await screenerMetricData(type)
            setmfCategories(screener_datas?.fabricatedData?.mf_categories)
            setAllFunds(screener_datas?.allFunds)
        }

        setallFilteredFundsData([])
        setloading(false)
    }

    React.useEffect(() => {
        setloading(true)
        setloading2(true)
        loadData()
    }, [type.value])

    React.useEffect(() => {
        if (isWidget && !loading) {
            setTableLoading(true)
            getAlphaData()
        }
    }, [loading])

    const getAlphaData = async () => {
        //console.log(start_date, end_date)
        if (start_date !== null && end_date !== null) {
            setloading2(false)
            var d = await AdminServicesPY.getMFAlphaAnalysis({ category_id: category_id[1], benchmark_id, start_date, end_date, instrument_type: type.value === 1 ? 1 : 0 }).then((r) => r)
            //console.log('suraj', d)
            if (d?.type) {
                var filteredfunds = JSON.parse(d?.data?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))
                filteredfunds = filteredfunds.map((i) => {
                    return {
                        ...allFunds.filter((a) => a.plan_id === i.plan_id)[0],
                        ...i,
                        "start": moment(String(i?.start).replace("T00:00:00", "").split('-').join('/'), 'YYYY/MM/DD'),
                        "end": moment(String(i?.end).replace("T00:00:00", "").split('-').join('/'), 'YYYY/MM/DD')
                    }
                })
                setFundsInDateRange(getWeekData(filteredfunds))
                setallFilteredFundsData(filteredfunds)
                setTableLoading(false)
            }
            setTableLoading(false)
        } else {
            setloading2(true)
            toast.error("Please select start and end date")
        }
    }


    const AppliedFilterView = () => (
        <Card className='mobCardBorderRadius0' headStyle={{ padding: "1.25rem", height: "fit-content" }}
            title={<div className='d-flex align-items-center'
                style={{ flexWrap: "wrap", gap: "1rem" }}>
                <div style={{ flex: 1 }}>
                    <CardHeader heading={isWidget ? widgetItem?.Name : 'Alpha analysis'} headingType={isWidget ? 2 : 1} />
                    {isWidget && <div className="customTabsSubHeading w-400" style={{ marginBottom: 0 }}>{widgetItem?.Description}</div>}
                </div>
                {allFilteredFundsData.length > 0 && <span className='textSM w-400 d-flex align-items-center dark3'>
                    Show funds that existed on/before {formatDate(start_date)}
                    <Switch checked={showFundsInDateRange} onChange={() => setShowFundsInDateRange(prev => !prev)} style={{ marginLeft: "0.5rem" }} />
                </span>}
            </div>}
            style={{ borderBottom: isWidget ? '1px solid #f0f0f0' : 'none', borderBottomLeftRadius: isWidget ? 0 : '', borderBottomRightRadius: isWidget ? 0 : '' }}>
            <div style={{ display: "flex", flexDirection: "row", gap: "1.25rem", flexWrap: "wrap" }}>
                <Space direction='vertical' style={{}}>
                    <div className='textSM' style={{ flex: 1, fontWeight: "bold" }}>Select {type.omkar} category</div>
                    <Cascader
                        allowClear={false}
                        options={JSON.parse(JSON.stringify(mfCategories)).map((i, index) => {
                            i["value"] = i.categoryName
                            i["label"] = i.categoryName
                            i["title"] = i.categoryName
                            if (i["subCategories"] !== undefined) {
                                i["subCategories"]?.map((ii, indexi) => {
                                    ii["value"] = String(ii.categoryId)
                                    ii["label"] = ii.subCategoryName
                                    return ii
                                })
                                i["children"] = i["subCategories"]
                            }
                            return i
                        })}
                        style={{
                            width: isMobile ? '100%' : "17.5rem",
                            // padding: isMobile ? '0.75rem' : "0.5rem"
                        }}
                        showSearch
                        expandTrigger="hover"
                        placeholder={"Please select " + type.omkar + " category"}
                        defaultValue={category_id}
                        onChange={(value) => {
                            setcategory_id(value)
                        }}
                    />
                </Space>
                <Space direction='vertical' style={{}}>
                    <div className='textSM' style={{ flex: 1, fontWeight: "bold" }}>Select benchmark</div>

                    <Select
                        className='textXS black'
                        style={{
                            width: isMobile ? '100%' : "17.5rem",
                            // padding: isMobile ? '0.75rem' : "0.5rem"
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        suffixIcon={<IoIosArrowDown size={15} />}
                        defaultValue={benchmark_id}
                        placeholder={"Please select benchmark"}
                        onChange={(value) => {
                            // ////console.log(value)
                            setbenchmark_id([value])
                        }}
                        options={allbenchmarks.map((i) => {
                            // ////console.log(i)
                            return {
                                'value': i.benchmark_id,
                                'key': i.benchmark_id,
                                'label': i.benchmark_name
                            }
                        })}
                    />
                </Space>
                <Space direction='vertical' style={{}}>
                    <div className='textSM' style={{ flex: 1, fontWeight: "bold" }}>Select date range <span className='textXS'>(min. 3 months range)</span></div>

                    <Space direction='horizontal'>
                        <DatePicker disabledDate={disabledDate1} placeholder='Start date' value={value1} format={'DD-MM-YYYY'} onChange={(val) => {
                            setstart_date(formatDateyyyymmdd(new Date(val)))
                            setValue1(val)
                        }} style={{ paddingTop: isMobile ? '0.75rem' : '0.5rem', paddingBottom: isMobile ? '0.75rem' : '0.5rem' }} />
                        <DatePicker disabledDate={disabledDate2} disabled={value1 === null || value1 === undefined} placeholder='End date' value={value2} format={'DD-MM-YYYY'} onChange={(val) => {
                            setend_date(formatDateyyyymmdd(new Date(val)))
                            setValue2(val)
                        }} style={{ paddingTop: isMobile ? '0.75rem' : "0.5rem", paddingBottom: isMobile ? '0.75rem' : "0.5rem" }} />
                    </Space>
                </Space>
                <button className="btnBlack mobileButton" onClick={() => {
                    setTableLoading(true)
                    getAlphaData()
                }} style={{ height: "fit-content", alignSelf: "flex-end", justifySelf: isMobile ? "flex-start" : "flex-end" }}>
                    Check
                </button>
            </div>
        </Card >
    )


    const alphaCOls = [{
        "dataIndex": "start",
        "key": "start",
        "metric_col_code": "start",
        "metric_unit": "",
        "metric_data_type": "date",
        "metric_name": "Start date",
        "title": "Start date",
        "align": "right",
        "width": 100,
    }, {
        "dataIndex": "end",
        "key": "end",
        "metric_col_code": "end",
        "metric_unit": "",
        "metric_data_type": "date",
        "metric_name": "End date",
        "title": "End date",
        "align": "right",
        "width": 100,
    }, {
        "dataIndex": "rets",
        "key": "rets",
        "metric_col_code": "rets",
        "metric_unit": "percentage",
        "metric_data_type": "float",
        "metric_name": "Fund returns",
        "title": "Fund returns",
        "align": "right",
        "width": 100,
    }, {
        "dataIndex": "bm_rets",
        "key": "bm_rets",
        "metric_col_code": "bm_rets",
        "metric_unit": "percentage",
        "metric_data_type": "float",
        "metric_name": "Benchmark returns",
        "title": "Benchmark returns",
        "align": "right",
        "width": 100,
    }, {
        "dataIndex": "alpha",
        "key": "alpha",
        "metric_col_code": "alpha",
        "metric_unit": "percentage",
        "metric_data_type": "float",
        "metric_name": "Alpha",
        "title": "Alpha",
        "align": "right",
        "width": 100,
    }, {
        "dataIndex": "beta",
        "key": "beta",
        "metric_col_code": "beta",
        "metric_unit": "",
        "metric_data_type": "float",
        "metric_name": "Beta",
        "title": "Beta",
        "align": "right",
        "width": 100,
    }, {
        "dataIndex": "r2",
        "key": "r2",
        "metric_col_code": "r2",
        "metric_unit": "",
        "metric_data_type": "float",
        "metric_name": "R2",
        "title": "R2",
        "align": "right",
        "width": 100,
    }]

    const metaItems = {
        1: {
            title: "Calculating Alpha in ETFs for Better Returns and Performance",
            description: "Discover how sharpely's ETF research and analysis tools at the ETF Research Center, including Fund Focus, Screener, Portfolio Builder, Overlap, Stock Locator, Watchlist, and more, can help you. Visit us now for further information"
        },
        2: {
            title: "Calculating Alpha in Mutual Funds for Better Returns and Performance",
            description: "Discover how sharpely's alpha in Mutual Funds gauges return potential against the benchmark index. Visit us now for more information"
        }
    }

    if (isWidget) {
        return <AccessComponent component={<div>
            <AppliedFilterView />
            {
                loading && <div className='loaderContainer' >
                    <ActivityLoaderMintbox showQuote={false} />
                </div>
            }
            {!loading2 && <InstrumentListTable
                tableType={6}
                hideCheckBox={true}
                istableLoading={tableLoading}
                defaultInsType={type.value}
                otherCol={allFilteredFundsData.length !== allFunds.length ? alphaCOls.map((col) => {
                    return {
                        ...col,
                        "render": (text, record, index) => <div style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "flex-end" }}>
                            {displayValue({
                                text, metricObj: col
                            })}
                        </div>
                    }
                }) : []}
                showSymbolInMob={type?.value === 1}
                finalList={JSON.parse(JSON.stringify(showFundsInDateRange ? fundsInDateRange : allFilteredFundsData))}
                sortColKey={'alpha'}
                sortOrder={'top'}
                tableHeader={`Alpha analysis of funds in ${allFilteredFundsData?.[0]?.category_name}`}
                showToolsBtnsInTab downloadTable showHeatmapBtn
            />}
        </div>} moduleKey={type?.value === 2 ? 'ALPHA_ANALYSIS' : "ALPHA_ANALYSIS_ETF"} upgradeComponent={<Card>
            <div>
                <CardHeader heading={widgetItem?.Name} headingType={3} />
                <div className='customTabsSubHeading' style={{ marginBottom: 0 }}>{widgetItem?.Description}</div>
            </div>
            <div className='my-5'>
                <UpgradeToPro moduleKey={type?.value === 2 ? 'ALPHA_ANALYSIS' : "ALPHA_ANALYSIS_ETF"} height='fit-content' />
            </div>
        </Card>} />
    }
    if (loading) {
        return <React.Fragment>
            <div className='loaderContainer' >
                <ActivityLoaderMintbox showQuote={true} />
            </div>
        </React.Fragment>
    } else {
        return (
            <React.Fragment>
                <Head title={metaItems?.[type?.value]?.title || "Alpha analysis"} description={metaItems?.[type?.value]?.description || `${type?.dname} Alpha analysis`} />
                <AccessControlledPage moduleKey={type?.value === 2 ? 'ALPHA_ANALYSIS' : "ALPHA_ANALYSIS_ETF"} />
                <div className='normalContainer'>
                    {/* <ClosableHeader heading='Alpha Analysis' /> */}
                    <AppliedFilterView />
                    {!loading2 && <InstrumentListTable
                        tableType={6}
                        hideCheckBox={true}
                        istableLoading={tableLoading}
                        defaultInsType={type.value}
                        otherCol={allFilteredFundsData.length !== allFunds.length ? alphaCOls.map((col) => {
                            return {
                                ...col,
                                "render": (text, record, index) => <div style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "flex-end" }}>
                                    {displayValue({
                                        text, metricObj: col
                                    })}
                                </div>
                            }
                        }) : []}
                        finalList={JSON.parse(JSON.stringify(showFundsInDateRange ? fundsInDateRange : allFilteredFundsData))}
                        sortColKey={'alpha'}
                        showSymbolInMob={type?.value === 1}
                        sortOrder={'top'}
                        tableHeader={`Alpha analysis of funds in ${allFilteredFundsData?.[0]?.category_name}`}
                        showToolsBtnsInTab downloadTable showHeatmapBtn
                    />}
                </div>
            </React.Fragment >
        );
    }

}

export default AlphaAnalysis