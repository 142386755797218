import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import { StickyScrollTabs } from "Components/CustomComponents/StickyScrollTabs";
import CustomSegmentedTab from "Components/CustomSegmentedTab";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import { InsightIcon } from "Components/InsightsIcon";
import { NoDataFound } from "Components/NoDataFound";
import OHLC from "Components/OHLC";
import RedGreenText from "Components/RedGreenText";
import { StockScoresGauge2 } from "Components/StockScoresGauge2";
import datafeed from "Components/TradingView/datafeed";
import { GetLTP } from "Services/api/LivePricesWebSocketOnlyVal";
import { Card, Skeleton, Table } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import React from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { IoMdRefresh } from "react-icons/io";

const TechnicalAnalysis = React.memo(({ SYMBOL, fundData, isMobile, allIndicatorData, allVolumeData, allRangeAnalysisData, loadingTechnicalsIndicators,
    loadingTechnicalsVolume, loadingTechnicalsRange, onRefereshClick, isIndex = false, isCustomIndex, indexPrice }) => {

    const [timeFrame, setTimeFrame] = React.useState('1D')

    const TimeFrameSelector = () => {
        const timeFrameOptions = [
            {
                label: "1m",
                key: "1m",
                value: "1m",
            },
            {
                label: "5m",
                key: "5m",
                value: "5m",
            },
            {
                label: "15m",
                key: "15m",
                value: "15m",
            },
            {
                label: "30m",
                key: "30m",
                value: "30m",
            },
            {
                label: "1H",
                key: "1H",
                value: "1H",
            },
            {
                label: "1D",
                key: "1D",
                value: "1D",
            },
            {
                label: "1W",
                key: "1W",
                value: "1W",
            },
            {
                label: "1M",
                key: "1M",
                value: "1M",
            }

        ];
        return <CustomSegmentedTab options={timeFrameOptions} value={timeFrame} onChange={(e) => setTimeFrame(e)} isBlock={true} />
    }

    const AllIndicators = () => {
        const OneIndicator = ({ obj }) => {

            return <div className="hoverBackground hoverBold hoverDark" style={{ padding: '1rem 0.5rem', borderBottom: '1px solid var(--grey2)', display: 'flex', gap: '16px', alignItems: 'center', cursor: 'pointer', color: 'var(--dark3)' }}>
                <div className="textXS w-400" style={{ flex: 1 }}>
                    {/* {obj?.name} */}
                    {obj?.label}
                    {obj?.inputs && Object.values(obj?.inputs)?.length ? " (" + Object.keys(obj?.inputs).map(k => obj?.inputs?.[k]) + ")" : <></>}
                    {/* {obj?.inputs && Object.values(obj?.inputs)?.length ? "(" + Object.keys(obj?.inputs).map(k => k + ": " + obj?.inputs?.[k]) + ")" : <></>} */}
                    {/* <BiEdit className="hoverDark" style={{ marginLeft: '4px', color: 'var(--dark1)' }} /> */}
                    {" "}
                    {/* <RedGreenText value={obj?.sentiment} text={obj?.sentiment === -1 ? '(Bearish)' : obj?.sentiment === 1 ? '(Bullish)' : '(Neutral)'} showYellow={true} /> */}
                </div>
                {
                    obj?.type?.toLowerCase() !== 'pattern recognition' ?
                        <div style={{ maxWidth: '100px' }}>
                            {loadingTechnicalsIndicators ?
                                <Skeleton.Avatar style={{ height: "18px", width: "40px", borderRadius: 4 }} active />
                                :
                                <RedGreenText value={obj?.sentiment} text={obj?.value} showYellow={false} />
                            }
                        </div>
                        :
                        <></>
                }
                <div style={{ maxWidth: '60px', marginLeft: '8px' }}>
                    {loadingTechnicalsIndicators ?
                        <Skeleton.Avatar style={{ height: "18px", width: "45px", borderRadius: 4 }} active />
                        :
                        <RedGreenText value={obj?.sentiment} text={obj?.sentiment === -1 ? 'Bearish' : obj?.sentiment === 1 ? 'Bullish' : 'Neutral'} showYellow={false} />
                    }
                </div>
                {/* <div style={{ widht: '100px' }}>
                </div> */}
                {/* <div>
                    <MdClose size='1rem' className="hoverDark" style={{ marginLeft: '16px', color: 'var(--dark1)' }} onClick={() => removeObj({ obj })} />
                </div> */}
            </div>

        }
        const IndicatorComponent = ({ type, title }) => {
            var ind = allIndicatorData?.filter(x => x.type.toLowerCase() === type && x.timeframe === timeFrame);
            ind = ind.sort((a, b) => {
                if (a.sentiment + b.sentiment === -1) {
                    return a.sentiment
                } else return b.sentiment - a.sentiment
            })

            const buySentiment = ind.reduce((accumulator, object) => {
                return object.sentiment === 1 ? accumulator + 1 : accumulator
            }, 0)
            const sellSentiment = ind.reduce((accumulator, object) => {
                return object.sentiment === -1 ? accumulator + 1 : accumulator
            }, 0)
            const total = ind.length
            var perc = 0
            if (!ind?.length) {
                perc = undefined
            } else {
                if (type === 'pattern recognition') {
                    var sum = buySentiment - sellSentiment
                    perc = parseInt(sum > 4 ? 4 : sum < -4 ? -4 : sum / 4 * 100)
                } else {
                    perc = parseInt((buySentiment - sellSentiment) / total * 100)
                }
            }
            return <div style={{ width: "100%" }}>
                <div className="textLG w-500 dark4 text-center">
                    {title}
                </div>
                <div style={{ paddingBottom: '1rem' }}>
                    <StockScoresGauge2 val={perc} height={200} labelSuffix={"%"} labelFont={"16px"} showTickLabels={true} paneThickness={20} min={-100}
                        max={100} isAnalystRating={true}
                        yAxisLabels={{ '-100': "Strong<br />sell", '-50': "Sell", 0: "Neutral", 50: "Buy", 100: "Strong<br />buy" }} yAxisLabelClass='analystRatingGauge'
                        tickInterval={10} tickAmount={21} showTick={false} />
                    {total > 0 ?
                        <div style={{ display: "flex", gap: "0.75rem", alignItems: "center", justifyContent: 'center' }}>
                            {loadingTechnicalsIndicators ?
                                <Skeleton avatar={{ size: '2.5rem' }} paragraph={{ rows: 2, width: ['90%', '70%'] }} title={false} active />
                                :
                                <>
                                    <div style={{ flexShrink: 0 }}>
                                        <InsightIcon type={perc <= -50 ? "Negative" : perc >= 50 ? "Positive" : "Neutral"} height="2.5rem" width="2.5rem" padding="0.6rem" />
                                    </div>
                                    <div className="textXS dark4 w-500">
                                        {
                                            type === 'ma' ?
                                                `${fundData?.proper_name}'s price on ${timeFrame} timeframe is above ${buySentiment} and below ${sellSentiment} moving averages out of ${total} moving averages.`
                                                : type === 'oscillator' ?
                                                    `${fundData?.proper_name}'s price on ${timeFrame} timeframe is in bullish range for ${buySentiment} and bearish range for ${sellSentiment} oscillators out of ${total} oscillators.`
                                                    : type === 'pattern recognition' ?
                                                        `${fundData?.proper_name}'s chart on ${timeFrame} timeframe indicates ${buySentiment} bullish patterns and ${sellSentiment} bearish patterns out of ${total} candlestick patterns.`
                                                        : ''


                                        }
                                    </div>
                                </>
                            }
                        </div>
                        : <></>}
                </div>
                <div style={{
                    flexGrow: 1,
                    paddingBottom: !isMobile ? "0" : "1rem"
                }}>
                    <div style={{ width: "100%" }}>
                        {ind.map(obj => {
                            return <OneIndicator obj={obj} />
                        })}
                    </div>
                </div>
            </div>
        }
        return <>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }} className="margin20Bottom">
                <TimeFrameSelector />
            </div>
            <Card bodyStyle={{ display: "flex", flexDirection: isMobile ? 'column' : 'row', justifyContent: "space-between", padding: '1.25rem' }} style={{
                border: "1px solid var(--grey2)",
                marginTop: "1.25rem"
            }} >
                <div style={{ flex: 1, paddingRight: isMobile ? '' : '1rem', borderRight: isMobile ? '' : '1px solid var(--grey2)' }}>
                    <IndicatorComponent type={'oscillator'} title={"Oscillators"} />
                </div>
                <div style={{ flex: 1, paddingRight: isMobile ? '' : '1rem', paddingLeft: isMobile ? '' : '1rem', borderRight: isMobile ? '' : '1px solid var(--grey2)' }}>
                    <IndicatorComponent type={'ma'} title={"Moving averages"} />
                </div>
                <div style={{ flex: 1, paddingLeft: isMobile ? '' : '1rem' }}>
                    <IndicatorComponent type={"pattern recognition"} title={"Patterns"} />
                </div>
            </Card>
        </>
    }

    const Pivots = () => {
        const pivots = allIndicatorData?.filter(x => x.type === 'pivot' && x.timeframe === timeFrame) || []
        let columns = pivots?.map(p => {
            return {
                "title": p.label,
                "dataIndex": p.name,
                "align": 'center',
            }
        })
        columns.unshift({
            "title": "Pivots",
            "dataIndex": 'point',
            "fixed": 'left',
            width: isMobile ? 40 : 100
        })

        const loadingColumns = columns.map(c => {
            if (c.title === "Pivots") {
                return c
            }
            else return { ...c, render: () => { return <Skeleton.Avatar style={{ height: "18px", width: "60px", borderRadius: 4 }} active /> } }
        })
        const points = { 'r4': 'R4', 'r3': 'R3', 'r2': 'R2', 'r1': 'R1', 'tp': 'Pivot', 's1': 'S1', 's2': 'S2', 's3': 'S3', 's4': 'S4' }
        let dataSource = pivots?.length ? Object.keys(points).map(p => {
            let obj = { 'point': points[p] }
            pivots?.forEach(piv => {
                obj[piv.name] = piv?.value?.[p] || '-'
            })
            return obj
        }) : []

        console.log(columns, dataSource)

        if (loadingTechnicalsIndicators) {
            return dataSource.length ?
                <>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }} className="margin20Bottom">
                        <TimeFrameSelector />
                    </div>
                    <Table pagination={false} dataSource={dataSource} columns={loadingColumns} scroll={{ x: 'max-content' }} />
                </>
                :
                <ActivityLoaderMintbox />
        } else {
            return dataSource.length ?
                <>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }} className="margin20Bottom">
                        <TimeFrameSelector />
                    </div>
                    <Table pagination={false} dataSource={dataSource} columns={columns} scroll={{ x: 'max-content' }} />
                </>
                : <NoDataFound />
        }
    }

    const VolumeAnalysis = () => {
        const [selectedTabItem, setSelectedTabItem] = React.useState("Combined")
        const tabItems = [
            {
                key: 1,
                value: "Combined",
                label: "NSE + BSE",
            },
            {
                key: 2,
                value: "NSE",
                label: "NSE",
            },
            {
                key: 3,
                value: "BSE",
                label: "BSE",
            },

        ]
        const leftRef = React.useRef();
        const rightRef = React.useRef();
        const config = {
            chart: {
                type: 'column',
                height: 450,
                panning: {
                    enabled: true,
                    type: 'x'
                },
                scrollablePlotArea: {
                    scrollPositionX: 1,
                    minWidth: 200
                },
                events: {
                    load: function () {
                        const chart = this
                        let { dataMax } = chart.xAxis[0].getExtremes()
                        chart.xAxis[0].setExtremes(dataMax - 7, dataMax);
                        const moveLeft = () => {
                            let { min, max, dataMin } = chart.xAxis[0].getExtremes()
                            if (min - 7 >= dataMin) {
                                min -= 7
                                max -= 7
                            }
                            else if (min - 7 < dataMin && min > dataMin) {
                                min = 0;
                                max = 7;
                            }
                            chart.xAxis[0].setExtremes(min, max)
                        }
                        const moveRight = () => {
                            let { min, max, dataMax } = chart.xAxis[0].getExtremes()
                            if (max + 7 <= dataMax) {
                                min += 7
                                max += 7
                            }
                            else if ((max + 7 > dataMax) && (max < dataMax)) {
                                min = dataMax - 7
                                max = dataMax
                            }
                            chart.xAxis[0].setExtremes(min, max)
                        }
                        leftRef.current = moveLeft;
                        rightRef.current = moveRight;
                    }
                }
            },
            title: { text: "" },
            credits: { enabled: false },
            exporting: { enabled: false },
            xAxis: {
                categories: allVolumeData?.slice()?.reverse()?.map((el) => moment(el.index).format('D MMM')),
                // crossHair: true,
                min: 10,
                max: 10,
                scrollbar: {
                    enabled: true
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                },
                gridLineDashStyle: 'longdash',
                gridLineColor: 'var(--grey1)',
                stackLabels: {
                    enabled: true,
                    useHTML: true,
                    formatter: function () {
                        let tooltip = "";
                        this.axis.series.forEach((point) => {
                            if (point.name === "VWAP") {
                                tooltip += point.name + ": " + DecimalValueFormat({ num: point.yData[this.x], decimals: 2 }) + "<br/>";
                            } else if (point.name === "Total volume") {
                                tooltip += "Total: " + DecimalValueFormat({ num: point.yData[this.x], decimals: 0 }) + "<br/>";
                            } else if (point.name === "Delivery percentage") {
                                tooltip += "Delivery(%): " + DecimalValueFormat({ num: point.yData[this.x], decimals: 2 }) + "<br/>";
                            }
                        });
                        return "<div style='font-size:11px;color:var(--dark3)';font-weight:500;display:flex>" + tooltip + "</div>";
                    }
                },
            },
            legend: {
                enabled: true
            },
            tooltip: {
                shared: true,
                backgroundColor: "var(--dark4)",
                style: { color: "var(--grey5)", fontsize: "0.75rem" },
                useHTML: true,
                headerFormat: '<div class="textSM w-500 white">{point.key}</div>',
                valuePrefix: "",
                formatter: function () {
                    let tooltip = "<b>" + this.x + "</b><br/>";
                    this.points.forEach((point) => {
                        if (point.series.name === "VWAP") {
                            tooltip += point.series.name + ": " + point.y?.toFixed(2) + "<br/>";
                        } else if (point.series.name === "Delivery percentage") {
                            tooltip += point.series.name + ": " + DecimalValueFormat({ num: point.y, decimals: 2 }) + "<br/>";
                        } else {
                            tooltip += point.series.name + ": " + point.y + "<br/>";
                        }
                    });
                    return tooltip;
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false,
                    }
                },
                series: {
                    grouping: false,
                    lineWidth: 1,
                    label: {
                        connectorAllowed: false
                    },
                    marker: {
                        enabled: false
                    },
                }
            },
            series: [
                {
                    name: 'Trade volume',
                    color: 'pink',
                    data: allVolumeData?.slice()?.reverse()?.map((record) => {
                        return selectedTabItem === 'NSE' ? parseInt(record.total_traded_qty_nse / 1000)
                            : selectedTabItem === 'BSE' ? parseInt(record.total_traded_qty_bse / 1000)
                                : parseInt((record.total_traded_qty_nse + record.total_traded_qty_bse) / 1000)
                    }),
                    stack: 'volume',
                    dataLabels: {
                        enabled: true,
                        rotation: 0,
                        verticalAlign: 'middle',
                        align: 'center',
                        overflow: 'allow',
                        allowOverlap: true,
                        insid: true,
                        x: 0,
                        y: 0,
                        useHTML: true,
                        formatter: function () {
                            return "<div style='font-size:11px;color:var(--dark3);font-weight:500'>" + DecimalValueFormat({ num: this.y, decimals: 0 }) + "</div>";
                        }
                    }
                }, {
                    name: 'Delivery volume',
                    color: '#83BF6E88',
                    data: allVolumeData?.slice()?.reverse()?.map((record) => {
                        return selectedTabItem === 'NSE' ? parseInt(record.deliverable_qty_nse / 1000)
                            : selectedTabItem === 'BSE' ? parseInt(record.deliverable_qty_bse / 1000)
                                : parseInt((record.deliverable_qty_nse + record.deliverable_qty_bse) / 1000)
                    }),
                    stack: 'volume',
                    clip: false,
                    dataLabels: {
                        enabled: true,
                        rotation: 0,
                        align: 'center',
                        verticalAlign: 'middle',
                        overflow: 'allow',
                        allowOverlap: true,
                        insid: true,
                        x: 0,
                        y: 0,
                        useHTML: true,
                        formatter: function () {
                            return "<div style='font-size:11px;color:var(--dark3);font-weight:500'>" + DecimalValueFormat({ num: this.y, decimals: 0 }) + "</div>";
                        }
                    }
                },
                {
                    name: 'Total volume',
                    color: 'transparent',
                    type: 'line',
                    data: allVolumeData?.slice()?.reverse()?.map((record) => {
                        return selectedTabItem === 'NSE' ? parseInt((record.deliverable_qty_nse + record.total_traded_qty_nse) / 1000)
                            : selectedTabItem === 'BSE' ? parseInt((record.deliverable_qty_bse + record.total_traded_qty_bse) / 1000)
                                : parseInt((record.deliverable_qty_nse + record.deliverable_qty_bse + record.total_traded_qty_nse + record.total_traded_qty_bse) / 1000)
                    }),
                    showInLegend: false,
                    marker: false,
                    clip: false,
                },
                {
                    name: 'Delivery percentage',
                    color: 'transparent',
                    type: 'line',
                    data: allVolumeData?.slice()?.reverse()?.map((record) => {
                        return selectedTabItem === 'NSE' ? record.deliverable_qty_nse * 100 / (record.total_traded_qty_nse + record.deliverable_qty_nse)
                            : selectedTabItem === 'BSE' ? record.deliverable_qty_bse * 100 / (record.total_traded_qty_bse + record.deliverable_qty_bse)
                                : (record.deliverable_qty_nse + record.deliverable_qty_bse) * 100 / (record.deliverable_qty_nse + record.deliverable_qty_bse + record.total_traded_qty_nse + record.total_traded_qty_bse)
                    }),
                    showInLegend: false,
                    marker: false,
                    clip: false,
                },
                {
                    name: 'Average weekly total volume',
                    type: 'line',
                    color: 'var(--primary)',
                    clip: false,
                    data: allVolumeData?.slice()?.reverse()?.map((record) => {
                        return selectedTabItem === 'NSE' ? parseInt((record.deliverable_qty_nse_1W_avg + record.total_traded_qty_nse_1W_avg) / 1000)
                            : selectedTabItem === 'BSE' ? parseInt((record.deliverable_qty_bse_1W_avg + record.total_traded_qty_bse_1W_avg) / 1000)
                                : parseInt((record.deliverable_qty_nse_1W_avg + record.deliverable_qty_bse_1W_avg + record.total_traded_qty_nse_1W_avg + record.total_traded_qty_bse_1W_avg) / 1000)
                    })
                },
                {
                    name: 'VWAP',
                    type: 'line',
                    color: 'transparent',
                    data: allVolumeData?.slice()?.reverse()?.map((record) => {
                        return record.vwap_daily
                    }),
                    clip: false,
                    showInLegend: false,
                    marker: false,
                }
            ],
            boost: {
                useGPUTranslations: true,
            }
        }

        if (loadingTechnicalsVolume) {
            return <ActivityLoaderMintbox />
        }
        else if (allVolumeData?.length) {
            return <>
                <CustomSegmentedTab options={tabItems} value={selectedTabItem} onChange={(e) => setSelectedTabItem(e)} isBlock={false} keepSameInMobile />
                <div className="dark3 w-400 textXS py-2">Note: All volume data is in '000 unless mentioned otherwise.</div>
                <div className='d-flex align-items-center'>
                    <BiChevronLeft onClick={() => {
                        if (leftRef.current) {
                            leftRef.current();
                        }
                    }} style={{ fontSize: "3rem", color: "var(--dark1)", cursor: "pointer" }} />
                    <div style={{ flex: 1 }}>
                        <HighchartsReact highcharts={Highcharts} options={config} />
                    </div>
                    <BiChevronRight onClick={() => {
                        if (rightRef.current) {
                            rightRef.current();
                        }
                    }} style={{ fontSize: "3rem", color: "var(--dark1)", cursor: "pointer" }} />
                </div>
            </>
        } else return <NoDataFound />
    }

    const RangeAnalysis = () => {
        let data = allRangeAnalysisData;
        const ltp = isCustomIndex ? indexPrice?.currentLTP : GetLTP({ fundData: fundData, symbol: SYMBOL })
        const textFormat = {
            '1D': '1 Day',
            '1W': '1 Week',
            '1M': '1 Month',
            '3M': '3 Months',
            '6M': '6 Months',
            '1Y': '1 Year',
            '2Y': '2 Years',
            '3Y': '3 Years',
            '5Y': '5 Years',
            '10Y': '10 Years',
            '20Y': '20 Years'
        }
        const dataSource = Object.keys(data)?.map(k => {
            if (k === 'symbol') {
                return null
            } else if (k === 'last_date') {
                return null
            } else if (data[k]?.length) {
                return { 'time': k, ...data[k][0] }
            } else {
                return null
            }
        }).filter(x => x)
        const columns = [{
            "title": 'Time',
            "dataIndex": 'time',
            render: (text) => {
                return <span style={{ whiteSpace: 'nowrap' }}>{textFormat[text]}</span>
            }
        },
        {
            "title": "Summary",
            dataIndex: "summary",
            render: (text, record) => {
                return <OHLC style={{ padding: "1rem 1rem" }} open={record.open} high={Math.max(ltp, record.high)} low={Math.min(ltp, record.low)} close={ltp} />
            },
            "align": "center",
            width: 250
        },
        {
            "title": 'Absolute returns',
            "dataIndex": 'absolute_returns_pct',
            render: (text, record) => {
                // return <span style={{ whiteSpace: 'nowrap' }}>{RedGreenText({ text: DecimalValueFormat({ num: text, decimals: 2, suffix: ' %' }) || '-', value: text, arrow: true })}</span>
                const x = (ltp / record.prev_close - 1) * 100
                return <span style={{ whiteSpace: 'nowrap' }}>{RedGreenText({ text: DecimalValueFormat({ num: x, decimals: 2, suffix: ' %' }) || '-', value: x, arrow: true })}</span>
            },
            "align": "right"
        },
        {
            "title": 'CAGR',
            "dataIndex": 'CAGR',
            render: (text, record) => {
                // return <span style={{ whiteSpace: 'nowrap' }}>{RedGreenText({ text: DecimalValueFormat({ num: text, decimals: 2, suffix: ' %' }) || '-', value: text, arrow: true })}</span>
                if (record.candle_diff < 360) {
                    return '-'
                }
                const x = (Math.pow(ltp / record.open, 365 / record.candle_diff) - 1) * 100
                return <span style={{ whiteSpace: 'nowrap' }}>{RedGreenText({ text: DecimalValueFormat({ num: x, decimals: 2, suffix: ' %' }) || '-', value: x, arrow: true })}</span>
            },
            "align": "right"
        },
        {
            "title": 'Open',
            "dataIndex": 'open',
            render: (text, record) => {
                return DecimalValueFormat({ num: text, decimals: 2 }) || '-'
            },
            "align": "right"
        },
        {
            "title": 'High',
            "dataIndex": 'high',
            render: (text, record) => {
                // return DecimalValueFormat({ num: text, decimals: 2 }) || '-'
                return DecimalValueFormat({ num: Math.max(ltp, text), decimals: 2 }) || '-'
            },
            "align": "right"
        },
        {
            "title": 'Low',
            "dataIndex": 'low',
            render: (text, record) => {
                // return DecimalValueFormat({ num: text, decimals: 2 }) || '-'
                return DecimalValueFormat({ num: Math.min(ltp, text), decimals: 2 }) || '-'
            },
            "align": "right"
        },
        {
            "title": 'Close',
            "dataIndex": 'close',
            render: (text, record) => {
                // return DecimalValueFormat({ num: text, decimals: 2 }) || '-'
                return DecimalValueFormat({ num: ltp, decimals: 2 }) || '-'
            },
            "align": "right"
        },
        {
            "title": <span>% Below High</span>,
            "dataIndex": 'distance_pct_from_high',
            render: (text, record) => {
                // return <span style={{ whiteSpace: 'nowrap' }}>{DecimalValueFormat({ num: text, decimals: 2, suffix: ' %' }) || '-'}</span>
                const x = (ltp / Math.max(ltp, record.high) - 1) * 100
                return <span style={{ whiteSpace: 'nowrap' }}>{DecimalValueFormat({ num: x, decimals: 2, suffix: ' %' }) || '-'}</span>
            },
            width: 110,
            "align": "right"
        },
        {
            "title": <span>% Above Low</span>,
            "dataIndex": 'distance_pct_from_low',
            render: (text, record) => {
                // return <span style={{ whiteSpace: 'nowrap' }}>{DecimalValueFormat({ num: text, decimals: 2, suffix: ' %' }) || '-'}</span>
                const x = (ltp / Math.min(ltp, record.low) - 1) * 100
                return <span style={{ whiteSpace: 'nowrap' }}>{DecimalValueFormat({ num: x, decimals: 2, suffix: ' %' }) || '-'}</span>
            },
            width: 110,
            "align": "right"
        },
        ]

        const loadingColumns = columns.map(c => {
            if (c.title === "Summary") {
                return { ...c, render: () => { return <Skeleton.Avatar style={{ height: "28px", width: "120px", borderRadius: 4 }} active /> } }
            }
            else return { ...c, render: () => { return <Skeleton.Avatar style={{ height: "18px", width: "60px", borderRadius: 4 }} active /> } }
        })
        if (loadingTechnicalsRange) {
            return dataSource.length ? <Table pagination={false} dataSource={dataSource} columns={loadingColumns} /> : <ActivityLoaderMintbox />
        } else {
            return dataSource.length ? <Table pagination={false} dataSource={dataSource} columns={columns} scroll={{ x: 'max-content' }} /> : <NoDataFound />
        }
    }

    const TechnicalChart = () => {
        const loadScript = (FILE_URL, async = true, type = "text/javascript") => {
            return new Promise((resolve, reject) => {
                try {
                    const scriptEle = document.createElement("script");
                    scriptEle.type = type;
                    scriptEle.async = async;
                    scriptEle.src = FILE_URL;

                    scriptEle.addEventListener("load", (ev) => {
                        resolve({ status: true });
                    });

                    scriptEle.addEventListener("error", (ev) => {
                        reject({
                            status: false,
                            message: `Failed to load the script ${FILE_URL}`
                        });
                    });

                    document.head.appendChild(scriptEle);
                } catch (error) {
                    reject(error);
                }
            });
        };

        React.useEffect(() => {
            loadScript("/charting_library/charting_library.standalone.js");
            loadScript("/datafeeds/udf/dist/bundle.js").then(() => {
                new window.TradingView.widget({
                    "container": 'tradingview_bd52e1',
                    "library_path": '/charting_library/',
                    "symbol_search_request_delay": 100,
                    "datafeed": datafeed,
                    "autosize": true,
                    "symbol": SYMBOL,
                    "interval": "D",
                    "timezone": "Asia/Kolkata",
                    "disabled_features": ["header_compare", "symbol_search_hot_key", "header_symbol_search", "control_bar"],
                    "style": "1",
                    "locale": "en",
                    "enable_publishing": false,
                    "calendar": false,
                    "support_host": "https://www.tradingview.com"
                });
            }, []);
        })
        return <div className='tradingview-widget-container d-flex flex-column' style={{}}>
            <div id='tradingview_bd52e1' style={{ height: '500px' }} />
        </div>
    }

    const RefreshComponent = ({ last_date }) => {

        return <>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', alignItems: 'end' }}>
                <div className="d-flex align-items-center shadowSM textXS w-500 white" style={{
                    gap: "0.5rem", background: "var(--black)", borderRadius: "5rem", padding: "0.5rem 1rem",
                    cursor: "pointer", width: "fit-content", alignSelf: 'end'
                }}>
                    <IoMdRefresh style={{ fontSize: '0.9rem' }} /> Refresh
                </div>
                {last_date?.length ? <div className="textXS dark2">Last updated: {moment(last_date).format("Do MMM YY, h:mm a")}</div> : ''}
            </div>
        </>
    }

    const tabData = [
        {
            label: "Price, returns & range",
            key: 1,
            value: "RangeAnalysis",
            show: true,
            children: <RangeAnalysis />,
            heading: "Price, returns & range analysis",
            subHeading: "Explore the stock's price, returns, and range analysis in depth. Analyze price performance across different timeframes to gauge the strength.",
            rightLinkText: <RefreshComponent last_date={allRangeAnalysisData?.last_date} />,
            rightTextLinkonClick: () => onRefereshClick()
        },
        {
            label: "Volume analysis",
            key: 2,
            value: "Volume",
            show: isIndex ? false : true,
            children: VolumeAnalysis(),
            heading: "Volume Analysis",
            subHeading: "Dive into the volume analysis of the stock to gain insights into trading activity. Analyze volumes across different exchanges along with important data like delivered and traded volumes, and VWAP.",
        },
        {
            label: "Indicators",
            key: 3,
            value: "Indicators",
            show: true,
            children: <AllIndicators />,
            heading: "Indicators",
            subHeading: "Explore a comprehensive array of technical indicators along with moving averages and oscillators to conduct an in-depth analysis of the stock's price movements.",
            rightLinkText: <RefreshComponent last_date={allIndicatorData?.filter(i => i?.timeframe === timeFrame)?.[0]?.timestamp} />,
            rightTextLinkonClick: () => onRefereshClick()
        },
        {
            label: "Pivots",
            key: 4,
            value: "Pivots",
            show: true,
            children: <Pivots />,
            heading: "Pivots",
            subHeading: "Unlock insights into potential support and resistance levels with pivot points. Utilize pivot point analysis to identify critical levels where the market is likely to reverse or continue its trend.",
            rightLinkText: <RefreshComponent last_date={allIndicatorData?.filter(i => i?.timeframe === timeFrame)?.[0]?.timestamp} />,
            rightTextLinkonClick: () => onRefereshClick()
        },
        {
            label: "Technicals",
            key: 4,
            value: "Technicals",
            show: isIndex ? false : true,
            children: isMobile || isIndex ? <TechnicalChart /> : TechnicalChart(),
            heading: "Technicals",
            rightTextLinkonClick: () => onRefereshClick()
        },
    ].filter(t => t.show)

    return (
        <>
            <StickyScrollTabs isAccordionInMob queriesToStickToBot={['#sharpelyHeader', '#customTabsBarId']} tabData={tabData} />
        </>
    )
});

export default TechnicalAnalysis