import AccessControlledPage from 'Components/AccessControlledPage';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { formatDateMMMDDYYYY } from "Components/DateFormatter";
import { NoDataFound } from 'Components/NoDataFound';
import { CardHeader } from 'Components/atoms/SmallComponents';
import { useUserDataHooks } from 'CustomHooks/useUserDataHooks';
import { useGetAssetType } from 'Libs/utils';
import apiHelperPY from 'Services/api/ApiHelperPY';
import { Avatar, Card, Divider } from 'antd';
import React from 'react';
import { BiPlus } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

var { Meta } = Card
function AllModels() {
    const stockType = useGetAssetType(4);
    const etfType = useGetAssetType(1);
    const mfType = useGetAssetType(2);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accessLevelData = useSelector((state) => state?.accessLevel?.value);
    let user = useSelector((state) => state?.updateUserData?.value);
    const isMobile = window.innerWidth <= 760;
    const [models, setModels] = React.useState([])
    const [allPublishedModels, setallPublishedModels] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [allPublishers, setAllPublishers] = React.useState([])
    const { getUserStockModels } = useUserDataHooks();

    const loadData = async () => {

        let signals = await getUserStockModels();
        setModels(signals?.userModels?.reverse());
        let allPublishedModels = await apiHelperPY({ apiName: "getAllPublishedFactorModel" });
        setallPublishedModels(JSON.parse(allPublishedModels)?.filter((i) => i?.client_id !== user?.id))
        setLoading(false)

        setTimeout(() => {
            const hash = window.location.hash;
            if (hash) {
                const element = document.querySelector(String(hash));
                if (element) {
                    element.scrollTo({ behavior: 'smooth' });
                }
                console.log(element)
            }
        }, 300);
    }

    const getPublisherProfiles = async () => {
        let allPublishers = await apiHelperPY({ apiName: "getAllPublisherProfile", saveResponse: "sessionStorage" });
        allPublishers = JSON.parse(allPublishers);
        setAllPublishers(allPublishers);
    }

    React.useEffect(() => {
        getPublisherProfiles();
    }, []);

    React.useEffect(() => {
        loadData()
    }, [user])


    const instrumentTabs = [
        { key: 4, label: "Stock models", value: 3, val2: "stock" },
        { key: 1, label: "ETF models", value: 1, val2: "etf" },
        { key: 2, label: "MF models", value: 2, val2: "mf" },
    ]

    const [selectedInstrumentTab, setSelectedInstrumentTab] = React.useState(3);


    var cardDataItems = [{
        "label": "Total P/L",
        "key": "pnl"
    }, {
        "label": "Day change",
        "key": "day_change_per"
    }, {
        "label": "Abs. return",
        "key": "abs_change_per"
    },
    {
        "label": "XIRR",
        "key": "CAGR"
    },
        //  {
        //     "label": "1Y return",
        //     "key": "abs_change_per"
        // },
    ]

    return (
        <React.Fragment>
            <AccessControlledPage reqAccessLevel={1} />
            <div className="normalContainer">
                <Card>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                            <div style={{ flex: 3 }}>
                                <CardHeader heading={'My factor models'} headingType={2} />
                                {/* <div className='textXS dark3' style={{ marginTop: '0.25rem' }}>Track, Invest or Paper trade in strategies created by you or create a new personal strategy.</div> */}
                            </div>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                <button className="btnBlack d-flex align-items-center" style={{ gap: '0.5rem' }} onClick={() => {
                                    navigate("/stocks/factor-model")
                                }}>
                                    <BiPlus /> {isMobile ? '' : 'Create New'}
                                </button>
                            </div>
                        </div>
                        <div>
                            {
                                loading ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <ActivityLoaderMintbox />
                                </div> : models.length > 0 ?
                                    <div className='my-3' style={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr", gap: "1rem" }}>
                                        {models?.map((item) => {
                                            return (
                                                <Card
                                                    key={item?.sidnal_id}
                                                    bordered
                                                    className='w100 h100 shadowXS'
                                                    bodyStyle={{ width: "100%", height: "100%", cursor: 'pointer' }}
                                                    onClick={() =>
                                                        navigate(`/stocks/factor-model?fmName=${item?.signal_name}&fmId=${item?.signal_id}&userId=${item?.client_id}`)
                                                    }
                                                >
                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <Meta
                                                            style={{ flex: 1 }}
                                                            // avatar={<img loading="lazy"  src={magicLine} style={{ height: '2rem', width: '2rem', padding: '0.25rem', borderRadius: '50%', backgroundColor: 'var(--blueShade)' }} />}
                                                            title={<span className='textEllipsis1'>{item?.signal_name}</span>}
                                                            description={<span className='textXS textLight'>Created on {formatDateMMMDDYYYY(item?.created_on)}</span>}
                                                        />
                                                        {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            {strategy?.config?.is_live === 1 ? <Badge className='textXS' status="success" text="Live" /> : <Badge className='textXS' status="success" text="Paper trade" />}
                                        </div> */}
                                                    </div>

                                                    {/* <Divider className='my-3'/> */}
                                                    {/* <div className='mt-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        {cardDataItems?.map((item, index) => {
                                            return <div key={makeid(index + 10)}>
                                                <CardHeader
                                                    heading={item?.label}
                                                    textClassName='textXS textLight w-normal'
                                                    style={{
                                                        justifyContent: index === 0 ? "flex-start" : (index === cardDataItems?.length - 1) ? "flex-end" : "center"
                                                    }}
                                                />
                                                <CardHeader
                                                    heading={index === 0 ? CurrencyFormat(strategy?.stats?.[item?.key]) : item?.key === "CAGR" ? (monthDiff(new Date(strategy?.config?.go_live_timestamp), new Date())) <= 1 ? "-" : <RedGreenText
                                                        value={strategy?.stats?.[item?.key]}
                                                        text={DecimalValueFormat({ num: strategy?.stats?.[item?.key], suffix: "%" })}
                                                    /> : <RedGreenText
                                                        value={strategy?.stats?.[item?.key]}
                                                        text={DecimalValueFormat({ num: strategy?.stats?.[item?.key], suffix: "%" })}
                                                    />}
                                                    style={{
                                                        justifyContent: index === 0 ? "flex-start" : (index === cardDataItems?.length - 1) ? "flex-end" : "center"
                                                    }}
                                                    textClassName='textSM w-bold'
                                                />
                                            </div>
                                        })}
                                    </div> */}
                                                </Card>
                                            )
                                        })}
                                    </div>
                                    :
                                    <div>
                                        <NoDataFound msgMaxWidth='25rem' width='5rem' gap="1.5rem" bordered padding='3rem 1.25rem'
                                            ctaText={<span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}><BiPlus /> Create a new factor model</span>}
                                            ctaAction={() => navigate("/stocks/factor-model")}
                                            message='Looks like you haven’t created any model. Kick start your quantitative investment journey by creating your first factor model.' />
                                    </div>
                            }
                        </div>
                    </div>
                    <Divider id="community-models" />
                    <div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ flex: 3 }}>
                                <CardHeader heading={'Factor models created by sharpely community'} headingType={2} />
                                <div className='textXS dark3' style={{ marginTop: '0.25rem' }}>
                                    Explore models created by our community experts including SEBI registered entities. If you like a model, you can clone it and save as your own model.
                                </div>
                            </div>
                            {!isMobile && <div style={{ flex: 1 }}></div>}
                        </div>
                        <br></br>
                        {loading ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <ActivityLoaderMintbox />
                        </div> : allPublishedModels?.length === 0 ? <div>
                            <NoDataFound msgMaxWidth='25rem' width='5rem' gap="1.5rem" bordered padding='6rem 1.25rem'
                                message='Oops no factor models available right now!!' />
                        </div> : <div className='my-3' style={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr", gap: "1rem" }}>
                            {allPublishedModels?.map((item) => {
                                let publisherD = allPublishers?.filter((i) => i?.ClientId === item?.client_id)?.[0]
                                if (publisherD?.IsApproved === 1)
                                    return (
                                        <Card
                                            key={item?.signal_id}
                                            bordered
                                            style={{ flex: 1, cursor: 'pointer' }}
                                            className='w100 h100'
                                            onClick={() =>
                                                navigate(`/stocks/factor-model?fmName=${item?.signal_name}&fmId=${item?.signal_id}&userId=${item?.client_id}`)
                                            }
                                            // title={strategy?.StrategyName}
                                            bodyStyle={{ width: "100%", height: "100%" }}
                                        >
                                            <div className='mb-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "0.5rem" }}>
                                                <Meta
                                                    style={{ flex: 1, gap: 0 }}
                                                    avatar={<Avatar src={publisherD?.PublisherIcon} />}
                                                    title={<span className='textEllipsis1'>{item?.signal_name}</span>}
                                                    description={<div className='textXS textLight'>By {<Link style={{
                                                        color: "var(--primary)", cursor: "pointer",
                                                    }} to={`/publisher-profile?name=${publisherD?.Name?.replaceAll(" ", "-")}&id=${publisherD?.UID}`} onClick={(e) => { e.stopPropagation() }}>{String(publisherD?.Name)}</Link>} on {formatDateMMMDDYYYY(item?.created_on)}
                                                    </div>}
                                                />
                                            </div>
                                            <div className='textXS textEllipsis2' dangerouslySetInnerHTML={{ __html: `${item?.signal_desc?.replaceAll("{\"\n\"}", "")}` }}>
                                            </div>
                                        </Card>
                                    )
                                else
                                    return null
                            })}
                        </div>}
                    </div>
                </Card>
            </div>
        </React.Fragment >
    )
}

export default AllModels;