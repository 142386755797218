import { makeid } from "Libs/utils";
import { Input, Select, Tag } from "antd";
import React from "react";

export const CustomSelect = ({ customId = "random", options = [], value, style = {}, onChange = () => { }, tabs = [], showInput = false, inputVal = '', inputValType = 'text', inputPlaceholder = '',
    optionRenderType = '', ...props }) => {

    const [selectedTab, setSelectedTab] = React.useState(tabs?.length > 0 ? (tabs?.filter((el) => el?.show !== false)?.[0]?.value || "") : "");
    const [enteredVal, setEnteredVal] = React.useState(inputVal);
    const getFinalOptions = React.useCallback(() => {
        let temp = [...options];
        if (selectedTab) {
            temp = temp?.filter((ele) => ele?.tabType === selectedTab);
        }
        return temp;
    }, [selectedTab, options]);

    const finalOptions = getFinalOptions();
    const selectRef = React.useRef();

    const modifienOnChange = (item) => {
        onChange(item);
        if (selectRef.current) {
            selectRef.current.blur();
        }
        setTimeout(() => {
            setSelectedTab('');
        }, 100)
    }
    React.useEffect(() => {
        if (tabs?.length > 0) {
            setSelectedTab(tabs?.filter((el) => el?.show !== false)?.[0]?.value || "")
        }
        else {
            setSelectedTab('')
        }
    }, [tabs])

    React.useEffect(() => {
        setEnteredVal(inputVal);
    }, [inputVal])

    return (
        <React.Fragment>
            <div style={{ width: "100%" }}>
                <Select {...props} ref={selectRef} options={finalOptions} value={String(value)?.length === 0 ? undefined : value} style={{ ...style }} onChange={(val) => {
                    modifienOnChange(val)
                }}
                    // filterSort={(optionA, optionB) =>
                    //     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    // }
                    dropdownRender={(menu) => {
                        return (
                            <div style={{ width: "100%", maxWidth: "20rem" }}>
                                {tabs?.length > 0 && tabs?.filter((el) => el?.show !== false)?.length > 1 ? <div style={{
                                    display: "flex", gap: "10px", alignItems: "center", justifyContent: "flex-start", flexWrap: "wrap",
                                    padding: "0.75rem 0.75rem", background: "var(--white)", borderBottom: "1px solid var(--grey1)"
                                }}>
                                    {tabs?.filter((el) => el?.show !== false)?.concat(showInput ? [{ key: "CustomValue", label: "Custom Value", value: "CustomValue" }] :
                                        [])?.map((item) => {
                                            return (
                                                <div key={customId + makeid(3)}>
                                                    <Tag onClick={() => {
                                                        setSelectedTab(item?.value)
                                                    }} color={selectedTab === item?.value ? "var(--primary)" : "var(--grey3)"} className={"textXS w-500 " + (customId)} style={{
                                                        color: selectedTab === item?.value ? "var(--white)" : "var(--dark3)", padding: "0.25rem 0.5rem", borderRadius: 6,
                                                        border: `1px solid ${selectedTab === item?.value ? "var(--primary)" : "var(--grey1)"}`, cursor: "pointer", userSelect: "none",
                                                        margin: 0
                                                    }}>{item?.label}</Tag>
                                                </div>
                                            )
                                        })}
                                </div> : null}
                                <div>
                                    {showInput && selectedTab === 'CustomValue' ? <div style={{
                                        display: "flex", gap: "10px", alignItems: "center", justifyContent: "space-between", flexDirection: "column",
                                        padding: "0.75rem 0.75rem", background: "var(--white)", borderBottom: "1px solid var(--grey1)",
                                    }}>
                                        <>
                                            <Input autoFocus type={inputValType} placeholder={inputPlaceholder} value={enteredVal} onChange={(e) => { setEnteredVal(e.target.value) }} />
                                            <button className="btnBlack w100" disabled={inputValType === 'number' ? String(enteredVal)?.length <= 0 : enteredVal?.length <= 0}
                                                onClick={() => {
                                                    modifienOnChange(enteredVal)
                                                }}> Confirm </button>
                                        </>
                                    </div> : menu}
                                </div>
                            </div>
                        )
                    }} optionLabelProp='label' optionRender={(opt, i) => {
                        if (optionRenderType === 'metric') {
                            return <div className={customId} key={customId + makeid(3)}>
                                <div className={customId}>{opt?.label}</div>
                                <div style={{ fontSize: "11px" }} className={'w-500 dark3 ' + customId}>
                                    {[opt?.category_name, opt?.sub_category_name]?.filter((el) => el)?.join(', ')}
                                </div>
                            </div>
                        }
                        return <div className={`${customId} textEllipsis1`} key={customId + makeid(3)}>{opt?.label}</div>
                    }} />
            </div>
        </React.Fragment>
    )
}