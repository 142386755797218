import { Card, Dropdown, Tooltip } from "antd";
import AccessComponent from "Components/AccessComponent";
import AMCIcon from "Components/AMCIcon";
import { CardHeader } from "Components/atoms/SmallComponents";
import { CurrencyFormat } from "Components/CurrencyFormat";
import CustomSegmentedTab from "Components/CustomSegmentedTab";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import ListLoader from "Components/ListLoader";
import RedGreenText from "Components/RedGreenText";
import UpgradeToPro from "Components/UpgradeToPro";
import { fetchFundData } from "Libs/fetchFundData";
import { displayScoreValue, stock_universe } from "Libs/utils";
import React from "react";
import { FiChevronDown } from "react-icons/fi";
import { HiChevronRight } from "react-icons/hi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AdminServicesPY from "Services/api/AdminServicesPY";

const SuperStocks = ({ allStocks = [], type, widgetItem }) => {

    const [indexCode, setIndexCode] = React.useState('is_nifty_50')
    const [allFunds, setAllfunds] = React.useState([])
    const [allFilteredFunds, setAllFilteredfunds] = React.useState([])
    const [load, setLoad] = React.useState(true)

    const loadData = async () => {
        let funds = allStocks
        let result = await AdminServicesPY.getStocksColData({
            stocks: funds?.map((fund) => { return fund?.symbol }), cols: [...(stock_universe?.map((el) => el?.value)?.filter((el) =>
                el !== 'all')), 'sh_3fac_comp', 'style_box_code']
        })
        result = JSON.parse(result?.data)
        result = result?.map((fund) => {
            return {
                ...funds?.filter((ii) => ii?.symbol === fund?.symbol)?.[0],
                ...fund,
                // "gdf_symbol": fund?.nse_series === 2 ? fund?.symbol + ".BE" : fund?.nse_series === 3 ? fund?.symbol + ".BZ" : fund?.symbol
                "gdf_symbol": fund?.symbol
            }
        })
        result = result.filter(f => f?.['style_box_code'] === 2)?.sort((a, b) => b?.['sh_3fac_comp'] - a?.['sh_3fac_comp'])
        setAllfunds(result);
    }
    const getFilteredData = async () => {
        var allfundsc = type.value === 4 && indexCode !== 'all' ? allFunds.filter((i) => i[indexCode] === 1) : allFunds
        setAllFilteredfunds(allfundsc)
        setLoad(false)
    }

    React.useEffect(() => {
        setLoad(true)
        loadData()
    }, [])

    React.useEffect(() => {
        if (allFunds.length !== 0) {
            getFilteredData()
        }
    }, [allFunds, indexCode])

    return (
        <Card bordered={false} style={{ padding: 0 }} bodyStyle={{ padding: 0 }} className="mobCardBorderRadius0">
            <div className='pad20Container' style={{ display: "flex", alignItems: "center" }}>
                <CardHeader heading={widgetItem?.Name} headingType={2} />
                {type.value === 4 && <Dropdown
                    menu={{
                        items: stock_universe,
                        onClick: (e) => {
                            setIndexCode(e.key)
                        },
                        selectable: true,
                        defaultSelectedKeys: [indexCode],
                    }}

                    placement="bottomRight">
                    <span className="textXS" style={{ cursor: 'pointer' }}>{stock_universe?.filter((i) => i.key === indexCode)[0]?.label} <FiChevronDown size={12} /></span>
                </Dropdown>}
            </div>
            <AccessComponent component={load ? <ListLoader /> : <div>
                {allFilteredFunds?.length > 0 ? <>
                    {
                        allFilteredFunds?.slice(0, 10)?.map((f, i) => {
                            return <div key={i} className="borderAll" style={{ backgroundColor: i % 2 !== 0 ? 'var(--white)' : 'var(--grey4)' }}>
                                <Link to={type.basePath + '/stock-detail/' + f?.proper_name?.replaceAll(' ', '-') + '/' + f.symbol}>
                                    <div className="pad20Side6VerContainer" style={{ display: "flex", gap: "0.5rem", alignItems: "center" }} >
                                        <AMCIcon amcName={type.value === 4 ? f.symbol : f.amc_full_name} fundType={type.value} height='1.5rem' width="1.5rem"
                                            bseTicker={f?.bse_ticker} />
                                        <Tooltip title={<span className='textXS w-500'>{type.value === 4 ? f?.proper_name : f?.basic_name?.replace(" - Direct Plan", "")}</span>} arrow={false}>
                                            <div className="textXS black hoverBold" style={{ flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                                                {type.value === 4 ? f?.proper_name : f?.basic_name?.replace(" - Direct Plan", "")}
                                            </div>
                                        </Tooltip>
                                        <span className="textXS w-500 black" style={{ display: "flex", flexDirection: "column", gap: "0.1rem", alignItems: "flex-end", textAlign: "right", minWidth: "5rem" }}>
                                            {displayScoreValue({ text: f?.['sh_3fac_comp'] })}
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        })}
                    <div style={{ color: "var(--primary)", cursor: "pointer", textAlign: "right" }} className='textSM w-500 pad20Side12VerContainer' >
                        <Link to={`/stocks/stylebox`} className='d-flex align-items-center justify-content-end'>
                            {allFilteredFunds?.length > 10 ? 'See all' : 'Details'}<HiChevronRight />
                        </Link>
                    </div>
                </>
                    : <div className="textSM pad20Side6VerContainer my-4">
                        None of the stocks in the index are super stocks.
                    </div>}
            </div>} moduleKey="STOCK_STYLEBOX" upgradeComponent={<div className="pad20Container" style={{ margin: "5rem 0" }}>
                <UpgradeToPro moduleKey="STOCK_STYLEBOX" height="fit-content" />
            </div>} />
        </Card>
    )
}

export default SuperStocks;