import { loginImg1, loginImg2, loginImg3 } from 'Assets/images';
import React from "react";
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

export const LoginSignupCarousel = () => {

    return (
        <React.Fragment>
            <Swiper direction={'vertical'} pagination={{ clickable: true }} modules={[Pagination, Autoplay]} style={{ width: "100%", maxWidth: "100%" }} className='w100 h100'
                loop={true} autoplay={{ delay: 3500, disableOnInteraction: false, }} >
                <SwiperSlide>
                    <div className="h100">
                        <img loading="lazy"  src={loginImg1} style={{ width: "100%", maxWidth: "100%", height: "100%" }} />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="h100">
                        <img loading="lazy"  src={loginImg2} style={{ width: "100%", maxWidth: "100%", height: "100%" }} />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="h100">
                        <img loading="lazy"  src={loginImg3} style={{ width: "100%", maxWidth: "100%", height: "100%" }} />
                    </div>
                </SwiperSlide>
            </Swiper>
        </React.Fragment>
    )
}