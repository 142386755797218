import { stock_PaidCols } from "Libs/utils";
import { DataServicePY } from "./DataServicePY";

// const MINTBOX_CLIENT_ID = "Li2L9VO1eawEbsgLrHdpZjhmUdW6N8Cm";

const AdminServicesPY = {

	get_goal_scenarios: async (data) => {
		return DataServicePY.post('/getGoalScenariosv1', data);
	},

	getGoalCorpus: async (data) => {
		return DataServicePY.post('/getGoalTargetCorpusv1', data);
	},

	getGoalSIP: async (data) => {
		return DataServicePY.post('/getGoalSIPv1', data);
	},

	getGoalDetails: async (data) => {
		return DataServicePY.post('/getGoalDetailsv1', data);
	},

	getGoalReturn: async (data) => {
		return DataServicePY.post('/getGoalReturnsv1', data);
	},

	getETFTrailingReturns: async (id) => {
		return DataServicePY.get(
			`/core/getETFTrailingReturns/${id}`
		);
	},

	getETFTrackingError: async (id) => {
		return DataServicePY.get(
			`/core/getETFTrackingError/${id}`
		);
	},

	getFundsDetails: async ({ id }) => {
		return DataServicePY.get(
			"/core/getFundOverViewDetails/plan_id=" + id
		);
	},

	getCuratedPortfolioDetails: async (data) => {
		return DataServicePY.post(
			"/core/getOrCreateMintbox", data
		);
	},

	getMintboxOverview: async (data) => {
		return DataServicePY.post(
			"/core/getMintboxOverview", data
		);
	},


	getFundsHolding: async ({ id }) => {
		return DataServicePY.get(
			"/core/getHoldingDetails/plan_id=" + id
		);
	},

	getFundsRet: async ({ id }) => {
		return DataServicePY.get(
			"/core/getperfromancedetail/plan_id=" + id
		);
	},

	getEtfs: async () => {
		return DataServicePY.get(
			"/core/getAllFundsV2/instrumentType=1"
		);
	},

	getMFs: async () => {
		return DataServicePY.get(
			"/core/getAllFundsV2/instrumentType=0"
		);
	},
	getStocks: async () => {
		return DataServicePY.get(
			"/core/getAllFundsV2/instrumentType=2"
		);
	},
	getCompData: async (data) => {
		return DataServicePY.post(
			`/core/getFundsComparisonDataByISIN`, data
		);
	},

	getStocksColData: async (data) => {
		if (data) {
			if (!data?.all_cols) {
				data = {
					...data,
					cols: [...new Set(['symbol', ...stock_PaidCols, ...data?.cols])]
				}
			}
		}
		return DataServicePY.post(
			`/core/getStocksColData`, data
		);
	},


	getAllScreenedStocksNew: async (data) => {
		return DataServicePY.post(
			`/core/getAllScreenedStocksNew`, data
		);
	},

	getAllScreenedStocksAdvV2: async (data) => {
		return DataServicePY.post(
			`/core/getAllScreenedStocksAdvV2`, data
		);
	},

	getAllScoreStocksAdvV2: async (data) => {
		return DataServicePY.post(
			`/core/getAllScoreStocksAdvV2`, data
		);
	},


	back_test_screen: async (data) => {
		return DataServicePY.post(
			`/core/back_test_screen`, data
		);
	},



	getFundsComparisionData: async ({ type, data }) => {
		return DataServicePY.post(
			`/core/getFundsComparisonData/type=${type}`, data
		);
	},
	getStockPerformanceDetail: async ({ symbol }) => {
		return DataServicePY.get(
			`/core/getstockperfromancedetail/symbol=` + symbol
		);
	},
	getHistoricalCharts: async ({ symbol }) => {
		return DataServicePY.get(
			`/core/get_hisotorical_charts/symbol=` + symbol
		);
	},
	getFinancialStatements: async ({ symbol, fsym_id, industry_code }) => {
		return DataServicePY.get(
			`/core/getFinancialStatements/symbol=` + symbol + "/fsym_id=" + fsym_id + "/industry_code=" + industry_code
		);
	},
	getOwnership: async ({ symbol, fsym_id, industry_code }) => {
		return DataServicePY.get(
			`/core/getShareHoldingsData/symbol=` + symbol + "/fsym_id=" + fsym_id + "/industry_code=" + industry_code
		);
	},
	getEstimates: async ({ symbol, fsym_id, industry_code }) => {
		return DataServicePY.get(
			`/core/getEstimatesData/symbol=` + symbol + "/fsym_id=" + fsym_id + "/industry_code=" + industry_code
		);
	},

	getAllCheckLists: async ({ symbol, clientId }) => {
		return DataServicePY.get(
			`/core/get_all_check_lists/symbol=` + symbol + '/client_id=' + clientId
		);
	},
	getStockScoreCard: async ({ symbol }) => {
		return DataServicePY.get(
			`core/get_stock_score_card/symbol=${symbol}`
		)
	},
	getMetricDescription: async (type) => {
		return DataServicePY.get(
			`/core/getMetricDescription/instrument_type=` + type
		);
	},

	getScreenerBacktestData: async (data) => {
		return DataServicePY.post(
			`/core/backtestScreener`, data
		);
	},

	getBacktestSignal: async (data) => {
		return DataServicePY.post(
			`/core/backtestSignal`, data
		);
	},

	getScreenerMetricBacktestData: async ({ metric_code, universe_code }) => {
		return DataServicePY.post(
			`/core/backtestScreenerMetric/metric_code=${metric_code}/universe_code=${universe_code}`
		);
	},

	getStockStyles: async () => {
		return DataServicePY.get(
			`/core/getStockStyles`
		);
	},

	getSharpelyStyleBoxBacktest: async () => {
		return DataServicePY.get(
			`/core/getSharpelyStyleBoxBacktest`
		);
	},

	getMFInsights: async ({ planId, assetType }) => {
		return DataServicePY.get(
			`/core/mf_insights/plan_id=${planId}/asset_type=${assetType}`
		);
	},
	getMFInsightsV1: async ({ planId, assetType }) => {
		return DataServicePY.get(
			`/core/mf_insightsV1/plan_id=${planId}/asset_type=${assetType}`
		);
	},
	getStockInsights: async ({ ticker }) => {
		return DataServicePY.get(
			`/core/stock_insights/ticker=${ticker}`
		);
	},

	getSignalList: async (data) => {
		return DataServicePY.post(
			`/core/getSignalList`, data
		);
	},


	getSectorsClassificationData: async (data) => {
		return DataServicePY.get(
			`/core/getSectorsClassification/weight_method=` + data
		);
	},


	getPortfolioWeights: async (data) => {
		return DataServicePY.post(
			`/core/getPortfolioWeights`, data
		);
	},

	get_weights_and_min_inv: async (data) => {
		return DataServicePY.post(
			`/core/get_weights_and_min_inv`, data
		);
	},

	getInstrumentCharts: async (data) => {
		return DataServicePY.post(
			`/core/getInstrumentAllCharts`, data
		);
	},

	getInstrumentPerfCharts: async (data) => {
		return DataServicePY.post(
			`/core/getInstrumentPerfCharts`, data
		);
	},

	getInstrumentOtherCharts: async (data) => {
		return DataServicePY.post(
			`/core/getInstrumentOtherCharts`, data
		);
	},

	getAllScreenedFunds: async (data) => {
		return DataServicePY.post(
			`/core/getAllScreenedFunds`, data
		);
	},

	getAllScreenedFundsFromAdvQuery: async (data) => {
		return DataServicePY.post(
			`/core/getAllScreenedFundsFromAdvQuery`, data
		);
	},


	getScreenerQueryToString: async (data) => {
		return DataServicePY.post(
			`/core/getScreenerQueryToString`, data
		);
	},

	getMFAlphaAnalysis: async ({ category_id, benchmark_id, start_date, end_date, instrument_type }) => {
		return DataServicePY.get(
			`/core/getMFAlphaAnalysis/category_id=${category_id}/benchmark_id=${benchmark_id}/start_date=${start_date}/end_date=${end_date}/instrument_type=${instrument_type}`
		);
	},


	earningsDashboard: async (date) => {
		return DataServicePY.get(
			`/core/earningsDashboard/date=${date}`
		);
	},
	getMethodologyAndDisclaimer: async (valType) => {
		return DataServicePY.get(
			`/core/getMethodologyAndDisclaimer/valType=${valType}`
		)
	},

	getSectorsHistoricalCharts: async (sector_code) => {
		return DataServicePY.get(
			`/core/getSectorsHistoricalCharts/sector_code=${sector_code}`
		)
	},
	getBacktestNoData: async (valType) => {
		return DataServicePY.get(
			`/core/getBackTestNoData/valType=${valType}`
		)
	},
	getAllSectorData: async () => {
		return DataServicePY.get(
			`/core/getAllSectorData`
		)
	},
	getIndividualSectorData: async (sector_code) => {
		return DataServicePY.get(
			`/core/getIndividualSectorData/sector_code=${sector_code}`
		)
	},
	fetchSharpelyBenchmarkData: async () => {
		return DataServicePY.get(
			`/core/fetchSharpelyBenchmarkData`
		)
	},
	getSegmentsApi: async (fsym_id) => {
		return DataServicePY.get(
			`core/getSegmentsApi/fsym_id=${fsym_id}`
		)
	},
	getFinancialStatementsV2: async ({ fsym_id, gen_ind_code, ticker }) => {
		return DataServicePY.get(
			`/core/getFinancialStatementsV2/fsym_id=${fsym_id}/gen_ind_code=${gen_ind_code}/ticker=${ticker}`
		);
	},
	getStockInsightsDetailed: async ({ ticker }) => {
		return DataServicePY.get(
			`/core/stock_insights_detailed/ticker=${ticker}`
		)
	},
	getStockPriceVolFull: async ({ ticker }) => {
		return DataServicePY.get(
			`/core/getStockPriceVolFull/${ticker}`
		)
	},
	getShareholdingsV1: async ({ symbol }) => {
		return DataServicePY.get(
			`/core/getShareHoldingsDataV1/symbol=${symbol}`
		)
	},
	rrCalculator: async (data) => {
		return DataServicePY.post(`/core/mf-calculator/RRCalculator`, data)
	},
	sipCalculator: async (data) => {
		return DataServicePY.post(`/core/mf-calculator/SIPCalculator`, data)
	},
	swpCalculator: async (data) => {
		return DataServicePY.post(`/core/mf-calculator/SWPCalculator`, data)
	},
	getCalculatorDates: async (data) => {
		return DataServicePY.post(`/core/mf-calculator/get_calculator_dates`, data)
	},
	getPortfolioPerfOnly: async (data) => {
		return DataServicePY.post(`/core/get_portfolio_perf_only`, data)
	},
	getPortfolioPerformanceOnly: async (data) => {
		return DataServicePY.post(`/core/get_portfolio_performance_only`, data)
	},
	getPortfolioPerfAndTearsheet: async (data) => {
		return DataServicePY.post(`/core/get_portfolio_perf_and_tearsheet`, data)
	},
	getPortfolioOverview: async (data) => {
		return DataServicePY.post(`/core/get_portfolio_overview`, data)
	},
	getPortfolioFactsheet: async (data) => {
		return DataServicePY.post(`/core/get_portfolio_factsheet`, data)
	},
	getPortfolioInsights: async (data) => {
		return DataServicePY.post(`/core/get_portfolio_insights`, data)
	},
	getStockResources: async (symbol) => {
		return DataServicePY.get(`/core/get_stock_resources/symbol=${symbol}`)
	},

	genNewBasketOrder: async (data) => {
		return DataServicePY.post(`/core/basket-order/gen_new_basket_order`, data)
	},
	genRebalOrder: async (data) => {
		return DataServicePY.post(`/core/basket-order/gen_rebal_order`, data)
	},
	genSellOrder: async () => {
		return DataServicePY.get(`/core/load_function_map`)
	},
	getIndexCoverageList: async () => {
		return DataServicePY.get(`/core/get_index_coverage_list`)
	},
	getIndividualIndexData: async (id) => {
		return DataServicePY.get(`/core/get_individual_index_data/index_id=${id}`)
	},
	getIndividualIndexDataNew: async (id) => {
		return DataServicePY.get(`/core/get_individual_index_data_new/id=${id}`)
	},
	getIndexOHLC: async (id) => {
		return DataServicePY.get(`/core/get_index_ohlc/id=${id}`)
	},
	getIndexClose: async (id) => {
		return DataServicePY.get(`/core/get_index_close/id=${id}`)
	},
	getAllIndexData: async () => {
		return DataServicePY.get(`/core/get_all_index_data`)
	},
	getIndexConstituents: async () => {
		return DataServicePY.get(`/core/get_index_constitiuents`)
	},
	getEventCalendar: async () => {
		return DataServicePY.get(`/core/get_event_calendar`)
	},
	getAnnouncementFeed: async () => {
		return DataServicePY.get(`/core/get_announcement_feed`)
	},
	getStocksFeed: async (data) => {
		return DataServicePY.post(`/core/getStocksFeed`, data)
	},
	getAllSharpelyPlans: async () => {
		return DataServicePY.get(`/core/getAllSharpelyPlans`)
	},
	loadFunctionMap: async () => {
		return DataServicePY.get(`/core/load_function_map`)
	},

	evaluateScreenVariable: async (data) => {
		return DataServicePY.post(
			`/core/evaluate_variable`, data
		);
	},

	evaluateAlertCondition: async (data) => {
		return DataServicePY.post(
			`/core/evaluate_alert_condition`, data
		);
	},

	getIndicators: async (data) => {
		return DataServicePY.post(`/core/analysis/indicators`, data)
	},
	getVolumeAnalysis: async (data) => {
		return DataServicePY.post(`/core/analysis/volume`, data)
	},
	getRangeAnalysis: async (data) => {
		return DataServicePY.post(`/core/analysis/rangeAnalysis`, data)
	},
	getBulkBlockInsiderTrades: async (data) => {
		return DataServicePY.post(`/core/getBulkBlockInsiderTrades`, data)
	},
	getSastData: async (data) => {
		return DataServicePY.post(`/core/getSastTransactions`, data)
	},
	getPaperTradeOrder: async (data) => {
		return DataServicePY.post(`/core/getPaperTradeOrder`, data)
	},

	placeOrderAndSaveStrategy: async (data) => {
		return DataServicePY.post(`/core/placeOrderAndSaveStrategy`, data)
	},

	getStrategyDetails: async (id) => {
		return DataServicePY.get(`/core/getStrategyDetails/id=${id}`)
	},

	generateRebalanceOrder: async (id) => {
		return DataServicePY.get(`/core/generateRebalanceOrder/id=${id}`)
	},

	rebalanceStrategy: async (id, data) => {
		return DataServicePY.post(`/core/rebalanceStrategy/id=${id}`, data)
	},

	placeManualOrder: async (id, data) => {
		return DataServicePY.post(`/core/placeManualOrder/id=${id}`, data)
	},

	loadOneminutePriceBars: async (data) => {
		return DataServicePY.post(
			`/core/loadOneminutePriceBars`, data
		);
	},

	updateStrategyParams: async (data) => {
		return DataServicePY.post(`/core/editStrategy`, data)
	},

	getAllStrategiesByClientId: async ({ clientId }) => {
		return DataServicePY.get(`/core/getAllStrategiesByClientId/id=${clientId}`)
	},

	getAllStrategiesPublished: async () => {
		return DataServicePY.get(`/core/getAllStrategiesPublished`)
	},


	getAllPublisherProfile: async () => {
		return DataServicePY.get(`/core/getAllPublisherProfile`)
	},

	getAllPublishedScreens: async () => {
		return DataServicePY.get(`/core/getAllPublishedScreens`)
	},

	getAllPublishedFactorModel: async () => {
		return DataServicePY.get(`/core/getAllPublishedFactorModel`)
	},

	get_rrg_data: async (data) => {
		return DataServicePY.post(`/core/get_rrg_data`, data)
	},

	//live-tades
	getLiveTradeOrder: async (data) => {
		return DataServicePY.post(`/core/getPaperTradeOrder`, data)
	},

	getordersbytag: async (data) => {
		return DataServicePY.post(`/core/getordersbytag`, data)
	},

	placeLiveTradeOrderAndSaveStrategy: async (data) => {
		return DataServicePY.post(`/core/placeOrderAndSaveStrategy`, data)
	},

	deleteStrategy: async ({ id }) => {
		return DataServicePY.get(`/core/deleteStrategyById/id=${id}`)
	},
	getFunctionDoc: async ({ fileName }) => {
		return DataServicePY.get(`/core/getFuncDescHtml?name=${fileName}`)
	},
	getInsiderDashboard: async (data) => {
		return DataServicePY.post(`/core/get_insider_dashboard`, data)
	},
	getInsiderTradesTable: async (data) => {
		return DataServicePY.post(`core/get_insider_table_full`, data);
	},
	getNFODetails: async ({ launched_in = 1 }) => {
		return DataServicePY.get(`/core/get_nfo_details/launched_in=${launched_in}`)
	},
	getFMDetails: async ({ plan_id }) => {
		return DataServicePY.get(`/core/get_fm_details/plan_id=${plan_id}`)
	}
};

export default AdminServicesPY;