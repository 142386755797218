import { sortFunds } from "Libs/utils";
import { Collapse, Select, Table, Tag, Timeline } from "antd";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from "highcharts/modules/heatmap";
import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { TiDelete } from "react-icons/ti";
import ActivityLoaderMintbox from "./ActivityLoaderMintbox";
import { CurrencyFormat } from "./CurrencyFormat";
import CustomSegmentedTab from "./CustomSegmentedTab";
import CustomSideTabs from "./CustomSideTabs";
import { changeObjKey } from "./DataCustomizer";
import { DecimalValueFormat } from "./DecimalValueFormat";
import Disclaimer from "./Disclaimer";
import NoBackTestData from "./NoBackTestData";
import { NoDataFound } from "./NoDataFound";
import PerfBenchChart from "./PerfBenchChart";
import ScreenSignalBacktestChart from "./ScreenSignalBacktestChart"
import CustomLabelValueCards from "Components/CustomLabelValue";
import RedGreenText from "./RedGreenText";
import TableProgressBarFormat from "./TableProgessBarFormat";
import { CardHeader } from "./atoms/SmallComponents";
import { formatDateMMMDDYYYY } from "./DateFormatter";
HighchartsHeatmap(Highcharts)

const ScreenerOrSignalBacktest = ({
    backTestData = {},
    allFundsData = [],
    type = {},
    initial_capital = 100000,
    chartData = [],
    backTestModalLoading = true,
    xAxisKeyForPerfChart = 'Date',
    isSuperScreener = false,
    backtestType = 'screener'
}) => {


    // console.log(backTestData)

    const [topTabType, setTopTabType] = React.useState('id1')
    const [tradeLog, setTradelog] = React.useState('id1')
    const [tableDrop, settableDrop] = React.useState('perf')

    const [weightMethodOptions, setweightMethodOptions] = React.useState()
    const [selectedWeightMethod, setselectedWeightMethod] = React.useState()

    const WorstDrawdowns = () => {
        const dataSource = Object.values(backTestData?.perfromance_tables?.worst_5_drawdowns)

        const columns = [
            {
                title: 'Started on',
                dataIndex: 'Started',
                key: 'Started',
                width: 150,
                fixed: 'left'
            },
            {
                title: 'Drawdown',
                dataIndex: 'Drawdown',
                key: 'Drawdown',
                align: "right",
                render: (text) => DecimalValueFormat({ num: text, suffix: "%", placeholder: "-" })
            },
            {
                title: 'Lasted for (days)',
                dataIndex: 'Days',
                key: 'Days',
                align: "right",
            },
            {
                title: 'Recovered on',
                dataIndex: 'Recovered',
                key: 'Recovered',
                align: "right",
            }
        ]
        return <Table dataSource={dataSource} columns={columns} size={'small'} bordered className='textSM black' pagination={false} locale={{
            emptyText: <NoDataFound />
        }} />
    }

    const TableConstructor = ({ tableName = "", tableData = {}, showNameColumn = false, isTranspose = false }) => {
        if (isTranspose) {
            var dataSource = Object.values(tableData)
            var dataSourceKeys = Object.keys(tableData)

            var smDataSource = Object.keys(dataSource[0])
            smDataSource = smDataSource.map((key, index) => {
                var i = {
                    "key": key,
                    "name": key
                }
                dataSourceKeys.map((finalkey, index) => {
                    i = {
                        ...i,
                        [finalkey]: dataSource[index][key] !== '-' && dataSource[index][key] !== '+' && new Date(dataSource[index][key]) !== "Invalid Date" && !dataSource[index][key]?.includes("%") && !dataSource[index][key]?.includes(".") ? formatDateMMMDDYYYY(dataSource[index][key]) : dataSource[index][key]
                    }
                })
                return i
            })
            dataSourceKeys = ["name"]?.concat(dataSourceKeys)
            var columns = dataSourceKeys.map((key, index) => {
                return {
                    title: key === "name" ? "" : key,
                    dataIndex: key,
                    key: key,
                    width: 100,
                    align: key === "name" ? "left" : "right",
                }
            })
            return <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                {tableName && <CardHeader heading={tableName} headingType={2} />}
                <Table locale={{
                    emptyText: <NoDataFound />
                }} scroll={{ x: isTranspose ? 300 : 800 }} dataSource={smDataSource} columns={columns} bordered={false} pagination={false} />
            </div>
        } else {
            var dataSource = Object.values(tableData)
            var dataSourceKeys = Object.keys(tableData)

            if (showNameColumn) {
                dataSource = dataSource.map((item, index) => {
                    return {
                        ...item,
                        "col1_name_custom": dataSourceKeys[index]
                    }
                })
            }

            var columns = Object.entries(dataSource[0]).map(([key, val], index) => {
                return {
                    title: key,
                    dataIndex: key,
                    key: key,
                    width: 100,
                    align: () => {
                        if (new Date(val) !== "Invalid Date" || val.includes("%") || val.includes(".") || isNaN(Number(val))) {
                            return "right"
                        } else {
                            return "left"
                        }
                    },
                    fixed: key === "col1_name_custom" ? "left" : false,
                    render: (text) => {
                        if (new Date(text) !== "Invalid Date" && !text.includes("%") && !text.includes(".")) {
                            return formatDateMMMDDYYYY(text)
                        } else {
                            return text
                        }
                    }
                }
            })


            if (showNameColumn) {
                columns.splice(columns.indexOf((i) => i.key === "col1_name_custom"), 1)
                columns = [{
                    title: "",
                    width: 150,
                    dataIndex: "col1_name_custom",
                    key: "col1_name_custom",
                    fixed: "left",
                }]?.concat(columns)
            }
            return <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                {tableName && <CardHeader heading={tableName} headingType={2} />}
                <Table locale={{
                    emptyText: <NoDataFound />
                }} scroll={{ x: 800 }} bordered={false} dataSource={dataSource} columns={columns} pagination={false} />
            </div>
        }
    }

    const ReturnsHistogram = () => {
        var heatmapdata = backTestData?.perfromance_charts?.histogram_monthly_rets.map((res) => {
            return Number((res * 100).toFixed(2))
        })

        const xiData = [];
        const animationDuration = 1;//4000;
        const range = Math.max(...heatmapdata) - Math.min(...heatmapdata) * 2,
            startPoint = Math.min(...heatmapdata);
        let i = 0,
            j = 0;
        for (i = 0; i < range; i++) {
            xiData[i] = startPoint + i;
        }
        const data = [];

        function GaussKDE(xi, x) {
            return (1 / Math.sqrt(2 * Math.PI)) * Math.exp(Math.pow(xi - x, 2) / -2);
        }

        const N = heatmapdata.length;

        for (i = 0; i < xiData.length; i++) {
            let temp = 0;
            for (j = 0; j < heatmapdata.length; j++) {
                temp = temp + GaussKDE(xiData[i], heatmapdata[j]);
            }
            data.push([xiData[i], (1 / N) * temp * 100]);
        }

        const chart = {
            chart: {
                type: "areaspline",
                animation: true,
                height: 360,
                marginTop: 10
            },
            title: {
                text: '',
                style: {
                    color: 'var(--black)'
                }
            },
            scrollbar: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },

            title: {
                text: ""
            },
            xAxis: {
                title: { text: "" }
            },
            yAxis: {
                visible: false,
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                title: { text: 'Density' }
            },
            tooltip: {
                enabled: false
            },
            legend: {
                enabled: false
            },

            plotOptions: {
                areaspline: {
                    threshold: null
                },
                series: {
                    marker: {
                        enabled: false
                    },
                    dashStyle: "shortdot",
                    color: "#ff8d1e",
                    pointStart: xiData[0],
                    animation: {
                        duration: animationDuration
                    }
                }
            },
            series: [
                {
                    name: "Return density",
                    dashStyle: "solid",
                    lineWidth: 2,
                    color: 'var(--primary)',
                    data: data
                }
            ]
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={chart} />
    }

    const HeatMap = () => {


        var heatmapdata = backTestData?.perfromance_charts?.heatmap
        var years = backTestData?.perfromance_charts?.heatmap.map((data) => { return data.year })
        var months = Object.keys(backTestData?.perfromance_charts?.heatmap[0]).slice(1)

        var vals = []
        var dataFinal = months.map((month, index) => {
            return years.map((year, yindex) => {
                vals.push(Number((heatmapdata[yindex][month] * 100)?.toFixed(1)))
                return {
                    name: month.charAt(0).toUpperCase() + month.toLowerCase().slice(1) + " " + year,
                    x: index,
                    y: yindex,
                    value: Number((heatmapdata[yindex][month] * 100).toFixed(1))
                }
            })
        })


        var chart = {

            chart: {
                type: 'heatmap',
                plotBorderWidth: 0,
                borderWidth: 0,
                height: 360,

            },


            title: {
                text: '',
                style: {
                    color: "var(--black)"
                }
            },
            scrollbar: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },

            xAxis: {
                categories: months
            },

            yAxis: {
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                categories: years,
                title: null,
                reversed: true
            },

            colorAxis: {
                min: Math.min(...vals),
                max: Math.max(...vals.filter((i) => i !== 100)),
                minColor: '#c9f6bf',
                maxColor: '#fcaead',
            },
            legend: {
                enabled: false
            },

            tooltip: {
                headerFormat: '',
                pointFormat: '{point.name} returns: {point.value}%',
            },
            series: [{
                name: 'Monthly returns',
                borderWidth: 0.2,
                borderColor: 'black',
                data: dataFinal.flat(1),
                dataLabels: {
                    enabled: true,
                    color: '#000000',
                    style: {
                        fontWeight: 'normal',
                        fontSize: 8,
                        textOutline: false
                    },
                    pointFormat: '{point.value}%',
                }
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 300
                    },
                    chartOptions: {
                        yAxis: {
                            labels: {
                                formatter: function () {
                                    return this.value.charAt(0);
                                }
                            }
                        }
                    }
                }]
            }

        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={chart} />
    }

    const RollingVol = () => {

        var heatmapdata = backTestData?.perfromance_charts?.rolling_vol

        try {
            var conf = {
                chart: {
                    backgroundColor: "transparent",
                    height: 360,
                },
                title: {
                    text: ''
                },
                exporting: {
                    enabled: false
                },
                yAxis: {
                    tickAmount: 5,
                    gridLineDashStyle: 'longdash',
                    title: {
                        text: ''
                    },
                    gridLineColor: "var(--grey1)",
                    plotLines: [{
                        color: '#000', // Red
                        value: heatmapdata[0]["Average_6M_vol"], // Position, you'll have to translate this to the values on your x axis
                        dashStyle: 'dash',
                        width: 1,
                        label: {
                            text: "Average 6M vol", rotation: 0, x: 10,
                            style: {
                                color: "var(--dark2)",
                                // fontWeight: 'bold'
                            }
                        },
                        color: 'var(--grey1)',
                    }]
                },
                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%H:%M:%S.%L',
                        second: '%H:%M:%S',
                        minute: '%H:%M',
                        hour: '%H:%M',
                        day: '%e %b',
                        week: '%e %b',
                        month: '%b %y',
                        year: '%Y'
                    },
                    lineColor: "var(--grey1)",
                    tickColor: "var(--grey1)",
                }
                ],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        lineWidth: 1.5,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    valuedecimals: 1,
                    valuePrefix: '',
                    valueSuffix: '%'
                },
                credits: {
                    enabled: false
                },
                boost: {
                    useGPUTranslations: true,
                },
                series: [
                    {
                        name: "Portfolio", turboThreshold: 9e9,
                        color: '#4284f3',
                        data: changeObjKey(heatmapdata, ["Portfolio", "Date"], ["y", "x"], "rollingTearchart", 1)
                    }, {
                        name: "Benchmark", turboThreshold: 9e9,
                        color: "var(--primary)",
                        data: changeObjKey(heatmapdata, ["Benchmark", "Date"], ["y", "x"], "rollingTearchart", 1)
                    }
                ]?.reverse(),
                scrollbar: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 400
                        },
                    }]
                },

            };
        } catch (error) {
            //////console.log("SDF", error)
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={conf} />
    }

    const SharpeChart = () => {
        var heatmapdata = backTestData?.perfromance_charts?.rolling_sharpe

        try {
            var conf = {
                chart: {
                    backgroundColor: "transparent",
                    height: 360,
                },
                title: {
                    text: ''
                },
                exporting: {
                    enabled: false
                },
                yAxis: {
                    tickAmount: 5,
                    gridLineDashStyle: 'longdash',
                    title: {
                        text: ''
                    },
                    gridLineColor: "var(--grey1)",
                    plotLines: [{
                        color: '#000', // Red
                        value: heatmapdata[0]["Average_Sharpe"], // Position, you'll have to translate this to the values on your x axis
                        dashStyle: 'dash',
                        width: 1,
                        label: {
                            text: "Average Sharpe", rotation: 0, x: 10,
                            style: {
                                color: "var(--dark2)",
                                // fontWeight: 'bold'
                            }
                        },
                        color: 'var(--grey1)',
                    }]
                },
                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%H:%M:%S.%L',
                        second: '%H:%M:%S',
                        minute: '%H:%M',
                        hour: '%H:%M',
                        day: '%e %b',
                        week: '%e %b',
                        month: '%b %y',
                        year: '%Y'
                    },
                    lineColor: "var(--grey1)",
                    tickColor: "var(--grey1)",
                }
                ],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        lineWidth: 1.5,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    valueDecimals: 0,
                    valuePrefix: '₹',
                },
                credits: {
                    enabled: false
                },
                boost: {
                    useGPUTranslations: true,
                },
                series: [
                    {
                        name: "Portfolio", turboThreshold: 9e9,
                        color: "#24c1e0",
                        tooltip: {
                            valueSuffix: ' ({point.Portfolio}%)',
                        },
                        data: changeObjKey(heatmapdata, ["Portfolio", "Date"], ["y", "x"], "rollingTearchart", 1)
                    }
                ],
                scrollbar: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 400
                        },
                    }]
                },

            };
        } catch (error) {
            //////console.log("SDF", error)
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={conf} />
    }

    const UnderWater = () => {
        var heatmapdata = backTestData?.perfromance_charts?.underwater

        try {
            var conf = {
                chart: {
                    backgroundColor: "transparent",
                    height: 360,
                },
                title: {
                    text: ''
                },
                exporting: {
                    enabled: false
                },
                yAxis: {
                    tickAmount: 5,
                    gridLineDashStyle: 'longdash',
                    labels: {
                        format: '{value}%'
                    },
                    title: {
                        text: ''
                    },
                    gridLineColor: "var(--grey1)",
                },
                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%H:%M:%S.%L',
                        second: '%H:%M:%S',
                        minute: '%H:%M',
                        hour: '%H:%M',
                        day: '%e %b',
                        week: '%e %b',
                        month: '%b %y',
                        year: '%Y'
                    },
                    lineColor: "var(--grey1)",
                    tickColor: "var(--grey1)",
                }
                ],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        lineWidth: 1.5,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    valuedecimals: 1,
                    valuePrefix: '',
                    valueSuffix: '%'
                },
                credits: {
                    enabled: false
                },
                boost: {
                    useGPUTranslations: true,
                },
                series: [
                    {
                        name: "Portfolio", turboThreshold: 9e9,
                        color: "var(--red)",
                        data: changeObjKey(heatmapdata, ["Portfolio", "Date"], ["y", "x"], "rollingTearchart", 1).map((i) => {
                            return {
                                ...i,
                                y: i.y * 100
                            }
                        })
                    }
                ],
                scrollbar: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 400
                        },
                    }]
                },

            };
        } catch (error) {
            //////console.log("SDF", error)
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={conf} />
    }

    const RebalanceData = ({ rebalData }) => {
        const dataSource = sortFunds(rebalData, "weights")

        const columns = [
            {
                title: 'Symbol',
                dataIndex: 'symbol',
                key: 'symbol',
                width: 150,
                fixed: 'left'
            },
            {
                title: 'Market cap',
                dataIndex: 'mcap',
                key: 'mcap',
                align: "right",
                render: (text) => DecimalValueFormat({ num: text, suffix: " Cr", placeholder: "-" })
            },
            {
                title: 'Weights',
                dataIndex: 'weights',
                key: 'weights',
                width: 410,
                align: "right",
                render: (text) => TableProgressBarFormat({ num: text * 100 })
            },
            // {
            //     title: 'Next day avg. price',
            //     dataIndex: 'next_day_price_avg',
            //     key: 'next_day_price_avg',
            //     align: "right",
            //     render: (text) => CurrencyFormat(Number(text))
            // },
            {
                title: 'No. of shares',
                dataIndex: 'num_shares',
                key: 'num_shares',
                align: "right",
            }
        ]
        return <Table locale={{
            emptyText: <></>
        }} dataSource={dataSource} columns={columns} size={'small'} bordered className='textSM black my-2' pagination={false} />
    }

    const TradeData = ({ rebalData }) => {
        const dataSource = sortFunds(rebalData, "order_quantity")
        //console.log(dataSource)
        const columns = [
            {
                title: 'Symbol',
                dataIndex: 'symbol',
                key: 'symbol',
                width: 350,
                fixed: 'left',
                render: (text, obj) => {
                    return <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                        <span>{type?.value === 2 ? allFundsData?.filter((i) => i?.isin_code === text)?.[0]?.basic_name?.replace(" - Direct Plan", "") : text}</span>
                        <span>
                            <Tag className="textXS" color={"var(--grey2)"} style={{ fontSize: 10, color: "var(--dark4)" }}>{obj?.tag}</Tag>
                            <Tag className="textXS" color={obj?.direction === "BUY" ? "var(--greenShade)" : "var(--redShade)"} style={{ fontSize: 10, color: obj?.direction === "BUY" ? "var(--green)" : "var(--red)" }}>{String(obj?.direction)?.charAt(0)}</Tag>
                            {obj?.remark && <Tag className="textXS" color={"var(--grey2)"} style={{ fontSize: 10, color: "var(--dark4)" }}>{obj?.remark}</Tag>}
                        </span>
                    </div>
                }
            },
            {
                title: 'Price',
                dataIndex: 'fill_price',
                key: 'fill_price',
                align: "right",
                render: (text) => CurrencyFormat(Number(text))
            },
            {
                title: 'Quantity',
                dataIndex: 'fill_quantity',
                key: 'fill_quantity',
                // width: 410,
                align: "right",
                render: (text) => DecimalValueFormat({ num: text, suffix: "", placeholder: "-" })
            },
            {
                title: 'Profit / Loss',
                dataIndex: 'pnl',
                key: 'pnl',
                align: "right",
                render: (text) => text ? <RedGreenText text={CurrencyFormat(Number(text))} value={Number(text)} /> : "-"
            },
            {
                title: 'Returns',
                dataIndex: 'ret',
                key: 'ret',
                align: "right",
                render: (text) => <RedGreenText text={DecimalValueFormat({ num: text, suffix: "%", placeholder: "-" })} value={Number(text)} />
            }
        ]
        return <Table locale={{
            emptyText: <></>
        }} dataSource={dataSource} columns={columns} size={'small'} scroll={{ x: 'max-content' }} bordered className='textSM black my-2' pagination={false} />
    }

    const CustomAccordionItem = ({ x }) => {

        const [isOpen, setIsOpen] = React.useState(x.index === 0 ? true : false)
        const [selectedTab, setselectedTab] = React.useState("stock")
        return <div id={`id${x.index}`} key={`id${x.index}`} >
            <div className="textSM w-500 black" >{x.header}</div>



            <div className="mt-3" style={{ border: "1px solid var(--grey1)", display: "flex", alignItems: "center", backgroundColor: "var(--dark4)", borderRadius: "4px" }}>
                <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div onClick={() => {
                        setselectedTab("stock")
                    }} style={{ flex: 1, alignItems: "center", padding: "1rem", display: "flex", flexDirection: "column", borderBottom: selectedTab === "stock" ? "4px solid var(--primary)" : "unset", cursor: "pointer" }}>
                        <span className="textSM white">Stocks</span>
                        <span className="textSM white w-500">{CurrencyFormat(x?.dataAll?.stock_value)}</span>
                    </div>
                    <div style={{ flex: 1, alignItems: "center", }}>
                        <div style={{ flex: 1, alignItems: "center", display: "flex", flexDirection: "column" }}>
                            <span className="textSM white">+</span>
                        </div>
                    </div>
                    <div style={{ flex: 1, alignItems: "center", padding: "1rem", display: "flex", flexDirection: "column", borderBottom: selectedTab === "cash" ? "4px solid var(--primary)" : "unset", cursor: "pointer" }}>
                        <span className="textSM white w-500">Cash</span>
                        <span className="textSM white w-500">{CurrencyFormat(x?.dataAll?.cash)}</span>
                    </div>
                    <div style={{ flex: 1, alignItems: "center", }}>
                        <div style={{ flex: 1, alignItems: "center", display: "flex", flexDirection: "column" }}>
                            <span className="textSM white">=</span>
                        </div>
                    </div>
                    <div style={{ flex: 2, alignItems: "center", padding: "1rem", display: "flex", flexDirection: "column" }}>
                        <span className="textSM white">Total</span>
                        <span className="textSM white w-500">{CurrencyFormat(x?.dataAll?.total)}</span>
                    </div>
                </div>
            </div>

            {selectedTab === "stock" && <RebalanceData rebalData={x?.dataAll?.stocks} />}

        </div>

    }

    const CashPositions = () => {
        var heatmapdata = backTestData?.cash_positions

        try {
            var conf = {
                chart: {
                    backgroundColor: "transparent"
                },
                title: {
                    text: ''
                },
                exporting: {
                    enabled: false
                },
                yAxis: {
                    tickAmount: 5,
                    gridLineDashStyle: 'longdash',
                    labels: {
                        format: '{value}'
                    },
                    title: {
                        text: ''
                    },
                    gridLineColor: "var(--grey1)",
                },
                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%H:%M:%S.%L',
                        second: '%H:%M:%S',
                        minute: '%H:%M',
                        hour: '%H:%M',
                        day: '%e %b',
                        week: '%e %b',
                        month: '%b %y',
                        year: '%Y'
                    },
                    lineColor: "var(--grey1)",
                    tickColor: "var(--grey1)",
                }
                ],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        lineWidth: 1.5,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    valuedecimals: 1,
                    valuePrefix: '',
                    valueSuffix: ''
                },
                credits: {
                    enabled: false
                },
                boost: {
                    useGPUTranslations: true,
                },
                series: [
                    {
                        name: "Cash position", turboThreshold: 9e9,
                        color: "var(--red)",
                        data: changeObjKey(heatmapdata, ["cash", "date"], ["y", "x"], "rollingTearchart", 1).map((i) => {
                            return {
                                ...i,
                                y: i.y
                            }
                        })
                    }
                ],
                scrollbar: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 400
                        },
                    }]
                },

            };
        } catch (error) {
            //////console.log("SDF", error)
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={conf} />
    }


    const CustomAccordionItem2 = ({ x }) => {

        return <Collapse defaultActiveKey={[0]} expandIcon={({ isActive }) => <IoIosArrowDown style={{
            transition: "all 0.3s linear", transform: isActive ? "rotate(180deg)" : "rotate(0deg)", color: "var(--black)", fontSize: "0.875rem"
        }} />} className='tradeLogCollapse' expandIconPosition='end' items={[
            {
                key: x.index,
                label: <div className="textSM w-500 black" >{x.header}</div>,
                children: <TradeData rebalData={x?.dataAll} />,
            }
        ]} style={{ background: "var(--white)" }} bordered={false} />
        return <div id={`id${x.index}`} key={`id${x.index}`} >
            <div className="textSM w-500 black" >{x.header}</div>
            <TradeData rebalData={x?.dataAll} />
        </div>

    }

    const TradeLogs = () => {
        console.time();
        const result = tradeLog === "id1" ? Object.groupBy(backTestData?.trade_log, ({ fill_timestamp }) => fill_timestamp) : Object.groupBy(backTestData?.trade_log?.filter((i) => i?.tag === tradeLog), ({ fill_timestamp }) => fill_timestamp);
        // console.log(result)
        // console.timeEnd()
        return result !== undefined ?
            <Timeline items={Object.entries(result)?.reverse()?.map(([date, rebalData], index1) => {
                return {
                    children: <div>
                        <CustomAccordionItem2 x={{
                            "Date": date,
                            "header": "Trades on " + formatDateMMMDDYYYY(date),
                            "key": date + "" + index1,
                            "index": index1,
                            "dataAll": rebalData
                        }}
                        />
                    </div>
                }
            })} />
            : <NoDataFound />

    }

    const tableItems = [
        {
            value: 'perf', label: 'Performance summary',
            children: <TableConstructor tableData={backTestData?.perfromance_tables?.perf_summary} showNameColumn={true} isTranspose={true} />
        },
        {
            value: 'drawdown', label: 'Worst 5 Drawdowns',
            children: <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                <WorstDrawdowns />
            </div>
        },
        {
            value: 'trailing', label: 'Trailing Returns',
            children: <TableConstructor tableData={backTestData?.perfromance_tables?.trailing_returns} showNameColumn={true} isTranspose={true} />
        },
        {
            value: 'calender', label: 'Calender year returns',
            children: <TableConstructor tableData={backTestData?.perfromance_tables?.cal_year_returns} showNameColumn={true} isTranspose={true} />
        },
    ]

    const tabItems = Object.keys(backTestData || {}).length !== 0 ? [
        {
            label: `Performance`,
            key: "id1",
            value: "id1",
            children: (
                <div style={{ flex: 1, padding: "1.25rem 0rem", display: "flex", alignItems: "flex-start", gap: "2rem", justifyContent: "flex-start" }}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1rem", flex: 1.5 }}>
                        <CardHeader heading={'Portfolio vs Benchmark performance'} headingType={2} />
                        {isSuperScreener ? <ScreenSignalBacktestChart portfolioType={isSuperScreener ? 12 : 11} defAmtSelect={isSuperScreener ? initial_capital : ""} defYrSelect={isSuperScreener ? "365D" : "1095D"} chartData={isSuperScreener ? chartData : backTestData?.perfromance_against_bm?.prices} xAxisKey={xAxisKeyForPerfChart} fundName="Portfolio" /> :
                            <PerfBenchChart portfolioType={isSuperScreener ? 12 : 11} defAmtSelect={isSuperScreener ? initial_capital : ""} defYrSelect={isSuperScreener ? "max" : "1095D"} chartData={isSuperScreener ? chartData : backTestData?.perfromance_against_bm?.prices} xAxisKey={xAxisKeyForPerfChart} fundName="Portfolio" />}

                        {isSuperScreener && <div>
                            <CardHeader heading={'Trade Summary:'} headingType={3} />
                            <div style={{ marginTop: "1rem" }}>
                                <CustomLabelValueCards x={[
                                    { key: 0, heading: "Average loss percentage", value: DecimalValueFormat({ num: backTestData?.trade_summary?.average_loss_per, suffix: "%" }) },
                                    { key: 1, heading: "Average loss rate", value: DecimalValueFormat({ num: backTestData?.trade_summary?.average_loss_rate, suffix: "%" }) },
                                    { key: 2, heading: "Average win percentage", value: DecimalValueFormat({ num: backTestData?.trade_summary?.average_win_per, suffix: "%" }) },
                                    { key: 3, heading: "Average win rate", value: DecimalValueFormat({ num: backTestData?.trade_summary?.average_win_rate, suffix: "%" }) },
                                ]} />
                            </div>
                        </div>}
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <CardHeader heading={'Showing:'} headingType={3} />
                            <Select
                                style={{ width: '13rem' }}
                                value={tableDrop}
                                onChange={(e) => {
                                    settableDrop(e)
                                }}
                                options={tableItems}
                            />
                        </div>
                        {tableItems?.filter((i) => i?.value === tableDrop)?.[0]?.children}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1rem", flex: 1 }}>
                        <TableConstructor tableName='Performance metrics' tableData={backTestData?.perfromance_tables?.perf_metrics} showNameColumn={true} isTranspose={true} />
                    </div>
                </div>
            ),
        }, {
            label: `Performance charts`,
            key: "id11",
            value: "id11",
            children: <CustomSideTabs tabData={[
                {
                    key: '1',
                    label: 'Distribution of monthly returns',
                    children: <ReturnsHistogram />,
                    show: true,
                },
                {
                    key: '2',
                    label: 'Monthly returns heatmap',
                    children: <HeatMap />,
                    show: true,
                },
                {
                    key: '3',
                    label: '6-month rolling volatility',
                    children: <RollingVol />,
                    show: true,
                },
                {
                    key: '4',
                    label: '6-month rolling Sharpe ratio',
                    children: <SharpeChart />,
                    show: true,
                },
                {
                    key: '5',
                    label: 'Underwater plot',
                    children: <UnderWater />,
                    show: true,
                }
            ]} />

        }, isSuperScreener ? {
            label: `Trade logs`,
            key: "id2",
            value: "id2",
            children: <div className='my-4'>
                <TradeLogs />
            </div>
        } : {
            label: `Rebalance history`,
            key: "id2",
            value: "id2",
            children: (
                <div className='my-4'>
                    {backTestData?.[isSuperScreener ? "rebal_history" : "rebal_hsitory"] !== undefined ?
                        <Timeline items={Object.entries(backTestData?.[isSuperScreener ? "rebal_history" : "rebal_hsitory"])?.filter(([date, rebalData], index) => formatDateMMMDDYYYY(date) === selectedWeightMethod)?.map(([Date, rebalData], index1) => {
                            return {
                                children: <div>
                                    <CustomAccordionItem x={{
                                        "Date": Date,
                                        "header": "Portfolio as on " + formatDateMMMDDYYYY(Date),
                                        "rebalData": rebalData?.stocks,
                                        "key": Date + "" + index1,
                                        "cash": CurrencyFormat(rebalData?.cash),
                                        "index": index1,
                                        "dataAll": rebalData
                                    }}
                                    />
                                </div>
                            }
                        })} />
                        : <NoDataFound />}
                </div>
            ),
        }, {
            label: `Methodology`,
            key: "id4",
            value: "id4",
            children: (
                <div className='my-4'>
                    <Disclaimer val={backtestType === 'screener' ? 4 : 5} />
                </div>
            ),
        }] : [];


    React.useEffect(() => {
        if (!backTestModalLoading) {
            var weightMethodOptions = !backTestModalLoading && Object.keys(backTestData?.rebal_hsitory || {})?.map((date) => {
                return formatDateMMMDDYYYY(date)
            })

            weightMethodOptions = !backTestModalLoading && [...new Set(weightMethodOptions)];
            weightMethodOptions = !backTestModalLoading && weightMethodOptions?.map((yr) => {
                return {
                    "key": yr,
                    "value": yr,
                    "label": yr
                }
            }).reverse()

            setweightMethodOptions(weightMethodOptions)
            setselectedWeightMethod(weightMethodOptions?.[0]?.value)
        }
    }, [backTestModalLoading])

    return <div>

        <div style={{ display: "flex", alignItems: "center" }}>
            {!backTestModalLoading && <div style={{ flex: 1 }}>
                <CustomSegmentedTab isBlock={false} options={tabItems} value={topTabType}
                    onChange={(e) => setTopTabType(e)} />
            </div>}
            {topTabType === "id2" && (isSuperScreener ? <CustomSegmentedTab isBlock={false} options={[
                {
                    label: `All`,
                    key: "id1",
                    value: "id1",
                    children: <></>
                },
                ...[...new Set(backTestData?.trade_log?.map(item => item.tag))]?.map((i) => {
                    return {
                        label: i,
                        key: i,
                        value: i,
                        children: <></>
                    }
                })]}
                value={tradeLog}
                onChange={(e) => setTradelog(e)} /> : <Select removeIcon={<TiDelete size={12} />}
                    className='textSM black'
                    suffixIcon={<IoIosArrowDown size={15} />}
                    style={{
                        width: "150px"
                    }}
                    defaultValue={selectedWeightMethod}
                    placeholder="Please select portfolio year"
                    onChange={(value) => {
                        setselectedWeightMethod(value)
                    }}
                    options={weightMethodOptions}
            />)}
        </div>

        {backTestModalLoading ? <div className={isSuperScreener ? "" : 'loaderContainer'} style={isSuperScreener ? {} : {
            margin: 'auto',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            position: 'fixed',
        }}>{chartData?.length > 0 ? <PerfBenchChart isWebSocket={true} portfolioType={11} defAmtSelect={isSuperScreener ? initial_capital : ""} defYrSelect={isSuperScreener ? "max" : "1095D"} chartData={isSuperScreener ? {
            "max": {
                data: JSON.stringify([...chartData]?.map((i, index) => {
                    var dataFinal = [...chartData]
                    var changePer = index === 0 ? 0 : ((dataFinal[index]?.Portfolio - dataFinal[0]?.Portfolio) / dataFinal[0]?.Portfolio) * 100
                    var changePer2 = index === 0 ? 0 : ((dataFinal[index]?.Benchmark - dataFinal[0]?.Benchmark) / dataFinal[0]?.Benchmark) * 100
                    return {
                        ...i,
                        Portfolio: i?.Portfolio * Number((initial_capital / dataFinal?.[0]?.Portfolio)?.toFixed(2)),
                        Benchmark: i?.Benchmark * Number((initial_capital / dataFinal?.[0]?.Benchmark)?.toFixed(2)),
                        ret_Portfolio: changePer?.toFixed(2),
                        ret_Benchmark: changePer2?.toFixed(2),
                    }
                }))
            }
        } : backTestData?.perfromance_against_bm?.prices} xAxisKey={xAxisKeyForPerfChart} fundName="Portfolio" /> :
            <ActivityLoaderMintbox message={backtestType === 'screener' ? "Hang on….heavy lifting in progress! We simulate this screen over more than 10 years of historical data to get you the back tested performance." : "Hang on….heavy lifting in progress! We simulate this factor model over more than 10 years of historical data to get you the back tested performance."} />
            }
        </div> : Object.keys(backTestData || {}).length !== 0 ? tabItems.filter((i) => i?.value === topTabType)?.[0]?.children : <NoBackTestData val={backtestType === 'screener' ? 4 : 5} />}
    </div>

};



export default ScreenerOrSignalBacktest



