import { Card, Divider, Modal, Select } from "antd"
import TextArea from "antd/es/input/TextArea"
import { toggleHelpAndSupp, toggleloginModal } from "Libs/redux/UserAuth/reduxSlice"
import React, { useState } from "react"
import { toast } from "react-hot-toast"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"
import AdminServices from "Services/api/AdminServices"
import { CardHeader } from "./atoms/SmallComponents"
import { LoadingOutlined } from '@ant-design/icons';
import { helpAndSupportIssues } from "Libs/utils"

export const HelpAndSupportForm = () => {

    const [formData, setFormData] = useState({ issueType: "", message: "" });
    const user = useSelector((state) => state?.updateUserData?.value);
    const dispatch = useDispatch();

    const onChangeFormData = (value, key) => {
        setFormData({ ...formData, [key]: value })
    }

    const isMobile = window.innerWidth <= 575;

    const handleIssueSubmission = async (e) => {
        e.preventDefault();
        if (user?.isLogged) {
            if (!formData.issueType) {
                toast.error('Please select a issue.')
            }
            else {
                let temp = { ...formData, email: user?.email || '', name: user?.name || '', mobileNumber: user?.mobile || '' }
                let res = await AdminServices.submitIssue(temp);
                if (res.type === true) {
                    toast.success('Your issue has been submitted successfully.');
                    setFormData({ email: "", mobileNumber: "", name: "", issueType: "", message: "" })
                }
                else {
                    toast.error('Something went wrong! Please try again.')
                }
            }
        }
        else {
            toast.error('Please login first');
            dispatch(toggleloginModal(true));
        }
    }

    return (
        <React.Fragment>
            <Card className='mobCardBorderRadius0' bodyStyle={{ padding: isMobile ? "1.75rem" : "" }}>
                <CardHeader heading={'Help us make sharpely better'} headingType={2} />
                <Divider style={{ margin: "0.75rem 0", background: "var(--grey3)", height: "0.125rem" }} />
                <form onSubmit={handleIssueSubmission} >
                    <label className="textXS w-500 dark3" style={{ marginBottom: "0.25rem" }} >Issue</label>
                    <Select placeholder="Select Issue" className='textSM' style={{
                        marginBottom: "1rem", display: "block", background: "var(--grey4)",
                    }} allowClear options={helpAndSupportIssues} value={formData.issueType || undefined}
                        onChange={(value) => onChangeFormData(value, 'issueType')} required />
                    <label className="textXS w-500 dark3" style={{ marginBottom: "0.25rem" }} >Your Message</label>
                    <TextArea placeholder="Write your message..." className='customAntdInput'
                        style={{ marginBottom: "1rem", resize: "none", height: "8rem" }} type='text' required value={formData.message}
                        onChange={(e) => onChangeFormData(e.target.value, 'message')} />
                    <button className="btnBlack " style={{ width: "100%" }} type='submit'>
                        Submit
                    </button>
                </form>
            </Card>
        </React.Fragment>
    )
}

export const HelpAndSupportFormModal = () => {

    const [formData, setFormData] = useState({ issueType: "", message: "" });
    const open = useSelector((state) => state.helpAndSupp?.value);
    const user = useSelector((state) => state?.updateUserData?.value);
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    const onChangeFormData = (value, key) => {
        setFormData({ ...formData, [key]: value })
    }

    const handleIssueSubmission = async (e) => {
        e.preventDefault();
        if (user?.isLogged && !loading) {
            if (!formData.issueType) {
                toast.error('Please select a issue.')
            }
            else {
                setLoading(true);
                let temp = { ...formData, email: user?.email || '', name: user?.name || '', mobileNumber: user?.mobile || '' }
                let res = await AdminServices.submitIssue(temp);
                if (res.type === true) {
                    toast.success('Your issue has been submitted successfully.');
                    setFormData({ email: "", mobileNumber: "", name: "", issueType: "", message: "" })
                }
                else {
                    toast.error('Something went wrong! Please try again.')
                }
                setLoading(false);
            }
        }
        else {
            toast.error('Please login first');
            dispatch(toggleloginModal(true));
            handleClose();
        }
    }

    const handleClose = () => {
        dispatch(toggleHelpAndSupp(false));
        setFormData({ email: "", mobileNumber: "", name: "", issueType: "", message: "" })
    }

    return (
        <React.Fragment>
            <Modal open={open} closable={false} footer={<button onClick={handleClose} className='btnBlack'>Close</button>} onCancel={handleClose}
                onOk={handleClose} className='helpAndSupportForm mobileModalClass' style={{ width: "calc(100vw - 2.5rem)" }}>
                <Card>
                    <CardHeader heading={'Help us make sharpely better'} headingType={2} />
                    <Divider style={{ margin: "0.75rem 0", background: "var(--grey3)", height: "0.125rem" }} />
                    <form onSubmit={handleIssueSubmission} >
                        <label className="textXS w-500 dark3" style={{ marginBottom: "0.25rem" }} >Issue</label>
                        <Select placeholder="Select Issue" className='textSM' style={{
                            marginBottom: "1rem", display: "block", background: "var(--grey4)"
                        }} allowClear options={helpAndSupportIssues} value={formData.issueType || undefined}
                            onChange={(value) => onChangeFormData(value, 'issueType')} required />
                        <label className="textXS w-500 dark3" style={{ marginBottom: "0.25rem" }} >Your Message</label>
                        <TextArea placeholder="Write your message..." className='customAntdInput'
                            style={{ marginBottom: "1rem", resize: "none", height: "8rem" }} type='text' required value={formData.message}
                            onChange={(e) => onChangeFormData(e.target.value, 'message')} />
                        <button className="btnBlack" style={{ width: "100%" }} type='submit'>
                            {loading ? <LoadingOutlined style={{ fontSize: '1rem', color: "var(--white)" }} spin /> :
                                "Submit"}
                        </button>
                    </form>
                </Card>
            </Modal>
        </React.Fragment>
    )
}