import { andapp, appPic, iosapp } from "Assets/images";
import { useIsMobile } from "CustomHooks/useIsMobile";

export const AppSection = () => {

    const isMobile = useIsMobile();

    return (
        <div className="padAllCampaign" style={{}}>
            <div style={{ width: "100%", gap: isMobile ? "3rem" : "7rem", height: "100%" }}
                className='d-flex align-items-center justify-content-between flex-sm-row flex-column'>
                <div style={{ flex: 1.25, order: isMobile ? 3 : 0 }}>
                    <div style={{ width: "100%" }}>
                        <img loading="lazy" src={appPic} alt='sharpely app' style={{ width: "100%", maxWidth: "100%", height: "100%" }} />
                    </div>
                    <div className='d-flex align-items-center justify-content-center' style={{ gap: "1rem", marginTop: "2rem", width: '100%' }}>
                        <a href='https://apps.apple.com/us/app/sharpely/id6455889217' target={'_blank'} style={{ flex: 1 }}>
                            <img loading="lazy" src={iosapp} alt='App store' style={{ width: "100%", height: "100%" }} />
                        </a>
                        <a href='https://play.google.com/store/apps/details?id=com.investments.sharpely' target='_blank' style={{ flex: 1 }}>
                            <img loading="lazy" src={andapp} alt='play store' style={{ width: "100%", height: "100%" }} />
                        </a>
                    </div>
                </div>
                <div style={{ flex: 1.5 }}>
                    <div className='headLGtoXS w-700' style={{ color: 'rgba(255, 255, 255, 0.8)' }}>India's #1</div>
                    <div className='headLGtoXS black' style={{ color: 'rgba(255, 255, 255, 0.8)' }}>Quant-Based Analytical Tool (Research Platform)</div>
                    <div className='headLGtoXS w-700' style={{ color: "gold" }}>#GetSharpGetSharpely</div>
                    <div style={{ marginTop: "2.5rem" }}>
                        <div className='textLGtoESM w-700 dark2' style={{ maxWidth: "35ch" }}>
                            Institutional-level tools to research and invest in
                            Stocks, ETFs, & Mutual Funds.
                        </div>
                        <div className='textMDtoESM dark2 w-500' style={{ maxWidth: "60ch", marginTop: "1.75rem" }}>
                            sharpely is a SEBI-registered investment research platform. With sharpely,
                            you can access advanced analytical tools, curated baskets, and in-depth
                            insights on stocks, mutual funds, and ETFs. sharpely helps investors make
                            informed investment decisions. Whether you're a beginner or an
                            experienced investor, sharpely has tools for everyone. SEBI registration
                            no. INH000009524
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}