import { createSlice } from '@reduxjs/toolkit'

export const basketCreateModal = createSlice({
    name: "basketCreateModal",
    initialState: {
        open: false,
        type: {}
    },
    reducers: {
        toggleBasketCreateModal: (state, action) => {
            state.open = action.payload.open;
            state.type = action.payload.type;
        }
    }
})

export const globalInsSearch = createSlice({
    name: "globalInsSearch",
    initialState: {
        openSearchModal: false,
        filterAppFrNav: 0,
        letter: ''
    },
    reducers: {
        setOpenSearchModal: (state, action) => {
            state.openSearchModal = action.payload;
        },
        setFilterAppFrNav: (state, action) => {
            state.filterAppFrNav = action.payload;
        },
        setLetter: (state, action) => {
            state.letter = action.payload;
        }
    }
})

export const { toggleBasketCreateModal } = basketCreateModal.actions;
export const { setOpenSearchModal, setFilterAppFrNav, setLetter } = globalInsSearch.actions;