import AMCIcon from "Components/AMCIcon";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import { StickyScrollTabs } from "Components/CustomComponents/StickyScrollTabs";
import CustomTabs from "Components/CustomTabs";
import DashboardChartComponent from "Components/DashboardChartComponent";
import { formatDate } from "Components/DateFormatter";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import FundDetCards from "Components/FundDetCards";
import InstrumentListTable from "Components/InstrumentListTable";
import TechnicalAnalysis from "Components/StockDetailComponents/TechnicalAnalysis";
import Head from "Layout/Head";
import { arePropsEqual, getIndexList, screenerMetricData, useGetAssetType } from "Libs/utils";
import AdminServicesPY from "Services/api/AdminServicesPY";
import apiHelperPY from "Services/api/ApiHelperPY";
import { PriceDisplay, StockLivePrice } from "Services/api/LivePricesWebSocket";
import { Card } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import IndexFundamentals from "./IndexFundamentals";
import IndexTechnicals from "./IndexTechnicals";
import { useIsMobile } from "CustomHooks/useIsMobile";

const TopBanner = React.memo(({ indexDetails = {}, index = {}, isCustomIndex, indexPrice }) => {

    const isMobile = useIsMobile();

    return (
        <div className='leftRightPadContainer' style={{ paddingTop: "1.25rem", paddingBottom: "0.5rem" }}>
            <div style={{ display: "flex", gap: "1.25rem", justifyContent: "space-between" }}>
                <div className="d-flex" style={{ gap: "1rem" }}>
                    <AMCIcon fundType={4} showTricolor={Boolean(!isCustomIndex)} height={isMobile ? "4rem" : "3rem"} width={isMobile ? "4rem" : "3rem"}
                        showSharpelyLogo={Boolean(isCustomIndex)} />
                    <div>
                        <div style={{ display: "flex", alignItems: "center", gap: "1rem", }}>
                            <h1 className={isMobile ? "margin0 w-700 textLG" : "margin0 textLG w-700"} style={{}}>
                                {index?.IndexName}
                            </h1>
                        </div>
                        <div style={{ width: isMobile ? '100%' : '90%' }} className="textXS w-400 dark3 mt-1">
                            {!isMobile ? index?.IndexDescription : ''}
                            {!isCustomIndex && <a href={index?.FactsheetLink} target='_blank' style={{ whiteSpace: 'nowrap', fontWeight: '500' }}> {`${!isMobile ? 'Download complete factsheet' : 'View Factsheet'}`}</a>}
                        </div>
                    </div>
                </div>
                <div style={{ whiteSpace: 'nowrap' }}>
                    {isCustomIndex ? <PriceDisplay format={isMobile ? 11 : 8} priceObj={indexPrice} /> :
                        <StockLivePrice fundData={index} format={isMobile ? 11 : 8} symbol={index?.symbol} />}
                </div>
            </div>
        </div>
    )
}, arePropsEqual)

function IndexDetail() {

    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
    const params = useParams();
    const type = useGetAssetType(); // 1 = etf, 2 = mf
    const id = params.code;
    const [allStocks, setAllStocks] = React.useState([]);
    const [index, setIndex] = React.useState({});
    const [isCustomIndex, setIsCustomIndex] = React.useState(false);
    const [indexOHLC, setIndexOHLC] = React.useState([]);
    const [indexDetails, setIndexDetails] = React.useState({});
    const [indexConstituentsDetails, setindexConstituentsDetails] = React.useState([]);
    const [loadingIndexDetails, setLoadingIndexDetails] = React.useState(true);
    const [loadingIndexDataNew, setLoadingIndexDataNew] = React.useState(true);
    const [screenerMetricDescData, setScreenerMetricDescData] = React.useState([]);
    const [allIndicatorData, setAllIndicatorData] = React.useState([])
    const [allRangeAnalysisData, setAllRangeAnalysisData] = React.useState({})
    const [loadingTechnicalsIndicators, setLoadingTechnicalsIndicators] = React.useState(true);
    const [loadingTechnicalsRange, setLoadingTechnicalsRange] = React.useState(true);
    const [loadingConstituents, setLoadingConstituents] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const stockType = useGetAssetType(4);
    const isMobile = useIsMobile();
    const userCustomCols = useSelector((state) => state?.userCustomCols?.userCustomCols);

    const indexPrice = React.useMemo(() => {
        let result;
        if (indexOHLC?.length > 0) {
            let currPriceObj = indexOHLC?.[1];
            let prevPriceObj = indexOHLC?.[0];
            let currentLTP, changePer, changeVal, dateTime = '-';
            currentLTP = currPriceObj?.price_adj_split;
            changeVal = currentLTP - prevPriceObj?.price_adj_split;
            changePer = (changeVal / prevPriceObj?.price_adj_split) * 100;
            dateTime = new Date(currPriceObj?.Date)?.toLocaleString('en-IN', {
                day: "2-digit", month: "short", hour: "numeric",
                minute: "2-digit", timeZone: 'Asia/Kolkata',
            })
            result = {
                currentLTP, changePer, changeVal, dateTime
            }
        }
        return result;
    }, [indexOHLC]);

    const textFormat = {
        '1D': '1 Day',
        '1W': '1 Week',
        '1M': '1 Month',
        '3M': '3 Months',
        '6M': '6 Months',
        '1Y': '1 Year',
        '2Y': '2 Years',
        '3Y': '3 Years',
        '5Y': '5 Years',
        '10Y': '10 Years',
        '20Y': '20 Years'
    }

    const loadInitData = async () => {
        setLoading(true)
        let screenerMetric = await screenerMetricData(stockType);
        setAllStocks(screenerMetric?.allFunds)
        setScreenerMetricDescData(screenerMetric?.screenerMetricDescData);
        setLoading(false)
    }

    const getIndexDetails = async () => {
        let indices = await getIndexList();
        if (indices) {
            let ind = indices?.find((el) => Number(el?.Id) === Number(id)) || {};
            let indexBM = indices?.find((el) => Number(el?.Id) === (Number(id) === 0 ? 4 : 0)) || {};
            // console.log("ASFDSAF", indices, ind)
            setIndex(ind)
            if (ind?.IndexName?.startsWith('SHI:')) {
                setIsCustomIndex(true);
            }
            else {
                setIsCustomIndex(false);
            }
            setTimeout(() => { // ye chaiyea isko mat hata
                setLoadingIndexDetails(false)
            }, 1);
        } else {
            setIndex({})
            setLoadingIndexDetails(false)
        }
    }

    const getIndividualIndexDataNew = async () => {
        setLoadingIndexDataNew(true)
        let res2 = await apiHelperPY({ apiName: "getIndividualIndexDataNew", data: id, saveResponse: "" });
        if (res2) {
            res2 = typeof (res2) === 'string' ? JSON.parse(res2?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null)) || {} : res2;
            // console.log('new', res2);
            setIndexDetails(res2)
            setLoadingIndexDataNew(false)
        } else {
            setIndexDetails({})
            setLoadingIndexDataNew(false)
        }
    }

    const getIndicators = async () => {
        setLoadingTechnicalsIndicators(true)
        const obj = {
            'symbol': id,
            // 'timeframe': [timeFrame],
            'timeframe': ['1m', '5m', '15m', '30m', '1H', '1D', '1W', '1M'],
            'request': [{
                'inputs': { 'period': 5 },
                'name': 'EMA',
                'type': 'ma'
            },
            {
                'inputs': { 'period': 21 },
                'name': 'EMA',
                'type': 'ma'
            },
            {
                'inputs': { 'period': 50 },
                'name': 'EMA',
                'type': 'ma'
            },
            {
                'inputs': { 'period': 200 },
                'name': 'EMA',
                'type': 'ma'
            },
            {
                'inputs': { 'period': 5 },
                'name': 'SMA',
                'type': 'ma'
            },
            {
                'inputs': { 'period': 21 },
                'name': 'SMA',
                'type': 'ma'
            },
            {
                'inputs': { 'period': 50 },
                'name': 'SMA',
                'type': 'ma'
            },
            {
                'inputs': { 'period': 200 },
                'name': 'SMA',
                'type': 'ma'
            },
            {
                'inputs': { 'period': 14 },
                'name': 'RSI',
                'type': 'oscillator'
            },
            { 'inputs': { 'period': 14 }, 'name': 'MFI', 'type': 'indicator' },
            {
                'inputs': { 'fastperiod': 12, 'signalperiod': 9, 'slowperiod': 26 },
                'name': 'MACD',
                'type': 'oscillator'
            },
            // { 'inputs': { 'period': 14 }, 'name': 'ATR', 'type': 'oscillator' },
            { 'inputs': { 'period': 14 }, 'name': 'ADX', 'type': 'oscillator' },
            // { 'inputs': { 'period': 10 }, 'name': 'ROC', 'type': 'oscillator' },
            { 'inputs': { 'period': 14 }, 'name': 'CCI', 'type': 'oscillator' },
            {
                'inputs': { 'fastk_period': 5, 'slowd_period': 3, 'slowk_period': 3 },
                'name': 'StochasticOscillator',
                'type': 'oscillator'
            },
            {
                'inputs': { 'period': 14 },
                'name': 'StochasticRSI',
                'type': 'oscillator'
            },
            {
                'inputs': { 'period': 14 }, 'name': 'WilliamsR',
                'type': 'oscillator'
            },
            {
                'inputs': { 'fastperiod': 5, 'slowperiod': 34 },
                'name': 'AwesomOsillator',
                'type': 'oscillator'
            },
            {
                'inputs': { 'period': 14 },
                'name': 'MomentumOscillator',
                'type': 'oscillator'
            },
            { 'inputs': {}, 'name': 'UltimateOscillator', 'type': 'oscillator' },
            { 'inputs': {}, 'name': 'IchimokuBaseLine', 'type': 'ma' },
            { 'inputs': { 'period': 14 }, 'name': 'VWMA', 'type': 'ma' },
            {
                'inputs': { 'period': 14 },
                'name': 'HullMovingAverage',
                'type': 'ma'
            },
            {
                'inputs': {},
                'name': 'AvgDirectionalMovementIndexRating',
                'type': 'oscillator'
            },
            { 'inputs': {}, 'name': 'BullBearPower', 'type': 'oscillator' },
            { 'inputs': {}, "name": "Camarilla", "type": 'pivot' },
            { 'inputs': {}, "name": "Classic", "type": 'pivot' },
            { 'inputs': {}, "name": "Demark", "type": 'pivot' },
            { 'inputs': {}, "name": "Fibonacci", "type": 'pivot' },
            { 'inputs': {}, "name": "Woodie", "type": 'pivot' },
            ],
            'indices': true
        }
        let result = await AdminServicesPY.getIndicators({ 'params': JSON.stringify(obj) }).then(r => r);
        // console.log(result)
        if (result?.data?.['api-status'] !== 'error') {
            setAllIndicatorData(result?.data);
            setLoadingTechnicalsIndicators(false)
        } else {
            setAllIndicatorData([]);
            setLoadingTechnicalsIndicators(false)
        }
    };

    const getRangeAnalysis = async () => {
        setLoadingTechnicalsRange(true)
        const obj = {
            'symbol': id,
            'end_date': moment().format('YYYY-MM-DD'),
            frequency: null,
            'indices': true
        }
        let result = await AdminServicesPY.getRangeAnalysis({ 'params': JSON.stringify(obj) }).then(r => r);
        // console.log(result?.data?.[id])
        if (result?.data?.['api-status'] !== 'error') {
            setAllRangeAnalysisData(result?.data?.[id]);
            setLoadingTechnicalsRange(false)
        } else {
            setAllRangeAnalysisData({});
            setLoadingTechnicalsRange(false)
        }
    }

    const loadData = async () => {
        await Promise.allSettled([getIndexDetails(), getIndividualIndexDataNew(), getRangeAnalysis(), getIndicators()]);
    }


    const getFinalConstituents = async () => {
        setLoadingConstituents(true)
        try {
            var result = []
            try {
                result = indexDetails?.constituents?.map((fund) => {
                    let obj2 = allStocks?.find((ele) => ele?.symbol === fund?.symbol)
                    return {
                        ...obj2,
                        ...fund,
                        "gdf_symbol": fund?.symbol
                    }
                })
            } catch (error) {
                result = []
            }
            setindexConstituentsDetails(result)
            setLoadingConstituents(false)
        } catch (error) {
            setindexConstituentsDetails([])
            setLoadingConstituents(false)

        }
    };

    React.useEffect(() => {
        setLoadingIndexDetails(true)
        loadData();
    }, [id])

    React.useEffect(() => {
        loadInitData();
    }, []);



    React.useEffect(() => {
        if (Object.keys(indexDetails)?.length > 0 && allStocks?.length > 0) {
            getFinalConstituents();
        }
    }, [indexDetails, userCustomCols, allStocks]);


    const initialColumns = [
        {
            key: "weights",
            dataIndex: "weights",
            title: "Weights",
            render: (text) => {
                return DecimalValueFormat({ num: text, placeholder: "-", suffix: " %" })
            },
            width: 160,
            align: "right"
        }
    ]

    const x = Object.values(indexDetails?.fundamental_data?.Value || {}).map(tab => tab?.charts).flat(1).map(met => {
        const metricData = screenerMetricDescData?.find(m => m.desc_id === Number(met?.desc))
        return Object.keys(met?.chart_text_items).map(k => {
            return k !== 'Current' ? { label: k.split(' ')?.[0] + " " + met?.chart_name + " " + k.split(' ')?.[1], valueToRender: DecimalValueFormat({ num: met?.chart_text_items?.[k] }), metric_desc: metricData?.description, metric_name: metricData?.name, dontEllipsis: true } : undefined
        }).filter(b => b)
    }).flat(1)

    const y = Object.values(indexDetails?.fundamental_data?.Quality || {}).map(tab => tab?.charts).flat(1).map(met => {
        const metricData = screenerMetricDescData?.find(m => m.desc_id === Number(met?.desc))
        return { label: <span>{met?.chart_name}<div className='' style={{ fontSize: "0.5rem" }}>(as on {formatDate(met?.chart_text_items?.Latest?.as_on)})</div></span>, valueToRender: DecimalValueFormat({ num: met?.chart_text_items?.Latest?.val, suffix: ' %' }), metric_desc: metricData?.description, metric_name: metricData?.name, dontEllipsis: true }
    })

    var cards = [
        { label: "P/E Ratio", value: DecimalValueFormat({ num: indexDetails?.meta?.pe_latest, placeholder: "-" }), showIcon: false },
        { label: "P/B Ratio", value: DecimalValueFormat({ num: indexDetails?.meta?.pb_latest, placeholder: "-" }), showIcon: false },
        { label: "Constituents", value: indexConstituentsDetails?.length, showIcon: false },
        { label: "Dividend yield", value: DecimalValueFormat({ num: indexDetails?.meta?.div_yield, placeholder: "-", suffix: " %" }), showIcon: false },
    ]

    cards = [...cards, ...x, ...y]

    const tabItems = [
        {
            key: "Overview", label: "Overview", value: "Overview",
            children: <StickyScrollTabs isAccordionInMob queriesToStickToBot={['#sharpelyHeader', '#customTabsBarId']}
                tabData={[
                    {
                        label: 'Historical Performance',
                        key: 1,
                        value: 'Performance',
                        children: !loadingIndexDetails && <DashboardChartComponent type={type} selectedFundData={index} hidePriceChart />,
                        show: true,
                        noHeading: true,
                    },
                    {
                        label: "Constituents",
                        key: 4,
                        value: "Constituents",
                        children: <InstrumentListTable istableLoading={loadingConstituents} hideInvestBtn={true} tableType={5} tableHeader={<></>} hideHeaderBlueLine={true} hideCheckBox={true} finalList={indexConstituentsDetails}
                            showBubbleChartBtn={true} hidePaddingInMob={true} isCard={false} defaultInsType={4} otherCol={initialColumns} sortColKey='weights' showToolsBtnsInTab
                            showHeatmapBtn downloadTable fileDefaultName={index?.IndexName + ' Constituents'} />,
                        show: true,
                        heading: "Constituents",
                        subHeading: "A magic table that shows all the constituents of the index and lets you compare all the constituents at one place. Customize columns as per your preference.",
                        rightLinkText: "",
                    }
                ]
                } />
        },
        // {
        //     key: "Constituents", label: "Constituents", value: "Constituents", children: <CustomTabsMainChild heading={'Constituents'} subHeading={`A magic table that shows all the constituents of the index and lets you compare all the constituents at one place. Customize columns as per your preference.`}>
        //         <InstrumentListTable hideInvestBtn={true} tableType={5} tableHeader={<></>} hideHeaderBlueLine={true} hideCheckBox={true} finalList={indexConstituentsDetails}
        //             showBubbleChartBtn={true} hidePaddingInMob={true} isCard={false} defaultInsType={4} otherCol={initialColumns} sortColKey='weights' showToolsBtnsInTab
        //             showHeatmapBtn downloadTable fileDefaultName={index?.IndexName + ' Constituents'} />
        //     </CustomTabsMainChild>
        // },
        { key: "Fundamentals", label: "Fundamentals", value: "Fundamentals", children: <IndexFundamentals index={index} indexDetails={indexDetails} screenerMetricDescData={screenerMetricDescData} isCustomIndex={isCustomIndex} /> },
        { key: "Stock Technicals", label: "Stock Technicals", value: "Stock Technicals", children: <IndexTechnicals index={index} indexDetails={indexDetails} indexConstituentsDetails={indexConstituentsDetails} /> },

        {
            label: "Index Technicals",
            key: "Index Technicals",
            value: "Index Technicals",
            tabsLevel: 1,
            heading: `Exploring the technicals of ${index?.IndexName}`,
            text: `This section takes a deep dive into technicals starting with RSI, Moving Averages, MACD, MFI, Stochastic and so on`,
            children: <TechnicalAnalysis
                SYMBOL={id}
                fundData={index}
                isMobile={isMobile}
                allIndicatorData={allIndicatorData}
                allRangeAnalysisData={allRangeAnalysisData}
                loadingTechnicalsIndicators={loadingTechnicalsIndicators}
                loadingTechnicalsRange={loadingTechnicalsRange}
                onRefereshClick={() => { getIndicators(); getRangeAnalysis() }}
                isIndex={true}
                isCustomIndex={isCustomIndex}
                indexPrice={indexPrice}
            />,
        },
    ]

    const [selectedTab, setSelectedTab] = React.useState("Overview");

    if (loading || loadingIndexDetails) {
        return (
            <div className="loaderContainer">
                <ActivityLoaderMintbox showQuote />
            </div>
        )
    }
    return (
        <React.Fragment>
            <Head title={`${index?.IndexName} Index Detail - Constituents, Fundamentals & Technicals | Insights & Analysis`} description={`sharpely aggregates the consolidated P/E, P/B, and Dividend Yield of the ${index?.IndexName} Index as reported by NSE. It also evaluates the standalone momentum of stocks in the index and reports the number and percentage of stocks meeting the criteria.`} />
            <div>
                <TopBanner indexDetails={indexDetails} index={index} isCustomIndex={isCustomIndex} indexPrice={indexPrice} />
                <CustomTabs tabItems={tabItems} value={selectedTab} onChange={(e) => setSelectedTab(e)} childContainerId='indexDetailCustomTabsChild'
                    header={<div style={{
                        display: "flex", alignItems: "center", gap: "0.75rem",
                        flex: 1, justifyContent: "flex-end",
                    }}>
                        <AMCIcon fundType={4} showTricolor={!isCustomIndex} showSharpelyLogo={isCustomIndex} type="circle" height="1.5rem" width="1.5rem" />
                        {!isCustomIndex && <span className='textMD dark3 w-700'>{index?.symbol}</span>}
                        {isCustomIndex ? <PriceDisplay format={5} priceObj={indexPrice} showTime={false} /> :
                            <StockLivePrice symbol={index?.symbol} fundData={index} format={5} showTime={false} />}
                    </div>} />
                <div style={{}} id={"indexDetailCustomTabsChild"} className="">
                    <div className={isMobile ? "mainContainer" : "mainContainer leftRightPadContainer"} style={{
                        paddingTop: isMobile ? "0" : "1.25rem", paddingBottom: isMobile ? "0" : "1.25rem",
                        gap: isMobile ? "0" : "1.25rem",
                        display: selectedTab === "Overview" ? "flex" : "none"
                    }}>
                        <div className="leftContainerV2">
                            <DashboardChartComponent type={type} selectedFundData={index} hidePerformanceChart
                                indexMetricsComponent={<>
                                    <div style={{ paddingBottom: "1rem" }}>
                                        <Card bordered={false} style={{ height: "100%", minHeight: "30rem", border: "1px solid var(--grey2)" }} bodyStyle={{ height: "100%", padding: 0 }} className=''>
                                            <div className="d-flex flex-column h100" style={{ maxHeight: window.innerWidth > 760 ? "31rem" : "max-content", minHeight: "100%", }}>
                                                <div style={{ flex: 1, padding: "0rem", overflowY: "auto" }}>
                                                    <FundDetCards data={cards} type="grid" height="19.75rem" itemInGroups={2} headingType={3} />
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </>
                                } updateIndexOHLC={(res = []) => {
                                    let t = res?.reverse()?.slice(0, 2)?.reverse()
                                    // console.log(t);
                                    setIndexOHLC(t)
                                }}
                            />
                        </div>
                        {!isMobile && <div className="rightContainerV2" style={{ padding: isMobile ? "1rem" : "" }}>
                            <Card bordered={false} style={{ height: "100%", minHeight: "30rem", border: "1px solid var(--grey2)" }} bodyStyle={{ height: "100%", padding: 0 }} className=''>
                                <div className="d-flex flex-column h100" style={{ maxHeight: window.innerWidth > 760 ? "31rem" : "max-content", minHeight: "100%", }}>
                                    <div style={{ flex: 1, padding: "0rem", overflowY: "auto" }}>
                                        <FundDetCards data={cards} type="grid" height="19.75rem" itemInGroups={2} headingType={3} />
                                    </div>
                                </div>
                            </Card>
                        </div>}
                    </div>
                    {tabItems?.find((el) => el?.value === selectedTab)?.children}
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(IndexDetail);