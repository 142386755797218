import { useIsMobile } from 'CustomHooks/useIsMobile';
import { FaArrowCircleRight } from 'react-icons/fa';
import { VideoCard } from './Components/VideoCard';

export const FactorModelSection = () => {
    
    const isMobile = useIsMobile();

    return <div style={{
        height: '100%', width: '100%', display: 'flex', flexDirection: 'column',
    }} className='padAllCampaign'>
        <div style={{
            display: 'flex', flex: 1,
            justifyContent: 'space-between', height: '100%', width: '100%', gap: '4rem', flexDirection: "row",
        }} className='flex-sm-row flex-column'>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, }}>
                <h2 className='headLGtoXS margin0' style={{ fontWeight: 700, color: "var(--white2)" }}>
                    Invest like a pro with <br /><span style={{ color: "gold" }}>Multi-Factor Models</span>
                </h2>
                <div className='textMDtoESM dark2' style={{ width: "100%", paddingTop: "1.5rem" }}>
                    Factor investing lies at the core of quantitative investing. With sharpely Factor Models, you
                    can create your own factor-based quant models easily and intuitively.
                </div>
                <div style={{ marginTop: "2rem" }}>
                    <button className='btnPrimary textLG hoverEffect' style={{ padding: '0.6rem 1.25rem', display: 'flex', alignItems: 'center', gap: '1rem' }} onClick={(e) => {
                        window.open(`/stocks/factor-model`, '_blank', "noreferrer");
                    }}>
                        Start building a factor model <FaArrowCircleRight />
                    </button>
                </div>
            </div>
            <div style={{ width: isMobile ? "100%" : "min(100%, 30rem)" }}>
                <VideoCard videoItem={{
                    heading: "Building Factors Models on sharpely",
                    videoId: "e_RAAQW1a-k",
                    buttonText: "Create a Factor model|Explore community models",
                    webURL: "/stocks/factor-model|/factor-models#community-models"
                }} isBordered />
            </div>
        </div>
    </div>
}
