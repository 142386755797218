import { Card, Drawer, Image, Tooltip } from "antd";
import { toggleloginModal } from "Libs/redux/UserAuth/reduxSlice";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import * as ReactDOM from "react-dom";
import { BiCustomize } from "react-icons/bi";
import { IoIosArrowForward, IoIosClose } from "react-icons/io";
import { MdCancel, MdDragIndicator } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { CardHeader } from "./atoms/SmallComponents";
import { Checkbox } from "Components/atoms/Checkbox";
import { SubscribeContentWrapper } from "./SubscribeModal";
import { NoDataFound } from "./NoDataFound";
import { widgetImgs } from "Assets/Constant";
import { AiOutlineInfo, AiOutlineInfoCircle } from "react-icons/ai";

const CustomizeWidgets = React.memo(({ allWidgets = [], userCustomWidgets = [], widgets = [], onApplyChanges = () => { } }) => {

    //console.log('omkar', allWidgets)
    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
    const user = useSelector((state) => state?.updateUserData?.value)
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [selectedWidgets, setSelectedWidgets] = React.useState(widgets);
    const [selectedWidget, setSelectedWidget] = React.useState(widgets?.[0] || {});
    const isMobile = window.innerWidth <= 760;
    const dispatch = useDispatch();
    const categories = [...new Set([...allWidgets?.map(w => w?.CategoryName)])]
    const [selectedCategory, setSelectedCategory] = React.useState(categories?.[0])

    const portal = document.createElement("div");
    document.body.appendChild(portal);

    // const categoryName ={
    //     'MYSTUFF':'My stuff',
    //     'INDEX':'Market analysis',
    //     ''
    // }
    const changeWidgets = (item) => {
        let check = selectedWidgets?.find((ele) => ele?.UID === item?.UID);
        let temp = [...selectedWidgets]
        if (check?.UID) {
            temp = temp?.filter((el) => el?.UID !== item?.UID)
        }
        else {
            temp = [...temp, item]
        }
        setSelectedWidgets(temp);
    }

    const onClose = () => {
        setOpenDrawer(false);
        setSelectedWidgets(widgets);
    }

    const cancelChanges = () => {
        onClose();
    }

    const applyChanges = async () => {
        setOpenDrawer(false);
        setTimeout(async () => {
            await onApplyChanges(selectedWidgets);
        }, 400)
    }

    React.useEffect(() => {
        setSelectedWidgets(widgets);
    }, [widgets])

    const RenderAllWidgets = () => {

        return (
            <div>
                <div className="textSM black w-500">Mini Widgets</div>
                <div style={{ display: "flex", flexDirection: "column", gap: isMobile ? "0.75rem" : "0.5rem", marginTop: "0.625rem" }}>
                    {allWidgets?.filter((item) => item?.CategoryName === selectedCategory && item?.Type === 'Mini Widget')?.map((item) => {
                        let isWidgetPresent = Boolean(selectedWidgets?.find((ele) => ele?.UID === item?.UID));
                        return (
                            <div key={item?.UID} style={{
                                cursor: "pointer", color: "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem",
                                justifyContent: "space-between"
                            }} className='textXS' onClick={() => setSelectedWidget(item)}>
                                <div style={{ cursor: "pointer", color: "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem", }}
                                    className={`${isMobile ? "textSM" : 'textXS'}`}>
                                    <Checkbox checked={isWidgetPresent === true} onChange={(e) => {
                                        e.preventDefault()
                                        changeWidgets(item);
                                        setSelectedWidget(item);
                                    }} />
                                    <span onClick={() => setSelectedWidget(item)} style={{ color: item?.UID === selectedWidget?.UID ? "var(--primary)" : "" }}>{item.Name}</span>
                                    <Tooltip title={item?.Description} arrow={false} ><AiOutlineInfoCircle size={'0.85rem'} /></Tooltip>
                                </div>
                                {/* <IoIosArrowForward color={"var(--dark3)"} /> */}
                            </div>
                        )
                    })}
                </div>
                <div className="textSM black w-500" style={{ marginTop: "1rem" }}>Full Widgets</div>
                <div style={{ display: "flex", flexDirection: "column", gap: isMobile ? "0.75rem" : "0.5rem", marginTop: "0.625rem" }}>
                    {allWidgets?.filter((item) => item?.CategoryName === selectedCategory && item?.Type === 'Full Widget')?.map((item) => {
                        let isWidgetPresent = Boolean(selectedWidgets?.find((ele) => ele?.UID === item?.UID));
                        return (
                            <div key={item?.UID} style={{
                                cursor: "pointer", color: "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem",
                                justifyContent: "space-between"
                            }} className='textXS' onClick={() => setSelectedWidget(item)}>
                                <div style={{ cursor: "pointer", color: "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem", }}
                                    className={`${isMobile ? "textSM" : 'textXS'}`}>
                                    <Checkbox checked={isWidgetPresent === true} onChange={(e) => {
                                        e.preventDefault()
                                        changeWidgets(item);
                                        setSelectedWidget(item);
                                    }} />
                                    <span onClick={() => setSelectedWidget(item)} style={{ color: item?.UID === selectedWidget?.UID ? "var(--primary)" : "" }}>{item.Name}</span>
                                    <Tooltip title={item?.Description} arrow={false} ><AiOutlineInfoCircle size={'0.85rem'} /></Tooltip>
                                </div>
                                {/* <IoIosArrowForward color={"var(--dark3)"} /> */}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const RenderCategories = () => {

        return (<div>
            {/* <div className="textSM black w-500" style={{marginBottom: "1.5rem"}}>Widget categories</div> */}
            <div style={{ display: "flex", flexDirection: "column" }}>
                {categories?.map((item, index) => {
                    return <span key={item} style={{
                        padding: isMobile ? "0.75rem 0.5rem 0.75rem 0.75rem" : "0.5rem 1.25rem",
                        borderRadius: isMobile ? "4px 0px 0px 4px" : "4px 0px 0px 4px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: item === selectedCategory ? "var(--blueShade)" : "transparent", cursor: "pointer",
                        borderBottom: isMobile ? "none" : "none"
                    }} onClick={() => {
                        setSelectedCategory(item)
                    }}
                    >
                        <span style={{ flex: 1, color: item === selectedCategory ? "var(--primary)" : "var(--dark3)" }} className="textSM w-500">{item}</span>
                        <IoIosArrowForward color={item === selectedCategory ? "var(--primary)" : "var(--dark3)"} />
                    </span>
                })}
            </div>
        </div>
        )
    }

    const onOrderChange = (sourceId, destinationId) => {
        let temp = [...selectedWidgets];
        let sourceIndex = temp?.findIndex((el) => el?.UID === sourceId);
        let destinationIndex = temp?.findIndex((el) => el?.UID === destinationId);
        let [removed] = temp.splice(sourceIndex, 1);
        temp.splice(destinationIndex, 0, removed);
        setSelectedWidgets(temp);
    };

    function PortalAwareItem({ provided, snapshot, item, index }) {
        const usePortal = snapshot.isDragging;
        const child = (
            <div className="mb-2" ref={provided.innerRef}  {...provided.draggableProps} {...provided.dragHandleProps}>
                <div style={{ display: "flex", gap: '0.2rem', flexDirection: "row", alignItems: "center", padding: '0.25rem 0.5rem 0.25rem 0.1rem', border: '1px solid var(--grey2)', borderRadius: 5, backgroundColor: '#fff' }}>
                    <MdDragIndicator size={22} color="var(--dark2)" />
                    <span className='textXS dark4 w-500' style={{ flex: 1 }}>{item.Name}</span>
                    <MdCancel style={{ cursor: "pointer" }} className='textXS dark2' size={15} onClick={() => { changeWidgets(item) }} />
                </div>
            </div>
        );

        if (!usePortal) {
            return child;
        }

        return ReactDOM.createPortal(child, portal);
    }

    function VerticalList({ items, onChange, id = 'vertical-list-1' }) {
        const onDragEnd = (result) => {
            if (!result.destination) return;
            let sourceId = items[result.source.index]?.UID;
            let destinationId = items[result.destination.index]?.UID
            onChange(sourceId, destinationId);
        };
        return (
            <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem" }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={id}>
                        {(droppableProvided, droppableSnapshot) => (
                            <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} >
                                {items.map((item, index) => {
                                    return <Draggable key={item.UID} draggableId={item.Name} index={index}>
                                        {(draggableProvided, draggableSnapshot) => (
                                            <PortalAwareItem item={item} index={index} provided={draggableProvided} snapshot={draggableSnapshot} />
                                        )}
                                    </Draggable>
                                })}
                                {droppableProvided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div >
        );
    }

    const RenderAddedWidgets = () => {

        return (
            <div style={{
                display: "flex", flex: 1, minHeight: '50%', flexDirection: "column", padding: "1rem 1.25rem 0 1.25rem", overflowY: "auto", borderRight: '1px solid var(--grey2)'
            }}>
                <div className="textSM w-500 black">Arrange selected widgets</div>
                <div className="textXS dark3 mt-1 w-400">Drag and re-arrange your widgets. Once done make sure to apply your changes.</div>
                {selectedWidgets?.length <= 0 ? <div className="mt-3" style={{ flex: 1 }}>
                    <NoDataFound message={<span className="textXS w-500 dark4">No widgets selected</span>} width="5rem" />
                </div> : <div className="scrollbox" style={{ margin: '0.5rem 0', padding: "0.5rem", background: "var(--background)", borderRadius: "var(--borderRadius)" }}>
                    <div className="d-flex textXS w-500 dark4 ps-2" style={{ gap: '1rem' }}>
                        <div style={{ flex: 1 }}>Full Widgets</div>
                        <div style={{ flex: 1 }}>Mini Widgets</div>
                    </div>
                    <div className="d-flex mt-2 scrollbox" style={{ gap: '1rem', background: "var(--background)" }}>
                        <div style={{ flex: 1 }} className='d-flex flex-column'>
                            <div style={{ flex: 1 }}>
                                <VerticalList items={selectedWidgets?.filter((item) => item?.Type === 'Full Widget')} onChange={onOrderChange} />
                            </div>
                        </div>
                        <div style={{ flex: 1 }} className='d-flex flex-column'>
                            <div style={{ flex: 1 }}>
                                <VerticalList items={selectedWidgets?.filter((item) => item?.Type === 'Mini Widget')} onChange={onOrderChange} id='vertical-list-2' />
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        )
    }

    const UIDemo = () => {

        return (
            <div style={{ padding: "1.25rem", display: "flex", flexDirection: "column", gap: "0.5rem", flex: 1, overflow: "auto" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                    <div className="textSM w-500 black">Preview</div>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "2.5fr 1fr", gap: "0.5rem", padding: "0.5rem", background: "var(--background)", borderRadius: "var(--borderRadius)" }}>
                    <div className="d-flex flex-column w100  h100" style={{ gap: "0.5rem" }}>
                        {selectedWidgets?.filter((item) => item?.Type === 'Full Widget')?.map((item, i) => {
                            return <Image src={widgetImgs + item?.Key + '.webp'} key={item?.Name} style={{ width: "100%", borderRadius: "var(--borderRadius)", height: "auto" }}
                                alt={item?.Name} preview />
                        })}
                    </div>
                    <div className="d-flex flex-column w100  h100" style={{ gap: "0.5rem" }}>
                        {selectedWidgets?.filter((item) => item?.Type === 'Mini Widget')?.map((item, i) => {
                            return <Image src={widgetImgs + item?.Key + '.webp'} key={item?.Name} style={{ width: "100%", borderRadius: "var(--borderRadius)", height: "auto" }}
                                alt={item?.Name} preview />
                        })}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div style={{ position: "fixed", top: isMobile ? '94vh' : 75, right: 0, width: "fit-content", zIndex: 100 }} className={isDev ? "noTopLevelPadContainer" : 'leftRightPadContainer'}>
                <SubscribeContentWrapper onClick={() => {
                    setOpenDrawer(true)
                }} moduleKey="FULLY_CUSTOMIZABLE_DASHBOARD" preventDefault={user?.id <= 7}>
                    <div className="d-flex align-items-center shadowSM textSM w-500 white" style={{
                        gap: "0.5rem", background: "var(--black)", borderRadius: "5rem", padding: "0.5rem 1rem",
                        cursor: "pointer"
                    }} >
                        <BiCustomize size='1rem' /> Customize
                    </div>
                </SubscribeContentWrapper>
            </div>
            {openDrawer && <Drawer open={openDrawer} width={isMobile ? "100vw" : "80vw"} height={'100vh'} closable={false} onClose={onClose} styles={{ body: { padding: 0 } }} >
                <Card
                    actions={[<div style={{ display: "flex", gap: "0.5rem", alignItems: "center", justifyContent: "flex-end", marginRight: "1rem" }}>
                        <button className="btnWhite" onClick={() => { cancelChanges() }}>Cancel</button>
                        <SubscribeContentWrapper onClick={() => { applyChanges() }} moduleKey="FULLY_CUSTOMIZABLE_DASHBOARD"
                            disabled={JSON.stringify(widgets) === JSON.stringify(selectedWidgets)} preventDefault={user?.id <= 7}>
                            <button className="btnBlack" disabled={JSON.stringify(widgets) === JSON.stringify(selectedWidgets)}>Apply changes</button>
                        </SubscribeContentWrapper>
                    </div>]}
                    bodyStyle={{ display: "flex", flex: 1, height: "100%", padding: 0, overflowY: "scroll" }}
                    style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%" }}
                    headStyle={{ marginBottom: 0 }}
                    title={<div style={{ display: "flex", alignItems: "center" }}>
                        <CardHeader heading={'Customize Dashboard'} headingType={2} />
                        <IoIosClose onClick={() => { cancelChanges() }} size={"1.75rem"} style={{ cursor: "pointer" }} />
                    </div>}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
                        <div style={{ display: 'flex', borderBottom: '1px solid var(--grey2)' }}>
                            <Card bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, paddingRight: 0, overflowY: "scroll" }}
                                style={{ display: "flex", flexDirection: "column", flex: 1.2, borderRadius: 0, borderWidth: 0.5, borderRight: '1px solid var(--grey2)' }}>
                                <RenderCategories />
                            </Card>
                            <Card bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, overflowY: "scroll" }}
                                style={{ display: "flex", flexDirection: "column", flex: 1.25, borderRadius: 0, borderWidth: 0.5, borderRight: '1px solid var(--grey2)' }}>
                                <RenderAllWidgets />
                            </Card>
                        </div>
                        <RenderAddedWidgets />
                    </div>
                    {!isMobile && <Card bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", overflowY: "scroll", padding: 0 }}
                        style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                        <UIDemo />
                    </Card>
                    }
                </Card>
            </Drawer>}
        </React.Fragment >
    )
})

export { CustomizeWidgets };
