import React from 'react';
import CustomizeMetrics from 'Components/CustomizeMetrics';
import { useSelector } from 'react-redux';
import { displayValue, getSnapshotInitialMetrics } from "Libs/utils";
import apiHelper from "Services/api/ApiHelper";
import FundDetCards from "Components/FundDetCards";
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { CardHeader } from 'Components/atoms/SmallComponents';

const DashboardChartMetrics = React.memo(({ fundData, type, screenerFinalData, screenerMetric, screenerMetricDescData, userCustomColumns, updateUserCustomCols, snapshotMetricsLoading, isCard = false, itemInGroups = false, height }) => {

    const user = useSelector((state) => state?.updateUserData?.value);
    const [loading, setLoading] = React.useState(true);
    const [initialCols, setInitialCols] = React.useState([]);
    const [snapshotCards, setSnapshotCards] = React.useState([]);
    const [fundDetails, setfundDetails] = React.useState({});

    const createFundDetCardsData = (fundData, screenerMetricdata = [], cols) => {
        let metricColCodes = getSnapshotInitialMetrics(fundData, type)
        setInitialCols(metricColCodes);
        if (cols?.Snapshot?.length > 0) {
            let oth = cols?.Snapshot?.split('|')?.filter((el) => el) || []
            metricColCodes = [...metricColCodes, ...oth];
            metricColCodes = [...new Set(metricColCodes)]
        }

        let temp = metricColCodes?.map((el) => {
            let ob = screenerMetricdata?.find((e) => e?.metric_col_code === el);
            // console.log(ob?.needToRender, ob)
            let obj = {
                ...ob,
                metric_col_code: el,
                valueToRender: ob?.needToRender ? (ob?.dataToRenderFrom?.filter((e) => e?.[ob?.renderKey] === fundData?.[el])?.[0]?.[ob?.renderValue]) || "-" : displayValue({ text: fundData[el], metricObj: ob })
            }
            return obj;
        })
        return temp;
    };

    const loadData = () => {
        if (fundData && screenerMetric) {
            let cards = createFundDetCardsData(fundData, screenerMetric, userCustomColumns);
            setSnapshotCards(cards);
            setLoading(false)
        }
    }

    React.useEffect(() => {
        loadData()
    }, [fundData, screenerMetricDescData])

    const maxheight = window.innerWidth < 760 ? "45rem" : document.getElementById('omkar')?.offsetHeight
    return (
        <React.Fragment>
            <>
                {snapshotMetricsLoading ? <div style={{ margin: '6rem' }}><ActivityLoaderMintbox /></div> :
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', maxHeight: maxheight, minHeight: maxheight, height: maxheight, border: isCard ? '1px solid var(--grey3)' : '', borderRadius: isCard ? '5px' : '' }}>
                        <div style={{ height: '100%', overflow: "auto", }} className='scrollbox'>
                            <FundDetCards data={snapshotCards} type="grid" headingType={3} itemInGroups={itemInGroups ? itemInGroups : window.innerWidth <= 575 ? 2 : window.innerWidth <= 760 ? 3 : 4} />
                        </div>
                        <div style={{ padding: "1rem" }} className='d-flex justify-content-end'>
                            <CustomizeMetrics screenerFinalData={screenerFinalData} type={type} screenerMetric={screenerMetric} fundData={fundData} finalDataCols={snapshotCards}
                                disabledCols={initialCols} updateUserCustomCols={updateUserCustomCols} setFinalDataCols={(e) => { setSnapshotCards(e) }} ctaText='Customize'
                                btnClassName='btnBlack' />
                        </div>
                    </div>}
            </>
        </React.Fragment>
    )
})

export { DashboardChartMetrics };