import { useIsMobile } from 'CustomHooks/useIsMobile';
import { VideoSlider } from './Components/VideoSlider';

export const VideoSection = ({ type }) => {

    const isMobile = useIsMobile();

    const videoSections = {
        1: {
            "sectionId": 38,
            "sectionType": "gs_2_btn_action",
            "sectionHeading": <>Screen and backtest with <br /><span style={{ color: 'gold' }}>Super</span> Stock Screener</>,
            "sectionContent": "With Super Screener, build almost any screen – fundamental, technical, or a mix of both. You can work with multiple timeframes, view history, and backtest your screen.",
            "sectionRight": "",
            "sectionRightURL": "",
            "sectionBackgroundColor": "",
            "sectionBackgroundImage": "linear-gradient(180deg, #fff 7.5%, var(--dark1) 95.2%)",
            "sectionBottomCTA": "",
            "sectionBottomCTALink": "",
            "sectionChildren": [
                {
                    "heading": "Basic screen builder",
                    "headingColor": "#333333",
                    "content": "For those looking for building screens using an easy-to-use step-by-step method",
                    "contentColor": "#6C6C6C",
                    "buttonType": "contained|",
                    "buttonText": "Create a stock screen|View community screens",
                    "buttonColor": "#28293D|#FFF",
                    "backgroundColor": "",
                    "labelColor": "#FFF|#28293D",
                    "iconURL": "",
                    "appURL": "sharpely://SuperScreener/4|sharpely://sharpelyCommunity/Screeners",
                    "webURL": "/stocks/super-screener|/screens#community-screens",
                    "videoId": "8FI1Ej8jFX4",
                    "videoTitle": ""
                },
                {
                    "heading": "Advance screen builder",
                    "headingColor": "#333333",
                    "content": "For those looking for building more complex screens including custom metrics and timeframes",
                    "contentColor": "#6C6C6C",
                    "buttonType": "",
                    "buttonText": "Create a stock screen|View community screens",
                    "buttonColor": "#FFF",
                    "backgroundColor": "",
                    "labelColor": "#28293D",
                    "iconURL": "",
                    "appURL": "",
                    "webURL": "/stocks/super-screener?isAdvance=true|/screens#community-screens",
                    "videoId": "gu0q146snMQ",
                    "videoTitle": ""
                },
                {
                    "heading": "Backtest your screen",
                    "headingColor": "#333333",
                    "content": "Quickly get an idea of how good your screen is by running a backtest.",
                    "contentColor": "#6C6C6C",
                    "buttonType": "contained|",
                    "buttonText": "Create a stock screen|View community screens",
                    "buttonColor": "#28293D|#FFF",
                    "backgroundColor": "",
                    "labelColor": "#FFF|#28293D",
                    "iconURL": "",
                    "appURL": "sharpely://SuperScreener/4|sharpely://sharpelyCommunity/Screeners",
                    "webURL": "/stocks/super-screener|/screens#community-screens",
                    "videoId": "_Ze9btiCOzM",
                    "videoTitle": ""
                },

            ]
        },
        3: {
            "sectionId": 37,
            "sectionType": "gs_2_btn_action",
            "sectionHeading": <span>An institutional-grade<br /><span style={{ color: 'gold' }}>Stock report </span>for individual stock analysis</span>,
            "sectionContent": "If you want to analyze individual stocks, Stock Report is your go-to feature. Be it fundamental or technical analysis, we cover almost everything.",
            "sectionRight": "",
            "sectionRightURL": "",
            "sectionBackgroundColor": "",
            "sectionBackgroundImage": 'linear-gradient(to bottom, #87A7D9 0%, #E6E6E6 100%)',
            "sectionBottomCTA": "Explore your favourite stock",
            "sectionBottomCTALink": "sharpely://searchScreen",
            "sectionChildren": [
                {
                    "heading": "Proprietary stock analysis",
                    "headingColor": "#333333",
                    "content": "A lot of data crunching, presented in an easy-to-understand manner.",
                    "contentColor": "#6C6C6C",
                    "buttonType": "",
                    "buttonText": "",
                    "buttonColor": "#FFF",
                    "backgroundColor": "#fff",
                    "labelColor": "#28293D",
                    "iconURL": "",
                    "appURL": "sharpely://stylebox|sharpely://stylebox",
                    "webURL": "/stocks/styleBox|/stocks/styleBox",
                    "videoId": "lKX7RnXwiQg",
                    "videoTitle": "Comprehensive Overview"
                },
                {
                    "heading": "Fundamental analysis",
                    "headingColor": "#333333",
                    "content": "Ratios, financials (including segments), corporate action and dividends.",
                    "contentColor": "#6C6C6C",
                    "buttonType": "",
                    "buttonText": "",
                    "buttonColor": "#FFF",
                    "backgroundColor": "#fff",
                    "labelColor": "#28293D",
                    "iconURL": "",
                    "appURL": "sharpely://stylebox|sharpely://stylebox",
                    "webURL": "/stocks/styleBox|/stocks/styleBox",
                    "videoId": "82_RTSZWLXY",
                    "videoTitle": "Fundamental Analysis"
                },
                {
                    "heading": "Technical analysis ",
                    "headingColor": "#333333",
                    "content": "Indicators, oscillators, chart patterns. Available for timeframes ranging from 1 min to 1 month",
                    "contentColor": "#6C6C6C",
                    "buttonType": "",
                    "buttonText": "",
                    "buttonColor": "#FFF",
                    "backgroundColor": "#fff",
                    "labelColor": "#28293D",
                    "iconURL": "",
                    "appURL": "sharpely://stylebox|sharpely://stylebox",
                    "webURL": "/stocks/styleBox|/stocks/styleBox",
                    "videoId": "91Sg3xmsCaU",
                    "videoTitle": "Technical Analysis"
                },
                {
                    "heading": "Analyst Forecast ",
                    "headingColor": "#333333",
                    "content": "Forward looking estimates including target price and analyst recommendations.",
                    "contentColor": "#6C6C6C",
                    "buttonType": "",
                    "buttonText": "",
                    "buttonColor": "#FFF",
                    "backgroundColor": "#fff",
                    "labelColor": "#28293D",
                    "iconURL": "",
                    "appURL": "sharpely://stylebox|sharpely://stylebox",
                    "webURL": "/stocks/styleBox|/stocks/styleBox",
                    "videoId": "aFiRQJbJ5RU",
                    "videoTitle": "Analysts Forecast"
                },
                {
                    "heading": "Competition analysis ",
                    "headingColor": "#333333",
                    "content": "Compare peers in industry, industry group and sector with a fully customizable table.",
                    "contentColor": "#6C6C6C",
                    "buttonType": "",
                    "buttonText": "",
                    "buttonColor": "#FFF",
                    "backgroundColor": "#fff",
                    "labelColor": "#28293D",
                    "iconURL": "",
                    "appURL": "sharpely://stylebox|sharpely://stylebox",
                    "webURL": "/stocks/styleBox|/stocks/styleBox",
                    "videoId": "gl5M4VkL8AU",
                    "videoTitle": "Competitive Analysis"
                }
            ]
        },
        5: {
            "sectionId": 40,
            "sectionType": "gs_2_btn_action",
            "sectionHeading": <span className='headMDtoSM'>Stop relying on <span style={{ color: "gold" }}>stock tips!</span><br />Use <span style={{ color: "gold" }}>Strategy Builder</span> <br />to build, backtest and paper trade strategies</span>,
            // "sectionContent": "Explore the power of strategy first approach to stock investing with sharpely Strategy Builder.",
            "sectionRight": "",
            "sectionRightURL": "",
            "sectionBackgroundColor": "",
            "sectionBackgroundImage": "linear-gradient(180deg, #09203f 0%, #000 100%)" || "linear-gradient(170deg, #111 0%, #aaa 50%), linear-gradient(180deg,#aaa 50%,#fff 100%)",
            "sectionBottomCTA": "",
            "sectionBottomCTALink": "",
            "sectionChildren": [
                {
                    "heading": "Create a stock basket",
                    "headingColor": "#333333",
                    "content": "Add your favourite stocks, define weights and rebalancing schedule and you are all set.",
                    "contentColor": "#6C6C6C",
                    "buttonType": "",
                    "buttonText": "Create your first stock basket",
                    "buttonColor": "#FFF",
                    "backgroundColor": "",
                    "labelColor": "#28293D",
                    "iconURL": "",
                    "appURL": "sharpely://CreateCustomStrategy/4",
                    "webURL": "/stocks/build-custom-strategy",
                    "videoId": "vns1wm7cvf8",
                    "videoTitle": ""
                },
                {
                    "heading": "Strategy using stock screener ",
                    "headingColor": "#333333",
                    "content": "Create a dynamic strategy which uses screener as a stock selection model.",
                    "contentColor": "#6C6C6C",
                    "buttonType": "",
                    "buttonText": "Build a dynamic strategy",
                    "buttonColor": "#FFF",
                    "backgroundColor": "",
                    "labelColor": "#28293D",
                    "iconURL": "",
                    "appURL": "sharpely://SuperScreener/4",
                    "webURL": "/stocks/super-screener?fromStrategyCreation=true",
                    "videoId": "UP5eEWWl2RI",
                    "videoTitle": ""
                },
                {
                    "heading": "Strategy using factor model",
                    "headingColor": "#333333",
                    "content": "Create a dynamic strategy which uses factor model for stock selection",
                    "contentColor": "#6C6C6C",
                    "buttonType": "",
                    "buttonText": "Create a factor strategy",
                    "buttonColor": "#FFF",
                    "backgroundColor": "",
                    "labelColor": "#28293D",
                    "iconURL": "",
                    "appURL": "sharpely://signal/4",
                    "webURL": "/stocks/factor-model?fromStrategyCreation=true",
                    "videoId": "cJ6y3fqLkEI",
                    "videoTitle": ""
                },
                {
                    "heading": "Backtest and paper trade ",
                    "headingColor": "#333333",
                    "content": "Our paper trading engine lets you test your strategy in real market condition.",
                    "contentColor": "#6C6C6C",
                    "buttonType": "",
                    "buttonText": "Watch video",
                    "buttonColor": "#FFF",
                    "backgroundColor": "",
                    "labelColor": "#28293D",
                    "iconURL": "",
                    "appURL": "cardClick",
                    "webURL": "cardClick",
                    "videoId": "bJ67WyZWsY8",
                    "videoTitle": ""
                }
            ]
        },
        7: {
            "sectionId": 42,
            "sectionType": "gs_2_btn_action",
            "sectionHeading": <span className=''>It doesn’t end here!<br /> There’s so much more that you can do on <span className='' style={{ color: "gold" }}>sharpely</span></span>,
            // "sectionContent": "In our pursuit to deliver a comprehensive research experience, we have many more tools and features.",
            "sectionRight": "",
            "sectionRightURL": "",
            "sectionBackgroundColor": "",
            "sectionBackgroundImage": "linear-gradient(180deg, var(--background) 0%, #09203f 85%)",
            "sectionBottomCTA": "",
            "sectionBottomCTALink": "",
            "sectionChildren": [
                {
                    "heading": "Stock comparison",
                    "headingColor": "#333333",
                    "content": "Add multiple stocks and compare then across any metric of your choice.",
                    "contentColor": "#6C6C6C",
                    "buttonType": "contained|",
                    "buttonText": "Compare stocks|Watch video",
                    "buttonColor": "#28293D|#FFF",
                    "backgroundColor": "",
                    "labelColor": "#FFF|#28293D",
                    "iconURL": "",
                    "appURL": "sharpely://compare/4|cardClick",
                    "webURL": "/stocks/compare|cardClick",
                    "videoId": "O-WC8TMsIGI",
                    "videoTitle": ""
                },
                {
                    "heading": "Sector analysis ",
                    "headingColor": "#333333",
                    "content": "A comprehensive suite of tools to analyze fundamentals and technical of any sector or industry",
                    "contentColor": "#6C6C6C",
                    "buttonType": "contained|",
                    "buttonText": "Explore sectors|Watch video",
                    "buttonColor": "#28293D|#FFF",
                    "backgroundColor": "",
                    "labelColor": "#FFF|#28293D",
                    "iconURL": "",
                    "appURL": "sharpely://sector/4|cardClick",
                    "webURL": "/stocks/sectors|cardClick",
                    "videoId": "gDM7h-SFhIg",
                    "videoTitle": ""
                },
                {
                    "heading": "Index analysis ",
                    "headingColor": "#333333",
                    "content": "Explore and analyze indices including constituents, valuations and technicals. ",
                    "contentColor": "#6C6C6C",
                    "buttonType": "contained|",
                    "buttonText": "Explore indices|Watch video",
                    "buttonColor": "#28293D|#FFF",
                    "backgroundColor": "",
                    "labelColor": "#FFF|#28293D",
                    "iconURL": "",
                    "appURL": "sharpely://indexAnalysis|cardClick",
                    "webURL": "/indices|cardClick",
                    "videoId": "Rn3ZQEqM_f4",
                    "videoTitle": ""
                },
                {
                    "heading": "MF/ETF Magic Board ",
                    "headingColor": "#333333",
                    "content": "Quickly filter MFs/ETFs based on their category and benchmark and compare across multiple metrics.",
                    "contentColor": "#6C6C6C",
                    "buttonType": "contained|",
                    "buttonText": "MF Magicboard|ETF Magicboard",
                    "buttonColor": "#28293D|#FFF",
                    "backgroundColor": "",
                    "labelColor": "#FFF|#28293D",
                    "iconURL": "",
                    "appURL": "sharpely://magicBoard/2|sharpely://magicBoard/1",
                    "webURL": "/mutual-funds/magicboard|/etfs/magicboard",
                    "videoId": "IKFTURqFesw",
                    "videoTitle": ""
                },
                {
                    "heading": "MF/ETF screener ",
                    "headingColor": "#333333",
                    "content": "On sharpely, you can create screens for ETFs and Mutual Funds as well!",
                    "contentColor": "#6C6C6C",
                    "buttonType": "contained|",
                    "buttonText": "MF Screener|ETF Screener",
                    "buttonColor": "#28293D|#FFF",
                    "backgroundColor": "",
                    "labelColor": "#FFF|#28293D",
                    "iconURL": "",
                    "appURL": "sharpely://screener/2|sharpely://screener/1",
                    "webURL": "/mutual-funds/screener|/etfs/screener",
                    "videoId": "xYZ0dxueZmY",
                    "videoTitle": ""
                }
            ]
        }
    }

    const section = videoSections?.[type]

    return <div style={{
        flex: 1, height: '100%', width: '100%', display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center',
    }}>
        <div style={{
            display: 'flex', flexDirection: 'column', flex: 1,
            justifyContent: 'space-between', height: '100%', width: '100%', gap: "3rem", paddingLeft: 0, paddingRight: 0
        }} className='padAllCampaign'>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className='padLeftCampaign padRightCampaign'>
                <h2 className='headLGtoXS margin0' style={{ fontWeight: 700, width: isMobile ? "100%" : "20ch", color: 'var(--white2)' }}>
                    {section?.sectionHeading}
                </h2>
                <div className='textMDtoESM dark2' style={{ width: isMobile ? "100%" : "65%", paddingTop: "1rem", }}>
                    {section?.sectionContent}
                </div>
            </div>
            <VideoSlider videos={section?.sectionChildren} type={type} />
        </div>
    </div>
}
