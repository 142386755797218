import { Card, Cascader, Select, Tooltip } from "antd"
import React, { useState } from "react"
import { FiChevronDown } from "react-icons/fi"
import { Link } from "react-router-dom"
import AMCIcon from "./AMCIcon"
import { CardHeader } from "./atoms/SmallComponents"
import { DecimalValueFormat } from "./DecimalValueFormat"
import ListLoader from "./ListLoader"
import RedGreenText from "./RedGreenText"

const DashboardTopPerformersNew = ({ allFunds, widgetItem = {}, type, filter = [], fundsToShow = 5 }) => {
    const [loading, setLoading] = useState(true)
    const options = [
        {
            key: 'cagr_1y',
            value: 'cagr_1y',
            // label: <div className="textXS" onClick={() => setYear(options[0])}>1 Year</div>,
            label: '1 Year'
        },
        {
            key: 'cagr_3y',
            value: 'cagr_3y',
            // label: <div className="textXS" onClick={() => setYear(options[1])}>3 Years</div>,
            label: '3 Years'
        },
        {
            key: 'cagr_5y',
            value: 'cagr_5y',
            // label: <div className="textXS" onClick={() => setYear(options[2])}>5 Years</div>,
            label: '5 Years'
        },
        {
            key: 'cagr_10y',
            value: 'cagr_10y',
            // label: <div className="textXS" onClick={() => setYear(options[3])}>10 Years</div>,
            label: '10 Years'
        },
    ]
    const [year, setYear] = React.useState(options[1])
    const [funds, setFunds] = React.useState([]);
    const [magic_category_id, setmagic_category_id] = React.useState(['Equity', '100'])
    const [mfCategories, setmfCategories] = useState([])
    const loadData = async () => {
        var catLevel1 = [...new Set(allFunds.map((item, index) => item.primary_category_name))]
        var catLevel2 = [...new Set(allFunds.map((i, index) => {
            return {
                "categoryName": i.primary_category_name,
                "subCategoryName": i.category_name,
                "categoryId": i.category_id
            }
        }))]
        catLevel2 = Array.from(new Set(catLevel2.map(JSON.stringify))).map(JSON.parse)

        let categories = catLevel1.map((i) => {
            return {
                categoryName: i,
                subCategories: catLevel2.filter((ii) => ii.categoryName === i)
            }
        })
        setmfCategories(categories)
    }

    const filterSortFunds = () => {
        if (year?.key !== undefined && allFunds?.length) {
            ////console.log(year, magic_category_id, allFunds)
            setLoading(true)
            let a = [...allFunds]?.filter(f => f?.['category_id'] === Number(magic_category_id[1]))?.sort((a, b) => { return a[year.key] ? b[year.key] - a[year.key] : 1 })
            setFunds(a);
            setLoading(false)
        }
    }

    React.useEffect(() => {
        setLoading(true)
        loadData()
    }, [])

    React.useEffect(() => {
        filterSortFunds()
    }, [year, magic_category_id])


    return (
        <Card bordered={false} style={{ padding: 0 }} bodyStyle={{ padding: 0, paddingBottom: "0.5rem" }} className="mobCardBorderRadius0">
            <div className='pad20Container' style={{ display: "flex", alignItems: "center", justifyContent: 'space-between', paddingBottom: '0' }}>
                <CardHeader heading={widgetItem?.Name} headingType={2} />
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                <Cascader
                    showSearch
                    allowClear={false}
                    suffixIcon={<FiChevronDown size={12} />}
                    options={JSON.parse(JSON.stringify(mfCategories)).map((i, index) => {
                        i["value"] = i.categoryName
                        i["label"] = i.categoryName
                        i["title"] = i.categoryName
                        if (i["subCategories"] !== undefined) {
                            i["subCategories"]?.map((ii, indexi) => {
                                ii["value"] = String(ii.categoryId)
                                ii["label"] = ii.subCategoryName
                                return ii
                            })
                            i["children"] = i["subCategories"]
                        }
                        return i
                    })}
                    style={{
                        cursor: 'pointer',
                        width: 'fit-content',
                        maxWidth: '70%'
                    }}
                    expandTrigger='hover'
                    className="dashboardSelectDropdown borderLessSelect textXS w-400 dark3 pad20Container"
                    placeholder={"Please select " + type.omkar + " category"}
                    defaultValue={['Equity', '100']}
                    onChange={(value) => {
                        setmagic_category_id(value)
                    }}
                />
                {/* <Dropdown menu={{ items: options, selectable: true, defaultSelectedKeys: [year.key], }} placement="bottomRight">
                    <span className="textXS" style={{ cursor: 'pointer' }}>{year.text} <FiChevronDown size={12} /></span>
                </Dropdown> */}
                <Select
                    className='textSM dashboardSelectDropdown borderless w-400 dark3 pad20Container'
                    value={year.value}
                    onChange={(value) => {
                        setYear(options.find(o => o.value === value))
                    }}
                    suffixIcon={<FiChevronDown size={12} />}
                    options={options}
                />
            </div>
            {loading ? <ListLoader /> : <div>
                {funds.slice(0, fundsToShow).map((f, i) => {
                    return (
                        <div key={i} className="borderAll" style={{ backgroundColor: i % 2 !== 0 ? 'var(--white)' : 'var(--grey4)' }}>
                            <Link to={type.basePath + `${type.value === 4 ? '/stock-detail/' + f?.proper_name?.replaceAll(' ', '-') + '/' + f.symbol :
                                '/fund-detail/' + f?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + f.plan_id}`}>
                                <div className="pad20Side12VerContainer" style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                                    <AMCIcon amcName={type.value === 4 ? f.symbol : f.amc_full_name} fundType={type.value} height='1.5rem' width="1.5rem"
                                        bseTicker={f?.bse_ticker} />
                                    <Tooltip title={(type.value === 1 || type.value === 2) ?
                                        (<span className='textXS w-500'>
                                            {type.value === 4 ? f?.proper_name : f?.basic_name?.replace(" - Direct Plan", "")}
                                        </span>) : ""} arrow={false}>
                                        <div className="textXS black hoverBold" style={{ flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                                            {type.value === 4 ? f?.proper_name : f?.basic_name?.replace(" - Direct Plan", "")}
                                        </div>
                                    </Tooltip>
                                    <span className="textXS w-500" style={{
                                        minWidth: "4.5rem", display: "flex", alignItems: "center",
                                        justifyContent: "flex-end"
                                    }}>
                                        <span>
                                            <RedGreenText text={DecimalValueFormat({ num: f[year.key], suffix: ' %' })} value={f[year.key]} />
                                        </span>
                                    </span>
                                </div>
                            </Link>
                        </div>
                    )
                })}
            </div>}
        </Card>)
}

export default DashboardTopPerformersNew