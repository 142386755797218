import { formatDateyyyymmdd } from 'Components/DateFormatter';
import { CustomSelect } from 'Components/CustomComponents/CustomSelect';
import { isSubsequence } from "Libs/search";
import { makeid } from 'Libs/utils';
import LabelInputNew from 'Components/atoms/LabelInputNew';
import { Alert, Collapse, DatePicker, Space, Switch, Tooltip } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useCallback, useRef, useState } from 'react';
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiLock } from 'react-icons/bi';
import { IoIosArrowDown } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { inverseW, metricTypeTabs, risk_management, risk_management_FSDP, risk_management_FSTP, risk_management_TSL, sortBy, transaction_cost, transaction_cost_custom_per, transaction_cost_custom_rs } from './ScreenerUtils';
import { getUserAccessLimitFromAccessObj } from 'Components/SubscribeModal';

export const ScreenBacktestInpModal = React.memo(({ type = {}, orignalConfig = {}, open, toggle = () => { }, initialConfig = {}, viewType = "backtest", startDate = '2018-01-01',
    endDate = formatDateyyyymmdd(new Date()), onDone = () => { }, screenerMetrics, benchmarks, fromStrategyDetail = false, parsedCode = {}, readOnly = false }) => {
    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    const user = useSelector((state) => state?.updateUserData?.value)
    const userAccessLevel = user?.Client?.[0]?.SharpelyAccessLevel || 0
    let userAccess = getUserAccessLimitFromAccessObj({
        accessObj: accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === 'CREDITS_PER_STRATEGY_IN_PAPERTRADE'),
        accessLevel: userAccessLevel
    });
    var toggleCollapse = React.useRef(null)
    var { variables, conditions, conditions_oper } = parsedCode
    const [config, setConfig] = React.useState(JSON.parse(JSON.stringify(initialConfig)));
    const [globals, setGlobals] = React.useState(parsedCode?.globals);

    const [start_date, setstart_date] = useState(startDate)
    const [end_date, setend_date] = useState(endDate)
    const [dates, setDates] = useState(null);
    const [openAccord, setopenAccord] = useState("no")
    const minDate = moment(userAccessLevel > 2 ? '2014-01-01' : '2018-01-01');
    const maxDate = moment(formatDateyyyymmdd(new Date()));
    const [value1, setValue1] = useState(dayjs(startDate, "YYYY-MM-DD"));
    const [value2, setValue2] = useState(dayjs(endDate, "YYYY-MM-DD"));
    var isPanelActive = useRef();
    const quarterMonths = ['January, April, July, October', 'February, May, August, November', 'March, June, September, December'];
    const halfYearlyMonths = ['January, July', 'February, August', 'March, September', 'April, October', 'May, November', 'June, December'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const isMobile = window.innerWidth < 760

    console.log("logger", initialConfig)

    const collapseRef = React.useRef(null);

    React.useEffect(() => {
        const handleOutsideClick = (e) => {
            // Check if the click occurs outside the collapse panel
            // console.log(e.target)
            var handleCustomKeyClass = e.target.className?.includes("custom-key-handle-") || e.target.className?.includes("ant-select")
            var handleSwitches = e.target.className?.includes("ant-switch")
            var handleDateComp = e.target.className?.includes("ant-picker")
            var handleSegmentedComp = e.target.className?.includes("ant-segmented")

            if (collapseRef.current && !collapseRef.current.contains(e.target) && !handleCustomKeyClass && !handleDateComp && !handleSwitches && !handleSegmentedComp) {
                setopenAccord("0") // Close the collapse panel
            }
        };

        document.body.addEventListener('click', handleOutsideClick);

        return () => {
            document.body.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    // console.log(variables)
    const closeOpenMenus = (e) => {
        if (e.defaultPrevented) return;
        console.log("ADSSAD", document.getElementById('screenBacktestConfigCollapse'), e.target)
        setTimeout(() => {
            if (document.getElementById('screenBacktestConfigCollapse'))
                if (document.getElementById('screenBacktestConfigCollapse')?.contains(e.target)) {
                    // Clicked in box
                    console.log("sda21", openAccord)
                } else {
                    // Clicked outside the box
                    console.log("sda", openAccord)
                    if (openAccord === "backtestParameters") {
                        setopenAccord("0")
                    }
                }
        }, 2)

    }

    const handleCollapseClick = (e) => {
        // Check if click occurs within the select dropdown or its options
        if (e.target.closest('.ant-select-dropdown') || e.target.closest('.ant-select-selection-item')) {
            e.stopPropagation(); // Stop propagation to prevent collapse from closing
        }
    };


    // screenerMetrics = [...screenerMetrics]?.concat(priceItems?.map((i) => {
    //     return {
    //         ...i,
    //         "dataIndex": i?.value,
    //         "key": i?.value,
    //         "metric_col_code": i?.value,
    //         "metric_unit": "",
    //         "metric_data_type": "float",
    //         "metric_name": i?.label,
    //         "title": i?.label,
    //         "type": "Metric",
    //         "is_daily": 1
    //     }
    // }))

    // var technicalMetrics = [...screenerMetrics]?.filter((i) => i?.is_technical === 1 || priceItems?.map((i) => i?.value)?.includes(i?.metric_col_code))?.map((i) => {
    //     return {
    //         ...i,
    //         type: "Metric T"
    //     }
    // })
    // var fundamentalsMetrics = [...screenerMetrics]?.filter((i) => i?.is_technical !== 1 && !(priceItems?.map((i) => i?.value)?.includes(i?.metric_col_code)))?.map((i) => {
    //     return {
    //         ...i,
    //         type: "Metric F"
    //     }
    // })

    // screenerMetrics = [...technicalMetrics, ...fundamentalsMetrics].sort((a, b) => a.label.localeCompare(b.label))


    const disabledDate1 = useCallback((d) => {
        if (d == null) {
            return null;
        }
        return (
            (minDate != null && d.isBefore(minDate) && !d.isSame(minDate, 'day')) ||
            (maxDate != null && d.isAfter(maxDate) && !d.isSame(maxDate, 'day'))
        );
    }, [minDate, maxDate]);

    const disabledDate2 = useCallback((d) => {
        if (d == null) {
            return null;
        }
        return (
            (minDate != null && d.isBefore(minDate) && !d.isSame(minDate, 'day')) ||
            (maxDate != null && d.isAfter(maxDate) && !d.isSame(maxDate, 'day')) ||
            (value1 != null && d.isBefore(value1) && !d.isSame(value1, 'day')) || (d.diff(value1, 'days') < 91)
        );
    }, [minDate, maxDate, value1]);

    const backtestWeightMethods = [
        { key: 1, label: "Allocate equal weight", value: "W-EQ" },
        { key: 3, label: "Weight by metric", value: "W-VAR" },
        // { key: 2, label: "Manual weights", value: "W-CT", disabled: true },
    ]

    const backtestFrequencyOptions = [
        // { key: 1, label: "1 minute", value: "1min" },
        // { key: 2, label: "1 day", value: "1D" },

        { key: 3, label: "1 week", value: "1W" },
        { key: 4, label: "2 weeks", value: "2W" },
        { key: 5, label: "1 month", value: "1M" },
        { key: 6, label: "3 months", value: "3M" },
        { key: 7, label: "6 months", value: "6M" },
        { key: 8, label: "12 months", value: "12M" },
        { key: 9, label: "Never rebalance", value: "NA", show: config?.instrument_selection === 1 },
    ]

    const weekDays = [
        { key: 1, label: "Monday", value: 1 },
        { key: 2, label: "Tuesday", value: 2 },
        { key: 3, label: "Wednesday", value: 3 },
        { key: 4, label: "Thursday", value: 4 },
        { key: 5, label: "Friday", value: 5 },
    ]

    const timeframes = [
        { key: 1, value: "1D", label: "1 day" },
        { key: 2, value: "3D", label: "3 days" },
        { key: 3, value: "1W", label: "1 week" },
        { key: 4, value: "2W", label: "2 weeks" },
        { key: 5, value: "1M", label: "1 month" },
    ]

    function getNestedValue(keys) {
        let val = keys.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), config);
        let val2 = keys.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), initialConfig);
        //console.log(val, val2, keys, keys?.includes("schedule"), val === undefined)
        return keys?.includes("schedule") && val === undefined ? val : (val || val2);
    }

    function deleteNestedKey(obj, keysToDelete) {
        if (!obj || keysToDelete.length === 0) {
            return obj;
        }

        const [currentKey, ...remainingKeys] = keysToDelete;

        if (remainingKeys.length === 0) {
            delete obj[currentKey];
        } else if (obj[currentKey] && typeof obj[currentKey] === 'object') {
            deleteNestedKey(obj[currentKey], remainingKeys);
        }
        return obj;
    }

    function addNestedKey(obj, keysToAdd, value) {
        if (!obj || !Array.isArray(keysToAdd) || keysToAdd.length === 0) {
            return obj;
        }

        const [currentKey, ...remainingKeys] = keysToAdd;

        if (remainingKeys.length === 0) {
            obj[currentKey] = value;
        } else {
            obj[currentKey] = obj[currentKey] || {};
            addNestedKey(obj[currentKey], remainingKeys, value);
        }
        return obj;
    }

    function updateNestedValue(obj, keys, value) {
        // obj = obj
        if (!obj || keys.length === 0) {
            return obj;
        }
        //console.log("@#$#@$", obj, keys, value)

        const [currentKey, ...remainingKeys] = keys;

        if (remainingKeys.length === 0) {
            obj[currentKey] = value;
        } else {
            obj[currentKey] = obj[currentKey] || {};
            updateNestedValue(obj[currentKey], remainingKeys, value);
        }
        return obj;
    }

    const changeConfig = (updateKeys, addkeys = [], deleteKeys = []) => {
        let curr = JSON.parse(JSON.stringify(config));
        for (let item of updateKeys) {
            curr = updateNestedValue(curr, item?.keys, item?.value)
        }
        if (deleteKeys?.length > 0) {
            for (let value of deleteKeys) {
                curr = deleteNestedKey(curr, value)
            }
        }
        if (addkeys?.length > 0) {
            for (let item of addkeys) {
                curr = addNestedKey(curr, item?.keys, item?.value)
            }
        }
        console.log("ASASDSAD", curr);
        setConfig(curr);
        onDone(curr, start_date, end_date, {
            globals: globals,
            variables,
            conditions,
            conditions_oper
        });

    }

    console.log(config);

    const BacktestView = () => {
        return <div id='StategyParamsIds'>
            <div style={{ display: "grid", gridTemplateColumns: isMobile ? '1fr' : "0.7fr 1fr", gap: isMobile ? '2rem' : "4rem 5rem" }}>

                <div style={{ display: "flex", flexDirection: "column", gap: isMobile ? '2rem' : "4rem" }} className='w100 h100'>
                    {!fromStrategyDetail && <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "1rem" }}>
                        <Space direction='vertical' style={{}}>
                            <div className='textMD w-700 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                Initial capital
                                <Tooltip title={`Strategy initial capital is set based on your subscription.`} arrow={false}>
                                    <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                </Tooltip>
                            </div>
                            <LabelInputNew
                                min={1000}
                                max={userAccess}
                                // disabled={true}
                                value={getNestedValue(['initial_capital'])}
                                onChangeVal={(value) => {
                                    let val = Number(value)
                                    console.log(value)
                                    changeConfig([{ keys: ['initial_capital'], value: val }])
                                }}
                                prefix={"₹ "}
                                placeholder="10,00,000"
                            />
                        </Space>
                        {viewType === "backtest" && <Space direction='vertical' style={{}}>
                            <div className='textMD w-700 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                Select date range
                                <Tooltip title={`Backtest start and end date`} arrow={false}>
                                    <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                </Tooltip>
                            </div>
                            <Space direction='horizontal'>
                                <DatePicker style={{ padding: "0.37rem" }} rootClassName={`${"custom-key-handle-" + makeid(4)}`} popupClassName={`${"custom-key-handle-" + makeid(4)}`} className={`${"custom-key-handle-" + makeid(4)}`} disabledDate={disabledDate1} placeholder='Start date' value={value1} format={'DD-MM-YYYY'} onChange={(val) => {
                                    setstart_date(formatDateyyyymmdd(new Date(val)))
                                    setValue1(val)
                                    onDone(config, formatDateyyyymmdd(new Date(val)), end_date, {
                                        globals,
                                        variables,
                                        conditions,
                                        conditions_oper
                                    });
                                }} />
                                <DatePicker style={{ padding: "0.37rem" }} rootClassName={`${"custom-key-handle-" + makeid(4)}`} popupClassName={`${"custom-key-handle-" + makeid(4)}`} className={`${"custom-key-handle-" + makeid(4)}`} disabledDate={disabledDate2} disabled={value1 === null || value1 === undefined} placeholder='End date' value={value2} format={'DD-MM-YYYY'} onChange={(val) => {
                                    setend_date(formatDateyyyymmdd(new Date(val)))
                                    setValue2(val)
                                    onDone(config, start_date, formatDateyyyymmdd(new Date(val)), {
                                        globals,
                                        variables,
                                        conditions,
                                        conditions_oper
                                    });
                                }} />
                            </Space>
                        </Space>}
                    </div>}
                    <div>
                        <div className='d-flex mb-3' style={{ flexDirection: "column", gap: "0.2rem" }}>
                            <span className='textMD w-700 dark4'>Rebalance Schedule</span>
                            <span className='textXS textLight'>Define schedule at which your strategy will be rebalanced.{type?.value === 4 && config?.instrument_selection !== 1 ? "You can also configure the strategy to sell stocks when they enter ESM/GSM/ASM frameworks." : ""}</span>
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr", gap: "1rem" }}>
                            {/* <div>
                                <div className='textXS w-500 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                    Timeframe
                                    <Tooltip title={`Timeframe of candles on which to run backtest or strategy. This will always be same as the timeframe of your screen or factor model.`} arrow={false}>
                                        <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                                <CustomSelect
                                    customId={"custom-key-handle-" + makeid(4)} disabled options={timeframes} value={getNestedValue(['bar_timeframe'])} onChange={(val) => {
                                    changeConfig([{ keys: ['bar_timeframe'], value: val }])
                                }} style={{ width: "100%" }} className='whiteBackground' />
                            </div> */}
                            <div>
                                <div className='textXS w-500 dark4 mb-1'>Frequency</div>
                                <CustomSelect
                                    disabled={readOnly}
                                    customId={"custom-key-handle-" + makeid(4)} style={{ width: "100%" }} value={getNestedValue(['schedule', 'freq']) || "NA"} options={backtestFrequencyOptions?.filter((i) => i?.show !== false)} onChange={(val) => {
                                        let addKeys = [], delKeys = [['schedule', 'day'], ['schedule', 'month']];
                                        if (val?.includes('M')) {
                                            addKeys = [{ keys: ['schedule', 'day'], value: 1 }, { keys: ['schedule', 'month'], value: 1 }]
                                            delKeys = []
                                        } else if (val?.includes('W')) {
                                            addKeys = [{ keys: ['schedule', 'day'], value: 1 }]
                                            delKeys = [['schedule', 'month']]
                                        }
                                        changeConfig([{ keys: ['schedule', 'freq'], value: val }], addKeys, delKeys)
                                    }} className='whiteBackground' />
                            </div>
                            {getNestedValue(['schedule', 'freq'])?.includes('M') || getNestedValue(['schedule', 'freq'])?.includes('W') ? <div>
                                <div className='textXS w-500 dark4 mb-1'>On day of {getNestedValue(['schedule', 'freq'])?.includes("W") ? "week" : "month"}</div>
                                <CustomSelect
                                    disabled={readOnly}
                                    customId={"custom-key-handle-" + makeid(4)} options={getNestedValue(['schedule', 'freq'])?.includes('W') ? weekDays : new Array(28).fill(1).map((el, i) => {
                                        return { key: i, label: i + 1, value: i + 1 }
                                    })} value={getNestedValue(['schedule', 'day'])} onChange={(val) => {
                                        changeConfig([{ keys: ['schedule', 'day'], value: val }])
                                    }} style={{ width: "100%" }} className='whiteBackground' />
                            </div> : null}
                            {getNestedValue(['schedule', 'freq'])?.includes('M') && getNestedValue(['schedule', 'freq']) !== '1M' ? <div>
                                <div className='textXS w-500 dark4 mb-1'>On months</div>
                                <CustomSelect
                                    disabled={readOnly}
                                    customId={"custom-key-handle-" + makeid(4)} options={getNestedValue(['schedule', 'freq']) === "12M" ? months?.map((i, index) => {
                                        return {
                                            key: index,
                                            label: i,
                                            value: index + 1
                                        }
                                    }) :
                                        getNestedValue(['schedule', 'freq']) === "3M" ? quarterMonths?.map((opt, i) => {
                                            return {
                                                key: i,
                                                label: opt,
                                                value: i + 1
                                            }
                                        }) : halfYearlyMonths?.map((opt, i) => {
                                            return {
                                                key: i,
                                                label: opt,
                                                value: i + 1
                                            }
                                        })
                                    } value={getNestedValue(['schedule', 'month'])} onChange={(val) => {
                                        //console.log("@#$#@$", val)
                                        changeConfig([{ keys: ['schedule', 'month'], value: val }])
                                    }} style={{ width: "100%" }} className='whiteBackground' />
                            </div> : null}
                            {type?.value === 4 && config?.instrument_selection !== 1 && <div>
                                <div className='textXS w-500 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                    Sell on surveillance
                                    <Tooltip title={`This defines the action to be taken if one or more ${String(type?.basePath)?.replace("/", "")} in your portfolio enter surveillance (ASM/ESM/GSM). This parameter only applies to paper and live trading and will have no impact on backtest or strategy where ${String(type?.basePath)?.replace("/", "")} are NOT sold when they enter surveillance.`} arrow={false}>
                                        <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                                <Switch disabled={readOnly} checked={getNestedValue(['sell_on_surveillance']) === 1} onChange={(e) => {
                                    changeConfig([{ keys: ['sell_on_surveillance'], value: e ? 1 : 0 }])
                                }} />
                            </div>}
                        </div>
                    </div>
                    <div>
                        <div className='d-flex mb-3' style={{ flexDirection: "column", gap: "0.2rem" }}>
                            <span className='textMD w-700 dark4'>Other {viewType === "backtest" ? "Backtest" : "Strategy"} Parameters</span>
                            <span className='textXS textLight'>Here you define the benchmark{config?.instrument_selection !== 1 ? `, assumptions for order fills and what to do on rebalancing when no ${String(type?.basePath)?.replace("/", "")} qualify your selection criteria` : ""}.</span>
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr", gap: "1rem" }}>
                            <div>
                                <div className='textXS w-500 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                    Benchmark
                                    <Tooltip title={`Your strategy’s performance will be compared to this benchmark.`} arrow={false}>
                                        <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                                <CustomSelect
                                    disabled={readOnly}
                                    customId={"custom-key-handle-" + makeid(4)} style={{ width: "100%" }} value={getNestedValue(['benchmark_id'])} className='whiteBackground'
                                    onChange={(val) => {
                                        let bmObj = benchmarks?.find((el) => el?.benchmark_id === val);
                                        changeConfig([{ keys: ['benchmark_id'], value: val }, { keys: ['benchmark_name'], value: bmObj?.benchmark_name }])
                                    }}
                                    options={benchmarks?.map((el) => {
                                        return {
                                            ...el,
                                            key: el?.benchmark_id,
                                            value: el?.benchmark_id,
                                            label: el?.benchmark_name
                                        }
                                    })} showSearch filterOption={(inputVal, option) => isSubsequence(inputVal?.trim(), option?.label)} />
                            </div>
                            {viewType === "backtest" && <div>
                                <div className='textXS w-500 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                    Fill price assumption
                                    <Tooltip title={`Assumptions for fill prices of rebalancing orders. Note that stop loss, profit booking orders are assumed to be filled at their respective target prices.`} arrow={false}>
                                        <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                                <CustomSelect
                                    disabled={readOnly}
                                    customId={"custom-key-handle-" + makeid(4)} style={{ width: "100%" }} value={getNestedValue(['fill_on'])} className='whiteBackground'
                                    onChange={(val) => { changeConfig([{ keys: ['fill_on'], value: val }]) }}
                                    options={[{ key: 0, label: "Open of next candle", value: "open_next" }, { key: 1, label: "High of next candle", value: "high_next", disabled: true },
                                    { key: 2, label: "Low of next candle", value: "low_next", disabled: true }, { key: 3, label: "Close of next candle", value: "close_next", disabled: true },
                                    { key: 4, label: "OHLC4 of next candle", value: "ohlc4_next", disabled: true }]} />
                            </div>}
                            {type?.value === 4 && config?.instrument_selection !== 1 && <div>
                                <div className='textXS w-500 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                    Exclude surveillance stocks
                                    <Tooltip title={`This defines the action to be taken if one or more ${String(type?.basePath)?.replace("/", "")} in your portfolio enter surveillance (ASM/ESM/GSM). This parameter only applies to paper and live trading and will have no impact on backtest or strategy where ${String(type?.basePath)?.replace("/", "")} are NOT sold when they enter surveillance.`} arrow={false}>
                                        <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                                <Switch disabled={readOnly} checked={globals?.exclusion_list?.expression === 0} onChange={(e) => {
                                    if (e) {
                                        setGlobals({
                                            ...globals,
                                            exclusion_list: {
                                                name: "exclusion_list",
                                                isGlobal: true,
                                                expression: 0,
                                                expression_arr: 0
                                            }
                                        })

                                        onDone(config, start_date, end_date, {
                                            globals: {
                                                ...globals,
                                                exclusion_list: {
                                                    name: "exclusion_list",
                                                    isGlobal: true,
                                                    expression: 0,
                                                    expression_arr: 0
                                                }
                                            },
                                            variables,
                                            conditions,
                                            conditions_oper
                                        });
                                    } else {
                                        var akak = JSON.parse(JSON.stringify(globals))
                                        delete akak["exclusion_list"]
                                        setGlobals({
                                            ...akak
                                        })
                                        onDone(config, start_date, end_date, {
                                            globals: {
                                                ...akak
                                            },
                                            variables,
                                            conditions,
                                            conditions_oper
                                        });
                                    }
                                }} />
                            </div>}
                        </div>
                    </div>
                </div>

                <div style={{ display: "flex", flexDirection: "column", gap: isMobile ? "2rem" : "4rem" }} className='w100 h100'>
                    <div>
                        <div className='d-flex mb-3' style={{ flexDirection: "column", gap: "0.2rem" }}>
                            <span className='textMD w-700 dark4'>Position sizing</span>
                            <span className='textXS textLight'>This section allows you to define how to set weights for the {String(type?.basePath)?.replace("/", "")}{config?.instrument_selection !== 2 ? ` as well as maximum ${String(type?.basePath)?.replace("/", "")} to keep in case your criteria throws up a large number of ${String(type?.basePath)?.replace("/", "")}` : ""}.</span>
                        </div>
                        {/* <div className='textSM w-500 dark4 mb-2'>Weight options</div> */}
                        <div style={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr", gap: "1rem" }}>
                            <div id='custom-key-handle-Stock'>
                                <div className='textXS w-500 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                    Stock weightage
                                    <Tooltip title={`Here you can either decide to allocate equal weight to each ${String(type?.strVal)?.replace("/", "")} or weight them basis some chosen metric (like market cap)`} arrow={false}>
                                        <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                                <CustomSelect
                                    disabled={readOnly}
                                    customId={"custom-key-handle-" + makeid(4)}
                                    options={backtestWeightMethods}
                                    value={getNestedValue(['position_sizing', 'method'])}
                                    placeholder='Select weight method' style={{ width: "100%" }} onChange={(val) => {
                                        let delKeys = [], addKeys = [];
                                        if (val === 'W-EQ') {
                                            delKeys = [['position_sizing', 'weight_by'], ['position_sizing', 'weight_list']]
                                        }
                                        else if (val === 'W-VAR') {
                                            delKeys = [['position_sizing', 'weight_list']]
                                            addKeys = [{ keys: ['position_sizing', 'weight_by'], value: "mcap" }]
                                        }
                                        changeConfig([{ keys: ['position_sizing', 'method'], value: val }], addKeys, delKeys)
                                    }} className='whiteBackground' />
                            </div>
                            {getNestedValue(['position_sizing', 'method']) === 'W-VAR' || getNestedValue(['position_sizing', 'method']) === 'W-INV' ? <div>
                                <div className='textXS w-500 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                    Select metric to weight stocks
                                    <Tooltip title={`Stocks will be weighed in proportion to their corresponding value of this metric. Higher value ${String(type?.basePath)?.replace("/", "")} will get higher weight.`} arrow={false}>
                                        <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                                <CustomSelect
                                    disabled={readOnly}
                                    customId={"custom-key-handle-" + makeid(4)}
                                    tabs={metricTypeTabs} value={getNestedValue(['position_sizing', 'weight_by'])} options={screenerMetrics?.map((el) => {
                                        return { ...el, tabType: el?.type }
                                    })?.filter((i) => i?.metric_data_type === "float")?.concat(Object.values(variables || {})?.filter((i) => {
                                        if (config?.instrument_selection === 3) {
                                            return i?.name === "agg_z_score_val"
                                        }
                                        return true
                                    })?.map((el) => {
                                        if (el?.name === 'agg_z_score_val') {
                                            return { "key": "agg_z_score_val", "value": "agg_z_score_val", "label": "Aggregated score", tabType: "Variable" }
                                        }
                                        return { key: el?.name, label: el?.name, value: el?.name, tabType: "Variable" }
                                    }))} showSearch filterOption={(inputVal, option) => {
                                        return isSubsequence(inputVal?.trim(), option?.label)
                                    }} onChange={(val) => {
                                        changeConfig([{ keys: ['position_sizing', 'weight_by'], value: val }])
                                    }} style={{ width: "100%" }} className='whiteBackground' />
                            </div> : <div className='d-flex'></div>}
                            {getNestedValue(['position_sizing', 'method']) === 'W-VAR' || getNestedValue(['position_sizing', 'method']) === 'W-INV' ? <div>
                                <div className='textXS w-500 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                    Use
                                    <Tooltip title={`You can use inverse of metric value to assign weights. Useful when you use metric like PE Ratio, Volatility etc.`} arrow={false}>
                                        <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                                <CustomSelect
                                    disabled={readOnly}
                                    customId={"custom-key-handle-" + makeid(4)}
                                    options={inverseW}
                                    value={getNestedValue(['position_sizing', 'is_inverse'])}
                                    defaultValue={0}
                                    placeholder='Select weight method' style={{ width: "100%" }} onChange={(val) => {
                                        let delKeys = [], addKeys = [];
                                        changeConfig([{ keys: ['position_sizing', 'is_inverse'], value: val }], addKeys, delKeys)
                                    }} className='whiteBackground' />
                            </div> : <div className='d-flex'></div>}
                            {/* </div> */}
                            {/* <div className='textSM w-500 dark4 mb-2 mt-3'></div> */}
                            {/* <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}> */}
                            {config?.instrument_selection !== 1 && <div>
                                <div className='textXS w-500 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                    Max. number of {String(type?.basePath)?.replace("/", "")}
                                    <Tooltip title={`This will define the maximum number of ${String(type?.basePath)?.replace("/", "")} to keep in case your criteria throws up a large number of ${String(type?.basePath)?.replace("/", "")}. We do not support limits greater than 50.`} arrow={false}>
                                        <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                                {/* <InputNumber style={{ width: "10rem" }} min={5} max={50} placeholder={`No. of ${String(type?.basePath)?.replace("/", "")}`}  value={getNestedValue(['position_sizing', 'limit', 'top'])}
                                    onChange={(e) => {
                                        let val = Number(e)
                                        if (Number(val) <= 50 && Number(val) >= 0) {
                                            changeConfig([{ keys: ['position_sizing', 'limit', 'top'], value: Number(val) }])
                                        }
                                    }} /> */}

                                <LabelInputNew
                                    disabled={readOnly}
                                    width={"100%"}
                                    style={{ width: "100%" }}
                                    min={3} max={50}
                                    placeholder={`No. of ${String(type?.basePath)?.replace("/", "")}`}
                                    value={getNestedValue(['position_sizing', 'limit', 'top'])}
                                    onChangeVal={(e) => {
                                        let val = Number(e)
                                        // alert(val)
                                        if (Number(val) <= 50 && Number(val) >= 0) {
                                            changeConfig([{ keys: ['position_sizing', 'limit', 'top'], value: Number(val) }])
                                        }
                                    }}
                                />
                            </div>}
                            {config?.instrument_selection !== 1 && <div>
                                <div className='textXS w-500 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                    Sorted by
                                    <Tooltip title={`Allows you to limit the number of stocks by sorting on any metric of your choice.`} arrow={false}>
                                        <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                                <CustomSelect
                                    disabled={readOnly}
                                    customId={"custom-key-handle-" + makeid(4)}
                                    tabs={metricTypeTabs} value={getNestedValue(['position_sizing', 'limit', 'by'])} options={screenerMetrics?.map((el) => {
                                        return { ...el, tabType: el?.type }
                                    })?.filter((i) => i?.metric_data_type === "float")?.concat(Object.values(variables || {})?.filter((i) => {
                                        if (config?.instrument_selection === 3) {
                                            return i?.name === "agg_z_score_val"
                                        }
                                        return true
                                    })?.map((el) => {
                                        if (el?.name === 'agg_z_score_val') {
                                            return { "key": "agg_z_score_val", "value": "agg_z_score_val", "label": "Aggregated score", tabType: "Variable" }
                                        }
                                        return { key: el?.name, label: el?.name, value: el?.name, tabType: "Variable" }
                                    }))} showSearch filterOption={(inputVal, option) => {
                                        return isSubsequence(inputVal?.trim(), option?.label)
                                    }} onChange={(val) => {
                                        var met = screenerMetrics?.filter((i) => i?.value === val)?.direction_flag === 'top' ? "desc" : "asc"
                                        changeConfig([{ keys: ['position_sizing', 'limit', 'by'], value: val }, { keys: ['position_sizing', 'limit', 'sort'], value: met }])
                                    }} style={{ width: "100%" }} className='whiteBackground' />
                            </div>}
                            {config?.instrument_selection !== 1 && <div>
                                <div className='textXS w-500 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                    Sort metric in
                                    <Tooltip title={`Allows you to limit the number of stocks by sorting on any metric of your choice.`} arrow={false}>
                                        <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                                <CustomSelect
                                    disabled={readOnly}
                                    customId={"custom-key-handle-" + makeid(4)}
                                    options={sortBy}
                                    value={getNestedValue(['position_sizing', 'limit', 'sort'])}
                                    // defaultValue={0}
                                    placeholder='Sort metric in' style={{ width: "100%" }} onChange={(val) => {
                                        let delKeys = [], addKeys = [];
                                        changeConfig([{ keys: ['position_sizing', 'limit', 'sort'], value: val }], addKeys, delKeys)
                                    }} className='whiteBackground' />
                            </div>}

                            {config?.instrument_selection !== 1 && <div>
                                <div className='textXS w-500 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                    Min. number of {String(type?.basePath)?.replace("/", "")}
                                    <Tooltip title={`Min. number of ${String(type?.basePath)?.replace("/", "")} (at the time of rebalancing) that must qualify the selection criteria to trigger a rebalance.`} arrow={false}>
                                        <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                                <LabelInputNew
                                    width={"100%"}
                                    style={{ width: "100%" }}
                                    min={1} max={50}
                                    disabled={readOnly}
                                    placeholder={`No. of ${String(type?.basePath)?.replace("/", "")}`}
                                    value={getNestedValue(['position_sizing', 'min_num']) || 1}
                                    onChangeVal={(e) => {
                                        let val = Number(e)
                                        // alert(val)
                                        if (Number(val) <= 50 && Number(val) >= 0) {
                                            changeConfig([{ keys: ['position_sizing', 'min_num'], value: Number(val) }])
                                        }
                                    }}
                                />
                            </div>}
                            {type?.value === 4 && config?.instrument_selection !== 1 && <div>
                                <div className='textXS w-500 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                    If {String(type?.basePath)?.replace("/", "")} less than minimum
                                    <Tooltip title={`This will define the action to be taken in case (at the time of rebalancing), number of ${String(type?.basePath)?.replace("/", "")} as per the stock selection model is less than minimum required.`} arrow={false}>
                                        <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                                <CustomSelect
                                    disabled={readOnly}
                                    customId={"custom-key-handle-" + makeid(4)} value={getNestedValue(['on_no_signal'])} onChange={(val) => { changeConfig([{ keys: ['on_no_signal'], value: val }]) }}
                                    options={[{ key: 0, label: `Sell all ${String(type?.basePath)?.replace("/", "")} and move to cash`, value: "cash" }, { key: 1, label: `Hold on existing ${String(type?.basePath)?.replace("/", "")}`, value: "hold" }]} style={{ width: "100%" }} className='whiteBackground' />
                            </div>}
                            {config?.instrument_selection !== 1 && <div>
                                <div className='textXS w-500 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                    If {String(type?.basePath)?.replace("/", "")} less than maximum
                                    <Tooltip title={`This will define the action to be taken if number of stocks as per you selection model is less than maximum but more than minimum. You can chose to deploy entire capital or deploy partially (in ratio of number/maximum) and keep rest in cash.`} arrow={false}>
                                        <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                                <CustomSelect
                                    disabled={readOnly}
                                    customId={"custom-key-handle-" + makeid(4)} value={getNestedValue(['position_sizing', 'max_num_not_met'])} onChange={(val) => { changeConfig([{ keys: ['position_sizing', 'max_num_not_met'], value: val }]) }}
                                    options={[{ key: 0, label: `Allocate fully to all stocks`, value: "full_allocation" }, { key: 1, label: `Allocate partially. Rest cash.`, value: "partial_allocation" }]} style={{ width: "100%" }} className='whiteBackground' />
                            </div>}


                            {/* {config?.instrument_selection === 2 && <><div>
                                <div className='textXS w-500 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                    Metric to limit {String(type?.basePath)?.replace("/", "")}
                                    <Tooltip title={`This metric will be used to limit the ${String(type?.basePath)?.replace("/", "")} to maximum number as defined above.`} arrow={false}>
                                        <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                                <CustomSelect tabs={metricTypeTabs}
                                    customId={"custom-key-handle-" + makeid(4)}
                                    value={getNestedValue(['position_sizing', 'limit', 'by'])}
                                    options={screenerMetrics?.map((el) => {
                                        return { ...el, tabType: el?.type }
                                    })?.concat(Object.values(variables || {})?.map((el) => {
                                        return { key: el?.name, label: el?.name, value: el?.name, tabType: "Variable" }
                                    }))} showSearch filterOption={(inputVal, option) => {
                                        return isSubsequence(inputVal?.trim(), option?.label)
                                    }} onChange={(val) => {
                                        let met = screenerMetrics?.find((ele) => ele?.dataIndex === val)?.direction_flag === 'top' ? "desc" : "asc";
                                        changeConfig([{ keys: ['position_sizing', 'limit', 'by'], value: val }, { keys: ['position_sizing', 'limit', 'sort'], value: met }])
                                    }} style={{ width: "100%" }} className='whiteBackground' />
                            </div>
                                <div>
                                    <div className='textXS w-500 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                        Sort metric in
                                        <Tooltip title={`Metric will be sorted as per this rule before applying the limit`} arrow={false}>
                                            <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                        </Tooltip>
                                    </div>
                                    <CustomSelect
                                        customId={"custom-key-handle-" + makeid(4)} style={{ width: "100%" }} placeholder='Sort by' value={getNestedValue(['position_sizing', 'limit', 'sort'])}
                                        onChange={(val) => {
                                            changeConfig([{ keys: ['position_sizing', 'limit', 'sort'], value: val }])
                                        }} options={sortBy} className='whiteBackground' />
                                </div>
                            </>} */}

                        </div>
                    </div>
                    <div
                        style={{ cursor: fromStrategyDetail ? Object.keys(orignalConfig)?.includes('risk_management') ? "pointer" : "not-allowed" : "ponter" }}
                    >
                        <div className='d-flex mb-3' style={{ flexDirection: "column", gap: "0.2rem", opacity: fromStrategyDetail ? Object.keys(orignalConfig)?.includes('risk_management') ? 1 : 0.5 : 1 }}>
                            <div className='d-flex' style={{ alignItems: "center", gap: "1rem" }} >
                                <span className='textMD w-700 dark4'>Risk Management</span>

                                {fromStrategyDetail ? Object.keys(orignalConfig)?.includes('risk_management') ? <Switch checked={getNestedValue(['risk_management']) !== undefined} onChange={(e) => {
                                    if (e) {
                                        changeConfig([{ keys: ['risk_management'], value: risk_management }])
                                    } else {
                                        changeConfig([], [], [['risk_management']])
                                    }

                                }} /> : <BiLock /> : <Switch checked={getNestedValue(['risk_management']) !== undefined} onChange={(e) => {
                                    if (e) {
                                        changeConfig([{ keys: ['risk_management'], value: risk_management }])
                                    } else {
                                        changeConfig([], [], [['risk_management']])
                                    }

                                }} />}
                            </div>
                            <span className='textXS textLight'>This section allows you to define stop loss and profit booking criteria. We currently support 3 risk management models</span>
                        </div>
                        {(fromStrategyDetail ? ((!Object.keys(orignalConfig)?.includes('risk_management')) ? <div><Alert
                            style={{ maxWidth: "60%" }}
                            className='textXS'
                            message="Risk management settings cannot be edited"
                            type="info"
                            showIcon
                        /></div> : getNestedValue(['risk_management']) !== undefined && <div className='my-1 textXS textLight w-500'>Note: Risk management model cannot be edited</div>) : <div className='my-1 textXS textLight w-500'>Note: Risk management model cannot be edited once you start paper trading</div>)}
                        {getNestedValue(['risk_management']) !== undefined && <>
                            <CustomSelect
                                disabled={readOnly || fromStrategyDetail}
                                customId={"custom-key-handle-" + makeid(4)} style={{ width: "50%", marginBottom: 10 }}
                                placeholder="Select risk management model" className='whiteBackground'
                                value={getNestedValue(['risk_management', 'model'])}
                                onChange={(val) => {
                                    changeConfig([{ keys: ['risk_management'], value: val === "TSL" ? risk_management_TSL : val === "FSDP" ? risk_management_FSDP : risk_management_FSTP }])
                                }}
                                options={[{ key: 0, label: "Trailing Stop Loss", value: "TSL" },
                                { key: 1, label: "Fixed Stop with Drawdown Protection", value: "FSDP" },
                                { key: 2, label: "Fixed Stop and Take Profit", value: "FSTP" }]} />


                            {getNestedValue(['risk_management', 'model']) === "TSL" && <>
                                {/* <div className='textSM w-500 dark4 mb-2'>Trailing Stop Percentage</div> */}
                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                                        <div className='textXS w-500 dark3 mb-1'>Trailing Stop Percentage</div>
                                        {/* <InputNumber min={0} placeholder='Percent' suffix="%"  value={getNestedValue(['risk_management', 'trail_sl_percent']) * 100} onChange={ (e) => {
                                            let val = Number(e)
                                            changeConfig([{ keys: ['risk_management', 'trail_sl_percent'], value: (val / 100) || 0 }])
                                        } } /> */}

                                        <LabelInputNew
                                            disabled={readOnly}
                                            placeholder='Percent' suffix="%"
                                            min={0}
                                            value={getNestedValue(['risk_management', 'trail_sl_percent']) * 100} onChangeVal={(e) => {
                                                let val = Number(e)
                                                console.log(val)
                                                changeConfig([{ keys: ['risk_management', 'trail_sl_percent'], value: (val / 100) || 0 }])
                                            }}
                                        />
                                    </div>
                                </div>
                            </>}

                            {getNestedValue(['risk_management', 'model']) === "FSDP" && <>
                                {/* <div className='textSM w-500 dark4 mb-2'>Trailing Stop Percentage</div> */}
                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem", marginBottom: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                                        <div className='textXS w-500 dark3 mb-1'>Fixed Stop Percentage</div>
                                        <LabelInputNew disabled={readOnly} min={0} placeholder='Percent' suffix="%" value={getNestedValue(['risk_management', 'fixed_sl_percent']) * 100} onChangeVal={(e) => {
                                            let val = Number(e)
                                            changeConfig([{ keys: ['risk_management', 'fixed_sl_percent'], value: (val / 100) || 0 }])
                                        }} />
                                    </div>
                                    <div>
                                        <div className='textXS w-500 dark3 mb-1'>Drawdown Trigger Percentage</div>
                                        <LabelInputNew disabled={readOnly} min={0} placeholder='Percent' suffix="%" value={getNestedValue(['risk_management', 'drawdown_trigger_percent']) * 100} onChangeVal={(e) => {
                                            let val = Number(e)
                                            changeConfig([{ keys: ['risk_management', 'drawdown_trigger_percent'], value: (val / 100) || 0 }])
                                        }} />
                                    </div>
                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}>
                                    <div>
                                        <div className='textXS w-500 dark3 mb-1'>Drawdown Stop Percentage</div>
                                        <LabelInputNew disabled={readOnly} min={0} placeholder='Percent' suffix="%" value={getNestedValue(['risk_management', 'drawdown_percent_stop']) * 100} onChangeVal={(e) => {
                                            let val = Number(e)
                                            changeConfig([{ keys: ['risk_management', 'drawdown_percent_stop'], value: (val / 100) || 0 }])
                                        }} />
                                    </div>
                                </div>
                            </>}

                            {getNestedValue(['risk_management', 'model']) === "FSTP" && <>
                                {/* <div className='textSM w-500 dark4 mb-2'>Trailing Stop Percentage</div> */}
                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem", marginBottom: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                                        <div className='textXS w-500 dark3 mb-1'>Fixed Stop Percentage</div>
                                        <LabelInputNew disabled={readOnly} min={0} placeholder='Percent' suffix="%" value={getNestedValue(['risk_management', 'fixed_sl_percent']) * 100} onChangeVal={(e) => {
                                            let val = Number(e)
                                            changeConfig([{ keys: ['risk_management', 'fixed_sl_percent'], value: (val / 100) || 0 }])
                                        }} />
                                    </div>

                                    <div>
                                        <div className='textXS w-500 dark3 mb-1'>Take Profit Trigger Percentage</div>
                                        <LabelInputNew disabled={readOnly} min={0} placeholder='Percent' suffix="%" value={getNestedValue(['risk_management', 'fixed_bp_percent']) * 100} onChangeVal={(e) => {
                                            let val = Number(e)
                                            changeConfig([{ keys: ['risk_management', 'fixed_bp_percent'], value: (val / 100) || 0 }])
                                        }} />
                                    </div>
                                </div>
                            </>}



                        </>}

                    </div>


                    <div
                        style={{ cursor: fromStrategyDetail ? Object.keys(orignalConfig)?.includes('transaction_cost') ? "pointer" : "not-allowed" : "ponter" }}
                    >
                        <div className='d-flex mb-3' style={{ flexDirection: "column", gap: "0.2rem", opacity: fromStrategyDetail ? Object.keys(orignalConfig)?.includes('transaction_cost') ? 1 : 0.5 : 1 }}>
                            <div className='d-flex' style={{ alignItems: "center", gap: "1rem" }} >
                                <span className='textMD w-700 dark4'>Transaction cost</span>

                                {fromStrategyDetail ? Object.keys(orignalConfig)?.includes('transaction_cost') ? <Switch checked={getNestedValue(['transaction_cost']) !== undefined} onChange={(e) => {
                                    if (e) {
                                        changeConfig([{ keys: ['transaction_cost'], value: transaction_cost }])
                                    } else {
                                        changeConfig([], [], [['transaction_cost']])
                                    }

                                }} /> : <BiLock /> : <Switch checked={getNestedValue(['transaction_cost']) !== undefined} onChange={(e) => {
                                    if (e) {
                                        changeConfig([{ keys: ['transaction_cost'], value: transaction_cost }])
                                    } else {
                                        changeConfig([], [], [['transaction_cost']])
                                    }

                                }} />}
                            </div>
                            <span className='textXS textLight'>This section allows you to define brokerage model for your strategies.</span>
                        </div>
                        {(fromStrategyDetail ? ((!Object.keys(orignalConfig)?.includes('transaction_cost')) ? <div><Alert
                            style={{ maxWidth: "60%" }}
                            className='textXS'
                            message="Transaction cost settings cannot be edited"
                            type="info"
                            showIcon
                        /></div> : getNestedValue(['transaction_cost']) !== undefined && <div className='my-1 textXS textLight w-500'>Note: Transaction cost model cannot be edited</div>) : <div className='my-1 textXS textLight w-500'>Note: Transaction cost model cannot be edited once you start paper trading</div>)}
                        {getNestedValue(['transaction_cost']) !== undefined && <>
                            <CustomSelect
                                disabled={readOnly || fromStrategyDetail}
                                customId={"custom-key-handle-" + makeid(4)} style={{ width: "40%", marginBottom: 10 }}
                                placeholder="Select transaction cost model" className='whiteBackground'
                                value={getNestedValue(['transaction_cost', 'brokerage_model'])}
                                onChange={(val) => {
                                    changeConfig([{ keys: ['transaction_cost'], value: val === "ZERODHAEQDEL" ? transaction_cost : JSON.stringify(orignalConfig)?.includes("eq_del_percentage") ? transaction_cost_custom_per : transaction_cost_custom_rs }])
                                }}
                                options={[{ key: 0, label: "Zerodha", value: "ZERODHAEQDEL" },
                                { key: 1, label: "Custom", value: "CUSTOMEQDEL" }]} />


                            {getNestedValue(['transaction_cost', 'brokerage_model']) === "CUSTOMEQDEL" && <>
                                {/* <div className='textSM w-500 dark4 mb-2'>Trailing Stop Percentage</div> */}
                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}>
                                    <div>
                                        <div className='textXS w-500 dark3 mb-1'>Per Trade Charges</div>
                                        {/* <InputNumber min={0} placeholder='Percent' suffix="%"  value={getNestedValue(['risk_management', 'trail_sl_percent']) * 100} onChange={ (e) => {
                                            let val = Number(e)
                                            changeConfig([{ keys: ['risk_management', 'trail_sl_percent'], value: (val / 100) || 0 }])
                                        } } /> */}

                                        <LabelInputNew
                                            disabled={readOnly}
                                            showPerAmtToggle={true}
                                            allowDecimals={true}
                                            val_type={JSON.stringify(config)?.includes("eq_del_percentage") ? "percent" : "rupee"}
                                            placeholder='Enter charges'
                                            // suffix="%"
                                            onValTypeChange={(valType) => {
                                                try {
                                                    // alert(valType)
                                                    var delKeys = valType === "percent" ? [['transaction_cost', 'brokerage_params', 'eq_del_fixed']] : [['transaction_cost', 'brokerage_params', 'eq_del_percentage']]
                                                    changeConfig([{ keys: ['transaction_cost', 'brokerage_params', (valType === "percent" ? 'eq_del_percentage' : 'eq_del_fixed')], value: valType === "percent" ? 0.02 : 20 }], [], delKeys)
                                                } catch (error) {
                                                    console.log(error)
                                                }
                                            }}
                                            min={0}
                                            value={JSON.stringify(config)?.includes("eq_del_percentage") ? getNestedValue(['transaction_cost', 'brokerage_params', 'eq_del_percentage']) : getNestedValue(['transaction_cost', 'brokerage_params', 'eq_del_fixed'])}
                                            onChangeVal={(e, valType) => {
                                                let val = Number(e)
                                                console.log(val)
                                                changeConfig([{ keys: ['transaction_cost', 'brokerage_params', (valType === "percent" ? 'eq_del_percentage' : 'eq_del_fixed')], value: val || 0 }])
                                            }}
                                        />
                                    </div>
                                </div>
                            </>}

                        </>}

                    </div>
                </div>
            </div>
        </div>
    }


    // React.useEffect(() => {
    //     // console.log("DSFDASFF", openAccord)
    //     if (openAccord !== "no")
    //         document.addEventListener('click', closeOpenMenus)

    //     if (openAccord === "no")
    //         document.removeEventListener('click', closeOpenMenus)

    // }, [openAccord])

    // React.useEffect(() => {
    //     document.addEventListener('click', closeOpenMenus)
    //     return () => {
    //         document.removeEventListener('click', closeOpenMenus)
    //     }
    // }, [])


    return (
        <React.Fragment>
            <div ref={collapseRef} id="screenBacktestConfigCollapse">
                {fromStrategyDetail ? <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ flex: 1 }}>
                        <BacktestView />
                    </div>
                </div> : <Collapse oonClick={handleCollapseClick} className='screenBacktestConfigCollapse'

                    // ref={isPanelActiv
                    accordion
                    onChange={(activeKey) => {
                        console.log(activeKey?.length)
                        // if (Boolean(activeKey?.length))
                        if (!fromStrategyDetail)
                            setopenAccord(activeKey?.length > 0 ? "backtestParameters" : "no")
                        // isPanelActive.current = Boolean(activeKey?.length)
                    }}
                    bordered={false}
                    // defaultActiveKey={["sad"]}
                    activeKey={openAccord}
                    // collapsible={fromStrategyDetail ? 'disabled' : 'header'}
                    expandIconPosition={'end'}
                    expandIcon={({ isActive }) => <IoIosArrowDown style={{
                        transition: "0.2s linear", transform: isActive ? "rotate(180deg)" : "rotate(0deg)", fontSize: "1rem"
                    }} />}
                    style={{ padding: 0, paddingBottom: 0, backgroundColor: 'var(--white)', border: "1px solid var(--grey3)" }}
                    items={[
                        {
                            key: 'backtestParameters',
                            label: <div className='d-flex flex-1 align-items-center justify-content-between'>
                                <div className='textSM w-700 dark4'>{viewType === "backtest" ? "Backtest" : "Strategy"} Parameters</div>
                                {/* {!fromStrategyDetail && !((JSON.stringify(initialConfig) === JSON.stringify(config) && startDate === start_date && endDate === end_date)) && <button className='btnBlack'
                                onClick={(e) => {
                                    onDone(config, start_date, end_date);
                                    if (!isPanelActive?.current) {
                                        e.stopPropagation();
                                    }
                                }}>
                                Re run backtest
                            </button>} */}
                            </div>,
                            children: <BacktestView />
                        }
                    ]} />}

            </div>

        </React.Fragment>
    )
})