import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import React from 'react';
import { DecimalValueFormat } from './DecimalValueFormat';

const ColumnChart = ({ chartName = '', chartData = [], xAxisKey = "Date", yAxisKey = '', hidePerc = true, showYAxis = false, showDataLabels = true, height = 200, isQuaterly = false }) => {
    const [noChart, setnoChart] = React.useState(false);

    var redColor = '#FF6A55'
    var greenColor = '#83BF6E'
    const data = JSON.parse(chartData || '')
    if (!data.length || !data.some(d => d?.[yAxisKey])) {
        return <div className='textSM' style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><div className='mb-4'>Not enough data</div></div>
    }
    try {
        var options = {
            chart: {
                type: 'column',
                backgroundColor: "transparent",
                height: height,
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                visible: showYAxis,
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                title: {
                    text: ''
                },
                gridLineColor: 'var(--grey1)'
            },
            xAxis: [{
                type: 'categories',
                categories: data.map(x => x?.[xAxisKey]),
                lineColor: 'var(--grey1)',
                tickColor: 'var(--grey1)',
                endOnTick: true,
                labels: {
                    formatter: function () {
                        if (this.value === 'Latest' || this.value === 'TTM') {
                            return this.value
                        } else if (isQuaterly) {
                            return moment(new Date(this.value)).format('MMM YY')
                        } else return moment(new Date(this.value)).format('YYYY')
                    },
                    step: 1
                }
            }
            ],
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    lineWidth: 1,
                    label: {
                        connectorAllowed: false
                    },
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                formatter: function () {
                    return (moment(new Date(this.x)).year() || this.x) + '<br/>' + this.series.name + ": <b>" + DecimalValueFormat({ num: this.y, decimals: 2, suffix: hidePerc ? "" : "%" }) + "</b>"
                }
            },
            credits: {
                enabled: false
            },
            boost: {
                useGPUTranslations: true,
            },
            series: [{
                name: chartName,
                turboThreshold: 9e9,
                color: greenColor + "88",
                negativeColor: redColor + "88",
                type: 'column',
                dataLabels: {
                    enabled: showDataLabels,
                    style: { fontWeight: '400', textOutline: "none", color: "var(--dark4)" },
                    formatter: function () {
                        return DecimalValueFormat({ num: this.y, decimals: 2, suffix: hidePerc ? "" : "%" })
                    }
                },
                data: data.map((i, index) => {
                    return { y: i?.[yAxisKey], color: i?.[xAxisKey] === "Latest" || i?.[xAxisKey] === "TTM" ? 'pink' : null }
                }),
            }],
            scrollbar: {
                enabled: false
            },
        };
    } catch (error) {
        setnoChart(true)
    }




    try {
        return (
            noChart ? <div className='textXS' style={{ margin: "4rem", textAlign: "center" }}>
                No chart data available!
            </div>
                : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '1rem', }}>

                    <HighchartsReact highcharts={Highcharts} options={options} />
                </div>
        );
    } catch (error) {
        return <div className='textSM' style={{ margin: "4%", textAlign: "center" }}>
            No chart data available!
        </div>
    }
};
export default ColumnChart;