import { Input, Modal } from "antd";
import React from "react";

export const CustomComparatorEditor = React.memo(({ open, toggle = () => { }, width = 'min(100%, 30rem)', selectedCustomComp, onDone = () => { }, variables = {},
    screenerMetrics = {} }) => {

    const [startValue, setStartValue] = React.useState(selectedCustomComp?.selectedItems?.[0] || '');
    const [endValue, setEndValue] = React.useState(selectedCustomComp?.selectedItems?.[1] || '');

    React.useEffect(() => {
        if (open) {
            setStartValue(selectedCustomComp?.selectedItems?.[0] || '');
            setEndValue(selectedCustomComp?.selectedItems?.[1] || '');
        }
    }, [selectedCustomComp]);

    return (
        <React.Fragment>
            <Modal open={open} footer={null} onCancel={toggle} onOk={toggle} width={width} closable={false} centered>
                <div onClick={(e) => e.stopPropagation()}>
                    <div className='textMD w-700 dark4' style={{ textTransform: "capitalize" }}>{selectedCustomComp?.comparator?.label}</div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: '1.5rem 2rem', marginTop: "1.25rem", alignItems: "center" }}>
                        <div className="w100 h100">
                            <label className="textSM w-500 dark3 mb-2">Start value</label>
                            <Input placeholder='Start value' value={startValue} onChange={(e) => {
                                setStartValue(Number(e.target.value));
                            }} type='number' />
                        </div>
                        <div className="w100 h100">
                            <label className="textSM w-500 dark3 mb-2">End value</label>
                            <Input placeholder='End value' value={endValue} onChange={(e) => {
                                setEndValue(Number(e.target.value));
                            }} type='number' />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end" style={{ gap: "1.25rem", marginTop: "1.25rem" }}>
                        <button className="btnWhite" onClick={() => {
                            toggle();
                        }}>Cancel</button>
                        <button className="btnBlack" disabled={(!startValue && startValue !== 0) || (!endValue && endValue !== 0)} onClick={() => {
                            let modifiedOption = {
                                queryKey: selectedCustomComp?.queryKey,
                                query: {
                                    expression: selectedCustomComp?.comparator?.label,
                                    expression_details: selectedCustomComp?.comparator,
                                    expression_arr: [startValue, endValue],
                                    isCustomComparator: true,
                                    comparator: selectedCustomComp?.comparator?.value
                                }
                            }
                            onDone(modifiedOption);
                        }}>Done</button>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
})