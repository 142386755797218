import { Avatar } from 'antd';
import { baseImgURL } from 'Assets/Constant';
import { sharpelyOnlyLogo, tricolor } from 'Assets/images';
import React from 'react';

function AMCIcon({ amcName, type = "circle", height = "50px", width = "50px", className = "", fundType = 1, showTricolor = false, bseTicker, showSharpelyLogo = false }) {
    //fundType = 1 => etfs, 2 => mf, 4 => stocks
    const [hasError, setHasError] = React.useState(false);

    const amcList = [
        { Id: 0, Name: "by AMC", imgSource: baseImgURL + 'mintbox_logo.svg' },
        { Id: 1, Name: "Aditya Birla Sun Life Mutual Fund", imgSource: baseImgURL + 'ABC.webp' },
        { Id: 2, Name: "HDFC Mutual Fund", imgSource: baseImgURL + 'HDFC.webp' },
        { Id: 3, Name: "ICICI Prudential Mutual Fund", imgSource: baseImgURL + 'ICICI.webp' },
        { Id: 5, Name: "SBI Mutual Fund", imgSource: baseImgURL + 'SBI.webp' },
        { Id: 6, Name: "Tata Mutual Fund", imgSource: baseImgURL + 'Tata.webp' },
        { Id: 7, Name: "Invesco Mutual Fund", imgSource: baseImgURL + 'Invesco.webp' },
        { Id: 8, Name: "Quantum Mutual Fund", imgSource: baseImgURL + 'Quantum.webp' },
        { Id: 9, Name: "UTI Mutual Fund", imgSource: baseImgURL + 'UTI.webp' },
        { Id: 10, Name: "IDFC Mutual Fund", imgSource: baseImgURL + 'IDFC.webp' },
        { Id: 12, Name: "DSP Blackrock", imgSource: baseImgURL + 'DSP.webp' },
        { Id: 121, Name: "DSP Mutual Fund", imgSource: baseImgURL + 'DSP.webp' },
        { Id: 13, Name: "Kotak Mutual Fund", imgSource: baseImgURL + 'Kotak.webp' },
        { Id: 15, Name: "Mirae Asset Management", imgSource: baseImgURL + 'Mirae.webp' },
        { Id: 16, Name: "Franklin Templeton", imgSource: baseImgURL + 'Franklin.webp' },
        { Id: 161, Name: "Franklin Templeton Mutual Fund", imgSource: baseImgURL + 'Franklin.webp' },
        { Id: 18, Name: "Sundaram Mutual Fund", imgSource: baseImgURL + 'Sundaram.webp' },
        { Id: 20, Name: "Motilal Oswal Mutual Fund", imgSource: baseImgURL + 'Motilal.webp' },
        { Id: 22, Name: "HSBC Mutual Fund", imgSource: baseImgURL + 'HSBC.webp' },
        { Id: 23, Name: "Edelweiss Mutual Fund", imgSource: baseImgURL + 'Edelweiss.webp' },
        { Id: 24, Name: "L&T Mutual Fund", imgSource: baseImgURL + 'L&T.webp' },
        { Id: 25, Name: "Axis Mutual Fund", imgSource: baseImgURL + 'AXIS.webp' },
        { Id: 26, Name: "BNP Paribas Mutual Fund", imgSource: baseImgURL + 'BNP.webp' },
        { Id: 261, Name: "Baroda BNP Paribas Mutual Fund", imgSource: baseImgURL + 'BNP.webp' },
        { Id: 27, Name: "IIFL Mutual Fund", imgSource: baseImgURL + 'IIFL.webp' },
        { Id: 28, Name: "Taurus Mutual Fund", imgSource: baseImgURL + 'Taurus.webp' },
        { Id: 31, Name: "Baroda Mutual Fund", imgSource: baseImgURL + 'BARODA.webp' },
        { Id: 34, Name: "IDBI Mutual Fund", imgSource: baseImgURL + 'IDBI.webp' },
        { Id: 36, Name: "PPFAS Mutual Fund", imgSource: baseImgURL + 'PPFAS.webp' },
        { Id: 37, Name: "JM Financials Mutual Fund", imgSource: baseImgURL + 'JM.webp' },
        { Id: 371, Name: "JM Financial Mutual Fund", imgSource: baseImgURL + 'JM.webp' },
        { Id: 38, Name: "LIC Nomura Mutual Fund", imgSource: baseImgURL + 'LIC.webp' },
        { Id: 39, Name: "Canara Robeco Mutual Fund", imgSource: baseImgURL + 'CANARA.webp' },
        { Id: 40, Name: "Principal Mutual Fund", imgSource: baseImgURL + 'Principal.webp' },
        { Id: 41, Name: "Indiabulls Mutual Fund", imgSource: baseImgURL + 'Indiabulls.webp' },
        { Id: 42, Name: "Mahindra Manulife Mutual Fund", imgSource: baseImgURL + 'Mahindra.webp' },
        { Id: 45, Name: "Bharti AXA Mutual Fund", imgSource: baseImgURL + 'BOI.webp' },
        { Id: 46, Name: "Bank of India Mutual Fund", imgSource: baseImgURL + 'BOI.webp' },
        { Id: 56, Name: "Nippon India Mutual Fund", imgSource: baseImgURL + 'Nippon.webp' },
        { Id: 57, Name: "PGIM India Mutual Fund", imgSource: baseImgURL + 'PGIM.webp' },
        { Id: 58, Name: "Navi Mutual Fund", imgSource: baseImgURL + 'NAVI.webp' },
        { Id: 59, Name: "Shriram Mutual Fund", imgSource: baseImgURL + 'Shriram.webp' },
        { Id: 60, Name: "Union KBC Mutual Fund", imgSource: baseImgURL + 'Union.webp' },
        { Id: 69, Name: "Union Mutual Fund", imgSource: baseImgURL + 'Union.webp' },
        { Id: 61, Name: "Yes Mutual Fund", imgSource: baseImgURL + 'Yes.webp' },
        { Id: 62, Name: "ITI Mutual Fund", imgSource: baseImgURL + 'ITI.webp' },
        { Id: 63, Name: "Quant Mutual Fund", imgSource: baseImgURL + 'Quant.webp' },
        { Id: 64, Name: "Trust Mutual Fund", imgSource: baseImgURL + 'Trust.webp' },
        { Id: 641, Name: "TRUST Mutual Fund", imgSource: baseImgURL + 'Trust.webp' },
        { Id: 65, Name: "LIC Mutual Fund", imgSource: baseImgURL + 'LIC.webp' },
        { Id: 66, Name: "ICICI Mutual Fund", imgSource: baseImgURL + 'ICICI.webp' },
        { Id: 67, Name: "Mirae Asset Mutual Fund", imgSource: baseImgURL + 'Mirae.webp' },
        { Id: 68, Name: "Kotak Mahindra Mutual Fund", imgSource: baseImgURL + 'Kotak.webp' },
        { Id: 69, Name: "WhiteOak Capital Mutual Fund", imgSource: baseImgURL + 'white_oak.webp' },
        { Id: 69, Name: "NJ Mutual Fund", imgSource: baseImgURL + 'nj_mf.webp' },
        { Id: 69, Name: "Samco Mutual Fund", imgSource: baseImgURL + 'samco.webp' },
        { Id: 70, Name: "Bandhan Mutual Fund", imgSource: baseImgURL + 'stockimgfinal/BANDHANBNK.webp' },
        { Id: 701, Name: "Zerodha Mutual Fund", imgSource: baseImgURL + 'ZEDMF.png' },
        { Id: 702, Name: "Groww Mutual Fund", imgSource: baseImgURL + 'groww.png' },
        { Id: 703, Name: "Helios Mutual Fund", imgSource: baseImgURL + 'Helios Mutual Fund.png' },
    ]

    return (
        <Avatar shape={type} key={amcName} title={amcName} alt={amcName} size={'small'} style={{
            display: 'flex', alignItems: 'center', objectFit: 'cover',
            verticalAlign: 'middle', backgroundColor: "var(--grey1)", color: "var(--dark3)", height: height, width: width, flexShrink: 0
        }} src={showTricolor ? tricolor : showSharpelyLogo ? sharpelyOnlyLogo : fundType === 4 ? (hasError ? baseImgURL + 'stockimgfinal/' + bseTicker + ".webp" : baseImgURL + 'stockimgfinal/' + amcName + ".webp") : (amcList.filter((s) => s.Name === amcName)).length !== 0 ? (amcList.filter((s) => s.Name === amcName))[0].imgSource : amcList[0].imgSource} onError={(e) => {
            if (fundType === 4) {
                setHasError(true);
                return true;
            }
        }} >
            {amcName?.[0]}
        </Avatar>
    )
}

export default AMCIcon