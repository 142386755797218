import Head from "Layout/Head";
import React from "react";

export default function RefundAndCancellation() {

    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';

    return (
        <React.Fragment>
            <Head title='sharpely: Refund and Cancellation Policy | Transparent Guidelines' description="Understand sharpely's clear and concise refund and cancellation policy to ensure a seamless experience." />
            <main className="marketing">
                <div style={{ background: "var(--blueShade)" }}>
                    <div className={`padAll marHomeResMainCard ${isDev ? 'maxWidthContainer' : ""}`}>
                        <h1 className="headXLtoSM black w-700 margin0">
                            Refund and Cancellation
                        </h1>
                    </div>
                </div>
                <div className={`padAll ${isDev ? 'maxWidthContainer' : ""}`}>
                    {privacyData?.map((item, i) => (
                        <div key={item.section}>
                            <h2 className="headSMtoTextLG dark4 w-500 margin0">
                                {i + 1}. {item.section}
                            </h2>
                            <div style={{ marginTop: "1.25rem" }}>
                                {item.content?.map((ele, j) => (
                                    <div className="textMDtoSM black w-500" style={{ marginBottom: "1.5rem", opacity: 0.8 }} key={j}>
                                        {ele}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </main>
        </React.Fragment>
    )
}

const privacyData = [
    {
        section: "Refund and cancellation policy",
        content: ["sharpely basic plan is free of charge and hence refund and cancellation do not apply. You can avail all features in sharpely basic free of cost.", `sharpely paid plans are available with 2 payment options. You can pay monthly or pay annually. If you decide to pay annually, you get a 15% to 20% discount depending on your plan. `]
    },
    {
        section: "Cancel anytime",
        content: [
            <span>You can cancel your subscription anytime you like. You will continue to have access to your plan till the end date of your subscription period. </span>,
            <span>For example, if you a take a monthly PRO plan on 1st January 2024 and cancel on 10th January 2024, you will have PRO access till 31st January 2024. If you take an yearly Lite plan on 1st January 2024 and cancel on 14th March 2024, you will continue to have Lite access till 31st December 2024.</span>,
            <span>We are also open to refunds on a case by case basis. Please contact <a href='mailto:support@sharpely.in'>support@sharpely.in</a> for queries regarding refund.</span>,
            // <span>Also note that when you sign up for a free trial, you register a mandate that allows us to debit your bank account after the end of the trial period. If, during this trial period, you revoke the mandate or cancel your free trial, your access will also be revoked and you will not be charged anything.</span>
        ]
    }
]