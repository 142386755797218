import React, { createRef, useEffect } from "react";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox.js";

const Widget = ({ scriptHTML, scriptSRC, containerId, type, }) => {

  const ref = createRef();
  const [load, setLoad] = React.useState(true);

  try {

    useEffect(() => {
      let refValue;

      const initWidget = () => {
        if (ref.current) {
          const script = document.createElement("script");
          script.setAttribute("data-nscript", "afterInteractive");
          script.src = scriptSRC;
          script.async = true;
          script.type = "text/javascript";

          if (type === "Widget" || type === "MediumWidget") {
            script.onload = () => {
              if (typeof window.TradingView !== undefined) {
                script.innerHTML = JSON.stringify(type === "Widget" ? new window.TradingView.widget(scriptHTML) : type === "MediumWidget" ? new window.TradingView.MediumWidget(scriptHTML) : undefined);
              }
              setLoad(false);
            }
          } else {
            script.innerHTML = JSON.stringify(scriptHTML);
            setLoad(false);
          }
          if (!ref.current?.querySelector('script[src="' + scriptSRC + '"]')) {
            ref.current.appendChild(script);
          }
          refValue = ref.current;
        }
      };
      requestAnimationFrame(initWidget);

      return () => {
        if (refValue) {
          while (refValue.firstChild) {
            refValue.removeChild(refValue.firstChild);
          }
        }
      };
    }, [ref, scriptHTML, type, scriptSRC]);

    return (
      <div>
        {load && <ActivityLoaderMintbox />}
        <div ref={ref} id={containerId} />
      </div>
    )
  }
  catch (err) {
    return (
      <div>
        {load && <ActivityLoaderMintbox />}
        <div ref={ref} id={containerId} />
      </div>
    )
  }
};

export default Widget;