import { Card, Segmented, Select } from 'antd';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { CardHeader } from 'Components/atoms/SmallComponents';
import CustomSegmentedTab from 'Components/CustomSegmentedTab';
import IndexOrSectorsTreeChart from 'Components/IndexOrSectorsTreeChart';
import InstrumentListTable from 'Components/InstrumentListTable';
import PerformanceChart3 from 'Components/PerformanceChart3';
import Highcharts from 'highcharts';
import HighchartsHeatmap from "highcharts/modules/heatmap";
import Head from 'Layout/Head';
import { displayValue, screenerMetricData, useGetAssetType, generateUniqueKey } from 'Libs/utils';
import React, { useState } from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import { IoIosArrowDown } from 'react-icons/io';
import CustomSideTabs from "Components/CustomSideTabs";
import { TiDelete } from 'react-icons/ti';
import { useLocation, useNavigate } from 'react-router';
import AdminServicesPY from 'Services/api/AdminServicesPY';
import { NoDataFound } from 'Components/NoDataFound';
import CustomLabelValueCards from 'Components/CustomLabelValue';
import ColumnChart from 'Components/ColumnChart';
import { formatDateMMMDDYYYY } from "Components/DateFormatter";
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { DecimalValueFormat } from 'Components/DecimalValueFormat';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import AccessControlledPage from 'Components/AccessControlledPage';
import AccessComponent from 'Components/AccessComponent';
import UpgradeToPro from 'Components/UpgradeToPro';
HighchartsHeatmap(Highcharts)

const StockSectorsDetail = ({ isWidget = false, widgetItem = {} }) => {

    const { state } = useLocation()
    const [sectors, setSectors] = useState([])
    const [industries, setIndustries] = useState([])
    const [basicIndustries, setBasicIndustries] = useState([])
    const [def_stock_sectors, setDefSectors] = useState()
    const [def_stock_industries, setDefIndustries] = useState(state?.selectionData?.indgrp || '')
    const [def_stock_basic_industries, setDefBasicIndustries] = useState(state?.selectionData?.ind || '')
    const [loading, setloading] = useState(true)
    const [fundamentals, setFundamentals] = useState({})
    const [technicals, setTechnicals] = useState({})
    const [screenerMetricDescData, setScreenerMetricDescData] = React.useState([])
    const [investInScreenerModal, setinvestInScreenerModal] = useState(false)

    const type = useGetAssetType()

    const loadData = async () => {
        var screener_datas = await screenerMetricData(type, 0, 0, 0);
        //console.log(screener_datas)
        setSectors(screener_datas?.fabricatedData?.sectors)
        setScreenerMetricDescData(screener_datas?.screenerMetricDescData)
        setDefSectors(state?.selectionData?.sector || screener_datas?.fabricatedData?.sectors[0].value)
        setIndustries([{ "key": "all", "label": "All Industry groups", "value": "" }]?.concat(screener_datas?.fabricatedData?.industries))
        setBasicIndustries([{ "key": "all", "label": "All Industries", "value": "" }]?.concat(screener_datas?.fabricatedData?.basicIndustries))
        setloading(false)
    }

    React.useEffect(() => {
        ////console.log('sector changes in state')
        if (state?.selectionData?.sector) {
            setDefSectors(state?.selectionData?.sector)
            setDefIndustries(state?.selectionData?.indgrp)
            setDefBasicIndustries(state?.selectionData?.ind)
        }
    }, [state]);

    React.useEffect(() => {
        loadData()
    }, [])

    React.useEffect(() => {
        var getD = async () => {
            var code = def_stock_basic_industries !== "" ? def_stock_basic_industries : def_stock_industries !== "" ? def_stock_industries : def_stock_sectors
            if (code) {
                let res11 = await AdminServicesPY.getIndividualSectorData(code);
                res11 = res11.type !== false ? JSON.parse(res11.data.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null)) : []
                setFundamentals(res11['fundamental_data'] || {});
                setTechnicals(res11['tech_data'] || {});
            } else {
                setFundamentals({})
                setTechnicals({})
            }
        }
        if (def_stock_sectors !== '') {
            getD()
        }
    }, [def_stock_sectors, def_stock_basic_industries, def_stock_industries])

    const tabOptions = [
        {
            "value": "Stocks",
            "queryStr": "Stocks",
            "label": <h2 className='margin0 font-inherit w-500 lineHeightUnset'>Stocks</h2>,
            "children": <AccessComponent component={<InstrumentListTable
                tableType={0}
                isCard={false}
                showBubbleChartBtn
                showHeatmap={true}
                defFilters={[
                    ["sector_code", def_stock_sectors],
                    def_stock_industries.length > 0 && ["industry_code", def_stock_industries],
                    def_stock_basic_industries.length > 0 && ["nse_basic_ind_code", def_stock_basic_industries]
                ]?.filter((i) => i !== false)}
                tableHeader={<Header />}
                hideHeaderBlueLine={true}
                investInScreener={() => {
                    setinvestInScreenerModal(!investInScreenerModal)
                }}
                showToolsBtnsInTab downloadTable showHeatmapBtn
            />} upgradeComponent={<div>
                <Header />
                <UpgradeToPro moduleKey='SECTOR_DASHBOARD' />
            </div>} moduleKey='SECTOR_DASHBOARD' />
        },
        {
            "value": "Fundamentals",
            "queryStr": "Heatmap",
            "label": <h2 className='margin0 font-inherit w-500 lineHeightUnset'>Fundamentals</h2>,
            "children": <AccessComponent component={<Fundamentals />} upgradeComponent={<div>
                <UpgradeToPro moduleKey='SECTOR_DASHBOARD' />
            </div>} moduleKey='SECTOR_DASHBOARD' />
        },
        {
            "value": "Technicals",
            "queryStr": "Technicals",
            "label": <h2 className='margin0 font-inherit w-500 lineHeightUnset'>Technicals</h2>,
            "children": <AccessComponent component={<Technicals />} upgradeComponent={<div>
                <UpgradeToPro moduleKey='SECTOR_DASHBOARD' />
            </div>} moduleKey='SECTOR_DASHBOARD' />
        },
    ]

    const tabOptions3 = [
        {
            "value": "1D",
            "queryStr": "1D",
            "label": "1D returns"
        },
        {
            "value": "1M",
            "queryStr": "one_mth_pct",
            "label": "1M returns"
        },
        {
            "value": "3M",
            "queryStr": "three_mth_pct",
            "label": "3M returns"
        },
        {
            "value": "6M",
            "queryStr": "six_mth_pct",
            "label": "6M returns"
        },

        {
            "value": "1Y",
            "queryStr": "one_yr_pct",
            "label": "1Y returns"
        },
        {
            "value": "3Y",
            "queryStr": "three_yr_pct",
            "label": "3Y returns"
        },
        {
            "value": "5Y",
            "queryStr": "five_yr_pct",
            "label": "5Y returns"
        },
        {
            "value": "10Y",
            "queryStr": "ten_yr_pct",
            "label": "10Y returns"
        }
    ]

    const [selectedTabOption, setselectedTabOption] = useState(tabOptions[0].value)
    const [selectedYear, setselectedYear] = useState(tabOptions3[0].value)

    function Header() {
        return <CustomSegmentedTab
            flexVal={"unset"}
            isBlock={false}
            className='black textSM'
            options={tabOptions}
            onChange={(value) => {
                setselectedTabOption(value)
            }}
            value={selectedTabOption}
        />
    }

    const AppliedFilterView = () => (
        <Card style={isWidget ? { borderBottom: '1px solid #f0f0f0', borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : {}}>
            <div style={{ display: "flex", flexDirection: "column", gap: "1rem", justifyContent: 'space-between' }}>
                <div >
                    <CardHeader heading={isWidget ? widgetItem?.Name : 'Select sector/industry group/industry'} headingType={isWidget ? 2 : 1} />
                    {isWidget && <div className="customTabsSubHeading" style={{ marginBottom: 0 }}>{widgetItem?.Description}</div>}
                </div>
                <div style={{ alignItems: "center" }}>
                    <div className=''>
                        <Select removeIcon={<TiDelete size={12} />} className='textSM black' style={{ width: "17rem" }} value={def_stock_sectors}
                            suffixIcon={<IoIosArrowDown size={15} />} placeholder="Please select weighing method for stocks" onChange={(value) => {
                                setDefSectors(value)
                                setDefIndustries('')
                                setDefBasicIndustries('')
                            }} options={sectors} />
                        <BsArrowRightShort className='mx-3' />
                        <Select removeIcon={<TiDelete size={12} />} className='textSM black' style={{ width: "17rem" }}
                            suffixIcon={<IoIosArrowDown size={15} />} value={def_stock_industries} placeholder="Please select weighing method for stocks" onChange={(value) => {
                                setDefIndustries(value)
                                setDefBasicIndustries('')
                            }} options={industries.filter((i) => String(i.value).includes(def_stock_sectors) || i.value === "")} />
                        <BsArrowRightShort className='mx-3' />
                        <Select removeIcon={<TiDelete size={12} />} className='textSM black' style={{ width: "17rem" }}
                            suffixIcon={<IoIosArrowDown size={15} />} value={def_stock_basic_industries} placeholder="Please select weighing method for stocks" onChange={(value) => {
                                setDefBasicIndustries(value)
                            }} options={basicIndustries.filter((i) => (String(i.value).includes(def_stock_sectors) && String(i.value).includes(def_stock_industries)) || i.value === "")} />
                    </div>
                </div>
            </div>
        </Card >
    )

    function ChartNotes({ sec, tab }) {
        let notes = [{ sec: "Value", tab: ['Multiples'], note: "Note: 0 value to be interpreted as follows – the denominator (e.g., EPS) was negative or not available and hence multiple could not be calculated. Also, aggregated ratios (averaged using equal or market cap weights) can blow up when some of the stocks have negative value of denominator. Using median value avoids this and gives a better picture of valuation trend." },
        { sec: "Value", tab: ['Yields (%)'], note: "Note: Using aggregated yields (averaged using equal or market cap weights) can be misleading at times, especially when some of the stocks have negative value of numerator. Using median value avoids this and gives a better picture of valuation trend." }]

        let c = notes?.find(ele => ele.sec === sec && ele.tab.includes(tab))?.note;
        if (!c) return null;
        return (
            <div style={{ margin: "0 1rem 1rem" }} className='textXS black'>
                {c}
            </div>
        )
    }

    function Fundamentals() {
        const lineCharts = ['Value'];
        const barCharts = ['Quality', 'Financial Health'];
        const logCharts = ['Value']
        const FundamentalChildren = ({ data, type, selectedTab, yAxisType }) => {

            const FundamentalChart = ({ chart, type, yAxisType }) => {
                const [scaleType, setScaleType] = React.useState('linear');
                let check = ((typeof (chart?.chart_data) === 'string') && (JSON.parse(chart?.chart_data || '[]')?.length === 0) || chart?.chart_data?.length === 0)
                if (chart && check) {
                    return <div className='d-flex flex-column justify-content-center flex-grow-1'>
                        <NoDataFound message={chart?.no_data_text || 'Not enough data'} />
                    </div>
                }
                else if (chart && chart?.chart_data) {
                    var x = Object.keys(JSON.parse(chart?.chart_data)?.[0] || {})?.[0]
                    var y = Object.keys(JSON.parse(chart?.chart_data)?.[0] || {})?.[1]
                    const cards = selectedTab !== 'Value' ? Object.keys(chart?.chart_text_items)?.map((k, i) => {
                        let ob = {
                            key: i,
                            heading: <span>{k}<span className='' style={{ fontSize: "0.6rem" }}> as on {formatDateMMMDDYYYY(chart?.chart_text_items?.[k]?.as_on)}</span></span>,
                            value: displayValue({ text: chart?.chart_text_items?.[k]?.val, metricObj: metric })
                        }
                        return ob;
                    }) : Object.keys(chart?.chart_text_items)?.map((k, i) => {
                        return {
                            key: i,
                            heading: k,
                            value: displayValue({ text: chart?.chart_text_items?.[k], metricObj: metric })
                        }
                    })

                    return <>
                        <Card style={{ marginTop: '0' }} bodyStyle={{ padding: window.innerWidth > 760 ? '1.25rem 1.25rem 0' : '0', backgroundColor: window.innerWidth > 760 ? 'var(--white)' : 'var(--grey4)' }}>
                            <div style={{ marginBottom: '1rem' }}>
                                <CustomLabelValueCards x={cards} padding="0.75rem 1rem" minWidth="10rem" />
                            </div>
                            {yAxisType && chart?.tab === 'Multiples' && <Segmented value={scaleType} options={[{ label: "Linear", value: "linear", key: "linear" },
                            { label: "Logarithmic", value: "logarithmic", key: "logarithmic" }]}
                                onChange={(e) => { setScaleType(e) }} style={{ marginBottom: "1rem" }} size='small' />}
                            {
                                type === 'column' ?
                                    <ColumnChart chartName={chart.chart_name} chartData={chart.chart_data} xAxisKey={x} yAxisKey={y}
                                        showYAxis={true} showDataLabels={true} height={300} isQuaterly />
                                    : <PerformanceChart3 fundName={chart.chart_name} chartData={chart.chart_data} xAxisKey={x} chartType={type}
                                        showYAxis={true} showDataLabels={true} height={300} yAxisType={(yAxisType && chart?.tab === 'Multiples') ? scaleType : "linear"} />
                            }
                            <ChartNotes sec={selectedTab} tab={chart?.tab} />
                        </Card>
                    </>
                }
            }

            let options = [];
            let types = [];
            let tabs = [];

            Object.values(data || {})?.map((tab) => {
                tabs = [...tabs, tab.name];

                let temp = tab?.charts.map((ch, i) => {
                    types = [...types, ch?.chart_type]
                    return {
                        key: i + tab?.name,
                        tab: tab?.name,
                        label: ch?.chart_name,
                        value: ch?.chart_name,
                        ...ch
                    }
                })
                options = [...options, ...temp]
            })

            types = [...new Set(types)]?.map((ele) => ({
                key: ele,
                value: ele,
                label: ele === 'eq' ? "Equal weight" : ele === 'med' ? "Median values" : "Market cap"
            }))

            const [selectedType, setSelectedType] = React.useState(types?.find(ele => (selectedTab === 'Value' ? ele.key === 'med' : ele.key === 'mc'))?.value);
            const [selectedMetric, setSelectedMetric] = React.useState(options?.[0]?.value);
            const [selected, setSelected] = React.useState(tabs?.[0])
            const chart = options.find(ch => ch.value === selectedMetric && ch.chart_type === selectedType)
            const metric = screenerMetricDescData?.filter(ele => ele.desc_id === Number(chart?.desc))?.[0] || {}

            return (
                <Card bordered={false} bodyStyle={{
                    backgroundColor: 'var(--grey4)',
                    border: '1px solid var(--grey3)', borderRadius: '0.5rem',
                    minHeight: "50vh", display: "flex", flexDirection: "column", gap: "0rem", paddingBottom: window.innerWidth > 760 ? '1.25rem' : '0'
                }}>
                    <div style={{ display: "flex", marginBottom: '0.875rem', alignItems: "center", gap: "1rem" }}>
                        <div className="textLG w-700 dark3" style={{ flex: 1, cursor: 'pointer' }}>
                            {selectedMetric}
                        </div>
                        <Select
                            showSearch
                            placeholder="Select a metric"
                            optionFilterProp="children"
                            // defaultValue={options[0].value}
                            value={selectedMetric}
                            filterOption={(input, option) =>
                                (option?.label).toLowerCase().includes(input.toLowerCase())
                            }
                            onChange={(value) => setSelectedMetric(value)}
                            options={selected ? options.filter(o => o.tab === selected && o.chart_type === selectedType) :
                                options.filter((o) => o.chart_type === selectedType)}
                            style={{ width: window.innerWidth > 760 ? '20rem' : '100%' }}
                            dropdownRender={(menu) => {
                                const toggle = (val) => {
                                    ////console.log(val, selected, options)
                                    if (selected !== val) {
                                        setSelected(val);
                                        setSelectedMetric(options.find(m => m.tab === val)?.value)
                                    }
                                }
                                return <div >
                                    <div style={{ display: 'flex', gap: '0.75rem', padding: '0.75rem 1rem', backgroundColor: 'var(--white)', borderBottom: '1px solid var(--grey4)' }} className='sticky-top'>
                                        {
                                            tabs.map((t, key1) => {
                                                return <div onClick={() => toggle(t)} className="btnWhiteSM"
                                                    style={{ borderRadius: '0.5rem', backgroundColor: t === selected ? "var(--primary)" : "var(--grey3)", color: t === selected ? "var(--white)" : "var(--dark3)" }} key={generateUniqueKey(key1)}>{t}</div>
                                            })
                                        }
                                    </div>
                                    <div>
                                        {menu}
                                    </div>
                                </div >
                            }}
                        />
                        <Select placeholder='Select chart type' value={selectedType} options={types} onChange={(e) => setSelectedType(e)} />
                    </div>
                    <FundamentalChart chart={chart} type={type} yAxisType={yAxisType} />
                </Card>
            )
        }

        return <React.Fragment>
            {CustomSideTabs({
                tabData: Object.entries(fundamentals || {})?.map((ele) => {
                    return {
                        label: ele[0],
                        key: ele[0],
                        children: <FundamentalChildren data={ele[1]} type={lineCharts.includes(ele[0]) ? "line" : barCharts.includes(ele[0]) ? "column" : "column"}
                            selectedTab={ele[0]} yAxisType={logCharts?.includes(ele[0])} />,
                        show: true,
                        noHeader: true
                    }
                })
            })}
        </React.Fragment>
    }

    function HeatMapComp() {
        return <Card bordered={false} bodyStyle={{
            backgroundColor: 'var(--grey4)',
            border: '1px solid var(--grey3)', borderRadius: '0.5rem',
            minHeight: "50vh", display: "flex", flexDirection: "column", gap: "0rem", paddingBottom: window.innerWidth > 760 ? '1.25rem' : '0'
        }}>
            <IndexOrSectorsTreeChart filters={[
                ["sector_code", def_stock_sectors],
                def_stock_industries.length > 0 && ["industry_code", def_stock_industries],
                def_stock_basic_industries.length > 0 && ["nse_basic_ind_code", def_stock_basic_industries]
            ]?.filter((i) => i !== false)} type={type} header={<CardHeader heading='Heatmap' headingType={2} />}

                showKey={selectedYear === "1D" ? 'changePer' : tabOptions3?.filter((op) => op?.value === selectedYear)[0]?.queryStr}
            />
        </Card>
    }

    function TechnicalChart({ chart, chartDataType }) {

        const leftRef = React.useRef();
        const rightRef = React.useRef();

        if (chart && (chart?.chart_data)?.length === 0) {
            return <NoDataFound message={chart?.no_data_text} />
        }
        else if (chart && chart?.chart_data) {

            let data = JSON.parse(chart?.chart_data)
            let xAxisKey = 'as_on_date';
            let yAxisKey = Object.keys(data?.[0] || {})?.filter(ele => ele !== xAxisKey)?.[0]
            yAxisKey = yAxisKey?.split('_');
            yAxisKey.pop();
            yAxisKey = yAxisKey?.join('_');

            const options = {
                chart: {
                    type: 'column',
                    panning: true,
                    height: 400,
                    events: {
                        load: function () {
                            const chart = this
                            let { dataMax } = chart.xAxis[0].getExtremes()
                            chart.xAxis[0].setExtremes(dataMax - 13, dataMax);
                            const moveLeft = () => {
                                let { min, max, dataMin } = chart.xAxis[0].getExtremes()
                                if (min - 14 >= dataMin) {
                                    min -= 14
                                    max -= 14
                                }
                                else if (min - 14 < dataMin && min > dataMin) {
                                    min = 0;
                                    max = 13;
                                }
                                chart.xAxis[0].setExtremes(min, max)
                            }
                            const moveRight = () => {
                                let { min, max, dataMax } = chart.xAxis[0].getExtremes()
                                if (max + 14 <= dataMax) {
                                    min += 14
                                    max += 14
                                }
                                else if ((max + 14 > dataMax) && (max < dataMax)) {
                                    min = dataMax - 13
                                    max = dataMax
                                }
                                chart.xAxis[0].setExtremes(min, max)
                            }
                            leftRef.current = moveLeft;
                            rightRef.current = moveRight;
                        }
                    }
                },
                title: {
                    text: ""
                },
                exporting: {
                    enabled: false
                },
                xAxis: {
                    type: 'categories',
                    categories: data.map(item => item[xAxisKey]),
                    lineColor: 'var(--grey1)',
                    tickColor: 'var(--grey1)',
                    endOnTick: true,
                    labels: {
                        formatter: function (obj) {

                            if (this.value === 'Latest' || this.value === 'TTM') {
                                return this.value
                            }
                            else if (obj.isLast === true) {
                                return (moment(new Date(this.value)).format('DD MMM YY'))
                            }
                            else {
                                return (moment(new Date(this.value)).format('MMM YY'))
                            }
                        },
                        step: 1
                    },
                    crosshair: true,
                    max: 13
                },
                yAxis: {
                    visible: true,
                    tickAmount: 5,
                    title: {
                        text: ''
                    },
                    gridLineColor: 'transparent',
                    scrollbar: {
                        enabled: true,
                        showFull: false
                    },
                    max: chartDataType === 'per' ? 100 : undefined
                },
                tooltip: {
                    formatter: function () {
                        let d = new Date(this.x).toLocaleString('en-IN', {
                            month: 'short',
                            year: 'numeric',
                        }) || this.x
                        if (chartDataType === 'per') {
                            return d + '<br/><span>' + 'Percentage : <b>' + DecimalValueFormat({
                                num: this.points[1].y, decimals: 2, suffix: "%"
                            }) + "</b>" + '</span>'
                        }
                        else return (
                            d + '<br/>Total : <b>' + this.points[0].y + '</b><br/>Number : <b>' + this.points[1].y + '</b>'
                        )
                    },
                    shared: true,
                    useHTML: true
                },
                series: [
                    {
                        name: 'Total',
                        data: data.map(item => {
                            if (chartDataType === 'per') return 100;
                            else return item[`${yAxisKey}_total`]
                        }),
                        color: 'var(--grey1)', // Grey color for the background column
                        dataLabels: {
                            enabled: chartDataType !== 'per',
                            className: "sectrmomenchart",
                            style: { fontWeight: '400', textOutline: "none", color: "var(--dark4)" },
                            formatter: function () {
                                return this.y
                            }
                        },
                    },
                    {
                        name: chartDataType === 'per' ? 'Percentage' : "Number",
                        data: data.map(item => item[`${yAxisKey}_${chartDataType}`]),
                        color: 'var(--green)', // Green color for the percentage column
                        dataLabels: {
                            enabled: true,
                            className: "sectrmomenchart",
                            style: { fontWeight: '400', textOutline: "none", color: "var(--dark4)" },
                            formatter: function () {
                                if (chartDataType === 'per') {
                                    return DecimalValueFormat({ num: this.y, decimals: 1, suffix: "%" })
                                }
                                else return this.y
                            }
                        },
                        // pointWidth: 30
                    },
                ],
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                boost: {
                    useGPUTranslations: true,
                },
                plotOptions: {
                    series: {
                        grouping: false,
                        lineWidth: 1,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                    }
                }
            };

            return (
                <Card bodyStyle={{ padding: window.innerWidth > 760 ? '1.25rem 1.25rem 0' : '0' }}>
                    <div className='d-flex align-items-center'>
                        <BiChevronLeft onClick={() => {
                            if (leftRef.current) {
                                leftRef.current();
                            }
                        }} style={{ fontSize: "3rem", color: "var(--dark1)", cursor: "pointer" }} />
                        <div style={{ flex: 1 }}>
                            <HighchartsReact highcharts={Highcharts} options={options} />
                        </div>
                        <BiChevronRight onClick={() => {
                            if (rightRef.current) {
                                rightRef.current();
                            }
                        }} style={{ fontSize: "3rem", color: "var(--dark1)", cursor: "pointer" }} />
                    </div>
                </Card>
            )
        }
    }

    function Technicals() {

        function TechnicalChildren({ data }) {

            let options = [];
            let types = [{ key: "per", value: "per", label: "Percentage" }, { key: "num", value: "num", label: "Number" }];

            data?.charts?.map((ch) => {
                let el = {
                    key: ch.chart_name,
                    label: ch.chart_name,
                    value: ch.chart_name,
                    ...ch
                }
                options = [...options, el]
            })
            const [selectedChart, setSelectedChart] = React.useState(options?.[0]?.value);
            const [selectedType, setSelectedType] = React.useState(types?.[0]?.value);
            const chart = options.find(ch => ch.value === selectedChart)

            return (
                <Card bordered={false} bodyStyle={{
                    backgroundColor: 'var(--grey4)',
                    border: '1px solid var(--grey3)', borderRadius: '0.5rem',
                    minHeight: "50vh", display: "flex", flexDirection: "column", gap: "0rem", paddingBottom: window.innerWidth > 760 ? '1.25rem' : '0'
                }}>
                    <div style={{ display: "flex", marginBottom: '0.875rem', alignItems: "center", gap: "1rem" }}>
                        <div className="textLG w-700 dark3" style={{ flex: 1, cursor: 'pointer' }}>
                            {selectedChart}
                        </div>
                        <Select placeholder='Select chart type' value={selectedChart} options={options} onChange={(e) => setSelectedChart(e)}
                            style={{ width: window.innerWidth > 760 ? '20rem' : '100%' }} />
                        <Select placeholder='Select chart type' value={selectedType} options={types} onChange={(e) => setSelectedType(e)} />
                    </div>
                    <TechnicalChart chart={chart} chartDataType={selectedType} />
                </Card>
            )
        }
        const tabData = [
            {
                key: "Absolute momentum",
                label: "Absolute momentum",
                show: true,
                children: <TechnicalChildren data={technicals['Absolute momentum']} />,
                noHeader: true
            },
            {
                key: "Relative momentum",
                label: "Relative momentum",
                show: true,
                children: <TechnicalChildren data={technicals['Relative momentum']} />,
                noHeader: true
            },
            {
                key: "Heatmap",
                label: "Heatmap",
                show: true,
                children: <HeatMapComp />,
                noHeader: true
            }
        ]

        return (
            <React.Fragment>
                {CustomSideTabs({
                    tabData: tabData
                })}
            </React.Fragment>
        )
    }

    if (isWidget) {
        if (loading) {
            return <React.Fragment>
                <div className='loaderContainer' >
                    <ActivityLoaderMintbox showQuote={true} />
                </div>
            </React.Fragment>
        } else {
            return (
                <div>
                    <AppliedFilterView />
                    <Card style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                            {(selectedTabOption === "Technicals" || selectedTabOption === 'Fundamentals') && <div style={{ flex: 1 }}>
                                <CustomSegmentedTab
                                    flexVal={"unset"}
                                    isBlock={false}
                                    className='black textSM'
                                    options={tabOptions}
                                    onChange={(value) => {
                                        setselectedTabOption(value)
                                    }}
                                    value={selectedTabOption}
                                />
                            </div>}
                        </div>
                        {tabOptions.find(ele => ele.value === selectedTabOption)?.children}
                    </Card>
                </div>
            );
        }
    }
    if (loading) {
        return <React.Fragment>
            <div className='loaderContainer' >
                <ActivityLoaderMintbox showQuote={true} />
            </div>
        </React.Fragment>
    } else {
        return (
            <React.Fragment>
                <Head title="Stock Sector & Industry Performance| NSE, Analysis" description="Explore stock sectors and industries on sharpely. Find NSE sector-wise stock lists with performance and in-depth analysis for all sectors in the stock market." />
                <AccessControlledPage moduleKey={'SECTOR_DASHBOARD'} />
                <div className='normalContainer'>
                    <AppliedFilterView />
                    <Card>
                        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                            {(selectedTabOption === "Technicals" || selectedTabOption === 'Fundamentals') && <div style={{ flex: 1 }}>
                                <CustomSegmentedTab
                                    flexVal={"unset"}
                                    isBlock={false}
                                    className='black textSM'
                                    options={tabOptions}
                                    onChange={(value) => {
                                        setselectedTabOption(value)
                                    }}
                                    value={selectedTabOption}
                                />
                            </div>}
                        </div>
                        {tabOptions.find(ele => ele.value === selectedTabOption)?.children}
                    </Card>

                </div>
            </React.Fragment >
        );
    }

}

export default StockSectorsDetail