import { checkmark } from "Assets/images";
import AMCIcon from "Components/AMCIcon";
import ListLoader from "Components/ListLoader";
import MintboxIcon from "Components/MintboxIcon";
import { NoDataFound } from "Components/NoDataFound";
import { Checkbox } from "Components/atoms/Checkbox";
import { LoadMore } from "Components/atoms/LoadMore";
import { fetchFundDataWithoutFilter } from 'Libs/fetchFundData';
import searchAndSort from "Libs/search";
import { getIndexList } from "Libs/utils";
import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { IoSearch } from "react-icons/io5";
import InfiniteScroll from 'react-infinite-scroller';
import { useLocation } from "react-router";
import { v4 } from 'uuid';

export const InstrumentListSearch = React.memo(({
    allInstrumentsList = [],
    placeholder = '', type = 1, isMultiple = false, selectedValues = null, onChange = () => { }, openOnFocus = true, align = 'left',
    ctaText = 'Compare', header = <></>, maxSelected = Infinity, isFullWidthInMob = false
}) => {

    const [searchText, setSearchText] = useState('');
    const [mainData, setMainData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [showList, setShowList] = useState(false);
    const [uniqueId] = useState('su' + v4());
    const location = useLocation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const onSearch = (e) => {
        setShowList(true)
        if (e?.target?.value?.length > 0) {
            if (type === 3) {
                let newData = searchAndSort(e.target.value, [...mainData], ['MintboxName'])
                setSearchData(newData);
                fetchData(newData)
            } else if (type === 1) {
                let newData = searchAndSort(e.target.value, [...mainData], ['nse_symbol', 'basic_name']);
                setSearchData(newData);
                fetchData(newData)
            } else if (type === 11) {
                let newData = searchAndSort(e.target.value, [...mainData], ['benchmark_name'])
                setSearchData(newData);
                fetchData(newData)
            } else if (type === 4) {
                let newData = searchAndSort(e.target.value, [...mainData], ['symbol', 'proper_name'])
                setSearchData(newData);
                fetchData(newData)
            } else if (type === 5) {
                let newData = searchAndSort(e.target.value, [...mainData], ['IndexName', 'IndexNameCaps'])
                setSearchData(newData);
                fetchData(newData)
            } else {
                let newData = searchAndSort(e.target.value, [...mainData], ['isin_code', 'basic_name'])
                setSearchData(newData);
                fetchData(newData)
            }
        } else {
            setSearchData(mainData);
            fetchData(mainData)
        }
        setSearchText(e.target.value);
    }

    const loadData = async () => {
        if (type === 5) {
            let indices = await getIndexList();
            if (indices) {
                setMainData(indices);
                setSearchData(indices);
                fetchData(indices);
            }
        }
        else {
            let res = await fetchFundDataWithoutFilter(type, false);
            if (res.length) {
                if (type === 4) {
                    res = res.sort((a, b) => b?.mcap - a?.mcap);
                }
                else if (type === 1 || type === 2) {
                    res = res.sort((a, b) => b?.aum - a?.aum)
                }
                setMainData(res);
                setSearchData(res);
                fetchData(res);
            }
        }
        setLoading(false);
    }

    const fetchData = (arr) => {
        let temp = ([...arr])?.slice(0, 20);
        setData(temp);
    }

    const loadMoreData = () => {
        if (searchText !== '') {
            let temp = [...searchData];
            let temp2 = temp.slice(data.length, data.length + 20);
            setData([...data, ...temp2]);
        }
        else {
            let temp = [...mainData];
            let temp2 = temp.slice(data.length, data.length + 20);
            setData([...data, ...temp2]);
        }
    }

    const onSelect = (item = {}) => {
        if (!isMultiple) {
            // console.log('abcd')
            setSelectedData([item]);
            onChange([item]);
            setShowList(false);
            return;
        }
        if (selectedData?.length >= maxSelected) {
            toast.error(`You can select a maximum of ${maxSelected} ${type === 1 ? "ETFs" : type === 2 ? "MFs" : "stocks"}`);
        }
        else {
            let temp = [...selectedData];
            if (type === 1 || type === 2) {
                temp = temp.filter((ele) => ele.plan_id === item.plan_id);
            }
            else if (type === 3) {
                temp = temp.filter((ele) => ele.UID === item.UID);
            }
            else if (type === 4) {
                temp = temp.filter((ele) => ele.symbol === item.symbol);
            }

            else if (type === 5) {
                temp = temp.filter((ele) => ele.IndexNameCaps === item.IndexNameCaps);
            }
            else {
                temp = temp.filter((ele) => ele.benchmark_id === item.benchmark_id);
            }
            if (!temp?.length) {
                setSelectedData([...selectedData, item]);
            }
            else {
                setSelectedData(selectedData?.filter(ele => (
                    Object.keys(ele).join(' ') + Object.values(ele).join(' ') !== Object.keys(item).join(' ') + Object.values(item).join(' ')
                )))
            }
        }
    }

    const handleDocumentClick = (e) => {
        let target = document.getElementById(`${uniqueId}`);
        let currTarget = e.target
        let classNames = ['insListItemSelected', 'insListItem', 'insListSearchInput', 'insListItemSelected insListItem', 'insListContent']
        do {
            if (target === currTarget || classNames?.includes(currTarget.className) || currTarget.className === 'insListItem') {
                return;
            }
            else if (currTarget === target?.querySelector('.insListSearchInput')) {
                setShowList(true);
                return;
            }
            else if (currTarget === target?.querySelector('.insListItem')) {
                setShowList(true);
                return;
            }
            currTarget = currTarget.parentNode
        }
        while (currTarget) {
            setShowList(false);
        }
    }

    const onCompare = () => {
        if (selectedData.length > 0) onChange(selectedData);
        setShowList(false)
    }

    function isChildVisible(container, child) {
        const containerRect = container.getBoundingClientRect();
        const childRect = child.getBoundingClientRect();
        return (
            childRect.top >= containerRect.top &&
            childRect.bottom <= containerRect.bottom
        );
    }

    const handleArrowUpDown = (e) => {
        const items = Array.from(document.querySelectorAll(`#${uniqueId} .insListContent .insListItem`));
        const parent = document.querySelector(`#${uniqueId} .insListContent`)
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            let check = items.find((ele) => ele.getAttribute('data-focused') !== null);
            if (check) {
                let ind = items.findIndex((ele) => ele.getAttribute('data-focused') !== null);
                items[ind].removeAttribute('data-focused');
                if (ind === items.length - 1) {
                    items[0].setAttribute('data-focused', 'active');
                    ind = 0;
                }
                else {
                    items[ind + 1].setAttribute('data-focused', 'active');
                    ind = ind + 1;
                }
                if (!isChildVisible(parent, items[ind])) {
                    parent.scrollTo({ top: items[ind].offsetTop - parent.offsetTop - 110, behavior: "smooth" });
                }
            }
            else {
                items[0].setAttribute('data-focused', 'active');
            }
        }
        else if (e.key === 'ArrowUp') {
            e.preventDefault();
            let check = items.find((ele) => ele.getAttribute('data-focused') !== null);
            if (check) {
                let ind = items.findIndex((ele) => ele.getAttribute('data-focused') !== null);
                items[ind].removeAttribute('data-focused');
                if (ind === 0) {
                    items[0].setAttribute('data-focused', 'active');
                    ind = 0;
                }
                else {
                    items[ind - 1].setAttribute('data-focused', 'active')
                    ind = ind - 1;
                }
                if (!isChildVisible(parent, items[ind])) {
                    parent.scrollTo({ top: items[ind].offsetTop - parent.offsetTop, behavior: "smooth" });
                }
            }
            else {
                items[0].setAttribute('data-focused', 'active');
            }
        }
        else if (e.code === 'Enter') {
            let check = items.find((ele) => ele.getAttribute('data-focused') !== null);
            if (check) {
                check.click();
            }
        }
    }

    useEffect(() => {
        // console.log('showlist changing', showList)
        setSearchText('');
        if (!showList && mainData.length) {
            setSearchData(mainData);
            fetchData(mainData)
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        loadData();
    }, [location.pathname]);

    useEffect(() => {
        setSelectedData(selectedValues || []);
    }, [selectedValues])

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        document.getElementById(uniqueId)?.addEventListener('keydown', handleArrowUpDown);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
            document.getElementById(uniqueId)?.removeEventListener('keydown', handleArrowUpDown);
        }
    }, []);

    return (
        <React.Fragment>
            <div className={`insListSearch ${isFullWidthInMob ? 'fullWidthInMob' : ""}`} id={uniqueId}>
                <Input prefix={<IoSearch color='var(--dark2)' className="site-form-item-icon me-2" />}
                    style={{ height: window.innerWidth < 760 ? "3rem" : "2.5rem", border: "1px solid var(--grey2)", borderRadius: "var(--borderRadius)", background: "var(--grey3)", fontSize: '0.85rem' }}
                    className='insListSearchInput dark2 textXS'
                    placeholder={placeholder} value={searchText} onInput={onSearch}
                    onFocus={() => { setShowList(true) }} />
                {showList && (openOnFocus ? true : searchText?.length > 0) && <div className={`insListContainer ${align}`} >
                    {header}
                    <div className='insListContent'>
                        {searchData?.length > 0 && !loading && <InfiniteScroll hasMore={searchData?.length > data?.length} loadMore={() => loadMoreData()} useWindow={false} loader={<LoadMore key={0} />}
                            threshold={30}>
                            {searchData?.length > 0 && data?.map((item, i) => {
                                let isSelected = type === 1 || type === 2 ? selectedData?.filter(ele => ele.plan_id === item.plan_id)?.length > 0 :
                                    type === 3 ? selectedData?.filter(ele => ele.UID === item.UID)?.length > 0 : type === 4 ?
                                        selectedData?.filter(ele => ele.symbol === item.symbol)?.length > 0 : type === 11 ?
                                            selectedData?.filter(ele => ele.benchmark_id === item.benchmark_id)?.length > 0 : type === 5 ?
                                                selectedData?.filter(ele => ele.IndexNameCaps === item.IndexNameCaps)?.length > 0 : false;
                                let isCustomIndex = type === 5 ? String(item?.IndexName)?.startsWith('SHI:') : false
                                return (
                                    <div key={i} onClick={() => onSelect(item)}
                                        className={isSelected ? "insListItemSelected insListItem" : "insListItem"}>
                                        <div>
                                            {isMultiple && <Checkbox onChange={() => onSelect(item)}
                                                checked={isSelected}
                                            />}
                                            <span style={{ cursor: "pointer", marginLeft: "0.5rem" }}>
                                                {type === 1 || type === 2 ? <AMCIcon height='2rem' width='2rem' amcName={item.amc_full_name} type='circle' />
                                                    : type === 3 ? <MintboxIcon height='1.25rem' width='1.25rem'
                                                        id={item.MintboxId || Number(item.FundCode) || item.UID || item.mintbox_id || item.inv_MintboxId} />
                                                        : type === 4 ? <AMCIcon amcName={item.symbol} fundType={type} height='2rem'
                                                            width='2rem' type='circle' bseTicker={item?.bse_ticker} /> :
                                                            type === 5 ? <AMCIcon amcName={item.symbol} fundType={type} height='2rem'
                                                                width='2rem' type='circle' bseTicker={item?.bse_ticker} showTricolor={!isCustomIndex}
                                                                showSharpelyLogo={isCustomIndex} /> : <></>
                                                }
                                            </span>
                                            {(type === 3 || type === 11) && <div className='textSM dark4' style={{
                                                flex: 1, whiteSpace: 'initial', cursor: "pointer",
                                                height: "fit-content"
                                            }}>
                                                {type === 3 ? item.MintboxName : type === 4 ? item.proper_name : type === 11 ? item.benchmark_name :
                                                    item.basic_name?.replace(" - Direct Plan", "")}
                                            </div>}
                                            {(type === 1 || type === 2 || type === 4 || type === 5) && <div>
                                                <div className='textXS black w-500'
                                                    style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                    {type === 1 || type === 2 ? item.basic_name?.replace(" - Direct Plan", "") : type === 5 ? item?.IndexName : item.proper_name}
                                                </div>
                                                <div className='itemNameBtmContainer'>
                                                    <div className='textXS dark3 w-500'>
                                                        {type === 1 ? item?.nse_symbol : type === 2 ? item?.isin_code : type === 5 ? item?.IndexNameCaps : item?.symbol}
                                                    </div>
                                                    <div className='dark3 textXS w-500'>
                                                        {type === 1 ? "ETFs" : type === 2 ? "Mutual Funds" : type === 5 ? "Index" : "Stocks"}
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                        {!isMultiple && <div className='checkmarkCont'>
                                            {isSelected ? <img loading="lazy" alt="" src={checkmark} /> : ""}
                                        </div>}
                                    </div>
                                )
                            })}
                        </InfiniteScroll>
                        }
                    </div>
                    {loading ? <ListLoader /> : searchData?.length <= 0 ?
                        <div className='d-flex align-items-center justify-content-center mt-3' style={{ padding: "0.5rem" }}>
                            <NoDataFound className="textSM w-500 black" imageStyle={{ height: "5rem" }} /></div> : null
                    }
                    {searchData.length > 0 && isMultiple && <div className='insListFooter'>
                        <button className="btnBlack " onClick={onCompare}>
                            {ctaText} ({selectedData.length}{maxSelected !== Infinity && `/${maxSelected}`})
                        </button>
                    </div>}
                </div>}
            </div>
        </React.Fragment >
    )
})