import moengage from "@moengage/web-sdk";
import { decrypt } from "Libs/SHA256Hasher";
import db from "Libs/db";
import { updateStockPrices, updatesubscribedStocks } from "Libs/redux/StockLivePrice/reduxSlice";
import { updatePrevStockPrices } from 'Libs/redux/StocksPrevPrices/reduxSlice';
import { updateUserAccessLevel, updateUserData, updateWatchlists } from "Libs/redux/UserAuth/reduxSlice";
import { Dashboard_Portfolio } from "Services/api/FabricatedAPIS";
import { notification } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

export const UserDataHandler = () => {

    const preventNotifRoutes = ['/onboarding']
    const user = useSelector((state) => state?.updateUserData?.value)
    const userAccessLevel = useSelector((state) => state?.userAccessLevel?.value);
    const location = useLocation();
    const dispatch = useDispatch();
    // const planExpiredRef = React.useRef();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // const noRedirectPath = ['/', '/about-us', '/pricing', '/privacy-policy', '/terms-and-conditions', '/refund-and-cancellation-policy', '/contact-us']

    const resetData = () => {
        dispatch(updatePrevStockPrices([]));
        dispatch(updateStockPrices([]));
        dispatch(updatesubscribedStocks({}));
        dispatch(updateWatchlists(undefined));
        dispatch(updateUserData(undefined));
    }

    const handleUserAutoLogout = () => {
        //console.log('checking user login session')
        if (user?.isLogged) {
            let date = new Date(user?.login_Date)
            let currentDate = new Date();
            let check = (currentDate.getTime() - date.getTime()) >= 86400000
            if (check || (String(date) === 'Invalid Date')) {
                //console.log('login session expired');
                moengage.destroy_session();
                moengage.track_event('Logout');
                resetData();
                db.clear();
            }
        }
    }

    const handleAutoLogoutEvent = (e) => {
        if (document.hidden === false) {
            handleUserAutoLogout();
        }
    }

    // const handleUserFreeTrial = async () => {
    //     if (user?.isLogged) {
    //         let ftStartDate = user?.Client?.[0]?.SharpelyFTStartDate;
    //         let ftEndDate = user?.Client?.[0]?.SharpelyFTEndDate;
    //         let isSharpelyFreeTrial = user?.Client?.[0]?.IsSharpelyFreeTrial === 1;
    //         if (ftStartDate && ftEndDate && isSharpelyFreeTrial && (new Date(ftEndDate).getTime() <= new Date().getTime())) {
    //             moengage.track_event('7_DAY_TRIAL_ENDED', {
    //                 'isPlanChanged': false,
    //                 "subCancelled": false
    //             })
    //         }
    //     }
    // }

    const updateUserAccessOnUserUpdate = () => {
        if (user?.isLogged) {
            let obj = {
                access: user?.Client?.[0]?.SharpelyAccessLevel,
                id: user?.id
            }
            if (userAccessLevel?.access === undefined) {
                dispatch(updateUserAccessLevel(obj));
            }
            else if (userAccessLevel?.access > user?.Client?.[0]?.SharpelyAccessLevel && userAccessLevel?.id === user?.id) {
                if (!preventNotifRoutes.includes(location.pathname)) {
                    notification.open({
                        message: <div className="textMD w-500">Plan expired!</div>,
                        key: "planExpiredNotification",
                        description: <div>
                            <div className="textSM">Don't miss out on our premium tools and features – renew your plan today!</div>
                            <button className="btnPrimary mt-2" onClick={() => {
                                notification.destroy('planExpiredNotification')
                                navigate('/pricing');
                            }}>Renew now</button>
                        </div>,
                        placement: "bottomRight",
                        duration: 0
                    })
                }
                dispatch(updateUserAccessLevel(obj));
            }
            else {
                dispatch(updateUserAccessLevel(obj));
            }
        }
    }

    const handleUserAutoLogin = async () => {
        let autoLogin = searchParams.get('auto_login_user') === 'true';
        if (autoLogin) {
            let userId = searchParams.get('loginUserId');
            userId = Number(decrypt(userId));
            if (!isNaN(userId) && typeof userId === 'number') {
                let userData = await Dashboard_Portfolio.getClientData({ id: userId });
                if (userData) {
                    userData = {
                        ...userData,
                        name: userData?.Client?.[0].DisplayName,
                        email: userData?.Client?.[0].Email,
                        id: userData?.Client?.[0].ClientId,
                        mobile: userData?.Client?.[0].MobileNumber,
                        isLogged: true,
                        login_Date: new Date(),
                    }
                    dispatch(updateUserData(userData))
                }
            }
            searchParams.delete('auto_login_user');
            searchParams.delete('loginUserId')
            setSearchParams(searchParams)
        }
    }

    // const checkUserPlanExpired = async () => {
    //     if (user?.isLogged) {
    //         if (planExpiredRef.current) {
    //             clearInterval(planExpiredRef.current)
    //         }
    //         planExpiredRef.current = setInterval(async () => {
    //             let userData = { name: user?.name, email: user?.email, mobile: user?.mobile, isLogged: true, id: user?.id, login_Date: user?.login_Date }
    //             let ltUserData = await Dashboard_Portfolio.getClientData(userData, true);
    //             // if(user?.Client?.[0]?.SharpelyAccessLevel>ltUserData?.Client?.[0]?.SharpelyAccessLevel){
    //             //console.log(12);
    //             //console.log(userAccessLevel);
    //             // }
    //         }, 2000)
    //     }
    // }

    React.useEffect(() => {
        handleUserAutoLogin()
    }, [location]);

    React.useEffect(() => {
        document.removeEventListener('visibilitychange', handleAutoLogoutEvent)
        document.addEventListener('visibilitychange', handleAutoLogoutEvent)
        return () => {
            document.removeEventListener('visibilitychange', handleAutoLogoutEvent)
        }
    }, [user])

    React.useEffect(() => {
        handleUserAutoLogout();
    }, [location])

    React.useEffect(() => {
        // handleUserFreeTrial();
    }, [])

    React.useEffect(() => {
        updateUserAccessOnUserUpdate();
    }, [user])

    return null;
}
