import { PlanName, SubscribeContentWrapper, useSubscribeMiddleware } from "Components/SubscribeModal";
import React from "react";
import { Modal } from 'antd';
import { useNavigate } from "react-router";

export const CloneItemModal = ({ type = 'screen', moduleKey = '', userCloneCount = 0, onConfirm = () => { }, onCancel = () => { } }) => {

    const subscribeMiddle = useSubscribeMiddleware();
    const [openModal, setOpenModal] = React.useState(false);
    const navigate = useNavigate();
    const cloneDetailedAccess = subscribeMiddle({ moduleKey: moduleKey, checkLimit: true, getDetailedAccessData: true, usageCount: userCloneCount });

    const itemName = type === 'model' ? "factor model" : type === 'screen' ? "screener" : type;

    return (
        <React.Fragment>
            <SubscribeContentWrapper onClick={() => {
                setOpenModal(true);
            }} moduleKey={moduleKey}>
                <button className="btnBlack" style={{ cursor: "pointer" }}>
                    Clone this {type}
                </button>
            </SubscribeContentWrapper>
            <Modal open={openModal} centered closable={true} title={`Clone ${type}`} footer={null} onCancel={() => {
                setOpenModal(false);
            }}>
                <div>
                    <div className="textSM w-500 dark4 mb-2">
                        You have {cloneDetailedAccess?.remainingLimit === 0 ? `exhausted all ${cloneDetailedAccess?.maxLimit} out of ${cloneDetailedAccess?.maxLimit} clone credits` :
                            `${cloneDetailedAccess?.remainingLimit} out of ${cloneDetailedAccess?.maxLimit} ${itemName} clone credits remaining`}.
                    </div>
                    {cloneDetailedAccess?.remainingLimit === 0 ? <div className="textESM w-400 dark4">
                        You do not have any {itemName} clone credits remaining. Upgrade to <PlanName accessLevel={cloneDetailedAccess?.currentAccessLevel + 1} /> to get more {itemName} clone credits.
                    </div> : <>
                        <div className="textESM w-400 dark4 mb-2">
                            Once you confirm, you can view and edit {type === 'model' ? "model." : type === 'screen' ? "the screening filters." : "strategy parameters and paper trade this strategy."}
                            &nbsp;Note that upon clicking on confirm, your clone credits will be deducted by 1 irrespective of whether you decide to {type === 'model' ? "save the model or not." :
                                type === 'screen' ? "save the screen or not." : 'paper trade the strategy or not.'}
                        </div>
                        <div className="textESM w-400 dark4">
                            Once you clone, you can come back at any time and {type === 'model' ? "edit and save this model or save multiple versions of this model." :
                                type === 'screen' ? "edit and save this screen or save multiple versions of this screen." :
                                    "paper trade this strategy."}
                        </div>
                    </>}
                    <div className="d-flex align-items-center justify-content-end" style={{ gap: "1.25rem", marginTop: "1.25rem" }}>
                        <button className="btnWhite" onClick={() => { setOpenModal(false) }}>Cancel</button>
                        {cloneDetailedAccess?.remainingLimit === 0 ? <button className="btnPrimary" onClick={() => {
                            navigate('/pricing');
                        }}>
                            Upgrade
                        </button> : <button className="btnBlack" onClick={() => {
                            setOpenModal(false);
                            onConfirm();
                        }}>Clone</button>}
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}