import { defaultPagination } from 'Libs/utils';
import { Table } from 'antd';
import React, { useRef } from 'react';
import { v4 } from 'uuid';

function withScrollToTop(WrappedComponent) {
    return function WithScrollToTop({ scrollToLeft, scrollLeftDependency = [], ...props }) {
        const tableRef = useRef(null);
        const uniqueId = v4();
        const handlePageChange = (page, pageSize) => {
            // window.scrollTo({ top: (window.pageYOffset + document.getElementById(uniqueId).getClientRects().top), left: 0 });
            // //console.log(document.getElementById(uniqueId).getClientRects())

            // tableRef.current.scrollIntoView({behavior:"smooth"})

            const tableTop = tableRef.current.getBoundingClientRect().top + window.pageYOffset - 80;
            window.scrollTo({ top: tableTop, behavior: "smooth" });
            if (props.onChange) {
                props.onChange(page, pageSize);
            }
        };
        // console.log("heyeye", props?.dataSource)
        let updDataSource = props?.dataSource?.filter((el) => {
            let c1 = el?.hasOwnProperty('symbol') && !el?.hasOwnProperty('plan_id');
            // console.log(c1)
            if (c1) {
                return el?.proper_name
            }
            return true;
        })
        // console.log("heyeye", updDataSource)
        React.useEffect(() => {
            if (tableRef?.current && scrollToLeft) {
                // console.log(tableRef?.current)
                let x = tableRef.current?.getElementsByClassName("ant-table-content")?.[0]
                // console.log(x)
                if (x) {
                    x.scrollLeft += 200000
                }
            }
        }, scrollLeftDependency)
        return (
            <div ref={tableRef} id={uniqueId}>
                <WrappedComponent {...props} dataSource={updDataSource} pagination={props?.defaultPagination ? { ...defaultPagination, defaultPageSize: props?.paginationSize || 10, onChange: handlePageChange } :
                    ((props?.noPagination || Object.keys(props?.pagination || {})?.length <= 0) ? false : { ...props.pagination, onChange: handlePageChange })} />
            </div>
        );
    };
}

const MyTable = withScrollToTop(Table);

export default MyTable;
