import { random1, random2, random3, random4, random5, random6, random7, random8, random9, random10, random11, random12, random13, random14, random15, random16, random17, random18, random19, random20, random21, random22, random23, random24, random25, random26, random27, random28, random29, random30, basket1, basket2, basket3, basket4, basket5, basket6, basket7, basket8 } from 'Assets/images'


export const BasketIcon = ({ id = 1, width = '1rem', height = '1rem', padding = '0.25rem', bg = 'var(--greenShade)',
    border = '1px solid var(--green)', borderRadius = '4px' }) => {
    let icons = [random1, random2, random3, random4, random5, random6, random7, random8, random9, random10, random11, random12, random13, random14, random15,
        random16, random17, random18, random19, random20, random21, random22, random23, random24, random25, random26, random27, random28, random29, random30]
    let updId = id < 1 || id > 30 ? 1 : id
    return <div style={{
        width: width, height: height, padding: padding, borderRadius: borderRadius, background: bg,
        border: border
    }}
        className='d-flex align-items-center justify-content-center'>
        <img loading="lazy"  alt="" src={icons[updId - 1]} style={{ width: "100%", height: "100%" }} />
    </div>
}