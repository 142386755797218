import { Avatar } from 'antd';
import { BiLineChartDown, BiSearchAlt } from 'react-icons/bi';
import { FaRegChartBar } from 'react-icons/fa';
import { GiPendulumSwing, GiWolfTrap } from 'react-icons/gi';
import { IoWarningOutline } from 'react-icons/io5';
import { MdOutlineShowChart, MdOutlineWaterfallChart } from 'react-icons/md';
import { TbChartCandle } from 'react-icons/tb';
import { TfiBackLeft } from 'react-icons/tfi';
const StyleBoxIcons = ({ style_box_code, size = "small", fundColors = ['#555770', '#83BF6E', '#007AFF', '#FFD966', '#FF55BB', '#0062CC', '#AA55FF', '#FF9C55', '#1A1D1F', '#FF6A55'] }) => {
    const icons = [
        <TbChartCandle color={fundColors[style_box_code]} />,
        <MdOutlineShowChart color={fundColors[style_box_code]} />,
        <BiSearchAlt color={fundColors[style_box_code]} />,
        <FaRegChartBar color={fundColors[style_box_code]} />,
        <TfiBackLeft color={fundColors[style_box_code]} />,
        <GiWolfTrap color={fundColors[style_box_code]} />,
        <GiPendulumSwing color={fundColors[style_box_code]} />,
        <MdOutlineWaterfallChart color={fundColors[style_box_code]} />,
        <BiLineChartDown color={fundColors[style_box_code]} />,
        <IoWarningOutline color={fundColors[style_box_code]} />
    ]
    return <Avatar
        icon={icons[style_box_code]}
        size={size}
        style={{
            objectFit: 'cover', verticalAlign: 'middle',
            alignItems: "center",
            backgroundColor: fundColors[style_box_code] + "44",
            color: fundColors[style_box_code]
        }}
    />
}

export default StyleBoxIcons