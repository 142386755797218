import { Card, Input, Select, Tooltip } from 'antd';
import { CardHeader } from 'Components/atoms/SmallComponents';
import CustomSegmentedTab from 'Components/CustomSegmentedTab';
import { DecimalValueFormat } from 'Components/DecimalValueFormat';
import LoadingTableSkeleton from 'Components/LoadingTableSkeleton';
import { NoDataFound } from 'Components/NoDataFound';
import { useSubscribeMiddleware } from 'Components/SubscribeModal';
import MyTable from 'Components/TableWithScroll';
import Highcharts from 'highcharts';
import HighchartsHeatmap from "highcharts/modules/heatmap";
import Head from 'Layout/Head';
import { exportAntDTable } from 'Libs/exportTable';
import { defaultPagination, displayScoreValue, useGetAssetType } from 'Libs/utils';
import React, { useState } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { IoIosArrowDown } from 'react-icons/io';
import { IoSearch } from 'react-icons/io5';
import { TiDelete } from 'react-icons/ti';
import { useNavigate } from 'react-router';
import apiHelperPY from 'Services/api/ApiHelperPY';
HighchartsHeatmap(Highcharts)

const StockSectors = ({ paginationSize = 50, isWidget = false, widgetItem = {} }) => {

    const isMobile = window.innerWidth <= 575;
    const navigate = useNavigate()
    const [loading, setloading] = useState(true)
    const type = useGetAssetType()
    const [searchText, setSearchText] = useState("");
    const subscribeMiddle = useSubscribeMiddleware();

    const tabOptions = [
        {
            "value": "sector",
            "queryStr": "sector",
            "label": <h2 className='margin0 font-inherit lineHeightUnset w-500'>Sectors</h2>
        },
        {
            "value": "indgrp",
            "queryStr": "indgrp",
            "label": <h2 className='margin0 font-inherit lineHeightUnset w-500'>Industry group</h2>
        },
        {
            "value": "ind",
            "queryStr": "ind",
            "label": <h2 className='margin0 font-inherit lineHeightUnset w-500'>Industry</h2>
        },
    ]

    const weightMethodOptions = [{
        "value": 'MC',
        "label": "Weight stocks by market cap",
        "key": 6,
    }, {
        "value": 'EQ',
        "label": "Equal weight",
        "key": 2,
    }, {
        "value": "Tech",
        "label": "Technicals",
        "key": 3
    }]

    const hidden_col = ["med_pe", "med_pb", "med_div_yield", "num_stocks", "sector_code", "industry_code", "basic_industry_code"]

    const [selectedTabOption, setselectedTabOption] = useState(tabOptions[0].value)
    const [selectedWeightMethod, setselectedWeightMethod] = useState(weightMethodOptions[0].value)
    const [clasificationData, setclasificationData] = useState({})

    const listDataRender = (item, index) => {
        var specialCaseScores = ["QVM Score", "Value Score", "Momentum Score", "Quality Score"]
        if (specialCaseScores.includes(item)) {
            return {
                render: (text, record) => {
                    return <span>
                        {displayScoreValue({ text, metricObj: item })}
                    </span>
                }
            }
        }
    }

    const loadData = async () => {
        setloading(true)
        let sectorsData = await apiHelperPY({ apiName: 'getAllSectorData', saveResponse: 'sessionStorage' });
        sectorsData = JSON.parse(sectorsData.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null));
        setclasificationData(sectorsData);
        setloading(false)
    }

    React.useEffect(() => {
        loadData()
    }, [])

    const getColumns = React.useCallback(() => {
        let columns = Object.entries(clasificationData?.[selectedTabOption]?.[selectedWeightMethod]?.[0] || {})?.map(([key, val], index) => {
            return {
                "dataIndex": key,
                "title": key === "sector_desc" ? "Sector name" : key === "industry_desc" ? "Industry group name" : key === "basic_industry_desc" ? "Industry name" : key,
                'width': (key === "sector_desc" || key === "industry_desc" || key === "basic_industry_desc") ? isMobile ? 150 : 250 : isMobile ? 100 : 150,
                'fixed': (key === "sector_desc" || key === "industry_desc" || key === "basic_industry_desc") ? 'left' : "false",
                "align": typeof (val) === "object" || typeof (val) === "number" ? "right" : "left",
                "render": (text, record) => key === "sector_desc" ? <div onClick={() => {
                    var data = {
                        "sector": record.sector_code,
                        "indgrp": "",
                        "ind": ""
                    }
                    navigate(type.basePath + "/sector-details", { state: { selectionData: data, selectedWeightMethod } })
                }} style={{ color: "var(--primary)", cursor: "pointer", }}>
                    {text}
                </div> : key === "industry_desc" ? <div onClick={() => {
                    var data = {
                        "sector": String(record.industry_code).slice(0, 6),
                        "indgrp": record.industry_code,
                        "ind": ""
                    }
                    navigate(type.basePath + "/sector-details", { state: { selectionData: data, selectedWeightMethod } })
                }} style={{ color: "var(--primary)", cursor: "pointer", }}>
                    {text}
                </div> : key === "basic_industry_desc" ? <div onClick={() => {
                    var data = {
                        "sector": String(record.basic_industry_code).slice(0, 6),
                        "indgrp": String(record.basic_industry_code).slice(0, 8),
                        "ind": record.basic_industry_code
                    }
                    navigate(type.basePath + "/sector-details", { state: { selectionData: data, selectedWeightMethod } })
                }} style={{ color: "var(--primary)", cursor: "pointer", }}>
                    {text}
                </div> : selectedWeightMethod === 'Tech' ? <span>{DecimalValueFormat({ num: text, suffix: "%", placeholder: "-", decimals: 2 })}</span> :
                    typeof (text) === "object" || typeof (text) === "number" ?
                        DecimalValueFormat({ num: text, suffix: "", placeholder: "-", decimals: 2 }) : text,
                ...listDataRender(key)
            }
        })
        columns = columns?.filter((col) => !hidden_col.includes(col.dataIndex));
        return columns;
    }, [selectedTabOption, selectedWeightMethod, clasificationData])

    const columns = getColumns();

    const exportTable = (fileFormat = 'xlsx') => {
        subscribeMiddle({
            moduleKey: 'EXPORT_SECTOR_DATA',
            onNext: () => {
                let dataSource = searchText !== "" ? clasificationData[selectedTabOption][selectedWeightMethod]?.sort((a, b) => a?.[selectedTabOption === 'sector' ? 'sector_desc' : selectedTabOption === 'indgrp' ? 'industry_desc' : 'basic_industry_desc']?.localeCompare(b?.[selectedTabOption === 'sector' ? 'sector_desc' : selectedTabOption === 'indgrp' ? 'industry_desc' : 'basic_industry_desc']))?.filter((item) => {
                    return item[selectedTabOption === 'sector' ? 'sector_desc' : selectedTabOption === 'indgrp' ? 'industry_desc' : 'basic_industry_desc'].toLowerCase().includes(searchText.toLowerCase());
                }) : clasificationData[selectedTabOption][selectedWeightMethod]?.sort((a, b) => a?.[selectedTabOption === 'sector' ? 'sector_desc' : selectedTabOption === 'indgrp' ? 'industry_desc' : 'basic_industry_desc']?.localeCompare(b?.[selectedTabOption === 'sector' ? 'sector_desc' : selectedTabOption === 'indgrp' ? 'industry_desc' : 'basic_industry_desc']))

                exportAntDTable({
                    fileFormat: fileFormat, fileDefaultName: "Sector analysis data", columns: columns,
                    dataSource
                })
            }
        })
    }

    if (loading) {
        return <React.Fragment>
            <Card bordered={false} style={{ flex: 1 }}>
                <div style={{ display: "flex", gap: "0.5rem", justifyContent: 'space-between', marginBottom: '1rem' }} className='mobileFlexCol'>
                    <div style={{ flex: 1 }}>
                        <CardHeader heading={isWidget ? widgetItem.Name : "Sectors"} headingType={isWidget ? 2 : 1} />
                        {isWidget && <div className="customTabsSubHeading" style={{ marginBottom: 0 }}>{widgetItem?.Description}</div>}
                    </div>
                    <Select removeIcon={<TiDelete size={12} />}
                        className='textSM black'
                        suffixIcon={<IoIosArrowDown size={15} />}
                        style={{
                            width: window.innerWidth > 760 ? "250px" : "220px"
                        }}
                        defaultValue={selectedWeightMethod}
                        placeholder="Please select weighing method for stocks"
                        onChange={(value) => {
                            setselectedWeightMethod(value)
                        }}
                        options={weightMethodOptions}
                    />
                </div>
                <CustomSegmentedTab
                    flexVal={'unset'}
                    isBlock={false}
                    className='textXS black margin20Bottom'
                    options={tabOptions}
                    onChange={(value) => {
                        setselectedTabOption(value)
                    }}
                    value={selectedTabOption}
                />

                <LoadingTableSkeleton />
            </Card>

        </React.Fragment>
    } else {
        return (
            <React.Fragment>
                {!isWidget && <Head title='Stock Share Market Sectors | NSE, Analysis, Performance' description="Access the list of stocks by sector and industry on sharpely. Discover sector-wise stock lists for NSE with performance and analysis for all sectors in the stock market." />}
                <Card bordered={false} style={{ flex: isWidget ? "" : 1 }}>
                    <div style={{ display: "flex", gap: "0.5rem", justifyContent: 'space-between', marginBottom: '1rem', alignItems: isWidget ? "flex-end" : "flex-start" }}
                        className='mobileFlexCol'>
                        <div style={{ flex: 1 }}>
                            <CardHeader heading={isWidget ? widgetItem?.Name : 'Sectors'} />
                            {isWidget && <div className="customTabsSubHeading" style={{ marginBottom: 0 }}>{widgetItem?.Description}</div>}
                        </div>
                        <Select removeIcon={<TiDelete size={12} />}
                            className='textSM black'
                            suffixIcon={<IoIosArrowDown size={15} />}
                            style={{
                                width: window.innerWidth > 760 ? "250px" : "100%"
                            }}
                            defaultValue={selectedWeightMethod}
                            placeholder="Please select weighing method for stocks"
                            onChange={(value) => {
                                setselectedWeightMethod(value)
                            }}
                            options={weightMethodOptions}
                        />
                    </div>
                    <div style={{ display: "flex", alignItems: "center", flexWrap: 'wrap', gap: '1rem' }}>
                        <div style={{ flex: 1 }}>
                            <CustomSegmentedTab
                                flexVal={'unset'}
                                isBlock={false}
                                className='textXS black'
                                options={tabOptions}
                                onChange={(value) => {
                                    setselectedTabOption(value)
                                }}
                                value={selectedTabOption}
                            />
                        </div>
                        <div className="d-flex justify-content-between align-items-center" style={{ gap: "1.25rem", width: !isMobile ? "fit-content" : "100%" }}>
                            <Input prefix={<IoSearch color='var(--dark2)' className="site-form-item-icon me-2" />}
                                style={{
                                    fontSize: '0.85rem', width: isMobile ? "100%" : "16rem", background: "var(--grey3)",
                                    border: "1px solid var(--grey2)", height: isMobile ? '3rem' : '100%'
                                }}
                                placeholder={`Search by ${selectedTabOption === 'sector' ? 'sector' : selectedTabOption === 'indgrp' ? 'industry group' : 'industry'} name`} allowClear onChange={(value) => {
                                    setSearchText(value.target.value)
                                }} className='customInput2' />
                            <Tooltip title='Export sector data' arrow={false}>
                                <button className={"btnWhite"} style={{ height: isMobile ? '3rem' : '100%', width: isMobile ? '3.5rem' : '' }} onClick={() => {
                                    exportTable('csv')
                                }}>
                                    <AiOutlineDownload size={15} />
                                </button>
                            </Tooltip>
                        </div>
                    </div>

                    <div style={{ marginTop: '1rem' }}>
                        <MyTable locale={{ emptyText: <NoDataFound /> }} pagination={{ ...defaultPagination, defaultPageSize: isWidget ? 10 : paginationSize }}
                            showSorterTooltip={false} defaultSortOrder='descend'
                            dataSource={searchText !== "" ? clasificationData[selectedTabOption][selectedWeightMethod]?.sort((a, b) => a?.[selectedTabOption === 'sector' ? 'sector_desc' : selectedTabOption === 'indgrp' ? 'industry_desc' : 'basic_industry_desc']?.localeCompare(b?.[selectedTabOption === 'sector' ? 'sector_desc' : selectedTabOption === 'indgrp' ? 'industry_desc' : 'basic_industry_desc']))?.filter((item) => {
                                return item[selectedTabOption === 'sector' ? 'sector_desc' : selectedTabOption === 'indgrp' ? 'industry_desc' : 'basic_industry_desc'].toLowerCase().includes(searchText.toLowerCase());
                            }) : clasificationData[selectedTabOption][selectedWeightMethod]?.sort((a, b) => a?.[selectedTabOption === 'sector' ? 'sector_desc' : selectedTabOption === 'indgrp' ? 'industry_desc' : 'basic_industry_desc']?.localeCompare(b?.[selectedTabOption === 'sector' ? 'sector_desc' : selectedTabOption === 'indgrp' ? 'industry_desc' : 'basic_industry_desc']))}
                            columns={columns}
                            rowKey={(record) => record[selectedTabOption === 'sector' ? 'sector_desc' : selectedTabOption === 'indgrp' ? 'industry_desc' : 'basic_industry_desc']}
                            scroll={{ x: "max-content" }}
                        />
                    </div>
                </Card>
            </React.Fragment >
        );
    }
}

export default StockSectors