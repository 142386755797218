import { CurrencyFormat } from "Components/CurrencyFormat";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import RedGreenText from "Components/RedGreenText";
import { isMarketOpen } from "Components/atoms/IsMarketOpen";
import { useIsMobile } from "CustomHooks/useIsMobile";
import { arePropsEqual } from "Libs/utils";
import React, { useRef } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useSelector } from "react-redux";

export const PriceDisplay = React.memo(({ format = 1, showTime = true, priceObj }) => {

    const ref = useRef(null)
    const isMobile = useIsMobile();

    if (priceObj !== null && priceObj !== undefined && Object.keys(priceObj)?.length > 0) {
        let currentLTP = priceObj?.currentLTP, changeVal = priceObj?.changeVal, changePer = priceObj?.changePer, dateTime = priceObj?.dateTime;
        if (format === 1) {
            return <span ref={ref} className="textXS w-500 black" style={{ display: "flex", gap: "0.2rem", alignItems: "center", flex: 1, minWidth: "9vw", textAlign: "right" }}>
                <span className="dark3" style={{ flex: 1, textAlign: "right" }}><span className="dark3 me-1">{DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })}</span>
                    <span className="dark3" style={{ alignItems: "center" }}>(<RedGreenText text={DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })} value={changeVal} />{changeVal > 0 ? <IoIosArrowUp color="var(--green)" /> : <IoIosArrowDown color="var(--red)" />})</span></span>
                <span style={{ textAlign: "right", minWidth: "3.6vw" }}>{CurrencyFormat(currentLTP, 'long', 2, '', false)}</span>
            </span>
        } else if (format === 2) {
            return <span ref={ref} className="textXS w-500 black" style={{ display: "flex", gap: "0.2rem", alignItems: "center", flex: 1, minWidth: "11.5vw", textAlign: "right" }}>
                <span >{CurrencyFormat(currentLTP, 'long', 2, '', false)}</span>
                <span className="dark3" style={{ display: "flex", alignItems: "center" }} >(<RedGreenText text={DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })} value={changeVal} />{changeVal > 0 ? <IoIosArrowUp color="var(--green)" /> : <IoIosArrowDown color="var(--red)" />})</span>
            </span>
        } else if (format === 3) {
            return <span ref={ref} className="textXS w-500 black" style={{ display: "flex", flexDirection: "column", gap: "0.1rem", alignItems: "flex-end", flex: 1, textAlign: "right" }}>
                <span style={{ textAlign: "right" }}>{CurrencyFormat(currentLTP, 'long', 2, '', false)}</span>
                <span className="dark3" style={{ flex: 1, textAlign: "right" }}>
                    <RedGreenText text={`${DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })?.replace("NaN", "0.0")} (${DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })?.replace("NaN", "0.00")})`} value={changeVal} />
                </span>
            </span>
        } else if (format === 4) {
            return <span ref={ref} className="textXS w-500 black" style={{ display: "flex", gap: "0.2rem", alignItems: "center", flex: 1, minWidth: "11.5vw", textAlign: "right" }}>
                <span className="dark3" style={{ flex: 1, textAlign: "right" }}>{DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })}</span>
                <span style={{ flex: 1, textAlign: "right" }}><RedGreenText text={DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })} value={changeVal} />{changeVal > 0 ? <IoIosArrowUp color="var(--green)" /> : <IoIosArrowDown color="var(--red)" />}</span>
                <span style={{ flex: 1, textAlign: "right" }}>{CurrencyFormat(currentLTP, 'long', 2, '', false)}</span>
            </span>
        } else if (format === 5) {
            //chart special
            return <span>
                <span ref={ref} className="textMD w-500 black flex-sm-row flex-column align-items-start align-items-sm-center"
                    style={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
                    <span className="textMD w-500 black" style={{ display: "flex", gap: "0.5rem", alignItems: "center", flexWrap: "wrap" }}>
                        <span className="w-700 dark3" style={{ whiteSpace: "nowrap" }}>{CurrencyFormat(currentLTP, 'long', 2, '', false)}</span>
                        <RedGreenText isBg={true}
                            text={<span className="textSM w-500" style={{ whiteSpace: "nowrap" }}>
                                {DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })?.replace("NaN", "0.0")} ({DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })?.replace("NaN", "0.00")})
                            </span>} value={changeVal} />
                    </span>
                    {showTime && <span className="w-500 textXS dark3" style={{ whiteSpace: "nowrap" }}>{dateTime}</span>}
                </span >
            </span>
        }
        else if (format === 6) {
            // for global instrument search
            return <span ref={ref} className="textXS w-500 black" style={{ display: "flex", flexDirection: "column", gap: "0.1rem", alignItems: "flex-end", flex: 1, minWidth: "auto", textAlign: "right" }}>
                <span style={{ textAlign: "right", minWidth: "5rem" }}>{CurrencyFormat(currentLTP, 'long', 2, '', false)}</span>
                <span className="dark3" style={{ flex: 1, textAlign: "right" }}>
                    <RedGreenText text={`${DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })?.replace("NaN", "0.0")} (${DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })?.replace("NaN", "0.00")})`} value={changeVal} />
                </span>
            </span>
        }
        else if (format === 7) {
            // forlive price marquee
            return <span ref={ref} className="textXS w-500 black" style={{ display: "flex", gap: "0.5rem", flex: 1, minWidth: "auto", }}>
                <span style={{}}>{CurrencyFormat(currentLTP, 'long', 2, '', false)}</span>
                <span className="dark3" style={{ flex: 1, }}>
                    <RedGreenText text={`${DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })?.replace("NaN", "0.0")} (${DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })?.replace("NaN", "0.00")})`} value={changeVal} />
                </span>
            </span>
        }
        else if (format === 8) {
            // new stock detail page
            return <div>
                <span ref={ref} className="textXS w-500 black" style={{
                    display: "flex", gap: "0.5rem", flex: 1, minWidth: "auto",
                    justifyContent: isMobile ? "start" : "flex-end"
                }}>
                    <span style={{}} className='textXL w-700 black'>{CurrencyFormat(currentLTP, 'long', 2, '', false)}</span>
                    <span className="dark3 mt-2 textSM w-500" style={{}}>
                        <RedGreenText text={`${DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })?.replace("NaN", "0.0")} (${DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })?.replace("NaN", "0.00")})`} value={changeVal} />
                    </span>
                </span>
                <div className="w-500 textXS dark3" style={{ whiteSpace: "nowrap", textAlign: isMobile ? 'left' : "right" }}>{dateTime}</div>
            </div>
        } else if (format === 9) {
            // investmemnts
            return <div>
                <span ref={ref} className="textXS w-500 black" style={{
                    display: "flex", gap: "0.5rem", flex: 1, minWidth: "auto",
                }}>
                    <span style={{}} className='textXL w-700 black'>{CurrencyFormat(currentLTP, 'long', 2, '', false)}</span>
                    <span className="dark3 mt-2 textSM w-500" style={{}}>
                        <RedGreenText text={`${DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })?.replace("NaN", "0.0")} (${DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })?.replace("NaN", "0.00")})`} value={changeVal} />
                    </span>
                </span>
                <div className="w-500 textXS dark3" style={{ whiteSpace: "nowrap" }}>{dateTime}</div>
            </div>
        }
        else if (format === 10) {
            //console.log("sad", symbol, changeVal)
            return <span ref={ref} className="textXS w-500 black" style={{ display: "flex", gap: "0.2rem", alignItems: "center", flex: 1, minWidth: "11.5vw", textAlign: "right", whiteSpace: 'nowrap' }}>
                <span className="dark2">
                    <RedGreenText text={CurrencyFormat(currentLTP, 'long', 2, '')} value={changeVal} />
                </span>
                <span className="dark3" style={{ display: "flex", alignItems: "center" }} ><RedGreenText arrow text={`(${DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })})`} value={changeVal} /></span>
            </span>
        } else if (format === 11) {
            return <span ref={ref} className="textXS w-500 black" style={{ display: "flex", flexDirection: "column", gap: "0.1rem", alignItems: "flex-end", flex: 1, textAlign: "right" }}>
                <span style={{ textAlign: "right" }}>{CurrencyFormat(currentLTP, 'long', 2, "", false)}</span>
                <span className="dark3" style={{ flex: 1, textAlign: "right" }}>
                    <RedGreenText text={`${DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })?.replace("NaN", "0.0")} (${DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })?.replace("NaN", "0.00")})`} value={changeVal} />
                </span>
            </span>
        }
        else if (format === 'MarketPrice') {
            return <span ref={ref} className="textXS w-500 black">
                {CurrencyFormat(currentLTP, 'long', 2, '')}
            </span>
        }

        else if (format === 'DayChange') {
            return <span ref={ref} className="textXS w-500 dark3" style={{ flex: 1, textAlign: "right" }}>
                <RedGreenText text={`${DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })?.replace("NaN", "0.0")} (${DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })?.replace("NaN", "0.00")})`} value={changeVal} />
            </span>
        }
    }
    else {
        return <span className="w-500">
            {"-"}
        </span>
    }
}, arePropsEqual)

const StockLivePriceFun = ({ symbol, fundData, format = 1, showTime = true }) => {
    // format
    // 1 => change changeper ltp
    // 2 => LTP (changeper)
    // 3 => LTP change(changeper)
    // 4 => LTP 
    //      change(changeper)
    // 5 => LTP

    var stockprices = useSelector((state) => state?.stockprices?.value)
    var prevstockprices = useSelector((state) => state?.prevStockPrices?.value)
    var changeVal, changePer, currentLTP, dateTime = "-"
    var currentSymbolAllData = stockprices === "close" ? prevstockprices?.[fundData?.gdf_symbol || fundData?.symbol] : stockprices?.[fundData?.gdf_symbol || fundData?.symbol] || prevstockprices?.[fundData?.gdf_symbol || fundData?.symbol]

    if (currentSymbolAllData === undefined && fundData?.isin_code !== undefined) {
        // console.log(Object?.values(prevstockprices)?.filter((i) => String(i?.InstrumentToken)?.includes(fundData?.isin_code)))
        currentSymbolAllData = stockprices === "close" ? Object?.values(prevstockprices)?.filter((i) => String(i?.InstrumentToken)?.includes(fundData?.isin_code)) : Object?.values(stockprices)?.filter((i) => String(i?.InstrumentToken)?.includes(fundData?.isin_code)) || Object?.values(prevstockprices)?.filter((i) => String(i?.InstrumentToken)?.includes(fundData?.isin_code))

        currentSymbolAllData = currentSymbolAllData?.[0] || undefined

    }
    // if (fundData?.gdf_symbol === "ALKEM")

    currentLTP = currentSymbolAllData?.LastTradePrice
    changeVal = currentSymbolAllData?.PriceChange
    changePer = currentSymbolAllData?.PriceChangePercentage

    let currentTime = new Date(currentSymbolAllData?.LastTradeTime * 1000)
    if (currentTime?.getHours() > 15 || (currentTime?.getHours() === 15 && currentTime?.getMinutes() > 30) || !isMarketOpen()) {
        currentTime?.setHours(15, 30, 0, 0); // Set time to 3:30 PM
    }
    if (currentTime?.getDate() !== new Date().getDate() || currentTime?.getMonth() !== new Date().getMonth() || currentTime?.getFullYear() !== new Date().getFullYear()) {
        currentTime?.setHours(15, 30, 0, 0); // Set time to 3:30 PM
    }
    let factSetTime = new Date(fundData?.price_date)
    if (factSetTime?.getHours() > 15 || (factSetTime?.getHours() === 15 && factSetTime?.getMinutes() > 30) || !isMarketOpen()) {
        factSetTime?.setHours(15, 30, 0, 0); // Set time to 3:30 PM
    }
    if (factSetTime?.getDate() !== new Date().getDate() || factSetTime?.getMonth() !== new Date().getMonth() || factSetTime?.getFullYear() !== new Date().getFullYear()) {
        factSetTime?.setHours(15, 30, 0, 0); // Set time to 3:30 PM
    }
    factSetTime = factSetTime?.getTime();
    dateTime = currentTime?.toLocaleString('en-IN', {
        day: "2-digit", month: "short", hour: "numeric",
        minute: "2-digit", timeZone: 'Asia/Kolkata',
    })

    // if (fundData?.gdf_symbol === "ALKEM")
    //     //console.log("ASDASD", fundData?.price_date, currentTime, factSetTime)


    // console.log("ASDASD", currentSymbolAllData)
    if (currentSymbolAllData === undefined) {
        // //console.log('fund data is undefined for', symbol)
        let ele = fundData
        currentLTP = ele?.price || 0
        changePer = ele?.one_day_pct || 0
        let originalPrice = currentLTP / (1 + (changePer / 100))
        changeVal = currentLTP - originalPrice;
        // dateTime = formatDateMMMDDYYYY(fundData?.price_date)
        dateTime = new Date(factSetTime)?.toLocaleString('en-IN', {
            day: "2-digit", month: "short", hour: "numeric",
            minute: "2-digit", timeZone: 'Asia/Kolkata',
        })
    }
    if (currentSymbolAllData !== undefined && !(isNaN(factSetTime)) && factSetTime > currentSymbolAllData?.LastTradeTime * 1000 && (fundData?.price !== undefined || fundData?.price !== null)) {
        let ele = fundData
        currentLTP = ele?.price || 0
        changePer = ele?.one_day_pct || 0
        let originalPrice = currentLTP / (1 + (changePer / 100))
        changeVal = currentLTP - originalPrice;
        dateTime = new Date(factSetTime)?.toLocaleString('en-IN', {
            day: "2-digit", month: "short", hour: "numeric",
            minute: "2-digit", timeZone: 'Asia/Kolkata',
        })
        // dateTime = formatDateMMMDDYYYY(fundData?.price_date)

    }
    if (String(dateTime) === ' NaN, NaN') {
        dateTime = ''
    }
    // //console.log(format)
    const ref = useRef(null)
    const isMobile = window.innerWidth <= 760;
    if (stockprices !== "close" ? Object.keys(stockprices).length > 0 : Object.keys(prevstockprices).length > 0) {

        if (format === 1) {
            return <span ref={ref} id={'stock-' + symbol} className="textXS w-500 black" style={{ display: "flex", gap: "0.2rem", alignItems: "center", flex: 1, minWidth: "9vw", textAlign: "right" }}>
                <span className="dark3" style={{ flex: 1, textAlign: "right" }}><span className="dark3 me-1">{DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })}</span>
                    <span className="dark3" style={{ alignItems: "center" }}>(<RedGreenText text={DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })} value={changeVal} />{changeVal > 0 ? <IoIosArrowUp color="var(--green)" /> : <IoIosArrowDown color="var(--red)" />})</span></span>
                <span style={{ textAlign: "right", minWidth: "3.6vw" }}>{CurrencyFormat(currentLTP, 'long', 2, '', false)}</span>
            </span>
        } else if (format === 2) {
            return <span ref={ref} id={'stock-' + symbol} className="textXS w-500 black" style={{ display: "flex", gap: "0.2rem", alignItems: "center", flex: 1, minWidth: "11.5vw", textAlign: "right" }}>
                <span >{CurrencyFormat(currentLTP, 'long', 2, '', false)}</span>
                <span className="dark3" style={{ display: "flex", alignItems: "center" }} >(<RedGreenText text={DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })} value={changeVal} />{changeVal > 0 ? <IoIosArrowUp color="var(--green)" /> : <IoIosArrowDown color="var(--red)" />})</span>
            </span>
        } else if (format === 3) {
            return <span ref={ref} id={'stock-' + symbol} className="textXS w-500 black" style={{ display: "flex", flexDirection: "column", gap: "0.1rem", alignItems: "flex-end", flex: 1, textAlign: "right" }}>
                <span style={{ textAlign: "right" }}>{CurrencyFormat(currentLTP, 'long', 2, '', false)}</span>
                <span className="dark3" style={{ flex: 1, textAlign: "right" }}>
                    <RedGreenText text={`${DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })?.replace("NaN", "0.0")} (${DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })?.replace("NaN", "0.00")})`} value={changeVal} />
                </span>
            </span>
        } else if (format === 4) {
            return <span ref={ref} id={'stock-' + symbol} className="textXS w-500 black" style={{ display: "flex", gap: "0.2rem", alignItems: "center", flex: 1, minWidth: "11.5vw", textAlign: "right" }}>
                <span className="dark3" style={{ flex: 1, textAlign: "right" }}>{DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })}</span>
                <span style={{ flex: 1, textAlign: "right" }}><RedGreenText text={DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })} value={changeVal} />{changeVal > 0 ? <IoIosArrowUp color="var(--green)" /> : <IoIosArrowDown color="var(--red)" />}</span>
                <span style={{ flex: 1, textAlign: "right" }}>{CurrencyFormat(currentLTP, 'long', 2, '', false)}</span>
            </span>
        } else if (format === 5) {
            //chart special
            return <span>
                <span ref={ref} id={'stock-' + symbol} className="textMD w-500 black flex-sm-row flex-column align-items-start align-items-sm-center"
                    style={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
                    <span className="textMD w-500 black" style={{ display: "flex", gap: "0.5rem", alignItems: "center", flexWrap: "wrap" }}>
                        <span className="w-700 dark3" style={{ whiteSpace: "nowrap" }}>{CurrencyFormat(currentLTP, 'long', 2, '', false)}</span>
                        <RedGreenText isBg={true}
                            text={<span className="textSM w-500" style={{ whiteSpace: "nowrap" }}>
                                {DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })?.replace("NaN", "0.0")} ({DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })?.replace("NaN", "0.00")})
                            </span>} value={changeVal} />
                    </span>
                    {showTime && <span className="w-500 textXS dark3" style={{ whiteSpace: "nowrap" }}>{dateTime}</span>}
                </span >
            </span>
        }
        else if (format === 6) {
            // for global instrument search
            return <span ref={ref} id={'stock-' + symbol} className="textXS w-500 black" style={{ display: "flex", flexDirection: "column", gap: "0.1rem", alignItems: "flex-end", flex: 1, minWidth: "auto", textAlign: "right" }}>
                <span style={{ textAlign: "right", minWidth: "5rem" }}>{CurrencyFormat(currentLTP, 'long', 2, '', false)}</span>
                <span className="dark3" style={{ flex: 1, textAlign: "right" }}>
                    <RedGreenText text={`${DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })?.replace("NaN", "0.0")} (${DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })?.replace("NaN", "0.00")})`} value={changeVal} />
                </span>
            </span>
        }
        else if (format === 7) {
            // forlive price marquee
            return <span ref={ref} id={'stock-' + symbol} className="textXS w-500 black" style={{ display: "flex", gap: "0.5rem", flex: 1, minWidth: "auto", }}>
                <span style={{}}>{CurrencyFormat(currentLTP, 'long', 2, '', false)}</span>
                <span className="dark3" style={{ flex: 1, }}>
                    <RedGreenText text={`${DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })?.replace("NaN", "0.0")} (${DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })?.replace("NaN", "0.00")})`} value={changeVal} />
                </span>
            </span>
        }
        else if (format === 8) {
            // new stock detail page
            return <div>
                <span ref={ref} id={'stock-' + symbol} className="textXS w-500 black" style={{
                    display: "flex", gap: "0.5rem", flex: 1, minWidth: "auto",
                    justifyContent: isMobile ? "start" : "flex-end"
                }}>
                    <span style={{}} className='textXL w-700 black'>{CurrencyFormat(currentLTP, 'long', 2, '', false)}</span>
                    <span className="dark3 mt-2 textSM w-500" style={{}}>
                        <RedGreenText text={`${DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })?.replace("NaN", "0.0")} (${DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })?.replace("NaN", "0.00")})`} value={changeVal} />
                    </span>
                </span>
                <div className="w-500 textXS dark3" style={{ whiteSpace: "nowrap", textAlign: isMobile ? 'left' : "right" }}>{dateTime}</div>
            </div>
        } else if (format === 9) {
            // investmemnts
            return <div>
                <span ref={ref} id={'stock-' + symbol} className="textXS w-500 black" style={{
                    display: "flex", gap: "0.5rem", flex: 1, minWidth: "auto",
                }}>
                    <span style={{}} className='textXL w-700 black'>{CurrencyFormat(currentLTP, 'long', 2, '', false)}</span>
                    <span className="dark3 mt-2 textSM w-500" style={{}}>
                        <RedGreenText text={`${DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })?.replace("NaN", "0.0")} (${DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })?.replace("NaN", "0.00")})`} value={changeVal} />
                    </span>
                </span>
                <div className="w-500 textXS dark3" style={{ whiteSpace: "nowrap" }}>{dateTime}</div>
            </div>
        }
        else if (format === 10) {
            //console.log("sad", symbol, changeVal)
            return <span ref={ref} id={'stock-' + symbol} className="textXS w-500 black" style={{ display: "flex", gap: "0.2rem", alignItems: "center", flex: 1, minWidth: "11.5vw", textAlign: "right", whiteSpace: 'nowrap' }}>
                <span className="dark2">
                    <RedGreenText text={CurrencyFormat(currentLTP, 'long', 2, '')} value={changeVal} />
                </span>
                <span className="dark3" style={{ display: "flex", alignItems: "center" }} ><RedGreenText arrow text={`(${DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })})`} value={changeVal} /></span>
            </span>
        } else if (format === 11) {
            return <span ref={ref} id={'stock-' + symbol} className="textXS w-500 black" style={{ display: "flex", flexDirection: "column", gap: "0.1rem", alignItems: "flex-end", flex: 1, textAlign: "right" }}>
                <span style={{ textAlign: "right" }}>{CurrencyFormat(currentLTP, 'long', 2, "", false)}</span>
                <span className="dark3" style={{ flex: 1, textAlign: "right" }}>
                    <RedGreenText text={`${DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })?.replace("NaN", "0.0")} (${DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })?.replace("NaN", "0.00")})`} value={changeVal} />
                </span>
            </span>
        }
        else if (format === 'MarketPrice') {
            return <span ref={ref} id={'stock-' + symbol} className="textXS w-500 black">
                {CurrencyFormat(currentLTP, 'long', 2, '')}
            </span>
        }

        else if (format === 'DayChange') {
            return <span ref={ref} id={'stock-' + symbol} className="textXS w-500 dark3" style={{ flex: 1, textAlign: "right" }}>
                <RedGreenText text={`${DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })?.replace("NaN", "0.0")} (${DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })?.replace("NaN", "0.00")})`} value={changeVal} />
            </span>
        }

    } else {
        return <span className="w-500">
            {"-"}
        </span>
    }

}

export const StockLivePrice = React.memo(StockLivePriceFun);