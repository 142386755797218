import AMCIcon from "Components/AMCIcon";
import AccessComponent from 'Components/AccessComponent';
import AccessControlledPage from "Components/AccessControlledPage";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox.js";
import Analysis from 'Components/Analysis';
import { HeatMap, ReturnsHistogram, RollingVol, SharpeChart, TableConstructor, UnderWater, WorstDrawdowns } from 'Components/BasketDetailComponents/index';
import BuySellSwitch from "Components/BuySellSwitch";
import { CurrencyFormat } from "Components/CurrencyFormat.js";
import { InstrumentListSearch } from "Components/CustomComponents/InstrumentListSearch";
import { StickyScrollTabs } from "Components/CustomComponents/StickyScrollTabs";
import CustomDrawer from 'Components/CustomDrawer';
import CustomLabelValueCards from 'Components/CustomLabelValue';
import CustomSegmentedTab from "Components/CustomSegmentedTab";
import CustomTabs, { CustomTabsMainChild } from "Components/CustomTabs";
import { dayDiff, formatDate, formatDateWithTime, formatDateyyyymmdd, monthDiff } from "Components/DateFormatter";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import Disclaimer from 'Components/Disclaimer';
import FundDetCards from 'Components/FundDetCards';
import { FundInsightsModal, StockInsightsModal } from 'Components/Insights';
import InstrumentListTable from "Components/InstrumentListTable";
import { NoDataFound } from 'Components/NoDataFound';
import RedGreenText from 'Components/RedGreenText';
import ScreenSignalBacktestChart from 'Components/ScreenSignalBacktestChart';
import { ScreenBacktestInpModal } from 'Components/Screener/ScreenBacktestInpModal';
import { codeParserWrapper, conditionOperators, finalCode } from 'Components/Screener/ScreenerUtils';
import { ScreenerIcon } from "Components/ScreenerIcon";
import SharpelyGateway2 from "Components/SharpelyGateway/SharpelyGateway2";
import { PlanName, SubscribeContentWrapper, getUserAccessLimitFromAccessObj, useSubscribeMiddleware } from "Components/SubscribeModal";
import TableProgressBarFormat from "Components/TableProgessBarFormat";
import MyTable from 'Components/TableWithScroll';
import UpgradeToPro from 'Components/UpgradeToPro';
import { CloneItemModal } from "Components/atoms/CloneItemModal";
import { DescriptionInput } from "Components/atoms/DescriptionInput";
import { isMarketOpen } from "Components/atoms/IsMarketOpen";
import LabelInputNew from 'Components/atoms/LabelInputNew';
import { NameInput } from "Components/atoms/NameInput";
import { CardHeader } from "Components/atoms/SmallComponents";
import { useUserDataHooks } from "CustomHooks/useUserDataHooks";
import Head from "Layout/Head";
import { exportAntDTable, exportExcel } from "Libs/exportTable";
import { fetchFundData } from "Libs/fetchFundData";
import searchAndSort, { isSubsequence } from "Libs/search";
import { displayValue, generateUniqueKey, getIndexList, groupBy, isInvalidTextOrLength, isSharpelyMember, quantile, screenerMetricData, sortFunds, useGetAssetType } from "Libs/utils";
import FactorModel from 'Pages/FactorModel/FactorModel';
import Screener from "Pages/Screener/NewScreener";
import AdminServices from "Services/api/AdminServices";
import AdminServicesPY from 'Services/api/AdminServicesPY';
import apiHelper from "Services/api/ApiHelper";
import apiHelperPY from 'Services/api/ApiHelperPY';
import { StockLivePrice } from 'Services/api/LivePricesWebSocket';
import { Alert, Badge, Button, Card, Checkbox, Collapse, Divider, Drawer, Input, Modal, Popconfirm, Progress, Result, Segmented, Select, Skeleton, Space, Spin, Table, Tabs, Tag, Timeline, Tooltip, notification } from "antd";
import Highcharts from 'highcharts';
import HighchartsHeatmap from "highcharts/modules/heatmap";
import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import toast from 'react-hot-toast';
import { AiOutlineDownload } from "react-icons/ai";
import { BiBriefcase, BiDownload, BiSolidCheckCircle } from 'react-icons/bi';
import { BsArrowRight, BsChevronLeft, BsSearch } from 'react-icons/bs';
import { FiMinusCircle } from "react-icons/fi";
import { IoIosArrowDown, IoIosClose } from "react-icons/io";
import { IoRemoveCircle } from "react-icons/io5";
import { MdDelete, MdOutlineCancel } from "react-icons/md";
import { TbChartCandleFilled, TbDeviceDesktopAnalytics } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Link, useParams, useSearchParams } from "react-router-dom";
HighchartsHeatmap(Highcharts)

const ChartAndSnapshot = React.memo(({ isMobile, dispatch, type, fundDetCardsData, fundData, fundDetails, MINTBOX_TYPE, location, canEditBasket }) => {

    const [selectedChartItem, setSelectedChartItem] = useState(1);
    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';

    const chartItems = [
        {
            key: 1,
            value: 1,
            label: "Performance",
            heading: fundDetails?.config?.is_live === 1 ? "Equity curve" : "Strategy performance",
            subHeading: "Performance is calculated from the date of strategy creation"
        },
        {
            key: 2,
            value: 2,
            label: "Disclaimer",
            heading: "Disclaimer"
        }
    ]

    return (
        <div className={isMobile ? "mainContainer" : "mainContainer leftRightPadContainer"} style={{
            paddingTop: isMobile ? "0" : "1.25rem", paddingBottom: isMobile ? "0" : "1.25rem", gap: isMobile ? "0" : "1.25rem"
        }}>
            <div className="leftContainerV2">
                <Card bordered={false} bodyStyle={{ paddingBottom: 0 }} className="mobCardBorderRadius0 w100 h100" style={{ border: isMobile ? "none" : "1px solid var(--grey3)" }}>
                    <div style={{ display: "flex", flexDirection: 'var(--flexDirection)', gap: '1rem', justifyContent: 'space-between' }}>
                        <div style={{ display: "flex", flex: 1, gap: "1rem" }}>
                            <div>
                                <CardHeader heading={chartItems?.find((el) => el.value === selectedChartItem)?.heading} headingType={2} hideHeaderBlueLine
                                    textClassName={isMobile ? "textMD w-500" : 'textXL w-700'} />
                                <div className='textSM dark3' style={{ marginTop: isMobile ? "0.75rem" : "0rem" }}>
                                    {chartItems?.find((el) => el.value === selectedChartItem)?.subHeading}
                                </div>
                            </div>
                        </div>
                        <div>
                            <CustomSegmentedTab flexVal="unset" isBlock={false} mobileStyles={{ margin: '0 -4px 1rem' }} value={selectedChartItem}
                                onChange={(e) => { setSelectedChartItem(e) }} options={chartItems} />
                        </div>
                    </div>
                    <Divider style={{ margin: "0.75rem 0" }} className='display-desktop' />
                    {selectedChartItem === 1 ?
                        <ScreenSignalBacktestChart portfolioType={12} is_live={fundDetails?.config?.is_live} defAmtSelect={fundDetails?.config?.initial_capital} defYrSelect={"365D"} chartData={fundDetails?.wi_with_benchmark} xAxisKey={"date"} fundName="Portfolio" key1="portfolio" key2="benchmark" goLiveDate={fundDetails?.config?.go_live_timestamp} />
                        // <PerfBenchChart xAxisKey='date' goLiveDate={fundDetails?.config?.go_live_timestamp} portfolioType={MINTBOX_TYPE} isDashboard={false} type={type} chartData={fundDetails?.wi_with_benchmark} />
                        :
                        <Disclaimer val={fundDetails?.config?.is_live === 1 ? 10 : 9} />}
                </Card>
            </div>
            {isMobile && <Divider style={{ background: "var(--grey3)", margin: "1rem 0" }} />}
            <div className="rightContainerV2" style={{ padding: isMobile ? "1rem" : "" }}>
                <Card bordered={false} style={{ height: "100%", minHeight: "30rem", border: "1px solid var(--grey2)" }} bodyStyle={{ height: "100%", padding: 0 }} className=''>
                    <div className="d-flex flex-column h100" style={{ maxHeight: window.innerWidth > 760 ? "31rem" : "max-content", minHeight: "100%", }}>
                        <div style={{ flex: 1, padding: "0rem", overflowY: "auto" }}>
                            <FundDetCards data={fundDetCardsData} type="grid" height="19.75rem" headingType={3} />
                        </div>
                        {/* <Divider style={{ margin: 0, background: "var(--grey13)" }} />
                        <div style={{ padding: "1.25rem", gap: "1rem" }} className='d-flex'>
                            <SharpelyGateway2 IS_BASKET={true} INSTRUMENT_TYPE={4} FUNDDATA={fundData} TRANSACTION_TYPE={'BUY'} CAN_INVEST={canEditBasket}
                                cannotInvestContent={(fundDetails?.config?.screen_id || 0) > 0 ? "You cannot invest in this strategy because you have deleted the screen using which this strategy was built." :
                                    (fundDetails?.config?.signal_id || 0) > 0 ? "You cannot invest in this strategy because you have deleted the factor model using which this strategy was built." : ""} />
                        </div> */}
                    </div>
                </Card>
            </div>
        </div>
    )
})

const Fundamentals = React.memo(({ fundamentals = {}, loading }) => {

    const portfolioFundamentals = [{
        key: 'a',
        heading: 'P/E ratio',
        value: DecimalValueFormat({ num: fundamentals?.pe, suffix: "", placeholder: "-" })
    }, {
        key: 'b',
        heading: 'P/B ratio',
        value: DecimalValueFormat({
            num: fundamentals?.pb,
            suffix: "", placeholder: "-"
        }),
    }, {
        key: 'c',
        heading: 'Average YTM',
        value: DecimalValueFormat({
            num: fundamentals?.ytm,
            suffix: "%", placeholder: "-"
        })
    }, {
        key: 'd',
        heading: 'Average maturity',
        value: DecimalValueFormat({
            num: fundamentals?.maturity,
            suffix: " years", placeholder: "-"
        })
    }, {
        key: 'e',
        heading: 'Average duration',
        value: DecimalValueFormat({
            num: fundamentals?.duration,
            suffix: " years", placeholder: "-"
        })
    }]

    return (
        <>
            {loading ? <ActivityLoaderMintbox /> :
                <CustomLabelValueCards x={portfolioFundamentals} showDetailIcon={true} />}
        </>
    )
});

const HoldingBreakup = React.memo(({ performanceFactsheet = {}, loading }) => {

    const TopHoldings = ({ data = {} }) => {
        const chipset2 = Object.keys(data || {})?.map((d, index) => {
            if (data[d]?.length > 0) {
                return {
                    key: index,
                    value: d,
                    label: d.replace("international", "intl'").charAt(0).toUpperCase() + d.replace("international", "intl'").replace("_", " ").slice(1),
                }
            }
        })?.filter((el) => el !== undefined)
        const [holdingCat, setholdingCat] = React.useState(chipset2?.[0]?.value)
        // alert(holdingCat)
        const dataSource = data?.[holdingCat] || []
        const columns = [
            {
                title: 'Instrument',
                dataIndex: data?.[holdingCat]?.hasOwnProperty('instrument') ? "instrument" : "full_name",
                fixed: 'left',
                width: 250
            },
            {
                title: 'Sector',
                dataIndex: 'sector',
                align: 'left',
                width: 150,
                show: holdingCat !== "others"
            },
            {
                title: 'Weight',
                dataIndex: data?.[holdingCat]?.hasOwnProperty('weight') ? 'weight' : "weights",
                align: "right",
                width: 150,
                render: text => DecimalValueFormat({ num: data?.[holdingCat]?.hasOwnProperty('weight') ? Number(text) : Number(text) * 100, suffix: "%", placeholder: "-" })
            }
        ]?.filter((i) => i?.show !== false)
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'start', marginTop: "1rem" }} className="mb-3">
                    <Segmented options={chipset2.filter(ele => ele)} value={holdingCat} onChange={setholdingCat} />
                </div>
                <MyTable dataSource={dataSource} columns={columns} className='textXS black' pagination={{
                    position: ['bottomCenter'],
                    showSizeChanger: false,
                    showPrevNextJumpers: true,
                    className: "textSM",
                    hideOnSinglePage: true,
                    itemRender: (current, type, originalElement) => {
                        if (type === 'prev') {
                            return <a {...originalElement.props} className="textSM mx-2">Previous</a>;
                        }
                        if (type === 'next') {
                            return <a {...originalElement.props} className="textSM mx-2">Next</a>;
                        }
                        return originalElement;
                    }
                }} />
            </div>
        );
    }

    const SectorAllocation = ({ data }) => {

        if (!Object.values(data)?.length) {
            return <Card><NoDataFound message='Not enough data!' /></Card>
        }
        const dataSource = Object.keys(data || {}).map(f => {
            return {
                'Sector': f,
                'Allocation': data[f]
            }
        })?.sort((a, b) => b.Allocation - a.Allocation);
        const columns = [
            {
                title: 'Sector',
                dataIndex: 'Sector',
                width: 250,
                fixed: 'left'
            },
            {
                title: 'Allocation',
                dataIndex: 'Allocation',
                render: (text) => TableProgressBarFormat({ num: text })
            },
        ]
        return <div style={{ display: 'flex', gap: '1rem', marginTop: "1rem" }}>
            <div>
                <MyTable style={{}} dataSource={dataSource} columns={columns} className='textXS black' pagination={{ hideOnSinglePage: true, pageSize: 50 }} />

            </div>
        </div>
    }

    const AssetAllocation = ({ data = {}, type = "table" }) => {
        ////console.log(data)
        if (!Object.values(data)?.length) {
            return <Card><NoDataFound message='Not enough data!' /></Card>
        }
        const dataSource = Object.keys(data || {}).map(f => {
            return {
                'Asset': f[0]?.toUpperCase() + f.substring(1),
                'Allocation': data[f]
            }
        })?.filter(x => x.Allocation)?.sort((a, b) => b.Allocation - a.Allocation);
        const columns = [
            {
                title: 'Asset',
                dataIndex: 'Asset',
                width: 150,
                fixed: 'left'
            },
            {
                title: 'Allocation',
                dataIndex: 'Allocation',
                render: (text) => TableProgressBarFormat({ num: text })
            }
        ]
        if (type === "table")
            return <div style={{ display: 'flex', gap: '1rem', marginTop: "1rem" }}>
                <div>
                    <MyTable style={{}} dataSource={dataSource} columns={columns} className='textXS black' pagination={{ hideOnSinglePage: true, pageSize: 50 }} />

                </div>
            </div>
        else
            return <div style={{ display: 'flex', gap: '1rem', marginTop: "1rem" }}>
                {dataSource?.map((i) => {
                    <div>
                        <Progress type="circle" percent={i?.Allocation} />
                        <div>{i?.Asset}</div>
                    </div>
                })}
            </div>
    }

    const CreditRating = ({ data }) => {

        if (!Object.values(data)?.length) {
            return <Card>
                <NoDataFound message='It seems like your basket has no significant debt holding or debt is held through Fund of Funds (FoF) in which case we are not able to provide credit rating profile' />
            </Card>
        }
        const dataSource = Object.keys(data || {}).map(f => {
            return {
                'Sector': f,
                'Weight': data[f]
            }
        })?.sort((a, b) => b.Weight - a.Weight);
        const columns = [
            {
                title: 'Sector',
                dataIndex: 'Sector',
                width: 250,
                fixed: 'left'
            },
            {
                title: 'Weight',
                dataIndex: 'Weight',
                render: (text) => TableProgressBarFormat({ num: text })
            }
        ]
        return <div style={{ display: 'flex', gap: '1rem', marginTop: "1rem" }}>
            <div>
                <MyTable style={{}} dataSource={dataSource} columns={columns} className='textXS black' pagination={{ hideOnSinglePage: true, pageSize: 50 }} />
            </div>
        </div>
    }

    const tabData = [
        { key: 1, label: "Top holdings", value: 1, children: <TopHoldings data={performanceFactsheet?.factsheet?.holdings} /> },
        { key: 2, label: "Sector allocation", value: 2, children: <SectorAllocation data={performanceFactsheet?.factsheet?.sector_alloc} /> },
        { key: 3, label: "Asset allocation", value: 2, children: <AssetAllocation data={performanceFactsheet?.asset_allocation} /> },
        { key: 4, label: "Credit rating", value: 3, children: <CreditRating data={performanceFactsheet?.factsheet?.credit_rating} /> },
    ]

    const [selectedTab, setSelectedTab] = React.useState(1);

    return (
        <>
            {loading ? <ActivityLoaderMintbox margin='4rem' /> :
                <Tabs size='medium' items={tabData} onChange={(e) => setSelectedTab(e)} activeKey={selectedTab} />}
        </>
    )
});

// static strategy
const ListEtfView = React.memo(({ id, data, index, type, GetLTP, weight_method, staticStrategyFunds, changeData, isModalView, del, isCloned }) => {
    const [weight, setWeight] = React.useState(Number(data.allocation))
    ////console.log("logger", data)
    // var textinp = React.useRef()
    try {
        return <div style={{ borderBottom: "0.5px solid var(--grey2)" }} key={id}>
            <div style={{ display: "flex", gap: "20px", alignItems: "center", padding: 10 }}>
                {type.value === 4 ? <AMCIcon amcName={data.symbol} fundType={type.value} height='1.2rem' width="1.2rem" /> :
                    <AMCIcon amcName={data.amc_full_name} height='1.2rem' width="1.2rem" />}
                <div style={{ flex: 1 }}>
                    <div className='textXS w-500'>{type.value === 4 ? data?.proper_name : data?.basic_name}</div>
                    <div className='textXS'>{type.value === 2 ? "NAV: " : "LTP: "} <span className='textXS w-500'>{CurrencyFormat(type.value === 2 ? data?.latest_nav : GetLTP({ fundData: data }))}</span></div>
                </div>
                <CurrencyInput
                    disabled={(!(weight_method === 1 || weight_method === "W-CT")) || isCloned}
                    className={`inputBoxMain ${"textSM"} black  `}
                    value={weight}
                    suffix={" %"}
                    onValueChange={(value) => {
                        setWeight(value)
                    }}
                    min={1}
                    max={staticStrategyFunds.length > 1 ? 99 : 100}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            if (weight !== undefined) {
                                changeData(index, Number(weight))
                            } else {
                                toast.error("Please enter a value")
                            }
                            // onValueChange(Number(metricVal))
                        }
                    }}
                    style={{ width: "" }}
                    onBlur={() => {
                        if (weight !== undefined) {
                            changeData(index, Number(weight))
                        } else {
                            toast.error("Please enter a value")
                        }
                        // onValueChange(Number(metricVal))
                    }}
                    onSubmit={() => {
                        if (weight !== undefined) {
                            changeData(index, Number(weight))
                        } else {
                            toast.error("Please enter a value")
                        }
                        // onValueChange(Number(metricVal))
                    }}
                />
                {(isModalView !== true && !isCloned) ?
                    <FiMinusCircle size={20} style={{ backgroundColor: "#FFE7E5", color: '#FF8080', borderRadius: "50%", margin: "auto 10", cursor: "pointer" }} onClick={() => del(index)} /> : <></>}
            </div>
        </div>
    } catch (error) {
        ////////console.log("error", error)
        return <div><span>err</span></div>
    }
})

const ListEtfViewLoading = React.memo(() => {
    try {
        return <div style={{ borderBottom: "0.5px solid var(--grey2)" }}>
            <div style={{ display: "flex", gap: "20px", alignItems: "center", padding: 10 }}>
                <Skeleton.Avatar shape="square" size="small" />
                <div style={{ display: "flex", alignItems: "center", flex: 1, justifyContent: "space-between" }}>
                    <Skeleton.Input size="small" />
                    <Skeleton.Input size="small" />
                </div>
            </div>
        </div>
    } catch (error) {
        return <div><span>err</span></div>
    }
})

const AddFromWatchlist = React.memo(({ watchlistModal, setWatchlistModal, staticStrategyFunds = [], type, onAddInstruments, watchData, checkAddDisabled, allFunds, watchlist }) => {

    const filteredWatchlist = watchlist ? watchlist?.filter((el) => el?.FundType === type?.value) : [];
    const [selectedWatchlist, setSelectedWatchlist] = React.useState(watchlist ? watchlist?.filter((el) => el?.FundType === type?.value)?.[0] : {});
    const [selectedWatchFunds, setselectedWatchFunds] = React.useState(staticStrategyFunds);

    const addFundFromWatchlist = (item) => {
        let check = selectedWatchFunds?.find((el) => type.value === 4 ? (el?.symbol === item?.symbol) : (el?.plan_id === item?.plan_id));
        if (check) {
            let temp = selectedWatchFunds?.filter((el) => type.value === 4 ? (el?.symbol !== item?.symbol) : (el?.plan_id !== item?.plan_id));
            setselectedWatchFunds(temp);
        }
        else {
            setselectedWatchFunds([...selectedWatchFunds, item])
        }
    }

    React.useEffect(() => {
        setselectedWatchFunds(staticStrategyFunds || [])
    }, [staticStrategyFunds])

    React.useEffect(() => {
        setSelectedWatchlist(watchlist ? watchlist?.filter((el) => el?.FundType === type?.value)?.[0] : {});
    }, [type.value])

    return (
        <Modal open={watchlistModal} closable={false} centered footer={<div>
            <button onClick={() => {
                setselectedWatchFunds(staticStrategyFunds);
                setWatchlistModal(false);
            }} className='btnWhite' style={{ marginRight: "1rem" }}>Cancel</button>
            <button onClick={() => {
                onAddInstruments(selectedWatchFunds);
                setWatchlistModal(false);
            }} className='btnBlack' disabled={checkAddDisabled(selectedWatchFunds)}>Add</button>
        </div>} title='Watchlist' onCancel={() => {
            setselectedWatchFunds(staticStrategyFunds);
            setWatchlistModal(false);
        }}>
            {filteredWatchlist?.length > 0 ? <div>
                <CustomSegmentedTab options={filteredWatchlist?.map((el) => { return { key: el?.Name, label: el?.Name, value: el?.WatchListId } })} value={selectedWatchlist?.WatchListId}
                    onChange={(e) => {
                        let t = filteredWatchlist?.find((el) => el?.WatchListId === e);
                        setSelectedWatchlist(t);
                    }} isBlock={false} style={{ padding: "0.25rem" }} />
                <div style={{ marginTop: "1.25rem" }}>
                    {selectedWatchlist?.FundCodes?.length > 0 ? <div className='d-flex flex-column' style={{ gap: "0.75rem", maxHeight: "50vh", overflow: "auto" }}>
                        {selectedWatchlist?.FundCodes?.map((el, i) => {
                            let item = allFunds?.find((ele) => el === String(ele[type.value === 1 ? "nse_symbol" : type.value === 2 ? "plan_id" : "symbol"]));
                            let isPresent = selectedWatchFunds?.find((el) => type.value === 4 ? (el?.symbol === item?.symbol) : (el?.plan_id === item?.plan_id)) ? true : false
                            return (
                                <div key={generateUniqueKey(el)} style={{
                                    border: "1px solid var(--grey2)", padding: "0.25rem 0.5rem", borderRadius: "var(--borderRadius)", cursor: "pointer", gap: "0.5rem",
                                    background: isPresent ? "var(--blueShade)" : ""
                                }}
                                    className='d-flex align-items-center' onClick={() => addFundFromWatchlist(item)}>
                                    <Checkbox checked={isPresent} onChange={() => { }} />
                                    <AMCIcon amcName={item?.symbol || item?.amc_full_name} fundType={type?.value} height='1.6rem' width="1.6rem" type='square'
                                        bseTicker={item?.bse_ticker} />
                                    <div style={{ flex: 1 }}>
                                        <div className='textXS w-500 dark4' style={{ width: "100%", whiteSpace: "nowrap", textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                            {type?.value === 4 ? item?.proper_name : item?.basic_name?.replace(" - Direct Plan", "")}
                                        </div>
                                        <div className='w-500 dark3' style={{ fontSize: '0.6rem' }}>{type.value === 4 ? item?.symbol : item?.isin_code}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div> : <NoDataFound message={`Looks like you have not added any ${type?.dname?.toLowerCase()} in this watchlist!`} />}
                </div>
            </div> : <div >
                <NoDataFound message={`You have not created any watchlist yet!!`} />
            </div>}
        </Modal>
    )
})

const AddFromScreen = React.memo(({ screenModal, setScreenModal, staticStrategyFunds = [], allFunds = [], type, loadingScreens, screens, onAddInstruments, checkAddDisabled }) => {

    const [selectedFunds, setSelectedFunds] = React.useState(staticStrategyFunds);
    const [selectedScreen, setSelectedScreen] = React.useState({});
    const [screenFunds, setScreenFunds] = React.useState([]);
    const [screenFundsLoading, setScreenFundsLoading] = React.useState(true);

    const onCancel = () => {
        setScreenModal(false);
        setSelectedFunds(staticStrategyFunds);
        setSelectedScreen({});
    }

    const getScreenerFunds = async (item) => {
        setScreenFundsLoading(true);
        setSelectedScreen(item);
        const final = item.rules;
        var allFund = JSON.parse(JSON.stringify(allFunds))
        if (type.value === 4) {
            var filFunds = await AdminServicesPY.getAllScreenedFundsFromAdvQuery({ rules: final }).then((r) => r)
            filFunds = filFunds?.data?.length > 0 ? filFunds?.data : []
            allFund = allFund?.filter((fund) => filFunds.includes(fund?.symbol))
        }
        else {
            for (let i = 0; i < final.length; i++) {
                var query = final[i]
                var primary_col_name = query['primary_col'];
                var secondary_col_name = null
                if (allFund?.length !== 0) {
                    if (primary_col_name == "investing_style") {
                        if (query['abs_val_1'] !== 'all') {
                            var filt = query['abs_val_1']?.split(',')
                            filt?.map((filter) => {
                                allFund = allFund.filter((fund) => eval(`fund[filter] == 1`))
                                return ""
                            })
                        }

                    } else {
                        allFund = allFund.filter((fund) => eval(`fund[primary_col_name] !== null`))

                        if (query['oper'] == 'abs_comp') {
                            var op = query['comp_operator']
                            var num = parseFloat(query['abs_val_1'])

                            if (op == 'gt')
                                allFund = allFund.filter((fund) =>
                                    eval(`fund[primary_col_name] > ${num} `))
                            if (op == 'lt')
                                allFund = allFund.filter((fund) =>
                                    eval(`fund[primary_col_name] < ${num} `))
                            if (op == 'eq')
                                allFund = allFund.filter((fund) =>
                                    eval(`fund[primary_col_name] == ${num} `))
                            if (op == 'lteq')
                                allFund = allFund.filter((fund) =>
                                    eval(`fund[primary_col_name] <= ${num} `))
                            if (op == 'gteq')
                                allFund = allFund.filter((fund) =>
                                    eval(`fund[primary_col_name] >= ${num} `))
                        }

                        if (query['oper'] == 'isin_comp' && query['abs_val_1'] !== undefined && query['abs_val_1']?.length !== 0) {
                            var str = []
                            query['abs_val_1'].split(",").map((fItem) => {
                                str.push(`String(fund['${String(primary_col_name)}']) === '${String(fItem)}'`)
                            })
                            allFund = allFund.filter((fund) => eval(str.join(" || ")))
                        }

                        if (query['oper'] == 'isnotin_comp' && query['abs_val_1'] !== undefined && query['abs_val_1']?.length !== 0) {
                            var str = []
                            query['abs_val_1'].split(",").map((fItem) => {
                                str.push(`String(fund['${String(primary_col_name)}']) !== '${String(fItem)}'`)
                            })
                            allFund = allFund.filter((fund) => eval(str.join(" && ")))
                        }

                        if (query['oper'] == 'rank_comp') {
                            var op = query['comp_operator']
                            var [asc_flag, cutoff, pct_flag] = op.split("_")

                            if (asc_flag == 'rng') {
                                var [ct_low, ct_high] = cutoff.split("-")

                                if (pct_flag === "per") {
                                    //per
                                    var list = allFund.map(function (d) {
                                        return d[primary_col_name]
                                    })

                                    if (list.length > 0) {
                                        var val_low = quantile(list, parseFloat(ct_low) / 100)
                                        var val_high = quantile(list, parseFloat(ct_high) / 100)
                                        allFund = allFund.filter((fund, index) => eval(`Number(fund[primary_col_name]) >= ${parseFloat(val_low)} && Number(fund[primary_col_name]) >= ${parseFloat(val_high)} `))
                                    }
                                }

                            } else if (asc_flag == 'top') {
                                if (asc_flag !== undefined) {
                                    allFund = sortFunds(allFund, primary_col_name, asc_flag)
                                }

                                if (pct_flag === "per") {
                                    //per
                                    var list = allFund.map(function (d) {
                                        return d[primary_col_name]
                                    })

                                    if (list.length > 0) {
                                        var val = quantile(list, parseFloat(cutoff) / 100)
                                        allFund = allFund.filter((fund, index) => eval(`Number(fund[primary_col_name]) >= ${parseFloat(val)} `))
                                    }
                                } else {
                                    //abs
                                    allFund = allFund.splice(0, parseInt(cutoff))
                                }


                            } else {
                                if (asc_flag !== undefined) {
                                    allFund = sortFunds(allFund, primary_col_name, asc_flag)
                                }

                                if (pct_flag === "per") {
                                    //per
                                    var list = allFund.map(function (d) {
                                        return d[primary_col_name]
                                    })

                                    if (list.length > 0) {
                                        var val = quantile(list, parseFloat(cutoff) / 100)
                                        allFund = allFund.filter((fund, index) => eval(`Number(fund[primary_col_name]) <= ${parseFloat(val)} `))
                                    }
                                } else {
                                    //abs
                                    allFund = allFund.splice(0, parseInt(cutoff))
                                }
                            }



                        }

                        if (query['oper'] == 'col_comp') {
                            secondary_col_name = query['sec_col']
                            var adjustment_operator = query['adj_operator']

                            const finalCalc = (num) => {
                                var op = query['comp_operator']
                                if (op == 'gt')
                                    allFund = allFund.filter((fund) =>
                                        eval(`fund[primary_col_name] > ${num} && fund[primary_col_name] !== null`))
                                if (op == 'lt')
                                    allFund = allFund.filter((fund) =>
                                        eval(`fund[primary_col_name] < ${num} && fund[primary_col_name] !== null`))
                                if (op == 'eq')
                                    allFund = allFund.filter((fund) =>
                                        eval(`fund[primary_col_name] == ${num} && fund[primary_col_name] !== null`))
                                if (op == 'lteq')
                                    allFund = allFund.filter((fund) =>
                                        eval(`fund[primary_col_name] <= ${num} && fund[primary_col_name] !== null`))
                                if (op == 'gteq')
                                    allFund = allFund.filter((fund) =>
                                        eval(`fund[primary_col_name] >= ${num} && fund[primary_col_name] !== null`))
                            }

                            if (adjustment_operator !== null) {
                                var [ad_operator, ad_val] = adjustment_operator.split("_")
                                var num = null
                                if (ad_operator == 'add')
                                    finalCalc(`fund[secondary_col_name] + ${parseFloat(ad_val)} `)
                                if (ad_operator == 'sub')
                                    finalCalc(`fund[secondary_col_name] - ${parseFloat(ad_val)} `)
                                if (ad_operator == 'mult')
                                    finalCalc(`fund[secondary_col_name] * ${parseFloat(ad_val)} `)
                                if (ad_operator == 'div')
                                    finalCalc(`fund[secondary_col_name] / ${parseFloat(ad_val)} `)
                            }
                        }
                    }
                }
            }
        }
        setScreenFunds(allFund);
        setScreenFundsLoading(false);
    }

    const addFundFromScreen = (item) => {
        let check = selectedFunds?.find((el) => type.value === 4 ? (el?.symbol === item?.symbol) : (el?.plan_id === item?.plan_id));
        if (check) {
            let temp = selectedFunds?.filter((el) => type.value === 4 ? (el?.symbol !== item?.symbol) : (el?.plan_id !== item?.plan_id));
            setSelectedFunds(temp);
        }
        else {
            setSelectedFunds([...selectedFunds, item])
        }
    }

    React.useEffect(() => {
        setSelectedFunds(staticStrategyFunds || [])
    }, [staticStrategyFunds])

    return (
        <Modal open={screenModal} footer={null} closable={false} onCancel={onCancel}>
            <div className='textLG w-700 dark4' >
                {!selectedScreen?.client_id ? 'Select a screen' : <div className='textLG w-700 dark4 d-flex align-items-center' style={{ gap: "0.5rem", cursor: "pointer" }}
                    onClick={() => { setSelectedScreen({}) }}>
                    <BsChevronLeft /> {selectedScreen?.checklist_name}
                </div>}
            </div>
            {selectedFunds?.length > 0 && !selectedScreen?.client_id && <>
                <div className='textSM w-500 mt-2'>Selected {type.value === 4 ? "Stock" : type.strVal?.toUpperCase()}s</div>
                <div className='d-flex align-items-center scrollbox' style={{ gap: "0.75rem", width: "100%", overflow: "auto", margin: '0.3rem 0 0.5rem' }}>
                    {selectedFunds?.map((item, i) => {
                        return (
                            <div className='d-flex align-items-center' style={{ gap: "0.5rem", padding: "0.5rem", border: "1px solid var(--grey2)", borderRadius: "0.75rem" }} key={generateUniqueKey(i)}>
                                <AMCIcon amcName={item?.symbol || item?.amc_full_name} height='1rem' width="1rem" fundType={type.value} bseTicker={item?.bse_ticker} />
                                <div className='textXS dark4 w-500' style={{ whiteSpace: "nowrap" }}>{type.value === 4 ? item?.proper_name :
                                    item?.basic_name?.replace(" - Direct Plan", "")}</div>
                                <MdOutlineCancel onClick={() => addFundFromScreen(item)} style={{ cursor: "pointer" }} />
                            </div>
                        )
                    })}
                </div>
                <Divider style={{ margin: 0 }} />
            </>}
            <div style={{ minHeight: "30vh", maxHeight: "50vh", overflowY: "auto" }}>
                {loadingScreens ? <ActivityLoaderMintbox /> : screens.length === 0 ? <NoDataFound message={`You have not created any ${type.strVal} screen`} /> :
                    <div style={{ height: '100%' }}>
                        {!selectedScreen?.client_id ? <>
                            <div className='textSM w-500 mt-2'>Your screens</div>
                            {screens?.map((item, i) => (
                                <div key={generateUniqueKey(i)} className='d-flex align-items-center' style={{ gap: "0.75rem", padding: "0.5rem 0", borderBottom: "1px solid var(--grey2)", cursor: "pointer" }}
                                    onClick={() => getScreenerFunds(item)}>
                                    <ScreenerIcon width='1.5rem' height='1.5rem' id={item?.checklist_id} />
                                    <div className="textSM w-500 black" style={{ flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                                        {item?.checklist_name}
                                    </div>
                                </div>
                            ))}</> : <div className='d-flex flex-column' style={{ gap: "0.75rem", overflow: "auto", marginTop: "1rem" }}>
                            {screenFundsLoading ? <div>
                                <ActivityLoaderMintbox />
                            </div> : screenFunds?.map((item, i) => {
                                let isPresent = selectedFunds?.find((el) => type.value === 4 ? (el?.symbol === item?.symbol) : (el?.plan_id === item?.plan_id)) ? true : false
                                return (
                                    <div key={generateUniqueKey(i)} style={{
                                        border: "1px solid var(--grey2)", padding: "0.25rem 0.5rem", borderRadius: "var(--borderRadius)", cursor: "pointer", gap: "0.5rem",
                                        background: isPresent ? "var(--blueShade)" : ""
                                    }}
                                        className='d-flex align-items-center' onClick={() => addFundFromScreen(item)}>
                                        <Checkbox checked={isPresent} onChange={() => { }} />
                                        <AMCIcon amcName={item?.symbol || item?.amc_full_name} height='1.6rem' width="1.6rem" type='square' fundType={type.value}
                                            bseTicker={item?.bse_ticker} />
                                        <div style={{ flex: 1 }}>
                                            <div className='textXS w-500 dark4' style={{ width: "100%", whiteSpace: "nowrap", textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                                {type.value === 4 ? item?.proper_name : item?.basic_name?.replace(" - Direct Plan", "")}
                                            </div>
                                            <div className='w-500 dark3' style={{ fontSize: '0.6rem' }}>{type.value === 4 ? item?.symbol : item?.isin_code}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>}
                    </div>}
            </div>
            <div className='d-flex justify-content-end' style={{ gap: "1rem", marginTop: "1rem" }}>
                <button className='btnWhite' onClick={onCancel}>Cancel</button>
                <button className='btnBlack' onClick={() => {
                    onAddInstruments(selectedFunds);
                    setScreenModal(false);
                }} disabled={checkAddDisabled(selectedFunds)}>Add ({selectedFunds?.length})</button>
            </div>
        </Modal>
    )
})

const AddInstrument = React.memo(({ allFunds = [], staticStrategyFunds = [], type, instrument_method = 1, sectors = [], industryGrp = [], industries = [], mfCategories = [], benchmarks = [], isMobile, isModalView, watchlist,
    onAddInstruments, listLoading, backtestWeightMethods, weight_method, setweight_method, weight_by, setweight_by, nextDisabled, screens = [], loadingScreens, watchData, GetLTP, changeData, del, checkAddDisabled, sum, screenerMetrics = [], isCloned = false }) => {

    const [watchlistModal, setWatchlistModal] = React.useState(false);
    const [screenModal, setScreenModal] = React.useState(false);
    const [searchData, setSearchData] = React.useState(allFunds);
    const [fundsToShow, setFundsToShow] = React.useState([]);
    const [searchText, setSearchText] = React.useState('');
    const [selectedFunds, setSelectedFunds] = React.useState(staticStrategyFunds);
    const [selectedFilterOption, setSelectedFilterOption] = React.useState();
    const [fundsLoading, setFundsLoading] = React.useState(true);

    const addFunds = (item) => {
        let max = type.value === 4 ? 50 : 10;
        let check = selectedFunds?.find((el) => type.value === 4 ? (el?.symbol === item?.symbol) : (el?.plan_id === item?.plan_id));
        if (check) {
            let temp = selectedFunds?.filter((el) => type.value === 4 ? (el?.symbol !== item?.symbol) : (el?.plan_id !== item?.plan_id));
            setSelectedFunds(temp);
        }
        else {
            if (selectedFunds?.length >= max) {
                toast.error(`You can add a maximum of ${max} ${type.dname}s`)
            }
            else {
                setSelectedFunds([...selectedFunds, item])
            }
        }
    }

    const loadMoreData = () => {
        let temp = [...searchData];
        let temp2 = temp.slice(fundsToShow.length, fundsToShow.length + 20);
        setFundsToShow([...fundsToShow, ...temp2]);
    }

    const getFundsToShow = (arr) => {
        let temp = ([...arr])?.slice(0, 20);
        setFundsToShow(temp);
    }

    const handleInstrumentListScroll = (event) => {
        const scrollContainer = event.target;
        const scrollPosition = scrollContainer.scrollTop;
        const containerHeight = scrollContainer.clientHeight;
        const contentHeight = scrollContainer.scrollHeight;
        const buffer = 40;
        if (scrollPosition + containerHeight >= contentHeight - buffer) {
            loadMoreData();
        }
    }

    const WatchScreenCTA = ({ padding = '0.75rem' }) => {

        return (
            <div style={{ padding: padding, gap: "0.75rem", borderBottom: "1px solid var(--grey2)" }} className='d-flex align-items-center'>
                <div onClick={() => setWatchlistModal(true)} style={{ padding: "0.5rem 0.75rem", background: "var(--grey3)", borderRadius: "var(--borderRadius)", cursor: "pointer" }}
                    className='textXS w-500 dark4'>
                    Add from Watchlist
                </div>
                <div onClick={() => setScreenModal(true)} style={{ padding: "0.5rem 0.75rem", background: "var(--grey3)", borderRadius: "var(--borderRadius)", cursor: "pointer" }}
                    className='textXS w-500 dark4'>
                    Add from Screen
                </div>
            </div>
        )
    }

    const filterTabs = [
        { key: 1, value: 1, label: "Sector", filterKey: "sector_code", options: sectors, show: type.value === 4 },
        { key: 2, value: 2, label: "Industry group", filterKey: "industry_code", options: industryGrp, show: type.value === 4 },
        { key: 3, value: 3, label: "Industry", filterKey: "nse_basic_ind_code", options: industries, show: type.value === 4 },
        { key: 1, value: 1, label: "Category", filterKey: "category_name", options: sectors, show: type.value !== 4, type: "cascader" },
        { key: 2, value: 2, label: "Benchmark", filterKey: "benchmark", options: benchmarks, show: type.value !== 4 },
    ]?.filter((el) => el?.show)

    const [selectedFilterType, setSelectedFilterType] = React.useState(1);

    const onSearch = (e) => {
        setSearchText(e.target.value)
        let funds = [...allFunds];
        let key = filterTabs?.find((el) => el?.value === selectedFilterType)?.filterKey || '';
        if (selectedFilterOption) {
            funds = funds?.filter((el) => el?.[key] === selectedFilterOption);
        }
        if (type.value === 4) {
            let temp = searchAndSort(e.target.value, [...funds], ['symbol', 'proper_name']);
            setSearchData(temp);
        }
        else if (type.value === 1) {
            let temp = searchAndSort(e.target.value, [...funds], ['nse_symbol', 'basic_name']);
            setSearchData(temp);
        }
        else {
            let temp = searchAndSort(e.target.value, [...funds], ['isin_code', 'basic_name']);
            setSearchData(temp);
        }
    }

    React.useEffect(() => {
        onSearch({ target: { value: searchText || '' } })
    }, [selectedFilterOption]);

    React.useEffect(() => {
        if (instrument_method === 1 && searchData?.length > 0) {
            getFundsToShow(searchData);
            if (fundsLoading) {
                setFundsLoading(false);
            }
        }
    }, [searchData]);

    React.useEffect(() => {
        if (allFunds?.length > 0 && instrument_method === 1) {
            setSearchData(allFunds);
        }
    }, [allFunds]);

    React.useEffect(() => {
        if (instrument_method === 1) {
            setSelectedFunds(staticStrategyFunds);
        }
    }, [staticStrategyFunds]);

    return (
        <div style={{ flex: 1, maxHeight: "100%", overflow: "scroll" }} className='m-2 d-flex'>
            <div className='d-flex flex-column' style={{ flex: 1, maxHeight: "100%" }}>
                {/* <div>
                    <CardHeader heading={`${isModalView ? "Define weights for " + (instrument_method === 2 ? "screened " : "") + type.dname : "Add your favourite " + type.dname + " and define weights"}`} hideHeaderBlueLine headingType={2} textClassName={isMobile ? "textMD w-700" : 'textXL w-700'} />
                    <div className='textSM dark3' style={{ margin: "0.25rem 0 1rem", maxWidth: "60ch" }}>
                        {`Tell us how much weight do you want to assign each ${type.omkar} in your strategy?`}
                    </div>
                </div> */}
                {staticStrategyFunds.length === 0 && <div id="content" style={{ flex: 1, marginTop: "2rem" }} className="scrollbox">
                    {listLoading && new Array(4).fill(1).map((ele, i) => (
                        <ListEtfViewLoading key={generateUniqueKey(i)} />
                    ))}
                </div>}
                {staticStrategyFunds.length !== 0 && <>
                    <div id="header" style={{ display: "flex", gap: "1rem", alignItems: isMobile ? "start" : "center", flexDirection: isMobile ? 'column' : "row" }}>
                        <div style={{ flex: 1 }}>
                            <div className={`textSM ${isMobile ? "my-3" : "my-2"} w-500`}>{staticStrategyFunds.length} {type.dname} selected</div>
                            <div style={{ marginBottom: isMobile ? "0" : "20px" }} className="textSM" >Minimum 2 | Max {type.value === 4 ? 50 : 10}</div>
                        </div>
                        <div style={{ display: "flex", gap: "0.4rem", alignItems: "center", justifyContent: "flex-end" }} >
                            <Select className='textSM black' disabled={isCloned} suffixIcon={<IoIosArrowDown size={15} />} value={weight_method} style={{ width: "10.5rem", marginTop: 0 }}
                                placeholder="Please select" onChange={(value) => {
                                    setweight_method(value)
                                    setweight_by(value === "W-VAR" ? type?.value === 4 ? "mcap" : "aum" : "")
                                }} options={backtestWeightMethods.filter((i) => i.show !== false)}
                            />
                            {weight_method === "W-VAR" && <Select className='textSM black' disabled={isCloned} suffixIcon={<IoIosArrowDown size={15} />} value={weight_by} style={{ width: "14.5rem", marginTop: 0 }}
                                placeholder="Please select metric" options={screenerMetrics?.map((el) => {
                                    return { ...el, tabType: el?.type }
                                })} showSearch
                                filterOption={(inputVal, option) => {
                                    return isSubsequence(inputVal?.trim(), option?.label)
                                }} onChange={(val) => {
                                    setweight_by(val)
                                    // changeConfig([{ keys: ['position_sizing', 'weight_by'], value: val }])
                                }}
                            />}

                        </div>
                    </div>
                    {nextDisabled && <Alert className='textXS' message={`Sum of weights of selected metrics must be 100. (Current weight ${sum})`} type="error" showIcon />}
                    {nextDisabled && <br></br>}
                    <div id="content" style={{ flex: 1 }} className="scrollbox">
                        {staticStrategyFunds.length > 0 ? staticStrategyFunds.map((data, index) => {
                            if (listLoading) {
                                return <ListEtfViewLoading key={generateUniqueKey(index)} />
                            } else {
                                return <ListEtfView id={data?.isin_code || data?.symbol} key={generateUniqueKey(data?.isin_code || data?.symbol)} data={data}
                                    index={index} type={type} isCloned={isCloned}
                                    GetLTP={(e) => GetLTP(e)} weight_method={weight_method} staticStrategyFunds={staticStrategyFunds} changeData={changeData} isModalView={isModalView} del={del} />
                            }
                        }) : null}
                    </div>
                </>}
            </div>
            {instrument_method === 1 && !isCloned && <div style={{ width: "35%", maxHeight: "60vh", borderLeft: "1px solid var(--grey3)", paddingLeft: "1rem", marginLeft: "1rem", overflow: "hidden" }}
                className='d-flex flex-column h100'>
                <div className='d-flex align-items-center w100' style={{
                    padding: "0.5rem", borderRadius: "var(--borderRadius)", background: "var(--grey3)",
                    border: "1px solid var(--grey2)"
                }}>
                    <BsSearch className='dark2 textSM' style={{ marginRight: "0.3rem" }} />
                    <input style={{ border: "none", outline: "none", background: "transparent", flex: 1 }} placeholder={`Search and add ${type.dname}`} className='textXS dark4'
                        value={searchText} onChange={onSearch} />
                </div>
                <div style={{ flex: 1, maxHeight: '60vh', overflow: "scroll" }} className='mt-2'>
                    <div style={{ maxHeight: '60vh', overflow: "scroll" }} className='scrollbox' onScroll={handleInstrumentListScroll}>
                        {fundsLoading ? <div>
                            <ActivityLoaderMintbox />
                        </div> : searchData?.length > 0 && fundsToShow?.length > 0 ? fundsToShow?.map((item, i) => {
                            let isPresent = selectedFunds?.find((el) => type.value === 4 ? (el?.symbol === item?.symbol) : (el?.plan_id === item?.plan_id)) ? true : false
                            return (
                                <div className='d-flex align-items-center' style={{
                                    gap: "0.5rem", padding: "0.25rem 0.75rem", border: "1px solid var(--grey2)", marginBottom: "0.5rem",
                                    borderRadius: "var(--borderRadius)", cursor: "pointer", background: isPresent ? "var(--blueShade)" : ""
                                }} key={generateUniqueKey(i)}
                                    onClick={() => addFunds(item)}>
                                    <Checkbox checked={isPresent} onChange={() => { }} />
                                    <AMCIcon amcName={item.symbol || item.amc_full_name} fundType={type.value} height='1.6rem' width="1.6rem" type='square'
                                        bseTicker={item?.bse_ticker} />
                                    <div style={{ flex: 1 }}>
                                        <div className='textXS w-500 dark4' style={{ width: "100%", whiteSpace: "nowrap", textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                            {type?.value === 4 ? item?.proper_name : item?.basic_name?.replace(" - Direct Plan", "")}
                                        </div>
                                        <div className='w-500 dark3' style={{ fontSize: '0.6rem' }}>{type.value !== 2 ? item?.symbol || item?.nse_symbol : item?.isin_code}</div>
                                    </div>
                                </div>
                            )
                        }) : fundsToShow?.length === 0 && searchData?.length === 0 && searchText !== '' && <div style={{ margin: "1rem 0" }}>
                            <NoDataFound message={`No ${type.dname} found`} />
                        </div>}
                    </div>
                </div>
                <div style={{ padding: "0.75rem", borderTop: "1px solid var(--grey3)" }} className='d-flex justify-content-end'>
                    <button className='btnBlack' onClick={() => onAddInstruments(selectedFunds)} disabled={checkAddDisabled(selectedFunds)}>
                        Add {selectedFunds?.length}/{type.value === 4 ? 50 : 10}
                    </button>
                </div>
            </div>}
            {instrument_method === 1 && <AddFromWatchlist watchlistModal={watchlistModal} setWatchlistModal={setWatchlistModal} staticStrategyFunds={staticStrategyFunds} type={type}
                onAddInstruments={onAddInstruments} watchData={watchData} checkAddDisabled={checkAddDisabled} watchlist={watchlist} allFunds={allFunds} />}
            {instrument_method === 1 && <AddFromScreen screenModal={screenModal} setScreenModal={setScreenModal} staticStrategyFunds={staticStrategyFunds} allFunds={allFunds} type={type}
                loadingScreens={loadingScreens} screens={screens} onAddInstruments={onAddInstruments} checkAddDisabled={checkAddDisabled} />}
        </div>
    )
})
// static strategy

const ManualRebalOrderPanel = React.memo(({
    fundDetails = {},
    type = {},
    allFundsData = {},
    GetLTP,
    placeManualOrder

}) => {
    console.log((type?.value === 4 ? fundDetails?.analytics?.holdings : fundDetails?.analytics?.constituents))
    const [orders, setOrders] = React.useState((type?.value === 4 ? fundDetails?.analytics?.holdings : fundDetails?.analytics?.constituents)?.map((i) => {
        return {
            ...[...allFundsData].filter((ii) => ii?.[type?.value === 4 ? "symbol" : "plan_id"] === i?.[type?.value === 4 ? "symbol" : "plan_id"])[0],
            ...i,
            transaction_type: "BUY",
            order_quantity: 1,
            order_type: "MKT"
        }
    }))

    const InstrumentComponent = ({ record = {}, index }) => {

        const updateOrder = (data) => {
            var ak = JSON.parse(JSON.stringify(orders))
            ak[index] = data
            setOrders(ak)
        }

        return <div style={{ borderBottom: "1px solid var(--grey3)", paddingBottom: "0.5rem", display: "flex", gap: "1rem", alignItems: "center" }}>
            <div style={{ display: "flex", gap: "1rem", alignItems: "center", flex: 2 }}>
                <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center" }}>
                    <AMCIcon amcName={type?.value === 4 ? record.symbol : record.amc_full_name} fundType={type?.value} height='1.15rem' width="1.15rem" bseTicker={record?.bse_ticker} />
                    <div>
                        <div className="w-500 black" style={{ width: 180, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{record?.proper_name || record.basic_name}</div>
                        {type?.value !== 2 ? <StockLivePrice fundData={record} symbol={record?.symbol || record.nse_symbol} format={10} /> : <span className='dark3 textXS w-500'>NAV :&nbsp; <span className='w-700 textXS'>{CurrencyFormat(record?.latest_nav, 'long', 2, '', false)}</span></span>}
                        <div className='textXS my-1'> <BiBriefcase />
                            <b> {record?.quantity || 0}</b></div>
                    </div>

                </div>
                <BuySellSwitch disabled={(record?.quantity || 0) === 0} className="textXS" value={record?.transaction_type !== "BUY"} onChange={(e) => updateOrder({
                    ...record,
                    order_quantity: 1,
                    transaction_type: e.target.checked ? "SELL" : "BUY"
                })} />
            </div>

            {/* <div style={{ display: "flex", gap: "1rem", alignItems: "center", flex: 1, justifyContent: "flex-end" }}> */}
            <div style={{ flexDirection: "column", flex: 1 }}>
                <LabelInputNew
                    min={1}
                    style={{ width: "55%", textAlign: "center", paddingLeft: 0 }}
                    max={record?.transaction_type !== "BUY" ? record?.quantity : undefined}
                    msg={"quantity available: "}
                    msg2={"required: "}
                    customMsg={record?.transaction_type !== "BUY" ? undefined : "Enter a value"}
                    placeholder={`No. of ${String(type?.basePath)?.replace("/", "")}`}
                    value={record?.order_quantity}
                    onChangeVal={(e) => {
                        let val = Number(e)
                        // alert(val)
                        if (record?.transaction_type !== "BUY") {
                            if (val <= record?.quantity)
                                updateOrder({
                                    ...record,
                                    order_quantity: val
                                })
                        } else {
                            updateOrder({
                                ...record,
                                order_quantity: val
                            })
                        }
                    }}
                />
                {/* <div className='textXS' style={{ color: 'var(--red)' }}>
                    {record?.transaction_type === "BUY" ? }
                </div> */}
            </div>

            <IoRemoveCircle style={{ cursor: "pointer" }} color="var(--red)" onClick={() => {
                var ak = JSON.parse(JSON.stringify(orders))
                ak.splice(index, 1)
                setOrders(ak)
            }} />
            {/* </div> */}
        </div>
    }


    const requiredMargin = () => {
        var ak = orders?.map((i) => {
            if (i?.transaction_type === "BUY") {
                return type?.value === 2 ? i?.order_quantity * i?.latest_nav : i?.order_quantity * GetLTP({ fundData: i })
            }
            return 0
        })
        console.log(ak, (ak?.reduce((a, b) => a + b, 0) || 0))
        return (ak?.reduce((a, b) => a + b, 0) || 0)
    }


    const availableMargin = () => {
        var ak = orders?.map((i) => {
            if (i?.transaction_type === "SELL") {
                return type?.value === 2 ? i?.order_quantity * i?.latest_nav : i?.order_quantity * GetLTP({ fundData: i })
            }
            return 0
        })

        console.log(ak, (ak?.reduce((a, b) => a + b, 0) || 0))
        return (ak?.reduce((a, b) => a + b, 0) || 0) + fundDetails?.cash
    }

    // var sell_error = []
    // const sellChecker = () => {
    //     var ak = orders?.map((i) => {
    //         if (i?.transaction_type !== "BUY" && i?.quantity)
    //             if (i?.quantity <= i?.order_quantity) {
    //                 return 1
    //             } else {
    //                 sell_error.push(`${i?.symbol || i?.nse_symbol || i?.isis_code} max quantity ava`)
    //                 return -1
    //             }
    //         else
    //             return 1
    //     })

    //     return ak?.filter((i) => i === 1)?.length === orders?.length
    // }

    return <div style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", gap: "2rem" }}>

        <div style={{ display: "flex", flexDirection: "column", flex: 1, gap: "1rem", padding: '1rem 1.5rem', overflowY: "scroll" }}>
            <InstrumentListSearch ctaText={`Add ${type.dname}`} placeholder={`Search and add ${type.dname}`} type={type.value} isMultiple={true} selectedValues={orders} onChange={(x) => {
                if (x.length > 50) {
                    toast.error(`You can select a maximum of 50 ${type.dname}.`)
                }
                else {
                    setOrders(x?.map((i) => {
                        return {
                            transaction_type: "BUY",
                            order_quantity: 1,
                            order_type: "MKT",
                            ...i,
                        }
                    }))
                }
            }} maxSelected={50} isFullWidthInMob />
            {orders?.map((item, index) => <InstrumentComponent key={index} record={item} index={index} />)}
        </div>
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: "1px solid var(--grey2)", padding: '0.25rem 1.5rem' }}>
                <div className='textXS'>Margin required:
                    <b> {CurrencyFormat(requiredMargin())}</b></div>
                <div className='textXS' style={{ color: availableMargin() < requiredMargin() ? "var(--red)" : "var(--dark4)" }} >Margin available:
                    <b> {CurrencyFormat(availableMargin())}</b></div>
            </div>
            {(availableMargin() < requiredMargin()) && <Alert className="mx-4 mt-3" type="error" showIcon message={<span className='textXS'>Max margin available is : {<b> {CurrencyFormat(availableMargin())}</b>}. Please adjust your orders.</span>} />}
            <div style={{ display: 'flex', gap: '1rem', padding: '1rem 1.5rem' }}>
                <button style={{ flex: 1 }} disabled={(availableMargin() < requiredMargin())} className="btnBlack " onClick={() => {
                    // setOpenInvModal(false);

                    var finalOrder = orders?.map((order) => {
                        return {
                            "symbol": order?.symbol || order?.nse_symbol || order?.isin_code,
                            "order_quantity": order?.transaction_type === "BUY" ? order?.order_quantity : -1 * order?.order_quantity,
                            "entry_price": type?.value === 2 ? order?.latest_nav : GetLTP({ fundData: order }),
                            "direction": order?.transaction_type,
                            "order_type": "MKT"
                        }
                    })
                    placeManualOrder(finalOrder)
                }}>Execute order</button>
            </div>
        </div>
    </div>
})

const StrategyDetail = (props) => {
    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
    const watchlist = useSelector((state) => state?.userWatchlists?.value);
    const [searchParams, setSearchParams] = useSearchParams()
    const params = useParams();
    const MINTBOX_TYPE = Number(params.type)
    const MINTBOX_ID = params.id
    const type = useGetAssetType(MINTBOX_TYPE) // 1 = etf, 2 = mf
    const navigate = useNavigate()
    const { state } = useLocation()
    const dispatch = useDispatch()
    const location = useLocation()
    const user = useSelector((state) => state?.updateUserData?.value)
    const [loading, setLoading] = useState(true);
    const [allFilteredFundsData, setallFilteredFundsData] = useState([])
    const [allFundsData, setallFundsData] = useState([])
    const [fundData, setFundData] = useState({});
    const [fundDetails, setfundDetails] = useState({});
    const [isWatchlisted, setIsWatchlisted] = useState(false);
    const [apiError, setApiError] = useState(false)
    const [flipped, setFlipped] = useState(false)
    const [screenerMetric, setscreenerMetric] = useState([])
    const [screenerMetricDescData, setScreenerMetricDescData] = useState([])
    const [screener_data_final, setscreener_data_final] = useState([])
    const [selectedTabItem, setSelectedTabItem] = React.useState(state?.defTab || 'Overview');
    const [selectedChartItem, setSelectedChartItem] = useState(1);
    const [editBasketLoader, seteditBasketLoader] = useState(true)
    const [editBasketDrawer, seteditBasketDrawer] = useState(false);
    const [basketEdit, setbasketEdit] = useState({});
    const [disclaimer, setDisclaimer] = useState('');
    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    const [canEditBasket, setCanEditBasket] = React.useState(true);
    const isMobile = window.innerWidth <= 760;

    const [start_date, setstart_date] = useState('2014-01-01')
    const [end_date, setend_date] = useState(formatDateyyyymmdd(new Date()))
    const [indices, setIndices] = React.useState([]);
    const [benchmarks, setBenchmarks] = React.useState([]);
    const [functions, setFunctions] = React.useState([]);
    const [functionMap, setFunctionMap] = React.useState([]);
    const [globals, setGlobals] = React.useState([]);
    const [updated_globals, setupdated_Globals] = React.useState([]);
    const [conditions, setConditions] = React.useState({
        "0_condition": {
            queries: {}
        }
    })
    const [conditions_oper, setConditionsOper] = React.useState([])
    const [initialParsedData, setinitialParsedData] = React.useState({})
    const [variables, setVariables] = React.useState({})
    const [childrenDrawerloading, setChildrenDrawerloading] = React.useState(false);
    const [childrenDrawer, setChildrenDrawer] = React.useState(false);
    const [selectedFund, setSelectedFund] = React.useState({})
    const [staticStrategyFunds, setstaticStrategyFunds] = React.useState([])
    const [fund_insight, setfund_Insights] = useState({})

    const [strategy_name, set_strategy_name] = useState('')
    const [strategy_desc, set_strategy_desc] = useState('')
    const [strategy_config, set_strategy_config] = useState({})
    const [paperTradeConfig, setPaperTradeConfig] = useState({});
    const [isRefreshRequired, setIsRefreshRequired] = useState(false)
    const [rebalanceOrder, setrebalanceOrder] = useState([])
    const [rebalanceStocks, setrebalanceStocks] = React.useState([])
    const [rebalanceCols, setrebalanceCols] = React.useState([])
    const [openManualRebalanceDrawer, setOpenManualRebalanceDrawer] = React.useState(false)
    const [showRebalModal, setshowRebalModal] = React.useState(false)

    const [showRebalModalLoading, setshowRebalModalLoading] = useState(false);
    const [rebalType, setRebalType] = useState(undefined); // manual-auto  or manual-custom

    const [loader, setLoader] = React.useState(true);
    const [isAdvance, setisAdvance] = React.useState('Advance');
    const [screenerFinalData, setscreenerFinalData] = React.useState([])
    const [allFuncAndMetricForDrawer, setallFuncAndMetricForDrawer] = React.useState([])
    const [nextDisabled, setnextDisabled] = React.useState(true);
    const [userClonedStrategiesNum, setUserClonedStrategiesNum] = React.useState(0);
    const [userTotalStrategies, setUserTotalStrategies] = React.useState(0);
    const [isLocked, setIsLocked] = React.useState(false);
    const [isCloned, setIsCloned] = React.useState(false);
    const { getUserStrategies, getUserCloneItems } = useUserDataHooks();
    const [allPublishers, setAllPublishers] = React.useState([]);
    const userPublisherProfile = allPublishers?.filter((i) => i?.ClientId === user?.id)?.[0] || {}
    const strategyPublisherProfile = allPublishers?.filter((i) => {
        if (Object.keys(fundDetails || {})?.length > 0) {
            if (fundDetails?.config?.is_published === 1) {
                return fundDetails?.config?.client_id === i?.ClientId;
            }
            else return false;
        }
        else return false;
    })?.[0] || {}
    console.log(strategyPublisherProfile, userPublisherProfile)
    let subscribeMiddle = useSubscribeMiddleware();

    const userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel || 0

    let userAccess = getUserAccessLimitFromAccessObj({
        accessObj: accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === 'CREDITS_PER_STRATEGY_IN_PAPERTRADE'),
        accessLevel: userAccesslevel
    });

    let user_initial_capital = userAccess || 100000

    const GetLTP = ({ fundData, symbol }) => {
        // return (stockprices?.[symbol]?.LastTradePrice || (stockprices?.[symbol]?.Close))
        const stockprices = useSelector((state) => state?.stockprices?.value);
        const prevstockprices = useSelector((state) => state?.prevStockPrices?.value);
        var changeVal, changePer, currentLTP = "-"
        var currentSymbolAllData = stockprices === "close" ? prevstockprices?.[fundData?.gdf_symbol] : stockprices?.[fundData?.gdf_symbol] || prevstockprices?.[fundData?.gdf_symbol]
        currentLTP = currentSymbolAllData?.LastTradePrice
        changeVal = currentSymbolAllData?.PriceChange
        changePer = currentSymbolAllData?.PriceChangePercentage

        if (currentSymbolAllData == undefined) {
            let ele = fundData
            currentLTP = ele?.price || 0
            changePer = ele?.one_day_pct || 0
            let originalPrice = currentLTP / (1 + (changePer / 100))
            changeVal = currentLTP - originalPrice;
        }


        return currentLTP
    }

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, title, desc, btn = null) => {
        const key = `open${Date.now()}`;
        // const btn = (
        //     <Space>
        //         <Button type="primary" size="small" onClick={() => api.destroy(key)}>
        //             Confirm
        //         </Button>
        //     </Space>
        // );
        api[type]({
            message: <span className="textMD w-500 dark4">{title}</span>,
            description: <span className="textXS w-500 dark4">{desc}</span>,
            placement: "topRight",
            key,
            btn
        });
    };

    let strategySaveAccess = subscribeMiddle({ moduleKey: "BUILD,_BACKTEST_AND_PAPERTRADE", getDetailedAccessData: true, checkLimit: true, usageCount: userTotalStrategies });
    let strategyCloneAccess = subscribeMiddle({ moduleKey: "CLONE_COMMUNITY_STRATEGIES", getDetailedAccessData: true, checkLimit: true, usageCount: userClonedStrategiesNum });

    const dataTableColumns = [
        type?.value === 4 ? {
            "title": "Name",
            'disableDeleteInCustomize': true,
            "dataIndex": "proper_name",
            render: (text, record, index) => <Tooltip className="tableItemName" title={record.proper_name} arrow={false} onClick={() => {
                setChildrenDrawer(!childrenDrawer)
                setSelectedFund(record)
            }}>
                <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center" }}>
                    <AMCIcon amcName={record.symbol} fundType={type.value} height='1.15rem' width="1.15rem" bseTicker={record?.bse_ticker} />
                    <div style={{ flex: 1 }}>
                        <div className="w-500 black textEllipsis1">{record?.proper_name}</div>
                        <div style={{ display: "flex", gap: "0.1rem", alignItems: "center" }}>
                            {/* <div> */}
                            <StockLivePrice fundData={record} symbol={record?.symbol} format={10} />
                            {/* </div> */}

                        </div>
                    </div>
                    <div className="text-left textXS primary tableItemIcon">
                        <TbDeviceDesktopAnalytics size={"1rem"} />
                    </div>
                </div>
            </Tooltip>,
            width: 300,
            fixed: window.innerWidth > 760 ? 'left' : "",
            show: true
        } : {
            "title": "Name",
            'disableDeleteInCustomize': true,
            "dataIndex": "basic_name",
            render: (text, record, index) => <Tooltip className="tableItemName" title={record.basic_name} arrow={false} onClick={async () => {
                setChildrenDrawerloading(true)
                setChildrenDrawer(!childrenDrawer)
                setSelectedFund(record)

                let res = await apiHelperPY({ apiName: 'getMFInsights', data: { planId: record?.plan_id, assetType: record?.primary_category_name?.toLowerCase() }, saveResponse: '' })
                setfund_Insights(res)
                if (res) {
                    setfund_Insights(res)
                    setChildrenDrawerloading(false)
                }
                else {
                    setChildrenDrawerloading(false)
                    setfund_Insights([])
                }


            }}>
                <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center", width: "100%" }}>
                    <AMCIcon amcName={record.amc_full_name} height='1.15rem' width="1.15rem" />
                    <div style={{ flex: 1 }}>
                        <div className="w-500 black textEllipsis1" >{record?.basic_name?.replace(" - Direct Plan", "")}</div>
                        {/* <div style={{ display: "flex", gap: "0.1rem", alignItems: "center" }}>
                            
                            <StockLivePrice fundData={record} symbol={record?.symbol} format={10} />
                           

                        </div> */}
                    </div>
                    <div className="text-left textXS primary tableItemIcon">
                        <TbDeviceDesktopAnalytics size={"1rem"} />
                    </div>
                </div>
            </Tooltip>,
            width: 400,
            fixed: window.innerWidth > 760 ? 'left' : "",
            show: true
        },
        {
            "title": "Weights",
            "dataIndex": "weights",
            render: (text, record, index) => DecimalValueFormat({ num: type?.value === 4 ? text : Number(text) * 100, suffix: '%', placeholder: '-' }),
            width: 120,
            align: 'right',
            show: true
        },
        {
            "title": "Quantity",
            "dataIndex": "quantity",
            render: (text, record, index) => DecimalValueFormat({ num: text, suffix: '', placeholder: '-', decimals: type?.value !== 2 ? 0 : 2 }),
            width: 120,
            align: 'right',
            show: true
        }
    ]

    const noBacktestVal = React.useMemo(() => {
        let temp = MINTBOX_TYPE === 2 ? (fundDetails?.config?.instrument_method === '1' ? 1 : 0) : "";
        return temp;
    }, [fundDetails, MINTBOX_TYPE])

    const metric_data = (key, value) => {
        return screenerMetric.filter((met) => met[key] === value)[0]
    }

    const decryptCode = async (code, screenerMetrics, functions, config, allFunds) => {
        var res = await codeParserWrapper({
            code, screenerMetrics, functions
        })
        // ////console.log("logger", res)
        try {
            setConditions(res?.conditions)
            setConditionsOper(res?.conditionOperators)
            setGlobals(res?.globals)
            setupdated_Globals(res?.globals)
            setVariables(res?.variables)
            setinitialParsedData(res)
            var finalSelectedStocks = (res?.staticStrategyData || []).map((i) => {
                return {
                    ...[...allFunds].filter((ii) => (ii?.symbol || ii?.nse_symbol || ii?.isin_code) === i)[0],
                    allocation: config?.position_sizing?.method === "W-CT" ? Number(config?.position_sizing?.weight_list?.[i]?.toFixed(1)) * 100 : null
                }
            })

            if (finalSelectedStocks?.length > 0 && config?.position_sizing?.method !== "W-CT") {
                var data = {
                    syms: res?.staticStrategyData,
                    position_sizing: JSON.stringify(config?.position_sizing)
                }

                // try {
                //     delete data?.position_sizing?.limit
                // } catch (error) {

                // }

                const res2 = await AdminServicesPY.get_weights_and_min_inv(data).then(res => res)

                ////console.log("logger", res?.staticStrategyData, finalSelectedStocks)
                var weights = JSON.parse(res2?.data)
                finalSelectedStocks = finalSelectedStocks.map((obj, index) => ({
                    ...obj,
                    allocation: (Number(weights?.filter((i) => i?.symbol === (obj?.symbol || obj?.nse_symbol || obj?.isin_code))?.[0]?.weights) * 100)?.toFixed(1),
                    locked: true,
                    index
                }))
                ////console.log("logger", data, finalSelectedStocks)
                setstaticStrategyFunds(finalSelectedStocks)

            } else {
                setstaticStrategyFunds(finalSelectedStocks)
            }
        } catch (error) {
            ////console.log("logger", error)
        }
    }
    // const hiddenMetrics = ['is_nifty_50', 'is_nifty_100', 'is_nifty_500', 'is_nifty_midcap100', 'is_nifty_smallcap100'];
    const hiddenMetrics = [];

    const dem = async () => {
        setLoading(true);
        let res2 = await AdminServicesPY.getStrategyDetails(MINTBOX_ID).then((r) => r)
        res2 = res2?.data
        if (res2) {
            // res2 = res2
            // //////console.log('getCuratedPortfolioDetails fundDetails', JSON.parse(res2.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null)))
            var fundD = JSON.parse(res2.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null)?.replaceAll(/null/g, null))
            console.log(fundD)
            setfundDetails(fundD)
            set_strategy_config(fundD?.config)
            set_strategy_name(fundD?.config?.strategy_name)
            set_strategy_desc(fundD?.config?.strategy_desc)

            res2 = fundD
        }
        else {
            setfundDetails({})
        }
        let func_map = await AdminServicesPY.loadFunctionMap().then((r) => r)
        func_map = JSON.parse(func_map?.data?.replace(/NaN/g, null)?.replace(/Infinity/g, null))
        func_map = func_map?.filter((el) => el?.sh_name !== 'mavp')

        var screener_datas = await screenerMetricData(type,
            Number(fundD?.instrument_selection) === 1 ? 1 : 0,
            Number(fundD?.instrument_selection) === 2 ? 1 : 0,
            1,
            Number(fundD?.instrument_selection) === 2 ? 1 : 0)

        if (type?.value === 4) {
            var results = screener_datas?.allFunds?.sort((a, b) => b?.mcap - a?.mcap) || [];
            setallFundsData(results)
        } else {
            var results = screener_datas?.allFunds?.sort((a, b) => b?.aum - a?.aum) || [];
            setallFundsData(results)
        }
        let strategyRes = await getUserStrategies();
        setUserTotalStrategies(strategyRes?.userStrategies?.length || 0);
        let cloneRes = await getUserCloneItems();
        if (cloneRes) {
            setUserClonedStrategiesNum(cloneRes?.strategies?.clonedStrategiesInMonth?.length || 0);
            let checkIsCloned = cloneRes?.strategies?.clonedStrategiesInMonth?.find((el) => String(el?.TypeId) === String(MINTBOX_ID));
            if (checkIsCloned) {
                setIsCloned(true);
            }
            else {
                setIsCloned(false);
            }
        }
        else {
            setUserClonedStrategiesNum(0);
            setIsCloned(false);
        }
        let checkIfLocked = strategyRes?.userStrategies?.find((el) => el?.config?.[`_id`] === MINTBOX_ID)?.is_locked === 1;
        if (checkIfLocked) {
            setIsLocked(true);
        }
        else {
            setIsLocked(false);
        }
        setscreener_data_final(screener_datas);
        let mets = screener_datas?.screenerMetricData;
        mets = mets?.map((el) => {
            var selectedCategory = screener_datas?.screenerFinalData?.filter((i) => i?.category_id === el?.category_id)?.[0] || {}
            var selectedSubCategory = selectedCategory?.sub_category?.filter((i) => i?.sub_category_id === el?.sub_category_id)?.[0] || {}
            return {
                ...el,
                type: el?.type || "Metric",
                // label: el?.label?.replace(/\s/g, ''),
                category_name: selectedCategory?.category_name,
                sub_category_name: selectedSubCategory?.sub_category_name,
            }
        });
        mets = mets?.filter((el) => !hiddenMetrics.includes(el?.dataIndex))
        // ////console.log("logger", mets, screener_datas)
        setscreenerMetric(mets);
        setscreenerFinalData(screener_datas?.screenerFinalData)
        setScreenerMetricDescData(screener_datas?.screenerMetricDescData);
        ////console.log("ds", screener_datas?.allFunds)
        setallFilteredFundsData(screener_datas?.allFunds)
        setLoading(false)
        decryptCode(res2?.config?.code, screener_datas?.screenerMetricData, func_map, res2?.config, screener_datas?.allFunds)
        let indices = await getIndexList();
        let benchmarks = await fetchFundData(11);
        setBenchmarks(benchmarks);
        setIndices([{
            IndexNameCaps: "all",
            IndexName: "All",
            key: "all"
        }].concat(indices));
        setFunctions(func_map)
        setFunctionMap(groupBy(func_map, "type"));
        var ak2 = groupBy(func_map, "type")
        ak2 = Object?.entries(ak2)?.map(([key, val], index) => {
            return {
                [key]: Object?.entries(groupBy(val, "sub_type"))?.map(([key, val], index) => {
                    return {
                        category_id: index + 1,
                        category_name: key,
                        sub_category: [{
                            sub_category_id: index + 1,
                            sub_category_name: '',
                            metric: val?.map((item) => {
                                return { ...item, key: item?.sh_name, label: item?.display_name, value: item?.sh_name, type: item?.type }
                            })
                        }]
                    }
                })
            }
        })

        //////console.log("akkkka", ak2)

        var final = {
            "Metrics": screener_datas?.screenerFinalData,
            ...Object.assign({}, ...ak2)
        }

        //////console.log("akkkka", final)
        setallFuncAndMetricForDrawer(final)



        // parse code
    }

    const backtestWeightMethods = [
        { key: 1, label: "Allocate equal weight", value: "W-EQ" },
        { key: 3, label: "Weight by metric", value: "W-VAR", "show": type?.value === 4 },
        { key: 2, label: "Manual weights", value: "W-CT", "show": strategy_config?.instrument_selection === 1 },
    ]
    // const [backtestWeightMethods, setweight_methods] = React.useState(backtestWeightMethods);

    const [sum, setSum] = React.useState(100)
    const [weight_method, setweight_method] = React.useState(strategy_config?.position_sizing?.method);
    const [weight_by, setweight_by] = React.useState(strategy_config?.position_sizing?.weight_by || "");
    const [calculatedWeights, setcalculatedWeights] = React.useState([])
    const [listLoading, setlistLoading] = React.useState(false);

    const [triggerBacktest, settriggerBacktest] = React.useState(false);

    const getPublisherProfiles = async () => {
        let allPublishers = await apiHelperPY({ apiName: "getAllPublisherProfile", saveResponse: "sessionStorage" });
        allPublishers = JSON.parse(allPublishers);
        setAllPublishers(allPublishers);
    }

    React.useEffect(() => {
        getPublisherProfiles();
    }, []);

    React.useEffect(() => {
        if (Object.keys(strategy_config)?.includes("position_sizing")) {
            setweight_method(strategy_config?.position_sizing?.method)
            setweight_by(strategy_config?.position_sizing?.weight_by || "")
        }
    }, [strategy_config])



    // change can be in weight method , meight by or list of funds
    const noChangeInNameOrDesc = React.useCallback(() => {
        var orignalConfig = { ...fundDetails?.config }
        var updatedConfig = { ...strategy_config }
        var alsoNeedToCompareKeys = ["schedule", "benchmark_id", "benchmark_name", "sell_on_surveillance", "fill_on", "on_no_signal"]
        var temp = alsoNeedToCompareKeys?.map((i) => {
            return JSON.stringify(orignalConfig?.[i]) === JSON.stringify(updatedConfig?.[i])
        })
        return (strategy_name === fundDetails?.config?.strategy_name) &&
            (strategy_desc === fundDetails?.config?.strategy_desc) &&
            (temp?.filter((i) => i === true)?.length === alsoNeedToCompareKeys?.length)
    }, [strategy_name, strategy_desc, strategy_config])


    const noChangeInRiskManagement = React.useCallback(() => {
        var orignalConfig = { ...fundDetails?.config }
        var updatedConfig = { ...strategy_config }

        return (JSON.stringify(orignalConfig?.["risk_management"]) === JSON.stringify(updatedConfig?.["risk_management"]))
    }, [strategy_config])

    const noChangeInStaticStrategy = React.useCallback(() => {
        var orignalConfig = { ...fundDetails?.config }
        var updatedConfig = { ...strategy_config }


        var noNeedToCompareKeys = ["schedule", "benchmark_id", "benchmark_name", "sell_on_surveillance", "fill_on", "on_no_signal"]

        var temp = noNeedToCompareKeys?.map((i) => {
            delete orignalConfig?.[i]
            delete updatedConfig?.[i]
            return true
        })
        //console.log("akakakaka", orignalConfig, updatedConfig, strategy_config)
        return ((JSON.stringify(orignalConfig) === JSON.stringify(updatedConfig)) &&
            (JSON.stringify(updated_globals) === JSON.stringify(globals)) &&
            (weight_method === orignalConfig?.position_sizing?.method) &&
            (weight_by === (orignalConfig?.position_sizing?.weight_by || "")) &&
            (type?.value === 4 ? staticStrategyFunds?.map((i) => i?.symbol)?.every(v => initialParsedData?.staticStrategyData.includes(v)) : type?.value === 1 ? staticStrategyFunds?.map((i) => i?.nse_symbol)?.every(v => initialParsedData?.staticStrategyData.includes(v)) : staticStrategyFunds?.map((i) => i?.isin_code)?.every(v => initialParsedData?.staticStrategyData.includes(v))) &&
            (initialParsedData?.staticStrategyData?.length === staticStrategyFunds?.length))
    }, [strategy_config, weight_method, weight_by, staticStrategyFunds]);


    const noChangeInScreenFactorBasedStrategy = React.useCallback(() => {
        var orignalConfig = { ...fundDetails?.config }
        var updatedConfig = { ...strategy_config }


        var noNeedToCompareKeys = ["schedule", "benchmark_id", "benchmark_name", "sell_on_surveillance", "fill_on", "on_no_signal"]

        var temp = noNeedToCompareKeys?.map((i) => {
            delete orignalConfig?.[i]
            delete updatedConfig?.[i]
            return true
        })

        //console.log("akakakaka", orignalConfig, updatedConfig, strategy_config)

        return ((JSON.stringify(orignalConfig) === JSON.stringify(updatedConfig)) &&
            (JSON.stringify(updated_globals) === JSON.stringify(globals)))
    }, [strategy_config]);

    var nameOrDescChange = noChangeInNameOrDesc()
    var strategyParamOrCodeChange = (fundDetails?.config?.instrument_selection === 1 ? noChangeInStaticStrategy() : noChangeInScreenFactorBasedStrategy())
    var risk_management_changed = noChangeInRiskManagement()
    var needToUpdate = !(nameOrDescChange && strategyParamOrCodeChange)

    // // console.log(nameOrDescChange, strategyParamOrCodeChange)

    const updateStrategyParams = async (config, type = 0) => {
        // type === 2  (only publish)
        if (type === 2) {
            var res = await AdminServicesPY.updateStrategyParams({
                config: JSON.stringify({
                    ...config,
                    strategy_name: strategy_name,
                    strategy_desc: strategy_desc,
                    'is_published': 2
                })
            }).then((r) => r)

            if (!JSON.stringify(res)?.includes("Failed")) {
                let data = {
                    "clientId": user?.id,
                    "publisherId": userPublisherProfile?.UID,
                    "type": "STRATEGY",
                    "typeId": MINTBOX_ID,
                    "publishedDate": new Date().toISOString(),
                    "isPublished": 1,
                    "isDeleted": 0
                }
                await apiHelperPY({ apiName: "getAllStrategiesByClientId", data: { clientId: user.id } })
                await apiHelper({ apiName: "addPublishedStrategy", data: { data } });
                openNotificationWithIcon("info", "Strategy updated", `Your publish request has been registered and your strategy is under review. 
                If your strategy meets all our internal criteria, it will be approved within 24-48 hours and be visible to other users.`)
                navigate(`/strategy-detail/${String(strategy_name)?.toLowerCase()?.replaceAll(" ", "-")}/${MINTBOX_TYPE}/${MINTBOX_ID}`)
                setIsRefreshRequired(false)
                dem()
            } else {
                toast.error("Something went wrong!!")
            }
        } else {
            if (config?.cloned_from) {
                config.linked_to_clone = 0;
            }
            if (strategy_config?.instrument_selection === 1) {
                var weight_list = {}
                if (weight_method === "W-CT") {
                    var a = staticStrategyFunds?.map((el) => {
                        weight_list[el?.symbol || el?.nse_symbol || el?.isin_code] = Number(el?.allocation) / 100
                    })
                }
                var code = `selected_symbols = symbol is_in [${staticStrategyFunds?.map((el) => `"${el?.symbol || el?.nse_symbol || el?.isin_code}"`)}];output = selected_symbols;`
                var res = await AdminServicesPY.updateStrategyParams({
                    risk_management_updated: !risk_management_changed,
                    config: JSON.stringify({
                        ...config,
                        "position_sizing": weight_method === "W-EQ" ? {
                            'method': weight_method
                        } : weight_method === "W-VAR" ? {
                            'method': weight_method,
                            'weight_by': weight_by
                        } : {
                            'method': weight_method,
                            'weight_list': weight_method === "W-CT" ? weight_list : []
                        },
                        strategy_name: strategy_name,
                        strategy_desc: strategy_desc,
                        'strategy_type_id': config?.schedule?.freq !== "NA" ? 1 : 2,
                        "code": code
                    })
                }).then((r) => r)

                if (!JSON.stringify(res)?.includes("Failed")) {
                    await apiHelperPY({ apiName: "getAllStrategiesByClientId", data: { clientId: user.id } })
                    toast.success("Your strategy updated successfully!!")
                    if (!strategyParamOrCodeChange) {
                        setIsRefreshRequired(true)
                    } else {
                        navigate(`/strategy-detail/${String(strategy_name)?.toLowerCase()?.replaceAll(" ", "-")}/${MINTBOX_TYPE}/${MINTBOX_ID}`)
                        // //seteditBasketDrawer(false)
                        setIsRefreshRequired(false)
                        dem()
                    }

                } else {
                    toast.error("Something went wrong!!")
                }
            } else {
                var res = await AdminServicesPY.updateStrategyParams({
                    risk_management_updated: !risk_management_changed,
                    config: JSON.stringify({
                        ...config,
                        strategy_name: strategy_name,
                        strategy_desc: strategy_desc,
                        'strategy_type_id': config?.schedule?.freq !== "NA" ? 1 : 2,
                    })
                }).then((r) => r)

                if (!JSON.stringify(res)?.includes("Failed")) {
                    await apiHelperPY({ apiName: "getAllStrategiesByClientId", data: { clientId: user.id } })
                    toast.success("Your strategy updated successfully!!")
                    if (!strategyParamOrCodeChange) {
                        setIsRefreshRequired(true)
                    } else {
                        navigate(`/strategy-detail/${String(strategy_name)?.toLowerCase()?.replaceAll(" ", "-")}/${MINTBOX_TYPE}/${MINTBOX_ID}`)
                        // //seteditBasketDrawer(false)
                        setIsRefreshRequired(false)
                        dem()
                    }

                } else {
                    toast.error("Something went wrong!!")
                }

            }
        }
    }

    const generateRebalOrder = async (calType = 1) => {
        // //////console.log("wdsaff")
        try {
            var res = await AdminServicesPY.generateRebalanceOrder(MINTBOX_ID).then((r) => r)
            console.log(res?.data)
            if (res?.type) {
                setrebalanceOrder(JSON.parse(res?.data))
                if (JSON.parse(res?.data)?.orders?.length > 0) {
                    var orders = JSON.parse(res?.data)?.orders
                    setrebalanceStocks((orders || []).map((i) => {
                        ////////console.log(i)
                        return {
                            ...[...allFundsData].filter((ii) => type?.value === 4 ? ii?.symbol === i?.symbol : type?.value === 1 ? ii.nse_symbol === i.symbol : ii?.isin_code === i?.symbol)[0],
                            ...i,
                        }
                    }))

                    setrebalanceCols((Object.keys(orders?.[0])?.filter((i) => !(['symbol', 'mcap', 'entry_price', 'min_inv_amount']?.includes(i))))?.map((item) => {
                        ////////console.log(typeof (orders?.[0]?.[item]))
                        return {
                            "dataIndex": item,
                            "key": item,
                            "metric_col_code": item,
                            "metric_unit": "",
                            "metric_data_type": typeof (orders?.[0]?.[item]),
                            "metric_name": item,
                            "title": <span style={{ textTransform: "capitalize" }}>{String(item)?.replace("_", " ")}</span>,
                            "align": "center",
                            "width": 150,
                        }
                    }))
                }
                if (calType === 2) {
                    setTimeout(() => {
                        setshowRebalModalLoading(false)
                    }, 100);

                }
            } else {
                toast.error("Something went wrong while generating the order. Please try again later!!")
            }
        } catch (error) {
            console.log("err", error)
        }
    }

    const placeRebalanceOrder = async () => {
        var res = await AdminServicesPY.rebalanceStrategy(MINTBOX_ID, {
            orders: JSON.stringify(rebalanceOrder?.orders)
        })?.then((r) => r)
        if (res?.type) {
            toast.success(JSON.parse(res?.data?.replace(/NaN/g, null)?.replace(/Infinity/g, null))?.message)
            navigate(`/strategy-detail/${String(strategy_name)?.toLowerCase()?.replaceAll(" ", "-")}/${MINTBOX_TYPE}/${MINTBOX_ID}`)
            // //seteditBasketDrawer(false)
            setIsRefreshRequired(false)
            setrebalanceStocks([])
            dem()
        } else {
            toast.error("Something went wrong!!")
        }
        //////console.log(res)
    }

    const deleteStrategy = async () => {
        let res = await apiHelperPY({ apiName: "deleteStrategy", data: { id: MINTBOX_ID } })
        if (res) {
            toast.error('Strategy deleted!');
            navigate('/strategies');
        }
        else {
            toast.error('Something went wrong!');
        }
    }

    React.useEffect(() => {
        // alert("sad")
        dem();
    }, [MINTBOX_ID, user])

    const NoBTData = () => {
        return (
            <Card>
                {/* <NoBackTestData val={MINTBOX_TYPE === 2 ? (
                    fundDetails?.config?.instrument_method === '1' ? 1 : 0
                ) : ""} /> */}
                <NoDataFound />
            </Card>
        )
    }

    const SectorAllocationTable = ({ data, header }) => {
        const options1 = [
            {
                key: 'sector',
                name: 'Sector'
            },
            {
                key: 'industry_group',
                name: 'Industry group'
            },
            {
                key: 'industry',
                name: 'Industry'
            },
        ]
        const [selected, setSelected] = React.useState(options1[0])

        const dataSource = fundDetails?.analytics?.[selected.key]?.sort((a, b) => b?.weights - a?.weights);
        const columns = [{
            title: selected.name,
            dataIndex: 'descs',
            width: 250,
            fixed: 'left',
            render: (text, record) => text
        },
        {
            title: 'Alocation',
            dataIndex: 'weights',
            render: (text) => TableProgressBarFormat({ num: text })
        }
        ]

        return <>
            <CustomSegmentedTab isBlock={false} value={selected.name} options={options1.map(m => m.name)}
                onChange={(e) => setSelected(options1.find(o => o.name === e))} />
            <div style={{ display: 'flex', gap: '1rem', marginTop: "1.25rem" }}>
                <div>
                    <MyTable style={{}} dataSource={dataSource} columns={columns} className='textXS black' pagination={{ hideOnSinglePage: true, pageSize: 50 }} />
                </div>
            </div>
        </>

    }

    const tradeLogsColumns = [
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
            width: 350,
            fixed: 'left',
            render: (text, obj) => {
                return <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                    <span>{type?.value === 2 ? allFundsData?.filter((i) => i?.isin_code === text)?.[0]?.basic_name?.replace(" - Direct Plan", "") : text}</span>
                    <span>
                        <Tag className="textXS" color={"var(--grey2)"} style={{ fontSize: 10, color: "var(--dark4)" }}>{obj?.tag}</Tag>
                        <Tag className="textXS" color={obj?.direction === "BUY" ? "var(--greenShade)" : "var(--redShade)"} style={{ fontSize: 10, color: obj?.direction === "BUY" ? "var(--green)" : "var(--red)" }}>{String(obj?.direction)?.charAt(0)}</Tag>
                        {obj?.remark && <Tag className="textXS" color={"var(--grey2)"} style={{ fontSize: 10, color: "var(--dark4)" }}>{obj?.remark}</Tag>}
                    </span>
                </div>
            }
        },
        {
            title: 'Fill Price',
            dataIndex: 'fill_price',
            key: 'fill_price',
            align: "right",
            render: (text) => CurrencyFormat(Number(text))
        },
        {
            title: 'Order Quantity',
            dataIndex: 'order_quantity',
            key: 'order_quantity',
            // width: 410,
            align: "right",
            render: (text) => DecimalValueFormat({ num: text, suffix: "", placeholder: "-", decimals: 0 })
        },
        {
            title: 'Fill Quantity',
            dataIndex: 'fill_quantity',
            key: 'fill_quantity',
            // width: 410,
            align: "right",
            render: (text) => DecimalValueFormat({ num: text, suffix: "", placeholder: "-", decimals: 0 })
        },
        {
            title: 'Profit / Loss',
            dataIndex: 'pnl',
            key: 'pnl',
            align: "right",
            render: (text) => text ? <RedGreenText text={CurrencyFormat(Number(text))} value={Number(text)} /> : "-"
        },
        {
            title: 'Returns',
            dataIndex: 'ret',
            key: 'ret',
            align: "right",
            render: (text) => text ? <RedGreenText text={DecimalValueFormat({ num: text, suffix: "%", placeholder: "-" })} value={Number(text)} /> : "-"
        },
        {
            title: 'Non broker charges',
            dataIndex: 'statutory_charges',
            key: 'statutory_charges',
            align: "right",
            render: (text) => text ? CurrencyFormat(Number(text)) : "-"
        },
        {
            title: 'Brokerage',
            dataIndex: 'brokerage',
            key: 'brokerage',
            align: "right",
            render: (text) => text ? CurrencyFormat(Number(text)) : "-"
        },
    ]

    const TradeData = ({ rebalData }) => {
        const dataSource = sortFunds(rebalData, "order_quantity")
        // //console.log(rebalData)

        return <div>
            <Table locale={{
                emptyText: <></>
            }} dataSource={dataSource} columns={tradeLogsColumns} scroll={{ x: 'max-content' }} size={'small'} bordered className='textSM black my-2' pagination={false} />
        </div >
    }

    const CustomAccordionItem2 = ({ x }) => {

        return <Collapse defaultActiveKey={[0]} expandIcon={({ isActive }) => <IoIosArrowDown style={{
            transition: "all 0.3s linear", transform: isActive ? "rotate(180deg)" : "rotate(0deg)", color: "var(--black)", fontSize: "0.875rem"
        }} />} className='tradeLogCollapse' expandIconPosition='end' items={[
            {
                key: x.index,
                label: <div className="textSM w-500 black" >{x.header}</div>,
                children: <TradeData rebalData={x?.dataAll?.map((i) => {
                    return {
                        ...i,
                        "date": x?.Date
                    }
                })} />,
            }
        ]} style={{ background: "var(--white)" }} bordered={false} />

    }

    const TradeLogs = React.memo(() => {
        const result = fundDetails?.trade_log?.trades;
        return result !== undefined ?
            <div className='mt-4'>
                <Timeline items={Object.entries(result)?.reverse()?.map(([date, rebalData], index1) => {
                    return {
                        children: <div>
                            <CustomAccordionItem2 x={{
                                "Date": date,
                                "header": "Trades on " + formatDateWithTime(date),
                                "key": date + "" + index1,
                                "index": index1,
                                "dataAll": rebalData
                            }}
                            />
                        </div>
                    }
                })} />
            </div>
            : <NoDataFound />

    })

    const instrumentOptions = [
        {
            title: 'Stock basket',
            desc: 'Select your favourite stocks, define weights and rebalancing schedule and you are all set. In paper trading, your strategy will be automatically rebalanced as per your rules. In live trading, your will be notified to carry out the order execution.',
            learnText: 'Learn more about creating stock baskets',
            blogLink: '',
            videoLink: '',
            instrumentType: 4,
            method: 1,
        }, {
            title: 'Dynamic strategy using stock screener',
            desc: 'In this strategy, a screener will used for dynamic stock selection. You can define weights, rebalancing schedule and other parameters to further finetune your strategy. In paper trading, your strategy will be automatically rebalanced as per your rules. In live trading, your will be notified to carry out the order execution.',
            learnText: 'Learn more about creating dynamic strategies using stock screener',
            blogLink: '',
            videoLink: '',
            instrumentType: 4,
            method: 2,
        }, {
            title: 'Dynamic strategy using factor model',
            desc: 'In this strategy, a factor model will be used for dynamic stock selection. You can define weights, rebalancing schedule and other parameters to further finetune your strategy. In paper trading, your strategy will be automatically rebalanced as per your rules. In live trading, your will be notified to carry out the order execution.',
            learnText: 'Learn more about creating dynamic strategies using factor model',
            blogLink: '',
            videoLink: '',
            instrumentType: 4,
            method: 3,
        },
        {
            title: 'ETF basket',
            desc: 'Select your favourite ETFs, define weights and rebalancing schedule and you are all set. In paper trading, your strategy will be automatically rebalanced as per your rules. In live trading, your will be notified to carry out the order execution.',
            learnText: 'Learn more about creating ETF baskets',
            blogLink: '',
            videoLink: '',
            instrumentType: 1,
            method: 1,
        }
        , {
            title: 'MF basket',
            desc: 'Select your favourite MFs, define weights and you are all set. Your strategy will not be rebalanced. You can trigger manual rebalance or buy/sell individual MFs any time. ',
            learnText: 'Learn more about creating MF baskets',
            blogLink: '',
            videoLink: '',
            instrumentType: 2,
            method: 1,
        }
    ]

    const AssetAllocation = ({ }) => {
        console.log(fundDetails)
        var data = (type?.value === 4 ? fundDetails?.analytics?.mcap_segment : fundDetails?.analytics?.asset_allocation) || {}

        if (type?.value === 4) {
            var dd = ["LARGE", "MID", "SMALL"]
            const dataSource = dd?.map((f, i) => {
                return {
                    'Asset': f?.[0]?.toUpperCase() + f?.substring(1)?.toLowerCase(),
                    'Allocation': Number(Number(data?.find((i) => i?.mcap_segment?.toUpperCase() === f)?.weights || 0)?.toFixed(1)),
                    "Color": f?.toUpperCase() === "LARGE" ? "var(--primary)" : f?.toUpperCase() === "MID" ? "var(--orange)" : "var(--green)"
                }
            })?.sort((a, b) => b.Allocation - a.Allocation);
            return <div className="my-2" style={{ display: 'flex', gap: '1rem', alignItems: "center", justifyContent: "space-between" }}>
                {dataSource?.map((i) => {
                    return <div style={{ display: "flex", flex: 1, alignItems: "center", flexDirection: "column", gap: "0.2rem" }}>
                        <Progress status="normal" strokeWidth={10} format={(f) => <span className="textXS w-bold">{Number(f.toFixed(1))}%</span>} strokeColor={i?.Color} type="circle" size={"small"} percent={i?.Allocation} />
                        <div className="textXS w-500" style={{ textAlign: "center" }}>{i?.Asset}</div>
                    </div>
                })}
            </div>
        } else {
            if (!Object.values(data)?.length) {
                return <Card><NoDataFound message='Not enough data!' /></Card>
            }
            const dataSource = Object.keys(data || {}).map((f, i) => {
                return {
                    'Asset': f[0]?.toUpperCase() + f.substring(1)?.toLowerCase(),
                    'Allocation': Number(Number(data[f])?.toFixed(1)),
                    "Color": f === "equity" ? "var(--primary)" : f === "debt" ? "var(--orange)" : "var(--green)"
                }
            })?.filter(x => x.Allocation)?.sort((a, b) => b.Allocation - a.Allocation);

            return <div className="my-2" style={{ display: 'flex', gap: '1rem', alignItems: "center", justifyContent: "space-between" }}>
                {dataSource?.map((i) => {
                    return <div style={{ display: "flex", flex: 1, alignItems: "center", flexDirection: "column", gap: "0.2rem" }}>
                        <Progress status="normal" strokeWidth={10} format={(f) => <span className="textXS w-bold">{Number(f.toFixed(1))}%</span>} strokeColor={i?.Color} type="circle" size={"small"} percent={i?.Allocation} />
                        <div className="textXS w-500" style={{ textAlign: "center" }}>{i?.Asset}</div>
                    </div>
                })}
            </div>
        }
    }

    // console.log("dsfdsf", fundDetails?.perfromance_tables?.perf_summary?.Strategy?.CAGR)
    const fundDetCardsData = [
        { label: "Initial capital", value: CurrencyFormat(fundDetails?.config?.initial_capital, "long", 0), showIcon: false, show: strategy_config?.is_live !== 1 },
        {
            label: `Created on`,
            value: formatDate(fundDetails?.config?.go_live_timestamp) || '-', showIcon: false
        },
        {
            label: "XIRR",
            value: (monthDiff(new Date(fundDetails?.config?.go_live_timestamp), new Date())) <= 1 ? "-" : DecimalValueFormat({ num: Number(fundDetails?.xirr || fundDetails?.perfromance_tables?.perf_summary?.Strategy?.CAGR?.replace("%", "")), suffix: "%" }),
            showIcon: true,
            helperText: "XIRR is only shown after strategy has completed 30 days"
        },
        { label: "Absolute return", value: DecimalValueFormat({ num: fundDetails?.abs_change_per, suffix: "%" }), showIcon: false },
        { label: "Total P&L", value: CurrencyFormat(fundDetails?.pnl), showIcon: false, show: strategy_config?.is_live === 1 },
        { label: "Avg. expense ratio", value: DecimalValueFormat({ num: fundDetails?.analytics?.expense, suffix: "%" }), show: type?.value !== 4, showIcon: false },
        {
            label: type?.value === 4 ? "P/E ratio" : "Portfolio PE", value: DecimalValueFormat({
                num: fundDetails?.analytics?.top_box_summary?.pe || fundDetails?.analytics?.factsheet?.fundamentals?.pe, suffix: ""
            }), showIcon: false
        },
        { label: "P/B ratio", value: DecimalValueFormat({ num: fundDetails?.analytics?.top_box_summary?.pb, suffix: "" }), show: type?.value == 4, showIcon: false },
        { label: `Number of ${String(type?.dname)?.toLowerCase()}`, value: type?.value === 4 ? fundDetails?.analytics?.holdings?.length : fundDetails?.analytics?.constituents?.length || '-', showIcon: false },
        { label: "Last rebalanced on", value: formatDate(fundDetails?.config?.last_rebal_date) || '-', showIcon: false },
        { label: "Rebalance due on", value: formatDate(fundDetails?.config?.next_rebal_date) || '-', showIcon: false, show: fundDetails?.config?.strategy_type_id !== 2 },

        { label: type?.value === 4 ? "Benchmark" : "Performance benchmark", value: benchmarks?.length > 0 ? benchmarks?.filter((i) => i?.benchmark_id === fundDetails?.config?.benchmark_id)?.[0]?.benchmark_name || '-' : "-", isFull: true, showIcon: false },

        // { label: "Methodology", value: instrumentOptions?.filter((i) => i?.instrumentType === type?.value && i?.method === fundDetails?.config?.instrument_selection)?.[0]?.title || "-", isFull: true, show: type?.value == 4, showIcon: false },

        {
            label: "Allocation", value: <AssetAllocation />, isFull: true, showIcon: false
        },
    ]

    const [basketOverviewModal, setBasketOverviewModal] = useState(false);
    const [editBasketModel, seteditBasketModel] = useState(false);


    console.log(strategy_config);
    const [cloneModal, setCloneModal] = React.useState(false)
    const [cloneOrder, setCloneOrder] = React.useState([])
    const [cloneOrderCols, setcloneOrderCols] = React.useState([])

    const [paperTradeData, setPaperTradeData] = React.useState({});

    let strategyDetailedAccess = subscribeMiddle({ moduleKey: "BUILD,_BACKTEST_AND_PAPERTRADE", getDetailedAccessData: true, usageCount: userTotalStrategies, checkLimit: true });
    console.log(strategyDetailedAccess);

    const generateCloneOrder = async () => {
        // //////console.log("wdsaff")
        setCloneModal(true);
        var config = {
            ...strategy_config,
            "client_id": user?.id,
            "cloned_from": strategy_config?._id,
            "strategy_id": 0,
            "is_published": 0,
            "linked_to_clone": 1
        }
        delete config?.["_id"]
        var res = await AdminServicesPY.getPaperTradeOrder({
            config: JSON.stringify(config),
            // initial_capital: user_initial_capital,
        })
        setPaperTradeData(JSON.parse(res?.data))
        if (JSON.parse(res?.data)?.orders?.length > 0) {
            var orders = JSON.parse(res?.data)?.orders
            setCloneOrder((orders || []).map((i) => {
                ////console.log(i)
                return {
                    ...[...allFundsData].filter((ii) => type?.value === 4 ? ii?.symbol === i?.symbol : type?.value === 1 ? ii.nse_symbol === i.symbol : ii?.isin_code === i?.symbol)[0],
                    ...i,
                }
            }))

            setcloneOrderCols((Object.keys(orders?.[0])?.filter((i) => !(['symbol', 'mcap', 'entry_price', 'min_inv_amount']?.includes(i))))?.map((item) => {
                ////console.log(typeof (orders?.[0]?.[item]))
                return {
                    "dataIndex": item,
                    "key": item,
                    "metric_col_code": item,
                    "metric_unit": "",
                    "metric_data_type": typeof (orders?.[0]?.[item]),
                    "metric_name": item,
                    "title": <span style={{ textTransform: "capitalize" }}>{String(item)?.replace("_", " ")}</span>,
                    "align": "center",
                    "width": 100,
                }
            }))
        }
    }

    const placeOrderAndSaveStrategy = async (clone_strategy_name, clone_strategy_desc) => {
        var config = {
            ...strategy_config,
            "client_id": user?.id,
            // "initial_capital": user_initial_capital,
            "cloned_from": strategy_config?._id,
            "strategy_id": 0,
            "is_published": 0,
            "linked_to_clone": 1,
            "last_edit_date": null
        }
        delete config?.["_id"]
        var res = await AdminServicesPY.placeOrderAndSaveStrategy({
            config: JSON.stringify({ ...config }),
            orders: JSON.stringify(paperTradeData?.orders)
        }).then((r) => r)
        if (res?.type) {
            await apiHelperPY({ apiName: "getAllStrategiesByClientId", data: { clientId: user.id } })
            toast.success(JSON.parse(res?.data)?.message)
            navigate('/strategies')
        } else {
            toast.error("Something went wrong!!")
        }
        // //console.log(config)
    }


    const placeLiveTradeOrderAndSaveStrategy = async () => {
        var config = {
            ...strategy_config,
            "broker_name": user?.Client?.[0]?.Broker,
            'is_live': 1
        }
        var res = await AdminServicesPY.placeLiveTradeOrderAndSaveStrategy({
            config: JSON.stringify({ ...config }),
            orders: JSON.stringify(liveTradeData?.orders)
        }).then((r) => r)


        var orders = JSON.parse(res?.data)?.orders
        console.log(orders)
        if (isMarketOpen()) {
            return { status: res?.type, orders: orders }
        } else {
            return { status: false }
        }

        // //console.log(config)
    }


    const OrderQueue = ({ data = {} }) => {
        var inputJson = data
        console.log(data)
        const resultArray = Object.keys(inputJson["order_quantity"]).map(symbol => {
            console.log([...allFundsData])
            return {
                ...[...allFundsData].filter((ii) => type?.value === 4 ? ii.symbol === symbol : type?.value === 1 ? ii.nse_symbol === symbol : ii?.isin_code === symbol)[0],
                "tag": inputJson?.["tag"]?.[symbol],
                "entry_price": inputJson?.["entry_price"]?.[symbol],
                "symbol": symbol,
                "order_quantity": (inputJson?.["order_quantity"]?.[symbol])?.toFixed(type?.value !== 2 ? 0 : 2),
                "direction": inputJson?.["direction"]?.[symbol],
                "order_type": inputJson?.["order_type"]?.[symbol],
                "exchange": inputJson?.["exchange"]?.[symbol],
                "ltp": inputJson?.["ltp"]?.[symbol],
                "sharpelyorderid": inputJson?.["sharpelyorderid"]?.[symbol],
                "min_inv_amount": inputJson?.["min_inv_amount"]?.[symbol],
                "order_flag": inputJson?.["order_flag"]?.[symbol],

            };
        });



        var rebalanceCols = Object.keys(inputJson)?.filter((i) => !["entry_price", "tag", 'min_inv_amount', 'exchange', 'client_id', 'sharpelyorderid', 'order_flag', 'ltp', 'is_amo']?.includes(i))?.map((item) => {
            return {
                "dataIndex": item,
                "key": item,
                "metric_col_code": item,
                "metric_unit": "",
                "metric_data_type": typeof (resultArray?.[0]?.[item]),
                "metric_name": item,
                "title": <span style={{ textTransform: "capitalize" }}>{String(item)?.replace("_", " ")}</span>,
                "align": "center",
                "width": 150,
            }
        })
        ////console.log(rebalanceCols)
        return <div className='mt-4'>
            <MyTable
                // loading={isPaperTradeView}
                dataSource={resultArray}
                columns={[{
                    "title": "Name",
                    'disableDeleteInCustomize': true,
                    "dataIndex": type?.value === 4 ? "proper_name" : "basic_name",
                    render: (text, record, index) => <Tooltip title={record.proper_name || record.basic_name} arrow={false}>
                        <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center" }}>
                            <AMCIcon amcName={type?.value === 4 ? record.symbol : record.amc_full_name} fundType={type?.value} height='1.15rem' width="1.15rem" bseTicker={record?.bse_ticker} />
                            <div>
                                <div className="w-500 black" style={{ width: 180, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{record?.proper_name || record.basic_name}</div>
                                {type?.value !== 2 ? <StockLivePrice fundData={record} symbol={record?.symbol || record.nse_symbol} format={10} /> : <span className='dark3 textXS w-500'>NAV :&nbsp; <span className='w-700 textXS'>{CurrencyFormat(record?.latest_nav, 'long', 2, '', false)}</span></span>}
                            </div>
                        </div>
                    </Tooltip>,
                    width: 150,
                    fixed: window.innerWidth > 760 ? 'left' : "",
                    show: true
                }, ...rebalanceCols.map((col) => {
                    return {
                        ...col,
                        "render": (text, record, index) => <div style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "center" }}>
                            {displayValue({
                                text, metricObj: col
                            })}
                        </div>
                    }
                })]}
                rowKey={(record) => record.plan_id || record.symbol}
                scroll={{ x: 'max-content' }}
            />
        </div>
    }

    const NotInvestedView = () => {
        return (
            <React.Fragment>
                <Head title={`${String(type?.omkar)} strategy details`} />
                <TopBanner2 />
                <Card style={{ paddingTop: "1.25rem", paddingBottom: "1.25rem" }} id="topHeadingDetailCard" bodyStyle={{ padding: 0, gap: "0.5rem" }}
                    className={`leftRightPadContainer ${isDev ? 'maxWidthContainer' : ""}`}>
                    <div className='textMD w-500 mb-3' style={{ gap: 0 }}>
                        {fundDetails?.message}
                    </div>
                    {fundDetails?.config?.instrument_type !== 2 ? fundDetails?.config?.order_queue?.map((i) => {
                        return <OrderQueue data={i} />
                    }) : fundDetails?.config?.order_queue?.map((i) => {
                        return <OrderQueue data={i?.orders} />
                    })}


                </Card>
            </React.Fragment >
        );
    }

    const loadWeightsData = async (staticStrategyFunds, isFresh = false) => {
        if (isFresh == true) {
            var instruments = type.value === 4 ? staticStrategyFunds.map((instrument) => { return instrument.symbol }) : type.value === 1 ? staticStrategyFunds.map((instrument) => { return instrument.nse_symbol }) : staticStrategyFunds.map((instrument) => { return instrument.isin_code })

            if (type?.value !== 2) {
                // adv stock flow
                var data = {
                    syms: instruments,
                    position_sizing: JSON.stringify(weight_method === "W-EQ" ? {
                        'method': weight_method
                    } : {
                        'method': weight_method,
                        'weight_by': weight_by
                    })
                }

                const res = await AdminServicesPY.get_weights_and_min_inv(data).then(res => res)
                //console.log(res?.data)
                setcalculatedWeights(JSON.parse(res?.data) || [])

                return JSON.parse(res?.data) || []
            } else {
                // old flow
                var data = {
                    instruments: instruments,
                    instrument_type: type.value === 4 ? 3 : type.value,
                    mCap: 0
                }

                const res = await AdminServicesPY.getPortfolioWeights(data).then(res => res)
                ////console.log(res?.data)

                setcalculatedWeights(res?.data || [])

                return res.data || []
            }


        } else {
            return calculatedWeights
        }
    }



    const allocate = async (staticStrategyFunds) => {
        setlistLoading(true)
        var a = staticStrategyFunds
        if (weight_method === 1 || weight_method === "W-CT") {
            if (100 % a?.length === 0) {
                a = a.map((obj, index) => ({
                    ...obj,
                    allocation: (100 / a?.length).toFixed(0), locked: false, index
                }))
                setstaticStrategyFunds(a)
            } else {
                a = a.map((obj, index) => ({
                    ...obj,
                    allocation: ((100 - (100 % a?.length)) / a?.length).toFixed(0), locked: false, index
                }))
                a[0].allocation = Number(a[0]?.allocation) + (100 % a?.length)
                setstaticStrategyFunds(a)
            }
        } else {
            await loadWeightsData(a, true).then((weights) => {
                // //console.log(weights)
                if (type?.value !== 2) {
                    //new
                    a = a.map((obj, index) => ({
                        ...obj,
                        allocation: (Number(weights?.filter((i) => i?.symbol === (obj?.symbol || obj?.nse_symbol || obj?.isin_code))?.[0]?.weights) * 100)?.toFixed(1),
                        locked: true,
                        index
                    }))
                    setstaticStrategyFunds(a)
                } else {
                    //old 
                    var finalWeights = weights.filter((i) => i.wt_method === (weight_method === "W-EQ" ? 2 : 1))[0]?.weights
                    a = a.map((obj, index) => ({
                        ...obj,
                        allocation: finalWeights[index].toFixed(1),
                        locked: true,
                        index
                    }))
                    setstaticStrategyFunds(a)
                }
            })
        }
        setlistLoading(false)
    }

    const isValidAllo = (viewType) => {
        var sum = 0;
        staticStrategyFunds.map((data, index) => {
            sum = (parseFloat(sum) + parseFloat(data.allocation)).toFixed(2);
        })
        ////console.log(sum)
        setSum(sum);
        if (Number(sum) !== 100) {

            setnextDisabled(true)
        } else {

            setnextDisabled(false)
        }
        setlistLoading(false)
    }

    const changeData = (index, text) => {
        try {
            var upDatedFilters = []
            upDatedFilters = [...staticStrategyFunds]
            const setFinalData = (data) => {
                setstaticStrategyFunds(data)
            }
            var a = JSON.parse(JSON.stringify(upDatedFilters))
            if (Number(text) !== 0) {
                a[index].allocation = text
                setFinalData(a)
            } else {
                toast.error("Weights can't be zero.\nEither allocate some weight or remove the factor model you don't want", {
                    className: "textSM"
                })
                setFinalData(staticStrategyFunds);
            }
        } catch (error) {
        }
    }

    const onAddInstruments = (item) => {
        setcalculatedWeights([])
        let l = item?.length;
        if (type.value === 4) {
            if (l > 50) {
                toast.error(`Maximum 50 ${type.dname} allowed`);
                return;
            }
        } else {
            if (l > 10) {
                toast.error(`Maximum 10 ${type.dname} allowed`);
                return;
            }
        }
        allocate([...item])
    }

    const del = (index) => {
        var a = staticStrategyFunds;
        a.splice(index, 1)
        if (a?.length === 0) {
            setstaticStrategyFunds([])
        } else {
            allocate([...a])
        }
    }

    const checkAddDisabled = (selectedData = []) => {
        let key = type.value === 4 ? 'symbol' : "plan_id";
        let init = selectedData?.map((el) => el?.[key]) || [];
        let oths = staticStrategyFunds?.map((el) => el?.[key]) || [];
        if (init?.length !== oths?.length) return false;
        return oths.every((el) => init.includes(el));
    }


    const TradeLogsAndComments = React.memo(() => {
        const comments = fundDetails?.trade_log?.comments;
        var obj = {}
        var finalComments = comments?.map((item, index) => {
            console.log(item)
            if (Object.keys(obj)?.includes(Object.keys(item)?.[0])) {
                obj[Object.keys(item)?.[0]] = [
                    ...obj[Object.keys(item)?.[0]],
                    Object?.values(item)?.[0]
                ]
            } else {
                obj[Object.keys(item)?.[0]] = [
                    Object?.values(item)?.[0]
                ]
            }
        })
        console.log(obj)
        const options1 = [
            {
                key: 'Trade logs',
                name: 'Trade logs',
                view: <CustomTabsMainChild heading={"Trade log"}
                    rightLinkText={fundDetails?.trade_log?.trades ?
                        <button className="btnWhite" onClick={() => {
                            exportAntDTable({
                                columns: [
                                    ...tradeLogsColumns,
                                    {
                                        title: 'Date',
                                        dataIndex: 'date',
                                        key: 'date',
                                        align: "right"
                                    }],
                                dataSource: Object.entries(fundDetails?.trade_log?.trades)?.reverse()?.map(([date, rebalData], index1) => {
                                    return sortFunds(rebalData?.map((i) => {
                                        return {
                                            ...i,
                                            date: date
                                        }
                                    }), "order_quantity")
                                })?.flat(1),
                                getValueOnly: true
                            })
                        }}>
                            <Tooltip arrow={false} title={`Export trade logs`}><AiOutlineDownload size={15} /></Tooltip> Export trade logs
                        </button> : ""}
                    subHeading=''

                >
                    <TradeLogs />
                </CustomTabsMainChild>
            },
            {
                key: 'Corporate actions',
                name: 'Corporate actions',
                view: <CustomTabsMainChild heading={""}
                    subHeading=''
                >
                    {comments?.length > 0 ? <div className='mt-4'>
                        <Timeline items={Object.entries(obj)?.reverse()?.map(([date, rebalData], index1) => {
                            return {
                                children: <div>
                                    <div className="mb-2 textXS w-500 textLight">Corporate Actions on {formatDateWithTime(date)}</div>
                                    <ul>
                                        {rebalData?.map((i) => {
                                            return <li>
                                                {String(i)?.split(":")?.map((ii, index) => {
                                                    return <span className={index === 0 ? "w-500 textMD" : "textMD"}>{ii}{index === 0 ? ": " : ""}</span>
                                                })}
                                            </li>
                                        })}
                                    </ul>
                                </div>
                            }
                        })} />
                    </div>
                        : <NoDataFound message="No corporate actions found" />}
                </CustomTabsMainChild>
            }
        ]

        const [selected, setSelected] = React.useState(options1[0])

        return <>
            <div style={{ padding: "1.5rem", paddingBottom: "0rem" }}>
                <CustomSegmentedTab isBlock={false} value={selected.name} options={options1.map(m => m.name)}
                    onChange={(e) => setSelected(options1.find(o => o.name === e))} />
            </div>
            {selected?.view}
        </>
    })

    React.useEffect(() => {
        ////console.log("logger1", staticStrategyFunds)
        if (staticStrategyFunds?.length > 0) {
            allocate(staticStrategyFunds)
        }
    }, [weight_method, weight_by])

    let tabItems = [
        {
            label: "Overview",
            key: "Overview",
            value: "Overview",
            children: <>
                <ChartAndSnapshot isMobile={isMobile} dispatch={dispatch} type={type} fundDetCardsData={fundDetCardsData} fundData={fundData} fundDetails={fundDetails}
                    MINTBOX_TYPE={MINTBOX_TYPE} location={location} canEditBasket={canEditBasket} />
                <div style={{ padding: "1.5rem" }}>
                    <InstrumentListTable
                        showToolsBtnsInTab
                        hideSettings
                        showHeatmapBtn
                        downloadTable
                        defaultInsType={type?.value}
                        paginationSize={(type?.value === 4 ? fundDetails?.analytics?.holdings : fundDetails?.analytics?.constituents)?.length}
                        finalList={(type?.value === 4 ? fundDetails?.analytics?.holdings : fundDetails?.analytics?.constituents)?.map((i) => {
                            return {
                                ...[...allFundsData].filter((ii) => ii?.[type?.value === 4 ? "symbol" : "plan_id"] === i?.[type?.value === 4 ? "symbol" : "plan_id"])[0],
                                ...i,
                            }
                        })}
                        hideInvestBtn={true}
                        tableType={12}
                        isScrollingTable={true}
                        defFilters={[]}
                        otherCol={[...dataTableColumns]}
                        tableHeader={<div>
                            <CardHeader heading={"Current Holdings"} hideHeaderBlueLine textClassName={isMobile ? "textMD w-500" : 'textXL w-700'} headingType={2} />
                            <div className='textSM dark3 w-400' style={{ whiteSpace: 'wrap', margin: "0.12rem 0 1.25rem", maxWidth: "100%", minWidth: isMobile ? '100%' : '' }}>{`Current ${String(type?.dname)} in your portfolio. You can add/drop columns as per your preference.`}</div>
                        </div>}
                        hideHeaderBlueLine
                        onRebalancePressed={() => {
                            console.log("heyeyey", fundDetails)

                            if (fundDetails?.config?.strategy_type_id === 2) {
                                // manual rebalance
                                setOpenManualRebalanceDrawer(!openManualRebalanceDrawer)


                            } else {
                                // normal rebalance
                                setshowRebalModal(true)
                            }
                        }}
                        showRebalanceBtn={strategy_config?.is_live !== 1 && dayDiff(new Date(fundDetails?.config?.last_rebal_date), new Date()) >= 1 ? fundDetails?.config?.strategy_type_id === 2 ? "Trade now" : ((Number(user?.id) === Number(strategy_config?.client_id))) ? "Manual rebalance" : false : false}
                        hideCheckBox={true} showBubbleChartBtn={true} hidePaddingInMob={true} isCard={false} />
                </div>
                {/* <CustomTabsMainChild heading='Current Holdings'
                    subHeading={`Current ${String(type?.dname)} in your portfolio. You can add/drop columns as per your preference.`} >
                    
                </CustomTabsMainChild> */}
            </>
        },
        {
            label: "Analysis",
            key: "Analysis",
            value: "Analysis",
            children: type?.value === 4 ? <Analysis insights={fundDetails?.analytics?.insights} holdings={fundDetails?.analytics?.holdings} type={type} analysisType='stockPortfolio'
                screenerMetricDescData={screenerMetricDescData} showAnalysis={false} otherTabs={[{
                    key: 'Sector allocation',
                    heading: 'Sector allocation',
                    value: "SectorAllocation",
                    children: <SectorAllocationTable />,
                    show: true,
                    subHeading: "Sectoral breakup of this strategy at 3 levels – Sector, Industry Group and Industry"
                }]} /> : <Analysis insights={fundDetails?.analytics?.insights}
                    holdings={type?.value === 4 ? fundDetails?.analytics?.holdings : fundDetails?.analytics?.constituents}
                    showAnalysis={false}
                    overlap={fundDetails?.analytics?.factsheet?.overlap} analysisType='fundPortfolio' type={type} screenerMetricDescData={screenerMetricDescData || []}

                    otherTabs={[{
                        key: 'Fundamentals',
                        heading: 'Fundamentals',
                        value: "Fundamentals",
                        children: <Fundamentals fundamentals={fundDetails?.analytics?.factsheet?.fundamentals || {}} />,
                        show: true,
                        subHeadinng: "A quick snapshot of portfolio fundamentals calculated as weighted average of fundamentals of individual ETF/MFs."
                    },
                    {
                        key: "HoldingBreakup",
                        value: "HoldingBreakup",
                        heading: "Holding Breakup",
                        show: true,
                        subHeading: "Look at breakup of holdings by individual instruments or by sector or by credit rating.",
                        children: <HoldingBreakup performanceFactsheet={fundDetails?.analytics || {}} />
                    }]?.reverse()} />,
            show: !isLocked,
        },
        {
            label: "Performance Summary",
            key: "HistoricalPerformance",
            value: "HistoricalPerformance",
            children: <>
                <StickyScrollTabs isAccordionInMob showHardCodedData={Object.keys(fundDetails?.perfromance_tables?.perf_summary || {})?.length > 0 ? false : <div>
                    <NoDataFound
                        message="Strategy needs to complete atleast 2 trading sessions to display performance data"
                        msgMaxWidth="40ch"
                    />
                </div>} queriesToStickToBot={['#sharpelyHeader', '#customTabsBarId']} tabData={[
                    {
                        key: 'Portfolio Summary',
                        heading: 'Portfolio Summary',
                        value: "PortfolioSummary",
                        children: <TableConstructor tableData={fundDetails?.perfromance_tables?.perf_summary} isTranspose={true} />,
                        show: true,
                    },
                    {
                        key: 'Key Performance Metrics',
                        heading: 'Key Performance Metrics',
                        value: "KeyPerformanceMetrics",
                        children: <TableConstructor tableData={fundDetails?.perfromance_tables?.perf_metrics} isTranspose={true} />,
                        show: true,
                    },
                    {
                        key: 'Trailing returns',
                        heading: 'Trailing returns',
                        value: "TrailingReturns",
                        children: <TableConstructor tableData={fundDetails?.perfromance_tables?.trailing_returns} isTranspose={true} />,
                        show: true,
                    },
                    {
                        key: 'Calendar year returns',
                        heading: 'Calendar year returns',
                        value: "CalendarYearReturns",
                        children: <TableConstructor tableData={fundDetails?.perfromance_tables?.cal_year_returns} isTranspose={true} />,
                        show: true,
                    },
                    {
                        key: 'Worst 5 drawdowns',
                        heading: 'Worst 5 drawdowns',
                        value: "Worst5Drawdowns",
                        children: <WorstDrawdowns data={Object.values(fundDetails?.perfromance_tables?.worst_5_drawdowns || {})} />,
                        show: true,
                    },
                    {
                        key: 'Monthly returns heatmap',
                        heading: 'Monthly returns heatmap',
                        value: "MonthlyReturnsHeatmap",
                        rightTextLinkonClick: () => {
                            var heatmapdata = fundDetails?.perfromance_charts?.heatmap
                            var years = fundDetails?.perfromance_charts?.heatmap?.map((data) => { return data.year })
                            var months = Object.keys(fundDetails?.perfromance_charts?.heatmap?.[0]).slice(1)
                            var finalWS = []
                            var header = ['Months/Years', ...years]
                            finalWS = [header]
                            var dataFinal = months.map((month, index) => {
                                var row = [month]
                                years.map((year, yindex) => {
                                    row.push(Number((heatmapdata[yindex][month] * 100).toFixed(1)))
                                })
                                finalWS.push(row)
                            })
                            exportExcel({ headers: header, wsData: finalWS, fileDefaultName: "HeatMapData" })
                        },
                        rightLinkText: <button className="btnWhite"><BiDownload /></button>,
                        children: fundDetails?.perfromance_charts?.heatmap?.length > 0 ? <HeatMap data={fundDetails?.perfromance_charts?.heatmap} /> : <NoBTData />,
                        show: true,
                    },
                    {
                        key: 'Distribution of monthly returns',
                        heading: 'Distribution of monthly returns',
                        value: "DistributionOfMonthlyReturns",
                        children: fundDetails?.perfromance_charts?.histogram_monthly_rets?.length > 0 ? <ReturnsHistogram histData={fundDetails?.perfromance_charts?.histogram_monthly_rets} /> : <NoBTData />,
                        show: true,
                    },
                    {
                        key: '6-month rolling volatility',
                        heading: '6-month rolling volatility',
                        value: "SixMonthRollingVolatility",
                        children: fundDetails?.perfromance_charts?.rolling_vol?.length > 0 ? <RollingVol data={fundDetails?.perfromance_charts?.rolling_vol}
                            changeObjSecondKey='date' /> : <NoBTData />,
                        show: true,
                    },
                    {
                        key: '6-month rolling Sharpe ratio',
                        heading: '6-month rolling Sharpe ratio',
                        value: "SixMonthRollingSharpeRatio",
                        children: fundDetails?.perfromance_charts?.rolling_sharpe?.length > 0 ? <SharpeChart data={fundDetails?.perfromance_charts?.rolling_sharpe}
                            changeObjSecondKey='date' /> : <NoBTData />,
                        show: true,
                    },
                    {
                        key: 'Underwater plot',
                        heading: 'Underwater plot',
                        value: "UnderwaterPlot",
                        children: fundDetails?.perfromance_charts?.underwater?.length > 0 ? <UnderWater data={fundDetails?.perfromance_charts?.underwater}
                            changeObjSecondKey='date' datakey="portfolio" /> : <NoBTData />,
                        show: true,
                    },
                ]} />
            </>,
            show: !isLocked && strategy_config?.is_live !== 1,
        },
        {
            label: "Trade logs",
            key: "Trade logs",
            value: "Trade logs",
            children: <>
                <TradeLogsAndComments />
            </>,
            show: !isLocked,
        },
        {
            label: "Pending orders",
            key: "Pending orders",
            value: "Pending orders",
            children: <>
                <CustomTabsMainChild heading='Pending orders'
                    subHeading='' >
                    <Timeline items={fundDetails?.config?.order_queue?.map((i, index) => {
                        return {
                            children: <Collapse defaultActiveKey={[0]} expandIcon={({ isActive }) => <IoIosArrowDown style={{
                                transition: "all 0.3s linear", transform: isActive ? "rotate(180deg)" : "rotate(0deg)", color: "var(--black)", fontSize: "0.875rem"
                            }} />} className='tradeLogCollapse mt-4' expandIconPosition='end' items={[
                                {
                                    key: index,
                                    label: <div className="textSM w-500 black" >Order batch ({index + 1})</div>,
                                    children: <OrderQueue data={i} />,
                                }
                            ]} style={{ background: "var(--white)" }} bordered={false} />
                        }
                    })} />
                    {/* {fundDetails?.config?.order_queue?.map((i) => {
                        return <OrderQueue data={i} />
                    })} */}
                    {/* <OrderQueue /> */}
                </CustomTabsMainChild>
            </>,
            show: !isLocked && fundDetails?.config?.order_queue?.[0] !== undefined
        },
        {
            label: "Methodology",
            key: "Methodology",
            value: "Methodology",
            children: (rebalanceStocks?.length > 0 && isRefreshRequired) ? <div className="topLevelContainer" style={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", flex: 2 }}>
                    <CardHeader heading={"Rebalance order"} />
                    <MyTable
                        // loading={isPaperTradeView}
                        dataSource={rebalanceStocks}
                        columns={[{
                            "title": "Name",
                            'disableDeleteInCustomize': true,
                            "dataIndex": type?.value === 4 ? "proper_name" : "basic_name",
                            render: (text, record, index) => <Tooltip title={record.proper_name || record.basic_name} arrow={false}>
                                <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center" }}>
                                    <AMCIcon amcName={type?.value === 4 ? record.symbol : record.amc_full_name} fundType={type?.value} height='1.15rem' width="1.15rem" bseTicker={record?.bse_ticker} />
                                    <div>
                                        <div className="w-500 black" style={{ width: 180, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{record?.proper_name || record.basic_name}</div>
                                        {type?.value !== 2 && < StockLivePrice fundData={record} symbol={record?.symbol || record?.nse_symbol} format={10} />}
                                    </div>
                                </div>
                            </Tooltip>,
                            width: 150,
                            fixed: window.innerWidth > 760 ? 'left' : "",
                            show: true
                        }, ...rebalanceCols.map((col) => {
                            return {
                                ...col,
                                "render": (text, record, index) => <div style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "center" }}>
                                    {displayValue({
                                        text, metricObj: col
                                    })}
                                </div>
                            }
                        })]}
                        rowKey={(record) => record.plan_id || record.symbol}
                        scroll={{ x: 0, y: "80vh" }}
                    />
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", flex: 1 }}>
                    <div className='textSM'>{rebalanceOrder?.message}</div>
                    <div className='textXS' style={{ paddingTop: "1rem", paddingBottom: "1rem", }}>
                        <b>Disclaimer: </b>
                        {rebalanceOrder?.disclaimer}
                    </div>
                    <div><button className='btnPrimary align-items-center' style={{
                        gap: "0.5rem",
                        alignItems: "center",
                        alignContent: "center"
                    }} onClick={() => {
                        placeRebalanceOrder()
                    }}>Execute order <BsArrowRight /></button></div>
                </div>
            </div> : isRefreshRequired ?
                <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem", flex: 1, height: "100%", justifyContent: "center", alignItems: "center" }}>
                    <Result
                        status="success"
                        style={{ maxWidth: "50vw" }}
                        className='textSM w-500'
                        title="Your strategy parameters have been updated. Do you want to trigger a rebalancing now?"
                        // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
                        extra={[
                            <Button key={"exit"} onClick={() => {
                                navigate(`/strategy-detail/${String(strategy_name)?.toLowerCase()?.replaceAll(" ", "-")}/${MINTBOX_TYPE}/${MINTBOX_ID}`)
                                //seteditBasketDrawer(false)
                                setIsRefreshRequired(false)
                                dem()
                            }}>
                                Not now
                            </Button>,
                            <Button key={"rebal"} type="primary" onClick={() => {
                                generateRebalOrder()
                            }}>Rebalance now</Button>,
                        ]}
                    />
                </div> :
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="topLevelContainer" style={{ display: "flex", flexDirection: "column", gap: "2rem", paddingBottom: 0 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div className="textLG w-bold mt-2" style={{ flex: 1, padding: isMobile ? '1.25rem' : '0' }}>Review and edit Strategy parameters and {type?.dname} selection model</div>
                            {needToUpdate ? <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                {!strategyParamOrCodeChange && <button className="btnWhite ms-2 "
                                    disabled={strategyParamOrCodeChange}
                                    onClick={!(strategyParamOrCodeChange) ? () => {
                                        //////console.log(strategy_config)
                                        settriggerBacktest(true)
                                        setTimeout(() => {
                                            settriggerBacktest(false)
                                        }, 500);
                                    } : () => { }}>
                                    Backtest strategy
                                </button>}
                                <button className="btnBlack ms-2 "
                                    disabled={!(needToUpdate && nextDisabled)}
                                    onClick={needToUpdate ? () => {
                                        //////console.log(strategy_config)
                                        updateStrategyParams(strategy_config)
                                    } : () => { }}>
                                    Update strategy
                                </button>
                            </div> : <></>}
                        </div>
                        <Collapse
                            bordered
                            className="customCollapseWithoutInnerPadding"
                            expandIconPosition={'end'}
                            // defaultActiveKey={["stockSelection"]}
                            expandIcon={({ isActive }) => <IoIosArrowDown style={{
                                transition: "0.2s linear", transform: isActive ? "rotate(180deg)" : "rotate(0deg)", fontSize: "1rem"
                            }} />}

                            style={{ padding: 0, paddingBottom: 0, }}
                            items={[
                                {
                                    key: 'backtestParameters',
                                    label: <div className='d-flex flex-1 align-items-center justify-content-between'>
                                        <div style={{ display: "flex", flex: 1, flexDirection: "column", gap: "0.5rem" }}>
                                            <div className='textSM w-700 dark4'>Strategy parameters</div>
                                            <div className='textXS dark2'>
                                                {isCloned ? 'This section provides an overview of parameters that are used to execute this strategy. Paper Trade this strategy to make any changes in these parameters.' :
                                                    'Use this section to edit the parameters that are used to execute your strategy.'}
                                            </div>
                                        </div>
                                    </div>,
                                    children: <div>
                                        <div className="" style={{ flex: 1, height: "100%" }}>
                                            <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem" }}>
                                                {/* <span style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                                                    <CardHeader heading={'Edit your strategy'} hideHeaderBlueLine headingType={2} />
                                                </span> */}
                                                {!isCloned && <div className='d-flex flex-column' style={{ gap: "1rem", maxWidth: "50%" }}>
                                                    <span className='d-flex flex-column' style={{ gap: "0.3rem" }}>
                                                        <span className='textXS w-500'>Strategy name</span>
                                                        <Input readOnly={isCloned} status={isInvalidTextOrLength({ text: strategy_name }) ? "error" : ""} size='middle'
                                                            value={strategy_name} onChange={(e) => {
                                                                let val = String(e.target.value);
                                                                set_strategy_name(val)
                                                            }} placeholder='Enter your strategy name' />
                                                        {isInvalidTextOrLength({ text: strategy_name }) ? <label style={{ color: "var(--red)", textAlign: "right" }} className='textXS w-500'>
                                                            * Special characters are not allowed
                                                        </label> : null}
                                                    </span>

                                                    <DescriptionInput label='Description' value={strategy_desc} onChange={(e) => {
                                                        // console.log(e)
                                                        set_strategy_desc(e)
                                                    }} labelClassName='textXS w-500 dark4' />

                                                </div>}
                                                <ScreenBacktestInpModal
                                                    readOnly={isCloned}
                                                    viewType={"strategy"}
                                                    type={type}
                                                    orignalConfig={fundDetails?.config}
                                                    fromStrategyDetail={true}
                                                    startDate={start_date}
                                                    endDate={end_date}
                                                    initialConfig={strategy_config}
                                                    screenerMetrics={screenerMetric}
                                                    variables={variables}
                                                    benchmarks={benchmarks}
                                                    parsedCode={{
                                                        globals: updated_globals,
                                                        variables: variables,
                                                        conditions: conditions,
                                                        conditions_oper: conditionOperators
                                                    }}
                                                    onDone={(config, start_date, end_date, parsedCode) => {
                                                        //////console.log(config, start_date, end_date, parsedCode)
                                                        set_strategy_config(config)
                                                        if (JSON.stringify(parsedCode?.globals) !== JSON.stringify(updated_globals)) {
                                                            setupdated_Globals(parsedCode?.globals)
                                                            var c = finalCode({
                                                                ...parsedCode,
                                                                indices: indices,
                                                                sortAndLimit: config?.position_sizing
                                                            })
                                                            set_strategy_config({
                                                                ...config,
                                                                code: c
                                                            })
                                                        }
                                                        // updateStrategyParams(config)
                                                        // //seteditBasketDrawer(!editBasketDrawer)
                                                    }}
                                                    toggle={(e) => {
                                                        //seteditBasketDrawer(e)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            ]} />
                        <Collapse
                            bordered
                            className="customCollapseWithoutInnerPadding"
                            expandIconPosition={'end'}
                            defaultActiveKey={["stockSelection"]}
                            expandIcon={({ isActive }) => <IoIosArrowDown style={{
                                transition: "0.2s linear", transform: isActive ? "rotate(180deg)" : "rotate(0deg)", fontSize: "1rem"
                            }} />}

                            style={{ padding: 0, paddingBottom: 0, }}
                            items={[
                                {
                                    key: 'stockSelection',
                                    label: <div className='d-flex flex-1 align-items-center justify-content-between'>
                                        <div style={{ display: "flex", flex: 1, flexDirection: "column", gap: "0.5rem" }}>
                                            <div className='textSM w-700 dark4'>{type?.dname} selection model</div>
                                            <div className='textXS dark2'>
                                                {isCloned ? `This section provides an overview of the model that is used for selecting ${type?.dname}. Paper Trade this strategy to make any changes in the ${type?.dname} selection model.` :
                                                    `Use this section to edit the model used for selecting ${type?.dname}`}
                                            </div>
                                        </div>
                                    </div>,

                                    children: <div>
                                        <div className="textMD w-bold m-2 mb-0">
                                            {fundDetails?.config?.instrument_selection !== 1 ? `${fundDetails?.config?.instrument_selection === 2 ? "Screen" : "Factor model"} used for ${type?.omkar} selection` : `Your current ${type?.omkar} basket`}
                                        </div>
                                        {fundDetails?.config?.instrument_selection === 1 ? <AddInstrument allFunds={allFundsData} watchlist={watchlist} staticStrategyFunds={staticStrategyFunds} type={type} instrument_method={1} sectors={screener_data_final?.fabricatedData?.sectors} industryGrp={screener_data_final?.fabricatedData?.industries}
                                            industries={screener_data_final?.fabricatedData?.basicIndustries} mfCategories={screener_data_final?.fabricatedData?.mf_categories} benchmarks={benchmarks} isMobile={isMobile} isModalView={false} onAddInstruments={onAddInstruments}
                                            listLoading={listLoading} backtestWeightMethods={backtestWeightMethods} weight_method={weight_method}
                                            setweight_method={setweight_method} weight_by={weight_by} setweight_by={setweight_by} GetLTP={(e) => GetLTP(e)}
                                            changeData={changeData} del={del} checkAddDisabled={checkAddDisabled} sum={sum} screenerMetrics={screenerMetric?.filter((i) => i?.metric_data_type === "float")} isCloned={isCloned} />
                                            : fundDetails?.config?.instrument_selection === 2 ? <Screener
                                                triggerBacktest={triggerBacktest}
                                                strategy_fundD={{ ...fundDetails, config: { ...strategy_config } }}
                                                typeD={type}
                                                updateStrategyParams={(code) => {
                                                    var nConfig = {
                                                        ...strategy_config,
                                                        "code": code
                                                    }
                                                    //console.log("KAKAK", (JSON.stringify(strategy_config) !== JSON.stringify(nConfig)), JSON.stringify(strategy_config), JSON.stringify(nConfig))
                                                    if (JSON.stringify(strategy_config) !== JSON.stringify(nConfig)) {
                                                        set_strategy_config(nConfig)
                                                    }
                                                    ////console.log("KAKAK", config)
                                                    // updateStrategyParams({
                                                    //     ...fundDetails?.config,
                                                    //     ...config
                                                    // }, 1)
                                                }}
                                                readOnly={isCloned}
                                            /> : <div className="mt-4" style={{ display: "flex", flexDirection: "column", gap: "2rem", paddingBottom: 0 }}>
                                                <FactorModel
                                                    triggerBacktest={triggerBacktest}
                                                    strategy_fundD={{ ...fundDetails, config: { ...strategy_config } }}
                                                    typeD={type}
                                                    updateStrategyParams={(code) => {

                                                        var nConfig = {
                                                            ...strategy_config,
                                                            "code": code
                                                        }

                                                        //console.log("KAKAK", nConfig)
                                                        if (JSON.stringify(strategy_config) !== JSON.stringify(nConfig)) {
                                                            set_strategy_config(nConfig)
                                                        }
                                                    }}
                                                />
                                            </div>}
                                    </div>
                                }
                            ]} />
                        {/* <Collapse
                            expandIconPosition={'end'}
                            expandIcon={({ isActive }) => <IoIosArrowDown style={{
                                transition: "0.2s linear", transform: isActive ? "rotate(180deg)" : "rotate(0deg)", fontSize: "1rem"
                            }} />}
                            style={{ padding: 0, paddingBottom: 0, backgroundColor: 'var(--grey3)', border: "0px solid var(--grey3)" }}
                            items={} /> */}
                    </div>


                    {/* <StickyScrollTabs isSingleChildInDesk isAccordionInMob
                    defaultSelectedTab='Strategyparameters'
                    queriesToStickToBot={['#sharpelyHeader', '#customTabsBarId']} tabData={[
                        {
                            key: 'Strategy parameters',
                            heading: 'Strategy parameters',
                            value: "Strategyparameters",
                            rightLinkText: isRefreshRequired ? false : !((JSON.stringify(fundDetails?.config) === JSON.stringify(strategy_config)) &&
                                (JSON.stringify(updated_globals) === JSON.stringify(globals)) &&
                                (strategy_name === fundDetails?.config?.strategy_name) &&
                                (strategy_desc === fundDetails?.config?.strategy_desc)
                            ) ? <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <button className="btnBlack ms-2 "
                                    disabled={((JSON.stringify(fundDetails?.config) === JSON.stringify(strategy_config)) &&
                                        (JSON.stringify(updated_globals) === JSON.stringify(globals)) &&
                                        (strategy_name === fundDetails?.config?.strategy_name) &&
                                        (strategy_desc === fundDetails?.config?.strategy_desc)
                                    ) && !nextDisabled}
                                    onClick={!((JSON.stringify(fundDetails?.config) === JSON.stringify(strategy_config)) &&
                                        (JSON.stringify(updated_globals) === JSON.stringify(globals)) &&
                                        (strategy_name === fundDetails?.config?.strategy_name) &&
                                        (strategy_desc === fundDetails?.config?.strategy_desc)
                                    ) ? () => {
                                        //////console.log(strategy_config)
                                        updateStrategyParams(strategy_config)
                                    } : () => { }}>
                                    Update
                                </button>
                            </div> : <></>,
                            children: Strategyparameters(),
                            show: true,
                        },
                        {
                            key: 'Stock selection model',
                            noHeading: strategy_config?.instrument_selection !== 1,
                            heading: 'Stock selection model',
                            value: "stockselection",
                            children: StockSelectionModel(),
                            show: true,
                        }
                    ]} /> */}
                </div>,
            show: !isLocked && ((Number(user?.id) === Number(strategy_config?.client_id)) || isCloned),
        },
    ]

    const PaperTradePanel = React.memo(() => {
        const [showSaveModalMobile, setShowSaveModalMobile] = React.useState(false)
        const [updConfig, setUpdConfig] = React.useState({})

        const SaveCard = () => {
            const [clone_strategy_name, setclone_strategy_name] = React.useState(strategy_name + " (Copy)")
            const [clone_strategy_desc, setclone_strategy_desc] = React.useState(strategy_desc)
            return <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", flex: 1 }}>
                <div className='textSM'>{paperTradeData?.message}</div>
                <div>
                    <div className='textSM w-500 mb-2'>Let’s give your strategy a name</div>
                    <div>
                        <NameInput placeholder='Multibagger Strategy (min. 4 char)' changeValueOnChange value={clone_strategy_name} onChange={(e) => {
                            let val = String(e);
                            setclone_strategy_name(val)
                        }} />
                    </div>
                </div>
                <div>
                    <DescriptionInput label='Description' value={clone_strategy_desc} onChange={(e) => {
                        setclone_strategy_desc(e);
                    }} />
                </div>
                <div>
                    <SubscribeContentWrapper onClick={() => {
                        setTimeout(() => {
                            if (clone_strategy_name?.length > 4) {
                                placeOrderAndSaveStrategy(clone_strategy_name, clone_strategy_desc)
                            } else {
                                toast.error("Please enter proper name for your strategy")
                            }
                        }, 500)
                    }} moduleKey={type?.value === 4 ? "BUILD,_BACKTEST_AND_PAPERTRADE" : type?.value === 1 ? "BUILD,_BACKTEST_AND_PAPERTRADE_ETFs" :
                        "BUILD,_BACKTEST_AND_PAPERTRADE_MF"} checkLimit usageCount={userTotalStrategies} wrapStyle={{ width: "fit-content" }}
                        disabled={isInvalidTextOrLength({ text: clone_strategy_name, checkLength: true }) || clone_strategy_desc?.length < 15}>
                        <button className='btnPrimary align-items-center' disabled={isInvalidTextOrLength({ text: clone_strategy_name, checkLength: true }) || clone_strategy_desc?.length < 15} style={{
                            gap: "0.5rem",
                            alignItems: "center",
                            alignContent: "center"
                        }} >Execute order <BsArrowRight />
                        </button>
                    </SubscribeContentWrapper>
                </div>
                <div className='textXS' style={{ paddingTop: "1rem", paddingBottom: "1rem", }}>
                    <b>Disclaimer: </b>
                    {paperTradeData?.disclaimer}
                </div>
            </div>
        }
        return <div>
            {cloneOrder?.length === 0 ?
                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginTop: 40, alignItems: "center", justifyContent: "center" }}>
                    <Spin />
                </div>
                : cloneOrder?.length > 0 ? <div style={{ display: "flex", flexDirection: isMobile ? "column-reverse" : "row", gap: "1rem", marginBottom: '2rem', width: '100%' }}>
                    <Card bordered style={{ flex: 1, padding: '0.75rem' }} bodyStyle={{ padding: 0 }}>
                        <div style={{ padding: '0.5rem 0.75rem' }}>
                            <CardHeader heading={!String(paperTradeData?.message)?.includes("on market open") ? "You will be creating a paper traded strategy. Here are your virtual orders that will be executed now" : "You will be creating a paper traded strategy. Here are your virtual orders that will be executed at next market open."}
                                textClassName="textMD w-500"
                            />
                        </div>
                        {
                            isMobile && <button className='btnWhite align-items-center' style={{
                                marginTop: '0.5rem',
                                marginBottom: '1.5rem',
                                gap: "0.5rem",
                                alignItems: "center",
                                alignContent: "center",
                                alignSelf: 'flex-end'
                            }} onClick={() => setShowSaveModalMobile(true)}>Save & Execute order <BsArrowRight />
                            </button>
                        }
                        <Table
                            dataSource={cloneOrder}
                            columns={[{
                                "title": "Name",
                                'disableDeleteInCustomize': true,
                                "dataIndex": type?.value === 4 ? "proper_name" : "basic_name",
                                render: (text, record, index) => <Tooltip title={record.proper_name || record.basic_name} arrow={false}>
                                    <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center" }}>
                                        <AMCIcon amcName={type?.value === 4 ? record.symbol : record.amc_full_name} fundType={type?.value} height='1.15rem' width="1.15rem" bseTicker={record?.bse_ticker} />
                                        <div>
                                            <div className="w-500 black" style={{ width: 180, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{record?.proper_name || record.basic_name}</div>
                                            {type?.value !== 2 && <StockLivePrice fundData={record} symbol={record?.symbol || record.nse_symbol} format={10} />}
                                        </div>
                                    </div>
                                </Tooltip>,
                                width: 150,
                                fixed: 'left',
                                show: true
                            }, ...cloneOrderCols.map((col) => {
                                return {
                                    ...col,
                                    "render": (text, record, index) => <div style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "center" }}>
                                        {displayValue({
                                            text, metricObj: col
                                        })}
                                    </div>
                                }
                            })]}
                            rowKey={(record) => record.plan_id || record.symbol}
                            scroll={{ x: 'max-content' }}
                            size="small"
                        />
                    </Card>
                    {!isMobile && <Card bordered style={{ flex: 1, minWidth: '40%', height: 'fit-content' }}>
                        <SaveCard />
                    </Card>}
                    {
                        isMobile && showSaveModalMobile && <Modal centered closable={false} styles={{ body: { padding: '0' } }}
                            open={showSaveModalMobile} onCancel={() => setShowSaveModalMobile(false)} footer={null}>
                            <SaveCard />
                        </Modal>
                    }
                </div> : <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", }}>
                    <div>{paperTradeData?.message}</div>
                </div>
            }
        </div >
    });



    //live trading
    const [live_tade_capital, set_live_trade_capital] = React.useState(null)
    const [liveOrderModal, setliveOrderModal] = React.useState(false)
    const [liveTradeData, setliveTradeData] = React.useState({})
    const [liveTradeOrders, setliveTradeOrders] = React.useState([])
    const [liveTradeCols, setliveTradeCols] = React.useState([])


    const generateLiveOrder = async (capital) => {
        var config = {
            ...strategy_config,
            "broker_name": user?.Client?.[0]?.Broker,
            "is_live": 1
        }
        var res = await AdminServicesPY.getLiveTradeOrder({
            config: JSON.stringify(config),
            initial_capital: capital || live_tade_capital,
        })
        console.log(res)
        setliveTradeData(JSON.parse(res?.data))
        if (JSON.parse(res?.data)?.orders?.length > 0) {
            var orders = JSON.parse(res?.data)?.orders
            setliveTradeOrders((orders || []).map((i) => {
                ////console.log(i)
                return {
                    ...[...allFundsData].filter((ii) => type?.value === 4 ? ii?.symbol === i?.symbol : type?.value === 1 ? ii.nse_symbol === i.symbol : ii?.isin_code === i?.symbol)[0],
                    ...i,
                }
            }))

            setliveTradeCols((Object.keys(orders?.[0])?.filter((i) => !(['symbol', 'ltp', 'mcap', 'entry_price', 'client_id', 'is_amo', 'strategy_id', 'tag', '_id', 'timestamp', 'sharpelyorderid', 'exchange', 'min_inv_amount']?.includes(i))))?.map((item) => {
                ////console.log(typeof (orders?.[0]?.[item]))
                return {
                    "dataIndex": item,
                    "key": item,
                    "metric_col_code": item,
                    "metric_unit": "",
                    "metric_data_type": typeof (orders?.[0]?.[item]),
                    "metric_name": item,
                    "title": <span style={{ textTransform: "capitalize" }}>{String(item)?.replace("_", " ")}</span>,
                    "align": "center",
                    "width": 150,
                }
            }))
        }
    }

    const LiveTradePanel = React.memo(() => {
        return <div>
            {liveTradeOrders?.length === 0 ?
                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginTop: 40, alignItems: "center", justifyContent: "center" }}>
                    <Spin />
                </div>
                : liveTradeOrders?.length > 0 ? <div style={{ display: "flex", flex: 1, flexDirection: "column", gap: "2rem", alignItems: "flex-start", paddingTop: 40 }}>
                    <CardHeader heading={!String(liveTradeData?.message)?.includes("on market open") ? "You will be creating a paper traded strategy. Here are your virtual orders that will be executed now" : "You will be creating a paper traded strategy. Here are your virtual orders that will be executed at next market open."}
                        textClassName="textMD w-500"
                    />
                    <div style={{ display: "flex", flexDirection: "row", gap: "2rem" }}>

                        <MyTable
                            dataSource={liveTradeOrders}
                            columns={[{
                                "title": "Name",
                                'disableDeleteInCustomize': true,
                                "dataIndex": type?.value === 4 ? "proper_name" : "basic_name",
                                render: (text, record, index) => <Tooltip title={record.proper_name || record.basic_name} arrow={false}>
                                    <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center" }}>
                                        <AMCIcon amcName={type?.value === 4 ? record.symbol : record.amc_full_name} fundType={type?.value} height='1.15rem' width="1.15rem" bseTicker={record?.bse_ticker} />
                                        <div>
                                            <div className="w-500 black" style={{ width: 180, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{record?.proper_name || record.basic_name}</div>
                                            {type?.value !== 2 && <StockLivePrice fundData={record} symbol={record?.symbol || record.nse_symbol} format={10} />}
                                        </div>
                                    </div>
                                </Tooltip>,
                                width: 150,
                                fixed: window.innerWidth > 760 ? 'left' : "",
                                show: true
                            }, ...liveTradeCols.map((col) => {
                                return {
                                    ...col,
                                    "render": (text, record, index) => <div style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "center" }}>
                                        {displayValue({
                                            text, metricObj: col
                                        })}
                                    </div>
                                }
                            })]}
                            rowKey={(record) => record.plan_id || record.symbol}
                            scroll={{ x: 'max-content' }}
                        />

                        <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                            <div className='textSM'>{liveTradeData?.message}</div>
                            <div className='textXS' style={{ paddingTop: "1rem", paddingBottom: "1rem", }}>
                                <b>Disclaimer: </b>
                                {liveTradeData?.disclaimer}
                            </div>
                            <div>
                                <SharpelyGateway2
                                    EXECUTE_BEFORE_INVEST={() => placeLiveTradeOrderAndSaveStrategy()}
                                    AFTER_SUCCESS_INV={async () => {
                                        console.log("Invested")
                                        var res = await AdminServices.updatePortfolioAll().then((i) => { return i })
                                        console.log(res)
                                        navigate('/strategies')
                                    }}
                                    IS_STRATEGY_LIVE={true}
                                    INSTRUMENT_TYPE={type.value}
                                    ORDERS={liveTradeOrders}
                                    FUNDDATA={fundDetails}
                                    btnText={"Execute order"}
                                />
                                {/* <SubscribeContentWrapper onClick={() => {
                                    placeLiveTradeOrderAndSaveStrategy()
                                }} moduleKey={type?.value === 4 ? "BUILD,_BACKTEST_AND_PAPERTRADE" : type?.value === 1 ? "BUILD,_BACKTEST_AND_PAPERTRADE_ETFs" :
                                    "BUILD,_BACKTEST_AND_PAPERTRADE_MF"} checkLimit usageCount={userTotalStrategies}>
                                     <button className='btnPrimary align-items-center' style={{
                                        gap: "0.5rem",
                                        alignItems: "center",
                                        alignContent: "center"
                                    }} >Execute order <BsArrowRight /> 
                                    
                                </button>
                            </SubscribeContentWrapper> */}
                            </div>
                        </div>
                    </div>
                </div> : <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", }}>
                    <div>{liveTradeData?.message}</div>
                </div>
            }
        </div >
    });


    const TopBanner = () => {

        return (
            <Card style={{ paddingTop: "1.25rem", paddingBottom: "1.25rem" }} id="topHeadingDetailCard" bodyStyle={{ padding: 0 }}
                className={`leftRightPadContainer ${isDev ? 'maxWidthContainer' : ""}`}>
                {isCloned && <Alert style={{ marginBottom: 20 }}
                    message={<span className="textESM w-500 dark4">
                        You have cloned this strategy. You can edit strategy parameters or {type?.dname} selection model and also paper trade this strategy.
                    </span>}
                    type="info"
                    showIcon
                    closable
                />}
                <div style={{ display: "flex", gap: "1.25rem", justifyContent: "space-between", flexDirection: isMobile ? "column" : "row" }}>
                    <div className="d-flex" style={{ gap: "1rem" }}>
                        <div>
                            <div style={{ display: "flex", flexDirection: "column", gap: "0.3rem", }}>
                                <h1 className={isMobile ? "margin0 textMD w-700" : "margin0 textLG w-700"} style={{}}>
                                    {fundDetails?.config?.strategy_name}
                                </h1>
                                <div className="d-flex align-items-center" style={{ gap: "1rem" }}>
                                    {strategy_config?.is_published !== 0 && <Tag color={strategy_config?.is_published === 1 ? "green" : strategy_config?.is_published === 2 ? "blue" :
                                        strategy_config?.is_published === -1 ? "red" : "default"} className="textXS w-500" style={{ padding: "0.1rem 0.5rem", cursor: "pointer" }} >
                                        {strategy_config?.is_published === 1 ? "Published" : strategy_config?.is_published === 2 ? "Under Review" : strategy_config?.is_published === -1 ? "Rejected" : ""}
                                    </Tag>}
                                    <Badge status="success" text={strategy_config?.is_live === 1 ? "Live" : "Paper trade"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column' style={{ alignItems: isMobile ? "flex-start" : "flex-end" }}>
                        <div>
                            <span className="textXS w-500 black" style={{
                                display: "flex", flexDirection: "row", gap: "0.3rem", flex: 1, minWidth: "auto",
                                justifyContent: "flex-end"
                            }}>
                                <span style={{}} className='textXL w-700 black'>{CurrencyFormat(fundDetails?.current_val, 'long', 2, '')}</span>
                                <span className="dark3 mt-2 textSM w-500" style={{ textAlign: "right" }}>
                                    <RedGreenText text={`${DecimalValueFormat({ num: fundDetails?.current_val - (fundDetails?.current_val / (1 + (fundDetails?.day_change_per / 100))), suffix: '', decimals: 1 })?.replace("NaN", "0.0")} (${DecimalValueFormat({ num: fundDetails?.day_change_per, suffix: "%", decimals: 2 })?.replace("NaN", "0.00")})`} value={fundDetails?.day_change_per} />
                                </span>
                            </span>
                            <div className="w-500 textXS dark3" style={{ whiteSpace: "nowrap", textAlign: isMobile ? 'left' : "right" }}>{new Date(fundDetails?.last_updated_timestamp)?.toLocaleString('en-IN', {
                                day: "2-digit", month: "short", hour: "numeric",
                                minute: "2-digit", timeZone: 'Asia/Kolkata',
                            })}</div>
                        </div>
                    </div>
                </div>
                <div style={{
                    display: "flex", gap: "1.25rem", justifyContent: "space-between", marginTop: "1rem", alignItems: isMobile ? "start" : "center",
                    flexDirection: isMobile ? "column" : "row"
                }}>

                    <div style={{ marginBottom: "0.75rem", flex: 2, gap: "1.25rem", }}>

                        <div className='p-0 metricDescDrawer textXS' dangerouslySetInnerHTML={{ __html: `${fundDetails?.config?.strategy_desc?.replaceAll("{\"\n\"}", "")}` }}>
                        </div>
                    </div>
                    {strategy_config?.is_live !== 1 && <div style={{ display: "flex", flex: 1, justifyContent: "flex-end", gap: "1rem" }}>
                        {user?.id === strategy_config?.client_id ? <button className="btnWhite " onClick={(e) => {
                            if (isSharpelyMember(user?.id)) {
                                if (strategy_config?.is_published !== 0) {
                                    openNotificationWithIcon("info", 'Published strategy', 'Since this strategy is published, it cannot be live traded. If you want to start live trading in this strategy, make a similar strategy and trade.')
                                }
                                else {
                                    setliveOrderModal(true)
                                }
                            } else {
                                openNotificationWithIcon("info", "Coming soon!!", "Live trading in strategies will be live soon.")
                            }
                        }} style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                            <TbChartCandleFilled />
                            Start live trading
                        </button> : isCloned ? <button className="btnWhite" onClick={() => {
                            generateCloneOrder()
                        }}>
                            Paper Trade
                        </button> : <CloneItemModal onConfirm={async () => {
                            let data = {
                                "clientId": user?.id,
                                "publisherId": strategyPublisherProfile?.UID,
                                "type": "STRATEGY",
                                "typeId": MINTBOX_ID,
                                "publishedDate": new Date().toISOString(), // cloning date
                                "isPublished": 1,
                                "isDeleted": 0
                            }
                            await apiHelper({ apiName: "addClonedStrategy", data: { data } });
                            setIsCloned(true);
                            dem();
                        }} moduleKey="CLONE_COMMUNITY_STRATEGIES" type='strategy' userCloneCount={userClonedStrategiesNum} />}
                        {((user?.id === strategy_config?.client_id)) && strategy_config?.is_published === 0 && !strategy_config?.cloned_from && <SubscribeContentWrapper moduleKey="PUBLISH_STRATEGIES" onClick={() => {
                            if (!userPublisherProfile?.IsApproved) {
                                if (userPublisherProfile?.IsApproved === 0)
                                    openNotificationWithIcon("warning", "Pending approval", "Your profile is under review. Once approved then you will be allowed to publish this screen", <Space>
                                        <Link to="/user-profile?tab=publisherprofile" target="_blank">
                                            <button className="btnPrimary">
                                                View profile
                                            </button>
                                        </Link>
                                    </Space>)
                                else
                                    openNotificationWithIcon("info", "Incomplete Publisher profile", "Please complete your publisher profile to start publishing screens.", <Space>
                                        <Link to="/user-profile?tab=publisherprofile" target="_blank">
                                            <button className="btnPrimary">
                                                Complete profile
                                            </button>
                                        </Link>
                                    </Space>)
                            }
                            else if (fundDetails?.config?.cloned_from) {
                                openNotificationWithIcon("info", "Cloned Strategy", "Since this strategy has been cloned, it cannot be published.")
                            }
                            else if (strategy_config?.is_live === 1) {
                                openNotificationWithIcon("info", 'Live strategy', 'Since this strategy is live traded, it cannot be published. If you want to publish it, make a similar strategy and publish.')
                            }
                            else {
                                if (strategy_config?.is_published !== 1) {
                                    if ((monthDiff(new Date(fundDetails?.config?.go_live_timestamp), new Date())) <= 1) {
                                        openNotificationWithIcon("info", "Not suitable to publish", "A strategy must complete atleast 30 days.")
                                    } else {
                                        updateStrategyParams(strategy_config, 2)
                                    }
                                }
                            }

                        }} disabled={strategy_config?.is_published === 1}>
                            <button className='btnBlack d-flex align-items-center' style={{
                                background: strategy_config?.is_published !== 1 ? "" : "var(--white)",
                                color: strategy_config?.is_published !== 1 ? "" : "var(--green)",
                                borderColor: strategy_config?.is_published !== 1 ? "" : "var(--green)",
                                gap: "0.25rem"
                            }}  >
                                {strategy_config?.is_published === 1 && <BiSolidCheckCircle size='1rem' />}{strategy_config?.is_published !== 1 ? 'Publish strategy' : "Published"}
                            </button>
                        </SubscribeContentWrapper>}
                        {user?.id === strategy_config?.client_id && <Popconfirm title={<span className='textSM w-500 dark4' style={{ userSelect: "none" }}>
                            Are you sure you want to delete this<br /> strategy?</span>}
                            description={<span className='textXS w-500 dark3 mt-3' style={{ userSelect: "none" }}>Note: This action is irreversible.</span>}
                            onConfirm={(e) => {
                                e.stopPropagation()
                                deleteStrategy();
                            }} arrow={false} placement='topLeft'>
                            <button className="btnWhite">
                                <MdDelete color="var(--red)" />
                            </button>
                        </Popconfirm>}
                    </div>}



                </div>
            </Card>
        )
    }

    const TopBanner2 = () => {
        return (
            <Card style={{ paddingTop: "1.25rem", paddingBottom: "1.25rem" }} id="topHeadingDetailCard" bodyStyle={{ padding: 0 }}
                className={`leftRightPadContainer ${isDev ? 'maxWidthContainer' : ""}`}>
                <div style={{ display: "flex", gap: "1.25rem", justifyContent: "space-between", flexDirection: isMobile ? "column" : "row" }}>
                    <div className="d-flex" style={{ gap: "1rem" }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: "0.3rem", }}>
                            <h1 className={isMobile ? "margin0 textMD w-700" : "margin0 textLG w-700"} style={{}}>
                                {fundDetails?.config?.strategy_name}
                            </h1>
                            <div className='p-0 metricDescDrawer textXS' dangerouslySetInnerHTML={{ __html: `${fundDetails?.config?.strategy_desc?.replaceAll("{\"\n\"}", "")}` }}>
                            </div>
                            <Badge status="warning" text="Pending execution of first order" />
                        </div>
                    </div>
                </div>
                {/* <button className='btnWhite' onClick={() => {
                    //seteditBasketDrawer(true)
                }}>
                    <img loading="lazy"  alt="" style={{ height: "0.75rem", marginRight: "0.5rem", marginBottom: "0.1rem" }} src={`${flaticonSvg}fi-rr-edit.svg`} />
                    <span className='textSM black w-500'>Edit strategy</span>
                </button> */}
            </Card>
        )
    }


    const placeManualOrder = async (orders) => {
        var res = await AdminServicesPY.placeManualOrder(MINTBOX_ID, {
            orders: JSON.stringify(orders)
        })?.then((r) => r)
        if (res?.type) {
            toast.success(JSON.parse(res?.data?.replace(/NaN/g, null)?.replace(/Infinity/g, null))?.message)
            navigate(`/strategy-detail/${String(strategy_name)?.toLowerCase()?.replaceAll(" ", "-")}/${MINTBOX_TYPE}/${MINTBOX_ID}`)
            // //seteditBasketDrawer(false)
            setOpenManualRebalanceDrawer(false)
            setrebalanceStocks([])
            dem()
        } else {
            toast.error("Something went wrong!!")
        }
        //////console.log(res)
    }


    const ContentView = () => {
        return <>
            <CustomTabs tabItems={tabItems?.filter((i) => i?.show !== false)} value={selectedTabItem} onChange={(e) => { setSelectedTabItem(e) }} childContainerId='stockPortDetCustomTabsChild' />
            <div style={{}} id={"stockPortDetCustomTabsChild"} className={`${isDev ? 'maxWidthContainer' : ""}`}>
                {tabItems?.find((el) => el.value === selectedTabItem)?.children}
            </div>
        </>
    }

    if (loading) {
        return <div className='loaderContainer' >
            <ActivityLoaderMintbox showQuote={true} message={['1W', '2W']?.includes(fundData?.RebalanceFrequency) ? `This strategy is rebalanced ${fundData?.RebalanceFrequency === '1W' ? "weekly" : "biweekly"}. Backtesting might take a few seconds.` : ""} />
        </div>
    }
    if (fundDetails?.last_updated_timestamp) {
        return (
            <React.Fragment>
                {contextHolder}
                <Head title={`${String(type?.omkar)} strategy details`} />
                <AccessControlledPage reqAccessLevel={1} moduleKey={isLocked ? "BUILD,_BACKTEST_AND_PAPERTRADE" : ""} isDefaultOpen={isLocked} subModalParams={isLocked ? {
                    isLimitType: true,
                    customAccessObj: {
                        ModuleName: "Strategy Report",
                        ModuleDesc: `This strategy report is locked. With your current plan ${strategyDetailedAccess?.maxLimit === 0 ? 'you cannot view any of your saved strategies. To view detailed performance and historical analysis of your saved strategies, upgrade your plan.' : `you can only view your first ${strategyDetailedAccess?.maxLimit} saved strategies.
                        To view detailed performance and historical analysis of your other strategies, upgrade your plan.`}`
                    }
                } : {}} />
                <div className='normalContainer' style={{ gap: 0 }}>
                    <TopBanner />
                    {ContentView()}
                </div>
                <Drawer
                    open={cloneModal}
                    onClose={() => {
                        setCloneModal(!cloneModal)
                    }}
                    closable={false}
                    width={isMobile ? "100%" : "90vw"}
                    title={<div className='d-flex justify-content-between align-items-start'>
                        <div>
                            <div>
                                Your virtual orders
                            </div>
                            {strategySaveAccess?.maxLimit !== Infinity && <div>
                                <div style={{ userSelect: "none" }}>
                                    <span className="textXS dark3 w-500">Credits: <span className='dark4 w-700'>
                                        <b style={{ color: strategySaveAccess?.remainingLimit === 0 ? "red" : "var(--primary)" }}>{strategySaveAccess?.remainingLimit}</b>
                                        <b>/{strategySaveAccess?.maxLimit}</b></span>.&nbsp;
                                        {strategySaveAccess?.currentAccessLevel !== 4 && <span>Upgrade to <Link className="dark4 w-700" to='/pricing' target="_blank">
                                            <PlanName accessLevel={strategySaveAccess?.currentAccessLevel + 1} /></Link>.</span>}
                                    </span>
                                </div>
                            </div>}
                        </div>
                        <IoIosClose size={'1.5rem'} onClick={() => setCloneModal(false)} style={{ cursor: "pointer" }} />
                    </div>}
                    footer={null}
                    styles={{ header: { padding: "1rem 1.25rem" }, body: { padding: "0rem" } }}>
                    <div className='d-flex flex-column w100 h100' style={{ overflow: "auto", padding: "1.25rem" }}>
                        <PaperTradePanel />
                    </div>
                </Drawer>

                <Drawer
                    open={liveOrderModal}
                    onClose={() => {
                        setliveOrderModal(!liveOrderModal)
                    }}
                    closable={true}
                    width={"90vw"}
                    title={<div
                        className='d-flex justify-content-between align-items-center'>
                        Your orders
                    </div>}
                    footer={null}
                    styles={{ header: { padding: "1rem 1.25rem" }, body: { padding: "0rem" } }}>
                    <div className='d-flex flex-column w100 h100 my-4' style={{ overflow: "auto", padding: "0rem 2rem" }}>
                        <div className="textXS textLight">Investment amount</div>
                        <div className="mb-2" style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
                            <LabelInputNew
                                width={300}
                                style={{ width: 300 }}
                                min={0}
                                placeholder={`Enter amount you want to invest`}
                                value={live_tade_capital}
                                onChangeVal={(e) => {
                                    let val = Number(e)
                                    set_live_trade_capital(val)
                                    setTimeout(() => {
                                        generateLiveOrder(val)
                                    }, 100);
                                }}
                            />
                            <button className="btnPrimary" onClick={() => { generateLiveOrder() }}>Generate order</button>
                        </div>
                        {live_tade_capital && ((live_tade_capital >= Number(liveTradeOrders?.[0]?.min_inv_amount)) ? <LiveTradePanel /> : <div className="textSM">Investment amount should be greater than <span className="w-bold">{CurrencyFormat(Number(liveTradeOrders?.[0]?.min_inv_amount))}</span></div>)}
                    </div>
                </Drawer>

                <CustomDrawer title={<div style={{ display: 'flex', gap: '0.75rem', alignItems: 'center' }}>
                    {type?.value === 4 ?
                        <AMCIcon amcName={selectedFund?.symbol} fundType={4} height='1.5rem' width="1.5rem" bseTicker={selectedFund?.bse_ticker} /> :
                        <AMCIcon amcName={selectedFund?.amc_full_name} height='1.5rem' width="1.5rem" />}
                    <div className="textSM w-700 black">{selectedFund.basic_name || selectedFund.full_name || selectedFund.proper_name}</div>
                </div>} toggle={() => setChildrenDrawer(!childrenDrawer)} isOpen={childrenDrawer} showBackBtn={true}>
                    {childrenDrawerloading ? <ActivityLoaderMintbox /> : type?.value === 4 ? <AccessComponent component={<StockInsightsModal fetchFromApi={true} ticker={selectedFund?.symbol} />}
                        upgradeComponent={<UpgradeToPro accessObj={accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === "STOCK_INSIGHTS")} height="20rem" width="90%" />}
                        reqAccessLevel={accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === "STOCK_INSIGHTS")?.AccessLevel} />
                        :
                        <FundInsightsModal insights={fund_insight} type={type} />}
                </CustomDrawer>

                {showRebalModal && <Modal open={showRebalModal} width={"30vw"} onCancel={() => {
                    setshowRebalModal(false)
                }} centered footer={null}>
                    <div>
                        <div className="w-700 textLG dark4">Want to rebalance your strategy?</div>
                        <div className="textSM dark4 mt-2">This will trigger a manual rebalance in your portfolio and the rebalance will be executed now. Only use this if you feel that your portfolio has deviated a lot from its intended targets. Also note that if your stock selection model is dynamic, the rebalance will also change stocks in your portfolio. This is different from automatic rebalance which will not be impacted and will continue to happen as per set schedule.</div>

                        <div className="d-flex align-items-center justify-content-end" style={{ gap: "1.25rem", marginTop: "1rem" }}>
                            <button className="btnWhite" onClick={() => {
                                setshowRebalModal(false)
                            }}>Cancel</button>
                            <button className="btnBlack" onClick={() => {
                                setshowRebalModalLoading(true)
                                setshowRebalModal(false)
                                setOpenManualRebalanceDrawer(!openManualRebalanceDrawer)
                                generateRebalOrder(2)
                            }}>Rebalance now</button>
                        </div>
                    </div>
                </Modal>}

                {openManualRebalanceDrawer && <Drawer
                    open={openManualRebalanceDrawer}
                    onClose={() => {
                        setOpenManualRebalanceDrawer(!openManualRebalanceDrawer)
                    }}
                    closable={true}
                    width={isMobile ? "100%" : fundDetails?.config?.strategy_type_id === 2 ? "50vw" : "90vw"}
                    title={<div
                        className='d-flex justify-content-between align-items-center'>
                        Your rebalance orders
                    </div>}
                    footer={null}
                    styles={{ header: { padding: "1rem 1.25rem" }, body: { flex: 1, padding: "0rem" } }}>
                    {showRebalModalLoading ? <ActivityLoaderMintbox />
                        : fundDetails?.config?.strategy_type_id === 2 ? <ManualRebalOrderPanel
                            fundDetails={fundDetails}
                            type={type}
                            allFundsData={allFundsData}
                            GetLTP={(e) => GetLTP(e)}
                            placeManualOrder={placeManualOrder}
                        />
                            : <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "1rem", padding: '1.25rem' }}>
                                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", flex: 2 }}>
                                    <MyTable
                                        // loading={isPaperTradeView}
                                        dataSource={rebalanceStocks}
                                        columns={[{
                                            "title": "Name",
                                            'disableDeleteInCustomize': true,
                                            "dataIndex": type?.value === 4 ? "proper_name" : "basic_name",
                                            render: (text, record, index) => <Tooltip title={record.proper_name || record.basic_name} arrow={false}>
                                                <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center" }}>
                                                    <AMCIcon amcName={type?.value === 4 ? record.symbol : record.amc_full_name} fundType={type?.value} height='1.15rem' width="1.15rem" bseTicker={record?.bse_ticker} />
                                                    <div>
                                                        <div className="w-500 black" style={{ width: 180, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{record?.proper_name || record.basic_name}</div>
                                                        {type?.value !== 2 && < StockLivePrice fundData={record} symbol={record?.symbol || record?.nse_symbol} format={10} />}
                                                    </div>
                                                </div>
                                            </Tooltip>,
                                            // width: 150,
                                            fixed: window.innerWidth > 760 ? 'left' : "",
                                            show: true
                                        }, ...rebalanceCols.map((col) => {
                                            return {
                                                ...col,
                                                "render": (text, record, index) => <div style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "center" }}>
                                                    {displayValue({
                                                        text, metricObj: col
                                                    })}
                                                </div>
                                            }
                                        })]}
                                        rowKey={(record) => record.plan_id || record.symbol}
                                        scroll={{ x: 'max-content', y: "80vh" }}
                                    />
                                </div>
                                <Card bordered>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", flex: 1 }}>
                                        <div className='textSM'>{rebalanceOrder?.message}</div>
                                        <div className='textXS' style={{ paddingTop: "1rem", paddingBottom: "1rem", }}>
                                            <b>Disclaimer: </b>
                                            {rebalanceOrder?.disclaimer}
                                        </div>
                                        <div><button className='btnPrimary align-items-center' style={{
                                            gap: "0.5rem",
                                            alignItems: "center",
                                            alignContent: "center"
                                        }} onClick={() => {
                                            placeRebalanceOrder()
                                        }}>Execute order <BsArrowRight /></button></div>
                                    </div>
                                </Card>
                            </div>
                    }
                </Drawer>}
            </React.Fragment >
        );
    } else {
        return <NotInvestedView />
    }
};

export default StrategyDetail;
