import { toggleMetricDescModal } from "Libs/redux/KnowledgeBase/reduxSlice";
import { specialCaseScores } from "Libs/utils";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useDispatch } from "react-redux";


const LabelRenderer = ({ item, screener_Metric_Desc }) => {
    var showInfoIcon = ['style_box_code', ...specialCaseScores]
    const dispatch = useDispatch();

    if (showInfoIcon?.includes(item?.metric_col_code)) {
        return <><AiOutlineInfoCircle onClick={() => {
            dispatch(toggleMetricDescModal({
                value: true,
                params: {
                    title: screener_Metric_Desc?.filter((i) => i.desc_id === item.desc_id)[0]?.name || item?.metric_name,
                    description: String(screener_Metric_Desc?.filter((i) => i.desc_id === item.desc_id)[0]?.description)?.replaceAll('\"color: black;\"', '')?.replaceAll(".html", "")?.replaceAll("beta.","")
                }
            }))
        }} style={{
            fontSize: "15px",
            // color: "var(--primary)"
        }} />
        </>
    } else {
        return <></>
    }
};

export default LabelRenderer;