import { Card, Collapse, Modal } from 'antd';
import React from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleHelpAndSupp } from "Libs/redux/UserAuth/reduxSlice";
import { BiMinus, BiPlus } from 'react-icons/bi';
import { HiOutlineArrowSmRight } from 'react-icons/hi';

export const FAQs = () => {
    const dispatch = useDispatch();
    const isMobile = window.innerWidth <= 575;
    const [faqModal, setFaqModal] = React.useState(false);
    const faqs = [
        {
            title: "What instruments can I screen using sharpely screeners?",
            content: <span>
                <div>
                    Currently, we provide screeners for 3 types of instruments.
                </div>
                <ol className='marginTopFAQ'>
                    <li>Indian Stocks</li>
                    <li>Mutual Fund</li>
                    <li>ETFs</li>
                </ol>
            </span>,
        },
        {
            title: "Can I buy and sell individual stocks, ETFs, and Mutual Funds on sharpely?",
            content: <span>
                <div>Yes, you can. If you already have a demat account, you just need to link your demat account and invest in just a few clicks. If you don't have a demat, you can create one on the app without any paperwork.</div>
                <div className='marginTopFAQ'>However, MF investments are currently not supported due to regulatory issues. We will be adding MF support soon.</div>
            </span>,
        },
        {
            title: "What are guru stock screens?",
            content: <span><div>
                Guru stocks screens are ready-made screens based on the frameworks created by veteran investors and scholars. Each screen will have certain pre-set filters based on the investment framework of different investment Gurus. Currently, you can see the investment frameworks created by Benjamin Graham, O'Shaughnessy, and John Nash. We will keep adding more.</div></span>,
        },
        {
            title: "How can I find stocks of a particular sector?",
            content: <span>
                <div>
                    You can find stocks of a particular sector in the <b>Sector analysis</b> tab. You can open this by clicking the <b>Stocks</b> dropdown and selecting the <b>Sector analysis</b> option.
                </div>
            </span>,
        },
        {
            title: "How can I find red flags in a particular stock?",
            content: <span>
                <div>You can find out the red flags in any stock very easily. For that, you just have to open the stock page and click on the insights tab. You will find the red flags in the red flags tab</div>
            </span>
        },
        {
            title: "How frequently is the data updated?",
            content: <span>
                <div>All price and volume data is available almost near time. However, data for screeners is updated after the end-of-the-day closing. We will be adding support for real-time screening soon.</div>
                <div className='marginTopFAQ'>
                    All fundamental data is updated as and when they become available. Learn more about our data and methodology
                    <Link to='/knowledge-base/data-&-methodology'> here.</Link>
                </div>
            </span>
        },
        {
            title: "How reliable is the data?",
            content: <span><div>
                We would like to think that our data is extremely reliable because (1) we source the raw data from best-in-class vendors and (2) have a top-notch data processing engine. Learn more about our data and methodology
                <Link to='/knowledge-base/data-&-methodology'> here.</Link>
            </div>
                <div className='marginTopFAQ'>
                    And we do take the quality and reliability of our data very seriously. If you do find something that looks wrong, do report <span onClick={() => {
                        dispatch(toggleHelpAndSupp(true))
                    }} style={{ cursor: "pointer", color: "var(--primary)" }}> here.</span>
                </div>
            </span>
        },
        {
            title: "What are baskets?",
            content: <span>
                <div>At sharpely, it is our strong belief that a portfolio approach to investing is the best approach. Therefore, instead of picking individual Stocks/ETFs/MFs, investors should rather focus on building a portfolio.</div>
                <div className='marginTopFAQ'>
                    But building a portfolio is just the starting point. You need to manage that portfolio. One key aspect of automated systematic investing is that the entire process of portfolio management is based on a certain set of executable rules. These automated rules are your portfolio management strategy. Baskets are nothing but strictly defined portfolio management strategies.
                </div>
                <div className='marginTopFAQ'>
                    Learn more about baskets <Link to='/knowledge-base/baskets'> here.</Link>
                </div>
            </span>
        },
        {
            title: "Can I create and invest in my own custom baskets?",
            content: <span>
                <div>
                    Of course! The ability to create and invest in your own custom baskets is one of our core features. You can create simple baskets or go for baskets with complex rules. And the best part is that you can either paper invest and track that basket or invest real money. Learn everything about creating custom stock baskets
                    <Link to='/knowledge-base/baskets/custom-stock-baskets/building-a-static-stock-basket'> here</Link>  and custom ETF/MF baskets <Link to='/knowledge-base/baskets/custom-mf-and-etf-baskets/building-a-static-mf-or-etf-basket'> here.</Link>
                </div>
            </span>
        },
        {
            title: "What is paper investing?",
            content: <span>
                <div>
                    When you are researching for the best set of stocks (or mutual funds) or the best strategy to invest your money, you would like to see (1) how it has performed historically and (2) how it performs going forward.
                </div>
                <div className='marginTopFAQ'>
                    As soon as you create a basket, you can access the historical backtested performance. At the same time, the basket is saved against your account (paper invested). You can track the performance of the basket from the date you created it. Learn more about paper investing &nbsp;
                    <Link to='/knowledge-base/baskets/custom-mf-and-etf-baskets/saving-and-tracking-mf-or-etf-baskets-(paper-investing)'>
                        here.</Link>
                </div>
                <div className='marginTopFAQ'>
                    Once you are satisfied, you can invest real money in the basket.
                </div>
            </span>
        },
        {
            title: "How do I track my investments?",
            content: <span>
                <div>You can go to <b>Investments</b> and get a detailed overview of all your investments. Get a comprehensive portfolio report including XIRR, growth, distribution, and much more. You also get access to transaction and capital gains reports.

                </div>
            </span>
        },
        {
            title: "Can I sell my investments? Is there any restriction on selling?",
            content: <span>
                <div>
                    If you can buy, you can sell! You can sell your investments anytime you like. We do not impose any restrictions whatsoever.
                </div>
                <div className='marginTopFAQ'>
                    However, there are cases that are out of our control:
                </div>
                <ul className='marginTopFAQ'>
                    <li>
                        MF scheme has a lock-in (closed-ended funds or ELSS) or suspends redemption (we have seen this!)
                    </li>
                    <li>
                        Your stock gets suspended from trading
                    </li>
                </ul>
            </span>
        },
        {
            title: "What are Curated Baskets?",
            content: <span>
                <div>Curated baskets are low-cost, ready-to-invest portfolios created using proprietary algorithms of sharpely. Curated baskets have 2 choices for instruments (MF and ETF). Baskets are available for different risk levels. You can find the risk level, expense ratio, and past returns of each basket on the curated basket dashboard. Learn more about curated baskets&nbsp;
                    <Link to='/knowledge-base/baskets/curated-baskets/what-are-curated-baskets'>here.</Link>
                </div>
            </span>
        },
        {
            title: "Can I invest in curated baskets?",
            content: <span>
                <div>
                    Yes, you can! The whole purpose of curating these baskets is to help investors who do not have the expertise/bandwidth to build their own investment portfolio.
                </div>
                <div className='marginTopFAQ'>
                    However, investment in MF baskets is currently not available due to regulatory constraints. We will be adding MF support soon.
                </div>
            </span>
        },
        {
            title: "How can I select the best curated basket for my investment portfolio?",
            content: <span>
                <div>
                    Before selecting a curated basket, you should have a clear idea of your risk appetite and return expectations. You can also look at the overview section of different baskets. Based on that you can filter the baskets and select the most suitable option.
                </div>
            </span>
        },
        {
            title: "Why do I see the same basket twice on the curated basket dashboard?",
            content: <span>
                <div>
                    Even though the name of the baskets is the same, they have different underlying investment vehicles (MF/ETF). You can see the type of underlying investment vehicle of the bracket after the name. The returns and expense ratios of curated baskets with the same names will also differ slightly.
                </div>
            </span>
        },
        {
            title: "How can I use stock StyleBox in my investment research? How can I find avoidable stocks using sharpely?",
            content: <span>
                <div>
                    You can use Stylebox to find avoidable stocks. Out of 10 StyleBox categories, if the stock is in the bottom 5 categories, you should be extremely careful while investing in the stock. These 5 categories are value trap, momentum trap, out of favour, fallen angel and don’t touch.
                </div>
            </span>
        },
        {
            title: "How can I find the best mutual funds using sharpely?",
            content: <span>
                <div>
                    Selecting the right mutual funds will need a complete understanding of your risk appetite and return expectations. You can then use mutual fund screener and comparison tools on sharpely to find suitable funds accordingly. We have discussed the selection process in much more detail&nbsp;
                    <Link to='/knowledge-base/mf-masterclass/mutual-fund-analysis-and-framework/how-to-select-mutual-funds-for-your-portfolio:-finding-the-right-category'>
                        here.
                    </Link>
                </div>
            </span>
        },
        {
            title: "Which features of sharpely are useful for intraday trading?",
            content: <span>
                <div>
                    Currently, the platform is meant for long-term investors. Therefore, all the tools and features of sharpely are created for investment-related research. As someone who trades frequently using technical analysis, you won’t find much value.
                </div>
                <div className='marginTopFAQ'>
                    Having said that, we have already started work on sharpely Trade!
                </div>
            </span>
        },
        {
            title: "Can I find the target price of stocks in sharpely?",
            content: <span>
                <div>
                    Yes, you can find the target price of stocks that have analyst coverage. You can find the target price of these stocks in the estimates section of the stock. Just search the stock's name and scroll down until you see the estimates tab. Do remember that sharpely does not provide any estimates. These estimates (target price, earnings, etc) are shown based on the analyst coverage.
                </div>
            </span>
        },
        {
            title: "Can I compare stocks on sharpely?",
            content: <span>
                <div>
                    Yes. You can compare multiple stocks on sharpely. We provide one of the most advanced stock comparison tools. You can compare stocks based on more than 200 parameters using sharpely.
                </div>
            </span>
        },
        {
            title: "Can I compare mutual funds on sharpely?",
            content: <span>
                <div>
                    Yes. You can compare mutual funds on sharpely. We provide one of the most advanced mutual fund comparison tools. You can compare stocks based on more than 50 parameters using sharpely.
                </div>
            </span>
        },
        {
            title: "Where can I learn more about Stock scores and StyleBox?",
            content: <span>
                <div>
                    We have written detailed articles explaining all our proprietary stock scores and StyleBox classification methodology.
                    Check it out&nbsp; <Link to='/knowledge-base/stock-scores'> here.</Link>
                </div>
            </span>
        },
        {
            title: "What is the difference between a stock screener and factor model?",
            content: <span>
                <div>
                    Using a stock screener, you can screen stocks fulfilling certain conditions. But there will be problems with edge cases. For example, if you filter stocks with P/E &lt; 20, then good stocks with a P/E value of 20.1 will be left out from the screener. To solve this problem, we have built stock factor models. Using this you will be able to get an aggregate score rather than absolute value and will be able to include edge cases. To dive deep into stock factor models and how to use them, click&nbsp;
                    <Link to='/knowledge-base/stocks/factor-models/introducing-factor-model'>here.</Link>
                </div>
            </span>
        },
        {
            title: "Which type of financial statements will I be able to see in sharpely?",
            content: <span>
                <div>
                    Every company with subsidiaries will have 2 types of financial statements. Standalone and consolidated. We believe that to have a complete understanding of any business, you should always look at consolidated financial statements. So in sharpely, we provide only consolidated financial statements.
                </div>
            </span>
        }
    ]
    return (
        <>
            <Card style={{ borderRadius: "var(--borderRadius)", padding: "0px" }} bodyStyle={{ padding: "0rem" }}
                className='marketing mobCardBorderRadius0'>
                <div className="padAll mobileFAQ" style={{ background: "white", borderRadius: "var(--borderRadius)", background: "var(--white)" }} >
                    <div className='d-flex justify-content-between flex-column flex-sm-row' style={{ gap: "2.5rem" }} >
                        <div className="marHomeResMainCard">
                            <h2 className='headLGtoXS black w-700 margin0' style={{ width: "11ch" }}>Frequently asked questions</h2>
                            <div className="textMD dark3" style={{ marginTop: "0.75rem" }}>
                                Still have question? <span style={{ color: "var(--primary)", cursor: "pointer" }} onClick={() => {
                                    dispatch(toggleHelpAndSupp(true))
                                }}>Contact us</span>
                            </div>
                        </div>
                        <div className="flexCard scrollbox" >
                            <Collapse style={{ width: "100%", background: "transparent" }} bordered={false}
                                expandIcon={({ isActive }) => isActive ? <BiMinus size='0.875rem' color='var(--primary)' /> : <BiPlus size='0.875rem' color='var(--primary)' />}
                                expandIconPosition='start' items={faqs?.slice(0, 3).map((ele, i) => {
                                    return {
                                        key: i,
                                        label: <span className="textMDtoESM w-500">{ele.title}</span>,
                                        children: <span className="textSMtoESM dark3 w-500">{ele.content}</span>,
                                        style: {
                                            background: "var(--white)",
                                            borderBottom: "1px solid var(--grey3)",
                                            padding: '0.75rem',
                                            borderRadius: "var(--borderRadius)",
                                            borderTop: "none"
                                        }
                                    }
                                })} />
                            <div className='d-flex  align-items-center textMDtoSM w-500' style={{ gap: "0.25rem", color: "var(--primary)", cursor: "pointer" }}
                                onClick={() => setFaqModal(true)}>
                                See all FAQs  <HiOutlineArrowSmRight />
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
            <Modal open={faqModal} footer={null} onCancel={() => setFaqModal(false)} onOk={() => setFaqModal(false)} closable={true} width="min(100%, 40rem)" >
                <div style={{ maxHeight: "70vh", overflow: "auto" }} className='scrollbox'>
                    <Collapse style={{ width: "100%", background: "transparent" }} bordered={false}
                        expandIcon={({ isActive }) => isActive ? <BiMinus size='0.875rem' color='var(--primary)' /> : <BiPlus size='0.875rem' color='var(--primary)' />}
                        expandIconPosition='start' items={faqs?.map((ele, i) => {
                            return {
                                key: i,
                                label: <span className="textMDtoESM w-500">{ele.title}</span>,
                                children: <span className="textSMtoESM dark3 w-500">{ele.content}</span>,
                                style: {
                                    background: "transparent",
                                    borderBottom: "1px solid var(--grey3)",
                                    padding: '0.5rem 0',
                                    borderRadius: "var(--borderRadius)",
                                    borderTop: "none"
                                }
                            }
                        })} />
                </div>
            </Modal>
        </>
    )
}