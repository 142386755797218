import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useGetAssetType } from 'Libs/utils';
import React from 'react';
import { CardHeader } from './atoms/SmallComponents';
import { CurrencyFormat } from './CurrencyFormat';
import CustomSegmentedTab from './CustomSegmentedTab';
import { changeObjKey } from './DataCustomizer';
import { formatDateMMMDDYYYY } from './DateFormatter';

const PerformanceChartCompare = ({ chartData = [], type = 1, fundNames = [], isin_code = [], fundColors = [], xAxisKey = "Date", setSelectedChartInt }) => {
    const isMobile = window.innerWidth <= 760;
    const yrtabs = chartData !== undefined ? [
        { key: "A1", label: "1M", value: "30D", show: chartData?.["30D"]?.data !== undefined ? JSON.parse(chartData?.["30D"]?.data).length > 0 : false },
        { key: "A2", label: "6M", value: "182D", show: chartData?.["182D"]?.data !== undefined ? JSON.parse(chartData?.["182D"]?.data).length > 0 : false },
        { key: "A3", label: "YTD", value: "ytd", show: chartData?.["ytd"]?.data !== undefined ? JSON.parse(chartData?.["ytd"]?.data).length > 0 : false },
        { key: "A", label: "1Y", value: "365D", show: chartData?.["365D"]?.data !== undefined ? JSON.parse(chartData?.["365D"]?.data).length > 0 : false },
        { key: "b", label: "3Y", value: "1095D", show: chartData?.["1095D"]?.data !== undefined ? JSON.parse(chartData?.["1095D"]?.data).length !== JSON.parse(chartData?.["max"]?.data).length : false },
        { key: "c", label: "5Y", value: "1825D", show: chartData?.["1825D"]?.data !== undefined ? JSON.parse(chartData?.["1825D"]?.data).length !== JSON.parse(chartData?.["max"]?.data).length : false },
        { key: "d", label: "10Y", value: "3650D", show: chartData?.["3650D"]?.data !== undefined ? JSON.parse(chartData?.["3650D"]?.data).length !== JSON.parse(chartData?.["max"]?.data).length : false },
        { key: "dm", label: "Max", value: "max", show: chartData?.["max"]?.data !== undefined ? JSON.parse(chartData?.["max"]?.data).length > 0 : false },
    ] : []
    // const yrtabs = [
    //     { key: "A1", label: "1M", value: "30D", show: chartData["30D"] !== undefined ? chartData?.["30D"]?.data.length > 0 : false },
    //     { key: "A2", label: "6M", value: "182D", show: chartData["182D"] !== undefined ? chartData?.["182D"]?.data.length > 0 : false },
    //     { key: "A3", label: "YTD", value: "ytd", show: chartData["ytd"] !== undefined ? chartData?.["ytd"]?.data.length > 0 : false },
    //     { key: "A", label: "1Y", value: "365D", show: chartData["365D"]?.data?.length > 0 },
    //     { key: "b", label: "3Y", value: "1095D", show: chartData["1095D"]?.data?.length !== chartData["365D"]?.data?.length },
    //     { key: "c", label: "5Y", value: "1825D", show: chartData["1825D"]?.data?.length !== chartData["1095D"]?.data?.length },
    //     { key: "d", label: "10Y", value: "3650D", show: chartData["3650D"]?.data?.length !== chartData["1825D"]?.data?.length },
    //     { key: "dm", label: "Max", value: "max", show: chartData["max"]?.data?.length !== chartData["3650D"]?.data?.length },
    // ]
    const screen_type = useGetAssetType()
    const chipset = [
        { "key": "a", "name": "₹ 5,000", value: 5000 },
        { "key": "b", "name": "₹ 10,000", value: 10000 },
        { "key": "c", "name": "₹ 50,000", value: 50000 },
    ]

    const [noChart, setnoChart] = React.useState(false);
    const [chartType, setChartType] = React.useState(yrtabs[0].value)
    const [chartAmount, setChartAmount] = React.useState(chipset[1])

    // console.log(JSON.parse(chartData[chartType]?.data),'/n',isin_code,'/n',fundNames)
    try {
        var options = {
            chart: {
                backgroundColor: "transparent",
                height: 250,
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                visible: false,
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                title: {
                    text: ''
                },
                gridLineColor: 'var(--grey1)',
            },
            xAxis: [{
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%e %b',
                    week: '%e %b',
                    month: '%b %y',
                    year: '%Y'
                },
                lineColor: 'var(--grey1)',
                tickColor: 'var(--grey1)',
            }
            ],
            legend: {
                enabled: false,
                //     useHTML: true,
                //     symbolWidth: 5,
                //     symbolRadius: 6,
                //     itemWidth: 148,
                //     itemStyle: {
                //         color: 'var(--grey1)',
                //         fontSize: 10,
                //         fontWeight: "normal",
                //         overflow: "visible",
                //         marginTop: 5

                //     },
                //     labelFormatter: function () {
                //         return '<div style="margin-bottom:2px">' + this.name.replace(/(.*?\s.*?\s.*?\s)/g, '$1' + '<br>') + '</div>';
                //     },
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                shared: true,
                valueDecimals: 0,
                valuePrefix: '₹',
            },
            credits: {
                enabled: false
            },
            boost: {
                useGPUTranslations: true,
            },
            series:
                isin_code.map((i, index) => {
                    return {
                        name: type !== 4 ? fundNames[index][`fund_${i}`] : fundNames[index][`${i}`],
                        turboThreshold: 9e9,
                        // color: t.colors.secondary,
                        // tooltip: {
                        //     valueSuffix: ` ({point.ret_fund_${i}}%)`,
                        // },
                        tooltip: {
                            valueSuffix: (type === 4 ? ' ({point.ret_' : ' ({point.ret_fund_') + i + '}%)',
                        },
                        marker: { symbol: 'square', radius: 1 },
                        color: fundColors[index],
                        data: changeObjKey(chartData[chartType], [type !== 4 ? `fund_${i}` : i, xAxisKey], ["y", "x"], "mfetfChart", (chartAmount.value / 10000))
                    }
                })
            ,
            scrollbar: {
                enabled: false
            },
            // responsive: {
            //     rules: [{
            //         condition: {
            //             maxWidth: 400
            //         },
            //     }]
            // },

        };
    } catch (error) {
        setnoChart(true)
    }


    // const options = {
    //     global: {
    //         useUTC: true
    //     },
    //     lang: {
    //         decimalPoint: '.',
    //         thousandsSep: ','
    //     }
    // };


    const ContentBeforeChart = () => {

        try {
            if (chartType) {
                var mintData = JSON.parse(chartData?.[chartType]?.data)
            }
            return <div style={{ flex: 1, display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: isMobile ? "flex-start" : "center", gap: "1rem" }}>
                <div className='fontH2' style={{ flex: 1, color: 'var(--black)', display: "flex", alignItems: "center", gap: "0.3rem" }}>
                    <CardHeader heading={'Performance comparison'} headingType={2} />
                </div>
                <div style={{
                    display: 'flex', justifyContent: 'right', borderTop: "1px solid var(--grey3)", borderBottom: "1px solid var(--grey3)", padding: "0.4rem",
                    width: isMobile ? "100%" : "fit-content"
                }} >
                    <CustomSegmentedTab
                        isBlock={false}
                        className='performanceChartYrSegmented'
                        style={{ backgroundColor: "transparent", padding: 0 }}
                        options={yrtabs.filter((ele) => ele.show)}
                        value={chartType}
                        onChange={(e) => { console.log(e); setChartType(e); setSelectedChartInt(e) }}
                    />
                </div>
            </div>
        } catch (error) {
            return <></>
        }
    };




    try {
        if (chartType) {
            var mintData = JSON.parse(chartData?.[chartType]?.data)
        }
        return (
            noChart ? <div className='bodyTextDark' style={{ margin: "4rem", textAlign: "center" }}>
                No chart data available!
            </div>
                : <div>
                    <ContentBeforeChart />
                    <div style={{ marginTop: "0.75rem" }}>
                        <span>Value of <span className='textSM w-500' >{CurrencyFormat(chartAmount.value, 'long', 0)}</span> if invested on <span className='textSM w-500' >{formatDateMMMDDYYYY(mintData[0][xAxisKey])}</span></span>
                    </div>
                    <div>
                        <HighchartsReact highcharts={Highcharts} options={options} />
                        {/* {
                            <div className='textXS' style={{ padding: "4px", textAlign: 'justify' }}>
                                <b>Note: </b>Past performance is not an assurance of future performance. The chart has been shown based on EOD {screen_type.value === 4 ? "prices" : "NAVs"} of the {screen_type.dname}.
                            </div>
                        } */}
                    </div>
                </div>
        );
    } catch (error) {
        return <div className='bodyTextDark' style={{ margin: "4%", textAlign: "center" }}>
            No chart data available!
        </div>
    }
};
export default PerformanceChartCompare;