import axios from "axios";
import jwt_decode from "jwt-decode";

const MINTBOX_CLIENT_ID = 'Li2L9VO1eawEbsgLrHdpZjhmUdW6N8Cm';

const API_ENDPOINT = 'https://api.mintbox.ai/api';

const client = axios.create({
  baseURL: API_ENDPOINT,
  withCredentials: false
});

let countReCall = 0

class DataService {
  static get(path = '', data = {}) {
    const optional = { 'Content-Type': 'application/json' };

    return client({
      method: 'GET',
      url: path,
      headers: { ...axios.defaults.headers, ...optional }
    });
  }

  static post(path = '', data = {}) {
    const optional = { 'Content-Type': 'application/json' };

    return client({
      method: 'POST',
      url: path,
      data: JSON.stringify(data),
      headers: { ...axios.defaults.headers, ...optional }
    });
  }

  static postImg(path = '', data = {}) {
    const optional = { 'Content-Type': 'multipart/form-data' };

    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...axios.defaults.headers, ...optional }
    });
  }

  static patch(path = '', data = {}) {
    const optional = { 'Content-Type': 'application/json' };

    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...axios.defaults.headers, ...optional }
    });
  }

  static put(path = '', data = {}) {
    const optional = { 'Content-Type': 'application/json' };

    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...axios.defaults.headers, ...optional }
    });
  }

  static delete(path = '', data = {}) {
    const optional = { 'Content-Type': 'application/json' };

    return client({
      method: 'DELETE',
      url: path,
      headers: { ...axios.defaults.headers, ...optional }
    });
  }
}

const getAccessToken = async () => {
  const token = await localStorage.getItem('access_token');
  return JSON.stringify({ access_token: token || '' });
};

const refreshAccessToken = async () => {
  const res = await DataService.get(`/Auth/getToken?clientId=${MINTBOX_CLIENT_ID}`).then((d) => d);
  if (res.type) {
    await localStorage.setItem('access_token', res.data.accessToken);
    return res.data.accessToken;
  }
  return '';
};

client.interceptors.request.use(
  async (config) => {
    const value = await getAccessToken();
    const keys = JSON.parse(value);
    config.headers = {
      Authorization: `Bearer ${keys.access_token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

client.interceptors.response.use(
  async (response) => {
    if (response.data.statusCode === 200) {
      countReCall = 0
      return {
        msg: 'Success',
        data: response.data.response,
        type: true
      };
    }
    // alert('Error Occured!!');
    return {
      msg: response.data.errorInfo,
      data: response.data.response,
      type: false
    };
  },

  async (error) => {
    const { response } = error;
    const originalRequest = error.config;
    if (response) {
      if (response.status === 500) {
        try {
          var token = originalRequest?.headers?.Authorization?.replace("Bearer ", "")
          if (token?.length > 0) {
            var tockenVal = jwt_decode(token)
            if (new Date().getTime() < tockenVal?.exp * 1000) {
              return JSON.stringify({ access_token: token || '' });
            } else {
              const access_token = await refreshAccessToken();
              axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
              return client(originalRequest);
            }
          } else {
            const access_token = await refreshAccessToken();
            axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
            return client(originalRequest);
          }

        } catch (error) {
          return response
        }

      }
      // // Toast.show({
      // //   text1: 'Something went wrong!!',
      // //   text2: 'Try again ...',
      // //   type: 'error'
      // // });
      return response;
    }

    return Promise.reject(error);
  }
);

export { DataService, API_ENDPOINT };
