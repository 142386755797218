import { changeObjKey } from "Components/DataCustomizer";
import { formatDate } from "Components/DateFormatter";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import NoBackTestData from "Components/NoBackTestData";
import { NoDataFound } from "Components/NoDataFound";
import { Card, Table } from "antd";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import React from "react";
const NoBTData = React.memo(({ noBacktestVal }) => {
    return (
        <Card>
            <NoBackTestData val={noBacktestVal} />
        </Card>
    )
})

export const TableConstructor = React.memo(({ tableName = "", tableData = {}, showNameColumn = false, isTranspose = false, noBacktestVal }) => {
    if (isTranspose) {
        var dataSource = Object.values(tableData)
        var dataSourceKeys = Object.keys(tableData)

        var smDataSource = Object.keys(dataSource[0] || {})
        smDataSource = smDataSource.map((key, index) => {
            var i = {
                "key": key,
                "name": key
            }
            dataSourceKeys?.map((finalkey, index) => {
                i = {
                    ...i,
                    [finalkey]: new Date(dataSource[index][key]) !== "Invalid Date" && !dataSource[index][key]?.includes("%") && !dataSource[index][key]?.includes(".") ? formatDate(dataSource[index][key]) : (dataSource[index][key]?.length === 1 && dataSource[index][key]?.includes("%")) ? "-" : dataSource[index][key]
                }
            })
            return i
        })
        dataSourceKeys = ["name"].concat(dataSourceKeys)
        var columns = dataSourceKeys?.map((key, index) => {
            return {
                title: key === "name" ? "" : key,
                dataIndex: key,
                key: key,
                width: 100,
                align: key === "name" ? "left" : "right",
            }
        })
        let check1 = Object.keys(tableData)?.length <= 0
        let check2 = Object.values(tableData)?.length <= 0
        let temparr = Object.values(tableData);
        let check3 = true;
        for (let i = 0; i < temparr.length; i++) {
            let ch = Object.keys(temparr[i])?.length >= 0;
            let ch2 = Object.values(temparr[i])?.length >= 0;
            if (ch && ch2) {
                check3 = false;
                break;
            }
        }
        if (check1 || check2 || check3) {
            return <NoBTData noBacktestVal={noBacktestVal} />
        }
        return <div className=''>
            <Table scroll={{ x: isTranspose ? 300 : 800 }} dataSource={smDataSource} columns={columns} className='textXS black' pagination={false} /></div>
    } else {
        var dataSource = Object.values(tableData)
        var dataSourceKeys = Object.keys(tableData)

        if (showNameColumn) {
            dataSource = dataSource.map((item, index) => {
                return {
                    ...item,
                    "col1_name_custom": dataSourceKeys[index]
                }
            })
        }

        var columns = Object.entries(dataSource[0]).map(([key, val], index) => {
            return {
                title: key,
                dataIndex: key,
                key: key,
                width: 100,
                align: () => {
                    if (new Date(val) !== "Invalid Date" || val.includes("%") || val.includes(".") || isNaN(Number(val))) {
                        return "right"
                    } else {
                        return "left"
                    }
                },
                fixed: key === "col1_name_custom" ? "left" : false,
                render: (text) => {
                    if (new Date(text) !== "Invalid Date" && !text.includes("%") && !text.includes(".")) {
                        return formatDate(text)
                    } else {
                        return text
                    }
                }
            }
        })


        if (showNameColumn) {
            columns.splice(columns.indexOf((i) => i.key === "col1_name_custom"), 1)
            columns = [{
                title: "",
                width: 150,
                dataIndex: "col1_name_custom",
                key: "col1_name_custom",
                fixed: "left",
            }].concat(columns)
        }
        let check1 = Object.keys(tableData)?.length <= 0
        let check2 = Object.values(tableData)?.length <= 0
        let temparr = Object.values(tableData);
        let check3 = true;
        for (let i = 0; i < temparr.length; i++) {
            let ch = Object.keys(temparr[i])?.length >= 0;
            let ch2 = Object.values(temparr[i])?.length >= 0;
            if (ch && ch2) {
                check3 = false;
                break;
            }
        }
        if (check1 || check2 || check3) {
            return <NoBTData noBacktestVal={noBacktestVal} />
        }
        return <div className='my-3'>
            <div className='textSM black w-700 mb-2'>{tableName}</div>
            <Table scroll={{ x: 800 }} dataSource={dataSource} columns={columns} className='textXS black' pagination={false} />
        </div>
    }
})

export const WorstDrawdowns = React.memo(({ data, noBacktestVal }) => {
    const dataSource = data
    const columns = [
        {
            title: 'Started on',
            dataIndex: 'Started',
            width: 150,
            fixed: 'left'
        },
        {
            title: 'Drawdown',
            dataIndex: 'Drawdown',
            align: "right",
            render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
        },
        {
            title: 'Lasted for (days)',
            dataIndex: 'Days',
            align: "right",
        },
        {
            title: 'Recovered on',
            dataIndex: 'Recovered',
            align: "right",
        }
    ]
    let tableData = data;
    let check1 = Object.keys(tableData)?.length <= 0
    let check2 = Object.values(tableData)?.length <= 0
    let temparr = Object.values(tableData);
    let check3 = true;
    for (let i = 0; i < temparr.length; i++) {
        let ch = Object.keys(temparr[i])?.length >= 0;
        let ch2 = Object.values(temparr[i])?.length >= 0;
        if (ch && ch2) {
            check3 = false;
            break;
        }
    }
    if (check1 || check2 || check3) {
        return <NoBTData />
    }
    return <Table dataSource={dataSource} columns={columns} className='textXS black' pagination={false} />
})

export const HeatMap = React.memo(({ data = [], isCard = true }) => {

    var heatmapdata = data
    var years = data.map((data) => { return data.year })
    var months = Object.keys(data?.[0]).slice(1)
    var vals = []
    var dataFinal = months.map((month, index) => {
        return years.map((year, yindex) => {
            vals.push(Number((heatmapdata[yindex][month] * 100)?.toFixed(1)))
            return {
                name: month.charAt(0).toUpperCase() + month.toLowerCase().slice(1) + " " + year,
                x: index,
                y: yindex,
                value: Number((heatmapdata[yindex][month] * 100).toFixed(1))
            }
        })
    })

    var chart = {
        chart: {
            type: 'heatmap',
            plotBorderWidth: 0,
            borderWidth: 0,

        },
        title: {
            text: '',
            style: {
                color: "var(--black)"
            }
        },
        scrollbar: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: months
        },
        yAxis: {
            visible: true,
            tickAmount: 5,
            gridLineDashStyle: 'longdash',
            categories: years,
            title: null,
            reversed: true
        },
        colorAxis: {
            min: Math.min(...vals),
            max: Math.max(...vals),
            minColor: '#c9f6bf',
            maxColor: '#fcaead',
        },
        legend: {
            enabled: false
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '{point.name} returns: {point.value}%',
        },
        series: [{
            name: 'Monthly returns',
            borderWidth: 0.2,
            borderColor: 'black',
            data: dataFinal.flat(1),
            dataLabels: {
                enabled: true,
                color: '#000000',
                style: {
                    fontWeight: 'normal',
                    fontSize: 8,
                    textOutline: false
                },
                pointFormat: '{point.value}%',
            }
        }],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 300
                },
                chartOptions: {
                    yAxis: {
                        labels: {
                            formatter: function () {
                                return this.value.charAt(0);
                            }
                        }
                    }
                }
            }]
        }
    }
    return <Card bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: (window.innerWidth > 760 && isCard) ? '1.25rem' : 0 }}>
        <HighchartsReact highcharts={Highcharts} options={chart} />
    </Card>
})

export const ReturnsHistogram = React.memo(({ histData = [], isCard = true }) => {
    var heatmapdata = histData.map((res) => {
        return Number((res * 100).toFixed(2))
    })

    const xiData = [];
    const animationDuration = 1;//4000;
    const range = Math.max(...heatmapdata) - Math.min(...heatmapdata) * 2,
        startPoint = Math.min(...heatmapdata);
    let i = 0,
        j = 0;
    for (i = 0; i < range; i++) {
        xiData[i] = startPoint + i;
    }
    const data = [];

    function GaussKDE(xi, x) {
        return (1 / Math.sqrt(2 * Math.PI)) * Math.exp(Math.pow(xi - x, 2) / -2);
    }

    const N = heatmapdata.length;

    for (i = 0; i < xiData.length; i++) {
        let temp = 0;
        for (j = 0; j < heatmapdata.length; j++) {
            temp = temp + GaussKDE(xiData[i], heatmapdata[j]);
        }
        data.push([xiData[i], (1 / N) * temp * 100]);
    }

    const chart = {
        chart: {
            type: "areaspline",
            animation: true
        },
        title: {
            text: '',
            style: {
                color: 'var(--black)'
            }
        },
        scrollbar: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },

        title: {
            text: ""
        },
        xAxis: {
            title: { text: "" }
        },
        yAxis: {
            tickAmount: 5,
            gridLineDashStyle: 'longdash',
            title: { text: 'Density' }
        },
        tooltip: {
            enabled: false
        },
        legend: {
            enabled: false
        },

        plotOptions: {
            areaspline: {
                threshold: null
            },
            series: {
                marker: {
                    enabled: false
                },
                dashStyle: "shortdot",
                color: "#ff8d1e",
                pointStart: xiData[0],
                animation: {
                    duration: animationDuration
                }
            }
        },
        series: [
            {
                name: "Return density",
                dashStyle: "solid",
                lineWidth: 2,
                color: 'var(--primary)',
                data: data
            }
        ]
    }

    return <Card bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: (window.innerWidth > 760 && isCard) ? '1.25rem' : 0 }}>
        <HighchartsReact highcharts={Highcharts} options={chart} />
    </Card>
})

export const RollingVol = React.memo(({ data = [], changeObjSecondKey = 'Date', isCard = true }) => {

    var heatmapdata = data

    try {
        var conf = {
            chart: {
                backgroundColor: "transparent"
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                title: {
                    text: ''
                },
                gridLineColor: "var(--grey1)",
                plotLines: [{
                    color: '#000', // Red
                    value: heatmapdata[0]["Average_6M_vol"], // Position, you'll have to translate this to the values on your x axis
                    dashStyle: 'dash',
                    width: 1,
                    label: {
                        text: "Average 6M vol", rotation: 0, x: 10,
                        style: {
                            color: "var(--dark2)",
                            // fontWeight: 'bold'
                        }
                    },
                    color: 'var(--dark2)',
                }]
            },
            xAxis: [{
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%e %b',
                    week: '%e %b',
                    month: '%b %y',
                    year: '%Y'
                },
                lineColor: "var(--grey1)",
                tickColor: "var(--grey1)",
            }
            ],
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    lineWidth: 1.5,
                    label: {
                        connectorAllowed: false
                    },
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                shared: true,
                valuedecimals: 1,
                valuePrefix: '',
                valueSuffix: '%'
            },
            credits: {
                enabled: false
            },
            boost: {
                useGPUTranslations: true,
            },
            series: [
                {
                    name: "Portfolio", turboThreshold: 9e9,
                    color: '#4284f3',
                    data: changeObjKey(heatmapdata, ["Portfolio", changeObjSecondKey], ["y", "x"], "rollingTearchart", 1)
                }, {
                    name: "Benchmark", turboThreshold: 9e9,
                    color: "var(--primary)",
                    data: changeObjKey(heatmapdata, ["Benchmark", changeObjSecondKey], ["y", "x"], "rollingTearchart", 1)
                }
            ].reverse(),
            scrollbar: {
                enabled: false
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 400
                    },
                }]
            },

        };
    } catch (error) {
        //console.log("SDF", error)
    }

    return <Card bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: (window.innerWidth > 760 && isCard) ? '1.25rem' : 0 }}>
        <HighchartsReact highcharts={Highcharts} options={conf} />
    </Card>
})

export const SharpeChart = React.memo(({ data = [], changeObjSecondKey = 'Date', isCard = true }) => {
    var heatmapdata = data

    try {
        var conf = {
            chart: {
                backgroundColor: "transparent"
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                title: {
                    text: ''
                },
                gridLineColor: "var(--grey1)",
                plotLines: [{
                    color: '#000', // Red
                    value: heatmapdata[0]["Average_Sharpe"], // Position, you'll have to translate this to the values on your x axis
                    dashStyle: 'dash',
                    width: 1,
                    label: {
                        text: "Average Sharpe", rotation: 0, x: 10,
                        style: {
                            color: "var(--dark2)",
                            // fontWeight: 'bold'
                        }
                    },
                    color: 'var(--dark2)',
                }]
            },
            xAxis: [{
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%e %b',
                    week: '%e %b',
                    month: '%b %y',
                    year: '%Y'
                },
                lineColor: "var(--grey1)",
                tickColor: "var(--grey1)",
            }
            ],
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    lineWidth: 1.5,
                    label: {
                        connectorAllowed: false
                    },
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                shared: true,
                valueDecimals: 0,
                valuePrefix: '₹',
            },
            credits: {
                enabled: false
            },
            boost: {
                useGPUTranslations: true,
            },
            series: [
                {
                    name: "Portfolio", turboThreshold: 9e9,
                    color: "#24c1e0",
                    tooltip: {
                        valueSuffix: ' ({point.Portfolio}%)',
                    },
                    data: changeObjKey(heatmapdata, ["Portfolio", changeObjSecondKey], ["y", "x"], "rollingTearchart", 1)
                }
            ],
            scrollbar: {
                enabled: false
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 400
                    },
                }]
            },

        };
    } catch (error) {
        //console.log("SDF", error)
    }

    return <Card bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: (window.innerWidth > 760 && isCard) ? '1.25rem' : 0 }}>
        <HighchartsReact highcharts={Highcharts} options={conf} />
    </Card>
})

export const UnderWater = React.memo(({ data = [], datakey = "Portfolio", changeObjSecondKey = 'Date', isCard = true }) => {
    //console.log("underwatefr")
    var heatmapdata = data

    try {
        var conf = {
            chart: {
                backgroundColor: "transparent"
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                labels: {
                    format: '{value}%'
                },
                title: {
                    text: ''
                },
                gridLineColor: "var(--grey1)",
            },
            xAxis: [{
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%e %b',
                    week: '%e %b',
                    month: '%b %y',
                    year: '%Y'
                },
                lineColor: "var(--grey1)",
                tickColor: "var(--grey1)",
            }
            ],
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    lineWidth: 1.5,
                    label: {
                        connectorAllowed: false
                    },
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                shared: true,
                valuedecimals: 1,
                valuePrefix: '',
                valueSuffix: '%'
            },
            credits: {
                enabled: false
            },
            boost: {
                useGPUTranslations: true,
            },
            series: [
                {
                    name: "Portfolio", turboThreshold: 9e9,
                    color: "var(--red)",
                    data: changeObjKey(heatmapdata, [datakey, changeObjSecondKey], ["y", "x"], "rollingTearchart", 1).map((i) => {
                        return {
                            ...i,
                            y: i.y * 100
                        }
                    })
                }
            ],
            scrollbar: {
                enabled: false
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 400
                    },
                }]
            },

        };
    } catch (error) {
        //console.log("SDF", error)
    }

    return <Card bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: (window.innerWidth > 760 && isCard) ? '1.25rem' : 0 }} >
        <HighchartsReact highcharts={Highcharts} options={conf} />
    </Card>
})

export  const PieChart = React.memo(({ chartData, height = 350, tooltip={},name='' }) => {
    const config = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
            height: height,
        },
        title: { text: "", },
        tooltip: {
            ...tooltip
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    format: `<span class="textXS w-500 dark4"><b>{point.name}</b>: {point.percentage:.1f} %</span>`,
                },
            },
        },
        series: [{
            name: name,
            colorByPoint: true,
            data: chartData,
        },],
        credits: { enabled: false, },
        boost: { useGPUTranslations: true, },
        exporting: { enabled: false, },
    };

    if (chartData?.length <= 0 || !chartData) {
        return (
            <div>
                <NoDataFound message='Not enough data to show chart' />
            </div>
        )
    }

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={config} />
        </div>
    )
})