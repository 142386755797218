import AccessComponent from "Components/AccessComponent";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import { PieChart } from "Components/BasketDetailComponents";
import ColumnChart from "Components/ColumnChart";
import { CurrencyFormat } from "Components/CurrencyFormat";
import CustomLabelValueCards from "Components/CustomLabelValue";
import CustomSegmentedTab from "Components/CustomSegmentedTab";
import { formatDate, formatDateMonthYear } from "Components/DateFormatter";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import { NoDataFound } from "Components/NoDataFound";
import PerformanceChart3 from "Components/PerformanceChart3";
import RedGreenText from "Components/RedGreenText";
import MyTable from "Components/TableWithScroll";
import UpgradeToPro from "Components/UpgradeToPro";
import { toggleMetricDescModal } from "Libs/redux/KnowledgeBase/reduxSlice";
import { changeModuleKey, toggleHelpAndSupp, toggleSubModalParams, toggleSubscribeModal, toggleloginModal } from "Libs/redux/UserAuth/reduxSlice";
import { displayValue, generateUniqueKey } from "Libs/utils";
import { Card, Modal, Segmented, Select, Table, Tabs, Tooltip } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import React from "react";
import { AiOutlineDown, AiOutlineInfoCircle, AiOutlineMinusSquare, AiOutlinePlusSquare, AiOutlineRight } from "react-icons/ai";
import { BsFillBarChartFill } from "react-icons/bs";
import { MdOutlineLock } from "react-icons/md";

const SegmentChart = React.memo(({ chartData, name, sliceMax = false, chartText }) => {

    let total = chartData?.reduce((a, c) => {
        return a + c[name?.toLowerCase()];
    }, 0);

    let max = Math.max(...(chartData?.map((el) => el[name?.toLowerCase()]) || []));

    let d = []

    chartData?.map((ele) => {
        let t = {};
        let v = ele[name?.toLowerCase()]
        t.name = ele.label;
        t.custom = { value: v };
        t.y = (v / total) * 100;
        if (v === max && sliceMax) {
            t.sliced = true
        }
        d = [...d, t];
    });

    if (chartData?.length <= 0 || !chartData) {
        return null;
    }
    return (
        <div style={{
            border: "1px solid var(--grey3)", padding: "1.25rem 1.25rem 0.25rem", borderRadius: "0.5rem", width: window.innerWidth > 760 ? "calc(50% - 0.5rem)" : "100%",
            background: "var(--white)",
        }}>
            <div className="textLG w-500 dark4">
                {name === "Opinc" ? "Operating income" : name}
            </div>
            <div className='textSM dark4 mt-1'>
                {chartText}
            </div>
            <PieChart height={window.innerWidth < 760 ? 250 : 400} chartData={d} name={name} tooltip={{
                formatter: function (...hj) {
                    return ("<b>" + this.key + ": " + CurrencyFormat(this.point.custom.value, "long", 2, "Cr") + "</b>");
                },
            }} />
        </div>
    );
});

export const BusinessModel = React.memo(({ segmentsData, loadingSegments, fundData }) => {
    let businessData = Object.keys(segmentsData?.business || {})?.pop();
    businessData = segmentsData?.business?.[businessData] || {}
    return (
        <>
            {loadingSegments ? <div style={{}} className='d-flex align-items-center justify-content-center flex-grow-1'>
                <ActivityLoaderMintbox />
            </div> : Object.keys(segmentsData?.business)?.length <= 0 ?
                <div style={{ padding: "1.25rem 0" }} className='d-flex align-items-center justify-content-center flex-grow-1'>
                    <NoDataFound message={`We don't have enough data on the business model of ${fundData?.proper_name}`} msgMaxWidth='60ch' />
                </div> :
                <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", }}>
                    <SegmentChart chartData={businessData?.sales?.data} chartText={businessData?.sales?.text} name={'Sales'} sliceMax />
                    <SegmentChart chartData={businessData?.assets?.data} chartText={businessData?.assets?.text} name={'Assets'} sliceMax />
                    <SegmentChart chartData={businessData?.capex?.data} chartText={businessData?.capex?.text} name={'Capex'} sliceMax />
                    <SegmentChart chartData={businessData?.opinc?.data} chartText={businessData?.opinc?.text} name={'Opinc'} sliceMax />
                </div>
            }
        </>
    );
});

export const RatiosAndStatistics = React.memo(({ screenerMetricDescData = [], userAccesslevel, dispatch, historicalCharts, loadinghistoricalCharts, isMobile, navigate }) => {

    const RatioTable = ({ data, chartType, canToggleLogYAxis, colsAmountToShow = 8 }) => {
        let othCols = data?.find((ele) => ele?.cols?.length > 0)?.cols || [];
        let ttmDate = data?.find((ele) => String(new Date(ele?.ttm_end)) !== 'Invalid Date')?.ttm_end;
        console.log(ttmDate, 'afsgs', data);
        const [metricChart, setMetricChart] = React.useState({});
        const [scaleType, setScaleType] = React.useState('linear');
        othCols = othCols?.map((ele) => {
            let d = new Date(ele);
            let title = String(d) === "Invalid Date" ? <div><div className='textSM w-500 dark4'>{ele}</div>
                {String(new Date(ttmDate)) !== "Invalid Date" && <div className='textXS w-500 dark3'>{moment(new Date(ttmDate)).format("MMM DD")}</div>}</div> : <div><div className='textSM w-500 dark4'>{moment(d).format("YYYY")}</div>
                <div className='textXS w-500 dark3'>{moment(d).format("MMM DD")}</div></div>;
            let o = {
                title: title,
                key: ele,
                dataIndex: ele,
                render: (text, obj) => {
                    const metric = screenerMetricDescData?.filter((ele) => ele.desc_id === Number(obj?.desc))?.[0] || {};
                    let auth_flag = Number(obj?.auth_flag);
                    auth_flag = isNaN(auth_flag) ? 0 : auth_flag;
                    return obj.isSectionHeader ? null : <AccessComponent component={<span>{!text && text !== 0 ? '-' : displayValue({ text, metricObj: metric })}</span>} reqAccessLevel={auth_flag}
                        upgradeComponent={<Tooltip title={`${(auth_flag === 1 || userAccesslevel === 0) ? "Sign in" : auth_flag === 2 ? "Upgrade to Lite" : auth_flag === 3 ? "Upgrade to Pro" : auth_flag === 4 ? "Upgrade to Black" : ""} to get full access to ratio and statistics`} arrow={false}>
                            <MdOutlineLock color='var(--dark4)' size='1rem' cursor={'pointer'} onClick={() => {
                                if (userAccesslevel === 0) {
                                    dispatch(toggleloginModal(true))
                                }
                                else navigate('/pricing');
                            }} />
                        </Tooltip>
                        } />
                },
                align: "right",
            };
            return o;
        })?.reverse()?.slice(0, colsAmountToShow)?.reverse() || [];

        const columns = [
            {
                title: "",
                key: "name",
                dataIndex: "name",
                width: isMobile ? "35vw" : 180,
                render: (text, record) =>
                    record.isSectionHeader ? (
                        <span className="textSM w-700 black">{text}</span>
                    ) : (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div>{text}</div>
                            <AiOutlineInfoCircle style={{ marginLeft: "0.25rem", cursor: "pointer" }} onClick={() => {
                                let met = screenerMetricDescData?.find((ele) => ele?.desc_id === Number(record?.desc)) || {};
                                dispatch(toggleMetricDescModal({
                                    value: true,
                                    params: {
                                        title: met?.name,
                                        description: met?.description
                                    }
                                }))
                            }}
                                className="ratioRowinfo" />
                        </div>
                    ),
                fixed: "left",
            },
            ...othCols,
            {
                title: "",
                key: "key",
                dataIndex: "key",
                render: (text, obj) => {
                    return obj.isSectionHeader ? null : (
                        <div>
                            <BsFillBarChartFill onClick={() => {
                                if (userAccesslevel === 0 && Number(obj?.auth_flag) === 1) {
                                    dispatch(toggleloginModal(true))
                                }
                                else if (userAccesslevel < Number(obj?.auth_flag)) {
                                    dispatch(toggleSubscribeModal(true));
                                    dispatch(toggleSubModalParams({
                                        customAccessObj: {
                                            AccessLevel: Number(obj?.auth_flag),
                                            ModuleName: "",
                                            ModuleDesc: "",
                                            IsEnabled: 1,
                                        }
                                    }))
                                }
                                else {
                                    var x = Object.keys(JSON.parse(obj?.chart_data)?.[0] || {})?.[0];
                                    var y = Object.keys(JSON.parse(obj?.chart_data)?.[0] || {})?.[1];
                                    const metric = screenerMetricDescData?.filter((ele) => ele.desc_id === Number(obj?.desc))?.[0] || {};
                                    const cards = Object.keys(obj?.chart_text_items)?.map((k, i) => {
                                        return {
                                            key: i,
                                            heading: k,
                                            value: displayValue({
                                                text: obj?.chart_text_items?.[k],
                                                metricObj: metric,
                                            }),
                                        };
                                    }
                                    );
                                    setMetricChart({ ...obj, x, y, cards });
                                }
                            }} style={{ cursor: "pointer" }} />
                        </div>
                    );
                },
                align: "right",
            },
        ];

        return (
            <div>
                <MyTable columns={columns} dataSource={data} scroll={{ x: "max-content" }} noPagination={true} pagination={false} className="ratioTable" scrollToLeft={true}
                    rowClassName={(record, index) => {
                        if (!record.isSectionHeader) return "showInfoOnHover";
                        return "";
                    }} />
                <Modal open={metricChart?.name} onCancel={() => { setMetricChart({}); }} onOk={() => { setMetricChart({}); }} width={"min(50rem , 100%)"}
                    footer={<button onClick={() => { setMetricChart({}); }} className="btnBlack ">Close</button>} title={metricChart?.name}>
                    <div style={{ marginTop: "1.25rem", marginBottom: "-1rem" }}>
                        <div style={{ marginBottom: "1rem" }}>
                            <CustomLabelValueCards x={metricChart?.cards} padding="0.75rem 1rem" minWidth="10rem" />
                        </div>
                        {canToggleLogYAxis && metricChart?.section === 'Multiples' && <Segmented value={scaleType} options={[{ label: "Linear", value: "linear", key: "linear" },
                        { label: "Logarithmic", value: "logarithmic", key: "logarithmic" }]} onChange={(e) => { setScaleType(e) }} style={{ marginBottom: "1rem" }} size='small' />}
                        {chartType === "column" ? (
                            <ColumnChart chartName={metricChart?.chart_name} chartData={metricChart?.chart_data} xAxisKey={metricChart?.x} yAxisKey={metricChart?.y} showYAxis={true}
                                showDataLabels={true} height={300} />
                        ) : (
                            <PerformanceChart3 fundName={metricChart?.chart_name} chartData={metricChart?.chart_data} xAxisKey={metricChart?.x} chartType={chartType} showYAxis={true}
                                showDataLabels={true} height={300} yAxisType={(canToggleLogYAxis && metricChart?.section === 'Multiples') ? scaleType : "linear"} />
                        )}
                        {metricChart?.section === 'Multiples' && <div style={{ margin: "0 1rem 1rem" }} className='textXS black'>
                            Note: 0 value to be interpreted as follows – the denominator (e.g., EPS) was negative or not available and hence multiple could not be calculated. On log scale, 0 values will not be plotted.
                        </div>}
                    </div>
                </Modal>
            </div>
        );
    };
    const getTabledata = (chartData) => {
        let d = JSON.parse(chartData || "[]") || [];
        let key1 = Object.keys(d?.[0] || {})?.[0];
        let key2 = Object.keys(d?.[0] || {})?.[1];
        let obj = {};
        for (let i = 0; i < d?.length; i++) {
            let a = d[i]?.[key1];
            let b = d[i]?.[key2];
            obj[a] = b;
        }
        return obj;
    };

    const structureChartData = (d = {}) => {
        let a = [];
        Object.keys(d || {})?.map((ele) => {
            console.log("AKAK", ele)
            if (ele !== "vertical_col") {
                let ob = d?.[ele];
                console.log("AKAK", ob)
                let a1 = {
                    key: ob?.name,
                    name: ob?.name,
                    isSectionHeader: true,
                    show: ob?.charts?.length > 0,
                    showDesc: false,
                };
                let a2 = ob?.charts?.map((chart) => {
                    let k = getTabledata(chart?.chart_data || "[]");
                    let g = {
                        ...chart,
                        section: ob?.name,
                        key: chart?.chart_name,
                        name: chart?.chart_name,
                        showDesc: true,
                        ...k,
                        cols: Object.keys(k || {})
                    };
                    return g;
                }) || [];
                a = [...a, a1, ...a2];
            }
        })
            ?.filter((ele) => ele);

        console.log("AKAK", a)
        return a;
    };

    const tabItems = [
        {
            label: "Value",
            key: "value",
            value: "value",
            data: structureChartData(historicalCharts?.value),
        },
        {
            label: "Quality",
            key: "quality",
            value: "quality",
            data: structureChartData(historicalCharts?.quality),
        },
        {
            label: "Financial Health",
            key: "financial",
            value: "financial",
            data: structureChartData(historicalCharts?.["fin_health"]),
        },
    ];

    console.log(tabItems);

    const [selectedTabItem, setSelectedTabItem] = React.useState("value");

    const lineCharts = ["value"];
    const toggleYaxisType = ['value']
    return (
        <React.Fragment>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }} className="margin20Bottom">
                <CustomSegmentedTab options={tabItems} value={selectedTabItem} onChange={(e) => setSelectedTabItem(e)} isBlock={false} keepSameInMobile />
            </div>
            {loadinghistoricalCharts ? (<div className='flex-grow-1 d-flex align-items-center'>
                <ActivityLoaderMintbox showQuote />
            </div>) : (
                <RatioTable data={tabItems?.find((el) => el.value === selectedTabItem)?.data} chartType={lineCharts?.includes(selectedTabItem) ? "areaspline" : "column"}
                    canToggleLogYAxis={toggleYaxisType.includes(selectedTabItem)} colsAmountToShow={selectedTabItem === 'value' ? 13 : 8} />
            )}
        </React.Fragment>
    );
});

export const Statements = React.memo(({ loadingFinancialStatements, fundData, isMobile, finStatements, dispatch, screenerMetricDescData, displayValueV2 }) => {
    const options1 = [
        { key: "pnl", value: "pnl", name: "Profit and Loss", },
        { key: "bs", value: "bs", name: "Balance sheet", },
        { key: "cf", value: "cf", name: "Cash flow" }
    ];

    const options2 = [
        { key: "an", value: "an", name: "Annual", },
        { key: "qt", value: "qt", name: "Quaterly", },
    ];

    const options3 = [
        { key: "Value", value: "value", name: "Value", },
        { key: "growth", value: "growth", name: "Growth", },
    ];
    const [statementType, setStatementType] = React.useState(options1[0]);
    const [tenure, setTenure] = React.useState(options2[0]);
    const [tableValueToShow, setTableValueToShow] = React.useState(options3[0]);

    React.useEffect(() => {
        const x = document.querySelector(".statementsTable")?.getElementsByClassName("ant-table-content")?.[0];
        if (x) {
            x.scrollLeft += 200000;
        }
    }, [statementType, tenure, loadingFinancialStatements, tableValueToShow]);

    const StatementTable = ({ data, type, tableValueToShow, scrollLeftDependency }) => {
        const [metricChart, setMetricChart] = React.useState({});
        console.log(data, 'statementsTabledata');
        let g = Object.keys(data?.[0]?.data || {})?.map((ele) => {
            const d = new Date(ele);
            const title = ele === "TTM" ? (<div><div className='textSM w-500 dark4'>{ele}</div>
                <div className='textXS dark3 w-500'>{moment(new Date(finStatements?.['ttm_date'])).format("MMM DD")}</div>
            </div>) : (String(d) === 'Invalid Date' ? ele : <div><div className='textSM w-500 dark4'>{moment(d).format("YYYY")}</div>
                <div className='textXS dark3 w-500'>{moment(d).format("MMM DD")}</div>
            </div>);
            let obj = {
                title: title,
                key: ele,
                dataIndex: ele,
                align: "right",
                render: (text, obj) => {
                    let met = screenerMetricDescData?.find((ele) => ele.desc_id === obj?.meta?.desc_id);
                    return (
                        <div>
                            {tableValueToShow.value === "value" ? (
                                <div className="" style={{ fontSize: "13px" }}>
                                    {text ? displayValueV2({ text, descObj: met, roundTo: finStatements?.round_to || 0, }) : "-"}
                                </div>
                            ) : (
                                <span className="" style={{ fontSize: "13px", lineHeight: "13px" }}>
                                    {obj?.growth?.[ele] ? <RedGreenText text={<span>{obj?.growth?.[ele] + " %"}</span>} value={obj?.growth?.[ele]} arrow /> : '-'}
                                </span>
                            )}
                        </div>
                    );
                },
            };
            return obj;
        });

        console.log(g);
        const columns = [
            {
                title: "",
                key: "name",
                dataIndex: "name",
                width: isMobile ? "40vw" : 350,
                fixed: "left",
                render: (text, obj) => {
                    return (
                        <span style={{}}>
                            <span
                                style={{
                                    fontSize: obj?.type === 2 ? "14px" : obj?.type === 3 ? "13px" : "12px",
                                    color:
                                        obj?.type === 2
                                            ? "var(--black)"
                                            : obj?.type === 3
                                                ? "var(--dark4)"
                                                : "var(--dark3)",
                                }}
                            >
                                {text}
                            </span>
                            <AiOutlineInfoCircle
                                style={{ marginLeft: "0.25rem", cursor: "pointer" }}
                                onClick={() => {
                                    let met = screenerMetricDescData?.find((ele) => ele?.desc_id === obj?.meta?.desc_id);
                                    dispatch(toggleMetricDescModal({
                                        value: true,
                                        params: {
                                            title: met?.name,
                                            description: met?.description
                                        }
                                    }))
                                }}
                                className="statementRowInfo"
                            />
                        </span>
                    );
                },
            },
            ...g,
            {
                title: "",
                key: "key",
                dataIndex: "key",
                render: (text, obj) => {
                    return (
                        <BsFillBarChartFill
                            onClick={() => {
                                let d = obj?.data;
                                let data = Object.keys(d || {})?.map((ele) => {
                                    let a = {
                                        date: ele,
                                        y: d[ele],
                                    };
                                    return a;
                                });
                                setMetricChart({ ...obj, data });
                            }}
                            style={{ cursor: "pointer" }}
                        />
                    );
                },
                align: "right",
            },
        ];

        const getRowClassName = (descObj) => {
            return descObj.highlight === 2 ? "px-2 rowHighlightLevel2 showInfoOnHover" : descObj.highlight === 1 ? "px-2 rowHighlightLevel1 showInfoOnHover" : "px-2 showInfoOnHover";
        };

        return (
            <React.Fragment>
                <div className="textXS ms-1 mb-2">
                    (Showing consolidated numbers. All values are are in Rs. Crores unless specified otherwise)
                </div>
                {Object.keys(data?.[0]?.data || {})?.length <= 0 ? <div className='flex-grow-1 d-flex align-items-center' style={{ padding: "1.25rem 0" }}>
                    <NoDataFound message={`We don't have enough data to show financial statements for ${fundData?.proper_name}`} msgMaxWidth='60ch' />
                </div> :
                    <>
                        <Table columns={columns} dataSource={data} scroll={{ x: "max-content" }} className="statementsTable financialStatementsTable"
                            rowClassName={(record, index) => getRowClassName(record?.meta)} expandable={{
                                expandIcon: ({ expanded, onExpand, record }) => {
                                    let m = record?.meta?.acc_type;
                                    let c = record?.children?.length > 0;

                                    let style = {
                                        marginTop: "-0.06rem",
                                        marginRight: "0.6rem",
                                        fontSize: "0.75rem",
                                        cursor: "pointer",
                                    };
                                    return expanded ? (m === 1 && c ? (<AiOutlineDown onClick={(e) => onExpand(record, e)} style={style} />) : m === 2 && c ? (
                                        <AiOutlineMinusSquare onClick={(e) => onExpand(record, e)} style={style} />) : (<div></div>)) : m === 1 && c ? (
                                            <AiOutlineRight onClick={(e) => onExpand(record, e)} style={style} />) : m === 2 && c ? (
                                                <AiOutlinePlusSquare onClick={(e) => onExpand(record, e)} style={style} />) : (<div></div>);
                                },
                                expandRowByClick: isMobile ? true : false
                            }} pagination={false} />
                        <Modal open={metricChart?.name} onCancel={() => { setMetricChart({}); }} onOk={() => { setMetricChart({}) }} width={"min(50rem , 100%)"}
                            footer={<button onClick={() => { setMetricChart({}); }} className="btnBlack " >Close</button>} title={metricChart?.name}>
                            <div style={{ marginTop: "1.25rem", marginBottom: "-1rem" }}>
                                <ColumnChart chartName={metricChart?.name} chartData={JSON.stringify(metricChart?.data)} xAxisKey={"date"} yAxisKey={"y"} showYAxis={true} showDataLabels={false}
                                    height={350} isQuaterly={type === "qt"} />
                            </div>
                        </Modal>
                    </>}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {loadingFinancialStatements ? (<div className='flex-grow-1 d-flex align-items-center'>
                <ActivityLoaderMintbox />
            </div>) : (<div>
                <div style={{
                    display: "flex", justifyContent: "space-between", alignItems: window.innerWidth > 760 ? "center" : "stretch", gap: "1rem", marginBottom: "1.25rem",
                    flexDirection: window.innerWidth > 760 ? "row" : "column",
                }}>
                    <div style={{ flex: 1 }}>
                        <Segmented
                            size="medium"
                            value={statementType.value}
                            options={options1.map((m) => {
                                return {
                                    ...m,
                                    label: (
                                        <h3 className="margin0 w-400 font-inherit lineHeightUnset">
                                            {m.name}
                                        </h3>
                                    ),
                                };
                            })}
                            onChange={(e) =>
                                setStatementType(options1.find((o) => o.value === e))
                            }
                        />
                    </div>
                    <div className="textSM dark3 w-500" style={{ display: "flex", gap: "1rem", alignItems: "center" }} >
                        <div className="textSM dark3 w-500" style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                            <div>Statement period:</div>
                            <div style={{ flex: 1 }}>
                                {statementType.key === "pnl" ? (
                                    <Segmented value={tenure.name} options={options2.map((m) => m.name)} onChange={(e) => setTenure(options2.find((o) => o.name === e))} />) : (
                                    " Annual"
                                )}
                            </div>
                        </div>
                        <Segmented value={tableValueToShow.name} options={options3.map((m) => m.name)} onChange={(e) => setTableValueToShow(options3.find((o) => o.name === e))} />
                    </div>
                </div>
                <StatementTable data={finStatements?.[`${statementType.key}_${statementType.key === "pnl" ? tenure.key : "an"}`]}
                    type={statementType.key === "pnl" ? tenure.key : "an"} tableValueToShow={tableValueToShow} scrollLeftDependency={[statementType, tenure]} />
            </div>
            )}
        </React.Fragment>
    );
});

export const Dividends = React.memo(({ dividends, screenerMetricDescData, finStatements, loadingFinancialStatements, isMobile, displayValueV2, dispatch, fundData }) => {
    console.log('dividends', dividends)

    const DividendChart = React.memo(() => {
        const config = {
            chart: {
                height: 200,
            },
            title: { text: "" },
            credits: { enabled: false },
            exporting: { enabled: false },
            tooltip: {
                shared: true,
            },
            xAxis: [
                {
                    categories: Object.keys(dividends?.[0]?.data || {})?.map((el) => {
                        let title = new Date(el);
                        return String(title) === 'Invalid Date' ? el : new Date(el).toLocaleString("en-In", {
                            month: "short",
                            year: "2-digit",
                        });
                    }) || [],
                    crosshair: true,
                },
            ],
            tooltip: {
                shared: true,
            },
            yAxis: [
                {
                    title: {
                        text: "",
                        style: {
                            color: Highcharts.getOptions().colors[0],
                        },
                    },
                    labels: {
                        style: {
                            color: Highcharts.getOptions().colors[0],
                        },
                    },
                },
                {
                    labels: {
                        style: {
                            color: Highcharts.getOptions().colors[1],
                        },
                    },
                    title: {
                        text: "",
                        style: {
                            color: Highcharts.getOptions().colors[1],
                        },
                    },
                    opposite: true,
                },
            ],
            legend: {
                enabled: true,
            },
            series: [
                {
                    name: "Dividends per share",
                    type: "column",
                    data: Object.values(dividends?.[0]?.data) || [],
                    yAxis: 1,
                    tooltip: {
                        valueSuffix: "",
                    },
                },
                {
                    name: "Dividends payout ratio",
                    type: "spline",
                    data: Object.values(dividends?.[1]?.data) || [],
                    tooltip: {
                        valueSuffix: "%",
                    },
                },
                {
                    name: "Dividends yield",
                    type: "spline",
                    data: Object.values(dividends?.[2]?.data) || [],
                    tooltip: {
                        valueSuffix: "%",
                    },
                },
            ],
        };
        return (
            <Card style={{ marginBottom: "1.5rem" }} bodyStyle={{ padding: isMobile ? "1.25rem 0" : "1.25rem" }}>
                <HighchartsReact highcharts={Highcharts} options={config} />
            </Card>
        );
    });

    const roundTo2Metrics = [94, 205]

    let g = React.useMemo(() => {
        return Object.keys(dividends?.[0]?.data || {})?.map((ele) => {
            const d = new Date(ele);
            let title = ele === 'TTM' ? <div><div className='textSM w-500 dark4'>{ele}</div>
                <div className='textXS w-500 dark3'>{moment(new Date(finStatements?.['ttm_date'])).format("MMM DD")}</div>
            </div> : <div><div className='textSM w-500 dark4'>{moment(d).format('YYYY')}</div>
                <div className='textXS w-500 dark3'>{moment(d).format("MMM DD")}</div>
            </div>
            // const title = String(d)==='Invalid Date'?ele:moment(d).format("YYYY");
            let obj = {
                title: title,
                key: ele,
                dataIndex: ele,
                align: "right",
                render: (text, obj) => {
                    let met = screenerMetricDescData?.find(
                        (ele) => ele.desc_id === obj?.meta?.desc_id
                    );
                    let roundTo = roundTo2Metrics?.includes(obj?.meta?.desc_id) ? 2 : (finStatements?.round_to || 0)
                    return (
                        <div>
                            <div className="" style={{ fontSize: "13px" }}>
                                {text ? displayValueV2({ text, descObj: met, roundTo }) : "-"}
                            </div>
                        </div>
                    );
                },
            };
            return obj;
        })
    }, [dividends]);

    const columns = [
        {
            title: "",
            key: "name",
            dataIndex: "name",
            width: isMobile ? "35vw" : 350,
            fixed: "left",
            render: (text, obj) => {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="w-500 black">{text}</div>
                        <AiOutlineInfoCircle style={{ marginLeft: "0.25rem", cursor: "pointer" }} onClick={() => {
                            let met = screenerMetricDescData?.find((ele) => ele?.desc_id === obj?.meta?.desc_id);
                            dispatch(toggleMetricDescModal({
                                value: true,
                                params: {
                                    title: met?.name,
                                    description: met?.description
                                }
                            }))
                        }} className="statementRowInfo" />
                    </div>
                );
            },
        },
        ...g,
    ];
    return (
        <React.Fragment>
            {loadingFinancialStatements ? (
                <div className='flex-grow-1 d-flex align-items-center'>
                    <ActivityLoaderMintbox />
                </div>
            ) : dividends?.reduce((a, c) => a + (Object.keys(c?.data || {})?.length || 0), 0) <= 0 ? <div className='flex-grow-1 d-flex align-items-center' style={{ padding: "1.25rem 0" }}>
                <NoDataFound message={`We don't have enough data to show dividends for ${fundData?.proper_name}`} msgMaxWidth='60ch' />
            </div> : (
                <div>
                    <DividendChart />
                    <MyTable dataSource={dividends} columns={columns} scroll={{ x: "max-content" }} className="statementsTable" pagination={false}
                        rowClassName={() => "showInfoOnHover"} scrollToLeft={true} noPagination={true} />
                </div>
            )}
        </React.Fragment>
    );
});

export const CorporateActions = React.memo(({ fundData, finStatements, loadingFinancialStatements, accessLevelData, dispatch }) => {

    const DisplayCorporateActions = ({ x, message = "No data found", action, }) => {
        if ((typeof x !== "string" && Object.keys(x)?.length <= 0) || JSON.parse(x)?.length === 0) {
            return (
                <Card style={{ height: "20rem", width: "100%", textAlign: "center" }} bodyStyle={{ width: "100%", height: "100%" }} >
                    <NoDataFound message={<span> {fundData?.proper_name} has not{" "}{action === "split" ? "split its share " : action === "rights" ? "had any rights issue " :
                        action === "bonus" ? "announced any bonus " : action === "dividend" ? "declared any dividend " : ""} since 2006. If you think that this is wrong, please report this using{" "}
                        <span onClick={() => { dispatch(toggleHelpAndSupp(true)); }} style={{ color: "var(--primary)", cursor: "pointer" }} >
                            {" "} this link. </span>{" "} We will get this fixed asap! </span>} msgMaxWidth="60ch" />
                </Card>
            )
        }
        let data = JSON.parse(x)?.reverse();
        let k = Object.keys(data?.[0] || {})?.[0]
        const dataTableColumns = Object.keys(data?.[0] || {}).map((k, i) => {
            const t = k.replaceAll("_", " ");
            return {
                title: t[0].toUpperCase() + t.slice(1),
                dataIndex: k,
                key: k,
                width: 100,
                render: (text) => !text ? "-" : k.toLowerCase().includes("date") ? formatDate(text) : k.includes("Dividend") && Number(text) > 0 ? `₹ ${text} / share` : text,
            };
        });
        if (data.length) {
            return (
                <Table bordered={true} size={"small"} pagination={false} scroll={{ x: "max-content" }} dataSource={data} columns={dataTableColumns}
                    rowKey={(record) => generateUniqueKey(record?.[k])} />
            );
        }
    };

    console.log(finStatements?.corp_action);
    let tabs = Object.keys(finStatements?.corp_action || {}).map((a, i) => {
        return {
            label: a === 'dividend' ? "Dividend Payments" : a?.[0].toUpperCase() + a.slice(1),
            key: a,
            children: <DisplayCorporateActions x={finStatements?.corp_action?.[a]} action={a} />,
            show: a === "spinoff" ? JSON.parse(finStatements?.corp_action?.[a]).length : true,
            value: i,
        }
    })?.filter((el) => el);
    let index = tabs?.findIndex((el) => el.key === 'dividend');
    if (tabs?.length) {
        [tabs[0], tabs[index]] = [tabs[index], tabs[0]]
    }
    const [selectedTab, setSelectedTab] = React.useState(index || 0);
    return (
        <>
            <div className="d-flex align-items-center justify-content-between margin20Bottom">
                {!loadingFinancialStatements && (
                    <CustomSegmentedTab options={tabs} value={selectedTab} onChange={(e) => setSelectedTab(e)} isBlock={false} keepSameInMobile />)}
            </div>
            {loadingFinancialStatements ? (
                <div className="d-flex align-items-center justify-content-center margin20Bottom flex-grow-1">
                    <ActivityLoaderMintbox />
                </div>
            ) : (<>{tabs?.find((ele) => ele?.value === selectedTab)?.children}</>)}
        </>
    );
});

export const Segments = React.memo(({ segmentsData, fundData, loadingSegments, finStatements, isMobile }) => {

    const ChartView = () => {
        const SegmentChildren = ({ data = {} }) => {
            let options = [];
            Object.keys(data || {})?.map((ele) => {
                let obj = {
                    label: formatDateMonthYear(new Date(ele)),
                    value: ele,
                    key: ele,
                    chartData: data?.[ele],
                };
                options = [...options, obj];
            });
            options = options?.reverse();
            const [selectedTerm, setSelectedTerm] = React.useState(options?.[0]?.value);
            const charts = options?.find((ele) => ele?.value == selectedTerm)?.chartData;
            console.log('Segmentcharts data', data);
            return (
                <React.Fragment>
                    <div>
                        <div style={{ display: "flex", justifyContent: isMobile ? "" : "flex-end", position: isMobile ? "static" : "absolute", top: "0", right: "0", width: "fit-content", alignItems: "center", gap: "1rem", marginTop: isMobile ? "1rem" : "", }} className="" >
                            <div style={{ display: "flex", justifyContent: isMobile ? "" : "flex-end", width: "fit-content", alignItems: "center", gap: "1rem", }}>
                                <div className="textSM w-500 dark3" style={{ whiteSpace: "nowrap" }} >
                                    Segments analysis for FY end:{" "}
                                </div>
                                <Select options={options} value={selectedTerm} onChange={(e) => setSelectedTerm(e)} style={{ width: "min(100%, 10rem)" }} />
                            </div>
                        </div>
                        <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", marginTop: "1rem", }}>
                            {Object.keys(charts || {})?.map((ele, j) => {
                                return (
                                    <SegmentChart chartData={charts?.[ele]?.data} name={ele?.[0]?.toUpperCase() + ele?.slice(1)} chartText={charts?.[ele]?.text}
                                        key={generateUniqueKey(j)} />
                                );
                            })}
                        </div>
                    </div>
                </React.Fragment>
            );
        };

        let tabs = [
            {
                key: "Business",
                label: "Business",
                value: "Business",
                children: <SegmentChildren data={segmentsData?.business} />,
            },
            {
                key: "Regional",
                label: "Regional",
                value: "Regional",
                children: <SegmentChildren data={segmentsData?.regional} />,
            },
        ];

        const [selectedTab, setSelectedTab] = React.useState(tabs?.[0]?.value);

        return (
            <>
                {loadingSegments ? <div className='flex-grow-1 d-flex align-items-center'>
                    <ActivityLoaderMintbox />
                </div> : Object.values(segmentsData || {})?.reduce((a, c) => { return a + (Object.keys(c || {})?.length || 0) }, 0) <= 0 ?
                    <div className='flex-grow-1 d-flex align-items-center' style={{ padding: "1.25rem 0" }}>
                        <NoDataFound message={`We don't have enough data to show segments for ${fundData?.proper_name}`} />
                    </div> : <div style={{ flexGrow: 1, height: "100%", padding: "1.25rem 0" }}>
                        {loadingSegments ? <ActivityLoaderMintbox /> : <div style={{ position: "relative" }}>
                            <Segmented size={'medium'} value={selectedTab} options={tabs} onChange={(e) => setSelectedTab(e)} />
                            {tabs?.find((el) => el?.value === selectedTab)?.children}
                        </div>}
                    </div>}
            </>
        )
    }

    const HistoricalDataView = () => {

        const getTableData = (type) => {
            let ob = {}
            for (let key in segmentsData) {
                let a = [];
                let cols = []
                let segment = segmentsData?.[key];
                for (let key2 in segment) {
                    let s = segment?.[key2];
                    let t = s?.[type]?.data || [];
                    cols = [...cols, key2]
                    for (let j = 0; j < t?.length; j++) {
                        let k = t[j];
                        let check = a.find((el) => el.label === k.label);
                        let checkIndex = a.findIndex((el) => el.label === k.label);
                        if (check) {
                            a[checkIndex] = { ...a[checkIndex], [key2]: k?.[type] }
                        }
                        else {
                            a = [...a, { label: k.label, [key2]: k?.[type] }]
                        }
                    }
                }
                let lastCol = cols?.[cols?.length - 1] || "";
                console.log(a, lastCol)
                a = a.sort((a, b) => {
                    return Number(b[`${lastCol}`] || 0) - Number(a[`${lastCol}`] || 0)
                });
                console.log(a, lastCol)
                ob[key] = { dataSource: a, othCols: cols }
            }
            return ob;
        }

        const HistoricalChartAndTable = ({ data = {}, heading, label = '' }) => {
            console.log(data, heading)

            const othCols = data?.othCols?.map((el) => {
                let dat = new Date(el);
                let title = <div><div className='textSM w-500 dark4'>{moment(dat).format('YYYY')}</div>
                    <div className='textXS w-500 dark3'>{moment(dat).format("MMM DD")}</div>
                </div>
                let ob = {
                    title: title,
                    dataIndex: el,
                    key: el,
                    align: "right",
                    render: (text) => {
                        if (text === undefined || text === null) return '-';
                        return DecimalValueFormat({ num: text, suffix: "", prefix: "", decimals: finStatements?.round_to || 0 })
                    }
                }
                return ob;
            })

            const columns = [
                {
                    title: "",
                    dataIndex: "label",
                    key: "label",
                    fixed: "left",
                    width: isMobile ? "35vw" : 300,
                    render: (text) => {
                        return <span className='black'>{text}</span>
                    }
                },
                ...othCols
            ]

            const config = {
                chart: {
                    type: 'column',
                    height: 250,
                    panning: true
                },
                title: { text: "" },
                credits: { enabled: false },
                exporting: { enabled: false },
                xAxis: {
                    categories: data?.othCols?.map((el) => formatDateMonthYear(new Date(el))),
                    crossHair: true
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    },
                    stackLabels: {
                        enabled: false
                    },
                },
                legend: {
                    enabled: true
                },
                tooltip: {
                    shared: true,
                    backgroundColor: "var(--dark4)",
                    style: { color: "var(--grey5)", fontsize: "0.75rem" },
                    useHTML: true,
                    headerFormat: '<div class="textSM w-500 white">{point.key}</div>',
                    valueSuffix: " Cr",
                    valuePrefix: "₹ ",
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: false
                        }
                    },
                    series: {
                        grouping: false,
                        lineWidth: 1,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                series: data?.dataSource?.map((el) => {
                    let ob = {
                        name: el?.label,
                        data: data?.othCols?.map((ele) => el[ele] || '-')
                    }
                    return ob;
                }),
                boost: {
                    useGPUTranslations: true,
                }
            }
            return (
                <div style={{ marginTop: "1.25rem" }}>
                    <div className='textMD w-500'>{heading}</div>
                    {data?.dataSource?.length <= 0 ? <div>
                        <NoDataFound message={`We don't have enough data to show the ${label} for ${fundData?.proper_name}.`} msgMaxWidth='60ch' />
                    </div> :
                        <>
                            <div style={{ margin: "1rem 0" }}>
                                <HighchartsReact highcharts={Highcharts} options={config} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <Table columns={columns} dataSource={data?.dataSource} size={'small'} pagination={false} scroll={{ x: "max-content" }} className='segmentsTable'
                                    rowKey={(record) => generateUniqueKey(record?.label)} />
                            </div>
                        </>}
                </div>
            )
        }

        const tabItems = [
            { label: "Sales", key: "sales", value: "sales", data: getTableData('sales') },
            { label: "Assets", key: "assets", value: "assets", data: getTableData('assets') },
            { label: "Operating Income", key: "opinc", value: "opinc", data: getTableData('opinc') },
            { label: "Capex", key: "capex", value: "capex", data: getTableData('capex') },
        ]

        const othertabItems = [
            { label: "Business", key: "Business", value: "business" },
            { label: "Regional", key: "Regional", value: "regional" },
        ]

        console.log(tabItems);

        const [segmentType, setSegmentType] = React.useState('sales');
        const [segmentFactor, setSegmentFactor] = React.useState("business");

        React.useEffect(() => {
            const x = document.querySelector(".segmentsTable")?.getElementsByClassName("ant-table-content")?.[0];
            if (x) {
                x.scrollLeft += 200000;
            }
        }, [segmentType, segmentFactor]);

        return (
            <>
                {loadingSegments ? <div className='flex-grow-1 d-flex align-items-center'>
                    <ActivityLoaderMintbox />
                </div> : Object.values(segmentsData || {})?.reduce((a, c) => { return a + (Object.keys(c || {})?.length || 0) }, 0) <= 0 ?
                    <div className='flex-grow-1 d-flex align-items-center'>
                        <NoDataFound message={`We don't have enough data to show segments for ${fundData?.proper_name}`} />
                    </div> :
                    <div style={{ height: "100%", padding: "1.25rem 0" }}>
                        <div className='d-flex justify-content-between' style={{ flexWrap: "wrap", gap: "1.25rem" }} >
                            <Segmented size={'medium'} value={segmentType} options={tabItems} onChange={(e) => setSegmentType(e)} />
                            <Segmented size={'medium'} value={segmentFactor} options={othertabItems} onChange={(e) => setSegmentFactor(e)} />
                        </div>
                        <HistoricalChartAndTable heading={segmentFactor?.[0]?.toUpperCase() + segmentFactor?.substring(1)} label={tabItems?.find((el) => el?.value === segmentType)?.label}
                            data={tabItems?.find(el => el.value === segmentType)?.data?.[segmentFactor]} />
                    </div>
                }
            </>
        )
    }

    const [viewType, setViewType] = React.useState('chart');

    const viewItems = [
        { label: "Snapshot", key: '0', value: "chart", children: <ChartView /> },
        { label: "Historical Data", key: '1', value: "historicaldata", children: <HistoricalDataView /> }
    ]

    return (
        <>
            <div className='margin20Bottom'>
                <Tabs size={'medium'} activeKey={viewItems?.find((el) => el.key === viewType)?.key} items={viewItems} onChange={(e) => {
                    let el = viewItems?.find((el) => el.key === e)?.value
                    setViewType(el || '')
                }} />
            </div>
        </>
    );
});