import { Result } from "antd";
import { toggleHelpAndSupp } from "Libs/redux/UserAuth/reduxSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

export const ErrorComponent = (props) => {
    const location = useLocation();
    const [isError, setisError] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isError) {
            setisError(false);
            window.location.reload();
        }
    }, [location.pathname]);

    useEffect(() => {
        setisError(true);
    }, []);

    return (
        <div className='innerContainer'>
            <Result
                status="404"
                title=""
                subTitle={<div className='textSM w-500 black' style={{ maxWidth: "70ch", textAlign: "center", margin: "auto" }}>
                    Oops! Seems like we have hit a roadblock. Why not give this page a magical refresh? If the glitch continues, please report the issue using this <span onClick={() => {
                        dispatch(toggleHelpAndSupp(true))
                    }} style={{ color: "var(--primary)", cursor: "pointer" }}>link</span> and we will take it up ASAP. Your experience is our top priority!
                </div>}
                extra={<button onClick={() => {
                    window.location.reload()
                }} className="btnBlack ">Refresh</button>}
            />
        </div>
    );
};
