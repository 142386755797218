const BuySellSwitch = ({ onChange, value, type = 1, className = "", disabled = false }) => {
    if (type === 1) { // for buy sell green red
        return <div style={{ marginTop: '-1rem', opacity: disabled ? 0.6 : 1, cursor: disabled ? "not-allowed" : "pointer" }}>
            <span className={"textXS me-2"} style={{ color: value ? 'var(--black)' : 'var(--green)', fontWeight: value ? '400' : '500' }}>BUY</span>
            <label className="buysell-switch">
                <input disabled={disabled} type="checkbox" onChange={onChange} checked={value} style={{ cursor: disabled ? "not-allowed" : "pointer" }} />
                <span className="buysell-slider"></span>
            </label>
            <span className={"textXS ms-2"} style={{ color: value ? 'var(--red)' : 'var(--black)', fontWeight: value ? '500' : '400' }}>SELL</span>
        </div>
    } else if (type === 2) { // for normal switch
        return <div style={{}}>
            <label className="sip-switch">
                <input disabled={disabled} type="checkbox" onChange={onChange} checked={value} />
                <span className="sip-slider"></span>
            </label>
        </div>
    } else return <></>
}
export default BuySellSwitch