import React from "react";
import { cagr, alpha, sharpe, maxDrawdown, benchmark, expenseRatio, pe, pb, number, trackingErr, aum, inception, avgDuration, avgMaturity, top5, top10, beta, annVolatility, lockIn, taxation, exitLoad, minInvestment, minSubSeqInv, minSip, minWithdrawal, minBalance, avgDailyTradedVal, fundHouse, lumpsum, portfolioExpense, industry, sector, category, return1d, return1m, return6m, returnytd, roe, roce, trackingdiff1y, minadditionalinvestment, marketcap, enterprisevalue, annytm, avgytm, onedayreturn, annualreturn, roa, npa, } from 'Assets/images'

export const DetailIcon = ({ width = '1rem', height = '1rem', padding = '0.25rem', type = '' }) => {
    let icons = [
        { type: "Market cap", img: marketcap }, { type: "Enterprise value", img: enterprisevalue }, { type: "ROCE", img: roce }, { type: "ROE", img: roe },
        { type: "Return 1M", img: return1m }, { type: "Return 6M", img: return6m }, { type: "Return YTD", img: returnytd }, { type: "Return 1Y", img: return1d },
        { type: "Average YTM", img: avgytm }, { type: "Annualized YTM", img: annytm }, { type: "Yield to maturity", img: annytm },
        { type: "CAGR", img: cagr }, { type: "CAGR (since inception)", img: cagr }, { type: "CAGR (3Y)", img: cagr }, { type: "Alpha", img: alpha }, { type: "Beta", img: beta },
        { type: "Sharpe ratio", img: sharpe }, { type: "Max Drawdown", img: maxDrawdown }, { type: "Max drawdown", img: maxDrawdown },
        { type: "Benchmark", img: benchmark }, { type: "Avg. expense ratio", img: expenseRatio }, { type: "Expense Ratio", img: expenseRatio },
        { type: "Expense ratio", img: expenseRatio }, { type: "P/E ratio", img: pe }, { type: "P/E Ratio", img: pe }, { type: "P/E (TTM)", img: pe },
        { type: "PE score", img: pe }, { type: "P/B Ratio", img: pb }, { type: "P/B ratio", img: pb }, { type: "P/B", img: pb }, { type: "PB score", img: pe },
        { type: "Number of stocks", img: number }, { type: "Tracking Error", img: trackingErr }, { type: "AUM", img: aum }, { type: "Inception", img: inception },
        { type: "Duration", img: avgDuration }, { type: "Modified Duration", img: avgDuration }, { type: "Maturity", img: avgMaturity },
        { type: "Average Maturity", img: avgMaturity }, { type: "Number of securities", img: number }, { type: "Top 5 holding percentage", img: top5 },
        { type: "Top 10 holding percentage", img: top10 }, { type: "Annualized Volatility", img: annVolatility }, { type: "Lock in", img: lockIn },
        { type: "Taxation", img: taxation }, { type: "Exit load", img: exitLoad }, { type: "Min. Investment", img: minInvestment },
        { type: "Min. Addl Investment", img: minadditionalinvestment }, { type: "Min. SIP Investment", img: minSip }, { type: "Min. Withdrawal", img: minWithdrawal },
        { type: "Min. Balance", img: minBalance }, { type: "Avg. daily traded value", img: avgDailyTradedVal }, { type: "Fund house", img: fundHouse },
        { type: "Min. subsequent investment", img: minSubSeqInv }, { type: "Min. SIP investment", img: minSip }, { type: "Min. lumpsum", img: lumpsum },
        { type: "Portfolio expense", img: portfolioExpense }, { type: "Average maturity", img: avgMaturity }, { type: "Average duration", img: avgDuration }, { type: "Industry", img: industry }, { type: "Sector", img: sector }, { type: "Category", img: category },
        { type: "Tracking Diff (1Y)", img: trackingdiff1y }, { type: "Annual return", img: annualreturn }, { type: "1 Day return", img: onedayreturn },
        { type: "Current balance", img: minBalance },
        { type: "ROA", img: roa }, { type: "Net NPA (%)", img: npa }, { type: "Gross NPA (%)", img: npa }, { type: "Net Interest Margin", img: avgDailyTradedVal }
    ]
    return (
        <div style={{ width: width, height: height, padding: padding, borderRadius: "50%", background: "var(--blueShade)" }}
            className='d-flex align-items-center justify-content-center'>
            <img loading="lazy"  alt="" src={icons?.filter((ele) => ele.type === type)?.[0]?.img || (type.startsWith('AUM (as on ') ? aum : null)
                || icons[10]?.img}
                style={{ width: "100%", height: "100%" }} />
        </div>
    )
}