import { generateUniqueKey } from 'Libs/utils';
import React from 'react';


export function MatrixCardView({ v1 = "Low", v2 = "Low", v1Head = "AKC", v2Head = "AMM", v1Values = ["Low", "Moderate", "High"], v2Values = ["Low", "Moderate", "High"] }) {
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="textSM w-500 dark3" style={{ writingMode: 'vertical-lr', transform: 'rotate(180deg)', marginTop: '50px' }}>{v1Head}</div>
            </div>

            <table className='matrix-table'>
                <tbody>
                    <tr>
                        <td></td>
                        <th colSpan={3} className="textSM w-500 dark3">{v2Head}</th>
                    </tr>
                    <tr>
                        <td></td>
                        {
                            v2Values.map((c, key1) => <td className='textSM dark3' key={generateUniqueKey(key1)}>{c}</td>)
                        }
                    </tr>
                    {
                        v1Values.map((r, key2) => <tr key={generateUniqueKey(key2)}>
                            <td className='textSM dark3' style={{ writingMode: 'vertical-lr', transform: 'rotate(180deg)' }}>{r}</td>
                            {
                                v2Values.map((c, key3) => <td className='matrix-boxContainer' key={generateUniqueKey(key3)}>
                                    <div className="matrix-box" style={{ backgroundColor: r === v1 && c === v2 ? 'var(--primary)' : 'var(--white)' }}></div>
                                </td>)
                            }
                        </tr>)
                    }
                </tbody>
            </table >
        </div>
    );
}
