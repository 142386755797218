import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { baseImgURL } from "Assets/Constant";
import { sharpelyLogoDark, sharpelyLogoLight } from "Assets/images";
import { Tag } from 'antd';

const Logo = ({ type = "light", isClickable = true, showBeta = true, height = '2.3rem', userAccessLevel = 1, withPlanName = false }) => {

  let user = useSelector((state) => state?.updateUserData?.value)
  const isMobile = window.innerWidth <= 760;
  let isBeta = process.env.REACT_APP_ENVIRONMENT === 'beta';
  if (isBeta && showBeta) {
    return <Link to={isClickable ? user?.isLogged ? "/stocks/dashboard" : "/" : ""} style={{ position: 'relative' }}>
      <img loading="lazy" src={`${baseImgURL}sharpely-asset/images/sharpely-beta-logo.png`} alt="logo" style={{ height: height }} className='sharpelyLogo' />
    </Link>
  }
  return (
    <div style={{ width: 'fit-content' }}>
      {type === "light" ?
        <Link to={isClickable ? user?.isLogged ? "/stocks/dashboard" : "/" : ""} style={{ position: 'relative' }} onClick={(e) => {
          if (!isClickable) e.preventDefault();
        }}>
          <img loading="lazy" src={sharpelyLogoLight} alt="logo" style={{ height: height, width: "auto" }} className='sharpelyLogo' />
        </Link> :
        <Link to={isClickable ? user?.isLogged ? "/stocks/dashboard" : "/" : ""} onClick={(e) => {
          if (!isClickable) e.preventDefault();
        }}>
          <img loading="lazy" src={sharpelyLogoDark} alt="logo" style={{ height: height, width: "auto" }} className='sharpelyLogo' />
          {/* {withPlanName && userAccessLevel >= 1 &&
            <Tag color={userAccessLevel === 1 ? "var(--primary)" : userAccessLevel === 2 ? "var(--green)" : userAccessLevel === 3 ? "var(--yellow)" : "var(--dark4)"} style={{
              borderRadius: "20px", marginLeft: "0.75rem"
            }}>
              {userAccessLevel === 1 ? "Free" : userAccessLevel === 2 ? "Lite" : userAccessLevel === 3 ? "Pro" : "Black"}
            </Tag>
            // <div style={{
            //   position: "absolute", top: isMobile ? "50%" : '0.4rem', right: "0rem", padding: "4px 8px",
            //   transform: isMobile ? "translate(120%, -45%)" : 'translateX(50%)',
            //   background: userAccessLevel === 1 ? "var(--primary)" : userAccessLevel === 2 ? "var(--green)" : userAccessLevel === 3 ? "var(--yellow)" : "var(--dark4)",
            //   color: userAccessLevel === 1 ? "var(--white)" : userAccessLevel === 2 ? "var(--white)" : userAccessLevel === 3 ? "var(--black)" : "var(--white)",
            //   borderRadius: "20px", fontSize: "10px", lineHeight: "100%", fontWeight: 700
            // }}>
            //   {userAccessLevel === 1 ? "Free" : userAccessLevel === 2 ? "Lite" : userAccessLevel === 3 ? "Pro" : "Black"}
            // </div>
          } */}
        </Link>}
    </div>
  );
};

export default Logo;
