import AMCIcon from "Components/AMCIcon";
import AccessComponent from "Components/AccessComponent";
import CustomDrawer from "Components/CustomDrawer";
import { FundInsightsModal, StockInsightsModal } from 'Components/Insights';
import { InsightIcon } from "Components/InsightsIcon";
import { MatrixCardView } from "Components/MatrixCardView";
import { StockScoresGauge2 } from "Components/StockScoresGauge2";
import UpgradeToPro from "Components/UpgradeToPro";
import { generateUniqueKey } from 'Components/atoms/SmallComponents';
import { StickyScrollTabs } from "Components/CustomComponents/StickyScrollTabs";
import { toggleMetricDescModal } from "Libs/redux/KnowledgeBase/reduxSlice";
import apiHelperPY from 'Services/api/ApiHelperPY';
import { Card, Collapse, Segmented, Table } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react';
import { BsArrowRightShort } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from "reactstrap";
import ActivityLoaderMintbox from "./ActivityLoaderMintbox";
import { DecimalValueFormat } from "./DecimalValueFormat";
import { NoDataFound } from './NoDataFound';

const Analysis = ({ insights, holdings, overlap, loading, analysisType, type, fundData = {}, screenerMetricDescData = [], otherTabs = [], showAnalysis = true, fromFundDetail = false }) => {

    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    const dispatch = useDispatch();

    const DisplayViews = ({ x }) => {

        const ViewItem = ({ viewType = 'Positive', items = [] }) => {

            return (
                <Card style={{ background: "var(--white)", border: "1px solid var(--grey3)", borderRadius: "var(--borderRadius)", }} className="flexCard w100 h100"
                    bodyStyle={{ padding: "1.25rem", borderRadius: "var(--borderRadius)", }}>
                    <div className="textMD w-500" style={{ marginBottom: "0.75rem" }}>
                        {viewType}
                    </div>
                    {items?.length > 0 ? (
                        <Collapse accordion bordered={false} style={{ background: "var(--white)" }} expandIcon={({ isActive }) => (
                            <IoIosArrowDown style={{ fontSize: "0.75rem", transform: isActive ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.2s ease-in-out", }} />)}
                            className="blogAndFaqs" expandIconPosition="end" items={items?.map((item) => {
                                return {
                                    key: item?.param_id,
                                    label: <div style={{ display: "flex", alignItems: "center", gap: "1rem", }}>
                                        <InsightIcon type={viewType} height="2rem" width="2rem" padding="0.5rem" />
                                        <div style={{ flex: 1 }} className="textSM w-500">
                                            {item?.name}
                                        </div></div>,
                                    children: <div className='textXS dark4 w-500'>
                                        {item?.stance_text}
                                    </div>,
                                    style: { borderTop: "1px solid var(--grey3)", borderBottom: "0px", }
                                }
                            })} />
                    ) : (
                        <div>
                            <div className='textSM w-500 dark3'>{`Not enough talking point with ${viewType?.toLowerCase()} stance`}</div>
                        </div>)}
                </Card>
            )
        }

        return (
            <Row lg={3} sm={2} xs={1}>
                <Col>
                    <ViewItem viewType='Positive' items={x?.data?.filter((el) => el?.stance === 'Positive')} />
                </Col>
                <Col>
                    <ViewItem viewType='Negative' items={x?.data?.filter((el) => el?.stance === 'Negative')} />
                </Col>
                <Col>
                    <ViewItem viewType='Neutral' items={x?.data?.filter((el) => { return !((['Positive', 'Negative'])?.includes(el?.stance)) })} />
                </Col>
            </Row>
        )
    }

    const DisplayStyleAnalysis = ({ x }) => {

        const getScoreKey = (name) => {
            let keys = { "Value score": "sh_val_comp", "Quality score": "sh_qual_comp", "Momentum score": "sh_mom_comp", "QVM score": "sh_3fac_comp" }
            return keys[name]
        }

        return (
            <>
                <Row lg={4} sm={2} xs={1} className='gx-3 gy-3'>
                    {x?.data?.map((el) => {
                        let key = getScoreKey(el?.name);
                        return <Col key={generateUniqueKey(el?.name)}>
                            <Card key={el?.name} style={{ border: "1px solid var(--grey2)" }} bodyStyle={{ padding: "1rem" }} className='w100 h100'>
                                <div className="textSM w-500">
                                    {el?.name}
                                </div>
                                <AccessComponent component={<>
                                    <div>
                                        <StockScoresGauge2 val={el?.value || fundData?.[key]} height={170} paneThickness={17} showTickLabels={true} tickAmount={5}
                                            labelSuffix={'/ 100'} />
                                    </div>
                                    <div style={{ display: "flex", gap: "0.5rem", marginBottom: "1rem" }}>
                                        <div style={{ flexShrink: 0 }}>
                                            <InsightIcon type={el?.stance} height="2rem" width="2rem" padding="0.5rem" />
                                        </div>
                                        <div className='textXS w-500 dark4'>
                                            {el?.stance_text}
                                        </div>
                                    </div>
                                    <div className="textXS w-500 text-center" style={{ color: "var(--primary)", cursor: "pointer" }}
                                        onClick={() => {
                                            let met = screenerMetricDescData?.find((ele) => ele?.name?.toLowerCase()?.split(' ')?.join('') === el?.name?.toLowerCase()?.split(' ')?.join(''));
                                            dispatch(toggleMetricDescModal({
                                                value: true,
                                                params: {
                                                    title: met?.name,
                                                    description: met?.description
                                                }
                                            }))
                                        }}>
                                        What is {el?.name}?
                                    </div>
                                </>} moduleKey={fromFundDetail ? (type?.value === 1 ? "ADVANCE_ETF_REPORT" : type?.value === 2 ? "ADVANCE_MF_REPORT" : "") : ""}
                                    upgradeComponent={<div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-end", margin: "2rem 0" }}>
                                        <UpgradeToPro moduleKey={fromFundDetail ? (type?.value === 1 ? "ADVANCE_ETF_REPORT" : type?.value === 2 ? "ADVANCE_MF_REPORT" : "") : ""}
                                            height="fit-content" descClassName="textXS w-500 dark3 mb-3" zIndex="1" />
                                    </div>} />
                            </Card>
                        </Col>
                    })}
                </Row>
            </>
        )
    }

    const DisplayRedFlags = ({ x }) => {
        return <AccessComponent component={<div style={{ minHeight: '100%', display: 'flex', flexDirection: 'column', gap: '0.75rem', }}>
            {x?.data?.map((data, i) => {
                const dataTableColumns = Object.keys(data?.stance_table?.[0] || {}).map(k => {
                    if (k === 'symbol') {
                        return undefined
                    } else if (k === "proper_name") {
                        return {
                            "title": "Name",
                            "dataIndex": "proper_name",
                            render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }}>
                                <AMCIcon amcName={record.symbol} fundType={4} height='1.2rem' width="1.2rem" bseTicker={record?.bse_ticker} />
                                <div>
                                    <div className="textSM dark3 black">{record?.proper_name}</div>
                                </div>
                            </div>,
                            fixed: 'left',
                        }
                    }
                    else return {
                        "title": "Value",
                        "dataIndex": k,
                        "align": "right",
                    }
                }).filter(x => x)
                return <Card key={generateUniqueKey(data.param_id)} style={{ borderRadius: '12px', border: "1px solid var(--grey3)" }} bodyStyle={{ padding: 0 }}>
                    <div style={{ display: 'flex', gap: '1rem', padding: '0.625rem 1.25rem' }}>
                        <InsightIcon type='RedFlag' isRedFlag={true} redFlagType={data.stance} />
                        <div style={{ flex: 1 }}>
                            <div className="fontSM w-500 dark3 mb-2">{data.name}</div>
                            <div className="textSM dark3">{data.stance_text}</div>
                            <div className="mt-2 me-4">
                                {data.stance_table ? <Table className='insideTable' pagination={false} dataSource={data.stance_table} columns={dataTableColumns}
                                    rowKey={(rec) => generateUniqueKey(rec?.proper_name)} /> : <></>}
                            </div>
                        </div>
                    </div>
                </Card>
            })}
        </div>} moduleKey={fromFundDetail ? (type?.value === 1 ? "ADVANCE_ETF_REPORT" : type?.value === 2 ? "ADVANCE_MF_REPORT" : "") : ""}
            upgradeComponent={<div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-end", margin: "2rem 0" }}>
                <UpgradeToPro moduleKey={fromFundDetail ? (type?.value === 1 ? "ADVANCE_ETF_REPORT" : type?.value === 2 ? "ADVANCE_MF_REPORT" : "") : ""}
                    height="fit-content" descClassName="textXS w-500 dark3 mb-3" zIndex="1" />
            </div>} />
    }

    const DisplayTickerList = ({ x = [] }) => {
        const [childrenDrawer, setChildrenDrawer] = React.useState(false);
        const [selectedStock, setSelectedStock] = React.useState({})
        const dataTableColumns = [
            {
                "title": "Name",
                "dataIndex": "full_name",
                render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }}>
                    <AMCIcon amcName={record.symbol} fundType={4} height='1.2rem' width="1.2rem" bseTicker={record?.bse_ticker} />
                    <div>
                        <div className="textSM dark3 black">{text}</div>
                    </div>
                </div>,
                fixed: 'left',
            },
            {
                "title": "Weight",
                "dataIndex": 'weight',
                "align": "right",
                render: (text) => <div style={{ whiteSpace: 'nowrap' }}>{DecimalValueFormat({ num: text, suffix: " %" })}</div>,
            },
            {
                "align": "right",
                render: t => <BsArrowRightShort />,
            },
        ]

        return <div style={{ minHeight: '100%' }}>
            <Table bordered={false} className='textXS dark2 black tickerTable' pagination={false} dataSource={x} columns={dataTableColumns}
                onRow={(record) => { return { onClick: () => { setSelectedStock(record); setChildrenDrawer(true) }, } }}
                rowKey={(rec) => generateUniqueKey(rec?.full_name)} />
            <CustomDrawer title={<div style={{ display: 'flex', gap: '0.75rem', alignItems: 'center' }}>
                <AMCIcon amcName={selectedStock?.symbol} fundType={4} height='1.5rem' width="1.5rem" bseTicker={selectedStock?.bse_ticker} />
                <div className="textSM w-700 black">{selectedStock.full_name || selectedStock?.proper_name}</div>
            </div>} toggle={() => setChildrenDrawer(!childrenDrawer)} isOpen={childrenDrawer} showBackBtn={true}>
                <AccessComponent component={<StockInsightsModal fetchFromApi={true} ticker={selectedStock?.symbol} />}
                    upgradeComponent={<div style={{ background: "var(--grey4)", border: "1px solid var(--grey3)", borderRadius: "var(--borderRadius)" }}
                        className="w100 h100 d-flex justify-content-center align-items-center">
                        <UpgradeToPro moduleKey={'STOCK_INSIGHTS'} height="20rem" width="90%" />
                    </div>}
                    moduleKey={"STOCK_INSIGHTS"} />
            </CustomDrawer>
        </div>
    }

    const DisplayStanceTextList = ({ x }) => {
        return <AccessComponent component={<div style={{ minHeight: '100%', borderRadius: '0.5rem', display: 'flex', flexDirection: 'column', gap: '0.75rem', }}>
            {x?.data?.map((data, i) => {
                return <Card key={generateUniqueKey(data.param_id)} style={{ borderRadius: '12px', border: "1px solid var(--grey3)" }} bodyStyle={{ padding: 0 }}>
                    <div style={{ display: 'flex', gap: '1rem', padding: '0.625rem 1.25rem', alignItems: "center" }}>
                        <div style={{ marginRight: "0rem" }}>
                            <InsightIcon type={data.stance} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <div className="fontSM dark3 w-500 mb-2">{data.name}</div>
                            <div className="textSM dark3">{data.stance_text}</div>
                        </div>
                    </div>
                </Card>
            })}
        </div>} moduleKey={fromFundDetail ? (type?.value === 1 ? "ADVANCE_ETF_REPORT" : type?.value === 2 ? "ADVANCE_MF_REPORT" : "") : ""}
            upgradeComponent={<div className="my-4">
                <UpgradeToPro moduleKey={fromFundDetail ? (type?.value === 1 ? "ADVANCE_ETF_REPORT" : type?.value === 2 ? "ADVANCE_MF_REPORT" : "") : ""} height="fit-content"
                    descClassName="textXS w-500 dark3" />
            </div>} />
    }

    const DisplayEquityInsights = ({ x }) => {

        const viewItems = ['Diversification', 'Performance'];
        switch (x.type) {
            case 3: {
                if (x?.section_name === "Style analysis") {
                    return DisplayStyleAnalysis({ x });
                }
                else if (viewItems?.includes(x?.section_name)) {
                    return DisplayViews({ x });
                }
                else {
                    return DisplayStanceTextList({ x })
                }
            };
            case 4: return DisplayRedFlags({ x });
            case 10: return DisplayTickerList({ x: x?.data });
        }
    }

    const DisplayInstruments = ({ x }) => {
        const getDataTableColumns = React.useCallback(() => {
            return Object.keys(x?.data?.[0] || {}).map((k, i) => {
                const title = k.replaceAll("_", " ")
                return {
                    title: title[0].toUpperCase() + title.slice(1),
                    dataIndex: k,
                    align: i === 0 ? 'left' : 'right',
                    fixed: i === 0 ? 'left' : '',
                    width: i === 0 ? '30%' : '15%',
                    render: (text, record, col) => {
                        if (k === "coupon_rate" || k === "weight") {
                            return DecimalValueFormat({ num: text, suffix: '%' })
                        }
                        else return text
                    }
                }
            })
        }, [x])
        const dataTableColumns = getDataTableColumns()
        return <AccessComponent component={<div style={{ minHeight: '100%' }}>
            <Table className='insideTable' pagination={false} dataSource={x?.data} columns={dataTableColumns} rowKey={(rec) => generateUniqueKey(rec?.full_name)} />
        </div>} moduleKey={fromFundDetail ? (type?.value === 1 ? "ADVANCE_ETF_REPORT" : type?.value === 2 ? "ADVANCE_MF_REPORT" : "") : ""}
            upgradeComponent={<div className="my-4">
                <UpgradeToPro moduleKey={fromFundDetail ? (type?.value === 1 ? "ADVANCE_ETF_REPORT" : type?.value === 2 ? "ADVANCE_MF_REPORT" : "") : ""} height="fit-content"
                    descClassName="textXS w-500 dark3" />
            </div>} />
    }

    const DisplaySebiPrcMatrix = ({ x }) => {
        return <AccessComponent component={<div style={{ minHeight: '100%', borderRadius: '0.5rem', overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: '0.75rem', }}>
            <MatrixCardView v1Head='Credit risk' v2Head='Interest risk' v1={x?.data?.credit_risk} v2={x?.data?.interest_rate_risk} />
        </div>} upgradeComponent={<div className="my-4">
            <UpgradeToPro moduleKey={fromFundDetail ? (type?.value === 1 ? "ADVANCE_ETF_REPORT" : type?.value === 2 ? "ADVANCE_MF_REPORT" : "") : ""} height="fit-content"
                descClassName="textXS w-500 dark3" />
        </div>} moduleKey={fromFundDetail ? (type?.value === 1 ? "ADVANCE_ETF_REPORT" : type?.value === 2 ? "ADVANCE_MF_REPORT" : "") : ""} />
    }

    const DisplayDebtInsights = ({ x }) => {
        switch (x.type) {
            case 1: return DisplayInstruments({ x });
            case 2: return DisplaySebiPrcMatrix({ x });
            case 3: return DisplayStanceTextList({ x })
        }
    }

    const DisplayFundsList = ({ x, type }) => {
        const [childrenDrawer, setChildrenDrawer] = useState(false);
        const [loading, setLoading] = useState(false);
        const [selected, setSelected] = useState({})
        const [insight, setInsights] = useState({})
        const dataTableColumns = [
            {
                "title": "Name",
                "dataIndex": "basic_name",
                render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }}>
                    <AMCIcon amcName={record.amc_full_name} fundType={2} height='1.2rem' width="1.2rem" />
                    <div>
                        <div className="textSM dark3 black">{text}</div>
                    </div>
                </div>,
                fixed: 'left',
            },
            {
                "title": "Weight",
                "dataIndex": 'weights',
                "align": "right",
                render: (text) => <div style={{ whiteSpace: 'nowrap' }}>{DecimalValueFormat({ num: Number(text) * 100, suffix: " %" })}</div>,
            },
            {
                "align": "right",
                render: t => <BsArrowRightShort />,
            },
        ]
        React.useEffect(() => {
            const dum = async () => {
                setLoading(true)
                let res = await apiHelperPY({ apiName: 'getMFInsights', data: { planId: selected?.plan_id, assetType: selected?.primary_category_name?.toLowerCase() }, saveResponse: '' })
                if (res) {
                    setInsights(res)
                    setLoading(false)
                }
                else {
                    setLoading(false)
                    setInsights([])
                }
            }
            dum();
        }, [selected])
        return <><Table bordered={false} size='small' className='textXS dark2 black tickerTable' pagination={false}
            dataSource={x} columns={dataTableColumns} onRow={(record) => {
                return {
                    onClick: () => { setSelected(record); setChildrenDrawer(true) }, // click row
                };
            }} rowKey={(rec) => generateUniqueKey(rec?.basic_name)} />
            <CustomDrawer
                title={<div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                    <AMCIcon amcName={selected.amc_full_name} fundType={2} height='1.5rem' width="1.5rem" />
                    <div className="textSM w-700 black">{selected.basic_name}</div>
                </div>}
                toggle={() => setChildrenDrawer(!childrenDrawer)}
                isOpen={childrenDrawer} showBackBtn={true}
            >
                {
                    loading ?
                        <ActivityLoaderMintbox message='Data crunching underway. Hang on!' margin="5rem" />
                        :
                        Object.keys(insight || {}).length ?
                            <FundInsightsModal insights={insight} type={type} />
                            :
                            <div className="fontH2 mt-4">Not enough data</div>
                }
            </CustomDrawer>
        </>
    }

    const PortfolioOverlap = ({ data, holdings }) => {
        var heatmapdata = data
        var funds = data?.map((data) => { return data?.plan_id })
        var vals = []
        var dataFinal = funds?.map((s, index) => {
            return funds?.map((f, yindex) => {
                vals.push(Number((heatmapdata[yindex][s]).toFixed(1)))
                return {
                    name: holdings?.find(h => h.plan_id === f)?.basic_name,
                    x: index,
                    y: yindex,
                    value: Number((heatmapdata[yindex][s]).toFixed(1))
                }
            })
        })

        dataFinal = dataFinal?.map((i, i1) => {
            return i.map((a, i2) => {
                if (i2 <= i1) {
                    return {
                        ...a,
                        visible: false
                    }

                } else {
                    return {
                        ...a,
                    }

                }
            })
        })?.flat(1)


        var chart = {

            chart: {
                type: 'heatmap',
                plotBorderWidth: 0,
                borderWidth: 0,
                marginTop: -40
            },


            title: {
                text: '',
                style: {
                    color: "var(--black)"
                }
            },
            scrollbar: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },

            xAxis: {
                categories: funds?.map(p => holdings?.find(h => h.plan_id === p)?.basic_name?.replace(" - Direct Plan", "")),
                lineWidth: 0,

                labels: {
                    formatter: function () {
                        if (!this.isLast) {
                            return this.value;
                        } else {
                            return null
                        }

                    }
                }

            },

            yAxis: {
                tickAmount: 5,
                gridLineDashStyle: 'none',
                gridLineWidth: 0,
                categories: funds.map(p => holdings?.find(h => h.plan_id === p)?.basic_name?.replace(" - Direct Plan", "")),
                title: null,
                reversed: true,
                labels: {
                    formatter: function () {
                        if (!this.isFirst) {
                            return this.value;
                        } else {
                            return null
                        }

                    }
                }
            },


            colorAxis: {
                min: Math.min(...vals),
                max: Math.max(...vals.filter((i) => i !== 100)),
                minColor: '#c9f6bf',
                maxColor: '#fcaead',
            },

            legend: {
                enabled: false
            },

            tooltip: {
                headerFormat: '',
                pointFormat: '{point.value}% overlap',
            },
            series: [{
                name: 'Monthly returns',
                borderWidth: 0.2,
                borderColor: 'black',
                data: dataFinal,
                dataLabels: {
                    enabled: true,
                    color: '#000000',
                    style: {
                        fontWeight: 'normal',
                        fontSize: 8,
                        textOutline: false
                    },
                    pointFormat: '{point.value}%',
                }
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 300
                    },
                    chartOptions: {
                        yAxis: {
                            labels: {
                                formatter: function () {
                                    return this.value.charAt(0);
                                }
                            }
                        }
                    }
                }]
            }

        }
        if (data?.length <= 0) {
            return <div style={{ minHeight: '100%', padding: '1.25rem' }}>
                <Card>
                    <NoDataFound message='Not enough data to show portfolio overlap heatmap' gap='1.5rem' msgMaxWidth='60ch' />
                </Card>
            </div>
        }
        return <Card bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: '1rem', borderRadius: "var(--borderRadius)" }}
            style={{ padding: 0, borderRadius: "var(--borderRadius)", border: "1px solid var(--grey2)" }}>
            <HighchartsReact highcharts={Highcharts} options={chart} />
        </Card>
    }

    const FundAnalysis = () => {

        const FundOverview = ({ data }) => {

            const ViewItem = ({ viewType, items = [] }) => {
                return (
                    <Card style={{ background: "var(--white)", border: "1px solid var(--grey3)", borderRadius: "var(--borderRadius)", }} className="flexCard w100 h100"
                        bodyStyle={{ padding: "1.25rem", borderRadius: "var(--borderRadius)", }}>
                        <div className="textMD w-500" style={{ marginBottom: "0.75rem" }}>
                            {viewType}
                        </div>
                        {items?.length > 0 ? (
                            <Collapse accordion bordered={false} style={{ background: "var(--white)" }} expandIcon={({ isActive }) => (
                                <IoIosArrowDown style={{ fontSize: "0.75rem", transform: isActive ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.2s ease-in-out", }} />)}
                                className="blogAndFaqs" expandIconPosition="end" items={items?.map((item, i) => {
                                    let metricObj = screenerMetricDescData?.find((el) => el?.desc_id === Number(item?.desc_id)) || {};
                                    return {
                                        key: generateUniqueKey(i),
                                        label: <div style={{ display: "flex", alignItems: "center", gap: "1rem", }}>
                                            <InsightIcon type={viewType} height="2rem" width="2rem" padding="0.5rem" />
                                            <div style={{ flex: 1 }} className="textSM w-500">
                                                {item?.heading}
                                            </div></div>,
                                        children: <>
                                            <div className='textXS dark4 w-500'>
                                                {item?.text}
                                            </div>
                                            <div className='textXS dark4 w-500 mt-2'>
                                                {item?.help_text}
                                            </div>
                                            {metricObj?.desc_id && (<div className="textXS w-500" style={{ cursor: "pointer", color: "var(--primary)", marginTop: "1rem", }}
                                                onClick={() => {
                                                    dispatch(toggleMetricDescModal({
                                                        value: true,
                                                        params: {
                                                            title: metricObj?.name,
                                                            description: metricObj?.description
                                                        }
                                                    }))
                                                }}>
                                                What is {metricObj?.name}?
                                            </div>
                                            )}
                                            {item?.chart_type === "NA" || !item?.chart_type ? null : (<div style={{ marginTop: "1rem" }}></div>)}
                                        </>,
                                        style: { borderTop: "1px solid var(--grey3)", borderBottom: "0px", }
                                    }
                                })} />
                        ) : (
                            <div>
                                <div className='textSM w-500 dark3'>{`Not enough talking point with ${viewType?.toLowerCase()} stance`}</div>
                            </div>)}
                    </Card>
                )
            }

            if (loading) {
                return (
                    <div>
                        <ActivityLoaderMintbox message='Data crunching underway. Hang on!' margin='2rem' />
                    </div>
                )
            }

            return (
                <Row lg={3} sm={2} xs={1}>
                    <Col>
                        <ViewItem viewType='Positive' items={data?.views?.filter((el) => el?.stance === 'Positive')} />
                    </Col>
                    <Col>
                        <ViewItem viewType='Negative' items={data?.views?.filter((el) => el?.stance === 'Negative')} />
                    </Col>
                    <Col>
                        <ViewItem viewType='Neutral' items={data?.views?.filter((el) => el?.stance === 'Neutral')} />
                    </Col>
                </Row>
            )
        }

        const EquityInsights = ({ data }) => {

            const getOptions = React.useCallback(() => {
                let temp = Object.values(JSON.parse(data || "{}")?.equity_insights?.overview || {})
                if (JSON.parse(data || "{}")?.equity_insights?.ticker_list) {
                    temp.push({ section_name: 'Detailed equity insights', type: 10, data: JSON.parse(data || "{}")?.equity_insights?.ticker_list })
                }
                return temp;
            }, [data])

            let options = getOptions();
            const [selected, setSelected] = React.useState(options?.[0])
            console.log(selected);

            if (loading) {
                return (
                    <div>
                        <ActivityLoaderMintbox message='Data crunching underway. Hang on!' margin='2rem' />
                    </div>
                )
            }
            if (JSON.parse(data || "{}")?.equity_insights) {
                return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'var(--white)' }} >
                        <Segmented className="" value={selected?.section_name} options={options?.map(s => s.section_name)} onChange={(c) => setSelected(options?.find(x => x.section_name === c))} style={{ marginBottom: '1rem' }} />
                    </div>
                    <div style={{ flex: 1, overflowY: 'scroll' }}>
                        <DisplayEquityInsights x={selected} />
                    </div>
                </div>
            } else {
                return <Card style={{ border: "1px solid var(--grey3)" }}>No significant equity holdings</Card>
            }
        }

        const DebtInsights = ({ data }) => {

            const getOptions = React.useCallback(() => {
                let temp = Object.values(JSON.parse(data || "{}")?.debt_insights || {})
                return temp;
            }, [data])

            let options = getOptions();
            const [selected, setSelected] = React.useState(options?.[0])

            if (loading) {
                return (
                    <div>
                        <ActivityLoaderMintbox message='Data crunching underway. Hang on!' margin='2rem' />
                    </div>
                )
            }

            if (JSON.parse(data || "{}")?.debt_insights) {
                return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'var(--white)' }}>
                        <Segmented className="" value={selected?.section_name} options={options?.map(s => s.section_name)} onChange={(c) => setSelected(options?.find(x => x.section_name === c))} style={{ marginBottom: '1rem' }} /></div>
                    <div style={{ flex: 1, overflowY: 'scroll' }}>
                        <DisplayDebtInsights x={selected} />
                    </div>
                </div>
            } else {
                return <Card style={{ border: "1px solid var(--grey3)", }}>No significant debt holdings</Card>
            }
        }

        const tabData = [
            {
                key: 'Overview',
                label: "Key talking points",
                value: "Overview",
                heading: "Key talking points",
                show: true,
                children: <AccessComponent component={<FundOverview data={JSON.parse(insights?.overview || "{}")} />}
                    moduleKey={type?.value === 1 ? "ADVANCE_ETF_REPORT" : "ADVANCE_MF_REPORT"}
                    upgradeComponent={<div className="my-2">
                        <UpgradeToPro moduleKey={type?.value === 1 ? "ADVANCE_ETF_REPORT" : "ADVANCE_MF_REPORT"} height="fit-content" />
                    </div>} />,
                subHeading: "Some important talking points about the scheme’s performance, expense ratio and other things of importance."
            },
            {
                key: 'EquityInsights',
                label: "Equity Insights",
                value: "EquityInsights",
                heading: "Equity Insights",
                show: true,
                children: <EquityInsights data={insights?.mf_paid} />,
                subHeading: "A detailed look at the equity holding of the scheme (if applicable). Includes aggregated scores on value, momentum and quality parameters.",
            },
            {
                key: 'DebtInsights',
                label: "Debt Insights",
                value: "DebtInsights",
                heading: "Debt Insights",
                show: true,
                children: <DebtInsights data={insights?.mf_paid} />,
                subHeading: "A detailed look at the debt holding of the scheme (if applicable). Is the scheme yielding enough?"
            }
        ]
        return (
            <div>
                <StickyScrollTabs isAccordionInMob queriesToStickToBot={['#sharpelyHeader', '#customTabsBarId']} tabData={tabData} />
            </div>
        )
    }

    const FundPortfolioAnalysis = () => {

        const EquityInsights = () => {
            var options = Object.values(insights?.equity_insights?.overview || {})
            if (insights?.equity_insights?.ticker_list) {
                options.push({ section_name: 'Individual stock analysis', type: 10, data: insights?.equity_insights?.ticker_list })
            }

            const [selected, setSelected] = React.useState(options?.[0])
            if (insights?.equity_insights?.overview) {
                return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'var(--white)' }}>
                        <Segmented className="" value={selected?.section_name} options={options?.map(s => s.section_name)} onChange={(c) => setSelected(options?.find(x => x.section_name === c))} style={{ marginBottom: '1rem' }} />
                    </div>
                    <div style={{ flex: 1, overflowY: 'scroll' }}>
                        <DisplayEquityInsights x={selected} />
                    </div>
                </div>
            } else {
                return <div style={{ minHeight: '100%', padding: '1.25rem' }}>
                    <Card>
                        <NoDataFound message='It seems like your basket has no significant Indian equity holding or it is held
    through Fund of Funds (FoF) in which case we are not able to provide insights
    on the Indian equity portion of your basket' gap='1.5rem' msgMaxWidth='60ch' />
                    </Card>
                </div>
            }
        }

        const DebtInsights = () => {
            const options = Object.values(insights?.debt_insights || {})
            const [selected, setSelected] = React.useState(options?.[0])
            if (insights?.debt_insights) {
                return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'var(--white)' }} >
                        <Segmented className="" value={selected?.section_name} options={options?.map(s => s.section_name)} onChange={(c) => setSelected(options?.find(x => x.section_name === c))} style={{ marginBottom: '1rem' }} /></div>
                    <div style={{ flex: 1, overflowY: 'scroll' }}>
                        <DisplayDebtInsights x={selected} />
                    </div>
                </div>
            } else {
                return <div style={{ minHeight: '100%', padding: '1.25rem' }}>
                    <Card>
                        <NoDataFound message='It seems like your basket has no significant debt holding or debt is held
    through Fund of Funds (FoF) in which case we are not able to provide insights
    on the debt portion of your basket' gap='1.5rem' msgMaxWidth='60ch' />
                    </Card>
                </div>
            }

        }

        const tabData = [
            ...otherTabs,
            {
                key: "Equity portfolio",
                value: "EquityPortfolio",
                heading: 'Equity portfolio',
                show: true,
                children: <EquityInsights />,
                subHeading: "Analysis of stock holdings of the basket. Take a deep dive into individual holdings by switching tab."
            },
            {
                key: 'Debt portfolio',
                value: 'DebtPortfolio',
                heading: 'Debt portfolio',
                show: true,
                children: <DebtInsights />,
                subHeading: "Analysis of debt holdings of the basket. Look at all the individual debt instruments in the basket by switching tab."
            },
            {
                key: 'Individual Fund Analysis',
                value: "IndividualFundAnalysis",
                heading: `Individual ${type.strVal?.toUpperCase()} analysis`,
                children: <DisplayFundsList x={holdings} type={type} />,
                show: showAnalysis,
                subHeading: "Get a detailed insight into each individual ETF/MF in the basket"
            },
            {
                key: 'Portfolio Overlap',
                value: 'PortfolioOverlap',
                heading: `Portfolio Overlap`,
                children: <PortfolioOverlap data={overlap} holdings={holdings} />,
                show: true,
                subHeading: "An important chart that tells you how portfolios of the individual ETFs/MFs overlap. Very high overlap is not desirable."
            }
        ]

        return (
            <div>
                <StickyScrollTabs isAccordionInMob queriesToStickToBot={['#sharpelyHeader', '#customTabsBarId']} tabData={tabData} loading={loading} />
            </div>
        )
    }

    const StockPortfolioAnalysis = () => {
        const tickerList = holdings?.map(h => {
            return {
                symbol: h.symbol,
                full_name: h.proper_name,
                weight: h.weights
            }
        })

        const KeyTalkingPoints = () => {

            const getViews = React.useCallback(() => {
                let keys = ['diversification', 'perfromance'];
                let temp = keys?.reduce((a, c) => {
                    let j = insights?.[c]?.data || [];
                    if (j.length > 0) {
                        return [...a, ...j]
                    }
                    return a;
                }, []);
                return temp;
            }, [insights]);

            const views = getViews();
            console.log(views)
            // const tabItems = [
            //     {
            //         key: 1,
            //         value: 1,
            //         label: "Views",
            //         children: <DisplayViews x={{ data: views }} />
            //     },
            //     {
            //         key: 2,
            //         value: 2,
            //         label: "Red flags",
            //         children: <DisplayEquityInsights x={insights?.red_flag} />
            //     }
            // ]

            // const [selectedTabItem, setSelectedTabItem] = React.useState(1);

            return (
                <div style={{}}>
                    {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }} className="margin20Bottom">
                        <CustomSegmentedTab options={tabItems} value={selectedTabItem} onChange={(e) => setSelectedTabItem(e)} isBlock={false} keepSameInMobile />
                    </div>
                    <div>
                        {tabItems?.find((ele) => ele?.value === selectedTabItem)?.children}
                    </div> */}
                    <DisplayViews x={{ data: views }} />
                </div>
            )
        }

        const tabData = [
            {
                key: "StyleAnalysis",
                heading: "Style analysis",
                value: "StyleAnalysis",
                show: true,
                children: <DisplayEquityInsights x={insights?.factor_scores} />,
                subHeading: "A look at aggregated portfolio scores on value, momentum and quality parameters. Scores are calculated as weighted average."
            },
            {
                key: "Key talking points",
                heading: "Key talking points",
                value: "KeyTalkingPoints",
                show: true,
                children: <KeyTalkingPoints />,
                subHeading: "Some important talking points about this portfolio’s performance, diversification, and fundamentals."
            },
            {
                key: "IndividualStockAnalysis",
                heading: "Individual stock analysis",
                value: "IndividualStockAnalysis",
                show: showAnalysis,
                children: <DisplayEquityInsights x={{ section_name: 'Individual stock analysis', type: 10, data: tickerList }} />,
                subHeading: "Get a quick bird’s eye snapshot on individual stocks in this basket"
            },
            ...otherTabs
        ]
        return (
            <div>
                <StickyScrollTabs isAccordionInMob queriesToStickToBot={['#sharpelyHeader', '#customTabsBarId']} tabData={tabData} />
            </div>
        )
    }

    return (
        <React.Fragment>
            {analysisType === 'fund' ? <FundAnalysis /> : analysisType === 'fundPortfolio' ? <AccessComponent component={<FundPortfolioAnalysis />} upgradeComponent={
                <div style={{ minHeight: '100%', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
                    <UpgradeToPro accessObj={accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === "BASKET_ANALYTICS")} height="20rem" width="90%" />
                </div>}
                reqAccessLevel={accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === "BASKET_ANALYTICS")?.AccessLevel} /> :
                analysisType === 'stockPortfolio' ? <AccessComponent component={<StockPortfolioAnalysis />} upgradeComponent={
                    <div style={{ minHeight: '100%', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
                        <UpgradeToPro accessObj={accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === "BASKET_ANALYTICS")} height="20rem" width="90%" />
                    </div>
                }
                    reqAccessLevel={accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === "BASKET_ANALYTICS")?.AccessLevel} /> : null}
        </React.Fragment>
    )
}

export default React.memo(Analysis);