import AccessControlledPage from 'Components/AccessControlledPage';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import CustomSegmentedTab from 'Components/CustomSegmentedTab';
import { formatDateMMMDDYYYY } from "Components/DateFormatter";
import { NoDataFound } from 'Components/NoDataFound';
import { CardHeader } from 'Components/atoms/SmallComponents';
import { getUserScreeners, makeid, useGetAssetType } from 'Libs/utils';
import apiHelper from 'Services/api/ApiHelper';
import apiHelperPY from 'Services/api/ApiHelperPY';
import { Avatar, Badge, Card, Divider } from 'antd';
import React from 'react';
import { BiPlus } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
var { Meta } = Card
function AllScreeners() {
    const stockType = useGetAssetType(4);
    const etfType = useGetAssetType(1);
    const mfType = useGetAssetType(2);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accessLevelData = useSelector((state) => state?.accessLevel?.value);
    let user = useSelector((state) => state?.updateUserData?.value);
    const isMobile = window.innerWidth <= 760;
    const [screens, setScreens] = React.useState([])
    const [allPublishedScreens, setallPublishedScreens] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [searchParams, setSearchParams] = useSearchParams();
    const [allPublishers, setAllPublishers] = React.useState([]);
    const loadData = async () => {
        if (user?.isLogged) {
            let scr2 = await getUserScreeners(user?.id, etfType.strVal);
            let scr3 = await getUserScreeners(user?.id, mfType.strVal);
            scr2 = scr2.map((item) => { return { ...item, type: 1, instrument_type: 'ETF' } })?.sort((a, b) => new Date(b?.created_on) - new Date(a?.created_on));
            scr3 = scr3.map((item) => { return { ...item, type: 2, instrument_type: 'MF' } })?.sort((a, b) => new Date(b?.created_on) - new Date(a?.created_on));

            let res = await apiHelper({ apiName: "getAllSuperScreens", saveResponse: "sessionStorage", data: user?.id, getFresh: true });
            setScreens([
                ...res?.filter((i) => i?.is_deleted === 0)?.map((i) => {
                    return {
                        ...i,
                        isSuper: true,
                        type: 3
                    }
                })?.sort((a, b) => new Date(b?.created_on) - new Date(a?.created_on)),
                ...scr2, ...scr3,
            ])

        }
        let allPublishedScreens = await apiHelperPY({ apiName: "getAllPublishedScreens" });
        setallPublishedScreens(JSON.parse(allPublishedScreens)?.filter((i) => i?.client_id !== user?.id)?.sort((a, b) => new Date(b?.created_on) - new Date(a?.created_on)))
        setLoading(false)
    }

    const getPublisherProfiles = async () => {
        let allPublishers = await apiHelperPY({ apiName: "getAllPublisherProfile", saveResponse: "sessionStorage" });
        allPublishers = JSON.parse(allPublishers);
        setAllPublishers(allPublishers);
    }

    React.useEffect(() => {
        getPublisherProfiles();
    }, [])

    React.useEffect(() => {
        loadData()
    }, [user])

    React.useEffect(() => {

        setTimeout(() => {
            const hash = window.location.hash;
            if (hash) {
                const element = document.querySelector(String(hash));
                if (element) {
                    element.scrollTo({ top: '14rem', behavior: 'smooth' });
                }
                navigate(window.location.pathname)
            }

        }, 300);
    }, [window.location.hash])

    var instrumentTabs = [
        { "key": "4", "label": "Stocks screens", "dname": "Stocks", basePath: "/stocks", value: 3, strVal: 'stock', omkar: 'Stock' },
        { "key": "1", "label": "ETF screens", "dname": "ETFs", basePath: "/etfs", value: 1, strVal: 'etf', omkar: 'ETF' },
        { "key": "2", "label": "MF screens", "dname": "Mutual Funds", basePath: "/mutual-funds", value: 2, strVal: 'mf', omkar: 'Mutual Fund' },
    ]

    let defInsTab = searchParams?.get('myScreenType') ? (instrumentTabs?.find((el) => el?.label?.replace(' ', '-')?.toLowerCase() === searchParams?.get('myScreenType'))?.value || 3) : 3
    const [selectedInstrumentTab, setSelectedInstrumentTab] = React.useState(defInsTab);

    const CreateNewButton = () => {
        return <button className="btnBlack d-flex align-items-center" style={{ gap: '0.5rem' }} onClick={() => {
            var selectedType = instrumentTabs?.filter((a) => a?.value === selectedInstrumentTab)?.[0]
            if (selectedInstrumentTab === 3)
                navigate(`/stocks/super-screener`)
            else
                navigate(`${selectedType.basePath}/screener`)
        }}>
            <BiPlus /> {isMobile ? '' : 'Create New'}
        </button>
    }

    return (
        <React.Fragment>
            <AccessControlledPage reqAccessLevel={1} />
            <div className="normalContainer">
                <Card>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'start' }}>
                            <div style={{ flex: 3 }}>
                                <CardHeader heading={`My screens`} headingType={2} />
                                <div className='textXS dark3' style={{ marginTop: '0.25rem' }}>Track, Invest or Paper trade in strategies created by you or create a new personal strategy.</div>
                            </div>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                <CreateNewButton />
                            </div>
                        </div>
                        <CustomSegmentedTab isBlock={false} options={instrumentTabs} value={selectedInstrumentTab} onChange={(e) => {
                            let tf = instrumentTabs?.find((el) => el?.value === e)?.label?.toLowerCase()?.replace(' ', '-');
                            setSearchParams({ myScreenType: tf })
                            setSelectedInstrumentTab(e)
                        }} />
                        <div>

                            {
                                loading ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <ActivityLoaderMintbox />
                                </div> : <>{
                                    screens?.filter((i) => (i?.type === selectedInstrumentTab))?.length === 0 ?
                                        <div>
                                            <NoDataFound msgMaxWidth='25rem' width='5rem' gap="1.5rem" bordered padding='3rem 1.25rem'
                                                ctaText={<span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}><BiPlus /> Create a new {instrumentTabs?.filter((a) => a?.value === selectedInstrumentTab)?.[0]?.omkar} screen</span>}
                                                ctaAction={() => {
                                                    var selectedType = instrumentTabs?.filter((a) => a?.value === selectedInstrumentTab)?.[0]
                                                    if (selectedInstrumentTab === 3)
                                                        navigate(`/stocks/super-screener`)
                                                    else
                                                        navigate(`${selectedType.basePath}/screener`)
                                                }}
                                                message='Looks like you haven’t created any screen. Start creating your custom screen using a step-by-step screen builder or an advanced query builder.' />
                                        </div>
                                        :
                                        <>
                                            <div style={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr", gap: "1rem", marginBottom: '1rem' }}>
                                                {screens?.filter((i) => (i?.type === selectedInstrumentTab))?.map((item) => {
                                                    return <Badge.Ribbon key={makeid(3)} text={(item?.type === 3 ? 'Stock' : item?.type === 1 ? 'ETF' : 'MF')} color="var(--dark4)">
                                                        <Card
                                                            className='shadowXS'
                                                            key={makeid(3)}
                                                            bordered
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                var selectedType = instrumentTabs?.filter((a) => a?.value === selectedInstrumentTab)?.[0]
                                                                if (item?.isSuper)
                                                                    navigate(`/stocks/super-screener?screenName=${item?.screen_name?.replaceAll(' ', '-')}&screenId=${item?.screen_id}&userId=${item?.client_id || 0}`)
                                                                else
                                                                    navigate(`${selectedType.basePath}/screener?screenId=${item?.checklist_id}&screenType=3&userId=${item?.client_id || 0}`)

                                                            }}
                                                        >
                                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                <Meta
                                                                    style={{ flex: 1 }}
                                                                    title={<span className='textEllipsis1'>{item?.screen_name || item?.checklist_name}</span>}
                                                                    description={<span className='textXS textLight'>Created on {formatDateMMMDDYYYY(item?.created_on)}</span>}
                                                                />
                                                                {item?.isSuper && <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                    <Badge className='textXS' status="success" text="Super screen" />
                                                                </div>}
                                                            </div>
                                                        </Card>
                                                    </Badge.Ribbon>
                                                })}
                                            </div>
                                        </>
                                }
                                </>
                            }
                        </div>
                    </div>

                    <Divider id='community-screens' />

                    <div >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ flex: 3 }}>
                                <CardHeader heading={'Screens created by sharpely community'} headingType={2} />
                                <div className='textXS dark3' style={{ marginTop: '0.25rem' }}>Explore screens created by our community experts including SEBI registered entities. If you like a screen, you can clone it and save as your own screen.</div>
                            </div>
                            {!isMobile && <div style={{ flex: 1 }}></div>}
                        </div>
                        <br></br>
                        {
                            loading ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ActivityLoaderMintbox />
                            </div> : allPublishedScreens?.length === 0 ?
                                <div>
                                    <NoDataFound msgMaxWidth='25rem' width='5rem' gap="1.5rem" bordered padding='6rem 1.25rem'
                                        message='Oops no screens available right now!!' />
                                </div>
                                :
                                <div className='' style={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr", gap: "1rem" }}>
                                    {allPublishedScreens?.map((item) => {
                                        var publisherD = allPublishers?.filter((i) => i?.ClientId === item?.client_id)?.[0]
                                        // console.log(item)
                                        if (publisherD?.IsApproved === 1)
                                            return <Badge.Ribbon key={makeid(3)} text={String(item?.instrument_type)?.replace(String(item?.instrument_type)?.charAt(0), String(item?.instrument_type)?.charAt(0)?.toLocaleUpperCase())} color="var(--dark4)">
                                                <Card
                                                    className='shadowXS'
                                                    key={makeid(3)}
                                                    bordered
                                                    style={{ flex: 1, height: "100%", cursor: 'pointer' }}
                                                    onClick={() => {
                                                        navigate(`/stocks/super-screener?screenName=${item?.screen_name?.replaceAll(' ', '-')}&screenId=${item?.screen_id}&userId=${item?.client_id || 0}`)
                                                    }}
                                                // title={item?.StrategyName}
                                                >
                                                    <div className='mb-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "0.5rem" }}>
                                                        <Meta
                                                            style={{ flex: 1, gap: 0 }}
                                                            avatar={<Avatar src={publisherD?.PublisherIcon} />}
                                                            title={<span className='textEllipsis1'>{item?.screen_name || item?.checklist_name}</span>}
                                                            description={<div className='textXS textLight'>By {<Link style={{
                                                                color: "var(--primary)", cursor: "pointer",
                                                            }} to={`/publisher-profile?name=${publisherD?.Name?.replaceAll(" ", "-")}&id=${publisherD?.UID}`} onClick={(e) => { e.stopPropagation() }}>{String(publisherD?.Name)?.substring(0, 20)}{publisherD?.Name?.length > 15 ? "..." : ""}</Link>} on {formatDateMMMDDYYYY(item?.created_on)}</div>}
                                                        />
                                                        {item?.isSuper && <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                            <Badge className='textXS' status="success" text="Super screen" />
                                                        </div>}
                                                    </div>

                                                    <div className='textXS textEllipsis3 dark3 w-400' dangerouslySetInnerHTML={{ __html: `${item?.screen_desc?.replaceAll("{\"\n\"}", "")}` }}>
                                                    </div>
                                                </Card>
                                            </Badge.Ribbon>
                                        else
                                            return null
                                    })}
                                </div>
                        }
                    </div>
                </Card>
            </div>
        </React.Fragment >
    )
}

export default AllScreeners;