import { useSelector } from "react-redux";
import React from "react";

const DashboardChartNotes = ({ type, chartType, fundData }) => {
    // chartType = 1 // perf of indtrument
    // chartType = 2 // price vs nav of indtrument
    // chartType = 3 // technical of indtrument
    var stockprices = useSelector((state) => state?.stockprices?.value)
    var prevstockprices = useSelector((state) => state?.prevStockPrices?.value)

    var isLive = true
    var currentSymbolAllData = stockprices === "close" ? prevstockprices?.[fundData?.gdf_symbol] : stockprices?.[fundData?.gdf_symbol] || prevstockprices?.[fundData?.gdf_symbol]
    if (currentSymbolAllData == undefined) {
        isLive = false
    }

    return (
        <React.Fragment>
            <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ flex: 1 }}>
                    {chartType === 3 && // price volume chart
                        <>
                            {type?.value === 1 ?
                                isLive ? <div className='textXS' style={{ paddingBottom: "1rem", whiteSpace: 'pre-wrap' }}>
                                    <b>Note: </b>Prices may be delayed by a few minutes. For historical calculations, prices are adjusted for splits. Percentage change shown is absolute irrespective of the period.
                                </div>
                                    :
                                    <div className='textXS' style={{ paddingBottom: "1rem", whiteSpace: 'pre-wrap' }}>
                                        <b>Note: </b>Live price is not available for this ETF. Showing closing price. For historical calculations, prices are adjusted for splits. Percentage change shown is absolute irrespective of the period.
                                    </div>
                                :
                                type.value === 4 || type.value === 5 ?
                                    isLive ? <div className='textXS' style={{ paddingBottom: "1rem", whiteSpace: 'pre-wrap' }}>
                                        <b>Note: </b>Prices may be delayed by a few minutes. For historical calculations, prices are adjusted for splits, bonus and rights issue. Percentage change shown is absolute irrespective of the period.
                                    </div>
                                        :
                                        <div className='textXS' style={{ paddingBottom: "1rem", whiteSpace: 'pre-wrap' }}>
                                            <b>Note: </b>Live price is not available for this {type.value === 5 ? 'index' : 'stock'}. Showing closing price. For historical calculations, prices are adjusted for splits, bonus and rights issue. Percentage change shown is absolute irrespective of the period.
                                        </div> : <></>}
                        </>
                    }
                    {chartType === 2 && type?.value === 1 && // price vs NAV
                        <>
                            {<div className='textXS' style={{ paddingBottom: "1rem", whiteSpace: 'pre-wrap' }}>
                                <b>Note: </b>The chart helps in identifying any significant deviation between closing price and closing NAV. Prices are closing price on exchange. Both NAVs and prices are not adjusted for any splits.
                            </div>}
                        </>
                    }
                    {chartType === 1 && // performance chart
                        <>
                            {type?.value === 1 ? <div className='textXS' style={{ paddingBottom: "1rem", whiteSpace: 'pre-wrap' }}>
                                <b>Note: </b>Performance is calculated using closing NAVs and adjusted for dividends (if any). NAVs are also adjusted for splits. Absolute returns are shown for period less than 1 year and annualized returns are shown for periods more than 1 year.
                            </div> : type?.value === 2 ? <div className='textXS' style={{ paddingBottom: "1rem", whiteSpace: 'pre-wrap' }}>
                                <b>Note: </b>Performance is calculated using closing NAVs. NAVs are adjusted for splits. Absolute returns are shown for period less than 1 year and annualized returns are shown for periods more than 1 year.
                            </div> : type.value === 4 ? <div className='textXS' style={{ paddingBottom: "1rem", whiteSpace: 'pre-wrap' }}>
                                <b>Note: </b>Performance is calculated assuming reinvestment of dividends (if any). Prices are adjusted for splits, bonus and rights issue. Absolute returns are shown for period less than 1 year and annualized returns are shown for periods more than 1 year.
                            </div> : type.value === 5 ? <div className='textXS' style={{ paddingBottom: "1rem", whiteSpace: 'pre-wrap' }}>
                                <b>Note: </b>Performance is calculated assuming reinvestment of dividends (if any). Prices are adjusted for splits, bonus and rights issue. Absolute returns are shown irrespective of the period.
                            </div> : <></>}
                        </>
                    }
                </span>
            </div>
        </React.Fragment>
    )
}

export default DashboardChartNotes;