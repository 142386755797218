import AccessControlledPage from "Components/AccessControlledPage";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import { ScreenQueryPanel } from 'Components/Screener/ScreenQueryPanel';
import { fetchFundData } from "Libs/fetchFundData";
import { getIndexList, screenerMetricData, useGetAssetType } from "Libs/utils";
import AdminServicesPY from "Services/api/AdminServicesPY";
import { Skeleton } from "antd";
import React from "react";
import { Navigate, useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";

export default function Screener(props) {

    var { strategy_fundD, typeD, updateStrategyParams = () => { }, triggerBacktest = false, readOnly = false, isLanding = false } = props
    const { state } = useLocation()
    const [searchParams] = useSearchParams();

    const [functions, setFunctions] = React.useState([]);
    const [functionMap, setFunctionMap] = React.useState([]);
    const [loader, setLoader] = React.useState(true);
    const [isAdvance, setisAdvance] = React.useState(strategy_fundD !== undefined ? (strategy_fundD?.screen_level === 0 ? false : true) : searchParams.get('isAdvance') === 'true' ? true : false);
    const [screenerMetrics, setScreenerMetrcis] = React.useState([]);
    const [allFundsData, setallFundsData] = React.useState([])
    const [indices, setIndices] = React.useState([]);
    const [benchmarks, setBenchmarks] = React.useState([]);
    const [screenerFinalData, setscreenerFinalData] = React.useState([])
    const [allFuncAndMetricForDrawer, setallFuncAndMetricForDrawer] = React.useState({})

    var type = useGetAssetType(typeD?.value)

    function groupBy(arr, property) {
        return arr.reduce(function (memo, x) {
            if (!memo[x[property]]) { memo[x[property]] = []; }
            memo[x[property]].push(x);
            return memo;
        }, {});
    }

    // const hiddenMetrics = ['is_nifty_50', 'is_nifty_100', 'is_nifty_500', 'is_nifty_midcap100', 'is_nifty_smallcap100'];
    const hiddenMetrics = [];
    React.useEffect(() => {
        const getData = async () => {
            try {
                let screenerDatas = await screenerMetricData(type, 0, 1, 1, 1)
                setallFundsData(screenerDatas?.allFunds)
                let mets = screenerDatas?.screenerMetricData;
                mets = mets?.map((el) => {
                    var selectedCategory = screenerDatas?.screenerFinalData?.filter((i) => i?.category_id === el?.category_id)?.[0] || {}
                    var selectedSubCategory = selectedCategory?.sub_category?.filter((i) => i?.sub_category_id === el?.sub_category_id)?.[0] || {}
                    return {
                        ...el,
                        type: el?.type || "Metric",
                        // label: el?.label?.replace(/\s/g, ''),
                        category_name: selectedCategory?.category_name,
                        sub_category_name: selectedSubCategory?.sub_category_name,
                    }
                });
                mets = mets?.filter((el) => !hiddenMetrics.includes(el?.dataIndex))

                setScreenerMetrcis(mets);
                setscreenerFinalData(screenerDatas?.screenerFinalData)
                let func_map = await AdminServicesPY.loadFunctionMap().then((r) => r)
                func_map = JSON.parse(func_map?.data?.replace(/NaN/g, null)?.replace(/Infinity/g, null))
                func_map = func_map?.filter((el) => !['mavp', "groupby"]?.includes(el?.sh_name))
                func_map = func_map?.map((i) => {
                    return {
                        ...i,
                        category_name: i?.type,
                        sub_category_name: i?.sub_type,
                    }
                })
                console.log("HEYEYE", func_map)

                let indices = await getIndexList();
                indices = indices?.filter((el) => el?.is_in_screener === 1)
                let benchmarks = await fetchFundData(11);
                setBenchmarks(benchmarks);
                setIndices([{
                    IndexNameCaps: "all",
                    IndexName: "All",
                    key: "all"
                }].concat(indices));
                setFunctions(func_map)
                setFunctionMap(groupBy(func_map, "type"));
                setLoader(false)

                var ak2 = groupBy(func_map, "type")
                ak2 = Object?.entries(ak2)?.map(([key, val], index) => {
                    return {
                        [key]: Object?.entries(groupBy(val, "sub_type"))?.map(([key, val], index) => {
                            return {
                                category_id: index + 1,
                                category_name: key,
                                sub_category: [{
                                    sub_category_id: index + 1,
                                    sub_category_name: '',
                                    metric: val?.map((item) => {
                                        return { ...item, key: item?.sh_name, label: item?.display_name, value: item?.sh_name, type: item?.type }
                                    })
                                }]
                            }
                        })
                    }
                })

                console.log("akkkka", ak2)

                var final = {
                    "Metrics": screenerDatas?.screenerFinalData,
                    ...Object.assign({}, ...ak2)
                }

                console.log("akkkka", final)
                setallFuncAndMetricForDrawer(final)

            }
            catch (err) {
                console.log(err);
            }
        }
        getData()
    }, [])

    console.log("ASD", screenerFinalData)
    if (searchParams.get('fromStrategyCreation') === 'true') {
        return <Navigate to={'/stocks/super-screener'} state={{ fromStrategyCreation: true }} />
    }
    if (loader && !isLanding) {
        return (
            <div className="loaderContainer">
                <ActivityLoaderMintbox showQuote />
            </div>
        )
    }
    return (
        <React.Fragment>
            {!isLanding && <AccessControlledPage moduleKey="BASIC_SCREENING" />}
            <div className={`normalContainer`} >
                <ScreenQueryPanel
                    isLanding={isLanding}
                    triggerBacktest={triggerBacktest}
                    strategy_fundD={strategy_fundD}
                    updateStrategyParams={updateStrategyParams}
                    is_Advance={isAdvance}
                    allFuncAndMetricForDrawer={allFuncAndMetricForDrawer}
                    // user={user}
                    type={type}
                    allFundsData={allFundsData}
                    clonedData={state}
                    functionMap={functionMap}
                    functions={functions?.map((item) => {
                        return { ...item, key: item?.sh_name, label: item?.display_name, value: item?.sh_name, type: item?.type }
                    })}
                    screenerMetrics={screenerMetrics}
                    indices={indices}
                    benchmarks={benchmarks}
                    readOnly={readOnly}
                />
            </div>
        </React.Fragment>
    )
} 