import React from 'react';
import { Modal } from 'antd';
import { microphone } from 'Assets/images';
import { flaticonSvg } from 'Assets/Constant.js';
import { TbChartCandleFilled } from 'react-icons/tb';

export const CreateAlert = React.memo(({ type }) => {
  const [alertModal, setAlertModal] = React.useState(false);
  return (
    <>
      <button className="btnWhite " onClick={(e) => { setAlertModal(true); }} style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
        <img loading="lazy"  alt="" style={{ height: "14px" }} src={`${flaticonSvg}fi-rr-bell-ring.svg`} />
        Create alert
      </button>
      <Modal open={alertModal} footer={<button onClick={() => setAlertModal(false)} className="btnBlack ">Close</button>}
        onCancel={() => { setAlertModal(false); }} onOk={() => { setAlertModal(false); }}>
        <div className="d-flex flex-column align-items-center text-center">
          <img loading="lazy"  alt="microphone" src={microphone} style={{ width: "10rem", marginTop: "1rem" }} />
          <div className="textSM w-500 black " style={{ marginTop: "1rem" }}>
            We will soon be adding support for setting {type?.strVal} alerts.
          </div>
        </div>
      </Modal>
    </>
  );
});



export const CustmBtnModal = React.memo(({ btnName, content }) => {
  const [alertModal, setAlertModal] = React.useState(false);
  return (
    <>
      <button className="btnWhite " onClick={(e) => { setAlertModal(true); }} style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
        <TbChartCandleFilled />
        {btnName}
      </button>
      <Modal open={alertModal} footer={<button onClick={() => setAlertModal(false)} className="btnBlack ">Close</button>}
        onCancel={() => { setAlertModal(false); }} onOk={() => { setAlertModal(false); }}>
        <div className="d-flex flex-column align-items-center text-center">
          <img loading="lazy"  alt="microphone" src={microphone} style={{ width: "10rem", marginTop: "1rem" }} />
          <div className="textSM w-500 black " style={{ marginTop: "1rem" }}>
            {content}
          </div>
        </div>
      </Modal>
    </>
  );
});