import 'quill/dist/quill.snow.css';
import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const DescriptionInput = React.memo(({ label = '', labelClassName = '', labelStyle = {}, value, onChange = () => { }, placeholder = '', size = 'large',
    errorLabel = '* Special characters are not allowed', errorLabelStyle = {}, errorLabelClassName = '', changeValueOnChange = false }) => {

    // const { quill, quillRef, Quill } = useQuill({
    //     modules: { blotFormatter: {} }
    // });

    // if (Quill && !quill) {
    //     Quill.register('modules/blotFormatter', BlotFormatter);
    // }

    const [desc, setDesc] = React.useState(value);
    const inputRef = React.useRef();

    const sanitizeInput = (value) => {
        let t = String(value)
        t = t?.replaceAll(/(?<!<)\/+/g, '');
        t = t?.replaceAll(/[\'\"?&]/g, '')
        return t;
    };

    React.useEffect(() => {
        if (value !== desc) {
            setDesc(value);
        }
    }, [value]);

    return (
        <React.Fragment>
            <div className="w100">
                {label && <label className={`textXS dark3 w-500 mb-2 ${labelClassName}`} style={{ ...labelStyle }} >{label}</label>}
                <div className="descriptionEditorContainer">
                    {/* <div ref={quillRef} style={{ height: "5rem", overflow: "scroll", color: "black" }} /> */}
                    <ReactQuill defaultValue={desc} ref={inputRef} onChange={(e) => {
                        let t = sanitizeInput(e);
                        // console.log(e,t);
                        setDesc(t);
                        // if (changeValueOnChange) {
                        onChange(t)
                        // }
                    }} onBlur={(a, s, e) => {
                        let j = e.getHTML();
                        console.log(j);
                        let t = sanitizeInput(j);
                        console.log(t);
                        onChange(t);
                    }} style={{}} />
                </div>
            </div>
        </React.Fragment>
    )
})