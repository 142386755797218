import { Segmented } from 'antd';
import React from 'react';

export default function CustomSegmentedTab({ options = [], isBlock = true, onChange = () => { }, value = '', style = {}, className = '', flexVal = 1, size = 'middle', keepSameInMobile = false, mobileStyles = {} }) {

    // both formats are allowed in response you will get value
    // [{
    //     label: 'Watchlist',
    //     key: 'Watchlist',
    //     value: 'Watchlist'
    //   }]
    //   ['Watchlist', 'Screeners', 'Strategies']

    const onChangeVal = (i) => {
        onChange(i)
    }
    if (options?.length) {
        return window.innerWidth > 760 || keepSameInMobile ?
            <div><Segmented block={isBlock}
                value={value}
                size={size}
                className={'w-500 responsiveTabs ' + className}
                style={{ padding: '0.5rem', lineHeight: "unset", flex: flexVal, alignItems: "center", textAlign: "center", ...style }}
                onChange={(e) => onChangeVal(e)}
                options={
                    options
                }
            /></div> : <Segmented block={true}
                value={value}
                size={size}
                className={'w-500 mobileTabs2 ' + className}
                style={{ lineHeight: "unset", flex: flexVal, alignItems: "center", textAlign: "center", ...mobileStyles }}
                onChange={(e) => onChangeVal(e)}
                options={
                    options
                }
            />
    }
}