import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import { formatDateMMMDDYYYY } from "Components/DateFormatter";
import { NoDataFound } from "Components/NoDataFound";
import { CardHeader } from "Components/atoms/SmallComponents";
import { getKnowledgeBaseData } from "Components/getKnowledgeBaseData";
import Head from "Layout/Head";
import { Card, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Col, Row } from "reactstrap";

const SubCategoryPage = () => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [subCategory, setSubCategory] = useState({});
    const navigate = useNavigate();

    const loadData = async () => {
        let res = await getKnowledgeBaseData();
        if (res.type) {
            setSubCategory(res.subCategories?.filter(item => item?.Category?.toLowerCase().split(' ').join('-') === params?.category?.toLowerCase() && item?.SubCategory.toLowerCase().split(' ').join('-') === params?.subCategory?.toLowerCase())?.[0] || {})
        }
        setLoading(false);
    }

    useEffect(() => {
        loadData();
    }, [params]);

    if (loading) {
        return (
            <div className='loaderContainer' >
                <ActivityLoaderMintbox showQuote={true} />
            </div>
        )
    }
    return (
        <React.Fragment>
            <Head title={subCategory.SubCategory || ""} />
            <div className='normalContainer'>
                <Card style={{ padding: 0 }} bodyStyle={{ padding: 0 }} className='mobCardBorderRadius0'>
                    <div style={{ padding: "1.25rem" }}>
                        <CardHeader heading={subCategory?.SubCategory} />
                    </div>
                    <Divider style={{ margin: 0, background: "var(--grey3" }} />
                    <div style={{ padding: "1.25rem" }}>
                        {subCategory?.Resources?.length > 0 ?
                            <Row lg={3} sm={2} xs={1} className='gx-3 gy-3'>
                                {subCategory?.Resources?.map((item, i) => (
                                    <Col key={item.UID}>
                                        <Card style={{
                                            borderRadius: '0.75rem', background: "var(--grey4)", border: "1px solid var(--grey3)",
                                            padding: 0, height: "100%"
                                        }} bodyStyle={{ padding: "1rem", height: "100%" }}>
                                            <div className="d-flex flex-column black justify-content-between h100" style={{ gap: "1rem" }} >
                                                <div className="d-flex flex-column black" style={{ gap: "0.5rem" }}>
                                                    <div>
                                                        <h2 className="textMD w-500 margin0" style={{ cursor: "pointer" }}>
                                                            {item?.Title}
                                                        </h2>
                                                        <div style={{ color: "var(--dark3)" }} className='w-500 textXS'>
                                                            by <span style={{ color: "var(--black)" }}>{item.Author}</span> {formatDateMMMDDYYYY(item?.UpdatedOn)}
                                                        </div>
                                                    </div>
                                                    <div className="textSM">
                                                        {item.Description}
                                                    </div>
                                                </div>
                                                <button className="btnBlack " style={{ width: "fit-content" }} onClick={() => navigate(item.Slug)}>
                                                    Read
                                                </button>
                                            </div>
                                        </Card>
                                    </Col>
                                ))}
                            </Row> :
                            <NoDataFound message={`There are no resources in this sub-category`} ctaText='See other categories' ctaAction={
                                () => navigate('/knowledge-base')
                            } />}
                    </div>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default SubCategoryPage;