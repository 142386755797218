import React from "react";
import { Modal } from 'antd';

export const ConfirmModal = ({ trigger, title, description, onConfirm = () => { }, triggerClassName = '', triggerStyle = {}, modalStyles = {}, width = '30vw', modalClassName = '', showNote = true, confirmBtnText = "Confirm", showCancel = true }) => {

    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <div style={{ ...triggerStyle }} className={`${triggerClassName}`} onClick={() => {
                setOpen(true)
            }}>
                {trigger}
            </div>
            {open && <Modal open={open} width={width} closable={false} onCancel={() => {
                setOpen(false)
            }} centered footer={null} style={{ ...modalStyles }} className={`${modalClassName}`}>
                <div>
                    <div className="w-700 textLG dark4">{title}</div>
                    <div className="textSM dark4 mt-2">{description}</div>
                    {showNote && <div className="textXS w-500 dark3 mt-2">Note: This action is irreversible.</div>}
                    <div className="d-flex align-items-center justify-content-end" style={{ gap: "1.25rem", marginTop: "1rem" }}>
                        {showCancel && <button className="btnWhite" onClick={() => {
                            setOpen(false)
                        }}>Cancel</button>}
                        <button className="btnBlack" onClick={() => {
                            setOpen(false);
                            onConfirm();
                        }}>{confirmBtnText}</button>
                    </div>
                </div>
            </Modal>}
        </React.Fragment>
    )
}