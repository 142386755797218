import AccessControlledPage from 'Components/AccessControlledPage';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { CurrencyFormat } from 'Components/CurrencyFormat';
import CustomSegmentedTab from 'Components/CustomSegmentedTab';
import { formatDateMMMDDYYYY, monthDiff } from 'Components/DateFormatter';
import { DecimalValueFormat } from 'Components/DecimalValueFormat';
import { NoDataFound } from 'Components/NoDataFound';
import RedGreenText from 'Components/RedGreenText';
import { CardHeader } from 'Components/atoms/SmallComponents';
import { useUserDataHooks } from 'CustomHooks/useUserDataHooks';
import { toggleBasketCreateModal } from 'Libs/redux/Modals/reduxSlice';
import { makeid } from 'Libs/utils';
import AdminServicesPY from 'Services/api/AdminServicesPY';
import apiHelperPY from 'Services/api/ApiHelperPY';
import { Avatar, Badge, Card, Divider } from 'antd';
import React from 'react';
import toast from 'react-hot-toast';
import { BiPlus } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
var { Meta } = Card

function AllStrategies() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accessLevelData = useSelector((state) => state?.accessLevel?.value);
    let user = useSelector((state) => state?.updateUserData?.value);
    const isMobile = window.innerWidth <= 760;
    const [strategies, setStrategies] = React.useState([]);
    const [publishedStrategies, setPublishedStrategies] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [allPublishers, setAllPublishers] = React.useState([])
    const [selectedTab, setSelectedTab] = React.useState('Strategies');
    const { getUserStrategies } = useUserDataHooks();

    const loadData = async () => {
        if (user?.isLogged) {
            let res = await getUserStrategies();
            setStrategies(res?.userStrategies?.sort((a, b) => new Date(b?.config?.go_live_timestamp) - new Date(a?.config?.go_live_timestamp)));
        }
        var res = await AdminServicesPY.getAllStrategiesPublished().then((res) => res)
        if (res?.type) {
            setPublishedStrategies(JSON?.parse(res?.data?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))?.filter((i) => i?.config?.client_id !== user?.id)?.sort((a, b) => new Date(b?.config?.go_live_timestamp) - new Date(a?.config?.go_live_timestamp)))
            setLoading(false)
        } else {
            toast.error("Something went wrong!!")
        }
    }

    const getPublisherProfiles = async () => {
        let allPublishers = await apiHelperPY({ apiName: "getAllPublisherProfile", saveResponse: "sessionStorage" });
        allPublishers = JSON.parse(allPublishers);
        setAllPublishers(allPublishers);
    }

    React.useEffect(() => {
        getPublisherProfiles();
    }, []);

    React.useEffect(() => {
        loadData()
    }, [user])

    var cardDataItems = [{
        "label": "Total P/L",
        "key": "pnl"
    }, {
        "label": "Day change",
        "key": "day_change_per"
    }, {
        "label": "Abs. return",
        "key": "abs_change_per"
    },
    {
        "label": "XIRR",
        "key": "CAGR"
    }
    ]

    const tabItems = [
        {
            key: 1, label: "Paper Trade", value: "Strategies", children: <>
                {strategies?.filter((ele) => ele?.config?.is_live !== 1)?.length > 0 ? <div className='my-3' style={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr", gap: "1rem" }}>
                    {strategies?.filter((ele) => ele?.config?.is_live !== 1)?.map((strategy) => {
                        return (
                            <Badge.Ribbon key={strategy?.config?._id} text={strategy?.config?.instrument_type === 3 ? 'Stock' : strategy?.config?.instrument_type === 1 ? 'ETF' : 'MF'} color="var(--dark4)">
                                <Card
                                    key={strategy?.config?._id}
                                    bordered
                                    className='w100 h100 shadowXS'
                                    bodyStyle={{ width: "100%", height: "100%", cursor: 'pointer' }}
                                    onClick={() =>
                                        navigate(`/strategy-detail/${String(strategy?.config?.strategy_name)?.toLowerCase()?.replaceAll(" ", "-")}/${strategy?.config?.instrument_type === 3 ? 4 : strategy?.config?.instrument_type}/${strategy?.config?._id}`)}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Meta
                                            style={{ flex: 1 }}
                                            // avatar={<img loading="lazy"  src={magicLine} style={{ height: '2rem', width: '2rem', padding: '0.25rem', borderRadius: '50%', backgroundColor: 'var(--blueShade)' }} />}
                                            title={<span className='textEllipsis1'>{strategy?.config?.strategy_name}</span>}
                                            description={<span className='textXS textLight'>Created on {formatDateMMMDDYYYY(strategy?.config?.go_live_timestamp)}</span>}
                                        />
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            {strategy?.config?.is_live === 1 ? <Badge className='textXS' status="success" text="Live" /> : <Badge className='textXS' status="success" text="Paper trade" />}
                                        </div>
                                    </div>

                                    {/* <Divider className='my-3'/> */}
                                    <div className='mt-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        {cardDataItems?.map((item, index) => {
                                            return <div key={makeid(index + 10)}>
                                                <CardHeader
                                                    heading={item?.label}
                                                    textClassName='textXS textLight w-normal'
                                                    style={{
                                                        justifyContent: index === 0 ? "flex-start" : (index === cardDataItems?.length - 1) ? "flex-end" : "center"
                                                    }}
                                                />
                                                <CardHeader
                                                    heading={index === 0 ? CurrencyFormat(strategy?.stats?.[item?.key]) : item?.key === "CAGR" ? (monthDiff(new Date(strategy?.config?.go_live_timestamp), new Date())) <= 1 ? "-" : <RedGreenText
                                                        value={strategy?.stats?.[item?.key]}
                                                        text={DecimalValueFormat({ num: strategy?.stats?.[item?.key], suffix: "%" })}
                                                    /> : <RedGreenText
                                                        value={strategy?.stats?.[item?.key]}
                                                        text={DecimalValueFormat({ num: strategy?.stats?.[item?.key], suffix: "%" })}
                                                    />}
                                                    style={{
                                                        justifyContent: index === 0 ? "flex-start" : (index === cardDataItems?.length - 1) ? "flex-end" : "center"
                                                    }}
                                                    textClassName='textSM w-bold'
                                                />
                                            </div>
                                        })}
                                    </div>
                                </Card>
                            </Badge.Ribbon>
                        )
                    })}
                </div> : <div>
                    <NoDataFound msgMaxWidth='25rem' width='5rem' gap="1.5rem" bordered padding='3rem 1.25rem'
                        ctaText={<span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}><BiPlus /> Create a new strategy</span>}
                        ctaAction={() => dispatch(toggleBasketCreateModal({ open: true }))}
                        message='Create a fully automated or a manual strategy. Your strategy can be as simple as Stock/ETF baskets to a more complex stock strategy based on screens or factor models.' />
                </div>}
            </>
        },
        {
            key: 2, label: "Live Strategies", value: "Live Strategies", children: <>
                {strategies?.filter((ele) => ele?.config?.is_live === 1)?.length > 0 ? <div className='my-3' style={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr", gap: "1rem" }}>
                    {strategies?.filter((ele) => ele?.config?.is_live === 1)?.map((strategy) => {
                        return (
                            <Badge.Ribbon key={strategy?.config?._id} text={strategy?.config?.instrument_type === 3 ? 'Stock' : strategy?.config?.instrument_type === 1 ? 'ETF' : 'MF'} color="var(--dark4)">
                                <Card
                                    key={strategy?.config?._id}
                                    bordered
                                    className='w100 h100 shadowXS'
                                    bodyStyle={{ width: "100%", height: "100%", cursor: 'pointer' }}
                                    onClick={() =>
                                        navigate(`/strategy-detail/${String(strategy?.config?.strategy_name)?.toLowerCase()?.replaceAll(" ", "-")}/${strategy?.config?.instrument_type === 3 ? 4 : strategy?.config?.instrument_type}/${strategy?.config?._id}`)}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Meta
                                            style={{ flex: 1 }}
                                            // avatar={<img loading="lazy"  src={magicLine} style={{ height: '2rem', width: '2rem', padding: '0.25rem', borderRadius: '50%', backgroundColor: 'var(--blueShade)' }} />}
                                            title={<span className='textEllipsis1'>{strategy?.config?.strategy_name}</span>}
                                            description={<span className='textXS textLight'>Created on {formatDateMMMDDYYYY(strategy?.config?.go_live_timestamp)}</span>}
                                        />
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            {strategy?.config?.is_live === 1 ? <Badge className='textXS' status="success" text="Live" /> : <Badge className='textXS' status="success" text="Paper trade" />}
                                        </div>
                                    </div>

                                    {/* <Divider className='my-3'/> */}
                                    <div className='mt-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        {cardDataItems?.map((item, index) => {
                                            return <div key={makeid(index + 10)}>
                                                <CardHeader
                                                    heading={item?.label}
                                                    textClassName='textXS textLight w-normal'
                                                    style={{
                                                        justifyContent: index === 0 ? "flex-start" : (index === cardDataItems?.length - 1) ? "flex-end" : "center"
                                                    }}
                                                />
                                                <CardHeader
                                                    heading={index === 0 ? CurrencyFormat(strategy?.stats?.[item?.key]) : item?.key === "CAGR" ? (monthDiff(new Date(strategy?.config?.go_live_timestamp), new Date())) < 1 ? "-" : <RedGreenText
                                                        value={strategy?.stats?.["xirr"]}
                                                        text={DecimalValueFormat({ num: strategy?.stats?.["xirr"], suffix: "%" })}
                                                    /> : <RedGreenText
                                                        value={strategy?.stats?.[item?.key]}
                                                        text={DecimalValueFormat({ num: strategy?.stats?.[item?.key], suffix: "%" })}
                                                    />}
                                                    style={{
                                                        justifyContent: index === 0 ? "flex-start" : (index === cardDataItems?.length - 1) ? "flex-end" : "center"
                                                    }}
                                                    textClassName='textSM w-bold'
                                                />
                                            </div>
                                        })}
                                    </div>
                                </Card>
                            </Badge.Ribbon>
                        )
                    })}
                </div> : <div>
                    <NoDataFound msgMaxWidth='25rem' width='5rem' gap="1.5rem" bordered padding='3rem 1.25rem'
                        ctaText={<span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}><BiPlus /> Create a new strategy</span>}
                        ctaAction={() => dispatch(toggleBasketCreateModal({ open: true }))}
                        message='You have not live traded any strategy yet. Live trade your first strategy by creating a new strategy or live trade your other existing strategies.' />
                </div>}
            </>
        }
    ]

    return (
        <React.Fragment>
            <AccessControlledPage reqAccessLevel={1} />
            <div className="normalContainer">
                <Card>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'start' }}>
                            <div style={{ flex: 3 }}>
                                <CardHeader heading={'My strategies'} headingType={2} />
                                <div className='textXS dark3' style={{ marginTop: '0.25rem' }}>Track, Invest or Paper trade in strategies created by you or create a new personal strategy.</div>
                            </div>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                <button className="btnBlack d-flex align-items-center" style={{ gap: '0.5rem' }} onClick={() => {
                                    dispatch(toggleBasketCreateModal({ open: true }))
                                }}>
                                    <BiPlus /> {isMobile ? '' : 'Create New'}
                                </button>
                            </div>
                        </div>
                        <CustomSegmentedTab isBlock={false} options={tabItems} value={selectedTab} onChange={(e) => { setSelectedTab(e); }} />
                        {
                            loading ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ActivityLoaderMintbox />
                            </div> : <>
                                {tabItems?.find((ele) => ele?.value === selectedTab)?.children}
                            </>
                        }
                    </div>

                    <Divider />

                    <div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ flex: 3 }}>
                                <CardHeader heading={'Strategies curated by sharpely community'} headingType={2} />
                                <div className='textXS dark3' style={{ marginTop: '0.25rem' }}>Explore strategies created by market experts including SEBI registered entities. Click on creator's name to view full details.
                                    If you like a strategy, you can clone it to start paper/live trading.</div>
                            </div>
                            {!isMobile && <div style={{ flex: 1 }}></div>}
                        </div>
                        <br></br>
                        {loading ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <ActivityLoaderMintbox />
                        </div> : publishedStrategies?.length === 0 ? <div>
                            <NoDataFound msgMaxWidth='25rem' width='5rem' gap="1.5rem" bordered padding='6rem 1.25rem'
                                message='Oops no strategies available right now!!' />
                        </div> : <div className='my-3' style={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr", gap: "1rem" }}>
                            {publishedStrategies?.map((strategy) => {
                                let publisherD = allPublishers?.filter((i) => i?.ClientId === strategy?.config?.client_id)?.[0]
                                if (publisherD?.IsApproved === 1)
                                    return (
                                        <Badge.Ribbon key={strategy?.config?._id} text={strategy?.config?.instrument_type === 3 ? 'Stock' : strategy?.config?.instrument_type === 1 ? 'ETF' : 'MF'} color="var(--dark4)">
                                            <Card
                                                key={strategy?.config?._id}
                                                bordered
                                                style={{ flex: 1, cursor: 'pointer' }}
                                                className='w100 h100'
                                                onClick={() => navigate(`/strategy-detail/${String(strategy?.config?.strategy_name)?.toLowerCase()?.replaceAll(" ", "-")}/${strategy?.config?.instrument_type === 3 ? 4 : strategy?.config?.instrument_type}/${strategy?.config?._id}`)}
                                                // title={strategy?.StrategyName}
                                                bodyStyle={{ width: "100%", height: "100%" }}
                                            >
                                                <div className='mb-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "0.5rem" }}>
                                                    <Meta
                                                        style={{ flex: 1, gap: 0 }}
                                                        avatar={<Avatar src={publisherD?.PublisherIcon} />}
                                                        title={<span className='textEllipsis1'>{strategy?.config?.strategy_name}</span>}
                                                        description={<div className='textXS textLight'>By {<Link style={{
                                                            color: "var(--primary)", cursor: "pointer",
                                                        }} to={`/publisher-profile?name=${publisherD?.Name?.replaceAll(" ", "-")}&id=${publisherD?.UID}`} onClick={(e) => { e.stopPropagation() }}>{String(publisherD?.Name)}</Link>} on {formatDateMMMDDYYYY(strategy?.config?.go_live_timestamp)}
                                                        </div>}
                                                    />
                                                </div>
                                                <div className='textXS textEllipsis2' dangerouslySetInnerHTML={{ __html: `${strategy?.config?.strategy_desc?.replaceAll("{\"\n\"}", "")}` }}>
                                                </div>
                                                <div className='mt-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    {cardDataItems?.map((item, index) => {
                                                        return <div key={makeid(3)}>
                                                            <CardHeader
                                                                heading={item?.label}
                                                                textClassName='textXS textLight w-normal'
                                                                style={{
                                                                    justifyContent: index === 0 ? "flex-start" : (index === cardDataItems?.length - 1) ? "flex-end" : "center"
                                                                }}
                                                            />
                                                            <CardHeader
                                                                heading={index === 0 ? CurrencyFormat(strategy?.stats?.[item?.key]) : item?.key === "CAGR" ? (monthDiff(new Date(strategy?.config?.go_live_timestamp), new Date())) <= 1 ? "-" : <RedGreenText
                                                                    value={strategy?.stats?.[item?.key]}
                                                                    text={DecimalValueFormat({ num: strategy?.stats?.[item?.key], suffix: "%" })}
                                                                /> : <RedGreenText
                                                                    value={strategy?.stats?.[item?.key]}
                                                                    text={DecimalValueFormat({ num: strategy?.stats?.[item?.key], suffix: "%" })}
                                                                />}
                                                                style={{
                                                                    justifyContent: index === 0 ? "flex-start" : (index === cardDataItems?.length - 1) ? "flex-end" : "center"
                                                                }}
                                                                textClassName='textSM w-bold'
                                                            />
                                                        </div>
                                                    })}
                                                </div>
                                            </Card>
                                        </Badge.Ribbon>
                                    )
                                else
                                    return null
                            })}
                        </div>}
                    </div>
                </Card>
            </div>
        </React.Fragment >
    )
}

export default AllStrategies;