import { toggleUserSignedIn, updateCustomColumns, updateUserPlanData, updateWatchlists } from "Libs/redux/UserAuth/reduxSlice";
import { getFormattedWatchlist, getUserPlanData } from "Libs/utils";
import apiHelper from "Services/api/ApiHelper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

function UserDataFetcher() {

    let user = useSelector((state) => state?.updateUserData?.value)
    let watchlist = useSelector((state) => state?.userWatchlists?.value);
    let userPlanData = useSelector((state) => state?.userPlanData?.value);
    const dispatch = useDispatch();

    const getUserWatchlist = async () => {
        if (watchlist === undefined) {
            try {
                let watchlistt = await apiHelper({ apiName: 'getWatchListByClientId', data: { clientId: user.id } });
                if (watchlistt?.length > 0) {
                    let formattedWatchlist = getFormattedWatchlist(watchlistt)
                    // console.log(formattedWatchlist);
                    dispatch(updateWatchlists(formattedWatchlist));
                }
                else {
                    dispatch(updateWatchlists([]))
                }
            }
            catch (err) {
                // dispatch(updateWatchlists([]))
            }
        }
    }

    const loadSubscriptions = async () => {
        try {
            let res = await getUserPlanData({ user: user });
            if (res) {
                dispatch(updateUserPlanData(res?.data))
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const loadUserData = async () => {
        await getUserWatchlist();
        await loadSubscriptions();
    }

    const resetUserData = () => {
        dispatch(toggleUserSignedIn(false));
        dispatch(updateWatchlists(undefined));
        dispatch(updateUserPlanData(undefined));
        dispatch(updateCustomColumns(undefined));
    }

    useEffect(() => {
        if (user?.isLogged === true) {
            loadUserData();
        }
        else if (user === undefined || user?.isLogged === false) {
            resetUserData();
        }
    }, [user]);

    return null;
}

export default UserDataFetcher;