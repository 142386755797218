import { UserOutlined } from '@ant-design/icons';
import { flaticonSvg } from 'Assets/Constant';
import AMCIcon from "Components/AMCIcon";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import { CurrencyFormat } from "Components/CurrencyFormat";
import CustomDrawer from "Components/CustomDrawer";
import { formatDate } from "Components/DateFormatter";
import { DecimalValueFormat } from 'Components/DecimalValueFormat';
import FundDetCards from 'Components/FundDetCards';
import { NoDataFound } from 'Components/NoDataFound';
import { CardHeader } from "Components/atoms/SmallComponents";
import { useIsMobile } from 'CustomHooks/useIsMobile';
import Head from "Layout/Head";
import { fetchFundData } from 'Libs/fetchFundData';
import { arePropsEqual, generateUniqueKey, makeid } from "Libs/utils";
import apiHelperPY from "Services/api/ApiHelperPY";
import { Avatar, Card, Select, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { Col, Row } from "reactstrap";

const FundManager = React.memo(({ fmDetails }) => {

    const ManagerDetails = ({ d, isActive = false }) => {

        const [show, setShow] = React.useState(isActive)

        return <React.Fragment key={d.index}>
            <Card bordered={false} hoverable key={d.index} style={{ marginBottom: '1rem', border: '1px solid var(--grey3)' }} bodyStyle={{ padding: '0.875rem 1.25rem' }}
                onClick={() => {
                    if (!show) setShow(!show)
                }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }} onClick={() => setShow(!show)}>
                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <Avatar size={'2rem'} style={{ backgroundColor: 'var(--blueShade)' }} icon={<UserOutlined style={{ color: 'var(--primary)' }} />} />
                        <div className="textSM w-500 black">{d.name}</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <div className="textXS black">{formatDate(d.since.replace("T00:00:00.000Z", ""))} - Present</div>
                        <div className="textXL black mb-1">{show ? <BiChevronDown /> : <BiChevronRight />}</div>
                    </div>
                </div>
                {
                    show ?
                        <>
                            <div style={{ margin: '1.25rem 0', display: 'flex', flexDirection: 'column', gap: '1rem' }} className="textSM black">
                                <div>
                                    <div className="w-700 dark3" style={{ marginBottom: '0.5rem' }}>Education</div>
                                    <div>{d.education || "-"}</div>
                                </div>
                                <div>
                                    <div className="w-700 dark3" style={{ marginBottom: '0.5rem' }}>Past Experience</div>
                                    <div>{d.experience || "-"}</div>
                                </div>
                                {JSON.parse(d.other_funds).length > 0 ?
                                    <>
                                        <div className="w-700">Funds managed ({JSON.parse(d.other_funds).length})</div>
                                        {JSON.parse(d.other_funds).map((fund, i) => {
                                            return <Card style={{ border: '1px solid var(--grey3)', }} bodyStyle={{ padding: '0.875rem 1.25rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }}
                                                onClick={() => {
                                                    // window.open((fund?.is_etf_fund ? "/etfs" : "/mutual-funds") + '/fund-detail/' +
                                                    //     fund?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + fund.plan_id, '_blank')
                                                }}
                                                key={generateUniqueKey(fund?.basic_name)}>
                                                <div style={{ flex: 1, display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                                    <div className="textSM w-500 black">{fund.basic_name}</div>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                                    <div className="textXS black">{formatDate(fund.date_from.replace("T00:00:00.000Z", ""))} - Present</div>
                                                    <div className="textXL black mb-1"><BiChevronRight /></div>
                                                </div>
                                            </Card>
                                        })}
                                    </>
                                    :
                                    <></>
                                }
                            </div>
                        </>
                        :
                        <></>
                }
            </Card>
        </React.Fragment >
    }

    return Object.values(fmDetails || {})?.map((d, i) => {
        return <ManagerDetails d={d} key={generateUniqueKey(i)} />
    })
}, arePropsEqual)

const NFOCards = React.memo(({ nfoData, loadFundManagerDetails }) => {

    const nfoItemInfoCards = [
        { heading: "Benchmark", value: "benchmark", key: 2 },
        { heading: "Exit load", value: "exit_load", key: 1 },
        { heading: "Fund house", value: "amc", key: 8 },
        { heading: "Open date", value: "issue_open", key: 5, isDate: true },
        { heading: "Close date", value: "issue_close", key: 6, isDate: true },
        { heading: "Risk", value: "risk_level", key: 7, isRisk: true },
        { heading: "Min. Investment", value: "min_initial_investment", key: 3, isCurrency: true },
        { heading: "Min. SIP Investment", value: "min_sip", key: 4, isCurrency: true },
    ]

    return (
        <Row lg={3} md={2} sm={1} xs={1} className="gx-3 gy-3">
            {nfoData?.map((fund, i) => {
                return (
                    <Col key={makeid(i + 1)}>
                        <div className="w100 h100 d-flex flex-column justify-content-between" style={{
                            background: "var(--white)", borderRadius: "var(--borderRadius)",
                            border: "1px solid var(--grey1)", position: "relative", cursor: "pointer", padding: "0rem"
                        }}>
                            <div style={{ flex: 1 }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', padding: "0.75rem" }} className="mt-1">
                                    <AMCIcon amcName={fund?.amc} width="3rem" height="3rem" />
                                    <div>
                                        <div className="textESM w-500 black textEllipsis1">
                                            {fund?.basic_name?.replace(" - Direct Plan", '')}
                                        </div>
                                        <div className='d-flex' style={{ gap: "0.35rem" }}>
                                            <div style={{
                                                background: "var(--primary)", borderRadius: "var(--borderRadius)", padding: "2px 5px", width: "fit-content",
                                                fontSize: "10px"
                                            }} className="w-500 white mt-1">
                                                {fund?.type}
                                            </div>
                                            <div style={{
                                                background: "teal", borderRadius: "var(--borderRadius)", padding: "2px 5px", width: "fit-content",
                                                fontSize: "10px"
                                            }} className="w-500 white mt-1">
                                                {fund?.category_name}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex", flexDirection: "column", gap: "0.75rem", borderTop: "1px solid var(--grey3)"
                                }}>
                                    <FundDetCards type={'grid'} data={nfoItemInfoCards?.map((ele, k) => {
                                        return {
                                            label: ele?.heading,
                                            value: ele?.isCurrency ? CurrencyFormat(fund[ele?.value], 'long', 0) :
                                                ele?.isDate ? moment(fund[ele?.value]).format('DD MMM, YYYY') : fund[ele?.value] || '-',
                                            showIcon: false
                                        }
                                    })} labelClassName='textXS w-500 dark3' valueClassName='textXS w-500 dark4' valueStyles={{ fontSize: "var(--textXS)" }} />
                                </div>
                            </div>
                            <div style={{ marginTop: "0.25rem", gap: "1rem", padding: "0.75rem" }} className='d-flex '>
                                <button className="btnWhite w50 d-flex align-items-cente justify-content-center" onClick={() => {
                                    loadFundManagerDetails(fund)
                                }} style={{ gap: "0.5rem" }}>
                                    <UserOutlined />  Managers
                                </button>
                            </div>
                        </div>
                    </Col>
                )
            })}
        </Row>
    )
}, arePropsEqual)

function NFOTracker() {

    const [nfoData, setNFOData] = React.useState([]);
    const [recentLaunches, setRecentLaunches] = React.useState([]);
    const [recentLaunchesTime, setRecentLaunchesTime] = React.useState(1);
    const [allFunds, setAllFunds] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [nfoFMParams, setNFOFMParams] = React.useState({ show: false, planId: '' });
    const [fmDetails, setFMDetails] = React.useState({});
    const [fmDetailsLoading, setFMDetailsLoading] = React.useState(true);
    const isMobile = useIsMobile();

    const loadData = async () => {
        let etfs = await fetchFundData(1);
        let mfs = await fetchFundData(2);
        let allFunds = [];
        if (etfs?.length > 0) {
            allFunds = [...etfs]
        }
        if (mfs?.length > 0) {
            allFunds = [...allFunds, ...mfs];
        }
        setAllFunds(allFunds);
        let res = await apiHelperPY({ apiName: "getNFODetails" });
        if (res) {
            res = JSON.parse(res);
            res.recent_launches = res?.recent_launches?.map((item) => {
                let ele = allFunds?.find((el) => el?.plan_id === item?.plan_id);
                if (ele) {
                    return {
                        ...ele,
                        ...item
                    }
                }
                return null;
            })?.filter((el) => el)
            setNFOData(res?.nfo);
            setRecentLaunches(res?.recent_launches);
        }
        setLoading(false)
    }

    const loadRecentLaunches = async () => {
        let res = await apiHelperPY({ apiName: "getNFODetails", data: { launched_in: recentLaunchesTime } });
        if (res) {
            res = JSON.parse(res);
            res.recent_launches = res?.recent_launches?.map((item) => {
                let ele = allFunds?.find((el) => el?.plan_id === item?.plan_id);
                if (ele) {
                    return {
                        ...ele,
                        ...item
                    }
                }
                return null;
            })?.filter((el) => el)
            setRecentLaunches(res?.recent_launches);
        }
    }

    const loadFundManagerDetails = async (fund) => {
        setFMDetailsLoading(true);
        setNFOFMParams({ show: true, planId: fund?.plan_id });
        let res = await apiHelperPY({ apiName: "getFMDetails", data: { plan_id: fund?.plan_id } });
        if (res) {
            res = JSON.parse(res);
            setFMDetails(res);
        }
        else {
            setFMDetails({});
        }
        setFMDetailsLoading(false)
    }

    const recentLaunchesTimes = [
        { key: 1, label: "1 month", value: 1 },
        { key: 2, label: "2 month", value: 2 },
        { key: 3, label: "3 month", value: 3 },
    ]

    React.useEffect(() => {
        loadData();
    }, []);

    React.useEffect(() => {
        if (!loading) {
            loadRecentLaunches();
        }
    }, [recentLaunchesTime])

    if (loading) {
        return (
            <React.Fragment>
                <Head
                    title="New Fund Offers (NFO) List, NFO Stock List, New Fund Offering, NFO Mutual Fund, New Fund Listings"
                    description="New Fund Offers (NFO): Get the latest news on NFO Stock List, New Fund Offering, Best NFO Stocks, NFO Mutual Fund, NFO MF, New NFO and New Fund Listings only on sharpely.in"
                    keywords="NFO, NFO India, NFO in India, New NFO, NFO Mutual Fund, Mutual Fund NFO, NFO Fund Listings, New Fund Offering, Mutual Fund Offers."
                />
                <div className="loaderContainer">
                    <ActivityLoaderMintbox showQuote />
                </div>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <Head
                title="New Fund Offers (NFO) List, NFO Stock List, New Fund Offering, NFO Mutual Fund, New Fund Listings"
                description="New Fund Offers (NFO): Get the latest news on NFO Stock List, New Fund Offering, Best NFO Stocks, NFO Mutual Fund, NFO MF, New NFO and New Fund Listings only on sharpely.in"
                keywords="NFO, NFO India, NFO in India, New NFO, NFO Mutual Fund, Mutual Fund NFO, NFO Fund Listings, New Fund Offering, Mutual Fund Offers."
            />
            <div className="normalContainer">
                <div className="mainContainer">
                    <div className="leftContainer">
                        <Card title={<CardHeader heading={'New Fund Offers'} />}>
                            <NFOCards nfoData={nfoData} loadFundManagerDetails={(fund) => {
                                loadFundManagerDetails(fund)
                            }} />
                        </Card>
                    </div>
                    <div className="rightContainer">
                        <Card title={<div className='d-flex align-items-center' style={{ gap: "1rem" }}>
                            <CardHeader heading={'Recent launches'} headingType={2} />
                            <Select value={recentLaunchesTime} options={recentLaunchesTimes} onChange={(e) => { setRecentLaunchesTime(e) }} />
                        </div>} bodyStyle={{
                            padding: 0, paddingBottom: "0.5rem",
                            maxHeight: isMobile ? "" : "100rem", overflow: "auto"
                        }}>
                            {recentLaunches?.map((fund, i) => {
                                return (
                                    <div key={makeid(i + 4)} style={{ padding: "0.75rem 1.25rem", borderBottom: "1px solid var(--grey3)", cursor: "pointer" }}>
                                        <Tooltip title={fund?.basic_name?.replace(" - Direct Plan", '')} arrow={false}>
                                            <div className='d-flex align-items-center' style={{ gap: "0.5rem", }} onClick={() => {
                                                window.open(((fund?.is_etf_fund === 0 ? "/mutual-funds" : "/etfs") + `/fund-detail/${fund?.basic_name?.replace(" - Direct Plan", '')?.replaceAll(' ', '-')}/${fund?.plan_id}`), '_blank')
                                            }}>
                                                <AMCIcon amcName={fund?.amc_full_name} width='2.5rem' height='2.5rem' />
                                                <div>
                                                    <div className='d-flex align-items-center' style={{ gap: "0.75rem" }}>
                                                        <div className="textESM w-500 black textEllipsis1">
                                                            {fund?.basic_name?.replace(" - Direct Plan", '')}
                                                        </div>
                                                        <img loading="lazy" alt="" src={`${flaticonSvg}fi-rr-arrow-up-right-from-square.svg`} style={{ height: "0.75rem" }} />
                                                    </div>
                                                    <div className='d-flex mt-1' style={{ gap: "0.35rem" }}>
                                                        <div style={{
                                                            background: "teal", borderRadius: "var(--borderRadius)", padding: "2px 5px", width: "fit-content",
                                                            fontSize: "10px"
                                                        }} className="w-500 white">
                                                            {fund?.category_name}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tooltip>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "0.5rem", marginTop: "1rem" }}>
                                            <div style={{ textAlign: "left" }}>
                                                <div className='textXS w-500 dark3'>CAGR</div>
                                                <div className='textSM w-700 dark4' style={{ marginTop: "2px" }}>{DecimalValueFormat({ num: fund?.cagr_inception, suffix: "%" })}</div>
                                            </div>
                                            <div style={{ textAlign: "center" }}>
                                                <div className='textXS w-500 dark3'>NAV</div>
                                                <div className='textSM w-700 dark4' style={{ marginTop: "2px" }}>{CurrencyFormat(fund?.latest_nav)}</div>
                                            </div>
                                            <div style={{ textAlign: "right" }}>
                                                <div className='textXS w-500 dark3'>Inception</div>
                                                <div className='textSM w-700 dark4' style={{ marginTop: "2px" }}>{formatDate(new Date(fund?.inception))}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Card>
                    </div>
                </div>
                <CustomDrawer isOpen={nfoFMParams?.show} toggle={() => {
                    setNFOFMParams({ show: false, planId: "" });
                    setFMDetails({});
                }} title={'Fund Managers'} footerStyles={{ borderTop: "1px solid var(--grey3)" }} headerStyles={{ borderBottom: "1px solid var(--grey3)" }}
                    bodyStyles={{ padding: "1.25rem" }}>
                    {fmDetailsLoading ? <div>
                        <ActivityLoaderMintbox message="Hang on! Loading fund manager details..." margin="4rem" />
                    </div> : Object.keys(fmDetails || {})?.length === 0 ? <div style={{ margin: "2rem 0" }}>
                        <NoDataFound />
                    </div> : <FundManager fmDetails={fmDetails} />}
                </CustomDrawer>
            </div>
        </React.Fragment>
    )
}

export default NFOTracker;