import appQr from 'Assets/Images/AppQR.png';
import { useIsMobile } from 'CustomHooks/useIsMobile';
import React from "react";
import { isAndroid, isIOS } from 'react-device-detect';
import { FaApple, FaGooglePlay } from "react-icons/fa";

export const AppDownloadCard = () => {

    const isMobile = useIsMobile();

    const navigateToApp = () => {
        if (isAndroid) {
            window.location.href =
                "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.investments.sharpely";
        } else if (isIOS) {
            window.location.href =
                "https://apps.apple.com/in/app/sharpely/id6455889217";
        } else {
            window.location.href =
                "https://sharpely.in/";
        }
    }


    const handleScroll = () => {
        let appDownloadText = document.querySelector('.lpAppDownloadCardMob2>:nth-child(2)');
        if (window.scrollY > 200) {
            appDownloadText.style.maxWidth = '0px';
            appDownloadText.style.paddingLeft = '0px';
            appDownloadText.style.height = '0px';
            // appDownloadText.style.display = 'none';
        }
        else {
            appDownloadText.style.maxWidth = '300px';
            appDownloadText.style.paddingLeft = '0.5rem';
            appDownloadText.style.height = 'unset';
            // appDownloadText.style.display = 'block';
        }
    }

    React.useEffect(() => {
        if (!isMobile) {
            document.addEventListener('scroll', handleScroll);
        }
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    }, [])
    return (
        <React.Fragment>
            {isMobile ? <div style={{ padding: "1rem", gap: "1rem", position: "fixed", bottom: 0, right: 0, zIndex: 1000 }}>
                <div className="d-flex align-items-center justify-content-center lpAppDownloadCardMob2"
                    style={{ padding: "0.75rem", borderRadius: "8px", width: "fit-content", overflow: "hidden" }} onClick={() => {
                        navigateToApp();
                    }}>
                    {/* <Lottie options={appAnimationOptions} width='2.5rem' height='2.5rem' /> */}
                    <img width='2.5rem' height='2.5rem' style={{
                            height: "2.5rem",
                            width: "2.5rem",
                        }} src={require('Assets/lottie/appDownload.gif')} alt="loading..." />
                    {isIOS ? <FaApple size={'2.5rem'} /> : <FaGooglePlay size={'2.5rem'} />}
                </div>
            </div> : <div style={{
                padding: "2rem", gap: "1rem", position: "fixed", bottom: 0,
                zIndex: 1000, right: 0
            }}>
                <div style={{ position: "relative", cursor: "pointer" }} className="showContentOnHoverRow">
                    <div className="d-flex align-items-center justify-content-center lpAppDownloadCardMob2"
                        style={{ padding: "0.5rem", borderRadius: "8px", width: "fit-content", overflow: "hidden" }}>
                        {/* <Lottie options={appAnimationOptions} width='2.5rem' height='2.5rem' /> */}
                        <img width='2.5rem' height='2.5rem' style={{
                            height: "2.5rem",
                            width: "2.5rem",
                        }} src={require('Assets/lottie/appDownload.gif')} alt="loading..." />
                        <div className="dark4 textMD w-700" style={{
                            paddingLeft: "0.5rem", transition: "all 0.2s linear", maxWidth: "300px", overflow: "hidden",
                            lineHeight: "100%", whiteSpace: "nowrap"
                        }}>
                            Download the app
                        </div>
                    </div>
                    <div style={{
                        position: "absolute", background: "var(--white)", borderRadius: "0.5rem", padding: "0.75rem", bottom: 0, right: 0,
                        width: "max(180px, 100%)"
                    }} className="content">
                        <div className="textSM w-700 dark4 text-center" style={{ marginBottom: "0.75rem", lineHeight: "100%" }}>Scan to download sharpely app</div>
                        <img loading="lazy" src={appQr} width={'100%'} height={'100%'} />
                    </div>
                </div>
            </div>
            }
        </React.Fragment >
    )
}