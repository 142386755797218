function getColumnLetter(index) {
    if (typeof index === 'string') {
        index = parseInt(index, 10);
    }

    if (isNaN(index) || index < 1) {
        return null; // Invalid input
    }

    let columnLetter = '';
    while (index > 0) {
        const remainder = (index - 1) % 26;
        columnLetter = String.fromCharCode(65 + remainder) + columnLetter;
        index = Math.floor((index - 1) / 26);
    }

    return columnLetter;
}

function writeXlsxFile(workbook) {
    let content =
        '<?xml version="1.0"?>' +
        '<?mso-application progid="Excel.Sheet"?>' +
        '<Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet"' +
        ' xmlns:o="urn:schemas-microsoft-com:office:office"' +
        ' xmlns:x="urn:schemas-microsoft-com:office:excel"' +
        ' xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet"' +
        ' xmlns:html="http://www.w3.org/TR/REC-html40">' +
        '<Worksheet ss:Name="Sheet1">' +
        '<Table>';

    workbook.forEach((row) => {
        content += '<Row>';
        row.forEach((cell) => {
            content += `<Cell><Data ss:Type="String">${cell?.v}</Data></Cell>`;
        });
        content += '</Row>';
    });

    content += '</Table></Worksheet></Workbook>';
    return content;
}

function writeCsvFile(workbook) {
    let csvContent = '';
    workbook.forEach((row) => {
        const rowContent = row.map((cell) => {
            let cel = String(cell?.v);
            if (cel?.includes(',')) {
                cel = `"${cel}"`
            }
            cel = cel?.replaceAll('&', 'and');
            return cel;
        }).join(',');
        csvContent += rowContent + '\r\n';
    });
    return csvContent
}

export function writeSync(wb, opts) {

    switch (opts.bookType || 'xlsb') {
        case 'csv': return writeCsvFile(wb);
        case 'xlsx': return writeXlsxFile(wb)
        default: throw new Error("Unrecognized bookType |" + opts.bookType + "|");
    }
}

export const utils = {
    book_new: () => {
        return {
            SheetNames: [],
            Sheets: {}
        }
    },
    aoa_to_sheet: (sheetData = []) => {
        let result = []
        for (let i = 0; i < sheetData?.length; i++) {
            let temp = []
            for (let j = 0; j < sheetData?.[i]?.length; j++) {
                temp.push({
                    v: sheetData?.[i]?.[j],
                    t: 's'
                })
            }
            result.push(temp);
        }
        return result;
    },
    book_append_sheet: (sheetBook, sheet, sheetName) => {
        sheetBook.SheetNames.push(sheetName);
        sheetBook.Sheets[sheetName] = sheet;
    }
}