import { LoadingOutlined } from '@ant-design/icons';
import AccessComponent from 'Components/AccessComponent';
import AccessControlledPage from 'Components/AccessControlledPage';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import CustomSegmentedTab from 'Components/CustomSegmentedTab';
import { formatDateMMMDDYYYY, formatDateWithTime, formatDateyyyymmdd } from 'Components/DateFormatter';
import Disclaimer from 'Components/Disclaimer';
import InstrumentListTable from 'Components/InstrumentListTable';
import PerfBenchChart from 'Components/PerfBenchChart';
import { QueryOptionsDropdownV2 } from 'Components/Screener/QueryOptionsDropdown';
import { ScreenBacktestInpModal } from 'Components/Screener/ScreenBacktestInpModal';
import { codeParserWrapper, groupColumnNames, initBacktestConfig } from 'Components/Screener/ScreenerUtils';
import { PlanName, SubscribeBtnSmall, SubscribeContentWrapper, SubscribeIcon, getUserAccessLimitFromAccessObj, useSubscribeMiddleware } from 'Components/SubscribeModal';
import SuperScreenerBacktestAndPaperTrade from 'Components/SuperScreenerBacktestAndPaperTrade';
import { CloneItemModal } from 'Components/atoms/CloneItemModal';
import { CustomChangingTextLoader } from 'Components/atoms/CustomChangingTextLoader';
import { DescriptionInput } from 'Components/atoms/DescriptionInput';
import LabelInputNew from 'Components/atoms/LabelInputNew';
import { NameInput } from 'Components/atoms/NameInput';
import { CardHeader } from 'Components/atoms/SmallComponents';
import { useUserDataHooks } from 'CustomHooks/useUserDataHooks';
import Head from 'Layout/Head';
import { fetchFundData } from 'Libs/fetchFundData';
import { toggleUserGuideModal } from "Libs/redux/KnowledgeBase/reduxSlice";
import { toggleloginModal } from 'Libs/redux/UserAuth/reduxSlice';
import { displayScoreValue, getIndexList, groupBy, isInvalidTextOrLength, makeid, metricsToHideForCustomization, queries, screenerMetricData, stock_universe, useGetAssetType } from 'Libs/utils';
import BuildCustomPortfolio from 'Pages/CustomPortfolio/AdvCustomStrategy';
import AdminServices from 'Services/api/AdminServices';
import AdminServicesPY from 'Services/api/AdminServicesPY';
import apiHelper from 'Services/api/ApiHelper';
import apiHelperPY, { decodeData } from 'Services/api/ApiHelperPY';
import { Alert, Avatar, Button, Card, Checkbox, Collapse, Divider, Drawer, Modal, Segmented, Select, Space, Switch, Tooltip, notification } from 'antd';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-hot-toast';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BiCheck, BiInfoCircle } from 'react-icons/bi';
import { IoIosArrowDown, IoIosArrowForward, IoIosClose, IoIosCloseCircleOutline } from 'react-icons/io';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const grid = 5;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: "0.5rem 0rem",
    borderRadius: 5,
    ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
});

function Quartile(data, q) {
    var pos = ((data.length) - 1) * q;
    var base = Math.floor(pos);
    var rest = pos - base;
    if ((data[base + 1] !== undefined)) {
        return data[base] + rest * (data[base + 1] - data[base]);
    } else {
        return data[base];
    }
}

const SaveSignalModal = React.memo(({ signal_name, signal_desc, modalSave, setmodalSave, alertsEnabled, saveSignal, setSavingSignal, savingSignal, totalSignals, subscribeMiddle, isUpdating = false }) => {

    const [name, setName] = React.useState(signal_name);
    const [desc, setDesc] = React.useState(signal_desc);
    const [alerts, setAlerts] = React.useState(alertsEnabled);

    React.useEffect(() => {
        setName(signal_name);
    }, [signal_name]);

    React.useEffect(() => {
        setDesc(signal_desc);
    }, [signal_desc]);

    React.useEffect(() => {
        setAlerts(alertsEnabled);
    }, [alertsEnabled]);

    const modelDetailedAccess = subscribeMiddle({ moduleKey: "SAVE_MODELS_AND_SET_ALERTS", getDetailedAccessData: true, usageCount: totalSignals, checkLimit: true });

    return (
        <Modal
            title={<div className='d-flex align-items-center justify-content-between' style={{ gap: "1rem" }}>
                <div className='textLG w-700' >{isUpdating ? "Update Factor Model" : "Save Factor model"}</div>
                {!isUpdating && modelDetailedAccess?.maxLimit !== Infinity && <div>
                    <div style={{ userSelect: "none" }}>
                        <span className="textXS dark3 w-500">Credits: <span className='dark4 w-700'>
                            <b style={{ color: modelDetailedAccess?.remainingLimit === 0 ? "red" : "var(--primary)" }}>{modelDetailedAccess?.remainingLimit}</b>
                            <b>/{modelDetailedAccess?.maxLimit}</b></span>.&nbsp;
                            {modelDetailedAccess?.currentAccessLevel !== 4 && <span>Upgrade to <Link className="dark4 w-700" to='/pricing' target="_blank">
                                <PlanName accessLevel={modelDetailedAccess?.currentAccessLevel + 1} /></Link>.</span>}
                        </span>
                    </div>
                </div>}
            </div>}
            centered
            open={modalSave}
            closable={false}
            footer={false}
            width={"min(100%, 30rem)"}
            zIndex={1000}
            onCancel={() => {
                setmodalSave(false);
            }}
        >

            <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem", marginTop: "1rem" }}>
                <div style={{ display: "flex", gap: "2rem" }}>
                    <div style={{ display: "flex", gap: "0.25rem", flex: 1 }}>
                        <span className='d-flex gap-3 align-items-center'><span className='textXS w-500 dark3'>Alert</span><Switch checked={alerts} onChange={(e) => {
                            setAlerts(!alerts)
                        }} /></span>
                    </div>
                </div>

                <NameInput label='Factor model Name' placeholder='Enter your factor model name' value={name} onChange={(e) => {
                    setName(e);
                }} changeValueOnChange />

                <DescriptionInput label={<span>
                    Description
                </span>} placeholder='Enter your factor model description' value={desc} onChange={(e) => {
                    setDesc(e);
                }} changeValueOnChange />

                <span className='d-flex flex-row gap-2' style={{ justifyContent: "flex-end" }}>
                    <button className="btnWhite" onClick={() => setmodalSave(!modalSave)}>Cancel</button>
                    <SubscribeContentWrapper moduleKey="SAVE_MODELS_AND_SET_ALERTS" checkLimit usageCount={totalSignals} preventDefault={isUpdating} disabled={(isUpdating ? false : modelDetailedAccess?.remainingLimit === 0) || isInvalidTextOrLength({ text: name, checkLength: true }) || desc?.length < 15}
                        onClick={() => {
                            if (!savingSignal) {
                                setSavingSignal(true);
                                saveSignal({ updName: name, updDesc: desc, updAlerts: alerts })
                            }
                        }}>
                        <button className="btnBlack" disabled={(isUpdating ? false : modelDetailedAccess?.remainingLimit === 0) || isInvalidTextOrLength({ text: String(name), length: true }) || desc?.length < 15} >
                            {savingSignal ? <LoadingOutlined spin /> : null}    Save
                        </button>
                    </SubscribeContentWrapper>
                </span>
            </div>
        </Modal>
    )
})

const FactorModel = (props) => {

    var logg = "AKAK"

    var { strategy_fundD, typeD, updateStrategyParams = () => { }, triggerBacktest = false, isWidget = false, widgetSignalData } = props

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [saveAsChecklist, setsaveAsChecklist] = useState(true)

    var user = useSelector((state) => state?.updateUserData?.value)
    const accessLevelData = useSelector((state) => state?.accessLevel?.value)
    const userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel || 0

    let userAccess = getUserAccessLimitFromAccessObj({ accessObj: accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === 'CREDITS_PER_STRATEGY_IN_PAPERTRADE'), accessLevel: userAccesslevel });

    let initial_capital = userAccess || 100000

    // const [isAllFunds, setisAllFunds] = useState(true)
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, title, desc, btn = null) => {
        const key = `open${Date.now()}`;
        // const btn = (
        //     <Space>
        //         <Button type="primary" size="small" onClick={() => api.destroy(key)}>
        //             Confirm
        //         </Button>
        //     </Space>
        // );
        api[type]({
            message: title,
            description: desc,
            placement: "topRight",
            key,
            btn
        });
    };
    const [openInvModal, setOpenInvModal] = useState(false)
    const [screenerMetric, setscreenerMetric] = useState([])
    const [screenerFinalData, setscreenerFinalData] = useState([])
    const [stockSectorCLF, setStockSectorCLF] = useState([])
    const [styleBoxes, setStyleBoxes] = React.useState([]);
    const [allFilteredFundsData, setallFilteredFundsData] = useState([])
    const [allFundsData, setallFundsData] = useState([])
    const [backtestDataChangeReq, setBacktestDataChangeReq] = useState(true);
    const [selectedCategory, setselectedCategory] = useState("")
    const [selectedSubCategory, setselectedSubCategory] = useState("")
    const [selectedMetric, setselectedMetric] = useState("")
    const [totalSignals, setTotalSignals] = useState();
    const [totalClonedUserSignals, setTotalClonedUserSignals] = React.useState(0);
    const [isLocked, setIsLocked] = React.useState(0);
    const [isCloned, setIsCloned] = React.useState(false);
    const [canPublish, setCanPublish] = React.useState(false);
    const [savingSignal, setSavingSignal] = React.useState(false);
    const [allPublishers, setAllPublishers] = React.useState([])

    const [viewType, setviewType] = React.useState('backtest'); // backtest or papertrade
    const [viewTypePendingNewRun, setviewTypePendingNewRun] = React.useState(2); // 2 for both, 1 for backtest, 0 latest

    let last3Year1StDate = new Date();
    last3Year1StDate.setFullYear(last3Year1StDate.getFullYear() - 3);

    const [start_date, setstart_date] = useState(formatDateyyyymmdd(last3Year1StDate))
    const [end_date, setend_date] = useState(formatDateyyyymmdd(new Date()))

    const [start_date2, setstart_date2] = useState(formatDateyyyymmdd(last3Year1StDate))
    const [end_date2, setend_date2] = useState(formatDateyyyymmdd(new Date()))
    const [regenerateOrder, setregenerateOrder] = useState(false)

    const [backtestConfig, setBacktestConfig] = React.useState({ ...initBacktestConfig, 'initial_capital': initial_capital });
    const [updatedConfig, setupdatedConfig] = React.useState({ ...initBacktestConfig, 'initial_capital': initial_capital });
    const [backTestPerfData, setbackTestPerfData] = React.useState([]);
    const [backtestCode, setbacktestCode] = React.useState('');
    const [benchmarks, setBenchmarks] = React.useState([]);
    const subscribeMiddle = useSubscribeMiddleware();
    const { getUserStockModels, getUserCloneItems } = useUserDataHooks();
    const [indices, setIndices] = React.useState([]);
    const [sum, setSum] = useState(100)
    const [globals, setGlobals] = React.useState({});
    const [conditions, setConditions] = React.useState({
        "0_condition": {
            queries: {}
        }
    })
    const [conditions_oper, setConditionsOper] = React.useState([])
    const [variables, setVariables] = React.useState({})

    let score_across_opts = [...groupColumnNames];
    score_across_opts = score_across_opts?.filter((el) => el?.tabType === 'CrossSection')?.map((el) => {
        return {
            ...el,
            value: el?.value?.replace(/\"/g, ""),
            label: `All stocks in ${el?.label}`
        }
    })

    const sortOptions = [
        {
            value: 'top',
            queryStr: 'top',
            label: 'Higher the better',
        },
        {
            value: 'bot',
            queryStr: 'bot',
            label: 'Lower the better',
        }
    ]

    var { state } = useLocation()

    const [searchParams, setSearchParams] = useSearchParams();
    const param_fmId = Number(searchParams.get('fmId')) || 0;
    const param_fmName = searchParams.get('fmName');
    const param_fmUserId = Number(searchParams.get('userId')) || user?.id;
    var cloned_currentCheckListId = state?.signalData?.signal_id || 0
    var cloned_currentCheckListName = state?.signalData?.signal_name || ""
    var cloned_currentCheckListDesc = state?.signalData?.signal_desc || ""
    var cloned_signal_data = state?.signalData
    let parentSignalId = state?.parentSignalId || null;
    let publisherClientId = state?.publisherClientId || null;
    var fromStrategyCreation = state?.fromStrategyCreation || false


    const [alertsEnabled, setalertsEnabled] = useState(false)
    const [isPublished, setIsPublished] = useState(false)

    const [numOfFunds, setNumOfFunds] = React.useState(null)

    const [signalData, set_signal_final_query] = useState({})
    const [signal_name, setsignal_name] = useState('')
    const [signal_desc, setsignal_desc] = useState('')
    const [signal_id, setsignal_id] = useState(0)
    const [newFiltersScoreList, setnewFiltersScoreList] = useState([])
    const [tableLoading, setTableLoading] = useState(true)

    const [nextDisabled, setnextDisabled] = useState(true)

    const [newFiltersList, setnewFiltersList] = useState([])
    const [checkFiltersList, setcheckFiltersList] = useState([])
    const [mfCategories, setmfCategories] = useState([])
    const [mfAMC, setmfAMC] = useState([])
    const [stock_macro_sectors, setMacroSectors] = useState([])
    const [stock_sectors, setSectors] = useState([])
    const [stock_industries, setIndustries] = useState([])
    const [stock_basic_industries, setBasicIndustries] = useState([])
    const [loading, setloading] = useState(true)

    const [modal, setmodal] = useState(false)
    const [modalSave, setmodalSave] = useState(false)
    const [backTestModal, setbackTestModal] = useState(false)
    const [investInScreenerModal, setinvestInScreenerModal] = useState(false)
    const [backTestModalLoading, setbackTestModalLoading] = useState(true)
    const [backTestData, setBackTestData] = useState({})
    const [metricbackTestRes, setMetricbackTestRes] = useState({})
    const [metricbackTestLoading, setMetricBackTestLoading] = useState(false);
    const [metricbackTestResName, setMetricbackTestResName] = useState('')
    const [toggleMetricBackTest, settoggleMetricBackTest] = useState(false)
    const [toggleDesc, settoggleDesc] = useState(false)
    const [tableColumns, setTableColumns] = useState([])
    const [appliedMetrics, setappliedMetrics] = useState([])
    const [instrumentList, setinstrumentList] = React.useState([]);

    const [selectedStockUniverse, setselectedStockUniverse] = useState("NIFTY500")
    const [selectedStockUniverseMetricBack, setselectedStockUniverseMetricBack] = useState([])
    const [selectedPortfolioType, setselectedPortfolioType] = useState(0)
    const [excludeSurveillance, setexcludeSurveillance] = useState(false)
    const [btNotAvailable, setBtNotAvailable] = React.useState(false);
    const [popopen, setpopOpen] = useState();
    const [deleteSignalModal, setDeleteSignalModal] = React.useState(false);


    const [functions, setFunctions] = React.useState([]);
    const [functionMap, setFunctionMap] = React.useState([]);

    const [allPublishedFactorModels, setallPublishedFactorModels] = React.useState([]);

    const [signal_config, set_signal_config] = React.useState({});



    let fmPublisherProfile = allPublishers?.filter((i) => {
        if (Object.keys(signalData || {})?.length > 0) {
            if (signalData?.is_published === 1) {
                return signalData?.client_id === i?.ClientId;
            }
        }
        return false
    })?.[0] || {}
    let userPublisherProfile = allPublishers?.filter((i) => i?.ClientId === user?.id)?.[0] || {}
    var type = useGetAssetType(typeD?.value)


    const metric_data = (key, value) => {
        return screenerMetric.filter((met) => met[key] === value)[0]
    }

    const convertToCodeToNormal = async (code) => {
        var allRows = code?.split(";")

        var parsedCode = await codeParserWrapper({
            code: code,
            screenerMetrics: screenerMetric,
            functions: functions,
            isSignal: true
        })


        var universe = parsedCode?.globals?.universe?.expression
        var exclusion = parsedCode?.globals?.exclusion_list?.expression
        var exchange = parsedCode?.globals?.exchange?.expression

        var metrics = []
        var weights = []
        Object.values(parsedCode?.variables)?.map((metric, index) => {


            if (!metric?.name?.includes("agg_z")) {
                var query = Object?.values(metric?.queries)?.[0]
                var metric_col_code = ''
                var direction = ''
                var score_across = ''


                for (let i = 0; i < query?.expression_arr?.length; i++) {
                    const str = query?.expression_arr[i];
                    var item = query?.expression_details
                    if (String(str)?.match(/\s{0,3}=\s{0,3}/g)) {
                        var arr = String(str)?.split(/\s{0,3}=\s{0,3}/g)
                        var keyName = arr?.[0]
                        var keyValue = item?.type === "<class 'int'>" ? parseInt(Number(arr?.[1])) : item?.type === "<class 'float'>" ? parseFloat(Number(arr?.[1])) : arr?.[1];
                        if (String(str)?.match(/by\s{0,3}=\s{0,2}/)) {
                            score_across = keyValue?.replaceAll("\"", "")
                        } else if (String(str)?.match(/direction\s{0,3}=\s{0,2}/)) {
                            direction = keyValue
                        }
                    } else {
                        metric_col_code = String(str)?.trim()
                    }

                }


                metrics?.push({
                    ...metric_data('metric_col_code', metric_col_code),
                    metric_col_code: metric_col_code,
                    score_col_code: metric?.name,
                    filter: Number(direction) === 1 ? "top" : "bot",
                    score_across: score_across
                })


            } else {
                var queryKeys = Object?.keys(metric?.queries)?.filter((i) => i?.includes("_number"))
                weights = queryKeys?.map((key) => {
                    return Number(metric?.queries?.[key]?.expression) * 100
                })
            }
        })

        metrics = metrics?.map((i, index) => {
            return {
                ...i,
                allocation: weights?.[index]
            }
        })




        setVariables(parsedCode?.variables)
        setGlobals(parsedCode?.globals)
        setConditions(parsedCode?.conditions)
        setConditionsOper(parsedCode?.conditionOperators)

        // setselectedStockUniverseMetricBack(universe)

        setselectedStockUniverse(universe)
        setexcludeSurveillance(exclusion === 0)

        setTimeout(() => {
            setnewFiltersList(metrics)
            setcheckFiltersList(metrics)
            getScoreViewList(metrics)
        }, 100);


    }


    const getPublisherProfiles = async () => {
        let allPublishers = await apiHelperPY({ apiName: "getAllPublisherProfile", saveResponse: "sessionStorage" });
        allPublishers = JSON.parse(allPublishers);
        setAllPublishers(allPublishers);
    }

    React.useEffect(() => {
        getPublisherProfiles();
    }, [])

    React.useEffect(() => {
        if (Object?.keys(signal_config)?.length > 0) {
            convertToCodeToNormal(signal_config?.code)
            updateStrategyParams(signal_config?.code)
        }
    }, [signal_config])


    const convertToCode = (data) => {

        var current_q = data || {}

        //console?.log(current_q)
        if (current_q?.config) {
            var t = String(current_q?.config)?.substring(1, String(current_q?.config)?.length - 1)
            let config = JSON.parse(t);
            // if (current_q?.parent_signal_id) {
            //     config = {
            //         ...config,
            //         "parent_signal_id": current_q?.parent_signal_id
            //     }
            // }
            setNumOfFunds(config?.position_sizing?.limit?.top || null)
            set_signal_config(config)
        } else {
            var universe = String(current_q?.universe)?.includes("_") ? `universe = \"NIFTY500\";` : `universe = \"${current_q?.universe}\";`

            var metrics = current_q?.metric_cols?.split(",")

            var score_across_vals = current_q?.score_across_vals !== undefined ? current_q?.score_across_vals?.split(",") : null

            var metric_vars = metrics?.map((metric, index) => {
                return `s${index} = score(${metric}, by="${score_across_vals !== null ? score_across_vals?.[index] : "MKT"}", direction=${Number(current_q?.direction_flag.split(",")[index]) === 1 ? 1 : -1});`
            })

            var output = metrics?.map((metric, index) => {
                return `${Number(current_q?.weights.split(",")[index])} * s${index}`
            })

            var is_exclusion_list = current_q?.is_exclusion_list === 1 ? "exclusion_list = is_exclusion_list == 0;" : ""

            var finalCode = `${universe}${is_exclusion_list}${metric_vars?.join("")}agg_z_score_val = score(${output?.join(" + ")});`

            finalCode += is_exclusion_list?.length > 2 ? "output = exclusion_list;" : "output = 1;"



            var limit = current_q?.top > 0 ? { 'limit': { 'by': 'agg_z_score_val', 'top': current_q?.top, 'sort': 'desc' } } : {}

            var finalConfig = {
                'code': finalCode,
                'position_sizing': {
                    'method': 'W-EQ',
                    // 'weight_by': 'agg_z_score_val',
                    ...limit
                }
            }
            setNumOfFunds(finalConfig?.position_sizing?.limit?.top || null)
            set_signal_config(finalConfig)
        }





    }


    var selectedm = screenerMetric.filter((i) => i.metric_col_code === selectedMetric)[0]

    const saveSignal = async ({ isPublishing = false, isPublishedInit = false, updName = null, updDesc = null, updAlerts = null } = {}) => {

        let name = updName !== null ? updName : signal_name;
        let desc = updDesc !== null ? updDesc : signal_desc;
        let alerts = updAlerts !== null ? updAlerts : alertsEnabled;

        if (user !== undefined && user !== null) {
            if (name.length > 0) {


                var q_param = {
                    "ClientId": user.id,
                    'Universe': selectedStockUniverse,
                    'MetricCols': newFiltersList.map((i) => i.metric_col_code),
                    'IsExclusionList': excludeSurveillance ? 1 : 0,
                    'DirectionFlags': newFiltersList.map((i) => (i.filter || i.direction_flag) === "top" ? 1 : -1),
                    'Weights': newFiltersList.map((i) => i.allocation / 100),
                    'Top': numOfFunds || -1,
                    "SignalId": signal_id,
                    "SignalName": name,
                    "IsPublished": isPublishing ? Number(isPublishedInit) : Number(isPublished),
                    'Config': JSON.stringify(JSON.stringify(signal_config)),
                    "Description": desc,
                    "IsAlerts": alerts ? 1 : 0
                }

                var savedSignal = await apiHelper({ apiName: 'saveSignal', data: q_param })
                setsignal_name(name);
                setsignal_desc(desc);
                setalertsEnabled(alerts);
                if (savedSignal?.[0]?.signal_id !== undefined) {
                    setsignal_id(savedSignal[0]?.signal_id)
                    if (isPublishing) {
                        let data = {
                            "clientId": user?.id,
                            "publisherId": userPublisherProfile?.UID,
                            "type": "FM",
                            "typeId": String(signal_id),
                            "publishedDate": new Date().toISOString(),
                            "isPublished": 1,
                            "isDeleted": 0
                        }
                        await apiHelper({ apiName: "addPublishedStrategy", data: { data } });
                        toast.success('Factor model published.')
                        setIsPublished(isPublishedInit);
                    }
                    else {
                        toast.success("Factor model saved")
                    }
                    await getUserStockModels({ getFresh: true });
                    navigate(`${type.basePath}/factor-model?fmName=${name?.replaceAll(' ', '-')}&fmId=${savedSignal[0]?.signal_id}&userId=${user?.id || 0}`)
                    setmodalSave(false)
                } else {
                    toast.error("Something went wrong. Try again later!!")
                }

            } else {
                toast.error("Please enter factor model name")
            }
        } else {
            toast.error("You need to login to create factor model")
            dispatch(toggleloginModal(true))
        }
        setSavingSignal(false);
    }

    const loadData = async () => {

        let indices = await getIndexList();
        indices = indices?.filter((el) => el?.is_in_screener === 1)
        setIndices(indices);
        let benchmarks = await fetchFundData(11);
        setBenchmarks(benchmarks);
        var screener_datas = await screenerMetricData(type, 1, 0, 1)
        setselectedCategory(screener_datas?.levelVals?.m_category_id)
        setselectedSubCategory(screener_datas?.levelVals?.m_sub_category_id)
        // setselectedMetric(screener_datas?.levelVals?.m_metric_col_code)

        setStockSectorCLF(screener_datas?.fabricatedData?.stockSectorCLF)
        setStyleBoxes(screener_datas?.fabricatedData?.stockStyles)
        setMacroSectors(screener_datas?.fabricatedData?.macroSectors)
        setSectors(screener_datas?.fabricatedData?.sectors)
        setIndustries(screener_datas?.fabricatedData?.industries)
        setBasicIndustries(screener_datas?.fabricatedData?.basicIndustries)
        setmfCategories(screener_datas?.fabricatedData?.mf_categories)
        setmfAMC(screener_datas?.fabricatedData?.mf_amcs)
        setscreenerMetric(screener_datas?.screenerMetricData)
        setscreenerFinalData(screener_datas?.screenerFinalData)
        setallFundsData(screener_datas?.allFunds)

        let func_map = await AdminServicesPY.loadFunctionMap().then((r) => r)
        func_map = JSON.parse(func_map?.data?.replace(/NaN/g, null)?.replace(/Infinity/g, null))
        func_map = func_map?.filter((el) => el?.sh_name !== 'mavp')
        setFunctions(func_map)
        setFunctionMap(groupBy(func_map, "type"));

        if (param_fmId === 0) {
            let allPublishedFactorModels = await apiHelperPY({ apiName: "getAllPublishedFactorModel" });
            setallPublishedFactorModels(JSON.parse(allPublishedFactorModels)?.filter((i) => i?.client_id !== user?.id))
        }

        setallFilteredFundsData([])
        setselectedStockUniverseMetricBack([stock_universe.filter((i) => i?.key !== 'all')[0].value])
        setloading(false)
        setpopOpen(false)


    }

    const getTotalSignals = async () => {
        let modelRes = await getUserStockModels();
        if (modelRes) {
            setTotalSignals(modelRes?.userModels?.length || 0);
            let checkIfLocked = modelRes?.userModels?.find((el) => el?.signal_id === Number(param_fmId))?.is_locked === 1;
            if (checkIfLocked) {
                setIsLocked(true);
            }
            else {
                setIsLocked(false);
            }
        }
        else {
            setTotalSignals(0);
            setIsLocked(false);
        }
        let cloneRes = await getUserCloneItems();
        if (cloneRes) {
            setTotalClonedUserSignals(cloneRes?.factorModels?.clonedModelsInMonth?.length || 0);
        }
        else {
            setTotalClonedUserSignals(0)
        }
    }

    React.useEffect(() => {
        setloading(true)
        setTableLoading(true)
        setTotalSignals(0);
        setTotalClonedUserSignals(0);
        setIsLocked(false);
        setnewFiltersList([])
        setNumOfFunds(null)
        setcheckFiltersList([])
        setallFilteredFundsData([])
        setBackTestData({})
        set_signal_final_query({})
        setsignal_desc('')
        setsignal_id(0)
        setsignal_name('')
        setalertsEnabled(false)
        setIsPublished(false)
        loadData()
        getTotalSignals();
    }, [state, searchParams, user])


    React.useEffect(() => {
        const dem = async () => {
            if (strategy_fundD) {
                setNumOfFunds(strategy_fundD?.config?.position_sizing?.limit?.top || null)
                set_signal_config(strategy_fundD?.config)
                setCanPublish(false);
                setIsCloned(false);
            }
            else if (isWidget) {
                setalertsEnabled(false)
                setIsPublished(false)
                set_signal_final_query(widgetSignalData)
                setsignal_name(widgetSignalData?.signal_name)
                setsignal_desc(widgetSignalData?.signal_desc)
                setsignal_id(widgetSignalData?.signal_id)
                convertToCode(widgetSignalData)
                setCanPublish(false);
                setIsCloned(false);
            }
            else if (cloned_signal_data) {
                setalertsEnabled(false)
                setIsPublished(false)
                set_signal_final_query(cloned_signal_data)
                setsignal_name(cloned_signal_data?.signal_name)
                setsignal_desc(cloned_signal_data?.signal_desc)
                setsignal_id(0)
                convertToCode(cloned_signal_data)
                setCanPublish(false);
                setIsCloned(true);
            } else if (param_fmId && param_fmName) {
                setIsCloned(false);
                if (param_fmUserId !== user?.id) {
                    //publisher
                    let allPublishedFactorModels = await apiHelperPY({ apiName: "getAllPublishedFactorModel" });
                    var signal_data = JSON.parse(allPublishedFactorModels)?.filter((i) => i?.client_id === param_fmUserId && param_fmId === i?.signal_id)?.[0]
                    if (signal_data) {
                        let cloneRes = await getUserCloneItems();
                        let checkIfCloned = cloneRes?.factorModels?.allClonedModels?.find((ele) => String(ele?.TypeId) === String(signal_data?.signal_id));
                        if (checkIfCloned) {
                            var t = String(signal_data?.config)?.substring(1, String(signal_data?.config)?.length - 1)
                            let config = JSON.parse(t);
                            navigate(`/stocks/factor-model`, {
                                state: {
                                    signalData: {
                                        ...signal_data,
                                        config: `"${JSON.stringify({ ...config, "parent_signal_id": signal_data?.signal_id, "is_published": 0 })}"`,
                                        signal_name: signal_data?.signal_name + "(Copy)",
                                        signal_desc: signal_data?.signal_desc
                                    },
                                    parentSignalId: signal_data?.signal_id,
                                    publisherClientId: param_fmUserId
                                },
                                replace: true
                            })
                        }
                        else {
                            setalertsEnabled(signal_data?.is_alerts || false)
                            setIsPublished(signal_data?.is_published || false)
                            set_signal_final_query(signal_data)
                            setsignal_name(signal_data?.signal_name)
                            setsignal_desc(signal_data?.signal_desc)
                            setsignal_id(signal_data?.signal_id)
                            convertToCode(signal_data)
                            setCanPublish(false);
                        }
                    }
                    else {
                        navigate(`${type.basePath}/factor-model`);
                    }
                } else {
                    // same user
                    let signalRes = await getUserStockModels();
                    let res = signalRes?.userModels || [];
                    var signal_data = res?.filter((i) => i?.client_id === param_fmUserId && param_fmId === i?.signal_id)?.[0]
                    if (signal_data) {
                        try {
                            let config = signal_data?.config;
                            let t = typeof (config) === 'string' ? String(config)?.substring(1, String(config)?.length - 1) : JSON.stringify(config)
                            t = JSON.parse(t);
                            if (t?.parent_signal_id) {
                                setCanPublish(false);
                            }
                            else {
                                setCanPublish(true);
                            }
                        }
                        catch (err) {
                            setCanPublish(false);
                        }
                        setalertsEnabled(signal_data?.is_alerts || false)
                        setIsPublished(signal_data?.is_published || false)
                        set_signal_final_query(signal_data)
                        setsignal_name(signal_data?.signal_name)
                        setsignal_desc(signal_data?.signal_desc)
                        setsignal_id(signal_data?.signal_id)
                        convertToCode(signal_data)
                    }
                    else {
                        navigate(`${type.basePath}/factor-model`);
                    }
                }
            }
        }
        dem()
    }, [loading, widgetSignalData])


    const [signal_funds, set_signal_funds] = React.useState({})


    const getSignalFunds = async () => {
        if (newFiltersList.length !== 0) {
            var res = await apiHelperPY({
                apiName: 'getAllScreenedStocksAdvV2', data: {
                    code: JSON.stringify(signal_config)
                }
            })

            try {
                res = JSON.parse(res?.replaceAll(/NaN/g, null)?.replaceAll(/Infinity/g, null)?.replaceAll(/-Infinity/g, null))
                set_signal_funds(res)
                let funds = (res?.instruments || []).map((i) => {
                    return {
                        ...[...allFundsData].filter((ii) => ii?.symbol === i?.symbol)[0],
                        ...i,
                    }
                })
                setallFilteredFundsData(funds)
                setTableLoading(false)
            } catch (error) {
                toast.error("Something went wrong")
            }


        } else {
            setallFilteredFundsData([])

        }
    }

    var fstCall = strategy_fundD ? 1 : 0
    React.useEffect(() => {
        if (selectedStockUniverse?.length > 0 && newFiltersList?.length > 0) {
            setTableLoading(true);
            setBacktestDataChangeReq(true);
            if (strategy_fundD === undefined)
                getSignalFunds();
            // fstCall++
        }
    }, [newFiltersList])

    const getScoreViewList = (data) => {
        var newItems = JSON.parse(JSON.stringify([...data])).map((item) => {
            return {
                ...item,
                "dataIndex": item.score_col_code,
                "key": item.score_col_code,
                "metric_col_code": item.score_col_code,
                "metric_unit": "",
                "metric_data_type": "float",
                'disableDeleteInCustomize': true,
                // "metric_name": item.metric_name + " (score)",
                "title": item.metric_name + " (score)",
                "sorter": (a, b) => a.score_col_code - b.score_col_code,
                "render": (text, record, index) => <div>
                    {displayScoreValue({
                        text, metricObj: {
                            ...item, "dataIndex": item.score_col_code,
                            "key": item.score_col_code,
                            "metric_col_code": item.score_col_code,
                            "metric_unit": "",
                            "metric_data_type": "float",
                            // "metric_name": item.metric_name + " (score)",
                            "title": item.metric_name + " (score)",
                            sorter: true
                        }
                    })}
                </div>
            }
        })

        setnewFiltersScoreList(newItems)
    }


    const getUpdatedConfig = (a) => {

        var data = {
            'universe': selectedStockUniverse,
            'metric_cols': a?.map((i) => i.metric_col_code)?.join(","),
            'is_exclusion_list': excludeSurveillance ? 1 : 0,
            'score_across_vals': a?.map((i) => i.score_across)?.join(","),
            'direction_flag': a?.map((i) => (i.filter || i.direction_flag) === "top" ? 1 : -1)?.join(","),
            'weights': a?.map((i) => i.allocation / 100)?.join(","),
            'top': numOfFunds,
        }

        convertToCode(data)
        getScoreViewList(a)
    }


    React.useEffect(() => {
        if (newFiltersList?.length > 0)
            getUpdatedConfig(newFiltersList)
    }, [selectedStockUniverse, excludeSurveillance, numOfFunds])

    const allocate = (newFiltersList, viewType = 0) => {

        var a = newFiltersList

        if (viewType === 2) {
            //initial set check to mainlist
            getUpdatedConfig(a)
        } else {
            if (100 % a.length === 0) {
                a = a.map((obj, index) => ({
                    ...obj,
                    "title": obj.metric_name + " (value)", allocation: (100 / a.length).toFixed(0), locked: false, index
                }))
                if (viewType === 0) {
                    getUpdatedConfig(a)
                } else {
                    setcheckFiltersList(a)
                }

            } else {
                a = a.map((obj, index) => ({
                    ...obj,
                    "title": obj.metric_name + " (value)", allocation: ((100 - (100 % a.length)) / a.length).toFixed(0), locked: false, index
                }))
                a[0].allocation = Number(a[0].allocation) + (100 % a.length)
                if (viewType === 0) {
                    getUpdatedConfig(a)
                } else {
                    setcheckFiltersList(a)
                }
            }
        }






    }

    const isValidAllo = (viewType) => {
        var sum = 0;


        if (viewType === 0) {
            newFiltersList.map((data, index) => {
                sum += parseInt(data.allocation);
            })

        } else {
            checkFiltersList.map((data, index) => {
                sum += parseInt(data.allocation);
            })

        }
        setSum(sum)
        if (sum !== 100) {

            setnextDisabled(true)
        } else {

            setnextDisabled(false)
        }
    }


    React.useEffect(() => {
        isValidAllo()
    }, [newFiltersList])

    React.useEffect(() => {
        isValidAllo(1)
    }, [checkFiltersList])

    const changeData = (index, text) => {
        try {

            var upDatedFilters = []


            upDatedFilters = [...checkFiltersList]


            const setFinalData = (data) => {
                setcheckFiltersList(data)
            }

            var a = JSON.parse(JSON.stringify(upDatedFilters))

            if (Number(text) !== 0) {
                a[index].allocation = text
                setFinalData(a)
            } else {
                toast.error("Weights can't be zero.\nEither allocate some weight or remove the factor model you don't want", {
                    className: "textSM"
                })
                setFinalData(newFiltersList);
            }

        } catch (error) {
        }
    }

    const getScreenerMetricBackTest = async (metric_col_code) => {
        // var backTestRes = screener_metric_backTest

        if (metric_col_code === "style_box_code") {
            let backTestRes = await apiHelperPY({ apiName: 'getSharpelyStyleBoxBacktest' })
            backTestRes = decodeData(backTestRes || "");
            backTestRes = await JSON.parse(backTestRes?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))
            setMetricbackTestRes(backTestRes?.prices)
            setMetricbackTestResName(metric_col_code)
        } else {
            if (metric_col_code !== undefined) {
                var backTestRes = await apiHelperPY({ apiName: 'getScreenerMetricBacktestData', data: { metric_code: metric_col_code, universe_code: selectedStockUniverseMetricBack[0] } })
                backTestRes = decodeData(backTestRes || "");
                backTestRes = await JSON.parse(backTestRes?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))
                setMetricbackTestRes(backTestRes?.prices)
                setMetricbackTestResName(metric_col_code)
            }
        }
        setMetricBackTestLoading(false);
    }


    React.useEffect(() => {
        setMetricBackTestLoading(true)
        setMetricbackTestRes({})
        getScreenerMetricBackTest(selectedm?.metric_col_code)
    }, [selectedStockUniverseMetricBack])

    const deleteSignal = async () => {
        try {
            let res = await AdminServices.deleteSignal(user?.id, signal_id,);
            if (res.type === true) {
                await getUserStockModels({ getFresh: true })
                setDeleteSignalModal(false);
                toast.success('Factor model deleted!');
                setTimeout(() => {
                    navigate(`/factor-models`);
                }, 500)
                // setloading(true);
                // window.location.reload();
                // setResetState(prev=>prev+1)
            }
            else {
                toast.error('Something went wrong!');
                setDeleteSignalModal(false);
            }
        }
        catch (err) {
        }
    }


    const modelDetailedAccess = subscribeMiddle({ moduleKey: "SAVE_MODELS_AND_SET_ALERTS", getDetailedAccessData: true, usageCount: totalSignals, checkLimit: true });

    const openBacktestInpModal = (configM) => {

        if (configM === undefined)
            configM = signal_config

        let config = {
            ...backtestConfig,
            ...{
                ...configM,
                'position_sizing': {
                    ...backtestConfig?.position_sizing,
                    ...configM?.position_sizing,
                    'limit': {
                        'by': 'agg_z_score_val',
                        'top': configM?.position_sizing?.limit?.top || 30,
                        'sort': 'desc'
                    }
                }
            }, 'instrument_selection': 3
        };

        onBacktestInpDone(config, start_date, end_date)
    }

    const socketlogic = async (t) => {
        let webSocket = new WebSocket(`wss://pyapi.mintbox.ai/ws2/ws/backtest`);
        //wss://uat.mintbox.ai/ws2/ws/backtest
        //ws://pyapi.mintbox.ai/ws2/ws/backtest
        //wss://pyapi.mintbox.ai/ws2/ws/backtest
        //ws://localhost:8001/ws/backtest
        var backtestConfig = JSON.parse(t?.config)
        var chartData = []
        webSocket.onerror = function (event) {

        };

        webSocket.onopen = function (event) {
            webSocket?.send(JSON.stringify(t))
            return false;
        };

        webSocket.onmessage = function (event) {
            const message = JSON.parse(event.data?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null));
            if (message?.includes("code") && message?.includes("bt")) {
                setBackTestData(JSON.parse(message)?.bt)
                setbackTestModalLoading(false);
            } else if (Array.isArray(message)) {
                message.forEach(data => {
                    chartData.push({
                        Portfolio: data[1] / backtestConfig?.initial_capital,
                        Benchmark: data[2] / backtestConfig?.initial_capital,
                        Date: data[0]
                    })
                });
                setbackTestPerfData([
                    ...chartData
                ])
            }
            else {
            }
        };
        webSocket.onclose = async function (event) {
        };
    }

    const onBacktestInpDone = async (config, startDate, endDate) => {
        let code = signal_config?.code;
        if (code && !code?.includes("Error:")) {
            let updConfig = {
                ...config,
                // 'strategy_id':1,
                'client_id': user?.id,
                'strategy_type_id': config?.schedule?.freq !== "NA" ? 1 : 2,
                // 'strategy_name':"New Strategy",
                'strategy_desc': "",
                'instrument_type': 3,
                'instrument_selection': 3,
                'code': code
            }
            setbackTestModal(true);
            if (code?.length > 10 && (!(JSON.stringify(backtestConfig) === JSON.stringify(config) && start_date === startDate && end_date === endDate) || !(code === backtestCode))) {
                setbackTestModalLoading(true)
                setbackTestPerfData([]);
                setBacktestConfig(config);
                setupdatedConfig(config);
                setviewTypePendingNewRun(0)
                setbacktestCode(code)
                setstart_date(startDate)
                setend_date(endDate)
                socketlogic({
                    config: JSON.stringify(updConfig),
                    initial_capital: updConfig?.initial_capital || 100000,
                    backtest_start: startDate,
                    end_date: endDate
                })
            }
        } else {
            toast.error(code ? code?.replace("Error: ", "") : "Please add factors")
        }
    }

    const onPaperTrade = async (name = "") => {
        setviewType('papertrade')
        setregenerateOrder(false)
        let code = signal_config?.code;
        var isUpdated = false
        if (!(JSON.stringify(backtestConfig) === JSON.stringify(updatedConfig) && start_date2 === start_date && end_date2 === end_date) && viewTypePendingNewRun === 2) {
            isUpdated = true
            setviewTypePendingNewRun(1)
            // setBacktestConfig(updatedConfig)
            // setstart_date(start_date2)
            // setend_date(end_date2)

        }
        if (code && !code?.includes("Error:")) {
            let updConfig = {
                ...(isUpdated ? updatedConfig : backtestConfig),
                // 'strategy_id':1,
                'client_id': user?.id,
                'strategy_type_id': (isUpdated ? updatedConfig : backtestConfig)?.schedule?.freq !== "NA" ? 1 : 2,
                'strategy_name': name,
                'strategy_desc': "",
                'instrument_type': 3,
                'instrument_selection': 3,
                'code': code
            }
            return updConfig
        } else {
            toast.error(code ? code?.replace("Error: ", "") : "Please add factors")
            return null
        }
    }

    const MetricFilterView = ({ data, index, viewType = 0 }) => {

        const [weight, setWeight] = React.useState(Number(data?.allocation))
        var upDatedFilters = []


        upDatedFilters = [...checkFiltersList]



        var currentMetricData = { ...metric_data('metric_col_code', data.metric_col_code), ...data }

        var floatType = currentMetricData.metric_data_type === "float" && currentMetricData.show_percentile === 1 && currentMetricData.show_absolute === 1 ? "top" :
            currentMetricData.metric_data_type === "float" && currentMetricData.show_percentile === 1 ? "bot" : null

        var queriesFinal = currentMetricData.metric_data_type === "string" ? queries[0] : currentMetricData.metric_data_type === "boolean" ? queries[3] : (currentMetricData.metric_data_type === "Date" || currentMetricData.metric_data_type === "date") ? queries[4] :
            floatType === "top" ? queries[1]?.concat(queries[2]) :
                floatType === "bot" ? queries[1] :
                    floatType === null ? queries[2] : queries[0]

        const setFinalData = (data) => {

            setcheckFiltersList(data)


        }

        const onQueryValueChange = (queryValue) => {
            upDatedFilters[index] = {
                ...data,
                score_across: queryValue
            }

            setFinalData(upDatedFilters)
        }


        const FilterViewLevel1 = () => {
            return <Segmented
                className='textSM black'
                options={sortOptions}
                onChange={(value) => {
                    upDatedFilters[index] = {
                        ...data,
                        filter: value
                    }
                    // set()
                    setFinalData(upDatedFilters)
                }}

                defaultValue={currentMetricData.filter || (currentMetricData.direction_flag === "top" ? sortOptions[0].value : sortOptions[1].value)}

            />
        }

        return <Card className='shadowXS' bordered style={{ padding: 0, borderColor: viewType === 0 ? "var(--blueShade)" : "var(--grey3)", backgroundColor: viewType === 0 ? "var(--blue2)" : "unset", borderRadius: isMobile ? 0 : '', borderLeft: isMobile ? 'none' : '', borderRight: isMobile ? 'none' : '' }}
            key={index} bodyStyle={{ padding: "1.25rem" }}>
            {/* <MdDragIndicator size={17} color="var(--primary)" /> */}
            <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ flex: 1, alignItems: "center", display: "flex", gap: "0.5rem" }}>

                        <span className='textSM black w-bold'>{currentMetricData.metric_name}</span>
                        {viewType !== 0 && <BiInfoCircle style={{ cursor: "pointer" }} onClick={() => {
                            settoggleDesc(true)
                        }} />}
                    </span>
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <div style={{ display: "flex", gap: "0.5rem", flexDirection: "row", alignItems: 'center' }}>
                            <LabelInputNew
                                label={"Weight:"}
                                value={weight}
                                suffix={" %"}
                                min={1}
                                style={{ width: "5rem" }}
                                max={upDatedFilters.length > 1 ? 99 : 100}
                                onChangeVal={(weight) => {
                                    changeData(index, parseInt(weight))
                                }}
                            />
                        </div>
                        <IoIosCloseCircleOutline className='textSM black' color='var(--dark3)' style={{ cursor: "pointer" }} size={18} onClick={() => {
                            var a = [...upDatedFilters]
                            a.splice(index, 1)
                            setFinalData(a)
                        }} />
                    </div>
                </div>
                <div style={{ display: "flex", gap: "1rem", alignItems: "center", justifyContent: "start" }}>

                    <div style={{ display: "flex", gap: "0.2rem", flexDirection: "column" }}>
                        <span className='textXS dark3'>Direction:</span>
                        <FilterViewLevel1 />
                    </div>

                    <div style={{ display: "flex", gap: "0.2rem", flexDirection: "column" }}>
                        <span className='textXS dark3'>Score across:</span>
                        <Select removeIcon={<IoIosCloseCircleOutline size={12} />}
                            className='textSM black whiteBackground'
                            suffixIcon={<IoIosArrowDown size={15} />}
                            defaultValue={currentMetricData?.score_across || "MKT"}
                            value={currentMetricData?.score_across || "MKT"}
                            style={{ borderRadius: 5, backgroundColor: "var(--white)", width: '15rem' }}
                            onChange={(value) => {
                                onQueryValueChange(value)
                            }}
                            options={score_across_opts}
                        />
                    </div>


                    {/* <CurrencyInput
                    className='inputBoxMain textSM black  '
                    value={weight}
                    suffix={" %"}
                    onValueChange={(value) => {
                        setWeight(value)
                    }}
                    min={1}
                    max={upDatedFilters.length > 1 ? 99 : 100}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            if (weight !== undefined) {
                                changeData(index, parseInt(weight))
                            } else {
                                toast.error("Please enter a value")
                            }
                            // onValueChange(Number(metricVal))
                        }
                    }}
                    onBlur={() => {
                        if (weight !== undefined) {
                            changeData(index, parseInt(weight))
                        } else {
                            toast.error("Please enter a value")
                        }
                        // onValueChange(Number(metricVal))
                    }}
                    onSubmit={() => {
                        if (weight !== undefined) {
                            changeData(index, parseInt(weight))
                        } else {
                            toast.error("Please enter a value")
                        }
                        // onValueChange(Number(metricVal))
                    }}
                /> */}



                    {/* <Select options={
                    queries?.[6]?.map((el) => {
                        return {
                            ...el,
                            label: `All stocks in ${el?.label}`
                        }
                    })
                } value={getNestedValue(['schedule', 'day'])} onChange={(val) => {
                    changeConfig([{ keys: ['schedule', 'day'], value: val }])
                }} style={{ width: "100%" }} className='whiteBackground' />

 */}

                </div>
            </div>
        </Card>



    }

    const onMetricSearch = (metric) => {
        var upDatedFilters = [...checkFiltersList]
        // var upDatedFilters = []
        var itemIndex = [...checkFiltersList].findIndex((met) => met.metric_col_code === metric.metric_col_code)
        var isPresentIndex = itemIndex !== -1
        setselectedCategory(metric.category_id)
        if (!isPresentIndex) {
            subscribeMiddle({
                moduleKey: "NUMBER_OF_FACTORS",
                checkLimit: true,
                onNext: () => {
                    setselectedSubCategory(metric.sub_category_id)
                    setselectedMetric(metric.metric_col_code)
                    // isPresentIndex = true
                    var selectedm = screenerMetric.filter((i) => i.metric_col_code === metric.metric_col_code)[0]
                    upDatedFilters.push({
                        ...selectedm,
                        metric_col_code: metric.metric_col_code,
                        score_across: selectedm?.score_across || "MKT"
                    })
                    setcheckFiltersList(upDatedFilters)
                    allocate(upDatedFilters, 1)
                },
                usageCount: upDatedFilters?.length
            })
        } else {
            upDatedFilters.splice(itemIndex, 1)
            setcheckFiltersList(upDatedFilters)
            allocate(upDatedFilters, 1)
        }
    }

    const RenderIntialFlatlist = ({ item }) => {

        const [checked, setChecked] = React.useState()

        return item.sub_category.filter((ele) => ele.isVisibleNormally !== false).map((subCategory, subCategoryIndex) => {
            return <div key={"asd" + subCategoryIndex} style={{ borderRadius: 2, marginBottom: "2rem" }}>
                <span className="textSM black w-500"> {subCategory.sub_category_name}</span>

                <span style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginTop: "0.625rem" }}>
                    {subCategory.metric.map((metric, metricIndex) => {

                        var upDatedFilters = [...checkFiltersList]
                        // var upDatedFilters = []
                        var itemIndex = [...checkFiltersList].findIndex((met) => met.metric_col_code === metric.metric_col_code)
                        var isPresentIndex = itemIndex !== -1

                        if (metric.isVisibleNormally !== false) {
                            return <AccessComponent
                                reqAccessLevel={metric.auth_flag}
                                upgradeComponent={
                                    <div key={metric.metric_col_code} style={{ cursor: "pointer", color: "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem" }} className='textXS'>
                                        <Checkbox
                                            disabled={true}
                                        />
                                        <span onClick={() => {
                                            setselectedMetric(metric.metric_col_code)
                                        }}>{metric.metric_name}</span>
                                        <SubscribeIcon customAccessObj={{
                                            IsEnabled: 1,
                                            AccessLevel: Number(metric?.auth_flag),
                                            ModuleName: "Proprietary Metrics",
                                            ModuleDesc: "Get access to all sharpely proprietary metrics and scores."
                                        }} />
                                    </div>
                                }
                                component={<div key={metric.metric_col_code} style={{ cursor: "pointer", color: "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem" }} className='textXS'>
                                    <Checkbox
                                        checked={isPresentIndex === true}
                                        onChange={(e) => {
                                            e.preventDefault()
                                            if (!isPresentIndex) {
                                                subscribeMiddle({
                                                    onNext: () => {
                                                        setselectedSubCategory(subCategory.sub_category_id)
                                                        setselectedMetric(metric.metric_col_code)
                                                        // isPresentIndex = true
                                                        var selectedm = screenerMetric.filter((i) => i.metric_col_code === metric.metric_col_code)[0]
                                                        upDatedFilters.push({
                                                            ...selectedm,
                                                            metric_col_code: metric.metric_col_code,
                                                            score_across: selectedm?.score_across || "MKT",
                                                        })
                                                        setcheckFiltersList(upDatedFilters)
                                                        allocate(upDatedFilters, 1)
                                                    },
                                                    moduleKey: "NUMBER_OF_FACTORS",
                                                    checkLimit: true,
                                                    usageCount: upDatedFilters?.length
                                                })
                                            } else {
                                                upDatedFilters.splice(itemIndex, 1)
                                                setcheckFiltersList(upDatedFilters)
                                                allocate(upDatedFilters, 1)
                                            }
                                            if (isMobile) {
                                                setShowSelectedMetrics(true)
                                            }
                                        }}
                                    />
                                    <span onClick={() => {
                                        setselectedMetric(metric.metric_col_code)
                                    }}>{metric.metric_name}</span>
                                </div>} />
                        } else {
                            return <div key={"DSF" + metricIndex
                            } ></div >
                        }
                    })}
                </span >
            </div >
        })
    }

    const CheckedFilterView = () => {
        return <span style={{ display: "flex", flex: 1, flexDirection: "column", gap: "1.2rem", padding: '1.25rem', flex: 1 }}>
            <div style={{ display: "flex", alignItems: "center", gap: '1rem' }}>
                <div style={{ flex: 1 }}>
                    <div className='textSM black w-700'>Build your Factor Model</div>
                    <div className='textXS black w-400'>Start adding metrics</div>
                </div>
                {isMobile && <button className="btnBlack" onClick={() => {
                    setShowSelectedMetrics(false)
                }}>Add factor</button>}
                <button disabled={nextDisabled} className="btnWhite" onClick={() => {
                    setnewFiltersList([])
                    setcheckFiltersList([])
                }}>Clear all</button>
            </div>
            {nextDisabled && checkFiltersList.length > 0 && <Alert className='textXS' message={<span className='textXS'>Sum of weights of selected metrics must be 100. <span className='textXS primary' style={{ cursor: "pointer" }} onClick={(() => {
                allocate(checkFiltersList, 1)
            })}>Reset to equal weights</span></span>} type="error" showIcon />}
            <div style={{
                display: "flex", flex: 1, flexDirection: "column", overflowY: 'scroll',
                // minHeight: '15rem', maxHeight: '15rem',
                gap: "1.2rem", margin: isMobile ? "0 -1.25rem" : ""
            }}>
                {checkFiltersList.map((item, idx) => {
                    return <MetricFilterView key={idx} data={item} index={idx} viewType={1} />
                })}
            </div>
        </span>
    }

    const DrawerMetricDescComp = () => {
        return <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", flex: 1 }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <span className='textMD black w-700'>{selectedm?.metric_name}</span>
                {(selectedm?.back_test_available === 1 && selectedm?.metric_data_type === 'float') || selectedm?.metric_col_code === "style_box_code" ? <span>
                    {selectedm?.isVisibleNormally !== false ? <AccessComponent
                        reqAccessLevel={selectedm.auth_flag}
                        upgradeComponent={
                            <SubscribeBtnSmall str='Metric performance' customAccessObj={{
                                IsEnabled: 1,
                                AccessLevel: Number(selectedm?.auth_flag),
                                ModuleName: "Backtest Metrics",
                                ModuleDesc: "Backtest indvidual metrics to analyze it's effectiveness."
                            }} />
                        }
                        component={<button className='btnWhite btnSM mb-1' onClick={() => {
                            setMetricBackTestLoading(true);
                            getScreenerMetricBackTest(selectedm?.metric_col_code)
                            settoggleMetricBackTest(true)
                        }}>
                            Metric performance
                        </button>} />
                        : <></>}

                </span> : <Tooltip title={<span className='textXS w-500'>We do not have sufficient historical data to show back tested performance of this metric. Note that if you use this metric in your screen, back tested performance of screen will also not be available.</span>} arrow={false}>
                    <span>
                        <button className='btnWhite' disabled >Metric performance</button>
                    </span>
                </Tooltip>}
            </div>
            <span style={{ overflowY: "scroll" }}>
                {selectedm?.metric_desc}
            </span>
        </div>

    }

    const BacktestNotAvailable = () => {
        return (
            <Modal open={btNotAvailable} onCancel={() => { setBtNotAvailable(false) }} onOk={() => { setBtNotAvailable(false) }} footer={<button className='btnBlack'
                onClick={() => {
                    setBtNotAvailable(false);
                }}>Close</button>} closable={false}>
                <div className='' >
                    <div className='textMD' style={{ marginBottom: "1.25rem" }}>
                        Back tested performance of this factor model is not available. This could be due to following reasons:
                    </div>
                    <div style={{ marginBottom: "0.5rem" }} className='textSM'>
                        1.	Number of stocks in your factor model is less than 5 or more than 100
                    </div>
                    <div className='textSM'>
                        2.	We do not have sufficient historical data for one or more metrics used in your factor model
                    </div>
                    <div className='textSM'>
                        3.	You have not added any selection criteria.
                    </div>
                </div>
            </Modal>
        )
    }


    React.useEffect(() => {
        if (triggerBacktest) {
            openBacktestInpModal(strategy_fundD?.config)
        }
    }, [triggerBacktest])

    const AppliedFilterView = () => (
        <div
            style={{
                backgroundColor: "var(--white)",
                boxShadow: '0.1px 0.1px 10px 0px var(--grey1)', padding: "1rem", borderRadius: "0.3rem"
            }}
        >
            <div style={{ display: "flex", flex: 1, alignItems: "center", gap: "1rem", flexWrap: 'wrap' }}>
                <div style={{ display: "flex", gap: "0.4rem", alignItems: "center", flex: 1, minWidth: '45%' }}>
                    {((param_fmUserId !== user?.id) || isCloned) && <Avatar src={fmPublisherProfile?.PublisherIcon} />}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <CardHeader heading={strategy_fundD === undefined ? fromStrategyCreation ? "Build a factor model for your strategy" : (signal_name || "Build a multi factor model") : "Stock selection model"} />
                        {((param_fmUserId !== user?.id) || isCloned) && <div className="textXS textLight">
                            By {<Link style={{ color: "var(--primary)", cursor: "pointer" }} to={`/publisher-profile?name=${fmPublisherProfile?.Name?.replaceAll(" ", "-")}&id=${fmPublisherProfile?.UID}`} onClick={(e) => { e.stopPropagation() }}>{fmPublisherProfile?.Name}</Link>} on {formatDateMMMDDYYYY(signalData?.created_on)}
                        </div>}
                    </div>
                </div>

                {signal_id > 0 && param_fmUserId === user?.id && !fromStrategyCreation ? <SubscribeContentWrapper onClick={() => {
                    if (!userPublisherProfile?.IsApproved) {
                        if (userPublisherProfile?.IsApproved === 0)
                            openNotificationWithIcon("warning", "Pending approval", "Your profile is under review. Once approved then you will be allowed to publish this model", <Space>
                                <Link to="/user-profile" target="_blank">
                                    <Button type="primary" size="small">
                                        View profile
                                    </Button>
                                </Link>
                            </Space>)
                        else
                            openNotificationWithIcon("info", "Incomplete Publisher profile", "Please complete your publisher profile to start publishing model.", <Space>
                                <Link to="/user-profile" target="_blank" className="btn btn-primary">
                                    <Button type="primary" size="small">
                                        Complete profile
                                    </Button>
                                </Link>
                            </Space>)
                    }
                    else if (!canPublish) {
                        openNotificationWithIcon("info", "Cloned Model", "Since this model has been cloned, it cannot be published.")
                    }
                    else {
                        if (!isPublished) {
                            saveSignal({ isPublishing: true, isPublishedInit: !isPublished })
                        }
                    }
                }} moduleKey="PUBLISH_SCREENS_AND_MODELS" disabled={isPublished}>
                    <button className="btnWhite d-flex align-items-center" style={{
                        background: isPublished ? "var(--white)" : "", borderColor: isPublished ? "var(--green)" : "", gap: "0.25rem",
                        color: isPublished ? "var(--green)" : ""
                    }}>
                        {isPublished && <BiCheck size='1rem' />}
                        {isPublished ? "Published" : "Publish"}
                    </button>
                </SubscribeContentWrapper> : null}

                {strategy_fundD === undefined && <AccessComponent component={<div>
                    <span>
                        <button className='btnWhite' onClick={() => {
                            openBacktestInpModal()
                        }}>{fromStrategyCreation ? "Backtest and Paper trade" : "Backtest model"}</button>
                    </span>
                </div>} upgradeComponent={<SubscribeBtnSmall accessKey={fromStrategyCreation ? "BUILD,_BACKTEST_AND_PAPERTRADE" : 'BUILD_AND_BACKTEST_CUSTOM_MODELS'}
                    str={fromStrategyCreation ? "Backtest and Paper trade" : "Backtest model"} />}
                    moduleKey={fromStrategyCreation ? "BUILD,_BACKTEST_AND_PAPERTRADE" : 'BUILD_AND_BACKTEST_CUSTOM_MODELS'} />}

                {!fromStrategyCreation && (param_fmUserId == user?.id && strategy_fundD === undefined) ? <SubscribeContentWrapper moduleKey='SAVE_MODELS_AND_SET_ALERTS'
                    onClick={() => { setmodalSave(true) }}  >
                    <button className='btnBlack' disabled={newFiltersList.length === 0}>{signal_id !== 0 ? "Update" : "Save"}</button>
                </SubscribeContentWrapper> : strategy_fundD !== undefined && <button className='btnPrimary' onClick={() => getSignalFunds()}>
                    Run model
                </button>}
                {strategy_fundD === undefined && signal_id !== 0 && param_fmUserId == user?.id && <button className='btnWhite' onClick={() => setDeleteSignalModal(true)}>
                    <MdOutlineDeleteOutline size='1rem' color='var(--red)' />
                </button>}


                {param_fmUserId !== user?.id && <div>
                    <CloneItemModal onConfirm={async () => {
                        let data = {
                            "clientId": user?.id,
                            "publisherId": fmPublisherProfile?.UID,
                            "type": "FM",
                            "typeId": String(signal_id),
                            "publishedDate": new Date().toISOString(), // cloning date
                            "isPublished": 1,
                            "isDeleted": 0
                        }
                        await apiHelper({ apiName: "addClonedStrategy", data: { data } });
                        navigate(`/stocks/factor-model`, {
                            state: {
                                signalData: {
                                    ...signalData,
                                    config: `"${JSON.stringify({ ...signal_config, "parent_signal_id": signal_id, "is_published": 0 })}"`,
                                    signal_name: signal_name + "(Copy)",
                                    signal_desc: signal_desc
                                },
                                parentSignalId: signal_id,
                                publisherClientId: param_fmUserId
                            }
                        })
                    }} moduleKey="CLONE_COMMUNITY_MODELS" type='model' userCloneCount={totalClonedUserSignals} />
                </div>}

            </div>
            <Divider style={{ margin: "1rem 0" }} />
            {param_fmUserId !== user?.id ? <div style={{ display: "flex", gap: "1rem", flex: 1, width: "100%" }}>
                <div className='textXS textEllipsis2 dark2' style={{ flex: 3, maxWidth: "80%" }}>{signal_desc}</div>

                {param_fmUserId != user?.id && <div className='textXS textLight' style={{ flex: 1, textAlign: "right" }}>Clone this model to view/edit rules</div>}
            </div> : <div style={{ display: "flex", flexDirection: isMobile ? 'column' : 'row', gap: isMobile ? "1rem" : "1.75rem" }}>
                <div style={{ display: "flex", gap: "1.5rem" }}>
                    {type.value === 4 && <div className='' style={{ display: "flex", gap: "0.5rem", flexDirection: "column", flex: 1 }}>
                        <span className='textSM w-500' >Stock universe</span>
                        <Select
                            disabled={param_fmUserId !== user?.id}
                            options={indices?.map((item, i) => { return { key: i, label: item?.IndexName, value: item?.IndexNameCaps } })} style={{ minWidth: "12rem" }}
                            value={selectedStockUniverse}
                            placeholder="Stock universe"
                            onChange={(value) => {
                                setselectedStockUniverse(value)
                            }} />
                    </div>}
                    {type?.value === 4 && strategy_fundD === undefined && <div className='' style={{ display: "flex", gap: "0.5rem", flexDirection: "column", flex: 1, justifyContent: 'stretch' }}>
                        <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                            <label className='textSM w-500'>Number of stocks</label>

                            <Tooltip title={`Max.number of ${type.dname} in descending order of aggregate score`} arrow={false}>
                                <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                            </Tooltip>
                        </div>
                        <LabelInputNew disabled={param_fmUserId !== user?.id} max={50} min={numOfFunds === null ? null : 3} placeholder='No. of stocks' value={numOfFunds} style={{ width: '100%', minWidth: '12rem', alignSelf: 'stretch', paddingLeft: '1rem' }} onChangeVal={(e) => {
                            setNumOfFunds(e)
                        }} inputClassName="textSM w-500" />
                    </div>}
                </div>
                {type.value === 4 && strategy_fundD === undefined && <div className='' style={{ display: "flex", gap: "0.5rem", alignItems: 'center', height: 'fit-content' }}>
                    <span className='textSM w-500' >Exclude stocks in ASM/GSM/ESM framework</span>
                    <div>
                        <Switch disabled={param_fmUserId !== user?.id} className='' size="small" checked={excludeSurveillance} onChange={(e) => {
                            setexcludeSurveillance(e)
                        }} />
                    </div>
                </div>}
                <div style={{ flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                    <button className='btnBlack' onClick={() => {
                        setcheckFiltersList(newFiltersList)
                        setmodal(!modal)
                        setpopOpen(false)
                    }}>{newFiltersList.length === 0 ? "Add factor" : `Factor (${newFiltersList.length})`}</button>
                </div>
            </div>}
        </div>
    )

    const renderRow = ({ item, index }) => {
        return <span key={item.category_id} style={{
            padding: "0.75rem 1.25rem",
            borderRadius: "4px 0px 0px 4px",
            display: "flex",
            alignItems: "center",
            backgroundColor: item.category_id === selectedCategory ? "var(--blueShade)" : "transparent", cursor: "pointer"
        }}
            onClick={() => {
                setselectedCategory(item.category_id)
                setselectedSubCategory("")
                setselectedMetric("")
            }}
        >
            <span style={{ flex: 1, color: item.category_id === selectedCategory ? "var(--primary)" : "var(--dark3)" }} className="textSM w-500">{item.category_name}</span>
            <IoIosArrowForward color={item.category_id === selectedCategory ? "var(--primary)" : "var(--dark3)"} />
        </span>
    };

    const metricPerfOptions = [
        {
            value: 'performance',
            queryStr: 'performance',
            label: 'Performance',
            children: <div>
                <div className='' style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                    <span className='textSM w-500' >Stock universe</span>
                    <Select
                        className='textSM black '
                        style={{
                            width: "11rem"
                        }}
                        suffixIcon={<IoIosArrowDown size={15} />}
                        defaultValue={selectedStockUniverseMetricBack}
                        placeholder="Stock universe"
                        onChange={(value) => {
                            setselectedStockUniverseMetricBack([value])
                        }}
                        options={stock_universe.filter((i) => i.value !== 'all')}
                    />
                </div>

                {metricbackTestLoading ? <React.Fragment>
                    <div className='mainContainer' style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                        <ActivityLoaderMintbox message='Hang on….heavy lifting in progress! We simulate 5 quintile portfolios over more than 10
years of historical data to get you the back tested performance.' />
                    </div>
                </React.Fragment>
                    : Object.keys(metricbackTestRes).length !== 0 && selectedm?.metric_col_code === metricbackTestResName ?
                        <PerfBenchChart portfolioType={10} defYrSelect="1095D" xAxisKey={metricbackTestResName === "style_box_code" ? "Date" : "date"} chartData={metricbackTestRes} defAmtSelect={100000} />
                        : null
                }

            </div>
        }, {
            label: `Disclaimer`,
            key: "id4",
            value: "id4",
            children: (
                <div>
                    <Disclaimer val={3} />
                </div>
            ),
        }
    ]

    const getOptionsForSearch = React.useCallback(() => {
        let modInitData = screenerFinalData || []
        let j = modInitData?.filter((ele) => ele.isVisibleNormally === true);

        let t = [];
        for (let i = 0; i < j.length; i++) {
            let k = j[i]?.sub_category?.filter((ele) => ele?.isVisibleNormally !== false) || []
            if (type === 'customize') {
                k = k?.filter((ele) => ele?.isVisibleForCustomise);
            }
            t = [...t, ...k]
        }

        let mets = [];

        for (let i = 0; i < t.length; i++) {
            let k = t[i]?.metric?.filter((ele) => ele?.isVisibleNormally !== false) || [];
            if (type === 'customize') {
                k = k?.filter((ele) => ele?.hidden !== true && !(metricsToHideForCustomization.includes(ele?.metric_col_code)));
            }
            mets = [...mets, ...k]
        }

        // mets = mets?.map((ele) => {
        //     return {
        //         ...ele,
        //         tabType: ele?.type
        //     }
        // })
        mets = mets?.map((el) => {
            var selectedCategory = screenerFinalData?.filter((i) => i?.category_id === el?.category_id)?.[0] || {}
            var selectedSubCategory = selectedCategory?.sub_category?.filter((i) => i?.sub_category_id === el?.sub_category_id)?.[0] || {}
            return {
                ...el,
                tabType: el?.type,
                category_name: selectedCategory?.category_name,
                sub_category_name: selectedSubCategory?.sub_category_name,
            }
        });
        let metsToExclude = checkFiltersList ? checkFiltersList?.map((el) => el?.metric_col_code) : []
        mets = mets?.filter((el) => {
            return !(metsToExclude.includes(el?.metric_col_code));
        })
        return mets
    }, [screenerFinalData, checkFiltersList]);

    const optionsForSearch = getOptionsForSearch();

    const [metricPerfCur, setmetricPerfCur] = useState(metricPerfOptions[0].value)
    const [showSelectedMetrics, setShowSelectedMetrics] = useState(true)
    if (searchParams.get('fromStrategyCreation') === 'true') {
        return <Navigate to={'/stocks/factor-model'} state={{ fromStrategyCreation: true }} />
    }
    if (loading && isWidget === false) {
        return <React.Fragment>
            <div className='loaderContainer'>
                <ActivityLoaderMintbox showQuote={true} />
            </div>
        </React.Fragment>
    } else if (loading && isWidget) {
        return (
            <div>
                <ActivityLoaderMintbox showQuote />
            </div>
        )
    }
    else {
        // var numb = String(selectedStockUniverse)?.match(/\d/g);
        // numb = numb.join("");
        return (
            <React.Fragment>
                {contextHolder}
                {isCloned && <Alert style={{ marginBottom: 20 }}
                    message={<span className="textESM w-500 dark4">
                        This model has already been cloned by you. You can make changes to this model and save multiple versions of this model.
                    </span>}
                    type="info"
                    showIcon
                    closable
                />}
                {!isWidget && <Head title="Stocks Factor Model" description="sharpely offers factor models, which are financial tools that help investors identify and manage investment characteristics influencing the risks and returns of stocks and portfolios." />}
                {(isWidget === false && strategy_fundD === undefined) && <AccessControlledPage moduleKey={fromStrategyCreation ? "BUILD,_BACKTEST_AND_PAPERTRADE" : isLocked ? "SAVE_MODELS_AND_SET_ALERTS" : 'BUILD_AND_BACKTEST_CUSTOM_MODELS'}
                    isDefaultOpen={isLocked} subModalParams={isLocked ? {
                        isLimitType: true,
                        customAccessObj: {
                            ModuleName: "Factor model",
                            ModuleDesc: `This factor model is locked. With your current plan ${modelDetailedAccess?.maxLimit === 0 ? 'you cannot view any of your saved factor models. To view your factor models, upgrade your plan.' : `you can only view your first ${modelDetailedAccess?.maxLimit} saved factor models. 
                            To view your other factor models, upgrade your plan.`}`
                        }
                    } : {}} />}
                <div className='normalContainer'>
                    {/* <ClosableHeader heading={`${ type.dname } Signal`} /> */}
                    {isWidget == false && AppliedFilterView()}

                    {newFiltersScoreList?.length > 0 && newFiltersList?.length > 0 && allFilteredFundsData?.length > 0 ?
                        <InstrumentListTable
                            isCard={isWidget ? false : true}
                            finalList={allFilteredFundsData}
                            tableType={3}
                            hideCheckBox={true}
                            // istableLoading={tableLoading}
                            hideInvestBtn={true}
                            defaultInsType={type?.value}
                            hideSettings={true}
                            // disableInvestBtn={allFilteredFundsData.length < 5}
                            otherCol={(newFiltersScoreList.length > 0 ? [{
                                "dataIndex": "agg_z_score_val",
                                "key": "agg_z_score_val",
                                'disableDeleteInCustomize': true,
                                'className': 'blueBackground w-700',
                                "metric_col_code": "agg_z_score_val",
                                "metric_unit": "",
                                "metric_data_type": "float",
                                "metric_name": "Aggregated score",
                                "title": "Aggregated score",
                                "align": "right",
                                "width": 100,
                                "sorter": (a, b) => a.agg_z_score_val - b.agg_z_score_val,
                                "render": (text, record, index) => <div>
                                    {displayScoreValue({
                                        text, metricObj: {
                                            "dataIndex": "agg_z_score_val",
                                            "key": "agg_z_score_val",
                                            "metric_col_code": "agg_z_score_val",
                                            "metric_unit": "",
                                            "metric_data_type": "float",
                                            "metric_name": "Aggregated score",
                                            "title": "Aggregated score",
                                        }
                                    })}
                                </div>

                            }] : []).concat([
                                ...newFiltersScoreList,
                                // ...newFiltersList.map((i) => { return { ...i, ...metric_data('metric_col_code', i?.metric_col_code), } })
                            ])}

                            // initialColumns={tableColumns.map((i) => i.metric_col_code)}
                            tableHeader={<span style={{ display: "flex", flexDirection: "column" }}>
                                {strategy_fundD !== undefined ? <>
                                    <span>
                                        Latest stocks that qualify your stock selection model
                                    </span>
                                    <span className="textXS w-500 textLight">
                                        {signal_funds?.instruments?.length > 0 ? "as on " +
                                            formatDateWithTime(signal_funds?.timestamp) : ""}
                                    </span>
                                    <span className="textXS w-500 textLight">
                                        Note that list of stocks shown here may not match with your current holding as your portfolio was last rebalanced on
                                        &nbsp;{formatDateMMMDDYYYY(strategy_fundD?.config?.last_rebal_date)}
                                    </span>
                                </> : <>
                                    <span>
                                        Factor scores of {allFilteredFundsData.length} stocks
                                    </span>
                                    <span className="textXS w-500 textLight">
                                        {signal_funds?.instruments?.length > 0 ? "as on " +
                                            formatDateWithTime(signal_funds?.timestamp) : ""}
                                    </span>
                                </>}
                            </span>}
                            sortColKey='agg_z_score_val'
                            investInScreener={(rowSelection) => {
                                // if (rowSelection.length < (type.value === 4 ? 5 : 2)) {
                                //     toast.error(<span className='textSM dark3'>To invest in signal <br></br>Select <span className='black w-500'>Min. {type.value === 4 ? "5" : "2"}</span> or <span className='black w-500'>Max. {type.value === 4 ? "50" : "10"}</span> {type.dname}</span>)
                                // } else {
                                //     setinstrumentList(rowSelection)
                                setinvestInScreenerModal(!investInScreenerModal)
                                // }
                            }}
                            downloadTable={param_fmUserId === user?.id}
                            fileDefaultName={signal_name || "New factor model"}
                            downloadFileAccessKey={type.value === 4 ? 'DOWNLOAD_STOCK_FACTOR_MODEL_LIST' : "DOWNLOAD_MF_ETF_FACTOR_MODEL_LIST"}
                        /> : strategy_fundD === undefined && isWidget === false && <div className="mt-5" style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                            {/* <Divider className="mt-5 mb-3" />
                            <CardHeader
                                heading={"Explore factor models created by sharpely community"}
                            />
                            <div className="textSM textLight">Explore models created by our community experts including SEBI registered entities. If you like a model, you can clone it and save as your own model.</div>
                            <div className="mt-4" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: "1.5rem" }}>
                                {allPublishedFactorModels?.map((item, index) => {
                                    var publisherD = allPublishers?.filter((i) => i?.ClientId === item?.client_id)?.[0]
                                    if (publisherD?.IsApproved === 1)
                                        return <Card
                                            onClick={() => {
                                                // navigate("/stocks/factor-model", { state: { signalData: item } })
                                                navigate(`/stocks/factor-model?fmName=${item?.signal_name}&fmId=${item?.signal_id}&userId=${item?.client_id}`)
                                                // navigate(`${ type.basePath } / super- screener ? screenName = ${ item?.screen_name?.replaceAll(' ', '-')}& screenId=${item?.screen_id}&userId=${publisherD?.ClientId || 0}`)
                                            }}
                                            hoverable bordered>
                                            <div className='' style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                                <Meta
                                                    style={{ flex: 1, gap: 0 }}
                                                    avatar={<Avatar src={publisherD?.PublisherIcon} />}
                                                    title={<span className='textSM textEllipsis1'>{item?.signal_name}</span>}
                                                    description={<div className='textXS textLight'>By {<Link style={{
                                                        color: "var(--primary)", cursor: "pointer",
                                                    }} to={`/publisher-profile?name=${publisherD?.Name?.replaceAll(" ", "-")}&id=${publisherD?.UID}`} onClick={(e) => { e.stopPropagation() }}>{String(publisherD?.Name)?.substring(0, 20)}{publisherD?.Name?.length > 15 ? "..." : ""}</Link>} on {formatDateMMMDDYYYY(item?.created_on)}</div>}
                                                />

                                            </div>
                                            <div className="textXS textEllipsis3">
                                                {item?.screen_desc}
                                            </div>
                                        </Card>
                                    else
                                        return null
                                })}
                            </div > */}
                        </div >
                    }
                </div >

                <Drawer
                    width={isMobile ? "100%" : "87vw"}
                    closable={false}
                    title={false} placement="right"
                    onClose={() => {
                        setmodal(!modal)
                        settoggleMetricBackTest(false)
                    }}
                    open={modal}
                    footer={false}
                    styles={{ body: { padding: isMobile ? 0 : '1.25rem' } }}>
                    <Card
                        actions={[<div style={{ display: "flex", gap: "0.5rem", alignItems: "center", justifyContent: "flex-end", paddingRight: "1rem" }}><button className="btnWhite" onClick={() => {
                            setmodal(!modal)
                            settoggleMetricBackTest(false)
                        }}>Cancel</button>,
                            <button disabled={nextDisabled} className="btnBlack" onClick={() => {
                                var upDatedFilters = [...checkFiltersList]
                                setmodal(!modal)
                                settoggleMetricBackTest(false)
                                // setnewFiltersList(upDatedFilters)
                                allocate(upDatedFilters, 2)
                                // var isPresentIndex = upDatedFilters.findIndex((met) => met.metric_col_code === selectedm?.metric_col_code)
                                // if (isPresentIndex === -1) {
                                //     upDatedFilters.push({
                                //         ...selectedm,
                                //         disableDeleteInCustomize: true,
                                //         metric_col_code: selectedm?.metric_col_code,
                                //         value: selectedm?.metricDefVal,
                                //         pervalue: selectedm?.pervalue,
                                //         queryValue: selectedm?.queryValue,
                                //     })

                                // } else {
                                //     alert("Metric already added")
                                // }
                            }}>Run model</button></div>]}

                        bodyStyle={{ height: "100%", padding: 0, overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%" }}
                        bordered>
                        <div style={{ display: "flex", flex: 1, flexDirection: "column", width: "100%", height: "100%" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "1rem", gap: '1rem' }}>
                                {!isMobile && <CardHeader heading={'Select Metric'} hideHeaderBlueLine headingType={2} />}
                                <QueryOptionsDropdownV2 options={optionsForSearch} placeholder='Search Metrics' width={isMobile ? "100%" : '20rem'}
                                    onSelect={(it) => {
                                        onMetricSearch(it);
                                    }} dropdownPositions={{ right: "0%" }} tabs={[
                                        { key: "All", label: "All", value: "" },
                                        { key: "Fundamental Metrics", label: "Fundamental Metrics", value: "Metric F" },
                                        { key: "Technical Metrics", label: "Technical Metrics", value: "Metric T" },
                                    ]} />
                                <IoIosClose onClick={() => {
                                    setmodal(!modal)
                                    settoggleMetricBackTest(false)
                                }}
                                    size={"1.75rem"} style={{ cursor: "pointer" }} />
                            </div>
                            <div style={{ flex: 1, overflowY: "scroll", display: "flex", width: "100%" }}>
                                {(!showSelectedMetrics || !isMobile) &&
                                    <>
                                        <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", paddingRight: 0, overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                                            {screenerFinalData.map((item, index) => {
                                                if (item.isVisibleNormally === true) {
                                                    return renderRow({
                                                        item, index
                                                    })
                                                } else {
                                                    return <></>
                                                }
                                            }
                                            )}
                                        </Card>
                                        <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 1.1, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                                            <RenderIntialFlatlist item={screenerFinalData.filter((i) => i.category_id === selectedCategory)[0]} />
                                        </Card>
                                    </>
                                }
                                {
                                    (showSelectedMetrics || !isMobile) &&
                                    <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", overflowY: "scroll", padding: 0 }} style={{ display: "flex", flexDirection: "column", flex: 2.7, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                                        <CheckedFilterView />
                                    </Card>
                                }
                            </div>
                        </div>
                    </Card>

                    <Drawer
                        width={isMobile ? "100%" : '65vw'}
                        title={null} placement="right"
                        onClose={() => {
                            //setmodal(!modal)
                            settoggleMetricBackTest(false)
                        }}
                        closable={false}
                        open={toggleMetricBackTest}
                        styles={{ footer: { textAlign: 'right' } }}
                        footer={<button className="btnBlack ms-auto" onClick={() => {
                            settoggleMetricBackTest(false)
                        }}>Close</button>}>
                        <div className="">
                            <CardHeader heading={`Historical metric performance`} hideHeaderBlueLine headingType={2} />
                            <div style={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "1.25rem 0rem" }}>
                                <span>
                                    <CustomSegmentedTab
                                        isBlock={false}
                                        options={metricPerfOptions}
                                        value={metricPerfCur}
                                        onChange={(e) => setmetricPerfCur(e)}
                                    />
                                </span>
                                {metricPerfOptions?.filter((i) => i.value === metricPerfCur)?.[0]?.children}
                            </div>
                        </div>
                    </Drawer>

                    <Drawer
                        width={isMobile ? "100%" : '65vw'}
                        title={null} placement="right"
                        onClose={() => {
                            //setmodal(!modal)
                            settoggleDesc(false)
                        }}
                        closable={false}
                        open={toggleDesc}
                        styles={{ footer: { textAlign: 'right' } }}
                        footer={<button className="btnBlack ms-auto" onClick={() => {
                            settoggleDesc(false)
                        }}>Close</button>}>
                        <div className="">
                            {/* <CardHeader heading={`Metric desc`} hideHeaderBlueLine headingType={2} /> */}
                            <DrawerMetricDescComp />
                        </div>
                    </Drawer>
                </Drawer>

                <SaveSignalModal signal_name={signal_name} signal_desc={signal_desc} modalSave={modalSave} setmodalSave={setmodalSave} alertsEnabled={alertsEnabled} saveSignal={saveSignal}
                    setSavingSignal={setSavingSignal} savingSignal={savingSignal} totalSignals={totalSignals} isUpdating={signal_id !== 0} subscribeMiddle={subscribeMiddle} />

                {
                    backTestModal && <Drawer
                        width={isMobile ? "100%" : "90vw"}
                        closable={false}
                        // title={"Backest or Paper trade your screen"}
                        placement="right"
                        onClose={() => {
                            setbackTestModal(!backTestModal)
                            setviewType("backtest")
                        }}
                        open={backTestModal}
                        footer={false}>
                        <div id="backtest-modal-main-container" style={{ display: "flex", flexDirection: "column", gap: "1.25rem", flex: 1, height: "100%" }}>
                            <span style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                                <CardHeader heading={(strategy_fundD == undefined && param_fmUserId === user?.id) ? 'Backest or Paper trade your factor model' : "Backtest results"} hideHeaderBlueLine headingType={2} />
                                {!((JSON.stringify(backtestConfig) === JSON.stringify(updatedConfig) && start_date2 === start_date && end_date2 === end_date)) && viewTypePendingNewRun > 1 ? <button className='btnBlack'
                                    onClick={(e) => {
                                        if (viewType === "backtest")
                                            onBacktestInpDone(updatedConfig, start_date2, end_date2);
                                        else
                                            setregenerateOrder(true)
                                    }}>
                                    {viewType === "backtest" ? "Re run backtest" : "Regenerate orders"}
                                </button> : viewTypePendingNewRun === 1 && viewType === "backtest" ? <button className='btnBlack'
                                    onClick={(e) => {
                                        if (viewType === "backtest")
                                            onBacktestInpDone(updatedConfig, start_date2, end_date2);
                                        else
                                            setregenerateOrder(true)
                                    }}>
                                    {viewType === "backtest" ? "Re run backtest" : "Regenerate orders"}
                                </button> : <></>}
                                <button className='btnBlack' onClick={() => {
                                    setbackTestModal(!backTestModal)
                                    setviewType("backtest")
                                }}>Close</button>
                            </span>
                            {(strategy_fundD == undefined && param_fmUserId === user?.id) && <ScreenBacktestInpModal
                                type={type}
                                startDate={start_date}
                                endDate={end_date}
                                viewType={viewType}
                                initialConfig={{
                                    ...updatedConfig,
                                    ...{
                                        ...signal_config,
                                        'position_sizing': {
                                            ...updatedConfig?.position_sizing,
                                            ...signal_config?.position_sizing,
                                            'limit': {
                                                'by': 'agg_z_score_val',
                                                'top': signal_config?.position_sizing?.limit?.top || 30,
                                                'sort': 'desc'
                                            }
                                        }
                                    }, 'instrument_selection': 3
                                }}
                                screenerMetrics={screenerMetric}
                                variables={variables}
                                benchmarks={benchmarks}
                                parsedCode={{
                                    globals: globals,
                                    variables: variables,
                                    conditions: conditions,
                                    conditions_oper: conditions_oper
                                }}
                                onDone={(config, start_date, end_date, parsedCode) => {
                                    setupdatedConfig(config)
                                    setstart_date2(start_date)
                                    setend_date2(end_date)
                                    setviewTypePendingNewRun(2)
                                    if (JSON.stringify(parsedCode?.globals) !== JSON.stringify(globals))
                                        setGlobals(parsedCode?.globals)
                                }} />}
                            {backTestModalLoading && backTestPerfData?.length === 0 ? <div style={{
                                height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", alignContent: "center", justifyItems: "center"
                            }}>
                                <img width='10rem' height='10rem' style={{
                                    height: "10rem",
                                    width: "10rem",
                                }} src={require('Assets/lottie/chart-load.gif')} alt="loading..." />
                                <CustomChangingTextLoader
                                    otherText={["Generating signals...", "Running back test...", "Fetching results..."]}
                                />
                            </div> :
                                <SuperScreenerBacktestAndPaperTrade type={type} isAdvance={true} allFundsData={allFundsData} isPaperTradeAllowed={(strategy_fundD == undefined && param_fmUserId === user?.id)} onBacktestPressed={() => {
                                    setviewType("backtest")
                                }}
                                    viewType={viewType}
                                    onPaperTradePressed={onPaperTrade} regenerateOrder={regenerateOrder} initial_capital={backtestConfig?.initial_capital} backTestModalLoading={backTestModalLoading} isSuperScreener={true} chartData={backTestPerfData} backTestData={backTestData} backtestType='screener'
                                />
                            }
                        </div>
                    </Drawer>
                }
                {/* <Drawer
                    width={"90vw"}
                    closable={false}
                    title={null}
                    placement="right"
                    onClose={() => {
                        setbackTestModal(!backTestModal)
                    }}
                    open={backTestModal}
                    footer={false}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem" }}>
                        <span style={{ display: "flex", alignItems: "center" }}><CardHeader heading={'Historical Factor Model performance'} hideHeaderBlueLine
                            headingType={2} />
                            <button className='btnBlack' onClick={() => {
                                setbackTestModal(!backTestModal)
                            }}>Close</button></span>
                        <ScreenerOrSignalBacktest backTestModalLoading={backTestModalLoading} backTestData={backTestData} xAxisKeyForPerfChart='date' backtestType='signal' />
                    </div>
                </Drawer> */}

                {
                    investInScreenerModal && <Drawer
                        width={isMobile ? "100%" : "85vw"}
                        closable={false}
                        placement="right"
                        onClose={() => {
                            setinvestInScreenerModal(!investInScreenerModal)
                        }}
                        open={investInScreenerModal}
                        styles={{
                            footer: {
                                display: "flex",
                                justifyContent: "flex-end"
                            }
                        }}
                        footer={<div>
                            <button className="btnWhite" onClick={() => {
                                setinvestInScreenerModal(!investInScreenerModal)
                            }}>
                                Close
                            </button>
                        </div>}>
                        <div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CardHeader heading={'Build a factor model based basket'} hideHeaderBlueLine headingType={2} />
                                <div className='helpText' onClick={() => {
                                    dispatch(toggleUserGuideModal({
                                        value: true, params: {
                                            title: 'How to create a factor model based dynamic stock basket ?',
                                            article: 'creating-a-dynamic-basket-using-factor-models'
                                        }, type: 'kbArticle'
                                    }))
                                }}>How to create a screen-based stock basket?</div>
                            </div>
                            <div style={{ padding: '1.25rem 0rem' }}>
                                <BuildCustomPortfolio
                                    key={Math.random()}
                                    isModalView={true}
                                    instrumentList={[...instrumentList]}
                                    allFilteredFundsData={[...allFilteredFundsData]}
                                    instrument_method={3}
                                    signal_id={signal_id}
                                    query={{
                                        'Universe': selectedStockUniverse[0],
                                        'MetricCols': newFiltersList.map((i) => i.metric_col_code),
                                        'IsExclusionList': excludeSurveillance ? 1 : 0,
                                        'DirectionFlags': newFiltersList.map((i) => (i.filter || i.direction_flag) === "top" ? 1 : -1),
                                        'Weights': newFiltersList.map((i) => i.allocation / 100),
                                        'Top': numOfFunds,
                                        "SignalId": signal_id
                                    }}
                                    newFiltersList={newFiltersList}
                                    // tableColumns={tableColumns}
                                    screenerMetric={screenerMetric}
                                />
                            </div>
                        </div>
                    </Drawer>
                }

                <Modal open={openInvModal} onCancel={() => setOpenInvModal(false)} onOk={() => setOpenInvModal(false)} footer={<button className='btnBlack'
                    onClick={() => setOpenInvModal(false)}>
                    Close</button>}>
                    <div className='d-flex align-items-center flex-column' style={{ gap: "2rem", marginTop: "2rem" }}>
                        {/* <img loading="lazy"  alt="" src={noData} style={{ width: "8rem", height: "8rem" }} /> */}
                        <div className='textMD text-center'>
                            We do not have sufficient historical data to show back tested performance of this metric. Note that if you use this metric in your factor model, back tested performance of factor model will also not be available.
                        </div>
                    </div>
                </Modal>
                <Modal open={deleteSignalModal} title='Delete factor model' footer={null} onCancel={() => setDeleteSignalModal(false)} onOk={() => setDeleteSignalModal(false)} >
                    <div className='textSM dark4 mt-3'>
                        Are you sure you want to delete <q><b>{signal_name}</b></q> factor-model?
                    </div>
                    <div className='d-flex justify-content-end align-items-center' style={{ gap: '1.25rem', marginTop: "1rem" }}>
                        <button className='btnWhite' onClick={() => setDeleteSignalModal(false)}>Don't delete</button>
                        <button className='btnBlack' style={{ background: "var(--red)", border: "unset" }} onClick={deleteSignal}>Yes delete</button>
                    </div>
                </Modal>
                <BacktestNotAvailable />


                {
                    user?.id <= 7 && <Collapse style={{ marginTop: 20, width: "100%", background: "transparent", boxShadow: '0.1px 0.1px 10px 0px var(--grey1)' }} bordered={false} expandIconPosition='end'
                        className='screenerCollapseCont' expandIcon={({ isActive }) => <IoIosArrowDown style={{ transform: isActive ? "rotate(180deg)" : "" }} size='1.25rem'
                            color='var(--dark4)' />}
                        items={[{
                            key: 0,
                            label: <div style={{ display: "flex", backgroundColor: "var(--white)", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                <div className="textLG w-700">Developer Views</div>
                            </div>,
                            style: { backgroundColor: "var(--white)", borderTop: "none" },
                            children: <div>
                                < div style={{ display: "flex", flexDirection: "row", flex: 1, gap: 20 }}>
                                    <Card style={{ flex: 1, gap: 10 }}>
                                        <span>{signal_config?.code?.replaceAll(";", ";\n")?.split("\n")?.map((line) => {
                                            return <span key={makeid(3)}>{line}<br></br></span>
                                        })}</span>

                                    </Card>
                                </div>


                                < div style={{ display: "flex", flexDirection: "row", flex: 1, gap: 20 }}>
                                    <Card style={{ flex: 1, gap: 10 }}>
                                        <span>{strategy_fundD?.config?.code?.replaceAll(";", ";\n")?.split("\n")?.map((line) => {
                                            return <span key={makeid(3)}>{line}<br></br></span>
                                        })}</span>

                                    </Card>
                                </div>







                                <br></br>
                                <br></br>
                                <hr></hr>
                                <div id="resOut"></div>
                            </div>
                        }]} />
                }
            </React.Fragment>
        );
    }

}

export default React.memo(FactorModel)