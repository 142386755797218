import { useIsMobile } from "CustomHooks/useIsMobile";
import { makeid } from "Libs/utils";
import { VideoCard } from './VideoCard';

export const VideoSlider = ({ videos }) => {

    const isMobile = useIsMobile();

    return (
        <div style={{ width: '100%', maxWidth: '100%' }}>
            <div style={{ display: 'grid', gridTemplateColumns: `repeat(${videos?.length}, ${isMobile ? "85%" : "24rem"})`, gap: isMobile ? "1.25rem" : '2rem', overflowX: 'scroll', maxWidth: '100%', overflowY: "hidden" }} className="padLeftCampaign padRightCampaign">
                {
                    videos?.map((vid, i) => {
                        return <VideoCard videoItem={vid} key={makeid(i + 1)} />
                    })
                }
            </div>
        </div>
    )
}