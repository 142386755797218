import { Card, Skeleton } from "antd";
import { displayScoreValue } from "Libs/utils";
import { StockLivePrice } from "Services/api/LivePricesWebSocket";
import AMCIcon from "./AMCIcon";
import { CurrencyFormat } from "./CurrencyFormat";
import { DecimalValueFormat } from "./DecimalValueFormat";
import Slider52Week from "./Slider52Week";
import { WatchList } from "./Watchlist";
import OHLC from "./OHLC";
import { GetLTP } from "Services/api/LivePricesWebSocketOnlyVal";

const NewFundCard = ({ f, type, onClick, showQVM, cardBg = 'var(--blue2)', bordered = false, loading }) => {

    const ltp = type.value === 4 ? GetLTP({ fundData: f, symbol: f.nse_symbol || f.symbol }) : f.latest_nav
    const ItemLoader = () => (
        <div style={{
            padding: "1rem", background: cardBg, minWidth: "240px", minHeight: "6rem", borderRadius: "var(--borderRadius)", position: "relative",
            border: bordered ? "1px solid var(--grey2)" : "none"
        }}>
            <div className='d-flex flex-column justify-content-center align-items-center text-center w100' style={{ gap: "1rem", overflow: "hidden" }}>
                <Skeleton.Avatar active style={{ width: "3.125rem", height: "3.125rem" }} />
                <Skeleton.Button active style={{ height: "0.75rem", width: '170px' }} />
                <Skeleton.Button active style={{ height: "0.75rem", width: '120px' }} />
            </div>
            <div className='d-flex align-items-center justify-content-between' style={{ marginTop: "1.25rem" }}>
                <Skeleton.Button active style={{ height: "0.75rem", width: '50px' }} />
                <Skeleton.Button active style={{ height: "0.75rem", width: '50px' }} />
            </div>
            <div className='d-flex align-items-center justify-content-between' style={{ marginTop: "0.5rem" }}>
                <Skeleton.Button active style={{ height: "0.75rem", width: '50px' }} />
                <Skeleton.Button active style={{ height: "0.75rem", width: '50px' }} />
            </div>
        </div>
    )

    if (loading) return <ItemLoader />

    return <Card bodyStyle={{ cursor: 'pointer', backgroundColor: cardBg, width: window.innerWidth > 760 ? '240px' : '220px', height: '100%', borderRadius: 'var(--borderRadius)' }}
        onClick={onClick} style={{ border: bordered ? "1px solid var(--grey2)" : "none" }}>
        <div style={{ position: 'absolute', top: '0.75rem', right: '0.75rem' }} onClick={(e) => e.stopPropagation()}>
            {/* {
                false ?
                    <BsFillBookmarkFill style={{ fontSize: '1.2rem', color: 'var(--primary)', cursor: 'pointer' }} />
                    :
                    <BsBookmark className="dark3" style={{ fontSize: '1.2rem', cursor: 'pointer' }} />
            } */}
            <WatchList type={type.value} instrument={f} size={'1.2rem'} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.688rem', justifyContent: 'space-between', height: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '0.688rem' }}>
                <AMCIcon amcName={type.value === 4 ? f.symbol : f.amc_full_name} fundType={type.value} height='3.125rem' width="3.125rem" bseTicker={f?.bse_ticker} />
                <div style={{ marginTop: '0.688rem', textAlign: 'center', minHeight: "2rem" }} className="textXS w-500 black">{type.value === 4 ? f.proper_name : f.basic_name}</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '0.688rem' }}>
                {/* <Slider52Week fundData={f} high={f?.['52w_high']} low={f?.['52w_low']} symbol={type.value === 4 ? f.symbol : f.nse_symbol}
                    type={type.value} mfValue={f.latest_nav} /> */}
                <OHLC high={Math.max(ltp, f?.['52w_high'])} low={Math.min(ltp, f?.['52w_low'])} close={ltp} />
                {
                    type.value === 1 || type.value === 4 ?
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <div className="textXS w-500 black" style={{ whiteSpace: 'nowrap' }}>Market price</div>
                            <StockLivePrice fundData={f} symbol={f.nse_symbol || f.symbol} format={3} />
                        </div>
                        :
                        type.value === 2 ?
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <div className="textXS w-500 black" style={{ whiteSpace: 'nowrap' }}>NAV</div>
                                <div className="textSM w-500 black">{CurrencyFormat(f?.latest_nav, "long")}</div>
                            </div>
                            :
                            <></>
                }
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: "center" }}>
                    <div className="textXS w-500 black" style={{ whiteSpace: 'nowrap' }}>{showQVM ? "QVM score" : "CAGR (5y)"}</div>
                    <div className="textXS w-500 black">
                        {showQVM ? displayScoreValue({ text: f?.sh_3fac_comp }) : DecimalValueFormat({ num: showQVM ? f?.sh_3fac_comp : type.value === 4 ? f.five_yr_pct : f.cagr_5y, suffix: showQVM ? "" : '%' })}
                    </div>
                </div>
            </div>
        </div>
    </Card>
}

export default NewFundCard;