import { Badge, Divider, FloatButton, Skeleton, Table, Tag } from 'antd';
import { microphone, noInvestment, portfolioSnapCard } from 'Assets/images';
import AccessControlledPage from 'Components/AccessControlledPage';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { CardHeader } from 'Components/atoms/SmallComponents';
import TooltipCloseOnClick from 'Components/atoms/TooltipCloseOnClick';
import { CurrencyFormat } from 'Components/CurrencyFormat';
import CustomDrawer from 'Components/CustomDrawer';
import CustomLabelValueCards from 'Components/CustomLabelValue';
import CustomSegmentedTab from 'Components/CustomSegmentedTab';
import CustomTabs from "Components/CustomTabs";
import { filterByKeys, joinAndSum, matchObjects } from 'Components/DataCustomizer';
import { formatDate } from 'Components/DateFormatter';
import { DecimalValueFormat } from 'Components/DecimalValueFormat';
import { NoDataFound } from 'Components/NoDataFound';
import RedGreenText from 'Components/RedGreenText';
import SharpelyGateway2 from "Components/SharpelyGateway/SharpelyGateway2";
import SIPCard from 'Components/SIPCard';
import MyTable from 'Components/TableWithScroll';
import Head from 'Layout/Head';
import { fetchFundData } from "Libs/fetchFundData";
import { setFilterAppFrNav, setOpenSearchModal } from 'Libs/redux/Modals/reduxSlice';
import { useGetAssetType } from 'Libs/utils';
import React from 'react';
import { BiRefresh } from 'react-icons/bi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import apiHelper from 'Services/api/ApiHelper';
import { Dashboard_Portfolio } from 'Services/api/FabricatedAPIS';

export default function Portfolio(props) {

    let initialAlloc = [
        { "y": 0, "name": "ETFs", "labelColor": "#000", "color": "var(--primary)", "balance": 0 },
        { "y": 0, "name": "Mutual Funds", "labelColor": "#000", "color": "var(--yellow)", "balance": 0 },
        { "y": 0, "name": "Stocks", "labelColor": "#000", "color": "var(--red)", "balance": 0 }
    ]
    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams()
    const [xirr, setXIRR] = React.useState([]);
    const [portfolioSnap, setportfolioSnap] = React.useState({});
    const [portfolioHoldings, setPortfolioHoldings] = React.useState({})
    const [instrumentAllocation, setInstrumentAllocation] = React.useState(initialAlloc);
    const [insAllocLoad, setInsAllocLoad] = React.useState(true);
    const [transactions, setTransactions] = React.useState([]);
    const [scheduledOrders, setScheduledOrders] = React.useState([]);
    const [portfolioSnapLoading, setportfolioSnapLoading] = React.useState(true);
    const [portfolioXIRRloading, setportfolioXIRRloading] = React.useState(true);
    const [portfolioHoldingsLoading, setPortfolioHoldingsLoading] = React.useState(true);
    const [transactionsLoading, setTransactionsLoading] = React.useState(true);
    const [scheduledOrdersLoading, setScheduledOrdersLoading] = React.useState(true);
    const [orders, setOrders] = React.useState([]);
    const [ordersLoading, setOrdersLoading] = React.useState(true);
    const [allStocks, setAllStocks] = React.useState([]);
    const [allEtfs, setAllEtfs] = React.useState([]);
    const [allMintboxes, setAllMintboxes] = React.useState([]);
    const [showItemInsights, setShowItemInsights] = React.useState({});
    const stockType = useGetAssetType(4);
    const etfType = useGetAssetType(1);
    const mfType = useGetAssetType(2);
    const basketType = useGetAssetType(3);
    const navigate = useNavigate();
    const isMobile = window.innerWidth <= 760;

    let user = useSelector((state) => state?.updateUserData?.value)

    const finalInstbg = [
        {
            id: 1,
            name: 'ETFs',
            color: 'var(--primary)'
        },
        {
            id: 2,
            name: 'Mutual Funds',
            color: 'var(--yellow)'
        },
        {
            id: 4,
            name: 'Stocks',
            color: 'var(--red)'
        }
    ];

    const finalbg = [
        {
            id: 1,
            name: 'Indian equity',
            color: 'var(--primary)'
        },
        {
            id: 2,
            name: 'Gold',
            color: 'var(--red)'
        },
        {
            id: 3,
            name: 'Foreign equity',
            color: 'var(--green)'
        },
        {
            id: 4,
            name: 'Debt',
            color: 'var(--yellow)'
        },
        {
            id: 5,
            name: 'Cash',
            color: 'var(--blueShade)'
        },
        {
            id: 6,
            name: 'Silver',
            color: 'var(--grey1)'
        }
    ];

    const getInstrumentAlloc = (data = [], portfolioSnap) => {
        var aa = data || []
        var ak = []
        var holder = {
            1: 0, 2: 0, 4: 0
        };
        if (data === null) {
            setInstrumentAllocation(initialAlloc)
        } else {
            aa?.forEach(function (d) {
                if (holder.hasOwnProperty(d.FundType)) {
                    holder[d.FundType] = holder[d.FundType] + d.CurrentBalance;
                } else {
                    holder[d.FundType] = d.CurrentBalance;
                }
            });
            console.log(holder)
            var obj2 = [];

            for (var prop in holder) {
                obj2.push({ name: prop, value: holder[prop] });
            }
            obj2.map((a, index) => {
                var per = (a.value) / portfolioSnap?.Portfolio?.PortfolioBalance
                per = per * 100
                var clr = finalInstbg?.find((el) => el?.id === Number(a?.name))?.color
                var dd = {
                    y: Number(Number(per).toFixed(2)) > 100 ? 100 : Number(Number(per).toFixed(2)),
                    name: finalInstbg?.find((el) => el?.id === Number(a?.name))?.name,
                    labelColor: '#000',
                    color: clr,
                    balance: a?.value
                }
                ak.push(dd)
            })
            console.log(ak)
            ak.sort((a, b) => b?.balance - a?.balance)
            setInstrumentAllocation(ak)
            setInsAllocLoad(false);
        }
    }

    const resetData = () => {
        setportfolioSnapLoading(true);
        setInsAllocLoad(true);
        setportfolioXIRRloading(true);
        setPortfolioHoldingsLoading(true);
        setTransactionsLoading(true);
        setScheduledOrdersLoading(true);
        setOrdersLoading(true);
    }
    const loadData = async (fresh = false) => {
        //getPortfolioSnapData
        resetData()
        var portfolioSnapshot = await Dashboard_Portfolio.getPortfolioSnapData(user, fresh).then((res) => {
            console.log(res);
            setportfolioSnap(res)
            setportfolioSnapLoading(false)
            return res
        })
        const fetchData7 = async () => {
            try {
                let res = await apiHelper({ apiName: 'getOrderBook', data: { clientId: user?.id, broker: user?.Client?.[0]?.Broker }, saveResponse: '' });
                if (res) {
                    setOrders(res?.orders || []);
                }
                setOrdersLoading(false);
                return res;
            }
            catch (err) {
                return err;
            }
        }
        let orders = await fetchData7();
        if (!(portfolioSnapshot?.Portfolio?.PortfolioBalance === 0 || portfolioSnapshot?.Portfolio?.PortfolioBalance === undefined) || orders?.orders?.length > 0) {
            const fetchData2 = async () => {
                let res = await Dashboard_Portfolio.getPortfolioXIRR(user, fresh).then((res) => {
                    setXIRR(res)
                    setportfolioXIRRloading(false);
                    return res;
                })
                return res;
            }

            const fetchData3 = async () => {
                let res = await Dashboard_Portfolio.getPortfolioHoldingsData(user, fresh).then((res) => {
                    setPortfolioHoldings(res)
                    getInstrumentAlloc(res?.CustomAll, portfolioSnapshot)
                    return res;
                });
                let res2 = await fetchFundData(4);
                if (res2) {
                    setAllStocks(res2);
                }
                setPortfolioHoldingsLoading(false);
                return { holdings: res, stocks: res2 };
            }

            const fetchData4 = async () => {
                let res = await Dashboard_Portfolio.getPortfolioTransaction(user, fresh).then((res) => {
                    console.log('getPortfolioTransaction', res)
                    setTransactions(res);
                    setTransactionsLoading(false);
                });
                return res;
            }

            const fetchData5 = async () => {
                let res = await Dashboard_Portfolio.getPortfolioScheduledOrders(user, fresh).then((res) => {
                    console.log('getPortfolioScheduledOrders', res)
                    setScheduledOrders(res);
                    setScheduledOrdersLoading(false);
                });
                return res;
            }

            const fetchData8 = async () => {
                let res2 = await fetchFundData(1);
                if (res2) {
                    setAllEtfs(res2);
                }
                let res3 = await fetchFundData(3, false, user?.id);
                if (res3) {
                    setAllMintboxes(res3)
                }
                return { etfs: res2, baskets: res3 };
            }

            let responses = await Promise.allSettled([
                fetchData2(), fetchData3(), fetchData4(), fetchData5(), fetchData8()
            ]);

            console.log(responses);
        }
    };

    const refreshData = async () => {
        resetData()
        loadData(true);
    };

    React.useEffect(() => {
        loadData()
    }, [user]);

    const NoInvestment = ({ name, id = 1, type }) => {
        if (id === 1) {
            return (
                <React.Fragment>
                    <div className='mainContainer'>
                        <div className='normalContainer'>
                            <div className='d-flex align-items-center justify-content-center' style={{
                                gap: isMobile ? "1.25rem" : "3rem",
                                flexDirection: isMobile ? "column" : "row", marginTop: isMobile ? "" : "6rem"
                            }}>
                                <div style={{ flexShrink: 0 }}>
                                    <img loading="lazy"  alt="" src={noInvestment} style={{ maxWidth: "100%", width: isMobile ? "20rem" : "20rem", height: "auto" }} />
                                </div>
                                <div style={{ textAlign: isMobile ? "center" : "" }}>
                                    <div className='textLG dark4 w-700'>Your dashboard awaits!</div>
                                    <div className='textSM w-500 dark3 mt-2' style={{ maxWidth: "60ch" }}>
                                        It's currently empty, but fear not. Begin your investing adventure today with sharpely. Discover a world of investment possibilities with stocks, mutual funds, ETFs, and baskets. Get ready to seize new financial opportunities and shape your wealth with sharpely.
                                    </div>
                                    <button className='btnBlack mt-2 ' onClick={() => {
                                        dispatch(setOpenSearchModal(true));
                                    }}>Explore Instruments</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        else if (id === 2) {
            return (
                <div className='d-flex align-items-center justify-content-center flex-column' style={{ margin: isMobile ? "1.25rem 0" : "2rem 0" }}>
                    <div>
                        <img loading="lazy"  src='https://d3etfstcuyoos5.cloudfront.net/app-images/final_imgs/wealth_creator.png' alt='no invest' style={{ width: "10rem" }} />
                    </div>
                    <div className='textMD w-500 dark4 text-center' style={{ margin: isMobile ? "1rem" : "2rem" }}>Discovered a world of investment possibilities with {type.dname}</div>
                    <button className='btnBlack ' onClick={() => {
                        if (type.value === 3) {
                            navigate('/curated-baskets')
                        }
                        else {
                            dispatch(setFilterAppFrNav(type.value));
                            dispatch(setOpenSearchModal(true));
                        }
                    }}>Explore</button>
                </div>
            )
        }
        else if (id === 3) {
            return (
                <div className='d-flex align-items-center justify-content-center flex-column' style={{ margin: isMobile ? "1.25rem 0" : "2rem 0" }}>
                    <div>
                        <img loading="lazy"  src='https://d3etfstcuyoos5.cloudfront.net/app-images/final_imgs/wealth_creator.png' alt='no invest' style={{ width: "10rem" }} />
                    </div>
                    <div className='textMD w-500 dark4 text-center' style={{ margin: isMobile ? "1rem" : "2rem" }}>Discovered a world of investment possibilities with sharpely.</div>
                    <button className='btnBlack ' onClick={() => {
                        dispatch(setOpenSearchModal(true));
                    }}>Explore</button>
                </div>
            )
        }
        else {

            return (
                <div className='d-flex align-items-center justify-content-center flex-column' style={{ margin: isMobile ? "1.25rem 0" : "2rem 0" }}>
                    <div>
                        <img loading="lazy"  src={microphone} alt='microphone' style={{ width: "10rem" }} />
                    </div>
                    <div className='textMD w-500 dark4 text-center' style={{ margin: isMobile ? "1rem" : "2rem" }}>Mutual fund investments will be live soon.</div>
                </div>
            )
        }
    }

    const ItemInsights = () => {

        const fundData = showItemInsights?.params?.fundData
        const fundType = showItemInsights?.params?.fundType
        const isWealth = 0;
        const isCustom = showItemInsights?.params?.isCustom;

        const Funds = () => {

            let filteredList = fundData?.Funds;
            filteredList = filteredList?.sort((a, b) => a?.AssetClassId - b?.AssetClassId);

            const columns = [
                {
                    title: "Name",
                    key: "Name",
                    dataIndex: 'Name',
                    render: (text, record) => {
                        return (
                            <span className='d-flex align-items-center' style={{ gap: "0.75rem" }}>
                                <span >{text}</span>
                            </span>
                        )
                    },
                    width: 250,
                    fixed: "left"
                },
                {
                    title: "Current balance",
                    key: "CurrentBalance",
                    dataIndex: 'CurrentBalance',
                    render: (text, record) => {
                        return <span style={{ whiteSpace: "nowrap" }}>{CurrencyFormat(Number(text))}</span>
                    },
                    align: "center",
                },
                {
                    title: <div>P&L<div className='' style={{ fontSize: "10px" }}>(Today)</div></div>,
                    key: "CurrentBalance",
                    dataIndex: "CurrentBalance",
                    align: "right",
                    width: 120,
                    render: (CurrentBalance, obj) => {
                        let diff = Number(CurrentBalance) - obj?.PrevBalance;
                        return <RedGreenText value={diff} text={CurrencyFormat(diff, 'long', 2)} />
                    }
                },
                {
                    title: <div>Return<div className='' style={{ fontSize: "10px" }}>(Today)</div></div>,
                    key: "CurrentBalance",
                    dataIndex: "CurrentBalance",
                    align: "right",
                    width: 120,
                    render: (currentBalance, obj) => {
                        let prevBalance = obj?.PrevBalance
                        let percentageChange = ((currentBalance - prevBalance) / prevBalance) * 100 === Infinity ? 0 : ((currentBalance - prevBalance) / prevBalance) * 100
                        return <RedGreenText text={percentageChange?.toFixed(2) + " %"} value={percentageChange} />
                    }
                },
            ]

            return (
                <React.Fragment>
                    <Table dataSource={filteredList} columns={columns} pagination={false} scroll={{ x: "max-content" }} />
                </React.Fragment>
            )
        }

        const Allocation = () => {

            let filteredList = fundData?.Funds;
            let currentBalance = filteredList?.length > 0 ? filteredList?.map(item => item?.CurrentBalance)?.reduce((prev, next) => prev + next) || 0 : 0
            let fundConstituentsDataFinal = filteredList?.filter((item) => item?.CurrentBalance !== 0)?.map((item) => {
                return {
                    "CurrentWeightage": (item.CurrentBalance * 100 / currentBalance),
                    "Symbol": item.FundCode,
                    ...item
                }
            })?.sort((a, b) => a.AssetClassId - b.AssetClassId)

            const columns = [
                {
                    title: "Name",
                    dataIndex: "Name",
                    key: "Name",
                    render: (text, record) => {
                        return (
                            <span className='d-flex align-items-center' style={{ gap: "0.75rem" }}>
                                <span >{text}</span>
                            </span>
                        )
                    },
                    width: 250
                },
                {
                    title: "Allocation",
                    dataIndex: "CurrentWeightage",
                    key: "CurrentWeightage",
                    align: "right",
                    render: (text) => {
                        return <span>
                            {Number(text).toFixed(2)} %
                        </span>
                    },
                    width: 100
                }
            ]
            return <div>
                <Table dataSource={fundConstituentsDataFinal} columns={columns} pagination={false} scroll={{ x: "max-content" }} />
            </div>
        }

        const SIPs = () => {

            const getSips = React.useCallback(() => {
                if (fundType === 3) {
                    let orderList = scheduledOrders?.filter(item =>
                        matchObjects(item, fundData, ["MintboxId", "GoalId", "WealthCreator"])
                    )
                    return orderList;
                }
                else {
                    let orderList = scheduledOrders?.filter((obj) => String(obj?.ToFundCode) === String(fundData?.FundCode) && obj?.PortfolioId === 0)
                    return orderList;
                }
            }, [scheduledOrders]);
            const sips = getSips();
            console.log('sips', sips);

            if (sips?.length === 0 || !sips) {
                return (
                    <div style={{ flex: 1, alignItems: "center", marginTop: "3rem" }}>
                        <NoDataFound message="You don't have any active SIPs" />
                    </div>
                )
            }
            return (
                <React.Fragment>
                    <div>
                        {sips?.map((data, index) => {
                            return <div key={index + "SAd"} style={{ borderBottom: "0.1px solid var(--grey1)" }}>
                                <SIPCard
                                    orderType={"sip"}
                                    fundData={data}
                                    fundType={fundType}
                                    status={data.IsDeleted === 1 ? 0 : data.IsActive === 1 ? 1 : 2}
                                />
                            </div>
                        })}
                    </div>
                </React.Fragment>
            )
        }

        const Transactions = () => {

            const getTransactions = React.useCallback(() => {
                if (fundType === 3) {
                    return transactions?.filter(item =>
                        matchObjects(item, fundData, ["MintboxId", "GoalId", "WealthCreator"])
                    ).reverse()
                }
                else if (fundType === 1) {
                    return transactions?.filter((ele) => ele?.FundCode === fundData?.FundCode)?.reverse()
                }
                else {
                    return transactions?.filter((ele) => ele?.FundCode === fundData?.FundCode)?.reverse()
                }
            }, [transactions, showItemInsights]);

            const filteredTransactions = getTransactions();
            console.log(filteredTransactions, 'filteredTransactions')
            const columns = [
                {
                    title: "Name",
                    dataIndex: "FundCode",
                    key: "FundCode",
                    render: (text, record) => {
                        return (
                            <span className='d-flex align-items-center' style={{ gap: "0.75rem" }}>
                                <span>{text}</span>
                            </span>
                        )
                    },
                    width: 250
                },
                {
                    title: "Transaction Date",
                    dataIndex: "TradeDate",
                    key: "TradeDate",
                    render: (text) => {
                        return <span>{formatDate(text.toString().replace(" 00:00:00", ""))}</span>
                    }
                },
                {
                    title: "Units",
                    dataIndex: "Units",
                    key: "Units",
                    render: (text) => <span>{Math.abs(Number(text))}</span>,
                    align: "center"
                },
                {
                    title: "Avg. Price",
                    dataIndex: "Amount",
                    key: "Amount",
                    render: (text, obj) => {
                        return <span style={{ whiteSpace: "nowrap" }}>
                            {CurrencyFormat(Math.abs(Number(text) / Number(obj?.Units)))}
                        </span>
                    },
                    align: "center"
                },
                {
                    title: "Transaction type",
                    dataIndex: "TransactionType",
                    key: "TransactionType",
                    align: "center"
                }
            ]
            if (filteredTransactions?.length === 0) {
                return <div style={{ flex: 1, alignItems: "center", marginTop: "3rem" }}>
                    <NoDataFound message="You don't have any transactions." />
                </div>
            }
            return <div>
                <Table columns={columns} dataSource={filteredTransactions?.reverse()} pagination={false} scroll={{ x: "max-content" }} />
            </div>
        }

        const Info = () => {

            return <div>
                <CustomLabelValueCards x={[
                    {
                        heading: "Symbol",
                        value: fundData?.FundCode
                    },
                    {
                        heading: "Units owned",
                        value: fundData?.Units
                    }
                ]} />
            </div>
        }

        const tabs = [
            {
                key: 1,
                label: "Funds",
                value: 1,
                children: <Funds />,
                show: showItemInsights?.params?.fundType === 3
            },
            {
                key: 1,
                label: 'SIPs',
                value: 1,
                children: <SIPs />,
                show: showItemInsights?.params?.fundType !== 3
            },
            {
                key: 2,
                label: 'Allocation',
                value: 2,
                children: <Allocation />,
                show: showItemInsights?.params?.fundType === 3
            },
            {
                key: 5,
                label: 'SIPs',
                value: 5,
                children: <SIPs />,
                show: showItemInsights?.params?.fundType === 3
            },
            {
                key: 3,
                label: "Transactions",
                value: 3,
                children: <Transactions />
            },
            {
                key: 4,
                label: "More info",
                value: 4,
                children: <Info />,
                show: showItemInsights?.params?.fundType !== 3
            }
        ]?.filter((el) => el?.show === undefined || el?.show === true)

        const [selectedTab, setSelectedTab] = React.useState(1);

        return (
            <div>
                <div className='sticky-top' style={{ background: "var(--white)", paddingBottom: "1.25rem" }}>
                    <CustomSegmentedTab options={tabs} value={selectedTab} onChange={(e) => setSelectedTab(e)} isBlock={false} style={{ padding: "0.25rem" }} />
                </div>
                <div style={{}}>
                    {tabs?.find((el) => el?.value === selectedTab)?.children}
                </div>
            </div>
        )
    }

    const InstrumentsTable = ({ dataSource = [], itemType = 1, isCustom = false }) => {
        console.log(dataSource);

        const triggerGateway = (id) => {
            let target = document.querySelector(id);
            if (target) {
                target.click();
            }
        }

        const columns = [
            {
                title: "Name",
                key: "Name",
                dataIndex: "Name",
                width: isMobile ? 150 : 450,
                fixed: "left",
                render: (text, obj) => {
                    return (
                        <div className='d-flex align-items-center w100' style={{ gap: "0.75rem", position: "relative" }}>
                            <div>
                                <div>{text}</div>
                                {itemType === 0 && <div className='w-500' style={{ fontSize: "0.675rem", width: "fit-content" }}>
                                    {obj?.FundType === 1 ? "ETFs" : obj?.FundType === 2 ? "Mutual Funds" : "Stocks"}
                                </div>}
                                {itemType === 3 && obj?.GoalName && <div className='w-500' style={{ fontSize: "0.675rem", width: "fit-content" }}>
                                    {obj?.GoalName}
                                </div>}
                            </div>
                            <div className='d-flex align-items-center content justify-content-end' style={{
                                gap: "0.5rem", position: "absolute", right: 0
                            }}>
                                <button className='btnBuy' onClick={() => { triggerGateway('#buy' + obj?.FundCode + '>.btnBuy') }}>B</button>
                                <button className='btnSell' onClick={() => { triggerGateway('#sell' + obj?.FundCode + '>.btnSell') }}>S</button>
                                <TooltipCloseOnClick title='More'>
                                    <BsThreeDotsVertical style={{
                                        background: "var(--white)", color: "var(--dark4)", border: "none",
                                        borderRadius: "var(--borderRadius)", border: "1px solid var(--grey1)", padding: "0.45rem", cursor: "pointer"
                                    }} size='2rem'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowItemInsights({
                                                open: true,
                                                params: {
                                                    fundType: obj?.FundType,
                                                    isCustom: obj?.IsCustom,
                                                    fundData: obj,
                                                    title: obj?.Name,
                                                    itemType: itemType
                                                }
                                            })
                                        }} />
                                </TooltipCloseOnClick>
                            </div>
                        </div>
                    )
                },
            },
            {
                title: "Quantity",
                key: "Units",
                dataIndex: "Units",
                align: "right",
                width: 120,
                show: itemType !== 3
            },
            {
                title: "Investment",
                key: "Amount",
                dataIndex: "Amount",
                align: "right",
                width: 120,
                render: (text) => CurrencyFormat(text)
            },
            {
                title: "Current value",
                key: "CurrentBalance",
                dataIndex: "CurrentBalance",
                align: "right",
                width: 120,
                render: (text) => CurrencyFormat(text)
            },
            {
                title: "XIRR",
                key: "FundCode",
                dataIndex: "FundCode",
                align: "right",
                width: 120,
                render: (text, obj) => {
                    let retKey = obj?.FundType === 3 ? "MintboxXIRR" : "ETFXIRR"
                    let annRet = xirr?.[retKey]?.[text]?.XIRR;
                    return <RedGreenText value={annRet} text={(annRet?.toFixed(2) || '0.00') + ' %'} />
                },
            },
            {
                title: <div>P&L<div className='' style={{ fontSize: "10px" }}>(Overall)</div></div>,
                key: "CurrentBalance",
                dataIndex: "CurrentBalance",
                align: "right",
                width: 120,
                render: (text, obj) => {
                    let diff = Number(text) - obj?.Amount;
                    return <RedGreenText value={diff} text={CurrencyFormat(diff, 'long', 2)} />
                }
            },
            {
                title: <div>Return<div className='' style={{ fontSize: "10px" }}>(Overall)</div></div>,
                key: "CurrentBalance",
                dataIndex: "CurrentBalance",
                align: "right",
                width: 120,
                render: (currentBalance, obj) => {
                    let prevBalance = obj?.Amount
                    let percentageChange = ((currentBalance - prevBalance) / prevBalance) * 100 === Infinity ? 0 : ((currentBalance - prevBalance) / prevBalance) * 100
                    return <RedGreenText text={(percentageChange?.toFixed(2) || '0.00') + " %"} value={percentageChange} />
                }
            },
            {
                title: <div>P&L<div className='' style={{ fontSize: "10px" }}>(Today)</div></div>,
                key: "CurrentBalance",
                dataIndex: "CurrentBalance",
                align: "right",
                width: 120,
                render: (CurrentBalance, obj) => {
                    let diff = Number(CurrentBalance) - obj?.PrevBalance;
                    return <RedGreenText value={diff} text={CurrencyFormat(diff, 'long', 2)} />
                }
            },
            {
                title: <div>Return<div className='' style={{ fontSize: "10px" }}>(Today)</div></div>,
                key: "CurrentBalance",
                dataIndex: "CurrentBalance",
                align: "right",
                width: 120,
                render: (currentBalance, obj) => {
                    let prevBalance = obj?.PrevBalance
                    let percentageChange = ((currentBalance - prevBalance) / prevBalance) * 100 === Infinity ? 0 : ((currentBalance - prevBalance) / prevBalance) * 100
                    return <RedGreenText text={(percentageChange?.toFixed(2) || '0.00') + " %"} value={percentageChange} />
                }
            },
        ]

        return (
            <React.Fragment>
                <CardHeader heading='Holdings' headingType={2} hideHeaderBlueLine />
                <br />
                <MyTable dataSource={dataSource} columns={columns.filter((el) => el?.show === true || el?.show === undefined)} scroll={{ x: "max-content" }}
                    rowClassName={() => "showContentOnHoverRow"} />
                <div style={{ height: 0, width: 0, overflow: "hidden" }} >
                    {dataSource?.map((obj) => {
                        let temp = {};
                        let arr = []
                        if (itemType !== 3) {
                            let list = portfolioHoldings?.CustomAll?.filter((el) => el?.FundType === obj?.FundType && el?.FundCode === obj?.FundCode) || [];
                            list.forEach((ele) => {
                                let id = ele?.PortfolioId;
                                if (temp?.[id]) {
                                    temp[id] += ele?.Units
                                }
                                else {
                                    temp[id] = ele?.Units
                                }
                            })
                            for (let k in temp) {
                                let j = { PortfolioId: Number(k), Quantity: temp?.[k] }
                                arr.push(j)
                            }
                        }
                        return (
                            <div key={obj?.FundCode}>
                                <div id={'buy' + obj?.FundCode}>
                                    <SharpelyGateway2 FUNDDATA={obj?.FundData} INSTRUMENT_TYPE={itemType === 3 ? obj?.InstrumentType : obj?.FundType} TRANSACTION_TYPE={'BUY'}
                                        IS_BASKET={itemType === 3} PORTFOLIO_ID={itemType === 3 ? obj?.PortfolioId : 0} GOAL_ID={itemType === 3 ? obj?.GoalId : 0} btnText='B' btnClassName='btnBuy' AFTER_SUCCESS_INV={() => refreshData()}
                                        PORTFOLIO_OBJ={arr} />
                                </div>
                                <div id={'sell' + obj?.FundCode}>
                                    <SharpelyGateway2 FUNDDATA={obj?.FundData} INSTRUMENT_TYPE={itemType === 3 ? obj?.InstrumentType : obj?.FundType} TRANSACTION_TYPE={'SELL'}
                                        IS_BASKET={itemType === 3} PORTFOLIO_ID={itemType === 3 ? obj?.PortfolioId : 0} GOAL_ID={itemType === 3 ? obj?.GoalId : 0} btnText='S' btnClassName='btnSell' AFTER_SUCCESS_INV={() => refreshData()}
                                        PORTFOLIO_OBJ={arr} />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </React.Fragment>
        )
    }

    const All = () => {
        const getDataSource = React.useCallback(() => {
            let allInstrumentList = portfolioHoldings?.CustomAll;
            allInstrumentList = joinAndSum(allInstrumentList, ["FundType", "FundCode"], ["CurrentBalance", "Amount", "Units", "PrevBalance"]);
            allInstrumentList = allInstrumentList?.map((item) => {
                let fundData = (item?.FundType === 4 ? allStocks?.find((el) => el?.symbol === item?.FundCode) : allEtfs?.find((el) => el?.nse_symbol === item?.FundCode)) || {};
                let name = item?.FundType === 4 ? fundData?.proper_name : item?.ETFName
                return {
                    ...item,
                    Name: name,
                    FundData: fundData
                }
            })
            allInstrumentList = allInstrumentList?.filter((el) => el?.Units > 0);
            return allInstrumentList;
        }, [portfolioHoldings]);

        const dataSource = getDataSource();

        return (
            <React.Fragment>
                {portfolioHoldingsLoading || portfolioXIRRloading ? <div style={{ margin: "2rem" }}>
                    <ActivityLoaderMintbox showQuote />
                </div> : dataSource?.length !== 0 ?
                    <InstrumentsTable dataSource={dataSource} itemType={0} /> : <NoInvestment id={3} type={stockType} />}
            </React.Fragment>
        )
    }

    const Stocks = () => {
        const getDataSource = React.useCallback(() => {
            let allInstrumentList = portfolioHoldings?.CustomAll?.filter((item) => item?.FundType === 4);
            allInstrumentList = joinAndSum(allInstrumentList, ["FundType", "FundCode"], ["CurrentBalance", "Amount", "Units", "PrevBalance"]);
            allInstrumentList = allInstrumentList?.map((item) => {
                let fundData = allStocks?.find((el) => el?.symbol === item?.FundCode) || {};
                return {
                    ...item,
                    Name: fundData?.proper_name,
                    FundData: fundData
                }
            })
            allInstrumentList = allInstrumentList?.filter((el) => el?.Units > 0);
            return allInstrumentList;
        }, [portfolioHoldings]);

        const dataSource = getDataSource();
        return (
            <React.Fragment>
                {portfolioHoldingsLoading || portfolioXIRRloading ? <div style={{ margin: "2rem" }}>
                    <ActivityLoaderMintbox showQuote />
                </div> : dataSource?.length !== 0 ?
                    <InstrumentsTable dataSource={dataSource} itemType={4} /> : <NoInvestment id={2} type={stockType} />}
            </React.Fragment>
        )
    }

    const ETFs = () => {

        const getDataSource = React.useCallback(() => {
            let allInstrumentList = portfolioHoldings?.CustomAll?.filter((item) => item?.FundType === 1);
            allInstrumentList = joinAndSum(allInstrumentList, ["FundType", "FundCode"], ["CurrentBalance", "Amount", "Units", "PrevBalance"]);
            allInstrumentList = allInstrumentList?.map((item) => {
                let fundData = allEtfs?.find((el) => el?.nse_symbol === item?.FundCode) || {};
                return {
                    ...item,
                    Name: item?.ETFName,
                    FundData: fundData
                }
            })
            allInstrumentList = allInstrumentList?.filter((el) => el?.Units > 0);
            return allInstrumentList;
        }, [portfolioHoldings]);

        const dataSource = getDataSource();

        return (
            <React.Fragment>
                {portfolioHoldingsLoading || portfolioXIRRloading ? <div style={{ margin: "2rem" }}>
                    <ActivityLoaderMintbox showQuote />
                </div> : dataSource?.length !== 0 ?
                    <InstrumentsTable dataSource={dataSource} itemType={1} /> : <NoInvestment id={2} type={etfType} />}
            </React.Fragment>
        )
    }

    const MutualFunds = () => {
        return (
            <React.Fragment>
                {portfolioHoldingsLoading || portfolioXIRRloading ? <div style={{ margin: "2rem" }}>
                    <ActivityLoaderMintbox showQuote />
                </div> : <NoInvestment id={4} type={mfType} />}
            </React.Fragment>
        )
    }

    const Baskets = ({ }) => {

        const getDataSource = React.useCallback(() => {
            let temp = portfolioHoldings?.Mintboxes;
            let allBaskets = [...new Set(temp?.map(item => {
                return filterByKeys(item, ["PortfolioId", "MintboxId", "MintboxName", "WealthCreator", "IsCustom", "MinLumpsumAmount", "MinSIPAmount", "MinSubsequentLumpsum", "GoalDueDate", "GoalId", "GoalName", "GoalTargetCorpus", "GoalType"])
            }))] || []
            allBaskets = Array.from(new Set(allBaskets?.map(JSON.stringify)))?.map(JSON.parse);
            let newArray = allBaskets?.map((item) => {
                var prevBalance = (temp?.filter(ite =>
                    matchObjects(ite, item, ["MintboxId", "GoalId", "PortfolioId", "WealthCreator"])
                )).map(ite => ite?.PrevBalance).reduce((prev, next) => prev + next)

                var currentBalance = (temp?.filter(ite =>
                    matchObjects(ite, item, ["MintboxId", "GoalId", "PortfolioId", "WealthCreator"])
                )).map(ite => ite?.CurrentBalance).reduce((prev, next) => prev + next)

                var amount = (temp?.filter(ite =>
                    matchObjects(ite, item, ["MintboxId", "GoalId", "PortfolioId", "WealthCreator"])
                )).map(ite => ite?.Amount).reduce((prev, next) => prev + next)

                let funds = temp?.filter(ite =>
                    matchObjects(ite, item, ["MintboxId", "GoalId", "PortfolioId", "WealthCreator"])
                )?.map((item) => {
                    let name = item?.FundType === 4 ? allStocks?.find((el) => el?.symbol === item?.FundCode)?.proper_name : item?.ETFName
                    return {
                        ...item,
                        Name: name,
                    }
                })?.filter((el) => el?.CurrentBalance > 0);

                let fundData = allMintboxes?.find((el) => el?.UID === item?.MintboxId) || {};
                let obj = {
                    ...item,
                    Name: item?.MintboxName,
                    FundType: 3,
                    FundCode: item?.PortfolioId,
                    Amount: amount,
                    CurrentBalance: currentBalance,
                    PrevBalance: prevBalance,
                    Funds: funds,
                    InsType: funds?.[0]?.FundType,
                    FundData: fundData,
                    InstrumentType: fundData?.FundType
                }
                return obj;
            })
            newArray = newArray?.filter((el) => el?.CurrentBalance > 0);
            return newArray;
        }, [portfolioHoldings]);

        const dataSource = getDataSource()

        return (
            <React.Fragment>
                {portfolioHoldingsLoading || portfolioXIRRloading ? <div style={{ margin: "2rem" }}>
                    <ActivityLoaderMintbox showQuote />
                </div> : dataSource?.length !== 0 ?
                    <InstrumentsTable dataSource={dataSource} itemType={3} /> : <NoInvestment id={2} type={basketType} />}
            </React.Fragment>
        )
    }

    const Orders = () => {
        if (user?.Client?.[0]?.Broker === 'disconnected' || user?.Client?.[0]?.Broker === '') {
            return <div style={{ marginTop: "0.75rem", display: 'flex', justifyContent: 'start', gap: '1rem' }}>
                <CardHeader heading={`Connect your broker account to fetch today's order details.`} style={{ flex: 'none' }} />
                <div style={{ width: '200px', marginLeft: '1rem' }}><SharpelyGateway2 connectBroker={true} /></div>
            </div>
        }
        const successTexts = ['success', 'complete', 'traded']

        const openOrderColumns = [
            {
                title: "Instrument",
                key: "Instrument",
                dataIndex: "tradingSymbol",
                render: (text, obj) => {
                    let fund = allStocks?.find((el) => el?.symbol === obj?.instrumentIdentifier) || allEtfs?.find((el) => el?.nse_symbol === obj?.instrumentIdentifier)
                    return (
                        <div className='d-flex' style={{ alignItems: 'center', gap: "0.75rem", margin: '4px 0', flex: 1 }}>
                            <div className="textSM black w-500">{fund?.proper_name || fund?.basic_name?.replace(" - Direct Plan", "") || text}</div>
                        </div>
                    )
                }
            },
            {
                title: "Type",
                key: "transactionType",
                dataIndex: "transactionType",
                align: "right"
            },
            {
                title: "Qty.",
                key: "quantity",
                dataIndex: "quantity",
                render: (text, obj) => text + " Shares",
                align: "right"
            },
            {
                title: "Order",
                key: "order",
                dataIndex: "order",
                render: (text, obj) => {
                    return obj?.quantity + " Shares at " + (obj?.orderType === 'MARKET' ? 'MKT' : CurrencyFormat(obj?.price))
                },
                align: "right"
            },
            {
                title: "",
                key: "actions",
                dataIndex: "actions",
                render: (text, obj) => {
                    let fund = allStocks?.find((el) => el?.symbol === obj?.instrumentIdentifier) || allEtfs?.find((el) => el?.nse_symbol === obj?.instrumentIdentifier)
                    let temp = {};
                    let arr = []
                    let list = portfolioHoldings?.CustomAll?.filter((el) => el?.FundCode === obj?.instrumentIdentifier) || [];
                    list.forEach((ele) => {
                        let id = ele?.PortfolioId;
                        if (temp?.[id]) {
                            temp[id] += ele?.Units
                        }
                        else {
                            temp[id] = ele?.Units
                        }
                    })
                    for (let k in temp) {
                        let j = { PortfolioId: Number(k), Quantity: temp?.[k] }
                        arr.push(j)
                    }
                    return <div style={{ display: 'flex', gap: '1rem', justifyContent: "flex-end" }}>
                        <SharpelyGateway2 btnClassName="btnWhite  " btnText='Modify order'
                            INSTRUMENT_TYPE={fund?.symbol ? 4 : 1}
                            FUNDDATA={fund}
                            TRANSACTION_TYPE={obj?.transactionType}
                            MODIFY_ORDER_ID={obj?.orderId}
                            IS_MODIFY={true}
                            MODIFY_PRICE={Number(obj?.price)}
                            MODIFY_QUANTITY={Number(obj?.quantity)} PORTFOLIO_OBJ={arr}
                            IS_BASKET={false} AFTER_SUCCESS_INV={() => refreshData()} />

                        <SharpelyGateway2 btnClassName="btnBlack  " btnText='Cancel order'
                            INSTRUMENT_TYPE={fund?.symbol ? 4 : 1}
                            FUNDDATA={fund}
                            TRANSACTION_TYPE={obj?.transactionType}
                            MODIFY_ORDER_ID={obj?.orderId}
                            IS_CANCEL={true}
                            MODIFY_PRICE={Number(obj?.price)}
                            MODIFY_QUANTITY={Number(obj?.quantity)}
                            IS_BASKET={false} AFTER_SUCCESS_INV={() => refreshData()} />
                    </div>
                }
            }
        ]

        const orderColumns = [
            {
                title: "Instrument",
                key: "Instrument",
                dataIndex: "tradingSymbol",
                render: (text, obj) => {
                    let fund = allStocks?.find((el) => el?.symbol === obj?.instrumentIdentifier) || allEtfs?.find((el) => el?.nse_symbol === obj?.instrumentIdentifier)
                    return (
                        <div className='d-flex' style={{ alignItems: 'center', gap: "0.75rem", margin: '4px 0', flex: 1 }}>
                            <div className="textSM black w-500">{fund?.proper_name || fund?.basic_name?.replace(" - Direct Plan", "") || text}</div>
                        </div>
                    )
                }
            },
            {
                title: "Type",
                key: "transactionType",
                dataIndex: "transactionType",
                align: "right"
            },
            {
                title: "Qty.",
                key: "quantity",
                dataIndex: "quantity",
                render: (text, obj) => text + " Shares",
                align: "right"
            },
            {
                title: "Avg. price",
                key: "price",
                dataIndex: "price",
                render: (text, obj) => CurrencyFormat(text),
                align: "right"
            },
            {
                title: "Status",
                key: "orderStatus",
                dataIndex: "orderStatus",
                render: (text) => {
                    return <Tag color={successTexts?.includes(text?.toLowerCase()) ? 'green' : 'red'} style={{ textTransform: 'capitalize' }}>{text}</Tag>
                },
                align: "right"
            }
        ]

        const openOrders = [...orders]?.filter((ele) => ele?.orderStatus.toLowerCase() === 'open')?.sort((a, b) => new Date(b?.orderUpdateTime).getTime() - new Date(a?.orderUpdateTime).getTime()) || [];
        const closedOrders = [...orders]?.filter((ele) => ele?.orderStatus.toLowerCase() !== 'open')?.sort((a, b) => new Date(b?.orderUpdateTime).getTime() - new Date(a?.orderUpdateTime).getTime()) || [];

        return (
            <React.Fragment>
                {ordersLoading ? <div style={{ margin: "2rem 0" }}>
                    <ActivityLoaderMintbox showQuote />
                </div> : <>
                    <div style={{ marginBottom: "0.75rem" }}>
                        <CardHeader heading={`Open orders ${openOrders?.length ? '(' + openOrders?.length + ')' : ""}`} />
                    </div>
                    {openOrders?.length <= 0 || !openOrders ? <div style={{ margin: "2rem 0" }}>
                        <div className='textSM w-500 dark3'>No open orders.</div>
                    </div> : <MyTable dataSource={openOrders} columns={openOrderColumns} scroll={{ x: "max-content" }} />}
                    <Divider />
                    <div style={{ marginBottom: "0.75rem" }}>
                        <CardHeader heading={`Closed orders ${closedOrders?.length ? '(' + closedOrders?.length + ')' : ""}`} />
                    </div>
                    {closedOrders?.length <= 0 || !closedOrders ? <div style={{ margin: "2rem 0" }}>
                        <div className='textSM w-500 dark3'>No closed orders.</div>
                    </div> : <MyTable dataSource={closedOrders} columns={orderColumns} scroll={{ x: "max-content" }} />}
                </>}
            </React.Fragment>
        )
    }

    const tabItems = [
        { key: "All", value: "All", label: "All", children: <All /> },
        { key: 'Stocks', value: 'Stocks', label: "Stocks", children: <Stocks /> },
        { key: 'ETFs', value: 'ETFs', label: "ETFs", children: <ETFs /> },
        { key: 'MFs', value: 'MFs', label: "Mutual Funds", children: <MutualFunds /> },
        { key: 'Baskets', value: 'Baskets', label: "Baskets", children: <Baskets /> },
        {
            key: "Orders", value: "Orders", label: <div style={{ position: "relative" }}>
                Today's orders {orders?.length > 0 && ordersLoading === false && <div style={{ position: "absolute", right: "-1rem", top: "-0.75rem" }}>
                    <Badge count={orders?.length} color='var(--primary)' /></div>}
            </div>, children: <Orders />
        },
    ]

    const [selectedTab, setSelectedTab] = React.useState(searchParams.get('orders') === 'true' ? "Orders" : 'All');

    const ProfileDetails = () => {

        const getBalanceAndXirr = React.useCallback(() => {
            let currentBalance = 0, changeBalance = 0, percentageChange = 0, annRet = 0;
            let prevBalance = portfolioSnap?.Portfolio?.PrevPortfolioBalance
            currentBalance = portfolioSnap?.Portfolio?.PortfolioBalance
            changeBalance = prevBalance === 0 ? 0 : currentBalance - prevBalance
            percentageChange = prevBalance === 0 ? 0.00 : ((currentBalance - prevBalance) / ((currentBalance + prevBalance) / 2)) * 100 === Infinity ? 0 :
                ((currentBalance - prevBalance) / ((currentBalance + prevBalance) / 2)) * 100;
            annRet = xirr?.PortfolioXIRR?.XIRR;
            return { currentBalance, changeBalance, percentageChange, annRet }
        }, [portfolioSnap, portfolioHoldings, xirr]);

        const { currentBalance, changeBalance, percentageChange, annRet } = getBalanceAndXirr();

        if (isMobile) {
            return (
                <div className='w100' style={{ background: "var(--dark4)", borderRadius: "var(--borderRadius)", }}>
                    <div className='w100' style={{
                        position: "relative", minHeight: "5rem", backgroundImage: `url(${portfolioSnapCard})`, padding: "1rem", backgroundSize: '100% 100%', backgroundRepeat: "no-repeat",
                    }}>
                        <h1 className={`${isMobile ? "textMD w-700 white" : 'textLG w-700 dark4'} margin0`} >Your Investments</h1>
                        <div className='d-flex justify-content-between mt-2' style={{ gap: "1rem" }}>
                            <div>
                                <div className='textXS grey3 mb-1'>Today's P&L</div>
                                {portfolioSnapLoading ? <Skeleton.Button style={{ background: "var(--dark2)", height: "24px", width: "70px" }} active /> :
                                    <div className='textLG w-700 white'>{CurrencyFormat(changeBalance)}</div>}
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <div className='textXS grey3 mb-1'>Current value</div>
                                {portfolioSnapLoading ? <Skeleton.Button style={{ background: "var(--dark2)", height: "24px", width: "90px" }} active /> :
                                    <div className='textLG w-700 white'>{CurrencyFormat(currentBalance)}</div>}
                            </div>
                        </div>
                        <div className={`d-flex text-center`} style={{
                            marginTop: isMobile ? "0.75rem" : "2rem", background: isMobile ? "var(--dark3)" : "", backdropFilter: "blur(5px)",
                            borderRadius: "0.5rem", padding: isMobile ? "0.4rem" : "0", width: isMobile ? "100%" : "70%"
                        }}>
                            <div className='w100' >
                                <div className={`textXS grey3 mb-1`}>Today's P&L (%)</div>
                                {portfolioSnapLoading ? <Skeleton.Button style={{ background: isMobile ? "var(--dark2)" : "var(--grey3)", height: "24px", width: "70px" }} active /> :
                                    <div className={`textLG w-700 ${isMobile ? "white" : "dark4"}`}>{DecimalValueFormat({ num: percentageChange, suffix: "%" })}</div>}
                            </div>
                            <div className='w100' style={{ borderLeft: isMobile ? "1px solid var(--dark4)" : "" }}>
                                <div className={`textXS grey3 mb-1`}>XIRR</div>
                                {portfolioXIRRloading ? <Skeleton.Button style={{ background: isMobile ? "var(--dark2)" : "var(--grey3)", height: "24px", width: "70px" }} active /> :
                                    <div className={`textLG w-700 ${isMobile ? "white" : "dark4"}`}>{DecimalValueFormat({ num: annRet, suffix: "%" })}</div>}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className='w100 d-flex' style={{ gap: "1.25rem" }}>
                <div style={{ background: "var(--white)", borderRadius: "var(--borderRadius)", border: "1px solid var(--grey2)", width: "60%", position: "relative", minHeight: "8rem", }}>
                    <div style={{ top: 0 }}>
                        <svg viewBox="0 0 500 100">
                            <path d="M 0 100 C 15 10 250 50 500 0 L 500 0 L 0 0" fill="var(--blueShade)" opacity="0.5"></path>
                        </svg>
                    </div>
                    <div className='w100' style={{
                        padding: "1.25rem", backgroundRepeat: "no-repeat", position: "absolute", top: 0
                    }}>

                        <div className={`textLG w-700 dark4`} >Your Investments</div>
                        <div className={`d-flex`} style={{ marginTop: "1.25rem", width: "100%" }}>
                            <div className='w100'>
                                <div className={`textXS ${isMobile ? 'grey3' : "dark3 w-500"} mb-1`}>Current value</div>
                                {portfolioSnapLoading ? <Skeleton.Button style={{ background: isMobile ? "var(--dark2)" : "var(--grey3)", height: "24px", width: "70px" }} active /> :
                                    <div className={`textLG w-700 ${isMobile ? "white" : "dark4"}`}>{CurrencyFormat(currentBalance)}</div>}
                            </div>
                            <div className='w100' >
                                <div className={`textXS ${isMobile ? 'grey3' : "dark3 w-500"} mb-1`}>Today's P&L</div>
                                {portfolioSnapLoading ? <Skeleton.Button style={{ background: isMobile ? "var(--dark2)" : "var(--grey3)", height: "24px", width: "70px" }} active /> :
                                    <div className={`textLG w-700 ${isMobile ? "white" : "dark4"}`}>
                                        <RedGreenText text={CurrencyFormat(changeBalance)} value={changeBalance} />
                                    </div>}
                            </div>
                            <div className='w100' >
                                <div className={`textXS ${isMobile ? 'grey3' : "dark3 w-500"} mb-1`}>Today's P&L (%)</div>
                                {portfolioSnapLoading ? <Skeleton.Button style={{ background: isMobile ? "var(--dark2)" : "var(--grey3)", height: "24px", width: "70px" }} active /> :
                                    <div className={`textLG w-700 ${isMobile ? "white" : "dark4"}`}>
                                        <RedGreenText text={DecimalValueFormat({ num: percentageChange, suffix: "%" })} value={percentageChange} />
                                    </div>}
                            </div>
                            <div className='w100'>
                                <div className={`textXS dark3 w-500 mb-1`}>XIRR</div>
                                {portfolioXIRRloading ? <Skeleton.Button style={{ background: "var(--grey3)", height: "24px", width: "70px" }} active /> :
                                    <div className={`textLG w-700 dark4}`}>
                                        <RedGreenText text={DecimalValueFormat({ num: annRet, suffix: "%" })} value={annRet} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ background: "var(--white)", borderRadius: "var(--borderRadius)", border: "1px solid var(--grey2)", width: "40%", minHeight: "8rem", position: "relative" }}>
                    <div style={{ top: 0 }}>
                        <svg viewBox="0 0 500 100">
                            <path d="M 0 10 C 0 0 250 0 500 60 L 100 0 L 0 0" fill="var(--blueShade)" opacity="0.5"></path>
                        </svg>
                    </div>
                    <div style={{ position: "absolute", top: 0, padding: "1.25rem", width: "100%" }}>
                        <div className={`textLG w-700 dark4`} >Instrument  allocation</div>
                        <div className={`d-flex justify-content-between`} style={{ gap: "1rem", marginTop: "1.25rem", }} >
                            {instrumentAllocation?.map((item, i) => {
                                return (
                                    <div key={i + 1} style={{ minWidth: "6rem", flexGrow: 1 }}>
                                        <div className={`textXS dark3 w-500 mb-2`}>{item?.name}</div>
                                        {insAllocLoad ? <Skeleton.Button style={{ background: "var(--grey3)", height: "24px", width: "70px" }} active /> : <>
                                            <div className={`textSM w-700 dark4}`}>
                                                {CurrencyFormat(item?.balance)}
                                            </div>
                                            <span className='textXS w-500 dark4'>
                                                ({item?.y || 0}%)
                                            </span>
                                        </>}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div >
        )
    };

    if (portfolioSnapLoading || ordersLoading) {
        return (
            <div className='loaderContainer' >
                <ActivityLoaderMintbox showQuote={true} />
            </div>
        );
    }
    else {
        var lenf = (portfolioSnap?.Portfolio?.PortfolioBalance === 0 || portfolioSnap?.Portfolio?.PortfolioBalance === undefined) && (orders?.length <= 0)
        return (
            <div style={{ flex: 1 }}>
                <Head title={"Track and analyse your investments | Portfolio by sharpely"} description={'See all your transactions in portfolio. Track, analyze and enhance your investments through sharpely.'} keywords={'portfolio, investment, holdings, order, my investment, my portfolio, my order, buy stocks, buy etfs, track stocks, track investment, invested amount, current value of portfolio, profit and loss on investment, place orders, trade stocks, trade etfs, NSE stocks'} />
                <AccessControlledPage moduleKey={'PORTFOLIO_CONSOLE'} />
                {lenf ? <NoInvestment /> : <div style={{ flex: 1 }} className='myPortfolio'>
                    <div className={`leftRightPadContainer ${isDev ? 'maxWidthContainer' : ""}`} style={{ paddingTop: "1.25rem", paddingBottom: "1.25rem", borderBottom: "1px solid var(--grey3)" }}>
                        <ProfileDetails />
                    </div>
                    <CustomTabs tabItems={tabItems} value={selectedTab} onChange={(e) => setSelectedTab(e)} childContainerId='investmentsCustomTabsChild' />
                    <div style={{}} id={"investmentsCustomTabsChild"} className="">
                        <div className={`leftRightPadContainer ${isDev ? 'maxWidthContainer' : ""}`} style={{ paddingTop: "1.25rem", paddingBottom: "1.25rem", }}>
                            {tabItems?.find((el) => el?.value === selectedTab)?.children}
                        </div>
                    </div>
                    <CustomDrawer isOpen={showItemInsights?.open} toggle={() => setShowItemInsights({})} title={showItemInsights?.params?.title} >
                        <ItemInsights />
                    </CustomDrawer>
                </div>}
                <FloatButton onClick={refreshData} icon={<BiRefresh />} style={{ top: "5rem", right: "calc(((100vw - min(1600px, 100vw))/2) + 2rem)" }} />
            </div >
        );

    }
}