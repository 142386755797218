import React from 'react';

export const StyleboxDesc = ({styleboxCode=1,type='text'}) => {
    let styleboxes = [
        {code:1,text:"This stock could not be classified into any of the StyleBoxes and hence has a neutral classification. Since we have 3 factors and for each factor, we have 3 levels of score (high, medium, and low), theoretically we could build 27 StyleBoxes. Since we limit ourselves to 9 classifications (excluding neutral), a lot of stocks do end up being classified as neutral.",triggerDesc:"This stock could not be classified into any of the StyleBoxes and hence a neutral stance."},
        {code:2,text:"The stock scores high on all 3 factors – quality, value and momentum. Coined by Robert A Haugen in his book The Inefficient Stock Market, Haugen argued that good quality (Q) and cheap stocks (V) that have shown recent uptrend in price (M) tend to outperform the broader market.",triggerDesc:"The stock scores high on all 3 factors – quality, value & momentum and is a potential strong buy."},
        {code:3,text:"The stock has high quality and value, but low to medium momentum score indicating that it is a good quality cheap stock that has not done well in recent times reflecting either lack of investor interest and/or lack of discovery. ",triggerDesc:"The stock scores high on 2 factors – quality and value and is a potential buy."},
        {code:4,text:"Market loves this stock! The stock has high quality and momentum, but low to medium value score. Not cheap, but investors still prefer the stock. The stocks has delivered great returns in the past for investors and continue to deliver sustained growth. ",triggerDesc:"The stock scores high on 2 factors – quality and momentum and is a potential buy."},
        {code:5,text:"The stock is cheap and has shown recent uptick in prices after being beaten down for a while (and hence cheap). But the stock has low to medium quality score. Remember, value and momentum are 2 of the most effective factors and the stock has both in its favour.",triggerDesc:"The stock scores high on 2 factors – value and momentum and is a potential buy."},
        {code:6,text:"Beware of the value trap! The stock might appear cheap has low scores on both momentum and quality. Beaten down and showing no signs of revival. Picking such stocks solely based on valuation can be dangerous (a trap) because there could be something fundamentally wrong.",triggerDesc:"The stock is low on quality and momentum and is a potential value trap."},
        {code:7,text:"Beware of the momentum trap The stock has high momentum score but low scores on both value and quality. Such stocks tend to be hot trending stocks or meme stocks that have gained recent attention. But their underlying fundamentals are bad, and they are not cheap.",triggerDesc:"The stock is expensive and low on quality and is a potential momentum trap."},
        {code:8,text:"The stock has high quality score, but low scores on value and momentum. Looks like it was once a market angel (expensive high-quality stocks loved by the market), but the tide turned. However, the prices have not corrected to the extent that valuations have become cheap (to justify it as an “Under the radar” stock).",triggerDesc:"Quality stock but expensive and low momentum. Should be avoided."},
        {code:9,text:"The stock has high quality score, medium score on value and low score on momentum. Looks like it was once a market angel (expensive high-quality stocks loved by the market),and has gone out of favour. The prices have corrected (low momentum) and valuations have become reasonable but not cheap (medium value). Investors could wait till the stock correct more and moves into the “Under the radar” StyleBox.",triggerDesc:"Quality stock but moderately expensive. Keep an eye for further correction."},
        {code:10,text:"The stock has low scores on all 3 factors – quality, value, and momentum. Investors should just stay away from this stock (hence the name!). If you are thinking of buying such stocks, just ask yourself this – why would you want to own a company that has no quality, has not been performing and is not cheap?",triggerDesc:"The stock has low scores on all 3 factors – quality, value, and momentum. Stay away!"}
    ]

    let other ={text:"The stock cannot be classified in any of the StyleBoxes. This could be because of lack of sufficient data or because the market cap of the stock is less than Rs 100 crore."};

    let stylebox = styleboxes?.find(el=>el.code===styleboxCode)||other;

    return <span>
        {stylebox?.[type]}
    </span>
}