import { makeid } from "Libs/utils";
import { Card } from "antd";
import StaticSlider from "./StaticSlider";

const CustomRangeSliders = ({ sliders }) => {
    return <Card bodyStyle={{ padding: window.innerWidth > 760 ? '1.25rem' : 0 }}>
        {
            sliders.map((x, i) => {
                if (x.show) {
                    return <div style={{ display: 'flex', flexDirection: window.innerWidth > 760 ? 'row' : 'column', gap: '2rem', alignItems: window.innerWidth > 760 ? 'center' : 'stretch', paddingTop: i === 0 ? '0' : '2rem', paddingBottom: '2rem', borderBottom: i === sliders.length - 1 ? 'none' : '1px solid var(--grey3)' }}
                        key={makeid(i + 1)}>
                        <div style={{ flex: 2 }}>
                            <div className="textSM w-500 black">{x.title}</div>
                            <div className="textXS dark2">{x.description}</div>
                        </div>
                        <div style={{ flex: 3 }}>
                            <StaticSlider
                                currentVal={x.currentVal}
                                currentValStr={x.currentValStr}
                                min={x.min}
                                max={x.max}
                                minStr={x.minStr}
                                maxStr={x.maxStr}
                                minStrLabel={x.minStrLabel}
                                maxStrLabel={x.maxStrLabel}
                            />
                        </div>
                    </div>
                }
                else return null;
            })
        }
    </Card>
}

export default CustomRangeSliders;

export const CustomRangeSliders2 = ({ sliders }) => {
    return (
        <div style={{ height: 'calc(100%)', backgroundColor: 'var(--grey4)', border: '1px solid var(--grey3)', borderRadius: '0.5rem', overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
            {
                sliders.map((x, i) => {
                    if (x.show) {
                        return <Card key={makeid(i + 1)} style={{ borderRadius: '12px' }} bodyStyle={{ padding: '0.75rem 2.5rem' }}>
                            <div style={{ display: 'flex', gap: '2rem', alignItems: 'center' }}>
                                <div style={{ flex: 2 }}>
                                    <div className="textSM w-500 black">{x.title}</div>
                                    <div className="textXS dark2">{x.description}</div>
                                </div>
                                <div style={{ flex: 3 }}>
                                    <StaticSlider
                                        currentVal={x.currentVal}
                                        currentValStr={x.currentValStr}
                                        min={x.min}
                                        max={x.max}
                                        minStr={x.minStr}
                                        maxStr={x.maxStr}
                                        minStrLabel={x.minStrLabel}
                                        maxStrLabel={x.maxStrLabel}
                                    />
                                </div>
                            </div>
                        </Card>
                    }
                    else return null;
                })
            }
        </div>)
}
