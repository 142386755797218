import { Card, Select } from "antd";
import { CurrencyFormat } from "Components/CurrencyFormat";
import CustomSegmentedTab from "Components/CustomSegmentedTab";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import InstrumentListTable from "Components/InstrumentListTable";
import Head from "Layout/Head";
import { fetchFundData } from "Libs/fetchFundData";
import { stock_universe } from "Libs/utils";
import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import AdminServicesPY from "Services/api/AdminServicesPY";
import { StockLivePrice } from "Services/api/LivePricesWebSocket";

export default function MarketWatch() {

    let stockprices = useSelector((state) => state?.stockprices?.value)
    let prevstockprices = useSelector((state) => state?.prevStockPrices?.value || {})
    const [searchParams, setSearchParams] = useSearchParams();
    const watchType = searchParams.get('type');
    const index = searchParams.get('index')
    const [indexCode, setIndexCode] = useState(index || 'is_nifty_50');
    const [allFunds, setAllfunds] = React.useState([])
    const [loading, setLoad] = React.useState(true)

    const loadData = async () => {
        let allfundsc = await fetchFundData(4);
        let result = await AdminServicesPY.getStocksColData({ stocks: allfundsc?.map((el) => el?.symbol), cols: [...(stock_universe?.map((el) => el?.value)?.filter((el) => el !== 'all'))], all_cols: false });
        result = JSON.parse(result?.data);
        result = result?.map((fund) => {
            return {
                ...allfundsc?.filter((ii) => ii?.symbol === fund?.symbol)?.[0],
                ...fund,
                // "gdf_symbol": fund?.nse_series === 2 ? fund?.symbol + ".BE" : fund?.nse_series === 3 ? fund?.symbol + ".BZ" : fund?.symbol
                "gdf_symbol": fund?.symbol
            }
        })
        setAllfunds(result);
        setLoad(false);
    }

    React.useEffect(() => {
        setLoad(true)
        loadData()
    }, [])

    const gainersLosersCols = [
        {
            title: "LTP",
            dataIndex: 'symbol',
            key: "symbol",
            align: "right",
            width: 150,
            render: (val, f) => {
                return <StockLivePrice fundData={f} format={3} symbol={f?.symbol || f?.ticker || f?.nse_symbol} />
            },
            show: true,
            metric_col_code: "FundCode"
        },
        {
            title: "52W high",
            dataIndex: "symbol",
            key: "symbol",
            align: "right",
            width: "10rem",
            render: (val, obj) => {
                return (
                    CurrencyFormat(obj.currentHigh, 'long')
                )
            },
            metric_col_code: "52w_high"
        },
        {
            title: "52W low",
            dataIndex: "symbol",
            key: "symbol",
            align: "right",
            width: "10rem",
            render: (val, obj) => {
                return (
                    CurrencyFormat(obj.currentLow, 'long')
                )
            },
            metric_col_code: "52w_low"
        },
        {
            title: "CAGR 3Y",
            dataIndex: 'three_yr_pct',
            key: "three_yr_pct",
            align: "right",
            // width: 150,
            render: (val, fundData) => {
                return DecimalValueFormat({ num: val, suffix: " %", placeholder: "-" })
            },
            metric_col_code: "three_yr_pct"
        },
        {
            title: "CAGR 5Y",
            dataIndex: 'five_yr_pct',
            key: "five_yr_pct",
            align: "right",
            // width: 150,
            render: (val, fundData) => {
                return DecimalValueFormat({ num: val, suffix: " %", placeholder: "-" })
            },
            metric_col_code: "five_yr_pct"
        },
        {
            title: "CAGR 10Y",
            dataIndex: 'ten_yr_pct',
            key: "ten_yr_pct",
            align: "right",
            // width: 150,
            render: (val, fundData) => {
                return DecimalValueFormat({ num: val, suffix: " %", placeholder: "-" })
            },
            metric_col_code: "ten_yr_pct"
        },
    ]

    const getHighLowCols = (format = 'high') => ([
        {
            title: "LTP",
            dataIndex: 'symbol',
            key: "symbol",
            align: "right",
            width: 150,
            render: (val, f) => {
                return <StockLivePrice fundData={f} format={3} symbol={f?.symbol || f?.ticker || f?.nse_symbol} />
            },
            show: true,
            metric_col_code: "FundCode"
        },
        {
            title: format === 'high' ? "52W high" : "52W low",
            dataIndex: "symbol",
            key: "symbol",
            align: "right",
            width: "10rem",
            render: (val, obj) => {
                return (
                    format === 'high' ? CurrencyFormat(obj.currentHigh, 'long') : CurrencyFormat(obj.currentLow, 'long')
                )
            },
            metric_col_code: "52w_high"
        },
        {
            title: "%age diff",
            key: "symbol",
            dataIndex: "symbol",
            align: "right",
            width: "max(8rem, 100px)",
            render: (val, f) => {
                return (
                    format === 'high' ?
                        f.percDiff === 0 ? <span style={{ color: 'var(--green)' }}>At high</span> : `-${DecimalValueFormat({ num: f.percDiff, suffix: "%", decimals: 2 }).replace("NaN", "0.00")}`
                        : f.percDiff === 0 ? <span style={{ color: 'var(--red)' }}>At Low</span> : `${DecimalValueFormat({ num: f.percDiff, suffix: "%", decimals: 2 }).replace("NaN", "0.00")}`
                )
            },
            metric_col_code: "52w_low"
        },
        {
            title: "CAGR 3Y",
            dataIndex: 'three_yr_pct',
            key: "three_yr_pct",
            align: "right",
            // width: 150,
            render: (val, fundData) => {
                return DecimalValueFormat({ num: val, suffix: " %", placeholder: "-" })
            },
            metric_col_code: 'three_yr_pct',
        },
        {
            title: "CAGR 5Y",
            dataIndex: 'five_yr_pct',
            key: "five_yr_pct",
            align: "right",
            // width: 150,
            render: (val, fundData) => {
                return DecimalValueFormat({ num: val, suffix: " %", placeholder: "-" })
            },
            metric_col_code: "five_yr_pct"
        },
        {
            title: "CAGR 10Y",
            dataIndex: 'ten_yr_pct',
            key: "ten_yr_pct",
            align: "right",
            // width: 150,
            render: (val, fundData) => {
                return DecimalValueFormat({ num: val, suffix: " %", placeholder: "-" })
            },
            metric_col_code: "ten_yr_pct"
        },
    ])

    const getGainersLosersFunds = React.useCallback((format = 'top') => {
        console.log('getttinng gain lose funds')
        const sortFunds = (arr, key, order = "top") => {
            var arrr = [...arr]?.filter((i) => (i?.[key] !== undefined && i?.[key] !== NaN && i?.[key] !== null))
            if (order === "bot") {
                arrr = arrr.sort((a, b) => a[key] - b[key])
            } else {
                arrr = arrr.sort((a, b) => b[key] - a[key])
            }
            return arrr?.filter((i) => order === "top" ? i[key] > 0 : i[key] < 0);
        }
        var allfundsc = indexCode !== 'all' ? allFunds?.filter((i) => i[indexCode] === 1) : allFunds
        allfundsc = allfundsc?.map((fund) => {
            var changeVal, changePer, currentLTP = "-"
            var currentSymbolAllData = stockprices === "close" ? prevstockprices?.[fund?.gdf_symbol] : (stockprices?.[fund?.gdf_symbol] || prevstockprices?.[fund?.gdf_symbol])
            currentLTP = currentSymbolAllData?.LastTradePrice
            changeVal = currentSymbolAllData?.PriceChange
            changePer = currentSymbolAllData?.PriceChangePercentage
            var dayHigh = currentSymbolAllData?.High;
            var dayLow = currentSymbolAllData?.Low;
            const high = Math.max(fund['52w_high'], dayHigh)
            const low = Math.min(fund['52w_low'], dayLow)
            // ////console.log(currentLTP, changePer, changeVal)
            return {
                ...fund,
                "currentHigh": high,
                "currentLow": low,
                "currentLTP": currentLTP,
                "changePer": changePer,
                "changeVal": changeVal
            }
        }) || [];
        allfundsc = sortFunds(allfundsc, 'changePer', format) || []
        return allfundsc;
    }, [allFunds, stockprices, prevstockprices, indexCode])

    const getHighLowFunds = React.useCallback((format = 'high') => {
        const getChange = (current, high, low) => {
            if (format === 'high') {
                return ((high - current) / high) * 100
            }
            else return ((current - low) / low) * 100
        }

        const sortFunds = (arr, key) => {
            var arrr = [...arr]?.filter((i) => (i?.[key] !== undefined && i?.[key] !== NaN && i?.[key] !== null))
            arrr = arrr.sort((a, b) => Math.abs(a[key]) - Math.abs(b[key]))
            //console.log(arrr)
            return arrr;
        }

        var allfundsc = indexCode !== 'all' ? allFunds.filter((i) => i[indexCode] === 1) : allFunds
        allfundsc = allfundsc.map((fund) => {
            var currentLTP = "-"
            var currentSymbolAllData = stockprices === "close" ? prevstockprices?.[fund?.gdf_symbol] : (stockprices?.[fund?.gdf_symbol] || prevstockprices?.[fund?.gdf_symbol])
            currentLTP = currentSymbolAllData?.LastTradePrice;
            var dayHigh = currentSymbolAllData?.High;
            var dayLow = currentSymbolAllData?.Low;
            const high = Math.max(fund['52w_high'], dayHigh)
            const low = Math.min(fund['52w_low'], dayLow)
            if (currentLTP !== '-') {
                return {
                    ...fund,
                    "currentHigh": high,
                    "currentLow": low,
                    "percDiff": getChange(currentLTP, high, low),
                    "currentLTP": currentLTP
                }
            } else return {
                ...fund,
                "currentHigh": high,
                "currentLow": low,
                "percDiff": '-',
                "currentLTP": currentLTP
            }
        })
        allfundsc = sortFunds(allfundsc?.filter((i) => i?.["currentLTP"] !== undefined && i?.["currentLTP"] !== NaN && i?.["currentLTP"] !== null && i?.["currentLTP"] !== '-'), 'percDiff');
        return allfundsc;
    }, [allFunds, stockprices, prevstockprices, indexCode])

    const tabItems = [
        {
            key: 1,
            value: 1,
            label: "Top Gainers",
            to: 'top-gainers',
            columns: gainersLosersCols,
            dataSource: getGainersLosersFunds(),
            title: "Top Gainers Today NSE"
        },
        {
            key: 2,
            value: 2,
            label: "Top Losers",
            to: 'top-losers',
            columns: gainersLosersCols,
            dataSource: getGainersLosersFunds('bot'),
            title: "Top Losers Today NSE"
        },
        {
            key: 3,
            value: 3,
            label: "52W High",
            to: '52w-high',
            columns: getHighLowCols(),
            dataSource: getHighLowFunds('high'),
            title: "Near 52 Week High Stocks - Live Share Market Analysis"
        },
        {
            key: 4,
            value: 4,
            label: "52W Low",
            to: '52w-low',
            columns: getHighLowCols('low'),
            dataSource: getHighLowFunds('low'),
            title: "Near 52 Week Low Stocks - Live Share Market Analysis"
        }
    ]

    const [selectedTab, setSelectedTab] = useState(tabItems?.find(ele => ele.to === watchType?.toLowerCase())?.value || 4);
    const selectedTabItem = tabItems?.find(ele => ele.value === selectedTab) || {};
    return (
        <React.Fragment>
            <Head title={selectedTabItem?.title || 'Stock Market Trends Today - NSE Stocks Analysis'} />
            <div className="normalContainer">
                <div className='normalContainer'>
                    {/* <div className="leftContainer"> */}
                    <Card>
                        <div className="d-flex justify-content-between margin20Bottom" style={{
                            flexDirection: window.innerWidth > 760 ? "row" : "column", alignItems: window.innerWidth > 760 ? "center" : "start",
                            gap: "1.25rem"
                        }} >
                            <CustomSegmentedTab options={tabItems} isBlock={false} value={selectedTab} onChange={(e, obj) => {
                                setSelectedTab(e);
                                setSearchParams({ type: tabItems?.find((el) => el.value === e)?.to, index: indexCode })
                            }} />
                            <Select options={stock_universe} value={indexCode} onChange={(e) => {
                                setIndexCode(e)
                                setSearchParams({ type: tabItems?.find((ele) => ele.value === selectedTab)?.to, index: e })
                            }} style={{ width: "13rem" }}
                                suffixIcon={<IoIosArrowDown className="textSM w-500 black" />} />
                        </div>
                        <div>
                            <InstrumentListTable finalList={selectedTabItem?.dataSource || []} defaultInsType={4} otherCol={selectedTabItem?.columns || []} isCard={false}
                                hideCheckBox hidePaddingInMob={true} tableHeader={selectedTabItem?.label} lastCols={[]}
                                istableLoading={loading} allowInitSort={false} tableType={5} />
                        </div>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    )
}