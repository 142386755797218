import { Card, Skeleton } from "antd";
import { CardHeader } from "Components/atoms/SmallComponents";
import { getRandomColor } from "Libs/utils";
import React from "react";
import { Link } from "react-router-dom";

export const Categories = ({ categories, heading = 'Categories', showBlogCount = true, loading }) => {

    const ListLoader = () => {
        let items = new Array(4).fill(1);
        return (
            <div className='d-flex flex-column' style={{ gap: "1rem", marginTop: "1.25rem" }}>
                {items?.map((ele, i) => (
                    <div key={i + 1} className='d-flex align-items-center justify-content-between' style={{ gap: "1rem" }} >
                        <Skeleton.Input active={(i + 1) % 2} style={{ height: "1rem" }} />
                        <Skeleton.Avatar shape='square' style={{ height: "1rem", width: '3rem', borderRadius: "var(--borderRadius)" }} />
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Card>
            <CardHeader heading={heading} headingType={2} textClassName='textMD w-700' />
            {loading ? <ListLoader /> :
                categories?.map((item) => (
                    <div key={item.UID} className='resCategoryItem' style={{ cursor: "pointer" }} >
                        <Link to={item.Slug} className='textSM w-500'>
                            <div style={{ background: getRandomColor(), borderRadius: "50%", height: "0.6rem", width: "0.6rem" }}></div>
                            <h3 className='margin0 textSM w-500'>
                                {item?.Category}
                            </h3>
                        </Link>
                        {showBlogCount && <span className='textXS w-500 dark4'>
                            ({item?.Blogs?.length})
                        </span>}
                    </div>
                ))}
        </Card>
    )
}