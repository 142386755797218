import { Card, Collapse, Modal, Popover, Progress, Skeleton, Table } from "antd";
import AccessComponent from "Components/AccessComponent";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import AMCIcon from "Components/AMCIcon";
import ColumnChart from "Components/ColumnChart";
import CustomDrawer from "Components/CustomDrawer";
import CustomLabelValueCards from "Components/CustomLabelValue";
import CustomSegmentedTab from "Components/CustomSegmentedTab";
import CustomSideTabs from "Components/CustomSideTabs";
import { InsightIcon } from "Components/InsightsIcon";
import { NoDataFound } from "Components/NoDataFound";
import ordinal_suffix_of from "Components/Ordinal-suffix";
import PerformanceChart3 from "Components/PerformanceChart3";
import { CollapseText } from "Components/ReadMoreText";
import { ScreenerIcon } from "Components/ScreenerIcon";
import { StaticSlider2 } from "Components/StaticSlider";
import { StockScoresGauge2 } from "Components/StockScoresGauge2";
import { StyleboxDesc } from "Components/StyleboxFunctions";
import UpgradeToPro from "Components/UpgradeToPro";
import { toggleMetricDescModal } from "Libs/redux/KnowledgeBase/reduxSlice";
import { toggleHelpAndSupp } from "Libs/redux/UserAuth/reduxSlice";
import { arePropsEqual, displayMetricValue, displayValue, generateUniqueKey } from "Libs/utils";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch } from "react-redux";

export const SummaryRecommendation = React.memo(({ fundData, screenerMetricDescData, styleBoxes, isMobile, dispatch, allIndicatorData, loadingTechnicalsIndicators, goToTechnicals }) => {
    const changeMetricObj = (id) => {
        let ob = screenerMetricDescData?.find((el) => el?.desc_id === Number(id)) || {};
        dispatch(toggleMetricDescModal({
            value: true,
            params: {
                title: ob?.name,
                description: ob?.description
            }
        }))
    }

    let buyPer = Number(Number(fundData?.buy_per || 0)?.toFixed(2));
    let upsideTpPer = Number(Number(fundData?.upside_tp_per || 0)?.toFixed(2))
    let consensusTp = Number(Number(fundData?.consensus_tp || 0)?.toFixed(2));

    const ind = allIndicatorData?.filter(x => x.timeframe === '1D' && (x.type.toLowerCase() === 'ma' || x.type.toLowerCase() === 'oscillator'));
    if (!ind) { return <></> }
    const indBuyNum = ind.reduce((accumulator, object) => {
        return object.sentiment === 1 ? accumulator + object.sentiment : accumulator
    }, 0)
    const indTotal = ind.length
    const indBuyPerc = parseInt(indBuyNum / indTotal * 100)

    return (
        <div style={{}}>
            <div style={{ display: "flex", gap: "1.25rem", overflow: 'auto' }}>
                <Card style={{ border: "1px solid var(--grey3)", flex: 1, minWidth: isMobile ? '22rem' : '' }} className="flexCard" bodyStyle={{
                    padding: "1rem",
                    minWidth: '16rem', width: "100%", height: "100%", display: "flex", flexDirection: "column"
                }} >
                    <div style={{ display: "flex", alignItems: "center", gap: "0.75rem", justifyContent: "space-between" }}>
                        <div className='textSM w-500'>
                            StyleBox<sup style={{ fontSize: "0.5rem" }}>TM</sup>
                        </div>
                        <div style={{ color: "var(--primary)", cursor: "pointer", textAlign: "center" }} className='textXS w-500' onClick={() => changeMetricObj(124)}>
                            What is StyleBox?
                        </div>
                    </div>
                    <AccessComponent component={<>
                        <div style={{}}>
                            {fundData?.style_box_code ?
                                <StockScoresGauge2 val={styleBoxes?.find((el) => el.style_box_code === (Number(fundData?.style_box_code)))?.front_end_code} height={170} labelSuffix={""}
                                    showTickLabels={true} paneThickness={17} min={1} max={11} isStylebox={true} tickInterval={1} tickAmount={11} yAxisLabelClass='analystRatingGauge'
                                    dataLabel={styleBoxes?.find((el) => el.style_box_code === (Number(fundData?.style_box_code)))?.style_box_name}
                                    yAxisLabels={{ 1: "Don't<br/>touch", 6: "Neutral", 10: "Super<br/>stock", }} /> : <div style={{ padding: "1rem 0" }}>
                                    <NoDataFound width='6rem' textAlign='left' message={<div className='textXS dark4' style={{ textAlign: "left" }}>
                                        The stock cannot be classified in any of the StyleBoxes. This could be because of lack of sufficient data or because the market cap of the stock is less than Rs 100 crore.
                                    </div>} />
                                </div>
                            }
                        </div>
                        {fundData?.style_box_code && <>
                            <div style={{ display: "flex", gap: "0.5rem", }}>
                                <div style={{ flexShrink: 0 }}>
                                    <InsightIcon type={fundData?.style_box_code >= 6 ? "Negative" : fundData?.style_box_code === 1 ? "Neutral" : "Positive"} height="2rem" width="2rem" padding="0.5rem" />
                                </div>
                                <div className="textXS w-500 dark4">
                                    <StyleboxDesc styleboxCode={Number(fundData?.style_box_code)} type='triggerDesc' />
                                </div>
                            </div>
                            <CollapseText triggerText={'Detail >'} content={
                                <div>
                                    <div className="textXS w-500 dark4">
                                        <StyleboxDesc styleboxCode={Number(fundData?.style_box_code)} />
                                    </div>
                                </div>
                            } />
                        </>}
                    </>} moduleKey="ADVANCE_STOCK_REPORT" upgradeComponent={<div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-end", padding: "1.5rem 0" }}>
                        <UpgradeToPro moduleKey="ADVANCE_STOCK_REPORT" height="fit-content" descClassName="textXS w-500 dark3 mb-3" zIndex={2} />
                    </div>} />
                </Card>
                <Card style={{ border: "1px solid var(--grey3)", flex: 1, minWidth: isMobile ? '22rem' : '' }} className="flexCard" bodyStyle={{
                    padding: "1rem",
                    minWidth: '16rem', width: "100%", height: "100%", display: "flex", flexDirection: "column"
                }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "0.75rem", justifyContent: "space-between" }}>
                        <div className='textSM w-500'>
                            QVM score
                        </div>
                        <div style={{ color: "var(--primary)", cursor: "pointer" }} className='textXS w-500' onClick={() => changeMetricObj(144)}>
                            What is QVM score?
                        </div>
                    </div>
                    <AccessComponent component={<>
                        <div style={{}}>
                            {fundData?.sh_3fac_comp !== null ?
                                <StockScoresGauge2 val={Number(fundData?.sh_3fac_comp?.toFixed(1))} height={170} labelFont={"16px"} showTickLabels={true} paneThickness={17}
                                    min={0} max={100} tickAmount={5} labelSuffix={'/ 100'} /> : <div style={{ padding: "1rem 0" }}>
                                    <NoDataFound width='6rem' textAlign='left' message={<div className='textXS dark4' style={{ textAlign: "left" }}>
                                        The score is not available for this stock. This could be because (a) the market cap of the stock is less than Rs 100 crore, (b) the company has not reported any new financial information in the last 6 months or, (c) there is some issue with our data. Do report in case you feel it’s an issue.
                                    </div>} />
                                </div>
                            }
                        </div>
                        {fundData?.sh_3fac_comp !== null && <div style={{ display: "flex", gap: "0.5rem", }}>
                            <div style={{ flexShrink: 0 }}>
                                <InsightIcon type={Number(fundData?.sh_3fac_comp?.toFixed(1)) <= 30 ? "Negative" : Number(fundData?.sh_3fac_comp?.toFixed(1)) <= 70 ? "Neutral" : "Positive"} height="2rem" width="2rem" padding="0.5rem" />
                            </div>
                            <div className='textXS w-500 dark4'>
                                {Number(fundData?.sh_3fac_comp?.toFixed(1)) <= 30 ? "The stock has low QVM score with respect to other stocks in the market. Bearish." :
                                    Number(fundData?.sh_3fac_comp?.toFixed(1)) <= 70 ? "The stock has moderate QVM score with respect to other stocks in the market. Neutral." :
                                        "The stock has high QVM score with respect to other stocks in the market. Bullish."}
                            </div>
                        </div>}
                    </>} moduleKey="ADVANCE_STOCK_REPORT" upgradeComponent={<div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-end", padding: "1.5rem 0" }}>
                        <UpgradeToPro moduleKey="ADVANCE_STOCK_REPORT" height="fit-content" descClassName="textXS w-500 dark3 mb-3" zIndex={2} />
                    </div>} />
                </Card>
                <Card style={{ border: "1px solid var(--grey3)", flex: 1, minWidth: isMobile ? '22rem' : '' }} className="flexCard" bodyStyle={{
                    padding: "1rem",
                    minWidth: '16rem', width: "100%", height: "100%", display: "flex", flexDirection: "column"
                }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "0.75rem", justifyContent: "space-between" }}>
                        <div className='textSM w-500'>
                            Analyst rating
                        </div>
                        <div style={{ color: "var(--primary)", cursor: "pointer", textAlign: "center" }} className='textXS w-500' onClick={() => changeMetricObj(275)}>
                            What is Analyst rating?
                        </div>
                    </div>
                    <AccessComponent component={<>
                        <div style={{}}>
                            {fundData?.buy_per !== null ?
                                <StockScoresGauge2 val={Number(Number(fundData?.buy_per || 0)?.toFixed(2))} height={170} labelSuffix={"%"} labelFont={"16px"} showTickLabels={true} paneThickness={17} min={0}
                                    max={100} isAnalystRating={true} yAxisLabels={{ 10: "Strong<br/>sell", 30: "Sell", 50: "Neutral", 70: "Buy", 90: "Strong<br/>buy" }} yAxisLabelClass='analystRatingGauge'
                                    tickInterval={10} tickAmount={11} showTick={false} /> : <div style={{ padding: "1rem 0" }}>
                                    <NoDataFound width='6rem' textAlign='left' message={<div className='textXS dark4' style={{ textAlign: "left" }}>
                                        Looks like this stock does not have active sell-side coverage and hence recommendations and ratings are not available.
                                    </div>} />
                                </div>}
                        </div>
                        {fundData?.buy_per !== null ?
                            <>
                                <div style={{ display: "flex", gap: "0.5rem", }}>
                                    <div style={{ flexShrink: 0 }}>
                                        <InsightIcon type={buyPer <= 50 ? "Negative" : buyPer <= 70 ? "Neutral" : "Positive"} height="2rem" width="2rem" padding="0.5rem" />
                                    </div>
                                    <div className="textXS dark4 w-500">
                                        {buyPer <= 50 && "Only "}{buyPer}% of analysts have buy or strong buy on this stock implying {buyPer <= 50 ? "negative" : buyPer <= 70 ? "neutral" : "positive"} street outlook.
                                    </div>
                                </div>
                                <CollapseText triggerText='Detail >' content={
                                    <div className='textXS w-500 dark4'>
                                        {upsideTpPer < 0 ? `${buyPer <= 50 ? "Only " : ""}${buyPer}% of analysts have buy or strong buy rating on this stock. Median target price of ${consensusTp} implies a downside of ${upsideTpPer}% from current levels. Overall, ${buyPer <= 50 ? "negative" : buyPer <= 70 ? "neutral" : "positive"} outlook on the street.` : upsideTpPer > 0 ? `${buyPer <= 50 ? "Only " : ""}${buyPer}% of analysts have buy or strong buy rating on this stock. Median target price of ${consensusTp} implies a upside of ${upsideTpPer}% from current levels. Overall, ${buyPer <= 50 ? "negative" : buyPer <= 70 ? "neutral" : "positive"} outlook on the street.` : `${buyPer <= 50 ? "Only " : ""}${buyPer}% of analysts have buy or strong buy rating on this stock. Overall, ${buyPer <= 50 ? "negative" : buyPer <= 70 ? "neutral" : "positive"} outlook on the street.`}
                                    </div>
                                } />
                            </> : null}
                    </>} moduleKey="ADVANCE_STOCK_REPORT" upgradeComponent={<div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-end", padding: "1.5rem 0" }}>
                        <UpgradeToPro moduleKey="ADVANCE_STOCK_REPORT" height="fit-content" descClassName="textXS w-500 dark3 mb-3" zIndex={2} />
                    </div>} />
                </Card>
                <Card style={{ border: "1px solid var(--grey3)", flex: 1, minWidth: isMobile ? '22rem' : '' }} className="flexCard" bodyStyle={{
                    padding: "1rem",
                    minWidth: '16rem', width: "100%", height: "100%", display: "flex", flexDirection: "column"
                }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "0.75rem", justifyContent: "space-between" }}>
                        <div className='textSM w-500'>
                            Technical indicators
                        </div>
                        <div style={{ color: "var(--primary)", cursor: "pointer", textAlign: "center" }} className='textXS w-500' onClick={goToTechnicals}>
                            See all &gt;
                        </div>
                    </div>
                    <AccessComponent component={<>
                        {
                            loadingTechnicalsIndicators ?
                                <>
                                    <div style={{}}>
                                        <StockScoresGauge2 val={50} height={170} labelSuffix={"%"} labelFont={"16px"} showTickLabels={true} paneThickness={17} min={0}
                                            max={100} isAnalystRating={true} yAxisLabels={{ 10: "Strong<br/>sell", 30: "Sell", 50: "Neutral", 70: "Buy", 90: "Strong<br/>buy" }} yAxisLabelClass='analystRatingGauge'
                                            tickInterval={10} tickAmount={11} showTick={false} showLabel={false} />
                                    </div>
                                    <>
                                        <Skeleton avatar={{ size: '2.5rem' }} paragraph={{ rows: 2, width: ['90%', '70%'] }} title={false} active />
                                    </>
                                </>
                                :
                                <>
                                    <div style={{}}>
                                        {indTotal ?
                                            <StockScoresGauge2 val={indBuyPerc || 0} height={170} labelSuffix={"%"} labelFont={"16px"} showTickLabels={true} paneThickness={17} min={0}
                                                max={100} isAnalystRating={true} yAxisLabels={{ 10: "Strong<br/>sell", 30: "Sell", 50: "Neutral", 70: "Buy", 90: "Strong<br/>buy" }} yAxisLabelClass='analystRatingGauge'
                                                tickInterval={10} tickAmount={11} showTick={false} /> : <div style={{ padding: "1rem 0" }}>
                                                <NoDataFound width='6rem' textAlign='left' message={<div className='textXS dark4' style={{ textAlign: "left" }}>
                                                    Data not available.
                                                </div>} />
                                            </div>}
                                    </div>
                                    {indTotal ?
                                        <>
                                            <div style={{ display: "flex", gap: "0.5rem", }}>
                                                <div style={{ flexShrink: 0 }}>
                                                    <InsightIcon type={indBuyPerc <= 40 ? "Negative" : buyPer <= 60 ? "Neutral" : "Positive"} height="2rem" width="2rem" padding="0.5rem" />
                                                </div>
                                                <div className="textXS dark4 w-500">
                                                    {fundData?.proper_name} on 1D timeframe is bullish for {indBuyNum} out of {indTotal} technical oscillators and moving averages.
                                                </div>
                                            </div>
                                            {/* <CollapseText triggerText='Detail >' content={
                                            <div className='textXS w-500 dark4'>
                                            {fundData?.proper_name}  is above {buySentiment} out of {total} moving averages and is in bullish range for {buySentiment} out of {total} oscillators.
                                            </div>
                                        } /> */}
                                        </> : null}
                                </>
                        }
                    </>} moduleKey="ADVANCE_STOCK_REPORT" upgradeComponent={<div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-end", padding: "1.5rem 0" }}>
                        <UpgradeToPro moduleKey="ADVANCE_STOCK_REPORT" height="fit-content" descClassName="textXS w-500 dark3 mb-3" zIndex={2} />
                    </div>} />
                </Card>
                {/* <Card style={{ border: "1px solid var(--grey3)" }} className="flexCard" bodyStyle={{ padding: "1rem" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "0.75rem", justifyContent: "space-between" }}>
                        <div className='textSM w-500'>
                            RSI
                        </div>
                        <div style={{ color: "var(--primary)", cursor: "pointer", textAlign: "center" }} className='textXS w-500' onClick={() => changeMetricObj(329)} >
                            What is RSI?
                        </div>
                    </div>
                    <div style={{}}>
                        {fundData?.rsi_14 !== null ?
                            <StockScoresGauge2 val={Number(fundData?.rsi_14?.toFixed(2))} height={170} labelSuffix={""} labelFont={"16px"} showTickLabels={true} paneThickness={17} min={0}
                                max={100} tickAmount={5} tickInterval={25} yAxisLabels={{ 25: "Oversold", 50: "Balanced", 75: "Overbought" }} yAxisLabelClass='analystRatingGauge' isRSI={true}
                            /> : <div style={{ padding: "1rem 0" }}>
                                <NoDataFound width='6rem' textAlign='left' message={<div className='textXS dark4' style={{ textAlign: "left" }}>
                                    Looks like we do not have RSI data for this stock. This could be because the stock has not been trading frequently or a glitch in our data. Do report in case you feel it’s a glitch.
                                </div>} />
                            </div>
                        }
                    </div>
                    {fundData?.rsi_14 !== null && <div style={{ display: "flex", gap: "0.5rem", }}>
                        <div style={{ flexShrink: 0 }}>
                            <InsightIcon type={Number(fundData?.rsi_14?.toFixed(2)) <= 30 ? "Negative" : Number(fundData?.rsi_14?.toFixed(2)) <= 70 ? "Neutral" : "Negative"} height="2rem" width="2rem" padding="0.5rem" />
                        </div>
                        <div className="textXS w-500 dark4">
                            RSI is {Number(fundData?.rsi_14?.toFixed(2))}.
                            {Number(fundData?.rsi_14?.toFixed(2)) <= 30
                                ? `Relative Strength Index of ${Number(fundData?.rsi_14?.toFixed(2))} indicates that the stock is in oversold zone.`
                                : Number(fundData?.rsi_14?.toFixed(2)) <= 70
                                    ? `Relative Strength Index of ${Number(fundData?.rsi_14?.toFixed(2))} indicates that the stock is in balanced zone.`
                                    : `Relative Strength Index of ${Number(fundData?.rsi_14?.toFixed(2))} indicates that the stock is in overbought zone.`}
                        </div>
                    </div>}
                </Card> */}
            </div>
        </div>
    );
}, arePropsEqual);

export const KeyTalkingPoints = React.memo(({ stockInsights = {}, loadingStockInsights, screenerMetricDescData = [], isMobile }) => {

    const dispatch = useDispatch();

    const DisplayViews = ({ data = [] }) => {
        const InsightView = ({ viewType = "" }) => {
            return (
                <Card style={{ background: "var(--white)", border: "1px solid var(--grey3)", borderRadius: "var(--borderRadius)", }} className="flexCard"
                    bodyStyle={{ padding: "1.25rem", borderRadius: "var(--borderRadius)", }}>
                    <div className="textMD w-500" style={{ marginBottom: "0.75rem" }}>
                        {viewType}
                    </div>
                    {data?.filter((ele) => ele?.stance === viewType)?.length > 0 ? (
                        <Collapse accordion bordered={false} style={{ background: "var(--white)" }} expandIcon={({ isActive }) => (
                            <IoIosArrowDown style={{ fontSize: "0.75rem", transform: isActive ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.2s ease-in-out", }} />)}
                            className="blogAndFaqs" expandIconPosition="end" items={data?.filter((ele) => ele?.stance === viewType)?.map((item) => {
                                let metricObj = screenerMetricDescData?.find((el) => el?.desc_id === Number(item?.desc_id)) || {};
                                let keys = Object.keys(item?.chart_data?.data?.[0] || {});
                                return {
                                    key: item?.desc_id,
                                    label: <div style={{ display: "flex", alignItems: "center", gap: "1rem", }}>
                                        <InsightIcon type={item?.stance} height="2rem" width="2rem" padding="0.5rem" />
                                        <div style={{ flex: 1 }} className="textSM w-500">
                                            {item?.heading}
                                        </div>
                                    </div>,
                                    children: <>
                                        <div className="textXS w-500 dark4">{item?.text}</div>
                                        {metricObj?.desc_id && (<div className="textXS w-500" style={{ cursor: "pointer", color: "var(--primary)", marginTop: "1rem", }}
                                            onClick={() => {
                                                dispatch(toggleMetricDescModal({
                                                    value: true,
                                                    params: {
                                                        title: metricObj?.name,
                                                        description: metricObj?.description
                                                    }
                                                }))
                                            }}>
                                            What is {metricObj?.name}?
                                        </div>
                                        )}
                                        {item?.chart_type === "NA" ? null : (<div style={{ marginTop: "1rem" }}>
                                            {item?.chart_type === "RSL" ? (<div>
                                                <div className="textXS dark4 w-500">Median: {item?.chart_data?.data?.median}</div>
                                                <StaticSlider2 min={item?.chart_data?.data?.min} max={item?.chart_data?.data?.max} val={item?.chart_data?.data?.val}
                                                    othdata={item?.chart_data?.data?.median} othText={"Median"} />
                                            </div>
                                            ) : item?.chart_type === "BAR" ? (
                                                <div>
                                                    <ColumnChart chartName={item?.chart_data?.name} chartData={JSON.stringify(item?.chart_data?.data || "[]")} xAxisKey={keys[0]} yAxisKey={keys[1]}
                                                        showYAxis={false} showDataLabels={true} height={200} isQuaterly={true} />
                                                    {item?.chart_data?.desc_id && (<div className="textXS w-500" style={{ cursor: "pointer", color: "var(--primary)", marginTop: "1rem", }}
                                                        onClick={() => {
                                                            let ob = screenerMetricDescData?.find((el) => el?.desc_id === Number(item?.chart_data?.desc_id)) || {};
                                                            dispatch(toggleMetricDescModal({
                                                                value: true,
                                                                params: {
                                                                    title: ob?.name,
                                                                    description: ob?.description
                                                                }
                                                            }))
                                                        }}>
                                                        What is {item?.chart_data?.name}?
                                                    </div>
                                                    )}
                                                </div>
                                            ) : item?.chart_type === "GSCORE" ? (
                                                <StockScoresGauge2 val={Number(item?.chart_data?.data)} height={200} labelSuffix={""} labelFont={"16px"} showTickLabels={true} tickAmount={5}
                                                    tickInterval={25} paneThickness={20} />
                                            ) : null}
                                        </div>
                                        )}
                                    </>,
                                    style: { borderTop: "1px solid var(--grey3)", borderBottom: "0px", }
                                }
                            })} />
                    ) : (
                        <div>
                            <div className='textSM w-500 dark3'>{`Not enough talking point with ${viewType?.toLowerCase()} stance`}</div>
                        </div>
                    )}
                </Card>
            );
        };
        return (
            <div className="flexRowCard" style={{ flexDirection: isMobile ? "column" : "row" }}>
                <InsightView viewType="Positive" />
                <InsightView viewType="Negative" />
                <InsightView viewType="Neutral" />
            </div>
        );
    };

    const DisplayRedFlags = ({ data = [] }) => {
        return (
            <div style={{ height: "calc(100%)", overflowY: "scroll", display: "flex", flexDirection: "column", gap: "0.75rem", }} id="">
                {data?.map((item) => {
                    const dataTableColumns = Object.keys(item?.stance_table?.[0] || {}).map((k) => {
                        if (k === "symbol") {
                            return undefined;
                        } else if (k === "proper_name") {
                            return {
                                title: "Name",
                                dataIndex: "proper_name",
                                render: (text, record, index) => (
                                    <div style={{ display: "flex", gap: "12px", cursor: "pointer", }}>
                                        <AMCIcon amcName={record.symbol} fundType={4} height="1.2rem" width="1.2rem" bseTicker={record?.bse_ticker} />
                                        <div>
                                            <div className="textSM dark3 black">
                                                {record?.proper_name}
                                            </div>
                                        </div>
                                    </div>),
                                fixed: "left",
                            };
                        } else
                            return {
                                title: "Value",
                                dataIndex: k,
                                align: "right",
                            };
                    }).filter((x) => x);

                    return (
                        <Card key={generateUniqueKey(item.param_id)} style={{ borderRadius: "12px", border: "1px solid var(--grey2)" }} bodyStyle={{ padding: 0 }}>
                            <div style={{ display: "flex", gap: "1rem", padding: "0.625rem 1.25rem", }}>
                                <InsightIcon type="RedFlag" isRedFlag={true} redFlagType={item?.stance} width='2rem' height='2rem' padding='0.5rem' />
                                <div style={{ flex: 1 }}>
                                    <div className="fontSM w-500 dark3 mb-2">{item?.name}</div>
                                    <div className="textSM dark3">{item?.stance_text}</div>
                                    {item?.stance_table && (
                                        <div className="mt-2 me-4">
                                            <Table className="insideTable" pagination={false} dataSource={item.stance_table} columns={dataTableColumns} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Card>
                    );
                })}
            </div>
        );
    };

    const tabItems = [
        {
            label: "Views",
            key: "views",
            value: "views",
            children: <DisplayViews data={stockInsights?.views} />,
        },
        {
            label: "Red Flags",
            key: "redflags",
            value: "redflags",
            children: <DisplayRedFlags data={stockInsights?.red_flags} />,
        },
    ];

    const [selectedTabItem, setSelectedTabItem] = React.useState("views");

    return (
        <div style={{}}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }} className="margin20Bottom">
                <CustomSegmentedTab options={tabItems} value={selectedTabItem} onChange={(e) => setSelectedTabItem(e)} isBlock={false} keepSameInMobile />
            </div>
            {loadingStockInsights ? (
                <ActivityLoaderMintbox />
            ) : (
                <div>
                    {tabItems?.find((ele) => ele?.value === selectedTabItem)?.children}
                </div>
            )}
        </div>
    );
}, arePropsEqual);

export const StyleAnalysis = React.memo(({ screenerMetricDescData, dispatch, loadinghistoricalCharts, historicalCharts, screenerMetric, scorecard, loadingScorecard }) => {

    let metricColCodes = ["sh_val_comp", "sh_mom_comp", "sh_qual_comp", "sh_3fac_comp"];
    let chartNames = ["Value score", "Momentum score", "Quality score", "QVM score",];
    let charts = [];

    Object.values(historicalCharts?.["sharpely_scores"] || {})?.map((tab) => {
        let c = tab?.charts?.filter((ele) => chartNames?.includes(ele?.chart_name)) || [];
        charts = [...charts, ...c];
    });

    let scoreTexts = [
        { type: "Value score", texts: [{ text: "is overvalued relative", min: 0, max: 30 }, { text: "is reasonably valued relative", min: 30, max: 70 }, { text: "is undervalued relative", min: 70, max: 100 }] },
        { type: "Momentum score", texts: [{ text: "has low momentum relative", min: 0, max: 30 }, { text: "has moderate momentum relative", min: 30, max: 70 }, { text: "has high momentum relative", min: 70, max: 100 }] },
        { type: "Quality score", texts: [{ text: "has low quality relative", min: 0, max: 30 }, { text: "has moderate quality relative", min: 30, max: 70 }, { text: "has high quality relative", min: 70, max: 100 }] },
        { type: "QVM score", texts: [{ text: "has low QVM score with respect", min: 0, max: 30 }, { text: "has mmoderate QVM score with respect", min: 30, max: 70 }, { text: "has high QVM score with respect", min: 70, max: 100 }] },
    ]

    let data = screenerMetric?.filter((ele) => metricColCodes.includes(ele?.metric_col_code))?.map((met) => {

        return {
            key: met?.key,
            name: met?.metric_name || "",
            chart: charts?.find((ele) => ele?.chart_name === met?.metric_name),
            desc_id: met?.desc_id,
        }
    })

    const tabItems = [
        { label: "Market", value: "mkt", key: 0 },
        { label: "Sector", value: "sec", key: 1 },
        { label: "Industry Group", value: "indgrp", key: 2 },
        { label: "Industry", value: "ind", key: 3 },
    ];

    console.log(data, charts);

    const [selectedTabItem, setSelectedTabItem] = React.useState("mkt");
    const [historicalChartToShow, setHistoricalChartToShow] = React.useState({ isOpen: false, chart: {}, });

    let x = Object.keys(JSON.parse(charts?.[0]?.chart_data || "[]")?.[0] || {})?.[0];

    const changeChartsToShow = (chart) => {
        setHistoricalChartToShow({ isOpen: true, chart });
    };

    const NoChartData = ({ name }) => {
        const dispatch = useDispatch();
        return (
            <div style={{ display: "flex", flexDirection: "column", gap: "0.75rem" }}>
                <div className="textXS black mt-2">
                    Seems like we do not have {name} for this stock. This could happen due to following reasons:
                </div>
                <div className="textXS black">
                    <div>
                        1. Market capitalisation of the stock is less than Rs 100 crore. Currently, {name} is available only for stocks with market cap greater than Rs 100 crore.
                    </div>
                    <div>
                        2. Something went wrong with our server
                    </div>
                </div>
                <div className="textXS black">
                    Do{" "}<span onClick={() => { dispatch(toggleHelpAndSupp(true)); }} style={{ color: "var(--primary)", cursor: "pointer" }}>report
                    </span>{" "}this issue if you think that we should have insights for this stock.
                </div>
            </div>
        );
    };

    return (
        <div style={{}}>
            <div className="d-flex align-items-center justify-content-between margin20Bottom">
                {!loadingScorecard && (
                    <CustomSegmentedTab options={tabItems} value={selectedTabItem} onChange={(e) => setSelectedTabItem(e)} isBlock={false} keepSameInMobile />
                )}
            </div>
            {loadingScorecard ? (
                <ActivityLoaderMintbox />
            ) : (
                <div style={{ display: "flex", gap: "1.25rem", marginTop: "1.25rem", overflow: 'auto' }}>
                    {data?.map((score, i) => {
                        let scoreVal = scorecard?.[score?.key]?.[selectedTabItem]?.score
                        scoreVal = (scoreVal || scoreVal === 0) ? Number(scoreVal?.toFixed(1)) : scoreVal
                        return (
                            <div style={{
                                border: "1px solid var(--grey2)", borderRadius: "var(--borderRadius)", padding: "1rem", display: "flex", justifyContent: "center", alignItems: "",
                                flex: 1, background: "var(--white)", minWidth: window.innerWidth < 760 ? '22rem' : ''
                            }} key={i}>
                                <div style={{ minWidth: "13rem", flex: 1 }}>
                                    <div className='d-flex align-items-center justify-content-between' style={{ gap: "1rem" }}>
                                        <div className="textSM w-500">
                                            {score?.name}
                                        </div>
                                        {selectedTabItem === "mkt" && (<div className="textXS w-500" style={{ color: "var(--primary)", textAlign: "right", cursor: "pointer", }}
                                            onClick={() => { changeChartsToShow(score?.chart); }}>
                                            Historical data &gt;
                                        </div>
                                        )}
                                    </div>
                                    {!scoreVal && scoreVal !== 0 ? <div style={{ padding: "1rem 0" }}>
                                        <NoDataFound width='6rem' textAlign='left' message={<div className='textXS dark4' style={{ textAlign: "left" }}>
                                            The score is not available for this stock. This could be because (a) the market cap of the stock is less than Rs 100 crore, (b) the company has not reported any new financial information in the last 6 months or, (c) there is some issue with our data. Do report in case you feel it’s an issue.
                                            {/* {selectedTabItem === 'mkt' ? "The score is not available for this stock. This could be because (a) the market cap of the stock is less than Rs 100 crore, or (b) there is some issue with our data. Do report in case you feel it’s an issue." : selectedTabItem === 'sec' ? "The score is not available for this stock. This could be because (a) the market cap of the stock is less than Rs 100 crore, or (b) there are not enough stocks in the sector for meaningful score calculation or (c) there is some issue with our data. Do report in case you feel it’s an issue." : selectedTabItem === 'indgrp' ? "The score is not available for this stock. This could be because (a) the market cap of the stock is less than Rs 100 crore, or (b) there are not enough stocks in the industry group for meaningful score calculation or (c) there is some issue with our data. Do report in case you feel it’s an issue." : "The score is not available for this stock. This could be because (a) the market cap of the stock is less than Rs 100 crore, or (b) there are not enough stocks in the industry for meaningful score calculation or (c) there is some issue with our data. Do report in case you feel it’s an issue."} */}
                                        </div>} />
                                    </div>
                                        : <>
                                            <div>
                                                <StockScoresGauge2 val={scoreVal} fixValue={true} decimals={1} height={170} paneThickness={17} showTickLabels={true} tickAmount={5}
                                                    labelSuffix={'/ 100'} />
                                            </div>
                                            <div style={{ display: "flex", gap: "0.5rem", marginBottom: "1rem" }}>
                                                <div style={{ flexShrink: 0 }}>
                                                    <InsightIcon type={scoreVal <= 30 ? "Negative" : scoreVal <= 70 ? "Neutral" : "Positive"}
                                                        height="2rem" width="2rem" padding="0.5rem" />
                                                </div>
                                                <div className='textXS w-500 dark4'>
                                                    This indicates that the stock {scoreTexts?.find((el) => el?.type === score?.name)?.texts?.find((el) => scoreVal >= el?.min && scoreVal <= el?.max)?.text} to other stocks in the {tabItems?.find((el) => el.value === selectedTabItem)?.label?.toLowerCase()}
                                                </div>
                                            </div>
                                        </>}
                                    <div className="textXS w-500 text-center" style={{ color: "var(--primary)", cursor: "pointer" }}
                                        onClick={() => {
                                            let met = screenerMetricDescData?.find((ele) => ele?.desc_id === score?.desc_id);
                                            dispatch(toggleMetricDescModal({
                                                value: true,
                                                params: {
                                                    title: met?.name,
                                                    description: met?.description
                                                }
                                            }))
                                        }}>
                                        What is {score?.name}?
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            <Modal open={historicalChartToShow?.isOpen} footer={<button onClick={() => setHistoricalChartToShow({})} className="btnBlack ">Close</button>}
                onCancel={() => setHistoricalChartToShow({})} onOk={() => setHistoricalChartToShow({})} title={historicalChartToShow?.chart?.chart_name}
                width={"min(50rem, 100%)"}>
                {loadinghistoricalCharts ? (
                    <ActivityLoaderMintbox />
                ) : (
                    <div>
                        <div style={{ margin: "1rem 0 1.25rem" }}>
                            <CustomLabelValueCards x={Object.keys(historicalChartToShow?.chart?.chart_text_items || {})?.map((k, i) => {
                                return {
                                    key: i,
                                    heading: k,
                                    value: historicalChartToShow?.chart?.chart_text_items?.[k]?.toFixed(1) || '-',
                                };
                            })} padding="0.75rem 1rem" minWidth="10rem" />
                        </div>
                        {historicalChartToShow?.chart?.chart_data && JSON.parse(historicalChartToShow?.chart?.chart_data || "[]")?.length <= 0 ? <div>
                            <NoChartData name={historicalChartToShow?.chart?.chart_name} />
                        </div> :
                            <div style={{ flex: 1, marginBottom: "-1rem" }}>
                                <PerformanceChart3 fundName={historicalChartToShow?.chart?.chart_name} chartData={historicalChartToShow?.chart?.chart_data || "[]"} xAxisKey={x} chartType={"areaspline"}
                                    showYAxis={true} showDataLabels={true} height={200} />
                            </div>}
                    </div>
                )}
            </Modal>
        </div>
    );
}, arePropsEqual);

export const Scorecard = React.memo(({ loadingScorecard, scorecard, screenerFinalData, basicIndustriesCurrent, fundData, industriesCurrent,
    sectorsCurrent, type }) => {

    const dispatch = useDispatch();

    const CustomSmallProgressBar2 = ({ rank, total, metricObj, score, type }) => {
        let color = "var(--yellow)";
        if (score < 33.33) {
            color = "var(--red)";
        } else if (score > 66.66) {
            color = "var(--green)";
        }
        const Tooltip = () => {
            return (
                <div style={{ whiteSpace: "nowrap" }}>
                    <div className="textXS black">
                        Rank: <b>{rank}</b>/{total} (Sorted in{" "}
                        <b>{metricObj?.direction_flag === "top" ? "desc" : "asc"}</b> order)
                    </div>
                    <div className="textXS black">
                        Score: <b>{score.toFixed(1)}</b>/100
                    </div>
                </div>
            );
        };
        const NotEnoughData = () => {
            return (
                <div style={{ whiteSpace: "nowrap" }}>
                    <div className="textXS black">
                        Not enough stocks in this {type}
                        <br /> to calculate scores and rank
                    </div>
                </div>
            );
        };
        return (
            <Popover content={total < 4 ? <NotEnoughData /> : <Tooltip />} title="" placement="top" arrow={false}>
                <div className="custom-small-progressbar">
                    <Progress percent={score} showInfo={false} trailColor={"var(--grey2)"} strokeColor={color} type="line" style={{ borderRadius: "4px" }} />
                </div>
            </Popover>
        );
    };

    const ScoreBoardTablesView = ({ data = [] }) => {

        const sharedOnCell = (record, index) => {
            if (record.isSectionHeader) {
                return { colSpan: 0 };
            }
            return {};
        };

        const columns = [
            {
                title: "",
                dataIndex: "metric_name",
                width: "10rem",
                render: (text, record) =>
                    record.isSectionHeader ? (
                        <span className="textSM w-700 black">{text}</span>
                    ) : (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div>{text}</div>
                            <AiOutlineInfoCircle style={{ marginLeft: "0.25rem", cursor: "pointer" }} onClick={() => {
                                dispatch(toggleMetricDescModal({
                                    value: true,
                                    params: {
                                        title: record?.title,
                                        description: record?.metric_desc
                                    }
                                }))
                            }}
                                className="infoIcon" />
                        </div>
                    ),
                onCell: (record, index) => {
                    if (record.isSectionHeader) {
                        return { colSpan: 6 };
                    } else return {};
                },
            },
            {
                title: "",
                dataIndex: "val",
                align: "right",
                className: "blueBackground",
                width: "6rem",
                render: (text, record) => (
                    <div style={{ textAlign: "center", fontWeight: "700" }}>
                        {displayValue({ text, metricObj: record })}
                    </div>
                ),
                onCell: sharedOnCell,
            },
            {
                title: (
                    <div>
                        <div>Market</div>
                        <div className="dark3 w-400" style={{ fontSize: "0.625rem", lineHeight: "0.75rem" }}>
                            (All Stocks in NSE)
                        </div>
                    </div>
                ),
                dataIndex: "mkt",
                align: "left",
                width: "6rem",
                render: (text, record) =>
                    record.val !== null ? (
                        <CustomSmallProgressBar2 rank={Number(text?.rank)} total={Number(text?.count)} metricObj={record} score={Number(text?.score)}
                            tooltipContent={
                                <span className="textXS">
                                    {fundData?.proper_name}'s
                                    <b> {record?.metric_name}</b> is ranked{" "}
                                    <b>{ordinal_suffix_of(text?.rank)}</b> out of{" "}
                                    <b>{text?.count}</b> stocks in the <b>Indian</b>{" "}
                                    market
                                </span>
                            } type="market" />
                    ) : (
                        "-"
                    ),
                onCell: sharedOnCell,
            },
            {
                title: (
                    <div>
                        <div>Sector</div>
                        <div className="dark3 w-400" style={{ fontSize: "0.625rem", lineHeight: "0.75rem" }}>
                            ({sectorsCurrent})
                        </div>
                    </div>
                ),
                dataIndex: "sec",
                align: "left",
                width: "6rem",
                render: (text, record) =>
                    record.value !== null ? (
                        <CustomSmallProgressBar2 rank={Number(text?.rank)} total={Number(text?.count)} metricObj={record} score={Number(text?.score)}
                            tooltipContent={
                                <span className="textXS">
                                    {fundData?.proper_name}'s
                                    <b> {record?.metric_name}</b> is ranked{" "}
                                    <b>{ordinal_suffix_of(text?.rank)}</b> out of{" "}
                                    <b>{text?.count}</b> stocks in the{" "}
                                    <b>{sectorsCurrent}</b> sector
                                </span>
                            } type="sector" />
                    ) : (
                        "-"
                    ),
                onCell: sharedOnCell,
            },
            {
                title: (
                    <div>
                        <div>Industry Group</div>
                        <div className="dark3 w-400" style={{ fontSize: "0.625rem", lineHeight: "0.75rem" }}>
                            ({industriesCurrent})
                        </div>
                    </div>
                ),
                dataIndex: "indgrp",
                align: "left",
                width: "6rem",
                render: (text, record) =>
                    record.value !== null ? (
                        <CustomSmallProgressBar2 rank={Number(text?.rank)} total={Number(text?.count)} metricObj={record} score={Number(text?.score)}
                            tooltipContent={
                                <span className="textXS">
                                    {fundData?.proper_name}'s
                                    <b> {record?.metric_name}</b> is ranked{" "}
                                    <b>{ordinal_suffix_of(text?.rank)}</b> out of{" "}
                                    <b>{text?.count}</b> stocks in the{" "}
                                    <b>{industriesCurrent}</b> industry group
                                </span>
                            } type="industry group" />
                    ) : (
                        "-"
                    ),
                onCell: sharedOnCell,
            },
            {
                title: (
                    <div>
                        <div>Industry</div>
                        <div className="dark3 w-400" style={{ fontSize: "0.625rem", lineHeight: "0.75rem" }}>
                            ({basicIndustriesCurrent})
                        </div>
                    </div>
                ),
                dataIndex: "ind",
                align: "left",
                width: "6rem",
                render: (text, record) =>
                    record.value !== null ? (
                        <CustomSmallProgressBar2 rank={Number(text?.rank)} total={Number(text?.count)} metricObj={record} score={Number(text?.score)}
                            tooltipContent={
                                <span className="textXS">
                                    {fundData?.proper_name}'s
                                    <b> {record?.metric_name}</b> is ranked{" "}
                                    <b>{ordinal_suffix_of(text?.rank)}</b> out of{" "}
                                    <b>{text?.count}</b> stocks in the{" "}
                                    <b>{basicIndustriesCurrent}</b> industry
                                </span>
                            } type="industry" />
                    ) : (
                        "-"
                    ),
                onCell: sharedOnCell,
            },
        ];
        return (
            <>
                <Table dataSource={data} columns={columns} size={"small"} rowClassName={(record) => record.isSectionHeader ? "greyBackground" : "showInfoOnHoverRow"}
                    className="mt-3 scoreCardModalTable"
                    pagination={false} scroll={{ x: "max-content" }} />
            </>
        );
    };

    const hiddenCategories = ["Stock Scores"];

    const tabItems = screenerFinalData.map((s, i) => {
        var data = [];
        s.sub_category.map((cat) => {
            var a = cat.metric?.filter((x) => scorecard?.hasOwnProperty(x?.metric_col_code));
            if (a?.length > 0) {
                a = a?.map((item) => {
                    let h = scorecard?.[item?.metric_col_code];
                    return {
                        ...item,
                        ...h
                    }
                })
                data = [
                    ...data,
                    {
                        key: cat.sub_category_name,
                        metric_name: cat.sub_category_name,
                        isSectionHeader: true,
                    },
                    ...a,
                ];
            }
        });
        return {
            label: (
                <h3 className="margin0 w-500 font-inherit lineHeightUnset">
                    {s.category_name}
                </h3>
            ),
            key: i,
            value: i,
            children: <ScoreBoardTablesView data={data} />,
            show: hiddenCategories.includes(s.category_name) === true ? false : data.length > 0,
        };
    }).filter((x) => x.show);

    const [selectedTabItem, setSelectedTabItem] = React.useState(tabItems?.[0]?.key);

    return (
        <>
            {loadingScorecard ? (
                <ActivityLoaderMintbox />
            ) : (
                <>
                    <div style={{ overflow: "auto" }}>
                        <CustomSegmentedTab isBlock={false} value={selectedTabItem} mobileStyles={{}} onChange={(e) => { setSelectedTabItem(e); }} options={tabItems} keepSameInMobile />
                    </div>
                    {tabItems.filter((i) => i.value === selectedTabItem)[0]?.children}
                </>
            )}
        </>
    );
}, arePropsEqual);

export const Checklist = React.memo(({ allCheckLists = {}, loadingAllChecklists, screenerMetricDescData = [], fundData = {}, screenerMetric = [] }) => {

    const dispatch = useDispatch();

    const ChecklistView = ({ data, type, id = '' }) => {

        const passed = data?.checklist_val_data?.filter((f) => f.pass)?.length;
        const total = data?.checklist_val_data?.length;
        // console.log(data);
        const handleModal = (metricObj = {}) => {
            dispatch(toggleMetricDescModal({
                value: true,
                params: {
                    title: metricObj?.name,
                    description: metricObj?.description
                }
            }))
        };

        const AnalysisView = ({ viewType = 'Hits' }) => {
            return (
                <React.Fragment>
                    <Card style={{ background: "var(--white)", border: "1px solid var(--grey3)", borderRadius: "var(--borderRadius)", }} className="flexCard shadowMD"
                        bodyStyle={{ padding: "1.25rem", borderRadius: "var(--borderRadius)", }}>
                        <div className="textLG w-700" style={{ marginBottom: "0.75rem" }}>
                            {viewType}
                        </div>
                        {data?.checklist_val_data?.filter((ele) => viewType === 'Hits' ? ele?.pass : !ele?.pass)?.length <= 0 ? (
                            <NoDataFound message={viewType === 'Hits' ? "Oops! It seems like this stock has not passed any of the checklist's rules" :
                                "Woah! It seems like this stock has passed all of the checklist's rules."} width='4rem' />) :
                            <Collapse accordion bordered={false} style={{ background: "var(--white)" }} expandIcon={({ isActive }) => (
                                <IoIosArrowDown style={{ fontSize: "0.75rem", transform: isActive ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.2s ease-in-out", }} />)}
                                className="blogAndFaqs" expandIconPosition="end"
                                items={data?.checklist_val_data?.filter((ele) => viewType === 'Hits' ? ele?.pass : !ele?.pass)?.map((q) => {
                                    const metricDescObj = screenerMetricDescData?.find((m) => m.desc_id === q?.primary_col_details?.desc_id);
                                    const metricObj = screenerMetric?.find((m) => m?.metric_col_code === q?.primary_col_details?.col_code);
                                    const val = q?.primary_col_details?.val;
                                    const secMetricObj = screenerMetric?.find((m) => m.desc_id === q?.secondary_val_details?.desc_id);
                                    const secName = q?.secondary_val_details?.name;
                                    const secVal = q?.secondary_val_details?.val;
                                    return {
                                        key: generateUniqueKey(q.query_id),
                                        style: { borderTop: "1px solid var(--grey3)", borderBottom: "0px", },
                                        label: <div style={{ display: "flex", alignItems: "center", gap: "1rem", }}>
                                            {q.pass ? <InsightIcon height='2rem' width='2rem' type='Positive' padding='0.5rem' /> :
                                                <InsightIcon height='2rem' width='2rem' type='Negative' padding='0.5rem' />}
                                            <div style={{ flex: 1 }} className="textSM w-500">
                                                {q.name}
                                            </div>
                                        </div>,
                                        children: <div>
                                            <div className="fontH2">
                                                <b>Rule: </b>
                                                {q.name}
                                            </div>
                                            <div className="fontH2 w-500">
                                                <b>{metricDescObj?.name || "Value"}: </b>
                                                <span style={{ color: q.pass ? "var(--green)" : "var(--red)", }}>
                                                    {displayMetricValue({ text: val, metricObj: metricObj ? metricObj : metricDescObj })}
                                                </span>
                                            </div>
                                            {secName && (
                                                <div className="fontH2 w-500">
                                                    <b>{secName?.[0]?.toUpperCase() + secName?.substring(1)}:{" "}</b>
                                                    <span style={{ color: q.pass ? "var(--green)" : "var(--red)", }}>
                                                        {displayMetricValue({ text: secVal, metricObj: secMetricObj, })}
                                                    </span>
                                                </div>
                                            )}
                                            <div onClick={() => handleModal(metricDescObj)} className="textXS w-500" style={{
                                                color: "var(--primary)", cursor: "pointer",
                                                marginTop: "1rem",
                                            }}>
                                                What is {metricDescObj?.name}?
                                            </div>
                                        </div>
                                    }
                                })} />
                        }
                    </Card>
                </React.Fragment>
            )
        }

        return (
            <>
                <Card bodyStyle={{ padding: window.innerWidth > 760 ? "1.25rem" : 0, borderRadius: "var(--borderRadius)", }} style={{ borderRadius: "var(--borderRadius)" }}>
                    <div className="mainContainer margin20Bottom">
                        {data.description && (
                            <Card style={{ borderRadius: "var(--borderRadius)", border: "1px solid var(--grey2)", }} className="flexCard"
                                bodyStyle={{ padding: "1.25rem", borderRadius: "var(--borderRadius)", }}>
                                {type === "guru" && (
                                    <div className="mb-2">
                                        <ScreenerIcon type="guru" id={Number(id?.split('_')?.reverse()?.[0])} width="4rem" height="4rem" />
                                    </div>
                                )}
                                <div className="dark4 textSM">
                                    <span dangerouslySetInnerHTML={{ __html: `${data.description?.substring(0, 240)}` }} className="p-0 metricDescDrawer textSM p-inline"></span>
                                    {data.description?.length > 240 && "..."}
                                    {data.description?.length > 240 && (
                                        <span className="" style={{ color: "var(--primary)", cursor: "pointer", }} onClick={() => {
                                            dispatch(toggleMetricDescModal({
                                                value: true,
                                                params: {
                                                    title: data?.vertical_col,
                                                    description: data?.description
                                                }
                                            }))
                                        }}>
                                            Read More
                                        </span>
                                    )}
                                </div>
                            </Card>
                        )}
                        <Card style={{ borderRadius: "var(--borderRadius)", border: "1px solid var(--grey2)", }} className="flexCard"
                            bodyStyle={{ padding: "0", borderRadius: "var(--borderRadius)", }}>
                            <div className="d-flex flex-column align-items-center">
                                <div>
                                    <StockScoresGauge2 val={Number(((passed * 100) / total).toFixed(0))} height={170} labelSuffix={"%"} labelFont={"16px"} showTickLabels={false}
                                        paneThickness={20} />
                                </div>
                                <div className="textMD dark3" style={{ padding: '1.25rem' }}>
                                    <b>{fundData?.proper_name}</b> passes <b className="">{passed}</b> out of<b className=""> {total}</b> of this checklist's rules
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="dark4 displayXS margin20Bottom" style={{ fontWeight: 700 }}>
                        Checklist analysis
                    </div>
                    <div className="mainContainer">
                        <AnalysisView viewType='Hits' />
                        <AnalysisView viewType='Misses' />
                    </div>
                </Card>
            </>
        );
    };

    const customchecklist = Object.entries(allCheckLists?.custom || {}).map(([id, c], i) => {
        return {
            label: c.vertical_col,
            key: c.vertical_col,
            children: <ChecklistView data={c} id={id} />,
            show: true,
            noHeader: false,
        };
    }
    );

    const guruchecklist = Object.entries(allCheckLists?.guru || {}).map(
        ([id, c], i) => {
            return {
                label: c.vertical_col,
                key: c.vertical_col,
                children: <ChecklistView data={c} type="guru" id={id} />,
                show: true,
                noHeader: false,
            };
        }
    );

    let tabs = [
        {
            label: "Guru Checklist",
            key: "guruChecklist",
            tabs: guruchecklist,
            value: 1,
            show: guruchecklist?.length > 0,
            children: CustomSideTabs({ tabData: guruchecklist || [], hideLRPadInMob: true }),
        },
        {
            label: "Custom Checklist",
            key: "customChecklist",
            tabs: customchecklist,
            value: 2,
            show: customchecklist?.length > 0,
            children: CustomSideTabs({ tabData: customchecklist || [], hideLRPadInMob: true }),
        },
    ];

    tabs = tabs?.filter((el) => el?.show);
    console.log(tabs);
    let [stab, setStab] = React.useState(1);

    return (
        <div style={{ flex: 1 }} >
            <div className="d-flex align-items-center justify-content-between margin20Bottom">
                {!loadingAllChecklists && (<CustomSegmentedTab options={tabs} value={stab} onChange={(e) => setStab(e)} isBlock={false} />)}
            </div>
            {loadingAllChecklists ? (<ActivityLoaderMintbox />) : (
                <>{tabs?.find((ele) => ele.value === stab)?.children}</>)}
        </div>
    );
}, arePropsEqual);