
import { baseImgURL } from 'Assets/Constant'
import React from 'react'

const mintboxList = [
  { Id: 1, imgSource: baseImgURL + 'MintboxIcon1.webp' },
  { Id: 2, imgSource: baseImgURL + 'MintboxIcon2.webp' },
  { Id: 3, imgSource: baseImgURL + 'MintboxIcon3.webp' },
  { Id: 4, imgSource: baseImgURL + 'MintboxIcon4.webp' },
  { Id: 5, imgSource: baseImgURL + 'MintboxIcon5.webp' },
  { Id: 6, imgSource: baseImgURL + 'MintboxIcon6.webp' },
  { Id: 7, imgSource: baseImgURL + 'MintboxIcon7.webp' },
  { Id: 8, imgSource: baseImgURL + 'MintboxIcon8.webp' },
  { Id: 9, imgSource: baseImgURL + 'MintboxIcon9.webp' },
  { Id: 10, imgSource: baseImgURL + 'MintboxIcon10.webp' },
  { Id: 11, imgSource: baseImgURL + 'MintboxIcon11.webp' },
  { Id: 12, imgSource: baseImgURL + 'MintboxIcon12.webp' },
  { Id: 13, imgSource: baseImgURL + 'MintboxIcon13.webp' },
  { Id: 14, imgSource: baseImgURL + 'MintboxIcon14.webp' },
  { Id: 15, imgSource: baseImgURL + 'MintboxIcon15.webp' },
  { Id: 16, imgSource: baseImgURL + 'MintboxIcon16.webp' },
  { Id: 17, imgSource: baseImgURL + 'MintboxIcon17.webp' },
  { Id: 18, imgSource: baseImgURL + 'MintboxIcon14.webp' },
  { Id: 19, imgSource: baseImgURL + 'MintboxIcon19.webp' },
  { Id: 20, imgSource: baseImgURL + 'MintboxIcon20.webp' },
  { Id: 22, imgSource: baseImgURL + 'MintboxIcon1.webp' },
  { Id: 23, imgSource: baseImgURL + 'MintboxIcon2.webp' },
  { Id: 24, imgSource: baseImgURL + 'MintboxIcon3.webp' },
  { Id: 25, imgSource: baseImgURL + 'MintboxIcon4.webp' },
  { Id: 26, imgSource: baseImgURL + 'MintboxIcon5.webp' },
  { Id: 27, imgSource: baseImgURL + 'MintboxIcon19.webp' },
  { Id: 28, imgSource: baseImgURL + 'MintboxIcon14.webp' },
]

function MintboxIcon({ id, height, width }) {
  return (
    // <div style={{ display:'flex', border: "1px solid " + cssVar.getPropertyValue("--lowestRisk"),padding:`calc(${height}*10/100) 0`, borderRadius: '4px', height, width, backgroundColor: RiskColor(risk)}}>
    //     <img loading="lazy"  alt="" src={baseImgURL+'wealth-creatorswebp'} style={{height: `calc(${height}*80/100)`,margin:'auto'}}/>
    // </div>
    // Number(id) > 20 || id === undefined ?
    //   <img loading="lazy"  alt="" src={mintbaseImgURL + `mintbox_boxLogo.webp`} style={{ height, width }} />
    //   :
    <img loading="lazy"  alt="" src={mintboxList?.find((el) => el?.Id === Number(id)) ? mintboxList?.find((el) => el?.Id === Number(id))?.imgSource : mintboxList?.[0]?.imgSource}
      style={{ height, width }} />
  )
}

export default React.memo(MintboxIcon)