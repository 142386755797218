import { flaticonSvg } from 'Assets/Constant';
import { useGetAssetType } from 'Libs/utils';
import { StockLivePrice } from 'Services/api/LivePricesWebSocket';
import { Divider, Popover } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import React, { useState } from 'react';
import { BsFillSquareFill } from 'react-icons/bs';
import { MdEdit } from 'react-icons/md';
import { CurrencyFormat } from './CurrencyFormat';
import { InstrumentListSearch } from './CustomComponents/InstrumentListSearch';
import CustomSegmentedTab from './CustomSegmentedTab';
import { changeObjKey } from './DataCustomizer';
import { dayDiff, formatDateMMMDDYYYY, formatDateddmmyy } from './DateFormatter';
import RedGreenText from './RedGreenText';

const ScreenSignalBacktestChart = ({
    chartData = [],
    fundData = [],
    benchData = [],
    benchName = "",
    fundName = "",
    goLiveDate = "",
    portfolioType = 0,
    onBenchChange = () => { },
    defYrSelect = "1095D",
    defAmtSelect = "",
    showOnlySelectedKey = "",
    xAxisKey = "Date",
    showLiveTickerVal = false,
    fundColors = ['#007AFF', '#555770', '#83BF6E', '#FFD966', '#FF55BB', '#0062CC', '#AA55FF', '#FF9C55', '#FF6A55', '#1A1D1F', '#00FFFF', '#DBFF00'],
    isInstrumentPerf = true,
    isMetricPerf = false,
    key1 = "Portfolio",
    key2 = "Benchmark",
    is_live = 0,
    isWebSocket = false
}) => {
    // portfolioType 
    // 1 = curated , 
    // 2 = custom, 
    // 0 = for individual funds
    // -1 = for showing only one amount value 
    // 10 = metric perf
    // 11 = screener signal backtest
    // 12 = screener signal backtest


    const isMobile = window.innerWidth <= 575;
    defYrSelect = portfolioType === 1 ? "live" : defYrSelect
    fundName = fundName || fundData?.symbol || fundData?.nse_symbol || (portfolioType === 0 ? "Direct" : "")
    benchName = benchName || fundData?.[key2] || fundData?.bench_name || (portfolioType === 0 ? "Benchmark" : "")
    const type = useGetAssetType()
    ////console.log(chartData[defYrSelect])
    const yrtabs = chartData !== undefined ? [
        { key: 11, label: "1M", value: "30D", show: !isWebSocket },
        { key: 12, label: "6M", value: "180D", show: !isWebSocket },
        { key: 1, label: "1Y", value: "365D", show: !isWebSocket },
        { key: 3, label: "3Y", value: "1095D", show: !isWebSocket },
        { key: 5, label: "5Y", value: "1825D", show: !isWebSocket },
        { key: 10, label: "10Y", value: "3650D", show: !isWebSocket },
        { key: 0, label: "Max", value: "max" },
    ] : []

    var startDate_full = chartData !== undefined ? [...chartData]?.[0]?.[xAxisKey] || null : null
    var endDate_full = chartData !== undefined ? [...chartData]?.[chartData?.length - 1]?.[xAxisKey] || null : null

    var day_diff = 365000
    if (endDate_full !== null && startDate_full !== null) {
        day_diff = dayDiff(startDate_full, endDate_full)
        //console.log(day_diff)
    }

    var allowedTabs = yrtabs?.filter((i) => {
        var days = i?.value?.replace("D", '')
        var dateOffset = days === "max" ? true : Number(days) <= day_diff;
        return i.show !== false && dateOffset
    })

    //console.log(allowedTabs)
    if (!allowedTabs?.map((i) => i.value).includes(defYrSelect)) {
        defYrSelect = allowedTabs[allowedTabs.length - 1]?.value
    }

    //console.log(defYrSelect)
    const chipset = [
        { "key": "a", label: "₹ 5,000", value: 5000 },
        { "key": "b", label: "₹ 10,000", value: 10000 },
        { "key": "c", label: "₹ 50,000", value: 50000 },
    ]
    const [noChart, setnoChart] = React.useState(false);
    const [chartType, setChartType] = React.useState(portfolioType === 1 ? 'live' : defYrSelect)
    const [chartAmount, setChartAmount] = React.useState(defAmtSelect || chipset[1].value)

    const [finalChartData, setfinalChartData] = React.useState([])


    React.useEffect(() => {
        if (chartData !== undefined) {
            if (!isWebSocket) {
                var days = chartType?.replace("D", '')
                //console.log(days)
                var dateOffset = days === "max" ? 0 : Number(days);
                //console.log(Number(days), dateOffset)
                //console.log(new Date().setTime(new Date().getTime() - Number(dateOffset)))
                var filteredData = dateOffset === 0 ? [...chartData] : [...chartData].filter((item) => dayDiff(new Date(item?.[xAxisKey]), endDate_full) <= Number(dateOffset))
                //console.log(filteredData)
                //console.log(chartData)
                var chartDataFinal = JSON.stringify(filteredData?.map((i, index) => {
                    var dataFinal = filteredData
                    var changePer = index === 0 ? 0 : ((dataFinal[index]?.[key1] - dataFinal[0]?.[key1]) / dataFinal[0]?.[key1]) * 100
                    var changePer2 = index === 0 ? 0 : ((dataFinal[index]?.[key2] - dataFinal[0]?.[key2]) / dataFinal[0]?.[key2]) * 100
                    return is_live === 1 ? {
                        ...i,
                        Portfolio: i?.[key1] * Number((defAmtSelect / dataFinal?.[0]?.[key1])?.toFixed(2)),
                        // Benchmark: i?.[key2] * Number((defAmtSelect / dataFinal?.[0]?.[key2])?.toFixed(2)),
                        ret_Portfolio: changePer?.toFixed(2),
                        // ret_Benchmark: changePer2?.toFixed(2),
                    } : {
                        ...i,
                        Portfolio: i?.[key1] * Number((defAmtSelect / dataFinal?.[0]?.[key1])?.toFixed(2)),
                        Benchmark: i?.[key2] * Number((defAmtSelect / dataFinal?.[0]?.[key2])?.toFixed(2)),
                        ret_Portfolio: changePer?.toFixed(2),
                        ret_Benchmark: changePer2?.toFixed(2),
                    }
                }))
                //console.log(chartDataFinal)
                setfinalChartData({ data: chartDataFinal })
            } else {
                setfinalChartData(chartData)
            }

        }
    }, [chartType])

    var a = ["Portfolio", "Benchmark"]

    ////////console.log(a)

    if (a.length === 2) {
        fundColors = ['#007AFF', '#83BF6E', '#555770', '#FFD966', '#FF55BB', '#0062CC', '#AA55FF', '#FF9C55', '#FF6A55', '#1A1D1F', '#00FFFF', '#DBFF00']
    } else {
        fundColors = ['#007AFF', '#555770', '#83BF6E', '#FFD966', '#FF55BB', '#0062CC', '#AA55FF', '#FF9C55', '#FF6A55', '#1A1D1F', '#00FFFF', '#DBFF00']
    }

    try {
        var options = {
            chart: {
                backgroundColor: "transparent",
                marginLeft: 0,
                marginRight: 0,
            },
            title: null,
            exporting: {
                enabled: false
            },
            yAxis: {
                visible: false,
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                title: null,
                tickColor: 'transparent',
                gridLineColor: 'var(--grey1)',
            },
            xAxis: [{
                tickPixelInterval: 110,
                title: null,
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%e %b',
                    week: '%e %b',
                    month: '%b %y',
                    year: '%Y'
                },
                lineColor: 'var(--grey1)',
                tickColor: 'var(--grey1)',
            }
            ],
            legend: {
                enabled: false
            },
            plotOptions: {
                areaspline: {
                    threshold: null
                },
                series: {
                    lineWidth: 1,
                    label: {
                        connectorAllowed: false
                    },
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                shared: true,
                valueDecimals: 0,
                valuePrefix: '₹',
            },
            credits: {
                enabled: false
            },
            boost: {
                useGPUTranslations: true,
            },
            series: a.map((item, index) => {
                // ////console.log(item, a.length)


                return {
                    name: item,
                    turboThreshold: 9e9,
                    color: fundColors[index],
                    tooltip: {
                        valueSuffix: ' ({point.ret_' + item + '}%)',
                    },
                    type: a.length > 3 ? "spline" : "areaspline",
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, fundColors[index] + "77"],
                            [0.1, fundColors[index] + "11"]
                        ]
                    },
                    data: changeObjKey(finalChartData, [String(item), xAxisKey], ["y", "x"], "mfetfChart", (1))
                }
            }),
            scrollbar: {
                enabled: false
            },
        };
    } catch (error) {
        setnoChart(true)
    }

    const [popopen, setpopOpen] = useState();

    const hide = () => {
        setpopOpen(false);
    };
    const handleOpenChange = (newOpen) => {
        //////////console.log("hey")
        setpopOpen(newOpen);
    };

    const ContentBeforeChartNormal = () => {
        try {
            if (chartType) {
                var mintData = JSON.parse(finalChartData?.data)
            }
            return <div style={{ gap: '1rem', flexWrap: 'wrap', alignItems: "center" }}>

                <div style={{ flex: 1, display: "flex", flexDirection: "var(--flexDirection)", gap: '1rem' }} className='align-items-start align-items-md-center'>
                    <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                        {showLiveTickerVal && <span className='textMD w-700' style={{ flex: 1, color: 'var(--black)', display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                            <span>{fundData?.proper_name || fundData?.basic_name}
                                <img loading="lazy"  alt="" style={{ marginLeft: "0.5rem", height: "1rem" }} src={`${flaticonSvg}fi-rr-arrow-up-right-from-square.svg`} />
                            </span>
                            {type.value !== 2 ? <StockLivePrice fundData={fundData} format={5} symbol={fundData?.symbol || fundData?.nse_symbol} /> : <span className='textMD dark3'>NAV: {CurrencyFormat(fundData?.latest_nav)}</span>}
                        </span>}
                        <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem" }}>
                            {is_live !== 1 && <span>Value of <span className='textSM w-500' >{CurrencyFormat(chartAmount, 'long', 0)}</span> if invested on <span className='textSM w-500' >{formatDateMMMDDYYYY(mintData[0][xAxisKey])}</span></span>}
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: "0.5rem" }}>
                            {
                                a.map((item, index) => {
                                    // ////console.log(mintData[mintData?.length - 1]["ret_" + item])
                                    var val = Number(mintData[mintData?.length - 1][item] * (1)).toFixed(2)
                                    var valXIRR = Number(mintData[mintData?.length - 1]["ret_" + item])?.toFixed(2)
                                    return val && val !== 'NaN' ?
                                        <div key={index} style={{
                                            display: 'flex', gap: '0.375rem', flexDirection: "column",
                                        }}>
                                            {(a.length > 2 ? item === 'Benchmark' : index !== 0) && portfolioType === 0 ? <Popover
                                                arrow={false}
                                                content={
                                                    <div style={{ minHeight: "6rem", display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                                                        <span className='textMD w-500 mb-2'> Selected benchmark: <span className='primary'>{benchName}</span></span>
                                                        <span style={{ width: "15rem" }}>
                                                            <InstrumentListSearch selectedValues={benchData} placeholder={`Search and change benchmark`} type={11} onChange={(e) => {
                                                                onBenchChange(e)
                                                            }}
                                                                align='left' />
                                                        </span>
                                                    </div>
                                                }
                                                // title="Title"
                                                trigger="click"
                                                open={popopen}
                                                overlayStyle={{
                                                    position: "relative",
                                                    width: "max(30% , 20rem)",
                                                    height: "50%",
                                                    padding: 0
                                                }}
                                                style={{ position: "relative", width: "60vw" }}
                                                placement='rightTop'
                                                onOpenChange={(e) => handleOpenChange(e)}
                                            >
                                                <span>
                                                    <span className='textXS w-700' style={{ color: 'var(--black)', display: "flex", alignItems: "center", gap: "0.5rem", textDecoration: (a.length > 2 ? item === 'Benchmark' : index !== 0) ? "underline" : 'unset', textDecorationStyle: 'dotted', cursor: (a.length > 2 ? item === 'Benchmark' : index !== 0) && portfolioType === 0 ? "pointer" : 'unset' }}>
                                                        <BsFillSquareFill color={fundColors[index]} size={"0.5rem"} />
                                                        {a.length > 2 ? index === 0 ? (fundName || item) : item === "Benchmark" ? (benchName || item) : item : index === 0 ? (fundName || item) : (benchName || item)}
                                                        {(a.length > 2 ? item === 'Benchmark' : index !== 0) && <MdEdit style={{ cursor: "pointer" }} />}
                                                    </span>
                                                    <span className='textXS' style={{ flex: 1, padding: "0rem 1rem" }}>
                                                        {CurrencyFormat(val, 'long')}<span className='textXS'>{RedGreenText({ text: ` (${valXIRR}%)`, value: valXIRR })}</span>
                                                    </span>
                                                </span>
                                            </Popover> : <span>
                                                <span className='textXS w-700' style={{ color: 'var(--black)', display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                                    <BsFillSquareFill color={fundColors[index]} size={"0.5rem"} />
                                                    {a.length > 2 ? index === 0 ? (fundName || item) : item === "Benchmark" ? (benchName || item) : item : index === 0 ? (fundName || item) : (benchName || item)}
                                                </span>
                                                <span className='textXS' style={{ flex: 1, padding: "0rem 1rem" }}>
                                                    {CurrencyFormat(val, 'long')}<span className='textXS'>{RedGreenText({ text: ` (${valXIRR}%)`, value: valXIRR })}</span>
                                                </span>
                                            </span>}
                                            {/* } */}


                                        </div > : <></>
                                })
                            }
                        </div>
                    </div>
                    {!isWebSocket && <div className='w100-sm'>
                        <Divider style={{ margin: 0, marginBottom: "0.5rem" }} />
                        <CustomSegmentedTab
                            isBlock={isMobile ? true : false}
                            keepSameInMobile={true}
                            className='performanceChartYrSegmented'
                            style={{ backgroundColor: "transparent", padding: "0rem 0.5rem" }}
                            options={allowedTabs}
                            value={chartType}
                            onChange={(e) => setChartType(e)}
                        />
                        <Divider style={{ margin: 0, marginTop: "0.5rem" }} />
                    </div>}
                </div>

            </div>
        } catch (error) {
            ////console.log(error)
            return <></>
        }
    };

    const ContentBeforeChartMetric = () => {
        try {
            if (chartType) {
                var mintData = JSON.parse(finalChartData?.data)
            }
            return <div style={{ gap: '1rem', display: "flex", flexDirection: "column" }}>

                <span style={{ flex: 1, display: "flex", flexDirection: "row", marginBottom: "1rem", alignItems: "center" }}>
                    <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: "1.25rem" }}>
                        <span>Value of <span className='textSM w-500' >{CurrencyFormat(chartAmount, 'long')}</span> if invested on <span className='textSM w-500' >{formatDateMMMDDYYYY(mintData[0][xAxisKey])}</span></span>
                    </div>
                    {!isWebSocket && <span>
                        <Divider style={{ margin: 0, marginBottom: "0.5rem" }} />
                        <CustomSegmentedTab
                            isBlock={false}
                            className='performanceChartYrSegmented'
                            style={{ backgroundColor: "transparent", padding: "0rem 0.5rem" }}
                            options={allowedTabs}
                            value={chartType}
                            onChange={(e) => setChartType(e)}
                        />
                        <Divider style={{ margin: 0, marginTop: "0.5rem" }} />
                    </span>}
                </span>

                <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: "1.25rem" }}>
                    {showLiveTickerVal && <span className='textMD w-700' style={{ flex: 1, color: 'var(--black)', display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                        <span>{fundData?.proper_name || fundData?.basic_name}
                            <img loading="lazy"  alt="" style={{ marginLeft: "0.5rem", height: "1rem" }} src={`${flaticonSvg}fi-rr-arrow-up-right-from-square.svg`} />
                        </span>
                        {type.value !== 2 ? <StockLivePrice fundData={fundData} format={5} symbol={fundData?.symbol || fundData?.nse_symbol} /> : <span className='textMD dark3'>NAV: {CurrencyFormat(fundData?.latest_nav)}</span>}
                    </span>}
                    <div className='grid' style={{}}>
                        {
                            a.map((item, index) => {
                                // ////console.log(mintData[mintData?.length - 1][item],3242)
                                var val = Number(mintData[mintData?.length - 1][item] * (1)).toFixed(2)
                                var valXIRR = Number(mintData[mintData?.length - 1]["ret_" + item])?.toFixed(2)
                                return val && val !== 'NaN' ?
                                    <div className='col' key={index} style={{
                                        // display: 'flex', gap: '0.375rem', flexDirection: "column",
                                    }}>


                                        <span className='textXS w-700' style={{ color: 'var(--black)', display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                            <BsFillSquareFill color={fundColors[index]} size={"0.5rem"} />
                                            {index === 0 ? (fundName || item) : (benchName || item)}
                                        </span>
                                        <span className='textXS' style={{ flex: 1, padding: "0rem 1rem" }}>
                                            {CurrencyFormat(val, 'long')}<span className='textXS'>{RedGreenText({ text: ` (${valXIRR}%)`, value: valXIRR })}</span>
                                        </span>



                                    </div > : <></>
                            })
                        }
                    </div>
                </div>

            </div>
        } catch (error) {
            // ////////console.log(error)
            return <></>
        }
    };


    try {
        return (
            noChart ? <div className='textXS' style={{ margin: "4rem", textAlign: "center" }}>
                No chart data available!
            </div>
                : <span style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', }}>
                    {portfolioType !== 10 ? <ContentBeforeChartNormal /> : <ContentBeforeChartMetric />}
                    <HighchartsReact containerProps={{ style: { height: "20.8rem" } }} highcharts={Highcharts} options={options} />
                    {/* {
                        portfolioType === 1 || portfolioType === 2 ?
                            <>
                                <div className='textXS' style={{ paddingTop: "1rem", paddingBottom: "1rem", }}>
                                    <b>Notes and Disclaimer:</b>
                                    <ol style={{ marginBottom: 0, paddingLeft: '16px' }}>
                                        <li>Past performance is not an assurance of future performance.</li>
                                        <li>Back-tested calculations do take into account an estimate of potential transaction cost incurred. Calculations do not take into account taxation.</li>
                                        <li>Actual index data has been used to simulate back-tested performance. To that extent, return numbers are not adjusted for the cost of ETF and tracking error</li>
                                        <li>Live performance is based on actual portfolio performance since it went live.</li>
                                    </ol>
                                </div>
                            </>
                            :
                            <>
                                <div className='textXS' style={{ paddingTop: "1rem", paddingBottom: "1rem", whiteSpace: 'pre-wrap' }}>
                                    <b>Note: </b>Past performance is not an assurance of future performance. The chart has been shown based on EOD NAVs of the fund/ETF.
                                </div>
                            </>
                    } */}

                </span>
        );
    } catch (error) {
        //console.log(error)
        return <div className='textXS' style={{ margin: "4%", textAlign: "center" }}>
            No chart data available!
        </div>
    }
};
export default React.memo(ScreenSignalBacktestChart);