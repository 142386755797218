import { createSlice } from '@reduxjs/toolkit'

export const userDataSlice = createSlice({
  name: 'userData',
  initialState: {
    value: undefined,
  },
  reducers: {
    updateUserData: (state, action) => {
      state.value = action.payload
    },
  },
})

export const userAccessLevel = createSlice({
  name: "userAccessLevel",
  initialState: {
    value: {
      access: undefined,
      id: undefined,
    },
  },
  reducers: {
    updateUserAccessLevel: (state, action) => {
      state.value = action.payload
    }
  }
})

export const loginModal = createSlice({
  name: 'loginModal',
  initialState: {
    value: false,
    params: {
      isExtClickDisabled: false,
      modalType: ""
    }
  },
  reducers: {
    toggleloginModal: (state, action) => {
      state.value = action.payload
    },
    toggleLoginModalParams: (state, action) => {
      state.params = action.payload
    }
  },
})

export const userSignedIn = createSlice({
  name: "userSignedIn",
  initialState: {
    value: false
  },
  reducers: {
    toggleUserSignedIn: (state, action) => {
      state.value = action.payload
    }
  }
})

export const subscribeModal = createSlice({
  name: "subscribeModal",
  initialState: {
    open: false,
    moduleKey: "",
    params: {
      isExtClickDisabled: false,
      customAccessObj: {},
      isBeta: false,
      isLimitType: false,
      hideDescription: false,
    }
  },
  reducers: {
    toggleSubscribeModal: (state, action) => {
      state.open = action.payload
    },
    changeModuleKey: (state, action) => {
      state.moduleKey = action.payload
    },
    toggleSubModalParams: (state, action) => {
      state.params = action.payload
    }
  }
})

export const helpAndSupp = createSlice({
  name: "helpAndSupp",
  initialState: {
    value: false,
  },
  reducers: {
    toggleHelpAndSupp: (state, action) => {
      state.value = action.payload
    }
  }
})

export const pricingModal = createSlice({
  name: "pricingModal",
  initialState: {
    value: false
  },
  reducers: {
    togglePricingModal: (state, action) => {
      state.value = action.payload
    }
  }
});

export const preferenceModal = createSlice({
  name: "preferenceModal",
  initialState: {
    value: false
  },
  reducers: {
    togglePreferenceModal: (state, action) => {
      state.value = action.payload
    }
  }
});

export const userWatchlists = createSlice({
  name: "userWatchlists",
  initialState: {
    value: undefined,
  },
  reducers: {
    updateWatchlists: (state, action) => {
      state.value = action.payload
    }
  }
})

export const watchlistModal = createSlice({
  name: "watchlistModal",
  initialState: {
    open: false,
    params: {}
  },
  reducers: {
    toggleWatchlistModal: (state, action) => {
      state.open = action.payload
    },
    updateWatchlistModalParams: (state, action) => {
      state.params = action.payload
    }
  }
})

export const userPlanData = createSlice({
  name: "userPlanData",
  initialState: {
    value: undefined
  },
  reducers: {
    updateUserPlanData: (state, action) => {
      state.value = action.payload
    }
  }
})

export const userScreeners = createSlice({
  name: "userScreeners",
  initialState: {
    stocks: [],
    mfs: [],
    etfs: [],
  },
  reducers: {
    updateStockScreeners: (state, action) => {
      state.stocks = action.payload
    },
    updateMfScreeners: (state, action) => {
      state.mfs = action.payload
    },
    updateEtfScreeners: (state, action) => {
      state.etfs = action.payload
    }
  }
})

export const userBaskets = createSlice({
  name: "userBaskets",
  initialState: {
    stocks: [],
    mfs: [],
    etfs: [],
  },
  reducers: {
    updateStockBaskets: (state, action) => {
      state.stocks = action.payload
    },
    updateMfBaskets: (state, action) => {
      state.mfs = action.payload
    },
    updateEtfBaskets: (state, action) => {
      state.etfs = action.payload
    }
  }
})


export const userCustomCols = createSlice({
  name: "userCustomCols",
  initialState: {
    stocks: [],
    mfs: [],
    etfs: [],
    userCustomCols: undefined
  },
  reducers: {
    updateStockCustomCols: (state, action) => {
      state.stocks = action.payload
    },
    updateMfCustomCols: (state, action) => {
      state.mfs = action.payload
    },
    updateEtfCustomCols: (state, action) => {
      state.etfs = action.payload
    },
    updateCustomColumns: (state, action) => {
      state.userCustomCols = action.payload
    }
  }
})
// export const clientDataSlice = createSlice({
//   name: 'clientData',
//   initialState: {
//     value: undefined,
//   },
//   reducers: {
//     updateClientData: (state, action) => {
//       state.value = action.payload
//     },
//   },
// })

export const investmentModal = createSlice({
  name: "investmentModal",
  initialState: {
    invCardStateObj: {},
  },
  reducers: {
    updateInvCardStateObj: (state, action) => {
      state.invCardStateObj = action.payload
    }
    ,
  }
})

export const paySharpelyFeeModal = createSlice({
  name: "paySharpelyFeeModal",
  initialState: {
    open: false,
    params: {}
  },
  reducers: {
    togglePayFeeModal: (state, action) => {
      state.open = action.payload
    },
    updatePayFeeParams: (state, action) => {
      state.params = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { updateUserData } = userDataSlice.actions
export const { updateUserAccessLevel } = userAccessLevel.actions
export const { toggleUserSignedIn } = userSignedIn.actions
export const { updateUserPlanData } = userPlanData.actions
// export const { updateClientData } = clientDataSlice.actions
export const { toggleloginModal, toggleLoginModalParams } = loginModal.actions
export const { toggleSubscribeModal, changeModuleKey, toggleSubModalParams } = subscribeModal.actions;
export const { updateInvCardStateObj } = investmentModal.actions;
export const { togglePayFeeModal, updatePayFeeParams } = paySharpelyFeeModal.actions;
export const { toggleHelpAndSupp } = helpAndSupp.actions;
export const { togglePricingModal } = pricingModal.actions;
export const { togglePreferenceModal } = preferenceModal.actions;
export const { updateWatchlists } = userWatchlists.actions;
export const { toggleWatchlistModal, updateWatchlistModalParams } = watchlistModal.actions;
export const { updateStockCustomCols, updateMfCustomCols, updateEtfCustomCols, updateCustomColumns } = userCustomCols.actions;