import AccessComponent from "Components/AccessComponent";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import CustomSegmentedTab from "Components/CustomSegmentedTab";
import Disclaimer from "Components/Disclaimer";
import NoBackTestData from "Components/NoBackTestData";
import PerfBenchChart from "Components/PerfBenchChart";
import { SubscribeBtnSmall, SubscribeIcon } from "Components/SubscribeModal";
import { CardHeader } from "Components/atoms/SmallComponents";
import { isChildVisible } from "Libs/scroll";
import { sortDrop } from "Libs/search";
import { generateUniqueKey, makeid, stock_universe } from 'Libs/utils';
import AdminServices from "Services/api/AdminServices";
import apiHelperPY, { decodeData } from "Services/api/ApiHelperPY";
import { Card, Drawer, Input, Select, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { BiEdit, BiInfoCircle, BiSolidPencil } from "react-icons/bi";
import { IoIosArrowDown, IoIosArrowForward, IoIosClose, IoIosCloseCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { CustomComparatorEditor } from "./CustomComparatorEditor";
import { FunctionsEditor } from "./FunctionsEditor";
import { MetricsEditor } from "./MetricsEditor";
import { QueryOptionsDropdown } from "./QueryBuilderDropdown";
import { QueryOptionsDropdownV2 } from "./QueryOptionsDropdown";
import { basic_comparators, conditionOperators, customComparators, directionTypes, groupColumnNames, maTypes, mathOperators, multipleValueComp, patternOutputs, stringParser, withinQuotesFields } from "./ScreenerUtils";

export const QueryViewerBasic = React.memo(({ fromAlert = false, isVisible = true, type = {}, allFuncAndMetricForDrawer = {}, functions = [], variables = {}, timeframe = "1d", screenerMetrics = [], indices = [], onQueriesUpdate = () => { }, isCondition = false,
    initialQueries, isFocused = null, funcToCallOnWrapClick = () => { }, onCreateVariable = () => { }, isAdvance = false, openBasicDrawer = false, toggleBasicDrawer = () => { }, allFundsData = [] }) => {
    const sample_placeholder = "Create rule ..."
    const [queries, setQueries] = React.useState(JSON.parse(JSON.stringify(initialQueries)));
    const [query, setQuery] = React.useState('');
    const [showDropDown, setShowDropDown] = React.useState(false);
    const queryBuilderRef = React.useRef();
    const [selectedFunc, setSelectedFunc] = React.useState({});
    const [funcModal, setFuncModal] = React.useState();
    const [selectedQuery, setSelectedQuery] = React.useState({});
    const [metricModal, setMetricModal] = React.useState(false);
    const [selectedOptionInBasicDrawer, setselectedOptionInBasicDrawer] = React.useState("Metrics")
    const [toggleDesc, settoggleDesc] = useState(false)
    const queryDDItemRef = React.useRef();
    const multipleTypeMetrics = ["sector_code", "industry_code", "nse_basic_ind_code", "style_box_code"];
    const [allMetricDrawer, setAllMetricDrawer] = useState(openBasicDrawer)

    const [selectedLevel1, setSelectedLevel1] = React.useState('')
    const [newFiltersList, setnewFiltersList] = React.useState([])
    const [checkFiltersList, setcheckFiltersList] = React.useState([])

    const [selectedMetric, setSelectedMetric] = React.useState({});
    const [selectedm, setselectedm] = React.useState({});
    const [customCompModal, setCustomCompModal] = React.useState(false);
    const [selectedCustomComp, setSelectedCustomComp] = React.useState({});

    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    const isMobile = window.innerWidth < 760
    const [mobileLevel, setMobileLevel] = React.useState(1);

    // console.log(logg, allFuncAndMetricForDrawer)

    const getFunctionDoc = async () => {
        if (typeof (selectedm) === 'object' && selectedm?.hasOwnProperty('sh_name') && !(selectedm?.metric_desc)) {
            let doc = '';
            try {
                let t = await AdminServices.readAWSFile('functions-docs', selectedm?.sh_name);
                if (t.type) {
                    doc = t.data
                } else {
                    doc = ''
                }
            } catch (error) {
                console.log(error);
                doc = ''
            }
            if (doc) {
                setselectedm({
                    ...selectedm,
                    metric_name: selectedm?.display_name,
                    doc: doc,
                    metric_desc: <span id="metric_desc" className='ql-editor textSM' style={{ lineHeight: "1.125rem !important" }} dangerouslySetInnerHTML={{ __html: String(doc) }}></span>
                })
            }
        }
    }

    React.useEffect(() => {
        getFunctionDoc();
    }, [selectedm]);

    React.useEffect(() => {
        setAllMetricDrawer(openBasicDrawer)
        // if (allMetricDrawer !== openBasicDrawer)
        //     toggleBasicDrawer()
    }, [openBasicDrawer])

    var query_cond = Object.entries(queries || {})?.filter(([metric, metricObj]) => !(String(metricObj?.expression)?.toLowerCase() === "and" || String(metricObj?.expression)?.toLowerCase() === "or"))
    var query_oper = Object.entries(queries || {})?.filter(([metric, metricObj]) => (String(metricObj?.expression)?.toLowerCase() === "and" || String(metricObj?.expression)?.toLowerCase() === "or"))

    //////console.log(logg, query_cond, query_oper)

    var newBlock = {}
    var mainBlock = {}
    var final_cond = Object.entries(queries || {})?.map(([metric, metricObj]) => {
        if ((String(metricObj?.expression)?.toLowerCase() === "and" || String(metricObj?.expression)?.toLowerCase() === "or")) {
            mainBlock = {
                ...mainBlock,
                [Object.keys(mainBlock)?.length + "_cond"]: newBlock,
                [metric]: metricObj,
            }
            newBlock = {}
        } else {
            newBlock = {
                ...newBlock,
                [metric]: metricObj
            }
        }
    })

    if (Object?.keys(newBlock)?.length > 0) {
        mainBlock = {
            ...mainBlock,
            [Object.keys(mainBlock)?.length + "_cond"]: newBlock,
        }
        newBlock = {}
    }

    //////console.log(logg, mainBlock)

    const getInitialItemsToShow = React.useCallback(() => {
        let queryKeys = Object.keys(queries || {});
        queryKeys = queryKeys.reverse()?.map((el) => el?.split('_')?.[1]);
        let keys = ['compOperator', 'conditionOperator',]
        let key1 = queryKeys?.[0]
        let key2 = queryKeys?.[1]
        let result = [];
        let isLastCustomComp = Boolean(Object.values(queries || {})?.reverse()?.[0]?.isCustomComparator)
        if (selectedQuery?.isAdd === true) {
            if (selectedQuery?.key?.includes('_compOperator')) {
                result = [...basic_comparators];
            } else if (selectedQuery?.key?.includes('_conditionOperator')) {
                result = [...conditionOperators];
            } else {
                let its = [...functions, ...screenerMetrics, ...Object.values(variables)?.map((i) => {
                    return {
                        // ...i,
                        type: "Variable",
                        label: i?.name,
                        value: i?.name,
                    }
                })];
                if (!isCondition) {
                    its = its?.filter((el) => !multipleTypeMetrics?.includes(el?.metric_col_code));
                }
                result = !isNaN(Number(query?.trim())) && query?.length > 0 ? [] : its;
            }
        } else {
            if (selectedQuery?.key?.includes('_compOperator') || selectedQuery?.key?.includes('_conditionOperator')) {
                result = [...basic_comparators, ...conditionOperators];
            }
            else if (selectedQuery?.value?.type === 'metric') {
                result = screenerMetrics?.filter((el) => !multipleTypeMetrics?.includes(el?.metric_col_code))
            }
            else if (isLastCustomComp) {
                result = [...basic_comparators, ...conditionOperators];
            }
            else if (queryKeys?.length === 0 || keys.includes(key1) || selectedQuery?.key?.includes('_number') || selectedQuery?.value?.type === 'variable') {
                let its = [...functions, ...screenerMetrics, ...Object.values(variables)?.map((i) => {
                    return {
                        // ...i,
                        type: "Variable",
                        label: i?.name,
                        value: i?.name,
                    }
                })];
                if (!isCondition) {
                    its = its?.filter((el) => !multipleTypeMetrics?.includes(el?.metric_col_code));
                }
                result = !isNaN(Number(query?.trim())) && query?.length > 0 ? [] : its;
            }
            else if (!keys.includes(key1)) {
                result = [...basic_comparators, ...conditionOperators];
            }
        }
        return result;
    }, [queries, selectedQuery]);

    const initialOptions = getInitialItemsToShow();

    const getOptions = React.useCallback(() => {
        let queryKeys = Object.keys(queries || {});
        queryKeys = queryKeys.reverse()?.map((el) => el?.split('_')?.[1]);
        let keys = ['compOperator', 'conditionOperator',]
        let key1 = queryKeys?.[0]
        let key2 = queryKeys?.[1]
        let result = [];
        let isLastCustomComp = Boolean(Object.values(queries || {})?.reverse()?.[0]?.isCustomComparator)

        let its = [...functions?.filter((i) => i?.type !== "Patterns"), ...screenerMetrics, ...Object.values(variables)?.map((i) => {
            return {
                // ...i,
                type: "Variable",
                label: i?.name,
                value: i?.name,
            }
        })];
        // if (isCondition) {
        its = its?.filter((el) => !multipleTypeMetrics?.includes(el?.metric_col_code));
        // }
        let temp = !isNaN(Number(query?.trim())) && query?.length > 0 ? [] : sortDrop(query, its, ['label', 'value'])
        result = temp;

        return result;
        // return !isNaN(Number(query?.trim())) && query?.length > 0 ? [] : sortDrop(query, initialOptions, ['label', 'value'])
    }, [query, initialOptions])

    const getOptionsTabsTypeAndShow = React.useCallback(() => {
        let queryKeys = Object.keys(queries || {});
        queryKeys = queryKeys.reverse()?.map((el) => el?.split('_')?.[1]);
        let keys = ['compOperator', 'conditionOperator',]
        let key1 = queryKeys?.[0]
        let key2 = queryKeys?.[1]
        let result = { type: "", show: false }
        let isLastCustomComp = Boolean(Object.values(queries || {})?.reverse()?.[0]?.isCustomComparator)

        result = { type: "metrics", show: true, showVarTab: true, showCreateVarOpt: isCondition, typesToHide: ["Patterns"] }
        return result;
    }, [queries, selectedQuery]);

    const options = getOptions();

    const optionsTabTypeAndShow = getOptionsTabsTypeAndShow();

    const getDropdownPosition = React.useCallback(() => {
        if (showDropDown) {
            let dropdown = document.querySelector('.queryDropdownCont');
            // if (dropdown) {
            //     const {top,bottom,left,right,height,width} = dropdown.getBoundingClientRect();
            //     const viewportHeight = window.innerHeight;
            //     const viewportWidth = window.innerWidth;
            //     let result = {};
            //     if(bottom>viewportHeight){
            //         result.bottom = bottom-viewportHeight-height;
            //         if(left>0&&right)
            //     }
            //     if(top<0){
            //         result.top = '100%'
            //     }
            //     if(left<0){
            //         result.left = '100%'
            //     }
            //     if(right>viewportWidth){
            //         result.right = right - viewportWidth - right;
            //     }
            //     ////////console.log(top,bottom,left,right,height,width, viewportHeight, viewportWidth)
            //     return result;
            // }
        }
        return { top: "100%", left: "100%" }
    }, [query, options, showDropDown]);

    const dropdownPositions = getDropdownPosition();

    const expUIParser = (exp, queryData, metric, useColorCode = true) => {
        //////console.log("DSASDASD", exp, queryData)
        if (exp === "Add") {
            let colorCode = "var(--grey2)"
            return <span className="textESM w-500 textLight" style={{ lineHeight: undefined, padding: "0.4rem 0.4rem", minWidth: "15rem", border: `1px solid ${colorCode}`, borderRadius: 5, backgroundColor: "var(--grey4)" }}>
                {queryData?.expression_arr}
            </span>
        }
        const isMetric = (str) => {
            let ser = screenerMetrics?.filter((i) => i?.value === str)?.[0]
            if (ser) {
                return ser?.label
            }
            return str;
        }
        let item = queryData?.expression_details
        let expN = String(exp)?.match(/.*?\(/)?.[0]
        let colorCode = useColorCode ? metric?.includes("_number") ? "var(--green)" : metric?.includes("_compOperator") ? "var(--orange)" : "var(--dark4)" : "var(--dark4)"

        // //////console.log("hello",exp, queryData, metric)

        if (String(exp)?.match(/by|matype|direction\s{0,3}=\s{0,2}/)) {
            if (String(exp)?.match(/by\s{0,3}=\s{0,2}/)) {
                let showPeriod = queryData?.expression_arr?.map(element => {
                    return [`"MKT"`, `"SEC"`, `"INDGRP"`, `"IND"`].concat(indices?.map((item) => `"${item?.IndexNameCaps}"`)).filter(str => element.includes(str))?.length > 0
                });
                showPeriod = showPeriod?.filter((i) => i === false)?.length === queryData?.expression_arr?.length
                queryData = {
                    ...queryData,
                    expression_arr: !showPeriod ? queryData?.expression_arr?.filter(element => {
                        return !element.match(/period\s{0,3}=/)
                    }) : queryData?.expression_arr
                }
            }
            let newArr = queryData?.expression_arr?.map((str) => {
                // alert(str)
                str = isMetric(str)
                if (String(str)?.match(/by|matype|direction\s{0,3}=\s{0,2}/)) {
                    if (String(str)?.match(/\s{0,3}=\s{0,3}/g)) {
                        var arr = String(str)?.split(/\s{0,3}=\s{0,3}/g)
                        var keyName = arr?.[0]
                        var keyValue = item?.type === "<class 'int'>" ? parseInt(Number(arr?.[1])) : item?.type === "<class 'float'>" ? parseFloat(Number(arr?.[1])) : arr?.[1];

                        if (String(str)?.match(/by\s{0,3}=\s{0,2}/)) {
                            ////console.log("lala", str, keyValue)
                            var keyValue2 = groupColumnNames?.concat(indices?.map((item, i) => {
                                return { key: item?.IndexName, label: item?.IndexName, value: `"${item?.IndexNameCaps}"`, tabType: "Indices" }
                            }))?.filter((i) => {
                                return i?.value === keyValue
                            })?.[0]?.label

                            if (keyValue2) {
                                return keyName + "=" + keyValue2
                            } else {
                                return keyName + "=" + ((keyValue?.endsWith("F\"") || keyValue?.endsWith("Y\"")) ? "Years" : keyValue?.endsWith("Q\"") ? "Quarters" : (keyValue?.endsWith("M\"") ? "Months" : "Days"))
                            }


                        } else if (String(str)?.match(/matype\s{0,3}=\s{0,2}/)) {
                            var keyValue2 = maTypes?.filter((i) => {
                                return i?.value === Number(keyValue)
                            })?.[0]?.label
                            return keyName + "=" + keyValue2
                        } else if (String(str)?.match(/direction\s{0,3}=\s{0,2}/)) {
                            var keyValue2 = directionTypes?.filter((i) => {
                                return i?.value === Number(keyValue)
                            })?.[0]?.label
                            return keyName + "=" + keyValue2
                        }
                    } else {
                        return str
                    }
                } else {
                    return String(str)
                }
            })
            //////console.log("hello", newArr)
            return <span className="w-bold" style={{ color: colorCode }}>
                {String(queryData?.expression_details?.key)?.toUpperCase()}
                <span style={{ color: "var(--dark4)" }} className="w-400">{" ("}{newArr?.join(", ")}{")"}</span>
            </span>
        }
        if (String(exp)?.toLowerCase() === "and" || String(exp)?.toLowerCase() === "or")
            return <span className="w-bold" style={{ color: colorCode }}>{String(exp)?.toUpperCase()}</span>
        if (queryData?.type !== "metric" && expN) {
            //////console.log("hello", expN)
            let patternOutput = patternOutputs?.find((el) => el?.value === queryData?.patternOutput)?.label;

            return <span className="w-bold" style={{ color: colorCode }}>
                {String(expN)?.toUpperCase()?.replace("(", "")}
                <span className="w-400" style={{ color: "var(--dark4)" }}>
                    {" ("}{exp?.replace(expN, "")}</span>
                {patternOutput ? <span className="w-bold" style={{ color: useColorCode ? "var(--orange)" : "var(--black)" }}> is&nbsp;
                    <span className="w-bold" style={{ color: useColorCode ? "var(--green)" : "var(--black)" }}>{patternOutput}</span>
                </span> : ""}
            </span>
        }
        if (queryData?.type === 'metric' && queryData?.isMultiple === true && multipleTypeMetrics?.includes(item?.metric_col_code)) {
            let itemStr = queryData?.expression_arr?.map((el) => item?.dataToRenderFrom?.find((ele) => ele?.value === el)?.label)?.join(', ')
            return (
                <>
                    <span className="w-bold" style={{ color: colorCode, display: "inline", margin: "0 0.5rem 0.5rem 0", }}>{String(exp)}</span>
                    <span className="w-bold" style={{ color: useColorCode ? 'var(--orange)' : "var(--black)", margin: "0 0.5rem 0.5rem 0", display: "inline" }}>
                        {String(multipleValueComp?.find((el) => el?.value === queryData?.comparator)?.label)}
                    </span>
                    <span className="w-bold" style={{ color: "var(--black)" }}>[ <span className="w-normal" style={{ color: useColorCode ? 'var(--green)' : "var(--black)", display: "inline" }}>{itemStr}</span> ]</span>
                </>
            )
        }
        if (queryData?.isCustomComparator === true) {
            let itemStr = queryData?.expression_arr?.map((el) => isMetric(el))?.join(', ')
            return (
                <>
                    <span className="w-bold" style={{ color: colorCode, display: "inline", margin: "0 0.5rem 0.5rem 0", }}>{String(exp)}</span>
                    <span className="w-bold" style={{ color: "var(--black)" }}>[ <span className="w-normal" style={{ color: useColorCode ? 'var(--green)' : "var(--black)", display: "inline" }}>{itemStr}</span> ]</span>
                </>
            )
        }
        return <span className="w-bold" style={{ color: colorCode }}>{String(exp)}</span>

    }

    const handleKeyDown = (event) => {
        let includedKeys = ['Backspace', 'Delete', 'Enter', 'Space', 'ArrowUp', 'ArrowDown']
        if (!includedKeys.includes(event.key) && !includedKeys.includes(event.code)) {
            return event;
        }
        let menuItems = Array.from(document.querySelectorAll('.queriesDropdown .queryDropdownItem'));
        const parent = document.querySelector('.queriesDropdown')
        if ((event.key == 'Backspace' || event.key == 'Delete') && event?.target?.value?.trim()?.length <= 0 && !selectedQuery?.key) {
            let q = { ...queries }
            let keys = Object.keys(queries)
            if (keys?.length > 0) {
                delete q[keys[keys.length - 1]]
                setQueries(q)
            }
        }
        else if ((event.key === 'Enter' || event.code === 'Space') && query?.trim() !== '' && (!isNaN(Number(query?.trim())) || [...basic_comparators, ...mathOperators]?.map((i) => i?.value)?.includes(query?.trim()))) {
            let isNAN = isNaN(Number(query?.trim()))
            let val = !isNAN ? Number(query?.trim()) : query?.trim();
            // alert(selectedQuery?.key)
            if (selectedQuery?.key === "1_new_add_metric") {
                var f = { ...queries }
                delete f?.["1_new_add_metric"]
                f = {
                    ...f,
                    [(!isNAN ? Object.keys(f)?.length + "_number" : Object.keys(f)?.length + "_compOperator")]: {
                        expression: val,
                        expression_arr: val
                    }
                }

                setQueries(f)
            } else {
                setQueries({
                    ...queries,
                    [selectedQuery?.key || (!isNAN ? Object.keys(queries)?.length + "_number" : Object.keys(queries)?.length + "_compOperator")]: {
                        expression: val,
                        expression_arr: val
                    }
                })
            }

            setQuery("")
            if (selectedQuery?.key) {
                setSelectedQuery({});
                setTimeout(() => {
                    if (queryBuilderRef.current) {
                        queryBuilderRef.current.focus()
                    }
                }, 100)
            }
        }
        if (menuItems?.length <= 0) {
            return event
        }
        let maxIndex = menuItems.length - 1;
        let menuIndex = menuItems?.findIndex((ele) => ele.getAttribute('data-focused') !== null);
        if (!menuIndex && menuIndex !== 0) {
            menuItems[0].setAttribute('data-focused', 'active');
        }
        else if (event.key === 'ArrowUp' && menuIndex > 0) {
            event.preventDefault();
            menuItems[menuIndex]?.removeAttribute('data-focused');
            menuItems[menuIndex - 1].setAttribute('data-focused', 'active');
            if (!isChildVisible(parent, menuItems[menuIndex - 1])) {
                parent.scrollTo({ top: menuItems[menuIndex - 1].offsetTop - parent.offsetTop - 110, behavior: "smooth" });
            }
        }
        else if (event.key === 'ArrowDown' && menuIndex < maxIndex) {
            event.preventDefault();
            menuItems[menuIndex]?.removeAttribute('data-focused');
            menuItems[menuIndex + 1].setAttribute('data-focused', 'active');
            if (!isChildVisible(parent, menuItems[menuIndex + 1])) {
                parent.scrollTo({ top: menuItems[menuIndex + 1].offsetTop - parent.offsetTop - 110, behavior: "smooth" });
            }
        }
        else if ((event.key === 'Enter') && menuItems.length > 0 && menuIndex >= 0 && menuIndex <= maxIndex) {
            menuItems[menuIndex]?.click()
        }
    }

    const handleDocumentClick = (e) => {

        //////console.log(e.target)

        let target = document.querySelector('.queryDropdownCont');
        let currTarget = e.target
        let classNames = ['queriesDropdown', 'queryDropdownItem', 'queryDropdownTabCont', 'queryDropdownTabItem', 'queryDropdownInput', 'textSM w-500 dark4 queryDropdownInput', 'metricNameClass']

        //////console.log(currTarget.className)
        do {
            if (target === currTarget || classNames.some(r => String(currTarget.className)?.split(" ")?.includes(r))) {
                return;
            }
            else if (currTarget === target?.querySelector('.queryDropdownItem') || currTarget === target?.querySelector('.queryDropdownTabItem')) {
                setShowDropDown(true);
                return;
            }
            currTarget = currTarget.parentNode
        }
        while (currTarget) {
            //////console.log("selectedQuery, showDropDown")
            setShowDropDown(false);
            if (selectedQuery?.key) {
                setSelectedQuery({});
                setQuery('');
            }
        }
    }

    const handleQueryInput = (e, query_Key) => {
        let value = e.target.value;
        const enteredText = e.target.value?.trim();
        // Regular expressions to match mathematical and comparison operators
        const splitters = [...basic_comparators?.filter((i) => i?.value?.match(/<>/))?.reverse(), ...mathOperators]?.map((i) => i?.value)
        const escapedOperators = splitters.map(operator => operator.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
        // const regexPattern = new RegExp(`\\s{1,3}\(${escapedOperators.join('|')}\)\\s{1,3}`, 'g');
        const regexPattern = new RegExp(`${escapedOperators.join('|')}`, 'g');
        // Regular expressions to match 'AND' or 'OR'
        const keywordRegex = /\b(AND|OR)\b/i;
        if (regexPattern.test(enteredText) && optionsTabTypeAndShow?.type === "operator" && !selectedQuery?.key) {
            setQueries({
                ...queries,
                [query_Key || Object.keys(queries)?.length + "_compOperator"]: {
                    expression: enteredText,
                    expression_arr: enteredText
                }
            })
            setQuery("")
        } else if (keywordRegex.test(enteredText) && optionsTabTypeAndShow?.type === "operator" && !selectedQuery?.key) {
            setQueries({
                ...queries,
                [query_Key || Object.keys(queries)?.length + "_conditionOperator"]: {
                    expression: enteredText,
                    expression_arr: enteredText
                }
            })
            setQuery("")
        } else {
            setQuery(value);
            if (!showDropDown) {
                setShowDropDown(true);
            }
        }
    }

    const onQueryOptionSelect = ({ item = {}, query_key = undefined, queryObj = undefined, isFresh = false }) => {
        // alert(query_key)
        var currentQueryVals = isFresh ? {} : queries

        var extra = (isFresh && !multipleTypeMetrics?.includes(item?.metric_col_code) && item?.type !== "Patterns") ? {
            "0_compOperator": {
                expression: basic_comparators?.[1]?.label,
                expression_arr: basic_comparators?.[1]?.value
            },
            "1_new_add_metric": {
                prevdata: {},
                expression: "Add",
                expression_arr: "Compare with ..."
            }
        } : {}





        let selectedOption = item;

        if (item?.type === 'Variable') {

            //console.log("DSASDASD", item, query_key)
            let lastKey = Object.keys(currentQueryVals || {})?.reverse()?.[0]
            if (Object.keys(currentQueryVals)?.length > 2 && lastKey !== "1_new_add_metric") {
                delete currentQueryVals?.[lastKey]
            }

            setQueries({
                ...currentQueryVals,
                [query_key || Object.keys(currentQueryVals)?.length + "_variable"]: {
                    expression: item?.label,
                    expression_arr: item?.label,
                    type: "variable",
                    // expression_details: item,
                    // isMetric: true,
                },
                ...extra
            })

        } else if (item?.type === 'Metric F' || item?.type === 'Metric T') {
            if (multipleTypeMetrics?.includes(item?.metric_col_code)) {
                setSelectedMetric({
                    queryKey: query_key || Object.keys(currentQueryVals)?.length + "_" + selectedOption?.key,
                    metric: selectedOption,
                    comparator: "is_in",
                    selectedItems: [selectedOption?.dataToRenderFrom?.[0]?.value]
                })
                setMetricModal(true);
            }
            else {

                //console.log("DSASDASD", item, query_key)
                let lastKey = Object.keys(currentQueryVals || {})?.reverse()?.[0]
                if (Object.keys(currentQueryVals)?.length > 2 && lastKey !== "1_new_add_metric") {
                    delete currentQueryVals?.[lastKey]
                }
                setQueries({
                    ...currentQueryVals,
                    [query_key || Object.keys(currentQueryVals)?.length + "_" + selectedOption?.key]: {
                        expression: item?.label,
                        expression_arr: item?.key,
                        type: "metric",
                        expression_details: item,
                        isMetric: true,
                    },
                    ...extra
                })
                setQuery("")
            }
        }
        else if ((['mathOperator', 'comparator', 'conditionOperator'])?.includes(item?.type)) {
            //////console.log('retdgdt')
            if (customComparators?.map((i) => i?.value).includes(item?.value)) {
                setSelectedCustomComp({
                    queryKey: query_key || Object.keys(currentQueryVals)?.length + "_compOperator",
                    comparator: item,
                    selectedItems: []
                });
                setCustomCompModal(true);
            }
            else {

                //console.log("DSASDASD", item, query_key)
                let lastKey = Object.keys(currentQueryVals || {})?.reverse()?.[0]
                if (Object.keys(currentQueryVals)?.length > 2 && lastKey !== "1_new_add_metric") {
                    delete currentQueryVals?.[lastKey]
                }
                let key = item?.type === 'conditionOperator' ? 'conditionOperator' : 'compOperator'
                setQueries({
                    ...currentQueryVals,
                    [query_key || Object.keys(currentQueryVals)?.length + "_" + key]: {
                        expression: item?.label,
                        expression_arr: item?.value,
                        // basic_exp: item?.label,
                    },
                    ...extra
                })
                setQuery("")
            }
        }
        else {
            let exp = `${selectedOption?.sh_name}(`;
            var selectedOptionReqInputs = stringParser(selectedOption?.sh_req_inputs || "[]")
            let arr = [...selectedOptionReqInputs];
            let exp_arr = []
            stringParser(selectedOption?.sh_opt_inputs || "[]")?.map((item) => {
                let v = currentQueryVals?.[item?.name] ? currentQueryVals?.[item?.name] : item?.default
                v = item?.name + "=" + (withinQuotesFields.includes(item?.name) ? `"${v}"` : v)
                arr.push(v);
            })
            for (let i = 0; i < arr?.length; i++) {
                exp += arr[i] + (i === arr?.length - 1 ? "" : ", ")
                exp_arr?.push(arr[i])
            }
            exp += ')'


            let exp2 = `${selectedOption?.label}(`;
            // var selectedOptionReqInputs = stringParser(selectedOption?.sh_req_inputs || "[]")
            let arr2 = [];
            let exp_arr2 = []
            stringParser(selectedOption?.sh_opt_inputs || "[]")?.map((item) => {
                let v = currentQueryVals?.[item?.name] ? currentQueryVals?.[item?.name] : item?.default
                v = item?.name + "=" + (withinQuotesFields.includes(item?.name) ? `"${v}"` : v)
                arr2.push(v);
            })
            for (let i = 0; i < arr2?.length; i++) {
                exp2 += arr2[i] + (i === arr2?.length - 1 ? "" : ", ")
                exp_arr2?.push(arr[i])
            }
            exp2 += ')'


            // if (exp_arr?.includes('col_name')) {
            // alert(query_key)
            setSelectedFunc({
                metric: Object.keys(currentQueryVals)?.length + "_" + selectedOption?.key,
                query_key: query_key,
                extra: extra,
                query: {
                    expression: exp,
                    // basic_exp: exp?.replace(selectedOption?.sh_name, selectedOption?.label || selectedOption?.sh_name),
                    expression_arr: exp_arr,
                    expression_details: selectedOption,
                    type: "func"
                },
            })
            setFuncModal(true);
            // }
            // else {
            //     setQueries({
            //         ...currentQueryVals,
            //         [query_key || Object.keys(currentQueryVals)?.length + "_" + selectedOption?.key]: {
            //             expression: exp,
            //             basic_exp: exp?.replace(selectedOption?.sh_name, selectedOption?.label || selectedOption?.sh_name),
            //             expression_arr: exp_arr,
            //             expression_details: selectedOption,
            //             type: "func",
            //         },
            //         ...extra
            //     })
            //     setQuery("")
            // }
        }




        // if (isFresh) {
        //     setQuery("");
        //     setSelectedQuery({
        //         key: "1_new_add_metric",
        //         isAdd: true,
        //         value: extra?.["1_new_add_metric"]?.prevdata,
        //     })
        //     setTimeout(() => {
        //         if (queryBuilderRef.current) {
        //             queryBuilderRef.current.focus()
        //         }
        //     }, 1000)
        //     // e.stopPropagation()
        // } else {
        setSelectedQuery({});
        setTimeout(() => {
            if (queryBuilderRef.current) {
                queryBuilderRef.current.focus()
            }
        }, 100)
        // }


    }

    React.useEffect(() => {
        //////console.log("selectedQuery, showDropDown", selectedQuery, showDropDown)
        if (showDropDown) {
            document.addEventListener('click', handleDocumentClick);
        }
        else {
            document.removeEventListener('click', handleDocumentClick);
        }
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        }
    }, [selectedQuery, showDropDown])

    React.useEffect(() => {
        if ((JSON.stringify(queries || {}) !== JSON.stringify(initialQueries || {}))) {
            //////console.log(3);
            setShowDropDown(false);

        }
    }, [queries]);

    React.useEffect(() => {
        if (isFocused) {
            if (queryBuilderRef?.current) {
                queryBuilderRef.current.focus();
            }
        }
    }, [isFocused, queries])

    // basic items



    // ////console.log(queries)

    const onCheckBoxClicked = () => {
        // here either create condition or pop condition
        // setConditions({
        //     ...conditions,
        //     [`${Object.keys(conditions)?.length}_condition`]: {
        //         queries: {}
        //     }
        // })
        // setConditionsOper([
        //     ...conditions_oper,
        //     "and"
        // ])
    }

    const [selectedStockUniverseMetricBack, setselectedStockUniverseMetricBack] = useState([stock_universe[1].value])
    const [metricbackTestRes, setMetricbackTestRes] = useState({});
    const [metricbackTestLoading, setMetricBackTestLoading] = useState(false);
    const [metricbackTestResName, setMetricbackTestResName] = useState('')
    const [toggleMetricBackTest, settoggleMetricBackTest] = useState(false)
    const metricPerfOptions = [
        {
            value: 'performance',
            queryStr: 'performance',
            label: 'Performance',
            children: <div>
                <div className='' style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                    <span className='textSM w-500' >Stock universe</span>
                    <Select
                        className='textSM black '
                        style={{
                            width: "11rem"
                        }}
                        suffixIcon={<IoIosArrowDown size={15} />}
                        defaultValue={selectedStockUniverseMetricBack}
                        placeholder="Stock universe"
                        onChange={(value) => {
                            setselectedStockUniverseMetricBack([value])
                        }}
                        options={stock_universe.filter((i) => i.value !== 'all')}
                    />
                </div>

                {metricbackTestLoading === true ? <React.Fragment>
                    <div className='mainContainer' style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                        <ActivityLoaderMintbox message='Hang on….heavy lifting in progress! We simulate 5 quintile portfolios over more than 10
years of historical data to get you the back tested performance.' />
                    </div>
                </React.Fragment> : Object.keys(metricbackTestRes || {}).length !== 0 && selectedm?.metric_col_code === metricbackTestResName ?
                    <PerfBenchChart portfolioType={10} defYrSelect="1095D" xAxisKey={metricbackTestResName === "style_box_code" ? "Date" : "date"} chartData={metricbackTestRes} defAmtSelect={100000} />
                    : <NoBackTestData val={6} />}

            </div>
        }, {
            label: `Disclaimer`,
            key: "id4",
            value: "id4",
            children: (
                <div>
                    <Disclaimer val={3} />
                </div>
            ),
        }
    ]
    const [metricPerfCur, setmetricPerfCur] = useState(metricPerfOptions[0].value)

    const getScreenerMetricBackTest = async (metric_col_code) => {
        if (metric_col_code === "style_box_code") {
            let backTestRes = await apiHelperPY({ apiName: 'getSharpelyStyleBoxBacktest' })
            backTestRes = decodeData(backTestRes || "");
            backTestRes = await JSON.parse(backTestRes?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))
            setMetricbackTestRes(backTestRes?.prices)
            ////console.log(backTestRes)
            setMetricbackTestResName(metric_col_code)
        } else {
            if (metric_col_code !== undefined) {
                var backTestRes = await apiHelperPY({ apiName: 'getScreenerMetricBacktestData', data: { metric_code: metric_col_code, universe_code: selectedStockUniverseMetricBack[0] } })
                backTestRes = decodeData(backTestRes || "");
                backTestRes = await JSON.parse(backTestRes?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))
                setMetricbackTestRes(backTestRes?.prices)
                ////console.log(backTestRes)
                setMetricbackTestResName(metric_col_code)
            }
        }
        setMetricBackTestLoading(false);
    }

    const DrawerMetricDescComp = () => {
        // React.useEffect(() => {
        //     console.log("SDFDF", selectedm?.doc)
        //     document.getElementById('metric_desc').innerHTML = selectedm?.doc;
        // }, [selectedm])
        return <div style={{ padding: "1rem", borderTop: "1px solid var(--grey3)", paddingBottom: 0, display: "flex", flexDirection: "column", gap: "0.5rem", backgroundColor: "var(--white)", flex: 1, maxHeight: "50%" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span className='textMD black w-700'>{selectedm?.metric_name}</span>
                    <span className='textSM black w-700 mt-1'>{(selectedm?.type === 'Metric F' || selectedm?.type === 'Metric T') && (selectedm?.metric_unit === 'rupee_cr' ||
                        selectedm?.metric_unit === 'percentage' || selectedm?.metric_data_type === 'string' || selectedm?.metric_data_type === 'float') &&
                        <Tag color="var(--dark3)" className="textXS" style={{ borderRadius: 8, fontSize: "10px" }}>
                            Unit: {selectedm?.metric_unit === 'rupee_cr' ? "₹ Cr" : selectedm?.metric_unit === 'percentage' ? "%" :
                                selectedm?.metric_data_type === 'string' ? "Text" : selectedm?.metric_data_type === 'float' ? "Number" : ""}
                        </Tag>}</span>
                </div>
                {(selectedm?.back_test_available === 1 && selectedm?.metric_data_type === 'float') || selectedm?.metric_col_code === "style_box_code" ? <span>
                    {selectedm?.isVisibleNormally !== false ? <AccessComponent
                        reqAccessLevel={accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === 'STOCK_BACKTEST_METRICS')?.AccessLevel}
                        upgradeComponent={
                            <SubscribeBtnSmall str='Metric performance' accessKey='STOCK_BACKTEST_METRICS' />
                        }
                        component={<button className='btnWhite btnSM mb-1' onClick={() => {
                            setMetricBackTestLoading(true)
                            getScreenerMetricBackTest(selectedm?.metric_col_code)
                            settoggleMetricBackTest(true)
                        }}>
                            Metric performance
                        </button>} />
                        : <></>}

                </span> : <Tooltip title={<span className='textXS w-500'>We do not have sufficient historical data to show back tested performance of this metric. Note that if you use this metric in your screen, back tested performance of screen will also not be available.</span>} arrow={false}>
                    <span>
                        <button className='btnWhite' disabled >Metric performance</button>
                    </span>
                </Tooltip>}
            </div>
            <span style={{ height: "15rem", overflowY: "overlay" }} className='showScroll'>
                <div id="metric_desc" className='ql-editor textSM' sstyle={{ padding: '0px', height: "auto", flex: 1 }}>
                    {selectedm?.metric_desc}
                </div>
            </span>
        </div>
    }



    const RenderMetricOrFuncs = ({ item, index }) => {
        return <span key={makeid(3)} style={{
            padding: "0.75rem 1.25rem",
            borderRadius: "4px 0px 0px 4px",
            display: "flex",
            alignItems: "center",
            backgroundColor: item.category_id === selectedLevel1 ? "var(--blueShade)" : "transparent", cursor: "pointer"
        }}
            onClick={() => {
                setSelectedLevel1(item.category_id)
                // setMobileLevel(2)
                // // setselectedSubCategory("")
                // setSelectedMetric("")
                // setdisableAddRule(true)
                // setmetricFilterType("Basic")
            }}
        >
            <span style={{ flex: 1, color: item.category_id === selectedLevel1 ? "var(--primary)" : "var(--dark3)" }} className="textSM w-500">{item?.category_name}</span>
            <IoIosArrowForward color={item.category_id === selectedLevel1 ? "var(--primary)" : "var(--dark3)"} />
        </span>
    };

    const RenderMetricOrFuncsCatAndSubCat = ({ item }) => {
        ////////console.log(item)
        // return <></>
        return item?.sub_category.filter((ele) => ele?.isVisibleNormally !== false).map((sub_category, subCategoryIndex) => {
            return <div key={makeid(3)} style={{ borderRadius: 2, marginBottom: "2rem" }}>
                <span className="textSM black w-500"> {sub_category.sub_category_name}</span>

                <span style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginTop: "0.625rem" }}>
                    {sub_category.metric?.filter((i) => fromAlert ? i?.type?.includes("Metric") ? i?.metric_data_type === "float" : true : true)?.map((metric, metricIndex) => {

                        // var upDatedFilters = [...checkFiltersList]
                        var upDatedFilters = []
                        // var upDatedFilters = []
                        var itemIndex = -1
                        if (newFiltersList?.length > 0) {
                            itemIndex = [...newFiltersList].findIndex((met) => {
                                if (Object?.keys(met)?.includes("sh_name"))
                                    return metric?.sh_name === met.sh_name
                                else
                                    return met.metric_col_code === metric?.metric_col_code
                            })
                        }
                        var isPresentIndex = itemIndex !== -1



                        if (metric?.isVisibleNormally !== false) {
                            return <AccessComponent key={makeid(3)}
                                reqAccessLevel={metric.auth_flag}
                                upgradeComponent={
                                    <div key={makeid(3)} style={{ cursor: "pointer", color: "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem" }} className='textXS metricNameClass'>
                                        <span onClick={() => {
                                            // setSelectedMetric((metric.metric_col_code || metric.sh_name))
                                            // setdisableAddRule(true)
                                            setcheckFiltersList([])
                                        }}>{metric?.metric_name || metric?.display_name}</span>
                                        <SubscribeIcon customAccessObj={{
                                            IsEnabled: 1,
                                            AccessLevel: Number(metric?.auth_flag),
                                            ModuleName: "Proprietary Metrics",
                                            ModuleDesc: "Get access to all sharpely proprietary metrics and scores."
                                        }} />
                                    </div>
                                }
                                component={<div onClick={() => {
                                    setQueries({})
                                    setselectedm(metric)
                                    onQueryOptionSelect({ item: metric, isFresh: true })
                                    setMobileLevel(2)
                                    // setSelectedMetric(metric.metric_col_code || metric.sh_name)
                                    // // setdisableAddRule(false)
                                    // // setmetricFilterType("Basic")
                                    // isPresentIndex = true
                                    // var selectedm = metric
                                    // upDatedFilters.push({
                                    //     ...selectedm,
                                    //     metric_col_code: metric.metric_col_code || metric.sh_name
                                    // })
                                    // setcheckFiltersList(upDatedFilters)
                                    // // } else {
                                    // //     toast.error("Metric already added")
                                    // // }
                                }}
                                    key={makeid(3)}
                                    style={{ cursor: "pointer", color: (metric.metric_col_code === selectedMetric || metric.sh_name === selectedMetric) ? "var(--primary)" : "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem" }} className='textXS metricNameClass'>
                                    {metric?.metric_name || metric?.display_name}
                                </div>} />
                        } else {
                            return <div key={makeid(3)} ></div >
                        }
                        // if (metric?.isVisibleNormally !== false) {
                        //     return <div onClick={() => {
                        //         // ////////////console.log(e.target.checked)
                        //         // if (!isPresentIndex) {
                        //         // setselectedSubCategory(sub_category.sub_category_id)
                        //         setSelectedMetric(metric?.metric_col_code)
                        //         setmetricFilterType("Basic")
                        //         isPresentIndex = true
                        //         var selectedm = screenerMetric.filter((i) => i.metric_col_code === metric?.metric_col_code)[0]
                        //         upDatedFilters.push({
                        //             ...selectedm,
                        //             metric_col_code: metric?.metric_col_code,
                        //             abVal: selectedm?.metricDefVal,
                        //             pervalue: selectedm?.pervalue,
                        //             queryValue: selectedm?.queryValue,
                        //         })
                        //         setcheckFiltersList(upDatedFilters)
                        //         // } else {
                        //         //     toast.error("Metric already added")
                        //         // }
                        //     }} key={metric?.metric_col_code} style={{ cursor: "pointer", color: metric?.metric_col_code === selectedMetric ? "var(--primary)" : "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem" }} className='textXS'>
                        //         {metric?.metric_name}
                        //     </div>
                        // } else {
                        //     return <div key={"DSF" + metricIndex
                        //     } ></div >
                        // }
                    })}
                </span >
            </div >
        })
    }

    const MetricFilterView = ({ data, index, viewType = 0 }) => {
        var upDatedFilters = []
        upDatedFilters = [...checkFiltersList]

        return <Card bordered style={{ borderColor: viewType === 0 ? "var(--blueShade)" : "var(--grey3)", backgroundColor: viewType === 0 ? "var(--blue2)" : "unset" }} key={index} bodyStyle={{ padding: "0.2rem", display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <span className='mx-3' style={{ flex: 1, alignItems: "center", display: "flex", gap: "0.5rem" }}>

                <span className='textXS black w-bold'>{data?.metric_name || data?.display_name}</span>
                {viewType !== 0 && <BiInfoCircle style={{ cursor: "pointer" }} onClick={() => {
                    settoggleDesc(true)
                }} />}
            </span>

            <div className='mx-3' style={{ display: "flex", gap: "0.5rem", alignItems: "center", justifyContent: "space-between" }}>



                <IoIosCloseCircleOutline className='textSM black' color='var(--dark3)' style={{ cursor: "pointer" }} size={18} onClick={() => {
                    var a = [...upDatedFilters]
                    a.splice(index, 1)
                    setcheckFiltersList(a)
                }} />
            </div>


        </Card>



    }


    const CheckedFilterView = () => {
        //////console.log(queries, selectedQuery)
        return <span style={{ display: "flex", flex: 1, flexDirection: "column", gap: "1.2rem", padding: '1.25rem', flex: 1 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ flex: 1 }} className='textSM black w-700'>Edit rule</span>
                <button className="btnWhite" onClick={() => {
                    setQueries({})
                    setMobileLevel(1)
                }}>Clear</button>
            </div>
            <div className="" style={{ width: "100%", position: "relative", flexWrap: "wrap" }}>
                {Object.entries(queries || {})?.map(([metric, metricObj], index) => {
                    if (Object.keys(selectedQuery)?.length > 0) {
                        if (metric === selectedQuery?.key) {
                            return <span style={{ position: "relative", gap: 10, width: "40%" }}
                                key={generateUniqueKey(metric)} >

                                <Input
                                    onFocus={() => {
                                        setShowDropDown(true);
                                    }}
                                    value={query} autoFocus={true}
                                    onBlur={() => {

                                        if (query?.trim() !== '' && !isNaN(Number(query?.trim()))) {
                                            let isNAN = isNaN(Number(query?.trim()))
                                            let val = !isNAN ? Number(query?.trim()) : query?.trim();


                                            if (selectedQuery?.key === "1_new_add_metric") {
                                                var f = { ...queries }
                                                delete f?.["1_new_add_metric"]
                                                f = {
                                                    ...f,
                                                    [(!isNAN ? Object.keys(f)?.length + "_number" : Object.keys(f)?.length + "_compOperator")]: {
                                                        expression: val,
                                                        expression_arr: val
                                                    }
                                                }

                                                setQueries(f)
                                            } else {
                                                setQueries({
                                                    ...queries,
                                                    [selectedQuery?.key || (!isNAN ? Object.keys(queries)?.length + "_number" : Object.keys(queries)?.length + "_compOperator")]: {
                                                        expression: val,
                                                        expression_arr: val
                                                    }
                                                })
                                            }


                                            // setQueries({
                                            //     ...queries,
                                            //     [selectedQuery?.key || Object.keys(queries)?.length + "_number"]: {
                                            //         expression: val,
                                            //         expression_arr: val
                                            //     }
                                            // })
                                            setQuery("")
                                            if (selectedQuery?.key) {
                                                setSelectedQuery({});
                                                setTimeout(() => {
                                                    if (queryBuilderRef.current) {
                                                        queryBuilderRef.current.focus()
                                                    }
                                                }, 100)
                                            }
                                        }
                                    }}
                                    placeholder={metricObj?.expression === "Add" ? metricObj?.expression_arr?.replace("Add", "Search") || "" : ""}

                                    style={{
                                        borderRadius: "var(--borderRadius)", padding: "0.4rem",
                                        marginLeft: "1rem",
                                        maxWidth: "15rem"
                                    }} onChange={handleQueryInput} className='textSM w-500 dark4 queryDropdownInput' ref={queryBuilderRef} onKeyDown={handleKeyDown}

                                />

                                {/* <input } /> */}
                                {showDropDown && options?.length > 0 ?
                                    <QueryOptionsDropdown
                                        isAdvance={isAdvance}
                                        allowDDPosChange={false}
                                        dropdownPositions={{ top: "150%", left: 0 }}
                                        options={options}
                                        onSelect={(item) => {
                                            onQueryOptionSelect({ item: item, query_key: metric, queryObj: metricObj, isFresh: false });
                                        }}
                                        showDescBox={false}
                                        hideVarOpt={!optionsTabTypeAndShow?.showVarTab}
                                        typesToHide={optionsTabTypeAndShow?.typesToHide}
                                        hideCreateMetric={!optionsTabTypeAndShow?.showCreateVarOpt}
                                        hideTabs={!optionsTabTypeAndShow?.show}
                                        tabsType={optionsTabTypeAndShow?.type} /> : null}

                                {/* {<div style={{ width: "100%" }}></div>} */}
                            </span>
                        }
                    }
                    return (
                        <span style={{ lineHeight: "2.5rem" }} key={generateUniqueKey(metric)}>
                            {metric === "0_compOperator" && metricObj?.expression !== "is between" ? <Select
                                className='textESM black mb-2 '

                                suffixIcon={<IoIosArrowDown size={15} />}
                                defaultValue={metricObj?.expression_arr}
                                style={{
                                    width: "12rem",
                                }}
                                placeholder="Select"
                                onChange={(value) => {
                                    if (customComparators?.map((i) => i?.value)?.includes(value)) {
                                        setSelectedCustomComp({
                                            queryKey: metric,
                                            comparator: basic_comparators?.filter((i) => i?.value === value)?.[0],
                                            selectedItems: []
                                        });
                                        setCustomCompModal(true);
                                    } else {
                                        setQueries({
                                            ...queries,
                                            [metric]: {
                                                expression: basic_comparators?.filter((i) => i?.value === value)?.[0]?.label,
                                                expression_arr: value,
                                                // basic_exp: basic_comparators?.filter((i) => i?.value === value)?.[0]?.label,
                                            }
                                        })
                                    }

                                }}
                                options={basic_comparators}
                            /> :
                                <span className={metricObj?.expression === "Add" ? 'textESM' : "condition-query-item2 textESM"}
                                    onClick={(e) => {
                                        if ((index === 0 && metricObj?.type !== "func")) {
                                            e.stopPropagation()
                                        } else {
                                            if (metricObj?.expression === "Add") {
                                                setQuery("");
                                                setSelectedQuery({
                                                    key: metric,
                                                    isAdd: true,
                                                    value: metricObj?.prevdata,
                                                })
                                                setTimeout(() => {
                                                    if (queryBuilderRef.current) {
                                                        queryBuilderRef.current.focus()
                                                    }
                                                }, 100)
                                                e.stopPropagation()
                                                // } else if (!(metric?.includes("_compOperator") || metric?.includes("_conditionOperator") || metric?.includes("_number") || metricObj?.type === "metric")) {
                                            } else if (metricObj?.type === "func") {
                                                setSelectedFunc({
                                                    metric: metric,
                                                    query: metricObj,
                                                    allowFuncChange: index !== 0
                                                })
                                                setFuncModal(true)
                                            }
                                            else if (metricObj?.type === "metric" && metricObj?.isMultiple === true && multipleTypeMetrics?.includes(metricObj?.expression_details?.metric_col_code)) {
                                                setSelectedMetric({
                                                    queryKey: metric,
                                                    metric: metricObj?.expression_details,
                                                    comparator: metricObj?.comparator,
                                                    selectedItems: metricObj?.expression_arr
                                                })
                                                setMetricModal(true);
                                            }
                                            else if (metricObj?.isCustomComparator === true && customComparators?.map((i) => i?.value)?.includes(metricObj?.comparator)) {
                                                setSelectedCustomComp({
                                                    queryKey: metric,
                                                    comparator: metricObj?.expression_details,
                                                    selectedItems: metricObj?.expression_arr
                                                });
                                                setCustomCompModal(true);
                                            }
                                            else {
                                                setQuery(String(metricObj?.expression));
                                                setSelectedQuery({
                                                    key: metric,
                                                    value: metricObj
                                                })
                                                setTimeout(() => {
                                                    if (queryBuilderRef.current) {
                                                        queryBuilderRef.current.focus()
                                                    }
                                                }, 100)
                                                e.stopPropagation()
                                            }
                                        }
                                    }}
                                    style={{
                                        cursor: "pointer",
                                        border: index !== 0 && metricObj?.expression !== "Add" ? `1px solid var(--grey2)` : "",
                                        borderRadius: "var(--borderRadius)",
                                        padding: index !== 0 && metricObj?.expression !== "Add" ? "0.4rem" : "",
                                        backgroundColor: index !== 0 ? "var(--grey4)" : "",
                                        width: "15rem",
                                        alignItems: "center",
                                        // display: "flex",
                                        // gap: "0.4rem",
                                        margin: `0.5rem 0rem 0.5rem ${index !== 0 && metricObj?.expression !== "is between" ? "1rem" : 0}`,
                                    }}>
                                    {expUIParser(isAdvance ? metricObj?.expression : metricObj?.expression, metricObj, metric, false)}
                                    {metricObj?.expression !== "Add" && (index === 0 ? metricObj?.type === "func" ? <BiSolidPencil className="mx-2 mb-1" /> : null : <BiSolidPencil className="mx-2 mb-1" />)}
                                </span>}
                            {index === 0 && <div style={{ width: "100%" }}></div>}
                        </span>
                    )
                })}
            </div >
        </span >
    }


    // basic items

    return (
        <React.Fragment>
            <div style={{ display: isVisible ? "flex" : "none", flexDirection: "column", width: "100%", border: "0px", position: "relative", }}>

                {/* <button className="btnBlack w-bold" style={{ background: "var(--dark4)", border: "0px solid var(--purple)", color: "var(--white)" }} onClick={() => {
                    setAllMetricDrawer(true)
                }}><IoAddCircle /> Add rule</button> */}
                {isVisible && Object.keys(initialQueries)?.length <= 0 ? <div onClick={(e) => {
                    e.stopPropagation()
                    setAllMetricDrawer(!allMetricDrawer)
                }} className="textESM w-500" style={{ cursor: "pointer" }}>
                    {sample_placeholder}
                </div> : <div style={{ display: "flex", gap: "0.2rem", alignItems: "center" }}>
                    <div
                        onClick={(e) => {
                            e.stopPropagation()
                            setAllMetricDrawer(!allMetricDrawer)
                        }}
                        className="" style={{ display: "flex", flex: 1, gap: '5px 2px', width: "100%", position: "relative", flexWrap: "wrap", cursor: "pointer" }}>
                        {Object.entries(initialQueries || {})?.map(([metric, metricObj]) => {
                            return (
                                <span style={{}} key={generateUniqueKey(metric)}>
                                    <span className={'textESM'}
                                        style={{
                                            cursor: "pointer",
                                            border: "none", outline: "none",
                                            borderRadius: "var(--borderRadius)",
                                            padding: "0.25rem",
                                            margin: "0 0.4rem 0.4rem 0",
                                        }}>
                                        {expUIParser(isAdvance ? metricObj?.expression : metricObj?.expression, metricObj, metric)}
                                    </span>

                                </span>
                            )
                        })}
                    </div>
                    {Object.entries(initialQueries || {})?.length > 0 && <div className='d-flex justify-content-end align-items-center' style={{}}>
                        <div title="Edit" className='d-flex justify-content-center align-items-center textESM w-500' style={{ color: "var(--dark2)", cursor: "pointer" }}
                            onClick={(e) => {
                                e.stopPropagation()
                                setAllMetricDrawer(!allMetricDrawer)
                            }}>
                            <BiEdit /></div>
                    </div>}
                </div>}
                <Drawer
                    width={isMobile ? '100%' : "85vw"}
                    closable={false}
                    title={false} placement="right"
                    onClose={() => {
                        setAllMetricDrawer(!allMetricDrawer)
                        toggleBasicDrawer(!allMetricDrawer)
                        onQueriesUpdate(initialQueries);
                        // settoggleMetricBackTest(false)
                    }}
                    styles={{ body: { padding: isMobile ? 0 : '' } }}
                    open={allMetricDrawer}
                    footer={false}>
                    <Card
                        actions={[
                            <div style={{ display: "flex", gap: "0.5rem", alignItems: "center", justifyContent: "flex-end", paddingRight: "1rem" }}>
                                <button className="btnWhite" onClick={() => {
                                    setAllMetricDrawer(!allMetricDrawer)
                                    toggleBasicDrawer(!allMetricDrawer)
                                    onQueriesUpdate(initialQueries);
                                }}>Cancel</button>,
                                <button className="btnBlack"
                                    onClick={() => {
                                        ////console.log("SADSADASDasdsda", queries)
                                        if (!Object?.keys(queries)?.includes("1_new_add_metric")) {
                                            onQueriesUpdate(queries);
                                        } else {
                                            var lastVals = { ...queries }?.["1_new_add_metric"]
                                            if (lastVals?.type === "metric") {
                                                var f = { ...queries }
                                                delete f?.["1_new_add_metric"]
                                                f = {
                                                    ...f,
                                                    [(Object.keys(f)?.length + "_" + lastVals?.expression_arr)]: {
                                                        ...lastVals
                                                    }
                                                }
                                                onQueriesUpdate(f);
                                            } else {
                                                toast.error("Invalid rule.")
                                            }
                                        }

                                    }}>{Object.keys(initialQueries)?.length === 0 ? "Add rule" : "Update rule"}</button>
                            </div>]
                        }
                        bodyStyle={{ height: "100%", padding: 0, overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%" }}
                        bordered>
                        <div style={{ display: "flex", flex: 1, flexDirection: "column", width: "100%", height: "100%" }}>
                            <div style={{ display: "flex", flexDirection: isMobile ? 'column-reverse' : 'row', gap: '1rem', alignItems: isMobile ? 'stretch' : "center", justifyContent: "space-between", padding: "1rem" }}>
                                <CustomSegmentedTab
                                    isBlock={false}
                                    options={Object.keys(allFuncAndMetricForDrawer)?.map((i) => {
                                        return {
                                            key: i,
                                            value: i,
                                            label: i,
                                        }
                                    })}
                                    value={selectedOptionInBasicDrawer}
                                    onChange={(e) => {
                                        setselectedOptionInBasicDrawer(e)
                                        setSelectedLevel1(allFuncAndMetricForDrawer?.[selectedOptionInBasicDrawer]?.[0]?.category_id)
                                    }}
                                />
                                <div className="d-flex align-items-center" style={{ gap: "1rem" }}>
                                    <QueryOptionsDropdownV2 isAdvance={isAdvance} options={[...screenerMetrics, ...functions]?.map((el) => {
                                        return {
                                            ...el, tabType: el?.type === 'Metric F' || el?.type === 'Metric T' ? "Metrics" : el?.type
                                        }
                                    })} placeholder='Search Metrics' width={isMobile ? '100%' : '20rem'} showAllTabTypes={false}
                                        tabTypesToShow={query?.expression_details?.category === 'Fundamental' && query?.expression_details?.type === 'Fundamental Function' ?
                                            ['Metric F', 'Variable'] : ['Metric F', 'Variable', 'Metric T']}
                                        onSelect={(it) => {
                                            setQueries({});
                                            setselectedm(it);
                                            onQueryOptionSelect({ item: it, isFresh: true });
                                        }} dropdownPositions={{ right: "0%" }} tabs={[{ key: "all", label: "All", value: "" }].concat(Object.keys(allFuncAndMetricForDrawer || {})?.map((el) => {
                                            return {
                                                key: el,
                                                label: el,
                                                value: el
                                            }
                                        }))} />
                                    <IoIosClose onClick={() => {
                                        setAllMetricDrawer(!allMetricDrawer)
                                        toggleBasicDrawer(!allMetricDrawer)
                                    }}
                                        size={"1.75rem"} style={{ cursor: "pointer" }} />
                                </div>
                            </div>
                            <div style={{ flex: 1, overflowY: "scroll", display: "flex", width: "100%" }}>
                                <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", paddingRight: 0, overflowY: "overlay" }} style={{ maxWidth: isMobile ? Object.keys(queries).length === 0 ? '50%' : '0' : '', display: "flex", flexDirection: "column", flex: 1.1, height: "100%", borderRadius: 0, borderWidth: 0.5 }} className='showScroll scrBxInBody'>
                                    {allFuncAndMetricForDrawer?.[selectedOptionInBasicDrawer]?.map((item, index) => {
                                        return RenderMetricOrFuncs({
                                            item, index
                                        })
                                    }
                                    )}
                                </Card>
                                <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", overflowY: "overlay" }} style={{ maxWidth: isMobile ? Object.keys(queries).length === 0 ? '50%' : '0' : '', display: "flex", flexDirection: "column", flex: 1.1, height: "100%", borderRadius: 0, borderWidth: 0.5 }} className='showScroll scrBxInBody'>
                                    <RenderMetricOrFuncsCatAndSubCat item={allFuncAndMetricForDrawer?.[selectedOptionInBasicDrawer]?.filter((i) => i.category_id === selectedLevel1)[0]} />
                                </Card>
                                <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", overflowY: "scroll", padding: 0 }} style={{ maxWidth: isMobile ? Object.keys(queries).length > 0 ? '100%' : '0' : '', display: "flex", flexDirection: "column", flex: 2.8, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                                    {Object.keys(queries).length > 0 && <CheckedFilterView />}
                                    {Object.keys(queries).length > 0 && selectedm?.metric_desc && selectedm?.metric_desc?.props?.dangerouslySetInnerHTML?.['__html'] !== 'undefined' && <DrawerMetricDescComp />}
                                </Card>
                            </div>
                        </div>
                    </Card>




                    {funcModal && <FunctionsEditor allFundsData={allFundsData} isAdvance={isAdvance} variables={variables} indices={indices} timeframe={timeframe} open={funcModal} toggle={() => {
                        setFuncModal(false);
                        setSelectedFunc({});
                        setTimeout(() => {
                            if (queryBuilderRef.current) {
                                queryBuilderRef.current.focus()
                            }
                        }, 100)
                    }} selectedOption={selectedFunc} functions={functions?.filter((i) => i?.type !== "Patterns")} screenerMetrics={screenerMetrics}
                        allowFuncChange={selectedFunc?.allowFuncChange}
                        typesToHide={optionsTabTypeAndShow?.typesToHide}
                        onDone={(modifiedOption) => {
                            if (modifiedOption?.isItemChanged || selectedQuery?.key) {
                                let qrKeys = Object.keys(queries || {});
                                let qrObj = {};
                                let ke = selectedQuery?.key ? selectedQuery?.key : modifiedOption?.keyToReplaceWith

                                ////console.log(ke)
                                qrKeys.forEach((k) => {
                                    if (modifiedOption?.keyToReplace === k) {
                                        qrObj[modifiedOption?.keyToReplaceWith] = modifiedOption?.query;
                                    }
                                    else {
                                        qrObj[k] = queries[k];
                                    }
                                })

                                //console.log("DASDSADSADASD", selectedFunc, modifiedOption, qrObj)

                                setQueries({ ...qrObj, ...selectedFunc?.extra });
                            }
                            else {

                                //console.log("SADSADASD", modifiedOption, selectedFunc)

                                // if(query_key_initial)

                                let qrKeys = Object.keys(queries || {});
                                let qrObj = {};


                                ////console.log(ke)
                                if (qrKeys?.length > 0)
                                    qrKeys.forEach((k) => {
                                        if (modifiedOption?.keyToReplace === k) {
                                            qrObj[modifiedOption?.keyToReplaceWith] = modifiedOption?.query;
                                        }
                                        else {
                                            qrObj[k] = queries[k];
                                        }
                                    })
                                else
                                    qrObj = {
                                        ...queries,
                                        [modifiedOption?.keyToReplaceWith]: modifiedOption?.query,
                                        ...selectedFunc?.extra
                                    }

                                //console.log("DASDSADSADASD", selectedFunc, modifiedOption, qrObj)

                                // setQueries({ ...qrObj, ...selectedFunc?.extra });

                                var f = { ...qrObj, ...selectedFunc?.extra }
                                if (modifiedOption?.query_key_initial === "1_new_add_metric")
                                    delete f?.["1_new_add_metric"]
                                setQueries({
                                    ...f
                                })
                                // setQueries({
                                //     ...queries,
                                //     [modifiedOption?.keyToReplaceWith]: modifiedOption?.query,
                                //     ...selectedFunc?.extra
                                // })
                            }
                            setQuery("");
                            setSelectedFunc({});
                            setFuncModal(false);
                            setTimeout(() => {
                                if (queryBuilderRef.current) {
                                    queryBuilderRef.current.focus()
                                }
                            }, 100)
                        }} />}
                    {metricModal && <MetricsEditor open={metricModal} selectedMetric={selectedMetric} toggle={() => {
                        setMetricModal(false);
                        setSelectedMetric({});
                        setTimeout(() => {
                            if (queryBuilderRef.current) {
                                queryBuilderRef.current.focus()
                            }
                        }, 100)
                    }} onDone={(modifiedOption) => {

                        //////console.log(modifiedOption)
                        setQueries({
                            ...queries,
                            [modifiedOption?.queryKey]: modifiedOption?.query
                        })
                        setMetricModal(false);
                        setQuery('');
                        setTimeout(() => {
                            if (queryBuilderRef.current) {
                                queryBuilderRef.current.focus()
                            }
                        }, 100)
                    }} />}
                    {customCompModal && <CustomComparatorEditor functions={functions} open={customCompModal} selectedCustomComp={selectedCustomComp} toggle={() => {
                        setCustomCompModal(false);
                        setSelectedCustomComp({});
                        setTimeout(() => {
                            if (queryBuilderRef.current) {
                                queryBuilderRef.current.focus()
                            }
                        }, 100)
                    }} variables={variables} screenerMetrics={screenerMetrics} onDone={(modifiedOption) => {

                        var f = {
                            ...queries,
                            [modifiedOption?.queryKey]: modifiedOption?.query
                        }
                        let lastKey = Object.keys(f || {})?.reverse()?.[0]
                        if (Object.keys(f)?.length > 2) {
                            delete f?.[lastKey]
                        }
                        setQueries({
                            ...f
                        })
                        setCustomCompModal(false);
                        setSelectedCustomComp({});
                        setQuery('');
                        setTimeout(() => {
                            if (queryBuilderRef.current) {
                                queryBuilderRef.current.focus()
                            }
                        }, 100)
                    }} />}
                </Drawer>
                <Drawer
                    width={'65vw'}
                    height={"40vh"}
                    title={null} placement="right"
                    onClose={() => {
                        //setmodal(!modal)
                        settoggleMetricBackTest(false)
                    }}
                    closable={false}
                    open={toggleMetricBackTest}
                    styles={{ footer: { textAlign: 'right' } }}
                    footer={<button className="btnBlack ms-auto" onClick={() => {
                        settoggleMetricBackTest(false)
                    }}>Close</button>}>
                    <div className="">
                        <CardHeader heading={`Historical metric performance`} hideHeaderBlueLine headingType={2} />
                        <div style={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "1.25rem 0rem" }}>
                            <span>
                                <CustomSegmentedTab
                                    isBlock={false}
                                    options={metricPerfOptions}
                                    value={metricPerfCur}
                                    onChange={(e) => setmetricPerfCur(e)}
                                />
                            </span>
                            {metricPerfOptions?.filter((i) => i.value === metricPerfCur)?.[0]?.children}
                        </div>
                    </div>
                </Drawer>
            </div>
        </React.Fragment>
    )
})