import { formatDate } from "./DateFormatter";
import moment from 'moment';
import { DecimalValueFormat } from "./DecimalValueFormat";

function dropdownCustom(data, keys) {
  for (const [key, value] of Object.entries(data)) {
    value.id = value[keys[0]] - 1; // Assign new key
    value.name = value[keys[1]]; // Assign new key
  }

  return (data);
}

var aa = 0

function addMonths(date, months) {
  date.setMonth(date.getMonth() + months);
  return date;
}

const arr = x => Array.from(x);
const num = x => Number(x) || 0;
const str = x => String(x);
const isEmpty = xs => xs.length === 0;
const take = n => xs => xs.slice(0, n);
const drop = n => xs => xs.slice(n);
const reverse = xs => xs.slice(0).reverse();
const comp = f => g => x => f(g(x));
const not = x => !x;
const chunk = n => xs =>
  isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

// numToWords :: (Number a, String a) => a -> String
let numToWords = n => {

  let a = [
    '', 'one', 'two', 'three', 'four',
    'five', 'six', 'seven', 'eight', 'nine',
    'ten', 'eleven', 'twelve', 'thirteen', 'fourteen',
    'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
  ];

  let b = [
    '', '', 'twenty', 'thirty', 'forty',
    'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
  ];

  let g = [
    '', 'thousand', 'million', 'billion', 'trillion', 'quadrillion',
    'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion'
  ];

  // this part is really nasty still
  // it might edit this again later to show how Monoids could fix this up
  let makeGroup = ([ones, tens, huns]) => {
    return [
      num(huns) === 0 ? '' : a[huns] + ' hundred ',
      num(ones) === 0 ? b[tens] : b[tens] && b[tens] + '-' || '',
      a[tens + ones] || a[ones]
    ].join('');
  };

  let thousand = (group, i) => group === '' ? group : `${group} ${g[i]}`;

  if (typeof n === 'number')
    return numToWords(String(n));
  else if (n === '0')
    return 'zero';
  else
    return comp(chunk(3))(reverse)(arr(n))
      .map(makeGroup)
      .map(thousand)
      .filter(comp(not)(isEmpty))
      .reverse()
      .join(' ');
};


function changeObjKey(dataf, keys, newKeys, type, ty) {
  try {
    if (dataf) {
      if (type === "type") {
        var data = [...dataf]

        for (const [key, value] of Object.entries(data)) {
          value[newKeys[0]] = value[keys[0]]; // Assign new key
          value[newKeys[1]] = new Date(value[keys[1]].toString().replace("T00:00:00Z", "")).getTime(); // Assign new key
          value["name"] = "Date: " + formatDate(value[keys[1]].toString().replace("T00:00:00Z", ""))
        }
        return data;

      } if (type === "normal") {
        var data = typeof (dataf) === "string" ? JSON.parse(dataf) : JSON.parse(JSON.stringify(dataf))
        for (const [key, value] of Object.entries(data)) {
          for (var i = 0; i < keys.length; i++) {
            value[newKeys[i]] = typeof (value[keys[i]]) === "number" ? Number(DecimalValueFormat({ num: value[keys[i]] })) : value[keys[i]];
          }
          // value[newKeys[0]] = value[keys[0]]; // Assign new key
          // value[newKeys[1]] = value[keys[1]]; // Assign new key
        }
        return data;

      } else if (type === "normalanddelold") {
        var data = typeof (dataf) === "string" ? JSON.parse(dataf) : JSON.parse(JSON.stringify(dataf))
        for (const [key, value] of Object.entries(data)) {
          for (var i = 0; i < keys.length; i++) {
            value[newKeys[i]] = typeof (value[keys[i]]) === "number" ? Number(DecimalValueFormat({ num: value[keys[i]] })) : value[keys[i]];
          }
          // value[newKeys[0]] = value[keys[0]]; // Assign new key
          // value[newKeys[1]] = value[keys[1]]; // Assign new key
        }
        return removeUnwantedKeyValAdmin(data, newKeys);

      } else if (type === "mfetfChart") {


        var newArr = JSON.parse(dataf.data)


        for (const [key, value] of Object.entries(newArr)) {
          var a = String(value[keys[1]]).replace("T00:00:00.000Z", "").split('-').join('/')
          var dn = moment(a, 'YYYY/MM/DD')

          value[newKeys[0]] = Number(value[keys[0]]).toFixed(2) * ty; // Assign new key
          value[newKeys[1]] = new Date(dn).getTime(); // Assign new key
          value["name"] = "Date: " + formatDate(value[keys[1]].toString().replace(" 00:00:00", ""))
        }
        return newArr;
      } else if (type === "stockLiveDaily") {



        var newArr = dataf

        for (const [key, value] of Object.entries(newArr)) {
          // var a = String(value[keys[1]]).replace("T00:00:00.000Z", "").split('-').join('/')
          // var dn = moment(value[keys[1]])

          value[newKeys[0]] = Number(value[keys[0]]).toFixed(2) / ty; // Assign new key
          value[newKeys[1]] = Number(value[keys[1]]) * 1000; // Assign new key
          value["name"] = "Date: " + new Date(value[keys[1]] * 1000).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })
        }

        return newArr;
      } else if (type === "stockhistchart") {


        var newArr = JSON.parse(dataf)


        for (const [key, value] of Object.entries(newArr)) {
          var a = String(value[keys[1]]).replace("T00:00:00.000Z", "").split('-').join('/')
          var dn = moment(a, 'YYYY/MM/DD')

          value[newKeys[0]] = Number(value[keys[0]]).toFixed(2) * ty; // Assign new key
          value[newKeys[1]] = new Date(dn).getTime(); // Assign new key
          value["name"] = "Date: " + formatDate(value[keys[1]].toString().replace(" 00:00:00", ""))
        }

        return newArr;
      } else if (type === "rollingTearchart") {
        var newArr = dataf


        for (const [key, value] of Object.entries(newArr)) {
          var a = String(value[keys[1]]).split('-').join('/')
          var dn = moment(a, 'YYYY/MM/DD')

          value[newKeys[0]] = Number(value[keys[0]]).toFixed(2) * 1
          value[newKeys[1]] = new Date(dn).getTime(); // Assign new key
          value["name"] = "Date: " + formatDate(value[keys[1]].toString().replace(" 00:00:00", ""))
        }
        return newArr;
      } else if (type === "liveSocketChart") {
        var newArr = dataf


        for (const [key, value] of Object.entries(newArr)) {
          // var a = String(value[keys[1]]).split('-').join('/')
          var dn = value[keys[1]] * 1000
          value[newKeys[0]] = Number(value[keys[0]]).toFixed(2) * 1
          value[newKeys[1]] = new Date(dn).getTime() // Assign new key
          value["name"] = "Date: " + formatDate(dn)
        }
        return newArr;
      } else {
        var newArr = JSON.parse(dataf.data)

        for (const [key, value] of Object.entries(newArr)) {
          var a = String(value[keys[1]]).replace(" 00:00:00", "").split('-').join('/')
          var dn = moment(a, 'DD/MM/YYYY')

          value[newKeys[0]] = Number(value[keys[0]]).toFixed(2) * ty; // Assign new key
          value[newKeys[1]] = new Date(dn).getTime(); // Assign new key
          value["name"] = "Date: " + formatDate(value[keys[1]].toString().replace(" 00:00:00", ""))
        }
        return newArr;
      }
    }
    else return []
  } catch (error) {
    return []
  }


}

function changeObjKeyAdmin(dataf, keys, newKeys) {
  var data = JSON.parse(JSON.stringify(dataf))
  for (const [key, value] of Object.entries(data)) {
    keys.map((a, i) => {
      if (value[keys[i]] !== undefined) {
        value[newKeys[i]] = value[keys[i]];
      }
      // Assign new key
    })
  }
  return data;
}

function removeUnwantedKeyValAdmin(dataf, keys) {
  var data = JSON.parse(JSON.stringify(dataf))
  data.forEach(function (v) {
    var keysF = Object.keys(v)
    keysF.map((key) => {
      if (!keys.includes(key)) {
        delete v[key]
      }
    })
  });
  return data;
}

function filterByKeys(obj, keys = []) {
  try {
    const filtered = {}
    keys?.forEach(key => {
      if (obj.hasOwnProperty(key)) {
        filtered[key] = obj[key]
      }
    })

    // console.log(filtered)
    return filtered
  } catch (error) {
    return []
  }

}

function matchObjects(obj1, obj2, keysToMatch) {
  // Initialize a flag to track whether the objects match
  try {
    let match = true;

    // Iterate through the keys to match
    for (const key of keysToMatch) {
      if (obj1?.[key] !== obj2?.[key]) {
        // If the values of the keys don't match, set the match flag to false and break the loop
        match = false;
        break;
      }
    }

    return match;
  } catch (error) {
    return false
  }
}

function joinAndSum(arr, keysToMatch, keysToSum) {
  // console.log(arr)
  try {
    const groupedData = arr?.reduce((result, current) => {
      const key = keysToMatch?.map(k => current?.[k])?.join('-');

      if (!result?.[key]) {
        result[key] = { ...current };
      } else {
        // Sum the specified keys
        keysToSum?.forEach(k => {
          result[key][k] += current[k];
        });
      }

      return result;
    }, {});

    return Object.values(groupedData);
  } catch (error) {
    return []
  }
}

function convertToTime(input) {
  const intervals = {
    'D': 'day',
    'W': 'week',
    'M': 'month',
    'Y': 'year'
  };

  const regex = /(\d+)([DWMY])/g;
  const result = [];

  let match;
  while ((match = regex.exec(input)) !== null) {
    const quantity = parseInt(match[1], 10);
    const unit = match[2];

    if (intervals[unit]) {
      if (unit === "D" && quantity === 1) {
        result.push(`Daily`);
      } else {
        result.push(`${quantity > 1 ? String(quantity) + " " : ""}${intervals[unit]}${quantity > 1 ? 's' : ''}`);
      }
    }
  }

  return result.join(', ');
}

function convertBasketObjToPortParams(data={}){

}

export { dropdownCustom, changeObjKey, addMonths, numToWords, changeObjKeyAdmin, removeUnwantedKeyValAdmin, filterByKeys, matchObjects,joinAndSum,convertToTime,convertBasketObjToPortParams };

