import { Card } from 'antd';
import React from 'react';
import { HiOutlineArrowLongRight } from 'react-icons/hi2';
import { useNavigate } from 'react-router';

export const GIGO = React.memo(() => {

    const navigate = useNavigate();
    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';

    return <>
        <Card style={{ borderRadius: "0px", padding: "0px", background: "transparent" }} bodyStyle={{ padding: "0rem", background: "transparent" }}>
            <div style={{
                // backgroundImage: `url(${baseImgURL}sharpely-asset/images/Home/garbage1.webp),url(${baseImgURL}sharpely-asset/images/Home/garbage2.webp)`,
                backgroundSize: "cover,cover", backgroundRepeat: "no-repeat,no-repeat"
            }} >
                <div className={`marHomeGarbage ${isDev ? "maxWidthContainer" : ""}`}>
                    <div className='padAllCampaign marHomeLeftAlign align-items-sm-center' style={{
                        gap: "1.5rem", display: "flex", flexDirection: "column", textAlign: "center",
                    }}>
                        <h2 className="headLGtoXS margin0" style={{ fontWeight: 700, width: '70%', color: 'rgba(255, 255, 255, 0.8)' }}>
                            Avoiding the Garbage In Garbage Out <span style={{ color: "gold" }}>(GIGO)</span> trap
                        </h2>
                        <div className='dark2 textMDtoESM' style={{ width: "62%" }}>
                            The sanctity of any data-based investment platform lies in the quality of underlying data. If the data is not good enough, the entire thing can quickly cascade into a garbage-in-garbage-out situation. We take utmost care in ensuring top quality data
                        </div>
                        <div className='d-flex align-items-center' style={{
                            gap: "0.5rem", marginTop: "0.5rem", cursor: "pointer"
                        }}>
                            <div className='textMDtoSM w-500' style={{ color: "var(--primary)" }}
                                onClick={() => navigate('/knowledge-base/data-&-methodology')}>
                                Learn more about our data and methodology
                                <HiOutlineArrowLongRight color='var(--primary)' className='textMD w-500' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    </>
})
