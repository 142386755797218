import { noDataFoundIllustration, noSearchResult, screenLine, upgrade } from 'Assets/images';
import React from 'react';

export const NoDataFound = ({
    message = "No data found",
    ctaText,
    ctaAction,
    width = '10rem',
    gap = '0.75rem',
    textColor = 'black',
    textAlign = 'center',
    msgMaxWidth = 'auto',
    imgSrc,
    imgType = 'nodata',
    marginBottom = '',
    bordered = false,
    padding = ""
}) => (
    <div className={`d-flex align-items-center justify-content-center flex-column ${bordered ? 'shadowXS' : ''}`} style={{
        textAlign: textAlign, gap: gap, width: "100%", height: "100%",
        marginBottom: marginBottom, padding: bordered ? padding ? padding : '1.25rem' : 0, border: bordered ? '1px solid var(--grey2)' : 'none', borderRadius: '5px'
    }}>
        <img loading="lazy"  alt="" src={imgSrc ? imgSrc : imgType === 'upgrade' ? upgrade : screenLine} style={{ width: width, maxWidth: "100%" }} />
        {message && <div className='textSM w-500 dark3' style={{ maxWidth: msgMaxWidth }}>
            {message}
        </div>}
        {ctaText && <button onClick={() => ctaAction()} className='btnBlack' style={{ cursor: "pointer", userSelect: "none" }}>
            {ctaText}
        </button>}
    </div>
)