import { Card, Select } from 'antd';
import AccessComponent from 'Components/AccessComponent';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { CardHeader } from 'Components/atoms/SmallComponents';
import { formatDateWithTime } from 'Components/DateFormatter';
import InstrumentListTable from 'Components/InstrumentListTable';
import { NoDataFound } from 'Components/NoDataFound';
import { timeframes } from 'Components/Screener/ScreenerUtils';
import { useSubscribeMiddleware } from 'Components/SubscribeModal';
import UpgradeToPro from 'Components/UpgradeToPro';
import { useIsMobile } from 'CustomHooks/useIsMobile';
import { useUserDataHooks } from 'CustomHooks/useUserDataHooks';
import { fetchFundDataWithoutFilter } from 'Libs/fetchFundData';
import { quantile, sortFunds, stock_PaidCols, useGetAssetType } from 'Libs/utils';
import React from 'react';
import { BiPlusCircle } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AdminServicesPY from 'Services/api/AdminServicesPY';

const MyScreens = ({ widgetItem, allStocks, allETFs, allMutualFunds }) => {

    const isMobile = useIsMobile();
    const { getUserSuperScreens, getUserScreens } = useUserDataHooks();
    const subscribeMiddle = useSubscribeMiddleware();
    const [allFundsData, setAllFundsData] = React.useState([]);
    const [allScreens, setAllSceens] = React.useState([]);
    const [selectedScreen, setSelectedScreen] = React.useState({});
    const [screenedData, setScreenedData] = React.useState({});
    const [screenedCols, setScreenedCols] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [loadingFunds, setLoadingFunds] = React.useState(true);
    const userCustomCols = useSelector((state) => state?.userCustomCols?.userCustomCols);
    const navigate = useNavigate();
    const stockType = useGetAssetType(4);
    const etfType = useGetAssetType(1);
    const mfType = useGetAssetType(2);
    const currScreenType = selectedScreen?.type === 4 ? stockType : selectedScreen?.type === 1 ? etfType : mfType;
    const screenTypeKey = selectedScreen?.type === 4 ? "SAVE_SCREEN_AND_SET_ALERTS" : selectedScreen?.type === 1 ? "SAVE_AND_TRACK_ETF_SCREENS" : "SAVE_AND_TRACK_MF_SCREENS";
    const screenDetailedAccessSt = subscribeMiddle({ moduleKey: "SAVE_SCREEN_AND_SET_ALERTS", getDetailedAccessData: true, usageCount: allScreens?.filter((el) => el?.type === 4)?.length, checkLimit: true });
    const screenDetailedAccessETF = subscribeMiddle({ moduleKey: "SAVE_AND_TRACK_ETF_SCREENS", getDetailedAccessData: true, usageCount: allScreens?.filter((el) => el?.type === 1)?.length, checkLimit: true });
    const screenDetailedAccessMF = subscribeMiddle({ moduleKey: "SAVE_AND_TRACK_MF_SCREENS", getDetailedAccessData: true, usageCount: allScreens?.filter((el) => el?.type === 2)?.length, checkLimit: true });
    const currScreenAccess = selectedScreen?.type === 4 ? screenDetailedAccessSt : selectedScreen?.type === 1 ? screenDetailedAccessETF : screenDetailedAccessMF;

    const getSuperScreens = async () => {
        let s = await getUserSuperScreens();
        return s?.userScreens?.map((el) => {
            return {
                ...el,
                isSuper: true,
                type: 4
            }
        })
    }

    // let getStockScreens = async () => {
    //     let s = await getUserScreens({ type: stockType });
    //     return s?.userScreens?.map((el) => {
    //         return {
    //             ...el,
    //             type: 4
    //         }
    //     })
    // }

    let getETFScreens = async () => {
        let s = await getUserScreens({ type: etfType });
        return s?.userScreens?.map((el) => {
            return {
                ...el,
                type: 1
            }
        })
    }
    let getMFScreens = async () => {
        let s = await getUserScreens({ type: mfType });
        return s?.userScreens?.map((el) => {
            return {
                ...el,
                type: 2
            }
        })
    }
    const loadData = async () => {
        setAllFundsData([...allStocks?.map((el) => { return { ...el, type: 4 } }), ...allETFs?.map((el) => { return { ...el, type: 1 } }), ...allMutualFunds?.map((el) => { return { ...el, type: 2 } })]);

        Promise.all([getSuperScreens(), getETFScreens(), getMFScreens()]).then((values) => {
            let temp = values.flat().filter(s => !s.is_deleted && s.show_as_screen);
            setAllSceens(temp);
            setSelectedScreen(temp?.[0] || {});
            setLoading(false);
        }).catch((e) => {
            console.log(e);
            setAllSceens([]);
            setLoading(false);
        })
    }

    const loadScreenFunds = async (item) => {
        setLoadingFunds(true);
        try {
            if (item?.isSuper) {
                let config = item?.config;
                config = String(config)?.substring(1, String(config)?.length - 1);
                config = JSON.parse(config);
                let code = config?.code;
                let res = await AdminServicesPY.getAllScreenedStocksAdvV2({
                    code: JSON.stringify({
                        code: code,
                    }),
                    timeframe: timeframes?.[0]?.value,
                    barsAgo: null
                }).then(r => r)
                res = JSON.parse(res?.data?.replaceAll(/NaN/g, null))
                let funds = (res?.instruments || []).map((i) => {
                    return {
                        ...[...allFundsData].filter((ii) => ii?.symbol === i?.symbol)[0],
                        ...i,
                    }
                })
                if (res?.instruments?.length > 0) {
                    let cols = Object?.keys(res?.instruments?.[0] || {})?.filter((i) => (!(['symbol', 'mcap']?.includes(i) || i?.match(/^c[0-9]{0,3}[0-9]$/))))
                    setScreenedCols(cols);
                }
                setScreenedData({ ...res, instruments: funds })
            }
            else {
                let allFunds = JSON.parse(JSON.stringify(allFundsData))
                let final = item?.rules || [];
                let finalTableColumns = []
                let excludedKeys = [
                    "clientId", "screener_name", "is_fof", "is_index_fund", "category_id", "investing_style", "is_etf_fund", "is_exclusion_list", 'nse_segment', 'bse_segment', 'symbol', "bse_ticker", "proper_name", "nse_active", "gdf_symbol", "nse_series", "as_on_date", "price"
                ]
                if (item?.type === 4) {
                    let cols = [...stock_PaidCols]
                    let userCustomColumn = userCustomCols?.find((el) => el?.InstrumentType === stockType?.strVal?.toUpperCase());
                    if (userCustomColumn) {
                        cols = [...cols, ...userCustomColumn?.Columns?.split('|')?.filter((el) => el)]
                    }
                    var filFunds = await AdminServicesPY.getAllScreenedStocksNew({ rules: final, cols: cols?.filter((i) => i !== "proper_name") }).then((r) => r)
                    filFunds = filFunds?.data?.length > 0 ? filFunds?.data : []
                    allFunds = JSON.parse(filFunds)?.map((i) => {
                        return {
                            ...allFunds?.filter((ii) => ii?.symbol === i?.symbol)?.[0],
                            ...i,
                        }
                    })

                    for (let i = 0; i < final.length; i++) {

                        let query = final[i]
                        let primary_col_name = query['primary_col']

                        if (!excludedKeys.includes(primary_col_name)) {
                            finalTableColumns.push(primary_col_name)
                        }
                    }
                } else {
                    for (let i = 0; i < final.length; i++) {

                        var query = final[i]
                        var primary_col_name = query['primary_col']

                        if (!excludedKeys.includes(primary_col_name)) {
                            finalTableColumns.push(primary_col_name)
                        }

                        var secondary_col_name = null

                        if (allFunds?.length !== 0) {

                            if (query["is_advanced"] === 1) {
                                var filFunds = await AdminServicesPY.getAllScreenedFundsFromAdvQuery({ rules: [query], funds: JSON.stringify(allFunds?.map((i) => i.symbol)).replace(/null/g, NaN) }).then((r) => r)
                                filFunds = filFunds?.data?.length > 0 ? filFunds?.data : []
                                allFunds = allFunds?.filter((fund) => filFunds.includes(fund?.symbol))
                                console.log(allFunds)
                            } else if (primary_col_name == "investing_style") {
                                if (query['abs_val_1'] !== 'all') {
                                    var filt = query['abs_val_1']?.split(',')
                                    var filterString = []
                                    filt?.map((filter) => {
                                        console.log("allalal", filter)
                                        filterString?.push(query['oper'] == 'isin_comp' ? `fund["${filter}"] == 1` : `fund["${filter}"] != 1`)

                                        return ""
                                    })
                                    allFunds = allFunds.filter((fund) =>
                                        eval(filterString?.join(query['oper'] == 'isin_comp' ? " || " : " && ")))
                                }

                            } else {
                                allFunds = allFunds.filter((fund) =>
                                    eval(`fund[primary_col_name] !== null`))

                                if (query['oper'] == 'abs_comp') {
                                    var op = query['comp_operator']
                                    var num = parseFloat(query['abs_val_1'])

                                    if (op == 'gt')
                                        allFunds = allFunds.filter((fund) =>
                                            eval(`fund[primary_col_name] > ${num} `))
                                    if (op == 'lt')
                                        allFunds = allFunds.filter((fund) =>
                                            eval(`fund[primary_col_name] < ${num} `))
                                    if (op == 'eq')
                                        allFunds = allFunds.filter((fund) =>
                                            eval(`fund[primary_col_name] == ${num} `))
                                    ////console.log(allFunds.length)
                                    if (op == 'lteq')
                                        allFunds = allFunds.filter((fund) =>
                                            eval(`fund[primary_col_name] <= ${num} `))
                                    if (op == 'gteq')
                                        allFunds = allFunds.filter((fund) =>
                                            eval(`fund[primary_col_name] >= ${num} `))
                                }

                                if (query['oper'] == 'isin_comp' && query['abs_val_1'] !== undefined && query['abs_val_1']?.length !== 0) {
                                    var str = []
                                    query['abs_val_1'].split(",").map((fItem) => {
                                        str.push(`String(fund['${String(primary_col_name)}']) === '${String(fItem)}'`)
                                    })
                                    allFunds = allFunds.filter((fund) => eval(str.join(" || ")))
                                }

                                if (query['oper'] == 'isnotin_comp' && query['abs_val_1'] !== undefined && query['abs_val_1']?.length !== 0) {
                                    var str = []
                                    query['abs_val_1'].split(",").map((fItem) => {
                                        str.push(`String(fund['${String(primary_col_name)}']) !== '${String(fItem)}'`)
                                    })
                                    allFunds = allFunds.filter((fund) => eval(str.join(" && ")))
                                }

                                if (query['oper'] == 'rank_comp') {
                                    var op = query['comp_operator']
                                    var [asc_flag, cutoff, pct_flag] = op.split("_")

                                    if (asc_flag == 'rng') {
                                        var [ct_low, ct_high] = cutoff.split("-")

                                        if (pct_flag === "per") {
                                            //per
                                            var list = allFunds.map(function (d) {
                                                return d[primary_col_name]
                                            })

                                            if (list.length > 0) {
                                                var val_low = quantile(list, parseFloat(ct_low) / 100)
                                                var val_high = quantile(list, parseFloat(ct_high) / 100)
                                                allFunds = allFunds.filter((fund, index) => eval(`Number(fund[primary_col_name]) >= ${parseFloat(val_low)} && Number(fund[primary_col_name]) >= ${parseFloat(val_high)} `))
                                            }
                                        }

                                    } else if (asc_flag == 'top') {
                                        if (asc_flag !== undefined) {
                                            allFunds = sortFunds(allFunds, primary_col_name, asc_flag)
                                        }

                                        if (pct_flag === "per") {
                                            //per
                                            var list = allFunds.map(function (d) {
                                                return d[primary_col_name]
                                            })

                                            if (list.length > 0) {
                                                var val = quantile(list, parseFloat(cutoff) / 100)
                                                allFunds = allFunds.filter((fund, index) => eval(`Number(fund[primary_col_name]) >= ${parseFloat(val)} `))
                                            }
                                        } else {
                                            //abs
                                            allFunds = allFunds.splice(0, parseInt(cutoff))
                                        }


                                    } else {
                                        if (asc_flag !== undefined) {
                                            allFunds = sortFunds(allFunds, primary_col_name, asc_flag)
                                        }

                                        if (pct_flag === "per") {
                                            //per
                                            var list = allFunds.map(function (d) {
                                                return d[primary_col_name]
                                            })

                                            if (list.length > 0) {
                                                var val = quantile(list, parseFloat(cutoff) / 100)
                                                allFunds = allFunds.filter((fund, index) => eval(`Number(fund[primary_col_name]) <= ${parseFloat(val)} `))
                                            }
                                        } else {
                                            //abs
                                            allFunds = allFunds.splice(0, parseInt(cutoff))
                                        }
                                    }



                                }

                                if (query['oper'] == 'col_comp') {
                                    secondary_col_name = query['sec_col']
                                    var adjustment_operator = query['adj_operator']

                                    const finalCalc = (num) => {
                                        var op = query['comp_operator']
                                        if (op == 'gt')
                                            allFunds = allFunds.filter((fund) =>
                                                eval(`fund[primary_col_name] > ${num} && fund[primary_col_name] !== null`))
                                        if (op == 'lt')
                                            allFunds = allFunds.filter((fund) =>
                                                eval(`fund[primary_col_name] < ${num} && fund[primary_col_name] !== null`))
                                        if (op == 'eq')
                                            allFunds = allFunds.filter((fund) =>
                                                eval(`fund[primary_col_name] == ${num} && fund[primary_col_name] !== null`))
                                        if (op == 'lteq')
                                            allFunds = allFunds.filter((fund) =>
                                                eval(`fund[primary_col_name] <= ${num} && fund[primary_col_name] !== null`))
                                        if (op == 'gteq')
                                            allFunds = allFunds.filter((fund) =>
                                                eval(`fund[primary_col_name] >= ${num} && fund[primary_col_name] !== null`))
                                    }

                                    if (adjustment_operator !== null) {
                                        var [ad_operator, ad_val] = adjustment_operator.split("_")
                                        var num = null
                                        if (ad_operator == 'add')
                                            finalCalc(`fund[secondary_col_name] + ${parseFloat(ad_val)} `)
                                        if (ad_operator == 'sub')
                                            finalCalc(`fund[secondary_col_name] - ${parseFloat(ad_val)} `)
                                        if (ad_operator == 'mult')
                                            finalCalc(`fund[secondary_col_name] * ${parseFloat(ad_val)} `)
                                        if (ad_operator == 'div')
                                            finalCalc(`fund[secondary_col_name] / ${parseFloat(ad_val)} `)
                                    }
                                }
                            }
                        }

                    }
                }
                setScreenedCols(finalTableColumns);
                setScreenedData({ instruments: allFunds });
            }
        }
        catch (err) {
            console.log(err);
        }
        setTimeout(() => {
            setLoadingFunds(false);
        }, 200)
    }

    React.useEffect(() => {
        setLoading(true);
        loadData();
    }, []);

    React.useEffect(() => {
        if (loading === false && Object.keys(selectedScreen || {})?.length > 0) {
            loadScreenFunds(selectedScreen);
        }
    }, [selectedScreen, loading])

    return (
        <React.Fragment>
            <Card>
                <div className='d-flex justify-content-between align-items-end margin20Bottom' style={{ gap: "1rem", flexWrap: "wrap", flexDirection: isMobile ? "column" : "row" }}>
                    <div style={{ flex: 1 }}>
                        <CardHeader heading={widgetItem?.Name} headingType={2} />
                        <div className='customTabsSubHeading' style={{ marginBottom: "0" }}>{widgetItem?.Description}</div>
                    </div>
                    {loading ? <></> : <AccessComponent component={<Select value={selectedScreen?.isSuper ? 's' + selectedScreen?.screen_id : 'c' + selectedScreen?.checklist_id}
                        placeholder='Select a screen' style={{ width: isMobile ? "100%" : "12rem" }} onChange={(e) => {
                            let item = allScreens?.find((ele) => (ele?.isSuper ? 's' + ele?.screen_id : 'c' + ele?.checklist_id) === e);
                            setSelectedScreen(item);
                        }} options={allScreens?.map((item) => {
                            return {
                                // key: item?.isSuper ? item?.screen_id : item?.checklist_id,
                                value: item?.isSuper ? 's' + item?.screen_id : 'c' + item?.checklist_id,
                                label: item?.isSuper ? item?.screen_name : item?.checklist_name
                            }
                        })} />} upgradeComponent={null} />}
                </div>
                {loading ? <div>
                    <ActivityLoaderMintbox showQuote />
                </div> : allScreens?.length <= 0 ? <div style={{ margin: "0 0 1rem" }}>
                    <NoDataFound message={<div className='d-flex flex-column justify-content-center align-items-center' style={{ gap: "0.5rem" }}>
                        <div className='textSM dark3 my-2'>You have not created any screens yet. Start by creating one or cloning a community screen.</div>
                        <button className='btnWhite d-flex align-items-center' onClick={() => {
                            navigate(`/screens`)
                        }} style={{ gap: "0.25rem", width: "fit-content" }}>
                            <BiPlusCircle />  Create
                        </button>
                    </div>} />
                </div> : <div>
                    <AccessComponent component={<>
                        {loadingFunds ? <div>
                            <ActivityLoaderMintbox showQuote />
                        </div> : <InstrumentListTable
                            defaultInsType={selectedScreen?.type}
                            isCard={false}
                            hideCheckBox
                            finalList={screenedData?.instruments || []}
                            tableType={selectedScreen?.isSuper ? 11 : 1}
                            hideSettings={selectedScreen?.isSuper === true}
                            showToolsBtnsInTab downloadTable showHeatmapBtn showBubbleChartBtn={selectedScreen?.type === 4}
                            hideInvestBtn={true}
                            initialColumns={screenedCols || []}
                            tableHeader={selectedScreen?.isSuper ? <span style={{ display: "flex", flexDirection: "column", }}>
                                <span>{screenedData?.instruments?.length} {currScreenType.dname} qualify these filters</span>
                                <span className="textXS w-500 textLight">{screenedData?.instruments?.length > 0 ? "as on " + formatDateWithTime(screenedData?.timestamp) : ""}</span>
                            </span> : `${screenedData?.instruments?.length} ${currScreenType?.dname} qualify your filters`}
                        />}
                    </>} isDefaultUpgrade={selectedScreen?.is_locked === 1} upgradeComponent={<div style={{ background: "var(--grey4)", border: "1px solid var(--grey3)", borderRadius: "0.5rem" }}>
                        <UpgradeToPro customHeading='Upgrade your plan!' moduleKey={screenTypeKey} customDesc={`This screen is locked. With your current plan ${currScreenAccess?.maxLimit === 0 ? `you cannot view any of your saved ${currScreenType?.dname} screens. To view your screens, upgrade your plan.` : `you can only view your first ${currScreenAccess?.maxLimit} saved ${currScreenType?.dname} screens. 
                            To view your other factor models, upgrade your plan.`}`} />
                    </div>} />
                </div>}
            </Card>
        </React.Fragment>
    )
}

export default MyScreens;