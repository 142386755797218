import AccessComponent from "Components/AccessComponent";
import { CurrencyFormat } from "Components/CurrencyFormat";
import { formatDate } from "Components/DateFormatter";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import { priceItems } from "Components/Screener/ScreenerUtils";
import { SubscribeIcon } from "Components/SubscribeModal";
import LabelRenderer from "Components/atoms/LabelRenderer";
import AdminServices from "Services/api/AdminServices";
import AdminServicesPY from "Services/api/AdminServicesPY";
import apiHelper, { decodeDataNET } from "Services/api/ApiHelper";
import apiHelperPY, { decodeData } from "Services/api/ApiHelperPY";
import { Dashboard_Portfolio } from "Services/api/FabricatedAPIS";
import { useLocation } from "react-router-dom";
import db from "./db";
import { fetchFundData, fetchFundDataWithoutFilter } from "./fetchFundData";
// Logs out user
export const handleSignout = async (props) => {
    await db.clear();
    props.history.push("/login")
};

// Functions to remove lodash
function keyBy(collection, iteratee) {
    return collection.reduce((result, current) => {
        const key = typeof iteratee === 'function' ? iteratee(current) : current[iteratee];
        result[key] = current;
        return result;
    }, {});
}
function mergeObjects(...objects) {
    // Check if there are objects to merge
    if (objects.length < 2) {
        throw new Error('At least two objects must be provided to merge.');
    }

    const merge = (target, source) => {
        // Iterate over all enumerable properties of the source object
        for (let key in source) {
            // Check if the property is an own property of the source object
            if (Object.prototype.hasOwnProperty.call(source, key)) {
                // Check if the property in the source object is an object itself
                if (typeof source[key] === 'object' && source[key] !== null) {
                    // If the property is also an object in the target object, merge recursively
                    if (typeof target[key] === 'object' && target[key] !== null) {
                        merge(target[key], source[key]);
                    } else {
                        // Otherwise, assign the entire object from the source to the target
                        target[key] = Array.isArray(source[key]) ? [] : {};
                        merge(target[key], source[key]);
                    }
                } else {
                    // If the property is not an object, assign it directly to the target
                    target[key] = source[key];
                }
            }
        }
    };

    // Create a new object to hold the merged values
    let merged = {};

    // Merge each object into the initial empty object
    objects.forEach(obj => {
        merge(merged, obj);
    });

    return merged;
}

export const mergeArrayOfObj = (arr1, arr2, key) => {
    const map1 = keyBy(arr1, key);
    const map2 = keyBy(arr2, key);

    const mergedMap = mergeObjects({}, map1, map2);

    return Object.values(mergedMap);
}

export const imageExists = (image_url) => {

    var http = new XMLHttpRequest();

    http.open('HEAD', image_url, false);
    http.send();

    return http.status != 404;

}
export const stockType = { "key": "4", "name": "Stocks", "label": "Stocks", "dname": "Stocks", basePath: "/stocks", value: 4, strVal: 'stock', omkar: 'Stock' }
export const etfType = { "key": "1", "name": "Exchange traded funds", "label": "Exchange traded funds", "dname": "ETFs", basePath: "/etfs", value: 1, strVal: 'etf', omkar: 'ETF' }
export const mfType = { "key": "2", "name": "Mutual Funds", "label": "Mutual Funds", "dname": "Mutual Funds", basePath: "/mutual-funds", value: 2, strVal: 'mf', omkar: 'Mutual Fund' }
export const basketType = { "key": "3", "name": "Baskets", "label": "Baskets", "dname": "Baskets", basePath: "/baskets", value: 3, strVal: 'baskets', omkar: 'Baskets' }
export const benchmarkType = { "key": "11", "name": "Benchmark", "label": "Benchmark", "dname": "Benchmark", basePath: "/benchmark", value: 11, strVal: 'benchmarks', omkar: 'Benchmarks' }
export const indexType = { "key": "5", "name": "Index", "label": "Index", "dname": "Index", basePath: "/indices", value: 5, strVal: 'indices', omkar: 'Index' }

// for asset dropdown in header
export const useGetAssetType = (defaultType) => {
    var a = useLocation().pathname;
    var propData = [
        { "key": "1", "name": "Exchange traded funds", "dname": "ETFs", basePath: "/etfs", value: 1, strVal: 'etf', omkar: 'ETF' },
        { "key": "2", "name": "Mutual Funds", "dname": "Mutual Funds", basePath: "/mutual-funds", value: 2, strVal: 'mf', omkar: 'Mutual Fund' },
        { "key": "4", "name": "Stocks", "dname": "Stocks", basePath: "/stocks", value: 4, strVal: 'stock', omkar: 'Stock' },
        { "key": "3", "name": "Baskets", "label": "Baskets", "dname": "Baskets", basePath: "/baskets", value: 3, strVal: 'baskets', omkar: 'Baskets' },
        { "key": "5", "name": "Index", "label": "Index", "dname": "Index", basePath: "/indices", value: 5, strVal: 'indices', omkar: 'Index' }
    ]
    let type = {}
    if (defaultType) {
        type = propData?.find((ele) => ele?.value === defaultType) || {};
    }
    else {
        type = a.startsWith('/etfs') ? propData[0] : a.startsWith('/mutual-funds') ? propData[1] : a.startsWith('/stocks') ? propData[2] : a.startsWith('/indices') ? propData[4] : [];
    }
    return type
}

export const getUser = async (isFresh = false) => {
    return await db.get(db.model.user).then(async (user) => {
        if (user) {
            var a = await Dashboard_Portfolio.getClientData(user?.userData, isFresh).then((res) => {
                return res
            })
            return {
                ...user?.userData,
                ...a
            }
        } else {
            return null
        }
    })
}

export const getStockPrevPrices = async ({ isFresh = false }) => {
    let res = await apiHelper({ apiName: 'getStockPrevPrices', saveResponse: 'localStorage', data: true, getFresh: isFresh })
    return res
}


export function groupBy(arr, property) {
    return arr.reduce(function (memo, x) {
        if (!memo[x[property]]) { memo[x[property]] = []; }
        memo[x[property]].push(x);
        return memo;
    }, {});
}


export const sortFunds = (arr, key, order = "top", moveOneFundToTop = false, fund, type) => {
    var arrr = [...arr]?.filter((i) => (i[key] !== null))
    if (arrr?.length > 0) {
        if (order === "bot") {
            arrr = arrr.sort((a, b) => a[key] - b[key])
        } else {
            arrr = arrr.sort((a, b) => b[key] - a[key])
        }
        if (moveOneFundToTop && fund && type) {
            let k = type === 4 ? "symbol" : "plan_id"
            let id = arrr?.findIndex((el) => el?.[k] === fund?.[k]);
            if (id && fund?.[k]) {
                let [j] = arrr.splice(id, 1);
                arrr.unshift(j);
            }
        }
    }
    return arrr
}

export const quantile = (sorted, q) => {
    let pos = (sorted.length - 1) * q;
    if (pos % 1 === 0) {
        return sorted[pos];
    }
    pos = Math.floor(pos);
    if (sorted[pos + 1] !== undefined) {
        return (sorted[pos] + sorted[pos + 1]) / 2;
    }
    return sorted[pos];
};

export const inputBoxFormatter = ({ text, metricObj }) => {
    if (String(metricObj?.metric_data_type).toLowerCase() === "float") {
        if (String(metricObj?.metric_unit).toLowerCase() === "rupee") {
            return `₹ ${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        } else if (String(metricObj?.metric_unit).toLowerCase() === "rupee_cr") {
            return `₹ ${text} Cr`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        } else if (String(metricObj?.metric_unit).toLowerCase() === "percentage") {
            return `${text} %`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        } else {
            return text
        }
    } else {
        return text
    }
}

export const inputBoxParser = ({ text, metricObj }) => {
    if (String(metricObj?.metric_data_type).toLowerCase() === "float") {
        if (String(metricObj?.metric_unit).toLowerCase() === "rupee") {
            return text.replace(/\₹\s?|(,*)/g, '')
        } else if (String(metricObj?.metric_unit).toLowerCase() === "rupee_cr") {
            return text.replace(/\₹\s?|(,*)/g, '').replace("Cr", '')
        } else if (String(metricObj?.metric_unit).toLowerCase() === "percentage") {
            return text.replace(/\₹\s?|(,*)/g, '').replace("%", '')
        } else {
            return text
        }
    } else {
        return text
    }
}

export const displayScoreValue = ({ text, metricObj }) => {
    let color = '#FFD96699' //yellow
    if (text < 33.33) {
        color = '#FF6A5599'
    } else if (text > 66.66) {
        color = '#83BF6E99'
    }

    return <span className="textXS w-500" style={{ display: 'flex', fontSize: '0.65rem', alignItems: 'center', objectFit: 'cover', verticalAlign: 'middle', backgroundColor: color, color: "var(--dark4)", height: window.innerWidth <= 760 ? '2.6rem' : '2rem', width: window.innerWidth <= 760 ? '2.6rem' : '2rem', justifyContent: "center", borderRadius: '100%', alignSelf: "flex-end", float: "right" }}>
        {DecimalValueFormat({ num: text, decimals: text >= 100 ? 0 : 1, suffix: "", placeholder: "-" })}
    </span>
}

export const displayMetricValue = ({ text, metricObj }) => {
    if (metricObj?.needToRender && metricObj?.dataToRenderFrom && Array.isArray(metricObj?.dataToRenderFrom)) {
        return (metricObj?.dataToRenderFrom?.filter((e) => e?.[metricObj?.renderKey] === text)?.[0]?.[metricObj?.renderValue]) || "-"
    }
    else if (String(metricObj?.metric_data_type).toLowerCase() === "float") {
        if (String(metricObj?.metric_unit).toLowerCase() === "rupee") {
            return CurrencyFormat(text, 'long')
        } else if (String(metricObj?.metric_unit).toLowerCase() === "rupee_cr") {
            return CurrencyFormat(text, 'long', 2, ' Cr')
        } else if (String(metricObj?.metric_unit).toLowerCase() === "percentage") {
            return DecimalValueFormat({ num: text, suffix: "%", placeholder: "-" })
        } else {
            return DecimalValueFormat({ num: text, suffix: "", placeholder: "-" })
        }
    } else if (String(metricObj?.metric_data_type).toLowerCase() === 'date') {
        return formatDate(text)
    } else if (String(metricObj?.metric_data_type).toLowerCase() === 'boolean') {
        // //console.log("akakka", metricObj, metricObj?.metric_col_code)
        if (metricObj?.metric_col_code === "sip") {
            return Number(text) === 1 ? "Allowed" : "Not Allowed"
        } else {
            return Number(text) === 1 ? "True" : "False"
        }
    } else {
        return text || "-"
    }
}

export const displayValue = ({ text, metricObj }) => {
    if (String(metricObj?.metric_data_type).toLowerCase() === "float") {
        if (String(metricObj?.metric_unit).toLowerCase() === "rupee") {
            return CurrencyFormat(text, 'long')
        } else if (String(metricObj?.metric_unit).toLowerCase() === "rupee_cr") {
            return CurrencyFormat(text, 'long', 2, ' Cr')
        } else if (String(metricObj?.metric_unit).toLowerCase() === "percentage") {
            return DecimalValueFormat({ num: text, suffix: "%", placeholder: "-" })
        } else {
            return DecimalValueFormat({ num: text, suffix: "", placeholder: "-" })
        }
    } else if (String(metricObj?.metric_data_type).toLowerCase() === 'date') {
        return formatDate(text)
    } else if (String(metricObj?.metric_data_type).toLowerCase() === 'boolean') {
        // //console.log("akakka", metricObj, metricObj?.metric_col_code)
        if (metricObj?.metric_col_code === "sip") {
            return Number(text) === 1 ? "Allowed" : "Not Allowed"
        } else {
            return Number(text) === 1 ? "True" : "False"
        }
    } else {
        return text || "-"
    }
    // return (
    //     String(metricObj?.metric_data_type).toLowerCase() === "float"
    //         ?
    //         String(metricObj?.metric_unit).toLowerCase() === "rupee"
    //             ?
    //             CurrencyFormat(Number(text), 'long', 2)
    //             :
    //             String(metricObj?.metric_unit).toLowerCase() === "rupee_cr"
    //                 ?
    //                 CurrencyFormat(Number(text), 'long', 2) + ' Cr'
    //                 :
    //                 String(metricObj?.metric_unit).toLowerCase() === "percentage"
    //                     ?
    //                     <RedGreenText text={DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-", decimals: 1 })} value={Number(text)} />
    //                     : "ADSFSDF" + DecimalValueFormat({ num: Number(text), suffix: "", placeholder: "-", decimals: 1 })
    //         : String(metricObj?.metric_data_type).toLowerCase() === 'date'
    //             ?
    //             formatDate(Number(text))
    //             : text
    // )
}


export const makeSubstringBold = (fullString, substring) => {
    // Escape special characters in the substring to avoid regex issues
    if (substring?.trim()?.length > 0) {
        const escapedSubstring = substring.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        // Create a regex pattern to match the substring
        const regexPattern = new RegExp(`(${escapedSubstring})`, 'gi');

        // Replace the substring with <b> tags
        const htmlString = fullString.replace(regexPattern, '<b>$1</b>');

        // Return the HTML string
        return htmlString;
    } else {
        return fullString
    }
}

export const risk_box_codes = [{
    "value": 1,
    "label": "Super conservative",
    "key": 1,
}, {
    "value": 2,
    "label": "Conservative",
    "key": 2,
}, {
    "value": 3,
    "label": "Moderate",
    "key": 3,
}, {
    "value": 4,
    "label": "Aggressive",
    "key": 4,
}, {
    "value": 5,
    "label": "Super aggressive",
    "key": 5,
}, {
    "value": 6,
    "label": "Very aggressive",
    "key": 6,
}]


export const stock_universe = [{
    "value": "all",
    "label": "All stocks",
    "key": "all",
}, {
    "value": "is_nifty_50",
    "label": "Nifty 50",
    "key": "is_nifty_50",
}, {
    "value": "is_nifty_100",
    "label": "Nifty 100",
    "key": "is_nifty_100",
}, {
    "value": "is_nifty_500",
    "label": "Nifty 500",
    "key": "is_nifty_500",
}, {
    "value": "is_nifty_midcap100",
    "label": "Nifty Midcap 100",
    "key": "is_nifty_midcap100",
}, {
    "value": "is_nifty_smallcap100",
    "label": "Nifty Smallcap 100",
    "key": "is_nifty_smallcap100",
}]


export const exchanges = [{
    "value": "All",
    "label": "All",
    "key": "All",
}, {
    "value": "NSE-EQ",
    "label": "NSE Equity",
    "key": "NSE-EQ",
}, {
    "value": "BSE-EQ",
    "label": "BSE Equity",
    "key": "BSE-EQ",
}, {
    "value": "NSE-SM",
    "label": "NSE SME",
    "key": "NSE-SM",
}]

export const mf_etf_categories = [{
    "value": "Equity",
    "label": "Equity",
    "key": "Equity",
}, {
    "value": "Debt",
    "label": "Debt",
    "key": "Debt",
}, {
    "value": "Hybrid",
    "label": "Hybrid",
    "key": "Hybrid",
}, {
    "value": "Commodities",
    "label": "Commodities",
    "key": "Commodities",
}]

export const getValueFromArr = (arr, key, filter) => {
    return arr.filter((i) => i[filter[0]] === filter[1])?.[0]?.[key]
}

export const unique = (array, key) => [...new Map(array.map(item =>
    [item[key], item])).values()];

export const queries = [[
    {
        value: 'isOne',
        queryStr: 'isOne',
        label: 'includes',
    },
    {
        value: 'notOne',
        queryStr: 'notOne',
        label: 'excludes',
    }
], [
    {
        value: 'in',
        queryStr: 'in',
        label: 'is in',
    },
], [
    {
        value: 'gt',
        queryStr: 'gt',
        label: 'is greater than',
    },
    {
        value: 'gteq',
        queryStr: 'gteq',
        label: 'is greater than equal to',
    },
    {
        value: 'lt',
        queryStr: 'lt',
        label: 'is less than',
    },
    {
        value: 'lteq',
        queryStr: 'lteq',
        label: 'is less than equal to',
    },
    // {
    //     value: 'query7',
    //     label: 'is between',
    // }
], [
    {
        value: 'yes',
        queryStr: 'yes',
        label: 'yes',
    },
    {
        value: 'no',
        queryStr: 'no',
        label: 'no',
    }
], [
    {
        value: 'gt',
        queryStr: 'gt',
        label: 'after',
    },
    {
        value: 'lt',
        queryStr: 'lt',
        label: 'before',
    }
], [
    {
        value: 'avg',
        queryStr: 'avg',
        label: 'Average',
    },
    {
        value: 'med',
        queryStr: 'med',
        label: 'Median',
    }
], [
    {
        value: 'indgroup',
        queryStr: 'indgroup',
        label: 'Industry group',
    },
    {
        value: 'sec',
        queryStr: 'sec',
        label: 'Sector',
    },
    {
        value: 'ind',
        queryStr: 'ind',
        label: 'Industry',
    },
    {
        value: 'mkt',
        queryStr: 'mkt',
        label: 'Market',
    }
]],

    metric_level_1 = {
        'string': [
            //string
            {
                oper: 'isin_comp',
                comp_operator: '',
                value: 'val_1',
                label: 'includes',
            },
            {
                oper: 'isnotin_comp',
                comp_operator: '',
                value: 'val_2',
                label: 'excludes',
            }
        ],
        'float_1_1': [
            // float both per and abs
            {
                oper: 'rank_comp',
                comp_operator: '',
                value: 'val_1',
                label: 'is in',
            },
            {
                value: 'val_2',
                oper: 'abs_comp',
                comp_operator: 'gt',
                label: 'greater than',
            },
            {
                value: 'val_3',
                oper: 'abs_comp',
                comp_operator: 'gteq',
                label: 'greater than equal to',
            },
            {
                value: 'val_4',
                oper: 'abs_comp',
                comp_operator: 'lt',
                label: 'less than',
            },
            {
                value: 'val_5',
                oper: 'abs_comp',
                comp_operator: 'lteq',
                label: 'less than equal to',
            }
        ],
        'float_0_1': [
            // float only abs
            {
                value: 'val_2',
                oper: 'abs_comp',
                comp_operator: 'gt',
                label: 'greater than',
            },
            {
                value: 'val_3',
                oper: 'abs_comp',
                comp_operator: 'gteq',
                label: 'greater than equal to',
            },
            {
                value: 'val_4',
                oper: 'abs_comp',
                comp_operator: 'lt',
                label: 'less than',
            },
            {
                value: 'val_5',
                oper: 'abs_comp',
                comp_operator: 'lteq',
                label: 'less than equal to',
            }
        ],
        'float_1_0': [
            // float only per
            {
                oper: 'rank_comp',
                comp_operator: '',
                value: 'val_1',
                label: 'is in',
            }
        ],
        'boolean': [
            {
                value: 1,
                comp_operator: 'eq',
                oper: 'abs_comp',
                label: 'yes',
            },
            {
                value: 0,
                comp_operator: 'eq',
                oper: 'abs_comp',
                label: 'no',
            }
        ],
        'date': [
            {
                value: 'val_1',
                oper: 'abs_comp',
                comp_operator: 'gt',
                label: 'after',
            },
            {
                value: 'val_2',
                oper: 'abs_comp',
                comp_operator: 'lt',
                label: 'before',
            }
        ]
    },

    queries2 = [[
        //string
        {
            oper: 'isin_comp',
            comp_operator: '',
            value: 'isin_comp',
            label: 'includes',
        },
        {
            oper: 'isnotin_comp',
            comp_operator: '',
            value: 'isnotin_comp',
            label: 'excludes',
        }
    ], [
        //float with per
        {
            oper: 'rank_comp',
            comp_operator: '',
            value: 'rank_comp',
            label: 'is in',
        },
    ], [
        //float with abs
        {
            value: 'gt',
            oper: 'abs_comp',
            comp_operator: 'gt',
            label: 'greater than',
        },
        {
            value: 'gteq',
            oper: 'abs_comp',
            comp_operator: 'gteq',
            label: 'greater than equal to',
        },
        {
            value: 'lt',
            oper: 'abs_comp',
            comp_operator: 'lt',
            label: 'less than',
        },
        {
            value: 'lteq',
            oper: 'abs_comp',
            comp_operator: 'lteq',
            label: 'less than equal to',
        }
    ], [
        //boolean
        {
            value: 1,
            comp_operator: 'eq',
            oper: 'abs_comp',
            label: 'yes',
        },
        {
            value: 0,
            comp_operator: 'eq',
            oper: 'abs_comp',
            label: 'no',
        }
    ], [
        //date
        {
            value: 'gt',
            oper: 'abs_comp',
            comp_operator: 'gt',
            label: 'after',
        },
        {
            value: 'lt',
            oper: 'abs_comp',
            comp_operator: 'lt',
            label: 'before',
        }
    ], [
        {
            value: 'avg',
            comp_operator: '',
            oper: 'rel_comp',
            label: 'Average',
        },
        {
            value: 'med',
            comp_operator: '',
            oper: 'rel_comp',
            label: 'Median',
        }
    ], [
        {
            value: 'indgroup',
            comp_operator: '',
            oper: 'rel_comp',
            label: 'Industry group',
        },
        {
            value: 'sec',
            comp_operator: '',
            oper: 'rel_comp',
            label: 'Sector',
        },
        {
            value: 'ind',
            comp_operator: '',
            oper: 'rel_comp',
            label: 'Industry',
        }
    ]],

    perFilterValues = Array.from({ length: 20 }, (v, k) => (k + 1) * 5).map((i) => {
        return {
            value: String(i / 100),
            queryStr: String(i),
            label: String(i) + "%",
        }
    }),

    investingStylelist = [
        { "UniqueIDCheck": "Check_1", "label": "FoF", "CheckName": "Fund of funds", value: "is_fof", fundListKey: "is_fof", fundListValue: 1 },
        { "UniqueIDCheck": "Check_2", "label": "Index Funds", "CheckName": "Index funds", value: "is_index_fund", fundListKey: "is_index_fund", fundListValue: 1 },
        { "UniqueIDCheck": "Check_3", "label": "ELSS funds", "CheckName": "ELSS funds", value: "is_elss_fund", fundListKey: "is_elss_fund", fundListValue: "Equity: ELSS" },
        { "UniqueIDCheck": "Check_4", "label": "All", "CheckName": "All funds", value: "all", isAll: true }
    ],

    sortOptions = [
        {
            value: 'top',
            queryStr: 'top',
            label: 'Top',
        },
        {
            value: 'bot',
            queryStr: 'bot',
            label: 'Bottom',
        }
    ],

    // mf def cols
    mf_equityFreeCols = ["aum", 'expense_ratio', 'pe', 'pb', 'cagr_1y', 'cagr_3y', 'cagr_5y'],
    mf_equityPaidCols = ["aum", 'expense_ratio', 'pe', 'pb', 'cagr_1y', 'cagr_3y', 'cagr_5y'],

    mf_normalFreeCols = ["basic_name", "aum", 'expense_ratio', 'pe', 'pb', 'cagr_1y', 'cagr_3y', 'cagr_5y'],
    mf_normalPaidCols = ["basic_name", "aum", 'expense_ratio', 'pe', 'pb', 'cagr_1y', 'cagr_3y', 'cagr_5y'],

    mf_debtFreeCols = ["basic_name", "aum", 'expense_ratio', 'ytm', 'duration', 'maturity', 'credit_risk', 'ir_risk'],
    mf_debtPaidCols = ["basic_name", "aum", 'expense_ratio', 'ytm', 'duration', 'maturity', 'credit_risk', 'ir_risk'],

    mf_hybridFreeCols = ["basic_name", "aum", 'expense_ratio', 'pe', 'pb', 'ytm', 'duration', 'maturity'],
    mf_hybridPaidCols = ["basic_name", "aum", 'expense_ratio', 'pe', 'pb', 'ytm', 'duration', 'maturity'],

    mf_commodityFreeCols = ["basic_name", "aum", 'expense_ratio', 'benchmark', 'cagr_1y', 'cagr_3y', 'cagr_5y'],
    mf_commodityPaidCols = ["basic_name", "aum", 'expense_ratio', 'benchmark', 'cagr_1y', 'cagr_3y', 'cagr_5y'],


    // index def cols
    index_equityFreeCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', 'cagr_1y', 'cagr_3y', 'pe', 'pb'],
    index_equityPaidCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', 'cagr_1y', 'cagr_3y', 'pe', 'pb'],

    index_debtFreeCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', 'ytm', 'duration', 'maturity', 'credit_risk', 'ir_risk'],
    index_debtPaidCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', 'ytm', 'duration', 'maturity', 'credit_risk', 'ir_risk'],

    index_hybridFreeCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', 'pe', 'pb', 'ytm', 'duration', 'maturity'],
    index_hybridPaidCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', 'pe', 'pb', 'ytm', 'duration', 'maturity'],

    index_commodityFreeCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', 'benchmark', 'cagr_1y', 'cagr_3y', 'cagr_5y'],
    index_commodityPaidCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', 'benchmark', 'cagr_1y', 'cagr_3y', 'cagr_5y'],


    //etf def cols
    etf_equityFreeCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', '21d_vol', 'cagr_1y', 'cagr_3y', 'pe', 'pb'],
    etf_equityPaidCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', '21d_vol', 'cagr_1y', 'cagr_3y', 'pe', 'pb'],

    etf_normalFreeCols = ["aum", 'expense_ratio', 'tracking_error', 'td_1y', '21d_vol', 'cagr_1y', 'cagr_3y'],
    etf_normalPaidCols = ["aum", 'expense_ratio', 'tracking_error', 'td_1y', '21d_vol', 'cagr_1y', 'cagr_3y'],

    etf_debtFreeCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', '21d_vol', 'ytm', 'duration', 'maturity', 'credit_risk', 'ir_risk'],
    etf_debtPaidCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', '21d_vol', 'ytm', 'duration', 'maturity', 'credit_risk', 'ir_risk'],

    etf_hybridFreeCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', '21d_vol', 'pe', 'pb', 'ytm', 'duration', 'maturity'],
    etf_hybridPaidCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', '21d_vol', 'pe', 'pb', 'ytm', 'duration', 'maturity'],

    etf_commodityFreeCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', '21d_vol', 'benchmark', 'cagr_1y', 'cagr_3y', 'cagr_5y'],
    etf_commodityPaidCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', '21d_vol', 'benchmark', 'cagr_1y', 'cagr_3y', 'cagr_5y'],


    magicBoardCols = {
        "mf": {
            "Equity": {
                "free": mf_equityFreeCols,
                "paid": mf_equityPaidCols
            },
            "Debt": {
                "free": mf_debtFreeCols,
                "paid": mf_debtPaidCols
            },
            "Hybrid": {
                "free": mf_hybridFreeCols,
                "paid": mf_hybridPaidCols
            },
            "Commodities": {
                "free": mf_commodityFreeCols,
                "paid": mf_commodityPaidCols
            }
        },
        "index": {
            "Equity": {
                "free": index_equityFreeCols,
                "paid": index_equityPaidCols
            },
            "Debt": {
                "free": index_debtFreeCols,
                "paid": index_debtPaidCols
            },
            "Hybrid": {
                "free": index_hybridFreeCols,
                "paid": index_hybridPaidCols
            },
            "Commodities": {
                "free": index_commodityFreeCols,
                "paid": index_commodityPaidCols
            }
        },
        "etf": {
            "Equity": {
                "free": etf_equityFreeCols,
                "paid": etf_equityPaidCols
            },
            "Debt": {
                "free": etf_debtFreeCols,
                "paid": etf_debtPaidCols
            },
            "Hybrid": {
                "free": etf_hybridFreeCols,
                "paid": etf_hybridPaidCols
            },
            "Commodities": {
                "free": etf_commodityFreeCols,
                "paid": etf_commodityPaidCols
            }
        }
    },

    //stocks def cols
    stock_FreeCols = ["mcap", "style_box_code", "sh_val_comp", 'sh_mom_comp', 'sh_qual_comp', 'one_yr_pct', 'three_yr_pct', 'five_yr_pct', 'sh_3fac_comp',
        '21d_vol',],
    stock_PaidCols = ["mcap", "style_box_code", "sh_val_comp", 'sh_mom_comp', 'sh_qual_comp', 'one_yr_pct', 'three_yr_pct', 'five_yr_pct', 'sh_3fac_comp',
        '21d_vol',],
    stock_PaidCols_new = ["mcap", "sh_val_comp", 'sh_mom_comp', 'sh_qual_comp'],

    normalTableCols = {
        "mf": {
            "free": mf_equityFreeCols,
            "paid": mf_equityPaidCols
        },
        "etf": {
            "free": etf_normalFreeCols,
            "paid": etf_normalPaidCols
        },
        "stock": {
            "free": stock_FreeCols,
            "paid": stock_PaidCols
        }
    },


    hiddenCols = ["industry_code", "is_nifty_50", "is_nifty_100", "is_nifty_500", "is_nifty_midcap100", "is_nifty_smallcap100", "investing_style", "category_id", 'exit_load'],

    initialColumnsStocks = ["proper_name", "mcap", "style_box_code", "sh_3fac_comp", "sh_val_comp", 'sh_mom_comp', 'sh_qual_comp',],

    initialColumnsMFandETF = ["basic_name", "aum", 'expense_ratio', "cagr_1y", "cagr_3y", "cagr_5y"],

    stockCodes = ['sh_val_comp', 'sh_mom_comp', 'sh_qual_comp', 'sh_gr_comp', 'sh_v_qual_comp', 'sh_qual_mom_comp', 'sh_gr_mom_comp', 'sh_3fac_comp'],

    specialCaseScores = ["sh_val_comp", 'sh_mom_comp', 'sh_qual_comp', 'sh_3fac_comp']



export const makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export function generateUniqueKey(prefix = 'key', length = 1) {
    const timestamp = new Date().getTime();
    let pre = String(prefix)?.split(' ')?.join('_');
    let uniCharStr = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        uniCharStr += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return `${pre}_${timestamp}_${Math.random()}_${uniCharStr}`;
}

export const sortArrObject = (unordered) => {
    return Object.keys(unordered).sort().reduce(
        (obj, key) => {
            obj[key] = unordered[key];
            return obj;
        },
        {}
    );
}

export const median = (arr, key, order = "top") => {
    var nums = arr.filter((i) => i[key] !== null && i[key] !== NaN && i[key] !== undefined)

    if (nums.length > 0) {
        if (order === "bot") {
            nums = nums.sort((a, b) => a[key] - b[key])
        } else {
            nums = nums.sort((a, b) => b[key] - a[key])
        }
        nums = nums.sort((a, b) => a[key] - b[key])
        const mid = Math.floor(nums.length / 2)
        var a = nums.length % 2 !== 0 ? Number(nums[mid][key]) : (Number(nums[mid - 1][key]) + Number(nums[mid][key])) / 2;
        return Number(a.toFixed(2))
    }
    return null


};


export const setIsInitialCol = (user, item, freeCols, paidCols) => {
    if (user !== undefined && user !== null) {
        if (user?.Client[0]?.SharpelyAccessLevel > 0) {
            return paidCols.includes(item.metric_col_code)
        } else {
            return freeCols.includes(item.metric_col_code)
        }
    } else {
        return freeCols.includes(item.metric_col_code)
    }

}


export const screenerMetricData = async (type, isSignal = 0, isScreener = 0, noFilter = 0, isSuperScreener = 0) => {
    //console.log(type)
    var categories = []
    var amcs = []
    var macroSectors = []
    var sectors = []
    var industries = []
    var basicIndustries = []
    var stockStyles = []

    let a = []
    if (noFilter === 1) {
        a = await fetchFundDataWithoutFilter(type.value)
    } else {
        a = await fetchFundData(type.value)
    }

    //   const user = useSelector((state) => state?.updateUserData?.value)
    let user = await getUser(false)

    if (type.value === 1 || type.value === 2) {

        var catLevel1 = [...new Set(a.map((item, index) => item.primary_category_name))]
        var catLevel2 = [...new Set(a.map((i, index) => {
            return {
                "categoryName": i.primary_category_name,
                "subCategoryName": i.category_name,
                "categoryId": i.category_id
            }
        }))]
        catLevel2 = Array.from(new Set(catLevel2.map(JSON.stringify))).map(JSON.parse)

        categories = catLevel1.map((i) => {
            return {
                categoryName: i,
                subCategories: catLevel2.filter((ii) => ii.categoryName === i)
            }
        })
        amcs = [...new Set(a.map((item, index) => {
            return {
                "amc_full_name": item.amc_full_name,
                "key": item.amc_full_name.split(" ").join("_").toString().toLowerCase()
            }
        }))]
        amcs = Array.from(new Set(amcs.map(JSON.stringify))).map(JSON.parse)
        a = a.map(i => {
            if (i.category_id === 106) {
                return {
                    ...i,
                    is_elss_fund: 1
                }
            }
            return i
        })
    } else {
        // if (noFilter !== 1) {
        //     a = a.filter((i) => i.nse_active === 1)
        // }
        //sectorAndIndustries
        // basic_industry_code
        // basic_industry_desc
        // detailed_desc
        // industry_code
        // industry_desc
        // maco_sec_desc
        // macro_sec_code
        // sector_code
        // sector_desc
        var stockSectorCLF = await apiHelper({ apiName: 'getStockSectorCLF', saveResponse: 'sessionStorage' })

        stockStyles = await apiHelperPY({ apiName: 'getStockStyles', saveResponse: 'sessionStorage' })
        stockStyles = JSON.parse(stockStyles).map((item) => {
            return {
                ...item,
                "value": item.style_box_code,
                "label": item.style_box_name,
                "key": item.style_box_code,
            }
        })

        basicIndustries = [...new Set(stockSectorCLF.map(item => {
            return {
                "value": item.basic_industry_code,
                "label": item.basic_industry_desc?.replace(/\n/g, ' '),
                "key": item.basic_industry_code,
            }
        }))];
        basicIndustries = Array.from(new Set(basicIndustries.map(JSON.stringify))).map(JSON.parse)
        // basicIndustries = basicIndustries?.filter((item)=>a?.find((ele)=>ele?.nse_basic_ind_code===item?.value));

        industries = [...new Set(stockSectorCLF.map(item => {
            return {
                "value": item.industry_code,
                "label": item.industry_desc?.replace(/\n/g, ' '),
                "key": item.industry_code,
            }
        }))];

        industries = Array.from(new Set(industries.map(JSON.stringify))).map(JSON.parse);
        // industries = industries?.filter((item)=>a?.find((ele)=>ele?.industry_code===item?.value));

        // industri

        sectors = [...new Set(stockSectorCLF.map(item => {
            return {
                "value": item.sector_code,
                "label": item.sector_desc?.replace(/\n/g, ' '),
                "key": item.sector_code,
            }
        }))];
        sectors = Array.from(new Set(sectors.map(JSON.stringify))).map(JSON.parse)
        // sectors = sectors?.filter((item)=>a?.find((ele)=>ele?.sector_code===item?.value));

        macroSectors = [...new Set(stockSectorCLF.map(item => {
            return {
                value: item.macro_sec_code,
                label: item.maco_sec_desc?.replace(/\n/g, ' '),
                key: item.macro_sec_code,
            }
        }))];
        macroSectors = Array.from(new Set(macroSectors.map(JSON.stringify))).map(JSON.parse)


    }



    const listDataRender = (item, index, code) => {


        let auth_flag = Number(item?.auth_flag);
        auth_flag = isNaN(auth_flag) ? 0 : auth_flag;

        if (specialCaseScores.includes(item.metric_col_code)) {


            return {
                render: (text, record) => {
                    return <AccessComponent component={<span>
                        {displayScoreValue({ text, metricObj: item })}
                    </span>} reqAccessLevel={auth_flag} upgradeComponent={<SubscribeIcon navigateToPricing={true} />} />
                },
                hidden: hiddenCols.includes(item.metric_col_code),
                renderForTable: (text, record, userAccessLevel) => userAccessLevel < auth_flag ? <span></span> : <span >{displayScoreValue({ text, metricObj: item })}</span>,
            }
        }

        if (type.value === 4) {
            var checkAllData = item.metric_col_code === "macro_sec_code" ? macroSectors :
                item.metric_col_code === "sector_code" ? sectors :
                    item.metric_col_code === "industry_code" ? industries :
                        item.metric_col_code === "nse_basic_ind_code" ? basicIndustries :
                            item.metric_col_code === "risk_box_code" ? risk_box_codes : item.metric_col_code === "style_box_code" ? stockStyles : []

            if (checkAllData.length > 0) {
                return {
                    dataToRenderFrom: checkAllData,
                    renderKey: "value",
                    renderValue: "label",
                    needToRender: true,
                    render: (text, record) => <AccessComponent component={<span >{checkAllData.filter((i) => String(i.value) === String(text))[0]?.label || "-"}</span>}
                        reqAccessLevel={auth_flag} upgradeComponent={<SubscribeIcon navigateToPricing={true} />} />,
                    hidden: hiddenCols.includes(item.metric_col_code),
                    renderForTable: (text, record, userAccessLevel) => userAccessLevel < auth_flag ? <span></span> : <span >{checkAllData.filter((i) => String(i.value) === String(text))[0]?.label || "-"}</span>,
                    sorter: (a, b) => {
                        let c = checkAllData?.find((el) => el?.value === a?.[code])?.label;
                        let d = checkAllData?.find((el) => el?.value === b?.[code])?.label;
                        if (c < d) return 1;
                        else if (c > d) return -1;
                        return 0;
                    }
                }
            }

            if (stockCodes.includes(item.metric_col_code)) {
                return {
                    render: (text, record, index) => <AccessComponent component={<span style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "flex-end", filter: "none" }}>
                        {/* {+parseFloat(Number(text)).toFixed(2) !== 0 && <AiFillAlert color={Number(text) < 30 ? "var(--red)" : Number(text) > 70 ? "var(--green)" : "var(--primary)"} />} */}
                        <span>
                            {displayValue({ text, metricObj: item })}
                        </span>
                    </span>} reqAccessLevel={auth_flag} upgradeComponent={<SubscribeIcon navigateToPricing={true} />} />,
                    hidden: hiddenCols.includes(item.metric_col_code),
                    renderForTable: (text, record, userAccessLevel) => userAccessLevel < auth_flag ? <span></span> : <span >{displayValue({ text, metricObj: item })}</span>,
                }
            }
        }


        return {
            render: (text, record) => <AccessComponent component={<span >{displayValue({ text, metricObj: item })}</span>}
                reqAccessLevel={auth_flag} upgradeComponent={<SubscribeIcon navigateToPricing={true} />} />,
            hidden: hiddenCols.includes(item.metric_col_code),
            renderForTable: (text, record, userAccessLevel) => userAccessLevel < auth_flag ? <span></span> : <span >{displayValue({ text, metricObj: item })}</span>,
        }
    }


    //getScreenerCategory
    var screener_Category = await apiHelper({ apiName: 'getScreenerCategory', data: type.strVal, saveResponse: "sessionStorage" })

    screener_Category = decodeDataNET(screener_Category);
    //getScreenerSubCategory
    var screener_SubCategory = await apiHelper({ apiName: 'getScreenerSubCategory', data: type.strVal, saveResponse: "sessionStorage" })
    screener_SubCategory = decodeDataNET(screener_SubCategory);

    if (type.value === 4) {
        // for universe dropdown hide in modal in stocks
        screener_SubCategory = screener_SubCategory.filter((item) => item.sub_category_id !== 1)
    }

    //console.log(screener_Category, screener_SubCategory)
    //getScreenerMetric
    var screener_Metric = await apiHelper({ apiName: 'getScreenerMetric', data: type.strVal, saveResponse: "sessionStorage" })
    screener_Metric = decodeDataNET(screener_Metric);

    //getScreenerMetricDescription
    var screener_Metric_Desc = await apiHelperPY({ apiName: 'getMetricDescription', data: type.value, saveResponse: "sessionStorage" })
    screener_Metric_Desc = JSON.parse(decodeData(screener_Metric_Desc));
    // screener_Metric_Desc = JSON.parse(screener_Metric_Desc)


    if (isSignal === 1 || isScreener === 1) {
        screener_Metric = screener_Metric?.filter((i) => i?.show_as_metric !== 0)
    }

    var renderLabel = (item) => {

    }

    if (isSuperScreener === 1) {
        screener_Category = [...screener_Category]?.concat([
            {
                "category_id": screener_Category?.length + 1,
                "category_name": "Price and volume",
                "category_desc": "",
                "pos": screener_Category?.length + 1,
            }
        ])

        screener_SubCategory = [...screener_SubCategory]?.concat([
            {
                "sub_category_id": screener_SubCategory?.length + 1,
                "category_id": screener_Category?.length,
                "sub_category_name": "Price and volume metrics",
                "sub_category_desc": "",
                "gloassary_url": ""
            }
        ])

        screener_Metric = [...screener_Metric]?.concat(priceItems?.map((i) => {
            return {
                ...i,
                "dataIndex": i?.value,
                "key": i?.value,
                "metric_col_code": i?.value,
                "metric_unit": "",
                "metric_data_type": "float",
                "metric_name": i?.label,
                "title": i?.label,
                "is_technical": 1,
                "category_id": screener_Category?.length,
                "sub_category_id": screener_SubCategory?.length,
                "type": "Metric T",
                "frequency": "D"
            }
        }))
    }
    //console.log(screener_Metric)
    screener_Metric = screener_Metric?.map((item, index) => {

        var code = item.metric_col_code

        var floatType = item.metric_data_type === "float" && item.show_percentile === 1 && item.show_absolute === 1 ? "top" :
            item.metric_data_type === "float" && item.show_percentile === 1 ? "bot" : null

        var queriesFinal = item.metric_data_type === "string" ? queries[0] : item.metric_data_type === "boolean" ? queries[3] : item.metric_data_type === "Date" ? queries[4] :
            floatType === "top" ? queries[1]?.concat(queries[2]) :
                floatType === "bot" ? queries[1] :
                    floatType === null ? queries[2] : queries[0]

        if (item?.metric_col_code === "sip") {
            //console.log(item?.metric_col_code, item)
        }
        return {
            ...item,
            direction_flag: item.direction_flag === 1 ? "top" : item.direction_flag === -1 ? "bot" : item.direction_flag,
            title: item?.metric_name,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (<></>),
            filterIcon: <LabelRenderer item={item} screener_Metric_Desc={screener_Metric_Desc} />,
            // metric_name: screener_Metric_Desc?.filter((i) => i.desc_id === item.desc_id)[0]?.name || item?.metric_name,
            // label: screener_Metric_Desc?.filter((i) => i.desc_id === item.desc_id)[0]?.name || item?.metric_name,
            metric_name: item?.metric_name,
            label: item?.metric_name,
            dataIndex: item.metric_col_code,
            key: item.metric_col_code,
            value: item.metric_col_code,
            isPro: true,
            isInitial: type.value === 1 || type.value === 2 ? setIsInitialCol(user, item, initialColumnsMFandETF, initialColumnsMFandETF) : setIsInitialCol(user, item, stock_FreeCols, stock_PaidCols),
            metric_desc: <span id="metric_desc" className='ql-editor textSM' style={{ lineHeight: "1.125rem !important" }} dangerouslySetInnerHTML={{ __html: String(screener_Metric_Desc?.filter((i) => i.desc_id === item.desc_id)[0]?.description)?.replaceAll('\"color: black;\"', '')?.replaceAll(".html", "") }}></span>,
            align: item.metric_data_type === "float" ? "right" : 'right',//all right
            sorter: item.metric_data_type === 'Date' ? (a, b) => new Date(a[code]) - new Date(b[code]) : (a, b) => a[code] - b[code],
            width: 140,
            metricDefVal: item.metric_data_type === "float" ? Number(median(a, item.metric_col_code, item.direction_flag === 1 ? "top" : "bot")) : item.metric_data_type === "boolean" ? 'yes' : null,
            pervalue: floatType === "top" || floatType === "bot" ? perFilterValues[0]?.value : null,
            queryValue: queriesFinal[0]?.value,

            "is_advanced": 0,
            "oper": null,
            "sec_col": null,
            "adj_operator": 'mult_1',
            "sec_col_table": null,
            "abs_val_1": null,
            "abs_val_2": null,
            "comp_operator": 'gt',
            "rel_comp_stat": null,
            "rel_comp_name": null,
            "type": item?.is_technical === 1 ? "Metric T" : "Metric F",
            "hideInCustomizeTable": item?.in_table === "code",


            ...listDataRender(item, index, code)
        }
    })

    //console.log(screener_Metric)
    var ind1 = -1
    var ind2 = -1
    var ind3 = -1
    var m_category_id = ""
    var m_sub_category_id = ""
    var m_metric_col_code = ""
    var finalData = screener_Category.map((sc_category, ind_1) => {
        var screenerSubCat = screener_SubCategory.filter((su) => su.category_id === sc_category.category_id).map((sc_sub_category, ind_2) => {
            var metrics = screener_Metric.filter((me) => me.category_id === sc_category.category_id && me.sub_category_id === sc_sub_category.sub_category_id)
            if (isSignal === 1) {
                metrics = metrics.filter((me) => me.is_signal === 1)
            }
            if (type.value === 1) {
                //etf
                metrics = metrics.filter((item) => item.is_etf_metric === 1)
            }

            if (type.value === 2) {
                //etf
                metrics = metrics.filter((item) => item.is_mf_metric === 1)
            }




            for (var i = 0; i < metrics.length; i++) {
                if (ind1 === -1 && ind2 === -1 && ind3 === -1) {
                    if (!hiddenCols.includes(metrics[i]?.metric_col_code)) {
                        ind1 = ind_1
                        ind2 = ind_2
                        ind3 = i
                        m_category_id = sc_category.category_id
                        m_sub_category_id = sc_sub_category.sub_category_id
                        m_metric_col_code = metrics[i]?.metric_col_code
                        break;
                    }

                }
            }

            // //////console.log(metrics)

            return {
                ...sc_sub_category,
                key: "category_" + sc_category.category_id + "_subCategory_" + sc_sub_category.sub_category_id,
                isVisibleForCustomise: metrics.filter((i) => i.hidden !== true).length !== 0,
                isVisibleNormally: metrics.length !== 0,
                metric: metrics
            }
        })
        return {
            ...sc_category,
            key: "category_" + sc_category.category_id,
            sub_category: screenerSubCat,
            isVisibleForCustomise: screenerSubCat.filter((i) => i.isVisibleForCustomise === true).length !== 0,
            isVisibleNormally: screenerSubCat.filter((i) => i.isVisibleNormally === true).length !== 0,
        }
    })?.sort((a, b) => a?.pos - b?.pos)



    return {
        screenerMetricData: screener_Metric,
        screenerMetricDescData: screener_Metric_Desc,
        screenerFinalData: finalData,
        allFunds: a,
        levelVals: {
            level1: ind1,
            level2: ind2,
            level3: ind3,
            m_category_id: m_category_id,
            m_sub_category_id: m_sub_category_id,
            m_metric_col_code: m_metric_col_code
        },
        fabricatedData: {
            //stocks
            macroSectors: macroSectors,
            sectors: sectors,
            industries: industries,
            basicIndustries: basicIndustries,
            stockSectorCLF: stockSectorCLF,
            stockStyles: stockStyles,
            //mf and etfs
            mf_categories: categories,
            mf_amcs: amcs
        }
    }

}

export const getStockSectorCLF = async () => {
    var stockSectorCLF = await apiHelper({ apiName: 'getStockSectorCLF', saveResponse: 'sessionStorage' })

    let industries = [...new Set(stockSectorCLF.map(item => {
        return {
            "value": item.industry_code,
            "label": item.industry_desc?.replace(/\n/g, ' '),
            "key": item.industry_code,
        }
    }))];

    industries = Array.from(new Set(industries.map(JSON.stringify))).map(JSON.parse);

    let basicIndustries = [...new Set(stockSectorCLF.map(item => {
        return {
            "value": item.basic_industry_code,
            "label": item.basic_industry_desc?.replace(/\n/g, ' '),
            "key": item.basic_industry_code,
        }
    }))];
    basicIndustries = Array.from(new Set(basicIndustries.map(JSON.stringify))).map(JSON.parse)

    let sectors = [...new Set(stockSectorCLF.map(item => {
        return {
            "value": item.sector_code,
            "label": item.sector_desc?.replace(/\n/g, ' '),
            "key": item.sector_code,
        }
    }))];
    sectors = Array.from(new Set(sectors.map(JSON.stringify))).map(JSON.parse)

    let macroSectors = [...new Set(stockSectorCLF.map(item => {
        return {
            value: item.macro_sec_code,
            label: item.maco_sec_desc?.replace(/\n/g, ' '),
            key: item.macro_sec_code,
        }
    }))];
    macroSectors = Array.from(new Set(macroSectors.map(JSON.stringify))).map(JSON.parse)
    return { sectors, macroSectors, basicIndustries, industries }
}

export const getUserScreeners = async (id, type) => {
    try {
        let res = await apiHelper({ apiName: "getUserScreeners", saveResponse: "sessionStorage", data: { id: id, type: type }, }) || [];

        if (res.length > 0) {
            var n = [...new Set([...res].map((item) => {
                return {
                    "checklist_id": item.checklist_id,
                    "client_id": item.client_id,
                    "show_as_screen": item.show_as_screen,
                    "show_as_checklist": item.show_as_checklist,
                    "checklist_name": item.checklist_name,
                    "checklist_desc": item.checklist_desc,
                    "is_deleted": item.is_deleted,
                    "created_on": item.created_on,
                }
            }))]
            n = Array.from(new Set(n.map(JSON.stringify))).map(JSON.parse)
            n = n.map((screen) => {
                return {
                    ...screen,
                    rules: res?.filter((i) => i?.checklist_id === screen?.checklist_id)
                }
            })
            return n;
        }
        else {
            return []
        }
    }
    catch (err) {
        return [];
    }
}

export const getTotalScreenersCount = async (id) => {
    try {
        let res1 = await apiHelper({ apiName: "getUserScreeners", saveResponse: "sessionStorage", data: { id: id, type: 'stock' }, }) || [];
        let res2 = await apiHelper({ apiName: "getUserScreeners", saveResponse: "sessionStorage", data: { id: id, type: 'mf' }, }) || [];
        let res3 = await apiHelper({ apiName: "getUserScreeners", saveResponse: "sessionStorage", data: { id: id, type: 'etf' }, }) || [];

        let res = [...res1, ...res2, ...res3]

        if (res.length > 0) {
            var n = [...new Set([...res].map((item) => {
                return {
                    "checklist_id": item.checklist_id,
                    "client_id": item.client_id,
                    "show_as_screen": item.show_as_screen,
                    "show_as_checklist": item.show_as_checklist,
                    "checklist_name": item.checklist_name,
                    "checklist_desc": item.checklist_desc,
                    "is_deleted": item.is_deleted,
                    "created_on": item.created_on,
                }
            }))]
            n = Array.from(new Set(n.map(JSON.stringify))).map(JSON.parse)
            n = n.map((screen) => {
                return {
                    ...screen,
                    rules: res?.filter((i) => i?.checklist_id === screen?.checklist_id)
                }
            })
            return n.length
        }
        else {
            return 0
        }
    }
    catch (err) {
        return Infinity;
    }
}

export const getScreenById = async ({ screenType, userId, screenId, type }) => {
    // screenType = 2 for sharpely screens, 1 for guru screens  and 3 for user screens
    let rules = [];
    if (screenType === 2) {
        rules = await apiHelper({ apiName: "getSharpelyScreens", saveResponse: "sessionStorage" });
    }
    else if (screenType === 1) {
        rules = await apiHelper({ apiName: "getGuruScreens", data: type.omkar.toUpperCase(), saveResponse: "sessionStorage" })
    }
    else {
        rules = await apiHelper({ apiName: "getUserScreeners", saveResponse: "sessionStorage", data: { id: userId, type: type.strVal } })
    }
    //console.log(rules);
    rules = rules?.filter((el) => el?.checklist_id === screenId) || [];
    if (rules?.length > 0) {
        let screen = {
            "checklist_id": rules?.[0]?.checklist_id,
            "client_id": rules?.[0]?.client_id,
            "show_as_screen": rules?.[0]?.show_as_screen,
            "show_as_checklist": rules?.[0]?.show_as_checklist,
            "checklist_name": rules?.[0]?.checklist_name,
            "checklist_desc": rules?.[0]?.checklist_desc,
            "is_deleted": rules?.[0]?.is_deleted,
            "created_on": rules?.[0]?.created_on,
            "is_sharpely_screen": rules?.[0]?.is_sharpely_screen || 0,
            "rules": rules
        }
        return screen;
    }
}

export function getSnapshotInitialMetrics(fundData = {}, type = {}) {
    let metricColCodes = [];
    if (type?.value === 4) {
        if (fundData?.gen_ind_code === 4) {
            metricColCodes = ['mcap', 'pe_ttm', 'pb', 'net_int_margin_ttm', 'div_yield', 'gross_npa_per',
                'net_npa_per', 'sales_3y_cagr', 'sales_5y_cagr', 'roe_ttm', 'roa_ttm', 'avg_3_roe', 'avg_3_roa', 'earnings_3y_cagr',
                'consensus_tp', 'strong_buy_per', 'promoter_holding', 'promoter_holding_pledged']
        }
        else if ([5, 6].includes(fundData?.gen_ind_code)) {
            metricColCodes = ['mcap', 'pe_ttm', 'pb', 'div_yield',
                'ocf_yield', 'cape_3', 'operating_mgn_ttm', 'sales_3y_cagr', 'sales_5y_cagr', 'roe_ttm', 'roa_ttm', 'avg_3_roe',
                'avg_3_roa', 'earnings_3y_cagr', 'consensus_tp', 'strong_buy_per',
                'promoter_holding', 'promoter_holding_pledged']
        }
        else {
            metricColCodes = ['mcap', 'pe_ttm', 'pb', 'ps_ttm', 'ev_ebitda_ttm', 'cape_5', 'roe_ttm', 'roce_ttm', 'roa_ttm',
                'avg_3_roe', 'earnings_3y_cagr', 'sales_3y_cagr', 'sales_5y_cagr', 'debt_equity_latest',
                'consensus_tp', 'strong_buy_per', 'promoter_holding', 'promoter_holding_pledged']
        }
    }
    else if (type?.value === 1 || type?.value === 2) {
        if (fundData?.is_index_fund === 1) {
            if (fundData?.primary_category_name === 'Equity' && !(fundData?.category_name?.includes('International'))) {
                metricColCodes = ['aum', 'expense_ratio', 'inception', 'pe', 'pb', 'tracking_error', 'td_1y', 'td_3y', 'td_5y', 'cagr_1y', 'cagr_3y', 'cagr_5y']
            }
            else if (fundData?.primary_category_name === 'Equity' && fundData?.category_name?.includes('International')) {
                metricColCodes = ['aum', 'expense_ratio', 'inception', 'max_drawdown', 'ret_1month', 'tracking_error', 'td_1y', 'td_3y', 'td_5y', 'cagr_1y', 'cagr_3y', 'cagr_5y']
            }
            else if (fundData?.primary_category_name === 'Debt') {
                metricColCodes = ['aum', 'expense_ratio', 'inception', 'credit_risk', 'ir_risk', 'ytm', 'duration', 'maturity', 'tracking_error', 'td_1y', 'td_3y', 'cagr_3y']
            }
            else if (fundData?.primary_category_name === 'Commodities') {
                metricColCodes = ['aum', 'expense_ratio', 'inception', 'max_drawdown', 'tracking_error', 'td_1y', 'td_3y', 'td_5y', 'cagr_1y', 'cagr_3y', 'cagr_5y', 'cagr_10y']
            }
            else if (fundData?.primary_category_name === 'Hybrid' && !(fundData?.category_name?.includes('Arbitrage'))) {
                metricColCodes = ['aum', 'expense_ratio', 'inception', 'pe', 'pb', 'credit_risk', 'ir_risk', 'ytm', 'duration', 'maturity', 'tracking_error', 'td_1y', 'td_3y', 'td_5y']
            }
            else if (fundData?.primary_category_name === 'Hybrid' && fundData?.category_name?.includes('Arbitrage')) {
                metricColCodes = ['aum', 'expense_ratio', 'inception', 'max_drawdown', 'tracking_error', 'td_1y', 'td_3y', 'td_5y', 'cagr_1y', 'cagr_3y', 'cagr_5y', 'cagr_10y']
            }
        }
        else if (type?.value === 1) {
            if (fundData?.primary_category_name === 'Equity' && !(fundData?.category_name?.includes('International'))) {
                metricColCodes = ['aum', 'expense_ratio', 'inception', 'pe', 'pb', 'tracking_error', '21d_vol', 'td_1y', 'td_3y', 'td_5y', 'cagr_1y', 'cagr_3y']
            }
            else if (fundData?.primary_category_name === 'Equity' && fundData?.category_name?.includes('International')) {
                metricColCodes = ['aum', 'expense_ratio', 'inception', 'max_drawdown', 'ret_1month', '21d_vol', 'tracking_error', 'td_1y', 'td_3y', 'td_5y', 'cagr_1y', 'cagr_3y']
            }
            else if (fundData?.primary_category_name === 'Debt') {
                metricColCodes = ['aum', 'expense_ratio', 'inception', 'credit_risk', 'ir_risk', 'ytm', 'duration', 'maturity', '21d_vol', 'tracking_error', 'td_1y', 'td_3y']
            }
            else if (fundData?.primary_category_name === 'Commodities') {
                metricColCodes = ['aum', 'expense_ratio', 'inception', 'max_drawdown', '21d_vol', 'tracking_error', 'td_1y', 'td_3y', 'td_5y', 'cagr_1y', 'cagr_3y', 'cagr_5y']
            }
            else if (fundData?.primary_category_name === 'Hybrid' && !(fundData?.category_name?.includes('Arbitrage'))) {
                metricColCodes = ['aum', 'expense_ratio', 'inception', 'pe', 'pb', 'credit_risk', 'ir_risk', 'ytm', 'duration', 'maturity', 'tracking_error', 'td_1y']
            }
            else if (fundData?.primary_category_name === 'Hybrid' && fundData?.category_name?.includes('Arbitrage')) {
                metricColCodes = ['aum', 'expense_ratio', 'inception', 'max_drawdown', '21d_vol', 'tracking_error', 'td_1y', 'td_3y', 'td_5y', 'cagr_1y', 'cagr_3y', 'cagr_5y']
            }
        }
        else if (type?.value === 2) {
            if (fundData?.primary_category_name === 'Equity' && !(fundData?.category_name?.includes('International'))) {
                metricColCodes = ['aum', 'expense_ratio', 'inception', 'pe', 'pb', 'max_drawdown', 'ret_1month', 'cagr_1y', 'cagr_3y', 'cagr_5y', 'alpha', 'sharpe']
            }
            else if (fundData?.primary_category_name === 'Equity' && fundData?.category_name?.includes('International')) {
                metricColCodes = ['aum', 'expense_ratio', 'inception', 'max_drawdown', 'ret_1month', 'ret_ytd', 'cagr_1y', 'cagr_3y', 'cagr_5y', 'alpha', 'sharpe']
            }
            else if (fundData?.primary_category_name === 'Debt') {
                metricColCodes = ['aum', 'expense_ratio', 'inception', 'credit_risk', 'ir_risk', 'ytm', 'duration', 'maturity', 'ret_1month', 'cagr_1y', 'cagr_3y', 'cagr_5y']
            }
            else if (fundData?.primary_category_name === 'Commodities') {
                metricColCodes = ['aum', 'expense_ratio', 'inception', 'max_drawdown', 'ret_1month', 'ret_ytd', 'cagr_1y', 'cagr_3y', 'cagr_5y', 'alpha', 'sharpe']
            }
            else if (fundData?.primary_category_name === 'Hybrid' && !(fundData?.category_name?.includes('Arbitrage'))) {
                metricColCodes = ['aum', 'expense_ratio', 'inception', 'pe', 'pb', 'credit_risk', 'ir_risk', 'ytm', 'duration', 'maturity', 'cagr_3y', 'cagr_5y']
            }
            else if (fundData?.primary_category_name === 'Hybrid' && fundData?.category_name?.includes('Arbitrage')) {
                metricColCodes = ['aum', 'expense_ratio', 'inception', 'max_drawdown', 'ret_1month', 'ret_ytd', 'cagr_1y', 'cagr_3y', 'cagr_5y', 'alpha', 'sharpe']
            }
        }
    }
    return metricColCodes
}

export const screenerQueryBuilder = async (filters = {}, cols = [], exclusionList = true) => {

    // filters => key vlaue array

    var finalRule = {
        "abs_val_1": "2",
        "abs_val_2": null,
        "adj_operator": null,
        "checklist_id": 0,
        "client_id": 0,
        "comp_operator": "eq",
        "is_active": 1,
        "is_advanced": 0,
        "oper": "abs_comp",
        "pos": 2,
        "primary_col": "style_box_code",
        "primary_col_table": "S",
        "rel_comp_name": null,
        "rel_comp_stat": null,
        "rule_id": 2,
        "rule_name": "style_box_code",
        "sec_col": null,
        "sec_col_table": null
    }

    let allFilters = [exclusionList && {
        "abs_val_1": 0,
        "abs_val_2": null,
        "adj_operator": null,
        "checklist_id": 0,
        "client_id": 0,
        "comp_operator": "eq",
        "is_active": 1,
        "is_advanced": 0,
        "metric_col_code": "is_exclusion_list",
        "oper": "abs_comp",
        "pos": 1,
        "primary_col": "is_exclusion_list",
        "primary_col_table": "S",
        "rel_comp_name": null,
        "rel_comp_stat": null,
        "rule_id": 1,
        "rule_name": "Exclude Surveillance stocks",
        "screener_type": "stock",
        "sec_col": null,
        "sec_col_table": null
    }, ...filters?.map((rule, index) => {
        return {
            ...finalRule,
            "rule_id": index + 2,
            "pos": index + 2,
            "primary_col": rule[0],
            "rule_name": rule[0],
            "abs_val_1": rule[1],
            "oper": typeof (rule[1]) === "number" ? "abs_comp" : "isin_comp",
        }
    })]
    allFilters = allFilters?.filter((el) => el)
    //change c=karna hai rules ko abhi static hais
    var res = await AdminServicesPY.getAllScreenedStocksNew({
        rules: allFilters,
        cols: cols
    }).then((r) => { return r })
    // console.log(res)

    if (res?.type)
        return JSON.parse(res?.data) || []
    else
        return []
}
// const callPPAuthStatus = async (id) => {
//     var tr1 = await AdminServices.getPPAuthRequests(id).then((res) => { return res })
//     if (tr1?.data?.length) {
//         await AdminServices.getPPAuthReqStatus(tr1?.data?.[0]?.AuthId).then((res) => { return res })
//     }
//     return {
//         AuthId: tr1?.data?.[0]?.AuthId,
//         CreatedOn: tr1?.data?.[0]?.CreatedOn
//     }
// }

const getAccessLevelByPlanName = (name) => {
    switch (name) {
        case 'Lite': return 2;
        case 'Pro': return 3;
        case 'Black': return 4;
        default: return 1;
    }
}

const callPPAuthStatus = async (id) => {
    var tr1 = await AdminServices.getPPAuthRequests(id).then((res) => { return res })
    if (tr1?.data?.length) {
        await AdminServices.getPPAuthReqStatus(tr1?.data?.[0]?.AuthId).then((res) => { return res })
    }
    return {
        AuthId: tr1?.data?.[0]?.AuthId,
        CreatedOn: tr1?.data?.[0]?.CreatedOn
    }
}

const getUserSubOrOneTime = async ({ user }) => {
    let userAccessLevel = user?.Client?.[0]?.SharpelyAccessLevel;
    let nextDueDate = user?.Client?.[0]?.SharpelyPayDueDate;
    let allSubs = [], allPPSubs = [], allRPSubs = [], allPPOnetimes = [];

    async function getPPSubs() {
        allPPSubs = await apiHelper({ apiName: "getPPSubscriptions", data: user?.id });
        if (allPPSubs?.length > 0) {
            allPPSubs = allPPSubs?.reverse();
            for (let i = 0; i < allPPSubs?.length; i++) {
                let ele = allPPSubs?.[i]
                ele = {
                    ...ele,
                    CreatedOn: ele?.SubscriptionStaeDate,
                    PGType: "PhonePe"
                }
                allPPSubs[i] = ele;
            }
        }
    }

    async function getRazorSubs() {
        allRPSubs = await apiHelper({ apiName: "getRazorSubscriptionsByCustId", data: { id: user?.RazorpayInfo?.[0]?.CustId } });
        if (allRPSubs?.length > 0) {
            allRPSubs = allRPSubs?.reverse()
            let sharpelyPlans = await apiHelper({ apiName: "getRazorPlans" });
            if (sharpelyPlans) {
                sharpelyPlans = JSON.parse(sharpelyPlans);
            }
            else {
                sharpelyPlans = {}
            }
            allRPSubs = allRPSubs?.map((el) => {
                let t = JSON.parse(el);
                let pl = sharpelyPlans?.items?.find((ele) => ele?.id === t?.plan_id)
                t = {
                    ...t,
                    SubscriptionState: String(t?.status)?.toUpperCase(),
                    SubscriptionId: t?.id,
                    CreatedOn: new Date(Number(t?.created_at) * 1000)?.toISOString(),
                    Frequency: pl?.period,
                    Amount: pl?.item?.amount,
                    SubscriptionName: pl?.item?.name,
                    PGType: "RazorPay"
                }
                return t
            })
        }
    }

    async function getPPOneTimes() {
        allPPOnetimes = await AdminServices.getPPTrans(user?.id, "id").then((res) => { return res })
        allPPOnetimes = allPPOnetimes?.data?.filter((i) => i?.ResponseCode === "SUCCESS" && i?.State === "COMPLETED") || []
        allPPOnetimes = allPPOnetimes?.reverse()
        allPPOnetimes = allPPOnetimes?.filter((ele) => {
            return userAccessLevel === getAccessLevelByPlanName(ele?.Plan)
        })
    }

    await Promise.all([getPPSubs(), getRazorSubs(), getPPOneTimes()]);

    if (allPPSubs?.length > 0) {
        allSubs = [...allPPSubs]
    }

    if (allRPSubs?.length > 0) {
        allSubs = [...allSubs, ...allRPSubs]
    }

    allSubs.sort((a, b) => {
        const dateA = a.CreatedOn ? new Date(a.CreatedOn).getTime() : Infinity;
        const dateB = b.CreatedOn ? new Date(b.CreatedOn).getTime() : Infinity;
        const currentDate = new Date();
        if (!a.CreatedOn && !b.CreatedOn) {
            return 0;
        } else if (!a.CreatedOn) {
            return 1;
        } else if (!b.CreatedOn) {
            return -1;
        }
        const diffA = Math.abs(currentDate.getTime() - dateA);
        const diffB = Math.abs(currentDate.getTime() - dateB);
        return diffA - diffB;
    });

    let activeSub = allSubs?.find((el) => {
        return el?.SubscriptionState === 'ACTIVE' || el?.SubscriptionState === 'AUTHENTICATED'
    })

    let activeSubOrOnetime = {};

    if (userAccessLevel > 1) {
        if (activeSub?.SubscriptionId) {
            if (activeSub?.PGType === 'RazorPay') {
                nextDueDate = new Date(activeSub?.current_end * 1000)?.toISOString()
            }
            activeSubOrOnetime = {
                "planData": activeSub,
                "isRecurring": true,
                "startDate": activeSub?.CreatedOn,
                "nextDueDate": nextDueDate,
                "amount": activeSub?.Amount / 100,
                "frequency": activeSub?.Frequency === 'monthly' ? "Monthly" : "Yearly",
                "planName": activeSub?.SubscriptionName
            }
        }
        else if (allPPOnetimes?.length > 0) {
            activeSubOrOnetime = {
                "planData": allPPOnetimes?.[0],
                "isRecurring": false,
                "startDate": allPPOnetimes?.[0]?.CreatedOn,
                "nextDueDate": nextDueDate,
                "amount": allPPOnetimes?.[0]?.Amount / 100,
                "planName": allPPOnetimes?.[0]?.Plan,
                "expiresOn": nextDueDate
            }
        }
    }
    return { allSubs, activeSub, activeSubOrOnetime }
}

export const getUserPlanData = async ({ user }) => {
    try {
        let hasMadeFirstTransaction = false;
        if (user?.isLogged) {
            let res = await getUserSubOrOneTime({ user });
            let currentSubscription = res?.activeSub;
            let userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel
            let ftStartDate = user?.Client?.[0]?.SharpelyFTStartDate;
            let isSharpelyFreeTrial = user?.Client?.[0]?.IsSharpelyFreeTrial === 1;
            let hasEarlyAccess = false;
            let userActiveSubOrOneTime = res?.activeSubOrOnetime;
            if (userAccesslevel > 1 && !currentSubscription?.SubscriptionId && !userActiveSubOrOneTime?.planName) {
                hasEarlyAccess = true;
            }
            if (isSharpelyFreeTrial && ftStartDate) {
                hasMadeFirstTransaction = true
            }
            return {
                data: {
                    currentSubscription,
                    hasEarlyAccess,
                    allSubs: res?.allSubs,
                    userActiveSubOrOneTime,
                    hasMadeFirstTransaction,
                    nextDueDate: user?.Client?.[0]?.SharpelyPayDueDate
                }
            }
        }
        else {
            return undefined;
        }
    }
    catch (err) {
        return null
    }
}

export function getRandomColor() {
    let color = "hsl(" + Math.random() * 360 + ", 100%, 75%)";
    return color;
}

export const isSharpelyMember = (id) => {
    if (id <= 11 || [8176, 8425]?.includes(id)) {
        return true
    } else {
        return false
    }
}

export const preferenceQuestions = [
    {
        heading: "How do you intend to use sharpely?",
        subText: "This will help us provide a more customized experience of the platform as well as deliver tailored content. Don't worry, you can still use all the features!",
        options: [
            {
                label: "Screen stocks and do fundamental research", key: 1, value: 1
            },
            {
                label: "Get data-driven stock recommendations", key: 2, value: 2
            },
            {
                label: "Analyze and invest in mutual funds and ETFs", key: 3, value: 3
            },
            {
                label: "Build and invest in quantitative investment strategies", key: 4, value: 4
            },
            {
                label: "I will be actively using multiple features", key: 5, value: 5
            }
        ],
        value: 1,
        key: "q1Answer"
    },
    {
        heading: "How many years of investing experience do you have?",
        subText: "sharpely is for everyone but knowing your level of investing experience will help us serve you better.",
        options: [
            {
                label: "I am just getting started",
                heading: "Investment Novice",
                key: 1,
                value: 1
            },
            {
                label: "1 - 3 years",
                heading: "Financial Virtuoso",
                key: 2,
                value: 2
            },
            {
                label: "3 - 5 years",
                heading: "Investment Maestro",
                key: 3,
                value: 3
            },
            {
                label: "More than 5 years",
                heading: "Financial Wizard",
                key: 4,
                value: 4
            }
        ],
        value: 2,
        key: "q2Answer"
    },
    {
        heading: "Would you like to receive our free, weekly newsletter 'Sharp Insights' for key updates on the Indian stock market?",
        subText: "",
        options: [
            {
                label: "Yes",
                key: 1,
                value: 1
            },
            {
                label: "No",
                key: 0,
                value: 0
            }
        ],
        value: 3,
        key: "isNewsletterSubscribed"
    }
]

export const dashboardSegments = [
    { value: 1, key: "S_FUNDAMENTAL" },
    { value: 2, key: "S_STOCK_RECOMMEND" },
    { value: 3, key: "S_PERSONAL_FINANCE" },
    { value: 4, key: "S_QUANTITIVE" },
    { value: 5, key: "S_ALL" },
]

export const lightBgs = Array.from({ length: 20 }, (v, k) => (k + 1) * 5).map((i) => { return `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}` + "11" });

export const lightColors = Array.from({ length: 20 }, (v, k) => (k + 1) * 5).map((i) => { return `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}` });

export const defaultPagination = {
    defaultPageSize: 10,
    position: ['bottomCenter'],
    showSizeChanger: false,
    showPrevNextJumpers: true,
    className: "textSM",
    hideOnSinglePage: true,
    itemRender: (current, type, originalElement) => {
        if (type === 'prev') {
            return <a {...originalElement.props} className="textSM mx-2">Previous</a>;
        }
        if (type === 'next') {
            return <a {...originalElement.props} className="textSM mx-2">Next</a>;
        }
        return originalElement;
    }
}

export const getDefWidgetKeys = (user, type) => {
    let temp = [];
    if (type.value === 4) {
        temp = ['INDEX_WATCH', 'MARKET_MOVERS', 'HIGH_LOW', 'STOCK_WIZARDS', 'INDEX_HEATMAP', 'STOCK_STYLEBOX']
        if (user?.isLogged) {
            temp.splice(0, 0, 'MY_WIDGET')
        }
    }
    return temp;
}

export const getIndexList = async () => {
    let result = [];
    let indices = await apiHelperPY({ apiName: "getIndexCoverageList", saveResponse: "sessionStorage" })
    if (indices) {
        indices = JSON.parse(indices || '[]') || [];
        indices = indices?.map((item, i) => {
            let symbol = item?.UpstoxName?.split(':')?.[1]?.toUpperCase();
            let indexDescription = item?.IndexDescription;
            if (String(item?.IndexName)?.startsWith('SHI:')) {
                indexDescription = `sharpely's custom index created to track ${String(item?.IndexName?.replace('SHI: ', ''))}. It is created by picking top 20 stocks in the industry by market capitalization. The stocks must be actively traded on NSE. If number of stocks in the industry is less than 20, all stocks are taken. Index is constructed by weighting each selected stock in proportion to market capitalization. The index is rebalanced every 6 months on 1st Jan and 1st July.`;
            }
            return {
                ...item,
                IndexDescription: indexDescription,
                proper_name: item?.IndexName,
                symbol: symbol,
                gdf_symbol: symbol,
                key: i,
                label: item?.IndexName,
                value: item?.IndexNameCaps
            }
        }) || [];
        result = indices;
    }
    return result;
}

export const getFormattedWatchlist = (watchlistt = []) => {
    if (watchlistt.length > 0) {
        let watchlistIds = [...new Set(watchlistt?.map((ele) => ele?.WatchListId))];
        watchlistIds?.sort((a, b) => a - b)
        let formattedWatchlist = watchlistIds?.map((ele) => {
            let temp = watchlistt.filter((el) => el?.WatchListId === ele);
            let obj = {
                WatchListId: ele,
                Name: temp?.[0]?.Name,
                FundType: temp?.[0]?.FundType,
                FundCodes: temp?.filter(el => el?.IsFundActive === 1)?.map((el) => el?.FundCode)
            }
            return obj;
        })
        return formattedWatchlist;
    }
    return [];
}

export const betaAccessObj = {
    "UID": 'betaAccess',
    "ModuleKey": "BETA_ACCESS",
    "ModuleSection": "Static Baskets",
    "ModuleName": "Upgrade Your Plan for Beta Access!",
    "ModuleDesc": "Gain early access to sharpely's newest features! Upgrade now to become a beta tester and try out our latest tools and features before they go live. Your feedback will shape our platform's future.",
    "AccessLevel": 2,
    "AccessLimit": "",
    "IsEnabled": 1
}

export const metricsToHideForCustomization = ['profit_growth_qoq']

export const helpAndSupportIssues = [
    { key: 0, label: "Black subscriptions", value: "Black subscription" },
    { key: 1, label: "Data quality", value: "Data quality" },
    { key: 2, label: "Data availability", value: "Data availability" },
    { key: 3, label: "Methodology", value: "Methodology" },
    { key: 4, label: "Bug in the app", value: "Bug in the app" },
    { key: 5, label: "Feature request", value: "Feature request" },
    { key: 6, label: "Broker missing", value: "Broker missing" },
    { key: 7, label: "Disconnect existing broker", value: "Disconnect existing broker" },
    { key: 8, label: "Payment", value: "Payment" },
    // { key: 8, label: "Port Old screens to Super Screener", value: "Port Old screens to Super Screener" },
    // { key: 9, label: "Port Old baskets to Strategy", value: "Port Old baskets to Strategy" },
    { key: 10, label: "Other", value: "Other" }
]

export const isInvalidTextOrLength = ({ text = '', checkTextChars = true, checkLength = false, length = 3 } = {}) => {
    let c1 = checkLength ? text?.length < length : false;
    let c2 = checkTextChars ? String(text).match(/[&\/?#'""]/g)?.length > 0 : false;
    return c1 || c2;
}

export const arePropsEqual = (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
}

export const getMoengageDeviceUID = () => {
    let uuid = JSON.parse(localStorage.getItem('MOE_DATA') || "{}")
    if(uuid){
      return uuid?.USER_DATA?.deviceUuid
    }
    else {
      return ''
    }
}
