
import { useSelector } from "react-redux";

const GetLTP = ({ fundData, symbol }) => {
    var stockprices = useSelector((state) => state?.stockprices?.value)
    var prevstockprices = useSelector((state) => state?.prevStockPrices?.value)
    // return (stockprices?.[fundData?.gdf_symbol]?.LastTradePrice || (stockprices?.[fundData?.gdf_symbol]?.Close))

    var currentLTP = "-"
    var currentSymbolAllData = stockprices === "close" ? prevstockprices?.[fundData?.gdf_symbol] : stockprices?.[fundData?.gdf_symbol] || prevstockprices?.[fundData?.gdf_symbol]
    if (currentSymbolAllData === undefined && fundData?.isin_code !== undefined) {
        // console.log(Object?.values(prevstockprices))
        currentSymbolAllData = stockprices === "close" ? Object?.values(prevstockprices)?.filter((i) => String(i?.InstrumentToken)?.includes(fundData?.isin_code)) : Object?.values(stockprices)?.filter((i) => String(i?.InstrumentToken)?.includes(fundData?.isin_code)) || Object?.values(prevstockprices)?.filter((i) => String(i?.InstrumentToken)?.includes(fundData?.isin_code))
        currentSymbolAllData = currentSymbolAllData?.[0] || undefined
    }
    currentLTP = currentSymbolAllData?.LastTradePrice
    // changeVal = currentSymbolAllData?.PriceChange
    // changePer = currentSymbolAllData?.PriceChangePercentage

    if (currentSymbolAllData === undefined) {
        let ele = fundData
        currentLTP = ele?.price || 0
        // changePer = ele?.one_day_pct || 0
        // let originalPrice = currentLTP / (1 + (changePer / 100))
        // changeVal = currentLTP - originalPrice;
    }


    return currentLTP
}

export { GetLTP };
