import { LoadingOutlined } from '@ant-design/icons';
import moengage from "@moengage/web-sdk";
import { flaticonSvg } from 'Assets/Constant';
import { signInWithGoogle } from 'FirebaseConst';
import Head from 'Layout/Head';
import Logo from 'Layout/Logo';
import db from 'Libs/db';
import { updateStockPrices, updatesubscribedStocks } from 'Libs/redux/StockLivePrice/reduxSlice';
import { updatePrevStockPrices } from 'Libs/redux/StocksPrevPrices/reduxSlice';
import { togglePreferenceModal, toggleUserSignedIn, toggleloginModal, updateUserData } from 'Libs/redux/UserAuth/reduxSlice';
import AdminServices from 'Services/api/AdminServices';
import { Dashboard_Portfolio } from 'Services/api/FabricatedAPIS';
import { Divider } from 'antd';
import React from 'react';
import { toast } from 'react-hot-toast';
import { FcGoogle } from 'react-icons/fc';
import PinInput from 'react-pin-input';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { LoginSignupCarousel } from './LoginSignupCarousel';

export default function Login(props) {

  const user = useSelector((state) => state?.updateUserData?.value);
  const [email, setEmail] = React.useState('');
  const [userData, setuserData] = React.useState({});
  const [otp, setOTP] = React.useState('');
  // const [mainOTP, setmainOTP] = React.useState('');
  const [nextBtnPressed, setNextBtnPressed] = React.useState(false)
  const [loading, setLoading] = React.useState(false);
  const [isIncorrectOtp, setIsIncorrectOtp] = React.useState(false);
  const [resendOTPTime, setResendOTPTime] = React.useState(30);
  const navigate = useNavigate();
  const loc = useLocation();
  const dispatch = useDispatch();
  let isBeta = process.env.REACT_APP_ENVIRONMENT === 'beta';
  let isDev = process.env.NODE_ENV === 'development';
  const resendOTPTimerRef = React.useRef();
  const navigateToPath = loc?.state?.from ? (loc?.state?.from === '/' ? '/stocks/dashboard' : loc?.state?.from) : "/stocks/dashboard";

  const resetData = () => {
    dispatch(updatePrevStockPrices([]));
    dispatch(updateStockPrices([]));
    dispatch(updatesubscribedStocks({}));
    db.clear();
  }

  let otpref = React.useRef();

  const checkOTP = async () => {
    let isCorrectOTP = await AdminServices.verifyOTP({ value: email, otp: otp });
    console.log(isCorrectOTP);
    if (isCorrectOTP?.data?.Result === 'success') {
      setLoading(true)
      try {
        resetData();
        db.set(db.model.user, JSON.stringify({ userData })); // remove later
        let d = {}
        await Dashboard_Portfolio.getClientData(userData, true).then((res) => {
          if (res) {
            d = res;
            dispatch(updateUserData({ ...userData, ...res }))
          }
        })
        let userTracked = await moengage.add_unique_user_id("SHARPELY-" + userData.id);
        if (userTracked) {
          moengage.add_email(userData.email)
          moengage.add_mobile(userData.mobile)
          moengage.add_user_name(userData.name)
          moengage.add_user_attribute("SharpelyAccessLevel", d?.Client?.[0]?.SharpelyAccessLevel)
        }
        moengage.track_event("OTP_submitted_login", {
          "otp_user_email": userData?.email,
          'otp_user_id': userData?.id
        })
        moengage.track_event("Login_page_email", {
          "login_email": userData?.email,
          "login_mobile": userData?.mobile,
          "login_user_name": userData?.name
        })
        dispatch(toggleloginModal(false));
        dispatch(toggleUserSignedIn(true));
        if (!d?.Client?.[0]?.Q1Answer) {
          dispatch(togglePreferenceModal(true))
        }
        toast.success('Welcome back!')
        navigate(navigateToPath)
      } catch (error) {
        setLoading(false);
        console.log(error)
        otpref?.current?.clear()
        setOTP('');
        toast.error("Something went wrong...");
      }
    } else {
      toast.error("Incorrect OTP!");
      otpref?.current?.clear()
      setOTP('');
      setLoading(false)
    }
  }

  const handleValidation = () => {
    let mailformat = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    if (!email.match(mailformat)) {
      toast.error('Enter valid email!')
    }
    else {
      return true;
    }
    return;
  }

  const startResendOTPTimer = () => {
    resendOTPTimerRef.current = setInterval(() => {
      setResendOTPTime((prev) => {
        if (prev === 0) return prev;
        else return prev - 1;
      })
    }, 1000)
  }

  const sendOTP = async (id, email, name) => {
    try {
      // setmainOTP('')
      const res = await AdminServices.login({ id, appId: 2, displayName: name }).then(res => res);
      setResendOTPTime(30);
      if (res.type) {
        setNextBtnPressed(true);
        // setmainOTP(res.data.otpcode);
        moengage.track_event('email_submitted_login', {
          "user_email": email,
          "user_id": id
        })
        toast.success('OTP has been sent on your registered email/mobile');
        startResendOTPTimer();
      } else {
        toast.error("Something went wrong. Please try again ...");
      }
      setLoading(false)
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Please try again ...");
      setLoading(false)
    }
  };

  const onNextBtnPressed = async () => {
    try {
      setLoading(true)
      const res = await AdminServices.ClientExists({ type: "email", value: email }).then((res) => res);
      if (res.type) {
        if (res.data.ClientId !== '') {
          setuserData({
            name: res.data.ClientName,
            email: res.data.Email,
            mobile: res.data.MobileNumber,
            id: res.data.ClientId,
            isLogged: true,
            login_Date: new Date()
          });
          sendOTP(res.data.ClientId, res.data?.Email, res.data.ClientName)
        } else {
          setNextBtnPressed(false);
          setLoading(false)
          toast.error("Not a sharpely registered user");
        }
      } else {
        toast.error("Something went wrong. Please try again ...");
        setNextBtnPressed(false);
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong. Please try again ...");
      setNextBtnPressed(false);
      setLoading(false)
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return;
    if (nextBtnPressed) checkOTP()
    else {
      if (handleValidation() === true) {
        onNextBtnPressed();
      }
    }
  }

  React.useEffect(() => {
    if (otp.length === 6) checkOTP();
  }, [otp])

  React.useEffect(() => {
    if (resendOTPTime === 0) {
      clearInterval(resendOTPTimerRef.current);
      resendOTPTimerRef.current = null;
    }
  }, [resendOTPTime]);

  const signin = async () => {
    setLoading(true)
    var user = await signInWithGoogle()
    // user = {
    //   email: "nikhil.deshpande@gmail.com"
    // }
    if (Object.keys(user)?.length <= 0) {
      setNextBtnPressed(false);
      setLoading(false)
    }
    else {
      try {
        const res = await AdminServices.ClientExists({ type: "email", value: user.email }).then((res) => res);
        if (res.type) {
          if (res.data.ClientId !== '') {
            resetData();
            var userData = {
              name: res.data.ClientName,
              email: res.data.Email,
              mobile: res.data.MobileNumber,
              id: res.data.ClientId,
              isLogged: true,
              login_Date: new Date()
            }
            db.set(db.model.user, JSON.stringify({ userData })); // remove later
            console.log("asfsadf", user.email)
            moengage.track_event("Login_page_google", {
              "login_email": userData?.email,
              "login_mobile": userData?.mobile,
              "login_user_name": userData?.name
            })
            let d = {}
            await Dashboard_Portfolio.getClientData(userData, true).then((res) => {
              if (res) {
                d = res;
                dispatch(updateUserData({ ...userData, ...res }))
              }
            })
            let userTracked = await moengage.add_unique_user_id("SHARPELY-" + userData.id);
            if (userTracked) {
              moengage.add_email(userData.email)
              moengage.add_mobile(userData.mobile)
              moengage.add_user_name(userData.name)
              moengage.add_user_attribute("SharpelyAccessLevel", d?.Client?.[0]?.SharpelyAccessLevel)
            }
            dispatch(toggleloginModal(false))
            toast.success('Welcome back!')
            navigate(navigateToPath)

          } else {
            setNextBtnPressed(false);
            setLoading(false)
            toast.error("Not a sharpely registered user");
          }
        } else {
          toast.error("Something went wrong. Please try again ...");
          setNextBtnPressed(false);
          setLoading(false)

        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong. Please try again ...");
        setNextBtnPressed(false);
        setLoading(false)
      }
    }
  }

  if (user?.isLogged) {
    return <Navigate to='/stocks/dashboard' />
  }
  return (
    <React.Fragment>
      <Head title='sharpely Login | Access Your Financial Hub' description="Log into your sharpely account to explore personalized financial insights and manage your investments effortlessly." />
      <div className='mainContainer loginComponent' style={{ alignItems: "center", gap: 0 }}>
        <div style={{
          height: "calc(100vh)", width: "65%", display: "flex", alignItems: "center", justifyContent: "center", flexShrink: 0, background: "var(--dark4)", paddingRight: "2rem"
        }} className=''>
          <LoginSignupCarousel />
        </div>
        <div style={{ height: "calc(100vh)", flex: 1, width: "100%", padding: "0 1.25rem", display: "flex", alignItems: "center", }}>
          <div style={{ width: "100%", maxHeight: "100%", overflowY: "auto", padding: "1.25rem 0" }}>
            <Logo type='dark' height='4rem' />
            <div className='displayMD' style={{ marginTop: "2rem", fontWeight: 600 }}>
              Welcome back to<br />
              <span className='w-500' style={{ color: "var(--primary)" }}>sharpely! 👋🏻</span>
            </div>
            <div className='textSM w-500 dark4' style={{ marginTop: "0.75rem" }}>
              Please log-in to your account and start the adventure
            </div>
            <form onSubmit={(e) => handleSubmit(e)} style={{ marginTop: "2rem", marginBottom: "0rem" }}>
              {!nextBtnPressed ? <>
                <div style={{ marginBottom: "1rem" }}>
                  <label className='dark3 textXS w-500 mb-3'>Email Address </label>
                  <input name='email' placeholder='Enter your email address' onChange={(e) => { setEmail(e.target.value?.trim()) }} className='textSM antdInput' style={{
                    background: "var(--grey5)", border: "1px solid var(--grey2)", height: "40px"
                  }} type='text' value={email} autoComplete='on' autoFocus={true} />
                </div>
              </> : <>
                <div style={{ marginBottom: "1.5rem" }}>
                  <label className='dark3 textXS w-500 mb-1'>OTP</label>
                  <PinInput ref={otpref} length={6} initialValue="" secret secretDelay={100} onChange={(value, index) => { setOTP(value); }} type="numeric" inputMode="number"
                    style={{ padding: '0rem' }} inputStyle={{
                      borderColor: 'var(--grey2)', background: "var(--grey5)", borderRadius: "var(--borderRadius)",
                      height: "40px", width: "40px"
                    }} inputFocusStyle={{ borderColor: 'var(--primary)' }} onComplete={(value, index) => { }} autoSelect={true} focus={true} />
                </div>
                <div className='textESM dark3' style={{ marginBottom: "0.75rem" }}>
                  Did not received OTP? <span className='primary w-500' style={{ cursor: resendOTPTime === 0 ? "pointer" : "not-allowed" }} onClick={() => {
                    console.log(resendOTPTime)
                    if (resendOTPTime <= 0) {
                      sendOTP(userData?.id, userData?.email, userData?.name);
                    }
                  }}>Resend OTP</span>
                  {resendOTPTime > 0 && resendOTPTimerRef.current && <span> in {resendOTPTime}s</span>}
                </div>
                <div className='textESM dark3 d-flex' style={{ marginBottom: "1rem" }}>
                  Incorrect email?&nbsp;<span className='primary w-500' onClick={() => {
                    setNextBtnPressed(false);
                    // setmainOTP('');
                    setOTP('');
                    setuserData({});
                    clearInterval(resendOTPTimerRef.current)
                  }}>Change Email</span>
                </div>
              </>}
              <button style={{ width: "100%", opacity: loading ? "0.7" : "1" }} type='submit'
                className={" btnBlack textMD "} >
                {loading ? <LoadingOutlined style={{ fontSize: '1.5rem', color: "var(--white)" }} spin /> :
                  (nextBtnPressed ? "Sign in" : "Send OTP")}
              </button>
            </form>

            <Divider className='textMD'>or</Divider>
            <button style={{ width: "100%", opacity: loading ? "0.7" : "1" }} className={" btnBlack "} onClick={() => {
              if (!loading) {
                signin();
              }
            }} >
              <FcGoogle className='' style={{ marginRight: "0.5rem", fontSize: "var(--textMD)" }} />
              <span className='textMD w-500'>Sign In With Google</span>
            </button>

            <div className='text-center d-flex' style={{ marginTop: "1.25rem" }}>
              <div className='d-flex flex-column align-items-center w100' style={{ gap: "0.25rem", padding: "0 0.5rem" }}>
                <img loading="lazy" src={`${flaticonSvg}fi-rr-lock.svg`} style={{ width: "20px" }} alt='No credential' />
                <div className='textXS w-700 dark3'>Safe transactions</div>
                <div className='textXS dark3'>Money stays in broker account</div>
              </div>
              <div className='d-flex flex-column align-items-center w100' style={{
                gap: "0.25rem", padding: "0 0.5rem", borderLeft: "1px solid var(--grey2)",
                borderRight: "1px solid var(--grey2)"
              }}>
                <img loading="lazy" src={`${flaticonSvg}fi-rr-arrow-small-right.svg`} style={{ width: "20px" }} alt='No credential' />
                <div className='textXS w-700 dark3'>You’re In Control</div>
                <div className='textXS dark3'>Sell anytime from your broker</div>
              </div>
              <div className='d-flex flex-column align-items-center w100' style={{ gap: "0.25rem", padding: "0 0.5rem" }}>
                <img loading="lazy" src={`${flaticonSvg}fi-rr-shield-check.svg`} style={{ width: "20px" }} alt='No credential' />
                <div className='textXS w-700 dark3'>No Credential</div>
                <div className='textXS dark3'>Your Information is safe</div>
              </div>
            </div>
            <div className='textSM dark3 text-center mt-3'>
              By continuing you agree to our <Link to='/terms-and-conditions'>terms & conditions</Link> and <Link to='/privacy-policy'>privacy policy</Link>
            </div>
            <div className="text-center textSM" style={{ marginTop: "1.5rem" }}>
              New on our platform? <Link to="/signup" className='mx-1 w-500'>Create Account</Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

