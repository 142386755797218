import { Card, Collapse, Tabs } from "antd";
import React from "react";
import { IoIosArrowDown } from "react-icons/io";

const CustomSideTabs = ({ tabData, allowScroll = false, width = "14rem", headingType, showNoData = false, noDataComponent = <></>, hideLRPadInMob, defTab }) => {
    const newTabData = tabData.filter(t => t.show).map(tab => {
        return {
            ...tab,
            children: <div style={{}}>
                {tab.noHeader ?
                    tab.children
                    :
                    <Card bordered={false} bodyStyle={{
                        backgroundColor: 'var(--grey4)',
                        border: '1px solid var(--grey3)', borderRadius: '0.5rem',
                        minHeight: "50vh", display: "flex", flexDirection: "column", gap: "0rem"
                    }}>
                        <div style={{ display: "flex", marginBottom: '0.875rem', alignItems: "center" }}>
                            <div className="textLG w-700 dark3" style={{ flex: 1 }}>{tab.label}</div>
                            {tab?.right && tab?.right}
                        </div>
                        {tab.children}
                    </Card>}
            </div>
        }
    })
    const [activeKey, setActiveKey] = React.useState(defTab ? defTab : newTabData?.[0]?.key)
    if (showNoData) {
        return noDataComponent
    }

    return <>
        <div className="display-desktop customSideTabs" >
            <Tabs items={newTabData} tabPosition="left" style={{ marginTop: '1.25rem', minHeight: "50vh" }} activeKey={activeKey} defaultActiveKey={newTabData?.[0]?.key}
                renderTabBar={(props) => {
                    return <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'transparent', border: 'none', width: width, borderTop: '1px solid var(--grey4)' }} >
                        {newTabData.map((tab, i) => {
                            return <div
                                className="textSM d-flex align-items-center"
                                style={{
                                    width: width, borderRadius: "0 5rem 5rem 0",
                                    // borderLeft: '1px solid var(--grey4)',
                                    // borderRight: '1px solid var(--grey4)', borderBottom: '1px solid var(--grey3)',
                                    padding: '0.675rem 1.25rem', backgroundColor: props.activeKey === tab.key ? 'var(--blueShade2)' : 'transparent',
                                    color: 'var(--black)', fontWeight: '500',
                                    cursor: 'pointer', gap: "1rem"
                                }}
                                onClick={() => {
                                    setActiveKey(tab.key);
                                    if (tab?.labelOnClick && activeKey !== tab?.key) {
                                        tab.labelOnClick()
                                    }
                                }} key={i}>
                                {tab.icon || null} {headingType === 3 ? <h3 className="margin0 font-inherit lineHeightUnset w-500">{tab.label}</h3> : tab.label}
                            </div>
                        })}
                    </div>
                }} />
        </div>
        <div className="display-mobile" style={{ marginLeft: '-1.5rem', marginRight: '-1.5rem' }}>
            <Collapse bordered={false} expandIconPosition={'end'} expandIcon={({ isActive }) => <IoIosArrowDown style={{
                transition: "0.2s linear", transform: isActive ? "rotate(180deg)" : "rotate(0deg)", fontSize: "1rem"
            }} />} style={{ padding: 0, backgroundColor: 'var(--white)' }} items={tabData.filter(t => t.show)?.map((t) => {
                return {
                    key: t.key,
                    label: headingType === 3 ? <h3 className="margin0 textSM w-500 black lineHeightUnset">{t.label}</h3> :
                        <span className='textSM w-500 black'>{t.label}</span>,
                    children: t.children,
                    style: { padding: '0 !important', background: 'var(--white)', borderColor: "var(--grey3)" }
                }
            })} />
        </div>
    </>
}

export const CustomSideTabs2 = ({ tabData, width = "14rem", headingType }) => {

    const tabItems = tabData.reduce((a, c) => {
        let temp = [...a, ...c.tabs];
        return temp;
    }, [])?.filter(t => t.show).map(tab => {
        return {
            ...tab,
            children: <div style={{}}>
                {tab.noHeader ?
                    tab.children
                    :
                    <Card bordered={false} bodyStyle={{
                        backgroundColor: 'var(--grey4)',
                        border: '1px solid var(--grey3)', borderRadius: '0.5rem',
                        minHeight: "50vh", display: "flex", flexDirection: "column", gap: "0rem"
                    }}>
                        <div style={{ display: "flex", marginBottom: '0.875rem', alignItems: "center" }}>
                            <div className="textLG w-700 dark3" style={{ flex: 1 }}>{tab.label}</div>
                            {tab?.right && tab?.right}
                        </div>
                        {tab.children}
                    </Card>}
            </div>
        }
    });

    const [activeKey, setActiveKey] = React.useState(tabItems?.[0]?.key)
    return <>
        <div className="display-desktop customSideTabs">
            <Tabs items={tabItems} tabPosition="left" style={{ marginTop: '1.25rem', minHeight: "50vh" }} activeKey={activeKey} defaultActiveKey={tabItems?.[0]?.key}
                renderTabBar={(props) => {
                    return <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'transparent', border: 'none', width: width, borderTop: '1px solid var(--grey4)' }} >
                        <Collapse bordered={false} expandIconPosition={'end'} expandIcon={({ isActive }) => <IoIosArrowDown style={{
                            transition: "0.2s linear", transform: isActive ? "rotate(180deg)" : "rotate(0deg)", fontSize: "1rem"
                        }} />} style={{ padding: 0, backgroundColor: 'var(--white)' }} className='customSidetabsCollapse' accordion defaultActiveKey={[tabData[0].key]}
                            items={tabData?.map((ele, i) => {
                                return {
                                    key: ele?.key,
                                    label: <h3 className="textSM w-500 margin0 lineHeightUnset">{ele.label}</h3>,
                                    children: (
                                        ele.tabs?.map((tab, i) => {
                                            return <div
                                                className="textSM"
                                                style={{
                                                    width: width,
                                                    borderLeft: '1px solid var(--grey4)',
                                                    borderRight: '1px solid var(--grey4)',
                                                    borderBottom: '1px solid var(--grey3)',
                                                    padding: '1rem 1.25rem',
                                                    backgroundColor: props.activeKey === tab.key ? 'var(--grey4)' : 'transparent',
                                                    color: props.activeKey === tab.key ? 'var(--black)' : 'var(--dark2)',
                                                    fontWeight: '500',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => setActiveKey(tab.key)} key={i}
                                            >
                                                {headingType === 3 ? <h3 className="margin0 font-inherit lineHeightUnset w-500">{tab.label}</h3> : tab.label}
                                            </div>
                                        })
                                    )
                                }
                            })} />
                    </div>
                }} />
        </div>
        <div className="display-mobile">
            <Collapse bordered={false} expandIconPosition={'end'} expandIcon={({ isActive }) => <IoIosArrowDown style={{
                transition: "0.2s linear", transform: isActive ? "rotate(180deg)" : "rotate(0deg)", fontSize: "1rem"
            }} />} style={{ padding: 0, backgroundColor: 'var(--white)' }} items={tabData?.map((t) => {
                return {
                    key: t?.key,
                    label: <h3 className='textSM w-500 margin0 lineHeightUnset black'>{t.label}</h3>,
                    className: "textSM",
                    style: { padding: 0, background: 'var(--white)', borderColor: "var(--grey3)" },
                    children: <Collapse bordered={false} expandIconPosition={'end'} expandIcon={({ isActive }) => <IoIosArrowDown style={{
                        transition: "0.2s linear", transform: isActive ? "rotate(180deg)" : "rotate(0deg)", fontSize: "1rem"
                    }} />} style={{ padding: 0, backgroundColor: 'var(--white)', display: "flex", flexDirection: "column", gap: "1rem" }}
                        accordion items={t?.tabs?.map((ele, j) => {
                            return {
                                key: ele?.key,
                                label: headingType === 3 ? <h3 className="margin0 textSM w-500 black lineHeightUnset">{ele.label}</h3> :
                                    <span className='textSM w-500 black'>{ele.label}</span>,
                                children: ele?.children,
                                style: { border: "1px solid var(--grey3)", borderRadius: "var(--borderRadius)" }
                            }
                        })} />
                }
            })} />
        </div>
    </>
}

export default CustomSideTabs;