import { AiOutlineCheck, AiOutlineClose, AiOutlineExclamation, AiOutlineInfo, AiOutlineWarning } from "react-icons/ai"

const CardHeader = ({ heading, hideHeaderBlueLine = false, style, textClassName = window.innerWidth < 760 ? 'textMD w-700' : 'textLG w-700', headingType = 1,
    showHeaderBlueLine = false }) => {

    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';

    return <div className="d-flex align-items-center" style={{ alignItems: "center", gap: "0.6rem", flex: 1, ...style }}>
        {showHeaderBlueLine && <div style={{ width: "5px", height: "24px", backgroundColor: "var(--primary)", borderRadius: "var(--borderRadius)" }}></div>}
        {headingType === 0 ? <div className={`${textClassName} black d-flex align-items-center margin0`}>{heading}</div>
            : headingType === 1 ? <h1 className={`${textClassName} black d-flex align-items-center margin0`}>{heading}</h1> : headingType === 2 ?
                <h2 className={`${textClassName} black d-flex align-items-center margin0`}>{heading}</h2> :
                <h3 className={`${textClassName} black d-flex align-items-center margin0`}>{heading}</h3>
        }
    </div>
}

export { CardHeader }

export const CloseIcon = ({ closeToast }) => (
    <AiOutlineClose onClick={closeToast} style={{
        padding: "0.4rem", borderRadius: "0.4rem", fontSize: "1.7rem", background: "var(--dark1)",
        color: "var(--dark4)"
    }} />
)

export const Success = () => (<AiOutlineCheck style={{
    padding: "0.25rem", borderRadius: "0.2rem", fontSize: "1.5rem", background: "var(--greenShade)",
    color: "darkgreen"
}} />)

export const Error = () => (<AiOutlineExclamation style={{
    padding: "0.25rem", borderRadius: "0.2rem", fontSize: "1.5rem", background: "var(--redShade)",
    color: "var(--red)"
}} />)

export const Warning = () => (<AiOutlineWarning style={{
    padding: "0.25rem", borderRadius: "0.2rem", fontSize: "1.5rem", background: "orange",
    color: "var(--yellowShade)"
}} />)

export const Info = (props) => (<AiOutlineInfo onClick={props.onClick} style={{
    padding: "0.25rem", borderRadius: "0.2rem", fontSize: "20px", background: "var(--blueShade)",
    color: "var(--primary)"
}} />)

export function generateUniqueKey(prefix = 'key') {
    const timestamp = new Date().getTime();
    let pre = String(prefix)?.split(' ')?.join('_')
    return `${pre}_${timestamp}_${Math.random()}`;
}