import AccessControlledPage from "Components/AccessControlledPage";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import { formatDateMMMDDYYYY } from "Components/DateFormatter";
import { NoDataFound } from "Components/NoDataFound";
import { CardHeader } from "Components/atoms/SmallComponents";
import { useIsMobile } from "CustomHooks/useIsMobile";
import { useUserDataHooks } from "CustomHooks/useUserDataHooks";
import { makeid, useGetAssetType } from "Libs/utils";
import { Badge, Card, Divider } from "antd";
import Meta from "antd/es/card/Meta";
import React from "react";
import { BiPlus } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

export default function AllChecklists() {

    const type = useGetAssetType(4);
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const user = useSelector((s) => s?.updateUserData?.value);
    const [loading, setLoading] = React.useState(true);
    const [allChecklists, setAllChecklists] = React.useState([]);
    const { getUserChecklists } = useUserDataHooks();

    const loadData = async () => {
        let res = await getUserChecklists({ type });
        setAllChecklists(res?.userChecklists || []);
        setLoading(false);
    }

    React.useEffect(() => {
        setLoading(true);
        loadData();
    }, [user]);

    const CreateNewButton = () => {
        return <button className="btnBlack d-flex align-items-center" style={{ gap: '0.5rem' }} onClick={() => {
            navigate(`${type?.basePath}/checklist`);
        }}>
            <BiPlus /> {isMobile ? '' : 'Create New'}
        </button>
    }

    return (
        <React.Fragment>
            <AccessControlledPage reqAccessLevel={1} />
            <div className="normalContainer">
                <Card>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'start' }}>
                        <div style={{ flex: 3 }}>
                            <CardHeader heading={`My checklists`} headingType={1} />
                            <div className='textXS dark3' style={{ marginTop: '0.25rem' }}>
                                View and manage your personalized stock checklists created for evaluating investment opportunities.
                            </div>
                        </div>
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                            <CreateNewButton />
                        </div>
                    </div>
                    <Divider style={{ margin: "1rem 0" }} />
                    <div>
                        {loading ? <div className="my-4">
                            <ActivityLoaderMintbox showQuote />
                        </div> : allChecklists?.length === 0 ? <div>
                            <NoDataFound msgMaxWidth='25rem' width='5rem' gap="1.5rem" bordered padding='3rem 1.25rem'
                                ctaText={<span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}><BiPlus /> Create a new stock checklist</span>}
                                ctaAction={() => {
                                    navigate(`${type?.basePath}/checklist`);
                                }}
                                message='Looks like you haven’t created any checklist. Start creating your custom checklist using a step-by-step checklist builder or an advanced query builder.' />
                        </div> : <div>
                            <div style={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr", gap: "1rem", marginBottom: '1rem' }}>
                                {allChecklists?.map((item) => {
                                    return <Badge.Ribbon key={makeid(3)} text={'Stock'} color="var(--dark4)">
                                        <Card
                                            className='shadowXS'
                                            key={makeid(3)}
                                            bordered
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                navigate(`${type?.basePath}/checklist?checklistName=${item?.checklist_name?.replaceAll(' ', '-')}&checklistId=${item?.checklist_id}&userId=${user?.id}`);
                                            }}
                                        >
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <Meta
                                                    style={{ flex: 1 }}
                                                    title={<span className='textEllipsis1'>{item?.screen_name || item?.checklist_name}</span>}
                                                    description={<span className='textXS textLight'>Created on {formatDateMMMDDYYYY(item?.created_on)}</span>}
                                                />
                                            </div>
                                        </Card>
                                    </Badge.Ribbon>
                                })}
                            </div>
                        </div>}
                    </div>
                </Card>
            </div>
        </React.Fragment>
    )
}