import * as XLSX from './xlsx';
import moment from 'moment'

function getInnerText(jsxObject) {
    if (!jsxObject) return '';
    if (jsxObject.props && jsxObject.props.children) {
        if (Array.isArray(jsxObject.props.children)) {
            return jsxObject.props.children.map(child => getInnerText(child)).join(' ');
        } else {
            return getInnerText(jsxObject.props.children);
        }
    }
    if (jsxObject.props && jsxObject.props.children === undefined) {
        return jsxObject.props.textContent || '';
    }
    if (typeof (jsxObject) === 'string') return jsxObject
    return '';
}

export const exportAntDTable = ({ fileFormat = 'xlsx', columns = [], dataSource = [], fileDefaultName = 'New table', getValueOnly = false }) => {
    const headers = columns.map((col) => col.title);
    let wsData = [headers];
    dataSource?.forEach((rowData, k) => {
        const row = [];
        columns.forEach((col) => {
            let header = col?.dataIndex
            const cellData = rowData?.[header];
            if (col?.render && !getValueOnly) {
                let target = col.render(cellData, rowData, k);
                if (target) {
                    let data = getInnerText(target);
                    data = data?.replace('\n', '');
                    row.push(data?.replaceAll('₹', 'Rs'))
                }
            }
            else {
                row.push(cellData)
            }
        })
        wsData.push(row)
    })
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const fileName = fileDefaultName + ' ' + moment(new Date()).format('DD-MMM-YYYY')
    const csvBuffer = XLSX.writeSync(ws, { bookType: 'csv', type: 'string' });
    const blob = new Blob([csvBuffer], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.csv`;
    document.body.appendChild(a); // Append anchor to body
    a.click(); // Trigger the download
    document.body.removeChild(a); // Remove anchor from body once downloaded
    URL.revokeObjectURL(url);
}

export const exportExcel = ({ fileFormat = 'xlsx', headers = [], wsData = [], fileDefaultName = 'New table' }) => {

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const fileName = fileDefaultName + ' ' + moment(new Date()).format('DD-MMM-YYYY')
    const csvBuffer = XLSX.writeSync(ws, { bookType: 'csv', type: 'string' });
    console.log(csvBuffer);
    const blob = new Blob([csvBuffer], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.csv`;
    document.body.appendChild(a); // Append anchor to body
    a.click(); // Trigger the download
    document.body.removeChild(a); // Remove anchor from body once downloaded
    URL.revokeObjectURL(url);
}
