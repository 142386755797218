import Head from 'Layout/Head';
import { Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';

export default function PageNotFound() {

    const navigate = useNavigate();

    return (
        <React.Fragment>
            <Head title='Page not found' />
            <div className='normalContainer w100 h100 justify-content-center' >
                <Result status="404" title={<div className='textXL w-700 dark4'>This isn't the page you're looking for</div>}
                    subTitle={<div className='textMD dark4'>
                        <span style={{ cursor: "pointer", color: "var(--primary)" }} onClick={() => navigate(-1)}>Head back</span>, or move along to the homepage to find a new way forward.
                    </div>} extra={<button className="btnBlack " onClick={() => navigate('/stocks/dashboard')}>Back to Dashboard</button>} />
            </div>
        </React.Fragment>
    )
}