import AccessControlledPage from 'Components/AccessControlledPage';
import BreadCrumbs from 'Components/BreadCrumbs';
import { NoDataFound } from 'Components/NoDataFound';
import UserDataFetcher from 'Components/UserDataFetcher';
import { UserDataHandler } from 'Components/UserDataHandler';
import { ViewPlanButton } from 'Components/atoms/ViewPlanButton';
import { betaAccessObj } from 'Libs/utils';
import { ErrorComponent } from 'Pages/ErrorComponent';
import Pages from "Routes/Index";
import { Layout as AntDLayout, ConfigProvider } from 'antd';
import React from "react";
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Footer2 from './Footer2';
import Header from "./Header";
import MainModals from './MainModals';

const hideHeaderPaths = ['/login', '/signup', '/get-early-access', '/technical-chart', '/broker-page', '/user-onboarding', '/onboarding'];

const hideFooterPaths = ['/login', '/signup', '/get-early-access', '/technical-chart', '/broker-page', '/user-onboarding'];

const footer2Routes = ['/onboarding', '/', '/campaign/stock-report', '/campaign/strategy', '/campaign/screener'];

const hideMobTopBannerRoutes = ['/', '/pricing', '/campaign/stock-report', '/campaign/strategy', '/campaign/screener'];

const bgWhiteRoutes = ['/login', '/signup', '/', '/about-us', '/pricing', '/privacy-policy', '/terms-and-conditions', '/refund-and-cancellation-policy', '/get-early-access',
  '/contact-us', '/user-onboarding', '/stocks/stock-detail', '/strategy-detail', '/etfs/fund-detail/', '/mutual-funds/fund-detail/', '/etfs/basket-detail/',
  '/mutual-funds/basket-detail/', '/stocks/basket-detail/', '/etfs/build-custom-strategy', '/mutual-funds/build-custom-strategy', '/stocks/build-custom-strategy',
  '/investments', '/indices/index-detail', '/event-calendar', '/etfs/calculators', '/mutual-funds/calculators', '/onboarding', '/user-profile',
  '/campaign/stock-report', '/campaign/strategy', '/campaign/screener']

const topLevelPadZeroRoutes = ['/', '/about-us', '/pricing', '/privacy-policy', '/terms-and-conditions', '/refund-and-cancellation-policy', '/contact-us', '/technical-chart',
  '/login', '/signup', '/user-onboarding', '/stocks/stock-detail', '/strategy-detail', '/etfs/fund-detail/', '/mutual-funds/fund-detail/', '/etfs/basket-detail/',
  '/mutual-funds/basket-detail/', '/stocks/basket-detail/', '/etfs/build-custom-strategy', '/mutual-funds/build-custom-strategy', '/stocks/build-custom-strategy', '/dashboard',
  '/investments', '/indices/index-detail', '/event-calendar', '/stocks/all-screens', '/etfs/calculators',
  '/mutual-funds/calculators', '/onboarding', '/user-profile', '/stocks/super-screener', '/campaign/stock-report', '/campaign/strategy', '/campaign/screener']

const bottomMarginRoutes = ['/', '/about-us', '/pricing', '/privacy-policy', '/terms-and-conditions', '/refund-and-cancellation-policy', '/contact-us', '/technical-chart',
  '/login', '/signup', '/user-onboarding', '/onboarding', '/campaign/stock-report', '/campaign/strategy', '/campaign/screener']
//  for bg white and padding 0 at top level for other routes
const otherRoutes = []

const EmptyComponent = () => (
  <NoDataFound width='5rem' marginBottom='2rem' />
)

const Layout = (props) => {
  const { pathname } = useLocation();
  const cssVar = getComputedStyle(document.documentElement);
  let user = useSelector((state) => state?.updateUserData?.value)
  const isMobile = window.innerWidth <= 575;
  let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
  let isBeta = process.env.REACT_APP_ENVIRONMENT === 'beta';

  const openBasketCreateModal = useSelector((state) => state?.basketCreateModal?.open);
  // const openPricingModal = useSelector((state) => state?.pricingModal?.value);
  const checkTopLevelPadZero = React.useCallback(() => {
    let temp = topLevelPadZeroRoutes?.find((ele) => {
      let c1 = ele === pathname?.toLowerCase();
      let c2 = pathname?.toLowerCase()?.startsWith(ele) && ele !== '/';
      return c1 || c2;
    })
    return temp ? true : false
  }, [pathname])

  const layoutBackground = React.useMemo(() => {
    let bg = '#09203f';
    let temp = bgWhiteRoutes?.find((ele) => {
      let c1 = ele === pathname?.toLowerCase();
      let c2 = pathname?.toLowerCase()?.startsWith(ele) && ele !== '/';
      return c1 || c2;
    })
    if (pathname === '/') {
      bg = '#09203f';
    }
    else if (temp) {
      bg = 'var(--white)';
    }
    else {
      bg = 'var(--background)';
    }
    return bg;
  }, [pathname]);

  const headerClassname = React.useMemo(() => {
    if (pathname === '/') {
      return 'sharpelyHeaderLPMain'
    }
    return '';
  }, [pathname]);

  return (
    <React.Fragment>
      <ConfigProvider
        renderEmpty={EmptyComponent}
        theme={{
          token: {
            colorPrimary: cssVar.getPropertyValue('--primary'),
            borderRadius: "4px",
            borderRadiusLG: "4px",
            colorTextPlaceholder: "var(--dark2)",
            fontSizeIcon: 10,
            paddingXXS: 0,
            lineHeight: 1.25,
            colorTextBase: "var(--black)",
            colorBorder: "var(--grey3)",
            fontFamily: "DM Sans"
          },
        }}
        form={{ drawer: { keyboard: false } }}
      >
        <AntDLayout style={{
          backgroundColor: layoutBackground,
          minHeight: "100vh", '--maxWidth': isDev ? "1600px" : ""
        }}>
          {!hideHeaderPaths.includes(pathname) && <div style={{ position: "sticky", top: "0px", zIndex: 1000, }} className={`${headerClassname}`} id='sharpelyHeader'>
            {isMobile && (user?.Client?.[0]?.SharpelyAccessLevel || 0) <= 1 && !(hideMobTopBannerRoutes?.includes(window.location.pathname)) &&
              <div style={{ background: "var(--dark4)", padding: "1rem 1rem", gap: "0.5rem" }} className='unlockHeader d-flex align-items-center justify-content-between'>
                <p className='textESM white w-500' style={{ margin: 0 }}>🎉&nbsp; Unlock your investment potential!</p>
                <div className='d-flex align-items-center justify-content-between' style={{ gap: "0.75rem" }}>
                  <Link id='loginHeaderBtn' to='/pricing' style={{ color: "var(--black)" }} state={{ from: pathname }}>
                    <button className='headerLogSinBtn'>{user?.Client?.[0]?.SharpelyAccessLevel > 1 ? "Upgrade" : "View Plans"}</button>
                  </Link>
                  {!user?.isLogged && <Link to='/signup' style={{ color: "var(--black)" }} id='signupHeaderBtn' state={{ from: pathname }}>
                    <button className='headerLogSinBtn' >Signup</button>
                  </Link>}
                </div>
              </div>}
            <AntDLayout.Header style={{ backgroundColor: "var(--white)", padding: 0, borderBottom: "1px solid var(--grey2)", }}>
              <Header livedata={props.livedata} />
            </AntDLayout.Header>
          </div>}
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <AntDLayout.Content style={{ flex: 1, minHeight: "100vh" }} className='d-flex flex-column'>
              <BreadCrumbs />
              <main className={'topLevelContainer flex-grow-1'} style={{
                padding: isDev ? (!checkTopLevelPadZero() ? "" : 0) : (!checkTopLevelPadZero() ? "" : 0),
                maxWidth: isDev ? (checkTopLevelPadZero() ? "" : "1600px") : "", margin: isDev ? "auto" : ""
              }}>
                <Pages livedata={props.livedata} />
              </main>
              <ViewPlanButton />
            </AntDLayout.Content>
          </ErrorBoundary>
          {!hideFooterPaths.includes(pathname) && <AntDLayout.Footer style={{ padding: 0, marginTop: !bottomMarginRoutes?.includes(pathname) ? "2rem" : "0rem" }}>
            {footer2Routes?.includes(pathname) ? <Footer2 /> : <Footer />}
          </AntDLayout.Footer>}
        </AntDLayout>
        <MainModals />
        {isBeta && user?.isLogged && <AccessControlledPage customAccessObj={betaAccessObj} isBeta={true} />}
        <UserDataHandler />
        <UserDataFetcher />
      </ConfigProvider>
    </React.Fragment >
  );
};
export default Layout;
