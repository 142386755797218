import React from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router";
import AdminServices from "Services/api/AdminServices";

const SipRebalanceScreen = () => {
    const params = useParams();
    const { orderType, encryptedStr } = params
    const [loading, setLoading] = React.useState(true)
    const [orders, setOrders] = React.useState([])
    React.useEffect(() => {
        const dem = async () => {
            const res = await AdminServices.portfolioDecrypt(encryptedStr)
            if (res.type) {
                const [clientId, basketId, goalId, wealthCreator, scheduledId] = res.data?.split(':')

                const res2 = await AdminServices.rebalancePortfolio({
                    clientId: Number(clientId) || 0,
                    basketId: Number(basketId) || 0,
                    goalId: Number(goalId) || 0,
                    wealthCreator: Number(wealthCreator) || 0,
                    isRebalance: scheduledId ? false : true,
                    scheduledId: Number(scheduledId) || 0
                })
                if (res2.type) {
                    setOrders(res2.data)
                } else {
                    toast.error('Something went wrong. Please try again later')
                }
            }
        }
        dem();
    }, [])
    return <>
        <div>Orders</div>
    </>
}
export default SipRebalanceScreen