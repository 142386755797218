import React from "react";

export const useIsMobile = () => {

    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 760);

    React.useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 760);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return isMobile;
}