import { Card, Skeleton } from "antd";
import React from "react";
import { StockScoresGauge2 } from "./StockScoresGauge2";
import { NoDataFound } from "./NoDataFound";
import { InsightIcon } from "./InsightsIcon";
import AdminServicesPY from "Services/api/AdminServicesPY";
import { useIsMobile } from "CustomHooks/useIsMobile";
import CustomSegmentedTab from "./CustomSegmentedTab";
import { IoMdRefresh } from "react-icons/io";
import moment from "moment";
import { CardHeader } from "./atoms/SmallComponents";
const TechnicalsSummary = ({ fundData, allIndicatorData, loadingTechnicalsIndicators = true, onRefresh = () => { } }) => {


    const isMobile = useIsMobile();
    const [timeFrame, setTimeFrame] = React.useState('1D')

    const TimeFrameSelector = () => {
        const timeFrameOptions = [
            {
                label: "1m",
                key: "1m",
                value: "1m",
            },
            {
                label: "5m",
                key: "5m",
                value: "5m",
            },
            {
                label: "15m",
                key: "15m",
                value: "15m",
            },
            {
                label: "30m",
                key: "30m",
                value: "30m",
            },
            {
                label: "1H",
                key: "1H",
                value: "1H",
            },
            {
                label: "1D",
                key: "1D",
                value: "1D",
            },
            {
                label: "1W",
                key: "1W",
                value: "1W",
            },
            {
                label: "1M",
                key: "1M",
                value: "1M",
            }

        ];
        return <div style={{}}>
            <CustomSegmentedTab options={timeFrameOptions} value={timeFrame} onChange={(e) => setTimeFrame(e)} isBlock={true} />
        </div>
    }

    const IndicatorComponent = ({ type, title }) => {
        var ind = allIndicatorData?.filter(x => x.type.toLowerCase() === type && x.timeframe === timeFrame);
        ind = ind.sort((a, b) => {
            if (a.sentiment + b.sentiment === -1) {
                return a.sentiment
            } else return b.sentiment - a.sentiment
        })
        if (!ind) { return <></> }

        const buySentiment = ind.reduce((accumulator, object) => {
            return object.sentiment === 1 ? accumulator + 1 : accumulator
        }, 0)
        const sellSentiment = ind.reduce((accumulator, object) => {
            return object.sentiment === -1 ? accumulator + 1 : accumulator
        }, 0)
        const total = ind.length
        var perc = 0
        if (type === 'pattern recognition') {
            var sum = buySentiment - sellSentiment
            perc = parseInt(sum > 4 ? 4 : sum < -4 ? -4 : sum / 4 * 100)
        } else {
            perc = parseInt((buySentiment - sellSentiment) / total * 100)
        }
        return <Card style={{ border: "1px solid var(--grey3)", minWidth: isMobile ? '22rem' : '16rem' }} className="flexCard" bodyStyle={{ padding: "1rem" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "0.75rem", justifyContent: "space-between" }}>
                <div className='textSM w-500'>
                    {title}
                </div>
                {/* <div style={{ color: "var(--primary)", cursor: "pointer", textAlign: "center" }} className='textXS w-500' onClick={goToTechnicals}>
                    See all &gt;
                </div> */}
            </div>
            {
                loadingTechnicalsIndicators ?
                    <>
                        <div style={{}}>
                            <StockScoresGauge2 val={0} height={170} labelSuffix={"%"} labelFont={"16px"} showTickLabels={true} paneThickness={17} min={-100}
                                max={100} isAnalystRating={true}
                                yAxisLabels={{ '-100': "Strong<br />sell", '-50': "Sell", 0: "Neutral", 50: "Buy", 100: "Strong<br />buy" }} yAxisLabelClass='analystRatingGauge'
                                tickInterval={10} tickAmount={21} showTick={false} />
                        </div>
                        <div style={{ display: "flex", gap: "0.5rem", }}>
                            <Skeleton avatar={{ size: '2.5rem' }} paragraph={{ rows: 2, width: ['90%', '70%'] }} title={false} active />
                        </div>
                    </>
                    :
                    <>
                        <div style={{}}>
                            {total ?
                                <StockScoresGauge2 val={perc} height={170} labelSuffix={"%"} labelFont={"16px"} showTickLabels={true} paneThickness={17} min={-100}
                                    max={100} isAnalystRating={true}
                                    yAxisLabels={{ '-100': "Strong<br />sell", '-50': "Sell", 0: "Neutral", 50: "Buy", 100: "Strong<br />buy" }} yAxisLabelClass='analystRatingGauge'
                                    tickInterval={10} tickAmount={21} showTick={false} />
                                : <div style={{ padding: "1rem 0" }}>
                                    <NoDataFound width='6rem' textAlign='left' message={<div className='textXS dark4' style={{ textAlign: "left" }}>
                                        Data not available.
                                    </div>} />
                                </div>}
                        </div>
                        {total ?
                            <>
                                <div style={{ display: "flex", gap: "0.5rem", }}>
                                    <div style={{ flexShrink: 0 }}>
                                        <InsightIcon type={perc <= -50 ? "Negative" : perc >= 50 ? "Positive" : "Neutral"} height="2.5rem" width="2.5rem" padding="0.6rem" />
                                    </div>
                                    <div className="textXS dark4 w-500">
                                        {
                                            type === 'ma' ?
                                                `${fundData?.proper_name}'s price on ${timeFrame} timeframe is above ${buySentiment} and below ${sellSentiment} moving averages out of ${total} moving averages.`
                                                : type === 'oscillator' ?
                                                    `${fundData?.proper_name}'s on ${timeFrame} timeframe is in bullish range for ${buySentiment} and bearish range for ${sellSentiment} oscillators out of ${total} oscillators.`
                                                    : type === 'pattern recognition' ?
                                                        `${fundData?.proper_name} on ${timeFrame} timeframe indicates ${buySentiment} bullish patterns and ${sellSentiment} bearish patterns out of ${total} candlestick patterns.`
                                                        : ''
                                        }
                                    </div>
                                </div>
                            </> : null}
                    </>
            }
        </Card>
    }
    const RefreshComponent = ({ last_date }) => {
        console.log("Laste", last_date)
        return <>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', alignItems: 'end' }}>
                <div className="d-flex align-items-center shadowSM textXS w-500 white" onClick={() => onRefresh()}
                    style={{
                        gap: "0.5rem", background: "var(--black)", borderRadius: "5rem", padding: "0.5rem 1rem",
                        cursor: "pointer", width: "fit-content", alignSelf: 'end'
                    }}>
                    <IoMdRefresh style={{ fontSize: '0.9rem' }} /> Refresh
                </div>
                {last_date?.length ? <div className="textXS dark2">Last updated: {isMobile ? moment(last_date).format("Do MMM, h:mm a") : moment(last_date).format("Do MMM YY, h:mm a")}</div> : ''}
            </div>
        </>
    }
    return (
        <div style={{ marginBottom: '1.25rem' }}>
            <div style={{ display: "flex", flexDirection: isMobile ? 'column-reverse' : 'row', alignItems: isMobile ? "unset" : "center", justifyContent: "space-between", marginBottom: '0.5rem', gap: '1rem' }}>
                <TimeFrameSelector />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                    {isMobile && <CardHeader heading={'Technical indicators'} headingType={2} />}
                    <RefreshComponent last_date={allIndicatorData?.filter(i => i?.timeframe === timeFrame)?.[0]?.timestamp} />
                </div>
            </div>
            <div style={{ display: 'flex', overflowX: 'scroll', gap: '1rem' }}>
                <IndicatorComponent type={'oscillator'} title={"Oscillators"} />
                <IndicatorComponent type={'ma'} title={"Moving averages"} />
                <IndicatorComponent type={"pattern recognition"} title={"Patterns"} />
            </div>
        </div>
    );
};

export default TechnicalsSummary;