import AMCIcon from "Components/AMCIcon";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import DashboardTools from "Components/DashboardTools";
import { formatDateyyyymmdd } from 'Components/DateFormatter';
import { CardHeader } from "Components/atoms/SmallComponents";
import { useIsMobile } from 'CustomHooks/useIsMobile';
import Head from "Layout/Head";
import { fetchFundData } from "Libs/fetchFundData";
import { getDivHeight } from "Libs/scroll";
import { makeid, useGetAssetType } from 'Libs/utils';
import apiHelperPY from "Services/api/ApiHelperPY";
import { Badge, Card, Checkbox, Divider, Drawer, Select, Spin, Tag } from 'antd';
import moment from 'moment';
import { default as React, useState } from "react";
import toast from "react-hot-toast";
import { BsArrowRightShort } from "react-icons/bs";
import { MdFilterAlt } from "react-icons/md";
import { VscFilePdf } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import { NoDataFound } from "Components/NoDataFound";
import AccessControlledPage from "Components/AccessControlledPage";
import AccessComponent from "Components/AccessComponent";
import UpgradeToPro from "Components/UpgradeToPro";

function WatchlistFeed({ isWidget = false, allStocks }) {

    const isMobile = useIsMobile();
    const navigate = useNavigate()
    const [allFilteredFundsData, setallFilteredFundsData] = useState([])
    const [showFundsInDateRange, setShowFundsInDateRange] = useState(true);
    const [loading, setloading] = useState(false)
    const [feedLoading, setFeedLoading] = useState(true)
    const [moreloading, setmoreLoading] = useState(false)
    const user = useSelector((state) => state?.updateUserData?.value)


    var type = useGetAssetType(4)

    const today = new Date()
    today.setDate(today.getDate() - 6)
    const [start_date, setstart_date] = useState(formatDateyyyymmdd(today))
    const [end_date, setend_date] = useState(formatDateyyyymmdd(new Date()))

    const [selectedTags, setSelectedTags] = React.useState([])
    const [allTags, setAllTags] = React.useState([])
    const [stockFeed, setStockFeed] = React.useState([])
    const [events, setEvents] = React.useState([])
    const [selectedOption, setSelectedOption] = React.useState({})
    const [options, setOptions] = React.useState([])
    const [allConstituents, setAllConstituents] = React.useState([])
    const [openFilterModal, setOpenFilterModal] = React.useState(false)
    let watchlist = useSelector((state) => state?.userWatchlists?.value);
    const [allFunds, setAllFunds] = React.useState([]);

    const colors = {
        'company update': 'geekblue',
        'corporate action announcement': 'purple',
        'financial results': 'gold',
        'eps downgrade': 'volcano',
        'board meeting': 'orange',
        'eps upgrade': 'green',
        'insider trading / sast': 'magenta',
        'agm/egm': 'blue',
        'block deal': 'magenta',
        'target price downgrade': 'red',
        'dividend': 'green',
        'target price upgrade': 'lime',
        'rights issue': 'gold'
    }

    React.useEffect(() => {
        const dem = async () => {
            let funds = await fetchFundData(4);
            if (funds) {
                setAllFunds(funds)
            }
        }
        if (isWidget) {
            setAllFunds(allStocks)
        } else {
            dem()
        }
    }, [])

    React.useEffect(() => {
        const dum = async () => {
            //console.log("omk", watchlist)
            // let ind = await getIndexList();
            // ind = ind?.map(i => { return { 'key': i.Id, 'label': i.IndexName, 'value': i.IndexNameCaps, isWatchlist: false } })
            let wat = watchlist?.filter(w => w.FundType === 4)?.map(w => { return { ...w, 'key': 'w_' + w.WatchListId, 'label': w.Name, 'value': w.Name, isWatchlist: true } }) || []
            // let constituents = await apiHelperPY({ apiName: "getIndexConstituents", saveResponse: "" })
            const opts = [...wat]
            setOptions(opts)
            // setAllConstituents(JSON.parse(constituents))
            setSelectedOption(opts[0])
            setloading(false)
        }
        if (watchlist || !user?.isLogged) {
            dum();
        }
    }, [watchlist, user])

    const getStocksFeed = async (st = undefined, end = undefined, isLoadMore = false) => {
        const data = {
            'syms': selectedOption?.isWatchlist ? watchlist?.find(w => w.Name === selectedOption?.value && w.FundType === 4)?.FundCodes : allConstituents[selectedOption?.value]?.syms,
            'from_date': isLoadMore ? st : start_date,
            'to_date': isLoadMore ? end : end_date
        }
        if (!data?.syms?.length) {
            setStockFeed([])
            setEvents([])
            setFeedLoading(false)
            return
        }
        let res = await apiHelperPY({ apiName: "getStocksFeed", data: data, saveResponse: "" })
        if (res) {
            //console.log(JSON.parse(res))
            let fe = JSON.parse(res)?.feeds
            let ev = JSON.parse(res)?.events

            setStockFeed(isLoadMore ? [...stockFeed, ...fe] : fe)
            setEvents(ev)
            const all = [...new Set(fe.map(f => f.tag))]
            setAllTags([...new Set([...allTags, ...all])])
            if (!selectedTags?.length) {
                setSelectedTags(all)
            }
            setFeedLoading(false)
            if (isLoadMore) {
                setmoreLoading(false)
            }
        } else {
            toast.error('Something went wrong.')
            setFeedLoading(false)
            setmoreLoading(false)
        }
    }

    React.useEffect(() => {
        if (Object.keys(selectedOption || {})?.length) {
            setFeedLoading(true);
            getStocksFeed();
        }
    }, [selectedOption])

    const FeedCard = ({ data }) => {
        let fund = allFunds?.find((el) => el?.symbol === data?.symbol);
        return <Card
            className="shadowXS"
            key={makeid(3)}
            bordered
            style={{ marginBottom: '1rem' }}
            onClick={() => {
                // navigate(`/stocks/super-screener?screenName=${item?.screen_name?.replaceAll(' ', '-')}&screenId=${item?.screen_id}&userId=${item?.client_id || 0}`)
            }}
        >
            <div className='mb-3' style={{ display: 'flex', gap: '0.75rem', width: '100%' }}>
                <AMCIcon amcName={fund?.symbol} bseTicker={fund?.bse_ticker} fundType={4} height='2.75rem' width='2.75rem' type="square" />
                <div style={{ flex: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flex: 1, gap: '1rem' }}>
                            <div className='textMD w-500 black textEllipsis1'>{fund?.proper_name}</div>
                            {!isMobile && <div><Tag
                                // color={colors[data?.tag?.toLowerCase()]}
                                color={'var(--grey1)'}
                                style={{ color: 'var(--dark4)', fontWeight: '500' }}
                                bordered={false}
                            >
                                {data?.tag}
                            </Tag></div>}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {data?.timestamp && <div className="textXS w-400 dark3" style={{ whiteSpace: 'nowrap' }}>
                                {moment(data?.timestamp).format('Do MMM YY')}
                            </div>}
                            {data?.pdf_link ? <div style={{ marginLeft: '0.75rem' }}>
                                <a href={data?.pdf_link} target='_blank'>
                                    <VscFilePdf size={'1.25rem'} color="var(--red)" />
                                </a>
                            </div> : <div style={{ width: '2rem' }}></div>}
                        </div>
                    </div>
                    <div className="textXS w-400 dark3" style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>{data?.category} {data?.show_subcat ? <><BsArrowRightShort size={'0.75rem'} /> {data?.sub_category}</> : ''}</div>
                </div>
            </div>
            <div className="textSM w-500 textEllipsis2 mb-2" style={{ width: isMobile ? '100%' : '95%', paddingLeft: '3.5rem' }}>
                {data?.headline}
            </div>
            <div className='textXS textEllipsis2' style={{ width: isMobile ? '100%' : '90%', paddingLeft: '3.5rem' }}>
                {data?.description}
            </div>
        </Card>
    }

    const tagRender = (props) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color={colors[value.toLowerCase()]}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginInlineEnd: 4 }}
            >
                {label}
            </Tag>
        );
    };

    const onLoadMore = () => {
        let new_st_date = new Date(start_date)
        let new_end_date = new Date(start_date)
        new_st_date.setDate(new_st_date.getDate() - 7)
        new_end_date.setDate(new_end_date.getDate() - 1)
        setmoreLoading(true);
        getStocksFeed(formatDateyyyymmdd(new_st_date), formatDateyyyymmdd(new_end_date), true)
        setstart_date(formatDateyyyymmdd(new_st_date))
    }
    const [top, setTop] = React.useState(``)
    React.useEffect(() => {
        if (getDivHeight('#omkar') > window.innerHeight - getDivHeight('#sharpelyHeader')) {
            setTop(`calc(100% - ${getDivHeight('#omkar')}px - ${getDivHeight('#sharpelyHeader')}px + 3rem)`)
        } else {
            setTop(`calc(${getDivHeight('#sharpelyHeader')}px + 1.5rem`)
        }
    }, [events]);

    const Events = () => {
        let dates = [...new Set(events?.map(e => e.timestamp))]
        return <Card bodyStyle={{ minHeight: 200, padding: 0 }} headStyle={{ padding: "1.25rem", height: "fit-content" }}
            title={<CardHeader heading='Upcoming events' />}>
            {
                events?.length ? dates.map(date => {
                    return <>
                        <Divider children={<div className="textXS w-500 dark3">{moment(date).format('Do MMM YYYY')}</div>} style={{ marginTop: '12px', marginBottom: '0' }} />
                        {
                            events.filter(e => e.timestamp === date)?.map((f, i) => {
                                let fund = allFunds?.find((el) => el.symbol === f.symbol)
                                if (fund) {
                                    return (
                                        <div key={i} style={{ backgroundColor: i % 2 !== 0 ? 'var(--white)' : 'var(--white)', borderBottom: '1px solid var(--grey2)' }}>
                                            <Link to={type.basePath + `${'/stock-detail/' + fund?.proper_name?.replaceAll(' ', '-') + '/' + fund?.symbol}`}>
                                                <div className={"pad20Side12VerContainer"}
                                                    style={{ display: "flex", gap: "0.5rem", alignItems: "center" }} >
                                                    <AMCIcon amcName={fund?.symbol} fundType={4} height='1.5rem'
                                                        width="1.5rem" bseTicker={fund?.bse_ticker} />
                                                    <div className="textXS black hoverBold" style={{
                                                        flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                                                        overflow: 'hidden'
                                                    }}>
                                                        {fund?.proper_name}
                                                    </div>
                                                    <span className="textXS w-500 dark3" >
                                                        {f?.purpose}
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                }
                                else return null;
                            })
                        }
                    </>
                })
                    :
                    <NoDataFound message={`No upcomming events for selected stocks`} gap='3rem' />
            }
        </Card>
    }
    if (loading) {
        return <React.Fragment>
            <div className='loaderContainer' >
                <ActivityLoaderMintbox showQuote={true} />
            </div>
        </React.Fragment>
    } else {
        return (
            <React.Fragment>
                {!isWidget && <Head title='Watchlist feed - Financial Results | Insider Trading/SAST' description="sharpely provides an watchlist feed for corporate actions, financial results, insider trading (SAST), curated baskets, and in-depth insights on stocks, mutual funds, and ETFs" />}
                {!isWidget && <AccessControlledPage moduleKey='MULTIPLE_WATCHLISTS' subModalParams={{
                    customAccessObj: {
                        ModuleName: "Watchlist Feed",
                        ModuleDesc: "Get curated announcements and upcoming events for your watchlisted stocks. Stay informed with tailored insights.",
                    },
                    hideDescription: true
                }} />}
                <div className={isWidget ? '' : "mainContainer"}>
                    <div className={isWidget ? '' : "leftContainer"}>
                        <Card bordered={false} style={{ flex: (isWidget || (watchlist !== undefined && Array.isArray(watchlist) && watchlist?.filter(w => w.FundType === 4)?.length === 0)) ? "" : 1 }} headStyle={{ padding: "1.25rem", height: "fit-content" }}
                            title={<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <CardHeader heading={'Watchlist feed'} headingType={isWidget ? 2 : 1} />
                                    <AccessComponent component={
                                        <div className="customTabsSubHeading w-400" style={{ marginBottom: 0 }}>Showing data for last {moment(end_date).diff(moment(start_date), 'days') + 1} days</div>
                                    } moduleKey='MULTIPLE_WATCHLISTS' upgradeComponent={null} />
                                </div>
                                <AccessComponent component={
                                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', height: 'fit-content' }}>
                                        {/* <div className='textMD w-500 black mb-2'>Select watchlist</div> */}
                                        <Select options={options} style={{ display: 'block', width: '10rem' }}
                                            value={selectedOption?.value} onChange={(val, obj) => {
                                                //console.log(obj)
                                                setSelectedOption(obj)
                                            }}
                                        />
                                        {isMobile && <Badge><MdFilterAlt size={'20px'} onClick={() => { setOpenFilterModal(true) }} /></Badge>}
                                    </div>
                                } moduleKey='MULTIPLE_WATCHLISTS' upgradeComponent={null} />
                            </div>}>
                            <AccessComponent component={
                                <div style={{ display: 'flex', gap: '1rem' }}>
                                    {watchlist !== undefined && Array.isArray(watchlist) && watchlist?.filter(w => w.FundType === 4)?.length === 0 ? <div className="my-4">
                                        <div className="textSM dark3 mb-1">No Stock watchlists.</div>
                                        <div className="textSM dark3 mb-1">Get curated announcements and upcoming events for your watchlisted stocks. Stay informed with tailored insights.</div>
                                        <div className="textSM dark3">Start by creating your first stock watchlist.</div>
                                    </div> : <>
                                        {!isMobile && <div style={{ width: '175px', position: "sticky", top: `calc(${getDivHeight('#sharpelyHeader')}px + 1.5rem)`, alignSelf: "flex-start" }}>
                                            {/* <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }} /> */}
                                            <div className='textMD w-500 black mb-2' style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}><MdFilterAlt size={'1rem'} /> Filter tags</div>
                                            <Checkbox checked={selectedTags?.length === allTags?.length} onChange={(e) => {
                                                if (!e.target.checked) {
                                                    setSelectedTags([]);
                                                }
                                                else {
                                                    setSelectedTags(JSON.parse(JSON.stringify(allTags)));
                                                }
                                            }}>Select all</Checkbox>
                                            <Checkbox.Group style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem', marginTop: '1rem' }}
                                                options={allTags.map(t => {
                                                    return {
                                                        // label: <Tag
                                                        //     // color={colors[t?.toLowerCase()]}
                                                        //     color={'var(--grey1)'}
                                                        //     style={{ color: 'var(--dark4)' }}
                                                        //     className="textXS"
                                                        // >
                                                        //     {t?.replace('Corporate', 'Corp.')?.replace('announcement', 'ann')} ({stockFeed.filter(x => x.tag === t)?.length})
                                                        // </Tag>,
                                                        label: <div
                                                            style={{ color: 'var(--dark4)' }}
                                                            className="textXS"
                                                        >
                                                            {t?.replace('Corporate', 'Corp.')?.replace('announcement', 'ann')} ({stockFeed.filter(x => x.tag === t)?.length})
                                                        </div>,
                                                        value: t
                                                    }
                                                })}
                                                value={selectedTags} onChange={(c) => setSelectedTags(c)}
                                            />
                                        </div>}
                                        <div style={{ flex: 1, height: isWidget ? '500px' : 'unset', overflow: 'auto' }}>
                                            {feedLoading ? <div className='' >
                                                <ActivityLoaderMintbox showQuote={true} />
                                            </div> :
                                                <>
                                                    {
                                                        [...new Set(stockFeed?.filter(feed => selectedTags.includes(feed?.tag))?.map(e => new Date(e.timestamp).toDateString()))].map(date => {
                                                            return <>
                                                                <Divider children={<div className="textSM w-500 dark3">{moment(date).format('Do MMM YYYY')}</div>} style={{ marginTop: '0.5rem' }} />
                                                                {
                                                                    stockFeed?.filter(feed => (new Date(feed.timestamp).toDateString() === date && selectedTags.includes(feed?.tag)))?.map((feed, i) => {
                                                                        return <FeedCard data={feed} />
                                                                    })
                                                                }
                                                            </>
                                                        })
                                                    }
                                                    <div style={{ textAlign: 'center' }}>
                                                        <button className="btnBlack" style={{ opacity: moreloading ? 0.8 : 1 }}
                                                            disabled={moreloading}
                                                            onClick={() => onLoadMore()}>
                                                            Load more {moreloading ? <Spin style={{ marginLeft: '4px' }} indicator={<LoadingOutlined style={{ fontSize: 12 }} spin />} /> : <></>}
                                                        </button>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </>}
                                </div>
                            } moduleKey='MULTIPLE_WATCHLISTS' upgradeComponent={<UpgradeToPro moduleKey='MULTIPLE_WATCHLISTS'
                                customDesc="Get curated announcements and upcoming events for your watchlisted stocks. Stay informed with tailored insights." />} />
                        </Card>
                    </div>
                    {
                        !isWidget && <div id="omkar" className="rightContainer" style={{ position: "sticky", top: top, alignSelf: "flex-start" }}>
                            <DashboardTools hideInsTypeTabs defInstType={4} defToolType={'Watchlist'} isBordered={false} showToolHeading={true} type="watchlist" defaultSelectedStockWatchlist={selectedOption} />
                            {watchlist !== undefined && Array.isArray(watchlist) && watchlist?.filter(w => w.FundType === 4)?.length === 0 ? null : <Events />}
                        </div>
                    }
                </div >
                {isMobile && openFilterModal && <Drawer
                    title="Filter tags"
                    onClose={() => setOpenFilterModal(false)}
                    open={openFilterModal} placement="bottom" closable={false}>
                    <div>
                        <Checkbox checked={selectedTags?.length === allTags?.length} onChange={(e) => {
                            if (!e.target.checked) {
                                setSelectedTags([]);
                            }
                            else {
                                setSelectedTags(JSON.parse(JSON.stringify(allTags)));
                            }
                        }}>Select all</Checkbox>
                        <Checkbox.Group style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem', marginTop: '1rem' }}
                            options={allTags.map(t => {
                                return {
                                    // label: <Tag
                                    //     // color={colors[t?.toLowerCase()]}
                                    //     color={'var(--grey1)'}
                                    //     style={{ color: 'var(--dark4)' }}
                                    //     className="textXS"
                                    // >
                                    //     {t?.replace('Corporate', 'Corp.')?.replace('announcement', 'ann')} ({stockFeed.filter(x => x.tag === t)?.length})
                                    // </Tag>,
                                    label: <div
                                        style={{ color: 'var(--dark4)' }}
                                        className="textXS"
                                    >
                                        {t?.replace('Corporate', 'Corp.')?.replace('announcement', 'ann')} ({stockFeed.filter(x => x.tag === t)?.length})
                                    </div>,
                                    value: t
                                }
                            })}
                            value={selectedTags} onChange={(c) => setSelectedTags(c)}
                        /></div>
                </Drawer>}

            </React.Fragment >
        );
    }

}

export default React.memo(WatchlistFeed);