import { sortArrObject } from "Libs/utils";
import toast from "react-hot-toast";

export const priceValues = ['open', 'high', 'low', 'close', 'volume']

export const priceItems = [{ key: 0, label: "Open", value: "open" }, { key: 1, label: "High", value: "high" }, { key: 2, label: "Low", value: "low" },
{ key: 3, label: "Close", value: "close" }, { key: 4, label: "Volume", value: "volume" }]

export const stringParser = (inputString = '') => {
    try {
        let jsonString = inputString?.replace(/'(?=(?:(?:[^"]*"){2})*[^"]*$)/g, '"');
        return JSON.parse(jsonString);
    }
    catch (err) {
        return []
    }
}

export const directionTypes = [
    { key: 0, label: "Higher the better", value: 1 },
    { key: 1, label: "Lower the better", value: 0 }
]

export const maTypes = [
    { key: 0, label: "SMA", value: 0 },
    { key: 1, label: "EMA", value: 1 },
    { key: 2, label: "WMA", value: 2 },
    { key: 3, label: "DEMA", value: 3 },
    { key: 4, label: "TEMA", value: 4 },
    { key: 5, label: "TRIMA", value: 5 },
    { key: 6, label: "KAMA", value: 6 },
    { key: 7, label: "MAMA", value: 7 },
    { key: 8, label: "T3", value: 8 }
]

export const groupColumnNames = [
    {
        key: "SEC",
        value: `"SEC"`,
        label: "Sector",
        items: ['timestamp', 'sector_code'],
        tabType: "CrossSection"
    },
    {
        key: "INDGRP",
        value: `"INDGRP"`,
        label: "Industry group",
        items: ['timestamp', 'industry_code'],
        tabType: "CrossSection"
    },
    {
        key: "IND",
        value: `"IND"`,
        label: "Industry",
        items: ['timestamp', 'nse_basic_ind_code'],
        tabType: "CrossSection"
    },
    {
        key: "MKT",
        value: `"MKT"`,
        label: "Universe",
        items: ['timestamp'],
        tabType: "CrossSection"
    },
    {
        key: "F",
        value: `"F"`,
        label: "Years",
        items: ['symbol', 'fiscal_year_end'],
        tabType: "History"
    },
    {
        key: "Q",
        value: `"Q"`,
        label: "Quarters",
        items: ['symbol', 'quarter_end'],
        tabType: "History"
    },
    {
        key: "M",
        value: `"M"`,
        label: "Months",
        items: ['symbol', 'month_end'],
        tabType: "History"
    },
    {
        key: "D",
        value: `"D"`,
        label: "Days",
        items: ['symbol'],
        tabType: "History"
    }

]

export const allowedHistoryOptionsAndFrequencies = [
    { frequency: "F", allowedFields: [`"F"`], value: [`"F"`] },
    { frequency: "T", allowedFields: [`"F"`, `"Q"`], value: [`"TY"`, `"TQ"`] },
    { frequency: "Q", allowedFields: [`"F"`, `"Q"`], value: [`"QY"`, `"QQ"`] },
    { frequency: "M", allowedFields: [`"F"`, `"M"`], value: [`"MY"`, `"MM"`] },
    { frequency: "D", allowedFields: [`"D"`], value: [`"D"`] }
]
export const mathOperators = [
    { key: 0, label: '+', value: "+", type: "mathOperator" }, { key: 1, label: "-", value: "-", type: "mathOperator" },
    { key: 2, label: "*", value: "*", type: "mathOperator" }, { key: 3, label: '/', value: "/", type: "mathOperator" }, { key: 4, label: '^', value: "^", type: "mathOperator" }
];

export const comparators = [
    { key: 0, label: "<", value: "<", basic_exp: "is less than", type: "comparator" }, { key: 1, label: ">", value: ">", basic_exp: "is greater than", type: "comparator" },
    { key: 2, label: "<=", value: "<=", basic_exp: "is less than or equal to", type: "comparator" },
    { key: 3, label: "=", value: "==", basic_exp: "is equal to", type: "comparator" },
    { key: 4, label: ">=", value: ">=", basic_exp: "is greater than or equal to", type: "comparator" },
    { key: 5, label: "is between", value: "<>", basic_exp: "is between", type: "comparator" },
    // { key: 10, label: "crosses", value: "crosses", type: "comparator" },
    { key: 8, label: "crosses above", value: "crosses_above", type: "comparator" }, { key: 9, label: "crosses below", value: "crosses_below", type: "comparator" }
]

export const basic_comparators = [
    { key: 0, label: "is less than", value: "<", basic_exp: "is less than", type: "comparator" }, { key: 1, label: "is greater than", value: ">", basic_exp: "is greater than", type: "comparator" },
    { key: 2, label: "is less than or equal to", value: "<=", basic_exp: "is less than or equal to", type: "comparator" },
    { key: 3, label: "is equal to", value: "==", basic_exp: "equal to", type: "comparator" },
    { key: 4, label: "is greater than or equal to", value: ">=", basic_exp: "is greater than or equal to", type: "comparator" },
    { key: 5, label: "is between", value: "<>", basic_exp: "is between", type: "comparator" },
    // { key: 10, label: "crosses", value: "crosses", type: "comparator" },
    { key: 8, label: "crosses above", value: "crosses_above", type: "comparator" }, { key: 9, label: "crosses below", value: "crosses_below", type: "comparator" }
]

export const conditionOperators = [
    { key: 0, label: "AND", value: "and", type: "conditionOperator" }, { key: 1, label: "OR", value: "or", type: "conditionOperator" }
]

export const brackets = [
    { key: 0, label: "(", value: "(", type: "Brackets" },
    { key: 0, label: ")", value: ")", type: "Brackets" },
]

export const openingBracket = { key: 0, label: "(", value: "(", type: "Brackets" };
export const closingBracket = { key: 0, label: ")", value: ")", type: "Brackets" };

export const multipleValueComp = [
    { key: 0, label: "includes", value: "is_in", type: "comparator" },
    { key: 1, label: "excludes", value: "not_in", type: "comparator" },
]

export const customComparators = [{ key: 5, label: "is between", value: "<>", type: "comparator" },];

export const multipleTypeMetrics = ["sector_code", "industry_code", "nse_basic_ind_code", "style_box_code"];

export const timeframes = [
    { key: 1, value: "1d", label: "1 day" },
    // { key: 2, value: "3d", label: "3 days", disabled: true },
    { key: 3, value: "1w", label: "1 week" },
    // { key: 4, value: "2w", label: "2 weeks", disabled: true },
    { key: 5, value: "1M", label: "1 month" },
]

export const metricTypeTabs = [
    { key: 0, label: "All", value: "" },
    { key: 1, label: "Fundamental Metrics", value: "Metric F" },
    { key: 2, label: "Technical Metrics", value: "Metric T" },
    { key: 3, label: "Custom Metrics", value: "Variable" }
]

export const inverseW = [
    { key: 0, label: "Actual values", value: 0 },
    { key: 1, label: "Inverse values", value: 1 }
]

export const sortBy = [
    { key: 0, label: "Ascending order", value: "asc" },
    { key: 1, label: "Descending order", value: "desc" }
]

export const initBacktestConfig = {
    'bar_timeframe': '1D',
    'initial_capital': 100000,
    'schedule': {
        'freq': '3M',
        'day': 1,
        'month': 1
    },
    'position_sizing': {
        'method': "W-EQ",
        'max_num_not_met': 'full_allocation',
        'is_inverse': 0,
        'min_num': 1
    },
    'fill_on': 'open_next',
    'on_no_signal': 'cash',
    'benchmark_id': 7,
    'benchmark_name': 'NIFTY 50 TRI',
    'sell_on_surveillance': 0
}

export const schedule = {
    'schedule': {
        'freq': '3M',
        'day': 1,
        'month': 1
    },
}

export const risk_management = {
    'model': 'TSL',
    'trail_sl_percent': 0.2
}


export const transaction_cost = {
    "brokerage_model": "ZERODHAEQDEL"
}


export const transaction_cost_custom_per = {
    "brokerage_model": "CUSTOMEQDEL",
    "brokerage_params": { "eq_del_percentage": 0.2 }
}

export const transaction_cost_custom_rs = {
    "brokerage_model": "CUSTOMEQDEL",
    "brokerage_params": { "eq_del_fixed": 20 }
}

export const risk_management_TSL = {
    'model': 'TSL',
    'trail_sl_percent': 0.2
}

export const risk_management_FSTP = {
    'model': 'FSTP',
    'fixed_sl_percent': 0.2,
    'fixed_bp_percent': 0.3
}

export const risk_management_FSDP = {
    'model': 'FSDP',
    'fixed_sl_percent': 0.2,
    'drawdown_trigger_percent': 0.5,
    'drawdown_percent_stop': 0.1
}


export const conditionBlockWrappers = [
    {
        "key": "is_true_now",
        "value": "is_true_now",
        "label": "Condition is true now",
    }, {
        "key": "is_true_any_nbars",
        "value": "is_true_any_nbars",
        "label": "Condition was true anytime in last N candles",
    }, {
        "key": "is_true_all_nbars",
        "value": "is_true_all_nbars",
        "label": "Condition was true in all last N candles",
    }, {
        "key": "was_true_nbars_ago",
        "value": "was_true_nbars_ago",
        "label": "Condition was true N candles ago",
    }
]

export const sortOptions = [
    {
        value: 'and',
        queryStr: 'and',
        label: 'AND',
        // className: 'segmentedAnd'
    },
    {
        value: 'or',
        queryStr: 'or',
        label: 'OR',
        // className: 'segmentedOr'
    }
]


// Code parser
var akkaa = "akakaka"
export const codeParserWrapper = async ({ code = "", screenerMetrics = {}, functions = {}, isSignal = false }) => {
    const condParser = async (conditions, isVar = false, variables = {}) => {
        const globalVars = ['universe', 'exchange', 'exclusion_list']
        var splitters = [...comparators?.reverse(), ...mathOperators, ...sortOptions, ...multipleValueComp]?.map((i) => i?.value)
        const escapedOperators = splitters.map(operator => operator.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
        // console.log(akkaa, escapedOperators)
        console.log(akkaa, escapedOperators)
        const regexPattern = new RegExp(`\\s{1,4}(${escapedOperators.join('|')})\\s{1,4}`, 'g');

        var finalCondition = {}

        const isGlobal = (str) => {
            ////////console.log(logg, 2345, str)
            const regexPattern_cond_oper = new RegExp(`\\s{0,4}(${globalVars.join('|')})\\s{0,4}`, 'g');
            return str?.match(regexPattern_cond_oper)?.length > 0
        }


        const parseVariable = (expression) => {
            var cc = [{
                "key": "set_negative_to_na",
                "value": "set_negative_to_na",
                "label": "set_negative_to_na",
            }]
            const regexPattern_cond_wrap = new RegExp(`${cc.map(operator => operator?.value).join('|')}`, 'g');
            console.log("sadsadads =>>>>>> ", expression, String(expression).match(regexPattern_cond_wrap), regexPattern_cond_wrap)
            if (String(expression).match(regexPattern_cond_wrap)) {
                return true
            }

            return false
            // return {
            //     expression: String(expression),
            //     expression_arr: String(expression),
            //     type: "variable",
            //     // expression_details: expression_details
            // }
        }

        ////////console.log("xyxy", Object.keys(variables), allVariables)
        await conditions?.map((cond, index) => {
            // var cond_name = String(cond)?.match(/c\d{1,4} =/)?.[0]
            var allVariables = (isVar ? Object?.values(finalCondition)?.map((i) => i?.name) : Object?.values(variables)?.map((i) => i?.name)) || []

            var cond_name = String(cond)?.match(/.*?\s{0,4}=\s{0,4}.*?/)?.[0]
            var cond_exp = String(cond)?.replaceAll(cond_name, "")?.replace(/\s{2,}/g, ' ').trim()
            cond_name = cond_name?.replace(/ {0,4}= {0,4}/g, "")

            const regexPattern_cond_wrap2 = new RegExp(`\\([^()]*\\)`, 'g');
            const regexPattern_cond_wrap22 = new RegExp(`\\w+\\([^()]*\\)`, 'g');


            // console.log("AKAK", cond_name, cond_exp)
            console.log(akkaa, cond_name, cond_exp)
            const regexPattern_cond_wrap = new RegExp(`${conditionBlockWrappers.map(operator => operator?.value).join('|')}`, 'g');
            if (cond_exp?.match(regexPattern_cond_wrap)) {
                var wrapperNm = cond_exp?.match(regexPattern_cond_wrap)?.[0]
                var expParserReg = new RegExp(`${wrapperNm}\\(\\((.*?)\\),(.*)\\)`);
                var match = cond_exp?.match(expParserReg)
                // console.log(wrapperNm, expParserReg, match)
                console.log(akkaa, wrapperNm, expParserReg, match)
                if (match) {
                    const expression = match[1].trim();
                    const noOfBars = match[2].trim();

                    // var queries_orp = cond_exp?.match(regexPattern)
                    var queries_exp = String(expression)?.split(regexPattern)
                    console.log(akkaa, queries_exp)
                    queries_exp = queries_exp?.map((item) => {
                        var isStartBracket = item?.includes("(") ? 1 : 0
                        var isEndBracket = item?.includes(")") ? 1 : 0
                        var totalSum = isStartBracket + isEndBracket
                        console.log(akkaa, totalSum, isStartBracket, isEndBracket, item, item?.split(regexPattern_cond_wrap22), (item?.match(regexPattern_cond_wrap22)))
                        if (totalSum === 2 || totalSum === 0) {
                            //normal func
                            var ignorel1 = 0
                            var l1 = (item?.split(regexPattern_cond_wrap22) || [])?.map((i) => {
                                if (["(", ")"]?.includes(i?.trim())) {
                                    ignorel1++
                                }
                                return i?.trim()
                            })
                            var l2 = item?.match(regexPattern_cond_wrap22) || []

                            return ignorel1 === 2 ? [l1[0], ...l2, l1[1]] : l1?.includes("(") ? [...(l1), ...(l2)] : [...(l2), ...(l1)]
                        } else if (totalSum === 1 || totalSum > 2) {
                            // bracket .. split
                            console.log(akkaa, item)
                            const regexPattern = new RegExp(`\\s{0,4}(\\(|\\))\\s{0,4}`, 'g');
                            return item?.split(regexPattern)
                        }
                    })
                    queries_exp = queries_exp?.flat(1)?.filter((entry) => String(entry).trim()?.length != 0);
                    console.log(akkaa, wrapperNm, expression, queries_exp)
                    var queries = {}
                    var skipNext = false
                    var prevExpKey = ''
                    for (let i = 0; i < queries_exp.length; i++) {
                        if (skipNext) {
                            skipNext = false
                            // break;
                        } else {
                            const expression = queries_exp[i]?.replace(/\s{2,}/g, ' ').trim();
                            var match = expression?.match(/([a-z_0-9]+)\((.*?)\)/)

                            var query_metric_name = queries_exp?.[i]?.replace(/\s{2,}/g, '').trim()
                            //////console.log(query_metric_name);
                            var label = [...mathOperators, ...comparators, ...conditionOperators, ...multipleValueComp]?.filter((i) => i?.value === query_metric_name)?.[0]?.label

                            console.log(akkaa, match, expression)
                            if (match) {
                                ////////////////console.log(logg, "d")
                                var query_metric_name = match?.[1]?.replace(/\s{2,}/g, ' ').trim()
                                console.log(akkaa, query_metric_name)
                                var expression_details = functions?.filter((i) => i?.key === query_metric_name)?.[0]
                                var expression_arr = match?.[2]?.split(",")?.map((i) => i?.replace(/\s{2,}/g, ' ').trim())
                                let exp2 = `${expression_details?.label}(${match?.[2]})`;
                                queries = {
                                    ...queries,
                                    [i + "_" + query_metric_name]: {
                                        expression: match?.[0],
                                        expression_arr: expression_arr,
                                        basic_exp: match?.[0]?.replace(query_metric_name, expression_details?.label || query_metric_name),
                                        type: "func",
                                        expression_details: expression_details
                                    }
                                }
                                prevExpKey = i + "_" + query_metric_name
                            } else {
                                //////////////////console.log(logg, Number(expression), expression)
                                if (multipleValueComp?.map((i) => i?.label)?.includes(label)) {
                                    //ismultiple
                                    // so we change previous index data and skip the next index data
                                    // var prevQueryName = prevExpKey
                                    queries = {
                                        ...queries,
                                        [prevExpKey]: {
                                            ...(queries[prevExpKey]),
                                            isMultiple: true,
                                            expression_arr: JSON.parse(queries_exp?.[i + 1]),
                                            comparator: query_metric_name
                                        }
                                    }
                                    ////////console.log(logg, queries)
                                    skipNext = true
                                } else if (customComparators?.map((i) => i?.label)?.includes(label)) {
                                    let t = customComparators?.find((el) => el?.label === label);
                                    //////console.log(queries_exp, queries_exp[i + 1])
                                    let expArray = String(queries_exp?.[i + 1])?.replace(/[\[\]']+/g, '')?.split(', ');
                                    queries = {
                                        ...queries,
                                        [(i - 1) + "_" + "compOperator"]: {
                                            expression: t?.label,
                                            expression_arr: expArray,
                                            comparator: t?.value,
                                            expression_details: t,
                                            isCustomComparator: true
                                        }
                                    }
                                    skipNext = true
                                } else if (allVariables?.includes(String(expression))) {
                                    //variable
                                    // queries = {
                                    //     ...queries,
                                    //     [i + "_" + "variable"]: parseVariable(expression)
                                    // }
                                    queries = {
                                        ...queries,
                                        [i + "_" + "variable"]: {
                                            expression: String(expression),
                                            expression_arr: String(expression),
                                            type: "variable",
                                            // expression_details: expression_details
                                        }
                                    }
                                    prevExpKey = i + "_" + "variable"
                                } else if (!isNaN(Number(expression))) {
                                    //////////////////console.log(logg, Number(expression), expression)
                                    queries = {
                                        ...queries,
                                        [i + "_number"]: {
                                            expression: Number(expression),
                                            expression_arr: Number(expression),
                                            // expression_details: expression_details
                                        }
                                    }
                                    prevExpKey = i + "_number"
                                } else if (["(", ")"]?.includes(String(expression))) {
                                    queries = {
                                        ...queries,
                                        [i + "_bracket"]: {
                                            expression: String(expression),
                                            expression_arr: String(expression),
                                            // expression_details: expression_details
                                        }
                                    }
                                    prevExpKey = i + "_bracket"
                                } else {
                                    //////////////////console.log(logg, expression)


                                    var metricObj = screenerMetrics?.filter((i) => i?.metric_col_code === String(expression))?.[0]
                                    ////////console.log(logg, expression)
                                    if (metricObj) {
                                        queries = {
                                            ...queries,
                                            [i + "_" + expression]: {
                                                expression: screenerMetrics?.filter((i) => i?.metric_col_code === String(expression))?.[0]?.label,
                                                expression_arr: [],
                                                type: "metric",
                                                isMetric: true,
                                                expression_details: screenerMetrics?.filter((i) => i?.metric_col_code === String(expression))?.[0]
                                            }
                                        }
                                        prevExpKey = i + "_" + expression
                                    }
                                    else {

                                        queries = {
                                            ...queries,
                                            [(i - 1) + "_" + (String(label)?.toLowerCase() === "and" || String(label)?.toLowerCase() === "or" ? "conditionOperator" : "compOperator")]: {
                                                expression: label,
                                                expression_arr: query_metric_name
                                            }
                                        }
                                        prevExpKey = (i - 1) + "_" + (String(label)?.toLowerCase() === "and" || String(label)?.toLowerCase() === "or" ? "conditionOperator" : "compOperator")

                                    }




                                }
                            }

                        }



                        ////////console.log(logg, queries);

                        // if(exp//)

                        // queries = {
                        //     ...queries,
                        //     [i+"_"+]
                        // }

                    }

                    finalCondition = {
                        ...finalCondition,
                        [index + "_condition"]: {
                            conditionBlockWrapper: { type: wrapperNm, noOfBars: Number(noOfBars) },
                            queries: queries,
                            cond_name, cond_exp
                        }
                    }


                } else {
                    ////////////////////console.log("No match found.");
                }
            } else {

                // Level 1 check for brackets
                // \([^()]*\) is for only brackets and \w+\([^()]*\) is for checking if it is a function or not

                var isSetNAVar = false

                var queries_exp = (cond_name === "agg_z_score_val" ? (cond_exp?.replace("score(", "")?.replace(")", ""))?.split(regexPattern) : cond_exp?.split(regexPattern))
                // #special
                var parseV = parseVariable(cond_exp)
                if (parseV) {
                    isSetNAVar = true
                    queries_exp = (cond_exp?.replace("set_negative_to_na(", "")?.replace(")", ""))?.split(regexPattern)
                }
                console.log(akkaa, queries_exp)
                queries_exp = queries_exp?.map((item) => {
                    var isStartBracket = item?.includes("(") ? 1 : 0
                    var isEndBracket = item?.includes(")") ? 1 : 0
                    var totalSum = isStartBracket + isEndBracket
                    console.log(akkaa, totalSum, isStartBracket, isEndBracket, item, item?.split(regexPattern_cond_wrap22), (item?.match(regexPattern_cond_wrap22)))
                    if (totalSum === 2 || totalSum === 0) {
                        //normal func
                        var ignorel1 = 0
                        var l1 = (item?.split(regexPattern_cond_wrap22) || [])?.map((i) => {
                            if (["(", ")"]?.includes(i?.trim())) {
                                ignorel1++
                            }
                            return i?.trim()
                        })
                        var l2 = item?.match(regexPattern_cond_wrap22) || []
                        console.log(akkaa, ignorel1, l1, l2)
                        return ignorel1 === 2 ? [l1[0], ...l2, l1[1]] : l1?.includes("(") ? [...(l1), ...(l2)] : [...(l2), ...(l1)]
                    } else if (totalSum === 1 || totalSum > 2) {
                        // bracket .. split
                        console.log(akkaa, item)
                        const regexPattern = new RegExp(`\\s{0,4}(\\(|\\))\\s{0,4}`, 'g');
                        return item?.split(regexPattern)
                    }
                })
                queries_exp = queries_exp?.flat(1)?.filter((entry) => String(entry).trim()?.length != 0);
                var queries = {}
                var skipNext = false
                console.log(akkaa, 1234, cond_exp, cond_name, queries_exp)
                var currentExpKey = ''
                var prevExpKey = ''



                for (let i = 0; i < queries_exp.length; i++) {
                    if (skipNext) {
                        skipNext = false
                        // break;
                    } else {
                        // if (queries_orp?.length > 0) {
                        var query_metric_name = queries_exp?.[i]?.replace(/\s{2,}/g, '').trim()
                        var label = [...mathOperators, ...comparators, ...conditionOperators, ...multipleValueComp]?.filter((i) => i?.value === query_metric_name)?.[0]?.label
                        ////////console.log(logg, label, multipleValueComp?.map((i) => i?.label)?.includes(label))

                        const expression = queries_exp[i]?.replace(/\s{2,}/g, ' ').trim();
                        var match = expression?.match(/([a-z_0-9]+)\((.*?)\)/)
                        console.log(akkaa, match, expression)
                        if (match) {
                            ////////////////////console.log(logg, "d")
                            var query_metric_name = match?.[1]?.replace(/\s{2,}/g, ' ').trim()
                            ////////////////////console.log(query_metric_name)
                            var expression_details = functions?.filter((i) => i?.key === query_metric_name)?.[0]
                            var expression_arr = match?.[2]?.split(",")?.map((i) => i?.replace(/\s{2,}/g, ' ').trim())


                            let exp2 = `${expression_details?.label}(${match?.[2]})`;



                            queries = {
                                ...queries,
                                [i + "_" + query_metric_name]: {
                                    expression: match?.[0],
                                    basic_exp: match?.[0]?.replace(query_metric_name, expression_details?.label || query_metric_name),
                                    expression_arr: expression_arr,
                                    type: "func",
                                    expression_details: expression_details
                                }
                            }
                            prevExpKey = i + "_" + query_metric_name
                        } else {
                            //////////////////console.log(logg, Number(expression), expression)
                            if (multipleValueComp?.map((i) => i?.label)?.includes(label)) {
                                //ismultiple
                                // so we change previous index data and skip the next index data
                                // var prevQueryName = prevExpKey
                                queries = {
                                    ...queries,
                                    [prevExpKey]: {
                                        ...(queries[prevExpKey]),
                                        isMultiple: true,
                                        expression_arr: JSON.parse(queries_exp?.[i + 1]),
                                        comparator: query_metric_name
                                    }
                                }
                                ////////console.log(logg, queries)
                                skipNext = true
                            } else if (customComparators?.map((i) => i?.label)?.includes(label)) {
                                let t = customComparators?.find((el) => el?.label === label);
                                //////console.log(queries_exp, queries_exp[i + 1])
                                let expArray = String(queries_exp?.[i + 1])?.replace(/[\[\]']+/g, '')?.split(', ');
                                queries = {
                                    ...queries,
                                    [(i - 1) + "_" + "compOperator"]: {
                                        expression: t?.label,
                                        expression_arr: expArray,
                                        comparator: t?.value,
                                        expression_details: t,
                                        isCustomComparator: true
                                    }
                                }
                                skipNext = true
                            } else if (allVariables?.includes(String(expression))) {
                                //variable
                                queries = {
                                    ...queries,
                                    [i + "_variable"]: {
                                        expression: String(expression),
                                        expression_arr: String(expression),
                                        type: "variable",
                                        // expression_details: expression_details
                                    }
                                }
                                // queries = {
                                //     ...queries,
                                //     [i + "_" + "variable"]: parseVariable(expression)
                                // }
                                prevExpKey = i + "_variable"
                            } else if (!isNaN(Number(expression))) {
                                //////////////////console.log(logg, Number(expression), expression)
                                queries = {
                                    ...queries,
                                    [i + "_number"]: {
                                        expression: Number(expression),
                                        expression_arr: Number(expression),
                                        // expression_details: expression_details
                                    }
                                }
                                prevExpKey = i + "_number"
                            } else if (["(", ")"]?.includes(String(expression))) {
                                queries = {
                                    ...queries,
                                    [i + "_bracket"]: {
                                        expression: String(expression),
                                        expression_arr: String(expression),
                                        // expression_details: expression_details
                                    }
                                }
                                prevExpKey = i + "_bracket"
                            } else {
                                //////////////////console.log(logg, expression)



                                var metricObj = screenerMetrics?.filter((i) => i?.metric_col_code === String(expression))?.[0]
                                ////////console.log(logg, expression)
                                if (metricObj) {
                                    queries = {
                                        ...queries,
                                        [i + "_" + expression]: {
                                            expression: screenerMetrics?.filter((i) => i?.metric_col_code === String(expression))?.[0]?.label,
                                            expression_arr: [],
                                            type: "metric",
                                            isMetric: true,
                                            expression_details: screenerMetrics?.filter((i) => i?.metric_col_code === String(expression))?.[0]
                                        }
                                    }
                                    prevExpKey = i + "_" + expression
                                }
                                else {
                                    queries = {
                                        ...queries,
                                        [(i - 1) + "_" + (String(label)?.toLowerCase() === "and" || String(label)?.toLowerCase() === "or" ? "conditionOperator" : "compOperator")]: {
                                            expression: label,
                                            expression_arr: query_metric_name
                                        }
                                    }
                                    prevExpKey = (i - 1) + "_" + (String(label)?.toLowerCase() === "and" || String(label)?.toLowerCase() === "or" ? "conditionOperator" : "compOperator")
                                }



                            }
                        }

                    }



                    ////////console.log(logg, cond_name, cond_exp, queries);

                    // if(exp//)

                    // queries = {
                    //     ...queries,
                    //     [i+"_"+]
                    // }

                }

                finalCondition = isVar ? isGlobal(cond_name) ? cond_name === "exclusion_list" ? {
                    ...finalCondition,
                    [cond_name]: {
                        name: cond_name,
                        expression: 0,
                        expression_arr: 0,
                        isGlobal: isGlobal(cond_name),
                        cond_name, cond_exp
                    }
                } : {
                    ...finalCondition,
                    [cond_name]: {
                        name: cond_name,
                        expression: String(cond_exp)?.replace(/\"/g, ""),
                        expression_arr: String(cond_exp)?.replace(/\"/g, ""),
                        isGlobal: isGlobal(cond_name),
                        cond_name, cond_exp
                    }
                } : {
                    ...finalCondition,
                    [index + "_" + cond_name]: {
                        name: cond_name,
                        queries: queries,
                        isSetNAVar: isSetNAVar,
                        cond_name, cond_exp
                    }
                } : {
                    ...finalCondition,
                    [index + "_condition"]: {
                        queries: queries,
                        cond_name, cond_exp
                    }
                }
                // var array1 = cond_exp?.split("and");
                // var array2 = splitters;

                // var newArray = [];
                // var currArray = [];

                // for (let index = 0; index < array1.length; index++) {
                //     const element = array1[index];
                //     for (let index = 0; index < splitters.length; index++) {
                //         var al = element?.split(splitters[index])
                //         newArray = al
                //         newArray = newArray?.flat(1)
                //     }

                //     // //////////////////////console.log(logg, element);

                // }


                // ////////////////////console.log(logg, queries_orp, queries_exp)
            }


        })

        console.log(finalCondition)

        return finalCondition
    }

    const staticStrategyCodeParser = async (items) => {
        try {
            var str = items?.[0]
            var symbolsStr = String(str)?.substring(String(str)?.indexOf("[") + 1, String(str)?.indexOf("]"))
            var syms = symbolsStr?.replace(/\"/g, "")?.split(",")
            return syms
        } catch (error) {
            toast.error("Not a valid fixed strategy. Contact us.")
            return []
        }
    }

    const codeParser = async (codeM = "") => {
        const initialGlobals = {
            universe: {
                name: "universe",
                isGlobal: true,
                expression: "all",
                expression_arr: "all"
            },
            exchange: {
                name: "exchange",
                isGlobal: true,
                expression: "All",
                expression_arr: "All"
            }
        }
        var finalCondition = {}
        var mainArr = (codeM || "")?.split(";")?.map((str) => str?.replace(/\s{2,}/g, ' ').trim()?.replaceAll("\n", ''))?.filter((str) => str?.length > 0)
        const isCondition = (str) => {
            // var cond_name = String(str)?.match(/.*?\s{0,4}=\s{0,4}.*?/)?.[0]
            // ////console.log("AKAK", cond_name)
            // if (isSignal) {
            //     return str?.includes("_score_val")
            // } else {
            const regexPattern_cond_oper = new RegExp(`\\s{0,4}(${"c"})\\d{0,4}\\s{0,4}=`, 'g');
            return str?.match(regexPattern_cond_oper)?.length > 0
            // }

            // ////console.log("AKAK", str?.match(regexPattern_cond_oper))

            // if (isSignal) {
            //     return str[0] === "s" && typeof Number(str[1]) === "number"
            // } else {
            //     return str[0] === "c" && typeof Number(str[1]) === "number"
            // }

        }
        const isOutputString = (str) => {
            return str?.startsWith("output")
        }

        const isStaticStrategyCode = (str) => {
            const regexPattern_cond_oper = new RegExp(`\\s{0,4}(${"symbol"})\\s{0,4} (is_in)`, 'g');
            return str?.match(regexPattern_cond_oper)?.length > 0
        }

        var isStaticStrategy = mainArr?.filter((str) => isStaticStrategyCode(str))


        var conditions = mainArr?.filter((str) => isCondition(str))
        var output = mainArr?.filter((str) => isOutputString(str))?.[0]
        var global = []
        var variables = mainArr?.filter((str) => (!conditions?.includes(str) && ![output]?.includes(str)) && !isStaticStrategy?.includes(str))

        var allParsedVariables = await condParser(variables, true, {}, screenerMetrics)
        Object.filter = (obj, predicate) =>
            Object.fromEntries(Object.entries(obj).filter(predicate));
        variables = await Object.filter(allParsedVariables, ([varname, value]) => value?.isGlobal !== true)
        global = await Object.filter(allParsedVariables, ([varname, value]) => value?.isGlobal === true)
        console.log(variables)
        // setGlobals({ ...initialGlobals, ...global })

        var condition_operators = []
        var output_name = String(output)?.match(/output =/)?.[0]
        var output_exp = String(output)?.replaceAll(output_name, "")?.replace(/\s{2,}/g, ' ').trim()
        ////////console.log(logg, output_exp)

        const regexPattern_cond_oper = new RegExp(`${conditionOperators.map(operator => operator?.value).join('|')}`, 'g');

        if (output_exp?.match(regexPattern_cond_oper)) {
            condition_operators = output_exp?.match(regexPattern_cond_oper)
            condition_operators = condition_operators?.map((i) => i?.replace(/\s{2,}/g, ' ').trim())
        }
        var allParsedConditions = await condParser(conditions, false, variables, screenerMetrics)


        var staticStrategyData = {}
        if (isStaticStrategy?.length > 0) {
            staticStrategyData = await staticStrategyCodeParser(isStaticStrategy)
        }
        // setConditions(allParsedConditions)
        // setConditionsOper(condition_operators)


        return {
            "variables": variables,
            "globals": { ...initialGlobals, ...global },
            "conditions": allParsedConditions,
            "conditionOperators": condition_operators,
            "staticStrategyData": staticStrategyData
        }




    }

    console.log(akkaa, code)
    return await codeParser(code)
}


export const finalCodeRedable = (data = {}) => {

    var { conditions, conditions_oper, indices, screenerMetrics } = data
    console.log("DSASDASD", screenerMetrics)
    const expUIParser = (exp, queryData, metric, useColorCode = true) => {

        if (exp === "Add") {
            let colorCode = "var(--grey2)"
            return queryData?.expression_arr
        }
        const isMetric = (str) => {
            let ser = screenerMetrics?.filter((i) => i?.value === str)?.[0]
            if (ser) {
                return ser?.label
            }
            return str;
        }
        let item = queryData?.expression_details
        let expN = String(exp)?.match(/.*?\(/)?.[0]
        let colorCode = useColorCode ? metric?.includes("_number") ? "var(--green)" : metric?.includes("_compOperator") ? "var(--orange)" : "var(--dark4)" : "var(--dark4)"

        // //////console.log("hello",exp, queryData, metric)

        if (String(exp)?.match(/by|matype|direction\s{0,3}=\s{0,2}/)) {
            if (String(exp)?.match(/by\s{0,3}=\s{0,2}/)) {
                let showPeriod = queryData?.expression_arr?.map(element => {
                    return [`"MKT"`, `"SEC"`, `"INDGRP"`, `"IND"`].concat(indices?.map((item) => `"${item?.IndexNameCaps}"`)).filter(str => element.includes(str))?.length > 0
                });
                showPeriod = showPeriod?.filter((i) => i === false)?.length === queryData?.expression_arr?.length
                queryData = {
                    ...queryData,
                    expression_arr: !showPeriod ? queryData?.expression_arr?.filter(element => {
                        return !element.match(/period\s{0,3}=/)
                    }) : queryData?.expression_arr
                }
            }
            let newArr = queryData?.expression_arr?.map((str) => {
                // alert(str)
                str = isMetric(str)
                if (String(str)?.match(/by|matype|direction\s{0,3}=\s{0,2}/)) {
                    if (String(str)?.match(/\s{0,3}=\s{0,3}/g)) {
                        var arr = String(str)?.split(/\s{0,3}=\s{0,3}/g)
                        var keyName = arr?.[0]
                        var keyValue = item?.type === "<class 'int'>" ? parseInt(Number(arr?.[1])) : item?.type === "<class 'float'>" ? parseFloat(Number(arr?.[1])) : arr?.[1];

                        if (String(str)?.match(/by\s{0,3}=\s{0,2}/)) {
                            ////console.log("lala", str, keyValue)
                            var keyValue2 = groupColumnNames?.concat(indices?.map((item, i) => {
                                return { key: item?.IndexName, label: item?.IndexName, value: `"${item?.IndexNameCaps}"`, tabType: "Indices" }
                            }))?.filter((i) => {
                                return i?.value === keyValue
                            })?.[0]?.label

                            if (keyValue2) {
                                return keyName + "=" + keyValue2
                            } else {
                                return keyName + "=" + ((keyValue?.endsWith("F\"") || keyValue?.endsWith("Y\"")) ? "Years" : keyValue?.endsWith("Q\"") ? "Quarters" : (keyValue?.endsWith("M\"") ? "Months" : "Days"))
                            }


                        } else if (String(str)?.match(/matype\s{0,3}=\s{0,2}/)) {
                            var keyValue2 = maTypes?.filter((i) => {
                                return i?.value === Number(keyValue)
                            })?.[0]?.label
                            return keyName + "=" + keyValue2
                        } else if (String(str)?.match(/direction\s{0,3}=\s{0,2}/)) {
                            var keyValue2 = directionTypes?.filter((i) => {
                                return i?.value === Number(keyValue)
                            })?.[0]?.label
                            return keyName + "=" + keyValue2
                        }
                    } else {
                        return str
                    }
                } else {
                    return String(str)
                }
            })
            //////console.log("hello", newArr)
            return `${String(queryData?.expression_details?.key)?.toUpperCase()} (${newArr?.join(", ")})`

        }
        if (String(exp)?.toLowerCase() === "and" || String(exp)?.toLowerCase() === "or")
            return String(exp)?.toUpperCase()
        if (queryData?.type !== "metric" && expN) {
            //////console.log("hello", expN)
            let patternOutput = patternOutputs?.find((el) => el?.value === queryData?.patternOutput)?.label;

            return `${String(expN)?.toUpperCase()?.replace("(", "")}" ("${exp?.replace(expN, "")}${patternOutput ? " is " + patternOutput : ""}`
        }
        if (queryData?.type === 'metric' && queryData?.isMultiple === true && multipleTypeMetrics?.includes(item?.metric_col_code)) {
            let itemStr = queryData?.expression_arr?.map((el) => item?.dataToRenderFrom?.find((ele) => ele?.value === el)?.label)?.join(', ')
            return (`${String(exp)} ${String(multipleValueComp?.find((el) => el?.value === queryData?.comparator)?.label)} [ ${itemStr} ]`)
        }
        if (queryData?.isCustomComparator === true) {
            let itemStr = queryData?.expression_arr?.map((el) => isMetric(el))?.join(', ')
            return (`${String(exp)} [ ${itemStr} ]`)
        }
        return `${String(exp)}`

    }

    const expChecker = (exp, queryData, key) => {
        console.log(queryData, key)

        // if(queryData?.type === "variable"){
        //     var nD = variables[key]?.name
        //     return nD
        // }else 
        if (String(exp)?.match(/by\s{0,3}=\s{0,2}/)) {
            var showPeriod = queryData?.expression_arr?.map(element => {
                return [`"MKT"`, `"SEC"`, `"INDGRP"`, `"IND"`].concat(indices?.map((item) => `"${item?.IndexNameCaps}"`)).filter(str => element.includes(str))?.length > 0
            });
            showPeriod = showPeriod?.filter((i) => i === false)?.length === queryData?.expression_arr?.length
            queryData = {
                ...queryData,
                expression_arr: !showPeriod ? queryData?.expression_arr?.filter(element => {
                    return !element.match(/period\s{0,3}=/)
                }) : queryData?.expression_arr
            }


            return (`${queryData?.expression_details?.label}(${queryData?.expression_arr?.join(", ")})`)?.trim()
        }
        if (Object.keys(queryData)?.includes("patternOutput")) {
            return exp + " == " + queryData?.patternOutput
        }
        if (String(key)?.includes("Operator"))
            return (`${queryData?.expression_arr}`)?.toLowerCase()?.trim()
        return exp
    }

    const multiExpConvertor = (exp, queryData, key) => {
        let itemStr = queryData?.expression_arr?.map((i) => { return isNaN(i) ? `"${i}"` : i })?.join(', ')
        // console.log(logg, 123, queryData, itemStr)
        return `${queryData?.expression_details?.value} ${queryData?.comparator} [${itemStr}]`
    }

    const customCompConvertor = (exp, queryData, key) => {
        let itemStr = queryData?.expression_arr?.map((i) => { return i })?.join(', ')
        ////////console.log(logg, 123, queryData, itemStr)
        return `${queryData?.expression_details?.value} [${itemStr}]`
    }


    try {
        var output = ""
        var output_upfront = ""
        var code = []
        var code_f_key = ""


        var condition_keys = Object.keys(sortArrObject(conditions)) || []
        for (let index = 0; index < condition_keys?.length; index++) {
            const cond = condition_keys[index];
            // ////////////////console.log(logg, cond)
            const cond_queries = conditions[cond]?.queries;
            var cond_queries_keys = Object.keys(cond_queries) || []
            var queryExp = []

            if (cond_queries_keys?.length > 0) {
                for (let index = 0; index < cond_queries_keys.length; index++) {
                    const query_key = cond_queries_keys[index];
                    code?.push(expUIParser(cond_queries[query_key]?.expression, cond_queries[query_key], query_key, false))
                }
            }
            //         // ////////console.log(logg, 123, query_key, cond_queries[query_key])
            //         const query_exp = cond_queries[query_key]?.type === "metric" ? (cond_queries[query_key]?.isMultiple ?
            //             multiExpConvertor(cond_queries[query_key]?.expression, cond_queries[query_key], query_key) :
            //             cond_queries[query_key]?.expression_details?.label) : cond_queries[query_key]?.isCustomComparator === true ?
            //             customCompConvertor(cond_queries[query_key]?.expression, cond_queries[query_key], query_key) :
            //             expChecker(cond_queries[query_key]?.expression, cond_queries[query_key], query_key);
            //         //////console.log(query_exp);
            //         if ((index === cond_queries_keys?.length - 1) && String(query_key)?.includes("Operator") && !(cond_queries[query_key]?.isCustomComparator === true)) {
            //             return "Error: Condition cannot ends with comparators or operators."
            //         } else if ((index <= cond_queries_keys?.length - 1) && String(cond_queries[query_key]?.expression) === "Add") {
            //             return "Error: Not a valid condition."
            //         } else if ((index == cond_queries_keys?.length - 1) && String(cond_queries[query_key]?.expression) === "Add") {

            //         } else {
            //             queryExp.push(query_exp)
            //         }
            //     }
            //     var queriee = queryExp?.join(" ")
            //     if (conditions[cond]?.conditionBlockWrapper) {
            //         code?.push(`${conditions[cond]?.conditionBlockWrapper?.type}((${queriee}),${conditions[cond]?.conditionBlockWrapper?.noOfBars})`)
            //     } else {
            //         code?.push(`${queriee}`)
            //     }



            if (index < conditions_oper?.length)
                code?.push(conditions_oper?.[index])

            //     // code_f_key += "c" + index
            // }
            //////console.log(queryExp)
            // code += `${cond} = ${queriee}`
        }

        if (code?.length > 0) {
            output = code?.join("\n")
            output = output?.replace("Add", "")
            ////console.log(akkaa, variables, output)
            return output
        } else {
            return "Error: Condition block is empty"
        }
    } catch (error) {
        console.log(error)
        return "Error: Not a valid condition"
    }

    // ////console.log(logg, output)
}


export const finalCode = (data = {}) => {

    var { globals, variables, conditions, conditions_oper, indices, sortAndLimit } = data

    console.log("DSFDSF", data)
    const expChecker = (exp, queryData, key) => {
        ////////console.log(logg, queryData, key)

        // if(queryData?.type === "variable"){
        //     var nD = variables[key]?.name
        //     return nD
        // }else 
        if (String(exp)?.match(/by\s{0,3}=\s{0,2}/)) {
            var showPeriod = queryData?.expression_arr?.map(element => {
                return [`"MKT"`, `"SEC"`, `"INDGRP"`, `"IND"`].concat(indices?.map((item) => `"${item?.IndexNameCaps}"`)).filter(str => element.includes(str))?.length > 0
            });
            showPeriod = showPeriod?.filter((i) => i === false)?.length === queryData?.expression_arr?.length
            queryData = {
                ...queryData,
                expression_arr: !showPeriod ? queryData?.expression_arr?.filter(element => {
                    return !element.match(/period\s{0,3}=/)
                }) : queryData?.expression_arr
            }


            return (`${key?.replaceAll(/\d{1,4}_/g, '')}(${queryData?.expression_arr?.join(", ")})`)?.trim()
        }
        if (Object.keys(queryData)?.includes("patternOutput")) {
            return exp + " == " + queryData?.patternOutput
        }
        if (String(key)?.includes("Operator"))
            return (`${queryData?.expression_arr}`)?.toLowerCase()?.trim()
        return exp
    }

    const multiExpConvertor = (exp, queryData, key) => {
        let itemStr = queryData?.expression_arr?.map((i) => { return isNaN(i) ? `"${i}"` : i })?.join(', ')
        ////////console.log(logg, 123, queryData, itemStr)
        return `${queryData?.expression_details?.value} ${queryData?.comparator} [${itemStr}]`
    }

    const customCompConvertor = (exp, queryData, key) => {
        let itemStr = queryData?.expression_arr?.map((i) => { return i })?.join(', ')
        ////////console.log(logg, 123, queryData, itemStr)
        return `${queryData?.expression_details?.value} [${itemStr}]`
    }


    try {
        var output = ""
        var output_upfront = ""
        var code = []
        var code_f_key = ""

        console.log("heyeA", conditions)
        // var code_vars = variables
        //variables
        for (let index = 0; index < Object?.keys(globals).length; index++) {
            if (Object?.keys(globals)?.[index] === "exclusion_list") {
                code?.push(`${Object?.keys(globals)?.[index]} = is_exclusion_list == ${globals[Object?.keys(globals)?.[index]]?.expression};`)
                output_upfront = "exclusion_list"
            }
            else if (String(globals[Object?.keys(globals)?.[index]]?.expression)?.toLowerCase() !== "all")
                code?.push(`${Object?.keys(globals)?.[index]} = "${globals[Object?.keys(globals)?.[index]]?.expression}";`)
        }

        // console.log(output_upfront, code)
        // for (let index = 0; index < Object?.keys(variables).length; index++) {
        //     code?.push(`${Object?.keys(variables)?.[index]} = ${variables[Object?.keys(variables)?.[index]]?.expression};`)
        // }

        var variable_keys = Object.keys(sortArrObject(variables)) || []
        for (let index = 0; index < variable_keys?.length; index++) {

            Object.filter = (obj, predicate) => {
                return Object.fromEntries(Object.entries(obj).filter(predicate))
            };

            var conditionString = JSON.stringify(conditions)
            var variableString = JSON.stringify(Object.filter(variables, ([varname, value]) => value?.isGlobal !== true && varname !== variable_keys[index]))
            const regexPattern = new RegExp(`\\b(${variables[variable_keys[index]]?.name})\\b`, 'g');

            var adding = false

            console.log(regexPattern)
            if ((variableString?.match(regexPattern)?.length > 0 || conditionString?.match(regexPattern)?.length > 0) || variable_keys?.[index]?.includes("agg_z_score_val") || sortAndLimit?.by === variables[variable_keys[index]]?.name) {
                adding = true
            }

            console.log(akkaa, "2131212", conditionString, variableString, adding)
            if (adding === true) {
                const cond = variable_keys[index];
                // ////////////////console.log(logg, cond)
                const cond_queries = variables[cond]?.queries;
                var isSetNAVar = variables[cond]?.isSetNAVar || false
                var cond_queries_keys = Object.keys(cond_queries) || []
                var queryExp = []
                for (let index = 0; index < cond_queries_keys.length; index++) {
                    const query_key = cond_queries_keys[index];
                    console.log(akkaa, 123, query_key, cond_queries[query_key])
                    const query_exp = cond_queries[query_key]?.type === "metric" ? cond_queries[query_key]?.isMultiple ? multiExpConvertor(cond_queries[query_key]?.expression, cond_queries[query_key], query_key) : cond_queries[query_key]?.expression_details?.metric_col_code : expChecker(cond_queries[query_key]?.expression, cond_queries[query_key], query_key)
                    if ((index === cond_queries_keys?.length - 1) && String(query_key)?.includes("Operator")) {
                        return `Error: Variable '${variables[cond]?.name}' cannot ends with comparators or operators.`
                    } else if ((index <= cond_queries_keys?.length - 1) && String(cond_queries[query_key]?.expression) === "Add") {
                        return `Error: '${variables[cond]?.name}' is not a valid variable.`
                    } else if ((index == cond_queries_keys?.length - 1) && String(cond_queries[query_key]?.expression) === "Add") {

                    } else {
                        queryExp.push(query_exp)
                    }
                }
                console.log(akkaa, queryExp)
                var queriee = queryExp?.join(" ")
                console.log(akkaa, queryExp, queriee)
                if (variables[cond]?.conditionBlockWrapper) {
                    code?.push(`${variables[cond]?.name} = ${variables[cond]?.conditionBlockWrapper?.type}((${queriee}),${variables[cond]?.conditionBlockWrapper?.noOfBars});`)
                } else {
                    code?.push(isSetNAVar ? `${variables[cond]?.name} = set_negative_to_na(${queriee});` : `${variables[cond]?.name} = ${queriee};`)
                }
                console.log(akkaa, code)
            }


            // if (index > 0)
            //     code_f_key += " " + conditions_oper[index - 1] + " "

            // code_f_key += "c" + index
            // // code += `${cond} = ${queriee}`
        }


        var condition_keys = Object.keys(sortArrObject(conditions)) || []
        for (let index = 0; index < condition_keys?.length; index++) {
            const cond = condition_keys[index];
            // console.log('heyeA', cond)
            const cond_queries = conditions[cond]?.queries;
            var cond_queries_keys = Object.keys(cond_queries) || []
            var queryExp = []
            if (cond_queries_keys?.length > 0) {
                for (let index = 0; index < cond_queries_keys.length; index++) {
                    const query_key = cond_queries_keys[index];
                    // console.log(logg, 123, query_key, cond_queries[query_key])
                    const query_exp = cond_queries[query_key]?.type === "metric" ? (cond_queries[query_key]?.isMultiple ?
                        multiExpConvertor(cond_queries[query_key]?.expression, cond_queries[query_key], query_key) :
                        cond_queries[query_key]?.expression_details?.metric_col_code) : cond_queries[query_key]?.isCustomComparator === true ?
                        customCompConvertor(cond_queries[query_key]?.expression, cond_queries[query_key], query_key) :
                        expChecker(cond_queries[query_key]?.expression, cond_queries[query_key], query_key);
                    console.log(akkaa, query_exp);
                    if ((index === cond_queries_keys?.length - 1) && String(query_key)?.includes("Operator") && !(cond_queries[query_key]?.isCustomComparator === true)) {
                        return "Error: Condition cannot ends with comparators or operators."
                    } else if ((index <= cond_queries_keys?.length - 1) && String(cond_queries[query_key]?.expression) === "Add") {
                        return "Error: Not a valid condition."
                    } else if ((index == cond_queries_keys?.length - 1) && String(cond_queries[query_key]?.expression) === "Add") {

                    } else {
                        queryExp.push(query_exp)
                    }
                }
                console.log(akkaa, queryExp)
                var queriee = queryExp?.join(" ")
                if (conditions[cond]?.conditionBlockWrapper) {
                    code?.push(`c${index} = ${conditions[cond]?.conditionBlockWrapper?.type}((${queriee}),${conditions[cond]?.conditionBlockWrapper?.noOfBars});`)
                } else {
                    code?.push(`c${index} = ${queriee};`)
                }



                if (index > 0)
                    code_f_key += " " + conditions_oper[index - 1] + " "

                code_f_key += "c" + index
            }
            // console.log(queryExp, code, code_f_key, conditions_oper)
            // code += `${cond} = ${queriee}`

        }

        if (code?.length > 0) {
            output = code?.join("\n")
            output = output + "\n"
            console.log(akkaa, output)
            output += (code_f_key?.length === 0 && output_upfront?.length === 0) ? "output = 1;" : `output = ${output_upfront}${code_f_key?.length > 1 && output_upfront?.length > 1 ? " and " : ""}${code_f_key};`
            console.log(akkaa, output)
            output = output?.replace("Add", "")
            console.log(akkaa, output)
            return output
        } else {
            return "Error: Condition block is empty"
        }
    } catch (error) {
        console.log(error)
        return "Error: Not a valid condition"
    }

    // ////console.log(logg, output)
}

export const patternOutputs = [
    { key: "Bullish", value: 100, label: "Bullish Pattern" },
    { key: "Bearish", value: -100, label: "Bearish Pattern" },
    { key: "Neutral", value: 0, label: "Neutral Pattern" },
]

export const withinQuotesFields = ['by', 'symbol']