import { formatDate, isLastDayOfMonth } from "Components/DateFormatter";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import moment from "moment";
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { BsFillBarChartFill } from "react-icons/bs";
import { generateUniqueKey } from "Libs/utils";
import { Modal, Table, Timeline, Switch } from "antd";
import { NoDataFound } from "Components/NoDataFound";
import { AiOutlineDown, AiOutlineRight } from "react-icons/ai";
import MyTable from "Components/TableWithScroll";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import CustomSegmentedTab from "Components/CustomSegmentedTab";
import apiHelperPY from "Services/api/ApiHelperPY";
import { CurrencyFormat } from "Components/CurrencyFormat";
import RedGreenText from "Components/RedGreenText";
import { getDivHeight } from "Libs/scroll";

const BulkBlockDeals = React.memo(({ type, loadingBulkBlockDeals, bulkBlockDeals = {}, isMobile }) => {

    const [itemsToShow, setItemsToShow] = React.useState(2);

    const mainData = React.useMemo(() => {
        return bulkBlockDeals[type];
    }, [type, bulkBlockDeals])

    const dataToShow = React.useMemo(() => {
        return Object.keys(bulkBlockDeals?.[type])?.sort((a, b) => new Date(b) - new Date(a))?.slice(0, itemsToShow) || [];
    }, [type, bulkBlockDeals, itemsToShow])

    const columns = {
        block_deals: [
            {
                "title": 'Name',
                "dataIndex": 'name',
                render: (text) => {
                    return <span className="textXS dark3">{text}</span>
                },
                fixed: 'left',
                width: isMobile ? '35vw' : '',
            },
            {
                "title": 'Type',
                "dataIndex": 'transaction_type',
                render: (text) => {
                    return <span className="textXS dark3" style={{ textTransform: 'capitalize' }}>
                        {<RedGreenText text={text} value={text?.toLowerCase() === 'sell' ? -1 : text?.toLowerCase() === 'buy' ? 1 : 0} />}
                    </span>
                },
                align: 'right',
                width: 100,
            },
            {
                "title": 'Price',
                "dataIndex": 'transaction_price',
                render: (text) => {
                    return <span className="textXS dark3" style={{ whiteSpace: 'nowrap' }}>{CurrencyFormat(text, 'long', 2)}</span>
                },
                align: 'right',
                width: 100,
            },
            {
                "title": 'Qty.',
                "dataIndex": 'transaction_quantity',
                render: (text) => {
                    return <span className="textXS dark3">{CurrencyFormat(text, 'short', 2, '', false)}</span>
                },
                align: 'right',
                width: 100,
            },
            {
                "title": 'Value',
                "dataIndex": 'transaction_value',
                render: (text) => {
                    return <span className="textXS dark3" style={{ whiteSpace: 'nowrap' }}>{CurrencyFormat(text, 'short', 2)}</span>
                },
                align: 'right',
                width: 100,
            },
        ],
        bulk_deals: [
            {
                "title": 'Name',
                "dataIndex": 'name',
                render: (text) => {
                    return <span className="textXS dark3">{text}</span>
                },
                fixed: 'left',
                width: isMobile ? '35vw' : '',
            },
            {
                "title": 'Type',
                "dataIndex": 'transaction_type',
                render: (text) => {
                    return <span className="textXS dark3" style={{ textTransform: 'capitalize' }}>
                        {<RedGreenText text={text} value={text?.toLowerCase() === 'sell' ? -1 : text?.toLowerCase() === 'buy' ? 1 : 0} />}
                    </span>
                },
                align: 'right',
                width: 100,
            },
            {
                "title": 'Price',
                "dataIndex": 'transaction_price',
                render: (text) => {
                    return <span className="textXS dark3" style={{ whiteSpace: 'nowrap' }}>{CurrencyFormat(text, 'long', 2)}</span>
                },
                align: 'right',
                width: 100,
            },
            {
                "title": 'Qty.',
                "dataIndex": 'transaction_quantity',
                render: (text) => {
                    return <span className="textXS dark3">{CurrencyFormat(text, 'short', 2, '', false)}</span>
                },
                align: 'right',
                width: 100,
            },
            {
                "title": 'Value',
                "dataIndex": 'transaction_value',
                render: (text) => {
                    return <span className="textXS dark3" style={{ whiteSpace: 'nowrap' }}>{CurrencyFormat(text, 'short', 2)}</span>
                },
                align: 'right',
                width: 100,
            },
        ],
        insider_trades: [
            {
                "title": 'Name',
                "dataIndex": 'acquirer_name',
                render: (text, record) => {
                    return <div>
                        <div className="textXS dark3">{text}</div>
                        <div className="textXS dark1">{record?.acquirer_category}</div>
                    </div>
                },
                fixed: 'left',
                width: isMobile ? '35vw' : '',
            },
            {
                "title": 'Transaction',
                "dataIndex": 'transaction_type',
                render: (text, record) => {
                    return <div>
                        <div className="textXS dark3">{record?.mode_of_acquisition} ({text})</div>
                        <div className="textXS dark1">{moment(record?.exchange_broadcast_date).format('Do MMM YYYY')}</div>
                    </div>
                },
                align: 'right',
                width: 200,
            },
            {
                "title": <>Holding qty.<br />pre transaction</>,
                "dataIndex": 'security_held_pre_tx_per',
                render: (text, record) => {
                    return <span className="textXS dark3" style={{ whiteSpace: 'nowrap' }}>{CurrencyFormat(record?.security_held_pre_tx_num, 'short', 2, '', false)} ({DecimalValueFormat({ num: text, decimals: 2, suffix: ' %' })})</span>
                },
                align: 'right',
                width: 150,
            },
            {
                "title": 'Average price',
                "dataIndex": 'average_price',
                render: (text, record) => {
                    return <span className="textXS dark3" style={{ whiteSpace: 'nowrap' }}>
                        {record.security_num ? CurrencyFormat(record.transaction_value / record.security_num, 'long', 2) : '-'}
                    </span>
                },
                align: 'right',
                width: 100,
            },
            {
                "title": 'Qty.',
                "dataIndex": 'security_num',
                render: (text) => {
                    return <span className="textXS dark3" style={{ whiteSpace: 'nowrap' }}>{CurrencyFormat(text, 'short', 2, '', false)}</span>
                },
                align: 'right',
                width: 100,
            },
            {
                "title": 'Transaction value',
                "dataIndex": 'transaction_value',
                render: (text) => {
                    return <span className="textXS dark3" style={{ whiteSpace: 'nowrap' }}>{CurrencyFormat(text, 'short', 2)}</span>
                },
                align: 'right',
                width: 100,
            },
        ]
    }

    return <>
        {loadingBulkBlockDeals ? (
            <ActivityLoaderMintbox />
        ) : (
            <>
                {
                    mainData ?
                        <div style={{ margin: "1rem 0", maxHeight: 'calc(100vh - 20rem)', overflow: 'auto' }}>
                            <Timeline items={dataToShow?.map((date, i) => {
                                console.log(date)
                                return {
                                    children: <React.Fragment key={i}>
                                        <div className="textSM w-500 black" style={{ marginBottom: '8px' }}>{type !== 'insider_trades' ? formatDate(date) : moment(date, 'MMMM-YYYY').format('MMM YYYY')}</div>
                                        <Table pagination={false} dataSource={mainData?.[date]} columns={columns[type]} scroll={{ x: 'fit-content' }} />
                                    </React.Fragment>
                                }
                            })
                            } />
                            {Object.keys(mainData)?.length > itemsToShow && <div className='d-flex align-items-center justify-content-center my-2'>
                                <button className="btnBlack" onClick={() => {
                                    setItemsToShow(itemsToShow + 2);
                                }}>Load more</button>
                            </div>}
                        </div>
                        :
                        <NoDataFound />
                }
            </>
        )}
    </>
})

const SAST = React.memo(({ loadingSAST, sastData, isMobile }) => {

    const [itemsToShow, setItemsToShow] = React.useState(2);

    const columns = [
        {
            "title": 'Name',
            "dataIndex": 'name_acquirer',
            render: (text, record) => {
                return <div>
                    <div className="textXS dark3">{text}</div>
                    <div className="textXS dark1">{record?.promoter_status || '-'}</div>
                </div>
            },
            fixed: 'left',
            width: isMobile ? '35vw' : '',
        },
        {
            "title": 'Transaction',
            "dataIndex": 'transaction_type',
            render: (text, record) => {
                return <div>
                    <div className="textXS dark3">{`${text} (${record?.exchange})`}</div>
                    <div className="textXS dark1">{moment(record?.transaction_date).format('Do MMM YYYY')}</div>
                </div>
            },
            align: 'right',
            width: 120,
        },
        {
            "title": <>Holding qty.<br />pre transaction</>,
            "dataIndex": 'pct_shares_pre_transaction',
            render: (text, record) => {
                return <span className="textXS dark3" style={{ whiteSpace: 'nowrap' }}>{CurrencyFormat(record?.num_shares_pre_transaction, 'short', 2, '', false)} ({DecimalValueFormat({ num: text, decimals: 2, suffix: ' %' })})</span>
            },
            align: 'right',
            width: 150,
        },
        {
            "title": 'Qty.',
            "dataIndex": 'pct_shares_transacted',
            render: (text, record) => {
                return <span className="textXS dark3" style={{ whiteSpace: 'nowrap' }}>{CurrencyFormat(record.num_shares_transacted, 'short', 2, '', false)} ({DecimalValueFormat({ num: text, decimals: 2, suffix: ' %' })})</span>
            },
            align: 'right',
            width: 150,
        },
        {
            "title": <>Holding qty.<br />post transaction</>,
            "dataIndex": 'pct_shares_post_transaction',
            render: (text, record) => {
                return <span className="textXS dark3" style={{ whiteSpace: 'nowrap' }}>{CurrencyFormat(record?.num_shares_post_transaction, 'short', 2, '', false)} ({DecimalValueFormat({ num: text, decimals: 2, suffix: ' %' })})</span>
            },
            align: 'right',
            width: 150,
        },
    ]

    const mainData = React.useMemo(() => {
        let data = {}
        sastData?.map(t => moment(t.transaction_date).format('MMM YYYY')).map(d => {
            data[d] = sastData?.filter(t => moment(t.transaction_date).format('MMM YYYY') === d)
        })
        return data;
    }, [sastData]);

    const dataToShow = React.useMemo(() => {
        let data = {}
        sastData?.map(t => moment(t.transaction_date).format('MMM YYYY')).map(d => {
            data[d] = sastData?.filter(t => moment(t.transaction_date).format('MMM YYYY') === d)
        })
        return Object.keys(data)?.sort((a, b) => new Date(b) - new Date(a))?.slice(0, itemsToShow);
    }, [sastData, itemsToShow]);

    return <>
        {loadingSAST ? (
            <ActivityLoaderMintbox />
        ) : (
            <>
                {
                    sastData?.length ?
                        <div style={{ margin: "1rem 0", maxHeight: 'calc(100vh - 20rem)', overflow: 'auto' }}>
                            <Timeline items={dataToShow?.map((date, i) => {
                                return {
                                    children: <React.Fragment key={i}>
                                        <div className="textSM w-500 black" style={{ marginBottom: '8px' }}>{date}</div>
                                        <Table pagination={false} dataSource={mainData?.[date]} columns={columns} scroll={{ x: 'fit-content' }} />
                                    </React.Fragment>
                                }
                            })
                            } />
                            {Object.keys(mainData)?.length > itemsToShow && <div className='d-flex align-items-center justify-content-center my-2'>
                                <button className="btnBlack" onClick={() => {
                                    setItemsToShow(itemsToShow + 2);
                                }}>Load more</button>
                            </div>}
                        </div>
                        :
                        <NoDataFound />
                }
            </>
        )}
    </>
})

export const Ownership2 = React.memo(({ shareholdings = {}, loadingShareholding, bulkBlockDeals = {}, loadingBulkBlockDeals, fundData, SYMBOL, sastData, loadingSAST }) => {
    const isMobile = window.innerWidth < 760;
    const [selectedTabItem, setSelectedTabItem] = React.useState("share_holdings")

    const ShareHolding = () => {
        const [chartToShow, setChartToShow] = React.useState({});
        const [topHoldingsExpanded, setTopHoldingsExpanded] = React.useState([]);
        const [topHoldings, setTopHoldings] = React.useState({});
        const [showInterim, setShowInterim] = React.useState(false)
        const showNoOfShldrs = ["Non institutions/Retail"];

        const thColumns = [
            {
                title: "",
                dataIndex: "holder",
                fixed: "left",
                width: 250,

            },
            {
                title: "Shareholding",
                key: "sh_per",
                dataIndex: "sh_per",
                width: 100,
                render: (text) => {
                    return (DecimalValueFormat({ num: text, suffix: " %", decimals: 2, placeholder: "-" }))
                },
                align: "right",
            },
        ];

        let modShareHolCols = (shareholdings?.columns || [])?.filter((el) => {
            let dat = new Date(el);
            if (showInterim) return true;
            else return isLastDayOfMonth(dat);
        }) || [];

        let columns = [
            {
                title: "",
                dataIndex: "name",
                fixed: "left",
                width: isMobile ? '35vw' : 250,
                render: (text, record) => {
                    let checkTH = Object.keys(record || {})?.filter((el) => shareholdings?.columns?.includes(el))?.find((el) => el?.topHolders?.length > 0);
                    return (<span style={{ fontWeight: record?.type === 0 ? "700" : record?.type === 1 ? 500 : 400, fontSize: record?.type === 0 ? "14px" : record?.type === 1 ? "13px" : "12px" }}>
                        {text}
                        {showNoOfShldrs?.includes(text) && <div className='textXS dark3 w-400' style={{ marginLeft: "0.75rem", fontWeight: 400 }}>
                            No of shareholders
                        </div>}
                    </span>)
                },
            },
            ...(shareholdings?.columns?.map((d) => {
                let dat = new Date(d);
                let title = <div><div className='textSM w-500 dark4'>{moment(dat).format('YYYY')}</div>
                    <div className='textXS w-500 dark3'>{moment(dat).format("MMM DD")}</div>
                </div>
                return {
                    title: title,
                    dataIndex: d,
                    key: d,
                    align: "right",
                    width: 100,
                    render: (text, record) => {
                        // console.log(text,topHoldingsExpanded?.includes(record?.name),text?.topHolders?.length>0)
                        if (text?.sh_per === undefined || text?.sh_per === null) return '-'
                        return <div>
                            <div>{DecimalValueFormat({ num: text?.sh_per, suffix: " %", decimals: 2, placeholder: "-" })}</div>
                            {topHoldingsExpanded?.includes(record?.name) && text?.topHolders?.length > 0 && <div className='textXS w-500' style={{ color: "var(--primary)", cursor: "pointer" }} onClick={() => {
                                setTopHoldings({ name: record?.name, data: text?.topHolders })
                            }}>
                                Top Holders &gt;
                            </div>}
                            {showNoOfShldrs?.includes(record?.name) &&
                                <div className='textXS dark3 w-400' style={{ marginLeft: "0.75rem", fontWeight: 400 }}>
                                    {DecimalValueFormat({ num: text?.num_shldrs, decimals: 0, placeholder: "-" })}
                                </div>
                            }
                        </div>
                    },
                    show: Boolean(showInterim ? true : isLastDayOfMonth(new Date(dat)))
                }
            }) || []),
            {
                title: "",
                key: "code",
                dataIndex: "code",
                width: 100,
                render: (text, obj) => {
                    return (
                        <div>
                            <BsFillBarChartFill onClick={() => {
                                let chartData = Object.keys(obj || {})?.filter((el) => modShareHolCols?.includes(el))?.map((el) => obj?.[el]?.sh_per);
                                setChartToShow({ ...obj, chartData });
                            }} style={{ cursor: "pointer" }} />
                        </div>
                    );
                },
                align: "right",
            },
        ]
        columns = columns?.filter((el) => el?.show !== false);
        const ShareHoldingChart = ({ data = {} }) => {

            var options = {
                chart: {
                    type: "column",
                    backgroundColor: "transparent",
                    height: 200,
                },
                title: {
                    text: "",
                },
                exporting: {
                    enabled: false,
                },
                yAxis: {
                    visible: true,
                    // tickAmount: 5,
                    gridLineDashStyle: "longdash",
                    title: {
                        text: "",
                    },
                    gridLineColor: "var(--grey1)",
                    // max: 100,
                    min: 0,
                    // tickAmount: 5,
                },
                xAxis: [
                    {
                        type: "datetime",
                        lineColor: "var(--grey1)",
                        tickColor: "var(--grey1)",
                        categories: modShareHolCols || [],
                        labels: {
                            formatter: function () {
                                return formatDate(this.value, "date");
                            },
                        },
                    },
                ],
                legend: {
                    enabled: false,
                },
                plotOptions: {
                    series: {
                        lineWidth: 1,
                        label: {
                            connectorAllowed: false,
                        },
                        marker: {
                            enabled: false,
                        },
                    },
                },
                tooltip: {
                    shared: true,
                    valuedecimals: 2,
                    // valuePrefix: '₹',
                    valueSuffix: "%",
                },
                credits: {
                    enabled: false,
                },
                boost: {
                    useGPUTranslations: true,
                },
                series: [
                    {
                        name: data?.name,
                        turboThreshold: 9e9,
                        dataLabels: {
                            enabled: true,
                            crop: false,
                            overflow: 'none',
                            style: {
                                fontWeight: "500",
                                textOutline: "none",
                                color: "var(--dark4)",
                            },
                            formatter: function () {
                                return DecimalValueFormat({
                                    num: this.y,
                                    decimals: 2,
                                    suffix: "%",
                                });
                            },
                        },
                        data: data?.chartData || [],
                    },
                ],
                scrollbar: {
                    enabled: false,
                },
            };
            return <HighchartsReact highcharts={Highcharts} options={options} />;
        };
        return loadingShareholding ? (<div style={{ height: "100%", flexGrow: 1 }} className='d-flex align-items-center'>
            <ActivityLoaderMintbox />
        </div>) : shareholdings?.dataSource?.length ? (
            <>
                <div style={{}}>
                    <div style={{
                        position: isMobile ? "" : "absolute", gap: 10, top: `${getDivHeight('.ownershipTabsContainer') / 2}px`, right: 0,
                        transform: isMobile ? "" : "translateY(-50%)", marginBottom: isMobile ? "1.25rem" : ""
                    }}
                        className="d-flex align-items-center">
                        <span className="textESM w-500 dark3">Show interim holdings</span> <Switch value={showInterim} onChange={(e) => setShowInterim(e)} />
                    </div>
                    <div>
                        <MyTable dataSource={shareholdings?.dataSource} columns={columns} id="table-you-want"
                            rowClassName={(record) => record?.category_code % 1 === 0 ? "rowHighlightLevel1" : ""}
                            className="textXS black" pagination={{ hideOnSinglePage: true, pageSize: 50 }} scroll={{ x: "max-content" }} scrollLeftDependency={[]} scrollToLeft={true}
                            expandable={{
                                expandIcon: ({ expanded, onExpand, record }) => {
                                    let c = record?.children?.length > 0;
                                    let checkTH = Object.keys(record || {})?.filter((el) => shareholdings?.columns?.includes(el))?.map(el => record[el])?.find((el) => el?.topHolders?.length > 0);
                                    let style = {
                                        marginTop: "-0.06rem",
                                        marginRight: "0.6rem",
                                        fontSize: "0.75rem",
                                        cursor: "pointer",
                                    };
                                    const handleHolding = () => {
                                        if (topHoldingsExpanded?.includes(record?.name)) {
                                            setTopHoldingsExpanded(prev => { return prev?.filter(el => el !== record?.name) })
                                            console.log(1);
                                        }
                                        else {
                                            setTopHoldingsExpanded([...topHoldingsExpanded, record?.name])
                                            console.log(1, [...topHoldingsExpanded, record?.name]);
                                        }
                                    }
                                    const handleClick = (e) => {
                                        onExpand(record, e)
                                        if (checkTH) {
                                            console.log(1);
                                            handleHolding()
                                        }
                                    }
                                    return (!c && !checkTH) ? <div style={{ marginRight: "1.35rem" }}></div> : (
                                        expanded ? <AiOutlineDown onClick={handleClick} style={style} /> : <AiOutlineRight onClick={handleClick} style={style} />
                                    )
                                },
                                expandRowByClick: isMobile ? true : false
                            }} />
                        <span className='textXS dark4'>
                            (There could be abrupt changes in classifications post Sep 2022 as the new XBRL format for shareholding was introduced. Impacted fields could be FII, DII, FPI etc.)
                        </span>
                    </div>
                </div>
                <Modal open={chartToShow?.name?.length > 0} onCancel={() => setChartToShow({})} onOk={() => setChartToShow({})}
                    footer={<button onClick={() => setChartToShow({})} className="btnBlack ">Close</button>} closable={false} width={"min(40rem, 100%)"}>
                    <div style={{ flex: 1, width: "100%", height: "100%", }}>
                        <div className="textSM black w-500 ms-1 d-flex align-items-center" style={{ marginBottom: "1rem" }}>
                            <span className="textMD black w-500 ms-1 d-flex align-items-center">
                                {chartToShow?.name}
                            </span>
                        </div>
                        <div style={{ marginTop: "1.25rem", marginBottom: "-1rem" }}>
                            <ShareHoldingChart data={chartToShow} />
                        </div>
                    </div>
                </Modal>
                {topHoldings?.name?.length > 0 && <Modal open={topHoldings?.name?.length > 0} onCancel={() => setTopHoldings({})} onOk={() => setTopHoldings({})}
                    footer={<button onClick={() => setTopHoldings({})} className="btnBlack ">Close</button>} closable={false} width={"min(40rem, 100%)"}>
                    <div style={{ flex: 1, width: "100%", height: "100%", }}>
                        <div className="textSM black w-500 ms-1 d-flex align-items-center" style={{ marginBottom: "1rem" }}>
                            <span className="textMD black w-500 ms-1 d-flex align-items-center">
                                {topHoldings?.name}
                            </span>
                        </div>
                        <div style={{ marginTop: "1.25rem", }}>
                            <Table dataSource={topHoldings?.data} columns={thColumns} pagination={false} scroll={{ y: "60vh" }} rowKey={(record) => generateUniqueKey(record?.holder)} />
                        </div>
                    </div>
                </Modal>}
            </>
        ) : (<div style={{ height: "100%", flexGrow: 1 }} className='d-flex align-items-center'>
            <NoDataFound message={`We currently lack sufficient data on the shareholding of ${fundData?.proper_name}.`} msgMaxWidth={'50ch'} />
        </div>
        )
    }

    const tabItems = [
        {
            key: 1,
            value: "share_holdings",
            label: "Share holdings",
            show: true,
            children: <ShareHolding />
        },
        {
            key: 2,
            value: "sast",
            label: "SAST",
            show: sastData?.length,
            children: <SAST loadingSAST={loadingSAST} sastData={sastData} isMobile={isMobile} />
        },
        {
            key: 3,
            value: "insider_trades",
            label: "Insider trades",
            show: Object.keys(bulkBlockDeals?.['insider_trades'] || {})?.length,
            children: <BulkBlockDeals key={generateUniqueKey(1)} type="insider_trades" loadingBulkBlockDeals={loadingBulkBlockDeals} bulkBlockDeals={bulkBlockDeals}
                isMobile={isMobile} />
        },
        {
            key: 4,
            value: "block_deals",
            label: "Block deals",
            show: Object.keys(bulkBlockDeals?.['block_deals'] || {})?.length,
            children: <BulkBlockDeals key={generateUniqueKey(2)} type="block_deals" loadingBulkBlockDeals={loadingBulkBlockDeals} bulkBlockDeals={bulkBlockDeals}
                isMobile={isMobile} />
        },
        {
            key: 5,
            value: "bulk_deals",
            label: "Bulk deals",
            show: Object.keys(bulkBlockDeals?.['bulk_deals'] || {})?.length,
            children: <BulkBlockDeals key={generateUniqueKey(3)} type="bulk_deals" loadingBulkBlockDeals={loadingBulkBlockDeals} bulkBlockDeals={bulkBlockDeals}
                isMobile={isMobile} />
        }
    ].filter(k => k.show)

    return (
        <>
            <div style={{ position: "relative" }}>
                <div className="d-flex align-items-center justify-content-between margin20Bottom ownershipTabsContainer">
                    <CustomSegmentedTab options={tabItems} value={selectedTabItem} onChange={(e) => setSelectedTabItem(e)} isBlock={false} />
                </div>
                {tabItems?.find(x => x.value === selectedTabItem)?.children}
            </div>
        </>
    );
})