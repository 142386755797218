import { Card, Checkbox, Divider, Popover } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useGetAssetType, generateUniqueKey } from 'Libs/utils';
import React from 'react';
import { CardHeader } from './atoms/SmallComponents';
import { CurrencyFormat } from './CurrencyFormat';
import CustomSegmentedTab from './CustomSegmentedTab';
import { changeObjKey } from './DataCustomizer';
import RedGreenText from './RedGreenText';
import StyleBoxIcons from './StyleBoxIcons';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Collapse } from 'reactstrap';
import { IoIosArrowDown } from 'react-icons/io';

const StyleBoxChart = ({ chartData = [], benchName = "", fundName = "", portfolioType = 0, goLiveDate = "", defYrSelect = "1095D", defAmtSelect = "", showOnlySelectedKey = "", xAxisKey = "Date", fundColors = ['#555770', '#83BF6E', '#007AFF', '#FFD966', '#FF55BB', '#0062CC', '#AA55FF', '#FF9C55', '#1A1D1F', '#FF6A55'], isStyleBox = false }) => {
    // portfolioType 1 = curated , 2 = custom, 0 = for individual funds
    // portfolioType = -1 => for showing only one amount value 
    const [isOpen, setIsOpen] = React.useState(false)
    const type = useGetAssetType()
    const yrtabs = chartData !== undefined ? portfolioType === 1 ? [
        { key: "A1", label: "1M", value: "30D", show: chartData["30D"] !== undefined ? JSON.parse(chartData?.["30D"]?.data).length > 0 : false },
        { key: "A2", label: "6M", value: "182D", show: chartData["182D"] !== undefined ? JSON.parse(chartData?.["182D"]?.data).length > 0 : false },
        { key: "A3", label: "YTD", value: "ytd", show: chartData["ytd"] !== undefined ? JSON.parse(chartData?.["ytd"]?.data).length > 0 : false },
        { key: "A", label: "1Y", value: "365D", show: chartData["365D"] !== undefined ? JSON.parse(chartData?.["365D"]?.data).length > 0 : false },
        { key: "b", label: "3Y", value: "1095D", show: chartData["1095D"] !== undefined ? JSON.parse(chartData?.["1095D"]?.data).length !== JSON.parse(chartData?.["365D"]?.data).length : false },
        { key: "c", label: "5Y", value: "1825D", show: chartData["1825D"] !== undefined ? JSON.parse(chartData?.["1825D"]?.data).length !== JSON.parse(chartData?.["1095D"]?.data).length : false },
        { key: "d", label: "10Y", value: "3650D", show: chartData["3650D"] !== undefined ? JSON.parse(chartData?.["3650D"]?.data).length !== JSON.parse(chartData?.["1825D"]?.data).length : false },
        { key: "dm", label: "Live", value: "live", show: true },
        { key: "dmk", label: "Max", value: "max", show: chartData["max"] !== undefined ? JSON.parse(chartData?.["max"]?.data).length !== JSON.parse(chartData?.["3650D"]?.data).length : false },
    ] : [
        { key: "A1", label: "1M", value: "30D", show: chartData["30D"] !== undefined ? JSON.parse(chartData?.["30D"]?.data).length > 0 : false },
        { key: "A2", label: "6M", value: "182D", show: chartData["182D"] !== undefined ? JSON.parse(chartData?.["182D"]?.data).length > 0 : false },
        { key: "A3", label: "YTD", value: "ytd", show: chartData["ytd"] !== undefined ? JSON.parse(chartData?.["ytd"]?.data).length > 0 : false },
        { key: "A", label: "1Y", value: "365D", show: chartData["365D"] !== undefined ? JSON.parse(chartData?.["365D"]?.data).length > 0 : false },
        { key: "b", label: "3Y", value: "1095D", show: chartData["1095D"] !== undefined ? JSON.parse(chartData?.["1095D"]?.data).length !== JSON.parse(chartData?.["365D"]?.data).length : false },
        { key: "c", label: "5Y", value: "1825D", show: chartData["1825D"] !== undefined ? JSON.parse(chartData?.["1825D"]?.data).length !== JSON.parse(chartData?.["1095D"]?.data).length : false },
        { key: "d", label: "10Y", value: "3650D", show: chartData["3650D"] !== undefined ? JSON.parse(chartData?.["3650D"]?.data).length !== JSON.parse(chartData?.["1825D"]?.data).length : false },
        { key: "dm", label: "Max", value: "max", show: chartData["max"] !== undefined ? JSON.parse(chartData?.["max"]?.data).length !== JSON.parse(chartData?.["3650D"]?.data).length : false },
    ] : []

    const chipset = [
        { "key": "a", label: "₹ 5,000", value: 5000 },
        { "key": "b", label: "₹ 10,000", value: 10000 },
        { "key": "c", label: "₹ 50,000", value: 50000 },
    ]
    const [noChart, setnoChart] = React.useState(false);
    const [chartType, setChartType] = React.useState(portfolioType === 1 ? defYrSelect || yrtabs[4]?.value : defYrSelect || yrtabs[0]?.value)
    const [chartAmount, setChartAmount] = React.useState(defAmtSelect || chipset[1].value)



    var a = JSON.parse(chartData?.[chartType]?.data || null) !== null && JSON.parse(chartData?.[chartType]?.data || null) !== undefined ? showOnlySelectedKey !== "" ? Object.keys(JSON.parse(chartData?.[chartType]?.data || null)[0] || {})?.filter((i) => !i.includes("ret_") && i.toLowerCase() !== xAxisKey.toLowerCase()).filter((i) => String(i) === String(showOnlySelectedKey)) : Object.keys(JSON.parse(chartData?.[chartType]?.data || null)[0] || {})?.filter((i) => !i.includes("ret_") && i.toLowerCase() !== xAxisKey.toLowerCase()) : ["Fund", "Benchmark"]

    const [nonSelectedItem, setNonSelectedItems] = React.useState([])

    try {
        var options = {
            chart: {
                backgroundColor: "transparent",
                height: "25%",
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                visible: false,
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                title: {
                    text: ''
                },
                gridLineColor: 'var(--grey1)',
            },
            xAxis: [{
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%e %b',
                    week: '%e %b',
                    month: '%b %y',
                    year: '%Y'
                },
                lineColor: 'var(--grey1)',
                tickColor: 'var(--grey1)',
                plotLines: portfolioType === 1 && chartType.name !== "Live" ? [{
                    color: '#000', // Red
                    value: goLiveDate, // Position, you'll have to translate this to the values on your x axis
                    dashStyle: 'dash',
                    width: 1,
                    label: {
                        text: "Inception", rotation: 0, x: 5,
                        style: {
                            color: "var(--dark3)",
                            // fontWeight: 'bold'
                        }
                    },
                    color: 'var(--dark2)',
                }] : []
            }
            ],
            legend: {
                enabled: false
            },
            plotOptions: {
                areaspline: {
                    threshold: null
                },
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                shared: true,
                valueDecimals: 0,
                valuePrefix: '₹',
            },
            credits: {
                enabled: false
            },
            boost: {
                useGPUTranslations: true,
            },
            series: a.map((item, index) => {
                return {
                    name: item === "Benchmark" ? benchName || item : fundName || item,
                    visible: ![...nonSelectedItem].includes(item),
                    turboThreshold: 9e9,
                    color: fundColors[index],
                    tooltip: {
                        valueSuffix: ' ({point.ret_' + item + '}%)',
                    },
                    type: "spline",
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, fundColors[index] + "77"],
                            [0.7, fundColors[index] + "01"]
                        ]
                    },

                    data: changeObjKey(chartData?.[chartType], [String(item), xAxisKey], ["y", "x"], "mfetfChart", (chartAmount / 10000))
                }
            }),
            scrollbar: {
                enabled: false
            },
            responsive: {
                rules: [
                    {
                        chartOptions: {
                            chart: {
                                height: 300
                            }
                        },
                        condition: {
                            maxWidth: 760
                        }
                    }
                ]
            }
        };
    } catch (error) {
        setnoChart(true)
    }


    const ContentBeforeChart = () => {
        const [open, setOpen] = React.useState(false);
        const handleModalOpen = (val) => {
            setOpen(val)
        }

        const content = () => (
            <div style={{
                background: "var(--white)", width: "100%", display: "flex", flexDirection: "column", gap: "1rem", maxHeight: "60vh", overflowY: "auto", padding: "1rem",
                borderRadius: "var(--borderRadius)"
            }} className='scrollbox'>
                {
                    a.map((item, index) => {
                        var val = (mintData[mintData?.length - 1][item] * (chartAmount / 10000)).toFixed(2)
                        var valXIRR = (mintData[mintData?.length - 1]["ret_" + item])?.toFixed(2)
                        return val && val !== 'NaN' ?
                            <div className='col' key={generateUniqueKey(index)}>
                                <div className='d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                    <Checkbox
                                        style={{ alignItems: "flex-start" }}
                                        checked={![...nonSelectedItem].includes(item)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                var ind = nonSelectedItem.indexOf(item)
                                                var a = [...nonSelectedItem]
                                                a.splice(ind, 1)
                                                setNonSelectedItems(a)
                                                //pop
                                            } else {
                                                //push
                                                setNonSelectedItems([...nonSelectedItem, item])
                                            }
                                        }}
                                    >
                                    </Checkbox>
                                    <span className='textSM' style={{ color: 'var(--black)', display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                        <StyleBoxIcons fundColors={fundColors} style_box_code={index} />{item === "Benchmark" ? benchName || item : fundName || item}
                                    </span>
                                </div >
                                <div className='textXS w-500 dark3' style={{ flex: 1, marginTop: "0.25rem" }}>
                                    {CurrencyFormat(val, 'long')}<span className='textXS w-500'>{RedGreenText({ text: ` (${valXIRR}%)`, value: valXIRR })}</span>
                                </div>
                            </div> : null
                    })
                }
            </div>
        )
        try {
            if (chartType) {
                var mintData = JSON.parse(chartData?.[chartType]?.data)
            }
            return <React.Fragment>
                <div style={{ gap: '1rem', flexWrap: 'wrap', alignItems: "center" }} className='displayNoneMobile'>
                    <Divider style={{ margin: "1rem 0rem" }} />
                    <div className='grid'>
                        {
                            a.map((item, index) => {
                                var val = (mintData[mintData?.length - 1][item] * (chartAmount / 10000)).toFixed(2)
                                var valXIRR = (mintData[mintData?.length - 1]["ret_" + item])?.toFixed(2)
                                return val && val !== 'NaN' ?
                                    <div className='col' key={generateUniqueKey(index)}><Checkbox
                                        style={{ alignItems: "flex-start" }}
                                        checked={![...nonSelectedItem].includes(item)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                var ind = nonSelectedItem.indexOf(item)
                                                var a = [...nonSelectedItem]
                                                a.splice(ind, 1)
                                                setNonSelectedItems(a)
                                                //pop
                                            } else {
                                                //push
                                                setNonSelectedItems([...nonSelectedItem, item])
                                            }
                                        }}
                                    >
                                        <div key={index}>
                                            <span>
                                                <span className='textSM' style={{ color: 'var(--black)', display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                                    <StyleBoxIcons fundColors={fundColors} style_box_code={index} />{item === "Benchmark" ? benchName || item : fundName || item}
                                                </span>
                                                <div className='textSM w-normal dark3' style={{ flex: 1, marginTop: "0.25rem" }}>
                                                    {CurrencyFormat(val, 'long')}<span className='textSM w-normal'>{RedGreenText({ text: ` (${valXIRR}%)`, value: valXIRR })}</span>
                                                </div>
                                            </span>
                                        </div >
                                    </Checkbox></div> : null
                            })
                        }

                    </div>
                </div>
                <div className='displayBlockMobile'>
                    <Popover open={open} trigger="click" content={content} arrow={false} placement='topLeft' style={{ background: "var(--white)", width: "100%" }}
                        overlayInnerStyle={{ padding: "0rem", position: "absolute", top: "5rem", background: "var(--white)", width: "calc(100vw - 2.5rem)" }}
                        onOpenChange={(e) => handleModalOpen(e)} getPopupContainer={triggerNode => triggerNode.parentNode}
                        autoAdjustOverflow={{ adjustX: false, adjustY: false }}>
                        <div style={{ border: "1px solid var(--grey1)", padding: "0.5rem 1rem", borderRadius: "var(--borderRadius)", gap: "1.25rem" }}
                            className='d-flex justify-content-between align-items-center'>
                            <span className='textSM w-500 black'>All styleboxes</span>
                            <IoIosArrowDown />
                        </div>
                    </Popover>
                    <br />
                    <CustomSegmentedTab
                        isBlock={false}
                        className='performanceChartYrSegmented'
                        style={{ backgroundColor: "transparent", padding: 0 }}
                        options={yrtabs}
                        value={chartType}
                        onChange={(e) => {
                            setChartType(e)
                        }}
                    />
                </div>
            </React.Fragment>
        } catch (error) {
            return <></>
        }
    };



    const ContentAfterChart = () => {
        return <div>
            {
                portfolioType === 1 || portfolioType === 2 ?
                    <>
                        <div className='textXS' style={{ paddingTop: "1rem" }}>
                            <b>Notes and Disclaimer:</b>
                            <ol style={{ marginBottom: 0, paddingLeft: '16px' }}>
                                <li>Past performance is not an assurance of future performance.</li>
                                <li>Back-tested calculations do take into account an estimate of potential transaction cost incurred. Calculations do not take into account taxation.</li>
                                <li>Live performance is based on actual portfolio performance since it went live.</li>
                            </ol>
                        </div>
                    </>
                    :
                    <>
                        <div className='textXS' style={{ paddingTop: "1rem", whiteSpace: 'pre-wrap' }}>
                            <b>Note: </b>Past performance is not an assurance of future performance. The chart has been shown based on EOD {type.value === 4 ? "prices" : "NAVs"} of the {type.dname}.
                        </div>
                    </>
            }
        </div>
    }

    const ChartContent = () => {
        return (
            <React.Fragment>
                <Card bordered={false} style={{ gap: "1rem", width: "100%", flex: 1 }} className='displayNoneMobile'>
                    <div className="compare-collapse" style={{ backgroundColor: "transparent", borderBottom: "0px", padding: "0px", alignItems: "center" }} id={`id`} key={`id`}>
                        <div style={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center", gap: "2rem" }}>
                            <div onClick={() => {
                                setIsOpen(!isOpen)
                            }} className='fontH2' style={{ flex: 1, color: 'var(--black)', display: "flex", alignItems: "center", gap: "0.3rem" }}>
                                <CardHeader heading={'StyleBoxes Historical Performance'} headingType={1} />
                            </div>
                            {isOpen && <div style={{ display: 'flex', justifyContent: 'right', borderTop: "1px solid var(--grey3)", borderBottom: "1px solid var(--grey3)", padding: "0.4rem" }} >
                                <CustomSegmentedTab
                                    isBlock={false}
                                    className='performanceChartYrSegmented'
                                    style={{ backgroundColor: "transparent", padding: 0 }}
                                    options={yrtabs}
                                    value={chartType}
                                    onChange={(e) => {
                                        setChartType(e)
                                    }}
                                />
                            </div>}
                            <div onClick={() => {
                                setIsOpen(!isOpen)
                            }} className="black">{isOpen ? <FiChevronUp size={'18px'} /> : <FiChevronDown size={'18px'} />}</div>
                        </div>
                    </div>
                    <Collapse style={{ border: "1px", alignItems: "center" }}
                        isOpen={isOpen}
                        toggler={`#id`}
                        id={`#body`}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '1rem', }}>
                            <ContentBeforeChart />
                            <HighchartsReact highcharts={Highcharts} options={options} />
                            {/*<ContentAfterChart /> */}
                        </div>
                    </Collapse>
                </Card>
                <Card className='displayBlockMobile mobCardBorderRadius0'>
                    <div className='margin20Bottom'>
                        <CardHeader heading={'StyleBoxes Historical Performance'} headingType={1} />
                    </div>
                    <ContentBeforeChart />
                    <HighchartsReact highcharts={Highcharts} options={options} />
                </Card>
            </React.Fragment>
        )
    }
    try {
        return (
            noChart ? <div className='textXS' style={{ margin: "4rem", textAlign: "center" }}>
                No chart data available!
            </div>
                : <ChartContent />
        );
    } catch (error) {
        return <div className='textSM' style={{ margin: "4%", textAlign: "center" }}>
            No chart data available!
        </div>
    }
};
export default StyleBoxChart;