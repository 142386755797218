import { createSlice } from '@reduxjs/toolkit'

export const stockpricesSlice = createSlice({
  name: 'stockprices',
  initialState: {
    value: [],
  },
  reducers: {
    updateStockPrices: (state, action) => {
      if (action.payload === "close") {
        state.value = action.payload
      } else {
        state.value = {
          ...state.value,
          ...action.payload
        }
      }
    },
  },
})

export const subscribedStocksSlice = createSlice({
  name: 'subscribedStocks',
  initialState: {
    value: {},
  },
  reducers: {
    updatesubscribedStocks: (state, action) => {
      if (action.payload === "close") {
        state.value = action.payload
      } else {
        state.value = {
          ...state.value,
          ...action.payload
        }
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { updatesubscribedStocks } = subscribedStocksSlice.actions
export const { updateStockPrices } = stockpricesSlice.actions