import React from "react";
import { Helmet } from "react-helmet";

const Head = ({ ...props }) => {

  const safeDecodeURI = (str) => {
    try {
      return decodeURI(str);
    } catch (e) {
      let result = '';
      let currentIndex = 0;

      while (currentIndex < str.length) {
        let nextPercent = str.indexOf('%', currentIndex);

        if (nextPercent === -1) {
          result += str.slice(currentIndex);
          break;
        }

        if (nextPercent !== currentIndex) {
          result += str.slice(currentIndex, nextPercent);
          currentIndex = nextPercent;
        }

        let segment = str.slice(nextPercent, nextPercent + 3);
        try {
          result += decodeURI(segment);
          currentIndex += 3;
        } catch (decodeError) {
          result += '%';
          currentIndex++;
          if (currentIndex < str.length && str[currentIndex] === '%') {
            result += '%';
            currentIndex++;
          }
        }
      }

      return result;
    }
  };

  return (
    <Helmet>
      <title>{props.title ? props.title + " | " : ""} sharpely.in</title>
      <meta name="description" content={props.description || ""} />
      <meta name="keywords" content={(props.keywords ? props.keywords + ", " : "") + "sharpely, sharpely stock marketing, sharpely mutual fund, Sharpely Stock Screening, sharpely Mumbai, Sharpely Stock Signals, Sharpely Baskets, Sharpely Pricing, mintbox solutions private limited"} />
      <meta property="og:title" content={`${props.title ? props.title + " | " : ""} sharpely.in`} />
      <meta property="og:description" content={props.description || ""} />
      <meta property="og:image" content={props.image ? props.image : `https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/sharpely-asset/sharpely_logos/dark_name_logo.webp`} />
      <meta property="og:url" content={safeDecodeURI(String(window.location.href))} />
    </Helmet>
  );
};
export default Head;
