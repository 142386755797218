import { toast } from "react-hot-toast"
import apiHelper from "Services/api/ApiHelper"
import { Avatar } from 'antd';
import { baseBrokerURL, baseImgURL } from 'Assets/Constant';
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const paytm_api_key = 'd6429901b3ee44bc8c0b5dc60a2e4832'
const Paytm_api_secret_key = '38a978c2eeda4d3aa08bab15453dcfff'

const angel_api_key = 'B36g6dpj'
const angel_api_secret_key = '122d88f6-db42-4987-8b23-0abc63579d86'

const upstox_api_key = '45cc6680-bd5f-4a82-995d-36663a3507b8'

const fyers_api_key = 'NCCZYAYU8U-102'
const dhan_api_key = '5a4d95a6'
const dhan_api_secret_key = '83b31d5e-2543-4a47-93b0-b41853837bb2'
const five_paisa_key = 'EtgoaUf5NxR6OJkPKMUyEZL4Zaxsf2WB'

const redirect_uri = 'https%3A%2F%2Fsharpely.in%2Fbroker-page'
const redirect_url = 'https://sharpely.in/broker-page'

const redirectToBrokerLogin = ({ broker, user, zerodhaAPIKey = "" }) => {
    // zerodhaAPIKey is passed only for first time kite connection... else use users saved zerodhaAPIKey
    switch (broker) {
        case 'paytm': window.location.replace(`https://login.paytmmoney.com/merchant-login?apiKey=${paytm_api_key}&state`); return;
        case 'angel': window.location.replace(`https://smartapi.angelbroking.com/publisher-login?api_key=${angel_api_key}`); return;
        case 'upstox': window.location.replace(`https://api.upstox.com/v2/login/authorization/dialog?response_type=code&client_id=${upstox_api_key}&redirect_uri=${redirect_url}`); return;
        case 'fyers': window.location.replace(`https://api.fyers.in/api/v2/generate-authcode?client_id=${fyers_api_key}&redirect_uri=${redirect_uri}&response_type=code&state=sample_state`); return;
        case 'dhan': {
            const dem = async () => {
                let res2 = await apiHelper({ apiName: 'generateConsent', data: { clientId: user?.id, broker: 'dhan' }, saveResponse: '' })
                res2 = JSON.parse(res2)
                console.log("generateConsent", res2)
                if (res2 && res2?.consentStatus === 'GENERATED') {
                    window.location.replace(`https://auth.dhan.co/consent-login?consentId=${res2?.consentId}`);
                    return;
                }
                else {
                    toast.error("Something went wrong. Please try again")
                    return;
                }
            }
            dem();
        }
        case '5paisa': window.location.replace(`https://dev-openapi.5paisa.com/WebVendorLogin/VLogin/Index?VendorKey=${five_paisa_key}&ResponseURL=${redirect_url}`); return;
        case 'zerodha': window.location.replace(`https://kite.zerodha.com/connect/login?v=3&api_key=${zerodhaAPIKey?.length ? zerodhaAPIKey : user?.Client?.[0]?.ZerodhaAPIKey}`); return;
        default: return
    }
}

const redirectToBrokerSignup = ({ broker }) => {
    switch (broker) {
        case 'paytm': return;
        case 'angel': window.open(`https://www.angelone.in/open-demat-account`, '_blank'); return;
        case 'upstox': window.open(`https://upstox.com/open-account/?f=5PAMC6`, '_blank'); return;
        case 'fyers': return;
        case 'dhan': window.open(`https://dhan.co/open-demat-account/`, '_blank'); return;
        case '5paisa': return;
        default: return
    }
}

const BrokerIcon = ({ brokerName, type = "circle", height = "50px", width = "50px", className = "", style }) => {
    //fundType = 1 => etfs, 2 => mf, 4 => stocks
    console.log(brokerName)
    const brokerList = [
        { Id: 0, Name: "by AMC", imgSource: baseImgURL + 'mintbox_logo.svg' },
        { Id: 1, Name: "angel", imgSource: baseBrokerURL + 'angel.webp' },
        { Id: 2, Name: "paytm", imgSource: baseBrokerURL + 'paytmmoney.png' },
        { Id: 3, Name: "dhan", imgSource: baseBrokerURL + 'dhan.jpeg' },
        { Id: 4, Name: "upstox", imgSource: baseBrokerURL + 'upstox.png' },
        { Id: 5, Name: "fyers", imgSource: baseBrokerURL + 'fyers.png' },
        { Id: 6, Name: "zerodha", imgSource: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAZlBMVEX////2RhrbNCz1OAD6qpvso6DZIhj4RxjvRSXZIBTZNC78z8b2LAD80Mf2OgD4f2nkdnL4e2P4gGnkcW36ppj6oZL1JgD3c1z/+Pb+7OfyWT7XJiDsNgfXAADiamX81M3rmpf1zcxHxpeOAAACbElEQVR4nO2dCU4CQRBFR0YdlMV9RUXvf0k1xqgRhlm6f3UV793gpfr3rwYSqgoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgrO+PrTgZqESnC1rC27v7lWCdXNgQH0m8qsWcws/oWD8CS5tBC9UgrN59AkaZVA3QaMjqpug0SUjm+AiegapCe+C1EQmQWoiFeFrggxmEiSDqbDKoPCI2gheqgRZ1bwLUhPeBeNnMLxg9AzOags/pWD0mtiDDFr4URPpYFXLJKh7TfCizyPIJZNMMH5NRBcMn8Hwr4n4GQw+Qb58ySTIqpZMMHxNRL9k9qAmoguGzyCrmnfB8LdoeMHwGaQmsrCUCT7YHNHmUSVoNMNmujoXKupz2ExPJydKRfVB/RScaBW1ffgl+KF4FVTxW1A8Rd1e+iOoVhRN8begWFFTGn8F1deNgWC4LP4XDFYamwRDZXGzYKCDuk1QXP35SmO7YJAstgmGWODaBQNkcZeg+wVut6Dz0ugi6HqB6yboOItdBd2WRndBp1nsI+jyoPYTdLjA9RXUTjHB77z7Czpb4IYIiqc4LovDBB2VxlBBN6UxXNDJAjdG0MVLY5yggyyOFSxecbxg4QtcCsGiF7g0ggUvcKkEi81iOsFCs5hSsMgpphUscIFLLVjcQU0vWFhp5BAUl0Z7FvMIiqfYlsVcgsW8+vMJFpLFnIJFlEZeQfUncAaC5gc1v6BxaSgETUtDI2i4wKkEzbKoEzR69SsFTb620QoaLHBqQfkU9YLqBe5pdazn+UX1X0FVtT4y4fVNZggAAAAAAAAAAAAAAAAAAAAAAAAAAAAAI3gHMZFmvm1YbhgAAAAASUVORK5CYII=' },
        { Id: 7, Name: "5paisa", imgSource: baseImgURL + 'stockimgfinal/' + '5PAISA.webp' },
    ]

    return <Avatar
        shape={type}
        key={brokerName}
        title={brokerName}
        alt={brokerName}
        size={'small'}
        style={{ ...style, display: 'flex', alignItems: 'center', objectFit: 'cover', verticalAlign: 'middle', backgroundColor: "var(--grey1)", color: "var(--dark3)", height: height, width: width, flexShrink: 0 }}
        src={(brokerList.filter((s) => s.Name === brokerName)).length !== 0 ? (brokerList.filter((s) => s.Name === brokerName))[0].imgSource : brokerList[0].imgSource}
    >{brokerName?.[0]}</Avatar>
}
export { redirectToBrokerLogin, redirectToBrokerSignup, BrokerIcon };
