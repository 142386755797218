import { CustomSelect } from 'Components/CustomComponents/CustomSelect';
import { isChildVisible } from "Libs/scroll";
import { isSubsequence, sortDrop } from "Libs/search";
import { makeid } from "Libs/utils";
import { Input, Modal, Segmented, Select } from "antd";
import React from "react";
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { MdEdit } from "react-icons/md";
import { QueryOptionsDropdown } from "./QueryBuilderDropdown";
import { allowedHistoryOptionsAndFrequencies, directionTypes, groupColumnNames, maTypes, patternOutputs, priceItems, priceValues, stringParser, timeframes, withinQuotesFields } from './ScreenerUtils';
import { QueryOptionsDropdownV2 } from './QueryOptionsDropdown';
import LabelInputNew from 'Components/atoms/LabelInputNew';
import AdminServicesPY from 'Services/api/AdminServicesPY';
import { useDispatch } from 'react-redux';
import { toggleMetricDescModal } from 'Libs/redux/KnowledgeBase/reduxSlice';
import AdminServices from 'Services/api/AdminServices';

export const FunctionsEditor = React.memo(({ open, toggle = () => { }, timeframe = "1d", allowFuncChange = false, selectedOption = {}, functions, screenerMetrics, onDone = () => { }, isAdvance = false,
    width = 'min(100%, 35rem)', indices = [], variables = {}, typesToHide = [], allFundsData = [] }) => {
    // type = 1 => functions
    // type = 2 => sector etc.
    const [query, setquery] = React.useState(selectedOption?.query)
    const [isChangingOpt, setIsChangingOpt] = React.useState(false);
    const [metricChanged, setMetricChanged] = React.useState(false);
    const [searchText, setSearchText] = React.useState('');
    const [queryKey, setQueryKey] = React.useState(selectedOption?.metric)
    const queryBuilderRef = React.useRef();
    const required_inputs = stringParser(query?.expression_details?.sh_req_inputs || "[]")
    const required_inputs_vals = query?.expression_arr?.slice(0, required_inputs?.length)
    const optional_inputs = stringParser(query?.expression_details?.sh_opt_inputs || "[]")
    const optional_inputs_vals = query?.expression_arr?.slice(required_inputs?.length, query?.expression_arr?.length)
    const dispatch = useDispatch();
    // var showPeriod = true

    const getFunctionDoc = async () => {
        let doc = '';
        try {
            let t = await AdminServices.readAWSFile('functions-docs', query?.expression_details?.sh_name);
            if (t.type) {
                doc = t.data
            } else {
                doc = ''
            }
        } catch (error) {
            console.log(error);
            doc = ''
        }
        if (doc) {
            dispatch(toggleMetricDescModal({
                value: true,
                params: {
                    title: query?.expression_details?.display_name,
                    description: doc
                }
            }))
        }
    }

    const valueFromString = (str, item) => {
        var arr = String(str)?.split(/\s{0,3}=\s{0,3}/g)
        var keyValue = item?.type === "<class 'int'>" ? parseInt(Number(arr?.[1])) : item?.type === "<class 'float'>" ? parseFloat(Number(arr?.[1])) : arr?.[1];
        return keyValue
    }

    const getOptionalParamsString = (temp, optional_inputs_vals) => {
        return temp?.concat(optional_inputs_vals)?.join(", ")
    }

    const getOptions = React.useCallback(() => {
        let temp = sortDrop(searchText, [...functions], ['value', 'label'])
        return temp;
    }, [searchText])

    const options = getOptions();

    const handleKeyDown = (event) => {
        let menuItems = Array.from(document.querySelectorAll('.queriesDropdown .queryDropdownItem'));
        const parent = document.querySelector('.queriesDropdown')
        if (menuItems?.length <= 0) {
            return event
        }
        let maxIndex = menuItems.length - 1;
        let menuIndex = menuItems?.findIndex((ele) => ele.getAttribute('data-focused') !== null);
        if (!menuIndex && menuIndex !== 0) {
            menuItems[0].setAttribute('data-focused', 'active');
        }
        else if (event.key === 'ArrowUp' && menuIndex > 0) {
            event.preventDefault();
            menuItems[menuIndex]?.removeAttribute('data-focused');
            menuItems[menuIndex - 1].setAttribute('data-focused', 'active');
            if (!isChildVisible(parent, menuItems[menuIndex - 1])) {
                parent.scrollTo({ top: menuItems[menuIndex - 1].offsetTop - parent.offsetTop - 110, behavior: "smooth" });
            }
        }
        else if (event.key === 'ArrowDown' && menuIndex < maxIndex) {
            event.preventDefault();
            menuItems[menuIndex]?.removeAttribute('data-focused');
            menuItems[menuIndex + 1].setAttribute('data-focused', 'active');
            if (!isChildVisible(parent, menuItems[menuIndex + 1])) {
                parent.scrollTo({ top: menuItems[menuIndex + 1].offsetTop - parent.offsetTop - 110, behavior: "smooth" });
            }
        }
        else if (event.key === 'Enter' && menuIndex >= 0 && menuIndex <= maxIndex) {
            menuItems[menuIndex]?.click()
        }
    }

    const handleDocumentClick = (e) => {
        let target = document.querySelector('.queryDropdownCont');
        let currTarget = e.target
        let classNames = ['queriesDropdown', 'queryDropdownItem', 'queryDropdownTabCont', 'queryDropdownTabItem', 'queryDropdownInput', 'editModalDropdownCont', 'headingEditModal']
        do {
            if (target === currTarget || classNames?.includes(currTarget.className)) {
                return;
            }
            else if (currTarget === target?.querySelector('.queryDropdownItem') || currTarget === target?.querySelector('.queryDropdownTabItem')) {
                return;
            }
            currTarget = currTarget.parentNode
        }
        while (currTarget) {
            if (isChangingOpt) {
                setIsChangingOpt(false);
            }
        }
    }

    const handleQueryChange = (item) => {
        const selectedOption = item;
        if (item?.type === 'Metric T' || item?.type === 'Metric F') {
            setquery({
                expression: item?.label,
                expression_arr: [],
                type: "metric",
                expression_details: item,
                isMetric: true,
            })
        }
        else {
            let exp = `${selectedOption?.sh_name}(`;
            var selectedOptionReqInputs = stringParser(selectedOption?.sh_req_inputs || "[]")
            let arr = [...selectedOptionReqInputs];
            let exp_arr = []
            stringParser(selectedOption?.sh_opt_inputs || "[]")?.map((item) => {
                let v = item?.default
                v = item?.name + "=" + (withinQuotesFields.includes(item?.name) ? `"${v}"` : v)
                arr.push(v);
            })

            for (let i = 0; i < arr?.length; i++) {
                exp += arr[i] + (i === arr?.length - 1 ? "" : ", ")
                exp_arr?.push(arr[i])
            }
            exp += ')'



            let exp2 = `${selectedOption?.label}(`;
            // var selectedOptionReqInputs = stringParser(selectedOption?.sh_req_inputs || "[]")
            let arr2 = [];
            let exp_arr2 = []
            stringParser(selectedOption?.sh_opt_inputs || "[]")?.map((item) => {
                let v = item?.default
                v = item?.name + "=" + (withinQuotesFields.includes(item?.name) ? `"${v}"` : v)
                arr2.push(v);
            })
            for (let i = 0; i < arr2?.length; i++) {
                exp2 += arr2[i] + (i === arr2?.length - 1 ? "" : ", ")
                exp_arr2?.push(arr[i])
            }
            exp2 += ')'

            setquery({
                expression: exp,
                // basic_exp: exp?.replace(selectedOption?.sh_name, selectedOption?.label || selectedOption?.sh_name),
                expression_arr: exp_arr,
                expression_details: selectedOption,
                type: "func"
            })
        }
        let index = queryKey?.split('_')?.[0];
        setQueryKey(index + '_' + selectedOption?.key)
        setIsChangingOpt(false);
        setMetricChanged(true);
        setSearchText("");
        //console.log(1)
    }

    const onClickEdit = () => {
        if (allowFuncChange) {
            setSearchText('');
            setIsChangingOpt(true);
            //console.log(2)
        }
    }

    React.useEffect(() => {
        if (isChangingOpt === true) {
            setTimeout(() => {
                document.addEventListener('click', handleDocumentClick);
            }, 1000)
        }
        else {
            document.removeEventListener('click', handleDocumentClick);
        }
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        }
    }, [isChangingOpt])

    React.useEffect(() => {
        if (query?.expression_details?.type === 'Patterns') {
            if (!query?.patternOutput && query?.patternOutput !== 0) {
                setquery({
                    ...query,
                    patternOutput: 0
                })
            }
        }
    }, [query]);

    const checkColNameDataPresent = () => {
        // ////console.log("lala", JSON.stringify(required_inputs_vals), required_inputs?.filter((el) => el === 'col_name'))
        if (required_inputs?.filter((el) => el === 'col_name')?.length > 0) {
            return (JSON.stringify(required_inputs_vals)?.includes("col_name"))
        }
        return false
    }

    var showPeriod = optional_inputs_vals?.map(element => {
        return [`"MKT"`, `"SEC"`, `"INDGRP"`, `"IND"`].concat(indices?.map((item) => `"${item?.IndexNameCaps}"`))?.filter(str => element?.includes(str))?.length > 0
    });
    showPeriod = showPeriod?.filter((i) => i === false)?.length === optional_inputs_vals?.length

    let modPatternOutputs = query?.expression_details?.type === 'Patterns' ? patternOutputs?.filter((el) => {
        let st = String(query?.expression_details?.pattern_output || "");
        st = st?.split(',')?.map(Number)
        return st?.includes(el?.value)
    }) : [];

    const setQueryVal = (temp, setType = 2, temp2) => {
        // setType ==3 for setting both req and opt inputs at once. temp for required and temp2 for optional
        if (setType === 1) {
            // var optD = !showPeriod ? optional_inputs_vals?.filter(element => {
            //     ////console.log("org", element)
            //     return [`"MKT"`, `"SEC"`, `"INDGRP"`, `"IND"`].filter(str => element.includes(str))?.length > 0
            // }) : optional_inputs_vals
            setquery({
                ...query,
                expression: query?.expression_details?.key + "(" + getOptionalParamsString(temp, optional_inputs_vals) + ")",
                expression_arr: temp?.concat(optional_inputs_vals)
            })
        } else if (setType === 3) {
            setquery({
                ...query,
                expression: query?.expression_details?.key + "(" + getOptionalParamsString(temp, optional_inputs_vals) + ")",
                expression_arr: temp?.concat(temp2)
            })
        }
        else {
            // var optD = !showPeriod ? temp?.filter(element => {
            //     ////console.log("org2", element)
            //     return [`"MKT"`, `"SEC"`, `"INDGRP"`, `"IND"`].filter(str => element.includes(str))?.length > 0
            // }) : temp
            setquery({
                ...query,
                expression: query?.expression_details?.key + "(" + required_inputs_vals?.concat(temp)?.join(", ") + ")",
                expression_arr: required_inputs_vals?.concat(temp)
            })
        }

    }

    const getByOptions = (selectedMetric) => {
        let opts = [...groupColumnNames];
        if (!(['average', 'prev_period', 'minimum', 'maximum', 'median']?.includes(query?.expression_details?.value))) {
            opts = opts?.filter((el) => el?.tabType !== 'History')
        }
        if (['score', 'percentile', 'rank']?.includes(query?.expression_details?.value)) {
            opts = opts?.filter((el) => el?.tabType === 'CrossSection');
        }
        else if (query?.expression_details?.value === "prev_period") {
            opts = opts?.filter((el) => el?.tabType !== 'CrossSection')
        }
        else {
            opts = opts?.concat(indices?.filter(el => el?.IndexNameCaps !== 'all')?.map((item, i) => {
                return { key: item?.IndexName, label: item?.IndexName, value: `"${item?.IndexNameCaps}"`, tabType: "Indices" }
            }))
        }
        if (query?.expression_details?.value === 'prev_period') {
            opts = opts?.map((el) => {
                return {
                    ...el,
                    label: el?.label + ' ago'
                }
            })
        }
        else if (['average', 'score', 'rank', 'percentile', 'minimum', 'maximum', 'median']) {
            opts = opts?.map((el) => {
                return {
                    ...el,
                    label: el?.tabType === 'CrossSection' || el?.tabType === 'Indices' ? `All stocks in ${el?.label}` : el?.tabType === 'History' ?
                        `N ${el?.label}` : el?.label
                }
            })
        }
        let t1 = required_inputs?.[0] === 'col_name';
        let isMetricSelected, frequency;
        if (t1) {
            let m = required_inputs_vals?.[0];
            let tvariables = Object.values(variables || {});
            if (tvariables?.find((el) => el?.name === m) || (selectedMetric?.name && selectedMetric?.queries?.length > 0)) {
                let tvar = (selectedMetric?.name && selectedMetric?.queries?.length > 0) ? selectedMetric : tvariables?.find((el) => el?.name === m);
                let ch = Object.values(tvar?.queries || {}).find((el) => {
                    let c1 = el?.isMetric;
                    let c2 = el?.expression_details?.frequency === "D";
                    return c1 && c2;
                })
                if (ch) {
                    frequency = "D"
                }
                else {
                    frequency = "M"
                }
            }
            else {
                m = selectedMetric ? selectedMetric : screenerMetrics?.find((el) => el?.metric_col_code === m);
                if (m?.key) {
                    isMetricSelected = true;
                }
                frequency = m?.frequency;
            }
        }

        //console.log("lalalal", frequency)
        if (frequency) {
            let allowedoptions = allowedHistoryOptionsAndFrequencies?.find((el) => el?.frequency === frequency);
            opts = opts?.map((ele) => {
                if (ele?.tabType === 'History') {
                    if (allowedoptions?.allowedFields?.includes(ele?.value)) {
                        let allInd = allowedoptions?.allowedFields?.findIndex((el) => el === ele?.value);
                        return {
                            ...ele,
                            value: allowedoptions?.value?.[allInd]
                        }
                    }
                    else return null
                }
                else return ele;
            })?.filter((el) => el)
        }
        return opts;
    }

    const getItemLayoutType = React.useCallback(() => {
        let result = 'horizontal';
        if (query?.expression_details?.category === 'Fundamental') {
            result = 'vertical';
        }
        return result;
    }, [query?.expression_details?.value]);

    const itemLayoutType = getItemLayoutType();

    return (
        <React.Fragment>
            <Modal open={open} centered styles={{
                mask: {
                    backgroundColor: "#000000A1"
                }
            }} footer={null} onCancel={toggle} onOk={toggle} width={width} closable={false}>
                <div className="" style={{ flex: 1 }}>
                    {isChangingOpt && allowFuncChange ? <div style={{ position: "relative" }} onClick={() => { queryBuilderRef?.current.focus() }} className='editModalDropdownCont'>
                        <input autoFocus={true} value={searchText} placeholder='Select' style={{
                            outline: "none",
                            borderRadius: "var(--borderRadius)", padding: "0.3rem 0.6rem",
                            border: "1px solid var(--grey1)"
                        }} onChange={(e) => { setSearchText(e.target.value) }} className='textSM w-500 dark4 queryDropdownInputv2' ref={queryBuilderRef} onKeyDown={handleKeyDown} />
                        {options?.length > 0 ?
                            <QueryOptionsDropdown
                                isAdvance={isAdvance}
                                typesToHide={typesToHide}
                                options={options}
                                tabsType="func"
                                onSelect={(item) => {
                                    handleQueryChange(item);
                                }} allowDDPosChange={false}
                                showDescBox={false}
                                hideVarOpt={true} dropdownPositions={{ top: "100%", left: "0" }}
                            /> : null}
                    </div> : <div className='headingEditModal mb-2' style={{ gap: 5, display: "flex", alignItems: "center", gap: "0.75rem" }} >
                        <div className='textLG w-bold dark4 ' style={{ userSelect: "none" }} >{query?.expression_details?.display_name || query?.expression_details?.label}</div>
                        {allowFuncChange && <MdEdit color="var(--black)" className="textLG colorPrimaryOnHover" onClick={onClickEdit} style={{ cursor: "pointer", }} />}
                        <AiOutlineInfoCircle className='textLG' style={{ cursor: "pointer", }} onClick={(e) => {
                            e.preventDefault();
                            getFunctionDoc();
                        }} />
                    </div>}
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: '1.5rem 2rem', marginTop: "1.25rem", alignItems: "center" }}>
                        {required_inputs?.filter((el) => priceValues?.includes(el))?.length === 1 ?
                            <div key={makeid(3)} style={{ width: "100%", height: "100%" }}>
                                <div style={{ display: "flex", flexDirection: "column", gap: '0.1rem', width: "100%", }}>
                                    <div className="textXS w-700 dark4" style={{}}>Prices</div>
                                    {required_inputs?.map((item, i) => {
                                        if (priceValues?.includes(item)) {
                                            let opts = [...priceItems?.map((el) => {
                                                return {
                                                    ...el,
                                                    tabType: "Metric P"
                                                }
                                            })];
                                            if (isAdvance && (['Indicators', 'Patterns']?.includes(query?.expression_details?.type) || query?.expression_details?.sub_type === 'Math Transform')) {
                                                opts = opts?.concat(Object.values(variables || {})?.map((el) => {
                                                    return { key: el?.name, label: el?.name, value: el?.name, tabType: "Variable" }
                                                }))
                                            }
                                            return (
                                                <div key={i} >
                                                    <CustomSelect options={opts} value={required_inputs_vals?.[i]} onChange={(e) => {
                                                        let temp = [...required_inputs_vals];
                                                        temp[i] = e;
                                                        setQueryVal(temp, 1)
                                                    }} style={{ width: "100%" }} tabs={[
                                                        { key: 0, label: "All", value: "", show: (isAdvance && ['Indicators', 'Patterns']?.includes(query?.expression_details?.type)) || query?.expression_details?.sub_type === 'Math Transform' },
                                                        {
                                                            key: 1, label: "Price Metrics", value: "Metric P", show: (isAdvance && ['Indicators', 'Patterns']?.includes(query?.expression_details?.type)) ||
                                                                query?.expression_details?.sub_type === 'Math Transform'
                                                        },
                                                        {
                                                            key: 3, label: "Custom Metrics", value: "Variable", show: isAdvance && (['Indicators', 'Patterns']?.includes(query?.expression_details?.type) ||
                                                                query?.expression_details?.sub_type === 'Math Transform')
                                                        }
                                                    ]} showInput={query?.expression_details?.sub_type === 'Math Transform'} inputPlaceholder='Enter a value' inputValType='number'
                                                        inputVal={isNaN(Number(required_inputs_vals?.[i])) ? "" : required_inputs_vals?.[i]} />
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div> : null}
                        {required_inputs?.filter((el) => el === 'col_name')?.length > 0 ? <div key={makeid(3)} style={{
                            width: "100%", height: "100%",
                            gridColumn: ['average', 'score', 'rank', 'minimum', 'maximum', 'median', 'percentile', 'prev_period']?.includes(query?.expression_details?.value) ? "span 2" : ""
                        }}>
                            <div style={{
                                display: "grid", gridTemplateColumns: itemLayoutType === 'horizontal' ? "0.3fr 1fr" : "1fr", gap: '0.5rem', width: "100%",
                                alignItems: "center"
                            }}>
                                <div className="textXS w-700 dark4" style={{}}>
                                    {query?.expression_details?.value === 'average' ? `Select metric to average` : ['rank', 'score']?.includes(query?.expression_details?.value) ?
                                        `Select metric to ${query?.expression_details?.value} stocks` : query?.expression_details?.value === 'prev_period' ?
                                            "Select metric for past period" : ['percentile', 'median', 'minimum', 'maximum']?.includes(query?.expression_details?.value) ?
                                                `Select metric to calculate ${query?.expression_details?.value}` : "Metric"}
                                </div>
                                <div style={{ width: "100%" }}>
                                    {required_inputs?.map((item, i) => {
                                        if (item === 'col_name') {
                                            let mets = [...(screenerMetrics || [])];
                                            // if (query?.expression_details?.category === 'Fundamental' && query?.expression_details?.type === 'Fundamental Function') {
                                            //     mets = mets?.filter((el) => el?.type !== 'Metric T');
                                            // }
                                            mets = mets?.filter((el) => el?.metric_data_type === 'float');
                                            // //console.log("mets", mets)
                                            return (
                                                <QueryOptionsDropdownV2 options={mets?.map((ele) => {
                                                    return { ...ele, tabType: ele?.type }
                                                })?.concat(Object.values(variables || {})?.map((el) => {
                                                    return { key: el?.name, label: el?.name, value: el?.name, tabType: "Variable" }
                                                }))} placeholder='Search Metrics' width='20rem' showAllTabTypes={false}
                                                    // tabTypesToShow={query?.expression_details?.category === 'Fundamental' && query?.expression_details?.type === 'Fundamental Function' ?
                                                    //     ['Metric F', 'Variable'] : ['Metric F', 'Variable', 'Metric T']}
                                                    tabTypesToShow={['Metric F', 'Variable', 'Metric T']}
                                                    onSelect={(ite) => {
                                                        let e = ite?.value
                                                        let temp = [...required_inputs_vals];
                                                        temp[i] = e;
                                                        let met = screenerMetrics?.find((ele) => ele?.metric_col_code === e) || Object.values(variables || {})?.find((el) => el?.name === e);
                                                        let opts = getByOptions(met);
                                                        let byind = optional_inputs?.findIndex((ele) => ele?.name === 'by');
                                                        if (byind >= 0) {
                                                            byind = optional_inputs?.length - byind - 1;
                                                            let temp2 = [...optional_inputs_vals];
                                                            let defItem = opts?.find((el) => el?.value === `"MKT"`);
                                                            temp2[byind] = "by=" + `${defItem ? defItem?.value : opts?.[0]?.value}`;
                                                            setQueryVal(temp, 3, temp2);
                                                            return
                                                        }
                                                        setQueryVal(temp, 1);
                                                    }} value={required_inputs_vals?.[i] === 'col_name' ? "" : required_inputs_vals?.[i]}
                                                    tabs={[
                                                        { key: 'all', label: "All", value: '' },
                                                        { key: 3, label: "Fundamental Metrics", value: "Metric F" },
                                                        { key: 3, label: "Technical Metrics", value: "Metric T" },
                                                        { key: 4, label: "Custom Metrics", value: "Variable" }
                                                    ]}
                                                />
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </div> : null}
                        {optional_inputs?.reverse()?.map((item, i) => {
                            let modInd = optional_inputs?.length - i - 1;
                            if (item?.name?.includes('matype')) {
                                return (
                                    <div key={i} style={{ width: "100%", height: "100%" }}>
                                        <div style={{ display: "flex", flexDirection: "column", gap: '0.1rem', width: "100%", }}>
                                            <div className="textXS w-700 dark4">{item?.display_name}</div>
                                            <div style={{ width: "100%" }}>
                                                <Select value={valueFromString(optional_inputs_vals?.[modInd], item)} options={maTypes} onChange={(value) => {
                                                    let temp = [...optional_inputs_vals];
                                                    temp[modInd] = item?.name + "=" + value;
                                                    setQueryVal(temp)
                                                }} style={{ width: "100%" }} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            else if (item?.name === 'by') {
                                var ak = valueFromString(optional_inputs_vals?.[modInd], item);

                                // let temp = [...required_inputs_vals];
                                let col_name_ind = required_inputs?.findIndex((ele) => ele === 'col_name');
                                var col_name_val = [...required_inputs_vals][col_name_ind]
                                // console.log("lalalalal", col_name_ind, col_name_val)
                                var showHistory = screenerMetrics?.find((i) => i?.key === col_name_val)?.is_technical !== 1
                                let opts = getByOptions();
                                if (!showHistory) {
                                    opts = opts?.filter((el) => el?.tabType !== 'History')
                                }
                                return (
                                    <div key={i} style={{ width: "100%", height: "100%" }}>
                                        <div style={{ display: "flex", flexDirection: "column", gap: '0.1rem', width: "100%", }}>
                                            <div className="textXS w-700 dark4">{item?.display_name}</div>
                                            <div style={{ width: "100%" }}>
                                                <CustomSelect value={ak?.includes("\"") ? ak : `"${ak}"`} options={opts}
                                                    onChange={(value) => {
                                                        let temp = [...optional_inputs_vals];
                                                        temp[modInd] = item?.name + "=" + value;
                                                        setQueryVal(temp)
                                                    }} style={{ width: "100%" }} tabs={[
                                                        {
                                                            key: 4, label: "All", value: "", show: query?.expression_details?.value !== "prev_period" &&
                                                                query?.expression_details?.value !== "score"
                                                        },
                                                        { key: 0, label: "Cross Section", value: "CrossSection", show: query?.expression_details?.value !== "prev_period" },
                                                        { key: 1, label: "History", value: "History", show: ['average', 'prev_period', 'minimum', 'maximum', 'median']?.includes(query?.expression_details?.value) && showHistory },
                                                        {
                                                            key: 2, label: "Indices", value: "Indices", show: !(['score', 'percentile', 'rank', 'prev_period']?.includes(query?.expression_details?.value))
                                                        }
                                                    ]} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            else if (item?.name === 'direction') {
                                return (
                                    <div key={i} style={{ width: "100%", height: "100%" }}>
                                        <div style={{ display: "flex", flexDirection: "column", gap: '0.1rem', width: "100%", }}>
                                            <div className="textXS w-700 dark4">Direction</div>
                                            <div style={{ width: "100%" }}>
                                                <Segmented options={directionTypes} value={valueFromString(optional_inputs_vals?.[modInd], item)} onChange={(value) => {
                                                    let temp = [...optional_inputs_vals];
                                                    temp[modInd] = item?.name + "=" + value;
                                                    setQueryVal(temp)
                                                    // setquery({
                                                    //     ...query,
                                                    //     expression: query?.expression_details?.key + " (" + required_inputs_vals?.concat(temp)?.join(", ") + ")",
                                                    //     expression_arr: required_inputs_vals?.concat(temp)
                                                    // })
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            else if (item?.name === 'symbol') {
                                return <div style={{ width: "100%", height: "100%" }}>
                                    <div style={{ display: "flex", flexDirection: "column", gap: '0.1rem', width: "100%", }}>
                                        <div className="textXS w-700 dark4">Symbol</div>
                                        <div style={{ width: "100%" }}>
                                            <CustomSelect value={valueFromString(optional_inputs_vals?.[modInd], item)} options={[{
                                                key: "all", label: "All", value: `"ALL"`
                                            }, ...((allFundsData || [])?.map((el) => {
                                                return {
                                                    ...el, key: el?.symbol, label: el?.proper_name, value: `"${el?.symbol}"`, tabType: "Stocks"
                                                }
                                            })), ...((indices || [])?.map((item) => {
                                                return {
                                                    ...item,
                                                    key: item?.IndexName, label: item?.IndexName, value: `"${item?.IndexNameCaps}"`, tabType: "Indices"
                                                }
                                            }))]} style={{ width: "100%" }} tabs={[
                                                { key: 0, label: "All", value: "" },
                                                { key: 1, label: "Stocks", value: "Stocks" },
                                                { key: 2, label: "Indices", value: "Indices" },
                                            ]} showSearch onChange={(e) => {
                                                let temp = [...optional_inputs_vals];
                                                temp[modInd] = item?.name + "=" + e;
                                                setQueryVal(temp)
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            }
                            else if (item?.name === "period" && !showPeriod) {
                                return <></>
                            }
                            return (
                                <div key={i} style={{ width: "100%", height: "100%" }}>
                                    <div style={{ display: "flex", flexDirection: "column", gap: '0.1rem', width: "100%", }}>
                                        <div className="textXS w-700 dark4">
                                            {item?.name === 'period' ? query?.expression_details?.value === 'average' ? "Number of periods (N)" :
                                                query?.expression_details?.value === 'prev_period' ? "Periods ago" : item?.display_name : item?.display_name}
                                        </div>
                                        <div style={{ width: "100%" }}>
                                            <Input
                                                value={valueFromString(optional_inputs_vals?.[modInd], item)}
                                                style={{
                                                    textAlign: "left"
                                                }}
                                                onChange={(e) => {
                                                    let temp = [...optional_inputs_vals];
                                                    temp[modInd] = item?.name + "=" + (item?.type === "<class 'int'>" ? parseInt(e?.target?.value) : item?.type === "<class 'float'>" ? parseFloat(e?.target?.value) : e?.target?.value);
                                                    setQueryVal(temp)
                                                }} type={item?.type === "<class 'int'>" ? 'number' : item?.type === "<class 'float'>" ? "number" : "string"} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {query?.expression_details?.type === 'Patterns' ? <div style={{ width: "100%", height: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "column", gap: '0.1rem', width: "100%", }}>
                                <div className="textXS w-700 dark4">Indiactes</div>
                                <div style={{ width: "100%" }}>
                                    <Select options={modPatternOutputs} value={query?.patternOutput} onChange={(e) => {
                                        setquery({
                                            ...query,
                                            patternOutput: e
                                        })
                                    }} style={{ width: "100%" }} />
                                </div>
                            </div>
                        </div> : null}
                        {query?.expression_details?.multi_tf === 1 && isAdvance ? <div style={{ width: "100%", height: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "column", gap: '0.1rem', width: "100%", }}>
                                <div className="textXS w-700 dark4">Timeframe</div>
                                <div style={{ width: "100%" }}>
                                    <Select placeholder="Select timeframe"
                                        options={timeframes?.filter((frame) =>
                                            frame?.key >= timeframes?.filter((i) => i?.value === timeframe)?.[0]?.key
                                        )}
                                        style={{ width: "100%" }}
                                        value={valueFromString(optional_inputs_vals?.find((el) => el?.includes('timeframe')), { type: "<class 'str'>" })?.replaceAll("\"", "") || timeframe}
                                        // onChange={(val) => {
                                        //     setTimeframe(val)
                                        // }}
                                        onChange={(e) => {
                                            let ind = optional_inputs_vals?.findIndex((el) => el?.includes('timeframe'));
                                            let val = e;
                                            console.log(e)
                                            if (val === timeframe && ind >= 0) {
                                                let t = [...optional_inputs_vals];
                                                t.pop();
                                                setTimeout(() => {
                                                    setQueryVal(t)
                                                }, 1);

                                            }
                                            else if (val !== timeframe) {
                                                let t = [...optional_inputs_vals];
                                                if (ind < 0) {
                                                    t.push('timeframe=' + `"${val}"`)
                                                }
                                                else {
                                                    t[ind] = 'timeframe=' + `"${val}"`
                                                }
                                                setTimeout(() => {
                                                    setQueryVal(t)
                                                }, 1);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div> : null}
                        {query?.expression_details?.multi_tf === 1 ? <div style={{ width: "100%", height: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "column", gap: '0.1rem', width: "100%", }}>

                                <div className="textXS w-700 dark4">Offset</div>
                                <div style={{ width: "100%" }}>
                                    <LabelInputNew value={valueFromString(optional_inputs_vals?.find((el) => el?.includes('offset')), { type: "<class 'int'>" }) || 0}
                                        onChangeVal={(e) => {
                                            let ind = optional_inputs_vals?.findIndex((el) => el?.includes('offset'));
                                            let val = Number(e);
                                            if (val === 0 && ind >= 0) {
                                                let t = [...optional_inputs_vals];
                                                t.pop();
                                                setQueryVal(t)
                                            }
                                            else if (val !== 0) {
                                                let t = [...optional_inputs_vals];
                                                if (ind < 0) {
                                                    t.push('offset=' + val)
                                                }
                                                else {
                                                    t[ind] = 'offset=' + val
                                                }
                                                setQueryVal(t)
                                            }
                                        }} min={0} isCurrency={false} prefix='' />
                                    {/* <Input
                                        value={valueFromString(optional_inputs_vals?.find((el) => el?.includes('offset')), { type: "<class 'int'>" }) || 0}
                                        style={{
                                            textAlign: "left"
                                        }}
                                        onChange={(e) => {
                                            let ind = optional_inputs_vals?.findIndex((el) => el?.includes('offset'));
                                            let val = Number(e.target.value);
                                            if (val === 0 && ind >= 0) {
                                                let t = [...optional_inputs_vals];
                                                t.pop();
                                                setQueryVal(t)
                                            }
                                            else if (val !== 0) {
                                                let t = [...optional_inputs_vals];
                                                if (ind < 0) {
                                                    t.push('offset=' + val)
                                                }
                                                else {
                                                    t[ind] = 'offset=' + val
                                                }
                                                setQueryVal(t)
                                            }
                                        }} type={"number"}
                                    /> */}
                                </div>
                            </div>
                        </div> : null}
                    </div>
                    <div className="d-flex justify-content-end" style={{ gap: "1.25rem", marginTop: "1.25rem" }}>
                        <button className="btnWhite" onClick={() => {
                            toggle();
                        }}>Cancel</button>
                        <button className="btnBlack" disabled={checkColNameDataPresent()} onClick={() => {
                            // alert(selectedOption?.query_key)
                            let modifiedOption = {
                                query_key_initial: selectedOption?.query_key,
                                isItemChanged: metricChanged,
                                keyToReplace: selectedOption?.query_key || selectedOption?.metric,
                                keyToReplaceWith: queryKey,
                                query: query
                            }
                            // ////console.
                            onDone(modifiedOption);
                        }}>Done</button>
                    </div>
                </div>
            </Modal>
        </React.Fragment >
    )
})