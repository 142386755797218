import moengage from "@moengage/web-sdk";
import { CanonicalURL } from 'Components/atoms/CanonicalURL';
import { initTwitter } from 'Components/twitterTracking.js';
import { Card } from 'antd';
import Highcharts from 'highcharts';
import React from "react";
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import Layout from './Layout/Index';

function App() {

  let isProd = process.env.REACT_APP_ENVIRONMENT === 'prod';

  // document.onkeydown = function (e) {
  //   if (e.keyCode == 123) {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
  //     return false;
  //   }
  // }
  Card.defaultProps = {
    bordered: false
  }

  var themeNM = useSelector((state) => state?.changeTheme?.value)
  document.documentElement.setAttribute("data-theme", themeNM)

  Highcharts.setOptions({
    global: {
      useUTC: false,
    },
    lang: {
      decimalPoint: '.',
      thousandsSep: ','
    }
  })

  React.useEffect(() => {
    initTwitter()
    moengage.initialize({ app_id: 'TEJQFNH8O8N2PGNR5D51EXE9', debug_logs: isProd ? 0 : 0 });
  }, []);

  return (
    <React.Fragment>
      <CanonicalURL />
      <Layout livedata={""} />
      <Toaster position='top-right' containerStyle={{
        justifyContent: "flex-start"
      }}>
        {(t) => (
          <ToastBar position='top-right' t toast={t} style={{ backgroundColor: "var(--dark4)", padding: 0 }}>
            {({ icon, message }) => (
              <div style={{ display: "flex", height: "auto" }}>
                <div style={{ display: "flex", gap: "0.5rem", alignItems: "center", width: "15rem", padding: "0.5rem", flex: 1 }}>
                  {icon}
                  <span className='textSM white' style={{ textAlign: "left !important", alignItems: "flex-start", flex: 1, alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "flex-start", display: "inline-block", whiteSpace: 'initial', width: "8rem" }}>{message}</span>

                </div>
                <div style={{ borderLeft: "0.5px solid var(--grey1)", display: "flex", alignItems: "center", padding: "0.5rem", cursor: "pointer" }}>
                  {t.type !== 'loading' && (
                    <span onClick={() => toast.dismiss(t.id)} className='textSM white'>Close</span>
                  )}
                </div>
              </div>
            )}
          </ToastBar>
        )}
      </Toaster>
    </React.Fragment >

  );
}

export default App;
