export const sharpelyBaseImg = "https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/sharpely-asset/"
const sharpelyLoginImg = "https://d3etfstcuyoos5.cloudfront.net/app-images/login_page_imgs/";
const sharpelyBaseImg2 = "https://d3etfstcuyoos5.cloudfront.net/app-images/"
// illustrations
export const upgrade = sharpelyBaseImg + 'images/illustrations/Upgrade.webp'
export const basketCardBg = sharpelyBaseImg + 'images/Home/basketCardBg.webp'
export const loginIllustration = sharpelyBaseImg + 'images/illustrations/loginIllustration.webp'
export const userguide = sharpelyBaseImg + 'icons/user-guide-2.webp';
export const notinvestimg = sharpelyBaseImg + 'icons/no-invest.webp'
export const marketingSignup1 = sharpelyBaseImg + 'images/Home/signup1.webp'
export const marketingSignup2 = sharpelyBaseImg + 'images/Home/signup2.webp'
export const buysell = sharpelyBaseImg + 'images/illustrations/buysell.webp'
export const getstarted = sharpelyBaseImg + 'images/illustrations/gettingstarted.webp'
export const strategyCustom = sharpelyBaseImg + 'images/illustrations/strategyCustom.webp';
export const strategyScreener = sharpelyBaseImg + 'images/illustrations/strategyScreener.webp';
export const strategySignal = sharpelyBaseImg + 'images/illustrations/strategySignal.webp';
export const noDataFoundIllustration = sharpelyBaseImg + 'images/illustrations/noDataFound.webp'
export const noSearchResult = sharpelyBaseImg + 'images/search-not-found.webp'
// About us images
export const abtValues1 = sharpelyBaseImg + 'images/About/abtValues1.webp'
export const abtValues2 = sharpelyBaseImg + 'images/About/abtValues2.webp'
export const abtValues3 = sharpelyBaseImg + 'images/About/abtValues3.webp'
export const abtValues4 = sharpelyBaseImg + 'images/About/abtValues4.webp'
export const abtValues5 = sharpelyBaseImg + 'images/About/abtValues5.webp'
export const officepic2 = sharpelyBaseImg + 'about-us/office-all47_1.webp'
export const officepic1 = sharpelyBaseImg + 'about-us/office-all33.webp'
export const officepic3 = sharpelyBaseImg + 'about-us/office-all90.webp'
export const officepic4 = sharpelyBaseImg + 'about-us/office-all41.webp'
export const officepic5 = sharpelyBaseImg + 'about-us/office-all29.webp'
export const microphone = sharpelyBaseImg + 'images/illustrations/microphone.webp'
export const mayank = sharpelyBaseImg + 'about-us/office15.webp'
export const shubham = sharpelyBaseImg + 'about-us/office6.webp'
export const suraj = sharpelyBaseImg + 'about-us/office8.webp'
export const prateek = sharpelyBaseImg + 'images/About/prateek.webp'
export const tanvi = sharpelyBaseImg + 'images/About/tanvi.webp'
export const arpit = sharpelyBaseImg + 'about-us/office18.webp'
export const omkar = sharpelyBaseImg + 'about-us/office-all88.webp'
export const jayesh = sharpelyBaseImg + 'about-us/office17.webp';
export const factset = sharpelyBaseImg + 'about-us/factsetlogo.webp';
export const phonePe = 'https://d3etfstcuyoos5.cloudfront.net/app-images/blogimages/phonepe.svg';
export const rzpay = sharpelyBaseImg + 'about-us/rzpay.webp';
export const tradv = sharpelyBaseImg + 'about-us/tradv.webp';
export const value = sharpelyBaseImg + 'about-us/valueresearchlogo.webp';
export const chetan = sharpelyBaseImg + 'about-us/office12.webp';
export const naveen = sharpelyBaseImg + 'about-us/office10.webp';
export const tinku = sharpelyBaseImg + 'about-us/office-all63.webp';
export const grouppic1 = sharpelyBaseImg + 'about-us/office-all46_1.webp';
export const grouppic2 = sharpelyBaseImg + 'about-us/office-all91.webp';
export { default as avinash } from './Images/avinash.JPG'
// sharpely logos
export const sharpelyLogoLight = sharpelyBaseImg + 'sharpely_logos/light_name_logo.webp'
export const sharpelyLogoDark = sharpelyBaseImg + 'sharpely_logos/dark_name_logo.webp'
export const sharpelyOnlyLogo = sharpelyBaseImg + 'sharpely_logos/logo.webp'
// Sharpely features icons
export const alphaAnalysis = sharpelyBaseImg + 'images/illustrations/sharpely-features-icons/alphaAnalysis2.webp';
export const basket = sharpelyBaseImg + 'images/illustrations/sharpely-features-icons/basket.webp';
export const compareImg = sharpelyBaseImg + 'images/illustrations/sharpely-features-icons/compare2.webp'
export const createScreener = sharpelyBaseImg + 'images/illustrations/sharpely-features-icons/createScreener2.webp'
export const createSignal = sharpelyBaseImg + 'images/illustrations/sharpely-features-icons/createSignals2.webp'
export const dashboard = sharpelyBaseImg + 'images/illustrations/sharpely-features-icons/dashboard.webp';
export const explore = sharpelyBaseImg + 'images/illustrations/sharpely-features-icons/explore.webp'
export const home = sharpelyBaseImg + 'images/illustrations/home.webp'
export const sectors = sharpelyBaseImg + 'images/illustrations/sharpely-features-icons/sectors2.webp'
export const stylebox = sharpelyBaseImg + 'images/illustrations/sharpely-features-icons/stylebox2.webp'
export const blog = sharpelyBaseImg + 'icons/blog.webp'
export const faq = sharpelyBaseImg + 'icons/faq.webp'
// new sharpelly features icons
export const etfFill = sharpelyBaseImg2 + 'final_imgs/etf_fill.png';
export const stockFill = sharpelyBaseImg2 + 'final_imgs/stock_fill.png';
export const mfFill = sharpelyBaseImg2 + 'final_imgs/mf_fill.png';
export const screenerFill = sharpelyBaseImg2 + 'final_imgs/screener_fill.png';
export const compareFill = sharpelyBaseImg2 + 'final_imgs/compare_fill.png';
export const alphaFill = sharpelyBaseImg2 + 'final_imgs/alpha_fill.png';
export const factorFill = sharpelyBaseImg2 + 'final_imgs/factor_fill.png';
export const sectorFill = sharpelyBaseImg2 + 'final_imgs/sector_fill.png';
export const basketFill = sharpelyBaseImg2 + 'final_imgs/basket_fill.png';
export const magicFill = sharpelyBaseImg2 + 'final_imgs/margic_fill.png';
export const stockLine = sharpelyBaseImg2 + 'final_imgs/stock_line.png';
export const compareLine = sharpelyBaseImg2 + 'final_imgs/compare_line.png';
export const sectorLine = sharpelyBaseImg2 + 'final_imgs/sector_line.png';
export const styleboxLine = sharpelyBaseImg2 + 'final_imgs/style_line.png';
export const screenLine = sharpelyBaseImg2 + 'final_imgs/screener_line.png';
export const factorLine = sharpelyBaseImg2 + 'final_imgs/factor_line.png';
export const basketLine = sharpelyBaseImg2 + 'final_imgs/basket_line.png';
export const earningLine = sharpelyBaseImg2 + 'final_imgs/earning_line.png';
export const alphaLine = sharpelyBaseImg2 + 'final_imgs/alpha_line.png';
export const etfLine = sharpelyBaseImg2 + 'final_imgs/etf_line.png';
export const mfLine = sharpelyBaseImg2 + 'final_imgs/mf_line.png';
export const magicLine = sharpelyBaseImg2 + 'final_imgs/margic_line.png';
// Footer images
export const valueresearch = sharpelyBaseImg + 'images/Footer/val.webp'
export const factsetlogo = sharpelyBaseImg + 'images/Footer/factset.webp'
// Knowledge Base 
export const kbBanner = sharpelyBaseImg2 + 'knowledge-base-2.png'
export const kb = sharpelyBaseImg + 'images/KB/kb3.webp'
export const stocks = sharpelyBaseImg + 'images/KB/stocks2.webp';
export const etf = sharpelyBaseImg + 'images/KB/etf2.webp';
export const mf = sharpelyBaseImg + 'images/KB/mf2.webp';
export const fiass = sharpelyBaseImg + 'images/KB/fiass.webp';
export const pr = sharpelyBaseImg + 'images/KB/pr.webp';
export const stockscores = sharpelyBaseImg + 'images/KB/stockscores.webp';
export const etfmasterclass = sharpelyBaseImg + 'images/KB/etfmasterclass.webp';
export const mfmasterclass = sharpelyBaseImg + 'images/KB/mfmasterclass.webp';
export const dam = sharpelyBaseImg + 'images/KB/dam.webp';
export { default as basketImg } from './Images/KB/Basket.png'
export { default as damImg } from './Images/KB/data_methodology.png'
export { default as etfMasterclassImg } from './Images/KB/ETF_masterclass.png'
export { default as mfMasterclassImg } from './Images/KB/MF_masterclass.png'
export { default as mfetfImg } from './Images/KB/MF_ETF.png'
export { default as productImg } from './Images/KB/Product.png'
export { default as stockScoresImg } from './Images/KB/Stock_scores.png'
export { default as stocksImg } from './Images/KB/Stocks.png'
export { default as sysInvImg } from './Images/KB/systematic_investment.png'
//Basket icons 
export const basket1 = sharpelyBaseImg + 'icons/CuratedBasketIcons/basket1.webp'
export const basket2 = sharpelyBaseImg + 'icons/CuratedBasketIcons/basket2.webp'
export const basket3 = sharpelyBaseImg + 'icons/CuratedBasketIcons/basket3.webp'
export const basket4 = sharpelyBaseImg + 'icons/CuratedBasketIcons/basket4.webp'
export const basket5 = sharpelyBaseImg + 'icons/CuratedBasketIcons/basket5.webp'
export const basket6 = sharpelyBaseImg + 'icons/CuratedBasketIcons/basket6.webp'
export const basket7 = sharpelyBaseImg + 'icons/CuratedBasketIcons/basket7.webp'
export const basket8 = sharpelyBaseImg + 'icons/CuratedBasketIcons/basket8.webp'
// Screener Icons
export const screener1 = sharpelyBaseImg + "icons/StockScreeners/screener1.webp"
export const screener2 = sharpelyBaseImg + "icons/StockScreeners/screener2.webp"
export const screener3 = sharpelyBaseImg + "icons/StockScreeners/screener3.webp"
export const screener4 = sharpelyBaseImg + "icons/StockScreeners/screener4.webp"
export const screener5 = sharpelyBaseImg + "icons/StockScreeners/screener5.webp"
export const screener6 = sharpelyBaseImg + "icons/StockScreeners/screener6.webp"
export const screener7 = sharpelyBaseImg + "icons/StockScreeners/screener7.webp"
export const screener8 = sharpelyBaseImg + "icons/StockScreeners/screener8.webp"
export const screener9 = sharpelyBaseImg + "icons/StockScreeners/screener9.webp"
export const screener10 = sharpelyBaseImg + "icons/StockScreeners/screener10.webp"
export const screener11 = sharpelyBaseImg + "icons/StockScreeners/screener11.webp"
export const screener12 = sharpelyBaseImg + "icons/StockScreeners/screener12.webp"
export const guru1 = sharpelyBaseImg + "images/GuruScreens/1.webp"
export const guru2 = sharpelyBaseImg + "images/GuruScreens/Piotroski.webp"
export const guru3 = sharpelyBaseImg + "images/GuruScreens/3.png"
export const guru4 = sharpelyBaseImg + "images/GuruScreens/warrenbuffet.webp"
export const guru5 = sharpelyBaseImg + "images/GuruScreens/5.webp"
export const guru6 = sharpelyBaseImg + "images/GuruScreens/6.webp"
export const guru7 = sharpelyBaseImg + "images/GuruScreens/peterlynch.webp"
export const guru8 = sharpelyBaseImg + "images/GuruScreens/daviddreman.webp"
export const guru9 = sharpelyBaseImg + "images/GuruScreens/johntempleton.webp"
//Random icons 
export const random1 = sharpelyBaseImg + 'icons/RandomIcons/random1.webp'
export const random2 = sharpelyBaseImg + 'icons/RandomIcons/random2.webp'
export const random3 = sharpelyBaseImg + 'icons/RandomIcons/random3.webp'
export const random4 = sharpelyBaseImg + 'icons/RandomIcons/random4.webp'
export const random5 = sharpelyBaseImg + 'icons/RandomIcons/random5.webp'
export const random6 = sharpelyBaseImg + 'icons/RandomIcons/random6.webp'
export const random7 = sharpelyBaseImg + 'icons/RandomIcons/random7.webp'
export const random8 = sharpelyBaseImg + 'icons/RandomIcons/random8.webp'
export const random9 = sharpelyBaseImg + 'icons/RandomIcons/random9.webp'
export const random10 = sharpelyBaseImg + 'icons/RandomIcons/random10.webp'
export const random11 = sharpelyBaseImg + 'icons/RandomIcons/random11.webp'
export const random12 = sharpelyBaseImg + 'icons/RandomIcons/random12.webp'
export const random13 = sharpelyBaseImg + 'icons/RandomIcons/random13.webp'
export const random14 = sharpelyBaseImg + 'icons/RandomIcons/random14.webp'
export const random15 = sharpelyBaseImg + 'icons/RandomIcons/random15.webp'
export const random16 = sharpelyBaseImg + 'icons/RandomIcons/random16.webp'
export const random17 = sharpelyBaseImg + 'icons/RandomIcons/random17.webp'
export const random18 = sharpelyBaseImg + 'icons/RandomIcons/random18.webp'
export const random19 = sharpelyBaseImg + 'icons/RandomIcons/random19.webp'
export const random20 = sharpelyBaseImg + 'icons/RandomIcons/random20.webp'
export const random21 = sharpelyBaseImg + 'icons/RandomIcons/random21.webp'
export const random22 = sharpelyBaseImg + 'icons/RandomIcons/random22.webp'
export const random23 = sharpelyBaseImg + 'icons/RandomIcons/random23.webp'
export const random24 = sharpelyBaseImg + 'icons/RandomIcons/random24.webp'
export const random25 = sharpelyBaseImg + 'icons/RandomIcons/random25.webp'
export const random26 = sharpelyBaseImg + 'icons/RandomIcons/random26.webp'
export const random27 = sharpelyBaseImg + 'icons/RandomIcons/random27.webp'
export const random28 = sharpelyBaseImg + 'icons/RandomIcons/random28.webp'
export const random29 = sharpelyBaseImg + 'icons/RandomIcons/random29.webp'
export const random30 = sharpelyBaseImg + 'icons/RandomIcons/random30.webp'
// Detail icons
export const cagr = sharpelyBaseImg + 'icons/DetailIcons/CAGR.webp'
export const alpha = sharpelyBaseImg + 'icons/DetailIcons/alpha.webp'
export const sharpe = sharpelyBaseImg + 'icons/DetailIcons/sharpe.webp'
export const maxDrawdown = sharpelyBaseImg + 'icons/DetailIcons/max drawdown.webp'
export const benchmark = sharpelyBaseImg + 'icons/DetailIcons/benchmark.webp'
export const expenseRatio = sharpelyBaseImg + 'icons/DetailIcons/Expense ratio.webp'
export const pe = sharpelyBaseImg + 'icons/DetailIcons/PE score.webp'
export const pb = sharpelyBaseImg + 'icons/DetailIcons/pb score.webp'
export const number = sharpelyBaseImg + 'icons/DetailIcons/Number of securities.webp'
export const trackingErr = sharpelyBaseImg + 'icons/DetailIcons/tracking error.webp'
export const aum = sharpelyBaseImg + 'icons/DetailIcons/aum.webp'
export const inception = sharpelyBaseImg + 'icons/DetailIcons/inception.webp'
export const avgDuration = sharpelyBaseImg + 'icons/DetailIcons/Modified duration.webp'
export const avgMaturity = sharpelyBaseImg + 'icons/DetailIcons/Average maturity.webp'
export const top5 = sharpelyBaseImg + 'icons/DetailIcons/top5.webp'
export const top10 = sharpelyBaseImg + 'icons/DetailIcons/top10.webp'
export const beta = sharpelyBaseImg + 'icons/DetailIcons/beta.webp'
export const annVolatility = sharpelyBaseImg + 'icons/DetailIcons/annualized volatility.webp'
export const lockIn = sharpelyBaseImg + 'icons/DetailIcons/lock in.webp'
export const taxation = sharpelyBaseImg + 'icons/DetailIcons/taxation.webp'
export const exitLoad = sharpelyBaseImg + 'icons/DetailIcons/exit load.webp'
export const minInvestment = sharpelyBaseImg + 'icons/DetailIcons/mininvestment.webp'
export const minSubSeqInv = sharpelyBaseImg + 'icons/DetailIcons/min subseq investment.webp'
export const minSip = sharpelyBaseImg + 'icons/DetailIcons/minsip.webp'
export const minWithdrawal = sharpelyBaseImg + 'icons/DetailIcons/min withdrawal.webp'
export const minBalance = sharpelyBaseImg + 'icons/DetailIcons/min balance.webp'
export const avgDailyTradedVal = sharpelyBaseImg + 'icons/DetailIcons/avg daily traded value.webp'
export const fundHouse = sharpelyBaseImg + 'icons/DetailIcons/fund house.webp'
export const lumpsum = sharpelyBaseImg + 'icons/DetailIcons/lumpsum.webp'
export const portfolioExpense = sharpelyBaseImg + 'icons/DetailIcons/portfolio expense.webp'
export const industry = sharpelyBaseImg + 'icons/DetailIcons/industry.webp'
export const sector = sharpelyBaseImg + 'icons/DetailIcons/sector.webp'
export const category = sharpelyBaseImg + 'icons/DetailIcons/category.svg'
export const return1d = sharpelyBaseImg + 'icons/DetailIcons/return1d.webp'
export const return1m = sharpelyBaseImg + 'icons/DetailIcons/return1m.webp'
export const return6m = sharpelyBaseImg + 'icons/DetailIcons/return6m.webp'
export const returnytd = sharpelyBaseImg + 'icons/DetailIcons/returnytd.webp'
export const roe = sharpelyBaseImg + 'icons/DetailIcons/roe.webp'
export const roce = sharpelyBaseImg + 'icons/DetailIcons/roce.webp'
export const trackingdiff1y = sharpelyBaseImg + 'icons/DetailIcons/trackingdiff1y.webp'
export const minadditionalinvestment = sharpelyBaseImg + 'icons/DetailIcons/minadditionalinvestment.webp'
export const marketcap = sharpelyBaseImg + 'icons/DetailIcons/marketcap.webp'
export const enterprisevalue = sharpelyBaseImg + 'icons/DetailIcons/enterprisevalue.webp'
export const annytm = sharpelyBaseImg + 'icons/DetailIcons/annytm.svg'
export const avgytm = sharpelyBaseImg + 'icons/DetailIcons/avgytm.svg'
export const onedayreturn = sharpelyBaseImg + 'icons/DetailIcons/1dayreturn.webp'
export const annualreturn = sharpelyBaseImg + 'icons/DetailIcons/annualreturn.webp'
export { default as roa } from './icons/roa.png';
export { default as npa } from './icons/npa.png'
// Other icons
export const checkmark = sharpelyBaseImg + 'icons/checkmark.webp';
export const lockIcon = sharpelyBaseImg + 'images/lock.webp';
export { default as tricolor } from './icons/Tricolor.svg'
//App download buttons;
export { default as iosapp } from './icons/iosappdownload.png';
export { default as andapp } from './icons/androidappdownload.png';
//Login images
export const loginImg1 = sharpelyLoginImg + 'login_img_1.webp';
export const loginImg2 = sharpelyLoginImg + 'login_img_2.webp';
export { default as loginImg3 } from './Images/login_img_3.png';

// background images
export { default as portfolioSnapCard } from './bgImages/portfolioSnapCardbg.webp'
export { default as sharpelyBg1 } from './bgImages/sharpelybg.svg';

// other images
export { default as noInvestment } from './Images/noinvest.svg';

// campaign images
export { default as appPic } from './Images/Campaign/appPic.webp';
export { default as appStoreDownloadPic } from './Images/Campaign/appStoreDownloadPic.webp';
export { default as playStoreDownloadPic } from './Images/Campaign/playStoreDownloadPic.webp';
// export { default as factorModelAppPic } from './Images/Campaign/factorModelAppPic.webp';
// export { default as screenStocksPic } from './Images/Campaign/screenStocksPic.webp';
// export { default as analyseStocksPic } from './Images/Campaign/analyseStocksPic.webp';
// export { default as campaignMainBanner } from './Images/Campaign/mainBanner.webp';
// export { default as sharpelyLogoCampaign } from './Images/Campaign/sharpelyLogoCampaign.webp';
// export { default as campaignMainBannerMobile } from './Images/Campaign/mainBannerMobile.webp';

// alert icons
export { default as alertBuySell } from './alertIcons/buySell.svg';
export { default as alertRebalance } from './alertIcons/rebalance.svg';
export { default as alertScreen } from './alertIcons/screen.svg';
export { default as alertFM } from './alertIcons/factorModel.svg';
export { default as alertCorporate } from './alertIcons/corporate.svg';
export { default as alertSurveillance } from './alertIcons/surveillance.svg';