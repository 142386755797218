import React from "react";
import { isAndroid, isIOS } from 'react-device-detect';
export default function AppQRPage() {


    const redirectLinks = () => {
        if (isAndroid) {
            window.location.href =
                "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.investments.sharpely";
        } else if (isIOS) {
            window.location.href =
                "https://apps.apple.com/in/app/sharpely/id6455889217";
        } else {
            window.location.href =
                "https://sharpely.in/";
        }
        setTimeout(() => {
            window.close();
        }, 2000)
    }

    React.useEffect(() => {
        redirectLinks()
    });


    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "90vh",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <button
                className="btnBlack"
                onPress={() => redirectLinks()}
                name="Download the app"
            />
        </div>
    );
}
