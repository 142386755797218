import React from 'react';
import { Card } from 'antd';
import { noDataFoundIllustration, sharpelyLogoLight, sharpelyOnlyLogo } from 'Assets/images';
import Logo from 'Layout/Logo';
import { toast } from 'react-hot-toast';

const GoToDesktopVersion = () => {

    const navigateToApp = () => {
        const userAgent = navigator.userAgent;
        let appLink = 'sharpely://dashboard'
        let fallbackLink = ''
        if (/Android/i.test(userAgent)) {
            console.log('User is using Android device');
            // Your Android-specific logic here
            fallbackLink = 'https://play.google.com/store/apps/details?id=com.investments.sharpely'
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            console.log('User is using iOS device');
            // Your iOS-specific logic here
            fallbackLink = 'https://apps.apple.com/us/app/sharpely/id6455889217'
        }
        try {
            // window.location.href = appLink;
            // // const openedWindow = window.open(appLink, '_blank');
            // setTimeout(() => {
            //     if (window.confirm('You do not seem to have Your App installed, do you want to go download it now?')) {
            //         window.location.href = fallbackLink;
            //     }
            // }, 1000)
            window.location = appLink;

            const hanndleVgsg = (e) => {
                if (document.visibilityState !== 'hidden') {
                    setTimeout(function () { window.location = fallbackLink; }, 1000);
                }
            }

            document.addEventListener('visibilitychange', hanndleVgsg)
        }
        catch (err) {
            toast.error('Go to test')
        }

    }


    return (
        <Card style={{ height: 'calc(100vh - 16rem)' }} bodyStyle={{ height: '100%' }}>
            <div className='d-flex align-items-center flex-column'
                style={{ gap: '10rem', width: "100%", height: "100%", justifyContent: 'space-between' }}>
                {/* <img loading="lazy"  alt="" src={noDataFoundIllustration} style={{ width: '10rem', maxWidth: "100%" }} /> */}
                <div className='textSM w-500 p-2 text-center mt-4'>
                    {/* Hmmm... Looks like this page is feeling a bit shy on the mobile browser. While our tech team works their magic, we've got a suggestion for you: why not download our app and explore the market seamlessly with all our tools and features? It's the perfect way to dive into the financial world with ease! */}
                    This feature is currently not available on mobile browser. Install our app to access this feature.
                </div>
                <div className='w100 mb-4' style={{
                    display: "flex", padding: "1rem", background: "var(--white)", boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', borderRadius: 8,
                    alignItems: "center", gap: "0.75rem"
                }} onClick={navigateToApp}>
                    <img loading="lazy"  src={sharpelyOnlyLogo} alt="logo" style={{ height: '3.25rem' }} className='' />
                    <div style={{ flex: 1, height: "100%" }}>
                        <div className='w-700 dark4' style={{ fontSize: "20px" }}>sharpely</div>
                        <div className='textXS w-500' style={{ color: "var(--green)" }}>Mintbox Solutions Pvt. Ltd.</div>
                    </div>
                    <button style={{ border: "none", borderRadius: '1.2rem', padding: "0.5rem 2rem", background: "var(--primary)" }} className='btnXS textSM w-500 white'>Install</button>
                </div>
            </div>
        </Card>
    )
}

export default GoToDesktopVersion;