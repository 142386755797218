import Head from 'Layout/Head';
import Logo from 'Layout/Logo';
import React from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import ActivityLoaderMintbox from '../ActivityLoaderMintbox';
import Datafeed from './datafeed';

const TradingViewChart = ({ }) => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    let fundName = searchParams.get("symbol");
    console.log(searchParams, location)
    console.log(fundName)
    const [loading, setLoading] = React.useState(true);

    const loadScript = (FILE_URL, async = true, type = "text/javascript") => {
        return new Promise((resolve, reject) => {
            try {
                const scriptEle = document.createElement("script");
                scriptEle.type = type;
                scriptEle.async = async;
                scriptEle.src = FILE_URL;

                scriptEle.addEventListener("load", (ev) => {
                    resolve({ status: true });
                });

                scriptEle.addEventListener("error", (ev) => {
                    reject({
                        status: false,
                        message: `Failed to load the script ${FILE_URL}`
                    });
                });

                document.head.appendChild(scriptEle);
            } catch (error) {
                reject(error);
            }
        });
    };

    React.useEffect(() => {
        loadScript("/charting_library/charting_library.standalone.js");
        loadScript("/datafeeds/udf/dist/bundle.js").then(() => {
            new window.TradingView.widget({
                container: 'tradingview_bd52e',
                locale: 'en',
                library_path: '/charting_library/',
                datafeed: Datafeed,
                symbol: fundName,
                interval: '1D',
                autosize: true,
                debug: true,
                timezone: 'Asia/Kolkata',
                enabled_features: ["tick_resolution"],
            });
            setLoading(false)
        });


    }, []);

    return (
        <React.Fragment>
            <Head title={'Technical chart'} />
            <div style={{ minHeight: "100vh" }} className='d-flex flex-column'>
                <div style={{ padding: "0.5rem 1.25rem", background: "var(--white)" }}>
                    <Logo type='dark' />
                </div>
                <div className='tradingview-widget-container d-flex flex-column' style={{ height: '100%', flexGrow: 1, position: "relative" }}>
                    <div id='tradingview_bd52e' style={{ height: '85vh', flexGrow: 1 }} />
                    {loading && <div style={{ position: "absolute", height: "100%", width: "100%", background: "var(--grey3)" }}>
                        <ActivityLoaderMintbox showQuote />
                    </div>}
                </div>
            </div>

        </React.Fragment>
    )
};

export default TradingViewChart;
