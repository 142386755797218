import { Divider, Tag } from "antd";
import { Checkbox } from "Components/atoms/Checkbox";
import { NoDataFound } from "Components/NoDataFound";
import React from "react";
import { generateUniqueKey } from 'Libs/utils';
import InfiniteScroll from "react-infinite-scroller";
import { LoadMore } from "Components/atoms/LoadMore";
import axios from "axios";
import AdminServicesPY from "Services/api/AdminServicesPY";
import AdminServices from "Services/api/AdminServices";

export const QueryOptionsDropdown = React.memo(({ options, onSelect, hideTabs = false, hideVarOpt = false, isMultiple = false, tabsType = 'metrics', onCreateVariable = () => { },
    showType = true, dropdownPositions = {}, hideCreateMetric = false, isAdvance = false, queryDDItemRef, allowDDPosChange = true, showDescBox = true, typesToHide = [] }) => {

    var tabs = tabsType === 'all' ? [
        { key: 'all', label: "All", value: '' },
        { key: 3, label: "Fundamental Metrics", value: "Metric F" },
        { key: 3, label: "Technical Metrics", value: "Metric T" },
        !hideVarOpt && isAdvance && { key: 4, label: "Custom Metrics", value: "Variable" },
        { key: 8, label: "Fundamental Function", value: "Fundamental Function" },
        { key: 9, label: "Technical Function", value: "Technical Function" }, { key: 1, label: "Patterns", value: "Patterns" },
        { key: 2, label: "Indicators", value: "Indicators" },
        { key: 5, label: "Math operators", value: "mathOperator" },
        { key: 6, label: "Comparators", value: "comparator" },
        { key: 7, label: "Logical Operator", value: "conditionOperator" }
    ] : tabsType === 'metrics' ? [
        { key: 'all', label: "All", value: '' },
        { key: 3, label: "Fundamental Metrics", value: "Metric F" },
        { key: 3, label: "Technical Metrics", value: "Metric T" },
        !hideVarOpt && isAdvance && { key: 4, label: "Custom Metrics", value: "Variable" },
        { key: 5, label: "Fundamental Function", value: "Fundamental Function" },
        { key: 7, label: "Technical Function", value: "Technical Function" },
        { key: 1, label: "Patterns", value: "Patterns" },
        { key: 2, label: "Indicators", value: "Indicators" },
    ] : tabsType === 'func' ? [
        { key: 'all', label: "All", value: '' },
        { key: 0, label: "Fundamental Function", value: "Fundamental Function" },
        { key: 3, label: "Technical Function", value: "Technical Function" },
        { key: 1, label: "Patterns", value: "Patterns" },
        { key: 2, label: "Indicators", value: "Indicators" }
    ] : [
        { key: 0, label: "All", value: "" },
        { key: 1, label: "Math operators", value: "mathOperator" },
        { key: 2, label: "Comparators", value: "comparator" },
        { key: 3, label: "Logical Operator", value: "conditionOperator" }]

    tabs = tabs?.filter((i) => !typesToHide?.includes(i?.value))
    const [selectedTab, setSelectedTab] = React.useState('');
    const [selectedItems, setSelectedItems] = React.useState(isMultiple ? [options?.[0]] : []);
    const [itemsToShow, setItemsToShow] = React.useState(20);
    const isMobile = window.innerWidth < 760
    //console.log("aSA", dropdownPositions)
    const getFinalOptions = React.useCallback(() => {
        if (hideTabs) return options;
        let temp = selectedTab === '' ? options : options?.filter((el) => el?.type === selectedTab);
        temp = temp?.slice(0, itemsToShow)
        return temp || [];
    }, [options, selectedTab, itemsToShow]);

    const finalOptions = getFinalOptions();

    const loadMoreData = () => {
        setItemsToShow(prev => prev + 20)
    }

    const getFunctionDoc = async (name) => {
        let doc = '';
        try {
            let t = await AdminServices.readAWSFile('functions-docs', name);
            if (t.type) {
                doc = t.data
            } else {
                doc = ''
            }
        } catch (error) {
            console.log(error);
            doc = ''
        }
        return doc;
    }

    async function appendMetricDesc(targetNode) {

        try {
            let metricDescContainer = document.getElementById('queryDDItemDescCont');
            let dropdownContainer = document.querySelector('.queryDropdownCont');
            if (showDescBox) {
                if (targetNode && dropdownContainer && metricDescContainer) {
                    let targetData = JSON.parse(targetNode.getAttribute('data-all'));
                    let isFunction = typeof (targetData) === 'object' ? (targetData?.hasOwnProperty('sh_name') ? true : false) : false
                    let labelNode = metricDescContainer.querySelector('div:first-child');
                    let descNodeCont = metricDescContainer.querySelector('div:last-child');
                    let height = dropdownContainer.offsetHeight;
                    let dropdownRect = dropdownContainer.getBoundingClientRect();
                    let dropdownRight = window.innerWidth - dropdownRect.right;
                    let descContent;
                    if (isFunction) {
                        descContent = await getFunctionDoc(targetData?.sh_name);
                    }
                    if ((isFunction && descContent) || (targetData?.metric_name && labelNode && descNodeCont && targetData?.metric_desc && targetData?.metric_desc?.props?.dangerouslySetInnerHTML?.['__html'] !== 'undefined')) {
                        labelNode.innerHTML = isFunction ? targetData?.display_name : targetData?.metric_name;
                        let subCatNode = descNodeCont.querySelector('div:first-child');
                        let descNode = descNodeCont.querySelector('div:last-child');
                        if (!isFunction) {
                            descContent = targetData?.metric_desc?.props?.dangerouslySetInnerHTML?.['__html'];
                        }
                        descNode.innerHTML = descContent;
                        subCatNode.innerHTML = (targetData?.category_name !== undefined ||
                            targetData?.sub_category_name !== undefined) ? `${String(targetData?.category_name)}${targetData?.sub_category_name !== undefined ? ', ' + String(targetData?.sub_category_name) : ''}` : "";
                        metricDescContainer.style.height = `${height}px`;
                        metricDescContainer.style.display = 'flex';
                        if (dropdownRight < metricDescContainer.clientWidth) {
                            metricDescContainer.style.left = `-23rem`;
                        }
                        else {
                            metricDescContainer.style.left = `25rem`;
                        }
                    }
                    else {
                        metricDescContainer.style.display = 'none';
                    }
                }
                else if (metricDescContainer) {
                    metricDescContainer.style.display = 'none';
                }
            } else {
                metricDescContainer.style.display = 'none';
            }
        }
        catch (err) {
            //console.log(err);
            let metricDescContainer = document.getElementById('queryDDItemDescCont');
            if (metricDescContainer) {
                metricDescContainer.style.display = 'none';
            }
        }

    }

    React.useEffect(() => {
        setSelectedTab('');
        setItemsToShow(20);
    }, [tabsType, hideTabs])

    React.useEffect(() => {
        const listItems = Array.from(document.querySelectorAll('.queriesDropdown .queryDropdownItem'));
        let menuIndex = listItems?.findIndex((ele) => ele.getAttribute('data-focused') !== null);
        appendMetricDesc(listItems[menuIndex]);
        const observerCallback = (mutationsList) => {
            let menuIndex = mutationsList?.findIndex((ele) => ele.target.getAttribute('data-focused') !== null);
            if (mutationsList[menuIndex]) {
                appendMetricDesc(mutationsList[menuIndex].target);
            }
        };
        const observerOptions = { attributes: true };
        const observers = [];
        listItems.forEach((item) => {
            const observer = new MutationObserver(observerCallback);
            observer.observe(item, observerOptions);
            observers.push(observer);
        });
        return () => {
            observers.forEach((observer) => observer.disconnect());
        };
    }, [selectedTab, itemsToShow, finalOptions]);

    React.useEffect(() => {
        let dropdownContainer = document.querySelector('.queryDropdownCont');
        if (dropdownContainer && allowDDPosChange) {
            let dropdownRect = dropdownContainer.getBoundingClientRect();
            let prevSibling = dropdownContainer.previousElementSibling;
            if (window.innerWidth - dropdownRect.right < 0) {
                let visibleWidth = window.innerWidth - dropdownRect.left;
                dropdownContainer.style.left = `-${dropdownContainer.clientWidth - visibleWidth - prevSibling.clientWidth}px`;
            }
            else {
                dropdownContainer.style.left = '100%'
            }
        }
    }, [options])

    return (
        <React.Fragment>
            <div style={{
                border: "1px solid var(--grey1)", borderRadius: "var(--borderRadius)",
                position: "absolute", background: "var(--white)", zIndex: 10, background: "var(--white)", ...dropdownPositions
            }} className='queryDropdownCont'>
                <div style={{ padding: "0.5rem 0", width: "25rem", position: "relative" }}>
                    {!hideTabs && <div style={{
                        display: "flex", gap: "0.5rem 0.1rem", alignItems: "center", justifyContent: "flex-start", flexWrap: "wrap", padding: "0.5rem 0.75rem",
                        paddingBottom: "1rem", background: "var(--white)"
                    }} className='queryDropdownTabCont'>
                        {tabs?.filter((el) => el)?.map((item, i) => {
                            return (
                                <div className='queryDropdownTabItem' key={generateUniqueKey(i)}>
                                    <Tag onClick={() => {
                                        setSelectedTab(item?.value)
                                    }} color={selectedTab === item?.value ? "var(--primary)" : "var(--grey3)"} className="textXS w-500" style={{
                                        fontSize: 11,
                                        color: selectedTab === item?.value ? "var(--white)" : "var(--dark3)", padding: "0.25rem 0.5rem", borderRadius: 6,
                                        border: `1px solid ${selectedTab === item?.value ? "var(--primary)" : "var(--grey1)"}`, cursor: "pointer", userSelect: "none"
                                    }} >{item?.label}</Tag>
                                </div>
                            )
                        })}
                    </div>}
                    <div style={{ maxHeight: "20rem", overflow: "auto", }} className='scrollbox queriesDropdown'>
                        {finalOptions?.length > 0 ? <InfiniteScroll hasMore={options?.length > itemsToShow} loadMore={loadMoreData}
                            loader={<LoadMore key={0} />} useWindow={false} threshold={80}>{finalOptions?.map((item, i) => {
                                let isSelected = selectedItems?.find((el) => el?.value === item?.value);
                                return (
                                    <div key={generateUniqueKey(item?.label + item?.key + item?.value)} className='textXS w-500 dark4 queryDropdownItem' style={{
                                        gap: "0.7rem",
                                        padding: "0.5rem 0.75rem", cursor: "pointer",
                                        borderBottom: "0.5px solid var(--grey1)"
                                    }} onClick={() => {
                                        if (isMultiple) {
                                            let temp = [...selectedItems];
                                            if (isSelected) {
                                                temp = temp?.filter((el) => el?.value !== item?.value)
                                            }
                                            else {
                                                temp.push(item);
                                            }
                                            setSelectedItems(temp);
                                        }
                                        else {
                                            onSelect(item);
                                        }
                                    }} data-focused={i === 0 ? 'active' : null} data-all={JSON.stringify(item)}>
                                        <div className="d-flex align-items-center mb-1">
                                            {isMultiple ? <Checkbox onChange={() => { }}
                                                checked={isSelected}
                                            /> : null}
                                            <div className="textXS" style={{ flex: 1 }}>
                                                {item?.label}
                                            </div>
                                        </div>
                                        {item?.type !== "comparator" && item?.type !== "conditionOperator" && item?.type !== "mathOperator" &&
                                            <div className="d-flex align-items-center">
                                                <Tag color="var(--dark3)" className="textXS" style={{ borderRadius: 8, fontSize: "10px" }}>
                                                    {item?.type !== "Variable" ? item?.type : "Metric C"}
                                                </Tag>
                                                {(item?.type === 'Metric F' || item?.type === 'Metric T') && (item?.metric_unit === 'rupee_cr' ||
                                                    item?.metric_unit === 'percentage' || item?.metric_data_type === 'string' || item?.metric_data_type === 'float') &&
                                                    <Tag color="var(--dark3)" className="textXS" style={{ borderRadius: 8, fontSize: "10px" }}>
                                                        {item?.metric_unit === 'rupee_cr' ? "₹ Cr" : item?.metric_unit === 'percentage' ? "%" :
                                                            item?.metric_data_type === 'string' ? "Text" : item?.metric_data_type === 'float' ? "Number" : ""}
                                                    </Tag>}
                                                <span className="textXS" style={{ color: "var(--dark3)", fontSize: "10px", flex: 1, textAlign: "right" }}>
                                                    {(item?.type === "Metric F" || item?.type === 'Metric T') && (item?.category_name !== undefined ||
                                                        item?.sub_category_name !== undefined) ? `${String(item?.category_name)}${item?.sub_category_name !== undefined ? ', ' + String(item?.sub_category_name) : ''}` :
                                                        String(item?.sub_type || item?.value)?.toUpperCase()}</span>
                                            </div>}
                                    </div>
                                )
                            })} </InfiniteScroll> : <div className="my-2">
                            <NoDataFound ctaAction={selectedTab === 'Variable' && !hideCreateMetric ? onCreateVariable : () => { }} ctaText={selectedTab === 'Variable' && !hideCreateMetric ? "Create custom metric" : ""} message={selectedTab === 'Variable' && !hideCreateMetric ? "You have not created any custom metric." : "No results"} width="5rem" />
                        </div>}
                    </div>
                    {isMultiple && <div className='d-flex justify-content-end' style={{ padding: "0.5rem 0.75rem" }}>
                        <button className='btnBlack' onClick={() => onSelect(selectedItems)}>Done</button>
                    </div>}
                    {!isMobile && <div style={{
                        display: "none", width: "23rem", position: "absolute", top: -1, left: "25rem", background: "var(--white)", padding: "0.75rem 1rem",
                        border: "1px solid var(--grey1)", borderRadius: "var(--borderRadius)", overflow: "auto", flexDirection: "column", minHeight: "20rem"
                    }}
                        ref={queryDDItemRef} id='queryDDItemDescCont'>
                        <div className="textMD w-700 mb-2"></div>
                        <div className='scrollbox customScrollbar' style={{ flex: 1, overflow: "auto" }}>
                            <div className="textSM w-700 dark3 mb-2"></div>
                            <div className='p-0 metricDescDrawer textESM dark4 w-500'></div>
                        </div>
                    </div>}
                </div>
            </div>
        </React.Fragment>
    )
})