import { basket5, basketImg, dam, damImg, etf, etfmasterclass, etfMasterclassImg, fiass, mf, mfetfImg, mfmasterclass, mfMasterclassImg, pr, productImg, stocks, stockscores, stockScoresImg, stocksImg, sysInvImg } from 'Assets/images';
import React from 'react';

export const CategoryIcon = ({ title = '' }) => {

    const iconslist = [
        { title: "ETFs", image: etf },
        { title: "MFs and ETFs", image: mf },
        { title: "Stocks", image: stocks },
        { title: "Data & Methodology", image: dam },
        { title: "Product Roadmap", image: pr },
        { title: "Stock Scores", image: stockscores },
        { title: "MF Masterclass", image: mfmasterclass },
        { title: "ETF Masterclass", image: etfmasterclass },
        { title: "Systematic Investing", image: fiass },
        { title: "Baskets", image: basket5 },
    ]
    return (
        // <div style={{
        //     borderRadius: "var(--borderRadius)", width: "100%", height: "100%", padding: "0.5rem"
        // }} className='d-flex align-items-center justify-content-end'>
        <img loading="lazy"  alt={title + " thumbnail"} src={iconslist?.filter((ele) => ele.title?.toLowerCase() === title.toLowerCase())?.[0]?.image || iconslist[0]?.image}
            style={{ width: "100%" }} />
        // </div>
    )
}

export const CategoryIcon2 = ({ title, style, className = '' }) => {
    const iconslist = [
        { title: "ETFs", image: etf },
        { title: "MFs and ETFs", image: mfetfImg },
        { title: "Stocks", image: stocksImg },
        { title: "Data & Methodology", image: damImg },
        { title: "Product Roadmap", image: productImg },
        { title: "Stock Scores", image: stockScoresImg },
        { title: "MF Masterclass", image: mfMasterclassImg },
        { title: "ETF Masterclass", image: etfMasterclassImg },
        { title: "Systematic Investing", image: sysInvImg },
        { title: "Baskets", image: basketImg },
    ]
    return (
        <img loading="lazy"  alt="" src={iconslist?.filter((ele) => ele.title?.toLowerCase() === title?.toLowerCase())?.[0]?.image || iconslist[0]?.image}
            style={{ maxWidth: "100%", ...style }} className={className} />
    )
}