import { Categories } from 'Components/BlogComponents/Categories';
import { FeaturedBlogs } from 'Components/BlogComponents/FeaturedBlogs';
import { RecentBlogs } from 'Components/BlogComponents/RecentBlogs';
import { getBlogsData } from "Components/getKnowledgeBaseData";
import Head from 'Layout/Head';
import { Card, Skeleton } from 'antd';
import React from 'react';
import { useParams } from "react-router-dom";

export default function BlogCategory() {

    const params = useParams();
    const [category, setCategory] = React.useState({});
    const [categories, setCategories] = React.useState({});
    const [featuredBlogs, setFeaturedBlogs] = React.useState([])
    const [loading, setLoading] = React.useState(true);
    const [loadingCategory, setLoadingCategory] = React.useState(true);

    const isMobile = window.innerWidth <= 575;
    const loadData = async () => {
        let res = await getBlogsData();
        console.log(res);
        if (res.type) {
            setCategories(res.formattedData || [])
            setFeaturedBlogs(res?.featuredBlogs || []);
        }
        setLoadingCategory(false);
    }

    const loadCategory = () => {
        setCategory(categories?.find((el) => el.Category?.split(' ')?.join('-')?.toLowerCase() === params.category))
        setLoading(false);
    }

    React.useEffect(() => {
        setLoading(true);
        loadData();
    }, [])

    React.useEffect(() => {
        if (categories?.length) {
            setLoading(true);
            loadCategory()
        }
    }, [params, categories]);
    
    const SideLoader = () => {
        return (
            <Card bordered={false} className='mobCardBorderRadius0 rightContainer'>
                <div className="d-flex w100">
                    <Skeleton.Button style={{ width: "100%", height: "2rem" }} active className="flex-grow-1" />
                </div>
                {new Array(5)?.fill(1)?.map((ele, i) => (
                    <div className="d-flex align-items-center mt-3 w100" style={{ gap: "0.5rem" }} key={i}>
                        <Skeleton.Avatar active={i % 2 === 1} style={{ width: "1rem", height: "1rem" }} />
                        <Skeleton.Button active={i % 2 === 1} style={{ height: "1rem", width: "100%" }} className='flex-grow-1' />
                    </div>))}
            </Card>
        )
    }

    const categoryTitle = params.category?.replaceAll("-", ' ');

    return (
        <React.Fragment>
            <Head description={category?.Description || ""} title={(categoryTitle?.[0]?.toUpperCase() + categoryTitle?.slice(1)) || "Blog category"} />
            <div className='normalContainer'>
                <Card style={{ background: "linear-gradient(45deg, #ccbf76, #fff2a9)" }} bodyStyle={{ padding: isMobile ? "1.25rem" : "3rem" }} className='mobCardBorderRadius0'>
                    <h1 className='headLGtoXS'>{category.Category}</h1>
                    <div className='textMD dark4'>
                        {category.Description}
                    </div>
                </Card>
                <div className='mainContainer'>
                    <div className='leftContainer'>
                        <RecentBlogs recentBlogs={category?.Blogs?.reverse() || []} loading={loading} />
                    </div>
                    {loadingCategory ? <div className='rightContainer'>
                        <SideLoader />
                    </div> :
                        <div className='rightContainer'>
                            <Categories categories={categories} />
                            <FeaturedBlogs featuredBlogs={featuredBlogs} />
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}