// for searching and sorting instruments 
function searchAndSort(string, data, keys) {
    if (string === '') return data;
    return searchObjects(string, data, keys)
}

export function sortDrop(string = "", data = {}, keys = []) {
    string = string?.trim()
    function filterAndSortArray(data, keysToMatch, searchString) {
        const filteredResults = data.filter(obj => {
            const matchingValues = keysToMatch.filter(key => {
                const value = obj[key];
                if (typeof value === 'string') {
                    const lowercaseValue = value.toLowerCase();
                    const lowercaseSearchString = searchString.toLowerCase();

                    // Exact match takes priority
                    if (lowercaseValue === lowercaseSearchString) {
                        return true;
                    }

                    // console.log(lowercaseValue, lowercaseSearchString)
                    if (lowercaseValue?.replace(/\s/g, '') === lowercaseSearchString?.replace(/\s/g, '')) {

                        return true;
                    }

                    // Check if the value starts with the search string
                    if (lowercaseValue.startsWith(lowercaseSearchString)) {
                        return true;
                    }

                    // Check if each character of the search string matches the first letter of each word
                    const words = lowercaseValue.split(' ');
                    const isCharacterMatch = searchString.split('').every((char, index) =>
                        words.some(word => word[index] === char)
                    );

                    const words2 = [lowercaseValue?.replace(/\s/g, '')];
                    const isCharacterMatch2 = searchString.split('').every((char, index) =>
                        words2.some(word => word[index] === char)
                    );

                    return isCharacterMatch || isCharacterMatch2;
                }

                return false;
            });

            return matchingValues.length > 0; // Include objects with at least one matching value
        });

        // Sort based on priority: exact match and values starting with the search string
        const sortedResults = filteredResults.sort((a, b) => {
            const isExactMatchA = keysToMatch.some(key => a[key].toLowerCase() === searchString.toLowerCase());
            const isExactMatchB = keysToMatch.some(key => b[key].toLowerCase() === searchString.toLowerCase());

            if (isExactMatchA && !isExactMatchB) {
                return -1; // 'a' is an exact match, so it comes first
            } else if (!isExactMatchA && isExactMatchB) {
                return 1; // 'b' is an exact match, so it comes first
            }

            // If neither is an exact match, prioritize values starting with the search string
            const startsWithA = a[keysToMatch[0]].toLowerCase().startsWith(searchString.toLowerCase());
            const startsWithB = b[keysToMatch[0]].toLowerCase().startsWith(searchString.toLowerCase());

            if (startsWithA && !startsWithB) {
                return -1; // 'a' starts with the search string, so it comes first
            } else if (!startsWithA && startsWithB) {
                return 1; // 'b' starts with the search string, so it comes first
            }

            // If none of the above conditions are met, maintain the original order
            return 0;
        });

        // Include remaining items from data array at the end
        const remainingItems = data.filter(obj => !sortedResults.includes(obj));
        // const finalResults = [...sortedResults, ...remainingItems];
        const finalResults = [...sortedResults];


        return finalResults;
    }


    return filterAndSortArray(data, keys, string)

}

function searchObjects(searchInput, arr, keys) {
    const filteredObjs = [];

    function isSubsequence(subseq, str) {
        let i = 0;
        for (const char of str) {
            if (char?.toLowerCase() === subseq[i]?.toLowerCase()) {
                i++;
                if (i === subseq.length) {
                    return true;
                }
            }
        }
        return false;
    }

    function getSubsequenceIndex(subseq, str) {
        let i = 0;
        let index = 0;
        for (const char of str) {
            if (char?.toLowerCase() === subseq[i]?.toLowerCase()) {
                i++;
                if (i === subseq.length) {
                    break;
                }
            }
            index++;
        }
        return index;
    }

    for (const obj of arr) {
        for (const key of keys) {
            if (obj.hasOwnProperty(key) && obj?.[key] && isSubsequence(searchInput, obj[key])) {
                filteredObjs.push(obj);
                break;
            }
        }
    }

    filteredObjs.sort((a, b) => {
        let aIndex, bIndex;

        for (const key of keys) {
            if (a.hasOwnProperty(key) && a?.[key] && isSubsequence(searchInput, a[key])) {
                aIndex = getSubsequenceIndex(searchInput, a[key]);
                break;
            }
        }

        for (const key of keys) {
            if (b.hasOwnProperty(key) && b?.[key] && isSubsequence(searchInput, b[key])) {
                bIndex = getSubsequenceIndex(searchInput, b[key]);
                break;
            }
        }

        return aIndex - bIndex;
    });

    return filteredObjs;
}

export function filterSort(a, b, input, searchKey) {
    const indexA = a?.[searchKey].indexOf(input);
    const indexB = b?.[searchKey].indexOf(input);
    return indexA - indexB;
}

export function isSubsequence(subseq, str) {
    let i = 0;
    for (const char of str) {
        if (char?.toLowerCase() === subseq[i]?.toLowerCase()) {
            i++;
            if (i === subseq.length) {
                return true;
            }
        }
    }
    return false;
}

/* normal search function for searching  and sorting  in a array of objects
 receives  an array  of objects, an innput text and a searchKey which corresponds to the key in each object onn the basis of  which  the array needs to  filtered out and  sorted.
*/
export function textSearchAndSort(data, input, searchKey, sortingAllowed = true) {
    if (!input) return data;

    let filteredData = data?.filter((ele) => isSubsequence(input, ele?.[searchKey]));

    if (sortingAllowed) {
        filteredData.sort((a, b) => filterSort(a, b, input, searchKey));
    }

    return filteredData;
}
export default searchAndSort;