import React from 'react';
import apiHelperPY from 'Services/api/ApiHelperPY';
import ActivityLoaderMintbox from './ActivityLoaderMintbox';

const Disclaimer = ({ val }) => {

    const [disclaimer, setDisclaimer] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const loadData = async () => {
        let disclaimerData = await apiHelperPY({
            apiName: "getMethodologyAndDisclaimer", data: val, getFresh: false,
            saveResponse: "sessionStorage"
        });
        if (disclaimerData) {
            setDisclaimer(disclaimerData?.split('<br />'));
        }
        setLoading(false)
    }
    React.useEffect(() => {
        setLoading(true)
        loadData();
    }, []);
    if (loading) {
        return <div className='mainContainer' style={{
            marginTop: 30
        }}>
            <ActivityLoaderMintbox />
        </div>
    }

    return (
        <React.Fragment>
            <div id='disclaimerFundBasket123' className='textSM black' style={{ padding: "1rem 0rem" }}>
                <div className='d-flex flex-column scrollbox customScrollbar' style={{ gap: "1rem", width: "100%", maxHeight: "25rem", overflow: "auto" }}>
                    {disclaimer.map((ele, i) => (
                        <div className='d-flex' style={{ gap: "0.75rem" }} key={i + 1}>
                            <div style={{ background: "var(--blueShade)", height: "1.5rem", width: "1.5rem", borderRadius: "50%", flexShrink: 0 }}
                                className='black w-500 d-flex align-items-center justify-content-center'>
                                {i + 1}
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: ele }}>

                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    )
}

export default Disclaimer;