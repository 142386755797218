// custom tabs scroll functions 
export function scrollToDivMinusBtm(targetDivId, offsetDivId) {
    const targetElement = document.getElementById(targetDivId);
    const otherElement = document.getElementById(offsetDivId);
    if (targetElement && otherElement) {
        const scrollOptions = {
            behavior: 'instant',
            block: 'start',
            inline: 'nearest'
        };
        let rects = otherElement.getBoundingClientRect();
        if (window.pageYOffset === 0) return
        const targetScrollPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - rects.bottom;
        window.scrollTo({
            top: targetScrollPosition,
            ...scrollOptions
        });
    }
}

export function isChildVisible(container, child) {
    const containerRect = container.getBoundingClientRect();
    const childRect = child.getBoundingClientRect();
    return (
        childRect.top >= containerRect.top &&
        childRect.bottom <= containerRect.bottom
    );
}

export function getDivHeight(query) {
    const myDiv = document.querySelector(query);
    if (myDiv) {
        const computedStyle = window.getComputedStyle(myDiv);
        const borderTopWidth = parseFloat(computedStyle.getPropertyValue("border-top-width"));
        const borderBottomWidth = parseFloat(computedStyle.getPropertyValue("border-bottom-width"));
        const totalHeight = myDiv.clientHeight + borderTopWidth + borderBottomWidth;
        // console.log(totalHeight)
        return totalHeight;
    }
    return 0
}