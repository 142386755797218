import { scrollToDivMinusBtm } from "Libs/scroll";
import React from "react";
import { Link } from 'react-router-dom';
import { CardHeader } from "./atoms/SmallComponents";

function getDivHeight(query) {
    const myDiv = document.querySelector(query);
    if (myDiv) {
        const computedStyle = window.getComputedStyle(myDiv);
        const borderTopWidth = parseFloat(computedStyle.getPropertyValue("border-top-width"));
        const borderBottomWidth = parseFloat(computedStyle.getPropertyValue("border-bottom-width"));
        const totalHeight = myDiv.clientHeight + borderTopWidth + borderBottomWidth;
        return totalHeight;
    }
    return 0
}

function CustomTabs({ tabItems, onChange, value, header, childContainerId = 'customTabsChildCont' }) {

    const isMobile = window.innerWidth <= 760;
    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';

    const handlHeadOnScroll = () => {
        let head = document.getElementById('tabsDetailHead');
        let topHaedingCard = document.getElementById('customTabsBarId');
        if (head && topHaedingCard) {
            let r = topHaedingCard.getBoundingClientRect();
            let h = getDivHeight('#sharpelyHeader');
            if (r.top - h <= 0) {
                head.style.display = 'flex';
            }
            else {
                head.style.display = 'none';
            }
        }
    }

    React.useEffect(() => {
        window.addEventListener('scroll', handlHeadOnScroll);
        return () => {
            window.removeEventListener('scroll', handlHeadOnScroll);
        }
    }, [])

    return (
        <React.Fragment>
            <div style={{ position: "sticky", top: getDivHeight('#sharpelyHeader'), zIndex: 10, background: "var(--white)" }} id={'customTabsBarId'} >
                <div style={{
                    display: "flex", alignItems: "center", justifyContent: "start", textAlign: "center", paddingTop: "0.1rem",
                    borderRadius: "0 0 0 0", position: "relative", width: "100%", overflowX: "auto"
                }}>
                    <div style={{ position: "absolute", borderBottom: "1px solid var(--grey2)", width: "100%", bottom: "0px", zIndex: 0 }}></div>
                    <div style={{
                        display: "flex", alignItems: "center", justifyContent: "start", textAlign: "center", background: "var(--white)",
                        overflowX: "auto", maxWidth: isDev ? "1600px" : "", margin: isDev ? "auto" : "", width: isDev ? `min(1600px, 100vw)` : '100vw'
                    }}>
                        {tabItems?.map((tab, i) => {
                            return (
                                <div className="textSM w-500 leftRightPadContainer" style={{
                                    paddingTop: isMobile ? "1rem" : "0.75rem", paddingBottom: isMobile ? "1rem" : "0.75rem", width: "fit-content",
                                    color: value === tab?.value ? "var(--primary)" : "var(--dark4)",
                                    borderBottom: value === tab?.value ? "1px solid var(--primary)" : "1px solid var(--grey2)", cursor: "pointer", zIndex: 1,
                                    whiteSpace: "nowrap"
                                }} onClick={() => {
                                    onChange(tab?.value)
                                    scrollToDivMinusBtm(childContainerId, 'customTabsBarId')
                                }} key={tab?.value}>
                                    {tab?.label}
                                </div>
                            )
                        })}
                        {!isMobile && <div id='tabsDetailHead' style={{ flex: 1, display: "none", paddingRight: "1.25rem" }}>
                            {header}
                        </div>}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export const CustomTabsMainChild = React.memo(({ heading, subHeading, children, rightLinkText, rightTextLinkonClick, rightLinkTo }) => {

    const isMobile = window.innerWidth <= 760;
    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';

    return (
        <React.Fragment>
            <div style={{ background: "var(--white)" }}>
                <div style={{ paddingTop: "1.25rem", paddingBottom: "1.25rem", borderRadius: isMobile ? "" : "var(--borderRadius)", }}
                    className={`leftRightPadContainer ${isDev ? 'maxWidthContainer' : ""}`}>
                    <div className='d-flex justify-content-between' style={{
                        gap: isMobile ? "0" : "1.25rem", flexDirection: "row", alignItems: 'center',
                        paddingBottom: isMobile ? "1rem" : "0"
                    }}>
                        <CardHeader heading={heading} hideHeaderBlueLine textClassName={isMobile ? "textMD w-500" : 'textXL w-700'} headingType={2} />
                        {rightLinkText && <Link to={rightLinkTo} target='_blank' className='textXS w-500' onClick={(e) => {
                            if (rightTextLinkonClick) {
                                e.preventDefault();
                                rightTextLinkonClick();
                            } else if (!rightLinkTo) {
                                e.preventDefault();
                            }
                        }}>{rightLinkText}</Link>}
                    </div>
                    {subHeading && <div className='textSM dark3' style={{ margin: "0.25rem 0 1.25rem", maxWidth: isMobile ? "100%" : "60%" }}>
                        {subHeading}
                    </div>}
                    {children}
                </div>
            </div>
        </React.Fragment>
    )
})

export const goToCustomPillTab = (tab, subTab, tabAction) => {

    const isMobile = window.innerWidth <= 760;

    function getDivHeight(query) {
        const myDiv = document.querySelector(query);
        if (myDiv) {
            const computedStyle = window.getComputedStyle(myDiv);
            const borderTopWidth = parseFloat(computedStyle.getPropertyValue("border-top-width"));
            const borderBottomWidth = parseFloat(computedStyle.getPropertyValue("border-bottom-width"));
            const totalHeight = myDiv.clientHeight + borderTopWidth + borderBottomWidth;
            return totalHeight;
        }
        return 0
    }

    function scrollToDivMinusBtm(queries = [], target, e) {
        if (e?.target?.classList?.contains('activeCustomTab')) {
            console.log('tab already active')
        }
        else {
            let height = 0;
            for (let i = 0; i < queries?.length; i++) {
                height += getDivHeight(queries[i]);
            }
            const targetElement = document.getElementById(target);
            if (target) {
                const scrollOptions = {
                    behavior: isMobile ? "instant" : 'smooth',
                    block: 'start',
                    inline: 'nearest'
                };
                const targetScrollPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - height - (isMobile ? 0 : 15);
                window.scrollTo({
                    top: targetScrollPosition,
                    ...scrollOptions
                });
            }
        }
    }

    tabAction(tab);
    setTimeout(() => {
        if (isMobile) {
            let target = document.getElementById(subTab);
            console.log(target, target.classList)
            if (target) {
                let trigger = target.querySelector('.ant-collapse-expand-icon');
                console.log(trigger)
                if (trigger) {
                    trigger.click();
                }
                scrollToDivMinusBtm(['.ant-layout-header', '#customTabsBarId'], subTab, {})
            }
        }
        else {
            scrollToDivMinusBtm(['.ant-layout-header', '#customTabsBarId'], subTab, {})
        }
    }, 10)
}

export default React.memo(CustomTabs);