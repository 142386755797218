import axios from 'axios';
import AdminServices from './AdminServices';

const API_ENDPOINT = 'https://api.finpeg.com/api';

const client = axios.create({
  baseURL: API_ENDPOINT,
  withCredentials: false
});

const getAccessToken = async () => {
  const token = await localStorage.getItem('access_token');
  return JSON.stringify({ access_token: token ? token : '' });
};

const refreshAccessToken = async () => {
  const res = await AdminServices.getAccessToken().then((d) => {
    return d;
  });
  if (res.type) {
    localStorage.setItem('access_token', res.data.accessToken);
    return res.data.accessToken;
  } else {
    return '';
  }
};

client.interceptors.request.use(
  async (config) => {
    const value = await getAccessToken();
    const keys = JSON.parse(value);
    config.headers = {
      // Authorization: `Bearer ${keys.access_token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

class DataServiceOLDAPI {
  static get(path = '', data = {}) {
    var optional = {
      'Content-Type': 'application/json'
    };
    return client({
      method: 'GET',
      url: path,
      headers: { ...axios.defaults.headers, ...optional }
    });
  }

  static post(path = '', data = {}) {
    // //console.log('sad', data);
    var optional = {
      'Content-Type': 'application/json'
    };
    return client({
      method: 'POST',
      url: path,
      data: JSON.stringify(data),
      headers: { ...axios.defaults.headers, ...optional }
    });
  }

  static postImg(path = '', data = {}) {
    // //console.log('sad', data);

    var optional = {
      'Content-Type': 'multipart/form-data'
    };

    return client({
      method: 'POST',
      url: path,
      data: data,
      headers: { ...axios.defaults.headers, ...optional }
    });
  }

  static patch(path = '', data = {}) {
    var optional = {
      'Content-Type': 'application/json'
    };
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...axios.defaults.headers, ...optional }
    });
  }

  static put(path = '', data = {}) {
    var optional = {
      'Content-Type': 'application/json'
    };
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...axios.defaults.headers, ...optional }
    });
  }

  static delete(path = '', data = {}) {
    var optional = {
      'Content-Type': 'application/json'
    };
    return client({
      method: 'DELETE',
      url: path,
      headers: { ...axios.defaults.headers, ...optional }
    });
  }
}

client.interceptors.response.use(
  (response) => {
    if (response.data.statusCode === 200) {
      return {
        msg: 'Success',
        data: response.data.result,
        type: true
      };
    } else {
      return {
        msg: response.data.errorInfo,
        data: response.data.result,
        type: false
      };
    }
  },

  async (error) => {
    const { response } = error;
    const originalRequest = error.config;

    if (response) {
      if (response.status === 401) {
        originalRequest._retry = true;
        const access_token = await refreshAccessToken();
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + access_token;
        return client(originalRequest);
      } else {
        return response;
      }
    }

    return Promise.reject(error);
  }
);

export { DataServiceOLDAPI, API_ENDPOINT };
