import { Divider } from 'antd';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { FcCandleSticks } from 'react-icons/fc';
import ActivityLoaderMintbox from './ActivityLoaderMintbox';
import { CurrencyFormat } from './CurrencyFormat';
import CustomDrawer from './CustomDrawer';
import CustomSegmentedTab from './CustomSegmentedTab';
import { changeObjKey } from './DataCustomizer';
import { formatDate } from './DateFormatter';
import { DecimalValueFormat } from './DecimalValueFormat';
import { NoDataFound } from './NoDataFound';
import RedGreenText from './RedGreenText';
import { isMarketOpen } from './atoms/IsMarketOpen';
import { PriceDisplay, StockLivePrice } from 'Services/api/LivePricesWebSocket';

require('highcharts/indicators/indicators')(Highcharts)
require('highcharts/indicators/pivot-points')(Highcharts)
require('highcharts/indicators/macd')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/map')(Highcharts)
require('highcharts/indicators/vwap')(Highcharts)

window.Highcharts = Highcharts;
let tvScriptLoadingPromise;

const PriceVolumeChart = ({ type, fundData = [], chartData = [], portfolioType = 0, defYrSelect = "365D", isOneDay = [], isWidgit = false }) => {
    // portfolioType 1 = curated , 2 = custom, 0 = for individual funds
    // portfolioType = -1 => for showing only one amount value 
    const fundColors = ['#83BF6E', '#55577022', '#555770', '#FFD966', '#FF55BB', '#0062CC', '#AA55FF', '#FF9C55', '#FF6A55', '#1A1D1F', '#00FFFF', '#DBFF00']
    var redColor = '#FF6A55'
    var greenColor = '#83BF6E'
    var fundName = fundData?.symbol || fundData?.nse_symbol
    const isMobile = window.innerWidth <= 575;
    var prevDayPrice = isOneDay?.[isOneDay?.length - 1]?.PrevClose || 0
    const onLoadScriptRef = React.useRef();
    chartData = (chartData !== null && chartData !== undefined) ? JSON.parse(chartData)?.sort((a, b) => new Date(a?.Date) - new Date(b?.Date)) : undefined

    // console.log(chartData)

    var notShow = ['CloseValue',
        // 'LastTradePrice',
        'InstrumentIdentifier',
        'PrevClose',
        'PreOpen',
        'PriceChange',
        'PriceChangePercentage',
        'CreatedOn',
        'Exchange',
        'LastTradeTime',
        // 'TotalQtyTraded',
        '50_sma',
        '200_sma',
        'Date',
        'OpenValue',
        'High',
        'Low',
        'date',
        'vwap_adj_split',
        'price_adj_div',
    ]

    var a = isOneDay !== "noData" ? isOneDay !== undefined ? isOneDay?.length !== 0 ? Object.keys(isOneDay[0])?.filter((i) => !i.includes("ret_") && !notShow.includes(i)) : [] : [] : []
    var aa = chartData !== undefined ? chartData?.length !== 0 ? Object.keys(chartData[0])?.filter((i) => !i.includes("ret_") && !notShow.includes(i)) : [] : []

    const [liveStateData, setLiveStateData] = React.useState([])
    // console.log("arpic", isOneDay?.[0], isOneDay?.[isOneDay?.length - 1])
    var finalChartData = {
        "1D": isOneDay !== "noData" ? isOneDay !== undefined ? isOneDay?.length !== 0 ? isOneDay?.map(e => {
            {
                return {
                    ...e,
                    LastTradeTime: Number(e?.["LastTradeTime"])
                }
            }
        })?.sort((a, b) => a["LastTradeTime"] - b["LastTradeTime"]) || [] : [] : [] : [],
        "5D": [...chartData].filter((item) => new Date(item?.Date) >= new Date().setDate(new Date().getDate() - 5)),
        "30D": [...chartData].filter((item) => new Date(item?.Date) >= new Date().setDate(new Date().getDate() - 30)),
        "180D": [...chartData].filter((item) => new Date(item?.Date) >= new Date().setDate(new Date().getDate() - 180)),
        "365D": [...chartData].filter((item) => new Date(item?.Date) >= new Date().setFullYear(new Date().getFullYear() - 1)),
        "1095D": [...chartData].filter((item) => new Date(item?.Date) >= new Date().setFullYear(new Date().getFullYear() - 3)),
        "1825D": [...chartData].filter((item) => new Date(item?.Date) >= new Date().setFullYear(new Date().getFullYear() - 5)),
        "3650D": [...chartData].filter((item) => new Date(item?.Date) >= new Date().setFullYear(new Date().getFullYear() - 10)),
        "max": [...chartData],
        "trade": []
    }
    // console.log(finalChartData?.["1D"].length, finalChartData?.["1095D"].length, finalChartData?.["1825D"].length, finalChartData?.["3650D"].length, finalChartData?.["max"].length,)
    let yrtabs = finalChartData !== undefined ? [
        { key: "1", label: "1D", value: "1D", show: finalChartData["1D"] !== undefined ? finalChartData?.["1D"].length > 0 : false },
        { key: "2", label: "5D", value: "5D", show: finalChartData["5D"] !== undefined ? finalChartData?.["5D"].length > 0 : false, text: "in past 5 days" },
        { key: "3", label: "1M", value: "30D", show: finalChartData["30D"] !== undefined ? finalChartData?.["30D"].length > 0 : false, text: "in past 1 month" },
        { key: "4", label: "6M", value: "180D", show: finalChartData["180D"] !== undefined ? finalChartData?.["180D"].length > 0 : false, text: "in past 6 months" },
        { key: "5", label: "1Y", value: "365D", show: finalChartData["365D"] !== undefined ? finalChartData?.["365D"].length > 0 : false, text: "in past 1 year" },
        { key: "6", label: "3Y", value: "1095D", show: finalChartData["1095D"] !== undefined ? finalChartData?.["1095D"].length > 0 && finalChartData?.["1095D"].length !== finalChartData?.["1825D"].length : false, text: "in past 3 years" },
        { key: "7", label: "5Y", value: "1825D", show: finalChartData["1825D"] !== undefined ? finalChartData?.["1825D"].length > 0 && finalChartData?.["1825D"].length !== finalChartData?.["3650D"].length : false, text: "in past 5 years" },
        { key: "8", label: "10Y", value: "3650D", show: finalChartData["3650D"] !== undefined ? finalChartData?.["3650D"].length > 0 && finalChartData?.["3650D"].length !== finalChartData?.["max"].length : false, text: "in past 10 years" },
        { key: "9", label: "Max", value: "max", text: `since ${formatDate(new Date(finalChartData["max"]?.[0]?.["Date"]))}` },
        type?.value === 4 && { key: "trade", label: <FcCandleSticks size='1.25rem' style={{ background: "var(--grey4)" }} />, show: true, value: "trade" }
    ] : []
    yrtabs = yrtabs?.filter((el) => el)
    const [chartType, setChartType] = React.useState(finalChartData?.[defYrSelect]?.length > 0 ? defYrSelect || yrtabs?.filter((i) => i?.show !== false)[0]?.value : yrtabs?.filter((i) => i?.show !== false)[0]?.value)
    // console.log(chartType)

    const indexPrice = React.useMemo(() => {
        let result;
        if (chartData?.length > 0 && String(fundData?.IndexName)?.startsWith('SHI:')) {
            let indexOHLC = [...chartData]?.reverse()?.slice(0, 2)?.reverse();
            // console.log(indexOHLC);
            let currPriceObj = indexOHLC?.[1];
            let prevPriceObj = indexOHLC?.[0];
            let currentLTP, changePer, changeVal, dateTime = '-';
            currentLTP = currPriceObj?.price_adj_split;
            changeVal = currentLTP - prevPriceObj?.price_adj_split;
            changePer = (changeVal / prevPriceObj?.price_adj_split) * 100;
            dateTime = new Date(currPriceObj?.Date)?.toLocaleString('en-IN', {
                day: "2-digit", month: "short", hour: "numeric",
                minute: "2-digit", timeZone: 'Asia/Kolkata',
            })
            result = {
                currentLTP, changePer, changeVal, dateTime
            }
        }
        return result;
    }, [chartData]);

    React.useEffect(() => {
        if (chartType === 'trade') {
            window.open(`/technical-chart?symbol=${fundName?.replaceAll('&', '_')}`, '_blank');
            setChartType(defYrSelect)
        }
    }, [chartType])


    // React.useEffect(() => {
    //     console.log(finalChartData?.['1D']?.length)
    //     if (finalChartData?.['1D']?.length === 0) {
    //         // window.open(`/technical-chart?symbol=${fundName?.replaceAll('&', '_')}`, '_blank');
    //         setChartType(defYrSelect)
    //     }
    // }, [finalChartData])

    var today9 = new Date(Math.max(...finalChartData?.[chartType]?.map(e => chartType === "1D" ? e?.["LastTradeTime"] * 1000 : new Date(e?.["Date"]) * 1000)));

    var date = today9.getDate()
    var month = today9.getMonth()
    var year = today9.getFullYear()
    // today9.setHours(9)
    // //console.log(today9)


    try {
        var gchartData = [...(chartType === "1D" ? a : aa)].map((item, index) => {

            var dataFinal = chartType === "1D" ? changeObjKey(JSON.parse(JSON.stringify(finalChartData?.[chartType])), [String(item), "LastTradeTime"], ["y", "x"], "stockLiveDaily", 1) : changeObjKey(JSON.parse(JSON.stringify(finalChartData?.[chartType])), [String(item), "Date"], ["y", "x"], "type", 1)

            if (dataFinal?.length === 0) {
                return {}
            } else {
                var changeVal, changePer = 0
                if (chartType !== "1D") {
                    changeVal = dataFinal[dataFinal?.length - 1]?.y - dataFinal[0]?.y
                    changePer = ((dataFinal[dataFinal?.length - 1]?.y - dataFinal[0]?.y) / dataFinal[0]?.y) * 100
                } else {
                    changeVal = dataFinal[dataFinal?.length - 1]?.y - prevDayPrice
                    changePer = ((dataFinal[dataFinal?.length - 1]?.y - prevDayPrice) / dataFinal[0]?.y) * 100
                }



                return {
                    name: item === "volume_adj" || item === 'TotalQtyTraded' ? "Volume" : "Price",
                    id: item,
                    type: item === "volume_adj" || item === 'TotalQtyTraded' ? 'column' : chartType === "1D" ? 'area' : 'areaspline',
                    yAxis: item === "volume_adj" || item === 'TotalQtyTraded' ? 1 : 0,
                    turboThreshold: 9e9,
                    zIndex: item === "volume_adj" || item === 'TotalQtyTraded' ? 100 : 101,
                    color: (item === "volume_adj" || item === 'TotalQtyTraded') ? fundColors[1] : changePer > 0 ? greenColor : redColor,
                    tooltip: {
                        valueDecimals: item === "volume_adj" || item === 'TotalQtyTraded' ? 0 : 2,
                        valuePrefix: item === "volume_adj" || item === 'TotalQtyTraded' ? "" : '₹',
                    },
                    marker: {
                        enabled: false
                    },
                    changePer: changePer,
                    changeVal: changeVal,
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, changePer > 0 ? greenColor + "77" : redColor + "77"],
                            [0.1, changePer > 0 ? greenColor + "10" : redColor + "10"],
                        ]
                    },


                    data: dataFinal
                }
            }
        })

        var priceD = gchartData?.length > 0 ? gchartData?.filter((i) => i?.name === "Price")?.[0] : []
        var dataMin = gchartData?.length > 0 ? Math.min(...(priceD?.data?.map((i) => i?.y))) : 0
        var dataMax = gchartData?.length > 0 ? Math.max(...(priceD?.data?.map((i) => i?.y))) : 0
        var minVal = chartType === "1D" ? (dataMin < prevDayPrice) ? null : prevDayPrice : null
        var maxVal = chartType === "1D" ? (dataMax > prevDayPrice) ? null : prevDayPrice : null

        // console.log(gchartData, dataMin, dataMax, minVal, maxVal)
        if (minVal !== null) {
            maxVal = null
        }

        if (maxVal !== null) {
            minVal = null
        }

        var opn = {
            chart: {
                height: 300
            },
            title: {
                text: ''
            },
            navigator: {
                enabled: true
            },
            legend: {
                enabled: false
            },

            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            boost: {
                useGPUTranslations: true,
            },
            rangeSelector: {
                selected: 1,
                enabled: false
            },
            navigator: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            yAxis: [{
                // visible: false,
                // tickAmount: 5,
                "min": minVal,
                "max": maxVal,
                plotLines: [chartType === "1D" ? {
                    color: 'var(--dark3)',
                    value: prevDayPrice,
                    dashStyle: 'dash',
                    width: 1,
                    zIndex: 102,
                    label: {
                        text: `Previous close <br>(${CurrencyFormat(prevDayPrice)})`, rotation: 0, x: 10,
                        align: 'right',
                        style: {
                            color: "var(--dark3)",
                            // fontWeight: 'bold'
                        }
                    },
                    color: 'var(--dark2)',
                } : {}],
                gridLineDashStyle: 'longdash',
                title: {
                    text: ''
                },
                labels: {
                    enabled: false
                },
                gridLineColor: 'transparent',
                // height: '75%',
                resize: {
                    enabled: true
                }
            }, {
                visible: false,
                tickAmount: 0,
                gridLineDashStyle: 'longdash',
                title: {
                    text: ''
                },
                gridLineColor: 'var(--grey1)',
                top: '70%',
                height: '30%',
                offset: 0
            }],
            plotOptions: {
                areaspline: {
                    threshold: null
                },
                area: {
                    threshold: null
                },
                series: {
                    lineWidth: 1,
                    label: {
                        connectorAllowed: true
                    },
                    marker: {
                        enabled: false
                    },
                }
            },
            xAxis: [{
                tickPixelInterval: chartType === "1D" ? 200 : 110,
                type: 'datetime',
                showFirstLabel: true,
                pointStart: today9.getTime(),
                // ordinal: true,
                labels: {
                    format: chartType === "1D" ? '{value:%I:%M}' : (chartType === "5D" || chartType === "30D") ? '{value:%e %b}' : ["180D", "365D", '1095D']?.includes(chartType) ? '{value:%b %y}' : '{value:%Y}',
                    style: {
                        fontSize: '8px'
                    }
                },
                tickPositions: chartType === "5D" || chartType === "30D" ? gchartData?.[0]?.data?.map((i) => i?.x) : undefined,
                min: chartType === "1D" ? Date.UTC(year, month, date, 3, 45, 0, 0) : null,
                max: chartType === "1D" ? Date.UTC(year, month, date, 10, 0, 0, 0) : null,
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%e %b',
                    week: '%e %b',
                    month: '%b %y',
                    year: '%Y'
                },
                lineColor: "var(--grey1)",
                tickColor: "var(--grey1)",
            }
            ],
            tooltip: {
                split: false,
                shared: true
            },
            series: gchartData,
            time: {
                timezone: "Asia/Kolkata",
                timezoneOffset: -330
            }
        }

        return (
            <div style={{}}>
                <div style={{ flex: 1, display: "flex", justifyContent: 'space-between', flexWrap: "wrap", gap: "1rem" }}
                    className='align-items-start align-items-sm-center flex-column flex-sm-row'>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', }} className='w100-sm'>
                        <div>
                            {!isWidgit && (String(fundData?.IndexName)?.startsWith('SHI:') ? <PriceDisplay priceObj={indexPrice} format={9} /> :
                                <StockLivePrice symbol={fundName} fundData={fundData} format={9} />)}
                            {chartType !== '1D' && <div className='textSM dark3' style={{ position: 'absolute', zIndex: 1, marginTop: '0.25rem' }}>
                                <span className='textSM w-500'>{RedGreenText({
                                    text: <span>
                                        {/* {CurrencyFormat(gchartData?.[0]?.changeVal, 'long', 2, '', false)}  */}
                                        {DecimalValueFormat({
                                            num: gchartData?.[0]?.changePer,
                                            suffix: "%"
                                        })} </span>,
                                    value: gchartData?.[0]?.changePer,
                                    arrow: true
                                })}</span>
                                <span>
                                    {yrtabs?.find((ele) => ele.value === chartType)?.text}
                                </span>
                            </div>}
                        </div>
                    </div>
                    <div className='w100-sm' style={{ marginTop: isMobile ? '1rem' : 0 }}>
                        <Divider style={{ margin: 0, marginBottom: "0.5rem" }} />
                        <CustomSegmentedTab
                            isBlock={isMobile ? true : false}
                            keepSameInMobile={true}
                            className='performanceChartYrSegmented'
                            style={{ backgroundColor: "transparent", padding: "0rem 0.5rem" }}
                            options={yrtabs?.filter((i) => i?.show !== false)}
                            value={chartType}
                            onChange={(e) => setChartType(e)}
                        />
                        <Divider style={{ margin: 0, marginTop: "0.5rem" }} />
                    </div>
                </div>
                {
                    gchartData?.length > 0 ?
                        <HighchartsReact containerProps={{ style: {} }} highcharts={Highcharts} options={opn} constructorType={chartType === "1D" ? 'chart' : 'stockChart'} /> :
                        <div style={{ display: "flex", height: "10.8rem", justifyContent: "center" }}>
                            {isOneDay === "noData" ? <NoDataFound message="Live chart not available right now" /> : <ActivityLoaderMintbox />}
                        </div>
                }
            </div >
        );
    } catch (error) {
        return <div className='textSM' style={{ margin: "4%", textAlign: "center" }}>
            {chartType === 'trade' ? <>
                <ActivityLoaderMintbox />
            </> : "No chart data available!"}
        </div>
    }
};
export default PriceVolumeChart;