import { Card, Divider, Modal, Tag, Timeline } from "antd";
import CustomSegmentedTab from "Components/CustomSegmentedTab";
import { StickyScrollTabs } from "Components/CustomComponents/StickyScrollTabs";
import { NoDataFound } from "Components/NoDataFound";
import moment from "moment";
import React from "react";
import { BiDetail } from 'react-icons/bi';
import { IoIosArrowDown } from "react-icons/io";
import { RiVideoFill } from 'react-icons/ri';
import { VscFilePdf } from 'react-icons/vsc';
import { Col, Row } from "reactstrap";
import { arePropsEqual, makeid } from "Libs/utils";
import AMCIcon from "Components/AMCIcon";

export const Resources = ({ resources = {}, loadingResources, isMobile, allFunds = [] }) => {

    console.log(resources);

    const isBSE = resources?.response_structure === 'BSE';

    const Downloads = () => {

        const ViewItem = ({ viewType = 'Annual reports', data = [], dateKey = 'year', type = 1, showColumns = false }) => {
            // type = 1  for annual report, 2 for quarterly results, 3 for others
            console.log(data);
            return (
                <Card style={{ border: "1px solid var(--grey2)" }} bodyStyle={{ padding: "1rem" }} className='w100 h100'>
                    <div className="textMD w-500">
                        {viewType}
                    </div>
                    <Divider style={{ margin: "0.25rem 0 0.75rem" }} />
                    {data?.length == 0 ? <div style={{ margin: "2rem 0" }}>
                        <div className='textSM w-500 dark3 text-center'>No data</div>
                    </div> : <>
                        {showColumns && <div className='d-flex align-items-center justify-content-end' style={{ gap: "0.75rem", marginBottom: "0.75rem" }}>
                            <div className='textSM w-500  dark4'>Video</div>
                            <div className='textSM w-500  dark4'>Transcript</div>
                        </div>}
                        <div className='d-flex flex-column scrollbox' style={{ gap: "1.25rem", maxHeight: isMobile ? "25rem" : "20rem", overflowY: "auto" }} >
                            {data?.map((item, i) => {
                                return (
                                    <div key={i} className='d-flex align-items-center' style={{ gap: "0.75rem", flexWrap: "wrap", justifyContent: "space-between" }}>
                                        <div className='textSM  w-500 dark4' style={{ flex: 1 }}>
                                            {type === 1 ? `Financial year ${item?.[dateKey]}` : type === 2 ? `Quarter end ${moment(new Date(item?.[dateKey])).format('MMM YY')}` :
                                                moment(new Date(item?.[dateKey])).format('DD MMM, YY')}
                                        </div>
                                        <div className='d-flex align-items-center' style={{ gap: "0.75rem", flexWrap: "wrap" }}>
                                            {showColumns && <a href={item?.audio_video_file} target='_blank' style={{ marginRight: "3rem" }}>
                                                <RiVideoFill className='textMD' style={{ color: item?.audio_video_file ? "var(--primary)" : "var(--grey1)" }} />
                                            </a>}
                                            <a href={item?.pdf_link} target='_blank'>
                                                <VscFilePdf className='textMD' style={{ marginRight: showColumns ? "1rem" : "" }} />
                                            </a>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </>}
                </Card>
            )
        }

        return (
            <>
                <Row lg={4} sm={2} xs={1} className='gx-3 gy-3'>
                    <Col> <ViewItem viewType='Annual reports' data={resources?.annual_report} dateKey='year' type={1} /> </Col>
                    <Col> <ViewItem viewType='Quarterly results' data={resources?.quarter_res} dateKey='quarter_end' type={2} /> </Col>
                    <Col> <ViewItem viewType='Concalls' data={resources?.earn_call} dateKey='date_of_announcement' type={3} showColumns /> </Col>
                    <Col> <ViewItem viewType='Presentation' data={resources?.inv_presentation} dateKey='date_of_announcement' type={3} /> </Col>
                </Row>
            </>
        )
    }

    const Announcements = () => {

        const getTabs = React.useCallback(() => {
            if (isBSE) {
                let initTabs = resources?.corp_ann_tabs?.filter((el) => el !== 'Others') || [];
                const customizeData = (data, isRecent) => {
                    let dates = data?.map((el) => {
                        return moment(new Date(el?.date_of_announcement))?.format('DD MMM, YYYY');
                    }) || []
                    dates = [...(new Set(dates || []))];
                    if (isRecent) {
                        dates = dates?.splice(0, 5);
                    }
                    dates = dates?.map((date, i) => {
                        let evs = data?.filter((event) => {
                            let eventDate = moment(new Date(event?.date_of_announcement))?.format('DD MMM, YYYY');
                            return eventDate === date;
                        }) || [];
                        return {
                            date: date,
                            announcements: evs,
                            key: i
                        }
                    });
                    return dates;
                }
                let temp = [];
                let temp2 = initTabs?.map((ele, i) => {
                    let data = resources?.corp_announcements?.filter((el) => el?.category === ele);
                    let obj = {
                        key: ele,
                        label: ele,
                        value: i + 1,
                        data: customizeData(data)
                    }
                    return obj;
                })
                let others = resources?.corp_announcements?.filter((el) => !initTabs?.includes(el?.category));
                temp2.push({ key: 'Others', label: 'Others', data: customizeData(others), value: temp2?.length + 1 });
                temp = [...temp, ...temp2]
                return temp;
            }
            else return [];
        }, [resources]);

        const tabs = getTabs();

        const structuredData = React.useMemo(() => {
            let dates = resources?.corp_announcements?.map((el) => {
                return moment(new Date(el?.date_of_announcement))?.format('DD MMM, YYYY');
            }) || []
            dates = [...(new Set(dates || []))];
            dates = dates?.map((date, i) => {
                let evs = resources?.corp_announcements?.filter((event) => {
                    let eventDate = moment(new Date(event?.date_of_announcement))?.format('DD MMM, YYYY');
                    return eventDate === date;
                }) || [];
                return {
                    date: date,
                    announcements: evs,
                    key: i
                }
            });
            return dates;
        }, [resources]);

        const [selectedTab, setSelectedTab] = React.useState(1);
        const [itemsToShow, setItemsToShow] = React.useState(5);

        const data = isBSE ? tabs?.find((el) => el?.value === selectedTab)?.data : structuredData;

        const FeedCard = ({ data }) => {
            return <Card
                className="shadowXS"
                key={makeid(3)}
                bordered
                style={{ marginBottom: '1rem', padding: "0" }}
                bodyStyle={{ padding: "1rem" }}
                onClick={() => {
                }}
            >
                <div className='mb-3' style={{ display: 'flex', gap: '0.75rem', width: '100%', justifyContent: "space-between", alignItems: "flex-start" }}>
                    <div className="textMD w-700 dark4">
                        {!isBSE ? data?.category : data?.sub_category}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: "0.75rem" }}>
                        {data?.date_of_announcement && !isMobile && <div className="textXS w-400 dark3" style={{ whiteSpace: 'nowrap' }}>
                            {moment(data?.date_of_announcement).format('Do MMM YY')}
                        </div>}
                        {data?.pdf_link ? <div >
                            <a href={data?.pdf_link} target='_blank'>
                                <VscFilePdf size={'1.25rem'} color="var(--red)" />
                            </a>
                        </div> : <div style={{ width: '2rem' }}></div>}
                    </div>
                </div>
                <div className="textSM w-500 mb-2">
                    {data?.short_desc}
                </div>
                {isBSE && <div className='textSM'>
                    {data?.headline}
                </div>}
            </Card>
        }

        return (
            <div>
                {isBSE && <CustomSegmentedTab options={tabs} value={selectedTab} onChange={(e) => {
                    setSelectedTab(e);
                    setItemsToShow(5)
                }} isBlock={false} />}
                {data?.length === 0 ? <div style={{ margin: "4rem 0" }}>
                    <div className='textSM w-500 dark3 text-center'>No announcements</div>
                </div> : <div style={{ marginTop: "1.25rem" }}>
                    <Timeline items={data?.slice(0, itemsToShow)?.map((item) => {
                        return {
                            children: <React.Fragment key={item?.key}>
                                <div className="textSM w-500 dark3">{moment(item?.date).format('Do MMM YYYY')}</div>
                                <div style={{ marginTop: "1rem" }}>
                                    {item?.announcements?.map((ele, iop) => {
                                        return <FeedCard data={ele} key={makeid(iop)} />
                                    })}
                                </div>
                            </React.Fragment>
                        }
                    })} />
                    {data?.length > itemsToShow && <button className='btnBlack' onClick={() => setItemsToShow(prev => prev + 5)} style={{ marginTop: "0.25rem" }}>Show more</button>}
                </div>}
            </div>
        )
    }

    const Events = () => {

        const getEvents = React.useCallback(() => {
            let initEvents = resources?.events || [];
            let dates = initEvents?.map((el) => {
                return moment(new Date(el?.DATE))?.format('DD MMM, YYYY');
            })
            dates = [...(new Set(dates))];
            dates = dates?.map((date, i) => {
                let evs = initEvents?.filter((event) => {
                    let eventDate = moment(new Date(event?.DATE))?.format('DD MMM, YYYY');
                    return eventDate === date;
                }) || [];
                return {
                    date: date,
                    events: evs,
                    key: i
                }
            })
            return dates;
        }, [resources]);

        const events = getEvents();

        const filterEvents = React.useCallback((type) => {
            //  type = 0 for upcoming, 1 for today;
            if (type === 1) {
                let d = moment(new Date).format('DD MMM, YYYY');
                let temp = events?.filter((ele) => ele?.date === d) || [];
                return temp;
            }
            else if (type === 0) {
                let d = moment(new Date).format('DD MMM, YYYY');
                let temp = events?.filter((ele) => {
                    return new Date(ele?.date) >= new Date(d);
                })
                return temp;
            }
        }, [events])

        const tabs = [
            { key: 0, value: 0, label: "Upcoming", data: filterEvents(0) },
            { key: 1, value: 1, label: "Today", data: filterEvents(1) },
            { key: 2, value: 2, label: "All", data: events },
        ]

        const [selectedTab, setSelectedTab] = React.useState(0);
        const [itemsToShow, setItemsToShow] = React.useState(5);
        const data = tabs?.find((el) => el?.value === selectedTab)?.data || [];

        const EventItem = ({ item = {} }) => {
            const [collapse, setCollapse] = React.useState(false);
            return (
                <div style={{ borderBottom: "1px solid var(--grey3)" }}>
                    <div className='d-flex  align-items-center eventItemTrigger' style={{ gap: "0.5rem", padding: "0.75rem 1rem", background: collapse ? "var(--blueShade)" : "", cursor: "pointer" }}
                        onClick={() => setCollapse(prev => !prev)}>
                        <div className={`${collapse ? 'w-500' : ''} textXS dark4`} style={{ userSelect: "none" }}>{item?.PURPOSE}</div>
                        <IoIosArrowDown style={{ fontSize: "0.75rem", transform: collapse ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.2s ease-in-out", }} />
                    </div>
                    {collapse && <div className='textXS w-500 dark4' style={{ padding: "0.75rem 1rem", userSelect: "none" }} >
                        {item?.DETAILS}
                    </div>}
                </div>
            )
        }

        return (
            <div>
                {resources?.events?.length === 0 ? <div style={{ margin: "2rem 0" }}>
                    <NoDataFound message={`No events`} />
                </div> : <div>
                    <CustomSegmentedTab options={tabs} value={selectedTab} onChange={(e) => {
                        setSelectedTab(e);
                        setItemsToShow(5)
                    }} isBlock={false} />
                    <div style={{ marginTop: "1.25rem" }}>
                        {data?.length === 0 ? <div style={{ margin: "2rem 0" }}>
                            <div className='textSM w-500 dark3 text-center'>No scheduled events</div>
                        </div> : <div>
                            {data?.slice(0, itemsToShow)?.map((item) => {
                                return (
                                    <div key={item?.key}>
                                        <div className='textSM w-500 dark4' style={{ padding: "0.5rem 0.75rem", background: "var(--blue2)", borderLeft: "2px solid var(--primary)" }}>
                                            {item?.date}
                                        </div>
                                        <div>
                                            {item?.events?.map((ele, i) => <EventItem item={ele} key={i} />)}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>}
                        {data?.length > itemsToShow && <button className='btnBlack' onClick={() => setItemsToShow(prev => prev + 5)} style={{ marginTop: "1.25rem" }}>Show more</button>}
                    </div>
                </div>}
            </div>
        )
    }

    const tabData = [
        { key: "Downloads", heading: "Downloads", value: "Downloads", children: <Downloads /> },
        { key: "Announcements", heading: "Announcements", value: "Announcements", children: <Announcements /> },
        // { key: "Events", heading: "Events", value: "Events", children: <Events /> }
    ]

    return (
        <React.Fragment>
            <StickyScrollTabs isAccordionInMob queriesToStickToBot={['#sharpelyHeader', '#customTabsBarId']} tabData={tabData} loading={loadingResources} />
        </React.Fragment>
    )
};