import { formatDateMMMDDYYYY } from "Components/DateFormatter";
import { useSubscribeMiddleware } from "Components/SubscribeModal";
import { useIsMobile } from "CustomHooks/useIsMobile";
import { makeid } from "Libs/utils";
import apiHelperPY from "Services/api/ApiHelperPY";
import { Avatar, Badge } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Col, Row } from "reactstrap";

export const CommunityScreenSection = () => {

    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const subscribeMiddle = useSubscribeMiddleware();
    let user = useSelector((state) => state?.updateUserData?.value);
    const [allPublishedScreens, setallPublishedScreens] = React.useState([])
    const [allPublishers, setAllPublishers] = React.useState([])
    const [loading, setLoading] = React.useState(true);

    const loadData = async () => {
        let allPublishers = await apiHelperPY({ apiName: "getAllPublisherProfile", saveResponse: "sessionStorage" });
        allPublishers = JSON.parse(allPublishers);
        setAllPublishers(allPublishers);
        let allPublishedScreens = await apiHelperPY({ apiName: "getAllPublishedScreens" });
        setallPublishedScreens(JSON.parse(allPublishedScreens)?.filter((i) => i?.client_id !== user?.id)?.sort((a, b) => new Date(b?.created_on) - new Date(a?.created_on)))
        setLoading(false)
    }

    React.useEffect(() => {
        loadData();
    }, [])

    return (
        <div style={{
            flex: 1, height: '100%', width: '100%', display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center',
        }}>
            <div style={{
                display: 'flex', flexDirection: 'column', flex: 1,
                justifyContent: 'space-between', height: '100%', width: '100%', gap: "3rem", paddingLeft: 0, paddingRight: 0
            }} className='padAllCampaign'>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className='padLeftCampaign padRightCampaign'>
                    <h2 className='headLGtoXS margin0' style={{ fontWeight: 700, width: isMobile ? "100%" : "20ch", color: 'var(--white2)' }}>
                        <span style={{ color: "gold" }}>Screens</span> curated by sharpely community
                    </h2>
                    <div className='textMDtoESM dark2' style={{ width: isMobile ? "100%" : "65%", paddingTop: "1rem", }}>
                        Explore screens created by our community experts including SEBI registered entities. If you like a screen, you can clone it and save as your own screen.
                    </div>
                </div>
                <div className="padLeftCampaign padRightCampaign">
                    <Row lg={3} md={3} sm={2} xs={1} className="gx-4 gy-4">
                        {allPublishedScreens?.map((item, kl) => {
                            var publisherD = allPublishers?.filter((i) => i?.ClientId === item?.client_id)?.[0]
                            if (publisherD?.IsApproved === 1)
                                return <Col key={makeid(kl + 1)} >
                                    <Badge.Ribbon key={makeid(kl + 1)} text={String(item?.instrument_type)?.replace(String(item?.instrument_type)?.charAt(0), String(item?.instrument_type)?.charAt(0)?.toLocaleUpperCase())}
                                        color="geekblue">
                                        <div style={{ border: "1px solid var(--dark4)", padding: "1rem", borderRadius: "0.5rem", cursor: "pointer" }}
                                            key={makeid(kl + 1)}
                                            className="w100 h100 analyseStockItem"
                                            onClick={() => {
                                                subscribeMiddle({
                                                    reqAccessLevel: 1,
                                                    onNext: () => {
                                                        navigate(`/stocks/super-screener?screenName=${item?.screen_name?.replaceAll(' ', '-')}&screenId=${item?.screen_id}&userId=${item?.client_id || 0}`)
                                                    },
                                                    loginModalParams: {
                                                        type: "register",
                                                        executeOnLoginOrSignup: () => {
                                                            navigate(`/stocks/super-screener?screenName=${item?.screen_name?.replaceAll(' ', '-')}&screenId=${item?.screen_id}&userId=${item?.client_id || 0}`)
                                                        }
                                                    }
                                                })
                                            }}
                                        >
                                            <div className='mb-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "0.5rem" }}>
                                                <div style={{ flex: 1, display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                                    <Avatar src={publisherD?.PublisherIcon} style={{ filter: 'brightness(1) contrast(1.2) sepia(1) hue-rotate(190deg) saturate(1.5)' }} />
                                                    <div>
                                                        <div className='textEllipsis1 w-500 textMDtoSM' style={{ color: "var(--white2)" }}>{item?.screen_name || item?.checklist_name}</div>
                                                        <div className='textXS dark2'>By {<span style={{
                                                            color: "var(--primary)", cursor: "pointer",
                                                        }} onClick={(e) => {
                                                            e.stopPropagation()
                                                            subscribeMiddle({
                                                                reqAccessLevel: 1,
                                                                onNext: () => {
                                                                    navigate(`/publisher-profile?name=${publisherD?.Name?.replaceAll(" ", "-")}&id=${publisherD?.UID}`)
                                                                },
                                                                loginModalParams: {
                                                                    type: "register",
                                                                    executeOnLoginOrSignup: () => {
                                                                        navigate(`/publisher-profile?name=${publisherD?.Name?.replaceAll(" ", "-")}&id=${publisherD?.UID}`)
                                                                    }
                                                                }
                                                            })
                                                        }}>{String(publisherD?.Name)?.substring(0, 20)}{publisherD?.Name?.length > 15 ? "..." : ""}</span>} on {formatDateMMMDDYYYY(item?.created_on)}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='textESM textEllipsis3 dark2 w-400' dangerouslySetInnerHTML={{ __html: `${item?.screen_desc?.replaceAll("{\"\n\"}", "")}` }}>
                                            </div>
                                        </div>
                                    </Badge.Ribbon>
                                </Col>
                            else
                                return null

                        })}
                    </Row>
                </div>
            </div>
        </div>
    )
}