import { useGetAssetType } from 'Libs/utils';
import { Divider, Popover } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react';
import { BsFillSquareFill } from 'react-icons/bs';
import { MdEdit } from 'react-icons/md';
import { CurrencyFormat } from './CurrencyFormat';
import { InstrumentListSearch } from './CustomComponents/InstrumentListSearch';
import CustomSegmentedTab from './CustomSegmentedTab';
import { changeObjKey } from './DataCustomizer';
import { dayDiff, formatDateMMMDDYYYY } from './DateFormatter';
import RedGreenText from './RedGreenText';

const IndexPerformanceChart = ({
    fundData = [],
    benchData = [],
    indexPriceChartData = [],
    indexBenchmarkPriceChartData = [],
    onBenchChange = () => { },
    defAmtSelect = "",
    defYrSelect = "1095D",
    xAxisKey = "Date",
    fundColors = ['#007AFF', '#555770', '#83BF6E', '#FFD966', '#FF55BB', '#0062CC', '#AA55FF', '#FF9C55', '#FF6A55', '#1A1D1F', '#00FFFF', '#DBFF00'],
    isWebSocket = false,
    key1 = "Portfolio",
    key2 = "Benchmark",
}) => {
    const chartData = indexPriceChartData.map(day => {
        return { 'Date': day?.Date, 'Portfolio': day?.price_adj_split, 'Benchmark': indexBenchmarkPriceChartData?.find(d => d.Date?.slice(0, 10) === day.Date?.slice(0, 10))?.price_adj_split }
    }).filter(x => x?.Benchmark && x?.Portfolio)

    const isMobile = window.innerWidth <= 575;
    let fundName = fundData?.symbol
    let benchName = benchData?.symbol
    const type = useGetAssetType()

    const yrtabs = chartData !== undefined ? [
        { key: 11, label: "1M", value: "30D" },
        { key: 12, label: "6M", value: "180D" },
        { key: 1, label: "1Y", value: "365D" },
        { key: 3, label: "3Y", value: "1095D" },
        { key: 5, label: "5Y", value: "1825D" },
        { key: 10, label: "10Y", value: "3650D" },
        { key: 0, label: "Max", value: "max" },
    ] : []

    var startDate_full = chartData !== undefined ? [...chartData]?.[0]?.[xAxisKey] || null : null
    var endDate_full = chartData !== undefined ? [...chartData]?.[chartData?.length - 1]?.[xAxisKey] || null : null

    var day_diff = 365000
    if (endDate_full !== null && startDate_full !== null) {
        day_diff = dayDiff(startDate_full, endDate_full)
    }

    var allowedTabs = yrtabs?.filter((i) => {
        var days = i?.value?.replace("D", '')
        var dateOffset = days === "max" ? true : Number(days) <= day_diff;
        return i.show !== false && dateOffset
    })

    if (!allowedTabs?.map((i) => i.value).includes(defYrSelect)) {
        defYrSelect = allowedTabs[allowedTabs.length - 1]?.value
    }

    const [noChart, setnoChart] = React.useState(false);
    const [chartType, setChartType] = React.useState(defYrSelect)
    const chartAmount = defAmtSelect || 10000
    const [finalChartData, setfinalChartData] = React.useState([])

    React.useEffect(() => {
        if (chartData !== undefined) {
            var days = chartType?.replace("D", '')
            var dateOffset = days === "max" ? 0 : Number(days);
            var filteredData = dateOffset === 0 ? [...chartData] : [...chartData].filter((item) => dayDiff(new Date(item?.[xAxisKey]), endDate_full) <= Number(dateOffset))
            var chartDataFinal = JSON.stringify(filteredData?.map((i, index) => {
                var dataFinal = filteredData
                var changePer = index === 0 ? 0 : ((dataFinal[index]?.[key1] - dataFinal[0]?.[key1]) / dataFinal[0]?.[key1]) * 100
                var changePer2 = index === 0 ? 0 : ((dataFinal[index]?.[key2] - dataFinal[0]?.[key2]) / dataFinal[0]?.[key2]) * 100
                return {
                    ...i,
                    Portfolio: Number(i?.[key1] * (chartAmount / dataFinal?.[0]?.[key1]))?.toFixed(2),
                    Benchmark: Number(i?.[key2] * (chartAmount / dataFinal?.[0]?.[key2]))?.toFixed(2),
                    ret_Portfolio: changePer?.toFixed(2),
                    ret_Benchmark: changePer2?.toFixed(2),
                }
            }))
            setfinalChartData({ data: chartDataFinal })
        }
    }, [chartType])

    var a = ["Portfolio", "Benchmark"]


    if (a.length === 2) {
        fundColors = ['#007AFF', '#83BF6E', '#555770', '#FFD966', '#FF55BB', '#0062CC', '#AA55FF', '#FF9C55', '#FF6A55', '#1A1D1F', '#00FFFF', '#DBFF00']
    } else {
        fundColors = ['#007AFF', '#555770', '#83BF6E', '#FFD966', '#FF55BB', '#0062CC', '#AA55FF', '#FF9C55', '#FF6A55', '#1A1D1F', '#00FFFF', '#DBFF00']
    }

    try {
        var options = {
            chart: {
                backgroundColor: "transparent",
                marginLeft: 0,
                marginRight: 0,
            },
            title: null,
            exporting: {
                enabled: false
            },
            yAxis: {
                visible: false,
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                title: null,
                tickColor: 'transparent',
                gridLineColor: 'var(--grey1)',
            },
            xAxis: [{
                tickPixelInterval: 110,
                title: null,
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%e %b',
                    week: '%e %b',
                    month: '%b %y',
                    year: '%Y'
                },
                lineColor: 'var(--grey1)',
                tickColor: 'var(--grey1)',
            }
            ],
            legend: {
                enabled: false
            },
            plotOptions: {
                areaspline: {
                    threshold: null
                },
                series: {
                    lineWidth: 1,
                    label: {
                        connectorAllowed: false
                    },
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                shared: true,
                valueDecimals: 0,
                valuePrefix: '₹',
            },
            credits: {
                enabled: false
            },
            boost: {
                useGPUTranslations: true,
            },
            series: a.map((item, index) => {
                return {
                    name: item === "Portfolio" ? fundName : item === "Benchmark" ? benchName : "",
                    turboThreshold: 9e9,
                    color: fundColors[index],
                    tooltip: {
                        valueSuffix: ' ({point.ret_' + item + '}%)',
                    },
                    type: a.length > 3 ? "spline" : "areaspline",
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, fundColors[index] + "77"],
                            [0.1, fundColors[index] + "11"]
                        ]
                    },
                    data: changeObjKey(finalChartData, [String(item), xAxisKey], ["y", "x"], "mfetfChart", (1))
                }
            }),
            scrollbar: {
                enabled: false
            },
        };
    } catch (error) {
        setnoChart(true)
    }

    const [popopen, setpopOpen] = useState();

    const hide = () => {
        setpopOpen(false);
    };
    const handleOpenChange = (newOpen) => {
        setpopOpen(newOpen);
    };

    const ContentBeforeChartNormal = () => {
        try {
            if (chartType) {
                var mintData = JSON.parse(finalChartData?.data)
            }
            return <div style={{ gap: '1rem', flexWrap: 'wrap', alignItems: "center" }}>

                <div style={{ flex: 1, display: "flex", flexDirection: "var(--flexDirection)", gap: '1rem' }} className='align-items-start align-items-md-center'>
                    <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem" }}>
                            <span>Value of <span className='textSM w-500' >{CurrencyFormat(chartAmount, 'long', 0)}</span> if invested on <span className='textSM w-500' >{formatDateMMMDDYYYY(mintData[0][xAxisKey])}</span></span>
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: "0.5rem" }}>
                            {
                                a.map((item, index) => {
                                    var val = Number(mintData[mintData?.length - 1][item] * (1))?.toFixed(2)
                                    var valXIRR = Number(mintData[mintData?.length - 1]["ret_" + item])?.toFixed(2)
                                    return val && val !== 'NaN' ?
                                        <div key={index} style={{
                                            display: 'flex', gap: '0.375rem', flexDirection: "column",
                                        }}>
                                            {(a.length > 2 ? item === 'Benchmark' : index !== 0) ? <Popover
                                                arrow={false}
                                                content={
                                                    <div style={{ minHeight: "6rem", display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                                                        <span className='textMD w-500 mb-2'> Selected benchmark: <span className='primary'>{benchName}</span></span>
                                                        <span style={{ width: "15rem" }}>
                                                            <InstrumentListSearch selectedValues={[benchData]} placeholder={`Search and change benchmark`} type={5} onChange={(e) => {
                                                                onBenchChange(e?.[0])
                                                            }}
                                                                align='left' />
                                                        </span>
                                                    </div>
                                                }
                                                // title="Title"
                                                trigger="click"
                                                open={popopen}
                                                overlayStyle={{
                                                    position: "relative",
                                                    width: "max(30% , 20rem)",
                                                    height: "50%",
                                                    padding: 0
                                                }}
                                                style={{ position: "relative", width: "60vw" }}
                                                placement='rightTop'
                                                onOpenChange={(e) => handleOpenChange(e)}
                                            >
                                                <span>
                                                    <span className='textXS w-700' style={{ color: 'var(--black)', display: "flex", alignItems: "center", gap: "0.5rem", textDecoration: (a.length > 2 ? item === 'Benchmark' : index !== 0) ? "underline" : 'unset', textDecorationStyle: 'dotted', cursor: "pointer" }}>
                                                        <BsFillSquareFill color={fundColors[index]} size={"0.5rem"} />
                                                        {a.length > 2 ? index === 0 ? (fundName || item) : item === "Benchmark" ? (benchName || item) : item : index === 0 ? (fundName || item) : (benchName || item)}
                                                        {(a.length > 2 ? item === 'Benchmark' : index !== 0) && <MdEdit style={{ cursor: "pointer" }} />}
                                                    </span>
                                                    <span className='textXS' style={{ flex: 1, padding: "0rem 1rem" }}>
                                                        {CurrencyFormat(val, 'long')}<span className='textXS'>{RedGreenText({ text: ` (${valXIRR}%)`, value: valXIRR })}</span>
                                                    </span>
                                                </span>
                                            </Popover> : <span>
                                                <span className='textXS w-700' style={{ color: 'var(--black)', display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                                    <BsFillSquareFill color={fundColors[index]} size={"0.5rem"} />
                                                    {a.length > 2 ? index === 0 ? (fundName || item) : item === "Benchmark" ? (benchName || item) : item : index === 0 ? (fundName || item) : (benchName || item)}
                                                </span>
                                                <span className='textXS' style={{ flex: 1, padding: "0rem 1rem" }}>
                                                    {CurrencyFormat(val, 'long')}<span className='textXS'>{RedGreenText({ text: ` (${valXIRR}%)`, value: valXIRR })}</span>
                                                </span>
                                            </span>}
                                        </div > : <></>
                                })
                            }
                        </div>
                    </div>
                    {!isWebSocket && <div className='w100-sm'>
                        <Divider style={{ margin: 0, marginBottom: "0.5rem" }} />
                        <CustomSegmentedTab
                            isBlock={isMobile ? true : false}
                            keepSameInMobile={true}
                            className='performanceChartYrSegmented'
                            style={{ backgroundColor: "transparent", padding: "0rem 0.5rem" }}
                            options={allowedTabs}
                            value={chartType}
                            onChange={(e) => setChartType(e)}
                        />
                        <Divider style={{ margin: 0, marginTop: "0.5rem" }} />
                    </div>}
                </div>

            </div>
        } catch (error) {
            return <></>
        }
    };



    try {
        return (
            noChart ? <div className='textXS' style={{ margin: "4rem", textAlign: "center" }}>
                No chart data available!
            </div>
                : <span style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', }}>
                    <ContentBeforeChartNormal />
                    <HighchartsReact containerProps={{ style: { height: "20.8rem" } }} highcharts={Highcharts} options={options} />
                    {/* {
                        portfolioType === 1 || portfolioType === 2 ?
                            <>
                                <div className='textXS' style={{ paddingTop: "1rem", paddingBottom: "1rem", }}>
                                    <b>Notes and Disclaimer:</b>
                                    <ol style={{ marginBottom: 0, paddingLeft: '16px' }}>
                                        <li>Past performance is not an assurance of future performance.</li>
                                        <li>Back-tested calculations do take into account an estimate of potential transaction cost incurred. Calculations do not take into account taxation.</li>
                                        <li>Actual index data has been used to simulate back-tested performance. To that extent, return numbers are not adjusted for the cost of ETF and tracking error</li>
                                        <li>Live performance is based on actual portfolio performance since it went live.</li>
                                    </ol>
                                </div>
                            </>
                            :
                            <>
                                <div className='textXS' style={{ paddingTop: "1rem", paddingBottom: "1rem", whiteSpace: 'pre-wrap' }}>
                                    <b>Note: </b>Past performance is not an assurance of future performance. The chart has been shown based on EOD NAVs of the fund/ETF.
                                </div>
                            </>
                    } */}

                </span>
        );
    } catch (error) {
        console.log(error)
        return <div className='textXS' style={{ margin: "4%", textAlign: "center" }}>
            No chart data available!
        </div>
    }
};
export default React.memo(IndexPerformanceChart);