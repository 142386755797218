import { UserOutlined } from '@ant-design/icons';
import AMCIcon from "Components/AMCIcon";
import AccessComponent from 'Components/AccessComponent';
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox.js";
import Analysis from 'Components/Analysis';
import Calculators from 'Components/Calculators';
import { CreateAlert } from 'Components/CreateAlert';
import { CurrencyFormat } from "Components/CurrencyFormat.js";
import { StickyScrollTabs } from "Components/CustomComponents/StickyScrollTabs";
import CustomDrawer from 'Components/CustomDrawer';
import CustomLabelValueCards from "Components/CustomLabelValue";
import CustomRangeSliders, { CustomRangeSliders2 } from "Components/CustomRangeSliders";
import CustomSegmentedTab from "Components/CustomSegmentedTab";
import CustomTabs from "Components/CustomTabs";
import CustomizeMetrics from 'Components/CustomizeMetrics';
import DashboardChartComponent from "Components/DashboardChartComponent";
import { formatDate } from "Components/DateFormatter";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import FundDetCards from 'Components/FundDetCards';
import InstrumentListTable from "Components/InstrumentListTable";
import { NoDataFound } from 'Components/NoDataFound';
import RedGreenText from "Components/RedGreenText";
import SharpelyGateway2 from 'Components/SharpelyGateway/SharpelyGateway2';
import SimilarFunds from "Components/SimilarFunds";
import Slider52Week from "Components/Slider52Week";
import TableProgressBarFormat from 'Components/TableProgessBarFormat';
import MyTable from 'Components/TableWithScroll';
import UpgradeToPro from 'Components/UpgradeToPro';
import { WatchList } from "Components/Watchlist";
import { CardHeader, generateUniqueKey } from 'Components/atoms/SmallComponents';
import { getKnowledgeBaseBlog } from 'Components/getKnowledgeBaseData.js';
import Head from "Layout/Head";
import { toggleUserGuideModal } from 'Libs/redux/KnowledgeBase/reduxSlice';
import { toggleHelpAndSupp, updateCustomColumns } from "Libs/redux/UserAuth/reduxSlice";
import { displayValue, getSnapshotInitialMetrics, screenerMetricData, useGetAssetType } from "Libs/utils";
import apiHelper from "Services/api/ApiHelper";
import apiHelperPY from 'Services/api/ApiHelperPY';
import { Dashboard_Portfolio } from 'Services/api/FabricatedAPIS';
import { StockLivePrice } from 'Services/api/LivePricesWebSocket';
import { Avatar, Card, Divider, Segmented, Table } from "antd";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import React, { useState } from "react";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { BsFillBarChartLineFill, BsTable } from "react-icons/bs";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp, HiOutlineMinusSm } from "react-icons/hi";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router";
import { useParams } from "react-router-dom";

const FundDetail = (props) => {

    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
    const params = useParams();
    const PLAN_ID = Number(params.code)
    const type = useGetAssetType() // 1 = etf, 2 = mf
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const user = useSelector((state) => state?.updateUserData?.value);
    const userCustomCols = useSelector((state) => state?.userCustomCols?.userCustomCols);
    const [fundData, setFundData] = useState({}); // coming from screener data
    const [fundDetail, setFundDetail] = useState({});
    const [fundHolding, setFundHolding] = useState({});
    const [fundRet, setFundRet] = useState({});
    const [apiError, setApiError] = useState(false)
    const [screenerMetric, setscreenerMetric] = useState([])
    const [screenerFinalData, setscreenerFinalData] = useState([])
    const [screenerMetricDescData, setScreenerMetricDescData] = useState([]);
    const [stockSectorCLF, setStockSectorCLF] = useState([])
    const [styleBoxes, setStyleBoxes] = React.useState([]);
    const [allFilteredFundsData, setallFilteredFundsData] = useState([])
    const [allFundsData, setallFundsData] = useState([]);
    const [categoryOverview, setCategoryOverview] = useState({});
    const [mfCategories, setmfCategories] = useState([])
    const [mfAMC, setmfAMC] = useState([])
    const [stock_macro_sectors, setMacroSectors] = useState([])
    const [stock_sectors, setSectors] = useState([])
    const [stock_industries, setIndustries] = useState([])
    const [stock_basic_industries, setBasicIndustries] = useState([]);
    const [insights, setInsights] = React.useState({})
    const [industryPeersModal, setIndustryPeersModal] = useState(false);
    const [peersData, setPeersData] = useState([]);
    const [portfolioObj, setPortfolioObj] = React.useState([])
    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    const [loading, setLoading] = useState(true);
    const [loadingFundDetail, setLoadingFundDetail] = useState(true);
    const [loadingFundHolding, setLoadingFundHolding] = useState(true);
    const [loadingFundRet, setLoadingFundRet] = useState(true);
    const [loadingInsights, setLoadingInsights] = useState(true);
    const [snapshotCards, setSnapshotCards] = React.useState([]);
    const [initialCols, setInitialCols] = React.useState([]);
    // const [userCustomColumns, setUserCustomColumns] = React.useState({});
    const isMobile = window.innerWidth <= 760;

    const [selectedTabItem, setSelectedTabItem] = React.useState('Overview');

    const resetState = async () => {
        setSelectedTabItem('Overview')
        setLoading(true);
        setLoadingInsights(true);
        setLoadingFundDetail(true);
        setLoadingFundHolding(true);
        setLoadingFundRet(true);
        setPortfolioObj([]);
    }

    const dataTableColumns = type.value === 4 ? [
        {
            "title": "Name",
            "dataIndex": "proper_name",
            render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }} onClick={() => navigate({ pathname: type.basePath + `${type.value === 4 ? '/stock-detail/' + record?.proper_name?.replaceAll(' ', '-') + '/' + record.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + record.plan_id}` })}>
                <AMCIcon amcName={record.symbol} fundType={type.value} height='1.2rem' width="1.2rem" />
                <div>
                    <div className="textSM black">{record?.proper_name}</div>
                    <div className="text-left textXS ">{stockSectorCLF?.find(s => s.industry_code === record.industry_code)?.industry_desc}</div>
                </div>
            </div>,
            width: 230,
            fixed: 'left',
            show: true
        }
    ] : [
        {
            "title": "Name",
            "dataIndex": "basic_name",
            render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }} onClick={() => navigate({ pathname: type.basePath + `${type.value === 4 ? '/stock-detail/' + record?.proper_name?.replaceAll(' ', '-') + '/' + record.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + record.plan_id}` })}>
                <AMCIcon amcName={record.amc_full_name} height='1.2rem' width="1.2rem" />
                <div>
                    <div className="textSM black">{record?.basic_name?.replace(" - Direct Plan", "")}</div>
                    <div className="text-left textXS ">{record?.category_name}</div>
                </div>
            </div>,
            width: 230,
            fixed: 'left',
            show: true
        }
    ]

    const createFundDetCardsData = (fundData, screenerMetricdata, cols) => {
        let metricColCodes = getSnapshotInitialMetrics(fundData, type);
        setInitialCols(metricColCodes);
        if (cols?.Snapshot?.length > 0) {
            let oth = cols?.Snapshot?.split('|')?.filter((el) => el) || []
            metricColCodes = [...metricColCodes, ...oth];
            metricColCodes = [...new Set(metricColCodes)]
        }
        console.log(metricColCodes);
        let temp = metricColCodes?.map((el) => {
            let ob = screenerMetricdata?.find((e) => e?.metric_col_code === el);
            let obj = {
                ...ob,
                metric_col_code: el,
                valueToRender: ob?.needToRender ? (ob?.dataToRenderFrom?.filter((e) => e?.[ob?.renderKey] === fundData?.[el])?.[0]?.[ob?.renderValue]) || "-" : displayValue({ text: fundData[el], metricObj: ob })
            }
            return obj;
        })
        console.log(temp);
        return temp;
    }

    React.useEffect(() => {
        resetState();
        const dem = async () => {
            var screener_datas = await screenerMetricData(type)
            console.log(screener_datas);
            setStockSectorCLF(screener_datas?.fabricatedData?.stockSectorCLF)
            setStyleBoxes(screener_datas?.fabricatedData?.stockStyles)
            setMacroSectors(screener_datas?.fabricatedData?.macroSectors)
            setSectors(screener_datas?.fabricatedData?.sectors)
            setIndustries(screener_datas?.fabricatedData?.industries)
            setBasicIndustries(screener_datas?.fabricatedData?.basicIndustries)
            setmfCategories(screener_datas?.fabricatedData?.mf_categories)
            setmfAMC(screener_datas?.fabricatedData?.mf_amcs)
            setscreenerMetric(screener_datas?.screenerMetricData)
            setscreenerFinalData(screener_datas?.screenerFinalData)
            setScreenerMetricDescData(screener_datas?.screenerMetricDescData);
            setallFundsData(screener_datas?.allFunds)
            setallFilteredFundsData(screener_datas?.allFunds)

            let a = screener_datas?.allFunds
            const result = a.filter(x => x.plan_id === PLAN_ID);
            setFundData(result[0])
            console.log('fundData', type.value, PLAN_ID, result[0])
            let customCols = userCustomCols
            if (user?.isLogged && userCustomCols === undefined) {
                customCols = await apiHelper({ apiName: "getCustomColumns", data: user?.id, saveResponse: "sessionStorage" });
                dispatch(updateCustomColumns(customCols))
                // console.log(customCols);
            }
            let cols = null;
            if (customCols) {
                cols = customCols?.find((el) => el?.InstrumentType === type?.strVal?.toUpperCase());
                // setUserCustomColumns(cols);
            }
            let cards = createFundDetCardsData(result[0], screener_datas?.screenerMetricData, cols);
            setSnapshotCards(cards);
            setTimeout(() => {
                setLoading(false)
            }, 100);
            let category = await getKnowledgeBaseBlog({
                category: "mf-masterclass", subCategory: "mutual-fund-categorization",
                blog: result?.[0]?.category_name.split(" ").join("-").toLowerCase() + ""
            })
            setCategoryOverview(category)
            let res = await apiHelperPY({ apiName: 'getFundsDetails', data: { id: PLAN_ID }, saveResponse: '' })
            if (res) {
                res = await JSON.parse(res.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))
                console.log('getFundsDetails', res)
                setFundDetail(res)
                setLoadingFundDetail(false)
            }
            else {
                setApiError(true)
                setFundDetail({})
                setLoadingFundDetail(false)
            }

            let res4 = await apiHelperPY({ apiName: 'getMFInsightsV1', data: { planId: PLAN_ID, assetType: result[0]?.primary_category_name?.toLowerCase() }, saveResponse: '' })
            if (res4) {
                setInsights(res4)
            }
            console.log(JSON.parse(res4.overview), JSON.parse(res4.mf_paid))
            setLoadingInsights(false);

            let res1 = await apiHelperPY({ apiName: 'getFundsHolding', data: { id: PLAN_ID }, saveResponse: '' })
            if (res1) {
                res1 = await JSON.parse(res1.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))
                console.log('getFundsHolding', res1)
                setFundHolding(res1)
                setLoadingFundHolding(false)
            }
            else {
                setApiError(true)
                setFundHolding({})
                setLoadingFundHolding(false)
            }
            let res2 = await apiHelperPY({ apiName: 'getFundsRet', data: { id: PLAN_ID }, saveResponse: '' })
            if (res2) {
                res2 = await JSON.parse(res2.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))
                console.log('getFundsRet', res2)
                setFundRet(res2)
                setLoadingFundRet(false)
            }
            else {
                setApiError(true)
                setFundRet({})
                setLoadingFundRet(false)
            }
            if (type.value === 1) {
                let portfolioHoldings = await Dashboard_Portfolio.getPortfolioHoldingsData(user, false).then((res) => { return res; })
                if (portfolioHoldings) {
                    let temp = {};
                    let arr = []
                    let list = portfolioHoldings?.CustomAll?.filter((el) => el?.FundType === type.value && el?.FundCode === result[0]?.nse_symbol) || [];
                    list.forEach((ele) => {
                        let id = ele?.PortfolioId;
                        if (temp?.[id]) {
                            temp[id] += ele?.Units
                        }
                        else {
                            temp[id] = ele?.Units
                        }
                    })
                    for (let k in temp) {
                        let j = { PortfolioId: Number(k), Quantity: temp?.[k] }
                        arr.push(j)
                    }
                    setPortfolioObj(arr);
                } else {
                    setPortfolioObj([]);
                }
            }
        }
        dem();
    }, [PLAN_ID])

    const performance_summary = Object.keys(fundRet || {}).length > 1 ? [
        {
            key: '1',
            heading: 'CAGR (since inception)',
            value: DecimalValueFormat({
                num: fundData?.cagr_inception,
                suffix: "%"
            }),
            show: fundData?.cagr_inception || false
        },
        {
            key: '2',
            heading: 'Expense ratio',
            value: DecimalValueFormat({
                num: fundData?.expense_ratio,
                suffix: "%"
            }),
            tooltip: 'content content',
            show: fundData?.expense_ratio || false
        },
        {
            key: '3',
            heading: 'Max drawdown',
            value: DecimalValueFormat({
                num: fundRet?.summary?.max_drawdown,
                suffix: "%"
            }),
            tooltip: 'content content',
            show: fundRet?.summary?.max_drawdown || false
        },
        {
            key: '4',
            heading: 'Alpha',
            value: DecimalValueFormat({
                num: fundRet?.summary.ratios.alpha,
                suffix: '%'
            }),
            tooltip: 'content content',
            show: fundRet?.summary?.ratios?.alpha || false
        },
        {
            key: '5',
            heading: 'Beta',
            value: DecimalValueFormat({
                num: fundRet?.summary.ratios.beta,
            }),
            tooltip: 'content content',
            show: fundRet?.summary?.ratios?.beta || false
        },
        {
            key: '6',
            heading: 'Sharpe ratio',
            value: DecimalValueFormat({
                num: fundRet?.summary.ratios.sharpe,
            }),
            tooltip: 'content content',
            show: fundRet?.summary?.ratios?.sharpe || false
        },
        {
            key: '7',
            heading: 'Annualized Volatility',
            value: DecimalValueFormat({
                num: fundRet?.summary?.annualized_vol,
                suffix: "%"
            }),
            tooltip: 'content content',
            show: fundRet?.summary?.annualized_vol || false
        },
    ] : []

    const updateUserCustomCols = async (snapshot = []) => {
        let userCustomColumns = userCustomCols?.find((el) => el?.InstrumentType === type?.strVal?.toUpperCase());
        let d = {
            snapshot, columns: userCustomColumns?.Columns?.split('|')?.filter(el => el) || [], instrument_type: type.strVal?.toUpperCase(), client_id: user?.id, uid: userCustomColumns?.UID || 0
        }
        let res = await apiHelper({ apiName: "saveCustomColumns", data: JSON.stringify(d) });
        console.log(res);
        if (res === 'success') {
            let customCols = await apiHelper({ apiName: "getCustomColumns", data: user?.id, saveResponse: "sessionStorage", getFresh: true });
            // let cols = null;
            if (customCols) {
                // cols = customCols?.find((el) => el?.InstrumentType === type?.strVal?.toUpperCase());
                // setUserCustomColumns(cols);
                dispatch(updateCustomColumns(customCols))
            }
        }
    }

    const ChartAndSnapShot = () => {
        return (
            <div className={isMobile ? "mainContainer" : "mainContainer leftRightPadContainer"} style={{
                paddingTop: isMobile ? "0" : "1.25rem",
                paddingBottom: isMobile ? "0" : "1.25rem", gap: isMobile ? "0" : "1.25rem"
            }}>
                <div className="leftContainerV2">
                    <DashboardChartComponent type={type} selectedFundData={fundData} />
                </div>
                {isMobile && <Divider style={{ background: "var(--grey3)", margin: "1rem 0" }} />}
                <div className="rightContainerV2" style={{ padding: isMobile ? "1rem" : "" }}>
                    <Card bordered={false} style={{ height: "100%", minHeight: "30rem", border: "1px solid var(--grey2)" }} bodyStyle={{ height: "100%", padding: 0 }} className=''>
                        <div className="d-flex flex-column h100" style={{ maxHeight: window.innerWidth > 760 ? "31rem" : "max-content", minHeight: "100%", }}>
                            <Slider52Week fundData={fundData} high={fundData?.['52w_high']} low={fundData?.['52w_low']} symbol={fundData.nse_symbol} type={type.value}
                                mfValue={fundData.latest_nav} format='railTrack' style={{ padding: "1rem 1rem" }} />
                            <Divider style={{ margin: 0, background: "var(--grey13)" }} />
                            <div style={{ flex: 1, padding: "0rem", overflowY: "auto" }}>
                                <FundDetCards data={snapshotCards} type="grid" height="19.75rem" headingType={3} itemInGroups={2} />
                            </div>
                            <Divider style={{ margin: 0, background: "var(--grey13)" }} />
                            <div style={{ padding: "1.25rem", gap: "1rem" }} className='d-flex'>
                                <CustomizeMetrics screenerFinalData={screenerFinalData} type={type} screenerMetric={screenerMetric} finalDataCols={snapshotCards}
                                    setFinalDataCols={(e) => { setSnapshotCards(e) }} fundData={fundData} updateUserCustomCols={updateUserCustomCols} disabledCols={initialCols} />
                                {user?.id <= 7 && <div className='w100 flex-1'>
                                    <SharpelyGateway2 IS_BASKET={false} INSTRUMENT_TYPE={type.value} FUNDDATA={fundData} TRANSACTION_TYPE={'BUY'} PORTFOLIO_OBJ={portfolioObj} />
                                </div>}
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        );
    }

    const ObjectiveAndOverview = () => {

        return (
            <>
                <CardHeader headingType={3} heading='Scheme overview' hideHeaderBlueLine />
                <div className='textSM black mt-3 mb-2'>
                    {fundDetail?.overview?.objective_text?.split(`\{\"\\n\"\}`).map((ele, i) => (
                        <p key={generateUniqueKey(i)}>
                            {ele}
                        </p>
                    ))}
                </div>
                <CardHeader headingType={3} heading={`Category - ${fundData?.category_name}`} hideHeaderBlueLine />
                <div className="ql-editor textSM blogContentDesc mt-3" style={{ padding: '0px', height: "auto" }}>
                    <div dangerouslySetInnerHTML={{ __html: categoryOverview?.Content || "" }}>
                    </div>
                </div>
            </>
        )
    }

    const TrackingDifference = () => {
        const dataSource = [
            {
                'key': 'Fund',
                'name': 'Fund',
                '1y': fundRet?.tracking_diff?.scheme?.["td_1y"],
                '3y': fundRet?.tracking_diff?.scheme?.["td_3y"],
                '5y': fundRet?.tracking_diff?.scheme?.["td_5y"],
                '10y': fundRet?.tracking_diff?.scheme?.["td_10y"],
            },
            {
                'key': 'Avg. of fund with same benchmark',
                'name': 'Avg. of fund with same benchmark',
                '1y': fundRet?.tracking_diff?.same_bm_avg?.["td_1y"],
                '3y': fundRet?.tracking_diff?.same_bm_avg?.["td_3y"],
                '5y': fundRet?.tracking_diff?.same_bm_avg?.["td_5y"],
                '10y': fundRet?.tracking_diff?.same_bm_avg?.["td_10y"],
            }
        ];
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: 150,
                fixed: 'left'
            },
            {
                title: '1 Y',
                dataIndex: '1y',
                key: '1y',
                align: "right",
                render: (text) => DecimalValueFormat({ num: text, suffix: "%", placeholder: "-" })
            },
            {
                title: '3 M',
                dataIndex: '3y',
                key: '3y',
                align: "right",
                render: (text) => DecimalValueFormat({ num: text, suffix: "%", placeholder: "-" })
            },
            {
                title: '5 Y',
                dataIndex: '5y',
                key: '5y',
                align: "right",
                render: (text) => DecimalValueFormat({ num: text, suffix: "%", placeholder: "-" })
            },
            {
                title: '10 Y',
                dataIndex: '10y',
                key: '10y',
                align: "right",
                render: (text) => DecimalValueFormat({ num: text, suffix: "%", placeholder: "-" })
            },
        ]
        return <Table dataSource={dataSource} columns={columns} size={'small'} pagination={false} scroll={{ x: 'max-content' }} />
    }

    const NoData = () => {
        return (
            <Card>
                <NoDataFound message={<span>
                    No data found. If you believe this is wrong, please report the issue <span onClick={() => dispatch(toggleHelpAndSupp(true))} style={{
                        cursor: "pointer", color: "var(--primary)"
                    }}>here</span>.
                </span>} />
            </Card>
        )
    }
    const TrailingReturns = () => {
        const TrailingReturnsTable = () => {
            const showRank = ({ period }) => {
                const rank = fundRet?.trailing_returns?.scheme_rank?.[`rank_${period}`]
                const count = fundRet?.trailing_returns?.category_count?.[`count_${period}`]
                if (rank && count) {
                    return <span style={{ whiteSpace: 'nowrap' }}>{rank} / {count}</span>
                } else {
                    return '-'
                }
            }
            const dataSource = [
                {
                    'key': 'Fund',
                    'name': 'Fund',
                    'ytd': DecimalValueFormat({ num: fundRet?.trailing_returns?.scheme?.["ret_ytd"], suffix: "%", placeholder: "-" }),
                    '1m': DecimalValueFormat({ num: fundRet?.trailing_returns?.scheme?.["ret_1month"], suffix: "%", placeholder: "-" }),
                    '6m': DecimalValueFormat({ num: fundRet?.trailing_returns?.scheme?.["ret_6month"], suffix: "%", placeholder: "-" }),
                    '1y': DecimalValueFormat({ num: fundRet?.trailing_returns?.scheme?.["ret_1year"], suffix: "%", placeholder: "-" }),
                    '3y': DecimalValueFormat({ num: fundRet?.trailing_returns?.scheme?.["ret_3year"], suffix: "%", placeholder: "-" }),
                    '5y': DecimalValueFormat({ num: fundRet?.trailing_returns?.scheme?.["ret_5year"], suffix: "%", placeholder: "-" }),
                    '10y': DecimalValueFormat({ num: fundRet?.trailing_returns?.scheme?.["ret_10year"], suffix: "%", placeholder: "-" }),
                    'sinceInception': DecimalValueFormat({ num: fundRet?.trailing_returns?.scheme?.["ret_since_launch"], suffix: "%", placeholder: "-" }),
                },
                {
                    'key': fundData?.category_name,
                    'name': fundData?.category_name,
                    'ytd': DecimalValueFormat({ num: fundRet?.trailing_returns?.category?.["ret_ytd"], suffix: "%", placeholder: "-" }),
                    '1m': DecimalValueFormat({ num: fundRet?.trailing_returns?.category?.["ret_1month"], suffix: "%", placeholder: "-" }),
                    '6m': DecimalValueFormat({ num: fundRet?.trailing_returns?.category?.["ret_6month"], suffix: "%", placeholder: "-" }),
                    '1y': DecimalValueFormat({ num: fundRet?.trailing_returns?.category?.["ret_1year"], suffix: "%", placeholder: "-" }),
                    '3y': DecimalValueFormat({ num: fundRet?.trailing_returns?.category?.["ret_3year"], suffix: "%", placeholder: "-" }),
                    '5y': DecimalValueFormat({ num: fundRet?.trailing_returns?.category?.["ret_5year"], suffix: "%", placeholder: "-" }),
                    '10y': DecimalValueFormat({ num: fundRet?.trailing_returns?.category?.["ret_10year"], suffix: "%", placeholder: "-" }),
                    'sinceInception': '-',
                }, {
                    'key': 'rank',
                    'name': 'Category rank',
                    'ytd': showRank({ period: 'ytd' }),
                    '1m': showRank({ period: '1month' }),
                    '6m': showRank({ period: '6month' }),
                    '1y': showRank({ period: '1year' }),
                    '3y': showRank({ period: '3year' }),
                    '5y': showRank({ period: '5year' }),
                    '10y': showRank({ period: '10year' }),
                    'sinceInception': '-',
                }
            ];
            const columns = [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    width: 150,
                    fixed: 'left'
                },
                {
                    title: 'YTD',
                    dataIndex: 'ytd',
                    key: 'ytd',
                    align: "right",
                },
                {
                    title: '1 M',
                    dataIndex: '1m',
                    key: '1m',
                    align: "right",
                },
                {
                    title: '6 M',
                    dataIndex: '6m',
                    key: '6m',
                    align: "right",
                },
                {
                    title: '1 Y',
                    dataIndex: '1y',
                    key: '1y',
                    align: "right",
                },
                {
                    title: '3 Y',
                    dataIndex: '3y',
                    key: '3y',
                    align: "right",
                },
                {
                    title: '5 Y',
                    dataIndex: '5y',
                    key: '5y',
                    align: "right",
                },
                {
                    title: '10 Y',
                    dataIndex: '10y',
                    key: '10y',
                    align: "right",
                },
                {
                    title: "Since Inception",
                    key: "since inception",
                    align: "right",
                    dataIndex: "sinceInception",
                    width: "8rem"
                }
            ]
            return <Table dataSource={dataSource} columns={columns} size={'small'} pagination={false} scroll={{ x: 'max-content' }}
                rowClassName={(record, index) => record.key === 'rank' ? 'rowHighlightLevel1' : ''} />
        }

        const TrailingReturnsChart = () => {
            var data = fundRet?.trailing_returns
            const xAxisKeys = [
                { key: 'ret_ytd', value: 'YTD' },
                { key: 'ret_1month', value: '1 M' },
                { key: 'ret_6month', value: '6 M' },
                { key: 'ret_1year', value: '1 Y' },
                { key: 'ret_3year', value: '3 Y' },
                { key: 'ret_5year', value: '5 Y' },
                { key: 'ret_10year', value: '10 Y' },
                { key: 'ret_since_launch', value: 'Since Inception' },
            ];

            try {
                var conf = {
                    chart: {
                        backgroundColor: "transparent",
                        type: 'column',
                    },
                    title: {
                        text: ''
                    },
                    exporting: {
                        enabled: false
                    },
                    yAxis: {
                        gridLineDashStyle: 'longdash',
                        title: {
                            text: ''
                        },
                        gridLineColor: "var(--grey1)",
                        plotLines: [{
                            color: 'var(--grey1)',
                            width: 2,
                            value: 0,
                            zIndex: 5
                        }],
                    },
                    xAxis: {
                        categories: xAxisKeys.map(x => x.value).filter(a => a !== 'Since Inception'),
                        lineColor: "var(--grey1)",
                        labels: {
                            style: {
                                color: 'black'
                            }
                        }
                    }
                    ,
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        itemDistance: 70
                    },
                    plotOptions: {
                        series: {
                            lineWidth: 1.5,
                            label: {
                                connectorAllowed: false
                            },
                            marker: {
                                enabled: true
                            }
                        },
                        column: {
                            pointPadding: 0,
                        }
                    },
                    tooltip: {
                        valuedecimals: 1,
                    },
                    credits: {
                        enabled: false
                    },
                    boost: {
                        useGPUTranslations: true,
                    },
                    series: [
                        {
                            name: "Scheme", turboThreshold: 9e9,
                            color: '#007AFF77',
                            tooltip: {
                                valueSuffix: ' %',
                            },
                            data: xAxisKeys.map(x => data?.scheme?.[x.key]).slice(0, -1),
                            dataLabels: {
                                enabled: true,
                                style: { fontWeight: '400', textOutline: "none", color: "var(--dark4)" },
                                formatter: function () {
                                    return Highcharts.numberFormat(this.y, 1) + '%'
                                }
                            }
                        },
                        {
                            name: `Category (${fundData?.category_name})`, turboThreshold: 9e9,
                            color: '#83BF6E77',
                            tooltip: {
                                valueSuffix: ' %',
                            },
                            data: xAxisKeys.map(x => data?.category?.[x.key]),
                            dataLabels: {
                                style: { fontWeight: '400', textOutline: "none", color: "var(--dark4)" },
                                enabled: true,
                                formatter: function () {
                                    return Highcharts.numberFormat(this.y, 1) + '%';
                                }
                            }
                        }
                    ],
                    scrollbar: {
                        enabled: false
                    },
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 400
                            },
                        }]
                    },

                };
            } catch (error) {
                console.log("SDF", error)
            }

            return <div><HighchartsReact highcharts={Highcharts} options={conf} /></div>
        }
        const items = [
            {
                key: '1',
                value: '1',
                label: <BsFillBarChartLineFill size={'1rem'} />,
                children: <TrailingReturnsChart />
            },
            {
                key: '2',
                value: '2',
                label: <BsTable size={'1rem'} />,
                children: <TrailingReturnsTable />
            },
        ]

        const [selectedTab, setselectedTab] = React.useState('1')

        return <>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1rem" }}>
                <Segmented
                    value={selectedTab}
                    onChange={(e) => {
                        setselectedTab(e)
                    }}
                    options={items}

                />
            </div>
            <div style={{ marginTop: '12px' }}>
                {items.filter((i) => i.value === selectedTab)[0]?.children}
            </div>
        </>
    }

    const PeriodReturns = () => {

        const Chart = ({ type, option }) => {
            var data = type === '1' ? fundRet?.annual_returns : type === '2' ? fundRet?.quarterly_returns : type === '3' ? fundRet?.monthly_returns : []
            console.log(data);
            data = option === '1' ? data?.slice(-8) : data?.slice(-12)
            //console.log(data)
            try {
                var conf = {
                    chart: {
                        backgroundColor: "transparent",
                        // type: 'column',
                        // height: 300,
                    },
                    title: {
                        text: ''
                    },
                    exporting: {
                        enabled: false
                    },
                    yAxis: option === '1' ? { // Primary yAxis
                        gridLineColor: "var(--grey1)",
                        gridLineDashStyle: 'longdash',
                        labels: {
                            format: '{value}%',
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        },
                        // min: -50,
                        // max: 100,
                        // tickAmount: 7,
                        title: {
                            text: 'Returns',
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        }
                    } : {
                        // gridLineColor: "transparent",
                        gridLineColor: "var(--grey1)",
                        gridLineDashStyle: 'longdash',
                        title: {
                            text: '',
                        },
                        min: 0,
                        max: 100
                    },
                    xAxis: {
                        categories: data.map(d => new Date(d.index)),
                        lineColor: "var(--grey1)",
                        labels: {
                            style: {
                                color: 'black'
                            }
                        },
                        labels: {
                            formatter: function () {
                                return type === '1' ? moment(new Date(this.value)).format('YYYY') :
                                    type === '2' ? moment(new Date(this.value)).format('MMM YYYY') :
                                        type === '3' ? moment(new Date(this.value)).format('MMM YY') : ''
                            }
                        }
                    }
                    ,
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        itemDistance: 70
                    },
                    plotOptions: {
                        series: {
                            lineWidth: 1.5,
                            label: {
                                connectorAllowed: false
                            },
                            marker: {
                                enabled: true
                            },
                            states: {
                                hover: {
                                    enabled: false
                                }
                            },
                            enableMouseTracking: false,
                        },
                        column: {
                            pointPadding: 0,
                            maxPointWidth: 40,
                            enableMouseTracking: false
                        }
                    },
                    tooltip: {
                        // shared: true,
                        valuedecimals: 1,
                        // valuePrefix: '₹',
                    },
                    credits: {
                        enabled: false
                    },
                    boost: {
                        useGPUTranslations: true,
                    },
                    series: option === '1' ? [
                        {

                            name: "Scheme",
                            type: 'column',
                            turboThreshold: 9e9,
                            color: '#007AFF77',
                            yAxis: 0,
                            tooltip: {
                                valueSuffix: ' %',
                            },
                            data: data.map(d => Number(d.scheme_return)),
                            dataLabels: {
                                enabled: true,
                                style: { fontWeight: '400', textOutline: "none", color: "var(--dark4)" },
                                formatter: function () {
                                    return Highcharts.numberFormat(this.y, 1) + '%';
                                }
                            }
                        },
                        {

                            name: "Category",
                            type: 'column',
                            turboThreshold: 9e9,
                            color: '#83BF6E77',
                            yAxis: 0,
                            tooltip: {
                                valueSuffix: ' %',
                            },
                            data: data.map(d => Number(d.category_returns)),
                            dataLabels: {
                                enabled: true,
                                style: { fontWeight: '400', textOutline: "none", color: "var(--dark4)" },
                                formatter: function () {
                                    return Highcharts.numberFormat(this.y, 1) + '%';
                                }
                            }
                        }
                    ] : [
                        {

                            name: "Percentile rank",
                            type: 'column',
                            turboThreshold: 9e9,
                            color: '#007AFF77',
                            data: data.map(d => Number(d.percentile) * 100),
                            dataLabels: {
                                enabled: true,
                                style: { fontWeight: '400', textOutline: "none", color: "var(--dark4)" },
                                formatter: function () {
                                    return Highcharts.numberFormat(this.y, 1);
                                }
                            }
                        },
                    ],
                    scrollbar: {
                        enabled: false
                    },
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 400
                            },
                        }]
                    },
                };
            } catch (error) {
                //console.log("SDF", error)
            }
            return <HighchartsReact highcharts={Highcharts} options={conf} />
        }


        const [selectedOption, setselectedOption] = React.useState('1')

        const options = [
            {
                key: '1',
                value: '1',
                label: 'Returns',
            },
            {
                key: '2',
                value: '2',
                label: 'Percentile rank',
            }
        ]
        const items = [
            {
                key: '1',
                value: '1',
                label: 'Annual',
            },
            {
                key: '2',
                value: '2',
                label: 'Quaterly',
            },
            {
                key: '3',
                value: '3',
                label: 'Monthly',
            },
        ]
        const [selectedTab, setselectedTab] = React.useState('1')

        return <>
            <div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "1.25rem" }}>
                        <Segmented
                            value={selectedOption}
                            onChange={(e) => {
                                setselectedOption(e)
                            }}
                            options={options}
                        />
                        <Segmented
                            value={selectedTab}
                            onChange={(e) => {
                                setselectedTab(e)
                            }}
                            options={items}
                            style={{ alignSelf: window.innerWidth > 760 ? 'end' : 'start' }}
                        />
                    </div>
                    <Chart type={selectedTab} option={selectedOption} />
                </div>
            </div>
        </>
    }

    const alphaBetaSharpe = [{
        title: 'Alpha',
        description: 'Alpha is the excess returns relative to market benchmark for a given amount of risk taken by the scheme',
        currentVal: fundRet?.category_ratios?.alpha_average,
        currentValStr: fundRet?.category_ratios?.alpha_average?.toFixed(2) + "%",
        min: Math.min(...fundRet?.category_ratios?.alpha_list || []),
        max: Math.max(...fundRet?.category_ratios?.alpha_list || []),
        minStr: Math.min(...fundRet?.category_ratios?.alpha_list || [])?.toFixed(2) + "%",
        maxStr: Math.max(...fundRet?.category_ratios?.alpha_list || [])?.toFixed(2) + "%",
        minStrLabel: 'Category lowest',
        maxStrLabel: 'Category highest',
        show: fundRet?.category_ratios?.alpha_list?.length > 1
    },
    {
        title: 'Beta',
        description: 'Beta measures the expected move in a stock relative to movements in the overall market',
        currentVal: fundRet?.category_ratios?.beta_average,
        currentValStr: fundRet?.category_ratios?.beta_average?.toFixed(2),
        min: Math.min(...fundRet?.category_ratios?.beta_list || []),
        max: Math.max(...fundRet?.category_ratios?.beta_list || []),
        minStr: Math.min(...fundRet?.category_ratios?.beta_list || [])?.toFixed(2),
        maxStr: Math.max(...fundRet?.category_ratios?.beta_list || [])?.toFixed(2),
        minStrLabel: "Category lowest",
        maxStrLabel: "Category highest",
        show: fundRet?.category_ratios?.beta_list?.length > 1
    },
    {
        title: 'Sharpe',
        description: 'The Sharpe ratio compares the return of an investment with its risk',
        currentVal: fundRet?.category_ratios?.sharpe_average,
        currentValStr: fundRet?.category_ratios?.sharpe_average?.toFixed(2),
        min: Math.min(...fundRet?.category_ratios?.sharpe_list || []),
        max: Math.max(...fundRet?.category_ratios?.sharpe_list || []),
        minStr: Math.min(...fundRet?.category_ratios?.sharpe_list || [])?.toFixed(2),
        maxStr: Math.max(...fundRet?.category_ratios?.sharpe_list || [])?.toFixed(2),
        minStrLabel: "Category lowest",
        maxStrLabel: "Category highest",
        show: fundRet?.category_ratios?.sharpe_list?.length > 1
    }
    ]

    const WorstDrawdowns = () => {
        const dataSource = fundRet?.worst_5_drawdowns
        const columns = [
            {
                title: 'Started on',
                dataIndex: 'Started',
                key: 'Started',
                width: 150,
                fixed: 'left'
            },
            {
                title: 'Drawdown',
                dataIndex: 'Drawdown',
                key: 'Drawdown',
                align: "right",
                render: (text) => DecimalValueFormat({ num: text, suffix: "%", placeholder: "-" })
            },
            {
                title: 'Lasted for (days)',
                dataIndex: 'Days',
                key: 'Days',
                align: "right",
            },
            {
                title: 'Recovered on',
                dataIndex: 'Recovered',
                key: 'Recovered',
                align: "right",
            }
        ]
        if (!dataSource || dataSource?.length <= 0) {
            return <NoData />
        }
        return <Table dataSource={dataSource} columns={columns} size={'small'} pagination={false} scroll={{ x: 'max-content' }} rowKey={(rec) => generateUniqueKey(rec?.Drawdown)} />
    }

    const basic_details = [{
        key: '1',
        heading: 'Fund house',
        tooltip: 'content content',
        value: fundData?.amc_full_name,
    }, {
        key: '2',
        heading: 'Inception',
        value: formatDate(fundData?.inception),
        tooltip: 'content content',
        show: fundData?.inception || false
    }, {
        key: '3',
        heading: 'Benchmark',
        tooltip: 'content content',
        value: fundData?.benchmark || "-",
    }, {
        key: '4',
        heading: `AUM (as on ${formatDate(new Date(new Date().setDate(0)).toISOString())})`,
        value: CurrencyFormat(fundData?.aum, 'long', 2, ' Cr.'),
        tooltip: 'content content',
    },
    {
        key: '5',
        heading: 'Expense ratio',
        value: DecimalValueFormat({
            num: fundData?.expense_ratio || "-",
            suffix: "%"
        }),
        tooltip: 'content content',
    }, {
        key: '6',
        heading: 'Max Drawdown',
        value: DecimalValueFormat({
            num: fundData?.max_drawdown,
            suffix: "%"
        }),
        tooltip: 'content content',
        show: fundData?.max_drawdown || false
    }, {
        key: '7',
        heading: 'Tracking Error',
        value: DecimalValueFormat({
            num: fundData?.tracking_error,
            suffix: "%"
        }),
        tooltip: 'content content',
        show: fundData?.is_index_fund === 1 || fundData?.is_etf_fund === 1
    }, {
        key: '8',
        heading: 'Avg. daily traded value',
        value: CurrencyFormat(fundDetail?.overview?.scheme_volume, 'long', 2, ' Cr.'),
        tooltip: 'content content',
        show: fundData?.is_etf_fund === 1
    }, {
        key: '9',
        heading: 'Lock in',
        value: fundDetail?.overview?.is_lock_in === 1 ? fundDetail?.overview?.lock_in_period : "No lock in",
        tooltip: 'content content',
    }, {
        key: '10',
        heading: 'Taxation',
        value: fundDetail?.overview?.taxation?.allocation || "-",
        tooltip: 'content content',
    }, {
        key: '11',
        heading: 'Exit load',
        value: fundDetail?.overview?.exit_load?.note || "-",
        tooltip: 'content content',
    }, {
        key: '12',
        heading: 'Min. Investment',
        value: CurrencyFormat(fundDetail?.overview?.min_initial_investment),
        tooltip: 'content content',
    }, {
        key: '13',
        heading: 'Min. Addl Investment',
        value: CurrencyFormat(fundDetail?.overview?.min_subsequent_investment),
        tooltip: 'content content',
    }, {
        key: '14',
        heading: 'Min. SIP Investment',
        value: CurrencyFormat(fundDetail?.overview?.min_subsequent_sip_investment),
        tooltip: 'content content',
    }, {
        key: '15',
        heading: 'Min. Withdrawal',
        value: CurrencyFormat(fundDetail?.overview?.min_withdrawl_amount),
        tooltip: 'content content',
    }, {
        key: '16',
        heading: 'Min. Balance',
        value: CurrencyFormat(fundDetail?.overview?.min_balance),
        tooltip: 'content content',
    }]

    const Managers = () => {
        const ManagerDetails = ({ d }) => {
            const [show, setShow] = React.useState(false)
            return <React.Fragment key={d.index}>
                <Card bordered={false} hoverable key={d.index} style={{ marginBottom: '1rem', border: '1px solid var(--grey3)' }} bodyStyle={{ padding: '0.875rem 1.25rem' }}
                    onClick={() => setShow(!show)}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }}>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            <Avatar size={'2rem'} style={{ backgroundColor: 'var(--blueShade)' }} icon={<UserOutlined style={{ color: 'var(--primary)' }} />} />
                            <div className="textSM w-500 black">{d.name}</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            <div className="textXS black">{formatDate(d.since.replace("T00:00:00.000Z", ""))} - Present</div>
                            <div className="textXL black mb-1">{show ? <BiChevronDown /> : <BiChevronRight />}</div>
                        </div>
                    </div>
                    {
                        show ?
                            <>
                                <div style={{ margin: '1.25rem 0', display: 'flex', flexDirection: 'column', gap: '1rem' }} className="textSM black">
                                    <div>
                                        <div className="w-700 dark3" style={{ marginBottom: '0.5rem' }}>Education</div>
                                        <div>{d.education || "-"}</div>
                                    </div>
                                    <div>
                                        <div className="w-700 dark3" style={{ marginBottom: '0.5rem' }}>Past Experience</div>
                                        <div>{d.experience || "-"}</div>
                                    </div>
                                    {JSON.parse(d.other_funds).length > 0 ?
                                        <>
                                            <div className="w-700">Funds managed ({JSON.parse(d.other_funds).length})</div>
                                            {JSON.parse(d.other_funds).map((fund, i) => {
                                                return <Card style={{ border: '1px solid var(--grey3)', }} bodyStyle={{ padding: '0.875rem 1.25rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }}
                                                    onClick={() => navigate((fund?.is_etf_fund ? "/etfs" : "/mutual-funds") + '/fund-detail/' +
                                                        fund?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + fund.plan_id)}
                                                    key={generateUniqueKey(fund?.basic_name)}>
                                                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                                        <div className="textSM w-500 black">{fund.basic_name}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                                        <div className="textXS black">{formatDate(fund.date_from.replace("T00:00:00.000Z", ""))} - Present</div>
                                                        <div className="textXL black mb-1"><BiChevronRight /></div>
                                                    </div>
                                                </Card>
                                            })}
                                        </>
                                        :
                                        <></>
                                    }
                                </div>
                            </>
                            :
                            <></>
                    }
                </Card>
            </React.Fragment >
        }

        return fundDetail?.overview?.fm_details.map((d, i) => {
            return <ManagerDetails d={d} key={generateUniqueKey(i)} />
        })
    }

    const holdingSnapNew = [
        {
            key: 'a',
            heading: 'Number of securities',
            value: fundHolding?.snapshot?.num_securities,
            tooltip: 'content content',
            show: fundData?.is_fof === 0 && (Number(fundHolding?.snapshot?.num_securities) || false)
        }, {
            key: 'b',
            heading: 'P/E Ratio',
            value: DecimalValueFormat({
                num: fundHolding?.snapshot?.valuation?.pescore,
                suffix: ""
            }),
            tooltip: 'content content',
            show: Number(fundHolding?.snapshot?.valuation?.pescore) || false
        }, {
            key: 'c',
            heading: 'P/B Ratio',
            value: DecimalValueFormat({
                num: fundHolding?.snapshot?.valuation?.pbscore,
                suffix: ""
            }),
            tooltip: 'content content',
            show: Number(fundHolding?.snapshot?.valuation?.pbscore) || false
        }, {
            key: 'd',
            heading: 'Top 5 holding percentage',
            value: DecimalValueFormat({
                num: fundHolding?.snapshot?.top_5_holding_per,
                suffix: "%"
            }),
            tooltip: 'content content',
            show: Number(fundHolding?.snapshot?.top_5_holding_per) || false
        }, {
            key: 'e',
            heading: 'Top 10 holding percentage',
            value: DecimalValueFormat({
                num: fundHolding?.snapshot?.top_10_holding_per,
                suffix: "%"
            }),
            tooltip: 'content content',
            show: Number(fundHolding?.snapshot?.top_10_holding_per) || false
        }, {
            key: 'f',
            heading: 'Modified Duration',
            value: DecimalValueFormat({
                num: fundHolding?.snapshot?.debt_data?.duration,
                suffix: " years"
            }),
            tooltip: 'content content',
            show: Number(fundHolding?.snapshot?.debt_data?.duration) || false
        }, {
            key: 'g',
            heading: 'Average Maturity',
            value: DecimalValueFormat({
                num: fundHolding?.snapshot?.debt_data?.average_maturity_period,
                suffix: " years"
            }),
            tooltip: 'content content',
            show: Number(fundHolding?.snapshot?.debt_data?.average_maturity_period) || false
        }, {
            key: 'h',
            heading: 'Yield to maturity',
            value: DecimalValueFormat({
                num: fundHolding?.snapshot?.debt_data?.annualised_ytm,
                suffix: " %"
            }),
            tooltip: 'content content',
            show: Number(fundHolding?.snapshot?.debt_data?.annualised_ytm) || false
        }
    ]

    const AssetAllocation2 = ({ data = {} }) => {
        //console.log(data)
        if (!Object.values(data)?.length) {
            return <Card><NoDataFound message='Not enough data!' /></Card>
        }
        const dataMain = Object.values(data || {})
        const dates = dataMain?.map(x => x.as_on_date)
        const dataSource = Object.keys(dataMain?.[0] || {})?.map((k) => {
            if (k !== 'as_on_date' && (dataMain?.[0]?.[k] || dataMain?.[1]?.[k] || dataMain?.[2]?.[k])) {
                return {
                    asset: k,
                    [dates?.[0]]: dataMain?.[0]?.[k],
                    [dates?.[1]]: dataMain?.[1]?.[k],
                    [dates?.[2]]: dataMain?.[2]?.[k],
                }
            }
        }).filter(f => f).sort((a, b) => b?.[dates?.[0]] - a?.[dates?.[0]])
        //console.log(dataSource, dates)
        const columns = [{
            title: 'Asset',
            dataIndex: 'asset',
            fixed: 'left',
            width: 150,
            render: (text, record) => text === 'equity' ? 'Equity' : text === 'debt' ? 'Debt' : text === 'others' ? 'Others (including cash)' : text === 'commodies' ? 'Commodities' : text.charAt(0).toUpperCase() + text.slice(1)
        }, ...dates.map((d, index) => {
            return {
                title: formatDate(d, "date"),
                dataIndex: d,
                width: 100,
                render: (text) => TableProgressBarFormat({ num: text })
            }
        })
        ]
        return <MyTable style={{ maxWidth: '55rem' }} scroll={{ x: 'max-content' }} dataSource={dataSource} columns={columns} className='textXS black' pagination={{ hideOnSinglePage: true, pageSize: 50 }} rowKey={(record) => generateUniqueKey(record?.asset)} />
    }

    const CreditRating = () => {
        const dataSource = Object.keys(fundHolding?.scheme_rating_profile || {})?.map(x => {
            return {
                key: x,
                ratings: x,
                fund: fundHolding?.scheme_rating_profile?.[x],
                category: fundHolding?.category_rating_profile?.[x]
            }
        })?.sort((a, b) => b.fund - a.fund)

        const columns = [
            {
                title: 'Ratings',
                dataIndex: 'ratings',
                key: 'ratings',
                width: 250,
                fixed: 'left',
                render: (text) => <span className="w-500">{text}</span>
            },
            {
                title: 'Fund',
                dataIndex: 'fund',
                render: (text) => TableProgressBarFormat({ num: text })
            },
            {
                title: 'Category',
                dataIndex: 'category',
                render: (text) => TableProgressBarFormat({ num: text })
            }
        ]
        return <MyTable style={{ maxWidth: '55rem' }} scroll={{ x: 'max-content' }} dataSource={dataSource} columns={columns} className='textXS black' pagination={{ hideOnSinglePage: true, pageSize: 50 }} />
    }

    const SectorAllocation = () => {

        const dataMain = typeof (fundHolding?.sector) === "string" ? JSON.parse(fundHolding?.sector) : fundHolding?.sector
        const dates = dataMain?.map(x => x.index)
        const dataSource = Object.keys(dataMain?.[0] || {})?.map((k) => {
            if (k !== 'index') {
                return {
                    sector: k,
                    [dates[0]]: dataMain?.[0]?.[k],
                    [dates[1]]: dataMain?.[1]?.[k],
                    [dates[2]]: dataMain?.[2]?.[k],
                }
            }
        }).filter(f => f)
        console.log(dataSource, dataMain);
        const columns = [{
            title: 'Sector',
            dataIndex: 'sector',
            key: 'sector',
            fixed: 'left',
            width: 200,
            render: (text, record) => text
        }, ...(dates || [])?.map((d, index) => {
            return {
                title: formatDate(d, "date"),
                dataIndex: d,
                render: (text) => TableProgressBarFormat({ num: text })
            }
        })
        ]

        return <MyTable style={{ maxWidth: '55rem' }} scroll={{ x: 'max-content' }} dataSource={dataSource} columns={columns} className='textXS black' pagination={{ hideOnSinglePage: true, pageSize: 50 }} rowKey={(rec) => generateUniqueKey(rec?.sector)} />

    }

    const TopHoldings = () => {
        const chipset = fundHolding?.holdings?.map((holding_by_date, index) => {
            return {
                key: index,
                value: index,
                label: formatDate(holding_by_date?.index, "date")
            }
        })
        const [holdingDate, setholdingDate] = React.useState(chipset?.[0].key)

        console.log(Object.keys(fundHolding?.holdings?.[holdingDate] || {}));
        const chipset2 = Object.keys(fundHolding?.holdings?.[holdingDate] || {})?.splice(1)?.map((d, index) => {
            return {
                key: index,
                value: d,
                label: d.replace("international", "intl'").charAt(0).toUpperCase() + d.replace("international", "intl'").replace("_", " ").slice(1),
                show: fundHolding?.holdings?.[holdingDate]?.[d]?.length ? true : false
            }
        })
        console.log(chipset2);
        const [holdingCat, setholdingCat] = React.useState(chipset2?.filter((d) => d?.show !== false)?.[0]?.value)

        const [dataSource, setDataSource] = React.useState()

        React.useEffect(() => {
            setDataSource(fundHolding?.holdings?.[holdingDate]?.[holdingCat])
        }, [holdingDate, holdingCat])
        const columns = [
            {
                title: '',
                dataIndex: 'instrument',
                key: 'instrument',
                fixed: 'left'
            },
            {
                title: 'Weight',
                dataIndex: 'weight',
                key: 'weight',
                align: "right",
                render: (text) => {
                    return DecimalValueFormat({ num: text, suffix: "%", placeholder: "-" })
                }
            },
            {
                title: 'Change',
                dataIndex: 'change',
                key: 'change',
                align: "right",
                width: '8rem',
                render: (text, record) => {
                    return <div>
                        {text === "N" ?
                            "-"
                            :
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                <RedGreenText text={DecimalValueFormat({ num: text, suffix: "%" })} value={record.change} />
                                {
                                    record.change > 0 ?
                                        <HiOutlineArrowSmUp style={{ color: "var(--green)", fontSize: '1rem' }} />
                                        : record.change < 0 ?
                                            <HiOutlineArrowSmDown style={{ color: "var(--red)", fontSize: '1rem' }} />
                                            : <HiOutlineMinusSm style={{ fontSize: '1rem' }} />
                                }
                            </div>
                        }
                    </div>
                }
            }
        ]
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: window.innerWidth > 760 ? 'row' : 'column', gap: '1rem' }} className="mb-2">
                    <div>
                        <CustomSegmentedTab isBlock={false} keepSameInMobile={true}
                            value={holdingCat}
                            onChange={(e) => {
                                setholdingCat(e)
                            }}
                            options={chipset2.filter(c => c.show)} />
                    </div>
                    <div>
                        <CustomSegmentedTab isBlock={false} keepSameInMobile={true}
                            value={holdingDate}
                            onChange={(e) => {
                                setholdingDate(e)
                            }}
                            options={chipset} />
                    </div>
                </div>
                <MyTable dataSource={dataSource} columns={columns} size={'small'} pagination={{
                    position: ['bottomCenter'],
                    showSizeChanger: false,
                    showPrevNextJumpers: true,
                    className: "textSM",
                    hideOnSinglePage: true,
                    itemRender: (current, type, originalElement) => {
                        if (type === 'prev') {
                            return <a {...originalElement.props} className="textSM mx-2">Previous</a>;
                        }
                        if (type === 'next') {
                            return <a {...originalElement.props} className="textSM mx-2">Next</a>;
                        }
                        return originalElement;
                    }
                }} rowKey={(rec) => generateUniqueKey(rec?.instrument)} />
            </div>
        );
    }

    const tabItems = [
        {
            label: "Overview",
            key: "Overview",
            value: "Overview",
            children: (
                <>
                    <StickyScrollTabs isAccordionInMob queriesToStickToBot={['#sharpelyHeader', '#customTabsBarId']} tabData={[
                        {
                            key: 'basic_Details',
                            label: 'Basic details',
                            children: <AccessComponent component={<CustomLabelValueCards x={basic_details} showDetailIcon={true} />}
                                moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} upgradeComponent={
                                    <UpgradeToPro moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} />
                                } />,
                            show: true,
                            heading: "Basic details",
                            subHeading: "A snapshot of important details of the scheme including lock in, exit load and subscription details.",
                            value: "BasicDetails"
                        },
                        {
                            key: 'managers',
                            label: 'Managers',
                            children: <AccessComponent component={<Managers />}
                                moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} upgradeComponent={
                                    <UpgradeToPro moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} />
                                } />,
                            show: true,
                            heading: "Managers",
                            value: "Managers",
                            subHeading: "Check out the details of the fund managers along with other schemes managed by these managers"
                        },
                        {
                            key: "ObjectiveAndOverview",
                            label: "Objective & Overview",
                            children: <ObjectiveAndOverview />,
                            show: true,
                            heading: "Objective & Overview",
                            value: "ObjectiveAndOverview",
                            subHeading: "We discuss this scheme's objective and provide a detailed overview of the category"
                        }
                    ]} loading={loadingFundDetail} />
                </>
            )
        },
        {
            label: "Analysis",
            key: "Analysis",
            value: "Analysis",
            children: <Analysis insights={insights} loading={loadingInsights} analysisType='fund' fundData={fundData} screenerMetricDescData={screenerMetricDescData}
                fromFundDetail={true} type={type} />
        },
        {
            label: "Performance",
            key: "Performance",
            value: "Performance",
            children: (
                <>
                    <StickyScrollTabs isAccordionInMob queriesToStickToBot={['#sharpelyHeader', '#customTabsBarId']} tabData={[
                        {
                            key: 'PerformanceSummary',
                            label: 'Summary',
                            children: <AccessComponent component={<CustomLabelValueCards x={performance_summary} showDetailIcon={true} />}
                                moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} upgradeComponent={
                                    <UpgradeToPro moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} />
                                } />,
                            show: true,
                            heading: "Summary",
                            value: "PerformanceSummary",
                            subHeading: "A quick snapshot of schemes performance"
                        },
                        {
                            key: 'tracking_difference',
                            label: 'Tracking difference',
                            children: <AccessComponent component={<TrackingDifference />}
                                moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} upgradeComponent={
                                    <UpgradeToPro moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} />
                                } />,
                            show: Boolean(fundData?.is_etf_fund || fundData?.is_index_fund),
                            heading: "Tracking difference",
                            value: "TrackingDifference",
                            subHeading: "How the scheme's returns have lagged the benchmark returns over different time horizons"
                        },
                        {
                            key: 'trailing_returns',
                            label: 'Trailing returns',
                            children: <AccessComponent component={<TrailingReturns />}
                                moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} upgradeComponent={
                                    <UpgradeToPro moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} />
                                } />,
                            show: true,
                            heading: "Trailing returns",
                            value: "TrailingReturns",
                            subHeading: "Trailing returns of the scheme along with average returns of the category. To view table, toggle to table view."
                        },
                        {
                            key: 'period_returns',
                            label: 'Period returns',
                            children: <AccessComponent component={<PeriodReturns />}
                                moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} upgradeComponent={
                                    <UpgradeToPro moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} />
                                } />,
                            show: true,
                            heading: 'Period returns',
                            value: "PeriodReturns",
                            subHeading: "A look at yearly, quarterly and monthly returns of the scheme and the category. Toggle to view scheme’s percentile rank for each period."
                        },
                        {
                            key: 'alpha_beta_sharpe',
                            label: 'Alpha, beta and sharpe ratios',
                            children: <AccessComponent component={<CustomRangeSliders sliders={alphaBetaSharpe.filter(x => x.show)} />}
                                moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} upgradeComponent={
                                    <UpgradeToPro moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} />
                                } />,
                            show: Boolean(alphaBetaSharpe.filter(x => x.show)?.length),
                            heading: "Alpha, beta and sharpe ratios",
                            value: "AlphaBetaSharpeRatios",
                            subHeading: "A comparison of scheme’s alpha, beta and sharpe w.r.t other schemes in the category",
                            rightLinkText: "What are these ratios?",
                            rightTextLinkonClick: () => {
                                dispatch(toggleUserGuideModal({ value: true, type: "kbSubCategory", params: { subCategory: "risk-and-performance-measures" } }))
                            }
                        },
                        {
                            key: 'worst_drawdowns',
                            label: 'Worst drawdowns',
                            children: <AccessComponent component={<WorstDrawdowns />}
                                moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} upgradeComponent={
                                    <UpgradeToPro moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} />
                                } />,
                            show: true,
                            heading: "Worst drawdowns",
                            value: "WorstDrawdowns",
                            subHeading: "Look at worst drawdowns suffered by the scheme and subsequent recovery period."
                        },
                    ]} loading={loadingFundRet} />
                </>
            )
        },
        {
            label: "Holdings",
            key: "Holdings",
            value: "Holdings",
            children: (
                <>
                    <StickyScrollTabs isAccordionInMob queriesToStickToBot={['#sharpelyHeader', '#customTabsBarId']} tabData={[
                        {
                            key: 'holding_snapshot',
                            value: 'HoldingSnapshot',
                            label: 'Holding snapshot',
                            children: <AccessComponent component={<CustomLabelValueCards x={holdingSnapNew} showDetailIcon={true} />}
                                moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} upgradeComponent={
                                    <UpgradeToPro moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} />
                                } />,
                            show: holdingSnapNew?.filter(h => h.show)?.length,
                            heading: "Holding snapshot",
                            subHeading: "A quick snapshot of schemes holdings. Data points shown here will depend on scheme’s category"
                        },
                        {
                            key: 'actual_alloc',
                            value: "AssetAllocation",
                            label: 'Asset allocation',
                            children: <AccessComponent component={<AssetAllocation2 data={fundHolding?.actual_asset_alloc} />}
                                moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} upgradeComponent={
                                    <UpgradeToPro moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} />
                                } />,
                            show: true,
                            heading: "Asset allocation",
                            subHeading: "Scheme’s asset allocation (equity, debt) including historical data"
                        },
                        {
                            key: 'credit_rating',
                            value: "CreditRating",
                            label: 'Credit rating',
                            children: <AccessComponent component={<CreditRating />}
                                moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} upgradeComponent={
                                    <UpgradeToPro moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} />
                                } />,
                            show: (fundData?.primary_category_name === "Hybrid" || fundData?.primary_category_name === "Debt") && Object.keys(fundHolding?.category_rating_profile || {})?.length !== 0 && Object.keys(fundHolding?.scheme_rating_profile || {})?.length !== 0,
                            heading: "Credit rating",
                            subHeading: "A break up of credit rating of scheme’s debt instruments as percentage of total portfolio."
                        },
                        {
                            key: 'top_holdings',
                            value: "TopHoldings",
                            label: 'Top holdings',
                            children: <AccessComponent component={<TopHoldings />}
                                moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} upgradeComponent={
                                    <UpgradeToPro moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} />
                                } />,
                            show: fundHolding?.holdings?.length,
                            heading: "Top holdings",
                            subHeading: "A look at top holdings of the scheme (and MoM change) split across Indian Equities, Foreign Equities, Debt and Others (primarily Cash)."
                        },
                        {
                            key: 'sector_allox',
                            value: "SectorAllocation",
                            label: 'Sector allocation',
                            children: <AccessComponent component={<SectorAllocation />}
                                moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} upgradeComponent={
                                    <UpgradeToPro moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} />
                                } />,
                            show: JSON.parse(fundHolding?.sector || '[]')?.length,
                            heading: "Sector allocation",
                            subHeading: "Sectoral breakup of scheme’s holdings as a percentage of total portfolio."
                        }
                    ]} loading={loadingFundHolding} />
                </>
            ),
        },
        {
            label: "Peers",
            key: "Peers",
            value: "Peers",
            children: (
                <div style={{ paddingTop: "1.25rem", paddingBottom: "1.25rem", background: "var(--white)", borderRadius: isMobile ? "" : "var(--borderRadius)", }}
                    className={'leftRightPadContainer'}>
                    <div className='d-flex justify-content-between' style={{
                        gap: isMobile ? "0" : "1.25rem", flexDirection: isMobile ? "column" : "row",
                        paddingBottom: isMobile ? "1rem" : "0"
                    }}>
                        <CardHeader heading='Peer Analysis' hideHeaderBlueLine textClassName={isMobile ? "textMD w-500" : 'textXL w-700'} headingType={2} />
                    </div>
                    <div className='textSM dark3' style={{ margin: "0.25rem 0 1.25rem", maxWidth: isMobile ? "100%" : "70ch" }}>
                        Compare how this scheme fares with other schemes in the category or with other schemes tracking the same benchmark (switch tabs)
                    </div>
                    <AccessComponent component={<>
                        <InstrumentListTable
                            paginationSize={10}
                            hideInvestBtn={true}
                            tableType={8}
                            magicBoardtype={type?.value === 2 ? fundData?.is_index_fund === 1 ? "index" : "mf" : type?.value === 1 ? fundData?.is_index_fund === 1 ? "index" : "etf" : ""}
                            magicCatId={[fundData?.primary_category_name, fundData?.category_id]}
                            peersFilters={[
                                ["category_id", fundData?.category_id],
                                ["benchmark_id", fundData?.benchmark_id]
                            ]}
                            initialColumns={[
                                ...dataTableColumns,
                                ...screenerMetric.filter((item) => item.isInitial === true),
                            ]}
                            onPeersBtnClick={(data) => {
                                let temp = data?.columns?.filter(ele => ele?.metric_data_type === 'float')?.map((ele) => {
                                    let min = Math.min(...data?.funds?.map(el => el?.[ele?.metric_col_code]).filter(ele => ele) || []);
                                    let max = Math.max(...data?.funds?.map(el => el?.[ele?.metric_col_code]).filter(ele => ele) || []);
                                    let obj = {
                                        title: ele?.metric_name,
                                        description: '',
                                        currentVal: fundData?.[ele?.metric_col_code || ''],
                                        currentValStr: displayValue({ text: fundData?.[ele?.metric_col_code || ''], metricObj: ele }),
                                        min: min,
                                        max: max,
                                        minStr: displayValue({ text: min, metricObj: ele }),
                                        maxStr: displayValue({ text: max, metricObj: ele }),
                                        minStrLabel: "Lowest",
                                        maxStrLabel: "Highest",
                                        show: true
                                    }

                                    return obj;
                                }) || []
                                setPeersData({ sliders: temp, selectedTab: data?.selectedTab } || {});
                                setIndustryPeersModal(true)
                            }} fundData={fundData} isCard={false}
                        />
                        <CustomDrawer isOpen={industryPeersModal} toggle={() => setIndustryPeersModal(false)}
                            title={peersData?.selectedTab === 'category' ? `${type.dname} in  ${fundData?.category_name}` : `${type.dname} tracking ${fundData?.benchmark}`}>
                            <CustomRangeSliders2 sliders={peersData?.sliders || []} />
                        </CustomDrawer>
                    </>} moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} upgradeComponent={
                        <UpgradeToPro moduleKey={type?.value === 1 ? "BASIC_ETF_REPORT" : type?.value === 2 ? "BASIC_MF_REPORT" : ""} />
                    } />
                </div>
            )
        },
        {
            label: "Calculators",
            key: "Calculators",
            value: "Calculators",
            children: <Calculators fund={fundData} isDetailPage />
        }
    ]

    const cards = [
        { label: "AUM", value: CurrencyFormat(fundData?.aum, "long", 2, ' Cr') },
        { label: "Expense Ratio", value: fundData?.expense_ratio === null ? "-" : Number(fundData?.expense_ratio)?.toFixed(2) + '%' },
        { label: "Inception", value: formatDate(fundData?.inception) }
    ]

    if (loading) {
        return <div className='loaderContainer' >
            <ActivityLoaderMintbox showQuote={true} />
        </div>
    }
    return (
        <React.Fragment>
            <Head title={`${fundData?.basic_name} : Screener, Growth, Performance, Portfolio and Analysis | NSE | BSE`}
                description={type.value === 2 ? `Get detailed overview of ${fundData?.basic_name} growth, performance analysis and insight with sharpely, one of the best mutual funds analysis platform with investment reports.` : `Explore ${fundData?.basic_name} performance with sharpely etf analysis tool and make informed ETF investments. Build your ETF portfolio and analyze market data for optimal decision-making.`} />
            <Card style={{ paddingTop: "1.25rem", paddingBottom: "1.25rem" }} id="topHeadingDetailCard" bodyStyle={{ padding: 0 }}
                className={`leftRightPadContainer ${isDev ? 'maxWidthContainer' : ""}`}>
                <div style={{ display: "flex", gap: "1.25rem", justifyContent: "space-between", flexDirection: isMobile ? "column" : "row" }}>
                    <div className="d-flex" style={{ gap: "1rem" }}>
                        <AMCIcon fundType={type?.value} amcName={fundData?.amc_full_name} type="square" height={isMobile ? "4rem" : "3rem"} width={isMobile ? "4rem" : "3rem"} />
                        <div>
                            <div style={{ display: "flex", alignItems: "center", gap: "1rem", }}>
                                <h1 className={isMobile ? "margin0 w-500" : "margin0 textLG w-700"} style={{ fontSize: isMobile ? "20px" : "" }}>
                                    {fundData?.basic_name}
                                </h1>
                                <WatchList type={type.value} instrument={fundData} />
                            </div>
                            <div className={isMobile ? "textSM dark3 w-500" : "textSM dark3 w-700"} style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                {type.value === 1 ? "ETF" : "MF"}
                                {/* <a href={`https://www.nseindia.com/get-quotes/equity?symbol=${fundData?.nse_symbol}`} target='_blank'><Tag color='var(--green)'>NSE</Tag></a> */}
                            </div>
                        </div>
                    </div>
                    <div>
                        {type.value !== 2 ? <StockLivePrice fundData={fundData} format={8} symbol={fundData?.nse_symbol} /> : <>
                            <span className='w-700 textXL'>{CurrencyFormat(fundData?.latest_nav, 'long', 2, '', false)}</span>
                            <div style={{ textAlign: isMobile ? "" : "right" }}>
                                <RedGreenText text={DecimalValueFormat({ num: fundData?.ret_1day, suffix: "%" })} value={fundData?.ret_1day} />
                            </div>
                        </>}
                    </div>
                </div>
                <div style={{
                    display: "flex", gap: "1.25rem", justifyContent: "space-between", marginTop: "1rem", alignItems: isMobile ? "start" : "flex-end",
                    flexDirection: isMobile ? "column" : "row"
                }}>
                    <div>
                        <div style={{ marginBottom: "0.75rem", display: "flex", gap: "1.25rem", }}>
                            <CreateAlert type={type} />
                            {user?.id <= 7 && <div>
                                <SharpelyGateway2 IS_BASKET={false} INSTRUMENT_TYPE={type.value} FUNDDATA={fundData} TRANSACTION_TYPE={'BUY'} PORTFOLIO_OBJ={portfolioObj} />
                            </div>}
                        </div>
                        <div className="d-flex textXS dark3 flex-column flex-sm-row" style={{ gap: isMobile ? "0.25rem" : "1rem" }}>
                            <div>
                                Category:{" "}<span className="dark4 w-500">{fundData?.category_name}</span>
                            </div>
                            <div>
                                Benchmark:{" "}<span className="dark4 w-500">{fundData?.benchmark}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", borderRadius: "var(--borderRadius)", border: "1px solid var(--grey1)", width: isMobile ? "100%" : "" }}>
                        {cards?.map((ele, i) => {
                            return (
                                <div key={generateUniqueKey(i)} style={{ flex: isMobile ? 1 : '', display: 'flex' }}>
                                    <div style={{ padding: "0.5rem 1rem", flex: isMobile ? 1 : '' }}>
                                        <label className="textXS dark3">{ele.label}</label>
                                        <div className="textSM black w-500">{ele.value}</div>
                                    </div>
                                    {i !== 2 && (<div style={{ borderLeft: "1px solid var(--grey1)", margin: "0.5rem 0", }}></div>)}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Card>
            <CustomTabs tabItems={tabItems} value={selectedTabItem} onChange={(e) => { setSelectedTabItem(e); window.scrollBy({ top: 1 }) }} header={<div style={{
                display: "flex", alignItems: "center", gap: "0.75rem", flex: 1, justifyContent: "flex-end",
            }}>
                <AMCIcon fundType={type?.value} amcName={fundData?.amc_full_name} type="square" height="1.5rem" width="1.5rem" />
                {type.value !== 2 ? <StockLivePrice fundData={fundData} format={5} symbol={fundData?.nse_symbol} /> :
                    <span className='dark3 textMD w-500'>NAV :&nbsp; <span className='w-700 textMD'>{CurrencyFormat(fundData?.latest_nav, 'long', 2, '', false)}</span></span>}
            </div>} childContainerId='fundDetailCustomTabsChild' />
            <div style={{}} id={"fundDetailCustomTabsChild"} className={`${isDev ? 'maxWidthContainer' : ""}`}>
                <div style={{ display: selectedTabItem === 'Overview' ? "block" : "none" }}>
                    {ChartAndSnapShot()}
                    <Divider style={{ margin: isMobile ? "0" : '0.5rem 0', background: "var(--grey3)" }} />
                </div>
                {tabItems?.find((el) => el.value === selectedTabItem)?.children}
            </div>
            <div className={`${isDev ? 'maxWidthContainer' : ""}`}>
                {allFundsData.filter(f => f.category_id === fundData?.category_id && f.isin_code !== fundData.isin_code)?.length > 0 && <>
                    <Divider style={{ margin: isMobile ? "1rem 0" : '0.5rem 0', background: "var(--grey3)" }} />
                    <SimilarFunds funds={allFundsData.filter(f => f.category_id === fundData?.category_id && f.isin_code !== fundData.isin_code)?.sort((a, b) => b.aum - a.aum)?.slice(0, 10)} type={type}
                        heading={`${type.dname} in Category - ${fundData?.category_name}`} />
                </>}
                {(fundData?.is_index_fund === 1 || fundData?.is_etf_fund === 1) &&
                    allFundsData.filter(f => f.benchmark_id === fundData?.benchmark_id && f.isin_code !== fundData.isin_code)?.length > 0 ? <>
                    <Divider style={{ margin: isMobile ? "1rem 0" : '0.5rem 0', background: "var(--grey3)" }} />
                    <SimilarFunds funds={allFundsData.filter(f => f.benchmark_id === fundData?.benchmark_id && f.isin_code !== fundData.isin_code)?.sort((a, b) => b.aum - a.aum)?.slice(0, 10)} type={type}
                        heading={`${type.dname} tracking ${fundData?.benchmark}`} /></> : <></>}
            </div>
        </React.Fragment >
    );
};

export default FundDetail;
