import { Divider, Input } from "antd";
import { factsetlogo, valueresearch } from "Assets/images";
import { toggleBasketCreateModal } from "Libs/redux/Modals/reduxSlice";
import { toggleHelpAndSupp } from "Libs/redux/UserAuth/reduxSlice";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { BsInstagram, BsLinkedin, BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from 'react-icons/fa6';
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AdminServices from "Services/api/AdminServices";
import Logo from "./Logo";

const Footer2 = () => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  let isProd = process.env.REACT_APP_ENVIRONMENT === 'prod';
  let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 575;
  const products = [
    {
      label: "ETFs",
      link: "/etfs/dashboard"
    },
    {
      label: "Mutual Funds",
      link: "/mutual-funds/dashboard"
    },
    {
      label: "Stocks",
      link: "/stocks/dashboard"
    },
    {
      label: "Knowledge Base",
      link: "/knowledge-base"
    }
  ];

  const sharpely = [
    {
      label: "About Us",
      link: "/about-us",
    },
    {
      label: "Pricing",
      link: "/pricing"
    },
    {
      label: "Contact Us",
      link: "/contact-us"
    },
    {
      label: "Help & Support",
      link: "#",
      type: "modal"
    },

  ];

  const quicklinks = [
    {
      label: "Stock Screener",
      link: "/stocks/super-screener"
    },
    {
      label: "Factor Model",
      link: "/stocks/factor-model"
    },
    {
      label: "Stock Baskets",
      link: "/stocks/build-custom-strategy",
      type: "modal",
      onClick: () => {
        dispatch(toggleBasketCreateModal({ open: true, type: {} }))
      }
    },
    {
      label: "MF Screener",
      link: "/mutual-funds/screener"
    },
    {
      label: "MF Baskets",
      link: "/mutual-funds/build-custom-strategy"
    },
    {
      label: "ETF Screener",
      link: "/etfs/screener"
    },
    {
      label: "Investments",
      link: "/investments"
    }
  ]

  const socialmedia = [
    {
      label: "Instagram",
      link: "https://www.instagram.com/sharpely.in/",
      Icon: BsInstagram
    },
    {
      label: "Twitter",
      link: "https://x.com/sharpely_in",
      Icon: FaXTwitter
    },
    {
      label: "Linkedin",
      link: "https://www.linkedin.com/company/sharpely-in/",
      Icon: BsLinkedin
    },
    {
      label: "YouTube",
      link: "https://www.youtube.com/channel/UCBrx3b90Vpb5-CrMiqFYcrA",
      Icon: BsYoutube
    },
    {
      label: "Facebook",
      link: "https://www.facebook.com/sharpely.in",
      Icon: FaFacebookF
    },
  ]

  const footerlinks = [
    {
      label: "Privacy Policy",
      link: "/privacy-policy"
    },
    {
      label: "Terms and Conditions",
      link: "/terms-and-conditions"
    },
    {
      label: "Refund and Cancellation Policy",
      link: "/refund-and-cancellation-policy"
    }
  ]

  const subscribeToBlog = async () => {
    if (!email) {
      toast.error('Enter valid email!')
    }
    else {
      try {
        let res = await AdminServices.subscribeBlog(email);
        if (res.type === true) {
          toast.success('Hurray! You have successfully subscribed for our newsletter.');
          setEmail('');
        }
        else {
          toast.error('Something went wrong! Please try again.');
        }
      }
      catch (err) {
        toast.error('Something went wrong! Please try again.');
      }
    }
  }
  return (
    <React.Fragment>
      <div style={{ background: "#141414" }}>
        <div className='footerContent' style={{ maxWidth: isDev ? "1600px" : "", margin: isDev ? "auto" : "" }}>
          <div className='d-flex align-items-start flex-column' style={{ gap: "1.5rem", width: "40%" }}>
            <div>
              <Logo type='light' showBeta={false} />
              <div className='textSM mobileFooterText1 w-500' style={{ color: "#FFFFFF", marginTop: "0.75rem", marginBottom: "0rem" }}>
                sharpely is brand owned and operated by Mintbox Solutions Pvt. Ltd., a SEBI registered Research Analyst and online investing
                platform.
                <br />
                <br />
                SEBI Registration no. INH000009524
              </div>
            </div>
            <div className='textSM mobileFooterText1' style={{ color: "#FFFFFF" }}>
              Join our newsletter to stay up to date on features and releases.
            </div>
            <div className='d-flex align-items-start flex-column' style={{ gap: "1rem" }}>
              <div className='d-flex align-items-start align-items-md-center' style={{
                gap: "0.75rem", width: "100%"
              }}>
                <Input placeholder='Enter your email'
                  style={{ height: "40px", background: "rgba(255, 255, 255, 0.1)", border: "1px solid var(--dark3)", color: "#BBBBBB" }}
                  className='textSM black' value={email} onChange={(e) => {
                    setEmail(e.target.value);
                    e.stopPropagation();
                  }} type='email' name='email'
                  autoComplete="email" />
                <button className='btnBlack ' style={{
                  whiteSpace: "nowrap", height: "100%", background: "#141414", color: "white",
                  border: "1px solid var(--dark3)", outline: "none", userSelect: "none", flexShrink: 0
                }} onClick={() => subscribeToBlog()}>
                  Subscribe Now
                </button>
              </div>
              {/* <div className="substackIframeContainer">
              <iframe src="https://sharpely.substack.com/embed" style={{ border: "1px solid var(--white)", background: "transparent" }} height={50} width={'100%'}></iframe>
            </div> */}
              <div className='textXS mobileFooterText1' style={{ color: '#ffffff' }}>
                By subscribing you agree to with our Privacy Policy and provide consent to receive updates from our company.
              </div>
              <div>
                <div className='textSM mobileFooterText1 mb-1' style={{ color: '#ffffff' }}>Our data partners</div>
                <div className='d-flex align-items-center' style={{ gap: "1.5rem" }}>
                  <a href='https://www.factset.com/' target={'_blank'}>
                    <img loading="lazy"  alt="" src={factsetlogo} style={{ width: window.innerWidth > 760 ? "3.5rem" : "4.5rem", objectFit: "cover" }} />
                  </a>
                  <a href='https://www.valueresearchonline.com/' target={'_blank'}>
                    <img loading="lazy"  alt="" src={valueresearch} style={{ width: window.innerWidth > 760 ? "8rem" : "10rem" }} /></a>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-start flex-column' style={{ gap: "1.5rem", justifyContent: "space-between" }}>
            <div className='' style={{ gap: "3rem", display: "grid", gridTemplateColumns: "repeat(3,1fr)" }}>
              {!isMobile && <div>
                <div className='textMD w-700' style={{ color: "#ffffff" }}>PRODUCTS</div>
                {products?.map((item, i) => (
                  <Link to={item.link} className='dark3 textSM w-500 d-flex'
                    style={{ margin: "0.75rem 0px", color: "rgba(255, 255, 255, 0.67)" }} key={i + 1} >
                    {item.label}
                  </Link>
                ))}
              </div>}
              <div>
                <div className='textMD w-700' style={{ color: "#ffffff" }}>sharpely</div>
                {sharpely?.map((item, i) => {
                  if (item.type === 'modal') {
                    return (<div className='dark3 textSM w-500 d-flex'
                      style={{ margin: "0.75rem 0px", color: "rgba(255, 255, 255, 0.67)", cursor: "pointer" }} key={i + 1} onClick={(e) => {
                        if (item?.type === 'modal') {
                          dispatch(toggleHelpAndSupp(true))
                        }
                      }}>
                      {item.label}
                    </div>)
                  }
                  return <Link to={item.link} className='dark3 textSM w-500 d-flex'
                    style={{ margin: "0.75rem 0px", color: "rgba(255, 255, 255, 0.67)" }} key={i + 1} onClick={(e) => {
                      if (item?.type === 'modal') {
                        e.preventDefault();
                      }
                    }}>
                    {item.label}
                  </Link>
                })}
                {footerlinks.map((item, i) => (
                  <Link to={item.link} className='dark3 textSM w-500 d-sm-none d-flex '
                    style={{ margin: "0.75rem 0px", color: "rgba(255, 255, 255, 0.67)" }} key={item.link} onClick={(e) => {
                      if (item?.type === 'modal') {
                        e.preventDefault();
                      }
                    }}>
                    {item.label}
                  </Link>
                ))}
              </div>
              <div>
                <div className='textMD w-700' style={{ color: "#ffffff" }}>QUICK LINKS</div>
                {quicklinks?.map((item, i) => {
                  return <Link to={item.link} className='dark3 textSM w-500 d-flex'
                    style={{ margin: "0.75rem 0px", color: "rgba(255, 255, 255, 0.67)" }} key={i + 1} onClick={(e) => {
                      if (item?.type === 'modal') {
                        e.preventDefault();
                        item.onClick();
                      }
                    }}>
                    {item.label}
                  </Link>
                })}
              </div>
            </div>
            <div className="w100 d-flex flex-column " style={{ gap: "1rem", alignItems: isMobile ? "" : "flex-end" }}>
              {/* <div className="d-flex align-items-center" style={{ gap: "1rem", width: isMobile ? "100%" : "" }}>
                <a href='https://play.google.com/store/apps/details?id=com.investments.sharpely' target='_blank'>
                  <img loading="lazy"  src={andapp} alt='Download android app image' style={{ width: isMobile ? "100%" : "12rem" }} />
                </a>
                <a href='https://apps.apple.com/us/app/sharpely/id6455889217' target={'_blank'}>
                  <img loading="lazy"  src={iosapp} alt='Download ios app image' style={{ width: isMobile ? "100%" : "12rem" }} />
                </a>
              </div> */}
              <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }} >
                {socialmedia?.map((item, i) => (
                  <a key={i + 1} style={{ margin: "0.8rem 0px", gap: "0.5rem", color: "rgba(255, 255, 255, 0.67)" }}
                    className='d-flex align-items-center dark3 textSM w-400'
                    href={item.link} target='_blank'>
                    <item.Icon color='white' size='1.5rem' />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Divider style={{ margin: "0px", background: "var(--dark3)" }} />
        <div className='footerBottom' style={{ color: "#ffffff", maxWidth: isDev ? "1600px" : "", margin: isDev ? "auto" : "" }}>
          <div className='textSM' >
            Copyright © {(new Date()).getFullYear()} sharpely. All rights reserved.
          </div>
          <div className='d-sm-flex align-items-center d-none' style={{ gap: "1.5rem" }}>
            {footerlinks?.map((item, i) => (
              <Link key={i + 1} to={item.link} className='textSM ' style={{ color: "#ffffff" }}>
                {item.label}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Footer2;
