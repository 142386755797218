import AMCIcon from "Components/AMCIcon";
import { formatDateWithTime } from "Components/DateFormatter";
import MyTable from "Components/TableWithScroll";
import { displayValue, sortArrObject } from "Libs/utils";
import AdminServicesPY from "Services/api/AdminServicesPY";
import { Drawer, Input, Spin, Switch, Tooltip } from "antd";
import React from "react";
import { toast } from "react-hot-toast";
import { QueryViewer } from './QueryViewer';
import { comparators, groupColumnNames } from "./ScreenerUtils";

export const VariableBuilder = React.memo(({ isAdvance, open, allFundsData = [], toggle = () => { }, functions = [], variables = {}, screenerMetrics = [], onDone = () => { },
    isEditing = false, selectedVariable = {}, indices = [] }) => {

    const [variableName, setVariableName] = React.useState(selectedVariable?.name || '');
    const [initialQueries, setInitialQueries] = React.useState(selectedVariable?.queries || {});
    const [screenedStocks, setScreenedStocks] = React.useState([])
    const [screenedCols, setScreenedCols] = React.useState([])
    const [tableLoading, setTableLoading] = React.useState(false);
    const [timeStamp, setTimestamp] = React.useState('');

    const [isSetNAVar, setisSetNAVar] = React.useState(selectedVariable?.isSetNAVar || false)

    const isInvalidVariableName = React.useMemo(() => {
        var a = [...[...functions, ...screenerMetrics]?.map(i => String(i?.value)?.toLowerCase()), ...[...groupColumnNames, ...comparators]?.map((i) => i?.key), 'and', 'or', 'crosses', 'includes', 'excludes']
        if (a?.includes(String(variableName)?.toLowerCase())) {
            return true;
        }
        else return false;
    }, [variableName, functions, screenerMetrics]);

    const evaluateNow = async () => {
        const expChecker = (exp, queryData, key) => {
            ////console.log(logg, queryData, key)
            setTableLoading(true)
            // if(queryData?.type === "variable"){
            //     var nD = variables[key]?.name
            //     return nD
            // }else 
            if (String(exp)?.match(/by\s{0,3}=\s{0,2}/)) {
                var showPeriod = queryData?.expression_arr?.map(element => {
                    return [`"MKT"`, `"SEC"`, `"INDGRP"`, `"IND"`].concat(indices?.map((item) => `"${item?.IndexNameCaps}"`)).filter(str => element.includes(str))?.length > 0
                });
                showPeriod = showPeriod?.filter((i) => i === false)?.length === queryData?.expression_arr?.length
                queryData = {
                    ...queryData,
                    expression_arr: !showPeriod ? queryData?.expression_arr?.filter(element => {
                        return !element.match(/period\s{0,3}=/)
                    }) : queryData?.expression_arr
                }


                return (`${key?.replaceAll(/\d{1,4}_/g, '')}(${queryData?.expression_arr?.join(", ")})`)?.trim()
            }
            if (Object.keys(queryData)?.includes("patternOutput")) {
                return exp + " == " + queryData?.patternOutput
            }
            if (String(key)?.includes("Operator"))
                return (`${queryData?.expression_arr}`)?.trim()
            return exp
        }

        const multiExpConvertor = (exp, queryData, key) => {
            let itemStr = queryData?.expression_arr?.map((i) => { return `"${i}"` })?.join(', ')
            ////console.log(logg, 123, queryData, itemStr)
            return `${queryData?.expression_details?.value} ${queryData?.comparator} [${itemStr}]`
        }

        const finalCode = (vvl = 0) => {
            var output = ""
            var code = []
            var code_f_key = ""

            const cond_queries = initialQueries;
            //console.log(variables, cond_queries)
            var cond_queries_keys = Object.keys(cond_queries) || []
            var queryExp = []
            for (let index = 0; index < cond_queries_keys.length; index++) {
                const query_key = cond_queries_keys[index];
                ////console.log(logg, 123, query_key, cond_queries[query_key])
                const query_exp = cond_queries[query_key]?.type === "metric" ? cond_queries[query_key]?.isMultiple ? multiExpConvertor(cond_queries[query_key]?.expression, cond_queries[query_key], query_key) : cond_queries[query_key]?.expression_details?.metric_col_code : expChecker(cond_queries[query_key]?.expression, cond_queries[query_key], query_key)
                queryExp.push(query_exp)
            }
            var queriee = queryExp?.join(" ")
            var currentVar = `${variableName} = ${queriee};`

            // return code?.join("\n")

            var variable_keys = Object.keys(sortArrObject(variables)) || []
            for (let index = 0; index < variable_keys?.length; index++) {

                Object.filter = (obj, predicate) => {
                    return Object.fromEntries(Object.entries(obj).filter(predicate))
                };

                var conditionString = currentVar
                var variableString = JSON.stringify(Object.filter(variables, ([varname, value]) => value?.isGlobal !== true && varname !== variable_keys[index]))
                const regexPattern = new RegExp(`\\b(${variables[variable_keys[index]]?.name})\\b`, 'g');

                var adding = false


                if (variableString?.match(regexPattern)?.length > 0 || conditionString?.match(regexPattern)?.length > 0) {
                    adding = true
                }

                if (adding === true) {
                    const cond = variable_keys[index];
                    // ////////////console.log(logg, cond)
                    const cond_queries = variables[cond]?.queries;
                    var cond_queries_keys = Object.keys(cond_queries) || []
                    var queryExp = []
                    for (let index = 0; index < cond_queries_keys.length; index++) {
                        const query_key = cond_queries_keys[index];
                        // ////console.log(logg, 123, query_key, cond_queries[query_key])
                        const query_exp = cond_queries[query_key]?.type === "metric" ? cond_queries[query_key]?.isMultiple ? multiExpConvertor(cond_queries[query_key]?.expression, cond_queries[query_key], query_key) : cond_queries[query_key]?.expression_details?.metric_col_code : expChecker(cond_queries[query_key]?.expression, cond_queries[query_key], query_key)
                        queryExp.push(query_exp)
                    }
                    var queriee = queryExp?.join(" ")
                    if (variables[cond]?.conditionBlockWrapper) {
                        code?.push(`${variables[cond]?.name} = ${variables[cond]?.conditionBlockWrapper?.type}((${queriee}),${variables[cond]?.conditionBlockWrapper?.noOfBars});`)
                    } else {
                        code?.push(`${variables[cond]?.name} = ${queriee};`)
                    }
                }



                // if (index > 0)
                //     code_f_key += " " + conditions_oper[index - 1] + " "

                // code_f_key += "c" + index
                // // code += `${cond} = ${queriee}`
            }
            code?.push(currentVar)

            return (code?.join("\n"))
        }
        var output = finalCode()
        // //console.log(output)
        var res = await AdminServicesPY.evaluateScreenVariable({ code: output }).then(r => r)
        res = JSON.parse(res?.data?.replaceAll(/NaN/g, null))
        setScreenedStocks((res?.values || []).map((i) => {
            //console.log(i)
            return {
                ...[...allFundsData].filter((ii) => ii?.symbol === i?.symbol)[0],
                ...i,
            }
        }))
        if (res?.values?.length > 0)
            setScreenedCols((Object?.keys(res?.values?.[0])?.filter((i) => !(['symbol', 'mcap']?.includes(i))))?.map((item) => {
                return {
                    "dataIndex": item,
                    "key": item,
                    "metric_col_code": item,
                    "metric_unit": "",
                    "metric_data_type": "float",
                    "metric_name": item,
                    "title": <span>{item}</span>,
                    "align": "right",
                    "width": 120,
                }
            }))
        setTimestamp(res?.timestamp)
        setTableLoading(false)
    }

    return (
        <Drawer open={open} onClose={toggle} width="min(100%, 60rem)" title={isEditing ? "Edit Custom Metric" : 'Create Custom Metric'} closable={false}
            footer={<div className='d-flex align-items-center justify-content-end' style={{ gap: "1.25rem" }} styles={{
                content: {
                    overflow: ""
                }
            }}>
                <button className='btnWhite' onClick={toggle}>Cancel</button>
                <button className='btnBlack' disabled={variableName?.length <= 2 || isInvalidVariableName} onClick={() => {
                    onDone({
                        name: variableName,
                        isSetNAVar: isSetNAVar,
                        queries: initialQueries
                    })
                }}>Done</button>
            </div>}
        >
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
                <div>
                    <div className='textSM w-500 dark4 mb-2'>What would you like to name this custom metric?</div>
                    <div style={{ marginBottom: "1.25rem" }}>
                        <Input value={variableName} status={isInvalidVariableName ? "error" : ""} onChange={(e) => {
                            let val = e.target.value;
                            val = val?.replace(/[0-9\s]/g, '')
                            setVariableName(val)
                        }} onBlur={() => {
                            if (isInvalidVariableName) {
                                toast.error("Custom metric name cannot be same as any metric or functions")
                            }
                        }} style={{ width: "15rem" }} placeholder='Variable name' />
                        {isInvalidVariableName && <div className="textXS w-500 mt-2" style={{ color: "var(--red)" }}>
                            Custom metric name cannot be same as any predefined metric or function.
                        </div>}
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                    <div className="textESM textLight">Set negative values to NA</div>
                    <Switch checked={isSetNAVar} onChange={(e) => {
                        setisSetNAVar(e)
                    }} />
                </div>

            </div>
            <QueryViewer allFundsData={allFundsData} indices={indices} isAdvance={isAdvance} functions={functions} screenerMetrics={screenerMetrics} variables={Object.fromEntries(Object.entries(variables)?.filter(([key, val]) => val?.name !== selectedVariable?.name))} initialQueries={initialQueries}
                onQueriesUpdate={(queries) => {
                    setInitialQueries(queries)
                }}
            />
            {
                JSON.stringify(initialQueries)?.length > 3 && <div className="my-4">
                    {/* <div className='textSM w-500 dark4 mb-2'>Want to evaluate this variable?</div> */}
                    <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
                        <button className='btnWhite' onClick={() => {
                            if (variableName?.length > 2) {
                                evaluateNow()
                            } else {
                                toast.error("Enter proper variable name")
                            }

                        }}>
                            Evaluate now
                        </button>
                        {tableLoading && screenedStocks?.length === 0 && <Spin style={{ color: "var(--primary)" }} />}
                    </div>
                    {screenedStocks?.length > 0 && <div style={{}}>
                        <div className='textMD w-700 my-3'>As on {formatDateWithTime(timeStamp)}</div>
                        <div className="my-3" style={{ display: "flex" }}>
                            <MyTable
                                loading={tableLoading}
                                dataSource={screenedStocks}
                                columns={[{
                                    "title": "Name",
                                    'disableDeleteInCustomize': true,
                                    "dataIndex": "proper_name",
                                    render: (text, record, index) => <Tooltip title={record.proper_name} arrow={false}>
                                        <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center" }}>
                                            <AMCIcon amcName={record.symbol} fundType={4} height='1.15rem' width="1.15rem" bseTicker={record?.bse_ticker} />
                                            <div>
                                                <div className="w-500 black" style={{ width: 180, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{record?.proper_name}</div>
                                            </div>
                                        </div>
                                    </Tooltip>,
                                    width: 100,
                                    fixed: window.innerWidth > 760 ? 'left' : "",
                                    show: true
                                }, ...screenedCols.map((col) => {
                                    return {
                                        ...col,
                                        "render": (text, record, index) => <div style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "flex-end" }}>
                                            {displayValue({
                                                text, metricObj: col
                                            })}
                                        </div>
                                    }
                                })]}
                                rowKey={(record) => record.plan_id || record.symbol}
                                scroll={{ x: 'max-content' }}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                        </div>
                    </div>}
                </div>
            }
        </Drawer >
    )
})