import { Input, Modal, Select } from 'antd';
import Logo from 'Layout/Logo';
import React from 'react';
import { useSelector } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import { toast } from 'react-hot-toast';
import PinInput from 'react-pin-input';
import AdminServices from 'Services/api/AdminServices';
import { Dashboard_Portfolio } from 'Services/api/FabricatedAPIS';
import { useDispatch } from 'react-redux';
import { updateUserData } from 'Libs/redux/UserAuth/reduxSlice';
import moengage from "@moengage/web-sdk";

export const UpdatePhoneModal = ({ open = null, toggle }) => {

    const user = useSelector((state) => state?.updateUserData?.value)
    const openPreferenceModal = useSelector((state) => state?.preferenceModal?.value);
    const [openModal, setOpenModal] = React.useState(false);
    const [isOpenedOnce, setIsOpenedOnce] = React.useState(false);
    const [phone, setPhone] = React.useState('');
    const [nextBtnPressed, setNextBtnPressed] = React.useState(false);
    const [mainOtp, setMainOtp] = React.useState('');
    const [otp, setOTP] = React.useState('');
    const [updating, setUpdating] = React.useState(false);
    const otpref = React.useRef();
    const dispatch = useDispatch();

    const sendOTP = async () => {
        if (phone?.length !== 10) {
            toast.error('Enter valid phone number')
        }
        else {
            setUpdating(true);
            let tphone = phone
            let check = await AdminServices.ClientExists({ type: "mobile", value: tphone });
            if (check?.data?.Count > 0) {
                toast.error('This number is already registered on sharpely. Use another number.')
                setUpdating(false);
                setNextBtnPressed(false);
            }
            else {
                let res = await AdminServices.sendOTP('mobile', phone, 2, user?.name);
                if (res?.type === true) {
                    toast.success('OTP has been sent to phone number.')
                    // setMainOtp(res?.data?.otpcode);
                    setUpdating(false);
                    setNextBtnPressed(true);
                }
                else {
                    toast.error('Something went wrong! Please try again.')
                    setUpdating(false);
                    setNextBtnPressed(false);
                }
            }
        }
    }

    const checkOTP = async () => {
        let isCorrectOTP = await AdminServices.verifyOTP({ value: phone, otp: otp });
        if (isCorrectOTP?.data?.Result === 'success') {
            setUpdating(true);
            let tphone = phone
            let data = {
                clientId: user?.Client[0]?.ClientId,
                clientName: user?.Client[0]?.DisplayName,
                mobileNumber: tphone
            }
            let res = await AdminServices.editAccount(data);
            if (res?.type === true) {
                moengage.add_mobile(tphone);
                if (open !== null && toggle) {
                    toggle();
                }
                let userData = { name: user?.name, email: user?.email, mobile: user?.mobile, isLogged: true, id: user?.id, login_Date: user?.login_Date }
                await Dashboard_Portfolio.getClientData(userData, true).then((res) => {
                    if (res) {
                        dispatch(updateUserData({ ...userData, ...res, mobile: res?.Client[0]?.MobileNumber }))
                    }
                })
                toast.success('Phone number updated.')
                setUpdating(false);
                setOpenModal(false);
            }
            else {
                setUpdating(false);
                toast.error('Something went wrong! Please try again.')
            }
        }
        else {
            setUpdating(false);
            toast.error('Invalid OTP!')
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (updating) return;
        if (nextBtnPressed) {
            checkOTP();
        }
        else {
            sendOTP();
        }
    }

    const checkModalToOpen = () => {
        if (open !== null) {
            if (open === true) {
                setPhone('');
                setUpdating(false);
                setNextBtnPressed(false);
                // setMainOtp('');
                setOTP('');
                setOpenModal(true);
            }
        }
        else {
            if (user?.isLogged && !isOpenedOnce) {
                if (!user?.mobile && !openPreferenceModal) {
                    setTimeout(() => {
                        setPhone('');
                        setUpdating(false);
                        setNextBtnPressed(false);
                        // setMainOtp('');
                        setOTP('');
                        setOpenModal(true);
                        setIsOpenedOnce(true)
                    }, 10000)
                }
                else {
                    setOpenModal(false);
                }
            }
        }
    }

    const onClose = () => {
        setPhone('');
        setUpdating(false);
        setNextBtnPressed(false);
        // setMainOtp('');
        setOTP('');
        setOpenModal(false);
        setIsOpenedOnce(true);
        if (open !== null && toggle) {
            toggle();
        }
    }

    React.useEffect(() => {
        checkModalToOpen();
    }, [openPreferenceModal, open]);

    React.useEffect(() => {
        if (otp?.length === 6) {
            checkOTP();
        }
    }, [otp]);

    if (!user?.isLogged) return null;
    return (
        <React.Fragment>
            <Modal open={openModal} footer={null} onCancel={() => onClose()} width="min(100%, 30rem)" onOk={() => onClose()}>
                <Logo type='dark' />
                <div className='textMD black w-500' style={{ margin: "0.5rem 0 1rem" }}>
                    Add your phone number
                </div>
                <form onSubmit={(e) => handleSubmit(e)}>
                    {!nextBtnPressed ? <>
                        <div>
                            <label className='dark3 textXS w-500 mb-2'>Phone number</label>
                            <div className='d-flex align-items-center' style={{ gap: "1rem" }}>
                                <Input value={phone} onChange={(e) => setPhone(e.target.value)}
                                    placeholder='Enter phone number' type={'number'} />
                            </div>
                        </div>
                    </> : <>
                        <div>
                            <label className='dark3 textXS w-500 mb-2'>OTP</label>
                            <PinInput ref={otpref} length={6} initialValue="" secret secretDelay={100} onChange={(value, index) => { setOTP(value); }} type="numeric" inputMode="number"
                                style={{ padding: '0rem' }} inputStyle={{
                                    borderColor: 'var(--grey2)', background: "var(--grey5)", borderRadius: "var(--borderRadius)",
                                    height: "40px", width: "40px"
                                }} inputFocusStyle={{ borderColor: 'var(--primary)' }} onComplete={(value, index) => { }} autoSelect={true} focus={true} />
                        </div>
                    </>}
                    {/* <div style={{ display: "flex", alignItems: "center", marginTop: "1rem", gap: "1.25rem", justifyContent: "flex-end", width: "100%" }}> */}
                    <button className='btnBlack  w100' type='submit' style={{ marginTop: "1.5rem" }}>
                        {updating ? <LoadingOutlined style={{ fontSize: '1rem', color: "var(--white)" }} spin /> : (nextBtnPressed ?
                            'Verify OTP' : "Send OTP")}
                    </button>
                    {nextBtnPressed && <>
                        <div className='mt-3 text-center textXS w-500 dark4'>
                            Don't have access to this number? <span style={{ color: "var(--primary)", cursor: "pointer" }} onClick={() => {
                                setNextBtnPressed(false)
                            }}>Change number</span>
                        </div>
                    </>}
                    {/* </div> */}
                </form>
            </Modal>
        </React.Fragment>
    )
}