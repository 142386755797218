import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { changeObjKey } from './DataCustomizer';
import { DecimalValueFormat } from './DecimalValueFormat';

const PerformanceChart3 = ({ chartData = [], benchName = "", fundName = "", portfolioType = 0, xAxisKey = "Date", yAxisKeys = [], chartType = 'areaspline', showYAxis = false, drawHorizontalLines = [], hidePerc = true, showDataLabels = true, height = 200, yAxisType = 'linear', changeZeroToConstant = false, showLegend = false, tooltip = {} }) => {
    const fundColors = ['#007AFF', '#83BF6E', '#555770', '#FFD966', '#FF55BB', '#0062CC', '#AA55FF', '#FF9C55', '#FF6A55', '#1A1D1F', '#00FFFF', '#DBFF00']
    const [noChart, setnoChart] = React.useState(false);

    var redColor = '#FF6A55'
    var greenColor = '#83BF6E'

    var a = yAxisKeys.length === 0 ? Object.keys(JSON.parse(chartData)[0])?.filter((i) => i.toLowerCase() !== xAxisKey.toLowerCase()) : yAxisKeys

    try {
        var options = {
            chart: {
                type: chartType,
                backgroundColor: "transparent",
                height: height,
                marginRight: 0,
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                type: yAxisType,
                visible: showYAxis,
                // allowNegativeLog: true,
                custom:
                {
                    allowNegativeLog: true,
                },
                tickAmount: yAxisType === 'logarithmic' ? undefined : 5,
                gridLineDashStyle: 'longdash',
                title: {
                    text: ''
                },
                gridLineColor: 'var(--grey1)',
                plotLines: drawHorizontalLines.map((line, i) => {
                    return {
                        color: i === 0 ? 'green' : 'orange',
                        value: line.val, // Position, you'll have to translate this to the values on your x axis
                        dashStyle: 'dash',
                        width: 1,
                        label: {
                            text: line.label, rotation: 0, x: i === 0 ? 10 : 40,
                            y: 2,
                            style: {
                                color: i === 0 ? 'green' : 'orange',
                                fontWeight: '700',
                            }
                        }
                    }
                })
            },
            xAxis: [{
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%e %b',
                    week: '%e %b',
                    month: '%b %y',
                    year: '%Y'
                },
                lineColor: 'var(--grey1)',
                tickColor: 'var(--grey1)'
            }
            ],
            legend: {
                enabled: showLegend
            },
            plotOptions: {
                series: {
                    lineWidth: 1,
                    label: {
                        connectorAllowed: false
                    },
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                shared: true,
                valuedecimals: 1,
                ...tooltip
                // valuePrefix: '₹',
            },
            credits: {
                enabled: false
            },
            boost: {
                useGPUTranslations: true,
            },
            series: a.map((item, index) => {
                return {
                    name: item === 'pe_ttm' ? "P/E" : item === 'pb' ? "P/B" : item === "Benchmark" ? benchName || item : fundName || item,
                    turboThreshold: 9e9,
                    color: chartType === 'column' ? greenColor + "88" : fundColors[index],
                    negativeColor: chartType === 'column' ? redColor + "88" : fundColors[index],
                    type: chartType,
                    dataLabels: {
                        enabled: chartType === 'column' && showDataLabels,
                        style: { fontWeight: '400', textOutline: "none", color: "var(--dark4)" },
                        formatter: function () {
                            return DecimalValueFormat({ num: this.y, decimals: 2, suffix: hidePerc ? "" : "%" })
                        }
                    },
                    data: changeObjKey(chartData, [String(item), xAxisKey], ["y", "x"], "stockhistchart", 1).map((i, index) => {
                        if (chartType === 'column' && (JSON.parse(chartData).length - 1) === index) {
                            return {
                                ...i,
                                color: i.y > 0 ? greenColor : redColor
                            }
                        } else {
                            return {
                                ...i,
                            }
                        }
                    }),

                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, fundColors[index] + "77"],
                            [0.1, fundColors[index] + "10"],
                        ]
                    },

                }
            }),
            scrollbar: {
                enabled: false
            },
        };
        console.log('omkar', options)
    } catch (error) {
        setnoChart(true)
    }




    try {
        return (
            noChart ? <div className='textXS' style={{ margin: "4rem", textAlign: "center" }}>
                No chart data available!
            </div>
                : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '1rem', }}>

                    <HighchartsReact highcharts={Highcharts} options={options} />
                </div>
        );
    } catch (error) {
        return <div className='textSM' style={{ margin: "4%", textAlign: "center" }}>
            No chart data available!
        </div>
    }
};
export default PerformanceChart3;