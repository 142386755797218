import { Card, Collapse, Input, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { getstarted } from "Assets/images";
import Head from "Layout/Head";
import { toggleHelpAndSupp } from "Libs/redux/UserAuth/reduxSlice";
import { helpAndSupportIssues, makeid } from "Libs/utils";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { BiMinus, BiPlus } from "react-icons/bi";
import { BsInstagram, BsLinkedin, BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { MdCall, MdLocationOn, MdMail } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import AdminServices from "Services/api/AdminServices";

export default function ContactUs() {

    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
    const user = useSelector((state) => state?.updateUserData?.value);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [faqModal, setFaqModal] = useState(false);

    const socialmedia = [
        {
            label: "Instagram",
            link: "https://www.instagram.com/sharpely.in/",
            Icon: BsInstagram
        },
        {
            label: "Twitter",
            link: "https://x.com/sharpely_in",
            Icon: FaXTwitter
        },
        {
            label: "Linkedin",
            link: "https://www.linkedin.com/company/sharpely-in/",
            Icon: BsLinkedin
        },
        {
            label: "YouTube",
            link: "https://www.youtube.com/channel/UCBrx3b90Vpb5-CrMiqFYcrA",
            Icon: BsYoutube
        },
        {
            label: "Facebook",
            link: "https://www.facebook.com/sharpely.in",
            Icon: FaFacebookF
        },
    ]

    const faqs = [
        {
            title: "Is sharpely free to use?",
            content: <span>Yes. You can use sharpely for free. But in the free version, you will be able to use limited features. To get the best out of sharpely, you can purchase a suitable plan at any time. Here is a link to our pricing page for more details on what is available in the free plan.</span>,
        },
        {
            title: "What is sharpely premium and what do I get with a sharpely premium membership?",
            content: <span>
                <div>When you explore sharpely as a new visitor, you will encounter certain features that have restricted access. These exclusive features, known as premium or locked features, can be unlocked by subscribing to sharpely premium services.</div>
                <div className="marginTopFAQ">Sharpely premium provides unlimited and uninterrupted access to all the features we have designed specifically for you, including screeners, signals, scores, and much more.</div>
            </span>,
        },
        {
            title: "Why should I pay for sharpely premium when screening tools are generally available for free?",
            content: <span>
                <div>The financial data you encounter can be broadly categorized into two main groups.</div>
                <div className="marginTopFAQ">
                    Firstly, there is the category of ‘financial information’. This encompasses raw data such as financial statements, financial ratios, and basic screening tools. This information, along with the tools, is readily available to everyone and will always remain accessible for free on the Sharpely website.
                </div>
                <div className="marginTopFAQ">
                    The second category delves into much greater detail. Here, we leverage our proprietary tools to extract meaning from the raw data and provide actionable insights. This data is of institutional-grade quality and undergoes extensive refinement beyond its raw form. Additionally, we offer advanced stock analysis tools to further enhance your decision-making process.
                </div>
                <div className="marginTopFAQ">
                    By subscribing to sharpely premium services, you gain access to this refined data and invaluable insights, giving you a significant advantage over others. Alongside other key benefits, your subscription essentially grants you access to this high-quality data.
                </div>
                <div className="marginTopFAQ">
                    It's a small price to pay to stay sharp (no pun intended) and ahead of the rest.
                </div>
            </span>,
        },
        {
            title: "Can I use my membership on only one device?",
            content: <span>
                <div>Once subscribed, you get unlimited access to all the content and services from any device through the browser or via mobile applications.</div>
            </span>,
        },
        {
            title: "Can I create a customized package with the option I require?",
            content: <span>
                <div>Unfortunately, we do not provide the option to create a custom package. We do offer multiple packages to choose from in order to best fit your needs.</div>
            </span>,
        },
        {
            title: "How do I change my subscription plan?",
            content: <span>
                <div>If you want to upgrade from the free plan to a premium plan, visit the pricing page from your account. Compare available plans and select the most suitable option for you. Complete the payment using your preferred payment method.</div>
                <div className='marginTopFAQ'>
                    To change your premium subscription plan, all you have to do is simply mail us at <a href='mailto:support@sharpely.in'>support@sharpely.in</a> with the preferred plan.
                </div>
            </span>,
        },
        {
            title: "What are the different payment methods I can opt for?",
            content: <span>
                <div>Multiple payment options are supported. You can pay using a credit card, debit card, UPI, or net banking.</div>
                <div className='marginTopFAQ'>
                    You can also pay per month or set up a recurring payment (if you are opting for a monthly subscription). Note that you can cancel anytime.
                </div>
            </span>,
        },
        {
            title: "How do I cancel my sharpely pro subscription?",
            content: <span>
                <div>You can cancel anytime. Check our refund and cancellation policy <span style={{ color: "var(--primary)", cursor: "pointer" }} onClick={() => navigate('/refund-and-cancellation-policy')}>here.</span></div>
            </span>,
        },
        {
            title: "I have a paid membership but I am unable to access paid features?",
            content: <span>
                <div>sharpely paid membership is mapped against an email address which should be used to login to sharpely for accessing paid features.</div>
                <div className="marginTopFAQ">
                    1. The first step would be to check if you are logged in using the Email address you used for purchasing the paid plan. Kindly make sure that you have logged in using the same ID.
                </div>
                <div className="marginTopFAQ">
                    2. You might be logged out of your account while making the payment for a paid plan or inactivity over a long period of time. You need to log in to access the Pro features.
                </div>
                <div className='marginTopFAQ'>
                    You can always write to us at <a href='mailto:support@sharpely.in'>support@sharpely.in</a> if you face any issues accessing your paid membership.
                </div>
            </span>,
        },
        {
            title: "Can I delete my sharpely account?",
            content: <span>
                <div>Yes, you can delete your account. The option is available on your profile page.</div>
            </span>,
        }
    ]

    const NotLoggedIn = () => {
        return (
            <div id="contact-us-card" style={{ padding: '1.25rem', borderRadius: "0.75rem", boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', width: window.innerWidth <= 650 ? "100%" : '20rem' }}
                className='d-flex flex-column align-items-center text-center'>
                <img loading="lazy"  src={getstarted} style={{ width: "60%", margin: "" }} />
                <div className='textSM w-500' style={{ marginTop: "1.25rem" }}>
                    Embark on your investment journey today by creating a free account with sharpely.
                </div>
                <>
                    <Link to='/signup' className='w100'>
                        <button className='btnBlack w100' style={{ marginTop: "2rem" }} >
                            Create account
                        </button>
                    </Link>
                    <div className="d-flex align-items-center w100" style={{ gap: "1rem", margin: "1rem auto" }}>
                        <div className="flex-grow-1" style={{ borderTop: "1px solid var(--dark4)" }}></div>
                        <span className='textXS w-500'>Or</span>
                        <div className="flex-grow-1" style={{ borderTop: "1px solid var(--dark4)" }}></div>
                    </div>
                    <Link to='/login' className='w100'>
                        <button className='btnWhite w100'>
                            Login
                        </button>
                    </Link>
                </>
            </div>
        )
    }

    const ContactForm = () => {

        const [message, setMessage] = useState('');
        const [issue, setIssue] = useState('');
        const [phone, setPhone] = useState('')

        const handleSubmit = async () => {
            if (!issue || !issue.length) {
                toast.error('Please select a issue.');
            }
            else {
                let temp = { issueType: issue, email: user?.email || '', name: user?.name || '', mobileNumber: user?.mobile || phone, message }
                let res = await AdminServices.submitIssue(temp);
                if (res.type === true) {
                    toast.success('Your issue has been submitted successfully.');
                    setIssue('');
                    setPhone('');
                    setMessage('');
                }
                else {
                    toast.error('Something went wrong! Please try again.')
                }
            }
        }

        return (
            <div style={{
                boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                padding: "1.25rem", borderRadius: "0.75rem"
            }} className='w100 h100 d-flex flex-column'>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1.25rem" }}>
                    <div className="">
                        <label className="textSMtoXS w-500 dark4">Name</label>
                        <Input value={user?.name} readOnly className="mt-1" style={{ border: "1px solid var(--grey1)" }} />
                    </div>
                    <div className="">
                        <label className="textSMtoXS w-500 dark4">Email</label>
                        <Input value={user?.email} readOnly className="mt-1" style={{ border: "1px solid var(--grey1)" }} />
                    </div>
                </div>
                <div className="" style={{ marginTop: "1.25rem" }}>
                    <label className="textSMtoXS w-500 dark4">Phone Number</label>
                    <Input value={user?.mobile ? user.mobile : phone} readOnly={user?.mobile?.length} className="mt-1" style={{ border: "1px solid var(--grey1)" }} onChange={(e) => {
                        setPhone(e.target.value)
                    }} />
                </div>
                <div className="" style={{ marginTop: "1.25rem" }}>
                    <label className="textSMtoXS w-500 dark4" >Issue</label>
                    <Select placeholder="Select Issue" className='textSM mt-1' style={{
                        marginBottom: "", display: "block"
                    }} allowClear options={helpAndSupportIssues} value={issue || undefined}
                        onChange={(value) => setIssue(value)} required />
                </div>
                <div className="flex-grow-1 d-flex flex-column margin20Bottom" style={{ marginTop: "1.25rem" }}>
                    <label className="textSMtoXS w-500 dark4" >Your Message</label>
                    <TextArea placeholder="Write your message..." className='flex-grow-1 mt-1'
                        style={{ resize: "none", minHeight: "5rem", border: "1px solid var(--grey1)" }} type='text' required value={message}
                        onChange={(e) => setMessage(e.target.value)} />
                </div>
                <button className="btnPrimary " onClick={handleSubmit}>
                    Send message
                </button>
            </div>
        )
    }

    return (
        <React.Fragment>
            <Head title='Get in Touch with sharpely | Contact Information' description="Reach out to sharpely's dedicated team for assistance, queries, or collaborations. Find our contact details here." />
            <main className="marketing">
                <div className={`padAll marHomeCardPadTop ${isDev ? 'maxWidthContainer' : ""}`}>
                    <div className="d-flex w100 h100" style={{ gap: "2rem" }}>
                        <div className="normalContainer">
                            <div>
                                <h1 className="margin0 headLGtoXS w-700" style={{ maxWidth: "15ch" }}>
                                    Let's Chat! Reach Out to Us
                                </h1>
                                <div className="textLGtoESM dark3" style={{ marginTop: "1.25rem" }}>
                                    We're just a message away! Whether you have questions about our pricing, plans, or any other inquiries, we're here to help. Simply fill out the form below, and our dedicated team will be thrilled to connect with you and provide all the information you need.
                                </div>
                                {window.innerWidth <= 650 && <div style={{ marginTop: "1.5rem" }}>
                                    {user === undefined ? <NotLoggedIn /> : <ContactForm />}
                                </div>}
                                <div className='' style={{
                                    borderRadius: "0.75rem", padding: "1.25rem", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                    marginTop: "2rem"
                                }}>
                                    <div className="d-flex " style={{ gap: "1.25rem", }}>
                                        <MdLocationOn style={{ fontSize: "2rem", color: "var(--primary)", flexShrink: 0 }} />
                                        <div className=''>
                                            <div className='textMD w-700'>
                                                Visit us
                                            </div>
                                            <div className="textSM w-500 dark3">
                                                Come say Hello at our office HQ
                                            </div>
                                            <div className="textSM w-500 black mt-2">
                                                C1, 325, Soham Plaza, Manpada Flyover, Manpada, Thane West
                                                <br />
                                                Thane - 400607
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex " style={{ gap: "1.25rem", marginTop: "1.25rem" }}>
                                        <MdMail style={{ fontSize: "1.75rem", color: "var(--primary)", flexShrink: 0 }} />
                                        <div className=''>
                                            <div className='textMD w-700'>
                                                Mail us
                                            </div>
                                            <div className="textSM w-500 dark3">
                                                Our friendly team is here to help.
                                            </div>
                                            <div className="textSM w-500 black mt-2">
                                                <a href="mailto:hello@sharpely.in">hello@sharpely.in</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex " style={{ gap: "1.25rem", marginTop: "1.25rem" }}>
                                        <MdCall style={{ fontSize: "1.75rem", color: "var(--primary)", flexShrink: 0 }} />
                                        <div className=''>
                                            <div className='textMD w-700'>
                                                Call us
                                            </div>
                                            <div className="textSM w-500 dark3">
                                                Mon - Fri from 9AM to 7PM
                                            </div>
                                            <div className="textSM w-500 black mt-2">
                                                <a href="tel:+919324492596">+91 93244 92596</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center" style={{ marginTop: "1.25rem", gap: "1.25rem" }}>
                                        {socialmedia.map((ele) => (
                                            <a key={ele.label} href={ele.link} target='_blank'>
                                                <ele.Icon style={{ fontSize: "1.25rem" }} color='black' />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {window.innerWidth > 650 && <div className="normalContainer align-items-center justify-content-center">
                            {user === undefined ? <NotLoggedIn /> : <ContactForm />}
                        </div>}
                    </div>
                </div>
                <Card style={{ borderRadius: "0px", padding: "0px" }} bodyStyle={{ padding: "0rem" }} className={`${isDev ? 'maxWidthContainer' : ""}`}>
                    <div className="padLeft padRight padBottom" style={{ background: "var(--white)" }} >
                        <div className='d-flex justify-content-between flex-column flex-sm-row' style={{ gap: "2.5rem" }} >
                            <div className="marHomeResMainCard">
                                <h2 className='headLGtoXS black w-700 margin0' style={{ width: "11ch" }}>Frequently asked questions</h2>
                                <div className="textMD dark3" style={{ marginTop: "0.75rem" }}>
                                    Still have question? <span style={{ color: "var(--primary)", cursor: "pointer" }} onClick={() => {
                                        dispatch(toggleHelpAndSupp(true))
                                    }}>Contact us</span>
                                </div>
                            </div>
                            <div className="flexCard">
                                <Collapse style={{ width: "100%", background: "transparent" }} bordered={false}
                                    expandIcon={({ isActive }) => isActive ? <BiMinus size='0.875rem' color='var(--primary)' /> : <BiPlus size='0.875rem' color='var(--primary)' />}
                                    expandIconPosition='start' items={faqs?.slice(0, 3).map((ele, i) => {
                                        return {
                                            key: makeid(i),
                                            label: <span className="textMDtoESM w-500">{ele.title}</span>,
                                            children: <span className="textSMtoESM dark3 w-500">{ele.content}</span>,
                                            style: {
                                                background: "var(--white)",
                                                borderBottom: "1px solid var(--grey3)",
                                                padding: '0.75rem',
                                                borderRadius: "var(--borderRadius)",
                                                borderTop: "none"
                                            }
                                        }
                                    })} />
                                <div className='d-flex  align-items-center textMDtoSM w-500' style={{ gap: "0.25rem", color: "var(--primary)", cursor: "pointer" }}
                                    onClick={() => setFaqModal(true)}>
                                    See all FAQs  <HiOutlineArrowSmRight />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal open={faqModal} footer={null} onCancel={() => setFaqModal(false)} onOk={() => setFaqModal(false)} closable={true} width="min(100%, 40rem)" >
                        <div style={{ maxHeight: "70vh", overflow: "auto" }} className='scrollbox'>
                            <Collapse style={{ width: "100%", background: "transparent" }} bordered={false}
                                expandIcon={({ isActive }) => isActive ? <BiMinus size='0.875rem' color='var(--primary)' /> : <BiPlus size='0.875rem' color='var(--primary)' />}
                                expandIconPosition='start' items={faqs.map((ele, i) => {
                                    return {
                                        key: makeid(i),
                                        label: <span className="textMDtoESM w-500">{ele.title}</span>,
                                        children: <span className="textSMtoESM dark3 w-500">{ele.content}</span>,
                                        style: {
                                            background: "var(--white)",
                                            borderBottom: "1px solid var(--grey3)",
                                            padding: '0.75rem',
                                            borderRadius: "var(--borderRadius)",
                                            borderTop: "none"
                                        }
                                    }
                                })} />
                        </div>
                    </Modal>
                </Card>
            </main>
        </React.Fragment>
    )
}