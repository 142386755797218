import { LoadingOutlined } from '@ant-design/icons';
import moengage from "@moengage/web-sdk";
import { sendTwitterConversion } from "Components/twitterTracking";
import Head from "Layout/Head";
import Logo from "Layout/Logo";
import { togglePricingModal, toggleUserSignedIn, updateUserData } from "Libs/redux/UserAuth/reduxSlice";
import { preferenceQuestions as questions } from 'Libs/utils';
import AdminServices from "Services/api/AdminServices";
import { Dashboard_Portfolio } from "Services/api/FabricatedAPIS";
import React from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router";
import { LoginSignupCarousel } from "./LoginSignupCarousel";

function UserOnBoarding() {

    const [step, setStep] = React.useState(1);
    const [answers, setAnswers] = React.useState({ isNewsletterSubscribed: 1 });
    const user = useSelector((state) => state?.updateUserData?.value);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = React.useState(false);

    const WelcomeView = () => {
        return (
            <>
                <div className="displayXS" style={{ fontWeight: 700, marginTop: "1.25rem" }}>Let's set up your account</div>
                <div className="textMD w-700 mt-2">Welcome to sharpely and thank you for signing up!</div>
                <div className="textMD dark4 mt-2">We would like you to answer some question to set up your preferences. By answering these questions, you'll help us suggest personalized actions and send emails that align with your investment goals and interests. Thank you for taking the time to set up your preferences for a successful investment journey!</div>
            </>
        )
    }

    const QuestionView = () => {

        let question = questions?.find((el) => el?.value === step);

        return (
            <>
                <div className="displayXS" style={{ fontWeight: 700, marginTop: "1.25rem" }}>{question?.heading}</div>
                <div className="textMD mt-2">{question?.subText}</div>
                <div className="d-flex flex-column" style={{ gap: "1rem", marginTop: "1.25rem" }}>
                    {question?.options?.map((opt) => {
                        let questionKey = question?.key
                        return (
                            <div style={{
                                border: answers?.[questionKey] === opt?.value ? "1px solid var(--primary)" : "1px solid var(--grey2)",
                                background: answers?.[questionKey] === opt?.value ? "var(--blueShade)" : "var(--grey4)",
                                padding: "0.5rem 1rem", borderRadius: "var(--borderRadius)", cursor: "pointer",
                                display: "flex", gap: "0.75rem", alignItems: "center"
                            }} onClick={() => {
                                let temp = { ...answers, [questionKey]: opt?.value }
                                setAnswers(temp);
                                console.log(temp)
                            }}>
                                <div style={{
                                    border: answers?.[questionKey] === opt?.value ? "1px solid var(--primary)" : "1px solid var(--dark4)",
                                    background: answers?.[questionKey] === opt?.value ? "var(--primary)" : "var(--white)",
                                    height: "10px", width: "10px", borderRadius: "50%"
                                }}></div>
                                <div className="textSM w-500">{opt?.label}</div>
                            </div>
                        )
                    })}
                </div>
            </>
        )
    }

    const handleSubmit = async () => {
        if (loading) {
            return;
        }
        try {
            setLoading(true);
            let data = {
                clientId: user?.id,
                ...answers
            }
            let res = await AdminServices.editAccount(data)?.then((res1) => {
                return res1;
            })

            if (res?.type === true) {
                moengage.add_user_attribute('Preference1', answers?.['q1Answer']);
                moengage.add_user_attribute('Preference2', answers?.['q2Answer']);
                moengage.track_event("Preference_submitted", {
                    "user_email": user?.email
                })
                let userData = { name: user?.name, email: user?.email, mobile: user?.mobile, isLogged: true, id: user?.id, login_Date: user?.login_Date }
                await Dashboard_Portfolio.getClientData(userData, true).then((res) => {
                    if (res) {
                        dispatch(updateUserData({ ...userData, ...res, mobile: res?.Client[0]?.MobileNumber }))
                        setLoading(false);
                        toast.success('Thank you!');
                        dispatch(togglePricingModal(true))
                        navigate('/stocks/dashboard');
                    }
                })
            } else {
                setLoading(false);
            }
        }
        catch (err) {
            setLoading(false);
            toast.error('Something wennt wrong! Please try again.')
        }
    }

    React.useEffect(() => {
        sendTwitterConversion(user?.email);
        window.dataLayer?.push({ event: 'twitter-trigger' });
        dispatch(toggleUserSignedIn(true));
    }, [])

    if (!location?.state?.hasSignedUp) {
        return <Navigate to='/stocks/dashboard' />
    }
    return (
        <React.Fragment>
            <Head title='User onboarding' />
            <div className='mainContainer loginComponent' style={{ alignItems: "center", gap: 0 }}>
                <div style={{
                    height: "calc(100vh)", width: "65%", display: "flex", alignItems: "center", justifyContent: "center", flexShrink: 0, background: "var(--dark4)", paddingRight: "2rem"
                }} className=''>
                    <LoginSignupCarousel />
                </div>
                <div style={{ height: "calc(100vh)", flex: 1, width: "100%", padding: "0 20px", display: "flex", alignItems: "center", }}>
                    <div style={{ width: "100%", maxHeight: "100%", overflowY: "auto", padding: "20px 0" }}>
                        <Logo type='dark' height="4rem" />
                        {step === 0 ? <WelcomeView /> : <QuestionView />}
                        <div className="d-flex w100" style={{ gap: "1.25rem", marginTop: "1.25rem" }}>
                            {step > 1 && <button className="btnWhite w100 textMD" style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                                onClick={() => setStep(prev => prev - 1)} disabled={loading}>&lt; Previous</button>}
                            {step < questions?.length && <button className="btnBlack w100 textMD" style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                                onClick={() => setStep(prev => prev + 1)}>Next &gt;</button>}
                            {step === questions?.length && <button className="btnBlack w100 textMD" style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                                onClick={() => handleSubmit()} disabled={Object.keys(answers || {})?.length !== questions?.length}>
                                {loading ? <LoadingOutlined style={{ fontSize: '1rem', color: "var(--white)" }} spin /> : <span> Submit &gt;</span>}
                            </button>}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default UserOnBoarding;