import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import { checkIfSameMonthDate } from 'Components/DateFormatter';
import { generateUniqueKey, sortArrObject } from 'Libs/utils';
import apiHelper from "Services/api/ApiHelper";
import apiHelperPY from "Services/api/ApiHelperPY";
import { Segmented, notification, Divider } from "antd";
import React from "react";
import { FaPlay } from "react-icons/fa";
import { MdAddCircle, MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { QueryViewerBasic } from "../Screener/QueryViewerBasic";
import AdminServicesPY from "Services/api/AdminServicesPY";
import { NameInput } from "Components/atoms/NameInput";

export const AlertBuilder = ({ fundData = {}, allFuncAndMetricForDrawer = [], allFundsData = [], type, functions, screenerMetrics = [], indices = [], selectedAlert = {}, updateStrategyParams = () => { }, saveAlert = () => { }, }) => {

    const user = useSelector((state) => state?.updateUserData?.value)
    const [api, contextHolder] = notification.useNotification();
    const [alertName, setAlertName] = React.useState(selectedAlert?.alert_name || '')
    const openNotificationWithIcon = (type, title, desc, btn = null) => {
        const key = `open${Date.now()}`;
        // const btn = (
        //     <Space>
        //         <Button type="primary" size="small" onClick={() => api.destroy(key)}>
        //             Confirm
        //         </Button>
        //     </Space>
        // );
        api[type]({
            message: title,
            description: desc,
            placement: "topRight",
            key,
            btn
        });
    };

    let last3Year1StDate = new Date();
    last3Year1StDate.setFullYear(last3Year1StDate.getFullYear() - 3);

    const sortOptions = [
        {
            value: 'and',
            queryStr: 'and',
            label: 'AND',
            // className: 'segmentedAnd'
        },
        {
            value: 'or',
            queryStr: 'or',
            label: 'OR',
            // className: 'segmentedOr'
        }
    ]
    // const type = useGetAssetType();
    const initialGlobals = {
        universe: {
            name: "universe",
            isGlobal: true,
            expression: "all",
            expression_arr: "all"
        },
        exchange: {
            name: "exchange",
            isGlobal: true,
            expression: "All",
            expression_arr: "All"
        }
    }
    const [globals, setGlobals] = React.useState(initialGlobals);
    const [timeframe, setTimeframe] = React.useState('1d');
    const [conditions, setConditions] = React.useState(selectedAlert?.conditions || {})
    const [conditions_oper, setConditionsOper] = React.useState(selectedAlert?.conditions_oper || [])
    const [variables, setVariables] = React.useState({})
    const [lastFocusedBlock, setlastFocusedBlock] = React.useState("0_condition");
    const [isCondBlockActive, setIsCondBlockActive] = React.useState(false);
    const elementToFocus = React.useRef();
    const [focusElement, setFocusElement] = React.useState('');
    const [variableModal, setVariableModal] = React.useState(false);
    const [tableLoading, setTableLoading] = React.useState(false);
    const [screenedOnce, setScreenedOnce] = React.useState(false);
    const [allPublishedScreens, setallPublishedScreens] = React.useState([]);

    const [loading, setLoading] = React.useState(true);


    React.useEffect(() => {
        if (elementToFocus.current) {
            elementToFocus.current.focus();
        }
    }, [focusElement])

    const [openBasicDrawer2, setopenBasicDrawer2] = React.useState(false)

    const ConditionViewer = () => {
        const [openBasicDrawer, setopenBasicDrawer] = React.useState(openBasicDrawer2 || false)
        const allConditions = Object?.keys(conditions)
        ////console.log("DSADASD", allConditions)

        if (allConditions?.length === 0) {
            return <div style={{ boxShadow: '0.1px 0.1px 10px 0px var(--grey1)', padding: "1rem", borderRadius: 4, backgroundColor: "var(--white)" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div className="textMD w-500">Start building your alert condition</div>
                    <button className="btnWhite w-bold d-flex align-items-center" style={{
                        gap: "0.5rem",
                        alignItems: "center",
                        alignContent: "center"
                    }} onClick={() => {

                        setopenBasicDrawer(true)


                        // setConditionsOper([
                        //     ...conditions_oper,
                        //     "and"
                        // ])
                    }}><MdAddCircle /> Add rule</button>

                </div>
                <QueryViewerBasic fromAlert={true} allFundsData={allFundsData} isVisible={false} openBasicDrawer={openBasicDrawer} type={type} allFuncAndMetricForDrawer={allFuncAndMetricForDrawer} isAdvance={false} timeframe={timeframe} isCondition={true} variables={variables} functions={functions} screenerMetrics={screenerMetrics} initialQueries={{}}
                    // lastindx={lastEditingIndex}
                    toggleBasicDrawer={(e) => {
                        setopenBasicDrawer(false)
                        setopenBasicDrawer2(false)
                    }}
                    onQueriesUpdate={(queries) => {
                        if (Object?.keys(queries)?.length > 0) {
                            setConditions({
                                [`${Object.keys(conditions)?.length}_condition`]: {
                                    queries: {
                                        ...queries
                                    }
                                }
                            })
                        }
                        setopenBasicDrawer(false)
                        setopenBasicDrawer2(false)
                        // var condition = conditions[cond]
                        // setConditions({
                        //     ...conditions,
                        //     [cond]: {
                        //         ...condition,
                        //         queries: {
                        //             ...queries
                        //         }
                        //     }
                        // })
                        // setLastEditingIndex(lastEditingIndex)
                    }}
                    // isFocused={lastFocusedBlock === cond && isCondBlockActive}
                    // funcToCallOnWrapClick={() => {
                    //     if (!isCondBlockActive) {
                    //         setIsCondBlockActive(true);
                    //     }
                    //     if (lastFocusedBlock !== cond) {
                    //         setlastFocusedBlock(cond)
                    //     }
                    // }} onCreateVariable={() => {
                    //     setVariableModal(true);
                    // }}
                    indices={indices} />
            </div>
        } else {
            return <div style={{
                marginBottom: 20, gap: "1.25rem"
            }}>
                <div className="textMD w-500 mb-3">Rules ({allConditions?.filter((i) => Object?.keys(conditions?.[i]?.queries)?.length)?.length})</div>

                <div style={{}}>
                    {allConditions?.map((cond, index) => {

                        let thisCondition = conditions?.[cond];
                        let conditionBlockWrapper = conditions?.[cond]?.conditionBlockWrapper || {};
                        var len = Object.keys(conditions?.[cond]?.queries)?.length
                        // //console.log("akakak",conditions_oper?.[index - 1])
                        return <div style={{ gap: 10, display: len ? "unset" : "none" }} key={generateUniqueKey(cond)}>
                            {index > 0 && <div className="my-3">
                                <Segmented
                                    className={conditions_oper?.[index - 1] === "and" ? 'textSM purple ANDTabs' : "textSM purple ORTabs"}
                                    options={sortOptions}
                                    onChange={(value) => {
                                        var cond_oper = [...conditions_oper]
                                        cond_oper[index - 1] = value
                                        setConditionsOper(cond_oper)
                                    }}
                                    defaultValue={conditions_oper?.[index - 1] || "and"}
                                />
                            </div>
                            }
                            <div style={{ display: "flex", flexDirection: "column", gap: 0, }}>
                                <div style={{
                                    display: "flex", flexDirection: "row", alignItems: "center", gap: 20, border: "1px solid var(--grey3)", padding: '0.5rem',
                                    borderRadius: "0.2rem", backgroundColor: "var(--grey4)"
                                }}>
                                    <QueryViewerBasic fromAlert={true} allFundsData={allFundsData} openBasicDrawer={openBasicDrawer} type={type} allFuncAndMetricForDrawer={allFuncAndMetricForDrawer} isAdvance={false} timeframe={timeframe} isCondition={true} variables={variables} functions={functions} screenerMetrics={screenerMetrics} initialQueries={conditions[cond]?.queries || {}}
                                        toggleBasicDrawer={() => {
                                            setopenBasicDrawer(false)
                                            setopenBasicDrawer2(false)
                                        }}
                                        // lastindx={lastEditingIndex}
                                        onQueriesUpdate={(queries) => {
                                            if (Object.keys(queries)?.length) {
                                                var condition = conditions[cond]
                                                setConditions({
                                                    ...conditions,
                                                    [cond]: {
                                                        ...condition,
                                                        queries: {
                                                            ...queries
                                                        }
                                                    }
                                                })
                                            } else {
                                                var q = { ...conditions }
                                                let keys = Object.keys(conditions)
                                                let temp = {};
                                                for (let key in conditions) {
                                                    if (key !== cond) {
                                                        temp[Object.keys(temp)?.length + '_condition'] = conditions[key]
                                                    }
                                                }
                                                setConditions(temp);
                                                if (keys?.length > 0) {
                                                    var co_op = [...conditions_oper]
                                                    co_op?.splice(index - 1, 1)
                                                    setConditionsOper(co_op)
                                                }
                                                if (Object.keys(temp)?.length === 0) {
                                                    setScreenedOnce(false)
                                                }
                                            }

                                            setopenBasicDrawer(false)
                                            setopenBasicDrawer2(false)
                                            // setLastEditingIndex(lastEditingIndex)
                                        }} isFocused={lastFocusedBlock === cond && isCondBlockActive} funcToCallOnWrapClick={() => {
                                            if (!isCondBlockActive) {
                                                setIsCondBlockActive(true);
                                            }
                                            if (lastFocusedBlock !== cond) {
                                                setlastFocusedBlock(cond)
                                            }
                                        }} onCreateVariable={() => {
                                            setVariableModal(true);
                                        }} indices={indices} />

                                    {index > 0 && <div className='d-flex justify-content-end align-items-center' style={{ gap: '1rem', flex: 1 }}>
                                        <div title="Delete" className='d-flex justify-content-center align-items-center textSM w-500' style={{ color: "var(--dark2)", cursor: "pointer", gap: 2 }} onClick={() => {
                                            var q = { ...conditions }
                                            let keys = Object.keys(conditions)
                                            let temp = {};
                                            for (let key in conditions) {
                                                if (key !== cond) {
                                                    temp[Object.keys(temp)?.length + '_condition'] = conditions[key]
                                                }
                                            }
                                            setConditions(temp);
                                            if (keys?.length > 0) {
                                                var co_op = [...conditions_oper]
                                                co_op?.splice(index - 1, 1)
                                                setConditionsOper(co_op)
                                            }
                                        }}>
                                            <MdDelete /></div>
                                    </div>}
                                </div>
                                {/* <QueryViewer2 key={cond} condition_key={cond} condition={conditions[cond]} cond_index={index} /> */}


                            </div>

                        </div>
                    })}


                    <div className="my-4" style={{ display: "flex", gap: 10, justifyContent: "flex-start" }}>
                        <button className="btnWhite w-bold d-flex align-items-center" style={{
                            gap: "0.5rem",
                            alignItems: "center",
                            alignContent: "center"
                        }} onClick={() => {
                            setConditions({
                                ...conditions,
                                [`${Object.keys(conditions)?.length}_condition`]: {
                                    queries: {}
                                }
                            })
                            setConditionsOper([
                                ...conditions_oper,
                                "and"
                            ])
                            setTimeout(() => {
                                setopenBasicDrawer2(true)
                            }, 200);
                        }}><MdAddCircle /> Add another rule</button>
                    </div>
                </div>
            </div>
        }

    }

    const [code, setCode] = React.useState("")

    const expChecker = (exp, queryData, key) => {
        //console.log(logg, queryData, key)

        // if(queryData?.type === "variable"){
        //     var nD = variables[key]?.name
        //     return nD
        // }else 
        if (String(exp)?.match(/by\s{0,3}=\s{0,2}/)) {
            var showPeriod = queryData?.expression_arr?.map(element => {
                return [`"MKT"`, `"SEC"`, `"INDGRP"`, `"IND"`].concat(indices?.map((item) => `"${item?.IndexNameCaps}"`)).filter(str => element.includes(str))?.length > 0
            });
            showPeriod = showPeriod?.filter((i) => i === false)?.length === queryData?.expression_arr?.length
            queryData = {
                ...queryData,
                expression_arr: !showPeriod ? queryData?.expression_arr?.filter(element => {
                    return !element.match(/period\s{0,3}=/)
                }) : queryData?.expression_arr
            }


            return (`${key?.replaceAll(/\d{1,4}_/g, '')}(${queryData?.expression_arr?.join(", ")})`)?.trim()
        }
        if (Object.keys(queryData)?.includes("patternOutput")) {
            return exp + " == " + queryData?.patternOutput
        }
        if (String(key)?.includes("Operator"))
            return (`${queryData?.expression_arr}`)?.toLowerCase()?.trim()
        return exp
    }

    const multiExpConvertor = (exp, queryData, key) => {
        let itemStr = queryData?.expression_arr?.map((i) => { return isNaN(i) ? `"${i}"` : i })?.join(', ')
        //////////console.log(logg, 123, queryData, itemStr)
        return `${queryData?.expression_details?.value} ${queryData?.comparator} [${itemStr}]`
    }

    const customCompConvertor = (exp, queryData, key) => {
        let itemStr = queryData?.expression_arr?.map((i) => { return i })?.join(', ')
        //////////console.log(logg, 123, queryData, itemStr)
        return `${queryData?.expression_details?.value} [${itemStr}]`
    }

    const finalCode = (vvl = 0) => {
        try {
            var output = ""
            var output_upfront = ""
            var code = []
            var code_f_key = ""

            ////////console.log("heyeA", conditions)
            // var code_vars = variables
            //variables
            for (let index = 0; index < Object?.keys(globals).length; index++) {
                if (Object?.keys(globals)?.[index] === "exclusion_list") {
                    code?.push(`${Object?.keys(globals)?.[index]} = is_exclusion_list == ${globals[Object?.keys(globals)?.[index]]?.expression};`)
                    output_upfront = "exclusion_list"
                }
                else if (String(globals[Object?.keys(globals)?.[index]]?.expression)?.toLowerCase() !== "all")
                    code?.push(`${Object?.keys(globals)?.[index]} = "${globals[Object?.keys(globals)?.[index]]?.expression}";`)
            }

            // for (let index = 0; index < Object?.keys(variables).length; index++) {
            //     code?.push(`${Object?.keys(variables)?.[index]} = ${variables[Object?.keys(variables)?.[index]]?.expression};`)
            // }

            var variable_keys = Object.keys(sortArrObject(variables)) || []
            for (let index = 0; index < variable_keys?.length; index++) {

                Object.filter = (obj, predicate) => {
                    return Object.fromEntries(Object.entries(obj).filter(predicate))
                };

                var conditionString = JSON.stringify(conditions)
                var variableString = JSON.stringify(Object.filter(variables, ([varname, value]) => value?.isGlobal !== true && varname !== variable_keys[index]))
                const regexPattern = new RegExp(`\\b(${variables[variable_keys[index]]?.name})\\b`, 'g');

                var adding = false


                if (variableString?.match(regexPattern)?.length > 0 || conditionString?.match(regexPattern)?.length > 0) {
                    adding = true
                }

                if (adding === true) {
                    const cond = variable_keys[index];
                    // //////////////////console.log(logg, cond)
                    const cond_queries = variables[cond]?.queries;
                    var cond_queries_keys = Object.keys(cond_queries) || []
                    var queryExp = []
                    for (let index = 0; index < cond_queries_keys.length; index++) {
                        const query_key = cond_queries_keys[index];
                        // //////////console.log(logg, 123, query_key, cond_queries[query_key])
                        const query_exp = cond_queries[query_key]?.type === "metric" ? cond_queries[query_key]?.isMultiple ? multiExpConvertor(cond_queries[query_key]?.expression, cond_queries[query_key], query_key) : cond_queries[query_key]?.expression_details?.metric_col_code : expChecker(cond_queries[query_key]?.expression, cond_queries[query_key], query_key)
                        if ((index === cond_queries_keys?.length - 1) && String(query_key)?.includes("Operator")) {
                            return `Error: Variable '${variables[cond]?.name}' cannot ends with comparators or operators.`
                        } else if ((index <= cond_queries_keys?.length - 1) && String(cond_queries[query_key]?.expression) === "Add") {
                            return `Error: '${variables[cond]?.name}' is not a valid variable.`
                        } else if ((index == cond_queries_keys?.length - 1) && String(cond_queries[query_key]?.expression) === "Add") {

                        } else {
                            queryExp.push(query_exp)
                        }
                    }
                    var queriee = queryExp?.join(" ")
                    if (variables[cond]?.conditionBlockWrapper) {
                        code?.push(`${variables[cond]?.name} = ${variables[cond]?.conditionBlockWrapper?.type}((${queriee}),${variables[cond]?.conditionBlockWrapper?.noOfBars});`)
                    } else {
                        code?.push(`${variables[cond]?.name} = ${queriee};`)
                    }
                }


                // if (index > 0)
                //     code_f_key += " " + conditions_oper[index - 1] + " "

                // code_f_key += "c" + index
                // // code += `${cond} = ${queriee}`
            }


            var condition_keys = Object.keys(sortArrObject(conditions)) || []
            for (let index = 0; index < condition_keys?.length; index++) {
                const cond = condition_keys[index];
                // //////////////////console.log(logg, cond)
                const cond_queries = conditions[cond]?.queries;
                var cond_queries_keys = Object.keys(cond_queries) || []
                var queryExp = []
                if (cond_queries_keys?.length > 0) {
                    for (let index = 0; index < cond_queries_keys.length; index++) {
                        const query_key = cond_queries_keys[index];
                        // //////////console.log(logg, 123, query_key, cond_queries[query_key])
                        const query_exp = cond_queries[query_key]?.type === "metric" ? (cond_queries[query_key]?.isMultiple ?
                            multiExpConvertor(cond_queries[query_key]?.expression, cond_queries[query_key], query_key) :
                            cond_queries[query_key]?.expression_details?.metric_col_code) : cond_queries[query_key]?.isCustomComparator === true ?
                            customCompConvertor(cond_queries[query_key]?.expression, cond_queries[query_key], query_key) :
                            expChecker(cond_queries[query_key]?.expression, cond_queries[query_key], query_key);
                        ////////console.log(query_exp);
                        if ((index === cond_queries_keys?.length - 1) && String(query_key)?.includes("Operator") && !(cond_queries[query_key]?.isCustomComparator === true)) {
                            return "Error: Condition cannot ends with comparators or operators."
                        } else if ((index <= cond_queries_keys?.length - 1) && String(cond_queries[query_key]?.expression) === "Add") {
                            return "Error: Not a valid condition."
                        } else if ((index == cond_queries_keys?.length - 1) && String(cond_queries[query_key]?.expression) === "Add") {

                        } else {
                            queryExp.push(query_exp)
                        }
                    }
                    var queriee = queryExp?.join(" ")
                    if (conditions[cond]?.conditionBlockWrapper) {
                        code?.push(`c${index} = ${conditions[cond]?.conditionBlockWrapper?.type}((${queriee}),${conditions[cond]?.conditionBlockWrapper?.noOfBars});`)
                    } else {
                        code?.push(`c${index} = ${queriee};`)
                    }



                    if (index > 0)
                        code_f_key += " " + conditions_oper[index - 1] + " "

                    code_f_key += "c" + index
                }
                ////////console.log(queryExp)
                // code += `${cond} = ${queriee}`
            }

            if (code?.length > 0) {
                output = code?.join("\n")
                output = output + "\n"
                output += (code_f_key?.length === 0 && output_upfront?.length === 0) ? "output = 1;" : `output = ${output_upfront}${code_f_key?.length > 1 && output_upfront?.length > 1 ? " and " : ""}${code_f_key};`
                output = output?.replace("Add", "")?.replaceAll(" (", "(")
                ////console.log(output)
                if (vvl === 1)
                    setCode(output)
                return output
            } else {
                return "Error: Condition block is empty"
            }
        } catch (error) {
            ////////console.log(error)
            return "Error: Not a valid condition"
        }

        //////////////////console.log(logg, output)
    }

    const [screenedStocksData, setScreenedStocksData] = React.useState({})
    const [screenedCols, setScreenedCols] = React.useState([])

    const getScreenedStocks = async (isReplay = false, oriCode, sortAndLimit = null) => {
        try {
            setTableLoading(true);
            let output = oriCode || finalCode()

            console.log(output)
        } catch (error) {
            setScreenedOnce(false);
            setScreenedStocksData({})
            setScreenedCols([])
            setTableLoading(false)
        }
    }


    const loadScreen = async () => {
        setScreenedOnce(false);
        setScreenedStocksData({});
        setScreenedCols([]);
        setLoading(false);
    }

    React.useEffect(() => {
        loadScreen();
    }, []);

    // React.useEffect(() => {
    //     if (strategy_fundD) {
    //         var a = finalCode()
    //         updateStrategyParams(a)
    //     }
    // }, [variables, globals, conditions, conditions_oper])



    if (loading)
        return <React.Fragment>
            <div className='loaderContainer'>
                <ActivityLoaderMintbox showQuote={true} />
            </div>
        </React.Fragment>
    return (
        <>
            {contextHolder}
            <div className="p-4" style={{ display: "flex", flexDirection: "column", gap: "1rem", flex: 1, maxHeight: "85vh", overflowY: "scroll" }}>
                <div className="w50">
                    <NameInput label='Alert name' placeholder='Enter your alert name' value={alertName} onChange={(e) => {
                        setAlertName(e);
                    }} />
                </div>
                <div className="textMD w-500 mt-2">Alert me when the following conditions are met</div>
                <div style={{ width: "100%" }}>
                    <ConditionViewer />
                </div>
            </div>
            <Divider className="my-2" />
            <div className="px-4" style={{ display: "flex", justifyContent: "flex-end" }}>
                <button onClick={async () => {
                    if (alertName?.length >= 2) {
                        let code = finalCode();
                        if (!code?.includes("Error:")) {
                            var res = await AdminServicesPY.evaluateAlertCondition({ code: code, symbol: fundData?.symbol }).then(r => r)
                            res = res?.data
                            if (res?.satisfies_condition_now === 0) {
                                saveAlert(code, alertName)
                            } else {
                                openNotificationWithIcon("info", "Condition already satisfied", `${fundData?.proper_name} already satisfies your condition. Please change the condition to save the alert.`)
                            }
                        } else {
                            openNotificationWithIcon("error", "Error", code?.replace("Error: ", ""))
                        }
                    } else {
                        openNotificationWithIcon("error", "Error", "Enter proper alert name (min. 2 char)")
                    }
                }} className="btnBlack">Save</button>
            </div>
        </>

    )
}