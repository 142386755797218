import { Card, Skeleton } from "antd";
import { getKnowledgeBaseData, getNextPrevBlog } from "Components/getKnowledgeBaseData";
import ResourceSidebar2 from "Components/ResourceSidebar2";
import Head from "Layout/Head";
import { formatDateMMMDDYYYY } from "Components/DateFormatter";
import React, { useEffect, useState } from "react";
import { FcNext, FcPrevious } from 'react-icons/fc';
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import AdminServices from "Services/api/AdminServices";

const Resource = () => {

    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [blog, setBlog] = useState({});
    const [content, setContent] = useState([]);
    const [prevNext, setPrevNext] = useState({});
    const [allLoaded, setAllLoaded] = useState(false);
    const [blogs, setBlogs] = useState([]);
    let isDesktop = window.innerWidth > 760;

    console.log(params);

    const loadContent = async (url) => {
        let res = await AdminServices.readBlogFile(url);
        if (res.type) {
            setContent(res.data);
        }
        else setContent('');
    }

    const loadData = async () => {
        let res = await getKnowledgeBaseData();
        if (res.type) {
            setBlogs(res.blogs || [])
        }
    }

    const loadBlog = async () => {
        let it = blogs?.filter(item => item?.Category?.toLowerCase().split(' ').join('-') === params?.category?.toLowerCase() &&
            item?.SubCategory.toLowerCase().split(' ').join('-') === (params.subCategory?.toLowerCase() || '') &&
            item?.URL?.toLowerCase()?.split('/')?.[2]?.replaceAll('_', '-') === params.blog?.toLowerCase())?.[0] || {}
        setBlog(it)
        await loadContent(it?.URL?.toLowerCase()?.split('/')?.[2]);
        let prne = await getNextPrevBlog(params.blog);
        setPrevNext(prne);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        loadData();
    }, []);

    useEffect(() => {
        if (blogs.length) {
            setLoading(true);
            loadBlog();
        }
    }, [params, blogs]);

    useEffect(() => {
        if (!loading) {
            document.getElementById('resource-container').innerHTML = content;
            setTimeout(() => {
                const anchorTags = document.getElementsByTagName("a");
                for (let i = 0; i < anchorTags.length; i++) {
                    const anchor = anchorTags[i];
                    const href = anchor.getAttribute("href");
                    let modifiedHref = href;
                    if (modifiedHref?.startsWith("https://beta.sharpely") || modifiedHref?.startsWith("https://dev.sharpely")) {
                        modifiedHref = modifiedHref?.replace(/\b(beta\.sharpely|dev\.sharpely)\b/g, 'sharpely')
                    }
                    if (modifiedHref?.startsWith("/knowledge-base") || modifiedHref?.startsWith("https://sharpely.in/knowledge-base")) {
                        modifiedHref = modifiedHref?.replace(/\.html/g, "")?.replace(/_/g, "-");
                    }
                    anchor.setAttribute("href", modifiedHref);
                }
            }, 100)
        };
    }, [loading, content])

    const Loader = () => {
        return (
            <>
                <Card bodyStyle={{ padding: isDesktop ? "2rem" : "1.25rem" }} headStyle={{ padding: isDesktop ? "1.25rem 2rem" : "1.25rem" }} className='mobCardBorderRadius0'
                    title={<div>
                        <Skeleton.Button style={{ height: "2rem", width: "30rem", maxWidth: "100%" }} active />
                        <br />
                        <Skeleton.Button style={{ height: "1rem", width: "15rem", maxWidth: "100%", marginTop: "1rem" }} active />
                    </div>}>
                    <div>
                        <Skeleton active paragraph={{ rows: 4 }} style={{ marginBottom: "1.25rem" }} />
                        <Skeleton active paragraph={{ rows: 4 }} />
                    </div>
                </Card>
            </>
        )
    }
    let blogTitle = params.blog?.replaceAll("-", " ")?.toLowerCase();
    return (
        <React.Fragment>
            <Head title={(blogTitle?.[0]?.toUpperCase() + blogTitle?.slice(1)) || "Knowledge base resource"} description={blog?.Description || ""} />
            <div className="mainContainer kbResource" style={{ height: "100%" }}>
                <ResourceSidebar2 />
                {loading ?
                    <div className='leftContainer'>
                        <Loader />
                    </div>
                    :
                    <div className="leftContainer">
                        <Card bordered={false} title={
                            <div>
                                <h1 className='black' style={{ textOverflow: "unset", whiteSpace: "unset", display: "inline-block", whiteSpace: 'initial', }} >{blog.Title}</h1>
                                <div style={{ color: "var(--dark3)" }} className='w-500 textSM'>
                                    by <span style={{ color: "var(--black)" }}>{blog.Author}</span> {formatDateMMMDDYYYY(blog?.UpdatedOn)}
                                </div>
                            </div>
                        } bodyStyle={{ padding: isDesktop ? "2rem" : "1.25rem" }} headStyle={{ padding: isDesktop ? "1.25rem 2rem" : "1.25rem 1.25rem" }} className='mobCardBorderRadius0'>
                            <div id='resource-container' className="ql-editor textMD" style={{ padding: '0px', height: "auto" }}>
                            </div>
                        </Card>
                        <Card bordered={false} bodyStyle={{ padding: isDesktop ? "2rem" : "1.25rem" }} className='mobCardBorderRadius0'>
                            <div className='d-flex justify-content-between prevNextCont'>
                                {prevNext?.prev && (
                                    <Link to={prevNext?.prev?.Slug} >
                                        <div >
                                            <FcPrevious size='0.8rem' /> <span className='w-500 textXS'>PREVIOUS ARTICLE</span>
                                        </div>
                                        <h2 className={`${isDesktop ? "textXL" : "textLG"} w-700 margin0`} style={{ display: "inline-block", whiteSpace: 'initial' }}>
                                            {prevNext?.prev?.Title}
                                        </h2>
                                    </Link>
                                )}
                                {prevNext.next && (
                                    <Link to={prevNext?.next?.Slug} >
                                        <div>
                                            <span className='w-500 textXS'>NEXT ARTICLE</span> <FcNext size='0.8rem' />
                                        </div>
                                        <h2 className={`${isDesktop ? "textXL" : "textLG"} w-700 margin0`} style={{ display: "inline-block", whiteSpace: 'initial' }}>
                                            {prevNext?.next?.Title}
                                        </h2>
                                    </Link>
                                )}
                            </div>
                        </Card>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default Resource;