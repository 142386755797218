import { noDataFoundIllustration, noSearchResult } from 'Assets/images';
import React from "react";
import apiHelperPY from "Services/api/ApiHelperPY";

export default function NoBackTestData({ val }) {

    const [noData, setNoData] = React.useState('');

    const loadData = async () => {
        let noBacktestData = await apiHelperPY({
            apiName: "getBacktestNoData", data: val
        })
        setNoData(noBacktestData);
    }

    React.useEffect(() => {
        loadData();
    }, []);

    return (
        <React.Fragment>
            <div className='d-flex flex-column' style={{ gap: "1rem", marginTop: "1.25rem" }} >
                <img loading="lazy"  alt="" src={noSearchResult} style={{ width: "10rem", margin: "auto" }} />
                <div dangerouslySetInnerHTML={{ __html: noData }} style={{ flex: 1, marginTop: "0.75rem" }} className='textSM black noBacktestData' >

                </div>
            </div>
        </React.Fragment>
    )
}