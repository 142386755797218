import { checkIfSameMonthDate } from "Components/DateFormatter";
import { getUserAccessLimitFromAccessObj } from "Components/SubscribeModal";
import { useGetAssetType } from "Libs/utils";
import AdminServices from "Services/api/AdminServices";
import apiHelper from "Services/api/ApiHelper";
import apiHelperPY from "Services/api/ApiHelperPY";
import { useSelector } from "react-redux";

export const useUserDataHooks = () => {

    const user = useSelector((state) => state?.updateUserData?.value);
    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    const userAccessLevel = user?.Client?.[0]?.SharpelyAccessLevel;
    const stockType = useGetAssetType(4);

    const getUserStrategies = async () => {
        let temp = { userStrategies: [], clonedStrategiesInMonth: [] }
        try {
            let result = { ...temp };
            if (user?.isLogged) {
                let res = await apiHelperPY({ apiName: "getAllStrategiesByClientId", data: { clientId: user.id }, getFresh: true })
                if (res && res?.length > 0) {
                    let data = res;
                    data = JSON.parse(data?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null)) || [];
                    // console.log(data)
                    let accessObj = accessLevelData?.find((el) => el?.ModuleKey?.trim() === "BUILD,_BACKTEST_AND_PAPERTRADE") || {}
                    let userLimit = getUserAccessLimitFromAccessObj({ accessObj, accessLevel: userAccessLevel, limitIndex: 0 });
                    let clonedStrategies = [];
                    data = data?.map((item, i) => {
                        let updItem = {
                            ...item,
                            is_locked: Number(userLimit < i + 1)
                        }
                        if (updItem?.config?.cloned_from) {
                            let c = checkIfSameMonthDate(new Date(), updItem?.config?.go_live_timestamp);
                            if (c) {
                                clonedStrategies.push(updItem);
                            }
                        }
                        return updItem;
                    })
                    result.userStrategies = data;
                    result.clonedStrategiesInMonth = clonedStrategies;
                }
            }
            return result;
        }
        catch (err) {
            return temp;
        }
    }

    const getUserStockModels = async ({ getFresh = false } = {}) => {
        let temp = { userModels: [] }
        try {
            let result = { ...temp };
            if (user?.isLogged) {
                let signals = await apiHelper({ apiName: "getUserSignal", saveResponse: "sessionStorage", data: { id: user?.id, type: stockType.omkar }, getFresh: getFresh });
                if (signals && Array.isArray(signals) && signals?.length > 0) {
                    signals = signals?.filter((el) => el?.is_deleted === 0);
                    let accessObj = accessLevelData?.find((el) => el?.ModuleKey?.trim() === "SAVE_MODELS_AND_SET_ALERTS") || {}
                    let userLimit = getUserAccessLimitFromAccessObj({ accessObj, accessLevel: userAccessLevel, limitIndex: 0 });
                    signals = signals?.map((item, i) => {
                        let updItem = {
                            ...item,
                            is_locked: Number(userLimit < i + 1)
                        }
                        return updItem;
                    })
                    result.userModels = signals;
                }
            }
            return result;
        }
        catch (err) {
            console.log(err);
            return temp;
        }
    }

    const getUserSuperScreens = async () => {
        let temp = { userScreens: [], clonedScreensInMonth: [] }
        try {
            let result = { ...temp };
            if (user?.isLogged) {
                let ss = await apiHelper({ apiName: "getAllSuperScreens", saveResponse: "sessionStorage", data: user?.id });
                if (ss && Array.isArray(ss) && ss?.length > 0) {
                    ss = ss?.filter((el) => el?.is_deleted === 0);
                    let accessObj = accessLevelData?.find((el) => el?.ModuleKey?.trim() === "SAVE_SCREEN_AND_SET_ALERTS") || {}
                    let userLimit = getUserAccessLimitFromAccessObj({ accessObj, accessLevel: userAccessLevel, limitIndex: 0 });
                    let clonedScreens = [];
                    ss = ss?.map((item, i) => {
                        let updItem = {
                            ...item,
                            is_locked: Number(userLimit < i + 1)
                        }
                        let config = updItem?.config;
                        // console.log(config)
                        if (config !== null && config !== undefined && config !== '' && config) {
                            config = typeof (config) === 'string' ? String(config)?.substring(1, String(config)?.length - 1) : JSON.stringify(config);
                            config = JSON.parse(config);
                            if (typeof (config) === 'string') {
                                config = JSON.parse(config);
                            }
                            if (config?.parent_screen_id) {
                                let c = checkIfSameMonthDate(new Date(), updItem?.created_on);
                                if (c) {
                                    clonedScreens.push(updItem);
                                }
                            }
                        }
                        return updItem;
                    })
                    result.userScreens = ss;
                    result.clonedScreensInMonth = clonedScreens;
                }
            }
            return result;
        }
        catch (err) {
            console.log(err);
            return temp;
        }
    }

    const getUserScreens = async ({ type }) => {
        let temp = { userScreens: [] }
        try {
            let result = { ...temp };
            if (user?.isLogged) {
                let res = await apiHelper({ apiName: "getUserScreeners", saveResponse: "sessionStorage", data: { id: user?.id, type: type?.strVal }, }) || [];
                if (res.length > 0) {
                    let accessObj = accessLevelData?.find((el) => el?.ModuleKey?.trim() === (type?.value === 4 ? "SAVE_SCREEN_AND_SET_ALERTS" : type?.value === 1 ? "SAVE_AND_TRACK_ETF_SCREENS" : type?.value === 2 ? "SAVE_AND_TRACK_MF_SCREENS" : "")) || {}
                    let userLimit = getUserAccessLimitFromAccessObj({ accessObj, accessLevel: userAccessLevel, limitIndex: 0 });
                    var n = [...new Set([...res].map((item) => {
                        return {
                            "checklist_id": item.checklist_id,
                            "client_id": item.client_id,
                            "show_as_screen": item.show_as_screen,
                            "show_as_checklist": item.show_as_checklist,
                            "checklist_name": item.checklist_name,
                            "checklist_desc": item.checklist_desc,
                            "is_deleted": item.is_deleted,
                            "created_on": item.created_on,
                        }
                    }))]
                    n = Array.from(new Set(n.map(JSON.stringify))).map(JSON.parse)
                    n = n.map((screen, i) => {
                        return {
                            ...screen,
                            rules: res?.filter((i) => i?.checklist_id === screen?.checklist_id),
                            is_locked: Number(userLimit < i + 1)
                        }
                    })
                    result.userScreens = n;
                }
            }
            return result;
        }
        catch (err) {
            console.log(err);
            return temp
        }
    }

    const getUserChecklists = async ({ type }) => {
        let temp = { userChecklists: [] }
        try {
            let result = { ...temp };
            if (user?.isLogged) {
                let res = await apiHelper({ apiName: "getUserScreeners", saveResponse: "sessionStorage", data: { id: user?.id, type: type?.strVal }, }) || [];
                if (res.length > 0) {
                    let accessObj = accessLevelData?.find((el) => el?.ModuleKey?.trim() === (type?.value === 4 ? "STOCK_SAVE_CHECKLIST" : type?.value === 1 ? "SAVE_AND_TRACK_ETF_SCREENS" : type?.value === 2 ? "SAVE_AND_TRACK_MF_SCREENS" : "")) || {}
                    let userLimit = getUserAccessLimitFromAccessObj({ accessObj, accessLevel: userAccessLevel, limitIndex: 0 });
                    var n = [...new Set([...res].map((item) => {
                        return {
                            "checklist_id": item.checklist_id,
                            "client_id": item.client_id,
                            "show_as_screen": item.show_as_screen,
                            "show_as_checklist": item.show_as_checklist,
                            "checklist_name": item.checklist_name,
                            "checklist_desc": item.checklist_desc,
                            "is_deleted": item.is_deleted,
                            "created_on": item.created_on,
                        }
                    }))]
                    n = Array.from(new Set(n.map(JSON.stringify))).map(JSON.parse)
                    n = n?.filter((el) => el?.show_as_checklist === 1);
                    n = n.map((screen, i) => {
                        return {
                            ...screen,
                            rules: res?.filter((i) => i?.checklist_id === screen?.checklist_id),
                            is_locked: Number(userLimit < i + 1)
                        }
                    })
                    result.userChecklists = n;
                }
            }
            return result;
        }
        catch (err) {
            console.log(err);
            return temp
        }
    }

    const getUserCloneItems = async () => {
        let temp = {
            screens: {
                allClonedScreens: [],
                clonedScreensInMonth: []
            },
            factorModels: {
                allClonedModels: [],
                clonedModelsInMonth: []
            },
            strategies: {
                allClonedStrategies: [],
                clonedStrategiesInMonth: []
            }
        }
        try {
            let result = { ...temp }
            if (user?.isLogged) {
                let data = await AdminServices.getCloneSetupById({ clientId: user?.id })
                data = data?.data || [];
                for (let i = 0; i < data?.length; i++) {
                    let item = data[i];
                    let isClonedInSameMonth = checkIfSameMonthDate(new Date(), item?.CreatedOn);
                    let mainKey = item?.Type === "SCREEN" ? "screens" : item?.Type === "FM" ? "factorModels" : item?.Type === "STRATEGY" ? "strategies" : "";
                    let allItemKey = item?.Type === "SCREEN" ? "allClonedScreens" : item?.Type === "FM" ? "allClonedModels" : item?.Type === "STRATEGY" ? "allClonedStrategies" : "";
                    let cloneKey = item?.Type === "SCREEN" ? "clonedScreensInMonth" : item?.Type === "FM" ? "clonedModelsInMonth" : item?.Type === "STRATEGY" ? "clonedStrategiesInMonth" : "";
                    result?.[mainKey]?.[allItemKey]?.push(item);
                    if (isClonedInSameMonth) {
                        result?.[mainKey]?.[cloneKey]?.push(item);
                    }
                }
            }
            return result;
        } catch (error) {
            console.log(error);
            return temp;
        }
    }

    return { getUserStrategies, getUserStockModels, getUserSuperScreens, getUserScreens, getUserChecklists, getUserCloneItems }
}