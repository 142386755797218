import { useSelector } from "react-redux";
import { CurrencyFormat } from "./CurrencyFormat";
import { DecimalValueFormat } from "./DecimalValueFormat";

const OHLC = ({ open, high, low, close }) => {
    let percentage = ((close - low) / (high - low)) * 100;
    const color = percentage <= 33 ? 'var(--red)' : percentage >= 66 ? 'var(--green)' : 'var(--yellow)';
    // console.log(color)

    return (
        <div style={{ width: '100%', marginBottom: '1rem' }}>
            {/* {<div className="textXS w-500 black text-center" style={{ marginBottom: '0.5rem' }}>
                52 week high/low</div>} */}
            <div className='d-flex align-items-center justify-content-between textXS w-500 black' style={{ gap: "0.25rem" }}>
                <div className='d-flex flex-column' style={{ flex: 1, textAlign: 'right' }}>
                    <div style={{ whiteSpace: "nowrap" }}>
                        <div className="textXS dark2 w-400" style={{ textAlign: 'center' }}>Low</div>
                        <div style={{ textAlign: 'center' }}>{DecimalValueFormat({ num: low, decimals: 2 })}</div>
                    </div>
                </div>
                {
                    <div className='week52slider' style={{ '--left': percentage + '%', '--color': color }}>
                        <div className='week52slidertrack'>
                        </div>
                        <div className='week52sliderpoint'>
                        </div>
                        {/* <div className='week52slidertext' style={{ whiteSpace: 'nowrap' }}>{DecimalValueFormat({ num: close, decimals: 2 })}</div> */}
                    </div>
                }
                <div className='d-flex flex-column' style={{ flex: 1 }}>
                    <div style={{ whiteSpace: "nowrap" }}>
                        <div className="textXS dark2 w-400" style={{ textAlign: 'center' }}>High</div>
                        <div style={{ textAlign: 'center' }}>{DecimalValueFormat({ num: high, decimals: 2 })}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OHLC