import { baseImgURL } from 'Assets/Constant';
import AccessComponent, { IsAccessable } from 'Components/AccessComponent';
import AccessControlledPage from 'Components/AccessControlledPage';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { CustomDrawerWithTrigger } from 'Components/CustomDrawer';
import CustomSegmentedTab from 'Components/CustomSegmentedTab';
import { convertAnyDatetoDDMMYYYY } from 'Components/DateFormatter';
import Disclaimer from 'Components/Disclaimer';
import InstrumentListTable from 'Components/InstrumentListTable';
import { MetricSearch } from 'Components/MetricSearch';
import NoBackTestData from 'Components/NoBackTestData';
import PerfBenchChart from 'Components/PerfBenchChart';
import ScreenerOrSignalBacktest from 'Components/ScreenerOrSignalBacktest';
import { SubscribeBtnSmall, SubscribeContentWrapper, SubscribeIcon, SubscribeModalText, useSubscribeMiddleware } from 'Components/SubscribeModal';
import { DescriptionInput } from 'Components/atoms/DescriptionInput';
import { NameInput } from 'Components/atoms/NameInput';
import { CardHeader } from 'Components/atoms/SmallComponents';
import { useIsMobile } from 'CustomHooks/useIsMobile';
import { useUserDataHooks } from 'CustomHooks/useUserDataHooks';
import Head from 'Layout/Head';
import { stockExclusionList } from 'Libs/StaticContent';
import { toggleMetricDescModal, toggleUserGuideModal } from "Libs/redux/KnowledgeBase/reduxSlice";
import { toggleloginModal, updateCustomColumns } from 'Libs/redux/UserAuth/reduxSlice';
import { exchanges, generateUniqueKey, getScreenById, investingStylelist, isInvalidTextOrLength, perFilterValues, quantile, queries, risk_box_codes, screenerMetricData, sortFunds, sortOptions, stock_PaidCols, stock_universe, useGetAssetType } from 'Libs/utils';
import BuildCustomPortfolio from 'Pages/CustomPortfolio/AdvCustomStrategy';
import AdminServices from 'Services/api/AdminServices';
import AdminServicesPY from 'Services/api/AdminServicesPY';
import apiHelper from 'Services/api/ApiHelper';
import apiHelperPY, { decodeData } from 'Services/api/ApiHelperPY';
import { Card, Cascader, DatePicker, Drawer, Image, Modal, Popover, Segmented, Select, Switch, Tag, Tooltip, notification } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CurrencyInput from 'react-currency-input-field';
import { toast } from 'react-hot-toast';
import { AiOutlineClose, AiOutlineInfoCircle } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaFilter } from 'react-icons/fa';
import { IoIosArrowDown, IoIosArrowForward, IoIosClose, IoIosCloseCircleOutline } from 'react-icons/io';
import { MdDragIndicator, MdOutlineDeleteOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: "0.5rem 0rem",
    // border: '1px solid var(--grey3)',
    // padding: '0.5rem 0.1rem',
    borderRadius: 5,
    ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
    borderRadius: 5,
    display: "flex",
    flexDirection: "column"
});

const Screener = (props) => {

    const isMobile = useIsMobile();
    const [notifApi, notifContextHolder] = notification.useNotification();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const type = useGetAssetType();
    const [searchParams, setSearchParams] = useSearchParams();
    const screenId = searchParams.get('screenId');
    const screenType = searchParams.get('screenType') || 3;
    const user = useSelector((state) => state?.updateUserData?.value)
    const userAccessLevel = user?.Client?.[0]?.SharpelyAccessLevel || 0
    const screenUserId = Number(searchParams.get('userId'))
    const [currentCheckListRules, setCurrentCheckListRules] = React.useState(undefined);
    const [isEditable, setIsEditable] = React.useState();
    const [intialQuriesVals, setInitialQuerisVals] = useState({
        selectedStockUniverse: [],
        excludeSurveillance: false,
        exchange: 'All'
    })
    const userCustomCols = useSelector((state) => state?.userCustomCols?.userCustomCols);
    const [openInvModal, setOpenInvModal] = useState(false)
    const [saveAsChecklist, setsaveAsChecklist] = useState(true)
    const [alertsEnabled, setalertsEnabled] = useState(false)
    const [screenerMetric, setscreenerMetric] = useState([])
    const [screenerFinalData, setscreenerFinalData] = useState([])
    const [styleBoxes, setStyleBoxes] = React.useState([]);
    const [allFilteredFundsData, setallFilteredFundsData] = useState([])
    const [allFundsData, setallFundsData] = useState([])
    const [selectedCategory, setselectedCategory] = useState("")
    const [selectedMetric, setselectedMetric] = useState("")
    const [disableAddRule, setdisableAddRule] = useState(true)
    const [screener_final_query, set_screener_final_query] = useState([]);
    const [backtestDataChangeReq, setBacktestDataChangeReq] = useState(true);
    const [screener_name, setscreener_name] = useState('')
    const [screener_desc, setscreener_desc] = useState('')
    const [screener_id, setscreener_id] = useState(0)
    const [newFiltersList, setnewFiltersList] = useState([])
    const [initialTopQueries, setinitialTopQueries] = useState([])
    const [checkFiltersList, setcheckFiltersList] = useState([])
    const [mfCategories, setmfCategories] = useState([])
    const [stock_macro_sectors, setMacroSectors] = useState([])
    const [stock_sectors, setSectors] = useState([])
    const [stock_industries, setIndustries] = useState([])
    const [stock_basic_industries, setBasicIndustries] = useState([])
    const [loading, setloading] = useState(true)
    const [modal, setmodal] = useState(false)
    const [modalSave, setmodalSave] = useState(false)
    const [backTestModal, setbackTestModal] = useState(false)
    const [investInScreenerModal, setinvestInScreenerModal] = useState(false)
    const [backTestModalLoading, setbackTestModalLoading] = useState(true)
    const [backTestData, setBackTestData] = useState({})
    const [metricbackTestRes, setMetricbackTestRes] = useState({});
    const [metricbackTestLoading, setMetricBackTestLoading] = useState(false);
    const [metricbackTestResName, setMetricbackTestResName] = useState('')
    const [toggleMetricBackTest, settoggleMetricBackTest] = useState(false)
    const [tableColumns, setTableColumns] = useState([])
    const [appliedMetrics, setappliedMetrics] = useState([])
    const [instrumentList, setinstrumentList] = React.useState([]);
    const [selectedStockUniverseMetricBack, setselectedStockUniverseMetricBack] = useState([])
    const [popopen, setpopOpen] = useState();
    const [tableLoading, setTableLoading] = useState(true)
    const [rulesString, setrulesString] = useState([])
    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    const [totalScreens, setTotalScreens] = useState();
    const [adv_query_type, set_adv_query_type] = useState('query_type_1')
    var selectedm = screenerMetric.filter((i) => i.metric_col_code === selectedMetric)[0]
    const [btNotAvailable, setBtNotAvailable] = React.useState(false);
    const [deleteScreenModal, setDeleteScreenModal] = React.useState(false);
    const [isLocked, setIsLocked] = React.useState(false);
    const [resetState, setResetState] = React.useState(0);
    const subscribeMiddle = useSubscribeMiddleware();
    const { getUserScreens } = useUserDataHooks();

    let saveScreenAccess = subscribeMiddle({
        moduleKey: type?.value === 4 ? "SAVE_SCREEN_AND_SET_ALERTS" : type?.value === 1 ? "SAVE_AND_TRACK_ETF_SCREENS" : type?.value === 2 ? "SAVE_AND_TRACK_MF_SCREENS" : "",
        getDetailedAccessData: true, checkLimit: true, usageCount: totalScreens
    });

    console.log(saveScreenAccess);

    const filterTypes = [
        {
            value: 'Basic',
            queryStr: 'Basic',
            label: 'Basic',
        },
        {
            value: 'Advance',
            queryStr: 'Advance',
            label: <AccessComponent
                component={'Advance'} upgradeComponent={<SubscribeModalText str={'Advance'} />}
                reqAccessLevel={accessLevelData.find((ele) => ele.ModuleKey === "STOCK_ADVANCE_QUERIES")?.AccessLevel} />,
            disabled: !IsAccessable({ reqAccessLevel: accessLevelData.find((ele) => ele.ModuleKey === "STOCK_ADVANCE_QUERIES")?.AccessLevel })
        }
    ]

    const [metricFilterType, setmetricFilterType] = useState(filterTypes[0].value)

    const metric_data = (key, value) => {
        return screenerMetric.filter((met) => met[key] === value)[0]
    }

    const convertScreenerQueryToNormal = () => {
        ////console.log("aaya")
        var categories = mfCategories
        var current_q = currentCheckListRules
        var final_q = []


        var stock_uni = [stock_universe[0].value]
        var stock_exc = false
        var exchange = exchanges[0]?.value

        var initalQueries = []

        var toHidePos = []

        ////console.log(current_q)

        final_q = sortFunds(current_q, 'pos', 'bot').map((query) => {
            ////console.log("or bata")
            query = { ...metric_data('metric_col_code', query.primary_col), is_advanced: 0, ...query }
            var fquery = {
                ...query,
                metric_col_code: query.primary_col
            }
            ////console.log(query)
            if (query.oper === "abs_comp") {
                //abs
                if (query.metric_data_type === "boolean") {
                    ////console.log(stock_universe.map((i) => i.value).includes(query.primary_col) && type.value === 4)
                    if (stock_universe.map((i) => i.value).includes(query.primary_col) && type.value === 4) {
                        toHidePos.push(query.pos)
                        ////console.log("hvhg")
                        // setselectedStockUniverse([query.primary_col])
                        stock_uni = [query.primary_col]
                        // setselectedStockUniverseMetricBack(query.primary_col !== "all" ? [query.primary_col] : [stock_universe[1].value])
                    } else if (query.primary_col === 'is_exclusion_list') {
                        toHidePos.push(query.pos)
                        stock_exc = Number(query.abs_val_1) === 0 ? true : false
                        // setexcludeSurveillance(Number(query.abs_val_1) === 0 ? true : false)
                    } else {
                        fquery = {
                            ...fquery,
                            queryValue: Number(query.abs_val_1) === 1 ? "yes" : "no"
                        }
                    }
                } else {
                    fquery = {
                        ...fquery,
                        abVal: Number(query.abs_val_1),
                        queryValue: query.comp_operator
                    }
                }
            } else if (query.oper === "rank_comp") {
                //rank per or abs
                var a = query.comp_operator.split('_')
                //a[0] => top or bot (queryValue)
                //a[2] => per or abs
                //a[1] => 25,50,75,100 => a[1]/100 => if per => pervalue
                //a[1] => 25,50,75,100 => a[1] => if abs => value => top 25 stocks
                if (a[2] === 'per') {
                    fquery = {
                        ...fquery,
                        pervalue: String(Number(a[1]) / 100),
                        queryValue: 'in',//default
                        filter: a[0]
                    }
                } else {
                    //abs ko hide kiya
                    toHidePos.push(query.pos)
                }
            } else if (query.oper === "col_comp") {
                //compare other column
                if (query.adj_operator !== null) {
                    var [adjOperType, query_multiple_val] = query.adj_operator.split("_")
                    ////console.log(query.sec_col)
                    fquery = {
                        ...fquery,
                        adv_query_type: 'query_type_1',
                        rel_val: null,
                        query_multiple_val: Number(query_multiple_val)
                    }
                }
            } else if (query.oper === "rel_comp") {

                if (query.adj_operator !== null) {
                    var [adjOperType, query_multiple_val] = query.adj_operator.split("_")
                    fquery = {
                        ...fquery,
                        query_multiple_val: Number(query_multiple_val)
                    }
                }

                var [query_2_rel_comp, rel_val, query_year] = query.rel_comp_stat.split('_')

                if (query_2_rel_comp !== "self") {
                    fquery = {
                        ...fquery,
                        adv_query_type: 'query_type_2',
                        query_2_rel_comp: query_2_rel_comp,
                        rel_val: rel_val
                    }
                } else {

                    if (rel_val !== 'N') {
                        fquery = {
                            ...fquery,
                            adv_query_type: 'query_type_3',
                            query_2_rel_comp: query_2_rel_comp,
                            rel_val: rel_val,
                            query_year: Number(query_year.replace('M', '')) / 12
                        }
                    } else {
                        fquery = {
                            ...fquery,
                            adv_query_type: 'query_type_4',
                            query_2_rel_comp: query_2_rel_comp,
                            rel_val: null,
                            query_year: Number(query_year.replace('M', '')) / 12
                        }
                    }

                }

                // if (rule?.adv_query_type === 'query_type_2') {
                //     ////console.log(rule?.query_2_rel_comp, rule?.rel_val, 'N')
                //     extras = {
                //         "rel_comp_stat": [rule?.query_2_rel_comp, rule?.rel_val, 'N'].join("_")
                //     }
                // } else if (rule?.adv_query_type === 'query_type_3') {
                //     extras = {
                //         "rel_comp_stat": ['self', rule?.rel_val, String(rule?.query_year * 12) + "M"].join("_")
                //     }
                // } else if (rule?.adv_query_type === 'query_type_4') {
                //     extras = {
                //         "rel_comp_stat": ['self', 'N', String(rule?.query_year * 12) + "M"].join("_")
                //     }
                // }


                //rel with other table
            } else if (query.oper === "isin_comp") {
                //in arr
                if (query.primary_col === "category_id") {
                    var sub_category_id = query.abs_val_1.split(",")
                    var fi = []
                    categories.map((i, index) => {
                        var ak = []
                        if (i["subCategories"] !== undefined) {
                            i["subCategories"]?.map((ii, indexi) => {
                                if (sub_category_id.includes(String(ii.categoryId))) {
                                    ak = [i.categoryName, String(ii.categoryId)]
                                    fi.push(ak)
                                }
                            })
                        }
                    })
                    fquery = {
                        ...fquery,
                        queryValue: 'isOne',//default
                        abVal: fi
                    }
                } else if (query.primary_col === "nse_segment" || query.primary_col === "bse_segment") {
                    exchange = (query.primary_col === "nse_segment" ? "NSE-" : "BSE-") + (query.abs_val_1)
                } else {
                    fquery = {
                        ...fquery,
                        queryValue: 'isOne',//default
                        abVal: query.abs_val_1.split(",")
                    }
                }
            } else if (query.oper === "isnotin_comp") {
                //not arr
                if (query.primary_col === "category_id") {
                    var sub_category_id = query.abs_val_1.split(",")
                    var fi = []
                    categories.map((i, index) => {
                        var ak = []
                        if (i["subCategories"] !== undefined) {
                            i["subCategories"]?.map((ii, indexi) => {
                                if (sub_category_id.includes(String(ii.categoryId))) {
                                    ak = [i.categoryName, String(ii.categoryId)]
                                    fi.push(ak)
                                }
                            })
                        }
                    })
                    fquery = {
                        ...fquery,
                        queryValue: 'notOne',//default
                        abVal: fi
                    }
                } else {
                    fquery = {
                        ...fquery,
                        queryValue: 'notOne',//default
                        abVal: query.abs_val_1.split(",")
                    }
                }
            } else {
                // issue
            }


            return fquery
        })


        var hideCodes = [...stock_universe.map((i) => i.value), 'is_exclusion_list', 'bse_segment', 'nse_segment']
        var ak = final_q.filter((query) => !(hideCodes.includes(query.metric_col_code)) && !toHidePos.includes(Number(query.pos)))
        ////console.log(ak)
        // setselectedStockUniverse(stock_uni)
        setselectedStockUniverseMetricBack(stock_uni[0] !== "all" ? stock_uni : [stock_universe[1].value])
        // setexcludeSurveillance(stock_exc)
        setInitialQuerisVals({
            selectedStockUniverse: stock_uni,
            excludeSurveillance: stock_exc,
            exchange: exchange
        })
        setnewFiltersList(ak)

        setpopOpen(false)


    }

    const convertNormalToScreenerQuery = () => {

        var initalQueries = initialTopQueries

        var final = [...newFiltersList].map((rule, index) => {
            var rulePos = initalQueries.length + index + 1
            if (rule?.is_advanced === 1) {

                ////console.log(rule)

                ////console.log(rule?.adv_query_type)
                var extras = {}
                if (rule?.adv_query_type === 'query_type_2') {
                    console.log(rule, rule?.query_2_rel_comp, rule?.rel_val, 'N')
                    extras = {
                        "rel_comp_stat": [rule?.query_2_rel_comp, rule?.rel_val, 'N'].join("_")
                    }
                } else if (rule?.adv_query_type === 'query_type_3') {
                    extras = {
                        "rel_comp_stat": ['self', rule?.rel_val, String(rule?.query_year * 12) + "M"].join("_")
                    }
                } else if (rule?.adv_query_type === 'query_type_4') {
                    extras = {
                        "rel_comp_stat": ['self', 'N', String(rule?.query_year * 12) + "M"].join("_")
                    }
                }

                return {
                    "rule_id": rulePos,
                    "is_advanced": rule?.is_advanced,
                    "client_id": 0,
                    "checklist_id": 0,
                    "pos": rulePos,
                    "oper": rule?.oper || null,
                    "primary_col": rule?.metric_col_code,
                    "primary_col_table": "S",
                    "sec_col": rule?.sec_col || null,
                    "adj_operator": rule?.adj_operator || null,
                    "sec_col_table": rule?.sec_col_table || null,
                    "abs_val_1": rule?.abs_val_1 || null,
                    "abs_val_2": rule?.abs_val_2 || null,
                    "comp_operator": rule?.comp_operator || null,
                    "rel_comp_stat": rule?.rel_comp_stat || null,
                    "rel_comp_name": rule?.rel_comp_name || null,
                    "rule_name": rule?.metric_col_code,
                    "is_active": 1,
                    ...extras
                }
            } else {

                ////console.log(rule)

                var metricData = metric_data("metric_col_code", rule?.metric_col_code)
                var finalRule = {
                    "rule_id": rulePos,
                    "is_advanced": rule?.is_advanced,
                    "client_id": 0,
                    "checklist_id": 0,
                    "pos": rulePos,
                    "oper": rule?.oper,
                    "primary_col": rule?.metric_col_code,
                    "primary_col_table": "S",
                    "sec_col": null,
                    "adj_operator": null,
                    "sec_col_table": null,
                    "abs_val_1": String(rule?.abs_val_1),
                    "abs_val_2": null,
                    "comp_operator": rule?.comp_operator,
                    "rel_comp_stat": null,
                    "rel_comp_name": null,
                    "rule_name": rule?.metric_col_code,
                    "is_active": 1,
                }
                if (rule?.queryValue === 'isOne' || rule?.queryValue === 'notOne') {
                    //"isin_comp" , "isnotin_comp"
                    finalRule = {
                        ...finalRule,
                        "oper": rule?.queryValue === 'isOne' ? "isin_comp" : "isnotin_comp",
                        "abs_val_1": rule?.metric_col_code === "category_id" ? rule?.abVal?.map((i) => i[1]).join(",") : rule?.abVal?.join(","),
                    }
                } else if (rule?.queryValue === 'in') {
                    //"rank"
                    finalRule = {
                        ...finalRule,
                        "oper": "rank_comp",
                        "comp_operator": (rule?.filter || metricData.direction_flag) + "_" + Number(rule?.pervalue) * 100 + "_" + "per",
                    }
                } else if (rule?.queryValue === 'gt' || rule?.queryValue === 'lt' || rule?.queryValue === 'gteq' || rule?.queryValue === 'lteq') {
                    //abs
                    finalRule = {
                        ...finalRule,
                        "oper": "abs_comp",
                        "abs_val_1": String(rule?.abVal) || String(rule?.abs_val_1),
                        "comp_operator": rule?.queryValue || rule?.comp_operator,
                    }
                } else if (rule?.queryValue === 'yes' || rule?.queryValue === 'no') {
                    //bool
                    finalRule = {
                        ...finalRule,
                        "oper": "abs_comp",
                        "abs_val_1": String(rule?.queryValue === 'yes' ? 1 : 0),
                        "comp_operator": 'eq',
                    }
                }

                return finalRule
            }




        })

        final = [...initalQueries].concat(final)

        set_screener_final_query(final);
        setBacktestDataChangeReq(true);
        return final


    }

    const saveScreener = async ({ updName = null, updDesc = null, updAlerts = null, updChecklist = null } = {}) => {
        let name = updName !== null ? updName : screener_name;
        let desc = updDesc !== null ? updDesc : screener_desc;
        let alerts = updAlerts !== null ? updAlerts : alertsEnabled;
        let checklist = updChecklist !== null ? updChecklist : saveAsChecklist;
        setscreener_name(name);
        setscreener_desc(desc);
        setalertsEnabled(alerts);
        setsaveAsChecklist(checklist);
        if (user !== undefined && user !== null) {
            if (name.length > 0) {
                var screener = {
                    "ClientId": user.id,
                    "ShowAsScreen": 1,
                    "ShowAsChecklist": updChecklist === true ? 1 : 0,
                    "IsAlertsEnabled": alerts === true ? 1 : 0,
                    "ChecklistId": screener_id,
                    "ScreenerName": name,
                    "Description": desc,
                    "InstrumentType": type.strVal,
                }

                var savedScreener = await apiHelper({ apiName: 'saveScreener', data: screener })
                ////console.log(savedScreener)
                if (savedScreener?.[0]?.checklist_id !== undefined) {
                    setscreener_id(savedScreener[0]?.checklist_id)
                    var final = convertNormalToScreenerQuery()
                    ////console.log(final)

                    var a = await final.map(async (rule) => {
                        ////console.log(rule)
                        var saverule = await apiHelper({ apiName: 'saveScreenerRule', data: JSON.stringify({ ...rule, "screener_type": type.strVal, "client_id": user.id, "rule_id": 0, "checklist_id": savedScreener[0]?.checklist_id }) })
                        return saverule
                    })
                    setTimeout(async () => {
                        toast.success("Screen saved")
                        await apiHelper({ apiName: "getUserScreeners", saveResponse: "sessionStorage", data: { id: user.id, type: type.strVal }, getFresh: true });
                        navigate(`${type?.basePath}/screener?screenId=${savedScreener?.[0]?.checklist_id}&screenType=3&userId=${user?.id}`)
                        setmodalSave(false)
                        setTableLoading(false)
                    }, 200);

                } else {
                    toast.error("Something went wrong. Try again later!!")
                }

            } else {
                toast.error("Please enter screen name")
            }
        } else {
            toast.error("You need to login to create screen")
            dispatch(toggleloginModal(true))
        }


    }

    const loadData = async () => {
        var screener_datas = await screenerMetricData(type, 0, 1)
        setscreenerMetric(screener_datas?.screenerMetricData)
        if (type.value === 4) {
            setallFundsData(screener_datas?.allFunds?.filter((i) => i?.mcap > 0))
            setallFilteredFundsData(screener_datas?.allFunds?.filter((i) => i?.mcap > 0))
        } else {
            setallFundsData(screener_datas?.allFunds)
            setallFilteredFundsData(screener_datas?.allFunds)
        }

        if (userCustomCols === undefined) {
            let customCols = await apiHelper({ apiName: "getCustomColumns", data: user?.id, saveResponse: "sessionStorage" });
            let cols = null;
            if (customCols) {
                cols = customCols?.find((el) => el?.InstrumentType === type?.strVal?.toUpperCase());
                // setUserCustomColumns(cols);
            }
            dispatch(updateCustomColumns(customCols))
        }

        setselectedCategory(screener_datas?.levelVals?.m_category_id)
        setStyleBoxes(screener_datas?.fabricatedData?.stockStyles)
        setMacroSectors(screener_datas?.fabricatedData?.macroSectors)
        setSectors(screener_datas?.fabricatedData?.sectors)
        setIndustries(screener_datas?.fabricatedData?.industries)
        setBasicIndustries(screener_datas?.fabricatedData?.basicIndustries)
        setmfCategories(screener_datas?.fabricatedData?.mf_categories)
        setscreenerFinalData(screener_datas?.screenerFinalData)
    }

    const loadScreen = async () => {
        console.log('1234', screenId)
        if (screenId && (user?.isLogged || (!user?.isLogged && screenUserId !== 0))) {
            let screen = await getScreenById({ type, screenType: Number(screenType), screenId: Number(screenId), userId: screenUserId });
            if (screen) {
                setShowTable(false)
                setscreener_name(screen?.checklist_name);
                setscreener_desc(screen?.checklist_desc);
                setCurrentCheckListRules(screen?.rules);
                set_screener_final_query(screen?.rules);
                setIsEditable((Number(screenType) !== 3 || screenUserId !== user?.id) ? 0 : 1);
                setscreener_id(Number(screen?.checklist_id));
                if (((Number(screenType) !== 3 || screenUserId !== user?.id) ? 0 : 1) === 0) {
                    var d = await AdminServicesPY.getScreenerQueryToString({ rules: screen?.rules }).then((res) => res)
                    d = JSON.parse(d?.data) || []
                    setrulesString(d)
                }
            }
            else {
                toast.error("No such screen found!")
                navigate(`${type.basePath}/screener`);
            }
            console.log(screen, 'screen');
        }
        else {
            setscreener_name('');
            setscreener_desc('');
            setCurrentCheckListRules(undefined);
            set_screener_final_query(undefined);
            setIsEditable(1);
            setscreener_id(Number(0));
            setnewFiltersList([])
            setShowTable(false)
            setinitialTopQueries([]);
            setInitialQuerisVals({
                selectedStockUniverse: [],
                excludeSurveillance: false,
                exchange: 'All'
            })
        }
        let screenRes = await getUserScreens({ type: type });
        if (screenRes) {
            setTotalScreens(screenRes?.userScreens?.length);
            let checkIsLocked = screenRes?.userScreens?.find((ele) => Number(ele?.checklist_id) === Number(screenId))?.is_locked === 1;
            if (checkIsLocked) {
                setIsLocked(true);
            }
            else {
                setIsLocked(false);
            }
        }
        else {
            setTotalScreens(0);
            setIsLocked(false);
        }
    }

    const makeScreenCopy = () => {
        if (user?.isLogged) {
            setloading(true);
            setscreener_id(0);
            setIsEditable(1);
            setrulesString([])
            setscreener_name(prev => prev + " (Copy)");
            let rules = currentCheckListRules?.map((rule) => {
                return {
                    ...rule,
                    "checklist_id": 0,
                    "checklist_name": rule?.checklist_name + " (Copy)"
                }
            })
            setCurrentCheckListRules(rules);
            set_screener_final_query(rules);
        }
        else {
            dispatch(toggleloginModal(true))
        }
    }

    React.useEffect(() => {
        loadScreen();
    }, [type.value, screenId, resetState]);

    React.useEffect(() => {
        // setselectedStockUniverse([stock_universe[0].value])

        if (screenerMetric.length > 0) {
            if (currentCheckListRules !== undefined) {
                if (currentCheckListRules?.length !== 0) {
                    ////console.log("iisabi")
                    setTableLoading(true)
                    convertScreenerQueryToNormal()
                }
            } else {
                ////console.log("iisabi12212")
                setInitialQuerisVals({
                    ...intialQuriesVals,
                    selectedStockUniverse: [stock_universe[0].value]
                })
                setselectedStockUniverseMetricBack([stock_universe[1].value])
                setloading(false)
                setpopOpen(false)
            }

        }
    }, [screenerMetric, currentCheckListRules, resetState])

    const performBackTest = async () => {
        if (backtestDataChangeReq) {
            var final = screener_final_query

            var backTestData = await apiHelperPY({
                apiName: 'getScreenerBacktestData', data: {
                    rules: final
                }
            })
            backTestData = decodeData(backTestData || "");
            console.log(backTestData)
            backTestData = await JSON.parse(backTestData.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))
            console.log(backTestData)
            setBackTestData(backTestData);
            setbackTestModalLoading(false);
            setBacktestDataChangeReq(false);
        }
        else {
            setbackTestModalLoading(false);
        }
    }

    const getScreenerMetricBackTest = async (metric_col_code) => {
        if (metric_col_code === "style_box_code") {
            let backTestRes = await apiHelperPY({ apiName: 'getSharpelyStyleBoxBacktest' })
            backTestRes = decodeData(backTestRes || "");
            backTestRes = await JSON.parse(backTestRes?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))
            setMetricbackTestRes(backTestRes?.prices)
            console.log(backTestRes)
            setMetricbackTestResName(metric_col_code)
        } else {
            if (metric_col_code !== undefined) {
                var backTestRes = await apiHelperPY({ apiName: 'getScreenerMetricBacktestData', data: { metric_code: metric_col_code, universe_code: selectedStockUniverseMetricBack[0] } })
                backTestRes = decodeData(backTestRes || "");
                backTestRes = await JSON.parse(backTestRes?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))
                setMetricbackTestRes(backTestRes?.prices)
                console.log(backTestRes)
                setMetricbackTestResName(metric_col_code)
            }
        }
        setMetricBackTestLoading(false);
    }

    const MetricFilterView = ({ data, index, viewType = 0 }) => {
        //viewType = 0 => normal filter view , 1 => drawer view
        var upDatedFilters = []

        if (viewType === 0) {
            //for screen filters view we are using final filter i.e newFiltersList
            upDatedFilters = [...newFiltersList]
        } else {
            //for drawer (add rule) filters view we are using checked filter i.e checkFiltersList
            upDatedFilters = [...checkFiltersList]
        }

        var currentMetricData = {
            ...metric_data('metric_col_code', data.metric_col_code),
            "is_advanced": 0,
            "oper": null,
            "sec_col": null,
            "adj_operator": 'mult_1',
            "sec_col_table": null,
            "abs_val_1": data.metric_col_code == "investing_style" ? ['all'] : null,
            "abs_val_2": null,
            "comp_operator": 'gt',
            "rel_comp_stat": null,
            "rel_comp_name": null,

            ...data
        }

        var floatType = currentMetricData.metric_data_type === "float" && currentMetricData.show_percentile === 1 && currentMetricData.show_absolute === 1 ? "top" :
            currentMetricData.metric_data_type === "float" && currentMetricData.show_percentile === 1 ? "bot" : null

        var queriesFinal = currentMetricData.metric_data_type === "string" ? queries[0] : currentMetricData.metric_data_type === "boolean" ? queries[3] : (currentMetricData.metric_data_type === "Date" || currentMetricData.metric_data_type === "date") ? queries[4] :
            floatType === "top" ? queries[1]?.concat(queries[2]) :
                floatType === "bot" ? queries[1] :
                    floatType === null ? queries[2] : queries[0]


        var level1Width = currentMetricData.metric_data_type === "string" ? '7rem' : currentMetricData.metric_data_type === "boolean" ? '6rem' : (currentMetricData.metric_data_type === "Date" || currentMetricData.metric_data_type === "date") ? '8rem' : '12.5rem'

        const setFinalData = (data) => {
            ////console.log(data, viewType)
            if (viewType === 0) {
                setnewFiltersList(data)
            } else {
                setcheckFiltersList(data)
            }
        }

        const onQueryValueChange = (queryValue) => {
            upDatedFilters[index] = {
                ...data,
                queryValue: queryValue
            }

            setFinalData(upDatedFilters)
        }


        const FilterViewLevel1 = () => {
            const onValueChange = (value) => {
                if (Array.isArray(value) && value.length !== 0) {
                    if (value[value.length - 1] === "all") {
                        value = ["all"]
                    } else {
                        if (value.length > 1 && value[0] === "all") {
                            value.splice(0, 1)
                        }
                    }
                    upDatedFilters[index] = {
                        ...data,
                        abVal: value
                    }
                    setFinalData(upDatedFilters)
                } else {
                    upDatedFilters[index] = {
                        ...data,
                        abVal: value
                    }
                    setFinalData(upDatedFilters)
                }
            }

            // etf anf mf special cases
            if (type.value === 1 || type.value === 2) {
                if (currentMetricData.metric_col_code === "investing_style") {
                    return <Select
                        className='textSM black  '
                        mode="multiple"

                        suffixIcon={<IoIosArrowDown size={15} />}
                        defaultValue={currentMetricData.abVal ? currentMetricData.abVal : (currentMetricData.metric_col_code === "investing_style" ? ['all'] : currentMetricData.abVal)}
                        style={{
                            width: "15rem",
                        }}
                        placeholder="Select style"
                        onChange={(value) => {
                            onValueChange(value)
                        }}
                        options={investingStylelist}
                    />
                }
                if (currentMetricData.metric_col_code === "category_id") {
                    var categories = JSON.parse(JSON.stringify(mfCategories)).map((i, index) => {
                        i["value"] = i.categoryName
                        i["label"] = i.categoryName
                        i["title"] = i.categoryName
                        if (i["subCategories"] !== undefined) {
                            i["subCategories"]?.map((ii, indexi) => {
                                ii["value"] = String(ii.categoryId)
                                ii["label"] = ii.subCategoryName
                                return ii
                            })
                            i["children"] = i["subCategories"]
                        }
                        return i
                    })

                    return <Cascader
                        showSearch
                        className=''
                        style={{
                            width: "15rem",
                        }}
                        showCheckedStrategy='SHOW_CHILD'
                        placeholder='Select categories'
                        options={categories}
                        defaultValue={currentMetricData.abVal || []}
                        onChange={(value) => {
                            //////console.log(value)
                            onValueChange(value)
                        }}
                        multiple
                        maxTagCount="responsive"
                    />
                }
            } else if (type.value === 4) {
                if ((currentMetricData.metric_col_code === "macro_sec_code" ||
                    currentMetricData.metric_col_code === "sector_code" ||
                    currentMetricData.metric_col_code === "industry_code" ||
                    currentMetricData.metric_col_code === "nse_basic_ind_code" ||
                    currentMetricData.metric_col_code === "risk_box_code" ||
                    currentMetricData.metric_col_code === "style_box_code")) {
                    var checkAllData = currentMetricData.metric_col_code === "macro_sec_code" ? stock_macro_sectors :
                        currentMetricData.metric_col_code === "sector_code" ? stock_sectors :
                            currentMetricData.metric_col_code === "industry_code" ? stock_industries :
                                currentMetricData.metric_col_code === "nse_basic_ind_code" ? stock_basic_industries :
                                    currentMetricData.metric_col_code === "risk_box_code" ? risk_box_codes : currentMetricData.metric_col_code === "style_box_code" ? styleBoxes : []
                    return <Select
                        className='textSM black  '
                        mode="multiple"
                        maxTagCount='responsive'
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        suffixIcon={<IoIosArrowDown size={15} />}
                        dropdownStyle={{

                        }}
                        popupClassName="textSM black"
                        value={currentMetricData.metric_col_code === "style_box_code" ? (currentMetricData.abVal?.map((el) => Number(el)) || []) : (currentMetricData.abVal || [])}
                        style={{
                            width: "15rem",
                        }}
                        placeholder={"Select " + currentMetricData.metric_name}
                        onChange={(value) => {
                            onValueChange(value)
                        }}
                        options={checkAllData}
                    />
                }
            }

            if (currentMetricData.metric_data_type === "float") {
                if ((currentMetricData?.queryValue || queriesFinal[0]?.value) === "in" || (currentMetricData?.queryValue || queriesFinal[0]?.value) === "notin") {
                    return <Select
                        className='textSM black  '

                        suffixIcon={<IoIosArrowDown size={15} />}
                        defaultValue={currentMetricData.filter || (currentMetricData.direction_flag === "top" ? sortOptions[0] : sortOptions[1])}
                        style={{
                            width: "6rem",
                        }}
                        placeholder="Select"
                        onChange={(value) => {
                            upDatedFilters[index] = {
                                ...data,
                                filter: value
                            }

                            setFinalData(upDatedFilters)
                        }}
                        options={sortOptions}
                    />
                } else {
                    return <></>
                }
            } else if (currentMetricData.metric_data_type === "boolean") {
                return <></>
            } else if (currentMetricData.metric_data_type === "Date" || currentMetricData.metric_data_type === "date") {
                // convertAnyDatetoDDMMYYYY(currentMetricData?.filter)
                return <DatePicker className='textSM black  ' style={{
                    width: "8rem",
                }} onChange={(e) => {
                    if (e !== null) {
                        onValueChange(String(new Date(e).getTime()) + "0")
                    }
                }} defaultValue={dayjs('01-01-1990', 'DD-MM-YYYY')} value={dayjs(currentMetricData?.abVal !== undefined ? convertAnyDatetoDDMMYYYY(Number(currentMetricData?.abVal) / 10) : '01-01-1990', 'DD-MM-YYYY')} format={['DD-MM-YYYY', 'DD-MM-YY']} />
            } else {
                var checkAllData = Array.from(new Set(allFundsData.map(item => item[currentMetricData.metric_col_code])));
                checkAllData = checkAllData.map((i, index) => {
                    var x = {}
                    x["value"] = i
                    x["label"] = i
                    return x
                })
                checkAllData = checkAllData.filter((x) => x?.label !== null && x?.label !== "" && x?.label !== undefined)
                return <Select
                    className='textSM black  '
                    mode="multiple"
                    suffixIcon={<IoIosArrowDown size={15} />}
                    maxTagCount='responsive'
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    popupClassName="textSM black"
                    defaultValue={currentMetricData.abVal || []}
                    style={{
                        width: "15rem",
                    }}
                    placeholder={"Select " + currentMetricData.metric_name}
                    onChange={(value) => {
                        onValueChange(value)
                    }}
                    options={checkAllData}
                />
            }

        }

        const FilterViewLevel2 = () => {
            // currentMetricData?.queryValue?.value || queriesFinal[0]?.value === isOne,notOne  => Multi select
            // currentMetricData?.queryValue?.value || queriesFinal[0]?.value === in,notin  => percentile
            // currentMetricData?.queryValue?.value || queriesFinal[0]?.value === gt,lt,lteq,gteq  => absolute
            // currentMetricData?.queryValue?.value || queriesFinal[0]?.value === query7  => absolute and 

            const [metricVal, setmetricVal] = React.useState(currentMetricData.abVal)
            const onValueChange = (value, level = 1) => {
                if (value[value.length - 1] === "all") {
                    value = ["all"]
                } else {
                    if (value.length > 1 && value[0] === "all") {
                        value.splice(0, 1)
                    }
                }
                upDatedFilters[index] = {
                    ...data,
                    abVal: value
                }
                setFinalData(upDatedFilters)
            }

            if (currentMetricData.metric_data_type === "float") {
                if ((currentMetricData?.queryValue || queriesFinal[0]?.value) === "in" || (currentMetricData?.queryValue || queriesFinal[0]?.value) === "notin") {
                    return <Select
                        className='textSM black  '
                        suffixIcon={<IoIosArrowDown size={15} />}
                        defaultValue={currentMetricData.pervalue || perFilterValues[0]?.value}
                        style={{
                            width: "5rem",
                        }}
                        placeholder="Select"
                        onChange={(value) => {
                            upDatedFilters[index] = {
                                ...data,
                                pervalue: value
                            }
                            setFinalData(upDatedFilters)
                        }}
                        options={perFilterValues}
                    />
                } else {
                    return <CurrencyInput
                        className='inputBoxMain textSM black  '
                        allowDecimals
                        value={metricVal}
                        prefix={String(currentMetricData?.metric_unit).toLowerCase() === "rupee" || String(currentMetricData?.metric_unit).toLowerCase() === "rupee_cr" ? "₹ " : ""}
                        suffix={String(currentMetricData?.metric_unit).toLowerCase() === "rupee_cr" ? " Cr" : String(currentMetricData?.metric_unit).toLowerCase() === "percentage" ? " %" : ""}
                        onValueChange={(value) => {
                            //////console.log(value)
                            setmetricVal(value)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                if (metricVal !== undefined) {
                                    onValueChange(parseFloat(metricVal))
                                } else {
                                    toast.error("Please enter a value")
                                }
                            }
                        }}
                        onBlur={() => {
                            if (metricVal !== undefined) {
                                onValueChange(parseFloat(metricVal))
                            } else {
                                toast.error("Please enter a value")
                            }
                        }}
                        onSubmit={() => {
                            if (metricVal !== undefined) {
                                onValueChange(parseFloat(metricVal))
                            } else {
                                toast.error("Please enter a value")
                            }
                        }}


                    />
                    //  <InputNumber
                    //         controls={false}
                    //         formatter={(value) => inputBoxFormatter({
                    //             text: value,
                    //             metricObj: currentMetricData
                    //         })}

                    //         parser={(value) => inputBoxParser({
                    //             text: value,
                    //             metricObj: currentMetricData
                    //         })}


                    //         precision={2} style={{ width: "8.5rem", textAlign: 'center' }}
                    //         className='textSM black  '
                    //         value={Number(metricVal)}
                    //         defaultValue={Number(currentMetricData.abVal) || currentMetricData.metricDefVal}
                    //         onChange={(value) => {
                    //             setmetricVal(Number(value))
                    //         }}
                    //         onKeyDown={(e) => {
                    //             if (e.key === "Enter") {
                    //                 onValueChange(Number(metricVal))
                    //             }
                    //         }}
                    //         onBlur={() => {
                    //             onValueChange(Number(metricVal))
                    //         }}
                    //         onSubmit={() => {
                    //             onValueChange(Number(metricVal))
                    //         }}
                    //     />
                }
            } else {
                return <></>
            }
        }

        if (viewType === 0) {
            return <Card bordered style={{ borderColor: "var(--blueShade)", backgroundColor: "var(--blue2)" }} bodyStyle={{ padding: "0.5rem", display: "flex", flexDirection: isMobile ? 'column' : 'row', gap: "0.5rem", alignItems: isMobile ? "start" : "center" }}>
                <span style={{ flex: 1, alignItems: "center", display: "flex", gap: "0.25rem" }}>
                    <MdDragIndicator size={25} color="var(--primary)" />
                    <span className='textSM black'>{currentMetricData.metric_name}</span>
                </span>
                <div style={{ display: "flex", gap: "0.5rem", alignItems: "center", alignSelf: isMobile ? 'end' : 'normal' }}>
                    <Select
                        className='textSM black '
                        suffixIcon={<IoIosArrowDown size={15} />}
                        defaultValue={currentMetricData?.queryValue || queriesFinal[0]?.value}
                        value={currentMetricData?.queryValue || queriesFinal[0]?.value}
                        style={{
                            width: level1Width,
                        }}
                        onChange={(value) => {
                            onQueryValueChange(value)
                        }}
                        options={queriesFinal}
                    />
                    <FilterViewLevel1 />
                    {(currentMetricData?.filter?.length !== 0 && currentMetricData?.filter?.length !== undefined) || currentMetricData.metric_data_type === "float" ?
                        <FilterViewLevel2 /> : <></>}

                    <div>
                        <IoIosCloseCircleOutline style={{ cursor: "pointer" }} className='textSM black' color='var(--dark3)' size={18} onClick={() => {
                            var a = [...newFiltersList]
                            ////console.log(a, index)
                            a.splice(index, 1)
                            setFinalData(a)
                        }} />
                    </div>

                </div>
            </Card>
        } else {
            return <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginTop: "0.5rem" }}>
                <span className='textSM dark3 w-500'>{currentMetricData.metric_name}</span>
                <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                    <div style={{ display: "flex", gap: "0.5rem", alignItems: "center", flex: 1 }}>
                        <Select
                            className='textSM black'
                            suffixIcon={<IoIosArrowDown size={15} />}
                            defaultValue={currentMetricData?.queryValue || queriesFinal[0]?.value}
                            value={currentMetricData?.queryValue || queriesFinal[0]?.value}
                            style={{
                                width: level1Width,
                            }}
                            onChange={(value) => {
                                onQueryValueChange(value)
                            }}
                            options={queriesFinal}
                        />
                        <FilterViewLevel1 />
                        {(currentMetricData?.filter?.length !== 0 && currentMetricData?.filter?.length !== undefined) || currentMetricData.metric_data_type === "float" ?
                            <FilterViewLevel2 /> : <></>}
                    </div>
                </div>

            </div>
        }


    }

    const MetricAdvanceFilterView = ({ dataV, index, viewType = 0 }) => {
        //viewType = 0 => normal filter view , 1 => drawer view
        console.log("HEYARP", dataV)
        var data = {
            "is_advanced": 1,
            "oper": null,
            "sec_col": null,
            "adj_operator": 'mult_1',
            "sec_col_table": null,
            "abs_val_1": null,
            "abs_val_2": null,
            "comp_operator": 'gt',
            "rel_comp_stat": null,
            "rel_comp_name": null,

            //special case for adv query
            // "query_multiple_val": 1,
            "rel_val": dataV?.adv_query_type !== 'query_type_1' ? 'avg' : null,
            "query_2_rel_comp": dataV?.adv_query_type === 'query_type_2' ? 'ind' : null,
            "query_year": (dataV?.adv_query_type === 'query_type_4' ? 1 : 3),

            ...dataV
        }
        var upDatedFilters = []

        const [query_multiple_val, set_query_multiple_val] = React.useState(data?.query_multiple_val)
        const [rel_val, set_rel_val] = React.useState(data?.rel_val)
        const [query_2_rel_comp, set_query_2_rel_comp] = React.useState(data?.query_2_rel_comp)
        const [query_year, set_query_year] = React.useState(data?.query_year)

        ////console.log(data)
        if (viewType === 0) {
            //for screen filters view we are using final filter i.e newFiltersList
            upDatedFilters = [...newFiltersList]
        } else {
            //for drawer (add rule) filters view we are using checked filter i.e checkFiltersList
            upDatedFilters = [...checkFiltersList]
        }

        var currentMetricData = {
            ...metric_data('metric_col_code', data.metric_col_code),
            ...data
        }

        var queriesFinal = [{
            value: 'query_type_1',
            queryStr: 'query_type_1',
            oper: 'col_comp',
            label: `Compare ${currentMetricData?.metric_name} with another metric`,
        },
        {
            value: 'query_type_2',
            queryStr: 'query_type_2',
            oper: 'rel_comp',
            label: `Compare ${currentMetricData?.metric_name} of other stocks in sector, industry etc.`,
        },
        {
            value: 'query_type_3',
            queryStr: 'query_type_3',
            oper: 'rel_comp',
            label: `Compare with historical average or median values of ${currentMetricData?.metric_name}`,
        },
        {
            value: 'query_type_4',
            queryStr: 'query_type_4',
            oper: 'rel_comp',
            label: `Compare with historical values of ${currentMetricData?.metric_name}`,
        }]

        var level1Width = currentMetricData.metric_data_type === "string" ? '7rem' : currentMetricData.metric_data_type === "boolean" ? '6rem' : (currentMetricData.metric_data_type === "Date" || currentMetricData.metric_data_type === "date") ? '8rem' : '11.5rem'

        var allMetricAvailForComp = screenerMetric.filter((i) => i.metric_data_type === 'float' && i.metric_col_code !== currentMetricData?.metric_col_code)

        const setFinalData = (data) => {
            ////console.log(data, viewType)
            if (viewType === 0) {
                setnewFiltersList(data)
            } else {
                setcheckFiltersList(data)
            }
        }

        const onQueryValueChange = (queryValue) => {
            upDatedFilters[index] = {
                ...data,
                ...queryValue
            }

            ////console.log(upDatedFilters)
            setFinalData(upDatedFilters)
        }


        if (viewType === 0) {
            return <Card key={index} bordered style={{ borderColor: "var(--blueShade)", backgroundColor: "var(--blue2)" }} bodyStyle={{ padding: "0.5rem", display: "flex", gap: "0.5rem", alignItems: "center" }}>
                {/* <span style={{ flex: 1, alignItems: "center", display: "flex", gap: "0.25rem" }}>
                    <MdDragIndicator size={25} color="var(--primary)" />
                    <span className='textSM black'>{currentMetricData.metric_name}</span>
                </span> */}


                <div className='textSM dark3' style={{ display: "inline-flex", flexWrap: 'wrap', alignItems: "center", gap: "0.5rem", justifyContent: "flex-end" }}>
                    <MdDragIndicator size={25} color="var(--primary)" />
                    <span className='textSM dark3 w-500' style={{ flex: 1, minWidth: "16.3rem" }}>{currentMetricData.metric_name} </span>
                    <div>
                        <Select
                            className='textSM black'
                            suffixIcon={<IoIosArrowDown size={15} />}
                            value={currentMetricData?.comp_operator}
                            style={{
                                width: level1Width,
                            }}
                            onChange={(value) => {
                                onQueryValueChange({ 'comp_operator': value })
                            }}
                            options={queries[2]}
                        />
                    </div>
                    <CurrencyInput
                        className='inputBoxMain'
                        style={{ width: "4rem", textAlign: "center" }}
                        allowDecimals
                        decimalScale={2}
                        value={query_multiple_val}
                        // prefix={String(currentMetricData?.metric_unit).toLowerCase() === "rupee" || String(currentMetricData?.metric_unit).toLowerCase() === "rupee_cr" ? "₹ " : ""}
                        // suffix={String(currentMetricData?.metric_unit).toLowerCase() === "rupee_cr" ? " Cr" : String(currentMetricData?.metric_unit).toLowerCase() === "percentage" ? " %" : ""}
                        onValueChange={(value) => {
                            set_query_multiple_val(value)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                if (query_multiple_val !== undefined) {
                                    onQueryValueChange({
                                        'adj_operator': 'mult_' + String(query_multiple_val),
                                        'query_multiple_val': parseFloat(query_multiple_val)
                                    })
                                } else {
                                    toast.error("Please enter a value")
                                }
                            }
                        }}
                        onBlur={() => {
                            if (query_multiple_val !== undefined) {
                                onQueryValueChange({
                                    'adj_operator': 'mult_' + String(query_multiple_val),
                                    'query_multiple_val': parseFloat(query_multiple_val)
                                })
                            } else {
                                toast.error("Please enter a value")
                            }

                        }}
                        onSubmit={() => {
                            if (query_multiple_val !== undefined) {
                                onQueryValueChange({
                                    'adj_operator': 'mult_' + String(query_multiple_val),
                                    'query_multiple_val': parseFloat(query_multiple_val)
                                })
                            } else {
                                toast.error("Please enter a value")
                            }
                        }}
                    />
                    {/* <InputNumber
                        controls={false}
                        precision={1}
                        style={{ width: "4rem", textAlign: "center" }}
                        className='textSM black'
                        value={query_multiple_val}
                        onChange={(value) => {
                            set_query_multiple_val(Number(value))
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                onQueryValueChange({
                                    'adj_operator': 'mult_' + String(query_multiple_val),
                                    'query_multiple_val': query_multiple_val
                                })
                            }
                        }}
                        onBlur={() => {
                            onQueryValueChange({
                                'adj_operator': 'mult_' + String(query_multiple_val),
                                'query_multiple_val': query_multiple_val
                            })
                        }}
                        onSubmit={() => {
                            onQueryValueChange({
                                'adj_operator': 'mult_' + String(query_multiple_val),
                                'query_multiple_val': query_multiple_val
                            })
                        }}
                    /> */}
                    <span>times</span>

                    {data?.adv_query_type === 'query_type_1' && <>
                        <Select
                            showSearch
                            className='textSM black '
                            suffixIcon={<IoIosArrowDown size={15} />}
                            value={currentMetricData?.sec_col || allMetricAvailForComp[0]?.key}
                            style={{
                                width: '17rem',
                            }}
                            onChange={(value) => {
                                onQueryValueChange({ 'sec_col': value })
                            }}
                            options={allMetricAvailForComp}
                        /></>}

                    {data?.adv_query_type === 'query_type_2' && <>
                        <Select
                            suffixIcon={<IoIosArrowDown size={15} />}
                            value={rel_val}
                            style={{
                                width: '6rem',
                            }}
                            onChange={(value) => {
                                onQueryValueChange({ 'rel_val': value })
                            }}
                            options={queries[5]}
                        />
                        <span>{currentMetricData.metric_name} </span>
                        <span>of </span>
                        <Select
                            className='textSM black '
                            suffixIcon={<IoIosArrowDown size={15} />}
                            value={query_2_rel_comp}
                            style={{
                                width: level1Width,
                            }}
                            onChange={(value) => {
                                onQueryValueChange({ 'query_2_rel_comp': value })
                            }}
                            options={queries[6]}
                        />
                    </>}

                    {data?.adv_query_type === 'query_type_3' && <>
                        <Select
                            suffixIcon={<IoIosArrowDown size={15} />}
                            value={rel_val}
                            style={{
                                width: '6rem',
                            }}
                            onChange={(value) => {
                                onQueryValueChange({ 'rel_val': value })
                            }}
                            options={queries[5]}
                        />
                        <span>{currentMetricData.metric_name}</span>
                        <Select

                            suffixIcon={<IoIosArrowDown size={15} />}
                            value={query_year}
                            style={{
                                width: '9rem',
                            }}
                            onChange={(value) => {
                                onQueryValueChange({
                                    'query_year': value
                                })
                            }}
                            options={Array.from({ length: 10 }, (v, k) => (k + 1)).map((i) => {
                                return {
                                    key: i,
                                    value: i,
                                    queryStr: i,
                                    label: `in last ${i} ${i === 1 ? " year" : " years"}`
                                }
                            })}
                        />

                    </>}

                    {data?.adv_query_type === 'query_type_4' && <>
                        <span> {currentMetricData.metric_name} </span>

                        <Select

                            suffixIcon={<IoIosArrowDown size={15} />}
                            value={query_year}
                            style={{
                                width: '8rem',
                            }}
                            onChange={(value) => {
                                onQueryValueChange({
                                    'query_year': value
                                })
                            }}
                            options={Array.from({ length: 5 }, (v, k) => (k + 1)).map((i) => {
                                return {
                                    key: i,
                                    value: i,
                                    queryStr: i,
                                    label: `${i} ${i === 1 ? " year ago" : " years ago"}`
                                }
                            })}
                        />
                    </>}
                </div>


                <div>
                    <IoIosCloseCircleOutline style={{ cursor: "pointer" }} className='textSM black' color='var(--dark3)' size={18} onClick={() => {
                        var a = [...newFiltersList]
                        ////console.log(a, index)
                        a.splice(index, 1)
                        setFinalData(a)
                    }} />
                </div>

            </Card>
        } else {
            return <div style={{ display: "flex", flexDirection: "column", gap: "1rem", marginTop: "0.5rem" }}>

                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginTop: "0.5rem" }}>
                    <span className='textSM dark3 w-500'>Select type of advance query</span>
                    <Select
                        className='textSM black'
                        suffixIcon={<IoIosArrowDown size={15} />}
                        value={adv_query_type}
                        style={{
                            width: '90%',
                        }}
                        onChange={(value) => {
                            set_adv_query_type(value)
                            onQueryValueChange({
                                // 'oper': getValueFromArr(queriesFinal, 'oper', ['value', value]),
                                'adv_query_type': value,
                                "oper": value === 'query_type_1' ? 'col_comp' : 'rel_comp',
                                "rel_val": value === 'query_type_1' ? null : 'avg',
                                "query_2_rel_comp": value === 'query_type_2' ? 'ind' : null,
                                "query_year": (value === 'query_type_4' ? 1 : 3),

                            })
                        }}
                        options={queriesFinal}
                    />

                </div>


                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginTop: "1rem" }}>
                    <span className='textSM dark4 w-700 primary'>Query statement</span>

                    <div style={{ display: "flex", gap: "0.5rem", flexDirection: "column", border: "0px solid var(--grey1)", borderRadius: '4px', padding: "0rem 0.3rem" }}>
                        <span className='textSM dark3' style={{ display: "inline-flex", flexWrap: 'wrap', alignItems: "center", gap: "0.5rem" }}>
                            <span className='textSM dark3 w-500'>{currentMetricData.metric_name} </span>
                            <Select
                                suffixIcon={<IoIosArrowDown size={15} />}
                                value={currentMetricData?.comp_operator}
                                style={{
                                    width: level1Width,
                                }}
                                onChange={(value) => {
                                    onQueryValueChange({ 'comp_operator': value })
                                }}
                                options={queries[2]}
                            />

                            <CurrencyInput
                                className='inputBoxMain'
                                style={{ width: "4rem", textAlign: "center" }}
                                allowDecimals
                                decimalScale={2}
                                value={query_multiple_val}
                                // prefix={String(currentMetricData?.metric_unit).toLowerCase() === "rupee" || String(currentMetricData?.metric_unit).toLowerCase() === "rupee_cr" ? "₹ " : ""}
                                // suffix={String(currentMetricData?.metric_unit).toLowerCase() === "rupee_cr" ? " Cr" : String(currentMetricData?.metric_unit).toLowerCase() === "percentage" ? " %" : ""}
                                onValueChange={(value) => {
                                    set_query_multiple_val(value)
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        if (query_multiple_val !== undefined) {
                                            onQueryValueChange({
                                                'adj_operator': 'mult_' + String(query_multiple_val),
                                                'query_multiple_val': parseFloat(query_multiple_val)
                                            })
                                        } else {
                                            toast.error("Please enter a value")
                                        }
                                    }
                                }}
                                onBlur={() => {
                                    if (query_multiple_val !== undefined) {
                                        onQueryValueChange({
                                            'adj_operator': 'mult_' + String(query_multiple_val),
                                            'query_multiple_val': parseFloat(query_multiple_val)
                                        })
                                    } else {
                                        toast.error("Please enter a value")
                                    }

                                }}
                                onSubmit={() => {
                                    if (query_multiple_val !== undefined) {
                                        onQueryValueChange({
                                            'adj_operator': 'mult_' + String(query_multiple_val),
                                            'query_multiple_val': parseFloat(query_multiple_val)
                                        })
                                    } else {
                                        toast.error("Please enter a value")
                                    }
                                }}
                            />
                            {/* <InputNumber
                                controls={false}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        onQueryValueChange({
                                            'adj_operator': 'mult_' + String(query_multiple_val),
                                            'query_multiple_val': query_multiple_val
                                        })
                                    }
                                }}
                                precision={1}
                                style={{ width: "4rem", textAlign: "center" }}
                                className='textSM black'
                                value={query_multiple_val}
                                onChange={(value) => {
                                    set_query_multiple_val(Number(value))
                                }}
                                onBlur={() => {
                                    onQueryValueChange({
                                        'adj_operator': 'mult_' + String(query_multiple_val),
                                        'query_multiple_val': query_multiple_val
                                    })
                                }}
                                onSubmit={() => {
                                    onQueryValueChange({
                                        'adj_operator': 'mult_' + String(query_multiple_val),
                                        'query_multiple_val': query_multiple_val
                                    })
                                }}
                            /> */}
                            <span>times</span>

                            {data?.adv_query_type === 'query_type_1' && <>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    className='textSM black '
                                    placeholder='Select metric to compare with'
                                    suffixIcon={<IoIosArrowDown size={15} />}
                                    value={currentMetricData?.sec_col}
                                    style={{
                                        width: '17rem',
                                    }}
                                    onChange={(value) => {
                                        onQueryValueChange({ 'sec_col': value })
                                    }}
                                    options={allMetricAvailForComp}
                                /></>}

                            {data?.adv_query_type === 'query_type_2' && <>
                                <Select

                                    suffixIcon={<IoIosArrowDown size={15} />}
                                    value={rel_val}
                                    style={{
                                        width: '6rem',
                                    }}
                                    onChange={(value) => {
                                        onQueryValueChange({ 'rel_val': value })
                                    }}
                                    options={queries[5]}
                                />
                                <span>{currentMetricData.metric_name} </span>
                                <span>of</span>
                                <Select

                                    className='textSM black '
                                    suffixIcon={<IoIosArrowDown size={15} />}
                                    value={query_2_rel_comp}
                                    style={{
                                        width: level1Width,
                                    }}
                                    onChange={(value) => {
                                        onQueryValueChange({ 'query_2_rel_comp': value })
                                    }}
                                    options={queries[6]}
                                />
                            </>}

                            {data?.adv_query_type === 'query_type_3' && <>
                                <Select

                                    suffixIcon={<IoIosArrowDown size={15} />}
                                    value={rel_val}
                                    style={{
                                        width: '6rem',
                                    }}
                                    onChange={(value) => {
                                        onQueryValueChange({ 'rel_val': value })
                                    }}
                                    options={queries[5]}
                                />
                                <span>{currentMetricData.metric_name}</span>
                                {/* <span>in last</span> */}
                                <Select

                                    suffixIcon={<IoIosArrowDown size={15} />}
                                    value={query_year}
                                    style={{
                                        width: '9rem',
                                    }}
                                    onChange={(value) => {
                                        onQueryValueChange({
                                            'query_year': value
                                        })
                                    }}
                                    options={Array.from({ length: 10 }, (v, k) => (k + 1)).map((i) => {
                                        return {
                                            key: i,
                                            value: i,
                                            queryStr: i,
                                            label: `in last ${i} ${i === 1 ? " year" : " years"}`
                                        }
                                    })}
                                />
                                {/* <span>year(s)</span> */}

                            </>}

                            {data?.adv_query_type === 'query_type_4' && <>
                                <span>{currentMetricData.metric_name} </span>

                                <Select

                                    suffixIcon={<IoIosArrowDown size={15} />}
                                    value={query_year}
                                    style={{
                                        width: '8rem',
                                    }}
                                    onChange={(value) => {
                                        onQueryValueChange({
                                            'query_year': value
                                        })
                                    }}
                                    options={Array.from({ length: 5 }, (v, k) => (k + 1)).map((i) => {
                                        return {
                                            key: i,
                                            value: i,
                                            queryStr: i,
                                            label: `${i} ${i === 1 ? " year ago" : " years ago"}`
                                        }
                                    })}
                                />
                                {/* <span>year(s) ago</span> */}
                            </>}
                        </span>
                    </div>
                </div>
            </div>
        }


    }

    const onMetricSearch = (metric) => {
        var upDatedFilters = []
        var itemIndex = [...newFiltersList].findIndex((met) => met.metric_col_code === metric.metric_col_code)
        var isPresentIndex = itemIndex !== -1
        setselectedCategory(metric.category_id);
        setselectedMetric(metric.metric_col_code)
        setdisableAddRule(false)
        setmetricFilterType("Basic")
        isPresentIndex = true
        var selectedm = screenerMetric.filter((i) => i.metric_col_code === metric.metric_col_code)[0]
        upDatedFilters.push({
            ...selectedm,
            metric_col_code: metric.metric_col_code,
            abVal: metric.metric_col_code === "investing_style" ? ['all'] : selectedm?.metricDefVal,
            pervalue: selectedm?.pervalue,
            queryValue: selectedm?.queryValue,
        })
        setcheckFiltersList(upDatedFilters)
    }

    const RenderIntialFlatlist = ({ item }) => {

        const [checked, setChecked] = React.useState()

        return item.sub_category.filter((ele) => ele.isVisibleNormally !== false).map((subCategory, subCategoryIndex) => {
            return <div key={generateUniqueKey(subCategoryIndex)} style={{ borderRadius: 2, marginBottom: "2rem" }}>
                <span className="textSM black w-500"> {subCategory.sub_category_name}</span>

                <span style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginTop: "0.625rem" }}>
                    {subCategory.metric.map((metric, metricIndex) => {

                        // var upDatedFilters = [...checkFiltersList]
                        var upDatedFilters = []
                        var itemIndex = [...newFiltersList].findIndex((met) => met.metric_col_code === metric.metric_col_code)
                        var isPresentIndex = itemIndex !== -1

                        if (metric.isVisibleNormally !== false) {
                            return <AccessComponent key={generateUniqueKey(metricIndex)}
                                reqAccessLevel={metric.auth_flag}
                                upgradeComponent={
                                    <div key={metric.metric_col_code} style={{ cursor: "pointer", color: "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem" }} className='textXS'>
                                        <span onClick={() => {
                                            setselectedMetric(metric.metric_col_code)
                                            setdisableAddRule(true)
                                            setcheckFiltersList([])
                                        }}>{metric.metric_name}</span>
                                        <SubscribeIcon accessKey={type.value === 4 ? "STOCK_PROPRIETARY_METRICS" : "MF_ETF_PROPRIETARY_METRICS"} />
                                    </div>
                                }
                                component={<div onClick={() => {
                                    // ////console.log(e.target.checked)
                                    // if (!isPresentIndex) {
                                    // setselectedSubCategory(subCategory.sub_category_id)
                                    setselectedMetric(metric.metric_col_code)
                                    setdisableAddRule(false)
                                    setmetricFilterType("Basic")
                                    isPresentIndex = true
                                    var selectedm = screenerMetric.filter((i) => i.metric_col_code === metric.metric_col_code)[0]
                                    upDatedFilters.push({
                                        ...selectedm,
                                        metric_col_code: metric.metric_col_code,
                                        abVal: metric.metric_col_code === "investing_style" ? ['all'] : selectedm?.metricDefVal,
                                        pervalue: selectedm?.pervalue,
                                        queryValue: selectedm?.queryValue,
                                    })
                                    setcheckFiltersList(upDatedFilters)
                                    // } else {
                                    //     toast.error("Metric already added")
                                    // }
                                }} key={metric.metric_col_code} style={{ cursor: "pointer", color: metric.metric_col_code === selectedMetric ? "var(--primary)" : "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem" }} className='textXS'>
                                    {metric.metric_name}
                                </div>} />
                        } else {
                            return <div key={generateUniqueKey(metricIndex)} ></div >
                        }
                        // if (metric.isVisibleNormally !== false) {
                        //     return <div onClick={() => {
                        //         // ////console.log(e.target.checked)
                        //         // if (!isPresentIndex) {
                        //         // setselectedSubCategory(subCategory.sub_category_id)
                        //         setselectedMetric(metric.metric_col_code)
                        //         setmetricFilterType("Basic")
                        //         isPresentIndex = true
                        //         var selectedm = screenerMetric.filter((i) => i.metric_col_code === metric.metric_col_code)[0]
                        //         upDatedFilters.push({
                        //             ...selectedm,
                        //             metric_col_code: metric.metric_col_code,
                        //             abVal: selectedm?.metricDefVal,
                        //             pervalue: selectedm?.pervalue,
                        //             queryValue: selectedm?.queryValue,
                        //         })
                        //         setcheckFiltersList(upDatedFilters)
                        //         // } else {
                        //         //     toast.error("Metric already added")
                        //         // }
                        //     }} key={metric.metric_col_code} style={{ cursor: "pointer", color: metric.metric_col_code === selectedMetric ? "var(--primary)" : "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem" }} className='textXS'>
                        //         {metric.metric_name}
                        //     </div>
                        // } else {
                        //     return <div key={"DSF" + metricIndex
                        //     } ></div >
                        // }
                    })}
                </span >
            </div >
        })
    }

    const SaveOrUpdateScreen = () => {

        const [name, setName] = React.useState(screener_name);
        const [desc, setDesc] = React.useState(String(screener_desc));
        const [alerts, setAlerts] = React.useState(alertsEnabled);
        const [checklist, setChecklist] = React.useState(saveAsChecklist);

        const isUpdating = screener_id > 0;
        return (
            <div>
                <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem", marginTop: "1rem" }}>
                    {type?.value === 4 && <div style={{ display: "flex", gap: "1.25rem" }}>
                        <span className='d-flex gap-3 align-items-center'><span className='textXS w-500 dark3'>Also use as checklist</span><Switch checked={checklist} onChange={(e) => {
                            setChecklist(!checklist)
                        }} /></span>
                    </div>}

                    <NameInput label='Screen Name' value={String(name)} onChange={(e) => setName(String(e))} changeValueOnChange />
                    <DescriptionInput label='Description' value={desc} onChange={(e) => setDesc(String(e))} changeValueOnChange />

                    <span className='d-flex flex-row gap-2' style={{ justifyContent: "flex-end" }}>
                        <button className="btnWhite" onClick={() => setmodalSave(!modalSave)}>Cancel</button>
                        <SubscribeContentWrapper moduleKey={type?.value === 1 ? "SAVE_AND_TRACK_ETF_SCREENS" : type?.value === 2 ? "SAVE_AND_TRACK_MF_SCREENS" : type?.value === 4 ? "SAVE_SCREEN_AND_SET_ALERTS" : ""}
                            preventDefault={screener_id !== 0} disabled={(isUpdating ? false : saveScreenAccess?.remainingLimit === 0) || isInvalidTextOrLength({ text: name, checkLength: true }) || desc?.length < 15}
                            checkLimit usageCount={totalScreens} onClick={() => saveScreener({ updName: name, updDesc: desc, updChecklist: checklist })}>
                            <button className="btnBlack" disabled={(isUpdating ? false : saveScreenAccess?.remainingLimit === 0) || isInvalidTextOrLength({ text: name, checkLength: true }) || desc?.length < 15} >Save</button>
                        </SubscribeContentWrapper>
                    </span>
                </div>
            </div>
        )
    }

    const onDragEnd = (result, viewType = 0) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        if (viewType === 0) {

            const reorderedItems = reorder(
                newFiltersList,
                result.source.index,
                result.destination.index
            );

            setnewFiltersList(reorderedItems)
        } else {

            const reorderedItems = reorder(
                checkFiltersList,
                result.source.index,
                result.destination.index
            );

            setcheckFiltersList(reorderedItems)
        }

    };

    const isBackTestAvailable = () => {
        var con1 = allFilteredFundsData.length >= 5 && allFilteredFundsData.length <= 100
        var excludedKeys = ["is_exclusion_list", "nse_segment", "bse_segment"].concat(stock_universe.map((i) => i.key))
        var upDatedFilters = [...appliedMetrics].filter((i) => !excludedKeys.includes(i)).map((item) => {
            return {
                ...metric_data("metric_col_code", item),
            }
        })
        var akkak = upDatedFilters.filter((item) => {
            return item.back_test_available === 1
        }).length
        console.log(con1, tableColumns)
        var con2 = (upDatedFilters.length === akkak) && akkak !== 0
        return con1 && con2 && newFiltersList.length > 0
    }

    const hide = () => {
        setpopOpen(false);
    };

    const handleOpenChange = (newOpen) => {
        ////console.log("hey")
        setpopOpen(newOpen);
    };

    const getFilteredFunds = async (final) => {



        //////console.log(initialTopQueries)
        var allFunds = JSON.parse(JSON.stringify(allFundsData))
        //////console.log(allFunds.length)
        var finalTableColumns = []
        // console.log(final)

        var excludedKeys = [
            "clientId", "screener_name", "is_fof", "is_index_fund", "category_id", "investing_style", "is_etf_fund", "is_exclusion_list", 'nse_segment', 'bse_segment', 'symbol', "bse_ticker", "proper_name", "nse_active", "gdf_symbol", "nse_series", "as_on_date", "price"
        ]

        // console.log(final)

        if (type.value === 4) {
            var cols = [...stock_PaidCols]
            // let cols2 = null;
            // if (customCols) {
            //     cols2 = customCols?.find((el) => el?.InstrumentType === type?.strVal?.toUpperCase());
            //     // setUserCustomColumns(cols2);
            // }
            let userCustomColumn = userCustomCols?.find((el) => el?.InstrumentType === type?.strVal?.toUpperCase());
            if (userCustomColumn) {
                cols = [...cols, ...userCustomColumn?.Columns?.split('|')?.filter((el) => el)]
            }
            var filFunds = await AdminServicesPY.getAllScreenedStocksNew({ rules: final, cols: cols?.filter((i) => i !== "proper_name") }).then((r) => r)
            filFunds = filFunds?.data?.length > 0 ? filFunds?.data : []
            console.log(filFunds)

            allFunds = JSON.parse(filFunds)?.map((i) => {
                return {
                    ...allFunds?.filter((ii) => ii?.symbol === i?.symbol)?.[0],
                    ...i,
                }
            })

            // if (allFunds?.length > 0) {
            //     var allCols = Object?.keys(allFunds?.[0])
            //     // alert(allCols?.length)
            //     for (let i = 0; i < allCols.length; i++) {

            //         // console.log("safd2193019283")
            //         // var query = final[i]
            //         var primary_col_name = allCols[i]

            //         if (!excludedKeys.includes(primary_col_name)) {
            //             finalTableColumns.push(primary_col_name)
            //         }
            //     }
            // } else {
            for (let i = 0; i < final.length; i++) {

                // console.log("safd2193019283")
                var query = final[i]
                var primary_col_name = query['primary_col']

                if (!excludedKeys.includes(primary_col_name)) {
                    finalTableColumns.push(primary_col_name)
                }
            }
            // }
        } else {
            for (let i = 0; i < final.length; i++) {

                console.log("safd2193019283")
                var query = final[i]
                var primary_col_name = query['primary_col']

                if (!excludedKeys.includes(primary_col_name)) {
                    finalTableColumns.push(primary_col_name)
                }

                var secondary_col_name = null
                var currentMetricData = { ...metric_data('metric_col_code', query.metric_col_code), ...query }

                //console.log(allFunds)

                if (allFunds?.length !== 0) {

                    if (query["is_advanced"] === 1) {
                        setTableLoading(true)
                        // //console.log({ rules: [query], funds: JSON.stringify(allFunds?.map((i) => i.symbol)).replace(/null/g, NaN) })
                        var check1 = allFunds?.length === allFilteredFundsData?.length
                        var filFunds = await AdminServicesPY.getAllScreenedFundsFromAdvQuery({ rules: [query], funds: JSON.stringify(allFunds?.map((i) => i.symbol)).replace(/null/g, NaN) }).then((r) => r)
                        filFunds = filFunds?.data?.length > 0 ? filFunds?.data : []
                        allFunds = allFunds?.filter((fund) => filFunds.includes(fund?.symbol))
                        console.log(allFunds)
                    } else if (primary_col_name == "investing_style") {
                        console.log(query['abs_val_1'])
                        if (query['abs_val_1'] !== 'all') {
                            var filt = query['abs_val_1']?.split(',')
                            var filterString = []
                            filt?.map((filter) => {
                                console.log("allalal", filter)
                                filterString?.push(query['oper'] == 'isin_comp' ? `fund["${filter}"] == 1` : `fund["${filter}"] != 1`)

                                return ""
                            })
                            console.log("allalal", filterString)
                            allFunds = allFunds.filter((fund) =>
                                eval(filterString?.join(query['oper'] == 'isin_comp' ? " || " : " && ")))
                        }

                    } else {
                        allFunds = allFunds.filter((fund) =>
                            eval(`fund[primary_col_name] !== null`))

                        if (query['oper'] == 'abs_comp') {
                            var op = query['comp_operator']
                            var num = parseFloat(query['abs_val_1'])

                            if (op == 'gt')
                                allFunds = allFunds.filter((fund) =>
                                    eval(`fund[primary_col_name] > ${num} `))
                            if (op == 'lt')
                                allFunds = allFunds.filter((fund) =>
                                    eval(`fund[primary_col_name] < ${num} `))
                            if (op == 'eq')
                                allFunds = allFunds.filter((fund) =>
                                    eval(`fund[primary_col_name] == ${num} `))
                            ////console.log(allFunds.length)
                            if (op == 'lteq')
                                allFunds = allFunds.filter((fund) =>
                                    eval(`fund[primary_col_name] <= ${num} `))
                            if (op == 'gteq')
                                allFunds = allFunds.filter((fund) =>
                                    eval(`fund[primary_col_name] >= ${num} `))
                        }

                        if (query['oper'] == 'isin_comp' && query['abs_val_1'] !== undefined && query['abs_val_1']?.length !== 0) {
                            var str = []
                            query['abs_val_1'].split(",").map((fItem) => {
                                str.push(`String(fund['${String(primary_col_name)}']) === '${String(fItem)}'`)
                            })
                            allFunds = allFunds.filter((fund) => eval(str.join(" || ")))
                        }

                        if (query['oper'] == 'isnotin_comp' && query['abs_val_1'] !== undefined && query['abs_val_1']?.length !== 0) {
                            var str = []
                            query['abs_val_1'].split(",").map((fItem) => {
                                str.push(`String(fund['${String(primary_col_name)}']) !== '${String(fItem)}'`)
                            })
                            allFunds = allFunds.filter((fund) => eval(str.join(" && ")))
                        }

                        if (query['oper'] == 'rank_comp') {
                            var op = query['comp_operator']
                            var [asc_flag, cutoff, pct_flag] = op.split("_")

                            if (asc_flag == 'rng') {
                                var [ct_low, ct_high] = cutoff.split("-")

                                if (pct_flag === "per") {
                                    //per
                                    var list = allFunds.map(function (d) {
                                        return d[primary_col_name]
                                    })

                                    if (list.length > 0) {
                                        var val_low = quantile(list, parseFloat(ct_low) / 100)
                                        var val_high = quantile(list, parseFloat(ct_high) / 100)
                                        allFunds = allFunds.filter((fund, index) => eval(`Number(fund[primary_col_name]) >= ${parseFloat(val_low)} && Number(fund[primary_col_name]) >= ${parseFloat(val_high)} `))
                                    }
                                }

                            } else if (asc_flag == 'top') {
                                if (asc_flag !== undefined) {
                                    allFunds = sortFunds(allFunds, primary_col_name, asc_flag)
                                }

                                if (pct_flag === "per") {
                                    //per
                                    var list = allFunds.map(function (d) {
                                        return d[primary_col_name]
                                    })

                                    if (list.length > 0) {
                                        var val = quantile(list, parseFloat(cutoff) / 100)
                                        allFunds = allFunds.filter((fund, index) => eval(`Number(fund[primary_col_name]) >= ${parseFloat(val)} `))
                                    }
                                } else {
                                    //abs
                                    allFunds = allFunds.splice(0, parseInt(cutoff))
                                }


                            } else {
                                if (asc_flag !== undefined) {
                                    allFunds = sortFunds(allFunds, primary_col_name, asc_flag)
                                }

                                if (pct_flag === "per") {
                                    //per
                                    var list = allFunds.map(function (d) {
                                        return d[primary_col_name]
                                    })

                                    if (list.length > 0) {
                                        var val = quantile(list, parseFloat(cutoff) / 100)
                                        allFunds = allFunds.filter((fund, index) => eval(`Number(fund[primary_col_name]) <= ${parseFloat(val)} `))
                                    }
                                } else {
                                    //abs
                                    allFunds = allFunds.splice(0, parseInt(cutoff))
                                }
                            }



                        }

                        if (query['oper'] == 'col_comp') {
                            secondary_col_name = query['sec_col']
                            var adjustment_operator = query['adj_operator']

                            const finalCalc = (num) => {
                                var op = query['comp_operator']
                                if (op == 'gt')
                                    allFunds = allFunds.filter((fund) =>
                                        eval(`fund[primary_col_name] > ${num} && fund[primary_col_name] !== null`))
                                if (op == 'lt')
                                    allFunds = allFunds.filter((fund) =>
                                        eval(`fund[primary_col_name] < ${num} && fund[primary_col_name] !== null`))
                                if (op == 'eq')
                                    allFunds = allFunds.filter((fund) =>
                                        eval(`fund[primary_col_name] == ${num} && fund[primary_col_name] !== null`))
                                if (op == 'lteq')
                                    allFunds = allFunds.filter((fund) =>
                                        eval(`fund[primary_col_name] <= ${num} && fund[primary_col_name] !== null`))
                                if (op == 'gteq')
                                    allFunds = allFunds.filter((fund) =>
                                        eval(`fund[primary_col_name] >= ${num} && fund[primary_col_name] !== null`))
                            }

                            if (adjustment_operator !== null) {
                                var [ad_operator, ad_val] = adjustment_operator.split("_")
                                var num = null
                                if (ad_operator == 'add')
                                    finalCalc(`fund[secondary_col_name] + ${parseFloat(ad_val)} `)
                                if (ad_operator == 'sub')
                                    finalCalc(`fund[secondary_col_name] - ${parseFloat(ad_val)} `)
                                if (ad_operator == 'mult')
                                    finalCalc(`fund[secondary_col_name] * ${parseFloat(ad_val)} `)
                                if (ad_operator == 'div')
                                    finalCalc(`fund[secondary_col_name] / ${parseFloat(ad_val)} `)
                            }
                        }
                    }



                    // if (query['oper'] == 'pegy') {
                    //     allFunds = await AdminServicesPY.getAllScreenedFundsFromAdvQuery({ rules: final, funds: JSON.stringify(allFunds) }).then((r) => r)
                    //     allFunds = allFunds.data.length > 0 ? JSON.parse(allFunds.data) : []
                    // }

                }

            }
        }

        // console.log(allFunds)

        return {
            allFunds: allFunds,
            finalTableColumns: finalTableColumns,
            tableLoading: false
        }
        // if (final?.length !== 0) {



    }

    const deleteScreen = async () => {
        try {
            let res = await AdminServices.deleteScreener(user?.id, screener_id, type.strVal?.toUpperCase());
            if (res.type === true) {
                setDeleteScreenModal(false);
                toast.success('Screen deleted!');
                await apiHelper({ apiName: "getUserScreeners", saveResponse: "sessionStorage", data: { id: user.id, type: type.strVal }, getFresh: true });
                navigate(`${type.basePath}/screener`);
                setloading(true);
                setResetState(prev => prev + 1)
            }
            else {
                toast.error('Something went wrong!');
                setDeleteScreenModal(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    React.useEffect(() => {
        setloading(true)
        setTableLoading(true)
        setnewFiltersList([])
        setcheckFiltersList([])
        setallFilteredFundsData([])
        setBackTestData({})
        setBacktestDataChangeReq(true);
        loadData()
    }, [type.value, resetState])

    React.useEffect(() => {
        var initalQueries = []
        console.log(intialQuriesVals.selectedStockUniverse)
        if (type.value === 4 && intialQuriesVals.selectedStockUniverse?.length !== 0 && intialQuriesVals.selectedStockUniverse !== undefined && intialQuriesVals.selectedStockUniverse[0] !== "all") {

            initalQueries.push({
                "is_advanced": 0,
                "screener_type": type.strVal,
                "pos": 1,
                "oper": 'abs_comp',
                "primary_col": intialQuriesVals.selectedStockUniverse[0],
                "metric_col_code": intialQuriesVals.selectedStockUniverse[0],
                "sec_col": null,
                "adj_operator": null,
                "abs_val_1": 1,
                "abs_val_2": null,
                "comp_operator": 'eq',
                "rel_comp_stat": null,
                "rel_comp_name": null,
                "rule_name": 'Universe'
            })
        }


        if (type.value === 4 && intialQuriesVals.exchange !== "All") {

            initalQueries.push({
                "is_advanced": 0,
                "screener_type": type.strVal,
                "pos": initalQueries.length + 1,
                "oper": 'isin_comp',
                "primary_col": intialQuriesVals.exchange.includes("NSE") ? "nse_segment" : "bse_segment",
                "metric_col_code": intialQuriesVals.exchange.includes("NSE") ? "nse_segment" : "bse_segment",
                "sec_col": null,
                "adj_operator": null,
                "abs_val_1": intialQuriesVals.exchange.replace("NSE-", "").replace("BSE-", ""),
                "abs_val_2": null,
                "rule_id": initalQueries.length + 1,
                "comp_operator": 'eq',
                "rel_comp_stat": null,
                "rel_comp_name": null,
                "rule_name": 'Exchange'
            })
        }

        // if(type.value === 4 && intialQuriesVals.selectedStockUniverse !== undefined && intialQuriesVals.selectedStockUniverse[0] === "all"){
        //     initalQueries.splice(index, 1);
        // }

        if (type.value === 4 && intialQuriesVals.excludeSurveillance === true) {
            initalQueries.push({
                is_advanced: 0,
                "screener_type": type.strVal,
                "pos": initalQueries.length + 1,
                "oper": 'abs_comp',
                "primary_col": 'is_exclusion_list',
                "metric_col_code": 'is_exclusion_list',
                "sec_col": null,
                "adj_operator": null,
                "abs_val_1": 0,
                "abs_val_2": null,
                "comp_operator": 'eq',
                "rel_comp_stat": null,
                "rel_comp_name": null,
                "rule_name": 'Exclude Surveillance stocks',
                "rule_id": initalQueries.length + 1,
                "client_id": 0,
                "checklist_id": 0,
                "primary_col_table": "S",
                "sec_col_table": null,
                "is_active": 1,
            })
        }

        // var upFilters = newFiltersList.filter((query) => !(stock_universe.map((i) => i.value).includes(query.metric_col_code)) && "is_exclusion_list" !== query.metric_col_code)
        // ////console.log(upFilters)
        console.log(initalQueries)
        // ////console.log(initalQueries.concat(upFilters))
        setinitialTopQueries(initalQueries)
        if (type.value === 4 && intialQuriesVals.selectedStockUniverse?.length !== 0) {
            setloading(false)
        }
        if (type.value !== 4) {
            setloading(false)
        }
    }, [intialQuriesVals, resetState])

    const checkTableToShow = () => {
        if (initialTopQueries?.length > 1) {
            return true;
        }
        else if (newFiltersList?.length > 0) {
            return true;
        }
        else if (intialQuriesVals?.exchange !== 'All' || intialQuriesVals?.selectedStockUniverse?.[0] !== 'all') {
            return true;
        }
        else if (initialTopQueries?.length === 1) {
            let m = initialTopQueries?.[0]?.metric_col_code === "is_exclusion_list";
            if (m) return false;
            else return true;
        }
        return false;
    }

    const [showTable, setShowTable] = React.useState(checkTableToShow())



    React.useEffect(() => {
        // alert(["SDT", userCustomCols?.length, newFiltersList?.length, initialTopQueries?.length, loading, resetState]?.join("--"))
        const loadD = async () => {
            // applyFilters()
            console.log("hyhy", loading, initialTopQueries)
            var showTable = checkTableToShow()
            console.log(showTable)
            // setShowTable(showTable)
            if (!loading && allFundsData?.length > 0) {
                if (showTable) {
                    setTableLoading(true)
                    setShowTable(showTable)
                    var final = convertNormalToScreenerQuery()
                    if (final.length > 0) {
                        var res = await getFilteredFunds(final)?.then((r) => r)
                        console.log(res);
                        if (res) {
                            setallFilteredFundsData([...res?.allFunds])
                            setTableColumns(res?.finalTableColumns)
                            setappliedMetrics(res?.finalTableColumns)
                            setTableLoading(res?.tableLoading)
                        }
                    } else {
                        setallFilteredFundsData([])
                        setTableLoading(false)
                    }

                    console.log(newFiltersList, initialTopQueries, loading, intialQuriesVals)
                } else {
                    setShowTable(showTable)
                }
            }
            // if(screenId) {
            //     setShowTable(showTable)
            // }
        }

        loadD()

    }, [initialTopQueries, loading, resetState, newFiltersList, userCustomCols])

    console.log(intialQuriesVals, initialTopQueries);

    console.log(screener_final_query, 'screener_final_query')
    // React.useEffect(() => {
    //     // console.log(screener_final_query?.length > 0, !tableLoading, isBackTestAvailable())
    //     if (screener_final_query?.length > 0 && !tableLoading && isBackTestAvailable()) {
    //         setbackTestModalLoading(true)
    //         performBackTest()
    //     }

    // }, [tableLoading, screener_final_query])

    React.useEffect(() => {
        setMetricBackTestLoading(true);
        setMetricbackTestRes({})
        if ((selectedm?.back_test_available === 1 && selectedm?.metric_data_type === 'float') || selectedm?.metric_col_code === "style_box_code") {
            getScreenerMetricBackTest(selectedm?.metric_col_code)
        }
    }, [selectedStockUniverseMetricBack, resetState])

    React.useEffect(() => {
        if (!loading && user?.isLogged && type.value === 4 && !isMobile) {
            notifApi.open({
                message: <span className='textXL w-700 white'>Introducing Super Screener</span>,
                style: { width: "23rem", background: "var(--dark4)", borderRadius: 8, boxShadow: '0.1px 0.1px 10px 0px var(--grey1)', color: "var(--white)", position: "relative" },
                duration: 0,
                key: "ScreenPromoNotif",
                placement: "bottomRight",
                description: <div style={{}}>
                    <AiOutlineClose style={{ color: "var(--white)", position: "absolute", top: 10, right: 10, cursor: "pointer" }} onClick={() => {
                        notifApi.destroy('ScreenPromoNotif');
                    }} />
                    <Image.PreviewGroup items={[baseImgURL + 'super-screener-sample.png', baseImgURL + 'super-screener-5.png', baseImgURL + 'super-screener-1.png', baseImgURL + 'super-screener-3.png', baseImgURL + 'super-screener-4.png']} >
                        <Image src={baseImgURL + 'super-screener-sample.png'} style={{ width: "100%", borderRadius: 10, maxWidth: "100%", border: "1px solid var(--grey2)", background: "transparent" }} alt='Super screener' />
                    </Image.PreviewGroup>
                    <div className='textMD w-500 white mt-3'>
                        🌟 Level up your stock screening with the Super Screener 🌟
                    </div>
                    {user?.Client?.[0]?.SharpelyAccessLevel < 4 && <div className='textMD w-500 white mt-2'>
                        Upgrade to the sharpely Black for Unmatched Insights:
                    </div>}
                    <div className='mt-1'>
                        <ul className='d-flex flex-column textSM w-500 white' style={{ gap: "0.25rem" }}>
                            <li>📈 Over 400+ Screening Metrics</li>
                            <li> 📊 Technical Metrics & Chart Patterns</li>
                            <li>🎯 Custom Variables for Advanced Screening</li>
                        </ul>
                    </div>
                    <div className='w-500 white mt-2 textMD'>
                        Maximize your edge with this exclusive feature of sharpely Black!
                    </div>
                    <button className='btnPrimary w100 mt-3' onClick={() => {
                        navigate('/stocks/super-screener');
                        notifApi.destroy('ScreenPromoNotif')
                    }}>🚀 Start Screening</button>
                </div>,
                closeIcon: null
            })
        }
    }, [loading, user])

    const CheckedFilterView = () => {
        var upDatedFilters = [...checkFiltersList]

        return upDatedFilters.map((item, idx) => {
            const setFinalData = (data) => {
                setcheckFiltersList(data)
            }

            const onQueryValueChange = (queryValue, index) => {
                upDatedFilters[index] = {
                    ...item,
                    ...queryValue
                }

                ////console.log(upDatedFilters)
                setFinalData(upDatedFilters)
            }
            return <span key={generateUniqueKey(idx)} style={{ display: "flex", flexDirection: "column", padding: '1.25rem', flex: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span className='textSM black w-700' style={{ flex: 1 }}>Build your rule</span>
                    {item?.metric_data_type === 'float' && type.value === 4 && <Segmented
                        block={false}
                        style={{ border: "1px solid var(--grey3)" }}
                        options={filterTypes}
                        value={metricFilterType}
                        onChange={(e) => {
                            setmetricFilterType(e)
                            if (e === 'Basic') {
                                onQueryValueChange({
                                    'is_advanced': 0,
                                    "adv_query_type": null,
                                    "query_multiple_val": null,
                                    "oper": null,
                                    "rel_val": null,
                                    "query_2_rel_comp": null,
                                    "query_year": null
                                }, idx)
                            } else {
                                //special case for adv query
                                onQueryValueChange({
                                    'is_advanced': 1,
                                    "adv_query_type": adv_query_type,
                                    "query_multiple_val": 1,
                                    "oper": adv_query_type === 'query_type_1' ? 'col_comp' : 'rel_comp',
                                    "rel_val": adv_query_type !== 'query_type_1' ? 'avg' : null,
                                    "query_2_rel_comp": adv_query_type === 'query_type_2' ? 'ind' : null,
                                    "query_year": (adv_query_type === 'query_type_4' ? 1 : 3),


                                }, idx)

                            }

                        }}
                    />}
                </div>
                {metricFilterType === "Basic" ? <MetricFilterView key={idx} viewType={1} data={item} index={idx} /> :
                    <MetricAdvanceFilterView key={idx} viewType={1} dataV={item} index={idx} />
                }
            </span>
        })
    }

    const DrawerMetricDescComp = () => {
        return <div style={{ padding: "1.25rem", display: "flex", flexDirection: "column", gap: "0.5rem", backgroundColor: "var(--blue2)", flex: 1 }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <span className='textSM black w-700'>{selectedm?.metric_name}</span>
                {(selectedm?.back_test_available === 1 && selectedm?.metric_data_type === 'float') || selectedm?.metric_col_code === "style_box_code" ? <span>
                    {selectedm?.isVisibleNormally !== false ? <AccessComponent
                        reqAccessLevel={accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === 'STOCK_BACKTEST_METRICS')?.AccessLevel}
                        upgradeComponent={
                            <SubscribeBtnSmall str='Metric performance' accessKey='STOCK_BACKTEST_METRICS' />
                        }
                        component={<button className='btnWhite btnSM mb-1' onClick={() => {
                            setMetricBackTestLoading(true)
                            getScreenerMetricBackTest(selectedm?.metric_col_code)
                            settoggleMetricBackTest(true)
                        }}>
                            Metric performance
                        </button>} />
                        : <></>}

                </span> : <Tooltip title={<span className='textXS w-500'>We do not have sufficient historical data to show back tested performance of this metric. Note that if you use this metric in your screen, back tested performance of screen will also not be available.</span>} arrow={false}>
                    <span>
                        <button className='btnWhite' disabled >Metric performance</button>
                    </span>
                </Tooltip>}
            </div>
            <span style={{ height: disableAddRule ? "100%" : "15rem", overflowY: "overlay" }} className='showScroll'>
                {selectedm?.metric_desc}
            </span>
        </div>
    }

    const BacktestNotAvailable = () => {
        return (
            <Modal open={btNotAvailable} onCancel={() => { setBtNotAvailable(false) }} onOk={() => { setBtNotAvailable(false) }} footer={<button className='btnBlack'
                onClick={() => {
                    setBtNotAvailable(false);
                }}>Close</button>} closable={false}>
                <div className='' >
                    <div className='textMD' style={{ marginBottom: "1.25rem" }}>
                        Back tested performance of this screen is not available. This could be due to following reasons:
                    </div>
                    <div style={{ marginBottom: "0.5rem" }} className='textSM'>
                        1.	Number of stocks in your screen is less than 5 or more than 100
                    </div>
                    <div className='textSM'>
                        2.	We do not have sufficient historical data for one or more metrics used in your screen.
                    </div>
                    <div className='textSM'>
                        3.	You have not added any selection criteria.
                    </div>

                </div>
            </Modal>
        )
    }

    const AppliedFilterView = () => {

        const RulesContent = () => {

            return (
                <React.Fragment>
                    {isEditable === 1 ? <div >
                        <div className='scrollbox' style={{
                            position: "relative", minHeight: "40vh",
                            maxHeight: "40vh", overflowY: "scroll"
                        }}>
                            <DragDropContext onDragEnd={(e) => onDragEnd(e, 0)}>
                                <Droppable droppableId={"droppable"}>
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {newFiltersList.map((item, idx) => {
                                                return <Draggable key={generateUniqueKey(idx + "metric")} draggableId={idx + "metric"} index={idx}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            {item?.is_advanced === 1 ? <MetricAdvanceFilterView key={idx} dataV={item} index={idx} /> : <MetricFilterView key={idx} data={item} index={idx} />
                                                            }
                                                            {/* <MetricFilterView data={item} index={idx} /> */}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div> : <div >
                        <div className='scrollbox' style={{
                            position: "relative", minHeight: "50vh",
                            maxHeight: "50vh", overflowY: "scroll"
                        }}>
                            {!isMobile && <CardHeader heading={"Rules"} hideHeaderBlueLine={true} headingType={2} />}
                            <div id='disclaimerFundBasket123' className='textSM black' style={{ padding: isMobile ? "" : "1rem 0.5rem" }}>
                                <div className='d-flex flex-column' style={{ gap: "1rem", width: "100%", maxHeight: isMobile ? "" : "25rem", overflow: "auto" }}>
                                    {rulesString.map((ele, i) => (
                                        <div key={generateUniqueKey(i)} style={{
                                            border: "0.5px solid var(--grey3)",
                                            padding: "0.5rem",
                                            backgroundColor: "var(--blue2)",
                                            borderRadius: "var(--borderRadius)"
                                        }}>
                                            <div className='d-flex' style={{ gap: "0.75rem", alignItems: "center" }}>
                                                <div style={{ background: "var(--blueShade)", height: "1.5rem", width: "1.5rem", borderRadius: "50%", flexShrink: 0 }}
                                                    className='black w-500 d-flex align-items-center justify-content-center'>
                                                    {i + 1}
                                                </div>
                                                {ele}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </React.Fragment>
            )
        }

        return (
            <Card className='mobCardBorderRadius0' headStyle={{ padding: "1.25rem" }} title={<div style={{ display: "flex", gap: "0.5rem" }}>
                <CardHeader heading={screener_name || "Create screen"} headingType={1} />
                {type.value === 4 && <AccessComponent component={<div>
                    <span>
                        <button className='btnWhite' onClick={() => {
                            if (!isBackTestAvailable()) {
                                setBtNotAvailable(true);
                            }
                            else {
                                setbackTestModalLoading(true);
                                setbackTestModal(!backTestModal)
                                performBackTest()
                            }
                        }}>Backtest screen</button>
                    </span>
                </div>} upgradeComponent={<SubscribeBtnSmall accessKey={'STOCK_BACKTEST_SCREENS'} str='Backtest screen' className='btnSmall flex-order-0'
                    userAccessLevel={userAccessLevel} />}
                    reqAccessLevel={accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === 'STOCK_BACKTEST_SCREENS')?.AccessLevel} />
                }
                {isEditable === 1 ? <SubscribeContentWrapper onClick={() => {
                    setmodalSave(true)
                }} moduleKey={type?.value === 1 ? "SAVE_AND_TRACK_ETF_SCREENS" : type?.value === 2 ? "SAVE_AND_TRACK_MF_SCREENS" : type?.value === 4 ? "SAVE_SCREEN_AND_SET_ALERTS" : ""} preventDefault={screener_id !== 0}
                    checkLimit usageCount={totalScreens}>
                    <button className='btnBlack' disabled={newFiltersList.length === 0} >{screener_id !== 0 ? "Update" : "Save"}</button>
                </SubscribeContentWrapper>
                    : isMobile ? null : <button className='btnBlack' onClick={() => {
                        //copy this screen
                        makeScreenCopy()
                    }}>Make a copy</button>}
                {screener_id !== 0 && isEditable === 1 ? <button className='btnWhite' onClick={() => setDeleteScreenModal(true)}>
                    <MdOutlineDeleteOutline size='1rem' color='var(--red)' />
                </button> : null}
            </div>} bodyStyle={{ display: "flex", flexDirection: "column", gap: "1.5rem", paddingTop: 0, paddingBottom: 0 }}>
                <div style={{ display: "flex", gap: "1.25rem", alignItems: isMobile ? "" : "center", flexDirection: isMobile ? "column" : "row" }}>
                    {isEditable !== 0 && <div style={{ display: "flex", gap: "1.25rem", alignItems: isMobile ? "flex-end" : "" }}>
                        {type.value === 4 && isEditable === 1 && <div className='' style={{ display: "flex", gap: "0.5rem", flexDirection: "column" }}>
                            <span className='textSM w-500' >Exchange</span>

                            <Select
                                className='textSM black '
                                style={{
                                    width: "11rem"
                                }}
                                suffixIcon={<IoIosArrowDown size={15} />}
                                value={intialQuriesVals.exchange}
                                placeholder="Stock universe"
                                onChange={(value) => {
                                    // setselectedStockUniverse([value])
                                    setInitialQuerisVals({
                                        ...intialQuriesVals,
                                        exchange: value
                                    })
                                }}
                                options={exchanges}
                            />
                        </div>}
                        {type.value === 4 && isEditable === 1 && <div className='' style={{ display: "flex", gap: "0.5rem", flexDirection: "column" }}>
                            <span className='textSM w-500' >Stock universe</span>
                            {/* <Switch className='' size="small" checked={excludeSurveillance} onChange={(e) => {
                        setexcludeSurveillance(e)
                    }} /> */}
                            <Select
                                className='textSM black '
                                style={{
                                    width: "11rem"
                                }}
                                suffixIcon={<IoIosArrowDown size={15} />}
                                value={intialQuriesVals.selectedStockUniverse}
                                placeholder="Stock universe"
                                onChange={(value) => {
                                    // setselectedStockUniverse([value])
                                    setInitialQuerisVals({
                                        ...intialQuriesVals,
                                        selectedStockUniverse: [value]
                                    })
                                }}
                                options={stock_universe}
                            />
                        </div>}
                        {isMobile && isEditable === 1 && type.value === 4 && <CustomDrawerWithTrigger trigger={
                            <button className='btnWhite'>
                                <BsThreeDotsVertical />
                            </button>
                        } height='fit-content' showCloseIcon hideFooter>
                            <div style={{ marginBottom: "2rem" }}>
                                <span className="" style={{ display: "flex", alignItems: "center", gap: "0.75rem" }}>
                                    <span className='d-flex align-items-center textSM w-700 dark4' >
                                        Exclude flagged stocks
                                        <AiOutlineInfoCircle style={{ marginLeft: "0.15rem", cursor: "pointer" }} onClick={() => {
                                            dispatch(toggleMetricDescModal({
                                                value: true,
                                                params: {
                                                    title: stockExclusionList?.title,
                                                    description: stockExclusionList?.description
                                                }
                                            }))
                                        }} />
                                    </span>
                                    <Switch checked={intialQuriesVals.excludeSurveillance} size={'medium'} onChange={(e) => {
                                        setInitialQuerisVals({
                                            ...intialQuriesVals,
                                            excludeSurveillance: e
                                        })
                                    }} />
                                </span>
                            </div>
                        </CustomDrawerWithTrigger>}
                    </div>}
                    {isEditable === 0 && <div className='textSM' style={{ maxWidth: isMobile ? "100%" : "70%" }}>
                        {screener_desc}
                    </div>}
                    <div className='' style={{
                        flex: 1, display: "flex", gap: "0.75rem", flexDirection: "column", justifyContent: isMobile ? "" : "flex-end", alignItems: isMobile ? "" : "flex-end",
                        flexShrink: 0
                    }}>
                        <div style={{ alignItems: "center", display: "flex", gap: "1.25rem", width: isMobile ? "100%" : "" }}>
                            {newFiltersList.length > 0 && (isMobile ? <CustomDrawerWithTrigger trigger={
                                <button style={{ width: isMobile ? "" : "10vw" }} className="btnWhite "><span style={{ display: "flex", alignItems: "center" }}><FaFilter className='mx-2' /> Rules <Tag className='mx-2' color="var(--grey5)" style={{ color: "var(--black)" }}>{isEditable === 1 ? newFiltersList.length : rulesString.length}</Tag></span></button>
                            } title='Rules' hideFooter showCloseIcon>
                                <RulesContent />
                            </CustomDrawerWithTrigger> :
                                <Popover
                                    trigger="click"
                                    content={<RulesContent />}
                                    open={popopen}
                                    overlayStyle={{
                                        position: "relative",
                                        width: "55%",
                                        height: "50%",
                                        padding: 0
                                    }}
                                    arrow={false}
                                    style={{ position: "relative", width: "65vw" }}
                                    placement="bottomRight"
                                    onOpenChange={(e) => handleOpenChange(e)}
                                >
                                    <button style={{ width: isMobile ? "" : "10vw" }} className="btnWhite "><span style={{ display: "flex", alignItems: "center" }}><FaFilter className='mx-2' /> Rules <Tag className='mx-2' color="var(--grey5)" style={{ color: "var(--black)" }}>{isEditable === 1 ? newFiltersList.length : rulesString.length}</Tag></span></button>
                                </Popover>)}
                            {isEditable === 1 && <button className='btnBlack ' onClick={() => {
                                if (newFiltersList?.length > 0 && allFilteredFundsData.length === 0) {
                                    toast.error(<span className='textSM'>{`No ${type.dname} left to apply filter.Please adjust your current filters to proceed`}</span>)
                                } else {
                                    setmodal(!modal)
                                }
                            }}>Add {newFiltersList.length > 0 && 'another'} rule</button>}
                            {isMobile && isEditable === 0 && <div><button className='btnBlack ' onClick={() => {
                                makeScreenCopy()
                            }}>Make a copy</button></div>}
                        </div>
                        {!isMobile && isEditable === 1 && type.value === 4 && <div>
                            <span className="textXS" style={{ display: "flex", alignItems: "center", gap: "0.75rem" }}>
                                <span className='d-flex align-items-center'>
                                    Exclude flagged stocks
                                    <AiOutlineInfoCircle style={{ marginLeft: "0.15rem", cursor: "pointer" }} onClick={() => {
                                        dispatch(toggleMetricDescModal({
                                            value: true,
                                            params: {
                                                title: stockExclusionList?.title,
                                                description: stockExclusionList?.description
                                            }
                                        }))
                                    }} />
                                </span>
                                <Switch checked={intialQuriesVals.excludeSurveillance} size='small' onChange={(e) => {
                                    setInitialQuerisVals({
                                        ...intialQuriesVals,
                                        excludeSurveillance: e
                                    })
                                }} />
                            </span>
                        </div>}
                    </div>
                </div>
            </Card>
        )
    }

    const renderRow = ({ item, index }) => {
        return <span key={generateUniqueKey(item.category_id)} style={{
            padding: "0.75rem 1.25rem",
            borderRadius: "4px 0px 0px 4px",
            display: "flex",
            alignItems: "center",
            backgroundColor: item.category_id === selectedCategory ? "var(--blueShade)" : "transparent", cursor: "pointer"
        }}
            onClick={() => {
                setselectedCategory(item.category_id)
                // setselectedSubCategory("")
                setselectedMetric("")
                setdisableAddRule(true)
                setmetricFilterType("Basic")
            }}
        >
            <span style={{ flex: 1, color: item.category_id === selectedCategory ? "var(--primary)" : "var(--dark3)" }} className="textSM w-500">{item.category_name}</span>
            <IoIosArrowForward color={item.category_id === selectedCategory ? "var(--primary)" : "var(--dark3)"} />
        </span>
    };


    const metricPerfOptions = [
        {
            value: 'performance',
            queryStr: 'performance',
            label: 'Performance',
            children: <div>
                <div className='' style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                    <span className='textSM w-500' >Stock universe</span>
                    <Select
                        className='textSM black '
                        style={{
                            width: "11rem"
                        }}
                        suffixIcon={<IoIosArrowDown size={15} />}
                        defaultValue={selectedStockUniverseMetricBack}
                        placeholder="Stock universe"
                        onChange={(value) => {
                            setselectedStockUniverseMetricBack([value])
                        }}
                        options={stock_universe.filter((i) => i.value !== 'all')}
                    />
                </div>

                {metricbackTestLoading === true ? <React.Fragment>
                    <div className='mainContainer' style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                        <ActivityLoaderMintbox message='Hang on….heavy lifting in progress! We simulate 5 quintile portfolios over more than 10
years of historical data to get you the back tested performance.' />
                    </div>
                </React.Fragment> : Object.keys(metricbackTestRes || {}).length !== 0 && selectedm?.metric_col_code === metricbackTestResName ?
                    <PerfBenchChart portfolioType={10} defYrSelect="1095D" xAxisKey={metricbackTestResName === "style_box_code" ? "Date" : "date"} chartData={metricbackTestRes} defAmtSelect={100000} />
                    : <NoBackTestData val={6} />}

            </div>
        }, {
            label: `Disclaimer`,
            key: "id4",
            value: "id4",
            children: (
                <div>
                    <Disclaimer val={3} />
                </div>
            ),
        }
    ]

    const [metricPerfCur, setmetricPerfCur] = useState(metricPerfOptions[0].value)

    const metaItems = {
        1: {
            title: "ETF Investing | Screener for in-depth Analysis | Sharpely ETF Dashboard",
            description: "sharpely's Exchange-Traded Fund (ETF) provides investors and traders with the tools to evaluate the performance of these ETFs investment."
        },
        2: {
            title: "Top Mutual Funds Screener in India | sharpely MF Results",
            description: "sharpely's Mutual Fund Performance Screener helps investors filter and select the top-performing mutual funds in India, enabling them to choose the best mutual funds for various investments."
        },
        4: {
            title: "Stock Screener | Screener for in-depth Analysis | Sharpely Stock Screener",
            description: "Explore our stock screener models and leverage advanced technical analysis to enhance your ability to identify quality, value, momentum, and multi-factor screener scores for stocks."
        }
    }

    let metaItem = {};

    if (loading) {
        return <React.Fragment>
            <div className='loaderContainer'>
                <ActivityLoaderMintbox showQuote={true} />
            </div>
        </React.Fragment>
    } else {
        return (
            <React.Fragment>
                {notifContextHolder}
                <Head title={metaItem?.Title || metaItems?.[type?.value]?.title || "Screen"}
                    description={metaItem?.Description || metaItems?.[type?.value]?.description || `${type?.dname} Screen`} />
                <AccessControlledPage isDefaultOpen={isLocked} moduleKey={isLocked ? (type?.value === 4 ? "SAVE_SCREEN_AND_SET_ALERTS" : type?.value === 1 ? "SAVE_AND_TRACK_ETF_SCREENS" : type?.value === 2 ? "SAVE_AND_TRACK_MF_SCREENS" : "") :
                    (type?.value === 4 ? "BASIC_SCREENING" : type?.value === 1 ? "SCREENER" : type?.value === 2 ? "SCREENER_MF" : "")} subModalParams={isLocked ? {
                        isLimitType: true,
                        customAccessObj: {
                            ModuleName: "Screen Result",
                            ModuleDesc: `This screen is locked. With your current plan ${saveScreenAccess?.maxLimit === 0 ? 'you cannot view any of your saved screens. To view your screens, upgrade your plan.' : `you can only view your first ${saveScreenAccess?.maxLimit} saved screens. 
                        To view your other screens, upgrade your plan.`}`
                        }
                    } : {}} />
                <div className='normalContainer'>
                    <AppliedFilterView />
                    {showTable ? <InstrumentListTable
                        finalList={allFilteredFundsData}
                        tableType={1}
                        isEditable={isEditable}
                        hideInvestBtn={isEditable === 0}
                        // istableLoading={tableLoading}
                        onChangeExcludeStocksSwitch={(e) => {
                            setInitialQuerisVals({
                                ...intialQuriesVals,
                                excludeSurveillance: e
                            })
                            // setexcludeSurveillance(e)
                        }}
                        defExcludeStocks={intialQuriesVals.excludeSurveillance}
                        disableInvestBtn={allFilteredFundsData.length < 5}
                        // disableInvestBtn={allFilteredFundsData.length < 5 || newFiltersList.length < 1}
                        initialColumns={tableColumns}
                        tableHeader={`${allFilteredFundsData.length} ${type.dname} qualify your filters`}
                        investInScreener={(rowSelection) => {
                            // if (rowSelection.length < (type.value === 4 ? 5 : 2)) {
                            //     toast.error(<span className='textSM dark3'>To invest in screen <br></br>Select <span className='black w-500'>Min. {type.value === 4 ? "5" : "2"}</span> or <span className='black w-500'>Max. {type.value === 4 ? "50" : "10"}</span> {type.dname}</span>)
                            // } else {
                            // setinstrumentList(rowSelection)
                            // convertNormalToScreenerQuery()
                            setinvestInScreenerModal(!investInScreenerModal)
                            // }
                        }}
                        downloadTable={true}
                        fileDefaultName={screener_name || "New screen"}
                        downloadFileAccessKey={type.value === 4 ? 'DOWNLOAD_STOCK_SCREENED_LIST' : "DOWNLOAD_MF_ETF_SCREENED_LIST"}
                    /> : <></>}
                </div>

                <Drawer
                    width={isMobile ? "100%" : "85vw"}
                    closable={false}
                    title={false} placement="right"
                    onClose={() => {
                        setmodal(!modal)
                        settoggleMetricBackTest(false)
                    }}
                    styles={{ body: { padding: isMobile ? 0 : '1.25rem' } }}
                    open={modal}
                    footer={false}>
                    <Card
                        actions={[<div style={{ display: "flex", gap: "0.5rem", alignItems: "center", justifyContent: "flex-end", paddingRight: "1rem" }}><button className="btnWhite" onClick={() => {
                            setmodal(!modal)
                            setselectedMetric('')
                            settoggleMetricBackTest(false)
                        }}>Cancel</button>,
                            <button className="btnBlack" disabled={disableAddRule} onClick={() => {

                                // setnewFiltersList(checkFiltersList)

                                ////console.log(checkFiltersList)

                                var up = [...checkFiltersList]

                                var isPresent = [...newFiltersList]?.findIndex((i) => i?.metric_col_code === checkFiltersList?.[0]?.metric_col_code) !== -1
                                ////console.log(isPresent, checkFiltersList?.[0]?.metric_data_type !== 'float')
                                if (isPresent && checkFiltersList?.[0]?.metric_data_type !== 'float') {
                                    toast.error("Metric already added")
                                    // setcheckFiltersList([])
                                } else {
                                    setnewFiltersList(newFiltersList.concat(checkFiltersList))
                                    setselectedMetric("")
                                    setdisableAddRule(true)
                                    // setmetricFilterType("Basic")
                                    setmodal(!modal)
                                    settoggleMetricBackTest(false)

                                    ////console.log(checkFiltersList)
                                    ////console.log(up)
                                }

                            }}>Add rule</button></div>]}

                        bodyStyle={{ display: "flex", flex: 1, height: "100%", padding: 0, overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%" }}
                        title={<span style={{ display: "flex", alignItems: "center", justifyContent: 'space-between', gap: '1rem' }}>
                            {!isMobile && <CardHeader heading={'Select Metric'} hideHeaderBlueLine headingType={2} />}
                            <div style={{ width: isMobile ? '100%' : '' }}>
                                <MetricSearch data={screenerFinalData} onChange={onMetricSearch} insType={type.value} />
                            </div>
                            <IoIosClose onClick={() => {
                                setmodal(!modal)
                                settoggleMetricBackTest(false)
                            }}
                                size={"1.75rem"} style={{ cursor: "pointer" }} />
                        </span>}
                        bordered>
                        <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", paddingRight: 0, overflowY: "overlay" }} style={{ maxWidth: isMobile ? Object.keys(selectedm || {})?.length === 0 ? '50%' : '0' : '', display: "flex", flexDirection: "column", flex: 1.1, height: "100%", borderRadius: 0, borderWidth: 0.5 }} className='showScroll scrBxInBody'>
                            {screenerFinalData.map((item, index) => {
                                if (item.isVisibleNormally === true) {
                                    return renderRow({
                                        item, index
                                    })
                                } else {
                                    return <></>
                                }
                            }
                            )}
                        </Card>
                        <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", overflowY: "overlay" }} style={{ maxWidth: isMobile ? Object.keys(selectedm || {})?.length === 0 ? '50%' : '0' : '', display: "flex", flexDirection: "column", flex: 1.1, height: "100%", borderRadius: 0, borderWidth: 0.5 }} className='showScroll scrBxInBody'>
                            <RenderIntialFlatlist item={screenerFinalData.filter((i) => i.category_id === selectedCategory)[0]} />
                        </Card>
                        <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", overflowY: "scroll", padding: 0 }} style={{ maxWidth: isMobile ? Object.keys(selectedm || {})?.length > 0 ? '100%' : '0' : '', display: "flex", flexDirection: "column", flex: 2.8, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                            {selectedm?.metric_name !== undefined ? <>
                                {checkFiltersList.length > 0 && <CheckedFilterView />}
                                <DrawerMetricDescComp />
                            </> : <div className="mt-5" style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", alignItems: "center" }}>
                                {/* <img loading="lazy"  alt=""
                                    src='https://cdn-icons-png.flaticon.com/512/3344/3344553.png'
                                    height={80}
                                    width={80}
                                />
                                <br></br>
                                <div>Select a metric</div> */}
                            </div>
                            }
                        </Card>
                    </Card>

                    <Drawer
                        width={'65vw'}
                        height={"40vh"}
                        title={null} placement="right"
                        onClose={() => {
                            //setmodal(!modal)
                            settoggleMetricBackTest(false)
                        }}
                        closable={false}
                        open={toggleMetricBackTest}
                        styles={{ footer: { textAlign: 'right' } }}
                        footer={<button className="btnBlack ms-auto" onClick={() => {
                            settoggleMetricBackTest(false)
                        }}>Close</button>}>
                        <div className="">
                            <CardHeader heading={`Historical metric performance`} hideHeaderBlueLine headingType={2} />
                            <div style={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "1.25rem 0rem" }}>
                                <span>
                                    <CustomSegmentedTab
                                        isBlock={false}
                                        options={metricPerfOptions}
                                        value={metricPerfCur}
                                        onChange={(e) => setmetricPerfCur(e)}
                                    />
                                </span>
                                {metricPerfOptions?.filter((i) => i.value === metricPerfCur)?.[0]?.children}
                            </div>
                        </div>
                    </Drawer>
                </Drawer>

                <Modal
                    title={<div>
                        <div className='textLG w-700' >{screener_id > 0 ? "Update Screen" : "Save screen"}</div>
                        {screener_id <= 0 && saveScreenAccess?.maxLimit !== Infinity && <>
                            <div className="textXS dark2 w-500">You have <b className="dark4">{saveScreenAccess?.remainingLimit}</b> out of
                                &nbsp;<b className="dark4">{saveScreenAccess?.maxLimit}</b> save screen credits left.</div>
                            <div className="textXS dark2 w-500">Upgrade your plan to {saveScreenAccess?.remainingLimit === 0 ? 'save this screen' : 'get more credits'}.</div>
                        </>}
                    </div>}
                    centered
                    open={modalSave}
                    closable={false}
                    footer={false}
                    width={isMobile ? '100%' : "30vw"}
                    zIndex={1000}
                    onCancel={() => {
                        setmodalSave(false)
                    }}
                >
                    <SaveOrUpdateScreen />
                </Modal>

                <Drawer
                    width={isMobile ? '100%' : "90vw"}
                    closable={false}
                    title={null}
                    placement="right"
                    onClose={() => {
                        setbackTestModal(!backTestModal)
                    }}
                    open={backTestModal}
                    footer={false}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem" }}>
                        <span style={{ display: "flex", alignItems: "center" }}><CardHeader heading={'Historical Screen performance'} hideHeaderBlueLine
                            headingType={2} />
                            <button className='btnBlack' onClick={() => {
                                setbackTestModal(!backTestModal)
                            }}>Close</button></span>
                        <ScreenerOrSignalBacktest type={type} allFundsData={allFundsData} backTestModalLoading={backTestModalLoading} backTestData={backTestData} backtestType='screener'
                        />
                        {/* {backTestModalLoading ? <ActivityLoaderMintbox /> :
                            Object.keys(backTestData).length !== 0 ?
                                <ScreenerOrSignalBacktest backTestModalLoading={backTestModalLoading} backTestData={backTestData} /> : null} */}
                    </div>
                </Drawer>

                {investInScreenerModal && <Drawer
                    width={isMobile ? '100%' : "85vw"}
                    closable={false}
                    placement="right"
                    onClose={() => {
                        setinvestInScreenerModal(!investInScreenerModal)
                    }}
                    open={investInScreenerModal}
                    styles={{
                        footer: {
                            display: "flex",
                            justifyContent: "flex-end"
                        }
                    }}
                    footer={<div>
                        <button className="btnWhite" onClick={() => {
                            setinvestInScreenerModal(!investInScreenerModal)
                        }}>
                            Close
                        </button>
                    </div>}>
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <CardHeader heading={'Build a screener-based basket'} hideHeaderBlueLine headingType={2} />
                            <div className='helpText' onClick={() => {
                                dispatch(toggleUserGuideModal({
                                    value: true, params: {
                                        title: 'How to create a screen-based dynamic stock basket ?',
                                        article: 'creating-a-dynamic-basket-using-stock-screener'
                                    }, type: 'kbArticle'
                                }))
                            }}>How to create a screen-based stock basket?</div>
                        </div>
                        <div style={{ padding: '1.25rem 0rem' }}>
                            <BuildCustomPortfolio
                                isModalView={true}
                                instrumentList={[...instrumentList]}
                                allFilteredFundsData={[...allFilteredFundsData]}
                                instrument_method={2}
                                screen_id={screener_id}
                                query={screener_final_query.length > 0 ? screener_final_query : []}
                                newFiltersList={newFiltersList}
                                tableColumns={tableColumns}
                                screenerMetric={screenerMetric}
                            />
                        </div>
                    </div>

                </Drawer>}
                <Modal open={openInvModal} onCancel={() => setOpenInvModal(false)} onOk={() => setOpenInvModal(false)} footer={<button className='btnBlack'
                    onClick={() => setOpenInvModal(false)}>
                    Close</button>}>
                    <div className='d-flex align-items-center flex-column' style={{ gap: "2rem", marginTop: "2rem" }}>
                        {/* <img loading="lazy"  alt="" src={noData} style={{ width: "8rem", height: "8rem" }} /> */}
                        <div className='textMD text-center'>
                            We do not have sufficient historical data to show back tested performance of this metric. Note that if you use this metric in your screen, back tested performance of screen will also not be available.
                        </div>
                    </div>
                </Modal>
                <Modal open={deleteScreenModal} title='Delete screen' footer={null} onCancel={() => setDeleteScreenModal(false)} onOk={() => setDeleteScreenModal(false)} >
                    <div className='textSM dark4 mt-3'>
                        Are you sure you want to delete <q><b>{screener_name}</b></q> screen?
                    </div>
                    <div className='d-flex justify-content-end align-items-center' style={{ gap: '1.25rem', marginTop: "1rem" }}>
                        <button className='btnWhite' onClick={() => setDeleteScreenModal(false)}>Don't delete</button>
                        <button className='btnBlack' style={{ background: "var(--red)", border: "unset" }} onClick={deleteScreen}>Yes delete</button>
                    </div>
                </Modal>
                <BacktestNotAvailable />
            </React.Fragment >
        );
    }

}

export default Screener