import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { CurrencyFormat } from 'Components/CurrencyFormat';
import { formatDateMMMDDYYYY, monthDiff } from 'Components/DateFormatter';
import { DecimalValueFormat } from 'Components/DecimalValueFormat';
import RedGreenText from 'Components/RedGreenText';
import { CardHeader } from 'Components/atoms/SmallComponents';
import Head from 'Layout/Head';
import AdminServices from 'Services/api/AdminServices';
import AdminServicesPY from 'Services/api/AdminServicesPY';
import apiHelperPY from 'Services/api/ApiHelperPY';
import { Avatar, Badge, Card } from 'antd';
import Meta from 'antd/es/card/Meta';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export default function PublisherProfile(props) {

  const navigate = useNavigate();
  const user = useSelector((state) => state?.updateUserData?.value)
  const [publisherProfileDetails, setPublisherProfileDetails] = React.useState([]);
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(true)

  const [allPublishedScreens, setallPublishedScreens] = React.useState([]);
  const [allPublishedFactorModels, setallPublishedFactorModels] = React.useState([]);
  const [publishedStrategies, setPublishedStrategies] = React.useState([])

  const [searchParams, setSearchParams] = useSearchParams();
  const publisherId = Number(searchParams.get('id'));

  const loadData = async () => {
    try {
      setLoading(true)

      let allPublishers = await apiHelperPY({ apiName: "getAllPublisherProfile", saveResponse: "sessionStorage" });

      var client_id = JSON.parse(allPublishers)?.filter((i) => i?.UID === publisherId)?.[0]?.ClientId

      var da = await AdminServices.getClient({ id: client_id }).then((res) => JSON.stringify(res));
      da = JSON.parse(da);
      const aa = JSON.parse(da.data);
      const aak = aa.reduce((aa, f) => { return { ...aa, ...f } }, {})

      setPublisherProfileDetails({
        ...JSON.parse(allPublishers)?.filter((i) => i?.UID === publisherId)?.[0],
        ...aak
      })
      let allPublishedScreens = await apiHelperPY({ apiName: "getAllPublishedScreens" });
      setallPublishedScreens(JSON.parse(allPublishedScreens)?.filter((i) => i?.client_id === client_id))

      let allPublishedFactorModels = await apiHelperPY({ apiName: "getAllPublishedFactorModel" });
      setallPublishedFactorModels(JSON.parse(allPublishedFactorModels)?.filter((i) => i?.client_id === client_id))

      var res = await AdminServicesPY.getAllStrategiesPublished().then((res) => res)
      setPublishedStrategies(JSON?.parse(res?.data)?.filter((i) => i?.config?.client_id === client_id))

      setLoading(false)
      setTimeout(() => {
        const hash = window.location.hash;
        if (hash) {
          const element = document.querySelector(String(hash));
          if (element) {
            element.scrollTo({ behavior: 'smooth' });
          }
        }
      }, 400);
    }
    catch (err) {
      console.log(err);
    }
  }


  useEffect(() => {
    loadData();
  }, [])

  var cardDataItems = [{
    "label": "Total P/L",
    "key": "pnl"
  }, {
    "label": "Day change",
    "key": "day_change_per"
  }, {
    "label": "Abs. return",
    "key": "abs_change_per"
  }, {
    "label": "XIRR",
    "key": "CAGR"
  },
    //  {
    //     "label": "1Y return",
    //     "key": "abs_change_per"
    // },
  ]

  if (loading) {
    return <React.Fragment>
      <div className='loaderContainer'>
        <ActivityLoaderMintbox showQuote={true} />
      </div>
    </React.Fragment>
  } else {
    return (
      <React.Fragment>
        <Head title='PublisherProfile' />
        <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          <Card bordered bodyStyle={{ display: "flex", flexDirection: "row" }}>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <div style={{ display: "flex", flexDirection: "row", flex: 1, gap: "0.5rem" }}>
                <div style={{ display: "flex", flexDirection: "row", flex: 1, gap: "0.5rem", alignItems: "center" }}>
                  <Avatar size={50} shape='round' src={publisherProfileDetails?.PublisherIcon}>{publisherProfileDetails?.Name?.[0]}</Avatar>
                  <div>
                    <div className='displayMD w-bold'>{publisherProfileDetails?.Name}</div>
                    <div className='textSM w-normal textLight'>On sharpely platform since {formatDateMMMDDYYYY(publisherProfileDetails?.Client[0]?.CreatedOn)}</div>
                  </div>
                </div>
                {/* {JSON.stringify(publisherProfileDetails)} */}
              </div>
              <div className='mt-3 textSM w-normal textEllipsis6' style={{ maxWidth: "90%" }}>{publisherProfileDetails?.Description}</div>
            </div>
            <div className='' style={{ backgroundColor: "var(--white)" }}>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", alignContent: "space-evenly", height: "100%" }}>
                <div className='d-flex align-items-center' style={{
                  gap: "0.75rem", padding: window.innerWidth > 760 ? "0.75rem 1rem" : "0.75rem 1.25rem",
                }}>
                  <div>
                    <div className='textXS dark2'>SEBI Registered</div>
                    <div className='textMD black w-500'>{publisherProfileDetails?.IsSebiRegistered ? "Yes" : "No"}</div>
                  </div>
                </div>
                <div className='d-flex align-items-center' style={{
                  gap: "0.75rem", padding: window.innerWidth > 760 ? "0.75rem 1rem" : "0.75rem 1.25rem",
                }}>
                  <div>
                    <div className='textXS dark2'>SEBI Registeration No.</div>
                    <div className='textMD black w-500'>{publisherProfileDetails?.IsSebiRegistered ? publisherProfileDetails?.SebiRegistrationNo : "NA"}</div>
                  </div>
                </div>
                <div className='d-flex align-items-center' style={{
                  gap: "0.75rem", padding: window.innerWidth > 760 ? "0.75rem 1rem" : "0.75rem 1.25rem",
                }}>
                  <div>
                    <div className='textXS dark2'>SEBI Registeration type</div>
                    <div className='textMD black w-500'>{publisherProfileDetails?.IsSebiRegistered ? publisherProfileDetails?.SebiRegistrationType : "NA"}</div>
                  </div>
                </div>
                <div className='d-flex align-items-center' style={{
                  gap: "0.75rem", padding: window.innerWidth > 760 ? "0.75rem 1rem" : "0.75rem 1.25rem",
                }}>
                  <div>
                    <div className='textXS dark2'>Experience</div>
                    <div className='textMD black w-500'>{publisherProfileDetails?.YrsofExperience > 0 ? `${publisherProfileDetails?.YrsofExperience} ${publisherProfileDetails?.YrsofExperience > 1 ? "years" : "year"}` : "-"}</div>
                  </div>
                </div>
              </div>
            </div>
          </Card >

          {publishedStrategies?.length > 0 && <div id='publishedStrategies'><Card bordered hoverable title={<CardHeader heading={"Strategies created by " + publisherProfileDetails?.Name} />}>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "1.5rem" }}>
              {publishedStrategies?.map((strategy) => {
                var publisherD = publisherProfileDetails
                if (publisherD?.IsApproved === 1)
                  return <Badge.Ribbon text={strategy?.config?.instrument_type === 3 ? 'Stock' : strategy?.config?.instrument_type === 1 ? 'ETF' : 'MF'} color="var(--dark4)">
                    <Card
                      key={strategy?.config?._id}
                      bordered
                      style={{ flex: 1, backgroundColor: "var(--grey4)" }}
                      onClick={() => navigate(`/strategy-detail/${String(strategy?.config?.strategy_name)?.toLowerCase()?.replaceAll(" ", "-")}/${strategy?.config?.instrument_type === 3 ? 4 : strategy?.config?.instrument_type}/${strategy?.config?._id}`)}
                      // title={strategy?.StrategyName}
                      hoverable
                    >
                      <div className='mb-2' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "0.5rem" }}>
                        <Meta
                          style={{ flex: 1, gap: 0 }}
                          title={<span className='textEllipsis1'>{strategy?.config?.strategy_name}</span>}
                          description={<span className='textXS textLight'>
                            Created on {formatDateMMMDDYYYY(strategy?.config?.go_live_timestamp)}</span>}
                        />
                      </div>

                      <div className='textXS textEllipsis2' dangerouslySetInnerHTML={{ __html: `${strategy?.config?.strategy_desc?.replaceAll("{\"\n\"}", "")}` }}>
                      </div>
                      {/* <Divider className='my-3'/> */}
                      <div className='mt-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        {cardDataItems?.map((item, index) => {
                          return <div>
                            <CardHeader
                              heading={item?.label}
                              textClassName='textXS textLight w-normal'
                              style={{
                                justifyContent: index === 0 ? "flex-start" : (index === cardDataItems?.length - 1) ? "flex-end" : "center"
                              }}
                            />
                            <CardHeader
                              heading={index === 0 ? CurrencyFormat(strategy?.stats?.[item?.key]) : item?.key === "CAGR" ? (monthDiff(new Date(strategy?.config?.go_live_timestamp), new Date())) <= 1 ? "-" : <RedGreenText
                                value={strategy?.stats?.[item?.key]}
                                text={DecimalValueFormat({ num: strategy?.stats?.[item?.key], suffix: "%" })}
                              /> : <RedGreenText
                                value={strategy?.stats?.[item?.key]}
                                text={DecimalValueFormat({ num: strategy?.stats?.[item?.key], suffix: "%" })}
                              />}
                              style={{
                                justifyContent: index === 0 ? "flex-start" : (index === cardDataItems?.length - 1) ? "flex-end" : "center"
                              }}
                              textClassName='textSM w-bold'
                            />
                          </div>
                        })}
                      </div>
                    </Card>
                  </Badge.Ribbon>
                else
                  return null
              })}
            </div>
          </Card></div>}

          {allPublishedScreens?.length > 0 && <div id='publishedScreens'><Card bordered hoverable title={<CardHeader heading={"Super screens created by " + publisherProfileDetails?.Name} />}>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: "1.5rem" }}>
              {allPublishedScreens?.map((item, index) => {
                //console.log(item)
                var publisherD = publisherProfileDetails
                if (publisherD?.IsApproved === 1)
                  return <Card
                    style={{ flex: 1, backgroundColor: "var(--grey4)" }}
                    onClick={() => {
                      navigate(`/stocks/super-screener?screenName=${item?.screen_name?.replaceAll(' ', '-')}&screenId=${item?.screen_id}&userId=${publisherD?.ClientId || 0}`)
                    }}
                    hoverable bordered>
                    <div className='' style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                      <Meta
                        style={{ flex: 1, gap: 0 }}
                        title={<span className='textSM textEllipsis1'>{item?.screen_name}</span>}
                        description={<div className='textXS'>
                          Created on {formatDateMMMDDYYYY(item?.created_on)}
                        </div>}
                      />
                      <div className="textXS textEllipsis3" dangerouslySetInnerHTML={{ __html: `${item?.screen_desc}` }}>
                      </div>
                    </div>
                  </Card>
                else
                  return null
              })}
            </div>
          </Card></div>}

          {allPublishedFactorModels?.length > 0 && <div id='publishedFactorModels'><Card bordered hoverable title={<CardHeader heading={"Factor Models created by " + publisherProfileDetails?.Name} />}>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: "1.5rem" }}>
              {allPublishedFactorModels?.map((item, index) => {
                //console.log(item)
                var publisherD = publisherProfileDetails
                if (publisherD?.IsApproved === 1)
                  return <Card
                    style={{ flex: 1, backgroundColor: "var(--grey4)" }}
                    onClick={() => {
                      // navigate("/stocks/factor-model", { state: { signalData: item } })
                      navigate(`/stocks/factor-model?fmName=${item?.signal_name}&fmId=${item?.signal_id}&userId=${item?.client_id}`)
                      // navigate(`${ type.basePath } / super- screener ? screenName = ${ item?.screen_name?.replaceAll(' ', '-')}& screenId=${item?.screen_id}&userId=${publisherD?.ClientId || 0}`)
                    }}
                    hoverable bordered>
                    <div className='' style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                      <Meta
                        style={{ flex: 1, gap: 0 }}
                        title={<span className='textSM textEllipsis1'>{item?.signal_name}</span>}
                        description={<div className='textXS textEllipsis2'>
                          Created on {formatDateMMMDDYYYY(item?.created_on)}
                        </div>}
                      />
                      <div className="textXS textEllipsis3" dangerouslySetInnerHTML={{ __html: `${item?.signal_desc}` }}></div>
                    </div>
                  </Card>
                else
                  return null
              })}
            </div >
          </Card></div>}
        </div>
      </React.Fragment >
    )
  }
}
