import { Card } from "antd";
import { Categories } from "Components/BlogComponents/Categories";
import { FeaturedBlogs } from "Components/BlogComponents/FeaturedBlogs";
import { RecentBlogs } from "Components/BlogComponents/RecentBlogs";
import { FAQs } from "Components/BlogsAndFaqs";
import { getKnowledgeBaseData } from "Components/getKnowledgeBaseData";
import { HelpAndSupportForm } from "Components/HelpAndSupportForm";
import KnowledgeBaseCategories from "Components/KnowledgeBaseCategories";
import Head from "Layout/Head";
import React, { useEffect, useState } from "react";

const Blogs = () => {
    const [allCategories, setAllCategories] = useState([]);
    const [featuredBlogs, setFeaturedBlogs] = useState([]);
    const [recentBlogs, setRecentBlogs] = useState([]);
    const [blogCategories, setBlogCategories] = useState([])
    const [loading, setLoading] = useState(true);
    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
    const isMobile = window.innerWidth <= 575;

    const loadData = async () => {
        let res = await getKnowledgeBaseData(false, true, 9);
        if (res.type) {
            setAllCategories(res.categories || []);
            setFeaturedBlogs(res?.blogPosts || []);
            setRecentBlogs(res?.recentBlogs || []);
            setBlogCategories(res?.blogCategories || []);
            setLoading(false)
        }
        else {
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true)
        loadData();
    }, [])

    return (
        <React.Fragment>
            <Head title="sharpely's Blogs" description="Access a wealth of financial insights and guidance at sharpely's comprehensive knowledge base for informed decision-making." keywords="how to learn stock market trading, mutual fund for beginners, mutual funds investing in us stocks, mutual funds to buy for long term, Learn stock market trading for beginners, Learn stock market for free, how to learn stock market trading step by step, Best mutual Funds for beginners, Top mutual funds investing in us stocks, Best mutual funds to buy for long term, how to learn stock market trading in india, Best mutual Funds for beginners in India, Top mutual funds investing in us stocks in india, Best mutual funds to buy for long term in india, how to learn stock market trading for beginners in India" />
            <div className='normalContainer'>
                <Card style={{ background: "linear-gradient(45deg, #ccbf76, #fff2a9)" }} bodyStyle={{ padding: isMobile ? "1.25rem" : "3rem" }} className='mobCardBorderRadius0'>
                    <h1 className='headLGtoXS'>Recent blogs</h1>
                </Card>
                <div className="mainContainer">
                    <div className='leftContainer'>
                        <RecentBlogs showHeader={false} recentBlogs={recentBlogs} loading={loading} />
                    </div>
                    <div className="rightContainer">
                        <Categories categories={blogCategories} loading={loading} heading={'Blog Categories'} showBlogCount={false} />
                        <FeaturedBlogs featuredBlogs={featuredBlogs} loading={loading} />
                        <HelpAndSupportForm />
                    </div>
                </div>
                <FAQs />
            </div >
        </React.Fragment >
    )
}

export default Blogs;
