import { Card, Checkbox, Divider, Drawer } from "antd";
import { toggleloginModal } from "Libs/redux/UserAuth/reduxSlice";
import { displayValue } from "Libs/utils";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import * as ReactDOM from "react-dom";
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { BiCustomize } from "react-icons/bi";
import { HiLockClosed } from "react-icons/hi";
import { IoIosArrowBack, IoIosArrowForward, IoIosClose } from "react-icons/io";
import { MdCancel, MdDragIndicator, MdOutlineLock } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AccessComponent from "./AccessComponent";
import { CardHeader } from "./atoms/SmallComponents";
import { MetricSearch } from "./MetricSearch";
import { PlanName, SubscribeIcon } from "./SubscribeModal";

export default function CustomizeMetrics({ screenerFinalData = [], finalDataCols, setFinalDataCols, type, screenerMetric = [], fundData,
    updateUserCustomCols, disabledCols, ctaText = 'Customize', btnClassName = "btnWhite w100 flex-1" }) {

    //console.log(screenerFinalData)
    screenerFinalData = screenerFinalData?.filter((el) => el?.category_name !== 'Price and volume');
    const isMobile = window.innerWidth <= 760;
    const [customizeMetrics, setCustomizeMetrics] = React.useState(false);
    const [selectedCategory, setselectedCategory] = React.useState(isMobile ? "" : screenerFinalData?.[0]?.category_id);
    const [selectedMetric, setselectedMetric] = React.useState('');
    const [selectedCols, setSelectedCols] = React.useState(finalDataCols || [])
    const user = useSelector((state) => state?.updateUserData?.value);
    const dispatch = useDispatch();
    const portal = document.createElement("div");
    document.body.appendChild(portal);

    var selectedm = screenerMetric.filter((i) => i.metric_col_code === selectedMetric)[0]

    React.useEffect(() => {
        if (finalDataCols?.length > 0) {
            setSelectedCols(finalDataCols);
        }
        else {
            setSelectedCols([]);
        }
        // //console.log('customize metrics')
    }, [finalDataCols])

    const changeDataCols = (item) => {
        let c = selectedCols?.find((el) => el?.metric_id === item?.metric_id);
        if (c) {
            let temp = selectedCols?.filter((el) => el?.metric_id !== item?.metric_id);
            setSelectedCols(temp);
        }
        else {
            let o = {
                ...item,
                valueToRender: item?.needToRender ? (item?.dataToRenderFrom?.filter((e) => e?.[item?.renderKey] === fundData?.[item?.dataIndex])?.[0]?.[item?.renderValue]) || "-" :
                    displayValue({ text: (fundData?.[item?.dataIndex]), metricObj: item })
            }
            let temp = [...selectedCols, o];
            setSelectedCols(temp);
        }
    }

    const renderRow = ({ item, index, lastIndex }) => {
        return <span key={item.category_id} style={{
            padding: isMobile ? "1rem 0" : "0.75rem 1.25rem",
            borderRadius: isMobile ? "0" : "4px 0px 0px 4px",
            display: "flex",
            alignItems: "center",
            backgroundColor: isMobile ? "var(--white)" : item.category_id === selectedCategory ? "var(--blueShade)" : "transparent", cursor: "pointer",
            borderBottom: isMobile && lastIndex !== index ? "1px solid var(--grey2)" : "none"
        }} onClick={() => {
            setselectedCategory(item.category_id)
            setselectedMetric("")
        }}
        >
            <span style={{ flex: 1, color: item.category_id === selectedCategory ? "var(--primary)" : "var(--dark3)" }} className="textSM w-500">{item.category_name}</span>
            <IoIosArrowForward color={item.category_id === selectedCategory ? "var(--primary)" : "var(--dark3)"} />
        </span>
    };

    const onMetricSearch = (metric) => {
        changeDataCols(metric)
        // var upDatedFilters = [...selectedCols]
        // var presentIndex = upDatedFilters.findIndex((met) => met.dataIndex === metric.metric_col_code)
        // var isPresentIndex = presentIndex !== -1
        // var isDisabled = metric?.dataIndex === "proper_name" || metric?.dataIndex === "basic_name" || metric?.dataIndex === "mcap" || metric?.dataIndex === "aum"
        setselectedCategory(metric.category_id)
        // if (!isPresentIndex) {
        //     upDatedFilters.push({ ...metric })
        //     setSelectedCols([...upDatedFilters])
        // } else {
        //     upDatedFilters.splice(presentIndex, 1)
        //     setSelectedCols([...upDatedFilters])
        // }
        setselectedMetric(metric.metric_col_code)
    }

    const RenderIntialFlatlist = ({ item, index }) => {
        return item?.sub_category.filter((ele) => ele.isVisibleNormally !== false).map((subCategory, subCategoryIndex) => {
            if (subCategory.isVisibleForCustomise) {
                return <div key={"asd" + subCategoryIndex} style={{ borderRadius: 2, marginBottom: "2rem" }}>
                    <span className="textSM black w-500"> {subCategory.sub_category_name}</span>
                    <span style={{ display: "flex", flexDirection: "column", gap: isMobile ? "0.75rem" : "0.5rem", marginTop: "0.625rem" }}>
                        {subCategory.metric.map((metric) => {
                            var presentIndex = selectedCols?.findIndex((met) => met.dataIndex === metric.metric_col_code)
                            var isPresentIndex = presentIndex !== -1
                            if (metric.hidden !== true && metric?.hideInCustomizeTable !== true && metric.isVisibleNormally !== false) {
                                return <AccessComponent
                                    reqAccessLevel={metric.auth_flag}
                                    upgradeComponent={
                                        <div key={metric.metric_col_code} style={{
                                            cursor: "pointer", color: "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem",
                                            justifyContent: "space-between"
                                        }} className='textXS' onClick={() => { if (isMobile) setselectedMetric(metric.metric_col_code) }}>
                                            <div style={{ cursor: "pointer", color: "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem", }}
                                                className={`${isMobile ? "textSM" : 'textXS'}`}>
                                                {!isMobile && <Checkbox disabled={true} />}
                                                <span onClick={() => { setselectedMetric(metric.metric_col_code) }} >{metric.metric_name}</span>
                                                <SubscribeIcon accessKey={type.value === 4 ? "STOCK_PROPRIETARY_METRICS" : "MF_ETF_PROPRIETARY_METRICS"} />
                                            </div>
                                        </div>
                                    }
                                    component={<div key={metric.metric_col_code} style={{
                                        cursor: "pointer", color: "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem",
                                        justifyContent: "space-between"
                                    }} className='textXS' onClick={() => { if (isMobile) setselectedMetric(metric.metric_col_code) }}>
                                        <div style={{ cursor: "pointer", color: "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem", }}
                                            className={`${isMobile ? "textSM" : 'textXS'}`}>
                                            {!isMobile && <Checkbox
                                                checked={isPresentIndex === true}
                                                onChange={(e) => {
                                                    e.preventDefault()
                                                    changeDataCols(metric)
                                                    setselectedMetric(metric.metric_col_code)
                                                }}
                                                disabled={disabledCols?.includes(metric?.metric_col_code)}
                                            />}
                                            <span onClick={() => { setselectedMetric(metric.metric_col_code) }}>{metric.metric_name}</span>
                                        </div>
                                        <IoIosArrowForward color={"var(--dark3)"} />
                                    </div>} key={metric.metric_col_code} />
                            } else {
                                return null
                            }
                        })}
                    </span>
                </div>

            } else {
                return null
            }
        })
    }

    const onOrderChange = (items) => {
        // //console.log(items);
        let oth = selectedCols?.filter((el) => disabledCols.includes(el.metric_col_code))
        let temp = [...oth, ...items] || []
        setSelectedCols(temp);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    function PortalAwareItem({ provided, snapshot, item, index }) {
        const usePortal = snapshot.isDragging;
        const child = (
            <div
                className="mb-1"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <MdDragIndicator size={25} color="var(--dark3)" />

                    <span className='textXS dark3 mx-3' style={{ flex: 1 }}>{item.title}</span>
                    {item.disableDeleteInCustomize ? <HiLockClosed className='textXS dark3' size={15} onClick={() => { }} /> :
                        <MdCancel style={{ cursor: "pointer" }} className='textXS dark3' size={15} onClick={() => {
                            var a = [...selectedCols]
                            a = a.filter((el) => el?.metric_col_code !== item?.metric_col_code) || [];
                            setSelectedCols(a)
                        }} />}
                </div>
            </div>
        );

        if (!usePortal) {
            return child;
        }

        // if dragging - put the item in a portal
        return ReactDOM.createPortal(child, portal);
    }

    function VerticalList({ items, onChange }) {
        const onDragEnd = (result) => {
            if (!result.destination) return;
            onChange(reorder(items, result.source.index, result.destination.index));
        };
        return (
            <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem" }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="vertical-list">
                        {(droppableProvided, droppableSnapshot) => (
                            <div
                                ref={droppableProvided.innerRef}
                                {...droppableProvided.droppableProps}
                            >
                                {items.map((item, index) => {
                                    if (!item.disableDeleteInCustomize) {
                                        return <Draggable key={item.dataIndex} draggableId={item.dataIndex} index={index}>
                                            {(draggableProvided, draggableSnapshot) => (
                                                <PortalAwareItem
                                                    item={item}
                                                    index={index} //removing name and aum/mcap
                                                    provided={draggableProvided}
                                                    snapshot={draggableSnapshot}
                                                />
                                            )}
                                        </Draggable>
                                    } else {
                                        return null
                                    }
                                })}
                                {droppableProvided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div >
        );
    }

    const AppliedFilterView = () => {
        var nonEditable = selectedCols?.filter((el) => disabledCols.includes(el.metric_col_code))
        // //console.log(nonEditable, selectedCols?.filter((el) => disabledCols.includes(el.metric_col_code)), selectedCols)
        return <div style={{
            display: "flex", flex: 1, flexDirection: "column", gap: "1.2rem", padding: isMobile ? "1rem 0" : '1.25rem', flex: 1, height: isMobile ? "100%" : "50%", overflowY: "auto"
        }}> <span>
                {nonEditable.length > 2 ?
                    <div className="textSM" style={{ color: 'var(--primary)', backgroundColor: 'var(--blueShade)', padding: "0.875rem 0.75rem", borderRadius: "4px", alignItems: "center", gap: "0.5rem" }}>
                        <MdOutlineLock className='textXS primary me-2' size={15} onClick={() => { }} />{`${nonEditable[0]?.title}, ${nonEditable[1]?.title} and ${nonEditable.length - 2} others`}
                    </div> :
                    <div className="textSM" style={{ color: 'var(--primary)', backgroundColor: 'var(--blueShade)', padding: "0.875rem 0.75rem", borderRadius: "4px", alignItems: "center", gap: "0.5rem" }}>
                        <MdOutlineLock className='textXS primary me-2' size={15} onClick={() => { }} />{`${nonEditable[0]?.title} and ${nonEditable[1]?.title}`}
                    </div>
                }
            </span>
            <div style={{ overflowY: 'scroll', flex: 1 }}>
                <VerticalList
                    items={selectedCols?.filter((el) => !disabledCols.includes(el.metric_col_code))}
                    onChange={onOrderChange}
                />
            </div>
        </div >
    }

    const DrawerMetricDescComp = () => {
        return <div style={{ padding: "1.25rem", display: "flex", flexDirection: "column", gap: "0.5rem", backgroundColor: "var(--blue2)", flex: 1 }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <span className='textSM black w-700'>{selectedm?.metric_name}</span>
            </div>
            <span style={{ minHeight: "18rem", maxHeight: "18rem", overflowY: "scroll" }}>
                {selectedm?.metric_desc}
            </span>
        </div>
    }

    const DrawerTitle = () => {

        if (!isMobile) return null;
        return (
            <div className='d-flex align-items-center' style={{ gap: "1rem" }} >
                {!selectedCategory ? <AiOutlineClose onClick={() => { cancelChanges() }} /> : <IoIosArrowBack onClick={() => {
                    if (selectedCategory && !selectedMetric) {
                        setselectedCategory('');
                    }
                    else if (selectedCategory && selectedMetric) {
                        setselectedMetric('');
                    }
                }} />}
                <div>
                    {!selectedCategory ? "Select category" : !selectedMetric ? "Select metric" : "Customize metrics"}
                </div>
            </div>
        )
    }

    const Drawerfooter = () => {

        if (!isMobile) return null;
        return (
            <div className='d-flex align-items-center' style={{ gap: "1rem" }} >
                <button className='btnWhite w100 flex-grow-1 ' onClick={() => { cancelChanges() }}>Cancel</button>
                <button className='btnBlack w100 flex-grow-1 ' onClick={() => { applyChanges() }}>Apply changes</button>
            </div>
        )
    }

    const MetricInfoView = () => {

        var presentIndex = selectedCols?.findIndex((met) => met?.dataIndex === selectedm?.metric_col_code)

        const isDisabled = disabledCols?.includes(selectedm?.metric_col_code);
        // //console.log(disabledCols)
        return (
            <div style={{ flex: 1, display: "flex", flexDirection: "column", overflowY: "auto" }}>
                <div className='textSM w-700'>{selectedm?.metric_name}</div>
                <div style={{ border: "1px solid var(--grey2)", padding: "1rem", borderRadius: "var(--borderRadius)", marginTop: "0.75rem", height: "25vh", overflowY: "auto" }}
                    className='metricDescDrawer'>
                    {selectedm?.metric_desc}
                </div>
                <Divider style={{ margin: "1rem 0" }} />
                <AccessComponent component={
                    <button className="btnBlack " onClick={() => {
                        changeDataCols(selectedm);
                    }} style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "center" }} disabled={isDisabled}>{(presentIndex !== -1 || isDisabled) ? <>
                        <AiOutlineClose />
                        Remove this metric
                    </> : <>
                        <AiOutlinePlus /> Use this metric
                    </>}
                    </button>} reqAccessLevel={selectedm?.auth_flag} upgradeComponent={<div>
                        Upgrade to sharpely <PlanName accessLevel={selectedm?.auth_flag} /> plan to use this metric. <Link to='/pricing' target='_blank'>See pricing</Link>
                    </div>} />
                <Divider style={{ margin: "1rem 0" }} />
                <div style={{ flex: 1, display: "flex", flexDirection: "column", overflowY: "auto" }}>
                    <div className='textSM w-700'>Filters</div>
                    <div style={{ flex: 1, overflowY: "auto", display: "flex" }}>
                        <AppliedFilterView />
                    </div>
                </div>
            </div>
        )
    }

    const cancelChanges = () => {
        setCustomizeMetrics(false);
        setSelectedCols(finalDataCols || []);
    }

    const applyChanges = async () => {
        setFinalDataCols(selectedCols);
        setCustomizeMetrics(false);
        let metrics = [...selectedCols];
        let snapshot = metrics?.map((el) => el?.metric_col_code)?.filter((el) => !disabledCols.includes(el)) || [];
        updateUserCustomCols(snapshot);
    }
    return (
        <React.Fragment>
            <button className={btnClassName} style={{ display: "flex", alignItems: "center", gap: "0.25rem", justifyContent: "center", }}
                onClick={() => {
                    if (user?.isLogged) {
                        setCustomizeMetrics(true)
                    }
                    else {
                        dispatch(toggleloginModal(true))
                    }
                }}>
                <BiCustomize /> {ctaText}
            </button>
            <Drawer width={isMobile ? "100vw" : "75vw"} closable={false} title={isMobile ? <DrawerTitle /> : false} onClose={() => { cancelChanges() }} open={customizeMetrics}
                footer={isMobile ? <Drawerfooter /> : false}
                styles={{ header: isMobile ? { padding: "1rem" } : {}, body: isMobile ? { padding: "1rem" } : {}, footer: isMobile ? { padding: "1rem" } : {} }}>
                {!isMobile ? <Card actions={[<div style={{ display: "flex", gap: "0.5rem", alignItems: "center", justifyContent: "flex-end", paddingRight: "1rem" }}>
                    <button className="btnWhite" onClick={() => { cancelChanges() }}>Cancel</button>,
                    <button className="btnBlack" onClick={() => { applyChanges() }}>Apply changes</button></div>]}
                    bodyStyle={{ display: "flex", flex: 1, height: "100%", padding: 0, overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%" }}
                    title={<span style={{ display: "flex", alignItems: "center" }}>
                        <CardHeader heading={'Select and order metrics'} hideHeaderBlueLine headingType={2} />
                        <div>
                            <MetricSearch data={screenerFinalData} type='customize' finalDataCols={selectedCols} isMultiple={true} insType={type.value}
                                onChange={onMetricSearch} disabledCols={disabledCols} disable={true} />
                        </div>
                        <IoIosClose onClick={() => { cancelChanges() }} size={"1.75rem"} style={{ cursor: "pointer" }} />
                    </span>} bordered>
                    <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", paddingRight: 0, overflowY: "scroll" }}
                        style={{ display: "flex", flexDirection: "column", flex: 1.2, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                        {screenerFinalData?.map((item, index) => {
                            if (item.isVisibleNormally === true) {
                                return renderRow({
                                    item, index
                                })
                            } else {
                                return <></>
                            }
                        })}
                    </Card>
                    <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", overflowY: "scroll" }}
                        style={{ display: "flex", flexDirection: "column", flex: 1.1, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                        <RenderIntialFlatlist item={screenerFinalData.filter((i) => i.category_id === selectedCategory)[0]} />
                    </Card>
                    <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", overflowY: "scroll", padding: 0 }}
                        style={{ display: "flex", flexDirection: "column", flex: 2.7, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                        <AppliedFilterView />
                        <DrawerMetricDescComp />
                    </Card>
                </Card> :
                    <div style={{ height: "100%", overflowY: "hidden", display: "flex", flexDirection: "column" }}>
                        {!selectedCategory ? <div style={{ flex: 1, overflowY: "auto" }}>
                            {screenerFinalData?.filter((item) => item.isVisibleNormally === true)?.map((item, index, arr) => {
                                let lastIndex = arr?.length - 1;
                                return renderRow({
                                    item, index, lastIndex
                                })
                            })}
                        </div> : !selectedMetric ? <div style={{ flex: 1, overflowY: "auto" }}>
                            <RenderIntialFlatlist item={screenerFinalData.filter((i) => i.category_id === selectedCategory)[0]} />
                        </div> : <MetricInfoView />}
                    </div>}
            </Drawer>
        </React.Fragment>
    )
}