import { Modal, Select } from "antd";
import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { multipleValueComp } from "./ScreenerUtils";

export const MetricsEditor = React.memo(({ open, toggle = () => { }, width = 'min(100%, 30rem)', selectedMetric = {}, onDone = () => { } }) => {

    const [selectedComp, setSelectedComp] = React.useState(selectedMetric?.comparator);
    const [selectedItems, setSelectedItems] = React.useState(selectedMetric?.selectedItems);

    const onMetricDone = () => {
        let query = {
            expression: selectedMetric?.metric?.label,
            expression_arr: selectedItems,
            type: "metric",
            isMultiple: true,
            isMetric: true,
            expression_details: selectedMetric?.metric,
            comparator: selectedComp
        }
        onDone({ query, queryKey: selectedMetric?.queryKey })
    }

    return (
        <React.Fragment>
            <Modal open={open} footer={null} onCancel={toggle} onOk={toggle} width={width} closable={false} centered>
                <div onClick={(e) => e.stopPropagation()}>
                    <div className='textMD w-700 dark4' >{selectedMetric?.metric?.label}</div>
                    <div className='d-flex justify-content-between' style={{ marginTop: "1.25rem" }}>
                        <Select options={multipleValueComp} value={selectedComp} style={{ width: "10rem" }} onChange={(val) => setSelectedComp(val)} />
                        <Select options={selectedMetric?.metric?.dataToRenderFrom} value={selectedItems} style={{ width: "15rem" }} className='textSM black'
                            mode="multiple" maxTagCount='responsive' showSearch optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            } suffixIcon={<IoIosArrowDown size={15} />} popupClassName="textSM black" onChange={(val) => {
                                setSelectedItems(val);
                            }} />
                    </div>
                    <div className="d-flex justify-content-end" style={{ gap: "1.25rem", marginTop: "1.25rem" }}>
                        <button className="btnWhite" onClick={() => {
                            toggle();
                        }}>Cancel</button>
                        <button className="btnBlack" onClick={onMetricDone}>Done</button>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
})