import { Card, Select, Tooltip } from "antd";
import AMCIcon from "Components/AMCIcon";
import { CardHeader } from "Components/atoms/SmallComponents";
import { CurrencyFormat } from "Components/CurrencyFormat";
import CustomSegmentedTab from "Components/CustomSegmentedTab";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import ListLoader from "Components/ListLoader";
import RedGreenText from "Components/RedGreenText";
import { fetchFundData } from "Libs/fetchFundData";
import { getIndexList } from "Libs/utils";
import React from "react";
import { FiChevronDown } from "react-icons/fi";
import { HiChevronRight } from "react-icons/hi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AdminServicesPY from "Services/api/AdminServicesPY";

const TopGainersAndLosers = ({ allStocks = [], allIndices = [], type, format = 'top' }) => {
    // format
    // top => gainers
    // bot => losers
    const stockprices = useSelector((state) => state?.stockprices?.value)
    const prevstockprices = useSelector((state) => state?.prevStockPrices?.value)
    const [indices, setIndices] = React.useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState('NIFTY50');
    const [indexConstituents, setIndexConstituents] = React.useState({});
    const [allFunds, setAllfunds] = React.useState([])
    const [allFilteredFunds, setAllFilteredfunds] = React.useState([])
    const [load, setLoad] = React.useState(true)
    const [selectedTab, setSelectedTab] = React.useState('top');

    const sortFunds = (arr, key, order = "top") => {
        let arrr = [...arr]?.filter((i) => (i?.[key] !== undefined && i?.[key] !== NaN && i?.[key] !== null))
        if (order === "bot") {
            arrr = arrr.sort((a, b) => a[key] - b[key])
        } else {
            arrr = arrr.sort((a, b) => b[key] - a[key])
        }
        return arrr?.filter((i) => order === "top" ? i[key] > 0 : i[key] < 0).slice(0, 5);
    }

    const loadData = async () => {
        let allinds = allIndices
        allinds = allinds?.map((el, i) => {
            return {
                ...el,
                key: i,
                label: el?.IndexName,
                value: el?.IndexNameCaps
            }
        }).concat([{ key: "all", label: "All stocks", value: "all" }])
        setIndices(allinds);
        try {
            let res = await AdminServicesPY.getIndexConstituents();
            setIndexConstituents(JSON.parse(res?.data))
        } catch (error) {
            //console.log(error);
        }
        let funds = allStocks
        let result = await AdminServicesPY.getStocksColData({
            stocks: funds?.map((fund) => { return fund?.symbol }), cols: ['price', 'one_day_pct']
        })
        result = JSON.parse(result?.data)
        result = result?.map((fund) => {
            return {
                ...funds?.filter((ii) => ii?.symbol === fund?.symbol)?.[0],
                ...fund,
                // "gdf_symbol": fund?.nse_series === 2 ? fund?.symbol + ".BE" : fund?.nse_series === 3 ? fund?.symbol + ".BZ" : fund?.symbol
                "gdf_symbol": fund?.symbol
            }
        })
        setAllfunds(result);
    }

    const getFilteredData = async () => {
        var allfundsc = selectedIndex !== 'all' ? allFunds?.filter((el) => {
            let symbols = indexConstituents?.[selectedIndex]?.syms || [];
            return symbols?.includes(el?.symbol);
        }) : allFunds
        // var allfundsc = type.value === 4 && indexCode !== 'all' ? await screenerQueryBuilder([[indexCode, 1]], [...(stock_universe?.map((el) => el?.value)?.filter((el) =>
        //     el !== 'all')), 'price', 'one_day_pct']) : allFunds
        //console.log(allfundsc)
        allfundsc = allfundsc.map((fund) => {
            fund = {
                ...fund,
                ...allFunds?.filter((ii) => ii?.symbol === fund?.symbol)?.[0],
            }
            var changeVal, changePer, currentLTP = "-"
            var currentSymbolAllData = stockprices === "close" ? prevstockprices?.[fund?.gdf_symbol] : (stockprices?.[fund?.gdf_symbol] || prevstockprices?.[fund?.gdf_symbol])
            currentLTP = currentSymbolAllData?.LastTradePrice
            changeVal = currentSymbolAllData?.PriceChange
            changePer = currentSymbolAllData?.PriceChangePercentage

            if (currentSymbolAllData == undefined) {
                let ele = fund
                currentLTP = ele?.price || 0
                changePer = ele?.one_day_pct || 0
                let originalPrice = currentLTP / (1 + (changePer / 100))
                changeVal = currentLTP - originalPrice;
            }

            return {
                ...fund,
                "currentLTP": currentLTP,
                "changePer": changePer,
                "changeVal": changeVal
            }
        })
        //console.log(allfundsc);
        //console.log(sortFunds(allfundsc, 'changePer', selectedTab))
        setAllFilteredfunds(sortFunds(allfundsc, 'changePer', selectedTab))
        setLoad(false)
    }

    React.useEffect(() => {
        if (stockprices.length !== 0 && allFunds.length !== 0) {
            getFilteredData()
        }
    }, [allFunds, stockprices, prevstockprices, selectedIndex, selectedTab])

    React.useEffect(() => {
        setLoad(true)
        loadData()
    }, [])

    const tabs = [
        { key: 0, label: "Top Gainers", value: "top" },
        { key: 1, label: "Top Losers", value: "bot" }
    ]

    return (
        <Card bordered={false} style={{ padding: 0 }} bodyStyle={{ padding: 0 }} className="mobCardBorderRadius0">
            <div className='pad20Container' style={{ paddingBottom: 0 }}>
                <CustomSegmentedTab className='textXS' value={selectedTab} style={{ margin: '0 -0.5rem', width: 'calc(100% + 1rem)', maxWidth: 'calc(100% + 1rem)' }} onChange={(e) => {
                    setSelectedTab(e)
                }} options={tabs} />
            </div>
            <div className='pad20Container' style={{ display: "flex", alignItems: "center" }}>
                <CardHeader heading={selectedTab === 'top' ? 'Top gainers' : 'Top losers'} headingType={2} />
                <div style={{}}>
                    <Select
                        style={{ width: "6.5rem" }}
                        value={selectedIndex}
                        onChange={(value) => {
                            setSelectedIndex(value)
                        }}
                        suffixIcon={<FiChevronDown size={12} />}
                        options={indices}
                        dropdownStyle={{
                            width: "15rem"
                        }}
                    />
                </div>
            </div>
            {load ? <ListLoader /> : <div>
                {allFilteredFunds?.length > 0 ? <>
                    {
                        allFilteredFunds?.map((f, i) => {
                            return <div key={i} className="borderAll" style={{ backgroundColor: i % 2 !== 0 ? 'var(--white)' : 'var(--grey4)' }}>
                                <Link to={type.basePath + `${type.value === 4 ? '/stock-detail/' + f?.proper_name?.replaceAll(' ', '-') + '/' + f.symbol : '/fund-detail/' + f?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + f.plan_id}`}>
                                    <div className="pad20Side6VerContainer" style={{ display: "flex", gap: "0.5rem", alignItems: "center" }} >
                                        <AMCIcon amcName={type.value === 4 ? f.symbol : f.amc_full_name} fundType={type.value} height='1.5rem' width="1.5rem"
                                            bseTicker={f?.bse_ticker} />
                                        <Tooltip title={<span className='textXS w-500'>{type.value === 4 ? f?.proper_name : f?.basic_name?.replace(" - Direct Plan", "")}</span>} arrow={false}>
                                            <div className="textXS black hoverBold" style={{ flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                                                {type.value === 4 ? f?.proper_name : f?.basic_name?.replace(" - Direct Plan", "")}
                                            </div>
                                        </Tooltip>
                                        <span className="textXS w-500 black" style={{ display: "flex", flexDirection: "column", gap: "0.1rem", alignItems: "flex-end", textAlign: "right", minWidth: "5rem" }}>
                                            <span style={{ textAlign: "right" }}>{CurrencyFormat(f.currentLTP, 'long')}</span>
                                            <span className="dark3" style={{ flex: 1, textAlign: "right" }}>
                                                <RedGreenText text={`${DecimalValueFormat({ num: f.changeVal, suffix: '', decimals: 1 }).replace("NaN", "0.0")} (${DecimalValueFormat({ num: f.changePer, suffix: "%", decimals: 2 }).replace("NaN", "0.00")})`} value={f.changePer} />
                                            </span>
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        })}
                    <div style={{ color: "var(--primary)", cursor: "pointer", textAlign: "right" }} className='textSM w-500 pad20Side12VerContainer' >
                        <Link to={`/market-watch?type=${selectedTab === 'top' ? "top-gainers" : "top-losers"}`} className='d-flex align-items-center justify-content-end'>
                            See all <HiChevronRight />
                        </Link>
                    </div>
                </>
                    : <div style={{ flex: 1 }}>
                        {selectedTab !== "top" ? <div className="textSM pad20Side6VerContainer my-4">
                            Looks like a great day for markets! None of the stocks in the index are in red.
                        </div> : <div className="textSM pad20Side6VerContainer my-4">
                            Oh...it's a bloodbath! None of the stocks in the index are in green.
                        </div>}
                    </div>}

            </div>}
        </Card>
    )
}

export default TopGainersAndLosers;