export const stockExclusionList = {
    title: 'sharpely Exclusion List',
    description: `<p>For both screener and factor model, stocks that are in the “exclusion list” are filtered out by default.</p>
        <p><br/></p>
    <p>
        <strong>What is exclusion list?</strong>
    </p>
    <p><br /></p>
    <p>Exclusion list is a set of stocks that fall into any one of the categories:</p>
    <p><br /></p>
    <ul>
        <li>Stocks that are proposed to be delisted</li>
        <li>Stocks that have moved into surveillance – either Additional Surveillance Measure (ASM) or Graded Surveillance Measure (GSM)</li>
        <li>Stocks that have been moved to Trade for Trade</li>
    </ul>
    <p><br /></p>
    <p>
        <strong>Why exclude stocks?</strong>
    </p>
    <p><br /></p>
    <p>Note that both the stock screener and the factor model can be used to build a basket (investible portfolio) and invest in the basket. As a pre-emptive precautionary measure, we filter out stocks that qualify for our exclusion list. This prevents investors from accidentally investing in stocks that are to be delisted or could potentially be suspended for trading in the near future.</p>`
}

export const rebalanceContent = {
    static:{
        title:"What happens on rebalancing?",
        description:`
            <div class="textSM dark4">
                <div class="textMD w-700 mb-2">You make an actual investment in the basket</div>
                <div class="textSM dark4 mb-2">
                    Over time, weights of instruments in your basket can change from the target weight. Purpose of rebalancing will be to bring back the basket to original weight by selling overweight instruments and buying underweight instruments. More details on rebalancing can be read <a href="https://sharpely.in/knowledge-base/baskets/custom-stock-baskets/invest-in-stock-baskets" target="_blank">here</a>.
                </div>
                <div class="textSM dark4 mb-2">
                    A rebalance alert is triggered on the rebalancing dates corresponding to your chosen frequency. For example, if you have chosen to rebalance your portfolio every month on 5th, a rebalance alert will be triggered on 5th of every month.
                </div>
                <div class="textSM dark4 mb-2">
                    You will be notified on the app and through email and the alert will also be visible on notification section.
                </div>
                <div class="textSM dark4 mb-2">
                    Note that rebalancing will NOT be executed without your explicit authentication. You need to login and authenticate the orders. You will be shown full details of all the   orders (buy/sell), existing portfolio and post-rebalancing portfolio.
                </div>
                <div class="textSM dark4 mb-2">
                    If you don’t have sufficient funds in your broking account, you will need to top up funds for rebalancing to be executed.
                </div>
                <div class="textMD w-700 mb-2">You paper invest in the basket</div>
                <div class="textSM dark4 mb-2">
                    In this case, rebalancing just serves as an input for back testing the basket performance. However, note that back tested performance will account for the frequency that you have chosen but the rebalancing may not necessarily happen on the exact chosen dates.
                </div>
            </div>
        `
    },
    dynamic:{
        title:"What happens on rebalancing?",
        description:`
        <div class="textSM dark4">
            <div class="textMD w-700 mb-2">You make an actual investment in the basket</div>
            <div class="textSM dark4 mb-2">
            In dynamic baskets, stocks in your portfolio are dynamic and are derived using a screen or a factor model. The purpose of rebalancing is to create a completely new portfolio based on your stock selection logic, sell the old stocks, and buy the new ones. More details on rebalancing can be read <a href="https://sharpely.in/knowledge-base/baskets/custom-stock-baskets/invest-in-stock-baskets" target="_blank">here</a>.
            </div>
            <div class="textSM dark4 mb-2">
            For example, for a basket based on a screen, a new portfolio is created by running your screen logic on the latest data and your portfolio is changed accordingly.
            </div>
            <div class="textSM dark4 mb-2">
            Since overall churn in these kinds of strategies are high, we do not allow weekly frequencies for dynamic baskets.
            </div>
            <div class="textSM dark4 mb-2">
            A rebalance alert is triggered on the rebalancing dates corresponding to your chosen frequency. For example, if you have chosen to rebalance your portfolio every month on 5th, a rebalance alert will be triggered on 5th of every month.
            </div>
            <div class="textSM dark4 mb-2">
            You will be notified on the app and through email and the alert will also be visible on notification section.
            </div>
            <div class="textSM dark4 mb-2">
            Note that rebalancing will NOT be executed without your explicit authentication. You need to login and authenticate the orders. You will be shown full details of all the orders (buy/sell), existing portfolio and post-rebalancing portfolio.
            </div>
            <div class="textSM dark4 mb-2">
            If you don’t have sufficient funds in your broking account, you will need to top up funds for rebalancing to be executed.
            </div>
            <div class="textMD w-700 mb-2">You paper invest in the basket</div>
            <div class="textSM dark4 mb-2">
            In this case, rebalancing just serves as an input for back testing the basket performance. However, note that back tested performance will account for the frequency that you have chosen but the rebalancing may not necessarily happen on the exact chosen dates.
            </div>
        </div>
    `
    }
}

export const noRebalanceContent = {
    title:"What is this?",
    description:`
    <div class="textSM dark4">
        <div class="mb-2">
        There will be times when the weights of your portfolio deviates slightly from target weights but rebalancing cannot be carried out due to price constraint of the underlying.
        </div>
        <div class="mb-2">
        For example consider a portfolio of 2 stocks A and B. Prices of both stocks are 500 each and you hold 1 share of each. Your portfolio value is 1000.
        </div>
        <div class="mb-2">
        Now suppose stock A goes to 600 and stock B remains at 500. Your portfolio value is 1100 but the weight of stock A is 55% while stock B is 45%. Ideally, you want the weights to be  brought back to 50%. However, you cannot do so by selling A and buying B give the prices at which A and B trade.
        </div>
        <div class="mb-2">
        Note that this is just a toy example to illustrate the cases where even though a rebalancing is required, it cannot be carried out.
        </div>
    </div>
    `
}