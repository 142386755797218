import { Card, Select } from 'antd';
import AccessComponent from 'Components/AccessComponent';
import AccessControlledPage from 'Components/AccessControlledPage';
import { CardHeader } from 'Components/atoms/SmallComponents';
import CustomSegmentedTab from 'Components/CustomSegmentedTab';
import { formatDate } from 'Components/DateFormatter';
import InstrumentListTable from 'Components/InstrumentListTable';
import LoadingTableSkeleton from 'Components/LoadingTableSkeleton';
import UpgradeToPro from 'Components/UpgradeToPro';
import Head from 'Layout/Head';
import { displayValue } from 'Libs/utils';
import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { TiDelete } from 'react-icons/ti';
import AdminServicesPY from 'Services/api/AdminServicesPY';
import apiHelperPY from 'Services/api/ApiHelperPY';

const EarningsDashboard = ({ paginationSize = 0 }) => {
    const [loading, setloading] = useState(true)
    const tabOptions = [
        {
            "value": "eps",
            "queryStr": "eps",
            "label": "Earnings per share"
        },
        {
            "value": "sales",
            "queryStr": "sales",
            "label": "Sales"
        }
    ]

    const getWeightMethodOpts = React.useCallback(() => {
        let d = new Date(),
            y = d.getFullYear(),
            m = d.getMonth(),
            quarters = ['03', '06', '09', '12'],
            dates = ['31', '30', '30', '31'],
            options = [];

        for (var pastYear = 2019; pastYear < y; pastYear++) {
            quarters.forEach((q, i) => options.push(pastYear + '-' + q + '-' + dates[i]));
        }
        quarters.slice(0, parseInt(m / 3)).forEach((q, i) => options.push(pastYear + '-' + q + '-' + dates[i]));
        let temp = options.reverse().map((i) => {
            return {
                "value": i,
                "label": formatDate(new Date(i), "date"),
                "key": i,
            }
        });
        return temp;
    }, []);

    const weightMethodOptions = getWeightMethodOpts()

    const [selectedTabOption, setselectedTabOption] = useState(tabOptions[0].value)
    const [selectedWeightMethod, setselectedWeightMethod] = useState(weightMethodOptions[0].value)
    const [clasificationData, setclasificationData] = useState({})
    const [dataTableColumns, setdataTableColumns] = useState({})

    const customizeByResult = async (earningsData = {}) => {
        let res = await apiHelperPY({ apiName: "getEventCalendar", data: {}, saveResponse: "" });
        let tempED = { ...earningsData };
        if (res) {
            res = JSON.parse(res) || []
            res = res?.filter((el) => el?.PURPOSE === 'Financial Results');
            let d = new Date();
            let y = d.getFullYear();
            let m = d.getMonth();
            let quarterDates = [['01', '31'], ['01', '30'], ['01', '30'], ['01', '31']]
            let quarters = [['01', '03'], ['04', '06'], ['07', '09'], ['10', '12']];
            let currQuarter = parseInt(m / 3);
            let startDate = y + '-' + quarters?.[currQuarter]?.[0] + '-' + quarterDates?.[currQuarter]?.[0];
            startDate = new Date(startDate);
            // let endDate = y + '-' + quarters?.[currQuarter]?.[1] + '-' + quarterDates?.[currQuarter]?.[1];
            let endDate = new Date()
            endDate.setDate(endDate.getDate() - 3);
            console.log(startDate, endDate)
            let temp = res?.filter((el) => {
                let c1 = new Date(el?.DATE).getTime() >= startDate.getTime();
                let c2 = new Date(el?.DATE).getTime() <= endDate.getTime();
                return c1 && c2;

            })
            tempED.eps = tempED.eps.sort((a, b) => {
                let a2 = temp?.find((el) => el?.SYMBOL === a?.symbol)
                let b2 = temp?.find((el) => el?.SYMBOL === b?.symbol)
                if (a2 && b2) {
                    return new Date(b2.DATE) - new Date(a2.DATE);
                }
                else if (a2 && !b2) {
                    return -1;
                }
                else if (!a2 && b2) {
                    return 1;
                }
                else return 0;
            })
            tempED.sales = tempED.sales.sort((a, b) => {
                let a2 = temp?.find((el) => el?.SYMBOL === a?.symbol)
                let b2 = temp?.find((el) => el?.SYMBOL === b?.symbol)
                if (a2 && b2) {
                    return new Date(b2.DATE) - new Date(a2.DATE);
                }
                else if (a2 && !b2) {
                    return -1;
                }
                else if (!a2 && b2) {
                    return 1;
                }
                else return 0;
            })
        }
        return tempED;
    }

    const loadData = async () => {
        setloading(true)
        var earningsData = await AdminServicesPY.earningsDashboard(selectedWeightMethod).then((res) => res)
        earningsData = JSON.parse(earningsData.data.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))
        if (selectedWeightMethod === weightMethodOptions?.[0]?.value) {
            let t = await customizeByResult(earningsData);
            console.log(t)
            setclasificationData(t)
        }
        else {
            setclasificationData(earningsData)
        }
        var eps = Object.entries(earningsData["eps"][0]).filter(([key]) => key !== "proper_name" && key !== "mcap" && key !== "symbol" && key !== "fsym_id").map(([key, val], index) => {
            var id = key
            key = key.replace("_", " ").replace("qoq", "QoQ").replace("yoy", "YoY")
            return {

                "title": key.charAt(0).toUpperCase() + key.slice(1),
                "dataIndex": id,
                'disableDeleteInCustomize': true,
                "key": id,
                "metric_col_code": id,
                "align": 'right',
                "metric_unit": "",
                "metric_data_type": "float",
                "metric_name": key.charAt(0).toUpperCase() + key.slice(1),
                render: (text, record, index) => <span style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "flex-end" }}>
                    <span>
                        {displayValue({
                            text, metricObj: {
                                "title": key.charAt(0).toUpperCase() + key.slice(1),
                                "dataIndex": id,
                                "key": id,
                                "metric_col_code": id,
                                "align": 'right',
                                "metric_unit": typeof (text) === "number" || typeof (text) === "object" ? id === "actual" || id === "estimated" ? "" : "percentage" : "",
                                "metric_data_type": typeof (text) === "number" || typeof (text) === "object" ? "float" : "string",
                                "metric_name": key.charAt(0).toUpperCase() + key.slice(1),
                            }
                        })}
                    </span>
                </span>,
                width: 160,
                sorter: (b, a) => {
                    return a?.[id] - b?.[id]
                }
            }
        })

        var sales = Object.entries(earningsData["sales"][0]).filter(([key]) => key !== "proper_name" && key !== "mcap" && key !== "symbol" && key !== "fsym_id").map(([key, val], index) => {
            var id = key
            key = key.replace("_", " ").replace("qoq", "QoQ").replace("yoy", "YoY")
            return {

                "title": key.charAt(0).toUpperCase() + key.slice(1),
                "dataIndex": id,
                'disableDeleteInCustomize': true,
                "key": id,
                "metric_col_code": id,
                "align": 'right',
                "metric_unit": "",
                "metric_data_type": "float",
                "metric_name": key.charAt(0).toUpperCase() + key.slice(1),
                render: (text, record, index) => <span style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "flex-end" }}>
                    <span>
                        {displayValue({
                            text, metricObj: {
                                "title": key.charAt(0).toUpperCase() + key.slice(1),
                                "dataIndex": id,
                                "key": id,
                                "metric_col_code": id,
                                "align": 'right',
                                "metric_unit": typeof (text) === "number" || typeof (text) === "object" ? id === "actual" || id === "estimated" ? "rupee_cr" : "percentage" : "",
                                "metric_data_type": typeof (text) === "number" || typeof (text) === "object" ? "float" : "string",
                                "metric_name": key.charAt(0).toUpperCase() + key.slice(1),
                            }
                        })}
                    </span>
                </span>,
                width: 160,
                sorter: (b, a) => {
                    return a?.[id] - b?.[id]
                }
            }
        })

        setdataTableColumns({
            "eps": eps,
            "sales": sales
        })
        setloading(false)
    }

    React.useEffect(() => {
        loadData()
    }, [selectedWeightMethod])

    return (
        <React.Fragment>
            <Head title='Stocks Earnings Dashboard' description={'Get comprehensive stock info, historical stock quotes, and stock charts for your investments. Stay informed about stocks earnings today with sharpely. Access the tools you need for smart investing.'} />
            <AccessControlledPage moduleKey={'EARNINGS_DASHBOARD'} />
            <AccessComponent component={
                loading ? <Card bordered={false} style={{ flex: 1 }} title={<div style={{ display: "flex", gap: "0.5rem" }} className='mobCardBorderRadius0'>
                    <CardHeader heading={"Earnings Dashboard"} headingType={1} />
                    <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                        <div className='textSM w-500'>Quarter:</div>
                        <Select removeIcon={<TiDelete size={12} />}
                            className='textSM black mx-1'
                            suffixIcon={<IoIosArrowDown size={15} />}
                            style={{
                                width: "150px"
                            }}
                            defaultValue={selectedWeightMethod}
                            placeholder="Please select weighing method for stocks"
                            onChange={(value) => {
                                setselectedWeightMethod(value)
                            }}
                            options={weightMethodOptions}
                        />
                    </div>
                </div>}>
                    <LoadingTableSkeleton />
                </Card> : <div className='normalContainer'>
                    <Card bordered={false} style={{ flex: 1 }} title={<div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
                        className='mobileFlexCol'>
                        <CardHeader heading={"Earnings Dashboard"} headingType={1} />
                        <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                            <div className='textSM w-500'>Quarter:</div>
                            <Select removeIcon={<TiDelete size={12} />}
                                className='textSM black mx-1'
                                suffixIcon={<IoIosArrowDown size={15} />}
                                style={{
                                    width: "150px"
                                }}
                                defaultValue={selectedWeightMethod}
                                placeholder="Please select weighing method for stocks"
                                onChange={(value) => {
                                    setselectedWeightMethod(value)
                                }}
                                options={weightMethodOptions}
                            />
                        </div>
                    </div>} className='mobCardBorderRadius0'>
                        <InstrumentListTable
                            finalList={clasificationData?.[selectedTabOption]?.filter((el) => el?.actual !== null && el?.actual !== undefined)}
                            tableType={6}
                            hideCheckBox={true}
                            allowInitSort={false}
                            isCard={false}
                            showSearch={true}
                            istableLoading={loading}
                            hideInvestBtn={false}
                            hideSettings={true}
                            noPreviousPrice
                            otherCol={dataTableColumns[selectedTabOption]}
                            tableHeader={<CustomSegmentedTab
                                flexVal={'unset'}
                                isBlock={false}
                                className='textXS black'
                                options={tabOptions}
                                onChange={(value) => {
                                    setselectedTabOption(value)
                                }}
                                value={selectedTabOption}
                            />}
                            hideHeaderBlueLine
                        />
                    </Card>
                </div>
            } moduleKey='EARNINGS_DASHBOARD' upgradeComponent={<div>
                <Card bordered={false} style={{ flex: 1 }} title={<div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
                    className='mobileFlexCol'>
                    <CardHeader heading={"Earnings Dashboard"} headingType={1} />
                </div>}>
                    <UpgradeToPro moduleKey='EARNINGS_DASHBOARD' descClassName='textESM w-500 dark4 mb-2' />
                </Card>
            </div>} />

        </React.Fragment >
    )
}

export default EarningsDashboard