import { alphaLine, basketLine, compareLine, earningLine, etfLine, factorLine, magicLine, mfLine, screenLine, sectorLine, stockLine, styleboxLine } from "Assets/images";
import { CardHeader } from "Components/atoms/SmallComponents";
import { useIsMobile } from "CustomHooks/useIsMobile";
import Head from "Layout/Head";
import { setOpenSearchModal, toggleBasketCreateModal } from "Libs/redux/Modals/reduxSlice";
import { makeid, useGetAssetType } from "Libs/utils";
import { Card } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const PaymentStatus = ({ isSuccess = true }) => {

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const stockType = useGetAssetType(4);
    const etfType = useGetAssetType(1);
    const mfType = useGetAssetType(2);

    const isMobile = useIsMobile();
    const isFreeTrial = Boolean(Number(params?.isTrial));
    const frequency = params?.frequency;
    const plan = params?.planType;
    const paymentOption = params?.paymentOption?.replace('-', ' ');


    React.useEffect(() => {
        var eventname = `${isFreeTrial ? "trial" : paymentOption}_${plan}_${frequency}_${isSuccess ? "success" : "fail"}`
        window.dataLayer?.push({ event: eventname });
    }, [])


    const getProducts = React.useCallback(() => {

        let getTypeItems = (type) => {
            let items = [
                {
                    key: 0,
                    color: 'var(--primary)',
                    background: "var(--blueShade)",
                    title: `${type.value === 4 ? 'Stock' : type.value === 1 ? "ETF" : "MF"} dashboard`,
                    onClick: () => {
                        navigate(`${type.basePath}/dashboard`)
                    },
                    icon: type.value === 4 ? stockLine : type.value === 1 ? etfLine : mfLine,
                    show: true,
                    viewType: 'row'
                },
                {
                    key: 1,
                    color: 'var(--yellow)',
                    background: "var(--yellowShade)",
                    title: `Compare ${type.value === 4 ? 'stocks' : type.value === 1 ? "ETFs" : "MFs"}`,
                    onClick: () => {
                        navigate(`${type.basePath}/compare`)
                    },
                    icon: compareLine,
                    show: true,
                    viewType: "row"
                },
                {
                    key: 2,
                    color: 'var(--orange)',
                    background: "var(--orangeShade)",
                    title: `Sector Analysis`,
                    onClick: () => {
                        navigate(`${type.basePath}/sectors`)
                    },
                    icon: sectorLine,
                    show: type.value === 4,
                    viewType: "row"
                },
                {
                    key: 3,
                    color: 'var(--black)',
                    background: "var(--grey1)",
                    title: `Styleboxes`,
                    onClick: () => {
                        navigate(`${type.basePath}/styleBox`)
                    },
                    icon: styleboxLine,
                    show: type.value === 4,
                    viewType: "row"
                },
                {
                    key: 4,
                    color: 'var(--primary)',
                    background: type.value === 4 ? "var(--blueShade)" : "var(--orangeShade)",
                    title: `${type?.strVal?.[0]?.toUpperCase() + type?.strVal?.slice(1)} Screener`,
                    onClick: () => {
                        navigate(`${type.basePath}/screener`)
                    },
                    icon: screenLine,
                    show: true,
                },
                {
                    key: 9,
                    title: `${type?.strVal?.[0]?.toUpperCase() + type?.strVal?.slice(1)} Magicboard`,
                    icon: magicLine,
                    show: type.value !== 4,
                    viewType: "row",
                    background: "var(--greenShade)",
                    onClick: () => {
                        navigate(`${type.basePath}/magicboard`)
                    },
                },
                {
                    key: 5,
                    color: 'var(--green)',
                    background: "var(--greenShade)",
                    title: `Factor Model`,
                    onClick: () => {
                        navigate(`${type.basePath}/factor-model`)
                    },
                    icon: factorLine,
                    show: type.value === 4,
                },
                {
                    key: 6,
                    color: 'var(--red)',
                    background: "var(--redShade)",
                    title: `Custom baskets`,
                    onClick: () => {
                        if (type.value === 4) {
                            dispatch(toggleBasketCreateModal({ open: true, type: stockType }))
                        }
                        else {
                            navigate(`${type.basePath}/build-custom-strategy`)
                        }
                    },
                    icon: basketLine,
                    show: true,
                },
                {
                    key: 7,
                    color: 'var(--purple)',
                    background: "var(--purpleShade)",
                    title: `Earnings dashboard`,
                    onClick: () => {
                        navigate(`${type.basePath}/earnings-dashboard`)
                    },
                    icon: earningLine,
                    show: type.value === 4,
                },
                {
                    key: 8,
                    color: 'var(--purple)',
                    background: "var(--purpleShade)",
                    title: `${type?.strVal?.[0]?.toUpperCase() + type?.strVal?.slice(1)} Alpha analysis`,
                    onClick: () => {
                        navigate(`${type.basePath}/alpha-analysis`)
                    },
                    icon: alphaLine,
                    show: type.value !== 4,
                }
            ]
            return items?.filter((el) => el?.show);
        }

        let t1 = getTypeItems(stockType);
        let t2 = getTypeItems(etfType);
        let t3 = getTypeItems(mfType);

        return [...t1, ...t2, ...t3]
    }, [])

    const products = getProducts();

    const tools = [
        {
            "key": "screener",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/screener.webp" },
            "title": "Screen stocks",
            "content": "Build almost any screen – fundamental, technical, or a mix of both. Work with multiple timeframes, view history, and backtest.",
            onClick: () => {
                navigate('/stocks/super-screener')
            }
        },
        {
            "key": "strategy",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/strategy1.webp" },
            "title": "Paper trade strategies",
            "content": "Stop relying on stock tips! Use Strategy Builder to build, backtest and paper trade strategies",
            onClick: () => {
                navigate("/strategies")
            }
        },
        {
            "key": "stylebox",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/stylebox.webp" },
            "title": "Use StyleBox",
            "viewType": "row",
            "content": "With over 5000+ stocks to choose from, our StyleBox classification lets you narrow down your research",
            onClick: () => {
                navigate("/stocks/stylebox")
            }
        },
        {
            "key": "srchart",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/earnings.webp" },
            "title": "Sector rotation chart",
            "content": "A powerful tool that helps you the gauge the strength and momentum of various sectors (or stocks)",
            onClick: () => {
                navigate("/sector-rotation-chart")
            }
        },
        {
            "key": "bfmodel",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/factor.webp" },
            "title": "Build factor models",
            onClick: () => {
                navigate("/stocks/factor-model")
            },
            "content": "With sharpely Factor Models, you can create your own factor-based quant models easily and intuitively.",
        },
        {
            "key": "checklist",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/checklist.webp" },
            "title": "Use stock checklist",
            "content": "See how your stock fares on checklists created by investing legends. You can create your own custom checklists as well",
            onClick: () => {
                navigate("/stocks/all-checklists")
            }
        },
        {
            "key": "stalert",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/strategy2.webp" },
            "title": "Stock alert",
            "content": "Create simple or complex alerts on your favourite stocks and get notified in near real time",
            onClick: () => {
                dispatch(setOpenSearchModal(true));
            }
        },
        {
            "key": "sanal",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/sector.webp" },
            "title": "Sector analysis",
            "content": "Our powerful sector analysis tool drills deep into sector fundamentals and technicals",
            "viewType": "row",
            onClick: () => {
                navigate('/stocks/sectors')
            }
        },
        {
            "key": "inanal",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/index.webp" },
            "title": "Index analysis",
            "content": "A comprehensive analysis of stock indices including aggregated fundamentals and techincals",
            onClick: () => {
                navigate('/indices')
            }
        },
        {
            "key": "mfetfresearch",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/magic.webp" },
            "title": "MF and ETF research",
            "content": "Our product comes loaded with rich research and analysis on ETFs and Mutual Funds as well.",
            onClick: () => {
                dispatch(setOpenSearchModal(true));
            }
        },
        {
            "key": "muchmore",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/alpha.webp" },
            "title": "And much more!",
            "content": "We have so much more to offer. sharpely is the only investing platform you will ever need.",
            onClick: () => {
                navigate('/stocks/dashboard')
            }
        }
    ]

    return (
        <React.Fragment>
            <Head title={`Payment ${isSuccess ? "successful" : "failed"} - ${plan?.[0]?.toUpperCase()}${plan?.slice(1)} ${paymentOption} ${frequency}`} />
            <div className="normalContainer" style={{ padding: isMobile ? "1.25rem" : "" }}>
                <div className='d-flex flex-column align-items-center justify-content-center w100' style={{ marginTop: "0rem" }}>
                    {/* <Lottie options={isSuccess ? successLottieOptions : failureLottieOptions} width='13rem' height='13rem' isClickToPauseDisabled /> */}
                    <img width='13rem' height='13rem' style={{
                        height: "13rem",
                        width: "13rem",
                    }} src={isSuccess ? require('Assets/lottie/congratulations.gif') : require('Assets/lottie/order-failed.gif')} alt="loading..." />
                    <h1 className='displaySM w-500 text-center black mb-3 lineHeightUnset' style={{ userSelect: "none", marginBottom: "1rem" }}>
                        {isSuccess ? 'Hurray! 🎉' : "Payment failed"}
                    </h1>
                    <div className='textMD w-500 text-center dark4' style={{ width: "90%", margin: "auto", userSelect: "none", maxWidth: "60ch" }}>
                        {isSuccess ?
                            <span>Congrats!!🎉🎉 Your sharpely {plan?.[0]?.toUpperCase()}{plan?.slice(1)} plan is now active. To make the most of your plan,
                                make sure to browse through the
                                &nbsp;<Link to={'/knowledge-base'}>knowledge base</Link>. Also, if you need any assistance, feel free to write to us at
                                &nbsp;<a href={'mailto:support@sharpely.in'}>support@sharpely.in</a></span> :
                            `The payment has failed, either due to the request expiring or encountering other issues such as network problems or bank server issues. If any amount has been deducted from your account, please report it immediately.`}
                    </div>
                </div>
                <div>
                    <CardHeader heading={'Explore all our products'} />
                    <div style={{ marginTop: "1.25rem" }}>
                        <Row lg={4} md={3} sm={2} xs={1} className="gx-4 gy-4">
                            {tools?.map((tool, i) => {
                                return (
                                    <Col key={makeid(i + 1)}>
                                        <div style={{ border: "1px solid var(--grey1)", padding: "1rem", borderRadius: "0.5rem", cursor: "pointer", background: "var(---white)" }}
                                            className="w100 h100 analyseStockItem" onClick={() => {
                                                tool.onClick()
                                            }}>
                                            <div className="d-flex align-items-center" style={{ gap: "0.75rem" }}>
                                                <img loading="lazy" src={tool?.icon?.uri} style={{ width: "1.5rem", height: "1.5rem" }} />
                                                <div className="textMDtoSM w-500 dark4">
                                                    {tool.title}
                                                </div>
                                            </div>
                                            <div className="textESM mt-2 dark3">
                                                {tool?.content}
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PaymentStatus;