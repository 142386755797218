import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { BsFillSquareFill } from 'react-icons/bs';
import { changeObjKey } from './DataCustomizer';



const PriceVsNAVChart = ({ fundData = {}, chartData = [] }) => {
    // portfolioType 1 = curated , 2 = custom, 0 = for individual funds
    console.log(chartData)
    const [noChart, setnoChart] = React.useState(false);
    const fundColors = ['#007AFF', '#83BF6E', '#555770', '#FFD966', '#FF55BB', '#0062CC', '#AA55FF', '#FF9C55', '#FF6A55', '#1A1D1F', '#00FFFF', '#DBFF00']
    try {
        var conf = {
            chart: {
                backgroundColor: "transparent",
                marginLeft: 0,
                marginRight: 0,
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                visible: false,
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                title: {
                    text: ''
                },
                gridLineColor: 'var(--grey1)',
                labels: {
                    format: '₹{value}'
                },
            },
            xAxis: [{
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%e %b',
                    week: '%e %b',
                    month: '%b %y',
                    year: '%Y'
                },
                lineColor: "var(--grey1)",
                tickColor: "var(--grey1)",
            }
            ],
            legend: {
                enabled: false
            },
            plotOptions: {
                areaspline: {
                    threshold: null
                },
                series: {
                    lineWidth: 1,
                    label: {
                        connectorAllowed: false
                    },
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                shared: true,
                valueDecimals: 0,
                valuePrefix: '₹',
            },
            credits: {
                enabled: false
            },
            boost: {
                useGPUTranslations: true,
            },
            series: [
                {
                    name: "NAV", turboThreshold: 9e9,
                    color: fundColors[0],
                    type: "areaspline",
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, fundColors[0] + "77"],
                            [0.7, fundColors[0] + "11"]
                        ]
                    },
                    data: changeObjKey(chartData, [String(fundData?.plan_id), "Date"], ["y", "x"], "mfetfChart", 1)
                }, {
                    name: "Price", turboThreshold: 9e9,
                    color: fundColors[1],
                    type: "areaspline",
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, fundColors[1] + "77"],
                            [0.7, fundColors[1] + "11"]
                        ]
                    },
                    data: changeObjKey(chartData, ["price", "Date"], ["y", "x"], "mfetfChart", 1)
                }
            ].reverse(),
            scrollbar: {
                enabled: false
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 400
                    },
                }]
            },

        };
    } catch (error) {
        setnoChart(true)
    }


    const options = {
        global: {
            useUTC: true
        },
        lang: {
            decimalPoint: '.',
            thousandsSep: ','
        }
    };


    const ContentBeforeChart = () => {
        try {
            return <div style={{ gap: '1rem', flexWrap: 'wrap', alignItems: "center" }}>
                <span style={{ flex: 1, display: "flex", flexDirection: "row", marginBottom: "1rem" }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: "2rem" }}>
                        <span>
                            <span className='textXS w-700' style={{ color: 'var(--black)', display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                <BsFillSquareFill color={fundColors[1]} size={"0.5rem"} />Last closing Price
                            </span>
                        </span>
                        <span>
                            <span className='textXS w-700' style={{ color: 'var(--black)', display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                <BsFillSquareFill color={fundColors[0]} size={"0.5rem"} /> Last closing NAV
                            </span>
                        </span>
                    </div>
                </span>
            </div>
        } catch (error) {
            return <></>
        }
    };



    try {
        return (
            noChart ? <div className='textXS' style={{ margin: "4rem", textAlign: "center" }}>
                No chart data available!
            </div>
                : <span style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', }}>
                    <ContentBeforeChart />
                    <HighchartsReact containerProps={{ style: { height: "22.5rem" } }} highcharts={Highcharts} options={conf} />
                    {/* <ContentAfterChart /> */}

                </span>
        );
    } catch (error) {
        return <div className='textSM' style={{ margin: "4%", textAlign: "center" }}>
            No chart data available!
        </div>
    }
};
export default PriceVsNAVChart;