import { fetchFundData } from "Libs/fetchFundData";
import { setOpenSearchModal, toggleBasketCreateModal } from "Libs/redux/Modals/reduxSlice";
import { getUserScreeners, useGetAssetType } from "Libs/utils";
import AdminServices from "Services/api/AdminServices";
import apiHelper from "Services/api/ApiHelper";
import { StockLivePrice } from "Services/api/LivePricesWebSocket";
import { Dropdown, Popconfirm, Tabs, Tag, Tooltip } from "antd";
import React from "react";
import { toast } from "react-hot-toast";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import { HiChevronRight } from "react-icons/hi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import AMCIcon from "./AMCIcon";
import AccessComponent from "./AccessComponent";
import { BasketIcon } from "./BasketIcon";
import { CurrencyFormat } from "./CurrencyFormat";
import CustomSegmentedTab from "./CustomSegmentedTab";
import ListLoader from "./ListLoader";
import { NoDataFound } from "./NoDataFound";
import RedGreenText from "./RedGreenText";
import { ScreenerIcon } from "./ScreenerIcon";
import { useSubscribeMiddleware } from "./SubscribeModal";
import UpgradeToPro from "./UpgradeToPro";
import { CardHeader } from "./atoms/SmallComponents";
import { useUserDataHooks } from "CustomHooks/useUserDataHooks";

const DashboardTools = React.memo(({ hideInsTypeTabs = false, type, hideToolTypeTabs = false, showToolHeading = false, defInstType, defToolType,
    isBordered = true, defaultSelectedStockWatchlist = undefined, allStocks, allETFs, allMFs }) => {

    const [loading, setLoading] = React.useState(true);
    const stockType = useGetAssetType(4);
    const etfType = useGetAssetType(1);
    const mfType = useGetAssetType(2);
    const watchlist = useSelector((state) => state?.userWatchlists?.value);
    const [screeners, setscreeners] = React.useState([]);
    const [superScreens, setSuperScreens] = React.useState([]);
    const [signals, setsignals] = React.useState([]);
    const [baskets, setbaskets] = React.useState([]);
    const user = useSelector((state) => state?.updateUserData?.value);
    const accessLevelData = useSelector((state) => state?.accessLevel?.value);
    const [selectedTab, setselectedTab] = React.useState(defToolType ? defToolType : 'Watchlist');
    const [selectedInstrument, setSelectedInstrument] = React.useState(defInstType ? defInstType : 4);
    const { getUserStockModels } = useUserDataHooks();
    const subscribeMiddle = useSubscribeMiddleware();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [allFunds, setAllFunds] = React.useState([]);

    console.log(watchlist);

    const moduleKey = selectedInstrument === 4 ? (selectedTab === 'Watchlist' ? "STOCK_NO_OF_WATCHLIST" : selectedTab === 'Screens' ? "STOCK_SAVE_TRACK_SCREENS" : selectedTab === 'Baskets' ? "BASKET_SAVE_TRACK" : "STOCK_SAVE_TRACK_SIGNALS") : (selectedTab === 'Watchlist' ? "MF_ETF_NO_OF_WATCHLIST" : selectedTab === 'Screens' ? "MF_ETF_SAVE_TRACK_SCREENS" : selectedTab === 'Baskets' ? "BASKET_SAVE_TRACK" : "STOCK_SAVE_TRACK_SIGNALS");
    const accessObj = accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === moduleKey);

    const loadWatchlist = async () => {
        if (watchlist?.length > 0) {
            if (hideInsTypeTabs) {
                let res = await fetchFundData(selectedInstrument, false);
                res = res?.map((item) => { return { ...item, type: selectedInstrument } })
                setAllFunds(res);
            }
            else {
                let res1 = allStocks ? allStocks : await fetchFundData(4, false);
                let res2 = allETFs ? allETFs : await fetchFundData(1, false);
                let res3 = allMFs ? allMFs : await fetchFundData(2, false);
                res1 = res1.map((item) => { return { ...item, type: 4 } })
                res2 = res2.map((item) => { return { ...item, type: 1 } })
                res3 = res3.map((item) => { return { ...item, type: 2 } })
                let a = [...res1, ...res2, ...res3]
                setAllFunds(a);
            }
        }
    }

    const loadOtherItems = async (id) => {
        let scr1 = await getUserScreeners(id, stockType.strVal);
        let scr2 = await getUserScreeners(id, etfType.strVal);
        let scr3 = await getUserScreeners(id, mfType.strVal);
        scr1 = scr1.map((item) => { return { ...item, type: 4 } });
        scr2 = scr2.map((item) => { return { ...item, type: 1 } });
        scr3 = scr3.map((item) => { return { ...item, type: 2 } });
        let ss = await apiHelper({ apiName: "getAllSuperScreens", saveResponse: "sessionStorage", data: user?.id })
        setSuperScreens(ss?.filter((el) => el?.is_deleted === 0));
        console.log(ss)
        let screeners = [...scr1, ...scr2, ...scr3];
        let baskets = await fetchFundData(3, false, user.id);
        let signals = await getUserStockModels();

        //alerts
        let alerts = await apiHelper({ apiName: "getStockAlertById", saveResponse: "sessionStorage", data: { clientId: id } });

        setscreeners(screeners);
        setbaskets(baskets.filter(s => s.IsCustom === 1))
        setsignals(signals?.userModels || [])
        setLoading(false)
    }



    React.useEffect(() => {
        loadWatchlist();
    }, [watchlist])

    React.useEffect(() => {
        const dum = async () => {
            if (user !== undefined && user !== null) {
                loadOtherItems(user.id)
            } else {
                setLoading(false)
            }
        }
        setLoading(true);
        dum();
    }, [user])

    React.useEffect(() => {
        setselectedTab(defToolType ? defToolType : 'Watchlist');
    }, [selectedInstrument]);

    const instrumentTabs = [
        {
            key: 4,
            label: "Stocks",
            value: 4,
            type: stockType
        },
        {
            key: 1,
            label: "ETFs",
            value: 1,
            type: etfType
        },
        {
            key: 2,
            label: "Mutual funds",
            value: 2,
            type: mfType
        }
    ]

    const selectedType = instrumentTabs?.find((ele) => ele?.value === selectedInstrument)?.type;

    const Watchlist = () => {

        const filteredWatchlist = watchlist ? watchlist?.filter((el) => el?.FundType === selectedInstrument) : [];
        const [selectedList, setSelectedList] = React.useState(watchlist === undefined ? {} : defaultSelectedStockWatchlist ? defaultSelectedStockWatchlist : filteredWatchlist?.[0]);

        const watchlistAccessKey = Number(selectedInstrument) === 4 ? "MULTIPLE_WATCHLISTS" : Number(selectedInstrument) === 1 ? "MULTIPLE_WATCHLISTS_ETFs" :
            Number(selectedInstrument) === 2 ? "MULTIPLE_WATCHLISTS_MF" : "";


        const watchlistAccess = subscribeMiddle({
            moduleKey: watchlistAccessKey, getDetailedAccessData: true, checkLimit: true, usageCount: filteredWatchlist?.length
        })

        const isWatchlistLocked = Boolean(watchlistAccess?.maxLimit < (filteredWatchlist?.findIndex((el) => el?.WatchListId === selectedList?.WatchListId) + 1));
        console.log(isWatchlistLocked);
        return (<>
            <AccessComponent component={
                <>
                    <div className='pad20Container'>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CardHeader heading={'Watchlist'} />
                            </div>
                            {watchlist && filteredWatchlist?.length > 0 && <Dropdown menu={{
                                items: filteredWatchlist?.map((ele) => {
                                    return {
                                        key: ele?.WatchListId,
                                        label: <span>{ele?.Name}</span>
                                    }
                                }), selectable: true, defaultSelectedKeys: [selectedList?.WatchListId],
                                onClick: (e) => {
                                    setSelectedList(filteredWatchlist?.find((el) => String(el?.WatchListId) === e.key))
                                }
                            }}
                                placement="bottomRight">
                                <span className="textXS" style={{ cursor: 'pointer' }}>{selectedList?.Name} ({selectedList?.FundCodes?.length}) <FiChevronDown size={12} /></span>
                            </Dropdown>}
                        </div>
                    </div>
                    <AccessComponent component={
                        <div style={{ minHeight: loading ? "15rem" : selectedTab === 'Watchlist' ? "16rem" : "18.75rem", maxHeight: `max(18.75rem, min(${selectedList?.FundCodes?.length * 3.75}rem, 32rem))`, overflowY: 'scroll' }}>
                            <div>
                                {((watchlist === undefined)) ? <ListLoader /> : filteredWatchlist?.length > 0 ? selectedList?.FundCodes?.length > 0 ?
                                    selectedList?.FundCodes?.map((f, i) => {
                                        let fund = allFunds?.find((el) => f === (String(el[selectedInstrument === 1 ? "nse_symbol" : selectedInstrument === 2 ? "plan_id" : "symbol"])))
                                        if (fund) {
                                            return (
                                                <div key={i} className="borderAll" style={{ backgroundColor: i % 2 !== 0 ? 'var(--white)' : 'var(--grey4)' }}>
                                                    <Link to={selectedType.basePath + `${selectedType.value === 4 ? '/stock-detail/' + fund?.proper_name?.replaceAll(' ', '-') + '/' +
                                                        fund?.symbol : '/fund-detail/' + fund?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + fund?.plan_id}`}>
                                                        <div className={selectedType.value === 2 ? "pad20Side12VerContainer" : "pad20Side6VerContainer"}
                                                            style={{ display: "flex", gap: "0.5rem", alignItems: "center" }} >
                                                            <AMCIcon amcName={selectedType.value === 4 ? fund?.symbol : fund?.amc_full_name} fundType={selectedType.value} height='1.5rem'
                                                                width="1.5rem" bseTicker={fund?.bse_ticker} />
                                                            <Tooltip title={<span className='textXS w-500'>{selectedType.value === 4 ? fund?.proper_name :
                                                                fund?.basic_name?.replace(" - Direct Plan", "")}</span>} arrow={false}>
                                                                <div className="textXS black hoverBold" style={{
                                                                    flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                                                                    overflow: 'hidden'
                                                                }}>
                                                                    {selectedType.value === 4 ? fund?.proper_name : fund?.basic_name?.replace(" - Direct Plan", "")}
                                                                </div>
                                                            </Tooltip>
                                                            <span className="textXS w-500" >
                                                                {selectedType.value !== 2 ? <StockLivePrice fundData={fund} format={6}
                                                                    symbol={fund?.symbol || fund?.ticker || fund?.nse_symbol} /> :
                                                                    <RedGreenText text={CurrencyFormat(fund?.latest_nav)} value={fund?.latest_nav} />}
                                                            </span>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        }
                                        else return null;
                                    }) : <div style={{ padding: "1.25rem" }}>
                                        <NoDataFound message={`Looks like your watchlist is empty. Begin by adding ${selectedType?.dname?.toLowerCase()} to keep a close eye on their performance and make informed investment decisions.`} width='8rem' gap='3rem' />
                                    </div> :
                                    <div style={{ padding: "1.25rem" }}>
                                        <NoDataFound message={`Looks like you have not created any ${selectedType?.dname?.toLowerCase()} watchlist. Begin by creating your own 
                                    personalized ${selectedType?.dname?.toLowerCase()} watchlist.`} width='8rem' gap='3rem' />
                                    </div>
                                }
                            </div>
                        </div>
                    } isDefaultUpgrade={isWatchlistLocked} upgradeComponent={<div className="pad20Container d-flex flex-column justify-content-center">
                        <UpgradeToPro moduleKey={watchlistAccessKey} customDesc={`This watchlist is locked. With your current plan, you can only view your first ${watchlistAccess?.maxLimit} saved ${selectedType?.dname} watchlists. To view and make changes to your other watchlists, upgrade your plan.`} />
                    </div>} />
                    {loading ? null : <>
                        {filteredWatchlist?.length === 0 && selectedTab === 'Watchlist' && (
                            <div className='pad20Container'>
                                <button className='btnSecondary openGlobalSearchClass1 ' style={{ width: "100%" }} onClick={() => {
                                    dispatch(setOpenSearchModal(true));
                                }}>
                                    <AiOutlinePlusCircle className='textLG' cursor={'pointer'} /> Create new Watchlist
                                </button>
                            </div>
                        )}
                    </>}
                    {loading ? null : !isWatchlistLocked && selectedList?.FundCodes?.length > 0 ?
                        <div style={{ color: "var(--primary)", cursor: "pointer", textAlign: "right" }}
                            className='textSM w-500 pad20Side12VerContainer' >
                            <Link to={`/watchlist`} className='d-flex align-items-center justify-content-end'>
                                See all <HiChevronRight />
                            </Link>
                        </div> : null
                    }
                </>
            } moduleKey={watchlistAccessKey} upgradeComponent={<>
                {showToolHeading && <div className='pad20Container'>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <CardHeader heading='Watchlist' />
                        </div>
                    </div>
                </div>}
                <div className="pad20Container">
                    <UpgradeToPro moduleKey={watchlistAccessKey} customDesc={filteredWatchlist?.length === 0 ? "" : `All your previously created watchlists have been locked. To edit/update your watchlists, upgrade your plan.`} />
                </div>
            </>} />
        </>
        )
    }

    const Screens = () => {
        const deleteScreen = async (id, type) => {
            let res = await AdminServices.deleteScreener(user?.id, id, type.strVal?.toUpperCase());
            if (res.type === true) {
                toast.success('Screen deleted!');
                await apiHelper({ apiName: "getUserScreeners", saveResponse: "sessionStorage", data: { id: user?.id, type: type.strVal }, getFresh: true })
                let temp = screeners?.filter((el) => el?.checklist_id !== id);
                setscreeners(temp);
            }
        }
        return (<>
            <AccessComponent component={
                <>
                    {showToolHeading && <div className='pad20Container'>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CardHeader heading={'My Screens'} />
                                <Tag className='mx-3 textSM w-500' style={{ color: "var(--black)", height: "100%" }} color="var(--blueShade)">
                                    {screeners?.filter((el) => el?.type === selectedInstrument)?.length || 0}</Tag>
                            </div>
                            {screeners?.filter((el) => el?.type === selectedInstrument)?.length > 0 && <AiOutlinePlusCircle className='textLG black' cursor={'pointer'}
                                onClick={() => {
                                    subscribeMiddle({
                                        ModuleKey: selectedType.value === 4 ? "STOCK_CUSTOM_SCREENS" : "MF_ETF_CUSTOM_SCREENS", onNext: () => {
                                            navigate(selectedType.basePath + '/screener')
                                        }
                                    })
                                }} />}
                        </div>
                    </div>}
                    <div style={{ minHeight: loading ? "15rem" : "18.75rem", maxHeight: "18.75rem", overflowY: 'scroll' }}>
                        <div>
                            {screeners?.filter((el) => el?.type === selectedInstrument)?.length > 0 && !loading ?
                                screeners?.filter((el) => el?.type === selectedInstrument)?.map((item, i) => {
                                    return <div key={i} className="borderAll" style={{ backgroundColor: i % 2 !== 0 ? 'var(--white)' : 'var(--grey4)' }}>
                                        <div className="pad20Side12VerContainer" style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                                            <Link to={`${selectedType.basePath}/screener?screenId=${item?.checklist_id}&screenType=3&userId=${user?.id || 0}`} style={{
                                                display: "flex", gap: "0.5rem",
                                                alignItems: "center", width: "100%", flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'
                                            }}>
                                                <ScreenerIcon width='1.5rem' height='1.5rem' id={item?.checklist_id} />
                                                <div className="textXS black hoverBold" style={{ flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                                                    {item?.checklist_name}
                                                </div>
                                            </Link>
                                            <Popconfirm title={<span className='textSM w-500 dark4' style={{ userSelect: "none" }}>
                                                Are you sure you want to delete this<br /> screen?</span>}
                                                description={<span className='textXS w-500 dark3 mt-3' style={{ userSelect: "none" }}>Note: This action is irreversible.</span>}
                                                onConfirm={(e) => {
                                                    e.stopPropagation()
                                                    deleteScreen(item?.checklist_id, selectedType);
                                                }} arrow={false} placement='topLeft'>
                                                <MdOutlineDeleteOutline onClick={(e) => e.stopPropagation()} style={{ cursor: "pointer" }} color='var(--red)' />
                                            </Popconfirm>
                                        </div>
                                    </div>
                                }) : loading ? <ListLoader /> :
                                    <div style={{ padding: "1.25rem" }}>
                                        <NoDataFound message={`Looks like you haven't created any screens yet. Define your criteria and create screens to easily filter 
                                        ${selectedType?.dname?.toLowerCase()} and identify potential investment opportunities.`} width='8rem' gap='3rem' />
                                    </div>
                            }
                        </div>
                    </div>
                    {loading ? null : <>
                        {(screeners?.filter((el) => el?.type === selectedInstrument)?.length === 0) && selectedTab === 'Screens' && (
                            <div className='pad20Container'>
                                <button className='btnSecondary ' style={{ width: "100%" }} onClick={() => {
                                    navigate(selectedType.basePath + '/screener')
                                }}>
                                    <AiOutlinePlusCircle className='textLG' cursor={'pointer'} /> Create new Screen
                                </button>
                            </div>
                        )}
                    </>}
                </>
            } reqAccessLevel={accessObj?.AccessLevel} upgradeComponent={<>
                {showToolHeading && <div className='pad20Container'>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <CardHeader heading={'Screens'} />
                        </div>
                    </div>
                </div>}
                <div className='pad20Side12VerContainer'>
                    <UpgradeToPro accessObj={accessObj} flexDirection='column' textAlign='center' width='100%' contentClassName='d-flex flex-column align-items-center' />
                </div>
            </>} />
        </>
        )
    }

    const SuperScreens = () => {
        const deleteSuperScreen = async (id) => {
            let res = await apiHelper({ apiName: "deleteScreenv1", data: { screenId: id, clientId: user?.id } });
            if (res) {
                toast.error('Screen deleted!')
                let ss = await apiHelper({ apiName: "getAllSuperScreens", saveResponse: "sessionStorage", data: user?.id, getFresh: true })
                setSuperScreens(ss?.filter((el) => el?.is_deleted === 0));
            }
        }
        return (<>
            <AccessComponent component={
                <>
                    {showToolHeading && <div className='pad20Container'>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CardHeader heading={'My Super Screens'} />
                                <Tag className='mx-3 textSM w-500' style={{ color: "var(--black)", height: "100%" }} color="var(--blueShade)">
                                    {superScreens?.filter((el) => el?.instrument_type === selectedType?.strVal)?.length || 0}</Tag>
                            </div>
                            {superScreens?.filter((el) => el?.instrument_type === selectedType?.strVal)?.length > 0 && <AiOutlinePlusCircle className='textLG black' cursor={'pointer'}
                                onClick={() => {
                                    subscribeMiddle({
                                        ModuleKey: selectedType.value === 4 ? "STOCK_CUSTOM_SCREENS" : "MF_ETF_CUSTOM_SCREENS", onNext: () => {
                                            navigate(selectedType.basePath + '/super-screener')
                                        }
                                    })
                                }} />}
                        </div>
                    </div>}
                    <div style={{ minHeight: loading ? "15rem" : "18.75rem", maxHeight: "18.75rem", overflowY: 'scroll' }}>
                        <div>
                            {superScreens?.filter((el) => el?.instrument_type === selectedType?.strVal)?.length > 0 && !loading ?
                                superScreens?.filter((el) => el?.instrument_type === selectedType?.strVal)?.map((item, i) => {
                                    return <div key={i} className="borderAll" style={{ backgroundColor: i % 2 !== 0 ? 'var(--white)' : 'var(--grey4)' }}>
                                        <div className="pad20Side12VerContainer" style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                                            <Link
                                                to={`${selectedType.basePath}/super-screener?screenName=${item?.screen_name?.replaceAll(' ', '-')}&screenId=${item?.screen_id}&userId=${user?.id || 0}`}
                                                style={{
                                                    display: "flex", gap: "0.5rem",
                                                    alignItems: "center", width: "100%", flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'
                                                }}>
                                                <ScreenerIcon width='1.5rem' height='1.5rem' id={item?.screen_id} />
                                                <div className="textXS black hoverBold" style={{ flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                                                    {item?.screen_name}
                                                </div>
                                            </Link>
                                            <Popconfirm title={<span className='textSM w-500 dark4' style={{ userSelect: "none" }}>
                                                Are you sure you want to delete this<br /> screen?</span>}
                                                description={<span className='textXS w-500 dark3 mt-3' style={{ userSelect: "none" }}>Note: This action is irreversible.</span>}
                                                onConfirm={(e) => {
                                                    e.stopPropagation()
                                                    deleteSuperScreen(item?.screen_id);
                                                }} arrow={false} placement='topLeft'>
                                                <MdOutlineDeleteOutline onClick={(e) => e.stopPropagation()} style={{ cursor: "pointer" }} color='var(--red)' />
                                            </Popconfirm>
                                        </div>
                                    </div>
                                }) : loading ? <ListLoader /> :
                                    <div style={{ padding: "1.25rem" }}>
                                        <NoDataFound message={`Looks like you haven't created any super screens yet. Define your criteria and create screens to easily filter 
                                        ${selectedType?.dname?.toLowerCase()} and identify potential investment opportunities.`} width='8rem' gap='3rem' />
                                    </div>
                            }
                        </div>
                    </div>
                    {loading ? null : <>
                        {(superScreens?.filter((el) => el?.instrument_type === selectedType?.strVal)?.length === 0) && (
                            <div className='pad20Container'>
                                <button className='btnSecondary ' style={{ width: "100%" }} onClick={() => {
                                    navigate(selectedType.basePath + '/super-screener')
                                }}>
                                    <AiOutlinePlusCircle className='textLG' cursor={'pointer'} /> Create new Super Screen
                                </button>
                            </div>
                        )}
                    </>}
                </>
            } reqAccessLevel={4} upgradeComponent={<>
                {showToolHeading && <div className='pad20Container'>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <CardHeader heading={'Super Screens'} />
                        </div>
                    </div>
                </div>}
                <div className='pad20Side12VerContainer'>
                    <UpgradeToPro accessObj={{ AccessLevel: 4, ModuleDesc: "Create and save super screen using 400+ metrics and indicators." }} flexDirection='column' textAlign='center' width='100%' contentClassName='d-flex flex-column align-items-center' />
                </div>
            </>} />
        </>
        )
    }

    const Baskets = () => {
        const deleteMintbox = async (id) => {
            let res = await AdminServices.deleteMintbox(id)
            if (res.type === true) {
                toast.success('Basket deleted!');
                let baskets = await fetchFundData(3, true, user.id)
                setbaskets(baskets.filter(s => s.IsCustom === 1))
            }
        }
        return (<>
            <AccessComponent component={
                <>
                    {showToolHeading && <div className='pad20Container'>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CardHeader heading={'My Strategies'} />
                                <Tag className='mx-3 textSM w-500' style={{ color: "var(--black)", height: "100%" }} color="var(--blueShade)">
                                    {baskets?.filter((el) => el?.FundType === selectedInstrument)?.length || 0}</Tag>
                            </div>
                            {baskets?.filter((el) => el?.FundType === selectedInstrument)?.length > 0 &&
                                <AiOutlinePlusCircle className='textLG black' cursor={'pointer'} onClick={() => {
                                    if (selectedInstrument === 4) {
                                        dispatch(toggleBasketCreateModal({ open: true, type: {} }));
                                    }
                                    else {
                                        navigate(`${selectedType.basePath}/build-custom-strategy`)
                                    }
                                }} />}
                        </div>
                    </div>}
                    <div style={{ minHeight: loading ? "15rem" : selectedTab === 'Watchlist' ? "16rem" : "18.75rem", maxHeight: "18.75rem", overflowY: 'scroll' }}>
                        <div style={{}}>
                            {baskets?.filter((el) => el?.FundType === selectedInstrument)?.length > 0 && !loading ? baskets?.filter((el) => el?.FundType === selectedInstrument)?.map((fundData, i) => {
                                return <div key={i} className="borderAll" style={{ backgroundColor: i % 2 !== 0 ? 'var(--white)' : 'var(--grey4)' }}>
                                    <div className="pad20Side12VerContainer" style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                                        <Link to={selectedType.basePath + `/basket-detail/${fundData?.MintboxName?.replaceAll(' ', '-')}/${fundData.IsCustom === 1 ? 2 : 1}/${fundData.UID}`}
                                            style={{
                                                display: "flex", gap: "0.5rem",
                                                alignItems: "center", width: "100%", flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'
                                            }}>
                                            {fundData?.IsCustom === 1 ?
                                                <BasketIcon id={fundData?.UID > 30 ? fundData?.UID % 30 : fundData?.UID} height='1.5rem' width='1.5rem' bg='var(--blueShade)'
                                                    border='1px solid var(--primary)' borderRadius='2px' />
                                                : null
                                            }
                                            <div className="textXS black hoverBold" style={{ flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                                                {fundData?.MintboxName}
                                            </div>
                                        </Link>
                                        <Popconfirm title={<span className='textSM w-500 dark4' style={{ userSelect: "none" }}>
                                            Are you sure you want to delete this<br /> basket?</span>}
                                            description={<span className='textXS w-500 dark3 mt-3' style={{ userSelect: "none" }}>Note: This action is irreversible.</span>}
                                            onConfirm={(e) => {
                                                e.stopPropagation()
                                                deleteMintbox(fundData.UID);
                                            }} arrow={false} placement='topLeft'>
                                            <MdOutlineDeleteOutline onClick={(e) => e.stopPropagation()} style={{ cursor: "pointer" }} color='var(--red)' />
                                        </Popconfirm>
                                        {/* <IoIosArrowDropright /> */}
                                    </div>
                                </div>
                            }) : loading ? <ListLoader /> :
                                <div style={{ padding: "1.25rem" }}>
                                    <NoDataFound message={`Your basket collection is waiting to be filled. Start building baskets and assemble ${selectedType?.dname?.toLowerCase()} to construct a well-diversified investment portfolio.`} width='8rem' gap='3rem' />
                                </div>
                            }
                        </div>
                    </div>
                    {loading ? null : <>
                        {baskets?.filter((el) => el?.FundType === selectedInstrument)?.length === 0 && selectedTab === 'Baskets' && (
                            <div className='pad20Container'>
                                <button className='btnSecondary ' style={{ width: "100%" }} onClick={() => {
                                    if (selectedInstrument === 4) {
                                        dispatch(toggleBasketCreateModal({ open: true, type: {} }));
                                    }
                                    else {
                                        navigate(`${selectedType.basePath}/build-custom-strategy`)
                                    }
                                }}>
                                    <AiOutlinePlusCircle className='textLG' cursor={'pointer'} /> Create new basket
                                </button>
                            </div>
                        )}
                    </>}
                </>
            } reqAccessLevel={accessObj?.AccessLevel} upgradeComponent={<>
                {showToolHeading && <div className='pad20Container'>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <CardHeader heading={'Baskets'} />
                        </div>
                    </div>
                </div>}
                <div className='pad20Side12VerContainer'>
                    <UpgradeToPro accessObj={accessObj} flexDirection='column' textAlign='center' width='100%' contentClassName='d-flex flex-column align-items-center' />
                </div>
            </>} />
        </>
        )
    }

    const FactorModels = () => {
        const deleteSignal = async (id, type) => {
            let res = await AdminServices.deleteSignal(user?.id, id);
            if (res.type === true) {
                toast.success('Factor model deleted!');
                await getUserStockModels({ getFresh: true })
                let temp = signals?.filter((el) => el?.signal_id !== id);
                setsignals(temp);
            }
        }
        return (
            <>
                <AccessComponent component={
                    <>
                        {showToolHeading && <div className='pad20Container'>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <CardHeader heading={'My Factor Models'} />
                                    <Tag className='mx-3 textSM w-500' style={{ color: "var(--black)", height: "100%" }} color="var(--blueShade)">
                                        {signals?.length || 0}</Tag>
                                </div>
                                {signals?.length > 0 && <span>
                                    <AiOutlinePlusCircle className='textLG black' cursor={'pointer'} onClick={() => {
                                        subscribeMiddle({
                                            ModuleKey: "STOCK_CUSTOM_SIGNALS", onNext: () => {
                                                navigate(selectedType.basePath + '/factor-model')
                                            }
                                        })
                                    }} />
                                </span>}
                            </div>
                        </div>}
                        <div style={{ minHeight: loading ? "15rem" : "18.75rem", maxHeight: "18.75rem", overflowY: 'scroll' }}>
                            <div style={{}}>
                                {signals.length > 0 && !loading ? signals?.map((item, i) => {
                                    return <div key={i} className="borderAll" style={{ backgroundColor: i % 2 !== 0 ? 'var(--white)' : 'var(--grey4)' }}>
                                        <div className="pad20Side12VerContainer" style={{ display: "flex", gap: "0.5rem", alignItems: "center" }} >
                                            <Link to={`/stocks/factor-model?fmName=${item?.signal_name}&fmId=${item?.signal_id}&userId=${item?.client_id}`} style={{
                                                display: "flex", gap: "0.5rem",
                                                alignItems: "center", width: "100%", flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'
                                            }}>
                                                <ScreenerIcon width='1.5rem' height='1.5rem' id={item?.signal_id} />
                                                <div className="textXS black hoverBold" style={{ flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                                                    {item?.signal_name}
                                                </div>
                                            </Link>
                                            <Popconfirm title={<span className='textSM w-500 dark4' style={{ userSelect: "none" }}>
                                                Are you sure you want to delete this <br /> factor model?</span>}
                                                description={<span className='textXS w-500 dark3 mt-3' style={{ userSelect: "none" }}>Note: This action is irreversible.</span>}
                                                onConfirm={(e) => {
                                                    e.stopPropagation()
                                                    deleteSignal(item?.signal_id, stockType);
                                                }} arrow={false} placement='topLeft'>
                                                <MdOutlineDeleteOutline onClick={(e) => e.stopPropagation()} style={{ cursor: "pointer" }} color='var(--red)' />
                                            </Popconfirm>
                                        </div>
                                    </div>
                                }) : loading ? <ListLoader /> :
                                    <div style={{ padding: "1.25rem" }}>
                                        <NoDataFound message={`Supercharge your investment strategy by creating a personalized factor model to rank ${selectedType.dname.toLowerCase()} based on your preferences and uncover winning opportunities.`} width='8rem' gap='3rem' />
                                    </div>
                                }
                            </div>
                        </div>
                        {loading ? null : <>
                            {signals?.length === 0 && (
                                <div className='pad20Container'>
                                    <button className='btnSecondary ' style={{ width: "100%" }} onClick={() => {
                                        navigate(selectedType.basePath + '/factor-model')
                                    }}>
                                        <AiOutlinePlusCircle className='textLG' cursor={'pointer'} /> Create new Factor Model
                                    </button>
                                </div>
                            )}
                        </>}
                    </>
                } reqAccessLevel={accessObj?.AccessLevel} upgradeComponent={<>
                    {showToolHeading && <div className='pad20Container'>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CardHeader heading={'Factor Models'} />
                            </div>
                        </div>
                    </div>}
                    <div className='pad20Side12VerContainer'>
                        <UpgradeToPro accessObj={accessObj} flexDirection='column' textAlign='center' width='100%' contentClassName='d-flex flex-column align-items-center' />
                    </div>
                </>} />
            </>
        )
    }

    var items = [
        {
            key: 0,
            label: "Watchlist",
            value: "Watchlist",
            children: <Watchlist />
        },
        {
            key: 1,
            label: "Screens",
            value: "Screens",
            children: <Screens />
        },
        {
            key: 4,
            label: "Super Screens",
            value: "SuperScreens",
            children: <SuperScreens />,
            show: selectedInstrument === 4
        },
        {
            key: 2,
            label: "Strategies",
            value: "Strategies",
            children: <Baskets />
        },
        {
            key: 3,
            label: "Models",
            value: "Models",
            show: selectedInstrument === 4,
            children: <FactorModels />
        }
    ]

    if (type === 'watchlist') {
        items = items.filter(x => x.value === 'Watchlist')
    } else if (type === 'strategies') {
        items = items.filter(x => x.value === 'Strategies')
    } else if (type === 'screens_and_modals') {
        items = items.filter(x => x.value === 'Screens' || x.value === 'SuperScreens' || x.value === 'Models')
    }
    return (
        <React.Fragment>
            <div style={{
                background: "var(--white)", padding: hideInsTypeTabs ? "0" : "0.25rem 0", borderRadius: "var(--borderRadius)",
                border: isBordered ? "1px solid var(--grey2)" : ""
            }}>
                {!hideInsTypeTabs && <Tabs items={instrumentTabs} defaultValue={selectedInstrument} onChange={(e) => setSelectedInstrument(e)}
                    tabBarStyle={{ padding: "0 1rem", marginBottom: "1rem" }} />}
                {(!hideToolTypeTabs && items?.length > 1) && <div className='pad20Container' style={{ paddingBottom: 0 }}>
                    <CustomSegmentedTab options={items?.filter(((ele) => ele?.show !== false))} value={selectedTab} onChange={(e) => setselectedTab(e)}
                        className='textXS' style={{ margin: "0 -0.5rem", overflow: "auto", width: 'calc(100% + 1rem)', maxWidth: 'calc(100% + 1rem)' }} />
                </div>}
                <div>
                    {items.filter((i) => i.value === selectedTab)[0].children}
                </div>
            </div>
        </React.Fragment>
    )
});

export default DashboardTools;
