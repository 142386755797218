import ColumnChart from "Components/ColumnChart";
import CustomLabelValueCards from "Components/CustomLabelValue";
import CustomSegmentedTab from "Components/CustomSegmentedTab";
import { formatDate } from "Components/DateFormatter";
import { NoDataFound } from "Components/NoDataFound";
import PerformanceChart3 from "Components/PerformanceChart3";
import { StickyScrollTabs } from "Components/CustomComponents/StickyScrollTabs";
import { toggleMetricDescModal } from "Libs/redux/KnowledgeBase/reduxSlice";
import { arePropsEqual, displayValue } from "Libs/utils";
import { Segmented } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useIsMobile } from "CustomHooks/useIsMobile";

const IndexFundamentals = React.memo(({ index, indexDetails, screenerMetricDescData, isCustomIndex }) => {

    const isMobile = useIsMobile();
    const dispatch = useDispatch();

    const lineCharts = ['Value'];
    const barCharts = ['Quality', 'Financial Health'];
    const logCharts = ['Value']
    const subHeadings = { "Value": "We look at aggregate consolidated P/E, P/B  and Dividend Yield of the index as reported by NSE. Note that P/E values before 31st March 2021 are based on our own calculations", "Quality": "We look at aggregate quality measures of the index like ROE, ROCE and margins. Numerator and denominator are separately calculated as weighted averages." }

    function ChartNotes({ name }) {
        let notes = [{ name: "P/E Ratio", note: "Since NSE changed its methodology from standalone P/E to consolidated P/E on 31st March 2021, all numbers prior to that date is our internal calculation of consolidated P/E. Numbers post March 31st 2021 are as reported by NSE" }, { name: "P/B Ratio", note: "P/B ratio is shown as reported by NSE." },
        { name: "Dividend yield (%)", note: "Dividend yield is shown as reported by NSE." }]

        let c = notes?.find(ele => ele.name === name)?.note;
        if (!c || isCustomIndex) return null;
        return (
            <div style={{ margin: "0 1rem 1rem" }} className='textXS black'>
                {c}
            </div>
        )
    }

    const FundamentalChart = ({ chart, type, yAxisType, selectedTab, metric }) => {
        const [scaleType, setScaleType] = React.useState('linear');
        let check = ((typeof (chart?.chart_data) === 'string') && (JSON.parse(chart?.chart_data || '[]')?.length === 0) || chart?.chart_data?.length === 0)
        if (chart && check) {
            return <div className='d-flex flex-column justify-content-center flex-grow-1'>
                <NoDataFound message={chart?.no_data_text || 'Not enough data'} />
            </div>
        }
        else if (chart && chart?.chart_data) {
            console.log(JSON.parse(chart?.chart_data))
            var x = Object.keys(JSON.parse(chart?.chart_data)?.[0] || {})?.[0]
            var y = Object.keys(JSON.parse(chart?.chart_data)?.[0] || {})?.[1]
            const cards = selectedTab !== 'Value' ? Object.keys(chart?.chart_text_items)?.map((k, i) => {
                let ob = {
                    key: i,
                    heading: <span>{k}<span className='' style={{ fontSize: "0.6rem" }}> as on {formatDate(chart?.chart_text_items?.[k]?.as_on)}</span></span>,
                    value: displayValue({ text: chart?.chart_text_items?.[k]?.val, metricObj: metric })
                }
                return ob;
            }) : Object.keys(chart?.chart_text_items)?.map((k, i) => {
                return {
                    key: i,
                    heading: k,
                    value: displayValue({ text: chart?.chart_text_items?.[k], metricObj: metric })
                }
            })

            return (
                <div>
                    <div style={{ display: "flex", gap: "1.25rem", flexDirection: isMobile ? "column" : "row" }}>
                        <div style={{}}>
                            <CustomLabelValueCards x={cards} padding="0.75rem 1rem" orientation='verticle' />
                        </div>
                        <div style={{ flex: 1, padding: isMobile ? "" : "1.25rem", border: isMobile ? "" : "1px solid var(--grey2)", borderRadius: "var(--borderRadius)", }}>
                            {yAxisType && chart?.tab === 'Multiples' && <Segmented value={scaleType} options={[{ label: "Linear", value: "linear", key: "linear" },
                            { label: "Logarithmic", value: "logarithmic", key: "logarithmic" }]}
                                onChange={(e) => { setScaleType(e) }} style={{ marginBottom: "1rem" }} size='small' />}
                            {
                                type === 'column' ?
                                    <ColumnChart chartName={chart.chart_name} chartData={chart.chart_data} xAxisKey={x} yAxisKey={y}
                                        showYAxis={true} showDataLabels={true} height={300} isQuaterly />
                                    : <PerformanceChart3 fundName={chart.chart_name} chartData={chart.chart_data} xAxisKey={x} chartType={type}
                                        showYAxis={true} showDataLabels={true} height={300} yAxisType={(yAxisType && chart?.tab === 'Multiples') ? scaleType : "linear"} />
                            }
                            <ChartNotes name={chart.chart_name} />
                        </div>
                    </div>
                </div>
            )
        }
    }

    const FundamentalChildren = ({ data, type, selectedTab, yAxisType }) => {

        let options = [];
        let tabs = [];

        Object.values(data || {})?.map((tab) => {
            tabs = [...tabs, tab.name];
            let temp = tab?.charts?.map((ch, key2) => {
                return {
                    key: key2 + tab?.name,
                    tab: tab?.name,
                    label: ch?.chart_name,
                    value: ch?.chart_name,
                    ...ch
                }
            })
            options = [...options, ...temp]
        })

        const [selectedMetric, setSelectedMetric] = React.useState(options?.[0]?.value);
        const chart = options.find(ch => ch.value === selectedMetric)
        const metric = screenerMetricDescData?.filter(ele => ele.desc_id === Number(chart?.desc))?.[0] || {}

        return (
            <div style={{ minHeight: "50vh", display: "flex", flexDirection: "column", gap: "0rem", }}>
                <CustomSegmentedTab
                    flexVal="unset"
                    isBlock={false}
                    mobileStyles={{ margin: '0 -4px 1rem' }}
                    value={selectedMetric}
                    onChange={(e) => {
                        setSelectedMetric(e)
                    }}
                    options={options}
                />
                {metric?.desc_id && <div className='helpText' style={{ margin: '0.75rem 0 0.75rem' }} onClick={() => {
                    dispatch(toggleMetricDescModal({
                        value: true,
                        params: {
                            title: metric?.name,
                            description: metric?.description
                        }
                    }))
                }}>What is {metric?.name}?</div>}
                <FundamentalChart chart={chart} type={type} yAxisType={yAxisType} selectedTab={selectedTab} metric={metric} />
            </div>
        )
    }

    return <React.Fragment>
        <StickyScrollTabs isAccordionInMob queriesToStickToBot={['#sharpelyHeader', '#customTabsBarId']} tabData={Object.entries(indexDetails?.fundamental_data || {})?.map((ele) => {
            return {
                heading: ele?.[0],
                key: ele?.[0],
                children: <FundamentalChildren data={ele[1]} type={lineCharts.includes(ele[0]) ? "line" : barCharts.includes(ele[0]) ? "column" : "column"}
                    selectedTab={ele[0]} yAxisType={logCharts?.includes(ele[0])} />,
                value: ele?.[0]?.replaceAll(' ', ''),
                subHeading: subHeadings?.[ele?.[0]]
            }
        })} />
    </React.Fragment>
}, arePropsEqual)

export default IndexFundamentals