import { NoDataFound } from "Components/NoDataFound";
import { SubscribeContentWrapper } from "Components/SubscribeModal";
import { Checkbox } from "Components/atoms/Checkbox";
import { LoadMore } from "Components/atoms/LoadMore";
import { isChildVisible } from "Libs/scroll";
import { sortDrop } from 'Libs/search';
import { generateUniqueKey } from 'Libs/utils';
import { Tag } from "antd";
import React from "react";
import { BsSearch } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import InfiniteScroll from "react-infinite-scroller";
import { v4 } from "uuid";

export const QueryOptionsDropdownV2 = React.memo(({ options, onSelect = () => { }, isMultiple = false, dropdownPositions = {}, hideCreateMetric = false, isAdvance = false,
    dropdownWidth = '25rem', width = '10rem', placeholder = '', value, acceptCustomInput = false, customInputType, tabs = [], disabled = false
}) => {

    let uniqueId = React.useMemo(() => {
        return 'queryOptionsDropdown' + v4();
    }, []);
    const [selectedTab, setSelectedTab] = React.useState('');
    const [selectedItems, setSelectedItems] = React.useState(isMultiple ? [options?.[0]] : []);
    const [itemsToShow, setItemsToShow] = React.useState(20);
    const [openDropdown, setOpenDropdown] = React.useState(false);
    const inputRef = React.useRef();
    const [searchText, setSearchText] = React.useState('');

    const getFinalOptions = React.useCallback(() => {
        let tOptions = [...options]
        let temp = selectedTab === '' ? tOptions : tOptions?.filter((el) => el?.tabType === selectedTab || el?.type === selectedTab);
        temp = sortDrop(searchText, temp, ['label', 'value'])
        temp = temp?.slice(0, itemsToShow);
        if (acceptCustomInput) {
            if (customInputType === 'number' && typeof (Number(searchText)) === 'number' && !isNaN(Number(searchText)) && searchText !== '') {
                let ci = { key: "custominput", label: searchText, value: searchText, type: "Custom", metric_data_type: "float" };
                temp.unshift(ci);
            }
        }
        return temp || [];
    }, [options, selectedTab, itemsToShow, searchText]);

    const finalOptions = getFinalOptions();

    const loadMoreData = () => {
        setItemsToShow(prev => prev + 20)
    }

    const handleDocumentClick = (e) => {
        let target = document.getElementById(`${uniqueId}`);
        let currTarget = e.target
        let classNames = ['triggerAndInputContainerQODV2', 'inputContainerQODV2', 'inputQODV2', 'optionsDropdownWrapperQODV2', 'optionsDropdownContainerQODV2', 'optionsDropdownTabbarQODV2',
            'optionsDropdownTabItemQODV2',
            'scrollbox optionsDropdownItemsContainerQODV2', 'textXS w-500 dark4 optionsDropdownItemQODV2', 'optionsDropdownItemQODV2', 'optionsDropdownItemsContainerQODV2']
        let tabItemsClassNames = ['optionsDropdownTabbarQODV2', 'optionsDropdownTabItemQODV2']
        do {
            if (target === currTarget || classNames?.includes(currTarget.className) || currTarget === target?.querySelector('.optionsDropdownItemQODV2') || currTarget === target?.querySelector('.optionsDropdownTabItemQODV2')) {
                try {
                    if (inputRef?.current) {
                        inputRef.current.focus();
                    }
                }
                catch (err) { }
                if (tabItemsClassNames.includes(currTarget?.className)) {
                    return;
                }
                do {
                    if (currTarget === target) {
                        return;
                    }
                    currTarget = currTarget.parentNode
                }
                while (currTarget) {
                    setOpenDropdown(false);
                }
                return;
            }
            currTarget = currTarget.parentNode
        }
        while (currTarget) {
            if (openDropdown) {
                setOpenDropdown(false);
            }
        }
    }

    const handleKeyDown = (event) => {
        let menuItems = Array.from(document.querySelectorAll(`#${uniqueId} .optionsDropdownItemsContainerQODV2 .optionsDropdownItemQODV2`));
        const parent = document.querySelector(`#${uniqueId} .optionsDropdownItemsContainerQODV2`)
        if (menuItems?.length <= 0) {
            return event
        }
        let maxIndex = menuItems.length - 1;
        let menuIndex = menuItems?.findIndex((ele) => ele.getAttribute('data-focused') !== null);
        if (!menuIndex && menuIndex !== 0) {
            menuItems[0].setAttribute('data-focused', 'active');
        }
        else if (event.key === 'ArrowUp' && menuIndex > 0) {
            event.preventDefault();
            menuItems[menuIndex]?.removeAttribute('data-focused');
            menuItems[menuIndex - 1].setAttribute('data-focused', 'active');
            if (!isChildVisible(parent, menuItems[menuIndex - 1])) {
                parent.scrollTo({ top: menuItems[menuIndex - 1].offsetTop - parent.offsetTop - 110, behavior: "smooth" });
            }
        }
        else if (event.key === 'ArrowDown' && menuIndex < maxIndex) {
            event.preventDefault();
            menuItems[menuIndex]?.removeAttribute('data-focused');
            menuItems[menuIndex + 1].setAttribute('data-focused', 'active');
            if (!isChildVisible(parent, menuItems[menuIndex + 1])) {
                parent.scrollTo({ top: menuItems[menuIndex + 1].offsetTop - parent.offsetTop - 110, behavior: "smooth" });
            }
        }
        else if (event.key === 'Enter' && menuIndex >= 0 && menuIndex <= maxIndex) {
            menuItems[menuIndex]?.click()
        }
    }

    React.useEffect(() => {
        if (openDropdown === true) {
            document.addEventListener('click', handleDocumentClick);
        }
        else {
            setSearchText('');
            document.removeEventListener('click', handleDocumentClick);
        }
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        }
    }, [openDropdown])

    React.useEffect(() => {
        setSelectedTab('');
        setItemsToShow(20);
    }, [tabs])

    return (
        <React.Fragment>
            <div style={{ position: "relative", width: width }} id={uniqueId}>
                <div style={{
                    display: "flex", justifyContent: "space-between", background: "var(--grey4)", width: width, border: "1px solid var(--grey1)", borderRadius: "var(--borderRadius)",
                    padding: "0.25rem 0.5rem", minHeight: "2rem", alignItems: "center"
                }} onClick={(e) => {
                    if (!openDropdown) {
                        setOpenDropdown(true);
                    }
                    setTimeout(() => {
                        if (inputRef?.current) {
                            inputRef.current.focus();
                        }
                    }, 100)
                }} className="triggerAndInputContainerQODV2">
                    <div style={{ flex: 1, display: "flex", alignItems: "center", height: "fit-content" }} className="inputContainerQODV2">
                        {value && openDropdown === false ? <div className="textESM w-500 dark4" style={{ height: "fit-content" }}>
                            {options?.find((el) => el?.value === value)?.label || value}
                        </div> : <input disabled={disabled} style={{ width: "100%", outline: "none", border: "none", background: "transparent", cursor: disabled ? "not-allowed" : "default" }}
                            placeholder={value ? options?.find((el) => el?.value === value)?.label || value : placeholder} ref={inputRef}
                            className="inputQODV2 textESM" onKeyDown={handleKeyDown} value={searchText} onChange={(e) => {
                                setSearchText(e.target.value)
                            }} />}
                    </div>
                    {openDropdown ? <BsSearch style={{ flexShrink: 0, }} size='10px' /> :
                        <IoIosArrowDown style={{ flexShrink: 0, }} size='10px' />}
                </div>
                {openDropdown && <div style={{
                    border: "1px solid var(--grey1)", borderRadius: "var(--borderRadius)",
                    position: "absolute", background: "var(--white)", zIndex: 100000, background: "var(--white)", top: "110%", ...dropdownPositions,
                }} className='optionsDropdownWrapperQODV2'>
                    <div style={{ padding: "0.5rem 0", width: dropdownWidth, position: "relative" }} className="optionsDropdownContainerQODV2">
                        {tabs?.length > 0 && <div style={{
                            display: "flex", gap: "0.75rem 0.1rem", alignItems: "center", justifyContent: "flex-start", flexWrap: "wrap", padding: "0.25rem 0.75rem",
                            paddingBottom: "0.75rem", background: "var(--white)", borderBottom: "0.5px solid var(--grey1)", width: "100%"
                        }} className='optionsDropdownTabbarQODV2'>
                            {tabs?.filter((el) => el)?.map((item, i) => {
                                return (
                                    <div className='optionsDropdownTabItemQODV2' key={generateUniqueKey(i)}>
                                        <Tag onClick={() => {
                                            setSelectedTab(item?.value)
                                        }} className="textXS w-500"
                                            style={{
                                                fontSize: 11, background: selectedTab === item?.value ? "var(--primary)" : "var(--grey3)",
                                                color: selectedTab === item?.value ? "var(--white)" : "var(--dark3)", padding: "0.25rem 0.5rem", borderRadius: 6,
                                                border: `1px solid ${selectedTab === item?.value ? "var(--primary)" : "var(--grey1)"}`, cursor: "pointer", userSelect: "none"
                                            }} >
                                            {item?.label}
                                        </Tag>
                                    </div>
                                )
                            })}
                        </div>}
                        <div style={{ maxHeight: "20rem", overflow: "auto", }} className='scrollbox optionsDropdownItemsContainerQODV2'>
                            {finalOptions?.length > 0 ? <InfiniteScroll hasMore={options?.length > itemsToShow} loadMore={loadMoreData}
                                loader={<LoadMore key={0} />} useWindow={false} threshold={80}>{finalOptions?.map((item, i) => {
                                    let isSelected = selectedItems?.find((el) => el?.value === item?.value);
                                    // //console.log(item)
                                    let auth_flag = Number(item?.auth_flag);
                                    auth_flag = isNaN(auth_flag) ? 0 : auth_flag;
                                    return (
                                        <SubscribeContentWrapper key={generateUniqueKey(item?.label + item?.key + item?.value)} reqAccessLevel={auth_flag} subModalParams={{
                                            customAccessObj: {
                                                AccessLevel: auth_flag,
                                                IsEnabled: 1,
                                                ModuleName: "Proprietary Metrics",
                                                ModuleDesc: "Get access to all sharpely proprietary metrics and scores."
                                            }
                                        }} onClick={() => {
                                            if (isMultiple) {
                                                let temp = [...selectedItems];
                                                if (isSelected) {
                                                    temp = temp?.filter((el) => el?.value !== item?.value)
                                                }
                                                else {
                                                    temp.push(item);
                                                }
                                                setSelectedItems(temp);
                                            }
                                            else {
                                                setOpenDropdown(false);
                                                onSelect(item);
                                            }
                                        }} showLockIcon wrapClassName="textXS w-500 dark4 optionsDropdownItemQODV2" wrapStyle={{
                                            gap: "0.7rem",
                                            padding: "0.5rem 0.75rem", cursor: "pointer",
                                            borderBottom: "0.5px solid var(--grey1)"
                                        }} wrapAttributes={{
                                            'data-focused': i === 0 ? 'active' : null,
                                            'data-all': JSON.stringify(item)
                                        }}>
                                            <>
                                                <div className="d-flex align-items-center mb-1">
                                                    {isMultiple ? <Checkbox onChange={() => { }}
                                                        checked={isSelected}
                                                    /> : null}
                                                    <div className="textXS" style={{ flex: 1 }}>
                                                        {item?.label}
                                                    </div>
                                                </div>
                                                {item?.type !== "comparator" && item?.type !== "conditionOperator" && item?.type !== "mathOperator" &&
                                                    <div className="d-flex align-items-center">
                                                        <Tag color="var(--dark3)" className="textXS" style={{ borderRadius: 8, fontSize: "10px" }}>
                                                            {item?.type !== "Variable" ? item?.type : "Metric C"}
                                                        </Tag>
                                                        {(item?.type === 'Metric F' || item?.type === 'Metric T' || item?.type === 'Custom') && (item?.metric_unit === 'rupee_cr' ||
                                                            item?.metric_unit === 'percentage' || item?.metric_data_type === 'string' || item?.metric_data_type === 'float') &&
                                                            <Tag color="var(--dark3)" className="textXS" style={{ borderRadius: 8, fontSize: "10px" }}>
                                                                {item?.metric_unit === 'rupee_cr' ? "₹ Cr" : item?.metric_unit === 'percentage' ? "%" :
                                                                    item?.metric_data_type === 'string' ? "Text" : item?.metric_data_type === 'float' ? "Number" : ""}
                                                            </Tag>}
                                                        <span className="textXS" style={{ color: "var(--dark3)", fontSize: "10px", flex: 1, textAlign: "right" }}>
                                                            {(item?.type === "Metric F" || item?.type === 'Metric T') && (item?.category_name !== undefined ||
                                                                item?.sub_category_name !== undefined) ? `${String(item?.category_name)}${item?.sub_category_name !== undefined ? ', ' + String(item?.sub_category_name) : ''}` :
                                                                String(item?.sub_type || item?.value)?.toUpperCase()}</span>
                                                    </div>}
                                            </>
                                        </SubscribeContentWrapper>
                                    )
                                })} </InfiniteScroll> : <div className="my-2">
                                <NoDataFound message={selectedTab === 'Variable' && !hideCreateMetric ? "You have not created any custom metric." : "No results"} width="5rem" />
                            </div>}
                        </div>
                    </div>
                </div>}
            </div>
        </React.Fragment>
    )
})