import { baseImgURL } from "Assets/Constant";
import { GIGO } from "Pages/MarketingPages/MarketingComponents/GIGO";
import { AppSection } from './Sections/AppSection';
import { AppDownloadCard } from './Sections/Components/AppDownloadCard';
import { FactorModelSection } from "./Sections/FactorModelSection";
import { PricingSection } from "./Sections/PricingSection";
import { PublisherSection } from "./Sections/PublisherSection";
import { StyleboxSection } from "./Sections/StyleboxSection";
import { TopSection } from "./Sections/TopSection";
import { VideoSection } from "./Sections/VideoSection";

const LandingPage = () => {

    return <main style={{ backgroundImage: "linear-gradient(180deg, #09203f 0%, #000 100%)", overflowX: "hidden" }}>
        <div style={{ minHeight: '100vh', width: "100%", display: "flex", flexDirection: "column" }}>
            <TopSection />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: "100%", position: "relative" }}>
            <VideoSection type={1} />
        </div>
        <div>
            <img loading="lazy" alt="divider" src={`${baseImgURL}sharpely-asset/images/Home/sharpely-border.webp`} width={'100%'} height={'100%'} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
            <StyleboxSection />
        </div>
        <div className="" style={{ transform: "rotate(180deg)" }}>
            <img loading="lazy" alt="divider" src={`${baseImgURL}sharpely-asset/images/Home/sharpely-border.webp`} width={'100%'} height={'100%'} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
            <VideoSection type={3} />
        </div>
        <div>
            <img loading="lazy" alt="divider" src={`${baseImgURL}sharpely-asset/images/Home/sharpely-border.webp`} width={'100%'} height={'100%'} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
            <FactorModelSection />
        </div>
        <div className="" style={{ transform: "rotate(180deg)" }}>
            <img loading="lazy" alt="divider" src={`${baseImgURL}sharpely-asset/images/Home/sharpely-border.webp`} width={'100%'} height={'100%'} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
            <VideoSection type={5} />
        </div>
        <div>
            <img loading="lazy" alt="divider" src={`${baseImgURL}sharpely-asset/images/Home/sharpely-border.webp`} width={'100%'} height={'100%'} />
        </div>
        <div style={{ width: "100%" }}>
            <PricingSection />
        </div>
        <div className="flipImage">
            <img loading="lazy" alt="divider" src={`${baseImgURL}sharpely-asset/images/Home/sharpely-border.webp`} width={'100%'} height={'100%'} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
            <VideoSection type={7} />
        </div>
        <div >
            <img loading="lazy" alt="divider" src={`${baseImgURL}sharpely-asset/images/Home/sharpely-border.webp`} width={'100%'} height={'100%'} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
            <PublisherSection />
        </div>
        <div className="flipImage">
            <img loading="lazy" alt="divider" src={`${baseImgURL}sharpely-asset/images/Home/sharpely-border.webp`} width={'100%'} height={'100%'} />
        </div>
        <div>
            <GIGO />
        </div>
        <div>
            <img loading="lazy" alt="divider" src={`${baseImgURL}sharpely-asset/images/Home/sharpely-border.webp`} width={'100%'} height={'100%'} />
        </div>
        <div>
            <AppSection />
        </div>
        <AppDownloadCard />
    </main>
}

export default LandingPage;