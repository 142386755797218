import { ReloadOutlined } from '@ant-design/icons'
import { microphone } from "Assets/images"
import AMCIcon from "Components/AMCIcon"
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox"
import { CurrencyFormat } from "Components/CurrencyFormat"
import { getDateDiffInShort, getYYYYMMDD } from "Components/DateFormatter"
import SharpelyGateway2 from 'Components/SharpelyGateway/SharpelyGateway2'
import MyTable from "Components/TableWithScroll"
import { AlertIcon } from 'Components/atoms/AlertIcon'
import { CardHeader } from "Components/atoms/SmallComponents"
import { fetchFundDataWithoutFilter } from "Libs/fetchFundData"
import { makeSubstringBold, useGetAssetType } from "Libs/utils"
import AdminServices from "Services/api/AdminServices"
import apiHelper from "Services/api/ApiHelper"
import { StockLivePrice } from "Services/api/LivePricesWebSocket"
import { Badge, Button, Card, Drawer, Tabs, Tooltip } from "antd"
import React from "react"
import { IoIosClose } from "react-icons/io"
import { PiBell } from "react-icons/pi"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"

const Notifications = () => {
    const user = useSelector((state) => state?.updateUserData?.value);
    const [notificationModal, setnotificationModal] = React.useState(false);
    const [allStocks, setallStocks] = React.useState([])
    const [allETFs, setallETFs] = React.useState([])
    const [allMFs, setallMFs] = React.useState([])
    const [notifications, setNotifications] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [loadMore, setLoadMore] = React.useState(false);
    const [initialDataLoaded, setInitialDataLoaded] = React.useState(false);
    const [start_date, setstart_date] = React.useState(getYYYYMMDD(new Date().setDate(new Date().getDate() - 7)))
    const navigate = useNavigate();

    const loadNotifications = async (st) => {
        st = st || start_date
        var res = await AdminServices.getPushNotifications(user.id, st).then((res) => { return res })
        setNotifications(res?.data?.reverse() || []);
        setLoading(false);
        setLoadMore(false);
        setstart_date(st)
    }

    const loadFundData = async () => {
        var a1 = await fetchFundDataWithoutFilter(4)
        setallStocks(a1)
        var a2 = await fetchFundDataWithoutFilter(1)
        setallETFs(a2)
        var a3 = await fetchFundDataWithoutFilter(2)
        setallMFs(a3)
    }

    const loadInitialDataOnFocus = async () => {
        if (!initialDataLoaded) {
            setInitialDataLoaded(true);
            setLoading(true);
            await loadFundData();
            await loadNotifications();
        }
    }

    const expireNotification = async (id) => {
        try {
            let res = await apiHelper({ apiName: "expireNotification", data: { clientId: user?.id, id } });
            console.log(res);
            await loadNotifications();
        }
        catch (err) {
            console.log(err)
        }
    }
    var tabs = [
        {
            label: <span style={{}} className="w-500">Alerts</span>,
            key: "Important",
            value: "Important",
        },
        {
            label: <span style={{}} className="w-500">Live orders</span>,
            key: "Others",
            value: "Others",
        }
    ];
    const [selected, setSelected] = React.useState("Important")

    const [detailedView, setdetailedView] = React.useState(false)
    const [detailedViewData, setdetailedViewData] = React.useState({})
    const stockType = useGetAssetType(4);
    const etfType = useGetAssetType(1);
    const mfType = useGetAssetType(2);
    const basketType = useGetAssetType(3);
    const indexType = useGetAssetType(5);

    const getAssetType = (type) => {
        switch (type) {
            case 1: return etfType;
            case 2: return mfType;
            case 3: return basketType;
            case 4: return stockType;
            case 5: return indexType;
        }
        return {};
    }

    const handleNotifClick = (notification) => {

        // notification["MetaData"] = "{\"screen_id\": 51, \"screen_name\": \"Akakk\", \"num_stocks\": 292, \"new_entries\": [\"RAMCOCEM\", \"LODHA\", \"SUMICHEM\", \"COLPAL\"], \"moved_out\": [\"APOLLOHOSP\", \"KAJARIACER\", \"SONACOMS\"]}"
        console.log(notification)
        const handleNotificatonCenterTypes = (finalUrl) => {
            //type => screen, fm , pt-strategy , live-strategy
            var type = finalUrl?.[1]
            var client_id = user?.id
            var metaData = JSON.parse(notification?.Metadata || "{}")
            var instrumentType = metaData?.instrument_type || 4
            if (instrumentType === 3)
                instrumentType = 4
            if (type === "screen") {
                var screenId = finalUrl?.[2]
                var finalStocks = [...metaData?.new_entries, ...metaData?.moved_out]?.map((i) => {
                    return {
                        ...([...allStocks]?.find((ii) => ii?.symbol === i)),
                        "STATUS": metaData?.new_entries?.includes(i)
                    }
                })
                console.log(finalStocks)
                setdetailedViewData({
                    client_id: client_id,
                    screenId: screenId,
                    notification: notification,
                    metaData: metaData,
                    finalStocks: finalStocks,
                    detailFooter: `Load screen`,
                    type: getAssetType(instrumentType),
                    header: `"${metaData?.screen_name}" screen - alert details`,
                    url: `/${instrumentType === 4 ? "stocks" : instrumentType === 1 ? "etfs" : "mutual-funds"}/super-screener?screenName=${metaData?.screen_name}&screenId=${screenId}&userId=${client_id}`
                })
                setdetailedView(true)

            } else if (type === "fm") {
                var screenId = finalUrl?.[2]
                var finalStocks = [...metaData?.new_entries, ...metaData?.moved_out]?.map((i) => {
                    return {
                        ...([...allStocks]?.find((ii) => ii?.symbol === i)),
                        "STATUS": metaData?.new_entries?.includes(i)
                    }
                })
                console.log(finalStocks)
                setdetailedViewData({
                    client_id: client_id,
                    screenId: screenId,
                    notification: notification,
                    metaData: metaData,
                    finalStocks: finalStocks,
                    detailFooter: `Load factor model`,
                    type: getAssetType(instrumentType),
                    header: `"${metaData?.screen_name}" factor model - alert details`,
                    url: `/${instrumentType === 4 ? "stocks" : instrumentType === 1 ? "etfs" : "mutual-funds"}/factor-model?fmName=${metaData?.screen_name}&fmId=${screenId}&userId=${client_id}`
                })
                setdetailedView(true)

            } else if (type === "strategy") {

                setnotificationModal(false)
                navigate(`/strategy-detail/${metaData?.strat_name}/${instrumentType}/${metaData?.strategy_id}`, { state: { defTab: "Trade logs" } })
            } else if (type === "stock-alert") {
                setnotificationModal(false)
                var stockD = allStocks?.filter((i) => i?.symbol === finalUrl[2])?.[0]
                navigate(`/stocks/stock-detail/${stockD?.proper_name?.replaceAll(' ', '-')}/${finalUrl[2]}`, { state: { defTab: "Trade logs" } })
            }
        }


        const handleStrategyLiveRebalanceOrders = (finalUrl) => {
            return <SharpelyGateway2 IS_BASKET={true} IS_REBALANCE={true} REBALANCE_STR={finalUrl?.[2]} noCta={true} />
        }

        var finalUrl = notification?.WebURL?.length > 0 ? notification?.WebURL : notification?.AppURL
        if (finalUrl?.includes("sharpely://")) {
            //parse appURl
            finalUrl = finalUrl?.replace("sharpely://", "")?.split("/")
            // console.log(finalUrl?.[0])
            switch (finalUrl?.[0]) {
                case 'notificationCenter':
                    // latest ALerts and seperate screen/drawer
                    return handleNotificatonCenterTypes(finalUrl)
                case 'portfolio':
                    switch (finalUrl?.[1]) {
                        case "rebalance":
                            return handleStrategyLiveRebalanceOrders(finalUrl)
                    }

            }
        }
    }

    const handleCTA = (notification) => {
        var finalUrl = notification?.WebURL?.length > 0 ? notification?.WebURL : notification?.AppURL
        if (finalUrl?.includes("sharpely://")) {
            //parse appURl
            finalUrl = finalUrl?.replace("sharpely://", "")?.split("/")
            // console.log(finalUrl?.[0])
            switch (finalUrl?.[0]) {
                case 'notificationCenter':
                    // latest ALerts and seperate screen/drawer
                    return notification?.ButtonText
                case 'portfolio':
                    switch (finalUrl?.[1]) {
                        case "rebalance":
                            return <SharpelyGateway2 ctaType='text' IS_BASKET={true} btnText={notification?.ButtonText} btnClassName='textXS' IS_REBALANCE={true} REBALANCE_STR={finalUrl?.[2]} />
                    }

            }
        }
    }

    const strategyIconNames = {
        rebalance: 'rebalance',
        sl_bp: 'buySell',
        surveillance: "surveillance",
        corporate_action: "corporateAction"
    }

    return (
        <div>
            <div style={{ cursor: "pointer" }}>
                <Badge dot={notifications?.filter((ele) => ele?.IsExpired === 0)?.length > 0} >
                    <PiBell onClick={() => {
                        loadInitialDataOnFocus()
                        setnotificationModal(true)
                    }} style={{ cursor: "pointer" }} size={'1.25rem'} className="black" />
                </Badge>
            </div>
            <Drawer width='500px'
                title={<div style={{ display: "flex", alignItems: "center" }}>
                    <CardHeader heading={"Notifications"} />
                    <div className="d-flex align-items-center" style={{ gap: "1rem" }}>
                        <ReloadOutlined style={{ cursor: "pointer" }} onClick={() => {
                            setLoading(true);
                            loadNotifications(start_date);
                        }} spin={loading} />
                        <IoIosClose style={{ cursor: "pointer" }} size='1.5rem' onClick={() => {
                            setnotificationModal(false)
                        }} />
                    </div>
                </div>}
                styles={{ header: { padding: "1rem 1.25rem" }, body: { padding: "0rem" } }}
                open={notificationModal} closable={false} onClose={() => {
                    setnotificationModal(false)
                }}>
                {loading ? <div style={{ padding: "1.25rem" }}>
                    <ActivityLoaderMintbox showQuote />
                </div> : <>
                    {notifications?.filter((n) => n?.IsExpired === 0)?.length > 0 ?
                        <div style={{}} className="w100 h100 d-flex flex-column">
                            <div>
                                <Tabs items={tabs} defaultValue={selected} onChange={(e) => {
                                    setSelected(e)
                                }} tabBarStyle={{ padding: "0 1.25rem" }} />
                            </div>
                            <div style={{ padding: "1.25rem", flex: 1, overflowY: "scroll" }}>
                                <div className="d-flex flex-column" style={{ gap: "1rem" }}>
                                    {notifications?.filter((n) => n?.IsExpired === 0 && (selected === "Important" ? n?.Category === "Alerts" : n?.Category !== "Alerts"))?.length > 0 ? notifications?.filter((n) => n?.IsExpired === 0 && (selected === "Important" ? n?.Category === "Alerts" : n?.Category !== "Alerts")).map((n) => {
                                        let iconName = '';
                                        let itemName = '';
                                        let metaData = n?.Metadata;
                                        try {
                                            metaData = typeof (metaData) === 'string' ? metaData?.trim()?.length > 0 ? JSON.parse(metaData) : metaData : metaData;
                                        }
                                        catch (err) {
                                        }
                                        if (n?.NotificationId === 19) {
                                            iconName = 'screen'
                                            itemName = metaData?.screen_name
                                        }
                                        else if (n?.NotificationId === 22) {
                                            iconName = 'factorModel'
                                            itemName = metaData?.screen_name
                                        }
                                        else if (n?.NotificationId === 20) {
                                            let metaData = n?.Metadata;
                                            metaData = typeof (metaData) === 'string' ? JSON.parse(metaData) : metaData;
                                            let t = Object.entries(metaData?.alert || {})?.filter(([_, value]) => {
                                                let c = value && Array.isArray(value) && value?.length > 0
                                                return c;
                                            })?.[0] || [];
                                            iconName = strategyIconNames?.[t?.[0]]
                                            itemName = metaData?.strat_name
                                        }
                                        // console.log(n.Content.replace("{ en = ", "").replace(" }", "")?.split(itemName))
                                        // var
                                        return (
                                            <Card style={{ border: "1px solid var(--grey3)" }} bodyStyle={{ padding: "0.75rem" }} key={n?.UID} onClick={() => {
                                                handleNotifClick(n)
                                            }} hoverable className="shadowXS">
                                                <div style={{ userSelect: "none", opacity: n?.IsExpired === 0 ? 1 : 0.75 }}>
                                                    <div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between', }}>
                                                        <div style={{ display: 'flex', gap: '0.5rem', flex: 1, alignItems: "center" }}>
                                                            <AlertIcon name={iconName} />
                                                            <div className="textSM w-500" dangerouslySetInnerHTML={{ __html: makeSubstringBold(n.Title?.replace("{ en = ", "")?.replace(" }", ""), itemName) }}>
                                                            </div>
                                                        </div>
                                                        <div className="textXS dark2">
                                                            {getDateDiffInShort(new Date(n.CreatedOn), new Date())}
                                                        </div>
                                                    </div>
                                                    <div className="textXS dark3 w-400 mt-1" dangerouslySetInnerHTML={{ __html: makeSubstringBold(n.Content?.replace("{ en = ", "")?.replace(" }", ""), itemName) }}></div>
                                                </div>
                                                <div className="textXS mt-2 w-bold primary">
                                                    {handleCTA(n)}
                                                </div>
                                            </Card>
                                        )
                                    }) : <div className='d-flex flex-column align-items-center text-center my-4'>
                                        <img loading="lazy" alt="" src={microphone} style={{ width: "6rem", marginTop: "1rem" }} />
                                        <div className='textSM w-500 black ' style={{ marginTop: "1rem" }}>
                                            You don't have any new notifications.
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div> : <div className='d-flex flex-column align-items-center text-center my-4' style={{ padding: "1.25rem" }}>
                            <img loading="lazy" alt="" src={microphone} style={{ width: "6rem", marginTop: "1rem" }} />
                            <div className='textSM w-500 black ' style={{ marginTop: "1rem" }}>
                                You don't have any new notifications.
                            </div>
                        </div>}
                    <div style={{ padding: "1.25rem" }} className="d-flex justify-content-center">
                        <Button type="primary" onClick={() => {
                            setLoadMore(true)
                            loadNotifications(getYYYYMMDD(new Date(start_date).setDate(new Date(start_date).getDate() - 7)))
                        }} loading={loadMore}>
                            Load more
                        </Button>
                    </div>
                </>
                }
            </Drawer>


            <Drawer width='500px'
                title={<div style={{ display: "flex", alignItems: "center" }}>
                    <CardHeader heading={detailedViewData?.header} />
                </div>}
                style={{ padding: 0 }}
                open={detailedView} closable={true} onClose={() => {
                    setdetailedView(false)
                }}
                footer={<button className="btnPrimary m-2" style={{ width: "100%" }} onClick={() => {
                    setdetailedView(false)
                    setnotificationModal(false)
                    navigate(detailedViewData?.url);
                }}>{detailedViewData?.detailFooter}</button>}
            >
                <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
                    <div className="textSM w-bold">{detailedViewData?.notification?.Content?.replace("{ en = ", "")?.replace(" }", "")}</div>
                    <MyTable
                        // loading={isPaperTradeView}
                        dataSource={detailedViewData?.finalStocks || []}
                        columns={[{
                            "title": "Name",
                            'disableDeleteInCustomize': true,
                            "dataIndex": detailedViewData?.type?.value === 4 ? "proper_name" : "basic_name",
                            render: (text, record, index) => <Tooltip title={record.proper_name || record.basic_name} arrow={false}>
                                <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center" }}>
                                    <AMCIcon amcName={detailedViewData?.type?.value === 4 ? record.symbol : record.amc_full_name} fundType={detailedViewData?.type?.value} height='1.15rem' width="1.15rem" bseTicker={record?.bse_ticker} />
                                    <div>
                                        <div className="w-500 black" style={{ width: 180, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{record?.proper_name || record.basic_name}</div>
                                        {detailedViewData?.type?.value !== 2 ? <StockLivePrice fundData={record} symbol={record?.symbol || record.nse_symbol} format={10} /> : <span className='dark3 textXS w-500'>NAV :&nbsp; <span className='w-700 textXS'>{CurrencyFormat(record?.latest_nav, 'long', 2, '', false)}</span></span>}
                                    </div>
                                </div>
                            </Tooltip>,
                            // width: 150,
                            // fixed: window.innerWidth > 760 ? 'left' : "",
                            show: true
                        }, {
                            "title": "Status",
                            "dataIndex": "STATUS",
                            "render": (text, record, index) => <div style={{ color: Boolean(text) ? "var(--green)" : "var(--red)" }}>
                                {Boolean(text) ? "New entry" : "Moved out"}
                            </div>
                        }
                        ]}
                        rowKey={(record) => record.plan_id || record.symbol}
                        scroll={{ x: 'max-content' }}
                    />

                </div>
            </Drawer>
        </div>
    )
}
export default Notifications