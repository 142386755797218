import { Card, DatePicker, Segmented, Select, Slider } from "antd";
import { flaticonSvg } from "Assets/Constant";
import { CardHeader } from 'Components/atoms/SmallComponents';
import { InstrumentListSearch } from 'Components/CustomComponents/InstrumentListSearch';
import { StickyScrollTabs } from "Components/CustomComponents/StickyScrollTabs";
import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import bellcurve from "highcharts/modules/histogram-bellcurve";
import Head from 'Layout/Head';
import { fetchFundData } from 'Libs/fetchFundData';
import { useGetAssetType } from "Libs/utils";
import moment from "moment";
import React from "react";
import { Link, useParams } from "react-router-dom";
import apiHelperPY from 'Services/api/ApiHelperPY.js';
import { StockLivePrice } from "Services/api/LivePricesWebSocket";
import ActivityLoaderMintbox from "./ActivityLoaderMintbox";
import AMCIcon from "./AMCIcon";
import { CurrencyFormat } from "./CurrencyFormat";
import { DecimalValueFormat } from "./DecimalValueFormat";
import { NoDataFound } from "./NoDataFound";
import PerformanceChart3 from "./PerformanceChart3";
import RedGreenText from "./RedGreenText";

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
bellcurve(Highcharts);

function Calculators({ fund = null, isDetailPage = false }) {

    const [fundData, setFundData] = React.useState({});
    const [allFunds, setAllFunds] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const params = useParams()
    const [dates, setDates] = React.useState(undefined);
    const type = useGetAssetType();
    const isMobile = window.innerWidth <= 760;
    const isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';


    const loadData = async () => {
        setLoading(true);
        let a = await fetchFundData(type.value);
        if (a?.length) {
            setAllFunds(a);
            let planId = type.value === 2 ? 19701 : 30710;
            setFundData(a?.find((el) => el?.plan_id === planId) || a?.[0]);
        } else {
            console.log("aaya")
            setLoading(false);
        }
    }

    React.useEffect(() => {
        if (isDetailPage && fund) {
            setFundData(fund);
        }
        else {
            loadData();
        }
    }, [fund, type.value]);

    React.useEffect(() => {
        if (Object.keys(fundData)?.length > 0) {
            const dem = async () => {
                let res = await apiHelperPY({ apiName: "getCalculatorDates", data: { plan_id: fundData?.plan_id, reg_plan_id: fundData?.regular_plan_id }, saveResponse: "" });
                console.log("aaya", JSON.parse(res || "{}"))
                if (res) {
                    setDates(JSON.parse(res || "{}"))
                    setLoading(false)
                } else {
                    setDates({})
                    setLoading(false)
                }
            }
            dem();
        }
    }, [fundData])

    const calculateReturns = async (type = 'rr', data = {}) => {
        try {
            let apiName = type === 'rr' ? "rrCalculator" : type === 'sip' ? "sipCalculator" : "swpCalculator";
            let res = await apiHelperPY({ apiName: apiName, data: data, saveResponse: "" });
            return res;
        }
        catch (err) {
            console.log(err);
            return {}
        }
    }

    const DirectRegularSwitch = ({ value, onChange }) => {
        return <Segmented value={value} options={[{ label: "Direct", key: 1, value: false },
        { label: "Regular", key: 2, value: true, disabled: fundData?.regular_plan_id === null }]} onChange={onChange}
            style={{ display: fundData?.regular_plan_id === null ? "none" : "" }} />
    }

    const valueFormatter = (type, value) => {
        let currencyFormats = ['Total Investments', 'Final Corpus', 'Total Payments Done'];
        let returnFormats = ['XIRR (Annualized return)'];
        if (currencyFormats.includes(type)) {
            return CurrencyFormat(value, 'long', 0)
        }
        else if (returnFormats.includes(type)) {
            return DecimalValueFormat({ num: value, decimals: 2, suffix: " %", placeholder: "-" })
        }
        else if (!value && value !== 0) {
            return '-'
        }
        else return value
    }

    const CustomSlider = ({ onAdd, addDisabled, subDisabled, onSubtract, ...props }) => {
        return (
            <div className='d-flex align-items-center mt-2'>
                <Slider {...props} railStyle={{ background: "var(--grey3)", height: "6px" }} trackStyle={{ background: "var(--primary)", height: "6px" }}
                    handleStyle={{ height: "10px" }} className='w100' />
                <button className='btnWhite' disabled={addDisabled} style={{ padding: "0.25rem 0.5rem", marginLeft: "0.5rem" }} onClick={onAdd}>+</button>
                <button className='btnWhite' disabled={subDisabled} style={{ padding: "0.25rem 0.5rem", marginLeft: "0.5rem" }} onClick={onSubtract}>-</button>
            </div>
        )
    }

    const RenderLabelValue = ({ items, data }) => {
        return (
            <div className="w100" style={{ border: "1px solid var(--grey3)", height: "fit-content" }}>
                {items?.map((key, i, arr) => {
                    if (typeof (key) === 'object') {
                        return (
                            <div key={i} style={{
                                background: i % 2 === 0 ? "var(--white)" : "var(--grey4)",
                                borderBottom: i !== arr.length - 1 ? "1px solid var(--grey3)" : "", display: "grid", gridTemplateColumns: "1fr 1fr"
                            }} className='w100'>
                                {key?.map((el, j) => {
                                    return (
                                        <div key={j} style={{
                                            padding: !isMobile ? "0.75rem 1rem" : "0.75rem 1.25rem",
                                            borderRight: j === 0 ? "1px solid var(--grey3)" : ""
                                        }} className='w100 h100'>
                                            <div className="textXS dark3">{el}</div>
                                            <div className="textSM dark4 w-500">{valueFormatter(el, data?.[el])}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }
                    return (
                        <div key={i} style={{
                            background: i % 2 === 0 ? "var(--white)" : "var(--grey4)",
                            padding: !isMobile ? "0.75rem 1rem" : "0.75rem 1.25rem",
                            borderBottom: i !== arr.length - 1 ? "1px solid var(--grey3)" : ""
                        }} className='w100'>
                            <div className="textXS dark3">{key}</div>
                            <div className="textSM dark4 w-500">{valueFormatter(key, data?.[key])}</div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const RRCalculator = () => {
        const [rr, setRr] = React.useState({});
        const [timeFrame, setTimeFrame] = React.useState(1);
        const [start, setStart] = React.useState(moment((dates || {})?.direct?.start).format('YYYY-MM-DD'));
        const [end, setEnd] = React.useState(moment((dates || {})?.direct?.end).format('YYYY-MM-DD'));
        const [isRegular, setIsRegular] = React.useState(false);
        const [errorText, setErrorText] = React.useState('');
        const [loading, setLoading] = React.useState(true);

        console.log("aaya", loading)
        const otherFields = ['text', 'chart_data'];

        const loadData = async () => {
            console.log("dsf")
            try {
                let d = { plan_id: isRegular ? fundData?.regular_plan_id : fundData?.plan_id, tf: timeFrame, start, end, is_regular: isRegular }
                let res = await calculateReturns('rr', d)
                if (res) {
                    res = JSON.parse(res);
                    setRr(res);
                    console.log(res)
                }
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }

        const getListItems = React.useCallback(() => {
            let temp = [];
            let keys = Object.keys(rr || {})?.filter((el) => !otherFields.includes(el));
            temp.push(keys?.splice(0, 2))
            temp.push(keys?.splice(0, 2))
            temp = [...temp, ...keys];
            return temp;
        }, [rr]);

        let items = getListItems();

        const disabledStartDate = React.useCallback((current) => {
            let key = isRegular ? "regular" : "direct"
            let minDate = new Date(moment((dates || {})?.[key]?.start));
            let maxDate = new Date(moment(end).subtract(timeFrame, 'years'));
            return current < minDate || current > maxDate;
        }, [isRegular, dates, end, timeFrame])

        const disabledEndDate = React.useCallback((current) => {
            let key = isRegular ? "regular" : "direct"
            let minDate = new Date(moment(start).add(timeFrame, 'years'));
            let maxDate = new Date((dates || {})?.[key]?.end);
            return current < minDate || current > maxDate;
        }, [isRegular, dates, start, timeFrame]);

        const handleValidation = () => {
            let checkStart = new Date(moment(start)).getTime() >= new Date((dates || {})?.direct?.start).getTime();
            let checkStartReg = new Date(moment(start)).getTime() >= new Date((dates || {})?.regular?.start).getTime();
            let checkEnd = new Date(moment(end)).getTime() <= new Date((dates || {})?.direct?.end).getTime();
            let checkEndReg = new Date(moment(end)).getTime() <= new Date((dates || {})?.regular?.end).getTime();
            let checkTimeFrame = new Date(moment(start).add(timeFrame, 'years')).getTime() <= new Date(moment(end)).getTime()
            if (!checkStart && !isRegular) {
                setErrorText(`For direct funds, start date must be on or after ${moment((dates || {})?.direct?.start).format('DD MMM, YYYY')}`)
            }
            else if (!checkEnd && !isRegular) {
                setErrorText(`For direct funds, start date must be on or before ${moment((dates || {})?.direct?.end).format('DD MMM, YYYY')}`)
            }
            else if (!checkStartReg && isRegular) {
                setErrorText(`For regular funds, start date must be on or after ${moment((dates || {})?.regular?.start).format('DD MMM, YYYY')}`)
            }
            else if (!checkEndReg && isRegular) {
                setErrorText(`For regular funds, end date must be on or before ${moment((dates || {})?.regular?.end).format('DD MMM, YYYY')}`)
            }
            else if (!checkTimeFrame) {
                setErrorText(`The time period between start date and end date must be greater than or equal to ${timeFrame}Y`)
            }
            else {
                setErrorText('');
                return true;
            }
        }

        const onCalculate = () => {
            if (handleValidation()) {
                setLoading(true);
                loadData();
            }
            else {
                setRr({});
                setLoading(false)
            }
        }

        React.useEffect(() => {
            console.log(dates)
            if (dates)
                if ((dates || {})?.direct || (dates || {})?.regular) {
                    onCalculate();
                } else {
                    setLoading(false)
                }
        }, [dates, isRegular, start, end, timeFrame])

        const ChartView = () => {

            const [selectedTab, setSelectedTab] = React.useState('distribution');

            const Scatter = () => {
                const dataMain = JSON.parse(rr?.chart_data || {});
                const yAxisKey = Object.keys(dataMain?.[0] || {})?.[0]
                const options = {
                    title: { text: "" },
                    credits: { enabled: false },
                    exporting: { enabled: false },
                    xAxis: [{
                        title: { text: "", },
                        labels: {
                            formatter: function () {
                                const date = new Date(this.value);
                                const month = Highcharts.dateFormat('%b', date);
                                const year = Highcharts.dateFormat('%y', date);
                                return `${month}, '${year}`;
                            },
                        },
                    }],
                    yAxis: [{
                        title: { text: "Return (%)" }
                    }],
                    plotOptions: {
                        scatter: {
                            tooltip: {
                                pointFormatter: function () {
                                    const date = new Date(this.x);
                                    const formattedDate = moment(date).format('DD MMM, YYYY');
                                    return `Date: ${formattedDate}<br>Return: ${Highcharts.numberFormat(this.y, 2)} %`;
                                }
                            }
                        },
                    },
                    series: [{
                        name: "Scatter plot",
                        data: dataMain?.map((el) => [new Date(el?.Date).getTime(), el?.[yAxisKey]]),
                        marker: {
                            radius: 2.5
                        },
                        type: "scatter",
                        color: 'var(--primary)'
                    }],
                }

                if (!dataMain || !dataMain?.length) return null;
                return (
                    <div>
                        <HighchartsReact highcharts={Highcharts} options={options} />
                    </div>
                )
            }

            const Distribution = () => {
                const dataMain = JSON.parse(rr?.chart_data || {});
                const yAxisKey = Object.keys(dataMain?.[0] || {})?.[0];
                const binCount = 15;
                const minValue = Math.min(...(dataMain?.map((el) => el?.[yAxisKey]) || []));
                const maxValue = Math.max(...(dataMain?.map((el) => el?.[yAxisKey]) || []));
                const binWidth = (maxValue - minValue) / binCount;
                let histogramData = Array.from({ length: binCount }, (_, index) => ({
                    x: minValue + index * binWidth,
                    y: 0
                }));
                dataMain?.forEach(point => {
                    const binIndex = Math.floor((point?.[yAxisKey] - minValue) / binWidth);
                    if (histogramData?.[binIndex]) {
                        histogramData[binIndex].y++;
                    }
                });
                const middleValues = histogramData.map(bin => bin.x + binWidth / 2);
                const options = {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: ''
                    },
                    xAxis: {
                        visible: false,
                        title: {
                            text: 'Returns'
                        },
                        categories: histogramData?.map(bin => `${bin?.x?.toFixed(2)}% - ${(bin?.x + binWidth).toFixed(2)}%`)
                    },
                    yAxis: {
                        title: {
                            text: 'Number of days'
                        }
                    },
                    series: [{
                        name: 'Number of days',
                        data: histogramData?.map(bin => bin?.y),
                        pointPadding: 0,
                        groupPadding: 0,
                        pointPlacement: 'between'
                    }, {
                        data: histogramData?.map(bin => bin?.y),
                        type: "spline",
                        name: "Number of days",
                        pointPlacement: 'between'
                    }],
                    credits: { enabled: false },
                    exporting: { enabled: false },
                    legend: { enabled: false }
                }

                return (
                    <div>
                        <HighchartsReact highcharts={Highcharts} options={options} />
                    </div>
                )
            }

            const tabItems = [
                { label: "Distribution", key: "Distribution", value: "distribution", children: <Distribution /> },
                { label: "Scatter", key: "Scatter", value: "scatter", children: <Scatter /> }
            ]

            return (
                <div>
                    <Segmented options={tabItems} value={selectedTab} onChange={(e) => setSelectedTab(e)} style={{ marginBottom: "1.25rem" }} />
                    <div>
                        {tabItems?.find((el) => el?.value === selectedTab)?.children}
                    </div>
                </div>
            )
        }

        return (
            <div>
                <div className="d-flex align-items-center justify-content-between">
                    <DirectRegularSwitch value={isRegular} onChange={(e) => { setIsRegular(e) }} />
                </div>
                <div className="d-flex align-items-center w100" style={{ gap: "1.25rem", marginTop: "1rem" }}>
                    <div>
                        <div className="textSM w-500 mb-1">Timeframe</div>
                        <Select options={[{ label: "1Y", key: 1, value: 1 }, { label: "3Y", key: 2, value: 3 }, { label: "5Y", key: 3, value: 5 }]} value={timeFrame}
                            onChange={(e) => { setTimeFrame(e) }} style={{ minWidth: "10rem" }} />
                    </div>
                    <div>
                        <div className="textSM w-500 mb-1">Start date</div>
                        <DatePicker disabledDate={disabledStartDate} defaultValue={dayjs(start, 'YYYY-MM-DD')} format={'DD-MM-YYYY'} onChange={(e) => {
                            setStart(moment(new Date(e)).format('YYYY-MM-DD'))
                        }} />
                    </div>
                    <div>
                        <div className="textSM w-500 mb-1">End date</div>
                        <DatePicker disabledDate={disabledEndDate} defaultValue={dayjs(end, 'YYYY-MM-DD')} format={'DD-MM-YYYY'} onChange={(e) => {
                            setEnd(moment(new Date(e)).format('YYYY-MM-DD'))
                        }} />
                    </div>
                </div>
                <div style={{ marginTop: "1.25rem" }}>
                    {loading ? <ActivityLoaderMintbox showQuote margin="2rem" /> : Object.keys(rr || {})?.length <= 0 ? <>
                        <div>
                            {errorText ? <div className='textMD w-500 text-center' style={{ color: "var(--red)", margin: "1rem 0" }}>{errorText}</div> : <NoDataFound />}
                        </div>
                    </> : <>
                        <div className="textMD w-700 dark4">
                            {rr?.text}
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr", marginTop: "1.25rem", gap: "1.25rem" }}>
                            <RenderLabelValue items={items} data={rr} />
                            <div className="w100" style={{ border: "1px solid var(--grey2)", padding: "1rem", borderRadius: "var(--borderRadius)", height: "fit-content" }}>
                                {rr?.chart_data && <ChartView />}
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        )
    }

    const SIPCalculator = () => {
        const [sipData, setSipData] = React.useState({});
        const [step, setStep] = React.useState(5);
        const [start, setStart] = React.useState(moment((dates || {})?.direct?.start).format('YYYY-MM-DD'));
        const [end, setEnd] = React.useState(moment((dates || {})?.direct?.end).format('YYYY-MM-DD'));
        const [sipAmount, setSipAmount] = React.useState(1000);
        const [isRegular, setIsRegular] = React.useState(false);
        const [errorText, setErrorText] = React.useState('');
        const [loading, setLoading] = React.useState(true);

        const otherFields = ['chart_data'];

        const loadData = async () => {
            try {
                let d = { plan_id: isRegular ? fundData?.regular_plan_id : fundData?.plan_id, step: step, start, end, is_regular: isRegular, sip: sipAmount }
                let res = await calculateReturns('sip', d)
                if (res) {
                    res = JSON.parse(res?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))
                    console.log(res)
                    console.log(JSON.parse(res?.chart_data || '[]'));
                    setSipData(res);
                }
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }

        const disabledStartDate = React.useCallback((current) => {
            let key = isRegular ? "regular" : "direct"
            let minDate = new Date(moment((dates || {})?.[key]?.start));
            let maxDate = new Date(moment(end).subtract(1, 'months'));
            return current < minDate || current > maxDate;
        }, [isRegular, dates, end])

        const disabledEndDate = React.useCallback((current) => {
            let key = isRegular ? "regular" : "direct"
            let minDate = new Date(moment(start).add(1, 'months'));
            let maxDate = new Date((dates || {})?.[key]?.end);
            return current < minDate || current > maxDate;
        }, [isRegular, dates, start]);

        const handleValidation = () => {
            let checkStart = new Date(moment(start)).getTime() >= new Date((dates || {})?.direct?.start).getTime();
            let checkStartReg = new Date(moment(start)).getTime() >= new Date((dates || {})?.regular?.start).getTime();
            let checkEnd = new Date(moment(end)).getTime() <= new Date((dates || {})?.direct?.end).getTime();
            let checkEndReg = new Date(moment(end)).getTime() <= new Date((dates || {})?.regular?.end).getTime();
            if (!checkStart && !isRegular) {
                setErrorText(`For direct funds, start date must be on or after ${moment((dates || {})?.direct?.start).format('DD MMM, YYYY')}`)
            }
            else if (!checkEnd && !isRegular) {
                setErrorText(`For direct funds, start date must be on or before ${moment((dates || {})?.direct?.end).format('DD MMM, YYYY')}`)
            }
            else if (!checkStartReg && isRegular) {
                setErrorText(`For regular funds, start date must be on or after ${moment((dates || {})?.regular?.start).format('DD MMM, YYYY')}`)
            }
            else if (!checkEndReg && isRegular) {
                setErrorText(`For regular funds, end date must be on or before ${moment((dates || {})?.regular?.end).format('DD MMM, YYYY')}`)
            }
            else {
                setErrorText('')
                return true;
            }
        }

        const onCalculate = () => {
            if (handleValidation()) {
                setLoading(true);
                loadData();
            }
            else {
                setSipData({})
                setLoading(false)
            }
        }

        React.useEffect(() => {
            if (dates)
                if ((dates || {})?.direct || (dates || {})?.regular) {
                    onCalculate();
                } else {
                    setLoading(false)
                }
        }, [dates, isRegular, start, end, sipAmount, step])

        return (
            <div>
                <div className="d-flex align-items-center justify-content-between">
                    <DirectRegularSwitch value={isRegular} onChange={(e) => { setIsRegular(e) }} />
                </div>
                <div className="w100" style={{ marginTop: "1.25rem", display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "1.25rem" }}>
                    <div style={{ width: "100%" }}>
                        <div className="textSM w-500 mb-1 d-flex align-items-center justify-content-between">
                            SIP amount
                            <div style={{ background: "var(--blueShade)", padding: "0.25rem 0.5rem", borderRadius: "var(--borderRadius)" }}>
                                ₹ {sipAmount}
                            </div>
                        </div>
                        <CustomSlider min={500} max={50000} step={500} value={sipAmount} onChange={(e) => setSipAmount(e)} tooltip={{ formatter: null }}
                            onAdd={() => { setSipAmount(prev => prev + 500) }} onSubtract={() => { setSipAmount(prev => prev - 500) }} addDisabled={sipAmount === 50000}
                            subDisabled={sipAmount === 500} />
                    </div>
                    <div style={{ width: "100%" }}>
                        <div className="textSM w-500 mb-1 d-flex align-items-center justify-content-between">
                            Annual step up
                            <div style={{ background: "var(--blueShade)", padding: "0.25rem 0.5rem", borderRadius: "var(--borderRadius)" }}>
                                {step} %
                            </div>
                        </div>
                        <CustomSlider min={0} max={100} step={5} value={step} onChange={(e) => setStep(e)} tooltip={{ formatter: null }}
                            onAdd={() => { setStep(prev => prev + 5) }} onSubtract={() => { setStep(prev => prev - 5) }} addDisabled={step === 100} subDisabled={step === 0} />
                    </div>
                </div>
                <div className="d-flex align-items-center w100" style={{ gap: "1.25rem", marginTop: "0.75rem" }}>
                    <div>
                        <div className="textSM w-500 mb-1">Start date</div>
                        <DatePicker disabledDate={disabledStartDate} defaultValue={dayjs(start, 'YYYY-MM-DD')} format={'DD-MM-YYYY'} onChange={(e) => {
                            setStart(moment(new Date(e)).format('YYYY-MM-DD'))
                        }} />
                    </div>
                    <div>
                        <div className="textSM w-500 mb-1">End date</div>
                        <DatePicker disabledDate={disabledEndDate} defaultValue={dayjs(end, 'YYYY-MM-DD')} format={'DD-MM-YYYY'} onChange={(e) => {
                            setEnd(moment(new Date(e)).format('YYYY-MM-DD'))
                        }} />
                    </div>
                </div>
                <div style={{ marginTop: "1.25rem" }}>
                    {loading ? <ActivityLoaderMintbox showQuote margin="2rem" /> : Object.keys(sipData || {})?.length <= 0 ? <>
                        <div>
                            {errorText ? <div className='textMD w-500 text-center' style={{ color: "var(--red)", margin: "1rem 0" }}>{errorText}</div> : <NoDataFound />}
                        </div>
                    </> : <>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr", marginTop: "1.25rem", gap: "1.25rem" }}>
                            <RenderLabelValue data={sipData} items={Object.keys(sipData || {})?.filter((el) => !otherFields.includes(el))} />
                            <div className="w100" style={{ border: "1px solid var(--grey2)", padding: "1rem", borderRadius: "var(--borderRadius)" }}>
                                {sipData?.chart_data && <PerformanceChart3 chartData={sipData?.chart_data} chartType='line' showYAxis showLegend
                                    tooltip={{ valueDecimals: 0, valuePrefix: "₹ " }} height={300} />}
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        )
    }

    const SWPCalculator = () => {

        const [swpData, setSwpData] = React.useState({});
        const [step, setStep] = React.useState(5);
        const [start, setStart] = React.useState(moment((dates || {})?.direct?.start).format('YYYY-MM-DD'));
        const [end, setEnd] = React.useState(moment((dates || {})?.direct?.end).format('YYYY-MM-DD'));
        const [lumpsum, setLumpsum] = React.useState(500000);
        const [swp, setSwp] = React.useState(getIntitialSwp())
        const [isRegular, setIsRegular] = React.useState(false);
        const [loading, setLoading] = React.useState(true);
        const [errorText, setErrorText] = React.useState('');
        const otherFields = ['chart_data', 'Payment_schedule'];

        const loadData = async () => {
            try {
                let d = { plan_id: isRegular ? fundData?.regular_plan_id : fundData?.plan_id, start, end, is_regular: isRegular, ls: lumpsum, swp, step }
                let res = await calculateReturns('swp', d)
                if (res) {
                    res = JSON.parse(res);
                    setSwpData(res);
                    console.log(res)
                }
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }

        const disabledStartDate = React.useCallback((current) => {
            let key = isRegular ? "regular" : "direct"
            let minDate = new Date(moment((dates || {})?.[key]?.start));
            let maxDate = new Date(moment(end).subtract(1, 'months'));
            return current < minDate || current > maxDate;
        }, [isRegular, dates, end])

        const disabledEndDate = React.useCallback((current) => {
            let key = isRegular ? "regular" : "direct"
            let minDate = new Date(moment(start).add(1, 'months'));
            let maxDate = new Date((dates || {})?.[key]?.end);
            return current < minDate || current > maxDate;
        }, [isRegular, dates, start]);

        const handleValidation = () => {
            let checkStart = new Date(moment(start)).getTime() >= new Date((dates || {})?.direct?.start).getTime();
            let checkStartReg = new Date(moment(start)).getTime() >= new Date((dates || {})?.regular?.start).getTime();
            let checkEnd = new Date(moment(end)).getTime() <= new Date((dates || {})?.direct?.end).getTime();
            let checkEndReg = new Date(moment(end)).getTime() <= new Date((dates || {})?.regular?.end).getTime();
            if (!checkStart && !isRegular) {
                setErrorText(`For direct funds, start date must be on or after ${moment((dates || {})?.direct?.start).format('DD MMM, YYYY')}`)
            }
            else if (!checkEnd && !isRegular) {
                setErrorText(`For direct funds, start date must be on or before ${moment((dates || {})?.direct?.end).format('DD MMM, YYYY')}`)
            }
            else if (!checkStartReg && isRegular) {
                setErrorText(`For regular funds, start date must be on or after ${moment((dates || {})?.regular?.start).format('DD MMM, YYYY')}`)
            }
            else if (!checkEndReg && isRegular) {
                setErrorText(`For regular funds, end date must be on or before ${moment((dates || {})?.regular?.end).format('DD MMM, YYYY')}`)
            }
            else {
                setErrorText('');
                return true;
            }
        }

        const onCalculate = () => {
            if (handleValidation()) {
                setLoading(true);
                loadData();
            }
            else {
                setSwpData({});
                setLoading(false)
            }
        }

        const changeLumpsum = (value) => {
            setLumpsum(value);
            if (fundData?.primary_category_name === 'Equity') {
                let c = Math.ceil((value * 0.83) / 100);
                setSwp(c);
            }
            else if (fundData?.primary_category_name === 'Debt') {
                let c = Math.ceil((value * 0.50) / 100);
                setSwp(c);
            }
            else {
                let c = Math.ceil((value * 0.67) / 100);
                setSwp(c);
            }
        }

        function getIntitialSwp() {
            let c = 0;
            if (fundData?.primary_category_name === 'Equity') {
                c = Math.ceil((500000 * 0.83) / 100);
            }
            else if (fundData?.primary_category_name === 'Debt') {
                c = Math.ceil((500000 * 0.50) / 100);
            }
            else {
                c = Math.ceil((500000 * 0.67) / 100);
            }
            return c;
        }

        const getSwpMax = React.useCallback(() => {
            let c = 0;
            if (fundData?.primary_category_name === 'Equity') {
                c = Math.ceil((lumpsum * 1.33) / 100);
            }
            else if (fundData?.primary_category_name === 'Debt') {
                c = Math.ceil((lumpsum * 0.83) / 100);
            }
            else {
                c = Math.ceil((lumpsum * 1) / 100);
            }
            return c;
        }, [lumpsum]);

        React.useEffect(() => {
            if (dates)
                if ((dates || {})?.direct || (dates || {})?.regular) {
                    onCalculate();
                } else {
                    setLoading(false)
                }
        }, [isRegular, start, end, lumpsum, swp, step, dates])

        return (
            <div>
                <div className="d-flex align-items-center justify-content-between">
                    <DirectRegularSwitch value={isRegular} onChange={(e) => { setIsRegular(e) }} />
                </div>
                <div style={{ marginTop: "1.25rem", display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "1.25rem" }}>
                    <div style={{ width: "100%" }}>
                        <div className="textSM w-500 mb-1 d-flex align-items-center justify-content-between">
                            Lumpsum amount
                            <div style={{ background: "var(--blueShade)", padding: "0.25rem 0.5rem", borderRadius: "var(--borderRadius)" }}>
                                {CurrencyFormat(lumpsum, 'long', 0)}
                            </div>
                        </div>
                        <CustomSlider min={10000} max={5000000} step={10000} value={lumpsum} onChange={changeLumpsum} tooltip={{ formatter: null }}
                            onAdd={() => changeLumpsum(lumpsum + 10000)} onSubtract={() => changeLumpsum(lumpsum - 10000)} subDisabled={lumpsum === 10000} addDisabled={lumpsum === 5000000} />
                    </div>
                    <div style={{ width: "100%" }}>
                        <div className="textSM w-500 mb-1 d-flex align-items-center justify-content-between">
                            Monthly  withdrawal amount
                            <div style={{ background: "var(--blueShade)", padding: "0.25rem 0.5rem", borderRadius: "var(--borderRadius)" }}>
                                {CurrencyFormat(swp, 'long', 0)}
                            </div>
                        </div>
                        <CustomSlider min={0} max={getSwpMax()} step={500} value={swp} onChange={(e) => setSwp(e)} tooltip={{ formatter: null }}
                            onAdd={() => {
                                setSwp(prev => {
                                    if (prev + 500 > getSwpMax()) { return getSwpMax() }
                                    else return prev + 500
                                })
                            }} onSubtract={() => {
                                setSwp(prev => {
                                    if (prev - 500 < 0) { return 0 }
                                    else return prev - 500
                                })
                            }} addDisabled={swp === getSwpMax()} subDisabled={swp === 0} />
                    </div>
                    <div style={{ width: "100%" }}>
                        <div className="textSM w-500 mb-1 d-flex align-items-center justify-content-between">
                            Annual step up
                            <div style={{ background: "var(--blueShade)", padding: "0.25rem 0.5rem", borderRadius: "var(--borderRadius)" }}>
                                {step} %
                            </div>
                        </div>
                        <CustomSlider min={0} max={100} step={5} value={step} onChange={(e) => setStep(e)} tooltip={{ formatter: null }}
                            onAdd={() => { setStep(prev => prev + 5) }} onSubtract={() => { setStep(prev => prev - 5) }} addDisabled={step === 100} subDisabled={step === 0} />
                    </div>
                </div>
                <div className="d-flex align-items-center w100" style={{ gap: "1.25rem", marginTop: "1rem" }}>
                    <div>
                        <div className="textSM w-500 mb-1">Start date</div>
                        <DatePicker disabledDate={disabledStartDate} defaultValue={dayjs(start, 'YYYY-MM-DD')} format={'DD-MM-YYYY'} onChange={(e) => {
                            setStart(moment(new Date(e)).format('YYYY-MM-DD'))
                        }} />
                    </div>
                    <div>
                        <div className="textSM w-500 mb-1">End date</div>
                        <DatePicker disabledDate={disabledEndDate} defaultValue={dayjs(end, 'YYYY-MM-DD')} format={'DD-MM-YYYY'} onChange={(e) => {
                            setEnd(moment(new Date(e)).format('YYYY-MM-DD'))
                        }} />
                    </div>
                </div>
                <div style={{ marginTop: "1.25rem" }}>
                    {loading ? <ActivityLoaderMintbox showQuote margin="2rem" /> : Object.keys(swpData || {})?.length <= 0 ? <>
                        <div>
                            {errorText ? <div className='textMD w-500 text-center' style={{ color: "var(--red)", margin: "1rem 0" }}>{errorText}</div> : <NoDataFound />}
                        </div>
                    </> : <>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr", marginTop: "1.25rem", gap: "1.25rem" }}>
                            <RenderLabelValue data={swpData} items={Object.keys(swpData || {})?.filter((el) => !otherFields.includes(el))} />
                            <div className="w100" style={{ border: "1px solid var(--grey2)", padding: "1rem", borderRadius: "var(--borderRadius)" }}>
                                {swpData?.chart_data && <PerformanceChart3 chartData={swpData?.chart_data} chartType={'line'} showYAxis height={300}
                                    tooltip={{ valueDecimals: 0, valuePrefix: "₹ " }} />}
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        )
    }

    const tabData = [
        {
            key: "RollingReturnsCalculator",
            label: "Rolling Returns",
            value: "RollingReturnsCalculator",
            heading: "Rolling Returns",
            subHeading: "Calculate rolling returns of the scheme during any time period and for any time frame. For schemes before 1st Jan 2013, switch to regular to get larger history.",
            children: <RRCalculator />,
            show: true,
        },
        {
            key: "SIPCalculator",
            label: "SIP Returns",
            value: "SIPCalculator",
            heading: "SIP Returns",
            subHeading: "Calculate SIP performance of the scheme during any time period. For schemes before 1st Jan 2013, switch to regular to get larger history.",
            children: <SIPCalculator />,
            show: true
        },
        {
            key: "SWPCalculator",
            label: "SWP Calculator",
            value: "SWPCalculator",
            heading: "SWP Calculator",
            subHeading: "Calculate how SWP would have worked on this scheme during any time period. For schemes before 1st Jan 2013, switch to regular to get larger history.",
            children: <SWPCalculator />,
            show: true
        }
    ]

    const defaultSelectedTabOptions = { 'sip-calculator': "SIPCalculator", "swp-calculator": "SWPCalculator", "rr-calculator": "RollingReturnsCalculator" };

    const metaItems = {
        'sip-calculator': {
            title: "SIP Calculator - Systematic Investment Plan Calculator Online",
            description: "SIP Calculator - Systematic Investment Plan calculator is a tool that helps you determine the returns you can avail when parking your funds in such investment tools."
        },
        'swp-calculator': {
            title: "SWP (Systematic Withdrawal Plan) Calculator",
            description: "SWP Calculator - Systematic Withdrawal Plan calculator takes into consideration of returns from your investment to gives you the total value of your investment at the end of your SWP tenure."
        },
        'rr-calculator': {
            title: `Rolling Returns Calculator for ${type?.dname}`,
            description: `Rolling Returns Calculator helps you to find the rolling returns as per the rolling time period it's start date and whole amount instantly. Get best schemes.`
        }
    }

    if (loading && !isDetailPage) {
        return (
            <div className='loaderContainer'>
                <ActivityLoaderMintbox showQuote />
            </div>
        )
    }
    return (
        <div>
            {!isDetailPage && <>
                <Head title={metaItems?.[params?.calculator?.toLowerCase()]?.title || 'Calculator'} description={metaItems?.[params?.calculator?.toLowerCase()]?.description || 'Calculator'} />
                <Card bordered={false} styles={{ body: { paddingTop: "1.25rem", paddingBottom: "1.25rem", paddingLeft: "var(--margin)", paddingRight: "var(--margin)", borderBottom: "1px solid var(--grey2)" } }} id='calculatorsTopHeadingCard'>
                    <div className='d-flex align-items-sm-end align-items-start justify-content-between flex-sm-row flex-column' style={{ gap: "1.25rem" }}>
                        <div>
                            <CardHeader heading={'Calculators'} textClassName={isMobile ? "textMD w-500" : 'textXL w-700'} headingType={2} />
                            <div className='customTabsSubHeading' style={{ marginBottom: 0 }}>
                                Optimize investments with SIP, SWP, and more. Empower your financial journey with our calculators!
                            </div>
                        </div>
                        <div>
                            <InstrumentListSearch allInstrumentsList={allFunds} placeholder={`Search for ${type.dname}`} type={type.value} onChange={(e) => {
                                setFundData(e[0])
                            }} align={isMobile ? "left" : 'right'} />
                        </div>
                    </div>
                    {/* <Divider style={{margin:"0.75rem 0"}} /> */}
                    <div style={{ display: "flex", gap: "1.25rem", justifyContent: "space-between", flexDirection: isMobile ? "column" : "row", marginTop: "1.25rem" }}>
                        <div className="d-flex" style={{ gap: "1rem" }}>
                            <AMCIcon fundType={type?.value} amcName={fundData?.amc_full_name} type="square" height={isMobile ? "4rem" : "3rem"} width={isMobile ? "4rem" : "3rem"} />
                            <div>
                                <Link to={type.basePath + `${'/fund-detail/' + fundData?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + fundData?.plan_id}`}>
                                    <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", }}>
                                        <h1 className={isMobile ? "margin0 w-500 dark4 textSM w-500" : "margin0 textLG w-700 dark4"} style={{ fontSize: isMobile ? "20px" : "" }}>
                                            {fundData?.basic_name}
                                            <img loading="lazy" alt="" src={`${flaticonSvg}fi-rr-arrow-up-right-from-square.svg`} style={{ height: "1rem", marginLeft: "0.5rem" }} />
                                        </h1>
                                    </div>
                                </Link>
                                <div className={isMobile ? "textSM dark3 w-500" : "textSM dark3 w-700"} style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                    {type.value === 1 ? "ETF" : "MF"}
                                </div>
                            </div>
                        </div>
                        <div>
                            {type.value === 1 ? <StockLivePrice fundData={fundData} format={8} symbol={fundData?.nse_symbol} /> : <>
                                <span className='w-700 textXL'>{CurrencyFormat(fundData?.latest_nav, 'long', 2, '', false)}</span>
                                <div style={{ textAlign: isMobile ? "" : "right" }}>
                                    <RedGreenText text={DecimalValueFormat({ num: fundData?.ret_1day, suffix: "%" })} value={fundData?.ret_1day} />
                                </div>
                            </>}
                        </div>
                    </div>
                </Card>
            </>}
            <StickyScrollTabs isAccordionInMob={isDetailPage} queriesToStickToBot={['#sharpelyHeader', '#customTabsBarId']} tabData={tabData} isSingleChildInDesk={!isDetailPage}
                tabChangeOffsetIdInDesk={'sharpelyHeader'} defaultSelectedTab={isDetailPage ? "" : defaultSelectedTabOptions?.[params?.calculator?.toLowerCase()]}
                hideTabs={!isDetailPage} />
        </div>
    );
}

export default React.memo(Calculators);