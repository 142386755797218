import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { Link, useLocation } from 'react-router-dom';
import { ClosableHeader } from 'Components/ClosableHeader';

const BreadCrumbs = () => {

    const disabledPaths = ['/etfs', '/blog', '/mutual-funds', '/stocks', '/', '/login',
        '/signup', '/about-us', '/pricing', '/privacy-policy', '/terms-and-conditions', '/refund-and-cancellation-policy', '/get-early-access', '/contact-us', '/technical-chart',
        '/broker-page', '/user-onboarding', '/onboarding', '/campaign/stock-report', '/campaign/strategy', '/campaign/screener', '/insider-trades', '/nfo-tracker'
        // '/stocks/super-screener'
    ];

    const disabledStartsWithPaths = ['/scheduled-order', '/payment-status', '/payment-success', '/payment-failed', '/algo',
        // '/stocks/super-screener'
    ]

    let modifiedDashboardPaths = ['etfs', 'mutual-funds', 'stocks'];
    let omittedPaths = ['', '-', 'fund-detail', 'stock-detail', 'basket-detail', 'blog', 'strategy-detail']
    let detailPaths = ['stock-detail', 'fund-detail', 'basket-detail', 'index-detail', 'strategy-detail']
    let otherOmmittedPaths = [{ startsWith: "/blog/category", omit: ["blog", "category"] }, { startsWith: "/indices/index-detail", omit: ["index-detail"] },
    { startsWith: "/stocks/dashboard", omit: ['stocks'] }, { startsWith: "/etfs/dashboard", omit: ['etfs'] }, { startsWith: "/mutual-funds/dashboard", omit: ['mutual-funds'] },
    { startsWith: "/etfs/calculators", omit: ['calculators'] }, { startsWith: "/mutual-funds/calculators", omit: ['calculators'] }]
    let textsToModify = {
        dashboard: { index: -1, values: { stocks: "Dashboard", etfs: "ETF Dashboard", 'mutual-funds': "MF Dashboard" } },
        ['all-screens']: { index: -1, values: { stocks: "Stock Screens", etfs: "ETF Screens", 'mutual-funds': "MF Screens" } },
        ['rr-calculator']: { index: -1, values: { calculators: 'RR Calculator' } }, ['sip-calculator']: { index: -1, values: { calculators: 'SIP Calculator' } },
        ['swp-calculator']: { index: -1, values: { calculators: 'SWP Calculator' } }
    }

    const safeDecodeURIComponent = (str) => {
        try {
            return decodeURIComponent(str);
        } catch (e) {
            let result = '';
            let currentIndex = 0;

            while (currentIndex < str.length) {
                let nextPercent = str.indexOf('%', currentIndex);

                if (nextPercent === -1) {
                    result += str.slice(currentIndex);
                    break;
                }

                if (nextPercent !== currentIndex) {
                    result += str.slice(currentIndex, nextPercent);
                    currentIndex = nextPercent;
                }

                let segment = str.slice(nextPercent, nextPercent + 3);
                try {
                    result += decodeURIComponent(segment);
                    currentIndex += 3;
                } catch (decodeError) {
                    result += '%';
                    currentIndex++;
                    if (currentIndex < str.length && str[currentIndex] === '%') {
                        result += '%';
                        currentIndex++;
                    }
                }
            }

            return result;
        }
    };

    const formatNavItems = (location) => {
        let regexPattern = /[^A-Za-z0-9]/g;
        let to = '';
        let loc = safeDecodeURIComponent(location.pathname);
        let links = []
        let arr = loc?.split('/');
        let otherOmitCheck = otherOmmittedPaths?.find((el) => loc?.startsWith(el?.startsWith));
        for (let i = 0; i < arr.length; i++) {
            let ele = arr[i];
            if (omittedPaths.includes(ele)) {
                if (ele) to += '/' + ele;
            }
            else if (otherOmitCheck && otherOmitCheck?.omit?.includes(ele)) {
                if (ele) {
                    to += '/' + ele
                }
            }
            else {
                let temp = ele.replace(regexPattern, ' ');
                // For modifying path of resources
                if (temp.endsWith('html')) {
                    temp = temp.split(' ');
                    temp.pop();
                    temp = temp.join(' ');
                }
                // For modifying labels
                else if (temp === 'etfs') temp = 'ETF';
                else if (temp === 'screener') {
                    if (arr[i - 1] === 'etfs') temp = 'ETF Screener';
                    else temp = arr[i - 1].replace(regexPattern, ' ') + ' ' + temp;
                }
                if (detailPaths.includes(arr[i - 1])) {
                    to += loc.replace(to, '');
                    links.push({ to: to, label: temp, key: ele });
                    break;
                }
                to += '/' + ele;
                if (modifiedDashboardPaths.includes(ele) && i === 1) {
                    links.push({ to: to + '/dashboard', label: temp, key: ele })
                }
                else if (textsToModify?.[ele]) {
                    let prevPath = arr[i + textsToModify?.[ele]?.index]
                    let label = textsToModify?.[ele]?.values?.[prevPath?.toLowerCase()]
                    links.push({ to, label: label, key: ele })
                }
                else {
                    links.push({ to, label: temp, key: ele })
                }
            }
        }
        return links;
    }

    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
    const location = useLocation();
    let navItems = formatNavItems(location);
    let checkPath = disabledPaths.filter((ele) => location?.pathname?.startsWith(ele) && location.pathname.endsWith(ele));
    let checkStartWithPath = disabledStartsWithPaths.filter((ele) => location?.pathname?.startsWith(ele));
    if (checkPath.length || checkStartWithPath.length || window.innerWidth <= 760) {
        return (
            <React.Fragment></React.Fragment>
        )
    }
    return (
        <div style={{ background: "var(--white)", borderBottom: "1px solid var(--grey2)", }}>
            <div style={{
                paddingTop: "1.25rem", paddingBottom: "1.25rem",
                display: "flex", alignItems: "center", justifyContent: "space-between", maxWidth: isDev ? "1600px" : "", margin: isDev ? "auto" : '',
            }} className='headerContainer'>
                <div className='d-flex align-items-center flex-wrap textSM'
                    style={{ gap: "0.4rem", fontWeight: "500" }}>
                    {navItems?.map((item, i) => {
                        if (i + 1 === navItems.length) {
                            return (
                                <React.Fragment key={item?.key}>
                                    {i !== 0 && <IoIosArrowForward />}
                                    <div className={navItems.length === i + 1 ? "breadcrumbCurr" : "breadcrumbPrev"} style={{ cursor: "pointer" }}>
                                        <div>
                                            {item?.label}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        }
                        return (
                            <React.Fragment key={item?.key}>
                                <Link to={i + 1 === navItems.length ? (location.pathname + location.search) : item?.to} className={navItems.length === i + 1 ? "breadcrumbCurr" : "breadcrumbPrev"}>
                                    <div>
                                        {item?.label}
                                    </div>
                                </Link>
                                {navItems?.length - 2 !== i && <IoIosArrowForward />}
                            </React.Fragment>
                        )
                    })}
                </div>
                <ClosableHeader />
            </div>
        </div>
    )
}

export default BreadCrumbs;