import Head from "Layout/Head";
import React from "react";

export default function PrivacyPolicy() {

    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';

    return (
        <React.Fragment>
            <Head title='sharpely: Privacy Policy | Your Data Security Matters' description="Learn how sharpely prioritizes your privacy and safeguards your information through our detailed privacy policy." />
            <main className="marketing">
                <div style={{ background: "var(--blueShade)" }}>
                    <div className={`padAll marHomeResMainCard ${isDev ? "maxWidthContainer" : ""}`}>
                        <h1 className="headXLtoSM black w-700 margin0">
                            Privacy policy
                        </h1>
                    </div>
                </div>
                <div className={`padAll ${isDev ? "maxWidthContainer" : ""}`}>
                    <div className="textMDtoSM black w-500 dark3" style={{ marginBottom: "1.5rem", opacity: 0.8 }}>
                        We take privacy and security very seriously. We have laid down checks at all points where privacy could be compromised. Not only is your money safe by transacting with sharpely, but so are your sensitive details which are known only to the administration of sharpely.
                    </div>
                    <div className="textMDtoSM black w-500 dark3" style={{ marginBottom: "1.5rem", opacity: 0.8 }}>
                        This Privacy Policy lays down the norms that govern the use, disclosure and sharing of information about the registered users of sharpely.
                    </div>
                    {privacyData?.map((item, i) => (
                        <div key={item.section}>
                            <h2 className="headSMtoTextLG dark4 w-500 margin0">
                                {i + 1}. {item.section}
                            </h2>
                            <div style={{ marginTop: "1.25rem" }}>
                                {item.content?.map((ele, j) => (
                                    <div className="textMDtoSM black w-500 dark3" style={{ marginBottom: "1.5rem", opacity: 0.8 }} key={j}>
                                        {ele}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </main>
        </React.Fragment>
    )
}

const privacyData = [
    {
        section: "Security of your information",
        content: ["Registering for a service on our website/application requires the creation of a Login ID and Password by you to ensure the privacy and security of the information you provide. Your Login ID (same as your email address shared with us) and Password are the primary means to access our services and website/application, and hence as a security feature, our website/application requires creation of a password that meets certain requirements. We recommend and require that your keep your Login ID and Password confidential. Further, we recommend that you change your password periodically. We shall not be responsible for any unauthorized access to your account if you share your Login ID and Password with anyone or as a consequence of a violation of this Privacy Policy or the website/application’s Terms of Use.", "Every time you login to our website/application/application, we use, the latest authentication and encryption protocols (SSL) along with session time outs and firewalls to protect your account from unauthorised access. Your password information is not accessible to anyone including us and can only be changed/updated by you using a two-factor authentication process."]
    },
    {
        section: "Non personal information",
        content: ["When you visit our website/application, we collect and store information to enable us to provide you an experience that matches your device. The information collected includes the type and address of the device you use, the browser and its version, the operating system and its version you are using and the website/application from which you accessed the sharpely website/application.",
            "When you visit our website/application or receive an e-mail from us, we use cookies and/or pixel tags to collect information and store your online preferences. Cookies are widely used and most browsers are set up to accept them automatically. You can choose to not accept cookies.",
            "The information captured in such a manner is used only to improve your experience and store your preference. Neither is it possible nor do we attempt to identify you using these details. We may also supplement the information we collect with information we receive from other companies. For example, we may use marketing segments developed by us or other companies to customize certain services to your local area and provide relevant sharpely offers tailored for you."]
    },
    {
        section: "Personal information",
        content: ["We may receive and collect your personal information such as your name, phone number and e-mail address among others. We use these details and other personal information to provide a customized experience and/or to create your mutual fund transaction account with sharpely.", "This information will be collected via the website/application interface or encrypted modes like email and WhatsApp.",
            "The information is not shared with anyone else unless it is for compliance purposes with authorities that demand/request these details be produced for your continued association with sharpely or to conduct any transactions via sharpely.",
            "We may use aggregate personal information that does not identify you individually for better product design, research and developing customized marketing offers either by us or any third-party consultants we may hire. We will exercise reasonable caution to see to it that these third-party consultants treat your details with the same care that we do.",
            "Further, we may collect personal information when you are using our calculators and tools or registering with us for setting up of an Investment Account. This may include your PAN Card number and image, Aadhaar card number and image, Bank Account Details and relevant proofs of the same among others. This information is primarily collected for regulatory compliance with applicable laws in order to enable and/or activate your account with AMC’s and to enable you to successfully conduct online transactions.",
            "All information will be stored with us and shared with select third parties such as Asset Management Companies (AMC’s), Registrar and Transfer Agents (RTA’s), BSE and affiliate companies, payment gateway providers in order to enable us to complete your registration as a client with us and to process any transaction requests that you authorise, maintain a record of your transactions and holdings, generate and send reminders, alerts, notifications to you for transactions, upcoming funds transfers etc.",
            "We encourage you to read the Terms and Conditions to understand how we go about executing our specialized mutual fund offerings.",
            "We may use the information we collect online in conjunction with the personal information you have provided us with, or information that is publicly available from other sources. We may use this information to customize your experience and our communication with you both online and offline.",
            `When we use other companies to provide services for us, we require them to protect the confidentiality of personal information about you that they receive from us. The information is disclosed on a need-to-know basis. We may use third party service providers to enable us in providing some services to you such as sending e-mail messages to you and tracking them on our behalf, collecting a fee for our services, and providing technical support that can contact you regarding an issue with any feature or functionality on our website/application.`,
            "When you provide us with your personal information either while setting up an account (by yourself or by someone at sharpely) with us or later, it is kept maintained by the division that is responsible for your account management. You can view and/or update/edit this information by e-mailing us or calling customer service.",
            "As we add more services or wish to inform you of some additional benefits that we offer either directly or in partnership with any third party, we may use the information we have collected to offer you customized recommendations as well as marketing communications through any medium including e-mail, SMS etc. You will have the option to opt out of such communication(s) at any time."]
    }
]