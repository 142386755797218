import { formatDateMMMDDYYYY } from "Components/DateFormatter";
import { NoDataFound } from 'Components/NoDataFound';
import { getKnowledgeBaseData } from "Components/getKnowledgeBaseData";
import { toggleUserGuideModal } from 'Libs/redux/KnowledgeBase/reduxSlice';
import { makeid } from 'Libs/utils';
import { VideoCard } from 'Pages/LandingPage/Sections/Components/VideoCard';
import AdminServices from "Services/api/AdminServices";
import { Divider, Drawer } from 'antd';
import React from 'react';
import { IoIosArrowRoundBack } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import ActivityLoaderMintbox from "./ActivityLoaderMintbox";
import { useIsMobile } from "CustomHooks/useIsMobile";
import { CardHeader } from "./atoms/SmallComponents";

export const UserGuideDrawer = () => {

    let { value: openUserGuide, params, type } = useSelector((state) => state?.userGuideModal);
    const [category, setCategory] = React.useState({});
    const [subCategory, setSubCategory] = React.useState({});
    const [blog, setBlog] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [blogContent, setBlogContent] = React.useState("");
    const [loadingBlog, setLoadingBlog] = React.useState(true);
    const dispatch = useDispatch();
    const isMobile = useIsMobile();

    const loadData = async () => {
        let res = await getKnowledgeBaseData(false, false);
        if (type === 'kbCategory') {
            let temp = res?.categories?.find((el) => el?.Category?.split(' ')?.join('-')?.toLowerCase() === params?.category);
            console.log(temp);
            setCategory(temp);
        }
        else if (type === 'kbSubCategory') {
            let temp = res?.subCategories?.find((el) => el?.SubCategory?.split(' ')?.join('-')?.toLowerCase() === params?.subCategory);
            setSubCategory(temp);
        }
        else if (type === 'kbArticle') {
            let temp = res?.blogs?.find((el) => el?.Title?.split(' ')?.join('-')?.toLowerCase() === params?.article) || {};
            setBlog(temp);
        }
        setLoading(false);
    }

    const loadContent = async (url) => {
        let res = await AdminServices.readBlogFile(url);
        if (res.type) {
            setBlogContent(res.data);
        }
        else setBlogContent('');
        setLoadingBlog(false);
    }

    React.useEffect(() => {
        if (blog?.UID) {
            setLoadingBlog(true);
            loadContent(blog?.URL?.toLowerCase()?.split('/')?.[2]);
        }
    }, [blog]);

    React.useEffect(() => {
        if (!loadingBlog && blog?.UID) {
            document.getElementById('resource-container').innerHTML = blogContent;
            setTimeout(() => {
                const anchorTags = document.getElementsByTagName("a");
                for (let i = 0; i < anchorTags.length; i++) {
                    const anchor = anchorTags[i];
                    const href = anchor.getAttribute("href");
                    if (href?.startsWith("/knowledge-base")) {
                        const modifiedHref = href.replace(/\.html/g, "").replace(/_/g, "-");
                        anchor.setAttribute("href", modifiedHref);
                    }
                }
            }, 100)
        };
    }, [loadingBlog, blogContent])

    const onClose = () => {
        dispatch(toggleUserGuideModal({}))
    }

    const DrawerTitle = () => {
        return (
            <div style={{ display: "flex", alignItems: "flex-start", gap: "1rem", justifyContent: "space-between" }}>
                <div className={isMobile ? "textMD w-700" : 'textXL w-700'}>
                    {type === 'kbArticle' ? params?.title : blog?.UID ? blog?.Title : "Useful resources"}
                </div>
                {type !== 'kbArticle' && blog?.UID && <IoIosArrowRoundBack style={{ cursor: "pointer", fontSize: "1.5rem", flexShrink: 0 }} onClick={() => {
                    setBlogContent("");
                    setBlog({});
                }} />}
            </div>
        )
    }

    const CategoryView = () => {

        const videoIds = category?.SubCategories?.reduce((a, c) => {
            let j = c?.VideoIds;
            if (j?.length > 0) {
                j = j?.split('|');
                a = [...a, ...j];
                a = [...(new Set(a))]
            }
            return a;
        }, [])

        return (
            <React.Fragment>
                <div style={{}} className='d-flex flex-column'>
                    {videoIds?.length > 0 && <div style={{ padding: isMobile ? "1.25rem" : '1rem 1.5rem', }}>
                        <CardHeader heading={'Suggested videos'} headingType={2} showHeaderBlueLine />
                        <div style={{ width: '100%', maxWidth: '100%', marginTop: "1rem" }}>
                            <div style={{ display: 'grid', gridTemplateColumns: `repeat(${videoIds?.length}, 20rem)`, gap: '1.25rem', overflowX: 'scroll', maxWidth: '100%', overflowY: "hidden" }} >
                                {
                                    videoIds.map((item, i) => {
                                        var vid = {
                                            videoId: item
                                        }
                                        return <VideoCard videoItem={vid} key={makeid(i + 1)} />
                                    })
                                }
                            </div>
                        </div>
                    </div>}
                    {videoIds?.length > 0 && <Divider style={{ margin: 0 }} />}
                    {category?.SubCategories?.map((subCategory, subIndex) => {
                        return (
                            <div style={{ padding: isMobile ? "1.25rem" : '1rem 1.5rem', borderBottom: "1px solid var(--grey2)" }}>
                                <CardHeader heading={subCategory?.SubCategory} headingType={2} showHeaderBlueLine />
                                <div style={{ gap: "1rem", marginTop: "1rem" }} className="d-flex flex-column">
                                    {subCategory?.Resources?.length > 0 ? subCategory?.Resources?.map((item, i) => (
                                        <div key={i + item?.Title}>
                                            <div className='d-flex flex-column' style={{ gap: "0.75rem" }} onClick={() => {
                                                setLoadingBlog(true);
                                                setBlog(item);
                                            }}>
                                                <div>
                                                    <h3 className="textMD w-700 black margin0" style={{ cursor: "pointer" }} onClick={() => {
                                                        setLoadingBlog(true);
                                                        setBlog(item);
                                                    }}>{i + 1}. {item?.Title}</h3>
                                                    <div style={{ color: "var(--dark3)", userSelect: "none" }} className='w-500 textXS mt-1'>
                                                        by <span style={{ color: "var(--black)" }}>{item.Author}</span> {formatDateMMMDDYYYY(item?.UpdatedOn)}
                                                    </div>
                                                </div>
                                                {item?.Description && <div className='textSM black'>{item.Description}</div>}
                                            </div>
                                            {i !== subCategory?.Resources?.length - 1 && <Divider style={{ margin: '1rem 0 0', background: "var(--grey3)" }} />}
                                        </div>)) : <div style={{ padding: "1rem" }}>
                                        <NoDataFound message="No articles found" />
                                    </div>}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </React.Fragment>
        )
    }

    const SubCategoryView = () => {

        return (
            <div style={{}} className='d-flex flex-column'>
                {subCategory?.VideoIds?.length > 0 && <div style={{ padding: isMobile ? "1.25rem" : '1rem 1.5rem', }}>
                    <div style={{ width: '100%', maxWidth: '100%' }}>
                        <div style={{ display: 'grid', gridTemplateColumns: `repeat(${subCategory?.VideoIds?.split("|")?.length}, 20rem)`, gap: '1.25rem', overflowX: 'scroll', maxWidth: '100%', overflowY: "hidden" }} >
                            {
                                subCategory?.VideoIds?.split("|")?.map((item, i) => {
                                    var vid = {
                                        videoId: item
                                    }
                                    return <VideoCard videoItem={vid} key={makeid(i + 1)} />
                                })
                            }
                        </div>
                    </div>
                </div>}
                {subCategory?.VideoIds?.length > 0 && <Divider />}
                {subCategory?.Resources?.length > 0 ? subCategory?.Resources?.map((item, i) => (
                    <div key={i + item?.Title}>
                        <div className='d-flex flex-column' style={{ padding: isMobile ? "1.25rem" : '1rem 1.5rem', gap: "0.75rem" }} onClick={() => {
                            setLoadingBlog(true);
                            setBlog(item);
                        }}>
                            <div>
                                <h3 className="textMD w-700 black margin0" style={{ cursor: "pointer" }} onClick={() => {
                                    setLoadingBlog(true);
                                    setBlog(item);
                                }}>{i + 1}. {item?.Title}</h3>
                                <div style={{ color: "var(--dark3)", userSelect: "none" }} className='w-500 textXS mt-1'>
                                    by <span style={{ color: "var(--black)" }}>{item.Author}</span> {formatDateMMMDDYYYY(item?.UpdatedOn)}
                                </div>
                            </div>
                            {item?.Description && <div className='textSM black'>{item.Description}</div>}
                        </div>
                        {i !== subCategory?.Resources?.length - 1 && <Divider style={{ margin: 0, background: "var(--grey3)" }} />}
                    </div>)) : <div style={{ padding: "1rem" }}>
                    <NoDataFound message="No articles found" />
                </div>}
            </div>
        )
    }

    const ArticleView = () => {

        return (
            <div>
                <div id='resource-container' className="ql-editor textMD" style={{ padding: '1.25rem', height: "auto" }}>
                    <div style={{ marginTop: "23%" }}>
                        <ActivityLoaderMintbox showQuote />
                    </div>
                </div>
            </div>
        )
    }

    React.useEffect(() => {
        if (openUserGuide) {
            loadData();
        }
        else {
            setLoading(true);
            setBlog({});
            setBlogContent('');
            setSubCategory([])
        }
    }, [openUserGuide]);

    if (!openUserGuide) return null;
    return (
        <React.Fragment>
            <Drawer open={openUserGuide} width={!isMobile ? '60rem' : '100vw'} onClose={onClose} title={<DrawerTitle />}
                footer={<button className="btnBlack ms-auto " onClick={onClose}>Close</button>}
                styles={{
                    footer: { textAlign: "right" },
                    body: { padding: "0rem" },
                    header: { padding: isMobile ? "1.25rem" : "" }
                }} closable={false}>
                <div style={{ height: "100%", flexGrow: 1, overflowY: "auto" }}>
                    {loading ? <div style={{ marginTop: "30%" }}>
                        <ActivityLoaderMintbox />
                    </div> : blog?.UID ? <ArticleView /> : subCategory?.UID ? <SubCategoryView /> : category?.UID ? <CategoryView /> : null}
                </div>
            </Drawer>
        </React.Fragment>
    )
}