import { getBlogsData, getKnowledgeBaseData } from 'Components/getKnowledgeBaseData';
import { fetchFundData, fetchFundDataWithoutFilter } from 'Libs/fetchFundData';
import React from 'react';
import apiHelperPY from 'Services/api/ApiHelperPY';
import { formatDateyyyymmdd } from 'Components/DateFormatter';
import { getIndexList } from 'Libs/utils';

export default function GenerateSitemap() {

    const staticRoutes = ['/', '/about-us', '/pricing', '/contact-us', '/privacy-policy', '/terms-and-conditions', '/refund-and-cancellation-policy', '/login', '/signup',
        '/user-onboarding', '/go-to-desktop-version', '/broker-page', '/etfs/dashboard', '/mutual-funds/dashboard', '/stocks/dashboard', '/technical-chart',
        '/stocks/screener', '/etfs/magicboard', '/mutual-funds/magicboard', '/etfs/screener', '/mutual-funds/screener', '/etfs/alpha-analysis', '/mutual-funds/alpha-analysis',
        '/stocks/styleBox', '/stocks/sectors', '/stocks/earnings-dashboard', '/stocks/sector-details', '/stocks/factor-model', '/indices', '/stocks/build-custom-strategy',
        '/etfs/build-custom-strategy', '/mutual-funds/build-custom-strategy', '/curated-baskets', '/watchlist', '/market-watch', '/investments',
        '/event-calendar', '/watchlist-feed', '/user-profile', '/orders', '/stocks/compare', '/etfs/compare', '/mutual-funds/compare', '/knowledge-base',
        '/etfs/calculators/sip-calculator', '/etfs/calculators/rr-calculator', '/etfs/calculators/swp-calculator', '/mutual-funds/calculators/sip-calculator',
        '/mutual-funds/calculators/rr-calculator', '/mutual-funds/calculators/swp-calculator']

    const getDate = () => {
        const currentDate = new Date();
        let t = formatDateyyyymmdd(currentDate) + 'T13:21:20+09:00';
        return t;
    }

    const lastMod = getDate();

    const getEncodedURL = (url) => {
        let t = url?.replace(/&/g, '&amp;');
        return t;
    }

    const generateDynamicRoutes = async () => {
        try {
            let str = ``
            let stocks = await fetchFundDataWithoutFilter(4);
            for (let i = 0; i < stocks.length; i++) {
                let item = stocks[i];
                str += `<url>\n
                <loc>${getEncodedURL('https://sharpely.in/stocks/stock-detail/' + item?.proper_name?.replaceAll(' ', '-') + '/' + item?.symbol)}</loc>\n
                <lastmod>${lastMod}</lastmod>\n
                <changefreq>Weekly</changefreq>\n
                <priority>0.1</priority>\n
            </url>`
            }
            let etfs = await fetchFundData(1);
            for (let i = 0; i < etfs.length; i++) {
                let item = etfs[i];
                str += `<url>\n
                <loc>${getEncodedURL('https://sharpely.in/etfs/fund-detail/' + item?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + item.plan_id)}</loc>\n
                <lastmod>${lastMod}</lastmod>\n
                <changefreq>Weekly</changefreq>\n
                <priority>0.1</priority>\n
            </url>`
            }
            let mfs = await fetchFundData(2);
            for (let i = 0; i < mfs.length; i++) {
                let item = mfs[i];
                str += `<url>\n
                <loc>${getEncodedURL('https://sharpely.in/mutual-funds/fund-detail/' + item?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + item.plan_id)}</loc>\n
                <lastmod>${lastMod}</lastmod>\n
                <changefreq>Weekly</changefreq>\n
                <priority>0.1</priority>\n
            </url>`
            }
            let indices = await getIndexList();
            if (indices) {
                for (let i = 0; i < indices.length; i++) {
                    let item = indices[i];
                    str += `<url>\n
                    <loc>${getEncodedURL('https://sharpely.in/indices/index-detail/' + item?.IndexName?.replaceAll(' ', '-') + '/' + item?.Id)}</loc>\n
                    <lastmod>${lastMod}</lastmod>\n
                    <changefreq>Weekly</changefreq>\n
                    <priority>0.1</priority>\n
                </url>`
                }
            }
            let kb = await getKnowledgeBaseData();
            if (kb.type) {
                let cats = kb.categories;
                for (let i = 0; i < cats.length; i++) {
                    let item = cats[i];
                    str += `<url>\n
                    <loc>${getEncodedURL('https://sharpely.in' + item?.Slug)}</loc>\n
                    <lastmod>${lastMod}</lastmod>\n
                    <changefreq>Weekly</changefreq>\n
                    <priority>0.4</priority>\n
                </url>`
                }
                let subs = kb.subCategories;
                for (let i = 0; i < subs.length; i++) {
                    let item = subs[i];
                    str += `<url>\n
                    <loc>${getEncodedURL('https://sharpely.in' + item?.Slug)}</loc>\n
                    <lastmod>${lastMod}</lastmod>\n
                    <changefreq>Weekly</changefreq>\n
                    <priority>0.4</priority>\n
                </url>`
                }
                let blogs = kb.blogs;
                for (let i = 0; i < blogs.length; i++) {
                    let item = blogs[i];
                    str += `<url>\n
                    <loc>${getEncodedURL('https://sharpely.in' + item?.Slug)}</loc>\n
                    <lastmod>${lastMod}</lastmod>\n
                    <changefreq>Weekly</changefreq>\n
                    <priority>0.4</priority>\n
                </url>`
                }
            }
            let bls = await getBlogsData();
            if (bls.type) {
                let cats = bls.categories;
                for (let i = 0; i < cats.length; i++) {
                    let item = cats[i];
                    str += `<url>\n
                    <loc>${getEncodedURL('https://sharpely.in' + item?.Slug)}</loc>\n
                    <lastmod>${lastMod}</lastmod>\n
                    <changefreq>Weekly</changefreq>\n
                    <priority>0.4</priority>\n
                </url>`
                }
                let blogs = bls.blogs;
                for (let i = 0; i < blogs.length; i++) {
                    let item = blogs[i];
                    str += `<url>\n
                    <loc>${getEncodedURL('https://sharpely.in' + item?.Slug)}</loc>\n
                    <lastmod>${lastMod}</lastmod>\n
                    <changefreq>Weekly</changefreq>\n
                    <priority>0.4</priority>\n
                </url>`
                }
            }
            return str;
        }
        catch (err) {
            console.log(err)
            return '';
        }

    }

    const generateSitemaps = async () => {
        const sitemapHeader = '<?xml version="1.0" encoding="UTF-8" ?>\n<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n';
        const sitemapFooter = '</urlset>';

        const staticUrls = staticRoutes?.map((item) => {
            return `<url>\n  
            <loc>${getEncodedURL('https://sharpely.in' + item)}</loc>\n
            <lastmod>${lastMod}</lastmod>\n
            <changefreq>Weekly</changefreq>\n
            <priority>0.1</priority>\n
        </url>`
        })

        const dynamicUrls = await generateDynamicRoutes();

        const sitemapContent = sitemapHeader + staticUrls.join('\n') + dynamicUrls + '\n' + sitemapFooter;
        const blob = new Blob([sitemapContent], { type: 'application/xml' });

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = 'sitemap.xml'; // Specify the desired file name

        // Append the link to the document
        document.body.appendChild(downloadLink);

        // Trigger a click on the link to start the download
        downloadLink.click();

        // Remove the link from the document
        document.body.removeChild(downloadLink);
    }

    return (
        <React.Fragment>
            <div>
                <button className='btnBlack ' onClick={generateSitemaps}>Generate sitemap</button>
            </div>
        </React.Fragment>
    )
}