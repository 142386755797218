import { isMarketOpen } from "Components/atoms/IsMarketOpen";
import { updateStockPrices } from "Libs/redux/StockLivePrice/reduxSlice";
import { updatePrevStockPrices } from "Libs/redux/StocksPrevPrices/reduxSlice";
import { getStockPrevPrices } from "Libs/utils";
import React, { createContext, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

const LiveDataContext = createContext();

export const LiveDataContextProvider = ({ children }) => {
    const dispatch = useDispatch();
    const socketRef = useRef();
    var stockprices = useSelector((state) => state?.stockprices?.value)
    let isLocal = process.env.NODE_ENV === 'development'; // change later before pushing
    let isProd = process.env.REACT_APP_ENVIRONMENT === 'prod';

    const [isOnline, setIsOnline] = React.useState(navigator.onLine);
    React.useEffect(() => {
        function onlineHandler() {
            setIsOnline(true);
        }

        function offlineHandler() {
            //console.log(socketRef)
            if (socketRef.current) {
                //console.log("hua close")
                socketRef.current.close();
            }
            setIsOnline(false);
        }

        window.addEventListener("online", onlineHandler);
        window.addEventListener("offline", offlineHandler);


        return () => {
            window.removeEventListener("online", onlineHandler);
            window.removeEventListener("offline", offlineHandler);
        };
    }, []);


    const insertData = (data) => {
        // console.log(new Date())
        var allBSE = data?.filter((i) => i?.Exchange === "BSE").reduce(
            (obj, item) => Object.assign(obj, { [item.InstrumentIdentifier]: item }), {});
        var allNSE = data?.filter((i) => i?.Exchange === "NSE").reduce(
            (obj, item) => Object.assign(obj, { [item.InstrumentIdentifier]: item }), {});
        var object = {
            ...allBSE,
            ...allNSE
        }
        // console.log(new Date())
        return object
    }

    const live_Data = async () => {
        //console.log(isMarketOpen())
        if (isMarketOpen() && !isLocal) {
            if (socketRef.current && (socketRef.current?.readyState === 1)) {
                //console.log('socket is open');
                return;
            }
            socketRef.current = new WebSocket("wss://api.mintbox.ai/wss/gdf_live_prices");
            //console.log('New socket')
            socketRef.current.onopen = async function (e) {
                var res = await getStockPrevPrices({ isFresh: true }).then((r) => r)
                dispatch(updatePrevStockPrices(insertData(res)))
            };

            socketRef.current.onmessage = async function (event) {
                try {
                    dispatch(updateStockPrices(insertData(JSON.parse(event.data))))
                } catch (error) {
                    var res = await getStockPrevPrices({ isFresh: true }).then((r) => r)
                    dispatch(updatePrevStockPrices(insertData(res)))
                    socketRef.current.close();
                }
            };

            socketRef.current.onclose = async function (event) {
                // var res = await getStockPrevPrices({ isFresh: true }).then((r) => r)
                // dispatch(updatePrevStockPrices(insertData(res)))
                dispatch(updateStockPrices("close"))
                //console.log('1')
            };

            socketRef.current.onerror = async function (error) {
                var res = await getStockPrevPrices({ isFresh: true }).then((r) => r)
                dispatch(updatePrevStockPrices(insertData(res)))
                dispatch(updateStockPrices("close"))
            };
        } else {
            dispatch(updateStockPrices("close"))
            //console.log("market is closed or local environment")
            var res = await getStockPrevPrices({ isFresh: true }).then((r) => r)
            dispatch(updatePrevStockPrices(insertData(res)))
        }
    }


    const handleLiveData = (e) => {
        if (document.hidden === false) {
            live_Data();
            // webSocketManager.connect();
            // webSocketManager2.connect();
        }
    }

    React.useEffect(() => {
        // //console.log(stockprices)
        document.addEventListener('visibilitychange', handleLiveData)
        return () => {
            document.removeEventListener('visibilitychange', handleLiveData)
        }
    }, [])

    React.useEffect(() => {
        if (!isMarketOpen()) {
            // //console.log("DSFD",stockprices);
            document.addEventListener('visibilitychange', handleLiveData)
            return () => {
                document.removeEventListener('visibilitychange', handleLiveData)
            }
        }
    }, [stockprices])

    React.useEffect(() => {
        //console.log("main data", isOnline)
        if (isOnline) {
            live_Data()
            // webSocketManager.connect();
            // webSocketManager2.connect();
        }

    }, [isOnline])

    // React.useEffect(() => {
    //     return () => {
    //         //console.log(socketRef)
    //         if (socketRef.current) {
    //             //console.log("hua close main")
    //             socketRef.current.close();
    //         }
    //     }
    // }, [])

    return (
        <LiveDataContext.Provider value={1}>
            {children}
        </LiveDataContext.Provider>
    )
}

export const useLivePrices = () => useContext(LiveDataContext);