import { DecimalValueFormat } from 'Components/DecimalValueFormat';
import { Segmented } from 'antd';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import { isMobile } from 'react-device-detect';
import { FaPercentage, FaRupeeSign } from 'react-icons/fa';

const LabelInputNew = (props) => {
    var {
        value,
        onChangeVal = () => { },
        onValTypeChange = () => { },
        label = '',
        description = '',
        prefix = ' ',
        suffix = '',
        showPerAmtToggle = false,
        placeholder = '',
        allowDecimals = false,
        inputClassName,
        showCurrencyAddButton = false,
        showSlider = false,
        sliderMin = 0,
        sliderMax = 0,
        val_type,
        disabled = false,
        isCurrency = true,
        msg = "value allowed is",
        msg2,
        customMsg
    } = props

    const [val, setVal] = React.useState((value === null || value === undefined) ? null : isNaN(Number(value)) ? 0 : Number(value?.toFixed(2)))
    const [valType, setvalType] = React.useState(val_type)
    // console.log("SADASDSDA", value, val)
    React.useEffect(() => {
        if (value !== val) {
            setVal((value === null || value === undefined) ? null : isNaN(Number(value)) ? 0 : Number(value?.toFixed(2)))
        }
    }, [value])
    return <>
        {label && <div className='textXS dark3' >
            {label}
        </div>}
        <div style={{ display: "flex", flexDirection: "row", gap: "2rem", alignItems: "center" }}>
            <CurrencyInput
                {...Object.fromEntries(Object?.entries(props)?.filter(([k, v]) => !["onValTypeChange", "onChangeVal", 'customMsg']?.includes(k)))}
                className={'inputBoxMain ' + inputClassName}
                id={label}
                name={label}
                placeholder={placeholder}
                // defaultValue={val === 0 ? 0 : (val || '')}
                value={val === 0 ? 0 : (val || '')}
                allowDecimals={allowDecimals}
                allowNegativeValue={false}
                disableAbbreviations={false}
                decimalsLimit={2}
                prefix={prefix}
                suffix={suffix}
                onValueChange={(value) => {
                    setVal(value || null)
                }}
                onBlur={() => {
                    console.log(val)
                    if (val) {
                        if ((props?.min && props?.min >= (val || 0))) {
                            // alert("234 => " + props?.min + "  min " + val)
                            onChangeVal(Number(props?.min || props?.max), valType)
                        } else if ((props?.max && props?.max <= (val || 0))) {
                            onChangeVal(Number(props?.max || props?.min), valType)
                        } else {
                            onChangeVal(Number(val) || 0, valType)
                        }
                    } else {
                        onChangeVal(null, valType)
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        if (val) {
                            if ((props?.min && props?.min >= (val || 0))) {
                                // alert("234 => " + props?.min + "  min " + val)
                                onChangeVal(Number(props?.min || props?.max), valType)
                            } else if ((props?.max && props?.max <= (val || 0))) {
                                onChangeVal(Number(props?.max || props?.min), valType)
                            } else {
                                onChangeVal(Number(val) || 0, valType)
                            }
                        } else {
                            onChangeVal(null, valType)
                        }
                        e.target.blur();
                        // onValueChange(Number(metricVal))
                    }
                }}
                intlConfig={isCurrency ? { locale: 'en-IN', currency: 'INR' } : {}}
                style={{ textAlign: "left", paddingLeft: "0.5rem", ...props?.style }}
                disabled={disabled}
            />
            {showPerAmtToggle && <Segmented
                value={valType}
                onChange={(e) => {
                    setvalType(e)
                    onValTypeChange(e)
                }}
                options={[
                    { value: 'rupee', icon: <FaRupeeSign /> },
                    { value: 'percent', icon: <FaPercentage /> },
                ]}
            />}
        </div>
        {(props?.min && props?.min > val) ? <div className='textXS' style={{ color: 'var(--red)' }}> {customMsg || `Min ${msg2 || msg} ${prefix}${DecimalValueFormat({ num: props?.min, decimals: 0 })}${suffix}`}</div> : <></>}
        {(props?.max && props?.max < val) ? <div className='textXS' style={{ color: 'var(--red)' }}>{customMsg || `Max ${msg} ${prefix}${DecimalValueFormat({ num: props?.max, decimals: 0 })}${suffix}`}</div> : <></>}
    </>
}
export default LabelInputNew