import { toggleloginModal } from 'Libs/redux/UserAuth/reduxSlice';
import { MdOutlineLock } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const UpgradeToPro = ({ height = '20rem', width = '100%', accessObj, flexDirection = 'row', textAlign = 'left', margin = 'auto', contentClassName = '', moduleKey = '', customDesc = '',
    descClassName = 'textSM dark3 mb-3', zIndex = '10', customHeading = '' }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.updateUserData?.value)
    const userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel || 0
    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    let modAccessObj = accessObj;
    if (moduleKey) {
        modAccessObj = accessLevelData.find((ele) => ele.ModuleKey === moduleKey);
    }
    let defaultMessage = customHeading ? customHeading : `Subscribe to sharpely ${modAccessObj?.AccessLevel === 2 ? "Lite" : modAccessObj?.AccessLevel === 3 ? "Pro" : "Black"}`
    const isMobile = window.innerWidth <= 760;
    return (
        <div style={{
            width: width, height: height, margin: margin, textAlign: textAlign, zIndex: zIndex, overflow: 'hidden', border: isMobile ? '1px solid var(--grey3)' : '', borderRadius: '5px', padding: isMobile ? '32px 16px' : '',
        }}>
            <div style={{ display: 'flex', gap: '1rem', height: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: flexDirection }}>
                {(modAccessObj?.AccessLevel === 1 || userAccesslevel === 0) ?
                    <div className={contentClassName}>
                        <div className='d-flex align-items-center mb-2' style={{ gap: "0.5rem" }}>
                            <MdOutlineLock color='var(--black)' size='1.5rem' cursor={'pointer'} />
                            <div className='textMD w-500 black'>
                                {"Please login to continue"}
                            </div>
                        </div>
                        {(customDesc || modAccessObj?.ModuleDesc) && <div className={descClassName} style={{ maxWidth: "40ch" }}>
                            {customDesc || modAccessObj?.ModuleDesc}
                        </div>}
                        <button className="btnBlack " style={{ marginTop: '16px' }} onClick={() => dispatch(toggleloginModal(true))}>Login</button>
                    </div> :
                    <div className={contentClassName}>
                        <div>
                            <div className='d-flex align-items-center mb-2' style={{ gap: "0.5rem" }}>
                                <MdOutlineLock color='var(--black)' size='1rem' cursor={'pointer'} />
                                <div className='textMD w-500 black ' style={{}}>
                                    {defaultMessage}
                                </div>
                            </div>
                            {(customDesc || modAccessObj?.ModuleDesc) && <div className={`mb-2 ${descClassName}`} style={{ maxWidth: "40ch" }}>
                                {customDesc || modAccessObj?.ModuleDesc}
                            </div>}
                        </div>
                        <Link to='/pricing'>
                            <button className="btnBlack  d-flex align-items-center justify-content-center" style={{ gap: "0.5rem" }}>
                                Upgrade <MdOutlineLock color='var(--white)' size='1rem' cursor={'pointer'} />
                            </button>
                        </Link>
                    </div>
                }
            </div>
        </div>
    )
}

export default UpgradeToPro;