import { Card, Input, Tooltip } from "antd";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import { CardHeader, generateUniqueKey } from "Components/atoms/SmallComponents";
import CustomSegmentedTab from "Components/CustomSegmentedTab";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import { useSubscribeMiddleware } from "Components/SubscribeModal";
import MyTable from "Components/TableWithScroll";
import { useIsMobile } from "CustomHooks/useIsMobile";
import Head from "Layout/Head";
import { exportAntDTable } from 'Libs/exportTable';
import { textSearchAndSort } from "Libs/search";
import { getIndexList } from "Libs/utils";
import React from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { IoSearch } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import apiHelperPY from "Services/api/ApiHelperPY";
import { PriceDisplay, StockLivePrice } from "Services/api/LivePricesWebSocket";

function IndexList({ isWidget = false, widgetItem = {}, allIndices }) {

    const [allIndicesData, setAllIndicesData] = React.useState([]);
    const [searchText, setSearchText] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [selectedTab, setSelectedTab] = React.useState('index');
    const isMobile = useIsMobile();
    const subscribeMiddle = useSubscribeMiddleware();

    const loadData = async () => {
        setLoading(true)
        let allData = await apiHelperPY({ apiName: "getAllIndexData" });
        if (allData) {
            allData = JSON.parse(allData?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null));
            //console.log('abc1', allData)
            let indices = isWidget ? allIndices : await getIndexList();
            allData.index = allData.index?.map((item) => {
                let ele = indices?.find((el) => Number(el?.Id) === Number(item?.index));
                return {
                    ...item,
                    symbol: ele?.symbol,
                    gdf_symbol: ele?.symbol,
                    proper_name: ele?.proper_name
                }
            })
            allData.technical = allData.technical?.map((item) => {
                let ele = indices?.find((el) => Number(el?.Id) === Number(item?.index));
                return {
                    ...item,
                    symbol: ele?.symbol,
                    gdf_symbol: ele?.symbol,
                    proper_name: ele?.proper_name
                }
            })
            //console.log('abc', allData)
            setAllIndicesData(allData || [])
        }
        setLoading(false);
    }

    React.useEffect(() => {
        setLoading(true);
        loadData();
    }, []);

    const getColumns = React.useCallback(() => {
        let removedKeys = ['index', 'symbol', 'proper_name', 'gdf_symbol', 'close', 'pct_change']
        let entries = Object.entries(allIndicesData?.[selectedTab]?.[0] || {}) || [];
        let cols = entries?.filter(([key]) => !(removedKeys.includes(key)))?.map(([key]) => {
            return {
                key: key,
                dataIndex: key,
                fixed: key === 'IndexName' ? 'left' : "false",
                align: key === 'IndexName' ? "left" : 'right',
                title: key === 'IndexName' ? "Name" : key,
                width: key === "IndexName" ? (isMobile ? 150 : 250) : 150,
                render: (text, record) => {

                    let changeVal = record?.close - ((record?.close / (100 + record?.pct_change)) * 100)

                    if (key === 'IndexName') {
                        return <Link to={`/indices/index-detail/${text?.split(' ')?.join('-')}/${record?.index}`}>
                            <div>
                                {text}
                            </div>
                            <div>
                                {String(record?.IndexName)?.startsWith('SHI:') ? <PriceDisplay format={10} priceObj={{ currentLTP: record?.close, changePer: record?.pct_change, changeVal }} /> : <StockLivePrice fundData={record} format={10} symbol={record?.symbol} />}
                            </div>
                        </Link>
                    }
                    else return (
                        selectedTab === 'technical' ? <span>{DecimalValueFormat({ num: text, suffix: "%", placeholder: "-", decimals: 2 })}</span> :
                            typeof text === 'object' || typeof text === 'number' ? DecimalValueFormat({ num: text, suffix: "", placeholder: "-", decimals: 2 }) : text
                    )
                }
            }
        }) || [];
        return cols;
    }, [allIndicesData, selectedTab]);

    const columns = getColumns();

    const exportTable = (fileFormat = 'xlsx') => {

        subscribeMiddle({
            reqAccessLevel: 1,
            onNext: () => {
                exportAntDTable({
                    fileFormat: fileFormat, fileDefaultName: "Index analysis data", columns,
                    dataSource: textSearchAndSort(allIndicesData?.[selectedTab], searchText, 'IndexName')
                })
            }
        })
    }

    const tabData = [
        { key: 0, label: "Fundamentals", value: "index" },
        { key: 0, label: "Technicals", value: "technical" },
    ]

    if (loading && !isWidget) {
        return (
            <div className="loaderContainer">
                <ActivityLoaderMintbox showQuote />
            </div>
        )
    }
    else if (loading && isWidget) {
        return (
            <Card bordered={false} className='mobCardBorderRadius0'>
                <div className="d-flex justify-content-between align-items-start margin20Bottom" style={{ gap: "1.25rem", flexWrap: "wrap" }} >
                    <div>
                        <CardHeader heading={widgetItem?.Name} />
                        <div className="customTabsSubHeading" style={{ marginBottom: 0 }}>{widgetItem?.Description}</div>
                    </div>
                </div>
                <div className="d-flex allign-items-center justify-content-center">
                    <ActivityLoaderMintbox />
                </div>
            </Card>
        )
    }
    return (
        <React.Fragment>
            {!isWidget && <Head title='Stock Market Indices' description="Explore comprehensive stock and share market indices details on sharpely. Stay updated with today's latest market indices!" />}
            <Card bordered={false} className='mobCardBorderRadius0' style={{ flex: isWidget ? "" : 1 }}>
                <div style={{ gap: "1.25rem", marginBottom: "1.25rem" }} >
                    <CardHeader heading={isWidget ? widgetItem?.Name : 'Indices'} />
                    {isWidget && <div className="customTabsSubHeading" style={{ marginBottom: 0 }}>{widgetItem?.Description}</div>}
                </div>
                <div className="d-flex justify-content-between" style={{ alignItems: 'center', gap: "1.25rem", flexWrap: "wrap", flexDirection: isMobile ? "column" : "row" }}>
                    <CustomSegmentedTab options={tabData} value={selectedTab} onChange={(e) => setSelectedTab(e)} isBlock={false} mobileStyles={{ width: '100%' }} />
                    <div className="d-flex justify-content-between align-items-center" style={{ gap: "1.25rem", width: isMobile ? "100%" : "fit-content" }}>
                        <Input prefix={<IoSearch color='var(--dark2)' className="site-form-item-icon me-2" />} style={{
                            width: isMobile ? "100%" : "16rem", flex: 1, height: isMobile ? '3rem' : '100%',
                            background: "var(--grey3)", border: "1px solid var(--grey2)", fontSize: '0.85rem'
                        }} placeholder={`Search by index name`} allowClear onChange={(value) => {
                            setSearchText(value.target.value)
                        }} className='customInput2' />
                        <Tooltip title='Export index data' arrow={false}>
                            <button className={"btnWhite"} style={{ height: isMobile ? '3rem' : '100%', width: isMobile ? '3.5rem' : '' }} onClick={() => {
                                exportTable('csv')
                            }}>
                                <AiOutlineDownload size={15} />
                            </button>
                        </Tooltip>
                    </div>
                </div>
                <div style={{ marginTop: "1.25rem" }}>
                    <MyTable dataSource={textSearchAndSort(allIndicesData?.[selectedTab], searchText, 'IndexName')} columns={columns} scroll={{ x: "max-content" }}
                        paginationSize={isWidget ? 10 : window.innerWidth < 760 ? 15 : 50} defaultPagination={true}
                        rowKey={(record) => generateUniqueKey(record?.IndexName)} />
                </div>
            </Card>
        </React.Fragment>
    )
}

export default React.memo(IndexList);