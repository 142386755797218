import { screener1, screener10, screener11, screener12, screener2, screener3, screener4, screener5, screener6, screener7, screener8, screener9 } from 'Assets/images'

export const ScreenerIcon = ({ id = 1, width = '1rem', height = '1rem', padding = '0.25rem', type = 'screen', background = 'var(--blueShade)' }) => {

    let icons = [screener1, screener2, screener3, screener4, screener5, screener6, screener7, screener8, screener9, screener10, screener11, screener12]
    let updId = id < 1 ? 1 : id <= 12 ? id : ((id / 12) === Math.floor(id / 12)) ? 12 : id % 12

    let guruImg = {
        97: { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/final_imgs/gurus_final/shaughnessy.webp" },
        3: { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/final_imgs/gurus_final/benjamin.webp" },
        1: { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/final_imgs/gurus_final/shaughnessy.webp" },
        100: { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/final_imgs/gurus_final/john_neff.webp" },
        7: { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/final_imgs/gurus_final/piotroski.webp" },
        99: { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/final_imgs/gurus_final/warren_buffett.webp" },
        94: { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/final_imgs/gurus_final/peter_lynch.webp" },
        93: { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/final_imgs/gurus_final/dreman.webp" },
        98: { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/final_imgs/gurus_final/templeton.webp" },
        503: { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/final_imgs/gurus_final/richardmomentum.webp" },
    }

    if (type === "guru") {
        return <img loading="lazy"  alt={id} src={guruImg?.[id]?.uri} style={{
            width: width, height: height, borderRadius: "50%", objectFit: "contain", border: "1px solid var(--grey3)",
            padding: "0.25rem"
        }} />
    }
    return <div style={{ width: width, height: height, padding: padding, borderRadius: "50%", background: background }}
        className='d-flex align-items-center justify-content-center'>
        <img loading="lazy"  alt="" src={icons[updId - 1]} style={{ width: "100%", height: "100%" }} />
    </div>
}