function isMarketOpen() {
    var currentTime = new Date();

    var currentOffset = currentTime.getTimezoneOffset();

    var ISTOffset = 330;   // IST offset UTC +5:30 

    var ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000);

    // ISTTime now represents the time in IST coordinates

    var hoursIST = ISTTime.getHours()
    var minutesIST = ISTTime.getMinutes()
    var day = ISTTime.getDay();
    // console.log(`${hoursIST} : ${minutesIST}`)
    // if (hoursIST >= 9 && (day !== 6 && day !== 0)) {
    if (hoursIST >= 9 && (day !== 0)) {
        if (hoursIST < 15) {
            return true
        } else if (hoursIST === 15 && minutesIST <= 30) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }

    // return `${hoursIST} : ${minutesIST}`
}

export { isMarketOpen }