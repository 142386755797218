import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox.js";
import { CardHeader } from "Components/atoms/SmallComponents";
import { useIsMobile } from "CustomHooks/useIsMobile";
import { Collapse, Divider } from "antd";
import React from "react";
import { IoIosArrowDown } from 'react-icons/io';
import { Link } from "react-router-dom";

export const StickyScrollTabs = ({ tabData, showHardCodedData = false, scrollBehavior = 'smooth', isWidget = false, queriesToStickToBot = ['#sharpelyHeader'], loading, isAccordionInMob = false,
    isSingleChildInDesk = false, isChildOfCustomMainTab = true, tabChangeOffsetIdInDesk = 'customTabsBarId', defaultSelectedTab = '', hideTabs = false, contentBeforeTabs = null }) => {

    const isMobile = useIsMobile();
    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
    const [selectedTab, setSelectedTab] = React.useState(tabData?.find((el) => el?.value === defaultSelectedTab) ? defaultSelectedTab : tabData?.[0]?.value);

    function getDivHeight(query) {
        const myDiv = document.querySelector(query);
        if (myDiv) {
            const computedStyle = window.getComputedStyle(myDiv);
            const borderTopWidth = parseFloat(computedStyle.getPropertyValue("border-top-width"));
            const borderBottomWidth = parseFloat(computedStyle.getPropertyValue("border-bottom-width"));
            const totalHeight = myDiv.clientHeight + borderTopWidth + borderBottomWidth;
            return totalHeight;
        }
        return 0
    }

    function scrollToDivMinusBtm(queries = [], target, e) {
        if (e.target.classList.contains('activeStickyScrollTabChild')) {
            console.log('tab already active')
        }
        else {
            let height = 0;
            for (let i = 0; i < queries?.length; i++) {
                height += getDivHeight(queries[i]);
            }
            const targetElement = document.getElementById(target);
            if (target) {
                const scrollOptions = {
                    behavior: scrollBehavior,
                    block: 'start',
                    inline: 'nearest'
                };
                const targetScrollPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - height - 15;
                window.scrollTo({
                    top: targetScrollPosition,
                    ...scrollOptions
                });
            }
        }
    }

    const handleScroll = () => {
        let children = document.querySelectorAll('#stickyScrollTabsChildCont>div>div');
        let nearestElement = null;
        let nearestDistance = Number.MAX_VALUE;
        let nearestIndex
        if (children) {
            children.forEach((element, i) => {
                const { top, bottom } = element.getBoundingClientRect();
                // const distance = Math.abs(top);
                const distance = bottom
                element.classList.remove('activeStickyScrollTabChild')
                if (distance < nearestDistance && distance >= 150) {
                    nearestElement = element;
                    nearestDistance = distance;
                    nearestIndex = i;
                }
            });
        }
        if (nearestElement) {
            let tabs = document.querySelectorAll('#stickyScrollTabsBar>div');
            if (tabs && tabs?.length > 0 && tabs[nearestIndex]) {
                for (let i = 0; i < tabs.length; i++) {
                    tabs[i].classList.remove('activeStickyScrollTabChild');
                }
                tabs[nearestIndex].classList.add('activeStickyScrollTabChild');
            }
        }
    }

    function scrollToTargetMinusOffset(targetDivId, offsetDivId) {
        const targetElement = document.getElementById(targetDivId);
        const otherElement = document.getElementById(offsetDivId);
        if (targetElement && otherElement) {
            const scrollOptions = {
                behavior: 'instant',
                block: 'start',
                inline: 'nearest'
            };
            let rects = otherElement.getBoundingClientRect();
            if (window.pageYOffset === 0) return
            const targetScrollPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - rects.bottom;
            window.scrollTo({
                top: targetScrollPosition,
                ...scrollOptions
            });
        }
    }

    React.useEffect(() => {
        if (!isMobile && !isSingleChildInDesk && !showHardCodedData) {
            window.addEventListener('scroll', handleScroll);
        }
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    React.useEffect(() => {
        setTimeout(() => {
            let j = tabData?.find((el) => el?.value === defaultSelectedTab);
            if (j) {
                setSelectedTab(j?.value)
            }
            else {
                setSelectedTab(tabData?.[0]?.value)
            }
        }, 0)
    }, [defaultSelectedTab])

    return (
        <React.Fragment>
            {loading ? <div>
                <ActivityLoaderMintbox margin='4rem' showQuote />
            </div> : !isMobile ? <div style={{
                display: "flex", gap: isWidget ? "1.25rem" : "0rem", background: "var(--white)", postion: "relative", maxWidth: isDev ? "1600px" : "100%",
                width: "100%", margin: isDev ? "auto" : "", maxHeight: isWidget ? "80vh" : "", overflowY: isWidget ? "auto" : ""
            }} className=''>
                {!hideTabs && <div style={{
                    position: "sticky", top: isWidget ? 0 : queriesToStickToBot?.reduce((a, c) => a + getDivHeight(c), 0),
                    alignSelf: "flex-start",
                }}>
                    {contentBeforeTabs && <div className="leftRightPadContainer" style={{ paddingTop: "1.25rem" }}>
                        {contentBeforeTabs}
                    </div>}
                    <div style={{ width: isWidget ? "12rem" : "15rem", padding: "1.25rem 0" }} id='stickyScrollTabsBar'>
                        {tabData?.filter(el => el?.show !== false)?.map((tab, i) => {
                            return (
                                <div style={{
                                    background: "transparent", paddingTop: "0.675rem", paddingBottom: "0.675rem", display: "flex", alignItems: "center", gap: "0.75rem",
                                    color: "var(--black)", cursor: "pointer", borderRadius: "0 5rem 5rem 0", paddingLeft: isWidget ? '1rem' : "", paddingRight: isWidget ? '1rem' : ""
                                }} className={`textSM w-500 ${isSingleChildInDesk ? (selectedTab === tab?.value && !showHardCodedData ? "activeStickyScrollTabChild" : "") : i === 0 && !showHardCodedData ?
                                    "activeStickyScrollTabChild" : ''} ${isWidget ? '' : 'leftRightPadContainer'}`} key={tab.value}
                                    onClick={(e) => {
                                        if (isSingleChildInDesk) {
                                            setSelectedTab(tab?.value);
                                            scrollToTargetMinusOffset('stickyScrollTabsChildCont', tabChangeOffsetIdInDesk)
                                        }
                                        else {
                                            scrollToDivMinusBtm(queriesToStickToBot, tab.value, e);
                                        }
                                    }}>
                                    {tab?.icon && tab?.icon}
                                    {tab?.label || tab?.heading}
                                </div>
                            )
                        })}
                    </div>
                </div>}
                <div style={{
                    flex: 1, paddingTop: "1.25rem", maxWidth: isWidget ? "calc(100% - 12rem - 1.25rem)" : hideTabs ? "calc(100%)" :
                        "calc(100% - 15rem)"
                }} className={isWidget ? "" : 'leftRightPadContainer'} id='stickyScrollTabsChildCont'>
                    {isSingleChildInDesk ? <div id=''>
                        {tabData?.filter(el => el?.value === selectedTab)?.map((tab, i) => {
                            return (
                                <div key={tab.value} id={tab.value} style={{ width: "100%" }} >
                                    {!tab.noHeading &&
                                        <div className='d-flex justify-content-between' style={{ gap: "1.25rem" }}>
                                            <CardHeader heading={tab.heading || tab?.label} hideHeaderBlueLine headingType={2} textClassName={'textXL w-700'} />
                                            {tab?.rightLinkText && <Link to={tab?.rightLinkTo} target='_blank' className='textXS w-500' onClick={(e) => {
                                                if (tab?.rightTextLinkonClick) {
                                                    e.preventDefault();
                                                    tab?.rightTextLinkonClick();
                                                } else if (!tab?.rightLinkTo) {
                                                    e.preventDefault();
                                                }
                                            }}>{tab.rightLinkText}</Link>}
                                        </div>}
                                    {!tab.noHeading && !tab?.noSubHeading && <div style={{ margin: "0.25rem 0 1.25rem", maxWidth: "60%" }} className='textSM dark3'>{tab.subHeading}</div>}
                                    {tab?.children}
                                </div>
                            )
                        })}
                    </div> : <div style={{ display: "flex", flexDirection: "column", overflow: "hidden", }} id=''>
                        {showHardCodedData ? <div>
                            {showHardCodedData}
                        </div> : tabData?.filter(el => el?.show !== false)?.map((tab, i) => {
                            return (
                                <div key={tab.value} id={tab.value} style={{ width: "100%" }} >
                                    {!tab.noHeading &&
                                        <div className='d-flex justify-content-between' style={{ gap: "1.25rem" }}>
                                            <CardHeader heading={tab.heading || tab?.label} hideHeaderBlueLine headingType={2} textClassName={'textXL w-700'} />
                                            {tab?.rightLinkText && <Link to={tab?.rightLinkTo} target='_blank' className='textXS w-500' onClick={(e) => {
                                                if (tab?.rightTextLinkonClick) {
                                                    e.preventDefault();
                                                    tab?.rightTextLinkonClick();
                                                }
                                            }}>{tab.rightLinkText}</Link>}
                                        </div>}
                                    {!tab.noHeading && !tab?.noSubHeading && <div style={{ margin: "0.25rem 0 1.25rem", maxWidth: "60%" }} className='textSM dark3'>{tab.subHeading}</div>}
                                    {tab?.children}
                                    {i !== tabData?.filter(el => el?.show !== false)?.length - 1 && <Divider style={{ background: "var(--grey2)", margin: "2rem 0" }} />}
                                </div>
                            )
                        })}
                    </div>}
                </div>
            </div> : isAccordionInMob ? <div>
                <Collapse bordered={false} style={{ background: "var(--white)", borderColor: "var(--grey3)" }} expandIcon={({ isActive }) => (
                    <IoIosArrowDown style={{ fontSize: "1rem", transform: isActive ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.2s ease-in-out", }} />)}
                    expandIconPosition="end" items={tabData?.filter(el => el?.show !== false)?.map((tab, i) => {
                        return {
                            key: tab?.value,
                            id: tab?.value,
                            label: <div className='textMD w-500'>{tab?.label || tab?.heading}</div>,
                            style: { borderColor: "var(--grey3)", paddingTop: "1rem", paddingBottom: "1rem" },
                            children: <div>
                                <div style={{ margin: "0.25rem 0 1.25rem" }} className='textSM dark3'>
                                    {tab.subHeading}
                                    <div>
                                        {tab?.rightLinkText && <Link to={tab?.rightLinkTo} target='_blank' className='textXS w-500' onClick={(e) => {
                                            if (tab?.rightTextLinkonClick) {
                                                e.preventDefault();
                                                tab?.rightTextLinkonClick();
                                            }
                                        }}>{tab.rightLinkText} </Link>}
                                    </div>
                                </div>
                                {tab?.children}
                            </div>
                        }
                    })} />
            </div> : <div>
                {!hideTabs && <div className='d-flex' style={{
                    minWidth: "100%", overflow: "auto", borderBottom: "1px solid var(--grey2)", position: "sticky", top: queriesToStickToBot?.reduce((a, c) => a + getDivHeight(c), 0),
                    background: "var(--white)", zIndex: 10
                }} id='stickyScrollTabsBarMob'>
                    {tabData?.filter(el => el?.show !== false)?.map((tab, i) => {
                        return (
                            <div className='leftRightPadContainer textESM w-500' style={{
                                paddingTop: "0.75rem", paddingBottom: "0.75rem", whiteSpace: "nowrap",
                                color: selectedTab === tab?.value ? "var(--primary)" : "var(--black)", position: "relative", width: "fit-content"
                            }} onClick={() => {
                                setSelectedTab(tab?.value);
                                scrollToTargetMinusOffset('stickyScrollTabsChildContMob', 'stickyScrollTabsBarMob')
                            }} key={tab.value}>
                                {tab?.label || tab?.heading}
                                {selectedTab === tab?.value && <div className="d-flex justify-content-center leftRightPadContainer" style={{
                                    position: "absolute", bottom: 0, width: "100%", left: 0
                                }}>
                                    <div style={{ border: "1.5px solid var(--primary)", width: "95%", borderRadius: "2px 2px 0 0" }}></div>
                                </div>}
                            </div>
                        )
                    })}
                </div>}
                <div className={isWidget ? "" : 'leftRightPadContainer'} style={{ paddingTop: "1rem", paddingBottom: isWidget ? "0" : "1rem" }} id='stickyScrollTabsChildContMob'>
                    {tabData?.filter(el => el?.value === selectedTab)?.map((tab, i) => {
                        return (
                            <div key={tab?.key} style={{ display: selectedTab === tab?.value ? "block" : "none" }} className='w100'>
                                {!tab.noHeading && <CardHeader heading={tab.heading || tab?.label} hideHeaderBlueLine headingType={2} textClassName='textMD w-700' />}
                                {!tab.noHeading && !tab?.noSubHeading && <div style={{ margin: "0.25rem 0 1.25rem", maxWidth: "60%" }} className='textSM dark3'>{tab.subHeading}</div>}
                                {tab?.children}
                            </div>
                        )
                    })}
                </div>
            </div>}
        </React.Fragment>
    )
}