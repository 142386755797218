const RiskFormat = (riskID) => {
    switch (Number(riskID)) {
        case 1: return 'Super conservative'
        case 2: return 'Conservative'
        case 3: return 'Moderate'
        case 4: return 'Aggressive'
        case 5: return 'Super aggressive'
        case 6: return 'Very aggressive'
        default: return ''
    }
}

const RiskColor = (riskID) => {
    switch (Number(riskID)) {
        case 1: return 'var(--blueShade)'
        case 2: return 'var(--greenShade)'
        case 3: return 'var(--yellowShade)'
        case 4: return 'var(--orangeShade)'
        case 5: return 'var(--redShade)'
        case 6: return 'var(--redShade)'
        default: return 'var(--redShade)'
    }
}

const RiskTextColor = (riskID) => {
    switch (Number(riskID)) {
        case 1: return 'var(--primary)'
        case 2: return 'var(--green)'
        case 3: return 'var(--yellow)'
        case 4: return 'var(--orange)'
        case 5: return 'var(--red)'
        case 6: return 'var(--red)'
        default: return 'var(--red)'
    }
}

const RiskContent = (riskID) => {
    switch (Number(riskID)) {
        case 1: return 'You hate taking risks with your money. Invest in safe and portfolios heavy on debt instruments.'
        case 2: return 'You like to stay away from big risks. Equity investing is acceptable only if you are investing for the long term.'
        case 3: return `You don’t mind taking risks if they are likely to result in good returns. Invest in well-diversified portfolios and you should have a great investing experience.`
        case 4: return `You love to see high returns on your investments and you don’t mind taking  risks to generate high returns`
        case 5: return `For you, there is no cost too high to generate high returns. If you think that by investing in an instrument there is even a little chance of generating high returns, you will invest in it.`
        case 6: return `For you, there is no cost too high to generate high returns. If you think that by investing in an instrument there is even a little chance of generating high returns, you will invest in it.`
        default: return `For you, there is no cost too high to generate high returns. If you think that by investing in an instrument there is even a little chance of generating high returns, you will invest in it.`
    }
}

export { RiskFormat, RiskColor, RiskTextColor, RiskContent }