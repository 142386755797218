import { Card } from "antd";
import { abtValues1, abtValues2, abtValues3, abtValues4, abtValues5, arpit, avinash, factset, grouppic1, grouppic2, jayesh, marketingSignup1, marketingSignup2, mayank, naveen, officepic1, officepic2, officepic3, officepic4, officepic5, omkar, phonePe, shubham, suraj, tinku, tradv, value } from "Assets/images";
import Head from "Layout/Head";
import React from "react";
import { BiChevronRight } from "react-icons/bi";
import { BsLinkedin, BsTwitter } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { HiArrowUpRight, HiOutlineArrowLongLeft, HiOutlineArrowLongRight } from "react-icons/hi2";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const AboutUs = () => {

    const navigate = useNavigate();
    let user = useSelector((state) => state?.updateUserData?.value)
    const isMobile = window.innerWidth <= 575;
    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';

    const sharpelyValues = [
        {
            title: "Acknowledge our fiduciary duty",
            content: "We are in the business of selling product and service to retail investors and we fully acknowledge the responsibility that comes along with it",
            icon: abtValues1
        },
        {
            title: "No compromise on the data quality",
            content: "We only work with the best data vendors even at the cost of burning a hole in our pockets!",
            icon: abtValues2
        },
        {
            title: "Keep learning and keep improving",
            content: "The world of investing is dynamic and constantly evolving. Our aim is to keep pace with this dynamic world.",
            icon: abtValues3
        },
        {
            title: "Take criticism in your stride",
            content: "We love constructive criticism. We actively encourage our users to report bugs/data issues and fixing that becomes our top priority.",
            icon: abtValues4
        },
        {
            title: "We are just getting started",
            content: "There is a lot more to add, both in terms of depth and breadth (more instrument types) of our coverage",
            icon: abtValues5
        }
    ]

    const team = [
        {
            name: "Shubham Satyarth",
            designation: "Founder & Chief Executive Officer",
            linkedin: "https://www.linkedin.com/in/shubham-satyarth-03910021/",
            twitter: "https://twitter.com/shub_sat",
            avatar: shubham
        },
        {
            name: "Mayank Sheth",
            designation: "Chief Technology Officer",
            linkedin: "https://www.linkedin.com/in/mayanksheth/",
            twitter: "https://twitter.com/mayankjsheth",
            avatar: mayank
        },
        {
            name: "Arpit Chandak",
            designation: "Sr. Software Engineer",
            linkedin: "https://www.linkedin.com/in/arpitchandak11/",
            twitter: "https://twitter.com/ArpitChandak8",
            avatar: arpit
        },
        {
            name: "Omkar Bhogle",
            designation: "Sr. Software Engineer",
            linkedin: "https://www.linkedin.com/in/omkar-bhogle-973123119/",
            twitter: "https://twitter.com/BhogleOmkar",
            avatar: omkar
        },
        {
            name: "Suraj Kumar Mishra",
            designation: "Software Engineer",
            linkedin: "https://www.linkedin.com/in/surajkm24/",
            twitter: "https://twitter.com/SurajKumarM24",
            avatar: suraj
        },
        {
            name: "Avinash Bhatt",
            designation: "Chief of Content and Marketing",
            linkedin: "https://www.linkedin.com/in/avinashbhatt/",
            twitter: "https://twitter.com/Avi27bhatt",
            avatar: avinash
        },
        {
            name: "Jayesh Rogde",
            designation: "Wealth Manager",
            linkedin: "https://www.linkedin.com/in/jayesh-rodge-0855841b5/",
            twitter: "https://twitter.com/JayeshRodge",
            avatar: jayesh
        },
        {
            name: "Naveen Kumar Mishra",
            designation: "Customer Support",
            linkedin: "https://www.linkedin.com/in/naveenkumar-mishra-9313a51bb/",
            twitter: "https://twitter.com/mishra_naveenk",
            avatar: naveen
        },
        // {
        //     name: "Avinash Bhatt",
        //     designation: "Content & Marketing",
        //     linkedin: "https://www.linkedin.com/in/avinashbhatt/",
        //     twitter: "https://twitter.com/Avi27bhatt",
        //     avatar: 'https://img.uxwing.com/wp-content/themes/uxwing/download/peoples-avatars-thoughts/corporate-user-icon.svg'
        // },
        {
            name: "Tinku Valmiki",
            designation: "Office Helper",
            linkedin: "",
            twitter: "",
            avatar: tinku
        }
    ]

    const partners = [
        {
            name: "FactSet",
            content: "FactSet is rated as the number 1 data provider for company fundamentals. Further, use of FactSet ensures IFRS standardization of all financial items thereby circumventing (to an extent) potential reporting management",
            img: factset
        },
        {
            name: "Value Research",
            content: `We use Value Research for Mutual Fund and ETF data. Value Research is one of the top data
            providers for Mutual Funds in India with top-notch quality and coverage`,
            img: value
        },
        {
            name: "Trading View",
            content: <span>Our partner for technical analysis and advance charting. Check out the <a href='https://www.tradingview.com/economic-calendar/' target='_blank'>Economic calendar</a> to
                track important upcoming events or browse through <a href='https://www.tradingview.com/screener/' target='_blank'> Screener</a>.</span>,
            img: tradv
        },
        {
            name: "Razor Pay",
            content: `Our payments partner ensuring that all the payments on our system are 100% secure.`,
            img: phonePe
        }
    ]

    const handleScroll2 = (value) => {
        let target = document.querySelector('.heWhOuClSa');
        let otherTarget = document.querySelector('.heWhOuClSa>:first-child')?.clientWidth + 40;
        target.scrollBy({ left: value * otherTarget, behavior: "smooth" });
    }

    function scrollToElement(elementId) {
        const targetElement = document.getElementById(elementId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <React.Fragment>
            <Head title='sharpely: Empowering Financial Decisions | Learn About Our Journey' description="Discover the story behind sharpely, our mission, and how we're reshaping the landscape of financial empowerment." />
            <main className='marketing'>
                <Card style={{ borderRadius: "0px", padding: "0px" }} bodyStyle={{ padding: "0rem", height: "100%", width: "100%" }} className={`${isDev ? "maxWidthContainer" : ""}`}>
                    <div className="padAll marHomeCardPadTop">
                        <div className="d-flex flex-column align-items-center marHomeResMainCard" style={{ textAlign: "center", margin: "auto" }}>
                            <h1 className="black headXLtoSM " style={{
                                fontWeight: 700, padding: "0px", width: "14ch",
                                marginBottom: "1.25rem"
                            }}>
                                About Us
                            </h1>
                            <div className="textLGtoESM dark3" style={{ width: "50ch" }}>
                                We love technology, we love data crunching and we love investing (obviously!). sharpely is
                                our attempt to bring it all together.
                            </div>
                            <div style={{ marginTop: "1.5rem", cursor: "pointer" }} className='marBtnPri' onClick={() => { scrollToElement('team') }} >
                                Meet the team <BiChevronRight className="textSM" />
                            </div>
                        </div>
                        <div className="aboutUsBanner">
                            <div style={{
                                display: "grid", gridTemplateColumns: "1fr 2fr", gap: "1.5rem",
                                marginTop: "2.5rem"
                            }}>
                                <div className="w100 h100">
                                    <img loading="lazy"  alt="" src={officepic5} style={{
                                        objectFit: "cover",
                                        borderRadius: "var(--borderRadius)",
                                    }} />
                                </div>
                                <div className="w100 h100">
                                    <img loading="lazy"  alt="" src={officepic4} style={{
                                        objectFit: "cover",
                                        borderRadius: "var(--borderRadius)"
                                    }} />
                                </div>
                            </div>
                            <div style={{
                                display: "grid", gridTemplateColumns: "1fr 2fr", gap: "1.5rem",
                                marginTop: "1.5rem"
                            }}>
                                <div className="w100 h100">
                                    <img loading="lazy"  alt="" src={officepic3} style={{
                                        objectFit: "cover",
                                        borderRadius: "var(--borderRadius)"
                                    }} />
                                </div>
                                <div style={{
                                    display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1.5rem", width: "100%", height: "100%"
                                }}>
                                    <div className="w100 h100">
                                        <img loading="lazy"  alt="" src={officepic2} style={{
                                            objectFit: "cover",
                                            borderRadius: "var(--borderRadius)"
                                        }} />
                                    </div>
                                    <div className="w100 h100">
                                        <img loading="lazy"  alt="" src={officepic1} style={{
                                            objectFit: "cover",
                                            borderRadius: "var(--borderRadius)"
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Card>
                <Card style={{ borderRadius: "0px", padding: "0px" }} bodyStyle={{ padding: "0rem", height: "100%", width: "100%" }} className={`${isDev ? "maxWidthContainer" : ""}`}>
                    <div className="padLeft padRight padBottom">
                        <div className="flexRowCard flex-column flex-sm-row">
                            <div className="marHomeResMainCard d-flex flex-column align-items-center align-items-sm-start flexCard">
                                <h2 className="headLGtoXS black margin0" style={{ fontWeight: 700 }}>
                                    Our mission.
                                </h2>
                                <div className='textMDtoESM dark4' style={{ marginTop: '0rem' }}>
                                    <div>
                                        Having worked on the institutional side of the investing world, I have seen the sheer amount
                                        of information available to institutional investors. This scale of information asymmetry
                                        between institutional and retail investors worried me.
                                    </div>
                                    <div style={{ marginTop: "0.5rem" }}>
                                        Today, as a retail investor, availability of sufficient information that is both reliable and
                                        affordable is a big challenge. This is what we are trying to solve at sharpely.
                                    </div>
                                    <div style={{ marginTop: "0.5rem" }}>
                                        Our mission is to provide institutional-grade data and research tools and empower retail
                                        investors to make informed investment decisions.
                                    </div>
                                    <div style={{ marginTop: "0.5rem" }}>
                                        We aim to help investors make “better” investing choices and avoid the trap of “fear and
                                        greed” by following a data-driven investing approach.
                                    </div>
                                </div>
                            </div>
                            <div className='flexCard' style={{ flex: 0.9 }}>
                                <div style={{ width: isMobile ? "100%" : "72%", margin: "auto 0 auto auto" }}>
                                    <img loading="lazy"  alt="" src={grouppic1} style={{ width: "100%", borderRadius: "2.5rem", height: "auto" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                <Card style={{ borderRadius: "0px", padding: "0px" }} bodyStyle={{ padding: "0rem", background: "var(--dark4)" }} >
                    <div className={`padAll marHomeCardPadTop marHomeCardPadBot ${isDev ? "maxWidthContainer" : ""}`} >
                        <div className='d-flex flex-column flex-sm-row align-items-center justify-content-center marAbtQuantBann'
                            style={{ gap: "1.25rem" }}>
                            <div className="d-flex flex-column align-items-center flexCard" style={{
                                textAlign: "center",
                                borderRight: "1px solid var(--dark3)"
                            }}>
                                <div className="headSMtoTextLG white" style={{ fontWeight: 700 }}>
                                    50+ years
                                </div>
                                <div className='textMDtoESM dark1' style={{ marginTop: "-1rem" }}>
                                    Combined experience in Fintech
                                </div>
                            </div>
                            <div className="d-flex flex-column align-items-center flexCard" style={{
                                textAlign: "center",
                                borderRight: "1px solid var(--dark3)"
                            }}>
                                <div className="headSMtoTextLG white" style={{ fontWeight: 700 }}>
                                    Rs 5,000 crore+
                                </div>
                                <div className='textMDtoESM dark1' style={{ marginTop: "-1rem" }}>
                                    Asset management experience
                                </div>
                            </div>
                            <div className="d-flex flex-column align-items-center flexCard" style={{
                                textAlign: "center",
                                borderRight: "1px solid var(--dark3)"
                            }}>
                                <div className="headSMtoTextLG white" style={{ fontWeight: 700 }}>
                                    10,000+
                                </div>
                                <div className='textMDtoESM dark1' style={{ marginTop: "-1rem" }}>
                                    Clients advised
                                </div>
                            </div>
                            <div className="d-flex flex-column align-items-center flexCard" style={{
                                textAlign: "center"
                            }}>
                                <div className="headSMtoTextLG white" style={{ fontWeight: 700 }}>
                                    4%+
                                </div>
                                <div className='textMDtoESM dark1' style={{ marginTop: "-1rem" }}>
                                    Historical alpha delivered*
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                <Card style={{ borderRadius: "0px", padding: "0px" }} bodyStyle={{ padding: "0rem", background: "var(--blue2)" }} >
                    <div style={{ display: "flex", gap: "2.5rem", position: "relative" }}
                        className={`padAll flex-column flex-md-row flexRowCard marAbtValues ${isDev ? "maxWidthContainer" : ""}`}>
                        <div className='flexCard'>
                            <div className='marHomeResMainCard d-flex flex-column align-items-center align-items-md-start'>
                                <h2 className='headLGtoXS w-700 black margin0' style={{ fontWeight: 700 }}>
                                    Don’t compromise on product quality. Keep learning and improving.
                                </h2>
                                <div style={{ marginTop: "1.5rem", cursor: "pointer", padding: "0.75rem 1.25rem" }} className='btnBlack'
                                    onClick={() => { scrollToElement('team') }}>
                                    Meet our team <BiChevronRight className="textSM" />
                                </div>
                                <div style={{ width: "100%", margin: "3rem 0 0" }}>
                                    <img loading="lazy"  alt="" src={grouppic2} style={{ width: "100%", borderRadius: "2.5rem", height: "auto" }} />
                                </div>
                            </div>
                        </div>
                        <div className='flexCard'>
                            {sharpelyValues?.map((item, i) => (
                                <Card style={{ padding: 0, borderRadius: "var(--borderRadius)" }} bodyStyle={{ padding: '2rem 1.25rem' }} key={i}>
                                    <div className="d-flex marAbtValuesMiniCards" style={{ gap: "0.5rem", alignItems: "flex-start" }}>
                                        <img loading="lazy"  alt="" src={item.icon} style={{ width: "4rem", height: "4rem" }} />
                                        <div>
                                            <h3 className='displayXS black margin0' style={{ fontWeight: 700 }}>{item.title}</h3>
                                            <div className="textMDtoSM dark3">{item.content}</div>
                                        </div>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </div>
                </Card>
                <Card style={{ borderRadius: "0px", padding: "0px" }} bodyStyle={{ padding: "0rem" }}>
                    <div className={`padAll ${isDev ? "maxWidthContainer" : ""}`} id='team'>
                        <h2 className="headLGtoXS black margin0" style={{ fontWeight: 700, textAlign: "center" }}>
                            Meet our team
                        </h2>
                        <div className="textMDtoESM dark3" style={{ textAlign: "center", marginTop: "0.5rem" }}>
                            Our team has a combined experience of over 50 years in delivering cutting-edge
                            investment tech platforms.
                        </div>
                        <div className="" style={{
                            gap: "2rem", marginTop: "2rem", justifyContent: "space-evenly", display: "grid",
                            gridTemplateColumns: "repeat(auto-fit, minmax(18rem, 1fr))"
                        }}>
                            {team?.map((ele, i) => (
                                <div key={i} style={{ width: "100%" }}>
                                    <img loading="lazy"  alt="" src={ele?.avatar} className='teamMemberImg' />
                                    <div className="black textMD w-500 mt-2">
                                        {ele.name}
                                    </div>
                                    <div className="dark3 textSM mt-1">
                                        {ele.designation}
                                    </div>
                                    <div className="d-flex align-items-center" style={{ marginTop: "0.75rem", gap: "1rem" }}>
                                        {ele.linkedin && <a href={ele?.linkedin} target='_blank'>
                                            <BsLinkedin cursor='pointer' color='var(--black)' className="textLG" />
                                        </a>}
                                        {ele.twitter && <a href={ele?.twitter} target='_blank'>
                                            <BsTwitter cursor='pointer' color='var(--black)' className="textLG" />
                                        </a>}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Card>
                <Card style={{ borderRadius: "0px", padding: "0px" }} bodyStyle={{ padding: "0rem", background: "#F7F7FB" }}>
                    <div className={`padTop padRight padLeft ${isDev ? "maxWidthContainer" : ""}`}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                            <div className='marHomeResMainCard'>
                                <h2 className='headLGtoXS black margin0' style={{ fontWeight: 700 }}>
                                    Our partners
                                </h2>
                                <div className="textMDtoESM dark3" style={{ width: "55ch", marginTop: "1rem" }}>
                                    We work with industry leading players to deliver a best-in-class investment experience to
                                    you
                                </div>
                            </div>
                            <div style={{ justifyContent: "space-between", gap: "2rem" }} className='d-none d-sm-flex'>
                                <HiOutlineArrowLongLeft style={{
                                    padding: "1rem",
                                    background: "var(--white)",
                                    boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
                                    borderRadius: "50%",
                                    fontSize: "3.5rem",
                                    cursor: "pointer",
                                    color: "var(--primary)"
                                }} onClick={() => handleScroll2(-1)} />
                                <HiOutlineArrowLongRight style={{
                                    padding: "1rem",
                                    background: "var(--white)",
                                    boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
                                    borderRadius: "50%",
                                    fontSize: "3.5rem",
                                    cursor: "pointer",
                                    color: "var(--primary)"
                                }} onClick={() => handleScroll2(1)} />
                            </div>
                        </div>
                    </div>
                    <div className={`padLeft padBottom padRight ${isDev ? "maxWidthContainer" : ""}`} style={{ marginTop: "2.5rem" }}>
                        <div className="heWhOuClSa" style={{
                            width: "100%", overflowX: "auto", alignItems: "flex-start", gap: "1.25rem", display: "grid",
                            gridTemplateColumns: `repeat(${partners.length}, 1fr)`
                        }}>
                            {partners.map((ele, i) => (
                                <Card key={i} style={{ borderRadius: "var(--borderRadius)", minHeight: "12rem" }} bodyStyle={{ height: "100%" }}>
                                    <div style={{ width: "20rem" }}>
                                        <div>
                                            <img loading="lazy"  src={ele.img} style={{ height: "1.5rem", width: "auto" }} />
                                        </div>
                                        <div className="textSM dark3" style={{ marginTop: "0.75rem" }}>
                                            {ele.content}
                                        </div>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </div>
                </Card>
                <Card id="informed-box" style={{ borderRadius: "0px", padding: "0px" }} bodyStyle={{ padding: "0rem" }} >
                    <div className={`padAll marBottomBanner marHomeCardPadTop marHomeCardPadBot ${isDev ? "maxWidthContainer" : ""}`}>
                        <div style={{
                            background: "linear-gradient(131.73deg, #016DD7 -21.82%, #7553FF 90.86%)", textAlign: "center", gap: "0.75rem",
                            padding: "3rem 1rem 4rem", borderRadius: "var(--borderRadius)", position: "relative"
                        }}
                            className='d-flex flex-column align-items-center'>
                            <div className="">
                                <h2 className='w-700 white headLGtoTextLG margin0' style={{ width: "25ch" }}>
                                    Start making informed investing decision today
                                </h2>
                                <div className='textLGtoESM w-400 white ' style={{ marginTop: "0.75rem" }}>
                                    Get started today to boost your investments.
                                </div>
                            </div>
                            <div className='d-flex align-items-center' style={{ gap: "0.5rem", cursor: "pointer" }} onClick={() => {
                                navigate(user?.isLogged ? "/stocks/dashboard" : "/login")
                            }}>
                                <div className='textLG w-700 white'>{user?.isLogged ? <>Explore Stocks</> : <>Start now for FREE</>}</div>
                                <HiArrowUpRight className='textLG w-700 white' />
                            </div>
                            <div className='d-flex align-items-center flex-column flex-sm-row' style={{ gap: "1rem", marginTop: "1.25rem" }}>
                                <>
                                    {user?.isLogged && <Link to={user?.isLogged ? "/etfs/dashboard" : '/login'} style={{ flexShrink: 0 }}>
                                        <div className='w-500' style={{
                                            color: "var(--primary)", padding: "10px 19px", background: "var(--white)",
                                            border: "1px solid var(--white)", borderRadius: "var(--borderRadius)", fontSize: "var(--textSM)"
                                        }}>
                                            {user?.isLogged ? <> Explore ETFs</> : <> Sign Up With Email</>}
                                        </div>
                                    </Link>}
                                    <Link to={user?.isLogged ? "/mutual-funds/dashboard" : '/login'} style={{ width: "100%" }}>
                                        <div className='w-500 d-flex align-items-center' style={{
                                            color: "var(--primary)", padding: "10px 19px", background: "var(--white)",
                                            border: "1px solid var(--white)", borderRadius: "var(--borderRadius)", fontSize: "var(--textSM)"
                                        }}>
                                            {user?.isLogged ? <> Explore MFs</> : <>
                                                <FcGoogle className='' style={{ marginRight: "0.5rem", fontSize: "var(--textSM)" }} />
                                                <div>Sign Up With Google</div>
                                            </>}
                                        </div>
                                    </Link>
                                </>
                            </div>
                            <img loading="lazy"  alt="" src={marketingSignup1} style={{
                                position: "absolute", top: "3rem", left: "0px", width: "12rem"
                            }} />
                            <img loading="lazy"  alt="" src={marketingSignup2} style={{
                                position: "absolute", bottom: "0rem", right: "0px", width: "12rem"
                            }} />
                        </div>
                    </div>
                </Card>
            </main>
        </React.Fragment>
    )
}

export default AboutUs;