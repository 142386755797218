import { Card, Segmented, Select } from "antd";
import AccessComponent from "Components/AccessComponent";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import { CurrencyFormat } from "Components/CurrencyFormat";
import CustomLabelValueCards from "Components/CustomLabelValue";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import { InsightIcon } from "Components/InsightsIcon";
import { StickyScrollTabs } from "Components/CustomComponents/StickyScrollTabs";
import { NoDataFound } from "Components/NoDataFound";
import RedGreenText from "Components/RedGreenText";
import { StockScoresGauge2 } from "Components/StockScoresGauge2";
import UpgradeToPro from "Components/UpgradeToPro";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { toggleUserGuideModal } from "Libs/redux/KnowledgeBase/reduxSlice";
import { generateUniqueKey } from "Libs/utils";
import moment from "moment";
import React from "react";
import { GetLTP } from "Services/api/LivePricesWebSocketOnlyVal";

export const Forecast = React.memo(({ loadingEstimates, fundData, estimates, SYMBOL, accessLevelData, dispatch, isMobile }) => {
    const Estimates = ({ x, type }) => {

        if (loadingEstimates) {
            return <Card style={{ flexGrow: 1, height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }} >
                <ActivityLoaderMintbox />
            </Card>
        }
        if (!x) {
            return (
                <Card style={{ height: "20rem", width: "100%", textAlign: "center" }} bodyStyle={{ width: "100%", height: "100%" }} >
                    <NoDataFound message={`Seems like this stock doesn't have any active ${type === 0 ? "recommendations by analyst." : type === 1 ? "target price estimates by analyst." :
                        "financial estimates by analysts or consolidated estimates are not available."} `} msgMaxWidth='60ch' />
                </Card>
            );
        }

        const Recommendation = ({ data }) => {

            const cards = [
                { key: "total", heading: "Total recommendations", value: Number(data?.total_analyst), show: true, },
                { key: "strongbuy", heading: "Analysts with Strong Buy", value: Number(data?.strong_buy_num), show: true, },
                { key: "buy", heading: "Analysts with buy", value: Number(data?.buy_num), show: true, },
            ];

            const series = [
                { heading: "Analysts with Strong Buy", y: Number(data?.strong_buy_num), color: "var(--green)" },
                { heading: "Analysts with buy", y: Number(data?.buy_num), color: "var(--greenShade)", },
                { heading: "Analysts with hold", y: Number(data?.hold_num), color: "var(--yellowShade)", },
                { heading: "Analysts with Sell", y: Number(data?.sell_num), color: "var(--redShade)", },
                { heading: "Analysts with Strong Sell", y: Number(data?.strong_sell_num), color: "var(--red)", },
            ];

            const totalAnalysts = Number(data?.strong_buy_num) + Number(data?.buy_num) + Number(data?.hold_num) + Number(data?.sell_num) + Number(data?.strong_sell_num);
            const buyOrStrongBuy = Number(data?.strong_buy_num) + Number(data?.buy_num);
            const buyOrStrongBuyPer = buyOrStrongBuy && totalAnalysts ? Number(Number((buyOrStrongBuy / totalAnalysts) * 100)?.toFixed(2)) : null
            try {
                var conf = {
                    chart: {
                        backgroundColor: "transparent",
                        type: "bar",
                    },
                    title: {
                        text: "",
                    },
                    exporting: {
                        enabled: false,
                    },
                    yAxis: {
                        visible: false,
                        gridLineDashStyle: "longdash",
                        title: {
                            text: "",
                        },
                        gridLineColor: "var(--grey1)",
                    },
                    xAxis: {
                        categories: series.map((x) => x.heading),
                        lineColor: "var(--grey1)",
                        tickColor: "var(--grey1)",
                    },
                    legend: {
                        enabled: false,
                    },
                    plotOptions: {
                        series: {
                            // lineWidth: 1.5,
                            label: {
                                connectorAllowed: false,
                            },
                            marker: {
                                enabled: true,
                            },
                            pointWidth: 40,
                            pointPadding: 0,
                        },
                    },
                    tooltip: {
                        // shared: true,
                        // valuedecimals: 1,
                        enabled: false,
                        // valuePrefix: '₹',
                    },
                    credits: {
                        enabled: false,
                    },
                    boost: {
                        useGPUTranslations: true,
                    },
                    series: [
                        {
                            data: series,
                            dataLabels: {
                                enabled: true,
                            },
                        },
                    ],
                    scrollbar: {
                        enabled: false,
                    },
                    responsive: {
                        rules: [
                            {
                                condition: {
                                    maxWidth: 400,
                                },
                            },
                        ],
                    },
                };
            } catch (error) {
                //console.log("SDF", error)
            }
            if (!Object.keys(data).length) {
                return (
                    <Card style={{ height: "20rem", width: "100%", textAlign: "center" }} bodyStyle={{ width: "100%", height: "100%" }} >
                        <NoDataFound message={`Seems like this stock doesn't have any active recommendations by analyst.`} msgMaxWidth='60ch' />
                    </Card>
                );
            }
            return (
                <div>
                    <CustomLabelValueCards x={cards} />
                    <Card bodyStyle={{ display: "flex", flexDirection: "column", justifyContent: "space-around", padding: '1.25rem', }} style={{
                        border: "1px solid var(--grey2)",
                        marginTop: "1.25rem"
                    }} >
                        <div style={{ width: "100%", display: "grid", alignItems: "center", gridTemplateColumns: isMobile ? "1fr" : "3fr 2fr" }}>
                            <div style={{
                                flexGrow: 1, borderRight: isMobile ? "none" : '1px solid var(--grey2)', paddingRight: isMobile ? "0" : "1rem",
                                borderBottom: !isMobile ? "none" : '1px solid var(--grey2)', paddingBottom: !isMobile ? "0" : "1rem"
                            }}>
                                <div style={{ width: "100%" }}>
                                    <HighchartsReact highcharts={Highcharts} options={conf} />
                                </div>
                            </div>
                            <div style={{ flexGrow: 0.6, paddingLeft: "1rem" }}>
                                <StockScoresGauge2 val={buyOrStrongBuyPer} height={200} labelSuffix={"%"} labelFont={"16px"} showTickLabels={true} paneThickness={20} min={0}
                                    max={100} isAnalystRating={true} yAxisLabels={{ 10: "Strong<br/>sell", 30: "Sell", 50: "Neutral", 70: "Buy", 90: "Strong<br/>buy" }} yAxisLabelClass='analystRatingGauge'
                                    tickInterval={10} tickAmount={11} showTick={false} />
                                <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                                    <div style={{ flexShrink: 0 }}>
                                        <InsightIcon type={buyOrStrongBuyPer <= 50 ? "Negative" : buyOrStrongBuyPer <= 70 ? "Neutral" : "Positive"} height="2rem" width="2rem" padding="0.5rem" />
                                    </div>
                                    <div className="textXS dark4 w-500">
                                        {buyOrStrongBuyPer <= 50 && "Only "}{buyOrStrongBuyPer}% of analysts have buy or strong buy on this stock implying {buyOrStrongBuyPer <= 50 ? "negative" : buyOrStrongBuyPer <= 70 ? "neutral" : "positive"} street outlook.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            );
        };

        const ShowPercentChange = (target, current) => {
            const per = ((target - current) * 100) / current;
            //console.log(target, current, per)
            return (
                <span className="textXS">
                    <RedGreenText text={`(${DecimalValueFormat({ num: per, suffix: "%", placeholder: "-", })})`} value={per} sign={false} />
                </span>
            );
        };

        const TargetPrice = ({ data }) => {
            const curr = GetLTP({ fundData: fundData, symbol: SYMBOL });
            const todayAndNextYear = [new Date().getFullYear(), new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getFullYear(),];
            const cards = [
                { key: "current", heading: "Current market price", value: CurrencyFormat(curr, "long", 2), show: true, },
                {
                    key: "median", heading: "Median target price", value: (<span style={{ whiteSpace: "nowrap" }}>
                        {CurrencyFormat(data?.median, "long", 2)}
                        <br className="display-desktop" />{" "}
                        {ShowPercentChange(data?.median, curr)}
                    </span>), show: data.median,
                },
                {
                    key: "high", heading: "Highest target price", value: (<span style={{ whiteSpace: "nowrap" }}>
                        {CurrencyFormat(data?.high, "long", 2)}
                        <br className="display-desktop" />{" "}
                        {ShowPercentChange(data?.high, curr)}
                    </span>), show: data.high,
                },
                {
                    key: "avg", heading: "Average target price", value: (<span style={{ whiteSpace: "nowrap" }}>
                        {CurrencyFormat(data?.average, "long", 2)}
                        <br className="display-desktop" />{" "}
                        {ShowPercentChange(data?.average, curr)}
                    </span>), show: data.average,
                },
                {
                    key: "low", heading: "Lowest target price", value: (<span style={{ whiteSpace: "nowrap" }}>
                        {CurrencyFormat(data?.low, "long", 2)}
                        <br className="display-desktop" />{" "}
                        {ShowPercentChange(data?.low, curr)}
                    </span>), show: data.low,
                },
            ];
            var series = Object.values(data?.hist_prices || {})?.[0] || [];
            try {
                var conf = {
                    chart: {
                        backgroundColor: "transparent",
                        type: "line",
                        // height: 300,
                    },
                    title: {
                        text: "",
                    },
                    exporting: {
                        enabled: false,
                    },
                    yAxis: {
                        visible: false,
                        // tickInterval: 5,
                        gridLineDashStyle: "longdash",
                        title: {
                            text: "",
                        },
                        gridLineColor: "var(--grey1)",
                    },
                    xAxis: {
                        tickPositions: Object.keys(series)
                            .map((k) => {
                                return new Date(k).getFullYear();
                            })
                            .concat(todayAndNextYear),
                        lineColor: "var(--grey1)",
                        tickColor: "var(--grey1)",
                        labels: {
                            formatter: function () {
                                if (this.value === new Date().getFullYear()) {
                                    return "Today";
                                } else if (this.isLast) {
                                    return moment(new Date().setFullYear(this.value)).format("MMM YYYY")
                                }
                                return "Dec " + this.value;
                            },
                        },
                        plotLines: [
                            {
                                color: "var(--grey1)",
                                dashStyle: "longdash",
                                width: 1,
                                value: new Date().getFullYear(),
                                zIndex: 5,
                                label: {
                                    text: "Estimate",
                                    rotation: 0,
                                },
                            },
                        ],
                    },
                    legend: {
                        enabled: false,
                    },
                    plotOptions: {
                        series: {
                            // lineWidth: 1.5,
                            dataLabels: {
                                enabled: true,
                            },
                            label: {
                                connectorAllowed: false,
                            },
                            marker: {
                                enabled: false,
                            },
                            states: {
                                hover: {
                                    enabled: false,
                                },
                            },
                        },
                    },
                    tooltip: {
                        // shared: true,
                        // valuedecimals: 1,
                        enabled: false,
                        // valuePrefix: '₹',
                    },
                    credits: {
                        enabled: false,
                    },
                    boost: {
                        useGPUTranslations: true,
                    },
                    series: [
                        {
                            name: "Price",
                            data: Object.keys(series).map((k) => {
                                return { x: new Date(k).getFullYear(), y: series?.[k] };
                            })
                                .concat([{ x: new Date().getFullYear(), y: curr }]),
                            // data: Object.keys(series).map(k => { return series?.[k] }).concat([curr]),
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    return CurrencyFormat(this.y, "long", 0);
                                },
                            },
                            color: "var(--primary)",
                            showInLegend: false,
                            enableMouseTracking: false,
                        },
                        {
                            name: "High",
                            data: [
                                { x: todayAndNextYear[0], y: curr },
                                { x: todayAndNextYear[1], y: data?.high },
                            ],
                            // data: [lastPoint, { x: new Date('2024-12-31').getTime(), y: data?.high }],
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    return CurrencyFormat(this.y, "long", 0);
                                },
                            },
                            dashStyle: "dash",
                            color: "#83BF6E77",
                            showInLegend: false,
                            enableMouseTracking: false,
                        },
                        {
                            name: "Median",
                            data: [
                                { x: todayAndNextYear[0], y: curr },
                                { x: todayAndNextYear[1], y: data?.median },
                            ],
                            // data: [lastPoint, { x: new Date('2024-12-31').getTime(), y: data?.high }],
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    return CurrencyFormat(this.y, "long", 0);
                                },
                            },
                            dashStyle: "dash",
                            color: "var(--dark2)",
                            showInLegend: false,
                            enableMouseTracking: false,
                        },
                        {
                            name: "Low",
                            data: [
                                { x: todayAndNextYear[0], y: curr },
                                { x: todayAndNextYear[1], y: data?.low },
                            ],
                            // data: [lastPoint, { x: new Date('2024-12-31').getTime(), y: data?.high }],
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    return CurrencyFormat(this.y, "long", 0);
                                },
                            },
                            color: "#FF6A5577",
                            dashStyle: "dash",
                            showInLegend: false,
                            enableMouseTracking: false,
                        },
                    ],
                    scrollbar: {
                        enabled: false,
                    },
                    responsive: {
                        rules: [
                            {
                                condition: {
                                    maxWidth: 400,
                                },
                            },
                        ],
                    },
                };
            } catch (error) {
                //console.log("SDF", error)
            }
            if (!Object.keys(data).length) {
                return (
                    <Card style={{ height: "20rem", width: "100%", textAlign: "center" }} bodyStyle={{ width: "100%", height: "100%" }} >
                        <NoDataFound message={`Seems like this stock doesn't have any active target price estimates by analyst.`} msgMaxWidth='60ch' />
                    </Card>
                );
            }
            if (window.innerWidth > 760) {
                return (
                    <Card bodyStyle={{ display: "flex", padding: 0 }} style={{ padding: "0rem", borderRadius: "var(--borderRadius)" }}>
                        <div style={{ flex: 1, paddingRight: "1.5rem" }}>
                            <CustomLabelValueCards x={cards} orientation="verticle" />
                        </div>
                        <div style={{ flex: 5, padding: "1.25rem", border: "1px solid var(--grey2)", borderRadius: "var(--borderRadius)" }}>
                            <HighchartsReact highcharts={Highcharts} options={conf} />
                        </div>
                    </Card>
                );
            } else {
                return (
                    <Card bodyStyle={{ display: "flex", flexDirection: "column", padding: 0 }} style={{ padding: "0rem", borderRadius: "var(--borderRadius)" }}>
                        <div style={{}}>
                            <CustomLabelValueCards x={cards} orientation="verticle" />
                        </div>
                        <div style={{ padding: "1.25rem", border: "1px solid var(--grey2)", borderRadius: "var(--borderRadius)", marginTop: "1.25rem" }}>
                            <HighchartsReact highcharts={Highcharts} options={conf} />
                        </div>
                    </Card>
                );
            }
        };
        const Financial = ({ data }) => {
            //console.log("financial", data)
            var options = [
                { key: 0, name: "EPS", data: data?.eps, show: Object.keys(data?.eps || {})?.length, },
                { key: 1, name: "Sales", data: data?.sales, show: Object.keys(data?.sales || {})?.length, },
            ];
            options = options.filter((x) => x.show);
            const [selected, setSelected] = React.useState(options?.[0]);
            const series = Object.values(selected?.data?.chart || {})?.[0] || [];
            try {
                var conf = {
                    chart: {
                        backgroundColor: "transparent",
                        type: "column",
                        // height: 300,
                    },
                    title: {
                        text: "",
                    },
                    exporting: {
                        enabled: false,
                    },
                    yAxis: {
                        visible: false,
                        // tickInterval: 5,
                        gridLineDashStyle: "longdash",
                        title: {
                            text: "",
                        },
                        gridLineColor: "var(--grey1)",
                    },
                    xAxis: {
                        // categories: series.map(x => x.heading),
                        lineColor: "var(--grey1)",
                        tickColor: "var(--grey1)",
                    },
                    legend: {
                        enabled: false,
                    },
                    plotOptions: {
                        series: {
                            // lineWidth: 1.5,
                            label: {
                                connectorAllowed: false,
                            },
                            marker: {
                                enabled: true,
                            },
                        },
                    },
                    tooltip: {
                        // shared: true,
                        // valuedecimals: 1,
                        enabled: false,
                        // valuePrefix: '₹',
                    },
                    credits: {
                        enabled: false,
                    },
                    boost: {
                        useGPUTranslations: true,
                    },
                    series: [
                        {
                            data: Object.keys(series).map((k, i) => {
                                const last = Object.keys(series)?.length - 1;
                                return {
                                    x: moment(k).get("year"),
                                    y: series[k],
                                    color: i === last ? "var(--greenShade)" : i === last - 1 ? "var(--greenShade)" : i === last - 2 ? "var(--blueShade)" : "var(--grey1)",
                                };
                            }),
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    return DecimalValueFormat({ num: this.y, prefix: "₹", decimals: 2, });
                                },
                            },
                        },
                    ],
                    scrollbar: {
                        enabled: false,
                    },
                    responsive: {
                        rules: [
                            {
                                condition: {
                                    maxWidth: 400,
                                },
                            },
                        ],
                    },
                };
            } catch (error) {
                //console.log("SDF", error)
            }
            if (!options.length) {
                return (
                    <Card style={{ height: "20rem", width: "100%", textAlign: "center" }} bodyStyle={{ width: "100%", height: "100%" }} >
                        <NoDataFound message={`Seems like this stock doesn't have any active financial estimates by analysts or consolidated estimates are not available.`} msgMaxWidth='60ch' />
                    </Card>
                );
            }
            return (
                <>
                    <Card bodyStyle={{ padding: 0 }} style={{ padding: "0rem", borderRadius: "var(--borderRadius)" }}>
                        <div style={{ display: "flex", gap: "1rem", marginBottom: "1.5rem", justifyContent: "space-between", flexDirection: window.innerWidth > 760 ? "row" : "column", }}>
                            <div style={{ order: window.innerWidth > 760 ? 1 : 2 }}>
                                <div className="textMD w-700 dark3 mb-2">
                                    Actual {selected.name} for FY end{" "}
                                    {moment(selected?.data?.base_year).format("MMM YY")}
                                </div>
                                <div className="textMD w-700 dark3">
                                    {CurrencyFormat(selected?.data?.base_val, "long", 2, selected.name === "EPS" ? "/share" : " Cr")}
                                </div>
                            </div>
                            <div style={{ order: window.innerWidth > 760 ? 2 : 1 }}>
                                <Segmented block={window.innerWidth <= 760} options={options.map((m) => m.name)} onChange={(m) => setSelected(options.find((o) => o.name === m))} />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: window.innerWidth > 760 ? "row" : "column", gap: "1.25rem" }}>
                            <div style={window.innerWidth > 760 ? { flex: 1, paddingRight: "2.5rem" } : {}}>
                                {Object.values(selected?.data?.forecast).map((y, i) => {
                                    return (
                                        <div style={{ marginTop: i === 0 ? 0 : "1.5rem" }} key={generateUniqueKey(i)}>
                                            <div className="textMD w-700 dark3 mb-2">
                                                Estimated {selected.name} for FY end{" "}
                                                {moment(y?.year).format("MMM YY")}
                                            </div>
                                            <CustomLabelValueCards minWidth="9rem" x={[
                                                {
                                                    key: "median", heading: "Median", value: (<span style={{ whiteSpace: "nowrap" }}>
                                                        {CurrencyFormat(y?.median, "long", 2, selected.name === "EPS" ? "/share" : " Cr")}
                                                        <br className="display-desktop" />
                                                        {ShowPercentChange(y?.median, selected?.data?.base_val)}
                                                    </span>), show: data.median,
                                                },
                                                {
                                                    key: "high", heading: "Highest", value: (<span style={{ whiteSpace: "nowrap" }}>
                                                        {CurrencyFormat(y?.high, "long", 2, selected.name === "EPS" ? "/share" : " Cr")}
                                                        <br className="display-desktop" />{" "}
                                                        {ShowPercentChange(y?.high, selected?.data?.base_val)}
                                                    </span>), show: data.high,
                                                },
                                                {
                                                    key: "low", heading: "Lowest", value: (<span style={{ whiteSpace: "nowrap" }}>
                                                        {CurrencyFormat(y?.low, "long", 2, selected.name === "EPS" ? "/share" : " Cr")}
                                                        <br className="display-desktop" />{" "}
                                                        {ShowPercentChange(y?.low, selected?.data?.base_val)}
                                                    </span>), show: data.low,
                                                },
                                            ]} />
                                        </div>
                                    );
                                })}
                            </div>
                            <div style={window.innerWidth > 760 ? { flex: 1, padding: "1.25rem", border: "1px solid var(--grey2)", borderRadius: "var(--borderRadius)" } : {}}>
                                <div style={{}}>
                                    <HighchartsReact highcharts={Highcharts} options={conf} />
                                </div>
                            </div>
                        </div>
                    </Card>
                </>
            );
        };
        const Revisions = ({ data }) => {
            console.log("revisions", data);
            const options = Object.keys(data).map((y) => {
                return {
                    key: y,
                    name: moment(y).format("MMM YY"),
                    data: data[y],
                };
            });
            const options2 = Object.keys(data).map((y) => ({
                key: y,
                label: moment(y).format("MMM YY"),
                data: data[y],
                value: moment(y).format("MMM YY"),
            }));
            const [selected, setSelected] = React.useState(options2[0]);
            try {
                var conf = {
                    chart: {
                        backgroundColor: "transparent",
                        type: "line",
                        // height: 300,
                    },
                    title: {
                        text: "",
                    },
                    exporting: {
                        enabled: false,
                    },
                    yAxis: {
                        visible: true,
                        // tickInterval: 5,
                        gridLineDashStyle: "longdash",
                        title: {
                            text: "",
                        },
                        labels: {
                            formatter: function () {
                                return DecimalValueFormat({ num: this.value, prefix: "₹", decimals: 2, });
                            },
                        },
                        gridLineColor: "var(--grey1)",
                    },
                    xAxis: {
                        // categories: Object.keys(series).map(d => moment(d).get('year')),
                        categories: selected?.data?.map((k) => moment(k?.date).format("MMM YY")),
                        lineColor: "var(--grey1)",
                        tickColor: "var(--grey1)",
                    },
                    legend: {
                        enabled: false,
                    },
                    plotOptions: {
                        series: {
                            // lineWidth: 1.5,
                            dataLabels: {
                                enabled: true,
                            },
                            label: {
                                connectorAllowed: false,
                            },
                            marker: {
                                enabled: true,
                            },
                        },
                    },
                    tooltip: {
                        // shared: true,
                        // valuedecimals: 1,
                        enabled: false,
                        // valuePrefix: '₹',
                    },
                    credits: {
                        enabled: false,
                    },
                    boost: {
                        useGPUTranslations: true,
                    },
                    series: [
                        {
                            data: selected?.data?.map((k) => k?.fe_median),
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    return DecimalValueFormat({ num: this.y, prefix: "₹", decimals: 2, });
                                },
                            },
                        },
                    ],
                    scrollbar: {
                        enabled: false,
                    },
                    responsive: {
                        rules: [
                            {
                                condition: {
                                    maxWidth: 400,
                                },
                            },
                        ],
                    },
                };
            } catch (error) {
                //console.log("SDF", error)
            }
            if (!Object.keys(data).length) {
                return (
                    <Card style={{ height: "20rem", width: "100%", textAlign: "center" }} bodyStyle={{ width: "100%", height: "100%" }} >
                        <NoDataFound message={`Seems like this stock doesn't have any active financial estimates by analysts or consolidated estimates are not available.`} msgMaxWidth='60ch' />
                    </Card>
                );
            }
            return (
                <div>
                    <div style={{ display: "flex", flexDirection: window.innerWidth > 760 ? "row" : "column", gap: "1rem", }}>
                        <div style={{ flex: 1 }} className="textSM w-500 black">
                            Earnings revisions in the last 12 months
                        </div>
                        <div className="textSM w-500 black">
                            EPS estimate for FY end:
                            <Select options={options2} value={selected.value} onChange={(m) => setSelected(options2.find((o) => o.value === m))} style={{ marginLeft: "0.5rem" }} />
                        </div>
                    </div>
                    <Card bodyStyle={{ display: "flex", flexDirection: "column", justifyContent: "space-around", padding: '1.25rem', }} style={{
                        border: "1px solid var(--grey2)",
                        marginTop: "1.25rem"
                    }}>
                        <div style={{ maxWidth: "100%" }}>
                            <HighchartsReact highcharts={Highcharts} options={conf} />
                        </div>
                    </Card>
                </div>
            );
        };
        switch (type) {
            case 0: return <Recommendation data={x} />;
            case 1: return <TargetPrice data={x} />;
            case 2: return <Financial data={x} />;
            case 3: return <Revisions data={x} />;
            default: return <></>;
        }
    };

    return (
        <div>
            <StickyScrollTabs isAccordionInMob queriesToStickToBot={['#sharpelyHeader', '#customTabsBarId']} tabData={[
                {
                    heading: "Analyst Recommendation",
                    subHeading: "Break up of recommendations provided the sell-side analysts split across Strong Buy, Buy, Hold, Sell and Strong Sell",
                    key: 0,
                    children: (
                        <AccessComponent component={<Estimates x={JSON.parse(estimates?.recommendations || "{}")} type={0} />}
                            upgradeComponent={<UpgradeToPro height="70%" width="100%" margin='1rem auto' moduleKey="ADVANCE_STOCK_REPORT" />}
                            moduleKey="ADVANCE_STOCK_REPORT" />
                    ),
                    show: true,
                    value: "Recommendation",
                    rightLinkText: "Why is analyst recommendation meaningful?",
                    rightTextLinkonClick: () => {
                        dispatch(toggleUserGuideModal({
                            value: true, type: "kbArticle", params: {
                                article: "does-analyst-recommendation-on-stocks-hold-any-importance",
                                title: "Does analyst recommendation on stocks hold any importance?"
                            }
                        }))
                    }
                },
                {
                    heading: "Target Price",
                    subHeading: "Look at 12-month target price by analysts. We look at median, average, highest and lowest target prices along with potential upside/downside from current levels.",
                    rightLinkText: "Target Price – A case study",
                    rightTextLinkonClick: () => {
                        dispatch(toggleUserGuideModal({
                            value: true, type: "kbArticle", params: {
                                article: "does-analyst-target-price-on-stocks-hold-any-importance",
                                title: "Does analyst target price on stocks hold any importance?"
                            }
                        }))
                    },
                    key: 1,
                    children: (
                        <AccessComponent component={<Estimates x={JSON.parse(estimates?.target_price || "{}")} type={1} />}
                            upgradeComponent={<UpgradeToPro height="70%" width="100%" margin='1rem auto' moduleKey="ADVANCE_STOCK_REPORT" />}
                            moduleKey="ADVANCE_STOCK_REPORT" />
                    ),
                    show: true,
                    value: "TargetPrice",
                },
                {
                    heading: "Financial Estimates",
                    subHeading: "Forward looking estimates (EPS and Sales). We look at next 2 financial years on a rolling basis.",
                    key: 2,
                    children: (
                        <AccessComponent component={<Estimates x={JSON.parse(estimates?.fin_est || "{}")} type={2} />}
                            upgradeComponent={<UpgradeToPro height="70%" width="100%" margin='1rem auto' moduleKey="ADVANCE_STOCK_REPORT" />}
                            moduleKey="ADVANCE_STOCK_REPORT" />
                    ),
                    show: true,
                    value: "Financials",
                },
                {
                    heading: "Earnings Revision",
                    subHeading: "This section analyses how the consensus EPS estimate for the company has moved in the last 12 months",
                    key: 3,
                    rightLinkText: "Why is this important?",
                    rightTextLinkonClick: () => {
                        dispatch(toggleUserGuideModal({
                            value: true, type: "kbArticle", params: {
                                article: "is-earnings-upgrade-by-analysts-an-important-metric-for-stock-selection",
                                title: "Is earnings upgrade by analysts an important metric for stock selection?"
                            }
                        }))
                    },
                    children: (
                        <AccessComponent component={<Estimates x={JSON.parse(estimates?.revisions || "{}")} type={3} />}
                            upgradeComponent={<UpgradeToPro height="70%" width="100%" margin='1rem auto' moduleKey="ADVANCE_STOCK_REPORT" />}
                            moduleKey="ADVANCE_STOCK_REPORT" />
                    ),
                    show: true,
                    value: "Revisions",
                },
            ]} />
        </div>
    )
})