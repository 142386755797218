import { createSlice } from '@reduxjs/toolkit'

export const prevstockpricesSlice = createSlice({
  name: 'prevstockprices',
  initialState: {
    value: [],
  },
  reducers: {
    updatePrevStockPrices: (state, action) => {
      if (action.payload === "close") {
        state.value = action.payload
      } else {
        state.value = {
          ...state.value,
          ...action.payload
        }
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { updatePrevStockPrices } = prevstockpricesSlice.actions