import CustomSegmentedTab from 'Components/CustomSegmentedTab';
import { fetchFundData } from 'Libs/fetchFundData';
import { getIndexList } from "Libs/utils";
import AdminServicesPY from 'Services/api/AdminServicesPY';
import { Card, Progress, Select, Tooltip } from 'antd';
import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { HiChevronRight } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AMCIcon from '../AMCIcon';
import { DecimalValueFormat } from '../DecimalValueFormat';
import ListLoader from '../ListLoader';
import { CardHeader } from '../atoms/SmallComponents';

const Near52WeekLowHigh = React.memo(({ allStocks, allIndices, type }) => {

    const stockprices = useSelector((state) => state?.stockprices?.value)
    const prevstockprices = useSelector((state) => state?.prevStockPrices?.value)
    const [selectedIndex, setSelectedIndex] = React.useState('NIFTY50');
    const [indexConstituents, setIndexConstituents] = React.useState({});
    const [allFilteredFunds, setAllFilteredfunds] = React.useState([])
    const [load, setLoad] = React.useState(true);
    const [selectedTab, setSelectedTab] = React.useState('high');

    const indices = allIndices?.map((el, i) => {
        return {
            ...el,
            key: i,
            label: el?.IndexName,
            value: el?.IndexNameCaps
        }
    }).concat([{ key: "all", label: "All stocks", value: "all" }])

    const getChange = (current, high, low) => {
        if (selectedTab === 'high') {
            return ((high - current) / high) * 100
        }
        else return ((current - low) / low) * 100
    }

    const sortFunds = (arr, key) => {
        var arrr = [...arr]?.filter((i) => (i?.[key] !== undefined && !isNaN(i?.[key]) && i?.[key] !== null))
        arrr = arrr.sort((a, b) => Math.abs(a[key]) - Math.abs(b[key]))
        return arrr.slice(0, 5);
    }

    const loadData = async () => {
        try {
            let res = await AdminServicesPY.getIndexConstituents();
            setIndexConstituents(JSON.parse(res?.data))
            setLoad(false)
        } catch (error) {
            console.log(error);
            setLoad(false)
        }
    }

    const getFilteredData = async () => {
        var allfundsc = selectedIndex !== 'all' ? allStocks?.filter((el) => {
            let symbols = indexConstituents?.[selectedIndex]?.syms || [];
            return symbols?.includes(el?.symbol);
        }) : allStocks;
        // console.log(allfundsc)
        allfundsc = allfundsc.map((fund) => {
            var currentLTP = "-"
            var currentSymbolAllData = stockprices === "close" ? prevstockprices?.[fund?.gdf_symbol] : (stockprices?.[fund?.gdf_symbol] || prevstockprices?.[fund?.gdf_symbol])
            currentLTP = currentSymbolAllData?.LastTradePrice;
            var dayHigh = currentSymbolAllData?.High || null;
            var dayLow = currentSymbolAllData?.Low || null;

            if (currentSymbolAllData === undefined) {
                let ele = fund
                currentLTP = ele?.price || 0
            }

            const high = dayHigh !== null ? Math.max(fund['52w_high'], dayHigh) : fund['52w_high']
            const low = dayLow !== null ? Math.min(fund['52w_low'], dayLow) : fund['52w_low']
            if (currentLTP !== '-') {
                return {
                    ...fund,
                    "currentHigh": high,
                    "currentLow": low,
                    "percDiff": getChange(currentLTP, high, low),
                    "currentLTP": currentLTP
                }
            } else return {
                ...fund,
                "currentHigh": high,
                "currentLow": low,
                "percDiff": '-',
                "currentLTP": currentLTP
            }

        })
        setAllFilteredfunds(sortFunds(allfundsc?.filter((i) => i?.["currentLTP"] !== undefined && !isNaN(i?.["currentLTP"]) && i?.["currentLTP"] !== null && i?.["currentLTP"] !== '-'), 'percDiff'))
    }

    React.useEffect(() => {
        if (stockprices.length !== 0 && allStocks.length !== 0 && Object.keys(indexConstituents)?.length) {
            getFilteredData()
        }
    }, [stockprices, prevstockprices, selectedIndex, selectedTab, indexConstituents])

    React.useEffect(() => {
        setLoad(true)
        loadData()
    }, [])

    const tabs = [
        { key: 0, label: "52W High", value: "high" },
        { key: 1, label: "52W Low", value: "low" }
    ]

    return (
        <React.Fragment>
            <Card bordered={false} style={{ padding: 0 }} bodyStyle={{ padding: 0 }} className="mobCardBorderRadius0">
                <div className='pad20Container' style={{ paddingBottom: 0 }}>
                    <CustomSegmentedTab className='textXS' value={selectedTab} style={{ margin: '0 -0.5rem', width: 'calc(100% + 1rem)', maxWidth: 'calc(100% + 1rem)' }} onChange={(e) => {
                        setSelectedTab(e)
                    }} options={tabs} />
                </div>
                <div className='pad20Container' style={{ display: "flex", alignItems: "center", gap: "0.75rem" }}>
                    <CardHeader heading={selectedTab === 'high' ? 'Near 52 Week High' : 'Near 52 Week Low'} headingType={2} />
                    <div style={{}}>
                        <Select
                            style={{ width: "6.5rem" }}
                            value={selectedIndex}
                            onChange={(value) => {
                                setSelectedIndex(value)
                            }}
                            suffixIcon={<FiChevronDown size={12} />}
                            options={indices}
                            dropdownStyle={{
                                width: "15rem"
                            }}
                        />
                    </div>
                </div>
                {load ? <ListLoader /> :
                    <div style={{}}>
                        {
                            allFilteredFunds.map((f, i) => {
                                let per = ((f.currentLTP - f.currentLow) / (f.currentHigh - f.currentLow)) * 100;

                                return <Tooltip key={i} style={{}} title={type.value === 4 ?
                                    (<span className='textXS w-500'>
                                        <b>{type.value === 4 ? f?.proper_name : f?.basic_name?.replace(" - Direct Plan", "")}</b> is trading
                                        {selectedTab === 'high' ?
                                            f.percDiff === 0 ?
                                                " at it's 52 week high"
                                                : ` at ${DecimalValueFormat({ num: f.percDiff, suffix: "%", decimals: 2 }).replace("NaN", "0.00")} discount to it's 52 week high.`
                                            : f.percDiff === 0 ?
                                                " at it's 52 week low"
                                                : ` ${DecimalValueFormat({ num: f.percDiff, suffix: "%", decimals: 2 }).replace("NaN", "0.00")} above it's 52 week low.`}
                                    </span>) : ""} arrow={false}>
                                    <div key={i} className="borderAll" style={{ backgroundColor: i % 2 !== 0 ? 'var(--white)' : 'var(--grey4)' }}>
                                        <Link to={type.basePath + `${type.value === 4 ? '/stock-detail/' + f?.proper_name?.replaceAll(' ', '-') + '/' + f.symbol : '/fund-detail/' + f?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + f.plan_id}`}>
                                            <div className="pad20Side12VerContainer" style={{ display: "flex", gap: "0.5rem", alignItems: "center", width: "100%", overflow: "hidden" }} >
                                                <AMCIcon amcName={type.value === 4 ? f.symbol : f.amc_full_name} fundType={type.value} height='1.5rem' width="1.5rem"
                                                    bseTicker={f?.bse_ticker} />
                                                <div className="textXS black hoverBold" style={{ flex: 1, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                                                    {f?.symbol}
                                                </div>
                                                <div style={{}}>
                                                    <Progress percent={per} strokeColor='var(--dark3)' trailColor='var(--dark1)'
                                                        size={window.innerWidth <= 760 ? ['10rem', 10] : ['7rem', 6]} style={{ marginBottom: 0, marginRight: window.innerWidth <= 760 ? '' : '0', display: "flex", }} format={() => {
                                                            return <span className="textXS w-500 dark3" style={{ flex: 1, textAlign: "right" }}>
                                                                {
                                                                    selectedTab === 'high' ?
                                                                        f.percDiff === 0 ? <span style={{ color: 'var(--green)' }}>At high</span> : `-${DecimalValueFormat({ num: f.percDiff, suffix: "%", decimals: 2 }).replace("NaN", "0.00")}`
                                                                        : f.percDiff === 0 ? <span style={{ color: 'var(--red)' }}>At Low</span> : `${DecimalValueFormat({ num: f.percDiff, suffix: "%", decimals: 2 }).replace("NaN", "0.00")}`
                                                                }
                                                            </span>
                                                        }} />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </Tooltip>
                            })
                        }
                        <div style={{ color: "var(--primary)", cursor: "pointer", textAlign: "right" }}
                            className='textSM w-500 pad20Side12VerContainer' >
                            <Link to={`/market-watch?type=${selectedTab === 'high' ? "52w-high" : "52w-low"}`} className='d-flex align-items-center justify-content-end'>
                                See all <HiChevronRight />
                            </Link>
                        </div>
                    </div>
                }
            </Card>
        </React.Fragment>
    )
})

export default Near52WeekLowHigh;