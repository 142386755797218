import { Card, Modal } from "antd";
import { buysell } from "Assets/images";
import { changeModuleKey, toggleSubscribeModal as toggle, toggleloginModal, toggleLoginModalParams, toggleSubModalParams } from "Libs/redux/UserAuth/reduxSlice";
import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { MdCancel, MdOutlineLock } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

export default function SubscribeModal() {

    let user = useSelector((state) => state?.updateUserData?.value);
    let openSubscribeModal = useSelector((state) => state.toggleSubscribeModal?.open);
    let moduleKey = useSelector((state) => state.toggleSubscribeModal?.moduleKey);
    let subModalParams = useSelector((state) => state?.toggleSubscribeModal?.params);
    let isBeta = subModalParams?.isBeta;
    const dispatch = useDispatch();

    let toggleSubscribeModal = () => {
        dispatch(toggle(!openSubscribeModal))
    }

    let setModuleKey = (value) => {
        dispatch(changeModuleKey(value));
    }

    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    let accessObj = moduleKey ? (accessLevelData?.find((ele) => ele.ModuleKey === moduleKey) || {}) : {};
    if (subModalParams?.customAccessObj && Object.keys(subModalParams?.customAccessObj || {})?.length > 0) {
        accessObj = { ...accessObj, ...subModalParams.customAccessObj }
    }

    const navigate = useNavigate();

    const onClose = () => {
        setModuleKey('');
        toggleSubscribeModal();
        dispatch(toggleSubModalParams({}))
    }

    const CheckIcon = ({ color }) => (
        <AiFillCheckCircle style={{ color: "var(--green)", fontSize: "1.5rem" }} />
    )

    const CrossIcon = ({ }) => (
        <MdCancel style={{ color: "var(--red)", fontSize: "1.5rem" }} />
    )

    const renderLimit = (num, ele) => {
        let accessLimit = ele?.AceessDescription?.split('|')?.filter((el) => el).reverse()?.map((desc, m) => {
            return { Description: desc, AccessLevel: 4 - m }
        }) || [];
        if (ele.IsEnabled !== 1) return 'Coming soon';
        let el = accessLimit.find((jk) => jk.AccessLevel === num);
        if (el && !subModalParams?.hideDescription) {
            return el.Description;
        }
        else return <CheckIcon />
    }

    return (
        <React.Fragment>
            <Modal open={openSubscribeModal} centered closable={false} footer={null} onCancel={() => {
                if (!subModalParams?.isExtClickDisabled) {
                    onClose()
                }
            }} onOk={() => {
                if (!subModalParams?.isExtClickDisabled) {
                    onClose()
                }
            }} styles={{
                mask: {
                    zIndex: subModalParams?.isExtClickDisabled && !isBeta ? 999 : 2000,
                    background: subModalParams?.isExtClickDisabled ? "linear-gradient(to right bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5))" : "",
                    backdropFilter: subModalParams?.isExtClickDisabled ? "blur(3px)" : ""
                }
            }} wrapClassName={subModalParams?.isExtClickDisabled && !isBeta ? "subscribeModalWrapInd" : "subscribeModalWrap"}>
                <img loading="lazy"  alt="" src={buysell} style={{
                    border: "1px solid var(--dark1)", borderRadius: "var(--borderRadius)", width: "100%",
                    height: "15rem", objectFit: "cover"
                }} />
                {(moduleKey || accessObj?.ModuleName) && <div style={{ marginTop: "1.25rem" }}>
                    <div className="textLG w-700 black mb-2">{accessObj?.ModuleName}</div>
                    <div className='textSM w-500 dark4'>{accessObj?.ModuleDesc}</div>
                </div>}
                <div style={{ marginTop: "1.25rem", gap: "1rem", display: "grid", gridTemplateColumns: "repeat(4,1fr)" }}>
                    {plans.map((ele) => (
                        <Card key={ele.AccessLevel} style={{
                            padding: 0, width: "100%", border: user?.Client?.[0]?.SharpelyAccessLevel === ele.AccessLevel ? "" : "1px solid var(--grey1)", height: "100%",
                            alignSelf: "stretch", background: user?.Client?.[0]?.SharpelyAccessLevel === ele.AccessLevel ? "var(--grey3)" : "var(--white)"
                        }} hoverable bodyStyle={{ padding: "1rem", width: "100%", color: 'var(--black)', height: "100%" }}>
                            <div className="textMD w-500 text-center">{ele.Name}</div>
                            {Object.keys(accessObj)?.length > 0 && <div className='textXS text-center mt-1'>{
                                accessObj?.AccessLevel <= ele.AccessLevel ? renderLimit(ele.AccessLevel, accessObj) : <CrossIcon />
                            }</div>}
                            {user?.Client?.[0]?.SharpelyAccessLevel === ele.AccessLevel && <div className='textXS black text-center mt-1 w-500'>
                                Current plan
                            </div>}
                        </Card>
                    ))}
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "1rem", marginTop: "1.25rem" }}>
                    <button className='btnPrimary ' style={{ padding: isBeta ? "0.5rem 3rem" : undefined }} onClick={() => {
                        if (isBeta) {
                            window.open('https://sharpely.in')
                        }
                        else {
                            onClose();
                            navigate('/pricing')
                        }
                    }}>
                        Upgrade
                    </button>
                    {subModalParams?.isExtClickDisabled && !isBeta && <button className='btnWhite ' onClick={() => {
                        navigate(-1)
                    }}>
                        Maybe later
                    </button>}
                </div>
            </Modal>
        </React.Fragment>
    )
}

export const SubscribeIcon = ({ accessKey = '', navigateToPricing = false, customAccessObj = {} }) => {

    const user = useSelector((state) => state?.updateUserData?.value);
    const userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel || 0;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <MdOutlineLock color='var(--dark4)' size='1rem' cursor={'pointer'} onClick={() => {
            if (userAccesslevel === 0) {
                dispatch(toggleloginModal(true))
            }
            else if (accessKey) {
                dispatch(toggle(true));
                dispatch(changeModuleKey(accessKey))
            } else if (navigateToPricing) {
                navigate('/pricing');
            }
            else if (Object.keys(customAccessObj)?.length > 0) {
                dispatch(toggleSubModalParams({
                    customAccessObj: customAccessObj
                }))
                dispatch(toggle(true));
            }
        }} />
    )
}

export const SubscribeBtnSmall = ({ str = "Pro", accessKey = '', customAccessObj = {}, loginModalParams = {} }) => {

    const dispatch = useDispatch();
    const user = useSelector((state) => state?.updateUserData?.value);
    const userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel || 0;

    return (
        <button onClick={() => {
            if (userAccesslevel === 0) {
                dispatch(toggleLoginModalParams(loginModalParams));
                dispatch(toggleloginModal(true))
            }
            else {
                dispatch(changeModuleKey(accessKey))
                dispatch(toggleSubModalParams({
                    customAccessObj: customAccessObj
                }))
                dispatch(toggle(true));
            }
        }} className=" textSM w-500 btnWhite d-flex align-items-center" style={{ order: "unset", gap: "0.25rem", borderRadius: "var(--borderRadius)", cursor: "pointer" }}>
            <MdOutlineLock color='var(--dark4)' size='1rem' cursor={'pointer'} />{str}
        </button>
    )
}

export const PlanName = ({ accessLevel = 1 }) => {
    let names = { 0: "Pre-Login", 1: "Free", 2: "Lite", 3: "Pro", 4: "Black" }
    return <span>{names[accessLevel]}</span>
}

export const SubscribeModalText = ({ str = "Pro", showLockIcon = true, style = { display: "flex", gap: "0.3rem", alignItems: "center", cursor: "pointer" }, onClick, accessKey = '' }) => {
    const dispatch = useDispatch();
    return (
        <span style={{ ...style, cursor: "pointer" }} onClick={() => {
            dispatch(toggle(true))
            if (accessKey) {
                dispatch(changeModuleKey(accessKey))
            }
        }}>
            {showLockIcon && <MdOutlineLock color='var(--dark4)' size='1rem' cursor={'pointer'} />}
            {str}
        </span>
    )
}

export const SubscribeContentWrapper = ({ moduleKey = '', children, reqAccessLevel = 0, subModalParams = {}, onClick = () => { }, checkLimit = false, checkEqualLimit = false, usageCount,
    showLockIcon = false, lockIconContainerStyles = {}, preventDefault = false, limitIndex = 0, wrapClassName = '', wrapStyle = {}, wrapAttributes = {}, disabled = false,
    isDefaultLocked = false, openByDefault = false, loginModalParams = {} }) => {

    const user = useSelector((state) => state?.updateUserData?.value)
    const userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel || 0
    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    let modReqAccessLevel = reqAccessLevel;
    if (moduleKey) {
        modReqAccessLevel = accessLevelData.find((ele) => ele.ModuleKey === moduleKey)?.AccessLevel
    }
    const dispatch = useDispatch();

    return (
        <div onClick={() => {
            if (disabled) {
                return;
            }
            if (preventDefault) {
                onClick();
            }
            else {
                if (userAccesslevel === 0 && modReqAccessLevel > 0) {
                    dispatch(toggleLoginModalParams(loginModalParams));
                    dispatch(toggleloginModal(true))
                }
                else if ((modReqAccessLevel > userAccesslevel) || openByDefault) {
                    dispatch(changeModuleKey(moduleKey));
                    dispatch(toggleSubModalParams(subModalParams))
                    dispatch(toggle(true))
                }
                else if (checkLimit) {
                    let accessObj = accessLevelData.find((ele) => ele.ModuleKey === moduleKey);
                    let accessLimits = accessObj?.AccessLimit?.split('|')?.filter((el) => el).reverse()?.map((val, m) => {
                        return {
                            Limit: val?.split(', ')?.map((el) => {
                                if (el === 'Unlimited') return Infinity;
                                else return Number(el);
                            }), AccessLevel: 4 - m
                        }
                    }) || [];
                    let userAccess = accessLimits?.find((ele) => ele.AccessLevel === userAccesslevel);
                    console.log(userAccess, accessLimits, usageCount)
                    let check = checkEqualLimit ? userAccess?.Limit?.[limitIndex] >= usageCount : userAccess?.Limit?.[limitIndex] > usageCount
                    if (check) {
                        onClick();
                    }
                    else {
                        dispatch(changeModuleKey(moduleKey));
                        dispatch(toggleSubModalParams(subModalParams))
                        dispatch(toggle(true))
                    }
                }
                else {
                    onClick();
                }
            }
        }} style={{ position: "relative", ...wrapStyle }} className={wrapClassName} {...wrapAttributes} >
            {children}
            {((userAccesslevel < modReqAccessLevel) || isDefaultLocked) && showLockIcon && <div style={{ position: "absolute", top: 5, right: 5, ...lockIconContainerStyles }}>
                <MdOutlineLock style={{ fontSize: "0.875rem" }} />
            </div>}
        </div>
    )
}

export const useSubscribeMiddleware = () => {

    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    const user = useSelector((state) => state?.updateUserData?.value);
    const userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel || 0;
    const dispatch = useDispatch();

    const subscribeMiddle = ({ moduleKey = '', onNext = (() => { }), reqAccessLevel = 0, customAccessObj = {}, checkLimit = false, usageCount, preventDefault = false,
        limitIndex = 0, disabled = false, returnHasAccess = false, returnMaxLimit = false, getDetailedAccessData = false, loginModalParams = {} }) => {

        let modReqAccessLevel = reqAccessLevel;
        if (moduleKey) {
            modReqAccessLevel = accessLevelData.find((ele) => ele.ModuleKey === moduleKey)?.AccessLevel
        }
        if (disabled) {
            return;
        }
        if (preventDefault) {
            onNext();
        }
        else {
            if (getDetailedAccessData) {
                let accessObj = accessLevelData.find((ele) => ele.ModuleKey === moduleKey);
                let accessLimits = accessObj?.AccessLimit?.split('|')?.filter((el) => el).reverse()?.map((val, m) => {
                    return {
                        Limit: val?.split(', ')?.map((el) => {
                            if (el === 'Unlimited') return Infinity;
                            else return Number(el);
                        }), AccessLevel: 4 - m
                    }
                }) || [];
                let userAccess = accessLimits?.find((ele) => ele.AccessLevel === userAccesslevel);
                let result = {
                    minAccessLevel: accessObj?.AccessLevel,
                    maxLimit: checkLimit ? userAccess?.Limit?.[limitIndex] || 0 : undefined,
                    limitExhausted: checkLimit ? usageCount >= (userAccess?.Limit?.[limitIndex] || 0) : false,
                    hasAccess: checkLimit ? (userAccesslevel >= accessObj?.AccessLevel ? usageCount < (userAccess?.Limit?.[limitIndex] || 0) : false) : userAccesslevel >= accessObj?.AccessLevel,
                    remainingLimit: checkLimit ? usageCount >= (userAccess?.Limit?.[limitIndex] || 0) ? 0 : (userAccess?.Limit?.[limitIndex] || 0) - usageCount : undefined,
                    currentAccessLevel: userAccesslevel
                }
                return result
            }
            if (returnMaxLimit) {
                let accessObj = accessLevelData.find((ele) => ele.ModuleKey === moduleKey);
                let accessLimits = accessObj?.AccessLimit?.split('|')?.filter((el) => el).reverse()?.map((val, m) => {
                    return {
                        Limit: val?.split(', ')?.map((el) => {
                            if (el === 'Unlimited') return Infinity;
                            else return Number(el);
                        }), AccessLevel: 4 - m
                    }
                }) || [];
                let userAccess = accessLimits?.find((ele) => ele.AccessLevel === userAccesslevel);
                return userAccess?.Limit?.[limitIndex];
            }
            if (userAccesslevel === 0 && modReqAccessLevel > 0) {
                if (returnHasAccess) {
                    return false;
                }
                else {
                    dispatch(toggleLoginModalParams(loginModalParams));
                    dispatch(toggleloginModal(true));
                }
            }
            else if (modReqAccessLevel > userAccesslevel) {
                if (returnHasAccess) {
                    return false;
                }
                else {
                    dispatch(changeModuleKey(moduleKey));
                    dispatch(toggleSubModalParams({
                        customAccessObj
                    }))
                    dispatch(toggle(true))
                }
            }
            else if (checkLimit) {
                let accessObj = accessLevelData.find((ele) => ele.ModuleKey === moduleKey);
                let accessLimits = accessObj?.AccessLimit?.split('|')?.filter((el) => el).reverse()?.map((val, m) => {
                    return {
                        Limit: val?.split(', ')?.map((el) => {
                            if (el === 'Unlimited') return Infinity;
                            else return Number(el);
                        }), AccessLevel: 4 - m
                    }
                }) || [];
                let userAccess = accessLimits?.find((ele) => ele.AccessLevel === userAccesslevel);
                if (userAccess?.Limit?.[limitIndex] > usageCount) {
                    if (returnHasAccess) {
                        return true;
                    }
                    else {
                        onNext();
                    }
                }
                else {
                    if (returnHasAccess) {
                        return false;
                    }
                    else {
                        dispatch(changeModuleKey(moduleKey));
                        dispatch(toggleSubModalParams({
                            customAccessObj
                        }))
                        dispatch(toggle(true))
                    }
                }
            }
            else {
                if (returnHasAccess) {
                    return true;
                }
                else {
                    onNext();
                }
            }
        }
    }

    return subscribeMiddle
}

export const getUserAccessLimitFromAccessObj = ({ accessObj = {}, accessLevel = 0, limitIndex = 0 }) => {
    try {
        if (accessObj?.AccessLevel > accessLevel) {
            return 0;
        }
        let accessLimits = accessObj?.AccessLimit?.split('|')?.filter((el) => el).reverse()?.map((val, m) => {
            return {
                Limit: val?.split(', ')?.map((el) => {
                    if (el === 'Unlimited') return Infinity;
                    else return Number(el);
                }), AccessLevel: 4 - m
            }
        }) || [];
        let userAccess = accessLimits?.find((ele) => ele.AccessLevel === accessLevel) || {};
        return userAccess?.Limit?.[limitIndex];
    }
    catch (err) {
        console.log(err);
        return 0;
    }
}

const plans = [
    { Name: "Free", AccessLevel: 1, Type: "Login", Price: "₹0", Bg: "var(--grey3)", Color: "var(--black)" },
    { Name: "Lite", AccessLevel: 2, Type: "Paid", Price: "₹199", Bg: "var(--primary)", Color: "var(--white)" },
    { Name: "Pro", AccessLevel: 3, Type: "Paid", Price: "₹479", Bg: "var(--yellow)", Color: "var(--black)" },
    { Name: "Black", AccessLevel: 4, Type: "Paid", Price: "₹999", Bg: "var(--green)", Color: "var(--white)" }
]