import { Card, Divider, Skeleton } from "antd";
import { lightBgs, lightColors } from "Libs/utils";
import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { CardHeader } from "./atoms/SmallComponents";
import { CategoryIcon } from "./CategoryIcon";
import { NoDataFound } from "./NoDataFound";

const KnowledgeBaseCategories = ({ allCategories, loading }) => {
    const navigate = useNavigate();
    const comingSoon = ['Systematic Investing', 'Product Roadmap'];

    const isMobile = window.innerWidth <= 575;

    const Loader = () => {
        return (
            <Row lg={3} sm={2} xs={1} className='gx-3 gy-3'>
                {new Array(3)?.fill(0)?.map((ele, index) => (
                    <Col key={index + 1} >
                        <Card style={{ border: "1px solid var(--grey2)", flex: 1, minWidth: isMobile ? "24rem" : '15rem', cursor: "pointer" }} bodyStyle={{ padding: "0rem", width: "100%", height: "100%" }} >
                            <div className="w100 h100 d-flex">
                                <div style={{ padding: "1rem", flex: 1 }}>
                                    <div className="textSM w-500 dark4">
                                        <Skeleton.Button active style={{ height: "1.25rem", width: "7rem" }} />
                                    </div>
                                    <div className="textXS dark3 textEllipsis3" style={{ marginTop: "0.75rem" }}>
                                        <Skeleton.Button active style={{ height: "0.75rem", width: "12rem" }} />
                                        <div>
                                            <Skeleton.Button active style={{ height: "0.75rem", marginTop: 2, width: "5rem" }} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    alignItems: "center", justifyContent: "center", minWidth: "25%", borderRadius: 8, borderTopLeftRadius: 100,
                                    borderBottomLeftRadius: 100, backgroundColor: lightBgs[index],
                                }} className='d-flex align-items-cennter justify-content-center'>
                                    <Skeleton.Avatar style={{ width: 50, height: 50 }} active />
                                </div>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
        )
    }
    const renderItems = (s, e, heading) => (
        <>
            <Card style={{ background: "transparent" }} bodyStyle={{ padding: 0 }} className={`mobCardBorderRadius0 ${isMobile ? "leftRightPadContainer" : ""}`}>
                <div style={{ marginBottom: "0.5rem" }}>
                    <CardHeader heading={heading} headingType={2} />
                </div>
                {loading ? <Loader /> : allCategories.slice(s, e)?.length <= 0 ?
                    <NoDataFound message='Something went wrong. Please refresh this page or try again after some time.' /> :
                    <Row lg={3} sm={2} xs={1} className='gx-3 gy-3'>
                        {allCategories.slice(s, e).map((item, index) => (
                            <Col key={item.UID}>
                                <Card style={{ border: "1px solid var(--grey2)", flex: 1, minWidth: isMobile ? "24rem" : '15rem', cursor: "pointer" }} bodyStyle={{ padding: "0rem", width: "100%", height: "100%" }} onClick={() => { navigate(item?.Slug) }}>
                                    <div className="w100 h100 d-flex">
                                        <div style={{ padding: "1rem" }}>
                                            <div className="textSM w-500 dark4">{item.Category}</div>
                                            <div className="textXS dark3 textEllipsis3" style={{ marginTop: "0.5rem" }}>{item.Description}</div>
                                        </div>
                                        <div style={{
                                            backgroundColor: item.backgroundColor, alignItems: "center", justifyContent: "center", minWidth: "25%", borderRadius: 8, borderTopLeftRadius: 100,
                                            borderBottomLeftRadius: 100, backgroundColor: item?.type === "guru" ? "transparent" : lightBgs[index],
                                        }} className='d-flex align-items-cennter justify-content-center'>
                                            <div style={{ width: 50, height: 50 }}>
                                                <CategoryIcon title={item.Category} />
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>}
            </Card>
            <Divider style={{ margin: "0rem" }} />
        </>
    )

    return (
        <React.Fragment>
            {renderItems(0, 3, 'User Guides')}
            {renderItems(3, 6, 'Our Product')}
            {renderItems(6, Infinity, 'sharpely Academy')}
        </React.Fragment>
    )
}

export default KnowledgeBaseCategories;