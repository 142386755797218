import { Categories } from "Components/BlogComponents/Categories";
import { FeaturedBlogs } from "Components/BlogComponents/FeaturedBlogs";
import { formatDateMMMDDYYYY } from "Components/DateFormatter";
import { getBlogsData, getPrevNextBlogs } from "Components/getKnowledgeBaseData";
import Head from "Layout/Head";
import AdminServices from "Services/api/AdminServices";
import { Card, Skeleton } from 'antd';
import React, { useEffect, useState } from "react";
import { BsFacebook, BsPinterest, BsWhatsapp } from 'react-icons/bs';
import { FaSquareXTwitter } from "react-icons/fa6";
import { FcNext, FcPrevious } from 'react-icons/fc';
import { Link, useParams } from "react-router-dom";

export default function Blog() {

    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [loadingCategory, setLoadingCategory] = useState(true);
    const [blog, setBlog] = useState({});
    const [content, setContent] = useState([]);
    const [prevNext, setPrevNext] = useState({});
    const [categories, setCategories] = useState([])
    const [blogs, setBlogs] = useState([]);
    const [featuredBlogs, setFeaturedBlogs] = useState([]);
    let isDesktop = window.innerWidth > 760;

    const loadContent = async (url) => {
        let res = await AdminServices.readBlogFile(url);
        if (res.type) {
            setContent(res.data);
        }
        else setContent('');
    }

    const loadData = async () => {
        let res = await getBlogsData();
        console.log(res);
        if (res.type) {
            setCategories(res.categories || [])
            setBlogs(res.blogs || []);
            setFeaturedBlogs(res.featuredBlogs || [])
        }
        setLoadingCategory(false);
    }

    const loadBlog = async () => {
        let it = blogs?.filter(item => item?.URL?.toLowerCase()?.split('/')?.[2]?.replaceAll('_', '-') === params.blog?.toLowerCase())?.[0] || {}
        setBlog(it)
        await loadContent(it?.URL?.toLowerCase()?.split('/')?.[2]);
        let prne = await getPrevNextBlogs(params.blog);
        setPrevNext(prne);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        setLoadingCategory(true);
        loadData();
    }, []);

    useEffect(() => {
        if (blogs.length) {
            setLoading(true);
            loadBlog();
        }
    }, [params, blogs]);

    useEffect(() => {
        if (!loading) {
            document.getElementById('blog-container').innerHTML = content;
            setTimeout(() => {
                const anchorTags = document.getElementsByTagName("a");
                for (let i = 0; i < anchorTags.length; i++) {
                    const anchor = anchorTags[i];
                    const href = anchor.getAttribute("href");
                    let modifiedHref = href;
                    if (modifiedHref?.startsWith("https://beta.sharpely") || modifiedHref?.startsWith("https://dev.sharpely")) {
                        modifiedHref = modifiedHref?.replace(/\b(beta\.sharpely|dev\.sharpely)\b/g, 'sharpely')
                    }
                    if (modifiedHref?.startsWith("/knowledge-base") || modifiedHref?.startsWith("https://sharpely.in/knowledge-base")) {
                        modifiedHref = modifiedHref?.replace(/\.html/g, "")?.replace(/_/g, "-");
                    }
                    anchor.setAttribute("href", modifiedHref);
                }
            }, 100)
        };
    }, [loading, content])

    const Loader = () => {
        return (
            <>
                <Card bodyStyle={{ padding: isDesktop ? "2rem" : "1.25rem" }} headStyle={{ padding: isDesktop ? "1.25rem 2rem" : "1.25rem 1.25rem" }}
                    className='mobCardBorderRadius0' title={<div>
                        <Skeleton.Button style={{ height: "2rem", width: "30rem", maxWidth: "100%" }} active />
                        <br />
                        <Skeleton.Button style={{ height: "1rem", width: "15rem", maxWidth: "100%", marginTop: "1rem" }} active />
                    </div>}>
                    <div>
                        <Skeleton active paragraph={{ rows: 4 }} style={{ marginBottom: "1.25rem" }} />
                        <Skeleton active paragraph={{ rows: 4 }} />
                    </div>
                </Card>
            </>
        )
    }

    const SideLoader = () => {
        return (
            <Card bordered={false} className='mobCardBorderRadius0 rightContainer'>
                <div className="d-flex w100">
                    <Skeleton.Button style={{ width: "100%", height: "2rem" }} active className="flex-grow-1" />
                </div>
                {new Array(5)?.fill(1)?.map((ele, i) => (
                    <div className="d-flex align-items-center mt-3 w100" style={{ gap: "0.5rem" }} key={i}>
                        <Skeleton.Avatar active={i % 2 === 1} style={{ width: "1rem", height: "1rem" }} />
                        <Skeleton.Button active={i % 2 === 1} style={{ height: "1rem", width: "100%" }} className='flex-grow-1' />
                    </div>))}
            </Card>
        )
    }

    let blogTitle = params.blog?.replaceAll("-", " ")?.toLowerCase();

    return (
        <React.Fragment>
            <Head title={(blogTitle?.[0]?.toUpperCase() + blogTitle?.slice(1)) || "Blog"} description={blog?.Description || ""} image={blog?.ImageUrl || ""} />
            <div className='normalContainer'>
                <div className='mainContainer'>
                    {loading ? <div className='leftContainer'>
                        <Loader />
                    </div> :
                        <div className='leftContainer'>
                            <Card bordered={false} title={
                                <div>
                                    <h1 className='black' style={{ textOverflow: "unset", whiteSpace: "unset", display: "inline-block", whiteSpace: 'initial', }} >{blog.Title}</h1>
                                    <div style={{ color: "var(--dark3)" }} className='w-500 textSM'>
                                        by <span style={{ color: "var(--black)" }}>{blog.Author}</span> {formatDateMMMDDYYYY(blog?.UpdatedOn)}
                                    </div>
                                </div>
                            } bodyStyle={{ padding: isDesktop ? "2rem" : "1.25rem" }} headStyle={{ padding: isDesktop ? "1.25rem 2rem" : "1.25rem 1.25rem" }}
                                className='mobCardBorderRadius0'>
                                <div style={{}}>
                                    <div id='blog-container' className="ql-editor textMD" style={{ padding: '0px', height: "auto", flex: 1 }}>
                                    </div>
                                    <div style={{ flexShrink: 0, display: "flex", flexDirection: "row", gap: "1.25rem", marginTop: "1.25rem", justifyContent: "flex-end" }}>
                                        <a href={`https://www.facebook.com/sharer/sharer.php?u=https://sharpely.in${window.location.pathname}`} target="_blank" rel="noopener noreferrer">
                                            <div style={{ padding: "0.75rem", background: "#3b5998", }} className=''>
                                                <BsFacebook style={{ color: "var(--white)", borderRadius: "50%" }} size='1.5rem' className='' />
                                            </div>
                                        </a>
                                        <a href={`https://twitter.com/intent/tweet?&text=${blog.Title}&via=sharpely_in&url=https://sharpely.in${window.location.pathname}`} target="_blank" rel="noopener noreferrer">
                                            <div style={{ padding: "0.75rem", background: "black", "--hoverColor": "#00acee" }} className=''>
                                                <FaSquareXTwitter style={{ color: "var(--white)", borderRadius: "50%" }} size='1.5rem' className='' />
                                            </div>
                                        </a>
                                        <a href={`https://www.pinterest.com/pin/create/bookmarklet/?url=https://sharpely.in${window.location.pathname}&media=${blog?.ImageUrl}`} target="_blank" rel="noopener noreferrer">
                                            <div style={{ padding: "0.75rem", background: "#E60023", "--hoverColor": "#E60023" }} className=''>
                                                <BsPinterest style={{ color: "var(--white)", borderRadius: "50%" }} size='1.5rem' className='' />
                                            </div>
                                        </a>
                                        <a href={`https://api.whatsapp.com/send?text=Checkout this informative blog by sharpely https://sharpely.in${window.location.pathname}`} data-action="share/whatsapp/share" target="_blank">
                                            <div style={{ padding: "0.75rem", background: "#25D367", "--hoverColor": "#E60023" }} className=''>
                                                <BsWhatsapp style={{ color: "var(--white)", borderRadius: "50%" }} size='1.5rem' className='' />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </Card>
                            <Card bordered={false} bodyStyle={{ padding: isDesktop ? "2rem" : "1.25rem" }} className='mobCardBorderRadius0'>
                                <div className='d-flex justify-content-between prevNextCont'>
                                    {prevNext?.prev && (
                                        <Link to={prevNext?.prev?.Slug} >
                                            <div >
                                                <FcPrevious size='0.8rem' /> <span className='w-500 textXS'>PREVIOUS ARTICLE</span>
                                            </div>
                                            <h2 className={`${isDesktop ? "textXL" : "textLG"} w-700 margin0`} style={{ display: "inline-block", whiteSpace: 'initial' }}>
                                                {prevNext?.prev?.Title}
                                            </h2>
                                        </Link>
                                    )}
                                    {prevNext.next && (
                                        <Link to={prevNext?.next?.Slug} >
                                            <div>
                                                <span className='w-500 textXS'>NEXT ARTICLE</span> <FcNext size='0.8rem' />
                                            </div>
                                            <h2 className={`${isDesktop ? "textXL" : "textLG"} w-700 margin0`} style={{ display: "inline-block", whiteSpace: 'initial' }}>
                                                {prevNext?.next?.Title}
                                            </h2>
                                        </Link>
                                    )}
                                </div>
                            </Card>
                        </div>
                    }
                    {loadingCategory ? <div className='rightContainer'>
                        <SideLoader />
                    </div> :
                        <div className='rightContainer'>
                            <Categories categories={categories} />
                            <FeaturedBlogs featuredBlogs={featuredBlogs} showDivider={false} />
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
