import { Collapse } from 'antd';
import React from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

export default function ReadMoreText({ text, className = "", onViewChange = () => { }, showCharacters = 130 }) {

    const [isReadMore, setIsReadMore] = React.useState(true);
    React.useEffect(() => {
        onViewChange(!isReadMore)
    }, [isReadMore])

    if (!text) {
        return ''
    }
    if (text.length < showCharacters + 20) {
        return text
    }
    else return <>
        <span className={className} >{isReadMore ? text?.slice(0, showCharacters) + "..." : text}</span>
        <span className={className} onClick={(e) => { e.stopPropagation(); setIsReadMore(!isReadMore) }} style={{ color: 'var(--primary)', fontWeight: "500", whiteSpace: "nowrap", cursor: 'pointer' }}>
            {isReadMore ? " Read More " : " Read Less "}{isReadMore ? <BsChevronDown size={12} /> : <BsChevronUp size={12} />}
        </span>
    </>
}

export const CollapseText = ({ triggerText = 'What does this mean?', triggerStyles = {}, content = '' }) => {

    return (
        <React.Fragment>
            <Collapse accordion bordered={false} style={{ background: "var(--white)" }} ghost expandIcon={() => ''} className="collapseText" expandIconPosition="end" items={[{
                key: 0,
                label: <div style={{ color: "var(--primary)", cursor: "pointer", userSelect: "none", textAlign: "", ...triggerStyles, marginLeft: "-16px", }} className='textXS w-500'>
                    {triggerText}
                </div>,
                children: content
            }]} />
        </React.Fragment>
    )
}