import AMCIcon from 'Components/AMCIcon';
import AccessControlledPage from 'Components/AccessControlledPage';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import BuySellSwitch from 'Components/BuySellSwitch';
import { CurrencyFormat } from 'Components/CurrencyFormat';
import { formatDate, formatDateyyyymmdd } from 'Components/DateFormatter';
import { DecimalValueFormat } from 'Components/DecimalValueFormat';
import RedGreenText from 'Components/RedGreenText';
import MyTable from 'Components/TableWithScroll';
import { CardHeader } from 'Components/atoms/SmallComponents';
import { useIsMobile } from 'CustomHooks/useIsMobile';
import Head from 'Layout/Head';
import { arePropsEqual, screenerMetricData, useGetAssetType } from 'Libs/utils';
import apiHelperPY from 'Services/api/ApiHelperPY';
import { StockLivePrice } from 'Services/api/LivePricesWebSocket';
import { Card, DatePicker, Divider, Select, Space, Switch, Tooltip } from 'antd';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import React from 'react';
import { IoMdRefresh } from 'react-icons/io';
import { Col, Row } from 'reactstrap';

const SectionChart = React.memo(({ chartData = [], xAxisKey, yAxisKey = 'security_value', height = 300, seriesName = '', showBuySellToggle = false, isCompany = false, from_date, to_date }) => {

    const [isSell, setIsSell] = React.useState(false);
    const [ignoreSmallerValues, setIgnoreSmallerValues] = React.useState(true);

    const defChartData = React.useMemo(() => {
        let data = [...chartData];
        if (showBuySellToggle) {
            data = data?.filter((el) => {
                if (isSell) {
                    return el?.[yAxisKey] < 0
                }
                else return el?.[yAxisKey] >= 0
            })?.sort((a, b) => Math.abs(b?.[yAxisKey]) - Math.abs(a?.[yAxisKey])) || [];
            if (ignoreSmallerValues) {
                data = data?.filter((el) => {
                    return Math.abs(el?.[yAxisKey]) >= 1
                })
            }
        }
        else {
            if (ignoreSmallerValues) {
                data = data?.filter((el) => {
                    return (el?.buy >= 50) || (el?.sell >= 50)
                })
            }
            data.sort((a, b) => b.buy - a.buy)
        }
        return data;
    }, [isSell, ignoreSmallerValues, chartData])

    const config = {
        chart: {
            type: 'bar',
            height: (defChartData?.length * (showBuySellToggle ? 40 : 60)),
            background: "transparent"
        },
        title: {
            text: ""
        },
        xAxis: {
            categories: defChartData.map((el) => el?.[xAxisKey])
        },
        yAxis: {
            visible: false
        },
        credits: {
            enabled: false,
        },
        scrollbar: {
            enabled: true,
        },
        exporting: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                pointWidth: showBuySellToggle ? 30 : 20,
                groupPadding: 0.1
            }
        },
        legend: {
            enabled: false
        },
        tooltip: {
            shared: true,
            formatter: function () {
                if (showBuySellToggle) {
                    return `<span>
                    <span>${this?.x}</span><br/><span>${isSell ? "Sell" : "Buy"}: 
                    <b>${CurrencyFormat(Math.abs(this?.y), 'long')} Cr</b></span>
                    </span>`;
                }
                else {
                    return `<span>
                    <span>${this?.x}</span><br/><span>Buy: 
                    <b>${CurrencyFormat(Math.abs(this?.points?.[0]?.y))} Cr</b></span><br/><span>Sell: 
                    <b>${CurrencyFormat(Math.abs(this.points?.[1]?.y))} Cr</b></span>
                    </span>`;
                }
            }
        },
        series: showBuySellToggle ? [
            {
                name: seriesName,
                data: defChartData?.map((el) => el?.[yAxisKey]),
                dataLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'normal',
                        textOutline: false
                    },
                    formatter: function () {
                        let num = this.y;
                        return DecimalValueFormat({ num: num, decimals: 2 })
                    }
                },
                color: isSell ? "var(--red)" : "var(--green)"
            }
        ] : [
            {
                name: "Buy",
                data: defChartData?.map((el) => el?.buy),
                color: 'var(--green)',
                dataLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'normal',
                        textOutline: false
                    },
                    formatter: function () {
                        let num = this.y;
                        return DecimalValueFormat({ num: num, decimals: 2 })
                    }
                },
            },
            {
                name: "Sell",
                data: defChartData?.map((el) => el?.sell),
                color: 'var(--red)',
                dataLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'normal',
                        textOutline: false
                    },
                    formatter: function () {
                        let num = this.y;
                        return DecimalValueFormat({ num: num, decimals: 2 })
                    }
                },
            }
        ]
    }

    return (
        <div>
            <div className='mb-3'>
                <CardHeader heading={`${seriesName} wise buying/selling activity (in Rs Cr.)`} headingType={3}
                    textClassName='textSM w-500 dark4' />
            </div>
            <div className='d-flex align-items-center justify-content-between mb-4' style={{ gap: "0.75rem" }}>
                <div className='textXS w-500 dark3'>
                    {moment(from_date).format('DD MMM,YYYY')} - {moment(to_date).format('DD MMM,YYYY')}
                </div>
                <div style={{ justifySelf: "flex-end" }}>
                    <Tooltip title={`Toggle this switch to ignore values lower than Rs. ${isCompany ? "1" : "50"} Cr.`} arrow={false}>
                        <div className='d-flex align-items-center justify-content-center' style={{ gap: "0.75rem", cursor: "pointer", width: "fit-content" }}>
                            <span className='textXS w-500 dark4'>Ignore smaller values</span>
                            <Switch size='small' value={ignoreSmallerValues} onChange={(e) => {
                                setIgnoreSmallerValues(e)
                            }} />
                        </div>
                    </Tooltip>
                </div>
            </div>
            {showBuySellToggle && <div className='mb-3'>
                <BuySellSwitch value={isSell} onChange={(e) => {
                    setIsSell(e.target.checked);
                }} />
            </div>}
            <div style={{ maxHeight: height, overflowY: "scroll" }} className='customScrollbar scrollbox'>
                <HighchartsReact highcharts={Highcharts} options={config} />
            </div>
        </div>
    )
}, arePropsEqual)

const TransactionTrendChart = React.memo(({ monthly_buys = [], monthly_sells = [], height = 300 }) => {

    const isMobile = useIsMobile();

    const chartData = React.useMemo(() => {
        let monthMarkers = [...(monthly_buys?.map((el) => el?.month_marker)), ...(monthly_sells?.map((el) => el?.month_marker))];
        monthMarkers = [...(new Set(monthMarkers))];
        let data = [];
        for (let i = 0; i < monthMarkers?.length; i++) {
            let time = monthMarkers[i];
            let temp = {};
            let buy = monthly_buys?.find((el) => el?.month_marker === time);
            if (buy) {
                buy = buy?.security_value;
            }
            else {
                buy = 0;
            }
            let sell = monthly_sells?.find((el) => el?.month_marker === time);
            if (sell) {
                sell = sell?.security_value;
            }
            else {
                sell = 0;
            }
            let year = String(time)?.substring(0, 4);
            let month = String(time)?.substring(4);
            let date = new Date();
            date.setFullYear(Number(year));
            date.setMonth(Number(month) - 1);
            date = moment(date).format('MMM, YYYY');
            temp = {
                date, buy, sell
            }
            data.push(temp);
        }
        data?.sort((a, b) => {
            return new Date(moment(a.date)).getTime() - new Date(moment(b.date)).getTime()
        })
        return data;
    }, [monthly_buys, monthly_sells]);

    const config = {
        chart: {
            type: 'column',
            background: "transparent",
            height: height
        },
        title: {
            text: ""
        },
        xAxis: {
            categories: chartData.map((el) => el?.date),
        },
        yAxis: {
            visible: true,
            title: {
                text: ""
            },
            gridLineDashStyle: "dash"
        },
        credits: {
            enabled: false,
        },
        scrollbar: {
            enabled: true,
        },
        exporting: {
            enabled: false,
        },
        plotOptions: {
            column: {
                grouping: false,
                pointPadding: 0,
                borderWidth: 0,
            }
        },
        tooltip: {
            shared: true,
            formatter: function () {
                return `<span>
                    <span>${this?.x}</span><br/><span>Buy: <b>${CurrencyFormat(Math.abs(this?.points?.[0]?.y))} Cr</b></span><br/><span>Sell: <b>${CurrencyFormat(Math.abs(this.points?.[1]?.y))} Cr</b></span>
                </span>`;
            }
        },
        legend: {
            enabled: true
        },
        series: [
            {
                name: 'Buy',
                data: chartData?.map((el) => el?.buy),
                color: "var(--green)",
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return `<span style="font-size:10px;font-weight:500">${CurrencyFormat(this.y, 'long', 0)}</span>`
                    }
                }
            },
            {
                name: 'Sell',
                data: chartData?.map((el) => -1 * el?.sell),
                color: "var(--red)",
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return `<span style="font-size:10px;font-weight:500">${CurrencyFormat(this.y, 'long', 0)}</span>`
                    }
                }
            }
        ]
    }

    return (
        <div>
            <div className='mb-3'>
                <CardHeader heading={`Month wise buying/selling activity (in Rs Cr.)`} headingType={3}
                    textClassName={isMobile ? "textSM w-500 dark4" : 'textMD w-500 dark4'} />
            </div>
            <div >
                <HighchartsReact highcharts={Highcharts} options={config} />
            </div>
        </div>
    )
}, arePropsEqual)

function InsiderTrades() {

    let insider_trading_moa_all = ['Market Purchase', 'Market Sale', 'Bonus', 'Market', 'Buy Back', 'Rights', 'Rights Issue', 'Private Placement', 'Off Market',
        'Block Deal', 'Open Offer', 'Allotment', 'Conversion of security', 'ESOP', 'ESOS', 'Inter-se Transfer', 'Invocation Of Pledged',
        'Preferential Offer', 'Public Right', 'Scheme of Amalgamation/Merger/Demerger/Arrangement', 'Scheme Of Amalgamation']

    let insider_trading_moa_default = ['Market Purchase', 'Market Sale', 'Market', 'Buy Back', 'Rights', 'Rights Issue', 'Private Placement', 'Off Market',
        'Block Deal', 'Open Offer', 'Conversion of security', 'Inter-se Transfer', 'Preferential Offer',]

    let insider_trading_tx_type_all = ['Acquisition', 'Invoke', 'Disposal', 'Pledge', 'Revoke', 'Buy', 'Sell', 'Pledge Revoke']

    let insider_trading_tx_default = ['Acquisition', 'Disposal', 'Buy', 'Sell']

    let security_type_all = ['Equity Shares', 'Warrants', 'Non Convertible Debentures', 'Debentures', 'Convertible Debentures']

    let security_type_default = ['Equity Shares']

    let acquirer_category_all = ['Promoter Group', 'Promoter', 'Connected Person', 'Designated Person', 'Director', 'Directors Immediate Relative', 'KMP',
        'Employee', 'Other', 'Promoters Immediate Relative', 'Promoter & Director', 'Employees Immediate Relative', 'Immediate Relative', 'Trust',
        'Member of Promoter Group', 'Promoter and Director']

    let acqurier_category_default = ['Promoter Group', 'Promoter', 'Connected Person', 'Designated Person', 'Director', 'Directors Immediate Relative', 'KMP',
        'Employee', 'Other', 'Promoters Immediate Relative', 'Promoter & Director', 'Employees Immediate Relative', 'Immediate Relative', 'Trust',
        'Member of Promoter Group', 'Promoter and Director']

    const stockType = useGetAssetType(4);
    const isMobile = useIsMobile();
    const initStartDate = new Date();
    initStartDate.setMonth(initStartDate.getMonth() - 6);
    const [from_date, setFromDate] = React.useState(formatDateyyyymmdd(initStartDate));
    const [to_date, setToDate] = React.useState(formatDateyyyymmdd(new Date()));
    const [value1, setValue1] = React.useState(dayjs(from_date, "YYYY-MM-DD"));
    const [value2, setValue2] = React.useState(dayjs(to_date, "YYYY-MM-DD"));
    const [acquiringModes, setAquiringModes] = React.useState(insider_trading_moa_default);
    const [transactionTypes, setTransactionTypes] = React.useState(insider_trading_tx_default);
    const [securityTypes, setSecurityTypes] = React.useState(security_type_default);
    const [acquirerCategory, setAcquirerCategory] = React.useState(acqurier_category_default);
    const [sectors, setSectors] = React.useState([])
    const [industryGroups, setIndustryGroups] = React.useState([])
    const [industries, setIndustries] = React.useState([])
    const [allFunds, setAllFunds] = React.useState([]);
    const [insiderTradeData, setInsiderTradeData] = React.useState({});
    const [insiderTradeTable, setInsiderTradeTable] = React.useState([]);
    const [ignoreSmallerValues, setIgnoreSmallerValues] = React.useState(false);
    const [previousParams, setPreviousParams] = React.useState({});
    const [initialLoading, setInitialLoading] = React.useState(true);
    const [dashboardLoading, setDashboardLoading] = React.useState(true);

    const disabledStartDate = React.useCallback((d) => {
        if (d == null) {
            return null;
        }
        return (
            !(to_date != null && d.isBefore(to_date) && !d.isSame(to_date, 'day'))
        );
    }, [to_date]);

    const disabledEndDate = React.useCallback((d) => {
        if (d == null) {
            return null;
        }
        return (
            !(from_date != null && d.isAfter(from_date) && !d.isSame(from_date, 'day'))
        );
    }, [from_date]);

    const isDataReloadRequired = React.useMemo(() => {
        let currParams = {
            from_date: from_date, to_date: to_date, moa_filter: acquiringModes, security_type_filter: securityTypes,
            acquirer_category_filter: acquirerCategory, exchange_code: null, index_code: null
        }
        return (Object.keys(previousParams)?.length > 0 && (JSON.stringify(previousParams) !== JSON.stringify(currParams)))
    }, [previousParams, from_date, to_date, acquiringModes, securityTypes, acquirerCategory]);

    const loadInitialData = async () => {
        setInitialLoading(true);
        let res = await screenerMetricData(stockType);
        setAllFunds(res?.allFunds)
        setSectors(res?.fabricatedData?.sectors);
        setIndustryGroups(res?.fabricatedData?.industries);
        setIndustries(res?.fabricatedData?.basicIndustries);
        setInitialLoading(false);
    }

    const loadInsiderTradeData = async () => {
        const params = {
            from_date: from_date, to_date: to_date, moa_filter: acquiringModes, security_type_filter: securityTypes,
            acquirer_category_filter: acquirerCategory, exchange_code: null, index_code: null
        }
        let res = await apiHelperPY({ apiName: "getInsiderDashboard", data: { params: JSON.stringify(params) } })
        if (res) {
            res = JSON.parse(res);
            console.log(res);
            setInsiderTradeData(res);
        }
        let res2 = await apiHelperPY({ apiName: "getInsiderTradesTable", data: { params: JSON.stringify(params) } });
        if (res2) {
            res2 = JSON.parse(res2);
            setInsiderTradeTable(res2);
        }
        setPreviousParams(params);
        setDashboardLoading(false);
    }

    const mainTableData = React.useMemo(() => {
        let mainTable = insiderTradeTable || [];
        mainTable = mainTable?.map((ele) => {
            let ob = allFunds?.find((el) => el?.symbol === ele?.symbol) || {}
            let mcap = ob?.mcap;
            mcap = Number(mcap) * 10000000
            let per_mcap = (ele?.security_value / mcap) * 100;
            return {
                ...ele,
                ...ob,
                per_mcap
            }
        })
        if (ignoreSmallerValues) {
            mainTable = mainTable?.filter((el) => el?.security_value > 1000000);
        }
        return mainTable;
    }, [insiderTradeTable, ignoreSmallerValues]);

    const sectorBuysChartData = React.useMemo(() => {
        let sectorCodes = [...((insiderTradeData?.group_sector_buys || [])?.map((el) => el?.sector_code)), ...((insiderTradeData?.group_sector_sells || [])?.map((el) => el?.sector_code))]
        sectorCodes = [...(new Set(sectorCodes))];
        sectorCodes = sectorCodes?.map((code) => {
            let sector_name = sectors?.find((ele) => ele?.value === code)?.label || '';
            let buy = insiderTradeData?.group_sector_buys?.find((el) => el?.sector_code === code);
            if (buy) {
                buy = buy?.security_value
            }
            else {
                buy = 0;
            }
            let sell = insiderTradeData?.group_sector_sells?.find((el) => el?.sector_code === code);
            if (sell) {
                sell = sell?.security_value
            }
            else {
                sell = 0;
            }
            return {
                sector_name, buy, sell, sector_code: code
            }
        })
        return sectorCodes;
    }, [insiderTradeData])

    const industryGrpBuysChartData = React.useMemo(() => {
        let indGrpCodes = [...((insiderTradeData?.group_industry_group_buys || [])?.map((el) => el?.industry_code)), ...((insiderTradeData?.group_industry_group_sells || [])?.map((el) => el?.industry_code))]
        indGrpCodes = [...(new Set(indGrpCodes))];
        indGrpCodes = indGrpCodes?.map((code) => {
            let industry_group_name = industryGroups?.find((ele) => ele?.value === code)?.label || '';
            let buy = insiderTradeData?.group_industry_group_buys?.find((el) => el?.industry_code === code);
            if (buy) {
                buy = buy?.security_value
            }
            else {
                buy = 0;
            }
            let sell = insiderTradeData?.group_industry_group_sells?.find((el) => el?.industry_code === code);
            if (sell) {
                sell = sell?.security_value
            }
            else {
                sell = 0;
            }
            return {
                industry_group_name, buy, sell, industry_code: code
            }
        })
        return indGrpCodes;
    }, [insiderTradeData])

    const industryBuysChartData = React.useMemo(() => {
        let indCodes = [...((insiderTradeData?.group_industry_buys || [])?.map((el) => el?.nse_basic_ind_code)), ...((insiderTradeData?.group_industry_sells || [])?.map((el) => el?.nse_basic_ind_code))]
        indCodes = [...(new Set(indCodes))];
        indCodes = indCodes?.map((code) => {
            let industry_name = industries?.find((ele) => ele?.value === code)?.label || '';
            let buy = insiderTradeData?.group_industry_buys?.find((el) => el?.nse_basic_ind_code === code);
            if (buy) {
                buy = buy?.security_value
            }
            else {
                buy = 0;
            }
            let sell = insiderTradeData?.group_industry_sells?.find((el) => el?.nse_basic_ind_code === code);
            if (sell) {
                sell = sell?.security_value
            }
            else {
                sell = 0;
            }
            return {
                industry_name, buy, sell, nse_basic_ind_code: code
            }
        })
        return indCodes;
    }, [insiderTradeData])

    const companyBuysChartData = React.useMemo(() => {
        let compData = insiderTradeData?.group_symbol_buys || [];
        compData = compData?.map((el) => {
            let company_name = allFunds?.find((ele) => ele?.symbol === el?.symbol)?.proper_name || "";
            return {
                ...el,
                company_name
            }
        })
        return compData;
    })

    React.useEffect(() => {
        loadInitialData();
    }, []);

    React.useEffect(() => {
        if (initialLoading === false) {
            loadInsiderTradeData();
        }
    }, [initialLoading]);

    const mainTableColumns = [
        {
            title: "Company name",
            key: "symbol",
            dataIndex: "symbol",
            render: (text, record, index) => <Tooltip title={record?.proper_name} arrow={false}>
                <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center" }}>
                    <AMCIcon amcName={record?.symbol} fundType={4} height='1.15rem' width="1.15rem" bseTicker={record?.bse_ticker} />
                    <div>
                        <div className="w-500 black" style={{ width: isMobile ? 100 : 200, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: isMobile ? "var(--textEXS)" : "" }}>
                            {record?.proper_name}
                        </div>
                        <StockLivePrice fundData={record} symbol={record?.symbol} format={10} />
                    </div>
                </div>
            </Tooltip>,
            fixed: "left",
            width: isMobile ? 180 : 250
        },
        {
            title: "Acquirer Name",
            key: "acquirer_name",
            dataIndex: "acquirer_name",
            // align: "right",
            width: isMobile ? 180 : 200
        },
        {
            title: "Transaction type",
            key: "transaction_type",
            dataIndex: "transaction_type",
            // align: "right",
            width: 150,
            filters: insider_trading_tx_type_all?.map((el) => { return { text: el, value: el } }),
            onFilter: (value, record) => String(record.transaction_type) === String(value)
        },
        {
            title: "Category",
            key: "acquirer_category",
            dataIndex: "acquirer_category",
            // align: "right",
            width: 150,
            filters: acquirer_category_all?.map((el) => { return { text: el, value: el } }),
            onFilter: (value, record) => String(record.acquirer_category) === String(value)
        },
        {
            title: "Exchange date",
            key: "exchange_broadcast_date",
            dataIndex: "exchange_broadcast_date",
            render: (text) => {
                return <span>{formatDate(text)}</span>
            },
            // align: "right",
            width: 150
        },
        {
            title: "Transaction value",
            key: "security_value",
            dataIndex: "security_value",
            render: (text) => {
                return DecimalValueFormat({ num: text, decimals: 0 })
            },
            align: "right",
            width: 150
        },
        {
            title: "Holding change (%)",
            key: "holding_change_pct",
            dataIndex: "holding_change_pct",
            align: "right",
            render: (text) => {
                return <RedGreenText text={DecimalValueFormat({ num: text, decimals: 2, suffix: "%" })} value={text} />
            },
            width: 150
        },
        {
            title: "Transaction value\n% Market cap",
            key: "per_mcap",
            dataIndex: "per_mcap",
            align: "right",
            render: (text, record) => {
                return DecimalValueFormat({ num: record?.holding_change_pct < 0 ? -1 * text : text, decimals: 2, suffix: "%" })
            },
            width: 150
        },
        {
            title: "Market cap (Cr)",
            key: "mcap",
            dataIndex: "mcap",
            align: "right",
            render: (text) => {
                return CurrencyFormat(text, 'long', 2)
            },
            width: 150
        }
    ]

    if (initialLoading) {
        return (
            <React.Fragment>
                <div className='loaderContainer'>
                    <ActivityLoaderMintbox showQuote />
                </div>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <AccessControlledPage moduleKey='INSIDER_TRADING_ACTIVITY' />
            <Head title='Insider trading dashboard' description="Track insider trades to spot market trends and investment opportunities with sharpely's Insider Trading Dashboard." />
            <div className='normalContainer'>
                <Card>
                    <div className='d-flex align-items-start'>
                        <CardHeader heading={'Insider trading activity'} headingType={1} />
                        {isDataReloadRequired && <button className='btnBlack d-flex align-items-center' style={{ gap: "0.5rem" }} onClick={() => {
                            loadInsiderTradeData();
                        }}>
                            <IoMdRefresh className={`${dashboardLoading ? "rotate" : ""}`} style={{ '--rotationTime': "1s" }} size={'1.25rem'} /> Reload
                        </button>}
                    </div>
                    <Divider style={{ margin: "1rem 0" }} />
                    <div>
                        <Row lg={4} md={3} sm={1} xs={1} className='gx-3 gy-3'>
                            <Col>
                                <div className='w100 h100'>
                                    <div className='textSM w-500 dark3 mb-2'>Select a date range:</div>
                                    <Space direction='horizontal'>
                                        <DatePicker style={{ padding: "0.37rem" }} disabledDate={disabledStartDate} placeholder='Start date' value={value1} format={'DD-MM-YYYY'} onChange={(val) => {
                                            setFromDate(formatDateyyyymmdd(new Date(val)))
                                            setValue1(val)
                                        }} />
                                        <DatePicker style={{ padding: "0.37rem" }} disabledDate={disabledEndDate} placeholder='End date' value={value2} format={'DD-MM-YYYY'} onChange={(val) => {
                                            setToDate(formatDateyyyymmdd(new Date(val)))
                                            setValue2(val)
                                        }} />
                                    </Space>
                                </div>
                            </Col>
                            <Col>
                                <div className='w100 h100'>
                                    <div className='textSM w-500 dark3 mb-2'>Select acquiring mode:</div>
                                    <Select showSearch={false} options={insider_trading_moa_all?.map((el, i) => { return { key: el, label: el, value: el } })}
                                        style={{ width: "100%" }} placeholder='Acquiring mode' value={acquiringModes} onChange={(e) => {
                                            setAquiringModes(e)
                                        }} mode="multiple" maxTagCount='responsive' />
                                </div>
                            </Col>
                            <Col>
                                <div className='w100 h100'>
                                    <div className='textSM w-500 dark3 mb-2'>Type of security:</div>
                                    <Select showSearch={false} options={security_type_all?.map((el, i) => { return { key: el, label: el, value: el } })}
                                        style={{ width: "100%" }} placeholder='Security type' mode='multiple' value={securityTypes} onChange={(e) => {
                                            setSecurityTypes(e)
                                        }} maxTagCount='responsive' />
                                </div>
                            </Col>
                            <Col>
                                <div className='w100 h100'>
                                    <div className='textSM w-500 dark3 mb-2'>Select acquirer category:</div>
                                    <Select showSearch={false} options={acquirer_category_all?.map((el, i) => { return { key: el, label: el, value: el } })}
                                        style={{ width: "100%" }} placeholder='Acquirer category' mode='multiple' value={acquirerCategory} onChange={(e) => {
                                            setAcquirerCategory(e)
                                        }} maxTagCount='responsive' />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {isMobile && <>
                        <div style={{ marginTop: "1.25rem" }}>
                            <button className='btnBlack d-flex align-items-center' style={{ gap: "0.5rem" }} onClick={() => {
                                setDashboardLoading(true);
                                loadInsiderTradeData();
                            }}>
                                <IoMdRefresh className={`${dashboardLoading ? "rotate" : ""}`} style={{ '--rotationTime': "1s" }} size={'1.25rem'} /> Reload
                            </button>
                        </div>
                    </>}
                </Card>
                {dashboardLoading ? <div className='mt-5'>
                    <ActivityLoaderMintbox showQuote />
                </div> : <Card>
                    <div style={{ marginBottom: "1.25rem" }}>
                        <Row lg={3} md={2} sm={1} xs={1} className='gx-3 gy-3'>
                            <Col lg={8} sm={16} xs={16}>
                                <div className='w100 h100 d-flex' style={{
                                    border: "1px solid var(--grey1)", padding: "1rem", borderRadius: "var(--borderRadius)",
                                    gap: "1rem", alignItems: "flex-start", flexDirection: isMobile ? "column" : "row"
                                }}>
                                    <div style={{
                                        gap: "1rem", paddingRight: isMobile ? "0" : "1rem", borderRight: isMobile ? "0" : "1px solid var(--grey1)", height: "100%",
                                        flexDirection: isMobile ? "row" : "column", paddingBottom: isMobile ? "1rem" : "0", borderBottom: !isMobile ? "0" : "1px solid var(--grey1)",
                                        width: isMobile ? "100%" : undefined
                                    }} className='d-flex'>
                                        <div style={{ padding: "1rem", borderRadius: "0.5rem", background: "var(--green)", flex: isMobile ? 1 : undefined }}>
                                            <div className='mb-2 textLGtoSM w-700 dark4'>{CurrencyFormat(insiderTradeData?.total_buy)}</div>
                                            <div className='textSM'>Total buy value (Cr)</div>
                                        </div>
                                        <div style={{ padding: "1rem", borderRadius: "0.5rem", background: "var(--red)", flex: isMobile ? 1 : undefined }}>
                                            <div className='mb-2 textLGtoSM w-700 dark4'>{CurrencyFormat(insiderTradeData?.total_sell)}</div>
                                            <div className='textSM'>Total sell value (Cr)</div>
                                        </div>
                                    </div>
                                    <div style={{ flex: 1, width: isMobile ? "100%" : undefined }}>
                                        <TransactionTrendChart monthly_buys={insiderTradeData?.monthly_buys} monthly_sells={insiderTradeData?.monthly_sells} />
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className='w100 h100' style={{ border: "1px solid var(--grey1)", padding: "1rem", borderRadius: "var(--borderRadius)" }}>
                                    <SectionChart chartData={sectorBuysChartData} xAxisKey={'sector_name'} seriesName='Sector' from_date={previousParams?.from_date} to_date={previousParams?.to_date} />
                                </div>
                            </Col>
                            <Col>
                                <div className='w100 h100' style={{ border: "1px solid var(--grey1)", padding: "1rem", borderRadius: "var(--borderRadius)" }}>
                                    <SectionChart chartData={industryGrpBuysChartData} xAxisKey={'industry_group_name'} seriesName='Industry group' from_date={previousParams?.from_date} to_date={previousParams?.to_date} />
                                </div>
                            </Col>
                            <Col>
                                <div className='w100 h100' style={{ border: "1px solid var(--grey1)", padding: "1rem", borderRadius: "var(--borderRadius)" }}>
                                    <SectionChart chartData={industryBuysChartData} xAxisKey={'industry_name'} seriesName='Industry' from_date={previousParams?.from_date} to_date={previousParams?.to_date} />
                                </div>
                            </Col>
                            <Col>
                                <div className='w100 h100' style={{ border: "1px solid var(--grey1)", padding: "1rem", borderRadius: "var(--borderRadius)" }}>
                                    <SectionChart chartData={companyBuysChartData} xAxisKey={'company_name'} seriesName='Company' showBuySellToggle isCompany from_date={previousParams?.from_date} to_date={previousParams?.to_date} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <div style={{ marginBottom: "1.25rem" }} className='d-flex justify-content-end'>
                            <Tooltip title='Toggle this switch to ignore transaction lower than Rs. 10 lakhs' arrow={false}>
                                <div className='d-flex align-items-center justify-content-center' style={{ gap: "0.75rem", cursor: "pointer" }}>
                                    <span className='textSM w-500 dark4'>Ignore smaller values</span>
                                    <Switch value={ignoreSmallerValues} onChange={(e) => {
                                        setIgnoreSmallerValues(e)
                                    }} />
                                </div>
                            </Tooltip>
                        </div>
                        <MyTable dataSource={mainTableData} columns={mainTableColumns} scroll={{ x: "max-content" }} defaultPagination={true} paginationSize={25} />
                    </div>
                </Card>}
            </div>
        </React.Fragment>
    )
}

export default InsiderTrades;