


import { Progress } from 'antd';
import { useGetAssetType } from 'Libs/utils';
import { BsFillTriangleFill } from 'react-icons/bs';
import React from 'react';

const StaticSlider = ({ min, max, currentVal, minStr, maxStr, currentValStr = "", minStrLabel = "", maxStrLabel = "" }) => {
    const [v, setv] = React.useState(currentVal)
    const type = useGetAssetType()
    const leftPercentage = ((currentVal - min) * 100 / (max - min))
    const color = 'var(--primaryPressed)'

    const marginLeft = leftPercentage < 10 ? 0 : leftPercentage > 90 ? 'calc(100% - 52px)' : `calc(${leftPercentage}% - 25px)`
    // const color = leftPercentage < 33 ? 'var(--red)' : leftPercentage < 66 ? 'var(--yellow)' : 'var(--green)'
    return <div style={{ flex: 1 }}>
        <div>
            <div style={{ textAlign: 'center', display: 'inline-block', marginLeft: marginLeft }}>
                <div className='textXS w-500 black' style={{ color: 'var(--primary)', whiteSpace: 'nowrap' }}>This {type.omkar}</div>
                <div className='textXS dark3' style={{ whiteSpace: 'nowrap' }}>{currentValStr}</div>
            </div>
        </div>
        <Progress percent={leftPercentage} showInfo={false}
            strokeColor={color} type="line" size={['100%', 8]}
        />
        {/* <Slider value={currentVal} disabled={true} min={min} max={max} step={0.01} color={color}/> */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <div className='textXS w-500 black' style={{ whiteSpace: 'nowrap' }}>{minStr}</div>
                <div className='textXS dark3' style={{ whiteSpace: 'nowrap' }}>{minStrLabel}</div>
            </div>
            <div>
                <div className='textXS w-500 black' style={{ textAlign: "right", whiteSpace: 'nowrap' }}>{maxStr}</div>
                <div className='textXS dark3' style={{ whiteSpace: 'nowrap' }}>{maxStrLabel}</div>
            </div>
        </div>
    </div>
}
export default StaticSlider

export const StaticSlider2 = ({ min, max, val, othdata, othText }) => {

    let percentage = ((val - min) / (max - min)) * 100;
    let othPer = ((othdata - min) / (max - min)) * 100;
    console.log(val);
    return <div>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0.5rem", position: "relative", }}>
            <div className='textXS w-500' style={{ whiteSpace: "nowrap" }}>Value {val}</div>
        </div>
        <div className='staticslider2'>
            <div className='staticSliderRail' style={{ '--width': `${percentage}%` }}></div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "0.5rem", position: "relative" }}>
            <div className='textXS w-500'>{min}</div>
            <div className='textXS w-500'>{max}</div>
        </div>
    </div>
}
