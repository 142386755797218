import { Checkbox, Select } from "antd";
import React, { useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import { SubscribeModalText } from "./SubscribeModal";
import { metricsToHideForCustomization } from "Libs/utils";

export const MetricSearch = ({ data = null, onChange, type, disable, finalDataCols, isMultiple, insType, checkFiltersList = [], disabledCols, functions = null, metrics = null }) => {

    var finalDataMetric = checkFiltersList
    let user = useSelector((state) => state?.updateUserData?.value)
    const userAccesslevel = user?.Client[0]?.SharpelyAccessLevel || 0;
    function isSubsequence(subseq, str) {
        let i = 0;
        for (const char of str) {
            if (char?.toLowerCase() === subseq[i]?.toLowerCase()) {
                i++;
                if (i === subseq.length) {
                    return true;
                }
            }
        }
        return false;
    }

    function customizeData(data) {
        let modInitData = data || []
        let j = modInitData?.filter((ele) => ele.isVisibleNormally === true);

        let t = [];
        for (let i = 0; i < j.length; i++) {
            let k = j[i]?.sub_category?.filter((ele) => ele?.isVisibleNormally !== false) || []
            if (type === 'customize') {
                k = k?.filter((ele) => ele?.isVisibleForCustomise);
            }
            t = [...t, ...k]
        }

        let mets = [];

        for (let i = 0; i < t.length; i++) {
            let k = t[i]?.metric?.filter((ele) => ele?.isVisibleNormally !== false) || [];
            if (type === 'customize') {
                k = k?.filter((ele) => ele?.hidden !== true && !(metricsToHideForCustomization.includes(ele?.metric_col_code)));
            }
            mets = [...mets, ...k]
        }

        let modInitMetrics = metrics || [];
        let othMets = modInitMetrics?.filter((ele) => ele?.hidden !== true);
        let modInitFunctions = functions || [];
        mets = [...mets, ...modInitFunctions, ...othMets];
        // console.log(mets);
        return mets
    }

    const [newData, setNewData] = useState(customizeData(data))
    // let newData = customizeData(data);

    React.useEffect(() => {
        setNewData(customizeData(data));
    }, [data, metrics, functions])

    return (
        <Select showSearch placeholder={<span className='w-normal'><BsSearch /> Search for metrics</span>}
            style={{ width: window.innerWidth < 760 ? "100%" : "20rem" }} onChange={(value) => {
                let it = newData.find((ele) => ele.value === value);
                onChange(it);
            }} filterOption={(input, option) => {
                return isSubsequence(input, option?.label)
            }} value={null} options={newData?.map((metric) => {
                const isDisabled = disabledCols?.length > 0 ? (disabledCols?.includes(metric?.metric_col_code)) : (metric?.dataIndex === "proper_name" || metric?.dataIndex === "basic_name" || metric?.dataIndex === "mcap" || metric?.dataIndex === "aum" || metric?.dataIndex === 'price')
                return {
                    key: metric?.value,
                    value: metric?.value,
                    disabled: userAccesslevel < metric.auth_flag ? true : disable ? isDisabled : false,
                    label: metric?.label,
                    metric: metric
                }
            })} optionRender={(option) => {
                let metric = option?.data?.metric || {}
                var itemIndex = finalDataMetric?.findIndex((met) => met.metric_col_code === metric.metric_col_code)
                var isPresentIndex = itemIndex !== -1
                const isDisabled = disabledCols?.length > 0 ? (disabledCols?.includes(metric?.metric_col_code)) : (metric?.dataIndex === "proper_name" || metric?.dataIndex === "basic_name" || metric?.dataIndex === "mcap" || metric?.dataIndex === "aum" || metric?.dataIndex === 'price')
                return (
                    <span className="d-flex align-items-center" >
                        {type === 'customize' && <Checkbox checked={finalDataCols?.filter((ele) => ele.metric_col_code === metric.metric_col_code)?.length === 1}
                            style={{ marginRight: "0.25rem" }} disabled={isDisabled} />}
                        {type === 'customize-signal' && <Checkbox style={{ marginRight: "0.25rem" }} checked={isPresentIndex === true} />}
                        {userAccesslevel < metric.auth_flag ? <SubscribeModalText str={metric?.label} accessKey={insType === 4 ? "STOCK_PROPRIETARY_METRICS" : "MF_ETF_PROPRIETARY_METRICS"} /> : metric?.label}
                    </span>
                )
            }} />
    )
}