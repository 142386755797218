import { Card, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { FcDocument, FcFolder } from "react-icons/fc";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CardHeader } from "./atoms/SmallComponents";
import { getKnowledgeBaseData } from "./getKnowledgeBaseData";

const ResourceSidebar2 = () => {

    const [category, setCategory] = useState({});
    const [categories, setCategories] = useState([]);
    const [subCategory, setSubCategory] = useState({});
    const [subCategories, setSubCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [amountToShow, setAmountToShow] = useState(5);
    const params = useParams();
    const navigate = useNavigate();

    const loadData = async () => {
        let res = await getKnowledgeBaseData();
        if (res.type) {
            setCategories(res.categories || []);
            setSubCategories(res?.subCategories || [])
        }
    }

    const setData = () => {
        setCategory(categories?.filter((item) => item.Category.split(' ').join('-').toLowerCase() === params.category)[0] || {});
        setSubCategory(subCategories?.filter((item) => item.SubCategory.split(' ').join('-').toLowerCase() === params.subCategory &&
            item.Category.split(' ').join('-').toLowerCase() === params.category)[0] || {});
        setLoading(false);
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (categories.length && subCategories.length) setData();
    }, [params, categories, subCategories]);

    useEffect(() => {
        setAmountToShow(5);
    }, [params.category, params.subCategory])

    const Loader = () => {
        return (
            <Card bordered={false} className='mobCardBorderRadius0 rightContainer'>
                <div className="d-flex w100">
                    <Skeleton.Button style={{ width: "100%", height: "2rem" }} active className="flex-grow-1" />
                </div>
                {new Array(5)?.fill(1)?.map((ele, i) => (
                    <div className="d-flex align-items-center mt-3 w100" style={{ gap: "0.5rem" }} key={i}>
                        <Skeleton.Avatar active={i % 2 === 1} style={{ width: "1rem", height: "1rem" }} />
                        <Skeleton.Button active={i % 2 === 1} style={{ height: "1rem", width: "100%" }} className='flex-grow-1' />
                    </div>))}
            </Card>
        )
    }

    return (
        <React.Fragment>
            <div className="rightContainer"
                style={{ height: "fit-content", alignSelf: "flex-start" }}
                id='sidebar'>
                {loading ? <Loader /> : <>
                    {subCategory?.UID && (
                        <React.Fragment>
                            <Card bordered={false} className='mobCardBorderRadius0'>
                                <CardHeader heading={subCategory.SubCategory} headingType={2} textClassName='textMD w-700' />
                                {subCategory?.Resources?.slice(0, amountToShow)?.map((item) => (
                                    <div key={item.UID} className='resCategoryItem' style={{ cursor: "pointer" }} onClick={() => {
                                        navigate(item.Slug)
                                    }} >
                                        <Link to={item.Slug} className='textSM w-500'>
                                            <FcDocument size='0.8rem' />
                                            <h3 className='margin0 textSM w-500'>{item?.Title}</h3>
                                        </Link>
                                        <span className='textXS'>
                                            Read
                                        </span>
                                    </div>
                                ))}
                                {subCategory?.Resources?.length > amountToShow && <button className="btnBlack w100"
                                    onClick={() => setAmountToShow(prev => prev + 5)}>
                                    Load more
                                </button>}
                            </Card>
                        </React.Fragment>
                    )}
                    {category?.UID && <Card bordered={false} className='mobCardBorderRadius0'>
                        <CardHeader heading={category.Category} headingType={2} textClassName='textMD w-700' />
                        {category?.SubCategories?.map((item) => (
                            <div key={item.UID} className='resCategoryItem' style={{ cursor: "pointer" }} onClick={() => {
                                navigate(item.FirstBlog)
                            }}>
                                <Link to={item.FirstBlog} className='textSM w-500'>
                                    <FcFolder size='0.7rem' />
                                    <h3 className='margin0 textSM w-500'>
                                        {item?.SubCategory}
                                    </h3>
                                </Link>
                                <span className='textXS'>
                                    ({item?.Resources?.length})
                                </span>
                            </div>
                        ))}
                        {category?.Resources?.map((item) => (
                            <div key={item.UID} className='resCategoryItem' style={{ cursor: "pointer" }} onClick={() => {
                                navigate(item.Slug)
                            }}>
                                <Link to={item.Slug} className='textSM w-500'>
                                    <FcDocument size='0.8rem' />
                                    <h3 className='margin0 textSM w-500'>
                                        {item?.Title}
                                    </h3>
                                </Link>
                                <span className='textXS'>
                                    Read
                                </span>
                            </div>
                        ))}
                    </Card>}
                    {categories?.[0]?.UID && <Card bordered={false} className='mobCardBorderRadius0'>
                        <CardHeader heading={'Categories'} textClassName='textMD w-700' headingType={2} />

                        {categories?.map((item) => (
                            <div key={item.UID} className='resCategoryItem' style={{ cursor: "pointer" }} onClick={() => {
                                navigate(item.Slug)
                            }}>
                                <Link to={item.Slug} className='textSM w-500'>
                                    <div style={{ background: getRandomColor(), borderRadius: "50%", height: "0.6rem", width: "0.6rem" }}></div>
                                    <h3 className='margin0 textSM w-500'>
                                        {item?.Category}
                                    </h3>
                                </Link>
                                <span className='textXS'>
                                    Explore
                                </span>
                            </div>
                        ))}
                    </Card>}
                </>}
            </div>
        </React.Fragment>
    )
}

export default ResourceSidebar2;

function getRandomColor() {
    let color = "hsl(" + Math.random() * 360 + ", 100%, 75%)";
    return color;
}

// const CardHeader = ({ heading }) => {
//     return <div className="d-flex" style={{ alignItems: "center", gap: "0.4rem", flex: 1 }}>
//         <div type='vertical' style={{ width: "0.3rem", height: "1.4rem", backgroundColor: "var(--primary)", borderRadius: "4px" }} />
//         <span className="textMD w-700" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{heading}</span>
//     </div>
// }