import AMCIcon from 'Components/AMCIcon';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { StickyScrollTabs } from 'Components/CustomComponents/StickyScrollTabs';
import CustomSegmentedTab from 'Components/CustomSegmentedTab';
import Head from 'Layout/Head';
import { fetchFundDataWithoutFilter } from 'Libs/fetchFundData';
import apiHelperPY from 'Services/api/ApiHelperPY';
import { Collapse } from 'antd';
import moment from 'moment';
import React from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function EventCalendar() {

    const [events, setEvents] = React.useState([]);
    const [allFunds, setAllFunds] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    let watchlist = useSelector((state) => state?.userWatchlists?.value);
    let watchlistSymbols = (watchlist || [])?.filter((ele) => ele?.FundType === 4)?.map((item) => item?.FundCodes)?.reduce((a, c) => { return [...a, ...c] }, []) || []
    const isMobile = window.innerWidth <= 575;

    const loadData = async () => {
        try {
            let funds = await fetchFundDataWithoutFilter(4);
            if (funds) {
                setAllFunds(funds)
            }
            let res = await apiHelperPY({ apiName: "getEventCalendar", data: {}, saveResponse: "" });
            if (res) {
                res = JSON.parse(res) || []
                let initEvents = [...res]
                if (initEvents?.length > 0) {
                    funds = funds?.map((el) => el?.symbol);
                    let temp = initEvents?.filter((el) => funds?.includes(el?.SYMBOL)) || [];
                    initEvents = temp;
                }
                let dates = initEvents?.map((el) => {
                    return moment(new Date(el?.DATE))?.format('DD MMM, YYYY');
                })
                dates = [...(new Set(dates))];
                dates = dates?.map((date, i) => {
                    let evs = initEvents?.filter((event) => {
                        let eventDate = moment(new Date(event?.DATE))?.format('DD MMM, YYYY');
                        return eventDate === date;
                    }) || [];
                    return {
                        date: date,
                        events: evs,
                        key: i
                    }
                })
                setEvents(dates);
            }
            setLoading(false)
        }
        catch (err) {
            console.log(err);
            setEvents([])
            setLoading(false)
        }
    }

    React.useEffect(() => {
        setLoading(true);
        loadData();
    }, [])

    const EventChildren = ({ type = 'today', count = 0 }) => {

        const filterEvents = React.useCallback(() => {
            if (type === 'upcoming') {
                let d = moment(new Date()).format('DD MMM, YYYY');
                let temp = events?.filter((ele) => {
                    return new Date(ele?.date) >= new Date(d);
                })
                return temp?.reverse();
            }
            else if (type === 'today') {
                let d = moment(new Date()).format('DD MMM, YYYY');
                let temp = events?.filter((ele) => ele?.date === d) || [];
                return temp?.reverse();
            }
            else if (type === 'day') {
                let currentDate = new Date();
                currentDate.setDate(currentDate.getDate() - 1);
                let c = moment(currentDate).format('DD MMM, YYYY');
                let lastDate = new Date();
                lastDate.setDate(lastDate.getDate() - count);
                let d = moment(lastDate).format('DD MMM, YYYY');
                let temp = events?.filter((ele) => {
                    return new Date(ele?.date) >= new Date(d) && new Date(ele?.date) <= new Date(c);
                })
                return temp;
            }
            else if (type === 'month') {
                let currentDate = new Date();
                currentDate.setDate(currentDate.getDate() - 1);
                let c = moment(currentDate).format('DD MMM, YYYY');
                let lastDate = new Date();
                lastDate.setDate(lastDate.getDate() - 1);
                lastDate.setMonth(currentDate.getMonth() - count);
                let d = moment(lastDate).format('DD MMM, YYYY');
                let temp = events?.filter((ele) => {
                    return new Date(ele?.date) >= new Date(d) && new Date(ele?.date) <= new Date(c);
                })
                return temp;
            }
            return events;
        }, [])

        const data = filterEvents();

        const eventPurposeTabs = [
            { key: 0, label: "Financial results", value: "Financial Results" },
            { key: 1, label: "Others", value: "Others" },
        ]

        let purposes = ['Financial Results'];

        const symbolTypeTabs = [
            { key: 0, label: "All", value: "All" },
            { key: 1, label: "Watchlist", value: "Watchlist" },
        ]

        const [selectedPurpose, setSelectedPurpose] = React.useState('Financial Results');
        const [selectedSymbolType, setSelectedSymbolType] = React.useState('All');
        const [itemsToShow, setItemsToShow] = React.useState(5);

        const getDataToShow = React.useCallback(() => {
            let temp = data?.filter((ele) => {
                if (purposes?.includes(selectedPurpose)) {
                    if (selectedSymbolType === 'All') {
                        return ele?.events?.find((el) => el?.PURPOSE === selectedPurpose);
                    }
                    else {
                        return ele?.events?.find((el) => el?.PURPOSE === selectedPurpose && watchlistSymbols?.includes(el?.SYMBOL))
                    }
                }
                else {
                    if (selectedSymbolType === 'All') {
                        return ele?.events?.find((el) => !(purposes.includes(el?.PURPOSE)));
                    }
                    else {
                        return ele?.events?.find((el) => (!(purposes.includes(el?.PURPOSE))) && watchlistSymbols?.includes(el?.SYMBOL))
                    }
                }
            });
            return temp;
        }, [selectedPurpose, selectedSymbolType]);

        const dataToShow = getDataToShow();

        const eventFilter = (item) => {
            if (purposes?.includes(selectedPurpose)) {
                if (selectedSymbolType === 'All') {
                    return item?.PURPOSE === selectedPurpose;
                }
                else {
                    return item?.PURPOSE === selectedPurpose && watchlistSymbols?.includes(item?.SYMBOL)
                }
            }
            else {
                if (selectedSymbolType === 'All') {
                    return !(purposes.includes(item?.PURPOSE));
                }
                else {
                    return (!(purposes.includes(item?.PURPOSE))) && watchlistSymbols?.includes(item?.SYMBOL)
                }
            }
        }

        const EventItem = ({ item = {} }) => {
            let fund = allFunds?.find((ele) => ele?.symbol === item?.SYMBOL);
            return (
                <div style={{ borderBottom: "1px solid var(--grey3)" }}>
                    <div className='d-flex  align-items-center eventItemTrigger showContentOnHoverRow' style={{
                        gap: "0.5rem", cursor: "pointer", padding: isMobile ? "0.75rem 0" : "0.5rem 1rem",
                        justifyContent: "space-between"
                    }}>
                        <div className='d-flex  align-items-sm-center align-items-start' style={{ gap: "0.5rem" }}>
                            <AMCIcon amcName={item?.SYMBOL} fundType={4} height='1.5rem' width='1.5rem' bseTicker={fund?.bse_ticker} />
                            <div className='textSM w-500 dark4'>{fund?.proper_name}</div>
                        </div>
                        {!isMobile && <div className='d-flex align-items-center content' style={{ gap: "0.5rem" }}>
                            <Link to={`/technical-chart?symbol=${item?.SYMBOL}`} target='_blank'>
                                <button className='btnXS w-500' style={{ borderRadius: "var(--borderRadius)", background: "var(--white)", color: "var(--black)", border: "none" }}>
                                    Launch chart</button>
                            </Link>
                            <Link to={`/stocks/stock-detail/${fund?.proper_name?.replaceAll(' ', '-')}/${item?.SYMBOL}`} target='_blank'>
                                <button className='btnXS w-500' style={{ borderRadius: "var(--borderRadius)", background: "var(--primary)", color: "var(--white)", border: "none" }}>
                                    See overview</button>
                            </Link>
                        </div>}
                    </div>
                    {selectedPurpose === 'Others' && <div style={{ padding: isMobile ? "0.75rem 0" : "0.5rem 1rem", }}>
                        <div className='textESM w-500 dark4'>{item?.PURPOSE}</div>
                        <div className='textXS w-500 dark4 mt-1' >{item?.DETAILS}</div>
                        {isMobile && <div className='d-flex align-items-center content mt-2' style={{ gap: "0.5rem" }}>
                            <Link to={`/technical-chart?symbol=${item?.SYMBOL}`} target='_blank'>
                                <button className='btnXS w-500' style={{
                                    borderRadius: "var(--borderRadius)", background: "var(--white)", color: "var(--black)",
                                    border: "1px solid  var(--grey1)"
                                }}>Launch chart</button>
                            </Link>
                            <Link to={`/stocks/stock-detail/${fund?.proper_name?.replaceAll(' ', '-')}/${item?.SYMBOL}`} target='_blank'>
                                <button className='btnXS w-500' style={{ borderRadius: "var(--borderRadius)", background: "var(--primary)", color: "var(--white)", border: "none" }}>
                                    See overview</button>
                            </Link>
                        </div>}
                    </div>}
                    {selectedPurpose !== 'Others' && isMobile && <div style={{ padding: isMobile ? "0.75rem 0" : "0.5rem 1rem", }}>
                        <div className='d-flex align-items-center content' style={{ gap: "0.5rem" }}>
                            <Link to={`/technical-chart?symbol=${item?.SYMBOL}`} target='_blank'>
                                <button className='btnXS w-500' style={{
                                    borderRadius: "var(--borderRadius)", background: "var(--white)", color: "var(--black)",
                                    border: "1px solid  var(--grey1)"
                                }}>Launch chart</button>
                            </Link>
                            <Link to={`/stocks/stock-detail/${fund?.proper_name?.replaceAll(' ', '-')}/${item?.SYMBOL}`} target='_blank'>
                                <button className='btnXS w-500' style={{ borderRadius: "var(--borderRadius)", background: "var(--primary)", color: "var(--white)", border: "none" }}>
                                    See overview</button>
                            </Link>
                        </div>
                    </div>}
                </div>
            )
        }

        return (
            <div>
                <div className='margin20Bottom' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <CustomSegmentedTab options={symbolTypeTabs} value={selectedSymbolType} onChange={(e) => setSelectedSymbolType(e)} isBlock={false} keepSameInMobile
                        style={{ padding: "2px" }} />
                    <CustomSegmentedTab options={eventPurposeTabs} value={selectedPurpose} onChange={(e) => setSelectedPurpose(e)} isBlock={false} keepSameInMobile
                        style={{ padding: "2px" }} />
                </div>
                <div>
                    {dataToShow?.length === 0 ? <div style={{ margin: "2rem 0" }}>
                        <div className='textSM w-500 dark3 text-center'>No scheduled events</div>
                    </div> :
                        <div>
                            <Collapse style={{ width: "100%", background: "transparent" }} bordered={false} expandIcon={({ isActive }) => <IoIosArrowDown style={{
                                transition: "all 0.3s linear", transform: isActive ? "rotate(180deg)" : "rotate(0deg)", color: "var(--black)", fontSize: "0.875rem"
                            }} />} expandIconPosition='end' accordion className='eventsCollapseContainer' defaultActiveKey={[dataToShow?.[0]?.key]}
                                items={dataToShow?.slice(0, itemsToShow)?.map((item, j) => {
                                    return {
                                        key: item?.key,
                                        label: <div className='textSM w-500 dark4' style={{}}>{item?.date}</div>,
                                        children: <div>
                                            {item?.events?.filter(eventFilter)?.map((ele, i) => <EventItem item={ele} key={i} />)}
                                        </div>,
                                        style: { borderBottom: "unset", }
                                    }
                                })} />
                            {dataToShow?.length > itemsToShow &&
                                <button className='btnBlack' onClick={() => setItemsToShow(prev => prev + 5)} style={{ marginTop: "1.25rem" }}>Show more</button>}
                        </div>
                    }
                </div>
            </div>
        )
    }

    const tabData = [
        { key: 1, label: "All upcoming", value: "AllUpcoming", heading: "All upcoming events", children: <EventChildren type='upcoming' /> },
        { key: 2, label: "Today", value: "Today", heading: "Today", children: <EventChildren type='today' /> },
        { key: 3, label: "Last 7 days", value: "Last7Days", heading: "Last 7 days", children: <EventChildren type='day' count={7} /> },
        { key: 4, label: "Last 1 month", value: "Last1Month", heading: "Last 1 month", children: <EventChildren type='month' count={1} /> },
        { key: 5, label: "Last 3 months", value: "Last3Months", heading: "Last 3 months", children: <EventChildren type='month' count={3} /> },
    ]

    if (loading) {
        return (
            <div className='loaderContainer'>
                <ActivityLoaderMintbox showQuote />
            </div>
        )
    }
    return (
        <React.Fragment>
            <Head title='Sharpely Event Calendar | Find Upcoming Events & Activities.' description="A comprehensive calendar of upcoming events and activities on Sharpely. Stay updated with the latest happenings and plan your schedule effortlessly. Don't miss out – latest stock events today!" />
            <div>
                <StickyScrollTabs tabData={tabData} scrollBehavior='instant' />
            </div>
        </React.Fragment>
    )
}

export default EventCalendar;