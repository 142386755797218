function kFormatter(val, fractionDigits, showRupee) {
  if (val >= 10000000) { val = `${(val / 10000000).toFixed(fractionDigits)} cr` }
  else if (val >= 100000) { val = `${(val / 100000).toFixed(fractionDigits)} lac` }
  else if (val >= 1000) { val = `${(val / 1000).toFixed(fractionDigits)} k` }
  else { val = normalFormatter(val) }
  if (!showRupee) return val.replace('-', '')
  return `₹ ${val.replace('-', '')}`;
}

function normalFormatter(num, fractionDigits = 2, showRupee) {
  const a = new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(num);
  if (!showRupee) return a
  return `₹ ${a}`;
}

export function CurrencyFormat(num = 0, type = "long", fractionDigits = 2, suffix = "", showRupee = true) {
  num = +parseFloat(num).toFixed(fractionDigits)
  return isNaN(num) ? "-" : type === "short" ?
    kFormatter(Number(num), fractionDigits, showRupee) :
    (normalFormatter(Number(num), fractionDigits, showRupee) + suffix)
}