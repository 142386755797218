import { baseImgURL } from 'Assets/Constant';
import { useIsMobile } from 'CustomHooks/useIsMobile';
import { FaArrowCircleRight } from 'react-icons/fa';

export const StyleboxSection = () => {

    const isMobile = useIsMobile();

    return <div style={{
        height: '100%', width: '100%', flex: 1,
        position: "relative"
    }} className='d-flex'>
        <div style={{ alignSelf: "flex-end", marginTop: isMobile ? "15rem" : "10rem" }}>
            <img loading="lazy" alt='stylebox' src={`${baseImgURL}sharpely-asset/images/Home/integrationsV2.webp`} width={'100%'} height={'100%'} />
        </div>
        <div style={{
            display: 'flex', flex: 1,
            justifyContent: 'space-between', height: '100%', width: '100%', gap: '4rem', flexDirection: "row", position: "absolute", top: 0, left: 0
        }} className='flex-sm-row flex-column padAllCampaign'>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: isMobile ? "" : "center", textAlign: isMobile ? "" : "center" }}>
                <h2 className='headLGtoXS' style={{
                    fontWeight: 700, color: 'var(--white2)', maxWidth: isMobile ? "" : "30ch"
                }}>
                    With <span style={{ color: "gold" }}>5000+ stocks</span> to choose from, use <span style={{ color: "gold" }}>StyleBox</span> to narrow down your stock list
                </h2>
                <div className='textMDtoESM dark2' style={{ width: "100%", paddingTop: "1rem", maxWidth: isMobile ? "" : "50%" }}>
                    With our StyleBox classification, we have classified stocks into winning
                    and losing styles using value, quality, and momentum factors.
                </div>
                <div style={{ marginTop: "2rem" }}>
                    <button className='btnPrimary textLG hoverEffect' style={{ padding: '0.6rem 1.25rem', display: 'flex', alignItems: 'center', gap: '1rem' }} onClick={(e) => {
                        window.open(`/stocks/stylebox`, '_blank', "noreferrer");
                    }}>
                        Explore Stylebox <FaArrowCircleRight />
                    </button>
                </div>
            </div>
        </div>
    </div>
}
