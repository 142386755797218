import { Card, Select } from 'antd';
import AccessComponent from 'Components/AccessComponent';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { CardHeader } from 'Components/atoms/SmallComponents';
import { NoDataFound } from 'Components/NoDataFound';
import { useSubscribeMiddleware } from 'Components/SubscribeModal';
import UpgradeToPro from 'Components/UpgradeToPro';
import { useIsMobile } from 'CustomHooks/useIsMobile';
import { useUserDataHooks } from 'CustomHooks/useUserDataHooks';
import FactorModel from 'Pages/FactorModel/FactorModel';
import React from 'react';
import { BiPlusCircle } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const FactorModelsWidgit = ({ type, widgetItem }) => {

    const user = useSelector((state) => state?.updateUserData?.value);
    const [models, setModels] = React.useState([]);
    const [selectedModel, setSelectedModel] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const { getUserStockModels } = useUserDataHooks();
    const isMobile = useIsMobile();
    const subscribeMiddle = useSubscribeMiddleware();
    const navigate = useNavigate();

    const loadData = async () => {
        setLoading(true)
        let res = await getUserStockModels();
        if (res) {
            setModels(res?.userModels);
            setSelectedModel(res?.userModels?.[0])
        }
        console.log(res);
        setTimeout(() => {
            setLoading(false)
        }, 1);
    }

    const modelDetailedAccess = subscribeMiddle({ moduleKey: "SAVE_MODELS_AND_SET_ALERTS", getDetailedAccessData: true, usageCount: models?.length, checkLimit: true });

    React.useEffect(() => {
        loadData();
    }, [type?.value])


    return (
        <React.Fragment>
            <Card>
                <AccessComponent component={<>
                    <div className='d-flex justify-content-between align-items-end margin20Bottom' style={{ gap: "1rem", flexWrap: "wrap", flexDirection: isMobile ? "column" : "row" }}>
                        <div style={{ flex: 1 }}>
                            <CardHeader heading={widgetItem?.Name} headingType={2} />
                            <div className='customTabsSubHeading' style={{ marginBottom: "0" }}>{widgetItem?.Description}</div>
                        </div>
                        {models?.length > 0 && <Select style={{ width: isMobile ? "100%" : "10rem" }} value={selectedModel?.signal_id} onChange={(e) => {
                            setSelectedModel(models?.find((ele) => ele?.signal_id === e) || {});
                        }} options={models?.map((el, i) => {
                            return {
                                ...el,
                                key: i,
                                label: el?.signal_name,
                                value: el?.signal_id
                            }
                        })} />}
                    </div>
                    {loading ? <div>
                        <ActivityLoaderMintbox showQuote />
                    </div> : models?.length <= 0 ? <div style={{ margin: "0 0 1rem" }}>
                        <NoDataFound message={<div className='d-flex flex-column justify-content-center align-items-center' style={{ gap: "0.5rem" }}>
                            <div className='textSM dark3 my-2'>You have not created any factor models. Start by creating one.</div>
                            <button className='btnWhite d-flex align-items-center' onClick={() => {
                                navigate(`/stocks/factor-model`)
                            }} style={{ gap: "0.25rem", width: "fit-content" }}>
                                <BiPlusCircle />  Create
                            </button>
                        </div>} />
                    </div> : <div>
                        <AccessComponent component={<FactorModel isWidget={true} widgetSignalData={selectedModel} />}
                            isDefaultUpgrade={selectedModel?.is_locked === 1} upgradeComponent={<div style={{ background: "var(--grey4)", border: "1px solid var(--grey3)", borderRadius: "0.5rem" }}>
                                <UpgradeToPro customHeading='Upgrade your plan!' moduleKey='SAVE_MODELS_AND_SET_ALERTS' customDesc={`This factor model is locked. With your current plan ${modelDetailedAccess?.maxLimit === 0 ? 'you cannot view any of your saved factor models. To view your factor models, upgrade your plan.' : `you can only view your first ${modelDetailedAccess?.maxLimit} saved factor models. 
                            To view your other factor models, upgrade your plan.`}`} />
                            </div>} />
                    </div>}
                </>} moduleKey='SAVE_MODELS_AND_SET_ALERTS' upgradeComponent={<>
                    <div className='d-flex justify-content-between align-items-end margin20Bottom' style={{ gap: "1rem", flexWrap: "wrap", flexDirection: isMobile ? "column" : "row" }}>
                        <div style={{ flex: 1 }}>
                            <CardHeader heading={widgetItem?.Name} headingType={2} />
                            <div className='customTabsSubHeading' style={{ marginBottom: "0" }}>{widgetItem?.Description}</div>
                        </div>
                    </div>
                    <div>
                        <UpgradeToPro moduleKey='SAVE_MODELS_AND_SET_ALERTS' />
                    </div>
                </>} />
            </Card>
        </React.Fragment >
    )
}

export default FactorModelsWidgit;