import { Progress } from "antd";

const CustomSharpelyScoreProgressBar = ({ val }) => {
    const value = val === '**' ? Math.random() * 100 : val;
    const color = value < 33.33 ? 'var(--red)' : value < 66.66 ? 'var(--yellow)' : value <= 100 ? 'var(--green)' : 'transparent'
    if (val) {
        if (val === '**') {
            return <Progress
                percent={value}
                type="circle"
                size={window.innerWidth > 760 ? '6rem' : '5rem'}
                strokeWidth={12}
                format={(f) => <span className="w-500 black" style={{ fontSize: '1rem' }}>XX</span>}
                strokeColor={color} />
        }
        return <Progress
            percent={value}
            type="circle"
            size={window.innerWidth > 760 ? '6rem' : '5rem'}
            strokeWidth={12}
            // gapDegree={0}
            format={(f) => <span className="w-500 black" style={{ fontSize: window.innerWidth > 760 ? '1rem' : '12px' }}>{Number(f.toFixed(1))}</span>}
            strokeColor={color} />
    } else {
        return <>-</>
    }

}

export default CustomSharpelyScoreProgressBar;