import db from "Libs/db";
import AdminServices from "./AdminServices";

const MINTBOX_CLIENT_ID = "Li2L9VO1eawEbsgLrHdpZjhmUdW6N8Cm";

export const Dashboard_Portfolio = {

	getDashboardData: async (user, isFresh = false) => {
		try {
			return await db.get(db.model.dashboard).then(async (p) => {
				const aa = p;
				if (p === null || isFresh) {
					let da = await AdminServices.getDashboardData().then((res) => JSON.stringify(res));
					da = JSON.parse(da);
					if (da.type === true) {
						db.set(db.model.dashboard, JSON.stringify(da.data))
						return da.data
					} else {
						db.set(db.model.dashboard, JSON.stringify([]))
						return []
					}

				} else {
					return aa
				}
			});
		} catch (error) {
			return []
		}
	},

	getClientData: async (user, isFresh = false) => {
		try {
			return await db.get(db.model.appData).then(async (p) => {
				//p = JSON.parse(p);
				if (p === null || isFresh) {
					let da = await AdminServices.getClient({ id: user.id }).then((res) => JSON.stringify(res));
					da = JSON.parse(da);
					const aa = JSON.parse(da.data);
					const aak = aa.reduce((aa, f) => { return { ...aa, ...f } }, {})
					db.set(db.model.appData, JSON.stringify(aak));
					return aak
					// setmfdEnabled(aa[0].Client[0].IsMFDEnabled)
					// getData(user, aa)
					// getXIRRData(user)
				} else {
					return p
					// setmfdEnabled(p[0].Client[0].IsMFDEnabled)
					// getData(user, p)
					// getXIRRData(user)
				}
			})
		} catch (error) {
			return []
		}
	},

	getPortfolioHoldingsData: async (user, isFresh = false) => {
		try {
			return await db.get(db.model.portfolioHoldings).then(async (p) => {
				const aa = p;
				if (p === null || isFresh) {
					try {
						const res = await AdminServices.getPortfolioHoldingsData(user.id, 0, isFresh).then((r) => r);
						const aa = JSON.parse(res.data);
						const aak = aa.reduce((aa, f) => { return { ...aa, ...f } }, {})
						db.set(db.model.portfolioHoldings, JSON.stringify(aak));
						return aak
					} catch (error) {
						return []
					}
				} else {
					return aa
				}
			});
		} catch (error) {
			return []
		}
	},

	getPortfolioScheduledOrders: async (user, isFresh = false) => {
		try {
			return await db.get(db.model.portfolioScheduledOrders).then(async (p) => {
				const aa = p;
				if (p === null || isFresh) {
					try {
						const res = await AdminServices.getPortfolioScheduledOrders(user.id, 0, isFresh).then((r) => r);
						const aa = res.data;
						const aak = aa
						db.set(db.model.portfolioScheduledOrders, JSON.stringify(aak));
						return aak
					} catch (error) {
						return []
					}
				} else {
					////////console.log("Portf-DATAdb",aa)
					return aa
				}
			});
		} catch (error) {
			////////console.log("PortF-Err",error)
			return []
		}
	},

	getPortfolioSnapData: async (user, isFresh = false) => {
		try {
			return await db.get(db.model.portfolioSnap).then(async (p) => {
				const aa = p;
				if (p === null || isFresh) {
					try {
						const res = await AdminServices.getPortfolioSnapshot(user.id, 0, isFresh).then((r) => r);
						const aa = JSON.parse(res.data);
						const aak = aa.reduce((aa, f) => { return { ...aa, ...f } }, {})
						db.set(db.model.portfolioSnap, JSON.stringify(aak));
						//////console.log("Portf-DATA", aak)
						return aak
					} catch (error) {
						//////console.log("PortF-Err", error)
						return []
					}
				} else {
					//////console.log("Portf-DATAdb", aa)
					return aa
				}
			});
		} catch (error) {
			////////console.log("PortF-Err",error)
			return []
		}
	},


	getPortfolioPerformance: async (user, isFresh = false) => {
		try {
			return await db.get(db.model.portfolioPerformance).then(async (p) => {
				const aa = p;
				if (p === null || isFresh) {
					let da = await AdminServices.getPortfolioPerformance(user.id, isFresh).then(res => res);
					const a = await JSON.parse(da.data);
					let aa = a.reduce((aa, f) => { return { ...aa, ...f } }, {})
					db.set(db.model.portfolioPerformance, JSON.stringify(aa))
					return aa
				} else {
					return aa
				}
			});
		} catch (error) {
			return []
		}
	},


	getPortfolioAsset: async (user, isFresh = false) => {
		try {
			return await db.get(db.model.portfolioAsset).then(async (p) => {
				const aa = p;
				if (p === null || isFresh) {
					let da = await AdminServices.getPortfolioAsset(user.id, isFresh).then((res) => JSON.stringify(res));
					da = JSON.parse(da);
					db.set(db.model.portfolioAsset, JSON.stringify(da.data))
					//////console.log("Asset-DATA", da)
					return da.data
				} else {
					////////console.log("Asset-DATAdb",aa)
					return aa
				}
			});
		} catch (error) {
			////////console.log("Asse-Err",error)
			return []
		}
	},


	getPortfolioGoals: async (user, isFresh = false) => {
		try {
			return await db.get(db.model.clientGoals).then(async (p) => {
				const aa = p;
				if (p === null || isFresh) {
					////console.log("sdfdsfdfs")
					let da = await AdminServices.getClientGoals(user.id).then((res) => JSON.stringify(res));
					da = JSON.parse(da);
					db.set(db.model.clientGoals, JSON.stringify(da.data))
					////console.log("Asset-DATA", da)
					return da.data
				} else {
					////console.log("Asset-DATAdb", aa)
					return aa
				}
			});
		} catch (error) {
			////////console.log("Asse-Err",error)
			return []
		}
	},

	getPortfolioInstruments: async (user, isFresh = false) => {
		try {
			return await db.get(db.model.portfolioInstrument).then(async (p) => {
				const aa = p;
				if (p === null || isFresh) {
					let da = await AdminServices.getPortfolioInstruments(user.id, isFresh).then((res) => JSON.stringify(res));
					da = JSON.parse(da);
					db.set(db.model.portfolioInstrument, JSON.stringify(da.data))
					//////console.log("Asset-DATA", da)
					return da.data
				} else {
					////////console.log("Asset-DATAdb",aa)
					return aa
				}
			});
		} catch (error) {
			////////console.log("Asse-Err",error)
			return []
		}
	},

	getPortfolioTransaction: async (user, isFresh = false) => {
		try {
			return await db.get(db.model.portfolioTransaction).then(async (p) => {
				const aa = p;
				if (p === null || isFresh) {
					let da = await AdminServices.getPortfolioTransactions(user.id, isFresh).then((res) => JSON.stringify(res));
					da = JSON.parse(da);
					db.set(db.model.portfolioTransaction, JSON.stringify(da.data))
					////////console.log("Tra-DATA",da)
					return da.data
				} else {
					////////console.log("Tra-DATAdb",aa)
					return aa
				}
			});
		} catch (error) {
			////////console.log("Tran-err",error)
			return []
		}
	},


	getPortfolioXIRR: async (user, isFresh = false) => {
		try {
			return await db.get(db.model.xirr).then(async (p) => {
				const aa = p;
				if (p === null || isFresh) {
					try {
						const res = await AdminServices.getPortfolioXIRR(user.id, isFresh).then((r) => r);
						const aa = JSON.parse(res.data);
						const aak = aa.reduce((aa, f) => { return { ...aa, ...f } }, {})
						db.set(db.model.xirr, JSON.stringify(aak));
						////console.log("Xirr-DATAdb", aak)

						return aak
					} catch (error) {
						////console.log("Xirr-Err", error)
						return []
					}
				} else {
					////console.log("Xirr-DATAdb", aa)
					return aa
				}
			});
		} catch (error) {
			////console.log("Xirr-Err", error)
			return []
		}
	},
};