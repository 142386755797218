import { useIsMobile } from 'CustomHooks/useIsMobile';
import { makeid } from 'Libs/utils';
import { FaArrowRight, FaPlayCircle } from "react-icons/fa";

export const VideoCard = ({ videoItem, isBordered = false }) => {

    const isMobile = useIsMobile();

    return (
        <div className="w100 h100" key={makeid(4)} onClick={() => {
            window.open('https://www.youtube.com/watch?v=' + videoItem?.videoId, "_blank", "noreferrer");
        }} style={{
            display: 'flex', flexDirection: 'column', borderRadius: '0.5rem', overflow: 'clip', cursor: 'pointer',

        }}>
            <div style={{ position: 'relative', border: isBordered ? "4px solid rgba(255, 255, 255, 0.95)" : "", borderBottom: 0 }} className="videoImgContainer">
                <img loading="lazy" alt={videoItem?.videoId} src={`https://img.youtube.com/vi_webp/${videoItem?.videoId}/${isMobile ? "mqdefault" : 'sddefault'}.webp`}
                    width={'100%'} style={{ aspectRatio: 16 / 9, objectFit: 'cover' }} />
                <div style={{ position: "absolute", background: "rgba(0, 0, 0, 0.5)", width: "100%", height: "100%", top: 0, left: 0 }}
                    className="d-flex align-items-center justify-content-center videoImgOverlay">
                    <FaPlayCircle style={{ color: "var(--white)" }} size={'2.5rem'} />
                </div>
            </div>
            <div style={{ padding: (videoItem?.heading?.length > 0 || videoItem?.content?.length > 0) ? '1rem 1rem' : "", backgroundColor: 'rgba(255,255,255,0.95)', border: '1px solid var(--grey2)', flex: 1 }}
                className="d-flex flex-column justify-content-between">
                {(videoItem?.heading?.length > 0 || videoItem?.content?.length > 0) && <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', userSelect: "none" }}>
                    <div className="textLG w-700 dark4" style={{}}>{videoItem?.heading}</div>
                    {videoItem?.content && <div className="textSMtoXS dark3">{videoItem?.content}</div>}
                </div>}
                {videoItem?.buttonText && <div>
                    <div style={{ display: "flex", gap: "1.25rem", marginTop: isMobile ? "1.5rem" : "1rem" }}>
                        <div className="textXS w-500" style={{
                            border: "none", outline: "none", background: 'var(--dark4)',
                            color: "var(--white)", borderRadius: "var(--borderRadius)", cursor: "pointer",
                            padding: isMobile ? "0.75rem 1rem" : "0.5rem 0.75rem", flex: isMobile ? 1 : undefined,
                            textAlign: isMobile ? "center" : "unset"
                        }} onClick={(e) => {
                            e.stopPropagation();
                            let url = videoItem.webURL?.split('|')?.[0];
                            if (url === 'cardClick') {
                                window.open('https://www.youtube.com/watch?v=' + videoItem?.videoId, "_blank", "noreferrer");
                            }
                            else {
                                window.open(`${url}`, '_blank', "noreferrer");
                            }
                        }}>
                            {videoItem?.buttonText?.split('|')?.[0]}
                        </div>
                        {!isMobile && videoItem?.buttonText?.split('|')?.[1] && <div className="textXS w-500" style={{
                            border: "1px solid var(--dark4)", outline: "none", background: 'var(--white)',
                            color: "var(--dark4)", borderRadius: "var(--borderRadius)", cursor: "pointer",
                            padding: isMobile ? "0.5rem 1rem" : "0.5rem 0.75rem"
                        }} onClick={(e) => {
                            e.stopPropagation();
                            let url = videoItem.webURL?.split('|')?.[1];
                            if (url === 'cardClick') {
                                window.open('https://www.youtube.com/watch?v=' + videoItem?.videoId, "_blank", "noreferrer");
                            }
                            else {
                                window.open(`${url}`, '_blank', "noreferrer");
                            }
                        }}>
                            {videoItem?.buttonText?.split('|')?.[1]}
                        </div>}
                    </div>
                    {isMobile && videoItem?.buttonText?.split('|')?.[1] && <div style={{ gap: "0.5rem", color: "var(--primary)", lineHeight: "100%" }}
                        className="d-flex align-items-center justify-content-center mt-3 textXS w-500"
                        onClick={(e) => {
                            e.stopPropagation();
                            let url = videoItem.webURL?.split('|')?.[1];
                            if (url === 'cardClick') {
                                window.open('https://www.youtube.com/watch?v=' + videoItem?.videoId, "_blank", "noreferrer");
                            }
                            else {
                                window.open(`${url}`, '_blank', "noreferrer");
                            }
                        }}>
                        {videoItem?.buttonText?.split('|')?.[1]} <FaArrowRight />
                    </div>}
                </div>}
            </div>
        </div>
    )
}