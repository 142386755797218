import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import GoToDesktopVersion from 'Components/GoToDesktopVersion';

export function ProtectedRoutes({ reqAccessLevel = 0, redirectRoute = "/", children, showInMobile = true }) {
  let location = useLocation();
  var user = useSelector((state) => state?.updateUserData?.value);
  const isMobile = window.innerWidth < 760
  if (reqAccessLevel === 0 || (user && (reqAccessLevel <= (user?.Client?.[0]?.SharpelyAccessLevel)))) {
    if (!isMobile || showInMobile) {
      return children;
    } else {
      return <GoToDesktopVersion />
    }
  } else {
    return <Navigate to={redirectRoute} state={{ from: location.pathname }} />;
  }
}