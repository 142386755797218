import { LoadingOutlined } from '@ant-design/icons';
import moengage from "@moengage/web-sdk";
import { useIsMobile } from "CustomHooks/useIsMobile";
import Logo from "Layout/Logo";
import { togglePayFeeModal, updatePayFeeParams, updateUserData, updateUserPlanData } from "Libs/redux/UserAuth/reduxSlice";
import { isInvalidTextOrLength } from 'Libs/utils';
import AdminServices from "Services/api/AdminServices";
import apiHelper from 'Services/api/ApiHelper';
import apiHelperPY from "Services/api/ApiHelperPY";
import { Dashboard_Portfolio } from "Services/api/FabricatedAPIS";
import { Divider, Drawer, Input, Modal, Popconfirm, Radio } from "antd";
import React from "react";
import toast from "react-hot-toast";
import { AiOutlineClose, AiOutlineCloseCircle, AiOutlineEdit } from "react-icons/ai";
import { BsFillCheckSquareFill } from "react-icons/bs";
import { HiCheck } from 'react-icons/hi';
import { IoIosClose } from 'react-icons/io';
import { RiCoupon2Fill, RiInformationFill } from 'react-icons/ri';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import { CurrencyFormat } from "./CurrencyFormat";
import { formatDateMMMDDYYYY } from "./DateFormatter"

export default function PaySharpelyPricingFee({ initPlansData }) {

    const user = useSelector((state) => state?.updateUserData?.value);
    const ftStartDate = user?.Client?.[0]?.SharpelyFTStartDate;
    const isSharpelyFreeTrial = user?.Client?.[0]?.IsSharpelyFreeTrial === 1;
    const hasMadeFirstTransaction = Boolean(isSharpelyFreeTrial && ftStartDate);
    const userPlanData = useSelector((state) => state?.userPlanData?.value);
    const currentPlan = userPlanData?.userActiveSubOrOneTime;
    const openPG = useSelector((state) => state?.paySharpelyFeeModal?.open);
    const paymentParams = useSelector((state) => state?.paySharpelyFeeModal?.params);
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [plansData, setPlansData] = React.useState({});
    const selectedPlan = React.useMemo(() => plansData?.plans?.find((item) => item?.level === (paymentParams?.plan)), [plansData, paymentParams]);
    const [upiId, setUpiId] = React.useState('');
    const [isValidUPI, setisValidUPI] = React.useState(false);
    const [freqAndPayType, setFreqAndPayType] = React.useState('1Y-O')
    const [payInitiated, setPayInitiated] = React.useState(false);
    const [payFailed, setPayFailed] = React.useState(false);
    const [payCancelled, setPayCancelled] = React.useState(false);
    const [paySuccess, setPaySuccess] = React.useState(false);
    const [subData, setSubData] = React.useState({});
    const [paymentTime, setPaymentTime] = React.useState(600);
    const [couponData, setCouponData] = React.useState({});
    // const [changePlan, setChangePlan] = React.useState(false);
    // const oneTimeLinkRef = React.useRef();
    // const payLinkWindowRef = React.useRef();
    const subscriptionRef = React.useRef();
    const paymentTimerRef = React.useRef();

    const planPricesAndFreqs = React.useMemo(() => {
        let result = [];
        if (selectedPlan?.price) {
            let planPrice1M = selectedPlan?.price?.new;
            Object.entries(selectedPlan?.discount?.onetime || {})?.map(([key, val]) => {
                let freqInMonth = key?.includes('M') ? Number(key?.replace('M', '')) : Number(key?.replace('Y', '')) * 12;
                let finalAmount = Math.ceil((freqInMonth * planPrice1M) * ((100 - val) / 100));
                let amtSaved = (freqInMonth * planPrice1M) - finalAmount;
                let endDate = new Date();
                endDate.setMonth(endDate.getMonth() + freqInMonth);
                result.push({
                    amount: finalAmount,
                    discount: Math.ceil(val),
                    amountSaved: amtSaved,
                    endDate,
                    label: freqInMonth + (freqInMonth === 1 ? " month" : " months"),
                    actualPrice: freqInMonth * planPrice1M,
                    value: key + '-O',
                    key: key,
                    isOneTime: true,
                    freqInMonth: freqInMonth,
                    frequency: key,
                    freqForUpdateAccess: key
                })
            })
            if (hasMadeFirstTransaction) {
                result.shift();
                Object.entries(selectedPlan?.discount?.subscription || {})?.map(([key, val]) => {
                    let freqInMonth = key === 'Monthly' ? 1 : key === 'Yearly' ? 12 : 0;
                    let finalAmount = Math.ceil((freqInMonth * planPrice1M) * ((100 - val) / 100));
                    let amtSaved = (freqInMonth * planPrice1M) - finalAmount;
                    let endDate = new Date();
                    endDate.setMonth(endDate.getMonth() + freqInMonth);
                    result.push({
                        amount: finalAmount,
                        discount: Math.ceil(val),
                        amountSaved: amtSaved,
                        endDate,
                        label: key,
                        actualPrice: freqInMonth * planPrice1M,
                        value: key + '-S',
                        key: key,
                        isSubscription: true,
                        freqInMonth: freqInMonth,
                        frequency: key,
                        freqForUpdateAccess: key === 'Monthly' ? "1M" : "1Y"
                    })
                })
            }
        }
        result?.sort((a, b) => b?.freqInMonth - a?.freqInMonth)
        return result;
    }, [plansData, selectedPlan, hasMadeFirstTransaction])

    const selectedPlanFreq = React.useMemo(() => {
        return planPricesAndFreqs?.find((el) => el?.value === freqAndPayType);
    }, [freqAndPayType, planPricesAndFreqs]);

    const isOneTime = selectedPlanFreq?.isOneTime === true;
    const isSubscription = selectedPlanFreq?.isSubscription === true;
    const isCouponAllowed = selectedPlanFreq?.freqForUpdateAccess === '1Y';
    const isCouponApplied = isCouponAllowed && Object.keys(couponData || {})?.length > 0;
    const finalDisplayAmount = isCouponApplied ? couponData?.DiscountedAmount : selectedPlanFreq?.amount;

    const onClose = () => {
        if (payInitiated === true && isOneTime) {
            moengage.track_event('ONETIME_PAYMENT_PLAN_FAILED', {
                "userId": user?.id
            })
        }
        if (payInitiated === true && isSubscription) {
            moengage.track_event('SUBSCRIPTION_PAYMENT_FAILED', {
                "subscriptionId": subData?.subId,
                "userId": user?.id,
                "authId": subData?.authId
            })
        }
        dispatch(togglePayFeeModal(false));
        setPayInitiated(false);
        setPaySuccess(false);
        setPayFailed(false);
        setPayCancelled(false);
        setSubData({});
        setCouponData({});
        setPaymentTime(600);
        setFreqAndPayType('1Y-O');
        // if (payLinkWindowRef.current) {
        //     payLinkWindowRef.current.close();
        //     payLinkWindowRef.current = null;
        // }
        dispatch(updatePayFeeParams({}))
    }

    const loadPlans = async () => {
        if (initPlansData && Object.keys(initPlansData)?.length !== 0) {
            setPlansData(initPlansData);
        }
        else {
            let pls = await apiHelperPY({ apiName: "getAllSharpelyPlans", saveResponse: "" })
            if (pls) {
                pls = JSON.parse(pls);
                // console.log(pls);
                setPlansData(pls);
            }
        }
    }

    const trackEventAndUpdUserData = async () => {
        moengage.track_event('CANCEL_SUBSCRIPTION', {
            "subscriptionId": userPlanData?.currentSubscription?.SubscriptionId,
            "userId": user?.id,
            "planName": userPlanData?.currentSubscription?.SubscriptionName,
            "frequency": userPlanData?.currentSubscription?.Frequency,
            "isPlanChanged": false
        })
    }

    const cancelSubscription = async () => {
        try {
            if (userPlanData?.currentSubscription?.PGType === 'PhonePe') {
                let data = {
                    clientId: user?.id,
                    subscriptionId: userPlanData?.currentSubscription?.SubscriptionId
                }
                let res = await AdminServices.cancelPPSubscription(data);
                if (res?.type === true) {
                    // toast.error('Subscription cancelled.')
                    await trackEventAndUpdUserData();
                    await AdminServices.getPPAuthReqStatus(userPlanData?.currentSubscription?.AuthId).then((res) => { return res });
                }
                else {
                    // toast.error('Something went wrong.')
                }
            }
            else if (userPlanData?.currentSubscription?.PGType === 'RazorPay') {
                let res = await apiHelper({ apiName: "cancelRazorSubscription", data: { id: userPlanData?.currentSubscription?.SubscriptionId } });
                if (res) {
                    // toast.error('Subscription cancelled.')
                    await trackEventAndUpdUserData();
                }
                else {
                    // toast.error('Something went wrong.')
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const updateUserDataOnPaySuccess = async () => {
        if (currentPlan?.planName) {
            await cancelSubscription();
        }
        if (!hasMadeFirstTransaction) {
            await AdminServices.enrollFreeTrial({ email: user?.email, accessLevel: selectedPlan?.level })
        }
        let d = { client_id: user?.id, access_level: selectedPlan?.level, frequency: selectedPlanFreq?.freqForUpdateAccess, isOneTime: 1 }
        await AdminServices.updateAccess(JSON.stringify(d));
        dispatch(updateUserPlanData(undefined));
        let userData = { name: user?.name, email: user?.email, mobile: user?.mobile, isLogged: true, id: user?.id, login_Date: user?.login_Date }
        await Dashboard_Portfolio.getClientData(userData, true).then((res) => {
            if (res) {
                dispatch(updateUserData({ ...userData, ...res, mobile: res?.Client[0]?.MobileNumber }))
            }
        });
    }

    const checkAuthIdStatus = async () => {
        let a = await AdminServices.getPPAuthReqStatus(subData?.authId).then((i) => { return i })
        if (JSON.parse(a?.data)?.data?.transactionDetails?.state === 'COMPLETED') {
            moengage.track_event('SUBSCRIPTION_PAYMENT_SUCCESSFUL', {
                "subscriptionId": subData?.subId,
                "userId": user?.id,
                "authId": subData?.authId,
                "plan": selectedPlan?.name,
                "period": selectedPlanFreq?.frequency
            })
            setPaySuccess(true);
            await updateUserDataOnPaySuccess();
            navigate(`/payment-success/${selectedPlan?.name?.toLowerCase()}/${isOneTime ? "onetime" : "subscription"}/${selectedPlanFreq?.frequency}/0`);
        }
    }

    const initializePhonepeSubscription = async () => {
        let subStartDate = new Date();
        const amount = selectedPlanFreq?.amount;
        var subData = {
            "clientId": user?.id,
            "amount": amount * 100,
            "subscriptionName": selectedPlan?.name,
            "frequecy": selectedPlanFreq?.frequency?.toLowerCase(),
            "duration": 120,
            "description": `Subscription to sharpely ${selectedPlan?.name} - ${selectedPlanFreq?.frequency}`,
            "mobileNumber": user?.mobile,
            "isFreeTrial": 0,
            "subscriptionStartDate": subStartDate
        }
        var sub = await AdminServices.createPPSub(subData)?.then((i) => { return i })
        if (JSON.parse(sub?.data)) {
            moengage.track_event('SUBSCRIPTION_CREATED', {
                "subscriptionId": JSON.parse(sub?.data)?.data?.subscriptionId,
                "userId": user?.id,
            })
            let subData = { subId: JSON.parse(sub?.data)?.data?.subscriptionId }
            var auReq = {
                "clientId": user?.id,
                "subscriptionId": JSON.parse(sub?.data)?.data?.subscriptionId,
                "upiMethod": "UPI_COLLECT",
                "vpa": upiId,
                "amount": amount * 100
            }
            var req = await AdminServices.createPPAuthRequest(auReq)?.then((i) => { return i })
            if (req?.data?.code === "SUCCESS") {
                setPayInitiated(true)
                subData.authId = req?.data?.authId;
                setSubData(subData);
                moengage.track_event('SUBSCRIPTION_PAYMENT_INITIATED', {
                    "subscriptionId": JSON.parse(sub?.data)?.data?.subscriptionId,
                    "userId": user?.id,
                    "authId": req?.data?.authId,
                    "plan": selectedPlan?.name,
                    "period": selectedPlanFreq?.frequency
                })
            } else {
                toast.error('Something went wrong! Please try again.')
            }
        }
        else {
            toast.error('Something went wrong! Please try again.')
        }
    }

    const initializePhonepeOnetime = async () => {

        async function callback(response, oneTimeData) {
            if (response === 'USER_CANCEL') {
                setPayCancelled(true);
                setPayFailed(false);
                setPaySuccess(false);
                setPayInitiated(false);
                moengage.track_event('ONETIME_PAYMENT_PLAN_FAILED', {
                    "userId": user?.id
                })
                return;
            } else if (response === 'CONCLUDED') {
                const amount = selectedPlanFreq?.amount
                let res = await AdminServices.getPPPaymentStatus(oneTimeData?.transactionId);
                if (res.type === true) {
                    let d = JSON.parse(res.data);
                    if (d?.data?.state === 'COMPLETED') {
                        moengage.track_event('ONETIME_PAYMENT_PLAN_SUCCESSFUL', {
                            amount: amount,
                            userId: user?.id,
                            plan: selectedPlan?.name,
                            period: selectedPlanFreq?.frequency
                        })
                        setPaySuccess(true);
                        setPayInitiated(false);
                        await updateUserDataOnPaySuccess();
                        navigate(`/payment-success/${selectedPlan?.name?.toLowerCase()}/${isOneTime ? "onetime" : "subscription"}/${selectedPlanFreq?.frequency}/0`);
                    }
                }
                else {
                    setPayInitiated(false);
                    setPayFailed(true);
                }
                return;
            } else {
                setPayCancelled(false);
                setPayFailed(true);
                setPaySuccess(false);
                setPayInitiated(false);
                moengage.track_event('ONETIME_PAYMENT_PLAN_FAILED', {
                    "userId": user?.id
                })
                return;
            }
        }
        const amount = selectedPlanFreq?.amount;
        let data = {
            clientId: user?.id,
            amount: amount * 100,
            mobileNumber: user?.mobile,
            plan: selectedPlan?.name,
            frequency: selectedPlanFreq?.frequency
        }
        if (isCouponApplied) {
            data.couponCode = couponData?.CouponCode;
        }
        let res = await AdminServices.getPPLink(data);
        if (res.type === true) {
            let d = JSON.parse(res.data);
            moengage.track_event('ONETIME_PAYMENT_PLAN_INITIATED', {
                amount: amount,
                userId: user?.id,
                plan: selectedPlan?.name,
                period: selectedPlanFreq?.frequency
            })
            window.PhonePeCheckout.transact({
                tokenUrl: d?.data?.instrumentResponse?.redirectInfo?.url, callback: (response) => {
                    callback(response, { payLink: d?.data?.instrumentResponse?.redirectInfo?.url, transactionId: d?.data?.merchantTransactionId })
                }, type: "IFRAME"
            });
            setPayInitiated(true);
        }
    }

    const completePurchase = () => {
        if (isOneTime) {
            initializePhonepeOnetime();
        }
        else if (isSubscription) {
            if (isValidUPI) {
                initializePhonepeSubscription();
            }
            else {
                toast.error('Enter valid UPI Id!');
            }
        }
    }

    React.useEffect(() => {
        loadPlans();
        return () => {
            onClose();
        }
    }, [])

    React.useEffect(() => {
        if (payInitiated && !isOneTime && subData?.authId) {
            paymentTimerRef.current = setInterval(() => {
                setPaymentTime(prev => prev === 0 ? prev : prev - 1)
            }, 1000)
        }
        else {
            setPaymentTime(600);
            clearInterval(paymentTimerRef.current)
        }
        return () => {
            clearInterval(paymentTimerRef.current)
        }
    }, [payInitiated, subData])

    React.useEffect(() => {
        if (payInitiated && !isOneTime && subData?.authId) {
            subscriptionRef.current = setInterval(() => {
                checkAuthIdStatus()
            }, 5000)
        }
        else {
            clearInterval(subscriptionRef.current)
        }
        return () => {
            clearInterval(subscriptionRef.current)
        }
    }, [payInitiated, subData]);

    React.useEffect(() => {
        if (paymentTime === 0 && !isOneTime) {
            clearInterval(paymentTimerRef.current)
            clearInterval(subscriptionRef.current)
            if (paySuccess === false && payInitiated == true) {
                moengage.track_event('SUBSCRIPTION_PAYMENT_FAILED', {
                    "subscriptionId": subData?.subId,
                    "userId": user?.id,
                    "authId": subData?.authId
                })
                setPayFailed(true);
            }
            setPayInitiated(false);
        }
    }, [paymentTime])

    const VerifyUPI = () => {
        const [upiId1, setUpiId1] = React.useState(upiId);
        const [verifyingUpi, setVerifyingUpi] = React.useState(false);

        const validateVPA = async () => {
            setVerifyingUpi(true);
            var a = await AdminServices.validateVPA(upiId1).then((i) => { return i })
            if (JSON.parse(a?.data)?.data?.exists) {
                moengage.track_event('UPI_VERIFIED', {
                    "upiId": upiId1,
                    "userId": user?.id
                })
                setisValidUPI(JSON.parse(a?.data)?.data?.exists)
                setUpiId(upiId1);
            }
            else {
                toast.error('UPI Id is not valid.')
            }
            setVerifyingUpi(false);
        }

        const inputRef = React.useRef();

        return (
            <div style={{ background: "var(--grey4)", borderRadius: "var(--borderRadius)", padding: "1rem" }}>
                <div className="d-flex justify-content-between">
                    <div className="textMD w-500">
                        Your UPI Id
                    </div>
                </div>
                <Divider style={{ margin: "0.5rem 0" }} />
                <form onSubmit={(e) => {
                    e.preventDefault();
                    validateVPA();
                }}>
                    <div className="d-flex align-items-center" style={{ gap: "1rem" }}>
                        <Input ref={inputRef} value={upiId1} placeholder='Enter your UPI Id' onChange={(e) => setUpiId1(e.target.value)} readOnly={isValidUPI} />
                        {isValidUPI ? <>
                            <BsFillCheckSquareFill style={{ color: "var(--green)", cursor: "pointer" }} size='2rem' />
                            <AiOutlineEdit size='2rem' onClick={() => setisValidUPI(false)} style={{ cursor: "pointer" }} />
                        </> : <button className="btnBlack d-flex align-items-center" type='submit' style={{ gap: "0.5rem" }} disabled={!(upiId1?.includes('@'))} >
                            {(!isValidUPI) ? "Verify" : "Verified"}
                            {verifyingUpi && <LoadingOutlined style={{ fontSize: '1rem', color: "var(--white)" }} spin />}
                        </button>}
                    </div>
                </form>
            </div>
        )
    }

    const PlanPriceBreakup = () => {

        return (
            <div style={{ background: "var(--grey4)", borderRadius: "var(--borderRadius)", padding: "1rem" }}>
                <div className="d-flex justify-content-between" style={{ gap: "1rem" }}>
                    <div className="textESM w-500 dark4">{selectedPlan?.name} plan</div>
                    <div className="textESM w-500 dark4">{CurrencyFormat(selectedPlanFreq?.actualPrice, 'long', 0)}</div>
                </div>
                {selectedPlanFreq?.discount !== 0 && <div className="d-flex justify-content-between mt-2" style={{ gap: "1rem" }}>
                    <div className="textESM w-500 dark4">{selectedPlanFreq?.discount}% discount</div>
                    <div className="textESM w-500 dark4">-&nbsp;{CurrencyFormat(selectedPlanFreq?.amountSaved, 'long', 0)}</div>
                </div>}
                {isCouponApplied && <div className="d-flex justify-content-between mt-2" style={{ gap: "1rem" }}>
                    <div className="textESM w-500 dark4">{couponData?.CouponCode}</div>
                    <div className="textESM w-500 dark4">-&nbsp;{CurrencyFormat(couponData?.DiscountAmount, 'long', 0)}</div>
                </div>}
                <Divider style={{ margin: "0.75rem 0" }} />
                <div className="d-flex justify-content-between" style={{ gap: "1rem" }}>
                    <div className="textMD w-500 dark4">Total</div>
                    <div className="textMD w-700 dark4">{CurrencyFormat(finalDisplayAmount, 'long', 0)}</div>
                </div>
                {!isOneTime && <div className="textESM dark3 mt-1">Billed every {selectedPlanFreq?.frequency === 'Monthly' ? "month" : "year"}.</div>}
                <div style={{ background: "var(--blueShade)", width: "100%", padding: "0.5rem 0.75rem", borderRadius: "var(--borderRadius)", margin: "0.75rem auto 0rem" }}
                    className='textSM'>
                    <div className='w-700 d-flex align-items-center' style={{ gap: "0.35rem" }}>
                        {isSubscription && <RiInformationFill color='var(--primary)' size={'1rem'} />}{isOneTime ? `You plan will be valid for ${selectedPlanFreq?.label}.` : "You can cancel auto-renewal"}
                    </div>
                    {isOneTime && <div className='mt-1 textXS'>
                        {isOneTime ? `After ${selectedPlanFreq?.label}, you will need to purchase a new plan to continue having access to premium features on sharpely. Failing to do so will revoke your access  to premium  features.` : "You can cancel your auto-renewal anytime."}
                    </div>}
                    {isOneTime && <div className='d-flex align-items-center textXS dark4 mt-2' style={{ gap: "0.35rem" }}>
                        <HiCheck color='var(--primary)' size={'1rem'} />  <b>No auto-renewal.</b> Top-up anytime.
                    </div>}
                </div>
            </div>
        )
    }

    const ApplyCouponCode = () => {

        const [couponCode, setCouponCode] = React.useState('');

        const verifyCouponCode = async () => {
            try {
                if (isInvalidTextOrLength({ text: couponCode, checkTextChars: false, checkLength: true })) {
                    toast.error('Enter valid coupon code!');
                }
                else {
                    let res = await AdminServices.validateCoupon({ couponCode: couponCode });
                    if (res?.type === true) {
                        if (res.data?.CouponState === "Valid") {
                            let amt = selectedPlanFreq?.amount;
                            let discountedAmount = Math.ceil((amt) * ((100 - Number(res?.data?.Discount)) / 100));
                            setCouponData({ ...res.data, DiscountedAmount: discountedAmount, DiscountAmount: amt - discountedAmount });
                        }
                        else {
                            toast.error('Invalid Coupon code!')
                        }
                    }
                    else {
                        toast.error('Something went wrong!')
                    }
                }
            } catch (error) {
                toast.error('Something went wrong!')
            }
        }

        return <div>
            <div className='textMD w-700 dark4'>Coupon Code</div>
            {isCouponApplied ? <div style={{ marginTop: "0.5rem" }}>
                <div className='d-flex justify-content-between' style={{ gap: "0.5rem" }}>
                    <div className='d-flex' style={{ gap: "0.5rem", flex: 1 }}>
                        <RiCoupon2Fill size={'1.25rem'} color='var(--primary)' />
                        <div>
                            <div className='textESM w-500 dark4'>YAY! You saved {CurrencyFormat(couponData?.DiscountAmount, 'long', 0)}</div>
                            <div className='textEXS w-500 dark3'>Coupon applied.</div>
                        </div>
                    </div>
                    <AiOutlineCloseCircle size={'1rem'} onClick={() => {
                        setCouponData({});
                    }} style={{ cursor: "pointer" }} />
                </div>
            </div> : < form onSubmit={(e) => {
                e.preventDefault();
                verifyCouponCode();
            }}>
                <div style={{
                    display: "flex", flexDirection: "row", width: "100%", gap: "0.75rem", border: "1px solid var(--grey1)", padding: "0.5rem 0.65rem",
                    borderRadius: "var(--borderRadius)", marginTop: "0.5rem"
                }}>
                    <input placeholder='Enter Coupon code here' style={{ flex: 1, outline: "none", border: "none", background: "transparent" }} autoComplete='false'
                        value={couponCode} onChange={(e) => {
                            setCouponCode(e.target.value.toUpperCase())
                        }} autoCapitalize='characters' />
                    <div className='textESM w-700 h100 d-flex align-items-center justify-content-center' style={{
                        cursor: "pointer", padding: "0 0.5rem", color: isInvalidTextOrLength({ text: couponCode, checkTextChars: false, checkLength: true }) ? "var(--grey1)" : "var(--green)",
                        userSelect: "none"
                    }} onClick={() => {
                        verifyCouponCode();
                    }}>APPLY</div>
                </div>
            </form>
            }
        </div >
    }

    const DefaultContent = () => {

        return (
            <div style={{ minHeight: isMobile ? undefined : "53vh", height: isMobile ? "100%" : "" }} className='d-flex flex-column'>
                <div style={{ padding: isMobile ? "1.25rem" : "", flex: 1 }} className='d-flex flex-column'>
                    <div className={isMobile ? "textLG w-700" : "textXL w-700"}>
                        sharpely {selectedPlan?.name}
                    </div>
                    <div style={{ marginTop: "1.25rem", gap: isMobile ? "1.75rem" : "1.25rem", flex: 1, display: "grid", gridTemplateColumns: isMobile ? "1fr" : "2.4fr 3fr" }} className='w100'>
                        <div style={{}} className='h100 w100 d-flex flex-column justify-content-between'>
                            <div style={{ flex: 1 }}>
                                <div style={{ marginTop: "0.75rem" }}>
                                    <div className='d-flex flex-column' style={{ gap: "1.25rem" }}>
                                        {planPricesAndFreqs?.map((ele) => {
                                            return <div key={ele.value} className='d-flex align-items-start' style={{ gap: "0.25rem", cursor: "pointer" }} onClick={() => {
                                                setFreqAndPayType(ele.value);
                                            }}>
                                                <Radio value={ele.value} checked={freqAndPayType === ele?.value} onChange={(e) => {
                                                    setFreqAndPayType(ele.value)
                                                }} />
                                                <div className='' style={{ marginTop: -3, flex: 1 }}>
                                                    <div className='d-flex align-items-center justify-content-between' style={{ gap: "0.75rem" }}>
                                                        <div className='textMDtoSM w-700 dark4'>{ele.label}</div>
                                                        <div className='d-flex align-items-center justify-content-between' style={{ gap: "0.75rem" }}>
                                                            {ele?.discount > 0 && <div style={{
                                                                background: "linear-gradient(90deg, #00bce5, #2962ff 50.31%, #d500f9)", minWidth: "70px",
                                                                color: "var(--white)", padding: "1px 8px", borderRadius: "var(--borderRadius)", fontSize: isMobile ? '10px' : "12px"
                                                            }} className='w-700 text-center'>Save {ele?.discount}%</div>}
                                                            {ele?.isSubscription === true && <div style={{
                                                                background: "transparent", minWidth: "70px", border: "1px solid var(--grey1)",
                                                                color: "var(--dark4)", padding: "1px 8px", borderRadius: "var(--borderRadius)", fontSize: isMobile ? '10px' : "12px"
                                                            }} className='w-700 text-center'>Recurring</div>}
                                                        </div>
                                                    </div>
                                                    <div className='d-flex align-items-center mt-1' style={{ gap: "0.75rem" }}>
                                                        <div className='textXS w-500 dark3'>{CurrencyFormat(ele?.amount, 'long', 0)}</div>
                                                        {ele?.discount > 0 && <div className='textXS w-500 dark2' style={{ textDecoration: "line-through" }}>{CurrencyFormat(ele?.actualPrice, 'long', 0)}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                                {isCouponAllowed && <div style={{ marginTop: "1rem" }}>
                                    <ApplyCouponCode />
                                </div>}
                            </div>
                            {!isMobile && <div style={{ marginTop: "1.25rem" }}>
                                {isOneTime && <div className='d-flex justify-content-between align-items-center mb-2'>
                                    <div className='textXS dark3'>Ends on</div>
                                    <div className='textXS dark3'>{formatDateMMMDDYYYY(selectedPlanFreq?.endDate)}</div>
                                </div>}
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='textESM dark4 w-700'>Due today</div>
                                    <div className='textESM dark4 w-700'>{CurrencyFormat(finalDisplayAmount, 'long', 0)}</div>
                                </div>
                                <div style={{ marginTop: "1rem" }}>
                                    <button className='btnPrimary w100' onClick={() => {
                                        completePurchase();
                                    }}>Purchase</button>
                                </div>
                            </div>}
                        </div>
                        <div style={{ borderLeft: isMobile ? "" : "1px solid var(--grey3)", paddingLeft: isMobile ? "0" : "1.25rem" }} className='w100 h100'>
                            <div>
                                {isSubscription && <div style={{ marginBottom: "1rem" }}>
                                    <VerifyUPI />
                                </div>}
                                <div className='textMD w-700 dark4'>{selectedPlanFreq?.label} - {isOneTime ? 'one time' : isSubscription ? "subscription" : ""}</div>
                                {selectedPlanFreq?.discount !== 0 &&
                                    <div className='textSM dark3 mt-2'>Save <b className='dark4'>{CurrencyFormat(selectedPlanFreq?.amountSaved, 'long', 0)}</b> as compared to monthly plan.</div>}
                                <div style={{ marginTop: "1rem" }}>
                                    <PlanPriceBreakup />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isMobile && <div style={{
                    padding: "1.25rem", position: "sticky", bottom: 0, background: "var(--white)", zIndex: 100,
                    boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px'
                }}>
                    {isOneTime && <div className='d-flex justify-content-between align-items-center mb-2'>
                        <div className='textXS dark3'>Ends on</div>
                        <div className='textXS dark3'>{formatDateMMMDDYYYY(selectedPlanFreq?.endDate)}</div>
                    </div>}
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='textESM dark4 w-700'>Due today</div>
                        <div className='textESM dark4 w-700'>{CurrencyFormat(finalDisplayAmount, 'long', 0)}</div>
                    </div>
                    <div style={{ marginTop: "1rem" }}>
                        <button className='btnPrimary w100' onClick={() => {
                            completePurchase();
                        }}>Purchase</button>
                    </div>
                </div>}
            </div >
        )
    }

    const PaymentInitiated = () => {

        return (
            <div style={{ display: "flex", flexDirection: "column", height: isMobile ? "100%" : "" }}>
                <div style={{ padding: isMobile ? "1.25rem" : "", flex: isMobile ? 1 : '' }}>
                    <div className={isMobile ? "textLG w-700" : "textXL w-700"}>
                        Complete your payment
                    </div>
                    {isOneTime ? <div style={{ marginTop: "1rem" }}>
                        <div className='textSM w-500 '>
                            Your payment request is initiated and you will be redirected to payment portal. If not redirected automatically, then click the below button to complete your payment.
                        </div>
                    </div> : <div style={{ marginTop: "1rem" }}>
                        <div className='textMD w-500 '>
                            Please open your UPI app and approve the collect request sent.
                        </div>
                    </div>}
                    {!isOneTime ? <div className='d-flex align-items-center justify-content-center' style={{ padding: "2rem 0" }}>
                        <div className='d-flex flex-column align-items-center justify-content-center' style={{
                            border: "4px solid var(--primary)", borderRadius: "50%", height: "150px",
                            width: "150px"
                        }}>
                            <div className='textESM dark4 w-500'>Request expires in </div>
                            <div className='textMD w-700' style={{ color: "var(---primary)" }}>
                                {Math.floor(paymentTime / 60) <= 9 ? `0${Math.floor(paymentTime / 60)}` : Math.floor(paymentTime / 60)}:
                                {Math.floor(paymentTime % 60) <= 9 ? `0${Math.floor(paymentTime % 60)}` : Math.floor(paymentTime % 60)}s
                            </div>
                        </div>
                    </div> : <div className='d-flex flex-column align-items-center mt-3'>
                        <img width={140} height={140} style={{
                            height: 140,
                            width: 140,
                        }} src={require('Assets/lottie/status.gif')} alt="loading..." />
                        <div className='textLG w-500 text-center black mb-3' style={{ userSelect: "none" }}>
                            Checking payment status
                        </div>
                    </div>}
                    <div className='textSM w-500 dark4 mt-1'>
                        Note: Please keep this payment panel open. It will close automatically when the payment process is complete.
                    </div>
                </div>
                {!isMobile && <div className='d-flex justify-content-end mt-2'>
                    <button className='btnBlack' style={{}} onClick={() => {
                        onClose();
                    }}>Cancel</button>
                </div>}
                {isMobile && <div style={{
                    padding: "1.25rem", position: "sticky", bottom: 0, background: "var(--white)", zIndex: 100,
                    boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px'
                }}>
                    <div style={{ marginTop: "0rem" }}>
                        <button className='btnBlack w100' onClick={() => {
                            onClose();
                        }}>Cancel</button>
                    </div>
                </div>}
            </div>
        )
    }

    const PaymentFailed = () => {

        return (
            <div style={{ padding: isMobile ? "1.25rem" : "" }}>
                <div className={isMobile ? "textLG w-700" : "textXL w-700"}>
                    Payment failed!
                </div>
                <div className='d-flex flex-column align-items-center mt-3'>
                    <img width='10rem' height='10rem' style={{
                        height: "10rem",
                        width: "10rem",
                    }} src={require('Assets/lottie/order-failed.gif')} alt="loading..." />

                    <div className='textXL w-500 text-center black mb-3' style={{ userSelect: "none" }}>
                        Payment failed.
                    </div>
                    <div className='textSM w-500 text-center dark3 mb-5' style={{ width: "90%", margin: "auto", userSelect: "none" }}>
                        The payment has failed, either due to the request expiring or encountering other issues such as network problems or bank server issues. If any amount has been deducted from your account, please report it immediately.
                    </div>
                </div>
            </div>
        )
    }

    const PaymentCancelled = () => {

        return (
            <div style={{ padding: isMobile ? "1.25rem" : "" }}>
                <div className={isMobile ? "textLG w-700" : "textXL w-700"}>
                    Payment cancelled!
                </div>
                <div className='d-flex flex-column align-items-center mt-3'>
                    <img width='10rem' height='10rem' style={{
                        height: "10rem",
                        width: "10rem",
                    }} src={require('Assets/lottie/order-failed.gif')} alt="loading..." />
                    <div className='textXL w-500 text-center black mb-3' style={{ userSelect: "none" }}>
                    </div>
                    <div className='textSM w-500 text-center dark3 mb-5' style={{ width: "90%", margin: "auto", userSelect: "none" }}>
                        Payment cancelled. Can you let us know why? We're all ears and ready to assist! <Link to='/contact-us'>Contact us.</Link>
                    </div>
                </div>
            </div>
        )
    }

    const PaymentSuccess = () => {

        return (
            <div style={{ padding: isMobile ? "1.25rem" : "" }}>
                <div className={isMobile ? "textLG w-700" : "textXL w-700"}>
                    Payment successful!
                </div>
                <div className='d-flex flex-column align-items-center'>
                    <img width='10rem' height='10rem' style={{
                        height: "10rem",
                        width: "10rem",
                    }} src={require('Assets/lottie/congratulations.gif')} alt="loading..." />
                    <div className='textXL w-500 text-center black mb-3' style={{ userSelect: "none" }}>
                        Hurray!
                    </div>
                    <div className='textSM w-500 text-center dark3 mb-5' style={{ width: "90%", margin: "auto", userSelect: "none" }}>
                        Congratulations! Your plan has been successfully upgraded to sharpely {selectedPlan?.name}, unlocking a whole new realm of exciting features for you to explore. Enjoy the enhanced experience!
                    </div>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            {isMobile ? <Drawer open={openPG} styles={{
                body: {
                    padding: "0rem",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                },
                header: {
                    padding: "1.25rem"
                }
            }} closable={false} title={<div
                className='d-flex justify-content-between align-items-center'>
                <Logo type='dark' isClickable={false} />
                <AiOutlineClose onClick={onClose} style={{ cursor: "pointer" }} />
            </div>}>
                {payInitiated ? <PaymentInitiated /> : payFailed ? <PaymentFailed /> : payCancelled ? <PaymentCancelled /> : paySuccess ? <PaymentSuccess /> :
                    <DefaultContent />}
            </Drawer> : <Modal open={openPG} footer={null} width={'50vw'} centered closable={false} onCancel={() => {
                if (payInitiated === true) {
                    return;
                }
                else {
                    onClose();
                }
            }}>
                {payInitiated === true ? <Popconfirm title={<span className='textSM w-500 dark4' style={{ userSelect: "none" }}>
                    Are you sure you want to cancel this<br /> transaction?</span>} arrow={false} cancelText='Yes' okText='No' onCancel={() => {
                        onClose();
                    }} placement='bottomRight'>
                    <IoIosClose size={'2rem'} style={{ position: "absolute", top: "0.75rem", right: "0.75rem", cursor: "pointer" }} />
                </Popconfirm> : <IoIosClose style={{ position: "absolute", top: "0.75rem", right: "0.75rem", cursor: "pointer" }} size={'2rem'} onClick={() => onClose()} />}
                {payInitiated ? <PaymentInitiated /> : payFailed ? <PaymentFailed /> : payCancelled ? <PaymentCancelled /> : paySuccess ? <PaymentSuccess /> :
                    <DefaultContent />}
            </Modal>}
        </React.Fragment>
    )
}