import moengage from "@moengage/web-sdk";
import { alphaFill, alphaLine, annualreturn, basketFill, basketLine, blog, compareLine, earningLine, etfFill, etfLine, explore, factorFill, factorLine, faq, magicFill, magicLine, mfFill, mfLine, minSip, screenLine, screenerFill, sectorLine, stockFill, stockLine, styleboxLine } from "Assets/images";
import { GlobalInstrumentSearch } from "Components/CustomComponents/GlobalInstrumentSearch";
import db from "Libs/db";
import { toggleBasketCreateModal } from "Libs/redux/Modals/reduxSlice";
import { updateStockPrices, updatesubscribedStocks } from "Libs/redux/StockLivePrice/reduxSlice";
import { updatePrevStockPrices } from "Libs/redux/StocksPrevPrices/reduxSlice";
import { updateUserData, updateWatchlists } from "Libs/redux/UserAuth/reduxSlice";
import { makeid } from "Libs/utils";
import { Avatar, Divider, Drawer, Popover, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import { AiOutlineClose } from 'react-icons/ai';
import { BiMoneyWithdraw } from "react-icons/bi";
import { BsBookmark } from "react-icons/bs";
import { FaRupeeSign } from "react-icons/fa";
import { FaRegLightbulb } from "react-icons/fa6";
import { FiBriefcase, FiLogOut } from "react-icons/fi";
import { GiGiftOfKnowledge } from "react-icons/gi";
import { GrAnnounce } from 'react-icons/gr';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { MdDashboard, MdFilterList, MdOutlineEventAvailable, MdOutlineExplore } from "react-icons/md";
import { RxHamburgerMenu } from 'react-icons/rx';
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setFilterAppFrNav, setLetter, setOpenSearchModal } from "../Libs/redux/Modals/reduxSlice";
import Logo from "./Logo";
import Notifications from "./Notifications";
import User from "./User";

const Header = () => {

  const loc = useLocation()
  const navigate = useNavigate();
  const user = useSelector((state) => state?.updateUserData?.value)
  const userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel || 0;
  const [navDrawer, setNavDrawer] = useState(false);
  const [selectedDrawerItem, setSelectedDrawerItem] = React.useState({});
  const dispatch = useDispatch();
  const themeNM = useSelector((state) => state?.changeTheme?.value)

  let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
  let isProd = process.env.REACT_APP_ENVIRONMENT === 'prod';
  const openSearchModal = useSelector((s) => s?.globalInsSearch?.openSearchModal);

  const dropdownItems = (type) => (
    type === 100 ?
      // for market
      [
        {
          label: 'Sector analysis',
          to: "/stocks/sectors",
          icon: 'https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/sector.webp',
          iconType: "image"
        },
        {
          label: "Index analysis",
          icon: 'https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/index.webp',
          to: '/indices',
          iconType: "image"
        },
        {
          label: "Sector rotation chart",
          icon: 'https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/screener.webp',
          iconType: "image",
          to: '/sector-rotation-chart'
        },
        {
          label: "Insider trading activity",
          icon: 'https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/strategy1.webp',
          to: '/insider-trades',
          iconType: "image"
        },
        {
          label: "NFO Tracker",
          icon: 'https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/stylebox.webp',
          to: '/nfo-tracker',
          iconType: "image"
        }
      ]
      :
      [
        {
          label: (type === 4 ? "Stock" : type === 1 ? "ETF" : "MF") + ' dashboard',
          to: (type === 4 ? "/stocks" : type === 1 ? "/etfs" : "/mutual-funds") + '/dashboard',
          show: type !== 4,
          Icon: MdDashboard,
          iconType: "react-icon",
          iconColor: "var(--featureIconBlue)"
        },
        {
          label: (type === 4 ? "Stock" : type === 1 ? "ETF" : "MF") + ' screener',
          to: type === 4 ? "/stocks/screener" : (type === 1 ? "/etfs" : "/mutual-funds") + '/screener',
          show: true,
          icon: 'https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/screener.webp',
          iconType: "image",
        },
        {
          label: 'Compare ' + (type === 4 ? "stocks" : type === 1 ? "ETFs" : "MFs"),
          to: (type === 4 ? "/stocks" : type === 1 ? "/etfs" : "/mutual-funds") + '/compare',
          show: true,
          icon: 'https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/compare.webp',
          iconType: "image",
        },
        {
          label: 'Alpha analysis',
          to: (type === 4 ? "/stocks" : type === 1 ? "/etfs" : "/mutual-funds") + '/alpha-analysis',
          show: type === 1 || type === 2,
          icon: 'https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/alpha.webp',
          iconType: "image",
        },
        {
          label: "Magicboard",
          icon: 'https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/magic.webp',
          show: type !== 4,
          iconType: "image",
          to: (type === 1 ? "/etfs" : "/mutual-funds") + '/magicboard'
        },
        {
          label: 'Stock Checklist',
          to: '/stocks/all-checklists',
          show: type === 4,
          icon: 'https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/checklist.webp',
          iconType: "image",
        },
        {
          label: 'Stock StyleBox',
          to: (type === 4 ? "/stocks" : type === 1 ? "/etfs" : "/mutual-funds") + '/styleBox',
          show: type === 4,
          icon: 'https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/stylebox.webp',
          iconType: "image",
        },
        {
          label: "Earnings dashboard",
          to: (type === 4 ? "/stocks" : type === 1 ? "/etfs" : "/mutual-funds") + '/earnings-dashboard',
          show: type === 4,
          icon: 'https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/earnings.webp',
          iconType: "image",
        },
        {
          label: "Event calendar",
          to: "/event-calendar",
          show: type === 4,
          iconType: "react-icon",
          iconColor: "var(--featureIconBlue)",
          Icon: MdOutlineEventAvailable
        },
        {
          label: "Watchlist Feed",
          to: "/stocks/watchlist-feed",
          show: type === 4,
          iconType: "react-icon",
          iconColor: "var(--featureIconBlue)",
          Icon: GrAnnounce
        },
        {
          label: "Explore",
          to: "",
          show: true,
          Icon: MdOutlineExplore,
          iconType: "react-icon",
          type: "cta",
          iconColor: "var(--featureIconBlue)",
          ctaAction: () => {
            dispatch(setFilterAppFrNav(type));
            dispatch(setOpenSearchModal(true));
          }
        },
      ]?.filter((ele) => ele?.show)
  )

  const calculators = (type) => (
    [
      {
        label: "SIP Returns",
        to: (type === 1 ? '/etfs' : '/mutual-funds') + '/calculators/sip-calculator',
        show: true,
        icon: 'https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/earnings.webp',
        iconType: "image"
      },
      {
        label: "Rolling Returns",
        to: (type === 1 ? '/etfs' : '/mutual-funds') + '/calculators/rr-calculator',
        show: true,
        icon: 'https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/screener.webp',
        iconType: "image"
      },
      {
        label: "SWP Calculator",
        to: (type === 1 ? '/etfs' : '/mutual-funds') + '/calculators/swp-calculator',
        show: true,
        icon: 'https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/factor.webp',
        iconType: "image"
      }
    ]
  )

  const otherTools = [
    {
      label: "Market",
      // icon: factorFill,
      // iconType: "noBg",
      children: dropdownItems(100)
    },
    {
      label: "Stocks",
      // icon: stockFill,
      // iconType: "noBg",
      children: dropdownItems(4)
    },
    {
      label: "ETFs",
      // icon: etfFill,
      // iconType: "noBg",
      children: dropdownItems(1),
      otherItems: [{ heading: "Calculators", children: calculators(1) }]
    },
    {
      label: "Mutual Funds",
      // icon: mfFill,
      // iconType: "noBg",
      children: dropdownItems(2),
      otherItems: [{ heading: "Calculators", children: calculators(2) }]
    }
  ]

  const handleDrawerClose = (linkTo) => {
    setNavDrawer(false);
    if (linkTo) {
      setTimeout(() => {
        navigate(linkTo)
      }, 300)
    }
  }

  const resetData = () => {
    dispatch(updatePrevStockPrices([]));
    dispatch(updateStockPrices([]));
    dispatch(updatesubscribedStocks({}));
    dispatch(updateWatchlists(undefined));
    dispatch(updateUserData(undefined));
  }

  const logout = () => {
    moengage.destroy_session();
    moengage.track_event('Logout')
    resetData();
    db.clear();
    setNavDrawer(false);
    navigate('/');
  }

  const handleKeyDown = (event) => {
    const preventPaths = ['/stocks/super-screener', '/stocks/factor-model', '/strategy-detail', '/etfs/screener', '/stocks/build-custom-strategy', '/etfs/build-custom-strategy',
      '/mutual-funds/build-custom-strategy'];
    const preventPathsEqual = ['/']
    const isGlobalSearch = document.querySelector('.modalSearchBarGlobal>input');
    const isShortcutKeyPressed = event.metaKey || event.ctrlKey;
    const focusedElement = document.activeElement;
    const isGlobalFocused = focusedElement === isGlobalSearch;
    const isInputElement = focusedElement instanceof HTMLInputElement || focusedElement instanceof HTMLTextAreaElement;
    const isPreventPath = preventPaths?.some((el) => window.location.pathname.startsWith(el)) || preventPathsEqual.some((el) => window.location.pathname === el);
    if (event.key !== ' ' && !isShortcutKeyPressed && (!isInputElement || isGlobalFocused) && !isPreventPath) {
      let letters = 'abcdefghijklmnopqrstuvwxyz';
      if (letters?.includes(event.key?.toLowerCase())) {
        dispatch(setOpenSearchModal(true));
        dispatch(setLetter(prev => prev + event.key));
      }
      else if (event.key === 'Escape') {
        dispatch(setOpenSearchModal(false));
      }
    }
  }

  React.useEffect(() => {
    setNavDrawer(false);
  }, [loc.pathname])

  React.useEffect(() => {
    if (!navDrawer) {
      setSelectedDrawerItem({})
    }
  }, [navDrawer])

  React.useEffect(() => {
    if (!openSearchModal) {
      window.addEventListener('keydown', handleKeyDown);
    }
    else {
      window.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, [openSearchModal]);

  const ItemIcon = ({ item = {}, size = '2rem', padding = '0.35rem' }) => {
    let isIconBg = item?.iconType !== 'noBg';
    return (
      <>
        {item?.iconType === 'react-icon-bg' ? <div style={{ height: size, width: size, background: item?.iconBg, borderRadius: "50%", padding: padding, }}
          className="d-flex align-items-center justify-content-center">
          <item.Icon style={{
            color: item?.iconColor, maxHeight: "100%", maxWidth: "100%"
          }} />
        </div> : item?.iconType === 'react-icon' ? <item.Icon size={size} color={item?.iconColor} style={{ padding: '2px' }} /> : item?.iconType === 'image' ? <div style={{
          width: size, height: size, padding: 0, flexShrink: 0,
          background: "", borderRadius: ""
        }} className='d-flex align-items-center justify-content-center'>
          <img loading="lazy" src={item?.icon} alt={item?.label + ' icon'} style={{ width: "100%", height: "100%", maxWidth: "100%" }} />
        </div> : <div style={{
          width: size, height: size, padding: isIconBg ? padding : "0", flexShrink: 0,
          background: isIconBg ? item?.iconBg : "", borderRadius: isIconBg ? "50%" : ""
        }}
          className='d-flex align-items-center justify-content-center'>
          <img loading="lazy" src={item?.icon} alt={item?.label + ' icon'} style={{ width: "100%", height: "100%", maxWidth: "100%" }} />
        </div>}
      </>
    )
  }

  function HeaderDropdown({ children, items = [], dropdownType = 1 }) {

    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const handleModalOpen = (val) => { setOpen(val); }

    const DropdownItem = ({ item }) => {
      return (
        <div className='d-flex align-items-center w100 flex-column text-center' style={{ gap: "0.5rem", width: "6.6rem", height: "100%", cursor: "pointer" }} key={item?.label}
          onClick={() => {
            if (item?.type === 'cta') {
              handleModalOpen(false);
              item.ctaAction();
            }
            else {
              handleModalOpen(false);
              navigate(item.to)
            }
          }}>
          {(item?.icon || item?.Icon) && <ItemIcon item={item} style={{ color: 'red' }} />}
          <div className="textXS w-500 black">
            {item?.label}
          </div>
        </div>
      )
    }

    const DropdownItem2 = ({ items, level = 1, otherItems = [] }) => {

      const [selectedItem, setSelectedItem] = React.useState(items?.[0]);

      return (
        <React.Fragment>
          <div style={{ minWidth: "12rem", padding: "0.75rem 0", borderLeft: level !== 1 ? "1px solid var(--grey3)" : "" }}>
            {items?.map((item, i) => {
              return (
                <div key={makeid(i + 1)} style={{
                  display: "flex", alignItems: "center", justifyContent: "space-between", gap: "1rem", padding: level === 1 ? "0.75rem 0.75rem" : "0.5rem 0.75rem",
                  cursor: "pointer"
                }}
                  className={selectedItem?.label === item?.label && level === 1 ? "activeHeaderDropdownItem" : level !== 1 ? "headerDropdownItem" : ""} onMouseOver={() => {
                    setSelectedItem(item);
                  }} onClick={() => {
                    if (item?.type === 'cta') {
                      handleModalOpen(false);
                      item.ctaAction();
                    }
                    else if (item?.to) {
                      handleModalOpen(false);
                      navigate(item.to)
                    }
                  }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", flex: 1 }}>
                    {(item?.icon || item?.Icon) && <ItemIcon item={item} size={level === 1 ? "2rem" : "1.25rem"} padding={level === 1 ? "0.35rem" : "0.25rem"} />}
                    <div style={{ whiteSpace: "nowrap", userSelect: "none" }} className={level === 1 ? "textSM w-500 dark4" : "textXS w-500 dark4"}>
                      {item?.label}
                    </div>
                  </div>
                  {item?.children && item?.children?.length > 0 && <IoIosArrowForward />}
                </div>
              )
            })}
            {otherItems?.map((item, i) => {
              return (
                <div key={makeid(i + 1)}>
                  {item?.heading && <div className='textSM w-500 dark3 my-2' style={{ paddingLeft: level === 1 ? "0.75rem" : "0.5rem" }}>
                    {item?.heading}
                  </div>}
                  {item?.children?.map((item, i) => {
                    return (
                      <div key={makeid(i + 1)} style={{
                        display: "flex", alignItems: "center", justifyContent: "space-between", gap: "1rem", padding: level === 1 ? "0.75rem 0.75rem" : "0.5rem 0.75rem",
                        cursor: "pointer"
                      }}
                        className={selectedItem?.label === item?.label && level === 1 ? "activeHeaderDropdownItem" : level !== 1 ? "headerDropdownItem" : ""} onMouseOver={() => {
                          setSelectedItem(item);
                        }} onClick={() => {
                          if (item?.type === 'cta') {
                            handleModalOpen(false);
                            item.ctaAction();
                          }
                          else if (item?.to) {
                            handleModalOpen(false);
                            navigate(item.to)
                          }
                        }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", flex: 1 }}>
                          <ItemIcon item={item} size={level === 1 ? "2rem" : "1.25rem"} padding={level === 1 ? "0.35rem" : "0.25rem"} />
                          <div style={{ whiteSpace: "nowrap", userSelect: "none" }} className={level === 1 ? "textSM w-500 dark4" : "textXS w-500 dark4"}>
                            {item?.label}
                          </div>
                        </div>
                        {item?.children && item?.children?.length > 0 && <IoIosArrowForward />}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
          {selectedItem?.children && selectedItem?.children?.length > 0 && <DropdownItem2 items={selectedItem?.children} level={level + 1} otherItems={selectedItem?.otherItems} />}
        </React.Fragment>
      )
    }

    if (dropdownType === 2) {
      return (
        <React.Fragment>
          <Popover open={open} trigger="hover" overlayInnerStyle={{ padding: "0", position: "absolute", top: "-15px", background: "var(--white)" }}
            onOpenChange={(e) => handleModalOpen(e)} getPopupContainer={triggerNode => triggerNode.parentNode} arrow={false} placement='topLeft'
            autoAdjustOverflow={{ adjustX: false, adjustY: false }} content={<React.Fragment>
              <div style={{ padding: "0em", display: "flex", flexDirection: "row", borderRadius: "var(--borderRadius)" }}>
                <DropdownItem2 items={items} />
              </div>
            </React.Fragment>} >
            {children}
          </Popover>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <Popover open={open} trigger="hover" overlayInnerStyle={{ padding: "0", position: "absolute", top: "-15px", background: "var(--white)" }}
          onOpenChange={(e) => handleModalOpen(e)} getPopupContainer={triggerNode => triggerNode.parentNode} arrow={false} placement='topLeft'
          autoAdjustOverflow={{ adjustX: false, adjustY: false }} content={<React.Fragment>
            <div style={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "1.5rem" }}>
              {items?.map((group, i) => {
                return (
                  <div style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }} key={makeid(i + 1)}>
                    {group?.heading && <div className="d-flex align-items-center" style={{ gap: "0.875rem" }}>
                      <span className="dark4 textSM w-700">{group.heading}</span>
                      <div style={{ borderTop: "1px solid var(--grey1)", flex: 1 }}></div>
                    </div>}
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "2rem 1rem", width: "21.8rem" }}>
                      {group?.children?.map((item, k) => {
                        return <DropdownItem item={item} key={makeid(k)} />
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </React.Fragment>} >
          {children}
        </Popover>
      </React.Fragment>
    )
  }

  const navItems = [
    {
      key: 1,
      label: <Link key={6} to="/stocks/dashboard">
        <span className={"menu-item textSM w-500"} style={{ display: "inline" }}>Dashboard</span>
      </Link>,
    },
    {
      key: 'strategies',
      label: <Link key={61} to="/strategies" >
        <span className={"menu-item textSM w-500"} style={{ display: "inline" }}>Strategies</span>
      </Link >
    },
    // {
    //   key: 'screens',
    //   label: <HeaderDropdown key={'screens'} items={[
    //     { heading: "Create new", children: screensNew() },
    //     { heading: "Explore", children: screensExploreNew(1) }]
    //   } >
    //     <span key={'screens'} style={{
    //       cursor: "pointer"
    //     }} >
    //       <span className={"menu-item textSM w-500"} style={{ display: "inline" }}>
    //         Screener <IoIosArrowDown />
    //       </span>
    //     </span>
    //   </HeaderDropdown>
    // },
    {
      key: 'screens',
      label: <Link key={62} to="/screens" >
        <span className={"menu-item textSM w-500"} style={{ display: "inline", whiteSpace: 'nowrap' }}>Screener</span>
      </Link >
    },
    {
      key: 'factor-model',
      label: <Link key={63} to="/factor-models" >
        <span className={"menu-item textSM w-500"} style={{ display: "inline", whiteSpace: 'nowrap' }}>Factor Models</span>
      </Link >
    },
    // {
    //   key: 'factor-model',
    //   label: <HeaderDropdown key={'models'} items={[
    //     {
    //       heading: "Create new", children: [{
    //         label: "Stock factor model",
    //         to: '/stocks/factor-model',
    //         show: true,
    //         icon: factorLine,
    //         iconBg: "var(--blueShade)"
    //       }]
    //     },
    //     { heading: "Explore", children: factorExploreNew(1) }]
    //   } >
    //     <span key={'models'} style={{
    //       cursor: "pointer"
    //     }} >
    //       <span className={"menu-item textSM w-500"} style={{ display: "inline" }}>
    //         Factor Model <IoIosArrowDown />
    //       </span>
    //     </span>
    //   </HeaderDropdown>
    // },
    {
      key: "OtherTools",
      label: <HeaderDropdown key={'OtherTools'} items={otherTools} dropdownType={2} >
        <span key={'OtherTools'} style={{
          cursor: "pointer"
        }} >
          <span className={"menu-item textSM w-500"} style={{ display: "inline" }}>
            Analysis Tools <IoIosArrowDown />
          </span>
        </span>
      </HeaderDropdown>
    },
    {
      key: "8",
      label: <Link key={9} to={'/pricing'} >
        {user?.isLogged ? userAccesslevel < 2 ? <span className="menu-item textSM w-500">Upgrade Plan ✨</span> : <></> :
          <span className={"menu-item textSM w-500"}>
            Pricing
          </span>}</Link>
    }
  ];

  const drawerNavItems = [
    {
      heading: "",
      items: [
        {
          key: 'dashboard',
          label: "Dashboard",
          to: "/stocks/dashboard",
          icon: alphaFill,
          iconType: "image",
        },
        {
          key: "strategies",
          label: "Strategies",
          icon: basketFill,
          iconType: "image",
          to: "/strategies"
        },
        // {
        //   key: "screener",
        //   label: "Screener",
        //   icon: screenerFill,
        //   iconType: 'image',
        //   items: [
        //     { heading: "Create new", items: screensNew() },
        //     { heading: "Explore", items: screensExploreNew(1) }
        //   ]
        // },
        {
          key: "screener",
          label: "Screener",
          icon: screenerFill,
          iconType: "image",
          to: "/screens"
        },
        {
          key: "factorModel",
          label: "Factor Models",
          icon: factorFill,
          iconType: "image",
          to: "/factor-models"
        },
        // {
        //   key: "factorModel",
        //   label: "Factor Model",
        //   icon: factorFill,
        //   iconType: 'image',
        //   items: [
        //     {
        //       heading: "Create new",
        //       items: [
        //         {
        //           label: "Stock factor model",
        //           to: '/stocks/factor-model',
        //           show: true,
        //           icon: factorLine,
        //           iconBg: "var(--blueShade)"
        //         }
        //       ]
        //     },
        //     { heading: "Explore", items: factorExploreNew(1) }
        //   ]
        // },
        {
          key: "otherTools",
          label: "Analysis Tools",
          icon: magicFill,
          iconType: "image",
          items: [
            {
              label: "Market",
              icon: factorFill,
              iconType: "noBg",
              items: dropdownItems(100)
            },
            {
              key: "stocks",
              label: "Stocks",
              icon: stockFill,
              iconType: "noBg",
              items: dropdownItems(4)
            },
            {
              key: "etfs",
              label: "ETFs",
              icon: etfFill,
              iconType: "noBg",
              items: [
                { heading: "", items: dropdownItems(1) },
                { heading: "Calculators", items: calculators(1) }
              ]
            },
            {
              key: "mfs",
              label: "Mutual Funds",
              icon: mfFill,
              iconType: "noBg",
              items: [
                { heading: "", items: dropdownItems(2) },
                { heading: "Calculators", items: calculators(2) }
              ]
            }
          ]
        },
        {
          key: "knowledgebase",
          label: "Knowledge Base",
          to: "/knowledge-base",
          Icon: GiGiftOfKnowledge,
          iconType: "react-icon-bg",
          iconBg: "var(--neonShade)",
        },
        {
          key: "watchlist",
          label: "Watchlist",
          to: "/watchlist",
          Icon: BsBookmark,
          iconType: "react-icon-bg",
          iconBg: "var(--blueShade)",
        },
        {
          key: "investments",
          label: "Investments",
          to: "/investments",
          Icon: FiBriefcase,
          iconType: "react-icon-bg",
          iconBg: "var(--purpleShade)",
        },
        {
          key: "pricing",
          label: "Pricing",
          to: "/pricing",
          Icon: FaRupeeSign,
          iconType: "react-icon-bg",
          iconBg: "var(--greenShade)",
          iconColor: "var(--green)"
        }
      ]
    }
  ]

  const DrawerNavItem = ({ item = {} }) => {
    return (
      <div className="d-flex align-items-center" style={{ gap: "0.75rem", padding: "1rem 1.25rem", cursor: "pointer" }} onClick={() => {
        if (item?.type === 'cta') {
          setNavDrawer(false);
          setTimeout(() => {
            item.ctaAction()
          }, 300)
        }
        else if (item?.to) {
          handleDrawerClose(item?.to)
        }
        else {
          setSelectedDrawerItem({
            title: item?.label,
            currentItem: item,
            previousItem: selectedDrawerItem,
          })
        }
      }}>
        <ItemIcon item={item} />
        <div className='textMD w-500 dark4' style={{ flexGrow: 1 }}>{item?.label}</div>
        {item?.items?.length > 0 && <IoIosArrowForward size='1.5rem' />}
      </div>
    )
  }

  const DrawerNavItemsList = ({ items = [] }) => {

    return (
      <div style={{ flexGrow: 1, overflow: "auto" }}>
        {items?.map((item, i) => {
          let check = item?.hasOwnProperty('heading');
          if (check) {
            return (
              <div key={makeid(i)}>
                {item?.heading ? <div className='textSM w-500 dark3' style={{ padding: "0.75rem 1.25rem" }}>{item?.heading}</div> : null}
                <DrawerNavItemsList items={item?.items} />
              </div>
            )
          }
          else {
            return (
              <DrawerNavItem item={item} key={makeid(i)} />
            )
          }
        })}
      </div>
    )
  }

  return (
    <div className='headerContainer' style={{
      display: "flex", gap: "1.25rem", alignItems: "center", height: "100%", justifyContent: 'space-between',
      maxWidth: isDev ? "1600px" : "", margin: isDev ? "auto" : '',
    }}>
      <div style={{ display: "flex", gap: "1.5rem", alignItems: "center" }}>
        <Logo type={themeNM === "light" ? "dark" : "light"} userAccessLevel={userAccesslevel} withPlanName />
        <div style={{ display: "flex", gap: "1.25rem", alignItems: "center" }} className='navLargeScreen'>
          {navItems.map(m => { return m.label })}
        </div>
      </div>
      <>
        <div style={{ display: "flex", gap: "1rem", alignItems: "center", justifyContent: "flex-end" }} className=''>
          <GlobalInstrumentSearch />
          <RxHamburgerMenu className='navSmallScreen black mobileNavIcon textMD' style={{ display: "none", cursor: "pointer", width: '24px', height: '24px' }}
            onClick={() => setNavDrawer(true)} />
          <div style={{ display: "flex", gap: "1rem", alignItems: "center", justifyContent: "flex-end" }} className='navLargeScreen'>
            {user?.isLogged && <Notifications />}
            {/* <Divider style={{ height: "2rem", margin: 0 }} type="vertical" /> */}
            <Link to='/knowledge-base'>
              <Tooltip title='Knowledge Base' arrow={false}>
                <FaRegLightbulb size={'1rem'} color='var(--black)' />
              </Tooltip>
            </Link>
            <Divider style={{ height: "2rem", margin: 0 }} type="vertical" />
            {userAccesslevel > 1 && <Link to="/user-profile?tab=myplan" >
              <Tag color={userAccesslevel === 2 ? "blue" : userAccesslevel === 3 ? "geekblue" : "default"} className="textXS" style={{ cursor: "pointer", lineHeight: undefined, margin: 0, fontSize: "0.6rem", border: userAccesslevel === 4 ? "1px solid black" : "" }}>
                {userAccesslevel === 2 ? "Lite Plan" : userAccesslevel === 3 ? "Pro Plan" : "Black Plan"}
              </Tag>
            </Link>}
            {userAccesslevel > 1 && <Divider style={{ height: "2rem", margin: 0 }} type="vertical" />}
            <User user={user} isProd={isProd} />
          </div>
        </div>
        <Drawer open={navDrawer} onClose={handleDrawerClose} title={<div className='d-flex align-items-center justify-content-between w100'>
          {selectedDrawerItem?.title ? <div className='d-flex align-items-center' style={{ gap: "0.75rem", cursor: "pointer", padding: "0.75rem 0" }} onClick={() => {
            if (selectedDrawerItem?.previousItem) {
              setSelectedDrawerItem(selectedDrawerItem?.previousItem)
            }
            else {
              setSelectedDrawerItem({});
            }
          }}>
            <IoIosArrowForward size='1.5rem' style={{ transform: "rotate(180deg)" }} />
            <div className='textMD w-700 dark4'>{selectedDrawerItem?.title}</div>
          </div> :
            <Logo type={themeNM === "light" ? "dark" : "light"} />}
          <AiOutlineClose onClick={handleDrawerClose} size='1.5rem' />
        </div>}
          closable={false} width='100vw' styles={{
            header: { padding: "0.75rem 1.25rem", background: "var(--white)" }, body: { padding: "0", background: "var(--white)" },
            mask: { background: "var(--white)" }
          }}
          className='navSmallScreen'>
          <div className="d-flex flex-column w100 h100" style={{ overflow: "hidden" }}>
            {(user !== undefined && user !== null) && <>
              <div className="d-flex align-items-center" style={{ gap: "0.5rem", padding: "0.75rem 1.25rem" }} onClick={() => handleDrawerClose("/user-profile")}>
                <Avatar>{user?.name[0]}</Avatar>
                <div>
                  <div className="textMD w-500 black">{user?.name}</div>
                  <div className="textSM black">{user?.email}</div>
                </div>
              </div>
              <Divider style={{ margin: '0rem', background: "var(--grey4)" }} />
            </>}
            <DrawerNavItemsList items={selectedDrawerItem?.currentItem?.items || drawerNavItems} />
            <div style={{ padding: "0.75rem 1.25rem", borderTop: "1px solid var(--grey4)" }} className='w100'>
              {!(user !== undefined && user !== null) ?
                <div style={{ display: "flex", gap: "0.875rem", alignItems: "center" }} className='w100'>
                  <button className={"btnBlack w100 "} onClick={() => {
                    setNavDrawer(false);
                    setTimeout(() => {
                      navigate(`/login`, { state: { from: loc.pathname } });
                    }, 500)
                  }}>
                    Login
                  </button>
                  <button className={"btnWhite w100 "} onClick={() => {
                    setNavDrawer(false);
                    setTimeout(() => {
                      navigate(`/signup`, { state: { from: loc.pathname } });
                    }, 500)
                  }}>
                    Signup
                  </button>
                </div>
                :
                <div className={`d-flex align-items-center `} style={{
                  gap: "0.5rem", padding: "0.75rem 1.25rem", borderRadius: "0.5rem", background: "var(--red)",
                  color: "var(--white)", cursor: "pointer"
                }} onClick={logout}>
                  <FiLogOut />
                  <div className="textMD white w-500">
                    Sign out
                  </div>
                </div>
              }
            </div>
          </div>
        </Drawer >
      </>
    </div >
  );
};
export default Header;

