import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup
} from "firebase/auth";

var firebaseConfig = {
    apiKey: "AIzaSyBMIi2-qXmLrJ298WvRIZ0gMFdGlfbPEpM",
    authDomain: "sharpely-854b7.firebaseapp.com",
    projectId: "sharpely-854b7",
    storageBucket: "sharpely-854b7.appspot.com",
    messagingSenderId: "432057325478",
    appId: "1:432057325478:web:39085e5f97a6de948da167",
    measurementId: "G-L12F9CLWRS"
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);


const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;

        return user
        // const q = query(collection(db, "users"), where("uid", "==", user.uid));
        // const docs = await getDocs(q);
        // if (docs.docs.length === 0) {
        //     await addDoc(collection(db, "users"), {
        //         uid: user.uid,
        //         name: user.displayName,
        //         authProvider: "google",
        //         email: user.email,
        //     });
        // }
    } catch (err) {
        console.error(err);
        return {}
        alert(err.message);
    }
};

export { signInWithGoogle };
