import { Tooltip } from 'antd';
import React, { useState } from 'react'

export default function TooltipCloseOnClick({ title, children }) {
    const [open, setOpen] = useState(false);
    return (
        <Tooltip title={title} open={open} onOpenChange={setOpen} arrow={false}>
            <span onClick={() => setOpen(false)}>
                {children}
            </span>
        </Tooltip>
    )
}