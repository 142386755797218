import { CurrencyFormat } from 'Components/CurrencyFormat';
import PaySharpelyPricingFee from 'Components/PaySharpelyPlanFee';
import { UpdatePhoneModal } from 'Components/atoms/UpdatePhoneModal';
import { useIsMobile } from 'CustomHooks/useIsMobile';
import { togglePayFeeModal, toggleloginModal, updatePayFeeParams } from 'Libs/redux/UserAuth/reduxSlice';
import apiHelperPY from 'Services/api/ApiHelperPY';
import React, { useState } from 'react';
import { BsCheck2 } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

export const PricingSection = () => {

    const user = useSelector((state) => state?.updateUserData?.value)
    const navigate = useNavigate();
    const [plansData, setPlansData] = useState({});
    const [updatePhoneModal, setUpdatePhoneModal] = useState(false);
    const isMobile = useIsMobile();
    let userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel || 0;
    const containerRef = React.useRef();
    const dispatch = useDispatch();

    const loadData = async () => {
        let pls = await apiHelperPY({ apiName: "getAllSharpelyPlans", saveResponse: "" })
        if (pls) {
            pls = JSON.parse(pls);
            setPlansData(pls);
        }
    }

    const isActivePlan = (item) => {
        return userAccesslevel === item?.level
    }

    const handleSubmit = (item) => {
        if (user?.isLogged) {
            if (user.mobile === '' || !user.mobile) {
                setUpdatePhoneModal(true);
            }
            else if (item?.type === 'free') {
                navigate('/stocks/dashboard')
            }
            else if (isActivePlan(item)) {
                return;
            }
            else if (item.type === 'paid') {
                dispatch(togglePayFeeModal(true));
                dispatch(updatePayFeeParams({
                    plan: item?.level
                }))
            }
            else {
                navigate('/contact-us');
            }
        }
        else {
            dispatch(toggleloginModal(true))
        }
    }

    React.useEffect(() => {
        loadData();
    }, [])

    React.useEffect(() => {
        if (Object.keys(plansData)?.length > 0 && containerRef?.current && isMobile) {
            if (containerRef.current?.querySelector) {
                let child = containerRef.current.querySelector('div');
                let width = child.offsetWidth;
                containerRef.current.scrollLeft = (2 * width) + 12
            }
        }
    }, [plansData]);

    return <div>
        <div style={{ paddingLeft: 0, paddingRight: 0 }} className='padAllCampaign' >
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'start', alignItems: 'center' }} className='padLeftCampaign padRightCampaign'>
                <h2 className='headLGtoXS margin0 text-center' style={{ fontWeight: 700, width: "20ch", color: "rgba(255, 255, 255, 0.8)" }}>
                    Not convinced yet?<br />Start with a 1-month <span style={{ color: "gold" }}>trial</span><br />
                </h2>
            </div>
            <div className='padLeftCampaign padRightCampaign' style={{
                marginTop: "4rem", display: "grid", gridTemplateColumns: `repeat(4, minmax(${isMobile ? "90%" : "17rem"} , 1fr))`,
                maxWidth: "100%", overflowX: "scroll", gap: "1.25rem",
            }} ref={containerRef}>
                {plansData?.plans?.map((item, i) => {
                    const yearlyPrice = Math.ceil((item?.price?.new * 12) * ((100 - item?.discount?.onetime?.['1Y']) / 100));
                    const yearlySaved = item?.price?.new * 12 - yearlyPrice;
                    return (
                        <div key={i + 1} className='w100 h100' style={{
                            padding: "1.25rem", borderRadius: '0.75rem' || (i === 0 ? "0.75rem 0 0 0.75rem" : i === 3 ? "0 0.75rem 0.75rem 0" : ""),
                            border: plansData?.popularPlan === item?.level ? "1.5px solid var(--primary)" : "1.5px solid var(--dark3)",
                            position: "relative",
                            boxShadow: plansData?.popularPlan === item?.level ? 'rgba(17, 17, 26, 0.1) 0px 0px 16px' : "",
                        }}>
                            {item?.level === 3 && <div style={{
                                position: "absolute", background: "var(--grey2)", color: "var(--dark4)",
                                borderRadius: "var(--borderRadius)", padding: "0.25rem 0.75rem", top: '0.75rem', right: '0.75rem'
                            }} className='textXS w-500'>
                                Most popular
                            </div>}
                            <div className='text-center headSMtoTextLG w-700' style={{ marginTop: "1.25rem", color: 'rgba(255, 255, 255, 0.8)' }} >
                                {item.name}
                            </div>
                            <div className="text-center textSMtoESM grey2" style={{
                                margin: "1rem 0 0rem", minHeight: "100px",
                                marginBottom: ""
                            }} >
                                {item.description}
                            </div>
                            <div style={{ minHeight: "8rem" }}>
                                <div className='headSMtoTextLG text-center w-700' style={{
                                    marginBottom: "1rem", display: "flex", justifyContent: "center",
                                    gap: "0.25rem", alignItems: "flex-end", color: 'rgba(255, 255, 255, 0.8)'
                                }}>
                                    {item.type === 'free' ? <div>Free forever</div> : <div>{CurrencyFormat(item?.price?.new, 'long', 0)}</div>}
                                    {item.type === 'paid' && <div className='textSM text-center w-700 dark2'>
                                        <span style={{ textDecoration: "line-through" }}>{CurrencyFormat(item?.price?.old, 'long', 0)}</span>
                                        <span className='textSM dark2 w-normal'>/ month</span>
                                    </div>}
                                </div>
                                {item.type === 'paid' && <div className='textSM dark2 w-normal text-center'>
                                    {CurrencyFormat(yearlyPrice, 'long', 0)} / year
                                </div>}
                                {item.type === 'paid' && <div style={{
                                    background: "rgba(255, 255, 255, 0.8)", width: "fit-content",
                                    padding: "0.5rem 1rem", borderRadius: "var(--borderRadius)", margin: "0.75rem auto 0rem"
                                }} className='textSM w-500'>
                                    You save {CurrencyFormat(yearlySaved, 'long', 0)} a year.
                                </div>}
                            </div>
                            <div style={{
                                minHeight: "6rem", margin: "auto",
                                width: "100%", marginTop: "1rem"
                            }}>
                                <button className='btnBlue w100 btnMD textMDtoSM text-center white' onClick={() => { handleSubmit(item) }} style={{
                                    background: "var(--dark4)",
                                    border: 'none'
                                }}>
                                    {isActivePlan(item) ? "Current plan" : item?.type === 'free' ? "Get Started" :
                                        (userAccesslevel <= 1 ? "Upgrade to " : "Switch to ") + item?.name}
                                </button>
                                <div className='text-center mt-1 textESM w-500'>
                                    {(item.type === 'paid') && <>
                                        <div className="d-flex align-items-center w100" style={{ gap: "1rem", margin: "0.5rem auto" }}>
                                            <div className="flex-grow-1" style={{ borderTop: "1px solid var(--dark2)" }}></div>
                                            <span className='textXS w-500' style={{ color: "var(--white2)" }}>Or</span>
                                            <div className="flex-grow-1" style={{ borderTop: "1px solid var(--dark2)" }}></div>
                                        </div>
                                        <div className='mt-2 textSM' style={{ color: "var(--primary)", cursor: "pointer" }}
                                            onClick={() => { handleSubmit(item, true) }}>
                                            Get upto {plansData?.discount?.maxDiscount}% off on long-term plans.
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                            <div className='d-flex flex-column' style={{ gap: "0.75rem", marginTop: "1.25rem" }}>
                                {item?.features?.map((ele, l) => {
                                    return (
                                        <div className='d-flex align-items-center justify-content-start' style={{ gap: "0.5rem" }} key={l + 1}>
                                            <BsCheck2 color='var(--white2)' />
                                            <div className='w-500 textXS' style={{ color: "var(--white2)" }}>{ele}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
        <UpdatePhoneModal open={updatePhoneModal} toggle={() => {
            setUpdatePhoneModal(false)
        }} />
        {Object.keys(plansData || {})?.length > 0 && <PaySharpelyPricingFee initPlansData={plansData} />}
    </div>
}
