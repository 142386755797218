import DashboardChartComponent from 'Components/DashboardChartComponent';
import DashboardTools from 'Components/DashboardTools';
import DashboardTopPerformers from 'Components/DashboardTopPerformers';
import { UpgradeCard } from 'Components/UpgradeCard';
import { CardHeader } from 'Components/atoms/SmallComponents';
import Head from 'Layout/Head';
import { useGetAssetType } from 'Libs/utils';
import Magicboard from "Pages/sharpelyFeatures/Magicboard";
import React from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const ETFDashboard = (props) => {

  const navigate = useNavigate();
  const type = useGetAssetType();
  const accessLevelData = useSelector((state) => state?.accessLevel?.value);
  let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';

  const dash_items = [
    {
      "UID": 25,
      "ClientId": 1,
      "DashboardId": 3,
      "InstrumentType": 4,
      "WidgetId": 25,
      "FullWidgetOrder": 9,
      "MiniWidgetOrder": 0,
      "IsVisible": 1,
      "IsDeleted": 0,
      "CreatedOn": "2024-04-12T14:03:57",
      "Type": "Full Widget",
      "Key": "ETF_WIZARD",
      "Name": "ETF Wizard",
      "Category": "ETF",
      "CategoryName": "ETF analysis",
      "Description": "A birds eye view of everything important to analyse an ETF",
      "IsDefaultPref1": 0,
      "IsDefaultPref2": 0,
      "IsDefaultPref3": 0,
      "IsDefaultPref4": 0,
      "IsDefaultPref5": 0
    },
    {
      "UID": 24,
      "ClientId": 1,
      "DashboardId": 3,
      "InstrumentType": 4,
      "WidgetId": 24,
      "FullWidgetOrder": 13,
      "MiniWidgetOrder": 0,
      "IsVisible": 1,
      "IsDeleted": 0,
      "CreatedOn": "2024-04-12T14:03:57",
      "Type": "Full Widget",
      "Key": "ETF_MAGICBOARD",
      "Name": "ETF Magicboard",
      "Category": "MAGICBOARD",
      "CategoryName": "ETF analysis",
      "Description": "Quickly filter ETFs based on their category and benchmark and compare across multiple metrics in a single table",
      "IsDefaultPref1": 0,
      "IsDefaultPref2": 0,
      "IsDefaultPref3": 0,
      "IsDefaultPref4": 0,
      "IsDefaultPref5": 0
    }
  ]

  return (
    <React.Fragment>
      <Head title="ETF Screener | Investment, Growth, & Analysis | sharpely ETFs Dashboard" description="Access comprehensive ETF market data and research tools with our ETF comparison tool. Gain valuable insights into Exchange-Traded Funds (ETFs) for informed investment decisions" keywords="ETF Investment, ETF Comparison tool, ETF Screener, ETF Performance tracker, ETF Portfolio builder, ETF Analysis, ETF Market Data, ETF Market Research Tools, ETF Insights, ETF Market Research Websites, ETF research tools, ETF Analysis tools, best etf analysis platform, best etf comparison tool, top etf screener, top etf performance tracker, best etf portfolio builder, best etf analysis websites, best etf market data providers, top etf market research tools, best etf insights provider, top etf market research websites, top etf research tools, top etf analysis tools, best etf analysis platform in India, best etf comparison tool in india, top etf screener in india, top etf performance tracker in India, best etf portfolio builder tool in india, best etf analysis websites in India, best etf market data providers in india, top etf market research tools india, best etf insights providers in india, top etf market research websites in India, top etf research tools in India, top etf analysis tools india" />

      <div className='mainContainer'>
        <div className='leftContainer'>
          <DashboardChartComponent isWidget type={type} header={dash_items[0]?.Name} subHeading={dash_items[0]?.Description} />
          <Magicboard defaultType={type} isWidget={true} widgetItem={dash_items[1]} />
        </div>
        <div className='rightContainer'>
          <UpgradeCard />
          <DashboardTools defInstType={type.value} hideInsTypeTabs isBordered={false} showToolHeading={true} />
          <DashboardTopPerformers header={<CardHeader heading={'Top Equity ' + type.dname} headingType={2} />} type={type} filter={[['primary_category_name', 'Equity']]} />
          <DashboardTopPerformers header={<CardHeader heading={'Top Debt ' + type.dname} headingType={2} />} type={type}
            filter={[['primary_category_name', 'Debt']]} />
          <DashboardTopPerformers header={<CardHeader heading={'Top Commodities ' + type.dname} headingType={2} />} type={type}
            filter={[['primary_category_name', 'Commodities']]} />
        </div>
      </div>
    </React.Fragment >
  );
}
export default ETFDashboard;
