import { NoDataFound } from 'Components/NoDataFound';
import { toggleHelpAndSupp } from "Libs/redux/UserAuth/reduxSlice";
import { generateUniqueKey } from 'Libs/utils';
import apiHelperPY from "Services/api/ApiHelperPY";
import { Card, Segmented, Table } from "antd";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import React, { useState } from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { HiOutlineHandThumbDown, HiOutlineHandThumbUp } from 'react-icons/hi2';
import { IoFlagOutline } from 'react-icons/io5';
import { MdOutlineSearchOff } from 'react-icons/md';
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import AMCIcon from "./AMCIcon";
import AccessComponent from "./AccessComponent";
import ActivityLoaderMintbox from "./ActivityLoaderMintbox";
import CustomDrawer from "./CustomDrawer";
import CustomSegmentedTab from "./CustomSegmentedTab";
import CustomSharpelyScoreProgressBar from "./CustomSharpelyScoreProgressBar";
import { DecimalValueFormat } from "./DecimalValueFormat";
import { MatrixCardView } from "./MatrixCardView";
import PerformanceChart3 from "./PerformanceChart3";
import StyleBoxIcons from "./StyleBoxIcons";
import UpgradeToPro from "./UpgradeToPro";

//done
const DisplayOverview = ({ x }) => {
    return <div style={{ minHeight: '100%', backgroundColor: 'var(--grey4)', border: '1px solid var(--grey3)', borderRadius: '0.5rem', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
        <div style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <div className="textSM dark3">sharpely styleBox:</div>
            <StyleBoxIcons style_box_code={x?.data?.style_box_code - 1} size={'large'} />
            <div className="textMD w-500">{x?.data?.style_box_name}</div>
        </div>
        {
            Object.values(x?.data?.stance_text || {})?.map((s, i) => {
                return <p className={i === 0 ? 'textSM dark3 w-500' : 'textSM dark3'} key={generateUniqueKey(i)} >{s}</p>
            })
        }
    </div>
}

//done
const DisplayStockStyleAnalysis = ({ x }) => {
    const children = x?.children
    const options2 = [
        {
            key: 'pe_ttm',
            name: 'P/E (TTM)'
        },
        {
            key: 'pb',
            name: 'P/B (Latest)'
        }
    ]
    const [selected, setSelected] = React.useState(children?.[0])
    const [selected2, setSelected2] = React.useState(options2?.[0])
    return <div style={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'var(--white)' }} className="sticky-top">
            <Segmented className="" value={selected?.section_name} options={children?.map(s => s.section_name)} onChange={(c) => setSelected(children?.find(x => x.section_name === c))} style={{ marginBottom: '1rem' }} />
        </div>
        <div style={{ flex: '1', backgroundColor: 'var(--grey4)', border: '1px solid var(--grey3)', borderRadius: '0.5rem', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '2%' }}>

                {selected?.data?.map((data, i) => {
                    if (data?.type === 1) {
                        if (data?.chart?.chart_data) {
                            return (
                                <div style={{ paddingTop: '1rem', flex: 1 }} key={generateUniqueKey(i)}>
                                    <div className='textMD black'>{data.name}</div>
                                    <div style={{ flex: 1, minWidth: '25rem', maxWidth: '100%', margin: '0.5rem 0' }}>
                                        <Segmented className="" value={selected2?.name} options={options2?.map(s => s.name)} onChange={(c) => setSelected2(options2?.find(x => x.name === c))} style={{ marginBottom: '1rem' }} />
                                        <Card style={{ width: '100%' }} bodyStyle={{ width: '100%' }}>
                                            <PerformanceChart3 yAxisKeys={[selected2.key]} fundName={''} chartData={JSON.stringify(data?.chart?.chart_data)} xAxisKey={'date'} showYAxis={true}
                                                drawHorizontalLines={
                                                    selected2.key === 'pe_ttm' ?
                                                        [{ val: data?.chart?.pe_avg, label: 'Avg' }, { val: data?.chart?.pe_median, label: 'Median' }]
                                                        : selected2.key === 'pb' ?
                                                            [{ val: data?.chart?.pb_avg, label: 'Avg' }, { val: data?.chart?.pb_median, label: 'Median' }]
                                                            : []
                                                } />
                                            <div style={{ margin: "1rem 0 0" }} className='textXS black'>
                                                Note: 0 value to be interpreted as follows – the denominator (e.g., EPS) was negative or not available and hence multiple could not be calculated.
                                            </div>
                                        </Card>
                                        <div className="textSM dark2" style={{ display: 'flex', justifyContent: 'space-evenly', margin: '1rem 0 2rem 0' }}>
                                            {
                                                selected2.key === 'pe_ttm' ?
                                                    <>
                                                        <div>Average PE: <b>{data?.chart?.pe_avg}</b></div>
                                                        <div>Median PE: <b>{data?.chart?.pe_median}</b></div>
                                                    </>
                                                    : selected2.key === 'pb' ?
                                                        <>
                                                            <div>Average PB: <b>{data?.chart?.pb_avg}</b></div>
                                                            <div>Median PB: <b>{data?.chart?.pb_median}</b></div>
                                                        </>
                                                        :
                                                        <></>
                                            }
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column' style={{ gap: "1rem" }}>
                                        {data?.children?.map((data, i) => {
                                            if ((selected2.key === 'pe_ttm' && (i === 0 || i === 1)) || (selected2.key === 'pb' && (i === 2 || i === 3))) {
                                                return (
                                                    <Card key={generateUniqueKey(i)} style={{ borderRadius: '12px' }} bodyStyle={{ padding: 0 }}>
                                                        <div style={{
                                                            display: 'flex', gap: '1rem', padding: '0.625rem 1.25rem',
                                                            alignItems: "center"
                                                        }}>
                                                            <div style={{ marginRight: "0rem" }}>
                                                                <InsightIcon type={data.stance} />
                                                            </div>
                                                            {/* {data.stance === "Positive" ?
                                                                <img loading="lazy"  alt="" style={{ height: '3rem', width: '3rem', marginRight: '1rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/like_icon.png" />
                                                                : data.stance === "Negative" ?
                                                                    <img loading="lazy"  alt="" style={{ height: '3rem', width: '3rem', marginRight: '1rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/dislike_icon.png" />
                                                                    : data.stance === "Neutral" ?
                                                                        <img loading="lazy"  alt="" style={{ height: '3rem', width: '3rem', marginRight: '1rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/neutral_icon.png" />
                                                                        :
                                                                        <img loading="lazy"  alt="" style={{ height: '3rem', width: '3rem', marginRight: '1rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/no_data_icon.png" />
                                                            } */}
                                                            <div style={{ flex: 1 }}>
                                                                <div className="textSM black mb-2">{data.name}</div>
                                                                <div className="textXS dark2">{data.stance_text}</div>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>)
                        } else return null
                    }
                    else return <div key={i} style={{ width: window.innerWidth > 760 ? '49%' : '100%', marginBottom: '1rem' }}>
                        <div className="textSM black text-center">{data?.name?.charAt(0)?.toUpperCase() + data?.name?.slice(1)}</div>
                        <div style={{ padding: '0.5rem', textAlign: 'center' }}>
                            <CustomSharpelyScoreProgressBar val={data?.val} />
                        </div>
                        {/* <div className="textSM dark3">{data?.stance_text}</div> */}
                        <div key={i} style={{ display: 'flex', marginBottom: '1rem', alignItems: 'center' }}>
                            <div style={{ marginRight: "1rem" }}>
                                <InsightIcon type={data.stance} />
                            </div>
                            {/* {data.stance === "Positive" ?
                                <img loading="lazy"  alt="" style={{ height: '3rem', width: '3rem', marginRight: '1rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/like_icon.png" />
                                : data.stance === "Negative" ?
                                    <img loading="lazy"  alt="" style={{ height: '3rem', width: '3rem', marginRight: '1rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/dislike_icon.png" />
                                    : data.stance === "Neutral" ?
                                        <img loading="lazy"  alt="" style={{ height: '3rem', width: '3rem', marginRight: '1rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/neutral_icon.png" />
                                        :
                                        <img loading="lazy"  alt="" style={{ height: '3rem', width: '3rem', marginRight: '1rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/no_data_icon.png" />
                            } */}
                            <div style={{ flex: 1 }}>
                                {/* <div className="fontH2 w-500">{data.name}</div> */}
                                <div className="textXS dark2">{data.stance_text}</div>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    </div>
}

//done
const DisplayStanceText = ({ x, withoutBgAndPad }) => {
    return <div style={{
        minHeight: '100%', backgroundColor: withoutBgAndPad ? "" : 'var(--grey4)', border: withoutBgAndPad ? "" : '1px solid var(--grey3)', borderRadius: '0.5rem',
        display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: withoutBgAndPad ? "" : '1.25rem'
    }}>
        {x?.data?.map((data, i) => {
            return <Card key={generateUniqueKey(data.param_id)} style={{ borderRadius: '12px', border: withoutBgAndPad ? "1px solid var(--grey3)" : "" }} bodyStyle={{ padding: 0 }}>
                <div style={{ display: 'flex', gap: '1rem', padding: '0.625rem 1.25rem', alignItems: "center" }}>
                    <div style={{ marginRight: "0rem" }}>
                        <InsightIcon type={data.stance} />
                    </div>
                    {/* {data.stance === "Positive" ?
                        <img loading="lazy"  alt="" style={{ height: '3rem', width: '3rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/like_icon.png" />
                        : data.stance === "Negative" ?
                            <img loading="lazy"  alt="" style={{ height: '3rem', width: '3rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/dislike_icon.png" />
                            : data.stance === "Neutral" ?
                                <img loading="lazy"  alt="" style={{ height: '3rem', width: '3rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/neutral_icon.png" />
                                :
                                <img loading="lazy"  alt="" style={{ height: '3rem', width: '3rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/no_data_icon.png" />
                    } */}
                    <div style={{ flex: 1 }}>
                        <div className="fontSM dark3 w-500 mb-2">{data.name}</div>
                        <div className="textSM dark3">{data.stance_text}</div>
                    </div>
                </div>
            </Card>
        })}
    </div>
}

//done
const DisplayRedFlags = ({ x, withoutBgAndPad }) => {
    return <div style={{
        minHeight: '100%', backgroundColor: withoutBgAndPad ? "" : 'var(--grey4)', border: withoutBgAndPad ? "" : '1px solid var(--grey3)', borderRadius: '0.5rem',
        display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: withoutBgAndPad ? "" : '1.25rem'
    }}>
        {x?.data?.map((data, i) => {
            const dataTableColumns = Object.keys(data?.stance_table?.[0] || {}).map(k => {
                if (k === 'symbol') {
                    return undefined
                } else if (k === "proper_name") {
                    return {
                        "title": "Name",
                        "dataIndex": "proper_name",
                        render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }}>
                            <AMCIcon amcName={record.symbol} fundType={4} height='1.2rem' width="1.2rem" bseTicker={record?.bse_ticker} />
                            <div>
                                <div className="textSM dark3 black">{record?.proper_name}</div>
                            </div>
                        </div>,
                        fixed: 'left',
                    }
                }
                else return {
                    "title": "Value",
                    "dataIndex": k,
                    "align": "right",
                }
            }).filter(x => x)
            return <Card key={generateUniqueKey(data.param_id)} style={{ borderRadius: '12px', border: withoutBgAndPad ? "1px solid var(--grey3)" : "" }} bodyStyle={{ padding: 0 }}>
                <div style={{ display: 'flex', gap: '1rem', padding: '0.625rem 1.25rem' }}>

                    {/* <MdFlag style={{ height: '3rem', width: '3rem', color: 'var(--red)' }} /> */}
                    <InsightIcon type='RedFlag' isRedFlag={true} redFlagType={data.stance} />
                    <div style={{ flex: 1 }}>
                        <div className="fontSM w-500 dark3 mb-2">{data.name}</div>
                        <div className="textSM dark3">{data.stance_text}</div>
                        <div className="mt-2 me-4">
                            {
                                data.stance_table ?
                                    <Table className='insideTable' pagination={false}
                                        dataSource={data.stance_table} columns={dataTableColumns} />
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </Card>
        })
        }
    </div>
}

//done
const DisplayTickerList = ({ x }) => {
    const [childrenDrawer, setChildrenDrawer] = useState(false);
    const [selectedStock, setSelectedStock] = useState({})
    const dataTableColumns = [
        {
            "title": "Name",
            "dataIndex": "full_name",
            render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }}>
                <AMCIcon amcName={record.symbol} fundType={4} height='1.2rem' width="1.2rem" bseTicker={record?.bse_ticker} />
                <div>
                    <div className="textSM dark3 black">{text}</div>
                </div>
            </div>,
            fixed: 'left',
        },
        {
            "title": "Weight",
            "dataIndex": 'weight',
            "align": "right",
            render: (text) => <div style={{ whiteSpace: 'nowrap' }}>{DecimalValueFormat({ num: text, suffix: " %" })}</div>,
        },
        {
            "align": "right",
            render: t => <BsArrowRightShort />,
        },
    ]

    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);

    return <div style={{ minHeight: '100%' }}><Table bordered={false} className='textXS dark2 black tickerTable' pagination={false}
        dataSource={x} columns={dataTableColumns} onRow={(record) => {
            return {
                onClick: () => { setSelectedStock(record); setChildrenDrawer(true) }, // click row
            };
        }} />
        <CustomDrawer title={<div style={{ display: 'flex', gap: '0.75rem', alignItems: 'center' }}>
            <AMCIcon amcName={selectedStock?.symbol} fundType={4} height='1.5rem' width="1.5rem" bseTicker={selectedStock?.bse_ticker} />
            <div className="textSM w-700 black">{selectedStock?.full_name || selectedStock?.proper_name}</div>
        </div>} toggle={() => setChildrenDrawer(!childrenDrawer)} isOpen={childrenDrawer} showBackBtn={true}>

            <AccessComponent
                component={<StockInsightsModal fetchFromApi={true} ticker={selectedStock?.symbol} />} upgradeComponent={<UpgradeToPro moduleKey="STOCK_INSIGHTS" height="20rem" width="90%" />}
                moduleKey="STOCK_INSIGHTS" />

        </CustomDrawer>
    </div>
}

//done
const DisplayInstruments = ({ x }) => {
    const dataTableColumns = Object.keys(x?.data?.[0] || {}).map((k, i) => {
        const title = k.replaceAll("_", " ")
        return {
            title: title[0].toUpperCase() + title.slice(1),
            dataIndex: k,
            align: i === 0 ? 'left' : 'right',
            fixed: i === 0 ? 'left' : '',
            width: i === 0 ? '30%' : '15%',
            render: (text, record, col) => {
                if (k === "coupon_rate" || k === "weight") {
                    return DecimalValueFormat({ num: text, suffix: '%' })
                }
                else return text
            }
        }
    })
    return <div style={{ minHeight: '100%' }}><Table className='insideTable' pagination={false}
        dataSource={x?.data} columns={dataTableColumns} /></div>
}

//done
const DisplaySebiPrcMatrix = ({ x, withoutBgAndPad }) => {
    return <div style={{ minHeight: '100%', backgroundColor: withoutBgAndPad ? "" : 'var(--grey4)', border: withoutBgAndPad ? "" : '1px solid var(--grey3)', borderRadius: '0.5rem', overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: withoutBgAndPad ? "" : '1.25rem' }}>
        <MatrixCardView v1Head='Credit risk' v2Head='Interest risk' v1={x?.data?.credit_risk} v2={x?.data?.interest_rate_risk} />
    </div>
}

//done
const DisplayEquityInsights = ({ x, withoutBgAndPad }) => {
    switch (x.type) {
        case 1: return DisplayOverview({ x })
        case 2: return DisplayStockStyleAnalysis({ x })
        case 3: return DisplayStanceText({ x, withoutBgAndPad })
        case 4: return DisplayRedFlags({ x, withoutBgAndPad })
        case 10: return DisplayTickerList({ x: x.data, withoutBgAndPad })
    }
}

//done
const DisplayDebtInsights = ({ x, withoutBgAndPad }) => {
    switch (x.type) {
        case 1: return DisplayInstruments({ x })
        case 2: return DisplaySebiPrcMatrix({ x, withoutBgAndPad })
        case 3: return DisplayStanceText({ x, withoutBgAndPad })
    }
}

const PortfolioOverlap = ({ data, holdings }) => {
    var heatmapdata = data
    var funds = data?.map((data) => { return data?.plan_id })
    var vals = []
    var dataFinal = funds?.map((s, index) => {
        return funds?.map((f, yindex) => {
            vals.push(Number((heatmapdata[yindex][s]).toFixed(1)))
            return {
                name: holdings?.find(h => h.plan_id === f)?.basic_name,
                x: index,
                y: yindex,
                value: Number((heatmapdata[yindex][s]).toFixed(1))
            }
        })
    })

    dataFinal = dataFinal.map((i, i1) => {
        return i.map((a, i2) => {
            if (i2 <= i1) {
                return {
                    ...a,
                    visible: false
                }

            } else {
                return {
                    ...a,
                }

            }
        })
    })?.flat(1)


    var chart = {

        chart: {
            type: 'heatmap',
            plotBorderWidth: 0,
            borderWidth: 0,
            marginTop: -40
        },


        title: {
            text: '',
            style: {
                color: "var(--black)"
            }
        },
        scrollbar: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },

        xAxis: {
            categories: funds.map(p => holdings?.find(h => h.plan_id === p)?.basic_name?.replace(" - Direct Plan", "")),
            lineWidth: 0,

            labels: {
                formatter: function () {
                    if (!this.isLast) {
                        return this.value;
                    } else {
                        return null
                    }

                }
            }

        },

        yAxis: {
            tickAmount: 5,
            gridLineDashStyle: 'none',
            gridLineWidth: 0,
            categories: funds.map(p => holdings?.find(h => h.plan_id === p)?.basic_name?.replace(" - Direct Plan", "")),
            title: null,
            reversed: true,
            labels: {
                formatter: function () {
                    if (!this.isFirst) {
                        return this.value;
                    } else {
                        return null
                    }

                }
            }
        },


        colorAxis: {
            min: Math.min(...vals),
            max: Math.max(...vals.filter((i) => i !== 100)),
            minColor: '#c9f6bf',
            maxColor: '#fcaead',
        },

        legend: {
            enabled: false
        },

        tooltip: {
            headerFormat: '',
            pointFormat: '{point.value}% overlap',
        },
        series: [{
            name: 'Monthly returns',
            borderWidth: 0.2,
            borderColor: 'black',
            data: dataFinal,
            dataLabels: {
                enabled: true,
                color: '#000000',
                style: {
                    fontWeight: 'normal',
                    fontSize: 8,
                    textOutline: false
                },
                pointFormat: '{point.value}%',
            }
        }],

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 300
                },
                chartOptions: {
                    yAxis: {
                        labels: {
                            formatter: function () {
                                return this.value.charAt(0);
                            }
                        }
                    }
                }
            }]
        }

    }

    return <Card bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: '1rem', borderRadius: "var(--borderRadius)" }}
        style={{
            padding: 0,
            borderRadius: "var(--borderRadius)"
        }}>
        <HighchartsReact highcharts={Highcharts} options={chart} />
    </Card>
}
//done
const DisplayMFList = ({ x, type }) => {
    const [childrenDrawer, setChildrenDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState({})
    const [insight, setInsights] = useState({})
    const dataTableColumns = [
        {
            "title": "Name",
            "dataIndex": "basic_name",
            render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }}>
                <AMCIcon amcName={record.amc_full_name} fundType={2} height='1.2rem' width="1.2rem" />
                <div>
                    <div className="textSM dark3 black">{text}</div>
                </div>
            </div>,
            fixed: 'left',
        },
        {
            "title": "Weight",
            "dataIndex": 'weights',
            "align": "right",
            render: (text) => <div style={{ whiteSpace: 'nowrap' }}>{DecimalValueFormat({ num: Number(text) * 100, suffix: " %" })}</div>,
        },
        {
            "align": "right",
            render: t => <BsArrowRightShort />,
        },
    ]
    React.useEffect(() => {
        const dum = async () => {
            setLoading(true)
            let res = await apiHelperPY({ apiName: 'getMFInsights', data: { planId: selected?.plan_id, assetType: selected?.primary_category_name?.toLowerCase() }, saveResponse: '' })
            if (res) {
                setInsights(res)
                setLoading(false)
            }
            else {
                setLoading(false)
                setInsights([])
            }
        }
        dum();
    }, [selected])
    return <><Table bordered={false} size='small' className='textXS dark2 black tickerTable' pagination={false}
        dataSource={x} columns={dataTableColumns} onRow={(record) => {
            return {
                onClick: () => { setSelected(record); setChildrenDrawer(true) }, // click row
            };
        }} />
        <CustomDrawer
            title={<div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                <AMCIcon amcName={selected.amc_full_name} fundType={2} height='1.5rem' width="1.5rem" />
                <div className="textSM w-700 black">{selected.basic_name}</div>
            </div>}
            toggle={() => setChildrenDrawer(!childrenDrawer)}
            isOpen={childrenDrawer} showBackBtn={true}
        >
            {
                loading ?
                    <ActivityLoaderMintbox message='Data crunching underway. Hang on!' margin="5rem" />
                    :
                    Object.keys(insight || {}).length ?
                        <FundInsightsModal insights={insight} type={type} />
                        :
                        <div className="fontH2 mt-4">Not enough data</div>
            }
        </CustomDrawer>
    </>
}

//done
export const FundInsightsModal = ({ insights: stInsights, type = {}, planId, assetType, fetchFromApi, layout = 'segmented', initialLoading }) => {
    const [childrenDrawer, setChildrenDrawer] = useState(false);
    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    const [insights, setInsights] = useState(stInsights || []);
    const [loading, setLoading] = useState(true);
    const loadData = async () => {
        let res4 = await apiHelperPY({ apiName: 'getMFInsights', data: { planId: planId, assetType: assetType }, saveResponse: '' })
        if (res4) {
            setInsights(res4)
        }
        setLoading(false)
    }

    React.useEffect(() => {
        setInsights(stInsights)
    }, [stInsights])

    React.useEffect(() => {
        if (fetchFromApi === true) {
            setLoading(true)
            loadData();
        }
        else {
            setLoading(false);
        }
    }, [planId, assetType])

    const Overview = () => {
        const [openedParamId, setOpenedParamId] = React.useState();
        if (layout === 'sidetabs' && initialLoading) {
            return (
                <div>
                    <ActivityLoaderMintbox message='Data crunching underway. Hang on!' margin='2rem' />
                </div>
            )
        }
        return <div style={{ minHeight: '100%', backgroundColor: layout === 'segmented' ? 'var(--grey4)' : "", border: layout === 'segmented' ? '1px solid var(--grey3)' : "", borderRadius: '0.5rem', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: layout === 'segmented' ? '1.25rem' : "" }}>
            {
                insights?.overview?.length ?
                    insights?.overview?.map((data) => {
                        return <Card key={generateUniqueKey(data.param_id)} style={{ borderRadius: '12px', border: layout === 'segmented' ? "" : "1px solid var(--grey2)" }} bodyStyle={{ padding: 0 }}>
                            <div style={{ display: 'flex', gap: '1rem', padding: '0.625rem 1.25rem' }}>
                                <div style={{ marginRight: "0rem" }}>
                                    <InsightIcon type={data.mintbox_stance} />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div className="textSM dark3 w-500">{data.param_text}</div>
                                    <div className="textSM dark3 mt-2">{data.stance_text}
                                        <span style={{ color: 'var(--primary)', whiteSpace: 'nowrap', cursor: 'pointer', marginLeft: '4px' }}
                                            onClick={() => { setOpenedParamId(data.param_id === openedParamId ? null : data.param_id) }} className='textSM w-500'>
                                            {
                                                data.param_id === openedParamId ?
                                                    "View less" : "Know more"
                                            }
                                        </span>
                                    </div>
                                    {
                                        data.param_id === openedParamId ?
                                            <div className="textXS dark2 mt-2">{data.help_text}</div>
                                            : <></>
                                    }
                                </div>
                            </div>
                        </Card>
                    })
                    :
                    <NoFundInsights />
            }
        </div>
    }

    const EquityInsights = () => {
        var options = Object.values(JSON.parse(insights?.mf_paid || '')?.equity_insights?.overview || {})
        if (JSON.parse(insights?.mf_paid || '')?.equity_insights?.ticker_list) {
            options.push({ section_name: 'Detailed equity insights', type: 10, data: JSON.parse(insights?.mf_paid || '')?.equity_insights?.ticker_list })
        }
        const [selected, setSelected] = React.useState(options?.[0])
        if (layout === 'sidetabs' && initialLoading) {
            return (
                <div>
                    <ActivityLoaderMintbox message='Data crunching underway. Hang on!' margin='2rem' />
                </div>
            )
        }
        if (JSON.parse(insights?.mf_paid || '')?.equity_insights) {
            return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'var(--white)' }}
                    className={`${layout === 'segmented' ? "sticky-top" : ""}`}>
                    <Segmented className="" value={selected?.section_name} options={options?.map(s => s.section_name)} onChange={(c) => setSelected(options?.find(x => x.section_name === c))} style={{ marginBottom: '1rem' }} />
                </div>
                <div style={{ flex: 1, overflowY: 'scroll' }}>
                    <DisplayEquityInsights x={selected} withoutBgAndPad={layout === 'sidetabs'} />
                </div>
            </div>
        } else {
            return <div style={{
                minHeight: '100%', backgroundColor: layout === 'sidetabs' ? "" : 'var(--grey4)', border: layout === 'sidetabs' ? "" : '1px solid var(--grey3)',
                borderRadius: layout === 'sidetabs' ? "" : '0.5rem', overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: layout === 'sidetabs' ? "" : '1.25rem'
            }}>
                <Card style={{ border: layout === 'sidetabs' ? "1px solid var(--grey3)" : "" }}>No significant equity holdings</Card>
            </div>
        }
    }

    const DebtInsights = () => {
        const options = Object.values(JSON.parse(insights?.mf_paid || '')?.debt_insights || {})
        const [selected, setSelected] = React.useState(options?.[0])
        if (layout === 'sidetabs' && initialLoading) {
            return (
                <div>
                    <ActivityLoaderMintbox message='Data crunching underway. Hang on!' margin='2rem' />
                </div>
            )
        }
        if (JSON.parse(insights?.mf_paid || '')?.debt_insights) {
            return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'var(--white)' }}
                    className={`${layout === 'segmented' ? "sticky-top" : ""}`}>
                    <Segmented className="" value={selected?.section_name} options={options?.map(s => s.section_name)} onChange={(c) => setSelected(options?.find(x => x.section_name === c))} style={{ marginBottom: '1rem' }} /></div>
                <div style={{ flex: 1, overflowY: 'scroll' }}>
                    <DisplayDebtInsights x={selected} withoutBgAndPad={layout === 'sidetabs'} />
                </div>
            </div>
        } else {
            return <div style={{
                minHeight: '100%', backgroundColor: layout === 'sidetabs' ? "" : 'var(--grey4)', border: layout === 'sidetabs' ? "" : '1px solid var(--grey3)',
                borderRadius: layout === 'sidetabs' ? "" : '0.5rem', overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: layout === 'sidetabs' ? "" : '1.25rem'
            }}>
                <Card style={{ border: layout === 'sidetabs' ? "1px solid var(--grey3)" : "", }}>No significant debt holdings</Card>
            </div>
        }

    }

    const tabs = [
        {
            key: "Overview",
            value: "Overview",
            label: 'Overview',
            children: <AccessComponent
                component={<Overview />} upgradeComponent={<div style={{ minHeight: '100%', backgroundColor: 'var(--grey4)', border: '1px solid var(--grey3)', borderRadius: '0.5rem', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
                    <UpgradeToPro moduleKey={type?.value === 1 ? "ETF_QUICK_INSIGHTS" : type?.value === 2 ? "MF_QUICK_INSIGHTS" : ""} height="20rem" width="90%" />
                </div>}
                moduleKey={type?.value === 1 ? "ETF_QUICK_INSIGHTS" : type?.value === 2 ? "MF_QUICK_INSIGHTS" : ""} />,
            show: true,
            heading: "Overview"
        },
        {
            key: "EquityInsights",
            value: "EquityInsights",
            label: 'Equity insights',
            children: <AccessComponent
                component={<EquityInsights />} upgradeComponent={<div style={{ minHeight: '100%', backgroundColor: 'var(--grey4)', border: '1px solid var(--grey3)', borderRadius: '0.5rem', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
                    <UpgradeToPro moduleKey={type?.value === 1 ? "ETF_PORTFOLIO_INSIGHTS" : type?.value === 2 ? "MF_PORTFOLIO_INSIGHTS" : ""} height="20rem" width="90%" />
                </div>}
                moduleKey={type?.value === 1 ? "ETF_PORTFOLIO_INSIGHTS" : type?.value === 2 ? "MF_PORTFOLIO_INSIGHTS" : ""} />,
            show: true,
            heading: "Equity insights"
        },
        {
            key: "Debtinsights",
            value: "DebtInsights",
            label: 'Debt insights',
            children: <AccessComponent
                component={<DebtInsights />} upgradeComponent={
                    <div style={{ minHeight: '100%', backgroundColor: 'var(--grey4)', border: '1px solid var(--grey3)', borderRadius: '0.5rem', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
                        <UpgradeToPro moduleKey={type?.value === 1 ? "ETF_PORTFOLIO_INSIGHTS" : type?.value === 2 ? "MF_PORTFOLIO_INSIGHTS" : ""} height="20rem" width="90%" />
                    </div>}
                moduleKey={type?.value === 1 ? "ETF_PORTFOLIO_INSIGHTS" : type?.value === 2 ? "MF_PORTFOLIO_INSIGHTS" : ""} />,
            show: true,
            heading: "Debt insights"
        }
    ]

    const [selectedTabItem, setSelectedTabItem] = React.useState('Overview');

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {(loading || initialLoading) ? <ActivityLoaderMintbox message='Data crunching underway. Hang on!' /> : <>
                    <div className="sticky-top" style={{ backgroundColor: 'var(--white)' }}>
                        <CustomSegmentedTab isBlock={false}
                            value={selectedTabItem}
                            mobileStyles={{ marginBottom: '0.5rem' }}
                            onChange={(e) => {
                                setSelectedTabItem(e)
                            }}
                            options={tabs} style={{ marginBottom: '1.25rem' }} />
                    </div>
                    <div style={{ flex: 1, overflowY: 'scroll' }}>
                        {tabs.filter((i) => i.value === selectedTabItem)[0]?.children}
                    </div>
                </>}
            </div>
        </>
    )
}

//done
export const StockInsightsModal = ({ ticker, fetchFromApi = true, insights: stInsights }) => {
    const [insights, setInsights] = useState(stInsights || []);
    const [loading, setLoading] = useState(fetchFromApi);
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => { setIsOpen(!isOpen) };

    const loadData = async () => {
        let res0 = await apiHelperPY({ apiName: 'getStockInsights', data: { ticker: ticker }, saveResponse: '' })
        if (res0) {
            setInsights(JSON.parse(res0))
        }
        setLoading(false)
    }

    React.useEffect(() => {
        if (fetchFromApi === true) {
            setLoading(true)
            loadData();
        }
    }, [ticker])

    const tabs = insights?.map((tab, i) => {
        return {
            key: i,
            value: i,
            label: tab.section_name,
            children: <DisplayEquityInsights x={tab} />
        }
    })
    const [selectedTabItem, setSelectedTabItem] = React.useState(0);
    return <>
        {loading ? <ActivityLoaderMintbox message='Data crunching underway. Hang on!' /> : insights?.length <= 0 ? <NoStockInsights /> :
            <>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div className="sticky-top" style={{ backgroundColor: 'var(--white)' }}>
                        <CustomSegmentedTab isBlock={false}
                            value={selectedTabItem}
                            mobileStyles={{ marginBottom: '0.5rem' }}
                            onChange={(e) => {
                                setSelectedTabItem(e)
                            }}
                            options={tabs} style={{ marginBottom: '1.25rem' }} />
                    </div>
                    <div style={{ flex: 1, overflowY: 'scroll' }}>
                        {tabs.filter((i) => i.value === selectedTabItem)[0]?.children}
                    </div>
                </div>
            </>
        }
    </>
}

//pending
const FundPortfolioInsightsModal = ({ insights, holdings, overlap, type }) => {
    const EquityInsights = () => {
        var options = Object.values(insights?.equity_insights?.overview || {})
        if (insights?.equity_insights?.ticker_list) {
            options.push({ section_name: 'Individual stock analysis', type: 10, data: insights?.equity_insights?.ticker_list })
        }

        const [selected, setSelected] = React.useState(options?.[0])
        if (insights?.equity_insights?.overview) {
            return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'var(--white)' }} className="sticky-top">
                    <Segmented className="" value={selected?.section_name} options={options?.map(s => s.section_name)} onChange={(c) => setSelected(options?.find(x => x.section_name === c))} style={{ marginBottom: '1rem' }} />
                </div>
                <div style={{ flex: 1, overflowY: 'scroll' }}>
                    <DisplayEquityInsights x={selected} />
                </div>
            </div>
        } else {
            return <div style={{ minHeight: '100%', backgroundColor: 'var(--grey4)', border: '1px solid var(--grey3)', borderRadius: '0.5rem', overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
                <Card>
                    <NoDataFound message='It seems like your basket has no significant Indian equity holding or it is held
through Fund of Funds (FoF) in which case we are not able to provide insights
on the Indian equity portion of your basket' gap='1.5rem' />
                </Card>
            </div>
        }
    }

    const DebtInsights = () => {
        const options = Object.values(insights?.debt_insights || {})
        const [selected, setSelected] = React.useState(options?.[0])
        if (insights?.debt_insights) {
            return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'var(--white)' }} className="sticky-top">
                    <Segmented className="" value={selected?.section_name} options={options?.map(s => s.section_name)} onChange={(c) => setSelected(options?.find(x => x.section_name === c))} style={{ marginBottom: '1rem' }} /></div>
                <div style={{ flex: 1, overflowY: 'scroll' }}>
                    <DisplayDebtInsights x={selected} />
                </div>
            </div>
        } else {
            return <div style={{ minHeight: '100%', backgroundColor: 'var(--grey4)', border: '1px solid var(--grey3)', borderRadius: '0.5rem', overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
                <Card>
                    <NoDataFound message='It seems like your basket has no significant debt holding or debt is held
through Fund of Funds (FoF) in which case we are not able to provide insights
on the debt portion of your basket' gap='1.5rem' />
                </Card>
            </div>
        }

    }
    const tabs = [
        {
            key: 0,
            value: 0,
            label: 'Equity portfolio',
            children: <EquityInsights />
        },
        {
            key: 1,
            value: 1,
            label: 'Debt portfolio',
            children: <DebtInsights />
        },
        {
            key: 2,
            value: 2,
            label: `Individual ${type.strVal?.toUpperCase()} analysis`,
            children: <DisplayMFList x={holdings} type={type} />
        },
        {
            key: 3,
            value: 3,
            label: `Portfolio Overlap`,
            children: <div style={{ minHeight: '100%', backgroundColor: 'var(--grey4)', border: '1px solid var(--grey3)', borderRadius: '0.5rem', overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
                <PortfolioOverlap data={overlap} holdings={holdings} />
            </div>
        },
    ]
    const [selectedTabItem, setSelectedTabItem] = React.useState(0);
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div className="sticky-top" style={{ backgroundColor: 'var(--white)' }}>
                    <CustomSegmentedTab isBlock={false}
                        value={selectedTabItem}
                        mobileStyles={{ marginBottom: '0.5rem' }}
                        onChange={(e) => {
                            setSelectedTabItem(e)
                        }}
                        options={tabs} style={{ marginBottom: '1.25rem' }} />
                </div>
                <div style={{ flex: 1, overflowY: 'scroll' }}>
                    {tabs.filter((i) => i.value === selectedTabItem)[0]?.children}
                </div>
            </div>
            {/* <Drawer
                title={`Stocks in this basket`}
                width={"30rem"}
                push={false}
                onClose={() => setChildrenDrawer(false)}
                open={childrenDrawer}
                bodyStyle={{ padding: 0 }}
                closeIcon={<AiOutlineArrowLeft style={{ fontSize: '1.5rem', color: 'black' }} />} headerStyle={{ paddingLeft: '0.2rem' }}
            >
                {
                    <DisplayTickerList x={insights?.equity_insights?.ticker_list} />
                }
            </Drawer> */}
        </>
    )
}

//done
const StockPortfolioInsightsModal = ({ insights, holdings, type }) => {
    const tickerList = holdings?.map(h => {
        return {
            symbol: h.symbol,
            full_name: h.proper_name,
            weight: h.weights
        }
    })
    const options = { ...insights, detailedEquityInsights: { section_name: 'Individual stock analysis', type: 10, data: tickerList } }
    const tabs = Object.values(options || {})?.map((tab, i) => {
        return {
            key: i,
            value: i,
            label: tab.section_name,
            children: <DisplayEquityInsights x={tab} />
        }
    })
    const [selectedTabItem, setSelectedTabItem] = React.useState(0);
    return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div className="sticky-top" style={{ backgroundColor: 'var(--white)' }}>
            <CustomSegmentedTab isBlock={false}
                value={selectedTabItem}
                mobileStyles={{ marginBottom: '0.5rem' }}
                onChange={(e) => {
                    setSelectedTabItem(e)
                }}
                options={tabs} style={{ marginBottom: '1.25rem' }} />
        </div>
        <div style={{ flex: 1, overflowY: 'scroll' }}>
            {tabs.filter((i) => i.value === selectedTabItem)[0]?.children}
        </div>
    </div>
}

const InsightIcon = ({ type, isRedFlag = false, redFlagType = 'Negative' }) => {
    const NeutralIcon = ({ style }) => (<HiOutlineHandThumbUp style={{ ...style, transform: "rotate(-90deg)" }} />)
    const colors = [
        { type: "Negative", color: "var(--red)", bg: "var(--redShade)", icon: HiOutlineHandThumbDown },
        { type: "Positive", color: "var(--green)", bg: "var(--greenShade)", icon: HiOutlineHandThumbUp },
        { type: "Neutral", color: "var(--yellow)", bg: "var(--yellowShade)", icon: NeutralIcon },
        { type: "NoData", color: "var(--dark3)", bg: "var(--grey1)", icon: MdOutlineSearchOff },
        { type: "RedFlag", color: "var(--red)", bg: "var(--redShade)", icon: IoFlagOutline },
    ]
    const redFlags = [
        { type: "Negative", color: "var(--red)", bg: "var(--redShade)", icon: IoFlagOutline },
        { type: "Positive", color: "var(--green)", bg: "var(--greenShade)", icon: IoFlagOutline },
    ]
    const icon = isRedFlag ? redFlags?.filter((ele) => ele.type === redFlagType)?.[0] : colors.filter((ele) => ele.type === type)?.[0] || colors[3]
    return (
        React.createElement(icon?.icon, {
            style: {
                color: icon.color,
                background: icon.bg,
                height: '3rem',
                width: "3rem",
                borderRadius: "50%",
                padding: "0.75rem"
            }
        })
    )
}
//done
const NoStockInsights = () => {
    const dispatch = useDispatch();
    return (
        <div style={{ minHeight: '100%', backgroundColor: 'var(--grey4)', border: '1px solid var(--grey3)', borderRadius: '0.5rem', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
            <NoDataFound message='' width='10rem' />
            <div className='textSM black mt-2'>
                Seems like we do not have insights for this stock. This could happen due to following reasons:
            </div>
            <ol className='textSM black'>
                <li>Market capitalisation of the stock is less than Rs 100 crore. Currently, insights are available only for stocks with market cap greater than Rs 100 crore.</li>
                <li>We do not have sufficient data to generate meaningful insights.</li>
                <li>Something went wrong with our server</li>
            </ol>
            <div className='textSM black'>
                Do <span onClick={() => { dispatch(toggleHelpAndSupp(true)) }} style={{ color: "var(--primary)", cursor: "pointer" }}>report</span> this issue if you think that we should have insights for this stock.
            </div>
        </div>
    )
}

const NoFundInsights = () => {
    const dispatch = useDispatch();
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', textAlign: 'center' }}>
            <NoDataFound message='' width='10rem' />
            <div className='textSM black mt-4'>
                Seems like we do not have insights for this fund.
            </div>
            <div className='textSM black'>
                Do <span onClick={() => { dispatch(toggleHelpAndSupp(true)) }} style={{ color: "var(--primary)", cursor: "pointer" }}>report</span> this issue if you think that we should have insights for this fund.
            </div>
        </div>
    )
}

const InsightsModal = ({ insights, holdings, overlap, type, detailType, btnType, fetchDataFromApi = false, planId, assetType, ticker, width = '100%' }) => {
    const [isOpen, setIsOpen] = useState(false);
    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    const toggle = () => { setIsOpen(!isOpen) };

    return (
        <>  {btnType === 'icon' ? <TbDeviceDesktopAnalytics style={{
            color: "var(--primary)", fontSize: "1rem", cursor: "pointer", display: "flex"
        }}
            onClick={toggle} /> :
            <button style={{ width: width }} className={btnType === 'small' ? "btnWhiteSM " : 'btnWhite '} onClick={toggle}>
                Insights
            </button>}
            <CustomDrawer title={<div className="textSM w-700 black">{(detailType === 'stockPortfolio' || detailType === 'fundPortfolio') ? "Basket" :
                type?.omkar} Insights</div>} toggle={toggle} isOpen={isOpen}>

                <>{detailType === 'fund' ?
                    <FundInsightsModal type={type} planId={planId} assetType={assetType} fetchFromApi={true} />
                    :
                    detailType === 'stock' ?
                        <AccessComponent
                            component={<StockInsightsModal type={type} ticker={ticker} />} upgradeComponent={
                                <div style={{ minHeight: '100%', backgroundColor: 'var(--grey4)', border: '1px solid var(--grey3)', borderRadius: '0.5rem', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
                                    <UpgradeToPro accessObj={
                                        accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === "STOCK_INSIGHTS")
                                    } height="20rem" width="90%" />
                                </div>
                            }
                            moduleKey={"STOCK_INSIGHTS"} />
                        :
                        detailType === 'fundPortfolio' ?
                            <AccessComponent
                                component={<FundPortfolioInsightsModal insights={insights} holdings={holdings} overlap={overlap} type={type} />} upgradeComponent={<div style={{ minHeight: '100%', backgroundColor: 'var(--grey4)', border: '1px solid var(--grey3)', borderRadius: '0.5rem', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
                                    <UpgradeToPro accessObj={accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === "BASKET_ANALYTICS")} height="20rem" width="90%" />
                                </div>}
                                reqAccessLevel={accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === "BASKET_ANALYTICS")?.AccessLevel} />
                            :
                            detailType === 'stockPortfolio' ?
                                <AccessComponent
                                    component={<StockPortfolioInsightsModal insights={insights} holdings={holdings} type={type} />} upgradeComponent={
                                        <div style={{ minHeight: '100%', backgroundColor: 'var(--grey4)', border: '1px solid var(--grey3)', borderRadius: '0.5rem', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
                                            <UpgradeToPro accessObj={accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === "BASKET_ANALYTICS")} height="20rem" width="90%" />
                                        </div>
                                    }
                                    reqAccessLevel={accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === "BASKET_ANALYTICS")?.AccessLevel} />
                                :
                                <></>
                }</>

            </CustomDrawer>
        </>
    )
}

export { InsightsModal };

