import apiHelper from "Services/api/ApiHelper"

const hiddenSubcategories = [38]

export const getKnowledgeBaseData = async (getFresh = false, withFilter = true, blogNum = 6) => {
    try {
        let categories = await apiHelper({ apiName: "getBlogCategories", saveResponse: "sessionStorage", getFresh: getFresh }) || [];
        let blogCategories = [...(categories?.filter((el) => el?.IsKnowledgeBase === 0 && el?.Category !== 'Featured') || [])]
        categories = categories?.filter((el) => el?.IsKnowledgeBase === 1);
        let subCategories = await apiHelper({ apiName: "getBlogSubCategories", saveResponse: "sessionStorage", getFresh: getFresh }) || [];
        subCategories = subCategories?.filter((el) => !hiddenSubcategories.includes(el?.UID))
        let blogs = await apiHelper({ apiName: "getBlogs", saveResponse: "sessionStorage", getFresh: getFresh }) || [];
        blogs = blogs?.filter((el) => !hiddenSubcategories.includes(el?.SubCategoryId))
        let recentBlogs = [...(blogs || [])]
        recentBlogs = recentBlogs?.reverse()?.filter((el) => el?.IsKnowledgeBase !== 1)?.slice(0, blogNum)
        let blogPosts = [...(blogs?.filter((el) => el?.IsKnowledgeBase !== 1 && el.Category.includes('Featured')) || [])];
        blogPosts = blogPosts?.map((el) => {
            return {
                ...el,
                Slug: "/blog/" + el?.URL?.split('/')?.[2]?.replaceAll("_", "-")?.replace(".html", '')
            }
        })
        recentBlogs = recentBlogs?.map((el) => {
            return {
                ...el,
                Slug: "/blog/" + el?.URL?.split('/')?.[2]?.replaceAll("_", "-")?.replace(".html", '')
            }
        })
        blogCategories = blogCategories?.map((item) => {
            let Slug = '/blog/category/' + item?.Category?.split(' ')?.join('-')?.toLowerCase();
            return {
                ...item,
                Slug
            }
        })
        blogs = blogs?.filter((el) => withFilter ? el?.IsKnowledgeBase === 1 : true);
        blogs = blogs?.map((i) => {
            return {
                ...i,
                URL: i?.URL?.replace(".html", ''),
                CategoryId: Number(i.CategoryId)
            }
        })
        let formattedData = formatKnowledgeBaseData(categories, subCategories, blogs);
        return { categories, subCategories, blogs, formattedData, type: true, blogPosts, recentBlogs, blogCategories };
    }
    catch (err) {
        return { type: false };
    }
}

export const getKnowledgeBaseBlog = async (params) => {
    try {
        let blogs = await apiHelper({ apiName: "getBlogs", saveResponse: "sessionStorage" }) || [];
        blogs = blogs?.filter((el) => el?.IsKnowledgeBase === 1);
        let blog = blogs?.find((item) => item?.Category?.toLowerCase().split(' ').join('-') === params?.category?.toLowerCase() &&
            item?.SubCategory.toLowerCase().split(' ').join('-') === (params.subCategory?.toLowerCase() || '') &&
            item?.Title?.toLowerCase()?.split(' ').join('-') === params.blog?.toLowerCase()) || {};
        if (blog?.Title) {
            let res = await apiHelper({ apiName: "readBlogFile", data: blog?.URL?.split('/')?.[2]?.replace(".html", ''), saveResponse: '' });
            if (res) {
                blog.Content = res;
            }
        }
        return blog;
    }
    catch (err) {
        console.log(err);
        return {}
    }
}

export const getBlogsData = async (getFresh = false) => {
    try {
        let categories = await apiHelper({ apiName: "getBlogCategories", saveResponse: "sessionStorage", getFresh: getFresh }) || [];
        categories = categories?.filter((el) => el?.IsKnowledgeBase === 0 && el?.Category !== 'Featured');
        let blogs = await apiHelper({ apiName: "getBlogs", saveResponse: "sessionStorage", getFresh: getFresh }) || [];
        blogs = blogs?.filter((el) => el?.IsKnowledgeBase === 0);
        blogs = blogs?.map((i) => {
            return {
                ...i,
                Slug: '/blog/' + i?.URL?.split('/')?.[2]?.replaceAll("_", "-")?.replace(".html", '')?.toLowerCase(),
                URL: i?.URL?.replace(".html", ''),
            }
        })
        let featuredBlogs = blogs?.filter((el) => el.Category?.includes('Featured'));
        let formattedData = formatBlogData(categories, blogs);
        return { categories, blogs, formattedData, type: true, featuredBlogs };
    }
    catch (err) {
        return { type: false };
    }
}

const formatKnowledgeBaseData = (allCategories, allSubCategories, allBlogs) => {
    let temp = [...allCategories];
    let base = '/knowledge-base';
    temp.forEach((item, i) => {
        let subs = allSubCategories.filter((ele) => ele.CategoryId === item.UID);
        item.SubCategories = [...subs];
        item.Slug = base + '/' + item?.Category?.split(' ')?.join('-')?.toLowerCase();
        item.SubCategories.forEach((sub, j) => {
            let resources = allBlogs.filter((ele) => ele.SubCategoryId === sub.UID && ele.CategoryId === item.UID);
            sub.Resources = [...resources];
            if (resources.length) {
                sub.FirstBlog = ('/knowledge-base/' + resources[0]?.Category.split(' ').join('-') + '/' + resources[0]?.SubCategory?.split(' ')
                    .join('-') + '/' + resources[0]?.URL.split('/')?.[2]?.replaceAll('_', '-') || '').toLowerCase()?.replace(".html", "");
            }
            else {
                sub.FirstBlog = ('/knowledge-base/' + allBlogs[0]?.Category.split(' ').join('-') + '/' + (allBlogs[0]?.SubCategory?.split(' ')
                    .join('-') || '-') + '/' + allBlogs[0]?.URL.split('/')?.[2]?.replaceAll('_', '-') || '').toLowerCase()?.replace(".html", "");
            }
            sub.Resources.forEach((res, i) => {
                let slug = base + '/' + res?.Category?.split(' ')?.join('-')?.toLowerCase() + '/' +
                    res?.SubCategory?.split(' ')?.join('-')?.toLowerCase() + '/' + res?.URL?.split('/')?.[2]?.replaceAll('_', '-')?.toLowerCase()?.replace(".html", "");
                res.Slug = slug;
            })
            sub.Slug = '/knowledge-base/' + sub?.Category?.split(' ').join('-')?.toLowerCase() + '/' + sub?.SubCategory?.split(' ').join('-')?.toLowerCase()
        })
        let blogs = allBlogs.filter((ele) => ele.CategoryId === item.UID && ele.SubCategoryId === 0);
        item.Resources = [...blogs];
        item.Resources.forEach((res, i) => {
            let slug = base + '/' + res?.Category?.split(' ')?.join('-')?.toLowerCase() + '/' +
                (res?.SubCategory?.split(' ')?.join('-')?.toLowerCase() || '-') + '/' + res?.URL?.split('/')?.[2]?.replaceAll('_', '-')?.toLowerCase();
            res.Slug = slug;
        })
    })
    return temp;
}

const formatBlogData = (allCategories, allBlogs) => {
    let temp = [...allCategories]
    temp.forEach((item, i) => {
        let blogs = allBlogs?.filter((el) => {
            let ct = el?.CategoryId?.split('|')?.map(Number);
            return ct.includes(item.UID);
        })
        item.Slug = '/blog/category/' + item?.Category?.split(' ')?.join('-')?.toLowerCase();
        item.Blogs = blogs;
        item.blogs?.forEach((el) => {
            let slug = '/blog/' + el?.URL?.split('/')?.[2]?.replaceAll("_", "-")?.replace(".html", '')?.toLowerCase();
            el.Slug = slug
        })
    })
    return temp;
}

export const getPrevNextBlogs = async (blogURL) => {
    let res = await getBlogsData();
    let blogs = res.blogs;
    let id = blogs.findIndex((item) => item.URL.split('/')?.[2]?.replaceAll('_', '-') === blogURL);
    return { prev: blogs[id - 1], next: blogs[id + 1] }
}

export const getNextPrevBlog = async (blogURL) => {
    let res = await getKnowledgeBaseData();
    let data = [...res?.formattedData];
    let blogs = [];
    for (let i = 0; i < data.length; i++) {
        let temp = data[i]?.SubCategories?.reduce((a, c) => [...a, ...c?.Resources], []);
        blogs = [...blogs, ...temp, ...data[i]?.Resources]
    };
    let id = blogs.findIndex((item) => item.URL.split('/')?.[2]?.replaceAll('_', '-') === blogURL);
    return { prev: blogs[id - 1], next: blogs[id + 1] }
}