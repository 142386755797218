import { formatDateMMMDDYYYY } from "Components/DateFormatter";
import { NoDataFound } from "Components/NoDataFound";
import { CardHeader } from "Components/atoms/SmallComponents";
import { Card, Skeleton } from "antd";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

export const RecentBlogs = ({ recentBlogs = [], loading, showHeader = true }) => {

    const Loader = () => {
        return (
            <Row lg={3} sm={2} xs={1} className='gx-3 gy-3'>
                {new Array(3)?.fill(3)?.map((el, i) => {
                    return (
                        <Col key={i}>
                            <div key={i} style={{
                                width: '100%', height: "100%", padding: "1.25rem", border: "1px solid var(--grey2)", borderRadius: "",
                                background: "var(--white)"
                            }}>
                                <div style={{ width: "100%", background: "rgb(0, 0, 0, 0.06)", borderRadius: "var(--borderRadius)", height: "8rem", marginBottom: "1.25rem" }}></div>
                                <Skeleton />
                            </div>
                        </Col>
                    )
                })}
            </Row>
        )
    }
    return (
        <>
            <Card>
                {showHeader && <div style={{ marginBottom: "1rem" }}>
                    <CardHeader heading={'Recent blogs'} headingType={2} />
                </div>}
                {loading ? <Loader /> : (recentBlogs?.length > 0 ?
                    <Row lg={3} sm={2} xs={1} className='gx-3 gy-3'>
                        {recentBlogs?.map((blog) => {
                            return (
                                <Col key={blog.UID}>
                                    <div key={blog.UID} style={{
                                        width: '100%', height: "100%", padding: "1.25rem", border: "1px solid var(--grey2)", borderRadius: "var(--borderRadius)",
                                        background: "var(--white)"
                                    }} className='blogCard'>
                                        <Link to={blog.Slug}>
                                            <div style={{ position: "relative", cursor: "pointer" }} className='blogImgCard'>
                                                <div style={{ position: "absolute", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <div className='textSM white'>READ MORE</div>
                                                </div>
                                                <img loading="lazy"  src={blog.ImageUrl} style={{ width: "100%" }} alt={blog.Title + ' thumbnail'} />
                                            </div>
                                        </Link>
                                        <div>
                                            <Link to={blog.Slug}>
                                                <h3 className='textLG w-700 dark4 featuredBlogHeader lineHeightUnset ' style={{ marginTop: "1rem" }}>
                                                    {blog.Title}
                                                </h3>
                                            </Link>
                                            <div style={{ color: "var(--dark3)", }} className='w-500 textSM mt-2'>
                                                by <span style={{ color: "var(--black)" }} className='w-700'>{blog.Author}</span> {formatDateMMMDDYYYY(blog?.UpdatedOn)}
                                            </div>
                                            <div className='textSM dark4' style={{ marginTop: "1rem" }}>
                                                {blog.Description?.substring(0, 140)}
                                                {blog.Description?.length > 140 && '...'}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row> : <div style={{ width: "100%", margin: "1.25rem 0" }} className='d-flex justify-content-center'>
                        <NoDataFound message='No blogs found' />
                    </div>)}
            </Card>
        </>
    )
}