import CustomTag from 'Components/atoms/CustomTag';
import { fetchFundData } from 'Libs/fetchFundData';
import { getIndexList, getStockSectorCLF } from 'Libs/utils';
import AdminServicesPY from 'Services/api/AdminServicesPY';
import apiHelperPY from 'Services/api/ApiHelperPY';
import { Select } from 'antd';
import Highcharts from 'highcharts';
import HighchartsTreemap from "highcharts/modules/treemap";
import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AMCIcon from './AMCIcon';
import ActivityLoaderMintbox from './ActivityLoaderMintbox';
import { CurrencyFormat } from './CurrencyFormat';
import { DecimalValueFormat } from './DecimalValueFormat';
HighchartsTreemap(Highcharts)

const IndexOrSectorsTreeChart = ({ allInstrumentListData, type, header = '', showIndexDropdown = false, filters, updateFilter = true, applyFilters = true, removeFalsyValues = true,
    subHeading = '', showAdvDec = true, showSectorDropdown = false, isCard = false, borderRadius0InMob = false, defShowKey = '', showReturnsDropdown = true,
    updateItemsOnLivePriceChange = true, isIndexList = false, showItemIcon = true, showLTP = true, nameKey = '', itemClickDisabled = false }) => {

    const navigate = useNavigate();
    const stockprices = useSelector((state) => state?.stockprices?.value)
    const prevstockprices = useSelector((state) => state?.prevStockPrices?.value)
    const [allFundsList, setAllFundsList] = React.useState([]);
    const [allFunds, setAllfunds] = React.useState([]);
    const [sectors, setSectors] = React.useState([]);
    const [indices, setIndices] = React.useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState('NIFTY50');
    const [allIndexConstituents, setAllIndexConstituents] = React.useState({});
    const [selectedSector, setSelectedSector] = React.useState('');
    const [initialDataLoad, setInitialDataLoad] = React.useState(true);
    const [load, setLoad] = React.useState(true);

    const tabOptions3 = type?.value === 4 ? [
        { "value": "1D", "queryStr": "changePer", "label": "1D returns" },
        { "value": "1M", "queryStr": "one_mth_pct", "label": "1M returns" },
        { "value": "3M", "queryStr": "three_mth_pct", "label": "3M returns" },
        { "value": "6M", "queryStr": "six_mth_pct", "label": "6M returns" },
        { "value": "1Y", "queryStr": "one_yr_pct", "label": "1Y returns" },
        { "value": "3Y", "queryStr": "three_yr_pct", "label": "3Y returns" },
        { "value": "5Y", "queryStr": "five_yr_pct", "label": "5Y returns" },
        { "value": "10Y", "queryStr": "ten_yr_pct", "label": "10Y returns" }
    ] : [
        { queryStr: "changePer", value: "1D", label: "1D" },
        { queryStr: "ret_1month", value: "ret_1month", label: "1M returns" },
        { queryStr: "ret_3month", value: "ret_3month", label: "3M returns" },
        { queryStr: "ret_6month", value: "ret_6month", label: "6M returns" },
        { queryStr: "ret_ytd", value: "ret_ytd", label: "YTD returns" },
        { queryStr: "cagr_1y", value: "cagr_1y", label: "1Y returns" },
    ]

    const [selectedYear, setselectedYear] = useState(tabOptions3[0].value)
    const [showKey, setShowKey] = useState(tabOptions3?.filter((op) => op?.value === selectedYear)[0]?.queryStr)

    const loadData = async () => {
        setInitialDataLoad(true);
        let allFunds = []
        if (allInstrumentListData) {
            allFunds = allInstrumentListData;
            // setAllFundsList(allInstrumentListData);
        }
        else {
            let res = await fetchFundData(type.value)
            allFunds = res;
            // setAllFundsList(res);
        }
        let filtersFinal = [];
        if (applyFilters && filters !== undefined) {
            filtersFinal = filters?.map((el) => el?.[0]);
        }
        if (showSectorDropdown) {
            filtersFinal = [...filtersFinal, 'sector_code'];
        }
        tabOptions3?.splice(0, 1);
        if (type?.value === 4) {
            var fundData = await AdminServicesPY.getStocksColData({
                stocks: [...allFunds]?.map((fund) => { return fund?.symbol }),
                cols: [...tabOptions3?.map((i) => { return i?.queryStr }), ...filtersFinal], all_cols: false
            }).then(r => r)
            allFunds = JSON.parse(fundData?.data)?.map((i) => {
                return {
                    ...i,
                    ...allFunds?.filter((ii) => ii?.symbol === i?.symbol)?.[0],
                }
            })
        }
        setAllFundsList(allFunds);
        if (showSectorDropdown) {
            let res = await getStockSectorCLF();
            if (res) {
                setSelectedSector(res?.sectors?.[0]?.value);
                setSectors(res?.sectors);
            }
        }
        if (showIndexDropdown) {
            let indices = await getIndexList();
            indices = indices?.filter((el) => el?.is_sharpely_coverage === 1);
            //console.log(indices);
            setIndices(indices);
            setSelectedIndex(indices?.[0]?.IndexNameCaps);
            let indCons = await apiHelperPY({ apiName: "getIndexConstituents" });
            indCons = JSON.parse(indCons);
            setAllIndexConstituents(indCons);
        }
        setInitialDataLoad(false);
    }

    const sortFunds = (arr, key, order = "top") => {
        var arrr = [...arr]?.filter((i) => removeFalsyValues ? (i?.[key] !== undefined && !isNaN(i?.[key]) && i?.[key] !== null) : true)
        if (order === "bot") {
            arrr = arrr.sort((a, b) => a[key] - b[key])
        } else {
            arrr = arrr.sort((a, b) => b[key] - a[key])
        }
        setLoad(false)
        return arrr;
        // return arrr
    }

    const getFinalData = async () => {
        var allfundsc = allFundsList;
        var filtersFinal = []
        if (applyFilters && filters !== undefined) {
            filtersFinal = [...filters]
        }
        if (showSectorDropdown) {
            filtersFinal = [
                ...filtersFinal,
                ['sector_code', selectedSector]
            ]
        }
        if (filtersFinal?.length > 0) {
            try {
                for (let i = 0; i < filtersFinal?.length; i++) {
                    let filter = filtersFinal[i];
                    allfundsc = allfundsc?.filter((ele) => {
                        return ele?.[filter?.[0]] === filter?.[1]
                    })
                }
            } catch (error) {
                //console.log(error);
            }
        }
        if (showIndexDropdown) {
            allfundsc = allfundsc?.filter((ele) => {
                return allIndexConstituents?.[selectedIndex]?.syms?.includes(ele?.symbol);
            })
        }
        // //console.log(stockprices);

        allfundsc = allfundsc.map((fund) => {
            let obj = {}
            if (type.value === 2) {
                var changeVal, changePer, currentLTP = "-"
                let ele = fund
                currentLTP = ele?.latest_nav || 0
                changePer = ele?.ret_1day || 0
                let originalPrice = currentLTP / (1 + (changePer / 100))
                changeVal = currentLTP - originalPrice;
                obj = {
                    ...fund,
                    "currentLTP": currentLTP,
                    "changePer": changePer,
                    "changeVal": changeVal,
                    "abs_changePer": Math.abs(changePer),
                    "abs_changeVal": Math.abs(changeVal)
                }
            }
            else {
                var changeVal, changePer, currentLTP = "-"
                var currentSymbolAllData = stockprices === "close" ? prevstockprices?.[fund?.gdf_symbol] : (stockprices?.[fund?.gdf_symbol] || prevstockprices?.[fund?.gdf_symbol])

                currentLTP = currentSymbolAllData?.LastTradePrice
                changeVal = currentSymbolAllData?.PriceChange
                changePer = currentSymbolAllData?.PriceChangePercentage

                if (currentSymbolAllData == undefined) {
                    let ele = fund
                    currentLTP = ele?.price || 0
                    changePer = ele?.one_day_pct || 0
                    let originalPrice = currentLTP / (1 + (changePer / 100))
                    changeVal = currentLTP - originalPrice;
                }
                obj = {
                    ...fund,
                    "currentLTP": currentLTP,
                    "changePer": changePer,
                    "changeVal": changeVal,
                    "abs_changePer": Math.abs(changePer),
                    "abs_changeVal": Math.abs(changeVal)
                }
            }
            return obj;
        })
        // }
        setAllfunds(sortFunds(allfundsc, showKey, 'top'))
    }

    const handleNavigate = (fund) => {
        if (itemClickDisabled) {
            return;
        }
        else if (isIndexList) {
            navigate(`/indices/index-detail/${fund?.IndexName?.replaceAll(' ', '-')}/${fund?.index}`)
        }
        else {
            navigate(type.basePath + `${type.value === 4 ? '/stock-detail/' + fund?.proper_name?.replaceAll(' ', '-') + '/' + fund.symbol : '/fund-detail/' + fund?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + fund.plan_id}`)
        }
    }

    React.useEffect(() => {
        setLoad(true)
        loadData();
    }, [filters, allInstrumentListData, showSectorDropdown, showIndexDropdown])

    React.useEffect(() => {
        if (defShowKey) {
            setShowKey(defShowKey)
        }
    }, [defShowKey])

    React.useEffect(() => {
        if (initialDataLoad === false) {
            setLoad(true)
            setTimeout(() => {
                getFinalData()
            }, 200);
        }
    }, [initialDataLoad, allFundsList, selectedIndex, selectedSector, showKey])

    React.useEffect(() => {
        if (initialDataLoad === false && updateItemsOnLivePriceChange) {
            getFinalData()
        }
    }, [initialDataLoad, stockprices, prevstockprices])

    var r = 131, g = 190, b = 110
    var r1 = 255, g1 = 105, b1 = 84

    try {
        return (
            <div style={{ padding: isCard ? "1.25rem" : "0", background: isCard ? "var(--white)" : "transparent", borderRadius: isCard ? "var(--borderRadius)" : "0" }}
                className={`${borderRadius0InMob ? "mobCardBorderRadius0" : ""}`}>
                <div className='d-flex justify-content-between mobileFlexCol' style={{ gap: "1.25rem", alignItems: subHeading ? "flex-end" : "center" }} >
                    {header && <div>
                        {header}
                        {subHeading && <div className='customTabsSubHeading' style={{ marginBottom: "0" }}>
                            {subHeading}
                        </div>}
                    </div>}
                    <div className='d-flex justify-content-between align-items-center' style={{ gap: "1.25rem" }} >
                        {showIndexDropdown && !initialDataLoad && <Select className='textSM black '
                            style={{ width: window.innerWidth < 760 ? "16rem" : "11rem" }} suffixIcon={<IoIosArrowDown size={15} />} value={selectedIndex}
                            placeholder="Stock universe" onChange={(value) => { setSelectedIndex(value) }}
                            options={indices} />}
                        {showSectorDropdown && !initialDataLoad && <Select className='textSM black'
                            style={{ width: window.innerWidth < 760 ? "20rem" : "13rem" }} suffixIcon={<IoIosArrowDown size={15} />}
                            value={selectedSector} placeholder="Select sector" onChange={(value) => { setSelectedSector(value) }} options={sectors} />}
                        {showReturnsDropdown && <Select
                            className='textSM black'
                            style={{ width: window.innerWidth < 760 ? "10rem" : "8rem" }}
                            suffixIcon={<IoIosArrowDown size={15} />}
                            defaultValue={selectedYear}
                            placeholder="Please select return period"
                            onChange={(value) => {
                                setselectedYear(value)
                                setShowKey(tabOptions3?.filter((op) => op?.value === value)[0]?.queryStr)
                            }}
                            options={tabOptions3}
                        />}
                    </div>
                </div>
                {showAdvDec && !load && <div className='d-flex align-items-center mt-4' style={{ gap: "1rem" }}>
                    <CustomTag bgColor={'var(--green)'} color='var(--white)' text={<span className='textXS w-500'>
                        Advance: {allFunds?.filter((fund) => fund?.[showKey] >= 0)?.length}</span>} />
                    <CustomTag bgColor={'var(--red)'} color='var(--white)' text={<span className='textXS w-500'>
                        Decline: {allFunds?.filter((fund) => fund?.[showKey] < 0)?.length}</span>} />
                </div>}
                <div className='scrollBox' style={{
                    maxHeight: window.innerWidth < 760 ? "60vh" : "85vh",
                    overflowY: "scroll", marginTop: "1.25rem"
                }}>
                    {load ? <div style={{
                        minHeight: "20vh",
                    }}>
                        <ActivityLoaderMintbox />
                    </div> : allFunds?.length > 0 ? <span className='heatmapWrapper'>
                        {allFunds?.filter((fund) => fund?.[showKey] >= 0)?.map((fund, index) => {
                            return <div key={fund?.symbol} style={{
                                flex: 1,
                                padding: "1rem", alignItems: "center", cursor: "pointer",
                                border: "0.1px solid var(--grey3)", borderRadius: 1, flexShrink: 0,
                                display: "flex", flexDirection: "column", backgroundColor: `rgba(${r},${g}, ${b},${(allFunds?.filter((fund) => fund?.[showKey] > 0).length - index) * 100 / (allFunds?.filter((fund) => fund?.[showKey] > 0).length - 1)}%)`
                            }} onClick={() => handleNavigate(fund)} >
                                <div style={{ display: "flex", flexDirection: "row", alignItems: nameKey ? "start" : "center", gap: "0.5rem" }}>
                                    {showItemIcon && <AMCIcon amcName={type.value === 4 ? fund?.symbol : fund?.amc_full_name} fundType={type.value} height='1rem' width="1rem"
                                        bseTicker={fund?.bse_ticker} />}
                                    <div className={`textXS w-500 ${!nameKey ? 'text-center' : ""}`}>{nameKey ? fund?.[nameKey]?.replace(" - Direct Plan", "") : fund?.symbol}</div>
                                </div>
                                {showLTP && <div className='textXS w-500'>{CurrencyFormat(fund?.currentLTP)}</div>}
                                <div className='textXS w-500'>{DecimalValueFormat({ num: fund?.[showKey], suffix: "%" })}</div>
                            </div>
                        })
                        }
                        {allFunds?.filter((fund) => fund?.[showKey] < 0)?.map((fund, index) => {
                            return <div key={fund?.symbol} style={{
                                flex: 1,
                                padding: "1rem", alignItems: "center", cursor: "pointer",
                                border: "0.1px solid var(--grey3)", borderRadius: 1, flexShrink: 0,
                                display: "flex", flexDirection: "column", backgroundColor: `rgba(${r1},${g1}, ${b1}, ${(index + 1) * 100 / (allFunds?.filter((fund) => fund?.[showKey] < 0).length - 1)}%)`
                            }} onClick={() => handleNavigate(fund)} >
                                <div style={{ display: "flex", flexDirection: "row", alignItems: nameKey ? "start" : "center", gap: "0.5rem" }}>
                                    {showItemIcon && <AMCIcon amcName={type.value === 4 ? fund?.symbol : fund?.amc_full_name} fundType={type.value} height='1rem' width="1rem"
                                        bseTicker={fund?.bse_ticker} />}
                                    <div className={`textXS w-500 ${!nameKey ? 'text-center' : ""}`}>{nameKey ? fund?.[nameKey]?.replace(" - Direct Plan", "") : fund?.symbol}</div>
                                </div>
                                {showLTP && <div className='textXS w-500'>{CurrencyFormat(fund?.currentLTP)}</div>}
                                <div className='textXS w-500'>{DecimalValueFormat({ num: fund?.[showKey], suffix: "%" })}</div>
                            </div>
                        })}
                    </span > : <div>
                        <div className='textSM w-500 dark3 my-3 text-center'>
                            We currently have no data {tabOptions3?.find((el) => el?.queryStr === showKey)?.label} for stocks. Please try changing the timeframe {
                                showIndexDropdown && showSectorDropdown ? ', sector or index.' : showIndexDropdown ? ' or index.' : showSectorDropdown ? ' or sector.' : null
                            }</div>
                    </div>
                    }
                </div>
            </div>
        );
    } catch (error) {
        //console.log(error)
        return <div className='textXS' style={{ margin: "4%", textAlign: "center" }}>
            No chart data available!
        </div>
    }
};

export default IndexOrSectorsTreeChart;