import React from 'react';

const ActivityLoaderMintbox = ({ type = '', margin = '0%', message = "", showQuote = false, messageClassName = 'textSM w-500 text-center', textListToShow = [], eachTextTime = 1500 }) => {

  const quotes = [{ q: 'Rule No. 1: Never lose money. Rule No. 2: Never forget Rule No. 1.', w: "Warren Buffet" },
  { q: 'The big money is not in the buying and selling, but in the waiting.', w: "Charlie Munger" },
  { q: "You don't have to be brilliant, only a little bit wiser than the other guys, on average, for a long, long time.", w: "Charlie Munger" },
  { q: "It's far better to buy a wonderful company at a fair price than a fair company at a wonderful price.", w: "Warren Buffet" },
  { q: "The most important investment you can make is in yourself.", w: "Warren Buffet" },
  { q: "In the business world, the rear view mirror is always clearer than the windshield.", w: "Warren Buffet" },
  { q: "Be fearful when others are greedy and greedy when others are fearful.", w: "Warren Buffet" },
  { q: "The way to build superior long-term returns is through preservation of capital and home runs.", w: "Stan Druckenmiller" },
  { q: "The stock market is filled with individuals who know the price of everything, but the value of nothing.", w: "Philip Fisher" },
  { q: "I have a rule that I never, ever hold on to a mistake longer than I have to. I prefer to ride my winners.", w: "Stan Druckenmiller" },
  { q: "The stock market is never obvious. It is designed to fool most of the people, most of the time.", w: "Jesse Livermore" },
  { q: "The stock market is a voting machine in the short term and a weighing machine in the long term.", w: "Benjamin Graham" },
  { q: "Investing should be more like watching paint dry or watching grass grow. If you want excitement, take $800 and go to Las Vegas.", w: "Paul Samuelson" },
  { q: "The key to making money in stocks is not to get scared out of them.", w: "Peter Lynch" },
  { q: "The stock market is not a casino; it is a device for transferring wealth from the impatient to the patient.", w: "John Bogle" }
  ]

  let quote = quotes[Math.floor(Math.random() * 15)];
  const [textToShow, setTextToShow] = React.useState(0);
  const intervalRef = React.useRef();
  React.useEffect(() => {
    if (textListToShow?.length > 0) {
      intervalRef.current = setInterval(() => {
        setTextToShow(prev => prev + 1)
      }, eachTextTime)
    }
    return () => {
      if (intervalRef?.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, []);

  React.useEffect(() => {
    if (textListToShow?.length > 0 && textToShow === textListToShow?.length - 1) {
      clearInterval(intervalRef.current)
    }
  }, [textToShow]);

  return (
    <div style={{
      marginTop: margin, marginBottom: margin, textAlign: 'center', display: "flex", alignItems: "center", justifyContent: "center", width: "100%",
      height: "100%", flexDirection: "column",
    }}>
      {/* <Lottie options={{
        loop: true,
        speed:2,
        autoplay: true,
        height: "10rem",
        width: "10rem",
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }} width='10rem' height='10rem' isClickToPauseDisabled /> */}
      <img width='10rem' height='10rem' style={{
        height: "10rem",
        width: "10rem",
      }} src={require('Assets/lottie/chart-load.gif')} alt="loading..." />
      {textListToShow?.length > 0 ? <div className={messageClassName}>
        {textListToShow?.[textToShow]}
      </div> :
        message !== '' ?
          <div className={messageClassName} style={{ maxWidth: "60ch", padding: window.innerWidth > 575 ? "0" : "0 1.25rem" }}>{message}</div>
          : showQuote ?
            <>
              <div className='textSM dark3 text-center' style={{ maxWidth: "60ch", padding: window.innerWidth > 575 ? "0" : "0 1.25rem" }} >
                <q style={{ fontStyle: "italic", }}>
                  {quote?.q}
                </q>
                <div className='textMD w-500 black' style={{ textAlign: "right" }}>
                  - {quote.w}
                </div>
              </div>
            </> : null
      }
    </div>
  )
}

export default React.memo(ActivityLoaderMintbox)