import AMCIcon from "Components/AMCIcon";
import AccessComponent from "Components/AccessComponent";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import { CurrencyFormat } from "Components/CurrencyFormat";
import CustomSegmentedTab from "Components/CustomSegmentedTab";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import { InsightsModal } from "Components/Insights";
import InstrumentListTable from "Components/InstrumentListTable";
import { NoDataFound } from "Components/NoDataFound";
import RedGreenText from "Components/RedGreenText";
import SimilarFunds from "Components/SimilarFunds";
import { useSubscribeMiddleware } from "Components/SubscribeModal";
import UpgradeToPro from "Components/UpgradeToPro";
import { CardHeader } from 'Components/atoms/SmallComponents';
import TooltipCloseOnClick from "Components/atoms/TooltipCloseOnClick";
import { useIsMobile } from "CustomHooks/useIsMobile";
import Head from "Layout/Head";
import { fetchFundData } from "Libs/fetchFundData";
import { toggleWatchlistModal, updateWatchlistModalParams, updateWatchlists } from "Libs/redux/UserAuth/reduxSlice";
import { getFormattedWatchlist, useGetAssetType } from "Libs/utils";
import AdminServicesPY from "Services/api/AdminServicesPY";
import apiHelper from "Services/api/ApiHelper";
import { StockLivePrice } from "Services/api/LivePricesWebSocket";
import { Card, Popconfirm, Tabs, Tooltip } from "antd";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { BiPlus } from "react-icons/bi";
import { MdDelete, MdDeleteOutline, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

export default function WatchlistPage({ isWidget = false, widgetItem = {}, allETFs, allMFs, allStocks }) {

    const [loading, setLoading] = useState(true);
    let watchlist = useSelector((state) => state?.userWatchlists?.value);
    let user = useSelector((state) => state?.updateUserData?.value);
    const [allFunds, setAllFunds] = React.useState([]);
    const [isWatchlistLoaded, setIsWatchlistLoaded] = React.useState(false);
    const dispatch = useDispatch();
    const stockType = useGetAssetType(4);
    const etfType = useGetAssetType(1);
    const mfType = useGetAssetType(2);
    const [selectedInstrumentTab, setSelectedInstrumentTab] = useState(4);
    const filteredWatchlist = watchlist ? watchlist?.filter((el) => el?.FundType === selectedInstrumentTab) : [];
    const [selectedWatchlist, setSelectedWatchlist] = React.useState(filteredWatchlist?.[0] || {})
    const defType = selectedInstrumentTab === 4 ? stockType : selectedInstrumentTab === 1 ? etfType : mfType;
    const subscribeMiddle = useSubscribeMiddleware();

    let isMobile = useIsMobile();

    const loadData = async () => {
        if (watchlist !== undefined && !isWatchlistLoaded) {
            let etfs = allETFs ? allETFs : await fetchFundData(1);
            etfs = etfs?.map((el) => { return { ...el, type: 1 } })
            let mfs = allMFs ? allMFs : await fetchFundData(2);
            mfs = mfs?.map((el) => { return { ...el, type: 2 } })
            let stocks = allStocks ? allStocks : await fetchFundData(4);
            stocks = stocks?.map((el) => { return { ...el, type: 4 } })
            let stockCols = ['symbol', 'nse_series', 'proper_name', 'bse_ticker', '52w_high', '52w_low', 'nse_active', 'nse_segment', 'one_day_pct', 'price', 'bse_segment',
                'mcap', 'nse_basic_ind_code', 'sh_3fac_comp', 'five_yr_pct']
            var results = await AdminServicesPY.getStocksColData({ stocks: stocks?.map((el) => el?.symbol), cols: stockCols, all_cols: false }).then(r => r)
            try {
                results = JSON.parse(results?.data);
                stocks = results?.map((fund) => {
                    return {
                        ...fund,
                        "gdf_symbol": fund?.symbol,
                        type: 4
                    }
                })
            }
            catch (err) {
                console.log(err)
            }
            setAllFunds([...etfs, ...mfs, ...stocks]);
            setIsWatchlistLoaded(true);
            setLoading(false);
        }
        else if (isWatchlistLoaded) {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        setLoading(true);
        loadData();
    }, [watchlist])

    const deleteWatchlist = async (fund) => {
        let key = selectedInstrumentTab === 4 ? "symbol" : selectedInstrumentTab === 1 ? "nse_symbol" : "plan_id";
        let res2 = await apiHelper({
            apiName: "removeItemFromWatchlist", data: {
                "clientId": user?.id, "watchlistId": selectedWatchlist?.WatchListId,
                fundCode: String(fund?.[key]), fundType: selectedInstrumentTab
            }
        })
        if (res2?.Result === 'success') {
            toast.success(`Item removed from ${selectedWatchlist?.Name}`)
            let res3 = await apiHelper({ apiName: 'getWatchListByClientId', data: { clientId: user.id } });
            if (res3.length > 0) {
                let forwat2 = getFormattedWatchlist(res3);
                dispatch(updateWatchlists(forwat2));
            }
        }
        else {
            toast.error('Something went wrong!')
        }
    }

    const instrumentTabs = [
        { key: 4, label: isMobile ? "Stocks" : "Stock watchlist", value: 4 },
        { key: 1, label: isMobile ? "ETFs" : "ETF watchlist", value: 1 },
        { key: 2, label: isMobile ? "MFs" : "MF watchlist", value: 2 },
    ]

    const watchlistAccess = subscribeMiddle({
        moduleKey: Number(selectedInstrumentTab) === 4 ? "MULTIPLE_WATCHLISTS" : Number(selectedInstrumentTab) === 1 ? "MULTIPLE_WATCHLISTS_ETFs" :
            Number(selectedInstrumentTab) === 2 ? "MULTIPLE_WATCHLISTS_MF" : "", getDetailedAccessData: true, checkLimit: true, usageCount: filteredWatchlist?.length
    })

    const dataTableColumns = [
        {
            "title": "Name",
            'disableDeleteInCustomize': true,
            "dataIndex": selectedInstrumentTab === 4 ? "proper_name" : "basic_name",
            width: 300,
            fixed: window.innerWidth > 760 ? 'left' : "",
            show: true,
            render: (text, record) => {
                return (
                    <Tooltip title={text?.replace(" - Direct Plan", "")} arrow={false}>
                        <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center" }} className="showContentOnHoverRow">
                            <AMCIcon amcName={record.symbol || record?.amc_full_name} fundType={selectedInstrumentTab} height='1.15rem' width="1.15rem" bseTicker={record?.bse_ticker} />
                            <div style={{ flex: 1 }}>
                                <div className="w-500 black textEllipsis1">{isMobile ? (selectedInstrumentTab === 4 ? record?.symbol : selectedInstrumentTab === 1 ? record?.nse_symbol : text?.replace(" - Direct Plan", "")) : text?.replace(" - Direct Plan", "")}</div>
                                <div style={{ display: "flex", gap: "0.1rem", alignItems: "center" }}>
                                    {selectedInstrumentTab !== 2 ? <StockLivePrice fundData={record} symbol={record?.symbol} format={10} /> : <>
                                        <RedGreenText text={CurrencyFormat(record?.latest_nav, 'long', 2, '', true)} value={record?.latest_nav} />
                                        &nbsp;
                                        <RedGreenText text={<span>({DecimalValueFormat({ num: record?.ret_1day, suffix: "%" })})</span>} value={record?.ret_1day} arrow />
                                    </>}
                                </div>
                            </div>
                            <div className="text-left textXS primary content d-flex align-items-center" style={{ gap: "1rem" }}>
                                <TooltipCloseOnClick title={<span className='textXS w-500'>{`Detailed insights of this ${selectedInstrumentTab === 4 ? 'stock' : "fund"} with red flag analysis.`}</span>} arrow={false}>
                                    <span>
                                        {selectedInstrumentTab === 4 ? <InsightsModal type={stockType} ticker={record?.symbol} detailType='stock' width='fit-content' btnType='icon' /> :
                                            <InsightsModal planId={record?.plan_id} assetType={record?.primary_category_name?.toLowerCase()} detailType='fund' width='fit-content' btnType='icon'
                                                type={selectedInstrumentTab === 1 ? etfType : mfType} />}
                                    </span>
                                </TooltipCloseOnClick>
                                <MdDeleteOutline style={{
                                    color: "var(--red)", fontSize: "1rem", flexShrink: 0, cursor: 'pointer'
                                }} onClick={() => deleteWatchlist(record)} />
                            </div>
                        </div>
                    </Tooltip>
                )
            }
        },
        {
            title: "CAGR 3Y",
            dataIndex: 'cagr_3y',
            key: "cagr_3y",
            align: "right",
            width: 150,
            render: (val, fundData) => {
                return DecimalValueFormat({ num: fundData?.['cagr_3y'], suffix: " %", placeholder: "-" })
            },
            show: [1, 2]?.includes(selectedInstrumentTab) && !isMobile
        }
    ]

    const getSuggestedFunds = React.useCallback(() => {
        if (selectedWatchlist?.FundCodes?.length === 0) {
            let tf = allFunds?.filter((el) => el?.type === selectedInstrumentTab);
            let codes = filteredWatchlist?.reduce((a, c) => { return [...a, ...c.FundCodes] }, []) || [];
            let key = selectedInstrumentTab === 4 ? "symbol" : selectedInstrumentTab === 1 ? "nse_symbol" : "plan_id";
            tf = tf?.filter((el) => !codes?.includes(el?.[key]));
            tf = tf.sort((a, b) => {
                if (selectedInstrumentTab === 4) {
                    return b?.mcap - a?.mcap
                }
                else return b?.aum - a?.aum
            })
            tf = tf?.slice(0, 20);
            return tf;
        }
        else return []
    }, [selectedWatchlist, allFunds, selectedInstrumentTab]);


    React.useEffect(() => {
        setSelectedWatchlist(watchlist ? watchlist?.filter((el) => el?.FundType === selectedInstrumentTab)?.[0] : {})
    }, [selectedInstrumentTab, isWatchlistLoaded]);

    React.useEffect(() => {
        if (selectedWatchlist?.WatchListId && watchlist?.filter((el) => el?.WatchListId === selectedWatchlist?.WatchListId)?.[0]?.WatchListId) {
            setSelectedWatchlist(watchlist?.filter((el) => el?.WatchListId === selectedWatchlist?.WatchListId)?.[0] || {})
        }
        else {
            setSelectedWatchlist(watchlist ? watchlist?.filter((el) => el?.FundType === selectedInstrumentTab)?.[0] : {})
        }
    }, [watchlist]);

    const suggestedFunds = getSuggestedFunds();

    const onDeleteWatchlist = async (watchToEdit) => {
        try {
            let res = await apiHelper({
                apiName: "removeWatchlist", data: {
                    clientId: user?.id, name: watchToEdit?.Name, watchlistId: watchToEdit?.WatchListId,
                    fundType: watchToEdit?.FundType
                }
            });
            if (res?.Result === 'success') {
                let watchlistt = await apiHelper({ apiName: 'getWatchListByClientId', data: { clientId: user.id } });
                if (watchlistt?.length > 0) {
                    let formattedWatchlist = getFormattedWatchlist(watchlistt);
                    setSelectedWatchlist(formattedWatchlist ? formattedWatchlist?.filter((el) => el?.FundType === selectedInstrumentTab)?.[0] : {})
                    dispatch(updateWatchlists(formattedWatchlist));
                }
                else {
                    dispatch(updateWatchlists([]));
                }
                toast.success('Watchlist deleted.');
            }
            else {
                toast.error('Something went wrong!');
            }
        }
        catch (err) {
            console.log(err);
            toast.error('Something went wrong!');
        }
    }

    const WatchTable = () => {

        return (<>
            <div className="mb-4">
                <Tabs defaultActiveKey={selectedWatchlist?.WatchListId} items={filteredWatchlist?.map((el, i) => {
                    return {
                        key: el?.WatchListId, label: <span className="d-flex align-items-center" style={{ gap: "0.5rem" }}>
                            {el?.Name}
                        </span>,
                        value: el?.WatchListId,
                        disabled: watchlistAccess?.maxLimit < (i + 1)
                    }
                })} onChange={(e) => {
                    let t = filteredWatchlist?.find((el) => el?.WatchListId === e);
                    setSelectedWatchlist(t);
                }} style={{ overflowX: "scroll", fontWeight: 700, flex: 1, width: isMobile ? "100%" : '', flexShrink: 1 }} />
            </div>
            {selectedWatchlist?.FundType === selectedInstrumentTab ?
                <InstrumentListTable
                    tableType={selectedInstrumentTab === 4 ? 5 : 0}
                    finalList={selectedWatchlist?.FundType === selectedInstrumentTab ? selectedWatchlist?.FundCodes?.map((el) => {
                        return allFunds?.find((ele) => el === String(ele?.[selectedInstrumentTab === 1 ? "nse_symbol" : selectedInstrumentTab === 2 ? "plan_id" : "symbol"]))
                    })?.filter((el) => el) || [] : []}
                    defaultInsType={selectedInstrumentTab}
                    isCard={false}
                    otherCol={dataTableColumns?.filter((ele) => ele.show)}
                    hideCheckBox={true}
                    hidePaddingInMob
                    tableHeader={<div className="d-flex align-items-center" style={{ gap: "0.5rem", cursor: "pointer" }} onClick={() => {
                        dispatch(updateWatchlistModalParams({
                            editWatchList: true,
                            type: selectedInstrumentTab,
                            watchToEdit: selectedWatchlist
                        }))
                        dispatch(toggleWatchlistModal(true))
                    }}>
                        {selectedWatchlist?.Name} ({selectedWatchlist?.FundCodes?.length})
                        <MdEdit />
                    </div>}
                    showBubbleChartBtn
                    showHeatmapBtn
                    isScrollingTable={true} showToolsBtnsInTab showDeleteButton deleteBtnComp={<Popconfirm arrow={false} title={<span className='textSM w-500 dark4' style={{ userSelect: "none" }}>
                        Are you sure you want to delete this<br /> watchlist?</span>}
                        description={<span className='textXS w-500 dark3 mt-3' style={{ userSelect: "none" }}>Note: This action is irreversible.</span>}
                        onConfirm={() => onDeleteWatchlist(selectedWatchlist)}>
                        <button className="btnWhite">
                            <MdDelete style={{ color: "var(--red)", cursor: "pointer" }} />
                        </button>
                    </Popconfirm>}
                    downloadTable fileDefaultName={selectedWatchlist?.Name} downloadFileAccessKey={selectedInstrumentTab === 4 ? "STOCK_DOWNLOAD_WATCHLIST" : "MF_ETF_DOWNLOAD_WATCHLIST"}
                    noDataMessage={`Looks like your watchlist is empty. Begin by adding ${selectedInstrumentTab === 4 ? "stocks" : selectedInstrumentTab === 1 ? "etfs" :
                        "mfs"} to keep a close eye on their performance and make informed investment decisions.`}
                /> : null}
        </>)
    }

    if (loading) {
        return <div className="loaderContainer">
            <ActivityLoaderMintbox showQuote />
        </div>
    }
    return (
        <React.Fragment>
            {isWidget ? <></> : <Head title={'Watchlist'} />}
            <div className="normalContainer">
                <Card style={{ padding: 0, borderRadius: "var(--borderRadius)" }} bodyStyle={{ padding: "1.25rem" }}>
                    {isWidget && <div style={{ marginBottom: '1rem' }}>
                        <CardHeader heading={widgetItem?.Name} headingType={2} />
                        <div className="customTabsSubHeading" style={{ marginBottom: 0 }}>{widgetItem?.Description}</div>
                    </div>}
                    <div className="d-flex align-items-center justify-content-between" style={{ gap: "1.25rem" }}>
                        <CustomSegmentedTab options={instrumentTabs}
                            value={selectedInstrumentTab}
                            onChange={(e) => setSelectedInstrumentTab(e)}
                            isBlock={false} />
                        <div className="d-flex align-items-center" style={{ gap: "1.25rem" }}>
                            <button className="btnBlack " style={{ whiteSpace: 'nowrap' }} onClick={() => {
                                dispatch(updateWatchlistModalParams({
                                    createNewWatch: true,
                                    type: selectedInstrumentTab
                                }))
                                dispatch(toggleWatchlistModal(true))
                            }}>
                                <BiPlus /> {isMobile ? '' : 'Create new'}
                            </button>
                        </div>
                    </div>
                    <AccessComponent component={filteredWatchlist?.length > 0 ? <div style={{ marginTop: "1rem" }}>
                        <WatchTable />
                        {suggestedFunds?.length > 0 && <SimilarFunds showQVM heading={`Suggested ${defType.dname}`} funds={suggestedFunds} type={defType}
                            bodyStyle={{ padding: "0rem", marginTop: "1.25rem" }} />}
                    </div> : <div style={{ marginBottom: "2rem" }}>
                        <NoDataFound message={`Look like you have not created any ${selectedInstrumentTab === 1 ? "ETF" : selectedInstrumentTab === 2 ? "mutual fund" : "stock"} watchlist. Start by creating your first personalized watchlist.`} />
                    </div>
                    }
                        moduleKey={Number(selectedInstrumentTab) === 4 ? "MULTIPLE_WATCHLISTS" : Number(selectedInstrumentTab) ? "MULTIPLE_WATCHLISTS_ETFs" : Number(selectedInstrumentTab) ? "MULTIPLE_WATCHLISTS_MF" : ""
                        } upgradeComponent={< div style={{
                            background: "var(--grey4)", border: "1px solid var(--grey3)", borderRadius: "0.5rem", marginTop: "1.25rem"
                        }}>
                            <UpgradeToPro moduleKey={Number(selectedInstrumentTab) === 4 ? "MULTIPLE_WATCHLISTS" : Number(selectedInstrumentTab) ? "MULTIPLE_WATCHLISTS_ETFs" : Number(selectedInstrumentTab) ? "MULTIPLE_WATCHLISTS_MF" : ""}
                                customDesc={filteredWatchlist?.length === 0 ? "" : `All your previously created watchlists have been locked. To edit/update your watchlists, upgrade your plan.`} />
                        </div >} />
                </Card >
            </div >
        </React.Fragment >
    )
}