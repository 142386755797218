import { fetchFundData } from 'Libs/fetchFundData.js';
import moment from 'moment';
import apiHelper from 'Services/api/ApiHelper.js';
import apiHelperPY from 'Services/api/ApiHelperPY.js';
import { makeApiRequest, generateSymbol, parseFullSymbol } from './helpers.js';
import { subscribeOnStream, unsubscribeFromStream } from './streaming.js';

// DatafeedConfiguration implementation
const configurationData = {
    // Represents the resolutions for bars supported by your datafeed
    supported_resolutions: ['1', '3', '5', '15', '30', '45', '60', '120', '180', '240', '1D', '1W', '1M'],
    // supported_resolutions: ['1D', '1W', '1M'],
    // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
    exchanges: [
        { value: 'NSE', name: 'NSE', desc: 'National Stock Exchange' },
        // { value: 'Kraken', name: 'Kraken', desc: 'Kraken bitcoin exchange' },
    ],
    // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
    symbols_types: [
        { name: 'stocks', value: 'stocks' }
    ]
};

// Obtains all symbols for all exchanges supported by CryptoCompare API
async function getAllSymbols() {
    const data = await fetchFundData(4);
    return data.map(obj => {
        return {
            symbol: obj.symbol,
            full_name: obj.symbol,
            description: obj.proper_name,
            exchange: 'NSE',
            type: 'stock',
            gdf_symbol: obj.gdf_symbol
        };
    });
}
const lastBarsCache = new Map();
export default {
    onReady: (callback) => {
        console.log('[onReady]: Method call');
        setTimeout(() => callback(configurationData));
    },

    searchSymbols: async (
        userInput,
        exchange,
        symbolType,
        onResultReadyCallback
    ) => {
        console.log('[searchSymbols]: Method call');
        const symbols = await getAllSymbols();
        const newSymbols = symbols.filter(symbol => {
            const isExchangeValid = exchange === '' || symbol.exchange === exchange;
            const isFullSymbolContainsInput = symbol.full_name
                .toLowerCase()
                .indexOf(userInput.toLowerCase()) !== -1;
            const isFullSymbolDescriptionContainsInput = symbol.description
                .toLowerCase()
                .indexOf(userInput.toLowerCase()) !== -1;
            return isExchangeValid && (isFullSymbolContainsInput || isFullSymbolDescriptionContainsInput);
        });
        onResultReadyCallback(newSymbols);
    },

    resolveSymbol: async (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback,
        extension
    ) => {
        console.log('[resolveSymbol]: Method call', symbolName);
        const symbols = await getAllSymbols();
        console.log("SYMBOLSSsssssss", symbols)
        const symbolItem = symbols.find(({ full_name }) => full_name === symbolName);
        if (!symbolItem) {
            console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
            onResolveErrorCallback('Cannot resolve symbol');
            return;
        }
        // Symbol information object
        const symbolInfo = {
            ticker: symbolItem.full_name,
            name: symbolItem.symbol,
            description: symbolItem.description,
            type: symbolItem.type,
            session: '0915-1530',
            timezone: 'Asia/Kolkata',
            exchange: symbolItem.exchange,
            minmov: 1,
            pricescale: 100,
            has_daily: true,
            // has_seconds: true,
            has_intraday: true,
            // intraday_multipliers: ["1"],
            // has_ticks: true,
            has_weekly_and_monthly: false,
            supported_resolutions: configurationData.supported_resolutions,
            volume_precision: 2,
            data_status: 'streaming',
            gdf_symbol: symbolItem.gdf_symbol
        };
        console.log('[resolveSymbol]: Symbol resolved', symbolName);
        onSymbolResolvedCallback(symbolInfo);
    },

    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        let { from, to, firstDataRequest } = periodParams;
        // from = from
        // to = to
        console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
        if (resolution === '1D') {
            try {
                console.log('resolutin', resolution)
                let data = await apiHelperPY({ apiName: 'getStockPriceVolFull', data: { 'ticker': symbolInfo.ticker }, saveResponse: '' })
                data = Object.values(data)
                console.log('getStockPriceVolFull ', data)
                console.log('from ', from, 'to ', to)
                if (data.length === 0) {
                    // "noData" should be set if there is no data in the requested period
                    onHistoryCallback([], { noData: true });
                    return;
                }

                let bars = [];
                data.forEach((bar, i) => {
                    let timestamp = new Date(bar.Date).getTime() + 19800000 // adding utc + 5:30
                    let t = timestamp / 1000
                    if (t >= from && t < to) {
                        bars = [...bars, {
                            time: timestamp,
                            low: bar.Low,
                            high: bar.High,
                            open: bar.Open,
                            close: bar.Close,
                            volume: bar.Volume
                        }];
                    }
                });
                console.log(`[getBars]: returned`, bars);
                onHistoryCallback(bars, { noData: false });
            } catch (error) {
                console.log('[getBars]: Get error', error);
                onErrorCallback(error);
            }
        } else {
            try {
                let data = await apiHelperPY({
                    apiName: 'loadOneminutePriceBars',
                    data: { "symbols": [symbolInfo.ticker], "start_date": moment.unix(from + 19800).format('YYYY-MM-DD kk:mm'), "end_date": moment.unix(to + 19800).format('YYYY-MM-DD kk:mm') }
                })
                data = JSON.parse(data)

                if (data.length === 0) {
                    // "noData" should be set if there is no data in the requested period
                    onHistoryCallback([], { noData: true });
                    return;
                }

                let bars = [];
                // data = data.filter(d => d.PreOpen !== 'True')
                data[symbolInfo.ticker].forEach((bar, i) => {
                    let timestamp = new Date(bar.timestamp).getTime()
                    let t = timestamp / 1000
                    console.log(from, t, to)

                    if (t >= from + 19800 && t < to + 19800) {
                        bars = [...bars, {
                            time: timestamp,
                            low: bar.low,
                            high: bar.high,
                            open: bar.open,
                            close: bar.close,
                            volume: bar.volume
                        }];
                    }
                });
                console.log(`[getBars]: returned`, bars);
                onHistoryCallback(bars, { noData: false });
            } catch (error) {
                console.log('[getBars]: Get error', error);
                onErrorCallback(error);
            }
        }
    },

    subscribeBars: (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscriberUID,
        onResetCacheNeededCallback
    ) => {
        // console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
        // subscribeOnStream(
        //     symbolInfo,
        //     resolution,
        //     onRealtimeCallback,
        //     subscriberUID,
        //     onResetCacheNeededCallback,
        //     lastBarsCache.get(symbolInfo.full_name)
        // );
    },

    unsubscribeBars: (subscriberUID) => {
        // console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
        // unsubscribeFromStream(subscriberUID);
    },
};