import AccessControlledPage from 'Components/AccessControlledPage';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { ClosableHeader } from 'Components/ClosableHeader';
import InstrumentListTable from 'Components/InstrumentListTable';
import StyleBoxChart from 'Components/StyleBoxChart';
import Head from 'Layout/Head';
import { stock_PaidCols } from 'Libs/utils';
import React from "react";
import apiHelperPY, { decodeData } from 'Services/api/ApiHelperPY';

const StockStyleBoxes = (props) => {

    const fundColors = ['#555770', '#83BF6E', '#007AFF', '#FFD966', '#FF55BB', '#0062CC', '#AA55FF', '#FF9C55', '#1A1D1F', '#FF6A55']
    const [loading, setLoading] = React.useState(true);
    const [styleBoxesBackTest, setstyleBoxesBackTest] = React.useState([]);

    const isMobile = window.innerWidth < 760;

    React.useEffect(() => {
        const dum = async () => {
            setLoading(true)
            let res2 = await apiHelperPY({ apiName: 'getSharpelyStyleBoxBacktest' })
            res2 = decodeData(res2 || "");
            res2 = await JSON.parse(res2.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))
            setstyleBoxesBackTest(res2)
            setLoading(false)
        }
        dum();
    }, [])

    if (loading) {
        return <React.Fragment>
            <div className='loaderContainer' >
                <ActivityLoaderMintbox showQuote={true} />
            </div>
        </React.Fragment>
    } else {
        return (
            <React.Fragment>
                <Head title="Best Stock Stylebox Performance in India" description={'sharpely offers style boxes that primarily distinguish the characteristics of stocks. Based on stocks Value score, Momentum score and Quality score which provide investor with detailed Analysis.'} />
                <AccessControlledPage moduleKey={'STOCK_STYLEBOX'} />
                <div className='normalContainer'>
                    {/* <ClosableHeader heading={`StyleBox`} /> */}
                    <StyleBoxChart isStyleBox={true} fundColors={fundColors} defAmtSelect={100000} chartData={styleBoxesBackTest?.prices} />
                    <InstrumentListTable
                        showBubbleChartBtn
                        initialColumns={stock_PaidCols}
                        hideInvestBtn={true}
                        tableType={7}
                        paginationSize={isMobile ? 10 : 50}
                    />
                </div>

            </React.Fragment >
        );
    };
}

export default StockStyleBoxes;
