import { StickyScrollTabs } from "Components/CustomComponents/StickyScrollTabs";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import IndexOrSectorsTreeChart from "Components/IndexOrSectorsTreeChart";
import { NoDataFound } from "Components/NoDataFound";
import { CardHeader } from "Components/atoms/SmallComponents";
import { useGetAssetType } from "Libs/utils";
import { Select } from "antd";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import React from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

const IndexTechnicals = ({ index, indexDetails, indexConstituentsDetails }) => {
    console.log(indexDetails)
    const isMobile = window.innerWidth <= 760;
    const stockType = useGetAssetType(4);

    const TechnicalChart = ({ chart, chartDataType }) => {
        const leftRef = React.useRef();
        const rightRef = React.useRef();

        if (chart && (chart?.chart_data)?.length === 0) {
            return <NoDataFound message={chart?.no_data_text} />
        }
        else if (chart && chart?.chart_data) {
            const maxViewOfChart = 6 * 30 * 24 * 60 * 60 * 1000
            const oneDay = 24 * 60 * 60 * 1000
            let data = JSON.parse(chart?.chart_data)
            console.log(data)
            let xAxisKey = 'as_on_date';
            let yAxisKey = Object.keys(data?.[0] || {})?.filter(ele => ele !== xAxisKey)?.[0]
            yAxisKey = yAxisKey?.split('_');
            yAxisKey.pop();
            yAxisKey = yAxisKey?.join('_');

            const options = {
                chart: {
                    type: 'areaspline',
                    panning: true,
                    height: 350,
                    events: {
                        load: function () {
                            const chart = this
                            let { dataMax } = chart.xAxis[0].getExtremes()
                            chart.xAxis[0].setExtremes(dataMax - maxViewOfChart, dataMax);
                            const moveLeft = () => {
                                let { min, max, dataMin } = chart.xAxis[0].getExtremes()
                                if (min - (maxViewOfChart + oneDay) >= dataMin) {
                                    min -= (maxViewOfChart + oneDay)
                                    max -= (maxViewOfChart + oneDay)
                                }
                                else if (min - (maxViewOfChart + oneDay) < dataMin && min > dataMin) {
                                    min = 0;
                                    max = maxViewOfChart;
                                }
                                chart.xAxis[0].setExtremes(min, max)
                            }
                            const moveRight = () => {
                                let { min, max, dataMax } = chart.xAxis[0].getExtremes()
                                if (max + (maxViewOfChart + oneDay) <= dataMax) {
                                    min += (maxViewOfChart + oneDay)
                                    max += (maxViewOfChart + oneDay)
                                }
                                else if ((max + (maxViewOfChart + oneDay) > dataMax) && (max < dataMax)) {
                                    min = dataMax - maxViewOfChart
                                    max = dataMax
                                }
                                chart.xAxis[0].setExtremes(min, max)
                            }
                            leftRef.current = moveLeft;
                            rightRef.current = moveRight;
                        }
                    },
                    marginBottom: 65
                },
                title: {
                    text: ""
                },
                exporting: {
                    enabled: false
                },
                yAxis: {
                    visible: true,
                    tickAmount: 5,
                    title: {
                        text: ''
                    },
                    gridLineDashStyle: "longdash",
                    gridLineColor: "var(--grey1)",
                    scrollbar: {
                        enabled: true,
                        showFull: false
                    },
                    min: 0,
                    max: chartDataType === 'per' ? 100 : undefined
                },
                xAxis: {
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%H:%M:%S.%L',
                        second: '%H:%M:%S',
                        minute: '%H:%M',
                        hour: '%H:%M',
                        day: '%e %b',
                        week: '%e %b',
                        month: '%b %y',
                        year: '%Y'
                    },
                    crosshair: true,
                    min: 0,
                    max: maxViewOfChart,
                    lineColor: 'var(--grey1)',
                    tickColor: 'var(--grey1)'
                },
                tooltip: {
                    shared: true,
                    useHTML: true,
                    formatter: function () {

                        let d = moment(this.x).format('Do MMM YYYY') || this.x
                        if (chartDataType === 'per') {
                            return '<b>' + d + '</b><br/><span>' + `${chart.chart_name} : <b>` + DecimalValueFormat({
                                num: this.points[0].y, decimals: 2, suffix: "%"
                            }) + "</b>" + '</span>'
                        }
                        else return (
                            '<b>' + d + `</b><br/>${chart.chart_name} : <b>` + this.points[1].y + `</b><br/>Total stocks : <b>` + this.points[0].y + '</b>'
                        )
                    },
                },
                series: [
                    chartDataType !== 'per' && {
                        name: 'Total no. of stocks in ' + index?.IndexName,
                        data: data.map(item => {
                            if (chartDataType === 'per') return { 'x': new Date(item.as_on_date).getTime(), 'y': 100 };
                            else return { 'x': new Date(item.as_on_date).getTime(), 'y': item[`${yAxisKey}_total`] }
                        }),
                        color: '#007AFF',
                        // dataLabels: {
                        //     enabled: chartDataType !== 'per',
                        //     className: "sectrmomenchart",
                        //     style: { fontWeight: '400', textOutline: "none", color: "var(--dark4)" },
                        //     formatter: function () {
                        //         return this.y
                        //     }
                        // },
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                x2: 0,
                                y1: 0,
                                y2: 0
                            },
                        },
                    },
                    {
                        name: chartDataType === 'per' ? 'Percentage of ' + chart.chart_name : "Total " + chart.chart_name,
                        data: data.map(item => {
                            return { 'x': new Date(item.as_on_date).getTime(), 'y': item[`${yAxisKey}_${chartDataType}`] }
                        }),
                        color: '#83BF6E',
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                x2: 0,
                                y1: 0,
                                y2: 1
                            },
                            stops: [
                                [0, '#83BF6E77'],
                                [0.1, '#83BF6E10'],
                            ]
                        },
                    },
                ].filter(x => x),
                legend: {
                    enabled: true,
                },
                credits: {
                    enabled: false
                },
                boost: {
                    useGPUTranslations: true,
                },
                plotOptions: {
                    series: {
                        lineWidth: 1,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                    },
                    column: {
                        animation: false, // Disable animation
                    },
                }
            };
            console.log('abcd', options)
            return (
                <div>
                    <div className='d-flex align-items-center'>
                        <BiChevronLeft onClick={() => {
                            if (leftRef.current) {
                                leftRef.current();
                            }
                        }} style={{ margin: '-1rem', fontSize: "3rem", color: "var(--dark1)", cursor: "pointer" }} />
                        <div style={{ flex: 1 }}>
                            <HighchartsReact highcharts={Highcharts} options={options} />
                        </div>
                        <BiChevronRight onClick={() => {
                            if (rightRef.current) {
                                rightRef.current();
                            }
                        }} style={{ margin: '-1rem', fontSize: "3rem", color: "var(--dark1)", cursor: "pointer" }} />
                    </div>
                </div>
            )
        }
    }

    const TechnicalChildren = ({ data }) => {

        let options = [];
        let types = [{ key: "per", value: "per", label: "Percentage" }, { key: "num", value: "num", label: "Number" }];

        data?.charts?.forEach((ch) => {
            let el = {
                key: ch.chart_name,
                label: ch.chart_name,
                value: ch.chart_name,
                ...ch
            }
            options = [...options, el]
        })
        const [selectedChart, setSelectedChart] = React.useState(options?.[0]?.value);
        const [selectedType, setSelectedType] = React.useState(types?.[0]?.value);
        const chart = options.find(ch => ch.value === selectedChart)
        return (
            <div style={{ display: "flex", flexDirection: "column", gap: "0rem", }}>
                <div style={{ display: "flex", marginBottom: '1.25rem', alignItems: "center", gap: "1rem" }}>
                    {!isMobile && <div className="textLG w-700 dark3" style={{ flex: 1, cursor: 'pointer' }}>
                        {selectedChart}
                    </div>
                    }
                    <Select placeholder='Select chart type' value={selectedChart} options={options} onChange={(e) => setSelectedChart(e)}
                        style={{ width: window.innerWidth > 760 ? '20rem' : '100%' }} />
                    <Select placeholder='Select chart value' value={selectedType} options={types} onChange={(e) => setSelectedType(e)} />
                </div>
                <TechnicalChart chart={chart} chartDataType={selectedType} />
            </div>
        )
    }

    const tabData = [
        {
            key: "Absolute momentum",
            heading: "Absolute momentum",
            value: "AbsoluteMomentum",
            show: true,
            children: <TechnicalChildren data={indexDetails?.tech_data?.['Absolute momentum']} />,
            subHeading: `Here we look at standalone momentum of stocks in the index and report number and percentage of stocks meeting the criteria.`,
        },
        {
            key: "Relative momentum",
            heading: "Relative momentum",
            value: "RelativeMomentum",
            show: true,
            children: <TechnicalChildren data={indexDetails?.tech_data?.['Relative momentum']} />,
            subHeading: `Here we look at relative performance of stocks w.r.t ${index?.IndexName} Index and report number and percentage of stocks meeting the criteria.`,
        },
        {
            key: "Heatmap",
            heading: "Heatmap",
            value: "Heatmap",
            show: true,
            children: <IndexOrSectorsTreeChart allInstrumentListData={indexConstituentsDetails || []} type={stockType} applyFilters={false} removeFalsyValues={false}
                header={isMobile ? null : <CardHeader heading={'Heatmap'} headingType={2} textClassName='textXL w-700' />}
                subHeading={isMobile ? null : "This gives you a snapshot of stock returns over any timeframe. Returns are adjusted for corporate actions. Choose a time period from the dropdown"} showAdvDec />,
            noHeading: true,
            subHeading: "This gives you a snapshot of stock returns over any timeframe. Returns are adjusted for corporate actions. Choose a time period from the dropdown",
            noSubHeading: true
        },
    ]

    return (
        <React.Fragment>
            <StickyScrollTabs isAccordionInMob queriesToStickToBot={['#sharpelyHeader', '#customTabsBarId']} tabData={tabData} />
        </React.Fragment>
    )
}

export default IndexTechnicals