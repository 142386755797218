import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import { accessDataSlice, publisherProfiles, resetSlice } from './AppData/reduxSlice';
import { darkModeSlice } from './DarkMode/reduxSlice';
import { knowledgeBaseModal, metricDescModal, userGuideModal } from './KnowledgeBase/reduxSlice';
import { stockpricesSlice, subscribedStocksSlice } from './StockLivePrice/reduxSlice';
import { prevstockpricesSlice } from './StocksPrevPrices/reduxSlice';
import { basketCreateModal, globalInsSearch } from './Modals/reduxSlice';
import { helpAndSupp, investmentModal, loginModal, subscribeModal, userCustomCols, userDataSlice, userWatchlists, userPlanData, pricingModal, preferenceModal, userSignedIn, paySharpelyFeeModal, userAccessLevel, watchlistModal } from './UserAuth/reduxSlice';

const rootReducer = combineReducers({
  stockprices: stockpricesSlice.reducer,
  subscribedStocks: subscribedStocksSlice.reducer,
  prevStockPrices: prevstockpricesSlice.reducer,
  accessLevel: accessDataSlice.reducer,
  updateUserData: userDataSlice.reducer,
  toggleloginModal: loginModal.reducer,
  toggleknowledgeBaseModal: knowledgeBaseModal.reducer,
  toggleSubscribeModal: subscribeModal.reducer,
  investmentModal: investmentModal.reducer,
  paySharpelyFeeModal: paySharpelyFeeModal.reducer,
  helpAndSupp: helpAndSupp.reducer,
  pricingModal: pricingModal.reducer,
  preferenceModal: preferenceModal.reducer,
  userWatchlists: userWatchlists.reducer,
  watchlistModal: watchlistModal.reducer,
  changeTheme: darkModeSlice.reducer,
  reset: resetSlice.reducer,
  userCustomCols: userCustomCols.reducer,
  metricDescModal: metricDescModal.reducer,
  userGuideModal: userGuideModal.reducer,
  userPlanData: userPlanData.reducer,
  userSignedIn: userSignedIn.reducer,
  basketCreateModal: basketCreateModal.reducer,
  userAccessLevel: userAccessLevel.reducer,
  publisherProfiles: publisherProfiles.reducer,
  globalInsSearch: globalInsSearch.reducer,
})

let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';

const reduxStateSyncConfig = {
  whitelist: ['userData/updateUserData', 'accessLevel/updateAccessLevels', 'userWatchlists/updateWatchlists', 'watchlistModal/updateWatchlistModalParams',
    'watchlistModal/toggleWatchlistModal'],
};

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storage,
  blacklist: ['accessLevel', 'reset', 'metricDescModal', 'toggleloginModal', 'toggleknowledgeBaseModal', 'toggleSubscribeModal', 'helpAndSupp',
    'userGuideModal', 'pricingModal', 'basketCreateModal', 'paySharpelyFeeModal', 'userCustomCols', 'stockprices', 'prevStockPrices', 'subscribedStocks',
    'watchlistModal', 'changeTheme', 'globalInsSearch']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    if (isDev) {
      return getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(createStateSyncMiddleware(reduxStateSyncConfig))
    }
    else {
      return getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      })
    }
  }
});

if (isDev) {
  initMessageListener(store);
}

export const persistor = persistStore(store);

export default store