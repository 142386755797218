import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moengage from "@moengage/web-sdk";
import { getstarted, microphone } from 'Assets/images';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { CurrencyFormat } from 'Components/CurrencyFormat';
import { StickyScrollTabs } from 'Components/CustomComponents/StickyScrollTabs';
import CustomSegmentedTab from 'Components/CustomSegmentedTab';
import { addWeek, formatDateMMMDDYYYY } from 'Components/DateFormatter';
import { NoDataFound } from 'Components/NoDataFound';
import { BrokerIcon } from 'Components/SharpelyGateway/BrokerFunctions';
import SharpelyGateway2 from 'Components/SharpelyGateway/SharpelyGateway2';
import { PlanName } from 'Components/SubscribeModal';
import MyTable from 'Components/TableWithScroll';
import { CardHeader } from 'Components/atoms/SmallComponents';
import { useIsMobile } from 'CustomHooks/useIsMobile';
import { useUserDataHooks } from 'CustomHooks/useUserDataHooks';
import Head from 'Layout/Head';
import Logo from 'Layout/Logo';
import db from 'Libs/db';
import { toggleBasketCreateModal } from 'Libs/redux/Modals/reduxSlice';
import { toggleHelpAndSupp, updateUserData, updateUserPlanData } from "Libs/redux/UserAuth/reduxSlice";
import { defaultPagination, generateUniqueKey, makeid, preferenceQuestions } from 'Libs/utils';
import AdminServices from 'Services/api/AdminServices';
import apiHelper from 'Services/api/ApiHelper';
import apiHelperPY from 'Services/api/ApiHelperPY';
import { Dashboard_Portfolio } from 'Services/api/FabricatedAPIS';
import { Avatar, Card, Divider, Input, Modal, Select, Tabs, Tag, Upload } from 'antd';
import Meta from 'antd/es/card/Meta';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { BiCustomize, BiPlusCircle } from 'react-icons/bi';
import { BsCreditCardFill } from 'react-icons/bs';
import { FaUserAlt, FaUserSecret } from 'react-icons/fa';
import { IoAddCircle } from 'react-icons/io5';
import { MdOutlineAccountBalanceWallet } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

const Preferences = React.memo(({ user, dispatch }) => {

	let answers = { q1Answer: user?.Client?.[0]?.Q1Answer, q2Answer: user?.Client?.[0]?.Q2Answer, isNewsletterSubscribed: user?.Client?.[0]?.IsNewsletterSubscribed }

	const QuestionCard = ({ question = {}, questionKey, apiQuestionKey }) => {

		const [answer, setAnswer] = React.useState(user?.Client?.[0]?.[questionKey]);
		const [loading, setLoading] = React.useState(false);

		const updatePreference = async (value) => {
			try {
				setLoading(true);
				let data = {
					clientId: user?.id,
					...answers,
					[apiQuestionKey]: value
				}
				let res = await AdminServices.editAccount(data)?.then((res1) => {
					return res1;
				})
				if (res?.type === true) {
					let userData = { name: user?.name, email: user?.email, mobile: user?.mobile, isLogged: true, id: user?.id, login_Date: user?.login_Date }
					await Dashboard_Portfolio.getClientData(userData, true).then((res) => {
						if (res) {
							setLoading(false);
							toast.success('Account preference updated!');
							dispatch(updateUserData({ ...userData, ...res }))
						}
					})
				}
				else {
					setLoading(false);
					toast.error('Something went wrong!')
				}
			}
			catch (err) {
				setLoading(false);
				toast.error('Something went wrong!')
			}
		}

		React.useEffect(() => {
			if (answer !== user?.Client?.[0]?.[questionKey]) {
				setTimeout(() => {
					updatePreference(answer)
				}, 1000)
			}
		}, [answer])

		return (
			<div>
				<div className='textMD w-700 dark4 d-flex align-items-center' style={{ gap: "0.5rem" }}>
					{question?.heading}
					{loading && <> <LoadingOutlined spin /><span className='textXS dark4'>Updating...</span></>}
				</div>
				<div className='textXS w-500 dark4 mt-2 mb-3'>{question?.subText}</div>
				<Select options={question?.options} value={answer} style={{ width: "min(100%, 20rem)" }} onChange={(value) => {
					setAnswer(value);
					setLoading(true);
				}} />
			</div>
		)
	}

	return (
		<div>
			<div className='d-flex flex-column' style={{ gap: "1.25rem" }}>
				{preferenceQuestions?.map((question, i) => {
					return (
						<QuestionCard key={i} question={question} questionKey={question?.key?.[0]?.toUpperCase() + question?.key?.slice(1)} apiQuestionKey={question?.key} />
					)
				})}
			</div>
		</div>
	)
})

export default function Profile(props) {

	const user = useSelector((state) => state?.updateUserData?.value)
	const userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel;
	const userPlanData = useSelector((state) => state?.userPlanData?.value);
	const [updModal, setUpdModal] = useState(false);
	const [payments, setPayments] = useState([]);
	const [ppDetails, setPPDetails] = React.useState([]);
	const [trackMyDetail, setTrackMyDetail] = useState(0);
	const [disconnectBrokerLoading, setDisconnectBrokerLoading] = useState(false)
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [userScreens, setUserScreens] = React.useState([]);
	const [userModels, setUserModels] = React.useState([]);
	const [userStrategies, setUserStrategies] = React.useState([]);
	const [publishedScreens, setPublishedScreens] = React.useState([]);
	const [publishedModels, setPublishedModels] = React.useState([]);
	const [publishedStrategies, setPublishedStrategies] = React.useState([]);
	const [publisherPayouts, setPublisherPayouts] = React.useState([]);
	const [publisherProfileLoad, setPublisherProfileLoad] = React.useState(true);
	const { getUserStrategies, getUserStockModels } = useUserDataHooks();
	const isMobile = useIsMobile();
	let userActiveSubOrOneTime = userPlanData?.userActiveSubOrOneTime
	const loadData = async () => {
		try {
			let userData = { name: user?.name, email: user?.email, mobile: user?.mobile, isLogged: true, id: user?.id, login_Date: user?.login_Date }
			await Dashboard_Portfolio.getClientData(userData, true).then((res) => {
				if (res) {
					dispatch(updateUserData({ ...userData, ...res }))
				}
			})
			let allPublishers = await apiHelperPY({ apiName: "getAllPublisherProfile", saveResponse: "sessionStorage" });
			let userPPDetail = JSON.parse(allPublishers)?.filter((i) => i?.ClientId === user?.id)?.[0];
			setPPDetails(userPPDetail)
			if (!userPPDetail) {
				setPublisherProfileLoad(false);
			}
			let pays = await AdminServices.getPPTrans(user?.id, "id").then((res) => { return res });
			if (pays) {
				let temp = pays.data || [];
				temp = temp.sort((a, b) => new Date(b?.CreatedOn).getTime() - new Date(a?.CreatedOn).getTime()) || [];
				setPayments(temp)
			}
			let strategyRes = await getUserStrategies();
			if (strategyRes) {
				setUserStrategies(strategyRes?.userStrategies || []);
			}
			let signalRes = await getUserStockModels();
			if (signalRes) {
				setUserModels(signalRes?.userModels || []);
			}
			let res2 = await AdminServices.getAllSuperScreens(user.id).then((res) => res)
			if (res2?.type) {
				setUserScreens([
					...res2?.data?.filter((i) => i?.is_deleted === 0)?.map((i) => {
						return {
							...i,
							isSuper: true
						}
					})])
			}
			else {
				setUserScreens([]);
			}
			if (userPPDetail) {
				let earningsDetail = await apiHelper({ apiName: "getPublisherPayoutsById", data: { publisherId: userPPDetail?.UID } });
				console.log(earningsDetail);
				setPublisherPayouts(earningsDetail || []);
				let allPublishedScreens = await apiHelperPY({ apiName: "getAllPublishedScreens" });
				setPublishedScreens(JSON.parse(allPublishedScreens)?.filter((i) => i?.client_id === user?.id));
				let allPublishedModels = await apiHelperPY({ apiName: "getAllPublishedFactorModel" });
				setPublishedModels(JSON.parse(allPublishedModels)?.filter((i) => i?.client_id === user?.id));
				let getAllStrategiesPublished = await apiHelperPY({ apiName: "getAllStrategiesPublished" });
				setPublishedStrategies(JSON.parse(getAllStrategiesPublished)?.filter((i) => i?.config?.client_id === user?.id));
				setPublisherProfileLoad(false);
			}
		}
		catch (err) {
			console.log(err);
		}
	}

	const LabelInput = ({ label, value = '--', updMsg, showUpdMsg, showUpdateBtn }) => (
		<div style={{ borderBottom: "1px solid var(--grey1)", paddingBottom: "1.25rem" }}>
			<label className="textMD dark4 w-500" style={{ marginBottom: "0.5rem" }}>{label}</label>
			<div>
				<div>
					<div className='textSM w-500 dark3'>
						{value || 'Not connected yet'}
					</div>
					{showUpdMsg && <div>
						<div className='textSM dark3 mt-1'>{updMsg}</div>
					</div>}
				</div>
				{showUpdateBtn && <button className='btnWhite mt-2' onClick={() => setUpdModal(true)} style={{ float: "left" }}>Update</button>}
			</div>
		</div>
	)

	const UpdateDetailsModal = () => {

		const [updData, setUpdData] = useState('')
		const [updating, setUpdating] = useState(false);

		const closeModal = () => {
			setUpdModal(false);
			setUpdData('');
			setUpdating(false);
		}

		const handleValidation = () => {
			if (updData?.length !== 10) {
				toast.error('Enter valid phone number');
			}
			else return true;
		}

		const handleDetailUpdate = async () => {
			if (handleValidation()) {
				setUpdating(true);
				try {
					let data = {
						clientId: user?.Client[0]?.ClientId,
						clientName: user?.Client[0]?.DisplayName,
						mobileNumber: updData
					}
					let res = await AdminServices.editAccount(data).then((res1) => {
						console.log(res1);
						return res1;
					})
					if (res?.type === true) {
						let userData = { name: user?.name, email: user?.email, mobile: user?.mobile, isLogged: true, id: user?.id, login_Date: user?.login_Date }
						await Dashboard_Portfolio.getClientData(userData, true).then((res) => {
							if (res) {
								dispatch(updateUserData({ ...userData, ...res, mobile: res?.Client[0]?.MobileNumber }))
							}
						})
						toast.success('Phone number updated.')
						closeModal();
						setUpdating(false);
					}
					else {
						setUpdating(false);
						toast.error('Something went wrong! Please try again.')
					}
				}
				catch (err) {
					setUpdating(false);
					toast.error('Something went wrong! Please try again.')
				}
			}
		}

		return (
			<Modal open={updModal} footer={null} onCancel={() => closeModal(false)} onOk={() => closeModal(false)} closable={false} width="min(100%, 30rem)">
				<Logo type='dark' />
				<div className='textSM black w-500' style={{ marginTop: "2rem" }}>
					Add your phone number
				</div>
				<Input value={updData} onChange={(e) => setUpdData(e.target.value)} style={{ border: "1px solid var(--grey1)", marginTop: "1rem" }}
					placeholder='Enter phone number' type={'number'} />
				<div style={{ display: "flex", alignItems: "center", marginTop: "1rem", gap: "1.25rem", justifyContent: "flex-end", width: "100%" }}>
					<button className='btnWhite ' onClick={() => closeModal(false)}>Cancel</button>
					<button className='btnBlack ' onClick={handleDetailUpdate}>
						{updating ? <LoadingOutlined style={{ fontSize: '1rem', color: "var(--white)" }} spin /> : 'Update'}
					</button>
				</div>
			</Modal>
		)
	}

	const BasicDetails = () => {
		const [deleteConfirm, setDeleteConfirm] = React.useState(false);

		const deleteAccount = async () => {
			try {
				let res = await AdminServices.deactivateAccount(user?.id);
				if (res.type === true) {
					setDeleteConfirm(false);
					toast.success('Account deleted.');
					moengage.track_event('Account_deactivated');
					moengage.destroy_session();
					moengage.track_event('Logout');
					dispatch(updateUserData(undefined));
					db.clear();
				}
			}
			catch (err) {

			}
		}

		return (
			<div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "2rem" }}>
				<div style={{ flex: 1 }}>
					{user?.Client?.[0]?.SharpelyAccessLevel > 2 && <CardHeader
						heading={"Basic Details"}
					/>}
					<div className='profileDetailsGrid mt-4' style={{ display: "grid", gridTemplateColumns: "1fr", gap: "1.25rem" }} >
						<LabelInput label={'Name'} value={user?.Client?.[0]?.DisplayName} />
						<LabelInput label={'Email'} value={user?.Client?.[0]?.Email} />
						<LabelInput label={'Phone number'} value={user?.Client?.[0]?.MobileNumber} updMsg={'Add your phone number for an easy and secure login experience'}
							showUpdMsg={!user?.Client?.[0]?.MobileNumber} showUpdateBtn={!user?.Client?.[0]?.MobileNumber} />
					</div>
					<div className='d-flex justify-content-end' style={{ marginTop: "2rem" }}>
						<button className='btnRed' onClick={() => setDeleteConfirm(true)}>Delete Account</button>
					</div>
					<Modal open={deleteConfirm} onCancel={() => setDeleteConfirm(false)} onOk={() => setDeleteConfirm(false)} closable={false}
						footer={<div className='d-flex justify-content-between align-items-end' style={{ gap: "1rem" }}>
							<div className='textSM w-400 dark4'>Note: This action is irreversible.</div>
							<div className='d-flex justify-content-end flex-grow-1' style={{ gap: "1rem" }}>
								<button className='btnBlack' onClick={() => setDeleteConfirm(false)}>Cancel</button>
								<button className='btnRed' onClick={() => deleteAccount()}>Confirm</button>
							</div>
						</div>}>
						<div className='textMD w-500 dark4' style={{ marginBottom: "2rem" }}>Are you sure you want to delete your sharpely account?</div>
					</Modal>
				</div>
			</div>
		)
	}

	const PublisherProfile = ({ data = null }) => {

		const [PPDetailsModal, setPPDetailsModal] = React.useState(false)
		const [uploadingImg, setUploadingImg] = React.useState(false);
		const [PPDetails, setppDetails] = React.useState(data || {
			"ClientId": user?.id,
			"Name": user?.name,
			"IsSebiRegistered": 0,
			"SebiRegistrationType": '',
			"SebiRegistrationNo": '',
			"YrsofExperience": 0,
			"Description": '',
			"PublisherIcon": ""
			// 1 = success, -1 =  failed, 0 = in review , other => incomplete
		})

		const uploadButton = (
			<button style={{ border: 0, background: 'none', }} type="button">
				{uploadingImg ? <LoadingOutlined /> : <PlusOutlined />}
				<div style={{ marginTop: 8, }}>Upload</div>
			</button>
		)

		const handleFileInputChange = async (event) => {
			setUploadingImg(true);
			const file = event.file?.originFileObj;
			const data = new FormData();
			data.append('file', file);
			const fileName = `${user?.id}-publisher-${file?.name}`
			let res = await AdminServices.uploadImg({ fileName: fileName, data, folderName: 'publisher-profile' });
			if (res?.type === true && res?.data === 'success') {
				setppDetails({
					...PPDetails,
					PublisherIcon: `https://d3etfstcuyoos5.cloudfront.net/publisher-profile/${fileName}`
				})
			}
			setUploadingImg(false);
		};

		const checkIsConfirmDisabled = () => {
			let c1 = PPDetails?.Name?.length <= 0 || String(PPDetails?.Name).match(/[\/?'&"]/g);
			let c2 = PPDetails?.PublisherIcon?.length === 0;
			let c3 = PPDetails?.IsSebiRegistered === 0 ? false : Object.entries(PPDetails || {})?.filter(([key]) =>
				(['SebiRegistrationType', 'SebiRegistrationNo', 'YrsofExperience']?.includes(key)))?.filter(([_, val]) => val === 0 ? true : val)?.length < 3;
			let c4 = PPDetails?.Description?.length === 0 || String(PPDetails?.Description).match(/[\/?'&"]/g);
			if (c1 || c2 || c3 || c4) {
				return true;
			}
		}

		const CreateStrategyButton = () => {
			return (
				<Card key={makeid(3)} bordered style={{
					flex: 1, backgroundColor: "white",
				}} bodyStyle={{ height: "100%", alignItems: "center" }}
					onClick={() => {
						if (userStrategies?.length > 0) {
							navigate('/strategies');
						}
						else {
							dispatch(toggleBasketCreateModal({ open: true, type: {} }))
						}
					}} hoverable className='w100 h100'>
					<Meta style={{ height: "100%", alignItems: "center" }} avatar={<IoAddCircle size={"2.5rem"} color='#5d81b7' />}
						title={<div className='ms-1' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<div style={{ gap: "0.1rem", flex: 1 }}>
								<span className='textLG w-bold textEllipsis1'>
									{userStrategies?.length > 0 ? "Publish a strategy" : "Create a new strategy"}
								</span>
								<span className='textXS textLight w-500 textEllipsis3'>
									{userStrategies?.length > 0 ? "Publish your already created strategies or create a new strategy and publish." :
										"Create a fully automated or a manual strategy. Your strategy can be as simple as Stock/ETF baskets to a more complex stock strategy based on screens or factor models."}
								</span>
							</div>
						</div>} />
				</Card>
			)
		}

		const CreateScreenButton = () => {
			return (
				<Card key={makeid(3)} bordered style={{
					flex: 1, backgroundColor: "white",
				}} bodyStyle={{ height: "100%", alignItems: "center" }}
					onClick={() => {
						if (userScreens?.length > 0) {
							navigate('/screens')
						}
						else {
							navigate('/stocks/super-screener')
						}
					}} hoverable className='w100 h100'>
					<Meta style={{ height: "100%", alignItems: "center" }} avatar={<IoAddCircle size={"2.5rem"} color='#5d81b7' />}
						title={<div className='ms-1' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<div style={{ gap: "0.1rem", flex: 1 }}>
								<span className='textLG w-bold textEllipsis1'>
									{userScreens?.length > 0 ? "Publish a Stock screen" : "Create a new Stock screen"}
								</span>
								<span className='textXS textLight w-500 textEllipsis3'>
									{userScreens?.length > 0 ? "Publish your already created screens or create a new screen and publish." :
										"Create custom stock screens using over 1000 metrics, indicators and chart patterns and functions."}
								</span>
							</div>
						</div>} />
				</Card>
			)
		}

		const CreateFactorModelBtn = () => {

			return (
				<Card key={makeid(3)} bordered style={{
					flex: 1, backgroundColor: "white",
				}} bodyStyle={{ height: "100%", alignItems: "center" }}
					onClick={() => {
						if (userModels?.length > 0) {
							navigate('/factor-models')
						}
						else {
							navigate('/stocks/factor-model')
						}
					}} hoverable className='w100 h100'>
					<Meta style={{ height: "100%", alignItems: "center" }} avatar={<IoAddCircle size={"2.5rem"} color='#5d81b7' />}
						title={<div className='ms-1' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<div style={{ gap: "0.1rem", flex: 1 }}>
								<span className='textLG w-bold textEllipsis1'>
									{userModels?.length > 0 ? "Publish a Factor Model" : "Create a new Factor model"}
								</span>
								<span className='textXS textLight w-500 textEllipsis3'>
									{userModels?.length > 0 ? "Publish your already created factor models or create a new model and publish." :
										"Create custom factor models using over 1000 metrics including premium metrics. Fine tune your model by back testing"}
								</span>
							</div>
						</div>} />
				</Card>
			)
		}

		const Earnings = () => {

			let totalEarnings = publisherPayouts?.reduce((a, c) => a + Number(c?.Rate), 0);
			let totalStrategyEarnings = publisherPayouts?.filter((el) => el?.Type === 'STRATEGY')?.reduce((a, c) => a + Number(c?.Rate), 0);
			let totalScreenEarnings = publisherPayouts?.filter((el) => el?.Type === 'SCREEN')?.reduce((a, c) => a + Number(c?.Rate), 0);
			let totalFMEarnings = publisherPayouts?.filter((el) => el?.Type === 'FM')?.reduce((a, c) => a + Number(c?.Rate), 0);

			return (
				<div>
					<div className='mb-3'>
						<CardHeader heading={'Earnings'} headingType={3} />
					</div>
					<div className=''>
						<Row lg={4} md={3} sm={2} xs={1} className='gx-3 gx-3'>
							<Col>
								<Card bordered bodyStyle={{ padding: "1rem", width: "100%", height: "100%" }} className='w100 h100'>
									<Card.Meta
										title={<div className='textSM w-700'>Total Earnings</div>}
										description={<div className='textSM dark4 w-500 mt-1'>
											{CurrencyFormat(totalEarnings)}
										</div>} />
								</Card>
							</Col>
							<Col>
								<Card bordered bodyStyle={{ padding: "1rem", width: "100%", height: "100%" }} className='w100 h100'>
									<Card.Meta
										title={<div className='textSM w-700'>Strategies</div>}
										description={<div className='textSM dark4 w-500 mt-1'>
											{CurrencyFormat(totalStrategyEarnings)}
										</div>} />
								</Card>
							</Col>
							<Col>
								<Card bordered bodyStyle={{ padding: "1rem", width: "100%", height: "100%" }} className='w100 h100'>
									<Card.Meta
										title={<div className='textSM w-700'>Screens</div>}
										description={<div className='textSM dark4 w-500 mt-1'>
											{CurrencyFormat(totalScreenEarnings)}
										</div>} />
								</Card>
							</Col>
							<Col>
								<Card bordered bodyStyle={{ padding: "1rem", width: "100%", height: "100%" }} className='w100 h100'>
									<Card.Meta
										title={<div className='textSM w-700'>Factor Models</div>}
										description={<div className='textSM dark4 w-500 mt-1'>
											{CurrencyFormat(totalFMEarnings)}
										</div>} />
								</Card>
							</Col>
						</Row>
					</div>
				</div>
			)
		}

		return (
			<div style={{ flex: 1 }}>
				{publisherProfileLoad ? <div>
					<ActivityLoaderMintbox showQuote />
				</div> : ![1, 0, -1]?.includes(PPDetails?.IsApproved) ? <div style={{ maxWidth: isMobile ? "" : "50%" }}>
					<div className='d-flex justify-content-end align-items-center'>
						<CardHeader heading={"Complete your profile"} />
						{![1, 0, -1]?.includes(PPDetails?.IsApproved) && <Tag color={PPDetails?.IsApproved === 1 ? "green" : PPDetails?.IsApproved === -1 ? "red" : PPDetails?.IsApproved === 0 ? "orange" : "yellow"}>
							{PPDetails?.IsApproved === 1 ? "Verified" : PPDetails?.IsApproved === -1 ? "Verification failed" : PPDetails?.IsApproved === 0 ? "In review" : "Incomplete"}
						</Tag>}
					</div>
					<div>
						<p className='testSM w-500 mb-3 mt-4'>Complete your publisher profile to start publishing your strategies on sharpely and earn every time someone clones your strategy.</p>
						<button className='btnBlack' onClick={() => { setPPDetailsModal(true) }}>Complete Profile</button>
					</div>
				</div> : <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", padding: "1rem", border: "1px solid var(--grey3)", borderRadius: 4 }}>
					<div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", gap: "1rem" }}>
						<Avatar src={PPDetails?.PublisherIcon} style={{ height: "3rem", width: "3rem" }} />
						<div style={{ display: "flex", flexDirection: "column", gap: "0.2rem", flex: 1, alignSelf: "center", paddingBottom: PPDetails?.IsSebiRegistered ? "" : "0.5rem" }}>
							<span className='textLG w-bold' style={{ flex: 1 }}>{PPDetails?.Name}</span>
							{PPDetails?.IsSebiRegistered ? <>
								<span className='textXS textLight'>SEBI Registration No. {PPDetails?.SebiRegistrationNo} ({PPDetails?.SebiRegistrationType})</span>
								{PPDetails?.YrsofExperience ? <span className='textXS textLight'>{PPDetails?.YrsofExperience > 1 ? `${PPDetails?.YrsofExperience} years` : `${PPDetails?.YrsofExperience} year`} of experience</span> : null}
							</> : null}
						</div>
						<Tag color={PPDetails?.IsApproved === 1 ? "green" : PPDetails?.IsApproved === -1 ? "red" : PPDetails?.IsApproved === 0 ? "orange" : "yellow"} style={{ cursor: "pointer" }}>
							{PPDetails?.IsApproved === 1 ? "Active" : PPDetails?.IsApproved === -1 ? "Verification failed" : PPDetails?.IsApproved === 0 ? "Under review" : "Incomplete"}
						</Tag>
					</div>
					<span className='textESM mt-1 w-400 dark4'>
						{PPDetails?.Description}
					</span>
					{PPDetails?.IsApproved !== -1 && <div>
						{publisherPayouts?.length > 0 && <>
							<Divider style={{ margin: "1rem 0" }} />
							<Earnings />
						</>}
						<Divider style={{ margin: "1rem 0" }} />
						{publishedScreens?.length === 0 && publishedModels?.length === 0 && publishedStrategies?.length === 0 ? <div>
							<div className='textMD w-700 dark4 mb-3'>
								Create and publish your first strategy/screen/model to start earning
							</div>
							<Row lg={3} md={2} sm={1} xs={1}>
								<Col>
									<CreateStrategyButton />
								</Col>
								<Col>
									<CreateScreenButton />
								</Col>
								<Col>
									<CreateFactorModelBtn />
								</Col>
							</Row>
						</div> : <div>
							<div className='mb-3'>
								<CardHeader headingType={3} heading={'Published Content'} />
							</div>
							<Row lg={3} md={2} sm={1} xs={1}>
								<Col>
									<Card style={{ border: "1px solid var(--grey3)" }} className='w100 h100' bodyStyle={{ width: "100%", height: "100%", padding: "1rem", }}>
										<div className='w100 h100 d-flex flex-column justify-content-between' style={{ gap: "1.5rem" }}>
											<div>
												<div className='textMD w-500 dark4'>Strategies</div>
												{publishedStrategies?.length > 0 ? <div className='mt-2 textXS dark3 w-500'>
													You have published {publishedStrategies?.length} strategies.
												</div> : <div className='mt-2 textXS dark3 w-500'>
													You haven't published any strategies yet.
													<div className='mt-1'>Create and publish your first strategy today to start earning.</div>
												</div>}
											</div>
											<div>
												{publishedStrategies?.length > 0 ? <button className='btnWhite' onClick={() => {
													navigate(`/publisher-profile?name=${PPDetails?.Name?.replaceAll(" ", "-")}&id=${PPDetails?.UID}#publishedStrategies`)
												}}>
													All Published Strategies
												</button> : <button className='btnWhite d-flex align-items-center' style={{ gap: 4 }}
													onClick={() => {
														dispatch(toggleBasketCreateModal({ open: true, type: {} }))
													}}>
													<BiPlusCircle /> Create new strategy
												</button>}
											</div>
										</div>
									</Card>
								</Col>
								<Col>
									<Card style={{ border: "1px solid var(--grey3)" }} className='w100 h100' bodyStyle={{ width: "100%", height: "100%", padding: "1rem", }}>
										<div className='w100 h100 d-flex flex-column justify-content-between' style={{ gap: "1.5rem" }}>
											<div>
												<div className='textMD w-500 dark4'>Screens</div>
												{publishedScreens?.length > 0 ? <div className='mt-2 textXS dark3 w-500'>
													You have published {publishedScreens?.length} Screens.
												</div> : <div className='mt-2 textXS dark3 w-500'>
													You haven't published any screens yet.
													<div className='mt-1'>Create and publish your first screen today to start earning.</div>
												</div>}
											</div>
											<div>
												{publishedScreens?.length > 0 ? <button className='btnWhite' onClick={() => {
													navigate(`/publisher-profile?name=${PPDetails?.Name?.replaceAll(" ", "-")}&id=${PPDetails?.UID}#publishedScreens`)
												}}>
													All Published Screens
												</button> : <button className='btnWhite d-flex align-items-center' style={{ gap: 4 }}
													onClick={() => {
														navigate('/stocks/super-screener')
													}}>
													<BiPlusCircle /> Create new screen
												</button>}
											</div>
										</div>
									</Card>
								</Col>
								<Col>
									<Card style={{ border: "1px solid var(--grey3)" }} className='w100 h100' bodyStyle={{ width: "100%", height: "100%", padding: "1rem", }}>
										<div className='w100 h100 d-flex flex-column justify-content-between' style={{ gap: "1.5rem" }}>
											<div>
												<div className='textMD w-500 dark4'>Factor Models</div>
												{publishedModels?.length > 0 ? <div className='mt-2 textXS dark3 w-500'>
													You have published {publishedStrategies?.length} factor models.
												</div> : <div className='mt-2 textXS dark3 w-500'>
													You haven't published any factor models yet.
													<div className='mt-1'>Create and publish your first model today to start earning.</div>
												</div>}
											</div>
											<div>
												{publishedModels?.length > 0 ? <button className='btnWhite' onClick={() => {
													navigate(`/publisher-profile?name=${PPDetails?.Name?.replaceAll(" ", "-")}&id=${PPDetails?.UID}#publishedFactorModels`)
												}}>
													All Published Models
												</button> : <button className='btnWhite d-flex align-items-center' style={{ gap: 4 }}
													onClick={() => {
														navigate('/stocks/factor-model')
													}}>
													<BiPlusCircle /> Create new model
												</button>}
											</div>
										</div>
									</Card>
								</Col>
							</Row>
						</div>}
					</div>}
					{PPDetails?.IsApproved === 0 && <div className='textXS dark3 mt-2'>
						Your publisher profile is under review. Our team may get in touch with you to verify your credentials. If everything is in place, your profile should be activated within 24-48 hours.
					</div>}
				</div>}
				{PPDetailsModal && <Modal title="Enter your details" onCancel={() => { setPPDetailsModal(false) }} open={PPDetailsModal} footer={false}>
					<form style={{ marginTop: "2rem", marginBottom: "0rem" }} onSubmit={async (e) => {
						e.preventDefault()
						console.log(PPDetails)
						if (!checkIsConfirmDisabled()) {
							var res = await AdminServices.createPublisherProfile(PPDetails).then((r) => r)
							if (res?.type) {
								if (res?.data?.length > 0) {
									toast.success("Profile updated successfully")
									setPPDetailsModal(false)
									let allPublishers = await apiHelperPY({ apiName: "getAllPublisherProfile", saveResponse: "sessionStorage", getFresh: true });
									setPPDetails(JSON.parse(allPublishers)?.filter((i) => i?.ClientId === user?.id)?.[0])
								} else {
									toast.error("Something went wrong")
									setPPDetailsModal(false)
								}
							} else {
								toast.error("Something went wrong")
								setPPDetailsModal(false)
							}
						}
					}}>
						<div style={{ marginBottom: "1.5rem" }}>
							<label className="dark3 textXS w-500 mb-2">Profile Picture</label>
							<Upload name="avatar" listType="picture-card" className="avatar-uploader" showUploadList={false} onChange={handleFileInputChange} style={{ height: "5rem" }}
								accept='image/png, image/jpeg'>
								{PPDetails?.PublisherIcon ? (
									<div style={{ maxHeight: "100%", overflow: "hidden" }}>
										<img loading="lazy" src={PPDetails?.PublisherIcon} alt="avatar" style={{ width: '5rem', height: "5rem", objectFit: "fill" }} />
									</div>
								) : (
									uploadButton
								)}
							</Upload>
						</div>
						<div style={{ marginBottom: "1.5rem" }}>
							<label className="dark3 textXS w-500 mb-1">
								Display Name
							</label>
							<input name="name" placeholder="Enter your Name" value={PPDetails?.Name} onChange={(e) => {
								let v = e.target.value;
								const nameRegex = /[^a-zA-Z\s]/g;
								v = v.replace(nameRegex, '')
								if (v?.length < 50) {
									setppDetails({
										...PPDetails,
										Name: v
									})
								}
							}} className="textSM antdInput"
								style={{ background: "var(--grey5)", border: "1px solid var(--grey2)", height: "40px", }} type="text" autoComplete="name" autoFocus={true} />
						</div>
						<div style={{ marginBottom: "1.5rem" }}>
							<label className="dark3 textXS w-500 mb-1 me-4">
								Are you SEBI registered?
							</label>
							<Select
								value={PPDetails?.IsSebiRegistered}
								style={{
									width: 70,
								}}
								onChange={(e) => {
									if (e === 1) {
										setppDetails({
											...PPDetails,
											IsSebiRegistered: e
										})
									}
									else {
										setppDetails({
											...PPDetails,
											IsSebiRegistered: e,
											"SebiRegistrationType": '',
											"SebiRegistrationNo": '',
											"YrsofExperience": 0,
										})
									}
								}}
								options={[
									{
										value: 1,
										label: 'Yes',
									},
									{
										value: 0,
										label: 'No',
									}
								]}
							/>
						</div>
						{PPDetails?.IsSebiRegistered === 1 && <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "1rem" }}>
							<div style={{ marginBottom: "1.5rem", display: "flex", flexDirection: "column" }}>
								<label className="dark3 textXS w-500 mb-1">
									SEBI Registeration Type
								</label>
								<Select
									value={PPDetails?.SebiRegistrationType}
									// style={{
									//   width: 70,
									// }}
									placeholder='SEBI Registration Type'
									onChange={(e) => {
										setppDetails({
											...PPDetails,
											SebiRegistrationType: e
										})
									}}
									options={[
										{
											value: 'RIA',
											label: 'RIA',
										},
										{
											value: 'RA',
											label: 'RA',
										},
										{
											value: 'PMS',
											label: 'PMS',
										}
									]}
								/>
							</div>

							<div style={{ marginBottom: "1.5rem", flexDirection: "column" }}>
								<label className="dark3 textXS w-500 mb-1">
									SEBI Registeration No.
								</label>
								<input name="sebinum" placeholder="SEBI Registeration No." value={PPDetails?.SebiRegistrationNo} onChange={(e) => {
									let v = e.target.value;
									setppDetails({
										...PPDetails,
										SebiRegistrationNo: v
									})
								}} className="textSM antdInput"
									style={{ background: "var(--grey5)", border: "1px solid var(--grey2)", height: "30px", }} type="text" />
							</div>

							<div style={{ marginBottom: "1.5rem", flexDirection: "column" }}>
								<label className="dark3 textXS w-500 mb-1">
									Years of experience
								</label>
								<input name="sebinum" placeholder="Years of experience" value={PPDetails?.YrsofExperience} type="number" onChange={(e) => {
									let v = e.target.value;
									setppDetails({
										...PPDetails,
										YrsofExperience: Number(v)
									})
								}} className="textSM antdInput"
									style={{ background: "var(--grey5)", border: "1px solid var(--grey2)", height: "30px", }} />
							</div>
						</div>}
						<span className='d-flex flex-column gap-1'>
							<span className='textXS w-500 dark3'>More about you</span>
							<TextArea size='middle' status={String(PPDetails?.Description).match(/[\/?'&"]/g) ? "error" : ""} rows={4} value={PPDetails?.Description} onChange={(e) => {
								setppDetails({
									...PPDetails,
									Description: e.target.value
								})
							}} placeholder='Enter description' />
							{String(PPDetails?.Description).match(/[\/?'&"]/g) ? <label style={{ color: "var(--red)", textAlign: "left" }} className='textXS w-500'>
								* Special characters are not allowed
							</label> : null}
						</span>
						<button disabled={checkIsConfirmDisabled()} style={{ width: "100%" }} type="submit" className={" btnBlack textMD  mt-3"}>
							Confirm
						</button>
					</form>
				</Modal>}
			</div>
		)
	}

	const Myplan = () => {

		const [selectedTab, setSelectedTab] = React.useState(0);

		const trackEventAndUpdUserData = async () => {
			moengage.track_event('CANCEL_SUBSCRIPTION', {
				"subscriptionId": userPlanData?.currentSubscription?.SubscriptionId,
				"userId": user?.id,
				"planName": userPlanData?.currentSubscription?.SubscriptionName,
				"frequency": userPlanData?.currentSubscription?.Frequency,
				"isPlanChanged": false
			})
			let userData = { name: user?.name, email: user?.email, mobile: user?.mobile, isLogged: true, id: user?.id, login_Date: user?.login_Date }
			await Dashboard_Portfolio.getClientData(userData, true).then((res) => {
				if (res) {
					dispatch(updateUserData({ ...userData, ...res, mobile: res?.Client[0]?.MobileNumber }))
				}
			});
		}

		const cancelSubscription = async () => {
			try {
				if (userPlanData?.currentSubscription?.PGType === 'PhonePe') {
					let data = {
						clientId: user?.id,
						subscriptionId: userPlanData?.currentSubscription?.SubscriptionId
					}
					let res = await AdminServices.cancelPPSubscription(data);
					if (res?.type === true) {
						toast.error('Subscription cancelled.')
						dispatch(updateUserPlanData(undefined));
						await AdminServices.getPPAuthReqStatus(userPlanData?.currentSubscription?.AuthId).then((res) => { return res });
						await trackEventAndUpdUserData();
					}
					else {
						toast.error('Something went wrong.')
					}
				}
				else if (userPlanData?.currentSubscription?.PGType === 'RazorPay') {
					let res = await apiHelper({ apiName: "cancelRazorSubscription", data: { id: userPlanData?.currentSubscription?.SubscriptionId } });
					if (res) {
						toast.error('Subscription cancelled.')
						dispatch(updateUserPlanData(undefined));
						await trackEventAndUpdUserData();
					}
					else {
						toast.error('Something went wrong.')
					}
				}
			}
			catch (err) {
				console.log(err);
			}
		}

		const CurrentPlan = () => {
			return (
				<React.Fragment>
					{userPlanData === undefined ? <ActivityLoaderMintbox />
						: (userAccesslevel === 1) ?
							<div style={{ background: "", width: "100%" }}>
								<div style={{ display: "flex", gap: "1.25rem" }} className='flex-sm-row flex-column'>
									<div style={{ width: "min(100%, 10rem)", flexShrink: 0 }} className='w100-sm'>
										<img loading="lazy" src={getstarted} style={{ width: "100%" }} />
									</div>
									<div>
										<div className='textMD w-500'>Unlock the full power of sharpely</div>
										<div className='textSM dark4 mt-2'>
											Upgrade your plan to any paid plan and explore our powerful suite of tools and gain access to premium features. With sharpely, you can leverage our intuitive screener to find the best trading opportunities and make informed decisions. Discover advanced analytics, real-time market data, and personalized insights tailored to your investment goals. Don't miss out on this opportunity to enhance your trading experience. Subscribe to sharpely now and unlock a world of possibilities!
										</div>
										<Link to='/pricing'>
											<button className='btnBlack mt-2'>Explore all plans</button>
										</Link>
									</div>
								</div>
							</div> : userPlanData?.hasEarlyAccess ? <Card style={{ background: "", width: "min(100%, 25rem)", border: "1px solid var(--grey1)" }} bodyStyle={{ width: "100%" }}>
								<div className=''>
									<div className='w-700 textLG'>
										sharpely <PlanName accessLevel={userAccesslevel} />
									</div>
									<div className='textSM dark4 mt-2'>
										Next due date: <b>{formatDateMMMDDYYYY(new Date(userPlanData?.nextDueDate))}</b>
									</div>
									<div className='mt-3 textXS dark4'>
										Reach out to us for any queries. <Link to='/contact-us'>Contact us.</Link>
									</div>
								</div>
							</Card> : <Card style={{ background: "", width: "min(100%, 25rem)", border: "1px solid var(--grey1)" }} bodyStyle={{ width: "100%" }}>
								<div className=''>
									<div className='w-700 textLG'>
										sharpely {userActiveSubOrOneTime?.planName} {!userActiveSubOrOneTime?.isRecurring && '- One time'}
									</div>
									<div className='textSM dark4 mt-2'>
										Date of purchase: {formatDateMMMDDYYYY(new Date(userActiveSubOrOneTime?.startDate))}
									</div>
								</div>
								<div style={{ marginTop: "1.25rem" }}>
									<div className='d-flex' style={{ borderBottom: "1px solid var(--grey1)", padding: "0.75rem" }}>
										<div className='w50 textSM dark4'>Status:</div>
										<div className='w50 textSM w-500 black' style={{ textTransform: "capitalize" }}>Active</div>
									</div>
									<div className='d-flex' style={{ borderBottom: "1px solid var(--grey1)", padding: "0.75rem" }}>
										<div className='w50 textSM dark4'>Auto renewal:</div>
										<div className='w50 textSM w-500 black' style={{ textTransform: "capitalize" }}>{userActiveSubOrOneTime?.isRecurring ? "Yes" : "No"}</div>
									</div>
									{userActiveSubOrOneTime?.isRecurring && <div className='d-flex' style={{ borderBottom: "1px solid var(--grey1)", padding: "0.75rem" }}>
										<div className='w50 textSM dark4'>Frequency:</div>
										<div className='w50 textSM w-500 black' style={{ textTransform: "capitalize" }}>
											{userActiveSubOrOneTime?.frequency}</div>
									</div>}
									<div className='d-flex' style={{ borderBottom: "1px solid var(--grey1)", padding: "0.75rem" }}>
										<div className='w50 textSM dark4'>
											{userActiveSubOrOneTime?.isRecurring ? 'Next due on:' : "Expires on:"}
										</div>
										<div className='w50 textSM w-500 black' >
											{moment(new Date(userActiveSubOrOneTime?.nextDueDate)).format('DD MMM, YYYY')}
										</div>
									</div>
									<div className='d-flex' style={{ borderBottom: "1px solid var(--grey1)", padding: "0.75rem" }}>
										<div className='w50 textSM dark4'>
											Amount:
											{userActiveSubOrOneTime?.isRecurring && <div className='textXS dark3'>
												(Billed {userPlanData?.currentSubscription?.Frequency === 'monthly' ? "Monthly" : "Yearly"})
											</div>}
										</div>
										<div className='w50 textSM w-500 black' >
											{CurrencyFormat(userActiveSubOrOneTime?.amount, 0)}</div>
									</div>
								</div>
								<div style={{ marginTop: "2rem", gap: "1rem" }} className='d-flex align-items-center'>
									<div className='w100'>
										<Link to='/pricing'>
											<button className='btnBlack w100'>Explore other plans</button>
										</Link>
									</div>
									{userActiveSubOrOneTime?.isRecurring && <button className='btnWhite w100' onClick={() => { cancelSubscription() }}>Cancel</button>}
								</div>
							</Card>
					}
				</React.Fragment>
			)
		}

		const ViewHistory = () => {

			const Subscriptions = () => {

				const columns = [
					{
						key: "SubscriptionName",
						dataIndex: "SubscriptionName",
						title: "Plan",
						width: 120,
					},
					{
						key: "Amount",
						dataIndex: "Amount",
						title: "Amount",
						render: (text) => {
							return CurrencyFormat(text / 100)
						},
						width: 120,
						align: "right"
					},
					{
						key: "SubscriptionId",
						dataIndex: "SubscriptionId",
						title: "Subscription Id",
						align: "right",
						width: 120,
					},
					{
						key: "Frequency",
						dataIndex: "Frequency",
						title: "Frequency",
						render: (text) => {
							return <span style={{ textTransform: "capitalize" }}>{text}</span>
						},
						width: 120,
						align: "right"
					},
					{
						key: "CreatedOn",
						dataIndex: "CreatedOn",
						title: "Date",
						render: (text) => {
							return formatDateMMMDDYYYY(text)
						},
						align: "right",
						width: 120,
					},
					{
						key: "SubscriptionState",
						dataIndex: "SubscriptionState",
						title: "Status",
						render: (text) => {
							return <Tag color={(['ACCEPTED', 'COMPLETED', 'SUCCESS', 'SUCCESSFUL', 'ACTIVE'])?.includes(text) ? "var(--green)" : (['FAILED', 'CANCELLED'])?.includes(text) ? "var(--red)" :
								"var(--primary)"}>{text}</Tag>
						},
						align: "right",
						width: 120,
					}
				]

				return (
					<div className='margin20Top'>
						{userPlanData?.allSubs?.length > 0 ?
							<MyTable dataSource={userPlanData?.allSubs} columns={columns} pagination={defaultPagination} scroll={{ x: "max-content" }}
								rowKey={(record) => generateUniqueKey(record?.MerchantTransactionId)} /> : <NoDataFound message='You have not created any subscription yet!' />
						}
					</div>
				)
			}

			const OnetimePayments = () => {

				const columns = [
					{
						key: "Plan",
						dataIndex: "Plan",
						title: "Plan",
						width: 120,
					},
					{
						key: "Amount",
						dataIndex: "Amount",
						title: "Amount",
						render: (text) => {
							return CurrencyFormat(text / 100)
						},
						width: 120,
					},
					{
						key: "MerchantTransactionId",
						dataIndex: "MerchantTransactionId",
						title: "Transaction Id",
						align: "right",
						width: 120,
					},
					{
						key: "CreatedOn",
						dataIndex: "CreatedOn",
						title: "Date",
						render: (text) => {
							return formatDateMMMDDYYYY(text)
						},
						align: "right",
						width: 120,
					},
					{
						key: "State",
						dataIndex: "State",
						title: "Status",
						render: (text) => {
							return <Tag color={(['ACCEPTED', 'COMPLETED', 'SUCCESS', 'SUCCESSFUL'])?.includes(text) ? "var(--green)" : (['FAILED', 'CANCELLED'])?.includes(text) ? "var(--red)" :
								"var(--primary)"}>{text}</Tag>
						},
						align: "right",
						width: 120,
					}
				]

				return (
					<div className='margin20Top'>
						{payments?.length > 0 ? <MyTable dataSource={payments} columns={columns} pagination={defaultPagination} scroll={{ x: "max-content" }}
							rowKey={(record) => generateUniqueKey(record?.MerchantTransactionId)} /> : <NoDataFound message='You have not made any onetime payment yet!' />}
					</div>
				)
			}

			const tabs = [
				{ key: 'Subscriptions', label: "Subscriptions", value: "Subscriptions", children: <Subscriptions /> },
				{ key: 'onetpayments', label: "Onetime Payments", value: "onetpayments", children: <OnetimePayments /> },
			]

			const [selectedTab, setSelectedTab] = React.useState('Subscriptions');

			return (
				<React.Fragment>
					<Tabs size={'medium'} activeKey={selectedTab} items={tabs} onChange={(e) => setSelectedTab(e)} />
				</React.Fragment>
			)
		}

		const tabData = [
			{ key: 0, label: "Current Plan", value: 0, children: <CurrentPlan /> },
			{ key: 1, label: "View History", value: 1, children: <ViewHistory /> }
		]

		return (
			<React.Fragment>
				<CustomSegmentedTab options={tabData} value={selectedTab} onChange={(e) => setSelectedTab(e)} isBlock={false} />
				<div className='margin20Top'>
					{tabData?.find((el) => el?.value === selectedTab)?.children}
				</div>
			</React.Fragment>
		)
	}

	const MyAlerts = () => {
		return (
			<Card style={{ width: "100%", background: "transparent" }}>
				<div className='d-flex flex-column align-items-center text-center' style={{ gap: "2rem" }}>
					<img loading="lazy" src={microphone} style={{ width: "min(100%, 15rem)" }} />
					<div className='textSM w-500 black'>
						We will soon be adding support for setting alerts.
					</div>
				</div>
			</Card>
		)
	}

	const ConnectedAccounts = () => {
		return (
			<React.Fragment>
				<div className='margin20Bottom' style={{ paddingBottom: "1.25rem", borderBottom: "1px solid var(--grey1)" }}>
					<div style={{ display: "grid", gridTemplateColumns: "1fr 3fr 1fr", gap: "1.25rem" }}>
						<div className='textMD dark4 w-500'>Broker account</div>
						<div>
							{user?.Client?.[0]?.Broker !== 'disconnected' && user?.Client?.[0]?.Broker !== '' ? <div className='d-flex align-items-center' style={{ gap: "0.75rem" }}>
								<BrokerIcon brokerName={user?.Client?.[0]?.Broker} height='2rem' width='2rem' />
								<div className='textMD w-500 black' style={{ textTransform: "capitalize" }}>
									{user?.Client?.[0]?.Broker}
								</div>
							</div>
								: <div>
									<div className='textMD w-500 black' >No account connected yet.</div>
									<div className='textSM dark3 mt-1' style={{ maxWidth: "50ch" }}>
										To invest and transact in stocks, MFs and ETFs, you need to have a demat account with one of our partners.
									</div>
								</div>
							}
						</div>
						{
							user?.Client?.[0]?.Broker !== 'disconnected' && user?.Client?.[0]?.Broker !== '' ?
								<button className={"btnWhite w100"} style={{ height: "fit-content" }}
									onClick={async () => {
										if (user.id <= 7) {
											setDisconnectBrokerLoading(true)
											let data = {
												clientId: user?.Client[0]?.ClientId,
												broker: 'disconnected'
											}
											await AdminServices.editAccount(data).then(async (res1) => {
												if (res1?.type === true) {
													let userData = { name: user?.name, email: user?.email, mobile: user?.mobile, isLogged: true, id: user?.id, login_Date: user?.login_Date }
													await Dashboard_Portfolio.getClientData(userData, true).then((res) => {
														if (res) {
															dispatch(updateUserData({ ...userData, ...res }))
															setDisconnectBrokerLoading(false)
														} else {
															toast.error("Something went wrong!")
															setDisconnectBrokerLoading(false)
														}
													})
												} else {
													toast.error("Something went wrong!")
													setDisconnectBrokerLoading(false)
												}
											})
										} else {
											toast('Please write to our support team to disconnect your broker.', { duration: 3000 })
											dispatch(toggleHelpAndSupp(true))
										}
									}}>
									{disconnectBrokerLoading ? <LoadingOutlined style={{ fontSize: '1.5rem' }} spin /> : 'Disconnect broker'}
								</button>
								:
								// <button className={"btnWhite w100"} style={{ height: "fit-content" }} disabled>
								//   Connect Broker
								// </button>
								<SharpelyGateway2 connectBroker={true} />
						}

					</div>
				</div>
				<div >
					<div style={{ display: "grid", gridTemplateColumns: "1fr 3fr 1fr", gap: "1.25rem" }}>
						<div className='textMD dark4 w-500'>Mutual Funds</div>
						<div>
							{/* {user?.Fatcas?.[0]?.PAN?.length > 0 ?
                  < div className='textMD w-500 black' style={{ textTransform: "uppercase" }}>{user?.Fatcas?.[0]?.PAN}</div>
                  : <div> */}
							{/* <div className='textMD w-500 black' >Mjutual Funds not connected</div> */}
							<div className='textSM dark3 mt-1' style={{ maxWidth: "50ch" }}>
								Mutual Fund investments are currently not available. Pretty soon, you will be able to buy direct plans of mutual funds through sharpely.
							</div>
							{/* </div> */}
							{/* } */}
						</div>
						<button className={"btnWhite w100"} disabled>
							Connect MF Account
						</button>
					</div>
				</div>
			</React.Fragment >
		)
	}

	const sideTabs = [
		{
			key: "basicdetails", value: "basicdetails", label: "My Details", children: <BasicDetails />, show: true, icon: <FaUserAlt />,
			labelOnClick: () => {
				moengage.track_event("My_details", {
					"user_email": user?.email
				})
			}
		},
		{ key: "publisherprofile", value: "publisherprofile", label: "Publisher Profile", children: <PublisherProfile data={ppDetails} />, show: true, icon: <FaUserSecret />, },
		// { key: "myCredits", value: "myCredits", label: "My Credits", children: <MyCredits />, show: userAccesslevel > 1, icon: <MdCreditScore /> },
		{ key: "preferences", value: "preferences", label: "Preferences", children: <Preferences user={user} dispatch={dispatch} />, show: true, icon: <BiCustomize /> },
		{
			key: "myplan", value: "myplan", label: "My Plan", children: <Myplan />, show: true, icon: <BsCreditCardFill />,
			labelOnClick: () => {
				moengage.track_event("Checked_subscriptions", {
					"user_email": user?.email
				})
			}
		},
		// { key: "Myalerts", value: "Myalerts", label: "My Alerts", children: <MyAlerts />, show: true, icon: <FaBell /> },
		{
			key: "ConnectedAccounts", value: "ConnectedAccounts", label: "Connected Accounts", children: <ConnectedAccounts />, show: true, icon: <MdOutlineAccountBalanceWallet />,
			labelOnClick: () => {
				moengage.track_event("Clicked_connected_accounts", {
					"user_email": user?.email
				})
			}
		},
	]

	React.useEffect(() => {
		loadData();
		if (trackMyDetail === 0) {
			moengage.track_event("My_details", {
				"user_email": user?.email
			})
			setTrackMyDetail(1);
		}
	}, [])

	return (
		<React.Fragment>
			<Head title='Profile' />
			<StickyScrollTabs isAccordionInMob queriesToStickToBot={['#sharpelyHeader']} tabData={sideTabs} isSingleChildInDesk contentBeforeTabs={<div style={{
				width: "100%", gap: "0.75rem",
				display: "flex", alignItems: "center", justifyContent: "flex-start"
			}}>
				<Avatar style={{ backgroundColor: "var(--dark1)" }} className="black">{user?.name[0]}</Avatar>
				<div style={{ flex: 1 }}>
					<div className='textSM w-500 dark4'>{user?.name}</div>
					<div className='textXS w-500 dark3'>{user?.email}</div>
				</div>
			</div>} defaultSelectedTab={searchParams.get('tab') || ''} />
			<UpdateDetailsModal />
		</React.Fragment>
	)
}
