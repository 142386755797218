import { baseURL } from 'Assets/Constant';
import { microphone } from 'Assets/images';
import AccessComponent from "Components/AccessComponent";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import { CustomizeWidgets } from 'Components/CustomizeWidgets';
import FactorModelsWidgit from 'Components/Dashboard/FactorModelsWidgit';
import MyScreens from 'Components/Dashboard/MyScreens';
import Near52WeekLowHigh from "Components/Dashboard/Near52WeekLowHigh";
import SuperStocks from 'Components/Dashboard/SuperStocks';
import TopGainersAndLosers from 'Components/Dashboard/TopGainersAndLosers';
import DashboardChartComponent from "Components/DashboardChartComponent";
import DashboardIndexPerf from "Components/DashboardIndexPerf";
import DashboardTools from "Components/DashboardTools";
import DashboardTopPerformersNew from 'Components/DashboardTopPerformersNew';
import IndexOrSectorsTreeChart from "Components/IndexOrSectorsTreeChart";
import InstrumentListTable from "Components/InstrumentListTable";
import UpgradeToPro from 'Components/UpgradeToPro';
import { CardHeader } from "Components/atoms/SmallComponents";
import Head from "Layout/Head";
import { fetchFundData } from "Libs/fetchFundData";
import { stock_PaidCols, useGetAssetType } from "Libs/utils";
import IndexList from 'Pages/Indices/IndexList';
import StockSectors from "Pages/Sectors/StockSectors";
import StockSectorsDetail from "Pages/Sectors/StockSectorsDetails";
import AlphaAnalysis from "Pages/sharpelyFeatures/AlphaAnalysis";
import Magicboard from "Pages/sharpelyFeatures/Magicboard";
import WatchlistFeed from 'Pages/sharpelyFeatures/WatchlistFeed';
import List from 'Pages/sharpelyFeatures/WatchlistPage';
import apiHelper from "Services/api/ApiHelper";
import { Badge, Card } from 'antd';
import React from "react";
import toast from "react-hot-toast";
import { FaDownload } from 'react-icons/fa';
import { MdDownloadForOffline } from 'react-icons/md';
import { useSelector } from "react-redux";

function Dashboard2() {

    const user = useSelector((state) => state?.updateUserData?.value)
    const stockType = useGetAssetType(4);
    const etfType = useGetAssetType(1);
    const mfType = useGetAssetType(2);
    const indexType = useGetAssetType(5);

    const [allWidgets, setAllWidgets] = React.useState([]);
    const [userDashboardId, setUserDashboardId] = React.useState(0);
    const [widgets, setWidgets] = React.useState([]);

    const [allETFs, setAllETFs] = React.useState([]);
    const [allMutualFunds, setAllMutualFunds] = React.useState([]);
    const [allStocks, setAllStocks] = React.useState([]);
    const [allIndices, setAllIndices] = React.useState([]);
    const [loadingETFs, setLoadingETFs] = React.useState(true);
    const [loadingMFs, setLoadingMFs] = React.useState(true);
    const [loadingStockss, setLoadingStocks] = React.useState(true);
    const [loadingIndices, setLoadingIndices] = React.useState(true);

    const [updatingDashboard, setUpdatingDashboard] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const loadData = async () => {
        setLoading(true)
        let res = await apiHelper({ apiName: "getAllWidgetsv1", saveResponse: "" });
        //console.log(res)
        if (res && res?.length) {
            setAllWidgets(res);

            let userWidgets = []
            if (user?.id) {
                userWidgets = await apiHelper({ apiName: "getDashboardByClientId", data: user?.id })
                if (userWidgets) {
                    setUserDashboardId(userWidgets?.[0]?.DashboardId);
                }
                //console.log(userWidgets)
            }
            if (!userWidgets?.length) { // if dashboard not available set default userWidgets based on preference
                const userPreference = user?.Client?.[0]?.Q1Answer || 3;
                userWidgets = res.filter(w => w?.[`IsDefaultPref${userPreference}`]).map(w => {
                    if (w?.Type === 'Mini Widget') {
                        return {
                            'WidgetId': w?.UID,
                            'MiniWidgetOrder': w?.[`IsDefaultPref${userPreference}`]
                        }
                    } else if (w?.Type === 'Full Widget') {
                        return {
                            'WidgetId': w?.UID,
                            'FullWidgetOrder': w?.[`IsDefaultPref${userPreference}`]
                        }
                    } else return null
                }).filter(x => x);
                //console.log(userWidgets)
            }
            const allUserWidgets = userWidgets.map(u => {
                const details = res.find(w => w.UID === u.WidgetId)
                return { ...u, ...details }
            })?.sort((a, b) => Math.max([a.FullWidgetOrder, a.MiniWidgetOrder]) - Math.max([b.FullWidgetOrder, b.MiniWidgetOrder]));
            //console.log(allUserWidgets)
            setWidgets(allUserWidgets);
        } else {
            setLoading(false)
            toast.error("Something went wrong. Please try again.")
        }

        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }

    const loadETFs = async () => {
        let etfs = await fetchFundData(1);
        if (etfs) {
            setAllETFs(etfs)
            setLoadingETFs(false)
        } else {
            setAllETFs([])
            setLoadingETFs(false)
        }
    }
    const loadMFs = async () => {
        let mfs = await fetchFundData(2);
        if (mfs) {
            setAllMutualFunds(mfs)
            setLoadingMFs(false)
        } else {
            setAllMutualFunds([])
            setLoadingMFs(false)
        }
    }
    const loadStocks = async () => {
        let stocks = await fetchFundData(4);
        if (stocks) {
            setAllStocks(stocks)
            setLoadingStocks(false)
        } else {
            setAllStocks([])
            setLoadingStocks(false)
        }
    }
    const loadIndices = async () => {
        let indices = await fetchFundData(5);
        if (indices) {
            //console.log('jajaj', indices)
            setAllIndices(indices)
            setLoadingIndices(false)
        } else {
            setAllIndices(indices)
            setLoadingIndices(false)
        }
    }
    React.useEffect(() => {
        loadData();
        Promise.all([loadETFs(), loadMFs(), loadStocks(), loadIndices()])
    }, [user])

    const onApplyChanges = async (items) => {
        setUpdatingDashboard(true);
        window.scrollTo({
            top: 0,
            behavior: "instant"
        })
        let fullWidgets = items?.filter((ele) => ele?.Type === 'Full Widget');
        let miniWidgets = items?.filter((ele) => ele?.Type === 'Mini Widget');
        fullWidgets = fullWidgets?.map((item, i) => {
            return {
                "widgetId": item?.UID,
                "fullWidgetOrder": i + 1,
                "miniWidgetOrder": 0,
                "isVisible": 1
            }
        })
        miniWidgets = miniWidgets?.map((item, i) => {
            return {
                "widgetId": item?.UID,
                "fullWidgetOrder": 0,
                "miniWidgetOrder": i + 1,
                "isVisible": 1
            }
        })
        let schema = {
            "clientId": user?.id,
            "dashboardId": userDashboardId,
            "dashboardName": "new dashboard",
            "instrumentType": stockType.value,
            "widgets": [
                ...miniWidgets, ...fullWidgets
            ]
        }
        let res = await apiHelper({ apiName: userDashboardId ? "editWidget" : "insertWidget", data: schema });
        if (res === 'success') {
            let userWidgets = await apiHelper({ apiName: "getDashboardByClientId", data: user?.id || -1 })
            if (userWidgets) {
                setUserDashboardId(userWidgets?.[0]?.DashboardId);
            }
        }
        setWidgets(items);
        setUpdatingDashboard(false);
    }

    const RenderWidgets = React.memo(({ widgets = [] }) => {
        const isMobile = window.innerWidth <= 760 ? true : false;

        const ComingSoon = ({ widgetItem }) => {
            return <div style={{ padding: "1.25rem", background: "var(--white)", borderRadius: "var(--borderRadius)" }}
                className="mobCardBorderRadius0">
                <div className='d-flex justify-content-between mobileFlexCol' style={{ gap: "1.25rem", alignItems: widgetItem?.Description ? "flex-end" : "center" }} >
                    {widgetItem?.Name && <div>
                        <CardHeader heading={widgetItem?.Name} headingType={2} />
                        {widgetItem?.Description && <div className='customTabsSubHeading' style={{ marginBottom: "0" }}>
                            {widgetItem?.Description}
                        </div>}
                    </div>}
                    <div className='d-flex justify-content-between align-items-center' style={{ gap: "1.25rem" }} >
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '2rem', textAlign: 'center' }}>
                    <img loading="lazy" alt="microphone" src={microphone} style={{ width: "10rem", marginTop: "1rem" }} />
                    <div className="textSM w-500 black">We are working on it and you will soon be able to use {widgetItem?.Name} widget.</div>
                </div>
            </div>
        }
        const WidgetItem = ({ item }) => {
            switch (item?.Key) {
                // mini widigts
                case "WATCHLIST_COMP": return <DashboardTools defInstType={stockType.value} defToolType={'Watchlist'} isBordered={false} showToolHeading={true} type="watchlist" allETFs={allETFs} allMFs={allMutualFunds} allStocks={allStocks} />;
                case "INDEX_WATCH_COMP": return <DashboardIndexPerf header={<CardHeader heading={"Index watch"} headingType={2} />} maxHeight={isMobile ? '20rem' : '25rem'} allIndices={allIndices?.filter((el) => !(String(el?.IndexName)?.startsWith('SHI:')))} loading={loadingIndices} />;
                case "MARKET_MOVERS": return (allStocks.length && allIndices.length) ? <TopGainersAndLosers allStocks={allStocks} type={stockType} allIndices={allIndices} /> : <></>;
                case "HIGH_LOW": return <Near52WeekLowHigh allStocks={allStocks} type={stockType} allIndices={allIndices} />;
                // case "MY_STRATEGIES": return <DashboardTools defInstType={stockType.value} defToolType={'Strategies'} isBordered={false} showToolHeading={true} type="strategies" />;
                // case "MY_SCREENS_MODELS": return <DashboardTools defInstType={stockType.value} defToolType={'Screens'} isBordered={false} showToolHeading={true} type="screens_and_modals" />;
                case "TOP_ETF": return <DashboardTopPerformersNew allFunds={allETFs} widgetItem={item} type={etfType} />;
                case "TOP_MF": return <DashboardTopPerformersNew allFunds={allMutualFunds} widgetItem={item} type={mfType} />;
                case "TOP_INDEX_FUNDS": return <DashboardTopPerformersNew allFunds={allMutualFunds.filter((f) => f['is_index_fund'] === 1)} widgetItem={item} type={mfType} />;
                case "SUPER_STOCKS": return <SuperStocks allStocks={allStocks} type={stockType} widgetItem={item} />;;

                // full widgets
                case "INDEX_HEATMAP": return <IndexOrSectorsTreeChart allInstrumentListData={allStocks} type={stockType} header={<CardHeader heading={item?.Name} headingType={2} />}
                    showIndexDropdown isCard borderRadius0InMob subHeading={item?.Description} />;
                case "SECTOR_HEATMAP": return <IndexOrSectorsTreeChart allInstrumentListData={allStocks} type={stockType} header={<CardHeader heading={item?.Name} headingType={2} />} isCard
                    borderRadius0InMob showSectorDropdown subHeading={item?.Description} />;
                case "MY_SCREENS": return <MyScreens widgetItem={item} allStocks={allStocks} allETFs={allETFs} allMutualFunds={allMutualFunds} />;
                case "MY_FACTOR_MODELS": return <FactorModelsWidgit type={stockType} widgetItem={item} />;
                case "STOCK_WIZARD": return <DashboardChartComponent isWidget selectedFundData={allStocks?.filter((i) => i?.symbol === "RELIANCE")?.[0]} allInstruments={allStocks} type={stockType} header={item?.Name} subHeading={item?.Description} />;
                case "INDEX_WATCH_FULL": return <IndexList isWidget widgetItem={item} allIndices={allIndices} />;
                case "INDEX_WIZARD": return <DashboardChartComponent isWidget type={indexType} header={item?.Name} subHeading={item?.Description} />;
                case "SECTOR_WATCH": return <StockSectors isWidget widgetItem={item} />;
                case "SECTOR_WIZARD": return <StockSectorsDetail isWidget widgetItem={item} />;
                case "ANNOUCEMENTS": return <WatchlistFeed isWidget widgetItem={item} allStocks={allStocks} />;
                case "STOCK_STYLEBOX": return <AccessComponent component={<InstrumentListTable showBubbleChartBtn initialColumns={stock_PaidCols} hideInvestBtn={true} tableType={7}
                    paginationSize={10} hideCheckBox isWidget widgetItem={item} showToolsBtnsInTab downloadTable showHeatmapBtn />} moduleKey="STOCK_STYLEBOX" upgradeComponent={<Card bodyStyle={{ padding: "1.25rem" }}>
                        <div>
                            <CardHeader heading={item?.Name} headingType={2} />
                            <div className='customTabsSubHeading' style={{ marginBottom: 0 }}>{item?.Description}</div>
                        </div>
                        <div>
                            <UpgradeToPro moduleKey='STOCK_STYLEBOX' />
                        </div>
                    </Card>} />;
                case "MF_MAGICBOARD": return <Magicboard defaultType={mfType} isWidget={true} widgetItem={item} />;
                case "MF_WIZARD": return <DashboardChartComponent isWidget type={mfType} header={item?.Name} subHeading={item?.Description} />;
                case "ETF_MAGICBOARD": return <Magicboard defaultType={etfType} isWidget={true} widgetItem={item} />;
                case "ETF_WIZARD": return <DashboardChartComponent isWidget type={etfType} header={item?.Name} subHeading={item?.Description} />;
                case "MF_ALPHA_ANALYSIS": return <AlphaAnalysis defaultType={mfType} isWidget={true} widgetItem={item} allMutualFunds={allMutualFunds} />;
                case "WATCHLIST_FULL": return <List isWidget={true} widgetItem={item} allETFs={allETFs} allMFs={allMutualFunds} allStocks={allStocks} />;
                default: return <ComingSoon widgetItem={item} />;
            }
        }

        return (
            <div className="mainContainer">
                <div className="leftContainer">
                    {widgets?.filter((item) => item?.Type === 'Full Widget')?.map((item, index) => {
                        return <WidgetItem item={item} key={index} index={index} />
                    })}
                </div>
                <div className="rightContainer">
                    {user?.id && <Badge.Ribbon key={"sa"} text={'Update'} color="var(--primary)">
                        <Card bordered={false} style={{ padding: 0 }} bodyStyle={{ padding: 0, paddingBottom: "0.5rem" }}
                            className="mobCardBorderRadius0">
                            <div className='pad20Container pb-2' style={{ display: "flex", alignItems: "center" }}>
                                <CardHeader heading={"Real Estate taxation analysis"} headingType={2} />
                            </div>
                            <div className='pad20Container pt-0' style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
                                <div className='textXS' style={{ display: "flex", flex: 1 }}>
                                    Download detailed spreadsheet to compare the impacts of old and new taxation rates over various holding periods on real estate investments.
                                </div>
                                <MdDownloadForOffline style={{ cursor: "pointer" }} onClick={() => window.open(baseURL + "old_vs_new_tax_real_estate.xlsx", "_blank")} size={30} />
                            </div>

                        </Card>
                    </Badge.Ribbon>}
                    {widgets?.filter((item) => item?.Type === 'Mini Widget')?.map((item, index) => {
                        return <WidgetItem item={item} key={index} index={index} />
                    })}
                </div>
            </div>
        )
    });

    if (loading) {
        return (
            <div className="loaderContainer">
                <ActivityLoaderMintbox showQuote />
            </div>
        )
    }
    return (
        <React.Fragment>
            <Head title="sharpely Stocks Dashboard | Real-time Market Insights" description="Dive into our dynamic stocks dashboard offering real-time market insights and analysis for informed trading decisions." keywords="Stock screener, Stock market charts, Stock market research, Stock market dashboard, Live stock updates, Stock performance analysis, Stock Market Research Websites, Stock market analytics, Real-time stock data, Tools for Share market, Share market technical analysis tools, best stock screener, best stock market charts, best Stock market research website, Top Stock market dashboard, best live stock updates website, top stock performance analysis platform, top Stock Market Research Websites, best Stock market analytics platform, best Real-time stock data platform, best Tools for Share market, best share market technical analysis tools, best stock screener India, best stock market charts in India, best Stock market research website in India, Top Stock market dashboard in India, best live stock updates website in India, top stock performance analysis platform in India, top Stock Market Research Websites in India, best Stock market analytics platform in India, best Real-time stock data Platform in India, best Tools for Share market in India, best share market technical analysis tools in India" />
            <CustomizeWidgets allWidgets={allWidgets} widgets={widgets} onApplyChanges={onApplyChanges} />
            {updatingDashboard && <div style={{
                position: "fixed", height: "100vh", width: "100vw", top: 0, left: 0, background: "rgb(0,0,0,0.1)", backdropFilter: "blur(2px)", zIndex: 1000
            }} className='d-flex justify-content-center align-items-center'>
                <ActivityLoaderMintbox message="Updating your dashboard" />
            </div>}
            <div className="normalContainer">
                <RenderWidgets widgets={widgets} />
            </div>
        </React.Fragment>
    )
}

export default Dashboard2;