import { togglePayFeeModal, togglePricingModal, updatePayFeeParams } from 'Libs/redux/UserAuth/reduxSlice';
import apiHelperPY from 'Services/api/ApiHelperPY';
import { Collapse, Modal } from 'antd';
import React from 'react';
import { AiFillCheckCircle } from "react-icons/ai";
import { IoIosArrowDown } from 'react-icons/io';
import { MdCancel } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row } from "reactstrap";

function PricingModal() {

    const navigate = useNavigate();
    const [plansData, setPlansData] = React.useState({});
    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    const openPricingModal = useSelector((state) => state?.pricingModal?.value);
    const dispatch = useDispatch();

    const loadData = async () => {
        let pls = await apiHelperPY({ apiName: "getAllSharpelyPlans", saveResponse: "" })
        if (pls) {
            pls = JSON.parse(pls);
            setPlansData(pls);
        }
    }

    React.useEffect(() => {
        loadData();
    }, [])

    const getFeatures = React.useCallback(() => {
        let data = {};
        for (let i = 0; i < accessLevelData?.length; i++) {
            let temp = accessLevelData?.[i];
            if (data[temp?.ModuleSection]) {
                data[temp?.ModuleSection]?.Data?.push(temp);
            }
            else {
                data[temp?.ModuleSection] = { Section: temp?.ModuleSection, Data: [temp] };
            }
        }
        return Object.values(data || {});
    }, [accessLevelData]);

    const features = getFeatures();

    const CheckIcon = () => (
        <AiFillCheckCircle style={{ color: "var(--green)", fontSize: "1.25rem" }} />
    )

    const CrossIcon = () => (
        <MdCancel style={{ color: "var(--red)", fontSize: "1.25rem" }} />
    )

    const onClose = () => {
        dispatch(togglePricingModal(false))
    }

    return (
        <React.Fragment>
            <Modal onCancel={onClose} onOk={onClose} open={openPricingModal} closable={false} footer={<div className='d-flex justify-content-end' style={{ gap: "1rem" }}>
                <button className='btnWhite' onClick={onClose}>Close</button>
                <Link to='/pricing' onClick={onClose}>
                    <button className='btnBlack'>Explore Plans</button>
                </Link>
            </div>} className='pricingModal'
                width={'min(45rem, 100%)'} height={'80vh'} style={{ overflow: "auto", borderRadius: "var(--borderRadius)", padding: 0 }}
                styles={{
                    body: {
                        overflow: "scroll"
                    }
                }}
            >
                <div className='d-flex flex-column h100' style={{ overflow: "scroll" }}>
                    <Row lg={4} sm={2} xs={2} className='gx-3 gy-3'>
                        {/* <Col>
                            <div style={{ padding: "0.75rem", background: "var(--blue2)", borderRadius: "var(--borderRadius)", overflow: "hidden" }} className='w100 h100 d-flex flex-column'>
                                <div style={{ color: "var(--black)" }} className='textSM w-500'>Find a plan that's right for you</div>
                                <div className='d-flex justify-content-center align-items-center' style={{ flex: 1 }}>
                                    <img loading="lazy"  src={sharpelyOnlyLogo} style={{ height: "4rem" }} alt='sharpely brand logo' />
                                </div>
                            </div>
                        </Col> */}
                        {plansData?.plans?.map((plan, i) => {
                            return (
                                <Col key={i}>
                                    <div style={{
                                        padding: "0.75rem", background: "var(--blue2)", borderRadius: "var(--borderRadius)", overflow: "hidden", gap: "1rem",
                                        border: plan?.type === 'free' ? "1px solid var(--primary)" : ""
                                    }}
                                        className='w100 h100 d-flex flex-column justify-content-between' >
                                        <div>
                                            <div className='textMD w-500 ' style={{ color: "var(--primary)" }}>{plan?.name}</div>
                                            <div className='textMD w-700 dark4' style={{ marginTop: "0.25rem" }}>₹ {plan?.price?.new}/Month</div>
                                            <div className='textXS w-500' style={{ color: "orange", marginTop: "0.25rem" }}>
                                                {plan?.type === 'paid' ? ' ' : "Free lifetime"}
                                            </div>
                                            {plan?.type === 'paid' && <div className='textXS w-500' style={{ color: "var(--primary)", marginTop: "0.25rem", cursor: "pointer" }}
                                                onClick={() => {
                                                    dispatch(togglePricingModal(false));
                                                    navigate('/pricing')
                                                    dispatch(togglePayFeeModal(true));
                                                    dispatch(updatePayFeeParams({
                                                        plan: plan?.level
                                                    }))
                                                }}>
                                                Get upto {plansData?.discount?.maxDiscount}% off on long-term plans.
                                            </div>}
                                        </div>
                                        <Link to={plan?.type === 'paid' ? "/pricing" : ""} onClick={(e) => {
                                            if (plan?.type === 'free') e.preventDefault();
                                            else {
                                                onClose()
                                            }
                                        }} >
                                            <button className='btnPrimary w100'>
                                                {plan?.type === 'paid' ? "Upgrade" : "Current plan"}
                                            </button>
                                        </Link>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                    <div style={{ marginTop: "1.25rem", }} >
                        <Collapse expandIconPosition="end" expandIcon={({ isActive }) => <IoIosArrowDown style={{
                            transition: "all 0.3s linear",
                            transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
                            color: "var(--black)",
                            fontSize: "0.875rem"
                        }} />} bordered={false} style={{ background: "transparent" }} defaultActiveKey={1} items={features?.map((item, i) => {
                            return {
                                key: i + 1,
                                label: <div style={{ fontSize: "var(--textMD)" }} className='w-500 dark4'>
                                    <div>{item.Section?.split(' - ')?.[0]}</div>
                                    <div className='textXS dark3 w-500' style={{ marginTop: "0.1rem" }}>{item.Section?.split(' - ')?.[1]}</div>
                                </div>,
                                className: 'pricingSection',
                                children: <>
                                    {item?.Data?.map((ele, j) => {
                                        let accessLimit = ele?.AceessDescription?.split('|')?.filter((el) => el)?.reverse()?.map((desc, m) => {
                                            return { Description: desc, AccessLevel: 4 - m }
                                        }) || [];
                                        const renderLimit = (num) => {
                                            if (ele.IsEnabled !== 1) return 'Coming soon';
                                            let el = accessLimit.find((jk) => jk.AccessLevel === num);
                                            if (el) {
                                                return el.Description;
                                            }
                                            else return <CheckIcon />
                                        }
                                        return (
                                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", borderBottom: "1px solid var(--grey2)", padding: "0.4rem 0", alignItems: "center" }} key={j} className=''>
                                                <div style={{ gap: "1rem", paddingLeft: "1rem" }} className='d-flex align-items-center'>
                                                    <div className="textESM dark4 w-500" style={{ wordBreak: "" }}>{ele.ModuleName}</div>
                                                </div>
                                                <div className='text-center textXS dark4 w-500'>{ele.AccessLevel <= 1 ? renderLimit(1) : <CrossIcon />}</div>
                                                <div className='text-center textXS dark4 w-500'>{ele.AccessLevel <= 2 ? renderLimit(2) : <CrossIcon />}</div>
                                                <div className='text-center textXS dark4 w-500'>{ele.AccessLevel <= 3 ? renderLimit(3) : <CrossIcon />}</div>
                                            </div>
                                        )
                                    }
                                    )}
                                </>
                            }
                        })} />
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default PricingModal;