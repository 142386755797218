import { BasketCreateModal } from "Components/BasketCreateModal";
import { HelpAndSupportFormModal } from "Components/HelpAndSupportForm";
import LoginSignupModal from "Components/LoginSignupModal";
import { MetricDescriptionDrawer } from "Components/MetricDescriptionDrawer";
import SubscribeModal from "Components/SubscribeModal";
import { UserGuideDrawer } from "Components/UserGuideDrawer";
import { UserOnBoardingModal } from "Components/UserOnBoardingModal";
import { WatchlistModal } from "Components/Watchlist";
import { UpdatePhoneModal } from "Components/atoms/UpdatePhoneModal";
import React from "react";
import { useSelector } from "react-redux";
import PricingModal from 'Components/PricingModal';

const MainModals = () => {

    const openBasketCreateModal = useSelector((state) => state?.basketCreateModal?.open);
    const ooenMetricDescModal = useSelector((state) => state?.metricDescModal?.value);
    const openHelpAndSuppModal = useSelector((state) => state.helpAndSupp?.value);
    const openUserGuide = useSelector((state) => state?.userGuideModal?.value);
    const openPreferenceModal = useSelector((state) => state?.preferenceModal?.value);
    const openPricingModal = useSelector((state) => state?.pricingModal?.value);
    const openSubscribeModal = useSelector((state) => state.toggleSubscribeModal?.open);
    const openWatchlistModal = useSelector((state) => state?.watchlistModal?.open);

    return (
        <React.Fragment>
            {openBasketCreateModal && <BasketCreateModal />}
            {ooenMetricDescModal && <MetricDescriptionDrawer />}
            {openHelpAndSuppModal && <HelpAndSupportFormModal />}
            {openUserGuide && <UserGuideDrawer />}
            {openPreferenceModal && <UserOnBoardingModal />}
            <UpdatePhoneModal />
            <LoginSignupModal />
            {openPricingModal && <PricingModal />}
            {openSubscribeModal && <SubscribeModal />}
            {openWatchlistModal && <WatchlistModal />}
        </React.Fragment>
    )
}

export default MainModals;