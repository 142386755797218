import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import React from "react";

export default function LazyLoadingFallback() {

    return (
        <React.Fragment>
            <div>
                <ActivityLoaderMintbox />
            </div>
        </React.Fragment>
    )
}

export const NullLazyFallback = ({ children }) => {

    return (
        <React.Suspense fallback={<></>}>
            {children}
        </React.Suspense>
    )
}

export const ModalLazyFallback = ({ children }) => {

    return (
        <React.Suspense fallback={
            <div className="loaderContainer2">
                <ActivityLoaderMintbox />
            </div>
        }>
            {children}
        </React.Suspense>
    )
}