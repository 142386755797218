import { FaCircle } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import { CurrencyFormat } from './CurrencyFormat';
import { convertToTime, numToWords } from './DataCustomizer';
import ordinal_suffix_of from './Ordinal-suffix';

const SIPCard = ({ orderType, fundType, status, transactionDate, amount, fundData, onModifyPress }) => {

    // if (fundType === 4) {
    //     //goal stopped or not started view
    //     return <div className='row'  >

    //     </div>
    // } else {
    //     return <div className='row'>
    //         <div className='col-md-12' style={{ margin: "16px 0" }}>
    //             {/* <div className="card">
    //                 <div className="card-body" style={{ padding: "16px" }}> */}
    //             <div className='row'>
    //                 <div className='col-4'>
    //                     <div className='fontBoldDark13' style={{ textAlign: "left", alignItems: "flex-start", margin: "4px 0" }}>{ordinal_suffix_of(fundData.OrderDate)} of every {fundData.Frequency === 12 ? "" : numToWords(fundData.Frequency)}month{"(s)"}</div>
    //                     <div className='fontBoldDark13' style={{ textAlign: "left", alignItems: "flex-start", margin: "4px 0" }}>{fundData.Units === 0 ? CurrencyFormat(fundData.Amount, "long") : fundData.Units + " units"}</div>
    //                 </div>
    //                 <div className='col-4' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
    //                     <span className='fontBoldDark13' style={{ textAlign: "right" }}>Modify</span>
    //                     <FiChevronDown size={12} style={{ color: "var(--darkGreen)" }} />
    //                 </div>
    //                 <div className='col-4' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
    //                     <FaCircle size={8} style={{ color: "var(--darkGreen)" }} />
    //                     <span className='fontBoldDark13' style={{ alignItems: "flex-start", textAlign: "left", marginLeft: "4px" }} >{status === 1 ? "Active" : status === 0 ? "Stopped" : "Paused"}</span>
    //                 </div>
    //             </div>
    //             {/* </div>
    //             </div> */}
    //         </div>
    //     </div>
    // }
    return (
        <div style={{ borderBottom: "1px solid var(--grey3)", padding: '0.75rem 0' }}>
            <div className="d-flex justify-content-between align-items-end" style={{ gap: "1rem" }}>
                <div>
                    <div className="textXS dark4 w-500">
                        {fundData?.Frequency !== "1D" && ordinal_suffix_of(fundData.OrderDate)}
                        &nbsp;{fundData?.Frequency !== "1D" ? "of every " : ""}{convertToTime(fundData?.Frequency)}
                    </div>
                    <div className="textMD w-700 dark4">
                        {fundData?.Units === 0 ? CurrencyFormat(fundData?.Amount) : fundData?.Units + (fundData?.Units > 1 ? " units" : " unit")}
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "1rem" }}>
                    <div className='' style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.25rem" }}>
                        <div style={{ background: status === 1 ? "var(--green)" : status === 0 ? "var(--red)" : "var(--gold)", width: 10, height: 10, borderRadius: "50%" }}></div>
                        <div className='textXS w-500 dark4' style={{ alignItems: "flex-start", textAlign: "left", marginLeft: "4px" }} >
                            {status === 1 ? "Active" : status === 0 ? "Stopped" : "Paused"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SIPCard;
