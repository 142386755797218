import { createSlice } from '@reduxjs/toolkit'


export const knowledgeBaseModal = createSlice({
  name: 'knowledgeBaseModal',
  initialState: {
    value: false,
    params: {}
  },
  reducers: {
    toggleknowledgeBaseModal: (state, action) => {
      state.value = action.payload.value
      state.params = action.payload.params
    },
  },
})

export const userGuideModal = createSlice({
  name:"userGuideModal",
  initialState:{
    value:false,
    type:undefined,
    params:{}
  },
  reducers:{
    toggleUserGuideModal: (state,action) => {
      state.value = action.payload.value
      state.params = action.payload.params
      state.type=action.payload.type
    }
  }
}) 

export const metricDescModal = createSlice({
  name: 'metricDescModal',
  initialState: {
    value: false,
    params: {}
  },
  reducers: {
    toggleMetricDescModal: (state, action) => {
      state.value = action.payload.value
      state.params = action.payload.params
    },
  },
})

export const { toggleknowledgeBaseModal } = knowledgeBaseModal.actions
export const { toggleMetricDescModal } = metricDescModal.actions
export const { toggleUserGuideModal } = userGuideModal.actions