import { LoadingOutlined } from '@ant-design/icons';
import { setOpenSearchModal } from 'Libs/redux/Modals/reduxSlice';
import { toggleWatchlistModal, updateWatchlistModalParams, updateWatchlists } from "Libs/redux/UserAuth/reduxSlice";
import { generateUniqueKey, getFormattedWatchlist } from 'Libs/utils';
import apiHelper from "Services/api/ApiHelper";
import { Divider, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { BiPlus } from "react-icons/bi";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { SubscribeContentWrapper, useSubscribeMiddleware } from "./SubscribeModal";

export const WatchList = ({ type, instrument, size = window.innerWidth > 760 ? '1.2rem' : '18px' }) => {

    let user = useSelector((state) => state?.updateUserData?.value);
    let watchlist = useSelector((state) => state?.userWatchlists?.value);
    const dispatch = useDispatch();
    const [isWatchlisted, setIsWatchlisted] = useState(false);

    const loadData = () => {
        setIsWatchlisted(false);
        if (user) {
            let filteredWatchlists = watchlist?.filter((ele) => ele?.FundType === type);
            let temp = filteredWatchlists?.find(ele => {
                if (ele?.FundType === 4) {
                    return ele?.FundCodes.includes(String(instrument['symbol']));
                }
                else if (ele?.FundType === 1) {
                    return ele?.FundCodes.includes(String(instrument["nse_symbol"]));
                }
                else if (ele?.FundType === 2) {
                    return ele?.FundCodes.includes(String(instrument["plan_id"]));
                }
            });
            if (temp) {
                setIsWatchlisted(true);
            }
        }
    }

    useEffect(() => {
        loadData();
    }, [watchlist, instrument]);

    return (
        <React.Fragment>
            <span style={{ cursor: "pointer", flexShrink: 0 }} onClick={(e) => {
                dispatch(setOpenSearchModal(false));
                e.stopPropagation();
                dispatch(updateWatchlistModalParams({
                    type, instrument, fundCode: String(type === 1 ? instrument.nse_symbol : type === 2 ? instrument.plan_id : instrument.symbol)
                }))
                dispatch(toggleWatchlistModal(true));
            }}>
                {isWatchlisted ? <BsBookmarkFill size={size} className='black' /> : <BsBookmark size={size} className='black' />}
            </span>
        </React.Fragment>
    )
}

export const WatchlistModal = () => {

    let user = useSelector((state) => state?.updateUserData?.value);
    const open = useSelector((state) => state?.watchlistModal?.open);
    const params = useSelector((state) => state?.watchlistModal?.params);
    let watchlist = useSelector((state) => state?.userWatchlists?.value);
    const dispatch = useDispatch();
    const [createNewWatchlist, setCreateNewWatchlist] = React.useState(false);
    const [watchlistName, setWatchlistName] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const subscribeMiddle = useSubscribeMiddleware();

    const isCreateWatchModal = params?.createNewWatch;

    const isEditWatchModal = params?.editWatchList;
    const watchToEdit = params?.watchToEdit;

    const onClose = () => {
        dispatch(toggleWatchlistModal(false));
        dispatch(updateWatchlistModalParams({}));
        setCreateNewWatchlist(false);
        setWatchlistName('');
    }

    const filteredWatchlist = React.useMemo(() => { return watchlist?.filter((ele) => ele?.FundType === params?.type) }, [watchlist, params]);

    const watchlistAccess = subscribeMiddle({
        moduleKey: Number(params?.type) === 4 ? "MULTIPLE_WATCHLISTS" : Number(params?.type) === 1 ? "MULTIPLE_WATCHLISTS_ETFs" :
            Number(params?.type) === 2 ? "MULTIPLE_WATCHLISTS_MF" : "", getDetailedAccessData: true, checkLimit: true, usageCount: filteredWatchlist?.length
    })

    const watchlistItemsAccess = subscribeMiddle({
        moduleKey: Number(params?.type) === 4 ? "MULTIPLE_WATCHLISTS" : Number(params?.type) === 1 ? "MULTIPLE_WATCHLISTS_ETFs" :
            Number(params?.type) === 2 ? "MULTIPLE_WATCHLISTS_MF" : "", getDetailedAccessData: true, checkLimit: true, usageCount: 0, limitIndex: 1
    })

    // console.log(watchlistAccess);
    const onClickCreateWatchlist = async () => {
        let createWatchObj = {
            "clientId": user?.id, "fundType": params?.type, "name": watchlistName,
            "watchlistId": 0, "fundCode": ""
        }
        let res1 = await apiHelper({ apiName: "createWatchlist", data: createWatchObj })
        if (res1?.Result === 'success') {
            let watchlistt = await apiHelper({ apiName: 'getWatchListByClientId', data: { clientId: user.id } });
            if (watchlistt.length > 0) {
                let formattedWatchlist = getFormattedWatchlist(watchlistt);
                if (params?.fundCode) {
                    let watchObj = formattedWatchlist?.find((el) => el?.Name === createWatchObj?.name && el?.FundType === createWatchObj?.fundType);
                    await handleWatchlistItem(watchObj, false);
                }
                else {
                    dispatch(updateWatchlists(formattedWatchlist));
                }
            }
            toast.success(isEditWatchModal ? `Watchlist edited successfully.` : `Watchlist added successfully.`)
        }
        else {
            toast.error('Watchlist with this name already exists! Use another name.')
        }
        if (!isCreateWatchModal) {
            setCreateNewWatchlist(false);
        }
        else {
            onClose();
        }
        setWatchlistName('');
    }

    const onEditWatchlist = async () => {
        try {
            setLoading(true);
            let res = await apiHelper({ apiName: "editWatchlistName", data: { watchListId: watchToEdit?.WatchListId, name: watchlistName } });
            if (res?.length > 0) {
                let watchlistt = await apiHelper({ apiName: 'getWatchListByClientId', data: { clientId: user.id } });
                if (watchlistt.length > 0) {
                    let formattedWatchlist = getFormattedWatchlist(watchlistt);
                    dispatch(updateWatchlists(formattedWatchlist));
                }
                setLoading(false);
                onClose();
                toast.success('Watchlist updated.');
            }
            else {
                setLoading(false);
                toast.error('Watchlist with this name already exists! Use another name.');
            }
        }
        catch (err) {
            console.log(err);
            setLoading(false)
            toast.error('Something went wrong!');
        }
    }

    // const onDeleteWatchlist = async () => {
    //     try {
    //         let res = await apiHelper({
    //             apiName: "removeWatchlist", data: {
    //                 clientId: user?.id, name: watchToEdit?.Name, watchlistId: watchToEdit?.WatchListId,
    //                 fundType: watchToEdit?.FundType
    //             }
    //         });
    //         if (res?.Result === 'success') {
    //             let watchlistt = await apiHelper({ apiName: 'getWatchListByClientId', data: { clientId: user.id }, saveResponse: 'sessionStorage', getFresh: true });
    //             if (watchlistt.length > 0) {
    //                 let formattedWatchlist = getFormattedWatchlist(watchlistt);
    //                 dispatch(updateWatchlists(formattedWatchlist));
    //             }
    //             onClose();
    //             toast.success('Watchlist deleted.');
    //         }
    //         else {
    //             toast.error('Something went wrong!');
    //         }
    //     }
    //     catch (err) {
    //         console.log(err);
    //         setLoading(false)
    //         toast.error('Something went wrong!');
    //     }
    // }

    // const handleCreateNewWatchlist = () => {
    //     subscribeMiddle({
    //         ModuleKey: params.type === 4 ? "STOCK_NO_OF_WATCHLIST" : "MF_ETF_NO_OF_WATCHLIST",
    //         isLimitType: true,
    //         userLimit: filteredWatchlist?.length || 0,
    //         onNext: () => {
    //             onClickCreateWatchlist()
    //         }
    //     })
    // }

    const handleWatchlistItem = async (watchObj, isWatchlisted) => {
        let res2 = await apiHelper({
            apiName: isWatchlisted ? "removeItemFromWatchlist" : "addItemToWatchlist", data: {
                "clientId": user?.id, "watchlistId": watchObj?.WatchListId,
                fundCode: params?.fundCode, fundType: params?.type
            }
        })
        if (res2?.Result === 'success') {
            toast.success(isWatchlisted ? `Item removed from ${watchObj?.Name}` : `Item added to ${watchObj?.Name}`)
            let res3 = await apiHelper({ apiName: 'getWatchListByClientId', data: { clientId: user.id } });
            if (res3.length > 0) {
                let forwat2 = getFormattedWatchlist(res3);
                dispatch(updateWatchlists(forwat2));
            }
        }
        else {
            toast.error('Something went wrong!')
        }
    }

    React.useEffect(() => {
        if (open === false) {
            setWatchlistName('');
            setCreateNewWatchlist(false);
        }
        if (open === true && isEditWatchModal) {
            setWatchlistName(watchToEdit?.Name);
        }
        if (open === true && filteredWatchlist?.length === 0) {
            setCreateNewWatchlist(true);
        }
    }, [open])

    if (isCreateWatchModal) {
        return (
            <React.Fragment>
                <Modal centered width={"min(25rem, 100%)"} open={open} onCancel={onClose} onOk={onClose} footer={null} title={<div>
                    <div className="textLG w-700 dark4">Create new watchlist</div>
                    <div className="textXS dark2 w-500">You have <b className="dark4">{watchlistAccess?.remainingLimit}</b> out of <b className="dark4">{watchlistAccess?.maxLimit}</b> watchlist credits left.</div>
                </div>}>
                    <div style={{ margin: "2rem 0" }}>
                        <Input status={watchlistName?.match(/[\/?'&"]/g) ? "error" : ""} autoFocus value={watchlistName} placeholder='Enter watchlist name' onChange={(e) => {
                            setWatchlistName(e.target.value)
                        }} style={{
                            background: "var(--grey3)", borderWidth: "0px 0px 2px 0px", borderStyle: "solid", borderColor: "var(--primary)", padding: "0.6rem 0.75rem",
                            borderRadius: "0", outline: "none"
                        }} />
                        {watchlistName?.match(/[\/?'&"]/g) && <label style={{ color: "var(--red)", textAlign: "right" }} className='textXS w-500'>
                            * Special characters are not allowed
                        </label>}
                    </div>
                    <SubscribeContentWrapper moduleKey={params?.type === 4 ? "MULTIPLE_WATCHLISTS" : params?.type === 1 ? "MULTIPLE_WATCHLISTS_ETFs" : params?.type === 2 ? "MULTIPLE_WATCHLISTS_MF" : ""}
                        checkLimit usageCount={filteredWatchlist?.length || 0} limitIndex={0} onClick={() => {
                            onClickCreateWatchlist();
                        }} disabled={watchlistName?.match(/[\/?'&"]/g) || watchlistName?.length < 3}>
                        <button className="btnBlack w100" disabled={watchlistName?.match(/[\/?'&"]/g) || watchlistName?.length < 3}>
                            Create
                        </button>
                    </SubscribeContentWrapper>
                </Modal>
            </React.Fragment>
        )
    }
    else if (isEditWatchModal) {
        return (
            <React.Fragment>
                <Modal centered width={"min(25rem, 100%)"} open={open} onCancel={onClose} onOk={onClose} footer={null} title={<div
                    className="d-flex justify-content-between align-items-center">
                    <div className="textLG w-700 dark4">Edit watchlist name</div>
                </div>} closable={false}>
                    <div style={{ margin: "2rem 0" }}>
                        <Input status={watchlistName?.match(/[\/?'&"]/g) ? "error" : ""} autoFocus value={watchlistName} placeholder='Enter watchlist name' onChange={(e) => {
                            setWatchlistName(e.target.value)
                        }} style={{
                            background: "var(--grey3)", borderWidth: "0px 0px 2px 0px", borderStyle: "solid", borderColor: "var(--primary)", padding: "0.6rem 0.75rem",
                            borderRadius: "0", outline: "none"
                        }} />
                        {watchlistName?.match(/[\/?'&"]/g) && <label style={{ color: "var(--red)", textAlign: "right" }} className='textXS w-500'>
                            * Special characters are not allowed
                        </label>}
                    </div>
                    <button className="btnBlack w100 d-flex align-items-center justify-content-center" disabled={watchlistName?.match(/[\/?'&"]/g) || watchlistName?.length < 3}
                        onClick={onEditWatchlist} style={{ gap: "0.75rem" }}>
                        {loading && <LoadingOutlined spin />} Save
                    </button>
                </Modal>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <Modal centered width={"min(30rem, 100%)"} open={open} onCancel={onClose} onOk={onClose} footer={null} title={<div>
                <div className="textLG w-700 dark4">Add {params?.type === 4 ? "Stock" : params?.type === 1 ? "ETF" : "MF"} to</div>

            </div>}>
                {filteredWatchlist?.length > 0 ? <div style={{
                    display: "flex", flexDirection: "column", gap: "1.25rem", marginBottom: "1.25rem", marginTop: "1.5rem",
                    minHeight: "12rem", maxHeight: "30vh", overflow: "auto"
                }} className='scrollbox'>
                    {filteredWatchlist?.map((item, i) => {
                        let isWatchlisted = item?.FundCodes?.includes(params?.fundCode)
                        let isLocked = Number(watchlistAccess?.maxLimit) < (i + 1);
                        return (
                            <SubscribeContentWrapper key={generateUniqueKey(i)} onClick={() => {
                                handleWatchlistItem(item, isWatchlisted)
                            }} openByDefault={isLocked} moduleKey={params?.type === 4 ? "MULTIPLE_WATCHLISTS" : params?.type === 1 ? "MULTIPLE_WATCHLISTS_ETFs" : params?.type === 2 ? "MULTIPLE_WATCHLISTS_MF" : ""}
                                checkLimit usageCount={item?.FundCodes?.length} preventDefault={isLocked ? false : isWatchlisted} limitIndex={1} subModalParams={{
                                    customAccessObj: {
                                        ModuleName: "Watchlist Locked",
                                        ModuleDesc: `This watchlist is locked. With your current plan ${watchlistAccess?.maxLimit === 0 ? 'you cannot add/delete any item from your saved watchlists. To edit/update watchlist, upgrade your plan.' : `you can only edit/update your first ${watchlistAccess?.maxLimit} saved watchlists.
                                        To edit/update your other watchlists, upgrade your plan.`}`
                                    }
                                }} isDefaultLocked={isLocked} showLockIcon={isLocked} lockIconContainerStyles={{ top: -3, right: 0 }}>
                                <div className="d-flex align-items-center w100 hoverBold" style={{ gap: "1rem", cursor: "pointer" }} >
                                    <div className='btnIcon'>
                                        {isWatchlisted ? <BsBookmarkFill style={{ color: "var(--primary)", fontSize: "1rem" }} /> :
                                            <BsBookmark style={{ color: "var(--primary)", fontSize: "1rem" }} />}
                                    </div>
                                    <div className="d-flex align-items-center " style={{ gap: "1rem", cursor: "pointer", flex: 1, justifyContent: "space-between" }}>
                                        <div className="textSM w-500 dark4">
                                            {item?.Name}
                                        </div>
                                        <div className="textXS w-500 dark3">
                                            {`${item?.FundCodes?.length} / ${watchlistItemsAccess?.maxLimit} ${params?.type === 4 ? "stocks" : params?.type === 1 ? "ETFs" : "MFs"}`}
                                        </div>
                                    </div>
                                </div>
                            </SubscribeContentWrapper>
                        )
                    })}
                </div> : <div style={{ minHeight: "6rem" }}>
                    <div className="textSM w-500 dark3 mt-4">
                        You don't have any {params?.type === 4 ? "Stock" : params?.type === 1 ? "ETF" : "MF"} watchlist.
                    </div>
                    <div className="textSM w-500 dark3 mt-2">
                        Create a new {params?.type === 4 ? "Stock" : params?.type === 1 ? "ETF" : "MF"} watchlist and add items to it.
                    </div>
                </div>}
                <Divider style={{ marginBottom: "1.25rem", marginTop: "1.25rem" }} />
                {createNewWatchlist ? <>
                    <div style={{ flex: 1, gap: "1rem" }} className='d-flex align-items-center'>
                        <Input status={watchlistName?.match(/[\/?'&"]/g) ? "error" : ""} placeholder="Enter watchlist name" autoFocus value={watchlistName} onChange={(e) => {
                            setWatchlistName(e.target.value)
                        }} />
                        <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                            <SubscribeContentWrapper moduleKey={params?.type === 4 ? "MULTIPLE_WATCHLISTS" : params?.type === 1 ? "MULTIPLE_WATCHLISTS_ETFs" : params?.type === 2 ? "MULTIPLE_WATCHLISTS_MF" : ""}
                                checkLimit usageCount={filteredWatchlist?.length || 0} limitIndex={0} onClick={() => {
                                    onClickCreateWatchlist();
                                }}>
                                <button className="btnIcon" disabled={watchlistName?.length < 3 || watchlistName?.match(/[\/?'&"]/g)} ><AiOutlineCheck /></button>
                            </SubscribeContentWrapper>
                            <button className="btnIcon" onClick={() => {
                                setCreateNewWatchlist(false);
                                setWatchlistName('');
                            }}><AiOutlineClose /></button>
                        </div>
                    </div>
                    {watchlistName?.match(/[\/?'&"]/g) && <label style={{ color: "var(--red)", textAlign: "right" }} className='textXS w-500'>
                        * Special characters are not allowed
                    </label>}
                </> : <SubscribeContentWrapper onClick={() => {
                    if (!createNewWatchlist) {
                        setCreateNewWatchlist(true)
                    }
                }} moduleKey={params?.type === 4 ? "MULTIPLE_WATCHLISTS" : params?.type === 1 ? "MULTIPLE_WATCHLISTS_ETFs" : params?.type === 2 ? "MULTIPLE_WATCHLISTS_MF" : ""}
                    checkLimit usageCount={filteredWatchlist?.length || 0} limitIndex={0}>
                    <div className="d-flex align-items-center w100" style={{ gap: "1rem", cursor: "pointer" }} >
                        <div className='btnIcon'>
                            <BiPlus style={{ color: "var(--primary)", fontSize: "1.25rem" }} />
                        </div>
                        <div className="textMD w-700 primary">
                            Add {params?.type === 4 ? "Stock" : params?.type === 1 ? "ETF" : "MF"} to new list
                        </div>
                    </div>
                </SubscribeContentWrapper>}
            </Modal>
        </React.Fragment>
    )
}