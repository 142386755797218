import { Card } from 'antd';
import AccessComponent from 'Components/AccessComponent';
import AccessControlledPage from 'Components/AccessControlledPage';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { CardHeader } from 'Components/atoms/SmallComponents';
import CustomSegmentedTab from 'Components/CustomSegmentedTab';
import InstrumentListTable from 'Components/InstrumentListTable';
import UpgradeToPro from 'Components/UpgradeToPro';
import Head from 'Layout/Head';
import { useGetAssetType } from 'Libs/utils';
import React from 'react';

function Magicboard({ defaultType = undefined, isWidget = false, widgetItem = {} }) {

    let type = useGetAssetType();
    type = defaultType ? defaultType : type

    const mf_magic_items = [
        { label: `Mutual Funds`, value: '1', key: '1', },
        { label: `Index funds`, value: '2', key: '2', },
        { label: `FOFs`, value: '3', key: '3', },
    ]

    const [selectedMFTab, setselectedMFTab] = React.useState(mf_magic_items.filter((i) => i !== false)[0]?.value || '');
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setLoading(true);
        setselectedMFTab(mf_magic_items.filter((i) => i !== false)[0]?.value || '');
        setTimeout(() => {
            setLoading(false);
        }, 100)
    }, [type.value])

    const items = [
        {
            key: 1, value: 1, children: <Card>
                <div style={{ marginBottom: "1rem" }}>
                    <CardHeader heading={isWidget ? widgetItem?.Name : 'ETF Magicboard'} headingType={isWidget ? 2 : 1} />
                    {isWidget && <div className="customTabsSubHeading" style={{ marginBottom: 0 }}>{widgetItem?.Description}</div>}
                </div>
                <InstrumentListTable defaultInsType={type.value} tableType={0} hideCheckBox={true} showCategoryDropDown={true}
                    paginationSize={15} isCard={false} tableHeader={<div></div>} showToolsBtnsInTab downloadTable showHeatmapBtn tableRowHeight='4rem' />
            </Card>
        },
        {
            key: 2, value: 2, children: <Card>
                <div style={{ marginBottom: "1rem" }}>
                    <CardHeader heading={isWidget ? widgetItem?.Name : 'Mutual Funds Magicboard'} headingType={isWidget ? 2 : 1} />
                    {isWidget && <div className="customTabsSubHeading" style={{ marginBottom: 0 }}>{widgetItem?.Description}</div>}
                </div>
                <InstrumentListTable defaultInsType={type.value} tableType={0} hideCheckBox={true} showCategoryDropDown={true} tableHeader={<CustomSegmentedTab isBlock={false} value={selectedMFTab}
                    onChange={(e) => { setselectedMFTab(e) }} options={mf_magic_items} />} paginationSize={15} isCard={false} defFilters={selectedMFTab === '1' ?
                        [["is_index_fund", 0], ["is_fof", 0]] : selectedMFTab === '2' ? [["is_index_fund", 1]] : [["is_fof", 1]]}
                    magicBoardtype={selectedMFTab === '2' ? "index" : "mf"} showToolsBtnsInTab downloadTable showHeatmapBtn showSymbolInMob={false} tableRowHeight='4rem' />
            </Card>
        }
    ]

    const metaItems = {
        1: {
            title: "ETF Magicboard: Unique Data ETF Screener and Analysis Tool | sharpely ETFs Magicboard",
            description: "sharpely's ETF Magicboard, a uique data ETF screener and analysis tool, helps streamline your investment decisions. Gain comprehensive insights from the sharpely ETF Magicboard for informed equity fund investing aimed at achieving benchmark returns."
        },
        2: {
            title: "Mutual Funds Magicboard: Real-Time MF Screener and Analysis Tool | sharpely MF Magicboard",
            description: "sharpely's Mutual Funds Magicboard, a real-time MF screener and analysis tool. Streamline your investment decisions with comprehensive insights from the sharpely MF Magicboard for informed fund selection and management."
        }
    }

    if (isWidget) {
        return <>
            <AccessComponent component={
                <>
                    {items?.find((ele) => ele?.value === type.value)?.children}
                </>
            } moduleKey={type?.value === 1 ? "MAGICBOARD" : "MAGICBOARD_MF"} upgradeComponent={<Card bordered={false}>
                <div>
                    <CardHeader heading={widgetItem?.Name} headingType={3} />
                    <div className='customTabsSubHeading' style={{ marginBottom: 0 }}>{widgetItem?.Description}</div>
                </div>
                <div className='my-5'>
                    <UpgradeToPro moduleKey={type?.value === 1 ? "MAGICBOARD" : "MAGICBOARD_MF"} height='fit-content' />
                </div>
            </Card>} />
        </>
    }
    if (loading) {
        return (
            <React.Fragment>
                <div className='loaderContainer'>
                    <ActivityLoaderMintbox />
                </div>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <Head title={metaItems?.[type?.value]?.title || 'Magicboard'} description={metaItems?.[type?.value]?.description || `${type?.dname} Magicboard`} />
            <AccessControlledPage moduleKey={type?.value === 1 ? "MAGICBOARD" : "MAGICBOARD_MF"} />
            <div className='normalContainer'>
                {items?.find((ele) => ele?.value === type.value)?.children}
            </div>
        </React.Fragment>
    )
}

export default Magicboard;