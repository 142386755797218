
import React from 'react';
import { DetailIcon } from './DetailIcon';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import AccessComponent from './AccessComponent';
import { Tooltip, Skeleton } from 'antd';
import { MdOutlineLock } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toggleloginModal } from 'Libs/redux/UserAuth/reduxSlice';
import { useNavigate } from 'react-router-dom';
import { toggleMetricDescModal } from 'Libs/redux/KnowledgeBase/reduxSlice';

export default function FundDetCards({ data, type, headingType, loading, itemInGroups = 2, labelClassName = '', valueClassName = '', valueStyles = {} }) {
    const user = useSelector((state) => state?.updateUserData?.value)
    const userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel || 0
    const dispatch = useDispatch();
    const navigate = useNavigate();
    function groupIntoPairs(inputArray) {
        const pairs = [];
        for (let i = 0; i < inputArray?.length; i += itemInGroups) {
            if (i + itemInGroups - 1 < inputArray?.length) {
                let t = []
                for (let j = 0; j < itemInGroups; j++) {
                    t.push(inputArray[i + j])
                }
                pairs.push(t);
            } else {
                let t = []
                for (let j = 0; j < itemInGroups; j++) {
                    if (i + j === inputArray?.length) {
                        break
                    }
                    t.push(inputArray[i + j])
                }
                pairs.push(t);
            }
        }
        return pairs;
    }

    return (
        <React.Fragment>
            {type === 'grid' ? <div className='snapshotMetricsGrid'>
                {groupIntoPairs(data?.filter((i) => i?.show !== false && i?.isFull !== true))?.map((pair, j) => (
                    <div style={{ display: "grid", gridTemplateColumns: new Array(itemInGroups).fill(0).map((el) => '1fr').join(' '), alignContent: "space-evenly", }} key={j + 1}>
                        {pair.map((ele, i) => (
                            <div key={i} style={{
                                width: "100%", height: "100%",
                                padding: window.innerWidth > 760 ? "0.75rem 0.75rem" : "0.75rem 0.75rem", borderBottom: "1px solid var(--grey3)",
                                borderLeft: i !== 0 ? "1px solid var(--grey3)" : "",
                            }} className={ele?.helperText ? '' : 'showInfoOnHoverRow'}>
                                <div>
                                    <div className='textXS dark2' style={{ display: "flex", gap: "0.01rem", alignItems: "center" }}>
                                        <span className={`${ele?.dontEllipsis ? '' : 'textEllipsis1'} ${labelClassName}`} style={{ flex: 1 }}>{ele.label}</span>
                                        {ele?.showIcon !== false && (ele?.helperText ? <Tooltip title={ele?.helperText} arrow={false}>
                                            <AiOutlineInfoCircle style={{ marginLeft: "0.25rem", cursor: "pointer" }} className='infoIcon' />
                                        </Tooltip> : <AiOutlineInfoCircle style={{ marginLeft: "0.25rem", cursor: "pointer" }} onClick={() => {
                                            dispatch(toggleMetricDescModal({
                                                value: true,
                                                params: {
                                                    title: ele?.metric_name,
                                                    description: ele?.metric_desc
                                                }
                                            }))
                                        }} className='infoIcon' />)}
                                    </div>
                                    <AccessComponent component={<div className={`textSM black w-500 ${valueClassName}`} style={{...valueStyles}}>{ele?.valueToRender || ele?.value}</div>} reqAccessLevel={ele?.auth_flag}
                                        upgradeComponent={<Tooltip title={`${(ele?.auth_flag === 1 || userAccesslevel === 0) ? "Sign in" : ele?.auth_flag === 2 ? "Upgrade to Lite" : ele?.auth_flag === 3 ? "Upgrade to Pro" : "Upgrade to Black"} to view this metric`} arrow={false}>
                                            <MdOutlineLock color='var(--dark4)' size='1rem' cursor={'pointer'} onClick={() => {
                                                if (userAccesslevel === 0) {
                                                    dispatch(toggleloginModal(true))
                                                }
                                                else navigate('/pricing');
                                            }} />
                                        </Tooltip>
                                        } />
                                </div>
                            </div>
                        ))}
                    </div>))}
                {groupIntoPairs(data?.filter((i) => i?.show !== false && i?.isFull === true))?.map((pair, j) => (
                    <div style={{ display: "grid", gridTemplateColumns: '1fr', alignContent: "space-evenly", }} key={j + 1}>
                        {pair.map((ele, i) => (
                            <div key={i} style={{
                                width: "100%", height: "100%",
                                padding: window.innerWidth > 760 ? "0.75rem 0.75rem" : "0.75rem 0.75rem", borderBottom: "1px solid var(--grey3)",
                                borderLeft: i !== 0 ? "1px solid var(--grey3)" : "",
                            }} className={ele?.helperText ? '' : 'showInfoOnHoverRow'}>
                                <div>
                                    <div className='textXS dark2' style={{ display: "flex", gap: "0.01rem", alignItems: "center" }}>
                                        <span className={ele?.dontEllipsis ? '' : 'textEllipsis1'} style={{ flex: 1 }}>{ele.label}</span>
                                        {ele?.showIcon !== false && (ele?.helperText ? <Tooltip title={ele?.helperText} arrow={false}>
                                            <AiOutlineInfoCircle style={{ marginLeft: "0.25rem", cursor: "pointer" }} className='infoIcon' />
                                        </Tooltip> : <AiOutlineInfoCircle style={{ marginLeft: "0.25rem", cursor: "pointer" }} onClick={() => {
                                            dispatch(toggleMetricDescModal({
                                                value: true,
                                                params: {
                                                    title: ele?.metric_name,
                                                    description: ele?.metric_desc
                                                }
                                            }))
                                        }} className='infoIcon' />)}
                                    </div>
                                    <AccessComponent component={<div className='textSM black w-500'>{ele?.valueToRender || ele?.value}</div>} reqAccessLevel={ele?.auth_flag}
                                        upgradeComponent={<Tooltip title={`${(ele?.auth_flag === 1 || userAccesslevel === 0) ? "Sign in" : ele?.auth_flag === 2 ? "Upgrade to Lite" : ele?.auth_flag === 3 ? "Upgrade to Pro" : "Upgrade to Black"} to view this metric`} arrow={false}>
                                            <MdOutlineLock color='var(--dark4)' size='1rem' cursor={'pointer'} onClick={() => {
                                                if (userAccesslevel === 0) {
                                                    dispatch(toggleloginModal(true))
                                                }
                                                else navigate('/pricing');
                                            }} />
                                        </Tooltip>
                                        } />
                                </div>
                            </div>
                        ))}
                    </div>))}
            </div> :
                type === 'list' ? <div>
                    {data?.map((ele, i) => (
                        <div key={i} className='d-flex align-items-center' style={{
                            gap: "0.75rem", padding: window.innerWidth > 760 ? "0.75rem 1rem" : "0.75rem 1.25rem",
                            background: i % 2 === 0 ? "var(--white)" : "var(--grey4)",
                            borderBottom: "1px solid var(--grey3)"
                        }}>
                            <DetailIcon width='2rem' height='2rem' padding='0.5rem' type={ele.labelString || ele.label} />
                            <div>
                                {headingType === 3 ? <h3 className='textXS dark2 margin0'>{ele.label}</h3> :
                                    <div className='textXS dark2'>{ele.label}</div>}
                                <div className='textMD black w-500'>
                                    {loading ? <Skeleton.Button active style={{ height: "1rem" }} /> : (ele?.valueToRender || ele?.value)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div> : null
            }
        </React.Fragment>
    )
}