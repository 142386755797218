import { HeatMap, ReturnsHistogram, RollingVol, SharpeChart, UnderWater } from "Components/BasketDetailComponents";
import CustomLabelValueCards from "Components/CustomLabelValue";
import { useUserDataHooks } from "CustomHooks/useUserDataHooks";
import { exportAntDTable, exportExcel } from "Libs/exportTable";
import { toggleHelpAndSupp } from "Libs/redux/UserAuth/reduxSlice";
import { displayValue, groupBy, isInvalidTextOrLength, sortFunds } from "Libs/utils";
import AdminServicesPY from "Services/api/AdminServicesPY";
import apiHelperPY from "Services/api/ApiHelperPY";
import { StockLivePrice } from "Services/api/LivePricesWebSocket";
import { Alert, Card, Collapse, Modal, Select, Spin, Table, Tag, Timeline, Tooltip } from "antd";
import Highcharts from 'highcharts';
import HighchartsHeatmap from "highcharts/modules/heatmap";
import React from "react";
import { Slide } from 'react-animated-components';
import toast from "react-hot-toast";
import { AiOutlineDownload } from "react-icons/ai";
import { BiDownload } from "react-icons/bi";
import { BsArrowLeft, BsArrowRight, BsFillQuestionCircleFill } from "react-icons/bs";
import { IoIosArrowDown, IoIosInformationCircle } from "react-icons/io";
import { TiDelete } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import AMCIcon from "./AMCIcon";
import ActivityLoaderMintbox from "./ActivityLoaderMintbox";
import { CurrencyFormat } from "./CurrencyFormat";
import CustomSegmentedTab from "./CustomSegmentedTab";
import CustomSideTabs from "./CustomSideTabs";
import { formatDateMMMDDYYYY, formatDateWithTime } from "./DateFormatter";
import { DecimalValueFormat } from "./DecimalValueFormat";
import Disclaimer from "./Disclaimer";
import NoBackTestData from "./NoBackTestData";
import { NoDataFound } from "./NoDataFound";
import PerfBenchChart from "./PerfBenchChart";
import RedGreenText from "./RedGreenText";
import ScreenSignalBacktestChart from "./ScreenSignalBacktestChart";
import { PlanName, SubscribeContentWrapper, SubscribeModalText, getUserAccessLimitFromAccessObj, useSubscribeMiddleware } from "./SubscribeModal";
import TableProgressBarFormat from "./TableProgessBarFormat";
import MyTable from "./TableWithScroll";
import { DescriptionInput } from "./atoms/DescriptionInput";
import { NameInput } from "./atoms/NameInput";
import { CardHeader } from "./atoms/SmallComponents";
import { toggleMetricDescModal } from "Libs/redux/KnowledgeBase/reduxSlice";
HighchartsHeatmap(Highcharts)

const SuperScreenerBacktestAndPaperTrade = ({
    backTestData = {},
    type = {},
    isAdvance = false,
    initial_capital = 100000,
    chartData = [],
    backtestConfig = {},
    viewType = "backtest",
    allFundsData = [],
    backTestModalLoading = true,
    xAxisKeyForPerfChart = 'Date',
    isSuperScreener = false,
    backtestType = 'screener',
    isPaperTradeAllowed = false,
    regenerateOrder = false,
    onPaperTradePressed = () => { },
    onBacktestPressed = () => { },
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    const user = useSelector((state) => state?.updateUserData?.value);
    const userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel || 0

    let userAccess = getUserAccessLimitFromAccessObj({
        accessObj: accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === 'CREDITS_PER_STRATEGY_IN_PAPERTRADE'),
        accessLevel: userAccesslevel
    });

    initial_capital = initial_capital || userAccess || 100000

    const [topTabType, setTopTabType] = React.useState('id1')
    const [tradeLog, setTradelog] = React.useState('id1')
    const [tableDrop, settableDrop] = React.useState('perf')

    const [weightMethodOptions, setweightMethodOptions] = React.useState()
    const [selectedWeightMethod, setselectedWeightMethod] = React.useState()

    const [isPaperTradeView, setisPaperTradeView] = React.useState(true)
    const [show, setShow] = React.useState(!(viewType === "backtest"));
    const [paperTradeData, setPaperTradeData] = React.useState({});

    const [screenedStocks, setScreenedStocks] = React.useState([])
    const [screenedCols, setScreenedCols] = React.useState([])
    const [userTotalStrategiesCount, setUserTotalStrategiesCount] = React.useState(0);
    const subscribeMiddle = useSubscribeMiddleware();
    const { getUserStrategies } = useUserDataHooks();
    // const [config, setConfig] = React.useState({})
    const isMobile = window.innerWidth < 760
    const genOrder = async () => {
        var config = await onPaperTradePressed()
        var res = await AdminServicesPY.getPaperTradeOrder({
            config: JSON.stringify(config),
            initial_capital: initial_capital || 100000,
        })
        setPaperTradeData(JSON.parse(res?.data))
        // setConfig(config)
        if (JSON.parse(res?.data)?.orders?.length > 0) {
            var orders = JSON.parse(res?.data)?.orders
            setScreenedStocks((orders || []).map((i) => {
                //console.log(i)
                return {
                    ...[...allFundsData].filter((ii) => (ii.symbol || ii?.nse_symbol || ii?.isin_code) === i.symbol)[0],
                    ...i,
                }
            }))

            setScreenedCols((Object?.keys(orders?.[0])?.filter((i) => !(['symbol', 'mcap', 'entry_price', 'min_inv_amount']?.includes(i))))?.map((item) => {
                //console.log(typeof (orders?.[0]?.[item]))
                return {
                    "dataIndex": item,
                    "key": item,
                    "metric_col_code": item,
                    "metric_unit": "",
                    "metric_data_type": isNaN(Number(orders?.[0]?.[item])) ? typeof (orders?.[0]?.[item]) : "float",
                    "metric_name": item,
                    "title": <span style={{ textTransform: "capitalize" }}>{String(item)?.replace("_", " ")}</span>,
                    "align": "center",
                    // "width": 150,
                }
            }))
        }

        setisPaperTradeView(false)
    }

    const onChange = async () => {
        setShow(!show);
        if (!show) {
            genOrder()
        } else {
            onBacktestPressed()
        }
    };

    const getUserStrategiesCount = async () => {
        try {
            let strategyRes = await getUserStrategies();
            if (strategyRes) {
                setUserTotalStrategiesCount(strategyRes?.userStrategies?.length || 0);
            }
            else {
                setUserTotalStrategiesCount(0);
            }
        }
        catch (err) {
            setUserTotalStrategiesCount(0);
        }
    }

    React.useEffect(() => {
        getUserStrategiesCount();
    }, [user, type?.value]);

    React.useEffect(() => {
        if (regenerateOrder)
            genOrder()
    }, [regenerateOrder])

    const WorstDrawdowns = () => {
        const dataSource = Object.values(backTestData?.perfromance_tables?.worst_5_drawdowns)

        const columns = [
            {
                title: 'Started on',
                dataIndex: 'Started',
                key: 'Started',
                width: 150,
                fixed: 'left'
            },
            {
                title: 'Drawdown',
                dataIndex: 'Drawdown',
                key: 'Drawdown',
                align: "right",
                render: (text) => DecimalValueFormat({ num: text, suffix: "%", placeholder: "-" })
            },
            {
                title: 'Lasted for (days)',
                dataIndex: 'Days',
                key: 'Days',
                align: "right",
            },
            {
                title: 'Recovered on',
                dataIndex: 'Recovered',
                key: 'Recovered',
                align: "right",
            }
        ]
        return <Table dataSource={dataSource} columns={columns} size={'small'} bordered className='textSM black' pagination={false} locale={{
            emptyText: <NoDataFound />
        }} />
    }

    const TableConstructor = ({ tableName = "", tableData = {}, showNameColumn = false, isTranspose = false }) => {
        if (isTranspose) {
            var dataSource = Object.values(tableData)
            var dataSourceKeys = Object.keys(tableData)

            var smDataSource = Object.keys(dataSource[0])
            smDataSource = smDataSource.map((key, index) => {
                var i = {
                    "key": key,
                    "name": key
                }
                dataSourceKeys.map((finalkey, index) => {
                    i = {
                        ...i,
                        [finalkey]: dataSource[index][key] !== '-' && dataSource[index][key] !== '+' && new Date(dataSource[index][key]) !== "Invalid Date" && !dataSource[index][key]?.includes("%") && !dataSource[index][key]?.includes(".") ? formatDateMMMDDYYYY(dataSource[index][key]) : dataSource[index][key]
                    }
                })
                return i
            })
            dataSourceKeys = ["name"]?.concat(dataSourceKeys)
            var columns = dataSourceKeys.map((key, index) => {
                return {
                    title: key === "name" ? "" : key,
                    dataIndex: key,
                    key: key,
                    width: 100,
                    align: key === "name" ? "left" : "right",
                }
            })
            return <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                {tableName && <CardHeader heading={tableName} headingType={2} />}
                <Table locale={{
                    emptyText: <NoDataFound />
                }} scroll={{ x: isTranspose ? 300 : 800 }} dataSource={smDataSource} columns={columns} bordered={false} pagination={false} />
            </div>
        } else {
            var dataSource = Object.values(tableData)
            var dataSourceKeys = Object.keys(tableData)

            if (showNameColumn) {
                dataSource = dataSource.map((item, index) => {
                    return {
                        ...item,
                        "col1_name_custom": dataSourceKeys[index]
                    }
                })
            }

            var columns = Object.entries(dataSource[0]).map(([key, val], index) => {
                return {
                    title: key,
                    dataIndex: key,
                    key: key,
                    width: 100,
                    align: () => {
                        if (new Date(val) !== "Invalid Date" || val.includes("%") || val.includes(".") || isNaN(Number(val))) {
                            return "right"
                        } else {
                            return "left"
                        }
                    },
                    fixed: key === "col1_name_custom" ? "left" : false,
                    render: (text) => {
                        if (new Date(text) !== "Invalid Date" && !text.includes("%") && !text.includes(".")) {
                            return formatDateMMMDDYYYY(text)
                        } else {
                            return text
                        }
                    }
                }
            })


            if (showNameColumn) {
                columns.splice(columns.indexOf((i) => i.key === "col1_name_custom"), 1)
                columns = [{
                    title: "",
                    width: 150,
                    dataIndex: "col1_name_custom",
                    key: "col1_name_custom",
                    fixed: "left",
                }]?.concat(columns)
            }
            return <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                {tableName && <CardHeader heading={tableName} headingType={2} />}
                <Table locale={{
                    emptyText: <NoDataFound />
                }} scroll={{ x: 800 }} bordered={false} dataSource={dataSource} columns={columns} pagination={false} />
            </div>
        }
    }

    const RebalanceData = ({ rebalData }) => {
        const dataSource = sortFunds(rebalData, "weights")

        const columns = [
            {
                title: 'Symbol',
                dataIndex: 'symbol',
                key: 'symbol',
                width: 150,
                fixed: 'left'
            },
            {
                title: 'Market cap',
                dataIndex: 'mcap',
                key: 'mcap',
                align: "right",
                render: (text) => DecimalValueFormat({ num: text, suffix: " Cr", placeholder: "-" })
            },
            {
                title: 'Weights',
                dataIndex: 'weights',
                key: 'weights',
                width: 410,
                align: "right",
                render: (text) => TableProgressBarFormat({ num: text * 100 })
            },
            // {
            //     title: 'Next day avg. price',
            //     dataIndex: 'next_day_price_avg',
            //     key: 'next_day_price_avg',
            //     align: "right",
            //     render: (text) => CurrencyFormat(Number(text))
            // },
            {
                title: 'No. of shares',
                dataIndex: 'num_shares',
                key: 'num_shares',
                align: "right",
            }
        ]
        return <Table locale={{
            emptyText: <></>
        }} dataSource={dataSource} columns={columns} size={'small'} bordered className='textSM black my-2' pagination={false} />
    }

    const tradeLogsColumns = [
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
            width: 350,
            fixed: 'left',
            render: (text, obj) => {
                return <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                    <span>{type?.value === 2 ? allFundsData?.filter((i) => i?.isin_code === text)?.[0]?.basic_name?.replace(" - Direct Plan", "") : text}</span>
                    <span>
                        <Tag className="textXS" color={"var(--grey2)"} style={{ fontSize: 10, color: "var(--dark4)" }}>{obj?.tag}</Tag>
                        <Tag className="textXS" color={obj?.direction === "BUY" ? "var(--greenShade)" : "var(--redShade)"} style={{ fontSize: 10, color: obj?.direction === "BUY" ? "var(--green)" : "var(--red)" }}>{String(obj?.direction)?.charAt(0)}</Tag>
                        {obj?.remark && <Tag className="textXS" color={"var(--grey2)"} style={{ fontSize: 10, color: "var(--dark4)" }}>{obj?.remark}</Tag>}
                    </span>
                </div>
            }
        },
        {
            title: 'Fill Price',
            dataIndex: 'fill_price',
            key: 'fill_price',
            align: "right",
            render: (text) => CurrencyFormat(Number(text))
        },
        {
            title: 'Order Quantity',
            dataIndex: 'order_quantity',
            key: 'order_quantity',
            // width: 410,
            align: "right",
            render: (text) => DecimalValueFormat({ num: text, suffix: "", placeholder: "-", decimals: type?.value === 2 ? 2 : 0 })
        },
        {
            title: 'Fill Quantity',
            dataIndex: 'fill_quantity',
            key: 'fill_quantity',
            // width: 410,
            align: "right",
            render: (text) => DecimalValueFormat({ num: text, suffix: "", placeholder: "-", decimals: type?.value === 2 ? 2 : 0 })
        },
        {
            title: 'Profit / Loss',
            dataIndex: 'pnl',
            key: 'pnl',
            align: "right",
            render: (text) => text ? <RedGreenText text={CurrencyFormat(Number(text))} value={Number(text)} /> : "-"
        },
        {
            title: 'Returns',
            dataIndex: 'ret',
            key: 'ret',
            align: "right",
            render: (text) => <RedGreenText text={DecimalValueFormat({ num: text, suffix: "%", placeholder: "-" })} value={Number(text)} />
        },
        {
            title: 'Non broker charges',
            dataIndex: 'statutory_charges',
            key: 'statutory_charges',
            align: "right",
            render: (text) => text ? CurrencyFormat(Number(text)) : "-"
        },
        {
            title: 'Brokerage',
            dataIndex: 'brokerage',
            key: 'brokerage',
            align: "right",
            render: (text) => text ? CurrencyFormat(Number(text)) : "-"
        },
    ]
    const TradeData = ({ rebalData }) => {
        const dataSource = sortFunds(rebalData, "order_quantity")
        console.log(dataSource)
        return <Table locale={{
            emptyText: <></>
        }} dataSource={dataSource} columns={tradeLogsColumns} scroll={{ x: 'max-content' }
        } size={'small'} bordered className='textSM black my-2' pagination={false} />
    }

    const CustomAccordionItem = ({ x }) => {

        const [isOpen, setIsOpen] = React.useState(x.index === 0 ? true : false)
        const [selectedTab, setselectedTab] = React.useState("stock")
        return <div id={`id${x.index}`} key={`id${x.index}`} >
            <div className="textSM w-500 black" >{x.header}</div>



            <div className="mt-3" style={{ border: "1px solid var(--grey1)", display: "flex", alignItems: "center", backgroundColor: "var(--dark4)", borderRadius: "4px" }}>
                <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div onClick={() => {
                        setselectedTab("stock")
                    }} style={{ flex: 1, alignItems: "center", padding: "1rem", display: "flex", flexDirection: "column", borderBottom: selectedTab === "stock" ? "4px solid var(--primary)" : "unset", cursor: "pointer" }}>
                        <span className="textSM white">Stocks</span>
                        <span className="textSM white w-500">{CurrencyFormat(x?.dataAll?.stock_value)}</span>
                    </div>
                    <div style={{ flex: 1, alignItems: "center", }}>
                        <div style={{ flex: 1, alignItems: "center", display: "flex", flexDirection: "column" }}>
                            <span className="textSM white">+</span>
                        </div>
                    </div>
                    <div style={{ flex: 1, alignItems: "center", padding: "1rem", display: "flex", flexDirection: "column", borderBottom: selectedTab === "cash" ? "4px solid var(--primary)" : "unset", cursor: "pointer" }}>
                        <span className="textSM white w-500">Cash</span>
                        <span className="textSM white w-500">{CurrencyFormat(x?.dataAll?.cash)}</span>
                    </div>
                    <div style={{ flex: 1, alignItems: "center", }}>
                        <div style={{ flex: 1, alignItems: "center", display: "flex", flexDirection: "column" }}>
                            <span className="textSM white">=</span>
                        </div>
                    </div>
                    <div style={{ flex: 2, alignItems: "center", padding: "1rem", display: "flex", flexDirection: "column" }}>
                        <span className="textSM white">Total</span>
                        <span className="textSM white w-500">{CurrencyFormat(x?.dataAll?.total)}</span>
                    </div>
                </div>
            </div>

            {selectedTab === "stock" && <RebalanceData rebalData={x?.dataAll?.stocks} />}

        </div>

    }

    const CustomAccordionItem2 = ({ x }) => {

        return <Collapse defaultActiveKey={[0]} expandIcon={({ isActive }) => <IoIosArrowDown style={{
            transition: "all 0.3s linear", transform: isActive ? "rotate(180deg)" : "rotate(0deg)", color: "var(--black)", fontSize: "0.875rem"
        }} />} className='tradeLogCollapse' expandIconPosition='end' items={[
            {
                key: x.index,
                label: <div className="textSM w-500 black" >{x.header}</div>,
                children: <TradeData rebalData={x?.dataAll} />,
            }
        ]} style={{ background: "var(--white)" }} bordered={false} />

    }

    const TradeLogs = () => {
        const result = tradeLog === "id1" ? groupBy(backTestData?.trade_log, "fill_timestamp") : groupBy(backTestData?.trade_log?.filter((i) => i?.tag === tradeLog), "fill_timestamp");

        return result !== undefined ?
            <Timeline items={Object.entries(result)?.reverse()?.map(([date, rebalData], index1) => {
                return {
                    children: <div>
                        <CustomAccordionItem2 x={{
                            "Date": date,
                            "header": "Trades on " + formatDateWithTime(date),
                            "key": date + "" + index1,
                            "index": index1,
                            "dataAll": rebalData
                        }}
                        />
                    </div>
                }
            })} />
            : <NoDataFound />

    }

    const tableItems = [
        {
            value: 'perf', label: 'Performance summary',
            children: <TableConstructor tableData={backTestData?.perfromance_tables?.perf_summary} showNameColumn={true} isTranspose={true} />
        },
        {
            value: 'drawdown', label: 'Worst 5 Drawdowns',
            children: <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                <WorstDrawdowns />
            </div>
        },
        {
            value: 'trailing', label: 'Trailing Returns',
            children: <TableConstructor tableData={backTestData?.perfromance_tables?.trailing_returns} showNameColumn={true} isTranspose={true} />
        },
        {
            value: 'calender', label: 'Calender year returns',
            children: <TableConstructor tableData={backTestData?.perfromance_tables?.cal_year_returns} showNameColumn={true} isTranspose={true} />
        },
    ]

    const tabItems = Object.keys(backTestData || {}).length !== 0 ? [
        {
            label: `Performance`,
            key: "id1",
            value: "id1",
            children: (
                <div style={{ flex: 1, padding: "1.25rem 0rem", display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "flex-start", gap: "2rem", justifyContent: "flex-start" }}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1rem", flex: 1.5 }}>
                        <CardHeader heading={'Portfolio vs Benchmark performance'} headingType={2} />
                        {isSuperScreener ? <ScreenSignalBacktestChart portfolioType={isSuperScreener ? 12 : 11} defAmtSelect={isSuperScreener ? initial_capital : ""} defYrSelect={isSuperScreener ? "365D" : "1095D"} chartData={isSuperScreener ? chartData : backTestData?.perfromance_against_bm?.prices} xAxisKey={xAxisKeyForPerfChart} fundName="Portfolio" /> :
                            <PerfBenchChart portfolioType={isSuperScreener ? 12 : 11} defAmtSelect={isSuperScreener ? initial_capital : ""} defYrSelect={isSuperScreener ? "max" : "1095D"} chartData={isSuperScreener ? chartData : backTestData?.perfromance_against_bm?.prices} xAxisKey={xAxisKeyForPerfChart} fundName="Portfolio" />}

                        {isSuperScreener && <div>
                            <CardHeader heading={'Trade Summary:'} headingType={3} />
                            <div style={{ marginTop: "1rem" }}>
                                {/* {JSON.stringify(backTestData?.trade_summary)} */}
                                <CustomLabelValueCards minWidth="10.6rem" headingClassName={"textXS w-500"} padding={"0.5rem 0.8rem"} x={[
                                    { key: 0, heading: "Total trades", value: DecimalValueFormat({ num: backTestData?.trade_summary?.total_trades, decimals: 0 }), helperText: "Total number of trades including buy and subsequent sell. For example, if 100 shares of reliance are bought and 100 sold, it will constitute 1 trade. If sold in 2 lots of 50, it will constitute 2 trades." },
                                    { key: 1, heading: "Total winning trades", value: <span>{DecimalValueFormat({ num: backTestData?.trade_summary?.total_winning_trades, decimals: 0 })} ({DecimalValueFormat({ num: backTestData?.trade_summary?.average_win_per, suffix: "%" })})</span>, helperText: "Number of trades where profit was made - selling price greater than average entry price." },
                                    { key: 2, heading: "Total losing trades", value: <span>{DecimalValueFormat({ num: backTestData?.trade_summary?.total_losing_trades, decimals: 0 })} ({DecimalValueFormat({ num: backTestData?.trade_summary?.average_loss_per, suffix: "%" })})</span>, helperText: "Number of trades that incurred losses - selling price less than average entry price." },
                                    { key: 4, heading: "Average loss rate", value: DecimalValueFormat({ num: backTestData?.trade_summary?.average_loss_rate, suffix: "%" }), helperText: "Average return of losing trades" },
                                    { key: 6, heading: "Average win rate", value: DecimalValueFormat({ num: backTestData?.trade_summary?.average_win_rate, suffix: "%" }), helperText: "Average return of winning trades" },

                                    { key: 7, heading: "Non broker charges", value: CurrencyFormat(backTestData?.trade_summary?.statutory_charges), helperText: "Total costs other than brokerage including STT, DP charges, Exchange charges, Stamp Duty and GST" },
                                    { key: 8, heading: "Total brokerage", value: CurrencyFormat(backTestData?.trade_summary?.brokerage), helperText: "Total brokerage paid for all the transactions." },
                                    { key: 9, heading: "Transaction cost", value: CurrencyFormat(backTestData?.trade_summary?.total_transaction_cost), helperText: "Total transaction cost including brokerage and non-broker charges" },
                                ]} />
                            </div>
                        </div>}
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <CardHeader heading={'Showing:'} headingType={3} />
                            <Select
                                style={{ width: '13rem' }}
                                value={tableDrop}
                                onChange={(e) => {
                                    settableDrop(e)
                                }}
                                options={tableItems}
                            />
                        </div>
                        {tableItems?.filter((i) => i?.value === tableDrop)?.[0]?.children}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1rem", flex: 1, width: '100%' }}>
                        <TableConstructor tableName='Performance metrics' tableData={backTestData?.perfromance_tables?.perf_metrics} showNameColumn={true} isTranspose={true} />
                    </div>
                </div>
            ),
        }, {
            label: `Performance charts`,
            key: "id11",
            value: "id11",
            children: <CustomSideTabs tabData={[
                {
                    key: '1',
                    label: 'Distribution of monthly returns',
                    children: <ReturnsHistogram histData={backTestData?.perfromance_charts?.histogram_monthly_rets} isCard={false} />,
                    show: true,
                },
                {
                    key: '2',
                    label: 'Monthly returns heatmap',
                    right: <button className="btnWhite" onClick={() => {
                        var heatmapdata = backTestData?.perfromance_charts?.heatmap
                        var years = backTestData?.perfromance_charts?.heatmap.map((data) => { return data.year })
                        var months = Object.keys(backTestData?.perfromance_charts?.heatmap[0]).slice(1)
                        var finalWS = []
                        var header = ['Months/Years', ...years]
                        finalWS = [header]
                        var dataFinal = months.map((month, index) => {
                            var row = [month]
                            years.map((year, yindex) => {
                                row.push(Number((heatmapdata[yindex][month] * 100).toFixed(1)))
                            })
                            finalWS.push(row)
                        })
                        exportExcel({ headers: header, wsData: finalWS, fileDefaultName: "HeatMapData" })
                    }}><BiDownload /></button>,
                    children: <HeatMap data={backTestData?.perfromance_charts?.heatmap} isCard={false} />,
                    show: true,
                },
                {
                    key: '3',
                    label: '6-month rolling volatility',
                    children: <RollingVol data={backTestData?.perfromance_charts?.rolling_vol} isCard={false} changeObjSecondKey='date' />,
                    show: true,
                },
                {
                    key: '4',
                    label: '6-month rolling Sharpe ratio',
                    children: <SharpeChart data={backTestData?.perfromance_charts?.rolling_sharpe} isCard={false} changeObjSecondKey='date' />,
                    show: true,
                },
                {
                    key: '5',
                    label: 'Underwater plot',
                    children: <UnderWater data={backTestData?.perfromance_charts?.underwater} isCard={false} datakey="portfolio" changeObjSecondKey='date' />,
                    show: true,
                }
            ]} />

        }, isSuperScreener ? {
            label: `Trade logs`,
            key: "id2",
            value: "id2",
            children: <div className='my-4'><TradeLogs /></div>
        } : {
            label: `Rebalance history`,
            key: "id2",
            value: "id2",
            children: (
                <div className='my-4'>
                    {backTestData?.[isSuperScreener ? "rebal_history" : "rebal_hsitory"] !== undefined ?
                        <Timeline items={Object.entries(backTestData?.[isSuperScreener ? "rebal_history" : "rebal_hsitory"])?.filter(([date, rebalData], index) => formatDateMMMDDYYYY(date) === selectedWeightMethod)?.map(([Date, rebalData], index1) => {
                            return {
                                children: <div>
                                    <CustomAccordionItem x={{
                                        "Date": Date,
                                        "header": "Portfolio as on " + formatDateMMMDDYYYY(Date),
                                        "rebalData": rebalData?.stocks,
                                        "key": Date + "" + index1,
                                        "cash": CurrencyFormat(rebalData?.cash),
                                        "index": index1,
                                        "dataAll": rebalData
                                    }}
                                    />
                                </div>
                            }
                        })} />
                        : <NoDataFound />}
                </div>
            ),
        }, {
            label: `Methodology`,
            key: "id4",
            value: "id4",
            children: (
                <div className='my-4'>
                    <Disclaimer val={8} />
                </div>
            ),
        }] : [];


    React.useEffect(() => {
        if (!backTestModalLoading) {
            var weightMethodOptions = !backTestModalLoading && Object.keys(backTestData?.rebal_hsitory || {})?.map((date) => {
                return formatDateMMMDDYYYY(date)
            })

            weightMethodOptions = !backTestModalLoading && [...new Set(weightMethodOptions)];
            weightMethodOptions = !backTestModalLoading && weightMethodOptions?.map((yr) => {
                return {
                    "key": yr,
                    "value": yr,
                    "label": yr
                }
            }).reverse()

            setweightMethodOptions(weightMethodOptions)
            setselectedWeightMethod(weightMethodOptions?.[0]?.value)
        }
    }, [backTestModalLoading])


    const placeOrderAndSaveStrategy = async (name, desc) => {
        var config = await onPaperTradePressed()
        var res = await AdminServicesPY.placeOrderAndSaveStrategy({
            config: JSON.stringify(config?.instrument_selection === 1 ? { ...config, 'strategy_name': name, 'strategy_desc': desc } : { ...config, 'screen_level': isAdvance ? 1 : 0, 'strategy_name': name, 'strategy_desc': desc }),
            orders: JSON.stringify(paperTradeData?.orders)
        }).then((r) => r)
        if (res?.type) {
            await apiHelperPY({ apiName: "getAllStrategiesByClientId", data: { clientId: user.id }, getFresh: true })
            toast.success(JSON.parse(res?.data)?.message)
            navigate('/strategies')
        } else {
            toast.error("Something went wrong!!")
        }
        // console.log(config)
    }


    const BacktestPanel = React.memo(() => {
        return <div
        >
            {backTestModalLoading ? <div className={isSuperScreener ? "" : 'loaderContainer'} style={isSuperScreener ? {} : {
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                position: 'fixed',
            }}>{chartData?.length > 0 ? <PerfBenchChart isWebSocket={true} portfolioType={11} defAmtSelect={isSuperScreener ? initial_capital : ""} defYrSelect={isSuperScreener ? "max" : "1095D"} chartData={isSuperScreener ? {
                "max": {
                    data: JSON.stringify([...chartData]?.map((i, index) => {
                        var dataFinal = [...chartData]
                        var changePer = index === 0 ? 0 : ((dataFinal[index]?.Portfolio - dataFinal[0]?.Portfolio) / dataFinal[0]?.Portfolio) * 100
                        var changePer2 = index === 0 ? 0 : ((dataFinal[index]?.Benchmark - dataFinal[0]?.Benchmark) / dataFinal[0]?.Benchmark) * 100
                        return {
                            ...i,
                            Portfolio: i?.Portfolio * Number((initial_capital / dataFinal?.[0]?.Portfolio)?.toFixed(2)),
                            Benchmark: i?.Benchmark * Number((initial_capital / dataFinal?.[0]?.Benchmark)?.toFixed(2)),
                            ret_Portfolio: changePer?.toFixed(2),
                            ret_Benchmark: changePer2?.toFixed(2),
                        }
                    }))
                }
            } : backTestData?.perfromance_against_bm?.prices} xAxisKey={xAxisKeyForPerfChart} fundName="Portfolio" /> :
                <ActivityLoaderMintbox message={backtestType === 'screener' ? "Hang on….heavy lifting in progress! We simulate this screen over more than 10 years of historical data to get you the back tested performance." : "Hang on….heavy lifting in progress! We simulate this factor model over more than 10 years of historical data to get you the back tested performance."} />
                }
            </div> : Object.keys(backTestData || {}).length !== 0 ? tabItems.filter((i) => i?.value === topTabType)?.[0]?.children : <NoBackTestData val={backtestType === 'screener' ? 4 : 5} />}
        </div>
    });

    const PaperTradePanel = React.memo(() => {

        const [showSaveModalMobile, setShowSaveModalMobile] = React.useState(false)
        let strategySaveAccess = subscribeMiddle({ moduleKey: "BUILD,_BACKTEST_AND_PAPERTRADE", getDetailedAccessData: true, checkLimit: true, usageCount: userTotalStrategiesCount });

        const SaveCard = () => {
            const [mintboxName, setMintboxName] = React.useState('');
            const [mintboxDesc, setMintboxDesc] = React.useState('');
            return <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                <div className='textSM'>{paperTradeData?.message}</div>
                <div className='textMD w-700 mt-2'>Let’s give your strategy a name</div>
                {strategySaveAccess?.maxLimit !== Infinity && <div>
                    <div style={{ userSelect: "none" }}>
                        <span className="textXS dark3 w-500">Credits: <span className='dark4 w-700'>
                            <b style={{ color: strategySaveAccess?.remainingLimit === 0 ? "red" : "var(--primary)" }}>{strategySaveAccess?.remainingLimit}</b>
                            <b>/{strategySaveAccess?.maxLimit}</b></span>.&nbsp;
                            {strategySaveAccess?.currentAccessLevel !== 4 && <span>Upgrade to <Link className="dark4 w-700" to='/pricing' target="_blank">
                                <PlanName accessLevel={strategySaveAccess?.currentAccessLevel + 1} /></Link>.</span>}
                        </span>
                    </div>
                </div>}
                {/* <div className='textSM w-500'>What would you like to call it?</div> */}
                <div style={{ marginTop: "2%", marginBottom: "2%", width: isMobile ? "100%" : "70%" }}>
                    <NameInput changeValueOnChange placeholder='E.g. Multibagger Strategy (min. 4 char)' value={mintboxName} onChange={(e) => {
                        let val = String(e);
                        setMintboxName(val)
                    }} />
                </div>
                <div>
                    <DescriptionInput label='Description' value={mintboxDesc} onChange={(e) => {
                        setMintboxDesc(e);
                    }} />
                </div>
                <div className="mb-2">
                    <SubscribeContentWrapper moduleKey={type?.value === 4 ? "BUILD,_BACKTEST_AND_PAPERTRADE" : type?.value === 1 ? "BUILD,_BACKTEST_AND_PAPERTRADE_ETFs" : "BUILD,_BACKTEST_AND_PAPERTRADE_MF"}
                        onClick={() => {
                            if (mintboxName?.length >= 4 && mintboxDesc?.length >= 15) {
                                placeOrderAndSaveStrategy(mintboxName, mintboxDesc)
                            } else {
                                toast.error("Please enter proper name for your strategy")
                            }
                        }} checkLimit usageCount={userTotalStrategiesCount} disabled={isInvalidTextOrLength({ text: mintboxName, checkLength: true }) || isInvalidTextOrLength({ text: mintboxDesc?.length, checkTextChars: false, checkLength: true, length: 15 })} wrapStyle={{ width: "fit-content" }}>
                        <button className='btnPrimary align-items-center' style={{
                            marginTop: '0.5rem',
                            gap: "0.5rem",
                            alignItems: "center",
                            alignContent: "center"
                        }} disabled={isInvalidTextOrLength({ text: mintboxName, checkLength: true }) || isInvalidTextOrLength({ text: mintboxDesc?.length, checkTextChars: false, checkLength: true, length: 15 })}>Execute order <BsArrowRight />
                        </button>
                    </SubscribeContentWrapper>
                </div>
                <div className='textXS' style={{}}>
                    <b>Disclaimer: </b>
                    {paperTradeData?.disclaimer}
                </div>
            </div>
        }
        return <div>
            {isPaperTradeView ?
                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginTop: 40, alignItems: "center", justifyContent: "center" }}>
                    <Spin />
                </div>
                : screenedStocks?.length > 0 ? <div style={{ display: "flex", flex: 1, flexDirection: "column", gap: "2rem", alignItems: "flex-start", paddingTop: '1rem' }}>
                    {/* {screenedStocks?.[0]?.["min_inv_amount"]}
                    {(initial_capital || 100000)} */}
                    {/* {((userAccess || 100000) <= screenedStocks?.[0]?.["min_inv_amount"]) || (screenedStocks?.[0]?.["min_inv_amount"] > (initial_capital || 100000)) ? <></> :
                        <></>} */}
                    {/* checkLimit usageCount={userTotalStrategiesCount} */}

                    {((userAccess || 100000) <= screenedStocks?.[0]?.["min_inv_amount"]) ? <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                        <div className="textMD">
                            Minimum amount required to invest in this strategy is <span className="w-bold">{CurrencyFormat(screenedStocks?.[0]?.["min_inv_amount"], "long", 0)}</span> which is more than the strategy credit of <span className="w-bold">{CurrencyFormat((userAccess || 100000), "long", 0)}</span> as per your subscription. You can do the following:

                            <ul className="mt-2" style={{ padding: "0 0 0 16px", gap: "0.2rem" }}>
                                <li>Upgrade your plan to get higher credits per strategy. <Link target="_blank" className="primary" to={'https://sharpely.in/pricing'}>Explore plans</Link></li>
                                <li>See if a stock with high share price has low weightage in your strategy.</li>
                                <ul>
                                    <li>You can either remove this stock or</li>
                                    <li>Change weight methodology to increase its weightage</li>
                                </ul>
                                <li>If you are still not able to proceed, you can get in touch with us <span className="primary" onClick={() => {
                                    dispatch(toggleHelpAndSupp(true))
                                }}>contact us</span> and we will help you to set up your strategy.</li>
                            </ul>


                        </div>
                    </div> : (screenedStocks?.[0]?.["min_inv_amount"] > (initial_capital || 100000)) ? <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                        <div className="textMD">
                            Minimum amount required to invest in this strategy is <span className="w-bold">{CurrencyFormat(screenedStocks?.[0]?.["min_inv_amount"], "long", 0)}</span> which is more than initial capital selected by you.
                        </div>
                    </div> : <div style={{ display: "flex", flexDirection: isMobile ? "column-reverse" : "row", gap: "1rem", marginBottom: '2rem', width: '100%' }}>
                        <Card bordered style={{ flex: 1, padding: '0.75rem' }} bodyStyle={{ padding: 0 }}>
                            <div style={{ padding: '0.5rem 0.75rem' }}>
                                <CardHeader
                                    heading={!String(paperTradeData?.message)?.includes("on market open") ? "You will be creating a paper traded strategy. Here are your virtual orders that will be executed now" : "You will be creating a paper traded strategy. Here are your virtual orders that will be executed at next market open."}
                                    textClassName="textMD w-500"
                                />
                            </div>
                            {
                                isMobile && <button className='btnWhite align-items-center' style={{
                                    marginTop: '0.5rem',
                                    marginBottom: '1.5rem',
                                    gap: "0.5rem",
                                    alignItems: "center",
                                    alignContent: "center",
                                    alignSelf: 'flex-end'
                                }} onClick={() => setShowSaveModalMobile(true)}>Save & Execute order <BsArrowRight />
                                </button>
                            }
                            <MyTable
                                loading={isPaperTradeView}
                                dataSource={screenedStocks}
                                columns={[{
                                    "title": "Name",
                                    'disableDeleteInCustomize': true,
                                    "dataIndex": type?.value === 4 ? "proper_name" : "basic_name",
                                    render: (text, record, index) => <Tooltip title={record.proper_name || record.basic_name} arrow={false}>
                                        <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center" }}>
                                            <AMCIcon amcName={type.value !== 2 ? record.symbol || record.amc_full_name : record.amc_full_name} fundType={type?.value} height='1.15rem' width="1.15rem" bseTicker={record?.bse_ticker} />
                                            <div>
                                                <div className="w-500 black" style={{ width: 180, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{record?.proper_name || record.basic_name}</div>
                                                {type.value !== 2 ? <StockLivePrice fundData={record} format={10} symbol={record?.symbol || record?.nse_symbol} /> : <span className='textXS dark3'>NAV: {CurrencyFormat(record?.latest_nav)}</span>}
                                            </div>
                                        </div>
                                    </Tooltip>,
                                    width: 150,
                                    fixed: window.innerWidth > 760 ? 'left' : "",
                                    show: true
                                }, ...screenedCols.map((col) => {
                                    return {
                                        ...col,
                                        // width: 150,
                                        "render": (text, record, index) => <div style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "center" }}>
                                            {displayValue({
                                                text, metricObj: col
                                            })}
                                        </div>
                                    }
                                })]}
                                rowKey={(record) => record.plan_id || record.symbol}
                                scroll={{ x: 'max-content' }}
                            />
                        </Card>
                        {!isMobile && <Card bordered style={{ flex: 1, minWidth: '40%', height: 'fit-content' }}>
                            <SaveCard />
                        </Card>}
                        {
                            isMobile && showSaveModalMobile && <Modal centered closable={false} styles={{ body: { padding: '0' } }}
                                open={showSaveModalMobile} onCancel={() => setShowSaveModalMobile(false)} footer={null}>
                                <SaveCard />
                            </Modal>
                        }
                    </div>}
                </div>
                    : <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", }}>
                        <div>{paperTradeData?.message}</div>
                    </div>}
        </div >
    });

    return <div id="BacktestOrPaperTradeContainer">

        <div style={{ display: "flex", alignItems: isMobile ? "stretch" : "center", flexDirection: isMobile ? topTabType === 'id2' ? 'column' : 'column-reverse' : 'row', gap: '1rem' }}>
            {!backTestModalLoading && <div style={{ flex: 1 }}>
                {show ? <button className='btnPrimary d-flex align-items-center mb-2' style={{
                    gap: "0.5rem",
                    alignItems: "center",
                    alignContent: "center"
                }} onClick={() => onChange()}><BsArrowLeft /> Review backtest</button>
                    : <CustomSegmentedTab isBlock={false} options={tabItems} value={topTabType}
                        onChange={(e) => setTopTabType(e)} />}
            </div>}
            {(!show && topTabType === "id1") && !backTestModalLoading && isPaperTradeAllowed && <SubscribeContentWrapper
                moduleKey={type?.value === 4 ? "BUILD,_BACKTEST_AND_PAPERTRADE" : type?.value === 1 ? "BUILD,_BACKTEST_AND_PAPERTRADE_ETFs" : "BUILD,_BACKTEST_AND_PAPERTRADE_MF"}
                onClick={() => onChange()}>
                <button id="PaperTradeNowBtn" className='btnPrimary d-flex align-items-center' style={{
                    gap: "0.5rem",
                    alignItems: "center",
                    alignContent: "center"
                }} onClick={() => { }}>Paper trade now <BsArrowRight /></button>
            </SubscribeContentWrapper>}
            {topTabType === "id2" && (isSuperScreener ? <>
                <CustomSegmentedTab isBlock={false} options={[
                    {
                        label: `All`,
                        key: "id1",
                        value: "id1",
                        children: <></>
                    },
                    ...[...new Set(backTestData?.trade_log?.map(item => item.tag))]?.map((i) => {
                        return {
                            label: i,
                            key: i,
                            value: i,
                            children: <></>
                        }
                    }),
                    {
                        label: <Tooltip arrow={false} title={`Export trade logs`}><AiOutlineDownload size={15} /></Tooltip>,
                        key: "id11",
                        value: "id11",
                        children: <></>
                    }
                ]}
                    value={tradeLog}
                    onChange={(e) => {
                        if (e === "id11") {
                            exportAntDTable({
                                columns: [
                                    ...tradeLogsColumns,
                                    {
                                        title: 'Date',
                                        dataIndex: 'date',
                                        key: 'date',
                                        align: "right"
                                    }],
                                dataSource: Object.entries(groupBy(backTestData?.trade_log, "fill_timestamp"))?.reverse()?.map(([date, rebalData], index1) => {
                                    return sortFunds(rebalData?.map((i) => {
                                        return {
                                            ...i,
                                            date: date
                                        }
                                    }), "order_quantity")
                                })?.flat(1),
                                getValueOnly: true
                            })
                        } else {
                            setTradelog(e)
                        }
                    }} />
            </> : <Select removeIcon={<TiDelete size={12} />}
                className='textSM black'
                suffixIcon={<IoIosArrowDown size={15} />}
                style={{
                    width: "150px"
                }}
                defaultValue={selectedWeightMethod}
                placeholder="Please select portfolio year"
                onChange={(value) => {
                    setselectedWeightMethod(value)
                }}
                options={weightMethodOptions}
            />
            )}
        </div>

        <div
        >
            {backTestModalLoading ? <div className={isSuperScreener ? "" : 'loaderContainer'} style={isSuperScreener ? {} : {
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                position: 'fixed',
            }}>{chartData?.length > 0 ? <PerfBenchChart isWebSocket={true} portfolioType={11} defAmtSelect={isSuperScreener ? initial_capital : ""} defYrSelect={isSuperScreener ? "max" : "1095D"} chartData={isSuperScreener ? {
                "max": {
                    data: JSON.stringify([...chartData]?.map((i, index) => {
                        var dataFinal = [...chartData]
                        var changePer = index === 0 ? 0 : ((dataFinal[index]?.Portfolio - dataFinal[0]?.Portfolio) / dataFinal[0]?.Portfolio) * 100
                        var changePer2 = index === 0 ? 0 : ((dataFinal[index]?.Benchmark - dataFinal[0]?.Benchmark) / dataFinal[0]?.Benchmark) * 100
                        return {
                            ...i,
                            Portfolio: i?.Portfolio * Number((initial_capital / dataFinal?.[0]?.Portfolio)?.toFixed(2)),
                            Benchmark: i?.Benchmark * Number((initial_capital / dataFinal?.[0]?.Benchmark)?.toFixed(2)),
                            ret_Portfolio: changePer?.toFixed(2),
                            ret_Benchmark: changePer2?.toFixed(2),
                        }
                    }))
                }
            } : backTestData?.perfromance_against_bm?.prices} xAxisKey={xAxisKeyForPerfChart} fundName="Portfolio" /> :
                <ActivityLoaderMintbox message={backtestType === 'screener' ? "Hang on….heavy lifting in progress! We simulate this screen over more than 10 years of historical data to get you the back tested performance." : "Hang on….heavy lifting in progress! We simulate this factor model over more than 10 years of historical data to get you the back tested performance."} />
                }
            </div> : <></>}
        </div>

        {!backTestModalLoading ? show ? <Slide
            left={show}
            durationMs={500}
        >
            <PaperTradePanel key={"papertrade"} />
        </Slide> : <Slide
            right={!show}
            durationMs={500}
        >
            {/* <BacktestPanel /> */}
            <div key={"backtest"}>
                {backTestData?.initial_capital_breached === 1 && <Alert
                    className="mt-4 my-2"
                    message={<span className="textSM">
                        The minimum investment required at the time of start of back test is larger than the initial capital. This may lead to inaccurate results since portfolio might not have been constructed as desired (<span className="textSM primary" style={{ cursor: "pointer" }} onClick={() => {
                            dispatch(toggleMetricDescModal({
                                value: true,
                                params: {
                                    title: "What happens when initial capital is not enough?",
                                    description: `<p>Suppose your initial capital is 1,00,000 and your strategy is to buy 2 stocks – MRF and TCS with 20% weight to MRF and 80% weight to TCS. Suppose you start your back tests from 1st April 2021.</p><br>
 
                                    <p>Based on prices on 1st April 2021, the minimum investment amount required to build this portfolio with 20:80 weightage was Rs 4,15,665 since MRF price was 83133. This would have ensured that we bought 1 share of MRF and 105 shares of TCS such that weights would have been 20% and 80% respectively. However, with an initial capital of 1,00,000, MRF cannot be purchased since it would completely throw off the weighting scheme. Hence, the back test would just buy 25 shares of TCS (worth roughly Rs 80,000 or 80% of total capital) and keep 20,000 in cash.</p><br>
                                     
                                    <p>Obviously this is not what the actual strategy logic is. Hence, the back test result may not be an accurate representation of actual strategy performance if initial capital is less than minimum investment requirement.</p><br>
                                     
                                    <p>In the above example, if we run the back test with initial capital of Rs 1 lac, our total portfolio grows to Rs 1,16,899 from 1st April 2021 to 17th May 2024 (total growth 16.9%). On the other hand, if we run the same back test with an initial capital of Rs 10 lac, our total portfolio grows to 12.79 lacs (total growth of 27.9%)</p>`
                                }
                            }))
                        }}>learn more</span>). Try increasing the initial capital amount by <SubscribeModalText str={'upgrading to higher plans'} accessKey="CREDITS_PER_STRATEGY_IN_PAPERTRADE" showLockIcon={false} style={{ color: "var(--primary)" }} />.
                    </span>}
                    type="info"
                    showIcon
                />}
                {backTestModalLoading ? <div className={isSuperScreener ? "" : 'loaderContainer'} style={isSuperScreener ? {} : {
                    margin: 'auto',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    position: 'fixed',
                }}>{chartData?.length > 0 ? <PerfBenchChart isWebSocket={true} portfolioType={11} defAmtSelect={isSuperScreener ? initial_capital : ""} defYrSelect={isSuperScreener ? "max" : "1095D"} chartData={isSuperScreener ? {
                    "max": {
                        data: JSON.stringify([...chartData]?.map((i, index) => {
                            var dataFinal = [...chartData]
                            var changePer = index === 0 ? 0 : ((dataFinal[index]?.Portfolio - dataFinal[0]?.Portfolio) / dataFinal[0]?.Portfolio) * 100
                            var changePer2 = index === 0 ? 0 : ((dataFinal[index]?.Benchmark - dataFinal[0]?.Benchmark) / dataFinal[0]?.Benchmark) * 100
                            return {
                                ...i,
                                Portfolio: i?.Portfolio * Number((initial_capital / dataFinal?.[0]?.Portfolio)?.toFixed(2)),
                                Benchmark: i?.Benchmark * Number((initial_capital / dataFinal?.[0]?.Benchmark)?.toFixed(2)),
                                ret_Portfolio: changePer?.toFixed(2),
                                ret_Benchmark: changePer2?.toFixed(2),
                            }
                        }))
                    }
                } : backTestData?.perfromance_against_bm?.prices} xAxisKey={xAxisKeyForPerfChart} fundName="Portfolio" /> :
                    <ActivityLoaderMintbox message={backtestType === 'screener' ? "Hang on….heavy lifting in progress! We simulate this screen over more than 10 years of historical data to get you the back tested performance." : "Hang on….heavy lifting in progress! We simulate this factor model over more than 10 years of historical data to get you the back tested performance."} />
                    }
                </div> : Object.keys(backTestData || {}).length !== 0 ? tabItems.filter((i) => i?.value === topTabType)?.[0]?.children : <NoBackTestData val={backtestType === 'screener' ? 4 : 5} />}
            </div>
        </Slide> : <></>}


    </div>

};



export default SuperScreenerBacktestAndPaperTrade



