import { isInvalidTextOrLength } from "Libs/utils";
import { Input } from "antd";
import React from "react";

export const NameInput = React.memo(({ label = '', labelClassName = '', labelStyle = {}, value, onChange = () => { }, placeholder = '', size = 'large',
    errorLabel = '* Special characters are not allowed', errorLabelStyle = {}, errorLabelClassName = '', changeValueOnChange = false }) => {

    const [name, setName] = React.useState(value);

    React.useEffect(() => {
        if (value !== name) {
            setName(value);
        }
    }, [value]);

    return (
        <React.Fragment>
            <div className="">
                {label && <label className={`textXS dark3 w-500 mb-2 ${labelClassName}`} style={{ ...labelStyle }} >{label}</label>}
                <Input className="textMD" placeholder={placeholder} size={size} value={name}
                    style={{ padding: window.innerWidth < 760 ? '0.9rem' : '' }}
                    status={isInvalidTextOrLength({ text: name }) ? "error" : ""} onChange={(e) => {
                        let val = String(e.target.value);
                        setName(val);
                        if (changeValueOnChange) {
                            onChange(val)
                        }
                    }} onBlur={() => {
                        if (name) {
                            onChange(String(name))
                        }
                    }} onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            if (name) {
                                onChange(String(name));
                            }
                            e.target.blur();
                        }
                    }} />
                {isInvalidTextOrLength({ text: name }) ? <label style={{ color: "var(--red)", textAlign: "right", ...errorLabelStyle }}
                    className={`textXS w-500 mt-1 w100 ${errorLabelClassName}`}>
                    {errorLabel}
                </label> : null}
            </div>
        </React.Fragment>
    )
})