import { baseImgURL } from 'Assets/Constant';
import { useIsMobile } from 'CustomHooks/useIsMobile';

export const PublisherSection = () => {

    const isMobile = useIsMobile();

    return <div style={{
        height: '100%', width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', flex: 1,
        // backgroundImage: "linear-gradient(180deg, #09203f 0%, #000 100%)",
        // backgroundSize: "100%",
    }} className='padAllCampaign'>
        <div style={{
            display: 'flex', flexDirection: 'row', flex: 1,
            justifyContent: 'space-between', height: '100%', width: '100%',
            gap: "4rem"
        }} className='d-flex align-items-center justify-content-between flex-sm-row flex-column'>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1.5 }}>
                <h2 className='headLGtoXS margin0' style={{ fontWeight: 700, color: 'rgba(255, 255, 255, 0.8)' }}>
                    Are you an <span className='' style={{ color: "gold" }}>expert stock investor?</span> Start earning by publishing for sharpely community
                </h2>
                <div className='textMDtoESM dark2' style={{ width: '100%', paddingTop: "1.5rem" }}>
                    You can activate your publisher profile and publish your screens, models, and strategies for
                    the community. You get paid every time someone clones your published content!
                </div>
            </div>
            <div style={{ flex: 1, alignSelf: 'center', }}>
                <img loading="lazy" src={`${baseImgURL}sharpely-asset/images/Home/dash-earnings.webp`} alt="publisher profile"
                    style={{
                        borderTopRightRadius: '6px', borderTopLeftRadius: '6px', boxShadow: 'inset 10 10px 10px red',
                        width: isMobile ? "100%" : "80%"
                    }} />
            </div>
        </div>
    </div>
}
