import { Card, Divider } from 'antd';
import { CardHeader } from 'Components/atoms/SmallComponents';
import { formatDateMMMDDYYYY } from "Components/DateFormatter";
import { Link } from 'react-router-dom';

export const FeaturedBlogs = ({ featuredBlogs = [], showDivider = true, loading }) => {

    const Loader = () => {
        return (
            <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                {new Array(4)?.fill(1)?.map((el, i) => {
                    return (
                        <div key={i}>
                            <div className='w100' style={{ height: "0.75rem", background: "var(--grey3)", borderRadius: "var(--borderRadius)" }} ></div>
                            <div className='w100 mt-1' style={{ height: "0.75rem", background: "var(--grey3)", borderRadius: "var(--borderRadius)" }} ></div>
                            <div className='mt-1' style={{ height: "0.75rem", background: "var(--grey3)", borderRadius: "var(--borderRadius)", width: "30%" }} ></div>
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <>
            <Card>
                <div style={{ marginBottom: showDivider ? "0" : "1.25rem" }}>
                    <CardHeader heading={'Featured blogs'} headingType={2} />
                </div>
                {showDivider && <Divider style={{ margin: "0.75rem 0", background: "var(--grey3)" }} />}
                {loading ? <Loader /> :
                    <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem", }}>
                        {featuredBlogs?.map((blog, i) => {
                            return (
                                <div style={{ display: "flex", gap: "1rem", justifyContent: "space-between" }} className='featuredBlogCard' key={i}>
                                    <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "0.25rem" }}>
                                        <Link to={blog?.Slug}>
                                            <h3 className='textMD black featuredBlogHeader  w-700 margin0 lineHeightUnset' style={{ cursor: "pointer" }}>{blog?.Title}</h3>
                                        </Link>
                                        <span className='textXS dark3'>{formatDateMMMDDYYYY(blog?.UpdatedOn)}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>}
            </Card>
        </>
    )
}