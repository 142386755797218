import { Card, Skeleton } from "antd";
import { HiOutlineArrowLongLeft, HiOutlineArrowLongRight } from "react-icons/hi2";
import { useNavigate } from "react-router";
import { v4 } from "uuid";
import { CardHeader } from "./atoms/SmallComponents";
import NewFundCard from "./NewFundCard";
import React from "react";

const SimilarFunds = React.memo(({ type, funds, heading, showQVM, style = {}, bodyStyle = {}, className = '', noHeader = false, isCard = true, loading }) => {
    
    const navigate = useNavigate();
    const uniqueId = 'sf' + v4();

    const handleScroll = (value) => {
        let target = document.querySelector(`#${uniqueId}`);
        let otherTarget = document.querySelector(`#${uniqueId}>:first-child`)?.clientWidth + 40;
        target.scrollBy({ left: value * otherTarget, behavior: "smooth" });
    }

    const ItemLoader = () => (
        <div style={{ padding: "1rem", background: "var(--blue2)", minWidth: "240px", minHeight: "6rem", borderRadius: "var(--borderRadius)", position: "relative" }}>
            <div className='d-flex flex-column justify-content-center align-items-center text-center w100' style={{ gap: "1rem", overflow: "hidden" }}>
                <Skeleton.Avatar active style={{ width: "3.125rem", height: "3.125rem" }} />
                <Skeleton.Button active style={{ height: "0.75rem", width: '170px' }} />
                <Skeleton.Button active style={{ height: "0.75rem", width: '120px' }} />
            </div>
            <div className='d-flex align-items-center justify-content-between' style={{ marginTop: "1.25rem" }}>
                <Skeleton.Button active style={{ height: "0.75rem", width: '50px' }} />
                <Skeleton.Button active style={{ height: "0.75rem", width: '50px' }} />
            </div>
            <div className='d-flex align-items-center justify-content-between' style={{ marginTop: "0.5rem" }}>
                <Skeleton.Button active style={{ height: "0.75rem", width: '50px' }} />
                <Skeleton.Button active style={{ height: "0.75rem", width: '50px' }} />
            </div>
        </div>
    )

    const Loader = () => {
        return (
            new Array(5).fill(1)?.map((ele, i) => (
                <ItemLoader key={i} />
            ))
        )
    }

    if (loading) {
        return <Card bordered={false} className={`${className} mobCardBorderRadius0`} style={style} bodyStyle={bodyStyle}>
            {!noHeader && <span className='d-flex align-items-center justify-content-between'>
                <CardHeader heading={heading} headingType={2}></CardHeader>
                <div style={{ justifyContent: "space-between", gap: "1.25rem" }} className='d-none d-sm-flex'>
                    <HiOutlineArrowLongLeft style={{
                        padding: "0.75rem",
                        background: "var(--white)",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        borderRadius: "50%",
                        fontSize: "2.5rem",
                        cursor: "pointer",
                        color: "var(--primary)"
                    }} onClick={() => handleScroll(-1)} />
                    <HiOutlineArrowLongRight style={{
                        padding: "0.75rem",
                        background: "var(--white)",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        borderRadius: "50%",
                        fontSize: "2.5rem",
                        cursor: "pointer",
                        color: "var(--primary)"
                    }} onClick={() => handleScroll(1)} />
                </div>
            </span>}
            <div style={{ display: 'flex', gap: '1.25rem', overflowX: 'scroll', marginTop: noHeader ? "0" : '1rem', alignItems: 'stretch' }} id={uniqueId}>
                <Loader />
            </div>
        </Card>
    }
    if (funds?.length) {
        return <Card bordered={false} className={`${className} mobCardBorderRadius0`} style={style} bodyStyle={bodyStyle}>
            {!noHeader && <span className='d-flex align-items-center justify-content-between'>
                <CardHeader heading={heading} headingType={2}></CardHeader>
                <div style={{ justifyContent: "space-between", gap: "1.25rem" }} className='d-none d-sm-flex'>
                    <HiOutlineArrowLongLeft style={{
                        padding: "0.75rem",
                        background: "var(--white)",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        borderRadius: "50%",
                        fontSize: "2.5rem",
                        cursor: "pointer",
                        color: "var(--primary)"
                    }} onClick={() => handleScroll(-1)} />
                    <HiOutlineArrowLongRight style={{
                        padding: "0.75rem",
                        background: "var(--white)",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        borderRadius: "50%",
                        fontSize: "2.5rem",
                        cursor: "pointer",
                        color: "var(--primary)"
                    }} onClick={() => handleScroll(1)} />
                </div>
            </span>}
            <div style={{ display: 'flex', gap: '1.25rem', overflowX: 'scroll', marginTop: noHeader ? "0" : '1rem', alignItems: 'stretch' }} id={uniqueId}>
                {
                    funds.map((f, i) => {
                        return <NewFundCard f={f} type={type} onClick={() => navigate(type.basePath + `${type.value === 4 ? '/stock-detail/' + f?.proper_name?.replaceAll(' ', '-') + '/' + f.symbol : '/fund-detail/' + f?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + f.plan_id}`)} key={i + 1} showQVM={showQVM} />
                    })
                }
            </div>
        </Card>
    }
    else return <></>

}, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})

export default SimilarFunds;