import { etf, mf, sharpelyOnlyLogo, stocks } from "Assets/images";
import { useUserDataHooks } from "CustomHooks/useUserDataHooks";
import { getUserScreeners, useGetAssetType } from "Libs/utils";
import apiHelper from "Services/api/ApiHelper";
import { Badge, Card, Divider, Modal } from "antd";
import React from "react";
import toast from "react-hot-toast";
import { BsChevronLeft, BsYoutube } from "react-icons/bs";
import { HiDocument } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { toggleBasketCreateModal } from "../Libs/redux/Modals/reduxSlice";
import ActivityLoaderMintbox from "./ActivityLoaderMintbox";
import { NoDataFound } from "./NoDataFound";
import ReadMoreText from "./ReadMoreText";
import { PlanName, useSubscribeMiddleware } from "./SubscribeModal";
import { CardHeader } from "./atoms/SmallComponents";

export const BasketCreateModal = () => {

    const navigate = useNavigate();
    const { open, type } = useSelector((state) => state?.basketCreateModal);
    const [selectedType, setselectedType] = React.useState('');
    const [viewType, setViewType] = React.useState('static');
    const user = useSelector((state) => state?.updateUserData?.value);
    const [screens, setScreens] = React.useState([]);
    const [signals, setSignals] = React.useState([]);
    const [strategies, setStrategies] = React.useState([]);
    const [dataLoading, setDataLoading] = React.useState(false);
    const dispatch = useDispatch();
    const { getUserStrategies, getUserStockModels } = useUserDataHooks();
    const subscribeMiddle = useSubscribeMiddleware();
    const isMobile = window.innerWidth < 760;

    const strategyDetailedAccess = React.useMemo(() => {
        let temp = subscribeMiddle({ moduleKey: "BUILD,_BACKTEST_AND_PAPERTRADE", getDetailedAccessData: true, checkLimit: true, usageCount: strategies?.length || 0 });
        return temp || {}
    }, [strategies]);

    console.log(strategyDetailedAccess);

    const closeModal = () => {
        dispatch(toggleBasketCreateModal({ open: false, type: {} }))
    }

    const handleTypeSelection = async (basketType = 1, instrumenntType = 4, item = {}) => {
        // if (basketType === 1) {
        closeModal();
        navigate(`/${instrumenntType === 4 ? "stocks" : instrumenntType === 1 ? "etfs" : "mutual-funds"}/${basketType === 1 ? "build-custom-strategy" : basketType === 2 ? "super-screener" : "factor-model"}`, { state: { fromStrategyCreation: true } })
        // }
        // else {
        //     setViewType(basketType === 2 ? 'screen' : "factorModel");
        // }
    }

    const handleFinalSelection = (basketInsType = 2, item = {}, insType = 'old') => {
        // if (insType === 'new') {
        closeModal();
        navigate(`/stocks/${basketInsType === 2 ? "super-screener" : "factor-model"}`);
        // }
        // else if (basketInsType === 2) {
        //     closeModal();
        //     navigate(`/${instrumenntType === 4 ? "stocks" : "etfs"}/super-screener?screenId=${item?.checklist_id}&screenType=3&userId=${user?.id || 0}`)
        // }
        // else if (basketInsType === 3) {
        //     closeModal();
        //     navigate(`/${instrumenntType === 4 ? "stocks" : "etfs"}/factor-model`, { state: { signalData: item } })
        // }
    }

    const loadData = async () => {
        setDataLoading(true);
        let strategyRes = await getUserStrategies();
        if (strategyRes) {
            setStrategies(strategyRes?.userStrategies);
        }
        let res = await getUserScreeners(user?.id, 'stock');
        setScreens(res);
        let signals = await getUserStockModels();
        setSignals(signals?.userModels);
        setDataLoading(false);
    }

    const reset = () => {
        setScreens([]);
        setSignals([]);
        setViewType('static');
        setStrategies([]);
        setDataLoading(true);
    }

    React.useEffect(() => {
        if (open) {
            loadData();
        }
        else {
            console.log('closed basket create modal')
            reset();
        }
    }, [open]);


    const instrumentOptions = [
        {
            title: 'Stock basket',
            desc: 'Select your favourite stocks, define weights and rebalancing schedule and you are all set. In paper trading, your strategy will be automatically rebalanced as per your rules. In live trading, your will be notified to carry out the order execution.',
            learnText: 'Learn more about creating stock baskets',
            blogLink: 'https://sharpely.in/knowledge-base/strategy/building-strategies/creating-a-stock-basket',
            videoLink: 'vns1wm7cvf8',
            instrumentType: 4,
            method: 1,
        }, {
            title: 'Dynamic strategy using stock screener',
            desc: 'In this strategy, a screener will used for dynamic stock selection. You can define weights, rebalancing schedule and other parameters to further finetune your strategy. In paper trading, your strategy will be automatically rebalanced as per your rules. In live trading, your will be notified to carry out the order execution.',
            learnText: 'Learn more about creating dynamic strategies using stock screener',
            blogLink: 'https://sharpely.in/knowledge-base/strategy/building-strategies/creating-a-dynamic-strategy-using-stock-screener',
            videoLink: 'UP5eEWWl2RI',
            instrumentType: 4,
            method: 2,
        }, {
            title: 'Dynamic strategy using factor model',
            desc: 'In this strategy, a factor model will be used for dynamic stock selection. You can define weights, rebalancing schedule and other parameters to further finetune your strategy. In paper trading, your strategy will be automatically rebalanced as per your rules. In live trading, your will be notified to carry out the order execution.',
            learnText: 'Learn more about creating dynamic strategies using factor model',
            blogLink: 'https://sharpely.in/knowledge-base/strategy/building-strategies/creating-a-dynamic-strategy-using-factor-model',
            videoLink: 'cJ6y3fqLkEI',
            instrumentType: 4,
            method: 3,
        },
        {
            title: 'ETF basket',
            desc: 'Select your favourite ETFs, define weights and rebalancing schedule and you are all set. In paper trading, your strategy will be automatically rebalanced as per your rules. In live trading, your will be notified to carry out the order execution.',
            learnText: 'Learn more about creating ETF baskets',
            blogLink: 'https://sharpely.in/knowledge-base/strategy/building-strategies/creating-a-stock-basket',
            videoLink: '',
            instrumentType: 1,
            method: 1,
        }
        , {
            title: 'MF basket',
            desc: 'Select your favourite MFs, define weights and rebalancing schedule and you are all set. In paper trading, your strategy will be automatically rebalanced as per your rules.',
            learnText: 'Learn more about creating MF baskets',
            blogLink: 'https://sharpely.in/knowledge-base/strategy/building-strategies/creating-a-stock-basket',
            videoLink: '',
            instrumentType: 2,
            method: 1,
        }
    ]

    const navigateToApp = () => {
        const userAgent = navigator.userAgent;
        let appLink = 'sharpely://dashboard'
        let fallbackLink = ''
        if (/Android/i.test(userAgent)) {
            console.log('User is using Android device');
            // Your Android-specific logic here
            fallbackLink = 'https://play.google.com/store/apps/details?id=com.investments.sharpely'
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            console.log('User is using iOS device');
            // Your iOS-specific logic here
            fallbackLink = 'https://apps.apple.com/us/app/sharpely/id6455889217'
        }
        try {
            window.location = appLink;

            const hanndleVgsg = (e) => {
                if (document.visibilityState !== 'hidden') {
                    setTimeout(function () { window.location = fallbackLink; }, 1000);
                }
            }

            document.addEventListener('visibilitychange', hanndleVgsg)
        }
        catch (err) {
            toast.error('Go to test')
        }

    }

    return (
        <Modal open={open} onCancel={closeModal} onOk={closeModal} footer={null} width={isMobile ? '100%' : '60rem'} closable={false}>
            <>
                <div className='d-flex justify-content-between' style={{ flexDirection: isMobile ? 'column' : 'row', gap: '0.5rem' }} >
                    <div className='textLG black w-700 d-flex align-items-center' style={{ gap: "0.5rem", cursor: "pointer" }} onClick={() => {
                        if (viewType !== 'static') setViewType('static')
                    }}>
                        {viewType !== 'static' && <BsChevronLeft />}
                        {viewType === 'static' ? 'Choose your strategy type' : `Dynamic ${viewType === 'screen' ? "screen" : "factor model"} based basket`}
                    </div>
                    {!dataLoading && strategyDetailedAccess?.maxLimit !== Infinity && strategyDetailedAccess?.currentAccessLevel >= strategyDetailedAccess?.minAccessLevel &&
                        <div style={{ userSelect: "none" }}>
                            <span className="textXS dark3 w-400">Credits: <span className='dark4 w-500'>
                                <b style={{ color: strategyDetailedAccess?.remainingLimit === 0 ? "red" : "var(--primary)" }}>{strategyDetailedAccess?.remainingLimit}</b>
                                <b>/{strategyDetailedAccess?.maxLimit}</b></span>.&nbsp;
                                {strategyDetailedAccess?.currentAccessLevel !== 4 && <span>Upgrade to <Link className="dark4 w-500" to='/pricing'>
                                    <PlanName accessLevel={strategyDetailedAccess?.currentAccessLevel + 1} /></Link>.</span>}
                            </span>
                        </div>}
                </div>
                <Divider style={{ borderColor: "var(--grey3)", margin: '1rem 0' }} />
                {viewType === 'static' ? <>
                    <div style={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr", gap: "1rem 1.5rem" }}>
                        {instrumentOptions?.map((item) => {
                            return <Badge.Ribbon text={item?.instrumentType === 4 ? 'Stock' : item?.instrumentType === 1 ? 'ETF' : 'MF'} color="var(--dark4)">
                                <Card className="shadowXS" style={{ background: "var(--grey4)", border: "1px solid var(--grey3)", cursor: "pointer", flex: 1 }} bodyStyle={{ display: "flex", flexDirection: "column", padding: "1rem", flex: 1, height: "100%" }}
                                    onClick={() => handleTypeSelection(item?.method, item?.instrumentType, item)}>
                                    <div className='d-flex align-items-center' style={{ gap: "0.5rem", cursor: "pointer" }}>
                                        <div className='textSM w-bold' style={{ flex: 1 }}>
                                            {item?.title}
                                        </div>
                                        {/* <span className="textXS textLight">Tutorial </span>
                                        <HiDocument color="var(--primary)" />
                                        <BsYoutube color="red" /> */}
                                    </div>
                                    <Divider style={{ margin: "0.5rem 0px 0.5rem", borderColor: "var(--grey1)" }} />
                                    <div className='textXS dark3 w-400 mb-2' style={{ flex: 1, height: "100%" }}>
                                        <ReadMoreText showCharacters={180} text={item?.desc} />
                                    </div>
                                    {/* <Divider style={{ margin: "0.5rem 0px 0.5rem", borderColor: "var(--grey1)" }} /> */}
                                    <div className='d-flex align-items-center' style={{ gap: "0.5rem", cursor: "pointer" }}>
                                        <div className='textSM w-bold' style={{ flex: 1 }}>
                                            {/* {item?.title} */}
                                        </div>
                                        <span className="textXS textLight">Tutorial </span>
                                        <HiDocument color="var(--primary)" onClick={() => {
                                            window.open(item?.blogLink, "_blank", "noreferrer");
                                        }} />
                                        <BsYoutube color="red"
                                            onClick={() => {
                                                window.open('https://www.youtube.com/watch?v=' + item?.videoLink, "_blank", "noreferrer");
                                            }} />
                                    </div>
                                    {/* <a href='/knowledge-base/baskets/custom-stock-baskets/building-a-static-stock-basket' target='_blank' onClick={(e) => e.stopPropagation()}>
                                    <div className='helpText mt-2'>
                                        {item?.learnText}
                                    </div>
                                </a> */}
                                </Card>
                            </Badge.Ribbon>
                        })}
                    </div>
                    {/* <Card style={{ background: "var(--grey4)", border: "1px solid var(--grey3)", cursor: "pointer" }} bodyStyle={{ padding: "1rem" }} className='margin20Bottom'
                        onClick={() => handleTypeSelection(1)}>
                        <div className='d-flex align-items-center' style={{ gap: "0.5rem", cursor: "pointer" }}>
                            <div className='textMD w-700'>
                                Create a static basket
                            </div>
                        </div>
                        <Divider style={{ margin: "0.5rem 0px 0.5rem", borderColor: "var(--grey1)" }} />
                        <div className='textSM dark4 w-500'>
                            Add your favourite stocks, define weights and rebalancing rule and you are all set!
                        </div>
                        <a href='/knowledge-base/baskets/custom-stock-baskets/building-a-static-stock-basket' target='_blank' onClick={(e) => e.stopPropagation()}>
                            <div className='helpText mt-2'>
                                How to create a static stock basket?
                            </div>
                        </a>
                    </Card>
                    <Card style={{ background: "var(--grey4)", border: "1px solid var(--grey3)" }} bodyStyle={{ padding: "1rem" }} className='margin20Bottom'
                        onClick={() => handleTypeSelection(2)}>
                        <div className='d-flex align-items-center' style={{ gap: "0.5rem", cursor: "pointer" }}>
                            <div className='textMD w-700'>
                                Create a dynamic basket using screen
                            </div>
                        </div>
                        <Divider style={{ margin: "0.5rem 0px 0.5rem", borderColor: "var(--grey1)" }} />
                        <div className='textSM dark4 w-500'>
                            Build a basket using a screen as the stock selection criteria.
                        </div>
                        <a href='/knowledge-base/baskets/custom-stock-baskets/creating-a-dynamic-basket-using-stock-screener' target='_blank' onClick={(e) => e.stopPropagation()}>
                            <div className='helpText mt-2'>
                                How to create a dynamic basket using stock screen?
                            </div>
                        </a>
                    </Card>
                    <Card style={{ background: "var(--grey4)", border: "1px solid var(--grey3)" }} bodyStyle={{ padding: "1rem" }} className=''
                        onClick={() => handleTypeSelection(3)}>
                        <div className='d-flex align-items-center' style={{ gap: "0.5rem", cursor: "pointer" }}>
                            <div className='textMD w-700'>
                                Create a dynamic basket using Factor Model
                            </div>
                        </div>
                        <Divider style={{ margin: "0.5rem 0px 0.5rem", borderColor: "var(--grey1)" }} />
                        <div className='textSM dark4 w-500'>
                            Build a basket using a factor model as the stock selection criteria.
                        </div>
                        <a href='/knowledge-base/baskets/custom-stock-baskets/creating-a-dynamic-basket-using-factor-models' target='_blank' onClick={(e) => e.stopPropagation()}>
                            <div className='helpText mt-2'>
                                How to create a dynamic basket using factor model?
                            </div>
                        </a>
                    </Card> */}
                </> : <>
                    <Card style={{ background: "var(--grey4)", border: "1px solid var(--grey3)" }} bodyStyle={{ padding: "1rem" }} className='margin20Bottom'>
                        <div className='d-flex align-items-center' style={{ gap: "0.5rem", cursor: "pointer" }}>
                            <div className='textMD w-700'>
                                Build using one of your saved {viewType === 'screen' ? "screens" : "factor models"}
                            </div>
                        </div>
                        {dataLoading ? <div>
                            <ActivityLoaderMintbox />
                        </div> : <div>
                            {((viewType === 'screen' && screens?.length === 0) || (viewType === 'factorModel' && signals?.length === 0)) ? <div className='my-3'>
                                <NoDataFound message={`You don't have created any ${viewType === 'screen' ? "screen" : "factor model"}`} />
                            </div> : <div style={{
                                maxHeight: "40vh", overflowY: "auto", background: "var(--white)", border: "1px solid var(--grey2)", minHeight: "25vh",
                                borderRadius: "var(--borderRadius)"
                            }} className='scrollbox my-2 d-flex flex-column'>
                                <div className='textSM w-700 dark4' style={{ padding: "0.5rem 0.75rem" }}>Your {viewType === 'screen' ? "screens" : "factor models"}</div>
                                {viewType === 'screen' ? screens?.map((item, i) => {
                                    return (
                                        <div key={i} style={{ cursor: "pointer" }} onClick={() => handleFinalSelection(2, item)}>
                                            <div style={{ padding: "0.5rem 0.75rem", gap: "0.5rem" }} className='d-flex align-items-center'>
                                                <div className='textSM w-500 dark4'>{item?.checklist_name}</div>
                                            </div>
                                            <Divider style={{ margin: 0 }} />
                                        </div>
                                    )
                                }) : signals?.map((item, i) => {
                                    return (
                                        <div key={i} style={{ cursor: "pointer" }} onClick={() => handleFinalSelection(3, item)}>
                                            <div style={{ padding: "0.5rem 0.75rem", gap: "0.5rem" }} className='d-flex align-items-center'>
                                                <div className='textSM w-500 dark4'>{item?.signal_name}</div>
                                            </div>
                                            <Divider style={{ margin: 0 }} />
                                        </div>
                                    )
                                })}
                            </div>}
                        </div>}
                        <div className='mt-2 textXS dark4 w-500'>
                            {viewType === 'screen' ? "You can use any of your saved screens to build your basket. Once you have selected the screen, you will be redirected to the screen page where you can proceed to build your basket" : "You can use any of your saved models to build your basket. Once you have selected the screen, you will be redirected to the model page where you can proceed to build your basket"}
                        </div>
                    </Card>
                    <Card style={{ background: "var(--grey4)", border: "1px solid var(--grey3)" }} bodyStyle={{ padding: "1rem" }} className=''
                        onClick={() => handleFinalSelection(viewType === 'screen' ? 2 : 3, {}, 'new')}>
                        <div className='d-flex align-items-center' style={{ gap: "0.5rem", cursor: "pointer" }}>
                            <div className='textMD w-700'>
                                Create a new {viewType === 'screen' ? "stock screen" : "factor model"}
                            </div>
                        </div>
                        <div className='mt-2 textXS dark4 w-500'>
                            {viewType === 'screen' ? "You will be redirected to stock screener where you can create a screen and proceed to build your basket" : "You will be redirected to Factor Models where you can create a model and proceed to build your basket"}
                        </div>
                    </Card>
                </>}
            </>
        </Modal>
    )
}

export const BasketCreateHeader = () => {
    const type = useGetAssetType();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <Card className='mobCardBorderRadius0' style={{ position: "relative" }} bodyStyle={{ padding: "0rem" }}>
                <div className='flexRowCard strategyCardHead' style={{ justifyContent: "space-between" }}>
                    <div className='flexCard' style={{ padding: "1.25rem", maxWidth: "65%", justifyContent: "space-between", background: window.innerWidth < 760 ? "linear-gradient(272.41deg, var(--grey2) 10%, var(--white) 30%)" : 'none' }}>
                        <div className='flexCard' style={{ gap: "1rem" }}>
                            <span style={{ height: "fit-content" }}>
                                <CardHeader heading={type.value === 4 ? `Create and Invest in your own Stock basket` : `Create and Invest in a Custom ${type.value === 1 ? "ETF" : "MF"} basket`} headingType={2} />
                            </span>
                            <div className='dark3 textSM' style={{
                                wordBreak: "break-word",
                                maxWidth: "45ch"
                            }}>
                                A portfolio approach is always the best approach to
                                investing. Stop the guess work. Focus on creating an investment
                                basket. Create and invest in a fully automated investment basket
                                by picking your favourite {type.value === 1 ? "ETFs" : type.value === 2 ? "Mutual Funds" : "Stocks"}.
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: "center", gap: "1rem" }} >
                            <button className="btnPrimary " style={{ width: "fit-content" }} onClick={() => {
                                if (type.value === 4) {
                                    dispatch(toggleBasketCreateModal({ open: true, type: type }))
                                }
                                else {
                                    navigate(`${type.basePath}/build-custom-strategy`)
                                }
                            }}>
                                Start Building
                            </button>
                            <button className="btnWhite " style={{ width: "fit-content" }} onClick={() => {
                                navigate('/knowledge-base/baskets')
                            }}>
                                Learn More
                            </button>
                        </div>
                    </div>
                    <div className="flexCard d-none d-sm-flex" style={{
                        flexShrink: 0, flexGrow: 1,
                        background: "linear-gradient(272.41deg, var(--grey2) 55.38%, var(--white) 89.01%)",
                        minHeight: "100%", borderRadius: "0.25rem", maxWidth: "33%",
                        display: "flex", justifyContent: "center", flexDirection: "column", position: "relative", alignItems: "center"
                    }}>
                        <img loading="lazy"  alt="" src={type.value === 1 ? etf : type.value === 2 ? mf : stocks} style={{ width: "80%", height: "80%", zIndex: 1 }} />
                    </div>
                </div>
            </Card>
        </React.Fragment>
    )
}