import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";

const RedGreenText = ({ text, value, isBg = false, sign = false, arrow = false, showYellow = false }) => {
    if (Number(value) > 0) {
        return <span style={{ color: "var(--green)", fontWeight: 500, backgroundColor: isBg ? "var(--greenShade)" : 'unset', padding: isBg ? "0rem 0.2rem" : 'unset', borderRadius: isBg ? 4 : 'unset', alignItems: "center", textAlign: "center" }}>
            {(isBg || arrow) && <HiOutlineArrowSmUp size={15} style={{ marginTop: -2 }} color="var(--green)" />}{sign && '+'}{text}
        </span>
    } else if (Number(value) < 0) {
        return <span style={{ color: "var(--red)", fontWeight: 500, backgroundColor: isBg ? "var(--redShade)" : 'unset', padding: isBg ? "0rem 0.2rem" : 'unset', borderRadius: isBg ? 4 : 'unset' }}>
            {(isBg || arrow) && <HiOutlineArrowSmDown size={15} style={{ marginTop: -2 }} color="var(--red)" />}{text}
        </span>
    } else if (showYellow) {
        return <span style={{ color: "var(--gold)", fontWeight: 500, backgroundColor: isBg ? "var(--yellowShade)" : 'unset', padding: isBg ? "0rem 0.2rem" : 'unset', borderRadius: isBg ? 4 : 'unset' }}>
            {(isBg || arrow) && <HiOutlineArrowSmDown size={15} style={{ marginTop: -2 }} color="var(--gold)" />}{text}
        </span>
    } else {
        return <span>{text}</span>

    }
}


export default RedGreenText; 