import { flaticonSvg } from "Assets/Constant";
import AMCIcon from "Components/AMCIcon";
import AccessComponent from "Components/AccessComponent";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox.js";
import { CurrencyFormat } from "Components/CurrencyFormat.js";
import { StickyScrollTabs } from "Components/CustomComponents/StickyScrollTabs";
import CustomDrawer from "Components/CustomDrawer";
import { CustomRangeSliders2 } from "Components/CustomRangeSliders";
import CustomTabs, { goToCustomPillTab } from "Components/CustomTabs";
import { DashboardChartMetrics } from "Components/Dashboard/DashboardChartMetrics";
import DashboardChartComponent from "Components/DashboardChartComponent";
import { formatDate } from "Components/DateFormatter";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import InstrumentListTable from "Components/InstrumentListTable";
import RedGreenText from "Components/RedGreenText";
import { codeParserWrapper, finalCodeRedable } from "Components/Screener/ScreenerUtils";
import SharpelyGateway2 from "Components/SharpelyGateway/SharpelyGateway2";
import SimilarFunds from "Components/SimilarFunds";
import { AlertBuilder } from 'Components/StockDetailComponents/AlertBuilder';
import { Checklist, KeyTalkingPoints, Scorecard, StyleAnalysis, SummaryRecommendation } from 'Components/StockDetailComponents/Analysis';
import { Forecast } from 'Components/StockDetailComponents/Forecast';
import { BusinessModel, CorporateActions, Dividends, RatiosAndStatistics, Segments, Statements } from 'Components/StockDetailComponents/Fundamentals';
import { Ownership2 } from 'Components/StockDetailComponents/Ownership2';
import { Resources } from 'Components/StockDetailComponents/Resources';
import TechnicalAnalysis from "Components/StockDetailComponents/TechnicalAnalysis";
import { SubscribeContentWrapper, useSubscribeMiddleware } from "Components/SubscribeModal";
import CompanyProfileWidget from 'Components/TradingView/CompanyProfile';
import UpgradeToPro from "Components/UpgradeToPro";
import { WatchList } from "Components/Watchlist";
import { ConfirmModal } from 'Components/atoms/ConfirmModal';
import { CardHeader } from "Components/atoms/SmallComponents";
import Head from "Layout/Head";
import { toggleMetricDescModal, toggleUserGuideModal } from "Libs/redux/KnowledgeBase/reduxSlice";
import { updateCustomColumns } from 'Libs/redux/UserAuth/reduxSlice';
import { displayValue, getIndexList, getSnapshotInitialMetrics, groupBy, screenerMetricData, useGetAssetType } from "Libs/utils";
import AdminServices from "Services/api/AdminServices";
import AdminServicesPY from "Services/api/AdminServicesPY";
import apiHelper from "Services/api/ApiHelper";
import apiHelperPY from "Services/api/ApiHelperPY";
import { Dashboard_Portfolio } from 'Services/api/FabricatedAPIS';
import { StockLivePrice } from "Services/api/LivePricesWebSocket";
import { Alert, Badge, Card, Divider, Drawer, Tag } from "antd";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import moment from "moment";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { BiEdit } from "react-icons/bi";
import { HiBellAlert } from "react-icons/hi2";
import { IoIosClose } from "react-icons/io";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useParams } from "react-router-dom";

const StockDetail = (props) => {
	const params = useParams();
	const SYMBOL = params.code;
	const type = useGetAssetType(); // 1 = etf, 2 = mf
	const user = useSelector((state) => state?.updateUserData?.value);
	const userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel || 0
	const navigate = useNavigate();
	const location = useLocation();
	const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
	const userCustomCols = useSelector((state) => state?.userCustomCols?.userCustomCols);
	let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
	const isMobile = window.innerWidth <= 760;

	// const [allFundsData, setallFundsData] = useState([]);
	const [allFunds, setAllFunds] = useState([]);
	const [fundData, setFundData] = useState({});
	const [finStatements, setFinstatements] = useState({});
	const [stockInsights, setStockInsights] = useState({});
	const [shareholdings, setShareholdings] = React.useState([]);
	const [bulkBlockDeals, setBulkBlockDeals] = React.useState({});
	const [sastData, setSastData] = React.useState({});
	const [estimates, setEstimates] = useState({});
	const [scorecard, setScorecard] = useState({});
	const [allCheckLists, setAllCheckLists] = useState({});
	const [industries, setIndustries] = useState();
	const [basicIndustries, setBasicIndustries] = useState({});
	const [sectors, setSectors] = useState();
	const [macroSectors, setMacroSectors] = useState({});
	const [industriesCurrent, setIndustriesCurrent] = useState("");
	const [basicIndustriesCurrent, setBasicIndustriesCurrent] = useState("");
	const [sectorsCurrent, setSectorsCurrent] = useState("");
	const [macroSectorsCurrent, setMacroSectorsCurrent] = useState("");
	const [screenerMetric, setscreenerMetric] = useState([]);
	const [styleBoxes, setStyleBoxes] = useState([]);
	const [screenerFinalData, setscreenerFinalData] = useState([]);
	const [stockSectorCLF, setStockSectorCLF] = useState([]);
	// const [allFilteredFundsData, setallFilteredFundsData] = useState([]);
	const [screenerMetricDescData, setScreenerMetricDescData] = useState([]);
	const [industryPeersModal, setIndustryPeersModal] = useState(false);
	const [peersData, setPeersData] = useState([]);
	const [segmentsData, setSegmentsData] = useState({});
	const [historicalCharts, setHistoricalCharts] = React.useState({});
	const [dividends, setDividends] = React.useState([]);
	const [resources, setResources] = React.useState({});
	// const [userCustomColumns, setUserCustomColumns] = React.useState({});
	const [portfolioObj, setPortfolioObj] = React.useState([])
	const [loading, setLoading] = useState(true);
	const [loadingStockInsights, setLoadingStockInsights] = React.useState(true);
	const [loadingScorecard, setLoadingScorecard] = useState(true);
	const [loadingAllChecklists, setLoadingAllChecklists] = useState(true);
	const [loadinghistoricalCharts, setLoadingHistoricalCharts] = React.useState(true);
	const [loadingFinancialStatements, setLoadingFinancialStatements] = React.useState(true);
	const [loadingShareholding, setLoadingShareholding] = React.useState(true);
	const [loadingBulkBlockDeals, setLoadingBulkBlockDeals] = React.useState(true)
	const [loadingSAST, setLoadingSAST] = React.useState(true)
	const [loadingEstimates, setLoadingEstimates] = React.useState(true);
	const [loadingSegments, setLoadingSegments] = React.useState(true);
	const [loadingResources, setLoadingResources] = React.useState(true);
	const dispatch = useDispatch();
	const [selectedTabItem, setSelectedTabItem] = React.useState("Overview");
	const [snapshotCards, setSnapshotCards] = React.useState([]);
	const [initialCols, setInitialCols] = React.useState([]);
	const [newListing, setNewListing] = React.useState(false);
	const [allIndicatorData, setAllIndicatorData] = React.useState([])
	const [allVolumeData, setAllVolumeData] = React.useState([])
	const [allRangeAnalysisData, setAllRangeAnalysisData] = React.useState({})
	const [loadingTechnicalsIndicators, setLoadingTechnicalsIndicators] = React.useState(true);
	const [loadingTechnicalsVolume, setLoadingTechnicalsVolume] = React.useState(true);
	const [loadingTechnicalsRange, setLoadingTechnicalsRange] = React.useState(true);


	const [alertModal, setalertModal] = React.useState(false)
	const [alertCreateEditModal, setalertCreateEditModal] = React.useState(false)
	const [alertsList, setalertsList] = React.useState([])
	const [alertsAllList, setalertsAllList] = React.useState([])

	const [selectedAlert, setselectedAlert] = React.useState({})
	const [alertsMetaData, setAlertMetaData] = React.useState({})
	const subscribeMiddle = useSubscribeMiddleware();

	const alertsDetailedAccess = subscribeMiddle({ moduleKey: "STOCK_ALERTS", getDetailedAccessData: true, checkLimit: true, usageCount: alertsAllList?.length || 0 });

	const resetState = async () => {
		setSelectedTabItem('Overview');
		setLoading(true);
		setLoadingScorecard(true);
		setNewListing(false);
		setLoadingShareholding(true);
		setLoadingBulkBlockDeals(true);
		setLoadingSAST(true);
		setLoadingStockInsights(true);
		setLoadingSegments(true)
		setLoadingAllChecklists(true);
		setLoadingFinancialStatements(true);
		setLoadingTechnicalsIndicators(true);
		setLoadingTechnicalsVolume(true);
		setLoadingTechnicalsRange(true);
		setLoadingHistoricalCharts(true);
		setLoadingEstimates(true);
		setLoadingResources(true);
		setPortfolioObj([]);
		setAllIndicatorData([]);
		setAllVolumeData([]);
		setAllRangeAnalysisData({});
	};

	const structureFinStData = (datas) => {
		let res = {};
		let divDescIds = [93, 94, 205];
		let dividendsData = [];
		Object.keys(datas || {})?.map((key) => {
			if (key === "corp_action" || key === "round_to" || key === 'ttm_date') {
				res[key] = datas[key];
			} else {
				let d = datas[key];
				let arr1 = [];
				let arr2 = [];
				let arr3 = [];
				d.map((obj, i) => {
					let m = JSON.parse(obj?.meta || "{}");
					let o = {
						key: m.name,
						...obj,
						meta: m,
						name: m?.name,
						...obj.data,
						type: m.display_order?.length,
					};
					let diso = m?.display_order?.split("")?.filter((el) => el === "_")?.length;
					if (diso === 0) {
						arr1.push(o);
					} else if (diso === 1) {
						arr2.push(o);
					} else {
						arr3.push(o);
					}
					if (divDescIds?.includes(m?.desc_id) && key === "pnl_an") {
						dividendsData.push({ ...o, children: undefined });
					}
				});
				for (let i = 0; i < arr2.length; i++) {
					let j = arr2[i];
					let p = arr3?.filter((ele) => ele?.meta?.display_order?.startsWith(j?.meta?.display_order + '_'));
					if (p.length) {
						if (j.children) {
							j.children = [...j.children, ...p];
						} else {
							j.children = [...p];
						}
					}
				}
				for (let i = 0; i < arr1.length; i++) {
					let j = arr1[i];
					let p = arr2?.filter((ele) => ele?.meta?.display_order?.startsWith(j?.meta?.display_order + '_'));
					if (p.length) {
						if (j.children) {
							j.children = [...j.children, ...p];
						} else {
							j.children = [...p];
						}
					}
				}
				res[key] = arr1.sort((a, b) => {
					let i = a?.meta?.display_order?.replaceAll('_', '')?.replace('L', '');
					let j = b?.meta?.display_order?.replaceAll('_', '')?.replace('L', '');
					return Number(i) - Number(j);
				});
			}
		});
		//console.log('lkjhgg', res);
		setDividends(dividendsData);
		return res || {};
	};

	const createFundDetCardsData = (fundData, screenerMetricdata = [], cols) => {
		let metricColCodes = getSnapshotInitialMetrics(fundData, type)
		setInitialCols(metricColCodes);
		if (cols?.Snapshot?.length > 0) {
			let oth = cols?.Snapshot?.split('|')?.filter((el) => el) || []
			metricColCodes = [...metricColCodes, ...oth];
			metricColCodes = [...new Set(metricColCodes)]
		}
		let temp = metricColCodes?.map((el) => {
			let ob = screenerMetricdata?.find((e) => e?.metric_col_code === el);
			let obj = {
				...ob,
				metric_col_code: el,
				valueToRender: ob?.needToRender ? (ob?.dataToRenderFrom?.filter((e) => e?.[ob?.renderKey] === fundData?.[el])?.[0]?.[ob?.renderValue]) || "-" : displayValue({ text: fundData[el], metricObj: ob })
			}
			return obj;
		})
		return temp;
	};

	const structureShareholdingData = (data = {}) => {
		try {
			let columns = Object.keys(data || {});
			let dataSource = [];
			let maxType = 0;
			let obj = {};

			for (let i = 0; i < columns?.length; i++) {
				let colData = data?.[columns?.[i]]?.data || [];
				let topHolders = data?.[columns?.[i]]?.top_holders || [];
				for (let j = 0; j < colData?.length; j++) {
					let d = colData[j];
					let type = d?.category_code?.split('')?.filter((el) => el === '_')?.length;
					if (type > maxType) maxType = type;
					let holders = topHolders?.filter((el) => el?.code === d?.category_code);;
					if (obj[type] === undefined) {
						let o = {
							code: d?.category_code,
							name: d?.category_name,
							key: d?.category_code,
							type,
							[columns[i]]: {
								...d,
								topHolders: holders
							},
						}
						obj[type] = [o]
					}
					else {
						let o = {
							code: d?.category_code,
							name: d?.category_name,
							key: d?.category_code,
							type,
							[columns[i]]: {
								...d,
								topHolders: holders
							}
						}
						let temp = [...obj[type]];
						let check = temp?.find((el) => el?.name === d?.category_name);
						if (check) {
							temp = temp?.map((el) => {
								if (el?.name === d?.category_name) {
									return {
										...el,
										...o
									}
								}
								else return el;
							})
							obj[type] = temp;
						} else {
							obj[type] = [...obj[type], o]
						}
					}
				}
			}
			for (let j = maxType; j >= 1; j--) {
				let arr2 = obj[j];
				let arr1 = obj[j - 1];
				for (let i = 0; i < arr1.length; i++) {
					let j = arr1[i];
					let p = arr2?.filter((ele) => ele?.code?.startsWith(j?.code + '_'));
					if (p.length) {
						if (j.children) {
							j.children = [...j.children, ...p];
						} else {
							j.children = [...p];
						}
					}
				}
			}
			dataSource = [...(obj?.[0] || [])];
			let l3Ind = dataSource?.findIndex((el) => el?.code === 'L3');
			if (l3Ind !== -1) {
				let l3 = dataSource.splice(l3Ind, 1);
				dataSource.push(l3?.[0]);
			}
			return { dataSource: dataSource || [], columns }
		}
		catch (err) {
			return { dataSource: [], columns: [] }
		}
	}

	const getIndicators = async () => {
		setLoadingTechnicalsIndicators(true)
		const obj = {
			'symbol': SYMBOL,
			// 'timeframe': ['1D'],
			'timeframe': ['1m', '5m', '15m', '30m', '1H', '1D', '1W', '1M'],
			'request': [],
			// 'request': [{
			// 	'inputs': { 'period': 5 },
			// 	'name': 'EMA',
			// 	'type': 'ma'
			// },
			// {
			// 	'inputs': { 'period': 21 },
			// 	'name': 'EMA',
			// 	'type': 'ma'
			// },
			// {
			// 	'inputs': { 'period': 50 },
			// 	'name': 'EMA',
			// 	'type': 'ma'
			// },
			// {
			// 	'inputs': { 'period': 200 },
			// 	'name': 'EMA',
			// 	'type': 'ma'
			// },
			// {
			// 	'inputs': { 'period': 5 },
			// 	'name': 'SMA',
			// 	'type': 'ma'
			// },
			// {
			// 	'inputs': { 'period': 21 },
			// 	'name': 'SMA',
			// 	'type': 'ma'
			// },
			// {
			// 	'inputs': { 'period': 50 },
			// 	'name': 'SMA',
			// 	'type': 'ma'
			// },
			// {
			// 	'inputs': { 'period': 200 },
			// 	'name': 'SMA',
			// 	'type': 'ma'
			// },
			// {
			// 	'inputs': { 'period': 14 },
			// 	'name': 'RSI',
			// 	'type': 'oscillator'
			// },
			// { 'inputs': { 'period': 14 }, 'name': 'MFI', 'type': 'indicator' },
			// {
			// 	'inputs': { 'fastperiod': 12, 'signalperiod': 9, 'slowperiod': 26 },
			// 	'name': 'MACD',
			// 	'type': 'oscillator'
			// },
			// // { 'inputs': { 'period': 14 }, 'name': 'ATR', 'type': 'oscillator' },
			// { 'inputs': { 'period': 14 }, 'name': 'ADX', 'type': 'oscillator' },
			// // { 'inputs': { 'period': 10 }, 'name': 'ROC', 'type': 'oscillator' },
			// { 'inputs': { 'period': 14 }, 'name': 'CCI', 'type': 'oscillator' },
			// {
			// 	'inputs': { 'fastk_period': 5, 'slowd_period': 3, 'slowk_period': 3 },
			// 	'name': 'StochasticOscillator',
			// 	'type': 'oscillator'
			// },
			// {
			// 	'inputs': { 'period': 14 },
			// 	'name': 'StochasticRSI',
			// 	'type': 'oscillator'
			// },
			// {
			// 	'inputs': { 'period': 14 }, 'name': 'WilliamsR',
			// 	'type': 'oscillator'
			// },
			// {
			// 	'inputs': { 'fastperiod': 5, 'slowperiod': 34 },
			// 	'name': 'AwesomOsillator',
			// 	'type': 'oscillator'
			// },
			// {
			// 	'inputs': { 'period': 14 },
			// 	'name': 'MomentumOscillator',
			// 	'type': 'oscillator'
			// },
			// { 'inputs': {}, 'name': 'UltimateOscillator', 'type': 'oscillator' },
			// { 'inputs': {}, 'name': 'IchimokuBaseLine', 'type': 'ma' },
			// { 'inputs': { 'period': 14 }, 'name': 'VWMA', 'type': 'ma' },
			// {
			// 	'inputs': { 'period': 14 },
			// 	'name': 'HullMovingAverage',
			// 	'type': 'ma'
			// },
			// {
			// 	'inputs': {},
			// 	'name': 'AvgDirectionalMovementIndexRating',
			// 	'type': 'oscillator'
			// },
			// { 'inputs': {}, 'name': 'BullBearPower', 'type': 'oscillator' },
			// { 'inputs': {}, "name": "Camarilla", "type": 'pivot' },
			// { 'inputs': {}, "name": "Classic", "type": 'pivot' },
			// { 'inputs': {}, "name": "Demark", "type": 'pivot' },
			// { 'inputs': {}, "name": "Fibonacci", "type": 'pivot' },
			// { 'inputs': {}, "name": "Woodie", "type": 'pivot' },
			// ]
		}
		let result = await AdminServicesPY.getIndicators({ 'params': JSON.stringify(obj) }).then(r => r);
		//console.log(result)
		if (result?.data?.['api-status'] !== 'error') {
			setAllIndicatorData(result?.data);
			setLoadingTechnicalsIndicators(false)
		} else {
			setAllIndicatorData([]);
			setLoadingTechnicalsIndicators(false)
		}
	};

	const getVolumeAnalysis = async () => {
		setLoadingTechnicalsVolume(true)
		const obj = {
			'symbol': SYMBOL,
			'start_date': null,
			'end_date': null,
			frequency: null
		}
		let result = await AdminServicesPY.getVolumeAnalysis({ 'params': JSON.stringify(obj) }).then(r => r);
		//console.log("volume data", result)
		if (result?.data?.['api-status'] !== 'error') {
			let res = result?.data?.sort((a, b) => new Date(b.index) - new Date(a.index))
			res = res.map(record => {
				return {
					...record,
					total_volume_nse: record.deliverable_qty_nse + record.total_traded_qty_nse,
					total_volume_bse: record.deliverable_qty_bse + record.total_traded_qty_bse,
					total_volume_combined: record.deliverable_qty_nse + record.total_traded_qty_nse + record.deliverable_qty_bse + record.total_traded_qty_bse,
				}
			})
			setAllVolumeData(res);
			setLoadingTechnicalsVolume(false)
		} else {
			setAllVolumeData([]);
			setLoadingTechnicalsVolume(false)
		}
	}

	const getRangeAnalysis = async () => {
		setLoadingTechnicalsRange(true)
		const obj = {
			'symbol': SYMBOL,
			'end_date': moment().format('YYYY-MM-DD'),
			frequency: null
		}
		let result = await AdminServicesPY.getRangeAnalysis({ 'params': JSON.stringify(obj) }).then(r => r);
		//console.log(result?.data?.[SYMBOL])
		if (result?.data?.['api-status'] !== 'error') {
			// console.log("omar", result?.data?.[SYMBOL])
			setAllRangeAnalysisData(result?.data?.[SYMBOL]);
			setLoadingTechnicalsRange(false)
		} else {
			setAllRangeAnalysisData({});
			setLoadingTechnicalsRange(false)
		}
	}

	const getData = async (screenerDatas) => {
		const hiddenMetrics = ['is_nifty_50', 'is_nifty_100', 'is_nifty_500', 'is_nifty_midcap100', 'is_nifty_smallcap100'];
		try {
			let mets = screenerDatas?.screenerMetricData;
			mets = mets?.map((el) => {
				var selectedCategory = screenerDatas?.screenerFinalData?.filter((i) => i?.category_id === el?.category_id)?.[0] || {}
				var selectedSubCategory = selectedCategory?.sub_category?.filter((i) => i?.sub_category_id === el?.sub_category_id)?.[0] || {}
				return {
					...el,
					type: el?.type || "Metric",
					// label: el?.label?.replace(/\s/g, ''),
					category_name: selectedCategory?.category_name,
					sub_category_name: selectedSubCategory?.sub_category_name,
				}
			});
			mets = mets?.filter((el) => !hiddenMetrics.includes(el?.dataIndex) && el?.metric_data_type === 'float')
			let func_map = await AdminServicesPY.loadFunctionMap().then((r) => r)
			func_map = JSON.parse(func_map?.data?.replace(/NaN/g, null)?.replace(/Infinity/g, null))
			func_map = func_map?.filter((el) => !['mavp', "groupby"]?.includes(el?.sh_name))?.filter((i) => i?.type !== "Patterns")
			func_map = func_map?.map((i) => {
				return {
					...i,
					category_name: i?.type,
					sub_category_name: i?.sub_type,
				}
			})

			let indices = await getIndexList();

			var ak2 = groupBy(func_map, "type")
			ak2 = Object?.entries(ak2)?.map(([key, val], index) => {
				return {
					[key]: Object?.entries(groupBy(val, "sub_type"))?.map(([key, val], index) => {
						return {
							category_id: index + 1,
							category_name: key,
							sub_category: [{
								sub_category_id: index + 1,
								sub_category_name: '',
								metric: val?.map((item) => {
									return { ...item, key: item?.sh_name, label: item?.display_name, value: item?.sh_name, type: item?.type }
								})
							}]
						}
					})
				}
			})

			var final = {
				"Metrics": screenerDatas?.screenerFinalData?.filter((i) => i?.category_name !== "Universe"),
				...Object.assign({}, ...ak2)
			}
			// console.log(final)
			setAlertMetaData({
				allFuncAndMetricForDrawer: final,
				allFundsData: screenerDatas?.allFunds,
				functions: func_map?.map((item) => {
					return { ...item, key: item?.sh_name, label: item?.display_name, value: item?.sh_name, type: item?.type }
				}),
				screenerMetrics: mets,
				indices: [{
					IndexNameCaps: "all",
					IndexName: "All",
					key: "all"
				}].concat(indices)
			})

			let alerts = await apiHelper({ apiName: "getStockAlertById", saveResponse: "sessionStorage", data: { clientId: user?.id }, getFresh: true });
			setalertsAllList((alerts || []))
			renderAlerts((alerts || [])?.filter((i) => i?.symbol === SYMBOL), mets, func_map?.map((item) => {
				return { ...item, key: item?.sh_name, label: item?.display_name, value: item?.sh_name, type: item?.type }
			}), {
				allFuncAndMetricForDrawer: final,
				allFundsData: screenerDatas?.allFunds,
				functions: func_map?.map((item) => {
					return { ...item, key: item?.sh_name, label: item?.display_name, value: item?.sh_name, type: item?.type }
				}),
				screenerMetrics: mets,
				indices: [{
					IndexNameCaps: "all",
					IndexName: "All",
					key: "all"
				}].concat(indices)
			})

		}
		catch (err) {
			console.log(err);
		}
	}

	const renderAlerts = async (alerts, mets, funcs, indices) => {
		for (let i = 0; i < alerts.length; i++) {
			var item = alerts[i]
			try {
				var res = await codeParserWrapper({
					code: item?.code,
					screenerMetrics: mets,
					functions: funcs
				})
				// console.log(res)
				alerts[i] = {
					...item,
					conditions: res?.conditions,
					conditionOperators: res?.conditionOperators,
					visibleCode: finalCodeRedable({
						conditions: res?.conditions,
						conditions_oper: res?.conditionOperators,
						indices: alertsMetaData?.indices || indices,
						screenerMetrics: mets
					})
				}

			} catch (error) {
				alerts[i] = null
				////console.log("logger", error)
			}
		}
		// console.log(alerts)
		setalertsList((alerts || [])?.filter((i) => i !== null))

	}

	const onUpdateUserCustomCols = async (snapshot = []) => {
		let userCustomColumns = userCustomCols?.find((el) => el?.InstrumentType === type?.strVal?.toUpperCase());
		let d = {
			snapshot, columns: userCustomColumns?.Columns?.split('|')?.filter(el => el) || [], instrument_type: type.strVal?.toUpperCase(), client_id: user?.id, uid: userCustomColumns?.UID || 0
		}
		let res = await apiHelper({ apiName: "saveCustomColumns", data: JSON.stringify(d) });
		//console.log(res);
		if (res === 'success') {
			let customCols = await apiHelper({ apiName: "getCustomColumns", data: user?.id, saveResponse: "sessionStorage", getFresh: true });
			// let cols = null;
			if (customCols) {
				// cols = customCols?.find((el) => el?.InstrumentType === type?.strVal?.toUpperCase());
				// setUserCustomColumns(cols);
				dispatch(updateCustomColumns(customCols))
			}
		}
	}

	const displayValueV2 = ({ text, descObj, roundTo }) => {
		if (String(descObj?.metric_data_type).toLowerCase() === "float") {
			if (String(descObj?.metric_unit).toLowerCase() === "rupee") {
				return CurrencyFormat(Number(text), "long", roundTo);
			} else if (String(descObj?.metric_unit).toLowerCase() === "rupee_cr") {
				return DecimalValueFormat({
					num: Number(text),
					suffix: "",
					placeholder: "-",
					decimals: roundTo,
				});
			} else if (
				String(descObj?.metric_unit).toLowerCase() === "percentage"
			) {
				////console.log('kmdkkm')
				return (
					<RedGreenText text={DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-", decimals: roundTo, })} value={Number(text)} />
				);
			} else {
				return DecimalValueFormat({
					num: Number(text),
					suffix: "",
					placeholder: "-",
					decimals: roundTo,
				});
			}
		} else if (String(descObj?.metric_data_type).toLowerCase() === "date") {
			return formatDate(Number(text));
		} else {
			return text;
		}
	};

	const navigateToSectors = (isSector = true, indGrp = false, ind = false) => {
		var data = {
			"sector": String(fundData?.sector_code),
			"indgrp": indGrp === true ? String(fundData?.industry_code) : "",
			"ind": (indGrp === true && ind === true) ? String(fundData?.nse_basic_ind_code) : "",
		}
		navigate('/stocks/sector-details', { state: { selectionData: data } })
	}

	const saveEditAlert = async (code = "", alertName = "") => {
		try {
			var res = await AdminServices.insUpStockAlert({
				data: {
					"alertId": selectedAlert?.alert_id || 0,
					"name": selectedAlert?.alert_name || alertName,
					"clientId": user?.id,
					"symbol": fundData?.symbol,
					"code": code,
					"timeframe": "1d",
					"isDeleted": 0,
					"isActive": 1,
					"lastAlertDate": ""
				}
			}).then((r) => r)
			// console.log(res?.data)
			if (res?.type) {
				toast.success(selectedAlert?.alert_id > 0 ? 'Alert updated!!' : 'Alert saved!!')
				let alerts = await apiHelper({ apiName: "getStockAlertById", saveResponse: "sessionStorage", data: { clientId: user?.id }, getFresh: true });
				setalertsAllList(alerts || [])
				renderAlerts((alerts || [])?.filter((i) => i?.symbol === SYMBOL), alertsMetaData?.screenerMetrics, alertsMetaData?.functions, alertsMetaData?.indices)
				setalertModal(false)
				setalertCreateEditModal(false)
				setselectedAlert({})
			}
		} catch (error) {
			toast.error('Something went wrong! Please try again.')
			console.log(error)
		}
	}

	const deleteAlert = async (id) => {
		try {
			let res = await AdminServices.deleteStockAlert({ alertId: id });
			// console.log(res);
			if (res?.type) {
				toast.error('Alert deleted!');
				let alerts = await apiHelper({ apiName: "getStockAlertById", saveResponse: "sessionStorage", data: { clientId: user?.id }, getFresh: true });
				setalertsAllList(alerts || [])
				renderAlerts((alerts || [])?.filter((i) => i?.symbol === SYMBOL), alertsMetaData?.screenerMetrics, alertsMetaData?.functions, alertsMetaData?.indices)
				setalertModal(false)
				setalertCreateEditModal(false)
				setselectedAlert({})
			}
		}
		catch (err) {
			toast.error('Something went wrong! Please try again.')
			console.log(err);
		}
	}

	const downloadStockReport = async () => {

		const reportHeading = document.getElementById('stockReportPDFHeading');
		const reportHeadingCanvas = await html2canvas(reportHeading, { useCORS: true });
		const reportHeadingImg = reportHeadingCanvas.toDataURL('image/png');

		const reportPdf = new jsPDF('p', 'pt', 'a4');
		// const reportHeadingProps = reportPdf.getImageProperties(reportHeadingImg);
		// const pdfWidth = reportPdf.internal.pageSize.getWidth();
		// const pdfHeight = (reportHeadingProps.height * pdfWidth) / reportHeadingProps.width;
		reportPdf.addImage(reportHeadingCanvas, 0, 0);
		reportPdf.save('scrollable-div.pdf');
	}

	React.useEffect(() => {
		resetState();
		const dem = async () => {
			try {
				var screener_datas = await screenerMetricData(type, 0, 1, 1, 1);
				//console.log(screener_datas);
				let a = screener_datas?.allFunds;
				// var a = await AdminServicesPY.getStocksColData({ stocks: [], cols: ['symbol','proper_name','mcap','sector_code','industry_code','nse_basic_ind_code'], all_cols: false }).then(r => r)
				// setAllFunds(a);
				// const result = a.filter((x) => x.symbol === SYMBOL);
				var result = await AdminServicesPY.getStocksColData({ stocks: [SYMBOL], cols: [], all_cols: true }).then(r => r)
				//console.log(result)
				try {
					result = JSON.parse(result?.data)
					result = result?.map((fund) => {
						return {
							...fund,
							// "gdf_symbol": fund?.nse_series === 2 ? fund?.symbol + ".BE" : fund?.nse_series === 3 ? fund?.symbol + ".BZ" : fund?.symbol
							"gdf_symbol": fund?.symbol
						}
					})?.[0]
				} catch (error) {
					result = {}
				}
				//console.log(result);
				setFundData(result);

				setStockSectorCLF(screener_datas?.fabricatedData?.stockSectorCLF);
				setStyleBoxes(screener_datas?.fabricatedData?.stockStyles);
				setMacroSectors(screener_datas?.fabricatedData?.macroSectors);
				setMacroSectorsCurrent(screener_datas?.fabricatedData?.macroSectors?.find((i) => i.value === result?.macro_sec_code)?.label || "-");
				setSectors(screener_datas?.fabricatedData?.sectors);
				setSectorsCurrent(screener_datas?.fabricatedData?.sectors?.find((i) => i.value === result?.sector_code)?.label);
				setIndustries(screener_datas?.fabricatedData?.industries);
				setIndustriesCurrent(screener_datas?.fabricatedData?.industries.find((i) => i.value === result?.industry_code)?.label);
				setBasicIndustries(screener_datas?.fabricatedData?.basicIndustries);
				setBasicIndustriesCurrent(screener_datas?.fabricatedData?.basicIndustries.find((i) => i.value === result?.nse_basic_ind_code)?.label);
				setscreenerMetric(screener_datas?.screenerMetricData);
				setscreenerFinalData(screener_datas?.screenerFinalData);
				setScreenerMetricDescData(screener_datas?.screenerMetricDescData);


				const getCustomCols = async () => {
					let customCols = userCustomCols;
					//console.log(userCustomCols);
					if (user?.isLogged && userCustomCols === undefined) {
						customCols = await apiHelper({ apiName: "getCustomColumns", data: user?.id, saveResponse: "sessionStorage" });
						dispatch(updateCustomColumns(customCols))
						// //console.log(customCols,userCustomCols);
					}
					let cols = null;
					if (customCols) {
						cols = customCols?.find((el) => el?.InstrumentType === type?.strVal?.toUpperCase());
					}
					let cards = createFundDetCardsData(result, screener_datas?.screenerMetricData, cols);
					setSnapshotCards(cards);
					setLoading(false);
				}

				const getShareholdings = async () => {
					let shareholdingsData = await apiHelperPY({ apiName: "getShareholdingsV1", data: { symbol: SYMBOL }, saveResponse: "" });
					if (shareholdingsData) {
						shareholdingsData = JSON.parse(JSON.stringify(shareholdingsData).replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null));
						setShareholdings(structureShareholdingData(JSON.parse(shareholdingsData?.shareholdings || {})))
						//console.log(JSON.parse(shareholdingsData?.shareholdings));
						setLoadingShareholding(false);
					}
					else {
						setShareholdings({});
						setLoadingShareholding(false);
					}
				}

				const getBulkBlockInsiderTrades = async () => {
					let res = await apiHelperPY({ apiName: "getBulkBlockInsiderTrades", data: { "params": JSON.stringify({ "symbol": SYMBOL }) }, saveResponse: "" });
					if (res) {
						res = JSON.parse(res.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null));
						setBulkBlockDeals(res)
						//console.log(res)
						setLoadingBulkBlockDeals(false);
					}
					else {
						setBulkBlockDeals({});
						setLoadingBulkBlockDeals(false);
					}
				}

				const getSastData = async () => {
					let res = await apiHelperPY({ apiName: "getSastData", data: { "params": JSON.stringify({ "symbol": SYMBOL }) }, saveResponse: "" });
					if (res) {
						res = JSON.parse(res.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null));
						// console.log(res);
						setSastData(res)
						setLoadingSAST(false);
					}
					else {
						setSastData({});
						setLoadingSAST(false);
					}
				}

				const getSegmentsData = async () => {
					let segmentData = await apiHelperPY({ apiName: "getSegmentsApi", data: result?.fs_regional_id, saveResponse: "", });
					//console.log('segments data', segmentData)
					if (segmentData) {
						setSegmentsData(segmentData);
					} else {
						setSegmentsData({})
					}
					setLoadingSegments(false);
				}

				await Promise.allSettled([getCustomCols(), getRangeAnalysis(), getShareholdings(), getBulkBlockInsiderTrades(), getSastData(), getSegmentsData(), getData(screener_datas)])

				let stockCols = ['symbol', 'nse_series', 'proper_name', 'bse_ticker', '52w_high', '52w_low', 'nse_active', 'nse_segment', 'one_day_pct', 'price', 'bse_segment',
					'mcap', 'nse_basic_ind_code', 'sh_3fac_comp', 'five_yr_pct']
				var results = await AdminServicesPY.getStocksColData({ stocks: a?.map((el) => el?.symbol), cols: stockCols, all_cols: false }).then(r => r)
				try {
					results = JSON.parse(results?.data);
					results = results?.map((fund) => {
						return {
							...fund,
							// "gdf_symbol": fund?.nse_series === 2 ? fund?.symbol + ".BE" : fund?.nse_series === 3 ? fund?.symbol + ".BZ" : fund?.symbol
							"gdf_symbol": fund?.symbol
						}
					})
					setAllFunds(results);
					//console.log(results);
				}
				catch (err) {
					//console.log(err)
				}

				const getStockInsights = async () => {
					let stockInsightsData = await apiHelperPY({ apiName: "getStockInsightsDetailed", data: { ticker: SYMBOL }, });
					if (stockInsightsData) {
						stockInsightsData = JSON.parse(JSON.stringify(stockInsightsData).replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null));
						//console.log("getStockInsights", JSON.parse(stockInsightsData));
						stockInsightsData = JSON.parse(stockInsightsData || "{}");
						setStockInsights(stockInsightsData);
					}
					else {
						setStockInsights({});
					}
					setLoadingStockInsights(false);
				}

				const getHistoricalData = async () => {
					let res8 = await apiHelperPY({ apiName: "getHistoricalCharts", data: { symbol: SYMBOL }, saveResponse: "", });
					if (res8) {
						res8 = await JSON.parse(res8.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null));
						//console.log('historicalData', res8)
						setHistoricalCharts(res8);
					} else {
						setHistoricalCharts({});
					}
					setLoadingHistoricalCharts(false);
				}

				const getStockScoreCardData = async () => {
					let ssc = await apiHelperPY({ apiName: "getStockScoreCard", data: { symbol: SYMBOL }, saveResponse: "" });
					if (ssc) {
						ssc = await JSON.parse(ssc.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null));
						let obj = {};
						for (let i = 0; i < ssc?.length; i++) {
							let key = Object.keys(ssc[i] || {})?.[0];
							obj[key] = ssc?.[i]?.[key]
						}
						setScorecard(obj)
						//console.log('getStockScoreCard', ssc, obj);
					}
					setLoadingScorecard(false);
				}

				const getChecklistData = async () => {
					try {
						let res2 = await apiHelperPY({ apiName: "getAllCheckLists", data: { symbol: SYMBOL, clientId: user?.id || 0 }, saveResponse: "", });
						//console.log("getAllCheckLists", res2)
						if (res2) {
							res2 = await JSON.parse(res2?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null));
							setAllCheckLists(res2);
							setLoadingAllChecklists(false);
						} else {
							setAllCheckLists({});
							setLoadingAllChecklists(false);
						}
					} catch (error) {
						setAllCheckLists({});
						setLoadingAllChecklists(false);
					}
				}

				const getFinStatementData = async () => {
					let finStatementsData = await apiHelperPY({
						apiName: "getFinancialStatementsV2", data: {
							fsym_id: result?.fs_regional_id,
							gen_ind_code: result?.gen_ind_code === null ? 1 : result?.gen_ind_code,
							ticker: result?.symbol
						}, saveResponse: "",
					});
					if (finStatementsData) {
						finStatementsData = await JSON.parse(JSON.stringify(finStatementsData).replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null));
						let d = JSON.parse(finStatementsData?.statements);
						//console.log('finStatementsData', d);
						setFinstatements(structureFinStData(d));
					}
					else {
						setFinstatements({})
					}
					setLoadingFinancialStatements(false);
				}

				const getEstimatesData = async () => {
					let res5 = await apiHelperPY({
						apiName: "getEstimates", data: { symbol: SYMBOL, fsym_id: result?.fs_regional_id, industry_code: result?.industry_code, },
						saveResponse: "",
					});
					//console.log("getEstimatesData", res5)
					if (res5) {
						res5 = await JSON.parse(JSON.stringify(res5).replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null));
						setEstimates(res5?.estimates || {});
						setLoadingEstimates(false);
					} else {
						setEstimates({});
						setLoadingEstimates(false);
					}
				}

				const getResourcesData = async () => {
					let resourceData = await apiHelperPY({ apiName: 'getStockResources', data: SYMBOL, saveResponse: '' });
					if (resourceData) {
						resourceData = JSON.parse(JSON.stringify(resourceData).replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))
						resourceData = typeof resourceData === 'string' ? JSON.parse(resourceData || "{}") : resourceData;
						setResources(resourceData)
						//console.log('getStockResources', resourceData)
					}
					else {
						setResources({})
					}
					setLoadingResources(false)
				}

				const getPortfolioHoldingsData = async () => {
					let portfolioHoldings = await Dashboard_Portfolio.getPortfolioHoldingsData(user, false).then((res) => { return res; })
					if (portfolioHoldings) {
						let temp = {};
						let arr = []
						let list = portfolioHoldings?.CustomAll?.filter((el) => el?.FundType === type.value && el?.FundCode === SYMBOL) || [];
						list.forEach((ele) => {
							let id = ele?.PortfolioId;
							if (temp?.[id]) {
								temp[id] += ele?.Units
							}
							else {
								temp[id] = ele?.Units
							}
						})
						for (let k in temp) {
							let j = { PortfolioId: Number(k), Quantity: temp?.[k] }
							arr.push(j)
						}
						setPortfolioObj(arr);
					} else {
						setPortfolioObj([]);
					}
				}

				await Promise.allSettled([getStockInsights(), getHistoricalData(), getStockScoreCardData(),
				getChecklistData(), getFinStatementData(), getIndicators(), getVolumeAnalysis(), getEstimatesData(), getResourcesData(), getPortfolioHoldingsData()])

			}
			catch (err) {
				//console.log(err)
			}
		};
		dem();
	}, [SYMBOL, location.pathname]);

	let tabItems = [
		{
			label: "Overview",
			key: "Overview",
			value: "Overview",
			tabsLevel: 1,
			children: (
				<>
					<StickyScrollTabs isAccordionInMob queriesToStickToBot={['#sharpelyHeader', '#customTabsBarId']} tabData={[
						{
							label: 'Historical Performance',
							key: 1,
							value: 'Performance',
							children: <DashboardChartComponent type={type} selectedFundData={fundData} hidePriceChart />,
							show: true,
							noHeading: true
						},
						{
							label: "Ownership",
							key: 4,
							value: "Ownership",
							children: <Ownership2 shareholdings={shareholdings} loadingShareholding={loadingShareholding}
								fundData={fundData} bulkBlockDeals={bulkBlockDeals} loadingBulkBlockDeals={loadingBulkBlockDeals} SYMBOL={SYMBOL}
								sastData={sastData} loadingSAST={loadingSAST} />,
							show: true,
							heading: "Ownership",
							subHeading: "An overview of who owns the company. Click on chart icon to view historical movement.",
							rightLinkText: "",
						},
						{
							label: "Business Model",
							key: 3,
							value: "BusinessModel",
							children: <BusinessModel segmentsData={segmentsData} loadingSegments={loadingSegments} fundData={fundData} />,
							show: true,
							heading: "Business model",
							subHeading: "A snapshot of business segments of the company – where is the revenue coming from, where are the assets deployed etc.",
							rightLinkText: "Detail segment analysis",
							rightLinkTo: "",
							rightTextLinkonClick: () => {
								goToCustomPillTab('Fundamentals', 'Segments', setSelectedTabItem)
							}
						},
						{
							label: "Company Profile",
							key: 4,
							value: "CompanyProfile",
							children: <CompanyProfileWidget symbol={SYMBOL?.replaceAll('&', '_')?.replaceAll('-', '_')} height={400} />,
							show: true,
							heading: "Company Profile",
							noHeading: true,
							noSubHeading: true
						}
					]
					} />
				</>
			),
			heading: "Ownership and Business Model",
			text: `Here we look at an overview of who owns ${SYMBOL} and what drives it's business.`
		},
		{
			label: "Analysis",
			key: "Analysis",
			value: "Analysis",
			tabsLevel: 1,
			children: (
				<div className='mobCardBorderRadius0'>
					<StickyScrollTabs isAccordionInMob queriesToStickToBot={['#sharpelyHeader', '#customTabsBarId']} tabData={[
						{
							label: "Summary Recommendation", key: 4, value: "SummaryRecommendation",
							children: <SummaryRecommendation fundData={fundData}
								allIndicatorData={allIndicatorData}
								loadingTechnicalsIndicators={loadingTechnicalsIndicators}
								goToTechnicals={() => { goToCustomPillTab('Technicals', 'Indicators', setSelectedTabItem) }}
								screenerMetricDescData={screenerMetricDescData} styleBoxes={styleBoxes} isMobile={isMobile} dispatch={dispatch} />,
							show: true, heading: "Summary Recommendation",
							subHeading: "A summary recommendation on the stock based on our classification and scoring techniques along with analyst recommendations.",
							rightLinkText: "", rightLinkTo: ""
						},
						{
							label: "Key talking points", key: 3, value: "KeyTalkingPoints", children: <AccessComponent component={<KeyTalkingPoints stockInsights={stockInsights}
								loadingStockInsights={loadingStockInsights} screenerMetricDescData={screenerMetricDescData} isMobile={isMobile} />} moduleKey="ADVANCE_STOCK_REPORT"
								upgradeComponent={<UpgradeToPro moduleKey="ADVANCE_STOCK_REPORT" height="13rem" />} />,
							show: true, heading: "Key talking points",
							subHeading: "Some important talking points about the company’s valuation, profitability, financial health, dividends and more.",
						},
						{
							label: "Style Analysis", key: 2, value: "StyleAnalysis", children: <AccessComponent component={<StyleAnalysis screenerMetricDescData={screenerMetricDescData}
								scorecard={scorecard} dispatch={dispatch} loadinghistoricalCharts={loadinghistoricalCharts} historicalCharts={historicalCharts}
								screenerMetric={screenerMetric} loadingScorecard={loadingScorecard} />} moduleKey="ADVANCE_STOCK_REPORT"
								upgradeComponent={<UpgradeToPro moduleKey="ADVANCE_STOCK_REPORT" height="13rem" />} />,
							heading: "Style Analysis", rightLinkText: "Learn more about stock scores", rightLinkTo: "/knowledge-base/stock-scores", show: true,
							subHeading: "A look at scores on value, momentum and quality parameters. Click on historical data to view chart. Change tabs to compare across market, sector, industry etc.",
						},
						{
							label: "Scorecard", key: 1, value: "Scorecard", children: <AccessComponent component={<Scorecard loadingScorecard={loadingScorecard}
								scorecard={scorecard} screenerFinalData={screenerFinalData} basicIndustriesCurrent={basicIndustriesCurrent} fundData={fundData}
								industriesCurrent={industriesCurrent} sectorsCurrent={sectorsCurrent} type={type} />} moduleKey="ADVANCE_STOCK_REPORT"
								upgradeComponent={<UpgradeToPro moduleKey="ADVANCE_STOCK_REPORT" height="13rem" />} />, show: true,
							subHeading: "A comprehensive scorecard to compare the stocks on all metrics with other stocks. Access both rank and scores.", heading: "Scorecard",
							rightLinkText: "How to read Scorecard?", rightLinkTo: "/knowledge-base/stocks/stock-report/stock-analysis", rightTextLinkonClick: () => {
								dispatch(toggleUserGuideModal({ value: true, type: "kbArticle", params: { article: "stock-analysis", title: "Stock Analysis" } }))
							}
						},
						{
							label: "Checklist", key: 0, value: "Checklist", children: <AccessComponent component={<Checklist allCheckLists={allCheckLists}
								loadingAllChecklists={loadingAllChecklists} screenerMetricDescData={screenerMetricDescData} fundData={fundData} screenerMetric={screenerMetric} />}
								moduleKey="ADVANCE_STOCK_REPORT" upgradeComponent={<UpgradeToPro moduleKey="ADVANCE_STOCK_REPORT" height="13rem" />} />,
							show: true, noCard: true,
							subHeading: "Analyse how the stock fares on some investment checklists created by legendary investors and gurus. Also, build your own custom checklist.",
							heading: "Checklist", rightLinkText: "What is Checklist?", rightLinkTo: "/knowledge-base/stocks/stock-report/stock-analysis",
							rightTextLinkonClick: () => {
								dispatch(toggleUserGuideModal({ value: true, type: "kbArticle", params: { article: "stock-analysis", title: "Stock Analysis" } }))
							}
						},
					]} />
				</div>
			),
			heading: `Detailed analysis of ${SYMBOL} stock`,
			text: `This intent of this section is to give you a detailed analysis of ${SYMBOL} and help you make an informed decision`
		},
		{
			label: "Fundamentals",
			key: "Fundamentals",
			value: "Fundamentals",
			tabsLevel: 1,
			children: (
				<div>
					<StickyScrollTabs isAccordionInMob queriesToStickToBot={['#sharpelyHeader', '#customTabsBarId']} tabData={[
						{
							label: "Ratios & Statistics",
							key: 2,
							value: "RatiosAndStatistics",
							show: true,
							children: <RatiosAndStatistics screenerMetricDescData={screenerMetricDescData} userAccesslevel={userAccesslevel} dispatch={dispatch} historicalCharts={historicalCharts}
								loadinghistoricalCharts={loadinghistoricalCharts} isMobile={isMobile} navigate={navigate} />,
							heading: "Ratios & Statistics",
							subHeading: "A look at some key ratios and statistics for the stock. Change tabs to find the relevant ratios. Click on chart icon to view historical analysis of a particular ratio."
						},
						{
							label: "Statements",
							key: 3,
							value: "Statements",
							show: true,
							children: <Statements loadingFinancialStatements={loadingFinancialStatements} fundData={fundData} isMobile={isMobile} finStatements={finStatements} dispatch={dispatch}
								screenerMetricDescData={screenerMetricDescData} displayValueV2={displayValueV2} />,
							heading: "Financial Statements",
							subHeading: "Financial statement of the company. Change tabs to switch between Income Statement, Balance Sheet and Cash Flow statement.",
							rightLinkText: "What are Financial Statements?",
							rightLinkTo: "/knowledge-base/fundamental-analysis/financial-statement-analysis/introduction-to-financial-statements",
							rightTextLinkonClick: () => {
								dispatch(toggleUserGuideModal({ value: true, type: "kbSubCategory", params: { subCategory: "financial-statement-analysis" } }))
							}
						},
						{
							label: "Segments Analysis",
							key: 1,
							value: "Segments",
							show: true,
							children: <Segments segmentsData={segmentsData} fundData={fundData} loadingSegments={loadingSegments}
								finStatements={finStatements} isMobile={isMobile} />,
							heading: "Segment Analysis",
							subHeading: "A detailed analysis of the segments of the Company. Select relevant financial year from the dropdown or switch tabs to view entire history in one view.",
						},
						{
							label: "Dividends",
							key: 0,
							value: "Dividends",
							show: true,
							children: <Dividends dividends={dividends} screenerMetricDescData={screenerMetricDescData} finStatements={finStatements} fundData={fundData} dispatch={dispatch}
								loadingFinancialStatements={loadingFinancialStatements} isMobile={isMobile} displayValueV2={displayValueV2} />,
							heading: "Dividends",
							subHeading: "See how the company has been paying dividends over the years including payout ratio and dividend yield",
							rightLinkText: "What is dividend yield?",
							rightLinkTo: "",
							rightTextLinkonClick: () => {
								let obj = screenerMetricDescData?.find((el) => el?.desc_id === 205);
								dispatch(toggleMetricDescModal({
									value: true,
									params: {
										title: obj?.name,
										description: obj?.description
									}
								}))
							}
						},
						{
							label: "Corporate Actions",
							key: -1,
							value: "CorporateActions",
							show: true,
							children: <CorporateActions fundData={fundData} finStatements={finStatements} loadingFinancialStatements={loadingFinancialStatements}
								accessLevelData={accessLevelData} dispatch={dispatch} />,
							heading: "Corporate Actions",
							subHeading: "A complete summary of all important corporate actions of the company including splits, bonuses, rights issues and dividend payouts.",
							rightLinkText: "What is stock split?",
							rightTextLinkonClick: () => {
								dispatch(toggleUserGuideModal({
									value: true, type: "kbArticle", params: {
										article: "what-is-stock-split:-a-comprehensive-overview",
										title: "What is Stock Split: A Comprehensive Overview"
									}
								}))
							}
						},
					]} />
				</div>
			),
			heading: `Exploring the fundamentals of ${SYMBOL}`,
			text: `This section takes a deep dive into fundamentals starting with ratios and statistics, along with financial statements, dividend history and so on`
		},
		{
			label: "Technicals",
			key: "Technicals",
			value: "Technicals",
			tabsLevel: 1,
			heading: `Exploring the technicals of ${SYMBOL}`,
			text: `This section takes a deep dive into technicals starting with RSI, Moving Averages, MACD, MFI, Stochastic and so on`,
			children: <TechnicalAnalysis
				SYMBOL={SYMBOL}
				fundData={fundData}
				isMobile={isMobile}
				allIndicatorData={allIndicatorData}
				allVolumeData={allVolumeData}
				allRangeAnalysisData={allRangeAnalysisData}
				loadingTechnicalsIndicators={loadingTechnicalsIndicators}
				loadingTechnicalsVolume={loadingTechnicalsVolume}
				loadingTechnicalsRange={loadingTechnicalsRange}
				onRefereshClick={() => { getIndicators(); getRangeAnalysis() }} />,
		},
		{
			label: "Forecast",
			key: "Forecast",
			value: "Forecast",
			children: <Forecast loadingEstimates={loadingEstimates} fundData={fundData} estimates={estimates} SYMBOL={SYMBOL} accessLevelData={accessLevelData}
				dispatch={dispatch} isMobile={isMobile} />,
		},
		{
			label: "Competition",
			key: "Competition",
			value: "Competition",
			children: (
				<div style={{ paddingTop: "1.25rem", paddingBottom: "1.25rem", background: "var(--white)", borderRadius: isMobile ? "" : "var(--borderRadius)", }}
					className={'leftRightPadContainer'}>
					<div className='d-flex justify-content-between' style={{ gap: isMobile ? "0" : "1.25rem", flexDirection: isMobile ? "column" : "row", paddingBottom: isMobile ? "1rem" : "0" }}>
						<CardHeader heading='Competition Analysis' hideHeaderBlueLine textClassName={isMobile ? "textMD w-500" : 'textXL w-700'} headingType={2} />
						{!isMobile && <span style={{ cursor: "pointer", color: "var(--primary)" }} className='textXS w-500' onClick={() => {
							dispatch(toggleUserGuideModal({ value: true, type: "kbArticle", params: { article: "competition-analysis", title: "Competition Analysis" } }))
						}} >Tips and Tricks &gt;</span>}
					</div>
					<div className='textSM dark3' style={{ margin: "0.25rem 0 1.25rem", maxWidth: isMobile ? "100%" : "60%" }}>
						Compare how this stock fares with other stocks in same sector, industry group or industry by switching tabs.
						{isMobile && <div> <span style={{ cursor: "pointer", color: "var(--primary)" }} className='textXS w-500' onClick={() => {
							dispatch(toggleUserGuideModal({ value: true, type: "kbArticle", params: { article: "competition-analysis", title: "Competition Analysis" } }))
						}}>Tips and Tricks &gt;</span></div>}
					</div>
					<InstrumentListTable showBubbleChartBtn finalList={allFunds} tableType={9} peersFilters={[
						["nse_basic_ind_code", fundData?.nse_basic_ind_code],
						["industry_code", fundData?.industry_code],
						["sector_code", fundData?.sector_code],
					]} initialColumns={[...screenerMetric.filter((item) => item.isInitial === true),]}
						onPeersBtnClick={(data) => {
							let temp = data?.columns?.filter((ele) => ele?.metric_data_type === "float")?.map((ele) => {
								let min = Math.min(...(data?.funds?.map((el) => el?.[ele?.metric_col_code]).filter((ele) => ele) || []));
								let max = Math.max(...(data?.funds?.map((el) => el?.[ele?.metric_col_code]).filter((ele) => ele) || []));
								let obj = {
									title: ele?.metric_name,
									description: "",
									currentVal: fundData?.[ele?.metric_col_code || ""],
									currentValStr: displayValue({ text: fundData?.[ele?.metric_col_code || ""], metricObj: ele, }),
									min: min,
									max: max,
									minStr: displayValue({ text: min, metricObj: ele }),
									maxStr: displayValue({ text: max, metricObj: ele }),
									minStrLabel: "Lowest",
									maxStrLabel: "Highest",
									show: true,
								};

								return obj;
							});
							setPeersData({ data, sliders: temp } || {});
							setIndustryPeersModal(true);
						}} isCard={false} screenerFinalData={screenerFinalData} fundData={fundData} showToolsBtnsInTab showHeatmapBtn />
					<CustomDrawer isOpen={industryPeersModal} toggle={() => setIndustryPeersModal(false)} title={`Other ${type.dname} in ${peersData?.data?.selectedTab?.label === "Industry"
						? basicIndustriesCurrent : peersData?.data?.selectedTab?.label === "Industry Group" ? industriesCurrent : sectorsCurrent} ${peersData?.data?.selectedTab?.label}`}>
						<CustomRangeSliders2 sliders={peersData?.sliders || []} />
					</CustomDrawer>
				</div>
			),
		},
		{
			label: "Resources",
			key: "Resources",
			value: "Resources",
			children: <Resources resources={resources} loadingResources={loadingResources} isMobile={isMobile} allFunds={allFunds} />
		}
	];

	tabItems = tabItems?.filter((el) => el);

	if (loading) {
		return (
			<div className="loaderContainer" style={{ height: "100%" }}>
				<ActivityLoaderMintbox showQuote={true} />
			</div>
		);
	}

	return (
		<React.Fragment>
			<Head title={`${fundData?.proper_name} Stock, Results, NSE, BSE Live Charts Updates and Analysis`}
				description={`Access real time stock market updates, get stock performance analysis of 
      ${fundData?.proper_name} with sharpely, market charts and stock market research dashboard. Stay informed with real-time data and make better investment decisions.`} />
			{newListing ? <div className='d-flex flex-column align-items-center' style={{ marginTop: "2rem" }}>
				<img loading="lazy" src={'https://img.freepik.com/free-vector/tiny-people-examining-operating-system-error-warning-web-page-isolated-flat-illustration_74855-11104.jpg?w=2000&t=st=1690287768~exp=1690288368~hmac=b25307cf5b151f34118b8ff1f41530dcb9d32e63adc372d7621b58b7e18a80b4'} style={{ width: "20rem" }} />
				<div className='textSM w-500 dark3' style={{ maxWidth: "50ch", textAlign: "center", marginTop: "1.25rem" }}>
					The stock has recently listed and we do not have enough data to present any meaningful analysis.
				</div>
			</div> : <div className='topLevelContainer' style={{ padding: 0 }} >
				<div className='normalContainer' style={{ gap: 0 }}>
					{/* <div>
						<button className="btnBlack" onClick={downloadStockReport}>Download Report</button>
					</div> */}
					{/* Stock Report PDF heading start */}
					{/* <div style={{ display: "none", padding: "1.25rem", background: "var(--black)" }} id="stockReportPDFHeading">
						<h1 className={"margin0 textXL w-700 white"} style={{ flex: isMobile ? 1 : '', width: isMobile ? '1px' : 'auto', fontSize: isMobile ? "18px" : "", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
							{fundData?.proper_name}
						</h1>
						<div className="d-flex align-items-end justify-content-between" style={{ gap: "1.25rem", marginTop: "1.25rem" }}>
							<div className="d-flex textXS white flex-column flex-sm-row" style={{ gap: isMobile ? "0.25rem" : "1rem", flex: 1 }}>
								<div>
									Sector:{" "}<span className="white w-500 linkText" onClick={() => { navigateToSectors() }}>{sectorsCurrent}</span>
								</div>
								<div>
									Industry Group:{" "}<span className="white w-500 linkText" onClick={() => { navigateToSectors(true, true) }}>{industriesCurrent}</span>
								</div>
								<div>
									Industry:{" "}<span className="white w-500 linkText" onClick={() => { navigateToSectors(true, true, true) }}>{basicIndustriesCurrent}</span>
								</div>
							</div>
							<div className="d-flex align-items-center" style={{ gap: "1rem" }}>
								<div style={{ textAlign: "right" }}>
									<div className="textSM w-500 white">sharpely Stock Report</div>
									<div className="textSM w-500 white">{moment(new Date()).format('MMM DD, YYYY h:mm A')}</div>
								</div>
								<Logo />
							</div>
						</div>
					</div> */}
					{/* Stock Report PDF heading end */}
					<Card style={{ paddingTop: "1.25rem", paddingBottom: "0.5rem" }} id="topHeadingDetailCard" bodyStyle={{ padding: 0 }}
						className={`leftRightPadContainer ${isDev ? 'maxWidthContainer' : ""}`}>
						{fundData?.is_asm ? <Alert style={{ marginBottom: 20 }}
							message={<span>The stock has been placed in Additional Surveillance Measure ({<a href="https://www.nseindia.com/regulations/additional-surveillance-measure" target="_blank">ASM</a>}) list {fundData?.asm_stage}.</span>}
							type="warning"
							showIcon
							closable
						/> : fundData?.is_gsm ? <Alert style={{ marginBottom: 20 }}
							message={<span>The stock has been placed in Graded Surveillance Measure ({<a href="https://www.nseindia.com/regulations/graded-surveillance-measure" target="_blank">GSM</a>}) list {fundData?.gsm_stage}.</span>}
							type="warning"
							showIcon
							closable
						/> : fundData?.is_esm ? <Alert style={{ marginBottom: 20 }}
							message={<span>The stock has been placed in Enhanced Surveillance Measure ({<a href="https://www.nseindia.com/regulations/enhanced-surveillance-measure" target="_blank">ESM</a>}) list {fundData?.esm_stage}.</span>}
							type="warning"
							showIcon
							closable
						/> : <></>}
						<div style={{ display: 'flex', gap: '1rem' }}>
							<div style={{ flex: 1 }}>
								<div style={{ display: "flex", gap: "1.25rem", justifyContent: "space-between", flexDirection: isMobile ? "column" : "row" }}>
									<div className="d-flex" style={{ gap: "1rem", flex: 1 }}>
										<AMCIcon fundType={type?.value} amcName={SYMBOL} type="square" height={isMobile ? "4rem" : "3rem"} width={isMobile ? "4rem" : "3rem"}
											bseTicker={fundData?.bse_ticker} />
										<div style={{ flex: 1 }}>
											<div style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: isMobile ? "space-between" : "start" }}>
												<h1 className={isMobile ? "margin0 w-500 textLG" : "margin0 textLG w-700"} style={{ flex: isMobile ? 1 : '', width: isMobile ? '1px' : 'auto', fontSize: isMobile ? "18px" : "", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
													{fundData?.proper_name}
												</h1>
												{/* {fundData?.nse_segment !== null && <a href={`https://www.nseindia.com/get-quotes/equity?symbol=${SYMBOL}`} target='_blank'><Tag style={{ margin: 0 }} color='var(--green)'>NSE{fundData?.nse_segment === "SM" ? "-SM" : "-EQ"}</Tag></a>}
												{fundData?.bse_segment !== null && <a href={`https://www.bseindia.com/stock-share-price/${fundData?.proper_name?.split(' ')?.join('-')?.toLowerCase()}/${SYMBOL?.toLowerCase()}/${fundData?.bse_ticker?.split('-')?.[0]}/`} target='_blank'><Tag style={{ margin: 0 }} color='orange'>BSE</Tag></a>} */}
												<div style={{ display: 'flex', gap: '0.5rem' }}>
													<WatchList type={type.value} instrument={fundData} />
													{/* {<div style={{ cursor: 'pointer' }}>
														<SubscribeContentWrapper moduleKey="STOCK_ALERTS" onClick={(e) => {
															if (alertsList?.length === 0) {
																setselectedAlert({})
																setalertCreateEditModal(true)
															} else {
																setalertModal(true);
															}
														}} lockIconContainerStyles={{ top: 0, right: 1 }}>
															<Tooltip title="Stock alert" arrow={false} style={{ cursor: 'pointer' }}>
																{alertsList?.length === 0 ?
																	<BiAlarmAdd size={window.innerWidth > 760 ? '1.4rem' : '18px'} />
																	:
																	<Badge count={alertsList?.length} size="small" color="var(--primary)" offset={[2, 2]}><BiAlarmAdd size={window.innerWidth > 760 ? '1.4rem' : '18px'} /></Badge>
																}
															</Tooltip>
														</SubscribeContentWrapper>
													</div>} */}
												</div>
											</div>
											<div className={isMobile ? "textSM dark3 w-500" : "textSM dark3 w-700"} style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
												{SYMBOL}
												{fundData?.nse_segment !== null && <a href={`https://www.nseindia.com/get-quotes/equity?symbol=${SYMBOL}`} target='_blank'><Tag s color='var(--green)'>NSE{fundData?.nse_segment === "SM" ? "-SM" : "-EQ"}</Tag></a>}
												{fundData?.bse_segment !== null && <a href={`https://www.bseindia.com/stock-share-price/${fundData?.proper_name?.split(' ')?.join('-')?.toLowerCase()}/${SYMBOL?.toLowerCase()}/${fundData?.bse_ticker?.split('-')?.[0]}/`} target='_blank'><Tag color='orange'>BSE</Tag></a>}
											</div>
											{
												!isMobile &&
												<div className="d-flex textXS dark3 flex-column flex-sm-row mt-1" style={{ gap: isMobile ? "0.25rem" : "1rem" }}>
													<div>
														Sector:{" "}<span className="dark4 w-500 linkText" onClick={() => { navigateToSectors() }}>{sectorsCurrent}</span>
													</div>
													<div>
														Industry Group:{" "}<span className="dark4 w-500 linkText" onClick={() => { navigateToSectors(true, true) }}>{industriesCurrent}</span>
													</div>
													<div>
														Industry:{" "}<span className="dark4 w-500 linkText" onClick={() => { navigateToSectors(true, true, true) }}>{basicIndustriesCurrent}</span>
													</div>
												</div>
											}
										</div>
									</div>
									<div>
										<StockLivePrice symbol={SYMBOL} fundData={fundData} format={8} />
										{<div style={{ display: "flex", gap: "1.25rem", marginTop: '0.5rem', position: isMobile ? '' : 'absolute', right: '1.25rem', zIndex: 11 }}>
											<SubscribeContentWrapper moduleKey="STOCK_ALERTS" onClick={(e) => {
												if (alertsList?.length === 0) {
													setselectedAlert({})
													setalertCreateEditModal(true)
												} else {
													setalertModal(true);
												}
											}} lockIconContainerStyles={{ top: 0, right: 1 }}>
												<button className="btnWhite " style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
													<img loading="lazy" alt="" style={{ height: "14px" }} src={`${flaticonSvg}fi-rr-bell-ring.svg`} />
													{alertsList?.length === 0 ? "Create alert" : `Alerts (${alertsList?.length})`}
												</button>
											</SubscribeContentWrapper>
											{user?.id <= 7 && <div style={{ width: '7rem' }}>
												<SharpelyGateway2 IS_BASKET={false} INSTRUMENT_TYPE={4} FUNDDATA={fundData} TRANSACTION_TYPE={'BUY'} PORTFOLIO_OBJ={portfolioObj} />
											</div>}
										</div>}
									</div>
								</div>
							</div>
						</div>
					</Card>
					<CustomTabs tabItems={tabItems} value={selectedTabItem} onChange={(e) => { setSelectedTabItem(e); window.scrollBy({ top: 1 }) }}
						header={<div style={{
							display: "flex", alignItems: "center", gap: "0.75rem",
							flex: 1, justifyContent: "flex-end",
						}}>
							<AMCIcon fundType={type?.value} amcName={SYMBOL} type="square" height="1.5rem" width="1.5rem" bseTicker={fundData?.bse_ticker} />
							<span className='textMD dark3 w-700'>{SYMBOL}</span>
							<StockLivePrice symbol={SYMBOL} fundData={fundData} format={5} showTime={false} />
						</div>} childContainerId='stockDetailCustomTabsChild' />
					<div style={{}} id={"stockDetailCustomTabsChild"} className={`${isDev ? 'maxWidthContainer' : ""}`}>
						{selectedTabItem === 'Overview' && <div>
							<div className={isMobile ? "mainContainer" : "mainContainer leftRightPadContainer"} style={{
								paddingTop: isMobile ? "0" : "1.25rem", paddingBottom: isMobile ? "0" : "1.25rem",
								gap: isMobile ? "0" : "1.25rem", justifyContent: 'stretch'
							}}>
								<div id="omkar" className="leftContainerV2">
									<DashboardChartComponent type={type} selectedFundData={fundData} hidePerformanceChart />
								</div>
								{!isMobile && <div className="rightContainerV2" style={{ padding: isMobile ? "1rem" : "", maxHeight: '100%' }}>
									<DashboardChartMetrics fundData={fundData} screenerFinalData={screenerFinalData} type={type} screenerMetric={screenerMetric}
										screenerMetricDescData={screenerMetricDescData} userCustomColumns={userCustomCols} updateUserCustomCols={onUpdateUserCustomCols}
										snapshotMetricsLoading={false} isCard={true} itemInGroups={2} />
								</div>}
							</div>
							<Divider style={{ margin: isMobile ? "0" : '0.5rem 0', background: "var(--grey3)" }} />
						</div>}
						{tabItems?.find((el) => el.value === selectedTabItem)?.children}
					</div>
					<div className={`${isDev ? 'maxWidthContainer' : ""}`}>
						{fundData?.nse_basic_ind_code && (<>
							<Divider style={{ margin: isMobile ? "0 0 1rem" : '0.5rem 0', background: "var(--grey3)" }} />
							<SimilarFunds funds={allFunds.filter((f) => f.nse_basic_ind_code === fundData?.nse_basic_ind_code && f.symbol !== fundData?.symbol)?.sort((a, b) => b.mcap - a.mcap)?.slice(0, 10)} type={type}
								heading={`${type.dname} in Industry: ${basicIndustriesCurrent}`} style={{}} bodyStyle={{ padding: "1.25rem 0" }}
								className={'leftRightPadContainer'} />
						</>)}
						{fundData?.sh_3fac_comp && fundData?.sh_3fac_comp > 20 && (<>
							<Divider style={{ margin: isMobile ? "1rem 0" : '0.5rem 0', background: "var(--grey3)" }} />
							<SimilarFunds funds={allFunds.filter((f) => {
								let j = Math.abs(f?.sh_3fac_comp - fundData?.sh_3fac_comp);
								return j <= 2 && f.symbol !== fundData?.symbol;
							})?.sort((a, b) => b.mcap - a.mcap)?.slice(0, 10)} type={type} heading={`${type.dname} having similar QVM score`} showQVM={true} style={{}} bodyStyle={{ padding: "1.25rem 0" }}
								className={'leftRightPadContainer'} />
						</>)}
					</div>
				</div>
			</div>}




			{/* Alerts */}
			<Drawer
				width={'min(100%, 25rem)'}
				open={alertModal}
				onClose={() => {
					setalertModal(!alertModal)
				}}
				closable={false}
				title={<div
					className='d-flex justify-content-between align-items-center'>
					<div style={{ display: "flex", flexDirection: "column", gap: "0.1rem" }}>
						<div className="textSM w-500">My alerts for {fundData?.proper_name}</div>
					</div>
					<IoIosClose onClick={() => {
						setalertModal(!alertModal)
					}} style={{ cursor: "pointer" }} size='1.5rem' />
				</div>}
				footer={<div className="w100">
					<SubscribeContentWrapper moduleKey="STOCK_ALERTS" checkLimit usageCount={alertsAllList?.length} onClick={(e) => {
						setselectedAlert({})
						setalertCreateEditModal(true)
					}} >
						<button className="btnPrimary  w100 text-center" style={{ display: "flex", alignItems: "center", gap: "0.5rem", justifyContent: "center" }}>
							<HiBellAlert />
							Create new alert
						</button>
					</SubscribeContentWrapper>
				</div>}
				styles={{ header: { padding: "1rem 1.25rem" }, body: { padding: "0rem" }, footer: { padding: "1rem 1.25rem" } }}>
				{alertsList?.length === 0 && <div className="mt-5" style={{ flex: 1, display: "flex", flexDirection: "column", gap: "1rem", justifyContent: "center", alignItems: "center" }}>
					<div className="textSM w-bold">Create your 1st alert for {fundData?.proper_name}</div>
					<button className="btnPrimary" onClick={() => { setalertCreateEditModal(true) }}>Create Alert</button>
				</div>}
				{alertsList?.map((item) => {
					return <div className="m-4" style={{ border: "1px solid var(--grey3)", borderRadius: 4 }}>
						<div className="p-3" style={{ display: "flex", flexDirection: "column", gap: "0.6rem" }} onClick={() => {
							setselectedAlert(item)
							setalertCreateEditModal(true)
						}}>
							<div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
								<div style={{ flex: 1 }} className="textSM w-500 textEllipsis1">{item?.alert_name || "Alert Name"}</div>
								<div onClick={() => {
									setselectedAlert(item)
									setTimeout(() => {
										setalertCreateEditModal(true)
									}, 100);
								}} style={{ display: "flex", alignItems: "center", cursor: "pointer", justifyContent: "center" }}>
									<BiEdit color="var(--primary)" size={'1rem'} />
								</div>
								<ConfirmModal trigger={
									<div style={{ display: "flex", alignItems: "center", cursor: "pointer", justifyContent: "center" }}>
										<MdOutlineDeleteOutline color="var(--red)" size='1rem' />
									</div>
								} triggerStyle={{}} title={'Delete alert'}
									description={'Are you sure want to delete this alert?'} width='25rem' onConfirm={() => {
										deleteAlert(item?.alert_id)
									}} modalStyles={{
										right: 0, position: "fixed", top: '30vh',
										// transform: "translateY(-50%)",
										transition: "none"
									}} />
							</div>

							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<div>
									<div className="textXS w-500 dark2">Status</div>
									<div className="textXS w-500"><Badge className="textXS w-500" status={item?.is_active ? "success" : "processing"} text={item?.is_active ? "Active" : "Triggered"} /></div>
								</div>
								<div>
									<div className="textXS w-500 dark2">Created on</div>
									<div className="textXS w-500">{formatDate(item?.created_on)}</div>
								</div>
								<div>
									<div className="textXS w-500 dark2">Last triggered on</div>
									<div className="textXS w-500">{item?.is_active === 0 ? formatDate(item?.last_alert_date) : "-"}</div>
								</div>

							</div>
							<div>
								<div className="textXS dark2 textEllipsis2">{item?.visibleCode}</div>
							</div>
						</div>
					</div>
				})}
			</Drawer>
			{alertCreateEditModal && <Drawer
				open={alertCreateEditModal}
				onClose={() => {
					setselectedAlert({})
					setalertCreateEditModal(!alertCreateEditModal)
				}}
				closable={false}
				width={"60vw"}
				title={<div
					className='d-flex justify-content-between align-items-center'>
					{selectedAlert?.alert_name ? <div className="textSM w-500">{selectedAlert?.alert_name}</div> : <div style={{ display: "flex", flexDirection: "column", gap: "0.1rem" }}>
						<div className="textSM w-500">Build an alert for {fundData?.proper_name}</div>
						<div className="textXS w-500 dark2">You have <span className="textXS w-500 dark4">{alertsDetailedAccess?.remainingLimit}</span> out of <span className="textXS w-500 dark4">{alertsDetailedAccess?.maxLimit}</span> alert credits left.</div>
					</div>}
					<IoIosClose onClick={() => {
						setselectedAlert({})
						setalertCreateEditModal(!alertCreateEditModal)
					}} style={{ cursor: "pointer" }} size='1.5rem' />
				</div>}
				footer={null}
				styles={{ header: { padding: "1rem 1.25rem" }, body: { padding: "0rem", display: "flex", flexDirection: "column", flex: 1 } }}>
				<AlertBuilder
					fundData={fundData}
					allFuncAndMetricForDrawer={alertsMetaData?.allFuncAndMetricForDrawer}
					type={type}
					selectedAlert={selectedAlert}
					allFundsData={alertsMetaData?.allFundsData}
					functions={alertsMetaData?.functions}
					screenerMetrics={alertsMetaData?.screenerMetrics}
					indices={alertsMetaData?.indices}
					saveAlert={async (code, name) => {
						saveEditAlert(code, name)
					}}
				/>
			</Drawer>}
		</React.Fragment>
	);
};

export default StockDetail;