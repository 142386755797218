import React from 'react';
import { Skeleton } from 'antd';

const ListLoader = () => {
    let items = new Array(4).fill(1);
    return (
        <div className=''>
            {items?.map((ele, i) => (
                <div key={i + 1} className='pad20Side12VerContainer d-flex align-items-center justify-content-between' style={{ gap: "1rem" }} >
                    <Skeleton.Input active={(i + 1) % 2} style={{ height: "1rem" }} />
                    <Skeleton.Avatar shape='square' style={{ height: "1rem", width: '3rem', borderRadius: "var(--borderRadius)" }} />
                </div>
            ))}
        </div>
    );
};

export default ListLoader;