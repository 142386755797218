import Head from "Layout/Head";
import React from "react";

export default function TermsAndConditions() {

    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';

    return (
        <React.Fragment>
            <Head title='Terms And Conditions' />
            <main className="marketing">
                <div style={{ background: "var(--blueShade)" }}>
                    <div className={`padAll marHomeResMainCard ${isDev ? "maxWidthContainer" : ""}`}>
                        <h1 className="headXLtoSM black w-700 margin0">
                            Terms and conditions
                        </h1>
                    </div>
                </div>
                <div className={`padAll ${isDev ? "maxWidthContainer" : ""}`}>
                    <div className="textMDtoSM black w-500 dark3" style={{ marginBottom: "1.5rem", opacity: 0.8 }}>
                        Please read these terms of use carefully before using or accessing our website, mobile app or content on any other platform. If you continue to browse and use any of these, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy and our disclaimer govern Mintbox Solutions Pvt. Ltd. relationship with you.
                    </div>
                    <div className="textMDtoSM black w-500 dark3" style={{ marginBottom: "1.5rem", opacity: 0.8 }}>
                        The term Mintbox Solutions Pvt. Ltd. refers to the owner of the website. The term “you” refers to the user or viewer of our website, mobile app and content.
                    </div>
                    {privacyData?.map((item, i) => (
                        <div key={item.section}>
                            <h2 className="headSMtoTextLG dark4 w-500 margin0">
                                {i + 1}. {item.section}
                            </h2>
                            <div style={{ marginTop: "1.25rem" }}>
                                {item.content?.map((ele, j) => (
                                    <div className="textMDtoSM black w-500" style={{ marginBottom: "1.5rem", opacity: 0.8 }} key={j}>
                                        {ele}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </main>
        </React.Fragment>
    )
}

const privacyData = [
    {
        section: "Terms of use",
        content: ["You understand and accept that Mintbox Solutions Pvt. Ltd. maintains the web site https://www.sharpely.in/ a mobile app “sharpely” on iOS App Store & Google Play store, and content on other third-party platforms (going ahead referred to as “Site”) to provide visitors with information about Mintbox Solutions Pvt. Ltd., its services and products and to facilitate communication with Mintbox Solutions Pvt. Ltd. and availing its services. You also accept that visitors to the Site are required to read the below terms and use of the Site constitutes your acceptance and agreement to be bound by such terms, and the changes therein to the Terms from time to time, relating to your usage of the Site as communicated and made available on the Site.", `You are aware and accept that all information, content, materials, products (including, but not limited to text, content, photographs, graphics, video and audio content) on the Site is protected by copyright in the favour of Mintbox Solutions Pvt. Ltd. under applicable copyright laws and is also protected otherwise under general intellectual property law.`, `You understand and accept that all information submitted by you through the Site shall be deemed the property of Mintbox Solutions Pvt. Ltd., and Mintbox Solutions Pvt. Ltd. shall be free to use any ideas, concepts, know-how or techniques provided by you at the Site, in any manner whatsoever. On initiating a contact through the Site you agree to being contacted by Mintbox Solutions Pvt. Ltd. or any other entities with whom Mintbox Solutions Pvt. Ltd. has entered into an arrangement.`,
            `You agree that you shall not copy, reproduce, sell, redistribute, publish, enter into a database, display, perform, modify, transmit, license, create derivatives from, transfer or in any way exploit any part of any information, content, materials, services available from or through the Site, except that which you may download for your own personal, non-commercial use.`, 'You agree that you will not use Mintbox Solutions Pvt. Ltd.’s Site for any purpose that is unlawful or prohibited by these Terms. You also agree you will not use the Site in any manner that could damage, disable or impair the Site or interfere with any other party’s use or enjoyment of the Site.',
            'You acknowledge that the software and hardware underlying the Site as well as other Internet related software which are required for accessing the Site are the legal property of the respective Vendors. The permission given by Mintbox Solutions Pvt. Ltd. to access the Site will not convey any proprietary or ownership rights in the above software / hardware. You agree that you shall not attempt to modify, translate, disassemble, decompile or reverse engineer the software / hardware underlying the Site or create any derivative product based on the software / hardware.',
            'You understand and accept that not all the products and services offered on this Site are available in all geographic areas and you may not be eligible for all the products or services offered by Mintbox Solutions Pvt. Ltd. on the Site. Mintbox Solutions Pvt. Ltd. reserves the right to determine the availability and eligibility for any product or service.', 'Mintbox Solutions Pvt. Ltd. shall not be liable if any transaction does not fructify or may not be completed or for any failure on part of the Mintbox Solutions Pvt. Ltd. to perform any of its obligations under these terms and conditions or those applicable specifically to its services/facilities if performance is prevented, hindered or delayed by a Force Majeure event (defined below) and in such case its obligations shall be suspended for so long as the Force Majeure event continues.', `“Force Majeure Event” means any event due to any cause beyond the reasonable control of Mintbox Solutions Pvt. Ltd., including without limitations, unavailability of any communication systems, breach, or virus in the processes or payment or delivery mechanism, sabotage, fire, flood, explosion, acts of god, civil commotion, strikes or industrial action of any kind, riots, insurrection, war, acts of government, computer hacking, unauthorized access to computer data and storage devices, computer crashes, malfunctioning in the computer terminal or the systems getting affected by any malicious, destructive or corrupting code or program, mechanical or technical errors/failures or power shut down, faults or failures in telecommunication etc.`]
    },
    {
        section: "Links to other sites",
        content: ["You understand and accept that Mintbox Solutions Pvt. Ltd. is not responsible for the availability of content or other services on third party sites linked from the Site. You are aware that access of hyperlinks to other internet sites are at your own risk and the content, accuracy, opinions expressed, and other links provided by these sites are not verified, monitored or endorsed by Mintbox Solutions Pvt. Ltd. in any way. Mintbox Solutions Pvt. Ltd. does not make any warranties, and expressly disclaims all warranties express or implied, including without limitation, those of merchantability and fitness for a particular purpose, title or non-infringement with respect to any information or services or products that are available or advertised or sold through these third-party websites."]
    },
    {
        section: "Alerts",
        content: ["Mintbox Solutions Pvt. Ltd. is dedicated to providing timely alerts through SMS, email, WhatsApp, and push notifications. By receiving alerts from Mintbox Solutions Pvt. Ltd., you acknowledge that you have received the information on the mobile number or email address you provided on the sharpely Platform or while availing of any sharpely Platform Services. By registering on the sharpely Platform, you grant Mintbox Solutions Pvt. Ltd. the right to use the contact details you provided as your primary and default communication address for all sharpely Platform Services, without any doubt.", "It is important to note that Mintbox Solutions Pvt. Ltd. is not obligated to verify the identity of the recipient(s) of the alerts. Mintbox Solutions Pvt. Ltd. cannot be held responsible for any issues arising from the unavailability of the SMS, email, or push notification service.", "You should be aware that the SMS, email, WhatsApp, and push notification service offered by Mintbox Solutions Pvt. Ltd. is an additional convenience that may have errors, omissions, or inaccuracies. If you identify any errors in the alert content, kindly inform Mintbox Solutions Pvt. Ltd. promptly. We will take immediate action to rectify any errors.", "Please understand that Mintbox Solutions Pvt. Ltd. relies on Third Party Service Provider(s) for enabling SMS, email alerts, WhatsApp, push notifications, and OTPs. The timely delivery and accuracy of these alerts depend on multiple factors, including the infrastructure and connectivity of the Third Party Service Provider(s). Mintbox Solutions Pvt. Ltd. does not guarantee timely or delayed delivery and shall not be accountable for non-delivery, delayed delivery, distorted messages, or alerts."]
    },
    {
        section: "Limitation of liability",
        content: ["YOU UNDERSTAND AND AGREE THAT Mintbox Solutions Pvt. Ltd. ASSUMES NO LIABILITY WHATSOEVER REGARDING THE TIMELINESS, COMPLETENESS, ACCURACY, ADEQUACY, FULLNESS, FUNCTIONALITY AND RELIABILITY OF THE INFORMATION, STATEMENTS, OPINIONS, STATISTICS, PRODUCTS, SERVICES AND MATERIALS CONTAINED, PROVIDED, POSTED OR EXPRESSED ON THE SITE AND WILL NOT BE LIABLE FOR ANY DAMAGES, WHETHER DIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL INCURRED BY ANY USER OF THIS SITE ON ACCOUNT OF THE USE OF THE INFORMATION, STATEMENTS, OPINIONS, STATISTICS, PRODUCTS, SERVICES AND MATERIALS CONTAINED, PROVIDED, POSTED OR EXPRESSED IN THE SITE, WHETHER OR NOT THE OPERATORS OF THE SITE KNEW OR SHOULD HAVE KNOWN THAT SUCH DAMAGES MIGHT BE INCURRED, INCLUDING BUT NOT LIMITED TO LOSS OF INCOME OR PROFITS OR MARKET VALUE LOSS ON THE SALE OR PURCHASE OF SECURITIES AND/OR FUNDS", "Any advice or recommendation on the Site has been made on a general basis without taking into account or considering the investment objective, financial situation or particular need of any user or reader, any specific person or group of persons. Any reliance on such advice or recommendation is entirely at your own risk IN ALL CASES, YOU SHOULD ALWAYS SEEK THE ADVICE OF PROFESSIONAL ADVISERS REGARDING THE EVALUATION OF ANY PRODUCT, MUTUAL FUNDS OR OTHER FINANCIAL INSTRUMENT, REPORT, INDEX, ADVICE, OPINION OR ANY OTHER CONTENT.", "All rights not expressly granted herein are reserved."]
    }
]