import React from 'react';
import { Card, Tooltip } from "antd";
import { DetailIcon } from "./DetailIcon";
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { NoDataFound } from './NoDataFound';

const CustomLabelValueCards = ({ x, orientation = 'horizontal', minWidth = '13rem', showDetailIcon = false, padding = '0.875rem 1.5rem', headingClassName = 'textSM', style = {}, bodyStyle = {} }) => {
    return <div style={{ display: 'flex', justifyContent: 'stretch', gap: '0.688rem', flexWrap: 'wrap', flexDirection: orientation === 'verticle' ? 'column' : 'row' }}>
        {x?.filter((p) => p.show !== false)?.length > 0 ? x?.filter((p) => p.show !== false).map(p => {
            return (
                <Card bordered={false} key={p.key} style={{ minWidth: window.innerWidth > 760 ? minWidth : '100%', borderRadius: '0.5rem', border: 'none', boxShadow: 'none', ...style }} bodyStyle={{ padding: padding, border: '1px solid var(--grey2)', borderRadius: '0.5rem', ...bodyStyle }}>
                    <div className={`${headingClassName} dark2 d-flex align-items-center`} style={{ marginBottom: '0.5rem', gap: "0.4rem" }}>
                        {showDetailIcon && <DetailIcon type={p.heading} width='1.5rem' height='1.5rem' />} {p.heading}
                        {p?.helperText && <Tooltip title={p?.helperText} arrow={false}>
                            <AiOutlineInfoCircle style={{ marginLeft: "0.25rem", cursor: "pointer" }} className='infoIcon' />
                        </Tooltip>}
                    </div>
                    <div className="textSM w-700 dark3">{p.value}</div>
                </Card>
            )
        }) : <NoDataFound />}
    </div>
}

export default CustomLabelValueCards;