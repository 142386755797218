import React from "react";
const CountDown = ({ startTime }) => {

    const [time, setTime] = React.useState(startTime)
    const timeRef = React.useRef();
    React.useEffect(() => {
        timeRef.current = setInterval(() => {
            setTime((prev) => {
                if (prev) {
                    return prev - 1
                } else {
                    return '...'
                }
            })
        }, 1000);
        return () => {
            clearInterval(timeRef.current)
        }
    }, [])
    return <>{time}</>
}

export default CountDown