import React from "react";
import Widget from "./Widget";

const CompanyProfile = ({
  symbol = "BSE:RELIANCE",
  width = '100%',
  height = "100%",
  autosize = true,
  colorTheme = "light",
  isTransparent = false,
  locale = "en",
  largeChartUrl = undefined,
  ...props }) => {
  return (
    <div>
      <Widget
        scriptHTML={{
          ...(!autosize ? { width } : { width: "100%" }),
          ...(!autosize ? { height } : { height: height }),
          symbol,
          colorTheme,
          isTransparent,
          locale,
          largeChartUrl,
          ...props,
        }}
        scriptSRC="https://s3.tradingview.com/external-embedding/embed-widget-symbol-profile.js"
      ></Widget>
    </div>
  );
};

export default React.memo(CompanyProfile);