import React from "react";

function normalFormatterWithoutPrefix(num, tofix = 2) {
    const a = new Intl.NumberFormat("en-IN", {
        minimumFractionDigits: tofix,
        maximumFractionDigits: tofix,
    }).format(num);
    return a
}

function kFormatter(val, decimals) {
    if (val >= 10000000) { val = `${(val / 10000000).toFixed(decimals)} cr` }
    else if (val >= 100000) { val = `${(val / 100000).toFixed(decimals)} lac` }
    else if (val >= 1000) { val = `${(val / 1000).toFixed(decimals)} k` }
    else { val = normalFormatterWithoutPrefix(val) }
    return val.replace('-', '');
}

export function DecimalValueFormat({ num = 0, suffix = "", prefix = "", placeholder = "-", decimals = 2, type = "long" }) {
    if (num !== null) {
        num = +parseFloat(Number(num)).toFixed(decimals)
        if (num === -0) {
            num = 0
        }
    }
    return isNaN(num) || num === null ? placeholder : (prefix + (type === "long" ? normalFormatterWithoutPrefix(num, decimals) : kFormatter(num)) + suffix)
}