import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts';
import { NoDataFound } from 'Components/NoDataFound';

export const StockScoresGauge2 = React.memo(({ val, height = 100, min = 0, max = 100, labelSuffix = '', labelFont = '14px', showTickLabels = true, paneThickness = 10, tickAmount, tickInterval,
    isAnalystRating = false, yAxisLabels = {}, yAxisLabelClass = '', showTick = false, isRSI = false, isStylebox = false, dataLabel = '', showLabel = true, fixValue = false, decimals = 0 }) => {

    const options = {

        chart: {
            type: 'gauge',
            plotBackgroundColor: null,
            plotBackgroundImage: null,
            plotBorderWidth: 0,
            plotShadow: false,
            height: height,
        },

        title: {
            text: ''
        },

        pane: {
            startAngle: -90,
            endAngle: 89.9,
            background: null,
            center: ['50%', '75%'],
            size: '100%'
        },

        yAxis: {
            min: min,
            max: max,
            tickPixelInterval: 50,
            tickPosition: 'inside',
            tickColor: 'var(--grey3)',
            tickLength: showTick ? paneThickness : 0,
            tickWidth: showTick ? 2 : 0,
            minorTickInterval: null,
            tickAmount: tickAmount,
            tickInterval: tickInterval,
            className: yAxisLabelClass,
            labels: {
                enabled: showTickLabels,
                distance: 20,
                formatter: function () {
                    if (isAnalystRating || isRSI || isStylebox) {
                        return yAxisLabels?.[this.pos]
                    }
                    return this.pos
                },
                style: {
                    fontSize: '20px'
                }
            },
            lineWidth: 0,
            plotBands: [{
                from: min,
                to: max,
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 1,
                        y1: 0,
                        y2: 0
                    },
                    stops: isRSI ? [
                        [0, 'var(--red)'],
                        [0.2, 'var(--yellow)'],
                        [0.5, 'var(--green)'],
                        [0.8, 'var(--yellow)'],
                        [1, 'var(--red)'],
                    ] : [
                        [0, 'var(--red)'],
                        [0.3, 'var(--yellow)'],
                        [1, 'var(--green)'],
                    ]
                },
                thickness: paneThickness
            }]
        },

        series: [{
            name: '',
            data: [val],
            tooltip: {
                enabled: false
            },
            dataLabels: {
                enabled: showLabel,
                // format: isStylebox ? dataLabel : fixValue ? `{y:${decimals}.f} ${labelSuffix}` : `{y} ${labelSuffix}`,
                formatter: function () {
                    if (isStylebox) return dataLabel;
                    else if (fixValue) return `${this.y.toFixed(decimals)} ${labelSuffix}`;
                    return `${this.y} ${labelSuffix}`; // Fix to one decimal point
                },
                borderWidth: 0,
                color: (
                    Highcharts.defaultOptions.title &&
                    Highcharts.defaultOptions.title.style &&
                    Highcharts.defaultOptions.title.style.color
                ) || '#333333',
                className: "stockscoregauge",
                style: {
                    fontSize: labelFont
                }
            },
            dial: {
                radius: '100%',
                backgroundColor: 'var(--dark4)',
                baseWidth: 4,
                baseLength: '0%',
                rearLength: '0%'
            },
            pivot: {
                backgroundColor: 'var(--dark4)',
                radius: 5
            }

        }],
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        tooltip: {
            enabled: false
        }
    }
    if (!val && val !== 0) {
        return <div style={{ height: height }}>
            <NoDataFound message='Not enough data' width={'5rem'} />
        </div>
    }
    return (
        <React.Fragment >
            <HighchartsReact highcharts={Highcharts} options={options} />
        </React.Fragment >
    )
})