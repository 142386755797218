import { LoadingOutlined } from '@ant-design/icons';
import { microphone } from "Assets/images";
import { DecimalValueFormat } from 'Components/DecimalValueFormat';
import { BrokerIcon, redirectToBrokerLogin, redirectToBrokerSignup } from 'Components/SharpelyGateway/BrokerFunctions';
import CustomTag from "Components/atoms/CustomTag";
import { isMarketOpen } from 'Components/atoms/IsMarketOpen';
import Logo from "Layout/Logo";
import { updateInvCardStateObj } from "Libs/redux/UserAuth/reduxSlice";
import { etfType, makeid, mfType, screenerMetricData, stockType } from 'Libs/utils';
import AdminServices from "Services/api/AdminServices";
import AdminServicesPY from "Services/api/AdminServicesPY";
import apiHelper from "Services/api/ApiHelper";
import { StockLivePrice } from "Services/api/LivePricesWebSocket";
import { Card, Collapse, Divider, Drawer, Modal, Popover, Radio, Segmented, Skeleton, Tag, Tooltip, notification } from "antd";
import React from "react";
import CurrencyInput from "react-currency-input-field";
import { isMobile } from "react-device-detect";
import { toast } from "react-hot-toast";
import { AiFillCloseCircle, AiFillLock } from "react-icons/ai";
import { BiChevronRight } from "react-icons/bi";
import { HiArrowLongRight } from "react-icons/hi2";
import { IoIosArrowDown } from "react-icons/io";
import { IoCalendarOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import AMCIcon from "../AMCIcon";
import { BasketIcon } from "../BasketIcon";
import BuySellSwitch from "../BuySellSwitch";
import CountDown from "../Countdown";
import { CurrencyFormat } from "../CurrencyFormat";
import { formatDate, formatDateyyyymmdd, getNextSIPDate2, getNextSIPDatel1 } from "../DateFormatter";
import ordinal_suffix_of from "../Ordinal-suffix";
import { RiskColor, RiskTextColor } from "../RiskFunctions";
import { CardHeader, generateUniqueKey } from "../atoms/SmallComponents";

const SharpelyGateway2 = ({
    IS_GOAL = false,
    GOAL_OBJ,
    BEFORE_GOAL_INV = () => { },
    ORDERS = [],
    GOAL_ID = 0,
    IS_BASKET = false,
    PORTFOLIO_ID = 0,  // for basket portfolio id
    PORTFOLIO_OBJ = [], // for getting quantity in basket or diy in case of sell transactions
    INSTRUMENT_TYPE,
    FUNDDATA,
    TRANSACTION_TYPE = 'BUY', // 'BUY' or 'SELL'
    connectBroker = false,
    noCta = false, // remove later
    openFromParent = false, //remove later
    IS_REBALANCE = false, // for rebalance and sip from notification
    IS_STRATEGY_LIVE = false,
    REBALANCE_STR, // for rebalance and sip from notification
    IS_MODIFY = false,
    IS_CANCEL = false,
    MODIFY_ORDER_ID,
    MODIFY_PRICE,
    MODIFY_QUANTITY,
    AFTER_SUCCESS_INV = () => { }, // after successful inv
    btnClassName = 'btnBlack  w100',
    btnText = 'Invest',
    ctaType = 'button', // text
    WEALTH_CREATOR = 0,
    EXECUTE_BEFORE_INVEST, // func to execute before inv for creating live strategy
    CTA_DISABLED,
    CAN_INVEST = true,
    cannotInvestContent = ''
}) => {
    const ZerodhaSharpelyApiKey = "uw78p4pu6vyhub25"
    // openFromParent --> use this  to open investment  drawer in response to some action in parent.
    const user = useSelector((state) => state?.updateUserData?.value);
    // console.log("user", user)
    const brokerList = user?.id <= 7 ? ['zerodha', 'angel', 'dhan', 'fyers', 'upstox', '5paisa'] : ['angel', 'dhan', 'fyers', '5paisa']
    const newBrokerList = ['angel', 'dhan']
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams()
    const broker = user?.Client?.[0]?.Broker;
    const [loading, setLoading] = React.useState(false)
    const [openLoginModal, setOpenLoginModal] = React.useState(false)
    const [openSignupModal, setOpenSignupModal] = React.useState(false)
    const [openInvModal, setOpenInvModal] = React.useState(false)
    const [openPausedModal, setOpenPausedModal] = React.useState(false)
    const [showDateModal, setShowDateModal] = React.useState(false)
    const [openCannotInvModal, setOpenCannotInvModal] = React.useState(false);
    const [profile, setProfile] = React.useState({})
    const [funds, setFunds] = React.useState({})
    const [rebalanceObj, setRebalanceObj] = React.useState({})
    const [isReDateSame, setIsReDateSame] = React.useState(true)

    const [isRebalanceOnAdditionalInv, setIsRebalanceOnAdditionalInv] = React.useState(true)
    const [isCompleteRedemption, setIsCompleteRedemption] = React.useState(false)

    const totalStockQuantity = Number(PORTFOLIO_OBJ?.reduce((sum, s) => sum + Number(s.Quantity), 0)) || 0
    const totalStockQuantityDiy = Number(PORTFOLIO_OBJ.filter(s => s.PortfolioId === 0)[0]?.Quantity) || 0

    const [api, contextHolder] = notification.useNotification();
    var stockprices = useSelector((state) => state?.stockprices?.value)
    var prevstockprices = useSelector((state) => state?.prevStockPrices?.value)

    const rebal_freq = FUNDDATA?.RebalanceFrequency === '0' ? 0 : FUNDDATA?.RebalanceFrequency === 'M' ? 1 : FUNDDATA?.RebalanceFrequency === '3M' ? 3 : FUNDDATA?.RebalanceFrequency === '6M' ? 6 : FUNDDATA?.RebalanceFrequency === '12M' ? 12 : 0
    const rebalanceDate = rebal_freq === 0 ? null : new Date(new Date().setMonth(new Date().getMonth() + rebal_freq))
    const getLTP = ({ fundData }) => {
        var changeVal, changePer, currentLTP = "-"
        var currentSymbolAllData = stockprices === "close" ? prevstockprices?.[fundData?.gdf_symbol] : stockprices?.[fundData?.gdf_symbol] || prevstockprices?.[fundData?.gdf_symbol]
        currentLTP = currentSymbolAllData?.LastTradePrice
        changeVal = currentSymbolAllData?.PriceChange
        changePer = currentSymbolAllData?.PriceChangePercentage

        if (currentSymbolAllData == undefined) {
            let ele = fundData
            currentLTP = ele?.price || 0
            changePer = ele?.one_day_pct || 0
            let originalPrice = currentLTP / (1 + (changePer / 100))
            changeVal = currentLTP - originalPrice;
        }


        return currentLTP
    }

    const [transactionType, setTransactionType] = React.useState(TRANSACTION_TYPE);
    const [price, setPrice] = React.useState(IS_MODIFY ? MODIFY_PRICE : Number(getLTP({ fundData: FUNDDATA })));
    const [amount, setAmount] = React.useState('')
    const [minInv, setMinInv] = React.useState(IS_BASKET ? FUNDDATA?.MinSIPAmount : Number(getLTP({ fundData: FUNDDATA })));
    const [trxnStatus, setTrxnStatus] = React.useState('');

    const sipTypes = [
        {
            "label": "Daily",
            "key": "1D",
            "value": "1D",
            "title": "Daily"
        },
        {
            "label": "Weekly",
            "key": "1W",
            "value": "1W",
            "title": "Weekly"
        },
        {
            "label": "Monthly",
            "key": "1M",
            "value": "1M",
            "title": "Monthly"
        },
    ]

    const sipDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']?.map((i) => {
        return {
            "label": i,
            "key": i,
            "value": i,
            "title": i
        }
    })
    const [isSip, setIsSip] = React.useState(false);
    const [sipDate, setSipDate] = React.useState(new Date().getDate() > 28 ? 28 : new Date().getDate());
    const [sipFrequency, setSipFrequency] = React.useState(sipTypes?.[0]?.value)
    const [sipDay, setSipDay] = React.useState(sipDays?.[0]?.value)
    const [quantity, setQuantity] = React.useState(IS_MODIFY ? MODIFY_QUANTITY : 1)
    const [isMarket, setIsMarket] = React.useState(IS_MODIFY ? false : true)
    const [orders, setOrders] = React.useState([])
    const [ordersLoading, setOrdersLoading] = React.useState(false)
    const [rebalanceLoading, setRebalanceLoading] = React.useState(false)
    const [strategy_config, set_strategy_config] = React.useState({})
    const [stype, setsType] = React.useState({})
    const [liveOrderModal, setliveOrderModal] = React.useState(false)
    const [liveTradeData, setliveTradeData] = React.useState({})
    const [liveTradeOrders, setliveTradeOrders] = React.useState([])
    const [liveTradeCols, setliveTradeCols] = React.useState([])

    React.useEffect(() => {
        // console.log('params', searchParams)
        const dem = async () => {
            if (searchParams.get('openInv') === 'true') {
                searchParams.delete('openInv')
                // await handleBroker();
                setSearchParams(searchParams)
            }
        }
        dem()
    }, [searchParams])

    const timerRef = React.useRef()
    function debounce(func, wait) {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        timerRef.current = setTimeout(() => {
            func();
        }, wait);
    }

    React.useEffect(() => {
        if (IS_BASKET && FUNDDATA?.UID && openInvModal) {
            setOrdersLoading(true);
            debounce(getBasketQuantities, 1000);
        }
    }, [amount, isRebalanceOnAdditionalInv, isCompleteRedemption])

    React.useEffect(() => {
        if (openFromParent) {
            onInvestClick();
        }
    }, [openFromParent]);

    const createRebalanceOrder = async (data) => {
        var orders = JSON.parse(data?.orders)
        var config = JSON.parse(data?.config)

        var type = config?.instrument_type === 3 ? stockType : config?.instrument_type === 2 ? etfType : mfType
        INSTRUMENT_TYPE = type?.value
        setsType(type)
        console.log(INSTRUMENT_TYPE)
        var screener_datas = await screenerMetricData(type, 0, 1)
        var allFunds = screener_datas?.allFunds
        var sum = 0


        console.log(allFunds)
        set_strategy_config(config)

        if (orders?.length > 0) {
            // if (JSON.stringify(orders)?.includes("SELL")) {
            //     setcheckTpin(true)
            // }
            setliveTradeOrders((orders || []).map((i) => {
                if (i?.direction === "BUY") {
                    sum += (i?.ltp * i?.order_quantity)
                }
                // else {
                // 	// 80% logic
                // 	sum -= (i?.entry_price * i?.order_quantity) * 0.80
                // }


                return {
                    ...[...allFunds].filter((ii) => type?.value === 4 ? ii?.symbol === i?.symbol : type?.value === 1 ? ii.nse_symbol === i.symbol : ii?.isin_code === i?.symbol)[0],
                    ...i,
                }
            }))

            console.log("SDffdsf", sum)
            setAmount(sum || 0)

            setliveTradeCols((Object.keys(orders?.[0])?.filter((i) => !(['symbol', 'mcap', 'entry_price', 'min_inv_amount']?.includes(i))))?.map((item) => {
                ////////console.log(typeof (orders?.[0]?.[item]))
                return {
                    "dataIndex": item,
                    "key": item,
                    "metric_col_code": item,
                    "metric_unit": "",
                    "metric_data_type": typeof (orders?.[0]?.[item]),
                    "metric_name": item,
                    "title": <span style={{ textTransform: "capitalize" }}>{String(item)?.replace("_", " ")}</span>,
                    "align": "center",
                    "width": 150,
                }
            }))

            // setallFunds(allFunds)

            setOrdersLoading(false)
            setRebalanceLoading(false)
        } else if (orders?.length == 0) {
            setOrdersLoading(false)
            setRebalanceLoading(false)
        } else {
            // setIsErrorScreen(true)
            setOrdersLoading(false)
            setRebalanceLoading(false)
            toast.error('Something went wrong. Please try again later')
        }
    }

    React.useEffect(() => {
        if (IS_REBALANCE && openInvModal) {
            console.log('REB str', REBALANCE_STR)
            const dem = async () => {
                setRebalanceLoading(true)
                const res = await AdminServices.portfolioDecrypt(REBALANCE_STR)
                if (res.type) {
                    const [clientId, strategy_id, tag, timestamp, scheduledId, isRebalance, isRebalanceDateSame] = res.data?.split(':')

                    var res2 = await AdminServicesPY.getordersbytag({
                        strategy_id: strategy_id,
                        tag: tag
                    }).then((res) => { return res })
                    console.log("SAFDASF", res2)
                    if (res2?.type && res2?.data !== "Failed") {
                        createRebalanceOrder(res2?.data)
                    } else {
                        toast.error('Something went wrong. Please try again later')
                        setRebalanceLoading(false)
                    }

                    // const obj = {
                    //     clientId: Number(clientId) || 0,
                    //     basketId: Number(basketId) || 0,
                    //     goalId: Number(goalId) || 0,
                    //     wealthCreator: Number(wealthCreator) || 0,
                    //     isRebalance: Number(isRebalance) === 0 ? false : true,
                    //     scheduledId: Number(scheduledId) || 0
                    // }
                    // const res2 = await AdminServices.rebalancePortfolio(obj)
                    // if (res2.type) {
                    //     let data = {}
                    //     if (Number(basketId) === 0) {
                    //         let a = await fetchFundData(res2.data?.[0]?.instrumentType, false)
                    //         if (res2.data?.[0]?.instrumentType === 1) {
                    //             data = a.filter(x => x.nse_symbol === res2.data?.[0]?.fundCode)?.[0];
                    //         } else if (res2.data?.[0]?.instrumentType === 4) {
                    //             data = a.filter(x => x.symbol === res2.data?.[0]?.fundCode)?.[0];
                    //         }
                    //     } else {
                    //         let a = await fetchFundData(3, false, user?.id)
                    //         data = a.filter(x => x.UID === Number(basketId))?.[0];
                    //         console.log(a, data, basketId)
                    //     }
                    //     console.log('omkaer', { ...obj, orders: res2.data, FUNDDATA: data })
                    //     setRebalanceObj({ ...obj, orders: res2.data, FUNDDATA: data })
                    //     setRebalanceLoading(false)
                    // } else {
                    //     toast.error('Something went wrong. Please try again later')
                    //     setRebalanceLoading(false)
                    // }
                }
            }
            dem();
        }
    }, [REBALANCE_STR, openInvModal])

    const getBasketQuantities = async () => {
        var res;
        if (transactionType === 'SELL' && (isCompleteRedemption || amount !== '')) { // sell basket
            var dataFinal = {
                "mintbox_id": PORTFOLIO_ID,
                "client_id": user?.id,
                "sell_amount": isCompleteRedemption ? 0 : Number(amount)
            }
            res = await AdminServicesPY.genSellOrder(dataFinal).then(res => res)
        } else if (transactionType === 'BUY' && PORTFOLIO_ID !== 0) { // additional inv
            var dataFinal = {
                "mintbox_id": PORTFOLIO_ID,
                "client_id": user?.id,
                "additional_funds": Number(amount),
                "rebalance": isRebalanceOnAdditionalInv
            }
            res = await AdminServicesPY.genRebalOrder(dataFinal).then(res => res)
        } else if (transactionType === 'BUY' && PORTFOLIO_ID === 0) { // first time basket inv
            var dataFinal = {
                "mintbox_id": FUNDDATA?.UID,
                "client_id": user?.id,
                "total_funds": Number(amount)
            }
            res = await AdminServicesPY.genNewBasketOrder(dataFinal).then(res => res)
        }
        if (res?.type && res?.data && res?.data?.length) {
            // const obj = res?.data?.map(k => {
            //     return {
            //         "weights": k.weights,
            //         "tradingSymbol": k.symbol,
            //         "quantity": k.num_shares,
            //         "ltp": k.ltp
            //     }
            // })
            setOrders(res?.data)
            setOrdersLoading(false)
        } else {
            setOrders([])
            setOrdersLoading(false)
        }

    }

    const executeStrategyOrder = async ({ funds = 0 }) => {
        console.log(ORDERS, funds)
        let fundsReq = 0
        ORDERS.map(o => {
            if (o.direction === "BUY") {
                fundsReq = fundsReq + o?.ltp * o?.order_quantity
            }
        })
        if (fundsReq > funds) {
            toast.error("Please add sufficient funds to your broker account.")
        } else {
            let strategyRes = await EXECUTE_BEFORE_INVEST();
            console.log('strategy res', strategyRes)
            if (strategyRes?.status) {
                try {
                    console.log("lllllllllll", strategyRes?.orders)
                    setTrxnStatus('loading')
                    // const obj = rebalanceObj?.orders?.map(k => {
                    //     return {
                    //         "transactionType": k.buySellFlag === -1 ? 'SELL' : 'BUY',
                    //         "tradingSymbol": k.fundCode,
                    //         "quantity": k.rebalanceUnits
                    //     }
                    // })
                    const order = {
                        "clientId": user.id,
                        "broker": broker,
                        "sourceAppId": 2,
                        "basketId": 0,
                        // "goalId": rebalanceObj?.goalId,
                        // "isWealthCreator": rebalanceObj?.wealthCreator,
                        // "portfolioId": rebalanceObj?.orders?.[0]?.portfolioId,
                        "instrumentType": INSTRUMENT_TYPE,
                        "orders": strategyRes?.orders?.filter((order) => Number(order?.order_quantity) !== 0)?.map((order) => {
                            return {
                                "transactionType": String(order?.direction)?.toUpperCase(),
                                "tradingSymbol": order?.symbol,
                                "quantity": order?.order_quantity,
                                "exchange": order?.exchange || "NSE",
                                "internalRefTag": order?.sharpelyorderid
                            }
                        }),
                        "isAMO": false,
                    }
                    console.log("lllllllllll", order)
                    await createOrder({ order })
                    return true
                } catch (error) {
                    return false
                }
            } else {
                navigate('/strategies');
                return;
            }
        }

    }

    const handleBroker = async () => {
        if (broker !== 'disconnected' && broker !== '') {
            setLoading(true)
            // for zerodha publisher
            // if (broker === "zerodha" && !user?.Client?.[0]?.ZerodhaAPIKey) {
            //     if (IS_STRATEGY_LIVE) {
            //         await executeStrategyOrder()
            //     } else {
            //         setOpenInvModal(true)
            //     }
            //     setLoading(false);
            //     return;
            // }
            let res3 = await apiHelper({ apiName: 'validateSession', data: { clientId: user?.id, broker: broker }, saveResponse: '' })
            console.log('validateSession', res3)
            if (res3 && res3?.status?.toLowerCase() === 'active') {
                let res = await apiHelper({ apiName: 'getFunds', data: { clientId: user?.id, broker: broker }, saveResponse: '' })
                // res = JSON.parse(res)
                setFunds(res)
                console.log("getFunds", res)
                let res1 = await apiHelper({ apiName: 'getProfile', data: { clientId: user?.id, broker: broker }, saveResponse: '' })
                setProfile(res1)
                console.log("getProfile", res1)
                if (IS_STRATEGY_LIVE) {
                    await executeStrategyOrder({ funds: Number(res?.totalBalance) })
                } else {
                    setOpenInvModal(true)
                }
                setLoading(false)
            } else {
                setOpenLoginModal(true)
                setLoading(false)
            }

        } else {
            setOpenSignupModal(true)
        }
    }

    const onInvestClick = async () => {
        if (!user) {
            navigate('/login');
            return;
        }
        if (!CAN_INVEST) {
            setOpenCannotInvModal(true);
            return;
        }
        if (INSTRUMENT_TYPE === 2) {
            setOpenPausedModal(true);
            return;
        }
        await handleBroker()
    }

    const LoginModal = () => {
        const handleClick = () => {
            dispatch(updateInvCardStateObj({
                REDIRECT: location.pathname,
            }));
            redirectToBrokerLogin({ broker: broker, user: user });
        }
        return <Modal open={openLoginModal} onCancel={() => {
            setOpenLoginModal(false);
        }}
            footer={<div className="textXS dark2" style={{ margin: '0 0.5rem -1rem', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <span>Powered by </span><Logo type="dark" showBeta={false} height={'1.5rem'} /> <span className="ms-1">gateway</span>
            </div>}
            closable={true}>
            <div style={{ textAlign: 'center', margin: '4rem' }}>
                <div className="textLG w-500 mb-4 mt-4">Session timed out. Please login again to continue</div>
                <button className="btnWhite  text-center" style={{}} onClick={() => { handleClick() }}>
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <BrokerIcon height="1.5rem" width="1.5rem" brokerName={broker} /> Login to {broker?.charAt(0).toUpperCase() + broker?.slice(1)}
                    </div>
                </button>
            </div>
        </Modal>
    }

    const SignupModal = () => {
        const [openZerodhaModal, setOpenZerodhaModal] = React.useState(false)
        const [api_key, set_api_key] = React.useState('')
        const [api_secret, set_api_secret] = React.useState('')

        // const connectZerodhaPublisher = async () => {
        //     let data = {
        //         clientId: user?.Client[0]?.ClientId,
        //         broker: 'zerodha'
        //     }

        //     await AdminServices.editAccount(data).then(async (res1) => {
        //         if (res1?.type === true) {
        //             let userData = { name: user?.name, email: user?.email, mobile: user?.mobile, isLogged: true, id: user?.id, login_Date: user?.login_Date }
        //             await Dashboard_Portfolio.getClientData(userData, true).then((res) => {
        //                 if (res) {
        //                     dispatch(updateUserData({ ...userData, ...res }))
        //                     toast.success("Connection successful")
        //                     setOpenSignupModal(false)
        //                 } else {
        //                     console.log('get client failed')
        //                     toast.error("Something went wrong!")
        //                     setOpenSignupModal(false)

        //                 }
        //             })
        //         } else {
        //             console.log('editAccount failed')
        //             toast.error("Something went wrong!")
        //         }
        //     })
        // }
        const handleLogin = async ({ br }) => {
            dispatch(updateInvCardStateObj({
                BROKER: br,
                REDIRECT: location.pathname,
                zerodhaAPIKey: api_key,
                zerodhaAPISecret: api_secret
            }));
            redirectToBrokerLogin({ broker: br, user: user, zerodhaAPIKey: api_key });
        }
        const handleBrokerClick = async ({ br }) => {
            if (br === 'zerodha') {
                setOpenZerodhaModal(true)
            } else {
                handleLogin({ br: br })
            }
        }
        return <Modal open={openSignupModal} onCancel={() => {
            setOpenSignupModal(false);
        }}
            footer={<div className="textXS dark2" style={{ margin: '0 0.5rem -1rem', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <span>Powered by </span><Logo type="dark" showBeta={false} height={'1.5rem'} /> <span className="ms-1">gateway</span>
            </div>}
            closable={true} width='600px'
            styles={{
                body: { minHeight: '300px', marginRight: '1rem' }
            }}
        >
            {
                openZerodhaModal ?
                    <div>
                        <div className="textMD w-500 black mb-4">You will need a Zerodha KITE Connect API account to place orders on Zerodha</div>
                        <div style={{ display: 'flex', gap: '1rem' }}>
                            <div style={{ border: '1px solid var(--grey3)', padding: '1rem', borderRadius: '4px', height: 'fit-content', minHeight: '9rem' }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
                                    <BrokerIcon brokerName={'zerodha'} height='3.5rem' width="3.5rem" />
                                    <div style={{ textTransform: "capitalize", whiteSpace: 'nowrap' }}>Kite connect</div>
                                </div>
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '0.5rem' }}>
                                <div className="textSM w-500 black">Kite Connect API</div>
                                <div className="textXS w-400 dark3" style={{ maxWidth: '90%' }}>Create a KITE connect paid account and provide us your API key and API secret</div>
                                <div style={{ display: 'flex', gap: '1rem', flexDirection: isMobile ? 'column' : 'row' }}>
                                    <div style={{ flex: 1 }}>
                                        <div className="textXS w-500 black mx-1">API Key</div>
                                        <input name="API key" placeholder="Enter your API Key..." value={api_key} className="textSM antdInput"
                                            style={{ background: "var(--grey5)", border: "1px solid var(--grey2)" }} type="text"
                                            onChange={(e) => {
                                                set_api_key(e.target.value)
                                            }} />
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className="textXS w-500 black mx-1">API Secret</div>
                                        <input name="API Secret" placeholder="Enter your API Secret..." value={api_secret} className="textSM antdInput"
                                            style={{ background: "var(--grey5)", border: "1px solid var(--grey2)" }} type="text"
                                            onChange={(e) => {
                                                set_api_secret(e.target.value)
                                            }} />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', gap: '2rem', alignItems: 'center', marginTop: '0.5rem' }}>
                                    <button className="btnBlack" style={{ width: 'fit-content' }}
                                        onClick={() => handleLogin({ br: 'zerodha' })}>
                                        Connect
                                    </button>
                                    <div className="textSM w-400 primary hoverBold" style={{ cursor: 'pointer' }}>Learn more</div>
                                </div>
                            </div>
                        </div>
                        {/* <Divider style={{ fontSize: '12px', fontWeight: '400', margin: '1rem 0' }}>OR</Divider>
                        <div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>
                            <div style={{ border: '1px solid var(--grey3)', padding: '1rem', borderRadius: '4px', height: 'fit-content', minHeight: '9rem' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
                                    <BrokerIcon brokerName={'zerodha'} height='3.5rem' width="3.5rem" />
                                    <div style={{ textTransform: "capitalize", whiteSpace: 'nowrap' }}>Kite Publisher</div>
                                </div>
                            </div>
                            <div style={{ flex1: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '0.5rem' }}>
                                <div className="textSM w-500 black">Kite Publisher Account</div>
                                <div className="textXS w-400 dark3 mt-1" style={{ maxWidth: '90%' }}>Order will be placed directly on the KITE publisher portal. Please note that we will not be able to show your portfolio, get your order confirmations or suggest any rebalancing to your portfolio. You can view all details only in your Zerodha account.</div>
                                <div style={{ display: 'flex', gap: '2rem', alignItems: 'center', marginTop: '0.5rem' }}>
                                    <button className="btnBlack" style={{ width: 'fit-content' }}
                                        onClick={() => {

                                            connectZerodhaPublisher()
                                        }}>
                                        Connect
                                    </button>
                                    <div className="textSM w-400 primary hoverBold" style={{ cursor: 'pointer' }}>Learn more</div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    :
                    <div>
                        <div className="textLG w-500 mb-4 mt-4">
                            Connect your broker
                        </div>
                        <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                            {
                                brokerList.map((b, l) => {
                                    return <div style={{ border: '1px solid var(--grey3)', padding: '1rem', borderRadius: '4px', cursor: 'pointer', minWidth: '7rem' }}
                                        onClick={() => handleBrokerClick({ br: b })} key={generateUniqueKey(l)} >
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
                                            <BrokerIcon brokerName={b} height='3.5rem' width="3.5rem" />
                                            <div style={{ textTransform: "capitalize" }}>{b}</div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <Divider style={{ fontSize: '12px', fontWeight: '400', margin: '1rem 0' }}>OR</Divider>
                        <div className="textLG w-500 mb-4">
                            Create a new acccount
                        </div>
                        <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                            {
                                newBrokerList.map((b, l) => {
                                    return <div style={{ border: '1px solid var(--grey3)', padding: '1rem', borderRadius: '4px', cursor: 'pointer', minWidth: '7rem' }}
                                        onClick={() => redirectToBrokerSignup({ broker: b })} key={generateUniqueKey(l)}>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
                                            <BrokerIcon brokerName={b} height='3.5rem' width="3.5rem" />
                                            <div style={{ textTransform: "capitalize" }}>{b}</div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className="textXS mt-4 mb-4 dark3">
                            <span className="w-500">Don't see your broker? </span><br />
                            We are working on adding your favourite brokers. In the meantime please consider opening an account with one of the above brokers.
                        </div>
                    </div>
            }
        </Modal >
    }

    const PausedModal = () => {
        return <Modal open={openPausedModal} onCancel={() => { setOpenPausedModal(false) }}
            footer={<div className="textXS dark2" style={{ margin: '0 0.5rem -1rem', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <span>Powered by </span><Logo type="dark" showBeta={false} height={'1.5rem'} /> <span className="ms-1">gateway</span>
            </div>}
            closable={true} width='500px'>
            <div className='d-flex align-items-center flex-column' style={{ gap: "2rem", marginTop: "2rem" }}>
                <img loading="lazy" alt="" src={microphone} style={{ width: "8rem", height: "8rem" }} />
                <div className='textMD text-center'>
                    {INSTRUMENT_TYPE === 1 ? 'ETF' : INSTRUMENT_TYPE === 2 ? 'Mutual fund' : IS_BASKET ? 'Basket' : ''} investments are currently paused. Pretty soon you will be able to transact and invest in {INSTRUMENT_TYPE === 1 ? 'ETFs' : INSTRUMENT_TYPE === 2 ? 'mutual funds' : IS_BASKET ? 'baskets' : 'stocks, ETFs and mutual funds'}.
                </div>
            </div></Modal>
    }

    const TransactionStatusOverlay = () => {
        if (trxnStatus === 'success') {
            return <div style={{ position: 'absolute', top: 0, left: 0, backgroundColor: 'rgb(231, 242, 227,0.6)', height: '100%', width: '100%', zIndex: '1000' }}>
                <div style={{
                    margin: '20% auto', textAlign: 'center', height: '40%'
                }} className="fadeInBottom">
                    {/* <Lottie options={lottieOptionsSuccess} width='250px' height='250px' /> */}
                    <img width='250px' height='250px' style={{
                        height: "250px",
                        width: "250px",
                    }} src={require('Assets/lottie/congratulations.gif')} alt="loading..." />
                    <div className="displaySM w-500" style={{}}>Order placed successfully</div>
                    <div className="textSM mt-4">
                        Shares will be {transactionType === 'BUY' ? 'visible in' : 'removed from'} your investments after T+1 days credit cycle.
                    </div>
                    <div className="displayXS mt-4">Redirecting to Orders page <CountDown startTime={5} /> ...</div>
                </div>
            </div>
        } else if (trxnStatus === 'modified') {
            return <div style={{ position: 'absolute', top: 0, left: 0, backgroundColor: 'rgb(231, 242, 227,0.6)', height: '100%', width: '100%', zIndex: '1000' }}>
                <div style={{
                    margin: '20% auto', textAlign: 'center', height: '40%'
                }} className="fadeInBottom">
                    {/* <Lottie options={lottieOptionsSuccess2} width='250px' height='250px' /> */}
                    <img width='250px' height='250px' style={{
                        height: "250px",
                        width: "250px",
                    }} src={require('Assets/lottie/success.gif')} alt="loading..." />
                    <div className="displaySM w-500" style={{}}>Order has been modified</div>
                </div>
            </div>
        } else if (trxnStatus === 'cancelled') {
            return <div style={{ position: 'absolute', top: 0, left: 0, backgroundColor: 'rgb(231, 242, 227,0.6)', height: '100%', width: '100%', zIndex: '1000' }}>
                <div style={{
                    margin: '20% auto', textAlign: 'center', height: '40%'
                }} className="fadeInBottom">
                    {/* <Lottie options={lottieOptionsSuccess2} width='250px' height='250px' /> */}
                    <img width='250px' height='250px' style={{
                        height: "250px",
                        width: "250px",
                    }} src={require('Assets/lottie/success.gif')} alt="loading..." />
                    <div className="displaySM w-500" style={{}}>Order has been cancelled</div>
                </div>
            </div>
        }
        else if (trxnStatus === 'failed') {
            return <div style={{ position: 'absolute', top: 0, left: 0, backgroundColor: 'rgb(74, 40, 41,0.4)', height: '100%', width: '100%', zIndex: '1000' }}>
                <div style={{
                    margin: '20% auto', textAlign: 'center', height: '40%'
                }} className="fadeInBottom">
                    {/* <Lottie options={lottieOptionsFailed} width='200px' height='200px' /> */}
                    <img width='250px' height='250px' style={{
                        height: "250px",
                        width: "250px",
                    }} src={require('Assets/lottie/order-failed.gif')} alt="loading..." />
                    <div className="displaySM w-500" style={{ color: 'var(--white)' }}>Order failed</div>
                </div>
            </div>
        } else {
            return <></>
        }
    }

    const onPlaceUpstoxOrder = ({ order }) => {
        document.getElementById("upstox_access_token").value = order.upstoxRequestToken;
        document.getElementById("order_data").value = order.upstoxOrder;
        document.getElementById(`uplink-${IS_MODIFY ? 'modify' : IS_CANCEL ? 'cancel' : 'place'}`).submit();
    }

    const onPlaceZerodhaOrder = ({ order }) => {
        const zorder = order?.orders?.map(o => {
            return {
                "variety": "regular",
                "tradingsymbol": o?.tradingSymbol,
                "exchange": "NSE",
                "transaction_type": o?.transactionType,
                "order_type": order?.orderType,
                "quantity": o?.quantity,
                "price": o?.price,
                "readonly": true, // does not let user edit on zerodha page
            }
        })
        // document.getElementById("zerodha_access_token").value = user?.Client?.[0]?.ZerodhaAPIKey;
        document.getElementById("basket").value = JSON.stringify(zorder);
        document.getElementById("basket-form").submit();
    }

    function getNextSIPDate22(sipDate, freq = "daily", dayName) {
        //daily
        //weekly => otherdata => day
        //monthly => date
        function addMonths(date, months) {
            date.setMonth(date.getMonth() + months);
            return date;
        }

        if (freq === "daily") {
            var today = new Date();
            today.setDate(today.getDate() + 1);
            return today
        } else if (freq === "weekly") {
            var excludeToday = true
            var refDate = new Date();
            const dayOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
                .indexOf(dayName.slice(0, 3).toLowerCase());
            if (dayOfWeek < 0) return;
            refDate.setHours(0, 0, 0, 0);
            refDate.setDate(refDate.getDate() + +!!excludeToday +
                (dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7);

            const diffTime = Math.abs(refDate - new Date());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (diffDays > 5) {
                return refDate
            } else {
                refDate.setDate(refDate.getDate() + +!!excludeToday +
                    (dayOfWeek + 14 - refDate.getDay() - +!!excludeToday) % 7);
                return refDate
            }
        } else if (freq === "monthly") {
            var mon = 1
            const date1 = new Date();
            const date2 = new Date(addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), sipDate), mon));
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (diffDays < 27) {
                return new Date(addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), sipDate), mon + 1))
            } else {
                return new Date(addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), sipDate), mon))
            }
        }


    }

    const GoalSipSection = () => {
        return <Card bodyStyle={{ backgroundColor: 'var(--grey3)', borderRadius: 'var(--borderRadius)', marginTop: '1rem' }}>
            <div >
                <div style={{ display: 'flex', gap: '1rem', alignItems: 'center', marginBottom: '1rem' }}>
                    <div style={{}}>
                        <div className="textSM w-500">SIP frequency:</div>
                        {/* <div className="textXS dark2">SIP investment frequency</div> */}
                    </div>
                    <div style={{}} className="textSM w-500">
                        {GOAL_OBJ?.sipFreq === 3 ? "Every three months" : GOAL_OBJ?.sipFreq === 6 ? "Every six months" : GOAL_OBJ?.sipFreq === 1 ? "Every year" : "Every month"}
                    </div>
                </div>
                <>
                    <IoCalendarOutline className='textLG' style={{ color: "var(--primary)" }} />
                    <div className='textSM dark3'>
                        <><span className='textLG w-500 black'>{ordinal_suffix_of(Number(sipDate))}</span> of the month {GOAL_OBJ?.sipFreq === 3 ? "(every three months)" : GOAL_OBJ?.sipFreq === 6 ? "(every six months)" : GOAL_OBJ?.sipFreq === 1 ? "(every year)" : "(every month)"}</>
                    </div>
                    <Popover open={showDateModal} trigger={'click'} arrow={false} placement="topLeft" content={
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                                <div className="textLG w-500 mb-4">Select SIP date</div>
                                <div style={{ cursor: 'pointer' }} onClick={() => setShowDateModal(false)}><AiFillCloseCircle style={{ fontSize: '1.5rem' }} /></div>
                            </div>
                            <div className='dateSelector'>
                                {new Array(28)?.fill(1)?.map((item, i) => (
                                    <div key={generateUniqueKey(i + 1)} className={sipDate === (i + 1) ? "selectedDate" : ""}
                                        onClick={() => { setSipDate(i + 1); setShowDateModal(false) }}>
                                        {i + 1}
                                    </div>
                                ))}
                            </div>
                        </>
                    }>
                        <div style={{ color: "var(--primary)", cursor: "pointer" }} className='textSM w-500' onClick={() => setShowDateModal(true)}
                            id='sdmsuraj'>
                            Change <BiChevronRight color='var(--primary)' className='textSM' />
                        </div>
                    </Popover>
                </>
                <div className='textXS dark3' style={{ borderTop: "1px solid var(--grey2)", paddingTop: '0.5rem', marginTop: '1rem' }}>
                    <b>Note: </b> {GOAL_OBJ?.sipFreqText}
                    <div>
                        Your next SIP date is  {getNextSIPDate2(sipDate, GOAL_OBJ?.sipFreq === 12 ? 1 : GOAL_OBJ?.sipFreq === 1 ? 12 : GOAL_OBJ?.sipFreq)}.
                    </div>
                </div>
            </div>
        </Card>
    }

    const SipSection = ({ showAlsoRebalance = false }) => {
        return <Card bodyStyle={{ backgroundColor: 'var(--grey3)', borderRadius: 'var(--borderRadius)', marginTop: '1rem' }}>
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <div style={{}}>
                    <div className="textSM w-500">Do you want to start an SIP?</div>
                    <div className="textXS dark2">You will receive a reminder every month</div>
                </div>
                <div style={{}}>
                    <BuySellSwitch type={2} value={isSip} onChange={(e) => setIsSip(e.target.checked)} />
                </div>
            </div>
            {
                isSip ?
                    <div style={{ marginTop: '1rem' }}>
                        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center', marginBottom: '1rem' }}>
                            <div style={{}}>
                                <div className="textSM w-500">SIP frequency</div>
                                {/* <div className="textXS dark2">SIP investment frequency</div> */}
                            </div>
                            <div style={{}}>
                                <Segmented className="sipFreqTabs" options={sipTypes.map(t => t.label)} value={sipTypes.find(x => x.value === sipFrequency)?.label} onChange={(l) => setSipFrequency(sipTypes.find(x => x.label === l)?.value)} />
                            </div>
                        </div>
                        {
                            sipFrequency === '1D' ?
                                <></>
                                :
                                <>
                                    <IoCalendarOutline className='textLG' style={{ color: "var(--primary)" }} />
                                    <div className='textSM dark3'>
                                        {
                                            sipFrequency === '1M' ?
                                                <><span className='textLG w-500 black'>{ordinal_suffix_of(Number(sipDate))}</span> of every month</>
                                                :
                                                sipFrequency === '1W' ?
                                                    <><span className='textLG w-500 black'>{sipDay}</span> every week</>
                                                    :
                                                    <></>

                                        }
                                    </div>
                                    <Popover open={showDateModal} trigger={'click'} arrow={false} placement="topLeft" content={
                                        sipFrequency === '1M' ?
                                            <>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                                                    <div className="textLG w-500 mb-4">Select SIP date</div>
                                                    <div style={{ cursor: 'pointer' }} onClick={() => setShowDateModal(false)}><AiFillCloseCircle style={{ fontSize: '1.5rem' }} /></div>
                                                </div>
                                                <div className='dateSelector'>
                                                    {new Array(28)?.fill(1)?.map((item, i) => (
                                                        <div key={i + 1} className={sipDate === (i + 1) ? "selectedDate" : ""} onClick={() => { setSipDate(i + 1); setShowDateModal(false) }}>
                                                            {i + 1}
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="textLG w-500 mb-4">Select SIP day</div>
                                                <div style={{ width: '200px' }}>
                                                    {
                                                        sipDays.map(d => {
                                                            return <div onClick={() => { setSipDay(d.value); setShowDateModal(false) }} style={{ padding: '0.25rem', borderBottom: '1px solid var(--grey4)', backgroundColor: d.value === sipDay ? 'var(--blueShade)' : '', cursor: 'pointer' }}>{d.value}</div>
                                                        })
                                                    }
                                                </div>
                                            </>
                                    }>
                                        <div style={{ color: "var(--primary)", cursor: "pointer" }} className='textSM w-500' onClick={() => setShowDateModal(true)}
                                            id='sdmsuraj'>
                                            Change <BiChevronRight color='var(--primary)' className='textSM' />
                                        </div>
                                    </Popover>
                                </>
                        }
                        {
                            showAlsoRebalance && <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
                                <div style={{}}>
                                    <div className="textSM w-500">Also rebalance on SIP dates</div>
                                    <div className="textXS dark2">This will override your selected rebelancing frequency and your portfolio will be rebalanced on every SIP date</div>
                                </div>
                                <div style={{}}>
                                    <BuySellSwitch type={2} value={isReDateSame} onChange={(e) => setIsReDateSame(e.target.checked)} />
                                </div>
                            </div>
                        }
                        {/* <DateSelectModal /> */}
                        {isSip && <div className='textXS dark3' style={{ borderTop: "1px solid var(--grey2)", paddingTop: '0.5rem', marginTop: '1rem' }}>
                            {/* <b>Note:</b> First investment will be done today. You will get a reminder on <b>{ordinal_suffix_of(sipDate)}</b> of each month for subsequent investments. <span className="primary" style={{ cursor: 'pointer' }}>What will happen on SIP date?</span> */}
                            <b>Note:</b> First investment will be done today and  <b>{sipTypes?.filter((i) => i?.value === sipFrequency)?.[0]?.label?.toLocaleLowerCase()}</b> SIP will start from <b>{formatDate(getNextSIPDate22(sipDate, sipTypes?.filter((i) => i?.value === sipFrequency)?.[0]?.label?.toLocaleLowerCase(), sipDay))}</b> <span className="primary" style={{ cursor: 'pointer' }}>What will happen on SIP day?</span>
                        </div>}
                    </div>
                    :
                    <></>
            }
        </Card>
    }

    const createOrder = async ({ order }) => {
        console.log('lllllllllll', order);
        try {
            let res2 = await apiHelper({ apiName: 'createOrder', data: order, saveResponse: '' })
            console.log("lllllllllll", res2)
            var flag = false
            if (broker === "zerodha" && !user?.Client?.[0]?.ZerodhaAPIKey) { // zerodha publisher account
                // onPlaceZerodhaOrder({ order: order });
            } else if (broker === 'upstox') {
                res2 = res2[0]
                if (res2.upstoxRequestToken && res2.upstoxOrder) {
                    // document.getElementById("upstox_access_token").value = res2.upstoxRequestToken;
                    onPlaceUpstoxOrder({ order: res2 })
                    flag = true;
                } else {
                    setTrxnStatus('failed')
                }
            } else {
                if (res2) {
                    let count = 0;
                    res2.map(r => {
                        let x = r?.status?.toLowerCase()
                        if (x === 'true' || x === 'transit' || x === 'ok' || x === 'success') {
                            count = count + 1
                        }
                    })
                    if (res2?.length === count) {
                        flag = true;
                        setTrxnStatus('success');
                    } else {
                        setTrxnStatus('failed');
                        toast.error('Some orders could not be placed')
                    }
                }
                else {
                    setTrxnStatus('failed')
                }
            }
            setTimeout(() => {
                if (flag) {
                    AFTER_SUCCESS_INV();
                    setOpenInvModal(false)
                    // navigate('/investments?orders=true')
                } else {
                    setOpenInvModal(false)
                    setTrxnStatus('')
                }
            }, 9000);
        } catch (error) {
            setTrxnStatus('')
            setOpenInvModal(false)
            toast.error("Something went wrong. Please try again after some time")
        }
    }

    const PortfolioChangeTable = ({ orders }) => {
        if (orders.length) {
            return <Collapse bordered={false} style={{ marginBottom: '1rem', background: 'var(--white)', border: '1px solid var(--grey2)', borderRadius: 'var(--borderRadius)', }} expandIconPosition="end" expandIcon={({ isActive }) => <IoIosArrowDown style={{
                transition: "all 0.3s linear",
                transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
                color: "var(--black)",
                fontSize: "1rem", alignSelf: 'center', marginTop: '0.5rem'
            }} />} defaultActiveKey={1} items={[{
                key: 1,
                style: { borderBottom: "none" },
                label: <CardHeader heading="Portfolio change" style={{}} hideHeaderBlueLine />,
                children: <div>
                    <div className='d-flex' style={{ alignItems: 'center', gap: "0.75rem", padding: '0.5rem', borderBottom: '1px solid var(--grey2)' }}>
                        <div className="textXS black w-500" style={{ flex: 1 }}>Constituents</div>
                        <div className="textXS black w-500" style={{ width: '100px', textAlign: 'center' }}>
                            Current allocation
                        </div>
                        <div className="textXS black w-500" style={{ width: '50px', textAlign: 'center' }}>

                        </div>
                        <div className="textXS black w-500" style={{ width: '100px', textAlign: 'center' }}>
                            After execution
                        </div>
                    </div>
                    {
                        rebalanceLoading || ordersLoading ?
                            [1, 2, 3, 4].map(x => <div className='d-flex' style={{ alignItems: 'center', gap: "0.75rem", padding: '0.5rem', borderBottom: '1px solid var(--grey2)' }}>
                                <div>
                                    <Skeleton.Input style={{ width: '100px', height: '16px' }} />
                                </div>
                            </div>)
                            :
                            <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                                {
                                    orders?.map(o => {
                                        return <div className='d-flex' style={{ alignItems: 'center', gap: "0.75rem", padding: '0.5rem', borderBottom: '1px solid var(--grey2)' }}>
                                            <div style={{ flex: 1, textOverflow: 'ellipsis' }}>
                                                <div className="textXS">{o.fundCode}</div>
                                            </div>
                                            <div className="textXS black w-500" style={{ width: '100px', textAlign: 'center', whiteSpace: 'nowrap' }}>
                                                {(Number(o.weightY) * 100).toFixed(1)}% <span className='w-400 dark2' style={{ fontSize: '8px' }}>({o.numSharesY} qty)</span>
                                            </div>
                                            <div className="textXS" style={{ width: '50px', textAlign: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <span style={{ fontSize: '8px', lineHeight: '8px', marginBottom: '-8px' }}>{o.buySellFlag === -1 ? <span style={{ color: 'var(--red)' }}>SELL {Math.abs(o.rebalanceUnits)}</span> : o.buySellFlag === 1 ? <span style={{ color: 'var(--green)' }}>BUY {o.rebalanceUnits}</span> : <span style={{ color: 'var(--dark3)' }}>No change</span>}</span>
                                                    <HiArrowLongRight style={{ width: '30px', height: 'auto', color: 'var(--dark2)' }} />
                                                </div>
                                            </div>
                                            <div className="textXS black w-500" style={{ width: '100px', textAlign: 'center' }}>
                                                {(Number(o.weightX) * 100).toFixed(1)}% <span className='w-400 dark2' style={{ fontSize: '8px' }}>({o.numSharesX} qty)</span>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                    }
                </div>
            }]} />
        }
        else return <></>
    }

    const ReviewPortfolio = ({ orders }) => {
        if (orders?.length) {
            return <Collapse bordered={false} style={{ marginBottom: '1rem', background: 'var(--white)', border: '1px solid var(--grey2)', borderRadius: 'var(--borderRadius)', }} expandIconPosition="end" expandIcon={({ isActive }) => <IoIosArrowDown style={{
                transition: "all 0.3s linear",
                transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
                color: "var(--black)",
                fontSize: "1rem", alignSelf: 'center', marginTop: '0.5rem'
            }} />} defaultActiveKey={1} items={[{
                key: 1,
                label: <CardHeader heading="Review Portfolio" style={{}} hideHeaderBlueLine headingType={0} />,
                style: { borderBottom: 'none' },
                children: <div>
                    <div className='d-flex' style={{ alignItems: 'flex-start', gap: "0.75rem", padding: '0.5rem', borderBottom: '1px solid var(--grey2)' }}>
                        <div className="textXS black w-500" style={{ flex: 1 }}>Instrument</div>
                        <div style={{ width: '100px', textAlign: 'right' }}>
                            <div className="textXS black w-500">Current</div>
                            <div className="textXS dark3">units(weights)</div>
                        </div>
                    </div>
                    {
                        rebalanceLoading || ordersLoading ?
                            [1, 2, 3, 4].map(x => <div className='d-flex' style={{ alignItems: 'center', gap: "0.75rem", padding: '0.5rem', borderBottom: '1px solid var(--grey2)' }}>
                                <div>
                                    <Skeleton.Input style={{ width: '100px', height: '16px' }} />
                                </div>
                            </div>)
                            :
                            <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                                {
                                    orders?.map(o => {
                                        return <div className='d-flex' style={{ alignItems: 'center', gap: "0.75rem", padding: '0.5rem', borderBottom: '1px solid var(--grey2)' }}>
                                            <div style={{ flex: 1, textOverflow: 'ellipsis' }}>
                                                <div className="textXS w-700 dark4">{o.fundCode}</div>
                                            </div>
                                            <div className="textXS black" style={{ width: '100px', textAlign: 'right' }}>
                                                {o.numSharesY} ({(Number(o.weightY) * 100).toFixed(1)}% )
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                    }
                </div>
            }]} />
        }
        else return <></>
    }

    const InvModal = () => {
        const onRadioChange = (e) => {
            if (e.target.value === false) {
                setPrice(Number(getLTP({ fundData: FUNDDATA })))
            }
            setIsMarket(e.target.value)
        }
        // for rebalance and sip from notification
        if (IS_REBALANCE) {
            return <Drawer open={openInvModal} closable={false} maskClosable={true} onClose={() => {
                setOpenInvModal(false);
            }} width='500px' styles={{ footer: { padding: 0 } }}
                footer={
                    <div>
                        {/* <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: "1px solid var(--grey2)", padding: '0.5rem 1.5rem' }}>
                            <div className='textXS'>Funds required:
                                <b> {CurrencyFormat(amount)}</b>
                            </div>
                            <div className='textXS'>Available:
                                <b> {CurrencyFormat(Number(funds?.totalBalance))}</b></div>
                        </div> */}
                        <div style={{ display: 'flex', gap: '1rem', padding: '1rem 1.5rem' }}>
                            <button style={{ flex: 1 }} className="btnWhite " onClick={() => setOpenInvModal(false)}>Cancel</button>
                            <button className="btnPrimary " style={{ flex: 1 }}
                                onClick={async () => {
                                    if (Number(amount) > funds?.totalBalance) {
                                        toast.error("Fund Limit Insufficient. Please add funds in your broker account.");
                                        return;
                                    }
                                    setTrxnStatus('loading')

                                    const order = {
                                        "clientId": user.id,
                                        "broker": broker,
                                        "sourceAppId": 2,
                                        "instrumentType": stype?.value,
                                        "orderType": "MARKET",
                                        "orders": liveTradeOrders?.filter((order) => Number(order?.order_quantity) !== 0)?.map((order) => {
                                            return {
                                                "transactionType": String(order?.direction)?.toUpperCase(),
                                                "tradingSymbol": order?.symbol,
                                                "quantity": order?.order_quantity,
                                                "exchange": order?.exchange || "NSE",
                                                "internalRefTag": order?.sharpelyorderid

                                            }
                                        })
                                    }

                                    console.log(order)
                                    await createOrder({ order })

                                }} disabled={INSTRUMENT_TYPE === 2 || trxnStatus === 'loading' || liveTradeOrders?.orders?.filter(o => o.order_quantity)?.length === 0}>
                                {trxnStatus === 'loading' ? <LoadingOutlined style={{ fontSize: '1.5rem', color: "var(--black)" }} spin /> : IS_REBALANCE ? 'Place rebalance order' : 'Place SIP order'}
                            </button>
                        </div>
                    </div>
                }>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', minHeight: '400px', gap: '2rem', height: '100%' }}>
                    <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                        {/* {
                            rebalanceObj?.basketId === 0 ? // single stock sip
                                rebalanceLoading ?
                                    <Skeleton />
                                    :
                                    <>
                                        <div style={{ display: 'flex', gap: '1rem' }}>
                                            <AMCIcon amcName={rebalanceObj?.orders?.[0]?.instrumentType === 4 ? rebalanceObj?.FUNDDATA?.symbol : rebalanceObj?.FUNDDATA?.amc_full_name} bseTicker={rebalanceObj?.FUNDDATA?.bse_ticker}
                                                height='3rem' width='3rem' type={'square'} fundType={rebalanceObj?.orders?.[0]?.instrumentType} />
                                            <div>
                                                <div className="textLG black w-500">{rebalanceObj?.orders?.[0]?.instrumentType === 4 ? rebalanceObj?.FUNDDATA?.proper_name :
                                                    rebalanceObj?.FUNDDATA?.basic_name?.replace(" - Direct Plan", "")}</div>
                                                <div className={"textSM dark3 w-700"} style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                                    {rebalanceObj?.FUNDDATA?.symbol}
                                                    <a href={`https://www.nseindia.com/get-quotes/equity?symbol=${rebalanceObj?.FUNDDATA?.symbol}`} target='_blank'><Tag color='var(--green)'>NSE</Tag></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between', alignItems: 'center' }}>
                                            {rebalanceObj?.orders?.[0]?.instrumentType === 1 ? <StockLivePrice fundData={rebalanceObj?.FUNDDATA} format={9} symbol={FUNDDATA?.nse_symbol} /> : rebalanceObj?.orders?.[0]?.instrumentType == 4 ?
                                                <StockLivePrice fundData={rebalanceObj?.FUNDDATA} format={9} symbol={rebalanceObj?.FUNDDATA?.symbol} /> : rebalanceObj?.orders?.[0]?.instrumentType === 2 ?
                                                    <div className="textMD w-500 black">{CurrencyFormat(rebalanceObj?.FUNDDATA?.latest_nav, "long")}</div> :
                                                    <></>
                                            }
                                        </div>
                                        <Card style={{ border: '1px solid var(--grey1)', marginBottom: '1rem', }}>
                                            <CardHeader heading="Order details" style={{ marginBottom: '0.5rem' }} hideHeaderBlueLine />
                                            <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
                                                <div style={{ flex: 1, display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                                                    <div className='textSM w-500'>Quantity</div>
                                                    <CurrencyInput
                                                        className='currencyInputIc'
                                                        id="quantity"
                                                        name="quantity"
                                                        placeholder="Number of shares"
                                                        value={rebalanceObj?.orders?.[0]?.rebalanceUnits}
                                                        contentEditable={false}
                                                        allowDecimals={false}
                                                        allowNegativeValue={false}
                                                        disableAbbreviations={false}
                                                        autoComplete={false}
                                                        prefix=' '
                                                        intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                                                        style={{ maxWidth: '150px' }}
                                                    />
                                                </div>
                                                <div style={{ flex: 1, display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                                                    <div className='textSM w-500'>Price</div>
                                                    <div>
                                                        <CurrencyInput
                                                            className='currencyInputIc'
                                                            id="price"
                                                            name="price"
                                                            value={Number(getLTP({ fundData: rebalanceObj?.FUNDDATA }))}
                                                            suffix={' (at mkt)'}
                                                            contentEditable={false}
                                                            allowDecimals={true}
                                                            decimalsLimit={2}
                                                            decimalScale={2}
                                                            allowNegativeValue={false}
                                                            disableAbbreviations={false}
                                                            autoComplete={false}
                                                            prefix=' '
                                                            intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                                                            style={{ maxWidth: '150px' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </>
                                : */}
                        {rebalanceLoading ?
                            <Skeleton avatar title paragraph={false} />
                            :
                            <>
                                {/* <div className='d-flex' style={{ alignItems: 'center', gap: "1rem" }}>
                                    {rebalanceObj?.orders?.filter(o => o.rebalanceUnits)?.length > 0 &&
                                        <BasketIcon id={rebalanceObj?.basketId > 30 ? rebalanceObj?.basketId % 30 : rebalanceObj?.basketId} height={"3rem"} width={"3rem"}
                                            bg='var(--blueShade)' border='1px solid var(--primary)' padding='0.5rem' />}
                                    <div>
                                        <div className="textLG black w-500">{rebalanceObj?.FUNDDATA?.MintboxName}</div>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', flexWrap: 'wrap' }}>
                                            <CustomTag text={`${rebalanceObj?.FUNDDATA?.Risk} ${window.innerWidth > 760 ? 'risk' : ''}`}
                                                color={RiskTextColor(Number(rebalanceObj?.FUNDDATA?.RiskLevel))}
                                                bgColor={RiskColor(Number(rebalanceObj?.FUNDDATA?.RiskLevel))}
                                                onClick={() => { }} />
                                        </div>
                                    </div>
                                </div> */}
                                {liveTradeOrders?.length === 0 ? <div>It seems that your portfolio is up to date and no rebalancing trade is required</div> :

                                    <div>
                                        <div className='textLG mb-3 w-bold'>{strategy_config?.strategy_name}</div>
                                        {liveTradeOrders?.map((data, i, arr) => {
                                            var record = data
                                            return <div key={makeid(i)} style={{
                                                display: 'flex', flexDirection: "row", justifyContent: "space-between",
                                                borderBottomColor: 'var(--grey1)',
                                                borderBottomWidth: i === arr?.length - 1 ? 0 : 1,
                                                paddingHorizontal: 16, paddingVertical: 10,
                                                gap: 10
                                            }}>
                                                <div key={data.FundCode} style={{
                                                    display: 'flex', flexDirection: "row", flex: 3, gap: 10
                                                }}>
                                                    <AMCIcon amcName={stype?.value === 4 ? record.symbol : record.amc_full_name} fundType={stype?.value} height='1.15rem' width="1.15rem" bseTicker={record?.bse_ticker} />
                                                    <div>
                                                        <div className="w-500 black" style={{ width: 180, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{record?.proper_name || record.basic_name}</div>
                                                        {stype?.value !== 2 && <StockLivePrice fundData={record} symbol={record?.symbol || record.nse_symbol} format={10} />}
                                                    </div>
                                                </div>
                                                <div style={{ gap: 5, display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                                    <span className='textXS'>
                                                        QTY : <span style={{}}>
                                                            {DecimalValueFormat({ num: Number(data?.order_quantity), decimals: 0 })}
                                                        </span></span>
                                                    <div className='textXS' style={{ gap: 10, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                        {data?.order_type && <span style={{ backgroundColor: 'var(--blueShade)', color: 'var(--primary)', padding: 2, borderRadius: 4 }}>
                                                            {data?.order_type}
                                                        </span>}
                                                        <span style={{ backgroundColor: data?.direction === "BUY" ? 'var(--greenShade)' : 'var(--redShade)', color: data?.direction === "BUY" ? 'var(--green)' : 'var(--red)', padding: 2, borderRadius: 4 }}>
                                                            {data?.direction}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                        <Divider />
                                        <div className='textXS w-500 textLight'>Disclaimer: <span className='w-400'>Quantities shown are only an approximation based on last available prices. They can change based on actual fill prices when virtual orders are executed.</span></div>
                                    </div>
                                    // <Table
                                    //     dataSource={liveTradeOrders}
                                    //     columns={[{
                                    //         "title": "Name",
                                    //         'disableDeleteInCustomize': true,
                                    //         "dataIndex": INSTRUMENT_TYPE === 4 ? "proper_name" : "basic_name",
                                    //         render: (text, record, index) => <Tooltip title={record.proper_name || record.basic_name} arrow={false}>
                                    //             <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center" }}>
                                    // <AMCIcon amcName={INSTRUMENT_TYPE === 4 ? record.symbol : record.amc_full_name} fundType={INSTRUMENT_TYPE} height='1.15rem' width="1.15rem" bseTicker={record?.bse_ticker} />
                                    //     <div>
                                    //         <div className="w-500 black" style={{ width: 180, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{record?.proper_name || record.basic_name}</div>
                                    //         {INSTRUMENT_TYPE !== 2 && <StockLivePrice fundData={record} symbol={record?.symbol || record.nse_symbol} format={10} />}
                                    //     </div>
                                    // </div>
                                    //         </Tooltip>,
                                    //         width: 150,
                                    //         fixed: 'left',
                                    //         show: true
                                    //     }, ...liveTradeCols.map((col) => {
                                    //         return {
                                    //             ...col,
                                    //             "render": (text, record, index) => <div style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "center" }}>
                                    //                 {displayValue({
                                    //                     text, metricObj: col
                                    //                 })}
                                    //             </div>
                                    //         }
                                    //     })]}
                                    //     rowKey={(record) => record.plan_id || record.symbol}
                                    //     scroll={{ x: 'max-content' }}
                                    //     size="small"
                                    // />
                                }
                            </>
                        }
                    </div>
                </div>
                <TransactionStatusOverlay />
            </Drawer >
        }
        // for first time goal investment
        else if (IS_GOAL) {
            return <Drawer open={openInvModal} closable={false} maskClosable={true} onClose={() => {
                setOpenInvModal(false);
            }} width='500px'
                styles={{ footer: { padding: 0 } }}
                footer={
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: "1px solid var(--grey2)", padding: '0.25rem 1.5rem' }}>
                            <div className='textXS'>Funds required:
                                <b> {CurrencyFormat(Number(GOAL_OBJ?.goalfinal?.initialInvestment) + (GOAL_OBJ?.sipFreq === 1 ? 12 * Number(GOAL_OBJ?.sip) : GOAL_OBJ?.sipFreq === 12 ? Number(GOAL_OBJ?.sip) : GOAL_OBJ?.sipFreq * Number(GOAL_OBJ?.sip)))}</b></div>
                            <div className='textXS'>Available:
                                <b> {CurrencyFormat(Number(funds?.totalBalance))}</b></div>
                        </div>
                        <div style={{ display: 'flex', gap: '1rem', padding: '1rem 1.5rem' }}>
                            <button style={{ flex: 1 }} className="btnWhite " onClick={() => {
                                setOpenInvModal(false);
                            }}>Cancel</button>
                            <button className="btnPrimary "
                                style={{ flex: 1, color: 'var(--white)', border: 'none', backgroundColor: transactionType === 'BUY' ? 'var(--green)' : 'var(--red)' }}
                                onClick={async () => {
                                    let finalAmount = GOAL_OBJ?.finalInvAmount;
                                    if (funds?.totalBalance < Number(finalAmount)) {
                                        toast.error("Fund Limit Insufficient. Please add funds in your broker account.");
                                        return;
                                    }
                                    let goalData = await BEFORE_GOAL_INV();
                                    // let goalData = {"GoalId":620}
                                    console.log(goalData);
                                    if (goalData?.GoalId) {
                                        setTrxnStatus('loading')
                                        var dataFinal = {
                                            "mintbox_id": GOAL_OBJ?.inv_MintboxId,
                                            "client_id": user?.id,
                                            "total_funds": Number(finalAmount)
                                        }
                                        var res = await AdminServicesPY.genNewBasketOrder(dataFinal).then(res => res)
                                        if (res?.type && res?.data && res?.data?.length) {
                                            const obj = res?.data.map(k => {
                                                return {
                                                    "weights": k.weights,
                                                    "tradingSymbol": k.symbol,
                                                    "quantity": k.num_shares,
                                                    "ltp": k.ltp
                                                }
                                            })
                                            const order = {
                                                "clientId": user.id,
                                                "broker": broker,
                                                "sourceAppId": 2,
                                                "basketId": GOAL_OBJ?.inv_MintboxId,
                                                "goalId": Number(goalData?.GoalId) || 0,
                                                "isWealthCreator": 0,
                                                "instrumentType": GOAL_OBJ?.instrumentType,
                                                "basketAmount": Number(Math.ceil(finalAmount)),
                                                "isAMO": isMarketOpen() ? false : true,
                                                "orderType": "MARKET",
                                                "isSIPOrder": Number(GOAL_OBJ.sip) !== 0,
                                                "frequency": Number(GOAL_OBJ.sip) !== 0 ? (GOAL_OBJ?.sipFreq === 1 ? "1Y" : GOAL_OBJ?.sipFreq === 12 ? "1M" : `${GOAL_OBJ?.sipFreq}M`) : "",
                                                "sipDay": Number(GOAL_OBJ?.sip) !== 0 ? String(sipDate) : "",
                                                "sipStartDate": Number(GOAL_OBJ?.sip) !== 0 ? formatDateyyyymmdd(getNextSIPDatel1(sipDate, GOAL_OBJ?.sipFreq === 12 ? 1 : GOAL_OBJ?.sipFreq === 1 ? 12 : GOAL_OBJ?.sipFreq)) : "",
                                                "orders": obj?.map((order) => {
                                                    return {
                                                        "transactionType": "BUY",
                                                        "tradingSymbol": order?.tradingSymbol,
                                                        "quantity": order?.quantity,
                                                    }
                                                })
                                            }

                                            console.log('executing', order);
                                            let res2 = await apiHelper({ apiName: 'createOrder', data: order, saveResponse: '' })
                                            console.log("createOrder", res2)
                                            await createOrder({ order })

                                        } else {
                                            toast.error('Error')
                                        }
                                    }
                                }} disabled={INSTRUMENT_TYPE === 2 || trxnStatus === "loading"}>
                                {trxnStatus === 'loading' ? <LoadingOutlined style={{ fontSize: '1.5rem', color: "var(--white)" }} spin /> : 'Confirm & Invest'}
                            </button>
                        </div>
                    </div>
                }>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', minHeight: '400px', gap: '2rem', height: '100%' }}>
                    <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                        <div>
                            <div className='textLG dark4 w-700'>Today's investment</div>
                            <div className='textMD dark4 w-700'>{Number(GOAL_OBJ?.onetime) === 0 ? "(First SIP instalment)" : Number(GOAL_OBJ?.sip) === 0 ? "(One-time lumpsum)" : "(One-time lumpsum + First SIP instalment)"}</div>
                        </div>
                        <div className='w100 d-flex align-items-center' style={{ borderBottom: "1px solid var(--grey3)", gap: "0.5rem" }}>
                            <div className='textXL w-700 dark2'>
                                {CurrencyFormat(Number(GOAL_OBJ?.goalfinal?.initialInvestment) + (GOAL_OBJ?.sipFreq === 1 ? 12 * Number(GOAL_OBJ?.sip) : GOAL_OBJ?.sipFreq === 12 ? Number(GOAL_OBJ?.sip) : GOAL_OBJ?.sipFreq * Number(GOAL_OBJ?.sip)))}
                            </div>
                            <Tooltip arrow={false} title='This is the investment required to achieve your goal as per your inputs and our assumptions.'>
                                <AiFillLock color='var(--primary)' size='1.5rem' style={{ cursor: "pointer" }} />
                            </Tooltip>
                        </div>
                        {Number(GOAL_OBJ?.sip) !== 0 && <GoalSipSection />}
                    </div>
                </div >
                <TransactionStatusOverlay />
            </Drawer >
        }
        // modify
        else if (IS_MODIFY) {
            return <Drawer open={openInvModal} closable={false} maskClosable={true} onClose={() => {
                setOpenInvModal(false);
            }} width='500px'
                styles={{ footer: { padding: 0 } }}
                footer={
                    <div>
                        {transactionType === 'BUY' && <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: "1px solid var(--grey2)", padding: '0.25rem 1.5rem' }}>
                            <div className='textXS'>Funds required:
                                <b> {CurrencyFormat(Number(quantity) * Number(price))}</b></div>
                            <div className='textXS'>Available:
                                <b> {CurrencyFormat(Number(funds?.totalBalance))}</b></div>
                        </div>}
                        {
                            transactionType === 'SELL' && <>
                                {
                                    <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: "1px solid var(--grey2)", padding: '0.25rem 1.5rem' }}>
                                        {
                                            totalStockQuantity === 0 ?
                                                <div className='textXS'><b>Note: </b> Your portfolio does not have any shares of {INSTRUMENT_TYPE === 1 ? FUNDDATA?.nse_symbol : FUNDDATA?.symbol} to SELL</div>
                                                :
                                                totalStockQuantity > totalStockQuantityDiy ?
                                                    <div className='textXS'><b>Note: </b> Your portfolio has <b> {totalStockQuantity}</b> shares out of which <b>{totalStockQuantityDiy}</b> shares are available to sell. If you want to sell the rest of your {INSTRUMENT_TYPE === 1 ? FUNDDATA?.nse_symbol : FUNDDATA?.symbol} shares then visit baskets/goals section.</div>
                                                    :
                                                    <div className='textXS'>Available quantity: <b> {totalStockQuantityDiy}</b> shares</div>
                                        }
                                    </div>
                                }
                            </>
                        }
                        <div style={{ display: 'flex', gap: '1rem', padding: '1rem 1.5rem' }}>
                            <button style={{ flex: 1 }} className="btnWhite " onClick={() => setOpenInvModal(false)}>Cancel</button>

                            <button className="btnPrimary "
                                style={{ flex: 1, color: 'var(--white)', border: 'none', backgroundColor: transactionType === 'BUY' ? 'var(--green)' : 'var(--red)' }}
                                onClick={async () => {
                                    if (funds?.totalBalance < Number(price) * quantity) {
                                        toast.error("Fund Limit Insufficient. Please add funds in your broker account.");
                                        return;
                                    }
                                    setTrxnStatus('loading')
                                    const order = {
                                        "clientId": user.id,
                                        "broker": broker,
                                        "sourceAppId": 2,
                                        "basketId": 0,
                                        "goalId": 0,
                                        "isWealthCreator": 0,
                                        "portfolioId": 0,
                                        "instrumentType": INSTRUMENT_TYPE,
                                        "isAMO": isMarketOpen() ? false : true,
                                        "orderType": isMarket ? 'MARKET' : 'LIMIT',
                                        "orders": [{
                                            "transactionType": transactionType,
                                            "tradingSymbol": INSTRUMENT_TYPE === 1 ? FUNDDATA?.nse_symbol : FUNDDATA?.symbol,
                                            "quantity": quantity,
                                            "price": Number(price),
                                            "orderId": MODIFY_ORDER_ID,
                                        }]
                                    }
                                    console.log('executing', order);
                                    let res2 = await apiHelper({ apiName: 'modifyOrder', data: order, saveResponse: '' })
                                    res2 = res2?.[0]
                                    console.log("modifyOrder", res2)
                                    var flag = false
                                    if (broker === 'upstox') {
                                        if (res2.upstoxRequestToken && res2.upstoxOrder) {
                                            console.log(res2.upstoxRequestToken)
                                            flag = true
                                            // document.getElementById("upstox_access_token").value = res2.upstoxRequestToken;
                                            onPlaceUpstoxOrder({ order: res2 })
                                        } else {
                                            setTrxnStatus('failed')
                                        }
                                    } else {
                                        let x = res2?.status?.toLowerCase()
                                        if (res2 && (x === 'true' || x === 'transit' || x === 'ok' || x === 'success')) {
                                            flag = true
                                            setTrxnStatus('modified')
                                            // toast.success("Order placed")
                                            // showNotification({ type: "success", message: `${transactionType} ${quantity} quantity ${FUNDDATA?.symbol} at ${isMarket ? 'MARKET' : CurrencyFormat(Number(price))}` })
                                        }
                                        else {
                                            setTrxnStatus('failed')
                                            // showNotification({ type: "failed", message: res2?.message })
                                            // toast.error("Something went wrong. Please try again. ERROR: " + res2?.message)
                                        }

                                    }

                                    setTimeout(() => {
                                        if (flag) {
                                            AFTER_SUCCESS_INV();
                                        }
                                        setOpenInvModal(false)
                                        setTrxnStatus('')
                                    }, 3000);
                                }} disabled={INSTRUMENT_TYPE === 2 || trxnStatus === "loading"}>
                                {trxnStatus === 'loading' ? <LoadingOutlined style={{ fontSize: '1.5rem', color: "var(--white)" }} spin /> : 'Modify'}
                            </button>
                        </div>
                    </div>
                }>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', minHeight: '400px', gap: '2rem', height: '100%' }}>
                    <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', gap: '1rem' }}>
                            <AMCIcon amcName={INSTRUMENT_TYPE === 4 ? FUNDDATA?.symbol : FUNDDATA?.amc_full_name} bseTicker={FUNDDATA?.bse_ticker}
                                height='3rem' width='3rem' type={'square'} fundType={INSTRUMENT_TYPE} />
                            <div>
                                <div className="textLG black w-500">{INSTRUMENT_TYPE === 4 ? FUNDDATA?.proper_name :
                                    FUNDDATA?.basic_name?.replace(" - Direct Plan", "")}</div>
                                <div className={"textSM dark3 w-700"} style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                    {FUNDDATA?.symbol}
                                    <a href={`https://www.nseindia.com/get-quotes/equity?symbol=${FUNDDATA?.symbol}`} target='_blank'><Tag color='var(--green)'>NSE</Tag></a>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                            {INSTRUMENT_TYPE === 1 ? <StockLivePrice fundData={FUNDDATA} format={9} symbol={FUNDDATA?.nse_symbol} /> : INSTRUMENT_TYPE == 4 ?
                                <StockLivePrice fundData={FUNDDATA} format={9} symbol={FUNDDATA?.symbol} /> : INSTRUMENT_TYPE === 2 ?
                                    <div className="textMD w-500 black">{CurrencyFormat(FUNDDATA?.latest_nav, "long")}</div> :
                                    <></>
                            }
                            <div className="displayXS" style={{ color: transactionType === 'BUY' ? 'var(--green)' : 'var(--red)' }}>{transactionType}</div>
                        </div>
                        <Card style={{ border: '1px solid var(--grey1)' }}>
                            <CardHeader heading="Order details" style={{ marginBottom: '0.5rem' }} hideHeaderBlueLine />

                            <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
                                <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                                    <div className='textSM w-500'>Quantity</div>
                                    <CurrencyInput
                                        className='currencyInputIc'
                                        id="quantity"
                                        name="quantity"
                                        placeholder="Number of shares"
                                        value={quantity || ''}
                                        allowDecimals={false}
                                        allowNegativeValue={false}
                                        disableAbbreviations={false}
                                        autoComplete={false}
                                        prefix=' '
                                        onValueChange={(value) => { setQuantity(Number(value)) }}
                                        intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                                        style={{ maxWidth: '150px' }}
                                    />
                                </div>
                                <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                                    <div className='textSM w-500'>Price</div>
                                    <div>
                                        <CurrencyInput
                                            className='currencyInputIc'
                                            id="price"
                                            name="price"
                                            placeholder={isMarket ? Number(getLTP({ fundData: FUNDDATA })) : 'Price'}
                                            disabled={isMarket}
                                            value={isMarket ? '' : price}
                                            allowDecimals={true}
                                            decimalsLimit={2}
                                            decimalScale={2}
                                            allowNegativeValue={false}
                                            disableAbbreviations={false}
                                            autoComplete={false}
                                            prefix=' '
                                            onValueChange={(value) => { setPrice(value) }}
                                            intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                                            style={{ maxWidth: '150px' }}
                                        />
                                        <Radio.Group name="radiogroup" value={isMarket} onChange={onRadioChange} style={{ display: 'flex', gap: '8px', alignItems: 'center', marginTop: '0.5rem' }} >
                                            <Radio value={true}>Market</Radio>
                                            <Radio value={false}>Limit</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                <TransactionStatusOverlay />
            </Drawer >
        }
        // cancel
        else if (IS_CANCEL) {
            return <Drawer open={openInvModal} closable={false} maskClosable={true} onClose={() => {
                setOpenInvModal(false);
            }} width='500px'
                styles={{ footer: { padding: 0 } }}
                footer={
                    <div>
                        <div style={{ display: 'flex', gap: '1rem', padding: '1rem 1.5rem' }}>
                            <button style={{ flex: 1 }} className="btnWhite " onClick={() => setOpenInvModal(false)}>No</button>

                            <button className="btnPrimary "
                                style={{ flex: 1, color: 'var(--white)', border: 'none', backgroundColor: 'var(--red)' }}
                                onClick={async () => {
                                    setTrxnStatus('loading')
                                    const order = {
                                        "clientId": user.id,
                                        "broker": broker,
                                        "sourceAppId": 2,
                                        "basketId": 0,
                                        "goalId": 0,
                                        "isWealthCreator": 0,
                                        "portfolioId": 0,
                                        "instrumentType": INSTRUMENT_TYPE,
                                        "isAMO": isMarketOpen() ? false : true,
                                        "orderType": isMarket ? 'MARKET' : 'LIMIT',
                                        "orders": [{
                                            "transactionType": transactionType,
                                            "tradingSymbol": INSTRUMENT_TYPE === 1 ? FUNDDATA?.nse_symbol : FUNDDATA?.symbol,
                                            "quantity": quantity,
                                            "price": Number(price),
                                            "orderId": MODIFY_ORDER_ID,
                                        }]
                                    }
                                    console.log('executing', order);
                                    let res2 = await apiHelper({ apiName: 'cancelOrder', data: order, saveResponse: '' })
                                    res2 = res2?.[0]
                                    console.log("cancelOrder", res2)
                                    var flag = false;
                                    if (broker === 'upstox') {
                                        if (res2.upstoxRequestToken && res2.upstoxOrder) {
                                            console.log(res2.upstoxRequestToken)
                                            flag = true;
                                            // document.getElementById("upstox_access_token").value = res2.upstoxRequestToken;
                                            onPlaceUpstoxOrder({ order: res2 })
                                        } else {
                                            setTrxnStatus('failed')
                                        }
                                    } else {
                                        let x = res2?.status?.toLowerCase()
                                        if (res2 && (x === 'cancelled' || x === 'transit' || x === 'true' || x === 'ok' || x === 'success')) {
                                            flag = true;
                                            setTrxnStatus('cancelled')
                                            // toast.success("Order placed")
                                            // showNotification({ type: "success", message: `${transactionType} ${quantity} quantity ${FUNDDATA?.symbol} at ${isMarket ? 'MARKET' : CurrencyFormat(Number(price))}` })
                                        }
                                        else {
                                            setTrxnStatus('failed')
                                            // showNotification({ type: "failed", message: res2?.message })
                                            // toast.error("Something went wrong. Please try again. ERROR: " + res2?.message)
                                        }

                                    }

                                    setTimeout(() => {
                                        if (flag) {
                                            AFTER_SUCCESS_INV();
                                        }
                                        setOpenInvModal(false)
                                        setTrxnStatus('')
                                    }, 3000);
                                }} disabled={INSTRUMENT_TYPE === 2 || trxnStatus === "loading"}>
                                {trxnStatus === 'loading' ? <LoadingOutlined style={{ fontSize: '1.5rem', color: "var(--white)" }} spin /> : 'CANCEL'}
                            </button>
                        </div>
                    </div>
                }>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', minHeight: '400px', gap: '2rem', height: '100%' }}>
                    <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                        <Card style={{ border: '1px solid var(--grey1)' }}>
                            <CardHeader heading="Cancel order" style={{ marginBottom: '0.5rem' }} hideHeaderBlueLine />
                            <div style={{ display: 'flex', gap: '1rem' }}>
                                <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                                    <div className='textSM w-500'>Are you sure?</div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                <TransactionStatusOverlay />
            </Drawer >
        }
        // for additional buy or sell in basket
        else if (IS_BASKET && PORTFOLIO_ID !== 0) {
            return <Drawer open={openInvModal} closable={false} maskClosable={true} onClose={() => {
                setOpenInvModal(false);
            }} width='500px'
                styles={{ footer: { padding: 0 } }}
                footer={
                    <div>
                        {transactionType === 'BUY' && <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: "1px solid var(--grey2)", padding: '0.5rem 1.5rem' }}>
                                <div className='textXS'>Funds required:
                                    <b> {CurrencyFormat(amount)}</b>
                                </div>
                                <div className='textXS'>Available:
                                    <b> {CurrencyFormat(Number(funds?.totalBalance))}</b></div>
                            </div>
                        </>
                        }
                        <div style={{ display: 'flex', gap: '1rem', padding: '1rem 1.5rem' }}>
                            <button style={{ flex: 1 }} className="btnWhite " onClick={() => setOpenInvModal(false)}>Cancel</button>
                            {
                                transactionType === 'BUY' ?
                                    // for additional inv
                                    <button className="btnPrimary " style={{ flex: 1 }}
                                        onClick={async () => {
                                            console.log(minInv, amount)
                                            if (minInv > Number(amount)) {
                                                toast.error(`Min investment amount is Rs. ${minInv}`);
                                                return;
                                            }
                                            if (Number(amount) > funds?.totalBalance) {
                                                toast.error("Fund Limit Insufficient. Please add funds in your broker account.");
                                                return;
                                            }
                                            setTrxnStatus('loading')

                                            var dataFinal = {
                                                "mintbox_id": PORTFOLIO_ID,
                                                "client_id": user?.id,
                                                "additional_funds": Number(amount),
                                                "rebalance": isRebalanceOnAdditionalInv
                                            }
                                            var res = await AdminServicesPY.genRebalOrder(dataFinal).then(res => res)
                                            if (res?.type && res?.data && res?.data?.length) {
                                                const obj = res?.data.map(k => {
                                                    return {
                                                        "transactionType": transactionType,
                                                        "tradingSymbol": k.symbol,
                                                        "quantity": k.transaction_qty,
                                                    }
                                                })
                                                const order = {
                                                    "clientId": user.id,
                                                    "broker": broker,
                                                    "sourceAppId": 2,
                                                    "basketId": FUNDDATA?.UID,
                                                    "goalId": GOAL_ID,
                                                    "isWealthCreator": WEALTH_CREATOR || 0,
                                                    "portfolioId": PORTFOLIO_ID,
                                                    "instrumentType": INSTRUMENT_TYPE,
                                                    "orders": obj.filter(o => o.quantity),
                                                    "isAMO": isMarketOpen() ? false : true,
                                                }

                                                await createOrder({ order })

                                            } else {
                                                toast.error('Error')
                                                setTrxnStatus('failed')
                                            }
                                        }} disabled={INSTRUMENT_TYPE === 2 || trxnStatus === 'loading'}>
                                        {trxnStatus === 'loading' ? <LoadingOutlined style={{ fontSize: '1.5rem', color: "var(--black)" }} spin /> : 'Place order'}
                                    </button>
                                    :
                                    // for sell
                                    <button className="btnPrimary " style={{ flex: 1, color: 'var(--white)', backgroundColor: 'var(--red)', border: 'none' }}
                                        onClick={async () => {
                                            setTrxnStatus('loading')
                                            if (!isCompleteRedemption && amount === '') {
                                                toast.error('Enter redemption amount!');
                                                return;
                                            }
                                            var dataFinal = {
                                                "mintbox_id": PORTFOLIO_ID,
                                                "client_id": user?.id,
                                                "sell_amount": isCompleteRedemption ? 0 : Number(amount)
                                            }
                                            var res = await AdminServicesPY.genSellOrder(dataFinal).then(res => res)
                                            if (res?.type && res?.data && res?.data?.length) {
                                                const obj = res?.data.map(k => {
                                                    return {
                                                        "transactionType": transactionType,
                                                        "tradingSymbol": k.symbol,
                                                        "quantity": k.shares_to_sell,
                                                    }
                                                })
                                                const order = {
                                                    "clientId": user.id,
                                                    "broker": broker,
                                                    "sourceAppId": 2,
                                                    "basketId": FUNDDATA?.UID,
                                                    "goalId": GOAL_ID,
                                                    "isWealthCreator": WEALTH_CREATOR || 0,
                                                    "portfolioId": PORTFOLIO_ID,
                                                    "instrumentType": INSTRUMENT_TYPE,
                                                    "orders": obj.filter(o => o.quantity),
                                                    "isAMO": isMarketOpen() ? false : true,
                                                    "isReDateSame": isReDateSame,
                                                }
                                                await createOrder({ order })

                                            } else {
                                                toast.error('Error')
                                                setTrxnStatus('failed')
                                            }
                                        }} disabled={INSTRUMENT_TYPE === 2 || trxnStatus === 'loading'}>
                                        {trxnStatus === 'loading' ? <LoadingOutlined style={{ fontSize: '1.5rem', color: "var(--black)" }} spin /> : 'Redeem'}
                                    </button>
                            }
                        </div>
                    </div>
                }>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', minHeight: '400px', gap: '2rem', height: '100%' }}>
                    <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                        <div className='d-flex' style={{ alignItems: 'center', gap: "1rem" }}>
                            <BasketIcon id={FUNDDATA?.UID > 30 ? FUNDDATA?.UID % 30 : FUNDDATA?.UID} height={"3rem"} width={"3rem"}
                                bg='var(--blueShade)' border='1px solid var(--primary)' padding='0.5rem' />
                            <div>
                                <div className="textLG black w-500">{FUNDDATA?.MintboxName}</div>
                                {
                                    transactionType === 'BUY' && <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', flexWrap: 'wrap' }}>
                                        <CustomTag text={`Min investment ${CurrencyFormat(FUNDDATA?.MinLumpsumAmount, 'long', 0)}`}
                                            color='var(--primary)' bgColor='var(--blueShade)' onClick={() => { }} />
                                        <CustomTag text={`${FUNDDATA?.Risk} ${window.innerWidth > 760 ? 'risk' : ''}`}
                                            color={RiskTextColor(Number(FUNDDATA?.RiskLevel))}
                                            bgColor={RiskColor(Number(FUNDDATA?.RiskLevel))}
                                            onClick={() => { }} />
                                    </div>
                                }
                            </div>
                        </div>
                        <Card style={{ border: '1px solid var(--grey1)' }}>
                            {
                                transactionType === 'BUY' ?
                                    <CardHeader heading="Investment amount" style={{ marginBottom: '0.5rem' }} hideHeaderBlueLine />
                                    :
                                    <CardHeader heading="Redemption amount" style={{ marginBottom: '0.5rem' }} hideHeaderBlueLine />
                            }
                            <div style={{ display: 'flex', gap: '1rem' }}>
                                <div style={{ display: 'flex', gap: '0.5rem', flexDirection: 'column' }}>
                                    {/* <div>
                                            <div className='textLG w-500'>Investment amount</div>
                                        </div> */}
                                    {!isCompleteRedemption && <CurrencyInput
                                        className='currencyInputIc rupee'
                                        id="amount"
                                        name="amount"
                                        placeholder="Amount"
                                        value={amount}
                                        allowDecimals={true}
                                        decimalsLimit={2}
                                        allowNegativeValue={false}
                                        disableAbbreviations={false}
                                        autoComplete={false}
                                        prefix=' '
                                        onValueChange={(value) => { setAmount(value) }}
                                        intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                                    />
                                    }
                                    {
                                        transactionType === 'BUY' && <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem', alignItems: 'center' }}>
                                            <div style={{}}>
                                                <div className="textSM w-500">Also rebalance</div>
                                                <div className="textXS dark2">Your portfolio will be rebalanced with new investment</div>
                                            </div>
                                            <div style={{}}>
                                                <BuySellSwitch type={2} value={isRebalanceOnAdditionalInv} onChange={(e) => {
                                                    setIsRebalanceOnAdditionalInv(e.target.checked);
                                                }} />
                                            </div>
                                        </div>
                                    }
                                    {
                                        transactionType === 'SELL' && <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem', alignItems: 'center' }}>
                                            <div style={{}}>
                                                <div className="textSM w-500">Full redemption</div>
                                                <div className="textXS dark2">Sell all stocks in this basket</div>
                                            </div>
                                            <div style={{}}>
                                                <BuySellSwitch type={2} value={isCompleteRedemption} onChange={(e) => {
                                                    setIsCompleteRedemption(e.target.checked);
                                                }} />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>

                                </div>
                            </div>
                        </Card>
                        {
                            transactionType === 'BUY' &&
                            <PortfolioChangeTable orders={orders.map(o => {
                                return {
                                    "fundCode": o.symbol,
                                    "weightY": o.weights_y,
                                    "weightX": o.weights_x,
                                    "numSharesY": o.num_shares_y,
                                    "numSharesX": o.num_shares_x,
                                    "buySellFlag": o.direction,
                                    "rebalanceUnits": o.transaction_qty
                                }
                            })} />
                        }
                        {
                            <Collapse bordered={false} style={{ marginBottom: '1rem', background: 'var(--white)', border: '1px solid var(--grey2)', borderRadius: 'var(--borderRadius)', }} expandIconPosition="end" expandIcon={({ isActive }) => <IoIosArrowDown style={{
                                transition: "all 0.3s linear",
                                transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
                                color: "var(--black)",
                                fontSize: "1rem", alignSelf: 'center', marginTop: '0.5rem'
                            }} />} defaultActiveKey={1} items={[{
                                key: 1,
                                label: <CardHeader heading="Review orders" style={{}} hideHeaderBlueLine />,
                                style: { borderBottom: 'none' },
                                children: <div>
                                    <div className='d-flex' style={{ alignItems: 'center', gap: "0.75rem", padding: '0.5rem', borderBottom: '1px solid var(--grey2)' }}>
                                        <div className="textXS black w-500" style={{ flex: 1 }}>Constituents</div>
                                        <div className="textXS black w-500" style={{ width: '70px', textAlign: 'right' }}>
                                            LTP
                                        </div>
                                        <div className="textXS black w-500" style={{ width: '50px', textAlign: 'right' }}>
                                            Qty.
                                        </div>
                                        <div className="textXS black w-500" style={{ width: '50px', textAlign: 'right' }}>
                                            Order
                                        </div>
                                    </div>
                                    {
                                        ordersLoading ?
                                            [1, 2, 3, 4].map(x => <div className='d-flex' style={{ alignItems: 'center', gap: "0.75rem", padding: '0.5rem', borderBottom: '1px solid var(--grey2)' }}>
                                                <div>
                                                    <Skeleton.Input style={{ width: '100px', height: '16px' }} />
                                                </div>
                                            </div>)
                                            :
                                            <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                                                {
                                                    transactionType === 'BUY' ?
                                                        orders?.map(o => {
                                                            return <div className='d-flex' style={{ alignItems: 'center', gap: "0.75rem", padding: '0.5rem', borderBottom: '1px solid var(--grey2)' }}>
                                                                <div style={{ flex: 1, textOverflow: 'ellipsis' }}>
                                                                    <div className="textXS">{o.symbol}</div>
                                                                </div>
                                                                <div className="textXS" style={{ width: '70px', textAlign: 'right', whiteSpace: 'nowrap' }}>
                                                                    ₹ {o.ltp_x.toFixed(2)}
                                                                </div>
                                                                <div className="textXS" style={{ width: '50px', textAlign: 'right' }}>
                                                                    {o.transaction_qty}
                                                                </div>
                                                                <div className="textXS" style={{ width: '50px', textAlign: 'right' }}>
                                                                    <span style={{ color: 'var(--green)' }}>BUY</span>
                                                                </div>
                                                            </div>
                                                        })
                                                        :
                                                        orders?.map(o => {
                                                            return <div className='d-flex' style={{ alignItems: 'center', gap: "0.75rem", padding: '0.5rem', borderBottom: '1px solid var(--grey2)' }}>
                                                                <div style={{ flex: 1, textOverflow: 'ellipsis' }}>
                                                                    <div className="textXS">{o.symbol}</div>
                                                                </div>
                                                                <div className="textXS" style={{ width: '70px', textAlign: 'right', whiteSpace: 'nowrap' }}>
                                                                    ₹ {o.ltp.toFixed(2)}
                                                                </div>
                                                                <div className="textXS" style={{ width: '50px', textAlign: 'right' }}>
                                                                    {o.shares_to_sell}
                                                                </div>
                                                                <div className="textXS" style={{ width: '50px', textAlign: 'right' }}>
                                                                    <span style={{ color: 'var(--red)' }}>SELL</span>
                                                                </div>
                                                            </div>
                                                        })
                                                }
                                            </div>
                                    }
                                </div>
                            }]} />
                        }
                    </div>
                </div>
                <TransactionStatusOverlay />
            </Drawer >
        }
        // for basket first inv
        else if (IS_BASKET && PORTFOLIO_ID === 0) {
            return <Drawer open={openInvModal} closable={false} maskClosable={true} onClose={() => {
                setOpenInvModal(false);
            }} width='500px'
                styles={{ footer: { padding: 0 } }}
                footer={
                    <div>
                        {transactionType === 'BUY' && <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: "1px solid var(--grey2)", padding: '0.5rem 1.5rem' }}>
                                <div className='textXS'>Funds required:
                                    <b> {CurrencyFormat(amount)}</b>
                                </div>
                                <div className='textXS'>Available:
                                    <b> {CurrencyFormat(Number(funds?.totalBalance))}</b></div>
                            </div>
                        </>
                        }
                        {
                            (!isSip || !isReDateSame) && rebalanceDate && <div className='textXS dark3' style={{ borderBottom: "1px solid var(--grey2)", padding: '0.2rem 1.5rem' }}>
                                <b>Note:</b> First rebalancing will be scheduled on  <b>{rebalanceDate.toDateString()}</b>. <span className="primary" style={{ cursor: 'pointer' }}>What will happen on rebalancing date?</span>
                            </div>
                        }
                        <div style={{ display: 'flex', gap: '1rem', padding: '1rem 1.5rem' }}>
                            <button style={{ flex: 1 }} className="btnWhite " onClick={() => setOpenInvModal(false)}>Cancel</button>
                            <button className="btnPrimary " style={{ flex: 1 }}
                                onClick={async () => {
                                    console.log(minInv, amount)
                                    if (minInv > Number(amount)) {
                                        toast.error(`Min investment amount is Rs. ${minInv}`);
                                        return;
                                    }
                                    if (Number(amount) > funds?.totalBalance) {
                                        toast.error("Fund Limit Insufficient. Please add funds in your broker account.");
                                        return;
                                    }
                                    setTrxnStatus('loading')

                                    var dataFinal = {
                                        "mintbox_id": FUNDDATA?.UID,
                                        "client_id": user?.id,
                                        "total_funds": Number(amount)
                                    }
                                    var res = await AdminServicesPY.genNewBasketOrder(dataFinal).then(res => res)
                                    if (res?.type && res?.data && res?.data?.length) {
                                        const obj = res?.data.map(k => {
                                            return {
                                                "transactionType": transactionType,
                                                "tradingSymbol": k.symbol,
                                                "quantity": k.num_shares,
                                            }
                                        })
                                        const order = {
                                            "clientId": user.id,
                                            "broker": broker,
                                            "sourceAppId": 2,
                                            "basketId": FUNDDATA?.UID,
                                            "goalId": GOAL_ID,
                                            "isWealthCreator": WEALTH_CREATOR || 0,
                                            "portfolioId": PORTFOLIO_ID,
                                            "instrumentType": INSTRUMENT_TYPE,
                                            "orders": obj.filter(o => o.quantity),
                                            "isAMO": isMarketOpen() ? false : true,
                                            "isReDateSame": isReDateSame,
                                            "basketAmount": Number(amount), // sip amount
                                            "isSIPOrder": isSip,
                                            "frequency": isSip ? sipFrequency : "",
                                            "sipDay": isSip ? sipFrequency === "1W" ? sipDay : sipFrequency === "1M" ? String(sipDate) : "" : "",
                                            "sipStartDate": isSip ? formatDateyyyymmdd(getNextSIPDate22(sipDate, sipTypes?.filter((i) => i?.value === sipFrequency)?.[0]?.label?.toLocaleLowerCase(), sipDay)) : ""
                                        }

                                        await createOrder({ order })

                                    } else {
                                        toast.error('Error')
                                        setTrxnStatus('failed')
                                    }
                                }} disabled={INSTRUMENT_TYPE === 2 || trxnStatus === 'loading'}>
                                {trxnStatus === 'loading' ? <LoadingOutlined style={{ fontSize: '1.5rem', color: "var(--black)" }} spin /> : 'Place order'}
                            </button>
                        </div>
                    </div>
                }>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', minHeight: '400px', gap: '2rem', height: '100%' }}>
                    <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                        <div className='d-flex' style={{ alignItems: 'center', gap: "1rem" }}>
                            <BasketIcon id={FUNDDATA?.UID > 30 ? FUNDDATA?.UID % 30 : FUNDDATA?.UID} height={"3rem"} width={"3rem"}
                                bg='var(--blueShade)' border='1px solid var(--primary)' padding='0.5rem' />
                            <div>
                                <div className="textLG black w-500">{FUNDDATA?.MintboxName}</div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', flexWrap: 'wrap' }}>
                                    <CustomTag text={`Min investment ${CurrencyFormat(FUNDDATA?.MinLumpsumAmount, 'long', 0)}`}
                                        color='var(--primary)' bgColor='var(--blueShade)' onClick={() => { }} />
                                    <CustomTag text={`${FUNDDATA?.Risk} ${window.innerWidth > 760 ? 'risk' : ''}`}
                                        color={RiskTextColor(Number(FUNDDATA?.RiskLevel))}
                                        bgColor={RiskColor(Number(FUNDDATA?.RiskLevel))}
                                        onClick={() => { }} />
                                </div>
                            </div>
                        </div>
                        <Card style={{ border: '1px solid var(--grey1)' }}>
                            <CardHeader heading="Investment amount" style={{ marginBottom: '0.5rem' }} hideHeaderBlueLine />
                            <div style={{ display: 'flex', gap: '1rem' }}>
                                <div style={{ display: 'flex', gap: '0.5rem', flexDirection: 'column' }}>
                                    {/* <div>
                                            <div className='textLG w-500'>Investment amount</div>
                                        </div> */}
                                    <CurrencyInput
                                        className='currencyInputIc rupee'
                                        id="amount"
                                        name="amount"
                                        placeholder="Amount"
                                        value={amount}
                                        allowDecimals={true}
                                        decimalsLimit={2}
                                        allowNegativeValue={false}
                                        disableAbbreviations={false}
                                        autoComplete={false}
                                        prefix=' '
                                        onValueChange={(value) => { setAmount(value) }}
                                        intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                                    />
                                </div>
                                <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>

                                </div>
                            </div>
                            {transactionType === 'BUY' && <SipSection showAlsoRebalance={true} />}
                        </Card>
                        <Collapse bordered={false} style={{ marginBottom: '1rem', background: 'var(--white)', border: '1px solid var(--grey2)', borderRadius: 'var(--borderRadius)', }} expandIconPosition="end" expandIcon={({ isActive }) => <IoIosArrowDown style={{
                            transition: "all 0.3s linear",
                            transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
                            color: "var(--black)",
                            fontSize: "1rem", alignSelf: 'center', marginTop: '0.5rem'
                        }} />} items={[{
                            key: 1,
                            label: <CardHeader heading="Review orders" style={{}} hideHeaderBlueLine />,
                            style: { borderBottom: 'none' },
                            children: <div>
                                <div className='d-flex' style={{ alignItems: 'center', gap: "0.75rem", padding: '0.5rem', borderBottom: '1px solid var(--grey2)' }}>
                                    <div className="textXS black w-500" style={{ flex: 1 }}>Constituents</div>
                                    <div className="textXS black w-500" style={{ width: '70px', textAlign: 'right' }}>
                                        LTP
                                    </div>
                                    <div className="textXS black w-500" style={{ width: '50px', textAlign: 'right' }}>
                                        Weight
                                    </div>
                                    <div className="textXS black w-500" style={{ width: '50px', textAlign: 'right' }}>
                                        Qty.
                                    </div>
                                </div>
                                {
                                    ordersLoading ?
                                        [1, 2, 3, 4].map(x => <div className='d-flex' style={{ alignItems: 'center', gap: "0.75rem", padding: '0.5rem', borderBottom: '1px solid var(--grey2)' }}>
                                            <div>
                                                <Skeleton.Input style={{ width: '100px', height: '16px' }} />
                                            </div>
                                        </div>)
                                        :
                                        <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                                            {
                                                orders.map(o => {
                                                    return <div className='d-flex' style={{ alignItems: 'center', gap: "0.75rem", padding: '0.5rem', borderBottom: '1px solid var(--grey2)' }}>
                                                        <div style={{ flex: 1, textOverflow: 'ellipsis' }}>
                                                            <div className="textXS">{o.symbol}</div>
                                                        </div>
                                                        <div className="textXS" style={{ width: '70px', textAlign: 'right', whiteSpace: 'nowrap' }}>
                                                            {o.ltp}
                                                        </div>
                                                        <div className="textXS" style={{ width: '50px', textAlign: 'right' }}>
                                                            {(o.weights * 100).toFixed(1)}%
                                                        </div>
                                                        <div className="textXS" style={{ width: '50px', textAlign: 'right' }}>
                                                            {o.num_shares}
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                }
                            </div>
                        }]} />
                    </div>
                </div>
                <TransactionStatusOverlay />
            </Drawer >
        }

        // for stock and etf DIY
        else return <Drawer open={openInvModal} closable={false} maskClosable={true} onClose={() => {
            setOpenInvModal(false);
        }} width='500px'
            styles={{ footer: { padding: 0 } }}
            footer={
                <div>
                    {transactionType === 'BUY' && <>
                        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: "1px solid var(--grey2)", padding: '0.25rem 1.5rem' }}>
                            <div className='textXS'>Funds required:
                                <b> {CurrencyFormat(Number(quantity) * Number(price))}</b></div>
                            <div className='textXS'>Available:
                                <b> {CurrencyFormat(Number(funds?.totalBalance))}</b></div>
                        </div>
                    </>
                    }
                    {
                        transactionType === 'SELL' && <>
                            {
                                <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: "1px solid var(--grey2)", padding: '0.25rem 1.5rem' }}>
                                    {
                                        totalStockQuantity === 0 ?
                                            <div className='textXS'><b>Note: </b> Your portfolio does not have any shares of {INSTRUMENT_TYPE === 1 ? FUNDDATA?.nse_symbol : FUNDDATA?.symbol} to SELL</div>
                                            :
                                            totalStockQuantity > totalStockQuantityDiy ?
                                                <div className='textXS'><b>Note: </b> Your portfolio has <b> {totalStockQuantity}</b> shares out of which <b>{totalStockQuantityDiy}</b> shares are available to sell. If you want to sell the rest of your {INSTRUMENT_TYPE === 1 ? FUNDDATA?.nse_symbol : FUNDDATA?.symbol} shares then visit baskets/goals section.</div>
                                                :
                                                <div className='textXS'>Available quantity: <b> {totalStockQuantityDiy}</b> shares</div>
                                    }
                                </div>
                            }
                        </>
                    }
                    <div style={{ display: 'flex', gap: '1rem', padding: '1rem 1.5rem' }}>
                        <button style={{ flex: 1 }} className="btnWhite " onClick={() => {
                            setOpenInvModal(false);
                        }}>Cancel</button>

                        <button className="btnPrimary "
                            style={{ flex: 1, color: 'var(--white)', border: 'none', backgroundColor: transactionType === 'BUY' ? 'var(--green)' : 'var(--red)' }}
                            onClick={async () => {
                                if (transactionType === 'BUY' && (funds?.totalBalance < Number(price) * quantity)) {
                                    toast.error("Fund Limit Insufficient. Please add funds in your broker account.");
                                    return;
                                }
                                if (transactionType === 'SELL' && (totalStockQuantityDiy < Number(quantity))) {
                                    toast.error("You don't have enough units to sell");
                                    return;
                                }
                                setTrxnStatus('loading')
                                const order = {
                                    "clientId": user.id,
                                    "broker": broker,
                                    "sourceAppId": 2,
                                    "basketId": 0,
                                    "goalId": 0,
                                    "isWealthCreator": 0,
                                    "portfolioId": 0,
                                    "instrumentType": INSTRUMENT_TYPE,
                                    "isAMO": isMarketOpen() ? false : true,
                                    "isSIPOrder": isSip,
                                    "frequency": isSip ? sipFrequency : "",
                                    "sipDay": isSip ? sipFrequency === "1W" ? sipDay : sipFrequency === "1M" ? String(sipDate) : "" : "",
                                    "sipStartDate": isSip ? formatDateyyyymmdd(getNextSIPDate22(sipDate, sipTypes?.filter((i) => i?.value === sipFrequency)?.[0]?.label?.toLocaleLowerCase(), sipDay)) : "",
                                    "basketAmount": Number(amount), // sip amount
                                    "orderType": isMarket ? 'MARKET' : 'LIMIT',
                                    "orders": [{
                                        "transactionType": transactionType,
                                        "tradingSymbol": INSTRUMENT_TYPE === 1 ? FUNDDATA?.nse_symbol : FUNDDATA?.symbol,
                                        "quantity": quantity,
                                        "price": Number(price),
                                    }]
                                }
                                await createOrder({ order })

                            }} disabled={INSTRUMENT_TYPE === 2 || trxnStatus === "loading"}>
                            {trxnStatus === 'loading' ? <LoadingOutlined style={{ fontSize: '1.5rem', color: "var(--white)" }} spin /> : transactionType}
                        </button>
                    </div>
                </div>
            }>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', minHeight: '400px', gap: '2rem', height: '100%' }}>
                <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <AMCIcon amcName={INSTRUMENT_TYPE === 4 ? FUNDDATA?.symbol : FUNDDATA?.amc_full_name} bseTicker={FUNDDATA?.bse_ticker}
                            height='3rem' width='3rem' type={'square'} fundType={INSTRUMENT_TYPE} />
                        <div>
                            <div className="textLG black w-500">{INSTRUMENT_TYPE === 4 ? FUNDDATA?.proper_name :
                                FUNDDATA?.basic_name?.replace(" - Direct Plan", "")}</div>
                            <div className={"textSM dark3 w-700"} style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                {FUNDDATA?.symbol}
                                <a href={`https://www.nseindia.com/get-quotes/equity?symbol=${FUNDDATA?.symbol}`} target='_blank'><Tag color='var(--green)'>NSE</Tag></a>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between', alignItems: 'center' }}>

                        {INSTRUMENT_TYPE === 1 ? <StockLivePrice fundData={FUNDDATA} format={9} symbol={FUNDDATA?.nse_symbol} /> : INSTRUMENT_TYPE == 4 ?
                            <StockLivePrice fundData={FUNDDATA} format={9} symbol={FUNDDATA?.symbol} /> : INSTRUMENT_TYPE === 2 ?
                                <div className="textMD w-500 black">{CurrencyFormat(FUNDDATA?.latest_nav, "long")}</div> :
                                <></>
                        }
                        <div>
                            <BuySellSwitch onChange={(e) => {
                                if (e.target.checked) {
                                    setTransactionType('SELL')
                                } else {
                                    setTransactionType('BUY')
                                }
                            }} value={transactionType === 'SELL' ? true : false} />
                        </div>
                    </div>
                    <Card style={{ border: '1px solid var(--grey1)', marginBottom: '1rem', }}>
                        <CardHeader heading="Order details" style={{ marginBottom: '0.5rem' }} hideHeaderBlueLine />
                        <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
                            <div style={{ flex: 1, display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                                <div className='textSM w-500'>Quantity</div>
                                <CurrencyInput
                                    className='currencyInputIc'
                                    id="quantity"
                                    name="quantity"
                                    placeholder="Number of shares"
                                    value={quantity || ''}
                                    allowDecimals={false}
                                    allowNegativeValue={false}
                                    disableAbbreviations={false}
                                    autoComplete={false}
                                    prefix=' '
                                    onValueChange={(value) => { setQuantity(Number(value)) }}
                                    intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                                    style={{ maxWidth: '150px' }}
                                />
                            </div>
                            <div style={{ flex: 1, display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                                <div className='textSM w-500'>Price</div>
                                <div>
                                    <CurrencyInput
                                        className='currencyInputIc'
                                        id="price"
                                        name="price"
                                        placeholder={isMarket ? Number(getLTP({ fundData: FUNDDATA })) : 'Price'}
                                        disabled={isMarket}
                                        value={isMarket ? '' : price}
                                        allowDecimals={true}
                                        decimalsLimit={2}
                                        decimalScale={2}
                                        allowNegativeValue={false}
                                        disableAbbreviations={false}
                                        autoComplete={false}
                                        prefix=' '
                                        onValueChange={(value) => { setPrice(value) }}
                                        intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                                        style={{ maxWidth: '150px' }}
                                    />
                                    <Radio.Group name="radiogroup" value={isMarket} onChange={onRadioChange} style={{ display: 'flex', gap: '8px', alignItems: 'center', marginTop: '0.5rem' }} >
                                        <Radio value={true}>Market</Radio>
                                        <Radio value={false}>Limit</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                        </div>
                        {transactionType === 'BUY' && <SipSection />}
                    </Card>
                </div>
            </div >
            <TransactionStatusOverlay />
        </Drawer >
    }

    return <>
        {
            loading ?
                <div style={{ position: 'fixed', top: 0, left: 0, backgroundColor: 'rgba(0,0,0,0.5)', height: '100vh', width: '100vw', zIndex: 1000 }}>
                    <div style={{
                        margin: '20% auto', width: '400px', textAlign: 'center', height: '40%'
                    }}>
                        <BrokerIcon brokerName={broker} height="100px" width="100px" style={{ margin: 'auto' }} />
                        <div className="loading loading01 mt-4">
                            <span>C</span>
                            <span>o</span>
                            <span>n</span>
                            <span>n</span>
                            <span>e</span>
                            <span>c</span>
                            <span>t</span>
                            <span>i</span>
                            <span>n</span>
                            <span>g</span>
                            <span>&nbsp;</span>
                            <span>t</span>
                            <span>o</span>
                            <span>&nbsp;</span>
                            {[...broker].map(c => {
                                return <span>{c}</span>
                            })}
                        </div>
                    </div>
                </div>
                :
                <></>
        }
        {
            connectBroker ?
                <button className={"btnWhite w100"} style={{ height: "fit-content" }} onClick={onInvestClick}>
                    Connect Broker
                </button>
                :
                !noCta ? ctaType === "button" ? <button className={btnClassName} onClick={onInvestClick} disabled={CTA_DISABLED}>
                    {btnText}
                </button> : <span className={btnClassName} onClick={CTA_DISABLED ? () => { } : () => onInvestClick()}>
                    {btnText}
                </span> : null
        }
        <PausedModal />
        {LoginModal()}
        {SignupModal()}
        {openInvModal && InvModal()}
        {contextHolder}
        <Modal open={openCannotInvModal} onOk={() => setOpenCannotInvModal(false)}
            onCancel={() => setOpenCannotInvModal(false)} title='Investing not allowed'
            footer={<div>
                <button className="btnWhite ms-auto "
                    onClick={() => setOpenCannotInvModal(false)}>Close</button>
            </div>}>
            <div>
                {cannotInvestContent}
            </div>
        </Modal>
        {/* for upstox and zerodha*/}
        {openInvModal ?
            broker === 'upstox' ? <form method="post" id={`uplink-${IS_MODIFY ? 'modify' : IS_CANCEL ? 'cancel' : 'place'}`} action={`https://api-v2.upstox.com/uplink/order/${IS_MODIFY ? 'modify' : IS_CANCEL ? 'cancel' : 'place'}`}>
                <input type="hidden" name="access_token" value={''} id="upstox_access_token" />
                <input type="hidden" name="redirect_url" value={window.location.href} />
                <input type="hidden" name={`${(IS_MODIFY || IS_CANCEL) ? 'order_id' : 'data'}`} value="" id="order_data" />
                {(!IS_CANCEL && !IS_MODIFY) ? <input type="hidden" name="is_editable" value="false" /> : <></>}
            </form>
                :
                // broker === "zerodha" && !user?.Client?.[0]?.ZerodhaAPIKey ? <>
                //     <form method="post" id="basket-form" action="https://kite.zerodha.com/connect/basket" >
                //         <input type="hidden" name="api_key" value={`${ZerodhaSharpelyApiKey}`} />
                //         <input type="hidden" id="basket" name="data" value="" />
                //     </form>
                // </>
                //     :
                <></>
            :
            <></>}

    </>
}

export default SharpelyGateway2;