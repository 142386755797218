import { useIsMobile } from "CustomHooks/useIsMobile";
import { toggleloginModal, updateCustomColumns } from 'Libs/redux/UserAuth/reduxSlice';
import { displayValue, etf_normalPaidCols, generateUniqueKey, magicBoardCols, makeid, mergeArrayOfObj, metricsToHideForCustomization, mf_normalPaidCols, normalTableCols, screenerMetricData, screenerQueryBuilder, sortFunds, stock_PaidCols, stock_PaidCols_new, unique, useGetAssetType } from "Libs/utils";
import * as XLSX from 'Libs/xlsx';
import AdminServicesPY from "Services/api/AdminServicesPY";
import apiHelper from "Services/api/ApiHelper";
import { StockLivePrice } from "Services/api/LivePricesWebSocket";
import { Dropdown as AntDropdown, Card, Cascader, Checkbox, Divider, Drawer, Input, Segmented, Select, Skeleton, Tooltip } from "antd";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import moment from 'moment';
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import * as ReactDOM from "react-dom";
import { AiFillFileExcel, AiOutlineClose, AiOutlineDownload, AiOutlinePlus } from 'react-icons/ai';
import { BiCustomize } from "react-icons/bi";
import { CgAdd } from "react-icons/cg";
import { FaFileCsv } from 'react-icons/fa';
import { HiLockClosed } from "react-icons/hi";
import { IoIosArrowBack, IoIosArrowDown, IoIosArrowForward, IoIosClose, IoIosEye, IoIosEyeOff } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { MdBubbleChart, MdCancel, MdCompareArrows, MdDragIndicator, MdFormatListBulleted, MdOutlineDeleteOutline, MdOutlineLock, MdSettings } from "react-icons/md";
import { TbHelicopterLanding } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { v4 } from "uuid";
import AMCIcon from "./AMCIcon";
import AccessComponent from "./AccessComponent";
import CustomSegmentedTab from "./CustomSegmentedTab";
import { changeObjKey } from "./DataCustomizer";
import IndexOrSectorsTreeChart from "./IndexOrSectorsTreeChart";
import LoadingTableSkeleton from "./LoadingTableSkeleton";
import { MetricSearch } from "./MetricSearch";
import { NoDataFound } from "./NoDataFound";
import StyleBoxIcons from "./StyleBoxIcons";
import { PlanName, SubscribeIcon, SubscribeModalText, useSubscribeMiddleware } from "./SubscribeModal";
import MyTable from "./TableWithScroll";
import { CardHeader } from "./atoms/SmallComponents";
import { CurrencyFormat } from "./CurrencyFormat";
require('highcharts/highcharts-more')(Highcharts)

const InstrumentListTable = ({ istableLoading = undefined, showRebalanceBtn = false, onRebalancePressed = () => { }, finalList, tableType, tableHeader, tableHeaderComponent, hideHeaderBlueLine = false, initialColumns, magicBoardtype = "etf", sortColKey = '', sortOrder = 'top', otherCol = [],
    paginationSize = 200, isCard = true, investInScreener = () => { }, onChangeExcludeStocksSwitch = () => { }, onPeersBtnClick = () => { }, defExcludeStocks = true, defFilters,
    showSearch = false, hideCheckBox = false, showBubbleChartBtn = false, hideInvestBtn = false, hideSettings = false, disableInvestBtn = false, showCategoryDropDown = false,
    peersFilters = [], fundData = {}, isEditable = 1, hidePaddingInMob, lastCols = [], magicCatId = ['Equity', '100'],
    defaultInsType, rightText = '', onRightTextClick = () => { }, downloadTable = false, fileDefaultName = 'table data',
    downloadFileAccessKey = '', allowInitSort = true, isWidget = false, widgetItem = {}, showDeleteButton = false, onDeleteBtnClick = () => { }, deleteBtnTooltipText = '', noPreviousPrice = false,
    isScrollingTable = true, style = {}, showToolsBtnsInTab = false, showHeatmapBtn = false, deleteBtnComp = null, noDataMessage, showSymbolInMob = true,
    tableRowHeight, showCurrentMarketPrice = false
}) => {
    // //////console.log(initialColumns);
    // tableType
    // 1 => screener , 
    // 0 => normal without filters icon but filters applied if passed , 
    // 2 => with filters, 
    // 3 => signal,
    // 5 => compare
    // 6 => alpha
    // 7 => stylebox
    // 8 => etf/mf peers
    // 9 => stock peers
    //10 => other cols
    // 11 => for new screener
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isMobile = useIsMobile();
    let type = useGetAssetType(defaultInsType);
    sortColKey = sortColKey || (type.value === 4 ? "mcap" : "aum")
    const [customFinalList, setCustomFinalList] = useState(finalList);
    const [displayData, setDisplayData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [tableUniqueId] = React.useState('insTable' + v4());
    const [appliedFilters, setAppliedFilters] = useState({})
    const [modal, setmodal] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(paginationSize);
    const [pageData, setPageData] = useState({ page: 1, pageSize: paginationSize })
    const [magic_category_id, setmagic_category_id] = useState(magicCatId)
    const [magic_category_ids, setmagic_category_ids] = useState([magicCatId])
    const [magic_benchType, setmagic_benchType] = useState(['NIFTY 50 Total Return Index'])
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
    const [defFil, setDefFil] = React.useState(defFilters || []);
    const [showBubbleChart, setShowBubbleChart] = useState(false);
    const [bubbleChartType, setbubbleChartType] = useState(1);
    const [userBasedCols, setuserBasedCols] = useState([]);
    const [screenerMetric, setscreenerMetric] = useState([])
    const [screenerFinalData, setscreenerFinalData] = useState([])
    const [allFundsData, setallFundsData] = useState([])
    const [selectedCategory, setselectedCategory] = useState(isMobile ? "" : "")
    const [selectedMetric, setselectedMetric] = useState("")
    const [loading, setloading] = useState(true)
    const [excludeSurveillance, setexcludeSurveillance] = useState(defExcludeStocks)
    const [tableLoading, setTableLoading] = useState(true)
    const [contentLoading, setcontentLoading] = useState(false)
    // const [user, setUser] = useState(undefined)
    const [styleBoxes, setStyleBoxes] = React.useState([]);
    const [selectedStyleBox, setselectedStyleBox] = React.useState(2);
    const [mfCategories, setmfCategories] = useState([])
    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    const user = useSelector((state) => state?.updateUserData?.value);
    const userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel || 0
    const userCustomCols = useSelector((state) => state?.userCustomCols?.userCustomCols);
    const [showUserCustomCols, setShowUserCustomCols] = React.useState(false);
    const subscribeMiddle = useSubscribeMiddleware();
    // const userCustomCols = useSelector((state) => state?.userCustomCols)
    // //////console.log(userCustomCols?.stocks)
    // console.log('table rerendered');

    const metric_data = (key, value) => {
        return screenerMetric.filter((met) => met[key] === value)[0]
    }

    React.useEffect(() => {

        if (istableLoading !== undefined) {
            // alert(istableLoading)
            setTableLoading(istableLoading)
        }
    }, [istableLoading])

    const sortOptions = [
        {
            value: 'category',
            queryStr: 'category',
            label: 'Category',
        },
        {
            value: 'benchmark',
            queryStr: 'benchmark',
            label: 'Benchmark',
        }
    ]

    const stockPeersOptions = [
        {
            value: 'nse_basic_ind_code',
            queryStr: 'nse_basic_ind_code',
            label: 'Industry',
        },
        {
            value: 'industry_code',
            queryStr: 'industry_code',
            label: 'Industry Group',
        },
        {
            value: 'sector_code',
            queryStr: 'sector_code',
            label: 'Sector',
        }
    ]

    const [magicboardFilterType, setmagicboardFilterType] = useState(String(tableHeader).includes("ETF") ? sortOptions[1].value : sortOptions[0].value)
    const [stockPeersType, setstockPeersType] = useState(stockPeersOptions[0].value)

    const [finalDataCols, setFinalDataCols] = useState([]);
    const [selectedCols, setSelectedCols] = useState([]);
    const [userCustomColumns, setUserCustomColumns] = React.useState({});

    const [dataTableColumns, setDataTableColumns] = useState(type.value === 4 ? [
        {
            "title": "Name",
            'disableDeleteInCustomize': true,
            "dataIndex": "proper_name",
            render: (text, record, index) => <Tooltip title={record?.proper_name} arrow={false}>
                <Link to={type.basePath + `${type.value === 4 ? '/stock-detail/' + record?.proper_name?.replaceAll(' ', '-') + '/' + record?.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + record?.plan_id}`} target='_blank'>
                    <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center" }}>
                        <AMCIcon amcName={record?.symbol} fundType={type.value} height='1.15rem' width="1.15rem" bseTicker={record?.bse_ticker} />
                        <div>
                            <div className="w-500 black" style={{ width: isMobile ? 100 : 200, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: isMobile ? "var(--textEXS)" : "" }}>
                                {isMobile && showSymbolInMob ? record?.symbol : record?.proper_name}
                            </div>
                            {/* <div className="text-left textXS ">{stockSectorCLF?.find(s => s.industry_code === record?.industry_code)?.industry_desc}</div> */}
                            <StockLivePrice fundData={record} symbol={record?.symbol} format={10} />
                        </div>
                    </div>
                </Link>
            </Tooltip>,
            width: isMobile ? 100 : 200,
            fixed: window.innerWidth > 760 ? 'left' : "",
            show: true
        }, {
            "title": "Market capitalization",
            "dataIndex": "mcap",
            'disableDeleteInCustomize': true,
            "key": "mcap",
            "metric_col_code": "mcap",
            "align": 'right',
            "metric_unit": "rupee_cr",
            "metric_data_type": "float",
            "metric_name": "Market capitalization",
            "title": "Market capitalization",
            render: (text, record, index) => <span style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "flex-end" }}>
                <span>
                    {displayValue({
                        text, metricObj: {
                            "title": "Market capitalization",
                            "dataIndex": "mcap",
                            "key": "mcap",
                            "metric_col_code": "mcap",
                            "align": 'right',
                            "metric_unit": "rupee_cr",
                            "metric_data_type": "float",
                            "metric_name": "Market capitalization",
                            "title": "Market capitalization",
                        }
                    })}
                </span>
            </span>,
            width: 160,

        },
        showCurrentMarketPrice && {
            "metric_name": "Current Market Price",
            "metric_col_code": "price",
            'disableDeleteInCustomize': true,
            "metric_type": 2,
            "metric_data_type": "float",
            "metric_unit": "rupee",
            "title": "Current Market Price",
            "label": "Current Market Price",
            "dataIndex": "price",
            "key": "price",
            "align": "right",
            "width": 130,
            render: (text) => {
                return CurrencyFormat(text)
            }
        }
    ]
        :
        [
            {
                "title": "Name",
                'disableDeleteInCustomize': true,
                "dataIndex": "basic_name",
                render: (text, record, index) => <Tooltip title={record?.basic_name?.replace(" - Direct Plan", "")} arrow={false}>
                    <Link to={type.basePath + `${type.value === 4 ? '/stock-detail/' + record?.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + record?.plan_id}`} target='_blank'>
                        <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center", }}>
                            <AMCIcon amcName={record?.amc_full_name} height='1.15rem' width="1.15rem" />
                            <div>
                                <div className="w-500 black" style={{ width: isMobile ? 100 : 200, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: isMobile ? "var(--textEXS)" : "" }}>
                                    {isMobile && showSymbolInMob ? (type?.value === 2 ? record?.isin_code : record?.nse_symbol) : record?.basic_name?.replace(" - Direct Plan", "")}
                                </div>
                                {/* <div className="text-left textXS ">{record?.category_name}</div> */}
                            </div>
                        </div>
                    </Link>
                </Tooltip>,
                width: isMobile ? 100 : 200,
                fixed: window.innerWidth > 760 ? 'left' : "",
                show: true
            }, {
                "title": "Asset under management",
                "dataIndex": "aum",
                "key": "aum",
                'disableDeleteInCustomize': true,
                "metric_col_code": "aum",
                "metric_unit": "rupee_cr",
                "align": 'right',
                "metric_data_type": "float",
                "metric_name": "Asset under management",
                "title": "Asset under management",
                render: (text, record, index) => <span style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "flex-end" }}>
                    <span>
                        {displayValue({
                            text, metricObj: {
                                "title": "Asset under management",
                                "dataIndex": "aum",
                                "key": "aum",
                                "metric_col_code": "aum",
                                "metric_unit": "rupee_cr",
                                "align": 'right',
                                "metric_data_type": "float",
                                "metric_name": "Asset under management",
                                "title": "Asset under management",
                            }
                        })}
                    </span>
                </span>,
                width: 160,

            }
        ]);


    const loadData = async () => {
        var screener_datas = await screenerMetricData(type, 0, 1)
        setselectedCategory(isMobile ? "" : screener_datas?.levelVals?.m_category_id)
        setStyleBoxes(screener_datas?.fabricatedData?.stockStyles)
        setselectedMetric(isMobile ? "" : screener_datas?.levelVals?.m_metric_col_code)
        setscreenerMetric(screener_datas?.screenerMetricData)
        setmfCategories(screener_datas?.fabricatedData?.mf_categories)
        setscreenerFinalData(screener_datas?.screenerFinalData)
        setallFundsData(screener_datas?.allFunds)
        if (user?.isLogged) {
            if (userCustomCols === undefined) {
                let customCols = await apiHelper({ apiName: "getCustomColumns", data: user?.id, saveResponse: "sessionStorage" });
                let cols = null;
                if (customCols) {
                    cols = customCols?.find((el) => el?.InstrumentType === type?.strVal?.toUpperCase());
                    setUserCustomColumns(cols);
                }
                dispatch(updateCustomColumns(customCols))
            }
            else {
                let cols = userCustomCols?.find((el) => el?.InstrumentType === type?.strVal?.toUpperCase());
                setUserCustomColumns(cols);
            }
        }
        else {
            setUserCustomColumns({});
        }
        setloading(false)
    }

    React.useEffect(() => {
        loadData()
    }, [])

    React.useEffect(() => {
        if (finalList !== undefined) {
            setCustomFinalList(finalList)
        }
    }, [finalList])

    React.useEffect(() => {
        setDefFil(defFilters || [])
    }, [defFilters])

    const filterFundList = async () => {

        if (istableLoading === undefined && (tableType !== 11 || tableType !== 12 || tableType !== 1)) {
            setTableLoading(true)
        }
        var fundList = []
        var userBasedFilteredCols = []
        if (tableType === 1) {
            fundList = customFinalList
        } else {
            if (customFinalList !== undefined && customFinalList?.length >= 0) {
                //////console.log('customFinalList')
                fundList = customFinalList
            } else {
                //////console.log('allFundsData')
                fundList = allFundsData
            }
        }
        ////console.log("12222", tableType, type, fundList?.length)
        if (type)
            if (searchText !== "") {
                fundList = fundList.filter((item) => {
                    return item[type.value === 4 ? 'proper_name' : 'basic_name'].toLowerCase().includes(searchText.toLowerCase());
                });
            }

        var listf = defFilters || []
        if (showCategoryDropDown) {
            if (magicboardFilterType === "category") {
                let temp = [];
                magic_category_ids?.forEach((id) => {
                    let g = fundList?.filter((el) => {
                        if (id?.[1]) {
                            return String(id[1]) === String(el?.category_id)
                        }
                        else {
                            return id[0] === el?.primary_category_name
                        }
                    })
                    temp = [...temp, ...g]
                })

                fundList = temp;
            } else {
                fundList = fundList?.filter((el) => {
                    return magic_benchType?.includes(String(el?.benchmark))
                })
            }

        } else {
            userBasedFilteredCols = normalTableCols[type.strVal]["paid"]
        }
        if (tableType === 9) {
            //stock peers
            listf = listf.concat(peersFilters.filter((i) => i[0] === stockPeersType))

        }

        if (tableType === 8) {
            //etf or mf peers
            if (magicboardFilterType === "category") {
                listf = listf.concat([peersFilters[0]])
                userBasedFilteredCols = magicBoardCols[magicBoardtype][magic_category_id[0]]["paid"]
                // //////console.log(userBasedFilteredCols, magic_category_id)
                if (Number(magic_category_id[1]) === 118) {
                    //international
                    // ////////console.log("adf")
                    userBasedFilteredCols = magicBoardCols[magicBoardtype][magic_category_id[0]]["free"]
                }
            } else {
                listf = listf.concat([peersFilters[1]])
            }

        }
        //////console.log(fundList?.filter((el) => el?.symbol === 'HDFC'), fundList)
        if (listf?.length > 0 && type?.value !== 4) {
            listf?.forEach(item => {
                fundList = fundList.filter((fund) => {
                    return String(fund[String(item[0])]) === String(item[1])
                })
            });
        }

        // if (tableType === 7) {
        //     //stylebox
        //     fundList = fundList.filter((i) => Number(i.style_box_code) === Number(selectedStyleBox))
        // }



        // //////console.log(...screenerMetric.filter((item) => (type?.value === 1 ? userCustomCols?.etfs : type?.value === 2 ? userCustomCols?.mfs : type?.value === 4 ? userCustomCols?.stocks : [])?.includes(item?.metric_col_code)))
        //////console.log(userBasedFilteredCols)
        let customCols = userCustomColumns?.Columns?.split('|')?.filter((el) => el) || []
        let typeCols = type?.value === 4 ? (tableType === 11 || tableType === 12 ? stock_PaidCols_new : stock_PaidCols) : type?.value === 2 ? mf_normalPaidCols : etf_normalPaidCols
        var allCols = ((tableType === 6 || tableType === 10 || tableType === 3) ? [...dataTableColumns?.filter((el) => el), ...otherCol,] : tableType === 11 ? [
            ...dataTableColumns?.filter((el) => el),
            ...otherCol,
            ...(screenerMetric.filter((item) => [...(!showUserCustomCols ? typeCols : customCols)]?.includes(item?.metric_col_code)))
        ] : tableType === 12 ? [
            ...dataTableColumns?.filter((el) => el)?.filter((i) => i?.dataIndex === "proper_name" || i?.dataIndex === "basic_name"),
            ...otherCol,
            ...dataTableColumns?.filter((el) => el)?.filter((i) => i?.dataIndex !== "proper_name" && i?.dataIndex !== "basic_name"),
            ...(screenerMetric.filter((item) => [...(!showUserCustomCols ? typeCols : customCols)]?.includes(item?.metric_col_code)))
        ] : [
            ...dataTableColumns?.filter((el) => el),
            ...otherCol,
            ...screenerMetric.filter((item) => initialColumns?.includes(item?.metric_col_code)),
            ...screenerMetric.filter((item) => customCols?.includes(item?.metric_col_code)),
            ...(userBasedFilteredCols.length > 0 ? userBasedFilteredCols?.map((item) => {
                return {
                    ...metric_data('dataIndex', item),
                    disableDeleteInCustomize: true,
                }
            }) : screenerMetric?.filter((i) => i?.isInitial === true))
        ]?.map((i) => { return i?.dataIndex === "proper_name" || i?.dataIndex === "basic_name" || i?.dataIndex === "mcap" || i?.dataIndex === "aum" || i?.dataIndex === 'price' ? { ...i, disableDeleteInCustomize: true } : { ...i } }))

        // var reduxData = type?.value === 1 ? userCustomCols?.etfs : type?.value === 2 ? userCustomCols?.mfs : type?.value === 4 ? userCustomCols?.stocks : []
        // //////console.log(reduxData,type)

        // var sessionCols = screenerMetric.filter((item) => (reduxData)?.includes(item?.metric_col_code))

        // alert(allCols?.length)

        if ((tableType === 9 || (tableType === 0 && (finalList === undefined))) && type?.value === 4) {
            //stock peers
            // listf = listf.concat(peersFilters?.filter((i) => i[0] === stockPeersType))
            //////console.log(listf)
            fundList = await screenerQueryBuilder(listf,
                unique([...allCols], 'dataIndex')?.map((i) => i?.metric_col_code)?.filter(n => n), false)
            // console.log(fundList);
            // fundList = mergeArrayOfObj(allFundsData, fundList, "symbol")
            fundList = fundList?.map((i) => {
                return {
                    ...allFundsData?.filter((ii) => ii?.symbol === i?.symbol)?.[0],
                    ...i
                }
            })

            // console.log(fundList)
            //////console.log(fundList?.filter((ele) => ele?.symbol === 'HDFC'));
        }

        if (tableType === 7) {
            //stylebox
            fundList = await screenerQueryBuilder([
                ["style_box_code", selectedStyleBox]
            ],
                unique([...allCols], 'dataIndex')?.map((i) => i?.metric_col_code)?.filter(n => n), false)

            // fundList = mergeArrayOfObj(allFundsData, fundList, "symbol")
            fundList = fundList?.map((i) => {
                return {
                    ...allFundsData?.filter((ii) => ii?.symbol === i?.symbol)?.[0],
                    ...i
                }
            })
        }


        if ((tableType === 5) && type?.value === 4 && fundList?.length > 0) {
            //compare
            //////console.log(initialColumns)
            var filFunds = await AdminServicesPY.getStocksColData({
                stocks: fundList?.map((i) => { return i?.symbol }),
                cols: tableType === 3 ? [] : unique([...allCols], 'dataIndex')?.map((i) => i?.metric_col_code)?.filter(n => n)
            }).then((r) => r)

            filFunds = filFunds?.data?.length > 0 ? filFunds?.data : []
            //////console.log("FDSFD", filFunds, fundList)

            // fundList = JSON.parse(filFunds)?.map((i) => {
            //     return {
            //         // ...allFundsData?.filter((ii) => ii?.symbol === i?.symbol)?.[0],
            //         ...fundList?.filter((ii) => ii?.symbol === i?.symbol)?.[0],
            //         ...i,
            //     }
            // })
            // fundList = fundList?.map((i) => {
            //     return {
            //         ...JSON.parse(filFunds)?.filter((ii) => ii?.symbol === i?.symbol)?.[0],
            //         ...i
            //     }
            // })
            fundList = mergeArrayOfObj(JSON.parse(filFunds), fundList, "symbol")
        }

        if (tableType === 11 || tableType === 12) {
            if (type?.value === 4) {
                setcontentLoading(true)
                var listd = displayData
                if ((fundList?.length !== displayData?.length) || displayData?.length === 0)
                    listd = fundList
                try {
                    var remCols = listd?.length > 0 ? showUserCustomCols ? [...customCols, ...otherCol?.map((i) => i?.dataIndex)]?.filter((i) => !(Object.keys(listd?.[0])?.includes(i))) : stock_PaidCols_new?.filter((i) => !(Object.keys(listd?.[0])?.includes(i))) : []

                    if (remCols?.length > 0) {
                        var filFunds = await AdminServicesPY.getStocksColData({
                            stocks: listd?.map((i) => { return i?.symbol }),
                            cols: remCols
                        }).then((r) => r)
                        filFunds = filFunds?.data?.length > 0 ? filFunds?.data : []
                        // ////console.log(new Date().getTime())
                        fundList = mergeArrayOfObj(JSON.parse(filFunds), listd, "symbol")
                        // ////console.log(new Date().getTime())
                        // fundList = listd?.map((i) => {
                        //     return {
                        //         ...JSON.parse(filFunds)?.filter((ii) => ii?.symbol === i?.symbol)?.[0],
                        //         ...i
                        //     }
                        // })
                    } else {
                        fundList = listd
                    }
                } catch (error) {
                    fundList = listd
                }
            } else {
                var listd = displayData
                if ((fundList?.length !== displayData?.length) || displayData?.length === 0)
                    listd = fundList

                fundList = listd
            }


        }

        if (fundList !== undefined) {
            setDisplayData(fundList);
            // console.log('allcols', fundList)
            let uniqueAllCols = unique([...allCols,], 'dataIndex');
            if (uniqueAllCols.map((ele) => ele?.dataIndex)?.sort()?.join('') === selectedCols?.map((ele) => ele?.dataIndex)?.sort()?.join('')) {
                uniqueAllCols = selectedCols?.map((ele, i) => {
                    let ite = uniqueAllCols?.find((el) => el?.dataIndex === ele?.dataIndex);
                    return ite;

                })
                uniqueAllCols = uniqueAllCols?.filter((el) => el)
            }
            setFinalDataCols(uniqueAllCols)
            setSelectedCols(uniqueAllCols)
            if (istableLoading === undefined) {
                setTableLoading(false)
            }
        }
        setcontentLoading(false)
    }

    React.useEffect(() => {
        if (!loading && type) {
            setcontentLoading(true)
            filterFundList()
        }
    }, [loading, searchText, customFinalList, defFil, magic_category_id, magic_category_ids, magic_benchType, magicboardFilterType, selectedStyleBox, stockPeersType, userCustomColumns, showUserCustomCols])
    // }, [loading, searchText, customFinalList, defFil, magic_category_id, magic_benchType, magicboardFilterType, selectedStyleBox, stockPeersType, userCustomCols])

    const portal = document.createElement("div");
    document.body.appendChild(portal);

    var chartKeys = bubbleChartType === 1 ? ['sh_qual_comp', 'sh_val_comp', "proper_name"] : bubbleChartType === 2 ? ['sh_mom_comp', 'sh_val_comp', "proper_name"] : ['sh_mom_comp', 'sh_qual_comp', "proper_name"]
    var chartAxisNames = bubbleChartType === 1 ? ['sharpely Value Score', 'sharpely Quality Score'] : bubbleChartType === 2 ? ['sharpely Value Score', 'sharpely Momentum Score'] : ['sharpely Quality Score', 'sharpely Momentum Score']

    function getColumnLetter(index) {
        if (typeof index === 'string') {
            index = parseInt(index, 10);
        }

        if (isNaN(index) || index < 1) {
            return null; // Invalid input
        }

        let columnLetter = '';
        while (index > 0) {
            const remainder = (index - 1) % 26;
            columnLetter = String.fromCharCode(65 + remainder) + columnLetter;
            index = Math.floor((index - 1) / 26);
        }

        return columnLetter;
    }

    function getInnerText(jsxObject) {
        // console.log(jsxObject)
        try {
            if (!jsxObject) return '';
            if (jsxObject.props && jsxObject.props.children) {
                if (Array.isArray(jsxObject.props.children)) {
                    return jsxObject.props.children.map(child => getInnerText(child)).join(' ');
                } else {
                    return getInnerText(jsxObject.props.children);
                }
            }
            if (jsxObject.props && jsxObject.props.children === undefined && jsxObject.props.textContent) {
                return jsxObject.props.textContent || '';
            }
            if (typeof (jsxObject.type) === 'object') {
                if (typeof (jsxObject.type.type) === 'function') {
                    return getInnerText(jsxObject.type.type(jsxObject.props))
                }
            }
            if (typeof (jsxObject) === 'string') return jsxObject
            return '';
        } catch (error) {
            console.log(error);
            return '';
        }
    }

    const exportTable = () => {

        subscribeMiddle({
            reqAccessLevel: 1,
            moduleKey: downloadFileAccessKey,
            onNext: () => {
                const dataSource = tableType === 10 ? finalList : sortFunds(displayData, sortColKey, sortOrder, tableType === 9, fundData, type.value)
                let columns = [...finalDataCols, ...lastCols]?.filter((i) => i.hidden !== true && !['bse_segment', 'nse_segment', 'exclusion_list', 'is_exclusion_list']?.includes(i?.dataIndex));
                columns.splice(1, 0, { title: type.value === 4 ? "Symbol" : type.value === 1 ? "NSE Symbol" : "ISIN CODE", dataIndex: type.value === 4 ? "symbol" : type.value === 1 ? "nse_symbol" : "isin_code", });
                columns.splice(2, 0, { title: "Exchange", dataIndex: 'NSE', render: () => 'NSE' });
                if (type?.value === 4) {
                    columns.splice(3, 0, {
                        "metric_name": "Current Market Price",
                        "metric_col_code": "price",
                        'disableDeleteInCustomize': true,
                        "metric_type": 2,
                        "metric_data_type": "float",
                        "metric_unit": "rupee",
                        "title": "Current Market Price",
                        "label": "Current Market Price",
                        "dataIndex": "price",
                        "key": "price",
                        "align": "right",
                        "width": 130,
                        render: (text) => {
                            return String(Number(text).toFixed(2))
                        }
                    })
                }
                columns.push({ title: 'Symbol with Comma for External Upload', dataIndex: type.value === 4 ? "symbol" : type.value === 1 ? "nse_symbol" : "isin_code", render: (text) => text + ',' })
                columns = columns?.map((col) => {
                    let title = col?.title;
                    if (col?.metric_unit === "rupee_cr") {
                        title = title + ' (in Rs. Cr.)'
                    }
                    return {
                        ...col,
                        title
                    }
                })
                const headers = columns.map((col) => col.title);
                let wsData = [headers];
                dataSource?.forEach((rowData, k) => {
                    const row = [];
                    columns.forEach((col) => {
                        let header = col?.dataIndex
                        const cellData = rowData[header];
                        let data = cellData;
                        if (col?.metric_unit === "rupee_cr") {
                            data = String(Number(data).toFixed(2));
                        }
                        else if (col?.renderForTable) {
                            let target = col.renderForTable(cellData, rowData, userAccesslevel);
                            if (target) {
                                data = getInnerText(target);
                            }
                        }
                        else if (col?.render) {
                            let target = col.render(cellData, rowData);
                            if (target) {
                                data = getInnerText(target);
                            }
                        }
                        else {
                            data = cellData;
                        }
                        data = data?.replaceAll('\n', '');
                        data = data?.replaceAll('₹', 'Rs');
                        row.push(data);
                    })
                    wsData.push(row)
                })
                const ws = XLSX.utils.aoa_to_sheet(wsData);
                // console.log(ws);
                const fileName = fileDefaultName + ' ' + moment(new Date()).format('DD-MMM-YYYY')
                const csvBuffer = XLSX.writeSync(ws, { bookType: 'csv', type: 'string' });
                const blob = new Blob([csvBuffer], { type: 'text/csv' });
                // return;
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${fileName}.csv`;
                document.body.appendChild(a); // Append anchor to body
                a.click(); // Trigger the download
                document.body.removeChild(a); // Remove anchor from body once downloaded
                URL.revokeObjectURL(url);
            }
        })
    };

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            if (selectedRowKeys.length > ((tableType === 1 || tableType === 3) ? (type.value === 4 ? 50 : 10) : 25)) {
                return
            }
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record?.name === 'Disabled User', // Column configuration not to be checked
        }),
        hideSelectAll: true,
    };

    const getChartFunds = (pageNum, pageSize) => {
        var dataSource = tableLoading ? [] : sortFunds(displayData, sortColKey, sortOrder)
        return [...dataSource].slice(pageSize * (pageNum - 1), pageSize * pageNum)
    }

    const onOrderChange = (items) => {
        //////console.log(items);
        setSelectedCols(items);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    function PortalAwareItem({ provided, snapshot, item, index }) {
        const usePortal = snapshot.isDragging;
        const child = (
            <div
                className="mb-1"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <MdDragIndicator size={25} color="var(--dark3)" />

                    <span className='textXS dark3 mx-3' style={{ flex: 1 }}>{item.title}</span>
                    {item.disableDeleteInCustomize ? <HiLockClosed className='textXS dark3' size={15} onClick={() => { }} /> :
                        <MdCancel style={{ cursor: "pointer" }} className='textXS dark3' size={15} onClick={() => {
                            var a = [...selectedCols]
                            a.splice(index, 1)
                            setSelectedCols(a)
                        }} />}
                </div>
            </div>
        );

        if (!usePortal) {
            return child;
        }

        // if dragging - put the item in a portal
        return ReactDOM.createPortal(child, portal);
    }

    function VerticalList({ items, onChange }) {
        const onDragEnd = (result) => {
            if (!result.destination) return;
            onChange(reorder(items, result.source.index, result.destination.index));
        };
        return (
            <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem" }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="vertical-list">
                        {(droppableProvided, droppableSnapshot) => (
                            <div
                                ref={droppableProvided.innerRef}
                                {...droppableProvided.droppableProps}
                            >
                                {items.map((item, index) => {
                                    let isDisabled = item?.dataIndex === "proper_name" || item?.dataIndex === "basic_name" || item?.dataIndex === "mcap" || item?.dataIndex === "aum" || initialColumns?.includes(item?.dataIndex) || otherCol?.filter((el) => el?.dataIndex === item?.dataIndex)?.length === 1
                                    if (isDisabled) {
                                        return null

                                    }
                                    else {
                                        return <Draggable key={generateUniqueKey(item.dataIndex)} draggableId={index + 'metric'} index={index}>
                                            {(draggableProvided, draggableSnapshot) => (
                                                <PortalAwareItem
                                                    item={item}
                                                    index={index} //removing name and aum/mcap
                                                    provided={draggableProvided}
                                                    snapshot={draggableSnapshot}
                                                />
                                            )}
                                        </Draggable>
                                    }
                                })}
                                {droppableProvided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div >
        );
    }

    const DrawerMetricDescComp = () => {
        return <div style={{ padding: "1.25rem", display: "flex", flexDirection: "column", gap: "0.5rem", backgroundColor: "var(--blue2)", flex: 1 }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <span className='textSM black w-700'>{selectedm?.metric_name}</span>
            </div>
            <span style={{ minHeight: "18rem", maxHeight: "18rem", overflowY: "scroll" }}>
                {selectedm?.metric_desc}
            </span>
        </div>
    }

    const AppliedFilterView = () => {
        var nonEditable = [...selectedCols].filter((i) => {
            let c1 = i.disableDeleteInCustomize;
            let c2 = initialColumns?.includes(i?.metric_col_code);
            let c3 = otherCol?.filter((el) => el?.metric_col_code === i?.metric_col_code)?.length === 1;
            return c1 || c2 || c3;
        });

        return <div style={{
            display: "flex", flex: 1, flexDirection: "column", gap: "1.2rem", padding: '1.25rem', flex: 1
        }}>
            <span>
                {
                    <div className="textSM" style={{ color: 'var(--primary)', backgroundColor: 'var(--blueShade)', padding: "0.875rem 0.75rem", borderRadius: "4px", alignItems: "center", gap: "0.5rem" }}>
                        <MdOutlineLock className='textXS primary me-2' size={15} onClick={() => { }} />{`${nonEditable[0]?.title} and ${nonEditable[1]?.title}`}
                    </div>
                }
            </span>
            <div style={{ overflowY: 'scroll', maxHeight: '13rem' }}>
                <VerticalList items={selectedCols} onChange={onOrderChange} />
            </div>
        </div >
    }

    const renderRow = ({ item, index, lastIndex }) => {
        return <span key={generateUniqueKey(item.category_id)} style={{
            padding: isMobile ? "1rem 0" : "0.75rem 1.25rem",
            borderRadius: isMobile ? "0" : "4px 0px 0px 4px",
            display: "flex",
            alignItems: "center",
            backgroundColor: isMobile ? "var(--white)" : item.category_id === selectedCategory ? "var(--blueShade)" : "transparent", cursor: "pointer",
            borderBottom: isMobile && lastIndex !== index ? "1px solid var(--grey2)" : "none"
        }}
            onClick={() => {
                setselectedCategory(item.category_id)
                setselectedMetric("")
            }}
        >
            <span style={{ flex: 1, color: item.category_id === selectedCategory ? "var(--primary)" : "var(--dark3)" }} className="textSM w-500">{item.category_name}</span>
            <IoIosArrowForward color={item.category_id === selectedCategory ? "var(--primary)" : "var(--dark3)"} />
        </span>
    };

    const onMetricSelect = (metric) => {
        setselectedCategory(metric.category_id)
        let check = selectedCols?.find((el) => el?.metric_col_code === metric?.metric_col_code);
        if (check?.metric_col_code) {
            let temp = [...selectedCols];
            temp = temp?.filter((el) => el?.metric_col_code !== metric?.metric_col_code);
            setSelectedCols(temp);
        }
        else {
            setSelectedCols([...selectedCols, metric]);
        }
        setselectedMetric(metric.metric_col_code)
    }

    const RenderIntialFlatlist = ({ item, index }) => {
        return item?.sub_category.filter((ele) => ele.isVisibleNormally !== false).map((subCategory, subCategoryIndex) => {
            if (subCategory.isVisibleForCustomise) {
                return <div key={generateUniqueKey("asd" + subCategoryIndex)} style={{ borderRadius: 2, marginBottom: "2rem" }}>
                    <span className="textSM black w-500"> {subCategory.sub_category_name}</span>
                    <span style={{ display: "flex", flexDirection: "column", gap: isMobile ? "0.75rem" : "0.5rem", marginTop: "0.625rem" }}>
                        {subCategory.metric.map((metric, metricIndex) => {
                            var upDatedFilters = [...selectedCols]
                            var presentIndex = upDatedFilters.findIndex((met) => met.dataIndex === metric.metric_col_code)
                            var isPresentIndex = presentIndex !== -1
                            var isDisabled = metric?.dataIndex === "proper_name" || metric?.dataIndex === "basic_name" || metric?.dataIndex === "mcap" || metric?.dataIndex === "aum" || initialColumns?.includes(metric?.dataIndex) || otherCol?.filter((el) => el?.dataIndex === metric?.dataIndex)?.length === 1
                            if (metric.hidden !== true && metric.isVisibleNormally !== false && !(metricsToHideForCustomization.includes(metric.metric_col_code))) {
                                return <AccessComponent key={generateUniqueKey(metricIndex)}
                                    reqAccessLevel={metric.auth_flag}
                                    upgradeComponent={
                                        <div key={metric.metric_col_code} style={{ cursor: "pointer", color: "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem" }} className={`${isMobile ? "textSM" : 'textXS'}`}>
                                            {!isMobile && <Checkbox disabled={true} />}
                                            <span onClick={() => {
                                                setselectedMetric(metric.metric_col_code)
                                            }}>{metric.metric_name}</span>
                                            <SubscribeIcon accessKey={type.value === 4 ? "STOCK_PROPRIETARY_METRICS" : "MF_ETF_PROPRIETARY_METRICS"} />
                                        </div>
                                    }
                                    component={<div key={metric.metric_col_code} style={{
                                        cursor: "pointer", color: "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem",
                                        justifyContent: isMobile ? "space-between" : "start"
                                    }} className={`${isMobile ? "textSM" : 'textXS'}`}
                                        onClick={() => { if (isMobile) setselectedMetric(metric.metric_col_code) }}>
                                        {!isMobile && <Checkbox
                                            disabled={isDisabled}
                                            checked={isPresentIndex === true}
                                            onChange={(e) => {
                                                e.preventDefault()
                                                if (!isPresentIndex) {
                                                    upDatedFilters.push({ ...metric })
                                                    setSelectedCols([...upDatedFilters])
                                                } else {
                                                    upDatedFilters.splice(presentIndex, 1)
                                                    setSelectedCols([...upDatedFilters])
                                                }

                                                setselectedMetric(metric.metric_col_code)

                                                // var reduxData = type?.value === 1 ? userCustomCols?.etfs : type?.value === 2 ? userCustomCols?.mfs : type?.value === 4 ? userCustomCols?.stocks : []
                                                // // //////console.log(reduxData)
                                                // reduxData = [...reduxData, metric.metric_col_code]
                                                // if (type?.value === 1) {
                                                //     dispatch(updateEtfCustomCols(reduxData))
                                                // } else if (type?.value === 2) {
                                                //     dispatch(updateMfCustomCols(reduxData))
                                                // } else if (type?.value === 4) {
                                                //     dispatch(updateStockCustomCols(reduxData))
                                                // }

                                            }}
                                        />}
                                        <span onClick={() => {
                                            setselectedMetric(metric.metric_col_code)
                                        }}>{metric.metric_name}</span>
                                        {isMobile && <IoIosArrowForward color={"var(--dark3)"} />}
                                    </div>} />
                            } else {
                                return null
                            }
                        })}
                    </span>
                </div>

            } else {
                return null
            }
        })
    }

    const BubbleChart = () => {

        var options = {
            chart: {
                type: 'bubble',
                height: "85%",
                plotBorderWidth: 0,
                plotBorderBottomWidth: 0,
                plotBorderColor: "#ccd6eb"
            },

            legend: {
                enabled: false
            },

            title: {
                text: ''
            },

            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            xAxis: {
                tickLength: 0,

                // labels: { enabled: false },
                // gridLineWidth: 0.5,
                title: {
                    text: chartAxisNames[0]
                },
                accessibility: {
                    rangeDescription: 'Range: 0 to 100.'
                }
            },

            yAxis: {
                // visible: false,
                tickLength: 0,
                lineWidth: 1,

                // labels: { enabled: false },
                gridLineWidth: 0,
                startOnTick: false,
                endOnTick: false,
                title: {
                    text: chartAxisNames[1]
                },
                maxPadding: 0.2,

                accessibility: {
                    rangeDescription: 'Range: 0 to 100.'
                }
            },

            tooltip: {
                useHTML: true,
                headerFormat: '<table>',
                pointFormat: '<tr><th colspan="2"><h6>{point.name}</h6></th></tr>' +
                    '<tr><th>sharpely Value Score:</th><td>{point.x}</td></tr>' +
                    '<tr><th>sharpely Quality Score:</th><td>{point.y}</td></tr>',
                footerFormat: '</table>',
                followPointer: true
            },

            plotOptions: {
                bubble: {
                    minSize: 40,
                    maxSize: 45
                }
            },

            series: [{
                data: changeObjKey(getChartFunds(pageData.page, pageData.pageSize), chartKeys, ["y", "x", "name"], "normal").map((item) => {
                    return {
                        ...item,
                        color: item.y < 33.33 && item.x < 33.33 ? "#ef4221" : item.y > 66.66 && item.x > 66.66 ? "#58975e" : "#FFB70077"
                    }
                }),
            }]
        };
        return <HighchartsReact highcharts={Highcharts} options={options} />
    }

    var selectedm = screenerMetric.filter((i) => i.metric_col_code === selectedMetric)[0]

    const MetricSelectView = ({ currentMetricData }) => {

        if (currentMetricData?.metric_col_code === "benchmark") {
            var checkAllData = Array.from(new Set(allFundsData.map(item => item[currentMetricData.metric_col_code])));
            checkAllData = checkAllData.map((i, index) => {
                var x = {}
                x["value"] = i
                x["label"] = i
                return x
            })
            checkAllData = checkAllData.filter((x) => x?.label !== null && x?.label !== "" && x?.label !== undefined)
            return <Select
                className='textSM black'
                showSearch
                mode="multiple"
                maxTagCount='responsive'
                optionFilterProp="children"
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                suffixIcon={<IoIosArrowDown size={15} />}
                dropdownStyle={{ minWidth: "max-content" }}
                value={currentMetricData.value}
                style={{
                    width: "15rem",
                    marginTop: 0
                }}
                placeholder={"Please select a " + currentMetricData.metric_name}
                onChange={(value) => {
                    setmagic_benchType(value)
                }}
                options={checkAllData}
                dropdownRender={(menu) => {
                    let isFull = magic_benchType?.length === checkAllData?.length;
                    return (
                        <div style={{ width: "15rem" }}>
                            <div style={{ padding: "0.5rem 0.75rem", cursor: "pointer", gap: "0.75rem" }} className={`textESM w-500 dark3 antdOption d-flex align-items-center justify-content-between ${isFull ? 'antOptionselected' : ''}`} onClick={() => {
                                if (isFull) {
                                    setmagic_benchType([]);
                                }
                                else {
                                    setmagic_benchType(checkAllData?.map((el) => el?.value))
                                }
                            }}>
                                <div className="textEllipsis1">Select all</div>
                                {isFull && <span className="ant-select-item-option-state" unselectable="on" aria-hidden="true" style={{ userSelect: "none" }}>
                                    <span role="img" aria-label="check" className="anticon anticon-check">
                                        <svg viewBox="64 64 896 896" focusable="false" data-icon="check" width="1em" height="1em" fill="var(--primary)" aria-hidden="true">
                                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path>
                                        </svg>
                                    </span>
                                </span>}
                            </div>
                            <div>
                                {menu}
                            </div>
                        </div>
                    )
                }}
            />
        } else {
            return <Cascader
                multiple
                maxTagCount="responsive"
                showSearch
                allowClear={false}
                options={JSON.parse(JSON.stringify(mfCategories)).map((i, index) => {
                    i["value"] = i.categoryName
                    i["label"] = i.categoryName
                    i["title"] = i.categoryName
                    if (i["subCategories"] !== undefined) {
                        i["subCategories"]?.map((ii, indexi) => {
                            ii["value"] = String(ii.categoryId)
                            ii["label"] = ii.subCategoryName
                            return ii
                        })
                        i["children"] = i["subCategories"]
                    }
                    return i
                })}
                style={{
                    width: "15rem",
                }}
                expandTrigger='hover'
                placeholder={"Please select " + type.omkar + " category"}
                defaultValue={currentMetricData.value}
                onChange={(value) => {
                    console.log(value)
                    setmagic_category_ids(value)
                }}
            />
        }


    }

    const [settingsMenu, setSettingsMenu] = React.useState(false)

    const cancelChanges = () => {
        setmodal(!modal);
        setSelectedCols(finalDataCols);
    }

    const applyChanges = async () => {
        setmodal(!modal)
        //////console.log(selectedCols);
        setFinalDataCols(selectedCols);
        let columns = selectedCols?.map((el) => el?.metric_col_code) || [];
        if (initialColumns?.length > 0) {
            columns = columns?.filter((el) => !initialColumns?.includes(el)) || [];
        }
        if (otherCol?.length > 0) {
            columns = columns?.filter((el) => {
                let check = otherCol?.find((ele) => ele?.metric_col_code === el);
                if (check) return false;
                else return true;
            }) || [];
        }
        if (dataTableColumns?.filter((el) => el)?.length > 0) {
            columns = columns?.filter((el) => {
                let check = dataTableColumns?.filter((el) => el)?.find((ele) => ele?.metric_col_code === el);
                if (check) return false;
                else return true;
            }) || [];
        }
        let snapshot = userCustomColumns?.Snapshot?.split('|')?.filter((el) => el) || []
        let d = {
            snapshot, columns, instrument_type: type.strVal?.toUpperCase(), client_id: user?.id, uid: userCustomColumns?.UID || 0
        }
        let res = await apiHelper({ apiName: "saveCustomColumns", data: JSON.stringify(d) });
        //////console.log(res);
        if (res === 'success') {
            let customCols = await apiHelper({ apiName: "getCustomColumns", data: user?.id, saveResponse: "sessionStorage", getFresh: true });
            let cols = null;
            if (customCols) {
                cols = customCols?.find((el) => el?.InstrumentType === type?.strVal?.toUpperCase());
                setUserCustomColumns(cols);
                //////console.log(cols);
                dispatch(updateCustomColumns(customCols))
            }
        }
    }

    const Drawerfooter = () => {

        if (!isMobile) return null;
        return (
            <div className='d-flex align-items-center' style={{ gap: "1rem" }} >
                <button className='btnWhite w100 flex-grow-1 ' onClick={() => { cancelChanges() }}>Cancel</button>
                <button className='btnBlack w100 flex-grow-1 ' onClick={() => { applyChanges() }}>Apply changes</button>
            </div>
        )
    }

    const DrawerTitle = () => {

        if (!isMobile) return null;
        return (
            <div className='d-flex align-items-center' style={{ gap: "1rem" }} >
                {!selectedCategory ? <AiOutlineClose onClick={() => { cancelChanges() }} /> : <IoIosArrowBack onClick={() => {
                    if (selectedCategory && !selectedMetric) {
                        setselectedCategory('');
                    }
                    else if (selectedCategory && selectedMetric) {
                        setselectedMetric('');
                    }
                }} />}
                <div>
                    {!selectedCategory ? "Select category" : !selectedMetric ? "Select metric" : "Customize metrics"}
                </div>
            </div>
        )
    }

    const MetricInfoView = () => {

        var presentIndex = selectedCols?.findIndex((met) => met?.dataIndex === selectedm?.metric_col_code)

        var nonEditable = [...selectedCols].filter((i) => {
            let c1 = i.disableDeleteInCustomize;
            let c2 = initialColumns?.includes(i?.metric_col_code);
            let c3 = otherCol?.filter((el) => el?.metric_col_code === i?.metric_col_code)?.length === 1;
            return c1 || c2 || c3;
        });
        const isDisabled = nonEditable?.includes(selectedm?.metric_col_code);
        // //console.log(disabledCols)
        return (
            <div style={{ flex: 1, display: "flex", flexDirection: "column", overflowY: "auto" }}>
                <div className='textSM w-700'>{selectedm?.metric_name}</div>
                <div style={{ border: "1px solid var(--grey2)", padding: "1rem", borderRadius: "var(--borderRadius)", marginTop: "0.75rem", height: "25vh", overflowY: "auto" }}
                    className='metricDescDrawer'>
                    {selectedm?.metric_desc}
                </div>
                <Divider style={{ margin: "1rem 0" }} />
                <AccessComponent component={
                    <button className="btnBlack " onClick={() => {
                        onMetricSelect(selectedm);
                    }} style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "center" }} disabled={isDisabled}>{(presentIndex !== -1 || isDisabled) ? <>
                        <AiOutlineClose />
                        Remove this metric
                    </> : <>
                        <AiOutlinePlus /> Use this metric
                    </>}
                    </button>} reqAccessLevel={selectedm?.auth_flag} upgradeComponent={<div>
                        Upgrade to sharpely <PlanName accessLevel={selectedm?.auth_flag} /> plan to use this metric. <Link to='/pricing' target='_blank'>See pricing</Link>
                    </div>} />
                <Divider style={{ margin: "1rem 0" }} />
                <div style={{ flex: 1, display: "flex", flexDirection: "column", overflowY: "auto" }}>
                    <div className='textSM w-700'>Filters</div>
                    <div style={{ flex: 1, overflowY: "auto", display: "flex" }}>
                        <AppliedFilterView />
                    </div>
                </div>
            </div>
        )
    }


    let locale = {
        emptyText: 'Abc',
    };

    const [selectedToolFromTab, setSelectedToolFromTab] = React.useState('list');

    let toolsBtnTabItems = [
        {
            key: 0, icon: <Tooltip arrow={false} title={type?.dname + " list"}>
                <MdFormatListBulleted size={15} />
            </Tooltip>, value: "list", onClick: () => { setSelectedToolFromTab('list') },
        },
        {
            key: 1, icon: <Tooltip arrow={false} title='Factor map'><MdBubbleChart size={15} /></Tooltip>, value: "factormap", onClick: () => { setShowBubbleChart(true) },
            show: Boolean((tableType === 1 || tableType === 3 || showBubbleChartBtn) && type.value === 4)
        },
        {
            key: 'PeersAnalysis', icon: <Tooltip arrow={false} title='Peers Analysis'><MdCompareArrows size={'1rem'} /></Tooltip>, value: "peersAnalysis", onClick: () => onPeersBtnClick({
                'funds': tableLoading ? [] : sortFunds(displayData, sortColKey, sortOrder),
                'columns': finalDataCols.filter((i) => i.hidden !== true && !['bse_segment', 'nse_segment', 'exclusion_list', 'is_exclusion_list']?.includes(i?.dataIndex)),
                'selectedTab': tableType === 8 ? magicboardFilterType :
                    stockPeersOptions?.filter((ele) => ele?.value === stockPeersType)?.[0] || {}
            }),
            show: Boolean((tableType === 8 || tableType === 9) && displayData?.length > 1)
        },
        {
            key: 'h', icon: <Tooltip title='Heatmap' arrow={false}><TbHelicopterLanding size={15} /></Tooltip>, value: "heatmap",
            onClick: () => { setSelectedToolFromTab('heatmap') }, show: showHeatmapBtn
        },
        {
            key: 2, icon: <Tooltip arrow={false} title={`Export ${type?.dname} list`}><AiOutlineDownload size={15} /></Tooltip>, value: "download", onClick: () => { exportTable() },
            show: Boolean(downloadTable)
        },
        {
            key: 3, icon: <Tooltip arrow={false} title='Customize columns'><BiCustomize size={15} /></Tooltip>, value: "customize", onClick: () => {
                if (userAccesslevel === 0) {
                    dispatch(toggleloginModal(true))
                } else {
                    setSettingsMenu(false)
                    setmodal(true)
                }
            }, show: (tableType === 11 || tableType === 12) ? false : Boolean(!hideSettings)
        }
    ]
    toolsBtnTabItems = toolsBtnTabItems?.filter((el) => el?.show !== false);
    //console.log("heyeApr", [...finalDataCols, ...lastCols])

    if (tableLoading) {
        return (
            <Card bodyStyle={{ position: "relative", padding: isCard ? '1.25rem' : '0', }} className={`mobCardBorderRadius0 ${hidePaddingInMob ? "hideCardPadInMob" : ""}`}>
                {isWidget && <div>
                    <CardHeader heading={widgetItem?.Name} headingType={2} />
                    <div className='customTabsSubHeading' style={{ marginBottom: 0 }}>{widgetItem?.Description}</div>
                </div>}
                <LoadingTableSkeleton />
            </Card>
        )
    } else {
        return (
            <Card bodyStyle={{ position: "relative", padding: isCard ? '1.25rem' : '0', }} style={{ background: "" }}
                className={`mobCardBorderRadius0 ${hidePaddingInMob ? "hideCardPadInMob" : ""}`}>
                <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem" }}>
                    {(isWidget && tableType !== 7) && <div>
                        <CardHeader heading={widgetItem?.Name} headingType={2} />
                        <div className='customTabsSubHeading' style={{ marginBottom: 0 }}>{widgetItem?.Description}</div>
                    </div>}
                    <div style={{ display: "flex", flexDirection: "row", gap: "1rem", alignItems: "center", flexWrap: 'wrap' }} >
                        {tableHeaderComponent ? tableHeaderComponent : tableHeader && <CardHeader style={{ whiteSpace: 'nowrap' }} hideHeaderBlueLine={hideHeaderBlueLine} heading={tableHeader} headingType={2} />}
                        {tableType === 7 && <CardHeader style={{}} heading={isWidget ?
                            // styleBoxes?.filter((box) => box.style_box_code === selectedStyleBox)[0]?.style_box_name 
                            <div>
                                <CardHeader heading={widgetItem?.Name} headingType={2} />
                                <div className='customTabsSubHeading w-400' style={{ marginBottom: 0 }}>{widgetItem?.Description}</div>
                            </div>
                            :
                            `${displayData.length} stocks under ${styleBoxes?.filter((box) => box.style_box_code === selectedStyleBox)[0]?.style_box_name} StyleBox`}
                            headingType={2} />}
                        {tableType === 8 && <CardHeader style={{ whiteSpace: 'nowrap' }} heading={`Funds ${magicboardFilterType === 'category' ? "in " +
                            fundData?.category_name : "tracking " + fundData?.benchmark}`} headingType={2} />}
                        {tableType === 9 && <CardHeader style={{ whiteSpace: 'nowrap' }} heading={`${stockPeersOptions.filter((i) => i.value === stockPeersType)[0].label} peers`}
                            headingType={2} />}
                        {showSearch && <Input prefix={<IoSearch color='var(--dark2)' className="site-form-item-icon me-2" />} style={{
                            width: "16rem", background: "var(--grey3)",
                            border: "1px solid var(--grey2)", fontSize: '0.85rem'
                        }} placeholder={"Search by " + type.omkar + " name"} allowClear onChange={(value) => {
                            setSearchText(value.target.value)
                        }} className='customInput2' />}
                        {type.value !== 4 && showCategoryDropDown && <span style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                            <span className="textXS">Filter by:</span>
                            <Segmented
                                style={{ border: "1px solid var(--grey3)" }}
                                options={sortOptions}
                                onChange={(value) => {
                                    setmagicboardFilterType(value)
                                }}
                                value={magicboardFilterType}
                            />
                        </span>}

                        {tableType == 8 && <span>
                            <Segmented
                                style={{ border: "1px solid var(--grey3)" }}
                                options={sortOptions}
                                onChange={(value) => {
                                    setmagicboardFilterType(value)
                                }}
                                value={magicboardFilterType}
                            />
                        </span>}


                        {tableType == 9 && <Segmented
                            style={{ border: "1px solid var(--grey3)" }}
                            options={stockPeersOptions}
                            onChange={(value) => {
                                setstockPeersType(value)
                            }}
                            value={stockPeersType}
                        />}



                        {type.value !== 4 && showCategoryDropDown &&
                            MetricSelectView({
                                currentMetricData: {
                                    metric_name: magicboardFilterType,
                                    metric_col_code: magicboardFilterType === "benchmark" ? "benchmark" : "primary_category_name",
                                    value: magicboardFilterType === "benchmark" ? magic_benchType : magic_category_ids
                                }
                            })
                        }



                        {tableType === 7 && isWidget ? <Select value={selectedStyleBox} style={{ width: "10rem" }} options={styleBoxes?.map((ele) => {
                            return {
                                key: ele?.style_box_code,
                                value: ele?.style_box_code,
                                label: ele?.style_box_name
                            }
                        })} onChange={(e) => setselectedStyleBox(e)} /> : tableType === 7 ? <AntDropdown placement="bottomLeft" menu={{
                            items: styleBoxes?.map((box) => {
                                return {
                                    key: String(box.style_box_code),
                                    onClick: () => {
                                        setselectedStyleBox(box.style_box_code)
                                    },
                                    icon: <StyleBoxIcons style_box_code={box.style_box_code - 1} />,
                                    label: <span key={box.style_box_code}>
                                        <span className='ms-2 textSM w-500 dark4'>{box.style_box_name}
                                            <span className='ms-2 textXS w-400 dark2'>{box.style_box_desc}</span></span>
                                    </span>
                                }
                            }),
                            selectable: true,
                            defaultSelectedKeys: [String(selectedStyleBox)],
                        }}>
                            <button className={"btnWhite"} onClick={(e) => { e.preventDefault() }}>
                                <CgAdd size={18} /> StyleBox
                            </button>
                        </AntDropdown> : null}

                        {!(tableType === 1 || tableType === 3 || hideCheckBox) && <button className="btnBlack" disabled={selectedRows.length < 2} onClick={() => navigate(type.basePath + '/compare', { state: { "stocks": selectedRows } })}>Compare</button>}

                        {type.value === 4 && ((!hideInvestBtn) && (tableType === 1 || tableType === 3)) && <AccessComponent
                            component={<div>
                                <Tooltip title={<span className='textXS w-500'>You need a minimum of {type.value === 4 ? "5" : "2"} {type.dname} to build a basket</span>} arrow={false}>
                                    <span>
                                        <button className="btnBlack" disabled={disableInvestBtn} onClick={tableType === 1 || tableType === 3 ? () => { investInScreener(selectedRows) } : () => setmodal(true)}>{"Build a basket"}</button>
                                    </span>
                                </Tooltip>
                            </div>} upgradeComponent={<div>
                                {/* <Tooltip title={<span className='textXS w-500'>You need a minimum of {type.value === 4 ? "5" : "2"} {type.dname} to build a basket</span>} arrow={false}> */}
                                <span>
                                    <button className="btnWhite" disabled={disableInvestBtn} onClick={tableType === 1 || tableType === 3 ? (e) => { e.preventDefault() } : (e) => { e.preventDefault() }}><SubscribeModalText str={'Build a basket'} accessKey='DYNAMIC_BASKET_CREATE' /></button>
                                </span>
                                {/* </Tooltip> */}
                            </div>}
                            reqAccessLevel={accessLevelData.find((ele) => ele.ModuleKey === "DYNAMIC_BASKET_CREATE")?.AccessLevel} />}

                        {showRebalanceBtn ? <button className="btnBlack" onClick={() => {
                            onRebalancePressed()
                        }}>{showRebalanceBtn || "Rebalance"}</button> : null}
                        {showToolsBtnsInTab ? <>
                            <CustomSegmentedTab style={{ padding: "0.25rem" }} options={toolsBtnTabItems} value={selectedToolFromTab} onChange={(e) => {
                                let t = toolsBtnTabItems?.find((el) => el?.value === e);
                                if (t?.onClick) {
                                    t.onClick();
                                }
                            }} />
                            {showDeleteButton && <>
                                <Divider style={{ height: "1.5rem", margin: 0 }} type="vertical" />
                                {deleteBtnComp ? deleteBtnComp :
                                    <Tooltip title={deleteBtnTooltipText} arrow={false}>
                                        <button className='btnWhite' onClick={() => onDeleteBtnClick()}>
                                            <MdOutlineDeleteOutline size='1rem' color='var(--red)' />
                                        </button>
                                    </Tooltip>}
                            </>}
                        </> : <>
                            {(tableType === 1 || tableType === 3 || showBubbleChartBtn) && type.value === 4 && <div>
                                <Tooltip title={<span className='textXS w-500'>Factor map</span>} arrow={false}>
                                    <span>
                                        <button className={showBubbleChart ? "btnBlack" : " btnWhite"} onClick={() => setShowBubbleChart(!showBubbleChart)}>
                                            <MdBubbleChart size={20} />
                                        </button>
                                    </span>
                                </Tooltip>
                            </div>}

                            {(tableType === 8 || tableType === 9) && displayData?.length > 1 && <button className={"btnWhite"} onClick={() => onPeersBtnClick({
                                'funds': tableLoading ? [] : sortFunds(displayData, sortColKey, sortOrder),
                                'columns': finalDataCols.filter((i) => i.hidden !== true && !['bse_segment', 'nse_segment', 'exclusion_list', 'is_exclusion_list']?.includes(i?.dataIndex)),
                                'selectedTab': tableType === 8 ? magicboardFilterType :
                                    stockPeersOptions?.filter((ele) => ele?.value === stockPeersType)?.[0] || {}
                            })}>
                                <MdCompareArrows size={'1.3rem'} />
                            </button>
                            }
                            {downloadTable && <AntDropdown
                                overlayStyle={{ width: "10rem" }} placement='bottomRight'
                                menu={{
                                    items: [
                                        {
                                            key: '11',
                                            label: <span className="textSM w-500 mt-2">
                                                Download
                                            </span>,
                                        },
                                        {
                                            type: "divider"
                                        },
                                        {
                                            key: '1',
                                            icon: <AiFillFileExcel />,
                                            onClick: () => {
                                                exportTable();
                                            },
                                            label: <span className="textSM">
                                                Excel file
                                            </span>,
                                        },
                                        {
                                            key: '2',
                                            icon: <FaFileCsv />,
                                            onClick: () => {
                                                exportTable('csv')
                                            },
                                            label: <span className="textSM">
                                                CSV file
                                            </span>,
                                        },
                                    ],
                                }}
                            >
                                <button className={"btnWhite"} >
                                    <AiOutlineDownload size={18} />
                                </button>
                            </AntDropdown>}
                            {!hideSettings && <Divider style={{ height: "1.5rem", margin: 0 }} type="vertical" />}

                            {!hideSettings && <AntDropdown
                                overlayStyle={{ width: "16rem" }}
                                open={settingsMenu}
                                onOpenChange={(e) =>
                                    setSettingsMenu(e)
                                }
                                menu={{
                                    items: [
                                        {
                                            key: '11',
                                            label: <span className="textSM w-500 mt-2">
                                                Advance settings
                                            </span>,
                                        },
                                        {
                                            type: "divider"
                                        },
                                        {
                                            key: '1',
                                            icon: <BiCustomize />,
                                            onClick: () => {
                                                if (userAccesslevel === 0) {
                                                    dispatch(toggleloginModal(true))
                                                } else {
                                                    setSettingsMenu(false)
                                                    setmodal(true)
                                                }
                                            },

                                            label: <span className="textSM">
                                                Customise table columns
                                            </span>,
                                        },
                                    ],
                                }}
                            >
                                <button className={"btnWhite"} onClick={(e) => { e.preventDefault() }}>
                                    <MdSettings size={18} />
                                </button>
                            </AntDropdown>}
                        </>}
                        {(tableType === 11 || tableType === 12) && !hideSettings && <div>
                            {showUserCustomCols ? <div className='d-flex align-items-center' style={{ gap: "1rem" }}>
                                <button className='btnWhite' onClick={() => {
                                    setShowUserCustomCols(false);
                                }}>
                                    <IoIosEye /> Hide custom columns
                                </button>
                                <div style={{ display: "flex", alignItems: "center", color: "var(--primary)", cursor: "pointer" }} onClick={() => {
                                    setmodal(true)
                                }} className='textSM w-500'>
                                    Columns ({(userCustomColumns?.Columns?.split('|')?.filter((el) => el) || [])?.filter((el) => !(Boolean(otherCol?.find((ele) => ele?.metric_col_code === el)?.key)))?.length})
                                </div>
                            </div> :
                                <button className='btnWhite' onClick={() => {
                                    setShowUserCustomCols(true);
                                }}>
                                    <IoIosEyeOff /> View your custom columns
                                </button>}
                        </div>}
                    </div>

                    {selectedToolFromTab === 'list' ? <MyTable
                        // locale={locale}
                        loading={tableLoading || contentLoading}
                        // scroll={{
                        //     y: 240,
                        // }}
                        rowSelection={showBubbleChart || hideCheckBox || tableType === 1 || tableType === 3 ? false : rowSelection}
                        pagination={{
                            defaultPageSize: paginationSize,
                            position: ['bottomCenter'],
                            showSizeChanger: false,
                            showPrevNextJumpers: true,
                            className: "textSM",
                            hideOnSinglePage: true,
                            itemRender: (current, type, originalElement) => {
                                if (type === 'prev') {
                                    return <a {...originalElement.props} className="textSM mx-2">Previous</a>;
                                }
                                if (type === 'next') {
                                    return <a {...originalElement.props} className="textSM mx-2">Next</a>;
                                }
                                return originalElement;
                            }
                        }}
                        id={tableUniqueId}
                        showSorterTooltip={false}
                        locale={{
                            emptyText: tableLoading ? <></> : <NoDataFound message={noDataMessage} />
                        }}
                        style={{ '--insTableRowHeight': tableRowHeight }}
                        dataSource={tableLoading ? displayData || [] : tableType === 10 ? finalList : allowInitSort ? sortFunds(displayData, sortColKey, sortOrder, tableType === 9, fundData, type.value) : displayData}
                        columns={[...finalDataCols, ...lastCols]?.filter((i) => i.hidden !== true && !(['bse_segment', 'nse_segment', 'exclusion_list', 'is_exclusion_list']?.includes(i?.dataIndex)))}
                        rowKey={(record) => record?.plan_id || record?.symbol}
                        sticky={true}
                        scroll={isScrollingTable ? { x: "max-content", y: "125vh" } : { x: 'max-content' }}
                    /> : selectedToolFromTab === 'heatmap' ? <IndexOrSectorsTreeChart allInstrumentListData={tableType === 10 ? finalList : allowInitSort ?
                        sortFunds(displayData, sortColKey, sortOrder, tableType === 9, fundData, type.value) : displayData} showAdvDec type={type} itemClickDisabled
                        applyFilters nameKey={type?.value === 4 ? "symbol" : "basic_name"} updateItemsOnLivePriceChange={type.value !== 2} /> : null}
                </div>
                <Drawer
                    width={isMobile ? "100vw" : "75vw"}
                    closable={false}
                    title={isMobile ? <DrawerTitle /> : false}
                    onClose={() => {
                        cancelChanges()
                    }}
                    styles={{ header: isMobile ? { padding: "1rem" } : {}, body: isMobile ? { padding: "1rem" } : {}, footer: isMobile ? { padding: "1rem" } : {} }}
                    open={modal}
                    footer={isMobile ? <Drawerfooter /> : false}
                >
                    {!isMobile ? <Card actions={[<div style={{ display: "flex", gap: "0.5rem", alignItems: "center", justifyContent: "flex-end", paddingRight: "1rem" }}><button className="btnWhite" onClick={() => {
                        cancelChanges()
                    }}>Cancel</button>
                        <button className="btnBlack" onClick={() => {
                            applyChanges()
                        }}>Apply changes</button></div>]}
                        bodyStyle={{ display: "flex", flex: 1, height: "100%", padding: 0, overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%" }}
                        title={<span style={{ display: "flex", alignItems: "center" }}>
                            <CardHeader heading={'Select and order metrics'} hideHeaderBlueLine headingType={2} />
                            <div>
                                <MetricSearch data={screenerFinalData} onChange={onMetricSelect} type='customize' disable={true} finalDataCols={selectedCols}
                                    isMultiple={true} insType={type.value} />
                            </div>
                            <IoIosClose onClick={() => {
                                cancelChanges()
                            }}
                                size={"1.75rem"} style={{ cursor: "pointer" }} />
                        </span>}
                        bordered>
                        <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", paddingRight: 0, overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 1.2, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                            {screenerFinalData.map((item, index) => {
                                if (item.isVisibleNormally === true) {
                                    return renderRow({
                                        item, index
                                    })
                                } else {
                                    return <></>
                                }
                            }
                            )}
                        </Card>
                        <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 1.1, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                            <RenderIntialFlatlist item={screenerFinalData.filter((i) => i.category_id === selectedCategory)[0]} />
                        </Card>
                        <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", overflowY: "scroll", padding: 0 }} style={{ display: "flex", flexDirection: "column", flex: 2.7, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                            <AppliedFilterView />
                            {/* <Divider style={{ margin: '1rem 0rem', padding: 0 }} /> */}
                            <DrawerMetricDescComp />

                        </Card>
                    </Card> : <div style={{ height: "100%", overflowY: "hidden", display: "flex", flexDirection: "column" }}>
                        {!selectedCategory ? <div style={{ flex: 1, overflowY: "auto" }}>
                            {screenerFinalData?.filter((item) => item.isVisibleNormally === true)?.map((item, index, arr) => {
                                let lastIndex = arr?.length - 1;
                                return renderRow({
                                    item, index, lastIndex
                                })
                            })}
                        </div> : !selectedMetric ? <div style={{ flex: 1, overflowY: "auto" }}>
                            <RenderIntialFlatlist item={screenerFinalData.filter((i) => i.category_id === selectedCategory)[0]} />
                        </div> : <MetricInfoView />}
                    </div>}
                </Drawer>

                <Drawer
                    width={"89vw"}
                    title={null}
                    placement="right"
                    onClose={() => {
                        setShowBubbleChart(!showBubbleChart)
                    }}
                    closable={false}
                    open={showBubbleChart}
                    styles={{
                        footer: {
                            display: "flex",
                            justifyContent: "flex-end"
                        }
                    }}
                    footer={<div>
                        <button className="btnBlack" onClick={() => {
                            setShowBubbleChart(!showBubbleChart)
                        }}>
                            Close
                        </button>
                    </div>}>
                    <div>
                        <CardHeader heading={<span className="">Factor Map</span>} hideHeaderBlueLine headingType={2} />
                        <div style={{ display: "flex", flexDirection: "row", gap: "2rem", padding: '1.25rem 0rem' }}>
                            <Card bordered={false} style={{ padding: 0, position: "relative", flex: 1.4 }} bodyStyle={{ padding: 0 }}>
                                <CustomSegmentedTab
                                    options={[
                                        { "key": 1, label: "Quality v/s Value", value: 1 },
                                        { "key": 2, label: "Momentum v/s Value", value: 2 },
                                        { "key": 3, label: "Quality v/s Momentum", value: 3 },
                                    ]}
                                    isBlock={false}
                                    value={bubbleChartType}
                                    onChange={(e) => setbubbleChartType(e)}
                                />
                                <BubbleChart />
                            </Card>
                            <Card bordered={false} style={{ position: "relative", height: "100%", overflowY: "scroll", flex: 1 }} bodyStyle={{ padding: 0 }}>
                                <MyTable
                                    locale={{
                                        emptyText: tableLoading ? <Skeleton active={true} /> : <NoDataFound />
                                    }}
                                    rowSelection={showBubbleChart ? false : rowSelection}
                                    pagination={{
                                        defaultPageSize: paginationSize,
                                        position: ['bottomCenter'],
                                        showSizeChanger: false,
                                        showPrevNextJumpers: true,
                                        className: "textSM",
                                        hideOnSinglePage: true,
                                        itemRender: (current, type, originalElement) => {
                                            if (type === 'prev') {
                                                return <a {...originalElement.props} className="textSM mx-2">Previous</a>;
                                            }
                                            if (type === 'next') {
                                                return <a {...originalElement.props} className="textSM mx-2">Next</a>;
                                            }
                                            return originalElement;
                                        },
                                        onChange: (page, pageSize) => {
                                            setPageData({
                                                page: page,
                                                pageSize: pageSize
                                            })
                                        }
                                    }}
                                    defaultSortOrder='descend'
                                    bordered={false}
                                    size='small'
                                    className='textXS black'
                                    dataSource={tableLoading ? [] : sortFunds(displayData, sortColKey, sortOrder)}
                                    columns={unique([
                                        ...dataTableColumns?.filter((el) => el),
                                        ...screenerMetric.filter((item) => chartKeys.includes(item.metric_col_code)),
                                    ], 'dataIndex').filter((i) => (i.hidden !== true && chartKeys.includes(i.dataIndex) && !['bse_segment', 'nse_segment', 'exclusion_list', 'is_exclusion_list']?.includes(i?.dataIndex))).map((i) => {
                                        return {
                                            ...i,
                                            width: 120,
                                            sorter: false
                                        }
                                    })}
                                    rowKey={(record) => record?.plan_id || record?.symbol}
                                    scroll={{ x: "max-content", y: "70vh" }}
                                />
                            </Card>
                        </div>
                    </div>
                </Drawer>
            </Card>
        );
    }
};

export default InstrumentListTable;