import { Card, Divider, Select } from "antd";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import { CardHeader } from "Components/atoms/SmallComponents";
import { CategoryIcon2 } from "Components/CategoryIcon";
import { getKnowledgeBaseData } from "Components/getKnowledgeBaseData";
import { NoDataFound } from "Components/NoDataFound";
import Head from "Layout/Head";
import { formatDateMMMDDYYYY } from "Components/DateFormatter";
import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { FcFolder } from "react-icons/fc";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import apiHelperPY, { decodeData } from "Services/api/ApiHelperPY";

const CategoryPage = () => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const desc_id = searchParams.get('desc_id');
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState({});
    const [subCategory, setSubCategory] = useState({});
    const navigate = useNavigate();
    const [itemType, setItemType] = useState(desc_id === null ? 'subcategory' : "metric");
    const [apiError, setApiError] = useState(false);
    const [screenerData, setScreenerData] = useState([]);
    const [selectedMetric, setSelectedMetric] = useState({});
    const metricsCategory = ['stocks', 'mfs-and-etfs'];

    let isDesktop = window.innerWidth > 760;

    const loadData = async () => {
        try {
            let res = await getKnowledgeBaseData();
            if (res.type) {
                let tempCategory = res.categories?.filter(item => item?.Category?.toLowerCase().split(' ').join('-') === params?.category?.toLowerCase())?.[0] || {};
                setCategory(tempCategory);
                setSubCategory(tempCategory?.SubCategories?.[0] || {});
            }
            if (metricsCategory.includes(params.category?.toLowerCase())) {
                let type = params.category?.toLowerCase() === 'stocks' ? [{ type: 4, label: "st" }] : [{ type: 1, label: "etf" }];
                let tempData = [];
                for (let i = 0; i < type.length; i++) {
                    let res = await apiHelperPY({
                        apiName: 'getMetricDescription', data: type?.[i]?.type, getFresh: false,
                        saveResponse: "sessionStorage"
                    });
                    let d = JSON.parse(decodeData(res));
                    // let sy = JSON.parse(res) || [];
                    d = d?.filter((ele) => ele.description);
                    // console.log(d);
                    tempData = [...tempData, ...d];
                }
                setScreenerData(tempData);
                if (desc_id || desc_id === 0) {
                    setItemType("metric")
                    setSelectedMetric(tempData?.find(ele => ele.desc_id === Number(desc_id)) || {});
                }
                else {
                    setSelectedMetric(tempData?.[params.category?.toLowerCase() === 'stocks' ? 39 : 24] || {});
                }
            }
            setLoading(false);
        }
        catch (e) {
            setApiError(true);
            setLoading(false);
        }
    }

    function isSubsequence(subseq, str) {
        let i = 0;
        for (const char of str) {
            if (char?.toLowerCase() === subseq[i]?.toLowerCase()) {
                i++;
                if (i === subseq.length) {
                    return true;
                }
            }
        }
        return false;
    }

    const relatedMetrics = () => {
        let arr = [];
        let ind = screenerData.findIndex((el) => el.desc_id === selectedMetric.desc_id);
        if (ind === 0) {
            return screenerData.slice(1, 5);
        }
        else if (ind === screenerData.length - 1) {
            return screenerData.slice(screenerData.length - 5, screenerData.length - 1)
        }
        else {
            let temp1 = screenerData.slice(ind - 2, ind);
            let temp2 = screenerData.slice(ind + 1, ind + 3);
            return [...temp1, ...temp2]
        }
    }

    useEffect(() => {
        loadData();
    }, [params]);

    React.useEffect(() => {
        if (itemType === 'metric') {
            setTimeout(() => {
                const anchorTags = document.getElementsByTagName("a");
                for (let i = 0; i < anchorTags.length; i++) {
                    const anchor = anchorTags[i];
                    const href = anchor.getAttribute("href");
                    let modifiedHref = href;
                    if (modifiedHref?.startsWith("https://beta.sharpely") || modifiedHref?.startsWith("https://dev.sharpely")) {
                        modifiedHref = modifiedHref?.replace(/\b(beta\.sharpely|dev\.sharpely)\b/g, 'sharpely')
                    }
                    if (modifiedHref?.startsWith("/knowledge-base") || modifiedHref?.startsWith("https://sharpely.in/knowledge-base")) {
                        modifiedHref = modifiedHref?.replace(/\.html/g, "")?.replace(/_/g, "-");
                    }
                    anchor.setAttribute("href", modifiedHref);
                }
            }, 100)
        }
    }, [selectedMetric])

    const titleAndMeta = {
        "stocks": {
            title: "Explore Knowledge on Share Market Stocks - Penny Stocks, Blue-Chip Stocks, Share Market Basics",
            description: "Discover valuable insights on various types of stocks including penny stocks, blue-chip stocks, growth stocks, and more. Enhance your knowledge of share market and stock market indices with sharpely.",
            keywords: "Penny stocks, Blue-chip stocks, Share market basics, Growth stocks, Stock market indices, How to invest in stocks, Stock market terminology, Stock market trends, stock market investment strategy, Stock trading strategies, Stock expert, Stock Market Research Tools, top penny stocks, top blue chip stocks, best share market basics learning platform, top growth stocks, top stock market indices, how to invest in stocks for long term, best stock market terminology, stock market trends analysis, best stock market investment strategy, best stock trading strategies, best stock experts, top stock market research tools, top penny stocks in India, top blue chip stocks India, best share market basics learning platform India, top growth stocks india, top stock market indices india, how to invest in stocks in india, Stock market terminology in India, top stock market trends in India, best stock market investment strategy in India, best stock trading strategies in India, best stock experts in india, top stock market research tools in India"
        },
        "stock-scores": {
            title: "Explore Knowledge on Stock Scores",
            description: "Explore our stock scoring models and utilize advanced technical analysis to enhance your knowledge on identifying the quality, value, momentum and multi-factor scores of a stock.",
            keywords: "Stock analysis, stocks watchlist, Stock Analysis tools, Stock rating , Stock score technical analysis, Stock research tools, Stock scoring models, best stock analysis website, best stock watchlist website, best stock analysis tools, best stock rating companies, best stock scores technical analysis companies, best stock research tools, top stock scoring models, best stock analysis website in India, best stock watchlist websites in india, best stock analysis tools in India, best stock rating companies in India, stock scores technical analysis companies India, best stock research tools in India, top stock scoring models in India"
        },
        "mfs-and-etfs": {
            title: "ETFs and Mutual Funds: Investing in ETFs, Liquidity, and Advantages",
            description: "Know how to build your ETFs and Mututal Funds Screen and know what all matters while investing in Mutual Funds and ETFs. Enhance your investment skills with our vast knowledge base and make better investment decisions.",
            keywords: "Investing in Mutual Funds, Tax on Mutual Fund, Compare mutual fund performance, Mutual Fund investment strategies, Mutual Fund risk factors, Mutual Fund expert, Investing in ETFs, ETF liquidity, ETF advantages, ETF expense ratio comparison, ETF index replication methods, ETF investment strategy, ETF expert, Best mutual funds investment platform, Mutual fund tax calulation, Best tool to compare mutual fund performance , Top mutual fund investment strategies, Top mutual fund risk factors, Best mutual fund expert, best platform for investing in etfs, etf liquidity measures, best etf advantages, top etf expense ratio comparison platform, best etf index replication methods, top etf investment strategy, best etf experts, Best mutual funds investment platform in india, Best tool to compare mutual fund performance in india, Top mutual fund investment strategies in india, Best mutual fund expert india, best platform for investing in etfs in India, Top etf expense ratio comparison platform in India, best etf index replication methods in India, Top etf investment strategy in india, Best etf experts in india"
        },
        "etf-masterclass": {
            title: "Know all about ETFs | ETFs Types and Availability | Basic to Advance",
            description: "Learn the basics of ETFs, its performance evaluation, liquidity and the type of ETFs available in India. Get to know how to select the best ETFs portfolio.",
            keywords: "ETF investing strategies, ETF trading strategies, ETF strategies, ETF Masterclass, ETF portfolio management, ETF risk management, ETF investment analysis, ETF watchlist, top etf investing strategies, best etf trading strategies, best etf strategies, top etf masterclass, best etf portfolio management , Top etf risk management platform, Top etf investment analysis platform, best etf watchlist platform, Top etf investing strategies in india, Best etf trading strategies in india, Best etf strategies in india, top etf masterclass in india, Best etf portfolio management in india, Top etf risk management platform in india, Top etf investment analysis platform in india, Best etf watchlist platform in india"
        },
        "mf-masterclass": {
            title: "Mutual Funds : Types, Features, Objective, Performance, and Analysis Basics",
            description: "Get tips and tricks of successful Mutual Fund Investing with our comprehensive Mutual Fund Masterclass. Learn about Equity, Debt and Balanced mutual funds and gain valuable insights for making right investment decisions.",
            keywords: "Mutual Fund Investing, Debt Mutual Funds, Mutual Fund SIP (Systematic Investment Plan), Equity Mutual Funds, Balanced Mutual Funds, Mutual Fund Guide, Mutual Fund Performance Analysis, Mutual Fund Portfolio Management, Mutual Fund Strategies, Mutual Fund Investment Options, Mutual Fund Masterclass, Mutual fund watchlist, Best mutual fund investing platform, Top debt mutual funds, Top mutual fund SIP, Best equity mutual funds, Best balanced mutual funds, Top mutual fund guide, Top mutual fund performance analysis platform, Best mutual fund portfolio management, Best mutual fund strategies, Best mutual fund investment options, Best mutual fund masterclass, Top mutual fund watchlist, Best mutual fund investing platform in india, Top debt mutual funds in india, Best equity mutual funds in India, Best balanced mutual funds in india, Top mutual fund performance analysis platform in india, Best mutual fund portfolio management in india, Best mutual fund strategies in india, Best mutual fund investment options in india, Best mutual fund masterclass in india, Top mutual fund watchlist in India"
        },
        "baskets": {
            title: "Baskets",
            description: "",
            keywords: "",
        },
        "data-&-methodology": {
            title: "Data & Methodology",
            description: "",
            keywords: ""
        }
    }

    return (
        <React.Fragment>
            <Head title={titleAndMeta[params.category?.toLowerCase()]?.title || "Knowledge Base Category"}
                description={titleAndMeta[params.category?.toLowerCase()]?.description || "Category"} keywords={titleAndMeta[params.category?.toLowerCase()]?.keywords || ""} />
            {loading ? <div className='loaderContainer' >
                <ActivityLoaderMintbox showQuote={true} />
            </div> :
                <div className='normalContainer'>
                    <Card bodyStyle={{ background: "var(--white)", borderRadius: "0.5rem", padding: "0px" }}
                        style={{ borderRadius: "0.5rem", border: "1px solid var(--grey2)" }} className='mobCardBorderRadius0 mobBorderNone'>
                        <div className='' style={{ gap: "0rem", display: "grid", gridTemplateColumns: "2fr 1fr", alignItems: "center" }}>
                            <div style={{ padding: "1rem 2rem 1rem", }}>
                                <h1 className='displayMD black w-700' style={{ fontWeight: "700" }}>
                                    {category.Category}
                                </h1>
                                <div className='textMD dark3 margin20Bottom d-none d-sm-flex' style={{ maxWidth: "40ch" }}>
                                    {category.Description}
                                </div>
                            </div>
                            <div style={{
                                width: "100%",
                                //  background: "linear-gradient(272.41deg, var(--grey2) 55.38%, var(--white) 89.01%)",
                                backgroundSize: "cover", display: "flex", justifyContent: "flex-end", height: "100%",
                                borderRadius: "0.5rem"
                            }} className='mobCardBorderRadius0'>
                                <CategoryIcon2 title={category?.Category} style={{ width: "70%", padding: "1rem", }} className='mobileW100' />
                            </div>
                        </div>
                    </Card>
                    <div className="mainContainer" style={{ flex: 1 }}>
                        <div className='rightContainer'>
                            <Card style={{ padding: 0 }} bodyStyle={{ padding: 0 }} className='mobCardBorderRadius0'>
                                <div style={{ padding: "1rem" }}>
                                    <CardHeader heading={'Sections'} headingType={0} />
                                </div>
                                <Divider style={{ margin: 0, background: "var(--grey3)" }} />
                                <div style={{ padding: '1rem' }} className='d-flex flex-column'>
                                    {category?.SubCategories?.length > 0 ? category?.SubCategories?.map((item, i) => (
                                        <div key={i + item?.SubCategory} className='d-flex textSM w-500 black justify-content-between' style={{
                                            gap: "0.5rem", cursor: "pointer",
                                            background: (subCategory?.UID === item.UID) && itemType === 'subcategory' ? "var(--blueShade)" : "transparent",
                                            padding: "0.5rem 0.75rem",
                                            borderRadius: "var(--borderRadius)"
                                        }}
                                            onClick={() => {
                                                setItemType('subcategory')
                                                setSubCategory(item)
                                            }}>
                                            <div className='d-flex textSM w-500 black' style={{ gap: "0.5rem" }}>
                                                <FcFolder />
                                                <h2 className='margin0 textSM w-500'>{item.SubCategory}</h2>
                                            </div>
                                            <span className='textXS'>
                                                ({item?.Resources?.length})
                                            </span>
                                        </div>
                                    )) :
                                        <div style={{ padding: "0rem" }}>
                                            <NoDataFound message="It seems like this category has no sections." />
                                        </div>}
                                    {metricsCategory.includes(params?.category?.toLowerCase()) && Object.values(category).length > 0 &&
                                        <div className='d-flex textSM w-500 black justify-content-between' style={{
                                            gap: "0.5rem", cursor: "pointer",
                                            background: itemType === 'metric' ? "var(--blueShade)" : "transparent",
                                            padding: "0.5rem 0.75rem",
                                            borderRadius: "var(--borderRadius)"
                                        }}
                                            onClick={() => {
                                                // setSubCategory(item)
                                                setItemType('metric');
                                            }}>
                                            <div className='d-flex textSM w-500 black' style={{ gap: "0.5rem" }}>
                                                <FcFolder />
                                                <h2 className='margin0 textSM w-500'>{category.Category} metrics</h2>
                                            </div>
                                            <span className='textXS'>
                                                ({screenerData.length})
                                            </span>
                                        </div>
                                    }
                                </div>
                            </Card>
                        </div>
                        <div className="leftContainer" style={{ height: "100%" }}>
                            {itemType === 'subcategory' ?
                                <Card style={{ padding: 0, minHeight: "100%" }} bodyStyle={{ padding: 0, minHeight: "100%" }} className='mobCardBorderRadius0'>
                                    <div style={{ padding: "1rem" }}>
                                        <CardHeader heading={subCategory.SubCategory} headingType={2} />
                                    </div>
                                    <Divider style={{ margin: 0, background: "var(--grey3)" }} />
                                    <div style={{}} className='d-flex flex-column'>
                                        {subCategory?.Resources?.length > 0 ? subCategory?.Resources?.map((item, i) => (<div key={i + item?.Title}>
                                            <div className='d-flex flex-column' style={{ padding: '1rem', gap: "0.75rem" }}
                                                onClick={() => { navigate(item.Slug) }}>
                                                <div>
                                                    <h3 className="textMD w-700 black margin0" onClick={() => {
                                                        navigate(item.Slug)
                                                    }} style={{ cursor: "pointer" }}>{i + 1}. {item?.Title}</h3>
                                                    <div style={{ color: "var(--dark3)", userSelect: "none" }} className='w-500 textXS mt-1'>
                                                        by <span style={{ color: "var(--black)" }}>{item.Author}</span> {formatDateMMMDDYYYY(item?.UpdatedOn)}
                                                    </div>
                                                </div>
                                                {item?.Description && <div className='textSM black'>
                                                    {item.Description}
                                                </div>}
                                                {/* <div className="d-flex justify-content-end">
                                            <button className='btnPrimary' style={{ width: "fit-content" }} onClick={() => {
                                                navigate(item.Slug)
                                            }}>Read</button>
                                        </div> */}
                                            </div>
                                            {i !== subCategory?.Resources?.length - 1 &&
                                                <Divider style={{ margin: 0, background: "var(--grey3)" }} />}
                                        </div>
                                        )) : <div style={{ padding: "1rem" }}>
                                            <NoDataFound message="No data" />
                                        </div>}
                                    </div>
                                </Card> :
                                <Card style={{ padding: 0, minHeight: "100%" }} bodyStyle={{ padding: 0, minHeight: "100%" }} className='mobCardBorderRadius0'>
                                    <div style={{
                                        padding: "1rem", display: "flex", justifyContent: "space-between",
                                        flexDirection: window.innerWidth > 575 ? "row" : "column",
                                        gap: "1rem"
                                    }}>
                                        <CardHeader heading={category.Category + ' metrics'} headingType={2} />
                                        <Select showSearch placeholder={<span><BsSearch /> Search for metrics</span>}
                                            style={{ width: "20rem" }} onChange={(value) => {
                                                let temp = screenerData.find((el) => el.desc_id === value);
                                                setSelectedMetric(temp);
                                            }} filterOption={(input, option) => {
                                                return isSubsequence(input, option?.label)
                                            }} value={null} options={screenerData?.map((ele, i) => {
                                                return {
                                                    key: i + ele?.name,
                                                    label: ele?.name,
                                                    value: ele?.desc_id
                                                }
                                            })} />
                                    </div>
                                    {isDesktop && <Divider style={{ margin: 0, background: "var(--grey3)" }} />}
                                    <div style={{ padding: isDesktop ? "1rem" : "0" }}>
                                        {selectedMetric.name &&
                                            <Card style={{
                                                background: "var(--grey4)", border: isDesktop ? "1px solid var(--grey1)" : "", padding: 0,
                                                borderTop: "1px solid var(--grey1)"
                                            }}
                                                bodyStyle={{ padding: isDesktop ? "0.75rem" : "1rem" }} className='mobCardBorderRadius0'>
                                                <h3 className="textMD w-700 black margin0" style={{ cursor: "pointer" }}>
                                                    {selectedMetric?.name}
                                                </h3>
                                                <div className="textSM dark4" id='kbMetricDescDiv' dangerouslySetInnerHTML={{ __html: selectedMetric.description || "" }}
                                                    style={{ marginTop: "0.5rem" }}>
                                                </div>
                                            </Card>
                                        }
                                    </div>
                                    <Divider style={{ margin: 0, background: "var(--grey3)" }} />
                                    <div style={{ padding: "1rem" }}>
                                        <div className="textMD w-700 black">Other metrics of interest</div>
                                        <div style={{ display: "flex", gap: "1rem", flexWrap: 'wrap' }} className='textSM w-500 mt-2'>
                                            {relatedMetrics()?.map((ele, i) => (
                                                <Card style={{ cursor: "pointer", background: "var(--grey4)", border: "1px solid var(--grey1)", padding: 0 }} onClick={() => {
                                                    setSelectedMetric(ele)
                                                }} bodyStyle={{ padding: "0.375rem 0.75rem" }} className='categoryCardKb' key={i + 1}>
                                                    <div className='cardHeader'>
                                                        {ele.name}
                                                    </div>
                                                </Card>
                                            ))}
                                        </div>
                                    </div>
                                </Card>
                            }
                        </div>
                    </div>
                </div>
            }
        </React.Fragment >
    )
}

export default CategoryPage;