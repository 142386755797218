import { flaticonSvg } from 'Assets/Constant';
import CustomSegmentedTab from 'Components/CustomSegmentedTab';
import { DashboardChartMetrics } from 'Components/Dashboard/DashboardChartMetrics';
import IndexPerformanceChart from 'Components/IndexPerformanceChart';
import OHLC from "Components/OHLC";
import TechnicalsSummary from 'Components/TechnicalsSummary';
import { fetchFundDataWithoutFilter } from 'Libs/fetchFundData';
import { updateCustomColumns } from 'Libs/redux/UserAuth/reduxSlice';
import { makeid, screenerMetricData } from 'Libs/utils';
import AdminServicesPY from 'Services/api/AdminServicesPY';
import apiHelper from 'Services/api/ApiHelper';
import apiHelperPY, { decodeData } from 'Services/api/ApiHelperPY';
import { PriceDisplay, StockLivePrice } from 'Services/api/LivePricesWebSocket';
import { GetLTP } from "Services/api/LivePricesWebSocketOnlyVal";
import { Card, Divider, Skeleton } from 'antd';
import moment from 'moment';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import AMCIcon from './AMCIcon';
import ActivityLoaderMintbox from './ActivityLoaderMintbox';
import { CurrencyFormat } from './CurrencyFormat';
import { InstrumentListSearch } from './CustomComponents/InstrumentListSearch';
import { DecimalValueFormat } from './DecimalValueFormat';
import Fundamentals from './Fundamentals';
import PerfBenchChart from './PerfBenchChart';
import PriceVolumeChart from './PriceVolumeChart';
import PriceVsNAVChart from './PriceVsNAVChart';
import RedGreenText from './RedGreenText';
import DashboardChartNotes from './atoms/DashboardChartNotes';
import { isMarketOpen } from './atoms/IsMarketOpen';
import { CardHeader } from './atoms/SmallComponents';
import { NoDataFound } from './NoDataFound';

const DashboardChartComponent = ({
    type,
    header = '',
    subHeading = '',
    allInstruments = [],
    selectedFundData = {},
    indexMetricsComponent = <></>,
    allRangeAnalysisDatafromDetailPage,
    isWidget = false,
    hidePerformanceChart = false,
    hidePriceChart = false,
    updateIndexOHLC = () => { },
}) => {
    const dispatch = useDispatch()
    const isMobile = window.innerWidth <= 760;
    const user = useSelector((state) => state?.updateUserData?.value);
    const location = useLocation();
    const userCustomCols = useSelector((state) => state?.userCustomCols?.userCustomCols);
    const [perfChartData, setperfChartData] = React.useState(undefined)
    const [priceChartData, setPriceChartData] = React.useState({})

    const [indexOHLC, setIndexOHLC] = React.useState([]);
    const [indexBenchmarkOHLC, setIndexBenchmarkOHLC] = React.useState([]);
    const [allInstrumentsListData, setallInstrumentsListData] = React.useState(allInstruments)
    const [selectedInstrument, setselectedInstrument] = React.useState(selectedFundData)
    const [liveStateData, setLiveStateData] = React.useState([]);
    const [allIndicatorData, setAllIndicatorData] = React.useState([])
    const [loadingTechnicalsIndicators, setLoadingTechnicalsIndicators] = React.useState(true);
    const [allRangeAnalysisData, setAllRangeAnalysisData] = React.useState({})

    let webSocket = useRef();
    const isLocal = process.env.NODE_ENV === 'development'; // change later before pushing
    const [selectedBenchmark, setselectedBenchmark] = React.useState(type.value === 5 ? selectedInstrument?.Id ? {
        "Id": 0,
        "IndexName": "Nifty 50",
        "IndexNameCaps": "NIFTY50",
        "proper_name": "Nifty 50",
        "symbol": "NIFTY 50",
        "gdf_symbol": "NIFTY 50"
    } :
        {
            "Id": 4,
            "IndexName": "Nifty Bank",
            "IndexNameCaps": "NIFTYBANK",
            "proper_name": "Nifty Bank",
            "symbol": "NIFTY BANK",
            "gdf_symbol": "NIFTY BANK"
        } : [{
            "uid": 7,
            "benchmark_id": 7,
            "benchmark_name": "Nifty 50 TRI",
            "ticker": "NIFTY 50",
            "benchmark_type": 1,
            "rebalance": "",
            "Origin": "NSE"
        }]);
    const [snapshotMetricsLoading, setSnapshotMetricsLoading] = React.useState(true);
    const [userCustomColumns, setUserCustomColumns] = React.useState({});
    const [screenerMetricDescData, setScreenerMetricDescData] = React.useState([])
    const [screenerFinalData, setscreenerFinalData] = React.useState([]);
    const [screenerMetric, setscreenerMetric] = React.useState([]);
    const [historicalCharts, setHistoricalCharts] = React.useState({})
    var stockprices = useSelector((state) => state?.stockprices?.value)
    const [isOnline, setIsOnline] = React.useState(navigator.onLine);
    const [loading, setLoading] = React.useState(true);
    var akaka = 0

    const updateUserCustomCols = async (snapshot = []) => {
        let d = {
            snapshot, columns: userCustomColumns?.Columns?.split('|')?.filter(el => el) || [], instrument_type: type.strVal?.toUpperCase(), client_id: user?.id, uid: userCustomColumns?.UID || 0
        }
        ////console.log(d)
        let res = await apiHelper({ apiName: "saveCustomColumns", data: JSON.stringify(d) });
        ////console.log(res);
        if (res === 'success') {
            let customCols = await apiHelper({ apiName: "getCustomColumns", data: user?.id, saveResponse: "sessionStorage", getFresh: true });
            let cols = null;
            if (customCols) {
                cols = customCols?.find((el) => el?.InstrumentType === type?.strVal?.toUpperCase());
                ////console.log(cols);
                setUserCustomColumns(cols);
                dispatch(updateCustomColumns(customCols))
            }
        }
    }

    // live price logic starts
    const closeWebsocket = () => {
        if (webSocket.current) {
            ////console.log("hua close")
            webSocket.current?.close();
        }
    }

    const isCustomIndex = React.useMemo(() => {
        let res = false;
        if (type?.value === 5 && String(selectedInstrument?.IndexName)?.startsWith('SHI:')) {
            res = true;
        }
        return res;
    }, [selectedInstrument])

    const customIndexPrice = React.useMemo(() => {
        let result;
        if (type?.value === 5 && String(selectedInstrument?.IndexName)?.startsWith('SHI:') && indexOHLC?.length > 0) {
            let temp = [...indexOHLC];
            temp.sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime())
            temp = [...temp]?.slice(0, 2);
            let currPriceObj = temp?.[0];
            let prevPriceObj = temp?.[1];
            let currentLTP, changePer, changeVal, dateTime = '-';
            currentLTP = currPriceObj?.price_adj_split;
            changeVal = currentLTP - prevPriceObj?.price_adj_split;
            changePer = (changeVal / prevPriceObj?.price_adj_split) * 100;
            dateTime = new Date(currPriceObj?.Date)?.toLocaleString('en-IN', {
                day: "2-digit", month: "short", hour: "numeric",
                minute: "2-digit", timeZone: 'Asia/Kolkata',
            })
            result = {
                currentLTP, changePer, changeVal, dateTime
            }
        }
        return result;
    }, [indexOHLC, selectedInstrument, type?.value]);

    React.useEffect(() => {
        function onlineHandler() {
            setIsOnline(true);
        }

        function offlineHandler() {
            ////console.log(webSocket)
            closeWebsocket()
            setIsOnline(false);
        }

        window.addEventListener("online", onlineHandler);
        window.addEventListener("offline", offlineHandler);


        return () => {
            window.removeEventListener("online", onlineHandler);
            window.removeEventListener("offline", offlineHandler);
        };
    }, []);

    const loadPriceChartFromAPI = async () => {
        if (type?.value !== 2) {
            let t = document.getElementById('symbolid')?.innerText
            let resa = await apiHelper({ apiName: 'getFundOneDayPrices', data: { id: (t || '')?.replaceAll('&', '_') }, saveResponse: '' })
            if (resa !== null) {
                var point = resa;
                setLiveStateData(point || [])
            }
            else {
                setLiveStateData("noData")
            }
        }
    }

    const socketlogic = async () => {
        webSocket.current = new WebSocket(`wss://api.mintbox.ai/wss/gdf_live_prices_sym`);
        webSocket.current.onerror = function (event) {
            // alert(event.data);
        };

        webSocket.current.onopen = function (event) {
            let t = document.getElementById('symbolid')?.innerText
            webSocket.current?.send(t)
            return false;
        };

        webSocket.current.onmessage = function (event) {
            let t = document.getElementById('symbolid')?.innerText
            var point = JSON.parse(event.data);
            if (event?.data?.includes((t || ''))) {
                akaka += 1
                setLiveStateData(point)

            } else {
                webSocket.current?.send(t?.replaceAll('&', '_'))
                webSocket.current?.send(t?.replaceAll('&', '_'))
                webSocket.current?.send(t?.replaceAll('&', '_'))
                setLiveStateData([])
            }

            if (akaka > 4) {
                akaka = 0
                webSocket.current?.send(t?.replaceAll('&', '_'))
            }

        };

        webSocket.current.onclose = async function (event) {
            let t = document.getElementById('symbolid')?.innerText
            ////console.log(t)
            let resa = await apiHelper({ apiName: 'getFundOneDayPrices', data: { id: (t || '')?.replaceAll('&', '_') }, saveResponse: '', getFresh: true })
            // let resa = await apiHelper({ apiName: 'getFundOneDayPrices', data: { id: t }, saveResponse: '' })
            if (resa !== null) {
                var point = resa;
                setLiveStateData(point || [])
            }
            else {
                closeWebsocket()
                setLiveStateData("noData")
            }
        };
    }

    const liveData = () => {
        let s = document.getElementById('symbolid')?.innerText
        if (s !== undefined && type?.value !== 2) {
            if (isMarketOpen() && !isLocal) {
                if (webSocket.current && (webSocket.current?.readyState === 1)) {
                    return
                } else {
                    closeWebsocket()
                    setLiveStateData([])
                }
                socketlogic()
            }
            else {
                if (stockprices !== 'close') {
                    loadPriceChartFromAPI();
                }
            }
        }
    }

    const handleLiveData = (e) => {
        if (document.hidden === false) {
            if (type?.value !== 2) {
                liveData();
            }
        }
    }

    React.useEffect(() => {

        document.addEventListener('visibilitychange', handleLiveData)
        return () => {
            document.removeEventListener('visibilitychange', handleLiveData)
        }
    }, [selectedInstrument])

    React.useEffect(() => {
        ////console.log("ASDASD", webSocket.current)
        if (isMarketOpen() && (selectedInstrument?.gdf_symbol !== undefined) && webSocket.current?.readyState === 1 && type?.value !== 2) {
            webSocket.current?.send(selectedInstrument?.gdf_symbol?.replaceAll('&', '_'))
            webSocket.current?.send(selectedInstrument?.gdf_symbol?.replaceAll('&', '_'))
            webSocket.current?.send(selectedInstrument?.gdf_symbol?.replaceAll('&', '_'))
            webSocket.current?.send(selectedInstrument?.gdf_symbol?.replaceAll('&', '_'))
            webSocket.current?.send(selectedInstrument?.gdf_symbol?.replaceAll('&', '_'))
            webSocket.current?.send(selectedInstrument?.gdf_symbol?.replaceAll('&', '_'))
            webSocket.current?.send(selectedInstrument?.gdf_symbol?.replaceAll('&', '_'))
            webSocket.current?.send(selectedInstrument?.gdf_symbol?.replaceAll('&', '_'))
            webSocket.current?.send(selectedInstrument?.gdf_symbol?.replaceAll('&', '_'))
            webSocket.current?.send(selectedInstrument?.gdf_symbol?.replaceAll('&', '_'))
        }
    }, [selectedInstrument]);


    React.useEffect(() => {
        if (!isMarketOpen()) {
            document.addEventListener('visibilitychange', handleLiveData)
            return () => {
                document.removeEventListener('visibilitychange', handleLiveData)
            }
        }
    }, [stockprices])

    React.useEffect(() => {
        return () => {
            ////console.log(webSocket)
            if (webSocket.current) {
                ////console.log("hua close")
                webSocket.current?.close();
            }
            // clearInterval(timerID);
        }
    }, [])
    // Live price logic ends

    const getIndicators = async () => {

        setLoadingTechnicalsIndicators(true)
        const obj = {
            'symbol': type?.value === 5 ? String(selectedInstrument?.Id) : type.value === 4 ? selectedInstrument?.symbol || selectedInstrument?.nse_symbol : '',
            'timeframe': ['1m', '5m', '15m', '30m', '1H', '1D', '1W', '1M'],
            'request': [],
            'indices': type?.value === 5 ? true : false
        }
        if (obj?.symbol !== '') {
            let result = await AdminServicesPY.getIndicators({ 'params': JSON.stringify(obj) }).then(r => r);
            ////console.log(result)
            if (result?.data?.['api-status'] !== 'error') {
                setAllIndicatorData(result?.data);
                setLoadingTechnicalsIndicators(false)
            } else {
                setAllIndicatorData([]);
                setLoadingTechnicalsIndicators(false)
            }
        } else {
            setAllIndicatorData([]);
            setLoadingTechnicalsIndicators(false)
        }
    };

    const getIndexClose = async () => {
        setIndexOHLC([])
        updateIndexOHLC([])
        let res = await apiHelperPY({ apiName: "getIndexClose", data: selectedInstrument?.Id, saveResponse: "" });
        if (res) {
            res = JSON.parse(res?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null)) || {};
            res = res?.map(p => {
                return { 'Date': p.timestamp, 'price_adj_split': p.close, 'volume_adj': p.volume }
            })
            setIndexOHLC(res)
            updateIndexOHLC(res);
        }
    }

    const getBenchmarkOHLC = async () => {
        setIndexBenchmarkOHLC([])
        // for benchmark
        let res3 = await apiHelperPY({ apiName: "getIndexClose", data: selectedBenchmark?.Id, saveResponse: "" });
        if (res3) {
            res3 = JSON.parse(res3?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null)) || {};
            // //console.log('index_benchmark_ohlc', res3);
            res3 = res3?.map(p => {
                return { 'Date': p.timestamp, 'price_adj_split': p.close, 'volume_adj': p.volume }
            })
            setIndexBenchmarkOHLC(res3)
        }
    }

    const getRangeAnalysis = async () => {
        setAllRangeAnalysisData({});
        let SYMBOL = type?.value === 5 ? String(selectedInstrument?.Id) : type.value === 4 ? selectedInstrument?.symbol || selectedInstrument?.nse_symbol : ''
        const obj = {
            'symbol': SYMBOL,
            'end_date': moment().format('YYYY-MM-DD'),
            frequency: null,
            'indices': type?.value === 5
        }
        let result = await AdminServicesPY.getRangeAnalysis({ 'params': JSON.stringify(obj) }).then(r => r);
        if (result?.data?.['api-status'] !== 'error') {
            setAllRangeAnalysisData(result?.data?.[SYMBOL]);
        } else {
            setAllRangeAnalysisData({});
        }
    }

    const getChartDataDependingOnBM = async () => {
        try {
            if (type.value === 4) {
                //stocks
                var data = {
                    "symbol": selectedInstrument?.symbol,
                    "mintbox_bm_id": selectedBenchmark[0]?.benchmark_id,
                    "instrument_type": type.value
                }

                var res11 = await AdminServicesPY.getInstrumentPerfCharts(data).then((r) => r)
                let d1 = decodeData(res11?.data || "");
                setperfChartData(d1 || {})

            } else if (type.value === 1) {
                //etf
                var data = {
                    "symbol": selectedInstrument?.nse_symbol,
                    "plan_id": selectedInstrument?.plan_id,
                    "mintbox_bm_id": selectedBenchmark[0]?.benchmark_id,
                    "instrument_type": type.value
                }

                var res1 = await AdminServicesPY.getInstrumentPerfCharts(data).then((r) => r)
                let d1 = decodeData(res1?.data || "");
                setperfChartData(d1 || {})
            } else if (type.value === 2) {
                //mf
                var data = {
                    "plan_id": selectedInstrument?.plan_id,
                    "mintbox_bm_id": selectedBenchmark[0]?.benchmark_id,
                    "instrument_type": type.value
                }

                var res1 = await AdminServicesPY.getInstrumentPerfCharts(data).then((r) => r)
                let d1 = decodeData(res1?.data || "");
                // //console.log("ZXC",d1)
                setperfChartData(d1 || {})

            }
        } catch (error) {
            setperfChartData({})
        }
    }
    const getChartDataNotDependingOnBM = async () => {
        if (type.value === 4) {
            try {
                var data = {
                    "symbol": selectedInstrument?.symbol,
                    "mintbox_bm_id": selectedBenchmark[0]?.benchmark_id,
                    "instrument_type": type.value
                }
                var res = await AdminServicesPY.getInstrumentOtherCharts(data).then((r) => r)
                let d = decodeData(res?.data || "");
                //console.log('pricechartdata', d);
                setPriceChartData(d || {})
            } catch (error) {
                setPriceChartData({})
            }
            if (isWidget) {
                try {
                    let res1 = await apiHelperPY({ apiName: 'getHistoricalCharts', data: { symbol: selectedInstrument?.symbol }, saveResponse: '' })
                    if (res1) {
                        res1 = await JSON.parse(res1.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))
                        setHistoricalCharts(res1)
                    }
                    else {
                        setHistoricalCharts({})
                    }
                } catch (error) {
                    setHistoricalCharts({})
                }
            }
        } else if (type.value === 1) {
            //etf
            try {
                var data = {
                    "symbol": selectedInstrument?.nse_symbol,
                    "plan_id": selectedInstrument?.plan_id,
                    "mintbox_bm_id": selectedBenchmark[0]?.benchmark_id,
                    "instrument_type": type.value
                }
                var res = await AdminServicesPY.getInstrumentOtherCharts(data).then((r) => r)
                let d = decodeData(res?.data || "");
                setPriceChartData(d || {})
            } catch (error) {
                setPriceChartData({})
            }
        }
    }

    const getScreenerMetricData = async () => {
        setSnapshotMetricsLoading(true);
        var screener_datas = await screenerMetricData(type, 0, 1, 1)
        setScreenerMetricDescData(screener_datas?.screenerMetricDescData)
        setscreenerFinalData(screener_datas?.screenerFinalData);
        setscreenerMetric(screener_datas?.screenerMetricData);
        let customCols = userCustomCols;
        if (user?.isLogged && userCustomCols === undefined) {
            customCols = await apiHelper({ apiName: "getCustomColumns", data: user?.id, saveResponse: "sessionStorage" });
            dispatch(updateCustomColumns(customCols))
        }
        let cols = null;
        if (customCols) {
            cols = customCols?.find((el) => el?.InstrumentType === type?.strVal?.toUpperCase());
            setUserCustomColumns(cols);
        }
        setSnapshotMetricsLoading(false);
    }
    const getStockD = async (symbol = "RELIANCE") => {
        var fundData = await AdminServicesPY.getStocksColData({ stocks: [symbol], cols: [], all_cols: true }).then(r => r)
        try {
            fundData = JSON.parse(fundData?.data)
            fundData = fundData?.map((fund) => {
                return {
                    ...fund,
                    // "gdf_symbol": fund?.nse_series === 2 ? fund?.symbol + ".BE" : fund?.nse_series === 3 ? fund?.symbol + ".BZ" : fund?.symbol
                    "gdf_symbol": fund?.symbol
                }
            })?.[0]
        } catch (error) {
            fundData = {}
        }

        return fundData
    }

    const setDefaultInstrument = async ({ allList }) => {
        // //console.log(allList)
        if (type.value === 5) {
            setselectedInstrument(allList?.filter((i) => i.Id === 0)[0])
        } else if (type.value === 4) {
            var dd = await getStockD() // default Reliance
            setselectedInstrument(dd)
        } else if (type.value === 1) {
            setselectedInstrument(allList.filter((i) => i.nse_symbol === "NIFTYBEES")[0])
        } else {
            let a = allList?.filter((i) => i['primary_category_name'] === 'Equity')
            a = [...a]?.sort((x, y) => { return x['cagr_3y'] ? y['cagr_3y'] - y['cagr_3y'] : 1 })
            setselectedInstrument(a?.[0])
        }
    }

    const initializeAllVariables = async () => {
        if (type.value !== 5) {
            getScreenerMetricData();
        }
        const all = allInstrumentsListData?.length ? allInstrumentsListData : await fetchFundDataWithoutFilter(type.value)
        if (isWidget) {
            await setDefaultInstrument({ allList: all })
        }
    }
    React.useEffect(() => {
        initializeAllVariables();
    }, [])

    React.useEffect(() => {
        if (Object.keys(selectedInstrument || {})?.length) {
            // //console.log("ARPI", selectedInstrument)
            if (isWidget) {
                setperfChartData(undefined)
                setPriceChartData({})
                setHistoricalCharts({})
                setLiveStateData([])
            }
            if ((selectedInstrument?.gdf_symbol !== undefined)) {
                if (webSocket.current?.readyState !== 1) {
                    if (isMarketOpen() && type.value !== 2 && !isLocal) {
                        ////console.log('caling socket logic')
                        socketlogic()
                    } else {
                        ////console.log("loading 1d chart")
                        loadPriceChartFromAPI()
                    }
                }
                else if (isLocal) {
                    ////console.log("loading 1d chart")
                    loadPriceChartFromAPI();
                }
            }
            if (type.value !== 5) {
                getChartDataNotDependingOnBM() // for stocks, etf and mf
                getChartDataDependingOnBM() // for stocks, etf and mf
            } else {
                getIndexClose(); // for index only
            }
            if ([4, 5].includes(type.value)) { // for stock and index only
                if (isWidget) {
                    getIndicators();
                }
                if (!hidePriceChart) { // dont call for performance chart
                    getRangeAnalysis();
                }
            }
        }
    }, [selectedInstrument]);

    React.useEffect(() => {
        if (Object.keys(selectedBenchmark)?.length) {
            if (type.value === 5) {
                getBenchmarkOHLC()
            } else {
                getChartDataDependingOnBM()
            }
        }

    }, [selectedBenchmark])

    const RangeAnalysisComponent = () => {

        const ltp = (type?.value === 5 && String(selectedInstrument?.IndexName)?.startsWith('SHI:')) ? customIndexPrice?.currentLTP : GetLTP({ fundData: selectedInstrument, symbol: type.value === 5 ? selectedInstrument?.Id : selectedInstrument?.symbol })

        const textFormat = {
            '1D': '1 Day',
            '1W': '1 Week',
            '1M': '1 Month',
            '3M': '3 Months',
            '6M': '6 Months',
            '1Y': '1 Year',
            '2Y': '2 Years',
            '3Y': '3 Years',
            '5Y': '5 Years',
            '10Y': '10 Years',
            '20Y': '20 Years'
        }

        return <div style={{ display: 'grid', gridTemplateColumns: isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr', gap: '1rem', marginBottom: '1rem' }}>
            {
                ['1W', '1M', '1Y', '5Y']?.map((t, i) => {
                    const record = allRangeAnalysisData?.[t]?.[0]
                    let close = ltp || record?.close
                    return <div className="shadowXS" style={{
                        border: '1px solid var(--grey3)',
                        padding: "1rem 1rem 0",
                        borderRadius: '5px'
                    }} key={makeid(i + 1)}>
                        <div className="textESM dark3 w-500 pb-3">{textFormat[t]} returns: <span style={{ whiteSpace: 'nowrap' }}>{!record ? ' -' : RedGreenText({ text: DecimalValueFormat({ num: record?.absolute_returns_pct, decimals: 2, suffix: ' %' }) || '-', value: record?.absolute_returns_pct, arrow: true })}</span></div>
                        {
                            record ?
                                <OHLC style={{ padding: "1rem 1rem" }} open={record?.open} high={Math.max(close, record?.high)} low={Math.min(close, record?.low)} close={close} />
                                :
                                <div style={{ height: '3rem' }}></div>
                        }
                    </div>
                })
            }
        </div>
    }

    let items = type?.value === 5 ? [
        !hidePriceChart && {
            label: `Price & Volume`,
            heading: `Price & Volume`,
            value: '3',
            key: '3',
            noHeader: hidePerformanceChart,
            children: <span>
                {indexOHLC?.length > 0 ?
                    <PriceVolumeChart type={type} fundData={selectedInstrument} isOneDay={liveStateData} chartData={JSON.stringify(indexOHLC)} isWidgit={isWidget} />
                    : <div style={{ height: "21rem" }}><ActivityLoaderMintbox /></div>}
                <RangeAnalysisComponent />


            </span>,
        },
        (isMobile && !isWidget && !hidePriceChart) && { // only for mobile detail page
            label: "Metrics",
            heading: "Metrics",
            key: '4',
            value: '4',
            children: <span>
                {indexMetricsComponent}
            </span>
        },
        !hidePerformanceChart && {
            value: '1',
            key: '1',
            children: <span>
                {(indexOHLC?.length > 0 && indexBenchmarkOHLC?.length > 0) ?
                    <IndexPerformanceChart
                        fundData={selectedInstrument}
                        benchData={selectedBenchmark}
                        onBenchChange={(e) => {
                            setselectedBenchmark(e)
                        }}
                        indexPriceChartData={indexOHLC}
                        indexBenchmarkPriceChartData={indexBenchmarkOHLC}
                    /> : <div style={{ height: "21rem" }}><ActivityLoaderMintbox /></div>}
            </span>,
            heading: `Performance`,
            label: `Performance`,
        },
        isWidget && {
            label: `Technicals`,
            value: '300',
            key: '300',
            children: <span>
                <TechnicalsSummary fundData={selectedInstrument} allIndicatorData={allIndicatorData} loadingTechnicalsIndicators={loadingTechnicalsIndicators} onRefresh={() => getIndicators()} />
            </span>,
        }
    ] :
        type.value === 4 ? [
            !hidePriceChart && {
                key: '3',
                value: '3',
                label: `Price & Volume`,
                heading: `Price & Volume`,
                noHeader: hidePerformanceChart,
                children: <span>
                    {Object.keys(priceChartData).length > 0 ?
                        <PriceVolumeChart type={type} fundData={selectedInstrument} isOneDay={liveStateData} chartData={JSON.stringify(priceChartData?.priceVol)?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null)} isWidgit={isWidget} />
                        : <div style={{ height: "21rem" }}><ActivityLoaderMintbox /></div>}
                    <RangeAnalysisComponent />
                </span>,
            },
            !hidePerformanceChart && {
                key: '1',
                value: '1',
                label: 'Performance',
                heading: isMobile ? `Performance` : `Stock price performance`,
                subHeading: "Performance is calculated assuming reinvestment of dividends (if any)",
                children: <span>
                    {perfChartData === undefined ? <div style={{ height: "21rem" }}><ActivityLoaderMintbox /></div> : Object.keys(perfChartData).length > 0 ?
                        <PerfBenchChart
                            benchName={selectedBenchmark[0]?.benchmark_name}
                            benchData={selectedBenchmark}
                            onBenchChange={(e) => {
                                setselectedBenchmark(e)
                            }}
                            fundData={selectedInstrument}
                            chartData={JSON.parse(typeof (perfChartData?.perf) === "string" ? perfChartData?.perf?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null) : JSON.stringify(perfChartData?.perf)?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))?.total_returns?.prices}
                        /> : <div style={{ height: "25.35rem" }}><NoDataFound message='Performance data not available' /></div>}
                </span>,
            },
            isWidget && {
                key: '2',
                value: '2',
                label: `Fundamentals`,
                heading: `Fundamentals`,
                children: <span>
                    {Object.keys(historicalCharts).length > 0 ?
                        <Fundamentals screenerMetricDescData={screenerMetricDescData} historicalCharts={historicalCharts} /> : <div style={{ height: "25.35rem" }}><ActivityLoaderMintbox /></div>}
                </span>,
            },

            isWidget && {
                key: '300',
                value: '300',
                label: `Technicals`,
                heading: `Technicals`,
                children: <span>
                    <TechnicalsSummary fundData={selectedInstrument} allIndicatorData={allIndicatorData} loadingTechnicalsIndicators={loadingTechnicalsIndicators} onRefresh={() => getIndicators()} />
                </span>,
            },
            ((isWidget || isMobile) && !hidePriceChart) && {
                key: "4",
                value: "4",
                label: "Metrics",
                heading: "Metrics",
                children: <span>
                    <DashboardChartMetrics fundData={selectedInstrument} screenerFinalData={screenerFinalData} type={type} screenerMetric={screenerMetric}
                        screenerMetricDescData={screenerMetricDescData} userCustomColumns={userCustomColumns} updateUserCustomCols={updateUserCustomCols}
                        snapshotMetricsLoading={snapshotMetricsLoading} />
                </span>
            }
        ] : type.value === 1 ? [
            {
                key: '3',
                value: '3',
                label: `Price & Volume`,
                heading: `Price & Volume`,
                children: <span>
                    {Object.keys(priceChartData).length > 0 ?
                        <PriceVolumeChart type={type} fundData={selectedInstrument} isOneDay={liveStateData} chartData={JSON.stringify(priceChartData?.priceVol)?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null)} isWidgit={isWidget} /> : <div style={{ height: "21rem" }}><ActivityLoaderMintbox /></div>}
                </span>,
            },
            {
                key: '1',
                value: '1',
                label: `Performance`,
                heading: `ETF performance`,
                children: <span>
                    {perfChartData === undefined ? <div style={{ height: "21rem" }}><ActivityLoaderMintbox /></div> : Object.keys(perfChartData).length > 0 ?
                        <PerfBenchChart
                            // showLiveTickerVal={true}
                            benchData={selectedBenchmark}
                            benchName={selectedBenchmark[0]?.benchmark_name}
                            onBenchChange={(e) => {
                                setselectedBenchmark(e)
                            }}
                            fundData={selectedInstrument}
                            chartData={JSON.parse(typeof (perfChartData?.perf) === "string" ? perfChartData?.perf?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null) : JSON.stringify(perfChartData?.perf)?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))?.prices}
                        /> : <div style={{ height: "25.35rem" }}><ActivityLoaderMintbox /></div>}
                </span>,
            },
            {
                key: '2',
                value: '2',
                label: `Price vs NAV`,
                heading: `Price vs NAV`,
                children: <span>
                    {Object.keys(priceChartData).length > 0 ?
                        <PriceVsNAVChart
                            fundData={selectedInstrument}
                            chartData={{
                                data: JSON.stringify(priceChartData?.priceNAV)?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null)
                            }} /> : <div style={{ height: "25.35rem" }}><ActivityLoaderMintbox /></div>}
                </span>,
            },

            isWidget && {
                key: "4",
                value: "4",
                label: "Metrics",
                heading: "Metrics",
                children: <span>
                    <DashboardChartMetrics fundData={selectedInstrument} screenerFinalData={screenerFinalData} type={type} screenerMetric={screenerMetric}
                        screenerMetricDescData={screenerMetricDescData} userCustomColumns={userCustomColumns} updateUserCustomCols={updateUserCustomCols}
                        snapshotMetricsLoading={snapshotMetricsLoading} />
                </span>
            }
        ] : type.value === 2 ? [
            {
                key: '1',
                value: '1',
                label: `Performance`,
                heading: `Mutual Fund performance`,
                children: <span>
                    {perfChartData === undefined ? <div style={{ height: "21rem" }}><ActivityLoaderMintbox /></div> : Object.keys(perfChartData).length > 0 ?
                        <PerfBenchChart
                            // showLiveTickerVal={true}
                            benchData={selectedBenchmark}
                            benchName={selectedBenchmark[0]?.benchmark_name}
                            onBenchChange={(e) => {
                                setselectedBenchmark(e)
                            }}
                            fundData={selectedInstrument}
                            chartData={JSON.parse(typeof (perfChartData?.perf) === "string" ? perfChartData?.perf?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null) : JSON.stringify(perfChartData?.perf)?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null))?.prices}
                        /> : <div style={{ height: "25.35rem" }}><NoDataFound message='Performance data not available' /></div>}
                </span>,
            },
            isWidget && {
                label: "Metrics",
                value: "4",
                key: "4",
                children: <span>
                    <DashboardChartMetrics fundData={selectedInstrument} screenerFinalData={screenerFinalData} type={type} screenerMetric={screenerMetric}
                        screenerMetricDescData={screenerMetricDescData} userCustomColumns={userCustomColumns} updateUserCustomCols={updateUserCustomCols}
                        snapshotMetricsLoading={snapshotMetricsLoading} />
                </span>
            }
        ] : []

    items = items?.filter((el) => el);

    const [selectedTab, setselectedTab] = React.useState(items?.[0]?.value);

    return <Card style={{ border: isWidget || hidePriceChart ? "none" : "1px solid var(--grey3)" }} bodyStyle={{ padding: hidePriceChart ? 0 : '', paddingBottom: 0, }} className="mobCardBorderRadius0">
        <div id='symbolid' style={{ position: "fixed", top: "-10vh" }}>{selectedInstrument?.gdf_symbol}</div>
        {isWidget && <div className={subHeading ? "d-flex flex-md-row flex-column" : 'd-flex'} style={{
            alignItems: subHeading && !isMobile ? "flex-end" : 'flex-start',
            gap: "0.75rem"
        }}>
            {header.length > 0 && <div style={{ flex: 1 }}>
                <CardHeader heading={header} headingType={2} />
                {subHeading && <div className="customTabsSubHeading" style={{ marginBottom: 0 }}>{subHeading}</div>}
            </div>}
            <span style={{}}>
                <InstrumentListSearch allInstrumentsList={allInstrumentsListData} placeholder={`Look up ${type.dname} for quick overview`} type={type.value} onChange={async (e) => {
                    setselectedInstrument(type.value === 4 ? await getStockD(e[0]?.symbol) : e[0])
                }} align={subHeading && isMobile ? "left" : 'right'} />
            </span>
        </div>}
        {isWidget && <Divider style={{ margin: "0.75rem 0rem" }} />}
        <div style={{ display: "flex", flexDirection: 'var(--flexDirection)', gap: '1rem', justifyContent: 'space-between' }}>
            {isWidget ? ((selectedInstrument?.proper_name || selectedInstrument?.basic_name) !== undefined ? <div style={{ display: "flex", flex: 1, gap: "1rem" }}>
                <AMCIcon amcName={selectedInstrument?.symbol || selectedInstrument?.amc_full_name} fundType={type.value} height='3.2rem' width='3.2rem' type={type.value === 5 ? 'circle' : 'square'} showTricolor={isCustomIndex ? false : type.value === 5}
                    bseTicker={selectedInstrument?.bse_ticker} showSharpelyLogo={isCustomIndex} />
                <span className='textMD w-700' style={{ flex: 1, color: 'var(--black)', display: "flex", flexDirection: "column", gap: "0.3rem" }}>
                    <span style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                        <Link to={isWidget ? type.basePath + `${type.value === 5 ? '/index-detail/' + selectedInstrument?.proper_name?.replaceAll(' ', '-') + '/' + selectedInstrument?.Id : type.value === 4 ? '/stock-detail/' + selectedInstrument?.proper_name?.replaceAll(' ', '-') + '/' + selectedInstrument?.gdf_symbol : '/fund-detail/' + selectedInstrument?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + selectedInstrument?.plan_id}` : '#'} onClick={(e) => {
                            if (!isWidget) e.preventDefault();
                        }} >
                            <h1 style={{ cursor: isWidget ? "pointer" : "default", alignItems: "center", display: "flex", gap: "0.5rem" }}
                                className='black textMD w-700 margin0'>
                                {selectedInstrument?.proper_name || selectedInstrument?.basic_name}
                                {isWidget === true && <img loading="lazy" alt="" src={`${flaticonSvg}fi-rr-arrow-up-right-from-square.svg`} style={{ height: "1rem" }} />
                                }
                            </h1>
                        </Link>
                    </span>
                    <span style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                        {type.value !== 2 ? isCustomIndex ? <PriceDisplay priceObj={customIndexPrice} format={5} /> :
                            <StockLivePrice fundData={selectedInstrument} format={5} symbol={selectedInstrument?.symbol || selectedInstrument?.nse_symbol} /> : <span className='dark3'>NAV: {CurrencyFormat(selectedInstrument?.latest_nav)}</span>}
                    </span>

                </span></div> :
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', flex: 1 }}> <Skeleton.Input size='small' /> <Skeleton.Input size='small' /></div>) :
                (isMobile || items?.find((el) => el.value === selectedTab)?.noHeader) ? <></> : <div style={{ display: "flex", flex: 1, gap: "1rem" }}>
                    <div>
                        <CardHeader heading={items?.find((el) => el.value === selectedTab)?.heading} headingType={2} hideHeaderBlueLine textClassName={isMobile ? "textMD w-500" :
                            'textXL w-700'} />
                        <div className='textSM dark3' style={{ marginTop: isMobile ? "0.75rem" : "0rem" }}>{items?.find((el) => el.value === selectedTab)?.subHeading}</div>
                    </div>
                </div>
            }
            {items?.length > 1 && <div>
                <CustomSegmentedTab
                    flexVal="unset"
                    isBlock={false}
                    mobileStyles={{ marginBottom: '1rem' }}
                    value={selectedTab}
                    onChange={(e) => {
                        setselectedTab(e)
                    }}
                    options={items}
                />
            </div>}
        </div>
        {
            isMobile && items?.find((el) => el.value === selectedTab)?.subHeading ? <div style={{ display: "flex", flex: 1, gap: "1rem" }}>
                <div>
                    <div className='textSM dark3' style={{ marginBottom: "1rem" }}>{items?.find((el) => el.value === selectedTab)?.subHeading}</div>
                </div>
            </div> : <></>
        }
        {!items?.find((el) => el.value === selectedTab)?.noHeader && <Divider style={{ margin: "0.75rem 0rem" }} className='display-desktop' />}
        {items.filter((i) => i.value === selectedTab)[0]?.children}
        <DashboardChartNotes type={type} fundData={selectedInstrument} chartType={Number(selectedTab)} />
    </Card>
}

export default DashboardChartComponent;