import { Progress } from "antd";
import { DecimalValueFormat } from "./DecimalValueFormat";

const TableProgressBarFormat = ({ num = 0 }) => {
    return <div style={{ display: 'flex', gap: '1rem', justifyContent: 'end', marginRight: '0.5rem' }}>
        <div style={{ flex: 1 }}>{DecimalValueFormat({ num: num, suffix: "%", placeholder: "-" })}</div>
        <Progress percent={Number(num)} showInfo={false} trailColor={'var(--grey2)'} strokeColor={"var(--primary)"}
            size={['7rem', 14]} type="line" style={{ borderRadius: '4px', width: '7rem', marginRight: '-4px' }} />
    </div>
}

export default TableProgressBarFormat;