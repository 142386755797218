import { Card, Divider, Dropdown, Skeleton } from "antd";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import AMCIcon from "Components/AMCIcon";
import { CardHeader } from "Components/atoms/SmallComponents";
import { CurrencyFormat } from "Components/CurrencyFormat";
import { InstrumentListSearch } from "Components/CustomComponents/InstrumentListSearch";
import InstrumentListTable from "Components/InstrumentListTable";
import NewFundCard from "Components/NewFundCard";
import PerformanceChartCompare from "Components/PerformanceChartCompare";
import Head from "Layout/Head";
import { screenerMetricData, stock_universe, useGetAssetType } from "Libs/utils";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import { HiChevronRight } from "react-icons/hi";
import { MdOutlineCancel } from "react-icons/md";
import { useLocation, useNavigate } from "react-router";
import AdminServicesPY from "Services/api/AdminServicesPY";
import apiHelperPY from "Services/api/ApiHelperPY";

const Compare = (props) => {

    const isMobile = window.innerWidth <= 760;
    const fundColors = ['#007AFF', '#83BF6E', '#555770', '#FFD966', '#FF55BB', '#0062CC', '#AA55FF', '#FF9C55', '#FF6A55', '#1A1D1F', '#00FFFF', '#DBFF00', '#00FFFF', '#DBFF00', '#FF5733', '#FF884D', '#FFAB62', '#FFCE7C', '#FFF3A0', '#FFD662', '#FFD94E', '#FFE23E', '#FFF244', '#A8FF39', '#4CFF44', '#34FF63', '#36FF8C', '#41FFB5', '#42FFE6']
    const type = useGetAssetType()// 1 = etf, 2 = mf, 4 = stocks
    const { state } = useLocation()
    const [stocks, setStocks] = React.useState([])
    const [allStocks, setAllStocks] = React.useState([])
    const [popularStocks, setPopularStocks] = React.useState([])
    const [screenerMetric, setScreenerMetric] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [loadingChart, setLoadingChart] = React.useState(true)
    const [chartData, setChartData] = React.useState([])
    const [selectedChartInt, setSelectedChartInt] = React.useState('')
    const [indexCode, setIndexCode] = React.useState('is_nifty_50')
    const navigate = useNavigate()
    const dataTableColumns = type.value === 4 ? [
        {
            "title": "Name",
            "dataIndex": "proper_name",
            render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }} onClick={() => navigate({ pathname: type.basePath + `${type.value === 4 ? '/stock-detail/' + record?.proper_name?.replaceAll(' ', '-') + '/' + record.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + record.plan_id}` })}>
                <AMCIcon amcName={record.symbol} fundType={type.value} height='1.2rem' width="1.2rem" bseTicker={record?.bse_ticker} />
                <div>
                    <div className="textSM black">{record?.proper_name}</div>
                    {/* <div className="text-left textXS ">{stockSectorCLF?.find(s => s.industry_code === record.industry_code)?.industry_desc}</div> */}
                </div>
            </div>,
            width: 230,
            fixed: 'left',
            show: true
        }
    ]
        :
        [
            {
                "title": "Name",
                "dataIndex": "basic_name",
                render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }} onClick={() => navigate({ pathname: type.basePath + `${type.value === 4 ? '/stock-detail/' + record?.proper_name?.replaceAll(' ', '-') + '/' + record.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '/' + record.plan_id}` })}>
                    <AMCIcon amcName={record.amc_full_name} height='1.2rem' width="1.2rem" />
                    <div>
                        <div className="textSM black">{record?.basic_name?.replace(" - Direct Plan", "")}</div>
                        <div className="text-left textXS ">{record?.category_name}</div>
                    </div>
                </div>,
                width: 230,
                fixed: 'left',
                show: true
            }
        ]

    const resetState = () => {
        setLoading(true);
        setStocks([]);
        setPopularStocks([]);
    }
    React.useEffect(() => {
        resetState();
        const dum = async () => {
            setLoading(true)
            setStocks(state?.stocks || [])
            var screener_datas = await screenerMetricData(type);
            console.log(screener_datas);
            setScreenerMetric(screener_datas?.screenerMetricData);
            if (type.value === 4) {
                let indexCols = stock_universe?.map((el) => el?.value)?.filter((el) => el !== 'all');
                let result = await AdminServicesPY.getStocksColData({ stocks: screener_datas?.allFunds?.map((el) => el?.symbol), cols: [...indexCols, 'vol_surge'], all_cols: false }).then(r => r);
                result = JSON.parse(result?.data)
                result = result?.map((fund) => {
                    return {
                        ...screener_datas?.allFunds?.filter((ii) => ii?.symbol === fund?.symbol)?.[0],
                        ...fund,
                        // "gdf_symbol": fund?.nse_series === 2 ? fund?.symbol + ".BE" : fund?.nse_series === 3 ? fund?.symbol + ".BZ" : fund?.symbol
                        "gdf_symbol": fund?.symbol
                    }
                })
                setAllStocks(result);
                console.log(result)
            }
            else {
                setAllStocks(screener_datas?.allFunds)
            }
            setLoading(false);
        }
        dum();
    }, [type.value])

    React.useEffect(() => {
        if (type.value !== 4) {
            setPopularStocks(allStocks?.filter(x => type.value === 4 ? (!stocks.some(y => y.symbol === x.symbol)) : (!stocks.some(y => y.isin_code === x.isin_code))).sort((a, b) => {
                if (type.value === 2) {
                    return b['aum'] - a['aum']
                }
                return b['21d_vol'] - a['21d_vol']
            }).slice(0, 25));
        }
    }, [stocks, allStocks])

    React.useEffect(() => {
        getCompData()
    }, [stocks])

    const getCompData = async () => {
        if (stocks.length >= 2) {
            setLoadingChart(true)
            await getChartData(stocks.map(s => type.value === 4 ? s.symbol : s.isin_code))
            setLoadingChart(false)
        }
    }

    const getChartData = async (isins) => {
        let res0 = await apiHelperPY({ apiName: 'getFundsComparisionData', data: { type: type.value, data: { isins: isins } }, saveResponse: '' })
        if (res0) {
            // console.log("getFundsComparisionData", res0)
            setChartData(res0)
        }
        else {
            // setApiError(true)
            setChartData([])
        }
    }
    const onDeleteFund = (index) => {
        setStocks(stocks?.filter((f, i) => i !== index))
    }

    const addToCompare = (f) => {
        //console.log(f)
        // if (stocks?.length < 4) {
        if (stocks?.length === 25) {
            toast.error(`You can select a maximum of 25 ${type.dname} for comparison.`)
        }
        else if (stocks.some(s => type.value === 4 ? s.symbol === f.symbol : s.isin_code === f.isin_code)) {
            // already exists
        } else {
            setStocks([...stocks, f])
        }
        // }
    }

    const ItemLoader = () => (
        <div style={{ padding: "1rem", background: "var(--blue2)", minWidth: "240px", minHeight: "6rem", borderRadius: "var(--borderRadius)", position: "relative" }}>
            <div className='d-flex flex-column justify-content-center align-items-center text-center w100' style={{ gap: "1rem", overflow: "hidden" }}>
                <Skeleton.Avatar active style={{ width: "3.125rem", height: "3.125rem" }} />
                <Skeleton.Button active style={{ height: "0.75rem", width: '170px' }} />
                <Skeleton.Button active style={{ height: "0.75rem", width: '120px' }} />
            </div>
            <div className='d-flex align-items-center justify-content-between' style={{ marginTop: "1.25rem" }}>
                <Skeleton.Button active style={{ height: "0.75rem", width: '50px' }} />
                <Skeleton.Button active style={{ height: "0.75rem", width: '50px' }} />
            </div>
            <div className='d-flex align-items-center justify-content-between' style={{ marginTop: "0.5rem" }}>
                <Skeleton.Button active style={{ height: "0.75rem", width: '50px' }} />
                <Skeleton.Button active style={{ height: "0.75rem", width: '50px' }} />
            </div>
        </div>
    )

    const SuggestedInstruments = ({ title, symbols }) => {

        const funds = symbols.map(x => allStocks.find(s => (type.value === 4 && s.symbol === x) || (type.value !== 4 && s.plan_id === x)))
        const Loader = () => {
            return (
                new Array(5).fill(1)?.map((ele, i) => (
                    <>
                        <ItemLoader />
                        {i !== 4 &&
                            <span className='textXS w-500'>v/s</span>
                        }
                    </>
                ))
            )
        }
        return (
            <Card className="mobCardBorderRadius0">
                <div className='d-flex justify-content-between' style={{ flexDirection: isMobile ? "column" : "row", gap: "0.75rem", alignItems: isMobile ? "flex-start" : "center" }} >
                    <CardHeader heading={title} headingType={2} />
                    <div className='d-flex align-items-center textSM w-500' onClick={() => {
                        setLoadingChart(true)
                        setStocks(funds);
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth"
                        })
                    }} style={{ color: "var(--primary)", cursor: "pointer" }}>
                        Detailed comparison <HiChevronRight />
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '1.25rem', overflowX: 'scroll', marginTop: '1rem', alignItems: 'center' }}>
                    {loading ?
                        <Loader /> :
                        funds?.filter(ele => ele).map((f, i) => (
                            <>
                                <NewFundCard f={f} type={type} showQVM={true} />
                                {i !== (funds.length - 1) &&
                                    <span className='textXS w-500'>v/s</span>}
                            </>
                        ))}
                </div>
            </Card>
        )
    }

    const ListLoader = () => {
        let items = new Array(5).fill(1);
        if (isMobile) {
            return (
                <div className="d-flex scrollbox" style={{ gap: "1rem", overflow: "scroll", maxWidth: "100%" }}>
                    {items?.map((f, i) => {
                        return (
                            <div className='d-flex align-items-center' style={{
                                gap: "0.5rem", padding: "0.5rem", border: "1px solid var(--grey2)",
                                borderRadius: "0.75rem"
                            }} key={i}>
                                <Skeleton.Input active={(i + 1) % 2} style={{ height: "1rem", width: "5rem" }} />
                                <Skeleton.Avatar shape='square' style={{ height: "1rem", width: '1rem', borderRadius: "var(--borderRadius)" }} />
                            </div>
                        )
                    })}
                </div>
            )
        }
        return (
            <div className=''>
                {items?.map((ele, i) => (
                    <div key={i + 1} className='d-flex align-items-center justify-content-between' style={{ gap: "1rem", padding: "1rem 0" }} >
                        <Skeleton.Input active={(i + 1) % 2} style={{ height: "1rem" }} />
                        <Skeleton.Avatar shape='square' style={{ height: "1rem", width: '3rem', borderRadius: "var(--borderRadius)" }} />
                    </div>
                ))}
            </div>
        );
    };

    useEffect(() => {
        setSelectedChartInt('30D')
    }, [stocks])

    const chart = JSON.parse(chartData?.prices?.[selectedChartInt]?.data || '[]')?.reverse()?.[0]

    const metaItems = {
        1: {
            title: "Best ETFs Comparison Tool | sharpely ETF",
            description: "Enhance your investment strategy with our ETF research tools, offering in-depth analysis of ETF performance and comprehensive data. Stay informed and make data-driven decisions with sharpely's ETF research expertise."
        },
        2: {
            title: "Compare Mutual Funds | Returns | Performance | MF Research Tools",
            description: "sharpely provides a Mutual Fund comparison tool that will help you compare multiple mutual funds on performance, expenses, returns, risks, and portfolio allocation in India"
        },
        4: {
            title: "Compare the Best Stocks with sharpely Stocks Comparison Tool",
            description: "Unlock the power of stock analysis and research with sharpely's high-quality stocks compare tools. Make informed investment decisions with our advanced stock research tools, helping you identify top-performing stocks for your portfolio."
        }
    }
    // console.log(chart, selectedChartInt)
    return (
        <React.Fragment>
            <Head title={metaItems?.[type?.value]?.title || "Compare"} description={metaItems?.[type?.value]?.description || `Compare ${type?.dname}`} />
            <div className="normalContainer">
                <div className="mainContainer">
                    <div className="rightContainer">
                        <Card className='mobCardBorderRadius0'>
                            <CardHeader heading={`Compare ${type.dname}`} headingType={1} />
                            {/* <Divider style={{ margin: "0.75rem 0rem 0rem" }} /> */}
                            <div style={{ marginBottom: stocks.length >= 2 ? "0.75rem" : "0", marginTop: "0.75rem" }}>
                                <InstrumentListSearch placeholder={`Search and add ${type.dname}`} type={type.value} isMultiple={true} selectedValues={stocks} onChange={(x) => {
                                    if (x.length > 25) {
                                        toast.error(`You can select a maximum of 25 ${type.dname} for comparison.`)
                                    }
                                    else {
                                        setStocks(x)
                                    }
                                }} maxSelected={25} isFullWidthInMob />
                            </div>
                            {stocks.length ? <>{stocks.length >= 2 ? isMobile ? <div className="d-flex scrollbox" style={{
                                gap: "1rem", overflow: "scroll", maxWidth: "100%"
                            }}>
                                {stocks.map((f, i) => {
                                    let val, change;
                                    if (type.value === 4) {
                                        val = chart?.[f?.symbol]
                                        change = chart?.[`ret_${f?.symbol}`]
                                    }
                                    else {
                                        val = chart?.[`fund_${f.plan_id}`]
                                        change = chart?.[`ret_fund_${f?.plan_id}`]
                                    }

                                    return (
                                        <div className='d-flex align-items-center' style={{
                                            gap: "0.5rem", padding: "0.5rem", border: "1px solid var(--grey2)",
                                            borderRadius: "0.75rem"
                                        }} key={i}>
                                            <div style={{ height: '0.6rem', width: '0.6rem', backgroundColor: fundColors[i] }}></div>
                                            <div className='textXS dark4 w-500' style={{ whiteSpace: "nowrap" }}>{type.value === 4 ? f?.proper_name :
                                                f?.basic_name?.replace(" - Direct Plan", "")}</div>
                                            <MdOutlineCancel onClick={() => { onDeleteFund(i) }} style={{ cursor: "pointer" }} />
                                        </div>
                                    )
                                })}
                            </div> : loadingChart ? <ListLoader /> : <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {stocks.map((f, index) => {
                                    let val, change;
                                    if (type.value === 4) {
                                        val = chart?.[f?.symbol]
                                        change = chart?.[`ret_${f?.symbol}`]
                                    }
                                    else {
                                        val = chart?.[`fund_${f.plan_id}`]
                                        change = chart?.[`ret_fund_${f?.plan_id}`]
                                    }

                                    return (<>
                                        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', width: '100%', padding: '1rem 0', borderTop: '1px solid var(--grey2)' }}>
                                            <div style={{ flex: 1, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                                                <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', width: '100%' }}>
                                                    <div style={{ height: '0.6rem', width: '0.6rem', backgroundColor: fundColors[index] }}></div>
                                                    <div className="textXS black" style={{ flex: 1, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                                                        {type.value === 4 ? f.proper_name : f.basic_name}
                                                    </div>
                                                </div>
                                                {val && change && (stocks.length >= 2) && < span className='textXS w-500' style={{ marginLeft: "1.1rem" }}>
                                                    {CurrencyFormat(val)} ({change} %)
                                                </span>}
                                            </div>
                                            <AiOutlineCloseCircle size={'1.25rem'} style={{ marginLeft: '1rem', flexShrink: 0 }} onClick={() => onDeleteFund(index)} />
                                        </div>
                                    </>)
                                })}
                            </div> : null}
                            </> : <></>}
                        </Card>
                        {isMobile ? null : type.value !== 4 ? <Card >
                            <CardHeader heading={`Popular ${type.dname}`} headingType={2} />
                            <Divider style={{ margin: "0.75rem 0rem 0" }} />
                            {loading ? <ListLoader /> : popularStocks.length ? <>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {popularStocks.map((f) => {
                                        return (<>
                                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', width: '100%', padding: '1rem 0', borderBottom: '1px solid var(--grey2)' }}>
                                                <AMCIcon amcName={type.value === 4 ? f.symbol : f.amc_full_name} fundType={type.value} height='1.15rem' width="1.15rem"
                                                    bseTicker={f?.bse_ticker} />
                                                <div className="textXS black" style={{ flex: 1 }}>{type.value === 4 ? f.proper_name : f.basic_name}</div>
                                                <AiOutlinePlusCircle size={'1.25rem'} style={{ marginLeft: '1rem' }} onClick={() => addToCompare(f)} />
                                            </div>

                                        </>)
                                    })}
                                </div>
                            </> : <></>}
                        </Card> : <Card>
                            <div className='' style={{ display: "flex", alignItems: "center" }}>
                                <CardHeader heading={'Select stocks'} headingType={2} />
                                {type.value === 4 && <Dropdown
                                    menu={{
                                        items: stock_universe,
                                        onClick: (e) => {
                                            setIndexCode(e.key)
                                        },
                                        selectable: true,
                                        defaultSelectedKeys: [indexCode],
                                    }}
                                    placement="bottomRight">
                                    <span className="textXS" style={{ cursor: 'pointer' }}>{stock_universe?.filter((i) => i.key === indexCode)[0]?.label} <FiChevronDown size={12} /></span>
                                </Dropdown>}
                            </div>
                            <Divider style={{ margin: "0.75rem 0rem 0" }} />
                            {loading ? <ListLoader /> : <>
                                <div style={{ display: 'flex', flexDirection: 'column', maxHeight: "30rem", overflowY: "auto" }} className='scrollbox'>
                                    {allStocks?.filter(ele => ele?.[indexCode] !== 0)?.map((f, index) => {
                                        return (<>
                                            <div style={{
                                                display: 'flex', gap: '0.5rem', alignItems: 'center', width: '100%', padding: '1rem 0',
                                                borderBottom: '1px solid var(--grey2)'
                                            }}>
                                                <AMCIcon amcName={type.value === 4 ? f.symbol : f.amc_full_name} fundType={type.value} height='1.15rem' width="1.15rem" bseTicker={f?.bse_ticker} />
                                                <div className="textXS black" style={{ flex: 1 }}>{type.value === 4 ? f.proper_name : f.basic_name}</div>
                                                <AiOutlinePlusCircle size={'1.25rem'} style={{ marginLeft: '1rem' }} onClick={() => addToCompare(f)} />
                                            </div>
                                        </>)
                                    })}
                                </div>
                            </>}
                        </Card>}
                    </div>
                    <div className="leftContainer">
                        {stocks.length >= 2 ? <>
                            <Card className='mobCardBorderRadius0'>
                                {loadingChart ? <ActivityLoaderMintbox margin="65px" message="Loading performance chart..." /> : type.value === 4 ?
                                    <PerformanceChartCompare type={type.value} fundColors={fundColors} fundNames={stocks?.map((f) => {
                                        var ob = {}
                                        ob[f.symbol] = f.proper_name
                                        return ob
                                    })} isin_code={stocks?.map((f) => { return f.symbol })} chartData={chartData?.prices} benchName={"df"}
                                        setSelectedChartInt={setSelectedChartInt} /> :
                                    <PerformanceChartCompare fundColors={fundColors} fundNames={stocks?.map((f) => {
                                        var ob = {}
                                        ob["fund_" + f.plan_id] = f.basic_name?.replace(" - Direct Plan", "")
                                        return ob
                                    })} isin_code={stocks.map((f) => { return f.plan_id })} chartData={chartData?.prices} benchName={"df"} setSelectedChartInt={setSelectedChartInt} />}
                            </Card>
                            <InstrumentListTable finalList={stocks} tableType={5} hideCheckBox={true} initialColumns={[...dataTableColumns,
                            ...screenerMetric.filter((item) => item.isInitial === true),]} tableHeader={'Detailed comparison'}
                                // showDeleteButton deleteBtnTooltipText='Clear all'
                                //     onDeleteBtnClick={() => {
                                //         setStocks([]);
                                //         window.scrollTo({
                                //             top: 0,
                                //             left: 0,
                                //             behavior: "smooth"
                                //         })
                                //     }} 
                                showToolsBtnsInTab showHeatmapBtn downloadTable showBubbleChartBtn={type?.value === 4} />
                        </> : <>
                            {type.value === 4 ? <>
                                <SuggestedInstruments title='Cruising through the auto market' symbols={['TATAMOTORS', 'MARUTI', 'M&M', 'BAJAJ-AUTO', 'ASHOKLEY']} />
                                <SuggestedInstruments title='Decoding the IT sector' symbols={['TCS', 'INFY', 'HCLTECH', 'WIPRO', 'LTIM']} />
                                <SuggestedInstruments title='Banking giants' symbols={['HDFCBANK', 'ICICIBANK', 'SBIN', 'KOTAKBANK', 'AXISBANK']} />
                                <SuggestedInstruments title='Pocket-friendly picks' symbols={['ZOMATO', 'MOTHERSON', 'IOC', 'GAIL', 'TATASTEEL']} />
                                <SuggestedInstruments title='Surging volume'
                                    symbols={allStocks?.sort((a, b) => (b.vol_surge - a.vol_surge))?.filter(ele => ele?.is_nifty_500 !== 0)?.slice(0, 25)?.map(ele => ele?.symbol) || []} />
                            </> : type.value === 1 ? <>
                                <SuggestedInstruments title='Following our market' symbols={[30710, 1171, 18653, 10843, 31376]} />
                                <SuggestedInstruments title='Navigating the debt market' symbols={[1787, 37659, 35400, 43078]} />
                                <SuggestedInstruments title='Gold buying made simple' symbols={[4957, 11314, 10382, 11347, 5341]} />
                                <SuggestedInstruments title='Navigating US investments from India' symbols={[12498, 41429, 41698, 41923]} />
                                <SuggestedInstruments title='Banking ETFs showdown' symbols={[2255, 28873, 29709, 40130, 40378]} />
                            </> : <>
                                <SuggestedInstruments title='Following our market' symbols={[15830, 16069, 15959, 16233, 41574]} />
                                <SuggestedInstruments title='Tax-efficient wealth creation' symbols={[15688, 31318, 15889, 40105, 17381]} />
                                <SuggestedInstruments title='Maximizing liquidity and safety' symbols={[16302, 16167, 15961, 15800, 16365]} />
                                <SuggestedInstruments title='Navigating US investments from India' symbols={[38228, 16027, 40997, 17160, 41260]} />
                            </>}
                        </>}
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};

export default Compare;