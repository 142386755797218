import { baseImgURL } from "Assets/Constant";
import { AppSection } from "./Sections/AppSection";
import { PricingSection } from "./Sections/PricingSection";
import { StockAnalysisToolsSection } from "./Sections/StockAnalysisToolsSection";
import { TopSection } from "./Sections/TopSection";
import { VideoSection } from "./Sections/VideoSection";

const StockReportLanding = () => {

    return (
        <main style={{ backgroundImage: "linear-gradient(180deg, #09203f 0%, #000 100%)", overflowX: "hidden" }}>
            <div style={{ minHeight: '100vh', width: "100%", display: "flex", flexDirection: "column" }}>
                <TopSection sectionType={2} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                <VideoSection type={3} />
            </div>
            <div className="">
                <img loading="lazy" alt="divider" src={`${baseImgURL}sharpely-asset/images/Home/sharpely-border.webp`} width={'100%'} height={'100%'} />
            </div>
            <div>
                <StockAnalysisToolsSection />
            </div>
            <div className="flipImage">
                <img loading="lazy" alt="divider" src={`${baseImgURL}sharpely-asset/images/Home/sharpely-border.webp`} width={'100%'} height={'100%'} />
            </div>
            <div style={{ width: "100%" }}>
                <PricingSection />
            </div>
            <div>
                <img loading="lazy" alt="divider" src={`${baseImgURL}sharpely-asset/images/Home/sharpely-border.webp`} width={'100%'} height={'100%'} />
            </div>
            <div>
                <AppSection />
            </div>
        </main>
    )
}

export default StockReportLanding;