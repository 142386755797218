import { Avatar, Dropdown } from "antd";
import db from "Libs/db";
import { updateStockPrices, updatesubscribedStocks } from "Libs/redux/StockLivePrice/reduxSlice";
import { updatePrevStockPrices } from "Libs/redux/StocksPrevPrices/reduxSlice";
import { updateUserData, updateWatchlists } from "Libs/redux/UserAuth/reduxSlice";
import { CgFileDocument } from "react-icons/cg";
import { FiBookmark, FiBriefcase, FiLogOut } from 'react-icons/fi';
import { IoIosArrowDown } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import moengage from "@moengage/web-sdk";

const User = ({ user }) => {

  const loc = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resetData = () => {
    dispatch(updatePrevStockPrices([]));
    dispatch(updateStockPrices([]));
    dispatch(updatesubscribedStocks({}));
    dispatch(updateWatchlists(undefined));
    dispatch(updateUserData(undefined));
  }

  const items = [
    {
      key: 'A',
      icon: <Avatar>
        {user?.name[0]}
      </Avatar>,
      label: (
        <Link className="" to="/user-profile">
          <div className="fontH2">{user?.name}</div>
          <div className="textXS">{user?.email}</div>
        </Link>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '1',
      icon: <IoSettingsOutline />,
      label: (
        <Link to="/user-profile" className="fontH2">
          Account settings
        </Link>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      icon: <FiBriefcase />,
      label: (
        <Link to="/investments" className="fontH2">
          Investments
        </Link>
      ),
    },
    {
      key: '3',
      icon: <FiBookmark />,
      label: (
        <Link to="/watchlist" className="fontH2">
          Watchlist
        </Link>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      danger: true,
      icon: <FiLogOut />,
      label: <Link to="/" onClick={() => {
        moengage.destroy_session();
        moengage.track_event('Logout')
        resetData();
        db.clear()
      }} className="fontH2">
        Sign out
      </Link>,
    },
  ];

  return (
    user !== undefined && user !== null && user?.length !== 0 ? <>
      <Dropdown overlayStyle={{ width: "fit-content", cursor: "pointer" }} menu={{ items }}>
        <div style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "0.2rem", cursor: "pointer" }}>
          <Avatar style={{ backgroundColor: "var(--dark3)", cursor: "pointer" }} className="white">{user?.name[0]}</Avatar>
          <IoIosArrowDown className='d-none d-xl-block' />
        </div>
      </Dropdown>
    </> :
      <div style={{ display: "flex", gap: "0.875rem", alignItems: "center" }}>
        <button className={"btnBlack "} onClick={() => { navigate(`/login`, { state: { from: loc.pathname } }) }}>Login</button>
        <button className={"btnWhite "} onClick={() => { navigate(`/signup`, { state: { from: loc.pathname } }) }}>Signup</button>
      </div>
  );
};

export default User;
