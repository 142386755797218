import { createSlice } from '@reduxjs/toolkit'

export const stocksDataSlice = createSlice({
    name: 'stocks',
    initialState: {
        value: [],
    },
    reducers: {
        updateStocks: (state, action) => {
            state.value = action.payload
        },
    },
})

export const etfsDataSlice = createSlice({
    name: 'etfs',
    initialState: {
        value: [],
    },
    reducers: {
        updateETFs: (state, action) => {
            state.value = action.payload
        },
    },
})

export const mfsDataSlice = createSlice({
    name: 'mfs',
    initialState: {
        value: [],
    },
    reducers: {
        updateMFs: (state, action) => {
            state.value = action.payload
        },
    },
})

export const accessDataSlice = createSlice({
    name: "accessLevel",
    initialState: {
        value: [],
    },
    reducers: {
        updateAccessLevels: (state, action) => {
            // console.log(action.payload)
            state.value = action.payload
            // console.log(state.value)
        }
    }
})

export const publisherProfiles = createSlice({
    name: "publisherProfiles",
    initialState: {
        value: [],
    },
    reducers: {
        updatepublisherProfiles: (state, action) => {
            console.log("FDAAFF", action.payload)
            state.value = action.payload
            // console.log(state.value)
        }
    }
})

export const resetSlice = createSlice({
    name: "reset",
    initialState: {
        value: 'initial'
    },
    reducers: {
        resetState: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { updateStocks } = stocksDataSlice.actions;
export const { updateETFs } = etfsDataSlice.actions;
export const { updateMFs } = mfsDataSlice.actions;
export const { updateAccessLevels } = accessDataSlice.actions;
export const { updatepublisherProfiles } = publisherProfiles.actions;
export const { resetState } = resetSlice.actions;