import AMCIcon from "Components/AMCIcon";
import AccessControlledPage from "Components/AccessControlledPage";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import { InstrumentListSearch } from 'Components/CustomComponents/InstrumentListSearch';
import CustomSegmentedTab from "Components/CustomSegmentedTab";
import { NoDataFound } from 'Components/NoDataFound';
import { CardHeader } from "Components/atoms/SmallComponents";
import Head from "Layout/Head";
import { fetchFundData } from "Libs/fetchFundData";
import apiHelperPY from "Services/api/ApiHelperPY";
import { Card, Select, Slider, Tag } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import React from "react";
import { FaMagic } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function SectorRotationChart() {

    const [rrgData, setRRGData] = React.useState({});
    const [originalData, setOriginalData] = React.useState([]);
    const [allDates, setAllDates] = React.useState([])
    const [selectedStocks, setSelectedStocks] = React.useState({ 'stocks': [], 'indices': [] })
    const unOptions = [{ label: "Index analysis", value: 5, key: 0 }, { label: "Stock analysis", value: 4, key: 1 }]
    const [universeType, setUniverseType] = React.useState(unOptions[0].value)
    const [tailLength, setTailLength] = React.useState(9)
    const [range, setRange] = React.useState([])
    const [benchmark, setBenchmark] = React.useState('NIFTY50')
    const [allIndices, setAllIndices] = React.useState([]);
    const [allStocks, setAllStocks] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [loadingRRG, setLoadingRRG] = React.useState(true);
    const isMobile = window.innerWidth <= 760;
    const [adjustedMinMax, setAdjustedMinMax] = React.useState([100, 100, 100, 100])
    const fundColors = ['#007AFF', '#83BF6E', '#FF55BB', '#0062CC', '#AA55FF', '#FF9C55', '#FF6A55', '#1A1D1F', '#00FFFF', '#DBFF00']
    const [timeFrame, setTimeFrame] = React.useState('1W')
    const animateSRCRef = React.useRef();
    let watchlist = useSelector((state) => state?.userWatchlists?.value)?.filter(w => w.FundType === 4);
    console.log(watchlist)
    const timeFrameOptions = [
        {
            label: "Daily candle",
            key: "1d",
            value: "1d",
            word: 'day'
        },
        {
            label: "Weekly candle",
            key: "1w",
            value: "1W",
            word: 'week'
        },
        {
            label: "Monthly candle",
            key: "1m",
            value: "1M",
            word: 'month'
        }

    ];

    const loadData = async () => {
        setLoading(true);
        try {
            let funds = await fetchFundData(4);
            let indices = await fetchFundData(5);
            indices = indices.filter(i => i.use_in_rrg === 1)
            setAllStocks(funds)
            if (funds && indices) {
                setAllIndices(indices)
                setSelectedStocks({
                    'stocks': funds?.sort((a, b) => b.mcap - a.mcap)?.slice(0, 5),
                    'indices': indices?.filter(i => [1, 4, 13, 15, 21, 27, 30, 68].includes(i.Id))
                })
            }
        }
        catch (err) {
            console.log(err);
        }
        setTimeout(() => {
            setLoading(false);
        }, 1);
    }

    const getRRG = async () => {
        if (!selectedStocks[universeType === 4 ? 'stocks' : 'indices'].length) {
            setOriginalData([])
            setRRGData({})
            setLoadingRRG(false)
        }
        const input = {
            "symbol_list": `[${selectedStocks[universeType === 4 ? 'stocks' : 'indices']?.map(el => universeType === 4 ? `"${el?.symbol}"` : `"${el?.IndexNameCaps}"`)}]`,
            "benchmark": `"${benchmark}"`,
            "universe_type": universeType === 4 ? 1 : 2,
            "bar_timeframe": timeFrame,
            "tail": 100
        }
        try {
            if (JSON.parse(input?.symbol_list || '[]')?.length > 0) {
                let res = await apiHelperPY({ apiName: "get_rrg_data", data: input, saveResponse: "" });
                if (res) {
                    let data = JSON.parse(res)
                    data = data.filter(x => x.rs && x.rm)
                    setOriginalData(data)
                    const d = [...new Set(data.map(x => x.timestamp))].sort((a, b) => new Date(a) - new Date(b))
                    setAllDates(d)
                    setRange([d.length - tailLength, d.length - 1])
                    let dataToShow = data.filter(t => moment(t.timestamp).isBetween(moment(d[d.length - tailLength]), moment(d[d.length - 1]), null, []))
                    console.log(dataToShow, d, [d.length - tailLength, d.length - 1])
                    setAdjustedMinMax([
                        Math.min(...dataToShow.map(x => x.rs), 99.5) - 1,
                        Math.max(...dataToShow.map(x => x.rs), 100.5) + 1,
                        Math.min(...dataToShow.map(x => x.rm), 99.5) - 1,
                        Math.max(...dataToShow.map(x => x.rm), 100.5) + 1,
                    ])
                    data = data?.reduce(function (acc, item) {
                        if (!acc[item.symbol]) {
                            acc[item.symbol] = [];
                        }
                        acc[item.symbol].push(item);
                        return acc;
                    }, {});
                    setRRGData(data)
                    setLoadingRRG(false)
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    React.useEffect(() => {
        setLoadingRRG(true);
        getRRG()
    }, [selectedStocks, timeFrame, benchmark, universeType])

    React.useEffect(() => {
        setLoading(true);
        loadData();
    }, []);

    const RRGChart = () => {
        // Function to generate color dynamically based on symbol
        function getColor(id) {
            return fundColors[id];
        }
        // Create series for each symbol
        var series = Object.keys(rrgData).map(function (symbol, id) {
            return {
                name: symbol,
                type: 'spline',
                data: rrgData[symbol].map(function (item, i) {

                    return moment(item?.timestamp).isBetween(moment(allDates[range[0]]), moment(allDates[range[1]]), null, [])
                        ? {
                            x: item.rs, y: item.rm, time: item?.timestamp
                            //  marker: {
                            //     enabled: true,
                            //     symbol: 'triangle',
                            //     fillColor: getColor(id),
                            //     lineColor: getColor(id),
                            //     radius: 6,
                            //     lineWidth: 1,
                            //     width: 10,
                            //     height: 10,
                            //     x: 8 * Math.cos(Math.atan2(rrgData[symbol][i + 1].rm - item.rm, rrgData[symbol][i + 1].rs - item.rs)),
                            //     y: 8 * Math.sin(Math.atan2(rrgData[symbol][i + 1].rm - item.rm, rrgData[symbol][i + 1].rm - item.rs))
                            // }
                        }
                        : null;
                }).filter(x => x),
                color: getColor(id),
                zIndex: 2, // Scatter points above line connectors
                marker: {
                    symbol: 'arrow',
                    radius: 2.5
                },
                lineWidth: 2, // Line width for scatter series
                enableMouseTracking: true,
                showInLegend: true
            };
        });

        var conf = {
            chart: {
                type: 'spline',
                height: '500px',
                events: {
                    load: function () {
                        const chart = this
                        chart.renderer.rect(chart.plotBox.x + chart.axes[0].toPixels(100, true), chart.plotBox.y, chart.plotBox.width - chart.axes[0].toPixels(100, true), chart.axes[1].toPixels(100, true), 1)
                            .attr({ fill: 'green', zIndex: 1, opacity: 0.1 }).add();
                        chart.renderer.rect(chart.plotBox.x + chart.axes[0].toPixels(100, true), chart.plotBox.y + chart.axes[1].toPixels(100, true), chart.plotBox.width - chart.axes[0].toPixels(100, true), chart.plotBox.height - chart.axes[1].toPixels(100, true), 1)
                            .attr({ fill: 'gold', zIndex: 1, opacity: 0.1 }).add();
                        chart.renderer.rect(chart.plotBox.x, chart.plotBox.y + chart.axes[1].toPixels(100, true), chart.axes[0].toPixels(100, true), chart.plotBox.height - chart.axes[1].toPixels(100, true), 1)
                            .attr({ fill: 'red', zIndex: 1, opacity: 0.1 }).add();
                        chart.renderer.rect(chart.plotBox.x, chart.plotBox.y, chart.axes[0].toPixels(100, true), chart.axes[1].toPixels(100, true), 1)
                            .attr({ fill: 'blue', zIndex: 1, opacity: 0.1 }).add();
                        chart.renderer.text('<span style="color: green; font-size: 0.9rem; z-index: 5; font-weight: 500">Leading</span>', chart.plotBox.width + 5, chart.plotBox.y + 15, true).add()
                        chart.renderer.text('<span style="color: gold; font-size: 0.9rem; z-index: 5; font-weight: 500">Weakening</span>', chart.plotBox.width - 16, chart.plotBox.height, true).add()
                        chart.renderer.text('<span style="color: red; font-size: 0.9rem; z-index: 5; font-weight: 500">Lagging</span>', chart.plotBox.x + 5, chart.plotBox.height, true).add()
                        chart.renderer.text('<span style="color: blue; font-size: 0.9rem; z-index: 5; font-weight: 500">Improving</span>', chart.plotBox.x + 5, chart.plotBox.y + 15, true).add();
                        let big = true;
                        setInterval(() => {
                            big = !big
                            if (this.series) {
                                this.series.map(s => {
                                    s.points[s.points.length - 1].update({
                                        marker: {
                                            radius: big ? 3 : 5.5
                                        }
                                    })
                                })
                            }
                        }, 500)
                    }
                }
            },
            title: {
                text: '',
            },
            scrollbar: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + moment(this.point.time).format('Do MMM YYYY') + '</b></br>RS  ratio: <b>' + this.x.toFixed(2) +
                        '</b></br>RM ratio: <b>' + this.y.toFixed(2) + '</b>';
                }
            },
            xAxis: {
                title: {
                    text: 'RS Ratio'
                },
                plotLines: [{
                    color: 'var(--dark2)',
                    value: 100,
                    zIndex: 5
                }],
                gridLineWidth: 1,
                gridLineColor: 'var(--grey3)',
                gridZIndex: 1,
                min: adjustedMinMax[0],
                max: adjustedMinMax[1],
                tickInterval: 0.5,
                labels: {
                    useHTML: true,
                    format: '<div style="font-size:0.6rem;">{value}</div>',
                    rotation: -45
                },
                endOnTick: true,
                startOnTick: true
            },
            yAxis: {
                title: {
                    text: 'RS Momentum'
                },
                plotLines: [{
                    color: 'var(--dark2)',
                    value: 100,
                    zIndex: 5
                }],
                gridLineWidth: 1,
                gridLineColor: 'var(--grey3)',
                gridZIndex: 1,
                min: adjustedMinMax[2],
                max: adjustedMinMax[3],
                tickInterval: 0.5,
                minorGridLineColor: 'var(--grey2)',
                labels: {
                    useHTML: true,
                    format: '<div style="font-size:0.6rem;">{value}</div>'
                },
                endOnTick: true,
                startOnTick: true
            },
            plotOptions: {
                series: {
                    animation: false,
                    marker: {
                        enabled: true
                    },
                    legend: {
                        enabled: true
                    },
                    dataLabels: {
                        enabled: true,
                        allowOverlap: true,
                        formatter() {
                            if (this.point.index === this.series.points.length - 1) {
                                return `<span style="color:${this.color}">${this.series.name}</span>`
                            }
                        }
                    }
                },

            },
            series: series,


        }
        return <HighchartsReact highcharts={Highcharts} options={conf} />

    }

    const StockSelectionSection = () => {
        return <>
            {!isMobile && <CardHeader heading={'Select indices/stocks'} headingType={2} />}
            <CustomSegmentedTab isBlock options={unOptions} value={universeType} onChange={(op) => setUniverseType(op)} mobileStyles={{ marginTop: '0.5rem' }} style={{ marginTop: '0.5rem' }} />
            {universeType === 4 && <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '4px' }}>
                <span className="textSM w-500" style={{ marginTop: '8px', marginRight: '8px' }}>Quick add from your watchlists: </span>{
                    watchlist?.length ? watchlist.map(w => {
                        return <Tag color={'var(--grey1)'} className="hoverBold"
                            bordered={false} style={{ color: 'var(--dark4)', cursor: 'pointer', marginTop: '4px', maxWidth: '20ch', textOverflow: 'ellipsis', overflow: 'hidden' }} onClick={() => setSelectedStocks({
                                ...selectedStocks,
                                'stocks': allStocks.filter(s => w?.FundCodes?.includes(s.symbol))
                            })
                            }>{w.Name}</Tag>
                    }) :
                        <div className="m-2 textXS">You have not created any stock watchlist. <Link to="/watchlist">Create</Link> a stock watchlist for easy selection of stocks</div>
                }</div>}
            <div className='mt-3'>
                <InstrumentListSearch
                    key={universeType}
                    isFullWidthInMob={true}
                    maxSelected={20}
                    placeholder={universeType === 4 ? "Search and add stocks" : "Search and add indices"}
                    isMultiple
                    selectedValues={selectedStocks[universeType === 4 ? 'stocks' : 'indices']}
                    type={universeType}
                    ctaText="Add"
                    onChange={(x) => {
                        console.log(x)
                        setSelectedStocks({
                            ...selectedStocks,
                            [universeType === 4 ? 'stocks' : 'indices']: x
                        })
                    }}
                />
            </div>
            <div className='mt-2' style={{ borderRadius: '5px', overflow: 'clip', border: '1px solid var(--grey4)', margin: isMobile ? '0 -0.75rem' : '' }}>
                {selectedStocks[universeType === 4 ? 'stocks' : 'indices']?.length > 0 ? <>
                    {/* <div className='textMD w-500 my-2 black'>Selected {universeType === 4 ? 'stocks' : 'indices'}</div> */}
                    {
                        isMobile ?
                            <div style={{ display: 'flex', gap: '0.2rem 0', flexWrap: 'wrap', padding: "0.5rem", border: '1px solid var(--grey3)', borderRadius: '5px' }}>{selectedStocks[universeType === 4 ? 'stocks' : 'indices']?.map((f, i) => {
                                let isCustomIndex = universeType === 5 ? String(f?.IndexName)?.startsWith('SHI:') : false
                                return <Tag style={{ display: 'flex', gap: '0.25rem', padding: '0.35rem 0.5rem', alignItems: 'center', marginRight: '0.35rem' }}>
                                    <IoClose onClick={() => {
                                        setLoading(true)
                                        setSelectedStocks(() => {
                                            if (universeType === 4) {
                                                return ({ ...selectedStocks, "stocks": selectedStocks['stocks'].filter(s => s.symbol !== f.symbol) })
                                            } else {
                                                return ({ ...selectedStocks, "indices": selectedStocks['indices'].filter(s => s.Id !== f.Id) })
                                            }
                                        })
                                        setLoading(false)
                                    }} />
                                    <AMCIcon amcName={f.symbol} fundType={4} showTricolor={universeType === 5 ? !isCustomIndex : false} height='1.5rem' width="1.5rem"
                                        bseTicker={f?.bse_ticker} showSharpelyLogo={isCustomIndex} />
                                    <div className="textXS black hoverBold" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                                        {f?.proper_name}
                                    </div>
                                </Tag>
                            })}
                            </div>
                            :
                            selectedStocks[universeType === 4 ? 'stocks' : 'indices']?.map((f, i) => {
                                let isCustomIndex = universeType === 5 ? String(f?.IndexName)?.startsWith('SHI:') : false
                                return <div key={i} className="borderAll" style={{ backgroundColor: i % 2 !== 0 ? 'var(--white)' : 'var(--grey4)' }}>
                                    <div className="pad20Side6VerContainer" style={{ display: "flex", gap: "1rem", alignItems: "center" }} >
                                        <span className="textXS w-500 black" style={{ cursor: 'pointer' }}>
                                            <IoClose onClick={() => {
                                                setLoading(true)
                                                setSelectedStocks(() => {
                                                    if (universeType === 4) {
                                                        return ({ ...selectedStocks, "stocks": selectedStocks['stocks'].filter(s => s.symbol !== f.symbol) })
                                                    } else {
                                                        return ({ ...selectedStocks, "indices": selectedStocks['indices'].filter(s => s.Id !== f.Id) })
                                                    }
                                                })
                                                setLoading(false)
                                            }} />
                                        </span>
                                        <Link style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }} to={universeType === 4 ? '/stocks/stock-detail/' + f?.proper_name?.replaceAll(' ', '-') + '/' + f.symbol : `/indices/index-detail/${f.proper_name?.split(' ')?.join('-')}/${f?.Id}`}>
                                            <AMCIcon amcName={f.symbol} fundType={4} showTricolor={universeType === 5 ? !isCustomIndex : false} height='1.5rem' width="1.5rem"
                                                bseTicker={f?.bse_ticker} showSharpelyLogo={isCustomIndex} />
                                            <div className="textXS black hoverBold" style={{ flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                                                {f?.proper_name}
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            })
                    }
                </>
                    : <div className="textSM pad20Side6VerContainer my-4">
                        Select {universeType === 4 ? 'stocks' : 'indices'} to check out their RRG analysis
                    </div>}
            </div>
        </>
    }

    if (loading) {
        return (
            <div className='loaderContainer'>
                <ActivityLoaderMintbox showQuote />
            </div>
        )
    }
    return (
        <React.Fragment>
            <Head title='Sector Rotation Chart' description="Sector rotation chart" />
            <AccessControlledPage moduleKey="SECTOR_ROTATION_CHART" />
            <div className='mainContainer' style={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}>
                <div className='leftContainerV2'>
                    <Card className='mobCardBorderRadius0'>
                        <div className=' d-flex justify-content-between align-items-start' style={{ gap: "1.25rem" }}>
                            <CardHeader heading={'Sector Rotation Chart'} />
                            <div>
                                <div className="textSM dark3 w-500 mb-2" style={{ textAlign: "right" }}>Candle timeframe</div>
                                <Select options={timeFrameOptions} style={{ width: "10rem" }} placeholder='Candle timeframe' value={timeFrame} onChange={(e) => {
                                    setTimeFrame(e)
                                }} />
                            </div>
                        </div>
                        {isMobile && <StockSelectionSection />}
                        <div style={{ display: 'flex', gap: isMobile ? "1.5rem" : '3rem', marginTop: '1rem', flexDirection: isMobile ? "column" : "row" }}>
                            <div style={{}}>
                                <div className='textSM dark3 w-400 mb-1'>Benchmark:</div>
                                <Select style={{ width: "15rem" }} value={benchmark} options={allIndices.map(x => { return { value: x.IndexNameCaps, label: x.proper_name } })} onChange={(b) => setBenchmark(b)} />
                            </div>
                            <div style={{}}>
                                <div className='textSM dark3 w-400'>Tail length: <span className='black w-500'>{tailLength - 1} {tailLength - 1 ? timeFrameOptions.find(t => t.value === timeFrame)?.word : ''}{tailLength <= 2 ? '' : 's'}</span></div>
                                <Slider value={tailLength - 1} min={0} max={30} tooltip={{ open: false }}
                                    disabled={loading || loadingRRG}
                                    style={{ width: '15rem' }}
                                    styles={{
                                        rail: {
                                            backgroundColor: 'var(--grey3)'
                                        },
                                        track: {
                                            backgroundColor: 'var(--primary)'
                                        },
                                        handle: {
                                            boxShadow: '0 0 0 5px var(--primary)',
                                            borderRadius: '50%'
                                        }
                                    }}
                                    onChange={len => {
                                        len = len + 1
                                        setTailLength(len)
                                        let min, max
                                        if (range[1] - len >= 0) {
                                            min = range[1] - len + 1
                                            max = range[1]
                                        } else {
                                            min = 0
                                            max = len - 1
                                        }
                                        setRange([min, max])
                                        let dataToShow = originalData.filter(t => moment(t.timestamp).isBetween(moment(allDates[min]), moment(allDates[max]), null, []))
                                        setAdjustedMinMax([
                                            Math.min(...dataToShow.map(x => x.rs), 99.5) - 1,
                                            Math.max(...dataToShow.map(x => x.rs), 100.5) + 1,
                                            Math.min(...dataToShow.map(x => x.rm), 99.5) - 1,
                                            Math.max(...dataToShow.map(x => x.rm), 100.5) + 1,
                                        ])
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <div style={{ display: 'flex', gap: '2rem', margin: '1rem 0', alignItems: 'center' }}>
                                <div style={{ flex: 1, }}>
                                    <div className='textSM dark3 w-400 mb-1'>
                                        Showing data from {isMobile ? <br /> : <></>}<span className='w-500 black'>{moment(allDates[range[0]]).format('Do MMM YYYY')}</span> to <span className='w-500 black'>{moment(allDates[range[1]]).format('Do MMM YYYY')}</span>
                                    </div>
                                    <RangeSlider
                                        id='range-slider'
                                        disabled={loading || loadingRRG}
                                        value={range}
                                        min={0}
                                        max={allDates.length - 1}
                                        onInput={(val) => {
                                            console.log(val)
                                            setRange(val);
                                            let dataToShow = originalData.filter(t => moment(t.timestamp).isBetween(moment(allDates[val[0]]), moment(allDates[val[1]]), null, []))
                                            setAdjustedMinMax([
                                                Math.min(...dataToShow.map(x => x.rs), 99.5) - 1,
                                                Math.max(...dataToShow.map(x => x.rs), 100.5) + 1,
                                                Math.min(...dataToShow.map(x => x.rm), 99.5) - 1,
                                                Math.max(...dataToShow.map(x => x.rm), 100.5) + 1,
                                            ])
                                        }}
                                        thumbsDisabled={true}
                                    />
                                    <div className='textXS dark2 w-400 mt-1'>
                                        Note: Drag window to see historic data
                                    </div>
                                </div>
                                {/* <Slider range={{ draggableTrack: true }} value={range}
                                    onChange={(val) => {
                                        console.log(val)
                                        if (val[1] - val[0] + 1 === tailLength) {
                                            setRange(val);
                                            let dataToShow = originalData.filter(t => moment(t.timestamp).isBetween(moment(allDates[val[0]]), moment(allDates[val[1]]), null, []))
                                            setAdjustedMinMax([
                                                Math.min(...dataToShow.map(x => x.rs), 99.5) - 1,
                                                Math.max(...dataToShow.map(x => x.rs), 100.5) + 1,
                                                Math.min(...dataToShow.map(x => x.rm), 99.5) - 1,
                                                Math.max(...dataToShow.map(x => x.rm), 100.5) + 1,
                                            ])
                                        }
                                    }}
                                    min={0}
                                    max={allDates.length - 1}
                                    tooltip={{ open: true, arrow: false, formatter: (val) => moment(allDates[val]).format('Do MMM YYYY'), style: { backgroundColor: 'white', color: 'black' } }}
                                // marks={Object.assign({}, allDates.map((t, i) => (i % 20) ? null : moment(t).format("Do MMM YY")))}
                                /> */}
                                <button className='btnBlack w-500'
                                    disabled={loading || loadingRRG}
                                    style={{ display: 'flex', alignItems: 'center', gap: '4px' }} onClick={() => {
                                        if (animateSRCRef.current === undefined) {
                                            let dataToShow = originalData
                                            setAdjustedMinMax([
                                                Math.min(...dataToShow.map(x => x.rs), 99.5) - 1,
                                                Math.max(...dataToShow.map(x => x.rs), 100.5) + 1,
                                                Math.min(...dataToShow.map(x => x.rm), 99.5) - 1,
                                                Math.max(...dataToShow.map(x => x.rm), 100.5) + 1,
                                            ])
                                            let i = 0
                                            animateSRCRef.current = setInterval(() => {
                                                if (i === allDates.length - tailLength + 1) {
                                                    clearInterval(animateSRCRef.current);
                                                    animateSRCRef.current = undefined;
                                                    // dataToShow = originalData.filter(t => moment(t.timestamp).isBetween(moment(allDates[range[0]]), moment(allDates[range[1]]), null, []))
                                                    // setAdjustedMinMax([
                                                    //     Math.min(...dataToShow.map(x => x.rs), 99.5) - 1,
                                                    //     Math.max(...dataToShow.map(x => x.rs), 100.5) + 1,
                                                    //     Math.min(...dataToShow.map(x => x.rm), 99.5) - 1,
                                                    //     Math.max(...dataToShow.map(x => x.rm), 100.5) + 1,
                                                    // ])
                                                } else {
                                                    setRange([i, i + tailLength - 1])
                                                    i++;
                                                }
                                            }, 100);
                                        }
                                    }}>Animate <FaMagic /></button>
                            </div>
                            {loadingRRG ?
                                <ActivityLoaderMintbox margin='7rem' />
                                : !originalData?.length ?
                                    <NoDataFound message={`Select ${universeType == 4 ? 'stocks' : 'indices'} to continue`} marginBottom='2rem' />
                                    :
                                    <div style={{ margin: "0 -0.75rem 0 -1.25rem" }}><RRGChart /></div>}
                        </div>
                    </Card>
                </div>
                {!isMobile && <div className='rightContainerV2' style={{ height: '100%' }}>
                    <Card>
                        <StockSelectionSection />
                    </Card>
                </div>}
            </div>

        </React.Fragment>
    )
}

export default React.memo(SectorRotationChart);