import { Card, Divider, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Logo from 'Layout/Logo';
import db from 'Libs/db';
import { toggleloginModal, toggleLoginModalParams, togglePreferenceModal, updateUserData } from 'Libs/redux/UserAuth/reduxSlice';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import AdminServices from 'Services/api/AdminServices';
import { Dashboard_Portfolio } from 'Services/api/FabricatedAPIS';
import { useSelector } from 'react-redux';
import PinInput from 'react-pin-input';
import { updatePrevStockPrices } from 'Libs/redux/StocksPrevPrices/reduxSlice';
import { updateStockPrices, updatesubscribedStocks } from 'Libs/redux/StockLivePrice/reduxSlice';
import moengage from "@moengage/web-sdk";
import { signInWithGoogle } from 'FirebaseConst';
import { FcGoogle } from 'react-icons/fc';
import { getMoengageDeviceUID } from 'Libs/utils';

export default function LoginSignupModal({ }) {

    const modelOpen = useSelector((state) => state?.toggleloginModal?.value);
    const modalParams = useSelector((state) => state?.toggleloginModal?.params);
    const executeOnLoginOrSignup = modalParams?.executeOnLoginOrSignup || null;
    const [modalType, setModalType] = React.useState('login');
    const dispatch = useDispatch();

    const onCancel = () => {
        dispatch(toggleloginModal(false))
        dispatch(toggleLoginModalParams({}))
    }

    React.useEffect(() => {
        setModalType(modalParams?.type || "login");
    }, [modalParams, modelOpen])

    React.useEffect(() => {
        if (!modelOpen) {
            dispatch(toggleLoginModalParams({}))
        }
    }, [modelOpen])

    const LoginSection = () => {

        const [email, setEmail] = React.useState('');
        const [userData, setuserData] = React.useState({});
        const [otp, setOTP] = React.useState('');
        const [nextBtnPressed, setNextBtnPressed] = React.useState(false);
        const [loading, setLoading] = React.useState(false);
        const otpref = React.useRef()

        const resetData = () => {
            dispatch(updatePrevStockPrices([]));
            dispatch(updateStockPrices([]));
            dispatch(updatesubscribedStocks({}));
            db.clear();
        }

        const checkOTP = async () => {
            setLoading(true);
            let isCorrectOTP = await AdminServices.verifyOTP({ value: email, otp: otp });
            console.log(isCorrectOTP);
            if (isCorrectOTP?.data?.Result === 'success') {
                try {
                    resetData();
                    db.set(db.model.user, JSON.stringify({ userData }));
                    let d = {}
                    await Dashboard_Portfolio.getClientData(userData, true).then((res) => {
                        if (res) {
                            d = res;
                            dispatch(updateUserData({ ...userData, ...res }))
                            toast.success("Welcome back.");
                            dispatch(toggleloginModal(false))
                            if (typeof executeOnLoginOrSignup === 'function') {
                                executeOnLoginOrSignup();
                            }
                            setNextBtnPressed(false);
                            setOTP('');
                            setEmail('');
                            setLoading(false);
                        }
                    })
                    let userTracked = await moengage.add_unique_user_id("SHARPELY-" + userData.id);
                    if (userTracked) {
                        moengage.add_email(userData.email)
                        moengage.add_mobile(userData.mobile)
                        moengage.add_user_name(userData.name)
                        moengage.add_user_attribute("SharpelyAccessLevel", d?.Client?.[0]?.SharpelyAccessLevel)
                    }
                    moengage.track_event("Login_popup", {
                        "login_email": userData?.email,
                        "login_mobile": userData?.mobile,
                        "login_user_name": userData?.name
                    })
                    moengage.track_event("OTP_submitted_login", {
                        "otp_user_email": userData?.email,
                        'otp_user_id': userData?.id
                    })
                } catch (error) {
                    toast.error('Something went wrong. Please try again.')
                    dispatch(toggleloginModal(false))
                    setNextBtnPressed(false);
                    setOTP('');
                    setEmail('');
                    otpref?.current?.clear()
                    setLoading(false);
                }
            } else {
                toast.error("You have entered an incorrect OTP!");
                setOTP('');
                otpref?.current?.clear()
                setLoading(false);
            }
        }

        const sendOTP = async (id, email, name) => {
            try {
                const res = await AdminServices.login({ id, appId: 2, displayName: name }).then(res => res)
                if (res.type) {
                    moengage.track_event('email_submitted_login', {
                        "user_email": email,
                        "user_id": id
                    })
                    setNextBtnPressed(true);
                    toast.success("OTP has been sent to your registered email/phone.");
                } else {
                    toast.error("Something went wrong. Please try again ...");
                }
            } catch (error) {
                toast.error("Something went wrong. Please try again ...");
            }
        };

        const onNextBtnPressed = async () => {
            setLoading(true);
            try {
                const res = await AdminServices.ClientExists({ type: "email", value: email }).then((res) => res);
                if (res.type) {
                    if (res.data.ClientId !== '') {
                        let uuid = getMoengageDeviceUID()
                        // let checkSession = await AdminServices.verifySession({type:"CLIENTID",value:res.data.ClientId,uniqueId:uuid})
                        // console.log(checkSession)
                        // return;
                        setuserData({
                            name: res.data.ClientName,
                            email: res.data.Email,
                            mobile: res.data.MobileNumber,
                            id: res.data.ClientId,
                            isLogged: true,
                            login_Date: new Date()
                        });
                        sendOTP(res.data.ClientId, res.data?.Email, res.data.ClientName)
                    } else {
                        setNextBtnPressed(false);
                        toast.error("Not a sharpely registered user");
                    }
                } else {
                    toast.error("Something went wrong. Please try again ...");
                    setNextBtnPressed(false);
                }
                setLoading(false);
            } catch (error) {
                toast.error("Something went wrong. Please try again ...");
                setNextBtnPressed(false);
                setLoading(false);
            }
        };

        const handleSubmit = (e) => {
            e.preventDefault();
            if (loading) return;
            if (nextBtnPressed) checkOTP()
            else {
                // if (handleValidation() === true) {
                onNextBtnPressed();
                // }
            }
        }

        const signin = async () => {
            setLoading(true)
            var user = await signInWithGoogle()
            if (Object.keys(user)?.length <= 0) {
                setNextBtnPressed(false);
                setLoading(false)
            }
            else {
                try {
                    const res = await AdminServices.ClientExists({ type: "email", value: user.email }).then((res) => res);
                    if (res.type) {
                        if (res.data.ClientId !== '') {
                            resetData();
                            var userData = {
                                name: res.data.ClientName,
                                email: res.data.Email,
                                mobile: res.data.MobileNumber,
                                id: res.data.ClientId,
                                isLogged: true,
                                login_Date: new Date()
                            }
                            db.set(db.model.user, JSON.stringify({ userData })); // remove later
                            moengage.track_event("Login_page_google", {
                                "login_email": userData?.email,
                                "login_mobile": userData?.mobile,
                                "login_user_name": userData?.name
                            })
                            let d = {}
                            await Dashboard_Portfolio.getClientData(userData, true).then((res) => {
                                if (res) {
                                    d = res;
                                    dispatch(updateUserData({ ...userData, ...res }))
                                }
                            })
                            let userTracked = await moengage.add_unique_user_id("SHARPELY-" + userData.id);
                            if (userTracked) {
                                moengage.add_email(userData.email)
                                moengage.add_mobile(userData.mobile)
                                moengage.add_user_name(userData.name)
                                moengage.add_user_attribute("SharpelyAccessLevel", d?.Client?.[0]?.SharpelyAccessLevel)
                            }
                            dispatch(toggleloginModal(false))
                            toast.success('Welcome back!')
                            if (typeof executeOnLoginOrSignup === 'function') {
                                executeOnLoginOrSignup();
                            }

                        } else {
                            setNextBtnPressed(false);
                            setLoading(false)
                            toast.error("Not a sharpely registered user");
                        }
                    } else {
                        toast.error("Something went wrong. Please try again ...");
                        setNextBtnPressed(false);
                        setLoading(false)

                    }
                } catch (error) {
                    console.log(error);
                    toast.error("Something went wrong. Please try again ...");
                    setNextBtnPressed(false);
                    setLoading(false)
                }
            }
        }

        React.useEffect(() => {
            if (otp.length === 6) {
                checkOTP();
            }
        }, [otp]);

        return (
            <React.Fragment>
                <Card bordered={false} style={{ padding: "0rem" }} bodyStyle={{ padding: 0, display: "flex", flexDirection: "column" }}>
                    <Logo type='dark' isClickable={false} />
                    <div className='textLG w-700 mt-4'>Hello! let's get started</div>
                    <form onSubmit={handleSubmit}>
                        <div style={{ marginTop: '1rem' }}>
                            <div className='d-flex flex-column'>
                                <div className='textSM w-500 dark3 mb-2'>Sign in to continue.</div>
                                {!nextBtnPressed ?
                                    <input name='email' placeholder='Enter your email address' onChange={(e) => {
                                        setEmail(e.target.value?.trim())
                                    }} className='textSM antdInput' style={{
                                        background: "var(--grey5)", border: "1px solid var(--grey2)",
                                        height: "40px"
                                    }} type='text' value={email}
                                        autoComplete='on'
                                        autoFocus={true}
                                    /> : <PinInput ref={otpref} length={6} initialValue="" secret secretDelay={100} onChange={(value, index) => {
                                        setOTP(value);
                                    }} type="numeric" inputMode="number" style={{ padding: '0rem' }} inputStyle={{
                                        borderColor: 'var(--grey2)', background: "var(--grey5)", borderRadius: "var(--borderRadius)",
                                        height: "40px", width: "40px"
                                    }} inputFocusStyle={{ borderColor: 'var(--primary)' }} onComplete={(value, index) => { }} autoSelect={true} focus={true} />
                                }
                            </div>
                        </div>
                        <button style={{ width: "100%", opacity: loading ? "0.7" : "1", marginTop: '1.5rem' }} type='submit'
                            className={" btnBlack "}>
                            {loading ? <LoadingOutlined style={{ fontSize: '1.5rem', color: "var(--white)" }} spin /> :
                                (nextBtnPressed ? "Sign in" : "Send OTP")}
                        </button>
                    </form>
                    <Divider className="textMD" style={{ margin: "0.5rem 0" }}>or</Divider>
                    <button style={{ width: "100%", opacity: loading ? "0.7" : "1" }} className={" btnBlack "} onClick={() => {
                        if (!loading) {
                            signin();
                        }
                    }} >
                        <FcGoogle className='' style={{ marginRight: "0.5rem", fontSize: "var(--textSM)" }} />
                        <span className='textSM w-500'>Sign In With Google</span>
                    </button>
                    <div className="text-center mt-4 fontH2">
                        New to sharpely? <span onClick={() => {
                            setModalType('register');
                        }} style={{ color: "var(--primary)", cursor: "pointer" }} className='w-500'>Register</span>
                    </div>
                </Card>
            </React.Fragment>
        )
    }

    const RegisterSection = () => {

        const [email, setEmail] = React.useState("");
        const [name, setName] = React.useState("");
        const [phone, setPhone] = React.useState("");
        const [otp, setOTP] = React.useState("");
        const [nextBtnPressed, setNextBtnPressed] = React.useState(false);
        const [loading, setLoading] = React.useState(false);
        const otpref = React.useRef();
        const nameRegex = /[^a-zA-Z\s]/g;

        const handleValidation = () => {
            let mailformat = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (name?.trim()?.length < 4) {
                toast.error("Name must have at least 4 characters");
            } else if (phone.length !== 10) {
                toast.error("Enter valid phone number!");
            } else if (!email.match(mailformat)) {
                toast.error("Enter valid email!");
            } else {
                return true;
            }
            return;
        };

        const checkOTP = async () => {
            setLoading(true);
            let isCorrectOTP = await AdminServices.verifyOTP({ value: email, otp: otp });
            console.log(isCorrectOTP);
            if (isCorrectOTP?.data?.Result === 'success') {
                let tphone = phone
                const data = {
                    mobileNumber: tphone, // will be blank if skip is pressed
                    email: email,
                    clientName: name,
                    source: "web",
                    appId: 2
                };
                try {
                    const res = await AdminServices.createUser(data).then((res) => res);
                    if (res.type) {
                        if (res.data.Result === "success") {
                            const userData = {
                                name: name,
                                email: email,
                                mobile: tphone,
                                id: res.data.ClientId,
                                isLogged: true,
                                login_Date: new Date(),
                            };
                            db.set(db.model.user, JSON.stringify({ userData }));
                            let d = {}
                            await Dashboard_Portfolio.getClientData(userData, true).then(
                                (res) => {
                                    if (res) {
                                        d = res;
                                        dispatch(updateUserData({ ...userData, ...res }));
                                    }
                                }
                            );
                            dispatch(toggleloginModal(false));
                            moengage.track_event("Registration_completed_email", {
                                "registration_email": email,
                            })
                            moengage.track_event("OTP_submitted_registration", {
                                "otp_user_email": userData?.email,
                                'otp_user_id': userData?.id
                            })
                            let userTracked = await moengage.add_unique_user_id("SHARPELY-" + userData.id);
                            if (userTracked) {
                                moengage.add_email(userData.email)
                                moengage.add_mobile(userData.mobile)
                                moengage.add_user_name(userData.name)
                                moengage.add_user_attribute("SharpelyAccessLevel", d?.Client?.[0]?.SharpelyAccessLevel)
                            }
                            toast.success("Account created successfully!");
                            setNextBtnPressed(false);
                            if (typeof executeOnLoginOrSignup === 'function') {
                                executeOnLoginOrSignup();
                            }
                        } else {
                            toast.error("Something went wrong. Please try again ...");
                            setNextBtnPressed(false);
                            setLoading(false);
                        }
                    } else {
                        toast.error("Something went wrong. Please try again ...");
                        setNextBtnPressed(false);
                        setLoading(false);
                    }
                } catch (error) {
                    toast.error("Something went wrong. Please try again ...");
                    setNextBtnPressed(false);
                    setLoading(false);
                }
            } else {
                toast.error("You have entered an incorrect OTP. Try again ...");
                setOTP('');
                otpref?.current?.clear()
                setNextBtnPressed(false);
                setLoading(false);
            }
        };

        const sendOTP = async () => {
            setLoading(false);
            try {
                // setmainOTP("");
                const res = await AdminServices.sendOTP("EMAIL", email, 2, name).then(
                    (res) => res
                );
                if (res.type) {
                    if (res.data.Result === "success") {
                        // setmainOTP(res.data.otpcode);
                        // let userTracked = await moengage.add_unique_user_id("SHARPELY-" + id);
                        // if (userTracked) {
                        //   moengage.add_email(email)
                        //   moengage.add_user_name(name)
                        // }
                        moengage.track_event("Registration_initiated_email", {
                            "registration_email": email,
                        })
                        moengage.track_event('email_submitted_registration', {
                            "user_email": email,
                        })
                        toast.success("OTP has been sent on your registered email/mobile");
                        setNextBtnPressed(true);
                    } else {
                        toast.error("Something went wrong. Please try again ...");
                    }
                } else {
                    toast.error("Something went wrong. Please try again ...");
                }
            } catch (error) {
                toast.error("Something went wrong. Please try again ...");
            }
        };

        const onNextBtnPressed = async () => {
            try {
                setLoading(true);
                let tphone = phone
                const res = await AdminServices.ClientExists({
                    type: "email",
                    value: email,
                }).then((res) => res);
                const res2 = await AdminServices.ClientExists({ type: "mobile", value: tphone });
                if (res.type && res2?.type) {
                    if (res.data.ClientId !== "" || res2.data.ClientId !== "") {
                        toast.error(
                            "You are already a sharpely registered user. Please login!"
                        );
                        setLoading(false);
                    }
                    else {
                        sendOTP(res.data.ClientId);
                    }
                } else {
                    toast.error("Something went wrong. Please try again ...");
                    setLoading(false);
                }
            } catch (error) {
                toast.error("Something went wrong. Please try again ...");
                setLoading(false);
            }
        };

        const handleSubmit = (e) => {
            e.preventDefault();
            if (loading) return;
            if (nextBtnPressed) checkOTP();
            else {
                if (handleValidation() === true) {
                    onNextBtnPressed();
                }
            }
        };

        const signin = async () => {
            setLoading(true);
            var user = await signInWithGoogle();
            if (Object.keys(user)?.length <= 0) {
                setNextBtnPressed(false);
                setLoading(false);
            } else {
                try {
                    moengage.track_event("Registration_initiated_google", {
                        "registration_email": user?.email,
                    })
                    const res = await AdminServices.ClientExists({ type: "email", value: user.email, }).then((res) => res);
                    if (res.type) {
                        if (res.data.ClientId !== "") {
                            toast.error(
                                "You are already a sharpely registered user. Please login! Please Login."
                            );
                            setLoading(false);
                            setNextBtnPressed(false);
                        } else {

                            let userName = user?.displayName?.replace(nameRegex, ' ')?.trim();
                            userName = userName ? userName : user?.email?.replace('@gmail.com', '')?.replace(nameRegex, ' ')?.trim();

                            const data = {
                                mobileNumber: "", // will be blank if skip is pressed
                                email: user.email,
                                clientName: userName,
                                source: "web",
                                appId: 2,
                            };

                            const res = await AdminServices.createUser(data).then((res) => res);
                            if (res.type) {
                                if (res.data.Result === "success") {
                                    const userData = {
                                        name: userName,
                                        email: user.email,
                                        mobile: "",
                                        id: res.data.ClientId,
                                        isLogged: true,
                                        login_Date: new Date(),
                                    };
                                    moengage.track_event("Registration_completed_google", {
                                        "registration_email": user?.email,
                                    })
                                    db.set(db.model.user, JSON.stringify({ userData }));
                                    let d = {}
                                    await Dashboard_Portfolio.getClientData(userData, true).then(
                                        (res) => {
                                            if (res) {
                                                d = res;
                                                dispatch(updateUserData({ ...userData, ...res }));
                                            }
                                        }
                                    );
                                    dispatch(toggleloginModal(false));
                                    let userTracked = await moengage.add_unique_user_id("SHARPELY-" + userData.id);
                                    if (userTracked) {
                                        moengage.add_email(userData.email)
                                        moengage.add_mobile(userData.mobile)
                                        moengage.add_user_name(userData.name)
                                        moengage.add_user_attribute("SharpelyAccessLevel", d?.Client?.[0]?.SharpelyAccessLevel)
                                    }
                                    toast.success("Account created successfully!");
                                    setNextBtnPressed(false);
                                    if (typeof executeOnLoginOrSignup === 'function') {
                                        executeOnLoginOrSignup();
                                    }
                                } else {
                                    toast.error("Something went wrong. Please try again ...");
                                    setNextBtnPressed(false);
                                    setLoading(false);
                                }
                            } else {
                                toast.error("Something went wrong. Please try again ...");
                                setNextBtnPressed(false);
                                setLoading(false);
                            }
                        }
                    } else {
                        toast.error("Something went wrong. Please try again ...");
                        setLoading(false);
                    }
                } catch (error) {
                    toast.error("Something went wrong. Please try again ...");
                    setLoading(false);
                }
            }
        };

        React.useEffect(() => {
            if (otp.length === 6) checkOTP();
        }, [otp]);

        return (
            <React.Fragment>
                <Card bordered={false} style={{ padding: "0rem" }} bodyStyle={{ padding: 0, display: "flex", flexDirection: "column", gap: "0rem" }}>
                    <Logo type='dark' isClickable={false} />
                    <div className='textLG w-700 mt-2'>Welcome to <b style={{ color: "var(--primary)" }}>sharpely</b></div>
                    <div className='mt-2 textSM dark3'>Signing up is easy. It only takes a few steps.</div>
                    <form onSubmit={(e) => handleSubmit(e)} style={{ marginTop: "1rem", marginBottom: "0rem" }}>
                        {!nextBtnPressed ? <>
                            <div style={{ marginBottom: "1rem" }}>
                                <label className="dark3 textXS w-500 mb-1">
                                    Name <span style={{ color: "var(--red)" }}>&#42;</span>
                                </label>
                                <input name="name" placeholder="Enter your Name" value={name} onChange={(e) => {
                                    let v = e.target.value;
                                    v = v.replace(nameRegex, '')
                                    if (v?.length < 50) {
                                        setName(v);
                                    }
                                }} className="textSM antdInput"
                                    style={{ background: "var(--grey5)", border: "1px solid var(--grey2)", height: "40px", }} type="text" autoComplete="name" autoFocus={true} />
                            </div>
                            <div style={{ marginBottom: "1rem" }}>
                                <label className="dark3 textXS w-500 mb-1">
                                    Phone Number <span style={{ color: "var(--red)" }}>&#42;</span>
                                </label>
                                <div className='d-flex align-items-center' style={{ gap: "1rem" }}>
                                    <input name="tel" placeholder="Enter your phone number" onChange={(e) => { setPhone(e.target.value); }} className="textSM antdInput"
                                        style={{ background: "var(--grey5)", border: "1px solid var(--grey2)", height: "40px", flex: 9 }} type="number" autoComplete="tel" value={phone} />
                                </div>
                            </div>
                            <div style={{ marginBottom: "1rem" }}>
                                <label className="dark3 textXS w-500 mb-1">
                                    Email Address{" "}
                                    <span style={{ color: "var(--red)" }}>&#42;</span>
                                </label>
                                <input name="email" placeholder="Enter your email address" onChange={(e) => { setEmail(e.target.value?.trim()); }} className="textSM antdInput"
                                    style={{ background: "var(--grey5)", border: "1px solid var(--grey2)", height: "40px", }} type="text" autoComplete="email" value={email} />
                            </div>
                        </> : <>
                            <div style={{ marginBottom: "1.5rem" }}>
                                <label className="dark3 textXS w-500 mb-1">OTP</label>
                                <PinInput ref={otpref} length={6} initialValue="" secret secretDelay={100} onChange={(value, index) => { setOTP(value); }} type="numeric" inputMode="number"
                                    style={{ padding: "0rem" }} inputStyle={{
                                        borderColor: "var(--grey2)", background: "var(--grey5)", borderRadius: "var(--borderRadius)", height: "40px",
                                        width: "2.5rem",
                                    }} inputFocusStyle={{ borderColor: "var(--primary)" }} onComplete={(value, index) => { }} autoSelect={true} focus={true} />
                            </div>
                        </>}
                        <button style={{ width: "100%", opacity: loading ? "0.7" : "1" }} type="submit" className={" btnBlack "}>
                            {loading ? (
                                <LoadingOutlined style={{ fontSize: "1.5rem", color: "var(--white)" }} spin />
                            ) : nextBtnPressed ? ("Sign Up") : ("Send OTP")}
                        </button>
                    </form>

                    <Divider className="textMD" style={{ margin: "0.5rem 0" }}>or</Divider>
                    <button style={{ width: "100%", opacity: loading ? "0.7" : "1" }} className={" btnBlack "} onClick={() => {
                        if (!loading) {
                            signin();
                        }
                    }}>
                        <FcGoogle className="" style={{ marginRight: "0.5rem", fontSize: "var(--textSM)" }} />
                        <span className="textSM w-500">Sign Up With Google</span>
                    </button>
                    <div className="text-center mt-4 fontH2">
                        Already have an account? <span onClick={() => {
                            setModalType('login');
                        }} style={{ color: "var(--primary)", cursor: "pointer" }} className='w-500'>Login</span>
                    </div>
                </Card>
            </React.Fragment>
        )
    }

    return (
        <Modal animation={false} centered mask transitionName="" maskTransitionName="" styles={{
            mask: {
                zIndex: modalParams?.isExtClickDisabled ? 999 : 2000,
                background: modalParams?.isExtClickDisabled ? "linear-gradient(to right bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5))" : "",
                backdropFilter: modalParams?.isExtClickDisabled ? "blur(3px)" : ""
            },
            body: { padding: 0 }
        }} maskClosable={true} open={modelOpen} width={"min(30rem,100%)"} zIndex={modalParams?.isExtClickDisabled ? 999 : 1000} style={{ top: 0, padding: 0 }} footer={null}
            title={null} closable={false}
            onCancel={() => {
                if (!modalParams?.isExtClickDisabled) {
                    onCancel();
                }
            }} wrapClassName={modalParams?.isExtClickDisabled ? "loginSignupModalWrapInd" : 'loginSignupModalWrap'}>
            {modalType === 'login' ? <LoginSection /> : <RegisterSection />}
        </Modal>
    )
}
