import apiHelper from "Services/api/ApiHelper";
import apiHelperPY from "Services/api/ApiHelperPY";
import { getIndexList } from "./utils";

let isSaveResponse = () => {
    let d = new Date();
    let h = d.getHours();
    let m = d.getMinutes();
    if (h <= 9 && m <= 15) {

    }
}

const excludedSymbols = ['HDFC']

export async function fetchFundData(type, isFresh = false, clientID = -1) {

    //console.log(type, isFresh)
    // type values
    // 1 = ETF
    // 2 = MF
    // 3 = Mintbox
    // 4 = Stocks
    // 5 = Index
    // 11 = benchmarks

    if (type === 1) {
        let res = await apiHelperPY({ apiName: 'getEtfs', saveResponse: 'sessionStorage', getFresh: isFresh })
        if (res) {
            let d = JSON.parse(convertToJson(res))
            return d?.filter((i) => i.isin_code !== null)?.map((fund) => {
                return {
                    ...fund,
                    // "gdf_symbol": fund?.nse_series === 2 ? fund?.nse_symbol + ".BE" : fund?.nse_series === 3 ? fund?.nse_symbol + ".BZ" : fund?.nse_symbol
                    "gdf_symbol": fund?.nse_symbol
                }
            })
            // return JSON.parse(convertToJson(res).replace(/NaN/g, null)?.replace(/Infinity/g, null)).filter((i) => i.isin_code !== null)
        } else {
            return []
        }
    }
    else if (type === 2) {
        let res = await apiHelperPY({ apiName: 'getMFs', saveResponse: 'sessionStorage', getFresh: isFresh })
        //console.log(typeof (res))
        if (res) {
            //console.log(res)
            let d = JSON.parse(convertToJson(res))
            return d?.filter((i) => i.isin_code !== null)
            // return JSON.parse(convertToJson(res).replace(/NaN/g, null)?.replace(/Infinity/g, null)).filter((i) => i.isin_code !== null)
        } else {
            return []
        }
    } else if (type === 3) {
        let res = await apiHelper({ apiName: 'getMintboxes', data: { clientId: clientID }, saveResponse: 'sessionStorage', getFresh: isFresh })
        if (res) {
            return res.filter(m => m.IsDisplay === 1)
        } else {
            return []
        }
    } if (type === 4) {
        let res = await apiHelperPY({ apiName: 'getStocks', saveResponse: 'sessionStorage', getFresh: isFresh })
        //console.log(res)
        if (res) {
            // console.log("haha")
            let d = JSON.parse(convertToJson(res))
            // console.log("haha2")
            return d?.filter((i) => i.symbol.length > 0 && (i?.nse_segment !== null || i?.bse_segment !== null) && i?.mcap > 0 && !(excludedSymbols.includes(i?.symbol)))?.map((fund) => {
                // console.log(fund?.nse_series === 2 ? fund?.symbol + ".BE" : fund?.nse_series === 3 ? fund?.symbol + ".BZ" : fund?.symbol)
                return {
                    ...fund,
                    // "gdf_symbol": fund?.nse_series === 2 ? fund?.symbol + ".BE" : fund?.nse_series === 3 ? fund?.symbol + ".BZ" : fund?.symbol
                    "gdf_symbol": fund?.symbol
                }
            })
            // return JSON.parse(convertToJson(res).replace(/NaN/g, null)?.replace(/Infinity/g, null)).filter((i) => i.nse_active === 1 && i.symbol.length > 0)
        } else {
            return []
        }
    } else if (type === 5) {
        return await getIndexList()
    } else if (type === 11) {
        let res = await apiHelper({ apiName: 'getBenchmarks', saveResponse: 'sessionStorage', getFresh: isFresh })
        //console.log(res)
        if (res) {
            return res?.filter((i) => i.is_active === 1)
        } else {
            return []
        }
    } else {
        return []
    }
};



export async function fetchFundDataWithoutFilter(type, isFresh = false, clientID = -1) {

    ////console.log(type, isFresh)
    // type values
    // 1 = ETF
    // 2 = MF
    // 3 = Mintbox
    // 4 = Stocks
    // 5 = Index
    // 11 = benchmarks

    if (type === 1) {
        let res = await apiHelperPY({ apiName: 'getEtfs', saveResponse: 'sessionStorage', getFresh: isFresh })
        if (res) {
            let d = JSON.parse(convertToJson(res))
            return d?.filter((i) => i.isin_code !== null)?.map((fund) => {
                return {
                    ...fund,
                    // "gdf_symbol": fund?.nse_series === 2 ? fund?.nse_symbol + ".BE" : fund?.nse_series === 3 ? fund?.nse_symbol + ".BZ" : fund?.nse_symbol
                    "gdf_symbol": fund?.nse_symbol
                }
            })
            // return JSON.parse(convertToJson(res).replace(/NaN/g, null)?.replace(/Infinity/g, null)).filter((i) => i.isin_code !== null)
        } else {
            return []
        }
    }
    else if (type === 2) {
        let res = await apiHelperPY({ apiName: 'getMFs', saveResponse: 'sessionStorage', getFresh: isFresh })
        //console.log(typeof (res))
        if (res) {
            //console.log(res)
            let d = JSON.parse(convertToJson(res))
            return d?.filter((i) => i.isin_code !== null)
            // return JSON.parse(convertToJson(res).replace(/NaN/g, null)?.replace(/Infinity/g, null)).filter((i) => i.isin_code !== null)
        } else {
            return []
        }
    } else if (type === 3) {
        let res = await apiHelper({ apiName: 'getMintboxes', data: { clientId: clientID }, saveResponse: 'sessionStorage', getFresh: isFresh })
        if (res) {
            return res.filter(m => m.IsDisplay === 1)
        } else {
            return []
        }
    } if (type === 4) {
        let res = await apiHelperPY({ apiName: 'getStocks', saveResponse: 'sessionStorage', getFresh: isFresh })
        //console.log(res)
        if (res) {
            // console.log("haha")
            let d = JSON.parse(convertToJson(res))
            // console.log("haha2")
            return d?.filter((i) => i.symbol.length > 0 && (i?.nse_segment !== null || i?.bse_segment !== null) && !(excludedSymbols.includes(i?.symbol)))?.map((fund) => {
                // console.log(fund?.nse_series === 2 ? fund?.symbol + ".BE" : fund?.nse_series === 3 ? fund?.symbol + ".BZ" : fund?.symbol)
                return {
                    ...fund,
                    // "gdf_symbol": fund?.nse_series === 2 ? fund?.symbol + ".BE" : fund?.nse_series === 3 ? fund?.symbol + ".BZ" : fund?.symbol
                    "gdf_symbol": fund?.symbol
                }
            })
            // return JSON.parse(convertToJson(res).replace(/NaN/g, null)?.replace(/Infinity/g, null)).filter((i) => i.nse_active === 1 && i.symbol.length > 0)
        } else {
            return []
        }
    } else if (type === 5) {
        return await getIndexList()
    } else if (type === 11) {
        let res = await apiHelper({ apiName: 'getBenchmarks', saveResponse: 'sessionStorage', getFresh: isFresh })
        //console.log(res)
        if (res) {
            return res?.filter((i) => i.is_active === 1)
        } else {
            return []
        }
    } else {
        return []
    }
};

const convertToJson = (data) => {
    data = JSON.parse(data)
    const keys = data.keys
    const values = JSON.parse(data.values)
    const z = Array.from(values, (arr) => Object.fromEntries(keys.map((key, index) => [key, arr[index]])));
    return JSON.stringify(z)
}