import { microphone } from "Assets/images";
import AMCIcon from "Components/AMCIcon";
import AccessControlledPage from "Components/AccessControlledPage";
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { CurrencyFormat } from "Components/CurrencyFormat";
import { CustomSelect } from "Components/CustomComponents/CustomSelect";
import { InstrumentListSearch } from "Components/CustomComponents/InstrumentListSearch";
import CustomSegmentedTab from "Components/CustomSegmentedTab";
import { formatDateyyyymmdd } from "Components/DateFormatter";
import { minInvestment } from "Components/MinInvestment";
import { NoDataFound } from 'Components/NoDataFound';
import { inverseW, risk_management, risk_management_FSDP, risk_management_FSTP, risk_management_TSL, schedule, transaction_cost, transaction_cost_custom_per, transaction_cost_custom_rs } from "Components/Screener/ScreenerUtils";
import SharpelyGateway2 from 'Components/SharpelyGateway/SharpelyGateway2';
import { getUserAccessLimitFromAccessObj, useSubscribeMiddleware } from 'Components/SubscribeModal';
import SuperScreenerBacktestAndPaperTrade from "Components/SuperScreenerBacktestAndPaperTrade";
import { Checkbox } from "Components/atoms/Checkbox";
import { CustomChangingTextLoader } from "Components/atoms/CustomChangingTextLoader";
import LabelInputNew from 'Components/atoms/LabelInputNew';
import { CardHeader } from "Components/atoms/SmallComponents";
import { useIsMobile } from "CustomHooks/useIsMobile";
import Head from "Layout/Head";
import { rebalanceContent } from 'Libs/StaticContent';
import { fetchFundData } from "Libs/fetchFundData";
import { toggleMetricDescModal } from 'Libs/redux/KnowledgeBase/reduxSlice';
import { toggleloginModal } from "Libs/redux/UserAuth/reduxSlice";
import searchAndSort, { isSubsequence } from "Libs/search";
import { generateUniqueKey, getTotalScreenersCount, getUserScreeners, makeid, quantile, screenerMetricData, sortFunds, sortOptions, useGetAssetType } from "Libs/utils";
import AdminServices from "Services/api/AdminServices";
import AdminServicesPY from "Services/api/AdminServicesPY";
import apiHelper from "Services/api/ApiHelper";
import { Alert, Card, Cascader, DatePicker, Divider, Input, Modal, Segmented, Select, Skeleton, Space, Switch, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import moment from 'moment';
import React, { useCallback, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { toast } from 'react-hot-toast';
import { AiOutlineInfoCircle, AiOutlineQuestionCircle } from "react-icons/ai";
import { BiLock } from "react-icons/bi";
import { BsCheck2, BsChevronLeft, BsSearch } from 'react-icons/bs';
import { FiMinusCircle } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { MdArrowDropDown, MdOutlineCancel } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import { Col, Row } from "reactstrap";

const ListEtfView = React.memo(({ id, data, index, type, getCurrentLTP, weight_method, instrumentList, changeData, isModalView, del, isMobile }) => {
    const [weight, setWeight] = useState(Number(data.allocation))
    // var textinp = React.useRef()
    try {
        return <div style={{ borderBottom: "0.5px solid var(--grey2)" }} key={id}>
            <div style={{ display: "flex", gap: "20px", alignItems: "center", padding: 10 }}>
                {type.value === 4 ? <AMCIcon amcName={data.symbol} fundType={type.value} height='1.2rem' width="1.2rem" /> :
                    <AMCIcon amcName={data.amc_full_name} height='1.2rem' width="1.2rem" />}
                <div style={{ flex: 1 }}>
                    <div className={`textXS w-500 ${isMobile ? "textEllipsis1" : ""}`}>{type.value === 4 ? data?.proper_name : data?.basic_name}</div>
                    <div className='textXS'>{type.value === 2 ? "NAV: " : "LTP: "} <span className='textXS w-500'>{CurrencyFormat(type.value === 2 ? data?.latest_nav : getCurrentLTP({ fundData: data }))}</span></div>
                </div>
                <CurrencyInput
                    disabled={!(weight_method === 1 || weight_method === "W-CT")}
                    className={`inputBoxMain ${"textSM"} black  `}
                    value={weight}
                    suffix={" %"}
                    onValueChange={(value) => {
                        setWeight(value)
                    }}
                    min={1}
                    max={instrumentList.length > 1 ? 99 : 100}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            if (weight !== undefined) {
                                changeData(index, Number(weight))
                            } else {
                                toast.error("Please enter a value")
                            }
                            // onValueChange(Number(metricVal))
                        }
                    }}
                    style={{ width: isMobile ? "80px" : "" }}
                    onBlur={() => {
                        if (weight !== undefined) {
                            changeData(index, Number(weight))
                        } else {
                            toast.error("Please enter a value")
                        }
                        // onValueChange(Number(metricVal))
                    }}
                    onSubmit={() => {
                        if (weight !== undefined) {
                            changeData(index, Number(weight))
                        } else {
                            toast.error("Please enter a value")
                        }
                        // onValueChange(Number(metricVal))
                    }}
                />
                {isModalView !== true ?
                    <FiMinusCircle size={20} style={{ backgroundColor: "#FFE7E5", color: '#FF8080', borderRadius: "50%", margin: "auto 10", cursor: "pointer" }} onClick={() => del(index)} /> : <></>}
            </div>
        </div>
    } catch (error) {
        //////console.log("error", error)
        return <div><span>err</span></div>
    }
})

const ListEtfViewLoading = React.memo(() => {
    try {
        return <div style={{ borderBottom: "0.5px solid var(--grey2)" }}>
            <div style={{ display: "flex", gap: "20px", alignItems: "center", padding: 10 }}>
                <Skeleton.Avatar shape="square" size="small" />
                <div style={{ display: "flex", alignItems: "center", flex: 1, justifyContent: "space-between" }}>
                    <Skeleton.Input size="small" />
                    <Skeleton.Input size="small" />
                </div>
            </div>
        </div>
    } catch (error) {
        return <div><span>err</span></div>
    }
})

const AddFromWatchlist = React.memo(({ watchlistModal, setWatchlistModal, instrumentList = [], type, onAddInstruments, watchData, checkAddDisabled, allFunds, watchlist }) => {

    const filteredWatchlist = watchlist ? watchlist?.filter((el) => el?.FundType === type?.value) : [];
    const [selectedWatchlist, setSelectedWatchlist] = React.useState(watchlist ? watchlist?.filter((el) => el?.FundType === type?.value)?.[0] : {});
    const [selectedWatchFunds, setselectedWatchFunds] = React.useState(instrumentList);

    const addFundFromWatchlist = (item) => {
        let check = selectedWatchFunds?.find((el) => type.value === 4 ? (el?.symbol === item?.symbol) : (el?.plan_id === item?.plan_id));
        if (check) {
            let temp = selectedWatchFunds?.filter((el) => type.value === 4 ? (el?.symbol !== item?.symbol) : (el?.plan_id !== item?.plan_id));
            setselectedWatchFunds(temp);
        }
        else {
            setselectedWatchFunds([...selectedWatchFunds, item])
        }
    }

    React.useEffect(() => {
        setselectedWatchFunds(instrumentList || [])
    }, [instrumentList])

    React.useEffect(() => {
        setSelectedWatchlist(watchlist ? watchlist?.filter((el) => el?.FundType === type?.value)?.[0] : {});
    }, [type.value, watchlist])

    return (
        <Modal open={watchlistModal} closable={false} centered footer={<div>
            <button onClick={() => {
                setselectedWatchFunds(instrumentList);
                setWatchlistModal(false);
            }} className='btnWhite' style={{ marginRight: "1rem" }}>Cancel</button>
            <button onClick={() => {
                onAddInstruments(selectedWatchFunds);
                setWatchlistModal(false);
            }} className='btnBlack' disabled={checkAddDisabled(selectedWatchFunds)}>Add</button>
        </div>} title='Watchlist' onCancel={() => {
            setselectedWatchFunds(instrumentList);
            setWatchlistModal(false);
        }}>
            {filteredWatchlist?.length > 0 ? <div>
                <CustomSegmentedTab options={filteredWatchlist?.map((el) => { return { key: el?.Name, label: el?.Name, value: el?.WatchListId } })} value={selectedWatchlist?.WatchListId}
                    onChange={(e) => {
                        let t = filteredWatchlist?.find((el) => el?.WatchListId === e);
                        setSelectedWatchlist(t);
                    }} isBlock={false} style={{ padding: "0.25rem" }} />
                <div style={{ marginTop: "1.25rem" }}>
                    {selectedWatchlist?.FundCodes?.length > 0 ? <div className='d-flex flex-column' style={{ gap: "0.75rem", maxHeight: "50vh", overflow: "auto" }}>
                        {selectedWatchlist?.FundCodes?.map((el, i) => {
                            let item = allFunds?.find((ele) => el === String(ele[type.value === 1 ? "nse_symbol" : type.value === 2 ? "plan_id" : "symbol"]));
                            let isPresent = selectedWatchFunds?.find((el) => type.value === 4 ? (el?.symbol === item?.symbol) : (el?.plan_id === item?.plan_id)) ? true : false
                            return (
                                <div key={generateUniqueKey(el)} style={{
                                    border: "1px solid var(--grey2)", padding: "0.25rem 0.5rem", borderRadius: "var(--borderRadius)", cursor: "pointer", gap: "0.5rem",
                                    background: isPresent ? "var(--blueShade)" : ""
                                }}
                                    className='d-flex align-items-center' onClick={() => addFundFromWatchlist(item)}>
                                    <Checkbox checked={isPresent} onChange={() => { }} />
                                    <AMCIcon amcName={item?.symbol || item?.amc_full_name} fundType={type?.value} height='1.6rem' width="1.6rem" type='square'
                                        bseTicker={item?.bse_ticker} />
                                    <div style={{ flex: 1 }}>
                                        <div className='textXS w-500 dark4' style={{ width: "100%", whiteSpace: "nowrap", textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                            {type?.value === 4 ? item?.proper_name : item?.basic_name?.replace(" - Direct Plan", "")}
                                        </div>
                                        <div className='w-500 dark3' style={{ fontSize: '0.6rem' }}>{type.value === 4 ? item?.symbol : item?.isin_code}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div> : <NoDataFound message={`Looks like you have not added any ${type?.dname?.toLowerCase()} in this watchlist!`} />}
                </div>
            </div> : <div >
                <NoDataFound message={`You have not created any watchlist yet!!`} />
            </div>}
        </Modal>
    )
})

const AddFromScreen = React.memo(({ screenModal, setScreenModal, instrumentList = [], allFunds = [], type, loadingScreens, screens, onAddInstruments, checkAddDisabled }) => {

    const [selectedFunds, setSelectedFunds] = React.useState(instrumentList);
    const [selectedScreen, setSelectedScreen] = React.useState({});
    const [screenFunds, setScreenFunds] = React.useState([]);
    const [screenFundsLoading, setScreenFundsLoading] = React.useState(true);

    const onCancel = () => {
        setScreenModal(false);
        setSelectedFunds(instrumentList);
        setSelectedScreen({});
    }

    const getScreenerFunds = async (item) => {
        setScreenFundsLoading(true);
        setSelectedScreen(item);
        const final = item?.rules;
        var allFund = JSON.parse(JSON.stringify(allFunds))
        if (type.value === 4) {
            // var filFunds = await AdminServicesPY.getAllScreenedFundsFromAdvQuery({ rules: final }).then((r) => r)
            // filFunds = filFunds?.data?.length > 0 ? filFunds?.data : []
            // allFund = allFund?.filter((fund) => filFunds.includes(fund?.symbol))
            var t = String(item?.config)?.substring(1, String(item?.config)?.length - 1)
            ////console.log(t)
            let config = JSON.parse(t);
            let res = await AdminServicesPY.getAllScreenedStocksAdvV2({
                code: JSON.stringify({
                    code: config?.code,
                })
            }).then(r => r);
            if (type) {
                res = JSON.parse(res?.data?.replaceAll(/NaN/g, null))
                ////////console.log(res)
                allFund = (res?.instruments || []).map((i) => {
                    return {
                        ...[...allFunds].filter((ii) => ii?.symbol === i?.symbol)[0],
                        ...i,
                    }
                })

                // if (!screenedOnce) {
                //     setScreenedOnce(true);
                // }
                // setScreenFunds(funds)
                // if (res?.instruments?.length > 0) {
                //     let cols = Object?.keys(res?.instruments?.[0] || {})?.filter((i) => (!(['symbol', 'mcap']?.includes(i) || i?.match(/^c[0-9]{0,3}[0-9]$/))))?.map((key) => {
                //         let metric = screenerMetrics?.find((ele) => ele?.metric_col_code === key) || {};
                //         return {
                //             "dataIndex": key,
                //             "key": key,
                //             "metric_col_code": key,
                //             "metric_unit": "",
                //             "metric_data_type": "float",
                //             "metric_name": key,
                //             "title": key,
                //             "align": "right",
                //             "width": 140,
                //             ...metric
                //         }
                //     })
                //     ////////console.log(cols)
                //     setScreenedCols(cols);
                // }
            }
        }
        else {
            for (let i = 0; i < final.length; i++) {
                var query = final[i]
                var primary_col_name = query['primary_col'];
                var secondary_col_name = null
                if (allFund?.length !== 0) {
                    if (primary_col_name == "investing_style") {
                        if (query['abs_val_1'] !== 'all') {
                            var filt = query['abs_val_1']?.split(',')
                            filt?.map((filter) => {
                                allFund = allFund.filter((fund) => eval(`fund[filter] == 1`))
                                return ""
                            })
                        }

                    } else {
                        allFund = allFund.filter((fund) => eval(`fund[primary_col_name] !== null`))

                        if (query['oper'] == 'abs_comp') {
                            var op = query['comp_operator']
                            var num = parseFloat(query['abs_val_1'])

                            if (op == 'gt')
                                allFund = allFund.filter((fund) =>
                                    eval(`fund[primary_col_name] > ${num} `))
                            if (op == 'lt')
                                allFund = allFund.filter((fund) =>
                                    eval(`fund[primary_col_name] < ${num} `))
                            if (op == 'eq')
                                allFund = allFund.filter((fund) =>
                                    eval(`fund[primary_col_name] == ${num} `))
                            if (op == 'lteq')
                                allFund = allFund.filter((fund) =>
                                    eval(`fund[primary_col_name] <= ${num} `))
                            if (op == 'gteq')
                                allFund = allFund.filter((fund) =>
                                    eval(`fund[primary_col_name] >= ${num} `))
                        }

                        if (query['oper'] == 'isin_comp' && query['abs_val_1'] !== undefined && query['abs_val_1']?.length !== 0) {
                            var str = []
                            query['abs_val_1'].split(",").map((fItem) => {
                                str.push(`String(fund['${String(primary_col_name)}']) === '${String(fItem)}'`)
                            })
                            allFund = allFund.filter((fund) => eval(str.join(" || ")))
                        }

                        if (query['oper'] == 'isnotin_comp' && query['abs_val_1'] !== undefined && query['abs_val_1']?.length !== 0) {
                            var str = []
                            query['abs_val_1'].split(",").map((fItem) => {
                                str.push(`String(fund['${String(primary_col_name)}']) !== '${String(fItem)}'`)
                            })
                            allFund = allFund.filter((fund) => eval(str.join(" && ")))
                        }

                        if (query['oper'] == 'rank_comp') {
                            var op = query['comp_operator']
                            var [asc_flag, cutoff, pct_flag] = op.split("_")

                            if (asc_flag == 'rng') {
                                var [ct_low, ct_high] = cutoff.split("-")

                                if (pct_flag === "per") {
                                    //per
                                    var list = allFund.map(function (d) {
                                        return d[primary_col_name]
                                    })

                                    if (list.length > 0) {
                                        var val_low = quantile(list, parseFloat(ct_low) / 100)
                                        var val_high = quantile(list, parseFloat(ct_high) / 100)
                                        allFund = allFund.filter((fund, index) => eval(`Number(fund[primary_col_name]) >= ${parseFloat(val_low)} && Number(fund[primary_col_name]) >= ${parseFloat(val_high)} `))
                                    }
                                }

                            } else if (asc_flag == 'top') {
                                if (asc_flag !== undefined) {
                                    allFund = sortFunds(allFund, primary_col_name, asc_flag)
                                }

                                if (pct_flag === "per") {
                                    //per
                                    var list = allFund.map(function (d) {
                                        return d[primary_col_name]
                                    })

                                    if (list.length > 0) {
                                        var val = quantile(list, parseFloat(cutoff) / 100)
                                        allFund = allFund.filter((fund, index) => eval(`Number(fund[primary_col_name]) >= ${parseFloat(val)} `))
                                    }
                                } else {
                                    //abs
                                    allFund = allFund.splice(0, parseInt(cutoff))
                                }


                            } else {
                                if (asc_flag !== undefined) {
                                    allFund = sortFunds(allFund, primary_col_name, asc_flag)
                                }

                                if (pct_flag === "per") {
                                    //per
                                    var list = allFund.map(function (d) {
                                        return d[primary_col_name]
                                    })

                                    if (list.length > 0) {
                                        var val = quantile(list, parseFloat(cutoff) / 100)
                                        allFund = allFund.filter((fund, index) => eval(`Number(fund[primary_col_name]) <= ${parseFloat(val)} `))
                                    }
                                } else {
                                    //abs
                                    allFund = allFund.splice(0, parseInt(cutoff))
                                }
                            }



                        }

                        if (query['oper'] == 'col_comp') {
                            secondary_col_name = query['sec_col']
                            var adjustment_operator = query['adj_operator']

                            const finalCalc = (num) => {
                                var op = query['comp_operator']
                                if (op == 'gt')
                                    allFund = allFund.filter((fund) =>
                                        eval(`fund[primary_col_name] > ${num} && fund[primary_col_name] !== null`))
                                if (op == 'lt')
                                    allFund = allFund.filter((fund) =>
                                        eval(`fund[primary_col_name] < ${num} && fund[primary_col_name] !== null`))
                                if (op == 'eq')
                                    allFund = allFund.filter((fund) =>
                                        eval(`fund[primary_col_name] == ${num} && fund[primary_col_name] !== null`))
                                if (op == 'lteq')
                                    allFund = allFund.filter((fund) =>
                                        eval(`fund[primary_col_name] <= ${num} && fund[primary_col_name] !== null`))
                                if (op == 'gteq')
                                    allFund = allFund.filter((fund) =>
                                        eval(`fund[primary_col_name] >= ${num} && fund[primary_col_name] !== null`))
                            }

                            if (adjustment_operator !== null) {
                                var [ad_operator, ad_val] = adjustment_operator.split("_")
                                var num = null
                                if (ad_operator == 'add')
                                    finalCalc(`fund[secondary_col_name] + ${parseFloat(ad_val)} `)
                                if (ad_operator == 'sub')
                                    finalCalc(`fund[secondary_col_name] - ${parseFloat(ad_val)} `)
                                if (ad_operator == 'mult')
                                    finalCalc(`fund[secondary_col_name] * ${parseFloat(ad_val)} `)
                                if (ad_operator == 'div')
                                    finalCalc(`fund[secondary_col_name] / ${parseFloat(ad_val)} `)
                            }
                        }
                    }
                }
            }
        }
        setScreenFunds(allFund);
        setScreenFundsLoading(false);
    }

    const addFundFromScreen = (item) => {
        let check = selectedFunds?.find((el) => type.value === 4 ? (el?.symbol === item?.symbol) : (el?.plan_id === item?.plan_id));
        if (check) {
            let temp = selectedFunds?.filter((el) => type.value === 4 ? (el?.symbol !== item?.symbol) : (el?.plan_id !== item?.plan_id));
            setSelectedFunds(temp);
        }
        else {
            setSelectedFunds([...selectedFunds, item])
        }
    }

    React.useEffect(() => {
        setSelectedFunds(instrumentList || [])
    }, [instrumentList])

    return (
        <Modal open={screenModal} footer={null} closable={false} onCancel={onCancel}>
            <div className='textLG w-700 dark4' >
                {!selectedScreen?.client_id ? 'Select a screen' : <div className='textLG w-700 dark4 d-flex align-items-center' style={{ gap: "0.5rem", cursor: "pointer" }}
                    onClick={() => { setSelectedScreen({}) }}>
                    <BsChevronLeft /> {selectedScreen?.checklist_name}
                </div>}
            </div>
            {selectedFunds?.length > 0 && !selectedScreen?.client_id && <>
                <div className='textSM w-500 mt-2'>Selected {type.value === 4 ? "Stock" : type.strVal?.toUpperCase()}s</div>
                <div className='d-flex align-items-center scrollbox' style={{ gap: "0.75rem", width: "100%", overflow: "auto", margin: '0.3rem 0 0.5rem' }}>
                    {selectedFunds?.map((item, i) => {
                        return (
                            <div className='d-flex align-items-center' style={{ gap: "0.5rem", padding: "0.5rem", border: "1px solid var(--grey2)", borderRadius: "0.75rem" }} key={generateUniqueKey(i)}>
                                <AMCIcon amcName={item?.symbol || item?.amc_full_name} height='1rem' width="1rem" fundType={type.value} bseTicker={item?.bse_ticker} />
                                <div className='textXS dark4 w-500' style={{ whiteSpace: "nowrap" }}>{type.value === 4 ? item?.proper_name :
                                    item?.basic_name?.replace(" - Direct Plan", "")}</div>
                                <MdOutlineCancel onClick={() => addFundFromScreen(item)} style={{ cursor: "pointer" }} />
                            </div>
                        )
                    })}
                </div>
                <Divider style={{ margin: 0 }} />
            </>}
            <div style={{ minHeight: "30vh", maxHeight: "50vh", overflowY: "auto" }}>
                {loadingScreens ? <ActivityLoaderMintbox /> : screens.length === 0 ? <NoDataFound message={`You have not created any ${type.strVal} screen`} /> :
                    <div style={{ height: '100%' }}>
                        {!selectedScreen?.client_id ? <>
                            <div className='textSM w-500 my-2 textLight'>Your screens</div>
                            {screens?.map((item, i) => (
                                <div key={generateUniqueKey(i)} className='d-flex align-items-center' style={{ gap: "0.75rem", padding: "0.5rem 0", borderBottom: "1px solid var(--grey2)", cursor: "pointer" }}
                                    onClick={() => getScreenerFunds(item)}>
                                    {/* <ScreenerIcon width='1.5rem' height='1.5rem' id={item?.checklist_id} /> */}
                                    <div className="textSM w-500 black" style={{ flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                                        {item?.checklist_name || item?.screen_name}
                                    </div>
                                </div>
                            ))}</> : <div className='d-flex flex-column' style={{ gap: "0.75rem", overflow: "auto", marginTop: "1rem" }}>
                            {screenFundsLoading ? <div>
                                <ActivityLoaderMintbox />
                            </div> : screenFunds?.map((item, i) => {
                                let isPresent = selectedFunds?.find((el) => type.value === 4 ? (el?.symbol === item?.symbol) : (el?.plan_id === item?.plan_id)) ? true : false
                                return (
                                    <div key={generateUniqueKey(i)} style={{
                                        border: "1px solid var(--grey2)", padding: "0.25rem 0.5rem", borderRadius: "var(--borderRadius)", cursor: "pointer", gap: "0.5rem",
                                        background: isPresent ? "var(--blueShade)" : ""
                                    }}
                                        className='d-flex align-items-center' onClick={() => addFundFromScreen(item)}>
                                        <Checkbox checked={isPresent} onChange={() => { }} />
                                        <AMCIcon amcName={item?.symbol || item?.amc_full_name} height='1.6rem' width="1.6rem" type='square' fundType={type.value}
                                            bseTicker={item?.bse_ticker} />
                                        <div style={{ flex: 1 }}>
                                            <div className='textXS w-500 dark4' style={{ width: "100%", whiteSpace: "nowrap", textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                                {type.value === 4 ? item?.proper_name : item?.basic_name?.replace(" - Direct Plan", "")}
                                            </div>
                                            <div className='w-500 dark3' style={{ fontSize: '0.6rem' }}>{type.value === 4 ? item?.symbol : item?.isin_code}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>}
                    </div>}
            </div>
            <div className='d-flex justify-content-end' style={{ gap: "1rem", marginTop: "1rem" }}>
                <button className='btnWhite' onClick={onCancel}>Cancel</button>
                <button className='btnBlack' onClick={() => {
                    onAddInstruments(selectedFunds);
                    setScreenModal(false);
                }} disabled={checkAddDisabled(selectedFunds)}>Add ({selectedFunds?.length})</button>
            </div>
        </Modal>
    )
})

const AddInstrument = React.memo(({ allFunds = [], instrumentList = [], type, instrument_method = 1, sectors = [], industryGrp = [], industries = [], mfCategories = [], benchmarks = [], isMobile, isModalView, watchlist,
    onAddInstruments, listLoading, weight_methods, weight_method, setweight_method, weight_by, setweight_by, inverseV, setinverseV, nextDisabled, screens = [], loadingScreens, watchData, getCurrentLTP, changeData, del, checkAddDisabled, sum, screenerMetrics = [] }) => {

    const [watchlistModal, setWatchlistModal] = React.useState(false);
    const [screenModal, setScreenModal] = React.useState(false);
    const [searchData, setSearchData] = React.useState(allFunds);
    const [fundsToShow, setFundsToShow] = React.useState([]);
    const [searchText, setSearchText] = React.useState('');
    const [selectedFunds, setSelectedFunds] = React.useState(instrumentList);
    const [selectedFilterOption, setSelectedFilterOption] = React.useState();
    const [fundsLoading, setFundsLoading] = React.useState(true);

    const addFunds = (item) => {
        let max = type.value === 4 ? 50 : 10;
        let check = selectedFunds?.find((el) => type.value === 4 ? (el?.symbol === item?.symbol) : (el?.plan_id === item?.plan_id));
        if (check) {
            let temp = selectedFunds?.filter((el) => type.value === 4 ? (el?.symbol !== item?.symbol) : (el?.plan_id !== item?.plan_id));
            setSelectedFunds(temp);
        }
        else {
            if (selectedFunds?.length >= max) {
                toast.error(`You can add a maximum of ${max} ${type.dname}s`)
            }
            else {
                setSelectedFunds([...selectedFunds, item])
            }
        }
    }

    const loadMoreData = () => {
        let temp = [...searchData];
        let temp2 = temp.slice(fundsToShow.length, fundsToShow.length + 20);
        setFundsToShow([...fundsToShow, ...temp2]);
    }

    const getFundsToShow = (arr) => {
        let temp = ([...arr])?.slice(0, 20);
        setFundsToShow(temp);
    }

    const handleInstrumentListScroll = (event) => {
        const scrollContainer = event.target;
        const scrollPosition = scrollContainer.scrollTop;
        const containerHeight = scrollContainer.clientHeight;
        const contentHeight = scrollContainer.scrollHeight;
        const buffer = 40;
        if (scrollPosition + containerHeight >= contentHeight - buffer) {
            loadMoreData();
        }
    }

    const WatchScreenCTA = ({ padding = '0.75rem' }) => {

        return (
            <div style={{ padding: padding, gap: "0.75rem", borderBottom: "1px solid var(--grey2)" }} className='d-flex align-items-center'>
                <div onClick={() => setWatchlistModal(true)} style={{ padding: "0.5rem 0.75rem", background: "var(--grey3)", borderRadius: "var(--borderRadius)", cursor: "pointer" }}
                    className='textXS w-500 dark4'>
                    Add from Watchlist
                </div>
                <div onClick={() => setScreenModal(true)} style={{ padding: "0.5rem 0.75rem", background: "var(--grey3)", borderRadius: "var(--borderRadius)", cursor: "pointer" }}
                    className='textXS w-500 dark4'>
                    Add from Screen
                </div>
            </div>
        )
    }

    const filterTabs = [
        { key: 1, value: 1, label: "Sector", filterKey: "sector_code", options: sectors, show: type.value === 4 },
        { key: 2, value: 2, label: "Industry group", filterKey: "industry_code", options: industryGrp, show: type.value === 4 },
        { key: 3, value: 3, label: "Industry", filterKey: "nse_basic_ind_code", options: industries, show: type.value === 4 },
        { key: 1, value: 1, label: "Category", filterKey: "category_name", options: sectors, show: type.value !== 4, type: "cascader" },
        { key: 2, value: 2, label: "Benchmark", filterKey: "benchmark", options: benchmarks, show: type.value !== 4 },
    ]?.filter((el) => el?.show)

    const [selectedFilterType, setSelectedFilterType] = React.useState(1);

    const onSearch = (e) => {
        setSearchText(e.target.value)
        let funds = [...allFunds];
        let key = filterTabs?.find((el) => el?.value === selectedFilterType)?.filterKey || '';
        if (selectedFilterOption) {
            funds = funds?.filter((el) => el?.[key] === selectedFilterOption);
        }
        if (type.value === 4) {
            let temp = searchAndSort(e.target.value, [...funds], ['symbol', 'proper_name']);
            setSearchData(temp);
        }
        else if (type.value === 1) {
            let temp = searchAndSort(e.target.value, [...funds], ['nse_symbol', 'basic_name']);
            setSearchData(temp);
        }
        else {
            let temp = searchAndSort(e.target.value, [...funds], ['isin_code', 'basic_name']);
            setSearchData(temp);
        }
    }

    React.useEffect(() => {
        onSearch({ target: { value: searchText || '' } })
    }, [selectedFilterOption]);

    React.useEffect(() => {
        if (instrument_method === 1 && searchData?.length > 0) {
            getFundsToShow(searchData);
            if (fundsLoading) {
                setFundsLoading(false);
            }
        }
    }, [searchData]);

    React.useEffect(() => {
        if (allFunds?.length > 0 && instrument_method === 1) {
            setSearchData(allFunds);
        }
    }, [allFunds]);

    React.useEffect(() => {
        if (instrument_method === 1) {
            setSelectedFunds(instrumentList);
        }
    }, [instrumentList]);

    return (
        <div style={{ flex: 1, maxHeight: "100%", overflow: "scroll" }} className='d-flex'>
            <div className='d-flex flex-column' style={{ flex: 1, maxHeight: "100%" }}>
                <div>
                    <CardHeader heading={`${isModalView ? "Define weights for " + (instrument_method === 2 ? "screened " : "") + type.dname : "Add your favourite " + type.dname + " and define weights"}`} hideHeaderBlueLine headingType={2} textClassName={isMobile ? "textMD w-700" : 'textXL w-700'} />
                    <div className='textSM dark3' style={{ margin: isMobile ? "0.75rem 0 1.25rem" : "0.25rem 0 1rem", maxWidth: "60ch" }}>
                        {`Tell us how much weight do you want to assign each ${type.omkar} in your strategy?`}
                    </div>
                </div>
                {isMobile && <div style={{ width: "100%", marginBottom: "1rem" }}>
                    <InstrumentListSearch type={type?.value} allInstrumentsList={allFunds || []} placeholder={`Search and add ${type.dname}`} isFullWidthInMob
                        selectedValues={selectedFunds} maxSelected={type?.value === 4 ? 50 : 10} isMultiple={true} onChange={(items) => {
                            onAddInstruments(items)
                        }} ctaText={`Add ${type?.dname}`} />
                </div>}
                {instrumentList.length === 0 && <div id="content" style={{ flex: 1, marginTop: "2rem" }} className="scrollbox">
                    {listLoading && new Array(4).fill(1).map((ele, i) => (
                        <ListEtfViewLoading key={generateUniqueKey(i)} />
                    ))}
                </div>}
                {instrumentList.length !== 0 && <>
                    <div id="" className={`${isMobile ? "mb-3" : "mb-2"}`} style={{ display: "flex", gap: "1rem", alignItems: isMobile ? "start" : "center", flexDirection: "row", flexWrap: "wrap" }}>
                        <div style={{ flex: 1 }} className={`textSM ${isMobile ? "" : "my-2"} w-bold`}>{instrumentList.length} {type.dname} selected</div>
                        <div className="textXS textLight" >Minimum 2 | Max {type.value === 4 ? 50 : 10}</div>
                    </div>
                    <div className="mb-4" style={{ display: "flex", gap: "1rem", alignItems: "center", flexWrap: "wrap" }} >
                        <div>
                            <div className='textXS w-500 dark4 d-flex align-items-center' style={{ gap: "0.5rem", marginBottom: isMobile ? 6 : 10 }}>
                                {type?.omkar} weightage
                                <Tooltip title={`Here you can either decide to allocate equal/manual weight to each ${String(type?.strVal)?.replace("/", "")} or weight them basis some chosen metric (like ${type?.value === 4 ? "market cap" : "aum"})`} arrow={false}>
                                    <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                </Tooltip>
                            </div>
                            <Select className='textSM black whiteBackground' suffixIcon={<IoIosArrowDown size={15} />} value={weight_method} style={{ width: "10.5rem", marginTop: 0 }}
                                placeholder="Please select" onChange={(value) => {
                                    setweight_method(value)
                                    setweight_by(value === "W-VAR" ? type?.value === 4 ? "mcap" : "aum" : "")
                                }} options={weight_methods.filter((i) => i.show !== false)}
                            />
                        </div>
                        {weight_method === "W-VAR" && <div>
                            <div className='textXS w-500 dark4 d-flex align-items-center' style={{ gap: "0.5rem", marginBottom: isMobile ? 6 : 10 }}>
                                Select metric to weight {String(type?.basePath)?.replace("/", "")}
                                <Tooltip title={`Stocks will be weighed in proportion to their corresponding value of this metric. Higher value ${String(type?.basePath)?.replace("/", "")} will get higher weight.`} arrow={false}>
                                    <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                </Tooltip>
                            </div><Select className='textSM black whiteBackground' suffixIcon={<IoIosArrowDown size={15} />} value={weight_by} style={{ width: "12.5rem", marginTop: 0 }}
                                placeholder="Please select metric" options={screenerMetrics?.map((el) => {
                                    return { ...el, tabType: el?.type }
                                })} showSearch
                                filterOption={(inputVal, option) => {
                                    return isSubsequence(inputVal?.trim(), option?.label)
                                }} onChange={(val) => {
                                    setweight_by(val)
                                    // changeConfig([{ keys: ['position_sizing', 'weight_by'], value: val }])
                                }}
                            /></div>}
                        {weight_method === "W-VAR" || weight_method === 'W-INV' ? <div>
                            <div className='textXS w-500 dark4 d-flex align-items-center' style={{ gap: "0.5rem", marginBottom: isMobile ? 6 : 10 }}>
                                Use
                                <Tooltip title={`You can use inverse of metric value to assign weights. Useful when you use metric like PE Ratio, Volatility etc.`} arrow={false}>
                                    <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "var(--textXS)", cursor: "pointer" }} />
                                </Tooltip>
                            </div>
                            <CustomSelect
                                customId={"custom-key-handle-" + makeid(4)}
                                options={inverseW}
                                value={inverseV}
                                defaultValue={0}
                                placeholder='Select weight method' style={{ width: "100%" }} onChange={(val) => {
                                    setinverseV(val)
                                }} className='whiteBackground' />
                        </div> : <div className='d-flex'></div>}
                    </div>
                    {nextDisabled && <Alert className='textXS' message={`Sum of weights of selected metrics must be 100. (Current weight ${sum})`} type="error" showIcon />}
                    {nextDisabled && <br></br>}
                    <div id="content" style={{ flex: 1 }} className="scrollbox">
                        {instrumentList.length > 0 ? instrumentList.map((data, index) => {
                            if (listLoading) {
                                return <ListEtfViewLoading key={generateUniqueKey(index)} />
                            } else {
                                return <ListEtfView id={data?.isin_code || data?.symbol} key={generateUniqueKey(data?.isin_code || data?.symbol)} data={data}
                                    index={index} type={type} isMobile={isMobile}
                                    getCurrentLTP={getCurrentLTP} weight_method={weight_method} instrumentList={instrumentList} changeData={changeData} isModalView={isModalView} del={del} />
                            }
                        }) : null}
                    </div>
                </>}
            </div>
            {instrument_method === 1 && !isMobile && <div style={{ width: "35%", maxHeight: "100%", borderLeft: "1px solid var(--grey3)", paddingLeft: "1rem", marginLeft: "1rem", overflow: "hidden" }}
                className='d-flex flex-column h100'>
                <div className='d-flex align-items-center w100' style={{
                    padding: "0.5rem", borderRadius: "var(--borderRadius)", background: "var(--grey3)",
                    border: "1px solid var(--grey2)"
                }}>
                    <BsSearch className='dark2 textSM' style={{ marginRight: "0.3rem" }} />
                    <input style={{ border: "none", outline: "none", background: "transparent", flex: 1 }} placeholder={`Search and add ${type.dname}`} className='textXS dark4'
                        value={searchText} onChange={onSearch} />
                </div>
                <div style={{ padding: "0.5rem 0", borderBottom: "1px solid var(--grey3)" }}>
                    <div className='d-flex  align-items-center w100' style={{ gap: "0.75rem" }}>
                        <div className='textXS w-500 dark4'>Filter by:</div>
                        <div style={{ flex: 1 }}>
                            <CustomSegmentedTab options={filterTabs} value={selectedFilterType} onChange={(e) => {
                                setSelectedFilterType(e);
                                setSelectedFilterOption();
                            }} size='medium' style={{ padding: '0.25rem', width: "100%" }} />
                        </div>
                    </div>
                    <div className='d-flex align-items-center mt-2' style={{ gap: "0.5rem" }}>
                        {type.value !== 4 && selectedFilterType === 1 ? <Cascader showSearch allowClear={false} options={JSON.parse(JSON.stringify(mfCategories)).map((i, index) => {
                            i["value"] = i.categoryName
                            i["label"] = i.categoryName
                            i["title"] = i.categoryName
                            if (i["subCategories"] !== undefined) {
                                i["subCategories"]?.map((ii, indexi) => {
                                    ii["value"] = String(ii.subCategoryName)
                                    ii["label"] = ii.subCategoryName
                                    return ii
                                })
                                i["children"] = i["subCategories"]
                            }
                            return i
                        })} style={{ width: "100%", }} expandTrigger='hover' placeholder={"Please select " + type.omkar + " category"} value={selectedFilterOption}
                            onChange={(value) => {
                                let e = value;
                                if (type.value !== 4 && e && e?.length === 2) {
                                    e = e[1];
                                }
                                //console.log(e)
                                if (e) {
                                    //console.log(e);
                                    let initFunds = searchText === '' ? allFunds : searchData
                                    let key = filterTabs?.find((el) => el?.value === selectedFilterType)?.filterKey || '';
                                    let temp = initFunds?.filter((el) => el?.[key] === e) || [];
                                    setSearchData(temp);
                                    setSelectedFilterOption(e);
                                }
                                else {
                                    setSelectedFilterOption(e);
                                }
                            }} /> : <Select options={filterTabs?.find((el) => el?.value === selectedFilterType)?.options}
                                placeholder={`Select ${filterTabs?.find((el) => el?.value === selectedFilterType)?.label}`} className='textXS w-500 dark4' style={{ flex: 1 }}
                                value={selectedFilterOption} onChange={(e) => {
                                    if (e) {
                                        let initFunds = searchText === '' ? allFunds : searchData
                                        let key = filterTabs?.find((el) => el?.value === selectedFilterType)?.filterKey || '';
                                        let temp = initFunds?.filter((el) => el?.[key] === e) || [];
                                        setSearchData(temp);
                                        setSelectedFilterOption(e);
                                    }
                                    else {
                                        setSelectedFilterOption(e);
                                    }
                                }} allowClear />}
                    </div>
                </div>
                <WatchScreenCTA padding='0.75rem 0' />
                <div style={{ flex: 1, maxHeight: '100%', overflow: "hidden" }} className='mt-2'>
                    <div style={{ maxHeight: '100%', overflow: "scroll" }} className='scrollbox' onScroll={handleInstrumentListScroll}>
                        {fundsLoading ? <div>
                            <ActivityLoaderMintbox />
                        </div> : searchData?.length > 0 && fundsToShow?.length > 0 ? fundsToShow?.map((item, i) => {
                            let isPresent = selectedFunds?.find((el) => type.value === 4 ? (el?.symbol === item?.symbol) : (el?.plan_id === item?.plan_id)) ? true : false
                            return (
                                <div className='d-flex align-items-center' style={{
                                    gap: "0.5rem", padding: "0.25rem 0.75rem", border: "1px solid var(--grey2)", marginBottom: "0.5rem",
                                    borderRadius: "var(--borderRadius)", cursor: "pointer", background: isPresent ? "var(--blueShade)" : ""
                                }} key={generateUniqueKey(i)}
                                    onClick={() => addFunds(item)}>
                                    <Checkbox checked={isPresent} onChange={() => { }} />
                                    <AMCIcon amcName={item.symbol || item.amc_full_name} fundType={type.value} height='1.6rem' width="1.6rem" type='square'
                                        bseTicker={item?.bse_ticker} />
                                    <div style={{ flex: 1 }}>
                                        <div className='textXS w-500 dark4' style={{ width: "100%", whiteSpace: "nowrap", textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                            {type?.value === 4 ? item?.proper_name : item?.basic_name?.replace(" - Direct Plan", "")}
                                        </div>
                                        <div className='w-500 dark3' style={{ fontSize: '0.6rem' }}>{type.value !== 2 ? item?.symbol || item?.nse_symbol : item?.isin_code}</div>
                                    </div>
                                </div>
                            )
                        }) : fundsToShow?.length === 0 && searchData?.length === 0 && searchText !== '' && <div style={{ margin: "1rem 0" }}>
                            <NoDataFound message={`No ${type.dname} found`} />
                        </div>}
                    </div>
                </div>
                <div style={{ padding: "0.75rem", borderTop: "1px solid var(--grey3)" }} className='d-flex justify-content-end'>
                    <button className='btnBlack' onClick={() => onAddInstruments(selectedFunds)} disabled={checkAddDisabled(selectedFunds)}>
                        Add {selectedFunds?.length}/{type.value === 4 ? 50 : 10}
                    </button>
                </div>
            </div>}
            {instrument_method === 1 && <AddFromWatchlist watchlistModal={watchlistModal} setWatchlistModal={setWatchlistModal} instrumentList={instrumentList} type={type}
                onAddInstruments={onAddInstruments} watchData={watchData} checkAddDisabled={checkAddDisabled} watchlist={watchlist} allFunds={allFunds} />}
            {instrument_method === 1 && <AddFromScreen screenModal={screenModal} setScreenModal={setScreenModal} instrumentList={instrumentList} allFunds={allFunds} type={type}
                loadingScreens={loadingScreens} screens={screens} onAddInstruments={onAddInstruments} checkAddDisabled={checkAddDisabled} />}
        </div>
    )
})

const SelectRebalancing = React.memo(({ rebalanceOption, setRebalanceOption, setRebalanceFreq, options, rebalanceFreq, rebalanceInp1, rebalanceInp2, setRebalanceInp1, setRebalanceInp2, type,
    instrument_method }) => {

    //console.log({ rebalanceOption, rebalanceFreq, rebalanceInp1, rebalanceInp2 })
    const dispatch = useDispatch();
    const customOptions = [
        { "key": 1, text: "Every week", value: "1W", disabled: type.value !== 4 },
        { "key": 2, text: "Every 2 weeks", value: "2W", disabled: type.value !== 4 },
        { "key": 3, "text": "Every month", value: '1M' },
        { "key": 4, "text": "Every quarter", value: '3M' },
        { "key": 5, "text": "Every 6 months", value: '6M' },
        { "key": 6, "text": "Every year", value: '12M' },
    ]
    const selectedFreq = options?.find((el) => el?.value === rebalanceFreq);
    const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    const quarterMonths = ['Jan, Apr, Jul, Oct', 'Feb, May, Aug, Nov', 'Mar, Jun, Sep, Dec'];
    const halfYearlyMonths = ['Jan, Jul', 'Feb, Aug', 'Mar, Sep', 'Apr, Oct', 'May, Nov', 'Jun, Dec'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    return (
        <div style={{ flex: 1 }}>
            <div className='textSM w-500'>How often would you like to rebalance your strategy?</div>
            <div className='textSM w-500 my-2' style={{ color: "var(--primary)", cursor: "pointer" }} onClick={() => {
                dispatch(toggleMetricDescModal({
                    value: true,
                    params: {
                        title: rebalanceContent?.[instrument_method === 1 ? "static" : "dynamic"]?.title,
                        description: rebalanceContent?.[instrument_method === 1 ? "static" : "dynamic"]?.description,
                    }
                }))
            }}>
                What happens on rebalancing?
            </div>
            <Select value={rebalanceOption} onChange={(value) => { setRebalanceOption(value) }} style={{ width: "12rem" }}
                options={options?.filter(el => el?.key !== 5 && el?.key !== 4)?.map((opt, i) => {
                    return {
                        key: opt.key,
                        label: opt.text,
                        value: opt.key,
                        disabled: opt.disabled
                    }
                })} />
            {rebalanceOption === 7 ? <div style={{ marginTop: "1.25rem" }}>
                <Row md={3} sm={2} className='gx-3 gy-3'>
                    <Col>
                        <div className='w100'>
                            <div className='textSM w-500 dark4 mb-3'>Rebalance</div>
                            <Select value={rebalanceFreq} onChange={(value) => { setRebalanceFreq(value) }} style={{ width: "12rem" }}
                                options={customOptions?.map((opt, i) => {
                                    return {
                                        key: opt.key,
                                        label: opt.text,
                                        value: opt.value,
                                        disabled: opt.disabled
                                    }
                                })} />
                        </div>
                    </Col>
                    <Col>
                        <div className='w100'>
                            <div className='textSM w-500 dark4 mb-3'>{selectedFreq?.inp1Label}</div>
                            <Select value={rebalanceInp1} onChange={(value) => { setRebalanceInp1(value) }} style={{ width: "12rem" }}
                                options={selectedFreq?.inp1type === 'week' ? weekDays?.map((opt, i) => {
                                    return {
                                        key: i,
                                        label: opt,
                                        value: opt
                                    }
                                }) : rebalanceFreq === '3M' ? quarterMonths?.map((opt, i) => {
                                    return {
                                        key: i,
                                        label: opt,
                                        value: `${i + 1},${i + 4},${i + 7},${i + 10}`
                                    }
                                }) : rebalanceFreq === '6M' ? halfYearlyMonths?.map((opt, i) => {
                                    return {
                                        key: i,
                                        label: opt,
                                        value: `${i + 1},${i + 7}`
                                    }
                                }) : rebalanceFreq === '12M' ? months?.map((opt, i) => {
                                    return {
                                        key: i,
                                        label: opt,
                                        value: i + 1
                                    }
                                }) : new Array(28).fill(1)?.map((ele, i) => {
                                    return {
                                        key: i,
                                        label: i + 1,
                                        value: i + 1
                                    }
                                })} />
                        </div>
                    </Col>
                    {selectedFreq?.inp2Label && <Col>
                        <div className='w100'>
                            <div className='textSM w-500 dark4 mb-3'>{selectedFreq?.inp2Label}</div>
                            <Select value={rebalanceInp2} onChange={(value) => { setRebalanceInp2(value) }} style={{ width: "12rem" }}
                                options={new Array(28).fill(1).map((ele, i) => {
                                    return {
                                        key: i,
                                        label: i + 1,
                                        value: i + 1
                                    }
                                })} />
                        </div>
                    </Col>}
                </Row>
            </div> : null}
        </div>
    )
})

const SelectRebalancing2 = React.memo(({ config = {}, setConfig, isMobile = false, fromStrategyDetail = false }) => {
    const quarterMonths = ['January, April, July, October', 'February, May, August, November', 'March, June, September, December'];
    const halfYearlyMonths = ['January, July', 'February, August', 'March, September', 'April, October', 'May, November', 'June, December'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const backtestFrequencyOptions = [
        // { key: 1, label: "1 minute", value: "1min" },
        // { key: 2, label: "1 day", value: "1D" },

        { key: 3, label: "1 week", value: "1W" },
        { key: 4, label: "2 weeks", value: "2W" },
        { key: 5, label: "1 month", value: "1M" },
        { key: 6, label: "3 months", value: "3M" },
        { key: 7, label: "6 months", value: "6M" },
        { key: 8, label: "12 months", value: "12M" },
        { key: 9, label: "Never rebalance", value: "NA" },
    ]

    const weekDays = [
        { key: 1, label: "Monday", value: 1 },
        { key: 2, label: "Tuesday", value: 2 },
        { key: 3, label: "Wednesday", value: 3 },
        { key: 4, label: "Thursday", value: 4 },
        { key: 5, label: "Friday", value: 5 },
    ]

    function getNestedValue(keys) {
        let val = keys.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), config);
        let val2 = keys.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), {});
        ////console.log(val, val2, keys, keys?.includes("schedule"), val === undefined)
        return keys?.includes("schedule") && val === undefined ? val : (val || val2);
    }

    function deleteNestedKey(obj, keysToDelete) {
        if (!obj || keysToDelete.length === 0) {
            return obj;
        }

        const [currentKey, ...remainingKeys] = keysToDelete;

        if (remainingKeys.length === 0) {
            delete obj[currentKey];
        } else if (obj[currentKey] && typeof obj[currentKey] === 'object') {
            deleteNestedKey(obj[currentKey], remainingKeys);
        }
        return obj;
    }

    function addNestedKey(obj, keysToAdd, value) {
        if (!obj || !Array.isArray(keysToAdd) || keysToAdd.length === 0) {
            return obj;
        }

        const [currentKey, ...remainingKeys] = keysToAdd;

        if (remainingKeys.length === 0) {
            obj[currentKey] = value;
        } else {
            obj[currentKey] = obj[currentKey] || {};
            addNestedKey(obj[currentKey], remainingKeys, value);
        }
        return obj;
    }

    function updateNestedValue(obj, keys, value) {
        // obj = obj
        if (!obj || keys.length === 0) {
            return obj;
        }
        ////console.log("@#$#@$", obj, keys, value)

        const [currentKey, ...remainingKeys] = keys;

        if (remainingKeys.length === 0) {
            obj[currentKey] = value;
        } else {
            obj[currentKey] = obj[currentKey] || {};
            updateNestedValue(obj[currentKey], remainingKeys, value);
        }
        return obj;
    }

    const changeConfig = (updateKeys, addkeys = [], deleteKeys = []) => {
        let curr = JSON.parse(JSON.stringify(config));
        for (let item of updateKeys) {
            curr = updateNestedValue(curr, item?.keys, item?.value)
        }
        if (deleteKeys?.length > 0) {
            for (let value of deleteKeys) {
                curr = deleteNestedKey(curr, value)
            }
        }
        if (addkeys?.length > 0) {
            for (let item of addkeys) {
                curr = addNestedKey(curr, item?.keys, item?.value)
            }
        }
        ////console.log(curr);
        setConfig(curr);

    }

    return (
        <div style={{ flex: 1 }}>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem", maxWidth: isMobile ? "unset" : "50%" }}>
                <div className={`${isMobile ? "w100 h100" : ""}`}>
                    <div className='textXS w-500 dark4 mb-1'>Frequency</div>
                    <CustomSelect
                        customId={"custom-key-handle-" + makeid(4)} style={{ width: isMobile ? "calc((100vw - 3rem)/2)" : "100%" }} value={getNestedValue(['schedule', 'freq']) || "NA"} options={backtestFrequencyOptions} onChange={(val) => {
                            let addKeys = [], delKeys = [['schedule', 'day'], ['schedule', 'month']];
                            if (val?.includes('M')) {
                                addKeys = [{ keys: ['schedule', 'day'], value: 1 }, { keys: ['schedule', 'month'], value: 1 }]
                                delKeys = []
                            } else if (val?.includes('W')) {
                                addKeys = [{ keys: ['schedule', 'day'], value: 1 }]
                                delKeys = [['schedule', 'month']]
                            }
                            changeConfig([{ keys: ['schedule', 'freq'], value: val }], addKeys, delKeys)
                        }} className='whiteBackground' />
                </div>
                {getNestedValue(['schedule', 'freq'])?.includes('M') || getNestedValue(['schedule', 'freq'])?.includes('W') ? <div className={`${isMobile ? "w100 h100" : ""}`}>
                    <div className='textXS w-500 dark4 mb-1'>On day of {getNestedValue(['schedule', 'freq'])?.includes("W") ? "week" : "month"}</div>
                    <CustomSelect
                        customId={"custom-key-handle-" + makeid(4)} options={getNestedValue(['schedule', 'freq'])?.includes('W') ? weekDays : new Array(28).fill(1).map((el, i) => {
                            return { key: i, label: i + 1, value: i + 1 }
                        })} value={getNestedValue(['schedule', 'day'])} onChange={(val) => {
                            changeConfig([{ keys: ['schedule', 'day'], value: val }])
                        }} style={{ width: isMobile ? "calc((100vw - 3rem)/2)" : "100%" }} className='whiteBackground' />
                </div> : null}
                {getNestedValue(['schedule', 'freq'])?.includes('M') && getNestedValue(['schedule', 'freq']) !== '1M' ? <div className={`${isMobile ? "w100 h100" : ""}`}>
                    <div className='textXS w-500 dark4 mb-1'>On months</div>
                    <CustomSelect
                        customId={"custom-key-handle-" + makeid(4)} options={getNestedValue(['schedule', 'freq']) === "12M" ? months?.map((i, index) => {
                            return {
                                key: index,
                                label: i,
                                value: index + 1
                            }
                        }) :
                            getNestedValue(['schedule', 'freq']) === "3M" ? quarterMonths?.map((opt, i) => {
                                return {
                                    key: i,
                                    label: opt,
                                    value: i + 1
                                }
                            }) : halfYearlyMonths?.map((opt, i) => {
                                return {
                                    key: i,
                                    label: opt,
                                    value: i + 1
                                }
                            })
                        } value={getNestedValue(['schedule', 'month'])} onChange={(val) => {
                            ////console.log("@#$#@$", val)
                            changeConfig([{ keys: ['schedule', 'month'], value: val }])
                        }} style={{ width: isMobile ? "calc((100vw - 3rem)/2)" : "100%" }} className='whiteBackground' />
                </div> : null}
            </div>

            {getNestedValue(['schedule', 'freq']) === "NA" && <div className="textSM my-4">
                Note That by selecting “Never rebalance” you will be creating a completely manual strategy. Your strategy will not be automatically rebalanced. You can trigger buy and sell and stock any time you want (within available paper trading credit). This is suitable for bottom-up stock pickers who enter and exit positions at different times based on their conviction around stocks.
            </div>}
        </div>
    )
})

const RiskMangementAndTransCost = React.memo(({ config = {}, setConfig, isMobile = false, fromStrategyDetail = false }) => {

    function getNestedValue(keys) {
        let val = keys.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), config);
        let val2 = keys.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), {});
        ////console.log(val, val2, keys, keys?.includes("schedule"), val === undefined)
        return keys?.includes("schedule") && val === undefined ? val : (val || val2);
    }

    function deleteNestedKey(obj, keysToDelete) {
        if (!obj || keysToDelete.length === 0) {
            return obj;
        }

        const [currentKey, ...remainingKeys] = keysToDelete;

        if (remainingKeys.length === 0) {
            delete obj[currentKey];
        } else if (obj[currentKey] && typeof obj[currentKey] === 'object') {
            deleteNestedKey(obj[currentKey], remainingKeys);
        }
        return obj;
    }

    function addNestedKey(obj, keysToAdd, value) {
        if (!obj || !Array.isArray(keysToAdd) || keysToAdd.length === 0) {
            return obj;
        }

        const [currentKey, ...remainingKeys] = keysToAdd;

        if (remainingKeys.length === 0) {
            obj[currentKey] = value;
        } else {
            obj[currentKey] = obj[currentKey] || {};
            addNestedKey(obj[currentKey], remainingKeys, value);
        }
        return obj;
    }

    function updateNestedValue(obj, keys, value) {
        // obj = obj
        if (!obj || keys.length === 0) {
            return obj;
        }
        ////console.log("@#$#@$", obj, keys, value)

        const [currentKey, ...remainingKeys] = keys;

        if (remainingKeys.length === 0) {
            obj[currentKey] = value;
        } else {
            obj[currentKey] = obj[currentKey] || {};
            updateNestedValue(obj[currentKey], remainingKeys, value);
        }
        return obj;
    }

    const changeConfig = (updateKeys, addkeys = [], deleteKeys = []) => {
        let curr = JSON.parse(JSON.stringify(config));
        for (let item of updateKeys) {
            curr = updateNestedValue(curr, item?.keys, item?.value)
        }
        if (deleteKeys?.length > 0) {
            for (let value of deleteKeys) {
                curr = deleteNestedKey(curr, value)
            }
        }
        if (addkeys?.length > 0) {
            for (let item of addkeys) {
                curr = addNestedKey(curr, item?.keys, item?.value)
            }
        }
        ////console.log(curr);
        setConfig(curr);

    }

    return <div style={{ flex: 1 }}>
        {/* {JSON.stringify(config)} */}
        <div style={{
            opacity: !fromStrategyDetail ? 1 : 0.5,
            cursor: !fromStrategyDetail ? 'default' : 'not-allowed',
        }}>
            <div className='d-flex mb-3' style={{ flexDirection: "column", gap: "0.2rem" }}>
                <div className='d-flex' style={{ alignItems: "center", gap: "1rem" }} >
                    <span className={"textMD w-500"}>Risk Management</span>
                    {!fromStrategyDetail ? <Switch checked={getNestedValue(['risk_management']) !== undefined} onChange={!fromStrategyDetail ? (e) => {
                        if (e) {
                            changeConfig([{ keys: ['risk_management'], value: risk_management }])
                        } else {
                            changeConfig([], [], [['risk_management']])
                        }

                    } : () => { }} /> : <BiLock />}
                </div>
                <span className='textXS textLight'>Define stop loss and profit booking criteria. We currently support 3 risk management models</span>
            </div>
            {getNestedValue(['risk_management']) !== undefined && <>

                <CustomSelect
                    customId={"custom-key-handle-" + makeid(4)} style={{ width: "18rem", marginBottom: 10 }}
                    placeholder="Select risk management model" className='whiteBackground'
                    value={getNestedValue(['risk_management', 'model'])}
                    onChange={!fromStrategyDetail ? (val) => {
                        changeConfig([{ keys: ['risk_management'], value: val === "TSL" ? risk_management_TSL : val === "FSDP" ? risk_management_FSDP : risk_management_FSTP }])
                    } : () => { }}
                    options={[{ key: 0, label: "Trailing Stop Loss", value: "TSL" },
                    { key: 1, label: "Fixed Stop with Drawdown Protection", value: "FSDP" },
                    { key: 2, label: "Fixed Stop and Take Profit", value: "FSTP" }]} />


                {getNestedValue(['risk_management', 'model']) === "TSL" && <>
                    {/* <div className='textSM w-500 dark4 mb-2'>Trailing Stop Percentage</div> */}
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}>
                        <div>
                            <div className='textXS w-500 dark3 mb-1'>Trailing Stop Percentage</div>
                            {/* <InputNumber min={0} placeholder='Percent' suffix="%"  value={getNestedValue(['risk_management', 'trail_sl_percent']) * 100} onChange={!fromStrategyDetail ? (e) => {
                                            let val = Number(e)
                                            changeConfig([{ keys: ['risk_management', 'trail_sl_percent'], value: (val / 100) || 0 }])
                                        } : () => { }} /> */}

                            <LabelInputNew
                                placeholder='Percent' suffix="%"
                                min={0}
                                value={getNestedValue(['risk_management', 'trail_sl_percent']) * 100} onChangeVal={!fromStrategyDetail ? (e) => {
                                    let val = Number(e)
                                    //console.log(val)
                                    changeConfig([{ keys: ['risk_management', 'trail_sl_percent'], value: (val / 100) || 0 }])
                                } : () => { }}
                            />
                        </div>
                    </div>
                </>}

                {getNestedValue(['risk_management', 'model']) === "FSDP" && <>
                    {/* <div className='textSM w-500 dark4 mb-2'>Trailing Stop Percentage</div> */}
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem", marginBottom: 10 }}>
                        <div className="w100 h100">
                            <div className='textXS w-500 dark3 mb-1'>Fixed Stop Percentage</div>
                            <LabelInputNew min={0} placeholder='Percent' suffix="%" value={getNestedValue(['risk_management', 'fixed_sl_percent']) * 100} onChangeVal={!fromStrategyDetail ? (e) => {
                                let val = Number(e)
                                changeConfig([{ keys: ['risk_management', 'fixed_sl_percent'], value: (val / 100) || 0 }])
                            } : () => { }} />
                        </div>
                        <div className="w100 h100">
                            <div className='textXS w-500 dark3 mb-1'>Drawdown Trigger Percentage</div>
                            <LabelInputNew min={0} placeholder='Percent' suffix="%" value={getNestedValue(['risk_management', 'drawdown_trigger_percent']) * 100} onChangeVal={!fromStrategyDetail ? (e) => {
                                let val = Number(e)
                                changeConfig([{ keys: ['risk_management', 'drawdown_trigger_percent'], value: (val / 100) || 0 }])
                            } : () => { }} />
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}>
                        <div>
                            <div className='textXS w-500 dark3 mb-1'>Drawdown Stop Percentage</div>
                            <LabelInputNew min={0} placeholder='Percent' suffix="%" value={getNestedValue(['risk_management', 'drawdown_percent_stop']) * 100} onChangeVal={(e) => {
                                let val = Number(e)
                                changeConfig([{ keys: ['risk_management', 'drawdown_percent_stop'], value: (val / 100) || 0 }])
                            }} />
                        </div>
                    </div>
                </>}

                {getNestedValue(['risk_management', 'model']) === "FSTP" && <>
                    {/* <div className='textSM w-500 dark4 mb-2'>Trailing Stop Percentage</div> */}
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem", marginBottom: 10 }}>
                        <div>
                            <div className='textXS w-500 dark3 mb-1'>Fixed Stop Percentage</div>
                            <LabelInputNew min={0} placeholder='Percent' suffix="%" value={getNestedValue(['risk_management', 'fixed_sl_percent']) * 100} onChangeVal={(e) => {
                                let val = Number(e)
                                changeConfig([{ keys: ['risk_management', 'fixed_sl_percent'], value: (val / 100) || 0 }])
                            }} />
                        </div>

                        <div>
                            <div className='textXS w-500 dark3 mb-1'>Take Profit Trigger Percentage</div>
                            <LabelInputNew min={0} placeholder='Percent' suffix="%" value={getNestedValue(['risk_management', 'fixed_bp_percent']) * 100} onChangeVal={(e) => {
                                let val = Number(e)
                                changeConfig([{ keys: ['risk_management', 'fixed_bp_percent'], value: (val / 100) || 0 }])
                            }} />
                        </div>
                    </div>
                </>}



            </>}

        </div>
        <Divider />
        <div style={{
            opacity: !fromStrategyDetail ? 1 : 0.5,
            cursor: !fromStrategyDetail ? 'default' : 'not-allowed',
        }}>
            <div className='d-flex mb-3' style={{ flexDirection: "column", gap: "0.2rem" }}>
                <div className='d-flex' style={{ alignItems: "center", gap: "1rem" }} >
                    <span className={"textMD w-500"}>Transaction cost</span>
                    {!fromStrategyDetail ? <Switch checked={getNestedValue(['transaction_cost']) !== undefined} onChange={(e) => {
                        if (e) {
                            changeConfig([{ keys: ['transaction_cost'], value: transaction_cost }])
                        } else {
                            changeConfig([], [], [['transaction_cost']])
                        }

                    }} /> : <BiLock />}
                </div>
                <span className='textXS textLight'>Define brokerage model for your strategies.</span>
            </div>
            {getNestedValue(['transaction_cost']) !== undefined && <>
                <CustomSelect
                    disabled={fromStrategyDetail}
                    customId={"custom-key-handle-" + makeid(4)} style={{ width: "18rem", marginBottom: 10 }}
                    placeholder="Select transaction cost model" className='whiteBackground'
                    value={getNestedValue(['transaction_cost', 'brokerage_model'])}
                    onChange={(val) => {
                        changeConfig([{ keys: ['transaction_cost'], value: val === "ZERODHAEQDEL" ? transaction_cost : JSON.stringify(config)?.includes("eq_del_percentage") ? transaction_cost_custom_per : transaction_cost_custom_rs }])
                    }}
                    options={[{ key: 0, label: "Zerodha", value: "ZERODHAEQDEL" },
                    { key: 1, label: "Custom", value: "CUSTOMEQDEL" }]} />


                {getNestedValue(['transaction_cost', 'brokerage_model']) === "CUSTOMEQDEL" && <>
                    {/* <div className='textSM w-500 dark4 mb-2'>Trailing Stop Percentage</div> */}
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}>
                        <div>
                            <div className='textXS w-500 dark3 mb-1'>Per Trade Charges</div>
                            {/* <InputNumber min={0} placeholder='Percent' suffix="%"  value={getNestedValue(['risk_management', 'trail_sl_percent']) * 100} onChange={ (e) => {
                                            let val = Number(e)
                                            changeConfig([{ keys: ['risk_management', 'trail_sl_percent'], value: (val / 100) || 0 }])
                                        } } /> */}

                            <LabelInputNew
                                showPerAmtToggle={true}
                                allowDecimals={true}
                                val_type={JSON.stringify(config)?.includes("eq_del_percentage") ? "percent" : "rupee"}
                                placeholder='Enter charges'
                                // suffix="%"
                                onValTypeChange={(valType) => {
                                    try {
                                        // alert(valType)
                                        var delKeys = valType === "percent" ? [['transaction_cost', 'brokerage_params', 'eq_del_fixed']] : [['transaction_cost', 'brokerage_params', 'eq_del_percentage']]
                                        changeConfig([{ keys: ['transaction_cost', 'brokerage_params', (valType === "percent" ? 'eq_del_percentage' : 'eq_del_fixed')], value: valType === "percent" ? 0.02 : 20 }], [], delKeys)
                                    } catch (error) {
                                        console.log(error)
                                    }
                                }}
                                min={0}
                                value={JSON.stringify(config)?.includes("eq_del_percentage") ? getNestedValue(['transaction_cost', 'brokerage_params', 'eq_del_percentage']) : getNestedValue(['transaction_cost', 'brokerage_params', 'eq_del_fixed'])}
                                onChangeVal={(e, valType) => {
                                    let val = Number(e)
                                    console.log(val)
                                    changeConfig([{ keys: ['transaction_cost', 'brokerage_params', (valType === "percent" ? 'eq_del_percentage' : 'eq_del_fixed')], value: val || 0 }])
                                }}
                            />
                        </div>
                    </div>
                </>}

            </>}

        </div>
    </div>
})

const BuildCustomPortfolio = (props) => {
    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
    const { state } = useLocation()
    const type = useGetAssetType();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stockprices = useSelector((state) => state?.stockprices?.value);
    const prevstockprices = useSelector((state) => state?.prevStockPrices?.value);
    const instrument_method = props?.instrument_method || 1
    const isModalView = props?.isModalView === true ? true : false
    const subscribeMiddle = useSubscribeMiddleware();
    const user = useSelector((state) => state?.updateUserData?.value);
    const accessLevelData = useSelector((state) => state?.accessLevel?.value)
    const userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel || 0

    let userAccess = getUserAccessLimitFromAccessObj({
        accessObj: accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === 'CREDITS_PER_STRATEGY_IN_PAPERTRADE'),
        accessLevel: userAccesslevel
    });

    let initial_capital = userAccess || 100000
    const watchlist = useSelector((state) => state?.userWatchlists?.value);
    const [watchData, setwatchData] = React.useState(undefined)
    const [allFunds, setAllFunds] = React.useState([]);
    const [fundsLoading, setFundsLoading] = React.useState(true);
    const [screenerMetrics, setScreenerMetrcis] = React.useState([]);
    const [sectors, setSectors] = React.useState([]);
    const [industryGrp, setIndustryGrp] = React.useState([]);
    const [industries, setIndustries] = React.useState([]);
    const [benchmarks, setBenchmarks] = React.useState([]);
    const [mfCategories, setmfCategories] = React.useState([])
    const [screens, setScreens] = React.useState([]);
    const [loadingScreens, setLoadingScreens] = React.useState(true);
    const [basketSaveModal, setBasketSaveModal] = React.useState({});
    const [nextDisabled, setnextDisabled] = React.useState(true);
    const [listLoading, setlistLoading] = React.useState(false);
    const backtestWeightMethods = [
        { key: 1, label: "Allocate equal weight", value: "W-EQ" },
        { key: 3, label: "Weight by metric", value: "W-VAR", "show": type?.value === 4 },
        { key: 2, label: "Manual weights", value: "W-CT", "show": instrument_method === 1 },
    ]
    const [weight_methods, setweight_methods] = React.useState(backtestWeightMethods);

    const [sum, setSum] = React.useState(100)
    const [weight_method, setweight_method] = React.useState("W-EQ");
    const [weight_by, setweight_by] = React.useState(weight_method === "W-VAR" ? type?.value === 4 ? "mcap" : "aum" : "");
    const [inverseV, setinverseV] = React.useState(0);
    const [mintboxName, setMintboxName] = React.useState('');
    const [instrumentList, setinstrumentList] = React.useState(props?.instrumentList || []);
    const [minInvestmentAMT, setminInvestmentAMT] = React.useState(0);
    const [selectedBenchmark, setselectedBenchmark] = React.useState([]);
    const [historicalPerformance, setHistoricalPerformance] = React.useState({});
    const [historicalPerfLoading, setHistoricalPerfLoading] = React.useState(true);
    const [totalBaskets, setTotalBaskets] = React.useState(0);
    const [totalScreens, setTotalScreens] = React.useState(0);
    const [totalSignals, setTotalSignals] = React.useState(0);
    const [calculatedWeights, setcalculatedWeights] = React.useState([])
    const [selectedPortfolioType, setselectedPortfolioType] = React.useState(instrument_method === 2 ? props?.allFilteredFundsData?.length <= 50 ? 1 : 2 : 2)

    // risk_management
    const [config, setConfig] = React.useState({ ...schedule, initial_capital });
    const [backtestConfig, setBacktestConfig] = React.useState({});
    const [backTestModalLoading, setbackTestModalLoading] = React.useState(true)
    const [backTestData, setBackTestData] = React.useState({})
    const [backTestPerfData, setbackTestPerfData] = React.useState([]);

    const [viewType, setviewType] = React.useState('backtest'); // backtest or papertrade
    const [viewTypePendingNewRun, setviewTypePendingNewRun] = React.useState(2); // 2 for both, 1 for backtest, 0 latest

    // screener or signal specific
    const [allFilteredFundsData, setallFilteredFundsData] = React.useState([])
    const [query, setquery] = React.useState(props?.query || [])
    const [metricType, setMetricType] = React.useState(instrument_method === 2 ? type.value === 4 ? "mcap" : "expense_ratio" : instrument_method === 3 ? "agg_z_score" : "")
    const [sortType, setSortType] = React.useState("top")
    const [numOfFunds, setNumOfFunds] = React.useState(props?.allFilteredFundsData?.length < 10 ? props?.allFilteredFundsData?.length : 10)

    // rebalance
    const date = moment(new Date());
    const disabledDays = ['Sat', 'Sun'];
    const defaultDay = disabledDays?.includes(date?.format('ddd')) ? 'Monday' : date?.format('dddd');
    const defaultDayFull = disabledDays?.includes(date?.format('ddd')) ? 'Monday' : date?.format('dddd');
    const defaultDate = Number(date.format('D')) > 28 ? 1 : Number(date.format('D'));
    const defaultDateFull = Number(date.format('D')) > 28 ? '1st' : date.format('Do');
    const defaultMonth = Number(date.format('M'));
    const defaultMonthFull = date.format('MMM')
    const [rebalanceOption, setRebalanceOption] = React.useState(3);
    const options = [
        { "key": 1, text: `Weekly on ${defaultDayFull}`, value: "1W", disabled: type.value !== 4, inp1: defaultDay, inp1Label: "On day", inp1type: "week" },
        { "key": 2, text: `Bi-Weekly on ${defaultDayFull}`, value: "2W", disabled: type.value !== 4, inp1: defaultDay, inp1Label: "On day", inp1type: "week" },
        { "key": 3, "text": `Monthly on ${defaultDateFull}`, value: '1M', inp1: defaultDate, inp1Label: "On date", inp1type: "date" },
        { "key": 4, "text": "Every quarter", value: '3M', inp1: '1,4,7,10', inp2: defaultDate, inp1Label: "In", inp1type: "month", inp2Label: "On date" },
        { "key": 5, "text": "Every 6 months", value: '6M', inp1: '1,7', inp2: defaultDate, inp1Label: "In", inp1type: "month", inp2Label: "On date" },
        {
            "key": 6, "text": `Annually on ${defaultDateFull} ${defaultMonthFull}`, value: '12M', inp1: defaultMonth, inp2: defaultDate, inp1Label: "In",
            inp1type: "month", inp2Label: "On date"
        },
        { "key": 7, text: "Custom", value: -1 },
        {
            "key": 8, "text": <span style={{ alignItems: "center" }}>
                Never rebalance <Tooltip arrow={false} placement="bottom" title={<span className="textXS">By selecting this option, you will not receive periodic rebalancing updates
                    for this strategy. However, you will be able to rebalance this strategy manually whenever you wish to from your strategy.</span>}>
                    <AiOutlineQuestionCircle size={15} className='secondaryGreen' />
                </Tooltip></span>, value: "0", disabled: isModalView
        }
    ]

    const [rebalanceFreq, setRebalanceFreq] = React.useState('1M');
    const [rebalanceInp1, setRebalanceInp1] = React.useState(defaultDate);
    const [rebalanceInp2, setRebalanceInp2] = React.useState('');
    const [rebalanceStartDate, setRebalanceStartDate] = React.useState('');
    //step
    const [current, setCurrent] = React.useState(0);
    const [basketSaved, setBasketSaved] = React.useState(false);
    const [basketData, setBasketData] = React.useState({});
    const isMobile = useIsMobile();
    const [showIndex, setShowIndex] = React.useState(isMobile);

    let last3Year1StDate = new Date();
    last3Year1StDate.setFullYear(last3Year1StDate.getFullYear() - 3);
    var startDate = formatDateyyyymmdd(new Date(last3Year1StDate))
    var endDate = formatDateyyyymmdd(new Date())
    const [start_date, setstart_date] = useState(startDate)
    const [end_date, setend_date] = useState(endDate)
    const [dates, setDates] = useState([startDate, endDate]);
    const [openAccord, setopenAccord] = useState("no")
    const minDate = moment(userAccesslevel > 2 ? '2014-01-01' : '2018-01-01');
    const maxDate = moment(formatDateyyyymmdd(new Date()));
    const [value1, setValue1] = useState(dayjs(startDate, "YYYY-MM-DD"));
    const [value2, setValue2] = useState(dayjs(endDate, "YYYY-MM-DD"));


    const loadWeightsData = async (instrumentList, isFresh = false) => {
        if (isFresh == true) {
            var instruments = type.value === 4 ? instrumentList.map((instrument) => { return instrument.symbol }) : type.value === 1 ? instrumentList.map((instrument) => { return instrument.nse_symbol }) : instrumentList.map((instrument) => { return instrument.isin_code })

            if (type?.value !== 2) {
                // adv stock flow
                var data = {
                    syms: instruments,
                    position_sizing: JSON.stringify(weight_method === "W-EQ" ? {
                        'method': weight_method,
                    } : {
                        'method': weight_method,
                        'weight_by': weight_by,
                        'is_inverse': inverseV
                    })
                }

                const res = await AdminServicesPY.get_weights_and_min_inv(data).then(res => res)
                //console.log(res?.data)
                setcalculatedWeights(JSON.parse(res?.data) || [])

                return JSON.parse(res?.data) || []
            } else {
                // old flow
                var data = {
                    instruments: instruments,
                    instrument_type: type.value === 4 ? 3 : type.value,
                    mCap: 0
                }

                const res = await AdminServicesPY.getPortfolioWeights(data).then(res => res)
                ////console.log(res?.data)

                setcalculatedWeights(res?.data || [])

                return res.data || []
            }


        } else {
            return calculatedWeights
        }
    }

    const getCurrentLTP = ({ fundData }) => {
        // var fundData = instrumentList?.filter((fund) => (fund?.symbol || fund?.nse_symbol) === symbol)?.[0]
        var changeVal, changePer, currentLTP = "-"
        var currentSymbolAllData = stockprices === "close" ? prevstockprices?.[fundData?.gdf_symbol] : (stockprices?.[fundData?.gdf_symbol] || prevstockprices?.[fundData?.gdf_symbol])
        currentLTP = currentSymbolAllData?.LastTradePrice
        changeVal = currentSymbolAllData?.PriceChange
        changePer = currentSymbolAllData?.PriceChangePercentage
        if (currentSymbolAllData == undefined) {
            let ele = fundData
            currentLTP = ele?.price || 0
        }
        return currentLTP
    }

    const metric_data = (key, value) => {
        return props?.screenerMetric.filter((met) => met[key] === value)[0]
    }

    const getBasketAccessLimit = async () => {
        let s = await getTotalScreenersCount(user?.id);
        setTotalScreens(s)
        let signals = await apiHelper({ apiName: "getUserSignal", saveResponse: "sessionStorage", data: { id: user?.id, type: type.omkar } });
        if (signals?.length > 0) {
            setTotalSignals(signals.length);
        }
        else {
            setTotalSignals(0);
        }
        let baskets = await fetchFundData(3, false, user.id);
        let t = baskets?.filter(s => s?.IsCustom === 1)

        setTotalBaskets(t?.length || 0);
    }

    const allocate = async (instrumentList) => {
        setlistLoading(true)
        var a = instrumentList
        if (weight_method === 1 || weight_method === "W-CT") {
            if (100 % a?.length === 0) {
                a = a.map((obj, index) => ({
                    ...obj,
                    allocation: (100 / a?.length).toFixed(0), locked: false, index
                }))
                setinstrumentList(a)
            } else {
                a = a.map((obj, index) => ({
                    ...obj,
                    allocation: ((100 - (100 % a?.length)) / a?.length).toFixed(0), locked: false, index
                }))
                a[0].allocation = Number(a[0]?.allocation) + (100 % a?.length)
                setinstrumentList(a)
            }
        } else {
            await loadWeightsData(a, true).then((weights) => {
                // //console.log(weights)
                if (type?.value !== 2) {
                    //new
                    a = a.map((obj, index) => ({
                        ...obj,
                        allocation: (Number(weights?.filter((i) => i?.symbol === (obj?.symbol || obj?.nse_symbol || obj?.isin_code))?.[0]?.weights) * 100)?.toFixed(1),
                        locked: true,
                        index
                    }))
                    setinstrumentList(a)
                } else {
                    //old 
                    var finalWeights = weights.filter((i) => i.wt_method === (weight_method === "W-EQ" ? 2 : 1))[0]?.weights
                    a = a.map((obj, index) => ({
                        ...obj,
                        allocation: finalWeights[index].toFixed(1),
                        locked: true,
                        index
                    }))
                    setinstrumentList(a)
                }
            })
        }
        setlistLoading(false)
    }

    const getFilteredFunds = async () => {

        var allFunds = JSON.parse(JSON.stringify(props?.allFilteredFundsData))
        ////console.log(allFunds.length)
        var filterVal = sortType
        var final = query
        var finalTableColumns = []
        ////console.log(final)

        var currentMetricData = { ...metric_data('metric_col_code', metricType), "metric_col_code": metricType }
        ////console.log(currentMetricData)

        if (currentMetricData.direction_flag !== null || currentMetricData.direction_flag !== undefined) {
            if (sortType !== undefined) {
                ////console.log(currentMetricData.metric_col_code)
                allFunds = sortFunds(allFunds, currentMetricData.metric_col_code, sortType)
            } else {
                // ////////console.log("ASDF", currentMetricData.direction_flag)
                filterVal = currentMetricData.direction_flag
                allFunds = sortFunds(allFunds, currentMetricData.metric_col_code, currentMetricData.direction_flag)
            }
        }

        allFunds = allFunds.splice(0, numOfFunds)

        setallFilteredFundsData([...allFunds])
        allocate([...allFunds])

        if (instrument_method === 2) {
            //screener
            var addonQuery = {
                "pos": props?.query?.length + 1,
                "oper": "rank_comp",
                "primary_col": metricType || null,
                "primary_col_table": "S",
                "sec_col": null,
                "adj_operator": null,
                "sec_col_table": null,
                "abs_val_1": null,
                "abs_val_2": null,
                "comp_operator": sortType + "_" + numOfFunds + "_abs",
                "rel_comp_stat": null,
                "rel_comp_name": null,
                "rule_name": "FINAL SORT"
            }
            setquery(props?.query.concat(addonQuery))
        }
    }

    const next = () => {
        //console.log(current)
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const saveScreener = () => new Promise(async (resolve, reject) => {
        if (user !== undefined && user !== null) {
            var screener = {
                "ClientId": user.id,
                "ShowAsScreen": 1,
                "ShowAsChecklist": 1,
                "IsAlertsEnabled": 1,
                "ChecklistId": 0,
                "ScreenerName": "Screen for " + mintboxName,
                "Description": '',
                "InstrumentType": type.strVal,
            }

            ////console.log(query, props?.query)

            var savedScreener = await apiHelper({ apiName: 'saveScreener', data: screener })
            ////console.log(savedScreener)
            if (savedScreener[0]?.checklist_id !== undefined) {

                var final = query
                ////console.log(final)

                var a = await final.map(async (rule) => {
                    ////console.log(rule)
                    var saverule = await apiHelper({ apiName: 'saveScreenerRule', data: JSON.stringify({ ...rule, "screener_type": type.strVal, "client_id": user.id, "rule_id": 0, "checklist_id": savedScreener[0]?.checklist_id }) })
                    return saverule
                })
                setTimeout(async () => {
                    toast.success("Screen Saved")
                    let screeners = await apiHelper({ apiName: "getUserScreeners", saveResponse: "sessionStorage", data: { id: user.id, type: type.strVal }, getFresh: true });
                    let baskets = await fetchFundData(3, true, user.id);
                    // setmodalSave(false)

                }, 200);
                resolve(savedScreener[0]?.checklist_id)
            } else {
                toast.error("Something went wrong. Try again later!!")
                resolve(-1)
            }


        } else {
            toast.error("You need to login to create strategy")
            dispatch(toggleloginModal(true))
            resolve(-1)
        }


    })

    const saveSignal = () => new Promise(async (resolve, reject) => {

        ////console.log(user)
        if (user !== undefined && user !== null) {



            var q_param = {
                ...query,
                "ClientId": user.id,
                'Top': selectedPortfolioType === 2 ? numOfFunds : -1,
                "SignalName": "Factor model for " + mintboxName,
            }

            ////console.log(q_param)
            var savedSignal = await apiHelper({ apiName: 'saveSignal', data: q_param })
            ////console.log(savedSignal)
            if (savedSignal[0]?.signal_id !== undefined) {
                toast.success("Factor model saved")
                let signals = await apiHelper({ apiName: "getUserSignal", saveResponse: "sessionStorage", data: { id: user.id, type: type.omkar }, getFresh: true });
                resolve(savedSignal[0]?.signal_id)
            } else {
                toast.error("Something went wrong. Try again later!!")
                resolve(-1)
            }


        } else {
            toast.error("You need to login to create factor model")
            dispatch(toggleloginModal(true))
            resolve(-1)
        }


    })

    const saveMintbox = async (screenerId, signalId, openInvPortal) => {

        var dataF = {
            "clientId": user.id,
            "name": mintboxName,
            "description": "",
            "benchmarkId": selectedBenchmark[0].benchmark_id,
            "risk": 1,
            "duration": 0,
            "minLumpsumInvestment": Number(minInvestment(instrumentList.map((data) => {
                return {
                    ...data,
                    latest_nav: type.value === 2 ? data?.latest_nav : getCurrentLTP({ fundData: data })
                }
            }))),
            "minSIPInvestment": Number(minInvestment(instrumentList.map((data) => {
                return {
                    ...data,
                    latest_nav: type.value === 2 ? data?.latest_nav : getCurrentLTP({ fundData: data })
                }
            }))),
            "xirr": 0,
            "constituents": instrumentList.map((instrument) => {
                return {
                    "fundCode": type.value === 4 ? instrument.symbol : type.value === 1 ? instrument.nse_symbol : instrument.isin_code,
                    "isin": type.value === 4 ? instrument.symbol : type.value === 1 ? instrument.nse_symbol : instrument.isin_code,
                    "indexId": 0,
                    "assetId": 0,
                    "weightage": instrument.allocation
                }
            }),
            "videoLink": "",
            "fee": "",
            "rebalanceFrequency": String(rebalanceFreq),
            "fundType": type.value,
            "screenId": screenerId, //logic baaki hai
            "signalId": signalId,//logic baaki hai
            "instrumentMethod": instrument_method,
            "weightMethod": weight_method,
            "rebalanceMethod": rebalanceFreq === "0" ? 0 : 1,
            "rebalanceTT": 0,
            "rebalanceDay": rebalanceFreq === "0" ? "" : String(rebalanceInp2 !== '' ? rebalanceInp2 : rebalanceInp1),
            "rebalanceStartOn": rebalanceFreq === "0" ? "" : moment(new Date(rebalanceStartDate)).format('DD-MM-YYYY')
        }

        ////console.log(dataF)
        try {
            const res = await AdminServices.createMintbox(dataF).then((res) => {
                return res
            });
            ////console.log(res)
            if (res.data.Result === 'Success') {
                toast.success('Strategy saved!');
                let strategies = await fetchFundData(3, true, user.id);
                if (openInvPortal) {
                    let obj = strategies?.find((el) => Number(el?.UID) === Number(res.data.MintboxId)) || {}
                    setBasketData(obj);
                    setBasketSaved(true);
                    setBasketSaveModal({})
                    return true;
                }
                else {
                    setBasketSaveModal({})
                    navigate(type.basePath + `/basket-detail/${mintboxName}/2/${res.data.MintboxId}`)
                }

            } else {
                toast.error('Something went wrong! Please try again.');
            }
        } catch (error) {
            toast.error('Something went wrong! Please try again.');
        }
    }

    const createMintbox = async (openInvPortal) => {
        var screenerId = props?.screen_id || 0
        var signalId = props?.signal_id || 0
        let check
        if (instrument_method === 2) {
            //screener
            subscribeMiddle({
                ModuleKey: type.value === 4 ? "STOCK_SAVE_TRACK_SCREENS" : "MF_ETF_SAVE_TRACK_SCREENS",
                onNext: async () => {
                    screenerId = await saveScreener()
                    check = saveMintbox(screenerId, signalId, openInvPortal)
                },
                isLimitType: true,
                userLimit: totalScreens
            })
        } else if (instrument_method === 3) {
            //signal
            subscribeMiddle({
                ModuleKey: type.value === 4 ? "STOCK_SAVE_TRACK_SIGNALS" : "",
                onNext: async () => {
                    signalId = await saveSignal()
                    check = saveMintbox(screenerId, signalId, openInvPortal)
                },
                isLimitType: true,
                userLimit: totalSignals
            })
        } else {
            check = saveMintbox(screenerId, signalId, openInvPortal)
        }
        getBasketAccessLimit();
        return check;
    }

    const calculateRebalanceStart = () => {
        const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let milliSecIn3M = 3 * 30 * 24 * 60 * 60 * 1000;
        let milliSecIn1M = 30 * 24 * 60 * 60 * 1000;
        const startDate = new Date();
        let finalDate
        if (rebalanceFreq === '1W' || rebalanceFreq === '2W') {
            const givenWeekdayIndex = weekdays.indexOf(rebalanceInp1);
            const weekInMillis = 7 * 24 * 60 * 60 * 1000 * (rebalanceFreq === '1W' ? 1 : 2);
            const endDate = new Date(startDate.getTime() + weekInMillis);
            const endDateWeekdayIndex = endDate.getDay();
            const daysToAdd = givenWeekdayIndex - endDateWeekdayIndex;
            finalDate = new Date(endDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
        }
        else if (rebalanceFreq === '1M') {
            finalDate = new Date(startDate);
            finalDate.setMonth(finalDate.getMonth() + 1);
            finalDate.setDate(rebalanceInp1);
        }
        else if (rebalanceFreq === '12M') {
            finalDate = new Date(startDate);
            finalDate.setMonth(rebalanceInp1 - 1);
            finalDate.setDate(rebalanceInp2);
            let check1 = finalDate.getTime() <= startDate.getTime();
            let check2 = (finalDate.getTime() - startDate.getTime()) < milliSecIn3M
            if (check1 || check2) {
                finalDate.setFullYear(finalDate.getFullYear() + 1)
            }
        }
        else if (rebalanceFreq === '6M' || rebalanceFreq === '3M') {
            let months = rebalanceInp1?.split(',')?.map((el) => Number(el) - 1);
            for (let i = 0; i < months?.length; i++) {
                let rebalStartDate = new Date(startDate);
                rebalStartDate.setMonth(months[i]);
                rebalStartDate.setDate(rebalanceInp2);
                if (rebalStartDate.getTime() > startDate.getTime() && (rebalStartDate.getTime() - startDate.getTime()) > milliSecIn1M) {
                    finalDate = rebalStartDate;
                    break;
                }
            }
            if (!finalDate) {
                finalDate = new Date(startDate);
                finalDate.setMonth(months[0]);
                finalDate.setDate(rebalanceInp2);
                finalDate.setFullYear(finalDate.getFullYear() + 1);
            }
        }
        setRebalanceStartDate(finalDate)
        return finalDate;
    }

    const socketlogic = async (t) => {
        console.log("SADsadasd", t)
        let webSocket = new WebSocket(`wss://pyapi.mintbox.ai/ws2/ws/backtest`);
        //wss://uat.mintbox.ai/ws2/ws/backtest
        //ws://pyapi.mintbox.ai/ws2/ws/backtest
        //wss://pyapi.mintbox.ai/ws2/ws/backtest
        //ws://localhost:8001/ws/backtest
        var backtestConfig = JSON.parse(t?.config)
        var chartData = []
        webSocket.onerror = function (event) {
            console.log("err")
            //console.log(event)

        };

        webSocket.onopen = function (event) {
            console.log("send")
            webSocket?.send(JSON.stringify(t))
            return false;
        };

        webSocket.onmessage = function (event) {
            const message = JSON.parse(event.data?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null));
            if (message?.includes("code") && message?.includes("bt")) {
                setBackTestData(JSON.parse(message)?.bt)
                setbackTestModalLoading(false);
            } else if (Array.isArray(message)) {
                message.forEach(data => {
                    console.log(data[1], backtestConfig?.initial_capital, (data[1] / backtestConfig?.initial_capital))
                    chartData.push({
                        Portfolio: data[1] / backtestConfig?.initial_capital,
                        Benchmark: data[2] / backtestConfig?.initial_capital,
                        Date: data[0]
                    })
                });
                setbackTestPerfData([
                    ...chartData
                ])
            }
            else {
                console.log("Final message:", message);
            }
        };
        webSocket.onclose = async function (event) {
            console.log("closed")
            console.log(event)
        };
    }

    const onPaperTrade = async (name = "") => {
        setviewType('papertrade')
        // setregenerateOrder(false)
        // let rebalanceStDate = calculateRebalanceStart();
        var weight_list = {}
        if (weight_method === "W-CT") {
            var a = instrumentList?.map((el) => {
                weight_list[el?.symbol || el?.nse_symbol] = Number(el?.allocation) / 100
            })
        }

        let last3Year1StDate = new Date();
        last3Year1StDate.setFullYear(last3Year1StDate.getFullYear() - 3);

        var code = `selected_symbols = symbol is_in [${instrumentList?.map((el) => `"${el?.symbol || el?.nse_symbol || el?.isin_code}"`)}];output = selected_symbols;`
        var ak = {
            "config": {
                ...config,
                "bar_timeframe": "1D",
                // 'initial_capital': initial_capital,
                "position_sizing": weight_method === "W-EQ" ? {
                    'method': weight_method
                } : weight_method === "W-VAR" ? {
                    'method': weight_method,
                    'weight_by': weight_by,
                    'is_inverse': inverseV
                } : {
                    'method': weight_method,
                    'weight_list': weight_method === "W-CT" ? weight_list : []
                },
                "fill_on": "open_next",
                "on_no_signal": "cash",
                "benchmark_id": Number(selectedBenchmark?.[0].benchmark_id),
                "benchmark_name": selectedBenchmark?.[0]?.benchmark_name,
                "sell_on_surveillance": 0,
                "client_id": user?.id,
                "strategy_type_id": config?.schedule?.freq !== "NA" ? 1 : 2,
                "strategy_desc": "",
                "instrument_type": type?.value === 4 ? 3 : type.value,
                "instrument_selection": instrument_method,
                "code": code
            },
            'initial_capital': config?.initial_capital,
            "backtest_start": start_date,
            "end_date": end_date
        }
        // if (ak?.config?.risk_management === null) {
        //     delete ak?.config?.risk_management
        // }

        return ak?.config
    }

    const getHistoricalPerformance = async (callFresh = false) => {
        // if (type.value !== 2) {
        //new
        var code = `selected_symbols = symbol is_in [${instrumentList?.map((el) => `"${el?.symbol || el?.nse_symbol || el?.isin_code}"`)}];output = selected_symbols;`

        var weight_list = {}
        if (weight_method === "W-CT") {
            var a = instrumentList?.map((el) => {
                weight_list[el?.symbol || el?.nse_symbol || el?.isin_code] = Number(el?.allocation) / 100
            })
        }
        let last3Year1StDate = new Date();
        last3Year1StDate.setFullYear(last3Year1StDate.getFullYear() - 3);
        var ak = {
            "config": {
                ...config,
                "bar_timeframe": "1D",
                // 'initial_capital': initial_capital,
                "position_sizing": weight_method === "W-EQ" ? {
                    'method': weight_method
                } : weight_method === "W-VAR" ? {
                    'method': weight_method,
                    'weight_by': weight_by,
                    'is_inverse': inverseV
                } : {
                    'method': weight_method,
                    'weight_list': weight_method === "W-CT" ? weight_list : {}
                },
                "fill_on": "open_next",
                "on_no_signal": "cash",
                "benchmark_id": Number(selectedBenchmark?.[0].benchmark_id),
                "benchmark_name": selectedBenchmark?.[0]?.benchmark_name,
                "sell_on_surveillance": 0,
                "client_id": user?.id,
                "strategy_type_id": config?.schedule?.freq !== "NA" ? 1 : 2,
                "strategy_desc": "",
                "instrument_type": type?.value === 4 ? 3 : type.value,
                "instrument_selection": instrument_method,
                "code": code
            },
            'initial_capital': config?.initial_capital,
            "backtest_start": start_date,
            "end_date": end_date
        }
        // if (ak?.config?.risk_management === null) {
        //     delete ak?.config?.risk_management
        // }
        if (viewType !== "backtest")
            setviewType("backtest")
        if ((code?.length > 10 && (!(JSON.stringify(backtestConfig) === JSON.stringify(ak?.config)))) || callFresh) {

            console.log("SADsadasd", code, ak, backtestConfig)

            setbackTestModalLoading(true)
            setbackTestPerfData([]);
            setBacktestConfig(ak?.config);
            setDates([start_date, end_date])
            setTimeout(() => {
                socketlogic({
                    ...ak,
                    config: JSON.stringify(ak?.config),
                })
            }, 100);
        }

        // //console.log(ak)
        // } else {
        //     //old
        //     let rebalanceStDate = calculateRebalanceStart();
        //     setHistoricalPerfLoading(true);
        //     setHistoricalPerformance({});
        //     let signalQuery = instrument_method === 3 ? {
        //         top: selectedPortfolioType === 2 ? numOfFunds : -1,
        //         universe: query?.Universe,
        //         direction_flag: query?.DirectionFlags,
        //         metric_cols: query?.MetricCols,
        //         weights: query?.Weights,
        //     } : {}
        //     //console.log(query, signalQuery);
        //     let data = {
        //         "mintbox_type": 3,
        //         "mintbox_id": 0,
        //         "instrument_method": instrument_method,
        //         "instrument_type": type.value,
        //         "screen_query": instrument_method === 2 ? query : [],
        //         "rebal_method": rebalanceFreq === "0" ? 0 : 1,
        //         "rebal_freq": String(rebalanceFreq),
        //         "mintbox_bm_id": Number(selectedBenchmark?.[0].benchmark_id),
        //         "mintbox_bm_name": selectedBenchmark?.[0]?.benchmark_name,
        //         "isins": type.value === 4 ? [] : instrumentList?.map((el) => type.value === 1 ? el?.nse_symbol : el?.isin_code),
        //         "tickers": type.value === 4 ? instrumentList?.map((el) => el?.symbol) : [],
        //         "weight_method": weight_method,
        //         "weights": instrumentList?.map((el) => Number(el?.allocation) / 100),
        //         "signal_query": signalQuery,
        //         "rebal_day": rebalanceFreq === "0" ? "" : (rebalanceFreq === '1W' || rebalanceFreq === '2W') ? String(rebalanceInp1) : rebalanceFreq === '1M' ? rebalanceInp1 : rebalanceInp2,
        //         "rebal_month": (rebalanceFreq === "0" || rebalanceFreq === '1W' || rebalanceFreq === '2W') ? "" : rebalanceStDate?.getMonth() + 1
        //     }
        //     let res = type.value === 4 ? await apiHelperPY({ apiName: "getPortfolioPerformanceOnly", saveResponse: "", data: { params: JSON.stringify(data) } }) : await apiHelperPY({ apiName: "getPortfolioPerfOnly", saveResponse: "", data: data })
        //     if (res) {
        //         let temp = JSON.parse(res.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null));
        //         //console.log(temp);
        //         setHistoricalPerformance(temp)
        //     }
        //     setHistoricalPerfLoading(false);
        // }
    }

    const isValidAllo = (viewType) => {
        var sum = 0;
        instrumentList.map((data, index) => {
            sum = (parseFloat(sum) + parseFloat(data.allocation)).toFixed(2);
        })
        //console.log(sum)
        setSum(sum);
        if (Number(sum) !== 100) {

            setnextDisabled(true)
        } else {

            setnextDisabled(false)
        }
        setlistLoading(false)
    }

    const changeData = (index, text) => {
        try {
            var upDatedFilters = []
            upDatedFilters = [...instrumentList]
            const setFinalData = (data) => {
                setinstrumentList(data)
            }
            var a = JSON.parse(JSON.stringify(upDatedFilters))
            if (Number(text) !== 0) {
                a[index].allocation = text
                setFinalData(a)
            } else {
                toast.error("Weights can't be zero.\nEither allocate some weight or remove the factor model you don't want", {
                    className: "textSM"
                })
                setFinalData(instrumentList);
            }
        } catch (error) {
        }
    }

    const onAddInstruments = (item) => {
        setcalculatedWeights([])
        let l = item?.length;
        if (type.value === 4) {
            if (l > 50) {
                toast.error(`Maximum 50 ${type.dname} allowed`);
                return;
            }
        } else {
            if (l > 10) {
                toast.error(`Maximum 10 ${type.dname} allowed`);
                return;
            }
        }
        allocate([...item])
    }

    const del = (index) => {
        var a = instrumentList;
        a.splice(index, 1)
        if (a?.length === 0) {
            setinstrumentList([])
        } else {
            allocate([...a])
        }
    }

    const checkAddDisabled = (selectedData = []) => {
        let key = type.value === 4 ? 'symbol' : "plan_id";
        let init = selectedData?.map((el) => el?.[key]) || [];
        let oths = instrumentList?.map((el) => el?.[key]) || [];
        if (init?.length < 2) return true;
        if (init?.length !== oths?.length) return false;
        return oths.every((el) => init.includes(el));
    }

    const resetState = () => {
        setnextDisabled(true);
        setCurrent(0);
        setRebalanceOption(3);
        setRebalanceFreq('6M');
        setRebalanceStartDate('');
        setlistLoading(false);
        setFundsLoading(true);
        setMintboxName('');
        setinstrumentList(props?.instrumentList || []);
        setminInvestmentAMT(0);
        setHistoricalPerformance({});
        setHistoricalPerfLoading(true);
        setcalculatedWeights([]);
        setselectedBenchmark([]);
        setwatchData(undefined)
        setAllFunds([])
        setScreens([])
        setLoadingScreens(true);
        setBasketSaved(false);
        setBasketData({});
    }

    // const hiddenMetrics = ['is_nifty_50', 'is_nifty_100', 'is_nifty_500', 'is_nifty_midcap100', 'is_nifty_smallcap100'];
    const hiddenMetrics = [];
    // fetching all funds, sectors, industries, category, screens for static baskets;
    React.useEffect(() => {
        // resetState();
        if (instrument_method === 1) {
            const dem = async () => {
                let res = await screenerMetricData(type);
                //console.log(res)
                let temp = res?.allFunds || [];
                let mets = res?.screenerMetricData;
                mets = mets?.map((el) => {
                    var selectedCategory = res?.screenerFinalData?.filter((i) => i?.category_id === el?.category_id)?.[0] || {}
                    var selectedSubCategory = selectedCategory?.sub_category?.filter((i) => i?.sub_category_id === el?.sub_category_id)?.[0] || {}
                    return {
                        ...el,
                        type: el?.type || "Metric",
                        // label: el?.label?.replace(/\s/g, ''),
                        category_name: selectedCategory?.category_name,
                        sub_category_name: selectedSubCategory?.sub_category_name,
                    }
                });
                mets = mets?.filter((el) => !hiddenMetrics.includes(el?.dataIndex))

                setScreenerMetrcis(mets);
                if (type.value === 4) {


                    setSectors(res?.fabricatedData?.sectors);
                    setIndustryGrp(res?.fabricatedData?.industries);
                    setIndustries(res?.fabricatedData?.basicIndustries);
                    let stockCols = ['sector_code', 'industry_code', 'nse_basic_ind_code']
                    var results = await AdminServicesPY.getStocksColData({ stocks: temp?.map((el) => el?.symbol), cols: stockCols, all_cols: false }).then(r => r)
                    try {
                        results = JSON.parse(results?.data);
                        results = results?.map((fund) => {
                            return {
                                ...temp?.filter((ii) => ii?.symbol === fund?.symbol)?.[0],
                                ...fund,
                                // "gdf_symbol": fund?.nse_series === 2 ? fund?.symbol + ".BE" : fund?.nse_series === 3 ? fund?.symbol + ".BZ" : fund?.symbol
                                "gdf_symbol": fund?.symbol
                            }
                        })
                        results = results?.sort((a, b) => b?.mcap - a?.mcap) || [];
                        setAllFunds(results);
                    }
                    catch (err) {
                        //console.log(err)
                        setAllFunds([])
                    }
                }
                else {
                    temp = temp?.sort((a, b) => b?.aum - a?.aum) || [];
                    setAllFunds(temp);
                    let benchmarks = Array.from(new Set(temp?.map(item => item?.benchmark)));
                    benchmarks = benchmarks.map((i, index) => {
                        var x = {}
                        x["value"] = i
                        x["label"] = i
                        x['key'] = i
                        return x
                    })
                    benchmarks = benchmarks.filter((x) => x?.label !== null && x?.label !== "" && x?.label !== undefined)
                    setBenchmarks(benchmarks)
                    setmfCategories(res?.fabricatedData?.mf_categories)
                }
                setFundsLoading(false);
                if (user?.isLogged) {
                    if (type?.value === 4) {
                        var res2 = await AdminServices.getAllSuperScreens(user.id).then((res) => res)
                        if (res2?.type) {
                            setScreens([
                                ...res2?.data?.filter((i) => i?.is_deleted === 0)?.map((i) => {
                                    return {
                                        ...i,
                                        isSuper: true
                                    }
                                })])
                            setLoadingScreens(false);
                        } else {
                            toast.error("Something went wrong!!")
                            setLoadingScreens(false);
                        }
                    } else {
                        let scr = await getUserScreeners(user?.id, type.strVal);
                        setScreens(scr);
                        setLoadingScreens(false);
                    }
                    //console.log(scr);
                }
            }
            dem();
        }
    }, [type.value, user]);

    // getting  watchlist useEffect
    React.useEffect(() => {
        if (instrument_method === 1 && allFunds?.length > 0) {
            if (watchlist?.length > 0) {
                let temp = watchlist.map((fund) => {
                    return {
                        ...fund,
                        ...allFunds.filter((asset) => (String(asset[type.value === 3 ? "UID" : type.value === 1 ? "nse_symbol" : type.value === 4 ? "symbol" : "plan_id"]) === String(fund["FundCode"]) || String(asset[type.value === 3 ? "UID" : type.value === 1 ? "isin_code" : type.value === 4 ? "symbol" : "isin_code"]) === String(fund["FundId"])))[0]
                    }
                })
                setwatchData(temp?.filter((i) => i?.FundType === type?.value)?.reverse())
            }
            else {
                setwatchData([]);
            }
        }
    }, [type.value, allFunds, watchlist]);

    React.useEffect(() => {
        setallFilteredFundsData(props?.allFilteredFundsData || [])
    }, [props?.allFilteredFundsData])

    React.useEffect(() => {
        setcalculatedWeights([])
    }, [allFilteredFundsData])

    // sets min investment amount on instrumennt change
    React.useEffect(() => {
        //console.log(instrumentList)
        var minInvestmentv = instrumentList?.length > 0 ? Number(minInvestment(instrumentList?.map((data) => {
            return {
                ...data,
                latest_nav: type.value === 2 ? data?.latest_nav : getCurrentLTP({ fundData: data })
            }
        }))).toFixed(2) : 0
        setminInvestmentAMT(minInvestmentv)
    }, [instrumentList])

    // gets data to checkk whether user can save basket
    React.useEffect(() => {
        setTotalScreens(0);
        setTotalSignals(0);
        setTotalBaskets(0);
        getBasketAccessLimit();
    }, [])

    // calculates weight allocation on weight method  change
    React.useEffect(() => {
        if (instrumentList.length > 0) {
            allocate(instrumentList)
        }
    }, [weight_method, weight_by, inverseV])

    React.useEffect(() => {
        let opt = options?.find((el) => el?.key === rebalanceOption);
        if (rebalanceOption === 7) {
            setRebalanceFreq('6M')
        }
        else {
            setRebalanceFreq(opt?.value)
        }
    }, [rebalanceOption]);

    React.useEffect(() => {
        let opt = options?.find((el) => el?.value === rebalanceFreq);
        if (opt) {
            setRebalanceInp1(opt?.inp1 || '');
            setRebalanceInp2(opt?.inp2 || '');
        }
    }, [rebalanceFreq])

    const RefineSelection = () => {
        ////console.log(props?.newFiltersList)
        const [query_multiple_val, set_query_multiple_val] = React.useState(numOfFunds)
        return <div>
            {instrument_method === 2 ? <div className='fontH2 mx-2 w-500'>Select a way to start investing in your {instrument_method === 2 ? "screen" : "signal"}</div> : <div className='fontH2 mx-2 w-500'>Selet the number of {type.dname} you want to keep in strategy</div>}
            {instrument_method === 2 && <Row className='m-2' style={{ gap: "1rem", position: "relative" }}>
                <Card onClick={props?.allFilteredFundsData.length <= 50 ? () => {
                    setselectedPortfolioType(1)
                } : () => { }} bodyStyle={{ padding: "1rem", opacity: props?.allFilteredFundsData.length <= 50 ? 1 : 0.4 }} style={{ flex: 1, cursor: props?.allFilteredFundsData.length <= 50 ? "pointer" : "not-allowed", border: selectedPortfolioType === 1 ? "2px solid var(--primary)" : "1px solid #e5e5e5" }} hoverable={props?.allFilteredFundsData.length <= 50}>
                    <div className='fontH2 w-500'>Invest in entire {instrument_method === 2 ? "screen" : "signal"}</div>
                    <div className='textXS'>All stocks in the {instrument_method === 2 ? "screen" : "signal"} will be selected in your portfolio. {props?.allFilteredFundsData.length > 50 && "This option is not available since number of stocks in your screen is greater than 50. You must refine it further to continue"}</div>
                </Card>
                <Card onClick={() => {
                    setselectedPortfolioType(2)
                }} bodyStyle={{ padding: "1rem" }} style={{ flex: 1, border: selectedPortfolioType === 2 ? "2px solid var(--primary)" : "1px solid #e5e5e5" }} hoverable>
                    <div className='fontH2 w-500'>Refine the {instrument_method === 2 ? "screen" : "signal"}</div>
                    <div className='textXS'>You can further refine your strategy by selecting top stocks based on your {instrument_method === 2 ? "screen" : "signal"} metrics. {props?.allFilteredFundsData.length > 50 && "Or you can go back and edit the screen so that number of stocks is less than 50"}</div>
                </Card>
            </Row>}

            {selectedPortfolioType === 2 ? <div className='m-2 mt-4'>
                {instrument_method === 2 && <div className='fontH2 my-1 w-500'>Refine your selections on the basis of market cap or your screener rules</div>}
                <div style={{ gap: "1rem", display: "flex", alignItems: "center" }}>
                    {instrument_method === 2 && <div>
                        <div className='textXS mt-1'>Metric</div>
                        <Select removeIcon={<TiDelete size={12} />}
                            className='textXS textDark'
                            suffixIcon={<MdArrowDropDown size={17} />}
                            value={metricType}
                            style={{
                                width: "200px",
                                marginTop: 0
                            }}
                            placeholder="Please select"
                            onChange={(value) => {
                                // onValueChange(value)
                                setMetricType(value)
                            }}
                            options={(type.value === 4 ? [{
                                value: "mcap",
                                label: "Market Cap",
                                key: "mcap"
                            }]?.concat(props?.newFiltersList.filter((i) => i.dataIndex !== "mcap" && i.metric_data_type === "float").map((i) => {
                                return {
                                    value: i.dataIndex,
                                    key: i.dataIndex,
                                    label: i.metric_name
                                }
                            }))
                                : type.value === 1 ? [{
                                    value: "expense_ratio",
                                    label: "Expense ratio",
                                    key: "expense_ratio"
                                }, {
                                    value: "21_day_vol",
                                    label: "21 day volume",
                                    key: "21_day_vol"
                                }]?.concat(props?.newFiltersList.filter((i) => (i.dataIndex !== "expense_ratio" || i.dataIndex !== "21_day_vol") && i.metric_data_type === "float").map((i) => {
                                    return {
                                        value: i.dataIndex,
                                        key: i.dataIndex,
                                        label: i.metric_name
                                    }
                                }))

                                    : [{
                                        value: "expense_ratio",
                                        label: "Expense ratio",
                                        key: "expense_ratio"
                                    }]?.concat(props?.newFiltersList.filter((i) => i.dataIndex !== "expense_ratio" && i.metric_data_type === "float").map((i) => {
                                        return {
                                            value: i.dataIndex,
                                            key: i.dataIndex,
                                            label: i.metric_name
                                        }
                                    }))

                            )}
                        />
                    </div>}

                    {instrument_method === 2 && <div>
                        <div className='textXS mt-1'>Order by</div>
                        <Segmented
                            className='fontC2 textDark'
                            options={sortOptions}
                            onChange={(value) => {
                                setSortType(value)
                            }}
                            value={sortType}
                            defaultValue={({ ...metric_data('metric_col_code', metricType) }.direction_flag === "top" ? sortOptions[0]?.value : sortOptions[1]?.value)}

                        />
                    </div>}

                    {instrument_method === 3 && <div className='textXS'>Top</div>}

                    <div>
                        {instrument_method === 2 && <div className='textXS mt-1'>Number of {type.dname}</div>}
                        <CurrencyInput
                            className='inputBoxMain'
                            style={{ width: "4rem", textAlign: "center" }}
                            allowDecimals={false}
                            min={type.value === 4 ? 5 : 2}
                            max={type.value === 4 ? 50 : 10}
                            value={query_multiple_val}
                            // prefix={String(currentMetricData?.metric_unit).toLowerCase() === "rupee" || String(currentMetricData?.metric_unit).toLowerCase() === "rupee_cr" ? "₹ " : ""}
                            // suffix={String(currentMetricData?.metric_unit).toLowerCase() === "rupee_cr" ? " Cr" : String(currentMetricData?.metric_unit).toLowerCase() === "percentage" ? " %" : ""}
                            onValueChange={(value) => {
                                set_query_multiple_val(value)

                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    if (query_multiple_val !== undefined) {
                                        setNumOfFunds(Number(query_multiple_val) < (type.value === 4 ? 5 : 2) ? (type.value === 4 ? 5 : 2) : Number(query_multiple_val) > (type.value === 4 ? 50 : 10) ? (type.value === 4 ? 50 : 10) : Number(query_multiple_val))
                                    } else {
                                        toast.error("Please enter a value")
                                    }
                                }
                            }}
                            onBlur={() => {
                                if (query_multiple_val !== undefined) {
                                    setNumOfFunds(Number(query_multiple_val) < (type.value === 4 ? 5 : 2) ? (type.value === 4 ? 5 : 2) : Number(query_multiple_val) > (type.value === 4 ? 50 : 10) ? (type.value === 4 ? 50 : 10) : Number(query_multiple_val))
                                } else {
                                    toast.error("Please enter a value")
                                }

                            }}
                            onSubmit={() => {
                                if (query_multiple_val !== undefined) {
                                    setNumOfFunds(Number(query_multiple_val) < (type.value === 4 ? 5 : 2) ? (type.value === 4 ? 5 : 2) : Number(query_multiple_val) > (type.value === 4 ? 50 : 10) ? (type.value === 4 ? 50 : 10) : Number(query_multiple_val))
                                } else {
                                    toast.error("Please enter a value")
                                }
                            }}
                        />
                    </div>

                    {instrument_method === 3 && <div className='textXS'>{type.dname} based on Aggregated score</div>}

                </div>
            </div> : <></>}
            <div className='fontH2 m-2 mt-4 w-500'>{type.value === 4 ? "Stocks" : "Funds"} you will be investing in</div>
            <div className='mx-2' style={{ position: "relative", height: selectedPortfolioType === 2 ? "26vh" : "40vh", overflowY: "scroll" }}>
                {allFilteredFundsData.map((item, index) => {
                    return <Tag key={generateUniqueKey(index)} style={{ position: "relative" }} className='my-1'>
                        <div style={{ display: "flex", alignItems: "center", gap: "0.2rem", padding: "0.3rem" }}>
                            <AMCIcon amcName={item.symbol || item.amc_full_name} fundType={type.value} height='1rem' width="1rem" bseTicker={item?.bse_ticker} />
                            <span>{item.proper_name || item.basic_name}</span>
                        </div>
                    </Tag>
                })}
            </div>
        </div >
    }

    const SelectBenchmark = () => {
        return <div style={{ flex: 1 }}>
            <div className='textSM w-500' >Select benchmark for your strategy</div>
            <div className="mt-3" style={{ width: isMobile ? "100%" : "20rem" }} >
                <InstrumentListSearch placeholder={`Search by benchmark name`} type={11} onChange={(e) => setselectedBenchmark(e)} align='left' isFullWidthInMob />
            </div>
            <div style={{ marginTop: "30px" }}>
                {selectedBenchmark.map((data, index) => {
                    return <div style={{ borderBottom: "1px solid " + 'var(--light)' }} key={generateUniqueKey(data.ticker)}>
                        <div style={{ display: "flex", gap: "20px", alignItems: "center", padding: 10 }}>
                            <div style={{ flex: 1 }}>
                                <div className='textSM w-500'>{data.benchmark_name}</div>
                            </div>
                            <FiMinusCircle size={20} style={{ backgroundColor: "#FFE7E5", color: '#FF8080', borderRadius: "50%", margin: "auto 0", cursor: "pointer" }}
                                onClick={() => setselectedBenchmark([])} />
                        </div>
                    </div>
                })}
            </div>
        </div>

    }

    const PortfolioName = () => {


        const disabledDate1 = useCallback((d) => {
            if (d == null) {
                return null;
            }
            return (
                (minDate != null && d.isBefore(minDate) && !d.isSame(minDate, 'day')) ||
                (maxDate != null && d.isAfter(maxDate) && !d.isSame(maxDate, 'day'))
            );
        }, [minDate, maxDate]);

        const disabledDate2 = useCallback((d) => {
            if (d == null) {
                return null;
            }
            return (
                (minDate != null && d.isBefore(minDate) && !d.isSame(minDate, 'day')) ||
                (maxDate != null && d.isAfter(maxDate) && !d.isSame(maxDate, 'day')) ||
                (value1 != null && d.isBefore(value1) && !d.isSame(value1, 'day')) || (d.diff(value1, 'days') < 91)
            );
        }, [minDate, maxDate, value1]);
        // if (type?.value !== 2) {
        return <div style={{ flex: 1, overflowY: "auto" }}>
            {backTestModalLoading && backTestPerfData?.length === 0 ? <div style={{
                height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", alignContent: "center", justifyItems: "center"
            }}>
                {/* <Lottie options={{
                    loop: true,
                    autoplay: true,
                    height: "10rem",
                    width: "10rem",
                    animationData: animationData,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                    },
                }} width='10rem' height='10rem' isClickToPauseDisabled /> */}
                <img width='10rem' height='10rem' style={{
                    height: "10rem",
                    width: "10rem",
                }} src={require('Assets/lottie/chart-load.gif')} alt="loading..." />
                <CustomChangingTextLoader
                    otherText={["Generating signals...", "Running back test...", "Fetching results..."]}
                />
            </div> :
                <div>
                    {viewType === "backtest" && <div className="mb-4" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Space direction='vertical' style={{}}>
                            <div className='textMD w-700 dark4 mb-1 d-flex align-items-center' style={{ gap: "0.5rem" }}>
                                Backtest start and end date
                            </div>
                            <Space direction='horizontal'>
                                <DatePicker rootClassName={`${"custom-key-handle-" + makeid(4)}`} popupClassName={`${"custom-key-handle-" + makeid(4)}`} className={`${"custom-key-handle-" + makeid(4)}`} disabledDate={disabledDate1} placeholder='Start date' value={value1} format={'DD-MM-YYYY'} onChange={(val) => {
                                    setstart_date(formatDateyyyymmdd(new Date(val)))
                                    setValue1(val)
                                }} />
                                <DatePicker rootClassName={`${"custom-key-handle-" + makeid(4)}`} popupClassName={`${"custom-key-handle-" + makeid(4)}`} className={`${"custom-key-handle-" + makeid(4)}`} disabledDate={disabledDate2} disabled={value1 === null || value1 === undefined} placeholder='End date' value={value2} format={'DD-MM-YYYY'} onChange={(val) => {
                                    setend_date(formatDateyyyymmdd(new Date(val)))
                                    setValue2(val)
                                }} />
                            </Space>
                        </Space>
                        {(start_date !== dates?.[0] || endDate !== dates?.[1]) && <button className="btnBlack" onClick={() => {
                            getHistoricalPerformance(true)
                        }}>Re run Backtest</button>}
                    </div>}
                    <SuperScreenerBacktestAndPaperTrade type={type} isAdvance={true} allFundsData={allFunds} isPaperTradeAllowed={true}
                        onBacktestPressed={() => {
                            setviewType("backtest")
                        }}
                        viewType={viewType}
                        onPaperTradePressed={onPaperTrade} initial_capital={backtestConfig?.initial_capital} backTestModalLoading={backTestModalLoading} isSuperScreener={true} chartData={backTestPerfData} backTestData={backTestData} backtestType='screener'
                    />
                </div>
            }
        </div>
        // } else {
        //     return (
        //         <div style={{ flex: 1, overflowY: "auto" }}>
        //             <div style={{}}>
        //                 <CardHeader heading='Historical performance' headingType={2} hideHeaderBlueLine />
        //                 <br />
        //                 {historicalPerfLoading ? <div style={{ margin: "2rem 0" }}>
        //                     <ActivityLoaderMintbox message={['1W', '2W']?.includes(rebalanceFreq) ? `This strategy will be rebalanced ${rebalanceFreq === '1W' ? "weekly" : "biweekly"}. Backtesting might take a few seconds.` : ""} />
        //                 </div> :
        //                     <PerfBenchChart portfolioType={3} isDashboard={false} type={type} chartData={historicalPerformance?.perfromance_against_bm?.prices}
        //                         benchName={selectedBenchmark?.[0]?.benchmark_name} xAxisKey={type.value === 4 ? 'date' : "Date"} />}
        //             </div>
        //         </div>
        //     )
        // }
    }

    const steps = [
        isModalView && {
            id: "aa",
            title: `Refine your ${instrument_method === 2 ? "screen" : "factor model"}`,
            description: `Select a way to start investing in your ${instrument_method === 2 ? "screen" : "factor model"}`,
            content: <RefineSelection />,
            nextbtnname: "Define weights",
            nextbtnclick: () => next()
        },
        {
            id: "a",
            title: `${isModalView ? "Define weights for " + (instrument_method === 2 ? "screened " : "") + type.dname : "Add your favourite " + type.dname + " and define weights"}`,
            description: `Tell us how much weight do you want to assign each ${type.omkar} in your strategy?`,
            content: <AddInstrument allFunds={allFunds} watchlist={watchlist} instrumentList={instrumentList} type={type} instrument_method={instrument_method} sectors={sectors} industryGrp={industryGrp}
                industries={industries} mfCategories={mfCategories} benchmarks={benchmarks} isMobile={isMobile} isModalView={isModalView} onAddInstruments={onAddInstruments}
                listLoading={listLoading} weight_methods={weight_methods} weight_method={weight_method}
                setweight_method={setweight_method} weight_by={weight_by} setweight_by={setweight_by} inverseV={inverseV} setinverseV={setinverseV} nextDisabled={nextDisabled} screens={screens} loadingScreens={loadingScreens} watchData={watchData} getCurrentLTP={getCurrentLTP}
                changeData={changeData} del={del} checkAddDisabled={checkAddDisabled} sum={sum} screenerMetrics={screenerMetrics?.filter((i) => i?.metric_data_type === "float")} />,
            prevbtnname: isModalView ? "Previous" : undefined,
            nextbtnname: "Confirm weights",
            nextbtndisabled: isNaN(minInvestmentAMT) || !((instrumentList?.length >= 2 && instrumentList?.length <= (type.value === 4 ? 50 : 10))) || nextDisabled,
            nextbtnclick: () => next(),
            hideTitle: true,
        },
        {
            id: "aaa",
            title: `Choose a performance benchmark for your strategy`,
            description: `We will show the back tested performance of your strategy against this benchmark`,
            content: <SelectBenchmark />,
            prevbtnname: "Previous",
            nextbtnname: "Confirm",
            nextbtndisabled: selectedBenchmark.length === 0,
            nextbtnclick: () => {
                next()
            }
        },
        {
            id: "c",
            title: `Choose rebalancing frequency`,
            description: `${isModalView ? "How often would you like the strategy to be rebalanced to new set of " + type.dname : "Tell us how often (if at all!) you want to rebalance your strategy to maintain weightages"}`,
            content: <SelectRebalancing2 config={config} setConfig={setConfig} isMobile={isMobile} />,
            prevbtnname: "Previous",
            nextbtnname: "Confirm",
            nextbtnclick: () => {
                if (type?.value === 2 || config?.schedule === undefined)
                    getHistoricalPerformance();
                next()
            }
        }, type?.value !== 2 && config?.schedule && {
            id: "d",
            title: `Risk Management & Transaction Cost`,
            // hideTitle: true,
            description: `This section allows you to define risk management models and brokerage models for your strategy.`,
            content: <RiskMangementAndTransCost config={config} setConfig={setConfig} isMobile={isMobile} />,
            prevbtnname: "Previous",
            nextbtnname: "Confirm",
            nextbtnclick: () => {
                if (type?.value !== 2)
                    getHistoricalPerformance();
                next()
            }
        },
        {
            id: "e",
            title: `View backtest and paper trade`,
            description: `That’s it - start investing in your own strategy. We will monitor and help you manage it.`,
            content: PortfolioName(),
            prevbtnname: "Previous",
            nextbtnname: viewType === "papertrade" ? undefined : "Paper trade",
            nextbtndisabled: false,
            nextbtnclick: () => {
                try {
                    document.getElementById("PaperTradeNowBtn").click()
                } catch (error) {

                }
            }
        },
    ].filter((i) => i)


    //console.log(steps)

    React.useEffect(() => {
        isValidAllo()
    }, [instrumentList])

    React.useEffect(() => {
        if (isModalView)
            getFilteredFunds()
    }, [metricType, sortType, numOfFunds])

    React.useEffect(() => {
        if (selectedPortfolioType === 1) {
            if (isModalView) {
                setallFilteredFundsData(props?.allFilteredFundsData)
                allocate(props?.allFilteredFundsData)
            }
        } else {
            if (isModalView)
                getFilteredFunds()
        }
    }, [selectedPortfolioType])

    const StepsView = () => {

        return (
            <div style={{ padding: isMobile ? "0" : "1rem", border: isMobile ? "none" : "1px solid var(--grey2)", borderRadius: 8, width: isMobile ? "100%" : "25%", flexShrink: 0 }}>
                {steps?.filter((el) => el)?.map((item, i, arr) => {
                    return (
                        <div key={generateUniqueKey(i)} style={{}} >
                            <div className='d-flex' style={{ gap: isMobile ? "1rem" : "0.5rem", alignItems: isMobile ? "flex-start" : "center" }}>
                                <div style={{
                                    height: '25px', width: "25px", background: isMobile ? "var(--dark4)" : basketSaved === true || current > i ? "var(--green)" : current === i ? "var(--primary)" : 'var(--grey2)',
                                    borderRadius: "50%", flexShrink: 0,
                                    color: isMobile ? "var(--white)" : basketSaved === true || current > i ? "var(--white)" : current === i ? "var(--white)" : 'var(--black)'
                                }}
                                    className='d-flex align-items-center justify-content-center textXS w-500'>
                                    {current > i || basketSaved === true ? <BsCheck2 size='1rem' /> : `${i + 1}`}
                                </div>
                                <div>
                                    <div className='textSM w-500'>
                                        {item?.title}
                                    </div>
                                    {isMobile && <div className='textXS dark3 mt-1'>{item?.description}</div>}
                                </div>
                            </div>
                            {i !== arr.length - 1 && <Divider style={{ margin: isMobile ? "1.25rem 0" : "1rem 0" }} />}
                        </div>
                    )
                })}
            </div>
        )
    }

    const metaItems = {
        1: {
            title: "Custom ETF Strategy | sharpely ETF Strategy",
            description: "sharpely's etfs strategy is personalized to provide assured investments. These funds track an index from a specific sector and invest in a stock strategy similar to the index."
        },
        2: {
            title: "Custom Mutual Fund Strategy | sharpely Strategy",
            description: "sharpely's MF strategy is personalized to offer assured equity and debt exposure through a portfolio of stocks, mutual funds, ETFs, and other asset classes.."
        },
        4: {
            title: "Custom Stocks Strategy | sharpely Stock Strategy",
            description: "sharpely Custom Strategy: let user handpick stocks for their portfolio."
        }
    }

    return (
        <React.Fragment>
            <Head title={metaItems?.[type?.value]?.title || "Custom strategy"} description={metaItems?.[type?.value]?.description || `Custom ${type?.dname} strategy`} />
            <AccessControlledPage moduleKey={type?.value === 4 ? "BUILD,_BACKTEST_AND_PAPERTRADE" : type?.value === 1 ? "BUILD,_BACKTEST_AND_PAPERTRADE_ETFs" : "BUILD,_BACKTEST_AND_PAPERTRADE_MF"} />
            <div style={{ paddingTop: "1.25rem", paddingBottom: "1.25rem" }} className={`normalContainer  ${'leftRightPadContainer'} ${isDev ? 'maxWidthContainer' : ""}`} >
                <div style={{ height: isMobile ? "auto" : '80vh', display: "flex", minHeight: isMobile ? "100vh" : "", flexDirection: "column" }}>
                    <div style={{ flexGrow: 1, height: isMobile ? "auto" : "100%", overflow: isMobile ? "" : "hidden" }} className='d-flex flex-column'>
                        <div style={{ flex: 1, display: "flex", flexDirection: "column", height: "100%", padding: 0 }} >
                            {showIndex ? <StepsView /> : <div style={{
                                height: isModalView ? '80vh' : isMobile ? `100%` : '80vh', display: "flex", gap: "1.5rem", flex: 1,
                                flexDirection: isMobile ? "column" : "row"
                            }}>
                                {!isMobile && <StepsView />}
                                <div style={{ flex: 1, display: "flex", flexDirection: "column", height: "100%", padding: 0 }}>
                                    {!steps[current]?.hideTitle && <div>
                                        <CardHeader heading={steps[current]?.title} hideHeaderBlueLine headingType={2} textClassName={isMobile ? "textMD w-700" : 'textXL w-700'} />
                                        <div className='textSM dark3' style={{ margin: isMobile ? "0.75rem 0 1.25rem" : "0.25rem 0 1rem", maxWidth: "60ch" }}>
                                            {steps[current]?.description}
                                        </div>
                                    </div>}
                                    {steps[current]?.content}
                                    {!isMobile && <>
                                        <Divider style={{ marginTop: 0, marginBottom: isMobile ? "0" : "1.25rem" }} />
                                        <div style={{
                                            padding: isMobile ? "1.25rem 0" : 0, position: isMobile ? "sticky" : "static", bottom: 0, background: "var(--white)",
                                            borderTop: isMobile ? '1px solid var(--grey2)' : ""
                                        }}>
                                            <div style={{ display: "flex", gap: "1rem", alignItems: "center", justifyContent: "flex-end", textDecoration: "none !important", cursor: "default", }}>
                                                {!nextDisabled && instrumentList?.length > 0 && steps[current]?.id !== "aa" &&
                                                    <div className="textSM dark3" style={{ flex: 1, display: "flex", alignItems: isMobile ? "start" : "center", flexDirection: isMobile ? "column" : "row" }}>
                                                        {isMobile ? <span className='textXS w-500 dark3'>Min Investment</span> : <span>Minimum investment: </span>}
                                                        <span className={isMobile ? "textSM w-500 black" : "mx-1 textMD dark w-500"}>
                                                            {CurrencyFormat(minInvestmentAMT)}
                                                        </span>
                                                    </div>}
                                                {basketSaved === false ? <>
                                                    {steps[current]?.prevbtnname !== undefined &&
                                                        <button className="btnWhite "
                                                            onClick={() => prev()}>
                                                            {steps[current]?.prevbtnname}
                                                        </button>}
                                                    {steps[current]?.nextbtnname !== undefined ?
                                                        <button className={"btnBlack "} disabled={steps[current]?.nextbtndisabled} onClick={() => steps[current]?.nextbtnclick()}>
                                                            {steps[current]?.nextbtnname}
                                                        </button> : null}
                                                </> :
                                                    <>
                                                        <Link to={type.basePath + `/basket-detail/${basketData?.MintboxName}/2/${basketData?.UID}`}>
                                                            <button className='btnWhite'>Strategy analytics</button>
                                                        </Link>
                                                        <div>
                                                            <SharpelyGateway2 IS_BASKET={true} INSTRUMENT_TYPE={type.value} FUNDDATA={basketData} TRANSACTION_TYPE={'BUY'} />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </>}
                                </div>
                            </div>}
                        </div>
                    </div>
                    {isMobile && <div style={{
                        borderTop: "1px solid var(--grey2)", padding: isMobile ? "1rem 0" : "1.25rem 0 0",
                        position: isMobile ? "sticky" : "static",
                        bottom: isMobile ? 0 : "", background: "var(--white)", marginTop: isMobile ? "1.25rem" : "",
                        zIndex: 1000
                    }}>
                        {!nextDisabled && instrumentList?.length > 0 && steps[current]?.id !== "aa" &&
                            <div className="textSM dark3 mb-3 text-center" style={{ flex: 1, display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}>
                                <span className='textXS w-500 dark3'>Min Investment: &nbsp;</span>
                                <span className={isMobile ? "textSM w-500 black" : "mx-1 textMD dark w-500"}>
                                    {CurrencyFormat(minInvestmentAMT)}
                                </span>
                            </div>}
                        <div style={{
                            gap: "1.25rem",
                        }} className='d-flex justify-content-end'>
                            {showIndex ? <button className='btnBlack w100 ' onClick={() => setShowIndex(false)}>
                                Get Started &gt;
                            </button> : <>
                                {steps[current]?.prevbtnname !== undefined && <button className="btnWhite " onClick={() => {
                                    prev()
                                }} style={{ flex: isMobile ? 1 : 0 }}>
                                    {steps[current]?.prevbtnname}
                                </button>}
                                {steps[current]?.nextbtnname !== undefined && <button className="btnBlack " disabled={steps[current]?.nextbtndisabled} onClick={() => {
                                    steps[current].nextbtnclick()
                                }} style={{ flex: isMobile ? 1 : 0 }}>
                                    {steps[current]?.nextbtnname}
                                </button>}
                            </>}
                        </div>
                    </div>}
                </div>
            </div>
            {basketData?.UID && basketSaved && <SharpelyGateway2 IS_BASKET={true} INSTRUMENT_TYPE={type.value} FUNDDATA={basketData} TRANSACTION_TYPE={'BUY'} openFromParent={basketSaved} noCta={true} />}
            <Modal open={basketSaveModal?.open} onCancel={() => setBasketSaveModal({})} onOk={() => setBasketSaveModal({})} closable={false} footer={null}>
                {basketSaveModal?.type === 'invest' && type?.value === 2 ? <>
                    <div className='d-flex align-items-center flex-column' style={{ gap: "2rem", marginTop: "2rem" }}>
                        <img loading="lazy" alt="" src={microphone} style={{ width: "8rem", height: "8rem" }} />
                        <div className='textMD text-center'>
                            Mutual fund investments are currently paused. Pretty soon you will be able to transact and invest in mutual funds.
                        </div>
                    </div>
                </> : <>
                    <div className='textMD w-700 mb-3'>You're just one step away!</div>
                    <div className='textSM mb-2'>Choose a name for your strategy and {basketSaveModal?.type === 'invest' ? "start investing." : "save it."}</div>
                    <div className='textSM w-500'>What would you like to call it?</div>
                    <div style={{ marginTop: "4%" }}>
                        <Input size="large" status={String(mintboxName).match(/[\/?'&"]/g) ? "error" : ""} maxLength={120} style={{ maxWidth: isMobile ? "" : "20vw" }} placeholder="My wealth box" value={mintboxName} onChange={(e) => {
                            let val = String(e.target.value);
                            setMintboxName(val)
                        }} />
                        {String(mintboxName).match(/[\/?'&"]/g) ? <label style={{ color: "var(--red)", textAlign: "right" }} className='textXS w-500'>
                            * Special characters are not allowed
                        </label> : null}
                    </div>
                    <div className='d-flex justify-content-end' style={{ gap: "1rem", marginTop: "1.25rem" }}>
                        <button className='btnWhite' onClick={() => setBasketSaveModal({})} >Cancel</button>
                        {basketSaveModal?.type === 'invest' ? <button className='btnBlack' style={{ textTransform: "capitalize" }} onClick={() => {
                            createMintbox(true)
                        }} disabled={String(mintboxName).match(/[\/?'&"]/g) || mintboxName?.length < 3}>{basketSaveModal?.type}</button> : <button className='btnBlack' style={{ textTransform: "capitalize" }} onClick={() => {
                            createMintbox(false)
                        }} disabled={String(mintboxName).match(/[\/?'&"]/g) || mintboxName?.length < 3}>{basketSaveModal?.type}</button>}
                    </div>
                </>}
            </Modal>
        </React.Fragment >
    );
};

export default BuildCustomPortfolio;
