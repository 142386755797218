import React from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ children }) => {
    const location = useLocation();

    React.useLayoutEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        })
    }, [location.pathname])

    React.useEffect(() => {
        if (location.hash) {
            var fragment = location.hash.substring(1);
            var targetElement = document.getElementById(fragment);
            if (targetElement) {
                targetElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                    inlineOffset: -60,
                    blockOffset: -60,
                });
            }
        }
        // dispatch(updatesubscribedStocks([]))
    }, [location.pathname]);

    React.useEffect(() => {
        const handleScrollToSection = () => {
            if (location.hash) {
                const fragment = location.hash.substring(1);
                const targetElement = document.getElementById(fragment);

                if (targetElement) {
                    targetElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'nearest',
                        inlineOffset: -60,
                        blockOffset: -60,
                    });
                }
            }
        };
        window.addEventListener('load', handleScrollToSection, { once: true });

        return () => {
            window.removeEventListener('load', handleScrollToSection);
        };
    }, [])
    return children || null;
};

export default ScrollToTop;