import React from "react"
import { LiveDataContextProvider } from "./LiveDataContext"

export const ContextProvider = ({ children }) => {

    return (
        <React.Fragment>
            <LiveDataContextProvider>
                {children}
            </LiveDataContextProvider>
        </React.Fragment>
    )
}