import { async } from "@firebase/util";
import { Card, Drawer } from "antd";
import axios from "axios";
import Logo from "Layout/Logo";
import React from "react"
import { IoIosArrowRoundBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { DataService } from "Services/api/DataService";
import ActivityLoaderMintbox from "../ActivityLoaderMintbox";
import AMCIcon from "../AMCIcon";
import { executeOrder } from "./BrokerFunctions";
// import querystring from 'querystring'
import apiHelper from "Services/api/ApiHelper";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import AdminServices from "Services/api/AdminServices";
import { Dashboard_Portfolio } from "Services/api/FabricatedAPIS";
import { updateInvCardStateObj, updateUserData } from "Libs/redux/UserAuth/reduxSlice";
const BrokerRedirectPage = ({ }) => {
    const user = useSelector((state) => state?.updateUserData?.value)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams()
    let invCardStateObj = useSelector((state) => state.investmentModal?.invCardStateObj);
    const existingbroker = user?.Client?.[0]?.Broker?.toLowerCase()
    const newBroker = invCardStateObj?.BROKER?.toLowerCase()
    const broker = (existingbroker === '' || existingbroker === 'disconnected') ? newBroker : existingbroker
    const authKeyName = {
        'paytm': 'requestToken',
        'angel': 'auth_token',
        'fyers': 'auth_code',
        'upstox': 'code',
        'dhan': 'tokenId',
    }

    const requestKeyName = {
        'zerodha': 'request_token',
        '5paisa': 'RequestToken',
    }
    const auth_token = searchParams.get(authKeyName?.[broker]) || ''
    const request_token = searchParams.get(requestKeyName?.[broker] || 'refresh_token') || ''
    console.log('request token', request_token)

    const handleError = () => {
        toast.error('something went wrong!')
        dispatch(updateUserData(undefined));
        navigate(invCardStateObj?.REDIRECT || '/')
        dispatch(updateInvCardStateObj({}))
    }
    React.useEffect(() => {
        console.log("sdjcnfsd", window.location.origin, auth_token)
        const dem = async () => {
            if ((auth_token && auth_token !== '') || broker === "zerodha" || broker === "5paisa") {
                // call api to generagte access token on success call execute order with state
                console.log('api call to get access token with ', auth_token, request_token)
                const obj = { clientId: user?.id, broker: broker, authToken: auth_token, requestToken: request_token }
                console.log('insertAuth ', obj)
                let res1 = await apiHelper({ apiName: 'insertAuth', data: obj, saveResponse: '' })
                console.log('response ', res1)
                if (res1) {
                    // res1 = await JSON.parse(JSON.stringify(res3).replace(/NaN/g, null)?.replace(/Infinity/g, null))
                    if (existingbroker === '' || existingbroker === 'disconnected') {
                        let data = broker === 'zerodha' ? {
                            clientId: user?.Client[0]?.ClientId,
                            broker: broker,
                            zerodhaAPIKey: invCardStateObj?.zerodhaAPIKey,
                            zerodhaAPISecret: invCardStateObj?.zerodhaAPISecret
                        } : {
                            clientId: user?.Client[0]?.ClientId,
                            broker: broker,
                        }

                        await AdminServices.editAccount(data).then(async (res1) => {
                            if (res1?.type === true) {
                                let userData = { name: user?.name, email: user?.email, mobile: user?.mobile, isLogged: true, id: user?.id, login_Date: user?.login_Date }
                                await Dashboard_Portfolio.getClientData(userData, true).then((res) => {
                                    if (res) {
                                        dispatch(updateUserData({ ...userData, ...res }))
                                        navigate(invCardStateObj?.REDIRECT + '?openInv=true' || '/')
                                    } else {
                                        console.log('get client failed')
                                        handleError()
                                    }
                                })
                            } else {
                                console.log('editAccount failed')
                                handleError()
                            }
                        })
                    } else {
                        toast.success(`Logged in to ${broker}`);
                        navigate(invCardStateObj?.REDIRECT + '?openInv=true' || '/')
                    }
                }
                else {
                    console.log('insertAuth failed')
                    handleError()
                }
            } else {
                console.log('error failed to get access token')
                handleError()
            }
        }
        dem()
    }, [])

    return <div className='loaderContainer'>
        <ActivityLoaderMintbox showQuote={true} />
    </div>

}

export default BrokerRedirectPage;
