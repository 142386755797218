import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { ContextProvider } from 'Context';
import store, { persistor } from 'Libs/redux';
import 'quill/dist/quill.snow.css';
import React, { Suspense } from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
// Import the functions you need from the SDKs you need
import AppDataFetcher from 'Components/AppDataFetcher';
import LazyLoadingFallback from 'Components/atoms/LazyLoadingFallback';
import ScrollToTop from 'Components/atoms/ScrollToTop';
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// //console.log(root.attributes.getNamedItem('data-theme').value)
// root.render(
//   <React.StrictMode>
//     <Router>
//       <App />
//     </Router>
//   </React.StrictMode>
// );

// const store = createStoreHook(rootReducer);


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBMIi2-qXmLrJ298WvRIZ0gMFdGlfbPEpM",
  authDomain: "sharpely-854b7.firebaseapp.com",
  projectId: "sharpely-854b7",
  storageBucket: "sharpely-854b7.appspot.com",
  messagingSenderId: "432057325478",
  appId: "1:432057325478:web:39085e5f97a6de948da167",
  measurementId: "G-L12F9CLWRS"
};

// if(isProd){
// }

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
window.livedata = {}
// if (!isDev) {

//   const originalConsoleError = console.error;
//   console.error = (...args) => {
//     if (args[0].includes('Warning:')) {
//       return;
//     }
//     originalConsoleError.apply(console, args);
//   };
//   console.log = () => { }
// }


// try {
//   console.log(navigator)
//   // if ('caches' in window) {
//   //   console.log(caches)
//   //   caches.keys()
//   //     .then(function (keyList) {
//   //       return Promise.all(keyList.map(function (key) {
//   //         return caches.delete(key);
//   //       }));
//   //     })
//   // }

//   if ('serviceWorker' in navigator) {
//     console.log("HI from serviceworker")
//     if ('serviceWorker' in navigator) {
//       navigator.serviceWorker.getRegistrations().then(function (registrations) {
//         //returns installed service workers
//         if (registrations.length) {
//           console.log("There are service workers")
//           for (let registration of registrations) {
//             console.log(registration)
//             registration.unregister();
//           }
//         }
//       });
//     }
//     serviceWorkerRegistration.unregister();
//     caches.keys().then(cacheNames => {
//       cacheNames.forEach(cacheName => {
//         caches.delete(cacheName);
//       });
//     }).then(() => {
//       console.log("Deleted cache")
//       // serviceWorkerRegistration.register();
//     })
//   }
//   // setTimeout(function () { window.location.replace(""); }, 300)
// } catch (error) {
//   console.log(error)
// }


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <Provider store={store}>
      <PersistGate loading={<div className='mainContainer' style={{
        margin: 'auto',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        position: 'fixed',
      }}>
        <ActivityLoaderMintbox />
      </div>} persistor={persistor}>
        <Suspense fallback={<LazyLoadingFallback />}>
          <Router basename={`/`}>
            <ContextProvider>
              <ScrollToTop>
                <App />
                <AppDataFetcher />
              </ScrollToTop>
            </ContextProvider>
          </Router>
        </Suspense>
      </PersistGate>
    </Provider >
  </React.Fragment>
);

// serviceWorkerRegistration.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
