import { toggleMetricDescModal } from "Libs/redux/KnowledgeBase/reduxSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ActivityLoaderMintbox from "./ActivityLoaderMintbox";
import CustomDrawer from "./CustomDrawer";

export const MetricDescriptionDrawer = () => {

    const dispatch = useDispatch();
    const metricDescModal = useSelector((state) => state?.metricDescModal);
    const description = metricDescModal?.params?.description;

    const toggle = () => {
        dispatch(toggleMetricDescModal({
            value: false,
            params: {}
        }))
    }

    React.useEffect(() => {
        if (metricDescModal?.value === true && description) {
            setTimeout(() => {
                const anchorTags = document.getElementsByTagName("a");
                for (let i = 0; i < anchorTags.length; i++) {
                    const anchor = anchorTags[i];
                    const href = anchor.getAttribute("href");
                    let modifiedHref = href;
                    if (modifiedHref?.startsWith("https://beta.sharpely") || modifiedHref?.startsWith("https://dev.sharpely")) {
                        modifiedHref = modifiedHref?.replace(/\b(beta\.sharpely|dev\.sharpely)\b/g, 'sharpely')
                    }
                    if (modifiedHref?.startsWith("/knowledge-base") || modifiedHref?.startsWith("https://sharpely.in/knowledge-base")) {
                        modifiedHref = modifiedHref?.replace(/\.html/g, "")?.replace(/_/g, "-");
                    }
                    anchor.setAttribute("href", modifiedHref);
                }
            }, 100)
        }
    }, [description]);

    if (!metricDescModal?.value) {
        return null;
    }
    return (
        <React.Fragment>
            <CustomDrawer isOpen={metricDescModal?.value} title={metricDescModal?.params?.title} toggle={toggle}>
                {!description ? <ActivityLoaderMintbox showQuote /> :
                    <div style={{ height: 'calc(100%)', backgroundColor: 'var(--grey4)', border: '1px solid var(--grey3)', borderRadius: '0.5rem', overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem 1.25rem' }} id=''>
                        {typeof (description) === 'string' ?
                            <div className='p-0 metricDescDrawer textSM' dangerouslySetInnerHTML={{ __html: description }}>
                            </div> :
                            <div className='p-0 metricDescDrawer textSM'>
                                {description}
                            </div>}
                    </div>
                }
            </CustomDrawer>
        </React.Fragment >
    )
}