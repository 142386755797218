import { useSubscribeMiddleware } from "Components/SubscribeModal";
import { useIsMobile } from "CustomHooks/useIsMobile";
import { setOpenSearchModal } from "Libs/redux/Modals/reduxSlice";
import { makeid } from "Libs/utils";
import React from "react";
import { FaArrowCircleRight } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Col, Row } from "reactstrap";

export const StockAnalysisToolsSection = ({ type = 1 }) => {
    /*  type = 1 --> Stock report
        type = 2 --> Strategy
        type = 3 --> Screener
    */
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const subscribeMiddle = useSubscribeMiddleware();
    const dispatch = useDispatch();

    const stockResearchTools = [
        {
            "key": "screener",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/screener.webp" },
            "title": type === 3 ? "Stock research" : "Screen stocks",
            "content": type === 3 ? "Our product comes loaded with rich research and analysis on Stocks." : "Build almost any screen – fundamental, technical, or a mix of both. Work with multiple timeframes, view history, and backtest.",
            onClick: () => {
                if (type === 3) {
                    dispatch(setOpenSearchModal(true))
                }
                else {
                    navigate('/stocks/super-screener')
                }
            }
        },
        {
            "key": "strategy",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/strategy1.webp" },
            "title": type === 2 ? "Stock research" : "Paper trade strategies",
            "content": type === 2 ? "Our product comes loaded with rich research and analysis on Stocks." :
                "Stop relying on stock tips! Use Strategy Builder to build, backtest and paper trade strategies",
            onClick: () => {
                if (type === 2) {
                    dispatch(setOpenSearchModal(true))
                }
                else {
                    navigate("/strategies")
                }
            }
        },
        {
            "key": "stylebox",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/stylebox.webp" },
            "title": "Use StyleBox",
            "viewType": "row",
            "content": "With over 5000+ stocks to choose from, our StyleBox classification lets you narrow down your research",
            onClick: () => {
                navigate("/stocks/stylebox")
            }
        },
        {
            "key": "srchart",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/earnings.webp" },
            "title": "Sector rotation chart",
            "content": "A powerful tool that helps you the gauge the strength and momentum of various sectors (or stocks)",
            onClick: () => {
                navigate("/sector-rotation-chart")
            }
        },
        {
            "key": "bfmodel",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/factor.webp" },
            "title": "Build factor models",
            onClick: () => {
                navigate("/stocks/factor-model")
            },
            "content": "With sharpely Factor Models, you can create your own factor-based quant models easily and intuitively.",
        },
        {
            "key": "checklist",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/checklist.webp" },
            "title": "Use stock checklist",
            "content": "See how your stock fares on checklists created by investing legends. You can create your own custom checklists as well",
            onClick: () => {
                navigate("/stocks/all-checklists")
            }
        },
        {
            "key": "stalert",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/strategy2.webp" },
            "title": "Stock alert",
            "content": "Create simple or complex alerts on your favourite stocks and get notified in near real time",
            onClick: () => {
                dispatch(setOpenSearchModal(true));
            }
        },
        {
            "key": "sanal",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/sector.webp" },
            "title": "Sector analysis",
            "content": "Our powerful sector analysis tool drills deep into sector fundamentals and technicals",
            "viewType": "row",
            onClick: () => {
                navigate('/stocks/sectors')
            }
        },
        {
            "key": "inanal",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/index.webp" },
            "title": "Index analysis",
            "content": "A comprehensive analysis of stock indices including aggregated fundamentals and techincals",
            onClick: () => {
                navigate('/indices')
            }
        },
        {
            "key": "mfetfresearch",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/magic.webp" },
            "title": "MF and ETF research",
            "content": "Our product comes loaded with rich research and analysis on ETFs and Mutual Funds as well.",
            onClick: () => {
                dispatch(setOpenSearchModal(true));
            }
        },
        {
            "key": "muchmore",
            "icon": { uri: "https://d3etfstcuyoos5.cloudfront.net/app-images/sharpely-explore/alpha.webp" },
            "title": "And much more!",
            "content": "We have so much more to offer. sharpely is the only investing platform you will ever need.",
            onClick: () => {
                navigate('/stocks/dashboard')
            }
        }
    ]

    return (
        <React.Fragment>
            <div style={{
                flex: 1, height: '100%', width: '100%', display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center'
            }}>
                <div style={{
                    display: 'flex', flexDirection: 'column', flex: 1,
                    justifyContent: 'space-between', height: '100%', width: '100%', gap: "3rem", paddingLeft: 0, paddingRight: 0
                }} className='padAllCampaign'>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className='padLeftCampaign padRightCampaign'>
                        <h2 className='headLGtoXS margin0' style={{ fontWeight: 700, width: isMobile ? "100%" : "20ch", color: 'var(--white2)' }}>
                            What else can you do on <span style={{ color: 'gold' }}>sharpely</span>
                        </h2>
                        <div className='textMDtoESM dark2' style={{ width: isMobile ? "100%" : "65%", paddingTop: "1rem", }}>
                            Discover cutting-edge tools that empower you to filter and analyze stocks with precision. Make informed investment decisions effortlessly and boost your portfolio's performance.
                        </div>
                    </div>
                    <div style={{
                        paddingTop: "1rem", paddingBottom: "1rem"
                    }} className='padLeftCampaign padRightCampaign'>
                        <Row lg={4} md={3} sm={2} xs={1} className="gx-4 gy-4">
                            {stockResearchTools?.map((tool, i) => {
                                return (
                                    <Col key={makeid(i + 1)}>
                                        <div style={{ border: "1px solid var(--dark4)", padding: "1rem", borderRadius: "0.5rem", cursor: "pointer" }}
                                            className="w100 h100 analyseStockItem" onClick={() => {
                                                subscribeMiddle({
                                                    reqAccessLevel: 1,
                                                    onNext: () => {
                                                        tool.onClick()
                                                    },
                                                    loginModalParams: {
                                                        type: "register",
                                                        executeOnLoginOrSignup: () => {
                                                            tool.onClick()
                                                        }
                                                    }
                                                })
                                            }}>
                                            <div className="d-flex align-items-center" style={{ gap: "0.75rem" }}>
                                                <img loading="lazy" src={tool?.icon?.uri} style={{ width: "1.5rem", height: "1.5rem" }} />
                                                <div className="textMDtoSM w-500" style={{ color: 'var(--white2)' }}>
                                                    {tool.title}
                                                </div>
                                            </div>
                                            <div className="textESM mt-2 dark2">
                                                {tool?.content}
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                    <div style={{ marginTop: "-1.5rem" }} className='padLeftCampaign padRightCampaign'>
                        <button className="btnPrimary textLG hoverEffect" style={{ padding: '0.6rem 1.25rem', display: 'flex', alignItems: 'center', gap: '1rem' }}
                            onClick={() => {
                                subscribeMiddle({
                                    reqAccessLevel: 1,
                                    onNext: () => {
                                        navigate('/stocks/dashboard')
                                    },
                                    loginModalParams: {
                                        type: "register",
                                        executeOnLoginOrSignup: () => {
                                            navigate('/stocks/dashboard')
                                        }
                                    }
                                })
                            }}>
                            Get started <FaArrowCircleRight />
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}