import moengage from "@moengage/web-sdk";
import { marketingSignup1, marketingSignup2 } from "Assets/images";
import { CurrencyFormat } from "Components/CurrencyFormat";
import PaySharpelyPricingFee from "Components/PaySharpelyPlanFee";
import { UpdatePhoneModal } from 'Components/atoms/UpdatePhoneModal';
import { useIsMobile } from "CustomHooks/useIsMobile";
import Head from "Layout/Head";
import { toggleHelpAndSupp, togglePayFeeModal, toggleloginModal, updatePayFeeParams } from "Libs/redux/UserAuth/reduxSlice";
import { getDivHeight } from "Libs/scroll";
import { makeid } from "Libs/utils";
import apiHelperPY from 'Services/api/ApiHelperPY';
import { Card, Collapse, Modal, Segmented, Tooltip } from "antd";
import React, { useState } from "react";
import { AiFillCheckCircle, AiOutlineInfoCircle } from "react-icons/ai";
import { BiMinus, BiPlus } from 'react-icons/bi';
import { BsCheck2 } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { HiArrowUpRight } from "react-icons/hi2";
import { IoIosArrowDown } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const Pricing = () => {

    const [plansData, setPlansData] = useState({});
    const [featuresData, setFeaturesData] = useState([]);
    const isDesktop = window.innerWidth > 575;
    const [updatePhoneModal, setUpdatePhoneModal] = useState(false);
    const [faqModal, setFaqModal] = useState(false);
    const [selectedFeatureType, setSelectedFeatureType] = React.useState('');
    const navigate = useNavigate();
    let isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
    let user = useSelector((state) => state?.updateUserData?.value)
    const dispatch = useDispatch();
    let userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel || 0;
    const accessData = useSelector((state) => state?.accessLevel?.value);
    const isMobile = useIsMobile();
    const pricingContainerRef = React.useRef();

    const faqs = [
        {
            title: "Is sharpely free to use?",
            content: <span>Yes. You can use sharpely for free. But in the free version, you will be able to use limited features. To get the best out of sharpely, you can purchase a suitable plan at any time. Here is a link to our pricing page for more details on what is available in the free plan.</span>,
        },
        {
            title: "What is sharpely premium and what do I get with a sharpely premium membership?",
            content: <span>
                <div>When you explore sharpely as a new visitor, you will encounter certain features that have restricted access. These exclusive features, known as premium or locked features, can be unlocked by subscribing to sharpely premium services.</div>
                <div className="marginTopFAQ">Sharpely premium provides unlimited and uninterrupted access to all the features we have designed specifically for you, including screeners, signals, scores, and much more.</div>
            </span>,
        },
        {
            title: "Why should I pay for sharpely premium when screening tools are generally available for free?",
            content: <span>
                <div>The financial data you encounter can be broadly categorized into two main groups.</div>
                <div className="marginTopFAQ">
                    Firstly, there is the category of ‘financial information’. This encompasses raw data such as financial statements, financial ratios, and basic screening tools. This information, along with the tools, is readily available to everyone and will always remain accessible for free on the Sharpely website.
                </div>
                <div className="marginTopFAQ">
                    The second category delves into much greater detail. Here, we leverage our proprietary tools to extract meaning from the raw data and provide actionable insights. This data is of institutional-grade quality and undergoes extensive refinement beyond its raw form. Additionally, we offer advanced stock analysis tools to further enhance your decision-making process.
                </div>
                <div className="marginTopFAQ">
                    By subscribing to sharpely premium services, you gain access to this refined data and invaluable insights, giving you a significant advantage over others. Alongside other key benefits, your subscription essentially grants you access to this high-quality data.
                </div>
                <div className="marginTopFAQ">
                    It's a small price to pay to stay sharp (no pun intended) and ahead of the rest.
                </div>
            </span>,
        },
        {
            title: "Can I use my membership on only one device?",
            content: <span>
                <div>Once subscribed, you get unlimited access to all the content and services from any device through the browser or via mobile applications.</div>
            </span>,
        },
        {
            title: "Can I create a customized package with the option I require?",
            content: <span>
                <div>Unfortunately, we do not provide the option to create a custom package. We do offer multiple packages to choose from in order to best fit your needs.</div>
            </span>,
        },
        {
            title: "How do I change my subscription plan?",
            content: <span>
                <div>If you want to upgrade from the free plan to a premium plan, visit the pricing page from your account. Compare available plans and select the most suitable option for you. Complete the payment using your preferred payment method.</div>
                <div className='marginTopFAQ'>
                    To change your premium subscription plan, all you have to do is simply mail us at <a href='mailto:support@sharpely.in'>support@sharpely.in</a> with the preferred plan.
                </div>
            </span>,
        },
        {
            title: "What are the different payment methods I can opt for?",
            content: <span>
                <div>Multiple payment options are supported. You can pay using a credit card, debit card, UPI, or net banking.</div>
                <div className='marginTopFAQ'>
                    You can also pay per month or set up a recurring payment (if you are opting for a monthly subscription). Note that you can cancel anytime.
                </div>
            </span>,
        },
        {
            title: "How do I cancel my sharpely pro subscription?",
            content: <span>
                <div>You can cancel anytime. Check our refund and cancellation policy <span style={{ color: "var(--primary)", cursor: "pointer" }} onClick={() => navigate('/refund-and-cancellation-policy')}>here.</span></div>
            </span>,
        },
        {
            title: "I have a paid membership but I am unable to access paid features?",
            content: <span>
                <div>sharpely paid membership is mapped against an email address which should be used to login to sharpely for accessing paid features.</div>
                <div className="marginTopFAQ">
                    1. The first step would be to check if you are logged in using the Email address you used for purchasing the paid plan. Kindly make sure that you have logged in using the same ID.
                </div>
                <div className="marginTopFAQ">
                    2. You might be logged out of your account while making the payment for a paid plan or inactivity over a long period of time. You need to log in to access the Pro features.
                </div>
                <div className='marginTopFAQ'>
                    You can always write to us at <a href='mailto:support@sharpely.in'>support@sharpely.in</a> if you face any issues accessing your paid membership.
                </div>
            </span>,
        },
        {
            title: "Can I delete my sharpely account?",
            content: <span>
                <div>Yes, you can delete your account. The option is available on your profile page.</div>
            </span>,
        }
    ]

    const loadData = async () => {
        let pls = await apiHelperPY({ apiName: "getAllSharpelyPlans", saveResponse: "" })
        if (pls) {
            pls = JSON.parse(pls);
            console.log('plans', pls);
            setPlansData(pls);
        }
        let pricingData = [...accessData];
        pricingData = pricingData?.filter((el) => el?.IsVisible !== 0);
        let data = {}
        for (let i = 0; i < pricingData?.length; i++) {
            let temp = pricingData?.[i];
            if (data[temp?.Type]) {
                if (data[temp?.Type][temp?.ModuleSection]) {
                    data[temp?.Type][temp?.ModuleSection]?.Data?.push(temp);
                }
                else {
                    data[temp?.Type][temp?.ModuleSection] = { Section: temp?.ModuleSection, Data: [temp] }
                }
            }
            else {
                data[temp?.Type] = { [temp?.ModuleSection]: { Section: temp?.ModuleSection, Data: [temp] } }
            }
        }
        let featuresTypes = Object.entries(data || {})?.map(([key, val]) => {
            return { key: key, label: key, value: key, data: Object.values(val || {}) }
        })
        setSelectedFeatureType(featuresTypes?.[0]?.value);
        setFeaturesData(featuresTypes);
    }

    const CheckIcon = () => (
        <AiFillCheckCircle style={{ color: "var(--green)", fontSize: "1.5rem" }} />
    )

    const CrossIcon = () => (
        <MdCancel style={{ color: "var(--red)", fontSize: "1.5rem" }} />
    )

    const isActivePlan = (item) => {
        return userAccesslevel === item?.level
    }

    const handleSubmit = (item, skipTrial = false) => {
        if (user?.isLogged) {
            if (user.mobile === '' || !user.mobile) {
                setUpdatePhoneModal(true);
            }
            else if (item?.type === 'free') {
                navigate('/stocks/dashboard')
            }
            else if (isActivePlan(item)) {
                return;
            }
            else if (item.type === 'paid') {
                dispatch(togglePayFeeModal(true));
                dispatch(updatePayFeeParams({
                    plan: item?.level
                }))
            }
            else {
                navigate('/contact-us');
            }
        }
        else {
            dispatch(toggleloginModal(true))
        }
    }

    const closeModal = () => {
        setUpdatePhoneModal(false);
    }

    const handlePricingScroll = (e) => {
        let target = document.querySelector('.pricingCtaCompare');
        let scrollCont = document.querySelector('.compareFeaturesSection');
        if (target && scrollCont) {
            let r = scrollCont.getBoundingClientRect();
            let h = getDivHeight('#sharpelyHeader');
            if (r.top - h <= 30 && r.bottom - h > 100) {
                target.style.display = 'flex';
            }
            else {
                target.style.display = 'none';
            }
        }
    }

    const featuresDataToShow = React.useMemo(() => {
        let temp = [];
        if (isMobile) {
            let t = featuresData?.reduce((a, c) => [...a, ...c.data], []);
            temp = { data: t }
        }
        else {
            temp = featuresData?.find((el) => el?.value === selectedFeatureType) || {};
        }
        return temp;
    }, [selectedFeatureType, featuresData])

    React.useEffect(() => {
        loadData();
        if (user?.isLogged) {
            moengage.track_event("Explored_plans", {
                "user_email": user?.email,
                "user_id": user?.id,
            })
        }
    }, [accessData])

    React.useEffect(() => {
        if (!isMobile) {
            window.addEventListener('scroll', handlePricingScroll);
        }
        return () => {
            window.removeEventListener('scroll', handlePricingScroll);
        }
    }, [])

    React.useEffect(() => {
        if (Object.keys(plansData)?.length > 0 && pricingContainerRef?.current && isMobile) {
            if (pricingContainerRef.current?.querySelector) {
                let child = pricingContainerRef.current.querySelector('div');
                let width = child.offsetWidth;
                pricingContainerRef.current.scrollLeft = (2 * width) + 12
            }
        }
    }, [plansData]);

    const PricingFeatureModule = ({ item, index }) => {

        const [isActive, setIsActive] = React.useState(isMobile ? false : true);

        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{}}>
                    {isMobile ? <div className='d-flex align-items-center justify-content-between' style={{
                        padding: "1.25rem 0", borderTop: "1px solid var(--grey2)",
                        cursor: "pointer"
                    }} onClick={() => {
                        setIsActive(prev => !prev)
                    }}>
                        <div className="textMD w-700 dark4" >{item?.Section?.split(' - ')?.[0]}</div>
                        <IoIosArrowDown style={{
                            transition: "all 0.3s linear",
                            transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
                            color: "var(--dark)",
                            fontSize: "1.5rem"
                        }} />
                    </div> : <div className='d-flex align-items-center justify-content-between' style={{
                        padding: "1rem", cursor: "pointer", backgroundColor: "var(--grey2)",
                        borderRadius: "var(--borderRadius)", alignSelf: "flex-start", borderBottom: '1px solid var(--grey1)'
                    }} onClick={() => {
                        setIsActive(prev => !prev)
                    }}>
                        <div>
                            <div style={{ fontSize: "var(--textLG)" }} className='w-500 dark'>
                                {item?.Section?.split(' - ')?.[0]}
                            </div>
                            <span className="textXS w-400" style={{ color: "var(--dark)" }}>{item?.Section?.split(' - ')?.[1]}</span>
                        </div>
                        <IoIosArrowDown style={{
                            transition: "all 0.3s linear",
                            transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
                            color: "var(--dark)",
                            fontSize: "0.875rem"
                        }} />
                    </div>}
                </div>
                <div style={{ maxHeight: isActive ? 20000 : 0, marginTop: isActive ? "0.75rem" : 0, overflow: "hidden" }}>
                    {item?.Data?.map((ele, j, arr) => {
                        let accessLimit = ele?.AceessDescription?.split('|')?.filter((el) => el).reverse()?.map((desc, m) => {
                            return { Description: desc, AccessLevel: 4 - m }
                        }) || [];
                        const renderLimit = (num) => {
                            if (ele.IsEnabled !== 1) return 'Coming soon!';
                            let el = accessLimit.find((jk) => jk.AccessLevel === num);
                            if (el) {
                                return el.Description;
                            }
                            else return <CheckIcon />
                        }
                        const isLast = j === arr.length - 1;
                        if (isMobile) {
                            return (
                                <div key={makeid(j + 1)}>
                                    <div className="d-flex align-items-center" style={{ gap: "0.5rem", marginTop: j === 0 ? "0.75rem" : "" }} >
                                        <div className="textSM dark4 w-500">{ele.ModuleName}</div>
                                        <Tooltip title={ele?.ModuleDesc} arrow={false}>
                                            <AiOutlineInfoCircle />
                                        </Tooltip>
                                    </div>
                                    <div style={{
                                        display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", alignItems: "center", textAlign: "center",
                                        borderBottom: isLast ? "none" : "1px solid var(--grey2)", marginBottom: "0.75rem", padding: "0.75rem 0", gap: "0.75rem"
                                    }} key={j} className='textXS dark4 w-500'>
                                        <div>{ele.AccessLevel <= 1 ? renderLimit(1) : <CrossIcon />}</div>
                                        <div>{ele.AccessLevel <= 2 ? renderLimit(2) : <CrossIcon />}</div>
                                        <div>{ele.AccessLevel <= 3 ? renderLimit(3) : <CrossIcon />}</div>
                                        <div>{ele.AccessLevel <= 4 ? renderLimit(4) : <CrossIcon />}</div>
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div style={{
                                display: "grid", gridTemplateColumns: "2.5fr 1fr 1fr 1fr 1fr", alignItems: "center", textAlign: "center",
                                '--borderWidthPricingFeat': isLast ? 0 : 1, minHeight: "6rem"
                            }} key={j} className='pricingFeature textXS dark4 w-500'>
                                <div style={{ gap: "0.25rem", textAlign: "left" }} className='d-flex flex-column'>
                                    <div className="textSM dark4 w-500">{ele.ModuleName}</div>
                                    <div className="textXS dark2 w-400">{ele?.ModuleDesc}</div>
                                </div>
                                <div>{ele.AccessLevel <= 1 ? renderLimit(1) : <CrossIcon />}</div>
                                <div>{ele.AccessLevel <= 2 ? renderLimit(2) : <CrossIcon />}</div>
                                <div>{ele.AccessLevel <= 3 ? renderLimit(3) : <CrossIcon />}</div>
                                <div>{ele.AccessLevel <= 4 ? renderLimit(4) : <CrossIcon />}</div>
                            </div>
                        )
                    })}
                </div>
            </div >
        )
    }

    return (
        <React.Fragment>
            <Head title='sharpely Pricing Plans | Choose Your Ideal Package' description="Explore sharpely's pricing options tailored to suit your financial needs and preferences." />
            <main className="marketing">
                <div style={{ display: "none", position: "fixed", top: getDivHeight("#sharpelyHeader"), zIndex: 10, width: "100vw", background: "var(--dark4)" }}
                    className={`pricingCtaCompare shadowXS`}>
                    <div className={`padLeft padRight ${isDev ? "maxWidthContainer" : "w100"}`}>
                        <div style={{ display: "grid", gridTemplateColumns: "2.5fr 1fr 1fr 1fr 1fr", width: "100%", alignItems: "center" }}>
                            <div>
                                <h2 className="headSMtoTextLG w-700 white margin0">
                                    Plans
                                </h2>
                            </div>
                            {plansData?.plans?.map((item, i, arr) => {
                                return (
                                    <div className='w100 h100 d-flex flex-column justify-content-between' style={{ padding: "1.25rem 0" }}>
                                        <div key={i + 1} className='w100 h100 d-flex flex-column justify-content-between' style={{
                                            padding: "0 1.25rem", borderRadius: isDesktop ? 0 : '0.75rem',
                                            borderRight: i !== arr?.length - 1 ? "1px solid var(--dark2)" : "none",
                                            position: "relative",
                                        }} >
                                            <div className='text-center textLG w-700 white' >
                                                {item.name}
                                            </div>
                                            <div style={{
                                                margin: "auto",
                                                width: "100%", marginTop: "1rem"
                                            }}>
                                                <button className='w100 textXS w-500 text-center black'
                                                    onClick={() => { handleSubmit(item) }}
                                                    style={{
                                                        border: "none", padding: "0.5rem 0.5rem", background: "var(--white)", borderRadius: "var(--borderRadius)", cursor: "pointer",
                                                    }}>
                                                    {isActivePlan(item) ? "Current plan" : item?.type === 'free' ? "Get Started" : userAccesslevel === 0 ? `Get ${plansData?.discount?.maxDiscount}% discount` :
                                                        (userAccesslevel <= 1 ? "Upgrade to " : "Switch to ") + item?.name}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <Card style={{ borderRadius: "0px", padding: "0px" }} bodyStyle={{ padding: "0rem" }} className={`${isDev ? "maxWidthContainer" : ""}`}>
                    <div className="padAll marHomeCardPadTop marHomeCardPadBot">
                        <div className="text-center">
                            <h1 className="headXLtoSM w-700 black margin0" >
                                sharpely Pricing
                            </h1>
                            <div className="textLGtoESM dark3" style={{ marginTop: "1rem" }}>
                                sharpely has tailored plans to meet your specific requirements.
                            </div>
                        </div>
                        <div className="text-center" style={{}}>
                            <div style={{
                                background: "var(--blueShade)", width: "fit-content", margin: "1rem auto 0", padding: "0.5rem 1rem",
                                borderRadius: "var(--borderRadius)"
                            }} className='textSM w-500'>
                                {plansData?.discountText}
                            </div>
                        </div>
                        <div className="" style={{
                            display: "grid", gridTemplateColumns: `repeat(4, minmax(${isMobile ? "90%" : "17rem"} , 1fr))`,
                            maxWidth: "100%", overflow: "auto", gap: "1.25rem", paddingTop: "2rem", paddingBottom: "1rem"
                        }} ref={pricingContainerRef}>
                            {plansData?.plans?.map((item, i) => {
                                const yearlyPrice = Math.ceil((item?.price?.new * 12) * ((100 - item?.discount?.onetime?.['1Y']) / 100));
                                const yearlySaved = item?.price?.new * 12 - yearlyPrice;
                                return (
                                    <div key={i + 1} className='w100 h100' style={{
                                        padding: "1.25rem", borderRadius: '0.75rem' || (i === 0 ? "0.75rem 0 0 0.75rem" : i === 3 ? "0 0.75rem 0.75rem 0" : ""),
                                        // borderTop: "1px solid var(--grey1)", borderBottom: "1px solid var(--grey1)", borderLeft: "1px solid var(--grey1)",
                                        // borderRight: (i === 3) ? "1px solid var(--grey1)" : "none",
                                        border: plansData?.popularPlan === item?.level ? "1.5px solid var(--primary)" : "1.5px solid var(--grey1)",
                                        position: "relative",
                                        boxShadow: plansData?.popularPlan === item?.level ? 'rgba(17, 17, 26, 0.1) 0px 0px 16px' : "",
                                    }}>
                                        {plansData?.popularPlan === item?.level && <div style={{
                                            position: "absolute", background: "var(--primary)", color: "var(--white)",
                                            padding: "2px 0.5rem", borderRadius: "var(--borderRadius)", right: "50%", top: "0%", transform: "translate(50%, -50%)"
                                        }} className="textXS w-700">
                                            Most popular
                                        </div>}
                                        <div className='text-center headSMtoTextLG w-700' style={{ marginTop: "1.25rem" }} >
                                            {item.name}
                                        </div>
                                        <div className="text-center textSMtoESM dark4" style={{
                                            margin: "1rem 0 0rem", minHeight: "100px",
                                            marginBottom: ""
                                        }} >
                                            {item.description}
                                        </div>
                                        <div style={{ minHeight: "8rem" }}>
                                            <div className='headSMtoTextLG text-center w-700' style={{
                                                marginBottom: "1rem", display: "flex", justifyContent: "center",
                                                gap: "0.25rem", alignItems: "flex-end"
                                            }}>
                                                {item.type === 'free' ? <div>Free forever</div> : <div>{CurrencyFormat(item?.price?.new, 'long', 0)}</div>}
                                                {item.type === 'paid' && <div className='textSM text-center w-700 dark2'>
                                                    <span style={{ textDecoration: "line-through" }}>{CurrencyFormat(item?.price?.old, 'long', 0)}</span>
                                                    <span className='textSM dark3 w-normal'>/ month</span>
                                                </div>}
                                            </div>
                                            {item.type === 'paid' && <div className='textSM dark3 w-normal text-center'>
                                                {CurrencyFormat(yearlyPrice, 'long', 0)} / year
                                            </div>}
                                            {item.type === 'paid' && <div style={{
                                                background: "var(--blueShade)", width: "fit-content",
                                                padding: "0.5rem 1rem", borderRadius: "var(--borderRadius)", margin: "0.75rem auto 0rem"
                                            }} className='textSM w-500'>
                                                You save {CurrencyFormat(yearlySaved, 'long', 0)} a year.
                                            </div>}
                                        </div>
                                        <div style={{
                                            minHeight: "6rem", margin: "auto",
                                            width: "100%", marginTop: "1rem"
                                        }}>
                                            <button className='btnBlue w100 btnMD textMDtoSM text-center white' onClick={() => { handleSubmit(item) }} style={{
                                                background: "var(--dark4)",
                                                border: 'none'
                                            }}>
                                                {isActivePlan(item) ? "Current plan" : item?.type === 'free' ? "Get Started" :
                                                    (userAccesslevel <= 1 ? "Upgrade to " : "Switch to ") + item?.name}
                                            </button>
                                            <div className='text-center mt-1 textESM w-500'>
                                                {(item.type === 'paid') && <>
                                                    <div className="d-flex align-items-center w100" style={{ gap: "1rem", margin: "0.5rem auto" }}>
                                                        <div className="flex-grow-1" style={{ borderTop: "1px solid var(--grey1)" }}></div>
                                                        <span className='textXS w-500'>Or</span>
                                                        <div className="flex-grow-1" style={{ borderTop: "1px solid var(--grey1)" }}></div>
                                                    </div>
                                                    <div className='mt-2 textSM' style={{ color: "var(--primary)", cursor: "pointer" }}
                                                        onClick={() => { handleSubmit(item, true) }}>
                                                        Get upto {plansData?.discount?.maxDiscount}% off on long-term plans.
                                                    </div>
                                                </>
                                                }
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column' style={{ gap: "0.75rem", marginTop: "1.25rem" }}>
                                            {item?.features?.map((ele) => {
                                                return (
                                                    <div className='d-flex align-items-center justify-content-start' style={{ gap: "0.5rem" }}>
                                                        <BsCheck2 color='var(--dark4)' />
                                                        <div className='dark4 w-500 textXS'>{ele}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Card>
                <Card style={{ borderRadius: "0px", padding: "0px" }} bodyStyle={{
                    padding: "0rem",
                    background: isMobile ? "" : "linear-gradient(172.45deg, rgba(229, 246, 255, 0.54913) 5.85%, #FFFFFF 20.65%)"
                }}>
                    <div className={`padAll ${isDev ? "maxWidthContainer" : ""}`}>
                        {!isMobile && <div className="d-flex justify-content-end" style={{ marginBottom: "2rem" }} >
                            <div style={{ width: "fit-content" }}>
                                <Segmented options={featuresData} block={false} value={selectedFeatureType} onChange={(e) => {
                                    setSelectedFeatureType(e)
                                }} className="mobileTabs2" style={{ padding: "0rem", background: "transparent" }} />
                            </div>
                        </div>}
                        <div style={{ display: "grid", gridTemplateColumns: "2.5fr 1fr 1fr 1fr 1fr", alignItems: "center" }} className='pricingCompareCollapseHead'>
                            <div className="d-flex flex-column justify-content-center h100">
                                <h2 className="headMDtoXS w-700 black margin0">
                                    Compare plans
                                </h2>
                            </div>
                            {!isMobile && plansData?.plans?.map((item, i) => (
                                <div key={i + 1} style={{ display: "flex", alignItems: "center", padding: "1rem 0", justifyContent: "center", height: "fit-content" }}>
                                    <div className='d-flex align-items-center flex-column justify-content-center'
                                        style={{ gap: "0.3rem" }}>
                                        <div style={{ fontSize: "var(--textLG)" }} className='black w-700'>{item.name}</div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div className="d-flex flex-column compareFeaturesSection" style={{
                                gap: isMobile ? "0" : "0.75rem", border: isMobile ? "1px solid var(--grey2)" : "none",
                                padding: isMobile ? "1rem" : "", borderRadius: isMobile ? "0.5rem" : ""
                            }}>
                                {isMobile && <div style={{
                                    display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", width: "100%", alignItems: "center", gap: "0.75rem",
                                    position: "sticky", top: getDivHeight('#sharpelyHeader'), background: "var(--white)", zIndex: 20, padding: "0.75rem 0",
                                    borderBottom: "1px solid var(--grey2)"
                                }}>
                                    {plansData?.plans?.map((item, i, arr) => {
                                        return (
                                            <div className='w100 d-flex flex-column justify-content-between' style={{ padding: "0rem 0" }}>
                                                <div key={i + 1} className='w100 d-flex flex-column justify-content-between' style={{
                                                    padding: "0rem",
                                                    position: "relative",
                                                }} >
                                                    <div className='text-center textSM w-700 black' >
                                                        {item.name}
                                                    </div>
                                                    <div style={{
                                                        marginTop: "0.75rem"
                                                    }}>
                                                        <div className='w100 w-500 text-center white'
                                                            onClick={() => { handleSubmit(item) }}
                                                            style={{
                                                                border: "none", padding: "0.5rem 0.25rem", background: "var(--dark4)", borderRadius: "var(--borderRadius)", cursor: "pointer",
                                                                fontSize: "10px"
                                                            }}>
                                                            {isActivePlan(item) ? "Current plan" : item?.type === 'free' ? "Get Started" :
                                                                (userAccesslevel <= 1 ? "Upgrade to " : "Switch to ") + item?.name}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>}
                                {featuresDataToShow?.data?.map((item, i) => {
                                    return <PricingFeatureModule item={item} key={makeid(i + 1)} index={i} />
                                })}
                            </div>
                        </div>
                    </div>
                </Card>
                <Card style={{ borderRadius: "0px", padding: "0px" }} bodyStyle={{ padding: "0rem" }} className={`${isDev ? "maxWidthContainer" : ""}`}>
                    <div className="padLeft padRight" style={{ background: "var(--white)" }} >
                        <div className='d-flex justify-content-between flex-column flex-sm-row' style={{ gap: "2.5rem" }} >
                            <div className="marHomeResMainCard">
                                <h2 className='headLGtoXS black w-700 margin0' style={{ width: "11ch" }}>Frequently asked questions</h2>
                                <div className="textMD dark3" style={{ marginTop: "0.75rem" }}>
                                    Still have question? <span style={{ color: "var(--primary)", cursor: "pointer" }} onClick={() => {
                                        dispatch(toggleHelpAndSupp(true))
                                    }}>Contact us</span>
                                </div>
                            </div>
                            <div className="flexCard">
                                <Collapse style={{ width: "100%", background: "transparent" }} bordered={false}
                                    expandIcon={({ isActive }) => isActive ? <BiMinus size='0.875rem' color='var(--primary)' /> : <BiPlus size='0.875rem' color='var(--primary)' />}
                                    expandIconPosition='start' items={faqs?.slice(0, 3)?.map((ele, i) => {
                                        return {
                                            key: i,
                                            label: <span className="textMDtoESM w-500">{ele.title}</span>,
                                            children: <span className="textSMtoESM dark3 w-500">{ele.content}</span>,
                                            style: {
                                                background: "var(--white)",
                                                borderBottom: "1px solid var(--grey3)",
                                                padding: '0.75rem',
                                                borderRadius: "var(--borderRadius)",
                                                borderTop: "none"
                                            }
                                        }
                                    })} />
                                <div className='d-flex  align-items-center textMDtoSM w-500' style={{ gap: "0.25rem", color: "var(--primary)", cursor: "pointer" }}
                                    onClick={() => setFaqModal(true)}>
                                    See all FAQs  <HiOutlineArrowSmRight />
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                <Card style={{ borderRadius: "0px", padding: "0px" }} bodyStyle={{ padding: "0rem" }} className={`${isDev ? "maxWidthContainer" : ""}`} >
                    <div className='padAll marBottomBanner marHomeCardPadTop marHomeCardPadBot'>
                        <div style={{
                            background: "linear-gradient(131.73deg, #016DD7 -21.82%, #7553FF 90.86%)", textAlign: "center", gap: "0.75rem",
                            padding: "3rem 1rem 4rem", borderRadius: "var(--borderRadius)", position: "relative"
                        }}
                            className='d-flex flex-column align-items-center'>
                            <div className="">
                                <h2 className='w-700 white headLGtoTextLG margin0' style={{ width: "25ch" }}>
                                    Start making informed investing decision today
                                </h2>
                                <div className='textLGtoESM w-400 white ' style={{ marginTop: "0.75rem" }}>
                                    Get started today to boost your investments.
                                </div>
                            </div>
                            <div className='d-flex align-items-center' style={{ gap: "0.5rem", cursor: "pointer" }} onClick={() => {
                                navigate(user?.isLogged ? "/stocks/dashboard" : "/login")
                            }}>
                                <div className='textLG w-700 white'>{user?.isLogged ? <>Explore Stocks</> : <>Start now for FREE</>}</div>
                                <HiArrowUpRight className='textLG w-700 white' />
                            </div>
                            <div className='d-flex align-items-center flex-column flex-sm-row' style={{ gap: "1rem", marginTop: "1.25rem" }}>
                                <>
                                    {user?.isLogged && <Link to={user?.isLogged ? "/etfs/dashboard" : '/login'} style={{ flexShrink: 0 }}>
                                        <div className='w-500' style={{
                                            color: "var(--primary)", padding: "10px 19px", background: "var(--white)",
                                            border: "1px solid var(--white)", borderRadius: "var(--borderRadius)", fontSize: "var(--textSM)"
                                        }}>
                                            {user?.isLogged ? <> Explore ETFs</> : <> Sign Up With Email</>}
                                        </div>
                                    </Link>}
                                    <Link to={user?.isLogged ? "/mutual-funds/dashboard" : '/login'} style={{ width: "100%" }}>
                                        <div className='w-500 d-flex align-items-center' style={{
                                            color: "var(--primary)", padding: "10px 19px", background: "var(--white)",
                                            border: "1px solid var(--white)", borderRadius: "var(--borderRadius)", fontSize: "var(--textSM)"
                                        }}>
                                            {user?.isLogged ? <> Explore MFs</> : <>
                                                <FcGoogle className='' style={{ marginRight: "0.5rem", fontSize: "var(--textSM)" }} />
                                                <div>Sign Up With Google</div>
                                            </>}
                                        </div>
                                    </Link>
                                </>
                            </div>
                            <img loading="lazy" alt="" src={marketingSignup1} style={{
                                position: "absolute", top: "3rem", left: "0px", width: "12rem"
                            }} />
                            <img loading="lazy" alt="" src={marketingSignup2} style={{
                                position: "absolute", bottom: "0rem", right: "0px", width: "12rem"
                            }} />
                        </div>
                    </div>
                </Card>
                <UpdatePhoneModal open={updatePhoneModal} toggle={() => closeModal(false)} />
                <Modal open={faqModal} footer={null} onCancel={() => setFaqModal(false)} onOk={() => setFaqModal(false)} closable={true} width="min(100%, 40rem)" >
                    <div style={{ maxHeight: "70vh", overflow: "auto" }} className='scrollbox'>
                        <Collapse style={{ width: "100%", background: "transparent" }} bordered={false}
                            expandIcon={({ isActive }) => isActive ? <BiMinus size='0.875rem' color='var(--primary)' /> : <BiPlus size='0.875rem' color='var(--primary)' />}
                            expandIconPosition='start' items={faqs?.map((ele, i) => {
                                return {
                                    key: i,
                                    label: <span className="textMDtoESM w-500">{ele.title}</span>,
                                    children: <span className="textSMtoESM dark3 w-500">{ele.content}</span>,
                                    style: {
                                        background: "transparent",
                                        borderBottom: "1px solid var(--grey3)",
                                        padding: '0.5rem 0rem',
                                        borderRadius: "var(--borderRadius)",
                                        borderTop: "none"
                                    }
                                }
                            })} />
                    </div>
                </Modal>
            </main>
            <PaySharpelyPricingFee />
        </React.Fragment >
    )
}

export default Pricing;