import AccessComponent from "Components/AccessComponent";
import AccessControlledPage from "Components/AccessControlledPage";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import { formatDateMMMDDYYYY, formatDateWithTime, formatDateyyyymmdd } from 'Components/DateFormatter';
import InstrumentListTable from "Components/InstrumentListTable";
import LoadingTableSkeleton from "Components/LoadingTableSkeleton";
import { NoDataFound } from "Components/NoDataFound";
import { PlanName, SubscribeBtnSmall, SubscribeContentWrapper, getUserAccessLimitFromAccessObj, useSubscribeMiddleware } from "Components/SubscribeModal";
import SuperScreenerBacktestAndPaperTrade from "Components/SuperScreenerBacktestAndPaperTrade";
import { CloneItemModal } from 'Components/atoms/CloneItemModal';
import { ConfirmModal } from "Components/atoms/ConfirmModal";
import { CustomChangingTextLoader } from "Components/atoms/CustomChangingTextLoader";
import { DescriptionInput } from 'Components/atoms/DescriptionInput';
import LabelInputNew from 'Components/atoms/LabelInputNew';
import { NameInput } from 'Components/atoms/NameInput';
import { CardHeader } from "Components/atoms/SmallComponents";
import { useUserDataHooks } from "CustomHooks/useUserDataHooks";
import { getDivHeight } from "Libs/scroll";
import { displayValue, exchanges, generateUniqueKey, isInvalidTextOrLength, makeid, sortArrObject } from 'Libs/utils';
import AdminServicesPY from "Services/api/AdminServicesPY";
import apiHelper from "Services/api/ApiHelper";
import apiHelperPY from "Services/api/ApiHelperPY";
import { Alert, Avatar, Button, Card, Collapse, Divider, Drawer, Dropdown, Input, Modal, Popconfirm, Segmented, Select, Space, Switch, notification } from "antd";
import React, { useState } from "react";
import { toast } from 'react-hot-toast';
import { BiCheck, BiCode } from "react-icons/bi";
import { BsBan } from "react-icons/bs";
import { CgDatabase } from "react-icons/cg";
import { FaPlay } from "react-icons/fa";
import { FiPlusCircle } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { IoPlayBackOutline } from "react-icons/io5";
import { MdAddCircle, MdDelete, MdEdit, MdOutlineWorkspacePremium } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { QueryOptionsDropdownV2 } from "./QueryOptionsDropdown";
import { QueryViewer } from "./QueryViewer";
import { QueryViewerBasic } from "./QueryViewerBasic";
import { ScreenBacktestInpModal } from './ScreenBacktestInpModal';
import { codeParserWrapper, directionTypes, finalCode, groupColumnNames, initBacktestConfig, maTypes, multipleValueComp, sortBy, timeframes } from "./ScreenerUtils";
import { VariableBuilder } from './VariableBuilder';

const SaveScreenModal = React.memo(({ screenId, screenName, screenDesc, alertsEnabled, screenSaveModal, setScreenSaveModal, saveScreener, userTotalScreens, isUpdating = false, subscribeMiddle }) => {

    const [name, setName] = React.useState(screenName);
    const [desc, setDesc] = React.useState(screenDesc);
    const [alerts, setAlerts] = React.useState(alertsEnabled);
    const isMobile = window.innerWidth < 760;

    React.useEffect(() => {
        setName(screenName);
    }, [screenName]);

    React.useEffect(() => {
        setDesc(screenDesc);
    }, [screenDesc]);

    React.useEffect(() => {
        setAlerts(alertsEnabled);
    }, [alertsEnabled]);

    let saveScreenAccess = subscribeMiddle({ moduleKey: "SAVE_SCREEN_AND_SET_ALERTS", getDetailedAccessData: true, checkLimit: true, usageCount: userTotalScreens });

    return (
        <Modal
            title={<div className="d-flex align-items-center justify-content-between" style={{ gap: "1rem" }}>
                <div className='textLG w-700' >{screenId > 0 ? "Update Screen" : "Save screen"}</div>
                {screenId <= 0 && saveScreenAccess?.maxLimit !== Infinity && <div>
                    <div style={{ userSelect: "none" }}>
                        <span className="textXS dark3 w-500">Credits: <span className='dark4 w-700'>
                            <b style={{ color: saveScreenAccess?.remainingLimit === 0 ? "red" : "var(--primary)" }}>{saveScreenAccess?.remainingLimit}</b>
                            <b>/{saveScreenAccess?.maxLimit}</b></span>.&nbsp;
                            {saveScreenAccess?.currentAccessLevel !== 4 && <span>Upgrade to <Link className="dark4 w-700" to='/pricing' target="_blank">
                                <PlanName accessLevel={saveScreenAccess?.currentAccessLevel + 1} /></Link>.</span>}
                        </span>
                    </div>
                </div>}
            </div>}
            centered
            open={screenSaveModal}
            closable={false}
            footer={false}
            width={isMobile ? "90%" : "32vw"}
            zIndex={1000}
            onCancel={() => setScreenSaveModal(false)}
        >

            <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem", marginTop: "1rem" }}>
                <div style={{ display: "flex", gap: "2rem" }}>
                    <div style={{ display: "flex", gap: "0.25rem", flex: 1 }}>
                        <span className='d-flex gap-3 align-items-center'><span className='textXS w-500 dark3'>Alert</span><Switch checked={alerts} onChange={(e) => {
                            setAlerts(!alerts)
                        }} /></span>
                    </div>
                </div>

                <NameInput label='Screen Name' placeholder='Enter your screen name' value={name} onChange={(e) => {
                    setName(e);
                }} changeValueOnChange />

                <DescriptionInput label={<span>
                    Description
                </span>} placeholder='Enter your screen description' value={desc} onChange={(e) => {
                    setDesc(e);
                }} />

                <span className='d-flex flex-row gap-2' style={{ justifyContent: "flex-end" }}>
                    <button className="btnWhite" onClick={() => setScreenSaveModal(!screenSaveModal)}>Cancel</button>
                    <SubscribeContentWrapper moduleKey="SAVE_SCREEN_AND_SET_ALERTS" checkLimit usageCount={userTotalScreens} preventDefault={screenId > 0} disabled={(screenId > 0 ? false : saveScreenAccess?.remainingLimit === 0) || isInvalidTextOrLength({ text: name, checkLength: true }) || desc?.length < 15}
                        onClick={() => {
                            setTimeout(() => {
                                saveScreener({ updName: name, updDesc: desc, updAlerts: alerts })
                            }, 500);
                        }}>
                        <button className="btnBlack" disabled={isInvalidTextOrLength({ text: name, checkLength: true }) || desc?.length < 15 || (screenId > 0 ? false : saveScreenAccess?.remainingLimit === 0)}>Save</button>
                    </SubscribeContentWrapper>
                </span>
            </div>
        </Modal >
    )
})

export const ScreenQueryPanel = ({ allFuncAndMetricForDrawer = [], allFundsData = [], type, functionMap, functions, screenerMetrics = [], indices = [], benchmarks = [],
    strategy_fundD = undefined, typeD = undefined, updateStrategyParams = () => { }, is_Advance = false, clonedData = {}, triggerBacktest = false, readOnly = false, isLanding = false }) => {

    const user = useSelector((state) => state?.updateUserData?.value)
    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    var cloned_screen_data = clonedData?.screenData || null
    var cloned_screen_name = clonedData?.screenName || null
    var cloned_screen_desc = clonedData?.desc || null
    var fromStrategyCreation = clonedData?.fromStrategyCreation || false
    const userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel || 0;

    let userAccess = getUserAccessLimitFromAccessObj({ accessObj: accessLevelData?.find((ele) => ele?.ModuleKey?.trim() === 'CREDITS_PER_STRATEGY_IN_PAPERTRADE'), accessLevel: userAccesslevel });

    var initial_capital = userAccess || 100000


    // alert(initial_capital + " " + userAccess)
    const [defCode, setDefCode] = React.useState("")

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, title, desc, btn = null) => {
        const key = `open${Date.now()}`;
        // const btn = (
        //     <Space>
        //         <Button type="primary" size="small" onClick={() => api.destroy(key)}>
        //             Confirm
        //         </Button>
        //     </Space>
        // );
        api[type]({
            message: title,
            description: desc,
            placement: "topRight",
            key,
            btn
        });
    };


    const navigate = useNavigate();
    var logg = "scr-new =>> "
    // ////////console.log(functions);
    var sample_placeholder = "Start typing"
    const [isAdvance, setisAdvance] = React.useState(is_Advance);
    const [screener_type, setscreener_type] = React.useState(is_Advance ? "Advance" : "Basic");
    // const user = useSelector((state) => state?.updateUserData?.value)
    const [allPublishers, setAllPublishers] = React.useState([])
    ////console.log(allPublishers)

    const userAccessLevel = user?.Client?.[0]?.SharpelyAccessLevel || 0
    const globalVars = ['universe', 'exchange', 'exclusion_list']

    let last3Year1StDate = new Date();
    last3Year1StDate.setFullYear(last3Year1StDate.getFullYear() - 3);

    const [start_date, setstart_date] = useState(formatDateyyyymmdd(last3Year1StDate))
    const [end_date, setend_date] = useState(formatDateyyyymmdd(new Date()))

    const [start_date2, setstart_date2] = useState(formatDateyyyymmdd(last3Year1StDate))
    const [end_date2, setend_date2] = useState(formatDateyyyymmdd(new Date()))
    const [regenerateOrder, setregenerateOrder] = useState(false)

    const [allMetricDrawer, setAllMetricDrawer] = useState(false)
    const [editScreenerView, setEditScreenerView] = useState(false);
    // screenerMetrics = [...screenerMetrics]?.concat(priceItems?.map((i) => {
    //     return {
    //         ...i,
    //         "dataIndex": i?.value,
    //         "key": i?.value,
    //         "metric_col_code": i?.value,
    //         "metric_unit": "",
    //         "metric_data_type": "float",
    //         "metric_name": i?.label,
    //         "title": i?.label,
    //         "type": "Metric",
    //         "frequency": "D"
    //     }
    // }))

    // var technicalMetrics = [...screenerMetrics]?.filter((i) => i?.is_technical === 1 || priceItems?.map((i) => i?.value)?.includes(i?.metric_col_code))?.map((i) => {
    //     return {
    //         ...i,
    //         type: "Metric T"
    //     }
    // })
    // var fundamentalsMetrics = [...screenerMetrics]?.filter((i) => i?.is_technical !== 1 && !(priceItems?.map((i) => i?.value)?.includes(i?.metric_col_code)))?.map((i) => {
    //     return {
    //         ...i,
    //         type: "Metric F"
    //     }
    // })

    // screenerMetrics = [...technicalMetrics, ...fundamentalsMetrics].sort((a, b) => a.label.localeCompare(b.label))
    // ////console.log(screenerMetrics)

    const multipleTypeMetrics = ["sector_code", "industry_code", "nse_basic_ind_code", "style_box_code"];

    const conditionBlockWrappers = [
        {
            "key": "is_true_now",
            "value": "is_true_now",
            "label": "Condition is true now",
        }, {
            "key": "is_true_any_nbars",
            "value": "is_true_any_nbars",
            "label": "Condition was true anytime in last N candles",
        }, {
            "key": "is_true_all_nbars",
            "value": "is_true_all_nbars",
            "label": "Condition was true in all last N candles",
        }, {
            "key": "was_true_nbars_ago",
            "value": "was_true_nbars_ago",
            "label": "Condition was true N candles ago",
        }
    ]

    const sortOptions = [
        {
            value: 'and',
            queryStr: 'and',
            label: 'AND',
            // className: 'segmentedAnd'
        },
        {
            value: 'or',
            queryStr: 'or',
            label: 'OR',
            // className: 'segmentedOr'
        }
    ]
    // const type = useGetAssetType();
    const initialGlobals = {
        universe: {
            name: "universe",
            isGlobal: true,
            expression: "all",
            expression_arr: "all"
        },
        exchange: {
            name: "exchange",
            isGlobal: true,
            expression: "All",
            expression_arr: "All"
        }
    }
    const [globals, setGlobals] = React.useState(initialGlobals);
    const [useSortAndLimit, setuseSortAndLimit] = React.useState(false);
    const [timeframe, setTimeframe] = React.useState('1d');
    const [screenId, setScreenId] = React.useState(0)
    const [screenData, setScreenData] = React.useState({})
    const [screenName, setScreenName] = React.useState('');
    const [screenDesc, setScreenDesc] = React.useState('');
    const [alertsEnabled, setAlertsEnabled] = React.useState(false);
    const [isPublished, setIsPublished] = useState(false)
    const [screenSaveModal, setScreenSaveModal] = React.useState(false);
    const [conditions, setConditions] = React.useState(isAdvance ? {
        "0_condition": {
            queries: {}
        }
    } : {})
    const [conditions_oper, setConditionsOper] = React.useState([])
    const [variables, setVariables] = React.useState({})
    const [editModal, setEditModal] = React.useState(false);
    const [lastFocusedBlock, setlastFocusedBlock] = React.useState("0_condition");
    const [isCondBlockActive, setIsCondBlockActive] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState({});
    const elementToFocus = React.useRef();
    const [focusElement, setFocusElement] = React.useState('');
    const [variableModal, setVariableModal] = React.useState(false);
    const [selectedVariable, setSelectedVariable] = React.useState({});
    const [tableLoading, setTableLoading] = React.useState(false);
    const [screenedOnce, setScreenedOnce] = React.useState(false);
    const [replayModal, setReplayModal] = React.useState(false);
    const [replayInput, setReplayInput] = React.useState(0);
    const [stocksSortAndLimit, setStocksSortAndLimit] = React.useState({ top: null, by: null, sort: null });
    const [excludeSurveillance, setexcludeSurveillance] = React.useState(false);
    const [backtestInpModal, setBacktestInpModal] = React.useState(false);
    const [backtestConfig, setBacktestConfig] = React.useState({ ...initBacktestConfig, 'initial_capital': initial_capital });
    const [updatedConfig, setupdatedConfig] = React.useState({ ...initBacktestConfig, 'initial_capital': initial_capital });
    const [backtestCode, setbacktestCode] = React.useState('');
    const [lastEditingIndex, setLastEditingIndex] = React.useState(undefined);
    const [backTestModalLoading, setbackTestModalLoading] = React.useState(true)
    const [backTestData, setBackTestData] = React.useState({})
    const [backTestPerfData, setbackTestPerfData] = React.useState([]);

    const [allPublishedScreens, setallPublishedScreens] = React.useState([]);
    const [userTotalScreens, setUserTotalScreens] = React.useState(0);
    const [userClonedTotalScreens, setUserClonedTotalScreens] = React.useState(0);
    const [isLocked, setIsLocked] = React.useState(false);
    const [canPublish, setCanPublish] = React.useState(false);
    const [viewType, setviewType] = React.useState('backtest'); // backtest or papertrade
    const [viewTypePendingNewRun, setviewTypePendingNewRun] = React.useState(2); // 2 for both, 1 for backtest, 0 latest
    const [isCloned, setIsCloned] = React.useState(false);

    const [loading, setLoading] = React.useState(true);
    const isMobile = window.innerWidth < 760;
    // const params = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const param_screenId = Number(searchParams.get('screenId'));
    const param_screen_name = searchParams.get('screenName');
    const param_screenUserId = Number(searchParams.get('userId')) || user?.id;

    let screenPublisherProfile = allPublishers?.filter((i) => {
        if (Object.keys(screenData || {})?.length > 0) {
            if (screenData?.is_published === 1) {
                return screenData?.client_id === i?.ClientId;
            }
        }
        return false
    })?.[0] || {};
    let userPublisherProfile = allPublishers?.filter((i) => i?.ClientId === user?.id)?.[0] || {}

    const location = useLocation();
    const { getUserSuperScreens, getUserCloneItems } = useUserDataHooks();
    const subscribeMiddle = useSubscribeMiddleware();

    var col = 0

    const [backTestModal, setbackTestModal] = React.useState(false)

    const getUserScreenCount = async () => {
        let screenRes = await getUserSuperScreens();
        if (screenRes) {
            setUserTotalScreens(screenRes?.userScreens?.length || 0);
            let checkIfLocked = screenRes?.userScreens?.find((el) => el?.screen_id === Number(param_screenId))?.is_locked === 1;
            if (checkIfLocked) {
                setIsLocked(true);
            }
            else {
                setIsLocked(false)
            }
        }
        else {
            setUserTotalScreens(0);
            setIsLocked(false);
        }
        let cloneRes = await getUserCloneItems();
        if (cloneRes) {
            setUserClonedTotalScreens(cloneRes?.screens?.clonedScreensInMonth?.length || 0);
        }
        else {
            setUserClonedTotalScreens(0);
        }
    }

    const getPublisherProfiles = async () => {
        let allPublishers = await apiHelperPY({ apiName: "getAllPublisherProfile", saveResponse: "sessionStorage" });
        allPublishers = JSON.parse(allPublishers);
        setAllPublishers(allPublishers);
    }

    React.useEffect(() => {
        getPublisherProfiles();
    }, []);

    React.useEffect(() => {
        getUserScreenCount();
    }, [user, searchParams]);

    React.useEffect(() => {
        if (elementToFocus.current) {
            elementToFocus.current.focus();
        }
    }, [focusElement])

    let screenDetailedAccess = subscribeMiddle({ moduleKey: "SAVE_SCREEN_AND_SET_ALERTS", getDetailedAccessData: true, checkLimit: true, usageCount: userTotalScreens });

    const expUIParser = (exp, queryData, metric) => {

        const isMetric = (str) => {
            var ser = screenerMetrics?.filter((i) => i?.value === str)?.[0]
            if (ser) {
                return ser?.label
            }
            return str
        }
        var item = queryData?.expression_details
        var expN = String(exp)?.match(/.*?\(/)?.[0]
        //console.log(logg, exp, expN, queryData, Object.values(variables)?.map((i) => i?.name)?.includes(metric))
        var colorCode = metric?.includes("_number") ? "var(--green)" : metric?.includes("_compOperator") ? "var(--gold)" : "var(--white)"
        // var colorCode = "var(--black)"
        if (String(exp)?.match(/by|matype|direction\s{0,3}=\s{0,2}/)) {

            if (String(exp)?.match(/by\s{0,3}=\s{0,2}/)) {
                var showPeriod = queryData?.expression_arr?.map(element => {
                    return [`"MKT"`, `"SEC"`, `"INDGRP"`, `"IND"`].concat(indices?.map((item) => `"${item?.IndexNameCaps}"`)).filter(str => element.includes(str))?.length > 0
                });
                showPeriod = showPeriod?.filter((i) => i === false)?.length === queryData?.expression_arr?.length
                queryData = {
                    ...queryData,
                    expression_arr: !showPeriod ? queryData?.expression_arr?.filter(element => {
                        return !element.match(/period\s{0,3}=/)
                    }) : queryData?.expression_arr
                }
            }

            var newArr = queryData?.expression_arr?.map((str) => {
                str = isMetric(str)
                if (String(str)?.match(/by|matype|direction\s{0,3}=\s{0,2}/)) {
                    if (String(str)?.match(/\s{0,3}=\s{0,3}/g)) {
                        var arr = String(str)?.split(/\s{0,3}=\s{0,3}/g)
                        var keyName = arr?.[0]
                        var keyValue = item?.type === "<class 'int'>" ? parseInt(Number(arr?.[1])) : item?.type === "<class 'float'>" ? parseFloat(Number(arr?.[1])) : arr?.[1];

                        if (String(str)?.match(/by\s{0,3}=\s{0,2}/)) {
                            var keyValue2 = groupColumnNames?.filter((i) => {
                                return i?.value === keyValue
                            })?.[0]?.label
                            if (keyValue2) {
                                return keyName + "=" + keyValue2
                            } else {
                                return keyName + "=" + ((keyValue?.endsWith("F\"") || keyValue?.endsWith("Y\"")) ? "Years" : keyValue?.endsWith("Q\"") ? "Quarters" : (keyValue?.endsWith("M\"") ? "Months" : "Days"))
                            }
                        } else if (String(str)?.match(/matype\s{0,3}=\s{0,2}/)) {
                            var keyValue2 = maTypes?.filter((i) => {
                                return i?.value === Number(keyValue)
                            })?.[0]?.label
                            return keyName + "=" + keyValue2
                        } else if (String(str)?.match(/direction\s{0,3}=\s{0,2}/)) {
                            var keyValue2 = directionTypes?.filter((i) => {
                                return i?.value === Number(keyValue)
                            })?.[0]?.label
                            return keyName + "=" + keyValue2
                        }
                    } else {
                        return str
                    }
                } else {
                    return str
                }
            })
            return <span className="w-bold" style={{ color: colorCode, display: "inline" }}>{String(queryData?.expression_details?.key)?.toUpperCase()}<span style={{ color: "#e0e0e0", display: "inline" }} className="w-400">{" ("}{newArr?.join(", ")}{")"}</span></span>
        }
        if (exp === "and" || exp === "or")
            return <span className="w-bold" style={{ color: "rgb(197, 134, 192)", display: "inline" }}>{String(exp)?.toUpperCase()}</span>
        if (queryData?.type !== "metric" && expN) {
            return <span className="w-bold" style={{ color: colorCode, display: "inline" }}>{String(expN)?.toUpperCase()?.replace("(", "")}<span className="w-400" style={{ color: "#e0e0e0", display: "inline" }}>{("(" + exp)?.replace(expN, "")}</span></span>
        }
        if (queryData?.type === 'metric' && queryData?.isMultiple === true && multipleTypeMetrics?.includes(item?.metric_col_code)) {
            let itemStr = queryData?.expression_arr?.map((el) => item?.dataToRenderFrom?.find((ele) => ele?.value === el)?.label)?.join(', ')
            return (
                <>
                    <span className="w-bold" style={{ color: colorCode, display: "inline", margin: "0 0.5rem 0.5rem 0" }}>{String(exp)}</span>
                    <span className="w-bold" style={{ color: 'var(--gold)', margin: "0 0.5rem 0.5rem 0", display: "inline" }}>{String(multipleValueComp?.find((el) => el?.value === queryData?.comparator)?.label)}</span>
                    <span className="" style={{ color: 'var(--green)', display: "inline", margin: "0 0.5rem 0.5rem 0" }}>[{itemStr}]</span>
                </>
            )
        }
        if (queryData?.isCustomComparator === true) {
            let itemStr = queryData?.expression_arr?.map((el) => isMetric(el))?.join(', ')
            return (
                <>
                    <span className="w-bold" style={{ color: colorCode, display: "inline", margin: "0 0.5rem 0.5rem 0", }}>{String(exp)}</span>
                    <span style={{ color: colorCode }}>[ <span className="" style={{ color: 'var(--green)', display: "inline" }}>{itemStr}</span> ]</span>
                </>
            )
        }
        return <span className="w-bold" style={{ color: colorCode, display: "inline" }}>{String(exp)}</span>
    }

    const [openBasicDrawer2, setopenBasicDrawer2] = React.useState(false)

    console.log(conditions);

    const [code, setCode] = React.useState("")

    const [screenedStocksData, setScreenedStocksData] = React.useState({})
    const [screenedCols, setScreenedCols] = React.useState([])

    const getScreenedStocks = async (isReplay = false, oriCode, sortAndLimit = null) => {
        try {
            setTableLoading(true);
            var sortAndLimitVal = useSortAndLimit ? sortAndLimit || stocksSortAndLimit : {}
            let output = oriCode || finalCode({
                globals: globals,
                variables: variables,
                conditions: conditions,
                conditions_oper: conditions_oper,
                indices: indices,
                sortAndLimit: sortAndLimitVal
            })


            if (output?.length > 0) {
                let res = await AdminServicesPY.getAllScreenedStocksAdvV2({
                    code: JSON.stringify(Object?.values(sortAndLimitVal)?.filter((i) => i)?.length === 3 ? {
                        code: output,
                        'position_sizing': {
                            limit: { by: sortAndLimitVal?.by, sort: sortAndLimitVal?.sort, top: sortAndLimitVal?.top }
                        }
                    } : {
                        code: output,
                    }),
                    timeframe: timeframe,
                    barsAgo: isReplay ? Number(replayInput) : null
                }).then(r => r);
                if (type) {
                    res = JSON.parse(res?.data?.replaceAll(/NaN/g, null))
                    ////////console.log(res)
                    let funds = (res?.instruments || []).map((i) => {
                        return {
                            ...[...allFundsData].filter((ii) => ii?.symbol === i?.symbol)[0],
                            ...i,
                        }
                    })
                    if (!screenedOnce) {
                        setScreenedOnce(true);
                    }
                    setScreenedStocksData({ ...res, instruments: funds })
                    if (res?.instruments?.length > 0) {
                        let cols = Object?.keys(res?.instruments?.[0] || {})?.filter((i) => (!(['symbol', 'mcap']?.includes(i) || i?.match(/^c[0-9]{0,3}[0-9]$/))))?.map((key) => {
                            let metric = screenerMetrics?.find((ele) => ele?.metric_col_code === key) || {};
                            return {
                                "dataIndex": key,
                                "key": key,
                                "metric_col_code": key,
                                "metric_unit": "",
                                "metric_data_type": "float",
                                "metric_name": key,
                                "title": key,
                                "align": "right",
                                "width": 140,
                                ...metric
                            }
                        })
                        ////////console.log(cols)
                        setScreenedCols(cols);
                    }
                    setTimeout(() => {
                        let target = document.getElementById('screenedItemsTableContainer');
                        if (target) {
                            window.scrollBy({ behavior: "smooth", top: (target.getBoundingClientRect().top - getDivHeight('#sharpelyHeader')) })
                        }
                    }, 500)
                }
                setTableLoading(false)
            } else {
                setScreenedOnce(false);
                setScreenedStocksData({})
                setScreenedCols([])
                setTableLoading(false)
            }

        } catch (error) {
            setScreenedOnce(false);
            setScreenedStocksData({})
            setScreenedCols([])
            setTableLoading(false)
        }
    }

    const resetState = async (isAdvanceSwitch = false) => {
        setDefCode("");
        if (!isAdvanceSwitch)
            setisAdvance(false);
        setScreenId(0);
        setScreenData({});
        setScreenName('');
        setScreenDesc('');
        setAlertsEnabled(false);
        setIsPublished(false)
        setConditions(isAdvanceSwitch ? {
            "0_condition": {
                queries: {}
            }
        } : {});
        setConditionsOper([]);
        setVariables({});
        setGlobals(initialGlobals)
        setlastFocusedBlock('0_condition');
        setIsCondBlockActive(false);
        setStocksSortAndLimit({ top: null, by: null, sort: null });
        setLoading(true);
        setScreenedOnce(false)
    }

    const loadScreen = async () => {
        await resetState();
        ////console.log("dssadasd", searchParams, param_screenUserId, param_screen_name, param_screenId)
        console.log('1233467888')
        if (searchParams.get('read_code_from_local') === 'true' && searchParams.get('from_screen_campaign') === 'true') {
            let config = JSON.parse(localStorage.getItem('campaignScreenCode'));
            if (config?.code) {
                var res = await codeParserWrapper({
                    code: config?.code, screenerMetrics, functions
                })
                setConditions(res?.conditions)
                setConditionsOper(res?.conditionOperators)
                setGlobals(res?.globals)
                setVariables(res?.variables)
                setLoading(false);
                getScreenedStocks(false, config?.code, config?.position_sizing?.limit || { by: null, sort: null, top: null })
                setIsCloned(false)
                setCanPublish(false);
            }
            else {
                setIsCloned(false)
                setScreenedOnce(false);
                setScreenedStocksData({});
                setScreenedCols([]);
                let allPublishedScreens = await apiHelperPY({ apiName: "getAllPublishedScreens" });
                setallPublishedScreens(JSON.parse(allPublishedScreens)?.filter((i) => i?.client_id !== user?.id))
                setLoading(false);
            }
            localStorage.removeItem('campaignScreenCode');
        }
        else if (cloned_screen_data) {
            setIsCloned(true);
            setisAdvance(cloned_screen_data?.screen_level === 1)
            ////console.log("sadasad", cloned_screen_data, cloned_screen_data?.config?.position_sizing?.limit || { by: null, sort: null, top: null })
            setStocksSortAndLimit(cloned_screen_data?.config?.position_sizing?.limit || { by: null, sort: null, top: null });
            if (cloned_screen_data?.config?.position_sizing?.limit?.by) {
                setuseSortAndLimit(true)
            }
            console.log("SAASDFASF", cloned_screen_data)
            setTimeframe(cloned_screen_data?.timeframe);
            setScreenData(cloned_screen_data)
            // codeParser(cloned_screen_data?.config?.code);

            var res = await codeParserWrapper({
                code: cloned_screen_data?.config?.code, screenerMetrics, functions
            })

            setConditions(res?.conditions)
            setConditionsOper(res?.conditionOperators)
            setGlobals(res?.globals)
            setVariables(res?.variables)


            setLoading(false);
            getScreenedStocks(false, cloned_screen_data?.config?.code, cloned_screen_data?.config?.position_sizing?.limit || { by: null, sort: null, top: null })
            setScreenName(cloned_screen_name)
            setScreenDesc(cloned_screen_desc)
            setCanPublish(false);

        }
        else if (strategy_fundD) {
            setIsCloned(false)
            setisAdvance(strategy_fundD?.config?.screen_level === 1)
            ////console.log("ASDSADAS", strategy_fundD?.config?.position_sizing?.limit || { by: null, sort: null, top: null })
            setStocksSortAndLimit(strategy_fundD?.config?.position_sizing?.limit || { by: null, sort: null, top: null });
            if (strategy_fundD?.config?.position_sizing?.limit?.by) {
                setuseSortAndLimit(true)
            }
            setTimeframe(strategy_fundD?.config?.bar_timeframe);
            setScreenData(strategy_fundD)
            // codeParser(strategy_fundD?.config?.code);
            var res = await codeParserWrapper({
                code: strategy_fundD?.config?.code, screenerMetrics, functions
            })

            setConditions(res?.conditions)
            setConditionsOper(res?.conditionOperators)
            setGlobals(res?.globals)
            setVariables(res?.variables)

            setCanPublish(false);
            setLoading(false);
            // getScreenedStocks(false, strategy_fundD?.config?.code, strategy_fundD?.config?.position_sizing?.limit || { by: null, sort: null, top: null })

        }
        else if (param_screen_name && param_screenId) {
            setIsCloned(false)
            if (param_screenUserId !== user?.id) {
                //publisher

                let allScreens = await apiHelperPY({ apiName: "getAllPublishedScreens" });
                allScreens = JSON.parse(allScreens)
                let scrId = Number(param_screenId);
                let scr = allScreens?.find((ele) => ele?.screen_id === scrId);
                if (scr) {
                    ////console.log("ASDASD", scr);
                    let cloneRes = await getUserCloneItems();
                    let checkIfCloned = cloneRes?.screens?.allClonedScreens?.find((el) => String(el?.TypeId) === String(param_screenId));
                    if (checkIfCloned) {
                        var t = String(scr?.config)?.substring(1, String(scr?.config)?.length - 1)
                        let config = JSON.parse(t);
                        navigate(`/stocks/super-screener`, {
                            state: {
                                "screenData": {
                                    ...scr,
                                    "config": {
                                        ...config,
                                        "parent_screen_id": scr?.screen_id,
                                        "is_published": 0
                                    }
                                },
                                screenName: scr?.screen_name + "(Copy)",
                                desc: scr?.screen_desc,
                            },
                            replace: true
                        })
                    }
                    else {
                        setisAdvance(scr?.screen_level === 1)
                        setScreenName(scr?.screen_name);
                        setScreenDesc(scr?.screen_desc);
                        setAlertsEnabled(Boolean(scr?.is_alerts))
                        setIsPublished(Boolean(scr?.is_published))
                        setTimeframe(scr?.timeframe);
                        setScreenId(scr?.screen_id);
                        setScreenData(scr)
                        var t = String(scr?.config)?.substring(1, String(scr?.config)?.length - 1)
                        ////console.log(t)
                        let config = JSON.parse(t);
                        setCanPublish(false);
                        // codeParser(config?.code);

                        var res = await codeParserWrapper({
                            code: config?.code, screenerMetrics, functions
                        })

                        setConditions(res?.conditions)
                        setConditionsOper(res?.conditionOperators)
                        setGlobals(res?.globals)
                        setVariables(res?.variables)

                        setStocksSortAndLimit(config?.position_sizing?.limit || { by: null, sort: null, top: null });
                        if (config?.position_sizing?.limit?.by) {
                            setuseSortAndLimit(true)
                        }
                        setLoading(false);

                        // () => {
                        // let code = finalCode();
                        // if (!code?.includes("Error:")) {
                        getScreenedStocks(false, config?.code, config?.position_sizing?.limit || { by: null, sort: null, top: null })
                        // } else {
                        //     openNotificationWithIcon("error", "Error", code?.replace("Error: ", ""))
                        // }
                        // }
                    }
                } else {
                    setLoading(false);
                    navigate(`${type.basePath}/super-screener`);


                }

            } else {
                let allScreens = await apiHelper({ apiName: "getAllSuperScreens", saveResponse: "sessionStorage", data: user?.id });
                let scrId = Number(param_screenId);
                let scr = allScreens?.filter((ele) => ele?.screen_id === scrId)?.[0];
                ////console.log(allScreens, scrId, scr)
                if (scr) {
                    console.log(scr);
                    setisAdvance(scr?.screen_level === 1)
                    setScreenName(scr?.screen_name);
                    setScreenDesc(scr?.screen_desc);
                    setAlertsEnabled(Boolean(scr?.is_alerts))
                    setIsPublished(Boolean(scr?.is_published))
                    setTimeframe(scr?.timeframe);
                    setScreenId(scr?.screen_id);
                    setScreenData(scr)
                    var t = String(scr?.config)?.substring(1, String(scr?.config)?.length - 1)
                    // co
                    let config = JSON.parse(t);
                    if (config?.parent_screen_id) {
                        setCanPublish(false);
                    }
                    else {
                        setCanPublish(true);
                    }
                    // codeParser(config?.code);
                    var res = await codeParserWrapper({
                        code: config?.code, screenerMetrics, functions
                    })

                    setConditions(res?.conditions)
                    setConditionsOper(res?.conditionOperators)
                    setGlobals(res?.globals)
                    setVariables(res?.variables)

                    setStocksSortAndLimit(config?.position_sizing?.limit || { by: null, sort: null, top: null });
                    if (config?.position_sizing?.limit?.by) {
                        setuseSortAndLimit(true)
                    }
                    setLoading(false);

                    // () => {
                    // let code = finalCode();
                    // if (!code?.includes("Error:")) {
                    getScreenedStocks(false, config?.code, config?.position_sizing?.limit || { by: null, sort: null, top: null })
                    // } else {
                    //     openNotificationWithIcon("error", "Error", code?.replace("Error: ", ""))
                    // }
                    // }
                } else {
                    setLoading(false);
                    navigate(`${type.basePath}/super-screener`);


                }

            }

        }
        else {
            setIsCloned(false)
            setScreenedOnce(false);
            setScreenedStocksData({});
            setScreenedCols([]);
            let allPublishedScreens = await apiHelperPY({ apiName: "getAllPublishedScreens" });
            ////console.log("saddadasda", JSON.parse(allPublishedScreens)?.filter((i) => i?.client_id !== user?.id))
            setallPublishedScreens(JSON.parse(allPublishedScreens)?.filter((i) => i?.client_id !== user?.id))
            setLoading(false);
        }
    }

    const deleteScreen = async (id) => {
        let res = await apiHelper({ apiName: "deleteScreenv1", data: { screenId: id, clientId: user?.id } });
        if (res) {
            toast.error('Screen deleted!')
            await apiHelper({ apiName: "getAllSuperScreens", saveResponse: "sessionStorage", data: user?.id, getFresh: true });
            navigate(`${type.basePath}/super-screener`)
        }
    }

    const landingPageFinalClick = (otherParams = {}) => {
        let code = finalCode({
            globals: globals,
            variables: variables,
            conditions: conditions,
            conditions_oper: conditions_oper,
            indices: indices,
            sortAndLimit: stocksSortAndLimit
        });
        localStorage.setItem('campaignScreenCode', JSON.stringify({
            code, ...otherParams
        }));
        navigate(`/stocks/super-screener?read_code_from_local=true&from_screen_campaign=true`);
    }

    React.useEffect(() => {
        loadScreen();
    }, [searchParams]);

    const openBacktestInpModal = (configM) => {
        if (configM === undefined)
            configM = backtestConfig
        let config = { ...configM };
        config = {
            ...config,
            'position_sizing': {
                ...config?.['position_sizing'],
                limit: useSortAndLimit ? { by: stocksSortAndLimit?.by, sort: stocksSortAndLimit?.sort, top: stocksSortAndLimit?.top } : { by: 'mcap', sort: 'desc', top: 30 }
            }
        }
        // if(JSON.stringify(backtestConfig) !== JSON.stringify(config)){
        // setBacktestConfig(config);
        // }

        onBacktestInpDone(config, start_date, end_date)
    }

    const socketlogic = async (t) => {
        let webSocket = new WebSocket(`wss://pyapi.mintbox.ai/ws2/ws/backtest`);
        //wss://uat.mintbox.ai/ws2/ws/backtest
        //ws://pyapi.mintbox.ai/ws2/ws/backtest
        //wss://pyapi.mintbox.ai/ws2/ws/backtest
        //ws://localhost:8001/ws/backtest
        var backtestConfig = JSON.parse(t?.config)
        var chartData = []
        webSocket.onerror = function (event) {
            ////////console.log("err")
            ////////console.log(event)

        };

        webSocket.onopen = function (event) {
            ////////console.log("send")
            webSocket?.send(JSON.stringify(t))
            return false;
        };

        webSocket.onmessage = function (event) {
            const message = JSON.parse(event.data?.replace(/NaN/g, null)?.replace(/-Infinity/g, null)?.replace(/Infinity/g, null));
            if (message?.includes("code") && message?.includes("bt")) {
                setBackTestData(JSON.parse(message)?.bt)
                setbackTestModalLoading(false);
            } else if (Array.isArray(message)) {
                message.forEach(data => {
                    chartData.push({
                        Portfolio: data[1] / backtestConfig?.initial_capital,
                        Benchmark: data[2] / backtestConfig?.initial_capital,
                        Date: data[0]
                    })
                });
                setbackTestPerfData([
                    ...chartData
                ])
            }
            else {
                ////////console.log("Final message:", message);
            }
        };
        webSocket.onclose = async function (event) {
            ////////console.log("closed")
            ////////console.log(event)
        };
    }

    const onBacktestInpDone = async (config, startDate, endDate) => {
        let code = finalCode({
            globals: globals,
            variables: variables,
            conditions: conditions,
            conditions_oper: conditions_oper,
            indices: indices,
            sortAndLimit: stocksSortAndLimit
        });
        if (!code?.includes("Error:")) {
            let updConfig = {
                ...config,
                // 'strategy_id':1,
                // 'initial_capital': initial_capital,
                'client_id': user?.id,
                'strategy_type_id': config?.schedule?.freq !== "NA" ? 1 : 2,
                // 'strategy_name':"New Strategy",
                'strategy_desc': "",
                'instrument_type': 3,
                'instrument_selection': 2,
                'code': code
            }
            setbackTestModal(true);
            if (code?.length > 10 && (!(JSON.stringify(backtestConfig) === JSON.stringify(config) && start_date === startDate && end_date === endDate) || !(code === backtestCode))) {
                setbackTestModalLoading(true)
                setbackTestPerfData([]);
                // ////////console.log('btlog=>', updConfig)
                setBacktestConfig(config);
                setupdatedConfig(config);
                setviewTypePendingNewRun(0)
                setbacktestCode(code)
                setstart_date(startDate)
                setend_date(endDate)
                socketlogic({
                    config: JSON.stringify(updConfig),
                    initial_capital: updConfig?.initial_capital || 100000,
                    backtest_start: startDate,
                    end_date: endDate
                })
            }
        } else {
            openNotificationWithIcon("error", "Error", code?.replace("Error: ", ""))
        }
    }


    const onPaperTrade = async (name = "") => {
        setviewType('papertrade')
        setregenerateOrder(false)
        let code = finalCode({
            globals: globals,
            variables: variables,
            conditions: conditions,
            conditions_oper: conditions_oper,
            indices: indices,
            sortAndLimit: stocksSortAndLimit
        });
        var isUpdated = false
        if (!(JSON.stringify(backtestConfig) === JSON.stringify(updatedConfig) && start_date2 === start_date && end_date2 === end_date) && viewTypePendingNewRun === 2) {
            isUpdated = true
            setviewTypePendingNewRun(1)
            // setBacktestConfig(updatedConfig)
            // setstart_date(start_date2)
            // setend_date(end_date2)

        }
        if (!code?.includes("Error:")) {
            let updConfig = {
                ...(isUpdated ? updatedConfig : backtestConfig),
                // 'strategy_id':1,
                'client_id': user?.id,
                'strategy_type_id': (isUpdated ? updatedConfig : backtestConfig)?.schedule?.freq !== "NA" ? 1 : 2,
                'strategy_name': name,
                'strategy_desc': "",
                'instrument_type': 3,
                'instrument_selection': 2,
                'code': code
            }
            setBacktestConfig(updConfig);
            setupdatedConfig(updConfig);
            return updConfig
        } else {
            openNotificationWithIcon("error", "Error", code?.replace("Error: ", ""))
            return null
        }
    }

    const saveScreener = async ({ isPublishing = false, isPublishedInit, updName = null, updDesc = null, updAlerts = null } = {}) => {
        let name = updName !== null ? updName : screenName;
        let desc = updDesc !== null ? updDesc : screenDesc;
        let alerts = updAlerts !== null ? updAlerts : alertsEnabled;
        setScreenName(name);
        setScreenDesc(desc);
        setAlertsEnabled(alerts);
        try {
            let code = finalCode({
                globals: globals,
                variables: variables,
                conditions: conditions,
                conditions_oper: conditions_oper,
                indices: indices,
                sortAndLimit: stocksSortAndLimit
            });
            if (!code?.includes("Error:")) {
                // console.log(screenData)
                var t = typeof (screenData?.config) === "string" ? String(screenData?.config)?.substring(1, String(screenData?.config)?.length - 1) : JSON.stringify(screenData?.config || {})
                ////console.log(t)
                let config = JSON.parse(t);
                let data = {
                    screenId: screenId,
                    clientId: user?.id,
                    config: JSON.stringify(JSON.stringify(Object?.values(stocksSortAndLimit)?.filter((i) => i)?.length === 3 ? {
                        ...config,
                        code: code,
                        'position_sizing': {
                            limit: { by: stocksSortAndLimit?.by, sort: stocksSortAndLimit?.sort, top: stocksSortAndLimit?.top }
                        }
                    } : { ...config, code })),
                    timeframe: timeframe,
                    isGuruScreen: 0,
                    isSharpelyScreen: 0,
                    screenLevel: isAdvance ? 1 : 0,
                    isPublished: isPublishing ? Number(isPublishedInit) : Number(isPublished),
                    showAsScreen: 1,
                    screenName: name,
                    screenDesc: desc,
                    isDeleted: 0,
                    isAlerts: Number(alerts),
                    instrumentType: type.strVal
                }
                // console.log(data)
                let res = await apiHelper({ apiName: "addScreenv1", data: data, saveResponse: "" });
                if (res) {
                    await apiHelper({ apiName: "getAllSuperScreens", saveResponse: "sessionStorage", data: user?.id, getFresh: true })
                    if (isPublishing) {
                        let data = {
                            "clientId": user?.id,
                            "publisherId": userPublisherProfile?.UID,
                            "type": "SCREEN",
                            "typeId": String(screenId),
                            "publishedDate": new Date().toISOString(), // cloning date
                            "isPublished": 1,
                            "isDeleted": 0
                        }
                        await apiHelper({ apiName: "addPublishedStrategy", data: { data } });
                        toast.success('Screen published.')
                        setIsPublished(isPublishedInit);
                    } else {
                        toast.success('Screen saved!')
                    }
                    navigate(`${type.basePath}/super-screener?screenName=${name?.replaceAll(' ', '-')}&screenId=${res?.[0]?.screen_id}&userId=${user?.id || 0}`)
                    setScreenSaveModal(false);
                }
                else {
                    toast.error('Something went wrong!');
                }
                ////////console.log(res);
            } else {
                openNotificationWithIcon("error", "Error", code?.replace("Error: ", ""))
            }
        }
        catch (err) {
            console.log(err)
            toast.error('Something went wrong!');
        }
    }


    React.useEffect(() => {
        setscreener_type(isAdvance ? "Advance" : "Basic")
    }, [isAdvance])


    React.useEffect(() => {
        if (triggerBacktest) {
            openBacktestInpModal(strategy_fundD?.config)
        }
    }, [triggerBacktest])

    const AppliedFilterView = () => {

        const handleMenuClick = (e) => {
            if (e?.key === 'Code') {
                openNotificationWithIcon("info", "SharpeScript™", "Stay tuned. We are launching soon");
            }
            else if ((e?.key === 'Advance') !== isAdvance) {
                let code = finalCode({
                    globals: globals,
                    variables: variables,
                    conditions: conditions,
                    conditions_oper: conditions_oper,
                    indices: indices,
                    sortAndLimit: stocksSortAndLimit
                });
                if (code?.includes("output")) {
                    setEditScreenerView(true)
                } else {
                    setisAdvance(e?.key === 'Advance')
                    resetState(e?.key === 'Advance')
                    setLoading(false);

                }
            }
        };
        const items = [
            {
                label: <div style={{ display: "flex", flexDirection: "row", gap: "0.52rem", alignItems: "flex-start", paddingTop: "0.3rem", paddingBottom: "0.3rem" }} onClick={() => {
                    handleMenuClick({ key: "Basic" })
                }}>
                    <CgDatabase size="1rem" className="mt-1" color="var(--primary)" />
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "0.1rem", }}>
                        <span className="textSM w-500" style={{ lineHeight: undefined }}>Basic Builder</span>
                        <span className="textXS w-500" style={{ lineHeight: undefined, color: isLanding ? "var(--dark3)" : "var(--dark2)" }}>Simple step-by-step screen builder</span>
                    </div>
                </div>
                ,
                key: "Basic"
            },
            {
                label: <SubscribeContentWrapper moduleKey="ADVANCE_SCREENING_WITH_BACKTEST_AND_REPLAY" loginModalParams={{
                    type: "register",
                    executeOnLoginOrSignup: () => {
                        landingPageFinalClick({ isAdvance: true, code: "" })
                    }
                }} onClick={() => {
                    handleMenuClick({ key: "Advance" })
                }} showLockIcon>
                    <div style={{ display: "flex", flexDirection: "row", gap: "0.52rem", alignItems: "flex-start", paddingTop: "0.3rem", paddingBottom: "0.3rem" }}>
                        <MdOutlineWorkspacePremium size="1rem" className="mt-1" color="var(--green)" />
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "0.1rem", }}>
                            <span className="textSM w-500" style={{ lineHeight: undefined }}>Advance Builder</span>
                            <span className="textXS textLight w-500" style={{ lineHeight: undefined, color: isLanding ? "var(--dark3)" : "var(--dark2)" }}>Slightly complicated but offers more flexibility</span>
                        </div>
                    </div>
                </SubscribeContentWrapper>,
                key: "Advance"
            },
            {
                label: <div style={{ display: "flex", flexDirection: "row", gap: "0.52rem", alignItems: "flex-start", paddingTop: "0.3rem", paddingBottom: "0.3rem" }} onClick={() => {
                    handleMenuClick({ key: "Code" })
                }}>
                    <BiCode size="1rem" className="mt-1" color="var(--orange)" />
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "0.1rem", }}>
                        <span className="textSM w-500" style={{ lineHeight: undefined }}>Code Builder</span>
                        <span className="textXS textLight w-500" style={{ lineHeight: undefined, color: isLanding ? "var(--dark3)" : "var(--dark2)" }}>Most powerful! But only if you know SharpeScript™</span>
                    </div>
                </div>,
                key: "Code"
            }
        ];
        const menuProps = {
            items
        };

        // [

        // ],
        // onClick: ({ key }) => {

        // }

        return <>
            {isCloned && <Alert style={{ marginBottom: 20 }}
                message={<span className="textESM w-500 dark4">
                    This screen has already been cloned by you. You can make changes to this screen and save multiple versions of this screen.
                </span>}
                type="info"
                showIcon
                closable
            />}
            <div
                className="mobCardBorderRadius0"
                style={{
                    backgroundColor: "var(--white)",
                    boxShadow: isLanding ? "" : '0.1px 0.1px 10px 0px var(--grey1)', padding: "1rem", borderRadius: "0.3rem", marginBottom: 20, gap: "1.25rem"
                }}
            >
                < div style={{
                    display: "flex", flex: 1, alignItems: isMobile ? "stretch" : "center", flexDirection: isMobile ? 'column' : 'row', gap: '1rem'
                }}>

                    <div style={{
                        display: "flex", flex: 1, alignItems: "center", gap: "2rem"
                    }}>
                        {(screenId !== 0 || isCloned) && <div style={{ display: "flex", gap: "0.4rem", alignItems: "center" }}>
                            {(param_screenUserId !== user?.id || isCloned) && <Avatar src={screenPublisherProfile?.PublisherIcon} />}
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <CardHeader heading={screenName} />
                                {(param_screenUserId !== user?.id || isCloned) && <div className="textXS textLight">
                                    By {<Link style={{ color: "var(--primary)", cursor: "pointer" }} to={`/publisher-profile?name=${screenPublisherProfile?.Name?.replaceAll(" ", "-")}&id=${screenPublisherProfile?.UID}`} onClick={(e) => { e.stopPropagation() }}>{screenPublisherProfile?.Name}</Link>} on {formatDateMMMDDYYYY(screenData?.created_on)} using {screenData?.screen_level === 0 ? "Basic builder" : screenData?.screen_level === 1 ? "Advance builder" : "Code builder"}
                                </div>}
                            </div>
                        </div>}



                        {(screenId === 0 && !isCloned) ? <Dropdown menu={menuProps}>
                            <div style={{ display: "flex", gap: "1rem", alignItems: "flex-start", flex: isMobile ? 1 : '', justifyContent: 'space-between' }}>
                                {items?.filter((i) => i?.key === screener_type)?.[0]?.label}
                                <IoIosArrowDown size={"1rem"} className="mt-2" />
                            </div>
                        </Dropdown> : null}
                    </div>
                    {isMobile && (screenId !== 0 || isCloned) && <div style={{ display: "flex", gap: "1rem", flex: 1, width: "100%" }}>
                        <div className='textXS dark4 metricDescDrawer' style={{ maxWidth: "100%" }} dangerouslySetInnerHTML={{ __html: `${screenDesc}` }}></div>
                    </div>}
                    {fromStrategyCreation && <AccessComponent component={<button className="btnWhite" style={{ cursor: "pointer" }} onClick={() => {
                        openBacktestInpModal()
                    }}>
                        Backtest and Paper trade
                    </button>} moduleKey={"BUILD,_BACKTEST_AND_PAPERTRADE"}
                        upgradeComponent={<SubscribeBtnSmall str='Backtest and Paper trade' accessKey={"BUILD,_BACKTEST_AND_PAPERTRADE"} />} />}
                    {!fromStrategyCreation && param_screenUserId === user?.id && < div style={{ display: "flex", gap: "0.8rem", flex: 1, justifyContent: "flex-end" }}>
                        {screenId > 0 && param_screenUserId === user?.id ? <SubscribeContentWrapper onClick={() => {
                            if (!userPublisherProfile?.IsApproved) {
                                if (userPublisherProfile?.IsApproved === 0)
                                    openNotificationWithIcon("warning", "Pending approval", "Your profile is under review. Once approved then you will be allowed to publish this screen", <Space>
                                        <Link to="/user-profile" target="_blank">
                                            <Button type="primary" size="small">
                                                View profile
                                            </Button>
                                        </Link>
                                    </Space>)
                                else
                                    openNotificationWithIcon("info", "Incomplete Publisher profile", "Please complete your publisher profile to start publishing screens.", <Space>
                                        <Link to="/user-profile" target="_blank" className="btn btn-primary">
                                            <Button type="primary" size="small">
                                                Complete profile
                                            </Button>
                                        </Link>
                                    </Space>)
                            }
                            else if (!canPublish) {
                                openNotificationWithIcon("info", "Cloned Screen", "Since this screen has been cloned, it cannot be published.")
                            }
                            else {
                                if (!isPublished) {
                                    saveScreener({ isPublishing: true, isPublishedInit: !isPublished })
                                }
                            }
                        }} moduleKey="PUBLISH_SCREENS_AND_MODELS" disabled={isPublished}>
                            <button className="btnBlack d-flex align-items-center" style={{
                                background: isPublished ? "var(--white)" : "", borderColor: isPublished ? "var(--green)" : "", gap: "0.25rem",
                                color: isPublished ? "var(--green)" : ""
                            }}>
                                {isPublished && <BiCheck size='1rem' />}
                                {isPublished ? "Published" : "Publish"}
                            </button>
                        </SubscribeContentWrapper> : null}
                        {!isLanding && <SubscribeContentWrapper moduleKey="SAVE_SCREEN_AND_SET_ALERTS" onClick={() => {
                            let code = finalCode({
                                globals: globals,
                                variables: variables,
                                conditions: conditions,
                                conditions_oper: conditions_oper,
                                indices: indices,
                                sortAndLimit: stocksSortAndLimit
                            });
                            if (!code?.includes("Error:")) {
                                setScreenSaveModal(true);
                                setIsCondBlockActive(false);
                            } else {
                                openNotificationWithIcon("error", "Error", "Not a valid condition!")
                            }
                        }} preventDefault={screenId > 0}>
                            <button className="btnWhite" style={{ cursor: "pointer" }} >
                                {screenId > 0 ? "Update Screen" : "Save screen"}
                            </button>
                        </SubscribeContentWrapper>}
                        {screenId > 0 && param_screenUserId === user?.id && <Popconfirm title={<span className='textSM w-500 dark4' style={{ userSelect: "none", cursor: "pointer" }}>
                            Are you sure you want to delete this<br /> screen?</span>}
                            description={<span className='textXS w-500 dark3 mt-3' style={{ userSelect: "none" }}>Note: This action is irreversible.</span>}
                            onConfirm={(e) => {
                                e.stopPropagation()
                                deleteScreen(screenId);
                            }} arrow={false} placement='topLeft'>
                            <button className="btnWhite">
                                <MdDelete color="var(--red)" />
                            </button>
                        </Popconfirm>}
                    </div>}


                    {param_screenUserId !== user?.id && <div style={{
                        display: "flex", flex: 1, alignItems: "center", gap: "2rem", justifyContent: "flex-end"
                    }}>
                        <div style={{ display: "flex", gap: "0.8rem", justifyContent: "flex-end" }}>
                            <AccessComponent component={<button className='btnWhite' onClick={() => {
                                openBacktestInpModal()
                            }}>Backtest screen</button>} moduleKey={isAdvance ? "ADVANCE_SCREENING_WITH_BACKTEST_AND_REPLAY" : "BASIC_SCREENING_WITH_BACKTEST_AND_REPLAY"}
                                upgradeComponent={<SubscribeBtnSmall str='Backtest screen' accessKey={isAdvance ? "ADVANCE_SCREENING_WITH_BACKTEST_AND_REPLAY" :
                                    "BASIC_SCREENING_WITH_BACKTEST_AND_REPLAY"} />} />
                        </div>

                        <div style={{ display: "flex", gap: "0.8rem", justifyContent: "flex-end" }} >
                            <CloneItemModal onConfirm={async () => {
                                var t = String(screenData?.config)?.substring(1, String(screenData?.config)?.length - 1)
                                let config = JSON.parse(t);
                                if (screenPublisherProfile?.IsApproved) {
                                    let data = {
                                        "clientId": user?.id,
                                        "publisherId": screenPublisherProfile?.UID,
                                        "type": "SCREEN",
                                        "typeId": String(screenId),
                                        "publishedDate": new Date().toISOString(), // cloning date
                                        "isPublished": 1,
                                        "isDeleted": 0
                                    }
                                    await apiHelper({ apiName: "addClonedStrategy", data: { data } });
                                }
                                navigate(`/stocks/super-screener`, {
                                    state: {
                                        "screenData": {
                                            ...screenData,
                                            "config": {
                                                ...config,
                                                "parent_screen_id": screenId,
                                                "is_published": 0
                                            }
                                        },
                                        screenName: screenName + "(Copy)",
                                        desc: screenDesc,
                                    }
                                })
                            }} moduleKey="CLONE_COMMUNITY_SCREENS" userCloneCount={userClonedTotalScreens} />
                        </div>
                    </div>}
                </div>
                {!isMobile && (screenId !== 0 || isCloned) && <Divider style={{ margin: '1rem 0' }} />}
                {!isMobile && (screenId !== 0 || isCloned) && <div style={{ display: "flex", gap: "1rem", flex: 1, width: "100%" }}>
                    <div className='textXS dark4 metricDescDrawer' style={{ flex: 3, maxWidth: "80%" }} dangerouslySetInnerHTML={{ __html: `${screenDesc}` }}></div>
                </div>}
            </div >
        </>
    }


    const StrategyDetailSpecialHeader = () => {
        return <div className="mb-4" style={{
            display: "flex", alignItems: "center", borderRadius: "0.3rem",
        }}>
            {/* <CardHeader heading={"Stock selection model"} headingType={1} /> */}
            {/* <button className='btnBlack' onClick={() => {
                let code = finalCode();
                var screen_config = {
                    ...strategy_fundD?.config,
                    code: code
                }
                if (!(JSON.stringify(strategy_fundD?.config) === JSON.stringify(screen_config))) {
                    updateStrategyParams(screen_config)
                } else {
                    toast.error("No changes made")
                }
            }}>{"Update"}</button> */}
        </div>
    }

    const ActivityLoader = () => {
        return <Card bodyStyle={{ position: "relative", padding: '1.25rem', }} className={`mobCardBorderRadius0`}>
            <LoadingTableSkeleton />
        </Card>
    }

    const ConditionViewer = () => {
        const [openBasicDrawer, setopenBasicDrawer] = React.useState(openBasicDrawer2 || false)
        const allConditions = Object?.keys(conditions)
        ////console.log("DSADASD", allConditions)

        if (allConditions?.length === 0) {
            if (!isAdvance)
                return <div className="mobCardBorderRadius0" style={{
                    boxShadow: isLanding ? "" : '0.1px 0.1px 10px 0px var(--grey1)',
                    padding: isMobile ? "3rem 1rem" : "1rem", borderRadius: 4, backgroundColor: "var(--white)"
                }}>
                    <div style={{ display: "flex", flexDirection: isMobile ? 'column' : 'row', justifyContent: "space-between", alignItems: "center", gap: '2rem' }}>
                        <div className="textMD w-500">Start adding rules for your super screen</div>
                        <button className="btnWhite w-bold d-flex align-items-center" style={{
                            gap: "0.5rem",
                            alignItems: "center",
                            alignContent: "center"
                        }} onClick={() => {

                            setopenBasicDrawer(true)


                            // setConditionsOper([
                            //     ...conditions_oper,
                            //     "and"
                            // ])
                        }}><MdAddCircle /> Add rule</button>

                    </div>


                    <QueryViewerBasic allFundsData={allFundsData} isVisible={false} openBasicDrawer={openBasicDrawer} type={type} allFuncAndMetricForDrawer={allFuncAndMetricForDrawer} isAdvance={isAdvance} timeframe={timeframe} isCondition={true} variables={variables} functions={functions} screenerMetrics={screenerMetrics} initialQueries={{}}
                        // lastindx={lastEditingIndex}
                        toggleBasicDrawer={(e) => {
                            setopenBasicDrawer(false)
                            setopenBasicDrawer2(false)
                        }}
                        onQueriesUpdate={(queries) => {
                            if (Object?.keys(queries)?.length > 0) {
                                setConditions({
                                    [`${Object.keys(conditions)?.length}_condition`]: {
                                        queries: {
                                            ...queries
                                        }
                                    }
                                })
                            }
                            setopenBasicDrawer(false)
                            setopenBasicDrawer2(false)
                            // var condition = conditions[cond]
                            // setConditions({
                            //     ...conditions,
                            //     [cond]: {
                            //         ...condition,
                            //         queries: {
                            //             ...queries
                            //         }
                            //     }
                            // })
                            // setLastEditingIndex(lastEditingIndex)
                        }}
                        // isFocused={lastFocusedBlock === cond && isCondBlockActive}
                        // funcToCallOnWrapClick={() => {
                        //     if (!isCondBlockActive) {
                        //         setIsCondBlockActive(true);
                        //     }
                        //     if (lastFocusedBlock !== cond) {
                        //         setlastFocusedBlock(cond)
                        //     }
                        // }} onCreateVariable={() => {
                        //     setVariableModal(true);
                        // }}
                        indices={indices} />
                </div>
        } else {
            if (isAdvance) {

                console.log(conditions, conditions_oper)
                return allConditions?.map((cond, index) => {

                    let thisCondition = conditions?.[cond];
                    let conditionBlockWrapper = conditions?.[cond]?.conditionBlockWrapper || {};
                    //console.log("akakak", conditions_oper)
                    return <div style={{
                        gap: 10, backgroundColor: "var(--white)",
                        boxShadow: '0.1px 0.1px 10px 0px var(--grey1)', padding: "1rem", borderRadius: "0.3rem", marginTop: index === 0 ? 0 : '1.25rem'
                    }} key={generateUniqueKey(cond)}>
                        {index > 0 && <div className="my-3">
                            <Segmented disabled={readOnly}
                                className={conditions_oper?.[index - 1] === "and" ? 'textSM purple ANDTabs' : "textSM purple ORTabs"}
                                options={sortOptions}
                                onChange={(value) => {
                                    var cond_oper = [...conditions_oper]
                                    cond_oper[index - 1] = value
                                    setConditionsOper(cond_oper)
                                }}
                                defaultValue={conditions_oper?.[index - 1] || "and"}
                            />
                        </div>}
                        <div style={{ display: "flex", flexDirection: "column", gap: 0, boxShadow: '0.1px 0.1px 10px 0px var(--grey1)' }}>
                            <div style={{
                                display: "flex", flexDirection: "row", alignItems: "center", gap: 20, backgroundColor: "#454545", padding: 10,
                                borderTopLeftRadius: "0.3rem", borderTopRightRadius: "0.3rem",
                            }}>
                                {isAdvance && <>
                                    <Select disabled={readOnly} options={conditionBlockWrappers} value={conditionBlockWrapper?.type} defaultValue={conditionBlockWrappers?.[0]?.value}
                                        style={{ width: "19rem" }} onChange={(value) => {
                                            if (isCondBlockActive) {
                                                setIsCondBlockActive(false);
                                            }
                                            if (value !== conditionBlockWrappers?.[0]?.value) {
                                                setConditions({
                                                    ...conditions,
                                                    [cond]: {
                                                        ...thisCondition,
                                                        "conditionBlockWrapper": {
                                                            type: value,
                                                            noOfBars: 10
                                                        }
                                                    }
                                                })
                                            }
                                            else {
                                                if (thisCondition.conditionBlockWrapper) {
                                                    delete thisCondition?.conditionBlockWrapper
                                                    setConditions({
                                                        ...conditions,
                                                        [cond]: {
                                                            ...thisCondition
                                                        }
                                                    })
                                                }
                                            }
                                        }} />
                                    <LabelInputNew
                                        style={{ display: conditionBlockWrapper?.type ? 'inline' : "none", width: "4rem" }}
                                        value={conditionBlockWrapper?.noOfBars}
                                        onChangeVal={(e) => {
                                            setTimeout(() => {
                                                setConditions({
                                                    ...conditions,
                                                    [cond]: {
                                                        ...thisCondition,
                                                        "conditionBlockWrapper": {
                                                            type: conditionBlockWrapper?.type,
                                                            noOfBars: Number(e)
                                                        }
                                                    }
                                                })
                                            }, 100)
                                        }}
                                    />
                                    {/* <Input defaultValue={conditionBlockWrapper?.noOfBars} type='number' style={{ textAlign: "center", width: "4rem", display: conditionBlockWrapper?.type ? 'inline' : "none" }}
                                        onBlur={(e) => {
                                            setTimeout(() => {
                                                setConditions({
                                                    ...conditions,
                                                    [cond]: {
                                                        ...thisCondition,
                                                        "conditionBlockWrapper": {
                                                            type: conditionBlockWrapper?.type,
                                                            noOfBars: Number(e.target.value)
                                                        }
                                                    }
                                                })
                                            }, 100)
                                        }} /> */}
                                </>}

                                {!readOnly && <div className='d-flex justify-content-end align-items-center' style={{ gap: '1rem', flex: 1 }}>
                                    <div title="Clear" className='d-flex justify-content-center align-items-center textSM w-500' style={{ color: "var(--grey1)", cursor: "pointer", gap: 2 }} onClick={() => {
                                        setConditions({
                                            ...conditions,
                                            [cond]: {
                                                "queries": {}
                                            }
                                        })
                                        setScreenedOnce(false)
                                        setScreenedStocksData({})
                                    }}>
                                        <BsBan style={{ fontSize: "0.8rem", marginRight: "0.2rem" }} /> Clear</div>
                                    {index > 0 && <div title="Delete" className='d-flex justify-content-center align-items-center textSM w-500' style={{ color: "var(--grey1)", cursor: "pointer", gap: 2 }} onClick={() => {
                                        var q = { ...conditions }
                                        let keys = Object.keys(conditions)
                                        let temp = {};
                                        for (let key in conditions) {
                                            if (key !== cond) {
                                                temp[Object.keys(temp)?.length + '_condition'] = conditions[key]
                                            }
                                        }
                                        setConditions(temp);
                                        if (keys?.length > 0) {
                                            var co_op = [...conditions_oper]
                                            co_op?.splice(index - 1, 1)
                                            setConditionsOper(co_op)
                                        }
                                    }}>
                                        <MdDelete /> Delete</div>}
                                </div>}
                            </div>
                            {/* <QueryViewer2 key={cond} condition_key={cond} condition={conditions[cond]} cond_index={index} /> */}
                            <QueryViewer allFundsData={allFundsData} isAdvance={isAdvance} timeframe={timeframe} isCondition={true} variables={variables} functions={functions} screenerMetrics={screenerMetrics} initialQueries={conditions[cond]?.queries || {}}
                                // lastindx={lastEditingIndex}
                                onQueriesUpdate={(queries) => {
                                    var condition = conditions[cond]
                                    setConditions({
                                        ...conditions,
                                        [cond]: {
                                            ...condition,
                                            queries: {
                                                ...queries
                                            }
                                        }
                                    })
                                    // setLastEditingIndex(lastEditingIndex)
                                }} isFocused={lastFocusedBlock === cond && isCondBlockActive} funcToCallOnWrapClick={() => {
                                    if (!isCondBlockActive) {
                                        setIsCondBlockActive(true);
                                    }
                                    if (lastFocusedBlock !== cond) {
                                        setlastFocusedBlock(cond)
                                    }
                                }} onCreateVariable={() => {
                                    setVariableModal(true);
                                }} indices={indices} readOnly={readOnly} />
                        </div>
                        {(index - conditions_oper?.length) === 0 && <div className="mt-3" style={{ display: "flex", gap: 10, justifyContent: "flex-start" }}>
                            {!readOnly && <button className="btnWhite w-bold d-flex align-items-center" style={{
                                gap: "0.5rem",
                                alignItems: "center",
                                alignContent: "center"
                            }} onClick={() => {
                                setConditions({
                                    ...conditions,
                                    [`${Object.keys(conditions)?.length}_condition`]: {
                                        queries: {}
                                    }
                                })
                                setConditionsOper([
                                    ...conditions_oper,
                                    "and"
                                ])
                            }}><MdAddCircle /> Add condition block</button>}

                            <button className='btnPrimary d-flex align-items-center' onClick={() => {
                                setIsCondBlockActive(false);
                                let code = finalCode({
                                    globals: globals,
                                    variables: variables,
                                    conditions: conditions,
                                    conditions_oper: conditions_oper,
                                    indices: indices,
                                    sortAndLimit: stocksSortAndLimit
                                });
                                if (!code?.includes("Error:")) {
                                    getScreenedStocks()
                                } else {
                                    openNotificationWithIcon("error", "Error", code?.replace("Error: ", ""))
                                }
                            }} style={{
                                gap: "0.5rem",
                                alignItems: "center",
                                alignContent: "center"
                            }} >
                                Run <FaPlay size={10} style={{ cursor: "pointer", color: "var(--white)" }} />
                            </button>

                        </div>
                        }
                    </div>
                })
            } else {

                return <div className="mobCardBorderRadius0" style={{
                    backgroundColor: "var(--white)",
                    boxShadow: isLanding ? "" : '0.1px 0.1px 10px 0px var(--grey1)', padding: "1rem", borderRadius: "0.3rem", gap: "1.25rem"
                }}>
                    <div className="textMD w-500 mb-3">Rules ({allConditions?.filter((i) => Object?.keys(conditions?.[i]?.queries)?.length)?.length})</div>

                    <div style={{}}>
                        {allConditions?.map((cond, index) => {

                            let thisCondition = conditions?.[cond];
                            let conditionBlockWrapper = conditions?.[cond]?.conditionBlockWrapper || {};
                            var len = Object.keys(conditions?.[cond]?.queries)?.length
                            console.log("akakak", conditions_oper?.[index - 1], thisCondition, lastFocusedBlock)
                            return <div style={{ gap: 10, display: len ? "unset" : "none" }} key={generateUniqueKey(cond)}>
                                {index > 0 && <div className="my-3">
                                    <Segmented
                                        className={conditions_oper?.[index - 1] === "and" ? 'textSM purple ANDTabs' : "textSM purple ORTabs"}
                                        options={sortOptions}
                                        onChange={(value) => {
                                            var cond_oper = [...conditions_oper]
                                            cond_oper[index - 1] = value
                                            setConditionsOper(cond_oper)
                                        }}
                                        defaultValue={conditions_oper?.[index - 1] || "and"}
                                    />
                                </div>
                                }
                                <div style={{ display: "flex", flexDirection: "column", gap: 0, }}>
                                    <div style={{
                                        display: "flex", flexDirection: "row", alignItems: "center", gap: 20, border: "1px solid var(--grey3)", padding: '0.5rem',
                                        borderRadius: "0.2rem", backgroundColor: "var(--grey4)"
                                    }}>
                                        <QueryViewerBasic allFundsData={allFundsData} openBasicDrawer={lastFocusedBlock === cond ? openBasicDrawer : false} type={type} allFuncAndMetricForDrawer={allFuncAndMetricForDrawer} isAdvance={isAdvance} timeframe={timeframe} isCondition={true} variables={variables} functions={functions} screenerMetrics={screenerMetrics} initialQueries={conditions[cond]?.queries || {}}
                                            toggleBasicDrawer={() => {
                                                setopenBasicDrawer(false)
                                                setopenBasicDrawer2(false)
                                            }}
                                            // lastindx={lastEditingIndex}
                                            onQueriesUpdate={(queries) => {
                                                if (Object.keys(queries)?.length) {
                                                    var condition = conditions[cond]
                                                    setConditions({
                                                        ...conditions,
                                                        [cond]: {
                                                            ...condition,
                                                            queries: {
                                                                ...queries
                                                            }
                                                        }
                                                    })
                                                } else {
                                                    var q = { ...conditions }
                                                    let keys = Object.keys(conditions)
                                                    let temp = {};
                                                    for (let key in conditions) {
                                                        if (key !== cond) {
                                                            temp[Object.keys(temp)?.length + '_condition'] = conditions[key]
                                                        }
                                                    }
                                                    setConditions(temp);
                                                    if (keys?.length > 0) {
                                                        var co_op = [...conditions_oper]
                                                        co_op?.splice(index - 1, 1)
                                                        setConditionsOper(co_op)
                                                    }
                                                    if (Object.keys(temp)?.length === 0) {
                                                        setScreenedOnce(false)
                                                    }
                                                }

                                                setopenBasicDrawer(false)
                                                setopenBasicDrawer2(false)
                                                // setLastEditingIndex(lastEditingIndex)
                                            }} isFocused={lastFocusedBlock === cond && isCondBlockActive} funcToCallOnWrapClick={() => {
                                                if (!isCondBlockActive) {
                                                    setIsCondBlockActive(true);
                                                }
                                                if (lastFocusedBlock !== cond) {
                                                    setlastFocusedBlock(cond)
                                                }
                                            }} onCreateVariable={() => {
                                                setVariableModal(true);
                                            }} indices={indices} />

                                        {index > 0 && <div className='d-flex justify-content-end align-items-center' style={{ gap: '1rem', flex: 1 }}>
                                            <div title="Delete" className='d-flex justify-content-center align-items-center textSM w-500' style={{ color: "var(--dark2)", cursor: "pointer", gap: 2 }} onClick={() => {
                                                var q = { ...conditions }
                                                let keys = Object.keys(conditions)
                                                let temp = {};
                                                for (let key in conditions) {
                                                    if (key !== cond) {
                                                        temp[Object.keys(temp)?.length + '_condition'] = conditions[key]
                                                    }
                                                }
                                                setConditions(temp);
                                                if (keys?.length > 0) {
                                                    var co_op = [...conditions_oper]
                                                    co_op?.splice(index - 1, 1)
                                                    setConditionsOper(co_op)
                                                }
                                            }}>
                                                <MdDelete /></div>
                                        </div>}
                                    </div>
                                    {/* <QueryViewer2 key={cond} condition_key={cond} condition={conditions[cond]} cond_index={index} /> */}


                                </div>

                            </div>
                        })}


                        <div className="my-4" style={{ display: "flex", gap: 10, justifyContent: "flex-start" }}>
                            <button className="btnWhite w-bold d-flex align-items-center" style={{
                                gap: "0.5rem",
                                alignItems: "center",
                                alignContent: "center"
                            }} onClick={() => {
                                setConditions({
                                    ...conditions,
                                    [`${Object.keys(conditions)?.length}_condition`]: {
                                        queries: {}
                                    }
                                })
                                setConditionsOper([
                                    ...conditions_oper,
                                    "and"
                                ])
                                setlastFocusedBlock(`${Object.keys(conditions)?.length}_condition`)
                                setTimeout(() => {
                                    setopenBasicDrawer2(true)
                                }, 200);
                            }}><MdAddCircle /> Add another rule</button>

                            <button className='btnPrimary d-flex align-items-center' onClick={() => {
                                if (isLanding) {
                                    subscribeMiddle({
                                        reqAccessLevel: 1,
                                        onNext: () => {
                                            let code = finalCode({
                                                globals: globals,
                                                variables: variables,
                                                conditions: conditions,
                                                conditions_oper: conditions_oper,
                                                indices: indices,
                                                sortAndLimit: stocksSortAndLimit
                                            });
                                            if (!code?.includes("Error:")) {
                                                getScreenedStocks()
                                            } else {
                                                openNotificationWithIcon("error", "Error", code?.replace("Error: ", ""))
                                            }
                                        },
                                        loginModalParams: {
                                            type: "register",
                                            executeOnLoginOrSignup: () => {
                                                landingPageFinalClick()
                                            }
                                        }
                                    })
                                }
                                else {
                                    let code = finalCode({
                                        globals: globals,
                                        variables: variables,
                                        conditions: conditions,
                                        conditions_oper: conditions_oper,
                                        indices: indices,
                                        sortAndLimit: stocksSortAndLimit
                                    });
                                    if (!code?.includes("Error:")) {
                                        getScreenedStocks()
                                    } else {
                                        openNotificationWithIcon("error", "Error", code?.replace("Error: ", ""))
                                    }
                                }
                            }} style={{
                                gap: "0.5rem",
                                alignItems: "center",
                                alignContent: "center"
                            }} >
                                Run <FaPlay size={10} style={{ cursor: "pointer", color: "var(--white)" }} />
                            </button>

                        </div>
                    </div>
                </div>


            }
        }

    }
    ////////console.log("gagagagga", JSON.stringify(backtestConfig) === JSON.stringify(updatedConfig), start_date2 === start_date, end_date2 === end_date);



    React.useEffect(() => {
        if (strategy_fundD) {
            var a = finalCode({
                globals: globals,
                variables: variables,
                conditions: conditions,
                conditions_oper: conditions_oper,
                indices: indices,
                sortAndLimit: stocksSortAndLimit
            })
            updateStrategyParams(a)
        }
    }, [variables, globals, conditions, conditions_oper])



    if (loading && !isLanding) {
        return (
            <div className="loaderContainer">
                <ActivityLoaderMintbox showQuote />
            </div>
        )
    }
    return (
        <>
            {contextHolder}
            <div className={strategy_fundD ? "" : isLanding ? "" : "topLevelContainer"}>
                {isAdvance && !isLanding && <AccessControlledPage moduleKey="ADVANCE_SCREENING_WITH_BACKTEST_AND_REPLAY" />}
                {!fromStrategyCreation && <AccessControlledPage isDefaultOpen={isLocked} moduleKey={isLocked ? "SAVE_SCREEN_AND_SET_ALERTS" : ""} subModalParams={isLocked ? {
                    isLimitType: true,
                    customAccessObj: {
                        ModuleName: "Screen Result",
                        ModuleDesc: `This screen is locked. With your current plan ${screenDetailedAccess?.maxLimit === 0 ? 'you cannot view any of your saved screens. To view your screens, upgrade your plan.' : `you can only view your first ${screenDetailedAccess?.maxLimit} saved screens. 
                        To view your other screens, upgrade your plan.`}`
                    }
                } : {}} />}
                {fromStrategyCreation && <CardHeader style={{ marginBottom: "1rem" }} heading={"Build a screen for your strategy"} />}
                {strategy_fundD === undefined ? <AppliedFilterView /> : <StrategyDetailSpecialHeader />}
                <div className={"normalContainer"}>
                    {param_screenUserId === user?.id && <div style={{ display: "flex", flexDirection: isMobile ? "column-reverse" : "row", gap: 20 }}>
                        <div style={{ flexDirection: "column", flex: isLanding ? 1 : 1.75, gap: 10 }}>
                            <div className="mobCardBorderRadius0" style={{
                                display: "flex", flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? "start" : "center",
                                backgroundColor: "var(--white)", justifyContent: isMobile ? "stretch" : "space-between",
                                boxShadow: isLanding ? "" : '0.1px 0.1px 10px 0px var(--grey1)', padding: "1rem", borderRadius: "0.3rem", marginBottom: 20, gap: "1.25rem"
                            }}>
                                <div style={{ display: "flex", alignItems: "end", backgroundColor: "var(--white)", gap: "1.25rem", flex: 1, width: '100%' }}>
                                    <div style={{ minWidth: '12rem', maxWidth: isMobile ? "" : "12rem", flex: 1 }}>
                                        <div className='textXS w-500 dark4 mb-1'>Scan on</div>
                                        <Select disabled={readOnly} showSearch style={{ width: '100%' }} options={indices?.map((item, i) => { return { key: i, label: item?.IndexName, value: item?.IndexNameCaps } })}
                                            value={globals?.universe?.expression} onChange={(val) => {
                                                setGlobals({
                                                    ...globals,
                                                    universe: {
                                                        ...globals?.universe,
                                                        expression: val,
                                                        expression_arr: val
                                                    }
                                                })
                                            }} />
                                    </div>
                                    <div style={{ minWidth: '8rem', maxWidth: isMobile ? "" : "8rem", flex: 1 }}>
                                        <div className='textXS w-500 dark4 mb-1'>Exchange</div>
                                        <Select disabled={readOnly} style={{ width: '100%' }} options={exchanges} value={globals?.exchange?.expression} onChange={(val) => {
                                            setGlobals({
                                                ...globals,
                                                exchange: {
                                                    ...globals?.exchange,
                                                    expression: val,
                                                    expression_arr: val
                                                }
                                            })
                                        }} />
                                    </div>
                                    {isAdvance && <div style={{ minWidth: '8rem', maxWidth: isMobile ? "" : "8rem", flex: 1 }}>
                                        <div className='textXS w-500 dark4 mb-1'>Candle Timeframe</div>
                                        <Select disabled={readOnly} style={{ width: '100%' }} options={timeframes} value={timeframe} onChange={(val) => {
                                            setTimeframe(val)
                                            if (val?.includes('d')) {
                                                setReplayInput(22);
                                            }
                                            else if (val?.includes('w')) {
                                                setReplayInput(2);
                                            }
                                            else {
                                                setReplayInput(1);
                                            }
                                        }} />
                                    </div>}
                                </div>
                                <div className='d-flex' style={{ gap: "1rem", justifyContent: 'end', width: '100%' }}>

                                    {strategy_fundD === undefined && <AccessComponent component={<button className='btnWhite d-flex align-items-center' style={{ gap: "0.5rem" }} onClick={() => {
                                        let code = finalCode({
                                            globals: globals,
                                            variables: variables,
                                            conditions: conditions,
                                            conditions_oper: conditions_oper,
                                            indices: indices,
                                            sortAndLimit: stocksSortAndLimit
                                        });
                                        if (!code?.includes("Error:")) {
                                            setIsCondBlockActive(false);
                                            setReplayModal(true);
                                        } else {
                                            toast.error("Not a valid condition")
                                        }

                                    }}>
                                        <IoPlayBackOutline style={{ cursor: "pointer", color: "var(--primary)" }} />  Replay
                                    </button>} moduleKey={isAdvance ? "ADVANCE_SCREENING_WITH_BACKTEST_AND_REPLAY" : "BASIC_SCREENING_WITH_BACKTEST_AND_REPLAY"}
                                        upgradeComponent={<SubscribeBtnSmall str='Replay' accessKey={isAdvance ? "ADVANCE_SCREENING_WITH_BACKTEST_AND_REPLAY" :
                                            "BASIC_SCREENING_WITH_BACKTEST_AND_REPLAY"} loginModalParams={!isLanding ? {} : {
                                                type: "register",
                                                executeOnLoginOrSignup: () => {
                                                    landingPageFinalClick()
                                                }
                                            }} />} />}


                                    {!fromStrategyCreation && strategy_fundD === undefined && <AccessComponent component={<button className='btnBlack' onClick={() => {
                                        openBacktestInpModal()
                                    }}>Backtest</button>} moduleKey={isAdvance ? "ADVANCE_SCREENING_WITH_BACKTEST_AND_REPLAY" : "BASIC_SCREENING_WITH_BACKTEST_AND_REPLAY"}
                                        upgradeComponent={<SubscribeBtnSmall str='Backtest' accessKey={isAdvance ? "ADVANCE_SCREENING_WITH_BACKTEST_AND_REPLAY" :
                                            "BASIC_SCREENING_WITH_BACKTEST_AND_REPLAY"} loginModalParams={!isLanding ? {} : {
                                                type: "register",
                                                executeOnLoginOrSignup: () => {
                                                    landingPageFinalClick()
                                                }
                                            }} />} />}
                                </div>
                            </div>
                            <ConditionViewer />
                        </div>
                        {((strategy_fundD !== undefined && !isAdvance) || isLanding) ? <></> : <div className='normalContainer' style={{
                            flex: 1,
                            borderRadius: "0.3rem", height: "fit-content"
                        }}>
                            {isAdvance && <Collapse defaultActiveKey={strategy_fundD === undefined ? Object.keys(variables)?.length > 0 ? [0] : [] : [0]}
                                style={{ width: "100%", background: "transparent", boxShadow: '0.1px 0.1px 10px 0px var(--grey1)' }}
                                bordered={false} expandIconPosition='end'
                                className='screenerCollapseCont' expandIcon={({ isActive }) => <IoIosArrowDown style={{ transform: isActive ? "rotate(180deg)" : "" }} size='1.25rem'
                                    color='var(--dark4)' />}
                                items={[{
                                    key: 0,
                                    label: <div className="mobCardBorderRadius0" style={{ display: "flex", backgroundColor: "var(--white)", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                        <div className="textLG w-700">Your custom metrics ({Object.keys(variables)?.length})</div>
                                        {!readOnly && <span style={{ display: "flex", gap: '0.3rem', alignItems: "center" }} onClick={(e) => {
                                            setVariableModal(!variableModal);
                                            e.stopPropagation();
                                        }}>
                                            <FiPlusCircle color='var(--primary)' size='1rem' style={{ cursor: "pointer" }} />
                                            <div className="textMD w-700 primary">Add</div>
                                        </span>}
                                    </div>,
                                    style: { backgroundColor: "var(--white)", borderTop: "none" },
                                    children: Object.values(variables || {})?.length === 0 ? <div style={{ padding: "1rem" }}>
                                        <NoDataFound message='' />
                                    </div> : <div style={{ flex: 1, gap: '1.25rem', marginTop: "1.25rem", display: "flex", flexDirection: "column" }}>
                                        {Object?.entries(sortArrObject(variables) || {})?.map(([key, i]) => {
                                            const queries = i?.queries
                                            return (
                                                <div style={{ display: "flex", flexDirection: "column", gap: 0, boxShadow: '0.1px 0.1px 10px 0px var(--grey1)' }}
                                                    key={generateUniqueKey(i?.name)}>
                                                    <div style={{
                                                        display: "flex", flexDirection: "row", alignItems: "center", gap: 20, backgroundColor: "#454545", padding: 10,
                                                        borderTopLeftRadius: "0.3rem", borderTopRightRadius: "0.3rem", justifyContent: "space-between"
                                                    }}>
                                                        <div className='textSM w-700 white'>{i?.name}</div>
                                                        {!readOnly && <div className='d-flex align-items-center' style={{ gap: 10 }}>
                                                            <MdEdit color='var(--white)' style={{ cursor: "pointer" }} size='1rem' onClick={() => {
                                                                setSelectedVariable({
                                                                    varKey: key,
                                                                    ...i
                                                                })
                                                                setVariableModal(true);
                                                            }} />

                                                            {finalCode({
                                                                globals: globals,
                                                                variables: variables,
                                                                conditions: conditions,
                                                                conditions_oper: conditions_oper,
                                                                indices: indices,
                                                                sortAndLimit: stocksSortAndLimit
                                                            })?.includes(i?.name) ? <ConfirmModal trigger={
                                                                <div style={{ display: "flex", alignItems: "center", cursor: "pointer", justifyContent: "center" }}>
                                                                    <MdDelete color='var(--white)' style={{ cursor: "pointer" }} size='1rem' />
                                                                </div>
                                                            } triggerStyle={{}} title={'Metric is used in your condition'}
                                                                description={'In order to delete this metric you need to edit your condition first.'}
                                                                showNote={false}
                                                                confirmBtnText={"Okay"}
                                                                showCancel={false}
                                                                width='25rem' onConfirm={() => {

                                                                }} /> : <ConfirmModal trigger={
                                                                    <div style={{ display: "flex", alignItems: "center", cursor: "pointer", justifyContent: "center" }}>
                                                                        <MdDelete color='var(--white)' style={{ cursor: "pointer" }} size='1rem' />
                                                                    </div>
                                                                } triggerStyle={{}} title={'Delete metric'}
                                                                    description={'Are you sure want to delete this metric?'} width='25rem' onConfirm={() => {
                                                                        var q = { ...variables }
                                                                        let keys = Object.keys(variables)
                                                                        let temp = {};
                                                                        for (let ke in variables) {
                                                                            if (ke !== key) {
                                                                                temp[Object.keys(temp)?.length + '_variable'] = variables[ke]
                                                                            }
                                                                        }
                                                                        setVariables(temp);
                                                                    }} />}



                                                            {/* 
                                                            <Popconfirm title={<span className='textSM w-500 dark4'>Delete metric</span>}
                                                                description={<span className='textESM w-500 dark4'>You might be using this metric in conditions.<br />Are you sure you want to delete it?</span>}
                                                                arrow={false} okText='Yes' cancelText='No' placement='topLeft' onConfirm={() => {
                                                                    var q = { ...variables }
                                                                    let keys = Object.keys(variables)
                                                                    let temp = {};
                                                                    for (let ke in variables) {
                                                                        if (ke !== key) {
                                                                            temp[Object.keys(temp)?.length + '_variable'] = variables[ke]
                                                                        }
                                                                    }
                                                                    setVariables(temp);
                                                                }}>
                                                                <MdDelete color='var(--white)' style={{ cursor: "pointer" }} size='1rem' />
                                                            </Popconfirm> */}
                                                        </div>}
                                                    </div>
                                                    <div style={{ background: "#1f1f1f", borderRadius: "0 0 0.3rem 0.3rem", padding: "1rem" }}>
                                                        {Object?.keys(queries)?.map((metric, index) => {
                                                            return <span className="textSM" style={{
                                                                cursor: "pointer",
                                                                border: "none", outline: "none", flex: (queries?.[metric] === "and" || queries?.[metric] === "or") ? 1 : '',
                                                                borderRadius: "var(--borderRadius)", padding: "0.25rem",
                                                                marginRight: "0.25rem",
                                                                lineHeight: "1.5rem",
                                                                backgroundColor: "var(--black)"
                                                            }} key={generateUniqueKey(index)}>{expUIParser(queries[metric]?.expression, queries[metric], metric)}</span>
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }]} />}
                            {strategy_fundD === undefined && !isLanding && <div className="mobCardBorderRadius0" style={{ boxShadow: '0.1px 0.1px 10px 0px var(--grey1)', background: "var(--white)", padding: "1rem", borderRadius: "0.3rem" }} onClick={() => {
                                setIsCondBlockActive(false);
                            }}>
                                <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                                    <div className='textLG w-700 dark4'>Sort and limit</div>
                                    <Switch checked={useSortAndLimit} onChange={(e) => {
                                        setuseSortAndLimit(e)
                                        if (!e) {
                                            var akak = JSON.parse(JSON.stringify(globals))
                                            delete akak["exclusion_list"]
                                            setGlobals({
                                                ...akak
                                            })
                                        }
                                    }} />
                                </div>
                                <div className='textXS textLight'>This section allows you to limit the number of stocks in your screen by sorting on any metric of your choice. You can also use custom metric to sort</div>

                                <div style={{ transition: 'all 5s linear', display: (useSortAndLimit || !isMobile) ? "grid" : "none", gridTemplateColumns: "1fr 1fr", gap: "1rem", marginTop: "1rem" }}>
                                    <div className='w100 h100'>
                                        <label className='textSM w-500 dark3 mb-2'>Number of stocks to show</label>
                                        <LabelInputNew disabled={!useSortAndLimit} max={50} min={stocksSortAndLimit?.top === null ? null : 3} placeholder='No. of stocks' value={stocksSortAndLimit?.top} style={{ width: "100%" }} onChangeVal={(e) => {
                                            setStocksSortAndLimit((prev) => {
                                                return {
                                                    ...prev,
                                                    top: e
                                                }
                                            })
                                        }} />
                                    </div>
                                    <div className='w100 h100'>
                                        <label className='textSM w-500 dark3 mb-2'>Sorted by</label>
                                        <br />
                                        <QueryOptionsDropdownV2 disabled={!useSortAndLimit} isAdvance={isAdvance} value={stocksSortAndLimit?.by} options={screenerMetrics?.filter((el) => el?.metric_data_type === 'float')?.map((el) => {
                                            return { ...el, tabType: el?.type }
                                        })?.concat(Object.values(variables || {})?.map((el) => {
                                            return { key: el?.name, label: el?.name, value: el?.name, tabType: "Variable" }
                                        }))} tabTypesToShow={['Metric F', 'Metric T', 'Variable']} onSelect={(met) => {
                                            setStocksSortAndLimit((prev) => {
                                                return {
                                                    ...prev,
                                                    by: met?.value,
                                                    sort: met?.direction_flag === 'top' ? "desc" : "asc"
                                                }
                                            })
                                        }} showAllTabTypes={false} dropdownPositions={{ right: "0%" }} width={"100%"} placeholder='Search metric'
                                            tabs={[{ key: 'all', label: "All", value: '' },
                                            { key: 3, label: "Fundamental Metrics", value: "Metric F" },
                                            { key: 3, label: "Technical Metrics", value: "Metric T" },
                                            { key: 4, label: "Custom Metrics", value: "Variable" }]}
                                        />
                                    </div>
                                    <div className='w100 h100'>
                                        <label className='textSM w-500 dark3 mb-2'>Sort metric in </label>
                                        <br />
                                        <Select disabled={!useSortAndLimit} style={{ width: "100%" }} placeholder='Sort by' value={stocksSortAndLimit?.sort} onChange={(val) => {
                                            setStocksSortAndLimit((prev) => {
                                                return {
                                                    ...prev,
                                                    sort: val
                                                }
                                            })
                                        }} options={sortBy} allowClear />
                                    </div>
                                    {type?.value === 4 && <div className='w100 h100'>
                                        <label className='textSM w-500 dark3 mb-2'>Exclude stocks in ASM/GSM/ESM framework</label>
                                        <br />
                                        <Switch disabled={!useSortAndLimit} checked={globals?.exclusion_list?.expression === 0} onChange={(e) => {
                                            if (e) {
                                                setGlobals({
                                                    ...globals,
                                                    exclusion_list: {
                                                        name: "exclusion_list",
                                                        isGlobal: true,
                                                        expression: 0,
                                                        expression_arr: 0
                                                    }
                                                })
                                            } else {
                                                var akak = JSON.parse(JSON.stringify(globals))
                                                delete akak["exclusion_list"]
                                                setGlobals({
                                                    ...akak
                                                })
                                            }

                                        }} />
                                    </div>}

                                </div>
                            </div>}
                        </div>}
                    </div>}

                    {tableLoading ? <ActivityLoader /> : screenedOnce && <div id="screenedItemsTableContainer">
                        <InstrumentListTable
                            showBubbleChartBtn={true}
                            finalList={screenedStocksData?.instruments || []}
                            tableType={11}
                            isCard={strategy_fundD === undefined}
                            hideCheckBox={true}
                            downloadTable={param_screenUserId === user?.id}
                            defaultInsType={type?.value}
                            hideInvestBtn={false}
                            showToolsBtnsInTab
                            showHeatmapBtn
                            // hideSettings
                            fileDefaultName="Screened stocks list"
                            otherCol={[...screenedCols].map((col) => {
                                return {
                                    "render": (text, record, index) => <div style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "flex-end" }}>
                                        {displayValue({
                                            text, metricObj: col
                                        })}
                                    </div>,
                                    ...col,
                                }
                            })}
                            disableInvestBtn={screenedStocksData?.instruments?.length < 5}
                            tableHeader={<span style={{ display: "flex", flexDirection: "column", }}>
                                <span>
                                    {strategy_fundD !== undefined ? `Latest stocks that qualify your stock selection model` : `${screenedStocksData?.instruments?.length} ${type.dname} qualify these filters`}
                                </span>
                                <span className="textXS w-500 textLight">
                                    {screenedStocksData?.instruments?.length > 0 ? "as on " +
                                        formatDateWithTime(screenedStocksData?.timestamp) : ""}
                                </span>
                                {strategy_fundD !== undefined && <span className="textXS w-500 textLight">
                                    Note that list of stocks shown here may not match with your current holding as your portfolio was last rebalanced on {formatDateMMMDDYYYY(strategy_fundD?.config?.last_rebal_date) || "-"}
                                </span>}
                            </span>}
                        />
                    </div>}



                    {/* {!screenedOnce && strategy_fundD === undefined && <div className="mt-5" style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                        <Divider className="mt-5 mb-3" />
                        <CardHeader
                            heading={"Explore screens created by sharpely community"}
                        />
                        <div className="textSM textLight">Explore screens created by our community experts including SEBI registered entities. If you like a screen, you can clone it and save as your own screen.</div>
                        <div className="mt-4" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: "1.5rem" }}>
                            {allPublishedScreens?.map((item, index) => {
                                ////console.log(item)
                                var publisherD = allPublishers?.filter((i) => i?.ClientId === item?.client_id)?.[0]
                                if (publisherD?.IsApproved === 1)
                                    return <Card
                                        onClick={() => {
                                            navigate(`${type.basePath}/super-screener?screenName=${item?.screen_name?.replaceAll(' ', '-')}&screenId=${item?.screen_id}&userId=${publisherD?.ClientId || 0}`)
                                        }}
                                        hoverable bordered>
                                        <div className='' style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                            <Meta
                                                style={{ flex: 1, gap: 0 }}
                                                avatar={<Avatar src={publisherD?.PublisherIcon} />}
                                                title={<span className='textSM textEllipsis1'>{item?.screen_name}</span>}
                                                description={<div className='textXS textLight'>By {<Link style={{
                                                    color: "var(--primary)", cursor: "pointer",
                                                }} to={`/publisher-profile?name=${publisherD?.Name?.replaceAll(" ", "-")}&id=${publisherD?.UID}`} onClick={(e) => { e.stopPropagation() }}>{String(publisherD?.Name)?.substring(0, 20)}{publisherD?.Name?.length > 15 ? "..." : ""}</Link>} on {formatDateMMMDDYYYY(item?.created_on)}</div>}
                                            />
                                            <div className="textXS textEllipsis3">
                                                {item?.screen_desc}
                                            </div>
                                        </div>
                                    </Card>
                                else
                                    return null
                            })}
                        </div>
                    </div>} */}

                    {(user?.id <= 7 && !isMobile && !isLanding) && <Collapse style={{ width: "100%", background: "transparent", boxShadow: '0.1px 0.1px 10px 0px var(--grey1)' }} bordered={false} expandIconPosition='end'
                        className='screenerCollapseCont' expandIcon={({ isActive }) => <IoIosArrowDown style={{ transform: isActive ? "rotate(180deg)" : "" }} size='1.25rem'
                            color='var(--dark4)' />}
                        items={[{
                            key: 0,
                            label: <div style={{ display: "flex", backgroundColor: "var(--white)", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                <div className="textLG w-700">Developer Views</div>
                            </div>,
                            style: { backgroundColor: "var(--white)", borderTop: "none" },
                            children: <div>
                                < div style={{ display: "flex", flexDirection: "row", flex: 1, gap: 20 }}>
                                    <Card style={{ flex: 1, gap: 10 }} className="mobCardBorderRadius0">
                                        <span>{code?.split("\n")?.map((line) => {
                                            return <span key={makeid(3)}>{line}<br></br></span>
                                        })}</span>
                                        <button className="btnBlack" onClick={() => {
                                            setCode(finalCode({
                                                globals: globals,
                                                variables: variables,
                                                conditions: conditions,
                                                conditions_oper: conditions_oper,
                                                indices: indices,
                                                sortAndLimit: stocksSortAndLimit
                                            }))
                                        }}>View Code</button>
                                    </Card>
                                    <Card title="Strategy orignal code">
                                        <span>{strategy_fundD?.config?.code?.split("\n")?.map((line) => {
                                            return <span key={makeid(3)}>{line}<br></br></span>
                                        })}</span>

                                        <span>{JSON.stringify(stocksSortAndLimit)}</span>

                                    </Card>
                                    <Card style={{ flex: 1, gap: 10 }}>
                                        <textarea onBlur={async (e) => {
                                            if (e.target.value?.length > 0) {
                                                setDefCode(e.target.value)
                                                // setTimeout(() => {
                                                // codeParser(e.target.value)

                                                var res = await codeParserWrapper({
                                                    code: e.target.value, screenerMetrics, functions
                                                })

                                                setConditions(res?.conditions)
                                                setConditionsOper(res?.conditionOperators)
                                                setGlobals(res?.globals)
                                                setVariables(res?.variables)
                                                // }, 2000);
                                            }
                                        }} style={{
                                            border: "1px solid var(--grey2)", borderRadius: 4
                                        }} defaultValue={defCode} className="textSM" rows="20" cols="50">
                                        </textarea>
                                        <button className="btnBlack" onClick={async () => {
                                            // codeParser(defCode);
                                            var res = await codeParserWrapper({
                                                code: defCode, screenerMetrics, functions
                                            })

                                            setConditions(res?.conditions)
                                            setConditionsOper(res?.conditionOperators)
                                            setGlobals(res?.globals)
                                            setVariables(res?.variables)
                                        }} >Parse Code</button>
                                    </Card>
                                </div>

                                {/* opigo */}
                                <button onClick={async () => {
                                    // var res = await AdminServicesOpigo.testApi().then((r) => r)
                                    // ////////console.log(res)
                                    // document.getElementById("resOut").innerText = JSON.stringify(res)
                                }}>
                                    Test
                                </button>







                                <br></br>
                                <br></br>
                                <hr></hr>
                                <div id="resOut"></div>
                            </div>
                        }]} />}

                    {
                        variableModal && <VariableBuilder indices={indices} isAdvance={isAdvance} allFundsData={allFundsData} variables={variables} isEditing={Boolean(selectedVariable?.name?.length)} toggle={() => {
                            setVariableModal(!variableModal);
                            if (Boolean(selectedVariable?.name?.length)) {
                                setSelectedVariable({})
                            }
                        }} open={variableModal}
                            functions={functions} screenerMetrics={screenerMetrics} selectedVariable={selectedVariable}
                            onDone={(variable) => {
                                let varNames = Object.keys(variables || {});
                                let currVarName = variable?.name;
                                if (varNames?.includes(currVarName)) {
                                    toast.error('Custom metric with this name already exists. Please use another name!')
                                }
                                else {
                                    if (selectedVariable?.name) {

                                        // var numkey = selectedVariable?.varKey?.split("_")?.[0]
                                        Object.filter = (obj, predicate) => {
                                            return Object.fromEntries(Object.entries(obj).filter(predicate))
                                        };
                                        var variableString = JSON.stringify(Object.filter(variables, ([varname, value]) => value?.isGlobal !== true && varname !== selectedVariable?.varKey))
                                        const regexPattern = new RegExp(`\\b${selectedVariable?.name}\\b`, 'g');
                                        variableString = variableString?.replace(regexPattern, `${variable?.name}`)

                                        setVariables({
                                            ...JSON.parse(variableString),
                                            [selectedVariable?.varKey]: variable
                                        })
                                        var condStr = JSON.stringify(conditions)
                                        condStr = condStr?.replace(regexPattern, `${variable?.name}`)
                                        //////////console.log(condStr)
                                        setConditions(JSON.parse(condStr))
                                        setSelectedVariable({})
                                    }
                                    else {
                                        setVariables({
                                            ...variables,
                                            [Object.keys(variables)?.length + '_variable']: variable
                                        })
                                    }
                                    setVariableModal(!variableModal)
                                }
                            }}
                        />
                    }

                    <Modal open={replayModal} title='Replay' footer={null} onCancel={() => { setReplayModal(false) }} centered>
                        <div className='d-flex align-items-center' style={{ gap: "1rem", marginTop: "1.25rem" }} >
                            <Input type="number" value={replayInput} onChange={(e) => setReplayInput(e.target.value)} style={{ width: "10rem" }} />
                            <div className='textSM dark4'>
                                {timeframe?.includes('d') ? 'days ago' : timeframe?.includes('w') ? 'weeks ago' : 'months ago'}
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-end' style={{ gap: "1.25rem", marginTop: "1.25rem" }} >
                            <button className='btnWhite' onClick={() => setReplayModal(false)}>Cancel</button>
                            <button className='btnBlack' onClick={() => {
                                setReplayModal(false)
                                getScreenedStocks(true)
                            }}>Done</button>
                        </div>
                    </Modal>

                    {backTestModal && <Drawer
                        width={isMobile ? "100%" : "90vw"}
                        closable={false}
                        placement="right"
                        onClose={() => {
                            setbackTestModal(!backTestModal)
                            setviewType("backtest")
                        }}
                        styles={{ body: { padding: '1.25rem' } }}
                        open={backTestModal}
                        footer={false}>
                        <div id="backtest-modal-main-container" style={{ display: "flex", flexDirection: "column", gap: "1.25rem", flex: 1, height: "100%" }}>
                            <span style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                                <CardHeader heading={strategy_fundD == undefined && param_screenUserId === user?.id ? `Backtest or Paper trade ${isMobile ? '' : 'your screen'}` : "Backtest results"} hideHeaderBlueLine headingType={2} />
                                {!((JSON.stringify(backtestConfig) === JSON.stringify(updatedConfig) && start_date2 === start_date && end_date2 === end_date)) && viewTypePendingNewRun > 1 ? <button className='btnBlack'
                                    onClick={(e) => {
                                        // ////console.log(updatedConfig)
                                        if (viewType === "backtest")
                                            onBacktestInpDone(updatedConfig, start_date2, end_date2);
                                        else
                                            setregenerateOrder(true)
                                    }}>
                                    {viewType === "backtest" ? "Re run backtest" : "Regenerate orders"}
                                </button> : viewTypePendingNewRun === 1 && viewType === "backtest" ? <button className='btnBlack'
                                    onClick={(e) => {
                                        // ////console.log(updatedConfig)
                                        if (viewType === "backtest")
                                            onBacktestInpDone(updatedConfig, start_date2, end_date2);
                                        else
                                            setregenerateOrder(true)
                                    }}>
                                    {viewType === "backtest" ? "Re run backtest" : "Regenerate orders"}
                                </button> : <></>}
                                <button className='btnBlack' onClick={() => {
                                    setbackTestModal(!backTestModal)
                                    setviewType("backtest")
                                }}>Close</button>
                            </span>
                            {(strategy_fundD == undefined && param_screenUserId === user?.id) && <ScreenBacktestInpModal
                                type={type}
                                startDate={start_date}
                                endDate={end_date}
                                // fromStrategyDetail
                                viewType={viewType}
                                initialConfig={updatedConfig}
                                screenerMetrics={screenerMetrics}
                                variables={variables}
                                benchmarks={benchmarks}
                                parsedCode={{
                                    globals: globals,
                                    variables: variables,
                                    conditions: conditions,
                                    conditions_oper: conditions_oper
                                }}
                                onDone={(config, start_date, end_date, parsedCode) => {
                                    setupdatedConfig({
                                        ...config,
                                        'position_sizing': {
                                            ...config?.position_sizing,
                                            limit: {
                                                ...backtestConfig?.position_sizing?.limit,
                                                ...config?.position_sizing?.limit
                                            }
                                        }
                                    })
                                    var position_sizing = {
                                        ...config?.position_sizing,
                                        limit: {
                                            ...backtestConfig?.position_sizing?.limit,
                                            ...config?.position_sizing?.limit
                                        }
                                    }
                                    setuseSortAndLimit(true)
                                    setStocksSortAndLimit(position_sizing?.limit)
                                    setstart_date2(start_date)
                                    setend_date2(end_date)
                                    setviewTypePendingNewRun(2)
                                    if (JSON.stringify(parsedCode?.globals) !== JSON.stringify(globals))
                                        setGlobals(parsedCode?.globals)
                                }} />}
                            {backTestModalLoading && backTestPerfData?.length === 0 ? <div style={{
                                height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", alignContent: "center", justifyItems: "center"
                            }}>
                                {/* <Lottie options={{
                                    loop: true,
                                    autoplay: true,
                                    height: "10rem",
                                    width: "10rem",
                                    animationData: animationData,
                                    rendererSettings: {
                                        preserveAspectRatio: 'xMidYMid slice',
                                    },
                                }} width='10rem' height='10rem' isClickToPauseDisabled /> */}
                                <img width='10rem' height='10rem' style={{
                                    height: "10rem",
                                    width: "10rem",
                                }} src={require('Assets/lottie/chart-load.gif')} alt="loading..." />
                                <CustomChangingTextLoader
                                    otherText={["Generating signals...", "Running back test...", "Fetching results..."]}
                                />
                            </div> :
                                <SuperScreenerBacktestAndPaperTrade type={type} isAdvance={isAdvance} allFundsData={allFundsData} isPaperTradeAllowed={strategy_fundD == undefined && param_screenUserId === user?.id} onBacktestPressed={() => {
                                    setviewType("backtest")
                                }}
                                    viewType={viewType}
                                    onPaperTradePressed={onPaperTrade} regenerateOrder={regenerateOrder} initial_capital={backtestConfig?.initial_capital} backTestModalLoading={backTestModalLoading} isSuperScreener={true} chartData={backTestPerfData} backTestData={backTestData} backtestType='screener'
                                />
                            }
                        </div>
                    </Drawer>}

                    <SaveScreenModal screenId={screenId} screenName={screenName} screenDesc={screenDesc} alertsEnabled={alertsEnabled} screenSaveModal={screenSaveModal}
                        setScreenSaveModal={setScreenSaveModal} saveScreener={saveScreener} userTotalScreens={userTotalScreens} subscribeMiddle={subscribeMiddle} />

                    <Modal open={editScreenerView}
                        onOk={() => {
                            resetState(!isAdvance)
                            setLoading(false);
                            setisAdvance(!isAdvance)
                            setEditScreenerView(false)
                        }}
                        closable={false}
                        onCancel={() => setEditScreenerView(false)}
                        // title={false}
                        footer={<div>
                            <button className="btnWhite ms-auto "
                                onClick={() => setEditScreenerView(false)}>Close</button>
                            <button
                                className="btnBlack ms-2 "
                                onClick={() => {
                                    resetState(!isAdvance)
                                    setLoading(false);
                                    setisAdvance(!isAdvance)
                                    setEditScreenerView(false)
                                }}>
                                Ok
                            </button>
                        </div >}>
                        <div>
                            <p className="textSM my-1">{'\n'}All your queries and conditions will be lost when switching mode. Please click ok to proceed.</p>
                        </div>
                    </Modal >


                </div>


            </div >
        </>

    )
}