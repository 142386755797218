import db from "Libs/db";
import { fetchFundDataWithoutFilter } from "Libs/fetchFundData";
import { updateAccessLevels } from "Libs/redux/AppData/reduxSlice";
import apiHelper from "Services/api/ApiHelper";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

function AppDataFetcher() {

    const dispatch = useDispatch();
    let user = useSelector((state) => state?.updateUserData?.value)
    const accessData = useSelector((state) => state?.accessLevel?.value);

    const getAccessLevels = async () => {
        try {
            let res = await apiHelper({ apiName: "getAccessLevelsv1", saveResponse: "sessionStorage" });
            if (res?.length > 0 && Array.isArray(res)) {
                if (JSON.stringify(res) !== JSON.stringify(accessData)) {
                    dispatch(updateAccessLevels(res))
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const loadAppData = async () => {
        await getAccessLevels();
    }

    const updateInstrumentsData = async () => {
        let lastUpdateTime = await db.get('instrumentsUpdateTime');
        lastUpdateTime = new Date(lastUpdateTime).getTime();
        let currentTime = new Date().getTime();
        if (currentTime - lastUpdateTime > 3600000) {
            await fetchFundDataWithoutFilter(4, true);
            await fetchFundDataWithoutFilter(1, true);
            await fetchFundDataWithoutFilter(2, true);
            await db.set('instrumentsUpdateTime', new Date());
        }
    }

    const updateInstrumentsEvent = () => {
        let pathsToPreventUpdIns = ['/', '/campaign/stock-report', '/campaign/screener', '/campaign/strategy'];
        if (!pathsToPreventUpdIns?.includes(window.location?.pathname)) {
            if (document.hidden !== false) {
                updateInstrumentsData();
            }
        }
    }

    React.useEffect(() => {
        loadAppData();
    }, [user])

    React.useEffect(() => {
        document.addEventListener('visibilitychange', updateInstrumentsEvent);
        return () => {
            document.removeEventListener('visibilitychange', updateInstrumentsEvent);
        }
    }, [])
    return null;
}

export default AppDataFetcher;