import { LoadingOutlined } from "@ant-design/icons";
import moengage from "@moengage/web-sdk";
import { flaticonSvg } from "Assets/Constant";
import { signInWithGoogle } from "FirebaseConst";
import Head from "Layout/Head";
import Logo from "Layout/Logo";
import db from "Libs/db";
import { toggleloginModal, updateUserData } from "Libs/redux/UserAuth/reduxSlice";
import AdminServices from "Services/api/AdminServices";
import { Dashboard_Portfolio } from "Services/api/FabricatedAPIS";
import { Divider } from "antd";
import React from "react";
import { toast } from "react-hot-toast";
import { FcGoogle } from "react-icons/fc";
import PinInput from "react-pin-input";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { LoginSignupCarousel } from "./LoginSignupCarousel";

export default function Register(props) {
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [otp, setOTP] = React.useState("");
  // const [mainOTP, setmainOTP] = React.useState("");
  const [nextBtnPressed, setNextBtnPressed] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [resendOTPTime, setResendOTPTime] = React.useState(30);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const otpref = React.useRef();
  const user = useSelector((state) => state?.updateUserData?.value);
  const resendOTPTimerRef = React.useRef();
  const nameRegex = /[^a-zA-Z\s]/g;
  const handleValidation = () => {
    let mailformat = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (name?.trim()?.length < 4) {
      toast.error("Name must have at least 4 characters");
    } else if (phone.length !== 10) {
      toast.error("Enter valid phone number!");
    } else if (!email.match(mailformat)) {
      toast.error("Enter valid email!");
    } else {
      return true;
    }
    return;
  };

  const checkOTP = async () => {
    setLoading(true);
    let isCorrectOTP = await AdminServices.verifyOTP({ value: email, otp: otp });
    console.log(isCorrectOTP);
    if (isCorrectOTP?.data?.Result === 'success') {
      let tphone = phone
      const data = {
        mobileNumber: tphone, // will be blank if skip is pressed
        email: email,
        clientName: name,
        source: "web",
        appId: 2
      };
      try {
        const res = await AdminServices.createUser(data).then((res) => res);
        if (res.type) {
          if (res.data.Result === "success") {
            const userData = {
              name: name,
              email: email,
              mobile: tphone,
              id: res.data.ClientId,
              isLogged: true,
              login_Date: new Date(),
            };
            db.set(db.model.user, JSON.stringify({ userData }));
            let d = {}
            await Dashboard_Portfolio.getClientData(userData, true).then(
              (res) => {
                if (res) {
                  d = res;
                  dispatch(updateUserData({ ...userData, ...res }));
                }
              }
            );
            dispatch(toggleloginModal(false));
            moengage.track_event("Registration_completed_email", {
              "registration_email": email,
            })
            moengage.track_event("OTP_submitted_registration", {
              "otp_user_email": userData?.email,
              'otp_user_id': userData?.id
            })
            let userTracked = await moengage.add_unique_user_id("SHARPELY-" + userData.id);
            if (userTracked) {
              moengage.add_email(userData.email)
              moengage.add_mobile(userData.mobile)
              moengage.add_user_name(userData.name)
              moengage.add_user_attribute("SharpelyAccessLevel", d?.Client?.[0]?.SharpelyAccessLevel)
            }
            toast.success("Account created successfully!");
            navigate("/user-onboarding", { state: { hasSignedUp: true } });
            setNextBtnPressed(false);
          } else {
            toast.error("Something went wrong. Please try again ...");
            setNextBtnPressed(false);
            setLoading(false);
          }
        } else {
          toast.error("Something went wrong. Please try again ...");
          setNextBtnPressed(false);
          setLoading(false);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again ...");
        setNextBtnPressed(false);
        setLoading(false);
      }
    } else {
      toast.error("You have entered an incorrect OTP. Try again ...");
      setOTP('');
      otpref?.current?.clear()
      setNextBtnPressed(false);
      setLoading(false);
    }
  };

  const startResendOTPTimer = () => {
    resendOTPTimerRef.current = setInterval(() => {
      setResendOTPTime((prev) => {
        if (prev === 0) return prev;
        else return prev - 1;
      })
    }, 1000)
  }

  const sendOTP = async () => {
    setLoading(false);
    try {
      // setmainOTP("");
      setResendOTPTime(30);
      const res = await AdminServices.sendOTP("EMAIL", email, 2, name).then(
        (res) => res
      );
      if (res.type) {
        if (res.data.Result === "success") {
          // setmainOTP(res.data.otpcode);
          // let userTracked = await moengage.add_unique_user_id("SHARPELY-" + id);
          // if (userTracked) {
          //   moengage.add_email(email)
          //   moengage.add_user_name(name)
          // }
          moengage.track_event("Registration_initiated_email", {
            "registration_email": email,
          })
          moengage.track_event('email_submitted_registration', {
            "user_email": email,
          })
          toast.success("OTP has been sent on your registered email/mobile");
          setNextBtnPressed(true);
          startResendOTPTimer();
        } else {
          toast.error("Something went wrong. Please try again ...");
        }
      } else {
        toast.error("Something went wrong. Please try again ...");
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again ...");
    }
  };

  const onNextBtnPressed = async () => {
    try {
      setLoading(true);
      let tphone = phone
      const res = await AdminServices.ClientExists({
        type: "email",
        value: email,
      }).then((res) => res);
      const res2 = await AdminServices.ClientExists({ type: "mobile", value: tphone });
      if (res.type && res2?.type) {
        if (res.data.ClientId !== "" || res2.data.ClientId !== "") {
          toast.error(
            "You are already a sharpely registered user. Please login!"
          );
          setLoading(false);
        }
        else {
          sendOTP(res.data.ClientId);
        }
      } else {
        toast.error("Something went wrong. Please try again ...");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again ...");
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return;
    if (nextBtnPressed) checkOTP();
    else {
      if (handleValidation() === true) {
        onNextBtnPressed();
      }
    }
  };

  const signin = async () => {
    setLoading(true);
    var user = await signInWithGoogle();
    if (Object.keys(user)?.length <= 0) {
      setNextBtnPressed(false);
      setLoading(false);
    } else {
      try {
        moengage.track_event("Registration_initiated_google", {
          "registration_email": user?.email,
        })
        const res = await AdminServices.ClientExists({ type: "email", value: user.email, }).then((res) => res);
        if (res.type) {
          if (res.data.ClientId !== "") {
            toast.error(
              "You are already a sharpely registered user. Please login! Please Login."
            );
            setLoading(false);
            setNextBtnPressed(false);
          } else {

            let userName = user?.displayName?.replace(nameRegex, ' ')?.trim();
            userName = userName ? userName : user?.email?.replace('@gmail.com', '')?.replace(nameRegex, ' ')?.trim();

            const data = {
              mobileNumber: "", // will be blank if skip is pressed
              email: user.email,
              clientName: userName,
              source: "web",
              appId: 2,
            };

            const res = await AdminServices.createUser(data).then((res) => res);
            if (res.type) {
              if (res.data.Result === "success") {
                const userData = {
                  name: userName,
                  email: user.email,
                  mobile: "",
                  id: res.data.ClientId,
                  isLogged: true,
                  login_Date: new Date(),
                };
                moengage.track_event("Registration_completed_google", {
                  "registration_email": user?.email,
                })
                db.set(db.model.user, JSON.stringify({ userData }));
                let d = {}
                await Dashboard_Portfolio.getClientData(userData, true).then(
                  (res) => {
                    if (res) {
                      d = res;
                      dispatch(updateUserData({ ...userData, ...res }));
                    }
                  }
                );
                dispatch(toggleloginModal(false));
                let userTracked = await moengage.add_unique_user_id("SHARPELY-" + userData.id);
                if (userTracked) {
                  moengage.add_email(userData.email)
                  moengage.add_mobile(userData.mobile)
                  moengage.add_user_name(userData.name)
                  moengage.add_user_attribute("SharpelyAccessLevel", d?.Client?.[0]?.SharpelyAccessLevel)
                }
                toast.success("Account created successfully!");
                navigate("/user-onboarding", { state: { hasSignedUp: true } });
                setNextBtnPressed(false);
              } else {
                toast.error("Something went wrong. Please try again ...");
                setNextBtnPressed(false);
                setLoading(false);
              }
            } else {
              toast.error("Something went wrong. Please try again ...");
              setNextBtnPressed(false);
              setLoading(false);
            }
          }
        } else {
          toast.error("Something went wrong. Please try again ...");
          setLoading(false);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again ...");
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    if (otp.length === 6) checkOTP();
  }, [otp]);

  React.useEffect(() => {
    if (resendOTPTime === 0) {
      clearInterval(resendOTPTimerRef.current);
      resendOTPTimerRef.current = null;
    }
  }, [resendOTPTime]);

  if (user?.isLogged) {
    return <Navigate to='/stocks/dashboard' />
  }

  return (
    <React.Fragment>
      <Head title="Join sharpely for Financial Empowerment | Sign Up Today" description="Sign up with sharpely to unlock a world of financial empowerment and personalized investment tools." />
      <div className="mainContainer loginComponent" style={{ alignItems: "center", gap: 0 }}>
        <div style={{ height: "calc(100vh)", width: "65%", display: "flex", alignItems: "center", justifyContent: "center", flexShrink: 0, background: "var(--dark4)", paddingRight: "2rem" }}>
          <LoginSignupCarousel />
        </div>
        <div style={{ height: "calc(100vh)", flex: 1, width: "100%", padding: "0 20px", display: "flex", alignItems: "center", }}>
          <div style={{ width: "100%", maxHeight: "100%", overflowY: "auto", padding: "20px 0" }}>
            <Logo type="dark" height='4rem' />
            <div className="displayMD " style={{ marginTop: "0.75rem", fontWeight: 600 }}>
              Welcome to
              <br />
              <span className="w-500" style={{ color: "var(--primary)" }}>
                sharpely! 👋🏻
              </span>
            </div>
            <div className="textMD dark4" style={{ marginTop: "0.75rem" }}>
              Signing up is easy. It only takes a few steps.
            </div>
            <form onSubmit={(e) => handleSubmit(e)} style={{ marginTop: "2rem", marginBottom: "0rem" }}>
              {!nextBtnPressed ? <>
                <div style={{ marginBottom: "1.5rem" }}>
                  <label className="dark3 textXS w-500 mb-1">
                    Name <span style={{ color: "var(--red)" }}>&#42;</span>
                  </label>
                  <input name="name" placeholder="Enter your Name" value={name} onChange={(e) => {
                    let v = e.target.value;
                    v = v.replace(nameRegex, '')
                    if (v?.length < 50) {
                      setName(v);
                    }
                  }} className="textSM antdInput"
                    style={{ background: "var(--grey5)", border: "1px solid var(--grey2)", height: "40px", }} type="text" autoComplete="name" autoFocus={true} />
                </div>
                <div style={{ marginBottom: "1.5rem" }}>
                  <label className="dark3 textXS w-500 mb-1">
                    Phone Number <span style={{ color: "var(--red)" }}>&#42;</span>
                  </label>
                  <div className='d-flex align-items-center' style={{ gap: "1rem" }}>
                    <input name="tel" placeholder="Enter your phone number" onChange={(e) => { setPhone(e.target.value); }} className="textSM antdInput"
                      style={{ background: "var(--grey5)", border: "1px solid var(--grey2)", height: "40px", flex: 9 }} type="number" autoComplete="tel" value={phone} />
                  </div>
                </div>
                <div style={{ marginBottom: "1.5rem" }}>
                  <label className="dark3 textXS w-500 mb-1">
                    Email Address{" "}
                    <span style={{ color: "var(--red)" }}>&#42;</span>
                  </label>
                  <input name="email" placeholder="Enter your email address" onChange={(e) => { setEmail(e.target.value?.trim()); }} className="textSM antdInput"
                    style={{ background: "var(--grey5)", border: "1px solid var(--grey2)", height: "40px", }} type="text" autoComplete="email" value={email} />
                </div>
              </> : <>
                <div style={{ marginBottom: "1.5rem" }}>
                  <label className="dark3 textXS w-500 mb-1">OTP</label>
                  <PinInput ref={otpref} length={6} initialValue="" secret secretDelay={100} onChange={(value, index) => { setOTP(value); }} type="numeric" inputMode="number"
                    style={{ padding: "0rem" }} inputStyle={{
                      borderColor: "var(--grey2)", background: "var(--grey5)", borderRadius: "var(--borderRadius)", height: "40px",
                      width: "2.5rem",
                    }} inputFocusStyle={{ borderColor: "var(--primary)" }} onComplete={(value, index) => { }} autoSelect={true} focus={true} />
                </div>
                <div className='textESM dark3' style={{ marginBottom: "0.75rem" }}>
                  Did not received OTP? <span className='primary w-500' style={{ cursor: resendOTPTime === 0 ? "pointer" : "not-allowed" }} onClick={() => {
                    console.log(resendOTPTime)
                    if (resendOTPTime <= 0) {
                      sendOTP();
                    }
                  }}>Resend OTP</span>
                  {resendOTPTime > 0 && resendOTPTimerRef.current && <span> in {resendOTPTime}s</span>}
                </div>
                <div className='textESM dark3 d-flex' style={{ marginBottom: "1rem" }}>
                  Incorrect email/phone?&nbsp;<span className='primary w-500' onClick={() => {
                    setNextBtnPressed(false);
                    // setmainOTP('');
                    setOTP('');
                    clearInterval(resendOTPTimerRef.current)
                  }}>Change email/phone</span>
                </div>
              </>}
              <button style={{ width: "100%", opacity: loading ? "0.7" : "1" }} type="submit" className={" btnBlack textMD "}>
                {loading ? (
                  <LoadingOutlined style={{ fontSize: "1.5rem", color: "var(--white)" }} spin />
                ) : nextBtnPressed ? ("Sign Up") : ("Send OTP")}
              </button>
            </form>

            <Divider className="textMD">or</Divider>
            <button style={{ width: "100%", opacity: loading ? "0.7" : "1" }} className={" btnBlack "} onClick={() => {
              if (!loading) {
                signin();
              }
            }}>
              <FcGoogle className="" style={{ marginRight: "0.5rem", fontSize: "var(--textMD)" }} />
              <span className="textMD w-500">Sign Up With Google</span>
            </button>

            <div className='text-center d-flex' style={{ marginTop: "1.25rem" }}>
              <div className='d-flex flex-column align-items-center w100' style={{ gap: "0.25rem", padding: "0 0.5rem" }}>
                <img loading="lazy" src={`${flaticonSvg}fi-rr-lock.svg`} style={{ width: "20px" }} alt='No credential' />
                <div className='textXS w-700 dark3'>Safe transactions</div>
                <div className='textXS dark3'>Money stays in broker account</div>
              </div>
              <div className='d-flex flex-column align-items-center w100' style={{
                gap: "0.25rem", padding: "0 0.5rem", borderLeft: "1px solid var(--grey2)",
                borderRight: "1px solid var(--grey2)"
              }}>
                <img loading="lazy" src={`${flaticonSvg}fi-rr-arrow-small-right.svg`} style={{ width: "20px" }} alt='No credential' />
                <div className='textXS w-700 dark3'>You’re In Control</div>
                <div className='textXS dark3'>Sell anytime from your broker</div>
              </div>
              <div className='d-flex flex-column align-items-center w100' style={{ gap: "0.25rem", padding: "0 0.5rem" }}>
                <img loading="lazy" src={`${flaticonSvg}fi-rr-shield-check.svg`} style={{ width: "20px" }} alt='No credential' />
                <div className='textXS w-700 dark3'>No Credential</div>
                <div className='textXS dark3'>Your Information is safe</div>
              </div>
            </div>
            <div className='textSM dark3 text-center mt-3'>
              By continuing you agree to our <Link to='/terms-and-conditions'>terms & conditions</Link> and <Link to='/privacy-policy'>privacy policy</Link>
            </div>

            <div className="text-center textSM" style={{ marginTop: "2rem" }}>
              Already have an account?{" "}
              <Link to="/login" className="mx-1 w-500">
                Login Here
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
