import { Card, Segmented, Select } from "antd";
import { toggleMetricDescModal } from "Libs/redux/KnowledgeBase/reduxSlice";
import { toggleHelpAndSupp } from "Libs/redux/UserAuth/reduxSlice";
import { displayValue } from "Libs/utils";
import React, { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import AccessComponent from "./AccessComponent";
import ColumnChart from "./ColumnChart";
import CustomLabelValueCards from "./CustomLabelValue";
import CustomSideTabs from "./CustomSideTabs";
import PerformanceChart3 from "./PerformanceChart3";
import UpgradeToPro from "./UpgradeToPro";

const Fundamentals = ({ historicalCharts, screenerMetricDescData }) => {
    const snapShotLineCharts = ['Value', 'Stock Scores']
    const logCharts = ['Value']
    const dispatch = useDispatch();

    const NoChartData = ({ name }) => {
        return (
            <div style={{
                minHeight: '100%', backgroundColor: 'var(--white)', borderRadius: '0.5rem', display: 'flex', flexDirection: 'column', gap: '0.75rem',
                padding: '5rem 1.25rem'
            }}>
                <div className='textXS black mt-2'>
                    Seems like we do not have {name} for this stock. This could happen due to following reasons:
                </div>
                <div className='textXS black'>
                    <div>1. Market capitalisation of the stock is less than Rs 100 crore. Currently, {name} is available only for stocks with market cap greater than Rs 100 crore.</div>
                    <div>2. Something went wrong with our server</div>
                </div>
                <div className='textXS black'>
                    Do <span onClick={() => { dispatch(toggleHelpAndSupp(true)) }} style={{ color: "var(--primary)", cursor: "pointer" }}>report</span> this issue if you think that we should have insights for this stock.
                </div>
            </div>
        )
    }

    const HistoricalGraphs2 = ({ data, type, yAxisType }) => {
        console.log(data);
        const Chart = ({ chart, type, yAxisType }) => {
            const [scaleType, setScaleType] = React.useState('linear');

            if (chart && JSON.parse(chart?.chart_data)?.length === 0) {
                return <NoChartData name={chart?.chart_name} />
            }
            else if (chart && chart?.chart_data) {
                //console.log('chartdata', chart.chart_name, chart.chart_data)
                var x = Object.keys(JSON.parse(chart?.chart_data)?.[0] || {})?.[0]
                var y = Object.keys(JSON.parse(chart?.chart_data)?.[0] || {})?.[1]
                const cards = Object.keys(chart?.chart_text_items)?.map((k, i) => {
                    return {
                        key: i,
                        heading: k,
                        value: displayValue({ text: chart?.chart_text_items?.[k], metricObj: metric })
                    }
                })

                return <>
                    <Card style={{ marginTop: '0', borderRadius: "var(--borderRadius)", flex: 1 }} bodyStyle={{ padding: '0', height: "100%", borderRadius: "var(--borderRadius)", }}>
                        {/* <div className="textSM black w-500 ms-1 d-flex align-items-center" style={{ cursor: "pointer", display: 'flex', alignItems: 'center', gap: '0.5rem' }}
                            onClick={() => handleTitleClick(chart)}>
                            <span className="textSM black w-500 ms-1 d-flex align-items-center">{chart.chart_name}</span>
                            <img loading="lazy"  alt="" style={{ height: "1rem" }} src={`${flaticonSvg}fi-rr-arrow-up-right-from-square.svg`} />
                        </div> */}
                        <div style={{ marginBottom: '1rem' }}>
                            <CustomLabelValueCards x={cards} padding="0.75rem 1rem" minWidth="10rem" />
                        </div>
                        {yAxisType && chart?.tab === 'Multiples' && <Segmented value={scaleType} options={[{ label: "Linear", value: "linear", key: "linear" },
                        { label: "Logarithmic", value: "logarithmic", key: "logarithmic" }]}
                            onChange={(e) => { setScaleType(e) }} style={{ marginBottom: "1rem" }} size='small' />}
                        {
                            type === 'column' ?
                                <ColumnChart chartName={chart.chart_name} chartData={chart.chart_data} xAxisKey={x} yAxisKey={y}
                                    showYAxis={true} showDataLabels={true} height={200} />
                                : <PerformanceChart3 fundName={chart.chart_name} chartData={chart.chart_data} xAxisKey={x} chartType={type}
                                    showYAxis={true} showDataLabels={true} height={200} yAxisType={(yAxisType && chart?.tab === 'Multiples') ? scaleType : "linear"}
                                    changeZeroToConstant={yAxisType && chart?.tab === 'Multiples'} />
                        }
                        {chart?.tab === 'Multiples' && <div style={{ margin: "0 1rem 1rem" }} className='textXS black'>
                            Note: 0 value to be interpreted as follows – the denominator (e.g., EPS) was negative or not available and hence multiple could not be calculated. On log scale, 0 values will not be plotted.
                        </div>}
                    </Card>
                </>
            }
        }

        const { vertical_col, ...newObj } = data;
        //console.log('newObj', newObj)
        var options = []
        var tabs = []
        Object.values(newObj).map(tab => {
            if (tab?.charts?.length > 0) {
                tabs = [...tabs, tab?.name]
                var temp = tab?.charts.map(ch => {
                    return {
                        tab: tab?.name,
                        label: ch?.chart_name,
                        value: ch?.chart_name,
                        ...ch
                    }
                })
                options = [...options, ...temp]
            }
        })
        const [selectedMetric, setSelectedMetric] = React.useState(options?.[0]?.value);
        const [selected, setSelected] = React.useState(tabs?.[0])
        const chart = options.find(ch => ch.value === selectedMetric)
        const metric = screenerMetricDescData?.filter(ele => ele.desc_id === Number(chart?.desc))?.[0] || {}
        const handleTitleClick = () => {
            dispatch(toggleMetricDescModal({
                value: true,
                params: {
                    title: metric?.name,
                    description: metric?.description
                }
            }))
        }
        return <>
            <div style={{ display: "flex", marginBottom: '0.875rem', alignItems: "center" }}>
                {window.innerWidth > 760 ?
                    <div className="textLG w-700 dark4" style={{ flex: 1, cursor: 'pointer' }} onClick={() => handleTitleClick()}>
                        {selectedMetric}
                        <AiOutlineInfoCircle style={{ color: "var(--black)", fontSize: "1.25rem", marginLeft: "0.25rem", marginBottom: "3px" }} />
                    </div>
                    : <></>}
                <Select
                    showSearch
                    placeholder="Select a metric"
                    optionFilterProp="children"
                    // defaultValue={options[0].value}
                    value={selectedMetric}
                    filterOption={(input, option) =>
                        (option?.label).toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={(value) => setSelectedMetric(value)}
                    options={selected ? options.filter(o => o.tab === selected) : options}
                    style={{ width: window.innerWidth > 760 ? '20rem' : '100%' }}
                    dropdownRender={(menu) => {
                        const toggle = (val) => {
                            //console.log(val, selected, options)
                            if (selected !== val) {
                                setSelected(val);
                                setSelectedMetric(options.find(m => m.tab === val)?.value)
                            }
                        }
                        return <div >
                            <div style={{ display: 'flex', gap: '0.75rem', padding: '0.75rem 1rem', backgroundColor: 'var(--white)', borderBottom: '1px solid var(--grey4)' }} className='sticky-top'>
                                {
                                    tabs.map((t, i) => {
                                        return <div key={i} onClick={() => toggle(t)} className="btnWhiteSM"
                                            style={{ borderRadius: '0.5rem', backgroundColor: t === selected ? "var(--primary)" : "var(--grey3)", color: t === selected ? "var(--white)" : "var(--dark3)" }}>{t}</div>
                                    })
                                }
                            </div>
                            <div>
                                {menu}
                            </div>
                        </div >
                    }}
                />
            </div>
            <AccessComponent component={<Chart chart={chart} type={type} yAxisType={yAxisType} />} reqAccessLevel={Number(chart?.auth_flag)} upgradeComponent={
                <UpgradeToPro accessObj={{ AccessLevel: Number(chart?.auth_flag) }} />
            } />
        </>
    }

    let newData = {
        "value": historicalCharts.value,
        "quality": historicalCharts.quality,
        "financial": historicalCharts['fin_health'],
        "sharpely_scores": historicalCharts['sharpely_scores']
    }

    return <div style={{ margin: window.innerWidth > 760 ? '0 0 1.25rem 0' : '0 -1rem' }}>
        {CustomSideTabs({
            tabData: Object.entries(newData).map(([key, c], i) => {
                if (c.vertical_col !== 'Statement') {
                    return {
                        label: c.vertical_col,
                        key: i,
                        children: <AccessComponent component={<Card key={i} bordered={false} bodyStyle={{
                            minHeight: "50vh", display: "flex", flexDirection: "column", gap: "0rem", padding: '0'
                        }}>
                            <HistoricalGraphs2 key={i} data={c} type={!snapShotLineCharts.includes(c.vertical_col) ? 'column' : 'areaspline'}
                                yAxisType={logCharts?.includes(c.vertical_col)} />
                        </Card>} moduleKey={key === 'sharpely_scores' ? "STOCK_SCORES" : ""} upgradeComponent={
                            <Card key={i} bordered={false} bodyStyle={{
                                backgroundColor: 'var(--grey4)',
                                border: '1px solid var(--grey3)', borderRadius: '0.5rem',
                                minHeight: "50vh", display: "flex", flexDirection: "column", gap: "0rem", padding: '0'
                            }}>
                                <UpgradeToPro moduleKey="STOCK_SCORES" />
                            </Card>
                        } />,
                        show: true,
                        noHeader: true
                    }
                }
            }).filter(x => x),
            width: '10rem',
        })
        }
    </div>
}

export default React.memo(Fundamentals);