import { BsArrowRightShort } from "react-icons/bs";
import { HiOutlineHandThumbDown, HiOutlineHandThumbUp } from 'react-icons/hi2';
import { IoFlagOutline } from 'react-icons/io5';
import { MdInsights, MdOutlineSearchOff } from 'react-icons/md';
import React from 'react';

export const InsightIcon = ({ type, isRedFlag = false, redFlagType = 'Negative',height='3rem',width='3rem',padding='0.75rem' }) => {
    const NeutralIcon = ({ style }) => (<HiOutlineHandThumbUp style={{ ...style, transform: "rotate(-90deg)" }} />)
    const colors = [
        { type: "Negative", color: "var(--red)", bg: "var(--redShade)", icon: HiOutlineHandThumbDown },
        { type: "Positive", color: "var(--green)", bg: "var(--greenShade)", icon: HiOutlineHandThumbUp },
        { type: "Neutral", color: "var(--yellow)", bg: "var(--yellowShade)", icon: NeutralIcon },
        { type: "NoData", color: "var(--dark3)", bg: "var(--grey1)", icon: MdOutlineSearchOff },
        { type: "RedFlag", color: "var(--red)", bg: "var(--redShade)", icon: IoFlagOutline },
    ]
    const redFlags = [
        { type: "Negative", color: "var(--red)", bg: "var(--redShade)", icon: IoFlagOutline },
        { type: "Positive", color: "var(--green)", bg: "var(--greenShade)", icon: IoFlagOutline },
    ]
    const icon = isRedFlag ? redFlags?.filter((ele) => ele.type === redFlagType)?.[0] : colors.filter((ele) => ele.type === type)?.[0] || colors[3]
    return (
        React.createElement(icon?.icon, {
            style: {
                color: icon.color,
                background: icon.bg,
                height: height,
                width: width,
                borderRadius: "50%",
                padding: padding
            }
        })
    )
}
