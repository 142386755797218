import React from "react";
import { alertBuySell, alertCorporate, alertFM, alertRebalance, alertScreen, alertSurveillance } from 'Assets/images';
import { Avatar } from 'antd';
import { flaticonSvg } from "Assets/Constant";

export const AlertIcon = ({ name = '', height = '2rem', width = '2rem', type = 'circle', padding = '0.45rem' }) => {

    const imgs = {
        screen: alertScreen,
        factorModel: alertFM,
        rebalance: alertRebalance,
        buySell: alertBuySell,
        corporateAction: alertCorporate,
        surveillance: alertSurveillance
    }

    return (
        <React.Fragment>
            <Avatar style={{ flexShrink: 0, flexGrow: 0, height: height, width: width, border: "1px solid var(--grey11)", padding: padding, background: "var(--grey1)" }} shape={type}
                src={imgs?.[name] || `${flaticonSvg}fi-rr-bell.svg`} />
        </React.Fragment>
    )
}