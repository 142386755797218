import { Drawer } from "antd"
import { useIsMobile } from "CustomHooks/useIsMobile"
import React from "react"
import { IoIosArrowRoundBack, IoIosClose } from "react-icons/io"
import { useLocation } from "react-router"

const CustomDrawer = ({ title, isOpen, toggle, children, showBackBtn = false, width = "52.6rem", height = '', showCloseIcon = false, hideFooter = false,
    footerStyles = {}, headerStyles = {}, bodyStyles = {} }) => {
    const location = useLocation();
    const [scroll, setScroll] = React.useState(0);
    const isMobile = useIsMobile();
    const onClose = () => {
        document.body.style.position = 'unset';
        window.scrollTo({ top: scroll, left: 0, behavior: "instant" });
        toggle();
    }

    React.useEffect(() => {
        document.body.style.position = 'unset'
    }, [location])

    React.useEffect(() => {
        if (isOpen) {
            let d = window.scrollY;
            setScroll(d);
            document.body.style.position = 'fixed';
            document.body.style.top = 0;
            document.body.style.bottom = 0;
            document.body.style.right = 0;
            document.body.style.left = 0;
        }
    }, [isOpen])

    return (
        <React.Fragment>
            <Drawer title={<div className="d-flex align-items-start w100" style={{ gap: "1rem", justifyContent: "space-between" }}>
                <div>{title}</div>
                {showCloseIcon && <div> <IoIosClose style={{ fontSize: isMobile ? "2rem" : "1.5rem" }} onClick={onClose} /></div>}
            </div>} placement={!isMobile ? "right" : "bottom"} onClose={onClose} open={isOpen}
                width={!isMobile ? width : '100vw'}
                height={height ? height : !isMobile ? "100vh" : '80vh'}
                styles={{
                    header: { padding: '1.25rem', borderBottom: 'none', ...headerStyles },
                    body: { paddingRight: "1.25rem", paddingLeft: "1.25rem", paddingTop: "0", paddingBottom: hideFooter ? "1.25rem" : "0", ...bodyStyles },
                    footer: { textAlign: 'right', borderTop: 'none', padding: '1.25rem', ...footerStyles },
                    content: { borderRadius: isMobile ? "1rem 1rem 0 0" : "0" }
                }} close
                footer={hideFooter ? null : <button className="btnBlack ms-auto " onClick={onClose}>Close</button>}
                closable={showBackBtn} closeIcon={showCloseIcon ? <IoIosClose style={{ fontSize: "1.5rem" }} /> :
                    <IoIosArrowRoundBack style={{ fontSize: "1.5rem" }} />}>
                {children}
            </Drawer>
        </React.Fragment>
    )
}

export const CustomDrawerWithTrigger = React.memo(({ trigger, title = '', children, height = '', showCloseIcon = false, hideFooter = false }) => {

    const [open, setOpen] = React.useState(false);

    const toggle = () => {
        setOpen(false)
    }

    return (
        <React.Fragment>
            <span onClick={() => { setOpen(true) }}>
                {trigger}
            </span>
            <CustomDrawer isOpen={open} toggle={toggle} height={height} title={title} showCloseIcon={showCloseIcon} hideFooter={hideFooter}>
                {children}
            </CustomDrawer>
        </React.Fragment>
    )
})
export default CustomDrawer