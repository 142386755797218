import { useSelector } from "react-redux";

const AccessComponent = ({ component, upgradeComponent = null, moduleKey = '', reqAccessLevel = 0, isDefaultUpgrade = false }) => {
    const user = useSelector((state) => state?.updateUserData?.value)
    const userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel || 0
    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    let modReqAccessLevel = reqAccessLevel;
    if (moduleKey) {
        modReqAccessLevel = accessLevelData.find((ele) => ele.ModuleKey === moduleKey)?.AccessLevel
    }
    if (isDefaultUpgrade) {
        return upgradeComponent;
    }
    if (accessLevelData?.length <= 0) {
        return upgradeComponent;
    }
    else if (modReqAccessLevel <= userAccesslevel) {
        return component;
    } else return upgradeComponent;
}

export const IsAccessable = ({ reqAccessLevel = 0, moduleKey = '' }) => {
    const user = useSelector((state) => state?.updateUserData?.value)
    const userAccesslevel = user?.Client?.[0]?.SharpelyAccessLevel || 0
    const accessLevelData = useSelector((state) => state?.accessLevel?.value || []);
    let modReqAccessLevel = reqAccessLevel;
    if (moduleKey) {
        modReqAccessLevel = accessLevelData?.find((ele) => ele.ModuleKey === moduleKey)?.AccessLevel
    }
    if (accessLevelData?.length <= 0) {
        return false;
    }
    else if (modReqAccessLevel <= userAccesslevel) {
        return true;
    } else return false;
}

export default AccessComponent;