import React from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router";

export const CanonicalURL = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const safeDecodeURIComponent = (str) => {
        try {
            return decodeURIComponent(str);
        } catch (e) {
            let result = '';
            let currentIndex = 0;

            while (currentIndex < str.length) {
                let nextPercent = str.indexOf('%', currentIndex);

                if (nextPercent === -1) {
                    result += str.slice(currentIndex);
                    break;
                }

                if (nextPercent !== currentIndex) {
                    result += str.slice(currentIndex, nextPercent);
                    currentIndex = nextPercent;
                }

                let segment = str.slice(nextPercent, nextPercent + 3);
                try {
                    result += decodeURIComponent(segment);
                    currentIndex += 3;
                } catch (decodeError) {
                    result += '%';
                    currentIndex++;
                    if (currentIndex < str.length && str[currentIndex] === '%') {
                        result += '%';
                        currentIndex++;
                    }
                }
            }

            return result;
        }
    };

    React.useEffect(() => {
        let url = location.pathname + location.search + location.hash;
        try {
            // console.log('changing url==>');
            let safeURL = safeDecodeURIComponent(url);
            // console.log('changing url==>', safeURL);
            let encUrl = encodeURI(safeURL);
            // console.log('changing url==>', encUrl);
            // encUrl = safeDecodeURIComponent(encUrl);
            // console.log('changing url==>', encUrl)
            if (url !== encUrl) {
                navigate(encUrl);
            }
        } catch (error) {

        }
    }, [location]);

    return (
        <React.Fragment>
            <Helmet>
                <link rel="canonical" href={window.location.origin + location?.pathname + location?.search} />
            </Helmet>
        </React.Fragment>
    )
}